import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataTablesModel, ResponseModal } from 'src/app/_models';
import { ICrossCuttingLevelOneAdult } from 'src/app/_models/assessments/general/crosscuttingleveloneadult.model';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class CrossCuttingLevelOneAdultApiService {
  constructor(private httpClient: HttpClient) {}

  getCrossCuttingLevelOneAdultDetails(
    crosscuttingleveloneadultId: string
  ): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/crosscuttingleveloneadult/` +
        crosscuttingleveloneadultId
    );
  }

  addCrossCuttingLevelOneAdult(
    thisCrossCuttingLevelOneAdult: ICrossCuttingLevelOneAdult
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<ICrossCuttingLevelOneAdult>(
        `${environment.apiUrl}/crosscuttingleveloneadult`,
        thisCrossCuttingLevelOneAdult,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  addGuestCrossCuttingLevelOneAdult(
    thisCrossCuttingLevelOneAdult: ICrossCuttingLevelOneAdult
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<ICrossCuttingLevelOneAdult>(
        `${environment.apiUrl}/guestassessments/crosscuttingleveloneadult`,
        thisCrossCuttingLevelOneAdult,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getCrossCuttingLevelOneAdultList(
    patientId: string,
    assessmentId: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('patientId', patientId);
    params = params.append('assessmentId', assessmentId);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/assessments/crosscuttingleveloneadultlist`,
      { params: params }
    );
  }
}
