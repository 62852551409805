<div class="container">
  <div class="dialogTitle" *ngIf="displayTitle">
    <p matDialogTitle>Enter Sleep Times during Shift</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <br />
  <form [formGroup]="sleepLogForm">
    <mat-chip-listbox #chipList>
      <div class="row">
        <div
          class="col-6 col-sm-4 col-md-3 col-xl-2"
          *ngFor="let timeslot of timeArray"
        >
          <mat-chip-option
            [class]="
              timeslot.selected ? 'mat-chip-active' : 'mat-chip-inactive'
            "
            (click)="timeslot.selected = !timeslot.selected"
          >
            {{ timeslot.text }}
          </mat-chip-option>
        </div>
      </div>
    </mat-chip-listbox>

    <div class="row" style="margin-top: 15px">
      <div class="col-md-12">
        <label class="labelBold">
          Activity Date/Time <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            [owlDateTime]="dt1"
            [owlDateTimeTrigger]="dt1"
            placeholder="Date Time"
            formControlName="activityDateTime"
            [max]="todaysDate"
          />
          <owl-date-time #dt1></owl-date-time>

          <mat-error *ngIf="sleepLogForm.controls['activityDateTime'].invalid">
            Please select the activity date and time
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="col-md-12" style="text-align: right">
      <button
        color="primary"
        mat-raised-button
        type="button"
        (click)="updateSelectedTimeSlots()"
        [disabled]="isProcessing || !isAtleastOneSlotSelected()"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="isProcessing"></i>
      </button>
    </div>
  </form>
</div>
