import { NgModule } from '@angular/core';
import { AutoSlashDateDirective } from 'src/app/_directives/auto-slash-date.directive';
import { TextAreaAutoResizeDirective } from 'src/app/_directives/textarea-resize.directive';

@NgModule({
  imports: [],
  declarations: [AutoSlashDateDirective, TextAreaAutoResizeDirective],
  exports: [AutoSlashDateDirective, TextAreaAutoResizeDirective],
  providers: [],
})
export class CustomDirectivesModule {}
