<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>YP-CORE 10 Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="ypcore10AssessmentForm"
    novalidate
    (ngSubmit)="onYpCore10FormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >1. I've felt edgy or nervous</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="feltEdgyOrNervous">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="Only occasionally"
                >Only occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >2. I haven't felt like talking to anyone</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group
              formControlName="haventFeltLikeTalkingToAnyone"
            >
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="Only occasionally"
                >Only occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >3. I've felt able to cope when things go wrong</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group
              formControlName="feltAbleToCopeWhenThingsGoWrong"
            >
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="Only occasionally"
                >Only occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >4. I've thought of hurting myself</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="thoughtOfHurtingMyself">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="Only occasionally"
                >Only occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >5. There's been someone I felt able to ask for help</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group
              formControlName="someoneIFeltAbleToAskForHelp"
            >
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="Only occasionally"
                >Only occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >6. My thoughts and feelings distressed me</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group
              formControlName="thoughtsAndFeelingsDistressedMe"
            >
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="Only occasionally"
                >Only occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >7. My problems have felt too much for me</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group
              formControlName="problemsHaveFeltTooMuchForMe"
            >
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="Only occasionally"
                >Only occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >8. It's been hard to go to sleep or stay asleep</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group
              formControlName="beenHardToGoToSleepOrStayAsleep"
            >
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="Only occasionally"
                >Only occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-3 asterix-after">9. I've felt unhappy</label>
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="feltUnhappy">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="Only occasionally"
                >Only occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >10. I've done all the things I wanted to</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group
              formControlName="doneAllTheThingsIWantedTo"
            >
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="Only occasionally"
                >Only occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          ypcore10AssessmentForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!ypcore10AssessmentForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
