import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IPatientAssessmentGoal } from 'src/app/_models/assessments/patientassessmentgoal.model';
import { ToastMessageService } from 'src/app/_services';
import { AssessmentGoalHttpService } from 'src/app/_services/assessments/assessmentgoal.service';

@Component({
  selector: 'app-edit-assessment-goal',
  templateUrl: './edit-assessment-goal.component.html',
  styleUrls: ['./edit-assessment-goal.component.css'],
})
export class EditAssessmentGoalComponent implements OnInit {
  goal: any;
  reloadRequired: string = 'No';
  numbers = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];

  editProcessing: boolean = false;
  showFollowUp: boolean = false;
  todaysDate: Date = new Date();

  patientAssessmentGoal: IPatientAssessmentGoal;
  public patientAssessmentGoalForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditAssessmentGoalComponent>,
    private formBuilder: FormBuilder,
    private goalsApiService: AssessmentGoalHttpService,
    private toastMessageService: ToastMessageService
  ) {
    this.goal = data.goal;
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.patientAssessmentGoalForm = this.formBuilder.group({
      id: new FormControl(this.goal.id),
      organizationId: new FormControl(this.goal.organizationId),
      careProviderId: new FormControl(this.goal.careProviderId),
      goalIndex: new FormControl(this.goal.goalIndex),
      assessmentHistoryId: new FormControl(this.goal.assessmentHistoryId),
      assessmentRequestId: new FormControl(this.goal.assessmentRequestId),
      assessmentType: new FormControl(this.goal.assessmentType),
      patientId: new FormControl(this.goal.patientId),
      patientName: new FormControl(this.goal.patientName),
      requestorId: new FormControl(this.goal.requestorId),
      requestorName: new FormControl(this.goal.requestorName),
      description: new FormControl(
        { value: this.goal.description, disabled: true },
        Validators.required
      ),
      targetNumber: new FormControl(
        this.goal.targetNumber,
        Validators.required
      ),
      targetType: new FormControl(this.goal.targetType, Validators.required),
      followUp: new FormControl(this.goal.followUp, Validators.required),
      followUpNumber: new FormControl(this.goal.followUpNumber),
      followUpType: new FormControl(this.goal.followUpType),
      nextFollowUpDate: new FormControl(this.goal.nextFollowUpDate),
      LastScheduledFollowUp: new FormControl(this.goal.LastScheduledFollowUp),
      followUpDetails: new FormControl(this.goal.followUpDetails),

      targetCompletionDate: new FormControl(this.goal.targetCompletionDate),
      ActualCompletionDate: new FormControl(this.goal.ActualCompletionDate),

      patientNotes: new FormControl(this.goal.patientNotes),
      physicianNotes: new FormControl(this.goal.physicianNotes),

      status: new FormControl(this.goal.status),
    });
    this.changeFollowUp();
  }

  changeFollowUp() {
    if (this.patientAssessmentGoalForm.controls['followUp'].value === 'Yes') {
      this.showFollowUp = true;
    } else {
      this.showFollowUp = false;
      this.patientAssessmentGoalForm.controls[
        'followUpNumber'
      ].clearValidators();
      this.patientAssessmentGoalForm.controls['followUpNumber'].reset();
      this.patientAssessmentGoalForm.controls['followUpType'].clearValidators();
      this.patientAssessmentGoalForm.controls['followUpType'].reset();
    }
  }

  updateGoal() {
    this.editProcessing = true;

    this.patientAssessmentGoal = Object.assign({}, this.patientAssessmentGoal);
    this.patientAssessmentGoal = Object.assign(
      this.patientAssessmentGoal,
      this.patientAssessmentGoalForm.value
    );

    this.goalsApiService
      .updatePatientAssessmentGoal(this.patientAssessmentGoal)
      .subscribe(
        (response) => {
          this.editProcessing = false;
          this.toastMessageService.displaySuccessMessage(
            'Goal ' + this.goal.goalIndex + ' has been updated'
          );
          this.dialogRef.close('reload');
        },
        () => {
          this.editProcessing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to update the goal'
          );
        }
      );
  }
}
