<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Buss Perry Aggression Questionnaire (BPAQ)</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-1"></div>
    <div class="col-sm-10">
      <button class="btn btn-primary" mat-raised-button>
        Physical Aggression: {{ paScore }}
      </button>
      <button class="btn btn-primary" mat-raised-button>
        Verbal Aggression: {{ vaScore }}
      </button>
      <button class="btn btn-primary" mat-raised-button>
        Hostility: {{ hScore }}
      </button>
      <button class="btn btn-primary" mat-raised-button>
        Anger: {{ aScore }}
      </button>
    </div>
    <div class="col-sm-1"></div>
  </div>

  <form
    [formGroup]="aggressionQuestionnaireForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >1. Some of my friends think I am a hothead.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q1"
        >
          <mat-button-toggle value="Extremely Uncharacteristic"
            >Extremely Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Uncharacteristic"
            >Somewhat Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Neither Uncharacteristic Nor Characteristic"
            >Neither Uncharacteristic Nor Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Characteristic"
            >Somewhat Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Extremely Characteristic"
            >Extremely Characteristic</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >2. If I have to resort to violence to protect my rights, I will.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q2"
        >
          <mat-button-toggle value="Extremely Uncharacteristic"
            >Extremely Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Uncharacteristic"
            >Somewhat Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Neither Uncharacteristic Nor Characteristic"
            >Neither Uncharacteristic Nor Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Characteristic"
            >Somewhat Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Extremely Characteristic"
            >Extremely Characteristic</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >3. When people are especially nice to me, I wonder what they
        want.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q3"
        >
          <mat-button-toggle value="Extremely Uncharacteristic"
            >Extremely Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Uncharacteristic"
            >Somewhat Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Neither Uncharacteristic Nor Characteristic"
            >Neither Uncharacteristic Nor Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Characteristic"
            >Somewhat Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Extremely Characteristic"
            >Extremely Characteristic</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >4. I tell my friends openly when I disagree with them.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q4"
        >
          <mat-button-toggle value="Extremely Uncharacteristic"
            >Extremely Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Uncharacteristic"
            >Somewhat Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Neither Uncharacteristic Nor Characteristic"
            >Neither Uncharacteristic Nor Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Characteristic"
            >Somewhat Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Extremely Characteristic"
            >Extremely Characteristic</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >5. I have become so mad that I have broken things.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q5"
        >
          <mat-button-toggle value="Extremely Uncharacteristic"
            >Extremely Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Uncharacteristic"
            >Somewhat Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Neither Uncharacteristic Nor Characteristic"
            >Neither Uncharacteristic Nor Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Characteristic"
            >Somewhat Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Extremely Characteristic"
            >Extremely Characteristic</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >6. I can’t help getting into arguments when people disagree with
        me.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q6"
        >
          <mat-button-toggle value="Extremely Uncharacteristic"
            >Extremely Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Uncharacteristic"
            >Somewhat Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Neither Uncharacteristic Nor Characteristic"
            >Neither Uncharacteristic Nor Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Characteristic"
            >Somewhat Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Extremely Characteristic"
            >Extremely Characteristic</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >7. I wonder why sometimes I feel so bitter about things.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q7"
        >
          <mat-button-toggle value="Extremely Uncharacteristic"
            >Extremely Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Uncharacteristic"
            >Somewhat Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Neither Uncharacteristic Nor Characteristic"
            >Neither Uncharacteristic Nor Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Characteristic"
            >Somewhat Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Extremely Characteristic"
            >Extremely Characteristic</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >8. Once in a while, I can’t control the urge to strike another
        person.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q8"
        >
          <mat-button-toggle value="Extremely Uncharacteristic"
            >Extremely Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Uncharacteristic"
            >Somewhat Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Neither Uncharacteristic Nor Characteristic"
            >Neither Uncharacteristic Nor Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Characteristic"
            >Somewhat Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Extremely Characteristic"
            >Extremely Characteristic</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >9. I am an even-tempered person.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q9"
        >
          <mat-button-toggle value="Extremely Uncharacteristic"
            >Extremely Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Uncharacteristic"
            >Somewhat Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Neither Uncharacteristic Nor Characteristic"
            >Neither Uncharacteristic Nor Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Characteristic"
            >Somewhat Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Extremely Characteristic"
            >Extremely Characteristic</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >10. I am suspicious of overly friendly strangers.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q10"
        >
          <mat-button-toggle value="Extremely Uncharacteristic"
            >Extremely Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Uncharacteristic"
            >Somewhat Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Neither Uncharacteristic Nor Characteristic"
            >Neither Uncharacteristic Nor Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Characteristic"
            >Somewhat Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Extremely Characteristic"
            >Extremely Characteristic</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >11. I have threatened people I know.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q11"
        >
          <mat-button-toggle value="Extremely Uncharacteristic"
            >Extremely Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Uncharacteristic"
            >Somewhat Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Neither Uncharacteristic Nor Characteristic"
            >Neither Uncharacteristic Nor Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Characteristic"
            >Somewhat Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Extremely Characteristic"
            >Extremely Characteristic</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >12. I flare up quickly but get over it quickly.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q12"
        >
          <mat-button-toggle value="Extremely Uncharacteristic"
            >Extremely Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Uncharacteristic"
            >Somewhat Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Neither Uncharacteristic Nor Characteristic"
            >Neither Uncharacteristic Nor Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Characteristic"
            >Somewhat Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Extremely Characteristic"
            >Extremely Characteristic</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >13. Given enough provocation, I may hit another person.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q13"
        >
          <mat-button-toggle value="Extremely Uncharacteristic"
            >Extremely Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Uncharacteristic"
            >Somewhat Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Neither Uncharacteristic Nor Characteristic"
            >Neither Uncharacteristic Nor Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Characteristic"
            >Somewhat Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Extremely Characteristic"
            >Extremely Characteristic</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >14. When people annoy me, I may tell them what I think of them.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q14"
        >
          <mat-button-toggle value="Extremely Uncharacteristic"
            >Extremely Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Uncharacteristic"
            >Somewhat Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Neither Uncharacteristic Nor Characteristic"
            >Neither Uncharacteristic Nor Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Characteristic"
            >Somewhat Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Extremely Characteristic"
            >Extremely Characteristic</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >15. I am sometimes eaten up with jealousy.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q15"
        >
          <mat-button-toggle value="Extremely Uncharacteristic"
            >Extremely Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Uncharacteristic"
            >Somewhat Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Neither Uncharacteristic Nor Characteristic"
            >Neither Uncharacteristic Nor Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Characteristic"
            >Somewhat Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Extremely Characteristic"
            >Extremely Characteristic</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >16. I can think of no good reason for ever hitting a person.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q16"
        >
          <mat-button-toggle value="Extremely Uncharacteristic"
            >Extremely Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Uncharacteristic"
            >Somewhat Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Neither Uncharacteristic Nor Characteristic"
            >Neither Uncharacteristic Nor Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Characteristic"
            >Somewhat Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Extremely Characteristic"
            >Extremely Characteristic</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >17. At times I feel I have gotten a raw deal out of life.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q17"
        >
          <mat-button-toggle value="Extremely Uncharacteristic"
            >Extremely Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Uncharacteristic"
            >Somewhat Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Neither Uncharacteristic Nor Characteristic"
            >Neither Uncharacteristic Nor Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Characteristic"
            >Somewhat Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Extremely Characteristic"
            >Extremely Characteristic</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >18. I have trouble controlling my temper.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q18"
        >
          <mat-button-toggle value="Extremely Uncharacteristic"
            >Extremely Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Uncharacteristic"
            >Somewhat Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Neither Uncharacteristic Nor Characteristic"
            >Neither Uncharacteristic Nor Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Characteristic"
            >Somewhat Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Extremely Characteristic"
            >Extremely Characteristic</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >19. When frustrated, I let my irritation show.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q19"
        >
          <mat-button-toggle value="Extremely Uncharacteristic"
            >Extremely Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Uncharacteristic"
            >Somewhat Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Neither Uncharacteristic Nor Characteristic"
            >Neither Uncharacteristic Nor Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Characteristic"
            >Somewhat Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Extremely Characteristic"
            >Extremely Characteristic</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >20. I sometimes feel that people are laughing at me behind my
        back.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q20"
        >
          <mat-button-toggle value="Extremely Uncharacteristic"
            >Extremely Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Uncharacteristic"
            >Somewhat Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Neither Uncharacteristic Nor Characteristic"
            >Neither Uncharacteristic Nor Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Characteristic"
            >Somewhat Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Extremely Characteristic"
            >Extremely Characteristic</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >21. I often find myself disagreeing with people.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q21"
        >
          <mat-button-toggle value="Extremely Uncharacteristic"
            >Extremely Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Uncharacteristic"
            >Somewhat Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Neither Uncharacteristic Nor Characteristic"
            >Neither Uncharacteristic Nor Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Characteristic"
            >Somewhat Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Extremely Characteristic"
            >Extremely Characteristic</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >22. If somebody hits me, I hit back.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q22"
        >
          <mat-button-toggle value="Extremely Uncharacteristic"
            >Extremely Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Uncharacteristic"
            >Somewhat Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Neither Uncharacteristic Nor Characteristic"
            >Neither Uncharacteristic Nor Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Characteristic"
            >Somewhat Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Extremely Characteristic"
            >Extremely Characteristic</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >23. I sometimes feel like a powder keg ready to explode.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q23"
        >
          <mat-button-toggle value="Extremely Uncharacteristic"
            >Extremely Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Uncharacteristic"
            >Somewhat Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Neither Uncharacteristic Nor Characteristic"
            >Neither Uncharacteristic Nor Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Characteristic"
            >Somewhat Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Extremely Characteristic"
            >Extremely Characteristic</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >24. Other people always seem to get the breaks.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q24"
        >
          <mat-button-toggle value="Extremely Uncharacteristic"
            >Extremely Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Uncharacteristic"
            >Somewhat Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Neither Uncharacteristic Nor Characteristic"
            >Neither Uncharacteristic Nor Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Characteristic"
            >Somewhat Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Extremely Characteristic"
            >Extremely Characteristic</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >25. There are people who pushed me so far that we came to blows.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q25"
        >
          <mat-button-toggle value="Extremely Uncharacteristic"
            >Extremely Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Uncharacteristic"
            >Somewhat Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Neither Uncharacteristic Nor Characteristic"
            >Neither Uncharacteristic Nor Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Characteristic"
            >Somewhat Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Extremely Characteristic"
            >Extremely Characteristic</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >26. I know that "friends" talk about me behind my back.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q26"
        >
          <mat-button-toggle value="Extremely Uncharacteristic"
            >Extremely Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Uncharacteristic"
            >Somewhat Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Neither Uncharacteristic Nor Characteristic"
            >Neither Uncharacteristic Nor Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Characteristic"
            >Somewhat Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Extremely Characteristic"
            >Extremely Characteristic</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >27. My friends say that I’m somewhat argumentative.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q27"
        >
          <mat-button-toggle value="Extremely Uncharacteristic"
            >Extremely Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Uncharacteristic"
            >Somewhat Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Neither Uncharacteristic Nor Characteristic"
            >Neither Uncharacteristic Nor Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Characteristic"
            >Somewhat Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Extremely Characteristic"
            >Extremely Characteristic</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >28. Sometimes I fly off the handle for no good reason.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q28"
        >
          <mat-button-toggle value="Extremely Uncharacteristic"
            >Extremely Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Uncharacteristic"
            >Somewhat Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Neither Uncharacteristic Nor Characteristic"
            >Neither Uncharacteristic Nor Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Characteristic"
            >Somewhat Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Extremely Characteristic"
            >Extremely Characteristic</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >29. I get into fights a little more than the average person.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q29"
        >
          <mat-button-toggle value="Extremely Uncharacteristic"
            >Extremely Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Uncharacteristic"
            >Somewhat Uncharacteristic</mat-button-toggle
          >
          <mat-button-toggle value="Neither Uncharacteristic Nor Characteristic"
            >Neither Uncharacteristic Nor Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Somewhat Characteristic"
            >Somewhat Characteristic</mat-button-toggle
          >
          <mat-button-toggle value="Extremely Characteristic"
            >Extremely Characteristic</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>

    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          aggressionQuestionnaireForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!aggressionQuestionnaireForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
