import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { MbscModule } from '@mobiscroll/angular';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgChartsModule } from 'ng2-charts';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { NgxMaskDirective, provideEnvironmentNgxMask } from 'ngx-mask';
import { NgProgressModule } from 'ngx-progressbar';
import { CustomDirectivesModule } from 'src/app/_directives/custom-directives.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { AngularMaterialModule } from '../../../material-imports';
import { PatientDetailModule } from '../../patient-details/patient-details.module';
import { AddMoodLogDialogComponent } from './hourly-mood-assessment/add-mood-log-dialog/add-mood-log-dialog.component';
import { HourlyMoodAssessmentComponent } from './hourly-mood-assessment/hourly-mood-assessment.component';
import { MemberDashboardVirtualAppointmentsComponent } from './member-dashboard-virtual-appointments/member-dashboard-virtual-appointments.component';
import { ConsentDocumentEsignComponent } from './member-intake-documents/to-do-list/consent-document-esign/consent-document-esign.component';
import { MemberToDoListUpdateInterfaceComponent } from './member-intake-documents/to-do-list/member-to-do-list-update-interface/member-to-do-list-update-interface.component';
import { MemberTodoListComponent } from './member-intake-documents/to-do-list/member-todo-list.component';
import { PatientDashboardHomepageComponent } from './patient-dashboard-homepage/patient-dashboard-homepage.component';
import { PatientDashboardComponent } from './patient-dashboard.component';
import { PatientDetailRoutes } from './patient-dashboard.routing';
import { PatientScreenAssessmentsComponent } from './patient-screen-assessments/patient-screen-assessments.component';
import { PatientScreenGoalsComponent } from './patient-screen-goals/patient-screen-goals.component';
import { PatientScreenSessionsComponent } from './patient-screen-sessions/patient-screen-sessions.component';
import { PatientSelfInformationComponent } from './patient-self-information/patient-self-information.component';

@NgModule({
  imports: [
    MbscModule,
    CommonModule,
    RouterModule.forChild(PatientDetailRoutes),
    FormsModule,
    PatientDetailModule,
    AngularMaterialModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    NgChartsModule,
    PdfJsViewerModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300,
    }),
    NgProgressModule,
    NgxMaskDirective,
    RxReactiveFormsModule,
    PipesModule,
    CustomDirectivesModule,
  ],
  declarations: [
    PatientDashboardComponent,
    PatientScreenGoalsComponent,
    PatientScreenSessionsComponent,
    HourlyMoodAssessmentComponent,
    PatientScreenAssessmentsComponent,
    AddMoodLogDialogComponent,
    ConsentDocumentEsignComponent,
    MemberTodoListComponent,
    PatientSelfInformationComponent,
    MemberDashboardVirtualAppointmentsComponent,
    PatientDashboardHomepageComponent,
    MemberToDoListUpdateInterfaceComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [provideEnvironmentNgxMask()],
})
export class PatientDashboardModule {}
