import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ITherapySession, Patient } from 'src/app/_models';
import { TherapySessionService } from 'src/app/_services';

@Component({
  selector: 'app-group-member-medications',
  templateUrl: './group-member-medications.component.html',
  styleUrl: './group-member-medications.component.css',
})
export class GroupMemberMedicationsComponent implements OnInit {
  sessionDetails: ITherapySession;
  selectedMemberId: string;
  hasMedicationAddAccess: boolean;
  hasMedicationEditAccess: boolean;
  hasMedicationDeleteAccess: boolean;
  patient: any;

  constructor(
    public dialogRef: MatDialogRef<GroupMemberMedicationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public therapysessionApiService: TherapySessionService
  ) {
    this.sessionDetails = data.sessionDetails;
    this.selectedMemberId = data.selectedMemberId;
    this.patient = data.patient;
    this.hasMedicationAddAccess = data.hasMedicationAddAccess;
    this.hasMedicationEditAccess = data.hasMedicationEditAccess;
    this.hasMedicationDeleteAccess = data.hasMedicationDeleteAccess;
  }

  ngOnInit(): void {}
}
