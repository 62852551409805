<mat-card
  class="mat-elevation-z8"
  appearance="raised"
  *ngIf="hasCaseNotesViewAccess"
  style="margin-bottom: 20px"
>
  <div
    class="row"
    style="margin-bottom: 15px"
    *ngIf="
      hasCaseNotesAddAccess &&
      patient.status === 'Active' &&
      case.status === 'active'
    "
  >
    <div class="col-md-12" style="text-align: right">
      <button
        type="button"
        mat-raised-button
        color="warn"
        (click)="addCaseNote()"
      >
        + Add Case Notes
      </button>
    </div>
  </div>

  <div *ngIf="data.total === 0">
    <mat-error> No case note found </mat-error>
  </div>

  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoadingResults"
  ></mat-progress-bar>

  <div
    *ngIf="data.total > 0"
    style="display: flex; flex-direction: column; row-gap: 20px; margin: 20px"
  >
    <div class="noteContainer mat-elevation-z8" *ngFor="let item of data.items">
      <!-- Note  -->
      <label class="labelHeader" style="text-decoration: underline">
        Note
      </label>
      <br />

      <label class="labelText">{{ item.text }} </label>

      <!-- Footer  -->
      <div class="createdByFooter">
        <div>
          <i class="material-icons" style="vertical-align: bottom"> person </i>
          <span
            class="mat-small"
            style="font-size: 10px; word-break: break-word"
          >
            Created by: {{ item.createdBy }}
          </span>
        </div>

        <div>
          <i class="material-icons" style="vertical-align: bottom"> event </i>
          <span *ngIf="!isIndianOrg" class="mat-small" style="font-size: 10px">
            {{ item.createdDate | date : 'MM/dd/YYYY' }}
          </span>
          <span *ngIf="isIndianOrg" class="mat-small" style="font-size: 10px">
            {{ item.createdDate | date : 'dd/MM/YYYY' }}
          </span>
        </div>
      </div>

      <!-- Action  -->
      <div
        class="caseNoteAction"
        *ngIf="hasCaseNotesEditAccess && patient.status === 'Active'"
      >
        <div
          class="material-icons"
          style="color: gray; font-size: 17px; cursor: pointer; z-index: 2"
          (click)="editCaseNote(item)"
          type="button"
        >
          edit
        </div>
      </div>
    </div>

    <mat-paginator
      #paginator
      [length]="data.total"
      [pageSize]="data.per_page"
      *ngIf="data.total > data.per_page"
      [showFirstLastButtons]="true"
      (page)="getNextMemberNotes($event)"
    >
    </mat-paginator>
  </div>
</mat-card>

<mat-card appearance="raised" *ngIf="!hasCaseNotesViewAccess">
  <mat-card-header>
    <mat-card-title>
      <mat-error> Sorry, You don't have access to view Case notes </mat-error>
    </mat-card-title>
  </mat-card-header>
</mat-card>
