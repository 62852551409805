import {
  NgxGpAutocompleteDirective,
  NgxGpAutocompleteOptions,
} from '@angular-magic/ngx-gp-autocomplete';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Countries, ITherapySession, Patient } from 'src/app/_models';
import { IntakeMemberData } from 'src/app/_models/session/intake/intakememberdata.model';
import { ToastMessageService } from 'src/app/_services/';
import { IntakeMemberInfoApiService } from 'src/app/_services/session/intake/intakememberdata.service';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { Gender } from 'src/app/shared/utilities/billing/gender';
import { USStates } from 'src/app/shared/utilities/billing/states';
import {
  buildValidationMsgs,
  formatDate,
  mmddyyyyToDate,
} from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-intake-member-data',
  templateUrl: './intake-member-data.component.html',
  styleUrls: ['./intake-member-data.component.css'],
})
export class IntakeMemberDataComponent implements OnInit {
  @ViewChild('ngxPlaces') placesRef: NgxGpAutocompleteDirective;

  @Input() patient: Patient;
  @Input() hasBPSAIntakeViewAccess: boolean;
  @Input() hasBPSAIntakeAddAccess: boolean;
  @Input() hasBPSAIntakeEditAccess: boolean;
  @Input() therapySession: ITherapySession;
  @Output() updatedTherapySession = new EventEmitter<any>();
  @Output() reloadPatientProfile = new EventEmitter<any>();

  options: NgxGpAutocompleteOptions = {
    componentRestrictions: { country: ['US'] },
    types: ['address'],
  };
  showSSN: boolean = false;

  constructor(
    public dialog: MatDialog,
    public intakeMemberInfoApiService: IntakeMemberInfoApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private toastMessageService: ToastMessageService,
    private _dateAdapter: DateAdapter<any>,
    private orgConfigSandbox: OrgConfigSandbox
  ) {}

  intakeMemberData: IntakeMemberData;
  maxDate: Date;
  public intakeMemberDataForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  accountError: Error = null;
  errors: any = {};
  isIndianOrg: boolean = false;
  orgConfig: any;
  processing: boolean = false;
  origPatientStatus: string = '';
  usStates = USStates;
  genderList = Gender;

  public ngOnInit() {
    this.intakeMemberData = this.therapySession.intakeMemberData;
    this.maxDate = new Date();

    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          this.orgConfig = orgConfig;
          if (this.orgConfig?.organizationCountry === Countries.India) {
            this.isIndianOrg = true;
            this._dateAdapter.setLocale('en-GB');
          }
        });
      }
    });

    this.buildForm();
    this.errorHandler.handleErrors(this.intakeMemberDataForm, this.errors);

    if (this.intakeMemberData) {
      this.intakeMemberDataForm.controls['firstName'].setValue(
        this.intakeMemberData.firstName
      );
      this.intakeMemberDataForm.controls['middleName'].setValue(
        this.intakeMemberData.middleName
      );
      this.intakeMemberDataForm.controls['lastName'].setValue(
        this.intakeMemberData.lastName
      );
      this.intakeMemberDataForm.controls['pronouns'].setValue(
        this.intakeMemberData.pronouns
      );
      this.intakeMemberDataForm.controls['admittanceDate'].setValue(
        mmddyyyyToDate(this.intakeMemberData.admittanceDate)
      );
      if (
        this.intakeMemberData.dateOfBirth &&
        this.intakeMemberData.dateOfBirth !== ''
      ) {
        this.intakeMemberDataForm.controls['dateOfBirth'].setValue(
          mmddyyyyToDate(this.intakeMemberData.dateOfBirth)
        );
      }
      this.intakeMemberDataForm.controls['gender'].setValue(
        this.intakeMemberData.gender
      );
      this.intakeMemberDataForm.controls['email'].setValue(
        this.intakeMemberData.email
      );
      this.intakeMemberDataForm.controls['phonePreference'].setValue(
        this.intakeMemberData.phonePreference
      );
      this.intakeMemberDataForm.controls['phoneNumber'].setValue(
        this.intakeMemberData.phoneNumber
      );
      this.intakeMemberDataForm.controls['homePhoneNumber'].setValue(
        this.intakeMemberData.homePhoneNumber
      );
      this.intakeMemberDataForm.controls['workPhoneNumber'].setValue(
        this.intakeMemberData.workPhoneNumber
      );
      this.intakeMemberDataForm.controls['addressLine1'].setValue(
        this.intakeMemberData.addressLine1
      );
      this.intakeMemberDataForm.controls['addressLine2'].setValue(
        this.intakeMemberData.addressLine2
      );
      this.intakeMemberDataForm.controls['city'].setValue(
        this.intakeMemberData.city
      );
      this.intakeMemberDataForm.controls['state'].setValue(
        this.intakeMemberData.state
      );
      this.intakeMemberDataForm.controls['zip'].setValue(
        this.intakeMemberData.zip
      );

      if (this.intakeMemberData.height) {
        this.intakeMemberDataForm.controls['height'].setValue(
          this.intakeMemberData.height
        );
      }
      if (this.intakeMemberData.weight) {
        this.intakeMemberDataForm.controls['weight'].setValue(
          this.intakeMemberData.weight
        );
      }
    }
  }

  onPatientFormSubmit() {
    if (this.intakeMemberDataForm.invalid) {
      this.intakeMemberDataForm.markAllAsTouched();
      return;
    }

    this.intakeMemberData = Object.assign({}, this.intakeMemberData);
    this.intakeMemberData = Object.assign(
      this.intakeMemberData,
      this.intakeMemberDataForm.value
    );

    if (
      this.intakeMemberData.dateOfBirth &&
      this.intakeMemberData.dateOfBirth !== ''
    ) {
      this.intakeMemberData.dateOfBirth = formatDate(
        new Date(this.intakeMemberData.dateOfBirth)
      );
    }

    if (
      this.intakeMemberData.admittanceDate &&
      this.intakeMemberData.admittanceDate !== ''
    ) {
      this.intakeMemberData.admittanceDate = formatDate(
        new Date(this.intakeMemberData.admittanceDate)
      );
    }

    this.processing = true;
    this.intakeMemberInfoApiService
      .updateMemberInfo(this.intakeMemberData)
      .subscribe(
        (response) => {
          this.processing = false;
          this.toastMessageService.displaySuccessMessage(
            'Successfully updated member details'
          );
          // Update therpay session
          this.updatedTherapySession.emit(response);
          // Update patient profile
          this.reloadPatientProfile.emit(true);
        },
        (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Something went wrong. Please try again.'
          );
        }
      );
  }

  private buildForm() {
    this.intakeMemberDataForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl(null),
      sessionId: new FormControl(
        this.therapySession.id,
        Validators.compose([Validators.required])
      ),
      patientId: new FormControl(
        this.therapySession.patientId,
        Validators.compose([Validators.required])
      ),
      firstName: new FormControl(
        {
          value: this.patient.firstName,
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
        },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      middleName: new FormControl({
        value: this.patient.middleName,
        disabled:
          this.therapySession.status != 'Active' ||
          (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
      }),
      lastName: new FormControl(
        {
          value: this.patient.lastName,
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
        },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      pronouns: new FormControl({
        value: this.patient.pronouns,
        disabled:
          this.therapySession.status != 'Active' ||
          (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
      }),
      dateOfBirth: new FormControl(
        {
          value: mmddyyyyToDate(this.patient.dateOfBirth),
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
        },
        Validators.compose([])
      ),
      admittanceDate: new FormControl(
        {
          value: mmddyyyyToDate(this.patient.admittanceDate),
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
        },
        Validators.compose([])
      ),
      gender: new FormControl(
        {
          value: this.patient.gender,
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      email: new FormControl(
        {
          value: this.patient.email,
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
        },
        Validators.email
      ),
      phonePreference: new FormControl(
        {
          value: this.patient.phonePreference,
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
        },
        Validators.required
      ),
      phoneNumber: new FormControl(
        {
          value: this.patient.phoneNumber,
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
        },
        Validators.compose([
          // PhoneValidator.validCountryPhone(country),
          this.isIndianOrg
            ? RxwebValidators.mask({ mask: '99999-99999' })
            : RxwebValidators.mask({ mask: '(999) 999-9999' }),
        ])
      ),
      homePhoneNumber: new FormControl({
        value: this.patient.homePhoneNumber,
        disabled:
          this.therapySession.status != 'Active' ||
          (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
      }),
      workPhoneNumber: new FormControl({
        value: this.patient.workPhoneNumber,
        disabled:
          this.therapySession.status != 'Active' ||
          (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
      }),
      addressLine1: new FormControl(
        {
          value: this.patient.addressLine1,
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
        },
        Validators.compose([])
      ),
      addressLine2: new FormControl(
        {
          value: this.patient.addressLine2,
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
        },
        Validators.compose([])
      ),
      city: new FormControl(
        {
          value: this.patient.city,
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
        },
        Validators.compose([])
      ),
      state: new FormControl(
        {
          value: this.patient.state,
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
        },
        Validators.compose([])
      ),

      ssn: new FormControl(
        {
          value: this.patient.ssn,
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
        },
        Validators.compose([RxwebValidators.mask({ mask: '999-99-9999' })])
      ),

      genderAtBirth: new FormControl({
        value: this.patient.genderAtBirth,
        disabled:
          this.therapySession.status != 'Active' ||
          (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
      }),
      zip: new FormControl(
        {
          value: this.patient.zip,
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      height: new FormControl({
        value: this.patient.height ? this.patient.height : '',
        disabled:
          this.therapySession.status != 'Active' ||
          (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
      }),
      weight: new FormControl({
        value: this.patient.weight ? this.patient.weight : '',
        disabled:
          this.therapySession.status != 'Active' ||
          (!this.hasBPSAIntakeAddAccess && !this.hasBPSAIntakeEditAccess),
      }),
    });

    this.intakeMemberDataForm.valueChanges.subscribe((response) =>
      this.onValueChanged()
    );
  }

  public validation_messages = {
    id: [{ type: 'required', message: 'id is required' }],
    firstName: [
      { type: 'required', message: 'First Name is required' },
      {
        type: 'minlength',
        message: 'First Name cannot be less than 1 characters long',
      },
    ],
    lastName: [
      { type: 'required', message: 'Last Name is required' },
      {
        type: 'minlength',
        message: 'Last Name cannot be less than 1 characters long',
      },
    ],
    dateOfBirth: [{ type: 'required', message: 'Date of Birth is required' }],
    gender: [{ type: 'required', message: 'Gender is required' }],
    phoneNumber: [
      {
        type: 'pattern',
        message: 'Please enter a valid 10 digit Phone Number',
      },
    ],
    memberNumber: [
      { type: 'required', message: 'Member Number is required' },
      { type: 'duplicate', message: 'Member Number already exists' },
    ],
    mco: [{ type: 'required', message: 'Provider Name is required' }],
    status: [{ type: 'required', message: 'Status is required' }],
    deceased: [{ type: 'required', message: 'Deceased is required' }],
    deceasedDate: [{ type: 'required', message: 'Deceased Date is required' }],
    preferredLanguage: [
      { type: 'required', message: 'Preferred Language is required' },
    ],
  };

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.intakeMemberDataForm) {
      return;
    }
    const form = this.intakeMemberDataForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }

  phonePreferenceChanged(event) {
    if (event.value === 'Home Phone') {
      this.intakeMemberDataForm
        .get('homePhoneNumber')
        .addValidators(Validators.required);
      this.intakeMemberDataForm.get('homePhoneNumber').updateValueAndValidity();

      this.intakeMemberDataForm.get('workPhoneNumber').clearValidators();
      this.intakeMemberDataForm.get('workPhoneNumber').updateValueAndValidity();
    } else if (event.value === 'Work Phone') {
      this.intakeMemberDataForm
        .get('workPhoneNumber')
        .addValidators(Validators.required);
      this.intakeMemberDataForm.get('workPhoneNumber').updateValueAndValidity();

      this.intakeMemberDataForm.get('homePhoneNumber').clearValidators();
      this.intakeMemberDataForm.get('homePhoneNumber').updateValueAndValidity();
    } else {
      this.intakeMemberDataForm.get('workPhoneNumber').clearValidators();
      this.intakeMemberDataForm.get('workPhoneNumber').updateValueAndValidity();

      this.intakeMemberDataForm.get('homePhoneNumber').clearValidators();
      this.intakeMemberDataForm.get('homePhoneNumber').updateValueAndValidity();
    }
  }

  handleAddressChange(address: google.maps.places.PlaceResult) {
    let addressField: string = '';

    for (const component of address.address_components as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number': {
          addressField = `${component.long_name} ${addressField}`;
          break;
        }

        case 'route': {
          addressField += component.long_name;
          break;
        }

        case 'locality':
          //city
          this.intakeMemberDataForm.controls['city'].setValue(
            component.long_name
          );
          break;

        case 'administrative_area_level_1': {
          //state
          this.intakeMemberDataForm.controls['state'].setValue(
            component.short_name
          );
          break;
        }

        case 'postal_code': {
          this.intakeMemberDataForm.controls['zip'].setValue(
            component.long_name
          );
          break;
        }
      }
    }
    if (addressField.trim() == '')
      addressField = address.formatted_address.split(',')[0];
    this.intakeMemberDataForm.controls['addressLine1'].setValue(addressField);
  }

  genderChanged(event) {
    if (event.value === 'O') {
      this.intakeMemberDataForm
        .get('genderAtBirth')
        .addValidators(Validators.required);
      this.intakeMemberDataForm.get('genderAtBirth').updateValueAndValidity();
    } else {
      this.intakeMemberDataForm.get('genderAtBirth').clearValidators();
      this.intakeMemberDataForm.get('genderAtBirth').updateValueAndValidity();
    }
  }
}
