import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IBpsaEduVocationalHistory } from 'src/app/_models/session/clinical/bpsa-clinical-edu-vocational-history.model';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class BpsaEduVocationalHistoryApiService {
  constructor(private httpClient: HttpClient) {}

  updateBpsaEduVocationalHistory(
    thisBpsaEduVocationalHistory: IBpsaEduVocationalHistory
  ): Observable<IBpsaEduVocationalHistory> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<IBpsaEduVocationalHistory>(
        `${environment.apiUrl}/bpsaeduvocationalhistory`,
        thisBpsaEduVocationalHistory,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
