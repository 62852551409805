import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { IEatingAttitudes } from 'src/app/_models/assessments/eating-disorder/eatingattitudes.model';
import {
  EatingAttitudesApiService,
  ToastMessageService,
} from 'src/app/_services/';

@Component({
  selector: 'app-eating-attitudes',
  templateUrl: './eating-attitudes.component.html',
  styleUrls: ['./eating-attitudes.component.css'],
})
export class EatingAttitudesComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public eatingAttitudesApiService: EatingAttitudesApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) {}
  public riskScore: number;
  public eatingAttitudesForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  eatingAttitudesAssessment: IEatingAttitudes;
  eatingAttitudesAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.eatingAttitudesForm, this.errors);

    this.loadEatingAttitudesAssessment();
  }

  loadEatingAttitudesAssessment() {
    this.eatingAttitudesForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.eatingAttitudesApiService
        .getEatingAttitudesDetails(this.assessmentId)
        .subscribe((eatingAttitudesAssessment) => {
          if (eatingAttitudesAssessment.data) {
            this.eatingAttitudesForm.controls['q1'].setValue(
              eatingAttitudesAssessment.data.q1.answer
            );
            this.eatingAttitudesForm.controls['q2'].setValue(
              eatingAttitudesAssessment.data.q2.answer
            );
            this.eatingAttitudesForm.controls['q3'].setValue(
              eatingAttitudesAssessment.data.q3.answer
            );
            this.eatingAttitudesForm.controls['q4'].setValue(
              eatingAttitudesAssessment.data.q4.answer
            );
            this.eatingAttitudesForm.controls['q5'].setValue(
              eatingAttitudesAssessment.data.q5.answer
            );
            this.eatingAttitudesForm.controls['q6'].setValue(
              eatingAttitudesAssessment.data.q6.answer
            );
            this.eatingAttitudesForm.controls['q7'].setValue(
              eatingAttitudesAssessment.data.q7.answer
            );
            this.eatingAttitudesForm.controls['q8'].setValue(
              eatingAttitudesAssessment.data.q8.answer
            );
            this.eatingAttitudesForm.controls['q9'].setValue(
              eatingAttitudesAssessment.data.q9.answer
            );
            this.eatingAttitudesForm.controls['q10'].setValue(
              eatingAttitudesAssessment.data.q10.answer
            );
            this.eatingAttitudesForm.controls['q11'].setValue(
              eatingAttitudesAssessment.data.q11.answer
            );
            this.eatingAttitudesForm.controls['q12'].setValue(
              eatingAttitudesAssessment.data.q12.answer
            );
            this.eatingAttitudesForm.controls['q13'].setValue(
              eatingAttitudesAssessment.data.q13.answer
            );
            this.eatingAttitudesForm.controls['q14'].setValue(
              eatingAttitudesAssessment.data.q14.answer
            );
            this.eatingAttitudesForm.controls['q15'].setValue(
              eatingAttitudesAssessment.data.q15.answer
            );
            this.eatingAttitudesForm.controls['q16'].setValue(
              eatingAttitudesAssessment.data.q16.answer
            );
            this.eatingAttitudesForm.controls['q17'].setValue(
              eatingAttitudesAssessment.data.q17.answer
            );
            this.eatingAttitudesForm.controls['q18'].setValue(
              eatingAttitudesAssessment.data.q18.answer
            );
            this.eatingAttitudesForm.controls['q19'].setValue(
              eatingAttitudesAssessment.data.q19.answer
            );
            this.eatingAttitudesForm.controls['q20'].setValue(
              eatingAttitudesAssessment.data.q20.answer
            );
            this.eatingAttitudesForm.controls['q21'].setValue(
              eatingAttitudesAssessment.data.q21.answer
            );
            this.eatingAttitudesForm.controls['q22'].setValue(
              eatingAttitudesAssessment.data.q22.answer
            );
            this.eatingAttitudesForm.controls['q23'].setValue(
              eatingAttitudesAssessment.data.q23.answer
            );
            this.eatingAttitudesForm.controls['q24'].setValue(
              eatingAttitudesAssessment.data.q24.answer
            );
            this.eatingAttitudesForm.controls['q25'].setValue(
              eatingAttitudesAssessment.data.q25.answer
            );
            this.eatingAttitudesForm.controls['q26'].setValue(
              eatingAttitudesAssessment.data.q26.answer
            );
            this.eatingAttitudesForm.controls['q27'].setValue(
              eatingAttitudesAssessment.data.q27.answer
            );
            this.eatingAttitudesForm.controls['q28'].setValue(
              eatingAttitudesAssessment.data.q28.answer
            );
            this.eatingAttitudesForm.controls['q29'].setValue(
              eatingAttitudesAssessment.data.q29.answer
            );
            this.eatingAttitudesForm.controls['q30'].setValue(
              eatingAttitudesAssessment.data.q30.answer
            );
            this.eatingAttitudesForm.controls['q31'].setValue(
              eatingAttitudesAssessment.data.q31.answer
            );
            this.eatingAttitudesForm.controls['q32'].setValue(
              eatingAttitudesAssessment.data.q32.answer
            );

            this.riskScore = eatingAttitudesAssessment.data.riskScore;
          }
        });
    }
  }

  onFormSubmit() {
    if (this.eatingAttitudesForm.invalid) {
      this.eatingAttitudesForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.eatingAttitudesAssessment = Object.assign(
      {},
      this.eatingAttitudesAssessment
    );
    this.eatingAttitudesAssessment.patientId =
      this.eatingAttitudesForm.controls['patientId'].value;
    this.eatingAttitudesAssessment.sessionId =
      this.eatingAttitudesForm.controls['sessionId'].value;
    this.eatingAttitudesAssessment.assessmentRequestId =
      this.eatingAttitudesForm.controls['assessmentRequestId'].value;
    this.eatingAttitudesAssessment.q1 = {
      answer: this.eatingAttitudesForm.controls['q1'].value,
    };
    this.eatingAttitudesAssessment.q2 = {
      answer: this.eatingAttitudesForm.controls['q2'].value,
    };
    this.eatingAttitudesAssessment.q3 = {
      answer: this.eatingAttitudesForm.controls['q3'].value,
    };
    this.eatingAttitudesAssessment.q4 = {
      answer: this.eatingAttitudesForm.controls['q4'].value,
    };
    this.eatingAttitudesAssessment.q5 = {
      answer: this.eatingAttitudesForm.controls['q5'].value,
    };
    this.eatingAttitudesAssessment.q6 = {
      answer: this.eatingAttitudesForm.controls['q6'].value,
    };
    this.eatingAttitudesAssessment.q7 = {
      answer: this.eatingAttitudesForm.controls['q7'].value,
    };
    this.eatingAttitudesAssessment.q8 = {
      answer: this.eatingAttitudesForm.controls['q8'].value,
    };
    this.eatingAttitudesAssessment.q9 = {
      answer: this.eatingAttitudesForm.controls['q9'].value,
    };
    this.eatingAttitudesAssessment.q10 = {
      answer: this.eatingAttitudesForm.controls['q10'].value,
    };
    this.eatingAttitudesAssessment.q11 = {
      answer: this.eatingAttitudesForm.controls['q11'].value,
    };
    this.eatingAttitudesAssessment.q12 = {
      answer: this.eatingAttitudesForm.controls['q12'].value,
    };
    this.eatingAttitudesAssessment.q13 = {
      answer: this.eatingAttitudesForm.controls['q13'].value,
    };
    this.eatingAttitudesAssessment.q14 = {
      answer: this.eatingAttitudesForm.controls['q14'].value,
    };
    this.eatingAttitudesAssessment.q15 = {
      answer: this.eatingAttitudesForm.controls['q15'].value,
    };
    this.eatingAttitudesAssessment.q16 = {
      answer: this.eatingAttitudesForm.controls['q16'].value,
    };
    this.eatingAttitudesAssessment.q17 = {
      answer: this.eatingAttitudesForm.controls['q17'].value,
    };
    this.eatingAttitudesAssessment.q18 = {
      answer: this.eatingAttitudesForm.controls['q18'].value,
    };
    this.eatingAttitudesAssessment.q19 = {
      answer: this.eatingAttitudesForm.controls['q19'].value,
    };
    this.eatingAttitudesAssessment.q20 = {
      answer: this.eatingAttitudesForm.controls['q20'].value,
    };
    this.eatingAttitudesAssessment.q21 = {
      answer: this.eatingAttitudesForm.controls['q21'].value,
    };
    this.eatingAttitudesAssessment.q22 = {
      answer: this.eatingAttitudesForm.controls['q22'].value,
    };
    this.eatingAttitudesAssessment.q23 = {
      answer: this.eatingAttitudesForm.controls['q23'].value,
    };
    this.eatingAttitudesAssessment.q24 = {
      answer: this.eatingAttitudesForm.controls['q24'].value,
    };
    this.eatingAttitudesAssessment.q25 = {
      answer: this.eatingAttitudesForm.controls['q25'].value,
    };
    this.eatingAttitudesAssessment.q26 = {
      answer: this.eatingAttitudesForm.controls['q26'].value,
    };
    this.eatingAttitudesAssessment.q27 = {
      answer: this.eatingAttitudesForm.controls['q27'].value,
    };
    this.eatingAttitudesAssessment.q28 = {
      answer: this.eatingAttitudesForm.controls['q28'].value,
    };
    this.eatingAttitudesAssessment.q29 = {
      answer: this.eatingAttitudesForm.controls['q29'].value,
    };
    this.eatingAttitudesAssessment.q30 = {
      answer: this.eatingAttitudesForm.controls['q30'].value,
    };
    this.eatingAttitudesAssessment.q31 = {
      answer: this.eatingAttitudesForm.controls['q31'].value,
    };
    this.eatingAttitudesAssessment.q32 = {
      answer: this.eatingAttitudesForm.controls['q32'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.eatingAttitudesAssessment.accessCode = this.accessCode;

      this.eatingAttitudesApiService
        .addGuestEatingAttitudes(this.eatingAttitudesAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Eating Attitudes Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Eating Attitudes Assessment'
            );
          }
        );
    } else {
      this.eatingAttitudesApiService
        .addEatingAttitudes(this.eatingAttitudesAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Eating Attitudes Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Eating Attitudes Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    q1: [
      {
        type: 'required',
        message: '1. I am terrified about being overweight. is required',
      },
    ],
    q2: [
      {
        type: 'required',
        message: '2. I avoid eating when I am hungry. is required',
      },
    ],
    q3: [
      {
        type: 'required',
        message: '3. I find myself preoccupied with food. is required',
      },
    ],
    q4: [
      {
        type: 'required',
        message:
          '4. I have gone on eating binges where I feel that I may not be able to stop. is required',
      },
    ],
    q5: [
      {
        type: 'required',
        message: '5. I cut my food into small pieces. is required',
      },
    ],
    q6: [
      {
        type: 'required',
        message:
          '6. I aware of the calorie content of foods that I eat. is required',
      },
    ],
    q7: [
      {
        type: 'required',
        message:
          '7. I particularly avoid food with a high carbohydrate content (i.e. bread, rice, potatoes, etc.) is required',
      },
    ],
    q8: [
      {
        type: 'required',
        message:
          '8. I feel that others would prefer if I ate more. is required',
      },
    ],
    q9: [
      {
        type: 'required',
        message: '9. I vomit after I have eaten. is required',
      },
    ],
    q10: [
      {
        type: 'required',
        message: '10. I feel extremely guilty after eating. is required',
      },
    ],
    q11: [
      {
        type: 'required',
        message: '11. I am occupied with a desire to be thinner. is required',
      },
    ],
    q12: [
      {
        type: 'required',
        message:
          '12. I think about burning up calories when I exercise. is required',
      },
    ],
    q13: [
      {
        type: 'required',
        message: '13. I other people think that I am too thin. is required',
      },
    ],
    q14: [
      {
        type: 'required',
        message:
          '14. I am preoccupied with the thought of having fat on my body. is required',
      },
    ],
    q15: [
      {
        type: 'required',
        message: '15. I take longer than others to eat my meals. is required',
      },
    ],
    q16: [
      {
        type: 'required',
        message: '16. I avoid foods with sugar in them. is required',
      },
    ],
    q17: [{ type: 'required', message: '17. I eat diet foods. is required' }],
    q18: [
      {
        type: 'required',
        message: '18. I feel that food controls my life. is required',
      },
    ],
    q19: [
      {
        type: 'required',
        message: '19. I display self-control around food. is required',
      },
    ],
    q20: [
      {
        type: 'required',
        message: '20. I feel that others pressure me to eat. is required',
      },
    ],
    q21: [
      {
        type: 'required',
        message: '21. I give too much time and thought to food. is required',
      },
    ],
    q22: [
      {
        type: 'required',
        message: '22. I feel uncomfortable after eating sweets. is required',
      },
    ],
    q23: [
      {
        type: 'required',
        message: '23. I engage in dieting behavior. is required',
      },
    ],
    q24: [
      {
        type: 'required',
        message: '24. I like my stomach to be empty. is required',
      },
    ],
    q25: [
      {
        type: 'required',
        message: '25. I have the impulse to vomit after meals. is required',
      },
    ],
    q26: [
      {
        type: 'required',
        message: '26. I enjoy trying new rich foods. is required',
      },
    ],
    q27: [
      {
        type: 'required',
        message:
          'A. Gone on eating binges where you feel that you may not be able to stop? (Defined as eating much more than most people would under the same circumstances and feeling that eating is out of control.) is required',
      },
    ],
    q28: [
      {
        type: 'required',
        message:
          'B. Ever made yourself sick (vomited) to control your weight or shape? is required',
      },
    ],
    q29: [
      {
        type: 'required',
        message:
          'C. Ever used laxatives, diet pills or diuretics (water pills) to control your weight or shape? is required',
      },
    ],
    q30: [
      {
        type: 'required',
        message:
          'D. Exercised more than 60 minutes a day to lose or to control your weight? is required',
      },
    ],
    q31: [
      {
        type: 'required',
        message: 'E. Lost 20 pounds or more in the past 6 months? is required',
      },
    ],
    q32: [
      {
        type: 'required',
        message:
          'F. Have you ever been treated for an eating disorder? is required',
      },
    ],
  };

  private buildForm() {
    this.eatingAttitudesForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      q1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q2: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q3: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q4: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q5: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q6: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q7: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q8: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q9: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q10: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q11: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q12: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q13: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q14: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q15: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q16: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q17: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q18: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q19: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q20: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q21: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q22: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q23: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q24: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q25: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q26: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q27: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q28: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q29: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q30: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q31: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q32: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.eatingAttitudesForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.eatingAttitudesForm) {
      return;
    }
    const form = this.eatingAttitudesForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
