<mat-card
  appearance="raised"
  *ngIf="!selectedTherapySession"
  style="margin-top: 10px; margin-bottom: 20px"
>
  <mat-card-title *ngIf="!status">Notes </mat-card-title>

  <!-- Search Box -->
  <div
    class="row"
    style="margin-right: 5px; row-gap: 15px; margin-bottom: 15px"
  >
    <div [class]="status ? 'col-sm-12 col-xl-8' : 'col-sm-12 col-md-4'">
      <div
        class="row"
        style="
          row-gap: 15px;
          column-gap: 15px;
          flex-wrap: wrap;
          margin-left: 5px;
        "
      >
        <div
          class="col"
          style="
            display: flex;
            align-items: center;
            padding: 5px;
            border-radius: 10px;
            border: 1px solid rgb(0, 0, 0, 0.2);
            background-color: #ffffff;
            padding: 5px;
            min-width: 140px;
          "
        >
          <span
            class="material-icons"
            style="text-align: left; font-size: 20px; opacity: 0.5"
          >
            search
          </span>
          <input
            type="search"
            placeholder="Note Id"
            [(ngModel)]="searchSessionCode"
            (ngModelChange)="searchBySessionId()"
            style="
              padding-left: 15px;
              width: 100%;
              background: transparent;
              border: 0;
            "
          />
        </div>

        <div
          class="col"
          *ngIf="status"
          style="
            display: flex;
            align-items: center;
            padding: 5px;
            border-radius: 10px;
            border: 1px solid rgb(0, 0, 0, 0.2);
            background-color: #ffffff;
            padding: 5px;
            min-width: 140px;
          "
        >
          <span
            class="material-icons"
            style="text-align: left; font-size: 20px; opacity: 0.5"
          >
            search
          </span>
          <input
            type="search"
            placeholder="Last Name"
            [(ngModel)]="searchPatientLastName"
            (ngModelChange)="searchBySessionId()"
            style="
              padding-left: 15px;
              width: 100%;
              background: transparent;
              border: 0;
            "
          />
        </div>

        <div
          class="col"
          *ngIf="status"
          style="
            display: flex;
            align-items: center;
            padding: 5px;
            border-radius: 10px;
            border: 1px solid rgb(0, 0, 0, 0.2);
            background-color: #ffffff;
            padding: 5px;
            min-width: 140px;
          "
        >
          <span
            class="material-icons"
            style="text-align: left; font-size: 20px; opacity: 0.5"
          >
            search
          </span>
          <input
            type="search"
            placeholder="First Name"
            [(ngModel)]="searchPatientFirstName"
            (ngModelChange)="searchBySessionId()"
            style="
              padding-left: 15px;
              width: 100%;
              background: transparent;
              border: 0;
            "
          />
        </div>
      </div>
    </div>

    <div
      *ngIf="!status"
      class="col-sm-12 col-md-8"
      style="
        column-gap: 20px;
        display: flex;
        flex-wrap: wrap;
        text-align: right;
        flex-direction: row-reverse;
        padding: 0;
        row-gap: 10px;
      "
    >
      <div *ngIf="hasTherapySessionAddAccess && patient.status === 'Active'">
        <button
          mat-raised-button
          color="warn"
          style="margin-right: 15px"
          (click)="startPhoneNote()"
        >
          + Phone Note
        </button>
        <button mat-raised-button color="warn" (click)="addNewTherapySession()">
          + Appointment
        </button>
      </div>

      <button mat-raised-button [matMenuTriggerFor]="sortMenu">
        <span style="white-space: break-spaces" *ngIf="searchStatus === ''">
          Status
        </span>
        <span
          style="white-space: break-spaces; color: #3ac47d"
          *ngIf="searchStatus === 'Active'"
        >
          Status: Active
        </span>
        <span
          style="white-space: break-spaces; color: #d92550"
          *ngIf="searchStatus === 'Canceled'"
        >
          Status: Canceled
        </span>
        <span
          style="white-space: break-spaces; color: #d92550"
          *ngIf="searchStatus === 'Closed'"
        >
          Status: Closed
        </span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>

      <mat-menu #sortMenu="matMenu">
        <button
          mat-menu-item
          style="font-weight: 400; font-size: 12px; width: 100%"
          (click)="filterOptionChanged('')"
        >
          None
        </button>
        <button
          mat-menu-item
          style="font-weight: 400; font-size: 12px; width: 100%"
          (click)="filterOptionChanged('Active')"
        >
          Active
        </button>
        <button
          mat-menu-item
          style="font-weight: 400; font-size: 12px; width: 100%"
          (click)="filterOptionChanged('Canceled')"
        >
          Canceled
        </button>
        <button
          mat-menu-item
          style="font-weight: 400; font-size: 12px; width: 100%"
          (click)="filterOptionChanged('Closed')"
        >
          Closed
        </button>
      </mat-menu>
    </div>
  </div>

  <div *ngIf="data.total === 0 && !isLoadingResults">
    <mat-error> No notes found </mat-error>
  </div>

  <div *ngIf="isLoadingResults || apiError">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="isLoadingResults"
    ></mat-progress-bar>

    <mat-error *ngIf="apiError">
      An error occurred while retrieving notes
    </mat-error>
  </div>

  <div *ngIf="data.total > 0" [class]="status ? '' : 'mat-elevation-z8'">
    <div class="table-responsive">
      <table
        mat-table
        [dataSource]="data.items"
        class="mat-elevation-z8"
        matSort
        matSortDisableClear
      >
        <ng-container matColumnDef="sessionCode">
          <th mat-header-cell *matHeaderCellDef style="width: 160px">
            Note Id
          </th>
          <td mat-cell *matCellDef="let row" style="position: relative">
            <div class="justifyCenterContainer">
              <mat-icon
                color="primary"
                *ngIf="row?.groupAppointment"
                matTooltip="Group Note"
              >
                group
              </mat-icon>
              <mat-icon
                color="primary"
                *ngIf="row.sessionType === 'Phone Note'"
                matTooltip="Phone Note"
              >
                phone
              </mat-icon>
              <button
                mat-button
                type="button"
                (click)="selectedSession(row)"
                color="primary"
                style="padding: 0"
              >
                {{ row.sessionCode }}
              </button>
            </div>
            <button
              id="chatButton"
              mat-icon-button
              color="primary"
              type="button"
              style="
                margin: 5px;
                opacity: 0.5;
                position: absolute;
                right: 0px;
                top: 0px;
              "
              *ngIf="row?.sessionLevelNote"
              (click)="viewSessionLevelNote(row)"
              matTooltip="View General Note"
            >
              <mat-icon style="font-size: 16px">chat</mat-icon>
            </button>

            <div class="stausDotWrapper">
              <mat-icon
                class="statusDot"
                [style.color]="
                  row.status === 'Active'
                    ? '#3ac47d'
                    : row.status === 'Closed'
                    ? '#b6b6b6'
                    : '#d92550'
                "
              >
                fiber_manual_record
              </mat-icon>
              <span
                class="statusIndicator"
                [style.color]="
                  row.status === 'Active'
                    ? '#3ac47d'
                    : row.status === 'Closed'
                    ? '#b6b6b6'
                    : '#d92550'
                "
              >
                {{ row.status }}
              </span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="memberName">
          <th mat-header-cell *matHeaderCellDef>Member</th>
          <td mat-cell *matCellDef="let row">
            <!-- For Ind Appointment -->
            <span *ngIf="!row.groupAppointment">
              {{ row?.patientLastName }}, {{ row?.patientFirstName }}
            </span>
            <!-- For Group Appointment -->
            <span *ngIf="row.groupAppointment">
              <span
                class="justifyCenterContainer"
                style="color: #787575; font-weight: 500"
              >
                <mat-icon>group</mat-icon> Group Appointment
              </span>

              <span *ngFor="let member of row.groupMembers">
                {{ member?.member?.lastName }}, {{ member?.member?.firstName }}
                {{ member?.member?.middleName }}
                <br />
              </span>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="case">
          <th mat-header-cell *matHeaderCellDef style="width: 110px">
            Care Id
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row?.caseNumber }}
          </td>
        </ng-container>
        <ng-container matColumnDef="time">
          <th mat-header-cell *matHeaderCellDef>Date/Time</th>
          <td mat-cell *matCellDef="let row" style="width: 290px">
            <span *ngIf="row?.start">
              <label class="labelText">
                <b>{{ row.start | date : 'M/d/y' : currentTimezone }}</b>
                <br />
                {{ row.start | date : 'h:mm a' : currentTimezone }} -
                {{ row.end | date : 'h:mm a' : currentTimezone }}
                {{ row?.start ? currentTimezone : '' }}
              </label>
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="visitReason">
          <th mat-header-cell *matHeaderCellDef>Details</th>
          <td mat-cell *matCellDef="let row">
            <div
              *ngIf="row?.groupAppointment"
              class="justifyCenterContainer"
              style="color: #6d6d6d"
            >
              <mat-icon>group</mat-icon>
              <span style="font-weight: 450">Group Appointment</span>
            </div>

            <ng-container *ngIf="row.sessionType !== 'Phone Note'">
              <b
                >{{ row.visitReasonName }}
                <span *ngIf="row.billingType">- {{ row.billingType }}</span></b
              >
              <br />
            </ng-container>

            <small *ngIf="row.sessionType !== 'Phone Note'">
              <b>Facility:</b> {{ row.billingProviderName }}
              <br />
            </small>

            <small>
              <span *ngIf="row.physicianId">
                <b> Provider:</b> {{ row.physicianLastName }},
                {{ row.physicianFirstName }}
              </span>

              <br *ngIf="row.physicianId" />
              {{ row.sessionType }}
            </small>
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let row" style="position: relative">
            <span
              *ngIf="row.status === 'Active'"
              style="color: #3ac47d; font-weight: 900"
            >
              {{ row.status }}
            </span>
            <span
              *ngIf="row.status != 'Active'"
              style="color: #d92550; font-weight: 900"
            >
              {{ row.status }}
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="queue">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let row" style="position: relative">
            <button
              mat-button
              class="queueBox"
              [matMenuTriggerFor]="queueMenu"
              *ngIf="row.sessionType !== 'Phone Note'"
              [disabled]="
                !hasTherapySessionEditAccess || row.status === 'Closed'
              "
            >
              {{ row.queue }}
              <mat-icon
                *ngIf="hasTherapySessionEditAccess && row.status != 'Closed'"
              >
                arrow_drop_down
              </mat-icon>
            </button>
            <mat-menu #queueMenu="matMenu">
              <button
                *ngFor="let queue of sessionQueues"
                mat-menu-item
                style="font-weight: 400; font-size: 12px; width: 100%"
                (click)="queueStatusChanged(row, queue)"
              >
                {{ queue }}
              </button>
            </mat-menu>

            <!-- Note and Charge Status if the status is not canceled -->
            <div
              class="noteChargeContainerWrapper"
              *ngIf="row.status != 'Canceled'"
            >
              <!-- Note -->
              <div
                class="noteChargeStatusDotWrapper"
                *ngIf="row.clinicalNoteStatus"
              >
                <mat-icon
                  class="statusDot"
                  [style.color]="
                    row.clinicalNoteStatus === 'Note in progress'
                      ? '#f8b042'
                      : '#b6b6b6'
                  "
                >
                  fiber_manual_record
                </mat-icon>
                <span class="statusIndicator" style="color: #b6b6b6">
                  {{ row.clinicalNoteStatus }}
                </span>
              </div>
              <!-- Charge -->
              <div
                class="noteChargeStatusDotWrapper"
                *ngIf="row.chargeStatus && row.sessionType !== 'Phone Note'"
              >
                <mat-icon
                  class="statusDot"
                  [style.color]="
                    row.chargeStatus === 'Charge in progress'
                      ? '#f8b042'
                      : row.chargeStatus === 'Charge not started'
                      ? '#b6b6b6'
                      : '#3ac47d'
                  "
                >
                  fiber_manual_record
                </mat-icon>
                <span class="statusIndicator" style="color: #b6b6b6">
                  {{ row.chargeStatus }}
                </span>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef style="text-align: right">
            Actions
          </th>
          <td
            mat-cell
            *matCellDef="let row; let i = index"
            style="text-align: right"
          >
            <button
              *ngIf="row.status != 'Closed'"
              mat-raised-button
              [matMenuTriggerFor]="menu"
              class="btn btn-just-icon btn-white btn-fab btn-round"
            >
              <i class="material-icons text_align-center">more_vert</i>
            </button>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                type="button"
                *ngIf="
                  row.status != 'Closed' && row?.sessionType != 'Phone Note'
                "
                (click)="rescheduleSession(row)"
              >
                <mat-icon style="color: #f39121"> pending_actions </mat-icon>
                <span>Edit/Rescheule</span>
              </button>
              <button
                mat-menu-item
                type="button"
                *ngIf="
                  row?.sessionType != 'Phone Note' && !row?.groupAppointment
                "
                (click)="viewSessionLevelNote(row)"
              >
                <mat-icon style="color: #2196f3"> speaker_notes </mat-icon>
                <span>General Notes</span>
              </button>
              <button
                mat-menu-item
                type="button"
                *ngIf="
                  row.status === 'Active' &&
                  hasMemberPaymentsAddAccess &&
                  orgConfig?.connectedAccountStatus === 'Setup_Complete' &&
                  !row?.groupAppointment
                "
                (click)="collectPayment(row)"
              >
                <mat-icon style="color: #029102"> paid </mat-icon>
                <span>Collect Payment</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <mat-paginator
      [length]="data.total"
      [pageSize]="data.per_page"
      *ngIf="data.total > data.per_page"
      (page)="getNext($event)"
      [showFirstLastButtons]="true"
    ></mat-paginator>
  </div>
</mat-card>

<ng-container *ngIf="selectedTherapySession">
  <app-session-details
    [patient]="patient"
    [secondaryTab]="secondaryTab"
    [therapySession]="selectedTherapySession"
    [hasMemberEditAccess]="hasMemberEditAccess"
    [hasTeleHealthEnabled]="hasTeleHealthEnabled"
    [hasHospitalDeviceFeature]="hasHospitalDeviceFeature"
    [hasClaimsFeature]="hasClaimsFeature"
    [hasBillingViewAccess]="hasBillingViewAccess"
    [hasBillingEditAccess]="hasBillingEditAccess"
    [hasASIFeatures]="hasASIFeatures"
    [hasTherapySessionViewAccess]="hasTherapySessionViewAccess"
    [hasTherapySessionAddAccess]="hasTherapySessionAddAccess"
    [hasTherapySessionEditAccess]="hasTherapySessionEditAccess"
    [hasTherapySessionDeleteAccess]="hasTherapySessionDeleteAccess"
    [hasBPSAIntakeViewAccess]="hasBPSAIntakeViewAccess"
    [hasBPSAIntakeAddAccess]="hasBPSAIntakeAddAccess"
    [hasBPSAIntakeEditAccess]="hasBPSAIntakeEditAccess"
    [hasBPSAClinicalViewAccess]="hasBPSAClinicalViewAccess"
    [hasBPSAClinicalAddAccess]="hasBPSAClinicalAddAccess"
    [hasBPSAClinicalEditAccess]="hasBPSAClinicalEditAccess"
    [hasClinicalNotesViewAccess]="hasClinicalNotesViewAccess"
    [hasClinicalNotesAddAccess]="hasClinicalNotesAddAccess"
    [hasClinicalNotesEditAccess]="hasClinicalNotesEditAccess"
    [hasSubjectivesViewAccess]="hasSubjectivesViewAccess"
    [hasSubjectivesEditAccess]="hasSubjectivesEditAccess"
    [hasObjectivesViewAccess]="hasObjectivesViewAccess"
    [hasObjectivesEditAccess]="hasObjectivesEditAccess"
    [hasSessionDocumentsViewAccess]="hasSessionDocumentsViewAccess"
    [hasSessionDocumentsAddAccess]="hasSessionDocumentsAddAccess"
    [hasSessionDocumentsDeleteAccess]="hasSessionDocumentsDeleteAccess"
    [hasMemberAssessmentViewAccess]="hasMemberAssessmentViewAccess"
    [hasMemberAssessmentAddAccess]="hasMemberAssessmentAddAccess"
    [hasMemberAssessmentEditAccess]="hasMemberAssessmentEditAccess"
    [hasMemberAssessmentDeleteAccess]="hasMemberAssessmentDeleteAccess"
    [hasAssessmentTakeAddAccess]="hasAssessmentTakeAddAccess"
    [hasAssessmentChartsViewAccess]="hasAssessmentChartsViewAccess"
    [hasAssessmentGuidanceViewAccess]="hasAssessmentGuidanceViewAccess"
    [hasAssessmentGuidanceEditAccess]="hasAssessmentGuidanceEditAccess"
    [hasAssessmentSummaryViewAccess]="hasAssessmentSummaryViewAccess"
    [hasAssessmentSummaryEditAccess]="hasAssessmentSummaryEditAccess"
    [hasGoalsViewAccess]="hasGoalsViewAccess"
    [hasGoalsAddAccess]="hasGoalsAddAccess"
    [hasGoalsEditAccess]="hasGoalsEditAccess"
    [hasGoalsDeleteAccess]="hasGoalsDeleteAccess"
    [hasDiagnosisViewAccess]="hasDiagnosisViewAccess"
    [hasDiagnosisAddAccess]="hasDiagnosisAddAccess"
    [hasDiagnosisEditAccess]="hasDiagnosisEditAccess"
    [hasDiagnosisDeleteAccess]="hasDiagnosisDeleteAccess"
    [hasMedicationViewAccess]="hasMedicationViewAccess"
    [hasMedicationAddAccess]="hasMedicationAddAccess"
    [hasMedicationEditAccess]="hasMedicationEditAccess"
    [hasMedicationDeleteAccess]="hasMedicationDeleteAccess"
    [prescriptionEnabled]="prescriptionEnabled"
    (output)="onEventTriggered($event)"
  ></app-session-details>
</ng-container>
