import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { setOptions } from '@mobiscroll/angular';
import { subYears } from 'date-fns';
import { Patient } from 'src/app/_models';
import { ToastMessageService } from 'src/app/_services';
import { MoodLogApiService } from 'src/app/_services/hourlylogs/moodlog.service';
import { AddMoodLogDialogComponent } from 'src/app/pages/dashboards/patient-dashboard/hourly-mood-assessment/add-mood-log-dialog/add-mood-log-dialog.component';
import { formatDate } from 'src/app/shared/utilities/utilities';
import { ViewMoodLogComponent } from './view-mood-log/view-mood-log.component';

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

@Component({
  selector: 'app-hourly-mood-report',
  templateUrl: './hourly-mood-report.component.html',
  styleUrls: ['./hourly-mood-report.component.css'],
})
export class HourlyMoodReportComponent implements OnInit {
  @Input() patient: Patient;
  @Input() hasMoodAddAccess: boolean;
  @Output() output = new EventEmitter<any>();

  viewType = 'year';
  calType = 'year';

  selectedDate = new Date();
  activeDate = new Date();
  currentDate = new Date();
  minimumDate = subYears(new Date(new Date().getFullYear(), 0, 1), 5);

  getMoodProcessing: boolean = true;

  allMoodLogForTheCalType = [];
  marked = [];
  colors = [];

  colorRank = new Map([
    ['red', 0],
    ['orange', 1],
    ['#72b9f1', 2],
    ['lightgreen', 3],
    ['green', 4],
  ]);

  moodColorIcon = [
    { id: 'red', mood: 'sentiment_very_dissatisfied' },
    { id: 'orange', mood: 'sentiment_dissatisfied' },
    { id: '#72b9f1', mood: 'sentiment_neutral' },
    { id: 'lightgreen', mood: 'sentiment_satisfied' },
    { id: 'green', mood: 'sentiment_very_satisfied' },
  ];

  constructor(
    private moodApiService: MoodLogApiService,
    private toastMessageService: ToastMessageService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getAllMoodDataForTheSelectedYear();
  }

  getAllMoodDataForTheSelectedYear() {
    this.getMoodProcessing = true;

    this.moodApiService
      .getMoodRecord(
        this.activeDate.getFullYear().toString(),
        this.patient.id,
        this.patient.organizationId
      )
      .subscribe(
        (response) => {
          if (response && response?.length > 0) {
            this.allMoodLogForTheCalType = response;
            this.handleMarkedAndColors();
          } else {
            this.marked = [];
            this.colors = [];
          }
          this.getMoodProcessing = false;
        },
        (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get mood report. Please refresh the page.'
          );
          this.getMoodProcessing = false;
        }
      );
  }

  handleMarkedAndColors() {
    if (this.allMoodLogForTheCalType) {
      let markedRecords = [];
      let colorBackground = [];
      let uniqueEntries = new Set<string>();
      let distinchBackGroundColorRank = new Map();
      let distinchBackGroundColor = new Map();

      // First calculate the calendars to mark
      this.allMoodLogForTheCalType.forEach((record) => {
        const key = `${record.logDate}_${record.color}`;

        // Only allow distinct entrees for colors pop up on bottom
        if (!uniqueEntries.has(key)) {
          uniqueEntries.add(key);

          // All moods on the bottom of the date
          markedRecords.push({
            date: new Date(record.logDate),
            color: record?.color ? record.color : '#ffffff',
          });

          // BackGround color
          if (distinchBackGroundColorRank.has(record.logDate)) {
            // Filter the mood override
            let oldColorRank = distinchBackGroundColorRank.get(record.logDate);
            let newColorRank = this.colorRank.get(record.color);
            // If the color rank is less replace with the lower one
            if (newColorRank < oldColorRank) {
              distinchBackGroundColorRank.set(record.logDate, newColorRank);
              distinchBackGroundColor.set(record.logDate, record.color);
            }
          } else {
            distinchBackGroundColorRank.set(
              record.logDate,
              this.colorRank.get(record.color)
            );
            distinchBackGroundColor.set(record.logDate, record.color);
          }
        }
      });

      // Now add background to each label
      distinchBackGroundColor.forEach((value, key) => {
        colorBackground.push({
          date: new Date(key),
          highlight: value,
        });
      });

      this.colors = colorBackground;
      this.marked = markedRecords;
    }
  }

  onPageChange(args: any): void {
    // Handle Arguments here to get the year
    this.activeDate = args.firstDay;
    this.getAllMoodDataForTheSelectedYear();
  }

  onCellClick(args: any) {
    let selectedCellDate = args.date;
    let formattedSelectedDate = formatDate(selectedCellDate);

    let filteredMoodLog = this.allMoodLogForTheCalType.filter((mood) => {
      return mood.logDate === formattedSelectedDate;
    });

    if (filteredMoodLog && filteredMoodLog.length > 0) {
      //Sort in hours asc
      filteredMoodLog.sort((a, b) => {
        if (a.hour > b.hour) {
          return 1;
        } else {
          return -1;
        }
      });

      let dialogRef = this.dialog.open(ViewMoodLogComponent, {
        data: {
          moodLog: filteredMoodLog,
          date: formattedSelectedDate,
        },
        disableClose: true,
        autoFocus: false,
        minWidth: '25vw',
      });

      dialogRef.afterClosed().subscribe((response) => {
        if (response) {
          this.getAllMoodDataForTheSelectedYear();
        }
      });
    }
  }

  captureMood() {
    const dialogRef = this.dialog.open(AddMoodLogDialogComponent, {
      data: { patientId: this.patient.id },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.getAllMoodDataForTheSelectedYear();
      }
    });
  }

  deSelectReport() {
    this.output.emit({ eventType: 'DESELECT_REPORT' });
  }
}
