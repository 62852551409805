import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { MbscModule } from '@mobiscroll/angular';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { NgxPrintModule } from 'ngx-print';
import { CustomDirectivesModule } from 'src/app/_directives/custom-directives.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { AngularMaterialModule } from '../../material-imports';
import { CareLogAddedListComponent } from './care-activities/carelog-added-list/carelog-added-list.component';
import { CaseNotesAvailableListComponent } from './care-activities/carelog-available-list/carelog-available-list.component';
import { CaseNoteCardComponent } from './care-activities/carelog-card/carelog-card.component';
import { AddCareLogDialogComponent } from './care-activities/carelog-dialogs/carelog-add/carelog-add.component';
import { CarelogBehavioralIncidentComponent } from './care-activities/carelog-dialogs/carelog-behavioral-incident/carelog-behavioral-incident.component';
import { CarelogBowelMovementComponent } from './care-activities/carelog-dialogs/carelog-bowel-movement/carelog-bowel-movement.component';
import { CarelogDialogSelectorComponent } from './care-activities/carelog-dialogs/carelog-dialog-selector/carelog-dialog-selector.component';
import { CarelogSeizureLogComponent } from './care-activities/carelog-dialogs/carelog-dialog-selector/carelog-seizure-log/carelog-seizure-log.component';
import { GetGoalDialogComponent } from './care-activities/carelog-dialogs/carelog-goal/carelog-goal.component';
import { CarelogSleepRecordComponent } from './care-activities/carelog-dialogs/carelog-sleep-record/carelog-sleep-record.component';
import { CarelogUrinalDrugAnalysisComponent } from './care-activities/carelog-dialogs/carelog-urinal-drug-analysis/carelog-urinal-drug-analysis.component';
import { CarelogVitalsComponent } from './care-activities/carelog-dialogs/carelog-vitals/carelog-vitals.component';
import { CaseNotesListComponent } from './care-activities/carelog-list/carelog-list.component';
import { CarelogComponent } from './care-activities/carelog.component';
import { CaseDetailsComponent } from './case-details/case-details.component';
import { CaseHeaderComponent } from './case-header/case-header.component';
import { CaseIncidentsComponent } from './case-incidents/case-incidents.component';
import { AddEditBehaviorIncidentDialogComponent } from './case-incidents/dialogs/add-edit-behavior-incident-dialog/add-edit-behavior-incident-dialog.component';
import { CaseNotesComponent } from './case-notes/case-notes.component';
import { AddCaseNotesDialogComponent } from './case-notes/dialogs/add/add.component';
import { CasePrintComponent } from './case-print/case-print.component';
import { AddDocumentDialogComponent } from './documents/dialogs/add/add.component';
import { DocumentsComponent } from './documents/documents.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ProgressComponent } from './file-upload/progress/progress.component';
import { AddGoalDialogComponent } from './goals/dialogs/add/add.component';
import { MemberGoalsComponent } from './goals/goals.component';
import { AddNoteDialogComponent } from './pre-defined-notes/dialogs/add/add.component';
import { MemberNotesComponent } from './pre-defined-notes/pre-defined-notes.component';
import { PrintBowelReportComponent } from './print-center/print-bowel-report/print-bowel-report.component';
import { PrintCarelogReportComponent } from './print-center/print-carelog-report/print-carelog-report.component';
import { PrintSeizureReportComponent } from './print-center/print-seizure-report/print-seizure-report.component';
import { PrintSleepReportComponent } from './print-center/print-sleep-report/print-sleep-report.component';
import { DrugScreenResultComponent } from './care-activities/drug-screen-result/drug-screen-result.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AngularMaterialModule,
    DragDropModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    PdfJsViewerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    PipesModule,
    NgxPrintModule,
    MbscModule,
    CustomDirectivesModule,
  ],
  declarations: [
    CaseDetailsComponent,
    CarelogComponent,
    CaseHeaderComponent,
    CaseNotesListComponent,
    CaseNoteCardComponent,
    MemberGoalsComponent,
    AddCareLogDialogComponent,
    AddGoalDialogComponent,
    GetGoalDialogComponent,
    DocumentsComponent,
    FileUploadComponent,
    ProgressComponent,
    MemberNotesComponent, //, FileSelectDirective, FileDropDirective, FileUploader,
    AddNoteDialogComponent,
    CasePrintComponent,
    CaseNotesAvailableListComponent,
    CareLogAddedListComponent,
    CarelogVitalsComponent,
    CaseNotesComponent,
    AddCaseNotesDialogComponent,
    AddDocumentDialogComponent,
    CarelogBowelMovementComponent,
    CarelogSeizureLogComponent,
    CarelogSleepRecordComponent,
    CaseIncidentsComponent,
    AddEditBehaviorIncidentDialogComponent,
    PrintCarelogReportComponent,
    PrintBowelReportComponent,
    PrintSeizureReportComponent,
    PrintSleepReportComponent,
    CarelogDialogSelectorComponent,
    CarelogBehavioralIncidentComponent,
    CarelogUrinalDrugAnalysisComponent,
    DrugScreenResultComponent
  ],
  exports: [CaseDetailsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CasesModule {}
