import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../store';
import { getActiveVisitReasons } from '../store/actions/visit-reasons.actions';
import {
  getVisitReasonData,
  getVisitReasonDataError,
  getVisitReasonDataLoading,
} from '../store/selectors';
import { Sandbox } from './base.sandbox';

@Injectable()
export class VisitReasonsSandbox extends Sandbox {
  constructor(protected appState$: Store<State>) {
    super(appState$);
  }

  public visitReasons$ = this.appState$.select(getVisitReasonData);

  public visitReasonsLoading$ = this.appState$.select(
    getVisitReasonDataLoading
  );

  public visitReasonsError$ = this.appState$.select(getVisitReasonDataError);

  public triggerVisitReasonsLoad() {
    this.appState$.dispatch(getActiveVisitReasons());
  }
}
