<div class="container">
  <!-- Heading -->
  <div class="dialogTitle">
    <p matDialogTitle>{{ assessmentRequest.assessmentType }} Goals</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      [mat-dialog-close]="reloadRequired"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <!-- Assessment Details -->
  <div class="patientDetailContainer" *ngIf="!isPatient">
    <div>
      <label>Patient&nbsp;:&nbsp;</label>
      <label class="labelText">
        {{
          assessmentRequest.patientLastName
            ? assessmentRequest.patientLastName +
              ', ' +
              assessmentRequest.patientFirstName
            : assessmentRequest.patientName
        }}</label
      >
    </div>
  </div>
  <!-- Body -->
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoadingResults"
  ></mat-progress-bar>

  <div class="gridContainer" *ngIf="!isLoadingResults">
    <mat-card
      appearance="raised"
      class="mat-elevation-z8"
      *ngFor="let goal of goalsList; let i = index"
      style="border-radius: 20px"
    >
      <span
        class="material-icons"
        style="
          color: gray;
          position: absolute;
          right: 15px;
          top: 20px;
          font-size: 17px;
          cursor: pointer;
          border-radius: 50%;
          z-index: 2;
          border: 2px solid darkgray;
        "
        [matMenuTriggerFor]="menu"
      >
        more_vert
      </span>

      <mat-icon
        style="color: #ff5722; cursor: pointer"
        *ngIf="
          isPatient && goal?.physicianNotes && goal?.physicianNotes.length > 0
        "
        (click)="viewOnlyNotes(goal, 'Physician')"
      >
        speaker_notes
      </mat-icon>

      <mat-icon
        style="color: #ff5722; cursor: pointer"
        (click)="viewOnlyNotes(goal, 'Patient')"
        *ngIf="
          !isPatient && goal?.patientNotes && goal?.patientNotes.length > 0
        "
      >
        speaker_notes
      </mat-icon>

      <mat-menu #menu="matMenu" style="max-height: 500px">
        <button
          mat-menu-item
          type="button"
          (click)="editGoal(goal)"
          *ngIf="goal?.status === 'Assigned' && !isPatient"
        >
          <mat-icon style="color: #f39121"> edit </mat-icon>
          <span>Edit</span>
        </button>
        <button mat-menu-item type="button" (click)="viewGoalNotes(goal)">
          <mat-icon style="color: #2196f3"> edit_note </mat-icon>
          <span>My Notes</span>
        </button>

        <button
          mat-menu-item
          type="button"
          (click)="viewOnlyNotes(goal, 'Physician')"
          *ngIf="isPatient && goal?.physicianNotes"
        >
          <mat-icon style="color: #023047"> assignment_ind </mat-icon>
          <span>Notes By Clinician</span>
        </button>
        <button
          mat-menu-item
          type="button"
          (click)="viewOnlyNotes(goal, 'Patient')"
          *ngIf="!isPatient && goal?.patientNotes"
        >
          <mat-icon style="color: #023047"> assignment_ind </mat-icon>
          <span>Notes By Member</span>
        </button>

        <button
          mat-menu-item
          type="button"
          (click)="viewFollowUp(goal)"
          *ngIf="goal.followUp === 'Yes'"
        >
          <mat-icon style="color: #794c8a"> question_answer </mat-icon>
          <span>Follow up history</span>
        </button>

        <button
          mat-menu-item
          type="button"
          (click)="deleteGoal(goal)"
          *ngIf="goal?.status === 'Assigned' && !isPatient"
        >
          <mat-icon style="color: red"> delete_forever </mat-icon>
          <span>Delete</span>
        </button>
        <button
          mat-menu-item
          type="button"
          (click)="markGoalAsComplete(goal)"
          *ngIf="goal?.status === 'Assigned'"
        >
          <mat-icon style="color: green"> check_circle </mat-icon>
          <span>Mark as Complete</span>
        </button>
      </mat-menu>

      <label class="goalHeader"> Goal: {{ goal.goalIndex }} </label>

      <div class="goalContainer">
        <label class="labelHeader">Description </label>
        <label class="labelText">
          {{ goal.description }}
        </label>

        <label class="labelHeader">Target Date</label>
        <label class="labelText">
          {{ goal.targetCompletionDate | date : 'MM/dd/y' }}
        </label>

        <label
          class="labelHeader"
          *ngIf="goal.status === 'Completed'"
          style="white-space: nowrap"
        >
          Completed On
        </label>
        <label class="labelText" *ngIf="goal.status === 'Completed'">
          {{ goal.actualCompletionDate | date : 'MM/dd/y' }}
        </label>

        <label
          class="labelHeader"
          *ngIf="goal.followUp === 'No' && goal?.status === 'Assigned'"
          >Follow Up
        </label>
        <label
          class="labelText"
          *ngIf="goal.followUp === 'No' && goal?.status === 'Assigned'"
        >
          {{ goal.followUp }}
        </label>

        <label
          *ngIf="goal.followUp === 'Yes' && goal?.status === 'Assigned'"
          class="labelHeader"
          >Follow Up
        </label>
        <label
          *ngIf="goal.followUp === 'Yes' && goal?.status === 'Assigned'"
          class="labelText"
        >
          Every {{ goal.followUpNumber }}
          {{ goal.followUpType }}
        </label>

        <label
          *ngIf="goal.followUp === 'Yes' && goal?.status === 'Assigned'"
          class="labelHeader"
          style="white-space: nowrap"
        >
          Next Follow Up
        </label>
        <label
          *ngIf="goal.followUp === 'Yes' && goal?.status === 'Assigned'"
          class="labelText"
          style="color: #2196f3; cursor: pointer"
          (click)="viewFollowUp(goal)"
        >
          {{ goal?.nextFollowUpDate | date : 'MM/dd/y' }}
        </label>

        <label class="labelHeader">Status </label>
        <label class="labelText">
          <span
            *ngIf="goal?.status === 'Completed'"
            style="color: #3ac47d; font-weight: 900"
          >
            {{ goal?.status }}
          </span>
          <span
            *ngIf="goal?.status === 'Assigned'"
            style="color: #ff9f40; font-weight: 900"
          >
            In Progress
          </span>
        </label>
      </div>
    </mat-card>
  </div>
</div>

<ng-template #callCompleteConfirmation>
  <form #userForm="ngForm">
    <h2 matDialogTitle style="text-align: center">Please Confirm</h2>
    <p style="font-weight: '400'">
      Are you sure you want to mark this goal as complete?
    </p>
    <mat-dialog-actions align="center">
      <button mat-raised-button (click)="closeCompleteDialog('No')">No</button>
      <button
        class="btn-primary"
        type="button"
        mat-raised-button
        (click)="closeCompleteDialog('Yes')"
        [disabled]="completeProcessing"
      >
        <span *ngIf="!completeProcessing">Yes</span>
        <span *ngIf="completeProcessing"
          >Updating <i class="fa fa-spinner fa-spin"></i
        ></span>
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>

<ng-template #callDeleteConfirmation>
  <form #userForm="ngForm">
    <h2 matDialogTitle style="color: red; text-align: center">
      Delete Confirmation
    </h2>
    <p style="font-weight: '400'">
      Are you sure you want to
      <span style="color: red; font-weight: bold">delete</span> this goal?
    </p>
    <mat-dialog-actions align="center">
      <button mat-raised-button (click)="closeDeleteDialog('No')">No</button>
      <button
        class="btn-primary"
        type="button"
        mat-raised-button
        (click)="closeDeleteDialog('Yes')"
        [disabled]="deleteProcessing"
      >
        <span *ngIf="!deleteProcessing">Yes</span>
        <span *ngIf="deleteProcessing"
          >Deleting <i class="fa fa-spinner fa-spin"></i
        ></span>
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>
