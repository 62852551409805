<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Mystical Experience Assessment(MEQ-30)</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Overall Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="mysticalExperienceForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >1. Freedom from the limitations of your personal self and feeling a
            unity or bond with what was felt to be greater than your personal
            self.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q1">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >2. Experience of pure being and pure awareness (beyond the world of
            sense impressions).</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q2">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >3. Experience of oneness in relation to an “inner world”
            within.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q3">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >4. Experience of the fusion of your personal self into a larger
            whole.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q4">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >5. Experience of unity with ultimate reality.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q5">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >6. Feeling that you experienced eternity or infinity.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q6">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >7. Experience of oneness or unity with objects and/or persons
            perceived in your surroundings.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q7">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >8. Experience of the insight that “all is One.”</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q8">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >9. Awareness of the life or living presence in all things.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q9">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >10. Gain of insightful knowledge experienced at an intuitive
            level.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q10">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >11. Certainty of encounter with ultimate reality (in the sense of
            being able to “know” and “see” what is really real at some point
            during your experience.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q11">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >12. You are convinced now, as you look back on your experience,
            that in it you encountered ultimate reality (i.e., that you “knew”
            and “saw” what was really real).</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q12">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >13. Sense of being at a spiritual height.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q13">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">14. Sense of reverence.</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q14">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >15. Feeling that you experienced something profoundly sacred and
            holy.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q15">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >16. Experience of amazement.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q16">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >17. Feelings of tenderness and gentleness.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q17">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >18. Feelings of peace and tranquility.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q18">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >19. Experience of ecstasy.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q19">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >20. Sense of awe or awesomeness.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q20">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">21. Feelings of joy.</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q21">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >22. Loss of your usual sense of time.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q22">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >23. Loss of your usual sense of space.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q23">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >24. Loss of usual awareness of where you were.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q24">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >25. Sense of being “outside of” time, beyond past and
            future.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q25">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >26. Being in a realm with no space boundaries.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q26">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >27. Experience of timelessness.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q27">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >28. Sense that the experience cannot be described adequately in
            words.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q28">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >29. Feeling that you could not do justice to your experience by
            describing it in words.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q29">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >30. Feeling that it would be difficult to communicate your own
            experience to others who have not had similar experiences.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q30">
              <mat-button-toggle value="none, not at all"
                >none, not at all</mat-button-toggle
              >
              <mat-button-toggle value="so slight cannot decide"
                >so slight cannot decide</mat-button-toggle
              >
              <mat-button-toggle value="slight">slight</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="strong">strong</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          mysticalExperienceForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!mysticalExperienceForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
