<!-- Group Billing -->
<form [formGroup]="billingForm">
  <!-- ******************************************************* -->
  <!-- ******************** Member Information *************** -->
  <div class="row" style="margin: 5px">
    <div
      class="col-md-12"
      *ngIf="selectedMemberId != 'All Members'"
      style="margin-top: 5px; margin-bottom: 25px; padding: 12px"
    >
      <!-- Billing Details -->
      <div
        class="justifyCenterContainer"
        style="margin: 12px 0px; text-align: center"
      >
        <label
          class="labelHeader"
          style="
            margin: 0;
            color: #2196f3;
            font-style: italic;
            text-decoration: underline;
          "
        >
          Billing Details for {{ memberNotes.member?.lastName }},
          {{ memberNotes.member?.firstName }}
          {{ memberNotes.member?.middleName }}
        </label>
      </div>

      <!-- Billing Type -->
      <div style="margin-bottom: 12px">
        <label class="labelBold" style="margin-top: 25px">
          Billing Type <span style="color: red">*</span>
        </label>
        <mat-radio-group
          aria-label="Select an option"
          formControlName="billingType"
          style="column-gap: 15px; display: flex; flex-wrap: wrap"
          color="primary"
          (change)="billingTypeChanged($event)"
        >
          <mat-radio-button value="Self-Pay"> Self-Pay </mat-radio-button>
          <mat-radio-button value="Insurance"> Insurance </mat-radio-button>
        </mat-radio-group>
      </div>

      <!-- Primary Insurance Details -->
      <div style="margin-bottom: 12px">
        <div class="justifyCenterContainer">
          <mat-icon>contact_emergency</mat-icon>
          <label class="labelHeader" style="margin: 0">
            Primary Insurance Details
            <span
              style="color: red"
              *ngIf="billingForm.controls['billingType'].value == 'Insurance'"
            >
              *
            </span>
          </label>
        </div>
        <!-- Add button -->
        <div
          *ngIf="sessionDetails?.status === 'Active'"
          style="text-align: right"
        >
          <button
            mat-raised-button
            color="primary"
            type="button"
            (click)="searchInsurance('Primary')"
          >
            + Add Primary Insurance
          </button>
        </div>
        <!-- // For Primary Insurance type and indicator -->
        <mat-error
          *ngIf="billingForm.controls['primaryInsuranceDetails'].invalid"
        >
          Please select/add a primary insurance
        </mat-error>
        <!-- Insurance Details -->
        <div
          class="row"
          *ngIf="billingForm.controls['primaryInsuranceDetails'].value"
        >
          <!-- Insurance Indicators -->
          <div class="col-md-6">
            <label class="labelBold">
              Type of Health Insurance
              <span style="opacity: 0.5; font-size: 12px">
                (HCFA 1500 Box 1)
              </span>
            </label>
            <mat-form-field appearance="outline">
              <mat-select
                formControlName="primaryHealthInsuranceType"
                (selectionChange)="updateMemberBillingDetails()"
              >
                <mat-option
                  *ngFor="let insuranceType of healthInsuranceTypeList"
                  [value]="insuranceType.id"
                >
                  {{ insuranceType.description }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  billingForm.controls['primaryHealthInsuranceType'].invalid
                "
              >
                Please select the health insurance type
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <label class="labelBold"> Claim Filing Indicator </label>
            <mat-form-field appearance="outline">
              <mat-select
                formControlName="primaryClaimFilingIndicator"
                (selectionChange)="updateMemberBillingDetails()"
              >
                <mat-option
                  *ngFor="let indicatorType of claimFilingIndicatorList"
                  [value]="indicatorType.id"
                >
                  {{ indicatorType.description }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  billingForm.controls['primaryClaimFilingIndicator'].invalid
                "
              >
                Please select the claim indicator type
              </mat-error>
            </mat-form-field>
          </div>

          <!-- Insurance Information -->
          <div
            style="margin-top: 15px; margin-bottom: 15px; position: relative"
          >
            <app-insurance-card
              [insurance]="
                billingForm.controls['primaryInsuranceDetails'].value
              "
              [patient]="memberNotes.member"
              parentClass="Session"
              [therapySession]="sessionDetails"
            ></app-insurance-card>
            <!-- Insurance Action -->
            <div
              style="position: absolute; right: 25px; top: 20px"
              *ngIf="sessionDetails?.status === 'Active'"
            >
              <mat-icon
                style="color: red; cursor: pointer"
                (click)="removeInsurance('Primary')"
              >
                delete_forever
              </mat-icon>
            </div>
          </div>

          <!-- Prior Auth Information -->
          <label class="labelBold">
            Is there a Prior Authorization Code?
            <span style="opacity: 0.5; font-size: 12px">
              (Box 23 on the CMS1500)
            </span>
          </label>
          <mat-radio-group
            aria-label="Select an option"
            formControlName="priorAuthCode"
            style="column-gap: 15px; display: flex; flex-wrap: wrap"
            color="primary"
            (change)="priorAuthChanged($event)"
          >
            <mat-radio-button value="Yes"> Yes </mat-radio-button>
            <mat-radio-button value="No"> No </mat-radio-button>
          </mat-radio-group>
          <!-- Look up prior auth code -->
          <div
            style="text-align: right"
            *ngIf="
              billingForm.controls['priorAuthCode'].value === 'Yes' &&
              sessionDetails?.status === 'Active'
            "
          >
            <button
              type="button"
              mat-raised-button
              color="primary"
              (click)="lookUpPriorAuth()"
            >
              <i class="material-icons">search</i> Lookup Prior Auth
            </button>
          </div>
          <!-- Prior auth code details -->
          <div
            *ngIf="billingForm.controls['priorAuthDetails'].value"
            style="margin-top: 15px"
          >
            <div class="twoGridContainer">
              <label class="labelBold"> Prior Auth # </label>
              <label class="labelText">
                {{ billingForm.controls['priorAuthDetails'].value.authNumber }}
              </label>
              <label class="labelBold"> Effective Start </label>
              <label class="labelText">
                {{
                  billingForm.controls['priorAuthDetails'].value
                    .effectiveStartDate | date : 'MM/dd/y'
                }}
              </label>
              <label class="labelBold"> Effective End </label>
              <label class="labelText">
                {{
                  billingForm.controls['priorAuthDetails'].value
                    .effectiveEndDate | date : 'MM/dd/y'
                }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <!-- Secondary Insurance Details -->
      <div style="margin-bottom: 12px">
        <div class="justifyCenterContainer">
          <mat-icon>style</mat-icon>
          <label class="labelHeader" style="margin: 0">
            Secondary Insurance Details
          </label>
        </div>
        <!-- Is there another -->
        <label class="labelBold" style="margin-top: 25px">
          Is there another Health Benefit Plan?
          <span style="opacity: 0.5; font-size: 12px">
            (Box 11d on the CMS1500)
          </span>
        </label>
        <mat-radio-group
          aria-label="Select an option"
          formControlName="anotherHealthPlan"
          style="column-gap: 15px; display: flex; flex-wrap: wrap"
          color="primary"
          (change)="secondaryInsuranceChaged($event)"
        >
          <mat-radio-button value="Yes"> Yes </mat-radio-button>
          <mat-radio-button value="No"> No </mat-radio-button>
        </mat-radio-group>

        <!-- Add button -->
        <div
          style="text-align: right"
          *ngIf="
            billingForm.controls['anotherHealthPlan'].value === 'Yes' &&
            sessionDetails?.status === 'Active'
          "
        >
          <button
            mat-raised-button
            color="primary"
            type="button"
            (click)="searchInsurance('Secondary')"
          >
            + Add Secondary Insurance
          </button>
        </div>
        <!-- Error -->
        <mat-error
          *ngIf="billingForm.controls['secondaryInsuranceDetails'].invalid"
        >
          Please select/add a secondary insurance
        </mat-error>
        <!-- Display Secondary Insurance -->
        <div
          class="row"
          *ngIf="billingForm.controls['secondaryInsuranceDetails'].value"
        >
          <!-- Insurance Indicators -->
          <div class="col-md-6">
            <label class="labelBold">
              Type of Health Insurance
              <span style="opacity: 0.5; font-size: 12px">
                (HCFA 1500 Box 1)
              </span>
            </label>
            <mat-form-field appearance="outline">
              <mat-select
                formControlName="secondaryHealthInsuranceType"
                (selectionChange)="updateMemberBillingDetails()"
              >
                <mat-option
                  *ngFor="let insuranceType of healthInsuranceTypeList"
                  [value]="insuranceType.id"
                >
                  {{ insuranceType.description }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  billingForm.controls['secondaryHealthInsuranceType'].invalid
                "
              >
                Please select the health insurance type
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <label class="labelBold"> Claim Filing Indicator </label>
            <mat-form-field appearance="outline">
              <mat-select
                formControlName="secondaryClaimFilingIndicator"
                (selectionChange)="updateMemberBillingDetails()"
              >
                <mat-option
                  *ngFor="let indicatorType of claimFilingIndicatorList"
                  [value]="indicatorType.id"
                >
                  {{ indicatorType.description }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  billingForm.controls['secondaryClaimFilingIndicator'].invalid
                "
              >
                Please select the claim indicator type
              </mat-error>
            </mat-form-field>
          </div>

          <!-- Insurance Information -->
          <div
            style="margin-top: 15px; margin-bottom: 15px; position: relative"
          >
            <app-insurance-card
              [insurance]="
                billingForm.controls['secondaryInsuranceDetails'].value
              "
              [patient]="memberNotes.member"
              parentClass="Session"
              [therapySession]="sessionDetails"
            ></app-insurance-card>
            <!-- Insurance Action -->
            <div
              style="position: absolute; right: 25px; top: 20px"
              *ngIf="sessionDetails?.status === 'Active'"
            >
              <mat-icon
                style="color: red; cursor: pointer"
                (click)="removeInsurance('Secondary')"
              >
                delete_forever
              </mat-icon>
            </div>
          </div>
        </div>
      </div>

      <!-- Diagnosis -->
      <div style="margin-bottom: 12px">
        <label class="labelHeader"> Diagnosis Codes (Maximum 12)</label>
        <!-- Add Diagnosis Button -->
        <div
          style="text-align: right; margin-bottom: 10px"
          *ngIf="
            (!memberNotes?.diagnosisCodes ||
              memberNotes.diagnosisCodes.length < 12) &&
            sessionDetails?.status === 'Active'
          "
        >
          <button mat-raised-button color="primary" (click)="addDiagnosis()">
            + Diagnosis Code
          </button>
        </div>
        <!-- For No Diagnosis Selected -->
        <div
          class="col-md-12"
          *ngIf="!currentMemberDiagnosis || currentMemberDiagnosis.length == 0"
        >
          <mat-error>No Diagnosis has been added</mat-error>
        </div>
        <!-- Progress bar -->
        <mat-progress-bar
          mode="indeterminate"
          *ngIf="diagnosisProcessing"
        ></mat-progress-bar>
        <!--  Diagnosis Selected -->
        <div
          class="col-md-12"
          *ngIf="
            currentMemberDiagnosis &&
            currentMemberDiagnosis.length > 0 &&
            !diagnosisProcessing
          "
        >
          <div class="table-responsive mat-elevation-z8">
            <table mat-table [dataSource]="currentMemberDiagnosis">
              <ng-container matColumnDef="diagnosisIndex">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row" style="color: #555555">
                  {{ row?.diagnosisIndex }}
                </td>
              </ng-container>
              <ng-container matColumnDef="moveArrows">
                <th mat-header-cell *matHeaderCellDef></th>
                <td
                  mat-cell
                  *matCellDef="let row; let i = index"
                  style="column-gap: 5px"
                >
                  <button
                    mat-mini-fab
                    (click)="moveDiagnosis('UP', i)"
                    style="margin: 5px; background: transparent; color: #555555"
                  >
                    <mat-icon style="color: #555555 !important">
                      arrow_upward
                    </mat-icon>
                  </button>

                  <button
                    mat-mini-fab
                    (click)="moveDiagnosis('DOWN', i)"
                    style="margin: 5px; background: transparent; color: #555555"
                  >
                    <mat-icon style="color: #555555 !important">
                      arrow_downward
                    </mat-icon>
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="diagnosisCode">
                <th mat-header-cell *matHeaderCellDef>ICD Code</th>
                <td mat-cell *matCellDef="let row" style="max-width: 350px">
                  {{ row.icdCode }}
                </td>
              </ng-container>

              <ng-container matColumnDef="diagnosisDescription">
                <th mat-header-cell *matHeaderCellDef>Description</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.shortDesc }}
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let row; let i = index">
                  <button
                    mat-icon-button
                    color="warn"
                    (click)="removeDiagnosis(i)"
                    type="button"
                  >
                    <mat-icon> cancel </mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="diagnosisColumn"></tr>
              <tr mat-row *matRowDef="let row; columns: diagnosisColumn"></tr>
            </table>
          </div>
        </div>
      </div>

      <!-- Charges -->
      <div style="margin-bottom: 12px">
        <label class="labelHeader"> Charges (Maximum 6)</label>
        <!-- Add Charge Button -->
        <div
          style="text-align: right; margin-bottom: 10px"
          *ngIf="
            (!currentMemberCharges || currentMemberCharges.length < 12) &&
            sessionDetails?.status === 'Active'
          "
        >
          <button mat-raised-button color="primary" (click)="addCharge()">
            + Charge
          </button>
        </div>
        <!-- No Charge Message -->
        <div
          class="col-md-12"
          *ngIf="!currentMemberCharges || currentMemberCharges.length == 0"
        >
          <mat-error> No charge has been added. </mat-error>
        </div>
        <!-- Progress bar -->
        <mat-progress-bar
          mode="indeterminate"
          *ngIf="chargeProcessing"
        ></mat-progress-bar>
        <!-- Charge Display -->
        <div
          class="col-md-12"
          *ngIf="
            currentMemberCharges &&
            currentMemberCharges.length > 0 &&
            !chargeProcessing
          "
        >
          <div class="table-responsive mat-elevation-z8">
            <table mat-table [dataSource]="currentMemberCharges">
              <ng-container matColumnDef="cptIndex">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                  <b>{{ row.cptIndex }}</b>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>
              <ng-container matColumnDef="moveArrows">
                <th mat-header-cell *matHeaderCellDef></th>
                <td
                  mat-cell
                  *matCellDef="let row; let i = index"
                  style="column-gap: 15px"
                >
                  <button
                    mat-mini-fab
                    (click)="moveCharge('UP', i)"
                    style="margin: 5px; background: transparent; color: #555555"
                  >
                    <mat-icon> arrow_upward </mat-icon>
                  </button>
                  <button
                    mat-mini-fab
                    (click)="moveCharge('DOWN', i)"
                    style="margin: 5px; background: transparent; color: #555555"
                  >
                    <mat-icon> arrow_downward </mat-icon>
                  </button>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>

              <ng-container matColumnDef="cptCode">
                <th mat-header-cell *matHeaderCellDef>Procedure/CPT Code</th>
                <td mat-cell *matCellDef="let row" style="max-width: 350px">
                  {{ row.cptCode }}
                  <span *ngIf="row.description"> - {{ row.description }}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>
              <ng-container matColumnDef="m1">
                <th mat-header-cell *matHeaderCellDef>M1</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.modifier1 }}
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>
              <ng-container matColumnDef="m2">
                <th mat-header-cell *matHeaderCellDef>M2</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.modifier2 }}
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>
              <ng-container matColumnDef="m3">
                <th mat-header-cell *matHeaderCellDef>M3</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.modifier3 }}
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>
              <ng-container matColumnDef="m4">
                <th mat-header-cell *matHeaderCellDef>M4</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.modifier4 }}
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>
              <ng-container matColumnDef="diagPointer">
                <th mat-header-cell *matHeaderCellDef>Diagnosis Pointer</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.diagnosisPointer }}
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>
              <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef>Rate</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.rate | currency : 'USD' }}
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>
              <ng-container matColumnDef="unit">
                <th mat-header-cell *matHeaderCellDef>Units</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.defaultUnit }}
                </td>
                <td mat-footer-cell *matFooterCellDef style="font-weight: 500">
                  Total
                </td>
              </ng-container>
              <ng-container matColumnDef="charges">
                <th mat-header-cell *matHeaderCellDef>Charges</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.charges | currency : 'USD' }}
                </td>
                <td mat-footer-cell *matFooterCellDef style="font-weight: 500">
                  {{ getTotal() | currency : 'USD' }}
                </td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let row; let i = index">
                  <button
                    mat-icon-button
                    (click)="editCharge(row, i)"
                    type="button"
                  >
                    <mat-icon style="color: #a8a8a8; font-size: 20px">
                      edit
                    </mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    color="warn"
                    (click)="removeCharge(i)"
                    type="button"
                  >
                    <mat-icon> cancel </mat-icon>
                  </button>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="chargesColumn"></tr>
              <tr mat-row *matRowDef="let row; columns: chargesColumn"></tr>
              <tr mat-footer-row *matFooterRowDef="chargesColumn"></tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- ******************************************************* -->
  <!-- ******************* Group Information ***************** -->
  <div
    class="row"
    style="margin: 5px"
    *ngIf="selectedMemberId === 'All Members'"
  >
    <div
      class="col-md-12"
      style="margin-top: 5px; margin-bottom: 25px; padding: 12px"
    >
      <!-- Billing Details -->
      <div
        class="justifyCenterContainer"
        style="margin: 12px 0px; text-align: center"
      >
        <label
          class="labelHeader"
          style="font-style: italic; text-decoration: underline"
        >
          Billing Details for All Group Members
        </label>
      </div>
      <!-- Left Side is for billing details -->
      <div class="row">
        <div class="col-md-6" style="margin-bottom: 15px">
          <!-- Billing Provider -->
          <div class="justifyCenterContainer" style="margin-bottom: 12px">
            <mat-icon>maps_home_work</mat-icon>
            <label class="labelHeader" style="margin: 0">
              Billing Provider</label
            >
          </div>
          <mat-card
            appearance="raised"
            class="mat-elevation-z8"
            style="border-radius: 15px; position: relative"
          >
            <div class="twoGridContainer">
              <!-- Provider Name -->
              <label class="labelBold">Billing Provider</label>
              <label class="labelText">
                {{ sessionDetails?.billingProviderName }}
              </label>
              <!-- Provider Address -->
              <label class="labelBold">Address</label>
              <label class="labelText">
                {{ sessionDetails?.billingProviderAddressLine1 }}
                {{ sessionDetails?.billingProviderAddressLine2 }}
                <br />
                {{ sessionDetails?.billingProviderCity }},
                {{ sessionDetails?.billingProviderState }},
                {{ sessionDetails?.billingProviderZip }}
              </label>
              <!-- Provider Phone -->
              <label class="labelBold">Phone</label>
              <label class="labelText">
                <span *ngIf="sessionDetails?.billingProviderPhone">
                  {{ sessionDetails?.billingProviderPhone | phoneFormat }}
                </span>
              </label>
              <!-- Provider NPI -->
              <label class="labelBold"> Group NPI</label>
              <label class="labelText">
                {{ sessionDetails?.billingProviderGroupNPI }}
              </label>
              <!-- Provider Taxonomy -->
              <label class="labelBold"> Taxonomy Code </label>
              <label class="labelText">
                {{ sessionDetails?.billingProviderTaxonomyCode }}
              </label>
            </div>
          </mat-card>

          <!-- Rendering Provider -->
          <br />
          <div class="justifyCenterContainer" style="margin-bottom: 12px">
            <mat-icon>person</mat-icon>
            <label class="labelHeader" style="margin: 0">
              Rendering Provider</label
            >
          </div>
          <mat-card
            appearance="raised"
            class="mat-elevation-z8"
            style="border-radius: 15px; position: relative"
          >
            <div class="twoGridContainer">
              <!-- Provider Name -->
              <label class="labelBold">Provider Name</label>
              <label class="labelText">
                {{ sessionDetails.physicianLastName }},{{ ' ' }}
                {{ sessionDetails.physicianFirstName }}{{ ' ' }}
                {{ sessionDetails.physicianMiddleName }}
              </label>
              <!-- Provider Email -->
              <label class="labelBold">Provider Email</label>
              <label class="labelText">
                {{ sessionDetails.physicianEmail }}
              </label>
              <!-- Provider Phone -->
              <label class="labelBold">Provider Phone</label>
              <label class="labelText">
                <span *ngIf="sessionDetails.physicianPhone">
                  {{ sessionDetails?.physicianPhone | phoneFormat }}
                </span>
              </label>
              <!-- Provider NPI -->
              <label class="labelBold">NPI</label>
              <label class="labelText">
                <span *ngIf="sessionDetails.physicianPhone">
                  {{ sessionDetails?.physicianNPI }}
                </span>
              </label>
              <!-- Provider NPI -->
              <label class="labelBold">Taxonomy</label>
              <label class="labelText">
                <span *ngIf="sessionDetails.physicianPhone">
                  {{ sessionDetails?.physicianTaxonomyCode }}
                </span>
              </label>
            </div>
          </mat-card>

          <!-- Supervising Provider -->
          <br />
          <div class="justifyCenterContainer" style="margin-bottom: 12px">
            <mat-icon>admin_panel_settings</mat-icon>
            <label class="labelHeader" style="margin: 0">
              Supervising Provider</label
            >
          </div>
          <!-- Yes No Option -->
          <mat-card
            appearance="raised"
            class="mat-elevation-z8"
            style="border-radius: 15px; position: relative"
          >
            <label class="labelBold">
              Is there a supervising rendering provider?
              <span style="color: red">*</span>
            </label>
            <mat-radio-group
              aria-label="Select an option"
              formControlName="supervisingRenderingProvider"
              style="column-gap: 15px; display: flex; flex-wrap: wrap"
              color="primary"
              (change)="supervisingProviderChanged($event)"
            >
              <mat-radio-button value="Yes"> Yes </mat-radio-button>
              <mat-radio-button value="No"> No </mat-radio-button>
            </mat-radio-group>
            <!-- Select SRP Option -->
            <div
              style="text-align: right"
              *ngIf="
                billingForm.controls['supervisingRenderingProvider'].value ===
                'Yes'
              "
            >
              <button
                mat-raised-button
                color="primary"
                type="button"
                (click)="editSupervisingProvider()"
              >
                Select Supervising Provider
              </button>
            </div>
            <!-- Displaying Selected Supervisor -->
            <label
              class="labelHeader"
              style="margin-top: 15px"
              *ngIf="
                billingForm.controls['supervisingRenderingProvider'].value ===
                  'Yes' && billingForm.controls['supervisingRPId'].valid
              "
            >
              Supervisor Details
            </label>
            <!-- SuperVising Provider Details -->
            <div
              class="twoGridContainer"
              *ngIf="
                billingForm.controls['supervisingRenderingProvider'].value ===
                  'Yes' && billingForm.controls['supervisingRPId'].valid
              "
            >
              <!-- Supervisor Name -->
              <label class="labelBold">Name</label>
              <label class="labelText">
                {{ billingForm.controls['supervisingRPLastName'].value }},{{
                  ' '
                }}
                {{ billingForm.controls['supervisingRPFirstName'].value
                }}{{ ' ' }}
                {{ billingForm.controls['supervisingRPMiddleName'].value }}
              </label>
              <!-- Supervisor Email -->
              <label class="labelBold">Email</label>
              <label class="labelText">
                {{ billingForm.controls['supervisingRPEmail'].value }}
              </label>
              <!-- Supervisor Phone -->
              <label class="labelBold">Phone</label>
              <label class="labelText">
                {{
                  billingForm.controls['supervisingRPPhone'].value | phoneFormat
                }}
              </label>
              <!-- Supervisor NPI -->
              <label class="labelBold">NPI</label>
              <label class="labelText">
                {{ billingForm.controls['supervisingRPNPI'].value }}
              </label>
              <!-- Supervisor NPI -->
              <label class="labelBold">Taxonomy</label>
              <label class="labelText">
                {{ billingForm.controls['supervisingRPTaxonomyCode'].value }}
              </label>
            </div>
          </mat-card>

          <!-- Referring Provider -->
          <br />
          <div class="justifyCenterContainer" style="margin-bottom: 12px">
            <mat-icon>people_alt</mat-icon>
            <label class="labelHeader" style="margin: 0">
              Referring Provider</label
            >
          </div>
          <mat-card
            appearance="raised"
            class="mat-elevation-z8"
            style="border-radius: 15px; position: relative"
          >
            <label class="labelBold">
              Is there a referring provider?
              <span style="color: red">*</span>
            </label>
            <mat-radio-group
              aria-label="Select an option"
              formControlName="referringProvider"
              style="column-gap: 15px; display: flex; flex-wrap: wrap"
              color="primary"
              (change)="referringProviderChanged($event)"
            >
              <mat-radio-button value="Yes"> Yes </mat-radio-button>
              <mat-radio-button value="No"> No </mat-radio-button>
            </mat-radio-group>

            <div
              *ngIf="billingForm.controls['referringProvider'].value === 'Yes'"
              style="text-align: right"
            >
              <button
                type="button"
                mat-raised-button
                color="primary"
                (click)="selectReferringProvider()"
              >
                Select Referring Provider
              </button>
            </div>

            <!-- Referring Provider Details -->
            <label
              class="labelHeader"
              style="margin-top: 15px"
              *ngIf="
                billingForm.controls['referringProvider'].value === 'Yes' &&
                billingForm.controls['referringProviderId'].valid
              "
            >
              Referring Provider Details
            </label>
            <!-- Referring Provider Details -->
            <div
              class="twoGridContainer"
              *ngIf="
                billingForm.controls['referringProvider'].value === 'Yes' &&
                billingForm.controls['referringProviderId'].valid
              "
            >
              <!-- Referring Provider Name -->
              <label class="labelBold">Name</label>
              <label class="labelText">
                {{ billingForm.controls['referringProviderLastName'].value }},{{
                  ' '
                }}
                {{ billingForm.controls['referringProviderFirstName'].value
                }}{{ ' ' }}
                {{ billingForm.controls['referringProviderMiddleName'].value }}
              </label>
              <!-- Referring Provider NPI -->
              <label class="labelBold">NPI</label>
              <label class="labelText">
                {{ billingForm.controls['referringProviderNPI'].value }}
              </label>
              <!-- Supervisor NPI -->
              <label class="labelBold">Taxonomy</label>
              <label class="labelText">
                {{ billingForm.controls['referringProviderTaxonomy'].value }}
              </label>
            </div>
          </mat-card>
        </div>

        <!-- Right Side Billing Details -->
        <div class="col-md-6" style="margin-bottom: 15px">
          <!-- Billing Details -->
          <div class="justifyCenterContainer" style="margin-bottom: 12px">
            <mat-icon>receipt_long</mat-icon>
            <label class="labelHeader" style="margin: 0">
              Billing Details</label
            >
          </div>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8"
            style="border-radius: 15px; position: relative"
          >
            <!-- place of service code -->
            <div style="margin-bottom: 12px">
              <label class="labelBold">
                Place of Service Code
                <span style="opacity: 0.5; font-size: 12px">
                  (Box 24b on the CMS1500)
                </span>
                <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select
                  placeholder="Place of Service Code"
                  formControlName="billingProviderPOSCode"
                  (selectionChange)="updateAllGroupMemberBilling()"
                >
                  <mat-option
                    *ngFor="let pos of placeOfService"
                    [value]="pos.code"
                  >
                    {{ pos.code }} - {{ pos.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="billingForm.controls['billingProviderPOSCode'].invalid"
                >
                  Please select the place of service code
                </mat-error>
              </mat-form-field>
            </div>

            <!-- emergency service -->
            <div style="margin-bottom: 12px">
              <label class="labelBold">
                Is this an emergency service?
                <span style="opacity: 0.5; font-size: 12px">
                  (Box 24c on the CMS1500)
                </span>
              </label>
              <mat-radio-group
                aria-label="Select an option"
                formControlName="emgType"
                style="column-gap: 15px; display: flex; flex-wrap: wrap"
                color="primary"
                (change)="updateAllGroupMemberBilling()"
              >
                <mat-radio-button value="NA"> NA </mat-radio-button>
                <mat-radio-button value="Yes"> Yes </mat-radio-button>
                <mat-radio-button value="No"> No </mat-radio-button>
              </mat-radio-group>
            </div>

            <!-- EPSDT -->
            <div class="row">
              <div class="col-md-6" style="margin-top: 12px">
                <label class="labelBold">
                  EPSDT
                  <span style="opacity: 0.5; font-size: 12px">
                    (Box 24H on the CMS1500)
                  </span>
                </label>
                <br />
                <mat-radio-group
                  aria-label="Select an option"
                  formControlName="epsdtFamilyPlan"
                  style="column-gap: 15px; display: flex; flex-wrap: wrap"
                  color="primary"
                  (change)="epsdtTypeChanged($event)"
                >
                  <mat-radio-button value="NA"> NA </mat-radio-button>
                  <mat-radio-button value="Yes"> Yes </mat-radio-button>
                  <mat-radio-button value="No"> No </mat-radio-button>
                </mat-radio-group>
              </div>
              <div
                class="col-md-6"
                style="margin-bottom: 12px"
                *ngIf="billingForm.controls['epsdtFamilyPlan'].value === 'Yes'"
              >
                <label class="labelBold">
                  EPSDT Condition Indicator
                  <span style="opacity: 0.5; font-size: 12px">
                    (Shaded area of Box 24H on the CMS1500)
                  </span>
                </label>
                <mat-form-field appearance="outline">
                  <mat-select
                    formControlName="epsdtType"
                    placeholder=""
                    (selectionChange)="updateAllGroupMemberBilling()"
                  >
                    <mat-option value=""></mat-option>
                    <mat-option
                      [value]="condition.id"
                      *ngFor="let condition of epsdtConditionTypes"
                    >
                      {{ condition.id }} - {{ condition.description }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <!-- Is the Member Condition Related To -->
            <div style="margin-bottom: 12px">
              <label class="labelBold" style="margin-top: 12px">
                Is the Member Condition Related To
                <span style="opacity: 0.5; font-size: 12px">
                  (Box 10 on the CMS1500)
                </span>
              </label>
            </div>

            <!-- Employment -->
            <div style="padding-left: 45px">
              <label class="labelBold">
                Employment
                <span style="opacity: 0.5; font-size: 12px">
                  (Box 10a on the CMS1500)
                </span>
              </label>
              <br />
              <mat-radio-group
                aria-label="Select an option"
                formControlName="conditionRTEmployment"
                style="column-gap: 15px; display: flex; flex-wrap: wrap"
                color="primary"
                (change)="updateAllGroupMemberBilling()"
              >
                <mat-radio-button value="Yes"> Yes </mat-radio-button>
                <mat-radio-button value="No"> No </mat-radio-button>
              </mat-radio-group>
            </div>

            <!-- Auto Accident -->
            <div style="margin-top: 12px; padding-left: 45px">
              <div class="row">
                <!-- General Question -->
                <div class="col-12">
                  <label class="labelBold">
                    Auto Accident
                    <span style="opacity: 0.5; font-size: 12px">
                      (Box 10b on the CMS1500)
                    </span>
                  </label>
                  <br />
                  <mat-radio-group
                    aria-label="Select an option"
                    formControlName="conditionRTAutoAccident"
                    style="column-gap: 15px; display: flex; flex-wrap: wrap"
                    color="primary"
                    (change)="autoAccidentTypeChanged($event)"
                  >
                    <mat-radio-button value="Yes"> Yes </mat-radio-button>
                    <mat-radio-button value="No"> No </mat-radio-button>
                  </mat-radio-group>
                </div>
                <!-- State Question -->
                <div
                  class="col"
                  *ngIf="
                    billingForm.controls['conditionRTAutoAccident'].value ===
                    'Yes'
                  "
                >
                  <label class="labelText">
                    Place (State) <span style="color: red">*</span></label
                  >
                  <mat-form-field appearance="outline">
                    <mat-select
                      formControlName="conditionRTAutoState"
                      placeholder="State"
                      (selectionChange)="autoAccidentTypeChanged($event)"
                    >
                      <mat-option
                        [value]="state.id"
                        *ngFor="let state of usStateList"
                      >
                        {{ state.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        billingForm.controls['conditionRTAutoState'].invalid
                      "
                    >
                      Please select the state
                    </mat-error>
                  </mat-form-field>
                </div>

                <!-- Date Question -->
                <div
                  class="col"
                  *ngIf="
                    billingForm.controls['conditionRTAutoAccident'].value ===
                    'Yes'
                  "
                >
                  <label class="labelText">
                    Accident Date <span style="color: red">*</span>
                  </label>

                  <mat-form-field appearance="outline">
                    <input
                      formControlName="conditionRTAutoDate"
                      matInput
                      [matDatepicker]="accidentDatePicker"
                      placeholder="Date"
                      [max]="currentTime"
                      autoSlashDate
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="accidentDatePicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #accidentDatePicker></mat-datepicker>
                    <mat-error
                      *ngIf="
                        billingForm.controls['conditionRTAutoDate'].invalid
                      "
                    >
                      Please select a valid date
                    </mat-error>
                  </mat-form-field>
                </div>

                <!-- Qualifier Question -->
                <div
                  class="col"
                  *ngIf="
                    billingForm.controls['conditionRTAutoAccident'].value ===
                    'Yes'
                  "
                >
                  <label class="labelText">
                    Qualifier <span style="color: red">*</span></label
                  >
                  <mat-form-field appearance="outline">
                    <mat-select
                      formControlName="conditionRTAutoQualifier"
                      placeholder="Qualifier"
                      (selectionChange)="updateAllGroupMemberBilling()"
                    >
                      <mat-option
                        [value]="qualifier.id"
                        *ngFor="let qualifier of autoAccidentQualifierList"
                      >
                        {{ qualifier.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        billingForm.controls['conditionRTAutoQualifier'].invalid
                      "
                    >
                      Please select the qualifier
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <!-- Other Accident -->
            <div class="col-md-12" style="margin-top: 12px; padding-left: 45px">
              <label class="labelBold">
                Other Accident
                <span style="opacity: 0.5; font-size: 12px">
                  (Box 10c on the CMS1500)
                </span>
              </label>
              <br />
              <mat-radio-group
                aria-label="Select an option"
                formControlName="conditionRTOtherAccident"
                style="column-gap: 15px; display: flex; flex-wrap: wrap"
                color="primary"
                (change)="otherAccidentTypeChanged($event)"
              >
                <mat-radio-button value="Yes"> Yes </mat-radio-button>
                <mat-radio-button value="No"> No </mat-radio-button>
              </mat-radio-group>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</form>
