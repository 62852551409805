<div class="container">
  <div class="dialogTitle">
    <p></p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="row" style="margin-top: 15px">
    <div class="col-md-12" style="display: flex; flex-direction: column">
      <label class="labelHeader" style="text-align: center; font-weight: 500">
        Informed Consent
      </label>
      <label class="labelText" style="font-weight: 500">
        Service Provider(s)
      </label>
      <label>
        The clinician is serious and committed to following the state licensing
        board rules and abiding by the code of ethics, both of which are in
        place to protect the member (patient).
      </label>
      <label class="labelText" style="font-weight: 500"> Services </label>
      <label>
        We, the Service Provider Agency, believe the key ingredient to sparking
        change and healing in the lives of our clients is the therapeutic
        relationship between the clinician and the member. The therapeutic
        relationship is unique in that it is a highly personal and, at the same
        time, contractual agreement. While it may not be easy to seek help from
        a professional, it is hoped that the member will be better able to
        understand their situation and feelings and move toward resolving their
        difficulties.
      </label>
      <label>
        Given this, it is essential for the clinician and the member to
        understand how their relationship will work and what both parties can
        expect.
        <b>
          This consent will provide a clear framework for the clinician and the
          member working together.
        </b>
      </label>
      <label>
        While every effort is made by the clinician and the agency staff to
        ensure the member's confidentiality, the member needs to be aware that
        services may be provided in a building with a public parking lot.
        Consequently, other people may see you enter, wait, or leave the
        facility.
      </label>

      <label class="labelText" style="font-weight: 500">Appointments </label>
      <label>
        Each member’s assigned clinician or the agency staff can provide the
        best phone number for canceling or rescheduling appointments. Please
        call to cancel or reschedule at least 24 hours in advance. Subsequent
        appointments are made with the treatment provider at the conclusion of
        each session.
      </label>

      <label class="labelText" style="font-weight: 500">
        Number of Visits
      </label>
      <label>
        The total number of sessions needed depends on many factors, varies from
        person to person, and will be discussed with the member by the assigned
        clinician or the agency.
      </label>

      <label class="labelText" style="font-weight: 500">
        Length of Visits
      </label>
      <label>
        The first counseling session (intake interview) may last longer than the
        typical session due to the amount of information to be gathered and the
        initial paperwork involved. Typical individual counseling sessions are
        50 minutes in length.
      </label>

      <label class="labelText" style="font-weight: 500"
        >Fees and Billing Arrangements
      </label>
      <label> All fees will be paid per the agency’s payment policy. </label>

      <label class="labelText" style="font-weight: 500">Relationship </label>
      <label>
        The member's relationship with the clinician is professional and
        therapeutic. The contact will be limited to the scheduled sessions and
        brief calls/texts to schedule future appointments. Long texts,
        voicemails, emails, and other forms of communication that are not
        face-to-face are discouraged and not in the best interest for quality
        treatment.
      </label>
      <label>
        To preserve this relationship, it is imperative that the clinician not
        have any other type of relationship with the member. Personal and
        business relationships undermine the effectiveness of the therapeutic
        relationship. The clinician cares about helping the member but cannot be
        friends or have a social or personal relationship with the member.
        Social invitations and offers of that nature will politely be turned
        down in honor of our code of ethics associated with our licensing board.
        The counselor cannot be a “friend” with the member on any form of social
        media (i.e., Facebook, Instagram, Snapchat, etc.). The clinician will
        not acknowledge the member first if the member were to see each other
        accidentally outside the therapy office. The member's right to privacy
        and confidentiality is of the utmost importance to the clinician, and
        the clinician does not wish to jeopardize the privacy. Gifts are not
        acceptable and should not be shared between the member and the
        clinician.
      </label>

      <label class="labelText" style="font-weight: 500"
        >Risks of Counseling
      </label>
      <label>
        Through counseling, the member may learn things about themself that are
        uncomfortable. Often, growth cannot occur until the member experience
        and confront issues that lead to feel sadness, sorrow, anxiety, and
        pain. The success of counseling depends on the quality of the efforts of
        both the clinician and the member and the realization that the member is
        responsible for the lifestyle choices or changes that may result from
        counseling. The Service Provider Agency always promise to see the member
        as a valuable, worthwhile human being and offer guidance and tools to
        help the member reach their goals. Traditionally, members who speak
        honestly and openly in sessions find this propels them forward in their
        self-awareness, progress towards goals, and ultimate healing.
      </label>

      <label class="labelText" style="font-weight: 500">Confidentiality</label>
      <label>
        Confidentiality means that the clinician is responsible for safeguarding
        information obtained during treatment. It is crucial that the member
        understand that all identifying information about the assessment and
        treatment is kept confidential, except as noted below in the limits of
        confidentiality. To protect the member's privacy, the member must sign a
        release of information before any information about the member is given.
      </label>
      <label>
        Should the member elect to utilize health insurance for services
        received, be aware that insurance and managed care companies often
        require information regarding diagnosis, symptoms, treatment goals,
        session notes, and prognosis about the insured before reimbursement
        occurs. Such companies have the right to request a copy of the member's
        records. When utilizing faxes, electronic communication devices, and
        web-based records management systems, there is always a level of
        vulnerability that may not be preventable despite all safeguards that
        have been put in place.
      </label>

      <label class="labelText" style="font-weight: 500">
        The member's rights regarding health care information about themself:
      </label>
      <ul>
        <li
          style="
            font-size: 14px;
            line-height: 1.42857;
            color: #808080;
            font-weight: 400;
          "
        >
          Right to inspect and obtain a copy of your medical record. The member
          must submit the request in writing.
        </li>
        <li
          style="
            font-size: 14px;
            line-height: 1.42857;
            color: #808080;
            font-weight: 400;
          "
        >
          Right to amend their record. If they feel their information is
          incorrect or incomplete, they may ask the clinician or agency staff to
          amend the information. The member must submit their request in writing
          to the address at the end of this notice. The agency staff might deny
          the request if it was not created by the agency, if the request is not
          information the agency have, or if agency find the information to be
          accurate and complete.
        </li>
        <li
          style="
            font-size: 14px;
            line-height: 1.42857;
            color: #808080;
            font-weight: 400;
          "
        >
          Right to an accounting of disclosures. The member has the right to
          request a list of their health information given to someone outside
          the agency. The member must submit their request in writing.
        </li>
        <li
          style="
            font-size: 14px;
            line-height: 1.42857;
            color: #808080;
            font-weight: 400;
          "
        >
          Right to request restrictions. The member has the right to request a
          restriction or limitation on the information the agency may use or
          disclose about them. The agency will accept the member request unless
          the information is needed to provide treatment.
        </li>
        <li
          style="
            font-size: 14px;
            line-height: 1.42857;
            color: #808080;
            font-weight: 400;
          "
        >
          Right to request confidential communication or to receive the member
          information differently. The member may request that the agency
          communicate with the member differently or at different locations. The
          agency will honor the request if the request is something the agency
          can offer to all patients equally. The member must submit the request
          in writing.
        </li>
        <li
          style="
            font-size: 14px;
            line-height: 1.42857;
            color: #808080;
            font-weight: 400;
          "
        >
          Right to a paper copy of this notice.
        </li>
      </ul>

      <label class="labelText" style="font-weight: 500">
        Limits of Confidentiality
      </label>
      <label>
        Some federal, state, and/or local laws require the agency to disclose
        the member's personal health information to law enforcement or other
        persons. Although we the agency strive to maintain confidentiality, here
        are some examples of limits to the member's confidentiality.
      </label>
      <ul>
        <li
          style="
            font-size: 14px;
            line-height: 1.42857;
            color: #808080;
            font-weight: 400;
          "
        >
          When a member represents an imminent danger to himself or herself.
        </li>
        <li
          style="
            font-size: 14px;
            line-height: 1.42857;
            color: #808080;
            font-weight: 400;
          "
        >
          When a member poses an imminent danger to another person.
        </li>
        <li
          style="
            font-size: 14px;
            line-height: 1.42857;
            color: #808080;
            font-weight: 400;
          "
        >
          In cases of suspected child abuse, spousal abuse, exploitation, or
          neglect as required by law.
        </li>
        <li
          style="
            font-size: 14px;
            line-height: 1.42857;
            color: #808080;
            font-weight: 400;
          "
        >
          Suspicions as stated above in the case of an elderly person who may be
          subjected to these abuses.
        </li>
        <li
          style="
            font-size: 14px;
            line-height: 1.42857;
            color: #808080;
            font-weight: 400;
          "
        >
          If a court of law issues a legitimate subpoena for information stated
          on the subpoena.
        </li>
        <li
          style="
            font-size: 14px;
            line-height: 1.42857;
            color: #808080;
            font-weight: 400;
          "
        >
          If a member is in therapy or being treated by order of a court of law
          or if the information is obtained to render an expert’s report to an
          attorney.
        </li>
        <li
          style="
            font-size: 14px;
            line-height: 1.42857;
            color: #808080;
            font-weight: 400;
          "
        >
          A mental health professional may disclose confidential information in
          proceedings brought by a member against a professional.
        </li>
      </ul>
      <label>
        It is important also to note that the assigned clinician might work as
        part of an agency. Members of the agency sometimes collaborate as a
        treatment team to offer you the best quality of services. The member's
        information may be discussed during this treatment team setting.
        However, all treatment team members are bound by the same rules of
        confidentiality.
      </label>

      <label class="labelText" style="font-weight: 500">
        After-Hours Emergencies
      </label>
      <label>
        Due to the outpatient setting of our agency, clinician are often
        unavailable outside of your scheduled appointments and are not available
        after business hours or on weekends. If the member find themself in a
        crisis at a time when your counselor is not available, please call 911
        immediately or arrange for transportation to your local hospital
        emergency room.
      </label>

      <label class="labelText" style="font-weight: 500">
        Counselor Incapacity
      </label>
      <label>
        If the member's assigned counselor becomes unavailable or incapacitated,
        it will become necessary for another counselor to take possession of the
        member's file and records.
      </label>

      <label class="labelText" style="font-weight: 500; margin-top: 25px">
        I {{ name }}, hereby acknowledge and agree that
      </label>
      <ul>
        <li
          style="
            font-size: 14px;
            line-height: 1.42857;
            color: #808080;
            font-weight: 400;
          "
        >
          I understand that my signature serves as confirmation of my review and
          agreement with the contents of this document.
        </li>
        <li
          style="
            font-size: 14px;
            line-height: 1.42857;
            color: #808080;
            font-weight: 400;
          "
        >
          I acknowledge that my electronic signature is legally binding and
          equivalent to my handwritten signature.
        </li>
        <li
          style="
            font-size: 14px;
            line-height: 1.42857;
            color: #808080;
            font-weight: 400;
          "
        >
          I authorize the use of my electronic signature for the responsible
          party and billing purposes related to this session.
        </li>
      </ul>
    </div>

    <div
      class="col-md-12"
      style="margin-top: 15px; text-align: center"
      *ngIf="type === 'VIEW'"
    >
      <mat-progress-bar
        mode="indeterminate"
        *ngIf="processing"
      ></mat-progress-bar>
      <img
        *ngIf="signatureURL"
        [src]="signatureURL"
        alt="signature"
        height="250"
        width="250"
        style="object-fit: contain"
      />
    </div>

    <div
      class="col-md-12"
      style="
        margin-top: 15px;
        margin-bottom: 25px;
        display: flex;
        justify-content: space-between;
      "
      *ngIf="type === 'ADD'"
    >
      <button type="button" mat-raised-button mat-dialog-close>Cancel</button>
      <button
        type="button"
        mat-raised-button
        color="primary"
        (click)="openSignaturePad()"
        [disabled]="processing"
      >
        <i class="material-icons" style="margin-right: 8px; font-size: 18px">
          edit
        </i>
        Sign <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </div>
  </div>
</div>
