<div class="row">
  <!-- Dashboard Header -->
  <div class="col-md-12">
    <app-provider-dashboard-header
      [reloadHeaderStatistics]="reloadHeaderStatistics.asObservable()"
    ></app-provider-dashboard-header>
  </div>
  <!-- Left Side to be Calendar -->
  <div class="col-md-8">
    <app-provider-dashboard-calendar
      (output)="reloadHeaderStats($event)"
    ></app-provider-dashboard-calendar>
  </div>
  <!-- Right Side to be Review Assessment -->
  <div class="col-md-4">
    <!-- Cases If Present -->
    <div
      *ngIf="hasCaseManagementFeature && hasCaseViewAccess"
      style="
        max-height: 50vh;
        overflow: auto;
        margin-top: 15px;
        border-radius: 15px;
      "
    >
      <mat-card appearance="raised" style="border-radius: 15px">
        <app-my-assigned-cases hideHeader="true"></app-my-assigned-cases>
      </mat-card>
    </div>

    <!-- Assessments -->
    <app-provider-dashboard-assessments></app-provider-dashboard-assessments>
  </div>
</div>
