import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DataTablesModel, ITimeOff } from '../../_models';

@Injectable({ providedIn: 'root' })
export class TimeOffAPIService {
  constructor(private httpClient: HttpClient) {}

  getAllMyProviderTimeOffsForSession(): Observable<DataTablesModel> {
    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/getprovidertimeoffsatmyassignedfacility`
    );
  }

  getProviderTimeOffsForSession(
    patientId: string
  ): Observable<DataTablesModel> {
    let params = new HttpParams();

    params = params.append('patientId', patientId);

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/getprovidertimeoffsatmemberfacility`,
      { params: params }
    );
  }

  getMyTimeOffs(
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/stafftimeoff`,
      { params: params }
    );
  }

  getVacationForSessions(careProviderId: string): Observable<DataTablesModel> {
    let params = new HttpParams();
    if (careProviderId)
      params = params.append('careProviderId', careProviderId);

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/vacationforsessions`,
      { params: params }
    );
  }

  addMyTimeOff(myTimeOff: ITimeOff): Observable<ITimeOff> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<ITimeOff>(`${environment.apiUrl}/myvacationdays`, myTimeOff, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateMyTimeOff(myTimeOff: ITimeOff): Observable<ITimeOff> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<ITimeOff>(`${environment.apiUrl}/myvacationdays`, myTimeOff, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  deleteTimeOff(id: string): Observable<void> {
    return this.httpClient
      .delete<void>(`${environment.apiUrl}/stafftimeoff/` + id)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getOrganizationTimeOff(
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/orgtimeoff`,
      { params: params }
    );
  }
}
