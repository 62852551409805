import { createAction, props } from '@ngrx/store';
import { IFacility } from '../../../_models';

export const getFacilityListAction = createAction(
  '[Get Facility List] Get Facility List'
);

export const getFacilityListSuccessAction = createAction(
  '[Get Facility List] Get Facility List Success',
  props<{ payload: IFacility[] }>()
);

export const getFacilityListFailedAction = createAction(
  '[Get Facility List] Get Facility List Failed',
  props<{ payload: any }>()
);

export const getFacilityResourcesAction = createAction(
  '[Get Facility Resources] Get Facility Resources'
);

export const getFacilityResourcesSuccessAction = createAction(
  '[Get Facility Resources] Get Facility Resources Success',
  props<{ payload: IFacility[] }>()
);

export const getFacilityResourcesFailedAction = createAction(
  '[Get Facility Resources] Get Facility Resources Failed',
  props<{ payload: any }>()
);
