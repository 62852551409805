<mat-card
  appearance="raised"
  class="mat-elevation-z8"
  style="border-radius: 2px"
>
  <div class="row">
    <div class="col-md-8">
      <label class="labelHeader"> Therapy Notes </label>
    </div>

    <div
      class="col-md-4"
      style="text-align: right"
      *ngIf="therapySession.status === 'Active'"
    >
      <button
        type="button"
        mat-raised-button
        class="mat-elevation-z8"
        style="color: #2196f3"
        (click)="addTherapyNote()"
      >
        + Add Therapy Note
      </button>
    </div>
  </div>

  <div *ngIf="data.total === 0 && !isLoadingResults" style="margin-top: 10px">
    <mat-error> No note found </mat-error>
  </div>

  <div class="row" *ngIf="data.total > 0" style="padding-top: 15px">
    <div class="col-md-12">
      <mat-progress-bar
        mode="indeterminate"
        *ngIf="isLoadingResults"
      ></mat-progress-bar>

      <div class="main-content" style="background-color: #f1f1f1">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-timeline card-plain">
              <div class="card-body">
                <ul class="timeline">
                  <li
                    *ngFor="let item of data.items; let index = index"
                    [class]="{ 'timeline-inverted': 0 !== index % 2 }"
                  >
                    <!--  Middle Circle Button -->
                    <div class="timeline-badge primary">
                      <i class="fa fa-sticky-note-o"></i>
                    </div>

                    <div class="timeline-panel mat-elevation-z8">
                      <!-- Action  -->
                      <div class="caseNoteAction pull-right">
                        <button
                          mat-icon-button
                          color="accent"
                          type="button"
                          (click)="editTherapyNote(item)"
                        >
                          <i class="material-icons text_align-center">edit</i>
                        </button>

                        <button
                          mat-icon-button
                          color="warn"
                          type="button"
                          (click)="deleteTherapyNote(item)"
                        >
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>

                      <!-- Text Body -->
                      <div class="timeline-body" style="padding-top: 30px">
                        <p style="font-weight: 400">{{ item.text }}</p>
                      </div>
                      <hr />

                      <!-- Text Footer -->
                      <div class="timeline-footer">
                        <div class="row" style="opacity: 0.6">
                          <div class="col-sm-12">
                            <label class="mat-small pull-right">
                              {{ item.createdDate | date : 'MM/dd/YYYY' }}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>
