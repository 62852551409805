<div class="row">
    <!-- Left Side calendar, right side cases -->
    <div class="col-md-12" style="margin-bottom: 25px">
        <div class="row">
            <!-- Left Side is calendar options and filter -->
            <div class="col-md-2" style="
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-bottom: 20px;
          ">
                <mat-card appearance="raised" class="mat-elevation-z8" style="border-radius: 15px">
                    <label class="labelText" style="display: flex">Referral Status</label>
                    <mat-form-field appearance="outline">
                        <mat-select placeholder="Status" [(ngModel)]="selectedStatus"
                            (selectionChange)="loadReferralData()">
                            <mat-option>All</mat-option>
                            <mat-option value="initial">Initial</mat-option>
                            <mat-option value="First Attempt">1st Attempt</mat-option>
                            <mat-option value="Second Attempt">2nd Attempt</mat-option>
                            <mat-option value="Third Attempt">3rd Attempt</mat-option>
                            <mat-option value="Success">Success</mat-option>
                            <mat-option value="Success">Closed</mat-option>
                            <mat-option value="Completed">Imported Member</mat-option>
                            <mat-option value="Deleted">Deleted</mat-option>
                        </mat-select>
                    </mat-form-field>
                </mat-card>
                <!-- Providers  -->
                <!-- <app-referral-staff-search
                    [providersSelectionList]="providersSelectionList"></app-referral-staff-search> -->

                <!-- Facilities  -->
                <app-facility-search [facilitiesSelectionList]="facilitiesSelectionList"></app-facility-search>
            </div>

            <div class="col-md-10" style="margin-bottom: 25px">
                <div class="row" style="max-height: 90vh; overflow: auto; border-radius: 15px">
                    <div style="border-radius: 15px">
                        <div class="row" style="padding: 10px; margin-bottom: 10px;">
                            <div class="col-md-12" style="text-align: right">
                                <button mat-raised-button color="warn" (click)="addReferral()">+ Referral
                                </button>
                            </div>
                        </div>
                        <!--Table start-->
                        <div *ngIf="!isLoading" class="row">
                            <div *ngFor="let element of referralData" class="col-md-3">
                                <mat-card apperance="raised" class="referralCard mat-elevation-z8">
                                    <div class="headerWithChipIcon">
                                    </div>
                                    <div class="row">
                                        <div class="col-6 pad20">
                                            <mat-card class=" smallText mat-elevation-z8">
                                                <div class="cardHeader"><mat-icon class="chipIcon">person</mat-icon>
                                                    Member:
                                                    {{ element.patientDetails.name }}</div>
                                                <mat-divider></mat-divider>
                                                <br />

                                                <div><b>DOB:</b> {{ element.patientDetails.dateOfBirth | date:
                                                    'MM/dd/yyyy'
                                                    }}</div>
                                                <div><b>Phone:</b> {{ element.patientDetails.phoneNumber}}</div>
                                                <div><b>Email:</b> {{ element.patientDetails.email }}</div>
                                                <div><b>Insurance:</b> {{ element.patientDetails.primaryInsurance }}
                                                </div>
                                                <div><b>Medicaid:</b> {{ element.patientDetails.medicaid }}</div>
                                                <div><b>Facility:</b> {{ getFacilityName(element.facilityId) }}</div>
                                                <div><b>Service:</b> {{ element.serviceType }}</div>
                                            </mat-card>
                                        </div>
                                        <div class="col-6 pad20">
                                            <mat-card class="smallText mat-elevation-z8">
                                                <div class="cardHeader">Referrer: {{ element.referredBy }}</div>
                                                <mat-divider></mat-divider>
                                                <br />

                                                <div><b>Area:</b> {{ element.referralZipCode }}</div>
                                                <div><b>Method:</b> {{ element.referralMethod }}</div>
                                                <div><b>Fax:</b> {{ element.referralFax }}
                                                </div>
                                                <div><b>Notes:</b> {{ element.referralNotes }}</div>

                                            </mat-card>
                                        </div>
                                        <div class="col-4 pad20">

                                            <mat-card class="smallText mat-elevation-z8"
                                                *ngIf="element.currentStep >= 1 && element.firstAttemptDate">
                                                <div class="cardHeader">1st Attempt</div>
                                                <mat-divider></mat-divider>
                                                <div class="example-expanded-content">
                                                    <b>Date:</b> {{ element.firstAttemptDate |
                                                    date:
                                                    'MM/dd/yyyy' }}
                                                    <br /><b>Time:</b> {{ element.firstAttemptHour
                                                    }}:{{
                                                    element.firstAttemptMinute }}
                                                    <br /><b>Outcome:</b> {{ element.firstAttemptResponse }}
                                                    <br /><b>By:</b> {{ element.firstAttemptLoggedBy }}
                                                </div>
                                            </mat-card>
                                            <button (click)="addAttempt(element.id, '1st')" mat-raised-button
                                                color="primary"
                                                *ngIf="!element.firstAttemptDate && element.status !== 'Deleted' && element.status !== 'Closed' && element.status !== 'Success'">
                                                <mat-icon>add</mat-icon> First
                                                Attempt
                                            </button>
                                        </div>
                                        <div class="col-4 pad20" *ngIf="element.currentStep >= 1">
                                            <mat-card class="smallText mat-elevation-z8"
                                                *ngIf="element.currentStep >= 2 && element.secondAttemptDate">
                                                <div class="cardHeader">2nd Attempt</div>
                                                <mat-divider></mat-divider>
                                                <div class="example-expanded-content">
                                                    <b>Date:</b> {{ element.secondAttemptDate |
                                                    date:'MM/dd/yyyy' }}
                                                    <br /><b>Time:</b> {{ element.secondAttemptHour
                                                    }}:{{
                                                    element.secondAttemptMinute }}
                                                    <br /><b>Outcome:</b> {{ element.secondAttemptResponse }}
                                                    <br /><b>By:</b> {{ element.secondAttemptLoggedBy }}
                                                </div>
                                            </mat-card>
                                            <button (click)="addAttempt(element.id, '2nd')" mat-raised-button
                                                color="primary"
                                                *ngIf="!element.secondAttemptDate && element.status !== 'Deleted' && element.status !== 'Closed' && element.status !== 'Success'">
                                                <mat-icon>add</mat-icon> Second
                                                Attempt
                                            </button>
                                        </div>

                                        <div class="col-4 pad20" *ngIf="element.currentStep >= 2">
                                            <mat-card class="smallText mat-elevation-z8"
                                                *ngIf="element.currentStep >= 2 && element.thirdAttemptDate">
                                                <div class="cardHeader">3rd Attempt</div>
                                                <mat-divider></mat-divider>

                                                <div class="example-expanded-content">
                                                    <b>Date:</b> {{ element.thirdAttemptDate |
                                                    date:
                                                    'MM/dd/yyyy' }}
                                                    <br /><b>Time:</b> {{ element.thirdAttemptHour
                                                    }}:{{
                                                    element.thirdAttemptMinute }}
                                                    <br /><b>Outcome:</b> {{ element.thirdAttemptResponse }}
                                                    <br /><b>By:</b> {{ element.thirdAttemptLoggedBy }}
                                                </div>
                                            </mat-card>
                                            <button (click)="addAttempt(element.id, '3rd')" mat-raised-button
                                                color="primary"
                                                *ngIf="!element.thirdAttemptDate && element.status !== 'Deleted' && element.status !== 'Closed' && element.status !== 'Success'">
                                                <mat-icon>add</mat-icon> Third
                                                Attempt
                                            </button>
                                        </div>
                                    </div>
                                    <p><button (click)="importMember(element.id)" mat-raised-button color="warn"
                                            *ngIf="element.status === 'Success'">
                                            <mat-icon>cloud_download</mat-icon> Import Member
                                        </button></p>
                                    <mat-divider></mat-divider>
                                    <div class="createdByFooter">
                                        <label style="padding:10px 0px 0px 0px">{{ element.createdDate | date:
                                            'MM/dd/yyyy'
                                            }}</label>
                                    </div>

                                    <!-- Action -->
                                    <div class="cardAction">
                                        <button mat-icon-button [matMenuTriggerFor]="menu">
                                            <mat-icon style="font-size: 18px">more_vert</mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <button mat-menu-item (click)="deleteImg('a')">
                                                <mat-icon class="material-icons-outlined">delete</mat-icon>
                                                <span>Close</span>
                                            </button>
                                        </mat-menu>
                                    </div>
                                </mat-card>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>