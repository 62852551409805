<div class="main-content" style="background-color: #eee" *ngIf="!isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div class="container-fluid" *ngIf="!isLoadingResults">
        <br />
        <div class="row">
            <div class="col-md-6">
                <mat-card>
                    <mat-card-header>
                        <h4 class="header text-center">Risk Score Graph</h4>
                    </mat-card-header>
                    <mat-card-content>
                        <div style="width: 100%">
                            <div class="chart-wrapper">
                                <canvas baseChart height="100%" [data]="lineChartData" [options]="lineChartOptions"
                                    [legend]="lineChartLegend" [type]="lineChartType" [plugins]="lineChartPlugins">
                                </canvas>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-md-6">
                <mat-card>
                    <mat-card-header>
                        <h4 class="header text-center">Interpreting the Overall Score</h4>
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    <mat-card-content>
                        <div class="row">
                            <div class="col-md-2" style="font-weight: 400">Score</div>
                            <div class="col-md-7" style="padding-top: 4px; font-weight: 400">Description</div>
                            <div class="col-md-3" style="font-weight: 400">Probablity</div>
                        </div>
                        <div class="row {{currentRiskScore===0?'highlightedRow':''}}">
                            <div class="col-md-2" style="padding-top: 4px;">
                                0
                            </div>
                            <div class="col-md-7" style="padding-top: 4px;">
                                Chance of depression symptoms within the last year
                            </div>
                            <div class="col-md-3" style="padding-top: 4px;">
                                14%
                            </div>
                        </div>
                        <div class="row {{currentRiskScore===0?'highlightedRow':''}}">
                            <div class="col-md-2" style="padding-top: 4px;">
                                0
                            </div>
                            <div class="col-md-7" style="padding-top: 4px;">
                                Chance of poor health, self reported
                            </div>
                            <div class="col-md-3" style="padding-top: 4px;">
                                16.3%
                            </div>
                        </div>
                        <div class="row {{currentRiskScore===0?'highlightedRow':''}}">
                            <div class="col-md-2" style="padding-top: 4px;">
                                0
                            </div>
                            <div class="col-md-7" style="padding-top: 4px;">
                                Chance of illegal drug usage
                            </div>
                            <div class="col-md-3" style="padding-top: 4px;">
                                6.4%
                            </div>
                        </div>
                        <div class="row {{currentRiskScore===0?'highlightedRow':''}}">
                            <div class="col-md-2" style="padding-top: 4px;">
                                0
                            </div>
                            <div class="col-md-7" style="padding-top: 4px;">
                                Chance of IV drug usage
                            </div>
                            <div class="col-md-3" style="padding-top: 4px;">
                                0.3%
                            </div>
                        </div>
                        <div class="row {{currentRiskScore===1?'highlightedRow':''}}">
                            <div class="col-md-2" style="padding-top: 4px;">
                                1
                            </div>
                            <div class="col-md-7" style="padding-top: 4px;">
                                Chance of depression symptoms within the last year
                            </div>
                            <div class="col-md-3" style="padding-top: 4px;">
                                21.4%
                            </div>
                        </div>
                        <div class="row {{currentRiskScore===1?'highlightedRow':''}}">
                            <div class="col-md-2" style="padding-top: 4px;">
                                1
                            </div>
                            <div class="col-md-7" style="padding-top: 4px;">
                                Chance of poor health, self reported
                            </div>
                            <div class="col-md-3" style="padding-top: 4px;">
                                17.8%
                            </div>
                        </div>
                        <div class="row {{currentRiskScore===1?'highlightedRow':''}}">
                            <div class="col-md-2" style="padding-top: 4px;">
                                1
                            </div>
                            <div class="col-md-7" style="padding-top: 4px;">
                                Chance of illegal drug usage
                            </div>
                            <div class="col-md-3" style="padding-top: 4px;">
                                11.4%
                            </div>
                        </div>
                        <div class="row {{currentRiskScore===1?'highlightedRow':''}}">
                            <div class="col-md-2" style="padding-top: 4px;">
                                1
                            </div>
                            <div class="col-md-7" style="padding-top: 4px;">
                                Chance of IV drug usage
                            </div>
                            <div class="col-md-3" style="padding-top: 4px;">
                                0.5%
                            </div>
                        </div>
                        <div class="row {{currentRiskScore===2?'highlightedRow':''}}">
                            <div class="col-md-2" style="padding-top: 4px;">
                                2
                            </div>
                            <div class="col-md-7" style="padding-top: 4px;">
                                Chance of depression symptoms within the last year
                            </div>
                            <div class="col-md-3" style="padding-top: 4px;">
                                31.5%
                            </div>
                        </div>
                        <div class="row {{currentRiskScore===2?'highlightedRow':''}}">
                            <div class="col-md-2" style="padding-top: 4px;">
                                2
                            </div>
                            <div class="col-md-7" style="padding-top: 4px;">
                                Chance of poor health, self reported
                            </div>
                            <div class="col-md-3" style="padding-top: 4px;">
                                19.9%
                            </div>
                        </div>
                        <div class="row {{currentRiskScore===2?'highlightedRow':''}}">
                            <div class="col-md-2" style="padding-top: 4px;">
                                2
                            </div>
                            <div class="col-md-7" style="padding-top: 4px;">
                                Chance of illegal drug usage
                            </div>
                            <div class="col-md-3" style="padding-top: 4px;">
                                19.9%
                            </div>
                        </div>
                        <div class="row {{currentRiskScore===2?'highlightedRow':''}}">
                            <div class="col-md-2" style="padding-top: 4px;">
                                2
                            </div>
                            <div class="col-md-7" style="padding-top: 4px;">
                                Chance of IV drug usage
                            </div>
                            <div class="col-md-3" style="padding-top: 4px;">
                                1.4%
                            </div>
                        </div>
                        <div class="row {{currentRiskScore===3?'highlightedRow':''}}">
                            <div class="col-md-2" style="padding-top: 4px;">
                                3
                            </div>
                            <div class="col-md-7" style="padding-top: 4px;">
                                Chance of depression symptoms within the last year
                            </div>
                            <div class="col-md-3" style="padding-top: 4px;">
                                36.2%
                            </div>
                        </div>
                        <div class="row {{currentRiskScore===3?'highlightedRow':''}}">
                            <div class="col-md-2" style="padding-top: 4px;">
                                3
                            </div>
                            <div class="col-md-7" style="padding-top: 4px;">
                                Chance of poor health, self reported
                            </div>
                            <div class="col-md-3" style="padding-top: 4px;">
                                20.3%
                            </div>
                        </div>
                        <div class="row {{currentRiskScore===3?'highlightedRow':''}}">
                            <div class="col-md-2" style="padding-top: 4px;">
                                3
                            </div>
                            <div class="col-md-7" style="padding-top: 4px;">
                                Chance of illegal drug usage
                            </div>
                            <div class="col-md-3" style="padding-top: 4px;">
                                21.4%
                            </div>
                        </div>
                        <div class="row {{currentRiskScore===3?'highlightedRow':''}}">
                            <div class="col-md-2" style="padding-top: 4px;">
                                3
                            </div>
                            <div class="col-md-7" style="padding-top: 4px;">
                                Chance of IV drug usage
                            </div>
                            <div class="col-md-3" style="padding-top: 4px;">
                                2.3%
                            </div>
                        </div>
                        <div class="row {{currentRiskScore >= 4?'highlightedRow':''}}">
                            <div class="col-md-2" style="padding-top: 4px;">
                                >=4
                            </div>
                            <div class="col-md-7" style="padding-top: 4px;">
                                Chance of depression symptoms within the last year
                            </div>
                            <div class="col-md-3" style="padding-top: 4px;">
                                50.7%
                            </div>
                        </div>
                        <div class="row {{currentRiskScore >= 4?'highlightedRow':''}}">
                            <div class="col-md-2" style="padding-top: 4px;">
                                >=4
                            </div>
                            <div class="col-md-7" style="padding-top: 4px;">
                                Chance of poor health, self reported
                            </div>
                            <div class="col-md-3" style="padding-top: 4px;">
                                28.7%
                            </div>
                        </div>
                        <div class="row {{currentRiskScore >= 4?'highlightedRow':''}}">
                            <div class="col-md-2" style="padding-top: 4px;">
                                >=4
                            </div>
                            <div class="col-md-7" style="padding-top: 4px;">
                                Chance of illegal drug usage
                            </div>
                            <div class="col-md-3" style="padding-top: 4px;">
                                28.4%
                            </div>
                        </div>
                        <div class="row {{currentRiskScore >= 4?'highlightedRow':''}}">
                            <div class="col-md-2" style="padding-top: 4px;">
                                >=4
                            </div>
                            <div class="col-md-7" style="padding-top: 4px;">
                                Chance of IV drug usage
                            </div>
                            <div class="col-md-3" style="padding-top: 4px;">
                                3.4%
                            </div>
                        </div>

                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <br />
        <div class="row">
            <ng-container *ngFor="let scoreDetail of scoreDetails">
                <div class="col-md-4" style="margin-bottom: 20px">
                    <mat-card>
                        <mat-card-header>
                            <h4 class="header text-center">{{ scoreDetail.title }}</h4>
                        </mat-card-header>
                        <mat-card-content>
                            <div style="width: 100%">
                                <div class="chart-wrapper">
                                    <canvas baseChart [data]="scoreDetail" [options]="assessmentDetailChartOptions"
                                        [legend]="lineChartLegend" [type]="lineChartType"
                                        [plugins]="detailChartPlugins">
                                    </canvas>
                                </div>
                            </div>
                        </mat-card-content>
                        <small><b>Answer:</b> <i>{{ scoreDetail.answer }}</i></small>
                    </mat-card>
                </div>
            </ng-container>
        </div>
    </div>
</div>