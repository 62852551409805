import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import {
  Case,
  Countries,
  DataTablesModel,
  IGoal,
  Patient,
} from 'src/app/_models';
import { GoalHttpService, ToastMessageService } from 'src/app/_services';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { PermissionsSandbox } from 'src/app/shared/sandbox/permissions.sandbox';
import { AddGoalDialogComponent } from './dialogs/add/add.component';

@Component({
  selector: 'app-member-goals',
  styleUrls: ['goals.component.css'],
  templateUrl: 'goals.component.html',
})
export class MemberGoalsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() case: Case;
  @Input() patient: Patient;
  @Output() goals = new EventEmitter<any[]>();

  @Input() hasGoalsViewAccess: boolean;
  @Input() hasGoalsAddAccess: boolean;
  @Input() hasGoalsEditAccess: boolean;
  @Input() hasGoalsDeleteAccess: boolean;

  displayedColumns = [
    'description',
    'status',
    'priority',
    'rank',
    'category',
    'targetDate',
    'closedDate',
  ];
  data: DataTablesModel = {} as DataTablesModel;

  isLoadingResults = false;
  apiError = false;

  orgConfig: any;
  isIndianOrg: boolean = false;

  constructor(
    public dialog: MatDialog,
    public goalApiService: GoalHttpService,
    protected permissionsSandbox: PermissionsSandbox,
    private orgConfigSandbox: OrgConfigSandbox,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit() {
    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          this.orgConfig = orgConfig;
          if (this.orgConfig?.organizationCountry === Countries.India) {
            this.isIndianOrg = true;
            this.displayedColumns = [
              'description',
              'status',
              'priority',
              'rank',
              'category',
              'in-targetDate',
              'in-closedDate',
            ];
          }
        });
      }
    });

    if (this.hasGoalsEditAccess || this.hasGoalsDeleteAccess) {
      this.displayedColumns.push('actions');
    }

    this.data.per_page = 10;
    this.data.page = 0;
    this.loadGoals();
  }

  addGoal() {
    const dialogRef = this.dialog.open(AddGoalDialogComponent, {
      data: {
        action: 'ADD',
        goal: null,
        patientId: this.case.patientId,
        caseId: this.case.id,
      },
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadGoals();
      }
    });
  }

  public editGoal(goal: IGoal) {
    const dialogRef = this.dialog.open(AddGoalDialogComponent, {
      data: { action: 'EDIT', goal },
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadGoals();
      }
    });
  }

  deleteGoal(goal: IGoal) {
    const dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to delete this goal?',
      },
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.goalApiService.deleteGoal(this.case.id, goal).subscribe(() => {
          if (this.data.items.length == 1) {
            this.data.page = this.data.page - 1;
            if (this.data.page < 0) {
              this.data.page = 0;
            }
          }
          this.toastMessageService.displaySuccessMessage(
            'Goal ' + goal.description + ' has been deleted.'
          );
          this.loadGoals();
        });
      }
    });
  }

  public loadGoals() {
    this.isLoadingResults = true;
    this.apiError = false;

    this.goalApiService
      .getGoals(
        this.case.id,
        this.case.patientId,
        false,
        this.data.per_page,
        this.data.page
      )
      .subscribe(
        (response) => {
          this.isLoadingResults = false;
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
            this.goals.emit(this.data.items);
          }
          if (response == null) {
            this.data.items = [];
            this.data.total = 0;
            this.goals.emit([]);
          }
        },
        (error) => {
          this.apiError = true;
          this.isLoadingResults = false;
          this.goals.emit([]);
        }
      );
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadGoals();
  }
}
