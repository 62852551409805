import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataTablesModel, IDiagnosis } from 'src/app/_models';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class DiagnosisApiService {
  constructor(private httpClient: HttpClient) {}

  getDiagnosisList(
    patientId: string,
    status: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    if (status) params = params.append('status', status);
    params = params.append('patientId', patientId);
    params = params.append('pageSize', pageSize);
    params = params.append('pageIndex', pageIndex);

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/diagnosislist`,
      { params }
    );
  }

  addDiagnosis(thisDiagnosis: IDiagnosis): Observable<any> {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient
      .post<any>(`${environment.apiUrl}/diagnosis`, thisDiagnosis, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateDiagnosis(thisDiagnosis: IDiagnosis): Observable<IDiagnosis> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<IDiagnosis>(`${environment.apiUrl}/diagnosis`, thisDiagnosis, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  deleteDiagnosis(id: string, patientId: string): Observable<void> {
    let params = new HttpParams();

    params = params.append('id', id);
    params = params.append('patientId', patientId);

    return this.httpClient
      .delete<void>(`${environment.apiUrl}/diagnosis`, { params })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  markAsPrimary(diagnosis) {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient
      .put<any>(`${environment.apiUrl}/markasprimarydiagnosis`, diagnosis, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
