<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Archive Claim</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <form [formGroup]="archiveForm" novalidate style="margin-top: 20px">
    <div class="row">
      <div class="col-md-12" style="margin-bottom: 12px">
        <label class="labelBold">
          Are you sure you want to archive this claim? If you wish to archive,
          please provide the reason(s).
        </label>
      </div>
      <div class="col-md-12">
        <label class="labelBold">
          Reasons <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <textarea
            matInput
            placeholder=""
            formControlName="message"
            name="message"
            spellcheck="true"
            required
            rows="10"
          ></textarea>
          <mat-error *ngIf="archiveForm.controls['message'].invalid">
            Please provide the reason(s)
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="col-md-12"
        style="display: flex; justify-content: space-between; margin-top: 15px"
      >
        <button mat-raised-button type="button" mat-dialog-close>Cancel</button>

        <button
          mat-raised-button
          type="button"
          color="warn"
          [disabled]="archiveForm.invalid || processing"
          (click)="submitForm()"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
