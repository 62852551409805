<div class="container">
  <div class="dialogTitle" *ngIf="initialAnswer === ''">
    <p matDialogTitle *ngIf="addingCustomNote">Select Care log Type</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="initialForm">
    <div *ngIf="initialAnswer === ''">
      <!-- Generic Log -->
      <div class="row">
        <div class="col-md-12" style="cursor: pointer">
          <mat-card
            appearance="raised"
            (click)="selectInitialAnswer('Generic')"
            class="mat-elevation-z8 hoverCard"
            style="display: flex; justify-content: center; align-items: center"
          >
            <div class="gridContainer">
              <button mat-mini-fab color="primary" type="button">
                <mat-icon>note_alt</mat-icon>
              </button>

              <span class="headerText">Generic Log</span>
            </div>
          </mat-card>
        </div>
      </div>

      <br />
      <hr />
      <br />
      <!--     Different Types of Log -->
      <div class="row">
        <div class="col-md-3" style="cursor: pointer; margin-bottom: 15px">
          <mat-card
            appearance="raised"
            (click)="selectInitialAnswer('Bowel')"
            class="mat-elevation-z8 hoverCard"
            style="display: flex; justify-content: center"
          >
            <div class="gridContainer">
              <img src="/assets/icons/poopemoji.png" alt="Bowel Movement" />

              <span class="headerText">Bowel Movement</span>
            </div>
          </mat-card>
        </div>

        <div class="col-md-3" style="cursor: pointer; margin-bottom: 15px">
          <mat-card
            appearance="raised"
            (click)="selectInitialAnswer('Sleep')"
            class="mat-elevation-z8 hoverCard"
            style="display: flex; justify-content: center"
          >
            <div class="gridContainer">
              <button mat-mini-fab color="primary" type="button">
                <mat-icon>local_hotel</mat-icon>
              </button>

              <span class="headerText">Sleep Log</span>
            </div>
          </mat-card>
        </div>

        <div class="col-md-3" style="cursor: pointer; margin-bottom: 15px">
          <mat-card
            appearance="raised"
            (click)="selectInitialAnswer('Seizure')"
            class="mat-elevation-z8 hoverCard"
            style="display: flex; justify-content: center"
          >
            <div class="gridContainer">
              <img src="/assets/icons/seizure.png" alt="Seizure" />

              <span class="headerText">Seizure</span>
            </div>
          </mat-card>
        </div>

        <div class="col-md-3" style="cursor: pointer; margin-bottom: 15px">
          <mat-card
            appearance="raised"
            (click)="selectInitialAnswer('Vitals')"
            class="mat-elevation-z8 hoverCard"
            style="display: flex; justify-content: center"
          >
            <div class="gridContainer">
              <button mat-mini-fab color="primary" type="button">
                <mat-icon>monitor_heart</mat-icon>
              </button>

              <span class="headerText">Vitals</span>
            </div>
          </mat-card>
        </div>

        <div class="col-md-3" style="cursor: pointer; margin-bottom: 15px">
          <mat-card
            appearance="raised"
            (click)="selectInitialAnswer('Behavioral Incident')"
            class="mat-elevation-z8 hoverCard"
            style="display: flex; justify-content: center"
          >
            <div class="gridContainer">
              <img
                src="/assets/icons/behavioral.png"
                alt="Behavioral Incident"
              />

              <span class="headerText">Behavioral Incident</span>
            </div>
          </mat-card>
        </div>

        <div class="col-md-3" style="cursor: pointer; margin-bottom: 15px">
          <mat-card
            appearance="raised"
            (click)="selectInitialAnswer('Urine Drug Screen')"
            class="mat-elevation-z8 hoverCard"
            style="display: flex; justify-content: center"
          >
            <div class="gridContainer">
              <img src="/assets/icons/urinetest.png" alt="Urine Drug Screen" />
              <span class="headerText">Urine Drug Screen</span>
            </div>
          </mat-card>
        </div>
      </div>
    </div>

    <app-carelog-add
      [displayTitle]="true"
      [patientId]="patientId"
      [sessionId]="sessionId"
      [action]="action"
      (taskCompleted)="taskCompleted($event)"
      [case]="case"
      [logDate]="logDate"
      [shift]="shift"
      [carelog]="carelog"
      [goals]="goals"
      [goalDrivenCare]="goalDrivenCare"
      *ngIf="initialAnswer === 'Generic'"
    ></app-carelog-add>
    <app-carelog-bowel-movement
      [displayTitle]="true"
      [patientId]="patientId"
      [sessionId]="sessionId"
      [action]="action"
      (taskCompleted)="taskCompleted($event)"
      [case]="case"
      [logDate]="logDate"
      [shift]="shift"
      [carelog]="carelog"
      [goals]="goals"
      [goalDrivenCare]="goalDrivenCare"
      *ngIf="initialAnswer === 'Bowel'"
    ></app-carelog-bowel-movement>
    <app-carelog-seizure-log
      [displayTitle]="true"
      [patientId]="patientId"
      [sessionId]="sessionId"
      [action]="action"
      (taskCompleted)="taskCompleted($event)"
      [case]="case"
      [logDate]="logDate"
      [shift]="shift"
      [carelog]="carelog"
      [goals]="goals"
      [goalDrivenCare]="goalDrivenCare"
      *ngIf="initialAnswer === 'Seizure'"
    ></app-carelog-seizure-log>
    <app-carelog-sleep-record
      [displayTitle]="true"
      [patientId]="patientId"
      [sessionId]="sessionId"
      [action]="action"
      (taskCompleted)="taskCompleted($event)"
      [case]="case"
      [logDate]="logDate"
      [shift]="shift"
      [carelog]="carelog"
      [goals]="goals"
      [goalDrivenCare]="goalDrivenCare"
      *ngIf="initialAnswer === 'Sleep'"
    ></app-carelog-sleep-record>
    <app-carelog-vitals
      [displayTitle]="true"
      [patientId]="patientId"
      [sessionId]="sessionId"
      [action]="action"
      (taskCompleted)="taskCompleted($event)"
      [case]="case"
      [logDate]="logDate"
      [shift]="shift"
      [carelog]="carelog"
      [goals]="goals"
      [goalDrivenCare]="goalDrivenCare"
      *ngIf="initialAnswer === 'Vitals'"
    ></app-carelog-vitals>
    <app-carelog-behavioral-incident
      [displayTitle]="true"
      [patientId]="patientId"
      [sessionId]="sessionId"
      [action]="action"
      (taskCompleted)="taskCompleted($event)"
      [case]="case"
      [logDate]="logDate"
      [shift]="shift"
      [carelog]="carelog"
      [goals]="goals"
      [goalDrivenCare]="goalDrivenCare"
      *ngIf="initialAnswer === 'Behavioral Incident'"
    ></app-carelog-behavioral-incident>
    <app-carelog-urinal-drug-analysis
      [displayTitle]="true"
      [patientId]="patientId"
      [sessionId]="sessionId"
      [action]="action"
      (taskCompleted)="taskCompleted($event)"
      [case]="case"
      [logDate]="logDate"
      [shift]="shift"
      [carelog]="carelog"
      [goals]="goals"
      [goalDrivenCare]="goalDrivenCare"
      *ngIf="initialAnswer === 'Urine Drug Screen'"
    ></app-carelog-urinal-drug-analysis>
  </form>
</div>
