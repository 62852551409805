import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  MbscCalendarEvent,
  MbscEventcalendarOptions,
  MbscPopup,
  MbscPopupOptions,
  momentTimezone,
  setOptions,
} from '@mobiscroll/angular';
import { addDays, addMinutes } from 'date-fns';
import { KeycloakService } from 'keycloak-angular';
import moment from 'moment-timezone';
import {
  CoreService,
  DashboardsService,
  DateTimeZoneService,
  TherapySessionService,
  TimeOffAPIService,
  ToastMessageService,
} from 'src/app/_services';
import { FacilitiesSandbox } from 'src/app/shared/sandbox/facilities.sandbox';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { PermissionsSandbox } from 'src/app/shared/sandbox/permissions.sandbox';
import { VisitReasonsSandbox } from 'src/app/shared/sandbox/visit-reasons.sandbox';
import { TimeArrayMap } from 'src/app/shared/utilities/calendar/timeArrays';
import { TimeZonesCalendar } from 'src/app/shared/utilities/calendar/timeZonesCalendar';
import { DaysNumberMap } from 'src/app/shared/utilities/calendar/weekDays';
import { Colors } from 'src/app/shared/utilities/colors';
import {
  SessionQueues,
  SessionQueuesColorMap,
} from 'src/app/shared/utilities/session/sessionQueues';
import { hasAccess } from 'src/app/shared/utilities/utilities';
import { SessionGeneralNotesComponent } from '../../patient-details/patient-components/sessions/session-general-notes/session-general-notes.component';

momentTimezone.moment = moment;
setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

@Component({
  selector: 'app-non-provider-dashboard',
  templateUrl: './non-provider-dashboard.component.html',
  styleUrl: './non-provider-dashboard.component.css',
})
export class NonProviderDashboardComponent implements OnInit {
  // Popup Elements
  @ViewChild('popup', { static: false })
  tooltip!: MbscPopup;
  anchor?: HTMLElement;
  timer: any;

  selectedEvent: any;
  isQueueSelectBoxOpen: boolean = false;

  loggedInUserId: String;
  loggedInUserName: String;

  hasTelehealthFeature: boolean = false;
  hasCaseManagementFeature: boolean = false;
  hasCaseViewAccess = false;

  // Importing Constants from the Shared Library
  colorsList = Colors;
  timeArrayMap = TimeArrayMap;
  daysNumberMap = DaysNumberMap;
  sessionQueues = SessionQueues;
  sessionQueuesColorMap = SessionQueuesColorMap;
  calendarTimeZones = TimeZonesCalendar;

  selectedCalendarTimeZone = '';
  selectedCaldendarTimeZoneDateFormat = '';

  // Visit Reasons
  visitReasons: any = [];
  visitReasonsListMap: Map<string, any> = new Map<string, any>();

  organizationalTimeOffs: any = [];
  organizationalTimeOffLoading: boolean = true;
  // Colors Map For Visit Reasons
  visitReasonsColorMap: Map<string, string> = new Map();

  // General Properties
  orgConfig: any;
  myFacilities: any = [];
  facilitiesSelectionList: any = [];
  filteredFacilities: any = [];
  searchFacilityName: string = '';

  allProviders = [];
  providersSelectionList = [];
  filteredProviders = [];
  searchProviderName: string = '';

  sessionStatus: string = 'All';
  existingSession: any = [];

  // Determining Calendar Start Time and End Time
  startTimeArray: number[] = [];
  endTimeArray: number[] = [];

  // Permissions
  hasMemberPaymentsAddAccess = false;
  hasSessionsViewAccess: boolean = false;
  hasSessionEditAccess: boolean = false;
  hasMemberViewAccess: boolean = false;
  hasTherapySessionEditAccess: boolean = false;

  // MobiScroll Calendar Options
  myEvents: MbscCalendarEvent[] = [];
  mySessionStatusEvents: MbscCalendarEvent[] = [];
  filteredEvents: MbscCalendarEvent[] = [];

  timeCellStep = 30; // 1, 5, 10, 15, 20, 30, 60, 120, 180, 240, 360,
  timeLabelStep = 30; // 1, 5, 10, 15, 20, 30, 60, 120, 180, 240, 360,
  calendarViewType: string = 'day';
  calendarStartDay = 0; // Sunday is 0, Monday is 1, ...
  calendarEndDay = 6;
  invalids = [];
  invalidColors = [];
  allWorkingHoursArray = [];
  allDaysArray = [];
  calendarStartHour = '06:00';
  calendarEndHour = '20:00';
  currentTimezone: string;

  initialResponsiveView = {
    allDay: false,
    currentTimeIndicator: false,
    startTime: this.calendarStartHour,
    endTime: this.calendarEndHour,
    startDay: this.calendarStartDay,
    endDay: this.calendarEndDay,
    timeCellStep: this.timeCellStep,
    timeLabelStep: this.timeLabelStep,
  };

  // Current Date & time
  currentTime = new Date();
  yesterday = addDays(new Date(), -1);
  minimumCalendarDate = addDays(new Date(), -4);
  maximumCalendarDate = addDays(new Date(), 120);

  // Setting Up Initial MobiScroll Calendar View
  responsiveMobiScrollCalendarView = {
    // Min-width:0px
    xsmall: {
      view: {
        schedule: {
          type: this.calendarViewType,
          ...this.initialResponsiveView,
        },
      },
    },
    // min-width: 768px
    medium: {
      view: {
        schedule: {
          type: this.calendarViewType,
          ...this.initialResponsiveView,
        },
      },
    },
  };

  // Setting up initial MobiScroll Calendar options
  mobiscrollCalendarOptions: MbscEventcalendarOptions = {
    clickToCreate: false,
    dragToCreate: false,
    dragToMove: false,
    dragInTime: false,
    eventOverlap: true,
    timezonePlugin: momentTimezone,

    // On hover in display the event
    onEventHoverIn: (args) => {
      const event = args.event;
      this.selectedEvent = event;

      // Clear the current timer
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = null;

      this.anchor = args.domEvent.target;
      this.tooltip.open();
    },

    // Hide event tool tip on hover out
    onEventHoverOut: () => {
      // Now Set new timer
      this.timer = setTimeout(() => {
        this.selectedEvent = null;

        this.tooltip.close();
      }, 500);
    },

    // Open Tool tip on event Click
    onEventClick: (args) => {
      const event = args.event;
      this.selectedEvent = event;

      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = null;

      this.anchor = args.domEvent.target;
      this.tooltip.open();
    },
  };

  popupOptions: MbscPopupOptions = {
    display: 'anchored',
    touchUi: false,
    showOverlay: false,
    contentPadding: false,
    closeOnOverlayClick: false,
    focusOnClose: false,
    width: 350,
  };

  mouseEnter(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = null;
  }

  mouseLeave(): void {
    // Only if the Queue Selection Box is not open, then restore popup
    if (!this.isQueueSelectBoxOpen) {
      // If there was an old timer clear timeout
      if (this.timer) {
        clearTimeout(this.timer);
      }
      // Setup New Timer
      this.timer = setTimeout(() => {
        this.selectedEvent = null;

        this.tooltip.close();
      }, 200);
    }
  }

  constructor(
    private dialog: MatDialog,
    private coreService: CoreService,
    private router: Router,
    private dashboardsService: DashboardsService,
    private therapySessionService: TherapySessionService,
    private visitReasonsSandBox: VisitReasonsSandbox,
    private timeOffAPIService: TimeOffAPIService,
    private orgConfigSandbox: OrgConfigSandbox,
    private facilitiesSandbox: FacilitiesSandbox,
    protected permissionsSandbox: PermissionsSandbox,
    private toastMessageService: ToastMessageService,
    private keycloakService: KeycloakService,
    private dateTimeZoneService: DateTimeZoneService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
    this.loggedInUserId = this.coreService.getLoggedInCareProviderId();
    this.loggedInUserName = this.coreService.getUserDetails().name;
  }

  ngOnInit(): void {
    // Default time zone based on user login
    this.selectedCalendarTimeZone = moment.tz.guess();
    this.selectedCaldendarTimeZoneDateFormat = moment
      .tz(this.selectedCalendarTimeZone)
      .format('z');

    // Check if this timezone exists or not
    this.checkTimeZoneExists();

    // load the visit reasons
    this.loadVisitReasons();

    // Handle Org Config to get features
    this.initializeOrgConfig();

    // First get all the facilities from sandbox
    this.loadMyFacilities();

    // Load all active physicians
    this.loadActiveProviders();

    // Load My Permissions();
    this.loadMyPermissions();

    // Load Organizational Holidays If Any
    this.loadOrganizationalHolidays();
  }

  // Handle time zone change to display date
  timeZoneChanged() {
    this.selectedCaldendarTimeZoneDateFormat = moment
      .tz(this.selectedCalendarTimeZone)
      .format('z');
  }

  // Check if the time zone exists and if not use the CT
  checkTimeZoneExists() {
    let timeZoneExists = false;
    this.calendarTimeZones.map((zone) => {
      if (zone.id === this.selectedCalendarTimeZone) {
        timeZoneExists = true;
      }
    });
    if (!timeZoneExists) {
      this.selectedCalendarTimeZone = 'America/Chicago';
      this.timeZoneChanged();
    }
  }

  // Get Visit Reasons From the SandBox
  loadVisitReasons() {
    this.visitReasonsSandBox.visitReasonsLoading$.subscribe((response) => {
      // If the visit Reasons Have Loaded Successfully
      if (!response) {
        // Now the load has been complete, get the visit reasons
        this.visitReasonsSandBox.visitReasons$.subscribe((visitReasons) => {
          this.visitReasons = visitReasons;
          this.visitReasonsColorMap = new Map<string, string>();

          // Set these visit reasons in color map
          visitReasons.forEach((visitReason) => {
            this.visitReasonsColorMap.set(visitReason.id, visitReason.color);
            this.visitReasonsListMap.set(visitReason.id, visitReason);
          });
        });

        // Now Load All Sessions
        this.getAllSession();
      }
    });
  }

  // Get all sessions
  getAllSession() {
    this.dashboardsService.getSessionsForNonProvider().subscribe({
      next: (response) => {
        if (response && response?.items) {
          this.existingSession = response.items;
          this.loadExistingEvents();
        }
      },
      error: (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Unable to retrieve existing sessions'
        );
      },
    });
  }

  // Loading Mobiscroll Events
  loadExistingEvents() {
    if (this.existingSession) {
      let allMobiScrollEvents = [];

      this.existingSession.forEach((session) => {
        let startTimeHour = new Date(session.start).getHours();
        let endTimeHour = new Date(session.end).getHours();
        this.startTimeArray.push(startTimeHour);
        this.endTimeArray.push(endTimeHour);

        let newEvent = <MbscCalendarEvent>{
          id: session.id,
          start: new Date(session.start),
          end: new Date(session.end),
          title: session.title,

          color:
            session.status === 'Canceled'
              ? 'darkgray'
              : this.visitReasonsColorMap.get(session.visitReasonId),
          editable: false,

          visitReasonId: session.visitReasonId,
          queue: session.queue,
          queueColor: this.sessionQueuesColorMap.get(session.queue),
          status: session.status,

          patientId: session.patientId,
          organizationId: session.organizationId,
          physicianId: session.physicianId,
          tooltip:
            session.physicianLastName +
            ', ' +
            session.physicianFirstName +
            '  |  ' +
            session.billingProviderName,
          physicianName:
            session.physicianLastName + ', ' + session.physicianFirstName,

          facilityId: session.billingProviderRefId,
          facilityName: session.billingProviderName,

          clinicalNoteStatus: session.clinicalNoteStatus,
          chargeStatus: session.chargeStatus,

          startTime: session.sessionStartTime,
          duration: session.sessionDuration,
          sessionCode: session.sessionCode,
          sessionType: session.sessionType,
          sessionFor: session.sessionFor,

          // Details for group appointments
          groupAppointment: session?.groupAppointment,
          groupMembers: session?.groupMembers,
          recurringAppointment: session?.recurringAppointment,
          recurringStart: session?.recurringStart,
          recurringEnd: session?.recurringEnd,
          recurringFrequency: session?.recurringFrequency,
          recurringDays: session?.recurringDays,
          sessionLevelNote: session?.sessionLevelNote,
        };

        allMobiScrollEvents.push(newEvent);
      });

      this.myEvents = [...allMobiScrollEvents];
      this.mySessionStatusEvents = [...allMobiScrollEvents];
      this.filteredEvents = [...allMobiScrollEvents];

      this.handleCalendarStartEndTime();
    }
  }

  // Handle Calendar Start End Time
  handleCalendarStartEndTime() {
    let minimum = Math.min(...this.startTimeArray);
    let maximum = Math.max(...this.endTimeArray);

    // Convert to appropriate format
    maximum = maximum + 1;
    if (maximum > 24) {
      maximum = 24;
    }

    let minimumString = minimum.toString();
    let maximumString = maximum.toString();

    if (minimumString.length === 1) {
      minimumString = '0' + minimumString;
    }
    if (maximumString.length === 1) {
      maximumString = '0' + maximumString;
    }

    this.calendarStartHour = minimumString + ':00';
    this.calendarEndHour = maximumString + ':00';

    // Once the star and end Time hour has been determined
    this.calendarTypeChanged({ value: this.calendarViewType });
  }

  // Get the required orgconfig Information
  initializeOrgConfig() {
    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        // When load is complete, get the org config from app state
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          // Saving the org config
          this.orgConfig = orgConfig;

          // Check for organization features
          if (this.orgConfig && this.orgConfig?.features) {
            this.hasCaseManagementFeature =
              this.orgConfig.features.includes('CASE_MANAGEMENT');

            this.hasTelehealthFeature =
              this.orgConfig.features.includes('TELEHEALTH_ENABLED');
          }
        });
      }
    });
  }

  // Loading my permissions
  loadMyPermissions() {
    this.permissionsSandbox.permissions$.subscribe((response) => {
      this.hasCaseViewAccess = hasAccess(
        this.keycloakService,
        'CASES_VIEW',
        response,
        null
      );

      this.hasMemberPaymentsAddAccess = hasAccess(
        this.keycloakService,
        'MEMBER_PAYMENTS_ADD',
        response,
        null
      );
      this.hasSessionsViewAccess = hasAccess(
        this.keycloakService,
        'THERAPY_SESSIONS_VIEW',
        response,
        null
      );

      this.hasSessionEditAccess = hasAccess(
        this.keycloakService,
        'THERAPY_SESSIONS_EDIT',
        response,
        null
      );

      this.hasMemberViewAccess = hasAccess(
        this.keycloakService,
        'MEMBERS_VIEW',
        response,
        null
      );

      this.hasTherapySessionEditAccess = hasAccess(
        this.keycloakService,
        'THERAPY_SESSIONS_EDIT',
        response,
        null
      );
    });
  }

  // Get All Organizational Holidays
  loadOrganizationalHolidays() {
    this.organizationalTimeOffLoading = true;
    this.timeOffAPIService.getOrganizationTimeOff(200, 0).subscribe({
      next: (response) => {
        if (response && response.items) {
          this.organizationalTimeOffs = response.items;

          // Now add these dates to invalids
          this.loadOrganizationalHolidaysOnMBSC();
        }
        this.organizationalTimeOffLoading = false;
      },
      error: (error) => {
        this.organizationalTimeOffLoading = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to retreive organizational holidays.'
        );
      },
    });
  }

  // Load Organizational Holidays as invalid in Mobiscroll
  loadOrganizationalHolidaysOnMBSC() {
    // Loop through each holiday for organization and mark them as invalid dates in calendar
    this.organizationalTimeOffs.forEach((holiday) => {
      // Start Date For Invalid in Mobi Scroll
      let startDate = new Date(holiday.startDay);
      // End Date For Invalid in Mobi Scroll
      let endDate = new Date(holiday.endDay);
      // Set End Date to be the start of the next day of end date
      //  endDate.setDate(endDate.getDate() + 1);

      // Invalid Date Structure as per Mobi Scroll
      let invalidDateInCalendar = {
        start: addMinutes(startDate, 2),
        end: endDate,
        title: holiday?.reason,
        cssClass: 'md-stripes-gray-bg',
      };
      // Now update existing invalid and add this invalid date aswell
      this.invalids = [...this.invalids, invalidDateInCalendar];
      this.invalidColors = [...this.invalidColors, invalidDateInCalendar];
    });
  }

  // Load All my facilities
  loadMyFacilities() {
    this.facilitiesSandbox.facilities$.subscribe((response) => {
      if (response) {
        this.myFacilities = response;
        this.filteredFacilities = response;
        this.facilitiesSelectionList = response;
      }
    });
  }

  // Load All The active providers
  loadActiveProviders() {
    // Loading active providers
    this.dashboardsService.getAllActiveProviders().subscribe({
      next: (response) => {
        if (response && response?.items) {
          this.allProviders = response.items;
          this.providersSelectionList = response.items;
          this.filteredProviders = response.items;
        }
      },
      error: (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to get active providers'
        );
      },
    });
  }

  // Handle Calendar Type Change
  calendarTypeChanged(event) {
    this.calendarViewType = event.value;
    this.responsiveMobiScrollCalendarView = {
      // Min-width:0px
      xsmall: {
        view: {
          schedule: {
            type: this.calendarViewType,
            ...this.initialResponsiveView,
            startTime: this.calendarStartHour,
            endTime: this.calendarEndHour,
            startDay: this.calendarStartDay,
            endDay: this.calendarEndDay,
          },
        },
      },
      // min-width: 768px
      medium: {
        view: {
          schedule: {
            type: event.value,
            ...this.initialResponsiveView,
            startTime: this.calendarStartHour,
            endTime: this.calendarEndHour,
            startDay: this.calendarStartDay,
            endDay: this.calendarEndDay,
          },
        },
      },
    };
  }

  // Handle Session Status Change
  sessionStatusChanged() {
    if (this.sessionStatus === 'All') {
      this.mySessionStatusEvents = [...this.myEvents];
      this.filteredEvents = [...this.myEvents];

      // Restore facility and provider selections
      this.handleCheckBoxChangeEvents();
    } else if (this.sessionStatus === 'Scheduled') {
      // For Scheduled ->Scheduled,Confirmed
      let scheduledEvents = this.myEvents.filter(
        (event) => event.queue === 'Scheduled' || event.queue === 'Confirmed'
      );
      this.mySessionStatusEvents = [...scheduledEvents];
      this.filteredEvents = [...scheduledEvents];

      // Restore facility and provider selections
      this.handleCheckBoxChangeEvents();
    } else if (this.sessionStatus === 'In Office') {
      // For Scheduled ->Scheduled,Confirmed
      let inOfficeEvents = this.myEvents.filter(
        (event) => event.queue === 'Arrived' || event.queue === 'Checked In'
      );
      this.mySessionStatusEvents = [...inOfficeEvents];
      this.filteredEvents = [...inOfficeEvents];

      // Restore facility and provider selections
      this.handleCheckBoxChangeEvents();
    } else if (this.sessionStatus === 'Closed') {
      let closedEvents = this.myEvents.filter(
        (event) =>
          event.status === 'Closed' ||
          event.status === 'Canceled' ||
          event.queue === 'Checked Out'
      );
      this.mySessionStatusEvents = [...closedEvents];
      this.filteredEvents = [...closedEvents];

      // Restore facility and provider selections
      this.handleCheckBoxChangeEvents();
    }
  }

  // For Facilities Options
  isFacilityVisible(facility) {
    return this.filteredFacilities.includes(facility);
  }

  selectDeselctFacility(event, facility) {
    if (event.checked) {
      // Add the facility to the list
      if (!this.filteredFacilities.includes(facility)) {
        this.filteredFacilities = [...this.filteredFacilities, facility];
        // Also add the events back to the list
        // Handle Events filter
        this.handleCheckBoxChangeEvents();
      }
    } else {
      // Remove the facility to the list
      if (this.filteredFacilities.includes(facility)) {
        this.filteredFacilities = this.filteredFacilities.filter(
          (resource) => resource != facility
        );
        // Filter Events to remove this facility
        // Handle Events filter
        this.handleCheckBoxChangeEvents();
      }
    }
  }

  // Facility search box
  searchByFacilityNameChanged() {
    if (this.searchFacilityName) {
      this.facilitiesSelectionList = this.myFacilities.filter((facility) => {
        if (
          facility.facilityName
            .toLowerCase()
            .includes(this.searchFacilityName.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.facilitiesSelectionList = [...this.myFacilities];
    }
  }

  selectAllFacilities() {
    this.filteredFacilities = [...this.myFacilities];
    // Handle Events filter
    this.handleCheckBoxChangeEvents();
  }

  deselectAllFacilities() {
    this.filteredFacilities = [];
    this.filteredEvents = [];
  }

  // Search By provider Name Changed
  searchByProviderNameChanged() {
    if (this.searchProviderName) {
      this.providersSelectionList = this.allProviders.filter((provider) => {
        const lastName = provider.lastName.toLowerCase();
        const firstName = provider.firstName.toLowerCase();

        const concatFirstLast = firstName + ' ' + lastName;
        const concatLastFirst = lastName + ' ' + firstName;

        if (
          concatFirstLast.includes(this.searchProviderName.toLowerCase()) ||
          concatLastFirst.includes(this.searchProviderName.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.providersSelectionList = [...this.allProviders];
    }
  }

  // This is for provider checkbox
  isProviderVisible(provider) {
    return this.filteredProviders.includes(provider);
  }

  // Handle the add/remove of provider
  selectDeselctProvider(event, provider) {
    if (event.checked) {
      // Add the provider to the list
      if (!this.filteredProviders.includes(provider)) {
        this.filteredProviders = [...this.filteredProviders, provider];
        // Also add the event back
        // Handle Events filter
        this.handleCheckBoxChangeEvents();
      }
    } else {
      // Remove the facility to the list
      if (this.filteredProviders.includes(provider)) {
        this.filteredProviders = this.filteredProviders.filter(
          (resource) => resource != provider
        );
        // Filter Events to remove this provider
        // Handle Events filter
        this.handleCheckBoxChangeEvents();
      }
    }
  }

  selectAllProviders() {
    this.filteredProviders = [...this.allProviders];
    // Handle Events filter
    this.handleCheckBoxChangeEvents();
  }

  // Figure out which events to display based on the selected facility and providers
  handleCheckBoxChangeEvents() {
    let filteredEventsList: MbscCalendarEvent[] = [];

    this.mySessionStatusEvents.forEach((event) => {
      let isFacilityVisible = this.filteredFacilities.findIndex(
        (existingFacility) => existingFacility.id == event.facilityId
      );
      let isProviderVisible = this.filteredProviders.findIndex(
        (provider) => provider.id == event.physicianId
      );
      if (isFacilityVisible >= 0 && isProviderVisible >= 0) {
        filteredEventsList.push(event);
      }
    });

    this.filteredEvents = [...filteredEventsList];
  }

  // De-select all providers
  deselectAllProviders() {
    this.filteredProviders = [];
    this.filteredEvents = [];
  }

  // Navigate to Member's page
  navigateToMemberPage(row) {
    // If only the user has access to view members
    if (this.hasMemberViewAccess) {
      if (row?.groupAppointment) {
        // For Normal navigate to session edit
        this.router.navigate([`/group-sessions/${row.id}`], {
          queryParams: {
            parent: 'Home',
          },
        });
      } else {
        // For Normal navigate to session edit
        // Check if this is a group or regular session
        this.router.navigate(['/main/member/' + row.patientId + '/NOTES'], {
          queryParams: {
            secondaryId: row.id,
          },
        });
      }
    }
  }

  // Navigate to sessions page
  navigateToSessionPage(row) {
    // If only the user has access to view member sessions
    if (this.hasSessionsViewAccess) {
      if (row?.groupAppointment) {
        // For Normal navigate to session edit
        this.router.navigate([`/group-sessions/${row.id}`], {
          queryParams: {
            parent: 'Home',
          },
        });
      } else {
        // For Normal navigate to session edit
        // Check if this is a group or regular session
        this.router.navigate(['/main/member/' + row.patientId + '/NOTES'], {
          queryParams: {
            secondaryId: row.id,
          },
        });
      }
    }
  }

  // Handling Mobiscroll Queue Boxes Change
  onQueueSelectionBoxOpen(event) {
    // Prevent the tool tip box from collapsing
    this.isQueueSelectBoxOpen = true;
  }

  // Handling Mobiscroll Queue Boxes Change
  onQueueSelectionBoxClose(event) {
    // Tool tip can now close
    this.isQueueSelectBoxOpen = false;
  }

  // Handle The Queue Status Change
  onQueueSelectionBoxChange(event) {
    if (event?.value) {
      let newQueueStatus = event.value;
      let selectedSessionId = this.selectedEvent.id;

      // Call in the endpoint to update the session Queue Status
      this.therapySessionService
        .updateQueueStatus(selectedSessionId, newQueueStatus)
        .subscribe({
          next: (response) => {
            if (response?.result) {
              // Now update the event queue status and color
              this.myEvents = this.myEvents.map((event) => {
                if (event.id === selectedSessionId) {
                  event.queue = newQueueStatus;
                  event.queueColor =
                    this.sessionQueuesColorMap.get(newQueueStatus);
                  event.status =
                    newQueueStatus === 'No Show' ||
                    newQueueStatus === 'Canceled'
                      ? 'Canceled'
                      : 'Active';

                  event.color =
                    event.status === 'Canceled'
                      ? 'darkgray'
                      : this.visitReasonsColorMap.get(event.visitReasonId);
                }
                return event;
              });

              // Now handle the filtering of events
              this.sessionStatusChanged();

              this.toastMessageService.displaySuccessMessage(
                'Queue for session ' +
                  this.selectedEvent.title +
                  '  has been updated'
              );

              // Clear timer and reset popup
              if (this.timer) {
                clearTimeout(this.timer);
              }

              setTimeout(() => {
                this.timer = null;
                this.selectedEvent = null;
                this.tooltip.close();
              }, 200);
            }
          },
          error: (error) => {
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to update the queue status for ' +
                this.selectedEvent.title
            );
          },
        });
    }
  }

  collectPayment(row) {
    this.router.navigate(['main/collectmemberpayment', row.patientId], {
      queryParams: { sessionId: row.id },
    });
  }

  rescheduleSession(row) {
    // For Group appointment navigate to group appointment scheduler
    if (row?.groupAppointment) {
      // For Normal navigate to session edit
      this.router.navigate([`/schedules/groupsessionscheduler/${row.id}`], {
        queryParams: {
          parent: 'Home',
        },
      });
    } else {
      // For Normal navigate to session edit
      this.router.navigate([`/schedules/reschedule/${row.id}`], {
        queryParams: {
          parent: 'Home',
        },
      });
    }
  }

  // Add Remove attendance
  takeAttendance(event: any, member: any, attendace: boolean) {
    this.therapySessionService
      .handleGroupSessionMemberAttendance(event.id, member.member.id, attendace)
      .subscribe({
        next: (response) => {
          // Now we first need to update the events properties
          this.myEvents = this.myEvents.map((filterEvent) => {
            if (filterEvent.id === event.id) {
              event.groupMembers = response.data.groupMembers;
            }
            return event;
          });
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to change the attendance status'
          );
        },
      });
  }

  viewSessionLevelNote(selectedEvent) {
    let sessionDetails = this.existingSession
      .filter((session) => session.id === selectedEvent.id)
      .at(0);

    if (sessionDetails) {
      this.dialog.open(SessionGeneralNotesComponent, {
        data: {
          therapySession: sessionDetails,
          hasEditAccess: this.hasTherapySessionEditAccess,
        },
        disableClose: true,
        autoFocus: false,
        minWidth: '35vw',
      });
    }
  }
}
