import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { IMemberInsurance, ITherapySession, Patient } from 'src/app/_models';
import {
  PatientInsuranceApiService,
  ToastMessageService,
} from 'src/app/_services';
import { AddEditMemberInsuranceComponent } from '../../../member-tab/member-tab-sections/member-insurance/add-edit-member-insurance/add-edit-member-insurance.component';

@Component({
  selector: 'app-edit-prior-auth',
  templateUrl: './edit-prior-auth.component.html',
  styleUrls: ['./edit-prior-auth.component.css'],
})
export class EditPriorAuthComponent implements OnInit {
  primaryInsuranceDetails: any;
  therapySession: ITherapySession;
  patient: Patient;
  processing: boolean = false;

  retrievedPrimaryInsuranceDetails: IMemberInsurance;
  priorAuths: any = [];

  displayedColumns = [
    'authNum',
    'start',
    'end',
    'remaining',
    'validity',
    'action',
  ];

  constructor(
    public dialogRef: MatDialogRef<EditPriorAuthComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private patientInsuranceApiService: PatientInsuranceApiService,
    private toastMessageService: ToastMessageService
  ) {
    this.primaryInsuranceDetails = data.primaryInsuranceDetails;
    this.patient = data.patient;
    this.therapySession = data.therapySession;
  }

  ngOnInit(): void {
    this.getInsuraneDetails();
  }

  getInsuraneDetails() {
    this.processing = true;
    this.priorAuths = [];

    this.patientInsuranceApiService
      .getInsuraneDetails(this.primaryInsuranceDetails.id)
      .subscribe(
        (response) => {
          this.processing = false;
          if (response && response.data) {
            this.retrievedPrimaryInsuranceDetails = response.data;
            this.priorAuths = response.data.priorAuths;
          }
        },
        (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get primary insurance details'
          );
          this.processing = false;
        }
      );
  }

  editInsurance() {
    const dialogRef = this.dialog.open(AddEditMemberInsuranceComponent, {
      data: {
        action: 'EDIT',
        patient: this.patient,
        memberInsurance: this.retrievedPrimaryInsuranceDetails,
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '40vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response && response === 'success') {
        this.getInsuraneDetails();
      }
    });
  }

  getValidity(auth) {
    let start = new Date(this.therapySession.start);
    let end = new Date(this.therapySession.end);

    let authStart = new Date(auth.effectiveStartDate);
    let authEnd = new Date(auth.effectiveEndDate);

    let visitsRemaining = auth.totalVisitNumber - auth.usedVisits;
    let visitRemainingValid = true;

    if (auth.visitType === 'Limited') {
      if (visitsRemaining <= 0) {
        visitRemainingValid = false;
      }
    }

    return (
      start >= authStart &&
      start <= authEnd &&
      end >= authStart &&
      end <= authEnd &&
      visitRemainingValid
    );
  }

  selectAuth(row) {
    this.dialogRef.close({ type: 'success', details: row });
  }
}
