<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Adult Attachment Scale (AAS)</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-12 text-center">
      <button class="btn btn-primary" mat-raised-button>
        Close: {{ close }}/5
      </button>
      <button class="btn btn-primary" mat-raised-button>
        Depend: {{ depend }}/5
      </button>
      <button class="btn btn-primary" mat-raised-button>
        Anxiety: {{ anxiety }}/5
      </button>
    </div>
  </div>
  <form
    [formGroup]="aasAssessmentForm"
    novalidate
    (ngSubmit)="onAasFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-12"
            >1. I find it relatively easy to get close to others.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="easyToGetClose">
              <mat-button-toggle value="Not at all characteristic of me"
                >Not at all characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly characteristic of me"
                >Slightly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately characteristic of me"
                >Moderately characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Fairly characteristic of me"
                >Fairly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Very characteristic of me"
                >Very characteristic of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >2. I find it difficult to allow myself to depend on others.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              formControlName="difficultToDependOnOthers"
            >
              <mat-button-toggle value="Not at all characteristic of me"
                >Not at all characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly characteristic of me"
                >Slightly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately characteristic of me"
                >Moderately characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Fairly characteristic of me"
                >Fairly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Very characteristic of me"
                >Very characteristic of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >3. I often worry that romantic partners don't really love
            me.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="worryPartnersDontLove">
              <mat-button-toggle value="Not at all characteristic of me"
                >Not at all characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly characteristic of me"
                >Slightly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately characteristic of me"
                >Moderately characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Fairly characteristic of me"
                >Fairly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Very characteristic of me"
                >Very characteristic of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >4. I find that others are reluctant to get as close as I would
            like.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              formControlName="othersReluctantToGetClose"
            >
              <mat-button-toggle value="Not at all characteristic of me"
                >Not at all characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly characteristic of me"
                >Slightly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately characteristic of me"
                >Moderately characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Fairly characteristic of me"
                >Fairly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Very characteristic of me"
                >Very characteristic of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >5. I am comfortable depending on others.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="comfortableDepending">
              <mat-button-toggle value="Not at all characteristic of me"
                >Not at all characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly characteristic of me"
                >Slightly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately characteristic of me"
                >Moderately characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Fairly characteristic of me"
                >Fairly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Very characteristic of me"
                >Very characteristic of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >6. I don't worry about people getting too close to me.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="notWorriedAboutCloseness">
              <mat-button-toggle value="Not at all characteristic of me"
                >Not at all characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly characteristic of me"
                >Slightly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately characteristic of me"
                >Moderately characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Fairly characteristic of me"
                >Fairly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Very characteristic of me"
                >Very characteristic of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >7. I find that people are never there when you need them.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="peopleNotThereWhenNeeded">
              <mat-button-toggle value="Not at all characteristic of me"
                >Not at all characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly characteristic of me"
                >Slightly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately characteristic of me"
                >Moderately characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Fairly characteristic of me"
                >Fairly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Very characteristic of me"
                >Very characteristic of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >8. I am somewhat uncomfortable being close to others.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="uncomfortableBeingClose">
              <mat-button-toggle value="Not at all characteristic of me"
                >Not at all characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly characteristic of me"
                >Slightly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately characteristic of me"
                >Moderately characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Fairly characteristic of me"
                >Fairly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Very characteristic of me"
                >Very characteristic of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >9. I often worry that romantic partners won't want to stay with
            me.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="worryPartnersWontStay">
              <mat-button-toggle value="Not at all characteristic of me"
                >Not at all characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly characteristic of me"
                >Slightly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately characteristic of me"
                >Moderately characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Fairly characteristic of me"
                >Fairly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Very characteristic of me"
                >Very characteristic of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >10. When I show my feelings for others , I'm afraid they will not
            feel the same about me.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              formControlName="fearUnreciprocatedFeelings"
            >
              <mat-button-toggle value="Not at all characteristic of me"
                >Not at all characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly characteristic of me"
                >Slightly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately characteristic of me"
                >Moderately characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Fairly characteristic of me"
                >Fairly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Very characteristic of me"
                >Very characteristic of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >11. I often wonder whether romantic partners really care about
            me.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="wonderIfPartnersCare">
              <mat-button-toggle value="Not at all characteristic of me"
                >Not at all characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly characteristic of me"
                >Slightly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately characteristic of me"
                >Moderately characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Fairly characteristic of me"
                >Fairly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Very characteristic of me"
                >Very characteristic of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >12. I am comfortable developing close relationships with
            others.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="comfortableWithCloseness">
              <mat-button-toggle value="Not at all characteristic of me"
                >Not at all characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly characteristic of me"
                >Slightly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately characteristic of me"
                >Moderately characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Fairly characteristic of me"
                >Fairly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Very characteristic of me"
                >Very characteristic of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >13. I am uncomfortable when anyone gets too emotionally close to
            me.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              formControlName="uncomfortableWithEmotions"
            >
              <mat-button-toggle value="Not at all characteristic of me"
                >Not at all characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly characteristic of me"
                >Slightly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately characteristic of me"
                >Moderately characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Fairly characteristic of me"
                >Fairly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Very characteristic of me"
                >Very characteristic of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >14. I know that people will be there when I need them.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="peopleThereWhenNeeded">
              <mat-button-toggle value="Not at all characteristic of me"
                >Not at all characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly characteristic of me"
                >Slightly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately characteristic of me"
                >Moderately characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Fairly characteristic of me"
                >Fairly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Very characteristic of me"
                >Very characteristic of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >15. I want to get close to people, but I worry about being
            hurt.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="wantClosenessFearHurt">
              <mat-button-toggle value="Not at all characteristic of me"
                >Not at all characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly characteristic of me"
                >Slightly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately characteristic of me"
                >Moderately characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Fairly characteristic of me"
                >Fairly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Very characteristic of me"
                >Very characteristic of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >16. I find it difficult to trust others completely.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="difficultToTrustOthers">
              <mat-button-toggle value="Not at all characteristic of me"
                >Not at all characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly characteristic of me"
                >Slightly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately characteristic of me"
                >Moderately characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Fairly characteristic of me"
                >Fairly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Very characteristic of me"
                >Very characteristic of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >17. Romantic partners often want me to be emotionally closer than I
            feel comfortable being.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              formControlName="partnersWantMoreCloseness"
            >
              <mat-button-toggle value="Not at all characteristic of me"
                >Not at all characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly characteristic of me"
                >Slightly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately characteristic of me"
                >Moderately characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Fairly characteristic of me"
                >Fairly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Very characteristic of me"
                >Very characteristic of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >18. I am not sure that I can always depend on people to be there
            when I need them.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="uncertainOfDependability">
              <mat-button-toggle value="Not at all characteristic of me"
                >Not at all characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly characteristic of me"
                >Slightly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately characteristic of me"
                >Moderately characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Fairly characteristic of me"
                >Fairly characteristic of me</mat-button-toggle
              >
              <mat-button-toggle value="Very characteristic of me"
                >Very characteristic of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          aasAssessmentForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!aasAssessmentForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
