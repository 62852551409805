import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import {
  Case,
  Countries,
  DataTablesModel,
  IPatientNote,
  Patient,
} from 'src/app/_models';
import { PatientNotesHttpService } from 'src/app/_services';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { PermissionsSandbox } from 'src/app/shared/sandbox/permissions.sandbox';
import { AddCaseNotesDialogComponent } from './dialogs/add/add.component';

@Component({
  selector: 'app-case-notes',
  templateUrl: './case-notes.component.html',
  styleUrls: ['./case-notes.component.css'],
})
export class CaseNotesComponent implements OnInit {
  @Input() case: Case;
  @Input() patient: Patient;

  @Input() hasCaseNotesViewAccess: boolean;
  @Input() hasCaseNotesAddAccess: boolean;
  @Input() hasCaseNotesEditAccess: boolean;
  @Input() hasCaseNotesDeleteAccess: boolean;

  displayedColumns = ['text', 'actions'];
  data: DataTablesModel = {} as DataTablesModel;

  isLoadingResults = false;
  apiError = false;

  isIndianOrg: boolean = false;
  orgConfig: any;

  constructor(
    public dialog: MatDialog,
    public caseNotesHttpService: PatientNotesHttpService,
    protected permissionsSandbox: PermissionsSandbox,
    private orgConfigSandbox: OrgConfigSandbox
  ) {}

  ngOnInit() {
    this.data.page = 0;
    this.data.per_page = 10;

    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          this.orgConfig = orgConfig;
          if (this.orgConfig?.organizationCountry === Countries.India) {
            this.isIndianOrg = true;
          }
        });
      }
    });

    this.loadCaseNote();
    this.notesListener();
  }

  addCaseNote() {
    const dialogRef = this.dialog.open(AddCaseNotesDialogComponent, {
      data: {
        action: 'ADD',
        caseNotes: null,
        patientId: this.case.patientId,
        caseId: this.case.id,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadCaseNote();
      }
    });
  }
  public editCaseNote(caseNotes: IPatientNote) {
    const dialogRef = this.dialog.open(AddCaseNotesDialogComponent, {
      data: { action: 'EDIT', caseNotes },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadCaseNote();
      }
    });
  }

  deleteCaseNote(caseNotes: IPatientNote) {
    const dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message: 'Are you sure that you want to delete this Case Note?',
      },
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.caseNotesHttpService
          .deleteCaseNote(this.case.id, caseNotes)
          .subscribe(() => {
            this.loadCaseNote();
          });
      }
    });
  }

  public loadCaseNote() {
    this.isLoadingResults = true;
    this.apiError = false;

    this.caseNotesHttpService
      .getCaseNotes(
        this.case.id,
        this.case.patientId,
        this.data.per_page,
        this.data.page
      )
      .subscribe(
        (response) => {
          this.isLoadingResults = false;
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          }
          if (response == null) {
            this.data.items = [];
            this.data.total = 0;
          }
        },
        (error) => {
          this.apiError = true;
          this.isLoadingResults = false;
        }
      );
  }

  notesListener() {
    addEventListener('notesModified', (event) => {
      this.loadCaseNote();
    });
  }

  // Member Notes Paginator
  getNextMemberNotes(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadCaseNote();
  }
}
