<div class="row">
  <div class="col-md-12">
    <p style="font-size: 16px; font-weight: 450">
      <a routerLink="/" routerLinkActive="active">
        <em
          class="material-icons text_align-center"
          style="font-size: 18px; margin-top: -3px"
          >home</em
        >
      </a>
      &nbsp; / &nbsp;
      <span style="color: dimgray">My Appointment Duration </span>
    </p>
  </div>
</div>

<div style="display: flex; justify-content: center">
  <form [formGroup]="timeSlotDurationForm" class="sessionContainer">
    <mat-spinner *ngIf="loadingSlotDurationData"></mat-spinner>
    <div *ngIf="!loadingSlotDurationData">
      <label class="labelText" style="font-weight: 500">
        Duration <span style="color: red">*</span>
      </label>
      <mat-form-field appearance="outline" style="font-weight: 450">
        <mat-select
          formControlName="timeSlotDuration"
          (selectionChange)="onTimeSlotDurationChange($event)"
        >
          <mat-option *ngFor="let time of timings" [value]="time.minutes">
            {{ time.text }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</div>
