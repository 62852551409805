export enum Role {
  SuperOrgAdmin = 'superorgadmin',
  Admin = 'orgadmin',
  Caregiver = 'Caregiver',
  Manager = 'Manager',
  Nurse = 'Nurse',
  Physician = 'physician',
  HospitalDevice = 'inhospitaldevice',
  Patient = 'patient',
}
