import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DataTablesModel } from '../../_models';

@Injectable({ providedIn: 'root' })
export class VisitReasonsAPIService {
  constructor(private httpClient: HttpClient) {}

  getAllActiveVisitReasons(): Observable<DataTablesModel> {
    let params = new HttpParams();

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/allactivevisitreasons`,
      { params: params }
    );
  }
}
