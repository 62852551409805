import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IAsiPsychiatric } from 'src/app/_models/assessments/asi/asipsychiatric.model';
import { AsiApiService } from 'src/app/_services/assessments/asi/asi.service';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-asi-psychiatric',
  templateUrl: './asi-psychiatric.component.html',
  styleUrls: ['./asi-psychiatric.component.css']
})

export class AsiPsychiatricComponent implements OnInit {
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentRequestId: string;

  constructor(
    private asiApiService: AsiApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute) {
  }

  public action: string;
  public asipsychiatricForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  asipsychiatric: IAsiPsychiatric;
  asipsychiatricError: Error = null;
  errors: any = {};
  processing: boolean = false;
  systemGeneratedScore: number;

  public ngOnInit() {
    this.buildForm();
    this.errorHandler.handleErrors(this.asipsychiatricForm, this.errors);

    this.loadAsiPsychiatric();
  }


  loadAsiPsychiatric() {
    this.asiApiService.getAsiDetails(this.assessmentRequestId).subscribe(response => {
      if (response.data) {
        this.asipsychiatricForm.controls['p1'].setValue(response.data.asiPsychiatric.p1);
        this.asipsychiatricForm.controls['p2'].setValue(response.data.asiPsychiatric.p2);
        this.asipsychiatricForm.controls['p3'].setValue(response.data.asiPsychiatric.p3);
        this.asipsychiatricForm.controls['p4_past30'].setValue(response.data.asiPsychiatric.p4_past30);
        this.asipsychiatricForm.controls['p4_inlife'].setValue(response.data.asiPsychiatric.p4_inlife);
        this.asipsychiatricForm.controls['p5_past30'].setValue(response.data.asiPsychiatric.p5_past30);
        this.asipsychiatricForm.controls['p5_inlife'].setValue(response.data.asiPsychiatric.p5_inlife);
        this.asipsychiatricForm.controls['p6_past30'].setValue(response.data.asiPsychiatric.p6_past30);
        this.asipsychiatricForm.controls['p6_inlife'].setValue(response.data.asiPsychiatric.p6_inlife);
        this.asipsychiatricForm.controls['p7_past30'].setValue(response.data.asiPsychiatric.p7_past30);
        this.asipsychiatricForm.controls['p7_inlife'].setValue(response.data.asiPsychiatric.p7_inlife);
        this.asipsychiatricForm.controls['p8_past30'].setValue(response.data.asiPsychiatric.p8_past30);
        this.asipsychiatricForm.controls['p8_inlife'].setValue(response.data.asiPsychiatric.p8_inlife);
        this.asipsychiatricForm.controls['p9_past30'].setValue(response.data.asiPsychiatric.p9_past30);
        this.asipsychiatricForm.controls['p9_inlife'].setValue(response.data.asiPsychiatric.p9_inlife);
        this.asipsychiatricForm.controls['p10_past30'].setValue(response.data.asiPsychiatric.p10_past30);
        this.asipsychiatricForm.controls['p10_inlife'].setValue(response.data.asiPsychiatric.p10_inlife);
        this.asipsychiatricForm.controls['p11_past30'].setValue(response.data.asiPsychiatric.p11_past30);
        this.asipsychiatricForm.controls['p11_inlife'].setValue(response.data.asiPsychiatric.p11_inlife);
        this.asipsychiatricForm.controls['p12'].setValue(response.data.asiPsychiatric.p12);
        this.asipsychiatricForm.controls['p13'].setValue(response.data.asiPsychiatric.p13);
        this.asipsychiatricForm.controls['p14'].setValue(response.data.asiPsychiatric.p14);
        this.asipsychiatricForm.controls['p15'].setValue(response.data.asiPsychiatric.p15);
        this.asipsychiatricForm.controls['p16'].setValue(response.data.asiPsychiatric.p16);
        this.asipsychiatricForm.controls['p17'].setValue(response.data.asiPsychiatric.p17);
        this.asipsychiatricForm.controls['p18'].setValue(response.data.asiPsychiatric.p18);
        this.asipsychiatricForm.controls['p19'].setValue(response.data.asiPsychiatric.p19);
        this.asipsychiatricForm.controls['p20'].setValue(response.data.asiPsychiatric.p20);
        this.asipsychiatricForm.controls['p21'].setValue(response.data.asiPsychiatric.p21);
        this.asipsychiatricForm.controls['p22'].setValue(response.data.asiPsychiatric.p22);
        this.asipsychiatricForm.controls['p23'].setValue(response.data.asiPsychiatric.p23);
        this.asipsychiatricForm.controls['comments'].setValue(response.data.asiPsychiatric.comments);
        this.asipsychiatricForm.controls['clinicianScore'].setValue(response.data.asiPsychiatric.clinicianScore);
      }
    });
  }
  onAsiPsychiatricFormSubmit() {
    if (this.asipsychiatricForm.invalid) {
      this.asipsychiatricForm.markAllAsTouched();
      return;
    }
    this.asipsychiatric = Object.assign({}, this.asipsychiatric);
    this.asipsychiatric = Object.assign(this.asipsychiatric, this.asipsychiatricForm.value);
    this.asiApiService.updateAsiPsychiatric(this.asipsychiatric).subscribe(() => {
      this.asipsychiatricForm.reset();
      this.asipsychiatricForm.clearValidators();
      this.asipsychiatricForm.markAsUntouched();
      this.asipsychiatricForm.markAsPristine();
    })
  };


  public validation_messages = {
    'clinicianScore': [
      { type: 'required', message: 'Clinician Score is required' },
    ],
    'p1': [
      { type: 'required', message: 'P1. In a hospital or inpatient setting?  is required' },
    ],
    'p2': [
      { type: 'required', message: 'P2. Outpatient/private patient?  is required' },
    ],
    'p3': [
      { type: 'required', message: 'P3. Do you receive a pension for a psychiatric disability? is required' },
    ],
    'p4_past30': [
      { type: 'required', message: 'P4. Experienced serious depression is required' },
    ],
    'p4_inlife': [
      { type: 'required', message: 'P4. Experienced serious depression is required' },
    ],
    'p5_past30': [
      { type: 'required', message: 'P5. Experienced serious anxiety or tension  is required' },
    ],
    'p5_inlife': [
      { type: 'required', message: 'P5. Experienced serious anxiety or tension  is required' },
    ],
    'p6_past30': [
      { type: 'required', message: 'P6. Experienced hallucinations is required' },
    ],
    'p6_inlife': [
      { type: 'required', message: 'P6. Experienced hallucinations is required' },
    ],
    'p7_past30': [
      { type: 'required', message: 'P7. Experienced trouble understanding, concentrating or remembering  is required' },
    ],
    'p7_inlife': [
      { type: 'required', message: 'P7. Experienced trouble understanding, concentrating or remembering  is required' },
    ],
    'p8_past30': [
      { type: 'required', message: 'P8. Experienced trouble controlling violent behavior including episodes or rage or violence  is required' },
    ],
    'p8_inlife': [
      { type: 'required', message: 'P8. Experienced trouble controlling violent behavior including episodes or rage or violence  is required' },
    ],
    'p9_past30': [
      { type: 'required', message: 'P9. Experienced serious thoughts of suicide  is required' },
    ],
    'p9_inlife': [
      { type: 'required', message: 'P9. Experienced serious thoughts of suicide  is required' },
    ],
    'p10_past30': [
      { type: 'required', message: 'P10. Attempted suicide  is required' },
    ],
    'p10_inlife': [
      { type: 'required', message: 'P10. Attempted suicide  is required' },
    ],
    'p11_past30': [
      { type: 'required', message: 'P11. Been prescribed medication for any psychological or emotional problems  is required' },
    ],
    'p11_inlife': [
      { type: 'required', message: 'P11. Been prescribed medication for any psychological or emotional problems  is required' },
    ],
    'p12': [
      { type: 'required', message: 'P12. How many days in the past 30 have you experienced these psychological or emotional problems? is required' },
      { type: 'minvalue', message: 'P12. How many days in the past 30 have you experienced these psychological or emotional problems? cannot be less than 0' },
      { type: 'maxvalue', message: 'P12. How many days in the past 30 have you experienced these psychological or emotional problems? cannot be greater than 30' },
    ],
    'p13': [
      { type: 'required', message: 'P13. How much have you been troubled or bothered by these psychological or emotional problems in the past 30 days?  is required' },
    ],
    'p14': [
      { type: 'required', message: 'P14. How important to you now is treatment for these psychological problems?  is required' },
    ],
    'p15': [
      { type: 'required', message: 'P15. Obviously depressed/withdrawn is required' },
    ],
    'p16': [
      { type: 'required', message: '16 is required' },
    ],
    'p17': [
      { type: 'required', message: '17 is required' },
    ],
    'p18': [
      { type: 'required', message: '18 is required' },
    ],
    'p19': [
      { type: 'required', message: '19 is required' },
    ],
    'p20': [
      { type: 'required', message: '20 is required' },
    ],
    'p21': [
      { type: 'required', message: '21 is required' },
      { type: 'minvalue', message: '21 cannot be less than 0' },
      { type: 'maxvalue', message: '21 cannot be greater than 9' },
    ],
    'p22': [
      { type: 'required', message: '22 is required' },
    ],
    'p23': [
      { type: 'required', message: '23 is required' },
    ],
    'comments': [
      { type: 'required', message: 'Comments is required' },
    ],
  };

  private buildForm() {
    this.asipsychiatricForm = this.formBuilder.group({
      clinicianScore: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      systemScore: new FormControl(0, Validators.compose([
      ])),
      sessionId: new FormControl(this.sessionId, Validators.compose([
        Validators.required,
      ])),
      patientId: new FormControl(this.patientId, Validators.compose([
        Validators.required,
      ])),
      assessmentRequestId: new FormControl(this.assessmentRequestId, Validators.compose([
        Validators.required,
      ])),
      p1: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      p2: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      p3: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      p4_past30: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      p4_inlife: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      p5_past30: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      p5_inlife: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      p6_past30: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      p6_inlife: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      p7_past30: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      p7_inlife: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      p8_past30: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      p8_inlife: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      p9_past30: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      p9_inlife: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      p10_past30: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      p10_inlife: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      p11_past30: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      p11_inlife: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      p12: new FormControl('', Validators.compose([
        Validators.required,
        Validators.min(0),
        Validators.max(30),
      ])),
      p13: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      p14: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      p15: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      p16: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      p17: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      p18: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      p19: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      p20: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      p21: new FormControl('', Validators.compose([
        Validators.required,
        Validators.min(0),
        Validators.max(9),
      ])),
      p22: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      p23: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      comments: new FormControl('', Validators.compose([
        Validators.required,
      ])),
    });
    this.asipsychiatricForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }


  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.asipsychiatricForm) {
      return;
    }
    const form = this.asipsychiatricForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }

}