import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IAsiAlcoholDrugs } from 'src/app/_models/assessments/asi/asialcoholdrugs.model';
import { AsiApiService } from 'src/app/_services/assessments/asi/asi.service';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
@Component({
  selector: 'app-asi-alcohol-drugs',
  templateUrl: './asi-alcohol-drugs.component.html',
  styleUrls: ['./asi-alcohol-drugs.component.css'],
})
export class AsiAlcoholDrugsComponent implements OnInit {
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentRequestId: string;

  constructor(
    private asiApiService: AsiApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute
  ) { }

  public action: string;
  public asialcoholdrugsForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  asialcoholdrugs: IAsiAlcoholDrugs;
  asialcoholdrugsError: Error = null;
  errors: any = {};
  processing: boolean = false;
  systemGeneratedScore: number;

  public ngOnInit() {
    this.buildForm();
    this.errorHandler.handleErrors(this.asialcoholdrugsForm, this.errors);

    this.loadAsiAlcoholDrugs();
  }

  loadAsiAlcoholDrugs() {
    this.asiApiService
      .getAsiDetails(this.assessmentRequestId)
      .subscribe((response) => {
        if (response.data) {
          this.asialcoholdrugsForm.controls['d1_past30'].setValue(
            response.data.asiAlcoholDrugs.d1_past30
          );
          this.asialcoholdrugsForm.controls['d1_lifetime'].setValue(
            response.data.asiAlcoholDrugs.d1_lifetime
          );
          this.asialcoholdrugsForm.controls['d2_past30'].setValue(
            response.data.asiAlcoholDrugs.d2_past30
          );
          this.asialcoholdrugsForm.controls['d2_lifetime'].setValue(
            response.data.asiAlcoholDrugs.d2_lifetime
          );
          this.asialcoholdrugsForm.controls['d3_past30'].setValue(
            response.data.asiAlcoholDrugs.d3_past30
          );
          this.asialcoholdrugsForm.controls['d3_lifetime'].setValue(
            response.data.asiAlcoholDrugs.d3_lifetime
          );
          this.asialcoholdrugsForm.controls['d3_route'].setValue(
            response.data.asiAlcoholDrugs.d3_route
          );
          this.asialcoholdrugsForm.controls['d4_past30'].setValue(
            response.data.asiAlcoholDrugs.d4_past30
          );
          this.asialcoholdrugsForm.controls['d4_lifetime'].setValue(
            response.data.asiAlcoholDrugs.d4_lifetime
          );
          this.asialcoholdrugsForm.controls['d4_route'].setValue(
            response.data.asiAlcoholDrugs.d4_route
          );
          this.asialcoholdrugsForm.controls['d5_past30'].setValue(
            response.data.asiAlcoholDrugs.d5_past30
          );
          this.asialcoholdrugsForm.controls['d5_lifetime'].setValue(
            response.data.asiAlcoholDrugs.d5_lifetime
          );
          this.asialcoholdrugsForm.controls['d5_route'].setValue(
            response.data.asiAlcoholDrugs.d5_route
          );
          this.asialcoholdrugsForm.controls['d6_past30'].setValue(
            response.data.asiAlcoholDrugs.d6_past30
          );
          this.asialcoholdrugsForm.controls['d6_lifetime'].setValue(
            response.data.asiAlcoholDrugs.d6_lifetime
          );
          this.asialcoholdrugsForm.controls['d6_route'].setValue(
            response.data.asiAlcoholDrugs.d6_route
          );
          this.asialcoholdrugsForm.controls['d7_past30'].setValue(
            response.data.asiAlcoholDrugs.d7_past30
          );
          this.asialcoholdrugsForm.controls['d7_lifetime'].setValue(
            response.data.asiAlcoholDrugs.d7_lifetime
          );
          this.asialcoholdrugsForm.controls['d7_route'].setValue(
            response.data.asiAlcoholDrugs.d7_route
          );
          this.asialcoholdrugsForm.controls['d8_past30'].setValue(
            response.data.asiAlcoholDrugs.d8_past30
          );
          this.asialcoholdrugsForm.controls['d8_lifetime'].setValue(
            response.data.asiAlcoholDrugs.d8_lifetime
          );
          this.asialcoholdrugsForm.controls['d8_route'].setValue(
            response.data.asiAlcoholDrugs.d8_route
          );
          this.asialcoholdrugsForm.controls['d9_past30'].setValue(
            response.data.asiAlcoholDrugs.d9_past30
          );
          this.asialcoholdrugsForm.controls['d9_lifetime'].setValue(
            response.data.asiAlcoholDrugs.d9_lifetime
          );
          this.asialcoholdrugsForm.controls['d9_route'].setValue(
            response.data.asiAlcoholDrugs.d9_route
          );
          this.asialcoholdrugsForm.controls['d10_past30'].setValue(
            response.data.asiAlcoholDrugs.d10_past30
          );
          this.asialcoholdrugsForm.controls['d10_lifetime'].setValue(
            response.data.asiAlcoholDrugs.d10_lifetime
          );
          this.asialcoholdrugsForm.controls['d10_route'].setValue(
            response.data.asiAlcoholDrugs.d10_route
          );
          this.asialcoholdrugsForm.controls['d11_past30'].setValue(
            response.data.asiAlcoholDrugs.d11_past30
          );
          this.asialcoholdrugsForm.controls['d11_lifetime'].setValue(
            response.data.asiAlcoholDrugs.d11_lifetime
          );
          this.asialcoholdrugsForm.controls['d11_route'].setValue(
            response.data.asiAlcoholDrugs.d11_route
          );
          this.asialcoholdrugsForm.controls['d12_past30'].setValue(
            response.data.asiAlcoholDrugs.d12_past30
          );
          this.asialcoholdrugsForm.controls['d12_lifetime'].setValue(
            response.data.asiAlcoholDrugs.d12_lifetime
          );
          this.asialcoholdrugsForm.controls['d12_route'].setValue(
            response.data.asiAlcoholDrugs.d12_route
          );
          this.asialcoholdrugsForm.controls['d13_past30'].setValue(
            response.data.asiAlcoholDrugs.d13_past30
          );
          this.asialcoholdrugsForm.controls['d13_lifetime'].setValue(
            response.data.asiAlcoholDrugs.d13_lifetime
          );
          this.asialcoholdrugsForm.controls['d14'].setValue(
            response.data.asiAlcoholDrugs.d14
          );
          this.asialcoholdrugsForm.controls['d15'].setValue(
            response.data.asiAlcoholDrugs.d15
          );
          this.asialcoholdrugsForm.controls['d16'].setValue(
            response.data.asiAlcoholDrugs.d16
          );
          this.asialcoholdrugsForm.controls['d17'].setValue(
            response.data.asiAlcoholDrugs.d17
          );
          this.asialcoholdrugsForm.controls['d18'].setValue(
            response.data.asiAlcoholDrugs.d18
          );
          this.asialcoholdrugsForm.controls['d19'].setValue(
            response.data.asiAlcoholDrugs.d19
          );
          this.asialcoholdrugsForm.controls['d20'].setValue(
            response.data.asiAlcoholDrugs.d20
          );
          this.asialcoholdrugsForm.controls['d21'].setValue(
            response.data.asiAlcoholDrugs.d21
          );
          this.asialcoholdrugsForm.controls['d22'].setValue(
            response.data.asiAlcoholDrugs.d22
          );
          this.asialcoholdrugsForm.controls['d23'].setValue(
            response.data.asiAlcoholDrugs.d23
          );
          this.asialcoholdrugsForm.controls['d24'].setValue(
            response.data.asiAlcoholDrugs.d24
          );
          this.asialcoholdrugsForm.controls['d25'].setValue(
            response.data.asiAlcoholDrugs.d25
          );
          this.asialcoholdrugsForm.controls['d26'].setValue(
            response.data.asiAlcoholDrugs.d26
          );
          this.asialcoholdrugsForm.controls['d27'].setValue(
            response.data.asiAlcoholDrugs.d27
          );
          this.asialcoholdrugsForm.controls['d28'].setValue(
            response.data.asiAlcoholDrugs.d28
          );
          this.asialcoholdrugsForm.controls['d29'].setValue(
            response.data.asiAlcoholDrugs.d29
          );
          this.asialcoholdrugsForm.controls['d30'].setValue(
            response.data.asiAlcoholDrugs.d30
          );
          this.asialcoholdrugsForm.controls['d31'].setValue(
            response.data.asiAlcoholDrugs.d31
          );
          this.asialcoholdrugsForm.controls['d32'].setValue(
            response.data.asiAlcoholDrugs.d32
          );
          this.asialcoholdrugsForm.controls['d33'].setValue(
            response.data.asiAlcoholDrugs.d33
          );
          this.asialcoholdrugsForm.controls['d34'].setValue(
            response.data.asiAlcoholDrugs.d34
          );
          this.asialcoholdrugsForm.controls['d35'].setValue(
            response.data.asiAlcoholDrugs.d35
          );
          this.asialcoholdrugsForm.controls['comments'].setValue(
            response.data.asiAlcoholDrugs.comments
          );
          this.asialcoholdrugsForm.controls['clinicianScore'].setValue(response.data.asiAlcoholDrugs.clinicianScore);
        }
      });
  }
  onAsiAlcoholDrugsFormSubmit() {
    if (this.asialcoholdrugsForm.invalid) {
      this.asialcoholdrugsForm.markAllAsTouched();
      return;
    }
    this.asialcoholdrugs = Object.assign({}, this.asialcoholdrugs);
    this.asialcoholdrugs = Object.assign(
      this.asialcoholdrugs,
      this.asialcoholdrugsForm.value
    );
    this.asiApiService
      .updateAsiAlcoholDrugs(this.asialcoholdrugs)
      .subscribe(() => {
      });
  }

  public validation_messages = {
    'clinicianScore': [
      { type: 'required', message: 'Clinician Score is required' },
    ],
    d1_past30: [{ type: 'required', message: 'Required' }],
    d1_lifetime: [{ type: 'required', message: 'Required' }],
    d2_past30: [{ type: 'required', message: 'Required' }],
    d2_lifetime: [{ type: 'required', message: 'Required' }],
    d3_past30: [{ type: 'required', message: 'Required' }],
    d3_lifetime: [{ type: 'required', message: 'Required' }],
    d3_route: [{ type: 'required', message: 'Required' }],
    d4_past30: [{ type: 'required', message: 'Required' }],
    d4_lifetime: [{ type: 'required', message: 'Required' }],
    d4_route: [{ type: 'required', message: 'Required' }],
    d5_past30: [{ type: 'required', message: 'Required' }],
    d5_lifetime: [{ type: 'required', message: 'Required' }],
    d5_route: [{ type: 'required', message: 'Required' }],
    d6_past30: [{ type: 'required', message: 'Required' }],
    d6_lifetime: [{ type: 'required', message: 'Required' }],
    d6_route: [{ type: 'required', message: 'Required' }],
    d7_past30: [{ type: 'required', message: 'Required' }],
    d7_lifetime: [{ type: 'required', message: 'Required' }],
    d7_route: [{ type: 'required', message: 'Required' }],
    d8_past30: [{ type: 'required', message: 'Required' }],
    d8_lifetime: [{ type: 'required', message: 'Required' }],
    d8_route: [{ type: 'required', message: 'Required' }],
    d9_past30: [{ type: 'required', message: 'Required' }],
    d9_lifetime: [{ type: 'required', message: 'Required' }],
    d9_route: [{ type: 'required', message: 'Required' }],
    d10_past30: [{ type: 'required', message: 'Required' }],
    d10_lifetime: [{ type: 'required', message: 'Required' }],
    d10_route: [{ type: 'required', message: 'Required' }],
    d11_past30: [{ type: 'required', message: 'Required' }],
    d11_lifetime: [{ type: 'required', message: 'Required' }],
    d11_route: [{ type: 'required', message: 'Required' }],
    d12_past30: [{ type: 'required', message: 'Required' }],
    d12_lifetime: [{ type: 'required', message: 'Required' }],
    d12_route: [{ type: 'required', message: 'Required' }],
    d13_past30: [{ type: 'required', message: 'Required' }],
    d13_lifetime: [{ type: 'required', message: 'Required' }],
    d14: [
      {
        type: 'required',
        message:
          'D14. According to the interviewer, which substance is/are the major problem? is required',
      },
    ],
    d15: [
      {
        type: 'required',
        message:
          'D15. How long was your last period of voluntary abstinence from this major substance? is required',
      },
    ],
    d16: [
      {
        type: 'required',
        message:
          'D16. How many months ago did this abstinence end? is required',
      },
    ],
    d17: [
      {
        type: 'required',
        message:
          'D17. How many times have you had Alcohol D.T.’s? Delirium Tremems (DTs) is required',
      },
    ],
    d18: [
      {
        type: 'required',
        message:
          'D18. How many times have you overdosed on drugs? Overdoses (OD) is required',
      },
    ],
    d19: [
      {
        type: 'required',
        message:
          'D19. How many times in your life have you been treated for: D19.* Alcohol abuse?  is required',
      },
    ],
    d20: [{ type: 'required', message: 'D20. Drug abuse? is required' }],
    d21: [
      {
        type: 'required',
        message: 'D21. How many of these were detox only Alcohol?  is required',
      },
    ],
    d22: [
      {
        type: 'required',
        message: 'D22. How many of these were detox only Drugs?  is required',
      },
    ],
    d23: [
      {
        type: 'required',
        message:
          'D23. How much money would you say you spent during the past 30 days on Alcohol?  is required',
      },
    ],
    d24: [
      {
        type: 'required',
        message:
          'D24. How much money would you say you spent during the past 30 days on Drugs? is required',
      },
    ],
    d25: [
      {
        type: 'required',
        message:
          'D25. How many days have you been treated in an outpatient setting for alcohol or drugs in the past 30 days? is required',
      },
    ],
    d26: [
      {
        type: 'required',
        message:
          'D26. How many days in the past 30 have you experienced Alcohol problems? is required',
      },
    ],
    d27: [
      {
        type: 'required',
        message:
          'D27. How many days in the past 30 have you experienced Drug problems? is required',
      },
    ],
    d28: [
      {
        type: 'required',
        message:
          'D28. How troubled or bothered have you been in the past 30 days by Alcohol problems is required',
      },
    ],
    d29: [
      {
        type: 'required',
        message:
          'D29. How troubled or bothered have you been in the past 30 days by Drug problems is required',
      },
    ],
    d30: [
      {
        type: 'required',
        message:
          'D30. How important to you now is treatment for Alcohol problems is required',
      },
    ],
    d31: [
      {
        type: 'required',
        message:
          'D31. How important to you now is treatment for Drug problems is required',
      },
    ],
    d32: [
      {
        type: 'required',
        message:
          'D32. How would you rate the patient’s need for treatment for Alcohol problems  is required',
      },
    ],
    d33: [
      {
        type: 'required',
        message:
          'D33. How would you rate the patient’s need for treatment for Drug problems  is required',
      },
    ],
    d34: [
      {
        type: 'required',
        message:
          'D34. Is the above information significantly distorted by Client’s misrepresentation? is required',
      },
    ],
    d35: [
      {
        type: 'required',
        message:
          'D35. Is the above information significantly distorted by Client’s inability to understand? is required',
      },
    ],
    comments: [{ type: 'required', message: 'Comments is required' }],
  };

  private buildForm() {
    this.asialcoholdrugsForm = this.formBuilder.group({
      clinicianScore: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      systemScore: new FormControl(0, Validators.compose([
      ])),
      sessionId: new FormControl(this.sessionId, Validators.compose([
        Validators.required,
      ])),
      patientId: new FormControl(this.patientId, Validators.compose([
        Validators.required,
      ])),
      assessmentRequestId: new FormControl(this.assessmentRequestId, Validators.compose([
        Validators.required,
      ])),
      d1_past30: new FormControl('', Validators.compose([Validators.required])),
      d1_lifetime: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      d2_past30: new FormControl('', Validators.compose([Validators.required])),
      d2_lifetime: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      d3_past30: new FormControl('', Validators.compose([Validators.required])),
      d3_lifetime: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      d3_route: new FormControl('', Validators.compose([Validators.required])),
      d4_past30: new FormControl('', Validators.compose([Validators.required])),
      d4_lifetime: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      d4_route: new FormControl('', Validators.compose([Validators.required])),
      d5_past30: new FormControl('', Validators.compose([Validators.required])),
      d5_lifetime: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      d5_route: new FormControl('', Validators.compose([Validators.required])),
      d6_past30: new FormControl('', Validators.compose([Validators.required])),
      d6_lifetime: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      d6_route: new FormControl('', Validators.compose([Validators.required])),
      d7_past30: new FormControl('', Validators.compose([Validators.required])),
      d7_lifetime: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      d7_route: new FormControl('', Validators.compose([Validators.required])),
      d8_past30: new FormControl('', Validators.compose([Validators.required])),
      d8_lifetime: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      d8_route: new FormControl('', Validators.compose([Validators.required])),
      d9_past30: new FormControl('', Validators.compose([Validators.required])),
      d9_lifetime: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      d9_route: new FormControl('', Validators.compose([Validators.required])),
      d10_past30: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      d10_lifetime: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      d10_route: new FormControl('', Validators.compose([Validators.required])),
      d11_past30: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      d11_lifetime: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      d11_route: new FormControl('', Validators.compose([Validators.required])),
      d12_past30: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      d12_lifetime: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      d12_route: new FormControl('', Validators.compose([Validators.required])),
      d13_past30: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      d13_lifetime: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      d14: new FormControl('', Validators.compose([Validators.required])),
      d15: new FormControl('', Validators.compose([Validators.required])),
      d16: new FormControl('', Validators.compose([Validators.required])),
      d17: new FormControl('', Validators.compose([Validators.required])),
      d18: new FormControl('', Validators.compose([Validators.required])),
      d19: new FormControl('', Validators.compose([Validators.required])),
      d20: new FormControl('', Validators.compose([Validators.required])),
      d21: new FormControl('', Validators.compose([Validators.required])),
      d22: new FormControl('', Validators.compose([Validators.required])),
      d23: new FormControl('', Validators.compose([Validators.required])),
      d24: new FormControl('', Validators.compose([Validators.required])),
      d25: new FormControl('', Validators.compose([Validators.required])),
      d26: new FormControl('', Validators.compose([Validators.required])),
      d27: new FormControl('', Validators.compose([Validators.required])),
      d28: new FormControl('', Validators.compose([Validators.required])),
      d29: new FormControl('', Validators.compose([Validators.required])),
      d30: new FormControl('', Validators.compose([Validators.required])),
      d31: new FormControl('', Validators.compose([Validators.required])),
      d32: new FormControl('', Validators.compose([Validators.required])),
      d33: new FormControl('', Validators.compose([Validators.required])),
      d34: new FormControl('', Validators.compose([Validators.required])),
      d35: new FormControl('', Validators.compose([Validators.required])),
      comments: new FormControl(''),
    });
    this.asialcoholdrugsForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.asialcoholdrugsForm) {
      return;
    }
    const form = this.asialcoholdrugsForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
