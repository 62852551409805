<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Payment Notes</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="row">
    <div class="col-md-12">
      <label>
        {{ notes }}
      </label>
    </div>
  </div>
</div>
