import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ChartService {
  private _baseUrl: string =
    'https://charts.mongodb.com/charts-dev-bundlen-ccndk';
  private _chartConfig = {
    patientOverallAssessmentProgressChart:
      '65006f53-8980-4713-84c0-8528ac2049a1',
    patientTopTenAssesmentsChart: '1938c605-b289-45ed-88ef-01f50cc676a9',
    patientSessionsAttendedChart: '65148e62-5e3b-43da-8b1c-77c81fed84f1',
  };

  getbaseUrl(): string {
    return this._baseUrl;
  }

  getchartConfig(): any {
    return this._chartConfig;
  }

  constructor() {
    this.setEnvVariables();
  }

  private setEnvVariables(): void {
    const hostname = window && window.location && window.location.hostname;
    if (/localhost/.test(hostname) || /^dev-*/.test(hostname)) {
    } else if (/^test-*/.test(hostname)) {
    } else if (/^stage-*/.test(hostname)) {
    } else {
      this._baseUrl =
        'https://charts.mongodb.com/charts-prod-home-aspectn-xwors';
      this._chartConfig.patientOverallAssessmentProgressChart =
        '65130c62-82f2-4a65-8172-2f07388e59bb';
      this._chartConfig.patientTopTenAssesmentsChart =
        '65130c62-82f2-4a9c-8d2c-2f07388e59bd';
      this._chartConfig.patientSessionsAttendedChart =
        '65130c62-82f2-4b4b-8bf8-2f07388e59bf';
    }
  }
}
