import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataTablesModel } from 'src/app/_models';
import { IMemberPharmacy } from 'src/app/_models/patient/memberpharmacy.model';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class MemberPharmacyApiService {
  constructor(private httpClient: HttpClient) {}

  getMemberPharmacyList(
    patientId: string,
    name: string,
    status: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();

    if (name) params = params.append('name', name);
    if (status) params = params.append('status', status);

    params = params.append('patientId', patientId);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/memberpharmacylist`,
      { params: params }
    );
  }

  addMemberPharmacy(
    thisMemberPharmacy: IMemberPharmacy
  ): Observable<IMemberPharmacy> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<IMemberPharmacy>(
        `${environment.apiUrl}/memberpharmacy`,
        thisMemberPharmacy,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateMemberPharmacy(
    thisMemberPharmacy: IMemberPharmacy
  ): Observable<IMemberPharmacy> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<IMemberPharmacy>(
        `${environment.apiUrl}/memberpharmacy`,
        thisMemberPharmacy,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  deleteMemberPharmacy(id: string): Observable<void> {
    return this.httpClient
      .delete<void>(`${environment.apiUrl}/memberpharmacy/` + id)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  markAsPrimary(id: string): Observable<IMemberPharmacy> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<IMemberPharmacy>(
        `${environment.apiUrl}/markpharmacyasprimary/${id}`,

        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
