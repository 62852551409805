<!-- Provide a bunch of print options -->
<mat-card
  appearance="raised"
  style="margin-top: 10px; margin-bottom: 20px"
  *ngIf="activeTab === ''"
>
  <div class="row" style="margin-bottom: 25px">
    <mat-card-title>Reports</mat-card-title>
  </div>

  <div class="row">
    <!-- Patient Reports -->
    <div class="col-sm-6 col-md-6 col-xl-4" *ngFor="let report of reportTypes">
      <mat-card
        appearance="raised"
        style="border-radius: 15px"
        class="mat-elevation-z8"
      >
        <p style="font-weight: 800; font-size: 19px">{{ report.title }}</p>
        <div
          *ngFor="let reportType of report.forms"
          style="
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            column-gap: 10px;
            flex-wrap: wrap;
          "
        >
          <a
            (click)="activeTab = reportType.tabName"
            style="
              font-weight: 400;
              color: #2196f3;
              cursor: pointer;
              opacity: 0.8;
            "
          >
            {{ reportType.id }}
          </a>
        </div>
      </mat-card>
    </div>
  </div>
</mat-card>

<!-- If a tab is selected -->
<app-hourly-mood-report
  *ngIf="activeTab === 'MOOD'"
  [patientId]="patient.id"
  [patient]="patient"
  [hasMoodAddAccess]="hasMoodAddAccess"
  (output)="triggerEvent($event)"
></app-hourly-mood-report>

<app-behavior-incident-report
  *ngIf="activeTab === 'INCIDENTS'"
  [patientId]="patient.id"
  [patient]="patient"
  [hasIncidentAddAccess]="hasIncidentAddAccess"
  (output)="triggerEvent($event)"
>
</app-behavior-incident-report>
