import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { MatTab, MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { IOrgConfig, Patient } from 'src/app/_models';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-member-tab',
  templateUrl: './member-tab.component.html',
  styleUrls: ['./member-tab.component.css'],
})
export class MemberTabComponent implements OnInit {
  @ViewChildren(MatTab) tabs!: QueryList<MatTab>;

  @Input() patient: Patient;
  @Input() subSectionId: number;
  @Input() isPhysician: boolean;
  @Input() orgConfig: IOrgConfig;

  @Input() memberTabPreSelected: string;
  @Input() hasHospitalDeviceFeature: boolean;
  @Input() hasTherapySessionFeature: boolean;
  @Input() hasTeleHealthEnabled: boolean;
  @Input() prescriptionEnabled: boolean;

  @Input() hasMemberEditAccess: boolean;

  @Input() hasMemberPaymentsViewAccess: boolean;
  @Input() hasMemberPaymentsAddAccess: boolean;
  @Input() hasMemberPaymentsEditAccess: boolean;
  @Input() hasMemberPaymentsDeleteAccess: boolean;

  @Input() hasTherapySessionViewAccess: boolean;

  @Input() hasNoteViewAccess: boolean;
  @Input() hasNoteAddAccess: boolean;
  @Input() hasNoteEditAccess: boolean;

  @Input() hasMoodViewAccess: boolean;
  @Input() hasMoodAddAccess: boolean;

  @Input() hasIncidentViewAccess: boolean;
  @Input() hasIncidentAddAccess: boolean;

  @Input() hasGoalsViewAccess: boolean;
  @Input() hasGoalsAddAccess: boolean;
  @Input() hasGoalsEditAccess: boolean;
  @Input() hasGoalsDeleteAccess: boolean;

  @Input() hasDiagnosisViewAccess: boolean;
  @Input() hasDiagnosisAddAccess: boolean;
  @Input() hasDiagnosisEditAccess: boolean;
  @Input() hasDiagnosisDeleteAccess: boolean;

  @Input() hasMedicationViewAccess: boolean;
  @Input() hasMedicationAddAccess: boolean;
  @Input() hasMedicationEditAccess: boolean;
  @Input() hasMedicationDeleteAccess: boolean;

  @Input() hasAllergiesViewAccess: boolean;
  @Input() hasAllergiesAddAccess: boolean;
  @Input() hasAllergiesEditAccess: boolean;
  @Input() hasAllergiesDeleteAccess: boolean;

  @Input() hasPhysiciansViewAccess: boolean;
  @Input() hasPhysiciansAddAccess: boolean;
  @Input() hasPhysiciansEditAccess: boolean;
  @Input() hasPhysiciansDeleteAccess: boolean;

  @Input() hasContactsViewAccess: boolean;
  @Input() hasContactsAddAccess: boolean;
  @Input() hasContactsEditAccess: boolean;
  @Input() hasContactsDeleteAccess: boolean;

  @Input() hasMemberHistoryViewAccess: boolean;
  @Input() hasMemberHistoryEditAccess: boolean;

  @Input() hasFamilyHistoryViewAccess: boolean;
  @Input() hasFamilyHistoryEditAccess: boolean;

  @Input() hasMemberAssessmentViewAccess: boolean;
  @Input() hasMemberAssessmentAddAccess: boolean;
  @Input() hasMemberAssessmentEditAccess: boolean;
  @Input() hasMemberAssessmentDeleteAccess: boolean;

  @Input() hasAssessmentChartsViewAccess: boolean;

  @Input() hasAssessmentGuidanceViewAccess: boolean;
  @Input() hasAssessmentGuidanceEditAccess: boolean;

  @Input() hasAssessmentSummaryViewAccess: boolean;
  @Input() hasAssessmentSummaryEditAccess: boolean;

  @Input() hasAssessmentTakeAddAccess: boolean;

  @Output() output = new EventEmitter<any>();

  selectedIndex: number = 0;
  isProfileTabSelected = true;
  isInsuranceTabSelected = false;
  isContactsTabSelected = false;
  isPaymentsTabSelected = false;
  isAssessmentsTabSelected = false;
  isGoalsTabSelected = false;
  isDiagnosisTabSelected = false;
  isMedicationsTabSelected = false;
  isAllergiesTabSelected = false;
  isMedHistTabSelected = false;
  isPharmacyTabSelected = false;
  isLabOrdersTabSelected = false;
  isConsentTabSelected = false;

  labOrdersEnabled = false;

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    if (this.subSectionId) {
      this.selectedIndex = this.subSectionId;
    } else {
      this.isProfileTabSelected = true;
    }

    this.labOrdersEnabled = environment.enableLabOrders;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes?.subSectionId) {
      this.selectedIndex = changes.subSectionId.currentValue;

      // Only if the changes has patient changes, i.e, this tab was not visible before, trigger the load
      if (changes?.patient) {
        if (this.selectedIndex == 2) {
          this.toggleTabShowNotShow('Contacts');
        } else if (this.selectedIndex == 1) {
          this.toggleTabShowNotShow('Insurance');
        } else if (this.selectedIndex == 3) {
          this.toggleTabShowNotShow('Payments');
        }
      }
    }
  }

  triggerEvent(event) {
    this.output.emit(event);
  }

  async changeSelectedIndex(event: MatTabChangeEvent) {
    const index = event.index;
    const tabTitle = event.tab.textLabel;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        secondaryId: index,
      },
      queryParamsHandling: 'merge',
    });

    await this.toggleTabShowNotShow(tabTitle);
  }

  async toggleTabShowNotShow(tabTitle) {
    this.isProfileTabSelected = tabTitle == 'Profile';
    this.isInsuranceTabSelected = tabTitle == 'Insurance';
    this.isContactsTabSelected = tabTitle == 'Contacts';
    this.isPaymentsTabSelected = tabTitle == 'Payments';
    this.isLabOrdersTabSelected = tabTitle == 'Lab Orders';
    this.isAssessmentsTabSelected = tabTitle == 'Assessments';
    this.isGoalsTabSelected = tabTitle == 'Goals';
    this.isDiagnosisTabSelected = tabTitle == 'Diagnosis';
    this.isMedicationsTabSelected = tabTitle == 'Medications';
    this.isAllergiesTabSelected = tabTitle == 'Allergies';
    this.isMedHistTabSelected = tabTitle == 'Medical History';
    this.isPharmacyTabSelected = tabTitle == 'Pharmacies';
    this.isConsentTabSelected = tabTitle == 'Consent Documents';
  }

  // Get tab from the available tabs
  async getIndexOfTab(tabTitle): Promise<Number> {
    let index = 0;
    if (this.tabs) {
      const tabArray = this.tabs.toArray();
      tabArray.forEach((tab, i) => {
        if (tab.textLabel == tabTitle) {
          index = i;
        }
      });
    }
    return index;
  }
}
