<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Survey of Wellbeing of Young Children</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="col-md-12" *ngIf="!processing">
  <form
    [formGroup]="swycForm"
    novalidate
    (ngSubmit)="onSwycFormSubmit()"
    class="form-horizontal"
  >
    <mat-stepper linear="true" #stepper>
      <mat-step [stepControl]="initial" *ngIf="!assessmentId">
        <ng-template matStepLabel>Child Date Of Birth</ng-template>
        <br />

        <div class="row">
          <div class="col-md-5"></div>
          <div class="col-md-2">
            <label class="labelText">
              Child Date Of Birth <span style="color: red">*</span>
            </label>
            <mat-form-field appearance="outline">
              <input
                formControlName="childDob"
                matInput
                [matDatepicker]="childDobPicker"
                placeholder="Date"
                [max]="todaysDate"
                [min]="minimumDate"
                (dateChange)="onChangeDob(stepper)"
                autoSlashDate
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="childDobPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #childDobPicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-5"></div>
        </div>
      </mat-step>
      <mat-step [stepControl]="details">
        <ng-template matStepLabel>SWYC Form</ng-template>
        <br />
        <ng-container *ngIf="selectedAgeGroup === '2months'">
          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Developmental Milestones </mat-card-title>
              <mat-card-subtitle
                >Most children at this age will be able to do some (but not all)
                of the developmental tasks listed below. Please tell us how much
                your child is doing each of these things. PLEASE BE SURE TO
                ANSWER ALL THE QUESTIONS.</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Makes sounds that let you know he or she is happy or upset
                </label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q1TwoMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Seems happy to see you</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q2TwoMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Follows a moving toy with his or her eyes</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q3TwoMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Turns head to find the person who is talking</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q4TwoMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Holds head steady when being pulled up to a sitting
                  position</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q5TwoMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Brings hands together</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q6TwoMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after">Laughs</label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q7TwoMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Keeps head steady when held in a sitting position</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q8TwoMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Makes sounds like "ga""ma" or "ba"</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q9TwoMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Looks when you call his or her name</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q10TwoMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title>
                Baby Pediatric Symptom Checklist (BPSC)
              </mat-card-title>
              <mat-card-subtitle
                >These questions are about your child's behavior. Think about
                what you would expect of other children the same age, and tell
                us how much each statement applies to your
                child.</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have a hard time being with new
                  people?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q11TwoMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have a hard time in new places?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q12TwoMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have a hard time with change?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q13TwoMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child mind being held by other people?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q14TwoMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child cry a lot?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q15TwoMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have a hard time calming down?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q16TwoMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is your child fussy or irritable?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q17TwoMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is it hard to comfort your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q18TwoMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is it hard to keep your child on a schedule or
                  routine?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q19TwoMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is it hard to put your child to sleep?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q20TwoMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is it hard to get enough sleep because of your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q21TwoMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have trouble staying asleep?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q22TwoMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Parent's Concerns </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you have any concerns about your child's learning or
                  development?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q23TwoMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you have any concerns about your child's behavior?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q24TwoMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Family Questions </mat-card-title>
              <mat-card-subtitle
                >Because family members can have a big impact on your child's
                development, please answer a few questions about your family
                below:</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does anyone who lives with your child smoke tobacco?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q25TwoMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >In the last year, have you ever drunk alcohol or used drugs
                  more than you meant to?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q26TwoMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have you felt you wanted or needed to cut down on your
                  drinking or drug use in the last year?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q27TwoMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Has a family member's drinking or drug use ever had a bad
                  effect on your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q28TwoMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Within the past 12 months, we worried whether our food would
                  run out before we got money to buy more</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q29TwoMonths">
                    <mat-button-toggle value="Never true"
                      >Never true</mat-button-toggle
                    >
                    <mat-button-toggle value="Sometimes true"
                      >Sometimes true</mat-button-toggle
                    >
                    <mat-button-toggle value="Often true"
                      >Often true</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >In general, how would you describe your relationship with
                  your spouse/partner?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q30TwoMonths">
                    <mat-button-toggle value="No tension"
                      >No tension</mat-button-toggle
                    >
                    <mat-button-toggle value="Some tension"
                      >Some tension</mat-button-toggle
                    >
                    <mat-button-toggle value="A lot of tension"
                      >A lot of tension</mat-button-toggle
                    >
                    <mat-button-toggle value="Not applicable"
                      >Not applicable</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you and your partner work out arguments with:</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q31TwoMonths">
                    <mat-button-toggle value="No difficulty"
                      >No difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Some difficulty"
                      >Some difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Great difficulty"
                      >Great difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Not applicable"
                      >Not applicable</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >During the past week, how many days did you or other family
                  members read to your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q32TwoMonths">
                    <mat-button-toggle value="0">0</mat-button-toggle>
                    <mat-button-toggle value="1">1</mat-button-toggle>
                    <mat-button-toggle value="2">2</mat-button-toggle>
                    <mat-button-toggle value="3">3</mat-button-toggle>
                    <mat-button-toggle value="4">4</mat-button-toggle>
                    <mat-button-toggle value="5">5</mat-button-toggle>
                    <mat-button-toggle value="6">6</mat-button-toggle>
                    <mat-button-toggle value="7">7</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title>
                Emotional Changes With a New Baby**
              </mat-card-title>
              <mat-card-subtitle
                >Since you have a new baby in your family, we would like to know
                how you are feeling now. Please check the answer that comes
                closest to how you have felt IN THE PAST 7 DAYS, not just how
                you feel today.</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >I have been able to laugh and see the funny side of
                  things</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q33TwoMonths">
                    <mat-button-toggle value="As much as I always could"
                      >As much as I always could</mat-button-toggle
                    >
                    <mat-button-toggle value="Not quite so much now"
                      >Not quite so much now</mat-button-toggle
                    >
                    <mat-button-toggle value="Definitely not so much now"
                      >Definitely not so much now</mat-button-toggle
                    >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >I have looked forward with enjoyment to things</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q34TwoMonths">
                    <mat-button-toggle value="As much as I ever did"
                      >As much as I ever did</mat-button-toggle
                    >
                    <mat-button-toggle value="Rather less than I used to"
                      >Rather less than I used to</mat-button-toggle
                    >
                    <mat-button-toggle value="Definitely less than I used to"
                      >Definitely less than I used to</mat-button-toggle
                    >
                    <mat-button-toggle value="Hardly at all"
                      >Hardly at all</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >I have blamed myself unnecessarily when things went
                  wrong</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q35TwoMonths">
                    <mat-button-toggle value="Yes most of the time"
                      >Yes most of the time</mat-button-toggle
                    >
                    <mat-button-toggle value="Yes some of the time"
                      >Yes some of the time</mat-button-toggle
                    >
                    <mat-button-toggle value="Not very often"
                      >Not very often</mat-button-toggle
                    >
                    <mat-button-toggle value="No never"
                      >No never</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >I have been anxious or worried for no good reason</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q36TwoMonths">
                    <mat-button-toggle value="No not at all"
                      >No not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Hardly ever"
                      >Hardly ever</mat-button-toggle
                    >
                    <mat-button-toggle value="Yes sometimes"
                      >Yes sometimes</mat-button-toggle
                    >
                    <mat-button-toggle value="Yes very often"
                      >Yes very often</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >I have felt scared or panicky for no good reason</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q37TwoMonths">
                    <mat-button-toggle value="Yes quite a lot"
                      >Yes quite a lot</mat-button-toggle
                    >
                    <mat-button-toggle value="Yes sometimes"
                      >Yes sometimes</mat-button-toggle
                    >
                    <mat-button-toggle value="No not much"
                      >No not much</mat-button-toggle
                    >
                    <mat-button-toggle value="No not at all"
                      >No not at all</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Things have been getting on top of me</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q38TwoMonths"
                    vertical
                  >
                    <mat-button-toggle
                      value="Yes most of the time I haven't been able to cope at all"
                      >Yes most of the time I haven't been able to cope at
                      all</mat-button-toggle
                    >
                    <mat-button-toggle
                      value="Yes sometimes I haven't been coping as well as usual"
                      >Yes sometimes I haven't been coping as well as
                      usual</mat-button-toggle
                    >
                    <mat-button-toggle
                      value="No most of the time I have coped quite well"
                      >No most of the time I have coped quite
                      well</mat-button-toggle
                    >
                    <mat-button-toggle
                      value="No I have been coping as well as ever"
                      >No I have been coping as well as ever</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >I have been so unhappy that I have had difficulty
                  sleeping</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q39TwoMonths">
                    <mat-button-toggle value="Yes most of the time"
                      >Yes most of the time</mat-button-toggle
                    >
                    <mat-button-toggle value="Yes sometimes"
                      >Yes sometimes</mat-button-toggle
                    >
                    <mat-button-toggle value="Not very often"
                      >Not very often</mat-button-toggle
                    >
                    <mat-button-toggle value="No not at all"
                      >No not at all</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >I have felt sad or miserable</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q40TwoMonths">
                    <mat-button-toggle value="Yes most of the time"
                      >Yes most of the time</mat-button-toggle
                    >
                    <mat-button-toggle value="Yes quite often"
                      >Yes quite often</mat-button-toggle
                    >
                    <mat-button-toggle value="Not very often"
                      >Not very often</mat-button-toggle
                    >
                    <mat-button-toggle value="No not at all"
                      >No not at all</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >I have been so unhappy that I have been crying</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q41TwoMonths">
                    <mat-button-toggle value="Yes most of the time"
                      >Yes most of the time</mat-button-toggle
                    >
                    <mat-button-toggle value="Yes quite often"
                      >Yes quite often</mat-button-toggle
                    >
                    <mat-button-toggle value="Only occasionally"
                      >Only occasionally</mat-button-toggle
                    >
                    <mat-button-toggle value="No never"
                      >No never</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >The thought of harming myself has occurred to me</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q42TwoMonths">
                    <mat-button-toggle value="Yes quite often"
                      >Yes quite often</mat-button-toggle
                    >
                    <mat-button-toggle value="Sometimes"
                      >Sometimes</mat-button-toggle
                    >
                    <mat-button-toggle value="Hardly ever"
                      >Hardly ever</mat-button-toggle
                    >
                    <mat-button-toggle value="Never">Never</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </ng-container>
        <ng-container *ngIf="selectedAgeGroup === '4months'">
          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Developmental Milestones </mat-card-title>
              <mat-card-subtitle
                >Most children at this age will be able to do some (but not all)
                of the developmental tasks listed below. Please tell us how much
                your child is doing each of these things. PLEASE BE SURE TO
                ANSWER ALL THE QUESTIONS.</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Holds head steady when being pulled up to a sitting
                  position</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q1FourMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Brings hands together</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q2FourMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after">Laughs</label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q3FourMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Keeps head steady when held in a sitting position</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q4FourMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Makes sounds like "ga," "ma," or "ba"</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q5FourMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Looks when you call his or her name</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q6FourMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after">Rolls over</label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q7FourMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Passes a toy from one hand to the other</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q8FourMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Looks for you or another caregiver when upset</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q9FourMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Holds two objects and bangs them together</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q10FourMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title>
                Baby Pediatric Symptom Checklist (BPSC)
              </mat-card-title>
              <mat-card-subtitle
                >These questions are about your child's behavior. Think about
                what you would expect of other children the same age, and tell
                us how much each statement applies to your
                child.</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have a hard time being with new
                  people?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q11FourMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have a hard time in new places?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q12FourMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have a hard time with change?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q13FourMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child mind being held by other people?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q14FourMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child cry a lot?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q15FourMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have a hard time calming down?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q16FourMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is your child fussy or irritable?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q17FourMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is it hard to comfort your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q18FourMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is it hard to keep your child on a schedule or
                  routine?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q19FourMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is it hard to put your child to sleep?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q20FourMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is it hard to get enough sleep because of your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q21FourMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have trouble staying asleep?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q22FourMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Parent's Concerns </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you have any concerns about your child's learning or
                  development?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q23FourMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you have any concerns about your child's behavior?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q24FourMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Family Questions </mat-card-title>
              <mat-card-subtitle
                >Because family members can have a big impact on your child's
                development, please answer a few questions about your family
                below:</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does anyone who lives with your child smoke tobacco?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q25FourMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >In the last year, have you ever drunk alcohol or used drugs
                  more than you meant to?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q26FourMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have you felt you wanted or needed to cut down on your
                  drinking or drug use in the last year?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q27FourMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Has a family member's drinking or drug use ever had a bad
                  effect on your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q28FourMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Within the past 12 months, we worried whether our food would
                  run out before we got money to buy more</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q29FourMonths">
                    <mat-button-toggle value="Never true"
                      >Never true</mat-button-toggle
                    >
                    <mat-button-toggle value="Sometimes true"
                      >Sometimes true</mat-button-toggle
                    >
                    <mat-button-toggle value="Often true"
                      >Often true</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >In general, how would you describe your relationship with
                  your spouse/partner?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q30FourMonths">
                    <mat-button-toggle value="No tension"
                      >No tension</mat-button-toggle
                    >
                    <mat-button-toggle value="Some tension"
                      >Some tension</mat-button-toggle
                    >
                    <mat-button-toggle value="A lot of tension"
                      >A lot of tension</mat-button-toggle
                    >
                    <mat-button-toggle value="Not applicable"
                      >Not applicable</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you and your partner work out arguments with:</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q31FourMonths">
                    <mat-button-toggle value="No difficulty"
                      >No difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Some difficulty"
                      >Some difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Great difficulty"
                      >Great difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Not applicable"
                      >Not applicable</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >During the past week, how many days did you or other family
                  members read to your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q32FourMonths">
                    <mat-button-toggle value="0">0</mat-button-toggle>
                    <mat-button-toggle value="1">1</mat-button-toggle>
                    <mat-button-toggle value="2">2</mat-button-toggle>
                    <mat-button-toggle value="3">3</mat-button-toggle>
                    <mat-button-toggle value="4">4</mat-button-toggle>
                    <mat-button-toggle value="5">5</mat-button-toggle>
                    <mat-button-toggle value="6">6</mat-button-toggle>
                    <mat-button-toggle value="7">7</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title>
                Emotional Changes With a New Baby**
              </mat-card-title>
              <mat-card-subtitle
                >Since you have a new baby in your family, we would like to know
                how you are feeling now. Please check the answer that comes
                closest to how you have felt IN THE PAST 7 DAYS, not just how
                you feel today.</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >I have been able to laugh and see the funny side of
                  things</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q33FourMonths">
                    <mat-button-toggle value="As much as I always could"
                      >As much as I always could</mat-button-toggle
                    >
                    <mat-button-toggle value="Not quite so much now"
                      >Not quite so much now</mat-button-toggle
                    >
                    <mat-button-toggle value="Definitely not so much now"
                      >Definitely not so much now</mat-button-toggle
                    >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >I have looked forward with enjoyment to things</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q34FourMonths">
                    <mat-button-toggle value="As much as I ever did"
                      >As much as I ever did</mat-button-toggle
                    >
                    <mat-button-toggle value="Rather less than I used to"
                      >Rather less than I used to</mat-button-toggle
                    >
                    <mat-button-toggle value="Definitely less than I used to"
                      >Definitely less than I used to</mat-button-toggle
                    >
                    <mat-button-toggle value="Hardly at all"
                      >Hardly at all</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >I have blamed myself unnecessarily when things went
                  wrong</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q35FourMonths">
                    <mat-button-toggle value="Yes most of the time"
                      >Yes most of the time</mat-button-toggle
                    >
                    <mat-button-toggle value="Yes some of the time"
                      >Yes some of the time</mat-button-toggle
                    >
                    <mat-button-toggle value="Not very often"
                      >Not very often</mat-button-toggle
                    >
                    <mat-button-toggle value="No never"
                      >No never</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >I have been anxious or worried for no good reason</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q36FourMonths">
                    <mat-button-toggle value="No not at all"
                      >No not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Hardly ever"
                      >Hardly ever</mat-button-toggle
                    >
                    <mat-button-toggle value="Yes sometimes"
                      >Yes sometimes</mat-button-toggle
                    >
                    <mat-button-toggle value="Yes very often"
                      >Yes very often</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >I have felt scared or panicky for no good reason</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q37FourMonths">
                    <mat-button-toggle value="Yes quite a lot"
                      >Yes quite a lot</mat-button-toggle
                    >
                    <mat-button-toggle value="Yes sometimes"
                      >Yes sometimes</mat-button-toggle
                    >
                    <mat-button-toggle value="No not much"
                      >No not much</mat-button-toggle
                    >
                    <mat-button-toggle value="No not at all"
                      >No not at all</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Things have been getting on top of me</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q38FourMonths"
                    vertical
                  >
                    <mat-button-toggle
                      value="Yes most of the time I haven't been able to cope at all"
                      >Yes most of the time I haven't been able to cope at
                      all</mat-button-toggle
                    >
                    <mat-button-toggle
                      value="Yes sometimes I haven't been coping as well as usual"
                      >Yes sometimes I haven't been coping as well as
                      usual</mat-button-toggle
                    >
                    <mat-button-toggle
                      value="No most of the time I have coped quite well"
                      >No most of the time I have coped quite
                      well</mat-button-toggle
                    >
                    <mat-button-toggle
                      value="No I have been coping as well as ever"
                      >No I have been coping as well as ever</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >I have been so unhappy that I have had difficulty
                  sleeping</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q39FourMonths">
                    <mat-button-toggle value="Yes most of the time"
                      >Yes most of the time</mat-button-toggle
                    >
                    <mat-button-toggle value="Yes sometimes"
                      >Yes sometimes</mat-button-toggle
                    >
                    <mat-button-toggle value="Not very often"
                      >Not very often</mat-button-toggle
                    >
                    <mat-button-toggle value="No not at all"
                      >No not at all</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >I have felt sad or miserable</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q40FourMonths">
                    <mat-button-toggle value="Yes most of the time"
                      >Yes most of the time</mat-button-toggle
                    >
                    <mat-button-toggle value="Yes quite often"
                      >Yes quite often</mat-button-toggle
                    >
                    <mat-button-toggle value="Not very often"
                      >Not very often</mat-button-toggle
                    >
                    <mat-button-toggle value="No not at all"
                      >No not at all</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >I have been so unhappy that I have been crying</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q41FourMonths">
                    <mat-button-toggle value="Yes most of the time"
                      >Yes most of the time</mat-button-toggle
                    >
                    <mat-button-toggle value="Yes quite often"
                      >Yes quite often</mat-button-toggle
                    >
                    <mat-button-toggle value="Only occasionally"
                      >Only occasionally</mat-button-toggle
                    >
                    <mat-button-toggle value="No never"
                      >No never</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >The thought of harming myself has occurred to me</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q42FourMonths">
                    <mat-button-toggle value="Yes quite often"
                      >Yes quite often</mat-button-toggle
                    >
                    <mat-button-toggle value="Sometimes"
                      >Sometimes</mat-button-toggle
                    >
                    <mat-button-toggle value="Hardly ever"
                      >Hardly ever</mat-button-toggle
                    >
                    <mat-button-toggle value="Never">Never</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </ng-container>
        <ng-container *ngIf="selectedAgeGroup === '6months'">
          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Developmental Milestones </mat-card-title>
              <mat-card-subtitle
                >Most children at this age will be able to do some (but not all)
                of the developmental tasks listed below. Please tell us how much
                your child is doing each of these things. PLEASE BE SURE TO
                ANSWER ALL THE QUESTIONS.</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Makes sounds like "ga", "ma" or "ba"</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q1SixMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Looks when you call his or her name</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q2SixMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after">Rolls over</label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q3SixMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Passes a toy from one hand to the other</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q4SixMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Looks for you or another caregiver when upset</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q5SixMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Holds two objects and bangs them together</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q6SixMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Holds up arms to be picked up</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q7SixMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Gets into a sitting position by him or herself</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q8SixMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Picks up food and eats it</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q9SixMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Pulls up to standing</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q10SixMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title>
                Baby Pediatric Symptom Checklist (BPSC)
              </mat-card-title>
              <mat-card-subtitle
                >These questions are about your child's behavior. Think about
                what you would expect of other children the same age, and tell
                us how much each statement applies to your
                child.</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have a hard time being with new
                  people?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q11SixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have a hard time in new places?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q12SixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have a hard time with change?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q13SixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child mind being held by other people?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q14SixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child cry a lot?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q15SixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have a hard time calming down?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q16SixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is your child fussy or irritable?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q17SixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is it hard to comfort your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q18SixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is it hard to keep your child on a schedule or
                  routine?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q19SixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is it hard to put your child to sleep?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q20SixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is it hard to get enough sleep because of your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q21SixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have trouble staying asleep?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q22SixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Parent's Concerns </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you have any concerns about your child's learning or
                  development?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q23SixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you have any concerns about your child's behavior?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q24SixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Family Questions </mat-card-title>
              <mat-card-subtitle
                >Because family members can have a big impact on your child's
                development, please answer a few questions about your family
                below:</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does anyone who lives with your child smoke tobacco?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q25SixMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >In the last year, have you ever drunk alcohol or used drugs
                  more than you meant to?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q26SixMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have you felt you wanted or needed to cut down on your
                  drinking or drug use in the last year?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q27SixMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Has a family member's drinking or drug use ever had a bad
                  effect on your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q28SixMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Within the past 12 months, we worried whether our food would
                  run out before we got money to buy more</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q29SixMonths">
                    <mat-button-toggle value="Never true"
                      >Never true</mat-button-toggle
                    >
                    <mat-button-toggle value="Sometimes true"
                      >Sometimes true</mat-button-toggle
                    >
                    <mat-button-toggle value="Often true"
                      >Often true</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >In general, how would you describe your relationship with
                  your spouse/partner?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q30SixMonths">
                    <mat-button-toggle value="No tension"
                      >No tension</mat-button-toggle
                    >
                    <mat-button-toggle value="Some tension"
                      >Some tension</mat-button-toggle
                    >
                    <mat-button-toggle value="A lot of tension"
                      >A lot of tension</mat-button-toggle
                    >
                    <mat-button-toggle value="Not applicable"
                      >Not applicable</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you and your partner work out arguments with:</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q31SixMonths">
                    <mat-button-toggle value="No difficulty"
                      >No difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Some difficulty"
                      >Some difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Great difficulty"
                      >Great difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Not applicable"
                      >Not applicable</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >During the past week, how many days did you or other family
                  members read to your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q32SixMonths">
                    <mat-button-toggle value="0">0</mat-button-toggle>
                    <mat-button-toggle value="1">1</mat-button-toggle>
                    <mat-button-toggle value="2">2</mat-button-toggle>
                    <mat-button-toggle value="3">3</mat-button-toggle>
                    <mat-button-toggle value="4">4</mat-button-toggle>
                    <mat-button-toggle value="5">5</mat-button-toggle>
                    <mat-button-toggle value="6">6</mat-button-toggle>
                    <mat-button-toggle value="7">7</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title>
                Emotional Changes With a New Baby**
              </mat-card-title>
              <mat-card-subtitle
                >Since you have a new baby in your family, we would like to know
                how you are feeling now. Please check the answer that comes
                closest to how you have felt IN THE PAST 7 DAYS, not just how
                you feel today.</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >I have been able to laugh and see the funny side of
                  things</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q33SixMonths">
                    <mat-button-toggle value="As much as I always could"
                      >As much as I always could</mat-button-toggle
                    >
                    <mat-button-toggle value="Not quite so much now"
                      >Not quite so much now</mat-button-toggle
                    >
                    <mat-button-toggle value="Definitely not so much now"
                      >Definitely not so much now</mat-button-toggle
                    >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >I have looked forward with enjoyment to things</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q34SixMonths">
                    <mat-button-toggle value="As much as I ever did"
                      >As much as I ever did</mat-button-toggle
                    >
                    <mat-button-toggle value="Rather less than I used to"
                      >Rather less than I used to</mat-button-toggle
                    >
                    <mat-button-toggle value="Definitely less than I used to"
                      >Definitely less than I used to</mat-button-toggle
                    >
                    <mat-button-toggle value="Hardly at all"
                      >Hardly at all</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >I have blamed myself unnecessarily when things went
                  wrong</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q35SixMonths">
                    <mat-button-toggle value="Yes most of the time"
                      >Yes most of the time</mat-button-toggle
                    >
                    <mat-button-toggle value="Yes some of the time"
                      >Yes some of the time</mat-button-toggle
                    >
                    <mat-button-toggle value="Not very often"
                      >Not very often</mat-button-toggle
                    >
                    <mat-button-toggle value="No never"
                      >No never</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >I have been anxious or worried for no good reason</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q36SixMonths">
                    <mat-button-toggle value="No not at all"
                      >No not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Hardly ever"
                      >Hardly ever</mat-button-toggle
                    >
                    <mat-button-toggle value="Yes sometimes"
                      >Yes sometimes</mat-button-toggle
                    >
                    <mat-button-toggle value="Yes very often"
                      >Yes very often</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >I have felt scared or panicky for no good reason</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q37SixMonths">
                    <mat-button-toggle value="Yes quite a lot"
                      >Yes quite a lot</mat-button-toggle
                    >
                    <mat-button-toggle value="Yes sometimes"
                      >Yes sometimes</mat-button-toggle
                    >
                    <mat-button-toggle value="No not much"
                      >No not much</mat-button-toggle
                    >
                    <mat-button-toggle value="No not at all"
                      >No not at all</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Things have been getting on top of me</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q38SixMonths"
                    vertical
                  >
                    <mat-button-toggle
                      value="Yes most of the time I haven't been able to cope at all"
                      >Yes most of the time I haven't been able to cope at
                      all</mat-button-toggle
                    >
                    <mat-button-toggle
                      value="Yes sometimes I haven't been coping as well as usual"
                      >Yes sometimes I haven't been coping as well as
                      usual</mat-button-toggle
                    >
                    <mat-button-toggle
                      value="No most of the time I have coped quite well"
                      >No most of the time I have coped quite
                      well</mat-button-toggle
                    >
                    <mat-button-toggle
                      value="No I have been coping as well as ever"
                      >No I have been coping as well as ever</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >I have been so unhappy that I have had difficulty
                  sleeping</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q39SixMonths">
                    <mat-button-toggle value="Yes most of the time"
                      >Yes most of the time</mat-button-toggle
                    >
                    <mat-button-toggle value="Yes sometimes"
                      >Yes sometimes</mat-button-toggle
                    >
                    <mat-button-toggle value="Not very often"
                      >Not very often</mat-button-toggle
                    >
                    <mat-button-toggle value="No not at all"
                      >No not at all</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >I have felt sad or miserable</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q40SixMonths">
                    <mat-button-toggle value="Yes most of the time"
                      >Yes most of the time</mat-button-toggle
                    >
                    <mat-button-toggle value="Yes quite often"
                      >Yes quite often</mat-button-toggle
                    >
                    <mat-button-toggle value="Not very often"
                      >Not very often</mat-button-toggle
                    >
                    <mat-button-toggle value="No not at all"
                      >No not at all</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >I have been so unhappy that I have been crying</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q41SixMonths">
                    <mat-button-toggle value="Yes most of the time"
                      >Yes most of the time</mat-button-toggle
                    >
                    <mat-button-toggle value="Yes quite often"
                      >Yes quite often</mat-button-toggle
                    >
                    <mat-button-toggle value="Only occasionally"
                      >Only occasionally</mat-button-toggle
                    >
                    <mat-button-toggle value="No never"
                      >No never</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >The thought of harming myself has occurred to me</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q42SixMonths">
                    <mat-button-toggle value="Yes quite often"
                      >Yes quite often</mat-button-toggle
                    >
                    <mat-button-toggle value="Sometimes"
                      >Sometimes</mat-button-toggle
                    >
                    <mat-button-toggle value="Hardly ever"
                      >Hardly ever</mat-button-toggle
                    >
                    <mat-button-toggle value="Never">Never</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </ng-container>
        <ng-container *ngIf="selectedAgeGroup === '9months'">
          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Developmental Milestones </mat-card-title>
              <mat-card-subtitle
                >Most children at this age will be able to do some (but not all)
                of the developmental tasks listed below. Please tell us how much
                your child is doing each of these things. PLEASE BE SURE TO
                ANSWER ALL THE QUESTIONS.</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Holds up arms to be picked up</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q1NineMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Gets into a sitting position by him or herself</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q2NineMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Picks up food and eats it</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q3NineMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Pulls up to standing</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q4NineMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Plays games like "peek-a-boo" or "pat-a-cake"</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q5NineMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Calls you "mama" or "dada" or similar name
                </label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q6NineMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Looks around when you say things like "Where's your bottle?"
                  or "Where's your blanket?"
                </label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q7NineMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Copies sounds that you make</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q8NineMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Walks across a room without help</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q9NineMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Follows directions - like "Come here" or "Give me the ball"
                </label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q10NineMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title>
                Baby Pediatric Symptom Checklist (BPSC)
              </mat-card-title>
              <mat-card-subtitle
                >These questions are about your child's behavior. Think about
                what you would expect of other children the same age, and tell
                us how much each statement applies to your
                child.</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have a hard time being with new
                  people?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q11NineMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have a hard time in new places?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q12NineMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have a hard time with change?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q13NineMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child mind being held by other people?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q14NineMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child cry a lot?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q15NineMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have a hard time calming down?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q16NineMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is your child fussy or irritable?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q17NineMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is it hard to comfort your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q18NineMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is it hard to keep your child on a schedule or
                  routine?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q19NineMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is it hard to put your child to sleep?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q20NineMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is it hard to get enough sleep because of your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q21NineMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have trouble staying asleep?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q22NineMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Parent's Concerns </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you have any concerns about your child's learning or
                  development?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q23NineMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you have any concerns about your child's behavior?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q24NineMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Family Questions </mat-card-title>
              <mat-card-subtitle
                >Because family members can have a big impact on your child's
                development, please answer a few questions about your family
                below:</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does anyone who lives with your child smoke tobacco?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q25NineMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >In the last year, have you ever drunk alcohol or used drugs
                  more than you meant to?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q26NineMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have you felt you wanted or needed to cut down on your
                  drinking or drug use in the last year?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q27NineMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Has a family member's drinking or drug use ever had a bad
                  effect on your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q28NineMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Within the past 12 months, we worried whether our food would
                  run out before we got money to buy more</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q29NineMonths">
                    <mat-button-toggle value="Never true"
                      >Never true</mat-button-toggle
                    >
                    <mat-button-toggle value="Sometimes true"
                      >Sometimes true</mat-button-toggle
                    >
                    <mat-button-toggle value="Often true"
                      >Often true</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Having little interest or pleasure in doing things?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q30NineMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Several days"
                      >Several days</mat-button-toggle
                    >
                    <mat-button-toggle value="More than half the days"
                      >More than half the days</mat-button-toggle
                    >
                    <mat-button-toggle value="Nearly every day"
                      >Nearly every day</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Feeling down, depressed, or hopeless?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q31NineMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Several days"
                      >Several days</mat-button-toggle
                    >
                    <mat-button-toggle value="More than half the days"
                      >More than half the days</mat-button-toggle
                    >
                    <mat-button-toggle value="Nearly every day"
                      >Nearly every day</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >In general, how would you describe your relationship with
                  your spouse/partner?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q32NineMonths">
                    <mat-button-toggle value="No tension"
                      >No tension</mat-button-toggle
                    >
                    <mat-button-toggle value="Some tension"
                      >Some tension</mat-button-toggle
                    >
                    <mat-button-toggle value="A lot of tension"
                      >A lot of tension</mat-button-toggle
                    >
                    <mat-button-toggle value="Not applicable"
                      >Not applicable</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you and your partner work out arguments with:</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q33NineMonths">
                    <mat-button-toggle value="No difficulty"
                      >No difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Some difficulty"
                      >Some difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Great difficulty"
                      >Great difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Not applicable"
                      >Not applicable</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >During the past week, how many days did you or other family
                  members read to your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q34NineMonths">
                    <mat-button-toggle value="0">0</mat-button-toggle>
                    <mat-button-toggle value="1">1</mat-button-toggle>
                    <mat-button-toggle value="2">2</mat-button-toggle>
                    <mat-button-toggle value="3">3</mat-button-toggle>
                    <mat-button-toggle value="4">4</mat-button-toggle>
                    <mat-button-toggle value="5">5</mat-button-toggle>
                    <mat-button-toggle value="6">6</mat-button-toggle>
                    <mat-button-toggle value="7">7</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </ng-container>
        <ng-container *ngIf="selectedAgeGroup === '12months'">
          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Developmental Milestones </mat-card-title>
              <mat-card-subtitle
                >Most children at this age will be able to do some (but not all)
                of the developmental tasks listed below. Please tell us how much
                your child is doing each of these things. PLEASE BE SURE TO
                ANSWER ALL THE QUESTIONS.</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Picks up food and eats it</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q1TwelveMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Pulls up to standing</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q2TwelveMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Plays games like "peek-a-boo" or "pat-a-cake"</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q3TwelveMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Calls you "mama" or "dada" or similar name
                </label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q4TwelveMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Looks around when you say things like "Where's your bottle?"
                  or "Where's your blanket?"
                </label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q5TwelveMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Copies sounds that you make</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q6TwelveMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Walks across a room without help</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q7TwelveMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Follows directions - like "Come here" or "Give me the ball"
                </label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q8TwelveMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after">Runs</label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q9TwelveMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Walks up stairs with help</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q10TwelveMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title>
                Baby Pediatric Symptom Checklist (BPSC)
              </mat-card-title>
              <mat-card-subtitle
                >These questions are about your child's behavior. Think about
                what you would expect of other children the same age, and tell
                us how much each statement applies to your
                child.</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have a hard time being with new
                  people?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q11TwelveMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have a hard time in new places?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q12TwelveMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have a hard time with change?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q13TwelveMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child mind being held by other people?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q14TwelveMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child cry a lot?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q15TwelveMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have a hard time calming down?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q16TwelveMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is your child fussy or irritable?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q17TwelveMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is it hard to comfort your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q18TwelveMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is it hard to keep your child on a schedule or
                  routine?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q19TwelveMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is it hard to put your child to sleep?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q20TwelveMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is it hard to get enough sleep because of your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q21TwelveMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have trouble staying asleep?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q22TwelveMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Parent's Concerns </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you have any concerns about your child's learning or
                  development?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q23TwelveMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you have any concerns about your child's behavior?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q24TwelveMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Family Questions </mat-card-title>
              <mat-card-subtitle
                >Because family members can have a big impact on your child's
                development, please answer a few questions about your family
                below:</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does anyone who lives with your child smoke tobacco?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q25TwelveMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >In the last year, have you ever drunk alcohol or used drugs
                  more than you meant to?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q26TwelveMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have you felt you wanted or needed to cut down on your
                  drinking or drug use in the last year?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q27TwelveMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Has a family member's drinking or drug use ever had a bad
                  effect on your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q28TwelveMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Within the past 12 months, we worried whether our food would
                  run out before we got money to buy more</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q29TwelveMonths">
                    <mat-button-toggle value="Never true"
                      >Never true</mat-button-toggle
                    >
                    <mat-button-toggle value="Sometimes true"
                      >Sometimes true</mat-button-toggle
                    >
                    <mat-button-toggle value="Often true"
                      >Often true</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Having little interest or pleasure in doing things?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q30TwelveMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Several days"
                      >Several days</mat-button-toggle
                    >
                    <mat-button-toggle value="More than half the days"
                      >More than half the days</mat-button-toggle
                    >
                    <mat-button-toggle value="Nearly every day"
                      >Nearly every day</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Feeling down, depressed, or hopeless?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q31TwelveMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Several days"
                      >Several days</mat-button-toggle
                    >
                    <mat-button-toggle value="More than half the days"
                      >More than half the days</mat-button-toggle
                    >
                    <mat-button-toggle value="Nearly every day"
                      >Nearly every day</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >In general, how would you describe your relationship with
                  your spouse/partner?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q32TwelveMonths">
                    <mat-button-toggle value="No tension"
                      >No tension</mat-button-toggle
                    >
                    <mat-button-toggle value="Some tension"
                      >Some tension</mat-button-toggle
                    >
                    <mat-button-toggle value="A lot of tension"
                      >A lot of tension</mat-button-toggle
                    >
                    <mat-button-toggle value="Not applicable"
                      >Not applicable</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you and your partner work out arguments with:</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q33TwelveMonths">
                    <mat-button-toggle value="No difficulty"
                      >No difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Some difficulty"
                      >Some difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Great difficulty"
                      >Great difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Not applicable"
                      >Not applicable</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >During the past week, how many days did you or other family
                  members read to your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q34TwelveMonths">
                    <mat-button-toggle value="0">0</mat-button-toggle>
                    <mat-button-toggle value="1">1</mat-button-toggle>
                    <mat-button-toggle value="2">2</mat-button-toggle>
                    <mat-button-toggle value="3">3</mat-button-toggle>
                    <mat-button-toggle value="4">4</mat-button-toggle>
                    <mat-button-toggle value="5">5</mat-button-toggle>
                    <mat-button-toggle value="6">6</mat-button-toggle>
                    <mat-button-toggle value="7">7</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </ng-container>
        <ng-container *ngIf="selectedAgeGroup === '15months'">
          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Developmental Milestones </mat-card-title>
              <mat-card-subtitle
                >Most children at this age will be able to do some (but not all)
                of the developmental tasks listed below. Please tell us how much
                your child is doing each of these things. PLEASE BE SURE TO
                ANSWER ALL THE QUESTIONS.</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Calls you "mama" or "dada" or similar name
                </label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q1FifteenMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Looks around when you say things like "Where's your bottle?"
                  or "Where's your blanket?"
                </label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q2FifteenMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Copies sounds that you make</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q3FifteenMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Walks across a room without help</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q4FifteenMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Follows directions - like "Come here" or "Give me the ball"
                </label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q5FifteenMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after">Runs</label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q6FifteenMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Walks up stairs with help</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q7FifteenMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after">Kicks a ball</label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q8FifteenMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Names at least - familiar obMects - like ball or milk</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q9FifteenMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Names at least - body parts - like nose, hand, or tummy...
                </label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q10FifteenMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title>
                Baby Pediatric Symptom Checklist (BPSC)
              </mat-card-title>
              <mat-card-subtitle
                >These questions are about your child's behavior. Think about
                what you would expect of other children the same age, and tell
                us how much each statement applies to your
                child.</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have a hard time being with new
                  people?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q11FifteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have a hard time in new places?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q12FifteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have a hard time with change?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q13FifteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child mind being held by other people?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q14FifteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child cry a lot?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q15FifteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have a hard time calming down?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q16FifteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is your child fussy or irritable?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q17FifteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is it hard to comfort your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q18FifteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is it hard to keep your child on a schedule or
                  routine?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q19FifteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is it hard to put your child to sleep?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q20FifteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is it hard to get enough sleep because of your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q21FifteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child have trouble staying asleep?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q22FifteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Parent's Concerns </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you have any concerns about your child's learning or
                  development?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q23FifteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you have any concerns about your child's behavior?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q24FifteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Family Questions </mat-card-title>
              <mat-card-subtitle
                >Because family members can have a big impact on your child's
                development, please answer a few questions about your family
                below:</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does anyone who lives with your child smoke tobacco?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q25FifteenMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >In the last year, have you ever drunk alcohol or used drugs
                  more than you meant to?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q26FifteenMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have you felt you wanted or needed to cut down on your
                  drinking or drug use in the last year?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q27FifteenMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Has a family member's drinking or drug use ever had a bad
                  effect on your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q28FifteenMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Within the past 12 months, we worried whether our food would
                  run out before we got money to buy more</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q29FifteenMonths">
                    <mat-button-toggle value="Never true"
                      >Never true</mat-button-toggle
                    >
                    <mat-button-toggle value="Sometimes true"
                      >Sometimes true</mat-button-toggle
                    >
                    <mat-button-toggle value="Often true"
                      >Often true</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Having little interest or pleasure in doing things?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q30FifteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Several days"
                      >Several days</mat-button-toggle
                    >
                    <mat-button-toggle value="More than half the days"
                      >More than half the days</mat-button-toggle
                    >
                    <mat-button-toggle value="Nearly every day"
                      >Nearly every day</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Feeling down, depressed, or hopeless?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q31FifteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Several days"
                      >Several days</mat-button-toggle
                    >
                    <mat-button-toggle value="More than half the days"
                      >More than half the days</mat-button-toggle
                    >
                    <mat-button-toggle value="Nearly every day"
                      >Nearly every day</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >In general, how would you describe your relationship with
                  your spouse/partner?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q32FifteenMonths">
                    <mat-button-toggle value="No tension"
                      >No tension</mat-button-toggle
                    >
                    <mat-button-toggle value="Some tension"
                      >Some tension</mat-button-toggle
                    >
                    <mat-button-toggle value="A lot of tension"
                      >A lot of tension</mat-button-toggle
                    >
                    <mat-button-toggle value="Not applicable"
                      >Not applicable</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you and your partner work out arguments with:</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q33FifteenMonths">
                    <mat-button-toggle value="No difficulty"
                      >No difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Some difficulty"
                      >Some difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Great difficulty"
                      >Great difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Not applicable"
                      >Not applicable</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >During the past week, how many days did you or other family
                  members read to your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q34FifteenMonths">
                    <mat-button-toggle value="0">0</mat-button-toggle>
                    <mat-button-toggle value="1">1</mat-button-toggle>
                    <mat-button-toggle value="2">2</mat-button-toggle>
                    <mat-button-toggle value="3">3</mat-button-toggle>
                    <mat-button-toggle value="4">4</mat-button-toggle>
                    <mat-button-toggle value="5">5</mat-button-toggle>
                    <mat-button-toggle value="6">6</mat-button-toggle>
                    <mat-button-toggle value="7">7</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </ng-container>
        <ng-container *ngIf="selectedAgeGroup === '18months'">
          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Developmental Milestones </mat-card-title>
              <mat-card-subtitle
                >Most children at this age will be able to do some (but not all)
                of the developmental tasks listed below. Please tell us how much
                your child is doing each of these things. PLEASE BE SURE TO
                ANSWER ALL THE QUESTIONS.</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after">Runs</label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q1EighteenMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Walks up stairs with help</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q2EighteenMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after">Kicks a ball</label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q3EighteenMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Names at least - familiar objects - like ball or milk</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q4EighteenMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Names at least 5 body parts - like nose, hand, or tummy ∙∙∙∙∙
                </label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q5EighteenMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Climbs up a ladder at a playground</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q6EighteenMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Uses words like "me" or "mine"</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q7EighteenMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Jumps off the ground with two feet</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q8EighteenMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Puts 2 or more words together - like "more water" or "go
                  outside"</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q9EighteenMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Uses words to ask for help</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q10EighteenMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title>
                Preschool Pediatric Symptom Checklist (PPSC)
              </mat-card-title>
              <mat-card-subtitle
                >These questions are about your child's behavior. Think about
                what you would expect of other children the same age, and tell
                us how much each statement applies to your
                child.</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Seem nervous or afraid?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q11EighteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Seem sad or unhappy?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q12EighteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Get upset if things are not done in a certain way?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q13EighteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have a hard time with change?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q14EighteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have trouble playing with other children?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q15EighteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Break things on purpose?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q16EighteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Fight with other children?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q17EighteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have trouble paying attention?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q18EighteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have a hard time calming down?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q19EighteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after">Aggressive?</label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q20EighteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Fidgety or unable to sit still?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q21EighteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after">Angry?</label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q22EighteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Take your child out in public?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q23EighteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Comfort your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q24EighteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Know what your child needs?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q25EighteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Keep your child on a schedule or routine?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q26EighteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Get your child to obey you?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q27EighteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title>
                Parent's Observations of Social Interactions (POSI)
              </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child bring things to you to show them to
                  you?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q28EighteenMonths">
                    <mat-button-toggle value="Many times a day"
                      >Many times a day</mat-button-toggle
                    >
                    <mat-button-toggle value="A few times a day"
                      >A few times a day</mat-button-toggle
                    >
                    <mat-button-toggle value="A few times a week"
                      >A few times a week</mat-button-toggle
                    >
                    <mat-button-toggle value="Less than once a week"
                      >Less than once a week</mat-button-toggle
                    >
                    <mat-button-toggle value="Never">Never</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is your child interested in playing with other
                  children?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q29EighteenMonths">
                    <mat-button-toggle value="Always">Always</mat-button-toggle>
                    <mat-button-toggle value="Usually"
                      >Usually</mat-button-toggle
                    >
                    <mat-button-toggle value="Sometimes"
                      >Sometimes</mat-button-toggle
                    >
                    <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
                    <mat-button-toggle value="Never">Never</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >When you say a word or wave your hand, will your child try to
                  copy you?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q30EighteenMonths">
                    <mat-button-toggle value="Always">Always</mat-button-toggle>
                    <mat-button-toggle value="Usually"
                      >Usually</mat-button-toggle
                    >
                    <mat-button-toggle value="Sometimes"
                      >Sometimes</mat-button-toggle
                    >
                    <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
                    <mat-button-toggle value="Never">Never</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child look at you when you call his or her
                  name?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q31EighteenMonths">
                    <mat-button-toggle value="Always">Always</mat-button-toggle>
                    <mat-button-toggle value="Usually"
                      >Usually</mat-button-toggle
                    >
                    <mat-button-toggle value="Sometimes"
                      >Sometimes</mat-button-toggle
                    >
                    <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
                    <mat-button-toggle value="Never">Never</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child look if you point to something across the
                  room?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q32EighteenMonths">
                    <mat-button-toggle value="Always">Always</mat-button-toggle>
                    <mat-button-toggle value="Usually"
                      >Usually</mat-button-toggle
                    >
                    <mat-button-toggle value="Sometimes"
                      >Sometimes</mat-button-toggle
                    >
                    <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
                    <mat-button-toggle value="Never">Never</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >How does your child usually show you something he or she
                  wants?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q33EighteenMonths">
                    <mat-button-toggle
                      value="Says a word for what he or she wants"
                      >Says a word for what he or she wants</mat-button-toggle
                    >
                    <mat-button-toggle value="Points to it with one finger"
                      >Points to it with one finger</mat-button-toggle
                    >
                    <mat-button-toggle value="Reaches for it"
                      >Reaches for it</mat-button-toggle
                    >
                    <mat-button-toggle
                      value="Pulls me over or puts my hand on it"
                      >Pulls me over or puts my hand on it</mat-button-toggle
                    >
                    <mat-button-toggle value="Grunts cries or screams"
                      >Grunts cries or screams</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >What are your child's favorite play activities?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q34EighteenMonths">
                    <mat-button-toggle
                      value="Playing with dolls or stuffed animals"
                      >Playing with dolls or stuffed animals</mat-button-toggle
                    >
                    <mat-button-toggle value="Reading books with you"
                      >Reading books with you</mat-button-toggle
                    >
                    <mat-button-toggle value="Climbing running and being active"
                      >Climbing running and being active</mat-button-toggle
                    >
                    <mat-button-toggle value="Lining up toys or other things"
                      >Lining up toys or other things</mat-button-toggle
                    >
                    <mat-button-toggle
                      value="Watching things go round and round like fans or wheels"
                      >Watching things go round and round like fans or
                      wheels</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Parent's Concerns </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you have any concerns about your child's learning or
                  development?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q35EighteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you have any concerns about your child's behavior?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q36EighteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Family Questions </mat-card-title>
              <mat-card-subtitle
                >Because family members can have a big impact on your child's
                development, please answer a few questions about your family
                below:</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does anyone who lives with your child smoke tobacco?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q37EighteenMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >In the last year, have you ever drunk alcohol or used drugs
                  more than you meant to?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q38EighteenMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have you felt you wanted or needed to cut down on your
                  drinking or drug use in the last year?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q39EighteenMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Has a family member's drinking or drug use ever had a bad
                  effect on your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q40EighteenMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Within the past 12 months, we worried whether our food would
                  run out before we got money to buy more</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q41EighteenMonths">
                    <mat-button-toggle value="Never true"
                      >Never true</mat-button-toggle
                    >
                    <mat-button-toggle value="Sometimes true"
                      >Sometimes true</mat-button-toggle
                    >
                    <mat-button-toggle value="Often true"
                      >Often true</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Having little interest or pleasure in doing things?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q42EighteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Several days"
                      >Several days</mat-button-toggle
                    >
                    <mat-button-toggle value="More than half the days"
                      >More than half the days</mat-button-toggle
                    >
                    <mat-button-toggle value="Nearly every day"
                      >Nearly every day</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Feeling down, depressed, or hopeless?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q43EighteenMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Several days"
                      >Several days</mat-button-toggle
                    >
                    <mat-button-toggle value="More than half the days"
                      >More than half the days</mat-button-toggle
                    >
                    <mat-button-toggle value="Nearly every day"
                      >Nearly every day</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >In general, how would you describe your relationship with
                  your spouse/partner?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q44EighteenMonths">
                    <mat-button-toggle value="No tension"
                      >No tension</mat-button-toggle
                    >
                    <mat-button-toggle value="Some tension"
                      >Some tension</mat-button-toggle
                    >
                    <mat-button-toggle value="A lot of tension"
                      >A lot of tension</mat-button-toggle
                    >
                    <mat-button-toggle value="Not applicable"
                      >Not applicable</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you and your partner work out arguments with:</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q45EighteenMonths">
                    <mat-button-toggle value="No difficulty"
                      >No difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Some difficulty"
                      >Some difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Great difficulty"
                      >Great difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Not applicable"
                      >Not applicable</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >During the past week, how many days did you or other family
                  members read to your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q46EighteenMonths">
                    <mat-button-toggle value="0">0</mat-button-toggle>
                    <mat-button-toggle value="1">1</mat-button-toggle>
                    <mat-button-toggle value="2">2</mat-button-toggle>
                    <mat-button-toggle value="3">3</mat-button-toggle>
                    <mat-button-toggle value="4">4</mat-button-toggle>
                    <mat-button-toggle value="5">5</mat-button-toggle>
                    <mat-button-toggle value="6">6</mat-button-toggle>
                    <mat-button-toggle value="7">7</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </ng-container>
        <ng-container *ngIf="selectedAgeGroup === '24months'">
          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Developmental Milestones </mat-card-title>
              <mat-card-subtitle
                >Most children at this age will be able to do some (but not all)
                of the developmental tasks listed below. Please tell us how much
                your child is doing each of these things. PLEASE BE SURE TO
                ANSWER ALL THE QUESTIONS.</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Names at least 5 body parts - like nose, hand, or
                  tummy...</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q1TwentyFourMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Climbs up a ladder at a playground</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q2TwentyFourMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Uses words like "me" or "mine"</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q3TwentyFourMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Jumps off the ground with two feet</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q4TwentyFourMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Puts 2 or more words together - like "more water" or "go
                  outside"</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q5TwentyFourMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Uses words to ask for help</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q6TwentyFourMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Names at least one color</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q7TwentyFourMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Tries to get you to watch by saying "Look at me"</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q8TwentyFourMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Says his or her first name when asked
                </label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q9TwentyFourMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after">Draws lines</label>
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q10TwentyFourMonths"
                  >
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title>
                Preschool Pediatric Symptom Checklist (PPSC)
              </mat-card-title>
              <mat-card-subtitle
                >These questions are about your child's behavior. Think about
                what you would expect of other children the same age, and tell
                us how much each statement applies to your
                child.</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Seem nervous or afraid?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q11TwentyFourMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Seem sad or unhappy?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q12TwentyFourMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Get upset if things are not done in a certain way?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q13TwentyFourMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have a hard time with change?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q14TwentyFourMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have trouble playing with other children?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q15TwentyFourMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Break things on purpose?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q16TwentyFourMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Fight with other children?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q17TwentyFourMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have trouble paying attention?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q18TwentyFourMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have a hard time calming down?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q19TwentyFourMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after">Aggressive?</label>
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q20TwentyFourMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Fidgety or unable to sit still?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q21TwentyFourMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after">Angry?</label>
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q22TwentyFourMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Take your child out in public?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q23TwentyFourMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Comfort your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q24TwentyFourMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Know what your child needs?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q25TwentyFourMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Keep your child on a schedule or routine?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q26TwentyFourMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Get your child to obey you?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q27TwentyFourMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title>
                Parent's Observations of Social Interactions (POSI)
              </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child bring things to you to show them to
                  you?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q28TwentyFourMonths"
                  >
                    <mat-button-toggle value="Many times a day"
                      >Many times a day</mat-button-toggle
                    >
                    <mat-button-toggle value="A few times a day"
                      >A few times a day</mat-button-toggle
                    >
                    <mat-button-toggle value="A few times a week"
                      >A few times a week</mat-button-toggle
                    >
                    <mat-button-toggle value="Less than once a week"
                      >Less than once a week</mat-button-toggle
                    >
                    <mat-button-toggle value="Never">Never</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is your child interested in playing with other
                  children?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q29TwentyFourMonths"
                  >
                    <mat-button-toggle value="Always">Always</mat-button-toggle>
                    <mat-button-toggle value="Usually"
                      >Usually</mat-button-toggle
                    >
                    <mat-button-toggle value="Sometimes"
                      >Sometimes</mat-button-toggle
                    >
                    <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
                    <mat-button-toggle value="Never">Never</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >When you say a word or wave your hand, will your child try to
                  copy you?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q30TwentyFourMonths"
                  >
                    <mat-button-toggle value="Always">Always</mat-button-toggle>
                    <mat-button-toggle value="Usually"
                      >Usually</mat-button-toggle
                    >
                    <mat-button-toggle value="Sometimes"
                      >Sometimes</mat-button-toggle
                    >
                    <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
                    <mat-button-toggle value="Never">Never</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child look at you when you call his or her
                  name?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q31TwentyFourMonths"
                  >
                    <mat-button-toggle value="Always">Always</mat-button-toggle>
                    <mat-button-toggle value="Usually"
                      >Usually</mat-button-toggle
                    >
                    <mat-button-toggle value="Sometimes"
                      >Sometimes</mat-button-toggle
                    >
                    <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
                    <mat-button-toggle value="Never">Never</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child look if you point to something across the
                  room?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q32TwentyFourMonths"
                  >
                    <mat-button-toggle value="Always">Always</mat-button-toggle>
                    <mat-button-toggle value="Usually"
                      >Usually</mat-button-toggle
                    >
                    <mat-button-toggle value="Sometimes"
                      >Sometimes</mat-button-toggle
                    >
                    <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
                    <mat-button-toggle value="Never">Never</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >How does your child usually show you something he or she
                  wants?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q33TwentyFourMonths"
                  >
                    <mat-button-toggle
                      value="Says a word for what he or she wants"
                      >Says a word for what he or she wants</mat-button-toggle
                    >
                    <mat-button-toggle value="Points to it with one finger"
                      >Points to it with one finger</mat-button-toggle
                    >
                    <mat-button-toggle value="Reaches for it"
                      >Reaches for it</mat-button-toggle
                    >
                    <mat-button-toggle
                      value="Pulls me over or puts my hand on it"
                      >Pulls me over or puts my hand on it</mat-button-toggle
                    >
                    <mat-button-toggle value="Grunts cries or screams"
                      >Grunts cries or screams</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >What are your child's favorite play activities?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q34TwentyFourMonths"
                  >
                    <mat-button-toggle
                      value="Playing with dolls or stuffed animals"
                      >Playing with dolls or stuffed animals</mat-button-toggle
                    >
                    <mat-button-toggle value="Reading books with you"
                      >Reading books with you</mat-button-toggle
                    >
                    <mat-button-toggle value="Climbing running and being active"
                      >Climbing running and being active</mat-button-toggle
                    >
                    <mat-button-toggle value="Lining up toys or other things"
                      >Lining up toys or other things</mat-button-toggle
                    >
                    <mat-button-toggle
                      value="Watching things go round and round like fans or wheels"
                      >Watching things go round and round like fans or
                      wheels</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Parent's Concerns </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you have any concerns about your child's learning or
                  development?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q35TwentyFourMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you have any concerns about your child's behavior?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q36TwentyFourMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Family Questions </mat-card-title>
              <mat-card-subtitle
                >Because family members can have a big impact on your child's
                development, please answer a few questions about your family
                below:</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does anyone who lives with your child smoke tobacco?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q37TwentyFourMonths"
                  >
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >In the last year, have you ever drunk alcohol or used drugs
                  more than you meant to?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q38TwentyFourMonths"
                  >
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have you felt you wanted or needed to cut down on your
                  drinking or drug use in the last year?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q39TwentyFourMonths"
                  >
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Has a family member's drinking or drug use ever had a bad
                  effect on your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q40TwentyFourMonths"
                  >
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Within the past 12 months, we worried whether our food would
                  run out before we got money to buy more</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q41TwentyFourMonths"
                  >
                    <mat-button-toggle value="Never true"
                      >Never true</mat-button-toggle
                    >
                    <mat-button-toggle value="Sometimes true"
                      >Sometimes true</mat-button-toggle
                    >
                    <mat-button-toggle value="Often true"
                      >Often true</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Having little interest or pleasure in doing things?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q42TwentyFourMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Several days"
                      >Several days</mat-button-toggle
                    >
                    <mat-button-toggle value="More than half the days"
                      >More than half the days</mat-button-toggle
                    >
                    <mat-button-toggle value="Nearly every day"
                      >Nearly every day</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Feeling down, depressed, or hopeless?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q43TwentyFourMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Several days"
                      >Several days</mat-button-toggle
                    >
                    <mat-button-toggle value="More than half the days"
                      >More than half the days</mat-button-toggle
                    >
                    <mat-button-toggle value="Nearly every day"
                      >Nearly every day</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >In general, how would you describe your relationship with
                  your spouse/partner?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q44TwentyFourMonths"
                  >
                    <mat-button-toggle value="No tension"
                      >No tension</mat-button-toggle
                    >
                    <mat-button-toggle value="Some tension"
                      >Some tension</mat-button-toggle
                    >
                    <mat-button-toggle value="A lot of tension"
                      >A lot of tension</mat-button-toggle
                    >
                    <mat-button-toggle value="Not applicable"
                      >Not applicable</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you and your partner work out arguments with:</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q45TwentyFourMonths"
                  >
                    <mat-button-toggle value="No difficulty"
                      >No difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Some difficulty"
                      >Some difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Great difficulty"
                      >Great difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Not applicable"
                      >Not applicable</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >During the past week, how many days did you or other family
                  members read to your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q46TwentyFourMonths"
                  >
                    <mat-button-toggle value="0">0</mat-button-toggle>
                    <mat-button-toggle value="1">1</mat-button-toggle>
                    <mat-button-toggle value="2">2</mat-button-toggle>
                    <mat-button-toggle value="3">3</mat-button-toggle>
                    <mat-button-toggle value="4">4</mat-button-toggle>
                    <mat-button-toggle value="5">5</mat-button-toggle>
                    <mat-button-toggle value="6">6</mat-button-toggle>
                    <mat-button-toggle value="7">7</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </ng-container>
        <ng-container *ngIf="selectedAgeGroup === '30months'">
          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Developmental Milestones </mat-card-title>
              <mat-card-subtitle
                >Most children at this age will be able to do some (but not all)
                of the developmental tasks listed below. Please tell us how much
                your child is doing each of these things. PLEASE BE SURE TO
                ANSWER ALL THE QUESTIONS.</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Names at least one color</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q1ThirtyMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Tries to get you to watch by saying "Look at me"</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q2ThirtyMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Says his or her first name when asked
                </label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q3ThirtyMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after">Draws lines</label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q4ThirtyMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Talks so other people can understand him or her most of the
                  time</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q5ThirtyMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Washes and dries hands without help (even if you turn on the
                  water)</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q6ThirtyMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Asks questions beginning with "why" or "how" - like "Why no
                  cookie?"
                </label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q7ThirtyMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Explains the reasons for things, like needing a sweater when
                  it's cold
                </label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q8ThirtyMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Compares things - using words like "bigger" or
                  "shorter"</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q9ThirtyMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Answers questions like "What do you do when you are cold?" or
                  .."when you are sleepy?"</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q10ThirtyMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title>
                Preschool Pediatric Symptom Checklist (PPSC)
              </mat-card-title>
              <mat-card-subtitle
                >These questions are about your child's behavior. Think about
                what you would expect of other children the same age, and tell
                us how much each statement applies to your
                child.</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Seem nervous or afraid?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q11ThirtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Seem sad or unhappy?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q12ThirtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Get upset if things are not done in a certain way?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q13ThirtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have a hard time with change?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q14ThirtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have trouble playing with other children?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q15ThirtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Break things on purpose?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q16ThirtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Fight with other children?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q17ThirtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have trouble paying attention?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q18ThirtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have a hard time calming down?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q19ThirtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after">Aggressive?</label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q20ThirtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Fidgety or unable to sit still?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q21ThirtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after">Angry?</label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q22ThirtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Take your child out in public?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q23ThirtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Comfort your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q24ThirtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Know what your child needs?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q25ThirtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Keep your child on a schedule or routine?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q26ThirtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Get your child to obey you?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q27ThirtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title>
                Parent's Observations of Social Interactions (POSI)
              </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child bring things to you to show them to
                  you?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q28ThirtyMonths">
                    <mat-button-toggle value="Many times a day"
                      >Many times a day</mat-button-toggle
                    >
                    <mat-button-toggle value="A few times a day"
                      >A few times a day</mat-button-toggle
                    >
                    <mat-button-toggle value="A few times a week"
                      >A few times a week</mat-button-toggle
                    >
                    <mat-button-toggle value="Less than once a week"
                      >Less than once a week</mat-button-toggle
                    >
                    <mat-button-toggle value="Never">Never</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Is your child interested in playing with other
                  children?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q29ThirtyMonths">
                    <mat-button-toggle value="Always">Always</mat-button-toggle>
                    <mat-button-toggle value="Usually"
                      >Usually</mat-button-toggle
                    >
                    <mat-button-toggle value="Sometimes"
                      >Sometimes</mat-button-toggle
                    >
                    <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
                    <mat-button-toggle value="Never">Never</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >When you say a word or wave your hand, will your child try to
                  copy you?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q30ThirtyMonths">
                    <mat-button-toggle value="Always">Always</mat-button-toggle>
                    <mat-button-toggle value="Usually"
                      >Usually</mat-button-toggle
                    >
                    <mat-button-toggle value="Sometimes"
                      >Sometimes</mat-button-toggle
                    >
                    <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
                    <mat-button-toggle value="Never">Never</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child look at you when you call his or her
                  name?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q31ThirtyMonths">
                    <mat-button-toggle value="Always">Always</mat-button-toggle>
                    <mat-button-toggle value="Usually"
                      >Usually</mat-button-toggle
                    >
                    <mat-button-toggle value="Sometimes"
                      >Sometimes</mat-button-toggle
                    >
                    <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
                    <mat-button-toggle value="Never">Never</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does your child look if you point to something across the
                  room?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q32ThirtyMonths">
                    <mat-button-toggle value="Always">Always</mat-button-toggle>
                    <mat-button-toggle value="Usually"
                      >Usually</mat-button-toggle
                    >
                    <mat-button-toggle value="Sometimes"
                      >Sometimes</mat-button-toggle
                    >
                    <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
                    <mat-button-toggle value="Never">Never</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >How does your child usually show you something he or she
                  wants?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q33ThirtyMonths">
                    <mat-button-toggle
                      value="Says a word for what he or she wants"
                      >Says a word for what he or she wants</mat-button-toggle
                    >
                    <mat-button-toggle value="Points to it with one finger"
                      >Points to it with one finger</mat-button-toggle
                    >
                    <mat-button-toggle value="Reaches for it"
                      >Reaches for it</mat-button-toggle
                    >
                    <mat-button-toggle
                      value="Pulls me over or puts my hand on it"
                      >Pulls me over or puts my hand on it</mat-button-toggle
                    >
                    <mat-button-toggle value="Grunts cries or screams"
                      >Grunts cries or screams</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >What are your child's favorite play activities?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q34ThirtyMonths">
                    <mat-button-toggle
                      value="Playing with dolls or stuffed animals"
                      >Playing with dolls or stuffed animals</mat-button-toggle
                    >
                    <mat-button-toggle value="Reading books with you"
                      >Reading books with you</mat-button-toggle
                    >
                    <mat-button-toggle value="Climbing running and being active"
                      >Climbing running and being active</mat-button-toggle
                    >
                    <mat-button-toggle value="Lining up toys or other things"
                      >Lining up toys or other things</mat-button-toggle
                    >
                    <mat-button-toggle
                      value="Watching things go round and round like fans or wheels"
                      >Watching things go round and round like fans or
                      wheels</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Parent's Concerns </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you have any concerns about your child's learning or
                  development?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q35ThirtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you have any concerns about your child's behavior?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q36ThirtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Family Questions </mat-card-title>
              <mat-card-subtitle
                >Because family members can have a big impact on your child's
                development, please answer a few questions about your family
                below:</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does anyone who lives with your child smoke tobacco?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q37ThirtyMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >In the last year, have you ever drunk alcohol or used drugs
                  more than you meant to?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q38ThirtyMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have you felt you wanted or needed to cut down on your
                  drinking or drug use in the last year?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q39ThirtyMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Has a family member's drinking or drug use ever had a bad
                  effect on your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q40ThirtyMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Within the past 12 months, we worried whether our food would
                  run out before we got money to buy more</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q41ThirtyMonths">
                    <mat-button-toggle value="Never true"
                      >Never true</mat-button-toggle
                    >
                    <mat-button-toggle value="Sometimes true"
                      >Sometimes true</mat-button-toggle
                    >
                    <mat-button-toggle value="Often true"
                      >Often true</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Having little interest or pleasure in doing things?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q42ThirtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Several days"
                      >Several days</mat-button-toggle
                    >
                    <mat-button-toggle value="More than half the days"
                      >More than half the days</mat-button-toggle
                    >
                    <mat-button-toggle value="Nearly every day"
                      >Nearly every day</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Feeling down, depressed, or hopeless?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q43ThirtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Several days"
                      >Several days</mat-button-toggle
                    >
                    <mat-button-toggle value="More than half the days"
                      >More than half the days</mat-button-toggle
                    >
                    <mat-button-toggle value="Nearly every day"
                      >Nearly every day</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >In general, how would you describe your relationship with
                  your spouse/partner?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q44ThirtyMonths">
                    <mat-button-toggle value="No tension"
                      >No tension</mat-button-toggle
                    >
                    <mat-button-toggle value="Some tension"
                      >Some tension</mat-button-toggle
                    >
                    <mat-button-toggle value="A lot of tension"
                      >A lot of tension</mat-button-toggle
                    >
                    <mat-button-toggle value="Not applicable"
                      >Not applicable</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you and your partner work out arguments with:</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q45ThirtyMonths">
                    <mat-button-toggle value="No difficulty"
                      >No difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Some difficulty"
                      >Some difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Great difficulty"
                      >Great difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Not applicable"
                      >Not applicable</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >During the past week, how many days did you or other family
                  members read to your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q46ThirtyMonths">
                    <mat-button-toggle value="0">0</mat-button-toggle>
                    <mat-button-toggle value="1">1</mat-button-toggle>
                    <mat-button-toggle value="2">2</mat-button-toggle>
                    <mat-button-toggle value="3">3</mat-button-toggle>
                    <mat-button-toggle value="4">4</mat-button-toggle>
                    <mat-button-toggle value="5">5</mat-button-toggle>
                    <mat-button-toggle value="6">6</mat-button-toggle>
                    <mat-button-toggle value="7">7</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </ng-container>
        <ng-container *ngIf="selectedAgeGroup === '36months'">
          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Developmental Milestones </mat-card-title>
              <mat-card-subtitle
                >Most children at this age will be able to do some (but not all)
                of the developmental tasks listed below. Please tell us how much
                your child is doing each of these things. PLEASE BE SURE TO
                ANSWER ALL THE QUESTIONS.</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Talks so other people can understand him or her most of the
                  time</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q1ThirtySixMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Washes and dries hands without help (even if you turn on the
                  water)</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q2ThirtySixMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Asks questions beginning with "why" or "how" - like "Why no
                  cookie?"
                </label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q3ThirtySixMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Explains the reasons for things, like needing a sweater when
                  it's cold
                </label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q4ThirtySixMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Compares things - using words like "bigger" or
                  "shorter"</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q5ThirtySixMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Answers questions like "What do you do when you are cold?" or
                  .."when you are sleepy?"</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q6ThirtySixMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Tells you a story from a book or tv</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q7ThirtySixMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Draws simple shapes - like a circle or a square</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q8ThirtySixMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Says words like "feet" for more than one foot and "men" for
                  more than one man</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q9ThirtySixMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Uses words like "yesterday" and "tomorrow" correctly</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q10ThirtySixMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title>
                Preschool Pediatric Symptom Checklist (PPSC)
              </mat-card-title>
              <mat-card-subtitle
                >These questions are about your child's behavior. Think about
                what you would expect of other children the same age, and tell
                us how much each statement applies to your
                child.</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Seem nervous or afraid?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q11ThirtySixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Seem sad or unhappy?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q12ThirtySixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Get upset if things are not done in a certain way?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q13ThirtySixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have a hard time with change?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q14ThirtySixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have trouble playing with other children?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q15ThirtySixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Break things on purpose?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q16ThirtySixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Fight with other children?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q17ThirtySixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have trouble paying attention?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q18ThirtySixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have a hard time calming down?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q19ThirtySixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after">Aggressive?</label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q20ThirtySixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Fidgety or unable to sit still?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q21ThirtySixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after">Angry?</label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q22ThirtySixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Take your child out in public?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q23ThirtySixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Comfort your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q24ThirtySixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Know what your child needs?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q25ThirtySixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Keep your child on a schedule or routine?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q26ThirtySixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Get your child to obey you?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q27ThirtySixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Parent's Concerns </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you have any concerns about your child's learning or
                  development?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q28ThirtySixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you have any concerns about your child's behavior?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q29ThirtySixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Family Questions </mat-card-title>
              <mat-card-subtitle
                >Because family members can have a big impact on your child's
                development, please answer a few questions about your family
                below:</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does anyone who lives with your child smoke tobacco?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q30ThirtySixMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >In the last year, have you ever drunk alcohol or used drugs
                  more than you meant to?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q31ThirtySixMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have you felt you wanted or needed to cut down on your
                  drinking or drug use in the last year?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q32ThirtySixMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Has a family member's drinking or drug use ever had a bad
                  effect on your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q33ThirtySixMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Within the past 12 months, we worried whether our food would
                  run out before we got money to buy more</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q34ThirtySixMonths">
                    <mat-button-toggle value="Never true"
                      >Never true</mat-button-toggle
                    >
                    <mat-button-toggle value="Sometimes true"
                      >Sometimes true</mat-button-toggle
                    >
                    <mat-button-toggle value="Often true"
                      >Often true</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Having little interest or pleasure in doing things?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q35ThirtySixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Several days"
                      >Several days</mat-button-toggle
                    >
                    <mat-button-toggle value="More than half the days"
                      >More than half the days</mat-button-toggle
                    >
                    <mat-button-toggle value="Nearly every day"
                      >Nearly every day</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Feeling down, depressed, or hopeless?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q36ThirtySixMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Several days"
                      >Several days</mat-button-toggle
                    >
                    <mat-button-toggle value="More than half the days"
                      >More than half the days</mat-button-toggle
                    >
                    <mat-button-toggle value="Nearly every day"
                      >Nearly every day</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >In general, how would you describe your relationship with
                  your spouse/partner?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q37ThirtySixMonths">
                    <mat-button-toggle value="No tension"
                      >No tension</mat-button-toggle
                    >
                    <mat-button-toggle value="Some tension"
                      >Some tension</mat-button-toggle
                    >
                    <mat-button-toggle value="A lot of tension"
                      >A lot of tension</mat-button-toggle
                    >
                    <mat-button-toggle value="Not applicable"
                      >Not applicable</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you and your partner work out arguments with:</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q38ThirtySixMonths">
                    <mat-button-toggle value="No difficulty"
                      >No difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Some difficulty"
                      >Some difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Great difficulty"
                      >Great difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Not applicable"
                      >Not applicable</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >During the past week, how many days did you or other family
                  members read to your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q39ThirtySixMonths">
                    <mat-button-toggle value="0">0</mat-button-toggle>
                    <mat-button-toggle value="1">1</mat-button-toggle>
                    <mat-button-toggle value="2">2</mat-button-toggle>
                    <mat-button-toggle value="3">3</mat-button-toggle>
                    <mat-button-toggle value="4">4</mat-button-toggle>
                    <mat-button-toggle value="5">5</mat-button-toggle>
                    <mat-button-toggle value="6">6</mat-button-toggle>
                    <mat-button-toggle value="7">7</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </ng-container>
        <ng-container *ngIf="selectedAgeGroup === '48months'">
          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Developmental Milestones </mat-card-title>
              <mat-card-subtitle
                >Most children at this age will be able to do some (but not all)
                of the developmental tasks listed below. Please tell us how much
                your child is doing each of these things. PLEASE BE SURE TO
                ANSWER ALL THE QUESTIONS.</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Compares things - using words like "bigger" or
                  "shorter"</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q1FortyEightMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Answers questions like "What do you do when you are cold?" or
                  .."when you are sleepy?"</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q2FortyEightMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Tells you a story from a book or tv</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q3FortyEightMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Draws simple shapes - like a circle or a square</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q4FortyEightMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Says words like "feet" for more than one foot and "men" for
                  more than one man</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q5FortyEightMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Uses words like "yesterday" and "tomorrow" correctly</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q6FortyEightMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Stays dry all night</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q7FortyEightMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Follows simple rules when playing a board game or card
                  game</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q8FortyEightMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Prints his or her name</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q9FortyEightMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Draws pictures you recognize</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q10FortyEightMonths"
                  >
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title>
                Preschool Pediatric Symptom Checklist (PPSC)
              </mat-card-title>
              <mat-card-subtitle
                >These questions are about your child's behavior. Think about
                what you would expect of other children the same age, and tell
                us how much each statement applies to your
                child.</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Seem nervous or afraid?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q11FortyEightMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Seem sad or unhappy?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q12FortyEightMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Get upset if things are not done in a certain way?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q13FortyEightMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have a hard time with change?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q14FortyEightMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have trouble playing with other children?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q15FortyEightMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Break things on purpose?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q16FortyEightMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Fight with other children?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q17FortyEightMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have trouble paying attention?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q18FortyEightMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have a hard time calming down?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q19FortyEightMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after">Aggressive?</label>
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q20FortyEightMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Fidgety or unable to sit still?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q21FortyEightMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after">Angry?</label>
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q22FortyEightMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Take your child out in public?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q23FortyEightMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Comfort your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q24FortyEightMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Know what your child needs?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q25FortyEightMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Keep your child on a schedule or routine?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q26FortyEightMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Get your child to obey you?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q27FortyEightMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Parent's Concerns </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you have any concerns about your child's learning or
                  development?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q28FortyEightMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you have any concerns about your child's behavior?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q29FortyEightMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Family Questions </mat-card-title>
              <mat-card-subtitle
                >Because family members can have a big impact on your child's
                development, please answer a few questions about your family
                below:</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does anyone who lives with your child smoke tobacco?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q30FortyEightMonths"
                  >
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >In the last year, have you ever drunk alcohol or used drugs
                  more than you meant to?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q31FortyEightMonths"
                  >
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have you felt you wanted or needed to cut down on your
                  drinking or drug use in the last year?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q32FortyEightMonths"
                  >
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Has a family member's drinking or drug use ever had a bad
                  effect on your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q33FortyEightMonths"
                  >
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Within the past 12 months, we worried whether our food would
                  run out before we got money to buy more</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q34FortyEightMonths"
                  >
                    <mat-button-toggle value="Never true"
                      >Never true</mat-button-toggle
                    >
                    <mat-button-toggle value="Sometimes true"
                      >Sometimes true</mat-button-toggle
                    >
                    <mat-button-toggle value="Often true"
                      >Often true</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Having little interest or pleasure in doing things?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q35FortyEightMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Several days"
                      >Several days</mat-button-toggle
                    >
                    <mat-button-toggle value="More than half the days"
                      >More than half the days</mat-button-toggle
                    >
                    <mat-button-toggle value="Nearly every day"
                      >Nearly every day</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Feeling down, depressed, or hopeless?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q36FortyEightMonths"
                  >
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Several days"
                      >Several days</mat-button-toggle
                    >
                    <mat-button-toggle value="More than half the days"
                      >More than half the days</mat-button-toggle
                    >
                    <mat-button-toggle value="Nearly every day"
                      >Nearly every day</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >In general, how would you describe your relationship with
                  your spouse/partner?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q37FortyEightMonths"
                  >
                    <mat-button-toggle value="No tension"
                      >No tension</mat-button-toggle
                    >
                    <mat-button-toggle value="Some tension"
                      >Some tension</mat-button-toggle
                    >
                    <mat-button-toggle value="A lot of tension"
                      >A lot of tension</mat-button-toggle
                    >
                    <mat-button-toggle value="Not applicable"
                      >Not applicable</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you and your partner work out arguments with:</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q38FortyEightMonths"
                  >
                    <mat-button-toggle value="No difficulty"
                      >No difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Some difficulty"
                      >Some difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Great difficulty"
                      >Great difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Not applicable"
                      >Not applicable</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >During the past week, how many days did you or other family
                  members read to your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group
                    formControlName="q39FortyEightMonths"
                  >
                    <mat-button-toggle value="0">0</mat-button-toggle>
                    <mat-button-toggle value="1">1</mat-button-toggle>
                    <mat-button-toggle value="2">2</mat-button-toggle>
                    <mat-button-toggle value="3">3</mat-button-toggle>
                    <mat-button-toggle value="4">4</mat-button-toggle>
                    <mat-button-toggle value="5">5</mat-button-toggle>
                    <mat-button-toggle value="6">6</mat-button-toggle>
                    <mat-button-toggle value="7">7</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </ng-container>
        <ng-container *ngIf="selectedAgeGroup === '60months'">
          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Developmental Milestones </mat-card-title>
              <mat-card-subtitle
                >Most children at this age will be able to do some (but not all)
                of the developmental tasks listed below. Please tell us how much
                your child is doing each of these things. PLEASE BE SURE TO
                ANSWER ALL THE QUESTIONS.</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Tells you a story from a book or tv</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q1SixtyMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Draws simple shapes - like a circle or a square</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q2SixtyMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Says words like "feet" for more than one foot and "men" for
                  more than one man</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q3SixtyMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Uses words like "yesterday" and "tomorrow" correctly</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q4SixtyMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Stays dry all night</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q5SixtyMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Follows simple rules when playing a board game or card
                  game</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q6SixtyMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Prints his or her name</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q7SixtyMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Draws pictures you recognize</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q8SixtyMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Stays in the lines when coloring</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q9SixtyMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Names the days of the week in the correct order</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q10SixtyMonths">
                    <mat-button-toggle value="Not Yet"
                      >Not Yet</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title>
                Preschool Pediatric Symptom Checklist (PPSC)
              </mat-card-title>
              <mat-card-subtitle
                >These questions are about your child's behavior. Think about
                what you would expect of other children the same age, and tell
                us how much each statement applies to your
                child.</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Seem nervous or afraid?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q11SixtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Seem sad or unhappy?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q12SixtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Get upset if things are not done in a certain way?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q13SixtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have a hard time with change?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q14SixtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have trouble playing with other children?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q15SixtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Break things on purpose?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q16SixtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Fight with other children?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q17SixtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have trouble paying attention?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q18SixtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have a hard time calming down?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q19SixtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after">Aggressive?</label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q20SixtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Fidgety or unable to sit still?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q21SixtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after">Angry?</label>
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q22SixtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Take your child out in public?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q23SixtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Comfort your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q24SixtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Know what your child needs?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q25SixtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Keep your child on a schedule or routine?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q26SixtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Get your child to obey you?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q27SixtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Parent's Concerns </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you have any concerns about your child's learning or
                  development?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q28SixtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you have any concerns about your child's behavior?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q29SixtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Somewhat"
                      >Somewhat</mat-button-toggle
                    >
                    <mat-button-toggle value="Very Much"
                      >Very Much</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8 margin-bottom-15"
            style="border-radius: 15px"
          >
            <mat-card-header>
              <mat-card-title> Family Questions </mat-card-title>
              <mat-card-subtitle
                >Because family members can have a big impact on your child's
                development, please answer a few questions about your family
                below:</mat-card-subtitle
              >
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content>
              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Does anyone who lives with your child smoke tobacco?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q30SixtyMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >In the last year, have you ever drunk alcohol or used drugs
                  more than you meant to?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q31SixtyMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Have you felt you wanted or needed to cut down on your
                  drinking or drug use in the last year?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q32SixtyMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Has a family member's drinking or drug use ever had a bad
                  effect on your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q33SixtyMonths">
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Within the past 12 months, we worried whether our food would
                  run out before we got money to buy more</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q34SixtyMonths">
                    <mat-button-toggle value="Never true"
                      >Never true</mat-button-toggle
                    >
                    <mat-button-toggle value="Sometimes true"
                      >Sometimes true</mat-button-toggle
                    >
                    <mat-button-toggle value="Often true"
                      >Often true</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Having little interest or pleasure in doing things?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q35SixtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Several days"
                      >Several days</mat-button-toggle
                    >
                    <mat-button-toggle value="More than half the days"
                      >More than half the days</mat-button-toggle
                    >
                    <mat-button-toggle value="Nearly every day"
                      >Nearly every day</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Feeling down, depressed, or hopeless?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q36SixtyMonths">
                    <mat-button-toggle value="Not at all"
                      >Not at all</mat-button-toggle
                    >
                    <mat-button-toggle value="Several days"
                      >Several days</mat-button-toggle
                    >
                    <mat-button-toggle value="More than half the days"
                      >More than half the days</mat-button-toggle
                    >
                    <mat-button-toggle value="Nearly every day"
                      >Nearly every day</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >In general, how would you describe your relationship with
                  your spouse/partner?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q37SixtyMonths">
                    <mat-button-toggle value="No tension"
                      >No tension</mat-button-toggle
                    >
                    <mat-button-toggle value="Some tension"
                      >Some tension</mat-button-toggle
                    >
                    <mat-button-toggle value="A lot of tension"
                      >A lot of tension</mat-button-toggle
                    >
                    <mat-button-toggle value="Not applicable"
                      >Not applicable</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >Do you and your partner work out arguments with:</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q38SixtyMonths">
                    <mat-button-toggle value="No difficulty"
                      >No difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Some difficulty"
                      >Some difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Great difficulty"
                      >Great difficulty</mat-button-toggle
                    >
                    <mat-button-toggle value="Not applicable"
                      >Not applicable</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="row pad10">
                <label class="col-md-3 asterix-after"
                  >During the past week, how many days did you or other family
                  members read to your child?</label
                >
                <div class="col-sm-9">
                  <mat-button-toggle-group formControlName="q39SixtyMonths">
                    <mat-button-toggle value="0">0</mat-button-toggle>
                    <mat-button-toggle value="1">1</mat-button-toggle>
                    <mat-button-toggle value="2">2</mat-button-toggle>
                    <mat-button-toggle value="3">3</mat-button-toggle>
                    <mat-button-toggle value="4">4</mat-button-toggle>
                    <mat-button-toggle value="5">5</mat-button-toggle>
                    <mat-button-toggle value="6">6</mat-button-toggle>
                    <mat-button-toggle value="7">7</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </ng-container>
      </mat-step>
    </mat-stepper>

    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          swycForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!swycForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
