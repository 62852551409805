<nav
  mat-tab-nav-bar
  class="mat-elevation-z8"
  style="background-color: #e1f5fe; margin-bottom: 15px"
  [tabPanel]="tabPanel"
>
  <a
    mat-tab-link
    (click)="activeTab = 'Medication History'"
    [active]="activeTab === 'Medication History'"
    >Medication History</a
  >
  <a
    *ngIf="prescriptionEnabled"
    mat-tab-link
    (click)="activeTab = 'E-Prescriptions'"
    [active]="activeTab === 'E-Prescriptions'"
    >E-Prescriptions</a
  >
</nav>

<mat-tab-nav-panel #tabPanel>
  <mat-card appearance="raised" style="margin-top: 10px; margin-bottom: 20px">
    <mat-card-content>
      <app-medications
        *ngIf="activeTab === 'Medication History'"
        [patient]="patient"
        [prescriptionEnabled]="prescriptionEnabled"
        [hasMedicationViewAccess]="hasMedicationViewAccess"
        [hasMedicationAddAccess]="hasMedicationAddAccess"
        [hasMedicationEditAccess]="hasMedicationEditAccess"
        [hasMedicationDeleteAccess]="hasMedicationDeleteAccess"
      >
      </app-medications>

      <app-dose-spot
        *ngIf="activeTab === 'E-Prescriptions' && prescriptionEnabled"
        [patient]="patient"
        (output)="triggerEvent($event)"
      >
      </app-dose-spot>
    </mat-card-content>
  </mat-card>
</mat-tab-nav-panel>
