import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap } from 'rxjs';
import { ResponseModal } from 'src/app/_models';
import { UserUIPropsSettingService } from '../../../_services';
import { UserSettingsPropsAction } from '../actions';

@Injectable()
export class UserSettingsPropsEffect {
  constructor(
    private actions$: Actions,
    private userUIPropsSettingService: UserUIPropsSettingService
  ) {}

  loadUserSettingsProps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserSettingsPropsAction.getUserSettingsProps),
      mergeMap((action) =>
        this.userUIPropsSettingService.getMyUISettings().pipe(
          map((response: ResponseModal) =>
            UserSettingsPropsAction.getUserSettingsSuccessAction({
              payload: response.data?.allSettings,
            })
          ),
          catchError((error) =>
            of(
              UserSettingsPropsAction.getUserSettingsFailedAction({
                payload: error,
              })
            )
          )
        )
      )
    )
  );

  handleUserSettingsPropsChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserSettingsPropsAction.handleUserSettingsPropsChange),
      mergeMap((action) =>
        this.userUIPropsSettingService
          .handleUISettingsPropsChange(action.category, action.value)
          .pipe(
            map(() =>
              UserSettingsPropsAction.handleUserSettingsPropsChangeSuccess()
            ),
            catchError((error) =>
              of(UserSettingsPropsAction.handleUserSettingsPropsChangeFailed())
            )
          )
      ),
      switchMap(() => [
        UserSettingsPropsAction.getUserSettingsProps() /**  Load the list, once the action is completed*/,
      ])
    )
  );
}
