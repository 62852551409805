import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-claims-alert',
  templateUrl: './claims-alert.component.html',
  styleUrl: './claims-alert.component.css',
})
export class ClaimsAlertComponent implements OnInit, OnChanges {
  @Input() claimStats: any[];
  @Output() selectNewTab = new EventEmitter<String>();

  dismissAlert: boolean = false;
  errors = [];

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.claimStats) {
      this.dismissAlert = false;
      this.errors = [];

      this.claimStats.forEach((tab) => {
        if (tab?.tabName === 'Rejected') {
          if (tab.count > 0) {
            this.errors.push({
              description: 'Rejected',
              count: tab.count,
              message: tab.count + ' Claim(s) have been Rejected.',
              color: 'red',
            });
          }
        } else if (tab?.tabName === 'On Hold') {
          if (tab.count > 0) {
            this.errors.push({
              description: 'On Hold',
              count: tab.count,
              message: tab.count + ' Claim(s) are currently On Hold.',
              color: '#d88c00',
            });
          }
        }
      });
    }
  }

  reviewAlert(alert) {
    this.selectNewTab.emit(alert.description);
  }
}
