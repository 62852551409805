<mat-card-title>Member Pharmacies</mat-card-title>
<mat-divider></mat-divider>

<div style="margin-top: 20px; padding: 10px">
  <!-- Search Box -->
  <div
    class="row"
    style="margin-right: 5px; row-gap: 15px; margin-bottom: 15px"
  >
    <div class="col-sm-12 col-md-4">
      <div
        style="
          display: flex;
          align-items: center;
          padding: 5px;
          border-radius: 10px;
          border: 1px solid rgb(0, 0, 0, 0.2);
          background-color: #ffffff;
          padding: 5px;
        "
      >
        <span
          class="material-icons"
          style="text-align: left; font-size: 20px; opacity: 0.5"
        >
          search
        </span>
        <input
          type="search"
          placeholder="Pharmacy Name"
          [(ngModel)]="searchName"
          (ngModelChange)="searchChanged()"
          style="
            padding-left: 15px;
            width: 100%;
            background: transparent;
            border: 0;
          "
        />
      </div>
    </div>
    <div
      class="col-sm-12 col-md-8"
      style="
        column-gap: 20px;
        display: flex;
        text-align: right;
        flex-direction: row-reverse;
        padding: 0;
        row-gap: 10px;
        flex-wrap: wrap;
      "
    >
      <button
        mat-raised-button
        color="warn"
        type="button"
        *ngIf="patient.status === 'Active'"
        (click)="addMemberPharmacy()"
      >
        + New Pharmacy
      </button>

      <button mat-raised-button [matMenuTriggerFor]="sortMenu">
        <span
          style="white-space: break-spaces; color: #3ac47d"
          *ngIf="searchStatus === 'Active'"
        >
          Status: Active
        </span>

        <span
          style="white-space: break-spaces; color: #d92550"
          *ngIf="searchStatus === 'Inactive'"
        >
          Status: Inactive
        </span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>

      <mat-menu #sortMenu="matMenu">
        <button
          mat-menu-item
          style="font-weight: 400; font-size: 12px; width: 100%"
          (click)="filterOptionChanged('Active')"
        >
          Active
        </button>
        <button
          mat-menu-item
          style="font-weight: 400; font-size: 12px; width: 100%"
          (click)="filterOptionChanged('Inactive')"
        >
          Inactive
        </button>
      </mat-menu>
    </div>
  </div>

  <!-- Loaders -->
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoadingResults"
  ></mat-progress-bar>

  <div *ngIf="data.total === 0 && !isLoadingResults">
    <mat-error> No {{ searchStatus }} Pharmacy </mat-error>
  </div>

  <!-- Data -->
  <div class="row" *ngIf="data.total > 0" style="margin-top: 10px">
    <div *ngFor="let pharmacy of data.items" class="col-md-6 col-lg-4">
      <mat-card
        [class]="
          pharmacy?.primaryPharmacy
            ? 'pharmacyCard defaultCard mat-elevation-z8'
            : 'pharmacyCard mat-elevation-z8'
        "
        appearance="raised"
      >
        <div class="pharmacyGridContainer">
          <div class="leftIcon">
            <mat-icon
              style="font-size: 45px; height: auto; width: auto"
              [style.color]="pharmacy?.primaryPharmacy ? 'green' : ''"
            >
              local_hospital
            </mat-icon>
          </div>
          <div class="rightbox">
            <!-- Name -->
            <label
              class="labelHeader"
              [style.color]="pharmacy?.primaryPharmacy ? 'green' : ''"
            >
              {{ pharmacy.pharmacyName }}
            </label>
            <br />
            <!-- Address -->
            <label style="margin: 0">{{ pharmacy.address1 }}</label>
            <br />
            <label style="margin: 0">
              {{ pharmacy.city }}, {{ pharmacy.state }}, {{ pharmacy.zip }}
            </label>
            <!-- Phone -->
            <br *ngIf="pharmacy.phoneNumber" />
            <label class="centerAligned" *ngIf="pharmacy.phoneNumber">
              <mat-icon>phone</mat-icon>
              {{ pharmacy.phoneNumber | phoneFormat }}
            </label>
            <!-- Fax -->
            <br *ngIf="pharmacy.faxNumber" />
            <label class="centerAligned" *ngIf="pharmacy.faxNumber">
              <mat-icon>fax</mat-icon> {{ pharmacy.faxNumber | phoneFormat }}
            </label>
          </div>
          <div class="editIcon">
            <button mat-icon-button type="button" [matMenuTriggerFor]="menu">
              <mat-icon style="font-size: 18px">more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu" style="max-height: 500px">
              <button
                mat-menu-item
                type="button"
                (click)="editMemberPharmacy(pharmacy)"
              >
                <mat-icon style="color: #f39121"> edit </mat-icon>
                <span>Edit</span>
              </button>

              <button
                mat-menu-item
                type="button"
                *ngIf="!pharmacy?.primaryPharmacy"
                (click)="markAsPrimary(pharmacy)"
              >
                <mat-icon style="color: #029102"> check_box </mat-icon>
                <span>Mark as Primary</span>
              </button>

              <button
                mat-menu-item
                type="button"
                (click)="deleteMemberPharmacy(pharmacy)"
              >
                <mat-icon style="color: red"> delete_forever </mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </div>
        </div>
        <label class="cardDefaultIndicator" *ngIf="pharmacy?.primaryPharmacy">
          <mat-icon>check_box</mat-icon>
          <span class="defaultIndicator">Default</span>
        </label>
      </mat-card>
    </div>

    <mat-paginator
      [length]="data.total"
      [pageSize]="data.per_page"
      *ngIf="data.total > data.per_page"
      (page)="getNext($event)"
      [showFirstLastButtons]="true"
    ></mat-paginator>
  </div>
</div>
