import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { IApgar } from 'src/app/_models/assessments/relationship-family/apgar.model';
import {
  ToastMessageService
} from 'src/app/_services/';
import {
  ApgarApiService
} from 'src/app/_services/assessments/relationship-family/apgar.service';

@Component({
  selector: 'app-apgar-assessment-dialog',
  templateUrl: './apgar-assessment-dialog.component.html',
  styleUrls: ['./apgar-assessment-dialog.component.css']
})
export class ApgarAssessmentDialogComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public apgarAssessmentApiService: ApgarApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public apgarAssessmentForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  apgarAssessment: IApgar;
  apgarAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.apgarAssessmentForm, this.errors);

    this.loadCore10Assessment();
  }

  loadCore10Assessment() {
    this.apgarAssessmentForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.apgarAssessmentApiService
        .getApgarDetails(this.assessmentId)
        .subscribe((apgarAssessment) => {
          if (apgarAssessment.data) {

            this.apgarAssessmentForm.controls['satisfiedWithFamilyHelp'].setValue(apgarAssessment.data.satisfiedWithFamilyHelp.answer);
            this.apgarAssessmentForm.controls['familyDiscussesInterests'].setValue(apgarAssessment.data.familyDiscussesInterests.answer);
            this.apgarAssessmentForm.controls['familyAcceptsNewActivities'].setValue(apgarAssessment.data.familyAcceptsNewActivities.answer);
            this.apgarAssessmentForm.controls['satisfiedWithFamilyAffection'].setValue(apgarAssessment.data.satisfiedWithFamilyAffection.answer);
            this.apgarAssessmentForm.controls['satisfiedWithFamilyTime'].setValue(apgarAssessment.data.satisfiedWithFamilyTime.answer);

            this.riskScore = apgarAssessment.data.riskScore;
          }
        });
    }
  }
  onApgarFormSubmit() {
    if (this.apgarAssessmentForm.invalid) {
      this.apgarAssessmentForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.apgarAssessment = Object.assign({}, this.apgarAssessment);
    this.apgarAssessment.patientId =
      this.apgarAssessmentForm.controls['patientId'].value;
    this.apgarAssessment.sessionId =
      this.apgarAssessmentForm.controls['sessionId'].value;
    this.apgarAssessment.assessmentRequestId =
      this.apgarAssessmentForm.controls['assessmentRequestId'].value;

    this.apgarAssessment.satisfiedWithFamilyHelp = { answer: this.apgarAssessmentForm.controls['satisfiedWithFamilyHelp'].value };
    this.apgarAssessment.familyDiscussesInterests = { answer: this.apgarAssessmentForm.controls['familyDiscussesInterests'].value };
    this.apgarAssessment.familyAcceptsNewActivities = { answer: this.apgarAssessmentForm.controls['familyAcceptsNewActivities'].value };
    this.apgarAssessment.satisfiedWithFamilyAffection = { answer: this.apgarAssessmentForm.controls['satisfiedWithFamilyAffection'].value };
    this.apgarAssessment.satisfiedWithFamilyTime = { answer: this.apgarAssessmentForm.controls['satisfiedWithFamilyTime'].value };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.apgarAssessment.accessCode = this.accessCode;

      this.apgarAssessmentApiService
        .addGuestApgar(this.apgarAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Family APGAR Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Family APGAR Assessment'
            );
          }
        );
    } else {
      this.apgarAssessmentApiService
        .addApgar(this.apgarAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Family APGAR Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Family APGAR Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    'satisfiedWithFamilyHelp': [
      { type: 'required', message: '1. I am satisfied with the help I receive from my family when something is troubling me is required' },
    ],
    'familyDiscussesInterests': [
      { type: 'required', message: '2. I am satisfied with the way my family discusses items of common interest and shares problem solving with me is required' },
    ],
    'familyAcceptsNewActivities': [
      { type: 'required', message: '3. I find that my family accepts my wishes to take on new activities or make changes in my life is required' },
    ],
    'satisfiedWithFamilyAffection': [
      { type: 'required', message: '4. I am satisfied with the way my family expresses affection and responds to my feelings is required' },
    ],
    'satisfiedWithFamilyTime': [
      { type: 'required', message: '5. I am satisfied with the amount of time my family and I spend together is required' },
    ],
  };

  private buildForm() {
    this.apgarAssessmentForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),

      satisfiedWithFamilyHelp: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      familyDiscussesInterests: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      familyAcceptsNewActivities: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      satisfiedWithFamilyAffection: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      satisfiedWithFamilyTime: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),

    });
    this.apgarAssessmentForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.apgarAssessmentForm) {
      return;
    }
    const form = this.apgarAssessmentForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
