<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Ritvo Asperger Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="ritvoAspergerForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >1. It is difficult for me to understand how other people are
            feeling when we are talking.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q1">
              <mat-button-toggle value="True Now & When Young"
                >True Now & When Young</mat-button-toggle
              >
              <mat-button-toggle value="True Only Now"
                >True Only Now</mat-button-toggle
              >
              <mat-button-toggle value="True When I Was Young"
                >True When I Was Young</mat-button-toggle
              >
              <mat-button-toggle value="Never True"
                >Never True</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >2. Some ordinary textures that do not bother others feel very
            offensive when they touch my skin.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q2">
              <mat-button-toggle value="True Now & When Young"
                >True Now & When Young</mat-button-toggle
              >
              <mat-button-toggle value="True Only Now"
                >True Only Now</mat-button-toggle
              >
              <mat-button-toggle value="True When I Was Young"
                >True When I Was Young</mat-button-toggle
              >
              <mat-button-toggle value="Never True"
                >Never True</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >3. It is very difficult for me to work and function in
            groups.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q3">
              <mat-button-toggle value="True Now & When Young"
                >True Now & When Young</mat-button-toggle
              >
              <mat-button-toggle value="True Only Now"
                >True Only Now</mat-button-toggle
              >
              <mat-button-toggle value="True When I Was Young"
                >True When I Was Young</mat-button-toggle
              >
              <mat-button-toggle value="Never True"
                >Never True</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >4. It is difficult to figure out what other people expect of
            me.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q4">
              <mat-button-toggle value="True Now & When Young"
                >True Now & When Young</mat-button-toggle
              >
              <mat-button-toggle value="True Only Now"
                >True Only Now</mat-button-toggle
              >
              <mat-button-toggle value="True When I Was Young"
                >True When I Was Young</mat-button-toggle
              >
              <mat-button-toggle value="Never True"
                >Never True</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >5. I often don't know how to act in social situations.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q5">
              <mat-button-toggle value="True Now & When Young"
                >True Now & When Young</mat-button-toggle
              >
              <mat-button-toggle value="True Only Now"
                >True Only Now</mat-button-toggle
              >
              <mat-button-toggle value="True When I Was Young"
                >True When I Was Young</mat-button-toggle
              >
              <mat-button-toggle value="Never True"
                >Never True</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >6. I can chat and make small talk with people.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q6">
              <mat-button-toggle value="True Now & When Young"
                >True Now & When Young</mat-button-toggle
              >
              <mat-button-toggle value="True Only Now"
                >True Only Now</mat-button-toggle
              >
              <mat-button-toggle value="True When I Was Young"
                >True When I Was Young</mat-button-toggle
              >
              <mat-button-toggle value="Never True"
                >Never True</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >7. When I feel overwhelmed by my senses, I have to isolate myself
            to shut them down.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q7">
              <mat-button-toggle value="True Now & When Young"
                >True Now & When Young</mat-button-toggle
              >
              <mat-button-toggle value="True Only Now"
                >True Only Now</mat-button-toggle
              >
              <mat-button-toggle value="True When I Was Young"
                >True When I Was Young</mat-button-toggle
              >
              <mat-button-toggle value="Never True"
                >Never True</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >8. How to make friends and socialize is a mystery to me.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q8">
              <mat-button-toggle value="True Now & When Young"
                >True Now & When Young</mat-button-toggle
              >
              <mat-button-toggle value="True Only Now"
                >True Only Now</mat-button-toggle
              >
              <mat-button-toggle value="True When I Was Young"
                >True When I Was Young</mat-button-toggle
              >
              <mat-button-toggle value="Never True"
                >Never True</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >9. When talking to someone, I have a hard time telling when it is
            my turn to talk or to listen.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q9">
              <mat-button-toggle value="True Now & When Young"
                >True Now & When Young</mat-button-toggle
              >
              <mat-button-toggle value="True Only Now"
                >True Only Now</mat-button-toggle
              >
              <mat-button-toggle value="True When I Was Young"
                >True When I Was Young</mat-button-toggle
              >
              <mat-button-toggle value="Never True"
                >Never True</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >10. Sometimes I have to cover my ears to block out painful noises
            (like vacuum cleaners or people talking too much or too
            loudly).</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q10">
              <mat-button-toggle value="True Now & When Young"
                >True Now & When Young</mat-button-toggle
              >
              <mat-button-toggle value="True Only Now"
                >True Only Now</mat-button-toggle
              >
              <mat-button-toggle value="True When I Was Young"
                >True When I Was Young</mat-button-toggle
              >
              <mat-button-toggle value="Never True"
                >Never True</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >11. It can be very hard to read someone's face, hand, and body
            movements when we are talking.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q11">
              <mat-button-toggle value="True Now & When Young"
                >True Now & When Young</mat-button-toggle
              >
              <mat-button-toggle value="True Only Now"
                >True Only Now</mat-button-toggle
              >
              <mat-button-toggle value="True When I Was Young"
                >True When I Was Young</mat-button-toggle
              >
              <mat-button-toggle value="Never True"
                >Never True</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >12. I focus on details rather than the overall idea.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q12">
              <mat-button-toggle value="True Now & When Young"
                >True Now & When Young</mat-button-toggle
              >
              <mat-button-toggle value="True Only Now"
                >True Only Now</mat-button-toggle
              >
              <mat-button-toggle value="True When I Was Young"
                >True When I Was Young</mat-button-toggle
              >
              <mat-button-toggle value="Never True"
                >Never True</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >13. I take things too literally, so I often miss what people are
            trying to say.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q13">
              <mat-button-toggle value="True Now & When Young"
                >True Now & When Young</mat-button-toggle
              >
              <mat-button-toggle value="True Only Now"
                >True Only Now</mat-button-toggle
              >
              <mat-button-toggle value="True When I Was Young"
                >True When I Was Young</mat-button-toggle
              >
              <mat-button-toggle value="Never True"
                >Never True</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >14. I get extremely upset when the way I like to do things is
            suddenly changed.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q14">
              <mat-button-toggle value="True Now & When Young"
                >True Now & When Young</mat-button-toggle
              >
              <mat-button-toggle value="True Only Now"
                >True Only Now</mat-button-toggle
              >
              <mat-button-toggle value="True When I Was Young"
                >True When I Was Young</mat-button-toggle
              >
              <mat-button-toggle value="Never True"
                >Never True</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          ritvoAspergerForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!ritvoAspergerForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
