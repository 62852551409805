<mat-card-title>
  Financial History, Marital Status, Family Lifestyle, Legal
  Issues</mat-card-title
>
<mat-divider></mat-divider>

<div style="margin-top: 20px; padding: 10px">
  <form
    [formGroup]="bpsafinancialmaritaletcForm"
    novalidate
    (ngSubmit)="onBpsaFinancialMaritalEtcFormSubmit()"
  >
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >Are you able to financially support yourself/family?
            <span style="color: red">*</span></label
          >
          <br />

          <mat-button-toggle-group formControlName="supportFinancially">
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="Member Denies"
              >Member Denies</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >Are you able to budget your finances?
            <span style="color: red">*</span></label
          >&nbsp;
          <br />
          <mat-button-toggle-group formControlName="canBudget">
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="Member Denies"
              >Member Denies</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >Who do you currently live with?
            <span style="color: red">*</span></label
          >&nbsp;
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="currentlyLiveWith"
              name="currentlyLiveWith"
              required
            />
            <mat-error
              *ngIf="
                bpsafinancialmaritaletcForm.controls['currentlyLiveWith']
                  .invalid
              "
            >
              Who do you currently live with is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >What is your relationship status?
            <span style="color: red">*</span></label
          >&nbsp;
          <br />
          <mat-button-toggle-group formControlName="relationshipStatus">
            <mat-button-toggle value="Partnered">Partnered</mat-button-toggle>
            <mat-button-toggle value="Married">Married</mat-button-toggle>
            <mat-button-toggle value="Separated">Separated</mat-button-toggle>
            <mat-button-toggle value="Divorced">Divorced</mat-button-toggle>
            <mat-button-toggle value="Single">Single</mat-button-toggle>
            <mat-button-toggle value="Member Denies"
              >Member Denies</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >Tell me about your current relationship or most recent past
            relationship <span style="color: red">*</span></label
          >&nbsp;
          <br />
          <mat-button-toggle-group formControlName="currentRelationshipDetail">
            <mat-button-toggle value="Member Denies"
              >Member Denies</mat-button-toggle
            >
            <mat-button-toggle value="NA">NA</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >Do you have a child or children?
            <span style="color: red">*</span></label
          >&nbsp;
          <br />
          <mat-button-toggle-group formControlName="haveChildren">
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="Member Denies"
              >Member Denies</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >How old are your children? <span style="color: red">*</span></label
          >&nbsp;
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="childrenAge"
              name="childrenAge"
              placeholder="Type N/A if not applicable"
              required
            />
            <mat-error
              *ngIf="
                bpsafinancialmaritaletcForm.controls['childrenAge'].invalid
              "
            >
              How old are your children is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >If divorced with children, who has primary custody?
            <span style="color: red">*</span></label
          >&nbsp;
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="primaryCustodyBy"
              name="primaryCustodyBy"
              placeholder="Type N/A if not applicable"
              required
            />
            <mat-error
              *ngIf="
                bpsafinancialmaritaletcForm.controls['primaryCustodyBy'].invalid
              "
            >
              If divorced with children, who has primary custody is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >Do you have any past or present legal issues?
            <span style="color: red">*</span></label
          >&nbsp;
          <br />
          <mat-button-toggle-group formControlName="legalIssues">
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="Member Denies"
              >Member Denies</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
  </form>
</div>
