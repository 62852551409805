import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { DataTablesModel } from 'src/app/_models';
import { ILabOrder } from 'src/app/_models/patient/laborder.model';
import { LabOrderApiService } from 'src/app/_services/patient/laborder.service';
import { AddEditLabOrderComponent } from './dialogs/add-edit-lab-order/add-edit-lab-order.component';

@Component({
  selector: 'app-lab-orders',
  templateUrl: './lab-orders.component.html',
  styleUrl: './lab-orders.component.css'
})

export class LabOrdersComponent implements OnInit {
  displayedColumns = ['testType', 'testDate', 'fasting', 'patientFirstName', 'patientLastName', 'patientDob', 'patientEmail', 'patientPhone', 'patientAddressLine1', 'patientAddressLine2', 'patientCity', 'patientState', 'patientZip'];
  data: DataTablesModel = {} as DataTablesModel;
  processing$ = false;
  isLoadingResults = false;
  apiError = false;
  action: string = '';
  error: string = '';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  patientId: string;

  constructor(public dialog: MatDialog,
    public laborderApiService: LabOrderApiService,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.data.page = 0;
    this.data.per_page = 10;
    this.loadLabOrderList();
  }

  addLabOrder() {
    this.action = 'ADD';
    const dialogRef = this.dialog.open(AddEditLabOrderComponent, {
      data: { action: this.action, laborder: null },
      autoFocus: false,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.loadLabOrderList();
    });
  }

  public editLabOrder(laborder: ILabOrder) {
    this.action = 'EDIT';
    const dialogRef = this.dialog.open(AddEditLabOrderComponent, {
      data: { action: this.action, laborder },

      autoFocus: false,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.loadLabOrderList();
    });
  }

  public loadLabOrderList() {
    this.isLoadingResults = true;
    this.error = '';
    this.laborderApiService.getLabOrderList(this.data.per_page, this.data.page).subscribe(response => {
      this.isLoadingResults = false;
      if (response && response.items) {
        this.data.items = response.items;
        this.data.total = response.total;
      }
      if (response == null) {
        this.data.items = [];
        this.data.total = 0;
      }
    },
      error => {
        this.error = error;
        this.isLoadingResults = false;
      })
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadLabOrderList();
  }

}