import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { DataTablesModel, Patient } from 'src/app/_models';
import { IAllergy } from 'src/app/_models/patient/allergy.model';
import { ToastMessageService } from 'src/app/_services';
import { AllergyApiService } from 'src/app/_services/patient/allergy.service';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { AddEditAllergyDialogComponent } from './dialogs/add-edit-allergy-dialog/add-edit-allergy-dialog.component';

@Component({
  selector: 'app-member-allergies',
  styleUrls: ['allergies.component.css'],
  templateUrl: 'allergies.component.html',
})
export class MemberAllergiesComponent implements OnInit, OnChanges {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() patient: Patient;

  @Input() hasAllergiesViewAccess: boolean;
  @Input() hasAllergiesAddAccess: boolean;
  @Input() hasAllergiesEditAccess: boolean;
  @Input() hasAllergiesDeleteAccess: boolean;

  displayedColumns = [
    'name',
    'reactionType',
    'reaction',
    'onsetDate',
    'status',
  ];
  data: DataTablesModel = {} as DataTablesModel;
  status = 'Active';
  isLoadingResults = false;
  apiError = false;

  constructor(
    public dialog: MatDialog,
    public allergyApiService: AllergyApiService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit() {
    if (this.hasAllergiesEditAccess || this.hasAllergiesDeleteAccess) {
      this.displayedColumns.push('action');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.patient) {
      this.data.page = 0;
      this.data.per_page = 10;

      this.loadAllergyList();
    }
  }

  addAllergy() {
    const dialogRef = this.dialog.open(AddEditAllergyDialogComponent, {
      data: { action: 'ADD', allergy: null, patientId: this.patient.id },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadAllergyList();
      }
    });
  }

  public editAllergy(allergy: IAllergy) {
    const dialogRef = this.dialog.open(AddEditAllergyDialogComponent, {
      data: { action: 'EDIT', allergy, patientId: this.patient.id },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadAllergyList();
      }
    });
  }

  filterOptionChanged(status) {
    this.status = status;
    this.data.page = 0;
    this.loadAllergyList();
  }

  public loadAllergyList() {
    this.isLoadingResults = true;
    this.apiError = false;

    this.allergyApiService
      .getAllergyList(
        this.patient.id,
        this.status,
        this.data.per_page,
        this.data.page
      )
      .subscribe(
        (response) => {
          this.isLoadingResults = false;
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          }
          if (response == null) {
            this.data.items = [];
            this.data.total = 0;
          }
        },
        (error) => {
          this.apiError = true;
          this.isLoadingResults = false;
        }
      );
  }

  deleteAllergy(allergy) {
    const dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to delete this allergy?',
      },
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.allergyApiService.deleteAllery(allergy.id).subscribe(
          () => {
            if (this.data.items.length == 1) {
              this.data.page = this.data.page - 1;
              if (this.data.page < 0) {
                this.data.page = 0;
              }
            }

            this.loadAllergyList();
            this.toastMessageService.displaySuccessMessage(
              'Allergies have been updated'
            );
          },
          (error) => {
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to update the Allergies'
            );
          }
        );
      }
    });
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadAllergyList();
  }
}
