<div class="row">
  <!-- Heading -->
  <div class="col-md-12">
    <p style="font-size: 16px; font-weight: 450">
      Welcome {{ loggedInUserName }}
    </p>
  </div>
  <!-- Statistic Cards -->
  <div class="col-md-4" *ngFor="let dasboardCard of combinedDashboardCards">
    <!-- For Assessments -->
    <mat-card appearance="raised" class="dashboardCardsContainer">
      <!-- Main Icon -->
      <div
        class="dashboardCardIcons"
        [style.background]="dasboardCard.iconBackgroundColor"
      >
        <mat-icon style="color: white">{{ dasboardCard.icon }}</mat-icon>
      </div>
      <!-- Main Header -->
      <div class="dashboardMainTitle">
        <label class="labelHeader">{{ dasboardCard.title }}</label>
      </div>
      <!-- Inner Dashboard components -->
      <div class="dashboardContentWrapper">
        <div
          class="contentWrapper"
          *ngFor="let content of dasboardCard.cards"
          (click)="navigateTo(content.route)"
        >
          <mat-icon [style.color]="content.iconBackgroundColor">
            {{ content.icon }}
          </mat-icon>
          <label class="labelText" [style.cursor]="content.cursor">
            {{ content.title }}
          </label>
          <label class="labelBold" [style.cursor]="content.cursor">
            {{ content.count }}
          </label>
        </div>
      </div>
    </mat-card>
  </div>
</div>
