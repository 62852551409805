<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>DSM 5 - Level 1 Cross Cutting Symptoms</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row pad20">
    <div class="col-sm-12 text-center">
      Symptoms:
      <span
        *ngIf="
          !somatic &&
          !sleep &&
          !depression &&
          !anger &&
          !mania &&
          !anxiety &&
          !psychosis &&
          !inattentive &&
          !repetetiveThoughtsAndBehaviors &&
          !substanceUse &&
          !suicidal &&
          !memory &&
          !dissociation &&
          !personality
        "
      >
        &nbsp;<strong>None</strong>
      </span>
      <br />
      <button class="btn btn-primary" mat-raised-button *ngIf="somatic">
        Somatic
      </button>
      <button class="btn btn-primary" mat-raised-button *ngIf="sleep">
        Sleep
      </button>
      <button class="btn btn-primary" mat-raised-button *ngIf="depression">
        Depression
      </button>
      <button class="btn btn-primary" mat-raised-button *ngIf="anger">
        Anger / Irritability
      </button>
      <button class="btn btn-primary" mat-raised-button *ngIf="mania">
        Mania
      </button>
      <button class="btn btn-primary" mat-raised-button *ngIf="anxiety">
        Anxiety
      </button>
      <button class="btn btn-primary" mat-raised-button *ngIf="psychosis">
        Psychosis
      </button>
      <button class="btn btn-primary" mat-raised-button *ngIf="inattentive">
        Inattention
      </button>
      <button
        class="btn btn-primary"
        mat-raised-button
        *ngIf="repetetiveThoughtsAndBehaviors"
      >
        Repetetive Thoughts And Behaviors
      </button>
      <button class="btn btn-primary" mat-raised-button *ngIf="substanceUse">
        Substance Use
      </button>
      <button class="btn btn-primary" mat-raised-button *ngIf="suicidal">
        Suicidal Ideation
      </button>
      <button class="btn btn-primary" mat-raised-button *ngIf="memory">
        Memory
      </button>
      <button class="btn btn-primary" mat-raised-button *ngIf="dissociation">
        Dissociation
      </button>
      <button class="btn btn-primary" mat-raised-button *ngIf="personality">
        Personality
      </button>
    </div>
  </div>

  <form
    [formGroup]="crosscuttinglevelonesixtoseventeenForm"
    novalidate
    (ngSubmit)="onCrossCuttingLevelOneSixToSeventeenFormSubmit()"
  >
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >1. Complained of stomachaches, headaches, or other aches and
        pains?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group formControlName="complained">
          <mat-button-toggle value="None">None</mat-button-toggle>
          <mat-button-toggle value="Slight">Slight</mat-button-toggle>
          <mat-button-toggle value="Mild">Mild</mat-button-toggle>
          <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
          <mat-button-toggle value="Severe">Severe</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >2. Said he/she was worried about his/her health or about getting
        sick?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group formControlName="worried">
          <mat-button-toggle value="None">None</mat-button-toggle>
          <mat-button-toggle value="Slight">Slight</mat-button-toggle>
          <mat-button-toggle value="Mild">Mild</mat-button-toggle>
          <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
          <mat-button-toggle value="Severe">Severe</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >3. Had problems sleeping—that is, trouble falling asleep, staying
        asleep,or waking up too early?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group formControlName="problemsSleeping">
          <mat-button-toggle value="None">None</mat-button-toggle>
          <mat-button-toggle value="Slight">Slight</mat-button-toggle>
          <mat-button-toggle value="Mild">Mild</mat-button-toggle>
          <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
          <mat-button-toggle value="Severe">Severe</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >4. Had problems paying attention when he/she was in class or doing
        his/her homework or reading a book or playing a game?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group formControlName="attentionProblems">
          <mat-button-toggle value="None">None</mat-button-toggle>
          <mat-button-toggle value="Slight">Slight</mat-button-toggle>
          <mat-button-toggle value="Mild">Mild</mat-button-toggle>
          <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
          <mat-button-toggle value="Severe">Severe</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >5. Had less fun doing things than he/she used to?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group formControlName="lessFun">
          <mat-button-toggle value="None">None</mat-button-toggle>
          <mat-button-toggle value="Slight">Slight</mat-button-toggle>
          <mat-button-toggle value="Mild">Mild</mat-button-toggle>
          <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
          <mat-button-toggle value="Severe">Severe</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >6. Seemed sad or depressed for several hours?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group formControlName="sadOrDepressed">
          <mat-button-toggle value="None">None</mat-button-toggle>
          <mat-button-toggle value="Slight">Slight</mat-button-toggle>
          <mat-button-toggle value="Mild">Mild</mat-button-toggle>
          <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
          <mat-button-toggle value="Severe">Severe</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >7. Seemed more irritated or easily annoyed than usual?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group formControlName="irritatedOrAnnoyed">
          <mat-button-toggle value="None">None</mat-button-toggle>
          <mat-button-toggle value="Slight">Slight</mat-button-toggle>
          <mat-button-toggle value="Mild">Mild</mat-button-toggle>
          <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
          <mat-button-toggle value="Severe">Severe</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >8. Seemed angry or lost his/her temper?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group formControlName="angerOrTemper">
          <mat-button-toggle value="None">None</mat-button-toggle>
          <mat-button-toggle value="Slight">Slight</mat-button-toggle>
          <mat-button-toggle value="Mild">Mild</mat-button-toggle>
          <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
          <mat-button-toggle value="Severe">Severe</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >9. Started lots more projects than usual or done more risky things than
        usual?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group formControlName="moreProjects">
          <mat-button-toggle value="None">None</mat-button-toggle>
          <mat-button-toggle value="Slight">Slight</mat-button-toggle>
          <mat-button-toggle value="Mild">Mild</mat-button-toggle>
          <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
          <mat-button-toggle value="Severe">Severe</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >10. Slept less than usual for him/her, but still had lots of
        energy?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group formControlName="sleptLess">
          <mat-button-toggle value="None">None</mat-button-toggle>
          <mat-button-toggle value="Slight">Slight</mat-button-toggle>
          <mat-button-toggle value="Mild">Mild</mat-button-toggle>
          <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
          <mat-button-toggle value="Severe">Severe</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >11. Said he/she felt nervous, anxious, or scared?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group formControlName="feltNervous">
          <mat-button-toggle value="None">None</mat-button-toggle>
          <mat-button-toggle value="Slight">Slight</mat-button-toggle>
          <mat-button-toggle value="Mild">Mild</mat-button-toggle>
          <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
          <mat-button-toggle value="Severe">Severe</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >12. Not been able to stop worrying?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group formControlName="stopWorrying">
          <mat-button-toggle value="None">None</mat-button-toggle>
          <mat-button-toggle value="Slight">Slight</mat-button-toggle>
          <mat-button-toggle value="Mild">Mild</mat-button-toggle>
          <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
          <mat-button-toggle value="Severe">Severe</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >13. Said he/she couldn't do things he/she wanted to or should have
        done, because they made him/her feel nervous? can't be null</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group formControlName="couldntDoThings">
          <mat-button-toggle value="None">None</mat-button-toggle>
          <mat-button-toggle value="Slight">Slight</mat-button-toggle>
          <mat-button-toggle value="Mild">Mild</mat-button-toggle>
          <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
          <mat-button-toggle value="Severe">Severe</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >14. Said that he/she heard voices—when there was no one there—speaking
        about him/her or telling him/her what to do or saying bad things to
        him/her?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group formControlName="heardVoices">
          <mat-button-toggle value="None">None</mat-button-toggle>
          <mat-button-toggle value="Slight">Slight</mat-button-toggle>
          <mat-button-toggle value="Mild">Mild</mat-button-toggle>
          <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
          <mat-button-toggle value="Severe">Severe</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >15. Said that he/she had a vision when he/she was completely awake—that
        is, saw something or someone that no one else could see?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group formControlName="illusion">
          <mat-button-toggle value="None">None</mat-button-toggle>
          <mat-button-toggle value="Slight">Slight</mat-button-toggle>
          <mat-button-toggle value="Mild">Mild</mat-button-toggle>
          <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
          <mat-button-toggle value="Severe">Severe</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >16. Said that he/she had thoughts that kept coming into his/her mind
        that he/she would do something bad or that something bad would happen to
        him/her or to someone else?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group formControlName="thoughts">
          <mat-button-toggle value="None">None</mat-button-toggle>
          <mat-button-toggle value="Slight">Slight</mat-button-toggle>
          <mat-button-toggle value="Mild">Mild</mat-button-toggle>
          <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
          <mat-button-toggle value="Severe">Severe</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >17. Said he/she felt the need to check on certain things over and over
        again, like whether a door was locked or whether the stove was turned
        off?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group formControlName="needToCheck">
          <mat-button-toggle value="None">None</mat-button-toggle>
          <mat-button-toggle value="Slight">Slight</mat-button-toggle>
          <mat-button-toggle value="Mild">Mild</mat-button-toggle>
          <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
          <mat-button-toggle value="Severe">Severe</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >18. Seemed to worry alot about things he/she touched being dirty or
        having germs or being poisoned?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group formControlName="worryALot">
          <mat-button-toggle value="None">None</mat-button-toggle>
          <mat-button-toggle value="Slight">Slight</mat-button-toggle>
          <mat-button-toggle value="Mild">Mild</mat-button-toggle>
          <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
          <mat-button-toggle value="Severe">Severe</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >19. Said that he/she had to do things in a certain way, like counting
        or saying special things out loud, in order to keep something bad from
        happening?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group formControlName="needToDoThings">
          <mat-button-toggle value="None">None</mat-button-toggle>
          <mat-button-toggle value="Slight">Slight</mat-button-toggle>
          <mat-button-toggle value="Mild">Mild</mat-button-toggle>
          <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
          <mat-button-toggle value="Severe">Severe</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >20. Had an alcoholic beverage (beer, wine, liquor, etc.,)?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group formControlName="alcoholicBeverage">
          <mat-button-toggle value="Yes">Yes</mat-button-toggle>
          <mat-button-toggle value="No">No</mat-button-toggle>
          <mat-button-toggle value="Don't Know">Don't Know</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >21. Smoked a cigarette, a cigar, or pipe, or used snuff or chewing
        tobacco?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group formControlName="smokedCigarette">
          <mat-button-toggle value="Yes">Yes</mat-button-toggle>
          <mat-button-toggle value="No">No</mat-button-toggle>
          <mat-button-toggle value="Don't Know">Don't Know</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >22. Used drugs like marijuana, cocaine, or crack, club drugs (Like
        Ecstasy), hallucinogens (like LSD), heroin, inhalants or insolvents
        (like glue), or methamphetamine (like speed)?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group formControlName="drugs">
          <mat-button-toggle value="Yes">Yes</mat-button-toggle>
          <mat-button-toggle value="No">No</mat-button-toggle>
          <mat-button-toggle value="Don't Know">Don't Know</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >23. Used any medicine without a doctor's prescription to get high or
        change the way you feel (e.g., painkillers[like Vicodin],
        stimulants[like Ritalin or Adderall], sedatives or tranquilizers [like
        sleeping pills or Valium], or Steriods)?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          formControlName="medicationWithoutPrescription"
        >
          <mat-button-toggle value="Yes">Yes</mat-button-toggle>
          <mat-button-toggle value="No">No</mat-button-toggle>
          <mat-button-toggle value="Don't Know">Don't Know</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >24. In the past TWO (2) WEEKS,has he/she talked about wanting to kill
        himself/herself or about wanting to commit suicide?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group formControlName="thoughtAboutKilling">
          <mat-button-toggle value="Yes">Yes</mat-button-toggle>
          <mat-button-toggle value="No">No</mat-button-toggle>
          <mat-button-toggle value="Don't Know">Don't Know</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >25. Has he/she EVER tried to kill himself/herself?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group formControlName="triedToKillSelf">
          <mat-button-toggle value="Yes">Yes</mat-button-toggle>
          <mat-button-toggle value="No">No</mat-button-toggle>
          <mat-button-toggle value="Don't Know">Don't Know</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          crosscuttinglevelonesixtoseventeenForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!crosscuttinglevelonesixtoseventeenForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
