import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boweldescriptions',
})
export class BowelPipe implements PipeTransform {
  transform(key: string): string {
    var output = "";
    switch (key) {
      case "SM":
        output = "Small"
        break;
      case "M":
        output = "Medium"
        break;
      case "L":
        output = "Large"
        break;
      case "LOA":
        output = "Leave of Absence"
        break;
      case "AS":
        output = "At School"
        break;
      case "B":
        output = "Brown"
        break;
      case "BL":
        output = "Black"
        break;
      case "TR":
        output = "Tarry"
        break;
      case "H":
        output = "Hard"
        break;
      case "F":
        output = "Formed"
        break;
      case "S":
        output = "Soft"
        break;
      case "W":
        output = "Watery"
        break;
      default:
        output = ""
        break;
    }

    return output;
  }
}
