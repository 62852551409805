import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-view-member-payment-notes',
  templateUrl: './view-member-payment-notes.component.html',
  styleUrl: './view-member-payment-notes.component.css',
})
export class ViewMemberPaymentNotesComponent implements OnInit {
  notes: string;

  constructor(
    public dialogRef: MatDialogRef<ViewMemberPaymentNotesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.notes = data.notes;
  }

  ngOnInit(): void {}
}
