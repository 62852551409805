import { Component, Input, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AssessmentApiService, CoreService, ToastMessageService } from 'src/app/_services';

@Component({
  selector: 'app-care-plan',
  templateUrl: './care-plan.component.html',
  styleUrls: ['./care-plan.component.css'],
})
export class CarePlanComponent implements OnInit {
  @Input() selectedAssessmentType: string;

  answerForm: FormGroup;
  questions: any[];
  processing: boolean = false;
  isLoading = true;
  error: string = '';
  metadata: any;
  displayBreadCrumbs = false;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private toastMessageService: ToastMessageService,
    public assessmentApiService: AssessmentApiService,
    private coreService: CoreService,
  ) {
    this.answerForm = this.fb.group({
      assessmentName: new FormControl('', Validators.compose([])),
    });
  }

  ngOnInit(): void {
    this.displayBreadCrumbs = !this.coreService.isSuperOrgAdmin() && !this.coreService.isOrgAdmin();
    this.selectedAssessmentType = this.route.snapshot.paramMap.get('id');
    this.getAssessmentDetails();
  }

  guidanceRecords(fieldName: string): FormArray {
    return this.answerForm.get(fieldName) as FormArray;
  }

  addGuidanceRecord(fieldName: string, value: string) {
    const newRecord = this.fb.group({
      guidance: new FormControl(value, Validators.compose([])),
    });
    this.guidanceRecords(fieldName).push(newRecord);
  }

  removeGuidanceRecord(fieldName: string, i: number) {
    this.guidanceRecords(fieldName).removeAt(i);
    this.onSubmit();
  }

  onSubmit() {
    this.processing = true;
    this.assessmentApiService.updateAssessment(this.answerForm.value).subscribe(
      (response) => {
        if (response) {
          this.processing = false;
          this.toastMessageService.displaySuccessMessage(
            'Successfully updated the care document'
          );
        }
      },
      (error) => {
        this.processing = false;
        this.toastMessageService.displayErrorMessage(
          'Failed to update the care document'
        );
      }
    );
  }

  public getAssessmentDetails() {
    this.isLoading = true;
    this.questions = [];
    this.answerForm = this.fb.group({
      assessmentName: '',
    });

    this.answerForm.controls['assessmentName'].setValue(
      this.selectedAssessmentType
    );

    this.error = '';
    this.assessmentApiService
      .getAssessmentGuide(this.selectedAssessmentType)
      .subscribe((assessmentGuideResponse) => {
        this.assessmentApiService
          .getAssessmentDetails(this.selectedAssessmentType)
          .subscribe(
            (response) => {
              if (response && response.data) {
                let questionAnswers = [];
                this.metadata = response.data.metadata;
                let questions = response.data.fields;
                for (var question of questions) {
                  let newAnswers = [];
                  for (var answer of question.answers) {
                    if (!answer.id) {
                      answer.id = question.name + '-' + answer.value;
                    }

                    this.addQuestion(answer.id);
                    if (
                      assessmentGuideResponse &&
                      assessmentGuideResponse.data &&
                      assessmentGuideResponse.data.configuration[answer.id]
                    ) {
                      for (var guidanceRecord of assessmentGuideResponse.data
                        .configuration[answer.id]) {
                        this.addGuidanceRecord(
                          answer.id,
                          guidanceRecord.guidance
                        );
                      }
                    } else {
                      this.addGuidanceRecord(answer.id, '');
                    }
                    question.answer = answer;
                    newAnswers.push(answer);
                  }
                  question.answers = newAnswers;
                  questionAnswers.push(question);
                }
                this.questions = questionAnswers;
              } else {
                this.questions = [];
              }
              this.isLoading = false;
            },
            (error) => {
              this.error = error;
              this.isLoading = false;
            }
          );
      });
  }

  addQuestion(fieldName: string) {
    this.answerForm.addControl(fieldName, this.fb.array([]));
  }
}
