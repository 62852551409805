<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Upload Document</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="documentForm" (ngSubmit)="submit()">
    <div class="row">
      <div class="col-md-12" *ngIf="!preSelectedFileType">
        <label class="labelText"
          >Document Type <span style="color: red">*</span></label
        >
        <mat-form-field appearance="outline">
          <mat-select formControlName="fileType" placeholder="File Type">
            <mat-option value="Clinical">Clinical</mat-option>
            <mat-option value="Discharge">Discharge</mat-option>
            <mat-option value="Paperwork">Paperwork</mat-option>
            <mat-option value="Insurance Card">Insurance Card</mat-option>
            <mat-option value="Photo ID">Photo ID</mat-option>
            <mat-option value="Release Of Information(ROI)"
              >Release Of Information(ROI)</mat-option
            >
            <mat-option value="Financial & Billing"
              >Financial & Billing</mat-option
            >
            <mat-option value="Consent">Consent</mat-option>
            <mat-option value="Care Plan">Care Plan</mat-option>
            <mat-option value="Assessment">Assessment</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <label class="labelText">Note</label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Note"
            formControlName="additionalInfo"
            name="additionalInfo"
          />
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <app-file-upload
          formControlName="image"
          [progress]="progress"
        ></app-file-upload>
      </div>

      <div class="col-md-12" style="text-align: right">
        <button
          color="primary"
          mat-raised-button
          type="submit"
          [disabled]="!documentForm.valid || processing"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
