import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { DataTablesModel, ResponseModal } from '../../../_models';
import { IAces } from '../../../_models/assessments/relationship-family/aces.model';
@Injectable({ providedIn: 'root' })
export class AcesApiService {
    constructor(private httpClient: HttpClient) { }

    getAcesDetails(
        assessmentId: string
    ): Observable<ResponseModal> {
        return this.httpClient.get<ResponseModal>(
            `${environment.apiUrl}/assessments/aces/` + assessmentId
        );
    }

    addAces(
        thisAces: IAces
    ): Observable<any> {
        const headers = { 'content-type': 'application/json' };
        return this.httpClient
            .post<IAces>(
                `${environment.apiUrl}/assessments/aces`,
                thisAces,
                { headers }
            )
            .pipe(
                catchError((err) => {
                    console.error(err);
                    throw err;
                })
            );
    }

    addGuestAces(
        thisAces: IAces
    ): Observable<any> {
        const headers = { 'content-type': 'application/json' };
        return this.httpClient
            .post<IAces>(
                `${environment.apiUrl}/guestassessments/aces`,
                thisAces,
                { headers }
            )
            .pipe(
                catchError((err) => {
                    console.error(err);
                    throw err;
                })
            );
    }

    getAcesList(
        patientId: string,
        assessmentId: string,
        pageSize: number,
        pageIndex: number
    ): Observable<DataTablesModel> {
        let params = new HttpParams();
        params = params.append('patientId', patientId);
        params = params.append('assessmentId', assessmentId);
        params = params.append('pageSize', pageSize.toString());
        params = params.append('pageIndex', pageIndex.toString());

        return this.httpClient.get<DataTablesModel>(
            `${environment.apiUrl}/assessments/aceslist`,
            { params: params }
        );
    }
}
