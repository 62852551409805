import { Component, Inject, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-eligibility-transaction-details-dialog',
  templateUrl: './eligibility-transaction-details-dialog.component.html',
  styleUrl: './eligibility-transaction-details-dialog.component.css',
})
export class EligibilityTransactionDetailsDialogComponent implements OnInit {
  public row: any;
  borderStyle: string = '5px solid red';

  displayedColumns = [
    'deductible',
    'networkType',
    'individualOrFamily',
    'benefitAmount',
    'term',
    'benefitBegin',
    'additionalInformation',
  ];
  background: ThemePalette = 'primary';
  mapOfArrays: { [key: string]: Array<any> } = {};
  activeLink = 'IND';
  healthBenefitPlanCoverageData: any[];
  emergencyServicesData: any[];
  psychotherapyData: any[];
  mentalHealthData: any[];
  physicianVisitWellData: any[];
  physicianVisitOfficeData: any[];
  professionalPhysicianData: any[];
  psychiatricData: any[];
  outpatientData: any[];
  inpatientData: any[];
  limitationsData: any[];
  physicianVisitOfficeWithSpecialistsData: { [key: string]: any[] } = {};
  physicianVisitOfficeWithoutSpecialistsData: { [key: string]: any[] } = {};

  constructor(
    public dialogRef: MatDialogRef<EligibilityTransactionDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialog: any
  ) {
    this.row = dialog.row;

    this.healthBenefitPlanCoverageData = this.filterBenefitsByServiceType(
      'Health Benefit Plan Coverage'
    );
    this.emergencyServicesData = this.filterBenefitsByServiceTypeCode('86');
    this.psychotherapyData = this.filterBenefitsByServiceTypeCode('A6');
    this.outpatientData = this.filterBenefitsByServiceTypeCode('A8');
    this.inpatientData = this.filterBenefitsByServiceTypeCode('A7');
    this.psychiatricData = this.filterBenefitsByServiceTypeCode('A4');
    this.mentalHealthData = this.filterBenefitsByServiceTypeCode('MH');
    this.limitationsData = this.filterBenefitsByName('Limitations');
    this.physicianVisitWellData = this.filterBenefitsByServiceTypeCode('BZ');
    this.physicianVisitOfficeData = this.filterBenefitsByServiceTypeCode('98');
    this.professionalPhysicianData = this.filterBenefitsByServiceTypeCode('96');

    this.regroupRecords(
      'healthBenefitPlanCoverageDataIND',
      this.healthBenefitPlanCoverageData,
      'IND'
    );

    this.regroupRecords(
      'emergencyServicesDataIND',
      this.emergencyServicesData,
      'IND'
    );
    this.regroupRecords('psychotherapyDataIND', this.psychotherapyData, 'IND');
    this.regroupRecords('outpatientDataIND', this.outpatientData, 'IND');
    this.regroupRecords('inpatientDataIND', this.inpatientData, 'IND');
    this.regroupRecords('psychiatricDataIND', this.psychiatricData, 'IND');

    this.regroupRecords('limitationsData', this.limitationsData, null);
    this.regroupRecords('mentalHealthData', this.mentalHealthData, null);
    this.regroupRecords(
      'physicianVisitWellDataIND',
      this.physicianVisitWellData,
      'IND'
    );
    this.regroupRecords(
      'physicianVisitOfficeDataIND',
      this.physicianVisitOfficeData,
      'IND'
    );
    this.regroupRecords(
      'professionalPhysicianDataIND',
      this.professionalPhysicianData,
      'IND'
    );

    this.regroupRecords(
      'healthBenefitPlanCoverageDataFAM',
      this.healthBenefitPlanCoverageData,
      'FAM'
    );
    this.regroupRecords(
      'emergencyServicesDataFAM',
      this.emergencyServicesData,
      'FAM'
    );
    this.regroupRecords('psychotherapyDataFAM', this.psychotherapyData, 'FAM');
    this.regroupRecords('outpatientDataFAM', this.outpatientData, 'FAM');
    this.regroupRecords('inpatientDataFAM', this.inpatientData, 'FAM');
    this.regroupRecords('psychiatricDataFAM', this.psychiatricData, 'FAM');
    this.regroupRecords(
      'physicianVisitWellDataFAM',
      this.physicianVisitWellData,
      'FAM'
    );
    this.regroupRecords(
      'physicianVisitOfficeDataFAM',
      this.physicianVisitOfficeData,
      'FAM'
    );
    this.regroupRecords(
      'professionalPhysicianDataFAM',
      this.professionalPhysicianData,
      'FAM'
    );

    this.physicianVisitOfficeWithSpecialistsData['IND'] = [];
    this.physicianVisitOfficeWithoutSpecialistsData['IND'] = [];

    this.mapOfArrays['physicianVisitOfficeDataIND'].forEach((item) => {
      let hasSpeciality = false;
      item.additionalInformation?.forEach((subRecord) => {
        if (subRecord.description?.includes('SPECIALIST')) {
          hasSpeciality = true;
        }
      });
      if (hasSpeciality) {
        this.physicianVisitOfficeWithSpecialistsData['IND'].push(item);
      } else {
        this.physicianVisitOfficeWithoutSpecialistsData['IND'].push(item);
      }
    });

    this.physicianVisitOfficeWithSpecialistsData['FAM'] = [];
    this.physicianVisitOfficeWithoutSpecialistsData['FAM'] = [];
    this.mapOfArrays['physicianVisitOfficeDataFAM'].forEach((item) => {
      let hasSpeciality = false;
      item.additionalInformation?.forEach((subRecord) => {
        if (subRecord.description?.includes('SPECIALIST')) {
          hasSpeciality = true;
        }
      });
      if (hasSpeciality) {
        this.physicianVisitOfficeWithSpecialistsData['FAM'].push(item);
      } else {
        this.physicianVisitOfficeWithoutSpecialistsData['FAM'].push(item);
      }
    });

    let prevName = '';
    let prevNetworkTypeCode = '';
    this.physicianVisitOfficeWithSpecialistsData['IND'].forEach(
      (element, index) => {
        if (index === 0 || element.name !== prevName) {
          element.newGroup = 'Y';
          prevName = element.name;
          prevNetworkTypeCode = element.inPlanNetworkIndicatorCode;
        }

        if (element.inPlanNetworkIndicatorCode !== prevNetworkTypeCode) {
          element.newSubGroup = 'Y';
          prevNetworkTypeCode = element.inPlanNetworkIndicatorCode;
        }
      }
    );

    prevName = '';
    prevNetworkTypeCode = '';
    this.physicianVisitOfficeWithoutSpecialistsData['IND'].forEach(
      (element, index) => {
        if (index === 0 || element.name !== prevName) {
          element.newGroup = 'Y';
          prevName = element.name;
          prevNetworkTypeCode = element.inPlanNetworkIndicatorCode;
        }

        if (element.inPlanNetworkIndicatorCode !== prevNetworkTypeCode) {
          element.newSubGroup = 'Y';
          prevNetworkTypeCode = element.inPlanNetworkIndicatorCode;
        }
      }
    );
  }

  ngOnInit(): void { }

  filterBenefitsByName(name: string) {
    return this.row.response?.benefitsInformation?.filter(
      (record) => record?.name === name
    );
  }

  filterBenefitsByServiceType(serviceType: string) {
    return this.row.response?.benefitsInformation?.filter((record) =>
      record?.serviceTypes?.includes(serviceType)
    );
  }

  filterBenefitsByServiceTypeCode(serviceTypeCode: string) {
    return this.row.response?.benefitsInformation?.filter((record) =>
      record?.serviceTypeCodes?.includes(serviceTypeCode)
    );
  }

  isNumber(value: any): boolean {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }

  regroupRecords(key: string, dataset: any[], coverageLevelCode: string) {
    this.mapOfArrays[key] = [];

    let activeCoverageRecords = dataset?.filter(
      (record) => record?.name === 'Active Coverage'
    );
    if (activeCoverageRecords && activeCoverageRecords.length > 0) {
      activeCoverageRecords[0].newGroup = 'Y';
      this.mapOfArrays[key].push(...activeCoverageRecords);
    }

    let inNetworkDeductibleRecords = dataset?.filter(
      (record) =>
        (record?.coverageLevelCode === coverageLevelCode || !record?.coverageLevelCode) &&
        record?.name === 'Deductible' &&
        (record?.inPlanNetworkIndicatorCode === 'Y' ||
          record?.inPlanNetworkIndicatorCode === 'W')
    );
    if (inNetworkDeductibleRecords && inNetworkDeductibleRecords.length > 0) {
      inNetworkDeductibleRecords[0].newGroup = 'Y';
      this.mapOfArrays[key].push(...inNetworkDeductibleRecords);
    }

    let outOfNetworkDeductibleRecords = dataset?.filter(
      (record) =>
        (record?.coverageLevelCode === coverageLevelCode || !record?.coverageLevelCode) &&
        record?.name === 'Deductible' &&
        record?.inPlanNetworkIndicatorCode === 'N'
    );
    if (
      outOfNetworkDeductibleRecords &&
      outOfNetworkDeductibleRecords.length > 0
    ) {
      outOfNetworkDeductibleRecords[0].newSubGroup = 'Y';
      this.mapOfArrays[key].push(...outOfNetworkDeductibleRecords);
    }

    let coPaymentRecords = dataset?.filter(
      (record) =>
        (record?.coverageLevelCode === coverageLevelCode || !record?.coverageLevelCode) &&
        record?.name === 'Co-Payment'
    );

    if (coPaymentRecords && coPaymentRecords.length > 0) {
      coPaymentRecords[0].newGroup = 'Y';
      this.mapOfArrays[key].push(...coPaymentRecords);
    }

    let coInsuranceRecords = dataset?.filter(
      (record) =>
        (record?.coverageLevelCode === coverageLevelCode || !record?.coverageLevelCode) &&
        record?.name === 'Co-Insurance'
    );
    if (coInsuranceRecords && coInsuranceRecords.length > 0) {
      coInsuranceRecords[0].newGroup = 'Y';
      this.mapOfArrays[key].push(...coInsuranceRecords);
    }

    let inNetworkStopLossRecords = dataset?.filter(
      (record) =>
        (record?.coverageLevelCode === coverageLevelCode || !record?.coverageLevelCode) &&
        record?.name === 'Out of Pocket (Stop Loss)' &&
        (record?.inPlanNetworkIndicatorCode === 'Y' ||
          record?.inPlanNetworkIndicatorCode === 'W')
    );
    if (inNetworkStopLossRecords && inNetworkStopLossRecords.length > 0) {
      inNetworkStopLossRecords[0].newGroup = 'Y';
      this.mapOfArrays[key].push(...inNetworkStopLossRecords);
    }

    let limitationsRecords = dataset?.filter(
      (record) => record?.name === 'Limitations'
    );
    if (limitationsRecords && limitationsRecords.length > 0) {
      limitationsRecords[0].newGroup = 'Y';
      this.mapOfArrays[key].push(...limitationsRecords);
    }


  }
}
