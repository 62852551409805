<div class="main-content" style="background-color: #eee" *ngIf="!isLoadingResults">
    <div class="container-fluid">
        <div class="container-fluid">
            <br *ngIf="!anchorTag" />
            <div class="row" *ngIf="!anchorTag">
                <div class="col-md-6">
                    <mat-card>
                        <mat-card-header>
                            <h4 class="header text-center">DSM 5 Level 1 Assessment</h4>
                        </mat-card-header>
                        <mat-card-content>
                            <div style="width: 100%">
                                <div class="chart-wrapper">
                                    <canvas baseChart height="100%" [data]="lineChartData" [options]="lineChartOptions"
                                        [legend]="true" [type]="mainChartType" [plugins]="lineChartPlugins">
                                    </canvas>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <br />
                    <mat-card>
                        <mat-card-header>
                            <h4 class="header text-center">Overall Graph</h4>
                        </mat-card-header>
                        <mat-card-content>
                            <div style="width: 100%">
                                <div class="chart-wrapper">
                                    <canvas baseChart [data]="overallChartData" [options]="overallChartOptions"
                                        [legend]="lineChartLegend" [type]="lineChartType"
                                        [plugins]="overallChartPlugins">
                                    </canvas>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
                <div class="col-md-6">
                    <mat-card class="mat-card-auto-height">
                        <mat-card-header>
                            <h4 class="header text-center">
                                Adult DSM-5-TR Self-Rated Level 1 Cross-Cutting Symptom Measure - Thresholds for further
                                inquiry
                            </h4>
                        </mat-card-header>
                        <mat-divider></mat-divider>
                        <mat-card-content>
                            <div class="row">
                                <div class="col-md-4" style="font-weight: 400">Type</div>
                                <div class="col-md-8" style="padding-top: 4px; font-weight: 400">
                                    Thresholds
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4" style="padding-top: 4px; font-weight: 300">
                                    Depression
                                </div>
                                <div class="col-md-8" style="padding-top: 4px; font-weight: 300">
                                    Mild or greater
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4" style="padding-top: 4px; font-weight: 300">
                                    Anger
                                </div>
                                <div class="col-md-8" style="padding-top: 4px; font-weight: 300">
                                    Mild or greater
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4" style="padding-top: 4px; font-weight: 300">
                                    Mania
                                </div>
                                <div class="col-md-8" style="padding-top: 4px; font-weight: 300">
                                    Mild or greater
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4" style="padding-top: 4px; font-weight: 300">
                                    Anxiety
                                </div>
                                <div class="col-md-8" style="padding-top: 4px; font-weight: 300">
                                    Mild or greater
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4" style="padding-top: 4px; font-weight: 300">
                                    Somatic Symptoms
                                </div>
                                <div class="col-md-8" style="padding-top: 4px; font-weight: 300">
                                    Mild or greater
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4" style="padding-top: 4px; font-weight: 300">
                                    Suicidal Ideation
                                </div>
                                <div class="col-md-8" style="padding-top: 4px; font-weight: 300">
                                    Slight or greater
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4" style="padding-top: 4px; font-weight: 300">
                                    Psychosis
                                </div>
                                <div class="col-md-8" style="padding-top: 4px; font-weight: 300">
                                    Slight or greater
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4" style="padding-top: 4px; font-weight: 300">
                                    Sleep Problems
                                </div>
                                <div class="col-md-8" style="padding-top: 4px; font-weight: 300">
                                    Mild or greater
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4" style="padding-top: 4px; font-weight: 300">
                                    Memory
                                </div>
                                <div class="col-md-8" style="padding-top: 4px; font-weight: 300">
                                    Mild or greater
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4" style="padding-top: 4px; font-weight: 300">
                                    Repetitive Thoughts and Behaviors
                                </div>
                                <div class="col-md-8" style="padding-top: 4px; font-weight: 300">
                                    Mild or greater
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4" style="padding-top: 4px; font-weight: 300">
                                    Dissociation
                                </div>
                                <div class="col-md-8" style="padding-top: 4px; font-weight: 300">
                                    Mild or greater
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4" style="padding-top: 4px; font-weight: 300">
                                    Personality Functioning
                                </div>
                                <div class="col-md-8" style="padding-top: 4px; font-weight: 300">
                                    Mild or greater
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4" style="padding-top: 4px; font-weight: 300">
                                    Substance Use
                                </div>
                                <div class="col-md-8" style="padding-top: 4px; font-weight: 300">
                                    Slight or greater
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <br *ngIf="!anchorTag" />
            <div *ngIf="scoreDetails.length > 0">
                <div class="row pad10" *ngIf="!anchorTag || anchorTag === 'Depression'">
                    <div class="col-md-12">
                        <mat-card class="mat-card-auto-height">
                            <mat-card-header>
                                <mat-card-title>Depression</mat-card-title>
                            </mat-card-header>
                            <mat-divider></mat-divider>
                            <mat-card-content>
                                <div class="row" style="padding:15px">
                                    <div class="col-md-6">
                                        <mat-card class="mat-card-auto-height mat-elevation-z8">
                                            <mat-card-header>
                                                <h4 class="header text-center">{{ scoreDetails[0].title }}</h4>
                                            </mat-card-header>
                                            <mat-card-content>
                                                <div style="width: 100%">
                                                    <div class="chart-wrapper">
                                                        <canvas baseChart [data]="scoreDetails[0]"
                                                            [options]="assessmentDetailChartOptions"
                                                            [legend]="lineChartLegend" [type]="lineChartType"
                                                            [plugins]="detailChartPlugins">
                                                        </canvas>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                            <small><b>Answer:</b> <i>{{ scoreDetails[0].answer }}</i></small>
                                        </mat-card>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-card class="mat-card-auto-height mat-elevation-z8">
                                            <mat-card-header>
                                                <h4 class="header text-center">{{ scoreDetails[1].title }}</h4>
                                            </mat-card-header>
                                            <mat-card-content>
                                                <div style="width: 100%">
                                                    <div class="chart-wrapper">
                                                        <canvas baseChart [data]="scoreDetails[1]"
                                                            [options]="assessmentDetailChartOptions"
                                                            [legend]="lineChartLegend" [type]="lineChartType"
                                                            [plugins]="detailChartPlugins">
                                                        </canvas>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                            <small><b>Answer:</b> <i>{{ scoreDetails[1].answer }}</i></small>
                                        </mat-card>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                <div class="row pad10" *ngIf="!anchorTag || anchorTag === 'Anger'">
                    <div class="col-md-12">
                        <mat-card class="mat-card-auto-height">
                            <mat-card-header>
                                <mat-card-title>Anger</mat-card-title>
                            </mat-card-header>
                            <mat-divider></mat-divider>
                            <mat-card-content>
                                <div class="row" style="padding:15px">
                                    <div class="col-md-6">
                                        <mat-card class="mat-card-auto-height mat-elevation-z8">
                                            <mat-card-header>
                                                <h4 class="header text-center">{{ scoreDetails[2].title }}</h4>
                                            </mat-card-header>
                                            <mat-card-content>
                                                <div style="width: 100%">
                                                    <div class="chart-wrapper">
                                                        <canvas baseChart [data]="scoreDetails[2]"
                                                            [options]="assessmentDetailChartOptions"
                                                            [legend]="lineChartLegend" [type]="lineChartType"
                                                            [plugins]="detailChartPlugins">
                                                        </canvas>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                            <small><b>Answer:</b> <i>{{ scoreDetails[2].answer }}</i></small>
                                        </mat-card>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                <div class="row pad10" *ngIf="!anchorTag || anchorTag === 'Mania'">
                    <div class="col-md-12">
                        <mat-card class="mat-card-auto-height">
                            <mat-card-header>
                                <mat-card-title>Mania</mat-card-title>
                            </mat-card-header>
                            <mat-divider></mat-divider>
                            <mat-card-content>
                                <div class="row" style="padding:15px">
                                    <div class="col-md-6">
                                        <mat-card class="mat-card-auto-height mat-elevation-z8">
                                            <mat-card-header>
                                                <h4 class="header text-center">{{ scoreDetails[3].title }}</h4>
                                            </mat-card-header>
                                            <mat-card-content>
                                                <div style="width: 100%">
                                                    <div class="chart-wrapper">
                                                        <canvas baseChart [data]="scoreDetails[3]"
                                                            [options]="assessmentDetailChartOptions"
                                                            [legend]="lineChartLegend" [type]="lineChartType"
                                                            [plugins]="detailChartPlugins">
                                                        </canvas>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                            <small><b>Answer:</b> <i>{{ scoreDetails[3].answer }}</i></small>
                                        </mat-card>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-card class="mat-card-auto-height mat-elevation-z8">
                                            <mat-card-header>
                                                <h4 class="header text-center">{{ scoreDetails[4].title }}</h4>
                                            </mat-card-header>
                                            <mat-card-content>
                                                <div style="width: 100%">
                                                    <div class="chart-wrapper">
                                                        <canvas baseChart [data]="scoreDetails[4]"
                                                            [options]="assessmentDetailChartOptions"
                                                            [legend]="lineChartLegend" [type]="lineChartType"
                                                            [plugins]="detailChartPlugins">
                                                        </canvas>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                            <small><b>Answer:</b> <i>{{ scoreDetails[4].answer }}</i></small>
                                        </mat-card>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                <div class="row pad10" *ngIf="!anchorTag || anchorTag === 'Anxiety'">
                    <div class="col-md-12">
                        <mat-card class="mat-card-auto-height">
                            <mat-card-header>
                                <mat-card-title>Anxiety</mat-card-title>
                            </mat-card-header>
                            <mat-divider></mat-divider>
                            <mat-card-content>
                                <div class="row" style="padding:15px">
                                    <div class="col-md-4">
                                        <mat-card class="mat-card-auto-height mat-elevation-z8">
                                            <mat-card-header>
                                                <h4 class="header text-center">{{ scoreDetails[5].title }}</h4>
                                            </mat-card-header>
                                            <mat-card-content>
                                                <div style="width: 100%">
                                                    <div class="chart-wrapper">
                                                        <canvas baseChart [data]="scoreDetails[5]"
                                                            [options]="assessmentDetailChartOptions"
                                                            [legend]="lineChartLegend" [type]="lineChartType"
                                                            [plugins]="detailChartPlugins">
                                                        </canvas>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                            <small><b>Answer:</b> <i>{{ scoreDetails[5].answer }}</i></small>
                                        </mat-card>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-card class="mat-card-auto-height mat-elevation-z8">
                                            <mat-card-header>
                                                <h4 class="header text-center">{{ scoreDetails[6].title }}</h4>
                                            </mat-card-header>
                                            <mat-card-content>
                                                <div style="width: 100%">
                                                    <div class="chart-wrapper">
                                                        <canvas baseChart [data]="scoreDetails[6]"
                                                            [options]="assessmentDetailChartOptions"
                                                            [legend]="lineChartLegend" [type]="lineChartType"
                                                            [plugins]="detailChartPlugins">
                                                        </canvas>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                            <small><b>Answer:</b> <i>{{ scoreDetails[6].answer }}</i></small>
                                        </mat-card>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-card class="mat-card-auto-height mat-elevation-z8">
                                            <mat-card-header>
                                                <h4 class="header text-center">{{ scoreDetails[7].title }}</h4>
                                            </mat-card-header>
                                            <mat-card-content>
                                                <div style="width: 100%">
                                                    <div class="chart-wrapper">
                                                        <canvas baseChart [data]="scoreDetails[7]"
                                                            [options]="assessmentDetailChartOptions"
                                                            [legend]="lineChartLegend" [type]="lineChartType"
                                                            [plugins]="detailChartPlugins">
                                                        </canvas>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                            <small><b>Answer:</b> <i>{{ scoreDetails[7].answer }}</i></small>
                                        </mat-card>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                <div class="row pad10" *ngIf="!anchorTag || anchorTag === 'Somatic Symptoms'">
                    <div class="col-md-12">
                        <mat-card class="mat-card-auto-height">
                            <mat-card-header>
                                <mat-card-title>Somatic Symptoms</mat-card-title>
                            </mat-card-header>
                            <mat-divider></mat-divider>
                            <mat-card-content>
                                <div class="row" style="padding:15px">
                                    <div class="col-md-6">
                                        <mat-card class="mat-card-auto-height mat-elevation-z8">
                                            <mat-card-header>
                                                <h4 class="header text-center">{{ scoreDetails[8].title }}</h4>
                                            </mat-card-header>
                                            <mat-card-content>
                                                <div style="width: 100%">
                                                    <div class="chart-wrapper">
                                                        <canvas baseChart [data]="scoreDetails[8]"
                                                            [options]="assessmentDetailChartOptions"
                                                            [legend]="lineChartLegend" [type]="lineChartType"
                                                            [plugins]="detailChartPlugins">
                                                        </canvas>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                            <small><b>Answer:</b> <i>{{ scoreDetails[8].answer }}</i></small>
                                        </mat-card>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-card class="mat-card-auto-height mat-elevation-z8">
                                            <mat-card-header>
                                                <h4 class="header text-center">{{ scoreDetails[9].title }}</h4>
                                            </mat-card-header>
                                            <mat-card-content>
                                                <div style="width: 100%">
                                                    <div class="chart-wrapper">
                                                        <canvas baseChart [data]="scoreDetails[9]"
                                                            [options]="assessmentDetailChartOptions"
                                                            [legend]="lineChartLegend" [type]="lineChartType"
                                                            [plugins]="detailChartPlugins">
                                                        </canvas>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                            <small><b>Answer:</b> <i>{{ scoreDetails[9].answer }}</i></small>
                                        </mat-card>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                <div class="row pad10" *ngIf="!anchorTag || anchorTag === 'Suicidal Ideation'">
                    <div class="col-md-12">
                        <mat-card class="mat-card-auto-height">
                            <mat-card-header>
                                <mat-card-title>Suicidal Ideation</mat-card-title>
                            </mat-card-header>
                            <mat-divider></mat-divider>
                            <mat-card-content>
                                <div class="row" style="padding:15px">
                                    <div class="col-md-6">
                                        <mat-card class="mat-card-auto-height mat-elevation-z8">
                                            <mat-card-header>
                                                <h4 class="header text-center">{{ scoreDetails[10].title }}</h4>
                                            </mat-card-header>
                                            <mat-card-content>
                                                <div style="width: 100%">
                                                    <div class="chart-wrapper">
                                                        <canvas baseChart [data]="scoreDetails[10]"
                                                            [options]="slightRiskDetailChartOptions"
                                                            [legend]="lineChartLegend" [type]="lineChartType"
                                                            [plugins]="slightRiskChartPlugins">
                                                        </canvas>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                            <small><b>Answer:</b> <i>{{ scoreDetails[10].answer }}</i></small>
                                        </mat-card>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                <div class="row pad10" *ngIf="!anchorTag || anchorTag === 'Psychosis'">
                    <div class="col-md-12">
                        <mat-card class="mat-card-auto-height">
                            <mat-card-header>
                                <mat-card-title>Psychosis</mat-card-title>
                            </mat-card-header>
                            <mat-divider></mat-divider>
                            <mat-card-content>
                                <div class="row" style="padding:15px">
                                    <div class="col-md-6">
                                        <mat-card class="mat-card-auto-height mat-elevation-z8">
                                            <mat-card-header>
                                                <h4 class="header text-center">{{ scoreDetails[11].title }}</h4>
                                            </mat-card-header>
                                            <mat-card-content>
                                                <div style="width: 100%">
                                                    <div class="chart-wrapper">
                                                        <canvas baseChart [data]="scoreDetails[11]"
                                                            [options]="slightRiskDetailChartOptions"
                                                            [legend]="lineChartLegend" [type]="lineChartType"
                                                            [plugins]="slightRiskChartPlugins">
                                                        </canvas>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                            <small><b>Answer:</b> <i>{{ scoreDetails[11].answer }}</i></small>
                                        </mat-card>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-card class="mat-card-auto-height mat-elevation-z8">
                                            <mat-card-header>
                                                <h4 class="header text-center">{{ scoreDetails[12].title }}</h4>
                                            </mat-card-header>
                                            <mat-card-content>
                                                <div style="width: 100%">
                                                    <div class="chart-wrapper">
                                                        <canvas baseChart [data]="scoreDetails[12]"
                                                            [options]="slightRiskDetailChartOptions"
                                                            [legend]="lineChartLegend" [type]="lineChartType"
                                                            [plugins]="slightRiskChartPlugins">
                                                        </canvas>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                            <small><b>Answer:</b> <i>{{ scoreDetails[12].answer }}</i></small>
                                        </mat-card>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                <div class="row pad10" *ngIf="!anchorTag || anchorTag === 'Sleep Problems'">
                    <div class="col-md-12">
                        <mat-card class="mat-card-auto-height">
                            <mat-card-header>
                                <mat-card-title>Sleep Problems</mat-card-title>
                            </mat-card-header>
                            <mat-divider></mat-divider>
                            <mat-card-content>
                                <div class="row" style="padding:15px">
                                    <div class="col-md-6">
                                        <mat-card class="mat-card-auto-height mat-elevation-z8">
                                            <mat-card-header>
                                                <h4 class="header text-center">{{ scoreDetails[13].title }}</h4>
                                            </mat-card-header>
                                            <mat-card-content>
                                                <div style="width: 100%">
                                                    <div class="chart-wrapper">
                                                        <canvas baseChart [data]="scoreDetails[13]"
                                                            [options]="assessmentDetailChartOptions"
                                                            [legend]="lineChartLegend" [type]="lineChartType"
                                                            [plugins]="detailChartPlugins">
                                                        </canvas>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                            <small><b>Answer:</b> <i>{{ scoreDetails[13].answer }}</i></small>
                                        </mat-card>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                <div class="row pad10" *ngIf="!anchorTag || anchorTag === 'Memory'">
                    <div class="col-md-12">
                        <mat-card class="mat-card-auto-height">
                            <mat-card-header>
                                <mat-card-title>Memory</mat-card-title>
                            </mat-card-header>
                            <mat-divider></mat-divider>
                            <mat-card-content>
                                <div class="row" style="padding:15px">
                                    <div class="col-md-6">
                                        <mat-card class="mat-card-auto-height mat-elevation-z8">
                                            <mat-card-header>
                                                <h4 class="header text-center">{{ scoreDetails[14].title }}</h4>
                                            </mat-card-header>
                                            <mat-card-content>
                                                <div style="width: 100%">
                                                    <div class="chart-wrapper">
                                                        <canvas baseChart [data]="scoreDetails[14]"
                                                            [options]="assessmentDetailChartOptions"
                                                            [legend]="lineChartLegend" [type]="lineChartType"
                                                            [plugins]="detailChartPlugins">
                                                        </canvas>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                            <small><b>Answer:</b> <i>{{ scoreDetails[14].answer }}</i></small>
                                        </mat-card>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                <div class="row pad10" *ngIf="!anchorTag || anchorTag === 'Repetitive Thoughts and Behaviors'">
                    <div class="col-md-12">
                        <mat-card class="mat-card-auto-height">
                            <mat-card-header>
                                <mat-card-title>Repetitive Thoughts and Behaviors</mat-card-title>
                            </mat-card-header>
                            <mat-divider></mat-divider>
                            <mat-card-content>
                                <div class="row" style="padding:15px">
                                    <div class="col-md-6">
                                        <mat-card class="mat-card-auto-height mat-elevation-z8">
                                            <mat-card-header>
                                                <h4 class="header text-center">{{ scoreDetails[15].title }}</h4>
                                            </mat-card-header>
                                            <mat-card-content>
                                                <div style="width: 100%">
                                                    <div class="chart-wrapper">
                                                        <canvas baseChart [data]="scoreDetails[15]"
                                                            [options]="assessmentDetailChartOptions"
                                                            [legend]="lineChartLegend" [type]="lineChartType"
                                                            [plugins]="detailChartPlugins">
                                                        </canvas>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                            <small><b>Answer:</b> <i>{{ scoreDetails[15].answer }}</i></small>
                                        </mat-card>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-card class="mat-card-auto-height mat-elevation-z8">
                                            <mat-card-header>
                                                <h4 class="header text-center">{{ scoreDetails[16].title }}</h4>
                                            </mat-card-header>
                                            <mat-card-content>
                                                <div style="width: 100%">
                                                    <div class="chart-wrapper">
                                                        <canvas baseChart [data]="scoreDetails[16]"
                                                            [options]="assessmentDetailChartOptions"
                                                            [legend]="lineChartLegend" [type]="lineChartType"
                                                            [plugins]="detailChartPlugins">
                                                        </canvas>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                            <small><b>Answer:</b> <i>{{ scoreDetails[16].answer }}</i></small>
                                        </mat-card>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                <div class="row pad10" *ngIf="!anchorTag || anchorTag === 'Dissociation'">
                    <div class="col-md-12">
                        <mat-card class="mat-card-auto-height">
                            <mat-card-header>
                                <mat-card-title>Dissociation</mat-card-title>
                            </mat-card-header>
                            <mat-divider></mat-divider>
                            <mat-card-content>
                                <div class="row" style="padding:15px">
                                    <div class="col-md-6">
                                        <mat-card class="mat-card-auto-height mat-elevation-z8">
                                            <mat-card-header>
                                                <h4 class="header text-center">{{ scoreDetails[17].title }}</h4>
                                            </mat-card-header>
                                            <mat-card-content>
                                                <div style="width: 100%">
                                                    <div class="chart-wrapper">
                                                        <canvas baseChart [data]="scoreDetails[17]"
                                                            [options]="assessmentDetailChartOptions"
                                                            [legend]="lineChartLegend" [type]="lineChartType"
                                                            [plugins]="detailChartPlugins">
                                                        </canvas>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                            <small><b>Answer:</b> <i>{{ scoreDetails[17].answer }}</i></small>
                                        </mat-card>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                <div class="row pad10" *ngIf="!anchorTag || anchorTag === 'Personality Functioning'">
                    <div class="col-md-12">
                        <mat-card class="mat-card-auto-height">
                            <mat-card-header>
                                <mat-card-title>Personality Functioning</mat-card-title>
                            </mat-card-header>
                            <mat-divider></mat-divider>
                            <mat-card-content>
                                <div class="row" style="padding:15px">
                                    <div class="col-md-6">
                                        <mat-card class="mat-card-auto-height mat-elevation-z8">
                                            <mat-card-header>
                                                <h4 class="header text-center">{{ scoreDetails[18].title }}</h4>
                                            </mat-card-header>
                                            <mat-card-content>
                                                <div style="width: 100%">
                                                    <div class="chart-wrapper">
                                                        <canvas baseChart [data]="scoreDetails[18]"
                                                            [options]="assessmentDetailChartOptions"
                                                            [legend]="lineChartLegend" [type]="lineChartType"
                                                            [plugins]="detailChartPlugins">
                                                        </canvas>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                            <small><b>Answer:</b> <i>{{ scoreDetails[18].answer }}</i></small>
                                        </mat-card>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-card class="mat-card-auto-height mat-elevation-z8">
                                            <mat-card-header>
                                                <h4 class="header text-center">{{ scoreDetails[19].title }}</h4>
                                            </mat-card-header>
                                            <mat-card-content>
                                                <div style="width: 100%">
                                                    <div class="chart-wrapper">
                                                        <canvas baseChart [data]="scoreDetails[19]"
                                                            [options]="assessmentDetailChartOptions"
                                                            [legend]="lineChartLegend" [type]="lineChartType"
                                                            [plugins]="detailChartPlugins">
                                                        </canvas>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                            <small><b>Answer:</b> <i>{{ scoreDetails[19].answer }}</i></small>
                                        </mat-card>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                <div class="row pad10" *ngIf="!anchorTag || anchorTag === 'Substance Use'">
                    <div class="col-md-12">
                        <mat-card class="mat-card-auto-height">
                            <mat-card-header>
                                <mat-card-title>Substance Use</mat-card-title>
                            </mat-card-header>
                            <mat-divider></mat-divider>
                            <mat-card-content>
                                <div class="row" style="padding:15px">
                                    <div class="col-md-4">
                                        <mat-card class="mat-card-auto-height mat-elevation-z8">
                                            <mat-card-header>
                                                <h4 class="header text-center">{{ scoreDetails[20].title }}</h4>
                                            </mat-card-header>
                                            <mat-card-content>
                                                <div style="width: 100%">
                                                    <div class="chart-wrapper">
                                                        <canvas baseChart [data]="scoreDetails[20]"
                                                            [options]="slightRiskDetailChartOptions"
                                                            [legend]="lineChartLegend" [type]="lineChartType"
                                                            [plugins]="slightRiskChartPlugins">
                                                        </canvas>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                            <small><b>Answer:</b> <i>{{ scoreDetails[20].answer }}</i></small>
                                        </mat-card>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-card class="mat-card-auto-height mat-elevation-z8">
                                            <mat-card-header>
                                                <h4 class="header text-center">{{ scoreDetails[21].title }}</h4>
                                            </mat-card-header>
                                            <mat-card-content>
                                                <div style="width: 100%">
                                                    <div class="chart-wrapper">
                                                        <canvas baseChart [data]="scoreDetails[21]"
                                                            [options]="slightRiskDetailChartOptions"
                                                            [legend]="lineChartLegend" [type]="lineChartType"
                                                            [plugins]="slightRiskChartPlugins">
                                                        </canvas>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                            <small><b>Answer:</b> <i>{{ scoreDetails[21].answer }}</i></small>
                                        </mat-card>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-card class="mat-card-auto-height mat-elevation-z8">
                                            <mat-card-header>
                                                <h4 class="header text-center">{{ scoreDetails[22].title }}</h4>
                                            </mat-card-header>
                                            <mat-card-content>
                                                <div style="width: 100%">
                                                    <div class="chart-wrapper">
                                                        <canvas baseChart [data]="scoreDetails[22]"
                                                            [options]="slightRiskDetailChartOptions"
                                                            [legend]="lineChartLegend" [type]="lineChartType"
                                                            [plugins]="slightRiskChartPlugins">
                                                        </canvas>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                            <small><b>Answer:</b> <i>{{ scoreDetails[22].answer }}</i></small>
                                        </mat-card>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>