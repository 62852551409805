<div class="row">
  <div class="col-md-12">
    <p style="font-size: 16px; font-weight: 450">
      <a routerLink="/" routerLinkActive="active">
        <em
          class="material-icons text_align-center"
          style="font-size: 18px; margin-top: -3px"
          >home</em
        >
      </a>
      &nbsp; / &nbsp;
      <span style="color: dimgray">Account Settings </span>
    </p>
  </div>
</div>

<br />

<div class="row" style="margin-bottom: 30px">
  <div class="col-md-12" style="margin-bottom: 20px">
    <label class="labelHeader">Personal Details</label>
  </div>

  <div class="col-md-12">
    <div class="gridContainer">
      <span class="headerText"> First Name </span>
      <span class="headerContent"> {{ firstName }} </span>
    </div>
    <hr />
    <div class="gridContainer">
      <span class="headerText"> Last Name </span>
      <span class="headerContent"> {{ lastName }} </span>
    </div>
    <hr />
    <div class="gridContainer">
      <span class="headerText"> Email Address </span>
      <span class="headerContent"> {{ emailAddress }}</span>
    </div>
    <hr />
    <div class="gridContainer">
      <span class="headerText"> Phone Number</span>
      <span
        class="headerContent"
        *ngIf="!edit"
        style="display: flex; align-items: center; flex-wrap: wrap"
      >
        <span *ngIf="profileForm.get('phoneNumber').value">
          {{ profileForm.get('phoneNumber').value | phoneFormat }}
        </span>

        <div
          id="editButton"
          class="material-icons"
          style="
            color: gray;
            font-size: 16px;
            cursor: pointer;
            opacity: 0.5;
            margin-left: 15px;
          "
          type="button"
          (click)="edit = !edit"
        >
          edit
        </div>
      </span>

      <span
        *ngIf="edit"
        style="display: flex; align-items: center; flex-wrap: wrap"
      >
        <!-- Update Form -->
        <form [formGroup]="profileForm" style="margin: 0">
          <input
            autofocus
            formControlName="phoneNumber"
            name="phoneNumber"
            required
            style="border: none"
          />
        </form>
        <!-- Buttons -->
        <button
          type="button"
          mat-raised-button
          color="primary"
          [disabled]="!profileForm.valid || processing"
          (click)="editNumber()"
        >
          Save
        </button>
        <button mat-raised-button type="button" (click)="restoreNumber()">
          Restore
        </button>
      </span>
    </div>
  </div>
</div>
