import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ClaimsAPIService,
  DateTimeZoneService,
  ToastMessageService,
} from 'src/app/_services';

@Component({
  selector: 'app-secondary-claims-history',
  templateUrl: './secondary-claims-history.component.html',
  styleUrl: './secondary-claims-history.component.css',
})
export class SecondaryClaimsHistoryComponent implements OnInit {
  secondaryClaimId: string;
  parent: string;
  prevTab: string;

  isLoadingDetails: boolean = false;
  isLoadingResults: boolean = false;
  currentTimezone: string;

  secondaryClaimDetails: any;
  secondaryClaimsHistory = [];

  selectedViewHistory: any;
  viewHistorySelected: boolean = false;
  displayedColumns = ['date', 'message', 'actions'];

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private dateTimeZoneService: DateTimeZoneService,
    private claimsApiService: ClaimsAPIService,
    private toastMessageService: ToastMessageService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit(): void {
    this.secondaryClaimId =
      this.route.snapshot.paramMap.get('secondaryClaimId');
    this.route.queryParamMap.subscribe((param) => {
      this.prevTab = param.get('prevTab');
      this.parent = param.get('parent');
    });

    // Load Claim Details
    this.loadSecondaryClaimDetails();

    // Load Claim Transactions
    this.loadClaimTransactions();
  }

  loadSecondaryClaimDetails() {
    this.isLoadingDetails = true;
    this.claimsApiService.getClaimDetails(this.secondaryClaimId).subscribe({
      next: (response) => {
        // Get claim details
        this.secondaryClaimDetails = response.data;
        this.isLoadingDetails = false;
      },
      error: (error) => {
        this.isLoadingDetails = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to retrieve claim details'
        );
      },
    });
  }

  loadClaimTransactions() {
    this.isLoadingResults = true;

    this.claimsApiService
      .getClaimTransactionHistory(this.secondaryClaimId)
      .subscribe({
        next: (response) => {
          this.isLoadingResults = false;
          if (response && response?.items) {
            this.secondaryClaimsHistory = response.items;
          }
        },
        error: (error) => {
          this.isLoadingResults = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load claims history'
          );
        },
      });
  }

  viewCurrentClaim() {
    this.router.navigate(
      [`/claims/secondary/${this.secondaryClaimDetails.primaryClaimId}`],
      {
        queryParams: {
          parent: this.parent,
          prevTab: this.prevTab,
        },
      }
    );
  }

  viewHistoryDetails(claim) {
    this.selectedViewHistory = claim;
    this.viewHistorySelected = true;
    window.scrollTo(0, 0);
  }

  cancelViewClaimHistory() {
    this.selectedViewHistory = null;
    this.viewHistorySelected = false;
  }
}
