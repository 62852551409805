<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle *ngIf="action === 'ADD'">Add Referring Provider</p>
    <p matDialogTitle *ngIf="action === 'EDIT'">Edit Referring Provider</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <!-- If the option is add, it is a stepper -->
  <form [formGroup]="userForm">
    <!-- Only If the user does not exist -->
    <div class="row">
      <!-- NPI LookUp -->
      <div
        class="col-md-12"
        style="margin-bottom: 12px; margin-top: 5px; text-align: right"
      >
        <button
          mat-raised-button
          color="primary"
          type="button"
          (click)="lookupNPI('NEW')"
        >
          <i class="material-icons" style="font-size: 18px"> search </i>
          NPI Lookup
        </button>
      </div>

      <!-- NPI & Taxonomy Information -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <label class="labelHeader">
          <mat-icon style="margin-right: 10px">pin</mat-icon>
          NPI & Taxonomy Code
        </label>
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-10">
                  <label class="labelText">
                    Individual NPI <span style="color: red">*</span></label
                  >
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      placeholder="Individual NPI"
                      formControlName="individualNPI"
                      name="individualNPI"
                      minlength="10"
                      maxlength="10"
                      required
                    />
                    <mat-error
                      *ngIf="userForm.controls['individualNPI'].invalid"
                    >
                      Please enter a valid Individual NPI
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-2" style="align-self: center">
                  <button
                    mat-icon-button
                    color="primary"
                    type="button"
                    (click)="lookupNPI('SEARCH')"
                    [disabled]="
                      userForm.controls['individualNPI'].invalid ||
                      userForm.controls['individualNPI'].value === '' ||
                      userForm.controls['individualNPI'].value === null
                    "
                    matTooltip="Search NPI Registry"
                    [matTooltipPosition]="'above'"
                  >
                    <mat-icon>search</mat-icon>
                  </button>
                </div>
              </div>
            </div>

            <div class="col-md-6" style="margin-bottom: 12px">
              <label class="labelText"> Taxonomy Code </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Taxonomy Code"
                  formControlName="taxonomyCode"
                  name="taxonomyCode"
                />
                <mat-error *ngIf="userForm.controls['taxonomyCode'].invalid">
                  Please enter the Taxonomy Code
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- General Information -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <label class="labelHeader">
          <mat-icon style="margin-right: 10px">person</mat-icon> General
          Information
        </label>
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-4">
              <label class="labelText">
                First Name <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="First Name"
                  formControlName="firstName"
                  name="firstName"
                  required
                />
                <mat-error *ngIf="userForm.controls['firstName'].invalid">
                  First name is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText"> Middle Name </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder=""
                  formControlName="middleName"
                  name="middleName"
                />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">
                Last Name <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Last Name"
                  formControlName="lastName"
                  name="lastName"
                  required
                />
                <mat-error *ngIf="userForm.controls['lastName'].invalid">
                  Last name is required
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-4">
              <label class="labelText"> Prefix</label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder=""
                  formControlName="prefix"
                  name="prefix"
                />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText"> Credential </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder=""
                  formControlName="credential"
                  name="credential"
                />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">
                Gender <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="gender">
                  <mat-option [value]="r.id" *ngFor="let r of genderList">
                    {{ r.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-4">
              <label class="labelText"> Date of Birth</label>
              <mat-form-field appearance="outline">
                <input
                  formControlName="dateOfBirth"
                  matInput
                  [matDatepicker]="dobDatePicker"
                  [max]="maxDate"
                  placeholder="MM/DD/YYYY"
                  autoSlashDate
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="dobDatePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #dobDatePicker></mat-datepicker>

                <mat-error *ngIf="userForm.controls['dateOfBirth'].invalid">
                  Please enter a valid Date of Birth
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-4">
              <label class="labelText">
                Status <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="status" placeholder="Status">
                  <mat-option value="Active">Active</mat-option>
                  <mat-option value="Inactive">Inactive</mat-option>
                </mat-select>
                <mat-error *ngIf="userForm.controls['status'].invalid">
                  Please select a status
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Contact Information -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <label class="labelHeader">
          <mat-icon style="margin-right: 10px">call</mat-icon>
          Contact Information
        </label>
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-12">
              <label class="labelText"> Email </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Email Address"
                  formControlName="emailAddress"
                  name="emailAddress"
                />
                <mat-error *ngIf="userForm.controls['emailAddress'].invalid">
                  Please enter a valid email
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-4">
              <label class="labelText">
                Phone Type <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="phoneType"
                  placeholder="Phone Type"
                >
                  <mat-option value="Cell">Cell Phone</mat-option>
                  <mat-option value="Home">Home Phone</mat-option>
                  <mat-option value="Work">Work Phone</mat-option>
                </mat-select>
                <mat-error *ngIf="userForm.controls['phoneType'].invalid">
                  Please select the phone type
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">
                Phone Number <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Phone Number"
                  formControlName="phoneNumber"
                  name="phoneNumber"
                  required
                />
                <mat-error *ngIf="userForm.controls['phoneNumber'].invalid">
                  Please enter a valid phone number
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText"> Fax Number </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="faxNumber" name="faxNumber" />
                <mat-error *ngIf="userForm.controls['faxNumber'].invalid">
                  Please enter a valid fax number
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Address Information -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <label class="labelHeader">
          <mat-icon style="margin-right: 10px">home</mat-icon>
          Address
        </label>
        <mat-card appearance="raised" class="relative mat-elevation-z8">
          <div class="row">
            <div class="col-md-8">
              <label class="labelText"> Address 1 </label>
              <mat-form-field appearance="outline">
                <input
                  [options]="options"
                  ngx-gp-autocomplete
                  #placesRef="ngx-places"
                  (onAddressChange)="handleAddressChange($event)"
                  matInput
                  formControlName="addressLine1"
                  name="addressLine1"
                />

                <mat-error *ngIf="userForm.controls['addressLine1'].invalid">
                  Please enter the address line 1
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">Address 2</label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="addressLine2"
                  name="addressLine2"
                />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText"> City </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="city" name="city" />
                <mat-error *ngIf="userForm.controls['city'].invalid">
                  Please enter the city
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">State </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="state" placeholder="State">
                  <mat-option value=""></mat-option>
                  <mat-option
                    [value]="state.id"
                    *ngFor="let state of statesList"
                  >
                    {{ state.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="userForm.controls['state'].invalid">
                  Please select the state
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">Zip </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="zip" name="zip" />
                <mat-error *ngIf="userForm.controls['zip'].invalid">
                  Please enter a valid zip
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>
    </div>

    <!-- Back button -->
    <div
      style="
        display: flex;
        justify-content: right;
        margin-top: 20px;
        margin-bottom: 20px;
      "
    >
      <button
        class="btn btn-primary"
        mat-raised-button
        type="button"
        [disabled]="!userForm.valid || processing"
        (click)="submitForm()"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </div>
  </form>
</div>
