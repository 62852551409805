import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { DomSanitizer } from '@angular/platform-browser';
import { DataTablesModel, IDocument, Patient } from 'src/app/_models';
import { DocumentHttpService, ToastMessageService } from 'src/app/_services';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { AddMemberDocumentComponent } from './add/add-member-document/add-member-document.component';
import { PreviewMemberDocumentComponent } from './dialogs/preview-member-document/preview-member-document.component';
import { RequestIntakeDocumentsComponent } from './request-intake-documents/request-intake-documents.component';
@Component({
  selector: 'app-member-documents',
  templateUrl: './member-documents.component.html',
  styleUrls: ['./member-documents.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class MemberDocumentsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() patient: Patient;

  @Output() output = new EventEmitter<any>();

  searchDocumentName: string = '';
  displayedColumns = [
    'fileName',
    'fileType',
    'uploadedDate',
    'uploadedBy',
    'actions',
  ];
  previewRow: any = null;
  data: DataTablesModel = {} as DataTablesModel;
  attachments: DataTablesModel = {} as DataTablesModel;
  isLoadingResults = false;
  apiError = false;
  supportedFileTypes: string[] = ['pdf'];
  selectedRow: any = null;
  loadingAttachments = false;

  constructor(
    public dialog: MatDialog,
    public documentApiService: DocumentHttpService,
    private toastMessageService: ToastMessageService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.data.per_page = 10;
    this.data.page = 0;
    this.attachments.per_page = 100;
    this.attachments.page = 0;
    this.loadDocuments();
  }

  isSupportedFileType(fileType: string): boolean {
    return this.supportedFileTypes.includes(fileType.toLowerCase());
  }

  openPreviewDialog(row: any) {
    this.isLoadingResults = true;
    this.documentApiService
      .downloadMemberDocument(row.patientId, row.id)
      .subscribe({
        next: (res) => {
          this.isLoadingResults = false;
          this.dialog.open(PreviewMemberDocumentComponent, {
            minWidth: '35vw',
            minHeight: '20vh',
            disableClose: true,
            data: { pdfSrc: res },
          });
        },
        error: (error) => {
          this.isLoadingResults = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Something went wrong. Please try again.'
          );
        },
      });
  }

  addDocument() {
    const dialogRef = this.dialog.open(AddMemberDocumentComponent, {
      data: {
        action: 'ADD',
        Document: null,
        patientId: this.patient.id,
      },
      minWidth: '35vw',
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((fileType) => {
      if (fileType) {
        this.loadDocuments();
        this.toastMessageService.displaySuccessMessage(
          'New document has been successfully uploaded.'
        );
      }
    });
  }

  deleteDocument(Document: IDocument) {
    const dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to delete this document?',
      },
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.isLoadingResults = true;
        this.documentApiService
          .deleteDocument(this.patient.id, Document)
          .subscribe({
            next: () => {
              this.isLoadingResults = false;
              if (this.data.items.length == 1) {
                this.data.page = this.data.page - 1;
                if (this.data.page < 0) {
                  this.data.page = 0;
                }
              }
              this.toastMessageService.displaySuccessMessage(
                'Document has been deleted.'
              );
              this.loadDocuments();
            },
            error: (error) => {
              this.isLoadingResults = false;
            },
          });
      } else {
        this.isLoadingResults = false;
      }
    });
  }

  downloadFile(patientId: string, fileId: string) {
    this.isLoadingResults = true;

    this.documentApiService.downloadMemberDocument(patientId, fileId).subscribe(
      (response: any) => {
        this.isLoadingResults = false;
        let blob: any = new Blob([response], { type: response.type });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      },
      (error) => {
        this.isLoadingResults = false;
      }
    );
  }

  public loadDocuments() {
    this.isLoadingResults = true;
    this.apiError = false;

    this.documentApiService
      .getMemberDocuments(
        this.patient.id,
        this.searchDocumentName,
        this.data.per_page,
        this.data.page
      )
      .subscribe({
        next: (response) => {
          this.isLoadingResults = false;
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          }
          if (response == null) {
            this.data.items = [];
            this.data.total = 0;
          }
        },
        error: (error) => {
          this.apiError = true;
          this.isLoadingResults = false;
        },
      });
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadDocuments();
  }

  requestIntakeDocument() {
    const dialogRef = this.dialog.open(RequestIntakeDocumentsComponent, {
      data: {
        patient: this.patient,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '45vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.output.emit({
          eventType: 'RELOAD_ALERTS',
        });
      }
    });
  }

  searchChanged() {
    this.data.page = 0;
    this.loadDocuments();
  }

  toggleRow(row: any) {
    row.showAttachments = !row.showAttachments;
    this.loadAttachments(row.requestId);
  }

  public loadAttachments(parentId: string) {
    this.loadingAttachments = true;

    this.documentApiService
      .getDocumentAttachments(this.patient.id, parentId, 100, 0)
      .subscribe({
        next: (response) => {
          this.loadingAttachments = false;
          if (response && response.items) {
            this.attachments.items = response.items;
            this.attachments.total = response.total;
          }
          if (response == null) {
            this.attachments.items = [];
            this.attachments.total = 0;
          }
        },
        error: (error) => {
          this.loadingAttachments = false;
        },
      });
  }
}
