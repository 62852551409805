import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IBpsaClinicalCulturalEtc } from 'src/app/_models/session/clinical/bpsa-clinical-cultural-etc.model';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class BpsaClinicalCulturalEtcApiService {
    constructor(private httpClient: HttpClient) { }
    updateBpsaClinicalCulturalEtc(thisBpsaClinicalCulturalEtc: IBpsaClinicalCulturalEtc): Observable<IBpsaClinicalCulturalEtc> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.put<IBpsaClinicalCulturalEtc>(`${environment.apiUrl}/bpsaclinicalculturaletc`, thisBpsaClinicalCulturalEtc, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }
}