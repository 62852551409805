import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ITherapySession, Patient } from 'src/app/_models';
import { TherapySessionService } from 'src/app/_services';

@Component({
  selector: 'app-group-session-assessment',
  templateUrl: './group-session-assessment.component.html',
  styleUrl: './group-session-assessment.component.css',
})
export class GroupSessionAssessmentComponent implements OnInit {
  sessionDetails: ITherapySession;
  selectedMemberId: string;
  patient: Patient;

  hasMemberAssessmentAddAccess: boolean;
  hasMemberAssessmentEditAccess: boolean;
  hasMemberAssessmentDeleteAccess: boolean;
  hasAssessmentChartsViewAccess: boolean;
  hasAssessmentGuidanceViewAccess: boolean;
  hasAssessmentGuidanceEditAccess: boolean;
  hasAssessmentSummaryViewAccess: boolean;
  hasAssessmentSummaryEditAccess: boolean;
  hasAssessmentTakeAddAccess: boolean;

  constructor(
    public dialogRef: MatDialogRef<GroupSessionAssessmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public therapysessionApiService: TherapySessionService
  ) {
    this.sessionDetails = data.sessionDetails;
    this.selectedMemberId = data.selectedMemberId;
    this.patient = data.patient;
    this.hasMemberAssessmentAddAccess = data.hasMemberAssessmentAddAccess;
    this.hasMemberAssessmentEditAccess = data.hasMemberAssessmentEditAccess;
    this.hasMemberAssessmentDeleteAccess = data.hasMemberAssessmentDeleteAccess;
    this.hasAssessmentChartsViewAccess = data.hasAssessmentChartsViewAccess;
    this.hasAssessmentGuidanceViewAccess = data.hasAssessmentGuidanceViewAccess;
    this.hasAssessmentGuidanceEditAccess = data.hasAssessmentGuidanceEditAccess;
    this.hasAssessmentSummaryViewAccess = data.hasAssessmentSummaryViewAccess;
    this.hasAssessmentSummaryEditAccess = data.hasAssessmentSummaryEditAccess;
    this.hasAssessmentTakeAddAccess = data.hasAssessmentTakeAddAccess;
  }

  ngOnInit(): void {}
}
