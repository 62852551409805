<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Shopping Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="col-md-12" style="padding: 0">
  <div
    *ngIf="assessmentId && !isPatient"
    class="row"
    style="
      margin-bottom: 20px;
      display: flex;
      align-content: center;
      flex-direction: column;
      font-weight: 450;
    "
  >
    Risk Score
    <button
      mat-fab
      color="primary"
      aria-label="Score"
      style="margin-left: 10px"
    >
      {{ riskScore }}
    </button>
  </div>

  <form
    [formGroup]="shoppingaddictionForm"
    novalidate
    (ngSubmit)="onShoppingAddictionFormSubmit()"
  >
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >1. Shopping/buying is the most important thing in my life.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [disabled]="disableFormElements"
          formControlName="shoppingIsMostImportant"
        >
          <mat-button-toggle value="Completely Disagree"
            >Completely Disagree</mat-button-toggle
          >
          <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
          <mat-button-toggle value="Neither Disagree Nor Agree"
            >Neither Disagree Nor Agree</mat-button-toggle
          >
          <mat-button-toggle value="Agree">Agree</mat-button-toggle>
          <mat-button-toggle value="Completely Agree"
            >Completely Agree</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >2. I think about shopping/buying things all the time.
      </label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [disabled]="disableFormElements"
          formControlName="thinkAboutShoppingAllTheTime"
        >
          <mat-button-toggle value="Completely Disagree"
            >Completely Disagree</mat-button-toggle
          >
          <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
          <mat-button-toggle value="Neither Disagree Nor Agree"
            >Neither Disagree Nor Agree</mat-button-toggle
          >
          <mat-button-toggle value="Agree">Agree</mat-button-toggle>
          <mat-button-toggle value="Completely Agree"
            >Completely Agree</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >3. I spend a lot of time thinking of or planning
        shopping/buying.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [disabled]="disableFormElements"
          formControlName="planningOrThinkingOfShopping"
        >
          <mat-button-toggle value="Completely Disagree"
            >Completely Disagree</mat-button-toggle
          >
          <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
          <mat-button-toggle value="Neither Disagree Nor Agree"
            >Neither Disagree Nor Agree</mat-button-toggle
          >
          <mat-button-toggle value="Agree">Agree</mat-button-toggle>
          <mat-button-toggle value="Completely Agree"
            >Completely Agree</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >4. Thoughts about shopping/buying keep popping in my head.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [disabled]="disableFormElements"
          formControlName="thoughtsAboutShopping"
        >
          <mat-button-toggle value="Completely Disagree"
            >Completely Disagree</mat-button-toggle
          >
          <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
          <mat-button-toggle value="Neither Disagree Nor Agree"
            >Neither Disagree Nor Agree</mat-button-toggle
          >
          <mat-button-toggle value="Agree">Agree</mat-button-toggle>
          <mat-button-toggle value="Completely Agree"
            >Completely Agree</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >5. I shop in order to feel better.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [disabled]="disableFormElements"
          formControlName="shopToFeelBetter"
        >
          <mat-button-toggle value="Completely Disagree"
            >Completely Disagree</mat-button-toggle
          >
          <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
          <mat-button-toggle value="Neither Disagree Nor Agree"
            >Neither Disagree Nor Agree</mat-button-toggle
          >
          <mat-button-toggle value="Agree">Agree</mat-button-toggle>
          <mat-button-toggle value="Completely Agree"
            >Completely Agree</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >6. I shop/buy things in order to change my mood.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [disabled]="disableFormElements"
          formControlName="shopToChangeTheMood"
        >
          <mat-button-toggle value="Completely Disagree"
            >Completely Disagree</mat-button-toggle
          >
          <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
          <mat-button-toggle value="Neither Disagree Nor Agree"
            >Neither Disagree Nor Agree</mat-button-toggle
          >
          <mat-button-toggle value="Agree">Agree</mat-button-toggle>
          <mat-button-toggle value="Completely Agree"
            >Completely Agree</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >7. I shop/buy things in order to forget about personal problems.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [disabled]="disableFormElements"
          formControlName="forgetAboutPersonalProblems"
        >
          <mat-button-toggle value="Completely Disagree"
            >Completely Disagree</mat-button-toggle
          >
          <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
          <mat-button-toggle value="Neither Disagree Nor Agree"
            >Neither Disagree Nor Agree</mat-button-toggle
          >
          <mat-button-toggle value="Agree">Agree</mat-button-toggle>
          <mat-button-toggle value="Completely Agree"
            >Completely Agree</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >8. I shop/buy things in order to reduce feelings of guilt, anxiety,
        helplessness, loneliness, and/or depression.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [disabled]="disableFormElements"
          formControlName="buyThingsInOrderToReduceFeelings"
        >
          <mat-button-toggle value="Completely Disagree"
            >Completely Disagree</mat-button-toggle
          >
          <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
          <mat-button-toggle value="Neither Disagree Nor Agree"
            >Neither Disagree Nor Agree</mat-button-toggle
          >
          <mat-button-toggle value="Agree">Agree</mat-button-toggle>
          <mat-button-toggle value="Completely Agree"
            >Completely Agree</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >9. I shop/buy so much that it negatively affects my daily obligations
        (e.g., school and work).</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [disabled]="disableFormElements"
          formControlName="negativelyAffectsYourActivities"
        >
          <mat-button-toggle value="Completely Disagree"
            >Completely Disagree</mat-button-toggle
          >
          <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
          <mat-button-toggle value="Neither Disagree Nor Agree"
            >Neither Disagree Nor Agree</mat-button-toggle
          >
          <mat-button-toggle value="Agree">Agree</mat-button-toggle>
          <mat-button-toggle value="Completely Agree"
            >Completely Agree</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >10. I give less priority to hobbies, leisure activities, job/studies,
        or exercise because of shopping/buying.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [disabled]="disableFormElements"
          formControlName="giveLessPriorityToOtherThings"
        >
          <mat-button-toggle value="Completely Disagree"
            >Completely Disagree</mat-button-toggle
          >
          <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
          <mat-button-toggle value="Neither Disagree Nor Agree"
            >Neither Disagree Nor Agree</mat-button-toggle
          >
          <mat-button-toggle value="Agree">Agree</mat-button-toggle>
          <mat-button-toggle value="Completely Agree"
            >Completely Agree</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >11. I have ignored love partner, family, and friends because of
        shopping/buying.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [disabled]="disableFormElements"
          formControlName="ignoredPeopleBecauseOfShopping"
        >
          <mat-button-toggle value="Completely Disagree"
            >Completely Disagree</mat-button-toggle
          >
          <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
          <mat-button-toggle value="Neither Disagree Nor Agree"
            >Neither Disagree Nor Agree</mat-button-toggle
          >
          <mat-button-toggle value="Agree">Agree</mat-button-toggle>
          <mat-button-toggle value="Completely Agree"
            >Completely Agree</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >12. I often end up in arguments with other because of
        shopping/buying.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [disabled]="disableFormElements"
          formControlName="argumentsWithOthers"
        >
          <mat-button-toggle value="Completely Disagree"
            >Completely Disagree</mat-button-toggle
          >
          <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
          <mat-button-toggle value="Neither Disagree Nor Agree"
            >Neither Disagree Nor Agree</mat-button-toggle
          >
          <mat-button-toggle value="Agree">Agree</mat-button-toggle>
          <mat-button-toggle value="Completely Agree"
            >Completely Agree</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >13. I feel an increasing inclination to shop/buy things.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [disabled]="disableFormElements"
          formControlName="increasingInclination"
        >
          <mat-button-toggle value="Completely Disagree"
            >Completely Disagree</mat-button-toggle
          >
          <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
          <mat-button-toggle value="Neither Disagree Nor Agree"
            >Neither Disagree Nor Agree</mat-button-toggle
          >
          <mat-button-toggle value="Agree">Agree</mat-button-toggle>
          <mat-button-toggle value="Completely Agree"
            >Completely Agree</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >14. I shop/buy much more than I had intended/planned.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [disabled]="disableFormElements"
          formControlName="buyMuchMoreThanIntended"
        >
          <mat-button-toggle value="Completely Disagree"
            >Completely Disagree</mat-button-toggle
          >
          <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
          <mat-button-toggle value="Neither Disagree Nor Agree"
            >Neither Disagree Nor Agree</mat-button-toggle
          >
          <mat-button-toggle value="Agree">Agree</mat-button-toggle>
          <mat-button-toggle value="Completely Agree"
            >Completely Agree</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >15. I feel I have to shop/buy more and more to obtain the same
        satisfaction as before.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [disabled]="disableFormElements"
          formControlName="obtainTheSameSatisfaction"
        >
          <mat-button-toggle value="Completely Disagree"
            >Completely Disagree</mat-button-toggle
          >
          <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
          <mat-button-toggle value="Neither Disagree Nor Agree"
            >Neither Disagree Nor Agree</mat-button-toggle
          >
          <mat-button-toggle value="Agree">Agree</mat-button-toggle>
          <mat-button-toggle value="Completely Agree"
            >Completely Agree</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >16. I spend more and more time shopping/buying.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [disabled]="disableFormElements"
          formControlName="spendMoreTimeShoppingBuying"
        >
          <mat-button-toggle value="Completely Disagree"
            >Completely Disagree</mat-button-toggle
          >
          <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
          <mat-button-toggle value="Neither Disagree Nor Agree"
            >Neither Disagree Nor Agree</mat-button-toggle
          >
          <mat-button-toggle value="Agree">Agree</mat-button-toggle>
          <mat-button-toggle value="Completely Agree"
            >Completely Agree</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >17. I have tried to cut down on shopping/buying without success.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [disabled]="disableFormElements"
          formControlName="triedToCutDownOnShopping"
        >
          <mat-button-toggle value="Completely Disagree"
            >Completely Disagree</mat-button-toggle
          >
          <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
          <mat-button-toggle value="Neither Disagree Nor Agree"
            >Neither Disagree Nor Agree</mat-button-toggle
          >
          <mat-button-toggle value="Agree">Agree</mat-button-toggle>
          <mat-button-toggle value="Completely Agree"
            >Completely Agree</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >18. I have been told by others to reduce shopping/buying without
        listening to them.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [disabled]="disableFormElements"
          formControlName="notListeningToOthers"
        >
          <mat-button-toggle value="Completely Disagree"
            >Completely Disagree</mat-button-toggle
          >
          <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
          <mat-button-toggle value="Neither Disagree Nor Agree"
            >Neither Disagree Nor Agree</mat-button-toggle
          >
          <mat-button-toggle value="Agree">Agree</mat-button-toggle>
          <mat-button-toggle value="Completely Agree"
            >Completely Agree</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >19. I have decided to shop/buy less, but have not been able to do
        so.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [disabled]="disableFormElements"
          formControlName="haveDecidedToShopLless"
        >
          <mat-button-toggle value="Completely Disagree"
            >Completely Disagree</mat-button-toggle
          >
          <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
          <mat-button-toggle value="Neither Disagree Nor Agree"
            >Neither Disagree Nor Agree</mat-button-toggle
          >
          <mat-button-toggle value="Agree">Agree</mat-button-toggle>
          <mat-button-toggle value="Completely Agree"
            >Completely Agree</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >20. I have managed to limit shopping/buying for periods, and the
        experienced relapse.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [disabled]="disableFormElements"
          formControlName="relapsedToExcessiveShopping"
        >
          <mat-button-toggle value="Completely Disagree"
            >Completely Disagree</mat-button-toggle
          >
          <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
          <mat-button-toggle value="Neither Disagree Nor Agree"
            >Neither Disagree Nor Agree</mat-button-toggle
          >
          <mat-button-toggle value="Agree">Agree</mat-button-toggle>
          <mat-button-toggle value="Completely Agree"
            >Completely Agree</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >21. I become stressed if obstructed from shopping/buying things.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [disabled]="disableFormElements"
          formControlName="becomeStressedIfObstructed"
        >
          <mat-button-toggle value="Completely Disagree"
            >Completely Disagree</mat-button-toggle
          >
          <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
          <mat-button-toggle value="Neither Disagree Nor Agree"
            >Neither Disagree Nor Agree</mat-button-toggle
          >
          <mat-button-toggle value="Agree">Agree</mat-button-toggle>
          <mat-button-toggle value="Completely Agree"
            >Completely Agree</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >22. I become sour and grumpy if I for some reasons cannot shop/buy
        things when I feel like it.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [disabled]="disableFormElements"
          formControlName="grumpyWhenUnableToShop"
        >
          <mat-button-toggle value="Completely Disagree"
            >Completely Disagree</mat-button-toggle
          >
          <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
          <mat-button-toggle value="Neither Disagree Nor Agree"
            >Neither Disagree Nor Agree</mat-button-toggle
          >
          <mat-button-toggle value="Agree">Agree</mat-button-toggle>
          <mat-button-toggle value="Completely Agree"
            >Completely Agree</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >23. I feel bad if I for some reason are prevented from shopping/buying
        things.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [disabled]="disableFormElements"
          formControlName="feelBadIfPrevented"
        >
          <mat-button-toggle value="Completely Disagree"
            >Completely Disagree</mat-button-toggle
          >
          <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
          <mat-button-toggle value="Neither Disagree Nor Agree"
            >Neither Disagree Nor Agree</mat-button-toggle
          >
          <mat-button-toggle value="Agree">Agree</mat-button-toggle>
          <mat-button-toggle value="Completely Agree"
            >Completely Agree</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >24. I there has been a while since I last shopped I feel a strong urge
        to shop/buy things.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [disabled]="disableFormElements"
          formControlName="strongUrgeToBuyThings"
        >
          <mat-button-toggle value="Completely Disagree"
            >Completely Disagree</mat-button-toggle
          >
          <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
          <mat-button-toggle value="Neither Disagree Nor Agree"
            >Neither Disagree Nor Agree</mat-button-toggle
          >
          <mat-button-toggle value="Agree">Agree</mat-button-toggle>
          <mat-button-toggle value="Completely Agree"
            >Completely Agree</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >25. I shop/buy so much that it has caused economic problems.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [disabled]="disableFormElements"
          formControlName="economicProblems"
        >
          <mat-button-toggle value="Completely Disagree"
            >Completely Disagree</mat-button-toggle
          >
          <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
          <mat-button-toggle value="Neither Disagree Nor Agree"
            >Neither Disagree Nor Agree</mat-button-toggle
          >
          <mat-button-toggle value="Agree">Agree</mat-button-toggle>
          <mat-button-toggle value="Completely Agree"
            >Completely Agree</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >26. I shop/buy so much that it has impaired my well-being.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [disabled]="disableFormElements"
          formControlName="impairedYourWellBeing"
        >
          <mat-button-toggle value="Completely Disagree"
            >Completely Disagree</mat-button-toggle
          >
          <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
          <mat-button-toggle value="Neither Disagree Nor Agree"
            >Neither Disagree Nor Agree</mat-button-toggle
          >
          <mat-button-toggle value="Agree">Agree</mat-button-toggle>
          <mat-button-toggle value="Completely Agree"
            >Completely Agree</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >27. I have worried so much about my shopping that it sometimes has made
        me sleepless.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [disabled]="disableFormElements"
          formControlName="worriedSoMuchAboutShopping"
        >
          <mat-button-toggle value="Completely Disagree"
            >Completely Disagree</mat-button-toggle
          >
          <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
          <mat-button-toggle value="Neither Disagree Nor Agree"
            >Neither Disagree Nor Agree</mat-button-toggle
          >
          <mat-button-toggle value="Agree">Agree</mat-button-toggle>
          <mat-button-toggle value="Completely Agree"
            >Completely Agree</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >28. I have been bothered with poor conscience because of
        shopping/buying.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [disabled]="disableFormElements"
          formControlName="poorConscience"
        >
          <mat-button-toggle value="Completely Disagree"
            >Completely Disagree</mat-button-toggle
          >
          <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
          <mat-button-toggle value="Neither Disagree Nor Agree"
            >Neither Disagree Nor Agree</mat-button-toggle
          >
          <mat-button-toggle value="Agree">Agree</mat-button-toggle>
          <mat-button-toggle value="Completely Agree"
            >Completely Agree</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </mat-card>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          shoppingaddictionForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!shoppingaddictionForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
