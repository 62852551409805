import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ILabOrder } from 'src/app/_models/patient/laborder.model';
import { LabOrderApiService } from 'src/app/_services/patient/laborder.service';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs, formatDate } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-add-edit-lab-order',
  templateUrl: './add-edit-lab-order.component.html',
  styleUrl: './add-edit-lab-order.component.css'
})
export class AddEditLabOrderComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<AddEditLabOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public laborderApiService: LabOrderApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler) {
    this.action = data.action;
    this.laborder = data.laborder;
  }

  public action: string;
  public laborderForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  laborder: ILabOrder;
  laborderError: Error = null;
  errors: any = {};
  processing = false;


  public ngOnInit() {
    this.buildForm();
    this.errorHandler.handleErrors(this.laborderForm, this.errors);

    if (this.action === 'EDIT') {
      this.laborderForm.controls['id'].setValue(this.laborder.id);
      this.laborderForm.controls['organizationId'].setValue(this.laborder.organizationId);
      this.laborderForm.controls['patientId'].setValue(this.laborder.patientId);
      this.laborderForm.controls['testType'].setValue(this.laborder.testType);
      this.laborderForm.controls['testDate'].setValue(this.laborder.testDate);
      this.laborderForm.controls['fasting'].setValue(this.laborder.fasting);
      this.laborderForm.controls['patientFirstName'].setValue(this.laborder.patientFirstName);
      this.laborderForm.controls['patientLastName'].setValue(this.laborder.patientLastName);
      this.laborderForm.controls['patientDob'].setValue(this.laborder.patientDob);
      this.laborderForm.controls['patientEmail'].setValue(this.laborder.patientEmail);
      this.laborderForm.controls['patientPhone'].setValue(this.laborder.patientPhone);
      this.laborderForm.controls['patientAddressLine1'].setValue(this.laborder.patientAddressLine1);
      this.laborderForm.controls['patientAddressLine2'].setValue(this.laborder.patientAddressLine2);
      this.laborderForm.controls['patientCity'].setValue(this.laborder.patientCity);
      this.laborderForm.controls['patientState'].setValue(this.laborder.patientState);
      this.laborderForm.controls['patientZip'].setValue(this.laborder.patientZip);
    }
  }


  onLabOrderFormSubmit() {
    if (this.laborderForm.invalid) {
      this.laborderForm.markAllAsTouched();
      return;
    }
    this.laborder = Object.assign({}, this.laborder);
    this.laborder = Object.assign(this.laborder, this.laborderForm.value);
    if (this.laborder.testDate !== '') {
      this.laborder.testDate = formatDate(new Date(this.laborder.testDate));
    }
    if (this.laborder.patientDob !== '') {
      this.laborder.patientDob = formatDate(new Date(this.laborder.patientDob));
    }
    this.processing = true;
    if (this.action === 'EDIT') {
      this.laborderApiService.updateLabOrder(this.laborder).subscribe(() => {
        this.processing = false;
        this.laborderForm.reset();
        this.laborderForm.clearValidators();
        this.laborderForm.markAsUntouched();
        this.laborderForm.markAsPristine();
        this.dialogRef.close();
      })
    } else {
      this.laborderApiService.addLabOrder(this.laborder).subscribe(() => {
        this.processing = false;
        this.laborderForm.reset();
        this.laborderForm.clearValidators();
        this.laborderForm.markAsUntouched();
        this.laborderForm.markAsPristine();
        this.dialogRef.close();
      })
    }
  };


  public validation_messages = {
    'id': [
      { type: 'required', message: 'Id is required' },
    ],
    'organizationId': [
      { type: 'required', message: 'Organization Id is required' },
    ],
    'patientId': [
      { type: 'required', message: 'Patient Id is required' },
    ],
    'testType': [
      { type: 'required', message: 'Test Type is required' },
    ],
    'testDate': [
      { type: 'required', message: 'Test Date is required' },
    ],
    'fasting': [
      { type: 'required', message: 'Fasting is required' },
    ],
    'patientFirstName': [
      { type: 'required', message: 'Patient First Name is required' },
    ],
    'patientLastName': [
      { type: 'required', message: 'Patient Last Name is required' },
    ],
    'patientDob': [
      { type: 'required', message: 'Patient Dob is required' },
    ],
    'patientEmail': [
      { type: 'required', message: 'Patient Email is required' },
      { type: 'pattern', message: 'Please enter a valid email address' },
    ],
    'patientPhone': [
      { type: 'required', message: 'Patient Phone is required' },
      { type: 'pattern', message: 'Please enter a valid 10 digit US Phone Number' },
    ],
    'patientAddressLine1': [
      { type: 'required', message: 'Patient Address Line 1 is required' },
    ],
    'patientAddressLine2': [
    ],
    'patientCity': [
      { type: 'required', message: 'Patient City is required' },
    ],
    'patientState': [
      { type: 'required', message: 'Patient State is required' },
    ],
    'patientZip': [
      { type: 'required', message: 'Patient Zip is required' },
      { type: 'pattern', message: 'Please enter a valid 5 digit US Zip Code' },
    ],
  };

  private buildForm() {
    this.laborderForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      organizationId: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      patientId: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      testType: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      testDate: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      fasting: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      patientFirstName: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      patientLastName: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      patientDob: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      patientEmail: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ])),
      patientPhone: new FormControl('', Validators.compose([
        Validators.required,
        RxwebValidators.mask({ mask: '(999)-999 9999' }),
      ])),
      patientAddressLine1: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      patientAddressLine2: new FormControl('', Validators.compose([
      ])),
      patientCity: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      patientState: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      patientZip: new FormControl('', Validators.compose([
        Validators.required,
        RxwebValidators.mask({ mask: '99999' }),
      ])),
    });
    this.laborderForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }


  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.laborderForm) {
      return;
    }
    const form = this.laborderForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }

  onSubmit() {
    if (this.laborderForm.invalid) {
      return;
    }
  };

  onNoClick(): void {
    this.dialogRef.close();
  }

}