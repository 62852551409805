import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ITherapySession, Patient } from 'src/app/_models';
import { TherapySessionService } from 'src/app/_services';

@Component({
  selector: 'app-group-member-goals',
  templateUrl: './group-member-goals.component.html',
  styleUrl: './group-member-goals.component.css',
})
export class GroupMemberGoalsComponent implements OnInit {
  sessionDetails: ITherapySession;
  selectedMemberId: string;
  patient: Patient;
  hasGoalsAddAccess: boolean;
  hasGoalsEditAccess: boolean;
  hasGoalsDeleteAccess: boolean;

  constructor(
    public dialogRef: MatDialogRef<GroupMemberGoalsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public therapysessionApiService: TherapySessionService
  ) {
    this.sessionDetails = data.sessionDetails;
    this.selectedMemberId = data.selectedMemberId;
    this.patient = data.patient;
    this.hasGoalsAddAccess = data.hasGoalsAddAccess;
    this.hasGoalsEditAccess = data.hasGoalsEditAccess;
    this.hasGoalsDeleteAccess = data.hasGoalsDeleteAccess;
  }

  ngOnInit(): void {}
}
