import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { IBingeEatingScale } from 'src/app/_models/assessments/eating-disorder/bingeeatingscale.model';
import {
  BingeEatingScaleApiService,
  ToastMessageService,
} from 'src/app/_services/';

@Component({
  selector: 'app-binge-eating-scale',
  templateUrl: './binge-eating-scale.component.html',
  styleUrls: ['./binge-eating-scale.component.css'],
})
export class BingeEatingScaleComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public bingeEatingScaleApiService: BingeEatingScaleApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) {}
  public riskScore: number;
  public bingeEatingScaleForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  bingeEatingScaleAssessment: IBingeEatingScale;
  bingeEatingScaleAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.bingeEatingScaleForm, this.errors);

    this.loadBingeEatingScaleAssessment();
  }

  loadBingeEatingScaleAssessment() {
    this.bingeEatingScaleForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.bingeEatingScaleApiService
        .getBingeEatingScaleDetails(this.assessmentId)
        .subscribe((bingeEatingScaleAssessment) => {
          if (bingeEatingScaleAssessment.data) {
            this.bingeEatingScaleForm.controls['question1'].setValue(
              bingeEatingScaleAssessment.data.question1.answer
            );
            this.bingeEatingScaleForm.controls['question2'].setValue(
              bingeEatingScaleAssessment.data.question2.answer
            );
            this.bingeEatingScaleForm.controls['question3'].setValue(
              bingeEatingScaleAssessment.data.question3.answer
            );
            this.bingeEatingScaleForm.controls['question4'].setValue(
              bingeEatingScaleAssessment.data.question4.answer
            );
            this.bingeEatingScaleForm.controls['question5'].setValue(
              bingeEatingScaleAssessment.data.question5.answer
            );
            this.bingeEatingScaleForm.controls['question6'].setValue(
              bingeEatingScaleAssessment.data.question6.answer
            );
            this.bingeEatingScaleForm.controls['question7'].setValue(
              bingeEatingScaleAssessment.data.question7.answer
            );
            this.bingeEatingScaleForm.controls['question8'].setValue(
              bingeEatingScaleAssessment.data.question8.answer
            );
            this.bingeEatingScaleForm.controls['question9'].setValue(
              bingeEatingScaleAssessment.data.question9.answer
            );
            this.bingeEatingScaleForm.controls['question10'].setValue(
              bingeEatingScaleAssessment.data.question10.answer
            );
            this.bingeEatingScaleForm.controls['question11'].setValue(
              bingeEatingScaleAssessment.data.question11.answer
            );
            this.bingeEatingScaleForm.controls['question12'].setValue(
              bingeEatingScaleAssessment.data.question12.answer
            );
            this.bingeEatingScaleForm.controls['question13'].setValue(
              bingeEatingScaleAssessment.data.question13.answer
            );
            this.bingeEatingScaleForm.controls['question14'].setValue(
              bingeEatingScaleAssessment.data.question14.answer
            );
            this.bingeEatingScaleForm.controls['question15'].setValue(
              bingeEatingScaleAssessment.data.question15.answer
            );
            this.bingeEatingScaleForm.controls['question16'].setValue(
              bingeEatingScaleAssessment.data.question16.answer
            );

            this.riskScore = bingeEatingScaleAssessment.data.riskScore;
          }
        });
    }
  }

  onFormSubmit() {
    if (this.bingeEatingScaleForm.invalid) {
      this.bingeEatingScaleForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.bingeEatingScaleAssessment = Object.assign(
      {},
      this.bingeEatingScaleAssessment
    );
    this.bingeEatingScaleAssessment.patientId =
      this.bingeEatingScaleForm.controls['patientId'].value;
    this.bingeEatingScaleAssessment.sessionId =
      this.bingeEatingScaleForm.controls['sessionId'].value;
    this.bingeEatingScaleAssessment.assessmentRequestId =
      this.bingeEatingScaleForm.controls['assessmentRequestId'].value;
    this.bingeEatingScaleAssessment.question1 = {
      answer: this.bingeEatingScaleForm.controls['question1'].value,
    };
    this.bingeEatingScaleAssessment.question2 = {
      answer: this.bingeEatingScaleForm.controls['question2'].value,
    };
    this.bingeEatingScaleAssessment.question3 = {
      answer: this.bingeEatingScaleForm.controls['question3'].value,
    };
    this.bingeEatingScaleAssessment.question4 = {
      answer: this.bingeEatingScaleForm.controls['question4'].value,
    };
    this.bingeEatingScaleAssessment.question5 = {
      answer: this.bingeEatingScaleForm.controls['question5'].value,
    };
    this.bingeEatingScaleAssessment.question6 = {
      answer: this.bingeEatingScaleForm.controls['question6'].value,
    };
    this.bingeEatingScaleAssessment.question7 = {
      answer: this.bingeEatingScaleForm.controls['question7'].value,
    };
    this.bingeEatingScaleAssessment.question8 = {
      answer: this.bingeEatingScaleForm.controls['question8'].value,
    };
    this.bingeEatingScaleAssessment.question9 = {
      answer: this.bingeEatingScaleForm.controls['question9'].value,
    };
    this.bingeEatingScaleAssessment.question10 = {
      answer: this.bingeEatingScaleForm.controls['question10'].value,
    };
    this.bingeEatingScaleAssessment.question11 = {
      answer: this.bingeEatingScaleForm.controls['question11'].value,
    };
    this.bingeEatingScaleAssessment.question12 = {
      answer: this.bingeEatingScaleForm.controls['question12'].value,
    };
    this.bingeEatingScaleAssessment.question13 = {
      answer: this.bingeEatingScaleForm.controls['question13'].value,
    };
    this.bingeEatingScaleAssessment.question14 = {
      answer: this.bingeEatingScaleForm.controls['question14'].value,
    };
    this.bingeEatingScaleAssessment.question15 = {
      answer: this.bingeEatingScaleForm.controls['question15'].value,
    };
    this.bingeEatingScaleAssessment.question16 = {
      answer: this.bingeEatingScaleForm.controls['question16'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.bingeEatingScaleAssessment.accessCode = this.accessCode;

      this.bingeEatingScaleApiService
        .addGuestBingeEatingScale(this.bingeEatingScaleAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Binge Eating Scale'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Binge Eating Scale'
            );
          }
        );
    } else {
      this.bingeEatingScaleApiService
        .addBingeEatingScale(this.bingeEatingScaleAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Binge Eating Scale'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Binge Eating Scale'
            );
          }
        );
    }
  }

  public validation_messages = {
    question1: [{ type: 'required', message: '1.) is required' }],
    question2: [{ type: 'required', message: '2.) is required' }],
    question3: [{ type: 'required', message: '3.) is required' }],
    question4: [{ type: 'required', message: '4.) is required' }],
    question5: [{ type: 'required', message: '5.) is required' }],
    question6: [{ type: 'required', message: '6.) is required' }],
    question7: [{ type: 'required', message: '7.) is required' }],
    question8: [{ type: 'required', message: '8.) is required' }],
    question9: [{ type: 'required', message: '9.) is required' }],
    question10: [{ type: 'required', message: '10.) is required' }],
    question11: [{ type: 'required', message: '11.) is required' }],
    question12: [{ type: 'required', message: '12.) is required' }],
    question13: [{ type: 'required', message: '13.) is required' }],
    question14: [{ type: 'required', message: '14.) is required' }],
    question15: [{ type: 'required', message: '15.) is required' }],
    question16: [{ type: 'required', message: '16.) is required' }],
  };

  private buildForm() {
    this.bingeEatingScaleForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      question1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      question2: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      question3: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      question4: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      question5: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      question6: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      question7: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      question8: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      question9: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      question10: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      question11: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      question12: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      question13: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      question14: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      question15: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      question16: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.bingeEatingScaleForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.bingeEatingScaleForm) {
      return;
    }
    const form = this.bingeEatingScaleForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
