import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { IAas } from 'src/app/_models/assessments/relationship-family/aas.model';
import {
  ToastMessageService
} from 'src/app/_services/';
import {
  AasApiService
} from 'src/app/_services/assessments/relationship-family/aas.service';

@Component({
  selector: 'app-aas-assessment-dialog',
  templateUrl: './aas-assessment-dialog.component.html',
  styleUrls: ['./aas-assessment-dialog.component.css']
})
export class AasAssessmentDialogComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public aasAssessmentApiService: AasApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }

  public close: number;
  public depend: number;
  public anxiety: number;
  public riskScore: number;
  public aasAssessmentForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  aasAssessment: IAas;
  aasAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.aasAssessmentForm, this.errors);

    this.loadAas();
  }

  loadAas() {
    this.aasAssessmentForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.aasAssessmentApiService
        .getAasDetails(this.assessmentId)
        .subscribe((aasAssessment) => {
          if (aasAssessment.data) {
            this.aasAssessmentForm.controls['easyToGetClose'].setValue(aasAssessment.data.easyToGetClose.answer);
            this.aasAssessmentForm.controls['difficultToDependOnOthers'].setValue(aasAssessment.data.difficultToDependOnOthers.answer);
            this.aasAssessmentForm.controls['worryPartnersDontLove'].setValue(aasAssessment.data.worryPartnersDontLove.answer);
            this.aasAssessmentForm.controls['othersReluctantToGetClose'].setValue(aasAssessment.data.othersReluctantToGetClose.answer);
            this.aasAssessmentForm.controls['comfortableDepending'].setValue(aasAssessment.data.comfortableDepending.answer);
            this.aasAssessmentForm.controls['notWorriedAboutCloseness'].setValue(aasAssessment.data.notWorriedAboutCloseness.answer);
            this.aasAssessmentForm.controls['peopleNotThereWhenNeeded'].setValue(aasAssessment.data.peopleNotThereWhenNeeded.answer);
            this.aasAssessmentForm.controls['uncomfortableBeingClose'].setValue(aasAssessment.data.uncomfortableBeingClose.answer);
            this.aasAssessmentForm.controls['worryPartnersWontStay'].setValue(aasAssessment.data.worryPartnersWontStay.answer);
            this.aasAssessmentForm.controls['fearUnreciprocatedFeelings'].setValue(aasAssessment.data.fearUnreciprocatedFeelings.answer);
            this.aasAssessmentForm.controls['wonderIfPartnersCare'].setValue(aasAssessment.data.wonderIfPartnersCare.answer);
            this.aasAssessmentForm.controls['comfortableWithCloseness'].setValue(aasAssessment.data.comfortableWithCloseness.answer);
            this.aasAssessmentForm.controls['uncomfortableWithEmotions'].setValue(aasAssessment.data.uncomfortableWithEmotions.answer);
            this.aasAssessmentForm.controls['peopleThereWhenNeeded'].setValue(aasAssessment.data.peopleThereWhenNeeded.answer);
            this.aasAssessmentForm.controls['wantClosenessFearHurt'].setValue(aasAssessment.data.wantClosenessFearHurt.answer);
            this.aasAssessmentForm.controls['difficultToTrustOthers'].setValue(aasAssessment.data.difficultToTrustOthers.answer);
            this.aasAssessmentForm.controls['partnersWantMoreCloseness'].setValue(aasAssessment.data.partnersWantMoreCloseness.answer);
            this.aasAssessmentForm.controls['uncertainOfDependability'].setValue(aasAssessment.data.uncertainOfDependability.answer);

            this.riskScore = aasAssessment.data.riskScore;

            this.close = aasAssessment.data.props['close'];
            this.depend = aasAssessment.data.props['depend'];
            this.anxiety = aasAssessment.data.props['anxiety'];

          }
        });
    }
  }
  onAasFormSubmit() {
    if (this.aasAssessmentForm.invalid) {
      this.aasAssessmentForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.aasAssessment = Object.assign({}, this.aasAssessment);
    this.aasAssessment.patientId =
      this.aasAssessmentForm.controls['patientId'].value;
    this.aasAssessment.sessionId =
      this.aasAssessmentForm.controls['sessionId'].value;
    this.aasAssessment.assessmentRequestId =
      this.aasAssessmentForm.controls['assessmentRequestId'].value;

    this.aasAssessment.easyToGetClose = { answer: this.aasAssessmentForm.controls['easyToGetClose'].value };
    this.aasAssessment.difficultToDependOnOthers = { answer: this.aasAssessmentForm.controls['difficultToDependOnOthers'].value };
    this.aasAssessment.worryPartnersDontLove = { answer: this.aasAssessmentForm.controls['worryPartnersDontLove'].value };
    this.aasAssessment.othersReluctantToGetClose = { answer: this.aasAssessmentForm.controls['othersReluctantToGetClose'].value };
    this.aasAssessment.comfortableDepending = { answer: this.aasAssessmentForm.controls['comfortableDepending'].value };
    this.aasAssessment.notWorriedAboutCloseness = { answer: this.aasAssessmentForm.controls['notWorriedAboutCloseness'].value };
    this.aasAssessment.peopleNotThereWhenNeeded = { answer: this.aasAssessmentForm.controls['peopleNotThereWhenNeeded'].value };
    this.aasAssessment.uncomfortableBeingClose = { answer: this.aasAssessmentForm.controls['uncomfortableBeingClose'].value };
    this.aasAssessment.worryPartnersWontStay = { answer: this.aasAssessmentForm.controls['worryPartnersWontStay'].value };
    this.aasAssessment.fearUnreciprocatedFeelings = { answer: this.aasAssessmentForm.controls['fearUnreciprocatedFeelings'].value };
    this.aasAssessment.wonderIfPartnersCare = { answer: this.aasAssessmentForm.controls['wonderIfPartnersCare'].value };
    this.aasAssessment.comfortableWithCloseness = { answer: this.aasAssessmentForm.controls['comfortableWithCloseness'].value };
    this.aasAssessment.uncomfortableWithEmotions = { answer: this.aasAssessmentForm.controls['uncomfortableWithEmotions'].value };
    this.aasAssessment.peopleThereWhenNeeded = { answer: this.aasAssessmentForm.controls['peopleThereWhenNeeded'].value };
    this.aasAssessment.wantClosenessFearHurt = { answer: this.aasAssessmentForm.controls['wantClosenessFearHurt'].value };
    this.aasAssessment.difficultToTrustOthers = { answer: this.aasAssessmentForm.controls['difficultToTrustOthers'].value };
    this.aasAssessment.partnersWantMoreCloseness = { answer: this.aasAssessmentForm.controls['partnersWantMoreCloseness'].value };
    this.aasAssessment.uncertainOfDependability = { answer: this.aasAssessmentForm.controls['uncertainOfDependability'].value };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.aasAssessment.accessCode = this.accessCode;

      this.aasAssessmentApiService
        .addGuestAas(this.aasAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Core 10 Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Core 10 Assessment'
            );
          }
        );
    } else {
      this.aasAssessmentApiService
        .addAas(this.aasAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Core 10 Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Core 10 Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    'easyToGetClose': [
      { type: 'required', message: '1. I find it relatively easy to get close to others. is required' },
    ],
    'difficultToDependOnOthers': [
      { type: 'required', message: '2. I find it difficult to allow myself to depend on others. is required' },
    ],
    'worryPartnersDontLove': [
      { type: 'required', message: '3. I often worry that romantic partners don\'t really love me.is required' },
    ],
    'othersReluctantToGetClose': [
      { type: 'required', message: '4. I find that others are reluctant to get as close as I would like. is required' },
    ],
    'comfortableDepending': [
      { type: 'required', message: '5. I am comfortable depending on others. is required' },
    ],
    'notWorriedAboutCloseness': [
      { type: 'required', message: '6. I don\'t worry about people getting too close to me.is required' },
    ],
    'peopleNotThereWhenNeeded': [
      { type: 'required', message: '7. I find that people are never there when you need them. is required' },
    ],
    'uncomfortableBeingClose': [
      { type: 'required', message: '8. I am somewhat uncomfortable being close to others. is required' },
    ],
    'worryPartnersWontStay': [
      { type: 'required', message: '9. I often worry that romantic partners won\'t want to stay with me.is required' },
    ],
    'fearUnreciprocatedFeelings': [
      { type: 'required', message: '10. When I show my feelings for others , I\'m afraid they will not feel the same about me.is required' },
    ],
    'wonderIfPartnersCare': [
      { type: 'required', message: '11. I often wonder whether romantic partners really care about me. is required' },
    ],
    'comfortableWithCloseness': [
      { type: 'required', message: '12. I am comfortable developing close relationships with others. is required' },
    ],
    'uncomfortableWithEmotions': [
      { type: 'required', message: '13. I am uncomfortable when anyone gets too emotionally close to me. is required' },
    ],
    'peopleThereWhenNeeded': [
      { type: 'required', message: '14. I know that people will be there when I need them. is required' },
    ],
    'wantClosenessFearHurt': [
      { type: 'required', message: '15. I want to get close to people, but I worry about being hurt. is required' },
    ],
    'difficultToTrustOthers': [
      { type: 'required', message: '16. I find it difficult to trust others completely. is required' },
    ],
    'partnersWantMoreCloseness': [
      { type: 'required', message: '17. Romantic partners often want me to be emotionally closer than I feel comfortable being. is required' },
    ],
    'uncertainOfDependability': [
      { type: 'required', message: '18. I am not sure that I can always depend on people to be there when I need them. is required' },
    ],
  };

  private buildForm() {
    this.aasAssessmentForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      easyToGetClose: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      difficultToDependOnOthers: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      worryPartnersDontLove: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      othersReluctantToGetClose: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      comfortableDepending: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      notWorriedAboutCloseness: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      peopleNotThereWhenNeeded: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      uncomfortableBeingClose: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      worryPartnersWontStay: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      fearUnreciprocatedFeelings: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      wonderIfPartnersCare: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      comfortableWithCloseness: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      uncomfortableWithEmotions: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      peopleThereWhenNeeded: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      wantClosenessFearHurt: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      difficultToTrustOthers: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      partnersWantMoreCloseness: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      uncertainOfDependability: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
    });
    this.aasAssessmentForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.aasAssessmentForm) {
      return;
    }
    const form = this.aasAssessmentForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
