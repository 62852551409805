import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import {
  MbscCalendarEvent,
  MbscEventcalendar,
  MbscEventcalendarOptions,
  MbscPopup,
  MbscPopupOptions,
  momentTimezone,
  setOptions,
} from '@mobiscroll/angular';
import { addDays, addMinutes } from 'date-fns';
import { KeycloakService } from 'keycloak-angular';
import moment from 'moment-timezone';
import { Observable, catchError, forkJoin, map, of } from 'rxjs';
import { DataTablesModel, ITherapySession, Patient } from 'src/app/_models';
import {
  CaseApiService,
  CoreService,
  DashboardsService,
  DateTimeZoneService,
  PatientService,
  TherapySessionService,
  TimeOffAPIService,
  ToastMessageService,
  WorkingHoursDialogApiService,
} from 'src/app/_services';
import { ConsentDocumentApiService } from 'src/app/_services/consent-docs/orgconsentdocument.service';
import { CustomFormApiService } from 'src/app/_services/custom-forms/customforms.service';
import { FacilitiesSandbox } from 'src/app/shared/sandbox/facilities.sandbox';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { PermissionsSandbox } from 'src/app/shared/sandbox/permissions.sandbox';
import { VisitReasonsSandbox } from 'src/app/shared/sandbox/visit-reasons.sandbox';
import { AutoAccidentQualifier } from 'src/app/shared/utilities/billing/autoAccidentQualifier';
import { EPSDTConditionInticatorType } from 'src/app/shared/utilities/billing/epsdtType';
import { USStates } from 'src/app/shared/utilities/billing/states';
import { IntakeDocumentsList } from 'src/app/shared/utilities/calendar/intakeDocumentsList';
import { TimeArrayMap } from 'src/app/shared/utilities/calendar/timeArrays';
import { TimeCellSteps } from 'src/app/shared/utilities/calendar/timeCellArrays';
import {
  TimeZonesCalendar,
  getTimeInTimeZone,
} from 'src/app/shared/utilities/calendar/timeZonesCalendar';
import { Colors } from 'src/app/shared/utilities/colors';
import { formatDate, hasAccess } from 'src/app/shared/utilities/utilities';
import { AddEditCaseDialogComponent } from '../../cases/case-dialogs/case-add-edit/case-add-edit.component';
import { IntakeSearchAddInsuranceComponent } from '../../patient-details/patient-components/sessions/bpsa/bpsa-intake/intake-insurance-info/intake-search-add-insurance/intake-search-add-insurance.component';
import { EditPriorAuthComponent } from '../../patient-details/patient-components/sessions/session-billing/edit-prior-auth/edit-prior-auth.component';
import { SelectReferringProviderComponent } from '../../patient-details/patient-components/sessions/session-billing/select-referring-provider/select-referring-provider.component';
import { EditConfirmProcedureCodeComponent } from '../../patient-details/patient-components/sessions/session-codes/session-procedural-codes/edit-confirm-procedure-code/edit-confirm-procedure-code.component';
import { SearchAddProcedureCodeComponent } from '../../patient-details/patient-components/sessions/session-codes/session-procedural-codes/search-add-procedure-code/search-add-procedure-code.component';
import { AddEditPatientComponent } from '../../patients/dialogs/add/add.component';

momentTimezone.moment = moment;
setOptions({
  theme: 'ios',
  themeVariant: 'light',
});
@Component({
  selector: 'app-intake-session-scheduler',
  templateUrl: './intake-session-scheduler.component.html',
  styleUrl: './intake-session-scheduler.component.css',
})
export class IntakeSessionSchedulerComponent implements OnInit {
  @ViewChild('mbscCalendar') mbscCalendar!: MbscEventcalendar;
  @ViewChild(MatStepper) stepper: MatStepper;
  @ViewChild('popup', { static: false })
  tooltip!: MbscPopup;
  anchor?: HTMLElement;
  timer: any;
  selectedEvent: any;

  intakeDocumentsList = IntakeDocumentsList;
  consolidatedMemberSearch = false;
  showMemberNumber: boolean = false;
  // Importing Constants from the Shared Library
  colorsList = Colors;
  timeArrayMap = TimeArrayMap;
  epsdtConditionTypes = EPSDTConditionInticatorType;
  usStateList = USStates;
  autoAccidentQualifierList = AutoAccidentQualifier;
  calendarTimeZones = TimeZonesCalendar;
  // Caledar Time Zone - Default CST
  selectedCalendarTimeZone = '';
  selectedCaldendarTimeZoneDateFormat = '';

  // Visit Reasons
  visitReasons: any = [];
  visitReasonsListMap: Map<String, any> = new Map<String, any>();
  organizationalTimeOffs: any = [];
  organizationalTimeOffLoading: boolean = true;
  // Colors Map For Visit Reasons
  visitReasonsColorMap: Map<string, string> = new Map();

  // Get All Active Providers
  providersList: any = [];
  supervisingProvidersList: any = [];
  filteredSupervisingProviderList: any = [];
  providersListMap: Map<String, any> = new Map<String, any>();
  searchProviderName: string = '';
  searchSupervisingProviderName: string = '';
  providersListLoading: boolean = true;
  // Using Map to Store Each Provider's Schedule and Time Off
  providersScheduleTimeOffMap: Map<String, any> = new Map<String, any>();

  // Permissions
  hasMemberAddAccess: boolean = false;
  hasSessionsViewAccess: boolean = false;
  hasMemberViewAccess: boolean = false;

  // Member search
  patient: Patient;
  membersList: DataTablesModel = {} as DataTablesModel;
  isMembersLoading: boolean = false;
  memberFirstName: string = '';
  memberLastName: string = '';
  memberPhone: string = '';
  displayedColumns = ['memberName', 'address', 'phoneNumber', 'actions'];

  // Charges
  chargesColumn = ['cptCode', 'charges', 'actions'];

  // MobiScroll Components
  allResources = [];
  filteredAllResources = [];
  filteredResources = [];

  invalids = [];
  invalidColors = [];
  myEvents: MbscCalendarEvent[] = [];
  allWorkingHoursArray = [];
  calendarStartDay = 0; // Sunday is 0, Monday is 1, ...
  calendarEndDay = 6;
  calendarStartHour = '00:00';
  calendarEndHour = '24:00';
  calendarViewType: string = 'week';
  timeCellStep = 30; // 1, 5, 10, 15, 20, 30, 60, 120, 180, 240, 360,
  timeLabelStep = 30; // 1, 5, 10, 15, 20, 30, 60, 120, 180, 240, 360,
  timeCellSteps = TimeCellSteps;

  initialResponsiveView = {
    allDay: false,
    currentTimeIndicator: true,
    startTime: this.calendarStartHour,
    endTime: this.calendarEndHour,
    startDay: this.calendarStartDay,
    endDay: this.calendarEndDay,
    timeCellStep: this.timeCellStep,
    timeLabelStep: this.timeLabelStep,
  };

  // Setting Up Initial MobiScroll Calendar View
  responsiveMobiScrollCalendarView = {
    // Min-width:0px
    xsmall: {
      view: {
        schedule: {
          type: 'day',
          ...this.initialResponsiveView,
        },
      },
    },
    // min-width: 768px
    medium: {
      view: {
        schedule: {
          type: 'week',
          ...this.initialResponsiveView,
        },
      },
    },
  };

  // Current Date & time
  currentTime = new Date();
  dayBeforeYesterday = addDays(new Date(), -2);
  minimumCalendarDate = addDays(new Date(), -4);
  maximumCalendarDate = addDays(new Date(), 120);

  // Facilities
  orgConfig: any;
  myFacilities: any = [];
  facilitiesSelectionList: any = [];
  filteredFacilities: any = [];
  filteredFacilitiesProviders = new Set<string>();
  facilitiesListMap: Map<String, any> = new Map<String, any>();

  selectedProviderFacilities: any = [];
  searchFacilityName: string = '';

  hasCareFeature: boolean = false;
  hasCaseAddAccess: boolean = false;
  hasTelehealthFeature: boolean = false;

  existingSession: any = [];
  isPhysician: boolean = false;
  loggedInUserId: String;
  loggedInUserName: String;
  // Care Log Cases
  existingCaseList = [];

  // Form Components
  newSession: ITherapySession;
  public therapysessionForm: FormGroup;
  processing: boolean = false;

  // Setting up initial MobiScroll Calendar options
  mobiscrollCalendarOptions: MbscEventcalendarOptions = {
    clickToCreate: 'double',
    dragToCreate: false,
    dragToMove: true,
    dragInTime: true,
    eventOverlap: true,
    dragBetweenResources: false,
    timezonePlugin: momentTimezone,

    // Double click event creation
    onEventCreated: (args) => {
      setTimeout(() => {
        if (!args?.event?.resource) {
          this.myEvents = this.myEvents.filter((event) => event.id != null);
          setTimeout(() => {
            this.tooltip.close();
          }, 150);
        } else if (args?.event?.resource) {
          setTimeout(() => {
            this.tooltip.close();
          }, 150);

          // First Getting the selected Time Segment Start Date
          let mobiscrollStartDate: string | object | Date = args.event.start;
          let eventStartDate: Date;

          // Convert to the appropriate date type
          if (typeof mobiscrollStartDate === 'string') {
            eventStartDate = new Date(mobiscrollStartDate);
          } else if (mobiscrollStartDate instanceof Date) {
            eventStartDate = mobiscrollStartDate;
          } else {
            eventStartDate = new Date(mobiscrollStartDate.toString());
          }

          let mobiScrollEvent = args.event;

          // Remove previosly selected Events if any
          this.myEvents = this.myEvents.filter((event) => event.id != null);

          // Extracting Data From MobiScroll Event
          let mobiscrollResouce = mobiScrollEvent.resource;
          let selectedSessionProviderId = mobiscrollResouce.toString();

          const { hour, minute, formattedDate } = getTimeInTimeZone(
            eventStartDate,
            this.selectedCalendarTimeZone
          );

          let startHour = hour;
          let startMinutes = minute;
          let startMinutesString = startMinutes.toString();
          if (startMinutesString.length == 1) {
            startMinutesString = startMinutesString + '0';
          }
          let combinedTimeString = startHour.toString() + startMinutesString;

          //******* Populating the Session Component
          // Loading session details
          this.newSession.start = eventStartDate;
          this.newSession.end = addMinutes(
            eventStartDate,
            this.therapysessionForm.controls['sessionDuration'].value
          );
          this.newSession.sessionDuration =
            this.therapysessionForm.controls['sessionDuration'].value;

          if (!this.newSession.title) {
            this.newSession.title = 'New Appointment';
          }

          this.newSession.sessionStartTime = combinedTimeString;

          // Loading the Provider Details
          let providerDetails = this.providersListMap.get(
            selectedSessionProviderId
          );
          this.newSession.physicianId = selectedSessionProviderId;
          this.newSession.physicianFirstName = providerDetails?.firstName;
          this.newSession.physicianMiddleName = providerDetails?.middleName;
          this.newSession.physicianLastName = providerDetails?.lastName;
          this.newSession.physicianPhone = providerDetails?.phoneNumber;
          this.newSession.physicianEmail = providerDetails?.emailAddress;

          // Determine the facilities box selection for the selected provider
          this.determineSelectedProviderFacilities(providerDetails);

          // Also populate the therapy session form
          this.therapysessionForm.controls['title'].setValue(
            this.newSession.title
          );
          this.therapysessionForm.controls['start'].setValue(eventStartDate);
          this.therapysessionForm.controls['end'].setValue(this.newSession.end);
          this.therapysessionForm.controls['sessionStartDate'].setValue(
            formatDate(eventStartDate)
          );
          this.therapysessionForm.controls['sessionStartTime'].setValue(
            this.timeArrayMap.get(Number(combinedTimeString))
          );

          // Now Load this as a new Event on calendar
          let newMobiscrollEvent = <MbscCalendarEvent>{
            id: null,
            resource: selectedSessionProviderId,
            start: mobiscrollStartDate,
            end: this.newSession.end,
            title: this.newSession.title,
            color: '#2196f3',
            editable: true,
          };

          //Add this event to the calendar
          this.myEvents = [...this.myEvents, newMobiscrollEvent];

          // Now move to the next screen
          this.stepper.next();
        }
      });
    },

    // When the drag even stops
    onEventDragEnd: (args) => {
      setTimeout(() => {
        //  Start Date Conversion
        let mobiscrollStartDate: string | object | Date = args.event.start;
        let eventStartDate: Date;
        // Convert to the appropriate date type
        if (typeof mobiscrollStartDate === 'string') {
          eventStartDate = new Date(mobiscrollStartDate);
        } else if (mobiscrollStartDate instanceof Date) {
          eventStartDate = mobiscrollStartDate;
        } else {
          eventStartDate = new Date(mobiscrollStartDate.toString());
        }

        // End date Conversion
        let mobiscrollEndDate: string | object | Date = args.event.end;
        let eventEndDate: Date;
        if (typeof mobiscrollEndDate === 'string') {
          eventEndDate = new Date(mobiscrollEndDate);
        } else if (mobiscrollEndDate instanceof Date) {
          eventEndDate = mobiscrollEndDate;
        } else {
          eventEndDate = new Date(mobiscrollEndDate.toString());
        }

        // Getting event from args
        let mobiScrollEvent = args.event;

        // Extracting Data From MobiScroll Event
        let mobiscrollResouce = mobiScrollEvent.resource;
        let selectedSessionProviderId = mobiscrollResouce.toString();

        const { hour, minute, formattedDate } = getTimeInTimeZone(
          eventStartDate,
          this.selectedCalendarTimeZone
        );

        let startHour = hour;
        let startMinutes = minute;
        let startMinutesString = startMinutes.toString();
        if (startMinutesString.length == 1) {
          startMinutesString = startMinutesString + '0';
        }
        let combinedTimeString = startHour.toString() + startMinutesString;

        // Handle changes with physician resource change
        // Loading the Provider Details
        let providerDetails = this.providersListMap.get(
          selectedSessionProviderId
        );
        this.newSession.physicianId = selectedSessionProviderId;
        this.newSession.physicianFirstName = providerDetails?.firstName;
        this.newSession.physicianMiddleName = providerDetails?.middleName;
        this.newSession.physicianLastName = providerDetails?.lastName;
        this.newSession.physicianPhone = providerDetails?.phoneNumber;
        this.newSession.physicianEmail = providerDetails?.emailAddress;

        // Determine the facilities box selection for the selected provider
        this.determineSelectedProviderFacilities(providerDetails);

        // Populating the therapy session form with updated value
        this.newSession.start = eventStartDate;
        this.therapysessionForm.controls['start'].setValue(eventStartDate);
        this.therapysessionForm.controls['sessionStartDate'].setValue(
          formatDate(eventStartDate)
        );
        this.therapysessionForm.controls['sessionStartTime'].setValue(
          this.timeArrayMap.get(Number(combinedTimeString))
        );
        this.newSession.end = eventEndDate;
        this.therapysessionForm.controls['end'].setValue(eventEndDate);

        // Overriding session start time in therapy object
        this.newSession.sessionStartTime = combinedTimeString;
      });
    },

    // On hover in display the event
    onEventHoverIn: (args) => {
      const event = args.event;

      this.selectedEvent = event;

      if (event.id) {
        // Clear the current timer
        clearTimeout(this.timer);
        this.anchor = args.domEvent.target;
        this.tooltip.open();
      }
    },

    // Hide event tool tip on hover out
    onEventHoverOut: () => {
      this.timer = setTimeout(() => {
        this.tooltip.close();
        this.selectedEvent = null;
      }, 1000);
    },
  };

  popupOptions: MbscPopupOptions = {
    display: 'anchored',
    touchUi: true,
    showOverlay: false,
    contentPadding: false,
    closeOnOverlayClick: false,
    focusOnClose: false,
    width: 350,
  };

  mouseEnter(): void {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  mouseLeave(): void {
    this.timer = setTimeout(() => {
      this.tooltip.close();
      this.selectedEvent = null;
    }, 200);
  }

  constructor(
    private coreService: CoreService,
    public caseApiService: CaseApiService,
    public patientService: PatientService,
    private dashboardsService: DashboardsService,
    private timeOffAPIService: TimeOffAPIService,
    private workinghoursdialogapiservice: WorkingHoursDialogApiService,
    private therapySessionService: TherapySessionService,
    private consentDocumentApiService: ConsentDocumentApiService,
    public customformApiService: CustomFormApiService,
    private dialog: MatDialog,
    private router: Router,
    private formBuilder: FormBuilder,
    private orgConfigSandbox: OrgConfigSandbox,
    private facilitiesSandbox: FacilitiesSandbox,
    protected permissionsSandbox: PermissionsSandbox,
    private visitReasonsSandBox: VisitReasonsSandbox,
    private toastMessageService: ToastMessageService,
    private keycloakService: KeycloakService,
    private dateTimeZoneService: DateTimeZoneService
  ) {
    this.loggedInUserId = this.coreService.getLoggedInCareProviderId();
    this.loggedInUserName = this.coreService.getUserDetails().name;
    this.isPhysician = this.coreService.isPhysician();
  }

  ngOnInit(): void {
    // Default time zone based on user login
    this.selectedCalendarTimeZone = moment.tz.guess();
    this.selectedCaldendarTimeZoneDateFormat = moment
      .tz(this.selectedCalendarTimeZone)
      .format('z');

    // Check if this timezone exists or not
    this.checkTimeZoneExists();

    // Setting up initial pagination for membersList
    this.membersList.per_page = 10;
    this.membersList.page = 0;

    // Initializing therapy session object
    this.newSession = Object.assign({}, this.newSession);

    // load the visit reasons
    this.loadVisitReasons();

    // Handle Org Config to get features
    this.initializeOrgConfig();

    // First get all the facilities from sandbox
    this.loadMyFacilities();

    // Load all active physicians
    this.loadActiveProviders();

    // Load Organizational Holidays If Any
    this.loadOrganizationalHolidays();

    // Block The Calendar Upto the current hours
    this.blockCalendarUptoNow();

    // Load Permissions
    this.loadMyPermissions();

    // Finally Build The Therapy Session Form
    this.buildForm();

    // Get all the intake Documents
    this.getIntakeDocumentsList();
  }

  public buildForm() {
    this.therapysessionForm = this.formBuilder.group({
      id: new FormControl(''),
      organizationId: new FormControl(''),
      caseId: new FormControl(''),
      caseNumber: new FormControl(''),

      title: new FormControl('', Validators.required),
      visitReasonId: new FormControl('', Validators.required),
      visitReasonName: new FormControl('', Validators.required),

      start: new FormControl(
        { value: '', disabled: true },
        Validators.required
      ),
      end: new FormControl('', Validators.required),
      sessionStartDate: new FormControl(
        { value: '', disabled: true },
        Validators.required
      ),
      sessionStartTime: new FormControl(
        { value: '', disabled: true },
        Validators.required
      ),
      sessionDuration: new FormControl(this.timeCellStep, Validators.required),

      sessionCode: new FormControl(''),
      sessionFor: new FormControl('Individual', Validators.required),
      sessionType: new FormControl(
        'In-Person Appointment',
        Validators.required
      ),

      patientId: new FormControl('', Validators.required),
      patientOrganizationId: new FormControl('', Validators.required),
      patientEmail: new FormControl(''),
      patientFirstName: new FormControl('', Validators.required),
      patientMiddleName: new FormControl(''),
      patientLastName: new FormControl('', Validators.required),
      patentDOB: new FormControl('', Validators.required),

      supervisingRenderingProvider: new FormControl('No', Validators.required),
      supervisingRPId: new FormControl(''),
      supervisingRPName: new FormControl(''),
      supervisingRPFirstName: new FormControl(''),
      supervisingRPMiddleName: new FormControl(''),
      supervisingRPLastName: new FormControl(''),
      supervisingRPEmail: new FormControl(''),
      supervisingRPPhone: new FormControl(''),

      referringProvider: new FormControl('No', Validators.required),
      referringProviderId: new FormControl(''),
      referringProviderFirstName: new FormControl(''),
      referringProviderMiddleName: new FormControl(''),
      referringProviderLastName: new FormControl(''),
      referringProviderNPI: new FormControl(''),
      referringProviderTaxonomy: new FormControl(''),

      billingProviderRefId: new FormControl('', Validators.required),

      // Billing Details
      billingType: new FormControl('', Validators.required),
      epsdtType: new FormControl(''),
      epsdtFamilyPlan: new FormControl('NA'),
      emgType: new FormControl('NA'),
      acceptAssignment: new FormControl('Yes'),
      primaryInsuranceDetails: new FormControl(null),
      primaryClaimFilingIndicator: new FormControl(null),
      primaryHealthInsuranceType: new FormControl(null),

      anotherHealthPlan: new FormControl('No', Validators.required),
      secondaryInsuranceDetails: new FormControl(null),
      secondaryClaimFilingIndicator: new FormControl(null),
      secondaryHealthInsuranceType: new FormControl(null),

      conditionRTEmployment: new FormControl('No'),
      conditionRTAutoAccident: new FormControl('No'),

      conditionRTAutoState: new FormControl(null),
      conditionRTAutoDate: new FormControl(null),
      conditionRTAutoQualifier: new FormControl(''),

      conditionRTOtherAccident: new FormControl('No'),
      icdIndicator: new FormControl(0),
      charges: this.formBuilder.array([]),
      priorAuthCode: new FormControl('No'),
      priorAuthDetails: new FormControl(null),

      // Custom Note
      sessionLevelNote: new FormControl(''),
      memberIntakeDocuments: new FormControl([]),
    });

    // WhenEver the session duration changes
    this.therapysessionForm.controls['sessionDuration'].valueChanges.subscribe(
      () => {
        this.sessionDurationChanged();
      }
    );
  }

  // Handle time zone change to display date
  timeZoneChanged() {
    this.selectedCaldendarTimeZoneDateFormat = moment
      .tz(this.selectedCalendarTimeZone)
      .format('z');
  }

  // Check if the time zone exists and if not use the CT
  checkTimeZoneExists() {
    let timeZoneExists = false;
    this.calendarTimeZones.map((zone) => {
      if (zone.id === this.selectedCalendarTimeZone) {
        timeZoneExists = true;
      }
    });
    if (!timeZoneExists) {
      this.selectedCalendarTimeZone = 'America/Chicago';
      this.timeZoneChanged();
    }
  }

  //determineSelectedProviderFacilities
  determineSelectedProviderFacilities(providerDetails) {
    let newFacilitiesSelectionList = [];

    providerDetails.facilities.forEach((facilityId) => {
      if (this.facilitiesListMap.get(facilityId)) {
        newFacilitiesSelectionList.push(this.facilitiesListMap.get(facilityId));
      }
    });

    this.selectedProviderFacilities = newFacilitiesSelectionList;

    // Check if the filtered facilities include the newly selected resource facility
    if (
      this.selectedProviderFacilities.filter(
        (facility) =>
          facility.id ===
          this.therapysessionForm.controls['billingProviderRefId'].value
      ).length == 0
    ) {
      // If the newly added facility does not exist, reset billingProviderRefId
      this.therapysessionForm.controls['billingProviderRefId'].setValue('');
    }
  }

  // Get Visit Reasons From the SandBox
  loadVisitReasons() {
    this.visitReasonsSandBox.visitReasonsLoading$.subscribe((response) => {
      // If the visit Reasons Have Loaded Successfully
      if (!response) {
        // Now the load has been complete, get the visit reasons
        this.visitReasonsSandBox.visitReasons$.subscribe((visitReasons) => {
          this.visitReasons = visitReasons;
          this.visitReasonsColorMap = new Map<string, string>();

          // Set these visit reasons in color map
          visitReasons.forEach((visitReason) => {
            this.visitReasonsColorMap.set(visitReason.id, visitReason.color);
            this.visitReasonsListMap.set(visitReason.id, visitReason);
          });
        });

        // Now Load All Sessions
        this.getAllSession();
      }
    });
  }

  // Get all sessions
  getAllSession() {
    this.dashboardsService.getSessionsForNonProvider().subscribe({
      next: (response) => {
        if (response && response?.items) {
          this.existingSession = response.items;
          this.loadExistingEvents();
        }
      },
      error: (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Unable to retrieve existing appointments'
        );
      },
    });
  }

  // Loading Mobiscroll resources
  loadExistingEvents() {
    if (this.existingSession) {
      let mobiScrollEvents = [];

      // Build the events
      this.existingSession.forEach((existingSession) => {
        let newEvent = <MbscCalendarEvent>{
          id: existingSession.id,
          resource: existingSession.physicianId,
          start: new Date(existingSession.start),
          end: new Date(existingSession.end),
          title: existingSession.title,
          color:
            existingSession.status === 'Canceled'
              ? 'darkgray'
              : this.visitReasonsColorMap.get(existingSession.visitReasonId),
          editable: false,

          status: existingSession.status,
          patientId: existingSession.patientId,
          organizationId: existingSession.organizationId,
          facilityId: existingSession.billingProviderRefId,
          facilityName: existingSession.billingProviderName,
          startTime: existingSession.sessionStartTime,
          duration: existingSession.sessionDuration,
          sessionCode: existingSession.sessionCode,
          sessionType: existingSession.sessionType,
          sessionFor: existingSession.sessionFor,
          groupAppointment: existingSession.groupAppointment,
        };

        mobiScrollEvents.push(newEvent);
      });

      // Finally append these events with existing events
      this.myEvents = [...this.myEvents, ...mobiScrollEvents];
    }
  }

  // Get the required orgconfig Information
  initializeOrgConfig() {
    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        // When load is complete, get the org config from app state
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          // Saving the org config
          this.orgConfig = orgConfig;

          // Check for organization features
          if (this.orgConfig && this.orgConfig?.features) {
            this.hasTelehealthFeature =
              this.orgConfig.features.includes('TELEHEALTH_ENABLED');

            this.hasCareFeature =
              this.orgConfig.features.includes('CASE_MANAGEMENT');

            this.consolidatedMemberSearch = this.orgConfig.features.includes(
              'CONSOLIDATED_MEMBER_SEARCH'
            );

            this.showMemberNumber = this.orgConfig.features.includes(
              'MANUAL_MEMBER_NUMBER'
            );

            if (this.consolidatedMemberSearch) {
              this.loadMembersForTheSelectedFacility();
            }
          }
        });
      }
    });
  }

  // Get My Permissions
  loadMyPermissions() {
    this.permissionsSandbox.permissions$.subscribe((response) => {
      this.hasMemberAddAccess = hasAccess(
        this.keycloakService,
        'MEMBERS_ADD',
        response,
        null
      );

      this.hasSessionsViewAccess = hasAccess(
        this.keycloakService,
        'THERAPY_SESSIONS_VIEW',
        response,
        null
      );

      this.hasCaseAddAccess = hasAccess(
        this.keycloakService,
        'CASES_ADD',
        response,
        null
      );

      this.hasMemberViewAccess = hasAccess(
        this.keycloakService,
        'MEMBERS_VIEW',
        response,
        null
      );
    });
  }

  // Load All my facilities
  loadMyFacilities() {
    this.facilitiesSandbox.facilities$.subscribe((response) => {
      if (response) {
        this.myFacilities = response;
        this.filteredFacilities = response;
        this.facilitiesSelectionList = response;

        this.loadFacilitiesMap();
        this.loadVisibleFacilitiesProvidersSet();
      }
    });
  }

  loadFacilitiesMap() {
    this.myFacilities.forEach((facility) => {
      this.facilitiesListMap.set(facility.id, facility);
    });
  }

  // Load All Active Providers
  loadActiveProviders() {
    this.providersListLoading = true;
    this.dashboardsService.getAllActiveProviders().subscribe({
      next: (response) => {
        if (response && response?.items) {
          this.providersList = response.items;
          this.supervisingProvidersList = response.items;
          this.filteredSupervisingProviderList = response.items;

          // Load These Providers as Resources for MobiScroll
          this.loadMobiScrollResources();

          // If there is providersList
          this.loadScheduleForAllProviders();
          this.loadTimeOffsForAllProviders();
        }
        this.providersListLoading = false;
      },
      error: (displayErrorMessage) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to retreive providers at the member facility.'
        );
        this.providersListLoading = false;
      },
    });
  }

  // Loading Resources for Mobi Scroll
  loadMobiScrollResources() {
    this.allResources = [];

    // Pushing each provider as resource
    this.providersList.forEach((provider) => {
      // Load provider as mobiscroll resource
      this.allResources.push({
        id: provider.id,
        name: provider.lastName + ', ' + provider.firstName,
      });
      // Load Provider to Map
      this.providersListMap.set(provider.id, provider);

      // Just Setting the Time map as null for now
      this.providersScheduleTimeOffMap.set(provider.id, {
        providerId: provider.id,
        workingHours: null,
      });
    });

    this.filteredResources = [...this.allResources];
    this.filteredAllResources = [...this.allResources];

    // If the list is greater than 3, deselect all providers
    if (this.providersList.length > 3) {
      this.filteredResources = [];
    }

    // If provider select the logged in provider, auto select them
    if (this.isPhysician) {
      let filteredPhysician = this.filteredAllResources.filter(
        (provider) => provider.id === this.loggedInUserId
      );
      if (filteredPhysician && filteredPhysician.length == 1) {
        if (this.filteredResources.indexOf(filteredPhysician.at(0)) < 0) {
          this.filteredResources = [
            ...this.filteredResources,
            ...filteredPhysician,
          ];
        }
      }
    }
  }

  // Load Schedule for all Providers
  loadScheduleForAllProviders() {
    // Obserable to handle multiple http call
    const allproviderScheduleTimeOffObservable: Observable<any>[] = [];

    // Get Schedule and Time Offs for all proviers
    allproviderScheduleTimeOffObservable.push(
      this.getAllProvidersWorkingHoursAndTimeOffs().pipe(
        map((responseList) => {
          // For Providers Schedule
          if (responseList[0]?.items) {
            // Now loop through each schedule and set the provider time
            responseList[0].items.forEach((providerSchedule) => {
              // First check if this provider is in schedule map
              if (
                this.providersScheduleTimeOffMap.get(
                  providerSchedule.careProviderId
                )
              ) {
                // Now as we have this provider update their set
                let currentTimeOffMapValue =
                  this.providersScheduleTimeOffMap.get(
                    providerSchedule.careProviderId
                  );
                currentTimeOffMapValue.workingHours = providerSchedule;

                this.providersScheduleTimeOffMap.set(
                  providerSchedule.careProviderId,
                  currentTimeOffMapValue
                );
              }
            });
          }

          // For Providers Time Offs
          return responseList;
        }),
        catchError((error) => {
          // If there is an error, display the error in toast message
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load working hours and time offs'
          );
          return of(null);
        })
      )
    );

    // Once all the providers time off and schedule has been loaded
    forkJoin(allproviderScheduleTimeOffObservable).subscribe(() => {
      // Now that the time Off and Working hours have been populated
      this.loadResourceSchedule();
    });
  }

  public getAllProvidersWorkingHoursAndTimeOffs(): Observable<any[]> {
    let allWorkingHours =
      this.workinghoursdialogapiservice.getAllMyProvidersSchedules();

    return forkJoin([allWorkingHours]);
  }

  // Load Time Off for all Providers
  loadTimeOffsForAllProviders() {
    this.timeOffAPIService.getAllMyProviderTimeOffsForSession().subscribe({
      next: (response) => {
        if (response && response?.items) {
          // For each time off block the resource calendar
          response.items.forEach((timeOff) => {
            // Start Date For Invalid in Mobi Scroll
            let startDate = new Date(timeOff.startDay);
            // End Date For Invalid in Mobi Scroll
            let endDate = new Date(timeOff.endDay);

            // Set End Date to be the start of the next day of end date
            // endDate.setDate(endDate.getDate() + 1);

            // Invalid Date Structure as per Mobi Scroll
            let invalidDateInCalendar = {
              start: startDate,
              end: endDate,
              title: timeOff?.reason,
              resource: timeOff.careProviderId,
              cssClass: 'md-rect-bg',
            };

            // Now update existing invalid and add this invalid date aswell
            this.invalids = [...this.invalids, invalidDateInCalendar];
            this.invalidColors = [...this.invalidColors, invalidDateInCalendar];
          });
        }
      },
      error: (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to load time offs for providers'
        );
      },
    });
  }

  // Get All Organizational Holidays
  loadOrganizationalHolidays() {
    this.organizationalTimeOffLoading = true;
    this.timeOffAPIService.getOrganizationTimeOff(200, 0).subscribe({
      next: (response) => {
        if (response && response.items) {
          this.organizationalTimeOffs = response.items;

          // Now add these dates to invalids
          this.loadOrganizationalHolidaysOnMBSC();
        }
        this.organizationalTimeOffLoading = false;
      },
      error: (error) => {
        this.organizationalTimeOffLoading = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to retreive organizational holidays.'
        );
      },
    });
  }

  // Load Organizational Holidays as invalid in Mobiscroll
  loadOrganizationalHolidaysOnMBSC() {
    // Loop through each holiday for organization and mark them as invalid dates in calendar
    this.organizationalTimeOffs.forEach((holiday) => {
      // Start Date For Invalid in Mobi Scroll
      let startDate = new Date(holiday.startDay);
      // End Date For Invalid in Mobi Scroll
      let endDate = new Date(holiday.endDay);
      // Set End Date to be the start of the next day of end date
      // endDate.setDate(endDate.getDate() + 1);

      // Invalid Date Structure as per Mobi Scroll
      let invalidDateInCalendar = {
        start: addMinutes(startDate, 2),
        end: endDate,
        title: holiday?.reason,
        cssClass: 'md-stripes-gray-bg',
      };
      // Now update existing invalid and add this invalid date aswell
      this.invalids = [...this.invalids, invalidDateInCalendar];
      this.invalidColors = [...this.invalidColors, invalidDateInCalendar];
    });
  }

  // Load Resource Schedules
  loadResourceSchedule() {
    // As of this point, the provider's schedule and time offs have been loaded
    // For Each Provider, we now need to load their working hours and block any time offs
    this.providersScheduleTimeOffMap.forEach((providerMap) => {
      // Now Load Each Provider Schedule
      this.loadResourceWorkingHours(
        providerMap.providerId,
        providerMap.workingHours
      );
    });

    // Now that all the resource working hours have been populated, determining the calendar start and end time
    this.determineCalendarStartEndTime();
  }

  // Determining calendar start and end time
  determineCalendarStartEndTime() {
    // Handle the time
    if (this.allWorkingHoursArray.length > 0) {
      // For check if there are numbers greater than 2400
      this.allWorkingHoursArray = this.allWorkingHoursArray.map((num) =>
        num >= 2400 ? 0 : num
      );

      // Now that we have converted the all working hours array
      let lowStart = Math.min(...this.allWorkingHoursArray);
      let highEnd = Math.max(...this.allWorkingHoursArray);

      // Now convert them into appropriate format
      let startTimeHour = Math.trunc(lowStart / 100);
      let startTimeHourString = startTimeHour.toString();

      let endTimeHour = Math.trunc(highEnd / 100);
      //endTimeHour = endTimeHour + 1;
      let endTimeHourString = endTimeHour.toString();

      if (startTimeHourString.length === 1) {
        startTimeHourString = '0' + startTimeHourString;
      }

      if (endTimeHourString.length === 1) {
        endTimeHourString = '0' + endTimeHourString;
      }

      // Now append this as the calendar start and end time range
      this.calendarStartHour = startTimeHourString + ':00';
      this.calendarEndHour = endTimeHourString + ':00';

      // Once the star and end Time hour has been determined
      this.responsiveMobiScrollCalendarView = {
        // Min-width:0px
        xsmall: {
          view: {
            schedule: {
              type: 'day',
              ...this.initialResponsiveView,
              startTime: this.calendarStartHour,
              endTime: this.calendarEndHour,
            },
          },
        },
        // min-width: 768px
        medium: {
          view: {
            schedule: {
              type: 'week',
              ...this.initialResponsiveView,
              startTime: this.calendarStartHour,
              endTime: this.calendarEndHour,
            },
          },
        },
      };
    }

    // Navigate to current time
    this.mbscCalendar.navigate(new Date(), true);
  }

  // Load Calendar Working Hours For Each Provider/Resource
  loadResourceWorkingHours(resourceId, workingHoursData) {
    // Making Sure that the working hours Data exists for this provider
    if (workingHoursData && workingHoursData?.id) {
      // Loop Through each day to find the provider's working hours
      this.determineWorkingHourForDay(
        resourceId,
        'SU',
        workingHoursData?.sunday
      );
      this.determineWorkingHourForDay(
        resourceId,
        'MO',
        workingHoursData?.monday
      );
      this.determineWorkingHourForDay(
        resourceId,
        'TU',
        workingHoursData?.tuesday
      );
      this.determineWorkingHourForDay(
        resourceId,
        'WE',
        workingHoursData?.wednesday
      );
      this.determineWorkingHourForDay(
        resourceId,
        'TH',
        workingHoursData?.thursday
      );
      this.determineWorkingHourForDay(
        resourceId,
        'FR',
        workingHoursData?.friday
      );
      this.determineWorkingHourForDay(
        resourceId,
        'SA',
        workingHoursData?.saturday
      );
    } else {
      // If The Working Hour of the provider is not set by default use 9-5
      this.handleDefaultWorkingHoursForResource(resourceId);
    }
  }

  // Handle the working hours for each day for each resource
  determineWorkingHourForDay(resourceId, day, data) {
    // If this day is an off day, block the day for this resource
    if (data.offday) {
      this.blockAllDayForResource(resourceId, day);
    } else {
      // If this is not a off day, now determine their working hours
      if (data?.workingHours) {
        // Now as there is working hours defined
        if (data.workingHours?.length == 1) {
          this.addSingleStartEndTime(
            resourceId,
            day,
            data.workingHours[0].startTime,
            data.workingHours[0].endTime
          );
        } else if (data.workingHours?.length > 1) {
          this.addMultipleStartEndTime(resourceId, day, data.workingHours);
        }
      }
    }
  }

  // Setting Default working hours to be 9-5 for the passed in resource
  handleDefaultWorkingHoursForResource(resourceId) {
    // Block Saturday and Sundays First
    this.blockAllDayForResource(resourceId, 'SA');
    this.blockAllDayForResource(resourceId, 'SU');

    // For Monday To Friday set 9-5
    this.addSingleStartEndTime(resourceId, 'MO', 900, 1700);
    this.addSingleStartEndTime(resourceId, 'TU', 900, 1700);
    this.addSingleStartEndTime(resourceId, 'WE', 900, 1700);
    this.addSingleStartEndTime(resourceId, 'TH', 900, 1700);
    this.addSingleStartEndTime(resourceId, 'FR', 900, 1700);
  }

  // Block Calendar Time For Single start and end time passed
  addSingleStartEndTime(
    resourceId: string,
    day: string,
    startTime: number,
    endTime: number
  ) {
    // Push this startTime & EndTime to the hours array
    this.allWorkingHoursArray.push(startTime, endTime);

    // block the time before start time and after the end time
    let dayStartTime = '00:00';
    let dayEndTime = '24:00';

    let startTimeHour = Math.trunc(startTime / 100);
    let startTimeHourString = startTimeHour.toString();
    let startTimeMinutes = startTime % 100;
    let startTimeMinutesString = startTimeMinutes.toString();

    let endTimeHour = Math.trunc(endTime / 100);
    let endTimeHourString = endTimeHour.toString();
    let endTimeMinutes = endTime % 100;
    let endTimeMinutesString = endTimeMinutes.toString();

    // Handling for 24
    if (startTimeHour == 24) {
      startTimeHourString = '00';
    }
    if (endTimeHour == 24) {
      endTimeHourString = '00';
    }

    // Converting to Correct Format
    if (startTimeHourString.length === 1) {
      startTimeHourString = '0' + startTimeHourString;
    }
    if (endTimeHourString.length === 1) {
      endTimeHourString = '0' + endTimeHourString;
    }
    if (startTimeMinutesString.length === 1) {
      startTimeMinutesString = startTimeMinutesString + '0';
    }
    if (endTimeMinutesString.length === 1) {
      endTimeMinutesString = endTimeMinutesString + '0';
    }

    // Now block the time for this resource
    this.invalids = [
      ...this.invalids,
      {
        start: dayStartTime,
        end: startTimeHourString + ':' + startTimeMinutesString,
        resource: resourceId,
        recurring: {
          repeat: 'weekly',
          weekDays: day,
        },
      },
      {
        start: endTimeHourString + ':' + endTimeMinutesString,
        end: dayEndTime,
        resource: resourceId,
        recurring: {
          repeat: 'weekly',
          weekDays: day,
        },
      },
    ];
  }

  // Block Calendar Time For Multiple start and end time passed
  addMultipleStartEndTime(resourceId: string, day: string, workingHours) {
    // block the time before start time and after the end time
    let dayStartTime = '00:00';
    let dayEndTime = '24:00';

    workingHours.forEach((workHour, index) => {
      let firstIndex = index == 0;
      let lastIndex = index == workingHours.length - 1;

      // Push this startTime & EndTime to the hours array
      this.allWorkingHoursArray.push(workHour.startTime, workHour.endTime);

      let startTimeHour = Math.trunc(workHour.startTime / 100);
      let startTimeHourString = startTimeHour.toString();
      let startTimeMinutes = workHour.startTime % 100;
      let startTimeMinutesString = startTimeMinutes.toString();

      let endTimeHour = Math.trunc(workHour.endTime / 100);
      let endTimeHourString = endTimeHour.toString();
      let endTimeMinutes = workHour.endTime % 100;
      let endTimeMinutesString = endTimeMinutes.toString();

      if (startTimeHour == 24) {
        startTimeHourString = '00';
      }
      if (endTimeHour == 24) {
        endTimeHourString = '00';
      }

      // Converting to Correct Format
      if (startTimeHourString.length === 1) {
        startTimeHourString = '0' + startTimeHourString;
      }
      if (endTimeHourString.length === 1) {
        endTimeHourString = '0' + endTimeHourString;
      }
      if (startTimeMinutesString.length === 1) {
        startTimeMinutesString = '0' + startTimeMinutesString;
      }
      if (endTimeMinutesString.length === 1) {
        endTimeMinutesString = '0' + endTimeMinutesString;
      }

      if (firstIndex) {
        // Get the Next Index Start Time
        let nextIndexStartTimeHour = Math.trunc(
          workingHours[1].startTime / 100
        );
        let nextIndexStartTimeHourString = nextIndexStartTimeHour.toString();
        let nextIndexStartTimeMinutes = workingHours[1].startTime % 100;
        let nextIndexStartTimeMinutesString =
          nextIndexStartTimeMinutes.toString();
        // Converting to the right format
        if (nextIndexStartTimeHourString.length === 1) {
          nextIndexStartTimeHourString = '0' + nextIndexStartTimeHourString;
        }
        if (nextIndexStartTimeMinutesString.length === 1) {
          nextIndexStartTimeMinutesString =
            '0' + nextIndexStartTimeMinutesString;
        }
        // Blocking from start of day to start of time and end of time to next index start
        this.invalids = [
          ...this.invalids,
          {
            start: dayStartTime,
            end: startTimeHourString + ':' + startTimeMinutesString,
            resource: resourceId,
            recurring: {
              repeat: 'weekly',
              weekDays: day,
            },
          },
          {
            start: endTimeHourString + ':' + endTimeMinutesString,
            end:
              nextIndexStartTimeHourString +
              ':' +
              nextIndexStartTimeMinutesString,
            resource: resourceId,
            recurring: {
              repeat: 'weekly',
              weekDays: day,
            },
          },
        ];
      } else if (lastIndex) {
        // For last Index just block from last index end time to the end of the day
        this.invalids = [
          ...this.invalids,
          {
            start: endTimeHourString + ':' + endTimeMinutesString,
            end: dayEndTime,
            resource: resourceId,
            recurring: {
              repeat: 'weekly',
              weekDays: day,
            },
          },
        ];
      } else {
        // For multiple start and end times within the start and end index
        // Get the Next Index Start Time
        let previousIndexEndTimeHour = Math.trunc(
          workingHours[index - 1].endTime / 100
        );
        let previousIndexEndTimeHourString =
          previousIndexEndTimeHour.toString();
        let previousIndexEndTimeMinutes = workingHours[index - 1].endTime % 100;
        let previousIndexEndTimeMinutesString =
          previousIndexEndTimeMinutes.toString();
        // Converting to the right format
        if (previousIndexEndTimeHourString.length === 1) {
          previousIndexEndTimeHourString = '0' + previousIndexEndTimeHourString;
        }
        if (previousIndexEndTimeMinutesString.length === 1) {
          previousIndexEndTimeMinutesString =
            '0' + previousIndexEndTimeMinutesString;
        }

        // Get the Next Index Start Time
        let nextIndexStartTimeHour = Math.trunc(
          workingHours[index + 1].startTime / 100
        );
        let nextIndexStartTimeHourString = nextIndexStartTimeHour.toString();
        let nextIndexStartTimeMinutes = workingHours[index + 1].startTime % 100;
        let nextIndexStartTimeMinutesString =
          nextIndexStartTimeMinutes.toString();
        // Converting to the right format
        if (nextIndexStartTimeHourString.length === 1) {
          nextIndexStartTimeHourString = '0' + nextIndexStartTimeHourString;
        }
        if (nextIndexStartTimeMinutesString.length === 1) {
          nextIndexStartTimeMinutesString =
            '0' + nextIndexStartTimeMinutesString;
        }

        // Blocking from previos end to current start and current end to next start
        this.invalids = [
          ...this.invalids,
          {
            start:
              previousIndexEndTimeHourString +
              ':' +
              previousIndexEndTimeMinutesString,
            end: startTimeHourString + ':' + startTimeMinutesString,
            resource: resourceId,
            recurring: {
              repeat: 'weekly',
              weekDays: day,
            },
          },
          {
            start: endTimeHourString + ':' + endTimeMinutesString,
            end:
              nextIndexStartTimeHourString +
              ':' +
              nextIndexStartTimeMinutesString,
            resource: resourceId,
            recurring: {
              repeat: 'weekly',
              weekDays: day,
            },
          },
        ];
      }
    });
  }

  // Block all day for this resource
  blockAllDayForResource(resourceId, day) {
    // Push this startTime & EndTime to the hours array
    this.allWorkingHoursArray.push(800, 1700);

    // Blocking all Day for the passed in day for the Resource
    this.invalids = [
      ...this.invalids,
      {
        resource: resourceId,
        recurring: {
          repeat: 'weekly',
          weekDays: day,
        },
      },
    ];
  }

  // Block calendar upto now
  blockCalendarUptoNow() {
    this.invalids = [
      ...this.invalids,
      // Giving extra cushion to schedule appointments for the past 24 hours
      {
        recurring: {
          repeat: 'daily',
          until: this.dayBeforeYesterday,
        },
      },
      // Giving extra cushion to schedule appointments for the past 24 hours
      //{
      //   start: this.dayBeforeYesterday,
      //   end: this.currentTime,
      // },
    ];
  }

  // Calendar Type Changed
  calendarTypeChanged(event) {
    this.calendarViewType = event.value;

    this.responsiveMobiScrollCalendarView = {
      // Min-width:0px
      xsmall: {
        view: {
          schedule: {
            type: 'day',
            ...this.initialResponsiveView,
            startTime: this.calendarStartHour,
            endTime: this.calendarEndHour,
          },
        },
      },
      // min-width: 768px
      medium: {
        view: {
          schedule: {
            type: event.value,
            ...this.initialResponsiveView,
            startTime: this.calendarStartHour,
            endTime: this.calendarEndHour,
          },
        },
      },
    };
  }

  // Create a set to load all active providers list in a set for performance
  loadVisibleFacilitiesProvidersSet() {
    let newVisibleProvidersSet = new Set<string>();
    // For All visible facilities, append the doctors/providers
    this.filteredFacilities.forEach((visibleFacility) => {
      if (visibleFacility?.doctors) {
        visibleFacility.doctors.forEach((provider) => {
          newVisibleProvidersSet.add(provider);
        });
      }
    });

    this.filteredFacilitiesProviders = newVisibleProvidersSet;
  }

  // Disable if this resource is not in the facilty
  isResourceFacilityVisible(provider) {
    // Check if the current providers set has this provider id
    return this.filteredFacilitiesProviders.has(provider.id);
  }

  // This is for resource checkbox
  isResourceVisible(provider) {
    return this.filteredResources.includes(provider);
  }

  // Show or hide resource
  selectDeselctResource(event, provider) {
    // Event checked => true meaning add the provider back
    if (event.checked) {
      // If the filtered list does not include the provider then add the provider to the resource to display
      if (!this.filteredResources.includes(provider)) {
        this.filteredResources = [...this.filteredResources, provider];

        // Handle Resource Filter
        this.handleResourceFilter();
      }
    } else {
      // If the filtered list does include the provider then remove the provider to the resource to display
      if (this.filteredResources.includes(provider)) {
        this.filteredResources = this.filteredResources.filter(
          (resource) => resource != provider
        );

        // Handle Resource Filter
        this.handleResourceFilter();
      }
    }
  }

  // Quick Actions To Select/Deselect all providers
  selectAllProviders() {
    this.filteredResources = [...this.allResources];

    // Handle Resource Filter
    this.handleResourceFilter();
  }

  deselectAllProviders() {
    this.filteredResources = [];

    // Handle Resource Filter
    this.handleResourceFilter();
  }

  // Search By provider Name Changed
  searchByProviderNameChanged() {
    if (this.searchProviderName) {
      this.filteredAllResources = this.allResources.filter((resource) => {
        const nameParts = resource.name.split(', ');
        const lastName = nameParts[0].toLowerCase();
        const firstName = nameParts[1].toLowerCase();

        const concatFirstLast = firstName + ' ' + lastName;
        const concatLastFirst = lastName + ' ' + firstName;

        if (
          concatFirstLast.includes(this.searchProviderName.toLowerCase()) ||
          concatLastFirst.includes(this.searchProviderName.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.filteredAllResources = [...this.allResources];
    }
  }

  // For Facilities Options
  isFacilityVisible(facility) {
    return this.filteredFacilities.includes(facility);
  }

  // Facility search box
  searchByFacilityNameChanged() {
    if (this.searchFacilityName) {
      this.facilitiesSelectionList = this.myFacilities.filter((facility) => {
        if (
          facility.facilityName
            .toLowerCase()
            .includes(this.searchFacilityName.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.facilitiesSelectionList = [...this.myFacilities];
    }
  }

  selectDeselctFacility(event, facility) {
    if (event.checked) {
      // Add the facility to the list
      if (!this.filteredFacilities.includes(facility)) {
        this.filteredFacilities = [...this.filteredFacilities, facility];

        // Handle Providers Map Change
        this.loadVisibleFacilitiesProvidersSet();

        // Handle Resource Filter
        this.handleResourceFilter();
      }
    } else {
      // Remove the facility to the list
      if (this.filteredFacilities.includes(facility)) {
        this.filteredFacilities = this.filteredFacilities.filter(
          (resource) => resource != facility
        );

        // Handle Providers Map Change
        this.loadVisibleFacilitiesProvidersSet();

        // Handle Resource Filter
        this.handleResourceFilter();
      }
    }
  }

  selectAllFacilities() {
    this.filteredFacilities = [...this.myFacilities];

    // Handle Providers Map Change
    this.loadVisibleFacilitiesProvidersSet();

    // Handle Resource filter
    this.handleResourceFilter();
  }

  deselectAllFacilities() {
    this.filteredFacilities = [];

    // Handle Providers Map Change
    this.loadVisibleFacilitiesProvidersSet();

    // Handle Resource filter
    this.handleResourceFilter();
  }

  handleResourceFilter() {
    // Need to Filter current visible resource based on facility
    let newFilteredResource = [];

    // For Each filtered resource check if their facility is also visible
    this.filteredResources.forEach((resource) => {
      if (this.filteredFacilitiesProviders.has(resource.id)) {
        newFilteredResource.push(resource);
      }
    });

    // This is the final filtered Resource
    this.filteredResources = newFilteredResource;
  }

  // For Days Range Format Label
  formatDaySliderLabel(value: number): string {
    switch (value) {
      case 0:
        return 'Sunday';
      case 1:
        return 'Monday';
      case 2:
        return 'Tuesday';
      case 3:
        return 'Wednesday';
      case 4:
        return 'Thursday';
      case 5:
        return 'Friday';
      case 6:
        return 'Saturday';
      default:
        return '';
    }
  }

  // Customize which days to display on Mobiscroll calendar
  sliderValueChanged() {
    this.responsiveMobiScrollCalendarView = {
      // Min-width:0px
      xsmall: {
        view: {
          schedule: {
            type: 'day',
            ...this.initialResponsiveView,
            startTime: this.calendarStartHour,
            endTime: this.calendarEndHour,
            startDay: this.calendarStartDay,
            endDay: this.calendarEndDay,
          },
        },
      },
      // min-width: 768px
      medium: {
        view: {
          schedule: {
            type: this.calendarViewType,
            ...this.initialResponsiveView,
            startTime: this.calendarStartHour,
            endTime: this.calendarEndHour,
            startDay: this.calendarStartDay,
            endDay: this.calendarEndDay,
          },
        },
      },
    };
  }

  timeCellStepChanged() {
    this.timeLabelStep = this.timeCellStep;

    this.responsiveMobiScrollCalendarView = {
      // Min-width:0px
      xsmall: {
        view: {
          schedule: {
            type: 'day',
            ...this.initialResponsiveView,
            startTime: this.calendarStartHour,
            endTime: this.calendarEndHour,
            startDay: this.calendarStartDay,
            endDay: this.calendarEndDay,
            timeCellStep: this.timeCellStep,
            timeLabelStep: this.timeLabelStep,
          },
        },
      },
      // min-width: 768px
      medium: {
        view: {
          schedule: {
            type: this.calendarViewType,
            ...this.initialResponsiveView,
            startTime: this.calendarStartHour,
            endTime: this.calendarEndHour,
            startDay: this.calendarStartDay,
            endDay: this.calendarEndDay,
            timeCellStep: this.timeCellStep,
            timeLabelStep: this.timeLabelStep,
          },
        },
      },
    };
  }

  // Navigate to Member's page
  navigateToMemberPage(row) {
    // If only the user has access to view members
    if (this.hasMemberViewAccess) {
      this.router.navigate(['/main/member/' + row.patientId + '/NOTES']);
    }
  }

  // Navigate to sessions page
  navigateToSessionPage(row) {
    if (this.hasSessionsViewAccess) {
      if (row?.groupAppointment) {
        // For Normal navigate to session edit
        this.router.navigate([`/group-sessions/${row.id}`], {
          queryParams: {
            parent: 'Home',
          },
        });
      } else {
        // For Normal navigate to session edit
        // Check if this is a group or regular session
        this.router.navigate(['/main/member/' + row.patientId + '/NOTES'], {
          queryParams: {
            secondaryId: row.id,
          },
        });
      }
    }
  }

  // New Service Location selected
  serviceFacilityChanged(event) {
    // Now Load Members
    this.loadMembersForTheSelectedFacility();
  }

  // Search Member Changed
  searchByNameChanged() {
    this.membersList.page = 0;

    this.loadMembersForTheSelectedFacility();
  }

  getNext(event: PageEvent) {
    this.membersList.page = event.pageIndex;
    this.loadMembersForTheSelectedFacility();
  }

  // Loading Existing Member for this facility
  loadMembersForTheSelectedFacility() {
    this.isMembersLoading = true;

    this.patientService
      .getPatients(
        this.consolidatedMemberSearch
          ? null
          : this.therapysessionForm.controls['billingProviderRefId'].value,
        null,
        this.memberFirstName,
        this.memberLastName,
        this.memberPhone,
        'Active',
        this.membersList.per_page,
        this.membersList.page
      )
      .subscribe({
        next: (response) => {
          if (response && response.items) {
            this.membersList.items = response.items;
            this.membersList.total = response.total;
          } else {
            this.membersList.items = [];
            this.membersList.total = 0;
          }
          this.isMembersLoading = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load members at the selected facility'
          );
          this.isMembersLoading = false;
        },
      });
  }

  // Add New Member if the user has Add Member Permission
  addMember() {
    const dialogRef = this.dialog.open(AddEditPatientComponent, {
      data: {
        action: 'ADD',
        patient: null,
        facilityId:
          this.therapysessionForm.controls['billingProviderRefId'].value,
        showMemberNumber: this.showMemberNumber,
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '40vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadMembersForTheSelectedFacility();
      }
    });
  }

  // Select the member
  selectMember(member: Patient) {
    this.patient = member;

    // Populate the member details and then go to next step
    this.therapysessionForm.controls['patientId'].setValue(member.id);
    this.therapysessionForm.controls['patientOrganizationId'].setValue(
      member.organizationId
    );
    this.therapysessionForm.controls['patientEmail'].setValue(member.email);
    this.therapysessionForm.controls['patientFirstName'].setValue(
      member.firstName
    );
    this.therapysessionForm.controls['patientMiddleName'].setValue(
      member.middleName
    );
    this.therapysessionForm.controls['patientLastName'].setValue(
      member.lastName
    );
    this.therapysessionForm.controls['patentDOB'].setValue(member.dateOfBirth);

    // Load Active Cases if the care feature is there
    if (this.hasCareFeature) {
      this.loadAllActiveCareCases();
    }

    // Reset the insurance Details
    this.therapysessionForm.controls['primaryInsuranceDetails'].setValue(null);
    this.therapysessionForm.controls['secondaryInsuranceDetails'].setValue(
      null
    );
    this.therapysessionForm.controls['priorAuthDetails'].setValue(null);

    // Reset the appointment title
    let newVisitReasonId =
      this.therapysessionForm.controls['visitReasonId'].value;
    if (newVisitReasonId) {
      let newVisitReasonDetails =
        this.visitReasonsListMap.get(newVisitReasonId);
      let newTitle = `${this.therapysessionForm.controls['patientLastName'].value}, ${this.therapysessionForm.controls['patientFirstName'].value} - ${newVisitReasonDetails.reasonName}`;
      this.newSession.title = newTitle;
    } else {
      let newTitle = `${this.therapysessionForm.controls['patientLastName'].value}, ${this.therapysessionForm.controls['patientFirstName'].value} - New Appointment`;
      this.newSession.title = newTitle;
    }

    // Now Set the appropriate End Time
    this.setNewEndTime();

    // Now move to the next screen
    this.stepper.next();
  }

  visitReasonChanged() {
    let newVisitReasonId =
      this.therapysessionForm.controls['visitReasonId'].value;

    let newVisitReasonDetails = this.visitReasonsListMap.get(newVisitReasonId);

    // Now Set the session title
    let newTitle = `${this.therapysessionForm.controls['patientLastName'].value}, ${this.therapysessionForm.controls['patientFirstName'].value} - ${newVisitReasonDetails.reasonName}`;

    // First update the session object
    this.newSession.title = newTitle;
    this.newSession.sessionDuration = newVisitReasonDetails.duration;

    // Set form control
    this.therapysessionForm.controls['title'].setValue(newTitle);
    this.therapysessionForm.controls['visitReasonName'].setValue(
      newVisitReasonDetails?.reasonName
    );
    this.therapysessionForm.controls['sessionDuration'].setValue(
      newVisitReasonDetails?.duration
    );

    // Now Set the appropriate End Time
    this.setNewEndTime();
  }

  // Set the new end Time
  setNewEndTime() {
    let newVisitReasonId =
      this.therapysessionForm.controls['visitReasonId'].value;
    let newEndTime = addMinutes(
      this.newSession.start,
      this.newSession.sessionDuration
    );
    this.newSession.end = newEndTime;
    this.therapysessionForm.controls['end'].setValue(newEndTime);

    // Now update the mobiscroll event
    this.myEvents = this.myEvents.map((event) => {
      if (event.id == null) {
        // Update this event
        event.title = this.newSession.title;
        event.end = newEndTime;
        // event.color = this.visitReasonsColorMap.get(newVisitReasonId);
        event.duration = this.newSession.sessionDuration;
      }
      return event;
    });
  }

  //  Session Duration Changed
  sessionDurationChanged() {
    let newSessionDuration =
      this.therapysessionForm.controls['sessionDuration'].value;

    if (this.newSession.start) {
      this.newSession.sessionDuration = newSessionDuration;

      this.setNewEndTime();
    }
  }

  // If there is case management and a case has been associated with it
  careCaseChanged(event) {
    let filteredCase = this.existingCaseList.filter(
      (temp) => temp.id === event.value
    );

    if (filteredCase[0]) {
      // Set the case number
      this.therapysessionForm.controls['caseNumber'].setValue(
        filteredCase[0]?.caseNumber
      );
    } else {
      // Reset the case number
      this.therapysessionForm.controls['caseNumber'].setValue(
        filteredCase[0]?.caseNumber
      );
    }
  }

  addNewCase() {
    const dialogRef = this.dialog.open(AddEditCaseDialogComponent, {
      data: {
        action: 'ADD',
        case: null,
        patientId: this.therapysessionForm.controls['patientId'].value,
        facilityId:
          this.therapysessionForm.controls['billingProviderRefId'].value,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response === 'success') {
        this.loadAllActiveCareCases();
      }
    });
  }

  // Change for supervisingProviderChanged
  supervisingProviderChanged(event) {
    if (event.value === 'Yes') {
      this.therapysessionForm
        .get('supervisingRPId')
        .addValidators(Validators.required);
      this.therapysessionForm.get('supervisingRPId').updateValueAndValidity();
    } else if (event.value === 'No') {
      this.therapysessionForm.get('supervisingRPId').setValue('');
      this.therapysessionForm.get('supervisingRPId').clearValidators();
      this.therapysessionForm.get('supervisingRPId').updateValueAndValidity();

      this.therapysessionForm.get('supervisingRPName').setValue('');
      this.therapysessionForm.get('supervisingRPFirstName').setValue('');
      this.therapysessionForm.get('supervisingRPMiddleName').setValue('');
      this.therapysessionForm.get('supervisingRPLastName').setValue('');
      this.therapysessionForm.get('supervisingRPEmail').setValue('');
      this.therapysessionForm.get('supervisingRPPhone').setValue('');
    }
  }

  // New Supervising provider selected
  newSupervisorSelected() {
    let newSupervisorId = this.therapysessionForm.get('supervisingRPId').value;
    let supervisorDetails = this.providersListMap.get(newSupervisorId);
    // Now Update the therapy session form to have this new value
    this.therapysessionForm.controls['supervisingRPName'].setValue(
      supervisorDetails?.firstName
    );
    this.therapysessionForm.controls['supervisingRPFirstName'].setValue(
      supervisorDetails?.firstName
    );
    this.therapysessionForm.controls['supervisingRPMiddleName'].setValue(
      supervisorDetails?.middleName
    );
    this.therapysessionForm.controls['supervisingRPLastName'].setValue(
      supervisorDetails?.lastName
    );
    this.therapysessionForm.controls['supervisingRPEmail'].setValue(
      supervisorDetails?.emailAddress
    );
    this.therapysessionForm.controls['supervisingRPPhone'].setValue(
      supervisorDetails?.phoneNumber
    );
  }

  // Search Filter for supervising provider
  filterSupervisingProvider() {
    if (this.searchSupervisingProviderName) {
      this.filteredSupervisingProviderList =
        this.supervisingProvidersList.filter((provider) => {
          const concatFirstLast = provider.firstName + ' ' + provider.lastName;
          const concatLastFirst = provider.lastName + ' ' + provider.firstName;

          if (
            concatFirstLast
              .toLowerCase()
              .includes(this.searchSupervisingProviderName.toLowerCase()) ||
            concatLastFirst
              .toLowerCase()
              .includes(this.searchSupervisingProviderName.toLowerCase())
          ) {
            return true;
          } else {
            return false;
          }
        });
    } else {
      this.filteredSupervisingProviderList = [...this.supervisingProvidersList];
    }
  }

  // Change for supervisingProviderChanged
  referringProviderChanged(event) {
    if (event.value === 'Yes') {
      this.therapysessionForm
        .get('referringProviderId')
        .addValidators(Validators.required);
      this.therapysessionForm
        .get('referringProviderId')
        .updateValueAndValidity();
    } else if (event.value === 'No') {
      this.therapysessionForm.get('referringProviderId').setValue('');
      this.therapysessionForm.get('referringProviderId').clearValidators();
      this.therapysessionForm
        .get('referringProviderId')
        .updateValueAndValidity();

      this.therapysessionForm.get('referringProviderFirstName').setValue('');
      this.therapysessionForm.get('referringProviderMiddleName').setValue('');
      this.therapysessionForm.get('referringProviderLastName').setValue('');
      this.therapysessionForm.get('referringProviderNPI').setValue('');
      this.therapysessionForm.get('referringProviderTaxonomy').setValue('');
    }
  }

  // Call in box to select referring providers
  selectReferringProvider() {
    let dialogRef = this.dialog.open(SelectReferringProviderComponent, {
      data: {},
      autoFocus: false,
      disableClose: true,
      minWidth: '45vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        this.therapysessionForm.controls['referringProviderId'].setValue(
          response.details.id
        );
        this.therapysessionForm.controls['referringProviderFirstName'].setValue(
          response.details.firstName
        );
        this.therapysessionForm.controls[
          'referringProviderMiddleName'
        ].setValue(response.details.middleName);
        this.therapysessionForm.controls['referringProviderLastName'].setValue(
          response.details.lastName
        );
        this.therapysessionForm.controls['referringProviderNPI'].setValue(
          response.details.individualNPI
        );
        this.therapysessionForm.controls['referringProviderTaxonomy'].setValue(
          response.details.taxonomyCode
        );
      }
    });
  }

  // Billing type changed, change validators
  billingTypeChanged(event) {
    if (event.value === 'Insurance') {
      this.therapysessionForm
        .get('primaryInsuranceDetails')
        .addValidators(Validators.required);
      this.therapysessionForm
        .get('primaryInsuranceDetails')
        .updateValueAndValidity();
      this.therapysessionForm.get('primaryInsuranceDetails').markAsTouched();
    } else if (event.value === 'Self-Pay') {
      this.therapysessionForm.get('primaryInsuranceDetails').clearValidators();
      this.therapysessionForm
        .get('primaryInsuranceDetails')
        .updateValueAndValidity();
    }
  }

  secondaryInsuranceChaged(event) {
    if (event.value === 'Yes') {
      this.therapysessionForm
        .get('secondaryInsuranceDetails')
        .addValidators(Validators.required);
      this.therapysessionForm
        .get('secondaryInsuranceDetails')
        .updateValueAndValidity();
      this.therapysessionForm.get('secondaryInsuranceDetails').markAsTouched();
    } else {
      this.therapysessionForm.get('secondaryInsuranceDetails').setValue(null);
      this.therapysessionForm
        .get('secondaryInsuranceDetails')
        .clearValidators();
      this.therapysessionForm
        .get('secondaryInsuranceDetails')
        .updateValueAndValidity();
    }
  }

  // When EPSDT is restored, restore type
  epsdtTypeChanged(event) {
    if (event.value === 'NA' || event.value === 'No') {
      // Restore epsdt type
      this.therapysessionForm.controls['epsdtType'].setValue('');
    }
  }

  // Add/edit primary/secondary insurance
  searchInsurance(type: string) {
    let dialogRef = this.dialog.open(IntakeSearchAddInsuranceComponent, {
      data: {
        patient: this.patient,
        therapySession: null,
        parentClass: 'TherapySession',
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '55vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response && response?.type === 'success') {
        if (type === 'Primary') {
          this.therapysessionForm.controls['primaryInsuranceDetails'].setValue(
            response.insurance
          );
          this.therapysessionForm.controls[
            'primaryClaimFilingIndicator'
          ].setValue(response.insurance.claimFilingIndicator);
          this.therapysessionForm.controls[
            'primaryHealthInsuranceType'
          ].setValue(response.insurance.healthInsuranceType);
        } else {
          this.therapysessionForm.controls[
            'secondaryInsuranceDetails'
          ].setValue(response.insurance);
          this.therapysessionForm.controls[
            'secondaryClaimFilingIndicator'
          ].setValue(response.insurance.claimFilingIndicator);
          this.therapysessionForm.controls[
            'secondaryHealthInsuranceType'
          ].setValue(response.insurance.healthInsuranceType);
        }
      }
    });
  }

  // Remove Insurance
  removeInsurance(type: string) {
    if (type === 'Primary') {
      this.therapysessionForm.controls['primaryInsuranceDetails'].setValue(
        null
      );
    } else {
      this.therapysessionForm.controls['secondaryInsuranceDetails'].setValue(
        null
      );
    }
  }

  // Auto Accident type changed
  autoAccidentTypeChanged(event) {
    if (event.value === 'Yes') {
      this.therapysessionForm
        .get('conditionRTAutoState')
        .addValidators(Validators.required);
      this.therapysessionForm
        .get('conditionRTAutoState')
        .updateValueAndValidity();

      this.therapysessionForm
        .get('conditionRTAutoDate')
        .addValidators(Validators.required);
      this.therapysessionForm
        .get('conditionRTAutoDate')
        .updateValueAndValidity();

      this.therapysessionForm
        .get('conditionRTAutoQualifier')
        .addValidators(Validators.required);
      this.therapysessionForm
        .get('conditionRTAutoQualifier')
        .updateValueAndValidity();
    } else if (event.value === 'No') {
      this.therapysessionForm.get('conditionRTAutoState').setValue('');
      this.therapysessionForm.get('conditionRTAutoState').clearValidators();
      this.therapysessionForm
        .get('conditionRTAutoState')
        .updateValueAndValidity();

      this.therapysessionForm.get('conditionRTAutoDate').setValue(null);
      this.therapysessionForm.get('conditionRTAutoDate').clearValidators();
      this.therapysessionForm
        .get('conditionRTAutoDate')
        .updateValueAndValidity();

      this.therapysessionForm.get('conditionRTAutoQualifier').setValue('');
      this.therapysessionForm.get('conditionRTAutoQualifier').clearValidators();
      this.therapysessionForm
        .get('conditionRTAutoQualifier')
        .updateValueAndValidity();
    }
  }

  // Other Accident type changed
  otherAccidentTypeChanged(event) {
    if (event.value === 'Yes') {
      this.therapysessionForm
        .get('conditionRTAutoDate')
        .addValidators(Validators.required);
      this.therapysessionForm
        .get('conditionRTAutoDate')
        .updateValueAndValidity();
    } else if (event.value === 'No') {
      this.therapysessionForm.get('conditionRTAutoDate').setValue(null);
      this.therapysessionForm.get('conditionRTAutoDate').clearValidators();
      this.therapysessionForm
        .get('conditionRTAutoDate')
        .updateValueAndValidity();
    }
  }

  // Prior Authorization details changed
  priorAuthChanged(event) {
    if (event.value === 'Yes') {
      this.therapysessionForm
        .get('priorAuthDetails')
        .addValidators(Validators.required);
      this.therapysessionForm.get('priorAuthDetails').updateValueAndValidity();
      this.therapysessionForm.get('priorAuthDetails').markAsTouched();
    } else if (event.value === 'No') {
      this.therapysessionForm.get('priorAuthDetails').setValue(null);
      this.therapysessionForm.get('priorAuthDetails').clearValidators();
      this.therapysessionForm.get('priorAuthDetails').updateValueAndValidity();
    }
  }

  // Look up prior Authorizations
  lookUpPriorAuth() {
    let dialogRef = this.dialog.open(EditPriorAuthComponent, {
      data: {
        patient: this.patient,
        therapySession: this.newSession,
        primaryInsuranceDetails:
          this.therapysessionForm.controls['primaryInsuranceDetails'].value,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '55vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        this.therapysessionForm.controls['priorAuthDetails'].setValue(
          response.details
        );
      }
    });
  }

  // For capturing session charges
  addCharge() {
    let dialogRef = this.dialog.open(SearchAddProcedureCodeComponent, {
      data: {
        parentClass: 'TherapySession',
        patient: this.patient,
        primaryInsurance:
          this.therapysessionForm.controls['primaryInsuranceDetails'].value,
        billingProviderId:
          this.therapysessionForm.controls['billingProviderRefId'].value,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '70vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        this.pushCharge(response.cptCode);
      }
    });
  }

  // Push charge to therapy session charge object
  pushCharge(cptCode) {
    const control = <FormArray>this.therapysessionForm.get('charges');
    let index = control.value.length;

    control.push(
      new FormGroup({
        cptIndex: new FormControl(index + 1),
        id: new FormControl(cptCode.id),
        cptCode: new FormControl(cptCode.cptCode),
        description: new FormControl(cptCode.description),
        rate: new FormControl(cptCode.rate),
        defaultUnit: new FormControl(cptCode.defaultUnit),
        charges: new FormControl(cptCode.rate * cptCode.defaultUnit),
        duration: new FormControl(cptCode.duration),
        durationUnit: new FormControl(cptCode.durationUnit),
        modifier1: new FormControl(cptCode.modifier1),
        modifier2: new FormControl(cptCode.modifier2),
        modifier3: new FormControl(cptCode.modifier3),
        modifier4: new FormControl(cptCode.modifier4),
        diagnosisPointer: new FormControl(cptCode.diagnosisPointer),
      })
    );
  }

  // Edit current charge
  editCharge(row, index) {
    let dialogRef = this.dialog.open(EditConfirmProcedureCodeComponent, {
      data: {
        cptCode: row,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '40vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        let cptCode = response.cptCode;
        // Get form control at index
        const control = <FormArray>this.therapysessionForm.get('charges');
        // Get Form Group and update the data
        let subFormGroup = control.at(index) as FormGroup;

        subFormGroup.controls['description'].setValue(cptCode.description);
        subFormGroup.controls['rate'].setValue(cptCode.rate);
        subFormGroup.controls['defaultUnit'].setValue(cptCode.defaultUnit);
        subFormGroup.controls['duration'].setValue(cptCode.duration);
        subFormGroup.controls['durationUnit'].setValue(cptCode.durationUnit);
        subFormGroup.controls['charges'].setValue(
          cptCode.rate * cptCode.defaultUnit
        ),
          subFormGroup.controls['modifier1'].setValue(cptCode.modifier1);
        subFormGroup.controls['modifier2'].setValue(cptCode.modifier2);
        subFormGroup.controls['modifier3'].setValue(cptCode.modifier3);
        subFormGroup.controls['modifier4'].setValue(cptCode.modifier4);
        subFormGroup.controls['diagnosisPointer'].setValue(
          cptCode.diagnosisPointer
        );
      }
    });
  }

  // Remove current charge
  removeCharge(index) {
    const control = <FormArray>this.therapysessionForm.get('charges');
    control.removeAt(index);

    // Resizing the index
    for (let i = index; i < control.length; i++) {
      let subFormGroup = control.at(i) as FormGroup;
      subFormGroup.controls['cptIndex'].setValue(i + 1);
    }
  }

  // If this organization has care log feature
  loadAllActiveCareCases() {
    // Loading Existing Active Cases
    this.caseApiService
      .getCases(
        this.therapysessionForm.controls['patientId'].value,
        null,
        null,
        null,
        'active',
        100,
        0
      )
      .subscribe({
        next: (response) => {
          if (response && response.items) {
            this.existingCaseList = response.items;
          } else {
            this.existingCaseList = [];
          }
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load active case management cases'
          );
        },
      });
  }

  // Finally submit the form
  submitForm() {
    // First assign all
    this.newSession = Object.assign(
      this.newSession,
      this.therapysessionForm.value
    );

    this.processing = true;

    this.therapySessionService
      .createNewTherapySession(this.newSession)
      .subscribe({
        next: (response) => {
          this.processing = false;
          this.toastMessageService.displaySuccessMessage(
            'New Session has been successfully created.'
          );

          this.router.navigate(['/']);
        },
        error: (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            "Error: Something wen't wrong while creating a new session."
          );
        },
      });
  }

  // Get Intake Documents list
  getIntakeDocumentsList() {
    this.consentDocumentApiService
      .getOrganizationAllIntakeDocuments()
      .subscribe({
        next: (response) => {
          if (response && response.data) {
            let consentDocuments = response.data?.consentDocuments;
            let profileForms = response.data?.profileForms;
            let medicalIntakeForms = response.data?.medicalIntakeForms;

            // Now update the intakeDocumentsList
            this.intakeDocumentsList.forEach((documentType) => {
              if (documentType.id === 'consentDocuments') {
                documentType.documents = consentDocuments;
              } else if (documentType.id === 'profileForms') {
                documentType.documents = profileForms;
              } else if (documentType.id === 'medicalIntakeForms') {
                documentType.documents = medicalIntakeForms;
              }
            });

            this.loadCustomFormList();
          }
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load intake document categories.'
          );
        },
      });
  }

  loadCustomFormList() {
    this.customformApiService.getActiveAndPublishedForms().subscribe(
      (response) => {
        if (response && response.items) {
          const documents: { [key: string]: any }[] = [];

          this.intakeDocumentsList.forEach((documentType) => {
            if (documentType.id === 'customForms') {
              response.items.forEach((item) => {
                // Create a new object with the updated keys
                const updatedItem: { [key: string]: any } = {
                  documentName: item.formName,
                  documentType: 'Custom Forms',
                  consentRenewal: 'Yearly',
                };
                documents.push(updatedItem);
              });
              documentType.documents = documents;
            }
          });
          // Filter the empty ones
          this.intakeDocumentsList = this.intakeDocumentsList.filter(
            (document) =>
              document.documents != null && document.documents.length > 0
          );
        }
      },
      (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to load custom forms.'
        );
      }
    );
  }

  removeIntakeDocument(document, index) {
    let existingIntakeDocuments = this.therapysessionForm.controls[
      'memberIntakeDocuments'
    ].value as [];

    existingIntakeDocuments.splice(index, 1);

    this.therapysessionForm.controls['memberIntakeDocuments'].setValue(
      existingIntakeDocuments
    );
  }
}
