<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>>Alcohol Addiction Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="col-md-12" style="padding: 0">
  <div
    *ngIf="assessmentId && !isPatient"
    class="row"
    style="
      margin-bottom: 20px;
      display: flex;
      align-content: center;
      flex-direction: column;
      font-weight: 450;
    "
  >
    Risk Score
    <button
      mat-fab
      color="primary"
      aria-label="Score"
      style="margin-left: 10px"
    >
      {{ riskScore }}
    </button>
  </div>

  <form
    [formGroup]="alcoholAddictionForm"
    novalidate
    (ngSubmit)="onAlcoholAddictionFormSubmit()"
  >
    <div class="row pad10">
      <label class="col-md-9 asterix-after"
        >1. Have you ever felt you needed to Cut down on your drinking?</label
      >
      <div class="col-sm-3">
        <mat-button-toggle-group
          formControlName="cutDownOnDrinking"
          [disabled]="disableFormElements"
        >
          <mat-button-toggle value="Yes">Yes</mat-button-toggle>
          <mat-button-toggle value="No">No</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10">
      <label class="col-md-9 asterix-after"
        >2. Have people Annoyed you by criticizing your drinking?</label
      >
      <div class="col-sm-3">
        <mat-button-toggle-group
          formControlName="criticizingDrinkingHabits"
          [disabled]="disableFormElements"
        >
          <mat-button-toggle value="Yes">Yes</mat-button-toggle>
          <mat-button-toggle value="No">No</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10">
      <label class="col-md-9 asterix-after"
        >3. Have you ever felt Guilty about drinking?</label
      >
      <div class="col-sm-3">
        <mat-button-toggle-group
          formControlName="guiltyAboutDrinking"
          [disabled]="disableFormElements"
        >
          <mat-button-toggle value="Yes">Yes</mat-button-toggle>
          <mat-button-toggle value="No">No</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10">
      <label class="col-md-9 asterix-after"
        >4. Have you ever felt you needed a drink first thing in the morning
        (Eye-opener) to steady your nerves or to get rid of a hangover?</label
      >
      <div class="col-sm-3">
        <mat-button-toggle-group
          formControlName="drinkFirstThingInTheMorning"
          [disabled]="disableFormElements"
        >
          <mat-button-toggle value="Yes">Yes</mat-button-toggle>
          <mat-button-toggle value="No">No</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          alcoholAddictionForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!alcoholAddictionForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
