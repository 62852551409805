<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Patient Health Questionnaire</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="patienthealthquestionnaireForm"
    novalidate
    (ngSubmit)="onPatientHealthQuestionnaireFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-sm-5 asterix-after"
            >1. Little interest or pleasure in doing things
          </label>
          <div class="col-sm-7">
            <mat-button-toggle-group formControlName="littleInterest">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="Several Days"
                >Several Days</mat-button-toggle
              >
              <mat-button-toggle value="More Than Half the Days"
                >More Than Half the Days
              </mat-button-toggle>
              <mat-button-toggle value="Nearly Every Day"
                >Nearly Every Day</mat-button-toggle
              >
            </mat-button-toggle-group>
            <mat-error
              *ngFor="let validation of validation_messages.littleInterest"
            >
              <mat-error
                class="error-message"
                *ngIf="
                  patienthealthquestionnaireForm
                    .get('littleInterest')
                    .hasError(validation.type) &&
                  (patienthealthquestionnaireForm.get('littleInterest').dirty ||
                    patienthealthquestionnaireForm.get('littleInterest')
                      .touched)
                "
              >
                {{ validation.message }}</mat-error
              >
            </mat-error>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-5 asterix-after"
            >2. Feeling down, depressed or hopeless</label
          >
          <div class="col-sm-7">
            <mat-button-toggle-group formControlName="feelingDown">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="Several Days"
                >Several Days</mat-button-toggle
              >
              <mat-button-toggle value="More Than Half the Days"
                >More Than Half the Days
              </mat-button-toggle>
              <mat-button-toggle value="Nearly Every Day"
                >Nearly Every Day</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-5 asterix-after"
            >3. Trouble falling asleep, staying asleep, or sleeping too
            much</label
          >
          <div class="col-sm-7">
            <mat-button-toggle-group formControlName="troubleSleeping">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="Several Days"
                >Several Days</mat-button-toggle
              >
              <mat-button-toggle value="More Than Half the Days"
                >More Than Half the Days
              </mat-button-toggle>
              <mat-button-toggle value="Nearly Every Day"
                >Nearly Every Day</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-5 asterix-after"
            >4. Feeling tired or having little energy</label
          >
          <div class="col-sm-7">
            <mat-button-toggle-group formControlName="feelTired">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="Several Days"
                >Several Days</mat-button-toggle
              >
              <mat-button-toggle value="More Than Half the Days"
                >More Than Half the Days
              </mat-button-toggle>
              <mat-button-toggle value="Nearly Every Day"
                >Nearly Every Day</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-5 asterix-after"
            >5. Poor appetite or overeating</label
          >
          <div class="col-sm-7">
            <mat-button-toggle-group formControlName="poorAppetite">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="Several Days"
                >Several Days</mat-button-toggle
              >
              <mat-button-toggle value="More Than Half the Days"
                >More Than Half the Days
              </mat-button-toggle>
              <mat-button-toggle value="Nearly Every Day"
                >Nearly Every Day</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-5 asterix-after"
            >6. Feeling bad about yourself - or that you’re a failure or have
            let yourself or your family down</label
          >
          <div class="col-sm-7">
            <mat-button-toggle-group formControlName="feelingBadAboutSelf">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="Several Days"
                >Several Days</mat-button-toggle
              >
              <mat-button-toggle value="More Than Half the Days"
                >More Than Half the Days
              </mat-button-toggle>
              <mat-button-toggle value="Nearly Every Day"
                >Nearly Every Day</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-5 asterix-after"
            >7. Trouble concentrating on things, such as reading the newspaper
            or watching television</label
          >
          <div class="col-sm-7">
            <mat-button-toggle-group formControlName="troubleConcentrating">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="Several Days"
                >Several Days</mat-button-toggle
              >
              <mat-button-toggle value="More Than Half the Days"
                >More Than Half the Days
              </mat-button-toggle>
              <mat-button-toggle value="Nearly Every Day"
                >Nearly Every Day</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-5 asterix-after"
            >8. Moving or speaking so slowly that other people could have
            noticed. Or, the opposite - being so fidgety or restless that you
            have been moving around a lot more than usual</label
          >
          <div class="col-sm-7">
            <mat-button-toggle-group formControlName="slowComparedToOthers">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="Several Days"
                >Several Days</mat-button-toggle
              >
              <mat-button-toggle value="More Than Half the Days"
                >More Than Half the Days
              </mat-button-toggle>
              <mat-button-toggle value="Nearly Every Day"
                >Nearly Every Day</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-5 asterix-after"
            >9. Thoughts that you would be better off dead or of hurting
            yourself in some way</label
          >
          <div class="col-sm-7">
            <mat-button-toggle-group formControlName="badThoughts">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="Several Days"
                >Several Days</mat-button-toggle
              >
              <mat-button-toggle value="More Than Half the Days"
                >More Than Half the Days
              </mat-button-toggle>
              <mat-button-toggle value="Nearly Every Day"
                >Nearly Every Day</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          patienthealthquestionnaireForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!patienthealthquestionnaireForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
