import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ITherapySession } from 'src/app/_models';
import { ClaimsAPIService, ToastMessageService } from 'src/app/_services';

@Component({
  selector: 'app-archive-claim-confirmation',
  templateUrl: './archive-claim-confirmation.component.html',
  styleUrl: './archive-claim-confirmation.component.css',
})
export class ArchiveClaimConfirmationComponent implements OnInit {
  therapySession: ITherapySession;
  archiveForm: FormGroup;
  processing: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ArchiveClaimConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public claimsApiService: ClaimsAPIService,
    public toastMessageService: ToastMessageService
  ) {
    this.therapySession = data.therapySession;
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.archiveForm = this.formBuilder.group({
      message: new FormControl('', Validators.required),
    });
  }

  submitForm() {
    this.processing = true;
    this.claimsApiService
      .markPrimaryClaimAsArchived(
        this.therapySession.id,
        this.therapySession.patientId,
        this.archiveForm.controls['message'].value
      )
      .subscribe({
        next: (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully marked the claim as "Archived"'
          );
          this.processing = false;
          this.dialogRef.close({ type: 'success', data: response.data });
        },
        error: (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to mark the claim as "Archived"'
          );
        },
      });
  }
}
