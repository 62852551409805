<div class="main-content" style="background-color: #eee" *ngIf="!isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div class="container-fluid" *ngIf="!isLoadingResults">
        <br />
        <div class="row">
            <div class="col-md-6">
                <mat-card>
                    <mat-card-header>
                        <h4 class="header text-center">Risk Level Historical Chart</h4>
                    </mat-card-header>
                    <mat-card-content>
                        <div style="width: 100%">
                            <div class="chart-wrapper">
                                <canvas baseChart height="100%" [data]="lineChartData" [options]="lineChartOptions"
                                    [legend]="lineChartLegend" [type]="lineChartType" [plugins]="lineChartPlugins">
                                </canvas>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-md-6">
                <mat-card>
                    <mat-card-header>
                        <h4 class="header text-center">Risk Levels as of
                            {{lineChartData.labels[lineChartData.labels.length-1]}}
                        </h4>
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    <mat-card-content>
                        <br />
                        <div class="row pad10">
                            <div class="col-md-3">
                                <mat-card class="mat-elevation-z8">
                                    <h4 class="header text-center">Tobacco Risk Level</h4>
                                    <mat-divider></mat-divider>
                                    <mat-card-content>
                                        <div class="row" style="padding:2px">
                                            <div class="col-md-2">
                                                <button
                                                    *ngIf="+lineChartData.datasets[0].data[lineChartData.datasets[0].data.length-1] === 0.3"
                                                    mat-raised-button class="btn btn-just-icon btn-fab btn-round"
                                                    style="height: 20px; width: 20px; min-width: 20px; background-color: #5a9e7c;"></button>
                                                <button
                                                    *ngIf="+lineChartData.datasets[0].data[lineChartData.datasets[0].data.length-1] === 1"
                                                    mat-raised-button class="btn btn-just-icon btn-fab btn-round"
                                                    style="height: 20px; width: 20px; min-width: 20px; background-color: #ffcd56;"></button>
                                                <button
                                                    *ngIf="+lineChartData.datasets[0].data[lineChartData.datasets[0].data.length-1] === 2"
                                                    mat-raised-button class="btn btn-just-icon btn-fab btn-round"
                                                    style="height: 20px; width: 20px; min-width: 20px; background-color: #ff8d33;"></button>
                                                <button
                                                    *ngIf="+lineChartData.datasets[0].data[lineChartData.datasets[0].data.length-1] === 3"
                                                    mat-raised-button class="btn btn-just-icon btn-fab btn-round"
                                                    style="height: 20px; width: 20px; min-width: 20px; background-color: #ff6384;"></button>
                                            </div>
                                            <div class="col-md-10">
                                                <span
                                                    *ngIf="+lineChartData.datasets[0].data[lineChartData.datasets[0].data.length-1] === 0.3">Minimal
                                                    Risk </span>
                                                <span
                                                    *ngIf="+lineChartData.datasets[0].data[lineChartData.datasets[0].data.length-1] === 1">In
                                                    Early Remission, But a Risk </span>
                                                <span
                                                    *ngIf="+lineChartData.datasets[0].data[lineChartData.datasets[0].data.length-1] === 2">Problem
                                                    Use: Risk for Adverse Outcomes </span>
                                                <span
                                                    *ngIf="+lineChartData.datasets[0].data[lineChartData.datasets[0].data.length-1] === 3">High
                                                    Risk </span>
                                                for Tobacco Use Disorder
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <mat-divider></mat-divider>
                                                <small
                                                    *ngIf="+lineChartData.datasets[0].data[lineChartData.datasets[0].data.length-1] === 0"><b>Implications: </b>
                                                    <i>Patients who report no past 12 months tobacco use are currently
                                                        at minimal risk for adverse outcomes associated with tobacco
                                                        use.
                                                        <br />
                                                        Note: Those who report tobacco use in previous years but have
                                                        not used in the past 12 months are at risk for relapse.
                                                    </i></small>
                                                <small
                                                    *ngIf="+lineChartData.datasets[0].data[lineChartData.datasets[0].data.length-1] === 1"><b>Implications: </b>
                                                    <i>Since the patient reports tobacco use during the past 12 months,
                                                        they may still be at risk for adverse outcomes despite no use in
                                                        the past 3 months and may meet DSM-5 criteria for Tobacco Use
                                                        Disorder.</i></small>
                                                <small
                                                    *ngIf="+lineChartData.datasets[0].data[lineChartData.datasets[0].data.length-1] === 2"><b>Implications: </b>
                                                    <i>Patients with this risk level are using tobacco in ways that can
                                                        have important implications for their health, and may meet DSM-5
                                                        criteria for Tobacco Use Disorder at a lower level of severity.
                                                        They are at risk for adverse outcomes associated with their
                                                        tobacco use.</i></small>
                                                <small
                                                    *ngIf="+lineChartData.datasets[0].data[lineChartData.datasets[0].data.length-1] === 3"><b>Implications: </b>
                                                    <i>Patients with this result are at high
                                                        risk for adverse outcomes related to tobacco use and are highly
                                                        likely to meet DSM-5 criteria for a Tobacco Use
                                                        Disorder.</i></small>
                                            </div>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                            <div class="col-md-3">
                                <mat-card class="mat-elevation-z8">
                                    <h4 class="header text-center">Alcohol Risk Level</h4>
                                    <mat-divider></mat-divider>
                                    <mat-card-content>
                                        <div class="row" style="padding:2px">
                                            <div class="col-md-2">
                                                <button
                                                    *ngIf="+lineChartData.datasets[1].data[lineChartData.datasets[1].data.length-1] === 0.3"
                                                    mat-raised-button class="btn btn-just-icon btn-fab btn-round"
                                                    style="height: 20px; width: 20px; min-width: 20px; background-color: #5a9e7c;"></button>
                                                <button
                                                    *ngIf="+lineChartData.datasets[1].data[lineChartData.datasets[1].data.length-1] === 1"
                                                    mat-raised-button class="btn btn-just-icon btn-fab btn-round"
                                                    style="height: 20px; width: 20px; min-width: 20px; background-color: #ffcd56;"></button>
                                                <button
                                                    *ngIf="+lineChartData.datasets[1].data[lineChartData.datasets[1].data.length-1] === 2"
                                                    mat-raised-button class="btn btn-just-icon btn-fab btn-round"
                                                    style="height: 20px; width: 20px; min-width: 20px; background-color: #ff8d33;"></button>
                                                <button
                                                    *ngIf="+lineChartData.datasets[1].data[lineChartData.datasets[1].data.length-1] === 3"
                                                    mat-raised-button class="btn btn-just-icon btn-fab btn-round"
                                                    style="height: 20px; width: 20px; min-width: 20px; background-color: #ff6384;"></button>
                                            </div>
                                            <div class="col-md-10">
                                                <span
                                                    *ngIf="+lineChartData.datasets[1].data[lineChartData.datasets[1].data.length-1] === 0.3">Minimal
                                                    Risk </span>
                                                <span
                                                    *ngIf="+lineChartData.datasets[1].data[lineChartData.datasets[1].data.length-1] === 1">In
                                                    Early Remission, But a Risk </span>
                                                <span
                                                    *ngIf="+lineChartData.datasets[1].data[lineChartData.datasets[1].data.length-1] === 2">Problem
                                                    Use - Risk for Adverse Outcomes </span>
                                                <span
                                                    *ngIf="+lineChartData.datasets[1].data[lineChartData.datasets[1].data.length-1] === 3">High
                                                    Risk </span>
                                                for Alcohol Use Disorder
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <mat-divider></mat-divider>
                                                <small
                                                    *ngIf="+lineChartData.datasets[1].data[lineChartData.datasets[1].data.length-1] === 0"><b>Implications: </b>
                                                    <i>Patients who report no past 12 months alcohol use are currently
                                                        at minimal risk for adverse outcomes associated with alcohol
                                                        use.
                                                        <br />
                                                        Note: Those who report alcohol use in previous years but have
                                                        not used in the past 12 months are at risk for
                                                        relapse.</i></small>
                                                <small
                                                    *ngIf="+lineChartData.datasets[1].data[lineChartData.datasets[1].data.length-1] === 1"><b>Implications: </b>
                                                    <i>Since the patient reports alcohol use during the past 12 months,
                                                        they may still be at risk for adverse outcomes despite no use in
                                                        the past 3 months and may meet DSM-5 criteria for Alcohol Use
                                                        Disorder.</i></small>
                                                <small
                                                    *ngIf="+lineChartData.datasets[1].data[lineChartData.datasets[1].data.length-1] === 2"><b>Implications: </b>
                                                    <i>Patients with this risk level are using alcohol in ways that can
                                                        have important implications for their health and may meet DSM-5
                                                        criteria for Alcohol Use Disorder at a lower level of severity.
                                                        They are at risk for adverse outcomes associated with their
                                                        alcohol use, including accidents.</i></small>
                                                <small
                                                    *ngIf="+lineChartData.datasets[1].data[lineChartData.datasets[1].data.length-1] === 3"><b>Implications: </b>
                                                    <i>Patients with this result are at high
                                                        risk for adverse outcomes related to alcohol use and are highly
                                                        likely to meet DSM-5 criteria for an Alcohol Use
                                                        Disorder.</i></small>
                                            </div>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                            <div class="col-md-3">
                                <mat-card class="mat-elevation-z8">
                                    <h4 class="header text-center">Sedatives Risk Level</h4>
                                    <mat-divider></mat-divider>
                                    <mat-card-content>
                                        <div class="row" style="padding:2px">
                                            <div class="col-md-2">
                                                <button
                                                    *ngIf="+lineChartData.datasets[2].data[lineChartData.datasets[2].data.length-1] === 0.3"
                                                    mat-raised-button class="btn btn-just-icon btn-fab btn-round"
                                                    style="height: 20px; width: 20px; min-width: 20px; background-color: #5a9e7c;"></button>
                                                <button
                                                    *ngIf="+lineChartData.datasets[2].data[lineChartData.datasets[2].data.length-1] === 1"
                                                    mat-raised-button class="btn btn-just-icon btn-fab btn-round"
                                                    style="height: 20px; width: 20px; min-width: 20px; background-color: #ffcd56;"></button>
                                                <button
                                                    *ngIf="+lineChartData.datasets[2].data[lineChartData.datasets[2].data.length-1] === 2"
                                                    mat-raised-button class="btn btn-just-icon btn-fab btn-round"
                                                    style="height: 20px; width: 20px; min-width: 20px; background-color: #ff8d33;"></button>
                                                <button
                                                    *ngIf="+lineChartData.datasets[2].data[lineChartData.datasets[2].data.length-1] === 3"
                                                    mat-raised-button class="btn btn-just-icon btn-fab btn-round"
                                                    style="height: 20px; width: 20px; min-width: 20px; background-color: #ff6384;"></button>
                                            </div>
                                            <div class="col-md-10">
                                                <span
                                                    *ngIf="+lineChartData.datasets[2].data[lineChartData.datasets[2].data.length-1] === 0.3">Minimal
                                                    Risk </span>
                                                <span
                                                    *ngIf="+lineChartData.datasets[2].data[lineChartData.datasets[2].data.length-1] === 1">Undetermined
                                                    Risk </span>
                                                <span
                                                    *ngIf="+lineChartData.datasets[2].data[lineChartData.datasets[2].data.length-1] === 2">Problem
                                                    Use - Risk for Adverse Outcomes </span>
                                                <span
                                                    *ngIf="+lineChartData.datasets[2].data[lineChartData.datasets[2].data.length-1] === 3">High
                                                    Risk </span>
                                                for Sedatives Use Disorder
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <mat-divider></mat-divider>
                                                <small
                                                    *ngIf="+lineChartData.datasets[2].data[lineChartData.datasets[2].data.length-1] === 0"><b>Implications: </b>
                                                    <i>Patients who report no past 12 months sedative use are currently
                                                        at minimal risk for adverse outcomes associated with sedative
                                                        use.
                                                        <br />
                                                        Note: Those who report sedative use in previous years but have
                                                        not used in the past 12 years are at risk for relapse.
                                                    </i></small>
                                                <small
                                                    *ngIf="+lineChartData.datasets[2].data[lineChartData.datasets[2].data.length-1] === 1"><b>Implications: </b>
                                                    <i>Since the patient may have used sedatives during the past 12
                                                        months, they may still be at risk for adverse outcomes despite
                                                        no use in the past 3 months and may meet DSM-5 criteria for
                                                        Sedative Use Disorder.
                                                        <br />
                                                        Note: Patients who report no past 12 months sedative use are
                                                        currently at an undetermined risk for adverse outcomes
                                                        associated with sedative use. Those who report sedative use in
                                                        previous years but have not used in the past 12 months may be at
                                                        risk for relapse or other complications.</i></small>
                                                <small
                                                    *ngIf="+lineChartData.datasets[2].data[lineChartData.datasets[2].data.length-1] === 2"><b>Implications: </b>
                                                    <i>Patients with this risk level are using sedatives (for example:
                                                        Xanax, Ativan, Klonopin, etc.) in ways that can have important
                                                        implications for their health and may meet DSM-5 criteria for
                                                        Sedative Use Disorder at a lower level of severity. They are at
                                                        some risk for adverse outcomes associated with their sedative
                                                        use.</i></small>
                                                <small
                                                    *ngIf="+lineChartData.datasets[2].data[lineChartData.datasets[2].data.length-1] === 3"><b>Implications: </b>
                                                    <i>Patients with this result are at high
                                                        risk for adverse outcomes related to sedative use (for example:
                                                        Xanax, Ativan, Klonopin, etc.) and are likely to meet DSM-5
                                                        criteria for Sedative Use Disorder.</i></small>
                                            </div>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                            <div class="col-md-3">
                                <mat-card class="mat-elevation-z8">
                                    <h4 class="header text-center">Cannabis Risk Level</h4>
                                    <mat-divider></mat-divider>
                                    <mat-card-content>
                                        <div class="row" style="padding:2px">
                                            <div class="col-md-2">
                                                <button
                                                    *ngIf="+lineChartData.datasets[3].data[lineChartData.datasets[3].data.length-1] === 0.3"
                                                    mat-raised-button class="btn btn-just-icon btn-fab btn-round"
                                                    style="height: 20px; width: 20px; min-width: 20px; background-color: #5a9e7c;"></button>
                                                <button
                                                    *ngIf="+lineChartData.datasets[3].data[lineChartData.datasets[3].data.length-1] === 1"
                                                    mat-raised-button class="btn btn-just-icon btn-fab btn-round"
                                                    style="height: 20px; width: 20px; min-width: 20px; background-color: #ffcd56;"></button>
                                                <button
                                                    *ngIf="+lineChartData.datasets[3].data[lineChartData.datasets[3].data.length-1] === 2"
                                                    mat-raised-button class="btn btn-just-icon btn-fab btn-round"
                                                    style="height: 20px; width: 20px; min-width: 20px; background-color: #ff8d33;"></button>
                                                <button
                                                    *ngIf="+lineChartData.datasets[3].data[lineChartData.datasets[3].data.length-1] === 3"
                                                    mat-raised-button class="btn btn-just-icon btn-fab btn-round"
                                                    style="height: 20px; width: 20px; min-width: 20px; background-color: #ff6384;"></button>
                                            </div>
                                            <div class="col-md-10">
                                                <span
                                                    *ngIf="+lineChartData.datasets[3].data[lineChartData.datasets[3].data.length-1] === 0.3">Minimal
                                                    Risk </span>
                                                <span
                                                    *ngIf="+lineChartData.datasets[3].data[lineChartData.datasets[3].data.length-1] === 1">Undetermined
                                                    Risk </span>
                                                <span
                                                    *ngIf="+lineChartData.datasets[3].data[lineChartData.datasets[3].data.length-1] === 2">Problem
                                                    Use - Risk for Adverse Outcomes</span>
                                                <span
                                                    *ngIf="+lineChartData.datasets[3].data[lineChartData.datasets[3].data.length-1] === 3">High
                                                    Risk </span>
                                                for Cannabis Use Disorder
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <mat-divider></mat-divider>
                                                <small
                                                    *ngIf="+lineChartData.datasets[3].data[lineChartData.datasets[3].data.length-1] === 0"><b>Implications: </b>
                                                    <i>Patients who report no past 12 months cannabis use are currently
                                                        at minimal risk for adverse outcomes associated with cannabis
                                                        use.
                                                        <br />
                                                        Note: Those who report cannabis use in previous years but have
                                                        not used in the past 12 months are at risk for relapse.
                                                    </i></small>
                                                <small
                                                    *ngIf="+lineChartData.datasets[3].data[lineChartData.datasets[3].data.length-1] === 1"><b>Implications: </b>
                                                    <i>Since the patient may have used cannabis during the past 12
                                                        months, they may still be at risk for adverse outcomes despite
                                                        no use in the past 3 months and may meet DSM-5 criteria for
                                                        Cannabis Use Disorder.
                                                        <br />
                                                        Note: Patients who report no past 12 months cannabis use are
                                                        currently at an undetermined risk for adverse outcomes. Those
                                                        who report cannabis use in previous years but have not used in
                                                        the past 12 months may be at risk for relapse or other
                                                        complications.</i></small>
                                                <small
                                                    *ngIf="+lineChartData.datasets[3].data[lineChartData.datasets[3].data.length-1] === 2"><b>Implications: </b>
                                                    <i>Patients with this risk level are using cannabis in ways that can
                                                        have important implications for their health and may meet DSM-5
                                                        criteria for Cannabis Use Disorder at a lower level of severity.
                                                        They are at some risk for adverse outcomes associated with their
                                                        cannabis use.</i></small>
                                                <small
                                                    *ngIf="+lineChartData.datasets[3].data[lineChartData.datasets[3].data.length-1] === 3"><b>Implications: </b>
                                                    <i>Patients with this result are at high
                                                        risk
                                                        for adverse outcomes related to
                                                        Cannabis use and are highly likely to meet DSM-5 criteria for
                                                        a
                                                        Cannabis Use
                                                        Disorder.</i></small>
                                            </div>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </div>
                        <div class="row pad10">
                            <div class="col-md-3">
                                <mat-card class="mat-elevation-z8">
                                    <h4 class="header text-center">Stimulant Risk Level</h4>
                                    <mat-divider></mat-divider>
                                    <mat-card-content>
                                        <div class="row" style="padding:2px">
                                            <div class="col-md-2">
                                                <button
                                                    *ngIf="+lineChartData.datasets[4].data[lineChartData.datasets[4].data.length-1] === 0.3"
                                                    mat-raised-button class="btn btn-just-icon btn-fab btn-round"
                                                    style="height: 20px; width: 20px; min-width: 20px; background-color: #5a9e7c;"></button>
                                                <button
                                                    *ngIf="+lineChartData.datasets[4].data[lineChartData.datasets[4].data.length-1] === 1"
                                                    mat-raised-button class="btn btn-just-icon btn-fab btn-round"
                                                    style="height: 20px; width: 20px; min-width: 20px; background-color: #ffcd56;"></button>
                                                <button
                                                    *ngIf="+lineChartData.datasets[4].data[lineChartData.datasets[4].data.length-1] === 2"
                                                    mat-raised-button class="btn btn-just-icon btn-fab btn-round"
                                                    style="height: 20px; width: 20px; min-width: 20px; background-color: #ff8d33;"></button>
                                                <button
                                                    *ngIf="+lineChartData.datasets[4].data[lineChartData.datasets[4].data.length-1] === 3"
                                                    mat-raised-button class="btn btn-just-icon btn-fab btn-round"
                                                    style="height: 20px; width: 20px; min-width: 20px; background-color: #ff6384;"></button>
                                            </div>
                                            <div class="col-md-10">
                                                <span
                                                    *ngIf="+lineChartData.datasets[4].data[lineChartData.datasets[4].data.length-1] === 0.3">Minimal
                                                    Risk </span>
                                                <span
                                                    *ngIf="+lineChartData.datasets[4].data[lineChartData.datasets[4].data.length-1] === 1">Undetermined
                                                    Risk </span>
                                                <span
                                                    *ngIf="+lineChartData.datasets[4].data[lineChartData.datasets[4].data.length-1] === 2">Problem
                                                    Use - Risk for Adverse Outcomes</span>
                                                <span
                                                    *ngIf="+lineChartData.datasets[4].data[lineChartData.datasets[4].data.length-1] === 3">High
                                                    Risk </span>
                                                for Stimulant Use Disorder
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <mat-divider></mat-divider>
                                                <small
                                                    *ngIf="+lineChartData.datasets[4].data[lineChartData.datasets[4].data.length-1] === 0"><b>Implications: </b>
                                                    <i>Patients who report no past 12 months stimulant use are currently
                                                        at minimal risk for adverse outcomes associated with stimulant
                                                        use.
                                                        <br />
                                                        Note: Those who report stimulant use in previous years but have
                                                        not used in the past 12 months are at risk for relapse.
                                                    </i></small>
                                                <small
                                                    *ngIf="+lineChartData.datasets[4].data[lineChartData.datasets[4].data.length-1] === 1"><b>Implications: </b>
                                                    <i>Since the patient may have used stimulants during the past 12
                                                        months, they may still be at risk for adverse outcomes despite
                                                        no use in the past 3 months and may meet DSM-5 criteria for
                                                        Stimulant Use Disorder.
                                                        <br />
                                                        Note: Patients who report no past 12 months stimulant use are
                                                        currently at an undetermined risk for adverse outcomes
                                                        associated with stimulant use. Those who report stimulant use in
                                                        previous years but have not used in the past 12 months may be at
                                                        risk for relapse or other complications.</i></small>
                                                <small
                                                    *ngIf="+lineChartData.datasets[4].data[lineChartData.datasets[4].data.length-1] === 2"><b>Implications: </b>
                                                    <i>Patients with this risk level are using stimulants (for example:
                                                        cocaine, crack, and methamphetamines, etc.) in ways that can
                                                        have important implications for their health, and may meet DSM-5
                                                        criteria for Stimulant Use Disorder at a lower level of
                                                        severity. They are at some risk for adverse outcomes associated
                                                        with stimulant use.</i></small>
                                                <small
                                                    *ngIf="+lineChartData.datasets[4].data[lineChartData.datasets[4].data.length-1] === 3"><b>Implications: </b>
                                                    <i>Patients with this result are at high
                                                        risk for adverse outcomes related to stimulant use (for example:
                                                        cocaine, crack, methamphetamine, etc.) and are likely to meet
                                                        DSM-5 criteria for a Stimulant Use Disorder.</i></small>
                                            </div>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                            <div class="col-md-3">
                                <mat-card class="mat-elevation-z8">
                                    <h4 class="header text-center">Opioids Risk Level</h4>
                                    <mat-divider></mat-divider>
                                    <mat-card-content>
                                        <div class="row" style="padding:2px">
                                            <div class="col-md-2">
                                                <button
                                                    *ngIf="+lineChartData.datasets[5].data[lineChartData.datasets[5].data.length-1] === 0.3"
                                                    mat-raised-button class="btn btn-just-icon btn-fab btn-round"
                                                    style="height: 20px; width: 20px; min-width: 20px; background-color: #5a9e7c;"></button>
                                                <button
                                                    *ngIf="+lineChartData.datasets[5].data[lineChartData.datasets[5].data.length-1] === 1"
                                                    mat-raised-button class="btn btn-just-icon btn-fab btn-round"
                                                    style="height: 20px; width: 20px; min-width: 20px; background-color: #ffcd56;"></button>
                                                <button
                                                    *ngIf="+lineChartData.datasets[5].data[lineChartData.datasets[5].data.length-1] === 2"
                                                    mat-raised-button class="btn btn-just-icon btn-fab btn-round"
                                                    style="height: 20px; width: 20px; min-width: 20px; background-color: #ff8d33;"></button>
                                                <button
                                                    *ngIf="+lineChartData.datasets[5].data[lineChartData.datasets[5].data.length-1] === 3"
                                                    mat-raised-button class="btn btn-just-icon btn-fab btn-round"
                                                    style="height: 20px; width: 20px; min-width: 20px; background-color: #ff6384;"></button>
                                            </div>
                                            <div class="col-md-10">
                                                <span
                                                    *ngIf="+lineChartData.datasets[5].data[lineChartData.datasets[5].data.length-1] === 0.3">Minimal
                                                    Risk </span>
                                                <span
                                                    *ngIf="+lineChartData.datasets[5].data[lineChartData.datasets[5].data.length-1] === 1">Undetermined
                                                    Risk </span>
                                                <span
                                                    *ngIf="+lineChartData.datasets[5].data[lineChartData.datasets[5].data.length-1] === 2">Problem
                                                    use - Risk for Adverse Outcomes </span>
                                                <span
                                                    *ngIf="+lineChartData.datasets[5].data[lineChartData.datasets[5].data.length-1] === 3">High
                                                    Risk </span>
                                                for Opioids Use Disorder
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <mat-divider></mat-divider>
                                                <small
                                                    *ngIf="+lineChartData.datasets[5].data[lineChartData.datasets[5].data.length-1] === 0"><b>Implications: </b>
                                                    <i>Patients who report no past 12 months opioid use are currently at
                                                        minimal risk for adverse outcomes associated with opioid use.
                                                        <br />
                                                        Note: Those who report opioid use in previous years but have not
                                                        used in the past 12 months are at risk for relapse. </i></small>
                                                <small
                                                    *ngIf="+lineChartData.datasets[5].data[lineChartData.datasets[5].data.length-1] === 1"><b>Implications: </b>
                                                    <i>Patients with this result are at high
                                                        risk for adverse outcomes related to illicit opioid use (for
                                                        example: heroin and fentanyl) and are highly likely to meet
                                                        DSM-5 criteria for an Opioid Use Disorder.</i></small>
                                                <small
                                                    *ngIf="+lineChartData.datasets[5].data[lineChartData.datasets[5].data.length-1] === 2"><b>Implications: </b>
                                                    <i>Patients with this risk level are using illicit opioids (for
                                                        example: heroin and fentanyl) in ways that can have important
                                                        implications for their health, and may meet DSM-5 criteria for
                                                        Opioid Use Disorder at a lower level of severity. They are at
                                                        some risk for adverse outcomes associated with their heroin use,
                                                        including overdose.</i></small>
                                                <small
                                                    *ngIf="+lineChartData.datasets[5].data[lineChartData.datasets[5].data.length-1] === 3"><b>Implications: </b>
                                                    <i>Patients with this result are at high
                                                        risk for adverse outcomes related to illicit opioid use (for
                                                        example: heroin and fentanyl) and are highly likely to meet
                                                        DSM-5 criteria for an Opioid Use Disorder.</i></small>
                                            </div>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                            <div class="col-md-3">
                                <mat-card class="mat-elevation-z8">
                                    <h4 class="header text-center">Other Substance Risk Level</h4>
                                    <mat-divider></mat-divider>
                                    <mat-card-content>
                                        <div class="row" style="padding:2px">
                                            <div class="col-md-2">
                                                <button
                                                    *ngIf="+lineChartData.datasets[6].data[lineChartData.datasets[6].data.length-1] === 0.3"
                                                    mat-raised-button class="btn btn-just-icon btn-fab btn-round"
                                                    style="height: 20px; width: 20px; min-width: 20px; background-color: #5a9e7c;"></button>
                                                <button
                                                    *ngIf="+lineChartData.datasets[6].data[lineChartData.datasets[6].data.length-1] === 1"
                                                    mat-raised-button class="btn btn-just-icon btn-fab btn-round"
                                                    style="height: 20px; width: 20px; min-width: 20px; background-color: #ffcd56;"></button>
                                                <button
                                                    *ngIf="+lineChartData.datasets[6].data[lineChartData.datasets[6].data.length-1] === 2"
                                                    mat-raised-button class="btn btn-just-icon btn-fab btn-round"
                                                    style="height: 20px; width: 20px; min-width: 20px; background-color: #ff8d33;"></button>
                                            </div>
                                            <div class="col-md-10">
                                                <span
                                                    *ngIf="+lineChartData.datasets[6].data[lineChartData.datasets[6].data.length-1] === 0.3">Minimal
                                                    Risk </span>
                                                <span
                                                    *ngIf="+lineChartData.datasets[6].data[lineChartData.datasets[6].data.length-1] === 1">Undetermined
                                                    Risk </span>
                                                <span
                                                    *ngIf="+lineChartData.datasets[6].data[lineChartData.datasets[6].data.length-1] >= 2">Problem
                                                    Use - Risk </span>
                                                for Adverse Outcomes
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <mat-divider></mat-divider>
                                                <small
                                                    *ngIf="+lineChartData.datasets[6].data[lineChartData.datasets[6].data.length-1] === 0"><b>Implications: </b>
                                                    <i>Patients who report no past 12 months use of illegal or
                                                        recreational drugs not otherwise included in this tool (for
                                                        example: ecstasy, Molly, GHB, poppers, LSD, mushrooms, special
                                                        K, bath salts, synthetic marijuana ("spice"), whip its, etc.)
                                                        are currently at minimal risk for adverse outcomes associated
                                                        with their use.
                                                        <br />
                                                        Note: Those who report the use of such drugs in previous years
                                                        but have not used in the past 12 months are at risk for
                                                        relapse.</i></small>
                                                <small
                                                    *ngIf="+lineChartData.datasets[6].data[lineChartData.datasets[6].data.length-1] === 1"><b>Implications: </b>
                                                    <i>Patients with this risk level are using
                                                        illegal or recreational drugs not otherwise included in this
                                                        tool (for example: ecstasy, Molly, GHB, poppers, LSD, mushrooms,
                                                        special K, bath salts, synthetic marijuana ("spice"), whip its,
                                                        etc.) in ways that can have important implications for their
                                                        health and may meet DSM-5 criteria for Substance Use Disorder at
                                                        a lower level of severity. They are at risk for adverse outcomes
                                                        associated with such drug use. </i></small>
                                                <small
                                                    *ngIf="+lineChartData.datasets[6].data[lineChartData.datasets[6].data.length-1] === 2"><b>Implications: </b>
                                                    <i>Patients with this risk level are using
                                                        illegal or recreational drugs not otherwise included in this
                                                        tool (for example: ecstasy, Molly, GHB, poppers, LSD, mushrooms,
                                                        special K, bath salts, synthetic marijuana ("spice"), whip its,
                                                        etc.) in ways that can have important implications for their
                                                        health and may meet DSM-5 criteria for Substance Use Disorder at
                                                        a lower level of severity. They are at risk for adverse outcomes
                                                        associated with such drug use. </i></small>
                                            </div>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <br />
    </div>
</div>