<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Member Access</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <!-- Left Side Un Assigned and right side assigned users -->
  <div class="row">
    <!-- Un Assigned -->
    <div class="col-md-6" style="margin-bottom: 15px">
      <mat-card
        appearance="outline"
        class="mat-elevation-z8"
        style="border-radius: 15px"
      >
        <label class="labelHeader" style="color: #ff5722">
          <mat-icon>close</mat-icon> Unauthorized Users
        </label>

        <div class="row">
          <!-- Search Box -->
          <div class="col-md-12">
            <div
              class="row"
              style="
                row-gap: 15px;
                column-gap: 15px;
                flex-wrap: wrap;
                margin: 10px 0px;
              "
            >
              <div
                class="col"
                style="
                  display: flex;
                  align-items: center;
                  padding: 5px;
                  border-radius: 10px;
                  border: 1px solid rgb(0, 0, 0, 0.2);
                  background-color: #ffffff;
                  padding: 5px;
                "
              >
                <span
                  class="material-icons"
                  style="text-align: left; font-size: 20px; opacity: 0.5"
                >
                  search
                </span>
                <input
                  type="search"
                  placeholder="Last Name"
                  [(ngModel)]="unAuthorizedLastName"
                  (ngModelChange)="searchUnauthorizedChanged()"
                  style="
                    padding-left: 15px;
                    width: 100%;
                    background: transparent;
                    border: 0;
                  "
                />
              </div>

              <div
                class="col"
                style="
                  display: flex;
                  align-items: center;
                  padding: 5px;
                  border-radius: 10px;
                  border: 1px solid rgb(0, 0, 0, 0.2);
                  background-color: #ffffff;
                  padding: 5px;
                "
              >
                <span
                  class="material-icons"
                  style="text-align: left; font-size: 20px; opacity: 0.5"
                >
                  search
                </span>
                <input
                  type="search"
                  placeholder="First Name"
                  [(ngModel)]="unAuthorizedFirstName"
                  (ngModelChange)="searchUnauthorizedChanged()"
                  style="
                    padding-left: 15px;
                    width: 100%;
                    background: transparent;
                    border: 0;
                  "
                />
              </div>

              <div
                class="col-12"
                style="
                  display: flex;
                  align-items: center;
                  padding: 5px;
                  border-radius: 10px;
                  border: 1px solid rgb(0, 0, 0, 0.2);
                  background-color: #ffffff;
                  padding: 5px;
                "
              >
                <span
                  class="material-icons"
                  style="text-align: left; font-size: 20px; opacity: 0.5"
                >
                  search
                </span>
                <input
                  type="search"
                  placeholder="Email"
                  [(ngModel)]="email"
                  (ngModelChange)="searchUnauthorizedChanged()"
                  style="
                    padding-left: 15px;
                    width: 100%;
                    background: transparent;
                    border: 0;
                  "
                />
              </div>
            </div>
          </div>

          <!-- Progress Bar -->
          <mat-progress-bar
            mode="indeterminate"
            *ngIf="unAssignedProcessing"
          ></mat-progress-bar>

          <!--No Record -->
          <mat-error
            *ngIf="!unAssignedProcessing && unAssignedUserList.total == 0"
          >
            No selectable user found
          </mat-error>

          <!--Selectable Record -->
          <div
            class="col-md-12 table-responsive"
            *ngIf="unAssignedUserList.total > 0"
          >
            <table mat-table [dataSource]="unAssignedUserList.items">
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.lastName }}, {{ row.firstName }}
                </td>
              </ng-container>
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>Email</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.emailAddress }}
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let row">
                  <button
                    mat-raised-button
                    type="button"
                    color="primary"
                    (click)="assignUser(row.id)"
                    [disabled]="processing"
                  >
                    Authorize
                  </button>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="unAssignedDisplayedColumns"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: unAssignedDisplayedColumns"
              ></tr>
            </table>

            <mat-paginator
              [length]="unAssignedUserList.total"
              [pageSize]="unAssignedUserList.per_page"
              *ngIf="unAssignedUserList.total > unAssignedUserList.per_page"
              (page)="getNextUnAssignedUser($event)"
              [showFirstLastButtons]="true"
            ></mat-paginator>
          </div>
        </div>
      </mat-card>
    </div>

    <!--  Assigned -->
    <div class="col-md-6" style="margin-bottom: 15px">
      <mat-card
        appearance="outline"
        class="mat-elevation-z8"
        style="border-radius: 15px"
      >
        <label class="labelHeader" style="color: green">
          <mat-icon>done</mat-icon> Authorized Users
        </label>

        <div class="row">
          <!-- Search Box -->
          <div class="col-md-12">
            <div
              class="row"
              style="
                row-gap: 15px;
                column-gap: 15px;
                flex-wrap: wrap;
                margin: 10px 0px;
              "
            >
              <div
                class="col"
                style="
                  display: flex;
                  align-items: center;
                  padding: 5px;
                  border-radius: 10px;
                  border: 1px solid rgb(0, 0, 0, 0.2);
                  background-color: #ffffff;
                  padding: 5px;
                "
              >
                <span
                  class="material-icons"
                  style="text-align: left; font-size: 20px; opacity: 0.5"
                >
                  search
                </span>
                <input
                  type="search"
                  placeholder="Last Name"
                  [(ngModel)]="authorizedLastName"
                  (ngModelChange)="searchAuthorizedChanged()"
                  style="
                    padding-left: 15px;
                    width: 100%;
                    background: transparent;
                    border: 0;
                  "
                />
              </div>

              <div
                class="col"
                style="
                  display: flex;
                  align-items: center;
                  padding: 5px;
                  border-radius: 10px;
                  border: 1px solid rgb(0, 0, 0, 0.2);
                  background-color: #ffffff;
                  padding: 5px;
                "
              >
                <span
                  class="material-icons"
                  style="text-align: left; font-size: 20px; opacity: 0.5"
                >
                  search
                </span>
                <input
                  type="search"
                  placeholder="First Name"
                  [(ngModel)]="authorizedFirstName"
                  (ngModelChange)="searchAuthorizedChanged()"
                  style="
                    padding-left: 15px;
                    width: 100%;
                    background: transparent;
                    border: 0;
                  "
                />
              </div>
            </div>
          </div>

          <!-- Progress Bar -->
          <mat-progress-bar
            mode="indeterminate"
            *ngIf="existingProcessing"
          ></mat-progress-bar>

          <!--No Record -->
          <mat-error *ngIf="!existingProcessing && existingUserList.total == 0">
            No authorized user found
          </mat-error>

          <!--Selectable Record -->
          <div
            class="col-md-12 table-responsive"
            *ngIf="existingUserList.total > 0"
          >
            <table mat-table [dataSource]="existingUserList.items">
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.lastName }}, {{ row.firstName }}
                </td>
              </ng-container>
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>Email</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.emailAddress }}
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let row">
                  <button
                    mat-raised-button
                    type="button"
                    color="warn"
                    (click)="unAssignUser(row.id, row.firstName)"
                    [disabled]="processing"
                  >
                    Remove
                  </button>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="unAssignedDisplayedColumns"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: unAssignedDisplayedColumns"
              ></tr>
            </table>

            <mat-paginator
              [length]="existingUserList.total"
              [pageSize]="existingUserList.per_page"
              *ngIf="existingUserList.total > existingUserList.per_page"
              (page)="getNextExistingUser($event)"
              [showFirstLastButtons]="true"
            ></mat-paginator>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
