<mat-card
  appearance="raised"
  class="mat-elevation-z8"
  style="margin-top: 10px; margin-bottom: 20px"
>
  <mat-card-subtitle style="font-style: italic; font-weight: 450">
    Member has a history of the following:
    <span
      style="color: #039be5; font-weight: 500"
      *ngIf="
        therapySession.status != 'Active' ||
        (!hasBPSAClinicalAddAccess &&
          !hasBPSAClinicalEditAccess &&
          therapySession.status === 'Active')
      "
      >(highlighted in blue)</span
    >
    <span
      style="color: #039be5; font-weight: 500"
      *ngIf="
        therapySession.status === 'Active' &&
        (hasBPSAClinicalAddAccess || hasBPSAClinicalEditAccess)
      "
      >(Please Select)</span
    >
  </mat-card-subtitle>

  <div
    class="row"
    *ngIf="
      therapySession.status === 'Active' &&
      (hasBPSAClinicalAddAccess || hasBPSAClinicalEditAccess)
    "
  >
    <div
      class="col-sm-6 col-xl-4"
      *ngFor="let condition of memberConditionsList"
    >
      <mat-chip-option
        [class]="condition.selected ? 'mat-chip-active' : 'mat-chip-inactive'"
        (click)="
          condition.selected = !condition.selected; updateHealthCondition()
        "
      >
        {{ condition.name }}
      </mat-chip-option>
    </div>
  </div>

  <div
    class="row"
    *ngIf="
      therapySession.status != 'Active' ||
      (!hasBPSAClinicalEditAccess &&
        !hasBPSAClinicalAddAccess &&
        therapySession.status === 'Active')
    "
  >
    <div
      class="col-sm-6 col-xl-4"
      *ngFor="let condition of memberConditionsList"
    >
      <mat-chip-option
        [class]="condition.selected ? 'mat-chip-active' : 'mat-chip-inactive'"
      >
        {{ condition.name }}
      </mat-chip-option>
    </div>
  </div>
</mat-card>
