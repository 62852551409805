import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Patient } from 'src/app/_models';
import { GuestCustomFormsComponent } from 'src/app/pages/guest/guest-custom-forms/guest-custom-forms.component';
import { GuestIntakeConsentDocumentsComponent } from 'src/app/pages/guest/guest-intake-consent-documents/guest-intake-consent-documents.component';
import { GuestIntakeMedicalHistoryComponent } from 'src/app/pages/guest/guest-intake-medical-history/guest-intake-medical-history.component';
import { GuestIntakeReleaseOfInformationComponent } from 'src/app/pages/guest/guest-intake-release-of-information/guest-intake-release-of-information.component';

@Component({
  selector: 'app-cp-complete-requested-documents',
  templateUrl: './cp-complete-requested-documents.component.html',
  styleUrl: './cp-complete-requested-documents.component.css',
})
export class CpCompleteRequestedDocumentsComponent implements OnInit {
  patient: Patient = null;
  requestedDocument: any;
  process: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CpCompleteRequestedDocumentsComponent>,
    private dialog: MatDialog
  ) {
    this.patient = data.patient;
    this.requestedDocument = data.requestedDocument;
    this.process = data.process;
  }

  ngOnInit(): void {
    // Handling the different types of form
    if (this.requestedDocument.documentType === 'Consent Documents') {
      this.completeConsentDocument();
    } else if (
      this.requestedDocument.documentName === 'Member Medical History'
    ) {
      this.completeIntakeMedicalHistory();
    } else if (
      this.requestedDocument.documentName ===
      'Authorization To Release Medical Records'
    ) {
      this.completeReleaseOfInformation();
    } else if (this.requestedDocument.documentType === 'Custom Forms') {
      this.completeCustomForm();
    }
  }

  completeConsentDocument() {
    const dialogRef = this.dialog.open(GuestIntakeConsentDocumentsComponent, {
      data: {
        requestId: this.requestedDocument.id,
        organizationId: this.requestedDocument.organizationId,
        consentRequestId: this.requestedDocument.id,
        consentDocumentId: this.requestedDocument.consentDocumentId,
        documentName: this.requestedDocument.documentName,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '55vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.dialogRef.close('success');
      } else {
        this.dialogRef.close();
      }
    });
  }

  completeIntakeMedicalHistory() {
    const dialogRef = this.dialog.open(GuestIntakeMedicalHistoryComponent, {
      data: {
        consentRequestId: this.requestedDocument.id,
        documentName: this.requestedDocument.documentName,
        patient: this.patient,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '55vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.dialogRef.close('success');
      } else {
        this.dialogRef.close();
      }
    });
  }

  completeReleaseOfInformation() {
    const addDemographicDialog = this.dialog.open(
      GuestIntakeReleaseOfInformationComponent,
      {
        data: {
          action: 'ADD',
          patient: this.patient,
          consentRequestId: this.requestedDocument.id,
        },
        autoFocus: false,
        disableClose: true,
        minWidth: '55vw',
      }
    );
    addDemographicDialog.afterClosed().subscribe((response) => {
      if (response && response === 'success') {
        this.dialogRef.close('success');
      } else {
        this.dialogRef.close();
      }
    });
  }

  completeCustomForm() {
    const addDemographicDialog = this.dialog.open(GuestCustomFormsComponent, {
      data: {
        requestId: this.requestedDocument.id,
        organizationId: this.requestedDocument.organizationId,
        consentRequestId: this.requestedDocument.id,
        consentDocumentId: this.requestedDocument.consentDocumentId,
        documentName: this.requestedDocument.documentName,
        patient: this.patient,
        process: this.process,
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '70vw',
    });
    addDemographicDialog.afterClosed().subscribe((response) => {
      if (response && response === 'success') {
        this.dialogRef.close('success');
      } else {
        this.dialogRef.close();
      }
    });
  }
}
