<form [formGroup]="searchForm">
  <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-2">
      <label class="labelText"> Month </label>
      <mat-form-field appearance="outline">
        <mat-select formControlName="month">
          <mat-option></mat-option>
          <mat-option *ngFor="let month of months" [value]="month.value">
            {{ month.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2">
      <label class="labelText"> Year </label>
      <mat-form-field appearance="outline">
        <mat-select formControlName="year">
          <mat-option></mat-option>
          <mat-option *ngFor="let year of years" [value]="year">
            {{ year }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-4"></div>
  </div>
</form>

<div class="col-md-12">
  <mat-error *ngIf="displayNoRecordsMsg" style="text-align: center">Unable to find any records for the selected
    month/year
  </mat-error>
</div>

<mat-progress-bar mode="indeterminate" *ngIf="processing"></mat-progress-bar>

<div style="height: 900px; width: 100%">
  <ng2-pdfjs-viewer [download]="false" #pdfViewerOnDemand></ng2-pdfjs-viewer>
</div>