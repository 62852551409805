import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class UsersService {
  constructor(private httpClient: HttpClient) { }
  updatePassword(passwordForm: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(`${environment.apiUrl}/users/change-password`, passwordForm, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updatePhoneNumber(phoneNumber: string): Observable<any> {
    let form: any = {};
    form.phoneNumber = phoneNumber;
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(`${environment.apiUrl}/users/update-phone`, form, { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getPhoneNumber(): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .get<any>(`${environment.apiUrl}/users/phone-number`, { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  checkIfThisUserHasERXEnabled(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/checkiferxenabled`)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
