<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Short Health Anxiety Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-3"></div>
    <div class="col-sm-7">
      <button class="btn btn-primary" mat-raised-button>
        Primary Score: {{ primary }}/42
      </button>
      <button class="btn btn-primary" mat-raised-button>
        Negative Consequences Score: {{ negative }}/12
      </button>
    </div>
    <div class="col-sm-2"></div>
  </div>

  <form
    [formGroup]="shortHealthAnxietyForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">1.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q1"
        >
          <mat-button-toggle value="I do not worry about my health."
            >I do not worry about my health.</mat-button-toggle
          >
          <mat-button-toggle value="I occasionally worry about my health."
            >I occasionally worry about my health.</mat-button-toggle
          >
          <mat-button-toggle
            value="I spend much of my time worrying about my health."
            >I spend much of my time worrying about my
            health.</mat-button-toggle
          >
          <mat-button-toggle
            value="I spend most of my time worrying about my health."
            >I spend most of my time worrying about my
            health.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">2.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q2"
        >
          <mat-button-toggle
            value="I notice aches/pains less than most other people (of my age)."
            >I notice aches/pains less than most other people (of my
            age).</mat-button-toggle
          >
          <mat-button-toggle
            value="I notice aches/pains as much as most other people (of my age)."
            >I notice aches/pains as much as most other people (of my
            age).</mat-button-toggle
          >
          <mat-button-toggle
            value="I notice aches/pains more than most other people (of my age)."
            >I notice aches/pains more than most other people (of my
            age).</mat-button-toggle
          >
          <mat-button-toggle
            value="I am aware of aches/pains in my body all the time."
            >I am aware of aches/pains in my body all the
            time.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">3.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q3"
        >
          <mat-button-toggle
            value="As a rule I am not aware of bodily sensations or changes."
            >As a rule I am not aware of bodily sensations or
            changes.</mat-button-toggle
          >
          <mat-button-toggle
            value="Sometimes I am aware of bodily sensations or changes."
            >Sometimes I am aware of bodily sensations or
            changes.</mat-button-toggle
          >
          <mat-button-toggle
            value="I am often aware of bodily sensations or changes."
            >I am often aware of bodily sensations or
            changes.</mat-button-toggle
          >
          <mat-button-toggle
            value="I am constantly aware of bodily sensations or changes."
            >I am constantly aware of bodily sensations or
            changes.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">4.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q4"
        >
          <mat-button-toggle
            value="Resisting thoughts of illness is never a problem."
            >Resisting thoughts of illness is never a
            problem.</mat-button-toggle
          >
          <mat-button-toggle
            value="Most of the time I can resist thoughts of illness."
            >Most of the time I can resist thoughts of
            illness.</mat-button-toggle
          >
          <mat-button-toggle
            value="I try to resist thoughts of illness but am often unable to do so."
            >I try to resist thoughts of illness but am often unable to do
            so.</mat-button-toggle
          >
          <mat-button-toggle
            value="Thoughts of illness are so strong that I no longer even try to resist them."
            >Thoughts of illness are so strong that I no longer even try to
            resist them.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">5.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q5"
        >
          <mat-button-toggle
            value="As a rule I am not afraid that I have a serious illness."
            >As a rule I am not afraid that I have a serious
            illness.</mat-button-toggle
          >
          <mat-button-toggle
            value="I am sometimes afraid that I have a serious illness."
            >I am sometimes afraid that I have a serious
            illness.</mat-button-toggle
          >
          <mat-button-toggle
            value="I am often afraid that I have a serious illness."
            >I am often afraid that I have a serious illness.</mat-button-toggle
          >
          <mat-button-toggle
            value="I am always afraid that I have a serious illness."
            >I am always afraid that I have a serious
            illness.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">6.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q6"
        >
          <mat-button-toggle
            value="I do not have images (mental pictures) of myself being ill."
            >I do not have images (mental pictures) of myself being
            ill.</mat-button-toggle
          >
          <mat-button-toggle
            value="I occasionally have images of myself being ill."
            >I occasionally have images of myself being ill.</mat-button-toggle
          >
          <mat-button-toggle
            value="I frequently have images of myself being ill."
            >I frequently have images of myself being ill.</mat-button-toggle
          >
          <mat-button-toggle
            value="I constantly have images of myself being ill."
            >I constantly have images of myself being ill.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">7.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q7"
        >
          <mat-button-toggle
            value="I do not have any difficulty taking my mind off thoughts about my health."
            >I do not have any difficulty taking my mind off thoughts about my
            health.</mat-button-toggle
          >
          <mat-button-toggle
            value="I sometimes have difficulty taking my mind off thoughts about my health."
            >I sometimes have difficulty taking my mind off thoughts about my
            health.</mat-button-toggle
          >
          <mat-button-toggle
            value="I often have difficulty in taking my mind off thoughts about my health."
            >I often have difficulty in taking my mind off thoughts about my
            health.</mat-button-toggle
          >
          <mat-button-toggle
            value="Nothing can take my mind off thoughts about my health."
            >Nothing can take my mind off thoughts about my
            health.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">8.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q8"
        >
          <mat-button-toggle
            value="I am lastingly relieved if my doctor tells me there is nothing wrong."
            >I am lastingly relieved if my doctor tells me there is nothing
            wrong.</mat-button-toggle
          >
          <mat-button-toggle
            value="I am initially relieved but the worries sometimes return later."
            >I am initially relieved but the worries sometimes return
            later.</mat-button-toggle
          >
          <mat-button-toggle
            value="I am initially relieved but the worries always return later."
            >I am initially relieved but the worries always return
            later.</mat-button-toggle
          >
          <mat-button-toggle
            value="I am not relieved if my doctor tells me there is nothing wrong."
            >I am not relieved if my doctor tells me there is nothing
            wrong.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">9.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q9"
        >
          <mat-button-toggle
            value="If I hear about an illness I never think I have it myself."
            >If I hear about an illness I never think I have it
            myself.</mat-button-toggle
          >
          <mat-button-toggle
            value="If I hear about an illness I sometimes think I have it myself."
            >If I hear about an illness I sometimes think I have it
            myself.</mat-button-toggle
          >
          <mat-button-toggle
            value="If I hear about an illness I often think I have it myself."
            >If I hear about an illness I often think I have it
            myself.</mat-button-toggle
          >
          <mat-button-toggle
            value="If I hear about an illness I always think I have it myself."
            >If I hear about an illness I always think I have it
            myself.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">10.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q10"
        >
          <mat-button-toggle
            value="If I have a bodily sensation or change I rarely wonder what it means."
            >If I have a bodily sensation or change I rarely wonder what it
            means.</mat-button-toggle
          >
          <mat-button-toggle
            value="If I have a bodily sensation or change I often wonder what it means."
            >If I have a bodily sensation or change I often wonder what it
            means.</mat-button-toggle
          >
          <mat-button-toggle
            value="If I have a bodily sensation or change I always wonder what it means."
            >If I have a bodily sensation or change I always wonder what it
            means.</mat-button-toggle
          >
          <mat-button-toggle
            value="If I have a bodily sensation or change I must know what it means."
            >If I have a bodily sensation or change I must know what it
            means.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">11.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q11"
        >
          <mat-button-toggle
            value="I usually feel at very low risk for developing a serious illness."
            >I usually feel at very low risk for developing a serious
            illness.</mat-button-toggle
          >
          <mat-button-toggle
            value="I usually feel at fairly low risk for developing a serious illness."
            >I usually feel at fairly low risk for developing a serious
            illness.</mat-button-toggle
          >
          <mat-button-toggle
            value="I usually feel at moderate risk for developing a serious illness."
            >I usually feel at moderate risk for developing a serious
            illness.</mat-button-toggle
          >
          <mat-button-toggle
            value="I usually feel at high risk for developing a serious illness."
            >I usually feel at high risk for developing a serious
            illness.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">12.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q12"
        >
          <mat-button-toggle value="I never think I have a serious illness."
            >I never think I have a serious illness.</mat-button-toggle
          >
          <mat-button-toggle value="I sometimes think I have a serious illness."
            >I sometimes think I have a serious illness.</mat-button-toggle
          >
          <mat-button-toggle value="I often think I have a serious illness."
            >I often think I have a serious illness.</mat-button-toggle
          >
          <mat-button-toggle value="I usually think that I am seriously ill."
            >I usually think that I am seriously ill.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">13.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q13"
        >
          <mat-button-toggle
            value="If I notice an unexplained bodily sensation I don't find it difficult to think about other things."
            >If I notice an unexplained bodily sensation I don't find it
            difficult to think about other things.</mat-button-toggle
          >
          <mat-button-toggle
            value="If I notice an unexplained bodily sensation I sometimes find it difficult to think about other things."
            >If I notice an unexplained bodily sensation I sometimes find it
            difficult to think about other things.</mat-button-toggle
          >
          <mat-button-toggle
            value="If I notice an unexplained bodily sensation I often find it difficult to think about other things."
            >If I notice an unexplained bodily sensation I often find it
            difficult to think about other things.</mat-button-toggle
          >
          <mat-button-toggle
            value="If I notice an unexplained bodily sensation I always find it difficult to think about other things."
            >If I notice an unexplained bodily sensation I always find it
            difficult to think about other things.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">14.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q14"
        >
          <mat-button-toggle
            value="My family/friends would say I do not worry enough about my health."
            >My family/friends would say I do not worry enough about my
            health.</mat-button-toggle
          >
          <mat-button-toggle
            value="My family/friends would say I have a normal attitude to my health."
            >My family/friends would say I have a normal attitude to my
            health.</mat-button-toggle
          >
          <mat-button-toggle
            value="My family/friends would say I worry too much about my health."
            >My family/friends would say I worry too much about my
            health.</mat-button-toggle
          >
          <mat-button-toggle
            value="My family/friends would say I am a hypochondriac."
            >My family/friends would say I am a
            hypochondriac.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">15.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q15"
        >
          <mat-button-toggle
            value="If I had a serious illness I would still be able to enjoy things in my life quite a lot."
            >If I had a serious illness I would still be able to enjoy things in
            my life quite a lot.</mat-button-toggle
          >
          <mat-button-toggle
            value="If I had a serious illness I would still be able to enjoy things in my life a little."
            >If I had a serious illness I would still be able to enjoy things in
            my life a little.</mat-button-toggle
          >
          <mat-button-toggle
            value="If I had a serious illness I would be almost completely unable to enjoy things in my life."
            >If I had a serious illness I would be almost completely unable to
            enjoy things in my life.</mat-button-toggle
          >
          <mat-button-toggle
            value="If I had a serious illness I would be completely unable to enjoy life at all."
            >If I had a serious illness I would be completely unable to enjoy
            life at all.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">16.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q16"
        >
          <mat-button-toggle
            value="If I developed a serious illness there is a good chance that modern medicine would be able to cure me."
            >If I developed a serious illness there is a good chance that modern
            medicine would be able to cure me.</mat-button-toggle
          >
          <mat-button-toggle
            value="If I developed a serious illness there is a moderate chance that modern medicine would be able to cure me."
            >If I developed a serious illness there is a moderate chance that
            modern medicine would be able to cure me.</mat-button-toggle
          >
          <mat-button-toggle
            value="If I developed a serious illness there is a very small chance that modern medicine would be able to cure me."
            >If I developed a serious illness there is a very small chance that
            modern medicine would be able to cure me.</mat-button-toggle
          >
          <mat-button-toggle
            value="If I developed a serious illness there is no chance that modern medicine would be able to cure me."
            >If I developed a serious illness there is no chance that modern
            medicine would be able to cure me.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">17.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q17"
        >
          <mat-button-toggle
            value="A serious illness would ruin some aspects of my life."
            >A serious illness would ruin some aspects of my
            life.</mat-button-toggle
          >
          <mat-button-toggle
            value="A serious illness would ruin many aspects of my life."
            >A serious illness would ruin many aspects of my
            life.</mat-button-toggle
          >
          <mat-button-toggle
            value="A serious illness would ruin almost every aspect of my life."
            >A serious illness would ruin almost every aspect of my
            life.</mat-button-toggle
          >
          <mat-button-toggle
            value="A serious illness would ruin every aspect of my life."
            >A serious illness would ruin every aspect of my
            life.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">18.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q18"
        >
          <mat-button-toggle
            value="If I had a serious illness I would not feel that I had lost my dignity."
            >If I had a serious illness I would not feel that I had lost my
            dignity.</mat-button-toggle
          >
          <mat-button-toggle
            value="If I had a serious illness I would feel that I had lost a little of my dignity."
            >If I had a serious illness I would feel that I had lost a little of
            my dignity.</mat-button-toggle
          >
          <mat-button-toggle
            value="If I had a serious illness I would feel that I had lost quite a lot of my dignity."
            >If I had a serious illness I would feel that I had lost quite a lot
            of my dignity.</mat-button-toggle
          >
          <mat-button-toggle
            value="If I had a serious illness I would feel that I had totally lost my dignity."
            >If I had a serious illness I would feel that I had totally lost my
            dignity.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          shortHealthAnxietyForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!shortHealthAnxietyForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
