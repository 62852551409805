<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle *ngIf="action === 'ADD'">Add Contact</p>
    <p matDialogTitle *ngIf="action === 'EDIT'">Edit Contact</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="contactForm" novalidate (ngSubmit)="onContactFormSubmit()">
    <!-- General Information -->
    <div class="row">
      <div class="col-md-12" style="margin-bottom: 12px">
        <label class="labelHeader">
          <mat-icon style="margin-right: 10px">person</mat-icon> General
          Information
        </label>
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-8">
              <label class="labelText">
                Relationship <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="relation"
                  name="relation"
                  placeholder="Relationship"
                >
                  <mat-option value="Associate">Associate</mat-option>
                  <mat-option value="Brother">Brother</mat-option>
                  <mat-option value="Care giver">Care giver</mat-option>
                  <mat-option value="Child">Child</mat-option>
                  <mat-option value="Handicapped dependent"
                    >Handicapped dependent</mat-option
                  >
                  <mat-option value="Life partner">Life partner</mat-option>
                  <mat-option value="Emergency contact"
                    >Emergency contact</mat-option
                  >
                  <mat-option value="Employee">Employee</mat-option>
                  <mat-option value="Employer">Employer</mat-option>
                  <mat-option value="Extended family"
                    >Extended family</mat-option
                  >
                  <mat-option value="Foster child">Foster child</mat-option>
                  <mat-option value="Friend">Friend</mat-option>
                  <mat-option value="Father">Father</mat-option>
                  <mat-option value="Grandchild">Grandchild</mat-option>
                  <mat-option value="Guardian">Guardian</mat-option>
                  <mat-option value="Grandparent">Grandparent</mat-option>
                  <mat-option value="Manager">Manager</mat-option>
                  <mat-option value="Mother">Mother</mat-option>
                  <mat-option value="Natural child">Natural child</mat-option>
                  <mat-option value="None">None</mat-option>
                  <mat-option value="Other adult">Other adult</mat-option>
                  <mat-option value="Other">Other</mat-option>
                  <mat-option value="Owner">Owner</mat-option>
                  <mat-option value="Parent">Parent</mat-option>
                  <mat-option value="Stepchild">Stepchild</mat-option>
                  <mat-option value="Self">Self</mat-option>
                  <mat-option value="Sibling">Sibling</mat-option>
                  <mat-option value="Sister">Sister</mat-option>
                  <mat-option value="Spouse">Spouse</mat-option>
                  <mat-option value="Trainer">Trainer</mat-option>
                  <mat-option value="Unknown">Unknown</mat-option>
                  <mat-option value="Ward of court">Ward of court</mat-option>
                </mat-select>
                <mat-error *ngIf="contactForm.controls['relation'].invalid">
                  Please select the relationship
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText"
                >Contact Type <span style="color: red">*</span></label
              >
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="contactType"
                  placeholder="Contact Type"
                >
                  <mat-option value="Primary Contact"
                    >Primary Contact</mat-option
                  >
                  <mat-option value="Secondary Contact"
                    >Secondary Contact</mat-option
                  >
                  <mat-option value="Other">Other</mat-option>
                </mat-select>
                <mat-error *ngIf="contactForm.controls['contactType'].invalid">
                  Please select the contact type
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">
                First Name <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="First Name"
                  formControlName="firstName"
                  name="firstName"
                  required
                />
                <mat-error
                  *ngFor="let validation of validation_messages.firstName"
                >
                  <mat-error
                    class="error-message"
                    *ngIf="
                      contactForm.get('firstName').hasError(validation.type) &&
                      (contactForm.get('firstName').dirty ||
                        contactForm.get('firstName').touched)
                    "
                  >
                    {{ validation.message }}</mat-error
                  >
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText"> Middle Name </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="middleName"
                  name="middleName"
                />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">
                Last Name <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Last Name"
                  formControlName="lastName"
                  name="lastName"
                  required
                />
                <mat-error
                  *ngFor="let validation of validation_messages.lastName"
                >
                  <mat-error
                    class="error-message"
                    *ngIf="
                      contactForm.get('lastName').hasError(validation.type) &&
                      (contactForm.get('lastName').dirty ||
                        contactForm.get('lastName').touched)
                    "
                  >
                    {{ validation.message }}</mat-error
                  >
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText"
                >Gender <span style="color: red">*</span></label
              >
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="gender"
                  placeholder="Gender"
                  required
                >
                  <mat-option [value]="r.id" *ngFor="let r of genderList">
                    {{ r.description }}
                  </mat-option>
                </mat-select>

                <mat-error *ngIf="contactForm.controls['gender'].invalid">
                  Gender is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText"> Date of Birth</label>
              <mat-form-field appearance="outline">
                <input
                  formControlName="dateOfBirth"
                  matInput
                  [matDatepicker]="dobDatePicker"
                  [max]="maxDate"
                  placeholder="MM/DD/YYYY"
                  autoSlashDate
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="dobDatePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #dobDatePicker></mat-datepicker>

                <mat-error *ngIf="contactForm.controls['dateOfBirth'].invalid">
                  Please enter a valid Date of Birth
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">Marital Status </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="maritalStatus">
                  <mat-option
                    [value]="r.id"
                    *ngFor="let r of marriedStatusList"
                  >
                    {{ r.description }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="contactForm.controls['maritalStatus'].invalid"
                >
                  Please select the marital status
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>
      <!-- Contact Information -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <label class="labelHeader">
          <mat-icon style="margin-right: 10px">contact_phone</mat-icon>
          Contact Information
        </label>
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-4">
              <label class="labelText"
                >Phone Preference <span style="color: red">*</span></label
              >
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="phonePreference"
                  placeholder="Phone Prefence"
                  (selectionChange)="phonePreferenceChanged($event)"
                >
                  <mat-option value="Cell Phone">Cell Phone</mat-option>
                  <mat-option value="Home Phone">Home Phone</mat-option>
                  <mat-option value="Work Phone">Work Phone</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="contactForm.controls['phonePreference'].invalid"
                >
                  Please select the phone preference
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">Fax </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="fax" name="fax" />
                <mat-error *ngIf="contactForm.controls['fax'].invalid">
                  Please enter a valid fax number
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">Email </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="email" name="email" />
                <mat-error *ngIf="contactForm.controls['email'].invalid">
                  Please enter a valid email address
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText"> Cell Phone Number </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="phone" name="phone" />
                <mat-error *ngIf="contactForm.controls['phone'].invalid">
                  Please enter a valid phone number
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText"
                >Home Phone Number
                <span
                  style="color: red"
                  *ngIf="
                    contactForm.controls['phonePreference'].value ===
                    'Home Phone'
                  "
                >
                  *
                </span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="homePhoneNumber"
                  name="homePhoneNumber"
                />
                <mat-error
                  *ngIf="contactForm.controls['homePhoneNumber'].invalid"
                >
                  Please enter a valid home number
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText"
                >Work Phone Number
                <span
                  style="color: red"
                  *ngIf="
                    contactForm.controls['phonePreference'].value ===
                    'Work Phone'
                  "
                >
                  *
                </span></label
              >
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="workPhoneNumber"
                  name="workPhoneNumber"
                />
                <mat-error
                  *ngIf="contactForm.controls['workPhoneNumber'].invalid"
                >
                  Please enter a valid work number
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>
      <!-- Address Information -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <label class="labelHeader">
          <mat-icon style="margin-right: 10px">home</mat-icon>
          Address
        </label>
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-8">
              <label class="labelText"> Address Line 1 </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  [options]="options"
                  ngx-gp-autocomplete
                  #placesRef="ngx-places"
                  (onAddressChange)="handleAddressChange($event)"
                  formControlName="addressLine1"
                  name="addressLine1"
                  placeholder=" "
                />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText"> Address Line 2 </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="addressLine2"
                  name="addressLine2"
                />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText"> City </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="city" name="city" />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText"> State </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="state">
                  <mat-option [value]="state.id" *ngFor="let state of usStates">
                    {{ state.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText"> Zip </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="zip" name="zip" />
                <mat-error *ngIf="contactForm.controls['zip'].invalid">
                  Please enter a valid zip
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <label class="labelText">Notes</label>
        <mat-form-field appearance="outline">
          <textarea
            matInput
            placeholder="Notes"
            formControlName="notes"
            name="notes"
          >
          </textarea>
        </mat-form-field>
      </div>
    </div>

    <button
      class="btn btn-primary pull-right"
      mat-raised-button
      type="submit"
      [disabled]="!contactForm.valid || processing"
    >
      Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
    </button>
  </form>
</div>
