export const Ethnicity = [
  {
    id: 'NSPCD',
    description: 'Not Specified',
  },
  {
    id: 'HLSON',
    description: 'Hispanic or Latino or Spanish Origin',
  },
  {
    id: 'NHLSO',
    description: 'Not Hispanic or Latino or Spanish Origin',
  },
];
