import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ResponseModal } from '../../../_models';
import { LocalStorageService } from '../../../_services/local-storage.service';
import { OrgConfigApiService } from '../../../_services/org-config.service';
import * as OrgConfigActions from '../actions/org-config.actions';

@Injectable()
export class OrgConfigEffects {
  constructor(
    private orgconfigService: OrgConfigApiService,
    private actions$: Actions,
    private localStorageService: LocalStorageService
  ) {}

  public getOrgConfig$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgConfigActions.LoadOrgConfigAction),
      mergeMap((action) =>
        this.orgconfigService.getOrgConfigDetails().pipe(
          map((orgConfig: ResponseModal) => {
            return OrgConfigActions.LoadOrgConfigSuccessAction({
              payload: orgConfig,
            });
          }),
          catchError((error: Error) => {
            return of(
              OrgConfigActions.LoadOrgConfigFailedAction({ payload: error })
            );
          })
        )
      )
    )
  );

  public getPhysicianOrgConfig$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgConfigActions.LoadPhysicianOrgConfigAction),
      mergeMap((action) =>
        this.orgconfigService.getPhysicianOrgConfigDetails().pipe(
          map((orgConfig: ResponseModal) => {
            return OrgConfigActions.LoadOrgConfigSuccessAction({
              payload: orgConfig,
            });
          }),
          catchError((error: Error) => {
            return of(
              OrgConfigActions.LoadOrgConfigFailedAction({ payload: error })
            );
          })
        )
      )
    )
  );
}
