<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Mood Entries for {{ date }}</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      [mat-dialog-close]="reloadRequired"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div
    class="row"
    style="display: flex; row-gap: 15px; margin-top: 15px; padding-bottom: 35px"
  >
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="deleteProcessing"
    ></mat-progress-bar>

    <mat-card
      appearance="raised"
      class="col-md-12 mat-elevation-z8 moodCard"
      *ngFor="let data of moodLog"
    >
      <!-- Edit Action -->
      <div class="moodAction">
        <button
          id="deleteButton"
          mat-icon-button
          type="button"
          style="color: red; opacity: 0.4"
          (click)="deleteMoodLog(data)"
        >
          <mat-icon>delete_forever</mat-icon>
        </button>
      </div>

      <label
        style="
          margin-left: 10px;
          margin-right: 10px;
          font-weight: 900;
          font-size: 15px;
        "
      >
        {{ getHour(data) }}
      </label>

      <div class="gridContainer">
        <div>
          <mat-icon [style.color]="data.color" class="moodIcon">
            {{ getIcon(data) }}
          </mat-icon>
        </div>
        <div
          style="
            padding-top: 8px;
            display: flex;
            flex-direction: column;
            padding-bottom: 8px;
          "
        >
          <label class="labelHeading">{{ data.mood }}</label>
          <label *ngIf="data?.note">
            <span style="font-weight: 500">Note:</span>
            {{ data?.note }}
          </label>
        </div>
      </div>
      <!-- Footer for date -->
      <span class="rightFooter" *ngIf="data?.createdByUser">
        Logged by {{ data.createdByUser }} on
        {{ data?.modifiedDate | date : 'M/d/y, h:mm a' : currentTimezone }}
      </span>
      <span class="rightFooter" *ngIf="!data?.createdByUser">
        Logged on
        {{ data?.modifiedDate | date : 'M/d/y, h:mm a' : currentTimezone }}
      </span>
    </mat-card>
  </div>
</div>
