import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { KeycloakService } from 'keycloak-angular';
import { Subscription } from 'rxjs/internal/Subscription';
import { IFacility } from 'src/app/_models/facility.model';
import { SearchService } from 'src/app/_services/common/search.service';
import { ReferralService } from 'src/app/_services/referrals/referral.service';
import { FacilitiesSandbox } from 'src/app/shared/sandbox/facilities.sandbox';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { PermissionsSandbox } from 'src/app/shared/sandbox/permissions.sandbox';
import { hasAccess } from 'src/app/shared/utilities/utilities';
import { AddAttemptDialogComponent } from './dialogs/add-attempt-dialog/add-attempt-dialog.component';
import { AddEditReferralComponent } from './dialogs/add-edit-referral/add-edit-referral.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
})
export class HomeComponent implements OnInit, OnDestroy {
  constructor(
    private orgConfigSandbox: OrgConfigSandbox,
    protected permissionsSandbox: PermissionsSandbox,
    private referralService: ReferralService,
    private keycloakService: KeycloakService,
    private searchService: SearchService,
    private facilitiesSandbox: FacilitiesSandbox,
    public dialog: MatDialog
  ) { }

  @ViewChild('callDeleteDataDialog')
  callDeleteDataDialog!: TemplateRef<any>;

  private subscriptions: Subscription = new Subscription();

  columnsToDisplay = [
    'dropdown',
    'clinicName',
    'name',
    'dateReceived',
    'modifiedBy',
    'contactAttempts',
    'attachments',
    'action',
  ];
  expandedElement!: PeriodicElement | null;
  referralData: any[];
  isLoading = true;

  anchor?: HTMLElement;
  timer: any;
  facilities: Array<IFacility> = [];

  selectedEvent: any;
  isQueueSelectBoxOpen: boolean = false;
  hasTelehealthFeature: boolean = false;
  hasCaseManagementFeature: boolean = false;
  hasCaseViewAccess = false;

  // Visit Reasons
  visitReasons: any = [];
  visitReasonsListMap: Map<string, any> = new Map<string, any>();

  organizationalTimeOffs: any = [];
  organizationalTimeOffLoading: boolean = true;
  // Colors Map For Visit Reasons
  visitReasonsColorMap: Map<string, string> = new Map();

  // General Properties
  orgConfig: any;
  myFacilities: any = [];
  filteredFacilities: any = [];
  searchFacilityName: string = '';
  selectedStatus: string = null;

  providersSelectionList = [];
  selectedProviders: any[] = [];

  filteredProviders = [];
  searchProviderName: string = '';

  facilitiesSelectionList: any = [];
  selectedFacilities: any[] = [];

  sessionStatus: string = 'All';
  existingSession: any = [];

  // Determining Calendar Start Time and End Time
  startTimeArray: number[] = [];
  endTimeArray: number[] = [];

  // Permissions
  hasMemberPaymentsAddAccess = false;
  hasSessionsViewAccess: boolean = false;
  hasSessionEditAccess: boolean = false;
  hasMemberViewAccess: boolean = false;

  ngOnInit(): void {
    this.loadFacilities();
    this.loadReferralData();

    // Handle Org Config to get features
    this.initializeOrgConfig();

    this.subscriptions.add(
      this.searchService.selectedFacilities$.subscribe((facilities) => {
        this.selectedFacilities = facilities;
        this.loadReferralData();
      })
    );

    this.subscriptions.add(
      this.searchService.selectedProviders$.subscribe((providers) => {
        this.selectedProviders = providers;
        this.loadReferralData();
      })
    );

    // Load My Permissions();
    this.loadMyPermissions();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  loadReferralData() {
    this.isLoading = true;
    this.referralData = [];
    this.referralService.getDetails(this.selectedStatus, this.selectedFacilities, this.selectedProviders).subscribe((data: any) => {
      if (data && data?.list) {
        this.referralData = data?.list;
      }
      this.isLoading = false;
    });
  }

  // Get the required orgconfig Information
  initializeOrgConfig() {
    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        // When load is complete, get the org config from app state
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          // Saving the org config
          this.orgConfig = orgConfig;

          // Check for organization features
          if (this.orgConfig && this.orgConfig?.features) {
            this.hasCaseManagementFeature =
              this.orgConfig.features.includes('CASE_MANAGEMENT');

            this.hasTelehealthFeature =
              this.orgConfig.features.includes('TELEHEALTH_ENABLED');
          }
        });
      }
    });
  }

  public loadFacilities() {
    this.facilitiesSandbox.facilities$.subscribe((response) => {
      if (response) {
        this.facilities = response;
      }
    });
  }

  // Loading my permissions
  loadMyPermissions() {
    this.permissionsSandbox.permissions$.subscribe((response) => {
      this.hasCaseViewAccess = hasAccess(
        this.keycloakService,
        'CASES_VIEW',
        response,
        null
      );

      this.hasSessionEditAccess = hasAccess(
        this.keycloakService,
        'THERAPY_SESSIONS_EDIT',
        response,
        null
      );
    });
  }

  addReferral() {
    let dialogRef = this.dialog.open(AddEditReferralComponent, {
      height: 'auto',
      width: 'auto',
      data: {
        facilities: this.facilities
      },
    });

    dialogRef.afterClosed().subscribe((response) => {
      this.loadReferralData();
    });
  }

  getFacilityName(facilityId: string): string {
    let selectedRecord = this.facilities.filter((record) => record.id === facilityId);
    return selectedRecord[0].facilityName;
  }

  addAttempt(id: any, attempt: any) {
    let dialogRef = this.dialog.open(AddAttemptDialogComponent, {
      height: 'auto',
      width: '700px',
      data: { rowId: id, attempt: attempt, facilities: this.facilities },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((response) => {
      this.loadReferralData();
    });
  }
  deleteImg(id: string) {
    let dialogRef = this.dialog.open(this.callDeleteDataDialog, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        alert('delete is selected');
      } else {
        this.dialog.closeAll();
      }
    });
  }

  importMember(id: any) {
    // Import Member
  }
}

export interface PeriodicElement {
  clinicName: string;
  patientName: string;
  dateOfBirth: string;
  dateReceived: string;
  modifiedBy: string;
  contactAttempts: number;
  attachments: number;
}
