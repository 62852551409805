<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle *ngIf="action === 'ADD'">Add Time Off</p>
    <p matDialogTitle *ngIf="action === 'EDIT'">Edit Time Off</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="timeOffForm" novalidate (ngSubmit)="onFormSubmit()">
    <div class="row">
      <div class="col-md-6">
        <label class="labelText">
          Start Date & Time <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            formControlName="startDay"
            matInput
            type="datetime-local"
            required
          />
          <mat-error *ngIf="timeOffForm.controls['startDay'].invalid">
            Please select a valid start date and time.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <label class="labelText">
          End Date & Time <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            formControlName="endDay"
            matInput
            type="datetime-local"
            required
            [min]="timeOffForm.controls['startDay'].value"
          />

          <mat-error *ngIf="timeOffForm.controls['endDay'].invalid">
            Please select a valid end date and time.
          </mat-error>
        </mat-form-field>
        <mat-error *ngIf="timeOffForm.errors?.dateRange">
          End date & time cannot be earlier than start date & time
        </mat-error>
        <!-- <input
          class="mat-input-class"
          display="anchored"
          formControlName="endDay"
          mbsc-datepicker
          [controls]="['calendar', 'time']"
          placeholder="MM/DD/YYYY HH:MM"
          [touchUi]="true"
          [min]="timeOffForm.controls['startDay'].value"
          [stepMinute]="5"
        /> -->
      </div>

      <div class="col-md-12" style="margin-top: 15px">
        <label class="labelText"> Reason </label>
        <mat-form-field appearance="outline">
          <textarea matInput formControlName="reason" rows="5"></textarea>
        </mat-form-field>
      </div>

      <div class="col-md-12" style="text-align: right">
        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="processing || timeOffForm.invalid"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
