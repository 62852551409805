import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IntakeMemberData } from 'src/app/_models/session/intake/intakememberdata.model';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class IntakeMemberInfoApiService {
  constructor(private httpClient: HttpClient) {}
  updateMemberInfo(
    intakeMemberData: IntakeMemberData
  ): Observable<IntakeMemberData> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<IntakeMemberData>(
        `${environment.apiUrl}/intakememberdata`,
        intakeMemberData,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
