import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { DataTablesModel } from '../_models';

@Injectable({ providedIn: 'root' })
export class FacilitiesHttpService {
  constructor(private httpClient: HttpClient) {}

  getMyFacilities(selectedOrganizationId: string): Observable<DataTablesModel> {
    let params = new HttpParams();
    if (selectedOrganizationId)
      params = params.append('orgId', selectedOrganizationId);

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/my-facilities`,
      { params: params }
    );
  }

  getMyFacilityResources(): Observable<DataTablesModel> {
    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/facilities-resources`
    );
  }

  getMyFacilityShifts(): Observable<DataTablesModel> {
    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/facilities-shifts`
    );
  }

  getMyEmployeeShifts(): Observable<DataTablesModel> {
    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/employee-shifts`
    );
  }
}
