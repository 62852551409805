import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-preview-member-document',
  templateUrl: './preview-member-document.component.html',
  styleUrl: './preview-member-document.component.css'
})
export class PreviewMemberDocumentComponent implements AfterViewInit {
  @ViewChild('pdfViewerOnDemand', { static: false }) pdfViewerOnDemand;
  displayNoRecordsMsg: boolean = false;
  pdfSrc: any;

  constructor(
    public dialogRef: MatDialogRef<PreviewMemberDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.pdfSrc = data.pdfSrc;
  }

  ngAfterViewInit() {
    if (!this.pdfSrc) {
      this.displayNoRecordsMsg = true;
    } else {
      this.pdfViewerOnDemand.pdfSrc = this.pdfSrc;
      this.pdfViewerOnDemand.refresh();
    }
  }


  onNoClick(): void {
    this.dialogRef.close();
  }
}
