import { Component, Input, OnInit } from '@angular/core';
import { ITherapySession } from 'src/app/_models';
import { DateTimeZoneService } from 'src/app/_services';

@Component({
  selector: 'app-remittance-claims-overview-header',
  templateUrl: './remittance-claims-overview-header.component.html',
  styleUrl: './remittance-claims-overview-header.component.css',
})
export class RemittanceClaimsOverviewHeaderComponent implements OnInit {
  @Input() therapySession: ITherapySession;
  currentTimezone: string;

  constructor(private dateTimeZoneService: DateTimeZoneService) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit(): void {}
}
