<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Add Recurring Assessment</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div style="margin-top: 15px">
    <form
      name="reoccurringAssessmentForm"
      [formGroup]="reoccurringAssessmentForm"
      #formDir="ngForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="row">
        <div class="col-md-6">
          <label class="labelText"
            >Start Date <span style="color: red">*</span></label
          >
          <mat-form-field appearance="outline">
            <input
              formControlName="startDate"
              matInput
              [matDatepicker]="startDatePicker"
              [min]="todaysDate"
              placeholder="MM/DD/YYYY"
              required
              autoSlashDate
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="startDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
            <mat-error
              *ngIf="reoccurringAssessmentForm.controls['startDate'].invalid"
            >
              Start Date is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <label class="labelText"
            >End Date <span style="color: red">*</span></label
          >
          <mat-form-field appearance="outline">
            <input
              formControlName="endDate"
              matInput
              [matDatepicker]="endDatePicker"
              [min]="reoccurringAssessmentForm.controls['startDate'].value"
              placeholder="MM/DD/YYYY"
              required
              autoSlashDate
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="endDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>

            <mat-error
              *ngIf="reoccurringAssessmentForm.controls['endDate'].invalid"
            >
              End Date is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <label class="labelText">
            Assessment Category <span style="color: red">*</span>
          </label>
          <mat-form-field appearance="outline">
            <mat-select
              formControlName="assessmentCategory"
              placeholder="Assessment Category"
              (selectionChange)="handleCategoryChanged()"
            >
              <mat-option
                *ngFor="let category of assessmentGroups; let i = index"
                [value]="category.name"
              >
                {{ category.name }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                reoccurringAssessmentForm.controls['assessmentCategory'].invalid
              "
            >
              Please select a category
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="selectedCategory.length > 0">
        <div class="col-md-12">
          <label class="labelText"
            >Assessment Type <span style="color: red">*</span></label
          >
          <mat-form-field appearance="outline">
            <mat-select
              formControlName="assessmentType"
              placeholder="Assessment Type"
              (selectionChange)="handleTypeChanged()"
            >
              <mat-option
                *ngFor="let type of typeList; let i = index"
                [value]="type.name"
              >
                {{ type.name }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                reoccurringAssessmentForm.controls['assessmentType'].invalid
              "
            >
              Please select the assessment type
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="selectedType.length > 0">
        <div class="col-md-12">
          <label class="labelText"
            >Frequency <span style="color: red">*</span></label
          >
          <mat-form-field appearance="outline">
            <mat-select formControlName="frequency" placeholder="Frequency">
              <mat-option
                *ngFor="let frequency of frequencyList; let i = index"
                [value]="frequency"
              >
                {{ frequency }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="reoccurringAssessmentForm.valid">
        <div class="col-md-12" style="text-align: right">
          <button
            color="primary"
            mat-raised-button
            type="submit"
            [disabled]="processing"
          >
            Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
