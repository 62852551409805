import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { PermissionsSandbox } from 'src/app/shared/sandbox/permissions.sandbox';
import { Case, DataTablesModel, INote, Patient } from '../../../_models';
import { NoteHttpService, ToastMessageService } from '../../../_services';
import { AddNoteDialogComponent } from './dialogs/add/add.component';

@Component({
  selector: 'app-member-pre-defined-notes',
  styleUrls: ['pre-defined-notes.component.css'],
  templateUrl: 'pre-defined-notes.component.html',
})
export class MemberNotesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() case: Case;
  @Input() patient: Patient;
  @Input() availableGoals: any[];

  @Input() hasCaseTaskViewAccess: boolean;
  @Input() hasCaseTaskAddAccess: boolean;
  @Input() hasCaseTaskEditAccess: boolean;
  @Input() hasCaseTaskDeleteAccess: boolean;

  @Output() notes = new EventEmitter<any[]>();
  @Input() goalDrivenService: boolean;

  displayedColumns = [
    'text',
    'category',
    'priority',
    'shift',
    'additionalInput',
  ];

  data: DataTablesModel = {} as DataTablesModel;
  isLoadingResults = false;
  apiError = false;

  orgConfig: any;

  constructor(
    public dialog: MatDialog,
    public noteApiService: NoteHttpService,
    protected permissionsSandbox: PermissionsSandbox,
    private orgConfigSandbox: OrgConfigSandbox,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit() {
    this.data.page = 0;
    this.data.per_page = 10;

    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          this.orgConfig = orgConfig;
        });
      }
    });

    if (this.hasCaseTaskEditAccess || this.hasCaseTaskDeleteAccess) {
      this.displayedColumns.push('actions');
    }

    this.loadNotes();
  }

  addNote() {
    const dialogRef = this.dialog.open(AddNoteDialogComponent, {
      data: {
        action: 'ADD',
        note: null,
        patientId: this.case.patientId,
        caseId: this.case.id,
        availableGoals: this.availableGoals,
        goalDrivenService: this.goalDrivenService,
      },
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadNotes();
      }
    });
  }

  public editNote(note: INote) {
    const dialogRef = this.dialog.open(AddNoteDialogComponent, {
      data: {
        action: 'EDIT',
        note,
        availableGoals: this.availableGoals,
        goalDrivenService: this.goalDrivenService,
      },
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadNotes();
      }
    });
  }

  deleteNote(note: INote) {
    const dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message: ' Are you sure you want to delete this scheduled Task?',
      },
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.noteApiService.deleteNote(this.case.id, note).subscribe(() => {
          if (this.data.items.length == 1) {
            this.data.page = this.data.page - 1;
            if (this.data.page < 0) {
              this.data.page = 0;
            }
          }
          this.toastMessageService.displaySuccessMessage(
            'Task ' + note.text + ' has been deleted.'
          );

          this.loadNotes();
        });
      }
    });
  }

  public loadNotes() {
    this.isLoadingResults = true;
    this.apiError = false;
    this.noteApiService
      .getNotes(
        this.case.id,
        this.case.patientId,
        this.data.per_page,
        this.data.page
      )
      .subscribe(
        (response) => {
          this.isLoadingResults = false;
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
            this.notes.emit(this.data.items);
          }
          if (response == null) {
            this.data.items = [];
            this.data.total = 0;
            this.notes.emit([]);
          }
        },
        (error) => {
          this.apiError = true;
          this.isLoadingResults = false;
          this.notes.emit([]);
        }
      );
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadNotes();
  }
}
