<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Claim Payment History</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoadingResults"
  ></mat-progress-bar>

  <!-- // If there is no payment record -->
  <div class="row" *ngIf="paymentsList?.length == 0 && !isLoadingResults">
    <div class="col-md-12">
      <mat-error>No payment history found</mat-error>
    </div>
  </div>

  <!--Payment records -->
  <div
    style="margin-top: 15px; padding-bottom: 80px"
    *ngIf="paymentsList && paymentsList?.length > 0 && !isLoadingResults"
  >
    <div class="table-responsive mat-elevation-z8">
      <table mat-table [dataSource]="paymentsList" multiTemplateDataRows>
        <ng-container matColumnDef="details">
          <th mat-header-cell *matHeaderCellDef>Payment Details</th>
          <td mat-cell *matCellDef="let row">
            <div class="columnsGrid">
              <label>
                Payer -
                <b> {{ row?.payerName }}</b>
              </label>

              <label>
                Payment Reference -
                <b> {{ row?.paymentReferenceNumber }}</b>
              </label>

              <label>
                Payment Method -
                <b> {{ row?.paymentMethodDescription }}</b>
              </label>

              <label>
                Effective Date -
                <b> {{ row?.paymentEffectiveDate }}</b>
              </label>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="claimStatusCode">
          <th mat-header-cell *matHeaderCellDef>Claim Status Code</th>
          <td mat-cell *matCellDef="let row">
            <span
              [class]="
                row.claimStatusCode === '1'
                  ? 'primaryStatus'
                  : row.claimStatusCode === '2'
                  ? 'secondaryStatus'
                  : row.claimStatusCode === '4' || row.claimStatusCode === '22'
                  ? 'rejectedStatus'
                  : 'otherStatus'
              "
            >
              {{ row?.claimStatusDescription }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Uploaded</th>
          <td mat-cell *matCellDef="let row">
            <label>{{ row.createdDate | date : 'M/d/y' }}</label>
          </td>
        </ng-container>

        <ng-container matColumnDef="totalAmount">
          <th mat-header-cell *matHeaderCellDef>Submitted Amount</th>
          <td mat-cell *matCellDef="let row">
            <label class="labelBold">
              {{ row?.totalAmount | currency : 'USD' }}
            </label>
          </td>
        </ng-container>

        <ng-container matColumnDef="paidAmount">
          <th mat-header-cell *matHeaderCellDef>Paid Amount</th>
          <td mat-cell *matCellDef="let row">
            <label class="labelBold">
              {{ row?.paidAmount | currency : 'USD' }}
            </label>
          </td>
        </ng-container>

        <ng-container matColumnDef="patientResponsibility">
          <th mat-header-cell *matHeaderCellDef>Patient Responsibility</th>
          <td mat-cell *matCellDef="let row">
            <label
              class="labelBold"
              [style.color]="
                row?.paidAmount < row?.totalAmount ? 'red' : 'green'
              "
            >
              {{ row?.patientResponsibility | currency : 'USD' }}
            </label>
          </td>
        </ng-container>

        <ng-container matColumnDef="expand">
          <th mat-header-cell *matHeaderCellDef>Servive Line COB</th>
          <td mat-cell *matCellDef="let row">
            <button
              mat-icon-button
              type="button"
              (click)="
                expandedElement = expandedElement === row ? null : row;
                $event.stopPropagation()
              "
            >
              @if (expandedElement === row) {
              <mat-icon>keyboard_arrow_up</mat-icon>
              } @else {
              <mat-icon>keyboard_arrow_down</mat-icon>
              }
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef style="text-align: right">
            Action
          </th>
          <td mat-cell *matCellDef="let row" style="text-align: right">
            <button
              type="button"
              mat-raised-button
              color="primary"
              (click)="selectEOB(row)"
            >
              Select
            </button>
          </td>
        </ng-container>

        <!-- Service Line COB Expanded -->
        <ng-container matColumnDef="expandedDetail">
          <td
            mat-cell
            *matCellDef="let element"
            [attr.colspan]="displayedColumns.length"
          >
            <div
              class="example-element-detail"
              [@detailExpand]="
                element == expandedElement ? 'expanded' : 'collapsed'
              "
            >
              <table mat-table [dataSource]="element?.serviceLineLoop">
                <ng-container matColumnDef="cptCode">
                  <th mat-header-cell *matHeaderCellDef style="width: 100px">
                    CPT Code
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <label class="labelBold" style="color: #2196f3">
                      {{ row?.cptCode }}
                    </label>
                  </td>
                </ng-container>
                <ng-container matColumnDef="serviceDate">
                  <th mat-header-cell *matHeaderCellDef style="width: 120px">
                    Service Date
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <label class="labelBold">
                      {{ row?.serviceDate }}
                    </label>
                  </td>
                </ng-container>
                <ng-container matColumnDef="billedAmount">
                  <th mat-header-cell *matHeaderCellDef>Amount Billed</th>
                  <td mat-cell *matCellDef="let row">
                    <label class="labelBold">
                      {{ row?.totalAmount | currency : 'USD' }}
                    </label>
                  </td>
                </ng-container>
                <ng-container matColumnDef="paidAmount">
                  <th mat-header-cell *matHeaderCellDef>Paid Amount</th>
                  <td mat-cell *matCellDef="let row">
                    <label class="labelBold">
                      {{ row?.paidAmount | currency : 'USD' }}
                    </label>
                  </td>
                </ng-container>
                <ng-container matColumnDef="patientResponsibility">
                  <th mat-header-cell *matHeaderCellDef>
                    Patient Responsibility
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <label
                      class="labelBold"
                      [style.color]="
                        row?.paidAmount < row?.totalAmount ? 'red' : 'green'
                      "
                    >
                      {{ row?.patientResponsibility | currency : 'USD' }}
                    </label>
                  </td>
                </ng-container>
                <ng-container matColumnDef="adjustments">
                  <th mat-header-cell *matHeaderCellDef>Adjustments</th>
                  <td mat-cell *matCellDef="let row">
                    <div
                      class="columnsGrid"
                      *ngFor="let adjustment of row.claimAdjustments"
                      style="margin-bottom: 12px"
                    >
                      <label class="labelBold">
                        {{ adjustment?.groupCode }}
                        ({{ adjustment?.groupCodeDescription }})
                      </label>
                      <label style="margin: 0">
                        <b>{{ adjustment?.reasonCode }} : </b>
                        {{ adjustment?.reasonCodeDescription }}
                      </label>
                      <label style="margin: 0">
                        Amount -
                        <b>{{ adjustment?.amount | currency : 'USD' }}</b>
                      </label>
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="serviceColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: serviceColumns"></tr>
              </table>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="example-element-row"
          [class.example-expanded-row]="expandedElement === row"
          (click)="expandedElement = expandedElement === row ? null : row"
        ></tr>
        <tr
          mat-row
          *matRowDef="let element; columns: ['expandedDetail']"
          class="example-detail-row"
        ></tr>
      </table>
    </div>
  </div>
</div>
