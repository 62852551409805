<div class="col-md-12">
    <mat-error *ngIf="displayNoRecordsMsg" style="text-align: center">Unable to generate the closure document for
      {{ therapySession.sessionCode }}
    </mat-error>
  </div>
  
  <mat-progress-bar mode="indeterminate" *ngIf="loadingReport"></mat-progress-bar>
  
  <div style="height: 900px; width: 100%">
    <ng2-pdfjs-viewer [download]="true" #pdfViewerOnDemand></ng2-pdfjs-viewer>
  </div>