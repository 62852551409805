<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>ADHD Self Report Scale Chart (ASRSv1.1)</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <form
    [formGroup]="selfReportScaleForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <mat-card appearance="raised" class="mat-elevation-z8">
      <mat-card-header>
        <div class="col-md-12">
          <div
            *ngIf="assessmentId && !isPatient"
            class="row"
            style="
              margin-bottom: 20px;
              display: flex;
              align-content: center;
              flex-direction: column;
              font-weight: 450;
            "
          >
            Part-A Score
            <button
              mat-fab
              color="primary"
              aria-label="Score"
              style="margin-left: 10px"
            >
              {{ partARisckScore }}
            </button>
          </div>
        </div>
      </mat-card-header>
      <br />
      <mat-card-content>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >1. How often do you have trouble wrapping up the final details of a
            project, once the challenging parts have been done?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q1">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >2. How often do you have difficulty getting things in order when
            you have to do a task that requires organization?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q2">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >3. How often do you have problems remembering appointments or
            obligations?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q3">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >4. When you have a task that requires a lot of thought, how often
            do you avoid or delay getting started?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q4">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >5. How often do you fidget or squirm with your hands or feet when
            you have to sit down for a long time?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q5">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >6. How often do you feel overly active and compelled to do things,
            like you were driven by a motor?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q6">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <br /><br />
    <mat-card appearance="raised" class="mat-elevation-z8">
      <mat-card-header>
        <div class="col-md-12">
          <div
            *ngIf="assessmentId && !isPatient"
            class="row"
            style="
              margin-bottom: 20px;
              display: flex;
              align-content: center;
              flex-direction: column;
              font-weight: 450;
            "
          >
            Part-B Score
            <button
              mat-fab
              color="primary"
              aria-label="Score"
              style="margin-left: 10px"
            >
              {{ partBRisckScore }}
            </button>
          </div>
        </div>
      </mat-card-header>
      <br />
      <mat-card-content>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >7. How often do you make careless mistakes when you have to work on
            a boring or difficult project?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q7">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >8. How often do you have difficulty keeping your attention when you
            are doing boring or repetitive work?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q8">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >9. How often do you have difficulty concentrating on what people
            say to you, even when they are speaking to you directly?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q9">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >10. How often do you misplace or have difficulty finding things at
            home or at work?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q10">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >11. How often are you distracted by activity or noise around
            you?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q11">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >12. How often do you leave your seat in meetings or other
            situations in which you are expected to remain seated?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q12">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >13. How often do you feel restless or fidgety?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q13">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >14. How often do you have difficulty unwinding and relaxing when
            you have time to yourself?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q14">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >15. How often do you find yourself talking too much when you are in
            social situations?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q15">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >16. When you’re in a conversation, how often do you find yourself
            finishing the sentences of the people you are talking to, before
            they can finish them themselves?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q16">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >17. How often do you have difficulty waiting your turn in
            situations when turn taking is required?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q17">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >18. How often do you interrupt others when they are busy?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q18">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          selfReportScaleForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!selfReportScaleForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
