<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Request Document</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="newDocumentsForm">
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="documents"
            multiple
            placeholder="Select documents"
          >
            <mat-select-trigger>
              <mat-chip-listbox>
                <mat-chip-row
                  *ngFor="
                    let intakeDocuments of newDocumentsForm.controls[
                      'documents'
                    ].value;
                    let i = index
                  "
                  (removed)="removeIntakeDocument(intakeDocuments, i)"
                >
                  {{ intakeDocuments.documentName }}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
              </mat-chip-listbox>
            </mat-select-trigger>
            <ng-container *ngFor="let documentGroup of intakeDocumentsList">
              <mat-optgroup
                *ngIf="documentGroup.documents?.length > 0"
                [label]="documentGroup.name"
              >
                <mat-option
                  *ngFor="let documentOption of documentGroup.documents"
                  [value]="documentOption"
                >
                  {{ documentOption.documentName }}
                </mat-option>
              </mat-optgroup>
            </ng-container>
          </mat-select>
          <mat-error *ngIf="newDocumentsForm.controls['documents'].invalid">
            Please select the intake documents to be requested
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12 columnGrid">
        <label *ngIf="!patient.email">
          * Member does not have an <b>email address</b> associated with their
          account.
        </label>
        <label *ngIf="!patient.phoneNumber">
          * Member does not have a <b>phone number</b> associated with their
          account.
        </label>
        <label *ngIf="!patient.phoneNumber || !patient.email">
          * To submit the document via email after requesting it, both an email
          address and phone number are needed.
        </label>
      </div>

      <div class="col-md-12" style="text-align: right">
        <button
          color="primary"
          mat-raised-button
          type="submit"
          (click)="submitForm()"
          [disabled]="newDocumentsForm.invalid || processing"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
