<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Request New Consent Document</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="col-md-12" *ngIf="isLoadingResults">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <form [formGroup]="newDocumentsForm">
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="documents"
            multiple
            placeholder="Select consent documents"
          >
            <mat-select-trigger>
              <mat-chip-listbox>
                <mat-chip-row
                  *ngFor="
                    let consentDocument of newDocumentsForm.controls[
                      'documents'
                    ].value;
                    let i = index
                  "
                  (removed)="removeConsentDocument(consentDocument, i)"
                >
                  {{ consentDocument.documentName }}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
              </mat-chip-listbox>
            </mat-select-trigger>

            <mat-option
              *ngFor="let documentOption of consentDocumentsList"
              [value]="documentOption"
            >
              {{ documentOption.documentName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="newDocumentsForm.controls['documents'].invalid">
            Please select the documents that you want to request
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12" style="text-align: right">
        <button
          color="primary"
          mat-raised-button
          type="button"
          [disabled]="!newDocumentsForm.valid || processing"
          (click)="submitForm()"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
