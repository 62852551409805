<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Edit {{ type }} Provider</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <form [formGroup]="providerForm" novalidate style="margin-top: 20px">
    <div class="row">
      <div class="col-md-6">
        <label class="labelText"> NPI <span style="color: red">*</span> </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="NPI"
            formControlName="npi"
            name="npi"
            required
            minlength="10"
            maxlength="10"
          />
          <mat-error *ngIf="providerForm.controls['npi'].invalid">
            Please enter a valid NPI
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6" style="margin-bottom: 12px">
        <label class="labelText"> Taxonomy Code </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Taxonomy Code"
            formControlName="taxonomy"
            name="taxonomy"
          />
          <mat-error *ngIf="providerForm.controls['taxonomy'].invalid">
            Please enter the Taxonomy Code
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6" style="margin-bottom: 12px">
        <label class="labelText">
          Does this provider bill using the Tax Id or SSN?
          <span style="color: red">*</span>
        </label>
        <br />
        <mat-radio-group
          aria-label="Select an option"
          formControlName="billingOption"
          style="column-gap: 15px; display: flex; flex-wrap: wrap"
          color="primary"
          (change)="billingOptionChanged($event)"
        >
          <mat-radio-button value="Tax Id">Tax Id</mat-radio-button>
          <mat-radio-button value="SSN">SSN</mat-radio-button>
        </mat-radio-group>
      </div>
      <div
        class="col-md-6"
        *ngIf="providerForm.controls['billingOption'].value === 'Tax Id'"
      >
        <label class="labelText">
          Tax Id
          <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Tax Id"
            formControlName="taxId"
            name="taxId"
            [type]="passwordView ? 'text' : 'password'"
          />
          <mat-icon matSuffix (click)="passwordView = !passwordView">{{
            passwordView ? 'visibility' : 'visibility_off'
          }}</mat-icon>
          <mat-error *ngIf="providerForm.controls['taxId'].invalid">
            Please enter the Tax Id
          </mat-error>
        </mat-form-field>
      </div>
      <div
        class="col-md-6"
        *ngIf="providerForm.controls['billingOption'].value === 'SSN'"
      >
        <label class="labelText">
          SSN
          <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="SSN"
            formControlName="ssn"
            name="ssn"
            [type]="passwordView ? 'text' : 'password'"
          />
          <mat-icon matSuffix (click)="passwordView = !passwordView">{{
            passwordView ? 'visibility' : 'visibility_off'
          }}</mat-icon>
          <mat-error *ngIf="providerForm.controls['ssn'].invalid">
            Please enter the SSN
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12" style="text-align: right">
        <button
          class="btn btn-primary pull-right"
          mat-raised-button
          type="button"
          (click)="submitForm()"
          [disabled]="!providerForm.valid"
        >
          Update
        </button>
      </div>
    </div>
  </form>
</div>
