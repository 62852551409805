<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Member Goals</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      type="button"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="row">
    <div class="col-md-12">
      <app-session-goals-by-category
        [patient]="patient"
        [therapySession]="sessionDetails"
        [hasGoalsViewAccess]="true"
        [hasGoalsAddAccess]="hasGoalsAddAccess"
        [hasGoalsEditAccess]="hasGoalsEditAccess"
        [hasGoalsDeleteAccess]="hasGoalsDeleteAccess"
      ></app-session-goals-by-category>
    </div>
  </div>
</div>
