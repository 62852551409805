import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IUserFavoritesResponse } from 'src/app/_models/users/user-favorites.model';
import { State } from '../store';
import * as FavoritesActions from '../store/actions/favorites.actions';
import {
  selectFavoritesLoading,
  selectFavoritesPayload,
} from '../store/selectors/favorites.selectors';
import { Sandbox } from './base.sandbox';

@Injectable()
export class FavoritesSandbox extends Sandbox {
  favoritesPayload$: Observable<IUserFavoritesResponse | null>;
  loading$: Observable<boolean>;

  constructor(protected store: Store<State>) {
    super(store);
    this.favoritesPayload$ = this.store.select(selectFavoritesPayload);
    this.loading$ = this.store.select(selectFavoritesLoading);
  }

  loadFavorites(): any {
    this.store.dispatch(FavoritesActions.loadFavorites());
  }

  addFavorite(categoryName: string, favoriteItem: string): any {
    this.store.dispatch(
      FavoritesActions.addFavorite({ categoryName, favoriteItem })
    );
  }

  removeFavorite(categoryName: string, favoriteItem: string): any {
    this.store.dispatch(
      FavoritesActions.removeFavorite({ categoryName, favoriteItem })
    );
  }
}
