<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Group Members</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      [mat-dialog-close]="reloadRequired"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="row">
    <!-- Search Box -->
    <div class="col-md-8">
      <div class="row" style="row-gap: 15px; column-gap: 15px; flex-wrap: wrap">
        <div
          class="col"
          style="
            display: flex;
            align-items: center;
            padding: 5px;
            border-radius: 10px;
            border: 1px solid rgb(0, 0, 0, 0.2);
            background-color: #ffffff;
            padding: 5px;
            min-width: 140px;
          "
        >
          <span
            class="material-icons"
            style="text-align: left; font-size: 20px; opacity: 0.5"
          >
            search
          </span>
          <input
            type="search"
            placeholder="Last Name"
            [(ngModel)]="memberLastName"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="searchByNameChanged()"
            style="
              padding-left: 15px;
              width: 100%;
              background: transparent;
              border: 0;
            "
          />
        </div>

        <div
          class="col"
          style="
            display: flex;
            align-items: center;
            padding: 5px;
            border-radius: 10px;
            border: 1px solid rgb(0, 0, 0, 0.2);
            background-color: #ffffff;
            padding: 5px;
            min-width: 140px;
          "
        >
          <span
            class="material-icons"
            style="text-align: left; font-size: 20px; opacity: 0.5"
          >
            search
          </span>
          <input
            type="search"
            placeholder="First Name"
            [(ngModel)]="memberFirstName"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="searchByNameChanged()"
            style="
              padding-left: 15px;
              width: 100%;
              background: transparent;
              border: 0;
            "
          />
        </div>

        <div
          class="col"
          style="
            display: flex;
            align-items: center;
            padding: 5px;
            border-radius: 10px;
            border: 1px solid rgb(0, 0, 0, 0.2);
            background-color: #ffffff;
            padding: 5px;
            min-width: 140px;
          "
        >
          <span
            class="material-icons"
            style="text-align: left; font-size: 20px; opacity: 0.5"
          >
            call
          </span>
          <input
            type="search"
            placeholder="Phone"
            [(ngModel)]="memberPhone"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="searchByNameChanged()"
            style="
              padding-left: 15px;
              width: 100%;
              background: transparent;
              border: 0;
            "
          />
        </div>
      </div>
    </div>

    <!-- Add Box -->
    <div *ngIf="hasMemberAddAccess" class="col-md-4" style="text-align: right">
      <div>
        <button mat-raised-button color="warn" (click)="addMember()">
          + Add Member
        </button>
      </div>
    </div>

    <!-- Progress bar -->
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="isMembersLoading || processing"
    ></mat-progress-bar>

    <!-- Member List -->
    <div style="margin-top: 25px; margin-bottom: 25px">
      <div *ngIf="membersList.total === 0 && !isMembersLoading">
        <mat-error> No member record found </mat-error>
      </div>

      <div class="table-responsive" *ngIf="membersList.total > 0">
        <table mat-table [dataSource]="membersList.items">
          <ng-container matColumnDef="memberName">
            <th mat-header-cell *matHeaderCellDef style="font-weight: 900">
              Member Name
            </th>
            <td mat-cell *matCellDef="let row">
              <b>{{ row.lastName }}, {{ row.firstName }}</b>
              <br />
              {{ row.dateOfBirth }} (
              {{
                row?.gender === 'M'
                  ? 'Male'
                  : row?.gender === 'F'
                  ? 'Female'
                  : row?.gender.toLowerCase()
              }}
              )
            </td>
          </ng-container>
          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef>Address</th>
            <td mat-cell *matCellDef="let row">
              {{ row.addressLine1 }} {{ row.addressLine2 }}<br />{{ row.city }}
              {{ row.state }}
              {{ row.zip }}
            </td>
          </ng-container>
          <ng-container matColumnDef="phoneNumber">
            <th mat-header-cell *matHeaderCellDef style="font-weight: 900">
              Phone Number
            </th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="row?.phoneNumber">
                {{ row?.phoneNumber | phoneFormat }}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th
              mat-header-cell
              *matHeaderCellDef
              style="font-weight: 900; text-align: right"
            >
              Action
            </th>
            <td mat-cell *matCellDef="let row" style="text-align: right">
              <!-- If not selected, display select button if selected display check icon -->
              <button
                *ngIf="!isMemberSelected(row)"
                mat-raised-button
                color="primary"
                type="button"
                (click)="addRemoveMemberToGroup(row, true)"
                matTooltip="Add Member"
                [disabled]="processing"
              >
                Select
              </button>

              <button
                *ngIf="isMemberSelected(row)"
                mat-raised-button
                color="warn"
                type="button"
                (click)="addRemoveMemberToGroup(row, false)"
                matTooltip="Add Member"
                [disabled]="processing"
              >
                Remove
              </button>
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <mat-paginator
        #paginator
        [length]="membersList.total"
        [pageSize]="membersList.per_page"
        [showFirstLastButtons]="true"
        *ngIf="membersList.total > membersList.per_page"
        (page)="getNext($event)"
      >
      </mat-paginator>
    </div>
  </div>
</div>
