import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Patient } from 'src/app/_models';
import { ToastMessageService } from 'src/app/_services';
import { DoseSpotApiService } from 'src/app/_services/dosespot/dosespot.service';
import { AddEditPatientComponent } from 'src/app/pages/patients/dialogs/add/add.component';

@Component({
  selector: 'app-dose-spot',
  templateUrl: './dose-spot.component.html',
  styleUrls: ['./dose-spot.component.css'],
})
export class DoseSpotComponent implements OnInit, OnChanges {
  @Input() patient: Patient;
  @Output() output = new EventEmitter<any>();

  apiError: boolean = false;
  isLoadingResults = true;
  showSpinner = true;
  iFrameUrl: string = '';

  constructor(
    private dialog: MatDialog,
    private doseSpotApiService: DoseSpotApiService,
    private toastMessageSerivce: ToastMessageService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.patient) {
      this.validateMemberAge();
    }
  }

  validateMemberAge() {
    let differenceInTime = Math.abs(
      new Date().getTime() - new Date(this.patient.dateOfBirth).getTime()
    );

    const years = Math.floor(differenceInTime / (1000 * 60 * 60 * 24 * 365.25));

    // For members below 19 years of age, validate if the height and weight is there
    if (years <= 19) {
      if (!this.patient.height || !this.patient.weight) {
        this.toastMessageSerivce.displayWarnMessage(
          'For members under 19 years old, height and weight is required. Please update the member height and weight to proceed.'
        );
        const dialogRef = this.dialog.open(AddEditPatientComponent, {
          data: { action: 'EDIT', patient: this.patient },
          disableClose: true,
          autoFocus: false,
          minWidth: '40vw',
        });
        dialogRef.afterClosed().subscribe((response) => {
          if (response === 'success') {
            let payload: any = {};
            payload.eventType = 'RELOAD_PATIENT_PROFILE';
            this.output.emit(payload);
          } else {
            this.toastMessageSerivce.displayInfoMessage(
              '* Please update the member height and weight.'
            );
            this.validateMemberAge();
          }
        });
      } else {
        this.validateMemberPhoneNumber();
      }
    } else {
      this.validateMemberPhoneNumber();
    }
  }

  validateMemberPhoneNumber() {
    if (!this.patient.phoneNumber) {
      this.toastMessageSerivce.displayWarnMessage(
        'Member phone number is required. Please update the member phone number to proceed.'
      );
      const dialogRef = this.dialog.open(AddEditPatientComponent, {
        data: { action: 'EDIT', patient: this.patient },
        disableClose: true,
        autoFocus: false,
        minWidth: '40vw',
      });
      dialogRef.afterClosed().subscribe((response) => {
        if (response === 'success') {
          let payload: any = {};
          payload.eventType = 'RELOAD_PATIENT_PROFILE';
          this.output.emit(payload);
        } else {
          this.toastMessageSerivce.displayInfoMessage(
            '* Please update the member phone number to proceed.'
          );
          this.validateMemberAge();
        }
      });
    } else {
      // Member had valid data to be submitted
      this.getSingleSignOnUrl();
    }
  }

  public getSingleSignOnUrl() {
    this.isLoadingResults = true;
    this.showSpinner = true;
    this.apiError = false;
    this.doseSpotApiService.getSingleSignOnUrl(this.patient.id).subscribe({
      next: (response) => {
        this.isLoadingResults = false;
        if (response && response.data) {
          this.iFrameUrl = response.data;
        }
        setTimeout(() => (this.showSpinner = false), 3000);
      },
      error: (error) => {
        this.toastMessageSerivce.displayErrorMessage(error.text);
        this.apiError = true;
        this.isLoadingResults = false;
        this.showSpinner = false;
      },
    });
  }
}
