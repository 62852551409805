import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-general-delete-confirm-dialog',
  templateUrl: './general-delete-confirm-dialog.component.html',
  styleUrls: ['./general-delete-confirm-dialog.component.css'],
})
export class GeneralDeleteConfirmDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<GeneralDeleteConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {}

  public cancel() {
    this.close(false);
  }
  public close(value) {
    this.dialogRef.close(value);
  }
  public confirm() {
    this.close(true);
  }

  @HostListener('keydown.esc')
  public onEsc() {
    this.close(false);
  }
}
