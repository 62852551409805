import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  DataTablesModel,
  HealthCondition,
  HealthConditions,
  Patient,
} from '../../../../../_models';
import { HealthConditionsApiService } from '../../../../../_services';
import { FamilyConditionsList } from '../../../../../shared/utilities/familyMedicalConditions';

@Component({
  selector: 'app-family-history',
  templateUrl: './family-history.component.html',
  styleUrls: ['./family-history.component.css'],
})
export class FamilyHistoryComponent implements OnInit, OnChanges {
  @Input() patient: Patient;
  @Input() hasFamilyHistoryViewAccess: boolean;
  @Input() hasFamilyHistoryEditAccess: boolean;

  private familyConditions: HealthCondition[];

  familyConditionsList = FamilyConditionsList;

  isLoadingResults = true;
  apiError = false;

  data: DataTablesModel = {} as DataTablesModel;
  healthConditions: HealthConditions;

  constructor(public healthConditionsApiService: HealthConditionsApiService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.patient) {
      if (this.patient) {
        this.loadHealthConditions();
      }
    }
  }

  public loadHealthConditions() {
    this.isLoadingResults = true;
    this.apiError = false;

    this.healthConditionsApiService
      .getExistingConditions(this.patient.id)
      .subscribe({
        next: (response) => {
          this.isLoadingResults = false;
          if (response && response.data) {
            this.resetMemberConditions();

            this.healthConditions = response.data;

            this.familyConditions = response.data.familyConditions;
            if (this.familyConditions) {
              for (let j = 0; j < this.familyConditionsList.length; j++) {
                if (
                  this.familyConditions.includes(
                    this.familyConditionsList[j].name
                  )
                ) {
                  this.familyConditionsList[j].selected = true;
                }
              }
            }
          } else {
            this.familyConditions = [];
            this.resetMemberConditions();
          }
        },
        error: (error) => {
          this.apiError = true;
          this.isLoadingResults = false;
        },
      });
  }

  public updateFamilyHealthCondition(condition: HealthCondition) {
    let selectedHealthConditions = [];
    for (let i = 0; i < this.familyConditionsList.length; i++) {
      if (this.familyConditionsList[i].selected) {
        selectedHealthConditions.push(this.familyConditionsList[i].name);
      }
    }

    if (!this.healthConditions) {
      this.healthConditions = {} as HealthConditions;
    }

    this.healthConditions.familyConditions = selectedHealthConditions;
    this.healthConditions.patientId = this.patient.id;

    this.healthConditionsApiService
      .updateHealthConditions(this.patient.id, this.healthConditions)
      .subscribe(() => {
        this.loadHealthConditions();
      });
  }

  resetMemberConditions() {
    this.familyConditionsList.map((condition) => {
      condition.selected = false;
      return condition;
    });
  }
}
