<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Hourly Mood Log</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="moodlogForm" novalidate (ngSubmit)="onMoodLogFormSubmit()">
    <!-- Select Date And Hour -->
    <mat-stepper linear="true" #stepper>
      <mat-step [stepControl]="datetime">
        <ng-template matStepLabel>Date / Hour</ng-template>
        <div class="row">
          <div class="col-md-12">
            <label class="labelText">
              Date <span style="color: red">*</span>
            </label>
            <mat-form-field appearance="outline">
              <input
                formControlName="logDate"
                matInput
                [matDatepicker]="logDatePicker"
                placeholder="Date"
                [max]="todaysDate"
                [min]="minimumDate"
                autoSlashDate
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="logDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #logDatePicker></mat-datepicker>
              <mat-error *ngIf="moodlogForm.controls['logDate'].invalid"
                >Please select the date</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col-md-12">
            <label class="labelText">
              Hour <span style="color: red">*</span>
            </label>

            <mat-chip-listbox #chipList multiple="false">
              <div class="sixGridContainer">
                <div *ngFor="let hour of filteredHours">
                  <mat-chip-option
                    [class]="
                      hour.value === selectedHour
                        ? 'mat-chip-active'
                        : 'mat-chip-inactive'
                    "
                    (click)="setSelectedHour(hour.value)"
                  >
                    {{ hour.text }}
                  </mat-chip-option>
                </div>
              </div>
            </mat-chip-listbox>
          </div>
        </div>

        <div style="display: flex; justify-content: right; margin-top: 20px">
          <button mat-raised-button matStepperNext type="button">Next</button>
        </div>
      </mat-step>

      <mat-step [stepControl]="mooddetails">
        <ng-template matStepLabel>Mood </ng-template>
        <div class="row">
          <div class="col-md-12">
            <label class="labelText">
              Mood <span style="color: red">*</span>
            </label>
            <div
              style="
                display: flex;
                justify-content: space-evenly;
                flex-wrap: wrap;
                height: 100%;
                column-gap: 22px;
              "
            >
              <mat-icon
                [ngStyle]="{
                  color: mood === 'Very Sad' ? 'red' : 'lightgrey'
                }"
                class="moodIcon"
                (click)="setMood('Very Sad')"
              >
                sentiment_very_dissatisfied
              </mat-icon>

              <mat-icon
                [ngStyle]="{
                  color: mood === 'Somewhat Sad' ? 'orange' : 'lightgrey'
                }"
                class="moodIcon"
                (click)="setMood('Somewhat Sad')"
              >
                sentiment_dissatisfied
              </mat-icon>

              <mat-icon
                [ngStyle]="{
                  color: mood === 'Neutral' ? '#72b9f1' : 'lightgrey'
                }"
                class="moodIcon"
                (click)="setMood('Neutral')"
              >
                sentiment_neutral
              </mat-icon>

              <mat-icon
                [ngStyle]="{
                  color: mood === 'Somewhat Happy' ? 'lightgreen' : 'lightgrey'
                }"
                class="moodIcon"
                (click)="setMood('Somewhat Happy')"
              >
                sentiment_satisfied
              </mat-icon>

              <mat-icon
                [ngStyle]="{
                  color: mood === 'Very Happy' ? 'green' : 'lightgrey'
                }"
                class="moodIcon"
                (click)="setMood('Very Happy')"
              >
                sentiment_very_satisfied
              </mat-icon>
            </div>
          </div>
        </div>

        <div class="row" style="margin-top: 25px">
          <div class="col-md-12">
            <label class="labelText">What made you feel this way?</label>

            <mat-form-field appearance="outline">
              <textarea
                matInput
                placeholder=""
                formControlName="note"
                name="note"
                spellcheck="true"
                rows="3"
              ></textarea>
            </mat-form-field>
          </div>
        </div>

        <div style="display: flex; justify-content: space-between">
          <button mat-raised-button matStepperPrevious type="button">
            Back
          </button>

          <button
            color="primary"
            mat-raised-button
            type="submit"
            [disabled]="!moodlogForm.valid || processing"
          >
            Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
          </button>
        </div>
      </mat-step>
    </mat-stepper>
  </form>
</div>
