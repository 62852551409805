<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>quantitative-checklist Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="quantitativeChecklistForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >1. Does your child look at you when you call his/her name?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="q1">
              <mat-button-toggle value="Always">Always</mat-button-toggle>
              <mat-button-toggle value="Usually">Usually</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Never">Never</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >2. How easy is it for you to get eye contact with your
            child?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="q2">
              <mat-button-toggle value="Very easy">Very easy</mat-button-toggle>
              <mat-button-toggle value="Quite easy"
                >Quite easy</mat-button-toggle
              >
              <mat-button-toggle value="Quite difficult"
                >Quite difficult</mat-button-toggle
              >
              <mat-button-toggle value="Very difficult"
                >Very difficult</mat-button-toggle
              >
              <mat-button-toggle value="Impossible"
                >Impossible</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >3. When your child is playing alone, does s/he line objects
            up?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="q3">
              <mat-button-toggle value="Always">Always</mat-button-toggle>
              <mat-button-toggle value="Usually">Usually</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Never">Never</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >4. Can other people easily understand your child's speech?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="q4">
              <mat-button-toggle value="Always">Always</mat-button-toggle>
              <mat-button-toggle value="Usually">Usually</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="My child does not speak"
                >My child does not speak</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >5. Does your child point to indicate that s/he wants something
            (e.g. a toy that is out of reach)?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="q5">
              <mat-button-toggle value="Many times a day"
                >Many times a day</mat-button-toggle
              >
              <mat-button-toggle value="A few times a day"
                >A few times a day</mat-button-toggle
              >
              <mat-button-toggle value="A few times a week"
                >A few times a week</mat-button-toggle
              >
              <mat-button-toggle value="Less than once a week"
                >Less than once a week</mat-button-toggle
              >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >6. Does your child point to share interest with you (e.g. pointing
            at an interesting sight)?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="q6">
              <mat-button-toggle value="Many times a day"
                >Many times a day</mat-button-toggle
              >
              <mat-button-toggle value="A few times a day"
                >A few times a day</mat-button-toggle
              >
              <mat-button-toggle value="A few times a week"
                >A few times a week</mat-button-toggle
              >
              <mat-button-toggle value="Less than once a week"
                >Less than once a week</mat-button-toggle
              >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >7. How long can your child's interest be maintained by a spinning
            object (e.g. washing machine, electric fan, toy car wheels)?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="q7">
              <mat-button-toggle value="Several hours"
                >Several hours</mat-button-toggle
              >
              <mat-button-toggle value="Half an hour"
                >Half an hour</mat-button-toggle
              >
              <mat-button-toggle value="Ten minutes"
                >Ten minutes</mat-button-toggle
              >
              <mat-button-toggle value="A couple of minutes"
                >A couple of minutes</mat-button-toggle
              >
              <mat-button-toggle value="Less than a minute"
                >Less than a minute</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >8. How many words can your child say?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="q8">
              <mat-button-toggle
                value="None — s/he has not started speaking yet"
                >None — s/he has not started speaking yet</mat-button-toggle
              >
              <mat-button-toggle value="Less than 10 words"
                >Less than 10 words</mat-button-toggle
              >
              <mat-button-toggle value="10 — 50 words"
                >10 — 50 words</mat-button-toggle
              >
              <mat-button-toggle value="51 — 100 words"
                >51 — 100 words</mat-button-toggle
              >
              <mat-button-toggle value="Over 100 words"
                >Over 100 words</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >9. Does your child pretend (egg care for dolls, talk on a toy
            phone)?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="q9">
              <mat-button-toggle value="Many times a day"
                >Many times a day</mat-button-toggle
              >
              <mat-button-toggle value="A few times a day"
                >A few times a day</mat-button-toggle
              >
              <mat-button-toggle value="A few times a week"
                >A few times a week</mat-button-toggle
              >
              <mat-button-toggle value="Less than once a week"
                >Less than once a week</mat-button-toggle
              >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >10. Does your child follow where you're looking?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="q10">
              <mat-button-toggle value="Many times a day"
                >Many times a day</mat-button-toggle
              >
              <mat-button-toggle value="A few times a day"
                >A few times a day</mat-button-toggle
              >
              <mat-button-toggle value="A few times a week"
                >A few times a week</mat-button-toggle
              >
              <mat-button-toggle value="Less than once a week"
                >Less than once a week</mat-button-toggle
              >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >11. How often does your child sniff or lick unusual objects?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="q11">
              <mat-button-toggle value="Many times a day"
                >Many times a day</mat-button-toggle
              >
              <mat-button-toggle value="A few times a day"
                >A few times a day</mat-button-toggle
              >
              <mat-button-toggle value="A few times a week"
                >A few times a week</mat-button-toggle
              >
              <mat-button-toggle value="Less than once a week"
                >Less than once a week</mat-button-toggle
              >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >12. Does your child place your hand on an object when s/he wants
            you to use it (e.g. on a door handle when s/he wants you to open the
            door, on a toy when s/he wants you to activate it)?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="q12">
              <mat-button-toggle value="Many times a day"
                >Many times a day</mat-button-toggle
              >
              <mat-button-toggle value="A few times a day"
                >A few times a day</mat-button-toggle
              >
              <mat-button-toggle value="A few times a week"
                >A few times a week</mat-button-toggle
              >
              <mat-button-toggle value="Less than once a week"
                >Less than once a week</mat-button-toggle
              >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >13. Does your child walk on tiptoe?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="q13">
              <mat-button-toggle value="Always">Always</mat-button-toggle>
              <mat-button-toggle value="Usually">Usually</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Never">Never</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >14. How easy is it for your child to adapt when his/her routine
            changes or when things are out of their usual place?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="q14">
              <mat-button-toggle value="Very easy">Very easy</mat-button-toggle>
              <mat-button-toggle value="Quite easy"
                >Quite easy</mat-button-toggle
              >
              <mat-button-toggle value="Quite difficult"
                >Quite difficult</mat-button-toggle
              >
              <mat-button-toggle value="Very difficult"
                >Very difficult</mat-button-toggle
              >
              <mat-button-toggle value="Impossible"
                >Impossible</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >15. If you or someone else in the family is visibly upset, does
            your child show signs of wanting to comfort them (e.g. stroking
            their hair, hugging them)?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="q15">
              <mat-button-toggle value="Always">Always</mat-button-toggle>
              <mat-button-toggle value="Usually">Usually</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Never">Never</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >16. Does your child do the same thing over and over again (e.g.
            running the tap, turning the light switch on and off, opening and
            closing doors)?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="q16">
              <mat-button-toggle value="Many times a day"
                >Many times a day</mat-button-toggle
              >
              <mat-button-toggle value="A few times a day"
                >A few times a day</mat-button-toggle
              >
              <mat-button-toggle value="A few times a week"
                >A few times a week</mat-button-toggle
              >
              <mat-button-toggle value="Less than once a week"
                >Less than once a week</mat-button-toggle
              >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >17. Would you describe your child's first words as:</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="q17">
              <mat-button-toggle value="Very typical"
                >Very typical</mat-button-toggle
              >
              <mat-button-toggle value="Quite typical"
                >Quite typical</mat-button-toggle
              >
              <mat-button-toggle value="Slightly unusual"
                >Slightly unusual</mat-button-toggle
              >
              <mat-button-toggle value="Very unusual"
                >Very unusual</mat-button-toggle
              >
              <mat-button-toggle value="My child doesn't speak"
                >My child doesn't speak</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >18. Does your child echo things s/he hears (e.g. things that you
            say, lines from songs or movies, sounds)?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="q18">
              <mat-button-toggle value="Many times a day"
                >Many times a day</mat-button-toggle
              >
              <mat-button-toggle value="A few times a day"
                >A few times a day</mat-button-toggle
              >
              <mat-button-toggle value="A few times a week"
                >A few times a week</mat-button-toggle
              >
              <mat-button-toggle value="Less than once a week"
                >Less than once a week</mat-button-toggle
              >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >19. Does your child use simple gestures (e.g. wave goodbye)?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="q19">
              <mat-button-toggle value="Many times a day"
                >Many times a day</mat-button-toggle
              >
              <mat-button-toggle value="A few times a day"
                >A few times a day</mat-button-toggle
              >
              <mat-button-toggle value="A few times a week"
                >A few times a week</mat-button-toggle
              >
              <mat-button-toggle value="Less than once a week"
                >Less than once a week</mat-button-toggle
              >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >20. Does your child make unusual finger movements near his/her
            eyes?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="q20">
              <mat-button-toggle value="Many times a day"
                >Many times a day</mat-button-toggle
              >
              <mat-button-toggle value="A few times a day"
                >A few times a day</mat-button-toggle
              >
              <mat-button-toggle value="A few times a week"
                >A few times a week</mat-button-toggle
              >
              <mat-button-toggle value="Less than once a week"
                >Less than once a week</mat-button-toggle
              >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >21. Does your child spontaneously look at your face to check your
            reaction when faced with something unfamiliar?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="q21">
              <mat-button-toggle value="Always">Always</mat-button-toggle>
              <mat-button-toggle value="Usually">Usually</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Never">Never</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >22. How long can your child's interest be maintained by just one or
            two objects?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="q22">
              <mat-button-toggle value="Most of the day"
                >Most of the day</mat-button-toggle
              >
              <mat-button-toggle value="Several hours"
                >Several hours</mat-button-toggle
              >
              <mat-button-toggle value="Half an hour"
                >Half an hour</mat-button-toggle
              >
              <mat-button-toggle value="Ten minutes"
                >Ten minutes</mat-button-toggle
              >
              <mat-button-toggle value="A couple of minutes"
                >A couple of minutes</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >23. Does your child twiddle objects repetitively (e.g. pieces of
            string)?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="q23">
              <mat-button-toggle value="Many times a day"
                >Many times a day</mat-button-toggle
              >
              <mat-button-toggle value="A few times a day"
                >A few times a day</mat-button-toggle
              >
              <mat-button-toggle value="A few times a week"
                >A few times a week</mat-button-toggle
              >
              <mat-button-toggle value="Less than once a week"
                >Less than once a week</mat-button-toggle
              >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >24. Does your child seem oversensitive to noise?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="q24">
              <mat-button-toggle value="Always">Always</mat-button-toggle>
              <mat-button-toggle value="Usually">Usually</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Never">Never</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >25. Does your child stare at nothing with no apparent
            purpose?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="q25">
              <mat-button-toggle value="Many times a day"
                >Many times a day</mat-button-toggle
              >
              <mat-button-toggle value="A few times a day"
                >A few times a day</mat-button-toggle
              >
              <mat-button-toggle value="A few times a week"
                >A few times a week</mat-button-toggle
              >
              <mat-button-toggle value="Less than once a week"
                >Less than once a week</mat-button-toggle
              >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          quantitativeChecklistForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!quantitativeChecklistForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
