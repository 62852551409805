import { createSelector } from '@ngrx/store';
import { State } from '..';
import { OrgConfigState } from './../state/org-config.state';

export const getOrgConfigDataFeature = (state: State) => state.orgConfig;

export const getOrgConfigData = createSelector(getOrgConfigDataFeature, (state: OrgConfigState) => state.payload);
export const getOrgConfigDataError = createSelector(getOrgConfigDataFeature, (state: OrgConfigState) => state.processingError);
export const getOrgConfigDataLoading = createSelector(getOrgConfigDataFeature, (state: OrgConfigState) => state.processingRequest);


