import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  profileJson: string = null;
  name: string = '';
  constructor(public keycloakService: KeycloakService) { }


  ngOnInit() {
    this.keycloakService.loadUserProfile().then(user => {
      this.name = user.firstName + ' ' + user.lastName;
    })
  }

  public get email() {
    return this.keycloakService.getUsername;
  }

}
