import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssessmentApiService, ToastMessageService } from 'src/app/_services';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-view-summary',
  templateUrl: './view-summary.component.html',
  styleUrls: ['./view-summary.component.css'],
})
export class ViewSummaryComponent implements OnInit {
  summaryForm: FormGroup;
  public patientId: string;
  public assessmentHistoryId: string;
  public formErrors: Record<string, Record<string, string>> = {};
  processing: boolean = false;
  summary: string;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ViewSummaryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public assessmentApiService: AssessmentApiService,
    private toastMessageService: ToastMessageService
  ) {
    this.assessmentHistoryId = data.record.id;
    this.patientId = data.record.patientId;
    this.summary = data.record.summary;
  }

  ngOnInit(): void {
    this.buildForm();

    if (this.summary) {
      this.summaryForm.controls['summary'].setValue(this.summary);
    }
  }

  onSubmit() {
    this.processing = true;
    this.assessmentApiService
      .updatePatientAssessmentSummary(this.summaryForm.value)
      .subscribe(
        (response) => {
          this.processing = false;
          this.toastMessageService.displaySuccessMessage(
            'Successfully updated summary document'
          );
          this.dialogRef.close('success');
        },
        () => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'An error occured while updating summary document'
          );
        }
      );
  }

  private buildForm() {
    this.summaryForm = this.formBuilder.group({
      id: new FormControl(this.assessmentHistoryId, Validators.compose([])),
      patientId: new FormControl(this.patientId, Validators.compose([])),
      summary: new FormControl('', Validators.required),
    });

    this.summaryForm.valueChanges.subscribe(() => this.onValueChanged());
  }

  private onValueChanged() {
    if (!this.summaryForm) {
      return;
    }
    const form = this.summaryForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }

  public validation_messages = {
    summary: [{ type: 'required', message: 'Summary is required' }],
  };
}
