import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Patient } from 'src/app/_models';
import { IAssessmentRequest } from 'src/app/_models/assessments/assessmentrequest.model';
import { ToastMessageService } from 'src/app/_services';
import { InSessionInterviewRequestApiService } from 'src/app/_services/session/insession-interview-request.service';

@Component({
  selector: 'app-add-insession-interview',
  templateUrl: './add-insession-interview.component.html',
  styleUrls: ['./add-insession-interview.component.css']
})
export class AddInSessionInterview implements OnInit {
  selectedAssessment: string = '';
  selectedAssessmentId: string = '';
  patientDetails: any = {};
  patient: Patient;
  sessionId: string;
  sessionCode: string;
  processing: boolean = false;
  assessmentList: any[] = [
    {
      id: 'asi',
      name: 'Addiction Severity Index(ASI)',
      description: 'Addiction Severity Index - 5th Edition',
      enabled: true,
      // }, {
      //   id: 'bpsa-intake',
      //   name: 'Intake',
      //   description: 'Biopsychosocial(BPSA) Intake Information',
      //   enabled: true,
      // }, {
      //   id: 'bpsa-clinical',
      //   name: 'Clinical',
      //   description: 'Biopsychosocial(BPSA) Clinical Information',
      //   enabled: true,
    }
  ];

  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9._%+-]+.[a-z]{2,4}$';
  assessmentType = new FormControl('', Validators.required);

  constructor(
    public dialogRef: MatDialogRef<AddInSessionInterview>,

    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private inSessionInterviewRequestApiService: InSessionInterviewRequestApiService,
    private toastMessageService: ToastMessageService
  ) {
    this.patient = data.patient;
    this.sessionId = data.sessionId;
    this.sessionCode = data.sessionCode;
    this.assessmentRequest = data.assessmentRequest;
  }

  public assessmentForm: FormGroup;
  assessmentRequest: IAssessmentRequest;

  ngOnInit() {
    this.buildForm();
  }

  private buildForm() {
    this.assessmentForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(null),
      requestorId: new FormControl(null),
      patientId: new FormControl(this.patient.id),
      sessionId: new FormControl(this.sessionId),
      sessionCode: new FormControl(this.sessionCode),
      assessmentType: new FormControl(''),
      status: new FormControl(null),
    });
  }

  assessmentRequestSubmit() {
    this.processing = true;
    this.assessmentRequest = Object.assign({}, this.assessmentRequest);
    this.assessmentRequest = Object.assign(
      this.assessmentRequest,
      this.assessmentForm.value
    );

    this.inSessionInterviewRequestApiService
      .addInSessionInterviewRequest(this.assessmentRequest)
      .subscribe(
        (response) => {
          this.processing = false;
          this.assessmentForm.reset();
          this.dialogRef.close(this.assessmentRequest.id);
          this.toastMessageService.displaySuccessMessage(
            'Successfully requested InSession Interview'
          );
        },
        () => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to add InSession Interview'
          );
          this.processing = false;
        }
      );
  }

  selectPatientDetails(patientDetails: any, stepper: MatStepper) {
    this.patientDetails = patientDetails;
    // this.patientEmailAddress.setValue(this.patientDetails.emailAddress);
    stepper.next();
  }

  goForward(stepper: MatStepper) {
    stepper.next();
  }

  assessmentStepper(stepper: MatStepper) {
    stepper.previous();
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  onSelectAssessmentType(selectedAssessment: any, stepper: MatStepper) {
    if (selectedAssessment.enabled) {
      this.selectedAssessment = selectedAssessment.name;
      this.selectedAssessmentId = selectedAssessment.id;
      this.assessmentType.setValue(selectedAssessment.name);
      this.assessmentForm.controls['assessmentType'].setValue(
        selectedAssessment.name
      );
      stepper.next();
    }
  }
}
