import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { subMinutes } from 'date-fns';
import { DataTablesModel } from 'src/app/_models';
import {
  DateTimeZoneService,
  TherapySessionService,
  ToastMessageService,
} from 'src/app/_services';
import { VideoIframeComponent } from 'src/app/shared/components/video-iframe/video-iframe.component';

@Component({
  selector: 'app-member-dashboard-virtual-appointments',
  templateUrl: './member-dashboard-virtual-appointments.component.html',
  styleUrl: './member-dashboard-virtual-appointments.component.css',
})
export class MemberDashboardVirtualAppointmentsComponent implements OnInit {
  isProcessing: boolean = false;
  data: DataTablesModel = {} as DataTablesModel;
  guestURLProcessing: boolean = false;
  currentTimezone: string = '';

  constructor(
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private therapySessionService: TherapySessionService,
    private toastMessageService: ToastMessageService,
    private dateTimeZoneService: DateTimeZoneService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit(): void {
    this.data.page = 0;
    this.data.per_page = 10;

    this.loadMyVirtualAppointments();
  }

  loadMyVirtualAppointments() {
    this.isProcessing = true;
    this.therapySessionService
      .getPatientAppointments(this.data.per_page, this.data.page)
      .subscribe({
        next: (response) => {
          if (response) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }
          this.isProcessing = false;
        },
        error: (error) => {
          this.isProcessing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load Tele-Health appointments.'
          );
        },
      });
  }

  getNextVirtual(event) {
    this.data.page = event.pageIndex;
    this.loadMyVirtualAppointments();
  }

  // Disable/Enable Join
  isJoinValid(appointment) {
    let currentTime = new Date();
    let appointmentStartTime = new Date(appointment.start);
    let appointmentEndTime = new Date(appointment.end);

    let delayedStartTime = subMinutes(appointmentStartTime, 5);
    let delayedEndTime = subMinutes(appointmentEndTime, 0);

    if (currentTime >= delayedStartTime && currentTime <= delayedEndTime) {
      return true;
    } else {
      return false;
    }
  }

  // Join Meeting
  JoinMeeting(appointmentId) {
    //First Get the host url
    this.guestURLProcessing = true;

    this.therapySessionService.getGuestUrl(appointmentId).subscribe({
      next: (response) => {
        this.guestURLProcessing = false;
        let hostUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          response.data.guestUrl
        );

        this.dialog.open(VideoIframeComponent, {
          data: {
            iframeMeeting: response.data.title,
            hostUrl: hostUrl,
          },
          minWidth: '100vw',
          maxWidth: '100vw',
          minHeight: '100vh',
          maxHeight: '100vh',
          autoFocus: false,
          disableClose: true,
        });
      },
      error: (error) => {
        this.guestURLProcessing = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Unable to load Tele-Health meeting.'
        );
      },
    });
  }
}
