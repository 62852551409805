<mat-card
  class="mat-elevation-z8"
  appearance="raised"
  *ngIf="hasGoalsViewAccess"
  style="margin-bottom: 20px"
>
  <div
    class="row"
    *ngIf="
      hasGoalsAddAccess &&
      patient.status === 'Active' &&
      case.status === 'active'
    "
    style="margin-bottom: 15px"
  >
    <div class="col-md-12" style="text-align: right">
      <button type="button" mat-raised-button color="warn" (click)="addGoal()">
        + Add Goal
      </button>
    </div>
  </div>

  <div *ngIf="data.total === 0">
    <mat-error> No goal found </mat-error>
  </div>

  <div class="mat-elevation-z8" *ngIf="data.total > 0">
    <div *ngIf="isLoadingResults || apiError">
      <mat-progress-bar
        mode="indeterminate"
        *ngIf="isLoadingResults"
      ></mat-progress-bar>

      <mat-error *ngIf="apiError">
        An error occurred while retrieving Goal records
      </mat-error>
    </div>

    <div class="table-responsive">
      <table mat-table [dataSource]="data.items" matSort matSortDisableClear>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Description</th>
          <td mat-cell *matCellDef="let row">{{ row.description }}</td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let row">
            <span
              *ngIf="row?.status === 'active'"
              style="
                color: #3ac47d;
                font-weight: 900;
                text-transform: capitalize;
              "
            >
              {{ row?.status }}
            </span>
            <span
              *ngIf="row?.status != 'active'"
              style="
                color: #d92550;
                font-weight: 900;
                text-transform: capitalize;
              "
            >
              {{ row?.status }}
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="priority">
          <th mat-header-cell *matHeaderCellDef>Priority</th>
          <td mat-cell *matCellDef="let row">{{ row.priority }}</td>
        </ng-container>
        <ng-container matColumnDef="rank">
          <th mat-header-cell *matHeaderCellDef>Rank</th>
          <td mat-cell *matCellDef="let row" style="text-transform: capitalize">
            {{ row.rank }}
          </td>
        </ng-container>
        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef>Category</th>
          <td mat-cell *matCellDef="let row">{{ row.category }}</td>
        </ng-container>
        <ng-container matColumnDef="targetDate">
          <th mat-header-cell *matHeaderCellDef>Target Date</th>
          <td mat-cell *matCellDef="let row">{{ row.targetDate }}</td>
        </ng-container>

        <ng-container matColumnDef="in-targetDate">
          <th mat-header-cell *matHeaderCellDef>Target Date</th>
          <td mat-cell *matCellDef="let row">
            {{ row.targetDate | date : 'dd/MM/YYYY' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="closedDate">
          <th mat-header-cell *matHeaderCellDef>Close Date</th>
          <td mat-cell *matCellDef="let row">{{ row.closedDate }}</td>
        </ng-container>

        <ng-container matColumnDef="in-closedDate">
          <th mat-header-cell *matHeaderCellDef>Close Date</th>
          <td mat-cell *matCellDef="let row">
            {{ row.closedDate | date : 'dd/MM/YYYY' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions" stickyEnd>
          <th mat-header-cell *matHeaderCellDef style="text-align: right">
            Actions
          </th>
          <td mat-cell *matCellDef="let record" style="text-align: right">
            <button
              *ngIf="case.status === 'active'"
              type="button"
              mat-raised-button
              [matMenuTriggerFor]="menu"
              class="btn btn-just-icon btn-white btn-fab btn-round pull-right"
            >
              <i class="material-icons text_align-center">more_vert</i>
            </button>

            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                (click)="editGoal(record)"
                *ngIf="hasGoalsEditAccess"
              >
                <mat-icon style="color: #f39121"> edit </mat-icon>
                <span>Edit</span>
              </button>
              <button
                mat-menu-item
                (click)="deleteGoal(record)"
                *ngIf="hasGoalsDeleteAccess"
              >
                <mat-icon style="color: red"> delete_forever </mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <mat-paginator
      [showFirstLastButtons]="true"
      #paginator
      [length]="data.total"
      [pageSize]="data.per_page"
      *ngIf="data.total > data.per_page"
      (page)="getNext($event)"
    >
    </mat-paginator>
  </div>
</mat-card>

<mat-card appearance="raised" *ngIf="!hasGoalsViewAccess">
  <mat-card-header>
    <mat-card-title>
      <mat-error> Sorry, You don't have access to view goals </mat-error>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content> </mat-card-content>
</mat-card>
