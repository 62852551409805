<div class="row" *ngIf="displayBreadCrumbs">
  <div class="col-md-12">
    <p style="font-size: 16px; font-weight: 450">
      <a routerLink="/" routerLinkActive="active">
        <em
          class="material-icons text_align-center"
          style="font-size: 18px; margin-top: -3px"
          >home</em
        >
      </a>
      <span *ngIf="selectedAssessmentType">
        &nbsp; / &nbsp;
        <a routerLink="/config/playbooks">Play Books</a>
        &nbsp; / &nbsp;
        {{ selectedAssessmentType }} Goals & Objectives
      </span>
      <span *ngIf="!selectedAssessmentType"
        >&nbsp; / &nbsp; Assessment Goals</span
      >
    </p>
  </div>
</div>

<mat-progress-bar
  mode="indeterminate"
  *ngIf="selectedAssessmentType && isLoadingResults"
></mat-progress-bar>

<div class="row">
  <div class="col-md-12" style="text-align: right">
    <button mat-raised-button color="warn" (click)="addGoal()">
      + Add Goal
    </button>
  </div>
  <div
    class="col-md-12"
    *ngIf="(!data?.total || data?.total == 0) && !isLoadingResults"
    style="text-align: center"
  >
    <span style="font-weight: 450; font-size: 14px; color: #d92550">
      No goal found
    </span>
  </div>

  <div class="gridContainer" *ngIf="data?.total > 0">
    <mat-card
      appearance="raised"
      *ngFor="let goal of data.items"
      style="border-radius: 20px"
    >
      <span
        class="material-icons"
        style="
          color: gray;
          position: absolute;
          right: 15px;
          top: 20px;
          font-size: 17px;
          cursor: pointer;
          border-radius: 50%;
          z-index: 2;
          border: 2px solid darkgray;
        "
        [matMenuTriggerFor]="menu"
      >
        more_vert
      </span>

      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="editGoal(goal)" type="button">
          <mat-icon style="color: #f39121"> edit </mat-icon>
          <span>Edit</span>
        </button>
        <button mat-menu-item (click)="deleteGoal(goal)" type="button">
          <mat-icon style="color: red"> delete_forever </mat-icon>
          <span>Delete</span>
        </button>
      </mat-menu>

      <label
        style="
          font-size: 18px;
          font-weight: 600;
          color: #2196f3;
          padding-left: 20px;
          padding-right: 25px;
        "
      >
        {{ goal.description }}
      </label>
      <div class="goalContainer">
        <span *ngIf="goal.question">
          <label class="labelHeader">Question #</label>
          <label class="labelText">
            {{ goal.question }}
          </label>
        </span>

        <span *ngIf="goal.answers">
          <label class="labelHeader">Response(s)</label>
          <label class="labelText">
            <p *ngFor="let answer of goal.answers" style="margin: 0">
              {{ answer }}
            </p>
          </label>
        </span>

        <label class="labelHeader">Objective(s)</label>
        <label class="labelText">
          <ul>
            <div *ngFor="let objective of goal.objectives">
              <li style="margin-bottom: 5px">
                {{ objective.objectiveDescription }}
                <br />
                <span style="opacity: 0.6"
                  >(Target {{ objective.targetNumber }}
                  {{ objective.targetType }})
                </span>
              </li>
            </div>
          </ul>
        </label>

        <label class="labelHeader">Status </label>
        <label class="labelText">
          <span
            *ngIf="goal?.status === 'Active'"
            style="color: #3ac47d; font-weight: 900"
          >
            {{ goal?.status }}
          </span>
          <span
            *ngIf="goal?.status != 'Active'"
            style="color: #d92550; font-weight: 900"
          >
            {{ goal?.status }}
          </span>
        </label>
      </div>
    </mat-card>
  </div>
  <mat-paginator
    #paginator
    [showFirstLastButtons]="true"
    [length]="data.total"
    [pageSize]="data.per_page"
    (page)="getNext($event)"
    *ngIf="data.total > data.per_page"
    style="width: 100%; background-color: transparent; margin-right: 20px"
  >
  </mat-paginator>
</div>
