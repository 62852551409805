<div class="row">
  <div class="col-md-12">
    <p style="font-size: 16px; font-weight: 450">
      <a routerLink="/" routerLinkActive="active">
        <em
          class="material-icons text_align-center"
          style="font-size: 18px; margin-top: -3px"
          >home</em
        >
      </a>
      &nbsp; / &nbsp;
      <span style="color: dimgray">My Notification Preferences </span>
    </p>
  </div>
</div>

<label style="padding: 0; margin: 0">
  We may still send you important notifications about your account outside of
  your notification settings.
</label>

<hr *ngIf="!processing" />
<mat-progress-bar
  mode="indeterminate"
  *ngIf="processing"
  style="margin-bottom: 12px; margin-top: 10px"
></mat-progress-bar>

<div>
  <!-- Physicians Assessment Emails -->
  <div
    class="row"
    style="align-items: center; margin-bottom: 12px"
    *ngIf="isPhysician"
  >
    <div class="col-md-7">
      <label class="labelHeader"> Assessments Review</label>
      <label>
        Get notified when members complete the assessments that are requested by
        you.
      </label>
    </div>
    <div class="col-md-5">
      <mat-slide-toggle
        color="primary"
        (change)="handleToggleChange($event, 'ASSESSMENTS_REVIEW_EMAIL')"
        [checked]="getChecked('ASSESSMENTS_REVIEW_EMAIL')"
      >
        Email
      </mat-slide-toggle>
    </div>
  </div>

  <div
    class="row"
    style="align-items: center; margin-bottom: 12px"
    *ngIf="isPhysician"
  >
    <div class="col-md-7">
      <label class="labelHeader"> Assessments Alerts </label>
      <label>
        Get notified when members complete the assessment and you have alerts
        set up in your playbook.
      </label>
    </div>
    <div
      class="col-md-5"
      style="display: flex; flex-direction: column; row-gap: 10px"
    >
      <mat-slide-toggle
        color="primary"
        (change)="handleToggleChange($event, 'ASSESSMENT_ALERT_EMAIL')"
        [checked]="getChecked('ASSESSMENT_ALERT_EMAIL')"
      >
        Email
      </mat-slide-toggle>
      <mat-slide-toggle
        color="primary"
        (change)="handleToggleChange($event, 'ASSESSMENT_ALERT_SMS')"
        [checked]="getChecked('ASSESSMENT_ALERT_SMS')"
      >
        SMS
      </mat-slide-toggle>
    </div>
  </div>

  <div
    class="row"
    style="align-items: center; margin-bottom: 12px"
    *ngIf="isPhysician"
  >
    <div class="col-md-7">
      <label class="labelHeader"> Notes </label>
      <label> Get notified when a new note is assigned to you. </label>
    </div>
    <div class="col-md-5">
      <mat-slide-toggle
        color="primary"
        (change)="handleToggleChange($event, 'THERAPY_SESSION_EMAIL')"
        [checked]="getChecked('THERAPY_SESSION_EMAIL')"
      >
        Email
      </mat-slide-toggle>
    </div>
  </div>

  <!-- Patient Assessment Emails -->
  <div
    class="row"
    style="align-items: center; margin-bottom: 12px"
    *ngIf="isPatient"
  >
    <div class="col-md-7">
      <label class="labelHeader"> Assessments </label>
      <label> Get notified when a clinician assigns a new assessment. </label>
    </div>
    <div class="col-md-5">
      <mat-slide-toggle
        color="primary"
        (change)="handleToggleChange($event, 'ASSESSMENT_EMAIL')"
        [checked]="getChecked('ASSESSMENT_EMAIL')"
      >
        Email
      </mat-slide-toggle>
    </div>
  </div>

  <div
    class="row"
    style="align-items: center; margin-bottom: 12px"
    *ngIf="isPatient"
  >
    <div class="col-md-7">
      <label class="labelHeader"> Appointments </label>
      <label>
        Get notified when a new appointment is scheduled with a clinician.
      </label>
    </div>
    <div class="col-md-5">
      <mat-slide-toggle
        color="primary"
        (change)="handleToggleChange($event, 'THERAPY_SESSION_EMAIL')"
        [checked]="getChecked('THERAPY_SESSION_EMAIL')"
      >
        Email
      </mat-slide-toggle>
    </div>
  </div>

  <div
    class="row"
    style="align-items: center; margin-bottom: 12px"
    *ngIf="isPatient"
  >
    <div class="col-md-7">
      <label class="labelHeader"> Goals </label>
      <label> Get notified when a clinician assigns a new goal. </label>
    </div>
    <div class="col-md-5">
      <mat-slide-toggle
        color="primary"
        (change)="handleToggleChange($event, 'GOALS_EMAIL')"
        [checked]="getChecked('GOALS_EMAIL')"
      >
        Email
      </mat-slide-toggle>
    </div>
  </div>

  <!-- Save Button -->
  <div
    style="
      display: flex;
      justify-content: center;
      gap: 25px;
      flex-wrap: wrap;
      margin-top: 15px;
    "
  >
    <div>
      <button
        mat-raised-button
        type="button"
        (click)="resetChanges()"
        [disabled]="processing"
      >
        Cancel
      </button>
    </div>
    <div>
      <button
        mat-raised-button
        type="button"
        color="primary"
        (click)="saveChanges()"
        [disabled]="processing"
      >
        Save Changes <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </div>
  </div>
</div>
