import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DataTablesModel, IPhysician, ResponseModal } from '../../_models';


@Injectable({ providedIn: 'root' })
export class PhysicianHttpService {
    constructor(private httpClient: HttpClient) { }

    getPhysicians(patientId: string, pageSize: number, pageIndex: number): Observable<DataTablesModel> {
        let params = new HttpParams();
        params = params.append('patientId', patientId);
        params = params.append('pageSize', pageSize.toString());
        params = params.append('pageIndex', pageIndex.toString());
        return this.httpClient.get<DataTablesModel>(`${environment.apiUrl}/physicians`, { params: params });
    }

    getPhysicianDetails(physicianId: string): Observable<ResponseModal> {
        return this.httpClient.get<ResponseModal>(`${environment.apiUrl}/physician/` + physicianId);
    }

    addPhysician(physician: IPhysician): Observable<IPhysician> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.post<IPhysician>(`${environment.apiUrl}/physician`, physician, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }

    updatePhysician(physician: IPhysician): Observable<IPhysician> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.put<IPhysician>(`${environment.apiUrl}/physician`, physician, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }

    deletePhysician(physician: IPhysician): Observable<IPhysician> {
        return this.httpClient.delete<IPhysician>(`${environment.apiUrl}/physician/` + physician.id).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }

}