<mat-card-title>Member Medical History</mat-card-title>
<mat-divider></mat-divider>

<div style="margin-top: 20px; padding: 10px">

  <mat-chip-listbox #chipList multiple="false">
    <mat-chip-option [class]="
        activeTab === 'PATIENT HISTORY'
          ? 'selectedChipClass mat-elevation-z8'
          : 'activeChipClass mat-elevation-z8'
      " (click)="activeTab = 'PATIENT HISTORY'">
      Member History
    </mat-chip-option>
    <mat-chip-option [class]="
        activeTab === 'FAMILY HISTORY'
          ? 'selectedChipClass mat-elevation-z8'
          : 'activeChipClass mat-elevation-z8'
      " (click)="activeTab = 'FAMILY HISTORY'">
      Family History
    </mat-chip-option>
  </mat-chip-listbox>

  <app-medical-history *ngIf="activeTab === 'PATIENT HISTORY'" [patient]="patient"
    [hasMemberHistoryViewAccess]="hasMemberHistoryViewAccess"
    [hasMemberHistoryEditAccess]="hasMemberHistoryEditAccess"></app-medical-history>

  <app-family-history *ngIf="activeTab === 'FAMILY HISTORY'" [patient]="patient"
    [hasFamilyHistoryViewAccess]="hasFamilyHistoryViewAccess"
    [hasFamilyHistoryEditAccess]="hasFamilyHistoryEditAccess"></app-family-history>
</div>