import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap } from 'rxjs';
import { FavoritesService } from 'src/app/_services/users/user-favorites.service';
import * as FavoritesActions from '../actions/favorites.actions';

@Injectable()
export class FavoritesEffects {
  constructor(
    private actions$: Actions,
    private favoritesService: FavoritesService
  ) {}

  loadFavorites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoritesActions.loadFavorites),
      mergeMap((action) =>
        this.favoritesService.getFavorites().pipe(
          map((favoritesPayload) =>
            FavoritesActions.loadFavoritesSuccess({ favoritesPayload })
          ),
          catchError((error) =>
            of(FavoritesActions.loadFavoritesFailure({ error }))
          )
        )
      )
    )
  );

  addFavorite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoritesActions.addFavorite),
      mergeMap((action) =>
        this.favoritesService
          .addFavorite(action.categoryName, action.favoriteItem)
          .pipe(
            map(() =>
              FavoritesActions.addFavoriteSuccess({
                categoryName: action.categoryName,
                favoriteItem: action.favoriteItem,
              })
            ),
            catchError((error) =>
              of(FavoritesActions.addFavoriteFailure({ error }))
            )
          )
      ),
      switchMap(() => [
        FavoritesActions.loadFavorites() /**  Load the favorities, once the add action is completed*/,
      ])
    )
  );

  removeFavorite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoritesActions.removeFavorite),
      mergeMap((action) =>
        this.favoritesService
          .removeFavorite(action.categoryName, action.favoriteItem)
          .pipe(
            map(() =>
              FavoritesActions.removeFavoriteSuccess({
                categoryName: action.categoryName,
                favoriteItem: action.favoriteItem,
              })
            ),
            catchError((error) =>
              of(FavoritesActions.removeFavoriteFailure({ error }))
            )
          )
      ),
      switchMap(() => [
        FavoritesActions.loadFavorites() /**  Load the favorities, once the remove action is completed*/,
      ])
    )
  );
}
