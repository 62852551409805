import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import SignaturePad from 'signature_pad';
import { ToastMessageService } from 'src/app/_services';
import { SignatureService } from 'src/app/_services/utils/signature.service';

@Component({
  selector: 'app-guest-intake-signature-pad',
  templateUrl: './guest-intake-signature-pad.component.html',
  styleUrl: './guest-intake-signature-pad.component.css',
})
export class GuestIntakeSignaturePadComponent implements OnInit, AfterViewInit {
  @ViewChild('canvas') canvasEl!: ElementRef;
  @ViewChild('canvasContainer') containerRef!: ElementRef<HTMLDivElement>;

  requestId: string = '';
  accessCode: string = '';
  organizationId: string = '';
  consentRequestId: string = '';
  consentDocumentId: string = '';
  documentName: string = '';
  esignFormName: string = '';
  pageName: string = '';
  pageUrl: string = '';

  signaturePad!: SignaturePad;
  signatureStroke = false;
  processing: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<GuestIntakeSignaturePadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private signatureService: SignatureService,
    private toastMessageService: ToastMessageService
  ) {
    this.requestId = data.requestId;
    this.accessCode = data.accessCode;
    this.organizationId = data.organizationId;
    this.consentRequestId = data.consentRequestId;
    this.consentDocumentId = data.consentDocumentId;
    this.documentName = data.documentName;

    this.esignFormName = data.esignFormName;
    this.pageName = data.pageName;
    this.pageUrl = data.pageUrl;
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
    this.addSignaturePadListener();
    this.resizeCanvas();
  }

  @HostListener('window:resize')
  onWindowResize(): void {
    this.resizeCanvas();
  }

  resizeCanvas(): void {
    if (this.canvasEl) {
      const canvas = this.canvasEl.nativeElement;
      const container = this.containerRef.nativeElement;

      // Set canvas dimensions to match container's dimensions
      canvas.width = container.clientWidth - 30;
    }
  }

  addSignaturePadListener() {
    if (this.signaturePad) {
      this.signaturePad.addEventListener('beginStroke', () => {
        this.signatureStroke = true;
      });
    }
  }

  clearPad() {
    this.signatureStroke = false;
    this.signaturePad.clear();
  }

  redoSignature() {
    this.wait(1000).then((result) => {
      this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
      this.addSignaturePadListener();
    });
  }

  wait(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  saveSignature() {
    const dataUrl = this.signaturePad.toDataURL();

    this.processing = true;

    this.convertDataURLToFile(dataUrl, (file) => {
      this.signatureService
        .saveGuestConsentSignature(
          this.requestId,
          this.accessCode,
          this.consentRequestId,
          this.consentDocumentId,
          this.pageName,
          this.pageUrl,
          'png',
          file
        )
        .subscribe({
          next: (response) => {
            this.processing = false;
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted the document.'
            );
            this.dialogRef.close('success');
          },
          error: (error) => {
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to save the signature.'
            );
            this.processing = false;
          },
        });
    });
  }

  private convertDataURLToFile(
    dataUrl: string,
    callback: (file: File) => void
  ) {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    const file = new File([u8arr], 'signature.png', { type: mime });
    callback(file);
  }
}
