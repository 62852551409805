<mat-card-title *ngIf="showTitle">Member Profile</mat-card-title>
<mat-divider *ngIf="showTitle"></mat-divider>

<div style="margin-top: 40px; padding: 10px">
  <form [formGroup]="patientForm" (ngSubmit)="onPatientFormSubmit()">
    <div class="row">
      <!-- General Information -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <label class="labelHeader">
          <mat-icon style="margin-right: 10px">person</mat-icon> General
          Information
        </label>
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-4">
              <label class="labelText">
                First Name <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input matInput placeholder="First Name" formControlName="firstName" name="firstName" minlength="1"
                  required />
                <mat-error *ngIf="patientForm.controls['firstName'].invalid">
                  First Name is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText"> Middle Name </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="middleName" name="middleName" />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">
                Last Name <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input matInput placeholder="Last Name" formControlName="lastName" name="lastName" minlength="1"
                  required />
                <mat-error *ngIf="patientForm.controls['lastName'].invalid">
                  Last Name is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">
                Date of Birth <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input formControlName="dateOfBirth" matInput [matDatepicker]="dobDatePicker" [max]="maxDate"
                  placeholder="MM/DD/YYYY" required autoSlashDate />
                <mat-datepicker-toggle matSuffix [for]="dobDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #dobDatePicker></mat-datepicker>

                <mat-error *ngIf="patientForm.controls['dateOfBirth'].invalid">
                  Please enter a valid Date of Birth
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">
                Gender <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="gender" placeholder="Gender" required
                  (selectionChange)="genderChanged($event)">
                  <mat-option [value]="r.id" *ngFor="let r of genderList">
                    {{ r.description }}
                  </mat-option>
                </mat-select>

                <mat-error *ngIf="patientForm.controls['gender'].invalid">
                  Gender is required
                </mat-error>
              </mat-form-field>
            </div>


            <!-- SSN -->
            <div class="col-md-4">
              <label class="labelText"> Social Security Number (SSN) </label>
              <mat-form-field appearance="outline">
                <input matInput placeholder="XXX-XX-XXXX" formControlName="ssn" name="ssn"
                  [type]="showSSN ? 'text' : 'password'" />
                <mat-icon matSuffix (click)="showSSN = !showSSN">
                  {{ showSSN ? 'visibility_off' : 'visibility' }}
                </mat-icon>
                <mat-error *ngIf="patientForm.controls['ssn'].invalid">
                  Please enter a valid SSN
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <label class="labelText"> Height </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="height" name="height" type="number" min="0" />
                <span matSuffix> in </span>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <label class="labelText"> Weight </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="weight" name="weight" type="number" min="0" />
                <span matSuffix> Lb </span>
              </mat-form-field>
            </div>

            <!-- Gender at birth -->
            <div class="col-md-4" *ngIf="patientForm.controls['gender'].value === 'O'">
              <label class="labelText">
                Gender at Birth<span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="genderAtBirth" placeholder="Gender at Birth">
                  <mat-option value="M"> Male </mat-option>
                  <mat-option value="F"> Female </mat-option>
                </mat-select>

                <mat-error *ngIf="patientForm.controls['genderAtBirth'].invalid">
                  Gender is required
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-4">
              <label class="labelText"> Pronouns </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="pronouns" name="pronouns" />
              </mat-form-field>
            </div>

            <div class="col-md-4">
              <label class="labelText">
                Admittance Date
              </label>
              <mat-form-field appearance="outline">
                <input formControlName="admittanceDate" matInput [matDatepicker]="admittanceDatePicker" [max]="maxDate"
                  placeholder="MM/DD/YYYY" autoSlashDate />
                <mat-datepicker-toggle matSuffix [for]="admittanceDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #admittanceDatePicker></mat-datepicker>

                <mat-error *ngIf="patientForm.controls['admittanceDate'].invalid">
                  Please enter a valid Admittance Date
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Contact Information -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <label class="labelHeader">
          <mat-icon style="margin-right: 10px">contact_phone</mat-icon> Contact
          Information
        </label>
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-8">
              <label class="labelText">Email</label>
              <mat-form-field appearance="outline">
                <input matInput placeholder="Email" formControlName="email" name="email" />
                <mat-error *ngIf="patientForm.controls['email'].invalid">
                  Please enter a valid email address
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">
                Phone Preference <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="phonePreference" placeholder="Phone Prefence"
                  (selectionChange)="phonePreferenceChanged($event)">
                  <mat-option value="Cell Phone">Cell Phone</mat-option>
                  <mat-option value="Home Phone">Home Phone</mat-option>
                  <mat-option value="Work Phone">Work Phone</mat-option>
                </mat-select>
                <mat-error *ngIf="patientForm.controls['phonePreference'].invalid">
                  Please select the phone preference
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">Cell Phone Number </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="phoneNumber" name="phoneNumber" placeholder="(999) 999-9999" />
                <mat-error *ngIf="patientForm.controls['phoneNumber'].invalid">
                  Please enter a valid phone number
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">
                Home Phone Number
                <span style="color: red" *ngIf="
                    patientForm.controls['phonePreference'].value ===
                    'Home Phone'
                  ">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="homePhoneNumber" name="homePhoneNumber" />
                <mat-error *ngIf="patientForm.controls['homePhoneNumber'].invalid">
                  Please enter a valid home number
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">Work Phone Number
                <span style="color: red" *ngIf="
                    patientForm.controls['phonePreference'].value ===
                    'Work Phone'
                  ">*</span></label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="workPhoneNumber" name="workPhoneNumber" />
                <mat-error *ngIf="patientForm.controls['workPhoneNumber'].invalid">
                  Please enter a valid work number
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Address Information -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <label class="labelHeader">
          <mat-icon style="margin-right: 10px">home</mat-icon>
          Address
        </label>
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-8">
              <label class="labelText">
                Address 1 <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input matInput [options]="options" ngx-gp-autocomplete #placesRef="ngx-places"
                  (onAddressChange)="handleAddressChange($event)" formControlName="addressLine1" name="addressLine1"
                  required />

                <mat-error *ngIf="patientForm.controls['addressLine1'].invalid">
                  Please enter the address line 1
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">Address 2</label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="addressLine2" name="addressLine2" />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">City <span style="color: red">*</span></label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="city" name="city" placeholder="City" />
                <mat-error *ngIf="patientForm.controls['city'].invalid">
                  Please enter the city
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">State <span style="color: red">*</span></label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="state" placeholder="State">
                  <mat-option [value]="state.id" *ngFor="let state of usStates">
                    {{ state.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="patientForm.controls['state'].invalid">
                  Please select the state
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">{{ isIndianOrg ? 'Pin Code' : 'Zip' }}
                <span style="color: red">*</span></label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="zip" name="zip" placeholder="Zip" />
                <mat-error *ngIf="patientForm.controls['zip'].invalid">
                  Please enter a valid zip
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Demographic Information -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <label class="labelHeader">
          <mat-icon style="margin-right: 10px">note_alt</mat-icon>
          Race, Ethnicity, Status
        </label>
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-4">
              <label class="labelText">Race </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="race" placeholder="Race">
                  <mat-option [value]="r.id" *ngFor="let r of raceList">
                    {{ r.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">Ethnicity </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="ethnicity" placeholder="Ethnicity">
                  <mat-option [value]="r.id" *ngFor="let r of ethnicityList">
                    {{ r.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">Preferred Language </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="preferredLanguage" placeholder="Language">
                  <mat-option [value]="r.id" *ngFor="let r of languageList">
                    {{ r.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">Employment Status </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="employmentStatus" placeholder="Employment Status">
                  <mat-option [value]="r.id" *ngFor="let r of employmentList">
                    {{ r.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">Marital Status</label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="maritalStatus" placeholder="Marital Status">
                  <mat-option [value]="r.id" *ngFor="let r of marriedStatusList">
                    {{ r.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">Time Zone </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="timeZone" placeholder="Time Zone">
                  <mat-option [value]="r.id" *ngFor="let r of timeZoneList">
                    {{ r.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>
    </div>

    <div class="row" *ngIf="isIndianOrg">
      <mat-form-field appearance="outline">
        <mat-label>Aadhaar No</mat-label>
        <input matInput placeholder="Aadhaar No" formControlName="aadhaarNo" name="aadhaarNo" minlength="1" />
        <mat-error></mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Pan No</mat-label>
        <input matInput placeholder="PAN No" formControlName="panNo" name="panNo" minlength="1" />
        <mat-error></mat-error>
      </mat-form-field>
    </div>

    <div class="row" *ngIf="showTitle">
      <div class="col-md-4">
        <label class="labelText">Status <span style="color: red">*</span></label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="status" placeholder="Status"
            (selectionChange)="confirmPatientStatusChange($event)">
            <mat-option value="Active">Active</mat-option>
            <mat-option value="Discharged">Discharged</mat-option>
            <mat-option value="Deceased">Deceased</mat-option>
          </mat-select>
          <mat-error></mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-8" *ngIf="patientForm.value.status === 'Discharged'">
        <label class="labelText">Discharged Date</label>
        <mat-form-field appearance="outline">
          <input formControlName="dischargedDate" matInput [matDatepicker]="dischargedDatePicker"
            placeholder="MM/DD/YYYY" [max]="maxDate" autoSlashDate />
          <mat-datepicker-toggle matSuffix [for]="dischargedDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #dischargedDatePicker></mat-datepicker>
          <mat-error></mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-8" *ngIf="patientForm.value.status === 'Deceased'">
        <label class="labelText">Deceased Date</label>
        <mat-form-field appearance="outline">
          <input formControlName="deceasedDate" matInput [matDatepicker]="deceasedDatePicker" placeholder="MM/DD/YYYY"
            [max]="maxDate" autoSlashDate />
          <mat-datepicker-toggle matSuffix [for]="deceasedDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #deceasedDatePicker></mat-datepicker>
          <mat-error></mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row" *ngIf="hasMemberEditAccess">
      <div class="col-md-12" style="text-align: right">
        <button color="primary" mat-raised-button type="submit" [disabled]="!patientForm.valid || processing">
          Update <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>