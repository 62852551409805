import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import {
  buildValidationMsgs,
  formatDate,
  mmddyyyyToDate,
} from 'src/app/shared/utilities/utilities';
import { Countries, IContact } from 'src/app/_models';
import { ContactHttpService, ToastMessageService } from 'src/app/_services/';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { USStates } from 'src/app/shared/utilities/billing/states';
import { Gender } from 'src/app/shared/utilities/billing/gender';
import { MarriedStatus } from 'src/app/shared/utilities/billing/maritalStatus';
import {
  NgxGpAutocompleteDirective,
  NgxGpAutocompleteOptions,
} from '@angular-magic/ngx-gp-autocomplete';

@Component({
  selector: 'app-add-contact-dialog',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AddContactDialogComponent implements OnInit {
  @ViewChild('ngxPlaces') placesRef: NgxGpAutocompleteDirective;
  options: NgxGpAutocompleteOptions = {
    componentRestrictions: { country: ['US'] },
    types: ['address'],
  };

  constructor(
    public dialogRef: MatDialogRef<AddContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public contactApiService: ContactHttpService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private localStorageService: LocalStorageService,
    private toastMessageService: ToastMessageService,
    private orgConfigSandbox: OrgConfigSandbox
  ) {
    this.action = data.action;
    this.contact = data.contact;
    this.patientId = data.patientId;
  }

  maxDate = new Date();
  public patientId: string;
  public action: string;
  public contactForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  contact: IContact;
  accountError: Error = null;
  errors: any = {};
  isIndianOrg: boolean = false;
  orgConfig: any;
  processing: boolean = false;
  usStates = USStates;
  genderList = Gender;
  marriedStatusList = MarriedStatus;

  public ngOnInit() {
    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          this.orgConfig = orgConfig;
          if (this.orgConfig?.organizationCountry === Countries.India) {
            this.isIndianOrg = true;
          }
        });
      }
    });

    this.buildForm();
    this.errorHandler.handleErrors(this.contactForm, this.errors);

    if (this.action === 'EDIT') {
      this.contactForm.controls['id'].setValue(this.contact.id);
      this.contactForm.controls['organizationId'].setValue(
        this.contact.organizationId
      );
      this.contactForm.controls['patientId'].setValue(this.contact.patientId);
      this.contactForm.controls['firstName'].setValue(this.contact.firstName);
      this.contactForm.controls['middleName'].setValue(this.contact.middleName);
      this.contactForm.controls['lastName'].setValue(this.contact.lastName);
      this.contactForm.controls['addressLine1'].setValue(
        this.contact.addressLine1
      );
      this.contactForm.controls['addressLine2'].setValue(
        this.contact.addressLine2
      );
      this.contactForm.controls['city'].setValue(this.contact.city);
      this.contactForm.controls['state'].setValue(this.contact.state);
      this.contactForm.controls['zip'].setValue(this.contact.zip);

      if (this.contact.dateOfBirth && this.contact.dateOfBirth !== '') {
        this.contactForm.controls['dateOfBirth'].setValue(
          mmddyyyyToDate(this.contact.dateOfBirth)
        );
      }
      this.contactForm.controls['gender'].setValue(this.contact.gender);
      this.contactForm.controls['relation'].setValue(this.contact.relation);
      this.contactForm.controls['maritalStatus'].setValue(
        this.contact.maritalStatus
      );
      this.contactForm.controls['contactType'].setValue(
        this.contact.contactType
      );

      this.contactForm.controls['phonePreference'].setValue(
        this.contact.phonePreference
      );
      this.contactForm.controls['phone'].setValue(this.contact.phone);
      this.contactForm.controls['homePhoneNumber'].setValue(
        this.contact.homePhoneNumber
      );
      this.contactForm.controls['workPhoneNumber'].setValue(
        this.contact.workPhoneNumber
      );

      this.contactForm.controls['fax'].setValue(this.contact.fax);
      this.contactForm.controls['email'].setValue(this.contact.email);
      this.contactForm.controls['notes'].setValue(this.contact.notes);
    } else {
    }
  }

  onContactFormSubmit() {
    if (this.contactForm.invalid) {
      this.contactForm.markAllAsTouched();
      return;
    }

    this.contact = Object.assign({}, this.contact);
    this.contact = Object.assign(this.contact, this.contactForm.value);

    if (this.contact.dateOfBirth && this.contact.dateOfBirth !== '') {
      this.contact.dateOfBirth = formatDate(new Date(this.contact.dateOfBirth));
    }

    this.processing = true;
    if (this.action === 'EDIT') {
      this.contactApiService.updateContact(this.contact).subscribe(
        () => {
          this.processing = false;
          this.dialogRef.close('success');
          this.toastMessageService.displaySuccessMessage(
            'Contact details have been updated.'
          );
        },
        (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to update contact information'
          );
        }
      );
    } else {
      this.contact.active = true;
      this.contact.patientId = this.patientId;
      this.contactApiService.addContact(this.contact).subscribe(
        () => {
          this.processing = false;
          this.dialogRef.close('success');
          this.toastMessageService.displaySuccessMessage(
            'New contact has been added.'
          );
        },
        (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to add contact information'
          );
        }
      );
    }
  }

  private buildForm() {
    this.contactForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(null),
      patientId: new FormControl(null),

      firstName: new FormControl('', Validators.required),
      middleName: new FormControl(''),
      lastName: new FormControl('', Validators.required),

      addressLine1: new FormControl(''),
      addressLine2: new FormControl(''),
      city: new FormControl(''),
      state: new FormControl(''),
      zip: new FormControl(
        '',
        Validators.compose([RxwebValidators.mask({ mask: '99999' })])
      ),

      dateOfBirth: new FormControl(''),
      gender: new FormControl('', Validators.required),
      relation: new FormControl('', Validators.required),
      maritalStatus: new FormControl(''),
      contactType: new FormControl('', Validators.required),
      phonePreference: new FormControl('Cell Phone', Validators.required),
      phone: new FormControl(
        '',
        Validators.compose([RxwebValidators.mask({ mask: '(999) 999-9999' })])
      ),
      homePhoneNumber: new FormControl(
        '',
        Validators.compose([RxwebValidators.mask({ mask: '(999) 999-9999' })])
      ),
      workPhoneNumber: new FormControl(
        '',
        Validators.compose([RxwebValidators.mask({ mask: '(999) 999-9999' })])
      ),

      fax: new FormControl(
        '',
        Validators.compose([RxwebValidators.mask({ mask: '(999) 999-9999' })])
      ),
      email: new FormControl(
        '',
        Validators.compose([
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ])
      ),
      notes: new FormControl(''),
    });

    this.contactForm.valueChanges.subscribe(() => this.onValueChanged());
  }

  public validation_messages = {
    firstName: [
      { type: 'required', message: 'First Name is required' },
      {
        type: 'minlength',
        message: 'First Name cannot be less than 2 characters long',
      },
    ],
    lastName: [
      { type: 'required', message: 'Last Name is required' },
      {
        type: 'minlength',
        message: 'Last Name cannot be less than 2 characters long',
      },
    ],
    phone: [
      {
        type: 'pattern',
        message: 'Please enter a valid 10 digit US Phone Number',
      },
    ],
    email: [{ type: 'pattern', message: 'Please enter a valid email address' }],
  };

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.contactForm) {
      return;
    }
    const form = this.contactForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }

  phonePreferenceChanged(event) {
    if (event.value === 'Home Phone') {
      this.contactForm
        .get('homePhoneNumber')
        .addValidators(Validators.required);
      this.contactForm.get('homePhoneNumber').updateValueAndValidity();

      this.contactForm.get('workPhoneNumber').clearValidators();
      this.contactForm.get('workPhoneNumber').updateValueAndValidity();
    } else if (event.value === 'Work Phone') {
      this.contactForm
        .get('workPhoneNumber')
        .addValidators(Validators.required);
      this.contactForm.get('workPhoneNumber').updateValueAndValidity();

      this.contactForm.get('homePhoneNumber').clearValidators();
      this.contactForm.get('homePhoneNumber').updateValueAndValidity();
    } else {
      this.contactForm.get('workPhoneNumber').clearValidators();
      this.contactForm.get('workPhoneNumber').updateValueAndValidity();

      this.contactForm.get('homePhoneNumber').clearValidators();
      this.contactForm.get('homePhoneNumber').updateValueAndValidity();
    }
  }

  handleAddressChange(address: google.maps.places.PlaceResult) {
    let addressField: string = '';

    for (const component of address.address_components as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number': {
          addressField = `${component.long_name} ${addressField}`;
          break;
        }

        case 'route': {
          addressField += component.long_name;
          break;
        }

        case 'locality':
          //city
          this.contactForm.controls['city'].setValue(component.long_name);
          break;

        case 'administrative_area_level_1': {
          //state
          this.contactForm.controls['state'].setValue(component.short_name);
          break;
        }

        case 'postal_code': {
          this.contactForm.controls['zip'].setValue(component.long_name);
          break;
        }
      }
    }
    if (addressField.trim() == '')
      addressField = address.formatted_address.split(',')[0];
    this.contactForm.controls['addressLine1'].setValue(addressField);
  }
}
