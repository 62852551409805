import { createReducer, on } from '@ngrx/store';
import { IUserFavoritesResponse } from '../../../_models/users/user-favorites.model';
import * as FavoritesActions from '../actions/favorites.actions';

export interface FavoritesState {
  favoritesPayload: IUserFavoritesResponse | null;
  loading: boolean;
  error: string | null;
}

export const initialState: FavoritesState = {
  favoritesPayload: null,
  loading: false,
  error: null,
};

export const favoritesReducer = createReducer(
  initialState,
  // Load favorites
  on(FavoritesActions.loadFavorites, (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),
  on(FavoritesActions.loadFavoritesSuccess, (state, { favoritesPayload }) => {
    return {
      ...state,
      favoritesPayload,
      loading: false,
    };
  }),
  on(FavoritesActions.loadFavoritesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  // Add favorite
  on(
    FavoritesActions.addFavoriteSuccess,
    (state, { categoryName, favoriteItem }) => {
      const updatedCategories =
        state.favoritesPayload?.data?.favoriteCategories.map((category) => {
          if (category.name === categoryName) {
            return {
              ...category,
              favorites: [...category.userFavorites, favoriteItem],
            };
          }
          return category;
        });
      return {
        ...state,
        favoritesPayload: {
          ...state.favoritesPayload!,
          categories: updatedCategories!,
        },
      };
    }
  ),
  on(FavoritesActions.addFavoriteFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  // Remove favorite
  on(
    FavoritesActions.removeFavoriteSuccess,
    (state, { categoryName, favoriteItem }) => {
      const updatedCategories =
        state.favoritesPayload?.data?.favoriteCategories.map((category) => {
          if (category.name === categoryName) {
            return {
              ...category,
              favorites: category.userFavorites.filter(
                (favorite) => favorite !== favoriteItem
              ),
            };
          }
          return category;
        });
      return {
        ...state,
        favoritesPayload: {
          ...state.favoritesPayload!,
          categories: updatedCategories!,
        },
      };
    }
  ),
  on(FavoritesActions.removeFavoriteFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);
