export const Gender = [
  {
    id: 'M',
    description: 'Male',
  },
  {
    id: 'F',
    description: 'Female',
  },
  // {
  //   id: 'U',
  //   description: 'Unknown',
  // },
  // {
  //   id: 'A',
  //   description: 'Ambiguous',
  // },
  {
    id: 'O',
    description: 'Other',
  },
  // {
  //   id: 'N',
  //   description: 'Not Applicable',
  // },
];
