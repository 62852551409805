import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataTablesModel, ITherapySession, Patient } from 'src/app/_models';

@Component({
  selector: 'app-added-goals',
  templateUrl: './added-goals.component.html',
  styleUrls: ['./added-goals.component.css'],
})
export class AddedGoalsComponent implements OnInit {
  @Input() data: DataTablesModel;
  @Input() therapySession: ITherapySession;
  @Input() patient: Patient;
  @Input() loggedInUserId: string;
  @Input() assignedGoalStatus: string;
  @Input() isLoadingResults: boolean;

  @Input() hasGoalsAddAccess: boolean;
  @Input() hasGoalsEditAccess: boolean;
  @Input() hasGoalsDeleteAccess: boolean;

  @Output() hasChanges = new EventEmitter<boolean>();
  @Output() changeAssignedGoalStatus = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  changesOccured(hasChangeEvent: boolean) {
    if (hasChangeEvent) {
      this.hasChanges.emit(true);
    }
  }

  changeStatus(status) {
    this.changeAssignedGoalStatus.emit({ newStatus: status });
  }
}
