import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IBpsaSubstanceEtc } from 'src/app/_models/session/clinical/bpsa-clinical-substance-etc.model';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class BpsaSubstanceEtcApiService {
    constructor(private httpClient: HttpClient) { }
    updateBpsaSubstanceEtc(thisBpsaSubstanceEtc: IBpsaSubstanceEtc): Observable<IBpsaSubstanceEtc> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.put<IBpsaSubstanceEtc>(`${environment.apiUrl}/bpsasubstanceetc`, thisBpsaSubstanceEtc, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }
}