import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { setOptions } from '@mobiscroll/angular';
import { subYears } from 'date-fns';
import { Patient } from 'src/app/_models';
import { CareLogHttpService } from 'src/app/_services';
import { AddEditBehaviorIncidentDialogComponent } from 'src/app/pages/cases/case-incidents/dialogs/add-edit-behavior-incident-dialog/add-edit-behavior-incident-dialog.component';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { formatDate } from 'src/app/shared/utilities/utilities';
import { ViewIncidentLogsComponent } from './view-incident-logs/view-incident-logs.component';

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

@Component({
  selector: 'app-behavior-incident-report',
  templateUrl: './behavior-incident-report.component.html',
  styleUrls: ['./behavior-incident-report.component.css'],
})
export class BehaviorIncidentReportComponent implements OnInit {
  @Input() patient: Patient;
  @Input() hasIncidentAddAccess: boolean;
  @Output() output = new EventEmitter<any>();

  getIncidentsProcessing: boolean = false;

  // Mobi Scroll Components
  viewType = 'year';
  calType = 'year';

  selectedDate = new Date();
  activeDate = new Date();
  currentDate = new Date();
  minimumDate = subYears(new Date(new Date().getFullYear(), 0, 1), 5);

  allIncidentLogsForTheCalType = [];
  marked = [];
  colors = [];

  colorRank = new Map([
    ['red', 0],
    ['orange', 1],
    ['#72b9f1', 2],
    ['lightgreen', 3],
    ['green', 4],
    ['#ffffff', 5],
  ]);

  severityColorMap = new Map([
    ['Severe', 'red'],
    ['Moderate', 'orange'],
  ]);

  behaviorColorIcon = [
    { id: 'green', severity: 'No Incident' },
    { id: 'orange', severity: 'Moderate Severity' },
    { id: 'red', severity: 'Severe' },
  ];

  orgConfig: any;
  hasBehaviorPredictFeature: boolean = false;

  constructor(
    public dialog: MatDialog,
    public careLogHttpService: CareLogHttpService,
    private orgConfigSandbox: OrgConfigSandbox
  ) {}

  ngOnInit(): void {
    // Get all incidents for the selected calendar year
    this.getAllIncidentsForTheSelectedYear();

    // Check organization config
    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          this.orgConfig = orgConfig;
          if (this.orgConfig && this.orgConfig?.features) {
            this.hasBehaviorPredictFeature = this.orgConfig?.features.includes(
              'PREDICTIVE_BEHAVIOR'
            );
          }
        });
      }
    });
  }

  getAllIncidentsForTheSelectedYear() {
    this.getIncidentsProcessing = true;

    this.careLogHttpService
      .getAllIncidentsForTheSelectedYear(
        this.activeDate.getFullYear().toString(),
        this.patient.id,
        this.patient.organizationId
      )
      .subscribe(
        (response) => {
          if (response) {
            if (response?.length > 0) {
              this.allIncidentLogsForTheCalType = response;
              this.handleMarkedAndColors();
            }
          }
          if (response == null) {
            this.marked = [];
            this.colors = [
              {
                recurring: {
                  repeat: 'daily',
                  until: this.currentDate,
                },

                highlight: 'green',
              },
            ];
          }
          this.getIncidentsProcessing = false;
        },
        (error) => {
          this.getIncidentsProcessing = false;
        }
      );
  }

  handleMarkedAndColors() {
    if (this.allIncidentLogsForTheCalType) {
      let markedRecords = [];
      let colorBackground = [];
      let allIncidentDays = [];
      let uniqueEntries = new Set<string>();
      let distinchBackGroundColorRank = new Map();
      let distinchBackGroundColor = new Map();

      // First calculate the calendars to mark
      this.allIncidentLogsForTheCalType.forEach((data) => {
        let record = data.behavioralIncident;

        const key = `${record.incidentDate}_${data.id}`;
        allIncidentDays.push(record.incidentDate);
        // Only allow distinct entrees for colors pop up on bottom
        if (!uniqueEntries.has(key)) {
          uniqueEntries.add(key);

          let currentColor = record?.severity
            ? this.severityColorMap.get(record.severity)
            : '#ffffff';

          // All severity colors on the bottom of the date
          markedRecords.push({
            date: new Date(record.incidentDate),
            color: record?.severity
              ? this.severityColorMap.get(record.severity)
              : '#ffffff',
          });

          // BackGround color
          if (distinchBackGroundColorRank.has(record.incidentDate)) {
            // Filter the mood override
            let oldColorRank = distinchBackGroundColorRank.get(
              record.incidentDate
            );
            let newColorRank = this.colorRank.get(currentColor);
            // If the color rank is less replace with the lower one
            if (newColorRank < oldColorRank) {
              distinchBackGroundColorRank.set(
                record.incidentDate,
                newColorRank
              );
              distinchBackGroundColor.set(record.incidentDate, currentColor);
            }
          } else {
            distinchBackGroundColorRank.set(
              record.incidentDate,
              this.colorRank.get(currentColor)
            );
            distinchBackGroundColor.set(record.incidentDate, currentColor);
          }
        }
      });

      // Highlighting all background by default green
      colorBackground.push({
        recurring: {
          repeat: 'daily',
          until: this.currentDate,
        },
        recurringException: [...allIncidentDays],
        highlight: 'green',
      });

      // Now add background to each label
      distinchBackGroundColor.forEach((value, key) => {
        colorBackground.push({
          date: new Date(key),
          highlight: value,
        });
      });

      this.colors = colorBackground;
      this.marked = markedRecords;
    }
  }

  deSelectReport() {
    this.output.emit({ eventType: 'DESELECT_REPORT' });
  }

  onPageChange(args: any): void {
    // Handle Arguments here to get the year
    this.activeDate = args.firstDay;
    this.getAllIncidentsForTheSelectedYear();
  }

  onCellClick(args: any) {
    let selectedCellDate = args.date;
    let formattedSelectedDate = formatDate(selectedCellDate);

    let filteredIncidentLogs = this.allIncidentLogsForTheCalType.filter(
      (incident) => {
        return (
          incident.behavioralIncident.incidentDate === formattedSelectedDate
        );
      }
    );

    // Only if the records exists for the given day
    if (filteredIncidentLogs && filteredIncidentLogs.length > 0) {
      this.dialog.open(ViewIncidentLogsComponent, {
        data: {
          incidents: filteredIncidentLogs,
          date: formattedSelectedDate,
          patient: this.patient,
        },
        disableClose: true,
        autoFocus: false,
        minWidth: '25vw',
      });
    }
  }

  addBehaviorIncident() {
    const dialogRef = this.dialog.open(AddEditBehaviorIncidentDialogComponent, {
      data: {
        action: 'ADD',
        Incident: null,
        patientId: this.patient.id,
        caseId: null,
        patient: this.patient,
        patientName: this.patient.firstName + ' ' + this.patient.lastName,
        organizationId: this.patient.organizationId,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '55vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.getAllIncidentsForTheSelectedYear();
      }
    });
  }
}
