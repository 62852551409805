import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { DataTablesModel, ResponseModal } from '../../../_models';
import { IApgar } from '../../../_models/assessments/relationship-family/apgar.model';
@Injectable({ providedIn: 'root' })
export class ApgarApiService {
    constructor(private httpClient: HttpClient) { }

    getApgarDetails(
        assessmentId: string
    ): Observable<ResponseModal> {
        return this.httpClient.get<ResponseModal>(
            `${environment.apiUrl}/assessments/apgar/` + assessmentId
        );
    }

    addApgar(
        thisApgar: IApgar
    ): Observable<any> {
        const headers = { 'content-type': 'application/json' };
        return this.httpClient
            .post<IApgar>(
                `${environment.apiUrl}/assessments/apgar`,
                thisApgar,
                { headers }
            )
            .pipe(
                catchError((err) => {
                    console.error(err);
                    throw err;
                })
            );
    }

    addGuestApgar(
        thisApgar: IApgar
    ): Observable<any> {
        const headers = { 'content-type': 'application/json' };
        return this.httpClient
            .post<IApgar>(
                `${environment.apiUrl}/guestassessments/apgar`,
                thisApgar,
                { headers }
            )
            .pipe(
                catchError((err) => {
                    console.error(err);
                    throw err;
                })
            );
    }

    getApgarList(
        patientId: string,
        assessmentId: string,
        pageSize: number,
        pageIndex: number
    ): Observable<DataTablesModel> {
        let params = new HttpParams();
        params = params.append('patientId', patientId);
        params = params.append('assessmentId', assessmentId);
        params = params.append('pageSize', pageSize.toString());
        params = params.append('pageIndex', pageIndex.toString());

        return this.httpClient.get<DataTablesModel>(
            `${environment.apiUrl}/assessments/apgarlist`,
            { params: params }
        );
    }
}
