<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Collect Payment</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      type="button"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="row">
    <div class="col-md-12">
      <app-collect-member-payment
        [sessionId]="sessionDetails.id"
        [parent]="'Group Note'"
        [patientId]="selectedMemberId"
        (closeDialog)="closeDialog()"
      ></app-collect-member-payment>
    </div>
  </div>
</div>
