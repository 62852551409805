import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { IAdhdDiagnosisRating } from 'src/app/_models/assessments/adhd/adhddiagnosisrating.model';
import {
  AdhdDiagnosisRatingApiService,
  ToastMessageService,
} from 'src/app/_services/';

@Component({
  selector: 'app-adhd-diagnosis-rating',
  templateUrl: './adhd-diagnosis-rating.component.html',
  styleUrls: ['./adhd-diagnosis-rating.component.css'],
})
export class AdhdDiagnosisRatingComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public adhdDiagnosisRatingApiService: AdhdDiagnosisRatingApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) {}
  public riskScore: number;
  public inattentive: boolean = false;
  public hyperactive: boolean = false;
  public defiant: boolean = false;
  public conduct: boolean = false;
  public depression: boolean = false;

  public adhdDiagnosisRatingForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  adhdDiagnosisRatingAssessment: IAdhdDiagnosisRating;
  adhdDiagnosisRatingAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.adhdDiagnosisRatingForm, this.errors);

    this.loadAdhdDiagnosisRatingAssessment();
  }

  loadAdhdDiagnosisRatingAssessment() {
    this.adhdDiagnosisRatingForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.adhdDiagnosisRatingApiService
        .getAdhdDiagnosisRatingDetails(this.assessmentId)
        .subscribe((adhdDiagnosisRatingAssessment) => {
          if (adhdDiagnosisRatingAssessment.data) {
            this.adhdDiagnosisRatingForm.controls['q1'].setValue(
              adhdDiagnosisRatingAssessment.data.q1.answer
            );
            this.adhdDiagnosisRatingForm.controls['q2'].setValue(
              adhdDiagnosisRatingAssessment.data.q2.answer
            );
            this.adhdDiagnosisRatingForm.controls['q3'].setValue(
              adhdDiagnosisRatingAssessment.data.q3.answer
            );
            this.adhdDiagnosisRatingForm.controls['q4'].setValue(
              adhdDiagnosisRatingAssessment.data.q4.answer
            );
            this.adhdDiagnosisRatingForm.controls['q5'].setValue(
              adhdDiagnosisRatingAssessment.data.q5.answer
            );
            this.adhdDiagnosisRatingForm.controls['q6'].setValue(
              adhdDiagnosisRatingAssessment.data.q6.answer
            );
            this.adhdDiagnosisRatingForm.controls['q7'].setValue(
              adhdDiagnosisRatingAssessment.data.q7.answer
            );
            this.adhdDiagnosisRatingForm.controls['q8'].setValue(
              adhdDiagnosisRatingAssessment.data.q8.answer
            );
            this.adhdDiagnosisRatingForm.controls['q9'].setValue(
              adhdDiagnosisRatingAssessment.data.q9.answer
            );
            this.adhdDiagnosisRatingForm.controls['q10'].setValue(
              adhdDiagnosisRatingAssessment.data.q10.answer
            );
            this.adhdDiagnosisRatingForm.controls['q11'].setValue(
              adhdDiagnosisRatingAssessment.data.q11.answer
            );
            this.adhdDiagnosisRatingForm.controls['q12'].setValue(
              adhdDiagnosisRatingAssessment.data.q12.answer
            );
            this.adhdDiagnosisRatingForm.controls['q13'].setValue(
              adhdDiagnosisRatingAssessment.data.q13.answer
            );
            this.adhdDiagnosisRatingForm.controls['q14'].setValue(
              adhdDiagnosisRatingAssessment.data.q14.answer
            );
            this.adhdDiagnosisRatingForm.controls['q15'].setValue(
              adhdDiagnosisRatingAssessment.data.q15.answer
            );
            this.adhdDiagnosisRatingForm.controls['q16'].setValue(
              adhdDiagnosisRatingAssessment.data.q16.answer
            );
            this.adhdDiagnosisRatingForm.controls['q17'].setValue(
              adhdDiagnosisRatingAssessment.data.q17.answer
            );
            this.adhdDiagnosisRatingForm.controls['q18'].setValue(
              adhdDiagnosisRatingAssessment.data.q18.answer
            );
            this.adhdDiagnosisRatingForm.controls['q19'].setValue(
              adhdDiagnosisRatingAssessment.data.q19.answer
            );
            this.adhdDiagnosisRatingForm.controls['q20'].setValue(
              adhdDiagnosisRatingAssessment.data.q20.answer
            );
            this.adhdDiagnosisRatingForm.controls['q21'].setValue(
              adhdDiagnosisRatingAssessment.data.q21.answer
            );
            this.adhdDiagnosisRatingForm.controls['q22'].setValue(
              adhdDiagnosisRatingAssessment.data.q22.answer
            );
            this.adhdDiagnosisRatingForm.controls['q23'].setValue(
              adhdDiagnosisRatingAssessment.data.q23.answer
            );
            this.adhdDiagnosisRatingForm.controls['q24'].setValue(
              adhdDiagnosisRatingAssessment.data.q24.answer
            );
            this.adhdDiagnosisRatingForm.controls['q25'].setValue(
              adhdDiagnosisRatingAssessment.data.q25.answer
            );
            this.adhdDiagnosisRatingForm.controls['q26'].setValue(
              adhdDiagnosisRatingAssessment.data.q26.answer
            );
            this.adhdDiagnosisRatingForm.controls['q27'].setValue(
              adhdDiagnosisRatingAssessment.data.q27.answer
            );
            this.adhdDiagnosisRatingForm.controls['q28'].setValue(
              adhdDiagnosisRatingAssessment.data.q28.answer
            );
            this.adhdDiagnosisRatingForm.controls['q29'].setValue(
              adhdDiagnosisRatingAssessment.data.q29.answer
            );
            this.adhdDiagnosisRatingForm.controls['q30'].setValue(
              adhdDiagnosisRatingAssessment.data.q30.answer
            );
            this.adhdDiagnosisRatingForm.controls['q31'].setValue(
              adhdDiagnosisRatingAssessment.data.q31.answer
            );
            this.adhdDiagnosisRatingForm.controls['q32'].setValue(
              adhdDiagnosisRatingAssessment.data.q32.answer
            );
            this.adhdDiagnosisRatingForm.controls['q33'].setValue(
              adhdDiagnosisRatingAssessment.data.q33.answer
            );
            this.adhdDiagnosisRatingForm.controls['q34'].setValue(
              adhdDiagnosisRatingAssessment.data.q34.answer
            );
            this.adhdDiagnosisRatingForm.controls['q35'].setValue(
              adhdDiagnosisRatingAssessment.data.q35.answer
            );
            this.adhdDiagnosisRatingForm.controls['q36'].setValue(
              adhdDiagnosisRatingAssessment.data.q36.answer
            );
            this.adhdDiagnosisRatingForm.controls['q37'].setValue(
              adhdDiagnosisRatingAssessment.data.q37.answer
            );
            this.adhdDiagnosisRatingForm.controls['q38'].setValue(
              adhdDiagnosisRatingAssessment.data.q38.answer
            );
            this.adhdDiagnosisRatingForm.controls['q39'].setValue(
              adhdDiagnosisRatingAssessment.data.q39.answer
            );
            this.adhdDiagnosisRatingForm.controls['q40'].setValue(
              adhdDiagnosisRatingAssessment.data.q40.answer
            );
            this.adhdDiagnosisRatingForm.controls['q41'].setValue(
              adhdDiagnosisRatingAssessment.data.q41.answer
            );
            this.adhdDiagnosisRatingForm.controls['q42'].setValue(
              adhdDiagnosisRatingAssessment.data.q42.answer
            );
            this.adhdDiagnosisRatingForm.controls['q43'].setValue(
              adhdDiagnosisRatingAssessment.data.q43.answer
            );
            this.adhdDiagnosisRatingForm.controls['q44'].setValue(
              adhdDiagnosisRatingAssessment.data.q44.answer
            );
            this.adhdDiagnosisRatingForm.controls['q45'].setValue(
              adhdDiagnosisRatingAssessment.data.q45.answer
            );
            this.adhdDiagnosisRatingForm.controls['q46'].setValue(
              adhdDiagnosisRatingAssessment.data.q46.answer
            );
            this.adhdDiagnosisRatingForm.controls['q47'].setValue(
              adhdDiagnosisRatingAssessment.data.q47.answer
            );
            this.adhdDiagnosisRatingForm.controls['q48'].setValue(
              adhdDiagnosisRatingAssessment.data.q48.answer
            );
            this.adhdDiagnosisRatingForm.controls['q49'].setValue(
              adhdDiagnosisRatingAssessment.data.q49.answer
            );
            this.adhdDiagnosisRatingForm.controls['q50'].setValue(
              adhdDiagnosisRatingAssessment.data.q50.answer
            );
            this.adhdDiagnosisRatingForm.controls['q51'].setValue(
              adhdDiagnosisRatingAssessment.data.q51.answer
            );
            this.adhdDiagnosisRatingForm.controls['q52'].setValue(
              adhdDiagnosisRatingAssessment.data.q52.answer
            );
            this.adhdDiagnosisRatingForm.controls['q53'].setValue(
              adhdDiagnosisRatingAssessment.data.q53.answer
            );
            this.adhdDiagnosisRatingForm.controls['q54'].setValue(
              adhdDiagnosisRatingAssessment.data.q54.answer
            );
            this.adhdDiagnosisRatingForm.controls['q55'].setValue(
              adhdDiagnosisRatingAssessment.data.q55.answer
            );

            this.riskScore = adhdDiagnosisRatingAssessment.data.riskScore;
            this.inattentive =
              adhdDiagnosisRatingAssessment.data.props['inattentive'] >= 6 &&
              adhdDiagnosisRatingAssessment.data.props['performance'] > 0
                ? true
                : false;
            this.hyperactive =
              adhdDiagnosisRatingAssessment.data.props['hyperactive'] >= 6 &&
              adhdDiagnosisRatingAssessment.data.props['performance'] > 0
                ? true
                : false;
            this.defiant =
              adhdDiagnosisRatingAssessment.data.props['defiant'] >= 4 &&
              adhdDiagnosisRatingAssessment.data.props['performance'] > 0
                ? true
                : false;
            this.conduct =
              adhdDiagnosisRatingAssessment.data.props['conduct'] >= 3 &&
              adhdDiagnosisRatingAssessment.data.props['performance'] > 0
                ? true
                : false;
            this.depression =
              adhdDiagnosisRatingAssessment.data.props['depression'] >= 3 &&
              adhdDiagnosisRatingAssessment.data.props['performance'] > 0
                ? true
                : false;
          }
        });
    }
  }

  onFormSubmit() {
    if (this.adhdDiagnosisRatingForm.invalid) {
      this.adhdDiagnosisRatingForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.adhdDiagnosisRatingAssessment = Object.assign(
      {},
      this.adhdDiagnosisRatingAssessment
    );
    this.adhdDiagnosisRatingAssessment.patientId =
      this.adhdDiagnosisRatingForm.controls['patientId'].value;
    this.adhdDiagnosisRatingAssessment.sessionId =
      this.adhdDiagnosisRatingForm.controls['sessionId'].value;
    this.adhdDiagnosisRatingAssessment.assessmentRequestId =
      this.adhdDiagnosisRatingForm.controls['assessmentRequestId'].value;
    this.adhdDiagnosisRatingAssessment.q1 = {
      answer: this.adhdDiagnosisRatingForm.controls['q1'].value,
    };
    this.adhdDiagnosisRatingAssessment.q2 = {
      answer: this.adhdDiagnosisRatingForm.controls['q2'].value,
    };
    this.adhdDiagnosisRatingAssessment.q3 = {
      answer: this.adhdDiagnosisRatingForm.controls['q3'].value,
    };
    this.adhdDiagnosisRatingAssessment.q4 = {
      answer: this.adhdDiagnosisRatingForm.controls['q4'].value,
    };
    this.adhdDiagnosisRatingAssessment.q5 = {
      answer: this.adhdDiagnosisRatingForm.controls['q5'].value,
    };
    this.adhdDiagnosisRatingAssessment.q6 = {
      answer: this.adhdDiagnosisRatingForm.controls['q6'].value,
    };
    this.adhdDiagnosisRatingAssessment.q7 = {
      answer: this.adhdDiagnosisRatingForm.controls['q7'].value,
    };
    this.adhdDiagnosisRatingAssessment.q8 = {
      answer: this.adhdDiagnosisRatingForm.controls['q8'].value,
    };
    this.adhdDiagnosisRatingAssessment.q9 = {
      answer: this.adhdDiagnosisRatingForm.controls['q9'].value,
    };
    this.adhdDiagnosisRatingAssessment.q10 = {
      answer: this.adhdDiagnosisRatingForm.controls['q10'].value,
    };
    this.adhdDiagnosisRatingAssessment.q11 = {
      answer: this.adhdDiagnosisRatingForm.controls['q11'].value,
    };
    this.adhdDiagnosisRatingAssessment.q12 = {
      answer: this.adhdDiagnosisRatingForm.controls['q12'].value,
    };
    this.adhdDiagnosisRatingAssessment.q13 = {
      answer: this.adhdDiagnosisRatingForm.controls['q13'].value,
    };
    this.adhdDiagnosisRatingAssessment.q14 = {
      answer: this.adhdDiagnosisRatingForm.controls['q14'].value,
    };
    this.adhdDiagnosisRatingAssessment.q15 = {
      answer: this.adhdDiagnosisRatingForm.controls['q15'].value,
    };
    this.adhdDiagnosisRatingAssessment.q16 = {
      answer: this.adhdDiagnosisRatingForm.controls['q16'].value,
    };
    this.adhdDiagnosisRatingAssessment.q17 = {
      answer: this.adhdDiagnosisRatingForm.controls['q17'].value,
    };
    this.adhdDiagnosisRatingAssessment.q18 = {
      answer: this.adhdDiagnosisRatingForm.controls['q18'].value,
    };
    this.adhdDiagnosisRatingAssessment.q19 = {
      answer: this.adhdDiagnosisRatingForm.controls['q19'].value,
    };
    this.adhdDiagnosisRatingAssessment.q20 = {
      answer: this.adhdDiagnosisRatingForm.controls['q20'].value,
    };
    this.adhdDiagnosisRatingAssessment.q21 = {
      answer: this.adhdDiagnosisRatingForm.controls['q21'].value,
    };
    this.adhdDiagnosisRatingAssessment.q22 = {
      answer: this.adhdDiagnosisRatingForm.controls['q22'].value,
    };
    this.adhdDiagnosisRatingAssessment.q23 = {
      answer: this.adhdDiagnosisRatingForm.controls['q23'].value,
    };
    this.adhdDiagnosisRatingAssessment.q24 = {
      answer: this.adhdDiagnosisRatingForm.controls['q24'].value,
    };
    this.adhdDiagnosisRatingAssessment.q25 = {
      answer: this.adhdDiagnosisRatingForm.controls['q25'].value,
    };
    this.adhdDiagnosisRatingAssessment.q26 = {
      answer: this.adhdDiagnosisRatingForm.controls['q26'].value,
    };
    this.adhdDiagnosisRatingAssessment.q27 = {
      answer: this.adhdDiagnosisRatingForm.controls['q27'].value,
    };
    this.adhdDiagnosisRatingAssessment.q28 = {
      answer: this.adhdDiagnosisRatingForm.controls['q28'].value,
    };
    this.adhdDiagnosisRatingAssessment.q29 = {
      answer: this.adhdDiagnosisRatingForm.controls['q29'].value,
    };
    this.adhdDiagnosisRatingAssessment.q30 = {
      answer: this.adhdDiagnosisRatingForm.controls['q30'].value,
    };
    this.adhdDiagnosisRatingAssessment.q31 = {
      answer: this.adhdDiagnosisRatingForm.controls['q31'].value,
    };
    this.adhdDiagnosisRatingAssessment.q32 = {
      answer: this.adhdDiagnosisRatingForm.controls['q32'].value,
    };
    this.adhdDiagnosisRatingAssessment.q33 = {
      answer: this.adhdDiagnosisRatingForm.controls['q33'].value,
    };
    this.adhdDiagnosisRatingAssessment.q34 = {
      answer: this.adhdDiagnosisRatingForm.controls['q34'].value,
    };
    this.adhdDiagnosisRatingAssessment.q35 = {
      answer: this.adhdDiagnosisRatingForm.controls['q35'].value,
    };
    this.adhdDiagnosisRatingAssessment.q36 = {
      answer: this.adhdDiagnosisRatingForm.controls['q36'].value,
    };
    this.adhdDiagnosisRatingAssessment.q37 = {
      answer: this.adhdDiagnosisRatingForm.controls['q37'].value,
    };
    this.adhdDiagnosisRatingAssessment.q38 = {
      answer: this.adhdDiagnosisRatingForm.controls['q38'].value,
    };
    this.adhdDiagnosisRatingAssessment.q39 = {
      answer: this.adhdDiagnosisRatingForm.controls['q39'].value,
    };
    this.adhdDiagnosisRatingAssessment.q40 = {
      answer: this.adhdDiagnosisRatingForm.controls['q40'].value,
    };
    this.adhdDiagnosisRatingAssessment.q41 = {
      answer: this.adhdDiagnosisRatingForm.controls['q41'].value,
    };
    this.adhdDiagnosisRatingAssessment.q42 = {
      answer: this.adhdDiagnosisRatingForm.controls['q42'].value,
    };
    this.adhdDiagnosisRatingAssessment.q43 = {
      answer: this.adhdDiagnosisRatingForm.controls['q43'].value,
    };
    this.adhdDiagnosisRatingAssessment.q44 = {
      answer: this.adhdDiagnosisRatingForm.controls['q44'].value,
    };
    this.adhdDiagnosisRatingAssessment.q45 = {
      answer: this.adhdDiagnosisRatingForm.controls['q45'].value,
    };
    this.adhdDiagnosisRatingAssessment.q46 = {
      answer: this.adhdDiagnosisRatingForm.controls['q46'].value,
    };
    this.adhdDiagnosisRatingAssessment.q47 = {
      answer: this.adhdDiagnosisRatingForm.controls['q47'].value,
    };
    this.adhdDiagnosisRatingAssessment.q48 = {
      answer: this.adhdDiagnosisRatingForm.controls['q48'].value,
    };
    this.adhdDiagnosisRatingAssessment.q49 = {
      answer: this.adhdDiagnosisRatingForm.controls['q49'].value,
    };
    this.adhdDiagnosisRatingAssessment.q50 = {
      answer: this.adhdDiagnosisRatingForm.controls['q50'].value,
    };
    this.adhdDiagnosisRatingAssessment.q51 = {
      answer: this.adhdDiagnosisRatingForm.controls['q51'].value,
    };
    this.adhdDiagnosisRatingAssessment.q52 = {
      answer: this.adhdDiagnosisRatingForm.controls['q52'].value,
    };
    this.adhdDiagnosisRatingAssessment.q53 = {
      answer: this.adhdDiagnosisRatingForm.controls['q53'].value,
    };
    this.adhdDiagnosisRatingAssessment.q54 = {
      answer: this.adhdDiagnosisRatingForm.controls['q54'].value,
    };
    this.adhdDiagnosisRatingAssessment.q55 = {
      answer: this.adhdDiagnosisRatingForm.controls['q55'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.adhdDiagnosisRatingAssessment.accessCode = this.accessCode;

      this.adhdDiagnosisRatingApiService
        .addGuestAdhdDiagnosisRating(this.adhdDiagnosisRatingAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted ADHD Diagnosis Rating'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting ADHD Diagnosis Rating'
            );
          }
        );
    } else {
      this.adhdDiagnosisRatingApiService
        .addAdhdDiagnosisRating(this.adhdDiagnosisRatingAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted ADHD Diagnosis Rating'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting ADHD Diagnosis Rating'
            );
          }
        );
    }
  }

  public validation_messages = {
    q1: [
      {
        type: 'required',
        message:
          '1. Does not pay attention to details or makes careless mistakes with, for example, homework is required',
      },
    ],
    q2: [
      {
        type: 'required',
        message:
          '2. Has difficulty keeping attention to what needs to be done is required',
      },
    ],
    q3: [
      {
        type: 'required',
        message:
          '3. Does not seem to listen when spoken to directly is required',
      },
    ],
    q4: [
      {
        type: 'required',
        message:
          '4. Does not follow through when given directions and fails to finish activities (not due to refusal or failure to understand) is required',
      },
    ],
    q5: [
      {
        type: 'required',
        message:
          '5. Has difficulty organizing tasks and activities is required',
      },
    ],
    q6: [
      {
        type: 'required',
        message:
          '6. Avoids, dislikes, or does not want to start tasks that require ongoing mental effort is required',
      },
    ],
    q7: [
      {
        type: 'required',
        message:
          '7. Loses things necessary for tasks or activities (toys, assignments, pencils, or books) is required',
      },
    ],
    q8: [
      {
        type: 'required',
        message:
          '8. Is easily distracted by noises or other stimuli is required',
      },
    ],
    q9: [
      {
        type: 'required',
        message: '9. Is forgetful in daily activities is required',
      },
    ],
    q10: [
      {
        type: 'required',
        message:
          '10. Fidgets with hands or feet or squirms in seat is required',
      },
    ],
    q11: [
      {
        type: 'required',
        message:
          '11. Leaves seat when remaining seated is expected is required',
      },
    ],
    q12: [
      {
        type: 'required',
        message:
          '12. Runs about or climbs too much when remaining seated is expected is required',
      },
    ],
    q13: [
      {
        type: 'required',
        message:
          '13. Has difficulty playing or beginning quiet play activities is required',
      },
    ],
    q14: [
      {
        type: 'required',
        message:
          '14. Is “on the go” or often acts as if “driven by a motor” is required',
      },
    ],
    q15: [{ type: 'required', message: '15. Talks too much is required' }],
    q16: [
      {
        type: 'required',
        message:
          '16. Blurts out answers before questions have been completed is required',
      },
    ],
    q17: [
      {
        type: 'required',
        message: '17. Has difficulty waiting his or her turn is required',
      },
    ],
    q18: [
      {
        type: 'required',
        message:
          '18. Interrupts or intrudes in on others’ conversations and/or activities is required',
      },
    ],
    q19: [{ type: 'required', message: '19. Argues with adults is required' }],
    q20: [{ type: 'required', message: '20. Loses temper is required' }],
    q21: [
      {
        type: 'required',
        message:
          '21. Actively defies or refuses to go along with adults’ requests or rules is required',
      },
    ],
    q22: [
      {
        type: 'required',
        message: '22. Deliberately annoys people is required',
      },
    ],
    q23: [
      {
        type: 'required',
        message:
          '23. Blames others for his or her mistakes or misbehaviors is required',
      },
    ],
    q24: [
      {
        type: 'required',
        message: '24. Is touchy or easily annoyed by others is required',
      },
    ],
    q25: [
      { type: 'required', message: '25. Is angry or resentful is required' },
    ],
    q26: [
      {
        type: 'required',
        message: '26. Is spiteful and wants to get even is required',
      },
    ],
    q27: [
      {
        type: 'required',
        message: '27. Bullies, threatens, or intimidates others is required',
      },
    ],
    q28: [
      { type: 'required', message: '28. Starts physical fights is required' },
    ],
    q29: [
      {
        type: 'required',
        message:
          '29. Lies to get out of trouble or to avoid obligations (i.e.,“cons” others) is required',
      },
    ],
    q30: [
      {
        type: 'required',
        message:
          '30. Is truant from school (skips school) without permission is required',
      },
    ],
    q31: [
      {
        type: 'required',
        message: '31. Is physically cruel to people is required',
      },
    ],
    q32: [
      {
        type: 'required',
        message: '32. Has stolen things that have value is required',
      },
    ],
    q33: [
      {
        type: 'required',
        message: '33. Deliberately destroys others’ property is required',
      },
    ],
    q34: [
      {
        type: 'required',
        message:
          '34. Has used a weapon that can cause serious harm (bat, knife, brick, gun) is required',
      },
    ],
    q35: [
      {
        type: 'required',
        message: '35. Is physically cruel to animals is required',
      },
    ],
    q36: [
      {
        type: 'required',
        message: '36. Has deliberately set fires to cause damage is required',
      },
    ],
    q37: [
      {
        type: 'required',
        message:
          '37. Has broken into someone else’s home, business, or car is required',
      },
    ],
    q38: [
      {
        type: 'required',
        message: '38. Has stayed out at night without permission is required',
      },
    ],
    q39: [
      {
        type: 'required',
        message: '39. Has run away from home overnight is required',
      },
    ],
    q40: [
      {
        type: 'required',
        message: '40. Has forced someone into sexual activity is required',
      },
    ],
    q41: [
      {
        type: 'required',
        message: '41. Is fearful, anxious, or worried is required',
      },
    ],
    q42: [
      {
        type: 'required',
        message:
          '42. Is afraid to try new things for fear of making mistakes is required',
      },
    ],
    q43: [
      {
        type: 'required',
        message: '43. Feels worthless or inferior is required',
      },
    ],
    q44: [
      {
        type: 'required',
        message: '44. Blames self for problems, feels guilty is required',
      },
    ],
    q45: [
      {
        type: 'required',
        message:
          '45. Feels lonely, unwanted, or unloved; complains that “no one loves him or her” is required',
      },
    ],
    q46: [
      {
        type: 'required',
        message: '46. Is sad, unhappy, or depressed is required',
      },
    ],
    q47: [
      {
        type: 'required',
        message: '47. Is self-conscious or easily embarrassed is required',
      },
    ],
    q48: [
      {
        type: 'required',
        message: '48. Overall school performance is required',
      },
    ],
    q49: [{ type: 'required', message: '49. Reading is required' }],
    q50: [{ type: 'required', message: '50. Writing is required' }],
    q51: [{ type: 'required', message: '51. Mathematics is required' }],
    q52: [
      {
        type: 'required',
        message: '52. Relationship with parents is required',
      },
    ],
    q53: [
      {
        type: 'required',
        message: '53. Relationship with siblings is required',
      },
    ],
    q54: [
      { type: 'required', message: '54. Relationship with peers is required' },
    ],
    q55: [
      {
        type: 'required',
        message: '55. Participation in organized activities is required',
      },
    ],
  };

  private buildForm() {
    this.adhdDiagnosisRatingForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      q1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q2: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q3: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q4: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q5: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q6: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q7: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q8: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q9: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q10: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q11: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q12: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q13: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q14: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q15: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q16: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q17: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q18: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q19: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q20: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q21: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q22: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q23: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q24: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q25: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q26: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q27: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q28: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q29: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q30: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q31: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q32: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q33: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q34: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q35: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q36: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q37: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q38: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q39: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q40: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q41: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q42: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q43: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q44: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q45: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q46: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q47: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q48: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q49: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q50: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q51: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q52: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q53: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q54: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q55: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.adhdDiagnosisRatingForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.adhdDiagnosisRatingForm) {
      return;
    }
    const form = this.adhdDiagnosisRatingForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
