import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Case } from 'src/app/_models';
import { DocumentHttpService, ToastMessageService } from 'src/app/_services';

@Component({
  selector: 'app-print-seizure-report',
  templateUrl: './print-seizure-report.component.html',
  styleUrls: ['./print-seizure-report.component.css'],
})
export class PrintSeizureReportComponent implements OnInit {
  @Input() case: Case;
  @ViewChild('pdfViewerOnDemand', { static: false }) pdfViewerOnDemand;

  displayNoRecordsMsg = false;
  processing: boolean = false;
  pdfSrc = null;
  public searchForm: FormGroup;
  years: number[] = [];
  months: any[] = [
    { value: '01', description: 'January' },
    {
      value: '02',
      description: 'February',
    },
    {
      value: '03',
      description: 'March',
    },
    {
      value: '04',
      description: 'April',
    },
    {
      value: '05',
      description: 'May',
    },
    { value: '06', description: 'June' },
    { value: '07', description: 'July' },
    { value: '08', description: 'August' },
    { value: '09', description: 'September' },
    { value: '10', description: 'October' },
    { value: '11', description: 'November' },
    { value: '12', description: 'December' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    public documentApiService: DocumentHttpService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit(): void {
    this.buildForm();
    for (
      let currentYear = new Date().getFullYear();
      currentYear >= 2021;
      currentYear--
    ) {
      this.years.push(currentYear);
    }
  }

  private buildForm() {
    this.searchForm = this.formBuilder.group({
      month: new FormControl('', Validators.compose([Validators.required])),
      year: new FormControl('', Validators.compose([Validators.required])),
    });
    this.searchForm.valueChanges.subscribe(() => this.onFormSubmit());
  }

  onFormSubmit() {
    this.displayNoRecordsMsg = false;
    if (this.searchForm.invalid) {
      this.searchForm.markAllAsTouched();
      return;
    }
    let searchDetails = {};
    searchDetails = Object.assign(searchDetails, this.searchForm.value);

    this.processing = true;
    this.documentApiService
      .seizureReport(
        this.case.id,
        this.searchForm.controls['month'].value,
        this.searchForm.controls['year'].value
      )
      .subscribe(
        (res) => {
          this.processing = false;
          if (res) {
            this.pdfViewerOnDemand.pdfSrc = res;
            this.pdfViewerOnDemand.refresh();
            this.displayNoRecordsMsg = false;
          } else {
            this.pdfViewerOnDemand.pdfSrc = null;
            this.pdfViewerOnDemand.refresh();
            this.displayNoRecordsMsg = true;
          }
        },
        (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Something went wrong'
          );
        }
      );
  }
}
