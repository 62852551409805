import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Patient } from 'src/app/_models';
import { PatientService, ToastMessageService } from 'src/app/_services';
import { GuestIntakeDemographicsComponent } from 'src/app/pages/guest/guest-intake-demographics/guest-intake-demographics.component';
import { GuestIntakeMedicalHistoryComponent } from 'src/app/pages/guest/guest-intake-medical-history/guest-intake-medical-history.component';
import { GuestIntakeReleaseOfInformationComponent } from 'src/app/pages/guest/guest-intake-release-of-information/guest-intake-release-of-information.component';
import { AddEditMemberInsuranceComponent } from 'src/app/pages/patient-details/patient-components/member-tab/member-tab-sections/member-insurance/add-edit-member-insurance/add-edit-member-insurance.component';

@Component({
  selector: 'app-member-to-do-list-update-interface',
  templateUrl: './member-to-do-list-update-interface.component.html',
  styleUrl: './member-to-do-list-update-interface.component.css',
})
export class MemberToDoListUpdateInterfaceComponent implements OnInit {
  row: any;
  patient: Patient = null;

  isLoadingDetails: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MemberToDoListUpdateInterfaceComponent>,
    private dialog: MatDialog,
    private patientApiService: PatientService,
    private toastMessageService: ToastMessageService
  ) {
    this.row = data.row;
  }

  async ngOnInit(): Promise<void> {
    this.getMyDetails();
  }

  getMyDetails() {
    this.isLoadingDetails = true;

    this.patientApiService.getMyDetails().subscribe({
      next: (response) => {
        this.patient = response.data;
        this.isLoadingDetails = false;

        if (this.row.documentName === 'Demographics Form') {
          this.addDemographicInformation();
        } else if (this.row.documentName === 'Insurance Information') {
          this.addInsurance();
        } else if (this.row.documentName === 'Member Medical History') {
          this.addIntakeMedicalHistory();
        } else if (
          this.row.documentName === 'Authorization To Release Medical Records'
        ) {
          this.addReleaseOfInformation();
        }
      },
      error: (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to load details'
        );
        this.isLoadingDetails = false;
      },
    });
  }

  addReleaseOfInformation() {
    const addDemographicDialog = this.dialog.open(
      GuestIntakeReleaseOfInformationComponent,
      {
        data: {
          action: 'ADD',
          patient: this.patient,
          consentRequestId: this.row.id,
        },
        autoFocus: false,
        disableClose: true,
        minWidth: '40vw',
      }
    );
    addDemographicDialog.afterClosed().subscribe((response) => {
      if (response && response === 'success') {
        this.dialogRef.close('success');
      } else {
        this.dialogRef.close();
      }
    });
  }

  addDemographicInformation() {
    const addDemographicDialog = this.dialog.open(
      GuestIntakeDemographicsComponent,
      {
        data: {
          action: 'ADD',
          patient: this.patient,
          consentRequestId: this.row.id,
        },
        autoFocus: false,
        disableClose: true,
        minWidth: '40vw',
      }
    );
    addDemographicDialog.afterClosed().subscribe((response) => {
      if (response && response === 'success') {
        this.dialogRef.close('success');
      } else {
        this.dialogRef.close();
      }
    });
  }

  addInsurance() {
    const addInsuranceDialog = this.dialog.open(
      AddEditMemberInsuranceComponent,
      {
        data: {
          action: 'ADD',
          patient: this.patient,
          consentRequestId: this.row.id,
        },
        autoFocus: false,
        disableClose: true,
        minWidth: '40vw',
      }
    );
    addInsuranceDialog.afterClosed().subscribe((response) => {
      if (response && response === 'success') {
        this.dialogRef.close('success');
      } else {
        this.dialogRef.close();
      }
    });
  }

  addIntakeMedicalHistory() {
    const dialogRef = this.dialog.open(GuestIntakeMedicalHistoryComponent, {
      data: {
        consentRequestId: this.row.id,
        documentName: this.row.documentName,
        patient: this.patient,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '45vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.dialogRef.close('success');
      } else {
        this.dialogRef.close();
      }
    });
  }

  triggerEvent(event) {
    this.dialogRef.close('success');
  }
}
