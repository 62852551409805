import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IReoccurringAssessmentRequest, Patient } from 'src/app/_models';
import { ToastMessageService } from 'src/app/_services';
import { AssessmentRequestApiService } from 'src/app/_services/assessments/assessmentrequest.service';
import { assessmentGroups } from 'src/app/shared/utilities/assessment-utils';
@Component({
  selector: 'app-reoccurring-assessment',
  templateUrl: './reoccurring-assessment.component.html',
  styleUrls: ['./reoccurring-assessment.component.css'],
})
export class ReoccurringAssessmentComponent implements OnInit {
  patient: Patient;

  typeList = [];
  frequencyList = ['Daily', 'Weekly', 'Bi-Weekly', 'Monthly'];
  reoccurringAssessmentForm: FormGroup;

  startDate: Date;
  endDate: Date;

  selectedCategory: string = '';
  selectedType: string = '';
  selectedFrequency: string = '';

  processing: boolean = false;
  todaysDate = new Date();
  reoccurringAssessment: IReoccurringAssessmentRequest;

  assessmentGroups: any[] = assessmentGroups();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ReoccurringAssessmentComponent>,
    private assessmentRequestApiService: AssessmentRequestApiService,
    private toastMessageService: ToastMessageService
  ) {
    this.patient = data.patient;
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.reoccurringAssessmentForm = new FormGroup({
      id: new FormControl(''),
      organizationId: new FormControl(''),
      startDate: new FormControl(null, Validators.required),
      endDate: new FormControl(null, Validators.required),
      patientId: new FormControl(this.patient.id, Validators.required),
      assessmentCategory: new FormControl('', Validators.required),
      assessmentType: new FormControl('', Validators.required),
      frequency: new FormControl('', Validators.required),
      inSessionOnly: new FormControl(false),
    });
  }

  handleCategoryChanged() {
    this.selectedCategory =
      this.reoccurringAssessmentForm.controls['assessmentCategory'].value;
    // Reset Type and Frequency
    this.reoccurringAssessmentForm.controls['assessmentType'].reset();
    this.selectedType = '';
    this.reoccurringAssessmentForm.controls['frequency'].reset();

    this.typeList = this.getAssessmentTypes(this.selectedCategory);
  }

  getAssessmentTypes(selectedCategory: string) {
    let category = this.assessmentGroups.filter(
      (category) => category.name === selectedCategory
    );
    return category[0].assessment;
  }

  handleTypeChanged() {
    this.selectedType =
      this.reoccurringAssessmentForm.controls['assessmentType'].value;
    // Reset frequency
    this.reoccurringAssessmentForm.controls['frequency'].reset();

    this.typeList.forEach((assessment) => {
      if (assessment.name == this.selectedType) {
        this.reoccurringAssessmentForm.controls['inSessionOnly'].setValue(
          assessment.inSessionOnly
        );
      }
    });
  }

  onSubmit() {
    if (this.reoccurringAssessmentForm.invalid) {
      return;
    }

    this.processing = true;
    this.reoccurringAssessment = Object.assign({}, this.reoccurringAssessment);
    this.reoccurringAssessment = Object.assign(
      this.reoccurringAssessment,
      this.reoccurringAssessmentForm.value
    );

    // Calling in api now
    this.assessmentRequestApiService
      .addReoccurringAssessment(this.reoccurringAssessment)
      .subscribe(
        (response) => {
          this.processing = false;
          this.dialogRef.close('success');
        },
        (error) => {
          this.processing = false;
          if (error.text === 'Duplicate') {
            this.toastMessageService.displayErrorMessage(
              'Error: Recurring assessment for type ' +
                this.reoccurringAssessment.assessmentType +
                ' already exist.'
            );
          } else {
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to add recurring assessment.'
            );
          }
        }
      );
  }
}
