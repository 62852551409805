import { createAction, props } from '@ngrx/store';
import { IUserSettingsProps } from 'src/app/_models';

export const getUserSettingsProps = createAction(
  '[Get User Settings Props] Get User Settings Props'
);

export const getUserSettingsSuccessAction = createAction(
  '[Get User Settings Props Success] Get User Settings Props Success',
  props<{ payload: IUserSettingsProps[] }>()
);

export const getUserSettingsFailedAction = createAction(
  '[Get User Settings Props Failed] Get User Settings Props Failed',
  props<{ payload: any }>()
);

export const handleUserSettingsPropsChange = createAction(
  '[UserSettingsProps] Handle Change',
  props<{ category: string; value: string }>()
);

export const handleUserSettingsPropsChangeSuccess = createAction(
  '[UserSettingsProps] Handle Change'
);

export const handleUserSettingsPropsChangeFailed = createAction(
  '[UserSettingsProps] Handle Change'
);
