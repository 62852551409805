import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DataTablesModel } from '../../_models';
@Injectable({ providedIn: 'root' })
export class AssessmentHistoryApiService {
  constructor(private httpClient: HttpClient) {}

  getAssessmentHistoryList(
    patientId: string,
    sessionId: string,
    status: string,
    pageSize: number,
    pageIndex: number,
    filter: string,
    sort: string,
    name: string
  ): Observable<DataTablesModel> {
    let params = new HttpParams();

    // Filter, sort, search option
    if (sessionId) params = params.append('sessionId', sessionId);
    if (filter) params = params.append('assessmentType', filter);
    if (sort) params = params.append('order', sort);
    if (name) params = params.append('memberName', name);
    if (status) params = params.append('status', status);

    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    if (patientId) {
      params = params.append('patientId', patientId);
      return this.httpClient.get<DataTablesModel>(
        `${environment.apiUrl}/assessments/list`,
        { params: params }
      );
    } else {
      if (status === 'Pending') {
        return this.httpClient.get<DataTablesModel>(
          `${environment.apiUrl}/my-assessmentrequests`,
          { params: params }
        );
      } else {
        return this.httpClient.get<DataTablesModel>(
          `${environment.apiUrl}/my-assessments`,
          { params: params }
        );
      }
    }
  }

  updateAssessmentStatus(assessmentHistoryId: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/assessment/` + assessmentHistoryId + `/complete`,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateAssessmentGoals(id: string, newGoalsList: any) {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/updatepatientassessmentgoals/` + id,
        newGoalsList,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  // Cancel Appointment
  deletePendingAssessment(
    assessmentId: string,
    patientId: string,
    organizationId: string
  ) {
    let params = new HttpParams();
    params = params.append('assessmentId', assessmentId);
    params = params.append('patientId', patientId);
    params = params.append('organizationId', organizationId);
    return this.httpClient.delete<void>(
      `${environment.apiUrl}/cancelpendingassessment`,
      { params: params }
    );
  }
}
