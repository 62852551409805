import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { IInfantToddler } from 'src/app/_models/assessments/autism/infanttoddler.model';
import {
  InfantToddlerApiService,
  ToastMessageService
} from 'src/app/_services/';

@Component({
  selector: 'app-infant-toddler',
  templateUrl: './infant-toddler.component.html',
  styleUrls: ['./infant-toddler.component.css'],
})
export class InfantToddlerComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public infantToddlerApiService: InfantToddlerApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public communication: string;
  public speech: string;
  public symbolic: string;
  public infantToddlerForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  infantToddlerAssessment: IInfantToddler;
  infantToddlerAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.infantToddlerForm, this.errors);

    this.loadInfantToddlerAssessment();
  }

  loadInfantToddlerAssessment() {
    this.infantToddlerForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.infantToddlerApiService
        .getInfantToddlerDetails(this.assessmentId)
        .subscribe((infantToddlerAssessment) => {
          if (infantToddlerAssessment.data) {
            this.infantToddlerForm.controls['age'].setValue(
              infantToddlerAssessment.data.age.answer
            );
            this.infantToddlerForm.controls['q1'].setValue(
              infantToddlerAssessment.data.q1.answer
            );
            this.infantToddlerForm.controls['q2'].setValue(
              infantToddlerAssessment.data.q2.answer
            );
            this.infantToddlerForm.controls['q3'].setValue(
              infantToddlerAssessment.data.q3.answer
            );
            this.infantToddlerForm.controls['q4'].setValue(
              infantToddlerAssessment.data.q4.answer
            );
            this.infantToddlerForm.controls['q5'].setValue(
              infantToddlerAssessment.data.q5.answer
            );
            this.infantToddlerForm.controls['q6'].setValue(
              infantToddlerAssessment.data.q6.answer
            );
            this.infantToddlerForm.controls['q7'].setValue(
              infantToddlerAssessment.data.q7.answer
            );
            this.infantToddlerForm.controls['q8'].setValue(
              infantToddlerAssessment.data.q8.answer
            );
            this.infantToddlerForm.controls['q9'].setValue(
              infantToddlerAssessment.data.q9.answer
            );
            this.infantToddlerForm.controls['q10'].setValue(
              infantToddlerAssessment.data.q10.answer
            );
            this.infantToddlerForm.controls['q11'].setValue(
              infantToddlerAssessment.data.q11.answer
            );
            this.infantToddlerForm.controls['q12'].setValue(
              infantToddlerAssessment.data.q12.answer
            );
            this.infantToddlerForm.controls['q13'].setValue(
              infantToddlerAssessment.data.q13.answer
            );
            this.infantToddlerForm.controls['q14'].setValue(
              infantToddlerAssessment.data.q14.answer
            );
            this.infantToddlerForm.controls['q15'].setValue(
              infantToddlerAssessment.data.q15.answer
            );
            this.infantToddlerForm.controls['q16'].setValue(
              infantToddlerAssessment.data.q16.answer
            );
            this.infantToddlerForm.controls['q17'].setValue(
              infantToddlerAssessment.data.q17.answer
            );
            this.infantToddlerForm.controls['q18'].setValue(
              infantToddlerAssessment.data.q18.answer
            );
            this.infantToddlerForm.controls['q19'].setValue(
              infantToddlerAssessment.data.q19.answer
            );
            this.infantToddlerForm.controls['q20'].setValue(
              infantToddlerAssessment.data.q20.answer
            );
            this.infantToddlerForm.controls['q21'].setValue(
              infantToddlerAssessment.data.q21.answer
            );
            this.infantToddlerForm.controls['q22'].setValue(
              infantToddlerAssessment.data.q22.answer
            );
            this.infantToddlerForm.controls['q23'].setValue(
              infantToddlerAssessment.data.q23.answer
            );
            this.infantToddlerForm.controls['q24'].setValue(
              infantToddlerAssessment.data.q24.answer
            );
            this.infantToddlerForm.controls['age'].setValue(
              infantToddlerAssessment.data.age.toString()
            );

            this.riskScore = infantToddlerAssessment.data.riskScore;
            this.communication =
              infantToddlerAssessment.data.props['communication'];
            this.speech = infantToddlerAssessment.data.props['speech'];
            this.symbolic = infantToddlerAssessment.data.props['symbolic'];
          }
        });
    }
  }

  onFormSubmit() {
    if (this.infantToddlerForm.invalid) {
      this.infantToddlerForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.infantToddlerAssessment = Object.assign(
      {},
      this.infantToddlerAssessment
    );
    this.infantToddlerAssessment.patientId =
      this.infantToddlerForm.controls['patientId'].value;
    this.infantToddlerAssessment.sessionId =
      this.infantToddlerForm.controls['sessionId'].value;
    this.infantToddlerAssessment.assessmentRequestId =
      this.infantToddlerForm.controls['assessmentRequestId'].value;
    this.infantToddlerAssessment.age = {
      answer: this.infantToddlerForm.controls['age'].value,
    };
    this.infantToddlerAssessment.q1 = {
      answer: this.infantToddlerForm.controls['q1'].value,
    };
    this.infantToddlerAssessment.q2 = {
      answer: this.infantToddlerForm.controls['q2'].value,
    };
    this.infantToddlerAssessment.q3 = {
      answer: this.infantToddlerForm.controls['q3'].value,
    };
    this.infantToddlerAssessment.q4 = {
      answer: this.infantToddlerForm.controls['q4'].value,
    };
    this.infantToddlerAssessment.q5 = {
      answer: this.infantToddlerForm.controls['q5'].value,
    };
    this.infantToddlerAssessment.q6 = {
      answer: this.infantToddlerForm.controls['q6'].value,
    };
    this.infantToddlerAssessment.q7 = {
      answer: this.infantToddlerForm.controls['q7'].value,
    };
    this.infantToddlerAssessment.q8 = {
      answer: this.infantToddlerForm.controls['q8'].value,
    };
    this.infantToddlerAssessment.q9 = {
      answer: this.infantToddlerForm.controls['q9'].value,
    };
    this.infantToddlerAssessment.q10 = {
      answer: this.infantToddlerForm.controls['q10'].value,
    };
    this.infantToddlerAssessment.q11 = {
      answer: this.infantToddlerForm.controls['q11'].value,
    };
    this.infantToddlerAssessment.q12 = {
      answer: this.infantToddlerForm.controls['q12'].value,
    };
    this.infantToddlerAssessment.q13 = {
      answer: this.infantToddlerForm.controls['q13'].value,
    };
    this.infantToddlerAssessment.q14 = {
      answer: this.infantToddlerForm.controls['q14'].value,
    };
    this.infantToddlerAssessment.q15 = {
      answer: this.infantToddlerForm.controls['q15'].value,
    };
    this.infantToddlerAssessment.q16 = {
      answer: this.infantToddlerForm.controls['q16'].value,
    };
    this.infantToddlerAssessment.q17 = {
      answer: this.infantToddlerForm.controls['q17'].value,
    };
    this.infantToddlerAssessment.q18 = {
      answer: this.infantToddlerForm.controls['q18'].value,
    };
    this.infantToddlerAssessment.q19 = {
      answer: this.infantToddlerForm.controls['q19'].value,
    };
    this.infantToddlerAssessment.q20 = {
      answer: this.infantToddlerForm.controls['q20'].value,
    };
    this.infantToddlerAssessment.q21 = {
      answer: this.infantToddlerForm.controls['q21'].value,
    };
    this.infantToddlerAssessment.q22 = {
      answer: this.infantToddlerForm.controls['q22'].value,
    };
    this.infantToddlerAssessment.q23 = {
      answer: this.infantToddlerForm.controls['q23'].value,
    };
    this.infantToddlerAssessment.q24 = {
      answer: this.infantToddlerForm.controls['q24'].value,
    };
    this.infantToddlerAssessment.age =
      this.infantToddlerForm.controls['age'].value;

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.infantToddlerAssessment.accessCode = this.accessCode;

      this.infantToddlerApiService
        .addGuestInfantToddler(this.infantToddlerAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Infant Toddler Assesment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Infant Toddler Assesment'
            );
          }
        );
    } else {
      this.infantToddlerApiService
        .addInfantToddler(this.infantToddlerAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Infant Toddler Assesment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Infant Toddler Assesment'
            );
          }
        );
    }
  }

  public validation_messages = {
    age: [{ type: 'required', message: 'Age is required' }],
    q1: [
      {
        type: 'required',
        message:
          '1. Do you know when your child is happy and when your child is upset? is required',
      },
    ],
    q2: [
      {
        type: 'required',
        message:
          '2. When your child plays with toys, does he/she look at you to see if you are watching? is required',
      },
    ],
    q3: [
      {
        type: 'required',
        message:
          '3. Does your child smile or laugh while looking at you? is required',
      },
    ],
    q4: [
      {
        type: 'required',
        message:
          '4. When you look at and point to a toy across the room, does your child look at it? is required',
      },
    ],
    q5: [
      {
        type: 'required',
        message:
          '1. Does your child let you know that he/she needs help or wants an object out of reach? is required',
      },
    ],
    q6: [
      {
        type: 'required',
        message:
          '2. When you are not paying attention to your child, does he/she try to get your attention? is required',
      },
    ],
    q7: [
      {
        type: 'required',
        message:
          '3. Does your child do things just to get you to laugh? is required',
      },
    ],
    q8: [
      {
        type: 'required',
        message:
          '4. Does your child try to get you to notice interesting objects—just to get you to look at the objects, not to get you to do anything with them? is required',
      },
    ],
    q9: [
      {
        type: 'required',
        message:
          '1. Does your child pick up objects and give them to you? is required',
      },
    ],
    q10: [
      {
        type: 'required',
        message:
          '2. Does your child show objects to you without giving you the object? is required',
      },
    ],
    q11: [
      {
        type: 'required',
        message: '3. Does your child wave to greet people? is required',
      },
    ],
    q12: [
      {
        type: 'required',
        message: '4. Does your child point to objects? is required',
      },
    ],
    q13: [
      {
        type: 'required',
        message:
          '5. Does your child nod his/her head to indicate yes? is required',
      },
    ],
    q14: [
      {
        type: 'required',
        message:
          '1. Does your child use sounds or words to get attention or help? is required',
      },
    ],
    q15: [
      {
        type: 'required',
        message:
          '2. Does your child string sounds together, such as uh oh, mama, gaga, bye bye, dada? is required',
      },
    ],
    q16: [
      {
        type: 'required',
        message:
          '3. About how many of the following consonant sounds does your child use: ma, na, ba, da, ga, wa, la, ya, sa, sha? is required',
      },
    ],
    q17: [
      {
        type: 'required',
        message:
          '1. About how many different words does your child use meaningfully that you recognize (such as baba for bottle; gaggie for doggie)? is required',
      },
    ],
    q18: [
      {
        type: 'required',
        message:
          '2. Does your child put two words together (for example, more cookie, bye bye Daddy)? is required',
      },
    ],
    q19: [
      {
        type: 'required',
        message:
          "1. When you call your child's name, does he/she respond by looking or turning toward you? is required",
      },
    ],
    q20: [
      {
        type: 'required',
        message:
          "2. About how many different words or phrases does your child understand without gestures? For example, if you say “where 's your tummy” “where 's Daddy,” “give me the ball,” or “come here,” without showing or pointing, your child will respond appropriately. is required",
      },
    ],
    q21: [
      {
        type: 'required',
        message:
          '1. Does your child show interest in playing with a variety of objects? is required',
      },
    ],
    q22: [
      {
        type: 'required',
        message:
          '2. About how many of the following objects does your child use appropriately: cup, bottle, bowl, spoon, comb or brush, toothbrush, washcloth, ball, toy vehicle, toy telephone? is required',
      },
    ],
    q23: [
      {
        type: 'required',
        message:
          '3. About how many blocks (or rings) does your child stack? is required',
      },
    ],
    q24: [
      {
        type: 'required',
        message:
          '4. Does your child pretend to play with toys (for example, feed a stuffed animal, put a doll to sleep, put an animal figure in a vehicle)? is required',
      },
    ],
  };

  private buildForm() {
    this.infantToddlerForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      age: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([Validators.required])),
      q1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q2: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q3: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q4: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q5: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q6: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q7: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q8: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q9: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q10: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q11: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q12: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q13: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q14: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q15: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q16: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q17: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q18: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q19: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q20: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q21: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q22: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q23: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q24: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.infantToddlerForm.valueChanges.subscribe(() => this.onValueChanged());
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.infantToddlerForm) {
      return;
    }
    const form = this.infantToddlerForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
