import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ITherapySession } from 'src/app/_models';
import { AutoAccidentQualifier } from 'src/app/shared/utilities/billing/autoAccidentQualifier';
import { ClaimFilingIndicators } from 'src/app/shared/utilities/billing/claimFilingIndicators';
import { ClaimResubmissionCodesList } from 'src/app/shared/utilities/billing/claimResubmissionCodes';
import { EPSDTConditionInticatorType } from 'src/app/shared/utilities/billing/epsdtType';
import { HealthInsuranceTypes } from 'src/app/shared/utilities/billing/healthInsuranceTypes';
import { PlaceOfServiceCodes } from 'src/app/shared/utilities/billing/placeofServices';
import { USStates } from 'src/app/shared/utilities/billing/states';
import { Colors } from 'src/app/shared/utilities/colors';
import { EditSupervisingProviderComponent } from '../../sessions/session-billing/edit-supervising-provider/edit-supervising-provider.component';
import { SelectReferringProviderComponent } from '../../sessions/session-billing/select-referring-provider/select-referring-provider.component';
import { IntakeSearchAddInsuranceComponent } from '../../sessions/bpsa/bpsa-intake/intake-insurance-info/intake-search-add-insurance/intake-search-add-insurance.component';
import { EditPriorAuthComponent } from '../../sessions/session-billing/edit-prior-auth/edit-prior-auth.component';
import { ExistingDiagnosisComponent } from '../../sessions/session-codes/session-diagnosis-codes/existing-diagnosis/existing-diagnosis.component';
import { TherapySessionService } from 'src/app/_services';
import { SearchAddProcedureCodeComponent } from '../../sessions/session-codes/session-procedural-codes/search-add-procedure-code/search-add-procedure-code.component';
import { EditConfirmProcedureCodeComponent } from '../../sessions/session-codes/session-procedural-codes/edit-confirm-procedure-code/edit-confirm-procedure-code.component';

@Component({
  selector: 'app-group-member-billing',
  templateUrl: './group-member-billing.component.html',
  styleUrl: './group-member-billing.component.css',
})
export class GroupMemberBillingComponent implements OnInit, OnChanges {
  @Input() sessionDetails: ITherapySession;
  @Input() selectedMemberId: string;
  @Input() hasBillingEditAccess: boolean;
  updatedSessionDetailsResponse: any;
  @Output() refreshTherapyObject = new EventEmitter<any>();

  // All information about this member
  memberNotes: any;

  // General Imports
  epsdtConditionTypes = EPSDTConditionInticatorType;
  usStateList = USStates;
  autoAccidentQualifierList = AutoAccidentQualifier;
  resubmissionCodesList = ClaimResubmissionCodesList;
  placeOfService = PlaceOfServiceCodes;
  healthInsuranceTypeList = HealthInsuranceTypes;
  claimFilingIndicatorList = ClaimFilingIndicators;

  // Billing related columns
  // Diagnosis list
  currentMemberDiagnosis = [];
  diagnosisColumn = [
    'moveArrows',
    'diagnosisIndex',
    'diagnosisCode',
    'diagnosisDescription',
    'actions',
  ];
  currentMemberCharges = [];
  chargesColumn = [
    'moveArrows',
    'cptIndex',
    'cptCode',
    'm1',
    'm2',
    'm3',
    'm4',
    'diagPointer',
    'amount',
    'unit',
    'charges',
    'actions',
  ];
  indexMap = new Map()
    .set(0, 'A')
    .set(1, 'B')
    .set(2, 'C')
    .set(3, 'D')
    .set(4, 'E')
    .set(5, 'F')
    .set(6, 'G')
    .set(7, 'H')
    .set(8, 'I')
    .set(9, 'J')
    .set(10, 'K')
    .set(11, 'L');

  colors = Colors;
  currentTime = new Date();

  diagnosisProcessing: boolean = false;
  chargeProcessing: boolean = false;
  processing: boolean = false;
  public billingForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    public therapysessionApiService: TherapySessionService
  ) {}

  ngOnInit(): void {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.sessionDetails || changes.selectedMemberId) {
      if (changes.selectedMemberId) {
        // Update the latest session object
        if (this.updatedSessionDetailsResponse) {
          this.refreshTherapyObject.emit(this.updatedSessionDetailsResponse);
          setTimeout(() => {
            this.updatedSessionDetailsResponse = null;
          }, 400);
        }
      }

      this.buildForm();

      if (this.selectedMemberId === 'All Members') {
        this.memberNotes = null;
        this.currentMemberDiagnosis = [];
        this.currentMemberCharges = [];
      } else {
        this.memberNotes = this.sessionDetails.groupMembers
          .filter((member) => member.member.id === this.selectedMemberId)
          .at(0);
        this.extractCurrentMemberDiagnosis();
        this.extractCurrentMemberCharges();
        this.populateExistingMemberDataOnForm();
      }
      //Check for session details
      if (
        this.sessionDetails.status != 'Active' ||
        !this.hasBillingEditAccess
      ) {
        this.billingForm.disable();

        this.diagnosisColumn = [
          'diagnosisIndex',
          'diagnosisCode',
          'diagnosisDescription',
        ];
        this.chargesColumn = [
          'cptIndex',
          'cptCode',
          'm1',
          'm2',
          'm3',
          'm4',
          'diagPointer',
          'amount',
          'unit',
          'charges',
        ];
      } else {
        this.billingForm.enable();
        this.diagnosisColumn = [
          'moveArrows',
          'diagnosisIndex',
          'diagnosisCode',
          'diagnosisDescription',
          'actions',
        ];
        this.chargesColumn = [
          'moveArrows',
          'cptIndex',
          'cptCode',
          'm1',
          'm2',
          'm3',
          'm4',
          'diagPointer',
          'amount',
          'unit',
          'charges',
          'actions',
        ];
      }
    }
  }

  buildForm() {
    this.billingForm = this.formBuilder.group({
      billingProviderPOSCode: new FormControl(
        this.sessionDetails.billingProviderPOSCode,
        Validators.required
      ),
      epsdtType: new FormControl(this.sessionDetails.epsdtType),
      epsdtFamilyPlan: new FormControl(this.sessionDetails.epsdtFamilyPlan),
      emgType: new FormControl(this.sessionDetails.emgType),
      acceptAssignment: new FormControl(this.sessionDetails.acceptAssignment),
      conditionRTEmployment: new FormControl(
        this.sessionDetails.conditionRTEmployment
      ),
      conditionRTAutoAccident: new FormControl(
        this.sessionDetails.conditionRTAutoAccident
      ),
      conditionRTAutoState: new FormControl(
        this.sessionDetails.conditionRTAutoState
      ),
      conditionRTAutoDate: new FormControl(
        this.sessionDetails.conditionRTAutoDate
          ? new Date(this.sessionDetails.conditionRTAutoDate)
          : null
      ),
      conditionRTAutoQualifier: new FormControl(
        this.sessionDetails.conditionRTAutoQualifier
      ),
      conditionRTOtherAccident: new FormControl(
        this.sessionDetails.conditionRTOtherAccident
      ),
      supervisingRenderingProvider: new FormControl(
        this.sessionDetails.supervisingRenderingProvider
      ),
      supervisingRPId: new FormControl(this.sessionDetails?.supervisingRPId),
      supervisingRPFirstName: new FormControl(
        this.sessionDetails.supervisingRPFirstName
      ),
      supervisingRPMiddleName: new FormControl(
        this.sessionDetails.supervisingRPMiddleName
      ),
      supervisingRPLastName: new FormControl(
        this.sessionDetails.supervisingRPLastName
      ),
      supervisingRPEmail: new FormControl(
        this.sessionDetails.supervisingRPEmail
      ),
      supervisingRPPhone: new FormControl(
        this.sessionDetails.supervisingRPPhone
      ),
      supervisingRPTaxonomyCode: new FormControl(
        this.sessionDetails.supervisingRPTaxonomyCode
      ),
      supervisingRPNPI: new FormControl(this.sessionDetails.supervisingRPNPI),
      supervisingRPBillingOption: new FormControl(
        this.sessionDetails.supervisingRPBillingOption
      ),
      supervisingRPTaxId: new FormControl(
        this.sessionDetails.supervisingRPTaxId
      ),
      supervisingRPSSN: new FormControl(this.sessionDetails.supervisingRPSSN),
      referringProvider: new FormControl(this.sessionDetails.referringProvider),
      referringProviderId: new FormControl(
        this.sessionDetails.referringProviderId
      ),

      referringProviderFirstName: new FormControl(
        this.sessionDetails.referringProviderFirstName
      ),
      referringProviderMiddleName: new FormControl(
        this.sessionDetails.referringProviderMiddleName
      ),
      referringProviderLastName: new FormControl(
        this.sessionDetails.referringProviderLastName
      ),
      referringProviderNPI: new FormControl(
        this.sessionDetails.referringProviderNPI
      ),
      referringProviderTaxonomy: new FormControl(
        this.sessionDetails.referringProviderTaxonomy
      ),

      // All member related billing information
      billingType: new FormControl(''),
      primaryInsuranceDetails: new FormControl(null),
      primaryClaimFilingIndicator: new FormControl(null),
      primaryHealthInsuranceType: new FormControl(null),
      anotherHealthPlan: new FormControl('No'),
      secondaryInsuranceDetails: new FormControl(null),
      secondaryClaimFilingIndicator: new FormControl(null),
      secondaryHealthInsuranceType: new FormControl(null),
      priorAuthCode: new FormControl('No'),
      priorAuthDetails: new FormControl(null),
    });
  }

  populateExistingMemberDataOnForm() {
    this.billingForm.controls['billingType'].setValue(
      this.memberNotes.billingType
    );
    // Insurance Details
    if (this.memberNotes.primaryInsuranceDetails) {
      this.billingForm.controls['primaryInsuranceDetails'].setValue(
        this.memberNotes.primaryInsuranceDetails
      );
      this.billingForm.controls['primaryClaimFilingIndicator'].setValue(
        this.memberNotes.primaryClaimFilingIndicator
      );
      this.billingForm.controls['primaryHealthInsuranceType'].setValue(
        this.memberNotes.primaryHealthInsuranceType
      );
    }
    if (this.memberNotes.priorAuthCode) {
      this.billingForm.controls['priorAuthCode'].setValue(
        this.memberNotes.priorAuthCode
      );
      this.billingForm.controls['priorAuthDetails'].setValue(
        this.memberNotes.priorAuthDetails
      );
    }
    this.billingForm.controls['anotherHealthPlan'].setValue(
      this.memberNotes.anotherHealthPlan
    );
    // Secondary Insurance
    this.billingForm.controls['anotherHealthPlan'].setValue(
      this.memberNotes.anotherHealthPlan
    );
    if (this.memberNotes.secondaryInsuranceDetails) {
      this.billingForm.controls['secondaryInsuranceDetails'].setValue(
        this.memberNotes.secondaryInsuranceDetails
      );
      this.billingForm.controls['secondaryClaimFilingIndicator'].setValue(
        this.memberNotes.secondaryClaimFilingIndicator
      );
      this.billingForm.controls['secondaryHealthInsuranceType'].setValue(
        this.memberNotes.secondaryHealthInsuranceType
      );
    }
  }

  // Supervising RP changed
  supervisingProviderChanged(event) {
    if (event.value === 'Yes') {
      this.billingForm
        .get('supervisingRPId')
        .addValidators(Validators.required);
      this.billingForm.get('supervisingRPId').updateValueAndValidity();
    } else if (event.value === 'No') {
      this.billingForm.get('supervisingRPId').setValue(null);
      this.billingForm.get('supervisingRPFirstName').setValue(null);
      this.billingForm.get('supervisingRPMiddleName').setValue(null);
      this.billingForm.get('supervisingRPLastName').setValue(null);
      this.billingForm.get('supervisingRPEmail').setValue(null);
      this.billingForm.get('supervisingRPPhone').setValue(null);
      this.billingForm.get('supervisingRPTaxonomyCode').setValue(null);
      this.billingForm.get('supervisingRPNPI').setValue(null);
      this.billingForm.get('supervisingRPBillingOption').setValue(null);
      this.billingForm.get('supervisingRPTaxId').setValue(null);
      this.billingForm.get('supervisingRPSSN').setValue(null);
      this.billingForm.get('supervisingRPId').clearValidators();
      this.billingForm.get('supervisingRPId').updateValueAndValidity();

      // Update the details on the member
      this.updateAllGroupMemberBilling();
    }
  }

  editSupervisingProvider() {
    let dialogRef = this.dialog.open(EditSupervisingProviderComponent, {
      data: {
        parentClass: 'Group Sessions',
        therapySession: this.sessionDetails,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '40vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        let supervisorDetails = response.details;
        this.billingForm.controls['supervisingRPId'].setValue(
          supervisorDetails.supervisingRPId
        );
        this.billingForm.controls['supervisingRPFirstName'].setValue(
          supervisorDetails.supervisingRPFirstName
        );
        this.billingForm.controls['supervisingRPMiddleName'].setValue(
          supervisorDetails.supervisingRPMiddleName
        );
        this.billingForm.controls['supervisingRPLastName'].setValue(
          supervisorDetails.supervisingRPLastName
        );
        this.billingForm.controls['supervisingRPEmail'].setValue(
          supervisorDetails.supervisingRPEmail
        );
        this.billingForm.controls['supervisingRPPhone'].setValue(
          supervisorDetails.supervisingRPPhone
        );
        this.billingForm.controls['supervisingRPTaxonomyCode'].setValue(
          supervisorDetails.supervisingRPTaxonomyCode
        );
        this.billingForm.controls['supervisingRPNPI'].setValue(
          supervisorDetails.supervisingRPNPI
        );
        this.billingForm.controls['supervisingRPBillingOption'].setValue(
          supervisorDetails.supervisingRPBillingOption
        );
        this.billingForm.controls['supervisingRPTaxId'].setValue(
          supervisorDetails.supervisingRPTaxId
        );
        this.billingForm.controls['supervisingRPSSN'].setValue(
          supervisorDetails.supervisingRPSSN
        );

        // Update the details on the member
        this.updateAllGroupMemberBilling();
      }
    });
  }

  referringProviderChanged(event) {
    if (event.value === 'Yes') {
      this.billingForm
        .get('referringProviderId')
        .addValidators(Validators.required);
      this.billingForm.get('referringProviderId').updateValueAndValidity();
    } else if (event.value === 'No') {
      this.billingForm.get('referringProviderId').setValue(null);
      this.billingForm.get('referringProviderId').clearValidators();
      this.billingForm.get('referringProviderId').updateValueAndValidity();

      this.billingForm.get('referringProviderFirstName').setValue(null);
      this.billingForm.get('referringProviderMiddleName').setValue(null);
      this.billingForm.get('referringProviderLastName').setValue(null);
      this.billingForm.get('referringProviderNPI').setValue(null);
      this.billingForm.get('referringProviderTaxonomy').setValue(null);

      // Update the details on the member
      this.updateAllGroupMemberBilling();
    }
  }

  selectReferringProvider() {
    let dialogRef = this.dialog.open(SelectReferringProviderComponent, {
      data: { therapySession: this.sessionDetails },
      autoFocus: false,
      disableClose: true,
      minWidth: '35vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        this.billingForm
          .get('referringProviderId')
          .setValue(response.details.id);

        this.billingForm.controls['referringProviderFirstName'].setValue(
          response.details.firstName
        );
        this.billingForm.controls['referringProviderMiddleName'].setValue(
          response.details?.middleName
        );
        this.billingForm.controls['referringProviderLastName'].setValue(
          response.details.lastName
        );

        this.billingForm.controls['referringProviderNPI'].setValue(
          response.details.individualNPI
        );

        this.billingForm.controls['referringProviderTaxonomy'].setValue(
          response.details.taxonomyCode
        );

        // Update the details on the member
        this.updateAllGroupMemberBilling();
      }
    });
  }

  // When EPSDT is restored, restore type
  epsdtTypeChanged(event) {
    if (event.value === 'NA' || event.value === 'No') {
      // Restore epsdt type
      this.billingForm.controls['epsdtType'].setValue('');
    }
    // Update the details on the member
    this.updateAllGroupMemberBilling();
  }

  autoAccidentTypeChanged(event) {
    if (event.value === 'Yes') {
      this.billingForm
        .get('conditionRTAutoState')
        .addValidators(Validators.required);
      this.billingForm.get('conditionRTAutoState').updateValueAndValidity();

      this.billingForm
        .get('conditionRTAutoDate')
        .addValidators(Validators.required);
      this.billingForm.get('conditionRTAutoDate').updateValueAndValidity();

      this.billingForm
        .get('conditionRTAutoQualifier')
        .addValidators(Validators.required);
      this.billingForm.get('conditionRTAutoQualifier').updateValueAndValidity();
    } else if (event.value === 'No') {
      this.billingForm.get('conditionRTAutoState').setValue(null);
      this.billingForm.get('conditionRTAutoState').clearValidators();
      this.billingForm.get('conditionRTAutoState').updateValueAndValidity();

      this.billingForm.get('conditionRTAutoDate').setValue(null);
      this.billingForm.get('conditionRTAutoDate').clearValidators();
      this.billingForm.get('conditionRTAutoDate').updateValueAndValidity();

      this.billingForm.get('conditionRTAutoQualifier').setValue('');
      this.billingForm.get('conditionRTAutoQualifier').clearValidators();
      this.billingForm.get('conditionRTAutoQualifier').updateValueAndValidity();
    }

    // Update the details on the member
    this.updateAllGroupMemberBilling();
  }

  otherAccidentTypeChanged(event) {
    if (event.value === 'Yes') {
      this.billingForm
        .get('conditionRTAutoDate')
        .addValidators(Validators.required);
      this.billingForm.get('conditionRTAutoDate').updateValueAndValidity();
    } else if (event.value === 'No') {
      this.billingForm.get('conditionRTAutoDate').setValue(null);
      this.billingForm.get('conditionRTAutoDate').clearValidators();
      this.billingForm.get('conditionRTAutoDate').updateValueAndValidity();
    }

    // Update the details on the member
    this.updateAllGroupMemberBilling();
  }

  billingTypeChanged(event) {
    if (event.value === 'Insurance') {
      this.billingForm
        .get('primaryInsuranceDetails')
        .addValidators(Validators.required);
      this.billingForm.get('primaryInsuranceDetails').updateValueAndValidity();
    } else if (event.value === 'Self-Pay') {
      this.billingForm.get('primaryInsuranceDetails').clearValidators();
      this.billingForm.get('primaryInsuranceDetails').updateValueAndValidity();

      this.billingForm.get('primaryClaimFilingIndicator').clearValidators();
      this.billingForm
        .get('primaryClaimFilingIndicator')
        .updateValueAndValidity();
      this.billingForm.get('primaryHealthInsuranceType').clearValidators();
      this.billingForm
        .get('primaryHealthInsuranceType')
        .updateValueAndValidity();
    }

    // Update the details on the member
    this.updateMemberBillingDetails();
  }

  searchInsurance(type) {
    let dialogRef = this.dialog.open(IntakeSearchAddInsuranceComponent, {
      data: {
        patient: this.memberNotes.member,
        therapySession: null,
        parentClass: 'SessionBilling',
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response && response?.type === 'success') {
        if (type === 'Primary') {
          this.billingForm.controls['primaryInsuranceDetails'].setValue(
            response.insurance
          );
          this.billingForm.controls['primaryClaimFilingIndicator'].setValue(
            response.insurance.claimFilingIndicator
          );
          this.billingForm.controls['primaryHealthInsuranceType'].setValue(
            response.insurance.healthInsuranceType
          );

          // Update endpoint to update the value
          this.updateMemberBillingDetails();
        } else {
          this.billingForm.controls['secondaryInsuranceDetails'].setValue(
            response.insurance
          );

          this.billingForm.controls['secondaryClaimFilingIndicator'].setValue(
            response.insurance.claimFilingIndicator
          );
          this.billingForm.controls['secondaryHealthInsuranceType'].setValue(
            response.insurance.healthInsuranceType
          );

          // Update endpoint to update the value
          this.updateMemberBillingDetails();
        }
      }
    });
  }

  removeInsurance(type) {
    if (type === 'Primary') {
      this.billingForm.controls['primaryInsuranceDetails'].setValue(null);
      this.billingForm.controls['primaryClaimFilingIndicator'].setValue(null);
      this.billingForm.controls['primaryHealthInsuranceType'].setValue(null);
    } else {
      this.billingForm.controls['secondaryInsuranceDetails'].setValue(null);
      this.billingForm.controls['secondaryClaimFilingIndicator'].setValue(null);
      this.billingForm.controls['secondaryHealthInsuranceType'].setValue(null);
    }

    // Update endpoint to update the value
    this.updateMemberBillingDetails();
  }

  priorAuthChanged(event) {
    if (event.value === 'Yes') {
      this.billingForm
        .get('priorAuthDetails')
        .addValidators(Validators.required);
      this.billingForm.get('priorAuthDetails').updateValueAndValidity();
    } else if (event.value === 'No') {
      this.billingForm.get('priorAuthDetails').setValue(null);
      this.billingForm.get('priorAuthDetails').clearValidators();
      this.billingForm.get('priorAuthDetails').updateValueAndValidity();

      // Update the details on the member
      this.updateMemberBillingDetails();
    }
  }

  lookUpPriorAuth() {
    let dialogRef = this.dialog.open(EditPriorAuthComponent, {
      data: {
        patient: this.memberNotes.member,
        therapySession: this.sessionDetails,
        primaryInsuranceDetails:
          this.billingForm.controls['primaryInsuranceDetails'].value,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        this.billingForm.controls['priorAuthDetails'].setValue(
          response.details
        );

        // Update endpoint to update the value
        this.updateMemberBillingDetails();
      }
    });
  }

  secondaryInsuranceChaged(event) {
    if (event.value === 'Yes') {
      this.billingForm
        .get('secondaryInsuranceDetails')
        .addValidators(Validators.required);
      this.billingForm
        .get('secondaryInsuranceDetails')
        .updateValueAndValidity();
    } else if (event.value === 'No') {
      this.billingForm.get('secondaryInsuranceDetails').setValue(null);
      this.billingForm.get('secondaryInsuranceDetails').clearValidators();
      this.billingForm
        .get('secondaryInsuranceDetails')
        .updateValueAndValidity();

      this.billingForm.get('secondaryClaimFilingIndicator').setValue(null);
      this.billingForm.get('secondaryClaimFilingIndicator').clearValidators();
      this.billingForm
        .get('secondaryClaimFilingIndicator')
        .updateValueAndValidity();

      this.billingForm.get('secondaryHealthInsuranceType').setValue(null);
      this.billingForm.get('secondaryHealthInsuranceType').clearValidators();
      this.billingForm
        .get('secondaryHealthInsuranceType')
        .updateValueAndValidity();

      // Update the details on the member
      this.updateMemberBillingDetails();
    }
  }

  extractCurrentMemberDiagnosis() {
    this.currentMemberDiagnosis = this.sessionDetails.groupMembers
      .filter((member) => member.member.id === this.selectedMemberId)
      .at(0)?.diagnosisCodes;
  }

  extractCurrentMemberCharges() {
    this.currentMemberCharges = this.sessionDetails.groupMembers
      .filter((member) => member.member.id === this.selectedMemberId)
      .at(0)?.charges;
  }

  addDiagnosis() {
    const dialogRef = this.dialog.open(ExistingDiagnosisComponent, {
      data: {
        patient: {
          id: this.selectedMemberId,
          organizationId: this.sessionDetails.organizationId,
        },
        therapySession: this.sessionDetails,
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '50vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        // Now we need to add this diagnosis to the member diagnosis list and refresh the object
        let diagnosis = response.diagnosis;

        if (!this.currentMemberDiagnosis) {
          this.currentMemberDiagnosis = [];
        }

        // Check if the same diagnosis does exist or not
        let diagnosisExists = this.currentMemberDiagnosis.filter(
          (emp) => emp.icdCode === diagnosis.icdCode
        );
        if (diagnosisExists.length > 0) {
          return;
        }

        let index = this.currentMemberDiagnosis.length;
        this.currentMemberDiagnosis.push({
          id: diagnosis.id,
          icdCode: diagnosis.icdCode,
          shortDesc: diagnosis.shortDesc,
          longDesc: diagnosis.longDesc,
          diagnosisIndex: this.indexMap.get(index),
          status: diagnosis.status,
        });

        this.updateTherapyDiagnosis();
      }
    });
  }

  updateTherapyDiagnosis() {
    this.diagnosisProcessing = true;

    // Now append this diagnosis to the member list
    this.therapysessionApiService
      .updateGroupDianosis(this.sessionDetails.id, this.selectedMemberId, {
        diagnosisCodes: this.currentMemberDiagnosis,
      })
      .subscribe({
        next: (response) => {
          this.diagnosisProcessing = false;
          this.updatedSessionDetailsResponse = response;
        },
        error: (error) => {
          this.diagnosisProcessing = false;
        },
      });
  }

  moveDiagnosis(direction, index) {
    if (
      this.currentMemberDiagnosis &&
      this.currentMemberDiagnosis?.length == 1
    ) {
      return;
    }

    // Upward
    if (direction === 'UP') {
      let previosIndex = index - 1;
      if (index == 0 || previosIndex < 0) {
        return;
      }

      let icdCode = this.currentMemberDiagnosis[index].icdCode;
      let longDesc = this.currentMemberDiagnosis[index].longDesc;
      let shortDesc = this.currentMemberDiagnosis[index].shortDesc;

      this.currentMemberDiagnosis[index].icdCode =
        this.currentMemberDiagnosis[previosIndex].icdCode;
      this.currentMemberDiagnosis[index].longDesc =
        this.currentMemberDiagnosis[previosIndex].longDesc;
      this.currentMemberDiagnosis[index].shortDesc =
        this.currentMemberDiagnosis[previosIndex].shortDesc;

      this.currentMemberDiagnosis[previosIndex].icdCode = icdCode;
      this.currentMemberDiagnosis[previosIndex].longDesc = longDesc;
      this.currentMemberDiagnosis[previosIndex].shortDesc = shortDesc;

      // Update the diagnosis
      this.updateTherapyDiagnosis();
    } else {
      // Downward
      let nextIndex = index + 1;
      if (
        index - 1 == this.currentMemberDiagnosis.length ||
        nextIndex > this.currentMemberDiagnosis.length - 1
      ) {
        return;
      }

      let icdCode = this.currentMemberDiagnosis[index].icdCode;
      let longDesc = this.currentMemberDiagnosis[index].longDesc;
      let shortDesc = this.currentMemberDiagnosis[index].shortDesc;

      this.currentMemberDiagnosis[index].icdCode =
        this.currentMemberDiagnosis[nextIndex].icdCode;
      this.currentMemberDiagnosis[index].longDesc =
        this.currentMemberDiagnosis[nextIndex].longDesc;
      this.currentMemberDiagnosis[index].shortDesc =
        this.currentMemberDiagnosis[nextIndex].shortDesc;

      this.currentMemberDiagnosis[nextIndex].icdCode = icdCode;
      this.currentMemberDiagnosis[nextIndex].longDesc = longDesc;
      this.currentMemberDiagnosis[nextIndex].shortDesc = shortDesc;

      // Update the diagnosis
      this.updateTherapyDiagnosis();
    }
  }

  removeDiagnosis(index) {
    this.currentMemberDiagnosis.splice(index, 1);

    // Resizing the index
    for (let i = index; i < this.currentMemberDiagnosis.length; i++) {
      this.currentMemberDiagnosis[i].diagnosisIndex = this.indexMap.get(i);
    }

    // Update the diagnosis
    this.updateTherapyDiagnosis();
  }

  addCharge() {
    let dialogRef = this.dialog.open(SearchAddProcedureCodeComponent, {
      data: {
        parentClass: 'SessionBilling',
        patient: this.memberNotes.member,
        primaryInsurance:
          this.billingForm.controls['primaryInsuranceDetails'].value,
        billingProviderId: this.sessionDetails.billingProviderRefId,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '70vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        // Now we need to add this diagnosis to the member diagnosis list and refresh the object
        let charge = response.cptCode;

        if (!this.currentMemberCharges) {
          this.currentMemberCharges = [];
        }

        // Check if the same charge does exist or not
        let chargeExists = this.currentMemberCharges.filter(
          (emp) => emp.cptCode === charge.cptCode
        );
        if (chargeExists.length > 0) {
          return;
        }

        let index = this.currentMemberCharges.length;
        // Push this charge
        this.currentMemberCharges.push({
          cptIndex: index + 1,
          id: charge.id,
          cptCode: charge.cptCode,
          description: charge.description,
          rate: charge.rate,
          defaultUnit: charge.defaultUnit,
          charges: charge.rate * charge.defaultUnit,
          duration: charge.duration,
          durationUnit: charge.durationUnit,
          modifier1: charge.modifier1,
          modifier2: charge.modifier2,
          modifier3: charge.modifier3,
          modifier4: charge.modifier4,
          diagnosisPointer: charge.diagnosisPointer,
        });

        // Update endpoint to update the value
        this.updateMemberBillingDetails();
      }
    });
  }

  moveCharge(direction, index) {
    if (this.currentMemberCharges && this.currentMemberCharges?.length == 1) {
      return;
    }

    let id = this.currentMemberCharges[index].id;
    let cptCode = this.currentMemberCharges[index].cptCode;
    let description = this.currentMemberCharges[index].description;
    let rate = this.currentMemberCharges[index].rate;
    let defaultUnit = this.currentMemberCharges[index].defaultUnit;
    let charges = this.currentMemberCharges[index].charges;
    let duration = this.currentMemberCharges[index].duration;
    let durationUnit = this.currentMemberCharges[index].durationUnit;
    let modifier1 = this.currentMemberCharges[index].modifier1;
    let modifier2 = this.currentMemberCharges[index].modifier2;
    let modifier3 = this.currentMemberCharges[index].modifier3;
    let modifier4 = this.currentMemberCharges[index].modifier4;
    let diagnosisPointer = this.currentMemberCharges[index].diagnosisPointer;

    // Upward
    if (direction === 'UP') {
      let previosIndex = index - 1;
      if (index == 0 || previosIndex < 0) {
        return;
      }

      this.currentMemberCharges[index].id =
        this.currentMemberCharges[previosIndex].id;
      this.currentMemberCharges[index].cptCode =
        this.currentMemberCharges[previosIndex].cptCode;
      this.currentMemberCharges[index].description =
        this.currentMemberCharges[previosIndex].description;
      this.currentMemberCharges[index].rate =
        this.currentMemberCharges[previosIndex].rate;
      this.currentMemberCharges[index].defaultUnit =
        this.currentMemberCharges[previosIndex].defaultUnit;
      this.currentMemberCharges[index].charges =
        this.currentMemberCharges[previosIndex].charges;
      this.currentMemberCharges[index].duration =
        this.currentMemberCharges[previosIndex].duration;
      this.currentMemberCharges[index].durationUnit =
        this.currentMemberCharges[previosIndex].durationUnit;
      this.currentMemberCharges[index].modifier1 =
        this.currentMemberCharges[previosIndex].modifier1;
      this.currentMemberCharges[index].modifier2 =
        this.currentMemberCharges[previosIndex].modifier2;
      this.currentMemberCharges[index].modifier3 =
        this.currentMemberCharges[previosIndex].modifier3;
      this.currentMemberCharges[index].modifier4 =
        this.currentMemberCharges[previosIndex].modifier4;
      this.currentMemberCharges[index].diagnosisPointer =
        this.currentMemberCharges[previosIndex].diagnosisPointer;

      this.currentMemberCharges[previosIndex].id = id;
      this.currentMemberCharges[previosIndex].cptCode = cptCode;
      this.currentMemberCharges[previosIndex].description = description;
      this.currentMemberCharges[previosIndex].rate = rate;
      this.currentMemberCharges[previosIndex].defaultUnit = defaultUnit;
      this.currentMemberCharges[previosIndex].charges = charges;
      this.currentMemberCharges[previosIndex].duration = duration;
      this.currentMemberCharges[previosIndex].durationUnit = durationUnit;
      this.currentMemberCharges[previosIndex].modifier1 = modifier1;
      this.currentMemberCharges[previosIndex].modifier2 = modifier2;
      this.currentMemberCharges[previosIndex].modifier3 = modifier3;
      this.currentMemberCharges[previosIndex].modifier4 = modifier4;
      this.currentMemberCharges[previosIndex].diagnosisPointer =
        diagnosisPointer;

      // Update the charges
      this.updateMemberBillingDetails();
    } else {
      // Downward
      let nextIndex = index + 1;
      if (
        index - 1 == this.currentMemberCharges.length ||
        nextIndex > this.currentMemberCharges.length - 1
      ) {
        return;
      }

      this.currentMemberCharges[index].id =
        this.currentMemberCharges[nextIndex].id;
      this.currentMemberCharges[index].cptCode =
        this.currentMemberCharges[nextIndex].cptCode;
      this.currentMemberCharges[index].description =
        this.currentMemberCharges[nextIndex].description;
      this.currentMemberCharges[index].rate =
        this.currentMemberCharges[nextIndex].rate;
      this.currentMemberCharges[index].defaultUnit =
        this.currentMemberCharges[nextIndex].defaultUnit;
      this.currentMemberCharges[index].charges =
        this.currentMemberCharges[nextIndex].charges;
      this.currentMemberCharges[index].duration =
        this.currentMemberCharges[nextIndex].duration;
      this.currentMemberCharges[index].durationUnit =
        this.currentMemberCharges[nextIndex].durationUnit;
      this.currentMemberCharges[index].modifier1 =
        this.currentMemberCharges[nextIndex].modifier1;
      this.currentMemberCharges[index].modifier2 =
        this.currentMemberCharges[nextIndex].modifier2;
      this.currentMemberCharges[index].modifier3 =
        this.currentMemberCharges[nextIndex].modifier3;
      this.currentMemberCharges[index].modifier4 =
        this.currentMemberCharges[nextIndex].modifier4;
      this.currentMemberCharges[index].diagnosisPointer =
        this.currentMemberCharges[nextIndex].diagnosisPointer;

      this.currentMemberCharges[nextIndex].id = id;
      this.currentMemberCharges[nextIndex].cptCode = cptCode;
      this.currentMemberCharges[nextIndex].description = description;
      this.currentMemberCharges[nextIndex].rate = rate;
      this.currentMemberCharges[nextIndex].defaultUnit = defaultUnit;
      this.currentMemberCharges[nextIndex].charges = charges;
      this.currentMemberCharges[nextIndex].duration = duration;
      this.currentMemberCharges[nextIndex].durationUnit = durationUnit;
      this.currentMemberCharges[nextIndex].modifier1 = modifier1;
      this.currentMemberCharges[nextIndex].modifier2 = modifier2;
      this.currentMemberCharges[nextIndex].modifier3 = modifier3;
      this.currentMemberCharges[nextIndex].modifier4 = modifier4;
      this.currentMemberCharges[nextIndex].diagnosisPointer = diagnosisPointer;

      // Update the updateMemberCharges
      this.updateMemberBillingDetails();
    }
  }

  // Remove Charge
  removeCharge(index) {
    this.currentMemberCharges.splice(index, 1);

    // Resizing the index
    for (let i = index; i < this.currentMemberCharges.length; i++) {
      this.currentMemberCharges[i].cptIndex = i + 1;
    }

    // Update endpoint to update the value
    this.updateMemberBillingDetails();
  }

  // Edit charge
  editCharge(row, index) {
    let dialogRef = this.dialog.open(EditConfirmProcedureCodeComponent, {
      data: {
        cptCode: row,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '40vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        let cptCode = response.cptCode;
        this.currentMemberCharges[index].defaultUnit = cptCode.defaultUnit;
        this.currentMemberCharges[index].charges =
          cptCode.rate * cptCode.defaultUnit;
        this.currentMemberCharges[index].duration = cptCode.duration;
        this.currentMemberCharges[index].durationUnit = cptCode.durationUnit;
        this.currentMemberCharges[index].modifier1 = cptCode.modifier1;
        this.currentMemberCharges[index].modifier2 = cptCode.modifier2;
        this.currentMemberCharges[index].modifier3 = cptCode.modifier3;
        this.currentMemberCharges[index].modifier4 = cptCode.modifier4;
        this.currentMemberCharges[index].diagnosisPointer =
          cptCode.diagnosisPointer;

        // Update endpoint to update the value
        this.updateMemberBillingDetails();
      }
    });
  }

  getTotal() {
    let total = 0;

    this.currentMemberCharges.forEach((charge) => {
      total = total + charge.charges;
    });

    return total;
  }

  // Update billing form value changes
  updateAllGroupMemberBilling() {
    // Need to validate that this changes are not related to the all group members
    if (this.selectedMemberId != 'All Members') {
      return;
    }

    let currentBillingObject = Object.assign({}, this.billingForm.value);

    if (currentBillingObject.conditionRTAutoDate) {
      currentBillingObject.conditionRTAutoDate = new Date(
        currentBillingObject.conditionRTAutoDate
      );
    }
    let payload = {
      ...currentBillingObject,
      charges: this.currentMemberCharges,
    };
    this.processing = true;

    this.therapysessionApiService
      .updateAllGroupMemberBillingInformation(this.sessionDetails.id, payload)
      .subscribe({
        next: (response) => {
          this.processing = false;
          this.updatedSessionDetailsResponse = response;
        },
        error: (error) => {
          this.processing = false;
        },
      });
  }

  // Update billing form value changes
  updateMemberBillingDetails() {
    // Need to validate that this changes are not related to the all group members
    if (this.selectedMemberId === 'All Members') {
      return;
    }

    let currentBillingObject = Object.assign({}, this.billingForm.value);

    let payload = {
      ...currentBillingObject,
      charges: this.currentMemberCharges,
    };
    this.chargeProcessing = true;

    this.therapysessionApiService
      .updateGroupMemberCharge(
        this.sessionDetails.id,
        this.selectedMemberId,
        payload
      )
      .subscribe({
        next: (response) => {
          this.chargeProcessing = false;
          this.updatedSessionDetailsResponse = response;
        },
        error: (error) => {
          this.chargeProcessing = false;
        },
      });
  }
}
