<mat-card-title *ngIf="selectedOption">
  <span
    (click)="selectedOption = null"
    style="color: #2196f3; cursor: pointer; font-weight: 500"
  >
    Print Center
  </span>
  <span> &nbsp; / &nbsp; {{ selectedOption?.name }} </span>
</mat-card-title>
<mat-divider></mat-divider>

<div style="padding: 10px">
  <div class="gridContainer" *ngIf="!selectedOption" style="margin: 10px">
    <ng-container *ngFor="let category of printList; let i = index">
      <mat-card
        appearance="raised"
        style="border-radius: 15px"
        class="matCardBox mat-elevation-z8"
        *ngIf="
          !category.sessionStatus ||
          category.sessionStatus === therapySession.status
        "
      >
        <p style="font-weight: 800; font-size: 19px">{{ category.title }}</p>

        <div *ngFor="let form of category.forms" style="margin-bottom: 5px">
          <i
            class="fa fa-file-pdf-o"
            aria-hidden="true"
            style="color: #2196f3"
          ></i>
          <a
            (click)="selectedOption = form"
            style="
              font-weight: 400;
              color: #2196f3;
              cursor: pointer;
              opacity: 0.8;
            "
          >
            {{ form.name }}
          </a>
        </div>
      </mat-card>
    </ng-container>
  </div>

  <app-subjective-objective
    [therapySession]="therapySession"
    *ngIf="selectedOption?.name === 'Subjective & Objective'"
  ></app-subjective-objective>

  <app-treatment-plan
    [therapySession]="therapySession"
    *ngIf="selectedOption?.name === 'Treatment Plan'"
  ></app-treatment-plan>

  <app-bpsa-summary-document
    [therapySession]="therapySession"
    *ngIf="selectedOption?.name === 'Intake & Clinical Summary'"
  ></app-bpsa-summary-document>

  <app-clinical-notes
    [therapySession]="therapySession"
    *ngIf="
      selectedOption?.name === 'Clinical Notes' ||
      selectedOption?.name === 'Phone Notes'
    "
  ></app-clinical-notes>

  <app-intake-documents
    [therapySession]="therapySession"
    [documentType]="selectedOption?.documentId"
    *ngIf="selectedOption?.type === 'intakeReports'"
  >
  </app-intake-documents>
</div>
