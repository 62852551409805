<mat-card
  appearance="raised"
  style="border-radius: 15px"
  class="mat-elevation-z8"
>
  <div style="position: absolute; top: 0; right: 0" *ngIf="hasMemberEditAccess">
    <button
      id="editButton"
      style="z-index: 1000; opacity: 0.4"
      mat-icon-button
      type="button"
      (click)="editPatient(patient)"
    >
      <mat-icon style="font-size: 16px">edit</mat-icon>
    </button>
  </div>
  <div class="row">
    <!-- Basic patient information -->
    <div class="col-md-12" style="text-align: center">
      <i
        class="fa fa-user-circle-o fa-5x"
        [style]="
          patient?.restrictedMember
            ? 'color: #f44336; font-size: 50px'
            : 'color: cornflowerblue; font-size: 50px'
        "
      ></i>
    </div>
    <div
      class="col-md-12"
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
      "
    >
      <span style="font-weight: bold; font-size: 16px; margin-top: 5px">
        {{ patient?.lastName }}, {{ patient?.firstName }}
        {{ patient?.middleName }}
      </span>
      <ng-container *ngIf="patient?.dateOfBirth || patient?.gender">
        <span
          style="
            font-weight: normal;
            font-size: 12px;
            text-transform: capitalize;
          "
        >
          (
          <ng-container
            *ngIf="
              patient?.gender === 'M' ||
              patient?.gender === 'F' ||
              patient?.gender === 'MALE' ||
              patient?.gender === 'FEMALE'
            "
          >
            <span>
              {{
                patient?.gender === 'M'
                  ? 'Male, '
                  : patient?.gender === 'F'
                  ? 'Female, '
                  : patient?.gender
              }}
            </span>
          </ng-container>
          <span>{{ patient?.dateOfBirth }}</span>
          )
        </span>
      </ng-container>
      <label style="margin: 0; word-break: break-all" *ngIf="patient?.email">
        {{ patient?.email }}
      </label>
      <label style="margin: 0" *ngIf="patient?.phoneNumber">
        {{ patient?.phoneNumber | mask : '(000) 000-0000' }}
      </label>
    </div>

    <!-- More Information -->
    <div class="col-md-12" style="text-align: center; margin-top: 15px">
      <label
        style="
          color: #2196f3;
          cursor: pointer;
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        "
        (click)="showMemberInformation = true"
        *ngIf="!showMemberInformation"
      >
        Show Member Information <mat-icon>expand_more</mat-icon>
      </label>

      <label
        style="
          color: #2196f3;
          cursor: pointer;
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        "
        (click)="showMemberInformation = false"
        *ngIf="showMemberInformation"
      >
        Hide Member Information <mat-icon>expand_less</mat-icon>
      </label>
    </div>
    <div
      style="
        display: flex;
        flex-direction: column;
        margin-left: 5%;
        margin-top: 15px;
        width: 100%;
      "
      *ngIf="showMemberInformation"
    >
      <div class="twoGridContainer">
        <div class="text-bold">Member Number</div>
        <div>
          {{ patient?.memberNumber }}
        </div>
      </div>

      <div class="twoGridContainer" *ngIf="patient?.height">
        <div class="text-bold">Height</div>
        <div>{{ patient?.height }} in</div>
      </div>
      <div class="twoGridContainer" *ngIf="patient?.weight">
        <div class="text-bold">Weight</div>
        <div>{{ patient?.weight }} Lb</div>
      </div>

      <div
        class="twoGridContainer"
        *ngIf="
          patient?.maritalStatus &&
          patient?.maritalStatus != 'UNKWN' &&
          patient?.maritalStatus != 'OTHER'
        "
      >
        <div class="text-bold">Marital Status</div>
        <div style="text-transform: capitalize">
          {{
            patient?.maritalStatus === 'MARRD'
              ? 'Married'
              : patient?.maritalStatus === 'SINGL'
              ? 'Single'
              : patient?.maritalStatus === 'WIDWD'
              ? 'Widowed'
              : patient?.maritalStatus === 'SPRTD'
              ? 'Separated'
              : patient?.maritalStatus === 'DVRCD'
              ? 'Divorced'
              : patient?.maritalStatus === 'UNKWN'
              ? 'Unknown'
              : patient?.maritalStatus === 'ENGD'
              ? 'Engaged'
              : patient?.maritalStatus === 'INTRL'
              ? 'Interlocutory'
              : patient?.maritalStatus === 'PRVSLYMRD'
              ? 'Previously Married'
              : patient?.maritalStatus === 'NVRMRD'
              ? 'Never Married'
              : 'Other'
          }}
        </div>
      </div>
      <div class="twoGridContainer" *ngIf="patient?.employmentStatus">
        <div class="text-bold">Employment Status</div>
        <div style="text-transform: capitalize">
          {{
            patient?.employmentStatus === 'UNKWN'
              ? 'Unknown'
              : patient?.employmentStatus === 'EMPLY'
              ? 'Employed'
              : patient?.employmentStatus === 'FTSTD'
              ? 'Full Time Student'
              : patient?.employmentStatus === 'PTSTD'
              ? 'Part Time Student'
              : patient?.employmentStatus === 'RETRD'
              ? 'Retired'
              : patient?.employmentStatus === 'DISBD'
              ? 'Disabled'
              : patient?.employmentStatus === 'HNMKR'
              ? 'Homemaker'
              : patient?.employmentStatus === 'UNEMP'
              ? 'Unemployed'
              : ''
          }}
        </div>
      </div>
      <div
        class="twoGridContainer"
        *ngIf="
          patient?.preferredLanguage &&
          patient?.preferredLanguage != 'NTSPF' &&
          patient?.preferredLanguage != 'OTHER'
        "
      >
        <div class="text-bold">Preffered Language</div>
        <div>
          {{
            patient?.preferredLanguage === 'ENGSH'
              ? 'English'
              : patient?.preferredLanguage === 'SPNSH'
              ? 'Spanish'
              : patient?.preferredLanguage === 'ARBC'
              ? 'Arabic'
              : patient?.preferredLanguage === 'BENGI'
              ? 'Bengali'
              : patient?.preferredLanguage === 'FRNCH'
              ? 'French'
              : patient?.preferredLanguage === 'GERMN'
              ? 'German'
              : patient?.preferredLanguage === 'HINDI'
              ? 'Hindi'
              : patient?.preferredLanguage === 'ITLIN'
              ? 'Italian'
              : patient?.preferredLanguage === 'JPNES'
              ? 'Japanese'
              : patient?.preferredLanguage === 'KOREN'
              ? 'Korean'
              : patient?.preferredLanguage === 'MNCHI'
              ? 'Mandarin Chinese'
              : patient?.preferredLanguage === 'PNJBI'
              ? 'Punjabi'
              : patient?.preferredLanguage === 'PRTGS'
              ? 'Protuguese'
              : patient?.preferredLanguage === 'RUSIN'
              ? 'Russian'
              : patient?.preferredLanguage
          }}
        </div>
      </div>

      <div class="twoGridContainer" *ngIf="patient?.homePhoneNumber">
        <div class="text-bold">Home Phone</div>
        <div style="text-transform: capitalize">
          {{ patient?.homePhoneNumber | mask : '(000) 000-0000' }}
        </div>
      </div>

      <div class="twoGridContainer" *ngIf="patient?.workPhoneNumber">
        <div class="text-bold">Work Phone</div>
        <div style="text-transform: capitalize">
          {{ patient?.workPhoneNumber | mask : '(000) 000-0000' }}
        </div>
      </div>

      <div class="twoGridContainer" *ngIf="patient?.addressLine1">
        <div class="text-bold">Address</div>
        <div style="text-transform: capitalize">
          {{ patient?.addressLine1 }} {{ patient?.addressLine2 }}
          <br />
          {{ patient?.city }}&nbsp; {{ patient?.state }}&nbsp;
          {{ patient?.zip }}
        </div>
      </div>

      <div class="twoGridContainer">
        <div class="text-bold">Status</div>
        <div>
          <span
            *ngIf="patient?.status === 'Active'"
            style="color: #3ac47d; font-weight: 900"
          >
            {{ patient?.status }}
          </span>
          <span
            *ngIf="patient?.status != 'Active'"
            style="color: #d92550; font-weight: 900"
          >
            {{ patient?.status }}
          </span>
        </div>
      </div>
      <div class="twoGridContainer" *ngIf="patient?.status === 'Discharged'">
        <div class="text-bold">Discharged Date</div>
        <div>
          {{ patient?.dischargedDate | date : 'MM/dd/YYYY' }}
        </div>
      </div>
      <div class="twoGridContainer" *ngIf="patient?.status === 'Deceased'">
        <div class="text-bold">Deceased Date</div>
        <div>
          {{ patient?.deceasedDate }}
        </div>
      </div>
      <div
        class="twoGridContainer"
        *ngIf="patient?.status == 'Active' && hasPatientPortalFeature"
      >
        <div class="text-bold">Member Portal</div>
        <span
          style="color: #2196f3; font-weight: 440; cursor: pointer"
          (click)="callActiveConfirmation(patient)"
          *ngIf="!patient?.patientPortal"
        >
          Activate Member Portal
        </span>
        <span
          *ngIf="patient?.patientPortal"
          style="color: #2196f3; font-weight: 440"
        >
          Enabled
        </span>
      </div>
    </div>

    <!-- Mat Chip Selection -->
    <div class="col-md-12" style="text-align: center">
      <hr />

      <!-- Emergency Contacts -->
      <button
        type="button"
        mat-button
        (click)="navigateTo('CONTACTS')"
        *ngIf="hasContactsViewAccess"
        color="primary"
      >
        <mat-icon style="margin-right: 5px">contact_emergency</mat-icon>
        Emergency Contacts
      </button>

      <!-- \      Insurance  -->
      <button
        type="button"
        mat-button
        (click)="navigateTo('INSURANCE')"
        color="primary"
      >
        <mat-icon style="margin-right: 5px"> health_and_safety </mat-icon>
        Insurance Information
      </button>

      <!-- Provide Access Button -->
      <button
        *ngIf="patient?.restrictedMember && isOrgAdmin"
        type="button"
        mat-button
        (click)="assignAuthorizedUsers(patient)"
        color="primary"
      >
        <mat-icon style="margin-right: 5px"> groups </mat-icon>
        Access
      </button>
    </div>
  </div>
</mat-card>
