<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>
    Clinical Institute Withdrawal Assessment of Alcohol Scale, Revised (CIWA-AR)
  </p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="col-md-12" style="padding: 0">
  <div
    *ngIf="assessmentId && !isPatient"
    class="row"
    style="
      margin-bottom: 20px;
      display: flex;
      align-content: center;
      flex-direction: column;
      font-weight: 450;
    "
  >
    Risk Score
    <button
      mat-fab
      color="primary"
      aria-label="Score"
      style="margin-left: 10px"
    >
      {{ riskScore }}
    </button>
  </div>

  <form [formGroup]="ciwaForm" novalidate (ngSubmit)="onCiwaFormSubmit()">
    <div class="row">
      <div class="col-md-12 pad10 mat-elevation-z8">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <label class="labelText">
                  Pulse or heart rate, taken for one minute
                  <span style="color: red">*</span>
                </label>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    placeholder="Pulse or heart rate, taken for one minute"
                    formControlName="pulse"
                    name="pulse"
                    minlength="1"
                    required
                  />
                  <mat-error *ngIf="ciwaForm.controls['pulse'].invalid">
                    Pulse is required
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <label class="labelText">
                  Blood Pressure <span style="color: red">*</span>
                </label>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    placeholder="Blood Pressure"
                    formControlName="bloodpressure"
                    name="bloodpressure"
                    minlength="1"
                    required
                  />
                  <mat-error *ngIf="ciwaForm.controls['bloodpressure'].invalid">
                    Blood Pressure is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <mat-card class="col-md-2 pad10 mat-elevation-z8" style="padding: 0px">
        <mat-card-header>
          <mat-card-title>
            <span class="asterix-after">Nausea and Vomiting</span>
          </mat-card-title>
          <mat-card-subtitle
            >Ask “Do you feel sick to your stomach? Have you vomited?”
            Observation.</mat-card-subtitle
          >
        </mat-card-header>
        <mat-card-content>
          <div class="col-sm-12">
            <mat-button-toggle-group
              [disabled]="disableFormElements"
              vertical="true"
              formControlName="nauseaAndVomiting"
            >
              <mat-button-toggle
                value="0 - No nausea and no vomiting"
                class="toggleLabel"
                >0 - No nausea and no vomiting</mat-button-toggle
              >
              <mat-button-toggle
                value="1 - Mild nausea with no vomiting"
                class="toggleLabel"
                >1 - Mild nausea with no vomiting</mat-button-toggle
              >
              <mat-button-toggle value="2" class="toggleLabel"
                >2</mat-button-toggle
              >
              <mat-button-toggle value="3" class="toggleLabel"
                >3</mat-button-toggle
              >
              <mat-button-toggle
                value="4 - Intermittent nausea with dry heaves"
                class="toggleLabel"
                >4 - Intermittent nausea with dry heaves</mat-button-toggle
              >
              <mat-button-toggle value="5" class="toggleLabel"
                >5</mat-button-toggle
              >
              <mat-button-toggle value="6" class="toggleLabel"
                >6</mat-button-toggle
              >
              <mat-button-toggle
                value="7 - Constant nausea frequent dry heaves and vomiting"
                class="toggleLabel"
                >7 - Constant nausea frequent dry heaves and
                vomiting</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="col-md-2 pad10 mat-elevation-z8" style="padding: 0px">
        <mat-card-header>
          <mat-card-title>
            <span class="asterix-after">Tactile Disturbances</span>
          </mat-card-title>
          <mat-card-subtitle
            >Ask “Have you any itching, pins and needles sensations, any
            burning, any numbness, or do you feel bugs crawling on or under your
            skin?” Observation.</mat-card-subtitle
          >
        </mat-card-header>
        <mat-card-content>
          <div class="col-sm-12">
            <mat-button-toggle-group
              [disabled]="disableFormElements"
              vertical="true"
              formControlName="tactileDisturbances"
            >
              <mat-button-toggle value="0 - None">0 - None</mat-button-toggle>
              <mat-button-toggle
                value="1 - Very mild itching pins and needles burning or numbness"
                >1 - Very mild itching pins and needles burning or
                numbness</mat-button-toggle
              >
              <mat-button-toggle
                value="2 - Mild itching pins and needles burning or numbness"
                >2 - Mild itching pins and needles burning or
                numbness</mat-button-toggle
              >
              <mat-button-toggle
                value="3 - Moderate itching pins and needles burning or numbness"
                >3 - Moderate itching pins and needles burning or
                numbness</mat-button-toggle
              >
              <mat-button-toggle value="4 - Moderately severe hallucinations"
                >4 - Moderately severe hallucinations</mat-button-toggle
              >
              <mat-button-toggle value="5 - Severe hallucinations"
                >5 - Severe hallucinations</mat-button-toggle
              >
              <mat-button-toggle value="6 - Extremely severe hallucinations"
                >6 - Extremely severe hallucinations</mat-button-toggle
              >
              <mat-button-toggle value="7 - Continuous hallucinations"
                >7 - Continuous hallucinations</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="col-md-2 pad10 mat-elevation-z8" style="padding: 0px">
        <mat-card-header>
          <mat-card-title>
            <span class="asterix-after">Tremor</span>
          </mat-card-title>
          <mat-card-subtitle
            >Arms extended and fingers spread apart.
            Observation.</mat-card-subtitle
          >
        </mat-card-header>
        <mat-card-content>
          <div class="col-sm-12">
            <mat-button-toggle-group
              [disabled]="disableFormElements"
              vertical="true"
              formControlName="tremor"
            >
              <mat-button-toggle value="0 - No tremor"
                >0 - No tremor</mat-button-toggle
              >
              <mat-button-toggle
                value="1 - Not visible but can be felt fingertip to fingertip"
                >1 - Not visible but can be felt fingertip to
                fingertip</mat-button-toggle
              >
              <mat-button-toggle value="2">2</mat-button-toggle>
              <mat-button-toggle value="3">3</mat-button-toggle>
              <mat-button-toggle
                value="4 - Moderate with patient's arms extended"
                >4 - Moderate with patient's arms extended</mat-button-toggle
              >
              <mat-button-toggle value="5">5</mat-button-toggle>
              <mat-button-toggle value="6">6</mat-button-toggle>
              <mat-button-toggle value="7 - Severe even with arms not extended"
                >7 - Severe even with arms not extended</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="col-md-2 pad10 mat-elevation-z8" style="padding: 0px">
        <mat-card-header>
          <mat-card-title>
            <span class="asterix-after">Auditory Disturbances</span>
          </mat-card-title>
          <mat-card-subtitle
            >Ask “Are you more aware of sounds around you? Are they harsh? Do
            they frighten you? Are you hearing anything that is disturbing to
            you? Are you hearing things you know are not there?”
            Observation.</mat-card-subtitle
          >
        </mat-card-header>
        <mat-card-content>
          <div class="col-sm-12">
            <mat-button-toggle-group
              [disabled]="disableFormElements"
              vertical="true"
              formControlName="auditoryDisturbances"
            >
              <mat-button-toggle value="0 - Not present"
                >0 - Not present</mat-button-toggle
              >
              <mat-button-toggle
                value="1 - Very mild harshness or ability to frighten"
                >1 - Very mild harshness or ability to
                frighten</mat-button-toggle
              >
              <mat-button-toggle
                value="2 - Mild harshness or ability to frighten"
                >2 - Mild harshness or ability to frighten</mat-button-toggle
              >
              <mat-button-toggle
                value="3 - Moderate harshness or ability to frighten"
                >3 - Moderate harshness or ability to
                frighten</mat-button-toggle
              >
              <mat-button-toggle value="4 - Moderately severe hallucinations"
                >4 - Moderately severe hallucinations</mat-button-toggle
              >
              <mat-button-toggle value="5 - Severe hallucinations"
                >5 - Severe hallucinations</mat-button-toggle
              >
              <mat-button-toggle value="6 - Extremely severe hallucinations"
                >6 - Extremely severe hallucinations</mat-button-toggle
              >
              <mat-button-toggle value="7 - Continuous hallucinations"
                >7 - Continuous hallucinations</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="col-md-2 pad10 mat-elevation-z8" style="padding: 0px">
        <mat-card-header>
          <mat-card-title>
            <span class="asterix-after">Paroxysmal Sweats</span>
          </mat-card-title>
          <mat-card-subtitle>Observation</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <div class="col-sm-12">
            <mat-button-toggle-group
              [disabled]="disableFormElements"
              vertical="true"
              formControlName="paroxysmalSweats"
            >
              <mat-button-toggle value="0 - No sweat visible"
                >0 - No sweat visible</mat-button-toggle
              >
              <mat-button-toggle
                value="1 - Barely perceptible sweating, palms moist"
                >1 - Barely perceptible sweating, palms moist</mat-button-toggle
              >
              <mat-button-toggle value="2">2</mat-button-toggle>
              <mat-button-toggle value="3">3</mat-button-toggle>
              <mat-button-toggle value="4 - Beads of sweat obvious on forehead"
                >4 - Beads of sweat obvious on forehead</mat-button-toggle
              >
              <mat-button-toggle value="5">5</mat-button-toggle>
              <mat-button-toggle value="6">6</mat-button-toggle>
              <mat-button-toggle value="7 - Drenching sweats"
                >7 - Drenching sweats</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="col-md-2 pad10 mat-elevation-z8" style="padding: 0px">
        <mat-card-header>
          <mat-card-title>
            <span class="asterix-after">Visual Disturbances</span>
          </mat-card-title>
          <mat-card-subtitle
            >Ask “Does the light appear to be too bright? Is its color
            different? Does it hurt your eyes? Are you seeing anything that is
            disturbing to you? Are you seeing things you know are not there?”
            Observation.</mat-card-subtitle
          >
        </mat-card-header>
        <mat-card-content>
          <div class="col-sm-12">
            <mat-button-toggle-group
              [disabled]="disableFormElements"
              vertical="true"
              formControlName="visualDisturbances"
            >
              <mat-button-toggle value="0 - Not present"
                >0 - Not present</mat-button-toggle
              >
              <mat-button-toggle value="1 - Very mild sensitivity"
                >1 - Very mild sensitivity</mat-button-toggle
              >
              <mat-button-toggle value="2 - Mild sensitivity"
                >2 - Mild sensitivity</mat-button-toggle
              >
              <mat-button-toggle value="3 - Moderate sensitivity"
                >3 - Moderate sensitivity</mat-button-toggle
              >
              <mat-button-toggle value="4 - Moderately severe hallucinations"
                >4 - Moderately severe hallucinations</mat-button-toggle
              >
              <mat-button-toggle value="5 - Severe hallucinations"
                >5 - Severe hallucinations</mat-button-toggle
              >
              <mat-button-toggle value="6 - Extremely severe hallucinations"
                >6 - Extremely severe hallucinations</mat-button-toggle
              >
              <mat-button-toggle value="7 - Continuous hallucinations"
                >7 - Continuous hallucinations</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="col-md-2 pad10 mat-elevation-z8" style="padding: 0px">
        <mat-card-header>
          <mat-card-title>
            <span class="asterix-after">Anxiety</span>
          </mat-card-title>
          <mat-card-subtitle
            >Ask “Do you feel nervous?” Observation.</mat-card-subtitle
          >
        </mat-card-header>
        <mat-card-content>
          <div class="col-sm-12">
            <mat-button-toggle-group
              [disabled]="disableFormElements"
              vertical="true"
              formControlName="anxiety"
            >
              <mat-button-toggle value="0 - No anxiety at ease"
                >0 - No anxiety at ease</mat-button-toggle
              >
              <mat-button-toggle value="1 - Mild anxious"
                >1 - Mild anxious</mat-button-toggle
              >
              <mat-button-toggle value="2">2</mat-button-toggle>
              <mat-button-toggle value="3">3</mat-button-toggle>
              <mat-button-toggle
                value="4 - Moderately anxious or guarded so anxiety is inferred"
                >4 - Moderately anxious or guarded so anxiety is
                inferred</mat-button-toggle
              >
              <mat-button-toggle value="5">5</mat-button-toggle>
              <mat-button-toggle value="6">6</mat-button-toggle>
              <mat-button-toggle
                value="7 - Equivalent to acute panic states as seen in severe delirium or acute schizophrenic reactions"
                >7 - Equivalent to acute panic states as seen in severe delirium
                or acute schizophrenic reactions</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="col-md-2 pad10 mat-elevation-z8" style="padding: 0px">
        <mat-card-header>
          <mat-card-title>
            <span class="asterix-after">Headache, Fullness in Head</span>
          </mat-card-title>
          <mat-card-subtitle
            >Ask “Does your head feel different? Does it feel like there is a
            band around your head?” Do not rate for dizziness or
            lightheadedness. Otherwise, rate severity</mat-card-subtitle
          >
        </mat-card-header>
        <mat-card-content>
          <div class="col-sm-12">
            <mat-button-toggle-group
              [disabled]="disableFormElements"
              vertical="true"
              formControlName="headacheFullnessInHead"
            >
              <mat-button-toggle value="0 - No present"
                >0 - No present</mat-button-toggle
              >
              <mat-button-toggle value="1 - Very mild"
                >1 - Very mild</mat-button-toggle
              >
              <mat-button-toggle value="2 - Mild">2 - Mild</mat-button-toggle>
              <mat-button-toggle value="3 - Moderate"
                >3 - Moderate</mat-button-toggle
              >
              <mat-button-toggle value="4 - Moderately severe"
                >4 - Moderately severe</mat-button-toggle
              >
              <mat-button-toggle value="5 - Severe"
                >5 - Severe</mat-button-toggle
              >
              <mat-button-toggle value="6 - Very severe"
                >6 - Very severe</mat-button-toggle
              >
              <mat-button-toggle value="7 - Extremely severe"
                >7 - Extremely severe</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="col-md-2 pad10 mat-elevation-z8" style="padding: 0px">
        <mat-card-header>
          <mat-card-title>
            <span class="asterix-after">Agitation</span>
          </mat-card-title>
          <mat-card-subtitle>Observation</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <div class="col-sm-12">
            <mat-button-toggle-group
              [disabled]="disableFormElements"
              vertical="true"
              formControlName="agitation"
            >
              <mat-button-toggle value="0 - Normal activity"
                >0 - Normal activity</mat-button-toggle
              >
              <mat-button-toggle value="1 - Somewhat more than normal activity"
                >1 - Somewhat more than normal activity</mat-button-toggle
              >
              <mat-button-toggle value="2">2</mat-button-toggle>
              <mat-button-toggle value="3">3</mat-button-toggle>
              <mat-button-toggle value="4 - Moderately fidgety and restless"
                >4 - Moderately fidgety and restless</mat-button-toggle
              >
              <mat-button-toggle value="5">5</mat-button-toggle>
              <mat-button-toggle value="6">6</mat-button-toggle>
              <mat-button-toggle
                value="7 - Paces back and forth during most of the interview or constantly thrashes about"
                >7 - Paces back and forth during most of the interview or
                constantly thrashes about</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="col-md-2 pad10 mat-elevation-z8" style="padding: 0px">
        <mat-card-header>
          <mat-card-title>
            <span class="asterix-after"
              >Orientation and Clouding of Sensorium</span
            >
          </mat-card-title>
          <mat-card-subtitle
            >Ask “What day is this? Where are you? Who am I?”</mat-card-subtitle
          >
        </mat-card-header>
        <mat-card-content>
          <div class="col-sm-12">
            <mat-button-toggle-group
              [disabled]="disableFormElements"
              vertical="true"
              formControlName="orientationAndCloudingOfSensorium"
            >
              <mat-button-toggle
                value="0 - Oriented and can do serial additions"
                class="toggleLabel"
                >0 - Oriented and can do serial additions</mat-button-toggle
              >
              <mat-button-toggle
                value="1 - Cannot do serial additions or is uncertain about date"
                class="toggleLabel"
                >1 - Cannot do serial additions or is uncertain about
                date</mat-button-toggle
              >
              <mat-button-toggle
                value="2 - Disoriented for date by no more than 2 calendar days"
                class="toggleLabel"
                >2 - Disoriented for date by no more than 2 calendar
                days</mat-button-toggle
              >
              <mat-button-toggle
                value="3 - Disoriented for date by more than 2 calendar days"
                class="toggleLabel"
                >3 - Disoriented for date by more than 2 calendar
                days</mat-button-toggle
              >
              <mat-button-toggle
                value="4 - Disoriented for place/or person"
                class="toggleLabel"
                >4 - Disoriented for place/or person</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          ciwaForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!ciwaForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
