import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ITherapyNote } from 'src/app/_models/session/therapy-note';
import { environment } from '../../../environments/environment';
import { DataTablesModel } from '../../_models';

@Injectable({ providedIn: 'root' })
export class TherapyNotesHttpService {
  constructor(private httpClient: HttpClient) { }

  addTherapyNote(therapyNote: ITherapyNote): Observable<ITherapyNote> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<ITherapyNote>(
        `${environment.apiUrl}/therapy-note`,
        therapyNote,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateTherapyNote(therapyNote: ITherapyNote): Observable<ITherapyNote> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<ITherapyNote>(
        `${environment.apiUrl}/therapy-note`,
        therapyNote,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  deleteTherapyNote(
    note: ITherapyNote
  ): Observable<ITherapyNote> {
    return this.httpClient
      .delete<ITherapyNote>(
        `${environment.apiUrl}/therapy-note/` + note.id
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getTherapyNotes(
    patientId: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('patientId', patientId);
    params = params.append('pageSize', pageSize);
    params = params.append('pageIndex', pageIndex);
    params = params.append('order', 'DESC');
    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/therapy-notes`,
      { params: params }
    );
  }
}
