import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { Case, DataTablesModel, Patient } from 'src/app/_models';
import { CaseApiService } from 'src/app/_services';
import { AddEditCaseDialogComponent } from '../../../cases/case-dialogs/case-add-edit/case-add-edit.component';

@Component({
  selector: 'app-member-cases',
  styleUrls: ['case-list.component.css'],
  templateUrl: 'case-list.component.html',
})
export class MemberCasesComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() patient: Patient;
  @Input() selectedCaseId: string;
  @Input() secondaryTab: string;
  @Input() hasCaseViewAccess: boolean;
  @Input() hasClosedCaseViewAccess: boolean;
  @Input() hasCaseAddAccess: boolean;
  @Input() hasCaseEditAccess: boolean;

  @Input() hasCaseTaskViewAccess: boolean;
  @Input() hasCaseTaskAddAccess: boolean;
  @Input() hasCaseTaskEditAccess: boolean;
  @Input() hasCaseTaskDeleteAccess: boolean;

  @Input() hasCaseNotesViewAccess: boolean;
  @Input() hasCaseNotesAddAccess: boolean;
  @Input() hasCaseNotesEditAccess: boolean;
  @Input() hasCaseNotesDeleteAccess: boolean;

  @Input() hasCaseDocumentsViewAccess: boolean;
  @Input() hasCaseDocumentsAddAccess: boolean;
  @Input() hasCaseDocumentsDeleteAccess: boolean;

  @Input() hasCaseLogViewAccess: boolean;
  @Input() hasCaseLogAddAccess: boolean;
  @Input() hasCaseLogEditAccess: boolean;
  @Input() hasCaseLogDeleteAccess: boolean;

  @Input() hasGoalsViewAccess: boolean;
  @Input() hasGoalsAddAccess: boolean;
  @Input() hasGoalsEditAccess: boolean;
  @Input() hasGoalsDeleteAccess: boolean;

  @Input() reloadCasesTab: Subject<boolean> = new Subject<boolean>();
  private subscriptions: Subscription = new Subscription();

  displayedColumns: string[] = ['caseDetails', 'services', 'payor', 'status'];

  data: DataTablesModel = {} as DataTablesModel;
  isLoadingResults = true;
  apiError = false;

  searchCaseId: string = '';
  searchDescription: string = '';
  searchCaseManager: string = '';
  searchStatus: string = 'active';

  constructor(
    public dialog: MatDialog,
    public caseApiService: CaseApiService,
    private router: Router
  ) {}

  ngOnInit() {
    this.data.per_page = 10;
    this.data.page = 0;

    const reloadSubscription = this.reloadCasesTab.subscribe((reloadCases) => {
      if (reloadCases) {
        this.selectedCaseId = null;
      }
    });
    this.subscriptions.add(reloadSubscription);

    this.loadCases();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasCaseEditAccess) {
      if (this.hasCaseEditAccess) {
        if (!this.displayedColumns.includes('actions')) {
          this.displayedColumns.push('actions');
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  addCase() {
    const dialogRef = this.dialog.open(AddEditCaseDialogComponent, {
      data: {
        action: 'ADD',
        case: null,
        patientId: this.patient.id,
        facilityId: this.patient.facilityId,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response && response === 'success') {
        this.loadCases();
      }
    });
  }

  public editCase(currentCase: Case) {
    const dialogRef = this.dialog.open(AddEditCaseDialogComponent, {
      data: {
        action: 'EDIT',
        case: currentCase,
        patientId: this.patient.id,
        facilityId: this.patient.facilityId,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response && response === 'success') {
        this.loadCases();
      }
    });
  }

  public loadCases() {
    this.isLoadingResults = true;
    this.apiError = false;

    this.caseApiService
      .getCases(
        this.patient.id,
        this.searchCaseId,
        this.searchDescription,
        this.searchCaseManager,
        this.searchStatus,
        this.data.per_page,
        this.data.page
      )
      .subscribe({
        next: (response) => {
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }
          this.isLoadingResults = false;
        },
        error: () => {
          this.isLoadingResults = false;
          this.apiError = true;
        },
      });
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadCases();
  }

  setSelectCase(caseId: string) {
    // this.selectedCaseId = caseId;
    this.router.navigate(['/main/member/' + this.patient.id + '/CARE'], {
      queryParams: {
        secondaryId: caseId,
      },
    });
  }

  public onCaseTabEventTriggered(payload: any) {
    if (payload.eventType === 'DESELECT_CASE') {
      this.router.navigate(['/main/member/' + this.patient.id + '/CARE']);
    } else if (payload.eventType === 'DESELECT_RELOAD_CASE') {
      this.router.navigate(['/main/member/' + this.patient.id + '/CARE']);
      this.loadCases();
    }
  }

  searchChanged() {
    this.data.page = 0;
    this.loadCases();
  }

  filterOptionChanged(option) {
    this.searchStatus = option;
    this.data.page = 0;

    this.loadCases();
  }

  getLOS(row) {
    const startDate = new Date(row.admittanceDate);
    const today = new Date();

    startDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    const timeDifference = today.getTime() - startDate.getTime();
    const daysDifference = Math.ceil(
      Math.abs(Math.floor(timeDifference / (1000 * 60 * 60 * 24)))
    );

    if (row.status === 'active') {
      return daysDifference;
    } else {
      if (!row.closeDate) {
        return null;
      }
      const closeDate = new Date(row.closeDate);
      closeDate.setHours(0, 0, 0, 0);
      const timeDifference2 = closeDate.getTime() - startDate.getTime();
      const daysDifference2 = Math.ceil(
        Math.abs(Math.floor(timeDifference2 / (1000 * 60 * 60 * 24)))
      );
      return daysDifference2;
    }
  }

  getEstimatedTitle(row, type) {
    const startDate = new Date(row.admittanceDate);
    const today = new Date();
    const targetDate = new Date(row.expectedDischargeDate);

    startDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    targetDate.setHours(0, 0, 0, 0);
    // Calculate the difference in time and convert to days
    const timeDifference = targetDate.getTime() - today.getTime();
    let daysDifference = Math.ceil(
      Math.abs(Math.floor(timeDifference / (1000 * 60 * 60 * 24)))
    );
    let status: string;
    let outerColor: string;
    let innerColor: string;
    let percentage: string;

    const totalDuration = Math.ceil(
      (targetDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
    );
    const elapsedDays = Math.ceil(
      (today.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
    );
    // Calculate the percentage of time elapsed
    const elapsedPercentage =
      totalDuration > 0 ? (elapsedDays / totalDuration) * 100 : 0;

    // Determine if the date is in the past, today, or the future
    if (targetDate.toDateString() === today.toDateString()) {
      status = 'Days Remaining';
      outerColor = '#78C000';
      innerColor = '#C7E596';
      percentage = '100';
    } else if (targetDate > today) {
      if (daysDifference <= 1) {
        status = 'Day Remaining';
      } else {
        status = 'Days Remaining';
      }

      if (elapsedPercentage <= 30) {
        outerColor = '#78C000';
      } else if (elapsedPercentage <= 60) {
        outerColor = '#fff83a';
      } else if (elapsedPercentage <= 90) {
        outerColor = '#FDB900';
      } else {
        outerColor = '#FF6347';
      }

      innerColor = '#C7E596';
      percentage = elapsedPercentage.toFixed(2);
    } else {
      if (daysDifference <= 1) {
        status = 'Day Ago';
      } else {
        status = 'Days Ago';
      }

      outerColor = '#FF6347';
      innerColor = '#C7E596';
      percentage = '120';
    }
    if (type === 'days') {
      return daysDifference;
    } else if (type === 'colorOuter') {
      return outerColor;
    } else if (type === 'colorInner') {
      return innerColor;
    } else if (type === 'percentage') {
      return percentage;
    } else {
      return status;
    }
  }
}
