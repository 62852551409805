<div class="row">
  <!-- Payment Details -->
  <div class="col-md-4" style="margin-bottom: 15px">
    <div class="columnsGrid">
      <label class="labelHeader">
        <div class="justifyCenterContainer">
          <mat-icon style="margin-right: 10px">paid</mat-icon> Payment Details
        </div>
      </label>
    </div>

    <div class="columnsGrid">
      <label><b>Payment Amount</b></label>
      <label class="labelBold">
        {{ remitDetails?.paymentAmount | currency : 'USD' }}
      </label>
    </div>

    <div class="columnsGrid">
      <label><b>Payment Method</b></label>
      <label class="labelBold">
        ({{ remitDetails?.paymentMethodCode }})
        {{ remitDetails?.paymentMethodDescription }}
      </label>
    </div>

    <div class="columnsGrid">
      <label><b>Payment Reference Number</b></label>
      <label class="labelBold">
        {{ remitDetails?.paymentReferenceNumber }}
      </label>
    </div>
  </div>

  <!-- Payer Details -->
  <div class="col-md-4" style="margin-bottom: 15px">
    <div class="columnsGrid">
      <label class="labelHeader">
        <div class="justifyCenterContainer">
          <mat-icon style="margin-right: 10px">assured_workload</mat-icon>
          Payer Details
        </div>
      </label>
    </div>

    <div class="columnsGrid">
      <label class="labelBold">
        {{ remitDetails?.payerName }}
      </label>
      <label style="margin-top: 8px" *ngIf="remitDetails?.payerAddressLine1">
        <b> {{ remitDetails?.payerAddressLine1 }}</b>
      </label>
      <label *ngIf="remitDetails?.payerAddressLine1">
        <b>
          {{ remitDetails?.payerCity }}, {{ remitDetails?.payerState }},
          {{ remitDetails?.payerZip }}
        </b>
      </label>
    </div>

    <div class="columnsGrid">
      <label><b>Effective Date</b></label>
      <label class="labelBold">
        {{ remitDetails?.paymentEffectiveDate }}
      </label>
    </div>
  </div>

  <!-- Payee Details -->
  <div class="col-md-4" style="margin-bottom: 15px">
    <div class="columnsGrid">
      <label class="labelHeader">
        <div class="justifyCenterContainer">
          <mat-icon style="margin-right: 10px">business</mat-icon>
          Payee Details
        </div>
      </label>
    </div>

    <div class="columnsGrid">
      <label class="labelBold">
        {{ remitDetails?.payeeName }}
      </label>
      <label style="margin-top: 8px" *ngIf="remitDetails?.payeeAddressLine1">
        <b> {{ remitDetails?.payeeAddressLine1 }}</b>
      </label>
      <label *ngIf="remitDetails?.payeeAddressLine1">
        <b>
          {{ remitDetails?.payeeCity }}, {{ remitDetails?.payeeState }},
          {{ remitDetails?.payeeZip }}
        </b>
      </label>

      <label
        *ngIf="
          remitDetails?.status === 'New' ||
          remitDetails?.status === 'Needs Review'
        "
        class="errorDisclaimer"
      >
        <mat-icon>warning</mat-icon>
        Remittance has not been Recorded
      </label>

      <label
        *ngIf="remitDetails?.status === 'Recorded'"
        class="successDisclaimer"
      >
        <mat-icon>assignment_turned_in</mat-icon>
        Remittance has been Recorded
      </label>
    </div>
  </div>
</div>
