import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { MessagingComponent } from './components/messaging/messaging.component';
import { AssessmentCancelledComponent } from './pages/assessment-cancelled/assessment-cancelled.component';
import { AssessmentCompleteComponent } from './pages/assessment-complete/assessment-complete.component';
import { NonProviderDashboardComponent } from './pages/dashboards/non-provider-dashboard/non-provider-dashboard.component';
import { ProviderAllNotesComponent } from './pages/dashboards/provider-dashboard/provider-all-notes/provider-all-notes.component';
import { ProviderDashboardComponent } from './pages/dashboards/provider-dashboard/provider-dashboard.component';
import { StaffSchedulesDashboardComponent } from './pages/dashboards/staff-schedules-dashboard/staff-schedules-dashboard.component';
import { FacilityOTPCodeComponent } from './pages/facility-otpcode/facility-otpcode.component';
import { FormsComponent } from './pages/forms/forms.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { GroupSessionSchedulerComponent } from './pages/schedulers/group-session-scheduler/group-session-scheduler.component';
import { IntakeSessionSchedulerComponent } from './pages/schedulers/intake-session-scheduler/intake-session-scheduler.component';
import { RescheduleAppointmentComponent } from './pages/schedulers/reschedule-appointment/reschedule-appointment.component';
import { SessionSchedulerComponent } from './pages/schedulers/session-scheduler/session-scheduler.component';

const routes: Routes = [
  {
    path: 'guestlink',
    loadChildren: () =>
      import('./pages/guest/guest.module').then((m) => m.GuestModule),
  },
  {
    path: 'facilityOTP',
    component: FacilityOTPCodeComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['inhospitaldevice'],
    },
  },
  {
    path: 'assessmentComplete',
    component: AssessmentCompleteComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['inhospitaldevice'],
    },
  },
  {
    path: 'assessmentCancelled',
    component: AssessmentCancelledComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['inhospitaldevice'],
    },
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        'physician',
        'orgadmin',
        'superorgadmin',
        'careprovider',
        'patient',
      ],
      excludeRoles: ['inhospitaldevice'],
    },
  },
  {
    path: 'patients',
    loadChildren: () =>
      import('./pages/patients/patients.module').then((m) => m.PatientsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'notes/:status',
    component: ProviderAllNotesComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['physician'],
      excludeRoles: ['inhospitaldevice', 'patient'],
    },
  },
  {
    path: 'main',
    loadChildren: () =>
      import('./pages/patient-details/patient-details.module').then(
        (m) => m.PatientDetailModule
      ),
  },
  {
    path: 'ivr',
    loadChildren: () =>
      import('./pages/ivr/ivr.module').then((m) => m.IvrModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'mwv',
    loadChildren: () =>
      import('./pages/mobile-web-view/mobile-web-view.module').then(
        (m) => m.MobileWebViewModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'physician',
    loadChildren: () =>
      import('./pages/physician/physician.module').then(
        (m) => m.PhysicianModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'referral',
    loadChildren: () =>
      import('./pages/referrals/referral.module').then(
        (m) => m.ReferralModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'remittances',
    loadChildren: () =>
      import('./pages/remittances/remittances.module').then(
        (m) => m.RemittancesModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'config',
    loadChildren: () =>
      import('./pages/configurations/configurations.module').then(
        (m) => m.ConfigurationsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'pwotb',
    loadChildren: () =>
      import('./pages/pages-without-top-bar/pages-without-top-bar.module').then(
        (m) => m.PagesWithoutTopBarModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./pages/account/account.module').then((m) => m.AccountModule),
    canActivate: [AuthGuard],
    data: {
      roles: [
        'physician',
        'patient',
        'orgadmin',
        'superorgadmin',
        'careprovider',
      ],
      excludeRoles: ['inhospitaldevice'],
    },
  },
  {
    path: 'forms',
    component: FormsComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['physician', 'orgadmin', 'superorgadmin', 'careprovider'],
      excludeRoles: ['patient', 'inhospitaldevice'],
    },
  },
  {
    path: 'cases',
    loadChildren: () =>
      import('./pages/cases/cases.module').then((m) => m.CasesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'patientDashboard',
    loadChildren: () =>
      import(
        './pages/dashboards/patient-dashboard/patient-dashboard.module'
      ).then((m) => m.PatientDashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'patientSSODashboard',
    loadChildren: () =>
      import(
        './pages/dashboards/patient-dashboard/patient-dashboard.module'
      ).then((m) => m.PatientDashboardModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'provider-dashboard',
    component: ProviderDashboardComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['physician'],
      excludeRoles: ['inhospitaldevice', 'patient'],
    },
  },
  {
    path: 'general-dashboard',
    component: NonProviderDashboardComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['orgadmin', 'superorgadmin', 'careprovider'],
      excludeRoles: ['inhospitaldevice', 'patient', 'physician'],
    },
  },
  {
    path: 'sessionscheduler/:id',
    component: SessionSchedulerComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['physician', 'orgadmin', 'superorgadmin', 'careprovider'],
      excludeRoles: ['inhospitaldevice', 'patient'],
    },
  },
  {
    path: 'schedules/staffs-schedule-dashboard',
    component: StaffSchedulesDashboardComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['orgadmin', 'superorgadmin', 'careprovider'],
      excludeRoles: ['inhospitaldevice', 'patient'],
    },
  },
  {
    path: 'schedules/providerlistscheduler',
    component: IntakeSessionSchedulerComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['physician', 'orgadmin', 'superorgadmin', 'careprovider'],
      excludeRoles: ['inhospitaldevice', 'patient'],
    },
  },
  {
    path: 'schedules/groupsessionscheduler',
    component: GroupSessionSchedulerComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['physician', 'orgadmin', 'superorgadmin', 'careprovider'],
      excludeRoles: ['inhospitaldevice', 'patient'],
    },
  },
  {
    path: 'schedules/groupsessionscheduler/:id',
    component: GroupSessionSchedulerComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['physician', 'orgadmin', 'superorgadmin', 'careprovider'],
      excludeRoles: ['inhospitaldevice', 'patient'],
    },
  },
  {
    path: 'schedules/reschedule/:id',
    component: RescheduleAppointmentComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['physician', 'orgadmin', 'superorgadmin', 'careprovider'],
      excludeRoles: ['inhospitaldevice', 'patient'],
    },
  },
  {
    path: 'messaging',
    component: MessagingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'customform',
    loadChildren: () =>
      import(
        './pages/modules/custom-forms/custom-forms.module'
      ).then((m) => m.CustomFormsModule),
    canActivate: [AuthGuard],
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
