import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

var SockJs = require('sockjs-client');
var Stomp = require('stompjs');

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private audio = new Audio('../../assets/audio/notification-sound.mp3');

  constructor() {
    this.initializeWebSocketConnection();
  }
  public stompClient;
  public msg = [];
  initializeWebSocketConnection() {
    const serverUrl = `${environment.apiUrl}/mw-socket`;
    const ws = new SockJs(serverUrl);
    this.stompClient = Stomp.over(ws);
    const that = this;
    // tslint:disable-next-line:only-arrow-functions
    this.stompClient.connect({}, function (frame) {
      setTimeout(function () {
        that.stompClient.subscribe('/message', function (message) {
          if (message.body) {
            that.msg.push(message.body);
            that.playAudio();
          }
        });
      }, 500);
    });
  }

  sendMessage(message) {
    this.stompClient.send('/app/send/message', {}, message);
  }

  playAudio() {
    this.audio
      .play()
      .then(() => { })
      .catch((error) => {
        console.error('Error playing audio:', error);
      });
  }
}
