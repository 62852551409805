import {
  HttpClient,
  HttpEvent,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UploadFilesService {
  constructor(private httpClient: HttpClient) { }

  upload(
    caseId: string,
    fileType: string,
    file: File,
    additionalInfo: string
  ): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);
    formData.append('fileType', fileType);

    if (additionalInfo) {
      formData.append('additionalInfo', additionalInfo);
    }

    const req = new HttpRequest(
      'POST',
      `${environment.apiUrl}/case/` + caseId + `/documents`,
      formData,
      {
        reportProgress: true,
        responseType: 'json',
      }
    );

    return this.httpClient.request(req);
  }

  uploadMemberDocument(
    patientId: string,
    fileType: string,
    file: File,
    requestId: string,
    hasAttachments: string,
    additionalInfo: string
  ): Observable<any> {
    const formData: FormData = new FormData();

    formData.append('file', file);
    formData.append('fileType', fileType);
    if (requestId) {
      formData.append('requestId', requestId);
    }
    formData.append('hasAttachments', hasAttachments);
    if (additionalInfo) {
      formData.append('additionalInfo', additionalInfo);
    }

    return this.httpClient.post<any>(
      `${environment.apiUrl}/member/` + patientId + `/documents`,
      formData
    );
  }

  uploadMemberAttachments(
    patientId: string,
    fileType: string,
    files: File[],
    parentId: string
  ): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('fileType', fileType);
    files.forEach((file) => {
      formData.append('files', file);
    });
    formData.append('parentId', parentId);

    return this.httpClient.post<any>(
      `${environment.apiUrl}/member/` + patientId + `/documents-multiple`,
      formData,
      {
        reportProgress: false,
        responseType: 'json',
      }
    );
  }

  deleteMemberAttachments(
    patientId: string,
    fileType: string,
    fileName: string,
    requestId: string
  ): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('fileType', fileType);
    formData.append('fileName', fileName);
    formData.append('requestId', requestId);

    return this.httpClient.post<any>(
      `${environment.apiUrl}/member/` + patientId + `/delete-file`,
      formData
    );
  }

  uploadGuestCustomFormAttachments(
    patientId: string,
    fileType: string,
    files: File[],
    parentId: string,
    requestId: string,
    accessCode: string
  ): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('fileType', fileType);
    files.forEach((file) => {
      formData.append('files', file);
    });
    formData.append('parentId', parentId);
    formData.append('requestId', requestId);
    formData.append('accessCode', accessCode);

    return this.httpClient.post<any>(
      `${environment.apiUrl}/guestintake/member/` +
      patientId +
      `/documents-multiple`,
      formData,
      {
        reportProgress: false,
        responseType: 'json',
      }
    );
  }

  uploadSessionDocument(
    sessionId: string,
    sessionCode: string,
    patientId: string,
    fileType: string,
    file: File
  ): Observable<any> {
    const formData: FormData = new FormData();

    formData.append('sessionCode', sessionCode);
    formData.append('patientId', patientId);
    formData.append('file', file);
    formData.append('fileType', fileType);

    return this.httpClient.post<any>(
      `${environment.apiUrl}/session/` + sessionId + `/documents`,
      formData
    );
  }

  getFiles(caseId: string, patientId: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('patientId', patientId);
    return this.httpClient.get(
      `${environment.apiUrl}/case/` + caseId + `/documents/all`,
      { params: params }
    );
  }
}
