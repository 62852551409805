import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ICustomFormVersion } from 'src/app/_models/custom-forms/customformversion.model';
import { IFormResponse } from 'src/app/_models/custom-forms/formresponse.model';
import { CoreService } from 'src/app/_services/core.service';
import { CustomFormApiService } from 'src/app/_services/custom-forms/customforms.service';
import { FormResponseApiService } from 'src/app/_services/custom-forms/formresponse.service';
import { ToastMessageService } from 'src/app/_services/toast-message.service';
import { UploadFilesService } from 'src/app/_services/upload-files.service';
import { environment } from 'src/environments/environment';
import { ComponentCollection, Model, setLicenseKey } from 'survey-core';

@Component({
  selector: 'app-custom-form-request',
  templateUrl: './custom-form-request.component.html',
  styleUrl: './custom-form-request.component.css',
})
export class CustomFormRequestComponent implements OnInit {
  @Input() requestId: string;
  @Input() selectedVersionId: string;
  @Input() displayTitle: boolean = true;

  public isPatient = false;
  model: Model;
  selectedFormId: string;
  customFormVersion: ICustomFormVersion;
  isLoadingResults = false;
  error: string = '';
  processing: boolean = false;
  formResponse: IFormResponse;
  patientId: string;
  hasAttachments: boolean = false;
  formSubmitted: boolean = false;

  constructor(
    public dialog: MatDialog,
    public customformApiService: CustomFormApiService,
    public formResponseApiService: FormResponseApiService,
    private toastMessageService: ToastMessageService,
    private uploadFilesService: UploadFilesService,
    private coreService: CoreService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.isPatient = this.coreService.isPatient();

    setLicenseKey(environment.surveyJsLicenseKey);
    ComponentCollection.Instance.remove("dualSignatures");
    ComponentCollection.Instance.add({
      name: "dualSignatures",
      title: "Dual Signatures",
      iconName: "icon-toolbox-signature-16x16",
      elementsJSON: [
        {
          "type": "panel",
          "name": "panel1",
          visible: true,
          "elements": [
            {
              type: "signaturepad",
              name: "memberonly-signature",
              title: "Member signature",
              minWidth: "256px",
              signatureWidth: 480,
              signatureHeight: 80,
              signatureAutoScaleEnabled: true,
              placeholder: "Member signature",
              visible: true,
            },
            {
              "type": "signaturepad",
              "name": "careprovideronly-signature",
              title: "Care Provider signature",
              "startWithNewLine": false,
              minWidth: "256px",
              signatureWidth: 480,
              signatureHeight: 80,
              signatureAutoScaleEnabled: true,
              placeholder: "Care Provider signature",
              visible: true,
            }
          ]
        }
      ]
    });

    this.model = new Model();

    if (!this.requestId) {
      this.requestId = this.route.snapshot.paramMap.get('requestId');
    }
    if (!this.selectedVersionId) {
      this.selectedVersionId = this.route.snapshot.paramMap.get('formVersionId');
    }
    this.patientId = this.coreService.getPatientId();
    this.loadSurveyJson(this.selectedVersionId);
  }

  public loadSurveyJson(formVersionId: string) {
    this.isLoadingResults = true;
    this.error = '';

    this.customformApiService.getFormVersion(formVersionId).subscribe(
      (response) => {
        this.isLoadingResults = false;
        if (response) {
          this.model = new Model(response.data.formDefinition);
          this.model.allowEditSurveyTitle = false;
          this.model.JSON = response.data.formDefinition;

          this.formResponse = Object.assign({}, this.formResponse);
          this.formResponse.formVersionId = formVersionId;
          this.formResponse.versionNumber = response.data.versionNumber;
          this.formResponse.formName = response.data.formName;

          if (this.isPatient) {
            this.model.onAfterRenderQuestion.add((sender, options) => {
              const question = options.question;
              if (question.name.startsWith("careprovideronly-")) {
                question.readOnly = true;
              }
            });
          }

          if (!this.isPatient) {
            this.model.onAfterRenderQuestion.add((sender, options) => {
              const question = options.question;
              if (question.name.startsWith("memberonly-")) {
                question.readOnly = true;
              }
            });
          }


          this.model.onComplete.add((sender, options) => {
            this.formResponse.payload = sender.data;
            this.formResponseApiService
              .addFormResponse(
                this.requestId,
                this.formResponse,
                this.hasAttachments
              )
              .subscribe({
                next: (response) => {
                  this.processing = false;
                  this.toastMessageService.displaySuccessMessage(
                    'Successfully submitted the form.'
                  );
                  this.model.data = sender.data;
                  this.formSubmitted = true;
                },
                error: (error) => {
                  this.processing = false;
                  this.toastMessageService.displayErrorMessage(
                    'Error: Something went wrong. Please refresh the page.'
                  );
                },
              });
          });

          this.model.onUploadFiles.add((survey, options) => {
            this.hasAttachments = true;
            this.uploadFilesService
              .uploadMemberAttachments(
                this.patientId,
                'Member Document',
                options.files,
                this.requestId
              )
              .subscribe({
                next: (response) => {
                  // Pass the uploaded file's metadata back to SurveyJS
                  const uploadedFiles = Object.keys(response).map((filename) => {
                    return {
                      file: filename, // Use the unique identifier as the file value
                      content: `/member/${this.patientId}/document/${response[filename]}`,
                    };
                  });

                  options.callback('success', uploadedFiles);

                  // Update the SurveyJS data to include the file information
                  const currentFiles = survey.getValue(options.name) || [];
                  survey.setValue(options.name, [...uploadedFiles]);

                },
                error: (error) => {
                  options.callback('error');
                },
              });
          });

          this.model.onClearFiles.add(async (_, options) => {
            if (!options.value || options.value.length === 0)
              return options.callback("success");
            if (!options.fileName && !!options.value) {
              for (const item of options.value) {

                this.uploadFilesService.deleteMemberAttachments(
                  this.patientId,
                  'Member Document',
                  item.file,
                  this.requestId
                ).subscribe();;
                options.callback("success");
                // await deleteFile(item.content, options);
              }
            } else {
              const fileToRemove = options.value.find(
                (item) => item.name === options.fileName
              );
              if (fileToRemove) {
                this.uploadFilesService.deleteMemberAttachments(
                  this.patientId,
                  'Member Document',
                  fileToRemove.file,
                  this.requestId
                ).subscribe();

                options.callback("success");
                // await deleteFile(fileToRemove.content, options);
              } else {
                console.error(`File with name ${options.fileName} is not found`);
                options.callback("error");
              }
            }
          });
        }
      },
      (error) => {
        this.error = error;
        this.isLoadingResults = false;
      }
    );
  }

  closeScreen() {
    this.router.navigate(['/']);
  }
}