<div class="dialogTitle" style="padding-left: 10px; padding-right: 10px">
  <p matDialogTitle>
    {{
      selectedAssessment
        ? selectedAssessment
        : 'Assessment
    Form'
    }}
  </p>
  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<!-- Addiction Assessments -->
<app-asi-home
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Addiction Severity Index (ASI)'"
>
</app-asi-home>

<app-internet-addiction
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Internet Addiction'"
>
</app-internet-addiction>
<app-alcohol-addiction
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Alcohol Addiction'"
>
</app-alcohol-addiction>
<app-cssrs
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="
    selectedAssessment === 'Columbia-Suicide Severity Rating Scale (CSSRS)'
  "
>
</app-cssrs>
<app-cows
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Clinical Opiate Withdrawal Scale (COWS)'"
>
</app-cows>
<app-ciwa
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="
    selectedAssessment ===
    'Clinical Institute Withdrawal Assessment of Alcohol Scale, Revised (CIWA-AR)'
  "
>
</app-ciwa>
<app-gambling-addiction
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Gambling Disorders'"
>
</app-gambling-addiction>
<app-gaming-addiction
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Gaming Addiction'"
>
</app-gaming-addiction>
<app-sexual-addiction
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Sexual Addiction'"
>
</app-sexual-addiction>
<app-shopping-addiction
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Shopping Addiction'"
>
</app-shopping-addiction>
<app-dsm5-level2-substance-use
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'DSM-5-TR Level 2 - Substance Use | Age 11-17'"
>
</app-dsm5-level2-substance-use>
<app-taps-assessment
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'TAPS-I & TAPS-II'"
>
</app-taps-assessment>

<!-- ADHD Assessments -->
<app-adhd-diagnosis-rating
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="
    selectedAssessment === 'Vanderbilt ADHD Diagnostic Rating Scale (VADRS)'
  "
></app-adhd-diagnosis-rating>
<app-self-report-scale
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Self-Report Scale'"
></app-self-report-scale>
<app-attention-awareness-scale
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'The Mindful Attention Awareness Scale (MAAS)'"
>
</app-attention-awareness-scale>

<!-- Aggression Assessments -->
<app-aggression-questionnaire
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Aggression Questionnaire'"
></app-aggression-questionnaire>

<!-- Anxiety Assessments -->
<app-childrens-anxiety
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Spence Children\'s Anxiety Scale (SCAS)'"
></app-childrens-anxiety>
<app-generalized-anxiety
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="
    selectedAssessment === 'Generalized Anxiety Disorder 7 Item Scale (GAD-7)'
  "
></app-generalized-anxiety>
<app-hamilton-anxiety
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Hamilton Anxiety Rating Scale (HAM-A)'"
></app-hamilton-anxiety>
<app-panic-agoraphobia
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Panic and Agoraphobia Scale (PAS)'"
></app-panic-agoraphobia>
<app-penn-state-worry
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Penn State Worry Questionnaire (PSWQ)'"
></app-penn-state-worry>
<app-short-health-anxiety
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Short Health Anxiety Inventory (HAI-18)'"
></app-short-health-anxiety>
<app-social-phobia
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Social Phobia Inventory (SPIN)'"
></app-social-phobia>
<app-taylor-manifest
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Taylor Manifest Anxiety Scale (TMAS)'"
></app-taylor-manifest>
<app-zung-self-rating-scale-dialog
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Zung Self-Rating Anxiety Scale (SAS)'"
>
</app-zung-self-rating-scale-dialog>

<!-- Autism Assessments -->
<app-autism-spectrum-quotient
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Autism Spectrum Quotient (AQ)'"
></app-autism-spectrum-quotient>
<app-autism-spectrum-screening
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="
    selectedAssessment === 'Autism Spectrum Screening Questionnaire (ASSQ)'
  "
></app-autism-spectrum-screening>
<app-childhood-autism-spectrum
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Childhood Autism Spectrum Test (CAST)'"
></app-childhood-autism-spectrum>
<app-infant-toddler
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Infant-Toddler Checklist (ITC)'"
></app-infant-toddler>
<app-quantitative-checklist
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="
    selectedAssessment ===
    'Quantitative Checklist for Autism in Toddlers (Q-CHAT)'
  "
></app-quantitative-checklist>
<app-ritvo-asperger
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="
    selectedAssessment === 'Ritvo Autism & Asperger Diagnostic Scale (RAADS-14)'
  "
></app-ritvo-asperger>

<!-- Bipolar Assessments -->
<app-altman-mania
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Altman Self-Rating Mania Scale (ASRM)'"
></app-altman-mania>
<app-young-mania
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Young Mania Rating Scale (YMRS)'"
></app-young-mania>

<!-- Depression Assessments -->
<app-patient-health-questionnaire-dialog
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="selectedAssessment === 'Patient Health Questionnaire (PHQ-9)'"
>
</app-patient-health-questionnaire-dialog>
<app-geriatric-depression-scale-dialog
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Geriatric Depression Scale (GDS)'"
>
</app-geriatric-depression-scale-dialog>
<app-adolescent-depression
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="
    selectedAssessment ===
    'Kutcher Adolescent Depression Scale - 11-Item (KADS-11)'
  "
></app-adolescent-depression>
<app-major-depression
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Major Depression Inventory (MDI)'"
></app-major-depression>
<app-postnatal-depression
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Edinburgh Postnatal Depression Scale (EPDS)'"
></app-postnatal-depression>
<app-zung-depression-scale
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Zung Self-Rating Depression Scale (SDS)'"
></app-zung-depression-scale>

<!-- Eating-Disorder Assessments -->
<app-binge-eating-scale
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Binge Eating Scale (BES)'"
></app-binge-eating-scale>
<app-eating-attitudes
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Eating Attitudes Test'"
></app-eating-attitudes>

<!-- General Assessments -->
<app-cross-cutting-level-one-adult
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'DSM 5 Level 1 Adult Assessment'"
>
</app-cross-cutting-level-one-adult>
<app-cross-cutting-level-one-eleven-to-seventeen
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'DSM 5 Level 1 (11-17) Assessment'"
></app-cross-cutting-level-one-eleven-to-seventeen>
<app-cross-cutting-level-one-six-to-seventeen
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'DSM 5 Level 1 (6-17) Assessment'"
></app-cross-cutting-level-one-six-to-seventeen>
<app-core10-assessment-dialog
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'CORE 10 Assessment'"
>
</app-core10-assessment-dialog>
<app-ypcore10-assessment-dialog
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'YP-CORE 10 Assessment'"
>
</app-ypcore10-assessment-dialog>
<app-cdrisc-assessment-dialog
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Connor-Davidson Resilience Scale(CD-RISC 10)'"
>
</app-cdrisc-assessment-dialog>
<app-survey-wellbeing-of-young-children
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Survey of Wellbeing of Young Children'"
>
</app-survey-wellbeing-of-young-children>
<app-differention-of-self-inventory
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="
    selectedAssessment === 'Differentiation of Self Inventory - Short Form'
  "
>
</app-differention-of-self-inventory>

<!-- OCD Assessments -->
<app-obsessive-compulsive-inventory
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="
    selectedAssessment === 'Obsessive Compulsive Inventory - Revised (OCI-R)'
  "
></app-obsessive-compulsive-inventory>

<!-- Personality Assessments -->
<app-big-five
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Big 5 Personality Test (BFPT)'"
></app-big-five>
<app-empathy-quotient
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Empathy Quotient (EQ)'"
></app-empathy-quotient>
<app-hare-psychopathy
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="
    selectedAssessment === 'Hare Psychopathy Checklist (Original) (PCL-22)'
  "
></app-hare-psychopathy>
<app-mystical-experience
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="
    selectedAssessment === 'Revised Mystical Experience Questionnaire (MEQ-30)'
  "
></app-mystical-experience>
<app-personality-type
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Personality Type Indicator (PTI)'"
></app-personality-type>
<app-toronto-empathy-dialog
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Toronto Empathy Questionnaire (TEQ)'"
>
</app-toronto-empathy-dialog>

<!-- Realtionship/Family Assessments -->
<app-aas-assessment-dialog
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Adult Attachment Scale (AAS)'"
>
</app-aas-assessment-dialog>
<app-aces-assessment-dialog
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Aversive Childhood Experiences Scale (ACES)'"
></app-aces-assessment-dialog>
<app-cprs-assessment-dialog
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Child-Parent Relationship Scale (CPRS)'"
></app-cprs-assessment-dialog>
<app-apgar-assessment-dialog
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Family APGAR'"
></app-apgar-assessment-dialog>
<app-csi-assessment-dialog
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'The Couples Satisfaction Index (CSI)'"
>
</app-csi-assessment-dialog>

<!-- Stress Assessments -->
<app-complicated-grief
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Inventory of Complicated Grief (ICG)'"
></app-complicated-grief>
<app-ptsd-screening
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Primary Care PTSD Screen (DSM-5) (PC-PTSD-5)'"
></app-ptsd-screening>
<app-trauma-related-shame
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Trauma-Related Shame Inventory (TRSI-24)'"
></app-trauma-related-shame>

<!-- Therapeutic Alliance Assessments -->
<app-srs-assessment-dialog
  [displayTitle]="false"
  [assessmentRequestId]="requestId"
  (taskCompleted)="taskCompleted($event)"
  [patientId]="patientId"
  [sessionId]="sessionId"
  [assessmentType]="selectedAssessment"
  [accessCode]="accessCode"
  *ngIf="selectedAssessment === 'Session Rating Scale (SRS)'"
></app-srs-assessment-dialog>
