import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IReleaseMedicalRecords } from 'src/app/_models/session/intake/releasemedicalrecords.model';
import { environment } from '../../../environments/environment';
import {
  DataTablesModel,
  IAcknowledgeAlerts,
  IMemberConsentDocument,
  ResponseModal,
} from '../../_models';

@Injectable({ providedIn: 'root' })
export class ConsentDocumentApiService {
  constructor(private httpClient: HttpClient) {}

  getOrganizationAllConsentDocuments(): Observable<DataTablesModel> {
    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/getallactiveorgconsentdocuments`
    );
  }

  getOrganizationAllIntakeDocuments(): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/getmemberintakedocumentslist`
    );
  }

  getMyIntakeToDoList(): Observable<DataTablesModel> {
    let params = new HttpParams();

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/myintaketodolist`,
      { params: params }
    );
  }

  getMemberToDoList(memberId: string): Observable<DataTablesModel> {
    let params = new HttpParams();

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/memberintaketodolist/${memberId}`,
      { params: params }
    );
  }

  previewConsentDocument(documentId: string): any {
    return this.httpClient
      .get(
        `${environment.apiUrl}/orgconsentdocument-pdf-preview?consentDocumentId=${documentId}`,
        { responseType: 'blob' }
      )
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  previewConsentDocumentForGuest(
    requestId: string,
    accessCode: string,
    consentDocumentId: string
  ): any {
    let params = new HttpParams();

    params = params.append('requestId', requestId);
    params = params.append('accessCode', accessCode);
    params = params.append('consentDocumentId', consentDocumentId);

    return this.httpClient
      .get(`${environment.apiUrl}/guestintake/orgconsentdocument`, {
        responseType: 'blob',
        params: params,
      })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  getSignedConsentDocument(consentRequestId: string): any {
    return this.httpClient
      .get(
        `${environment.apiUrl}/membersignedconsentdocument/${consentRequestId}`,
        { responseType: 'blob' }
      )
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  getMemberCompletedConsentDocuments(
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();

    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/memberfindmycompletedconsentdocuments`,
      { params: params }
    );
  }

  getMemberConsentDocuments(
    memberId: string,
    status: string,
    searchDocumentName: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();

    if (status) params = params.append('status', status);
    if (searchDocumentName)
      params = params.append('documentName', searchDocumentName);

    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/memberconsentdocumentslist/${memberId}`,
      { params: params }
    );
  }

  requestNewConsentDocument(documentsList: any, memberId: string) {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/requestnewconsentdocument/${memberId}`,
        documentsList,
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  addNewMemberAlert(memberAlert: IMemberConsentDocument) {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient
      .post<any>(`${environment.apiUrl}/memberalerts`, memberAlert, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateMemberAlert(memberAlert: IMemberConsentDocument) {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient
      .put<any>(`${environment.apiUrl}/memberalerts`, memberAlert, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  deleteMemberAlert(id: string) {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient
      .delete<any>(`${environment.apiUrl}/memberalerts/${id}`, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  acceptRejectConsentDocument(id: string, option: boolean) {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/verifysubmittedconsentdocument/${id}/${option}`,
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  resendIntakeEmail(memberId: string) {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient
      .post<any>(`${environment.apiUrl}/resendintakeemail/${memberId}`, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  memberSubmitReleaseOfInformation(
    consentRequestId: string,
    releaseOfMedicalRecords: IReleaseMedicalRecords,
    file: File
  ): Observable<any> {
    const formData: FormData = new FormData();

    formData.append(
      'releaseOfMedicalRecords',
      new Blob([JSON.stringify(releaseOfMedicalRecords)], {
        type: 'application/json',
      })
    );
    formData.append('file', file);

    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/memberupdatereleaseofinformation/${consentRequestId}`,
        formData
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  guestSubmitReleaseOfInformation(
    requestId: string,
    accessCode: string,
    consentRequestId: string,
    releaseOfMedicalRecords: IReleaseMedicalRecords,
    file: File
  ): Observable<any> {
    const formData: FormData = new FormData();

    formData.append(
      'releaseOfMedicalRecords',
      new Blob([JSON.stringify(releaseOfMedicalRecords)], {
        type: 'application/json',
      })
    );
    formData.append('file', file);

    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/guestintake/addreleaseofinformation/${requestId}/${accessCode}/${consentRequestId}`,
        formData
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  acknowledgePatientAlerts(memberAlert: IAcknowledgeAlerts) {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient.post<any>(
      `${environment.apiUrl}/acknowledgealerts`,
      memberAlert,
      {
        headers,
      }
    );
  }
}
