import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { ResponseModal } from 'src/app/_models';
import { IAsiAlcoholDrugs } from 'src/app/_models/assessments/asi/asialcoholdrugs.model';
import { IAsiEmploymentSupport } from 'src/app/_models/assessments/asi/asiemploymentsupport.model';
import { IAsiFamilyHistory } from 'src/app/_models/assessments/asi/asifamilyhistory.model';
import { IAsiFamilySocialRelationships } from 'src/app/_models/assessments/asi/asifamilysocialrelationships.model';
import { IAsiGeneral } from 'src/app/_models/assessments/asi/asigeneral.model';
import { IAsiLegal } from 'src/app/_models/assessments/asi/asilegal.model';
import { IAsiMedical } from 'src/app/_models/assessments/asi/asimedical.model';
import { IAsiPsychiatric } from 'src/app/_models/assessments/asi/asipsychiatric.model';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class AsiApiService {
    constructor(private httpClient: HttpClient) { }
    getAsiDetails(asiId: string): Observable<ResponseModal> {
        return this.httpClient.get<ResponseModal>(`${environment.apiUrl}/asiassessment/` + asiId);
    }

    updateAsiAlcoholDrugs(thisAsiAlcoholDrugs: IAsiAlcoholDrugs): Observable<IAsiAlcoholDrugs> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.put<IAsiAlcoholDrugs>(`${environment.apiUrl}/asialcoholdrugs`, thisAsiAlcoholDrugs, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }

    updateAsiEmploymentSupport(thisAsiEmploymentSupport: IAsiEmploymentSupport): Observable<IAsiEmploymentSupport> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.put<IAsiEmploymentSupport>(`${environment.apiUrl}/asiemploymentsupport`, thisAsiEmploymentSupport, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }

    updateAsiFamilyHistory(thisAsiFamilyHistory: IAsiFamilyHistory): Observable<IAsiFamilyHistory> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.put<IAsiFamilyHistory>(`${environment.apiUrl}/asifamilyhistory`, thisAsiFamilyHistory, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }

    updateAsiFamilySocialRelationships(thisAsiFamilySocialRelationships: IAsiFamilySocialRelationships): Observable<IAsiFamilySocialRelationships> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.put<IAsiFamilySocialRelationships>(`${environment.apiUrl}/asifamilysocialrelationships`, thisAsiFamilySocialRelationships, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }

    updateAsiGeneral(thisAsiGeneral: IAsiGeneral): Observable<IAsiGeneral> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.put<IAsiGeneral>(`${environment.apiUrl}/asigeneral`, thisAsiGeneral, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }

    updateAsiLegal(thisAsiLegal: IAsiLegal): Observable<IAsiLegal> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.put<IAsiLegal>(`${environment.apiUrl}/asilegal`, thisAsiLegal, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }

    updateAsiMedical(thisAsiMedical: IAsiMedical): Observable<IAsiMedical> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.put<IAsiMedical>(`${environment.apiUrl}/asimedical`, thisAsiMedical, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }

    updateAsiPsychiatric(thisAsiPsychiatric: IAsiPsychiatric): Observable<IAsiPsychiatric> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.put<IAsiPsychiatric>(`${environment.apiUrl}/asipsychiatric`, thisAsiPsychiatric, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }
}