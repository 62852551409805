import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ITherapySession } from 'src/app/_models';
import { TherapySessionService, ToastMessageService } from 'src/app/_services';

@Component({
  selector: 'app-group-session-print-notes',
  templateUrl: './group-session-print-notes.component.html',
  styleUrl: './group-session-print-notes.component.css',
})
export class GroupSessionPrintNotesComponent implements OnInit {
  @ViewChild('pdfViewerOnDemand', { static: false }) pdfViewerOnDemand;
  @ViewChild(MatStepper) stepper: MatStepper;

  // Input
  sessionDetails: ITherapySession;
  allMembers = [];
  membersSelectionList = [];
  searchMember: string = '';

  // Print Configuration
  public printForm: FormGroup;
  processing: boolean = false;

  // Pdf document
  isLoadingPDF: boolean = false;
  pdfViewOpen: boolean = false;
  pdfSrc = null;

  constructor(
    public dialogRef: MatDialogRef<GroupSessionPrintNotesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public therapysessionApiService: TherapySessionService,
    private toastMessageService: ToastMessageService
  ) {
    this.sessionDetails = data.sessionDetails;
  }

  ngOnInit(): void {
    this.allMembers = this.sessionDetails.groupMembers;
    this.membersSelectionList = this.sessionDetails.groupMembers;

    this.buildForm();
  }

  buildForm() {
    this.printForm = this.formBuilder.group({
      selectedMemberId: new FormControl('', Validators.required),
      printType: new FormControl('', Validators.required),
      startDate: new FormControl(''),
      endDate: new FormControl(''),
    });
  }

  printTypeChanged(event) {
    if (event.value === 'Custom') {
      // Add in the validators
      this.printForm.get('startDate').addValidators(Validators.required);
      this.printForm.get('startDate').updateValueAndValidity();

      this.printForm.get('endDate').addValidators(Validators.required);
      this.printForm.get('endDate').updateValueAndValidity();
    } else {
      // Remove the validators
      this.printForm.get('startDate').setValue(null);
      this.printForm.get('startDate').clearValidators();
      this.printForm.get('startDate').updateValueAndValidity();

      this.printForm.get('endDate').setValue(null);
      this.printForm.get('endDate').clearValidators();
      this.printForm.get('endDate').updateValueAndValidity();
    }
  }

  filterMember() {
    if (this.searchMember) {
      this.membersSelectionList = this.allMembers.filter((member) => {
        const lastName = member.member.lastName.toLowerCase();
        const firstName = member.member.firstName.toLowerCase();
        const concatFirstLast = firstName + ' ' + lastName;
        const concatLastFirst = lastName + ' ' + firstName;
        if (
          concatFirstLast.includes(this.searchMember.toLowerCase()) ||
          concatLastFirst.includes(this.searchMember.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.membersSelectionList = [...this.allMembers];
    }
  }

  generatePDF() {
    this.processing = true;
    this.isLoadingPDF = true;
    this.pdfViewOpen = false;

    this.therapysessionApiService
      .viewGroupMemberNotes(
        this.sessionDetails.id,
        this.printForm.controls['selectedMemberId'].value,
        this.printForm.controls['printType'].value,
        this.printForm.controls['startDate'].value,
        this.printForm.controls['endDate'].value
      )
      .subscribe({
        next: (response) => {
          if (response) {
            this.pdfSrc = response;
            this.pdfViewOpen = true;

            setTimeout(() => {
              this.pdfViewerOnDemand.refresh();
              this.isLoadingPDF = false;
            }, 400);
          } else {
            this.pdfViewOpen = true;
            this.pdfSrc = null;

            setTimeout(() => {
              this.pdfViewerOnDemand.refresh();
              this.isLoadingPDF = false;
            }, 400);
          }

          this.processing = false;
        },
        error: (error) => {
          this.isLoadingPDF = false;
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to generate the note view'
          );
        },
      });
  }
}
