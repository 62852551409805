<mat-card-title>Alcohol / Drugs</mat-card-title>
<mat-divider></mat-divider>

<div style="margin-top: 40px; padding: 10px">
  <form [formGroup]="asialcoholdrugsForm" novalidate (ngSubmit)="onAsiAlcoholDrugsFormSubmit()">
    <div class="row">
      <!-- Questions Box -->
      <div class="col-sm-12 col-xl-8">
        <mat-card appearance="raised" class="mat-elevation-z8">
          <!-- Template -->
          <div class="row" style="margin-bottom: 20px">
            <div class="col-3"></div>
            <div class="col-3">
              <label class="labelHeader">A. Past 30 Days</label>
            </div>
            <div class="col-3">
              <label class="labelHeader">B. Lifetime (Years)</label>
            </div>
            <div class="col-3">
              <label class="labelHeader">C. Route of Admin</label>
            </div>
          </div>

          <!-- Questions -->
          <div class="row">
            <div class="col-3">
              <label class="labelText breakWord">
                D1. Alcohol (any use at all)
                <span style="color: red">*</span>
              </label>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="d1_past30" name="d1_past30" required />
                <mat-error *ngFor="let validation of validation_messages.d1_past30">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d1_past30')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d1_past30').dirty ||
                        asialcoholdrugsForm.get('d1_past30').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="d1_lifetime" name="d1_lifetime" required />
                <mat-error *ngFor="let validation of validation_messages.d1_lifetime">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d1_lifetime')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d1_lifetime').dirty ||
                        asialcoholdrugsForm.get('d1_lifetime').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3"></div>
          </div>

          <div class="row">
            <div class="col-3">
              <label class="labelText breakWord">
                D2. Alcohol (to intoxication)
                <span style="color: red">*</span>
              </label>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="d2_past30" name="d2_past30" required />
                <mat-error *ngFor="let validation of validation_messages.d2_past30">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d2_past30')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d2_past30').dirty ||
                        asialcoholdrugsForm.get('d2_past30').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="d2_lifetime" name="d2_lifetime" required />
                <mat-error *ngFor="let validation of validation_messages.d2_lifetime">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d2_lifetime')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d2_lifetime').dirty ||
                        asialcoholdrugsForm.get('d2_lifetime').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3"></div>
          </div>

          <div class="row">
            <div class="col-3">
              <label class="labelText breakWord">
                D3. Heroin
                <span style="color: red">*</span>
              </label>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="d3_past30" name="d3_past30" required />
                <mat-error *ngFor="let validation of validation_messages.d3_past30">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d3_past30')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d3_past30').dirty ||
                        asialcoholdrugsForm.get('d3_past30').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="d3_lifetime" name="d3_lifetime" required />
                <mat-error *ngFor="let validation of validation_messages.d3_lifetime">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d3_lifetime')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d3_lifetime').dirty ||
                        asialcoholdrugsForm.get('d3_lifetime').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <mat-select formControlName="d3_route">
                  <mat-option value="Oral">Oral</mat-option>
                  <mat-option value="Nasal">Nasal</mat-option>
                  <mat-option value="Smoking">Smoking</mat-option>
                  <mat-option value="Non-IV injection">Non-IV injection</mat-option>
                  <mat-option value="IV">IV</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-3">
              <label class="labelText breakWord">
                D4. Methadone
                <span style="color: red">*</span>
              </label>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="d4_past30" name="d4_past30" required />
                <mat-error *ngFor="let validation of validation_messages.d4_past30">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d4_past30')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d4_past30').dirty ||
                        asialcoholdrugsForm.get('d4_past30').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="d4_lifetime" name="d4_lifetime" required />
                <mat-error *ngFor="let validation of validation_messages.d4_lifetime">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d4_lifetime')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d4_lifetime').dirty ||
                        asialcoholdrugsForm.get('d4_lifetime').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <mat-select formControlName="d4_route">
                  <mat-option value="Oral">Oral</mat-option>
                  <mat-option value="Nasal">Nasal</mat-option>
                  <mat-option value="Smoking">Smoking</mat-option>
                  <mat-option value="Non-IV injection">Non-IV injection</mat-option>
                  <mat-option value="IV">IV</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-3">
              <label class="labelText breakWord">
                D5. Other Opiates/Analgesics
                <span style="color: red">*</span>
              </label>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="d5_past30" name="d5_past30" required />
                <mat-error *ngFor="let validation of validation_messages.d5_past30">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d5_past30')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d5_past30').dirty ||
                        asialcoholdrugsForm.get('d5_past30').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="d5_lifetime" name="d5_lifetime" required />
                <mat-error *ngFor="let validation of validation_messages.d5_lifetime">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d5_lifetime')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d5_lifetime').dirty ||
                        asialcoholdrugsForm.get('d5_lifetime').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <mat-select formControlName="d5_route">
                  <mat-option value="Oral">Oral</mat-option>
                  <mat-option value="Nasal">Nasal</mat-option>
                  <mat-option value="Smoking">Smoking</mat-option>
                  <mat-option value="Non-IV injection">Non-IV injection</mat-option>
                  <mat-option value="IV">IV</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-3">
              <label class="labelText breakWord">
                D6. Barbiturates
                <span style="color: red">*</span>
              </label>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="d6_past30" name="d6_past30" required />
                <mat-error *ngFor="let validation of validation_messages.d6_past30">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d6_past30')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d6_past30').dirty ||
                        asialcoholdrugsForm.get('d6_past30').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="d6_lifetime" name="d6_lifetime" required />
                <mat-error *ngFor="let validation of validation_messages.d6_lifetime">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d6_lifetime')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d6_lifetime').dirty ||
                        asialcoholdrugsForm.get('d6_lifetime').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <mat-select formControlName="d6_route">
                  <mat-option value="Oral">Oral</mat-option>
                  <mat-option value="Nasal">Nasal</mat-option>
                  <mat-option value="Smoking">Smoking</mat-option>
                  <mat-option value="Non-IV injection">Non-IV injection</mat-option>
                  <mat-option value="IV">IV</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-3">
              <label class="labelText breakWord">
                D7. Other Sedatives/Hypnotics/Tranquilizers
                <span style="color: red">*</span>
              </label>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="d7_past30" name="d7_past30" required />
                <mat-error *ngFor="let validation of validation_messages.d7_past30">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d7_past30')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d7_past30').dirty ||
                        asialcoholdrugsForm.get('d7_past30').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="d7_lifetime" name="d7_lifetime" required />
                <mat-error *ngFor="let validation of validation_messages.d7_lifetime">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d7_lifetime')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d7_lifetime').dirty ||
                        asialcoholdrugsForm.get('d7_lifetime').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <mat-select formControlName="d7_route">
                  <mat-option value="Oral">Oral</mat-option>
                  <mat-option value="Nasal">Nasal</mat-option>
                  <mat-option value="Smoking">Smoking</mat-option>
                  <mat-option value="Non-IV injection">Non-IV injection</mat-option>
                  <mat-option value="IV">IV</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-3">
              <label class="labelText breakWord">
                D8. Cocaine
                <span style="color: red">*</span>
              </label>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="d8_past30" name="d8_past30" required />
                <mat-error *ngFor="let validation of validation_messages.d8_past30">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d8_past30')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d8_past30').dirty ||
                        asialcoholdrugsForm.get('d8_past30').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="d8_lifetime" name="d8_lifetime" required />
                <mat-error *ngFor="let validation of validation_messages.d8_lifetime">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d8_lifetime')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d8_lifetime').dirty ||
                        asialcoholdrugsForm.get('d8_lifetime').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <mat-select formControlName="d8_route">
                  <mat-option value="Oral">Oral</mat-option>
                  <mat-option value="Nasal">Nasal</mat-option>
                  <mat-option value="Smoking">Smoking</mat-option>
                  <mat-option value="Non-IV injection">Non-IV injection</mat-option>
                  <mat-option value="IV">IV</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-3">
              <label class="labelText breakWord">
                D9. Amphetamines
                <span style="color: red">*</span>
              </label>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="d9_past30" name="d9_past30" required />
                <mat-error *ngFor="let validation of validation_messages.d9_past30">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d9_past30')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d9_past30').dirty ||
                        asialcoholdrugsForm.get('d9_past30').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="d9_lifetime" name="d9_lifetime" required />
                <mat-error *ngFor="let validation of validation_messages.d9_lifetime">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d9_lifetime')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d9_lifetime').dirty ||
                        asialcoholdrugsForm.get('d9_lifetime').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <mat-select formControlName="d9_route">
                  <mat-option value="Oral">Oral</mat-option>
                  <mat-option value="Nasal">Nasal</mat-option>
                  <mat-option value="Smoking">Smoking</mat-option>
                  <mat-option value="Non-IV injection">Non-IV injection</mat-option>
                  <mat-option value="IV">IV</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-3">
              <label class="labelText breakWord">
                D10. Cannabis
                <span style="color: red">*</span>
              </label>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="d10_past30" name="d10_past30" required />
                <mat-error *ngFor="let validation of validation_messages.d10_past30">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d10_past30')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d10_past30').dirty ||
                        asialcoholdrugsForm.get('d10_past30').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="d10_lifetime" name="d10_lifetime" required />
                <mat-error *ngFor="let validation of validation_messages.d10_lifetime">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d10_lifetime')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d10_lifetime').dirty ||
                        asialcoholdrugsForm.get('d10_lifetime').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <mat-select formControlName="d10_route">
                  <mat-option value="Oral">Oral</mat-option>
                  <mat-option value="Nasal">Nasal</mat-option>
                  <mat-option value="Smoking">Smoking</mat-option>
                  <mat-option value="Non-IV injection">Non-IV injection</mat-option>
                  <mat-option value="IV">IV</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-3">
              <label class="labelText breakWord">
                D11. Hallucinogens
                <span style="color: red">*</span>
              </label>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="d11_past30" name="d11_past30" required />
                <mat-error *ngFor="let validation of validation_messages.d11_past30">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d11_past30')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d11_past30').dirty ||
                        asialcoholdrugsForm.get('d11_past30').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="d11_lifetime" name="d11_lifetime" required />
                <mat-error *ngFor="let validation of validation_messages.d11_lifetime">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d11_lifetime')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d11_lifetime').dirty ||
                        asialcoholdrugsForm.get('d11_lifetime').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <mat-select formControlName="d11_route">
                  <mat-option value="Oral">Oral</mat-option>
                  <mat-option value="Nasal">Nasal</mat-option>
                  <mat-option value="Smoking">Smoking</mat-option>
                  <mat-option value="Non-IV injection">Non-IV injection</mat-option>
                  <mat-option value="IV">IV</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-3">
              <label class="labelText breakWord">
                D12. Inhalants
                <span style="color: red">*</span>
              </label>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="d12_past30" name="d12_past30" required />
                <mat-error *ngFor="let validation of validation_messages.d12_past30">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d12_past30')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d12_past30').dirty ||
                        asialcoholdrugsForm.get('d12_past30').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="d12_lifetime" name="d12_lifetime" required />
                <mat-error *ngFor="let validation of validation_messages.d12_lifetime">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d12_lifetime')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d12_lifetime').dirty ||
                        asialcoholdrugsForm.get('d12_lifetime').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <mat-select formControlName="d12_route">
                  <mat-option value="Oral">Oral</mat-option>
                  <mat-option value="Nasal">Nasal</mat-option>
                  <mat-option value="Smoking">Smoking</mat-option>
                  <mat-option value="Non-IV injection">Non-IV injection</mat-option>
                  <mat-option value="IV">IV</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-3">
              <label class="labelText breakWord">
                D13. More than one substance per day (including alcohol)
                <span style="color: red">*</span>
              </label>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="d13_past30" name="d13_past30" required />
                <mat-error *ngFor="let validation of validation_messages.d13_past30">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d13_past30')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d13_past30').dirty ||
                        asialcoholdrugsForm.get('d13_past30').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="d13_lifetime" name="d13_lifetime" required />
                <mat-error *ngFor="let validation of validation_messages.d13_lifetime">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d13_lifetime')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d13_lifetime').dirty ||
                        asialcoholdrugsForm.get('d13_lifetime').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3"></div>
          </div>

          <!-- Questions > 14 and above -->
          <div class="row">
            <div class="col-md-12">
              <label class="labelText breakWord">
                D14. According to the interviewer, which substance is/are the
                major problem?
                <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="d14" name="d14" required />
                <mat-error *ngFor="let validation of validation_messages.d14">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d14')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d14').dirty ||
                        asialcoholdrugsForm.get('d14').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <label class="labelText breakWord">
                D15. How long was your last period of voluntary abstinence from
                this major substance?
                <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="d15" name="d15" required />
                <mat-error *ngFor="let validation of validation_messages.d15">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d15')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d15').dirty ||
                        asialcoholdrugsForm.get('d15').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <label class="labelText breakWord">
                D16. How many months ago did this abstinence end?
                <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="d16" name="d16" required />
                <mat-error *ngFor="let validation of validation_messages.d16">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d16')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d16').dirty ||
                        asialcoholdrugsForm.get('d16').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <label class="labelText breakWord">
                D17. How many times have you had Alcohol D.T.'s? Delirium
                Tremems (DTs) <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="d17" name="d17" required />
                <mat-error *ngFor="let validation of validation_messages.d17">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d17')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d17').dirty ||
                        asialcoholdrugsForm.get('d17').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <label class="labelText breakWord">
                D18. How many times have you overdosed on drugs? Overdoses (OD)
                <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="d18" name="d18" required />
                <mat-error *ngFor="let validation of validation_messages.d18">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d18')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d18').dirty ||
                        asialcoholdrugsForm.get('d18').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <label class="labelText breakWord">
                D19. How many times in your life have you been treated for:
                Alcohol abuse? <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="d19" name="d19" required />
                <mat-error *ngFor="let validation of validation_messages.d19">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d19')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d19').dirty ||
                        asialcoholdrugsForm.get('d19').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <label class="labelText breakWord">
                D20. Drug abuse? <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="d20" name="d20" required />
                <mat-error *ngFor="let validation of validation_messages.d20">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d20')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d20').dirty ||
                        asialcoholdrugsForm.get('d20').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <label class="labelText breakWord">
                D21. How many of these were detox only Alcohol?
                <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="d21" name="d21" required />
                <mat-error *ngFor="let validation of validation_messages.d21">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d21')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d21').dirty ||
                        asialcoholdrugsForm.get('d21').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <label class="labelText breakWord">
                D22. How many of these were detox only Drugs?
                <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="d22" name="d22" required />
                <mat-error *ngFor="let validation of validation_messages.d22">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d22')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d22').dirty ||
                        asialcoholdrugsForm.get('d22').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <label class="labelText breakWord">
                D23. How much money would you say you spent during the past 30
                days on Alcohol? <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="d23" name="d23" required />
                <mat-error *ngFor="let validation of validation_messages.d23">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d23')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d23').dirty ||
                        asialcoholdrugsForm.get('d23').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <label class="labelText breakWord">
                D24. How much money would you say you spent during the past 30
                days on Drugs? <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="d24" name="d24" required />
                <mat-error *ngFor="let validation of validation_messages.d24">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d24')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d24').dirty ||
                        asialcoholdrugsForm.get('d24').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <label class="labelText breakWord">D25. How many days have you been treated in an outpatient
                setting for alcohol or drugs in the past 30 days?
                <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="d25" name="d25" required />
                <mat-error *ngFor="let validation of validation_messages.d25">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d25')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d25').dirty ||
                        asialcoholdrugsForm.get('d25').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <label class="labelText breakWord">
                D26. How many days in the past 30 have you experienced Alcohol
                problems? <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="d26" name="d26" required />
                <mat-error *ngFor="let validation of validation_messages.d26">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d26')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d26').dirty ||
                        asialcoholdrugsForm.get('d26').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <label class="labelText breakWord">
                D27. How many days in the past 30 have you experienced Drug
                problems? <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="d27" name="d27" required />
                <mat-error *ngFor="let validation of validation_messages.d27">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d27')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d27').dirty ||
                        asialcoholdrugsForm.get('d27').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 pad10">
              <label class="labelText breakWord">
                D28. How troubled or bothered have you been in the past 30 days
                by Alcohol problems <span style="color: red">*</span>
              </label>
              <br />
              <mat-button-toggle-group formControlName="d28">
                <mat-button-toggle value="Not at all">Not at all</mat-button-toggle>
                <mat-button-toggle value="Slightly">Slightly</mat-button-toggle>
                <mat-button-toggle value="Moderately">Moderately</mat-button-toggle>
                <mat-button-toggle value="Considerably">Considerably</mat-button-toggle>
                <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 pad10">
              <label class="labelText breakWord">
                D29. How troubled or bothered have you been in the past 30 days
                by Drug problems <span style="color: red">*</span>
              </label>
              <br />
              <mat-button-toggle-group formControlName="d29">
                <mat-button-toggle value="Not at all">Not at all</mat-button-toggle>
                <mat-button-toggle value="Slightly">Slightly</mat-button-toggle>
                <mat-button-toggle value="Moderately">Moderately</mat-button-toggle>
                <mat-button-toggle value="Considerably">Considerably</mat-button-toggle>
                <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 pad10">
              <label class="labelText breakWord">
                D30. How important to you now is treatment for Alcohol problems
                <span style="color: red">*</span>
              </label>
              <br />
              <mat-button-toggle-group formControlName="d30">
                <mat-button-toggle value="Not at all">Not at all</mat-button-toggle>
                <mat-button-toggle value="Slightly">Slightly</mat-button-toggle>
                <mat-button-toggle value="Moderately">Moderately</mat-button-toggle>
                <mat-button-toggle value="Considerably">Considerably</mat-button-toggle>
                <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 pad10">
              <label class="labelText breakWord">
                D31. How important to you now is treatment for Drug problems
                <span style="color: red">*</span>
              </label>
              <br />
              <mat-button-toggle-group formControlName="d31">
                <mat-button-toggle value="Not at all">Not at all</mat-button-toggle>
                <mat-button-toggle value="Slightly">Slightly</mat-button-toggle>
                <mat-button-toggle value="Moderately">Moderately</mat-button-toggle>
                <mat-button-toggle value="Considerably">Considerably</mat-button-toggle>
                <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <label class="labelText breakWord">
                D32. How would you rate the patient's need for treatment for
                Alcohol problems <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="d32" name="d32" required />
                <mat-error *ngFor="let validation of validation_messages.d32">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d32')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d32').dirty ||
                        asialcoholdrugsForm.get('d32').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <label class="labelText breakWord">
                D33. How would you rate the patient's need for treatment for
                Drug problems <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="d33" name="d33" required />
                <mat-error *ngFor="let validation of validation_messages.d33">
                  <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm
                        .get('d33')
                        .hasError(validation.type) &&
                      (asialcoholdrugsForm.get('d33').dirty ||
                        asialcoholdrugsForm.get('d33').touched)
                    ">{{ validation.message }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 pad10">
              <label class="labelText breakWord">
                D34. Is the above information significantly distorted by
                Client's misrepresentation? <span style="color: red">*</span>
              </label>
              <br />
              <mat-button-toggle-group formControlName="d34">
                <mat-button-toggle value="No">No</mat-button-toggle>
                <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 pad10">
              <label class="labelText breakWord">
                D35. Is the above information significantly distorted by
                Client's inability to understand?
                <span style="color: red">*</span>
              </label>
              <br />
              <mat-button-toggle-group formControlName="d35">
                <mat-button-toggle value="No">No</mat-button-toggle>
                <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Comment Box -->
      <div class="col-sm-12 col-xl-4">
        <div class="row">
          <div class="col-sm-6">
            <mat-card class="custom-score-card mat-elevation-z8" appearance="raised">
              <label class="labelText">Clinician Score <span style="color: red">*</span></label>
              <mat-card-content>
                <mat-form-field appearance="outline" style="width:100px">
                  <input type="text" formControlName="clinicianScore" maxlength="1"
                    style="font-size:48px; font-weight: 900 !important" pattern="[0-9]" matInput required />
                </mat-form-field>
              </mat-card-content>
              <mat-error *ngFor="let validation of validation_messages.clinicianScore">
                <mat-error class="error-message" *ngIf="
                      asialcoholdrugsForm.get('clinicianScore').hasError(validation.type) &&
                      (asialcoholdrugsForm.get('clinicianScore').dirty ||
                        asialcoholdrugsForm.get('clinicianScore').touched)
                    ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-card>
          </div>
          <div class="col-sm-6">
            <mat-card class="custom-score-card mat-elevation-z8" appearance="raised">
              <label class="labelText" style="justify-content: center;">System Score</label>
              <mat-card-content>
                <span class="width:100px; font-size:48px; font-weight: 900 !important">
                  {{systemGeneratedScore}}
                </span>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div class="row" style="margin-top:15px">
          <div class="col-sm-12">

            <mat-card appearance="raised" class="mat-elevation-z8">
              <div class="row">
                <div class="col-md-12">
                  <label class="labelHeader">Comments</label>
                  <mat-form-field appearance="outline">
                    <textarea formControlName="comments" matInput rows="50"></textarea>
                  </mat-form-field>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end">
      <button [class]="
          asialcoholdrugsForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        " mat-raised-button type="submit" [disabled]="!asialcoholdrugsForm.valid || processing">
        Submit
      </button>
    </mat-dialog-actions>
  </form>
</div>