import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { DataTablesModel } from '../_models';
@Injectable({ providedIn: 'root' })
export class PermissionApiService {
    constructor(private httpClient: HttpClient) { }
    getPermissions(): Observable<DataTablesModel> {
        return this.httpClient.get<DataTablesModel>(`${environment.apiUrl}/my-permissions`);
    }
}