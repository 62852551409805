import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../store';

import { UserSettingsPropsAction } from '../store/actions';
import { getUserSettingsPropsData } from '../store/selectors';
import { Sandbox } from './base.sandbox';

@Injectable()
export class UserSettingsPropsSandbox extends Sandbox {
  constructor(protected appState$: Store<State>) {
    super(appState$);
  }

  public userSettingsProps$ = this.appState$.select(getUserSettingsPropsData);

  public loadUserSettingsProps() {
    this.appState$.dispatch(UserSettingsPropsAction.getUserSettingsProps());
  }

  public handleUserSettingsPropsChange(category: string, value: string): any {
    this.appState$.dispatch(
      UserSettingsPropsAction.handleUserSettingsPropsChange({ category, value })
    );
  }
}
