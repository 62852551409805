import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IAsiEmploymentSupport } from 'src/app/_models/assessments/asi/asiemploymentsupport.model';
import { AsiApiService } from 'src/app/_services/assessments/asi/asi.service';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-asi-employment-support',
  templateUrl: './asi-employment-support.component.html',
  styleUrls: ['./asi-employment-support.component.css']
})

export class AsiEmploymentSupportComponent implements OnInit {
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentRequestId: string;

  constructor(
    private asiApiService: AsiApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute) {
  }

  public action: string;
  public asiemploymentsupportForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  asiemploymentsupport: IAsiEmploymentSupport;
  asiemploymentsupportError: Error = null;
  errors: any = {};
  processing: boolean = false;
  systemGeneratedScore: number;

  public ngOnInit() {
    this.buildForm();
    this.errorHandler.handleErrors(this.asiemploymentsupportForm, this.errors);

    this.loadAsiEmploymentSupport();
  }


  loadAsiEmploymentSupport() {
    this.asiApiService.getAsiDetails(this.assessmentRequestId).subscribe(response => {
      if (response.data) {
        this.asiemploymentsupportForm.controls['e1'].setValue(response.data.asiEmploymentSupport.e1);
        this.asiemploymentsupportForm.controls['e2'].setValue(response.data.asiEmploymentSupport.e2);
        this.asiemploymentsupportForm.controls['e3'].setValue(response.data.asiEmploymentSupport.e3);
        this.asiemploymentsupportForm.controls['e3_detail'].setValue(response.data.asiEmploymentSupport.e3_detail);
        this.asiemploymentsupportForm.controls['e4'].setValue(response.data.asiEmploymentSupport.e4);
        this.asiemploymentsupportForm.controls['e5'].setValue(response.data.asiEmploymentSupport.e5);
        this.asiemploymentsupportForm.controls['e6'].setValue(response.data.asiEmploymentSupport.e6);
        this.asiemploymentsupportForm.controls['e7'].setValue(response.data.asiEmploymentSupport.e7);
        this.asiemploymentsupportForm.controls['e7_detail'].setValue(response.data.asiEmploymentSupport.e7_detail);
        this.asiemploymentsupportForm.controls['e8'].setValue(response.data.asiEmploymentSupport.e8);
        this.asiemploymentsupportForm.controls['e9'].setValue(response.data.asiEmploymentSupport.e9);
        this.asiemploymentsupportForm.controls['e10'].setValue(response.data.asiEmploymentSupport.e10);
        this.asiemploymentsupportForm.controls['e11'].setValue(response.data.asiEmploymentSupport.e11);
        this.asiemploymentsupportForm.controls['e12'].setValue(response.data.asiEmploymentSupport.e12);
        this.asiemploymentsupportForm.controls['e13'].setValue(response.data.asiEmploymentSupport.e13);
        this.asiemploymentsupportForm.controls['e14'].setValue(response.data.asiEmploymentSupport.e14);
        this.asiemploymentsupportForm.controls['e15'].setValue(response.data.asiEmploymentSupport.e15);
        this.asiemploymentsupportForm.controls['e16'].setValue(response.data.asiEmploymentSupport.e16);
        this.asiemploymentsupportForm.controls['e17'].setValue(response.data.asiEmploymentSupport.e17);
        this.asiemploymentsupportForm.controls['e18'].setValue(response.data.asiEmploymentSupport.e18);
        this.asiemploymentsupportForm.controls['e19'].setValue(response.data.asiEmploymentSupport.e19);
        this.asiemploymentsupportForm.controls['e20'].setValue(response.data.asiEmploymentSupport.e20);
        this.asiemploymentsupportForm.controls['e21'].setValue(response.data.asiEmploymentSupport.e21);
        this.asiemploymentsupportForm.controls['e22'].setValue(response.data.asiEmploymentSupport.e22);
        this.asiemploymentsupportForm.controls['e23'].setValue(response.data.asiEmploymentSupport.e23);
        this.asiemploymentsupportForm.controls['e24'].setValue(response.data.asiEmploymentSupport.e24);
        this.asiemploymentsupportForm.controls['comments'].setValue(response.data.asiEmploymentSupport.comments);
        this.asiemploymentsupportForm.controls['clinicianScore'].setValue(response.data.asiEmploymentSupport.clinicianScore);
      }
    });
  }
  onAsiEmploymentSupportFormSubmit() {
    if (this.asiemploymentsupportForm.invalid) {
      this.asiemploymentsupportForm.markAllAsTouched();
      return;
    }
    this.asiemploymentsupport = Object.assign({}, this.asiemploymentsupport);
    this.asiemploymentsupport = Object.assign(this.asiemploymentsupport, this.asiemploymentsupportForm.value);
    this.asiApiService.updateAsiEmploymentSupport(this.asiemploymentsupport).subscribe(() => {
    })
  };


  public validation_messages = {
    'clinicianScore': [
      { type: 'required', message: 'Clinician Score is required' },
    ],
    'e1': [
      { type: 'required', message: 'E1. Education completed is required' },
    ],
    'e2': [
      { type: 'required', message: 'E2. Training or technical education completed is required' },
    ],
    'e3': [
      { type: 'required', message: 'E3. Do you have a profession, trade, or skill? is required' },
    ],
    'e3_detail': [
    ],
    'e4': [
      { type: 'required', message: 'E4. Do you have a valid driver\'s license? is required' },
    ],
    'e5': [
      { type: 'required', message: 'E5. Do you have an automobile available for use? is required' },
    ],
    'e6': [
      { type: 'required', message: 'E6. How long was your longest full-time job? is required' },
    ],
    'e7': [
      { type: 'required', message: 'E7. Usual (or last) occupation  is required' },
    ],
    'e7_detail': [
    ],
    'e8': [
      { type: 'required', message: 'E8. Does someone contribute to your support in any way?  is required' },
    ],
    'e9': [
      { type: 'required', message: 'E9. Does this support constitute the majority of your support? is required' },
    ],
    'e10': [
      { type: 'required', message: 'E10. Usual employment pattern, past 3 years? is required' },
    ],
    'e11': [
      { type: 'required', message: 'E11. How many days were you paid for working in the past 30 days? is required' },
    ],
    'e12': [
      { type: 'required', message: 'E12. Employment is required' },
    ],
    'e13': [
      { type: 'required', message: 'E13. Unemployment compensation is required' },
    ],
    'e14': [
      { type: 'required', message: 'E14. Welfare is required' },
    ],
    'e15': [
      { type: 'required', message: 'E15. Pension, benefits or social security is required' },
    ],
    'e16': [
      { type: 'required', message: 'E16. Mate, family or friends is required' },
    ],
    'e17': [
      { type: 'required', message: 'E17. Illegal is required' },
    ],
    'e18': [
      { type: 'required', message: 'E18. How many people depend on you for the majority   of their food, shelter, etc.?  is required' },
    ],
    'e19': [
      { type: 'required', message: 'E19. How many days have you experienced employment   problems in the past 30? is required' },
    ],
    'e20': [
      { type: 'required', message: 'E20. How troubled or bothered have you been by these employment problems in the past 30 days? is required' },
    ],
    'e21': [
      { type: 'required', message: 'E21. How important to you now is counseling for these employment problems?  is required' },
    ],
    'e22': [
      { type: 'required', message: 'E22. How would you rate the patient\'s need for employment counseling?   is required' },
    ],
    'e23': [
      { type: 'required', message: 'E23. Client\'s misrepresentation? is required' },
    ],
    'e24': [
      { type: 'required', message: 'E24. Client\'s inability to understand? is required' },
    ],
    'comments': [
      { type: 'required', message: 'Comments is required' },
    ],
  };

  private buildForm() {
    this.asiemploymentsupportForm = this.formBuilder.group({
      clinicianScore: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      systemScore: new FormControl(0, Validators.compose([
      ])),
      sessionId: new FormControl(this.sessionId, Validators.compose([
        Validators.required,
      ])),
      patientId: new FormControl(this.patientId, Validators.compose([
        Validators.required,
      ])),
      assessmentRequestId: new FormControl(this.assessmentRequestId, Validators.compose([
        Validators.required,
      ])),
      e1: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      e2: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      e3: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      e3_detail: new FormControl('', Validators.compose([
      ])),
      e4: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      e5: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      e6: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      e7: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      e7_detail: new FormControl('', Validators.compose([
      ])),
      e8: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      e9: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      e10: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      e11: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      e12: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      e13: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      e14: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      e15: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      e16: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      e17: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      e18: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      e19: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      e20: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      e21: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      e22: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      e23: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      e24: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      comments: new FormControl('', Validators.compose([
        Validators.required,
      ])),
    });
    this.asiemploymentsupportForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }


  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.asiemploymentsupportForm) {
      return;
    }
    const form = this.asiemploymentsupportForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }

}