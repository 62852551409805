import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CareLog, ITherapySession } from 'src/app/_models';
import {
  CareLogHttpService,
  TherapySessionService,
  ToastMessageService,
} from 'src/app/_services';

@Component({
  selector: 'app-group-member-vitals',
  templateUrl: './group-member-vitals.component.html',
  styleUrl: './group-member-vitals.component.css',
})
export class GroupMemberVitalsComponent implements OnInit {
  sessionDetails: ITherapySession;
  selectedMemberId: string;

  todaysDate = new Date();
  isLoadingResults: boolean = true;
  processing: boolean = false;
  vitalsForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<GroupMemberVitalsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private therapysessionApiService: TherapySessionService,
    private toastMessageService: ToastMessageService,
    public careLogHttpService: CareLogHttpService
  ) {
    this.sessionDetails = data.sessionDetails;
    this.selectedMemberId = data.selectedMemberId;
  }

  ngOnInit(): void {
    this.buildForm();

    this.loadVitalForGroupMember();

    // Disable form if it is closed i.e, only read only mode
    if (this.sessionDetails.status != 'Active') {
      this.vitalsForm.disable();
    }
  }

  loadVitalForGroupMember() {
    this.isLoadingResults = true;

    this.therapysessionApiService
      .getMemberSessionVitals(this.selectedMemberId, this.sessionDetails.id)
      .subscribe({
        next: (response) => {
          if (response && response.data) {
            this.vitalsForm.controls['id'].setValue(response.data.id);
            this.vitalsForm.controls['pulse'].setValue(response.data.pulse);
            this.vitalsForm.controls['bodyTemperature'].setValue(
              response.data.bodyTemperature
            );
            this.vitalsForm.controls['bloodPressureHigh'].setValue(
              response.data.bloodPressureHigh
            );
            this.vitalsForm.controls['bloodPressureLow'].setValue(
              response.data.bloodPressureLow
            );
            this.vitalsForm.controls['oxygenLevel'].setValue(
              response.data.oxygenLevel
            );
            this.vitalsForm.controls['bloodSugar'].setValue(
              response.data.bloodSugar
            );
            this.vitalsForm.controls['height'].setValue(response.data.height);
            this.vitalsForm.controls['weight'].setValue(response.data.weight);
            this.vitalsForm.controls['activityDateTime'].setValue(
              response.data.activityDateTime
            );
            this.vitalsForm.controls['message'].setValue(response.data.message);
          }
          this.isLoadingResults = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to retrieve vitals information'
          );
          this.isLoadingResults = false;
        },
      });
  }

  private buildForm() {
    this.vitalsForm = this.formBuilder.group(
      {
        id: new FormControl(null),
        pulse: new FormControl(null),
        bodyTemperature: new FormControl(null),
        bloodPressureHigh: new FormControl(null),
        bloodPressureLow: new FormControl(null),
        oxygenLevel: new FormControl(null),
        bloodSugar: new FormControl(null),
        height: new FormControl(null),
        weight: new FormControl(null),
        activityDateTime: new FormControl(new Date(), Validators.required),
        message: new FormControl(''),
        patientId: new FormControl(this.selectedMemberId, Validators.required),
      },
      { validators: this.customValidator }
    );
  }

  customValidator(group: FormGroup): { [key: string]: any } | null {
    const fields = [
      'pulse',
      'bodyTemperature',
      'oxygenLevel',
      'bloodSugar',
      'weight',
      'height',
    ];
    const atLeastOneFilled = fields.some((field) => !!group.get(field)?.value);

    const bp1 = group.get('bloodPressureLow')?.value;
    const bp2 = group.get('bloodPressureHigh')?.value;
    const comboBpFilled = !!bp1 && !!bp2;

    return atLeastOneFilled || comboBpFilled
      ? null
      : { atLeastOneRequired: true };
  }

  submitVitalForm() {
    this.processing = true;

    let carelog: CareLog = Object.assign({}, this.vitalsForm.value);

    this.careLogHttpService
      .upsertSessionMemberVitals(
        this.sessionDetails.id,
        this.selectedMemberId,
        carelog
      )
      .subscribe({
        next: (response) => {
          this.processing = false;
          this.toastMessageService.displaySuccessMessage(
            'Vitals log for member has been updated'
          );
        },
        error: (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Failed to update vitals log'
          );
        },
      });
  }
}
