import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { IClaims } from 'src/app/_models';

@Injectable({ providedIn: 'root' })
export class ClaimsAPIService {
  constructor(private httpClient: HttpClient) {}

  getClaimDetails(id: string) {
    return this.httpClient.get<any>(`${environment.apiUrl}/claimdetails/${id}`);
  }

  // get All Claims
  getAllClaims(
    patientName: string,
    clinicianName: string,
    status: string,
    claimCode: string,
    sessionCode: string,
    startDate: any,
    endDate: any,
    billingSequence: string,
    pageSize: number,
    pageIndex: number
  ) {
    let params = new HttpParams();

    params = params.append('status', status);
    if (patientName) params = params.append('patientName', patientName);
    if (clinicianName) params = params.append('clinicianName', clinicianName);
    if (claimCode) params = params.append('claimCode', claimCode);
    if (sessionCode) params = params.append('sessionCode', sessionCode);

    if (startDate) params = params.append('startDate', startDate);
    if (endDate) params = params.append('endDate', endDate);

    if (billingSequence)
      params = params.append('billingSequence', billingSequence);

    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<any>(`${environment.apiUrl}/getallclaims`, {
      params: params,
    });
  }

  // Get all member claims
  getMemberClaims(
    patientId: string,
    status: string,
    claimCode: string,
    sessionCode: string,
    startDate: any,
    endDate: any,
    pageSize: number,
    pageIndex: number
  ) {
    let params = new HttpParams();

    params = params.append('status', status);
    if (patientId) params = params.append('patientId', patientId);
    if (claimCode) params = params.append('claimCode', claimCode);
    if (sessionCode) params = params.append('sessionCode', sessionCode);
    if (startDate) params = params.append('startDate', startDate);
    if (endDate) params = params.append('endDate', endDate);

    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<any>(`${environment.apiUrl}/getmemberclaims`, {
      params: params,
    });
  }

  markPrimaryClaimAsArchived(
    sessionId: string,
    memberId: string,
    message: string
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/markprimaryclaimasarchived/${sessionId}/${memberId}`,
        message,
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  unArchievePrimaryClaim(sessionId: string, memberId: string): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/unarchieveprimaryclaim/${sessionId}/${memberId}`,
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  markPrimaryClaimOnHold(sessionId: string, memberId: string): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/markprimaryclaimonhold/${sessionId}/${memberId}`,
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  // Get getClaimTransactionHistory
  getClaimTransactionHistory(appointmentId: string) {
    return this.httpClient.get<any>(
      `${environment.apiUrl}/claimtransactionhistory/${appointmentId}`
    );
  }

  getClaimStatistics() {
    return this.httpClient.get<any>(
      `${environment.apiUrl}/getclaimsdashboardstats`
    );
  }

  saveSecondaryClaim(payload: IClaims): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<IClaims>(`${environment.apiUrl}/savesecondaryclaim`, payload, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  submitSecondaryClaim(payload: IClaims): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<IClaims>(`${environment.apiUrl}/submitsecondaryclaim`, payload, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  markSecondaryClaimOnHold(claimId: string): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(`${environment.apiUrl}/marksecondaryclaimonhold/` + claimId, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
