import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ITherapySession, Patient } from 'src/app/_models';
import { TherapySessionService, ToastMessageService } from 'src/app/_services';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { SessionAssignComponent } from '../dialogs/session-assign/session-assign.component';
import { CloseSessionComponent } from '../session-details/close-session/close-session.component';
import { PrintDialogComponent } from '../session-notes/dialogs/print-dialog/print-dialog.component';

@Component({
  selector: 'app-therapy',
  templateUrl: './therapy.component.html',
  styleUrls: ['./therapy.component.css'],
})
export class TherapyComponent implements OnInit {
  @Input() patient: Patient;
  @Input() loggedInUserId: string;
  @Input() hasASIFeatures: boolean;
  @Input() therapySession: ITherapySession;
  @Input() hasTherapySessionAddAccess: boolean;
  @Input() hasTherapySessionEditAccess: boolean;
  @Input() hasHospitalDeviceFeature: boolean;
  @Input() hasMemberEditAccess: boolean;
  @Input() hasClinicalNotesViewAccess: boolean;
  @Input() hasClinicalNotesAddAccess: boolean;
  @Input() hasClinicalNotesEditAccess: boolean;
  @Input() hasSubjectivesViewAccess: boolean;
  @Input() hasSubjectivesEditAccess: boolean;
  @Input() hasObjectivesViewAccess: boolean;
  @Input() hasObjectivesEditAccess: boolean;
  @Input() hasMemberAssessmentViewAccess: boolean;
  @Input() hasMemberAssessmentAddAccess: boolean;
  @Input() hasMemberAssessmentEditAccess: boolean;
  @Input() hasMemberAssessmentDeleteAccess: boolean;
  @Input() hasAssessmentTakeAddAccess: boolean;
  @Input() hasAssessmentChartsViewAccess: boolean;
  @Input() hasAssessmentGuidanceViewAccess: boolean;
  @Input() hasAssessmentGuidanceEditAccess: boolean;
  @Input() hasAssessmentSummaryViewAccess: boolean;
  @Input() hasAssessmentSummaryEditAccess: boolean;

  @Input() hasGoalsViewAccess: boolean;
  @Input() hasGoalsAddAccess: boolean;
  @Input() hasGoalsEditAccess: boolean;
  @Input() hasGoalsDeleteAccess: boolean;

  @Input() hasDiagnosisViewAccess: boolean;
  @Input() hasDiagnosisAddAccess: boolean;
  @Input() hasDiagnosisEditAccess: boolean;
  @Input() hasDiagnosisDeleteAccess: boolean;

  @Input() hasMedicationViewAccess: boolean;
  @Input() hasMedicationAddAccess: boolean;
  @Input() hasMedicationEditAccess: boolean;
  @Input() hasMedicationDeleteAccess: boolean;
  @Input() prescriptionEnabled: boolean;

  @Output() output = new EventEmitter<any>();
  @ViewChild(MatAccordion) accordion: MatAccordion;

  textShortCutsExpanded: boolean = false;

  constructor(
    public dialog: MatDialog,
    private toastMessageService: ToastMessageService,
    public therapysessionApiService: TherapySessionService,
    public router: Router
  ) {
    this.showAsi.next(false);
  }

  showAsi: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  activeTab: string = 'Clinical Note';

  ngOnInit(): void {
    let hasASI = this.therapySession?.inSessionInterviews?.includes(
      'Addiction Severity Index(ASI)'
    );

    this.showAsi.next(hasASI);
  }

  triggerEvent(payload: any) {
    if (payload.eventType === 'RELOAD_PENDING_ASSESSMENT_REQUESTS') {
      if (payload?.id === 'asi') {
        this.showAsi.next(true);
      }
      this.output.emit(payload);
    } else if (
      payload.eventType === 'RELOAD_THERPAY_OBJECT' ||
      payload.eventType === 'RELOAD_THERPAY_OBJECT_AFTER_1_SECOND'
    ) {
      this.output.emit(payload);
    }
  }

  public closeSession() {
    // Phone Note
    if (this.therapySession?.sessionType === 'Phone Note') {
      const addAnotherDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Please Confirm',
          msg: 'Are you sure you want to  close this note?',
          yesButtonTitle: 'Yes',
          noButtonTitle: 'No',
        },
        disableClose: true,
        autoFocus: false,
        minWidth: '20vw',
      });
      addAnotherDialog.afterClosed().subscribe((response) => {
        if (response) {
          this.therapysessionApiService
            .closeSession(this.therapySession?.id, {})
            .subscribe({
              next: () => {
                this.toastMessageService.displaySuccessMessage(
                  'Note ' +
                    this.therapySession.sessionCode +
                    ' has been closed.'
                );
                this.deSelectSession();
              },
              error: (error) => {
                this.toastMessageService.displayErrorMessage(
                  'Error occured while closing the note'
                );
              },
            });
        }
      });
    } else {
      // Regular Appointment
      // Validation for member data
      if (!this.therapySession.intakeMemberData) {
        this.toastMessageService.displayInfoMessage(
          "Please verify the member's detail on the Intake step"
        );
        return;
      }

      // Validation for signature
      if (
        this.therapySession.physicianSigned === 'Yes' &&
        this.hasTherapySessionEditAccess
      ) {
        // Validation for supervising RP
        if (
          this.therapySession.supervisingRenderingProvider === 'Yes' &&
          this.therapySession.supervisorSigned != 'Yes'
        ) {
          this.toastMessageService.displayInfoMessage(
            'Cannot close the session. Supervisor signature is still required.'
          );
          return;
        }

        let preDialog = this.dialog.open(ConfirmDialogComponent, {
          data: {
            title: 'Please Confirm',
            msg: 'Before continuing, please ensure that you have added the required clinical notes and verified the billing information.',
            yesButtonTitle: 'Proceed',
            noButtonTitle: 'Cancel',
          },
          autoFocus: false,
          disableClose: true,
        });
        preDialog.afterClosed().subscribe((response) => {
          if (response) {
            const dialogRef = this.dialog.open(CloseSessionComponent, {
              minWidth: '70vw',
              minHeight: '40vh',
              disableClose: true,
              autoFocus: false,
              data: {
                sessionId: this.therapySession.id,
                therapySession: this.therapySession,
              },
            });
            dialogRef.afterClosed().subscribe((response) => {
              if (response === 'success') {
                // If only the user has the therapy add access
                if (this.hasTherapySessionAddAccess) {
                  // Also bring in another box to ask if you would like to setup another session
                  const addAnotherDialog = this.dialog.open(
                    ConfirmDialogComponent,
                    {
                      data: {
                        title: 'Another Session?',
                        msg: 'Do you want to schedule another session?',
                        yesButtonTitle: 'Yes',
                        noButtonTitle: 'No',
                      },
                      disableClose: true,
                    }
                  );
                  addAnotherDialog.afterClosed().subscribe((response) => {
                    if (response) {
                      // Navigate to therapy scheduler
                      this.router.navigate([
                        '/sessionscheduler',
                        this.patient.id,
                      ]);
                    } else {
                      this.deSelectSession();
                    }
                  });
                } else {
                  this.deSelectSession();
                }
              }
            });
          }
        });
      } else {
        this.toastMessageService.displayInfoMessage(
          'Rendering Provider signature is required at the Clinical Step.'
        );
      }
    }
  }

  public deSelectSession() {
    this.router.navigate(['/main/member/' + this.patient.id + '/NOTES']);
    this.output.emit({ eventType: 'DESELECT_SESSION' });
  }

  printDialog() {
    this.dialog.open(PrintDialogComponent, {
      data: { therapySession: this.therapySession, patient: this.patient },
      autoFocus: false,
      disableClose: true,
      minWidth: '65vw',
    });
  }

  assignSession() {
    this.dialog.open(SessionAssignComponent, {
      data: {
        therapySession: this.therapySession,
        patientId: this.patient.id,
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '35vw',
    });
  }

  expandRow(type) {
    if (type === 'expand') {
      this.textShortCutsExpanded = true;
    } else {
      this.textShortCutsExpanded = false;
    }
  }
}
