<div class="container">
  <div>
    <h3 *ngIf="action === 'ADD'">Add Goal</h3>
    <h3 *ngIf="action === 'EDIT'">Edit Goal</h3>

    <button
      mat-icon-button
      aria-label="close dialog"
      class="mat-focus-indicator btn btn-just-icon"
      type="button"
      (click)="closeEditDialog('No')"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="patientAssessmentGoalForm" style="padding-top: 10px">
    <div class="row">
      <div class="col-md-12">
        <label class="labelText">
          Goal Description <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <textarea
            matInput
            formControlName="description"
            placeholder="Description"
            [readonly]="action === 'EDIT'"
            rows="3"
          ></textarea>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <button
          class="btn btn-success pull-right"
          (click)="createObjective('', '', '')"
        >
          + Add Objective
        </button>
      </div>
      <div class="col-md-12" formArrayName="objectives">
        <ng-container
          *ngFor="let objective of objectives().controls; let i = index"
        >
          <mat-card
            appearance="raised"
            class="pad20 mat-elevation-z8"
            style="width: 100%"
          >
            <div class="input-wrapper" [formGroupName]="i">
              <label class="labelText pull-left">
                Objective <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Type Objective..."
                  formControlName="objectiveDescription"
                  required
                />
              </mat-form-field>

              <div class="row">
                <div class="col-md-6">
                  <label class="labelText"> Target # </label>
                  <mat-form-field appearance="outline">
                    <mat-select
                      formControlName="targetNumber"
                      placeholder="Target #"
                    >
                      <mat-option *ngFor="let num of numbers" [value]="num">
                        {{ num }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <label class="labelText"> Target Type </label>
                  <mat-form-field appearance="outline">
                    <mat-select
                      formControlName="targetType"
                      placeholder="Target Type"
                    >
                      <mat-option value="Days">Day(s)</mat-option>
                      <mat-option value="Weeks">Week(s)</mat-option>
                      <mat-option value="Months">Month(s)</mat-option>
                      <mat-option value="Years">Year(s)</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-card>
        </ng-container>
      </div>
    </div>

    <div
      class="col-md-12"
      style="padding: 0; display: flex; justify-content: space-between"
    >
      <button mat-raised-button (click)="closeEditDialog('No')">Cancel</button>
      <button
        class="btn-primary"
        type="button"
        color="primary"
        mat-raised-button
        (click)="closeEditDialog('Yes')"
        [disabled]="editProcessing || patientAssessmentGoalForm.invalid"
      >
        <span *ngIf="!editProcessing && action === 'ADD'">Submit</span>
        <span *ngIf="editProcessing && action === 'ADD'"
          >Submitting <i class="fa fa-spinner fa-spin"></i
        ></span>

        <span *ngIf="!editProcessing && action === 'EDIT'">Update</span>
        <span *ngIf="editProcessing && action === 'EDIT'"
          >Updating <i class="fa fa-spinner fa-spin"></i
        ></span>
      </button>
    </div>
  </form>
</div>
