<mat-card-title>Provider Consent</mat-card-title>
<mat-divider></mat-divider>

<div style="margin-top: 20px; padding: 10px">
  <mat-progress-bar mode="indeterminate" *ngIf="processing"></mat-progress-bar>
  <div
    class="row"
    style="padding-right: 20px; padding-left: 20px; margin-bottom: 20px"
  >
    <!-- Signature Table -->
    <div class="col-md-12 mat-elevation-z8">
      <div class="table-responsive">
        <table mat-table matSort matSortDisableClear [dataSource]="data">
          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef>Role</th>
            <td mat-cell *matCellDef="let row" style="font-weight: 500">
              {{ row.role }}
            </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let row">{{ row.name }}</td>
          </ng-container>
          <ng-container matColumnDef="signatureDate">
            <th mat-header-cell *matHeaderCellDef>Last Signature Date</th>
            <td mat-cell *matCellDef="let row">
              {{ row?.signatureDate | date : 'MM/dd/y' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let row">
              <button
                mat-button
                type="button"
                color="warn"
                (click)="openSignature(row, 'ADD')"
                *ngIf="row?.signed != 'Yes'"
              >
                <i class="material-icons" style="margin-right: 8px"> draw </i>
                Signature Required
              </button>
              <button
                mat-button
                type="button"
                color="primary"
                (click)="openSignature(row, 'VIEW')"
                *ngIf="row?.signed === 'Yes'"
              >
                <i class="material-icons" style="margin-right: 8px">
                  visibility
                </i>
                View
              </button>
              <button
                mat-button
                color="warn"
                type="button"
                (click)="openSignature(row, 'ADD')"
                *ngIf="
                  row?.signed === 'Yes' &&
                  therapySession.status === 'Active' &&
                  loggedInUserId === row.userId
                "
              >
                <i class="material-icons" style="margin-right: 8px"> draw </i>
                Re-Do Signature
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
