import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ClaimsAPIService,
  DateTimeZoneService,
  RemittancesAPIService,
  ToastMessageService,
} from 'src/app/_services';

@Component({
  selector: 'app-claim-remits-payment-history',
  templateUrl: './claim-remits-payment-history.component.html',
  styleUrl: './claim-remits-payment-history.component.css',
})
export class ClaimRemitsPaymentHistoryComponent implements OnInit {
  claimId: string;
  parent: string;
  subParent: string;

  claimDetails: any;
  paymentsList: any;
  isLoadingDetails: boolean = false;
  isLoadingResults: boolean = false;
  apiError: boolean = false;

  currentTimezone: string = '';

  displayedColumns = [
    'details',
    'claimStatusCode',
    'date',
    'totalAmount',
    'paidAmount',
    'patientResponsibility',
    'action',
  ];

  constructor(
    public router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private remittancesApiService: RemittancesAPIService,
    private claimsApiService: ClaimsAPIService,
    private toastMessageService: ToastMessageService,
    private dateTimeZoneService: DateTimeZoneService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit(): void {
    this.claimId = this.route.snapshot.paramMap.get('claimId');
    this.route.queryParamMap.subscribe((param) => {
      this.parent = param.get('parent');
      this.subParent = param.get('subParent');
    });

    // Get session details
    this.getClaimDetails();

    //Get paymentHistory
    this.getPaymentHistory();
  }

  getClaimDetails() {
    this.isLoadingDetails = true;

    this.claimsApiService.getClaimDetails(this.claimId).subscribe({
      next: (response) => {
        this.claimDetails = response.data;
        this.isLoadingDetails = false;
      },
      error: (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to retrieve claim details'
        );
        this.isLoadingDetails = false;
      },
    });
  }

  getPaymentHistory() {
    this.isLoadingResults = true;
    this.apiError = false;

    this.remittancesApiService.getPaymentHistory(this.claimId).subscribe({
      next: (response) => {
        this.isLoadingResults = false;
        this.paymentsList = response.items;
      },
      error: (error) => {
        this.isLoadingResults = false;
        this.apiError = true;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to retrieve claim payment details.'
        );
      },
    });
  }

  viewClaimResponse(row) {
    this.router.navigate([`/remittances/remit/paymentremithistory/${row.id}`], {
      queryParams: {
        parent: this.parent,
        subParent: this.subParent,
      },
    });
  }
}
