import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelCaseToSpaces'
})
export class CamelCaseToSpacesPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return value;
    return value
      .replace(/([A-Z])/g, ' $1') // insert a space before all caps
      .replace(/^./, (str) => str.toUpperCase()); // capitalize the first letter
  }

}