import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataTablesModel, ResponseModal } from 'src/app/_models';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ClinicalNoteSectionsApiService {
  constructor(private httpClient: HttpClient) {}

  getClinicalNoteSectionList(
    noteId: string,
    pageSize: number,
    pageIndex: number
  ): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('noteId', noteId);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<any[]>(
      `${environment.apiUrl}/clinicalnotesections`,
      { params: params }
    );
  }

  getOrganizationAllActiveNotesList(): Observable<DataTablesModel> {
    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/activeorganizationclinicalnotes`
    );
  }

  getOrganizationAllActiveShortcutsTemplates(): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/activeorganizationshortcutstemplates`
    );
  }
}
