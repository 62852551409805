<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>hare-psychopathy Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="harePsychopathyForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >1. Glibness / superficial charm</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="charm">
              <mat-button-toggle value="Definitely Present"
                >Definitely Present</mat-button-toggle
              >
              <mat-button-toggle value="Possibly Present"
                >Possibly Present</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Absent"
                >Definitely Absent</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >2. Previous diagnosis as psychopath (or similar)</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="psychopathDiagnosis">
              <mat-button-toggle value="Definitely Present"
                >Definitely Present</mat-button-toggle
              >
              <mat-button-toggle value="Possibly Present"
                >Possibly Present</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Absent"
                >Definitely Absent</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >3. Egocentricity/grandiose sense of self-worth</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="senseOfSelfWorth">
              <mat-button-toggle value="Definitely Present"
                >Definitely Present</mat-button-toggle
              >
              <mat-button-toggle value="Possibly Present"
                >Possibly Present</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Absent"
                >Definitely Absent</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >4. Proneness to boredom / low frustration tolerance</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="frustrationTolerance">
              <mat-button-toggle value="Definitely Present"
                >Definitely Present</mat-button-toggle
              >
              <mat-button-toggle value="Possibly Present"
                >Possibly Present</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Absent"
                >Definitely Absent</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >5. Pathological lying and deception</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="lyingAndDeception">
              <mat-button-toggle value="Definitely Present"
                >Definitely Present</mat-button-toggle
              >
              <mat-button-toggle value="Possibly Present"
                >Possibly Present</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Absent"
                >Definitely Absent</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >6. Conning / lack of sincerity</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="sincerity">
              <mat-button-toggle value="Definitely Present"
                >Definitely Present</mat-button-toggle
              >
              <mat-button-toggle value="Possibly Present"
                >Possibly Present</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Absent"
                >Definitely Absent</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >7. Lack of remorse or guilt</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="lackOfRemorse">
              <mat-button-toggle value="Definitely Present"
                >Definitely Present</mat-button-toggle
              >
              <mat-button-toggle value="Possibly Present"
                >Possibly Present</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Absent"
                >Definitely Absent</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >8. Lack of affect and emotional depth</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="lackOfAffect">
              <mat-button-toggle value="Definitely Present"
                >Definitely Present</mat-button-toggle
              >
              <mat-button-toggle value="Possibly Present"
                >Possibly Present</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Absent"
                >Definitely Absent</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >9. Callous / lack of empathy</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="lackOfEmpathy">
              <mat-button-toggle value="Definitely Present"
                >Definitely Present</mat-button-toggle
              >
              <mat-button-toggle value="Possibly Present"
                >Possibly Present</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Absent"
                >Definitely Absent</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after">10. Parasitic lifestyle</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="lifestyle">
              <mat-button-toggle value="Definitely Present"
                >Definitely Present</mat-button-toggle
              >
              <mat-button-toggle value="Possibly Present"
                >Possibly Present</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Absent"
                >Definitely Absent</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >11. Short-tempered / poor behavioral controls</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="poorBehavior">
              <mat-button-toggle value="Definitely Present"
                >Definitely Present</mat-button-toggle
              >
              <mat-button-toggle value="Possibly Present"
                >Possibly Present</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Absent"
                >Definitely Absent</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >12. Promiscuous sexual relations</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="sexualRelations">
              <mat-button-toggle value="Definitely Present"
                >Definitely Present</mat-button-toggle
              >
              <mat-button-toggle value="Possibly Present"
                >Possibly Present</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Absent"
                >Definitely Absent</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >13. Early behavior problems</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="earlyBehavior">
              <mat-button-toggle value="Definitely Present"
                >Definitely Present</mat-button-toggle
              >
              <mat-button-toggle value="Possibly Present"
                >Possibly Present</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Absent"
                >Definitely Absent</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >14. Lack of realistic, long-term plans</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="usingElectronicsLongerThanPlanned"
            >
              <mat-button-toggle value="Definitely Present"
                >Definitely Present</mat-button-toggle
              >
              <mat-button-toggle value="Possibly Present"
                >Possibly Present</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Absent"
                >Definitely Absent</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after">15. Impulsivity</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="impulse">
              <mat-button-toggle value="Definitely Present"
                >Definitely Present</mat-button-toggle
              >
              <mat-button-toggle value="Possibly Present"
                >Possibly Present</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Absent"
                >Definitely Absent</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >16. Irresponsible behavior as parent</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="irresponsibleParent">
              <mat-button-toggle value="Definitely Present"
                >Definitely Present</mat-button-toggle
              >
              <mat-button-toggle value="Possibly Present"
                >Possibly Present</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Absent"
                >Definitely Absent</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >17. Frequent marital relationships</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="maritalRelationships">
              <mat-button-toggle value="Definitely Present"
                >Definitely Present</mat-button-toggle
              >
              <mat-button-toggle value="Possibly Present"
                >Possibly Present</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Absent"
                >Definitely Absent</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after">18. Juvenile delinquency</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="delinquency">
              <mat-button-toggle value="Definitely Present"
                >Definitely Present</mat-button-toggle
              >
              <mat-button-toggle value="Possibly Present"
                >Possibly Present</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Absent"
                >Definitely Absent</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >19. Poor probation or parole risk</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="paroleRisk">
              <mat-button-toggle value="Definitely Present"
                >Definitely Present</mat-button-toggle
              >
              <mat-button-toggle value="Possibly Present"
                >Possibly Present</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Absent"
                >Definitely Absent</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >20. Failure to accept responsibility for own actions</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="failureToAcceptResponsibility"
            >
              <mat-button-toggle value="Definitely Present"
                >Definitely Present</mat-button-toggle
              >
              <mat-button-toggle value="Possibly Present"
                >Possibly Present</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Absent"
                >Definitely Absent</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >21. Many types of offense</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="offense">
              <mat-button-toggle value="Definitely Present"
                >Definitely Present</mat-button-toggle
              >
              <mat-button-toggle value="Possibly Present"
                >Possibly Present</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Absent"
                >Definitely Absent</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >22. Drug or alcohol abuse not direct cause of antisocial
            behavior</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="drugOrAlcoholAbuse">
              <mat-button-toggle value="Definitely Present"
                >Definitely Present</mat-button-toggle
              >
              <mat-button-toggle value="Possibly Present"
                >Possibly Present</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Absent"
                >Definitely Absent</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          harePsychopathyForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!harePsychopathyForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
