import { Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { CustomFormRequestComponent } from './custom-form-request/custom-form-request.component';

export const CustomFormRoutes: Routes = [
  {
    path: 've',
    children: [
      {
        path: ':requestId/:formVersionId',
        component: CustomFormRequestComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
];
