import { IUserSettingsProps } from 'src/app/_models';

export interface UserSettingsPropsState {
  userSettings: IUserSettingsProps[];
  userSettingsError: Error;
  userSettingsSuccess: boolean;
  userSettingsLoading: boolean;
}

export const initialUserSettingsPropsState: UserSettingsPropsState = {
  userSettings: undefined,
  userSettingsError: undefined,
  userSettingsSuccess: false,
  userSettingsLoading: false,
};
