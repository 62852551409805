<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Search and Select Procedure Code</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="row" style="margin-top: 15px">
    <!-- Search Code -->
    <div
      class="col-md-12"
      style="
        display: flex;
        align-items: center;
        padding: 5px;
        border-radius: 10px;
        border: 1px solid rgb(0, 0, 0, 0.2);
        background-color: #ffffff;
        margin: 15px 5px;
      "
    >
      <span
        class="material-icons"
        style="text-align: left; font-size: 20px; opacity: 0.5"
      >
        search
      </span>
      <input
        type="search"
        placeholder="Procedure Code"
        [(ngModel)]="searchCPTCode"
        (ngModelChange)="searchChanged()"
        style="
          padding-left: 15px;
          width: 100%;
          background: transparent;
          border: 0;
        "
      />
    </div>

    <!-- Primary Insurance -->
    <div class="col-md-4" *ngIf="primaryInsuranceSelected">
      <mat-card appearance="raised" class="mat-elevation-z8">
        <label class="labelHeader">
          Procedure Code at Primary Insurance Level
        </label>

        <div>
          <mat-progress-bar
            mode="indeterminate"
            *ngIf="payerProcessing"
          ></mat-progress-bar>
          <mat-error *ngIf="!payerProcessing && payerCPTList.total == 0">
            No procedure code found
          </mat-error>
        </div>

        <div class="table-responsive" *ngIf="payerCPTList.total > 0">
          <table
            mat-table
            [dataSource]="payerCPTList.items"
            class="mat-elevation-z8"
            matSort
            matSortDisableClear
          >
            <ng-container matColumnDef="cptCode">
              <th mat-header-cell *matHeaderCellDef>CPT Code</th>
              <td mat-cell *matCellDef="let row">
                <b>{{ row.cptCode }}</b>
                <div class="starDiv">
                  <mat-icon
                    [ngClass]="row.isFavorite ? 'favorite' : 'not-favorite'"
                    (click)="toggleFavorite('PROCEDURE_CODES', row)"
                    >star</mat-icon
                  >
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef>Description</th>
              <td mat-cell *matCellDef="let row">
                {{ row.description }}
                <br />
                <b>
                  {{ row.duration }} minutes at
                  {{ row.rate | currency : 'USD' }}
                </b>
              </td>
            </ng-container>
            <ng-container matColumnDef="rate">
              <th mat-header-cell *matHeaderCellDef>Rate</th>
              <td mat-cell *matCellDef="let row">
                {{ row.duration }} minutes at {{ row.rate | currency : 'USD' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="defaultUnit">
              <th mat-header-cell *matHeaderCellDef>Default Unit</th>
              <td mat-cell *matCellDef="let row">
                {{ row.defaultUnit }}
              </td>
            </ng-container>
            <ng-container matColumnDef="modifiers">
              <th mat-header-cell *matHeaderCellDef>Modifiers</th>
              <td mat-cell *matCellDef="let row">
                <span class="modifierBox" *ngIf="row?.modifier1">{{
                  row?.modifier1
                }}</span>
                <span class="modifierBox" *ngIf="row?.modifier2">{{
                  row?.modifier2
                }}</span>
                <span class="modifierBox" *ngIf="row?.modifier3">{{
                  row?.modifier3
                }}</span>
                <span class="modifierBox" *ngIf="row?.modifier4">{{
                  row?.modifier4
                }}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>Action</th>
              <td mat-cell *matCellDef="let row; let i = index">
                <button
                  mat-raised-button
                  color="warn"
                  (click)="codeSelected(row)"
                >
                  Select
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <mat-paginator
            #paginator1
            [length]="payerCPTList.total"
            [pageSize]="payerCPTList.per_page"
            [showFirstLastButtons]="true"
            *ngIf="payerCPTList.total > payerCPTList.per_page"
            (page)="getNextPayerList($event)"
          >
          </mat-paginator>
        </div>
      </mat-card>
    </div>

    <!-- Organization -->
    <div [class]="primaryInsuranceSelected ? 'col-md-4' : 'col-md-6'">
      <mat-card appearance="raised" class="mat-elevation-z8">
        <label class="labelHeader">
          Procedure Code at Organization Level
        </label>

        <div>
          <mat-progress-bar
            mode="indeterminate"
            *ngIf="orgProcessing"
          ></mat-progress-bar>
          <mat-error *ngIf="!orgProcessing && organizationalCPTList.total == 0">
            No procedure code found
          </mat-error>
        </div>

        <div class="table-responsive" *ngIf="organizationalCPTList.total > 0">
          <table
            mat-table
            [dataSource]="organizationalCPTList.items"
            class="mat-elevation-z8"
            matSort
            matSortDisableClear
          >
            <ng-container matColumnDef="cptCode">
              <th mat-header-cell *matHeaderCellDef>CPT Code</th>
              <td mat-cell *matCellDef="let row">
                <b>{{ row.cptCode }}</b>

                <div class="starDiv">
                  <mat-icon
                    [ngClass]="row.isFavorite ? 'favorite' : 'not-favorite'"
                    (click)="toggleFavorite('PROCEDURE_CODES', row)"
                    >star</mat-icon
                  >
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef>Description</th>
              <td mat-cell *matCellDef="let row">
                {{ row.description }}
                <br />
                <b>
                  {{ row.duration }} minutes at
                  {{ row.rate | currency : 'USD' }}
                </b>
              </td>
            </ng-container>
            <ng-container matColumnDef="rate">
              <th mat-header-cell *matHeaderCellDef>Rate</th>
              <td mat-cell *matCellDef="let row">
                {{ row.duration }} minutes at {{ row.rate | currency : 'USD' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="defaultUnit">
              <th mat-header-cell *matHeaderCellDef>Default Unit</th>
              <td mat-cell *matCellDef="let row">
                {{ row.defaultUnit }}
              </td>
            </ng-container>
            <ng-container matColumnDef="modifiers">
              <th mat-header-cell *matHeaderCellDef>Modifiers</th>
              <td mat-cell *matCellDef="let row">
                <span class="modifierBox" *ngIf="row?.modifier1">{{
                  row?.modifier1
                }}</span>
                <span class="modifierBox" *ngIf="row?.modifier2">{{
                  row?.modifier2
                }}</span>
                <span class="modifierBox" *ngIf="row?.modifier3">{{
                  row?.modifier3
                }}</span>
                <span class="modifierBox" *ngIf="row?.modifier4">{{
                  row?.modifier4
                }}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>Action</th>
              <td mat-cell *matCellDef="let row; let i = index">
                <button
                  mat-raised-button
                  color="warn"
                  (click)="codeSelected(row)"
                >
                  Select
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <mat-paginator
            #paginator1
            [length]="organizationalCPTList.total"
            [pageSize]="organizationalCPTList.per_page"
            [showFirstLastButtons]="true"
            *ngIf="organizationalCPTList.total > organizationalCPTList.per_page"
            (page)="getNextOrgList($event)"
          >
          </mat-paginator>
        </div>
      </mat-card>
    </div>

    <!-- Facility -->
    <div [class]="primaryInsuranceSelected ? 'col-md-4' : 'col-md-6'">
      <mat-card appearance="raised" class="mat-elevation-z8">
        <label class="labelHeader"> Procedure Code at Service Location </label>

        <div>
          <mat-progress-bar
            mode="indeterminate"
            *ngIf="facilityProcessing"
          ></mat-progress-bar>
          <mat-error *ngIf="!facilityProcessing && facilityCPTList.total == 0">
            No procedure code found
          </mat-error>
        </div>

        <div class="table-responsive" *ngIf="facilityCPTList.total > 0">
          <table
            mat-table
            [dataSource]="facilityCPTList.items"
            class="mat-elevation-z8"
            matSort
            matSortDisableClear
          >
            <ng-container matColumnDef="cptCode">
              <th mat-header-cell *matHeaderCellDef>CPT Code</th>
              <td mat-cell *matCellDef="let row">
                <b>{{ row.cptCode }}</b>

                <div class="starDiv">
                  <mat-icon
                    [ngClass]="row.isFavorite ? 'favorite' : 'not-favorite'"
                    (click)="toggleFavorite('PROCEDURE_CODES', row)"
                    >star</mat-icon
                  >
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef>Description</th>
              <td mat-cell *matCellDef="let row">
                {{ row.description }}
                <br />
                <b>
                  {{ row.duration }} minutes at
                  {{ row.rate | currency : 'USD' }}
                </b>
              </td>
            </ng-container>
            <ng-container matColumnDef="rate">
              <th mat-header-cell *matHeaderCellDef>Rate</th>
              <td mat-cell *matCellDef="let row">
                {{ row.duration }} minutes at {{ row.rate | currency : 'USD' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="defaultUnit">
              <th mat-header-cell *matHeaderCellDef>Default Unit</th>
              <td mat-cell *matCellDef="let row">
                {{ row.defaultUnit }}
              </td>
            </ng-container>
            <ng-container matColumnDef="modifiers">
              <th mat-header-cell *matHeaderCellDef>Modifiers</th>
              <td mat-cell *matCellDef="let row">
                <span class="modifierBox" *ngIf="row?.modifier1">{{
                  row?.modifier1
                }}</span>
                <span class="modifierBox" *ngIf="row?.modifier2">{{
                  row?.modifier2
                }}</span>
                <span class="modifierBox" *ngIf="row?.modifier3">{{
                  row?.modifier3
                }}</span>
                <span class="modifierBox" *ngIf="row?.modifier4">{{
                  row?.modifier4
                }}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>Action</th>
              <td mat-cell *matCellDef="let row; let i = index">
                <button
                  mat-raised-button
                  color="warn"
                  (click)="codeSelected(row)"
                >
                  Select
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <mat-paginator
            #paginator1
            [length]="facilityCPTList.total"
            [pageSize]="facilityCPTList.per_page"
            [showFirstLastButtons]="true"
            *ngIf="facilityCPTList.total > facilityCPTList.per_page"
            (page)="getNextFacilityList($event)"
          >
          </mat-paginator>
        </div>
      </mat-card>
    </div>
  </div>
</div>
