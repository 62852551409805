<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Infant Toddler Assesment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-12 text-center">
      <button class="btn btn-primary" mat-raised-button>
        Communication Composite: {{ communication }}/26
      </button>
      <button class="btn btn-primary" mat-raised-button>
        Expressive Speech Composite: {{ speech }}/14
      </button>
      <button class="btn btn-primary" mat-raised-button>
        Symbolic Composite: {{ symbolic }}/17
      </button>
    </div>
  </div>

  <form
    [formGroup]="infantToddlerForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <mat-form-field appearance="outline">
          <mat-label>Age</mat-label>
          <mat-select formControlName="age" name="age" required>
            <mat-option value="6">6 Months</mat-option>
            <mat-option value="7">7 Months</mat-option>
            <mat-option value="8">8 Months</mat-option>
            <mat-option value="9">9 Months</mat-option>
            <mat-option value="10">10 Months</mat-option>
            <mat-option value="11">11 Months</mat-option>
            <mat-option value="12">12 Months</mat-option>
            <mat-option value="13">13 Months</mat-option>
            <mat-option value="14">14 Months</mat-option>
            <mat-option value="15">15 Months</mat-option>
            <mat-option value="16">16 Months</mat-option>
            <mat-option value="17">17 Months</mat-option>
            <mat-option value="18">18 Months</mat-option>
            <mat-option value="19">19 Months</mat-option>
            <mat-option value="20">20 Months</mat-option>
            <mat-option value="21">21 Months</mat-option>
            <mat-option value="22">22 Months</mat-option>
            <mat-option value="23">23 Months</mat-option>
            <mat-option value="24">24 Months</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="card">
      <div class="card-header"><b>Emotion and Eye Gaze</b></div>
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >1. Do you know when your child is happy and when your child is
            upset?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q1">
              <mat-button-toggle value="Not Yet">Not Yet</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >2. When your child plays with toys, does he/she look at you to see
            if you are watching?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q2">
              <mat-button-toggle value="Not Yet">Not Yet</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >3. Does your child smile or laugh while looking at you?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q3">
              <mat-button-toggle value="Not Yet">Not Yet</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >4. When you look at and point to a toy across the room, does your
            child look at it?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q4">
              <mat-button-toggle value="Not Yet">Not Yet</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header"><b>Communication</b></div>
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >1. Does your child let you know that he/she needs help or wants an
            object out of reach?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q5">
              <mat-button-toggle value="Not Yet">Not Yet</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >2. When you are not paying attention to your child, does he/she try
            to get your attention?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q6">
              <mat-button-toggle value="Not Yet">Not Yet</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >3. Does your child do things just to get you to laugh?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q7">
              <mat-button-toggle value="Not Yet">Not Yet</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >4. Does your child try to get you to notice interesting
            objects—just to get you to look at the objects, not to get you to do
            anything with them?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q8">
              <mat-button-toggle value="Not Yet">Not Yet</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header"><b>Gestures</b></div>
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >1. Does your child pick up objects and give them to you?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q9">
              <mat-button-toggle value="Not Yet">Not Yet</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >2. Does your child show objects to you without giving you the
            object?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q10">
              <mat-button-toggle value="Not Yet">Not Yet</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >3. Does your child wave to greet people?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q11">
              <mat-button-toggle value="Not Yet">Not Yet</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >4. Does your child point to objects?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q12">
              <mat-button-toggle value="Not Yet">Not Yet</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >5. Does your child nod his/her head to indicate yes?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q13">
              <mat-button-toggle value="Not Yet">Not Yet</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header"><b>Sounds</b></div>
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >1. Does your child use sounds or words to get attention or
            help?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q14">
              <mat-button-toggle value="Not Yet">Not Yet</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >2. Does your child string sounds together, such as uh oh, mama,
            gaga, bye bye, dada?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q15">
              <mat-button-toggle value="Not Yet">Not Yet</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >3. About how many of the following consonant sounds does your child
            use: ma, na, ba, da, ga, wa, la, ya, sa, sha?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q16">
              <mat-button-toggle value="None">None</mat-button-toggle>
              <mat-button-toggle value="1-2">1-2</mat-button-toggle>
              <mat-button-toggle value="3-4">3-4</mat-button-toggle>
              <mat-button-toggle value="5-8">5-8</mat-button-toggle>
              <mat-button-toggle value="Over 8">Over 8</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header"><b>Words</b></div>
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >1. About how many different words does your child use meaningfully
            that you recognize (such as baba for bottle; gaggie for
            doggie)?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q17">
              <mat-button-toggle value="None">None</mat-button-toggle>
              <mat-button-toggle value="1-3">1-3</mat-button-toggle>
              <mat-button-toggle value="4-10">4-10</mat-button-toggle>
              <mat-button-toggle value="11-30">11-30</mat-button-toggle>
              <mat-button-toggle value="Over 30">Over 30</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >2. Does your child put two words together (for example, more
            cookie, bye bye Daddy)?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q18">
              <mat-button-toggle value="Not Yet">Not Yet</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header"><b>Understanding</b></div>
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >1. When you call your child's name, does he/she respond by looking
            or turning toward you?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q19">
              <mat-button-toggle value="Not Yet">Not Yet</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >2. About how many different words or phrases does your child
            understand without gestures? For example, if you say “where 's your
            tummy” “where 's Daddy,” “give me the ball,” or “come here,” without
            showing or pointing, your child will respond appropriately.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q20">
              <mat-button-toggle value="None">None</mat-button-toggle>
              <mat-button-toggle value="1-3">1-3</mat-button-toggle>
              <mat-button-toggle value="4-10">4-10</mat-button-toggle>
              <mat-button-toggle value="11-30">11-30</mat-button-toggle>
              <mat-button-toggle value="Over 30">Over 30</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header"><b>Object Use</b></div>
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >1. Does your child show interest in playing with a variety of
            objects?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q21">
              <mat-button-toggle value="Not Yet">Not Yet</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >2. About how many of the following objects does your child use
            appropriately: cup, bottle, bowl, spoon, comb or brush, toothbrush,
            washcloth, ball, toy vehicle, toy telephone?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q22">
              <mat-button-toggle value="None">None</mat-button-toggle>
              <mat-button-toggle value="1-2">1-2</mat-button-toggle>
              <mat-button-toggle value="3-4">3-4</mat-button-toggle>
              <mat-button-toggle value="5-8">5-8</mat-button-toggle>
              <mat-button-toggle value="Over 8">Over 8</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >3. About how many blocks (or rings) does your child stack?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q23">
              <mat-button-toggle value="None">None</mat-button-toggle>
              <mat-button-toggle value="2 blocks">2 blocks</mat-button-toggle>
              <mat-button-toggle value="3-4 blocks"
                >3-4 blocks</mat-button-toggle
              >
              <mat-button-toggle value="5 or more">5 or more</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >4. Does your child pretend to play with toys (for example, feed a
            stuffed animal, put a doll to sleep, put an animal figure in a
            vehicle)?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q24">
              <mat-button-toggle value="Not Yet">Not Yet</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          infantToddlerForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!infantToddlerForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
