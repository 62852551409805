<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Zung Depression Scale</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="zungDepressionScaleForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >1. I feel down hearted and blue</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="downHeartedAndBlue">
              <mat-button-toggle value="A little of the time"
                >A little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most of the time"
                >Most of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >2. Morning is when I feel the best</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="feelBestInMorning">
              <mat-button-toggle value="A little of the time"
                >A little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most of the time"
                >Most of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >3. I have crying spells or feel like it</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="feelLikeCrying">
              <mat-button-toggle value="A little of the time"
                >A little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most of the time"
                >Most of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >4. I have trouble sleeping at night</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="troubleSleepingAtNight">
              <mat-button-toggle value="A little of the time"
                >A little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most of the time"
                >Most of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >5. I eat as much as I used to</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="eatAsPlanned">
              <mat-button-toggle value="A little of the time"
                >A little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most of the time"
                >Most of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">6. I still enjoy sex</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="enjoySex">
              <mat-button-toggle value="A little of the time"
                >A little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most of the time"
                >Most of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >7. I notice that I am losing weight</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="losingWeight">
              <mat-button-toggle value="A little of the time"
                >A little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most of the time"
                >Most of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >8. I have trouble with constipation</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="troubleWithConstipation">
              <mat-button-toggle value="A little of the time"
                >A little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most of the time"
                >Most of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >9. My heart beats faster than usual</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="heartBeatsFasterThanUsual"
            >
              <mat-button-toggle value="A little of the time"
                >A little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most of the time"
                >Most of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >10. I get tired for no reason</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="getTiredForNoReason">
              <mat-button-toggle value="A little of the time"
                >A little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most of the time"
                >Most of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >11. My mind is as clear as it used to be</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="mindIsClear">
              <mat-button-toggle value="A little of the time"
                >A little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most of the time"
                >Most of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >12. I find it easy to do the things I used to</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="easyToDoThings">
              <mat-button-toggle value="A little of the time"
                >A little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most of the time"
                >Most of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >13. I am restless and can't keep still</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="restlessAndCantKeepStill">
              <mat-button-toggle value="A little of the time"
                >A little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most of the time"
                >Most of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >14. I feel hopeful about the future</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="hopefulAboutFuture">
              <mat-button-toggle value="A little of the time"
                >A little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most of the time"
                >Most of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >15. I am more irritable than usual</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="irritableThanUsual">
              <mat-button-toggle value="A little of the time"
                >A little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most of the time"
                >Most of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >16. I find it easy to make decisions</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="easyToMakeDecisions">
              <mat-button-toggle value="A little of the time"
                >A little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most of the time"
                >Most of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >17. I feel that I am useful and needed</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="feelUsefulAndNeeded">
              <mat-button-toggle value="A little of the time"
                >A little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most of the time"
                >Most of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >18. My life is pretty full</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="lifeIsPrettyFull">
              <mat-button-toggle value="A little of the time"
                >A little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most of the time"
                >Most of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >19. I feel that others would be better off if I were dead</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="othersWouldBeBetterIffIfIWereDead"
            >
              <mat-button-toggle value="A little of the time"
                >A little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most of the time"
                >Most of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >20. I still enjoy the things I used to do</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="enjoyTheThingsIUsedToDo">
              <mat-button-toggle value="A little of the time"
                >A little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most of the time"
                >Most of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          zungDepressionScaleForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!zungDepressionScaleForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
