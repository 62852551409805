<div
  class="main-content"
  style="background-color: #eee"
  *ngIf="!isLoadingResults"
>
  <div class="container-fluid">
    <div class="container-fluid">
      <br />
      <div class="row">
        <div class="col-md-6">
          <mat-card appearance="raised">
            <mat-card-header>
              <h4 class="header text-center">Overall Graph</h4>
            </mat-card-header>
            <mat-card-content>
              <div style="width: 100%">
                <div class="chart-wrapper">
                  <canvas
                    baseChart
                    [data]="lineChartData"
                    [options]="lineChartOptions"
                    [legend]="lineChartLegend"
                    [type]="lineChartType"
                    [plugins]="lineChartPlugins"
                  >
                  </canvas>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-md-6">
          <mat-card appearance="raised">
            <mat-card-header>
              <h4 class="header text-center">
                Interpreting the Overall Scores
              </h4>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
              <div class="row">
                <div
                  class="col-md-12"
                  style="padding-top: 4px; font-weight: 300"
                >
                  <ul>
                    <li>
                      Total Trauma-Related Shame Inventory score will be between
                      0 and 72
                    </li>
                    <li>
                      Internal Shame percentage is determined by (Internal score
                      / Total Trauma-Related Shame Inventory score)
                    </li>
                    <li>
                      External Guilt percentage is determined by (External score
                      / Total Trauma-Related Shame Inventory score)
                    </li>
                  </ul>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-md-6">
          <mat-card appearance="raised">
            <mat-card-header>
              <h4 class="header text-center">Internal Shame Percentage</h4>
            </mat-card-header>
            <mat-card-content>
              <div style="width: 100%">
                <div class="chart-wrapper">
                  <canvas
                    baseChart
                    [data]="internalChartData"
                    [options]="internalChartOptions"
                    [legend]="lineChartLegend"
                    [type]="lineChartType"
                    [plugins]="internalChartPlugins"
                  >
                  </canvas>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-md-6">
          <mat-card appearance="raised">
            <mat-card-header>
              <h4 class="header text-center">External Guilt Percentage</h4>
            </mat-card-header>
            <mat-card-content>
              <div style="width: 100%">
                <div class="chart-wrapper">
                  <canvas
                    baseChart
                    [data]="externalChartData"
                    [options]="internalChartOptions"
                    [legend]="lineChartLegend"
                    [type]="lineChartType"
                    [plugins]="internalChartPlugins"
                  >
                  </canvas>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <br />
      <mat-divider></mat-divider>
      <br />
      <div class="row">
        <ng-container *ngFor="let scoreDetail of scoreDetails">
          <div class="col-md-4" style="margin-bottom: 20px">
            <mat-card appearance="raised">
              <mat-card-header>
                <h4 class="header text-center">{{ scoreDetail.title }}</h4>
              </mat-card-header>
              <mat-card-content>
                <div style="width: 100%">
                  <div class="chart-wrapper">
                    <canvas
                      baseChart
                      [data]="scoreDetail"
                      [options]="assessmentDetailChartOptions"
                      [legend]="lineChartLegend"
                      [type]="lineChartType"
                      [plugins]="detailChartPlugins"
                    >
                    </canvas>
                  </div>
                </div>
              </mat-card-content>
              <small
                ><b>Answer:</b> <i>{{ scoreDetail.answer }}</i></small
              >
            </mat-card>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
