export const AdjustmentGroupCodes = [
  {
    code: 'CO',
    description: 'Contractual Obligation',
  },
  {
    code: 'CR',
    description: 'Corrections and Reversal',
  },
  {
    code: 'OA',
    description: 'Other Adjustment',
  },
  {
    code: 'PI',
    description: 'Payer Initiated Reductions',
  },
  {
    code: 'PR',
    description: 'Patient Responsibility',
  },
];

//
export const AdjustmentGroupCodesMap = new Map<string, string>([
  ['CO', 'Contractual Obligation'],
  ['CR', 'Corrections and Reversal'],
  ['OA', 'Other Adjustment'],
  ['PI', 'Payer Initiated Reductions'],
  ['PR', 'Patient Responsibility'],
]);
