<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>PTSD Screening</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="ptsdScreeningForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <p>
          <strong
            >Sometimes things happen to people that are unusually or especially
            frightening, horrible, or traumatic. For example:</strong
          >
        </p>
        <ul>
          <li><strong>a serious accident or fire</strong></li>
          <li><strong>a physical or sexual assault or abuse</strong></li>
          <li><strong>an earthquake or flood</strong></li>
          <li><strong>a war</strong></li>
          <li>
            <strong>seeing someone be killed or seriously injured</strong>
          </li>
          <li>
            <strong>having a loved one die through homicide or suicide.</strong>
          </li>
        </ul>

        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >1. Have you ever experienced this kind of event?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="q1"
              (change)="askFurtherQuestions()"
            >
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div *ngIf="showFurtherQuetsions">
          <p><strong>In the past month, have you...</strong></p>

          <div class="row pad10">
            <label class="col-md-6 asterix-after"
              >2. had nightmares about the event(s) or thought about the
              event(s) when you did not want to?</label
            >
            <div class="col-sm-6">
              <mat-button-toggle-group formControlName="q2">
                <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                <mat-button-toggle value="No">No</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="row pad10">
            <label class="col-md-6 asterix-after"
              >3. tried hard not to think about the event(s) or went out of your
              way to avoid situations that reminded you of the event(s)?</label
            >
            <div class="col-sm-6">
              <mat-button-toggle-group formControlName="q3">
                <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                <mat-button-toggle value="No">No</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="row pad10">
            <label class="col-md-6 asterix-after"
              >4. been constantly on guard, watchful, or easily startled?</label
            >
            <div class="col-sm-6">
              <mat-button-toggle-group formControlName="q4">
                <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                <mat-button-toggle value="No">No</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="row pad10">
            <label class="col-md-6 asterix-after"
              >5. felt numb or detached from people, activities, or your
              surroundings?</label
            >
            <div class="col-sm-6">
              <mat-button-toggle-group formControlName="q5">
                <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                <mat-button-toggle value="No">No</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="row pad10">
            <label class="col-md-6 asterix-after"
              >6. felt guilty or unable to stop blaming yourself or others for
              the event(s) or any problems the event(s) may have caused?</label
            >
            <div class="col-sm-6">
              <mat-button-toggle-group formControlName="q6">
                <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                <mat-button-toggle value="No">No</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          ptsdScreeningForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!ptsdScreeningForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
