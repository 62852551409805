<!-- Top Navigation with patient details -->
<p style="font-size: 16px; font-weight: 450; white-space: nowrap" *ngIf="displayTitle">
    <a routerLink="/" routerLinkActive="active">
      <em class="material-icons text_align-center" style="font-size: 18px; margin-top: -3px">home</em>
    </a>
    &nbsp; / &nbsp; Form
  </p>
  
  <mat-progress-bar mode="indeterminate" *ngIf="isLoadingResults"></mat-progress-bar>
  
  <survey *ngIf="!isLoadingResults" [model]="model" style="min-height: 1000px"></survey>
  
  <div class="row" *ngIf="formSubmitted && displayTitle" style="margin-top: 25px">
    <div class="col-md-12" style="text-align: center">
      <button mat-raised-button color="warn" (click)="closeScreen()">
        Back to Homepage
      </button>
    </div>
  </div>