import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTablesModel } from 'src/app/_models';
import {
  AssessmentApiService,
  AssessmentHistoryApiService,
  CoreService,
  DateTimeZoneService,
  ToastMessageService,
} from 'src/app/_services';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { assessmentGroups } from 'src/app/shared/utilities/assessment-utils';
import { AssessmentChartDialogInterfaceComponent } from '../assessment-chart-dialog-interface/assessment-chart-dialog-interface.component';
import { AssessmentDialogInterfaceComponent } from '../assessment-dialog-interface/assessment-dialog-interface.component';
import { AssessmentMainDialogComponent } from '../assessment-main-dialog/assessment-main-dialog.component';
import { PatientAssessmentGoalsComponent } from '../patient-assessment-goals/patient-assessment-goals.component';
import { PrintGuidanceComponent } from '../print-guidance/print-guidance.component';
import { ViewGuidanceComponent } from '../view-guidance/view-guidance.component';
import { ViewPatientAssessmentGoalsComponent } from '../view-patient-assessment-goals/view-patient-assessment-goals.component';
import { ViewSummaryComponent } from '../view-summary/view-summary.component';

@Component({
  selector: 'app-assessment-history',
  templateUrl: './assessment-history.component.html',
  styleUrls: ['./assessment-history.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AssessmentHistoryComponent implements OnInit {
  @Input() patientId: string;
  @Input() showHeader: boolean;
  @Input() showSearchMember: boolean;
  @Input() status: string;
  @Input() sessionId: string;
  @Input() reloadCounter: number;
  @Input() showFilter: boolean;
  @Input() hideMemberName: boolean;
  @Input() hideSessionId: boolean;
  @Input() hideCompletedBy: boolean;
  @Input() hideCreatedDate: boolean;

  @Input() hasHospitalDeviceFeature: boolean;
  @Input() hasMemberAssessmentAddAccess: boolean;
  @Input() hasMemberAssessmentEditAccess: boolean;
  @Input() hasMemberAssessmentDeleteAccess: boolean;
  @Input() hasAssessmentChartsViewAccess: boolean;
  @Input() hasAssessmentGuidanceViewAccess: boolean;
  @Input() hasAssessmentGuidanceEditAccess: boolean;
  @Input() hasAssessmentSummaryViewAccess: boolean;
  @Input() hasAssessmentSummaryEditAccess: boolean;
  @Input() hasAssessmentTakeAddAccess: boolean;

  @Output() output = new EventEmitter<any>();

  displayedColumns = [
    'createdDate',
    'assessmentType',
    'status',
    'riskScore',
    'createdBy',
    'actions',
  ];
  data: DataTablesModel = {} as DataTablesModel;
  processing$ = false;
  isLoadingResults = true;
  approveProcessing = false;
  action: string = '';
  error: string = '';

  pageIndex: number = 0;
  isPhysician: boolean = false;
  isPatient: boolean = false;
  math = Math;

  sortBy: string = 'None';
  filterOption: string = 'None';
  searchByMName: string = '';
  currentTimezone: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    public assessmenthistoryApiService: AssessmentHistoryApiService,
    public assessmentApiService: AssessmentApiService,
    private toastMessageService: ToastMessageService,
    private coreService: CoreService,
    private route: ActivatedRoute,
    private router: Router,
    private dateTimeZoneService: DateTimeZoneService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit() {
    this.data.page = 0;
    this.data.per_page = 10;

    this.isPhysician = this.coreService.isPhysician();
    this.isPatient = this.coreService.isPatient();

    this.pageIndex = 0;
    if (this.paginator != null) {
      this.paginator.pageIndex = 0;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.route.snapshot.paramMap.get('memberId')) {
      this.patientId = this.route.snapshot.paramMap.get('memberId');
    }

    this.data.page = 0;
    this.data.per_page = 10;

    if (changes.status) {
      this.searchByMName = '';
      this.filterOption = 'None';
      this.sortBy = 'None';
      this.pageIndex = 0;

      this.status = changes.status.currentValue;
      if (this.status === 'Pending') {
        this.displayedColumns = [
          'createdDate',
          'sessionId',
          'patientName',
          'assessmentType',
        ];
        // Check if it is physician or not
        if (this.isPhysician) {
          if (!this.displayedColumns.includes('pendingAction')) {
            this.displayedColumns.push('pendingAction');
          }
        }
      } else if (this.status === 'In Review') {
        this.displayedColumns = [
          'createdDate',
          'sessionId',
          'patientName',
          'assessmentType',
          'riskScore',
          'createdBy',
          'actions',
        ];

        if (this.hideMemberName) {
          this.displayedColumns = this.displayedColumns.filter(
            (name) => name != 'patientName'
          );
        }
        if (this.hideSessionId) {
          this.displayedColumns = this.displayedColumns.filter(
            (name) => name != 'sessionId'
          );
        }
        if (this.hideCompletedBy) {
          this.displayedColumns = this.displayedColumns.filter(
            (name) => name != 'createdBy'
          );
        }
        if (this.hideCreatedDate) {
          this.displayedColumns = this.displayedColumns.filter(
            (name) => name != 'createdDate'
          );
        }
      } else if (this.status === 'Completed') {
        this.displayedColumns = [
          'createdDate',
          'completedDate',
          'sessionId',
          'patientName',
          'assessmentType',
          'riskScore',
          'createdBy',
          'actions',
        ];

        if (this.hideMemberName) {
          this.displayedColumns = this.displayedColumns.filter(
            (name) => name != 'patientName'
          );
        }
        if (this.hideSessionId) {
          this.displayedColumns = this.displayedColumns.filter(
            (name) => name != 'sessionId'
          );
        }
      } else {
        this.displayedColumns = [
          'createdDate',
          'sessionId',
          'assessmentType',
          'status',
          'riskScore',
          'createdBy',
          'actions',
        ];
      }
    }

    this.loadAssessmentHistoryList(this.patientId);
  }

  navigateToSessionPage(row) {
    if (row.groupAppointment) {
      // For Normal navigate to session edit
      this.router.navigate([`/group-sessions/${row.sessionId}`], {
        queryParams: {
          parent: 'Assessments',
        },
      });
    } else {
      this.router.navigate(['/main/member/' + row.patientId + '/NOTES'], {
        queryParams: {
          secondaryId: row.sessionId,
        },
      });
    }
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.pageIndex = event.pageIndex;
    this.loadAssessmentHistoryList(this.patientId);
  }

  public loadAssessmentHistoryList(patientId: string) {
    this.isLoadingResults = true;
    this.error = '';

    let searchName = '';
    let filter = '';
    let sort = '';

    // Determining search by name options here
    if (this.searchByMName && this.searchByMName.length > 0) {
      searchName = this.searchByMName;
    } else {
      searchName = null;
    }

    // Determining filter option here
    if (this.filterOption === 'None') {
      filter = null;
    } else {
      filter = this.filterOption;
    }

    // Determining sort option here
    if (this.sortBy === 'None') {
      sort = null;
    } else {
      if (this.sortBy === 'Date closest to now') {
        sort = 'DESC';
      }
      if (this.sortBy === 'Date farthest from now') {
        sort = 'ASC';
      }
    }

    this.assessmenthistoryApiService
      .getAssessmentHistoryList(
        patientId,
        this.sessionId,
        this.status,
        this.data.per_page,
        this.data.page,
        filter,
        sort,
        searchName
      )
      .subscribe(
        (response) => {
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }
          this.isLoadingResults = false;
        },
        (error) => {
          this.error = error;
          this.isLoadingResults = false;
        }
      );
  }

  openDialog(
    dialogType: string,
    assessmentType: string,
    assessmentId: string,
    assessmentRequestId: string,
    patientId: string,
    anchorTag?: string
  ) {
    let dialogComponent: any = {};
    dialogComponent.history = AssessmentDialogInterfaceComponent;
    dialogComponent.chart = AssessmentChartDialogInterfaceComponent;

    if (dialogComponent) {
      let component = dialogComponent.history;
      if (dialogType === 'chart') {
        component = dialogComponent.chart;
      }
      const dialogRef = this.dialog.open(component, {
        minWidth: '50vw',
        maxWidth: '90vw',
        minHeight: '50vh',
        maxHeight: '90vh',
        disableClose: true,
        autoFocus: false,
        data: {
          patientId: patientId,
          assessmentId: assessmentId,
          assessmentRequestId: assessmentRequestId,
          assessmentType: assessmentType,
          displayTitle: true,
          anchorTag: anchorTag,
          isPatient: this.isPatient,
        },
      });
    }
  }

  loadGuidanceDoc(record: any) {
    const dialogRef = this.dialog.open(ViewGuidanceComponent, {
      width: '1024px',
      height: '800px',
      disableClose: true,
      autoFocus: false,
      data: { record: record },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.loadAssessmentHistoryList(this.patientId);
    });
  }

  loadAssessmentGoals(row: any) {
    const dialogRef = this.dialog.open(PatientAssessmentGoalsComponent, {
      minWidth: '40vw',
      minHeight: '50vh',
      disableClose: true,
      autoFocus: false,
      data: { record: row },
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        if (response === 'reload') {
          this.loadAssessmentHistoryList(this.patientId);
        }
      }
    });
  }

  viewAssessmentGoals(row) {
    let dialogRef = this.dialog.open(ViewPatientAssessmentGoalsComponent, {
      minWidth: '50vw',
      minHeight: '50vh',
      autoFocus: false,
      data: { record: row },
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        if (response === 'reload') {
          this.loadAssessmentHistoryList(this.patientId);
        }
      }
    });
  }

  summary(record: any) {
    const dialogRef = this.dialog.open(ViewSummaryComponent, {
      minWidth: '30vw',
      disableClose: true,
      autoFocus: false,
      data: { record: record },
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadAssessmentHistoryList(this.patientId);
      }
    });
  }

  approveAssessment(record: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Submit Assessment',
        msg: 'Are you sure that you want to move the assessment to completed status?',
        yesButtonTitle: 'Yes',
        noButtonTitle: 'No',
      },
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.approveProcessing = true;
        this.assessmenthistoryApiService
          .updateAssessmentStatus(record.id)
          .subscribe(
            (response) => {
              this.approveProcessing = false;
              this.loadAssessmentHistoryList(this.patientId);
              this.toastMessageService.displaySuccessMessage(
                'Successfully updated Assessment to completed status'
              );
            },
            (error) => {
              this.approveProcessing = false;
              this.toastMessageService.displayErrorMessage(
                'An error occured while updating Assessment to completed status'
              );
            }
          );
      }
    });
  }

  printAssessment(
    assessmentType: string,
    assessmentId: string,
    patientId: string
  ) {
    const dialogRef = this.dialog.open(PrintGuidanceComponent, {
      width: '1024px',
      height: '800px',
      disableClose: true,
      autoFocus: false,
      data: {
        patientId: patientId,
        assessmentId: assessmentId,
        assessmentType: assessmentType,
      },
    });
  }

  assessmentGroups: any[] = assessmentGroups();

  // Filter and sort options
  sortByChanged(sortOption: string) {
    // First restore pagination
    this.data.page = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.pageIndex = 0;

    this.sortBy = sortOption;
    this.loadAssessmentHistoryList(this.patientId);
  }

  searchByNameChanged() {
    // First restore pagination
    this.data.page = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.pageIndex = 0;

    this.loadAssessmentHistoryList(this.patientId);
  }

  filterOptionChanged(filterOption: string) {
    // First restore pagination
    this.data.page = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.pageIndex = 0;

    this.filterOption = filterOption;
    this.loadAssessmentHistoryList(this.patientId);
  }

  // Score Board
  getTitle(numberator: number, denominator: number) {
    return numberator + ' / ' + denominator;
  }

  getSubTitle(percentage) {
    return percentage + '%';
  }

  openDeleteConfirm(row) {
    let dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to cancel this assessment request?',
      },
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.isLoadingResults = true;

        this.assessmenthistoryApiService
          .deletePendingAssessment(row.id, row.patientId, row.organizationId)
          .subscribe(
            (response) => {
              this.isLoadingResults = false;
              this.toastMessageService.displaySuccessMessage(
                'Assessment request has been canceled'
              );
              // Handling delete pagination
              if (this.data.items.length == 1) {
                this.data.page = this.data.page - 1;
                if (this.data.page < 0) {
                  this.data.page = 0;
                }
              }
              this.loadAssessmentHistoryList(this.patientId);
            },
            (error) => {
              this.isLoadingResults = false;
              this.toastMessageService.displayErrorMessage(
                'Error: Something went wrong while cancelling the assessment request.'
              );
            }
          );
      }
    });
  }

  getCustomChartColors(row, part: string) {
    var colorRed = '#FF6347';
    var colorOrange = '#FF9900';
    var colorYellow = '#FFCC00';
    var colorGreen = '#78C000';
    var colorRedOrange = '#E86850';

    // Determining color for each assessment
    switch (row.assessmentType) {
      // Starting with alcohol addiction
      case 'Shopping Addiction Assessment':
        if (row.riskScore <= 3) {
          return colorGreen;
        } else {
          return colorRed;
        }
        break;
      case 'Alcohol Addiction Assessment':
        if (row.riskScore <= 1) {
          return colorGreen;
        } else {
          return colorRed;
        }
        break;
      case 'Clinical Opiate Withdrawal Scale (COWS)':
        if (row.riskScore < 5) {
          return colorGreen;
        } else if (row.riskScore < 12) {
          return colorYellow;
        } else if (row.riskScore < 24) {
          return colorOrange;
        } else if (row.riskScore <= 36) {
          return colorRedOrange;
        } else {
          return colorRed;
        }
        break;
      case 'Clinical Institute Withdrawal Assessment of Alcohol Scale, Revised (CIWA-AR)':
        if (row.riskScore < 8) {
          return colorGreen;
        } else if (row.riskScore < 16) {
          return colorOrange;
        } else {
          return colorRed;
        }
        break;
      case 'Columbia-Suicide Severity Rating Scale (CSSRS)':
        if (row.riskScore <= 1) {
          return colorGreen;
        } else {
          return colorRed;
        }
        break;
      case 'Gambling Addiction Assessment':
        if (row.riskScore == 0) {
          return colorGreen;
        } else if (row.riskScore >= 1 && row.riskScore <= 2) {
          return colorYellow;
        } else if (row.riskScore >= 3 && row.riskScore <= 4) {
          return colorOrange;
        } else {
          return colorRed;
        }
        break;
      case 'Gaming Addiction Assessment':
        if (row.riskScore >= 0 && row.riskScore <= 30) {
          return colorGreen;
        } else if (row.riskScore > 30 && row.riskScore <= 49) {
          return colorYellow;
        } else if (row.riskScore >= 50 && row.riskScore <= 79) {
          return colorOrange;
        } else {
          return colorRed;
        }
        break;
      case 'Internet Addiction Assessment':
        if (row.riskScore >= 0 && row.riskScore <= 29) {
          return colorGreen;
        } else if (row.riskScore > 30 && row.riskScore <= 39) {
          return colorYellow;
        } else if (row.riskScore >= 40 && row.riskScore <= 59) {
          return colorOrange;
        } else {
          return colorRed;
        }
        break;
      case 'Sexual Addiction Assessment':
        if (row.riskScore <= 6) {
          return colorGreen;
        } else {
          return colorRed;
        }
        break;
      case 'DSM-5-TR Level 2 - Substance Use | Age 11-17':
        if (row.riskScore > 2) {
          return colorRed;
        } else {
          return colorGreen;
        }
        break;
      case 'TAPS-I & TAPS-II':
        if (row.riskScore > 2) {
          return colorRed;
        } else {
          return colorGreen;
        }
        break;

      // ADHD Assessments
      case 'Adult ADHD Self-Report Scale':
        if (part === 'partA') {
          if (row.props['partA'] <= 3) {
            return colorGreen;
          } else {
            return colorRed;
          }
        } else if (part === 'partB') {
          if (row.props['partB'] <= 6) {
            return colorGreen;
          } else {
            return colorRed;
          }
        }
        break;
      case 'Vanderbilt ADHD Diagnostic Rating Scale (VADRS)':
        if (row.riskScore <= 45) {
          return colorGreen;
        } else if (row.riskScore > 45 && row.riskScore <= 90) {
          return colorYellow;
        } else if (row.riskScore > 90 && row.riskScore <= 135) {
          return colorOrange;
        } else {
          return colorRed;
        }
        break;
      case 'Connor-Davidson Resilience Scale(CD-RISC 10)':
        if (row.riskScore <= 20) {
          return colorRed;
        } else {
          return colorGreen;
        }
        break;
      case 'Buss Perry Aggression Questionnaire':
        if (part === 'pa-score') {
          if (row.props['pa-score'] <= 0.4) {
            return colorGreen;
          } else if (row.props['pa-score'] <= 0.6) {
            return colorYellow;
          } else if (row.props['pa-score'] <= 0.7) {
            return colorOrange;
          } else {
            return colorRed;
          }
        } else if (part === 'va-score') {
          if (row.props['va-score'] <= 0.4) {
            return colorGreen;
          } else if (row.props['va-score'] <= 0.6) {
            return colorYellow;
          } else if (row.props['va-score'] <= 0.7) {
            return colorOrange;
          } else {
            return colorRed;
          }
        } else if (part === 'h-score') {
          if (row.props['h-score'] <= 0.4) {
            return colorGreen;
          } else if (row.props['h-score'] <= 0.6) {
            return colorYellow;
          } else if (row.props['h-score'] <= 0.7) {
            return colorOrange;
          } else {
            return colorRed;
          }
        } else if (part === 'a-score') {
          if (row.props['a-score'] <= 0.4) {
            return colorGreen;
          } else if (row.props['a-score'] <= 0.6) {
            return colorYellow;
          } else if (row.props['a-score'] <= 0.7) {
            return colorOrange;
          } else {
            return colorRed;
          }
        }
        break;
      case 'Generalized Anxiety Disorder 7 Item Scale':
        if (row.riskScore <= 5) {
          return colorGreen;
        } else if (row.riskScore <= 10) {
          return colorYellow;
        } else if (row.riskScore <= 15) {
          return colorOrange;
        } else {
          return colorRed;
        }
        break;
      case 'Hamilton Anxiety Rating Scale':
        if (row.riskScore <= 17) {
          return colorGreen;
        } else if (row.riskScore <= 24) {
          return colorYellow;
        } else if (row.riskScore <= 30) {
          return colorOrange;
        } else {
          return colorRed;
        }
        break;
      case 'Panic and Agoraphobia Scale':
        if (row.riskScore <= 13) {
          return colorGreen;
        } else if (row.riskScore <= 17) {
          return colorYellow;
        } else if (row.riskScore <= 41) {
          return colorOrange;
        } else {
          return colorRed;
        }
        break;
      case 'Penn State Worry Questionnaire':
        if (row.riskScore <= 19) {
          return colorGreen;
        } else if (row.riskScore <= 39) {
          return colorYellow;
        } else if (row.riskScore <= 59) {
          return colorOrange;
        } else {
          return colorRed;
        }
        break;
      case 'Short Health Anxiety Inventory':
        if (part === 'riskScore') {
          if (row.riskScore <= 19) {
            return colorGreen;
          } else if (row.riskScore <= 29) {
            return colorYellow;
          } else if (row.riskScore <= 39) {
            return colorOrange;
          } else {
            return colorRed;
          }
        } else if (part === 'primary') {
          if (row.props['primary'] <= 19) {
            return colorGreen;
          } else {
            return colorRed;
          }
        } else if (part === 'negative') {
          if (row.props['negative'] <= 6) {
            return colorGreen;
          } else {
            return colorRed;
          }
        }

        break;
      case 'Social Phobia Inventory':
        if (row.riskScore <= 20) {
          return colorGreen;
        } else if (row.riskScore <= 30) {
          return colorYellow;
        } else if (row.riskScore <= 40) {
          return colorOrange;
        } else if (row.riskScore <= 50) {
          return '#ab5570';
        } else {
          return colorRed;
        }
        break;
      case "Spence Children's Anxiety Scale":
        if (row.riskScore <= 59) {
          return colorGreen;
        } else {
          return colorRed;
        }
        break;
      case 'Taylor Manifest Anxiety Scale':
        if (row.riskScore <= 18) {
          return colorGreen;
        } else {
          return colorRed;
        }
        break;
      case 'Zung Self-Rating Scale':
        if (row.riskScore <= 44) {
          return colorGreen;
        } else if (row.riskScore <= 59) {
          return colorYellow;
        } else if (row.riskScore <= 74) {
          return colorOrange;
        } else {
          return colorRed;
        }
        break;
      case 'Autism Spectrum Quotient':
        if (row.riskScore <= 25) {
          return colorGreen;
        } else if (row.riskScore <= 32) {
          return colorYellow;
        } else {
          return colorRed;
        }
        break;
      case 'Autism Spectrum Screening Questionnaire':
        if (row.riskScore <= 12) {
          return colorGreen;
        } else {
          return colorRed;
        }
        break;
      case 'Childhood Autism Spectrum Test':
        if (row.riskScore <= 14) {
          return colorGreen;
        } else {
          return colorRed;
        }
        break;
      case 'Infant-Toddler Checklist':
        if (part === 'riskScore') {
          if (row.riskScore <= 13) {
            return colorRed;
          } else {
            return colorGreen;
          }
        } else if (part === 'communication') {
          if (row.props['communication'] <= 7) {
            return colorRed;
          } else {
            return colorGreen;
          }
        } else if (part === 'speech') {
          if (row.props['speech'] <= 2) {
            return colorRed;
          } else {
            return colorGreen;
          }
        } else if (part === 'symbolic') {
          if (row.props['symbolic'] <= 3) {
            return colorRed;
          } else {
            return colorGreen;
          }
        }
        break;
      case 'Quantitative Checklist for Autism in Toddlers':
        if (row.riskScore <= 29) {
          return colorGreen;
        } else if (row.riskScore <= 49) {
          return colorYellow;
        } else if (row.riskScore <= 79) {
          return colorOrange;
        } else {
          return colorRed;
        }
        break;
      case 'Ritvo Autism & Asperger Diagnostic Scale':
        if (row.riskScore <= 13) {
          return colorGreen;
        } else {
          return colorRed;
        }
        break;
      case 'Altman Self-Rating Mania Scale':
        if (row.riskScore <= 5) {
          return colorGreen;
        } else {
          return colorRed;
        }
        break;
      case 'Young Mania Rating Scale':
        if (row.riskScore <= 20) {
          return colorGreen;
        } else {
          return colorRed;
        }
        break;
      case 'Edinburgh Postnatal Depression Scale (EPDS)':
        if (row.riskScore <= 9) {
          return colorGreen;
        } else {
          return colorRed;
        }
        break;
      case 'Geriatric Depression Scale (GDS)':
        if (row.riskScore <= 10) {
          return colorGreen;
        } else if (row.riskScore <= 20) {
          return colorYellow;
        } else {
          return colorRed;
        }
        break;
      case 'Kutcher Adolescent Depression Scale':
        if (row.riskScore <= 10) {
          return colorGreen;
        } else if (row.riskScore <= 21) {
          return colorYellow;
        } else {
          return colorRed;
        }
        break;
      case 'Major Depression Inventory':
        if (row.riskScore <= 20) {
          return colorGreen;
        } else if (row.riskScore <= 30) {
          return colorYellow;
        } else if (row.riskScore <= 40) {
          return colorOrange;
        } else {
          return colorRed;
        }
        break;
      case 'Patient Health Questionnaire':
        if (row.riskScore <= 4) {
          return colorGreen;
        } else if (row.riskScore <= 14) {
          return colorYellow;
        } else if (row.riskScore <= 19) {
          return colorOrange;
        } else {
          return colorRed;
        }
        break;
      case 'Zung Self-Rating Depression Scale':
        if (row.riskScore <= 44) {
          return colorGreen;
        } else if (row.riskScore <= 59) {
          return colorYellow;
        } else if (row.riskScore <= 69) {
          return colorOrange;
        } else {
          return colorRed;
        }
        break;
      case 'Binge Eating Scale':
        if (row.riskScore <= 17) {
          return colorGreen;
        } else if (row.riskScore <= 26) {
          return colorOrange;
        } else {
          return colorRed;
        }
        break;
      case 'Eating Attitudes Test':
        if (row.riskScore <= 20) {
          return colorGreen;
        } else if (row.riskScore <= 25) {
          return colorYellow;
        } else if (row.riskScore <= 30) {
          return colorOrange;
        } else {
          return colorRed;
        }
        break;
      case 'DSM 5 Level 1 Adult Assessment':
        if (row.riskScore < 46) {
          return colorGreen;
        } else {
          return colorRed;
        }
        break;
      case 'DSM 5 Level 1 (11-17) Assessment':
      case 'DSM 5 Level 1 (6-17) Assessment':
        if (row.riskScore <= 38) {
          return colorGreen;
        } else {
          return colorRed;
        }
        break;
      case 'YP-CORE 10 Assessment':
      case 'CORE 10 Assessment':
        if (row.riskScore <= 9) {
          return colorGreen;
        } else if (row.riskScore <= 19) {
          return colorYellow;
        } else if (row.riskScore <= 24) {
          return colorOrange;
        } else {
          return colorRed;
        }
        break;
      case 'Differentiation of Self Inventory - Short Form':
        if (row.riskScore <= 3.5) {
          return colorRed;
        } else {
          return colorGreen;
        }
        break;

      case 'The Mindful Attention Awareness Scale (MAAS)':
        if (row.riskScore <= 3.5) {
          return colorRed;
        } else {
          return colorGreen;
        }
        break;
      case 'Obsessive Compulsive Inventory':
      case 'Obsessive Compulsive Inventory - Revised':
        if (row.riskScore <= 19) {
          return colorGreen;
        } else {
          return colorRed;
        }
        break;
      case 'Aversive Childhood Experiences Scale (ACES)':
        if (row.riskScore <= 1) {
          return colorGreen;
        } else if (row.riskScore <= 2) {
          return colorYellow;
        } else if (row.riskScore <= 3) {
          return colorOrange;
        } else {
          return colorRed;
        }
        break;
      case 'Adult Attachment Scale (AAS)':
        if (part === 'close') {
          if (row.props['close'] > 2.5) {
            return colorGreen;
          } else {
            return colorRed;
          }
        } else if (part === 'depend') {
          if (row.props['depend'] < 2.5) {
            return colorGreen;
          } else {
            return colorRed;
          }
        } else if (part === 'anxiety') {
          if (row.props['anxiety'] < 2.5) {
            return colorGreen;
          } else {
            return colorRed;
          }
        }
        break;
      case 'Child-Parent Relationship Scale (CPRS)':
        if (part === 'closeness') {
          if (row.props['closeness'] > 35) {
            return colorGreen;
          } else {
            return colorRed;
          }
        } else if (part === 'conflict') {
          if (row.props['conflict'] < 15) {
            return colorGreen;
          } else {
            return colorRed;
          }
        } else if (part === 'dependence') {
          if (row.props['dependence'] < 10) {
            return colorGreen;
          } else {
            return colorRed;
          }
        }
        break;
      case 'Family APGAR':
        if (row.riskScore <= 5) {
          return colorRed;
        } else {
          return colorGreen;
        }
        break;
      case 'The Couples Satisfaction Index (CSI)':
        if (row.riskScore <= 80) {
          return colorRed;
        } else {
          return colorGreen;
        }
        break;
      case 'Big 5 Personality Test':
        if (part === 'a-max') {
          if (row.props['a-score'] < 20) {
            return colorRed;
          } else {
            return colorGreen;
          }
        } else if (part === 'e-max') {
          if (row.props['e-score'] < 20) {
            return colorRed;
          } else {
            return colorGreen;
          }
        } else if (part === 'c-max') {
          if (row.props['c-score'] < 20) {
            return colorRed;
          } else {
            return colorGreen;
          }
        } else if (part === 'n-max') {
          if (row.props['n-score'] < 20) {
            return colorGreen;
          } else {
            return colorRed;
          }
        } else if (part === 'o-max') {
          if (row.props['o-score'] < 20) {
            return colorRed;
          } else {
            return colorGreen;
          }
        }
        break;
      case 'Empathy Quotient':
        if (row.riskScore <= 30) {
          return colorRed;
        } else {
          return colorGreen;
        }
        break;
      case 'Hare Psychopathy Checklist':
        if (row.riskScore <= 10) {
          return colorGreen;
        } else if (row.riskScore <= 21) {
          return colorYellow;
        } else if (row.riskScore <= 32) {
          return colorOrange;
        } else {
          return colorRed;
        }
        break;
      case 'Personality Type Indicator':
        return '#C7E596';
        break;
      case 'Revised Mystical Experience Questionnaire':
        return '#C7E596';
        break;
      case 'Toronto Empathy Questionnaire':
        if (row.riskScore <= 44) {
          return colorRed;
        } else {
          return colorGreen;
        }
        break;
      case 'Inventory of Complicated Grief':
        if (row.riskScore <= 24) {
          return colorGreen;
        } else {
          return colorRed;
        }
        break;
      case 'Primary Care PTSD Screen':
        if (row.riskScore <= 2) {
          return colorGreen;
        } else {
          return colorRed;
        }
        break;
      case 'Trauma-Related Shame Assessment':
        if (part === 'riskScore') {
          if (row.riskScore <= 24) {
            return colorGreen;
          } else {
            return colorRed;
          }
        } else if (part === 'internal') {
          if (row.props['internal'] <= 10) {
            return colorGreen;
          } else {
            return colorRed;
          }
        } else if (part === 'external') {
          if (row.props['external'] <= 10) {
            return colorGreen;
          } else {
            return colorRed;
          }
        }
        break;
    }
  }

  getBackgroundColor(row, type) {
    let filteredGoals = row.assessmentGoals.filter((goal) => {
      if (goal.status != 'Deleted') {
        return true;
      } else {
        return false;
      }
    });

    let totalGoals = filteredGoals.length;
    let count = 0;

    row?.assessmentGoals.forEach((goal) => {
      if (goal.status === 'Completed') {
        count++;
      }
    });

    let percentage = 0;

    if (count == 0) {
      if (type === 'color') {
        return 'warn';
      } else {
        return percentage;
      }
    } else {
      percentage = Math.floor((count / totalGoals) * 100);
      if (percentage <= 40) {
        if (type === 'color') {
          return 'warn';
        } else {
          return percentage;
        }
      } else if (percentage < 100) {
        if (type === 'color') {
          return 'accent';
        } else {
          return percentage;
        }
      } else {
        if (type === 'color') {
          return 'primary';
        } else {
          return percentage;
        }
      }
    }
  }

  // Take Assessment()
  takeAssessment(assessment) {
    const dialogRef = this.dialog.open(AssessmentMainDialogComponent, {
      minWidth: '50vw',
      minHeight: '50vh',
      maxHeight: '95vh',
      disableClose: true,
      autoFocus: false,
      data: {
        patientId: assessment.patientId,
        sessionId: assessment.sessionId,
        assessmentType: assessment.assessmentType,
        requestId: assessment.id,
      },
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        if (this.data.items.length == 1) {
          this.data.page = this.data.page - 1;
          if (this.data.page < 0) {
            this.data.page = 0;
          }
        }

        this.loadAssessmentHistoryList(this.patientId);
      }
    });
  }
}
