<div class="container">
  <div class="dialogTitle" *ngIf="isModalWindow">
    <p matDialogTitle>Authorization To Release Medical Records</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="releaseForm" novalidate>
    <div class="row" style="margin-bottom: 50px">
      <!-- Information To Be Sent To  -->
      <div class="col-md-12" style="margin-bottom: 15px">
        <label class="labelHeader"> Information To Be Sent To </label>

        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-6">
              <label class="labelText">
                Name of Designated Recipient <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Name"
                  formControlName="sentToName"
                  name="sentToName"
                  minlength="1"
                  required
                />
                <mat-error *ngIf="releaseForm.controls['sentToName'].invalid">
                  Name of the recipient is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <label class="labelText">
                Gender <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="sentToGender"
                  placeholder="Gender"
                  required
                >
                  <mat-option [value]="gen.id" *ngFor="let gen of genderList">
                    {{ gen.description }}
                  </mat-option>
                </mat-select>

                <mat-error *ngIf="releaseForm.controls['sentToGender'].invalid">
                  Gender is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <label class="labelText"> Home Phone Number </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="sentToHomePhone"
                  name="sentToHomePhone"
                  placeholder="(999) 999-9999"
                />
                <mat-error
                  *ngIf="releaseForm.controls['sentToHomePhone'].invalid"
                >
                  Please enter a valid phone number
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <label class="labelText"> Work Phone Number </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="sentToWorkPhone"
                  name="sentToWorkPhone"
                  placeholder="(999) 999-9999"
                />
                <mat-error
                  *ngIf="releaseForm.controls['sentToWorkPhone'].invalid"
                >
                  Please enter a valid phone number
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-8">
              <label class="labelText">
                Address 1 <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  [options]="options"
                  ngx-gp-autocomplete
                  #placesRef="ngx-places"
                  (onAddressChange)="handleAddressChange($event)"
                  formControlName="addressLine1"
                  name="addressLine1"
                  required
                />

                <mat-error *ngIf="releaseForm.controls['addressLine1'].invalid">
                  Please enter the address line 1
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">Address 2</label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="addressLine2"
                  name="addressLine2"
                />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">
                City <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="city"
                  name="city"
                  placeholder="City"
                />
                <mat-error *ngIf="releaseForm.controls['city'].invalid">
                  Please enter the city
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">
                State <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="state" placeholder="State">
                  <mat-option [value]="state.id" *ngFor="let state of usStates">
                    {{ state.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="releaseForm.controls['state'].invalid">
                  Please select the state
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">
                Zip <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="zip"
                  name="zip"
                  placeholder="Zip"
                />
                <mat-error *ngIf="releaseForm.controls['zip'].invalid">
                  Please enter a valid zip
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Information To Be Released  -->
      <div class="col-md-12" style="margin-bottom: 15px">
        <label class="labelHeader"> Information To Be Released </label>
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-12">
              <label class="labelText">
                Please select one <span style="color: red">*</span>
              </label>
            </div>
            <div class="col-md-12" style="margin-bottom: 12px">
              <mat-radio-group
                aria-label="Select an option"
                formControlName="infoReleasedType"
                style="
                  column-gap: 15px;
                  display: flex;
                  flex-direction: column;
                  flex-wrap: wrap;
                "
                color="primary"
                (change)="infoReleasedTypeChanged($event)"
              >
                <mat-radio-button value="Most Recent">
                  The most recent 2 years of pertinent information ( chart
                  notes, labs, x-rays and special tests) All medical records
                </mat-radio-button>
                <mat-radio-button value="Specific">
                  Specific information
                </mat-radio-button>
              </mat-radio-group>
            </div>

            <div
              class="col-md-12"
              *ngIf="
                releaseForm.controls['infoReleasedType'].value === 'Specific'
              "
            >
              <label class="labelText">
                Please specify <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <textarea
                  matInput
                  formControlName="specificInfoDetails"
                  name="specificInfoDetails"
                  spellcheck="true"
                ></textarea>
                <mat-error
                  *ngIf="releaseForm.controls['specificInfoDetails'].invalid"
                >
                  Please specify the information to be released
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- PURPOSE FOR WHICH THE DISCLOSURE IS BEING MADE  -->
      <div class="col-md-12" style="margin-bottom: 15px">
        <label class="labelHeader">
          Purpose For Which The Disclosure Is Being Made
        </label>
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-12">
              <label class="labelText">
                Please select one <span style="color: red">*</span>
              </label>
            </div>
            <div class="col-md-12" style="margin-bottom: 12px">
              <mat-radio-group
                aria-label="Select an option"
                formControlName="purposeOfDisclosure"
                style="column-gap: 15px; display: flex; flex-wrap: wrap"
                color="primary"
              >
                <mat-radio-button value="Attorney"> Attorney </mat-radio-button>
                <mat-radio-button value="Insurance">
                  Insurance
                </mat-radio-button>
                <mat-radio-button value="Doctor"> Doctor </mat-radio-button>
                <mat-radio-button value="Personal"> Personal </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </mat-card>
      </div>

      <!--PATIENT AUTHORIZATION  -->
      <div class="col-md-12" style="margin-bottom: 15px">
        <label class="labelHeader"> Patient Authorization </label>
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-12" style="margin-bottom: 15px">
              <label class="labelText">
                I understand that my records may contain information regarding
                the diagnosis or treatment of HIV/AIDS, sexually transmitted
                diseases, drug and/or alcohol abuse, mental illness, or
                psychiatric treatment. I give my specific authorization for
                these records to be released.
              </label>
            </div>

            <div class="col-md-12" style="margin-bottom: 10px">
              <label class="labelBold">
                <span style="color: red">EXCLUDE</span> the following
                information from the records released:
              </label>
            </div>

            <div
              class="col-md-12"
              style="
                margin-bottom: 15px;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
              "
            >
              <ng-container
                [formGroup]="releaseForm.controls.excludeInformation"
              >
                <mat-checkbox
                  color="primary"
                  class="checkbox-margin"
                  formControlName="drugAlcohol"
                >
                  Drug / Alcohol abuse/treatment & diagnosis
                </mat-checkbox>
                <mat-checkbox
                  color="primary"
                  class="checkbox-margin"
                  formControlName="sexualDisease"
                >
                  Sexually transmitted disease
                </mat-checkbox>
                <mat-checkbox
                  color="primary"
                  class="checkbox-margin"
                  formControlName="hivAids"
                >
                  HIV /Al DS diagnosis/treatment/testing
                </mat-checkbox>
                <mat-checkbox
                  color="primary"
                  class="checkbox-margin"
                  formControlName="mentalIllness"
                >
                  Mental illness or psychiatric diagnosis/treatment
                </mat-checkbox>
              </ng-container>
            </div>
          </div>
        </mat-card>
      </div>

      <!--MY RIGHTS -->
      <div class="col-md-12" style="margin-bottom: 15px">
        <label class="labelHeader"> My Rights</label>
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-12" style="margin-bottom: 15px">
              <label class="labelText">
                I understand I do not have to sign this authorization in order
                to obtain health care benefits (treatment, payment or
                enrollment). I may revoke this authorization in writing. To view
                the process for revoking this authorization, please read the
                Privacy Notice to patients posted at the facility where your
                information is being released. I understand that once the health
                information I have authorized to be disclosed reaches the noted
                recipient, that person or organization may re-disclose it, at
                which time it may no longer be protected under Privacy laws.
              </label>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Signature -->
      <div #canvasContainer class="col-md-12" style="text-align: center">
        <canvas #canvas height="250"></canvas>

        <button
          mat-raised-button
          type="button"
          (click)="clearPad()"
          style="float: left"
        >
          Clear Pad
        </button>

        <label class="labelBold">
          Signature of Patient, Guardian, or Authorized Representative
        </label>
      </div>

      <div class="col-md-12" style="text-align: right">
        <button
          color="primary"
          mat-raised-button
          type="button"
          [disabled]="releaseForm.invalid || !signatureStroke || processing"
          (click)="submitForm()"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
