export const PreferredLanguages = [
  {
    id: 'NTSPF',
    description: 'Not Specified',
  },
  {
    id: 'ENGSH',
    description: 'English',
  },
  {
    id: 'SPNSH',
    description: 'Spanish',
  },
  {
    id: 'ARBC',
    description: 'Arabic',
  },
  {
    id: 'BENGI',
    description: 'Bengali',
  },
  {
    id: 'FRNCH',
    description: 'French',
  },
  {
    id: 'GERMN',
    description: 'German',
  },
  {
    id: 'HINDI',
    description: 'Hindi',
  },
  {
    id: 'ITLIN',
    description: 'Italian',
  },
  {
    id: 'JPNES',
    description: 'Japanese',
  },
  {
    id: 'KOREN',
    description: 'Korean',
  },
  {
    id: 'MNCHI',
    description: 'Mandarin Chinese',
  },
  {
    id: 'PNJBI',
    description: 'Punjabi',
  },
  {
    id: 'PRTGS',
    description: 'Protuguese',
  },
  {
    id: 'RUSIN',
    description: 'Russian',
  },
  {
    id: 'OTHER',
    description: 'Other',
  },
];
