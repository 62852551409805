<app-intake-header
  sectionTitle="Member Insurance"
  [therapySession]="therapySession"
  [patient]="patient"
  [showPrintButton]="true"
></app-intake-header>
<mat-divider></mat-divider>

<div class="row" style="margin-top: 20px; padding: 10px; row-gap: 15px">
  <!-- Primary Insurance -->
  <div
    [class]="
      therapySession.anotherHealthPlan === 'Yes'
        ? 'col-md-12 col-xl-6'
        : 'col-md-12'
    "
    style="display: flex; flex-direction: column; flex-wrap: wrap"
  >
    <mat-card appearance="raised" class="mat-elevation-z8">
      <label class="labelHeader"> Primary Insurance </label>
      <!-- Add Button -->
      <div
        style="text-align: right"
        *ngIf="
          therapySession.status === 'Active' &&
          (hasBPSAIntakeAddAccess || hasBPSAIntakeEditAccess)
        "
      >
        <button
          mat-raised-button
          color="warn"
          type="button"
          (click)="searchInsurance('Primary')"
        >
          + Add/Update Primary Insurance
        </button>
      </div>

      <!-- Display Insurance -->
      <div style="margin-top: 15px; margin-bottom: 15px; position: relative">
        <!-- Insurance Information -->
        <app-insurance-card
          [patient]="patient"
          [insurance]="therapySession.primaryInsuranceDetails"
          parentClass="Session"
          [therapySession]="therapySession"
        ></app-insurance-card>
        <!-- Insurance Action -->
        <div
          style="position: absolute; right: 25px; top: 20px"
          *ngIf="
            therapySession.primaryInsuranceDetails &&
            therapySession.status === 'Active' &&
            (hasBPSAIntakeAddAccess || hasBPSAIntakeEditAccess)
          "
        >
          <mat-icon
            style="color: red; cursor: pointer"
            (click)="removeInsurance('Primary')"
          >
            delete_forever
          </mat-icon>
        </div>
      </div>
    </mat-card>
  </div>

  <!-- Secondary Insurance -->
  <div
    class="col-md-12 col-xl-6"
    style="display: flex; flex-direction: column; flex-wrap: wrap"
    *ngIf="therapySession.anotherHealthPlan === 'Yes'"
  >
    <mat-card appearance="raised" class="mat-elevation-z8">
      <label class="labelHeader"> Secondary Insurance </label>
      <!-- Add Button -->
      <div
        style="text-align: right"
        *ngIf="
          therapySession.status === 'Active' &&
          (hasBPSAIntakeAddAccess || hasBPSAIntakeEditAccess)
        "
      >
        <button
          mat-raised-button
          color="warn"
          type="button"
          (click)="searchInsurance('Secondary')"
        >
          + Add/Update Secondary Insurance
        </button>
      </div>

      <!-- Display Insurance -->
      <div style="margin-top: 15px; margin-bottom: 15px; position: relative">
        <!-- Insurance Information -->
        <app-insurance-card
          [insurance]="therapySession.secondaryInsuranceDetails"
          [patient]="patient"
          parentClass="Session"
          [therapySession]="therapySession"
        ></app-insurance-card>
        <!-- Insurance Action -->
        <div
          style="position: absolute; right: 25px; top: 20px"
          *ngIf="
            therapySession.secondaryInsuranceDetails &&
            therapySession.status === 'Active' &&
            (hasBPSAIntakeAddAccess || hasBPSAIntakeEditAccess)
          "
        >
          <mat-icon
            style="color: red; cursor: pointer"
            (click)="removeInsurance('Secondary')"
          >
            delete_forever
          </mat-icon>
        </div>
      </div>
    </mat-card>
  </div>

  <!-- Ask For Secondary Insurance -->
  <!-- // Asking if there is another health benefit plan -->
  <div class="col-md-12" style="margin-bottom: 12px; margin-top: 15px">
    <label class="labelText">
      Is there another Health Benefit Plan?
      <span style="opacity: 0.5; font-size: 12px">
        (Box 11d on the CMS1500)
      </span>
    </label>
    <br />
    <mat-radio-group
      aria-label="Select an option"
      style="column-gap: 15px; display: flex; flex-wrap: wrap"
      color="primary"
      [value]="therapySession.anotherHealthPlan"
      (change)="secondaryInsuranceChaged($event)"
      [disabled]="
        therapySession.status != 'Active' ||
        (!hasBPSAIntakeAddAccess && !hasBPSAIntakeEditAccess)
      "
    >
      <mat-radio-button value="Yes"> Yes </mat-radio-button>
      <mat-radio-button value="No"> No </mat-radio-button>
    </mat-radio-group>
  </div>
</div>
