<div class="row" style="margin-top: 10px; margin-bottom: 20px">
  <!-- Add Button -->
  <div
    class="col-md-12"
    style="text-align: right"
    *ngIf="hasMemberPaymentsAddAccess"
  >
    <button
      mat-raised-button
      type="button"
      color="warn"
      (click)="addNewCard()"
      [disabled]="processing"
    >
      + Add New Card <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
    </button>
  </div>

  <!-- Progress Bar -->
  <div class="col-md-12" *ngIf="isLoadingResults">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div class="col-md-12" style="padding-top: 30px">
    <!-- No Data -->
    <mat-error *ngIf="savedPaymentMehods.total == 0 && !isLoadingResults">
      No card on file
    </mat-error>

    <!-- Display Cards if the there is stored cards -->
    <div class="row">
      <div class="col-md-6" *ngFor="let card of savedPaymentMehods.items">
        <mat-card apperance="raised" class="paymentCard mat-elevation-z8">
          <div class="headerWithChipIcon">
            <mat-icon class="chipIcon">developer_board</mat-icon>
            <label class="cardType">{{ card.type }}</label>
          </div>
          <div class="cardNumber">
            <label class="cardNumberText">
              **** **** **** {{ card.last4 }}
            </label>
          </div>
          <div class="cardFooter">
            <label class="labelBold">{{ card.customer_name }}</label>
            <label>{{ card.exp_month }} / {{ card.exp_year }}</label>
          </div>
          <!-- Default Indicator -->
          <div
            class="cardDefaultIndicator"
            *ngIf="defaultPaymentId === card.id"
          >
            <mat-icon>check_box</mat-icon>
            <span class="defaultIndicator">Default</span>
          </div>
          <!-- Action -->
          <div class="cardAction">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon style="font-size: 18px">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                type="button"
                (click)="markAsDefault(card)"
                *ngIf="defaultPaymentId != card.id"
              >
                <mat-icon style="color: #029102"> check_box </mat-icon>
                <span>Mark as Default</span>
              </button>
              <button
                mat-menu-item
                type="button"
                (click)="deleteCard(card)"
                *ngIf="hasMemberPaymentsDeleteAccess"
              >
                <mat-icon style="color: red"> delete_forever </mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
