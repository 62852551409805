import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import moment from 'moment';
import { DataTablesModel } from 'src/app/_models';
import { ShortHealthAnxietyApiService } from 'src/app/_services';
import { greenChart, hexToRGBA, orangeChart, redChart, yellowChart } from 'src/app/shared/utilities/utilities';
@Component({
  selector: 'app-short-health-anxiety-charts',
  templateUrl: './short-health-anxiety-charts.component.html',
  styleUrls: ['./short-health-anxiety-charts.component.css'],
})
export class ShortHealthAxietyChartsComponent implements OnInit {
  @Input() patientId: string;
  @Input() assessmentId: string;
  lineChartData: ChartConfiguration<'line'>['data'] = {
    datasets: [{
      tension: 0.4,
      data: [], borderColor: '#EEEEEE',
      pointBackgroundColor: '#333333',
      pointBorderColor: '#FFFFFF',
      pointHoverBackgroundColor: '#FFFFFF',
      pointHoverBorderColor: '#333333',
      pointStyle: 'circle',
      pointRadius: 5,
    }]
  };
  primaryChartData: ChartConfiguration<'line'>['data'] = {
    datasets: [{
      tension: 0.4,
      data: [], borderColor: '#EEEEEE',
      pointBackgroundColor: '#333333',
      pointBorderColor: '#FFFFFF',
      pointHoverBackgroundColor: '#FFFFFF',
      pointHoverBorderColor: '#333333',
      pointStyle: 'circle',
      pointRadius: 5,
    }]
  };
  negativeChartData: ChartConfiguration<'line'>['data'] = {
    datasets: [{
      tension: 0.4,
      data: [], borderColor: '#EEEEEE',
      pointBackgroundColor: '#333333',
      pointBorderColor: '#FFFFFF',
      pointHoverBackgroundColor: '#FFFFFF',
      pointHoverBorderColor: '#333333',
      pointStyle: 'circle',
      pointRadius: 5,
    }]
  };

  lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        title: {
          display: false
        }
      },
      annotation: {
        annotations: {
          greenBg:
          {
            type: 'box',
            yMin: 0,
            yMax: 19.5,
            backgroundColor: hexToRGBA(greenChart.backgroundColor, 1),
            borderColor: greenChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          },
          yellowBg: {
            type: 'box',
            yMin: 19.5,
            yMax: 29.5,
            backgroundColor: hexToRGBA(yellowChart.backgroundColor, 1),
            borderColor: yellowChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          },
          orangeBg: {
            type: 'box',
            yMin: 29.5,
            yMax: 39.5,
            backgroundColor: hexToRGBA(orangeChart.backgroundColor, 1),
            borderColor: orangeChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          },
          redBg: {
            type: 'box',
            yMin: 39.5,
            yMax: 54,
            backgroundColor: hexToRGBA(redChart.backgroundColor, 1),
            borderColor: redChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          }
        }
      }
    },
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        },
        min: 0,
        max: 54,
      },
    }
  };
  primaryChartOptions: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        title: {
          display: false
        }
      },
      annotation: {
        annotations: {
          greenBg:
          {
            type: 'box',
            yMin: 0,
            yMax: 21.5,
            backgroundColor: hexToRGBA(greenChart.backgroundColor, 1),
            borderColor: greenChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          },
          redBg: {
            type: 'box',
            yMin: 21.5,
            yMax: 42,
            backgroundColor: hexToRGBA(redChart.backgroundColor, 1),
            borderColor: redChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          }
        }
      }
    },
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        },
        min: 0,
        max: 42,
        ticks: { stepSize: 15 },
      },
    }
  };
  negativeChartOptions: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        title: {
          display: false
        }
      },
      annotation: {
        annotations: {
          greenBg:
          {
            type: 'box',
            yMin: 0,
            yMax: 6.5,
            backgroundColor: hexToRGBA(greenChart.backgroundColor, 1),
            borderColor: greenChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          },
          redBg: {
            type: 'box',
            yMin: 6.5,
            yMax: 12,
            backgroundColor: hexToRGBA(redChart.backgroundColor, 1),
            borderColor: redChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          }
        }
      }
    },
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        },
        min: 0,
        max: 12,
      },
    }
  };
  assessmentDetailChartOptions: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        title: {
          display: false
        }
      }
    },
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        },
        min: 0,
        max: 4,
        ticks: { stepSize: 1 },
      },
    },
  };
  lineChartLegend = false;
  lineChartPlugins = [];
  primaryChartPlugins = [];
  negativeChartPlugins = [];
  lineChartType = 'line';
  detailChartPlugins = [];
  data: DataTablesModel = {} as DataTablesModel;
  processing$ = false;
  isLoadingResults = false;
  error: string = '';
  scoreMap = new Map();
  scoreDetails: any[] = [];
  constructor(
    public shortHealthAnxietyApiService: ShortHealthAnxietyApiService,
    private route: ActivatedRoute
  ) { }
  ngOnInit() {
    this.data.page = 0;
    this.data.per_page = 10;
    this.loadGamingAddictionAssessments();
  }
  public loadGamingAddictionAssessments() {
    this.isLoadingResults = true;
    this.error = '';
    this.shortHealthAnxietyApiService
      .getShortHealthAnxietyList(
        this.patientId,
        this.assessmentId,
        this.data.per_page,
        this.data.page
      )
      .subscribe(
        (response) => {
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
            var riskScores = [];
            var primaryScores = [];
            var negativeScores = [];
            var labels = [];
            this.scoreDetails = [];
            for (var item of response.items) {
              if (response.items.length === 1) {
                labels.push("");
                riskScores.push(item.riskScore);
                primaryScores.push(item.props['primary']);
                negativeScores.push(item.props['negative']);
              }
              riskScores.push(item.riskScore);
              if (item.props) {
                primaryScores.push(item.props['primary']);
                negativeScores.push(item.props['negative']);
              } else {
                primaryScores.push(0);
                negativeScores.push(0);
              }
              var stillUtc = moment.utc(item.createdDate).toDate();
              var assessmentDate = moment(stillUtc).local().format('MM/DD/YY');
              labels.push(assessmentDate);
              for (var key in item) {
                if (item.hasOwnProperty(key)) {
                  if (item[key] && item[key].hasOwnProperty('score')) {
                    var scoreObj = item[key];
                    var scores = [];
                    if (this.scoreMap.get(key)) {
                      scores = this.scoreMap.get(key).scores;
                    }

                    if (response.items.length === 1) {
                      scores.push(scoreObj["score"]);
                    }

                    scores.push(scoreObj['score']);
                    var returnObj = {
                      scores: scores,
                      key: key,
                      title: scoreObj['title'],
                      answer: scoreObj['answer'],
                      chartData: [],
                      chartColor: this.getChartColor(scores),
                    };
                    this.scoreMap.set(key, returnObj);
                  }
                }
              }
            }
            this.scoreMap.forEach((value, key) => {
              let dataset: any = {
                datasets: [
                  {
                    "data": value.scores,
                    "borderColor": value.chartColor.borderColor,
                    "backgroundColor": value.chartColor.backgroundColor,
                    "tension": 0.4,
                    "fill": true,
                    pointBackgroundColor: '#333333',
                    pointRadius: 5
                  }
                ],
                "labels": labels,
                "title": value.title,
                "answer": value.answer
              };
              this.scoreDetails.push(dataset);
            });
            this.lineChartData.datasets[0].data = riskScores;
            this.primaryChartData.datasets[0].data = primaryScores;
            this.negativeChartData.datasets[0].data = negativeScores;
            this.lineChartData.labels = labels;
            this.primaryChartData.labels = labels;
            this.negativeChartData.labels = labels;
            this.isLoadingResults = false;
          }
          if (response == null) {
            this.data.items = [];
            this.data.total = 0;
          }
        },
        (error) => {
          this.error = error;
          this.isLoadingResults = false;
        }
      );
  }
  getChartColor(scoringArray: any[]) {
    var score = scoringArray[scoringArray.length - 1];
    if (score >= 3) {
      return redChart;
    } else if (score >= 2) {
      return orangeChart;
    } else if (score >= 1) {
      return yellowChart;
    } else {
      return greenChart;
    }
  }
  getRiskScoreChartColor(scoringArray: any[], maxScore: number) {
    var lastScore = scoringArray[scoringArray.length - 1];
    if (lastScore >= 40) {
      return redChart;
    } else if (lastScore >= 30) {
      return orangeChart;
    } else if (lastScore >= 20) {
      return yellowChart;
    } else {
      return greenChart;
    }
  }
}
