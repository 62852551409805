import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { IShortHealthAnxiety } from 'src/app/_models/assessments/anxiety/shorthealthanxiety.model';
import {
  ShortHealthAnxietyApiService,
  ToastMessageService,
} from 'src/app/_services/';

@Component({
  selector: 'app-short-health-anxiety',
  templateUrl: './short-health-anxiety.component.html',
  styleUrls: ['./short-health-anxiety.component.css'],
})
export class ShortHealthAnxietyComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public shortHealthAnxietyApiService: ShortHealthAnxietyApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) {}
  public riskScore: number;

  public primary: string;
  public negative: string;
  public shortHealthAnxietyForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  shortHealthAnxietyAssessment: IShortHealthAnxiety;
  shortHealthAnxietyAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.shortHealthAnxietyForm, this.errors);

    this.loadShortHealthAnxietyAssessment();
  }

  loadShortHealthAnxietyAssessment() {
    this.shortHealthAnxietyForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.shortHealthAnxietyApiService
        .getShortHealthAnxietyDetails(this.assessmentId)
        .subscribe((shortHealthAnxietyAssessment) => {
          if (shortHealthAnxietyAssessment.data) {
            this.shortHealthAnxietyForm.controls['q1'].setValue(
              shortHealthAnxietyAssessment.data.q1.answer
            );
            this.shortHealthAnxietyForm.controls['q2'].setValue(
              shortHealthAnxietyAssessment.data.q2.answer
            );
            this.shortHealthAnxietyForm.controls['q3'].setValue(
              shortHealthAnxietyAssessment.data.q3.answer
            );
            this.shortHealthAnxietyForm.controls['q4'].setValue(
              shortHealthAnxietyAssessment.data.q4.answer
            );
            this.shortHealthAnxietyForm.controls['q5'].setValue(
              shortHealthAnxietyAssessment.data.q5.answer
            );
            this.shortHealthAnxietyForm.controls['q6'].setValue(
              shortHealthAnxietyAssessment.data.q6.answer
            );
            this.shortHealthAnxietyForm.controls['q7'].setValue(
              shortHealthAnxietyAssessment.data.q7.answer
            );
            this.shortHealthAnxietyForm.controls['q8'].setValue(
              shortHealthAnxietyAssessment.data.q8.answer
            );
            this.shortHealthAnxietyForm.controls['q9'].setValue(
              shortHealthAnxietyAssessment.data.q9.answer
            );
            this.shortHealthAnxietyForm.controls['q10'].setValue(
              shortHealthAnxietyAssessment.data.q10.answer
            );
            this.shortHealthAnxietyForm.controls['q11'].setValue(
              shortHealthAnxietyAssessment.data.q11.answer
            );
            this.shortHealthAnxietyForm.controls['q12'].setValue(
              shortHealthAnxietyAssessment.data.q12.answer
            );
            this.shortHealthAnxietyForm.controls['q13'].setValue(
              shortHealthAnxietyAssessment.data.q13.answer
            );
            this.shortHealthAnxietyForm.controls['q14'].setValue(
              shortHealthAnxietyAssessment.data.q14.answer
            );
            this.shortHealthAnxietyForm.controls['q15'].setValue(
              shortHealthAnxietyAssessment.data.q15.answer
            );
            this.shortHealthAnxietyForm.controls['q16'].setValue(
              shortHealthAnxietyAssessment.data.q16.answer
            );
            this.shortHealthAnxietyForm.controls['q17'].setValue(
              shortHealthAnxietyAssessment.data.q17.answer
            );
            this.shortHealthAnxietyForm.controls['q18'].setValue(
              shortHealthAnxietyAssessment.data.q18.answer
            );

            this.riskScore = shortHealthAnxietyAssessment.data.riskScore;
            this.primary = shortHealthAnxietyAssessment.data.props['primary'];
            this.negative = shortHealthAnxietyAssessment.data.props['negative'];
          }
        });
    }
  }

  onFormSubmit() {
    if (this.shortHealthAnxietyForm.invalid) {
      this.shortHealthAnxietyForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.shortHealthAnxietyAssessment = Object.assign(
      {},
      this.shortHealthAnxietyAssessment
    );
    this.shortHealthAnxietyAssessment.patientId =
      this.shortHealthAnxietyForm.controls['patientId'].value;
    this.shortHealthAnxietyAssessment.sessionId =
      this.shortHealthAnxietyForm.controls['sessionId'].value;
    this.shortHealthAnxietyAssessment.assessmentRequestId =
      this.shortHealthAnxietyForm.controls['assessmentRequestId'].value;
    this.shortHealthAnxietyAssessment.q1 = {
      answer: this.shortHealthAnxietyForm.controls['q1'].value,
    };
    this.shortHealthAnxietyAssessment.q2 = {
      answer: this.shortHealthAnxietyForm.controls['q2'].value,
    };
    this.shortHealthAnxietyAssessment.q3 = {
      answer: this.shortHealthAnxietyForm.controls['q3'].value,
    };
    this.shortHealthAnxietyAssessment.q4 = {
      answer: this.shortHealthAnxietyForm.controls['q4'].value,
    };
    this.shortHealthAnxietyAssessment.q5 = {
      answer: this.shortHealthAnxietyForm.controls['q5'].value,
    };
    this.shortHealthAnxietyAssessment.q6 = {
      answer: this.shortHealthAnxietyForm.controls['q6'].value,
    };
    this.shortHealthAnxietyAssessment.q7 = {
      answer: this.shortHealthAnxietyForm.controls['q7'].value,
    };
    this.shortHealthAnxietyAssessment.q8 = {
      answer: this.shortHealthAnxietyForm.controls['q8'].value,
    };
    this.shortHealthAnxietyAssessment.q9 = {
      answer: this.shortHealthAnxietyForm.controls['q9'].value,
    };
    this.shortHealthAnxietyAssessment.q10 = {
      answer: this.shortHealthAnxietyForm.controls['q10'].value,
    };
    this.shortHealthAnxietyAssessment.q11 = {
      answer: this.shortHealthAnxietyForm.controls['q11'].value,
    };
    this.shortHealthAnxietyAssessment.q12 = {
      answer: this.shortHealthAnxietyForm.controls['q12'].value,
    };
    this.shortHealthAnxietyAssessment.q13 = {
      answer: this.shortHealthAnxietyForm.controls['q13'].value,
    };
    this.shortHealthAnxietyAssessment.q14 = {
      answer: this.shortHealthAnxietyForm.controls['q14'].value,
    };
    this.shortHealthAnxietyAssessment.q15 = {
      answer: this.shortHealthAnxietyForm.controls['q15'].value,
    };
    this.shortHealthAnxietyAssessment.q16 = {
      answer: this.shortHealthAnxietyForm.controls['q16'].value,
    };
    this.shortHealthAnxietyAssessment.q17 = {
      answer: this.shortHealthAnxietyForm.controls['q17'].value,
    };
    this.shortHealthAnxietyAssessment.q18 = {
      answer: this.shortHealthAnxietyForm.controls['q18'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.shortHealthAnxietyAssessment.accessCode = this.accessCode;

      this.shortHealthAnxietyApiService
        .addGuestShortHealthAnxiety(this.shortHealthAnxietyAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Short Health Anxiety Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Short Health Anxiety Assessment'
            );
          }
        );
    } else {
      this.shortHealthAnxietyApiService
        .addShortHealthAnxiety(this.shortHealthAnxietyAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Short Health Anxiety Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Short Health Anxiety Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    q1: [{ type: 'required', message: '1. is required' }],
    q2: [{ type: 'required', message: '2. is required' }],
    q3: [{ type: 'required', message: '3. is required' }],
    q4: [{ type: 'required', message: '4. is required' }],
    q5: [{ type: 'required', message: '5. is required' }],
    q6: [{ type: 'required', message: '6. is required' }],
    q7: [{ type: 'required', message: '7. is required' }],
    q8: [{ type: 'required', message: '8. is required' }],
    q9: [{ type: 'required', message: '9. is required' }],
    q10: [{ type: 'required', message: '10. is required' }],
    q11: [{ type: 'required', message: '11. is required' }],
    q12: [{ type: 'required', message: '12. is required' }],
    q13: [{ type: 'required', message: '13. is required' }],
    q14: [{ type: 'required', message: '14. is required' }],
    q15: [{ type: 'required', message: '15. is required' }],
    q16: [{ type: 'required', message: '16. is required' }],
    q17: [{ type: 'required', message: '17. is required' }],
    q18: [{ type: 'required', message: '18. is required' }],
  };

  private buildForm() {
    this.shortHealthAnxietyForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      q1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q2: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q3: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q4: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q5: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q6: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q7: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q8: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q9: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q10: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q11: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q12: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q13: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q14: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q15: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q16: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q17: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q18: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.shortHealthAnxietyForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.shortHealthAnxietyForm) {
      return;
    }
    const form = this.shortHealthAnxietyForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
