import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[autoSlashDate]',
})
export class AutoSlashDateDirective {
  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('input', ['$event']) onInput(event: KeyboardEvent): void {
    let input = this.el.nativeElement.value;

    // Remove all non-digit characters
    input = input.replace(/\D/g, '');

    // Add leading zeros if necessary
    if (input.length >= 2) {
      let month = input.slice(0, 2);
      if (parseInt(month, 10) > 12) {
        month = '0' + input[0];
        input = month + input.slice(1);
      }
    }

    if (input.length > 4) {
      let day = input.slice(2, 4);
      if (parseInt(day, 10) > 31) {
        day = '0' + input[2];
        input = input.slice(0, 2) + day + input.slice(3);
      }
    }

    // Format input as MM/DD/YYYY
    if (input.length > 2 && input.length <= 4) {
      input = input.slice(0, 2) + '/' + input.slice(2);
    } else if (input.length > 4) {
      input =
        input.slice(0, 2) + '/' + input.slice(2, 4) + '/' + input.slice(4, 8);
    }

    // Update the input value
    this.el.nativeElement.value = input;
  }
}
