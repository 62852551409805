import { Component, Input, OnInit } from '@angular/core';
import { SearchService } from 'src/app/_services/common/search.service';
import { FacilitiesSandbox } from 'src/app/shared/sandbox/facilities.sandbox';

@Component({
  selector: 'app-facility-search',
  templateUrl: './facility-search.component.html',
  styleUrl: './facility-search.component.css'
})
export class FacilitySearchComponent implements OnInit {
  @Input() facilitiesSelectionList: any[] = [];
  myFacilities: any = [];
  filteredFacilities: any = [];
  searchFacilityName: string = '';

  constructor(private searchService: SearchService,
    private facilitiesSandbox: FacilitiesSandbox,) { }

  ngOnInit(): void {
    this.loadAllFacilities();
  }

  // Load All my facilities
  loadAllFacilities() {
    this.facilitiesSandbox.facilities$.subscribe((response) => {
      if (response) {
        this.myFacilities = response;
        this.filteredFacilities = response;
        this.facilitiesSelectionList = response;
        this.searchService.updateSelectedFacilities(this.myFacilities);
      }
    });
  }

  isFacilityVisible(facility) {
    return this.filteredFacilities.includes(facility);
  }

  // Facility search box
  searchByFacilityNameChanged() {
    if (this.searchFacilityName) {
      this.facilitiesSelectionList = this.myFacilities.filter((facility) => {
        if (
          facility.facilityName
            .toLowerCase()
            .includes(this.searchFacilityName.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.facilitiesSelectionList = [...this.myFacilities];
    }
  }

  selectAllFacilities() {
    this.filteredFacilities = [...this.myFacilities];
    this.updateSelectedFacilities();
  }

  deselectAllFacilities() {
    this.filteredFacilities = [];
    this.updateSelectedFacilities();
  }

  selectDeselctFacility(event, facility) {
    if (event.checked) {
      // Add the facility to the list
      if (!this.filteredFacilities.includes(facility)) {
        this.filteredFacilities = [...this.filteredFacilities, facility];
      }
    } else {
      // Remove the facility to the list
      if (this.filteredFacilities.includes(facility)) {
        this.filteredFacilities = this.filteredFacilities.filter(
          (resource) => resource != facility
        );
      }
    }
    this.updateSelectedFacilities();
  }

  private updateSelectedFacilities() {
    this.searchService.updateSelectedFacilities(this.filteredFacilities);
  }
}
