import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { ITaps } from 'src/app/_models/assessments/addiction/taps.model';
import { Patient } from 'src/app/_models/patient/patient';
import { PatientService, ToastMessageService } from 'src/app/_services/';
import { TapsApiService } from 'src/app/_services/assessments/addiction/taps.service';

@Component({
  selector: 'app-taps-assessment',
  templateUrl: './taps-assessment.component.html',
  styleUrls: ['./taps-assessment.component.css'],
})
export class TapsAssessmentComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public tapsAssessmentApiService: TapsApiService,
    public patientApiService: PatientService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) {}
  public riskScore: number;
  public tapsAssessmentForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  tapsAssessment: ITaps;
  tapsAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;
  requestTobaccoQstns: boolean = false;
  requestAlcoholQstns: boolean = false;
  requestPrescriptionQstns: boolean = false;
  requestSubstanceQstns: boolean = false;

  patient: Patient;
  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }

    if (this.patientId) {
      this.patientApiService
        .getPatientDetails(this.patientId, null)
        .subscribe((response) => {
          this.patient = response.data;
          this.buildForm();
          this.errorHandler.handleErrors(this.tapsAssessmentForm, this.errors);
          this.loadTaps();
        });
    }
  }

  loadTaps() {
    this.tapsAssessmentForm.controls['patientId'].setValue(this.patientId);
    if (this.assessmentId) {
      this.tapsAssessmentApiService
        .getTapsDetails(this.assessmentId)
        .subscribe((tapsAssessment) => {
          if (tapsAssessment.data) {
            this.tapsAssessmentForm.controls['tobaccoUse12M'].setValue(
              tapsAssessment.data.tobaccoUse12M.answer
            );
            this.tapsAssessmentForm.controls['male5Drinks12M'].setValue(
              tapsAssessment.data.male5Drinks12M.answer
            );
            this.tapsAssessmentForm.controls['female4Drinks12M'].setValue(
              tapsAssessment.data.female4Drinks12M.answer
            );
            this.tapsAssessmentForm.controls['drugUse12M'].setValue(
              tapsAssessment.data.drugUse12M.answer
            );
            this.tapsAssessmentForm.controls['rxFeelMoreUse12M'].setValue(
              tapsAssessment.data.rxFeelMoreUse12M.answer
            );
            this.tapsAssessmentForm.controls['past3MoDdYSmkCgrt'].setValue(
              tapsAssessment.data.past3MoDdYSmkCgrt.answer
            );
            this.tapsAssessmentForm.controls['past3MoDdYsllySmk'].setValue(
              tapsAssessment.data.past3MoDdYsllySmk.answer
            );
            this.tapsAssessmentForm.controls['past3MoDdYsllySmk1'].setValue(
              tapsAssessment.data.past3MoDdYsllySmk1.answer
            );
            this.tapsAssessmentForm.controls['past3MoDdYHvDrnkC'].setValue(
              tapsAssessment.data.past3MoDdYHvDrnkC.answer
            );
            this.tapsAssessmentForm.controls['past3MoDdYHv4rMrD'].setValue(
              tapsAssessment.data.past3MoDdYHv4rMrD.answer
            );
            this.tapsAssessmentForm.controls['past3MoDdYHv5rMrD'].setValue(
              tapsAssessment.data.past3MoDdYHv5rMrD.answer
            );
            this.tapsAssessmentForm.controls[
              'past3MoHvYTrdndFlCtDwnrStpD'
            ].setValue(tapsAssessment.data.past3MoHvYTrdndFlCtDwnrStpD.answer);
            this.tapsAssessmentForm.controls['past3MoHsnynxprss'].setValue(
              tapsAssessment.data.past3MoHsnynxprss.answer
            );
            this.tapsAssessmentForm.controls['past3MoDdYsMrjnHsWeed'].setValue(
              tapsAssessment.data.past3MoDdYsMrjnHsWeed.answer
            );
            this.tapsAssessmentForm.controls['past3MoHvYHdStrng'].setValue(
              tapsAssessment.data.past3MoHvYHdStrng.answer
            );
            this.tapsAssessmentForm.controls['past3MoHsnynxprss1'].setValue(
              tapsAssessment.data.past3MoHsnynxprss1.answer
            );
            this.tapsAssessmentForm.controls[
              'past3MoDdYsCcnCrackrMthmpht'
            ].setValue(tapsAssessment.data.past3MoDdYsCcnCrackrMthmpht.answer);
            this.tapsAssessmentForm.controls[
              'past3MoDdYsCcnCrackrMthmpht1'
            ].setValue(tapsAssessment.data.past3MoDdYsCcnCrackrMthmpht1.answer);
            this.tapsAssessmentForm.controls[
              'past3MoHsnynxprssCrackrMthm'
            ].setValue(tapsAssessment.data.past3MoHsnynxprssCrackrMthm.answer);
            this.tapsAssessmentForm.controls['past3MoDdYsHrn'].setValue(
              tapsAssessment.data.past3MoDdYsHrn.answer
            );
            this.tapsAssessmentForm.controls[
              'past3MoHvYTrdndFlCtDwnrStps'
            ].setValue(tapsAssessment.data.past3MoHvYTrdndFlCtDwnrStps.answer);
            this.tapsAssessmentForm.controls['past3MoHsnynxprss2'].setValue(
              tapsAssessment.data.past3MoHsnynxprss2.answer
            );
            this.tapsAssessmentForm.controls['past3MoDdYsPrscrp'].setValue(
              tapsAssessment.data.past3MoDdYsPrscrp.answer
            );
            this.tapsAssessmentForm.controls[
              'past3MoHvYTrdndFlCtDwnrSt1'
            ].setValue(tapsAssessment.data.past3MoHvYTrdndFlCtDwnrSt1.answer);
            this.tapsAssessmentForm.controls['past3MoHsnynxprss3'].setValue(
              tapsAssessment.data.past3MoHsnynxprss3.answer
            );
            this.tapsAssessmentForm.controls['past3MoDdYsMdctnF'].setValue(
              tapsAssessment.data.past3MoDdYsMdctnF.answer
            );
            this.tapsAssessmentForm.controls['past3MoHvYHdStrng1'].setValue(
              tapsAssessment.data.past3MoHvYHdStrng1.answer
            );
            this.tapsAssessmentForm.controls['past3MoHsnynxprss4'].setValue(
              tapsAssessment.data.past3MoHsnynxprss4.answer
            );
            this.tapsAssessmentForm.controls['past3MoDdYsMdctnF1'].setValue(
              tapsAssessment.data.past3MoDdYsMdctnF1.answer
            );
            this.tapsAssessmentForm.controls['past3MoDdYsMdctnF2'].setValue(
              tapsAssessment.data.past3MoDdYsMdctnF2.answer
            );
            this.tapsAssessmentForm.controls['past3MoHsnynxprss5'].setValue(
              tapsAssessment.data.past3MoHsnynxprss5.answer
            );
            this.tapsAssessmentForm.controls['past3MoDdYsnythrl'].setValue(
              tapsAssessment.data.past3MoDdYsnythrl.answer
            );
            this.tapsAssessmentForm.controls['past3MoWhtWrThthr'].setValue(
              tapsAssessment.data.past3MoWhtWrThthr
            );

            this.setSubsequentQuestions('tobaccoUse12M');
            this.setSubsequentQuestions('male5Drinks12M');
            this.setSubsequentQuestions('female4Drinks12M');
            this.setSubsequentQuestions('drugUse12M');
            this.setSubsequentQuestions('rxFeelMoreUse12M');

            this.riskScore = tapsAssessment.data.riskScore;
          }
        });
    }
  }
  onTapsFormSubmit() {
    if (this.tapsAssessmentForm.invalid) {
      this.tapsAssessmentForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.tapsAssessment = Object.assign({}, this.tapsAssessment);
    this.tapsAssessment.patientId =
      this.tapsAssessmentForm.controls['patientId'].value;
    this.tapsAssessment.sessionId =
      this.tapsAssessmentForm.controls['sessionId'].value;
    this.tapsAssessment.assessmentRequestId =
      this.tapsAssessmentForm.controls['assessmentRequestId'].value;

    this.tapsAssessment.tobaccoUse12M = {
      answer: this.tapsAssessmentForm.controls['tobaccoUse12M'].value,
    };
    this.tapsAssessment.male5Drinks12M = {
      answer: this.tapsAssessmentForm.controls['male5Drinks12M'].value,
    };
    this.tapsAssessment.female4Drinks12M = {
      answer: this.tapsAssessmentForm.controls['female4Drinks12M'].value,
    };
    this.tapsAssessment.drugUse12M = {
      answer: this.tapsAssessmentForm.controls['drugUse12M'].value,
    };
    this.tapsAssessment.rxFeelMoreUse12M = {
      answer: this.tapsAssessmentForm.controls['rxFeelMoreUse12M'].value,
    };
    this.tapsAssessment.past3MoDdYSmkCgrt = {
      answer: this.tapsAssessmentForm.controls['past3MoDdYSmkCgrt'].value,
    };
    this.tapsAssessment.past3MoDdYsllySmk = {
      answer: this.tapsAssessmentForm.controls['past3MoDdYsllySmk'].value,
    };
    this.tapsAssessment.past3MoDdYsllySmk1 = {
      answer: this.tapsAssessmentForm.controls['past3MoDdYsllySmk1'].value,
    };
    this.tapsAssessment.past3MoDdYHvDrnkC = {
      answer: this.tapsAssessmentForm.controls['past3MoDdYHvDrnkC'].value,
    };
    this.tapsAssessment.past3MoDdYHv4rMrD = {
      answer: this.tapsAssessmentForm.controls['past3MoDdYHv4rMrD'].value,
    };
    this.tapsAssessment.past3MoDdYHv5rMrD = {
      answer: this.tapsAssessmentForm.controls['past3MoDdYHv5rMrD'].value,
    };
    this.tapsAssessment.past3MoHvYTrdndFlCtDwnrStpD = {
      answer:
        this.tapsAssessmentForm.controls['past3MoHvYTrdndFlCtDwnrStpD'].value,
    };
    this.tapsAssessment.past3MoHsnynxprss = {
      answer: this.tapsAssessmentForm.controls['past3MoHsnynxprss'].value,
    };
    this.tapsAssessment.past3MoDdYsMrjnHsWeed = {
      answer: this.tapsAssessmentForm.controls['past3MoDdYsMrjnHsWeed'].value,
    };
    this.tapsAssessment.past3MoHvYHdStrng = {
      answer: this.tapsAssessmentForm.controls['past3MoHvYHdStrng'].value,
    };
    this.tapsAssessment.past3MoHsnynxprss1 = {
      answer: this.tapsAssessmentForm.controls['past3MoHsnynxprss1'].value,
    };
    this.tapsAssessment.past3MoDdYsCcnCrackrMthmpht = {
      answer:
        this.tapsAssessmentForm.controls['past3MoDdYsCcnCrackrMthmpht'].value,
    };
    this.tapsAssessment.past3MoDdYsCcnCrackrMthmpht1 = {
      answer:
        this.tapsAssessmentForm.controls['past3MoDdYsCcnCrackrMthmpht1'].value,
    };
    this.tapsAssessment.past3MoHsnynxprssCrackrMthm = {
      answer:
        this.tapsAssessmentForm.controls['past3MoHsnynxprssCrackrMthm'].value,
    };
    this.tapsAssessment.past3MoDdYsHrn = {
      answer: this.tapsAssessmentForm.controls['past3MoDdYsHrn'].value,
    };
    this.tapsAssessment.past3MoHvYTrdndFlCtDwnrStps = {
      answer:
        this.tapsAssessmentForm.controls['past3MoHvYTrdndFlCtDwnrStps'].value,
    };
    this.tapsAssessment.past3MoHsnynxprss2 = {
      answer: this.tapsAssessmentForm.controls['past3MoHsnynxprss2'].value,
    };
    this.tapsAssessment.past3MoDdYsPrscrp = {
      answer: this.tapsAssessmentForm.controls['past3MoDdYsPrscrp'].value,
    };
    this.tapsAssessment.past3MoHvYTrdndFlCtDwnrSt1 = {
      answer:
        this.tapsAssessmentForm.controls['past3MoHvYTrdndFlCtDwnrSt1'].value,
    };
    this.tapsAssessment.past3MoHsnynxprss3 = {
      answer: this.tapsAssessmentForm.controls['past3MoHsnynxprss3'].value,
    };
    this.tapsAssessment.past3MoDdYsMdctnF = {
      answer: this.tapsAssessmentForm.controls['past3MoDdYsMdctnF'].value,
    };
    this.tapsAssessment.past3MoHvYHdStrng1 = {
      answer: this.tapsAssessmentForm.controls['past3MoHvYHdStrng1'].value,
    };
    this.tapsAssessment.past3MoHsnynxprss4 = {
      answer: this.tapsAssessmentForm.controls['past3MoHsnynxprss4'].value,
    };
    this.tapsAssessment.past3MoDdYsMdctnF1 = {
      answer: this.tapsAssessmentForm.controls['past3MoDdYsMdctnF1'].value,
    };
    this.tapsAssessment.past3MoDdYsMdctnF2 = {
      answer: this.tapsAssessmentForm.controls['past3MoDdYsMdctnF2'].value,
    };
    this.tapsAssessment.past3MoHsnynxprss5 = {
      answer: this.tapsAssessmentForm.controls['past3MoHsnynxprss5'].value,
    };
    this.tapsAssessment.past3MoDdYsnythrl = {
      answer: this.tapsAssessmentForm.controls['past3MoDdYsnythrl'].value,
    };
    this.tapsAssessment.past3MoWhtWrThthr =
      this.tapsAssessmentForm.controls['past3MoWhtWrThthr'].value;

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.tapsAssessment.accessCode = this.accessCode;

      this.tapsAssessmentApiService.addGuestTaps(this.tapsAssessment).subscribe(
        (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully submitted TAPS-I & TAPS-II Assessment'
          );
          if (response.result) this.taskCompleted.emit(true);
          this.processing = false;
        },
        (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Failed submitting TAPS-I & TAPS-II Assessment'
          );
        }
      );
    } else {
      this.tapsAssessmentApiService.addTaps(this.tapsAssessment).subscribe(
        (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully submitted TAPS-I & TAPS-II Assessment'
          );
          if (response.result) this.taskCompleted.emit(true);
          this.processing = false;
        },
        (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Failed submitting TAPS-I & TAPS-II Assessment'
          );
        }
      );
    }
  }

  public validation_messages = {};

  private buildForm() {
    this.tapsAssessmentForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),

      tobaccoUse12M: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      male5Drinks12M: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([
          this.patient.gender === 'MALE' || this.patient.gender === 'M'
            ? Validators.required
            : null,
        ])
      ),
      female4Drinks12M: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([
          this.patient.gender === 'FEMALE' || this.patient.gender === 'F'
            ? Validators.required
            : null,
        ])
      ),
      drugUse12M: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      rxFeelMoreUse12M: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      past3MoDdYSmkCgrt: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      past3MoDdYsllySmk: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      past3MoDdYsllySmk1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      past3MoDdYHvDrnkC: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      past3MoDdYHv4rMrD: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      past3MoDdYHv5rMrD: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      past3MoHvYTrdndFlCtDwnrStpD: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      past3MoHsnynxprss: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      past3MoDdYsMrjnHsWeed: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      past3MoHvYHdStrng: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      past3MoHsnynxprss1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      past3MoDdYsCcnCrackrMthmpht: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      past3MoDdYsCcnCrackrMthmpht1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      past3MoHsnynxprssCrackrMthm: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      past3MoDdYsHrn: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      past3MoHvYTrdndFlCtDwnrStps: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      past3MoHsnynxprss2: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      past3MoDdYsPrscrp: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      past3MoHvYTrdndFlCtDwnrSt1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      past3MoHsnynxprss3: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      past3MoDdYsMdctnF: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      past3MoHvYHdStrng1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      past3MoHsnynxprss4: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      past3MoDdYsMdctnF1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      past3MoDdYsMdctnF2: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      past3MoHsnynxprss5: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      past3MoDdYsnythrl: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      past3MoWhtWrThthr: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
    });
    this.tapsAssessmentForm.valueChanges.subscribe(() => this.onValueChanged());
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.tapsAssessmentForm) {
      return;
    }
    const form = this.tapsAssessmentForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }

  setSubsequentQuestions(qstnId: string) {
    var answer = this.tapsAssessmentForm.controls[qstnId].value;
    switch (qstnId) {
      case 'tobaccoUse12M':
        if (answer === 'Never') {
          this.tapsAssessmentForm.controls['past3MoDdYSmkCgrt'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoDdYSmkCgrt'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoDdYSmkCgrt'].reset();

          this.tapsAssessmentForm.controls['past3MoDdYsllySmk'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoDdYsllySmk'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoDdYsllySmk'].reset();

          this.tapsAssessmentForm.controls['past3MoDdYsllySmk1'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoDdYsllySmk1'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoDdYsllySmk1'].reset();

          this.requestTobaccoQstns = false;
        } else {
          this.tapsAssessmentForm.controls['past3MoDdYSmkCgrt'].addValidators(
            Validators.required
          );
          this.requestTobaccoQstns = true;
        }
        break;
      case 'male5Drinks12M':
      case 'female4Drinks12M':
        if (answer === 'Never') {
          this.tapsAssessmentForm.controls['past3MoDdYHvDrnkC'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoDdYHvDrnkC'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoDdYHvDrnkC'].reset();

          this.tapsAssessmentForm.controls['past3MoDdYHv4rMrD'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoDdYHv4rMrD'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoDdYHv4rMrD'].reset();

          this.tapsAssessmentForm.controls['past3MoDdYHv5rMrD'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoDdYHv5rMrD'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoDdYHv5rMrD'].reset();

          this.tapsAssessmentForm.controls[
            'past3MoHvYTrdndFlCtDwnrStpD'
          ].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoHvYTrdndFlCtDwnrStpD'
          ].clearValidators();
          this.tapsAssessmentForm.controls[
            'past3MoHvYTrdndFlCtDwnrStpD'
          ].reset();

          this.tapsAssessmentForm.controls['past3MoHsnynxprss'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoHsnynxprss'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoHsnynxprss'].reset();

          this.requestAlcoholQstns = false;
        } else {
          this.tapsAssessmentForm.controls['past3MoDdYHvDrnkC'].addValidators(
            Validators.required
          );
          this.requestAlcoholQstns = true;
        }
        break;
      case 'drugUse12M':
        if (answer === 'Never') {
          this.tapsAssessmentForm.controls['past3MoDdYsMrjnHsWeed'].setValue(
            ''
          );
          this.tapsAssessmentForm.controls[
            'past3MoDdYsMrjnHsWeed'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoDdYsMrjnHsWeed'].reset();

          this.tapsAssessmentForm.controls['past3MoHvYHdStrng'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoHvYHdStrng'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoHvYHdStrng'].reset();

          this.tapsAssessmentForm.controls['past3MoHsnynxprss1'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoHsnynxprss1'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoHsnynxprss1'].reset();

          this.tapsAssessmentForm.controls[
            'past3MoDdYsCcnCrackrMthmpht'
          ].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoDdYsCcnCrackrMthmpht'
          ].clearValidators();
          this.tapsAssessmentForm.controls[
            'past3MoDdYsCcnCrackrMthmpht'
          ].reset();

          this.tapsAssessmentForm.controls[
            'past3MoDdYsCcnCrackrMthmpht1'
          ].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoDdYsCcnCrackrMthmpht1'
          ].clearValidators();
          this.tapsAssessmentForm.controls[
            'past3MoDdYsCcnCrackrMthmpht1'
          ].reset();

          this.tapsAssessmentForm.controls[
            'past3MoHsnynxprssCrackrMthm'
          ].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoHsnynxprssCrackrMthm'
          ].clearValidators();
          this.tapsAssessmentForm.controls[
            'past3MoHsnynxprssCrackrMthm'
          ].reset();

          this.tapsAssessmentForm.controls['past3MoDdYsHrn'].setValue('');
          this.tapsAssessmentForm.controls['past3MoDdYsHrn'].clearValidators();
          this.tapsAssessmentForm.controls['past3MoDdYsHrn'].reset();

          this.tapsAssessmentForm.controls[
            'past3MoHvYTrdndFlCtDwnrStps'
          ].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoHvYTrdndFlCtDwnrStps'
          ].clearValidators();
          this.tapsAssessmentForm.controls[
            'past3MoHvYTrdndFlCtDwnrStps'
          ].reset();

          this.tapsAssessmentForm.controls['past3MoHsnynxprss2'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoHsnynxprss2'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoHsnynxprss2'].reset();

          this.requestPrescriptionQstns = false;
        } else {
          this.tapsAssessmentForm.controls[
            'past3MoDdYsMrjnHsWeed'
          ].addValidators(Validators.required);
          this.tapsAssessmentForm.controls[
            'past3MoDdYsCcnCrackrMthmpht'
          ].addValidators(Validators.required);
          this.tapsAssessmentForm.controls['past3MoDdYsHrn'].addValidators(
            Validators.required
          );
          this.requestPrescriptionQstns = true;
        }
        break;
      case 'rxFeelMoreUse12M':
        if (answer === 'Never') {
          this.tapsAssessmentForm.controls['past3MoDdYsPrscrp'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoDdYsPrscrp'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoDdYsPrscrp'].reset();

          this.tapsAssessmentForm.controls[
            'past3MoHvYTrdndFlCtDwnrSt1'
          ].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoHvYTrdndFlCtDwnrSt1'
          ].clearValidators();
          this.tapsAssessmentForm.controls[
            'past3MoHvYTrdndFlCtDwnrSt1'
          ].reset();

          this.tapsAssessmentForm.controls['past3MoHsnynxprss3'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoHsnynxprss3'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoHsnynxprss3'].reset();

          this.tapsAssessmentForm.controls['past3MoDdYsMdctnF'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoDdYsMdctnF'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoDdYsMdctnF'].reset();

          this.tapsAssessmentForm.controls['past3MoHvYHdStrng1'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoHvYHdStrng1'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoHvYHdStrng1'].reset();

          this.tapsAssessmentForm.controls['past3MoHsnynxprss4'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoHsnynxprss4'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoHsnynxprss4'].reset();

          this.tapsAssessmentForm.controls['past3MoDdYsMdctnF1'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoDdYsMdctnF1'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoDdYsMdctnF1'].reset();

          this.tapsAssessmentForm.controls['past3MoDdYsMdctnF2'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoDdYsMdctnF2'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoDdYsMdctnF2'].reset();

          this.tapsAssessmentForm.controls['past3MoHsnynxprss5'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoHsnynxprss5'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoHsnynxprss5'].reset();

          this.tapsAssessmentForm.controls['past3MoDdYsnythrl'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoDdYsnythrl'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoDdYsnythrl'].reset();

          this.tapsAssessmentForm.controls['past3MoWhtWrThthr'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoWhtWrThthr'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoWhtWrThthr'].reset();

          this.requestSubstanceQstns = false;
        } else {
          this.tapsAssessmentForm.controls['past3MoDdYsPrscrp'].addValidators(
            Validators.required
          );
          this.tapsAssessmentForm.controls['past3MoDdYsMdctnF'].addValidators(
            Validators.required
          );
          this.tapsAssessmentForm.controls['past3MoDdYsMdctnF1'].addValidators(
            Validators.required
          );
          this.tapsAssessmentForm.controls['past3MoDdYsnythrl'].addValidators(
            Validators.required
          );
          this.requestSubstanceQstns = true;
        }
        break;
      case 'past3MoDdYSmkCgrt':
        if (answer === 'Yes') {
          this.tapsAssessmentForm.controls['past3MoDdYsllySmk'].addValidators(
            Validators.required
          );
          this.tapsAssessmentForm.controls['past3MoDdYsllySmk1'].addValidators(
            Validators.required
          );
        } else {
          this.tapsAssessmentForm.controls['past3MoDdYsllySmk'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoDdYsllySmk'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoDdYsllySmk'].reset();

          this.tapsAssessmentForm.controls['past3MoDdYsllySmk1'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoDdYsllySmk1'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoDdYsllySmk1'].reset();
        }
        break;
      case 'past3MoDdYHvDrnkC':
        if (answer === 'Yes') {
          this.tapsAssessmentForm.controls['past3MoDdYHv4rMrD'].addValidators(
            this.patient.gender === 'FEMALE' || this.patient.gender === 'F'
              ? Validators.required
              : null
          );
          this.tapsAssessmentForm.controls['past3MoDdYHv5rMrD'].addValidators(
            this.patient.gender === 'MALE' || this.patient.gender === 'M'
              ? Validators.required
              : null
          );
          this.tapsAssessmentForm.controls[
            'past3MoHvYTrdndFlCtDwnrStpD'
          ].addValidators(Validators.required);
          this.tapsAssessmentForm.controls['past3MoHsnynxprss'].addValidators(
            Validators.required
          );
        } else {
          this.tapsAssessmentForm.controls['past3MoDdYHv4rMrD'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoDdYHv4rMrD'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoDdYHv4rMrD'].reset();

          this.tapsAssessmentForm.controls['past3MoDdYHv5rMrD'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoDdYHv5rMrD'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoDdYHv5rMrD'].reset();

          this.tapsAssessmentForm.controls[
            'past3MoHvYTrdndFlCtDwnrStpD'
          ].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoHvYTrdndFlCtDwnrStpD'
          ].clearValidators();
          this.tapsAssessmentForm.controls[
            'past3MoHvYTrdndFlCtDwnrStpD'
          ].reset();

          this.tapsAssessmentForm.controls['past3MoHsnynxprss'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoHsnynxprss'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoHsnynxprss'].reset();
        }
        break;
      case 'past3MoDdYsMrjnHsWeed':
        if (answer === 'Yes') {
          this.tapsAssessmentForm.controls['past3MoHvYHdStrng'].addValidators(
            Validators.required
          );
          this.tapsAssessmentForm.controls['past3MoHsnynxprss1'].addValidators(
            Validators.required
          );
        } else {
          this.tapsAssessmentForm.controls['past3MoHvYHdStrng'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoHvYHdStrng'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoHvYHdStrng'].reset();

          this.tapsAssessmentForm.controls['past3MoHsnynxprss1'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoHsnynxprss1'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoHsnynxprss1'].reset();
        }
        break;
      case 'past3MoDdYsCcnCrackrMthmpht':
        if (answer === 'Yes') {
          this.tapsAssessmentForm.controls[
            'past3MoDdYsCcnCrackrMthmpht1'
          ].addValidators(Validators.required);
          this.tapsAssessmentForm.controls[
            'past3MoHsnynxprssCrackrMthm'
          ].addValidators(Validators.required);
        } else {
          this.tapsAssessmentForm.controls[
            'past3MoDdYsCcnCrackrMthmpht1'
          ].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoDdYsCcnCrackrMthmpht1'
          ].clearValidators();
          this.tapsAssessmentForm.controls[
            'past3MoDdYsCcnCrackrMthmpht1'
          ].reset();

          this.tapsAssessmentForm.controls[
            'past3MoHsnynxprssCrackrMthm'
          ].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoHsnynxprssCrackrMthm'
          ].clearValidators();
          this.tapsAssessmentForm.controls[
            'past3MoHsnynxprssCrackrMthm'
          ].reset();
        }
        break;
      case 'past3MoDdYsHrn':
        if (answer === 'Yes') {
          this.tapsAssessmentForm.controls[
            'past3MoHvYTrdndFlCtDwnrStps'
          ].addValidators(Validators.required);
          this.tapsAssessmentForm.controls['past3MoHsnynxprss2'].addValidators(
            Validators.required
          );
        } else {
          this.tapsAssessmentForm.controls[
            'past3MoHvYTrdndFlCtDwnrStps'
          ].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoHvYTrdndFlCtDwnrStps'
          ].clearValidators();
          this.tapsAssessmentForm.controls[
            'past3MoHvYTrdndFlCtDwnrStps'
          ].reset();

          this.tapsAssessmentForm.controls['past3MoHsnynxprss2'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoHsnynxprss2'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoHsnynxprss2'].reset();
        }
        break;
      case 'past3MoDdYsPrscrp':
        if (answer === 'Yes') {
          this.tapsAssessmentForm.controls[
            'past3MoHvYTrdndFlCtDwnrSt1'
          ].addValidators(Validators.required);
          this.tapsAssessmentForm.controls['past3MoHsnynxprss3'].addValidators(
            Validators.required
          );
        } else {
          this.tapsAssessmentForm.controls[
            'past3MoHvYTrdndFlCtDwnrSt1'
          ].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoHvYTrdndFlCtDwnrSt1'
          ].clearValidators();
          this.tapsAssessmentForm.controls[
            'past3MoHvYTrdndFlCtDwnrSt1'
          ].reset();

          this.tapsAssessmentForm.controls['past3MoHsnynxprss3'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoHsnynxprss3'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoHsnynxprss3'].reset();
        }
        break;
      case 'past3MoDdYsMdctnF':
        if (answer === 'Yes') {
          this.tapsAssessmentForm.controls['past3MoHvYHdStrng1'].addValidators(
            Validators.required
          );
          this.tapsAssessmentForm.controls['past3MoHsnynxprss4'].addValidators(
            Validators.required
          );
        } else {
          this.tapsAssessmentForm.controls['past3MoHvYHdStrng1'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoHvYHdStrng1'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoHvYHdStrng1'].reset();

          this.tapsAssessmentForm.controls['past3MoHsnynxprss4'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoHsnynxprss4'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoHsnynxprss4'].reset();
        }
        break;
      case 'past3MoDdYsMdctnF1':
        if (answer === 'Yes') {
          this.tapsAssessmentForm.controls['past3MoDdYsMdctnF2'].addValidators(
            Validators.required
          );
          this.tapsAssessmentForm.controls['past3MoHsnynxprss5'].addValidators(
            Validators.required
          );
        } else {
          this.tapsAssessmentForm.controls['past3MoDdYsMdctnF2'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoDdYsMdctnF2'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoDdYsMdctnF2'].reset();

          this.tapsAssessmentForm.controls['past3MoHsnynxprss5'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoHsnynxprss5'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoHsnynxprss5'].reset();
        }
        break;
      case 'past3MoDdYsnythrl':
        if (answer === 'Yes') {
          this.tapsAssessmentForm.controls['past3MoWhtWrThthr'].addValidators(
            Validators.required
          );
        } else {
          this.tapsAssessmentForm.controls['past3MoWhtWrThthr'].setValue('');
          this.tapsAssessmentForm.controls[
            'past3MoWhtWrThthr'
          ].clearValidators();
          this.tapsAssessmentForm.controls['past3MoWhtWrThthr'].reset();
        }
        break;
    }
    this.tapsAssessmentForm.updateValueAndValidity();
  }
}
