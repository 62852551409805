import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastMessageService, UploadFilesService } from 'src/app/_services';

@Component({
  selector: 'app-add-consent-document',
  templateUrl: './add-consent-document.component.html',
  styleUrls: ['./add-consent-document.component.css'],
})
export class AddConsentDocumentComponent implements OnInit {
  sessionId: string = '';
  sessionCode: string = '';
  patientId: string = '';
  type: string = '';
  consentForm: FormGroup;
  processing: boolean = false;
  fileSizeExceeded: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddConsentDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public uploadFilesService: UploadFilesService,
    private toastMessageService: ToastMessageService
  ) {
    this.sessionId = data.sessionId;
    this.sessionCode = data.sessionCode;
    this.patientId = data.patientId;
    this.type = data.type;
  }

  ngOnInit(): void {
    this.consentForm = new FormGroup({
      fileType: new FormControl(this.type),
      file: new FormControl(null, Validators.required),
    });
  }

  onFileSelected(event) {
    let file = event.target.files[0];

    if (file) {
      if (file.size > 10000000) {
        this.fileSizeExceeded = true;
        this.toastMessageService.displayInfoMessage(
          'File size exceeds 10 MB limit'
        );
        this.consentForm.controls['file'].setValue(null);
      } else {
        this.fileSizeExceeded = false;
        this.consentForm.controls['file'].setValue(file);
      }
    }
  }

  submit() {
    this.processing = true;

    this.uploadFilesService
      .uploadSessionDocument(
        this.sessionId,
        this.sessionCode,
        this.patientId,
        this.consentForm.controls['fileType'].value,
        this.consentForm.controls['file'].value
      )
      .subscribe(
        (response) => {
          if (response) {
            this.processing = false;
            this.dialogRef.close('success');
          }
        },
        (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Something went wrong. Please try again.'
          );
        }
      );
  }
}
