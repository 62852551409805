<mat-card appearance="raised" style="margin-top: 10px; margin-bottom: 20px">
  <div class="row" style="margin-bottom: 25px">
    <div class="col-md-6">
      <mat-card-title>
        <span
          style="color: #2196f3; cursor: pointer; font-weight: 500"
          (click)="deSelectReport()"
        >
          Reports
        </span>
        &nbsp; / &nbsp; Behavior Incidents
      </mat-card-title>
    </div>
    <div
      class="col-md-6"
      *ngIf="hasIncidentAddAccess && patient.status === 'Active'"
      style="text-align: right"
    >
      <button
        mat-raised-button
        color="warn"
        type="button"
        (click)="addBehaviorIncident()"
      >
        + Add Behavior Incident
      </button>
    </div>
  </div>

  <mat-progress-bar
    mode="indeterminate"
    *ngIf="getIncidentsProcessing"
  ></mat-progress-bar>

  <div class="row" style="margin-top: 15px">
    <!-- Calendar Component -->
    <div [class]="hasBehaviorPredictFeature ? 'col-md-8' : 'col-md-12'">
      <div class="mat-elevation-z8">
        <mbsc-datepicker
          [(ngModel)]="selectedDate"
          [controls]="['calendar']"
          display="inline"
          [min]="minimumDate"
          max="currentDate"
          [calendarType]="calType"
          [calendarHeaderTemplate]="headerTemplate"
          (onPageChange)="onPageChange($event)"
          (onCellClick)="onCellClick($event)"
          [marked]="marked"
          [colors]="colors"
        >
          <ng-template #headerTemplate>
            <mbsc-calendar-prev></mbsc-calendar-prev>
            <mbsc-calendar-nav></mbsc-calendar-nav>
            <mbsc-calendar-next></mbsc-calendar-next>
          </ng-template>
        </mbsc-datepicker>
      </div>
      <!-- Legend -->
      <div
        style="
          margin-top: 15px;
          display: flex;
          column-gap: 80px;
          justify-content: center;
        "
      >
        <div *ngFor="let incident of behaviorColorIcon">
          <div style="text-align: center">
            <mat-icon [style.color]="incident.id" style="font-size: 16px">
              fiber_manual_record
            </mat-icon>
          </div>
          <div>
            <label
              [style.color]="incident.id"
              style="font-size: 14px; font-weight: 450"
              >{{ incident.severity }}</label
            >
          </div>
        </div>
      </div>
    </div>

    <!-- Predictive Component -->
    <div class="col-md-4" *ngIf="hasBehaviorPredictFeature">
      <app-behavior-prediction [patient]="patient"> </app-behavior-prediction>
    </div>
  </div>
</mat-card>
