import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastMessageService } from 'src/app/_services';
import { ConsentDocumentApiService } from 'src/app/_services/consent-docs/orgconsentdocument.service';

@Component({
  selector: 'app-view-member-signed-consent-document',
  templateUrl: './view-member-signed-consent-document.component.html',
  styleUrl: './view-member-signed-consent-document.component.css',
})
export class ViewMemberSignedConsentDocumentComponent implements OnInit {
  @ViewChild('pdfViewerOnDemand', { static: false }) pdfViewerOnDemand;

  documentName: string = '';
  recordId: string = '';
  isLoadingResults: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ViewMemberSignedConsentDocumentComponent>,
    public consentDocumentApiService: ConsentDocumentApiService,
    private toastMessageService: ToastMessageService
  ) {
    this.documentName = data.documentName;
    this.recordId = data.recordId;
  }

  ngOnInit(): void {
    this.getConsentDocumentInformation();
  }

  getConsentDocumentInformation() {
    this.isLoadingResults = true;

    this.consentDocumentApiService
      .getSignedConsentDocument(this.recordId)
      .subscribe(
        (res) => {
          this.isLoadingResults = false;
          this.pdfViewerOnDemand.pdfSrc = res;
          this.pdfViewerOnDemand.refresh();
        },
        (error) => {
          this.isLoadingResults = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to retrieve the consent document'
          );
        }
      );
  }
}
