import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { toHTML } from 'ngx-editor';
import { TherapySessionService, ToastMessageService } from 'src/app/_services';
import { ClinicalNoteSectionsApiService } from 'src/app/_services/session/clinical/clinicalnotesections.service';
import { FavoritesSandbox } from 'src/app/shared/sandbox/favorites-sandbox';
import { UserSettingsPropsSandbox } from 'src/app/shared/sandbox/user-settings-props.sandbox';
import { TemplateDialogComponent } from '../template-dialog/template-dialog.component';

@Component({
  selector: 'app-clinical-shortcut-templates',
  templateUrl: './clinical-shortcut-templates.component.html',
  styleUrl: './clinical-shortcut-templates.component.css',
})
export class ClinicalShortcutTemplatesComponent implements OnInit {
  @Input() activeTab: string = 'Text Shortcuts';
  @Input() selectedSectionName: string;
  @Output() shortCutResponse = new EventEmitter<any>();
  @Output() templateResponse = new EventEmitter<any>();
  @Output() closeCardTab = new EventEmitter<string>();

  isLoadingShortCutsTemplates: boolean = false;
  selectedShortCutCategory: string = '';
  shortCutsCategoriesList: any = [];
  templates: any = [];

  // Display list
  shortCuts: any = [];
  categoryShortCuts: any = [];
  searchShortcuts: string = '';
  searchList: any = [];
  sandboxFavList: any = [];
  favList: any = [];

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private clinicalNoteSectionsApiService: ClinicalNoteSectionsApiService,
    public therapysessionApiService: TherapySessionService,
    private toastMessageService: ToastMessageService,
    private favoritesSandbox: FavoritesSandbox,
    private userSettingsPropsSandbox: UserSettingsPropsSandbox
  ) {}

  ngOnInit(): void {
    this.loadExistingShortCutsAndTemplates();

    // Subcribe to favorites sandbox
    this.favoritesSandbox.favoritesPayload$.subscribe((favoritesPayload) => {
      if (favoritesPayload) {
        this.sandboxFavList = favoritesPayload.data;
        this.toggleAllFavourites();
      }
    });
  }

  loadExistingShortCutsAndTemplates() {
    this.isLoadingShortCutsTemplates = true;

    this.clinicalNoteSectionsApiService
      .getOrganizationAllActiveShortcutsTemplates()
      .subscribe({
        next: (response) => {
          if (response && response.data) {
            this.shortCutsCategoriesList = response.data.shortcutCategories;
            this.shortCuts = response.data.shortcuts;
            this.templates = response.data.templates;

            this.toggleAllFavourites();
          } else {
            this.shortCutsCategoriesList = [];
            this.shortCuts = [];
            this.templates = [];
          }
          this.isLoadingShortCutsTemplates = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load text shortcuts and templates'
          );
          this.isLoadingShortCutsTemplates = false;
        },
      });
  }

  selectShortcutCategory(categoryName: string, categoryId: string) {
    this.selectedShortCutCategory = categoryName;

    // Toggle all favourites
    this.toggleAllFavourites();

    // Filter categoryShortCuts
    let selectedShortCuts = this.shortCuts.filter((shortcut) => {
      return shortcut.categoryId === categoryId;
    });

    this.categoryShortCuts = selectedShortCuts;
  }

  deSelectShortCutCategory() {
    this.selectedShortCutCategory = '';
    this.categoryShortCuts = [];
  }

  // From sandbox handle all the stars
  toggleAllFavourites() {
    if (this.shortCuts) {
      this.shortCuts.forEach((shortcut) => {
        shortcut.isFavorite = this.isFavorite('TEXT_SHORTCUTS', shortcut.id);
      });

      this.favList = this.shortCuts.filter((shortcut) => shortcut.isFavorite);
    }
  }

  // Determine if this category is fav or not
  isFavorite(categoryName: string, favoriteItem: string): boolean {
    if (this.sandboxFavList && this.sandboxFavList.favoriteCategories) {
      const category = this.sandboxFavList.favoriteCategories.find(
        (cat) => cat.name === categoryName
      );
      return category && category.userFavorites.includes(favoriteItem);
    } else {
      return false;
    }
  }

  // Toggle fav
  toggleFavorite(categoryName: string, item: any): void {
    if (this.isFavorite(categoryName, item.id)) {
      this.favoritesSandbox.removeFavorite(categoryName, item.id);
    } else {
      this.favoritesSandbox.addFavorite(categoryName, item.id);
    }
  }

  closePanel() {
    this.selectedShortCutCategory = '';
    this.categoryShortCuts = [];
    this.closeCardTab.emit();
  }

  addTextShortcutToEditor(shortcut) {
    this.shortCutResponse.emit(shortcut);
  }

  addTemplateToEditor(template) {
    const dialogRef = this.dialog.open(TemplateDialogComponent, {
      data: { template: template },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.templateResponse.emit(response);
      }
    });
  }

  // Search Shortcuts changed
  searchChanged() {
    if (this.searchShortcuts) {
      this.searchList = this.shortCuts.filter((shortcut) => {
        if (
          shortcut.shortDesc
            .toLowerCase()
            .includes(this.searchShortcuts.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.searchList = [];
    }
  }

  getContent(shortcut) {
    let htmlContents = toHTML(shortcut.content);
    return htmlContents;
  }
}
