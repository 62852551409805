import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-remittance-details-header',
  templateUrl: './remittance-details-header.component.html',
  styleUrl: './remittance-details-header.component.css',
})
export class RemittanceDetailsHeaderComponent implements OnChanges {
  @Input() remitDetails: any;

  ngOnChanges(changes: SimpleChanges): void {}
}
