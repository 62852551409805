<!-- // Bread Crumbs -->
<div class="row">
  <p style="font-size: 16px; font-weight: 450; margin-left: 10px">
    <a routerLink="/" routerLinkActive="active">
      <em
        class="material-icons text_align-center"
        style="font-size: 18px; margin-top: -3px"
        >home</em
      > </a
    >&nbsp; / &nbsp;
    <a
      [routerLink]="['/main/member', patientId, 'MEMBER']"
      [queryParams]="{ secondaryId: '3' }"
    >
      Member - {{ patient?.lastName }}, {{ patient?.firstName }} </a
    >&nbsp; / &nbsp; Collect New Payment
  </p>
</div>

<form [formGroup]="newPatientPaymentForm">
  <!-- Two Screens -->
  <div class="row">
    <!-- Left Side Payment Information -->
    <div
      class="col-md-8"
      style="
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 20px;
      "
    >
      <!-- Left Card -->
      <mat-card
        appearance="raised"
        class="mat-elevation-z8"
        style="border-radius: 15px"
      >
        <label class="labelHeader"> Member Details </label>
        <!-- Member Details -->
        <div class="twoGridContainer">
          <!-- Member Name -->
          <label class="labelBold">Member Name</label>
          <label class="labelText">
            {{ patient?.lastName }},{{ ' ' }} {{ patient?.firstName }}{{ ' ' }}
            {{ patient?.middleName }}
          </label>
          <!-- Member DOB -->
          <label class="labelBold">Member DOB</label>
          <label class="labelText">
            {{ patient?.dateOfBirth }}
          </label>
          <!-- Member Phone -->
          <label class="labelBold" *ngIf="patient?.phoneNumber">
            Member Phone
          </label>
          <label class="labelText" *ngIf="patient?.phoneNumber">
            {{ patient?.phoneNumber | phoneFormat }}
          </label>
        </div>
      </mat-card>

      <!-- Payment Amount -->
      <mat-card
        appearance="raised"
        class="mat-elevation-z8"
        style="border-radius: 15px"
      >
        <label class="labelHeader">
          Payment Amount <span style="color: red">*</span></label
        >
        <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="outline">
              <input
                matInput
                placeholder="Payment Amount"
                formControlName="totalAmount"
                type="number"
                required
                min="1"
                max="999999"
              />
              <span matPrefix>$</span>
              <mat-error
                *ngIf="newPatientPaymentForm.controls['totalAmount'].invalid"
              >
                Payment amount must be within $1 - $999,999
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <label class="labelHeader"> Payment note </label>
            <mat-form-field appearance="outline">
              <textarea
                matInput
                rows="1"
                placeholder=""
                formControlName="paymentNote"
                name="paymentNote"
                spellcheck="true"
              ></textarea>
            </mat-form-field>
          </div>
          <!-- Give an option to add this note for this session -->
          <div class="col-md-12" *ngIf="sessionId">
            <mat-checkbox color="primary" formControlName="addNoteToSession">
              Add this payment note to the therapy session
            </mat-checkbox>
          </div>
        </div>
      </mat-card>

      <!-- Payment Method  -->
      <mat-card
        appearance="raised"
        class="mat-elevation-z8"
        style="border-radius: 15px"
      >
        <label class="labelHeader"> Payment Method </label>
        <div class="row">
          <div class="col-md-12" style="margin-bottom: 12px">
            <mat-radio-group
              formControlName="paymentType"
              style="column-gap: 15px; display: flex; flex-wrap: wrap"
              color="primary"
              (change)="paymentMethodChanged($event)"
            >
              <mat-radio-button value="Card"> Card </mat-radio-button>
              <mat-radio-button value="Cash"> Cash </mat-radio-button>
              <mat-radio-button value="Check"> Check </mat-radio-button>
            </mat-radio-group>
          </div>
          <!-- If Check is the selected payment method -->
          <div
            class="col-md-12"
            *ngIf="
              newPatientPaymentForm.controls['paymentType'].value === 'Check'
            "
          >
            <label class="labelBold">Check Number</label>
            <mat-form-field appearance="outline">
              <input
                matInput
                placeholder="Check Number"
                formControlName="checkNumber"
              />
            </mat-form-field>
          </div>
          <!-- If Card is the selected payment method -->
          <div
            class="col-md-12"
            *ngIf="
              newPatientPaymentForm.controls['paymentType'].value === 'Card'
            "
            style="margin-bottom: 12px"
          >
            <label class="labelBold">
              Choose an online card on file or select add new
              <span style="color: red">*</span>
            </label>

            <!-- List Existing Cards if any -->
            <mat-radio-group
              formControlName="paymentMethodId"
              style="
                column-gap: 15px;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
              "
              color="primary"
              (change)="cardSelected($event)"
            >
              <mat-radio-button
                [value]="card.id"
                *ngFor="let card of savedPaymentMehods.items"
              >
                <b>{{ card.type }} - {{ card.last4 }}</b> (Exp
                {{ card.exp_month }}/{{ card.exp_year }})

                <span
                  class="defaultIndicator"
                  *ngIf="card.id === defaultPaymentId"
                >
                  Default
                </span>
              </mat-radio-button>
              <mat-radio-button value="Add New">
                <b>+ Add New</b>
              </mat-radio-button>
            </mat-radio-group>
          </div>

          <!-- If Add New Card is selected -->
          <div
            class="col-md-12"
            *ngIf="
              newPatientPaymentForm.controls['paymentMethodId'].value ===
              'Add New'
            "
          >
            <mat-checkbox
              color="primary"
              [checked]="isSaveNewPaymentChecked()"
              (change)="saveCardOnFileChanged($event)"
            >
              <span style="word-break: break-word">
                Save new card on file
              </span>
            </mat-checkbox>
          </div>
        </div>
      </mat-card>
    </div>
    <!-- Right Side Confirm Information -->
    <div
      class="col-md-4"
      style="
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 20px;
      "
    >
      <mat-card
        appearance="raised"
        class="mat-elevation-z8"
        style="border-radius: 15px"
      >
        <label class="labelHeader"> Summary </label>
        <!-- Payment Details -->
        <div class="twoGridContainer">
          <!-- Payment Amount -->
          <label class="labelBold">Payment Amount</label>
          <label class="labelText">
            {{
              newPatientPaymentForm.controls['totalAmount'].value
                | currency : 'USD'
            }}
          </label>
        </div>
        <!-- Payment Note -->
        <div>
          <label>
            {{ newPatientPaymentForm.controls['paymentNote'].value }}
          </label>
        </div>
        <!-- Button -->

        <button
          [class]="
            newPatientPaymentForm.invalid
              ? 'disabledChargeButton'
              : 'chargeButton'
          "
          mat-raised-button
          type="button"
          (click)="submitForm()"
          [disabled]="newPatientPaymentForm.invalid || processing"
        >
          <span
            *ngIf="
              newPatientPaymentForm.controls['paymentMethodId'].value ===
              'Add New'
            "
          >
            Continue to Checkout
          </span>
          <span
            *ngIf="
              newPatientPaymentForm.controls['paymentType'].value === 'Card' &&
              newPatientPaymentForm.controls['paymentMethodId'].value !=
                'Add New'
            "
          >
            Charge Card
          </span>
          <span
            *ngIf="
              newPatientPaymentForm.controls['paymentType'].value != 'Card'
            "
          >
            Record Payment
          </span>

          <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </mat-card>
    </div>
  </div>
</form>

<!-- Disclaimer -->
<div class="row">
  <div class="col-md-12">
    <label>
      * Card processing fees : For all major credit, debit, FSA, and HSA cards,
      the processing fee is 3.15% + $0.30 for each successful transaction.
    </label>
  </div>
</div>
