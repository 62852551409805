<div class="main-content" style="background-color: #eee" *ngIf="!isLoadingResults">
  <div class="container-fluid">
    <div class="container-fluid">
      <br />
      <div class="row">
        <div class="col-md-6">
          <mat-card appearance="raised">
            <mat-card-header>
              <h4 class="header text-center">Risk Score Graph</h4>
            </mat-card-header>
            <mat-card-content>
              <div class="chart-wrapper">
                <canvas baseChart height="100%" [data]="lineChartData" [options]="lineChartOptions"
                  [legend]="lineChartLegend" [type]="lineChartType" [plugins]="lineChartPlugins">
                </canvas>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-md-6">
          <mat-card appearance="raised">
            <mat-card-header>
              <h4 class="header text-center">Interpreting the Overall Score</h4>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
              <div class="row">
                <div class="col-md-2" style="font-weight: 400">Color</div>
                <div class="col-md-3" style="font-weight: 400">Score Range</div>
                <div class="col-md-7" style="padding-top: 4px; font-weight: 400">
                  Description
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <button mat-raised-button class="btn btn-just-icon btn-fab btn-round" style="
                      height: 20px;
                      width: 20px;
                      min-width: 20px;
                      background-color: #5a9e7c;
                    "></button>
                </div>
                <div class="col-md-3" style="padding-top: 4px; font-weight: 300">
                  0 to 4
                </div>
                <div class="col-md-7" style="padding-top: 4px; font-weight: 300">
                  Not indicative of depression symptoms
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <button mat-raised-button class="btn btn-just-icon btn-fab btn-round" style="
                      height: 20px;
                      width: 20px;
                      min-width: 20px;
                      background-color: #ffcd56;
                    "></button>
                </div>
                <div class="col-md-3" style="padding-top: 4px; font-weight: 300">
                  5 to 14
                </div>
                <div class="col-md-7" style="padding-top: 4px; font-weight: 300">
                  Indicative of minimal to minor depression symptoms
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <button mat-raised-button class="btn btn-just-icon btn-fab btn-round" style="
                      height: 20px;
                      width: 20px;
                      min-width: 20px;
                      background-color: #ff9f40;
                    "></button>
                </div>
                <div class="col-md-3" style="padding-top: 4px; font-weight: 300">
                  15 to 19
                </div>
                <div class="col-md-7" style="padding-top: 4px; font-weight: 300">
                  Indicative of moderately severe major depression symptoms
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <button mat-raised-button class="btn btn-just-icon btn-fab btn-round" style="
                      height: 20px;
                      width: 20px;
                      min-width: 20px;
                      background-color: #ff6384;
                    "></button>
                </div>
                <div class="col-md-3" style="padding-top: 4px; font-weight: 300">
                  20 to 27
                </div>
                <div class="col-md-7" style="padding-top: 4px; font-weight: 300">
                  Indicative of severe major depression symptoms
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <br />
      <div class="row">
        <ng-container *ngFor="let scoreDetail of scoreDetails">
          <div class="col-md-4" style="margin-bottom: 20px">
            <mat-card appearance="raised">
              <mat-card-header>
                <h4 class="header text-center">{{ scoreDetail.title }}</h4>
              </mat-card-header>
              <mat-card-content>
                <div style="width: 100%">
                  <div class="chart-wrapper">
                    <canvas baseChart [data]="scoreDetail" [options]="assessmentDetailChartOptions"
                      [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                    </canvas>
                  </div>
                </div>
              </mat-card-content>
              <small><b>Answer:</b> <i>{{ scoreDetail.answer }}</i></small>
            </mat-card>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>