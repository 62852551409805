import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assessment-cancelled',
  templateUrl: './assessment-cancelled.component.html',
  styleUrls: ['./assessment-cancelled.component.css']
})
export class AssessmentCancelledComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
