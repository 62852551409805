<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Run Insurance Eligibility</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-progress-bar mode="indeterminate" *ngIf="processing"></mat-progress-bar>

  <form [formGroup]="eligibilityForm" novalidate>
    <div class="row" style="margin-top: 15px">
      <div class="col-md-12">
        <label class="labelText">
          Rendering Provider
          <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="providerId"
            placeholder="Please Select a Rendering Provider"
          >
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Rendering Provider Name"
                noEntriesFoundLabel="No matching rendering provider found"
                [(ngModel)]="searchByRP"
                (ngModelChange)="filterRP($event)"
                [ngModelOptions]="{ standalone: true }"
              ></ngx-mat-select-search>
            </mat-option>

            <mat-option
              [value]="phy.id"
              *ngFor="let phy of filteredRenderingProviderList"
              style="height: auto"
            >
              <div class="row">
                <div class="col">{{ phy.lastName }}, {{ phy.firstName }}</div>
                <div class="col">
                  {{ phy.phoneNumber | phoneFormat }}
                </div>
                <div class="col">
                  {{ phy.emailAddress }}
                </div>
              </div>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="eligibilityForm.controls['providerId'].invalid">
            Please select a rendering provider
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <label class="labelText">
          Service Type <span style="color: red">*</span></label
        >
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="serviceType"
            placeholder="Please Select Service Type"
          >
            <mat-option value="30">Medical Benefits (30)</mat-option>
            <mat-option value="96">Professional (96)</mat-option>
            <mat-option value="A4">Psychiatric (A4)</mat-option>
            <mat-option value="A6">Psychotherapy (A6)</mat-option>
            <mat-option value="A7">Psychiatric - InPatient (A7)</mat-option>
            <mat-option value="A8">Psychiatric - OutPatient (A8)</mat-option>
          </mat-select>
          <mat-error *ngIf="eligibilityForm.controls['serviceType'].invalid">
            Please select Service Type
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <label class="labelText">
          Date of Service <span style="color: red">*</span></label
        >
        <mat-form-field appearance="outline">
          <input
            formControlName="startDate"
            matInput
            [matDatepicker]="startDatePicker"
            [min]="today"
            placeholder="MM/DD/YYYY"
            required
            autoSlashDate
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
          <mat-error *ngIf="eligibilityForm.controls['startDate'].invalid">
            Please select the start date
          </mat-error>
        </mat-form-field>
      </div>
      <!-- <div class="col-md-6">
        <label class="labelText"> End Date </label>
        <mat-form-field appearance="outline">
          <input formControlName="endDate" matInput [matDatepicker]="endDatePicker" placeholder="MM/DD/YYYY"
            [min]="eligibilityForm.controls['startDate'].value" />
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>
      </div> -->

      <div
        class="col-md-12"
        style="display: flex; justify-content: end; margin-bottom: 15px"
      >
        <button
          class="btn btn-primary"
          mat-raised-button
          type="submit"
          [disabled]="eligibilityForm.invalid || processing"
          (click)="submitForm()"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
