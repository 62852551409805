import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataTablesModel } from 'src/app/_models';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class CPTCodeApiService {
  constructor(private httpClient: HttpClient) {}

  getOrganizationCptCodeList(
    code: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());
    params = params.append('status', 'Active');

    if (code) params = params.append('code', code);

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/cptcodesbyorganization`,
      { params: params }
    );
  }

  getCptCodeListByFacility(
    facilityId: string,
    code: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();

    params = params.append('facilityId', facilityId);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());
    params = params.append('status', 'Active');

    if (code) params = params.append('code', code);

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/cptcodesbyfacility`,
      { params: params }
    );
  }

  getCptCodesByInsuranceList(
    insuranceId: string,
    code: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();

    params = params.append('insuranceId', insuranceId);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());
    params = params.append('status', 'Active');

    if (code) params = params.append('code', code);

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/cptcodesbyinsurance`,
      { params: params }
    );
  }
}
