<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Available Active Insurances</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <!-- Add New Insurance -->
  <div style="display: flex; justify-content: right; margin-bottom: 12px">
    <button
      mat-raised-button
      color="warn"
      type="button"
      (click)="addInsurance()"
    >
      + Add New Insurance
    </button>
  </div>

  <!-- No Active Insurance Cards -->
  <mat-error *ngIf="!processing && availableInsurance.total == 0">
    No Active Insurance Found
  </mat-error>

  <!-- Insurance Cards -->
  <div *ngIf="availableInsurance.total > 0">
    <mat-card
      appearance="raised"
      class="insuranceCard"
      *ngFor="let insurance of availableInsurance.items"
      style="margin-bottom: 20px"
    >
      <!-- Header  -->
      <div style="padding-top: 8px">
        <!-- Insurance Payer -->
        <label
          class="labelHeader"
          style="text-align: center; color: #2196f3; width: 90%"
        >
          {{ insurance.payerName }}
          <span *ngIf="insurance.claimPayerCode">
            ({{ insurance.claimPayerCode }})
          </span>
        </label>
      </div>
      <hr />
      <!-- Insurance Information -->
      <div class="gridContainer">
        <!-- Insurance Type -->
        <label class="labelText" style="font-weight: 500">
          Insurance Type
        </label>
        <span
          [class]="
            insurance.insuranceType === 'Primary'
              ? 'primaryStatus'
              : insurance.insuranceType === 'Secondary'
              ? 'secondaryStatus'
              : ''
          "
        >
          {{ insurance.insuranceType }}
        </span>
        <!-- Insured Relationship -->
        <label class="labelText" style="font-weight: 500">
          Insured Relationship
        </label>
        <label class="labelText">
          {{
            insurance.relationship === '19'
              ? 'Child'
              : insurance.relationship === '21'
              ? 'Other'
              : insurance.relationship === '18'
              ? 'Self'
              : 'Spouse'
          }}
        </label>
        <!-- Subscriber Name -->
        <label class="labelText" style="font-weight: 500">
          Subscriber Name
        </label>
        <label class="labelText">
          {{ insurance.subscriberLastName }},{{ ' ' }}
          {{ insurance.subscriberFirstName }}
        </label>
        <!-- Member Name -->
        <label class="labelText" style="font-weight: 500"> Member Name </label>
        <label class="labelText"> {{ insurance.memberName }} </label>
        <!-- Member Id -->
        <label class="labelText" style="font-weight: 500"> Member Id </label>
        <label class="labelText" style="color: #2196f3">
          {{ insurance.memberId }}
        </label>
        <!-- Plan Id -->
        <label
          class="labelText"
          style="font-weight: 500"
          *ngIf="insurance?.planId"
        >
          Plan Id
        </label>
        <label class="labelText" *ngIf="insurance?.planId">
          {{ insurance.planId }}
        </label>
        <!-- Group Id -->
        <label
          class="labelText"
          style="font-weight: 500"
          *ngIf="insurance?.groupId"
        >
          Group Id
        </label>
        <label class="labelText" *ngIf="insurance?.groupId">
          {{ insurance.groupId }}
        </label>
        <!-- Phone -->
        <label
          class="labelText"
          style="font-weight: 500"
          *ngIf="insurance?.payerPhone"
        >
          Payer Phone
        </label>
        <label class="labelText" *ngIf="insurance?.payerPhone">
          {{ insurance.payerPhone | phoneFormat }}
        </label>
        <!-- Fax -->
        <label
          class="labelText"
          style="font-weight: 500"
          *ngIf="insurance?.payerFax"
        >
          Payer Fax
        </label>
        <label class="labelText" *ngIf="insurance?.payerFax">
          {{ insurance.payerFax | phoneFormat }}
        </label>
        <!-- Employer/School -->
        <label
          class="labelText"
          style="font-weight: 500"
          *ngIf="insurance?.employerSchool"
        >
          Employer/School
        </label>
        <label class="labelText" *ngIf="insurance?.employerSchool">
          {{ insurance.employerSchool }}
        </label>
        <!-- Start Date -->
        <label
          class="labelText"
          style="font-weight: 500"
          *ngIf="insurance?.startDate"
        >
          Start Date
        </label>
        <label
          class="labelText"
          *ngIf="insurance?.startDate"
          style="font-weight: 500; color: #2196f3"
        >
          {{ insurance.startDate }}
        </label>
        <!-- End Date -->
        <label
          class="labelText"
          style="font-weight: 500"
          *ngIf="insurance?.endDate"
        >
          End Date
        </label>
        <label
          class="labelText"
          *ngIf="insurance?.endDate"
          style="font-weight: 500; color: #e82c2c"
        >
          {{ insurance.endDate }}
        </label>
        <label
          class="labelText"
          style="font-weight: 500"
          *ngIf="insurance?.copay"
        >
          Copay
        </label>
        <label
          class="labelText"
          *ngIf="insurance?.copay"
          style="font-weight: 500; color: red"
        >
          {{ insurance.copay | currency : 'USD' }}
        </label>
      </div>
      <!-- Use Action -->
      <div
        style="text-align: center"
        *ngIf="!checkIfInsuranceExists(insurance.id)"
      >
        <button
          class="btn btn-primary"
          mat-raised-button
          type="button"
          [disabled]="processing"
          (click)="useInsurance(insurance)"
        >
          Use This Insurance
          <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
      <div
        *ngIf="checkIfInsuranceExists(insurance.id)"
        style="text-align: center"
      >
        <label style="color: #029102">Already in Use</label>
      </div>
    </mat-card>
  </div>
</div>
