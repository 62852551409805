import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { KeycloakService } from 'keycloak-angular';
import { ToastMessageService, UsersService } from 'src/app/_services';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  edit: boolean = false;
  processing: boolean = false;
  userId: string;
  firstName: string;
  lastName: string;
  emailAddress: string;

  initialPhoneNumber: string;

  public profileForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};

  errors: any = {};

  constructor(
    private readonly keycloakService: KeycloakService,
    private usersService: UsersService,
    private toastMessageService: ToastMessageService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.errorHandler.handleErrors(this.profileForm, this.errors);
    this.loadUserProfile();
  }

  loadUserProfile() {
    let self = this;
    self.keycloakService.loadUserProfile().then(function (data) {
      self.userId = data.username;
      self.firstName = data.firstName;
      self.lastName = data.lastName;
      self.emailAddress = data.email;
    });

    this.usersService.getPhoneNumber().subscribe((res) => {
      self.profileForm.controls['phoneNumber'].setValue(res.data.phoneNumber);
      this.initialPhoneNumber = res.data.phoneNumber;
    });
  }

  editNumber() {
    this.onValueChanged();
    this.edit = false;
    this.processing = true;

    this.usersService
      .updatePhoneNumber(this.profileForm.controls['phoneNumber'].value)
      .subscribe((res) => {
        if (res.result) {
          this.toastMessageService.displaySuccessMessage(res.message.text);
          this.initialPhoneNumber =
            this.profileForm.controls['phoneNumber'].value;
        } else {
          this.toastMessageService.displayErrorMessage(
            'An error occured while updating the Phone Number'
          );
        }
        this.processing = false;
      });
  }

  private buildForm() {
    this.profileForm = this.formBuilder.group({
      phoneNumber: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          RxwebValidators.mask({ mask: '(999) 999-9999' }),
        ])
      ),
    });
  }

  private onValueChanged() {
    if (!this.profileForm) {
      return;
    }
    const form = this.profileForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }

  public validation_messages = {
    phoneNumber: [
      { type: 'required', message: 'Phone Number is required' },
      { type: 'mask', message: 'Please enter a valid 10 digit Phone Number' },
    ],
  };

  restoreNumber() {
    this.profileForm.controls['phoneNumber'].setValue(this.initialPhoneNumber);
    this.edit = false;
  }
}
