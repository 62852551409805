import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AssessmentApiService, ToastMessageService } from 'src/app/_services';

@Component({
  selector: 'app-view-guidance',
  templateUrl: './view-guidance.component.html',
  styleUrls: ['./view-guidance.component.css'],
})
export class ViewGuidanceComponent implements OnInit {
  error: string = '';
  selectedAssessmentType: string = '';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  questions: any[];
  questionAnswers: any[];
  answerForm: FormGroup;
  guidanceForm: FormGroup;
  processing: boolean = false;
  public patientId: string;
  public assessmentHistoryId: string;
  guidance: any;
  answers: any;

  constructor(
    public dialogRef: MatDialogRef<ViewGuidanceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public assessmentApiService: AssessmentApiService,
    private toastMessageService: ToastMessageService
  ) {
    this.patientId = data.record.patientId;
    this.assessmentHistoryId = data.record.id;
    this.selectedAssessmentType = data.record.assessmentType;
    this.guidance = data.record.guidance;
    this.questions = data.record.questions;
    this.answers = data.record.assessmentDetails;

    this.answerForm = this.fb.group({
      id: new FormControl('', Validators.compose([])),
      patientId: new FormControl(this.patientId, Validators.compose([])),
    });
  }
  ngOnInit(): void {
    this.answerForm = this.fb.group({
      id: new FormControl(this.assessmentHistoryId, Validators.compose([])),
      patientId: new FormControl(this.patientId, Validators.compose([])),
    });

    let questionAnswers = [];

    for (var question of this.questions) {
      let newAnswers = [];
      let selectedAnswer = this.answers[question.name].answer;
      if (selectedAnswer !== '') {
        let filteredAnswers = question.answers.filter(function (answer) {
          return answer.value === selectedAnswer;
        });

        if (filteredAnswers.length === 0) {
          let answer: any = {};
          answer.value = selectedAnswer;
          answer.id = question.name;
          this.addQuestion(answer.id);
          if (this.guidance && this.guidance[answer.id]) {
            for (var guidanceRecord of this.guidance[answer.id]) {
              this.addGuidanceRecord(answer.id, guidanceRecord.guidance);
            }
          } else {
            this.addGuidanceRecord(answer.id, '');
          }
          newAnswers.push(answer);
        } else {
          for (var answer of filteredAnswers) {
            if (!answer.id) {
              answer.id = question.name + '-' + answer.value;
            }
            this.addQuestion(answer.id);
            if (this.guidance && this.guidance[answer.id]) {
              for (var guidanceRecord of this.guidance[answer.id]) {
                this.addGuidanceRecord(answer.id, guidanceRecord.guidance);
              }
            } else {
              this.addGuidanceRecord(answer.id, '');
            }
            question.answer = answer;
            newAnswers.push(answer);
          }
        }

        question.answers = newAnswers;
        questionAnswers.push(question);
      }
    }
    this.questionAnswers = questionAnswers;

    // this.answerForm.valueChanges.subscribe(() =>
    //   this.onSubmit()
    // );
  }

  addQuestion(fieldName: string) {
    this.answerForm.addControl(fieldName, this.fb.array([]));
  }

  guidanceRecords(fieldName: string): FormArray {
    return this.answerForm.get(fieldName) as FormArray;
  }

  addGuidanceRecord(fieldName: string, value: string) {
    const newRecord = this.fb.group({
      guidance: new FormControl(value, Validators.compose([])),
    });
    this.guidanceRecords(fieldName).push(newRecord);
  }

  removeGuidanceRecord(fieldName: string, i: number) {
    this.guidanceRecords(fieldName).removeAt(i);
    this.onSubmit();
  }

  onSubmit() {
    this.processing = true;
    this.assessmentApiService
      .updatePatientAssessment(this.answerForm.value)
      .subscribe(
        (response) => {
          this.processing = false;
          this.toastMessageService.displaySuccessMessage(
            'Successfully updated'
          );
        },
        () => {
          this.toastMessageService.displaySuccessMessage(
            'An error occured while updating care document'
          );
        }
      );
  }
}
