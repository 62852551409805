<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Social Phobia Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="socialPhobiaForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >1. I am afraid of people in authority.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q1">
              <mat-button-toggle value="Not At All"
                >Not At All</mat-button-toggle
              >
              <mat-button-toggle value="A Little Bit"
                >A Little Bit</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Very Much">Very Much</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >2. I am bothered by blushing in front of people.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q2">
              <mat-button-toggle value="Not At All"
                >Not At All</mat-button-toggle
              >
              <mat-button-toggle value="A Little Bit"
                >A Little Bit</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Very Much">Very Much</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >3. Parties and social events scare me.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q3">
              <mat-button-toggle value="Not At All"
                >Not At All</mat-button-toggle
              >
              <mat-button-toggle value="A Little Bit"
                >A Little Bit</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Very Much">Very Much</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >4. I avoid talking to people I don't know.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q4">
              <mat-button-toggle value="Not At All"
                >Not At All</mat-button-toggle
              >
              <mat-button-toggle value="A Little Bit"
                >A Little Bit</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Very Much">Very Much</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >5. Being criticized scares me a lot.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q5">
              <mat-button-toggle value="Not At All"
                >Not At All</mat-button-toggle
              >
              <mat-button-toggle value="A Little Bit"
                >A Little Bit</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Very Much">Very Much</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >6. I avoid doing things or speaking to people for fear of
            embarrassment.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q6">
              <mat-button-toggle value="Not At All"
                >Not At All</mat-button-toggle
              >
              <mat-button-toggle value="A Little Bit"
                >A Little Bit</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Very Much">Very Much</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >7. Sweating in front of people causes me distress.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q7">
              <mat-button-toggle value="Not At All"
                >Not At All</mat-button-toggle
              >
              <mat-button-toggle value="A Little Bit"
                >A Little Bit</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Very Much">Very Much</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >8. I avoid going to parties.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q8">
              <mat-button-toggle value="Not At All"
                >Not At All</mat-button-toggle
              >
              <mat-button-toggle value="A Little Bit"
                >A Little Bit</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Very Much">Very Much</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >9. I avoid activities in which I am the center of attention.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q9">
              <mat-button-toggle value="Not At All"
                >Not At All</mat-button-toggle
              >
              <mat-button-toggle value="A Little Bit"
                >A Little Bit</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Very Much">Very Much</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >10. Talking to strangers scares me.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q10">
              <mat-button-toggle value="Not At All"
                >Not At All</mat-button-toggle
              >
              <mat-button-toggle value="A Little Bit"
                >A Little Bit</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Very Much">Very Much</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >11. I avoid having to give speeches.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q11">
              <mat-button-toggle value="Not At All"
                >Not At All</mat-button-toggle
              >
              <mat-button-toggle value="A Little Bit"
                >A Little Bit</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Very Much">Very Much</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >12. I would do anything to avoid being criticized.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q12">
              <mat-button-toggle value="Not At All"
                >Not At All</mat-button-toggle
              >
              <mat-button-toggle value="A Little Bit"
                >A Little Bit</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Very Much">Very Much</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >13. Heart palpitations bother me when I am around people.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q13">
              <mat-button-toggle value="Not At All"
                >Not At All</mat-button-toggle
              >
              <mat-button-toggle value="A Little Bit"
                >A Little Bit</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Very Much">Very Much</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >14. I am afraid of doing things when people might be
            watching.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q14">
              <mat-button-toggle value="Not At All"
                >Not At All</mat-button-toggle
              >
              <mat-button-toggle value="A Little Bit"
                >A Little Bit</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Very Much">Very Much</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >15. Being embarrassed or looking stupid are among my worst
            fears.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q15">
              <mat-button-toggle value="Not At All"
                >Not At All</mat-button-toggle
              >
              <mat-button-toggle value="A Little Bit"
                >A Little Bit</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Very Much">Very Much</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >16. I avoid speaking to anyone in authority.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q16">
              <mat-button-toggle value="Not At All"
                >Not At All</mat-button-toggle
              >
              <mat-button-toggle value="A Little Bit"
                >A Little Bit</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Very Much">Very Much</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >17. Trembling or shaking in front of others is distressing to
            me.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q17">
              <mat-button-toggle value="Not At All"
                >Not At All</mat-button-toggle
              >
              <mat-button-toggle value="A Little Bit"
                >A Little Bit</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Very Much">Very Much</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          socialPhobiaForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!socialPhobiaForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
