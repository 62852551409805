import { NgModule } from '@angular/core';
import { BowelPipe } from './bowel.pipe';
import { CamelCaseToSpacesPipe } from './camel-case-to-spaces.pipe';
import { DynamicFilterPipe } from './dynamic-filter.pipe';
import { EligibilityDataPipe } from './eligibility-data.pipe';
import { Hours } from './hours.pipe';
import { Hours24to12 } from './hours24to12.pipe';
import { PhoneFormatPipe } from './phone-format.pipe';
import { ReplacePipe } from './replace-pipe';
import { SanitizeUrlPipe } from './sanitizeurl.pipe';
import { yyyymmddToUsFormatPipe } from './yyyymmdd-to-us-format';


@NgModule({
  imports: [],
  declarations: [PhoneFormatPipe, SanitizeUrlPipe, DynamicFilterPipe, ReplacePipe, Hours24to12, Hours, BowelPipe, CamelCaseToSpacesPipe, yyyymmddToUsFormatPipe, EligibilityDataPipe],
  exports: [PhoneFormatPipe, SanitizeUrlPipe, DynamicFilterPipe, ReplacePipe, Hours24to12, Hours, BowelPipe, CamelCaseToSpacesPipe, yyyymmddToUsFormatPipe, EligibilityDataPipe]
})
export class PipesModule { } 
