import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Editor, schema, toDoc, toHTML, Toolbar } from 'ngx-editor';
import { ITherapySession } from 'src/app/_models';
import {
  DateTimeZoneService,
  TherapySessionService,
  ToastMessageService,
} from 'src/app/_services';
import { USERSETTINGSPROPS } from 'src/app/shared/constants/userSettings';
import { PermissionsSandbox } from 'src/app/shared/sandbox/permissions.sandbox';
import { UserSettingsPropsSandbox } from 'src/app/shared/sandbox/user-settings-props.sandbox';
import { LoadExistingClinicalNoteComponent } from '../../sessions/session-notes/load-existing-clinical-note/load-existing-clinical-note.component';
import { GroupMemberVitalsComponent } from '../group-member-vitals/group-member-vitals.component';
import { GroupMemberDiagnosisComponent } from '../group-member-diagnosis/group-member-diagnosis.component';
import { GroupMemberMedicationsComponent } from '../group-member-medications/group-member-medications.component';
import { GroupSessionAssessmentComponent } from '../group-session-assessment/group-session-assessment.component';
import { GroupMemberGoalsComponent } from '../group-member-goals/group-member-goals.component';
import { SessionGeneralNotesComponent } from '../../sessions/session-general-notes/session-general-notes.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { GroupMemberCollectPaymentsComponent } from '../group-member-collect-payments/group-member-collect-payments.component';

@Component({
  selector: 'app-group-member-level-notes',
  templateUrl: './group-member-level-notes.component.html',
  styleUrl: './group-member-level-notes.component.css',
})
export class GroupMemberLevelNotesComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Input() sessionDetails: ITherapySession;
  @Input() selectedMemberId: string;
  memberNotes: any;
  updatedSessionDetailsResponse: any;
  @Output() refreshTherapyObject = new EventEmitter<any>();
  // Permissions Load
  @Input() hasGoalsViewAccess: boolean;
  @Input() hasGoalsAddAccess: boolean;
  @Input() hasGoalsEditAccess: boolean;
  @Input() hasGoalsDeleteAccess: boolean;
  // Member Diagnosis
  @Input() hasDiagnosisViewAccess: boolean;
  @Input() hasDiagnosisAddAccess: boolean;
  @Input() hasDiagnosisEditAccess: boolean;
  @Input() hasDiagnosisDeleteAccess: boolean;
  // Member Medications
  @Input() hasMedicationViewAccess: boolean;
  @Input() hasMedicationAddAccess: boolean;
  @Input() hasMedicationEditAccess: boolean;
  @Input() hasMedicationDeleteAccess: boolean;
  // Assessments
  @Input() hasMemberAssessmentViewAccess: boolean;
  @Input() hasMemberAssessmentAddAccess: boolean;
  @Input() hasMemberAssessmentEditAccess: boolean;
  @Input() hasMemberAssessmentDeleteAccess: boolean;
  // Assessments Charts
  @Input() hasAssessmentChartsViewAccess: boolean;
  // Assessments Guidance
  @Input() hasAssessmentGuidanceViewAccess: boolean;
  @Input() hasAssessmentGuidanceEditAccess: boolean;
  // Assessments Summary
  @Input() hasAssessmentSummaryViewAccess: boolean;
  @Input() hasAssessmentSummaryEditAccess: boolean;
  // Assessments Take
  @Input() hasAssessmentTakeAddAccess: boolean;
  // Payments
  @Input() hasMemberPaymentsAddAccess: boolean;
  // Org config
  @Input() orgConfig: any;

  isLoadingResults: boolean = false;
  apiError: boolean = false;
  processing: boolean = false;

  currentTimezone: string = '';

  // Sandbox and font size
  sandboxFavList: any = [];
  editorFontSize: number = 14;

  //NGX Editor
  clinicalNoteForm: FormGroup;
  editor: Editor[] = [];
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['align_left', 'align_center', 'align_right'],
    ['horizontal_rule'],
  ];
  isLoadingNGXEditors: boolean = false;
  selectedSectionId: string;
  selectedSectionIndex: number;
  selectedSectionName: string;

  // Shortcuts opener
  activeTab: string = 'Text Shortcuts';
  hasChanges: boolean = false;
  autosaved: boolean = false;
  tabPanelOpen: boolean = false;
  hasChangesMap: { [key: string]: boolean } = {};
  hadChangesEarlier: { [key: string]: boolean } = {};
  changeTimeout: any;

  // Side Bar Items
  hover = false;
  sideBarMenuItems = [
    {
      icon: 'chat',
      name: 'General Notes',
    },
    {
      icon: 'emergency',
      name: 'Vitals',
    },
  ];

  // All Group member notes Config for this member note
  groupSectionNotesMap = new Map<string, any>();
  memberGroupLevelNoteConfig = new Map<string, boolean>();

  constructor(
    public router: Router,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private dateTimeZoneService: DateTimeZoneService,
    public therapysessionApiService: TherapySessionService,
    protected permissionsSandbox: PermissionsSandbox,
    private userSettingsPropsSandbox: UserSettingsPropsSandbox,
    private toastMessageService: ToastMessageService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit(): void {
    // Subcribe to user settings for font size
    this.userSettingsPropsSandbox.userSettingsProps$.subscribe(
      (userSettings) => {
        if (userSettings) {
          if (userSettings[USERSETTINGSPROPS.CLINICAL_NOTE_FONT]) {
            this.editorFontSize = Number(
              userSettings[USERSETTINGSPROPS.CLINICAL_NOTE_FONT]
            );
            // Re trigger font size
            this.handleFontSizeChange('Re-Trigger');
          }
        }
      }
    );

    this.buildSideBarMenu();
  }

  buildSideBarMenu() {
    this.sideBarMenuItems = [
      {
        icon: 'chat',
        name: 'General Notes',
      },
      {
        icon: 'emergency',
        name: 'Vitals',
      },
    ];
    // If diagnosis view permission is provided
    if (this.hasDiagnosisViewAccess) {
      this.sideBarMenuItems.push({
        icon: 'monitor_heart',
        name: 'Diagnosis',
      });
    }
    // If diagnosis view permission is provided
    if (this.hasMedicationViewAccess) {
      this.sideBarMenuItems.push({
        icon: 'medication',
        name: 'Medications',
      });
    }
    // If assessment view permission exits
    if (this.hasMemberAssessmentViewAccess) {
      this.sideBarMenuItems.push({
        icon: 'rate_review',
        name: 'Assessments',
      });
    }
    // If goals view permission exits
    if (this.hasGoalsViewAccess) {
      this.sideBarMenuItems.push({
        icon: 'crisis_alert',
        name: 'Goals',
      });
    }

    // If Payments is available
    if (
      this.hasMemberPaymentsAddAccess &&
      this.orgConfig?.connectedAccountStatus === 'Setup_Complete'
    ) {
      this.sideBarMenuItems.push({
        icon: 'paid',
        name: 'Collect Payment',
      });
    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.sessionDetails || changes.selectedMemberId) {
      this.clinicalNoteForm = this.formBuilder.group({});
      if (this.updatedSessionDetailsResponse) {
        this.refreshTherapyObject.emit(this.updatedSessionDetailsResponse);
        setTimeout(() => {
          this.updatedSessionDetailsResponse = null;
        }, 400);
      }

      this.memberNotes = this.sessionDetails.groupMembers
        .filter((member) => member.member.id === this.selectedMemberId)
        .at(0);

      if (this.sessionDetails && this.sessionDetails?.clinicalNoteId) {
        // Load the ngx editors
        await this.destroyAllNGXEditors();
        // Now load new NGX Editor
        await this.loadNGXEditor();
        // Handle font size
        setTimeout(() => {
          this.handleFontSizeChange('Re-Trigger');
        }, 200);
      }

      // Clinical note disable component after the note is closed
      if (this.sessionDetails.status === 'Active') {
        this.clinicalNoteForm.enable();
      } else {
        this.clinicalNoteForm.disable();
      }
    }
  }

  async loadNGXEditor() {
    this.isLoadingNGXEditors = true;

    let filteredMember = this.sessionDetails.groupMembers
      .filter((member) => member.member.id === this.selectedMemberId)
      .at(0);

    await filteredMember?.sessionNotes?.sections.forEach((section, i) => {
      this.editor.push(new Editor());
      // Now check if this section already has data
      if (section.payload) {
        let ngxEditorData = section.payload;
        let htmlNGXData = toHTML(ngxEditorData);
        // Add form control with existing value
        this.clinicalNoteForm.addControl(
          section.id,
          this.formBuilder.control(htmlNGXData, Validators.required)
        );
      } else {
        // Add a empty form control
        this.clinicalNoteForm.addControl(
          section.id,
          this.formBuilder.control('', Validators.required)
        );
      }

      this.isLoadingNGXEditors = false;
    });

    // If the config exists, then populate the new config
    if (filteredMember.groupLevelNoteConfig) {
      this.memberGroupLevelNoteConfig = new Map<string, boolean>();
      let keys = Object.keys(filteredMember.groupLevelNoteConfig);
      keys.forEach((key) => {
        this.memberGroupLevelNoteConfig.set(
          key,
          filteredMember.groupLevelNoteConfig[key]
        );
      });
    } else {
      this.memberGroupLevelNoteConfig = new Map<string, boolean>();
    }

    // Also load all the group member notes
    if (
      this.sessionDetails.clinicalNoteId &&
      this.sessionDetails.sessionNotes &&
      this.sessionDetails.sessionNotes.sections
    ) {
      this.sessionDetails.sessionNotes.sections.forEach((section) => {
        if (section.payload) {
          this.groupSectionNotesMap.set(section.id, toHTML(section.payload));
        }
      });
    }

    // Retrigger font -size
    this.handleFontSizeChange('Re-Trigger');
  }

  // Condition to handle group note include or note
  isIncludeGroupNoteChecked(sectionId: string): boolean {
    const value = this.memberGroupLevelNoteConfig.get(sectionId);
    return value !== undefined ? value : true;
  }

  ngOnDestroy(): void {
    this.destroyAllNGXEditors();
    if (this.updatedSessionDetailsResponse) {
      this.refreshTherapyObject.emit(this.updatedSessionDetailsResponse);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.handleFontSizeChange('Re-Trigger');
    }, 400);
  }

  // Change Editor click section
  editorClicked(event, sectionId, sectionName, index) {
    this.selectedSectionId = sectionId;
    this.selectedSectionName = sectionName;
    this.selectedSectionIndex = index;
  }

  //  Side Panel For Shortcuts and Templates
  openTabPanel(
    type: string,
    sectionId: string,
    sectionName: string,
    index: number
  ) {
    this.tabPanelOpen = true;
    this.selectedSectionId = sectionId;
    this.selectedSectionName = sectionName;
    this.selectedSectionIndex = index;
    this.activeTab = type;
  }

  closePanel() {
    this.tabPanelOpen = false;
    this.selectedSectionId = null;
    this.selectedSectionName = null;
    this.selectedSectionIndex = null;
    this.activeTab = null;
  }

  handleShortCutResponse(shortcut) {
    var currentValue =
      this.clinicalNoteForm.controls[this.selectedSectionId].value;

    // If there is existing value add these in a new line
    if (currentValue) {
      this.clinicalNoteForm.controls[this.selectedSectionId].setValue(
        currentValue + '<p/>' + toHTML(shortcut.content)
      );
    } else {
      this.clinicalNoteForm.controls[this.selectedSectionId].setValue(
        toHTML(shortcut.content)
      );
    }
  }

  handleTemplateResponse(response) {
    var currentValue =
      this.clinicalNoteForm.controls[this.selectedSectionId].value;

    // If there is existing value add these in a new line
    if (currentValue) {
      this.clinicalNoteForm.controls[this.selectedSectionId].setValue(
        currentValue + '<p/>' + response
      );
    } else {
      this.clinicalNoteForm.controls[this.selectedSectionId].setValue(response);
    }
  }

  async destroyAllNGXEditors() {
    this.clinicalNoteForm.reset();
    // Detroy all ngx editor and its contents
    if (this.editor) {
      this.editor.forEach((editor) => {
        editor.destroy();
      });
      // Reset editor to be empty
      this.editor = [];
    }
  }

  // Handle font size
  // Handling of font size for ngx editor
  async handleFontSizeChange(type) {
    const editorContents = document.querySelectorAll(
      '.NgxEditor__Content'
    ) as NodeListOf<HTMLElement>;
    // Loop through all the selected elements and apply the changes
    // Adjust font size based on the action
    if (type === 'increase') {
      this.editorFontSize += 1;
    } else if (type === 'decrease') {
      this.editorFontSize -= 1;
    }
    // Optionally, keep the font size within reasonable limits
    if (this.editorFontSize < 10) {
      this.editorFontSize = 10; // Min size
    } else if (this.editorFontSize > 30) {
      this.editorFontSize = 30; // Max size
    }
    // Dispatch the even to store this font size
    if (type != 'Re-Trigger') {
      this.userSettingsPropsSandbox.handleUserSettingsPropsChange(
        USERSETTINGSPROPS.CLINICAL_NOTE_FONT,
        this.editorFontSize.toString()
      );
    }
    editorContents.forEach((editorContent) => {
      // Apply the new font size to each element
      editorContent.style.fontSize = `${this.editorFontSize}px`;
    });
  }

  // ngModelChange handler to capture changes from the editor
  onEditorChange(event: any, sectionId: string): void {
    // Set the hasChanges flag to true when there are changes
    this.hasChangesMap[sectionId] = true;
    this.autosaved = false;
    this.hasChanges = true;

    // Clear any previous timeouts and reset the timeout to 5 seconds
    if (this.changeTimeout) {
      clearTimeout(this.changeTimeout);
    }

    // Set a timeout to trigger the API call after 5 seconds of inactivity
    this.changeTimeout = setTimeout(() => {
      if (this.hasChangesMap[sectionId]) {
        this.hasChangesMap[sectionId] = false; // Reset the flag
        this.hadChangesEarlier[sectionId] = true;
        this.saveNotes(); // Call API after 5 seconds
      }
    }, 500);
  }

  saveNotes() {
    // For each section, generating the payload to save
    let payload: any = [];

    this.sessionDetails.sessionNotes.sections.forEach((section, i) => {
      let jsonDoc = toDoc(
        this.clinicalNoteForm.controls[section.id].value,
        schema
      );
      payload.push({
        id: section.id,
        name: section.name,
        payload: jsonDoc,
      });
    });

    this.hasChanges = true;
    this.autosaved = false;
    this.therapysessionApiService
      .updateGroupMemberLevelNote(
        this.sessionDetails.id,
        this.selectedMemberId,
        { sections: payload }
      )
      .subscribe({
        next: (response) => {
          this.autosaved = true;
          this.hasChanges = false;
          this.updatedSessionDetailsResponse = response;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to update the clinical notes.'
          );
          this.autosaved = false;
          this.hasChanges = false;
        },
      });
  }

  // Load Data from the last Note
  loadLastNote() {
    let dialogRef = this.dialog.open(LoadExistingClinicalNoteComponent, {
      data: {
        therapySession: this.sessionDetails,
        groupAppointment: true,
        selectedMemberId: this.selectedMemberId,
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '65vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response.type === 'success') {
        // Now we need to load this note to existing form controls in ngx editor
        if (response.sessionNotes) {
          response.sessionNotes.sections.forEach((section) => {
            // Now check if this section already has data
            if (section.payload) {
              let ngxEditorData = section.payload;
              let htmlNGXData = toHTML(ngxEditorData);
              // Add form control with existing value if the section exists
              if (this.clinicalNoteForm.controls[section.id]) {
                this.clinicalNoteForm.controls[section.id].setValue(
                  htmlNGXData
                );
              }
            }
          });
        }
      }
    });
  }

  // Handle side menu clicks
  sideMenuClicked(menu) {
    // General Notes
    if (menu.name === 'General Notes') {
      let dialogRef = this.dialog.open(SessionGeneralNotesComponent, {
        data: {
          therapySession: this.sessionDetails,
          selectedMemberId: this.selectedMemberId,
          hasEditAccess: true,
          existingGroupMemberNote: this.memberNotes.sessionLevelNote,
        },
        disableClose: true,
        autoFocus: false,
        minWidth: '35vw',
      });

      dialogRef.afterClosed().subscribe((response) => {
        if (response && response.type === 'success') {
          this.updatedSessionDetailsResponse = response.res;
          // Emit the changes, to update the object
          this.refreshTherapyObject.emit(this.updatedSessionDetailsResponse);
        }
      });
    }
    // Open up vitals container
    else if (menu.name === 'Vitals') {
      this.dialog.open(GroupMemberVitalsComponent, {
        data: {
          sessionDetails: this.sessionDetails,
          selectedMemberId: this.selectedMemberId,
          patient: this.memberNotes.member,
        },
        autoFocus: false,
        disableClose: true,
        minWidth: '45vw',
      });
    }
    // For Diagnosis
    else if (menu.name === 'Diagnosis') {
      let dialogRef = this.dialog.open(GroupMemberDiagnosisComponent, {
        data: {
          sessionDetails: this.sessionDetails,
          selectedMemberId: this.selectedMemberId,
          patient: this.memberNotes.member,
          hasDiagnosisAddAccess: this.hasDiagnosisAddAccess,
          hasDiagnosisEditAccess: this.hasDiagnosisEditAccess,
          hasDiagnosisDeleteAccess: this.hasDiagnosisDeleteAccess,
        },
        autoFocus: false,
        disableClose: true,
        minWidth: '45vw',
      });

      dialogRef.afterClosed().subscribe((response) => {
        if (response && response.type === 'success') {
          this.updatedSessionDetailsResponse = response.res;
          this.refreshTherapyObject.emit(this.updatedSessionDetailsResponse);
        }
      });
    }
    // For Diagnosis
    else if (menu.name === 'Medications') {
      this.dialog.open(GroupMemberMedicationsComponent, {
        data: {
          sessionDetails: this.sessionDetails,
          selectedMemberId: this.selectedMemberId,
          patient: this.memberNotes.member,
          hasMedicationAddAccess: this.hasMedicationAddAccess,
          hasMedicationEditAccess: this.hasMedicationEditAccess,
          hasMedicationDeleteAccess: this.hasMedicationDeleteAccess,
        },
        autoFocus: false,
        disableClose: true,
        minWidth: '45vw',
      });
    }
    // For Assessments
    else if (menu.name === 'Assessments') {
      this.dialog.open(GroupSessionAssessmentComponent, {
        data: {
          sessionDetails: this.sessionDetails,
          selectedMemberId: this.selectedMemberId,
          patient: this.memberNotes.member,
          hasMemberAssessmentAddAccess: this.hasMemberAssessmentAddAccess,
          hasMemberAssessmentEditAccess: this.hasMemberAssessmentEditAccess,
          hasMemberAssessmentDeleteAccess: this.hasMemberAssessmentDeleteAccess,
          hasAssessmentChartsViewAccess: this.hasAssessmentChartsViewAccess,
          hasAssessmentGuidanceViewAccess: this.hasAssessmentGuidanceViewAccess,
          hasAssessmentGuidanceEditAccess: this.hasAssessmentGuidanceEditAccess,
          hasAssessmentSummaryViewAccess: this.hasAssessmentSummaryViewAccess,
          hasAssessmentSummaryEditAccess: this.hasAssessmentSummaryEditAccess,
          hasAssessmentTakeAddAccess: this.hasAssessmentTakeAddAccess,
        },
        autoFocus: false,
        disableClose: true,
        minWidth: '55vw',
      });
    }
    // For Goals
    else if (menu.name === 'Goals') {
      this.dialog.open(GroupMemberGoalsComponent, {
        data: {
          sessionDetails: this.sessionDetails,
          selectedMemberId: this.selectedMemberId,
          patient: this.memberNotes.member,
          hasGoalsAddAccess: this.hasGoalsAddAccess,
          hasGoalsEditAccess: this.hasGoalsEditAccess,
          hasGoalsDeleteAccess: this.hasGoalsDeleteAccess,
        },
        autoFocus: false,
        disableClose: true,
        minWidth: '55vw',
      });
    }
    // For Payments
    else if (menu.name === 'Collect Payment') {
      this.dialog.open(GroupMemberCollectPaymentsComponent, {
        data: {
          sessionDetails: this.sessionDetails,
          selectedMemberId: this.selectedMemberId,
          patient: this.memberNotes.member,
        },
        autoFocus: false,
        disableClose: true,
        minWidth: '75vw',
      });
      // Opening this payment collection in a new tab
      // const url = this.router.serializeUrl(
      //   this.router.createUrlTree(
      //     ['main/collectmemberpayment', this.selectedMemberId],
      //     {
      //       queryParams: {
      //         sessionId: this.sessionDetails.id,
      //         parent: 'Group Note',
      //       },
      //     }
      //   )
      // );

      // window.open(url, '_blank');
    }
  }

  // Group Session Section config changed
  groupSessionSectionConfigChanged(
    event: MatCheckboxChange,
    sectionId: string
  ) {
    this.therapysessionApiService
      .updateGroupMemberGroupNoteConfig(
        this.sessionDetails.id,
        this.selectedMemberId,
        sectionId,
        event.checked
      )
      .subscribe({
        next: (response) => {
          this.updatedSessionDetailsResponse = response;
          // Emit the changes, to update the object
          this.refreshTherapyObject.emit(response);
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to save the print settings'
          );
        },
      });
  }
}
