<div class="wrapper" *ngIf="isLoggedIn && !isGuestUrl">
  <div class="sidebar" data-color="white" data-background-color="sidebarbg"
    *ngIf="!isFacilityDevice && !isMobileWebView && !isSSOView">
    <app-sidebar-cmp></app-sidebar-cmp>
  </div>

  <app-nav-bar *ngIf="isLoggedIn && !isFacilityDevice && !isMobileWebView && !isSSOView"></app-nav-bar>

  <!-- Regular Browser Flow -->
  <div class="main-panel" style="padding-bottom: 80px" *ngIf="!isMobileWebView && !isSSOView">
    <div class="main-content">
      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

  <!-- SSO/Mobile app Flow -->
  <div *ngIf="isMobileWebView || isSSOView" style="margin-top: 20px">
    <div class="main-content">
      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<div class="wrapper" *ngIf="isGuestUrl">
  <div class="main-content">
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<div class="wrapper" *ngIf="!isLoggedIn && !isGuestUrl">
  <mat-spinner style="margin: auto"></mat-spinner>
</div>