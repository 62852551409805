<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Differentiation of Self Inventory - Short Form</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="dosiAssessmentForm"
    novalidate
    (ngSubmit)="onDosiFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >1. I tend to remain pretty calm even under stress.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group vertical formControlName="calmUnderStress">
              <mat-button-toggle value="Not Characteristic of Me"
                >Not Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Characteristic of Me"
                >Slightly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Characteristic of Me"
                >Somewhat Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately Characteristic of Me"
                >Moderately Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Highly Characteristic of Me"
                >Highly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Very Characteristic of Me"
                >Very Characteristic of Me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >2. I usually need a lot of encouragement from others when starting
            a big job or task.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              vertical
              formControlName="needsEncouragement"
            >
              <mat-button-toggle value="Not Characteristic of Me"
                >Not Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Characteristic of Me"
                >Slightly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Characteristic of Me"
                >Somewhat Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately Characteristic of Me"
                >Moderately Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Highly Characteristic of Me"
                >Highly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Very Characteristic of Me"
                >Very Characteristic of Me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >3. No matter what happens in my life I know that I'll never lose my
            sense of who I am.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              vertical
              formControlName="strongSelfIdentity"
            >
              <mat-button-toggle value="Not Characteristic of Me"
                >Not Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Characteristic of Me"
                >Slightly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Characteristic of Me"
                >Somewhat Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately Characteristic of Me"
                >Moderately Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Highly Characteristic of Me"
                >Highly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Very Characteristic of Me"
                >Very Characteristic of Me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >4. I tend to distance myself when people get too close to
            me.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              vertical
              formControlName="distanceWhenClose"
            >
              <mat-button-toggle value="Not Characteristic of Me"
                >Not Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Characteristic of Me"
                >Slightly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Characteristic of Me"
                >Somewhat Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately Characteristic of Me"
                >Moderately Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Highly Characteristic of Me"
                >Highly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Very Characteristic of Me"
                >Very Characteristic of Me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >5. When my spouse/partner criticizes me it bothers me for
            days.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              vertical
              formControlName="botheredByCriticism"
            >
              <mat-button-toggle value="Not Characteristic of Me"
                >Not Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Characteristic of Me"
                >Slightly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Characteristic of Me"
                >Somewhat Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately Characteristic of Me"
                >Moderately Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Highly Characteristic of Me"
                >Highly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Very Characteristic of Me"
                >Very Characteristic of Me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >6. At times my feelings get the best of me and I have trouble
            thinking clearly.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              vertical
              formControlName="emotionsCloudThinking"
            >
              <mat-button-toggle value="Not Characteristic of Me"
                >Not Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Characteristic of Me"
                >Slightly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Characteristic of Me"
                >Somewhat Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately Characteristic of Me"
                >Moderately Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Highly Characteristic of Me"
                >Highly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Very Characteristic of Me"
                >Very Characteristic of Me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >7. I'm often uncomfortable when people get too close to me.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              vertical
              formControlName="uncomfortableWhenClose"
            >
              <mat-button-toggle value="Not Characteristic of Me"
                >Not Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Characteristic of Me"
                >Slightly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Characteristic of Me"
                >Somewhat Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately Characteristic of Me"
                >Moderately Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Highly Characteristic of Me"
                >Highly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Very Characteristic of Me"
                >Very Characteristic of Me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >8. I feel a need for approval from virtually everyone in my
            life.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group vertical formControlName="approvalNeed">
              <mat-button-toggle value="Not Characteristic of Me"
                >Not Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Characteristic of Me"
                >Slightly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Characteristic of Me"
                >Somewhat Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately Characteristic of Me"
                >Moderately Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Highly Characteristic of Me"
                >Highly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Very Characteristic of Me"
                >Very Characteristic of Me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >9. At times I feel as if I'm riding an emotional
            roller-coaster.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              vertical
              formControlName="emotionalRollerCoaster"
            >
              <mat-button-toggle value="Not Characteristic of Me"
                >Not Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Characteristic of Me"
                >Slightly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Characteristic of Me"
                >Somewhat Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately Characteristic of Me"
                >Moderately Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Highly Characteristic of Me"
                >Highly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Very Characteristic of Me"
                >Very Characteristic of Me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >10. There's no point in getting upset about things I cannot
            change.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              vertical
              formControlName="unfazedByUnchangeables"
            >
              <mat-button-toggle value="Not Characteristic of Me"
                >Not Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Characteristic of Me"
                >Slightly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Characteristic of Me"
                >Somewhat Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately Characteristic of Me"
                >Moderately Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Highly Characteristic of Me"
                >Highly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Very Characteristic of Me"
                >Very Characteristic of Me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >11. I'm overly sensitive to criticism.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              vertical
              formControlName="sensitiveToCriticism"
            >
              <mat-button-toggle value="Not Characteristic of Me"
                >Not Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Characteristic of Me"
                >Slightly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Characteristic of Me"
                >Somewhat Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately Characteristic of Me"
                >Moderately Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Highly Characteristic of Me"
                >Highly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Very Characteristic of Me"
                >Very Characteristic of Me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >12. I'm fairly self-accepting.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group vertical formControlName="selfAccepting">
              <mat-button-toggle value="Not Characteristic of Me"
                >Not Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Characteristic of Me"
                >Slightly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Characteristic of Me"
                >Somewhat Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately Characteristic of Me"
                >Moderately Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Highly Characteristic of Me"
                >Highly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Very Characteristic of Me"
                >Very Characteristic of Me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >13. I often agree with others just to appease them.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group vertical formControlName="appeaseOthers">
              <mat-button-toggle value="Not Characteristic of Me"
                >Not Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Characteristic of Me"
                >Slightly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Characteristic of Me"
                >Somewhat Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately Characteristic of Me"
                >Moderately Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Highly Characteristic of Me"
                >Highly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Very Characteristic of Me"
                >Very Characteristic of Me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >14. If I have had an argument with my spouse/partner I tend to
            think about it all day.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              vertical
              formControlName="dwellsOnArguments"
            >
              <mat-button-toggle value="Not Characteristic of Me"
                >Not Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Characteristic of Me"
                >Slightly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Characteristic of Me"
                >Somewhat Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately Characteristic of Me"
                >Moderately Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Highly Characteristic of Me"
                >Highly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Very Characteristic of Me"
                >Very Characteristic of Me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >15. When one of my relationships becomes very intense I feel the
            urge to run away from it.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              vertical
              formControlName="runFromIntensity"
            >
              <mat-button-toggle value="Not Characteristic of Me"
                >Not Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Characteristic of Me"
                >Slightly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Characteristic of Me"
                >Somewhat Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately Characteristic of Me"
                >Moderately Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Highly Characteristic of Me"
                >Highly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Very Characteristic of Me"
                >Very Characteristic of Me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >16. If someone is upset with me I can't seem to let it go
            easily.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group vertical formControlName="holdsOntoUpset">
              <mat-button-toggle value="Not Characteristic of Me"
                >Not Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Characteristic of Me"
                >Slightly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Characteristic of Me"
                >Somewhat Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately Characteristic of Me"
                >Moderately Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Highly Characteristic of Me"
                >Highly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Very Characteristic of Me"
                >Very Characteristic of Me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >17. I often feel unsure when others are not around to help me make
            a decision.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group vertical formControlName="unsureAlone">
              <mat-button-toggle value="Not Characteristic of Me"
                >Not Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Characteristic of Me"
                >Slightly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Characteristic of Me"
                >Somewhat Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately Characteristic of Me"
                >Moderately Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Highly Characteristic of Me"
                >Highly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Very Characteristic of Me"
                >Very Characteristic of Me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >18. I'm very sensitive to being hurt by others.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group vertical formControlName="hurtSensitivity">
              <mat-button-toggle value="Not Characteristic of Me"
                >Not Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Characteristic of Me"
                >Slightly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Characteristic of Me"
                >Somewhat Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately Characteristic of Me"
                >Moderately Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Highly Characteristic of Me"
                >Highly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Very Characteristic of Me"
                >Very Characteristic of Me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >19. My self-esteem really depends on how others think of me.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              vertical
              formControlName="esteemDependsOnOthers"
            >
              <mat-button-toggle value="Not Characteristic of Me"
                >Not Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Characteristic of Me"
                >Slightly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Characteristic of Me"
                >Somewhat Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately Characteristic of Me"
                >Moderately Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Highly Characteristic of Me"
                >Highly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Very Characteristic of Me"
                >Very Characteristic of Me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >20. I tend to feel pretty stable under stress.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              vertical
              formControlName="stableUnderStress"
            >
              <mat-button-toggle value="Not Characteristic of Me"
                >Not Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Characteristic of Me"
                >Slightly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Characteristic of Me"
                >Somewhat Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Moderately Characteristic of Me"
                >Moderately Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Highly Characteristic of Me"
                >Highly Characteristic of Me</mat-button-toggle
              >
              <mat-button-toggle value="Very Characteristic of Me"
                >Very Characteristic of Me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          dosiAssessmentForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!dosiAssessmentForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
