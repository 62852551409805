<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle *ngIf="action === 'ADD'">Add Alert</p>
    <p matDialogTitle *ngIf="action === 'EDIT'">Edit Alert</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div>
    <mat-stepper
      linear
      #stepper
      *ngIf="assessmentDetails && assessmentDetails.length > 0"
      style="width: 100%"
    >
      <!-- Select question first -->
      <mat-step [stepControl]="selectedQuestion">
        <div class="col-md-12" style="text-align: center">
          <label class="labelText" *ngIf="action === 'ADD'">
            Please select a question <span style="color: red">*</span>
          </label>
          <label class="labelText" *ngIf="action === 'EDIT'">
            Selected Question
          </label>
          <mat-form-field appearance="outline">
            <mat-select
              [(ngModel)]="selectedQuestion"
              [disabled]="action === 'EDIT'"
            >
              <mat-option
                *ngFor="let question of assessmentDetails; let i = index"
                [value]="i"
                style="font-size: 12px"
              >
                {{ question.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div style="text-align: right">
          <button
            mat-button
            matStepperNext
            [disabled]="!(selectedQuestion >= 0)"
          >
            Next
          </button>
        </div>
      </mat-step>
      <!-- Select answers  -->
      <mat-step [stepControl]="selectedAnswer">
        <div class="col-md-12" style="text-align: center">
          <label class="labelText">
            Please select the response <span style="color: red">*</span>
          </label>
          <mat-form-field appearance="outline" *ngIf="selectedQuestion >= 0">
            <mat-select [(ngModel)]="selectedAnswer" multiple>
              <mat-option
                *ngFor="
                  let answer of assessmentDetails[selectedQuestion].answers;
                  let j = index
                "
                [value]="answer.value"
                style="font-size: 12px"
              >
                {{ answer.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div style="display: flex; justify-content: space-between">
          <button mat-raised-button matStepperPrevious>Back</button>
          <button
            mat-button
            matStepperNext
            [disabled]="!(selectedAnswer.length > 0)"
          >
            Next
          </button>
        </div>
      </mat-step>
      <!-- Now Describe the alert  -->
      <mat-step [stepControl]="assessmentAlertForm">
        <form [formGroup]="assessmentAlertForm">
          <div class="row">
            <div class="col-md-12">
              <label class="labelText">
                Message <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <textarea
                  matInput
                  placeholder="Description"
                  formControlName="message"
                  name="message"
                  rows="3"
                ></textarea>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <section class="checkbox-section">
                <mat-checkbox
                  color="primary"
                  class="checkbox-margin"
                  formControlName="smsAlerts"
                  >SMS Alert</mat-checkbox
                >
              </section>
            </div>
            <div class="col-md-6">
              <section class="checkbox-section">
                <mat-checkbox
                  color="primary"
                  class="checkbox-margin"
                  formControlName="emailAlerts"
                  >Email Alert</mat-checkbox
                >
              </section>
            </div>
            <div class="col-md-6">
              <label class="labelText">
                Status <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="status" placeholder="Status">
                  <mat-option value="Active">Active</mat-option>
                  <mat-option value="Inactive">Inactive</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </form>
        <div style="display: flex; justify-content: space-between">
          <button mat-raised-button matStepperPrevious>Back</button>
          <button
            mat-button
            matStepperNext
            [disabled]="assessmentAlertForm.invalid"
          >
            Next
          </button>
        </div>
      </mat-step>
      <!-- Summary -->
      <mat-step>
        <p style="text-align: center; font-weight: 600; font-size: 20px">
          Alert Overview
        </p>
        <div class="summaryGridContainer" *ngIf="assessmentAlertForm.valid">
          <label class="labelText">Question</label>
          <label>
            {{ assessmentDetails[selectedQuestion].title }}
          </label>

          <label class="labelText">Response(s)</label>
          <label>
            {{ selectedAnswer.toString() }}
          </label>

          <label class="labelText">Message</label>
          <label>
            {{ assessmentAlertForm.controls['message'].value }}
          </label>

          <label class="labelText">SMS Alerts</label>
          <label>
            <i
              class="fa fa-check"
              style="color: #78c000"
              aria-hidden="true"
              *ngIf="assessmentAlertForm.controls['smsAlerts'].value"
            ></i>
          </label>

          <label class="labelText">Email Alerts</label>
          <label>
            <i
              class="fa fa-check"
              style="color: #78c000"
              aria-hidden="true"
              *ngIf="assessmentAlertForm.controls['emailAlerts'].value"
            ></i>
          </label>
        </div>
        <div
          style="
            display: flex;
            justify-content: space-between;
            margin-top: 40px;
          "
        >
          <button mat-raised-button matStepperPrevious [disabled]="processing">
            Back
          </button>
          <button
            mat-raised-button
            color="primary"
            type="button"
            [disabled]="processing"
            (click)="submitForm()"
          >
            Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
          </button>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
</div>
