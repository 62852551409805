import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Patient } from 'src/app/_models/patient/patient';
import { ITherapySession } from 'src/app/_models/session/therapySession.model';
import { TherapySessionService } from 'src/app/_services/session/therapy-session.service';
import { ToastMessageService } from 'src/app/_services/toast-message.service';
import { CarelogDialogSelectorComponent } from 'src/app/pages/cases/care-activities/carelog-dialogs/carelog-dialog-selector/carelog-dialog-selector.component';

@Component({
  selector: 'app-session-vitals',
  templateUrl: './session-vitals.component.html',
  styleUrl: './session-vitals.component.css',
})
export class SessionVitalsComponent implements OnInit {
  @Input() patient: Patient;
  @Input() therapySession: ITherapySession;

  isProcessing = true;
  currentDate: Date;

  vitalInfo: any;

  constructor(
    public dialog: MatDialog,
    public therapysessionApiService: TherapySessionService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit(): void {
    this.currentDate = new Date();

    this.loadVitalsForSession();
  }

  public loadVitalsForSession() {
    this.isProcessing = true;

    this.therapysessionApiService
      .getMemberSessionVitals(this.patient.id, this.therapySession.id)
      .subscribe({
        next: (response) => {
          if (response && response.data) {
            this.vitalInfo = response.data;
          }
          this.isProcessing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to retrieve vitals information'
          );
          this.isProcessing = false;
        },
      });
  }

  captureVitals() {
    const dialogRef = this.dialog.open(CarelogDialogSelectorComponent, {
      data: {
        initialAnswer: 'Vitals',
        action: 'ADD',
        logDate: this.currentDate,
        patientId: this.patient.id,
        sessionId: this.therapySession.id,
      },
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadVitalsForSession();
      }
    });
  }

  editVitalData() {
    this.vitalInfo.vitalInfoAvailable = true;

    const dialogRef = this.dialog.open(CarelogDialogSelectorComponent, {
      data: {
        initialAnswer: 'Vitals',
        action: 'EDIT',
        logDate: this.currentDate,
        patientId: this.patient.id,
        sessionId: this.therapySession.id,
        carelog: this.vitalInfo,
      },
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadVitalsForSession();
      }
    });
  }
}
