import { Component, Input, OnInit } from '@angular/core';
import { Case } from 'src/app/_models';

@Component({
  selector: 'app-case-print',
  templateUrl: './case-print.component.html',
  styleUrls: ['./case-print.component.css']
})
export class CasePrintComponent implements OnInit {
  @Input() case: Case;

  slectedOption = null;

  constructor() { }

  ngOnInit(): void {

  }

  formList = [
    {
      title: 'Daily Reports',
      forms: [
        {
          reportName: 'Care Logs'
        }
      ]
    },
    {
      title: 'Monthly Reports',
      forms: [
        {
          reportName: 'Bowel Movement Report'
        },
        {
          reportName: 'Seizure Log Report'
        }
        ,
        {
          reportName: 'Sleep Log Report'
        }
      ]
    }
  ]

}
