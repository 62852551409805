import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ITherapySession, Patient } from 'src/app/_models';
import { IIntakeInsuranceInfo } from 'src/app/_models/session/intake/intakeinsuranceinfo.model';
import { TherapySessionService, ToastMessageService } from 'src/app/_services';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { IntakeSearchAddInsuranceComponent } from './intake-search-add-insurance/intake-search-add-insurance.component';

@Component({
  selector: 'app-intake-insurance-info',
  templateUrl: './intake-insurance-info.component.html',
  styleUrls: ['./intake-insurance-info.component.css'],
})
export class IntakeInsuranceInfoComponent implements OnInit {
  @Input() patient: Patient;
  @Input() therapySession: ITherapySession;
  @Input() hasBPSAIntakeViewAccess: boolean;
  @Input() hasBPSAIntakeAddAccess: boolean;
  @Input() hasBPSAIntakeEditAccess: boolean;

  @Output() updatedTherapySession = new EventEmitter<any>();

  public action: string;
  public intakeinsuranceinfoForm: FormGroup;
  processing: boolean = false;
  intakeinsuranceinfo: IIntakeInsuranceInfo;
  todaysDate = new Date();

  constructor(
    public dialog: MatDialog,
    public therapysessionApiService: TherapySessionService,
    private toastMessageService: ToastMessageService
  ) {}

  public ngOnInit() {
    this.intakeinsuranceinfo = this.therapySession.intakeInsuranceInfo;
  }

  searchInsurance(type) {
    let dialogRef = this.dialog.open(IntakeSearchAddInsuranceComponent, {
      data: {
        patient: this.patient,
        therapySession: this.therapySession,
        parentClass: 'Intake',
        type: type,
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '40vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        this.updatedTherapySession.emit(response.therpaySession);
      }
    });
  }

  removeInsurance(type) {
    const dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: { message: 'Are you sure you want to remove this insurance?' },
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        if (type === 'Primary') {
          this.therapySession.primaryInsuranceDetails = null;
        } else {
          this.therapySession.secondaryInsuranceDetails = null;
        }

        // Update the billing
        this.updateBilling();
      }
    });
  }

  secondaryInsuranceChaged(event) {
    this.therapySession.anotherHealthPlan = event.value;
    if (event.value === 'No') {
      this.therapySession.secondaryInsuranceDetails = null;

      // Update the billing
      this.updateBilling();
    }
  }

  updateBilling() {
    this.processing = true;

    this.therapysessionApiService
      .updateBillingInformation(this.therapySession.id, this.therapySession)
      .subscribe({
        next: (response) => {
          this.processing = false;
          this.updatedTherapySession.emit(response.data);
          // this.toastMessageService.displaySuccessMessage(
          //   'Successfully updated the insurance record.'
          // );
        },
        error: (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Unable to update the insurance record.'
          );
        },
      });
  }
}
