<div class="container">
  <div class="dialogTitle" *ngIf="displayTitle">
    <p matDialogTitle>Bowel Movement Info</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="bowelForm" (ngSubmit)="onBowelInfoSubmit()">
    <mat-stepper linear="true" #stepper>
      <mat-step [stepControl]="initial">
        <ng-template matStepLabel>Had Bowel?</ng-template>
        <br />
        <div class="row">
          <label class="labelText">
            Had Bowel during the shift? <span style="color: red">*</span>
          </label>
        </div>

        <div class="row">
          <div class="col-md-3" style="padding: 10px">
            <mat-card
              appearance="raised"
              (click)="selectInitialAnswer('Yes', stepper)"
              class="mat-elevation-z8 selectable-card"
            >
              <span class="selection" *ngIf="initialAnswer === 'Yes'">
                <mat-icon
                  class="primary-color-icon"
                  style="color: green; margin-top: 5px"
                  >check_circle</mat-icon
                >
              </span>

              <label class="labelText" style="font-size: 16px; cursor: pointer">
                Yes
              </label>
            </mat-card>
          </div>
          <div class="col-md-3" style="padding: 10px">
            <mat-card
              appearance="raised"
              (click)="selectInitialAnswer('No', stepper)"
              class="mat-elevation-z8 selectable-card"
            >
              <span class="selection" *ngIf="initialAnswer === 'No'">
                <mat-icon
                  class="primary-color-icon"
                  style="color: green; margin-top: 5px"
                  >check_circle</mat-icon
                >
              </span>
              <label class="labelText" style="font-size: 16px; cursor: pointer">
                No
              </label>
            </mat-card>
          </div>

          <div class="col-md-3" style="padding: 10px">
            <mat-card
              appearance="raised"
              (click)="selectInitialAnswer('Leave of Absence', stepper)"
              class="mat-elevation-z8 selectable-card"
            >
              <span
                class="selection"
                *ngIf="initialAnswer === 'Leave of Absence'"
              >
                <mat-icon
                  class="primary-color-icon"
                  style="color: green; margin-top: 5px"
                  >check_circle</mat-icon
                >
              </span>

              <label class="labelText" style="font-size: 16px; cursor: pointer">
                Leave of Absence
              </label>
            </mat-card>
          </div>

          <div class="col-md-3" style="padding: 10px">
            <mat-card
              appearance="raised"
              (click)="selectInitialAnswer('At School', stepper)"
              class="mat-elevation-z8 selectable-card"
            >
              <span class="selection" *ngIf="initialAnswer === 'At School'">
                <mat-icon
                  class="primary-color-icon"
                  style="color: green; margin-top: 5px"
                  >check_circle</mat-icon
                >
              </span>
              <label class="labelText" style="font-size: 16px; cursor: pointer">
                At School
              </label>
            </mat-card>
          </div>
        </div>
      </mat-step>

      <mat-step [stepControl]="details">
        <ng-template matStepLabel>Details / Confirm</ng-template>
        <div class="row">
          <div class="col-md-4" *ngIf="initialAnswer === 'Yes'">
            <label class="labelText">Size</label>
            <mat-form-field appearance="outline">
              <mat-select placeholder="Size" formControlName="size">
                <mat-option value="SM">Small</mat-option>
                <mat-option value="M">Medium</mat-option>
                <mat-option value="L">Large</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-4" *ngIf="initialAnswer === 'Yes'">
            <label class="labelText">Color</label>
            <mat-form-field appearance="outline">
              <mat-select placeholder="Color" formControlName="color">
                <mat-option value="B">Brown</mat-option>
                <mat-option value="BL">Black</mat-option>
                <mat-option value="TR">Tarry</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-4" *ngIf="initialAnswer === 'Yes'">
            <label class="labelText">Consistency</label>
            <mat-form-field appearance="outline">
              <mat-select
                placeholder="Consistency"
                formControlName="consistency"
              >
                <mat-option value="H">Hard</mat-option>
                <mat-option value="F">Formed</mat-option>
                <mat-option value="S">Soft</mat-option>
                <mat-option value="W">Watery</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-12">
            <label class="labelText">Additional Details</label>
            <mat-form-field appearance="outline">
              <textarea
                matInput
                placeholder="Additional Details"
                formControlName="customMessage"
                name="customMessage"
              ></textarea>
            </mat-form-field>
          </div>

          <div class="col-md-12">
            <label class="labelText">
              Activity Date/Time <span style="color: red">*</span>
            </label>
            <mat-form-field appearance="outline">
              <input
                matInput
                [owlDateTime]="dt1"
                [owlDateTimeTrigger]="dt1"
                placeholder="Date Time"
                formControlName="activityDateTime"
                [max]="todaysDate"
              />
              <owl-date-time #dt1></owl-date-time>

              <mat-error *ngIf="bowelForm.controls['activityDateTime'].invalid">
                Please select the activity date and time
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-12" style="text-align: right">
            <button
              color="primary"
              mat-raised-button
              type="submit"
              [disabled]="!bowelForm.valid || isProcessing"
            >
              Submit <i class="fa fa-spinner fa-spin" *ngIf="isProcessing"></i>
            </button>
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </form>
</div>
