import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataTablesModel } from '../../_models';
import {
  FileService,
  OrgDocumentCategoriesApiService,
  ToastMessageService,
} from '../../_services';
@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.css'],
})
export class FormsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ['name', 'status'];
  data: DataTablesModel = {} as DataTablesModel;
  files: DataTablesModel = {} as DataTablesModel;

  processing = false;
  isLoadingCategory = false;
  isLoadingFiles = false;

  constructor(
    public dialog: MatDialog,
    public orgdocumentcategoriesApiService: OrgDocumentCategoriesApiService,
    public fileService: FileService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit() {
    this.data.page = 0;
    this.data.per_page = 50;
    this.loadOrgDocumentCategoriesList();
    this.loadFiles();
  }

  ngAfterViewInit() {
    if (this.data.items) {
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    }
  }

  public loadOrgDocumentCategoriesList() {
    this.isLoadingCategory = true;

    this.orgdocumentcategoriesApiService
      .getOrgDocumentCategoriesList(this.data.page, this.data.per_page)
      .subscribe(
        (response) => {
          this.isLoadingCategory = false;
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          }
          if (response == null) {
            this.data.items = [];
            this.data.total = 0;
          }
        },
        (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load category list'
          );
          this.isLoadingCategory = false;
        }
      );
  }

  public loadFiles() {
    this.isLoadingFiles = true;

    this.fileService.getFiles().subscribe(
      (response) => {
        this.isLoadingFiles = false;
        if (response && response.items) {
          this.files.items = response.items;
          this.files.total = response.total;
        }
        if (response == null) {
          this.files.items = [];
          this.files.total = 0;
        }
      },
      (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to load documents'
        );
        this.isLoadingFiles = false;
      }
    );
  }

  getFilesByCategoryId(categoryId: string) {
    return this.files.items.filter((entry) => entry.refId === categoryId);
  }

  downloadFile(fileId: string, categoryId: string) {
    this.fileService.downloadDocument(fileId, categoryId).subscribe(
      (response: any) => {
        let blob: any = new Blob([response], { type: response.type });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      },
      (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Something went wrong while retrieving the file'
        );
      }
    );
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadOrgDocumentCategoriesList();
  }
}
