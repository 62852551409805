import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Patient } from 'src/app/_models';
import { IAssessmentRequest } from 'src/app/_models/assessments/assessmentrequest.model';
import { ToastMessageService } from 'src/app/_services';
import { AssessmentRequestApiService } from 'src/app/_services/assessments/assessmentrequest.service';
import { FavoritesSandbox } from 'src/app/shared/sandbox/favorites-sandbox';
import { assessmentGroups } from 'src/app/shared/utilities/assessment-utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-request-assessment',
  templateUrl: './request-assessment.component.html',
  styleUrls: ['./request-assessment.component.css'],
})
export class RequestAssessmentComponent implements OnInit {
  selectedCategory: string = '';
  selectedAssessment: any;
  selectedAssessmentId: string = '';
  groupAppointment: boolean;

  patientDetails: any = {};
  patient: Patient;
  sessionId: string;
  sessionCode: string;
  processing: boolean = false;

  sandboxFavList: any = [];
  searchAssessment: string = '';
  favoriteAssessments: any = [];
  filteredAssessments: any = [];
  assessments: any[] = [];
  assessmentList: any[] = [];
  assessmentGroups: any[] = assessmentGroups();

  isProd = environment.production;

  category = new FormControl('', Validators.required);
  assessmentType = new FormControl('', Validators.required);

  constructor(
    public dialogRef: MatDialogRef<RequestAssessmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private assessmentRequestApiService: AssessmentRequestApiService,
    private toastMessageService: ToastMessageService,
    private favoritesSandbox: FavoritesSandbox
  ) {
    this.patient = data.patient;
    this.sessionId = data.sessionId;
    this.sessionCode = data.sessionCode;
    this.assessmentRequest = data.assessmentRequest;
    this.groupAppointment = data.groupAppointment;
  }

  public assessmentForm: FormGroup;
  assessmentRequest: IAssessmentRequest;

  ngOnInit() {
    this.buildForm();
    this.assessments = this.getAssessments();

    this.favoritesSandbox.favoritesPayload$.subscribe((favoritesPayload) => {
      if (favoritesPayload) {
        this.sandboxFavList = favoritesPayload.data;

        this.toggleAllFavAssessments();
      }
    });
  }

  private buildForm() {
    this.assessmentForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(null),
      requestorId: new FormControl(null),
      patientId: new FormControl(this.patient.id),
      sessionId: new FormControl(this.sessionId),
      sessionCode: new FormControl(this.sessionCode),
      assessmentCategory: new FormControl(''),
      assessmentType: new FormControl(''),
      status: new FormControl(null),
      sendViaEmail: new FormControl(false),
      inSessionOnly: new FormControl(false),
      groupAppointment: new FormControl(this.groupAppointment),
    });
  }

  // Request a new assessment
  assessmentRequestSubmit() {
    this.processing = true;
    this.assessmentRequest = Object.assign({}, this.assessmentRequest);
    this.assessmentRequest = Object.assign(
      this.assessmentRequest,
      this.assessmentForm.value
    );

    this.assessmentRequestApiService
      .addAssessmentRequest(this.assessmentRequest)
      .subscribe({
        next: (response) => {
          this.processing = false;
          this.assessmentForm.reset();
          this.dialogRef.close('success');
          this.toastMessageService.displaySuccessMessage(
            'Successfully requested new assessment'
          );
        },
        error: () => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to request new assessment'
          );
          this.processing = false;
        },
      });
  }

  // Select category and go to next step
  selectCategory(selectedCategory: string, stepper: MatStepper) {
    this.selectedAssessment = {};
    this.selectedCategory = selectedCategory;
    this.category.setValue(selectedCategory);
    this.assessmentForm.controls['assessmentCategory'].setValue(
      selectedCategory
    );
    this.assessmentType.reset();
    this.assessmentList = this.getAssessmentTypes(selectedCategory);
    stepper.next();
  }

  // Regular flow from category and direct double jump
  onSelectAssessmentType(
    selectedAssessment: any,
    stepper: MatStepper,
    doubleJump: boolean
  ) {
    if (doubleJump) {
      this.selectCategory(selectedAssessment.category, stepper);
    }

    if (selectedAssessment.enabled || !this.isProd) {
      this.selectedAssessment = selectedAssessment;
      this.selectedAssessmentId = selectedAssessment.id;
      this.assessmentType.setValue(selectedAssessment.name);
      this.assessmentForm.controls['assessmentType'].setValue(
        selectedAssessment.name
      );
      this.assessmentForm.controls['inSessionOnly'].setValue(
        selectedAssessment.inSessionOnly
      );

      stepper.next();
    }
  }

  categoryStepper(stepper: MatStepper) {
    stepper.previous();
    stepper.previous();
  }

  assessmentStepper(stepper: MatStepper) {
    stepper.previous();
  }

  getAssessmentTypes(selectedCategory: string) {
    let category = this.assessmentGroups.filter(
      (category) => category.name === selectedCategory
    );
    return category[0].assessment;
  }

  getAssessments(): any[] {
    return this.assessmentGroups.reduce((acc, group) => {
      const enabledAssessments = group.assessment.filter((a) => a.enabled);
      enabledAssessments.forEach((assessment) => {
        assessment.category = group.name;
      });
      acc.push(...enabledAssessments);
      return acc;
    }, []);
  }

  // Show search results for the assessments
  searchAssessmentChanged() {
    if (this.searchAssessment) {
      this.filteredAssessments = this.assessments.filter((assessment) => {
        if (
          assessment.name
            .toLowerCase()
            .includes(this.searchAssessment.toLowerCase()) ||
          assessment.id
            .toLowerCase()
            .includes(this.searchAssessment.toLowerCase()) ||
          assessment.description
            .toLowerCase()
            .includes(this.searchAssessment.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.filteredAssessments = [];
    }
  }

  // From sandbox handle all the stars
  private toggleAllFavAssessments() {
    this.assessments.forEach((assessment) => {
      assessment.isFavorite = this.isFavorite('ASSESSMENTS', assessment.id);
    });

    this.favoriteAssessments = this.assessments.filter(
      (assessment) => assessment.isFavorite
    );
  }

  // Determine if this assessment is fav or not
  isFavorite(categoryName: string, favoriteItemId: string): boolean {
    if (this.sandboxFavList && this.sandboxFavList.favoriteCategories) {
      const category = this.sandboxFavList.favoriteCategories.find(
        (cat) => cat.name === categoryName
      );
      return category && category.userFavorites.includes(favoriteItemId);
    } else {
      return false;
    }
  }

  // Toggle fav
  toggleFavorite(categoryName: string, item: any, event: MouseEvent): void {
    if (item.isFavorite) {
      this.favoritesSandbox.removeFavorite(categoryName, item.id);
    } else {
      this.favoritesSandbox.addFavorite(categoryName, item.id);
    }
  }
}
