import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { IAdolescentDepression } from 'src/app/_models/assessments/depression/adolescentdepression.model';
import {
  AdolescentDepressionApiService,
  ToastMessageService,
} from 'src/app/_services/';

@Component({
  selector: 'app-adolescent-depression',
  templateUrl: './adolescent-depression.component.html',
  styleUrls: ['./adolescent-depression.component.css'],
})
export class AdolescentDepressionComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public adolescentDepressionApiService: AdolescentDepressionApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) {}
  public riskScore: number;
  public adolescentDepressionForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  adolescentDepressionAssessment: IAdolescentDepression;
  adolescentDepressionAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.adolescentDepressionForm, this.errors);

    this.loadAdolescentDepressionAssessment();
  }

  loadAdolescentDepressionAssessment() {
    this.adolescentDepressionForm.controls['patientId'].setValue(
      this.patientId
    );

    if (this.assessmentId) {
      this.adolescentDepressionApiService
        .getAdolescentDepressionDetails(this.assessmentId)
        .subscribe((adolescentDepressionAssessment) => {
          if (adolescentDepressionAssessment.data) {
            this.adolescentDepressionForm.controls['lowMood'].setValue(
              adolescentDepressionAssessment.data.lowMood.answer
            );
            this.adolescentDepressionForm.controls['losingIt'].setValue(
              adolescentDepressionAssessment.data.losingIt.answer
            );
            this.adolescentDepressionForm.controls[
              'sleepDifficulties'
            ].setValue(
              adolescentDepressionAssessment.data.sleepDifficulties.answer
            );
            this.adolescentDepressionForm.controls[
              'decreasedInterest'
            ].setValue(
              adolescentDepressionAssessment.data.decreasedInterest.answer
            );
            this.adolescentDepressionForm.controls['feelingWorthless'].setValue(
              adolescentDepressionAssessment.data.feelingWorthless.answer
            );
            this.adolescentDepressionForm.controls['tired'].setValue(
              adolescentDepressionAssessment.data.tired.answer
            );
            this.adolescentDepressionForm.controls[
              'troubleConcentration'
            ].setValue(
              adolescentDepressionAssessment.data.troubleConcentration.answer
            );
            this.adolescentDepressionForm.controls['lifeIsNotVeryFun'].setValue(
              adolescentDepressionAssessment.data.lifeIsNotVeryFun.answer
            );
            this.adolescentDepressionForm.controls['worried'].setValue(
              adolescentDepressionAssessment.data.worried.answer
            );
            this.adolescentDepressionForm.controls['physicalFeelings'].setValue(
              adolescentDepressionAssessment.data.physicalFeelings.answer
            );
            this.adolescentDepressionForm.controls['selfHarm'].setValue(
              adolescentDepressionAssessment.data.selfHarm.answer
            );

            this.riskScore = adolescentDepressionAssessment.data.riskScore;
          }
        });
    }
  }

  onFormSubmit() {
    if (this.adolescentDepressionForm.invalid) {
      this.adolescentDepressionForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.adolescentDepressionAssessment = Object.assign(
      {},
      this.adolescentDepressionAssessment
    );
    this.adolescentDepressionAssessment.patientId =
      this.adolescentDepressionForm.controls['patientId'].value;
    this.adolescentDepressionAssessment.sessionId =
      this.adolescentDepressionForm.controls['sessionId'].value;
    this.adolescentDepressionAssessment.assessmentRequestId =
      this.adolescentDepressionForm.controls['assessmentRequestId'].value;
    this.adolescentDepressionAssessment.lowMood = {
      answer: this.adolescentDepressionForm.controls['lowMood'].value,
    };
    this.adolescentDepressionAssessment.losingIt = {
      answer: this.adolescentDepressionForm.controls['losingIt'].value,
    };
    this.adolescentDepressionAssessment.sleepDifficulties = {
      answer: this.adolescentDepressionForm.controls['sleepDifficulties'].value,
    };
    this.adolescentDepressionAssessment.decreasedInterest = {
      answer: this.adolescentDepressionForm.controls['decreasedInterest'].value,
    };
    this.adolescentDepressionAssessment.feelingWorthless = {
      answer: this.adolescentDepressionForm.controls['feelingWorthless'].value,
    };
    this.adolescentDepressionAssessment.tired = {
      answer: this.adolescentDepressionForm.controls['tired'].value,
    };
    this.adolescentDepressionAssessment.troubleConcentration = {
      answer:
        this.adolescentDepressionForm.controls['troubleConcentration'].value,
    };
    this.adolescentDepressionAssessment.lifeIsNotVeryFun = {
      answer: this.adolescentDepressionForm.controls['lifeIsNotVeryFun'].value,
    };
    this.adolescentDepressionAssessment.worried = {
      answer: this.adolescentDepressionForm.controls['worried'].value,
    };
    this.adolescentDepressionAssessment.physicalFeelings = {
      answer: this.adolescentDepressionForm.controls['physicalFeelings'].value,
    };
    this.adolescentDepressionAssessment.selfHarm = {
      answer: this.adolescentDepressionForm.controls['selfHarm'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.adolescentDepressionAssessment.accessCode = this.accessCode;

      this.adolescentDepressionApiService
        .addGuestAdolescentDepression(this.adolescentDepressionAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Adolescent Depression Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed adding Adolescent Depression Assessment'
            );
          }
        );
    } else {
      this.adolescentDepressionApiService
        .addAdolescentDepression(this.adolescentDepressionAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Adolescent Depression Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed adding Adolescent Depression Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    lowMood: [
      {
        type: 'required',
        message:
          'Low mood, sadness, feeling blah or down, depressed, just can’t be bothered. is required',
      },
    ],
    losingIt: [
      {
        type: 'required',
        message:
          'Irritable, losing your temper easily, feeling pissed off, losing it. is required',
      },
    ],
    sleepDifficulties: [
      {
        type: 'required',
        message:
          'Sleeping differently from your usual (over the years before you got sick): trouble falling asleep, lying awake in bed. is required',
      },
    ],
    decreasedInterest: [
      {
        type: 'required',
        message:
          'Feeling decreased interest in: hanging out with friends; being with your best friend; being with your boyfriend/girlfriend; going out of the house; doing school work or work; doing hobbies or sports or recreation. is required',
      },
    ],
    feelingWorthless: [
      {
        type: 'required',
        message:
          'Feelings of worthlessness, hopelessness, letting people down, not being a good person is required',
      },
    ],
    tired: [
      {
        type: 'required',
        message:
          '​​Feeling tired, feeling fatigued, low in energy, hard to get motivated, have to push to get things done, want to rest or lie down a lot. is required',
      },
    ],
    troubleConcentration: [
      {
        type: 'required',
        message:
          'Trouble concentrating, can’t keep your mind on schoolwork or work, daydreaming when you should be working, hard to focus when reading, getting “bored” with work or school. is required',
      },
    ],
    lifeIsNotVeryFun: [
      {
        type: 'required',
        message:
          'Feeling that life is not very much fun, not feeling good when usually (before getting sick) would feel good, not getting as much pleasure from fun things as usual (before getting sick) is required',
      },
    ],
    worried: [
      {
        type: 'required',
        message:
          'Feeling worried, nervous, panicky, tense, keyed up, anxious. is required',
      },
    ],
    physicalFeelings: [
      {
        type: 'required',
        message:
          'Physical feelings of worry like: headaches, butterflies, nausea, tingling, restlessness, diarrhea, shakes or tremors. is required',
      },
    ],
    selfHarm: [
      {
        type: 'required',
        message:
          'Thoughts, plans or actions about suicide or self-harm. is required',
      },
    ],
  };

  private buildForm() {
    this.adolescentDepressionForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      lowMood: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      losingIt: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      sleepDifficulties: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      decreasedInterest: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feelingWorthless: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      tired: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      troubleConcentration: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      lifeIsNotVeryFun: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      worried: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      physicalFeelings: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      selfHarm: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.adolescentDepressionForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.adolescentDepressionForm) {
      return;
    }
    const form = this.adolescentDepressionForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
