import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { DateTimeZoneService, ToastMessageService } from 'src/app/_services';
import { MoodLogApiService } from 'src/app/_services/hourlylogs/moodlog.service';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';

@Component({
  selector: 'app-view-mood-log',
  templateUrl: './view-mood-log.component.html',
  styleUrls: ['./view-mood-log.component.css'],
})
export class ViewMoodLogComponent implements OnInit {
  date: any;
  moodLog: any;
  getAllMoodDataForTheSelectedYear: any;

  hoursMap = new Map([
    [0, '12 AM'],
    [1, '1 AM'],
    [2, '2 AM'],
    [3, '3 AM'],
    [4, '4 AM'],
    [5, '5 AM'],
    [6, '6 AM'],
    [7, '7 AM'],
    [8, '8 AM'],
    [9, '9 AM'],
    [10, '10 AM'],
    [11, '11 AM'],
    [12, '12 PM'],
    [13, '1 PM'],
    [14, '2 PM'],
    [15, '3 PM'],
    [16, '4 PM'],
    [17, '5 PM'],
    [18, '6 PM'],
    [19, '7 PM'],
    [20, '8 PM'],
    [21, '9 PM'],
    [22, '10 PM'],
    [23, '11 PM'],
  ]);

  currentTimezone: string;
  deleteProcessing: boolean = false;
  reloadRequired: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ViewMoodLogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private dateTimeZoneService: DateTimeZoneService,
    private moodApiService: MoodLogApiService,
    private toastMessageService: ToastMessageService
  ) {
    this.date = data.date;
    this.moodLog = data.moodLog;
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit(): void {}

  getIcon(data) {
    if (data.mood === 'Very Sad') {
      return 'sentiment_very_dissatisfied';
    } else if (data.mood === 'Somewhat Sad') {
      return 'sentiment_dissatisfied';
    } else if (data.mood === 'Neutral') {
      return 'sentiment_neutral';
    } else if (data.mood === 'Somewhat Happy') {
      return 'sentiment_satisfied';
    } else if (data.mood === 'Very Happy') {
      return 'sentiment_very_satisfied';
    }
  }

  getHour(data) {
    return this.hoursMap.get(data.hour);
  }

  deleteMoodLog(row) {
    const dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message: 'Are you sure that you want to remove this mood record?',
      },
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.deleteProcessing = true;

        this.moodApiService.deleteMoodLog(row.id).subscribe({
          next: (response) => {
            this.deleteProcessing = false;
            this.toastMessageService.displaySuccessMessage(
              'Mood log has been updated.'
            );

            this.moodLog = this.moodLog.filter((mood) => mood.id != row.id);
            this.reloadRequired = true;
          },
          error: (error) => {
            this.deleteProcessing = false;
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to remove the mood log.'
            );
          },
        });
      }
    });
  }
}
