<div class="row" style="padding: 10px">
  <!-- Top Buttons -->
  <div class="col-md-12" style="margin-bottom: 15px; text-align: right">
    <button
      class="actionButtons"
      mat-raised-button
      type="button"
      *ngIf="therapySession.status === 'Active'"
      (click)="assignSession()"
      style="color: #2196f3"
    >
      <mat-icon color="primary">person</mat-icon> Assign
    </button>

    <button
      class="actionButtons"
      mat-raised-button
      type="button"
      (click)="printDialog()"
      style="color: #2196f3"
    >
      <mat-icon color="primary">print</mat-icon> Print
    </button>

    <button
      class="actionButtons"
      color="warn"
      *ngIf="hasTherapySessionEditAccess && therapySession.status === 'Active'"
      mat-raised-button
      type="button"
      (click)="closeSession()"
    >
      <mat-icon style="color: white"> assignment_turned_in </mat-icon>
      Close
    </button>
  </div>

  <!-- Left Side Notes -->
  <div [class]="textShortCutsExpanded ? 'col-md-12' : 'col-md-8'">
    <nav
      mat-tab-nav-bar
      mat-stretch-tabs="false"
      class="mat-elevation-z8"
      [tabPanel]="tabPanel"
    >
      <a
        mat-tab-link
        [active]="activeTab === 'Clinical Note'"
        (click)="activeTab = 'Clinical Note'"
      >
        {{
          therapySession?.sessionType === 'Phone Note'
            ? 'Phone Note'
            : 'Clinical Note'
        }}
      </a>
      <a
        mat-tab-link
        [active]="activeTab === 'Therapy Note'"
        (click)="activeTab = 'Therapy Note'"
        *ngIf="therapySession?.sessionType !== 'Phone Note'"
      >
        Therapy Note
      </a>
      <a
        mat-tab-link
        [active]="activeTab === 'Subjectives'"
        (click)="activeTab = 'Subjectives'"
        *ngIf="therapySession?.sessionType !== 'Phone Note'"
      >
        Subjectives
      </a>
      <a
        mat-tab-link
        [active]="activeTab === 'Objectives'"
        (click)="activeTab = 'Objectives'"
        *ngIf="therapySession?.sessionType !== 'Phone Note'"
      >
        Objectives
      </a>
    </nav>

    <mat-tab-nav-panel #tabPanel>
      <app-session-clinical-notes
        *ngIf="activeTab === 'Clinical Note'"
        [patient]="patient"
        [therapySession]="therapySession"
        [hasClinicalNotesViewAccess]="hasClinicalNotesViewAccess"
        [hasClinicalNotesAddAccess]="hasClinicalNotesAddAccess"
        [hasClinicalNotesEditAccess]="hasClinicalNotesEditAccess"
        (output)="triggerEvent($event)"
        (expandrow)="expandRow($event)"
      >
      </app-session-clinical-notes>

      <app-therapy-notes
        *ngIf="activeTab === 'Therapy Note'"
        [patient]="patient"
        [therapySession]="therapySession"
        [loggedInUserId]="loggedInUserId"
        (output)="triggerEvent($event)"
      >
      </app-therapy-notes>

      <app-session-subjectives
        *ngIf="activeTab === 'Subjectives'"
        [patient]="patient"
        [therapySession]="therapySession"
        [loggedInUserId]="loggedInUserId"
        [hasSubjectivesViewAccess]="hasSubjectivesViewAccess"
        [hasSubjectivesEditAccess]="hasSubjectivesEditAccess"
        (output)="triggerEvent($event)"
      >
      </app-session-subjectives>
      <app-session-objectives
        *ngIf="activeTab === 'Objectives'"
        [patient]="patient"
        [therapySession]="therapySession"
        [loggedInUserId]="loggedInUserId"
        [hasObjectivesViewAccess]="hasObjectivesViewAccess"
        [hasObjectivesEditAccess]="hasObjectivesEditAccess"
        (output)="triggerEvent($event)"
      >
      </app-session-objectives>
    </mat-tab-nav-panel>
  </div>

  <!--Right Side Diagnosis, Vitals,... -->
  <div
    [class]="textShortCutsExpanded ? 'hiddenBox' : 'col-md-4 flexDownDirection'"
  >
    <div
      class="col-md-12"
      style="
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 15px;
      "
    >
      <button
        mat-button
        color="primary"
        (click)="accordion.closeAll()"
        type="button"
      >
        Collapse All <mat-icon>expand_less</mat-icon>
      </button>

      <button
        mat-button
        color="primary"
        (click)="accordion.openAll()"
        type="button"
      >
        Expand All <mat-icon>expand_more</mat-icon>
      </button>
    </div>

    <!-- Diagnosis -->
    <mat-accordion multi class="expansionContainer">
      <!-- Vitals -->
      <mat-expansion-panel class="mat-elevation-z8" [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon style="margin-right: 10px; color: #555555">
              emergency
            </mat-icon>
            <b> Vitals</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-session-vitals
          [patient]="patient"
          [therapySession]="therapySession"
        ></app-session-vitals>
      </mat-expansion-panel>

      <!-- Diagnosis -->
      <mat-expansion-panel
        class="mat-elevation-z8"
        *ngIf="hasDiagnosisViewAccess"
        [expanded]="true"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon style="margin-right: 10px; color: #555555">
              monitor_heart
            </mat-icon>
            <b> Diagnosis</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-session-diagnosis-codes
          [patient]="patient"
          [therapySession]="therapySession"
          [loggedInUserId]="loggedInUserId"
          [hasDiagnosisViewAccess]="hasDiagnosisViewAccess"
          [hasDiagnosisAddAccess]="hasDiagnosisAddAccess"
          [hasDiagnosisEditAccess]="hasDiagnosisEditAccess"
          [hasDiagnosisDeleteAccess]="hasDiagnosisDeleteAccess"
          (output)="triggerEvent($event)"
        ></app-session-diagnosis-codes>
      </mat-expansion-panel>

      <!-- Medications -->
      <mat-expansion-panel
        class="mat-elevation-z8"
        *ngIf="hasMedicationViewAccess"
        [expanded]="false"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon style="margin-right: 10px; color: #555555">
              medication
            </mat-icon>
            <b> Medications</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-medications
          [patient]="patient"
          [sessionId]="therapySession.id"
          [prescriptionEnabled]="prescriptionEnabled"
          [displayTitle]="false"
          [hasMedicationViewAccess]="hasMedicationViewAccess"
          [hasMedicationAddAccess]="
            hasMedicationAddAccess && therapySession.status === 'Active'
          "
          [hasMedicationEditAccess]="hasMedicationEditAccess"
          [hasMedicationDeleteAccess]="hasMedicationDeleteAccess"
        >
        </app-medications>
      </mat-expansion-panel>

      <!-- Assessments -->
      <mat-expansion-panel
        class="mat-elevation-z8"
        *ngIf="hasMemberAssessmentViewAccess"
        [expanded]="false"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon style="margin-right: 10px; color: #555555">
              rate_review
            </mat-icon>
            <b> Assessments</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-session-assessments
          [therapySession]="therapySession"
          [patient]="patient"
          [loggedInUserId]="loggedInUserId"
          [hasHospitalDeviceFeature]="hasHospitalDeviceFeature"
          [hasASIFeatures]="hasASIFeatures"
          [hasMemberEditAccess]="hasMemberEditAccess"
          [hasMemberAssessmentViewAccess]="hasMemberAssessmentViewAccess"
          [hasMemberAssessmentAddAccess]="hasMemberAssessmentAddAccess"
          [hasMemberAssessmentEditAccess]="hasMemberAssessmentEditAccess"
          [hasMemberAssessmentDeleteAccess]="hasMemberAssessmentDeleteAccess"
          [hasAssessmentTakeAddAccess]="hasAssessmentTakeAddAccess"
          [hasAssessmentChartsViewAccess]="hasAssessmentChartsViewAccess"
          [hasAssessmentGuidanceViewAccess]="hasAssessmentGuidanceViewAccess"
          [hasAssessmentGuidanceEditAccess]="hasAssessmentGuidanceEditAccess"
          [hasAssessmentSummaryViewAccess]="hasAssessmentSummaryViewAccess"
          [hasAssessmentSummaryEditAccess]="hasAssessmentSummaryEditAccess"
          (output)="triggerEvent($event)"
        ></app-session-assessments>
      </mat-expansion-panel>

      <!-- Goals -->
      <mat-expansion-panel
        class="mat-elevation-z8"
        *ngIf="hasMemberAssessmentViewAccess"
        [expanded]="false"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon style="margin-right: 10px; color: #555555">
              crisis_alert
            </mat-icon>
            <b> Goals</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-session-goals-by-category
          [patient]="patient"
          [therapySession]="therapySession"
          [loggedInUserId]="loggedInUserId"
          [hasGoalsViewAccess]="hasGoalsViewAccess"
          [hasGoalsAddAccess]="hasGoalsAddAccess"
          [hasGoalsEditAccess]="hasGoalsEditAccess"
          [hasGoalsDeleteAccess]="hasGoalsDeleteAccess"
        ></app-session-goals-by-category>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <!-- 
  <mat-tab *ngIf="hasMemberAssessmentViewAccess && (showAsi | async) === true">
    <ng-template mat-tab-label> ASI </ng-template> -->
  <!-- <app-asi-home [therapySession]="therapySession" [patient]="patient" [loggedInUserId]="loggedInUserId"></app-asi-home> -->
  <!-- </mat-tab> -->
</div>
