import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssessmentApiService, ToastMessageService } from 'src/app/_services';

@Component({
  selector: 'app-print-guidance',
  templateUrl: './print-guidance.component.html',
  styleUrls: ['./print-guidance.component.css'],
})
export class PrintGuidanceComponent implements OnInit {
  @ViewChild('pdfViewerOnDemand', { static: false }) pdfViewerOnDemand;
  displayNoRecordsMsg = false;
  public patientId: string;
  public assessmentId: string;
  public assessmentRequestId: string;
  public selectedAssessmentType: string;
  isLoadingResults: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<PrintGuidanceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastMessageService: ToastMessageService,
    public assessmentApiService: AssessmentApiService
  ) {
    this.patientId = data.patientId;
    this.assessmentId = data.assessmentId;
    this.selectedAssessmentType = data.assessmentType;
  }
  ngOnInit(): void {
    this.printAssessment();
  }

  printAssessment() {
    this.displayNoRecordsMsg = false;
    this.isLoadingResults = true;

    this.assessmentApiService
      .printAssessment(
        this.selectedAssessmentType,
        this.assessmentId,
        this.patientId
      )
      .subscribe(
        (res) => {
          this.isLoadingResults = false;
          if (res) {
            this.pdfViewerOnDemand.pdfSrc = res;
            this.pdfViewerOnDemand.refresh();
            this.displayNoRecordsMsg = false;
          } else {
            this.pdfViewerOnDemand.pdfSrc = null;
            this.pdfViewerOnDemand.refresh();
            this.displayNoRecordsMsg = true;
          }
        },
        (error) => {
          this.isLoadingResults = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Something went wrong. Please try again.'
          );
        }
      );
  }
}
