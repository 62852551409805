import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAssessmentRequest } from '../../_models/assessments/assessmentrequest.model';
@Injectable({ providedIn: 'root' })
export class InSessionInterviewRequestApiService {
  constructor(private httpClient: HttpClient) { }

  addInSessionInterviewRequest(
    interviewRequest: any
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<IAssessmentRequest>(
        `${environment.apiUrl}/insession-interview`,
        interviewRequest,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
