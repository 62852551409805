import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Patient } from 'src/app/_models';
import {
  CoreService,
  PatientService,
  ToastMessageService,
} from 'src/app/_services';
import { AddEditPatientComponent } from 'src/app/pages/patients/dialogs/add/add.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { AssignAccessComponent } from './dialogs/assign-access/assign-access.component';

@Component({
  selector: 'app-member-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class MemberProfileComponent implements OnInit {
  @Input() patient: Patient;
  @Input() hasMemberEditAccess: boolean;
  @Input() hasContactsViewAccess: boolean;
  @Input() hasPatientPortalFeature: boolean;

  @Output() output = new EventEmitter<any>();

  orgConfig: any;
  showMemberInformation: boolean = false;
  isOrgAdmin: boolean = false;

  constructor(
    public dialog: MatDialog,
    public apiService: PatientService,
    private toastMessageService: ToastMessageService,
    public patientApiService: PatientService,
    public coreService: CoreService,
    public router: Router
  ) {}

  ngOnInit() {
    this.isOrgAdmin =
      this.coreService.isSuperOrgAdmin() || this.coreService.isOrgAdmin();
  }

  public editPatient(patient: Patient) {
    const dialogRef = this.dialog.open(AddEditPatientComponent, {
      data: { action: 'EDIT', patient, isOrgAdmin: this.isOrgAdmin },
      disableClose: true,
      autoFocus: false,
      minWidth: '40vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        let payload: any = {};
        payload.eventType = 'RELOAD_PATIENT_PROFILE';
        this.output.emit(payload);
      }
    });
  }

  callActiveConfirmation(patient: Patient) {
    if (patient?.email && patient?.email.length > 4) {
      let dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Activation Confirmation',
          msg:
            'Are you sure you want to active the member portal for ' +
            patient.firstName +
            ' ' +
            patient.lastName +
            '?',
          yesButtonTitle: 'Yes',
          noButtonTitle: 'No',
        },
        disableClose: true,
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe((response) => {
        if (response) {
          this.patientApiService.activatePatientProfile(patient).subscribe(
            (response) => {
              if (response) {
                this.toastMessageService.displaySuccessMessage(
                  'Member portal has been activated'
                );
                let payload: any = {};
                payload.eventType = 'RELOAD_PATIENT_PROFILE';
                this.output.emit(payload);
              }
            },
            (error) => {
              if (error.text === 'Duplicate Email') {
                this.toastMessageService.displayErrorMessage(
                  'Error: Member with the given email already exists.'
                );
              } else {
                this.toastMessageService.displayErrorMessage(
                  'Error: Something went wrong. Please try again.'
                );
              }
            }
          );
        }
      });
    } else {
      this.toastMessageService.displayInfoMessage(
        'Member email is required to create member portal. Please update member email.'
      );
      this.editPatient(patient);
    }
  }

  navigateTo(tab: string) {
    if (tab === 'CONTACTS') {
      this.router.navigate(['/main/member/' + this.patient.id + '/MEMBER'], {
        queryParams: {
          secondaryId: 2,
        },
      });
    } else {
      this.router.navigate(['/main/member/' + this.patient.id + '/MEMBER'], {
        queryParams: {
          secondaryId: 1,
        },
      });
    }
  }

  assignAuthorizedUsers(patient: Patient) {
    this.dialog.open(AssignAccessComponent, {
      data: {
        alreadyAssigned: patient.authorizedUsers,
        patient: patient,
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '65vw',
    });
  }
}
