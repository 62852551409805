export const MarriedStatus = [
  {
    id: 'UNKWN',
    description: 'Unknown',
  },
  {
    id: 'MARRD',
    description: 'Married',
  },
  {
    id: 'SINGL',
    description: 'Single',
  },
  {
    id: 'WIDWD',
    description: 'Widowed',
  },
  {
    id: 'SPRTD',
    description: 'Separated',
  },
  {
    id: 'DVRCD',
    description: 'Divorced',
  },
  {
    id: 'ENGD',
    description: 'Engaged',
  },
  {
    id: 'INTRL',
    description: 'Interlocutory',
  },
  {
    id: 'PRVSLYMRD',
    description: 'Previously Married',
  },
  {
    id: 'NVRMRD',
    description: 'Never Married',
  },
  {
    id: 'OTHER',
    description: 'Other',
  },
];
