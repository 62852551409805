<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>CORE 10 Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-12 text-center">
      <button class="btn btn-primary" mat-raised-button>
        Closeness: {{ closeness }}/50
      </button>
      <button class="btn btn-primary" mat-raised-button>
        Conflicts: {{ conflicts }}/60
      </button>
      <button class="btn btn-primary" mat-raised-button>
        Dependence: {{ dependence }}/20
      </button>
    </div>
  </div>
  <form
    [formGroup]="cprsAssessmentForm"
    novalidate
    (ngSubmit)="onCprsFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >1. I share an affectionate, warm relationship with my child.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="warmChildRelation">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >2. My child and I always seem to be struggling with each
            other</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="struggleWithChild">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >3. If upset, my child will seek comfort from me.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="childSeeksComfort">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >4. My child is uncomfortable with physical affection or touch from
            me.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="childShunsTouch">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >5. My child values his/her relationship with me.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="childValuesRel">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >6. My child appears hurt or embarrassed when I correct
            him/her.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="childHurtByCorrection">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >7. My child does not want to accept help when he/she needs
            it.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="childRefusesHelp">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >8. When I praise my child, he/she beams with pride.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="childPrideOnPraise">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >9. My child reacts strongly to separation from me.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="childFearsSeparation">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >10. My child spontaneously shares information about
            himself/herself.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="childSharesInfo">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >11. My child is overly dependent on me.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="childDependent">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >12. My child easily becomes angry at me.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="childAngryAtMe">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >13. My child tries to please me.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="childPleases">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >14. My child feels that I treat him/her unfairly.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="childFeelsUnfair">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >15. My child asks for my help when he/she really does not need
            help.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="childAsksForHelp">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >16. It is easy to be in tune with what my child is feeling.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="inTuneWithChild">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >17. My child sees me as a source of punishment and
            criticism.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="childSeesPunishment">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >18. My child expresses hurt or jealousy when I spend time with
            other children.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="childJealousy">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >19. My child remains angry or is resistant after being
            disciplined.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="childStaysAngry">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >20. When my child is misbehaving, he/she responds to my look or
            tone of voice.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="childResponseToDisc">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >21. Dealing with my child drains my energy.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="childDrainsEnergy">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >22. I've noticed my child copying my behavior or ways of doing
            things.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="childCopiesBehavior">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >23. When my child is in a bad mood, I know we're in for a long and
            difficult day.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="childBadMood">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >24. My child's feelings toward me can be unpredictable or can
            change suddenly.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="childsMoodSwings">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >25. Despite my best efforts, I'm uncomfortable with how my child
            and I get along.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="uncomfyParenting">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >26. I often think about my child when at work.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="thinkOfChildWork">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >27. My child whines or cries when he/she wants something from
            me.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="childWhinesForNeeds">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >28. My child is sneaky or manipulative with me.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="childSneaky">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >29. My child openly shares his/her feelings and experiences with
            me.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="childSharesFeelings">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >30. My interactions with my child make me feel effective and
            confident as a parent.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="effectiveParenting">
              <mat-button-toggle value="Definitely does not apply"
                >Definitely does not apply</mat-button-toggle
              >
              <mat-button-toggle value="Not really"
                >Not really</mat-button-toggle
              >
              <mat-button-toggle value="Neutral, not sure"
                >Neutral, not sure</mat-button-toggle
              >
              <mat-button-toggle value="Applies somewhat"
                >Applies somewhat</mat-button-toggle
              >
              <mat-button-toggle value="Definitely applies"
                >Definitely applies</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          cprsAssessmentForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!cprsAssessmentForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
