import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { DataTablesModel, Patient } from 'src/app/_models';
import { ToastMessageService } from 'src/app/_services';
import { ConsentDocumentApiService } from 'src/app/_services/consent-docs/orgconsentdocument.service';
import { MemberAddConsentDocumentsComponent } from './member-add-consent-documents/member-add-consent-documents.component';
import { ViewMemberSignedConsentDocumentComponent } from './view-member-signed-consent-document/view-member-signed-consent-document.component';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';

@Component({
  selector: 'app-member-consent-documents',
  templateUrl: './member-consent-documents.component.html',
  styleUrl: './member-consent-documents.component.css',
})
export class MemberConsentDocumentsComponent implements OnInit, OnChanges {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() patient: Patient;

  @Output() output = new EventEmitter<any>();

  displayedColumns = ['documentName', 'sharedOn', 'status', 'action'];
  data: DataTablesModel = {} as DataTablesModel;
  isLoadingResults = false;
  deleteProcessing = false;

  status: string = '';
  searchDocumentName: string = '';

  constructor(
    public dialog: MatDialog,
    public consentDocumentApiService: ConsentDocumentApiService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.patient) {
      if (this.patient) {
        this.data.per_page = 10;
        this.data.page = 0;

        this.loadMemberConsentDocuments();
      }
    }
  }

  loadMemberConsentDocuments() {
    this.isLoadingResults = true;

    this.consentDocumentApiService
      .getMemberConsentDocuments(
        this.patient.id,
        this.status,
        this.searchDocumentName,
        this.data.per_page,
        this.data.page
      )
      .subscribe({
        next: (response) => {
          this.isLoadingResults = false;
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }
        },
        error: (error) => {
          this.isLoadingResults = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load documents'
          );
        },
      });
  }

  filterOptionChanged(status) {
    this.status = status;
    this.data.page = 0;
    this.loadMemberConsentDocuments();
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadMemberConsentDocuments();
  }

  viewConsentDocument(row) {
    this.dialog.open(ViewMemberSignedConsentDocumentComponent, {
      data: {
        recordId: row.id,
        documentName: row.documentName,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '55vw',
    });
  }

  searchByNameChanged() {
    this.data.page = 0;
    this.loadMemberConsentDocuments();
  }

  addConsentDocument() {
    let dialogRef = this.dialog.open(MemberAddConsentDocumentsComponent, {
      data: {
        patient: this.patient,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadMemberConsentDocuments();
        this.output.emit({
          eventType: 'RELOAD_ALERTS',
        });
      }
    });
  }

  deleteConsentDocument(id: string) {
    const dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to cancel this document request?',
      },
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.deleteProcessing = true;

        this.consentDocumentApiService.deleteMemberAlert(id).subscribe({
          next: (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Member document requests has been updated'
            );
            this.deleteProcessing = false;

            // Handle Delete Pagination
            if (this.data.items.length == 1) {
              this.data.page = this.data.page - 1;
              if (this.data.page < 0) {
                this.data.page = 0;
              }
            }
            this.loadMemberConsentDocuments();
            this.output.emit({
              eventType: 'RELOAD_ALERTS',
            });
          },
          error: (error) => {
            this.toastMessageService.displaySuccessMessage(
              'Error: Failed to remove the requested document'
            );
            this.deleteProcessing = false;
          },
        });
      } else {
        this.deleteProcessing = false;
      }
    });
  }
}
