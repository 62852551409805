import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { DataTablesModel, ITherapySession, Patient } from 'src/app/_models';
import { ITherapyNote } from 'src/app/_models/session/therapy-note';
import { DateTimeZoneService } from 'src/app/_services';
import { TherapyNotesHttpService } from 'src/app/_services/session/therapy-notes.service';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { AddTherapyNotesDialogComponent } from './dialogs/add-therapy-notes-dialog/add-therapy-notes-dialog.component';

@Component({
  selector: 'app-therapy-notes',
  templateUrl: './therapy-notes.component.html',
  styleUrl: './therapy-notes.component.css',
})
export class TherapyNotesComponent implements OnInit {
  @Input() patient: Patient;
  @Input() therapySession: ITherapySession;
  @Input() hasNoteAddAccess: boolean;
  @Input() hasNoteEditAccess: boolean;

  displayedColumns = ['text', 'actions'];
  data: DataTablesModel = {} as DataTablesModel;

  sessionId: string;
  isLoadingResults = false;
  apiError = false;
  action: string = '';
  error = '';
  currentTimezone: string;

  constructor(
    public dialog: MatDialog,
    public therapyNotesHttpService: TherapyNotesHttpService,
    private dateTimeZoneService: DateTimeZoneService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit() {
    this.data.page = 0;
    this.data.per_page = 10;

    this.sessionId = this.therapySession.id;

    this.loadTherapyNotes();
  }

  addTherapyNote() {
    this.action = 'ADD';
    const dialogRef = this.dialog.open(AddTherapyNotesDialogComponent, {
      data: {
        action: this.action,
        therapyNote: null,
        patientId: this.patient.id,
        sessionId: this.sessionId,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadTherapyNotes();
      }
    });
  }

  public editTherapyNote(therapyNote: ITherapyNote) {
    this.action = 'EDIT';
    const dialogRef = this.dialog.open(AddTherapyNotesDialogComponent, {
      data: { action: this.action, therapyNote },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadTherapyNotes();
      }
    });
  }

  deleteTherapyNote(therapyNote: ITherapyNote) {
    let dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to delete the note?',
      },
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.therapyNotesHttpService
          .deleteTherapyNote(therapyNote)
          .subscribe(() => {
            // Handle Delete Pagination
            if (this.data.items.length == 1) {
              this.data.page = this.data.page - 1;
              if (this.data.page < 0) {
                this.data.page = 0;
              }
            }

            this.loadTherapyNotes();
          });
      }
    });
  }

  public loadTherapyNotes() {
    this.isLoadingResults = true;
    this.error = '';
    this.therapyNotesHttpService
      .getTherapyNotes(this.patient.id, this.data.per_page, this.data.page)
      .subscribe(
        (response) => {
          this.isLoadingResults = false;
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          }
          if (response == null) {
            this.data.items = [];
            this.data.total = 0;
          }
        },
        (error) => {
          this.error = error;
          this.isLoadingResults = false;
        }
      );
  }

  // Member Notes Paginator
  getNextMemberNotes(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadTherapyNotes();
  }
}
