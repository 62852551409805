import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseModal } from 'src/app/_models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserUIPropsSettingService {
  constructor(private http: HttpClient) {}

  getMyUISettings(): Observable<ResponseModal> {
    return this.http.get<ResponseModal>(
      `${environment.apiUrl}/my-ui-props-settings`
    );
  }

  handleUISettingsPropsChange(
    category: string,
    value: string
  ): Observable<string> {
    let params = new HttpParams();
    params = params.append('category', category);
    params = params.append('value', value);

    return this.http.put<string>(
      `${environment.apiUrl}/handle-ui-props-settings`,
      {},
      { params }
    );
  }
}
