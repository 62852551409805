import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { CoreService } from 'src/app/_services';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';

@Component({
  selector: 'app-settings-dashboard',
  templateUrl: './settings-dashboard.component.html',
  styleUrls: ['./settings-dashboard.component.css'],
})
export class SettingsDashboardComponent implements OnInit {
  firstName: string = '';
  lastName: string = '';
  emailAddress: string = '';

  tabSelected: string = 'Account';

  isPhysician: boolean = false;
  isPatient: boolean = false;
  canEditWorkingHours: boolean = false;
  canEditTimeOff: boolean = false;

  mainSettings = [
    {
      leftIcon: 'account_circle',
      text: 'Account',
      rightIcon: 'chevron_right',
    },
    {
      leftIcon: 'sync_lock',
      text: 'Change Password',
      rightIcon: 'chevron_right',
    },
  ];

  constructor(
    private readonly keycloakService: KeycloakService,
    private coreService: CoreService,
    private orgConfigSandbox: OrgConfigSandbox
  ) {}

  ngOnInit(): void {
    this.isPhysician = this.coreService.isPhysician();
    this.isPatient = this.coreService.isPatient();

    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        this.orgConfigSandbox.orgConfig$.subscribe((response) => {
          if (response && response?.features) {
            this.canEditTimeOff = response.features.includes(
              'HOLIDAY_MANAGED_ALL'
            );
            this.canEditWorkingHours = response.features.includes(
              'WORK_HOURS_MANAGED_ALL'
            );
          }
        });
      }
    });

    this.loadUserProfile();

    // If this is a physician loggin in, add work time, vacation
    if (this.isPhysician) {
      this.mainSettings.push(
        {
          leftIcon: 'calendar_month',
          text: 'My Working Hours',
          rightIcon: 'chevron_right',
        },
        {
          leftIcon: 'houseboat',
          text: 'Time Off & Holidays',
          rightIcon: 'chevron_right',
        }
      );
    }

    // Push notifications if the user is patient or physician
    if (this.isPhysician || this.isPatient) {
      this.mainSettings.splice(2, 0, {
        leftIcon: 'notifications_none',
        text: 'Notifications',
        rightIcon: 'chevron_right',
      });
    }

    // Push Consent Documents For Patient
    if (this.isPatient) {
      this.mainSettings.push({
        leftIcon: 'history_edu',
        text: 'Consent Documents',
        rightIcon: 'chevron_right',
      });
    }
  }

  loadUserProfile() {
    this.keycloakService.loadUserProfile().then((data) => {
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.emailAddress = data.email;
    });
  }
}
