<div class="main-content" style="background-color: #eee" *ngIf="!isLoadingResults">
  <div class="row pad10" *ngIf="!anchorTag">
    <div class="col-md-6">
      <mat-card>
        <mat-card-header>
          <h4 class="header text-center">ADHD Symptom Chart</h4>
        </mat-card-header>
        <mat-card-content>
          <div style="width: 100%">
            <div class="chart-wrapper">
              <canvas baseChart height="100%" [data]="lineChartData" [options]="lineChartOptions"
                [legend]="lineChartLegend" [type]="lineChartType" [plugins]="lineChartPlugins">
              </canvas>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <br />
      <mat-card>
        <mat-card-header>
          <h4 class="header text-center">Overall Graph</h4>
        </mat-card-header>
        <mat-card-content>
          <div style="width: 100%">
            <div class="chart-wrapper">
              <canvas baseChart [data]="overallChartData" [options]="overallChartOptions" [legend]="overallChartLegend"
                [type]="detailChartType" [plugins]="overallChartPlugins">
              </canvas>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-md-6">
      <mat-card class="mat-card-auto-height">
        <mat-card-header>
          <h4 class="header text-center">
            ADHD Symptom Evaluation Criteria
          </h4>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
          <div class="row">
            <div class="col-md-4" style="font-weight: 400">Type</div>
            <div class="col-md-8" style="padding-top: 4px; font-weight: 400">
              Description
            </div>
          </div>
          <div class="row">
            <div class="col-md-4" style="padding-top: 4px; font-weight: 300">
              Inattentive type
            </div>
            <div class="col-md-8" style="padding-top: 4px; font-weight: 300">
              Must score either a 2 or 3 on six or more items in questions 1
              to 9, and (score a 4 on at least 2, or 5 on at least 1, of the performance questions 48 to 54).
            </div>
          </div>
          <div class="row">
            <div class="col-md-4" style="padding-top: 4px; font-weight: 300">
              Hyperactive / Impulsive type
            </div>
            <div class="col-md-8" style="padding-top: 4px; font-weight: 300">
              Must score either a 2 or 3 on six or more items in questions
              10 to 18, and (score a 4 on at least 2, or 5 on at least 1, of the performance questions 48 to 54).
            </div>
          </div>
          <div class="row">
            <div class="col-md-4" style="padding-top: 4px; font-weight: 300">
              Combined Inattention/Hyperactivity
            </div>
            <div class="col-md-8" style="padding-top: 4px; font-weight: 300">
              Requires the criteria on both inattention and hyperactivity/impulsivity
            </div>
          </div>
          <div class="row">
            <div class="col-md-4" style="padding-top: 4px; font-weight: 300">
              Oppositional defiant disorder (ODD)
            </div>
            <div class="col-md-8" style="padding-top: 4px; font-weight: 300">
              Must score either a 2 or a 3 on four or more items in
              questions 19 to 26, and (score a 4 on at least 2, or 5 on at least 1, of the performance questions 48
              to 54).
            </div>
          </div>
          <div class="row">
            <div class="col-md-4" style="padding-top: 4px; font-weight: 300">
              Conduct Disorder
            </div>
            <div class="col-md-8" style="padding-top: 4px; font-weight: 300">
              Must score either a 2 or 3 on three or more items in questions
              27 to 40, and (score a 4 on at least 2, or 5 on at least 1, of the performance questions 48 to 54).
            </div>
          </div>
          <div class="row">
            <div class="col-md-4" style="padding-top: 4px; font-weight: 300">
              Anxiety / Depression
            </div>
            <div class="col-md-8" style="padding-top: 4px; font-weight: 300">
              Must score either a 2 or 3 on three or more items in questions
              41 to 47, and (score a 4 on at least 2, or 5 on at least 1, of the performance questions 48 to 54).
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="row pad10"
    *ngIf="scoreDetails.length > 0 && (!anchorTag || anchorTag === 'ADHD inattentive type' || anchorTag === 'Combined Inattention/Hyperactivity')">
    <div class="col-md-12">
      <mat-card class="mat-card-auto-height">
        <mat-card-header>
          <mat-card-title>ADHD inattentive type</mat-card-title>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
          <div class="row" style="padding:15px">
            <ng-container *ngFor="let scoreDetail of firstSetScoreDetails.slice(0, 9)">
              <div class="col-md-4" style="margin-bottom: 20px">
                <mat-card>
                  <mat-card-header>
                    <h4 class="header text-center">{{ scoreDetail.title }}</h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetail" [options]="assessmentDetailInitialChartOptions"
                          [legend]="detailChartLegend" [type]="detailChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetail.answer }}</i></small>
                </mat-card>
              </div>
            </ng-container>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="row pad10"
    *ngIf="scoreDetails.length > 0 && (!anchorTag || anchorTag === 'ADHD hyperactive/impulsive type' || anchorTag === 'Combined Inattention/Hyperactivity')">
    <div class="col-md-12">
      <mat-card class="mat-card-auto-height">
        <mat-card-header>
          <mat-card-title>ADHD hyperactive/impulsive type</mat-card-title>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
          <div class="row" style="padding:15px">
            <ng-container *ngFor="let scoreDetail of firstSetScoreDetails.slice(9, 18)">
              <div class="col-md-4" style="margin-bottom: 20px">
                <mat-card>
                  <mat-card-header>
                    <h4 class="header text-center">{{ scoreDetail.title }}</h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetail" [options]="assessmentDetailInitialChartOptions"
                          [legend]="detailChartLegend" [type]="detailChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetail.answer }}</i></small>
                </mat-card>
              </div>
            </ng-container>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="row pad10"
    *ngIf="scoreDetails.length > 0 && (!anchorTag || anchorTag === 'Oppositional defiant disorder (ODD)')">
    <div class="col-md-12">
      <mat-card class="mat-card-auto-height">
        <mat-card-header>
          <mat-card-title>Oppositional defiant disorder (ODD)</mat-card-title>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
          <div class="row" style="padding:15px">
            <ng-container *ngFor="let scoreDetail of firstSetScoreDetails.slice(18, 26)">
              <div class="col-md-4" style="margin-bottom: 20px">
                <mat-card>
                  <mat-card-header>
                    <h4 class="header text-center">{{ scoreDetail.title }}</h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetail" [options]="assessmentDetailInitialChartOptions"
                          [legend]="detailChartLegend" [type]="detailChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetail.answer }}</i></small>
                </mat-card>
              </div>
            </ng-container>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="row pad10" *ngIf="scoreDetails.length > 0 && (!anchorTag || anchorTag === 'Conduct Disorder')">
    <div class="col-md-12">
      <mat-card class="mat-card-auto-height">
        <mat-card-header>
          <mat-card-title>Conduct Disorder</mat-card-title>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
          <div class="row" style="padding:15px">
            <ng-container *ngFor="let scoreDetail of firstSetScoreDetails.slice(26, 40)">
              <div class="col-md-4" style="margin-bottom: 20px">
                <mat-card>
                  <mat-card-header>
                    <h4 class="header text-center">{{ scoreDetail.title }}</h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetail" [options]="assessmentDetailInitialChartOptions"
                          [legend]="detailChartLegend" [type]="detailChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetail.answer }}</i></small>
                </mat-card>
              </div>
            </ng-container>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="row pad10" *ngIf="scoreDetails.length > 0 && (!anchorTag || anchorTag === 'Anxiety/Depression')">
    <div class="col-md-12">
      <mat-card class="mat-card-auto-height">
        <mat-card-header>
          <mat-card-title>Anxiety / Depression</mat-card-title>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
          <div class="row" style="padding:15px">
            <ng-container *ngFor="let scoreDetail of firstSetScoreDetails.slice(40, 47)">
              <div class="col-md-4" style="margin-bottom: 20px">
                <mat-card>
                  <mat-card-header>
                    <h4 class="header text-center">{{ scoreDetail.title }}</h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetail" [options]="assessmentDetailInitialChartOptions"
                          [legend]="detailChartLegend" [type]="detailChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetail.answer }}</i></small>
                </mat-card>
              </div>
            </ng-container>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="row pad10" *ngIf="scoreDetails.length > 0">
    <div class="col-md-12">
      <mat-card class="mat-card-auto-height">
        <mat-card-header>
          <mat-card-title>Performance</mat-card-title>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
          <div class="row" style="padding:15px">
            <ng-container *ngFor="let scoreDetail of scoreDetails">
              <div class="col-md-4" style="margin-bottom: 20px">
                <mat-card>
                  <mat-card-header>
                    <h4 class="header text-center">{{ scoreDetail.title }}</h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetail" [options]="assessmentDetailChartOptions"
                          [legend]="detailChartLegend" [type]="detailChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetail.answer }}</i></small>
                </mat-card>
              </div>
            </ng-container>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>