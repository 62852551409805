import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataTablesModel } from 'src/app/_models';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class PatientAssessmentsService {
  constructor(private httpClient: HttpClient) {}

  getMyAssessments(
    status: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('status', status);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/getmyassessments`,
      { params: params }
    );
  }

  getSubmittedAssisgnments(
    status: string,
    pageSize: number,
    pageIndex: number,
    sort: string,
    filter: string
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('status', status);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    if (filter) params = params.append('assessmentType', filter);
    if (sort) params = params.append('order', sort);

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/patientSubmittedAssignments`,
      { params: params }
    );
  }
}
