import { Action, createReducer, on } from '@ngrx/store';
import { VisitReasonsAction } from '../actions';
import { VisitReasonsState, initialVisitReasonsState } from '../state';

export const visitReasonReducer = createReducer(
  initialVisitReasonsState,

  // On load
  on(VisitReasonsAction.getActiveVisitReasons, (state) => ({
    ...state,
    visitReasonsLoading: true,
    visitReasonsSuccess: false,
    visitReasonsError: undefined,
  })),

  // On Success
  on(
    VisitReasonsAction.getActiveVisitReasonsSuccessAction,
    (state, { payload }) => ({
      ...state,
      visitReasons: payload,
      visitReasonsLoading: false,
      visitReasonsSuccess: true,
      visitReasonsError: undefined,
    })
  ),

  // On Failed
  on(VisitReasonsAction.getActiveVisitReasonFailedAction, (state) => ({
    ...state,
    visitReasonsLoading: false,
    visitReasonsSuccess: false,
    visitReasonsError: undefined,
  }))
);

export function VisitReasonReducer(
  state: VisitReasonsState | undefined,
  action: Action
): VisitReasonsState {
  return visitReasonReducer(state, action);
}
