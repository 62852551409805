import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { DataTablesModel, ITherapySession, Patient } from 'src/app/_models';
import { IIntakeInsuranceInfo } from 'src/app/_models/session/intake/intakeinsuranceinfo.model';
import {
  PatientInsuranceApiService,
  TherapySessionService,
  ToastMessageService,
} from 'src/app/_services';
import { AddEditMemberInsuranceComponent } from 'src/app/pages/patient-details/patient-components/member-tab/member-tab-sections/member-insurance/add-edit-member-insurance/add-edit-member-insurance.component';

@Component({
  selector: 'app-intake-search-add-insurance',
  templateUrl: './intake-search-add-insurance.component.html',
  styleUrls: ['./intake-search-add-insurance.component.css'],
})
export class IntakeSearchAddInsuranceComponent implements OnInit {
  patient: Patient;
  therapySession: ITherapySession;
  parentClass: string = '';
  type: string = '';

  processing: boolean = true;
  intakeinsuranceinfo: IIntakeInsuranceInfo;
  existingInsurance: any;
  availableInsurance: DataTablesModel = {} as DataTablesModel;

  constructor(
    public dialogRef: MatDialogRef<AddEditMemberInsuranceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private patientInsuranceApiService: PatientInsuranceApiService,
    public therapysessionApiService: TherapySessionService,
    private toastMessageService: ToastMessageService
  ) {
    this.patient = data.patient;
    this.therapySession = data.therapySession;
    this.parentClass = data.parentClass;
    this.type = data.type;
  }

  ngOnInit(): void {
    this.loadAvailableInsuranceData();
    if (this.parentClass === 'Intake') {
      if (this.type === 'Primary') {
        this.existingInsurance = this.therapySession.primaryInsuranceDetails;
      } else {
        this.existingInsurance = this.therapySession.secondaryInsuranceDetails;
      }
    } else {
      this.existingInsurance = null;
    }
  }

  addInsurance() {
    const dialogRef = this.dialog.open(AddEditMemberInsuranceComponent, {
      data: { action: 'ADD', patient: this.patient },
      autoFocus: false,
      disableClose: true,
      minWidth: '40vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response && response === 'success') {
        this.loadAvailableInsuranceData();
      }
    });
  }

  loadAvailableInsuranceData() {
    this.processing = true;
    this.patientInsuranceApiService
      .getMemberInsurances(this.patient.id, null, null, null, 'Active', 30, 0)
      .subscribe(
        (response) => {
          if (response) {
            this.availableInsurance.items = response.items;
            this.availableInsurance.total = response.total;
          } else {
            this.availableInsurance.items = [];
            this.availableInsurance.total = 0;
          }
          this.processing = false;
        },
        (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get member insurance details.'
          );
          this.processing = false;
        }
      );
  }

  useInsurance(insurance) {
    if (this.parentClass === 'Intake') {
      if (this.type === 'Primary') {
        this.therapySession.primaryInsuranceDetails = insurance;
      } else {
        this.therapySession.secondaryInsuranceDetails = insurance;
      }

      this.processing = true;
      this.therapysessionApiService
        .updateBillingInformation(this.therapySession.id, this.therapySession)
        .subscribe(
          (response) => {
            this.therapySession = response.data;
            this.dialogRef.close({
              type: 'success',
              therpaySession: this.therapySession,
            });
            this.processing = false;
            this.toastMessageService.displaySuccessMessage(
              'Successfully added the insurance record'
            );
          },
          (error) => {
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to add the insurance.'
            );
            this.processing = false;
          }
        );
    } else {
      // If this component is being called to just pick the insurance
      this.dialogRef.close({
        type: 'success',
        insurance: insurance,
      });
    }
  }

  checkIfInsuranceExists(id) {
    if (this.existingInsurance) {
      if (this.existingInsurance.id === id) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
