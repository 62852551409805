<div class="main-content" style="background-color: #eee" *ngIf="!isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div class="container-fluid" *ngIf="!isLoadingResults">
        <br />
        <div class="row">
            <div class="col-md-6">
                <mat-card>
                    <mat-card-header>
                        <h4 class="header text-center">Risk Score Graph</h4>
                    </mat-card-header>
                    <mat-card-content>
                        <div style="width: 100%">
                            <div class="chart-wrapper">
                                <canvas baseChart height="100%" [data]="lineChartData" [options]="lineChartOptions"
                                    [legend]="lineChartLegend" [type]="lineChartType" [plugins]="lineChartPlugins">
                                </canvas>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-md-6">
                <mat-card>
                    <mat-card-header>
                        <h4 class="header text-center">Interpreting the Overall Score</h4>
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    <mat-card-content>
                        <div class="row">
                            <div class="col-md-12" style="padding-top: 4px; font-weight: 300">
                                Higher scores indicate higher levels of relationship satisfaction.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12" style="padding-top: 4px; font-weight: 300">
                                Lower scores suggest less satisfaction within the relationship, which may be a concern
                                that warrants further exploration or intervention
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <br />
        <div class="row">
            <ng-container *ngFor="let scoreDetail of scoreDetails; index as i">
                <ng-container *ngIf="i === 0">
                    <div class="col-md-4" style="margin-bottom: 20px">
                        <mat-card>
                            <mat-card-header>
                                <h4 class="header text-center">{{ scoreDetail.title }}</h4>
                            </mat-card-header>
                            <mat-card-content>
                                <div style="width: 100%">
                                    <div class="chart-wrapper">
                                        <canvas baseChart [data]="scoreDetail" [options]="firstQuestionChartOptions"
                                            [legend]="lineChartLegend" [type]="lineChartType"
                                            [plugins]="detailChartPlugins">
                                        </canvas>
                                    </div>
                                </div>
                            </mat-card-content>
                            <small><b>Answer:</b> <i>{{ scoreDetail.answer }}</i></small>
                        </mat-card>
                    </div>
                </ng-container>
                <ng-container *ngIf="i > 0">
                    <div class="col-md-4" style="margin-bottom: 20px">
                        <mat-card>
                            <mat-card-header>
                                <h4 class="header text-center">{{ scoreDetail.title }}</h4>
                            </mat-card-header>
                            <mat-card-content>
                                <div style="width: 100%">
                                    <div class="chart-wrapper">
                                        <canvas baseChart [data]="scoreDetail" [options]="assessmentDetailChartOptions"
                                            [legend]="lineChartLegend" [type]="lineChartType"
                                            [plugins]="detailChartPlugins">
                                        </canvas>
                                    </div>
                                </div>
                            </mat-card-content>
                            <small><b>Answer:</b> <i>{{ scoreDetail.answer }}</i></small>
                        </mat-card>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>