<div class="container">
  <div class="dialogTitle" style="margin-bottom: 25px">
    <p matDialogTitle>Goal & Objectives</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="patientAssessmentGoalForm">
    <div class="row">
      <div class="col-md-12">
        <label class="labelText">
          Goal Description <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <textarea
            matInput
            placeholder=""
            formControlName="description"
            name="description"
            rows="3"
          ></textarea>
          <mat-error
            *ngIf="patientAssessmentGoalForm.controls['description'].invalid"
          >
            Goal description is required
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="col-md-12"
        style="margin-bottom: 12px"
        *ngIf="patient.status === 'Active'"
      >
        <button
          type="button"
          class="btn btn-success pull-right"
          (click)="createObjective('', null, '')"
        >
          + Add Objective
        </button>
      </div>

      <div class="col-md-12" formArrayName="objectives">
        <ng-container
          *ngFor="let objective of objectives().controls; let i = index"
        >
          <mat-card
            appearance="outlined"
            class="mat-elevation-z8"
            style="width: 100%; position: relative; margin-bottom: 20px"
          >
            <div
              style="position: absolute; right: 10px; z-index: 1050"
              *ngIf="objectives().length > 1 && patient.status === 'Active'"
            >
              <mat-icon
                id="deleteButton"
                style="
                  color: red;
                  font-size: 19px;
                  cursor: pointer;
                  opacity: 0.5;
                "
                (click)="removeObjective(i)"
                >delete_forever</mat-icon
              >
            </div>

            <div class="input-wrapper" [formGroupName]="i">
              <label class="labelText pull-left">
                Objective <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder=""
                  formControlName="objectiveDescription"
                  required
                />
                <mat-error
                  *ngIf="objective.controls.objectiveDescription.invalid"
                >
                  Please describe the objective
                </mat-error>
              </mat-form-field>

              <div class="row">
                <div class="col-md-6">
                  <label class="labelText">
                    Target Date <span style="color: red">*</span></label
                  >
                  <mat-form-field appearance="outline">
                    <input
                      formControlName="targetDate"
                      matInput
                      [matDatepicker]="targetDatePicker"
                      placeholder="Target Date"
                      required
                      autoSlashDate
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="targetDatePicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #targetDatePicker></mat-datepicker>
                    <mat-error *ngIf="objective.controls.targetDate.invalid">
                      Please select the target date
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <label class="labelText">
                    Status <span style="color: red">*</span></label
                  >
                  <mat-form-field appearance="outline">
                    <mat-select
                      formControlName="status"
                      placeholder="Status"
                      required
                    >
                      <mat-option value="Open">Open</mat-option>
                      <mat-option
                        value="Attained"
                        *ngIf="action === 'EDITEXISTINGGOAL'"
                        >Attained</mat-option
                      >
                      <mat-option
                        value="Canceled"
                        *ngIf="action === 'EDITEXISTINGGOAL'"
                        >Canceled</mat-option
                      >
                      <mat-option
                        value="Extended"
                        *ngIf="action === 'EDITEXISTINGGOAL'"
                        >Extended</mat-option
                      >
                      <mat-option
                        value="Revised"
                        *ngIf="action === 'EDITEXISTINGGOAL'"
                        >Revised</mat-option
                      >
                      <!-- <mat-option value="Referred">Referred</mat-option> -->
                    </mat-select>
                    <mat-error *ngIf="objective.controls.status.invalid">
                      Please select the status
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-card>
        </ng-container>
      </div>

      <div class="col-md-12" style="text-align: right">
        <button
          class="btn btn-primary"
          mat-raised-button
          type="submit"
          [disabled]="!patientAssessmentGoalForm.valid || processing"
          (click)="updateGoal()"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
