<div class="main-content" style="background-color: #eee" *ngIf="!isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div class="container-fluid" *ngIf="!isLoadingResults">
        <br />
        <div class="row">
            <div class="col-md-6">
                <mat-card>
                    <mat-card-header>
                        <h4 class="header text-center">Overall Chart</h4>
                    </mat-card-header>
                    <mat-card-content>
                        <div style="width: 100%">
                            <div class="chart-wrapper">
                                <canvas baseChart height="100%" [data]="lineChartData" [options]="lineChartOptions"
                                    [legend]="lineChartLegend" [type]="lineChartType" [plugins]="lineChartPlugins">
                                </canvas>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-md-6">
                <mat-card>
                    <mat-card-header>
                        <h4 class="header text-center">The Family APGAR Domains</h4>
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    <mat-card-content>
                        <div class="row">
                            <div class="col-md-3" style="font-weight: 400">Component</div>
                            <div class="col-md-9" style="padding-top: 4px; font-weight: 400">
                                Definition
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3" style="padding-top: 4px; font-weight: 300">
                                Adaptation
                            </div>
                            <div class="col-md-7" style="padding-top: 4px; font-weight: 300">
                                Adaptation is the utilization of familial resources for problems solving in the face of
                                life change(s) that disrupt the family's equilibrium
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3" style="padding-top: 4px; font-weight: 300">
                                Partnership
                            </div>
                            <div class="col-md-7" style="padding-top: 4px; font-weight: 300">
                                Partnership is the sharing of decision making and nurturing responsibilities within the
                                family
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3" style="padding-top: 4px; font-weight: 300">
                                Growth
                            </div>
                            <div class="col-md-7" style="padding-top: 4px; font-weight: 300">
                                Growth is the physical and emotional maturation and self-fulfillment achieved by family
                                members through mutual support and guidance
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3" style="padding-top: 4px; font-weight: 300">
                                Affection
                            </div>
                            <div class="col-md-7" style="padding-top: 4px; font-weight: 300">
                                Affection is the caring or loving relationship that exists among family members
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3" style="padding-top: 4px; font-weight: 300">
                                Resolve
                            </div>
                            <div class="col-md-7" style="padding-top: 4px; font-weight: 300">
                                Resolve is the commitment to devote resources to other family members for physical and
                                emotional nurturing
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <br />
        <div class="row">
            <ng-container *ngFor="let scoreDetail of scoreDetails">
                <div class="col-md-4" style="margin-bottom: 20px">
                    <mat-card>
                        <mat-card-header>
                            <h4 class="header text-center">{{ scoreDetail.title }}</h4>
                        </mat-card-header>
                        <mat-card-content>
                            <div style="width: 100%">
                                <div class="chart-wrapper">
                                    <canvas baseChart [data]="scoreDetail" [options]="assessmentDetailChartOptions"
                                        [legend]="lineChartLegend" [type]="lineChartType"
                                        [plugins]="detailChartPlugins">
                                    </canvas>
                                </div>
                            </div>
                        </mat-card-content>
                        <small><b>Answer:</b> <i>{{ scoreDetail.answer }}</i></small>
                    </mat-card>
                </div>
            </ng-container>
        </div>
    </div>
</div>