<div>
  <!-- Add Button -->
  <div class="row" style="margin-bottom: 10px">
    <div
      class="col-md-12"
      style="text-align: right"
      *ngIf="
        hasDiagnosisAddAccess &&
        therapySession.status === 'Active' &&
        diagnosisForm.controls['diagnosisCodes'].value.length < 12
      "
    >
      <button
        mat-raised-button
        type="button"
        class="mat-elevation-z8"
        (click)="addDiagnosis()"
        style="color: #2196f3"
      >
        + Search Diagnosis Code
      </button>
    </div>
  </div>

  <div class="row">
    <div
      class="col-md-12"
      *ngIf="diagnosisForm.controls['diagnosisCodes'].value.length == 0"
    >
      <mat-error>No diagnosis code has been assigned.</mat-error>
    </div>

    <mat-progress-bar
      mode="indeterminate"
      *ngIf="processing"
    ></mat-progress-bar>

    <div
      class="col-md-12"
      *ngIf="diagnosisForm.controls['diagnosisCodes'].value.length > 0"
    >
      <div class="table-responsive mat-elevation-z8">
        <table
          mat-table
          [dataSource]="diagnosisForm.controls['diagnosisCodes'].value"
        >
          <ng-container matColumnDef="diagnosisIndex">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row" style="color: #555555">
              {{ row?.diagnosisIndex }}
            </td>
          </ng-container>
          <ng-container matColumnDef="moveArrows">
            <th mat-header-cell *matHeaderCellDef></th>
            <td
              mat-cell
              *matCellDef="let row; let i = index"
              style="column-gap: 5px"
            >
              <button
                mat-mini-fab
                (click)="moveDiagnosis('UP', i)"
                style="margin: 5px; background: transparent; color: #555555"
              >
                <mat-icon> arrow_upward </mat-icon>
              </button>

              <button
                mat-mini-fab
                (click)="moveDiagnosis('DOWN', i)"
                style="margin: 5px; background: transparent; color: #555555"
              >
                <mat-icon> arrow_downward </mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="diagnosisCode">
            <th mat-header-cell *matHeaderCellDef>ICD Code</th>
            <td mat-cell *matCellDef="let row" style="max-width: 350px">
              {{ row.icdCode }}
            </td>
          </ng-container>

          <ng-container matColumnDef="diagnosisDescription">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let row">
              {{ row.shortDesc }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row; let i = index">
              <button
                mat-icon-button
                color="warn"
                (click)="removeDiagnosis(i)"
                type="button"
              >
                <mat-icon> cancel </mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="diagnosisColumn"></tr>
          <tr mat-row *matRowDef="let row; columns: diagnosisColumn"></tr>
        </table>
      </div>
    </div>

    <!-- Update Button -->
    <!-- <div
      class="col-md-12"
      *ngIf="
        therapySession.status === 'Active' &&
        (hasDiagnosisEditAccess || hasDiagnosisDeleteAccess)
      "
      style="text-align: right; margin-top: 20px"
    >
      <button
        mat-raised-button
        color="primary"
        type="button"
        [disabled]="diagnosisForm.invalid || processing"
        (click)="updateBillingDiagnosis()"
      >
        Update
        <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </div> -->
  </div>
</div>
