<mat-card
  appearance="raised"
  class="mat-elevation-z8"
  style="border-radius: 2px"
>
  <div class="row">
    <div class="col-md-8">
      <label class="labelHeader"> Subjectives </label>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12" [formGroup]="subjectiveForm" novalidate>
      <mat-form-field appearance="outline">
        <textarea
          matInput
          placeholder="Subjectives ... "
          formControlName="subjective"
          name="subjective"
          spellcheck="true"
          rows="20"
        ></textarea>
      </mat-form-field>
    </div>
  </div>
</mat-card>
