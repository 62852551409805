<div class="container">
  <div>
    <p matDialogTitle style="color: red; text-align: center">
      Delete Confirmation
    </p>
  </div>

  <p style="font-weight: 400; margin-top: 15px">
    {{ data.message }}
  </p>

  <mat-dialog-actions align="center">
    <button
      mat-raised-button
      [mat-dialog-close]="false"
      type="button"
      (click)="cancel()"
    >
      No
    </button>

    <button
      class="btn-primary"
      mat-raised-button
      [mat-dialog-close]="true"
      (click)="confirm()"
      cdkFocusInitial
    >
      Yes
    </button>
  </mat-dialog-actions>
</div>
