import { Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { ClaimsDetailsComponent } from './claims-details/claims-details.component';
import { ClaimsTransactionHistoryComponent } from './claims-transaction-history/claims-transaction-history.component';
import { ClaimsComponent } from './claims.component';
import { FileOtherClaimsComponent } from './file-other-claims/file-other-claims.component';
import { SecondaryClaimsHistoryComponent } from './secondary-claims-history/secondary-claims-history.component';

export const ClaimsRoutes: Routes = [
  {
    path: 'claims',
    children: [
      {
        path: '',
        component: ClaimsComponent,
        canActivate: [AuthGuard],
        data: {
          roles: ['physician', 'orgadmin', 'superorgadmin', 'careprovider'],
          excludeRoles: ['patient', 'inhospitaldevice'],
        },
      },
      {
        path: 'details/:id',
        component: ClaimsDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          roles: ['physician', 'orgadmin', 'superorgadmin', 'careprovider'],
          excludeRoles: ['patient', 'inhospitaldevice'],
        },
      },
      {
        path: 'history/:id',
        component: ClaimsTransactionHistoryComponent,
        canActivate: [AuthGuard],
        data: {
          roles: ['physician', 'orgadmin', 'superorgadmin', 'careprovider'],
          excludeRoles: ['patient', 'inhospitaldevice'],
        },
      },
      {
        path: 'secondary/:primaryId',
        component: FileOtherClaimsComponent,
        canActivate: [AuthGuard],
        data: {
          roles: ['physician', 'orgadmin', 'superorgadmin', 'careprovider'],
          excludeRoles: ['patient', 'inhospitaldevice'],
        },
      },
      {
        path: 'secondaryhistory/:secondaryClaimId',
        component: SecondaryClaimsHistoryComponent,
        canActivate: [AuthGuard],
        data: {
          roles: ['physician', 'orgadmin', 'superorgadmin', 'careprovider'],
          excludeRoles: ['patient', 'inhospitaldevice'],
        },
      },
    ],
  },
];
