import { HttpEventType } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UploadFilesService } from 'src/app/_services/';

@Component({
  selector: 'app-add-goal-dialog',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AddDocumentDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AddDocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public uploadFilesService: UploadFilesService
  ) {
    this.caseId = data.caseId;
    this.preSelectedFileType = data.preSelectedFileType;
  }

  public caseId: string;
  public preSelectedFileType: string;
  processing: boolean = false;
  progress = 0;
  documentForm: FormGroup;

  ngOnInit(): void {
    this.documentForm = new FormGroup({
      fileType: new FormControl(
        this.preSelectedFileType ? this.preSelectedFileType : null,
        Validators.required
      ),
      additionalInfo: new FormControl(null),
      image: new FormControl(null, [Validators.required]),
    });
  }

  submit() {
    this.processing = true;
    this.uploadFilesService
      .upload(
        this.caseId,
        this.documentForm.controls['fileType'].value,
        this.documentForm.controls['image'].value,
        this.documentForm.controls['additionalInfo'].value
      )
      .subscribe((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round((100 * event.loaded) / event.total);
        }

        if (event.type === HttpEventType.Response) {
          this.processing = false;
          this.dialogRef.close(this.documentForm.controls['fileType'].value);
        }
      });
  }
}
