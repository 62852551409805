<mat-card-title
  >Cultural, Spiritual, Leisure Activities, Member’s Strengths</mat-card-title
>
<mat-divider></mat-divider>

<div style="margin-top: 20px; padding: 10px">
  <form
    [formGroup]="bpsaclinicalculturaletcForm"
    novalidate
    (ngSubmit)="onBpsaClinicalCulturalEtcFormSubmit()"
  >
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >Do you have any cultural influences you would like me to know?
            <span style="color: red">*</span></label
          >&nbsp;
          <mat-button-toggle-group
            formControlName="culturalInfluences"
            (change)="checkCulturalInfluence()"
          >
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="Member Denies"
              >Member Denies</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
      <div
        class="row"
        *ngIf="bpsaclinicalculturaletcForm.value.culturalInfluences === 'Yes'"
      >
        <div class="col-md-12">
          <label class="labelText">
            Please explain <span style="color: red">*</span></label
          >
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="culturalInfluencesDetails"
              name="culturalInfluencesDetails"
            />
            <mat-error
              *ngIf="
                bpsaclinicalculturaletcForm.controls[
                  'culturalInfluencesDetails'
                ].invalid
              "
            >
              Please explain the cultural influences you would like me to know?
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >Do you identify with a specific religion?
            <span style="color: red">*</span></label
          >&nbsp;
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="religion"
              name="religion"
              required
            />
            <mat-error
              *ngIf="bpsaclinicalculturaletcForm.controls['religion'].invalid"
            >
              Please explain if you identify with a specific religion
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >Do you consider yourself <span style="color: red">*</span></label
          >&nbsp;
          <mat-button-toggle-group formControlName="beliefs">
            <mat-button-toggle value="Spiritual">Spiritual</mat-button-toggle>
            <mat-button-toggle value="Religious">Religious</mat-button-toggle>
            <mat-button-toggle value="Agnostic">Agnostic</mat-button-toggle>
            <mat-button-toggle value="Atheist">Atheist</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >What do you like to do in your spare time?
            <span style="color: red">*</span></label
          >&nbsp;
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="spareTimeActivity"
              name="spareTimeActivity"
              required
            />
            <mat-error
              *ngIf="
                bpsaclinicalculturaletcForm.controls['spareTimeActivity']
                  .invalid
              "
            >
              Please explain what you like to do in your spare time
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >When is the last time you engaged in these activities?
            <span style="color: red">*</span></label
          >&nbsp;
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="lastOccurenceOfSareTimeActivity"
              name="lastOccurenceOfSareTimeActivity"
              required
            />
            <mat-error
              *ngIf="
                bpsaclinicalculturaletcForm.controls[
                  'lastOccurenceOfSareTimeActivity'
                ].invalid
              "
            >
              Please explain the last time you engaged in these activities
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >What do you believe are some of your strengths or things you like
            about yourself? <span style="color: red">*</span></label
          >&nbsp;
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="strengths"
              name="strengths"
              required
            />
            <mat-error
              *ngIf="bpsaclinicalculturaletcForm.controls['strengths'].invalid"
            >
              Please explain some of your strengths or things you like about
              yourself
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
  </form>
</div>
