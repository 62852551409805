import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IPatientAssessmentGoal } from 'src/app/_models/assessments/patientassessmentgoal.model';
import { IAssessmentGoal } from 'src/app/_models/configure/assessment-goal.model';
import { environment } from '../../../environments/environment';
import { DataTablesModel } from '../../_models';

@Injectable({ providedIn: 'root' })
export class AssessmentGoalHttpService {
  constructor(private httpClient: HttpClient) {}

  getAssessmentGoals(
    assessmentType: string,
    pageSize: number,
    pageIndex: number,
    status: string
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('assessmentType', assessmentType);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());
    if (status) params = params.append('status', status);

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/assessment-goals`,
      { params: params }
    );
  }

  addAssessmentGoal(goal: IAssessmentGoal): Observable<IAssessmentGoal> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<IAssessmentGoal>(`${environment.apiUrl}/assessment-goal`, goal, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateAssessmentGoal(goal: IAssessmentGoal): Observable<IAssessmentGoal> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<IAssessmentGoal>(`${environment.apiUrl}/assessment-goal`, goal, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  deleteAssessmentGoal(goal: IAssessmentGoal): Observable<IAssessmentGoal> {
    return this.httpClient
      .delete<IAssessmentGoal>(
        `${environment.apiUrl}/assessment-goal/` + goal.id
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getPatientAssessmentGoals(
    assessmentHistoryId: string,
    assessmentRequestId: string,
    patientId: string,
    assessmentType: string,
    organizationId: string
  ): Observable<any> {
    let params = new HttpParams();
    params = params.append('assessmentHistoryId', assessmentHistoryId);
    params = params.append('assessmentRequestId', assessmentRequestId);
    params = params.append('patientId', patientId);
    params = params.append('assessmentType', assessmentType);
    params = params.append('organizationId', organizationId);

    return this.httpClient.get<any>(
      `${environment.apiUrl}/patient-assessment-goals`,
      { params: params }
    );
  }

  updateGoalNotes(goal: any, isPatient: boolean) {
    let endpoint = 'physician-assessment-goal-notes';

    if (isPatient) {
      endpoint = 'patient-assessment-goal-notes';
    }
    const headers = { 'content-type': 'application/json' };

    return this.httpClient
      .put<any>(`${environment.apiUrl}/${endpoint}`, goal, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  deletePatientAssessmentGoal(goal): Observable<any> {
    let params = new HttpParams();
    params = params.append('id', goal.id);
    params = params.append('organizationId', goal.organizationId);
    params = params.append('patientId', goal.patientId);

    return this.httpClient
      .delete<any>(`${environment.apiUrl}/patient-assessment-goals`, {
        params: params,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  addPatientAssessmentGoal(
    goal: IPatientAssessmentGoal
  ): Observable<IPatientAssessmentGoal> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<IPatientAssessmentGoal>(
        `${environment.apiUrl}/patient-assessment-goal`,
        goal,
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updatePatientAssessmentGoal(
    goal: IPatientAssessmentGoal
  ): Observable<IPatientAssessmentGoal> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<IPatientAssessmentGoal>(
        `${environment.apiUrl}/patient-assessment-goal`,
        goal,
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  markGoalAsComplete(
    goal: IPatientAssessmentGoal
  ): Observable<IPatientAssessmentGoal> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<IPatientAssessmentGoal>(
        `${environment.apiUrl}/patient-assessment-goal-complete`,
        goal,
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getPatientAssessmentWithGoals(
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/patient-assessment-with-goals`,
      { params: params }
    );
  }

  getSessionGoals(
    sessionId: string,
    sessionCode: string,
    patientId: string,
    pageSize: number,
    pageIndex: number,
    order: string,
    status: string,
    description: string
  ): Observable<any> {
    let params = new HttpParams();
    if (sessionId) params = params.append('sessionId', sessionId);
    if (sessionCode) params = params.append('sessionCode', sessionCode);
    if (order) params = params.append('order', order);
    if (status) params = params.append('status', status);
    if (description) params = params.append('description', description);

    params = params.append('patientId', patientId);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<any>(
      `${environment.apiUrl}/patient-session-goals`,
      { params: params }
    );
  }

  memberGetMyGoals(
    pageSize: number,
    pageIndex: number,
    order: string,
    status: string,
    description: string
  ): Observable<any> {
    let params = new HttpParams();

    if (order) params = params.append('order', order);
    if (status) params = params.append('status', status);
    if (description) params = params.append('description', description);

    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<any>(`${environment.apiUrl}/member-my-goals`, {
      params: params,
    });
  }
}
