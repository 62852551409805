import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DateTimeZoneService,
  RemittancesAPIService,
  ToastMessageService,
} from 'src/app/_services';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-remittances-details',
  templateUrl: './remittances-details.component.html',
  styleUrl: './remittances-details.component.css',
})
export class RemittancesDetailsComponent implements OnInit {
  remitId: string;
  isLoadingResults: boolean = false;
  apiError: boolean = false;

  remitDetails: any;
  processing: boolean = false;
  currentTimezone: string = '';

  displayedColumns = [
    'description',
    'claimStatusCode',
    'provider',
    'totalAmount',
    'paidAmount',
    'patientResponsibility',
    'actions',
  ];

  constructor(
    public router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private remittancesApiService: RemittancesAPIService,
    private toastMessageService: ToastMessageService,
    private dateTimeZoneService: DateTimeZoneService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit(): void {
    this.remitId = this.route.snapshot.paramMap.get('id');

    this.getRemitsDetails();
  }

  getRemitsDetails() {
    this.isLoadingResults = true;
    this.apiError = false;

    this.remittancesApiService.getRemitDetails(this.remitId).subscribe({
      next: (response) => {
        this.isLoadingResults = false;
        this.remitDetails = response.data;
      },
      error: (error) => {
        this.isLoadingResults = false;
        this.apiError = true;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to retrieve remits details.'
        );
      },
    });
  }

  editRemit() {
    this.router.navigate([
      '/remittances/remit/recordremit',
      this.remitDetails.id,
    ]);
  }

  needsReview() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Please Confirm',
        msg: 'Are you sure you want to mark this remittance as "Needs Review"?',
        yesButtonTitle: 'Yes',
        noButtonTitle: 'No',
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '20vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.processing = true;

        this.remittancesApiService
          .recordRemitReviewNeeded(this.remitId)
          .subscribe({
            next: (response) => {
              this.processing = false;
              this.remitDetails = response.data;
              this.toastMessageService.displaySuccessMessage(
                'Remittance marked for review.'
              );
            },
            error: (error) => {
              this.processing = false;
              this.toastMessageService.displayErrorMessage(
                'Error: Failed to record the payment.'
              );
            },
          });
      }
    });
  }

  recordPayment() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Record Payment',
        msg: 'Are you sure you want to record the payment?',
        yesButtonTitle: 'Yes',
        noButtonTitle: 'No',
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '20vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.processing = true;

        this.remittancesApiService.recordRemitPayment(this.remitId).subscribe({
          next: (response) => {
            this.processing = false;
            this.remitDetails = response.data;
            this.toastMessageService.displaySuccessMessage(
              'Remittance has been recorded.'
            );
          },
          error: (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to record the payment.'
            );
          },
        });
      }
    });
  }

  viewClaimResponse(row) {
    this.router.navigate(
      [
        `/remittances/remit/${this.remitId}/${this.remitDetails.paymentReferenceNumber}/${row.id}`,
      ],
      {
        queryParams: {
          parent: 'remittance',
        },
      }
    );
  }
}
