import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import { KeycloakService } from 'keycloak-angular';
import { DashboardsService, ToastMessageService } from 'src/app/_services';
import { ChartService } from 'src/app/_services/charts/chart.service';

@Component({
  selector: 'app-patient-dashboard-homepage',
  templateUrl: './patient-dashboard-homepage.component.html',
  styleUrl: './patient-dashboard-homepage.component.css',
})
export class PatientDashboardHomepageComponent implements OnInit {
  // Dashboards
  chartsProcessing: boolean = true;
  sdk: any;
  dashboard1: any;
  dashboard2: any;
  dashboard3: any;
  baseUrl: any;
  chartConfig: any;

  // Highlights Card
  isProcessing: boolean = true;

  highlightsCard: any = null;

  constructor(
    private readonly keycloak: KeycloakService,
    private readonly chartService: ChartService,
    private router: Router,
    private dashboardsService: DashboardsService,
    private toastMessageService: ToastMessageService
  ) { }

  ngOnInit(): void {
    this.baseUrl = this.chartService.getbaseUrl();
    this.chartConfig = this.chartService.getchartConfig();

    this.keycloak.getToken().then((token) => {
      this.sdk = new ChartsEmbedSDK({
        baseUrl: this.baseUrl,
        getUserToken: () => token,
      });

      this.dashboard1 = this.sdk.createChart({
        chartId: this.chartConfig.patientOverallAssessmentProgressChart,
        showAttribution: false,
        background: '#EEEEEE',
      });

      this.dashboard1.render(
        document.getElementById('patientOverallAssessmentProgressChart')
      );

      this.dashboard2 = this.sdk.createChart({
        chartId: this.chartConfig.patientTopTenAssesmentsChart,
        showAttribution: false,
        background: '#EEEEEE',
      });
      this.dashboard2.render(
        document.getElementById('patientTopTenAssesmentsChart')
      );

      this.dashboard3 = this.sdk.createChart({
        chartId: this.chartConfig.patientSessionsAttendedChart,
        showAttribution: false,
        background: '#EEEEEE',
      });
      this.dashboard3.render(
        document.getElementById('patientSessionsAttendedChart')
      );
    });

    this.getMemberDashboardStats();
  }

  getMemberDashboardStats() {
    this.isProcessing = true;

    this.dashboardsService.getMemberDashboardStats().subscribe({
      next: (response) => {
        this.isProcessing = false;

        if (response?.data) {
          this.highlightsCard = {
            title: 'Highlights',
            icon: 'dashboard',
            iconBackgroundColor: '#4677cc',
            cards: [
              {
                title: 'Pending Assessments',
                icon: 'pending_actions',
                iconBackgroundColor: '#42424ad9',
                count: response.data.pendingAssessmentCount,
                route: 'Pending_Assessments',
                cursor: 'pointer',
              },
              {
                title: 'Upcoming Appointments',
                icon: 'notifications_active',
                iconBackgroundColor: '#2196f3',
                count: response.data.upcomingSessionCount,
                route: 'Sessions',
                cursor: 'pointer',
              },
              {
                title: 'Active Goals',
                icon: 'adjust',
                iconBackgroundColor: '#3ac47d',
                count: response.data.activeGoalsCount,
                route: 'Goals',
                cursor: 'pointer',
              },
            ],
          };
        }
      },
      error: (error) => {
        this.isProcessing = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to load dashboards stats'
        );
      },
    });
  }

  // Navigate To
  navigateTo(route: string) {
    switch (route) {
      case 'Pending_Assessments':
        this.router.navigate(['/patientDashboard/Assessments']);
        break;

      case 'Sessions':
        this.router.navigate(['/patientDashboard/Sessions']);
        break;

      case 'Goals':
        this.router.navigate(['/patientDashboard/Goals']);
        break;
    }
  }
}
