export function assessmentGroups(): any[] {
  return [
    {
      name: 'Addiction',
      assessment: [
        {
          id: 'Alcohol Addiction Assessment',
          name: 'Alcohol Addiction',
          description: 'CAGE Alcohol Questionnaire (CAGE)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Addiction Severity Index (ASI)',
          name: 'Addiction Severity Index (ASI)',
          description:
            "Addiction Severity Index (ASI) is helpful to gauge the severity of a person's substance abuse and provides a comprehensive overview of a person's addiction-related issues.",
          enabled: false,
          inSessionOnly: true,
        },
        {
          id: 'Columbia-Suicide Severity Rating Scale (CSSRS)',
          name: 'Columbia-Suicide Severity Rating Scale (CSSRS)',
          description:
            'Columbia-Suicide Severity Rating Scale (C-SSRS) supports suicide risk screening through a series of simple, plain-language questions that anyone can ask. The answers help users identify whether someone is at risk for suicide, determine the severity and immediacy of that risk, and gauge the level of support that the person needs',
          enabled: true,
          inSessionOnly: true,
        },
        {
          id: 'Clinical Opiate Withdrawal Scale (COWS)',
          name: 'Clinical Opiate Withdrawal Scale (COWS)',
          description:
            'The Clinical Opiate Withdrawal Scale (COWS) is an 11-item scale designed to be administered by a clinician. This tool can be used in both inpatient and outpatient settings to reproducibly rate common signs and symptoms of opiate withdrawal and monitor these symptoms over time. The summed score for the complete scale can be used to help clinicians determine the stage or severity of opiate withdrawal and assess the level of physical dependence on opioids. ',
          enabled: true,
          inSessionOnly: true,
        },
        {
          id: 'Clinical Institute Withdrawal Assessment of Alcohol Scale, Revised (CIWA-AR)',
          name: 'Clinical Institute Withdrawal Assessment of Alcohol Scale, Revised (CIWA-AR)',
          description:
            'The CIWA-AR scale has been carefully refined to a list of 10 signs and symptoms. It is thus easy to use and has been shown to be feasible to use in a variety of clinical settings, including detoxification units, psychiatry units, and general medical/surgical wards. The CIWA-Ar has added usefulness because high scores, in addition to indicating severe withdrawal, are also predictive of the development of seizures and delirium.',
          enabled: true,
          inSessionOnly: true,
        },
        {
          id: 'DSM-5-TR Level 2 - Substance Use | Age 11-17',
          name: 'DSM-5-TR Level 2 - Substance Use | Age 11-17',
          description: 'DSM-5-TR Level 2 - Substance Use | Age 11-17',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Gambling Addiction Assessment',
          name: 'Gambling Disorders',
          description: 'Diagnostic Screen for Gambling Disorders (NODS-CLIP)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Gaming Addiction Assessment',
          name: 'Gaming Addiction',
          description: 'Excessive Gaming Screening Tool (EGST)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Internet Addiction Assessment',
          name: 'Internet Addiction',
          description: 'Internet Addiction Assessment (IAA)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Sexual Addiction Assessment',
          name: 'Sexual Addiction',
          description: 'Sexual Addiction Screening Test (SAST)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Shopping Addiction Assessment',
          name: 'Shopping Addiction',
          description: 'Bergen Shopping Addiction Scale (BSAS)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'TAPS-I & TAPS-II',
          name: 'TAPS-I & TAPS-II',
          description:
            'The Tobacco, Alcohol, Prescription medications, and other Substance (TAPS) Tool',
          enabled: true,
          inSessionOnly: false,
        },
      ],
    },
    {
      name: 'ADHD',
      assessment: [
        {
          id: 'Adult ADHD Self-Report Scale',
          name: 'Self-Report Scale',
          description: 'Adult ADHD Self-Report Scale (ASRSv1.1)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Vanderbilt ADHD Diagnostic Rating Scale (VADRS)',
          name: 'Vanderbilt ADHD Diagnostic Rating Scale (VADRS)',
          description: 'Vanderbilt ADHD Diagnostic Rating Scale (VADRS)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'The Mindful Attention Awareness Scale (MAAS)',
          name: 'The Mindful Attention Awareness Scale (MAAS)',
          description: 'The Mindful Attention Awareness Scale (MAAS)',
          enabled: true,
          inSessionOnly: false,
        },
      ],
    },
    {
      name: 'Aggression',
      assessment: [
        {
          id: 'Buss Perry Aggression Questionnaire',
          name: 'Aggression Questionnaire',
          description: 'Buss Perry Aggression Questionnaire (BPAQ)',
          enabled: true,
          inSessionOnly: false,
        },
      ],
    },

    {
      name: 'Anxiety',
      assessment: [
        {
          id: 'Generalized Anxiety Disorder 7 Item Scale',
          name: 'Generalized Anxiety Disorder 7 Item Scale (GAD-7)',
          description: 'Generalized Anxiety Disorder 7 Item Scale (GAD-7)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Hamilton Anxiety Rating Scale',
          name: 'Hamilton Anxiety Rating Scale (HAM-A)',
          description: 'Hamilton Anxiety Rating Scale (HAM-A)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Panic and Agoraphobia Scale',
          name: 'Panic and Agoraphobia Scale (PAS)',
          description: 'Panic and Agoraphobia Scale (PAS)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Penn State Worry Questionnaire',
          name: 'Penn State Worry Questionnaire (PSWQ)',
          description: 'Penn State Worry Questionnaire (PSWQ)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Short Health Anxiety Inventory',
          name: 'Short Health Anxiety Inventory (HAI-18)',
          description: 'Short Health Anxiety Inventory (HAI-18)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Social Phobia Inventory',
          name: 'Social Phobia Inventory (SPIN)',
          description: 'Social Phobia Inventory (SPIN)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: "Spence Children's Anxiety Scale",
          name: "Spence Children's Anxiety Scale (SCAS)",
          description: "Spence Children's Anxiety Scale (SCAS)",
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Taylor Manifest Anxiety Scale',
          name: 'Taylor Manifest Anxiety Scale (TMAS)',
          description: 'Taylor Manifest Anxiety Scale (TMAS)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Zung Self-Rating Scale',
          name: 'Zung Self-Rating Anxiety Scale (SAS)',
          description: 'Zung Self-Rating Anxiety Scale (SAS)',
          enabled: true,
          inSessionOnly: false,
        },
      ],
    },
    {
      name: 'Autism Spectrum',
      assessment: [
        {
          id: 'Autism Spectrum Quotient',
          name: 'Autism Spectrum Quotient (AQ)',
          description: 'Autism Spectrum Quotient (AQ)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Autism Spectrum Screening Questionnaire',
          name: 'Autism Spectrum Screening Questionnaire (ASSQ)',
          description: 'Autism Spectrum Screening Questionnaire (ASSQ)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Childhood Autism Spectrum Test',
          name: 'Childhood Autism Spectrum Test (CAST)',
          description: 'Childhood Autism Spectrum Test (CAST)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Infant-Toddler Checklist',
          name: 'Infant-Toddler Checklist (ITC)',
          description: 'Infant-Toddler Checklist (ITC)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Quantitative Checklist for Autism in Toddlers',
          name: 'Quantitative Checklist for Autism in Toddlers (Q-CHAT)',
          description: 'Quantitative Checklist for Autism in Toddlers (Q-CHAT)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Ritvo Autism & Asperger Diagnostic Scale',
          name: 'Ritvo Autism & Asperger Diagnostic Scale (RAADS-14)',
          description: 'Ritvo Autism & Asperger Diagnostic Scale (RAADS-14)',
          enabled: true,
          inSessionOnly: false,
        },
      ],
    },
    {
      name: 'Bipolar',
      assessment: [
        {
          id: 'Altman Self-Rating Mania Scale',
          name: 'Altman Self-Rating Mania Scale (ASRM)',
          description: 'Altman Self-Rating Mania Scale (ASRM)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Young Mania Rating Scale',
          name: 'Young Mania Rating Scale (YMRS)',
          description: 'Young Mania Rating Scale (YMRS)',
          enabled: true,
          inSessionOnly: false,
        },
      ],
    },
    {
      name: 'Depression',
      assessment: [
        {
          id: 'Edinburgh Postnatal Depression Scale (EPDS)',
          name: 'Edinburgh Postnatal Depression Scale (EPDS)',
          description: 'Edinburgh Postnatal Depression Scale (EPDS)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Geriatric Depression Scale (GDS)',
          name: 'Geriatric Depression Scale (GDS)',
          description: 'Geriatric Depression Scale (GDS)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Kutcher Adolescent Depression Scale',
          name: 'Kutcher Adolescent Depression Scale - 11-Item (KADS-11)',
          description:
            'Kutcher Adolescent Depression Scale - 11-Item (KADS-11)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Major Depression Inventory',
          name: 'Major Depression Inventory (MDI)',
          description: 'Major Depression Inventory (MDI)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Patient Health Questionnaire',
          name: 'Patient Health Questionnaire (PHQ-9)',
          description: 'Patient Health Questionnaire (PHQ-9)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Zung Self-Rating Depression Scale',
          name: 'Zung Self-Rating Depression Scale (SDS)',
          description: 'Zung Self-Rating Depression Scale (SDS)',
          enabled: true,
          inSessionOnly: false,
        },
      ],
    },

    {
      name: 'Eating Disorders',
      assessment: [
        {
          id: 'Binge Eating Scale',
          name: 'Binge Eating Scale (BES)',
          description: 'Binge Eating Scale (BES)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Eating Attitudes Test',
          name: 'Eating Attitudes Test',
          description: 'Eating Attitudes Test - 26 Item (EAT-26)',
          enabled: true,
          inSessionOnly: false,
        },
      ],
    },
    {
      name: 'General',
      assessment: [
        {
          id: 'DSM 5 Level 1 Adult Assessment',
          name: 'DSM 5 Level 1 Adult Assessment',
          description: 'DSM 5 Level 1 Adult Assessment',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'DSM 5 Level 1 (11-17) Assessment',
          name: 'DSM 5 Level 1 (11-17) Assessment',
          description: 'DSM 5 Level 1 (11-17) Assessment',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'DSM 5 Level 1 (6-17) Assessment',
          name: 'DSM 5 Level 1 (6-17) Assessment',
          description: 'DSM 5 Level 1 (6-17) Assessment',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'CORE 10 Assessment',
          name: 'CORE 10 Assessment',
          description: 'CORE 10 Assessment',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'YP-CORE 10 Assessment',
          name: 'YP-CORE 10 Assessment',
          description:
            'Youth Core-10 Assessment for Adolescent Client (age 11-17)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Connor-Davidson Resilience Scale(CD-RISC 10)',
          name: 'Connor-Davidson Resilience Scale(CD-RISC 10)',
          description:
            'Connor-Davidson Resilience Scale(CD-RISC 10) is intended to measure resilience.',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Survey of Wellbeing of Young Children',
          name: 'Survey of Wellbeing of Young Children',
          description:
            "First-level screener that monitors risk for developmental-behavioral disorders in children up to 5 ½ years old. It is not a diagnostic tool - instead, the SWYC is a parent-report instrument primarily designed to maximize the amount of information that can be elicited reliably from parents before they meet with their child's pediatric primary care provider (PPCP). ",
          enabled: false,
          inSessionOnly: false,
        },
        {
          id: 'Differentiation of Self Inventory - Short Form',
          name: 'Differentiation of Self Inventory - Short Form',
          description: 'Differentiation of Self Inventory - Short Form',
          enabled: true,
          inSessionOnly: false,
        },
      ],
    },
    {
      name: 'OCD',
      assessment: [
        {
          id: 'Obsessive Compulsive Inventory - Revised',
          name: 'Obsessive Compulsive Inventory - Revised (OCI-R)',
          description: 'Obsessive Compulsive Inventory - Revised (OCI-R)',
          enabled: true,
          inSessionOnly: false,
        },
      ],
    },
    {
      name: 'Personality',
      assessment: [
        {
          id: 'Big 5 Personality Test',
          name: 'Big 5 Personality Test (BFPT)',
          description: 'Big 5 Personality Test (BFPT)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Empathy Quotient',
          name: 'Empathy Quotient (EQ)',
          description: 'Empathy Quotient (EQ)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Hare Psychopathy Checklist',
          name: 'Hare Psychopathy Checklist (Original) (PCL-22)',
          description: 'Hare Psychopathy Checklist (Original) (PCL-22)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Personality Type Indicator',
          name: 'Personality Type Indicator (PTI)',
          description: 'Personality Type Indicator (PTI)',
          enabled: false,
          inSessionOnly: false,
        },
        {
          id: 'Revised Mystical Experience Questionnaire',
          name: 'Revised Mystical Experience Questionnaire (MEQ-30)',
          description: 'Revised Mystical Experience Questionnaire (MEQ-30)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Toronto Empathy Questionnaire',
          name: 'Toronto Empathy Questionnaire (TEQ)',
          description: 'Toronto Empathy Questionnaire (TEQ)',
          enabled: true,
          inSessionOnly: false,
        },
      ],
    },
    {
      name: 'Relationship / Family',
      assessment: [
        {
          id: 'Adult Attachment Scale (AAS)',
          name: 'Adult Attachment Scale (AAS)',
          description:
            'The AAS scale is a self report instrument reflecting attachment-related attitudes. The dimensional scales of the AAS assess to what extent a person feels close to his partner, to what extent he is able to rely on others, and how much someone is afraid of losing an intimate partner.',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Aversive Childhood Experiences Scale (ACES)',
          name: 'Aversive Childhood Experiences Scale (ACES)',
          description: 'Aversive Childhood Experiences Scale (ACES)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Child-Parent Relationship Scale (CPRS)',
          name: 'Child-Parent Relationship Scale (CPRS)',
          description:
            'Child-Parent Relationship Scale (CPRS). This assemment battery is useful for Adolescent Clients (Age 11-17), Child Clients (Age 0 - 11), Family Therapy Clients, and Couples Therapy Clients(If Couple is raising children)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Family APGAR',
          name: 'Family APGAR',
          description:
            "Family APGAR is a tool that measures important elements of a family system that can be helpful in assessing a family's resources and functionality in responding to the vicissitudes and challenges in their lives.",
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'The Couples Satisfaction Index (CSI)',
          name: 'The Couples Satisfaction Index (CSI)',
          description: 'The Couples Satisfaction Index (CSI)',
          enabled: true,
          inSessionOnly: false,
        },
      ],
    },
    {
      name: 'Stress',
      assessment: [
        {
          id: 'Inventory of Complicated Grief',
          name: 'Inventory of Complicated Grief (ICG)',
          description: 'Inventory of Complicated Grief (ICG)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Primary Care PTSD Screen',
          name: 'Primary Care PTSD Screen (DSM-5) (PC-PTSD-5)',
          description: 'Primary Care PTSD Screen (DSM-5) (PC-PTSD-5)',
          enabled: true,
          inSessionOnly: false,
        },
        {
          id: 'Trauma-Related Shame Assessment',
          name: 'Trauma-Related Shame Inventory (TRSI-24)',
          description: 'Trauma-Related Shame Inventory (TRSI-24)',
          enabled: true,
          inSessionOnly: false,
        },
      ],
    }
  ];
}
