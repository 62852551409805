import { createSelector } from '@ngrx/store';
import { State } from '..';
import { VisitReasonsState } from '../state';

export const getVisitReasons = (state: State) => state.visitReasons;

export const getVisitReasonData = createSelector(
  getVisitReasons,
  (state: VisitReasonsState) => state.visitReasons
);

export const getVisitReasonDataSuccess = createSelector(
  getVisitReasons,
  (state: VisitReasonsState) => state.visitReasonsSuccess
);

export const getVisitReasonDataError = createSelector(
  getVisitReasons,
  (state: VisitReasonsState) => state.visitReasonsError
);

export const getVisitReasonDataLoading = createSelector(
  getVisitReasons,
  (state: VisitReasonsState) => state.visitReasonsLoading
);
