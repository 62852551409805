import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { IAlcoholAddiction } from 'src/app/_models/assessments/addiction/alcoholaddiction.model';
import {
  AlcoholAddictionApiService,
  ToastMessageService,
} from 'src/app/_services';

@Component({
  selector: 'app-alcohol-addiction',
  templateUrl: './alcohol-addiction.component.html',
  styleUrls: ['./alcohol-addiction.component.css'],
})
export class AlcoholAddictionComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public alcoholAddictionApiService: AlcoholAddictionApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) {}
  public riskScore: number;
  public alcoholAddictionForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  alcoholAddiction: IAlcoholAddiction;
  alcoholAddictionError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.alcoholAddictionForm, this.errors);

    this.loadAlcoholAddictionAssessment();
  }

  loadAlcoholAddictionAssessment() {
    this.alcoholAddictionForm.controls['patientId'].setValue(this.patientId);
    if (this.assessmentId) {
      this.alcoholAddictionApiService
        .getAlcoholAddictionDetails(this.assessmentId)
        .subscribe((alcoholaddiction) => {
          if (alcoholaddiction.data) {
            this.alcoholAddictionForm.controls['cutDownOnDrinking'].setValue(
              alcoholaddiction.data.cutDownOnDrinking.answer
            );
            this.alcoholAddictionForm.controls[
              'criticizingDrinkingHabits'
            ].setValue(alcoholaddiction.data.criticizingDrinkingHabits.answer);
            this.alcoholAddictionForm.controls['guiltyAboutDrinking'].setValue(
              alcoholaddiction.data.guiltyAboutDrinking.answer
            );
            this.alcoholAddictionForm.controls[
              'drinkFirstThingInTheMorning'
            ].setValue(
              alcoholaddiction.data.drinkFirstThingInTheMorning.answer
            );
            this.riskScore = alcoholaddiction.data.riskScore;
          }
        });
    }
  }
  onAlcoholAddictionFormSubmit() {
    if (this.alcoholAddictionForm.invalid) {
      this.alcoholAddictionForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.alcoholAddiction = Object.assign({}, this.alcoholAddiction);
    this.alcoholAddiction.patientId =
      this.alcoholAddictionForm.controls['patientId'].value;
    this.alcoholAddiction.sessionId =
      this.alcoholAddictionForm.controls['sessionId'].value;
    this.alcoholAddiction.assessmentRequestId =
      this.alcoholAddictionForm.controls['assessmentRequestId'].value;
    this.alcoholAddiction.cutDownOnDrinking = {
      answer: this.alcoholAddictionForm.controls['cutDownOnDrinking'].value,
    };
    this.alcoholAddiction.criticizingDrinkingHabits = {
      answer:
        this.alcoholAddictionForm.controls['criticizingDrinkingHabits'].value,
    };
    this.alcoholAddiction.guiltyAboutDrinking = {
      answer: this.alcoholAddictionForm.controls['guiltyAboutDrinking'].value,
    };
    this.alcoholAddiction.drinkFirstThingInTheMorning = {
      answer:
        this.alcoholAddictionForm.controls['drinkFirstThingInTheMorning'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.alcoholAddiction.accessCode = this.accessCode;

      this.alcoholAddictionApiService
        .addGuestLinkAlcoholAddiction(this.alcoholAddiction)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Alcohol Addiction Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Alcohol Addiction Assessment'
            );
          }
        );
    } else {
      this.alcoholAddictionApiService
        .addAlcoholAddiction(this.alcoholAddiction)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Alcohol Addiction Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Alcohol Addiction Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    cutDownOnDrinking: [
      {
        type: 'required',
        message:
          'Have you ever felt you needed to Cut down on your drinking? is required',
      },
    ],
    criticizingDrinkingHabits: [
      {
        type: 'required',
        message:
          'Have people Annoyed you by criticizing your drinking? is required',
      },
    ],
    guiltyAboutDrinking: [
      {
        type: 'required',
        message: 'Have you ever felt Guilty about drinking? is required',
      },
    ],
    drinkFirstThingInTheMorning: [
      {
        type: 'required',
        message:
          'Have you ever felt you needed a drink first thing in the morning (Eye-opener) to steady your nerves or to get rid of a hangover? is required',
      },
    ],
  };

  private buildForm() {
    this.alcoholAddictionForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      cutDownOnDrinking: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      criticizingDrinkingHabits: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      guiltyAboutDrinking: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      drinkFirstThingInTheMorning: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.alcoholAddictionForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.alcoholAddictionForm) {
      return;
    }
    const form = this.alcoholAddictionForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
