import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { IQuantitativeChecklist } from 'src/app/_models/assessments/autism/quantitativechecklist.model';
import {
  QuantitativeChecklistApiService,
  ToastMessageService,
} from 'src/app/_services/';

@Component({
  selector: 'app-quantitative-checklist',
  templateUrl: './quantitative-checklist.component.html',
  styleUrls: ['./quantitative-checklist.component.css'],
})
export class QuantitativeChecklistComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public quantitativeChecklistApiService: QuantitativeChecklistApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) {}
  public riskScore: number;
  public quantitativeChecklistForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  quantitativeChecklistAssessment: IQuantitativeChecklist;
  quantitativeChecklistAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.quantitativeChecklistForm, this.errors);

    this.loadQuantitativeChecklistAssessment();
  }

  loadQuantitativeChecklistAssessment() {
    this.quantitativeChecklistForm.controls['patientId'].setValue(
      this.patientId
    );

    if (this.assessmentId) {
      this.quantitativeChecklistApiService
        .getQuantitativeChecklistDetails(this.assessmentId)
        .subscribe((quantitativeChecklistAssessment) => {
          if (quantitativeChecklistAssessment.data) {
            this.quantitativeChecklistForm.controls['q1'].setValue(
              quantitativeChecklistAssessment.data.q1.answer
            );
            this.quantitativeChecklistForm.controls['q2'].setValue(
              quantitativeChecklistAssessment.data.q2.answer
            );
            this.quantitativeChecklistForm.controls['q3'].setValue(
              quantitativeChecklistAssessment.data.q3.answer
            );
            this.quantitativeChecklistForm.controls['q4'].setValue(
              quantitativeChecklistAssessment.data.q4.answer
            );
            this.quantitativeChecklistForm.controls['q5'].setValue(
              quantitativeChecklistAssessment.data.q5.answer
            );
            this.quantitativeChecklistForm.controls['q6'].setValue(
              quantitativeChecklistAssessment.data.q6.answer
            );
            this.quantitativeChecklistForm.controls['q7'].setValue(
              quantitativeChecklistAssessment.data.q7.answer
            );
            this.quantitativeChecklistForm.controls['q8'].setValue(
              quantitativeChecklistAssessment.data.q8.answer
            );
            this.quantitativeChecklistForm.controls['q9'].setValue(
              quantitativeChecklistAssessment.data.q9.answer
            );
            this.quantitativeChecklistForm.controls['q10'].setValue(
              quantitativeChecklistAssessment.data.q10.answer
            );
            this.quantitativeChecklistForm.controls['q11'].setValue(
              quantitativeChecklistAssessment.data.q11.answer
            );
            this.quantitativeChecklistForm.controls['q12'].setValue(
              quantitativeChecklistAssessment.data.q12.answer
            );
            this.quantitativeChecklistForm.controls['q13'].setValue(
              quantitativeChecklistAssessment.data.q13.answer
            );
            this.quantitativeChecklistForm.controls['q14'].setValue(
              quantitativeChecklistAssessment.data.q14.answer
            );
            this.quantitativeChecklistForm.controls['q15'].setValue(
              quantitativeChecklistAssessment.data.q15.answer
            );
            this.quantitativeChecklistForm.controls['q16'].setValue(
              quantitativeChecklistAssessment.data.q16.answer
            );
            this.quantitativeChecklistForm.controls['q17'].setValue(
              quantitativeChecklistAssessment.data.q17.answer
            );
            this.quantitativeChecklistForm.controls['q18'].setValue(
              quantitativeChecklistAssessment.data.q18.answer
            );
            this.quantitativeChecklistForm.controls['q19'].setValue(
              quantitativeChecklistAssessment.data.q19.answer
            );
            this.quantitativeChecklistForm.controls['q20'].setValue(
              quantitativeChecklistAssessment.data.q20.answer
            );
            this.quantitativeChecklistForm.controls['q21'].setValue(
              quantitativeChecklistAssessment.data.q21.answer
            );
            this.quantitativeChecklistForm.controls['q22'].setValue(
              quantitativeChecklistAssessment.data.q22.answer
            );
            this.quantitativeChecklistForm.controls['q23'].setValue(
              quantitativeChecklistAssessment.data.q23.answer
            );
            this.quantitativeChecklistForm.controls['q24'].setValue(
              quantitativeChecklistAssessment.data.q24.answer
            );
            this.quantitativeChecklistForm.controls['q25'].setValue(
              quantitativeChecklistAssessment.data.q25.answer
            );

            this.riskScore = quantitativeChecklistAssessment.data.riskScore;
          }
        });
    }
  }

  onFormSubmit() {
    if (this.quantitativeChecklistForm.invalid) {
      this.quantitativeChecklistForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.quantitativeChecklistAssessment = Object.assign(
      {},
      this.quantitativeChecklistAssessment
    );
    this.quantitativeChecklistAssessment.patientId =
      this.quantitativeChecklistForm.controls['patientId'].value;
    this.quantitativeChecklistAssessment.sessionId =
      this.quantitativeChecklistForm.controls['sessionId'].value;
    this.quantitativeChecklistAssessment.assessmentRequestId =
      this.quantitativeChecklistForm.controls['assessmentRequestId'].value;
    this.quantitativeChecklistAssessment.q1 = {
      answer: this.quantitativeChecklistForm.controls['q1'].value,
    };
    this.quantitativeChecklistAssessment.q2 = {
      answer: this.quantitativeChecklistForm.controls['q2'].value,
    };
    this.quantitativeChecklistAssessment.q3 = {
      answer: this.quantitativeChecklistForm.controls['q3'].value,
    };
    this.quantitativeChecklistAssessment.q4 = {
      answer: this.quantitativeChecklistForm.controls['q4'].value,
    };
    this.quantitativeChecklistAssessment.q5 = {
      answer: this.quantitativeChecklistForm.controls['q5'].value,
    };
    this.quantitativeChecklistAssessment.q6 = {
      answer: this.quantitativeChecklistForm.controls['q6'].value,
    };
    this.quantitativeChecklistAssessment.q7 = {
      answer: this.quantitativeChecklistForm.controls['q7'].value,
    };
    this.quantitativeChecklistAssessment.q8 = {
      answer: this.quantitativeChecklistForm.controls['q8'].value,
    };
    this.quantitativeChecklistAssessment.q9 = {
      answer: this.quantitativeChecklistForm.controls['q9'].value,
    };
    this.quantitativeChecklistAssessment.q10 = {
      answer: this.quantitativeChecklistForm.controls['q10'].value,
    };
    this.quantitativeChecklistAssessment.q11 = {
      answer: this.quantitativeChecklistForm.controls['q11'].value,
    };
    this.quantitativeChecklistAssessment.q12 = {
      answer: this.quantitativeChecklistForm.controls['q12'].value,
    };
    this.quantitativeChecklistAssessment.q13 = {
      answer: this.quantitativeChecklistForm.controls['q13'].value,
    };
    this.quantitativeChecklistAssessment.q14 = {
      answer: this.quantitativeChecklistForm.controls['q14'].value,
    };
    this.quantitativeChecklistAssessment.q15 = {
      answer: this.quantitativeChecklistForm.controls['q15'].value,
    };
    this.quantitativeChecklistAssessment.q16 = {
      answer: this.quantitativeChecklistForm.controls['q16'].value,
    };
    this.quantitativeChecklistAssessment.q17 = {
      answer: this.quantitativeChecklistForm.controls['q17'].value,
    };
    this.quantitativeChecklistAssessment.q18 = {
      answer: this.quantitativeChecklistForm.controls['q18'].value,
    };
    this.quantitativeChecklistAssessment.q19 = {
      answer: this.quantitativeChecklistForm.controls['q19'].value,
    };
    this.quantitativeChecklistAssessment.q20 = {
      answer: this.quantitativeChecklistForm.controls['q20'].value,
    };
    this.quantitativeChecklistAssessment.q21 = {
      answer: this.quantitativeChecklistForm.controls['q21'].value,
    };
    this.quantitativeChecklistAssessment.q22 = {
      answer: this.quantitativeChecklistForm.controls['q22'].value,
    };
    this.quantitativeChecklistAssessment.q23 = {
      answer: this.quantitativeChecklistForm.controls['q23'].value,
    };
    this.quantitativeChecklistAssessment.q24 = {
      answer: this.quantitativeChecklistForm.controls['q24'].value,
    };
    this.quantitativeChecklistAssessment.q25 = {
      answer: this.quantitativeChecklistForm.controls['q25'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.quantitativeChecklistAssessment.accessCode = this.accessCode;

      this.quantitativeChecklistApiService
        .addGuestQuantitativeChecklist(this.quantitativeChecklistAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted quantitative-checklist Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting quantitative-checklist Assessment'
            );
          }
        );
    } else {
      this.quantitativeChecklistApiService
        .addQuantitativeChecklist(this.quantitativeChecklistAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted quantitative-checklist Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting quantitative-checklist Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    q1: [
      {
        type: 'required',
        message:
          '1. Does your child look at you when you call his/her name? is required',
      },
    ],
    q2: [
      {
        type: 'required',
        message:
          '2. How easy is it for you to get eye contact with your child? is required',
      },
    ],
    q3: [
      {
        type: 'required',
        message:
          '3. When your child is playing alone, does s/he line objects up? is required',
      },
    ],
    q4: [
      {
        type: 'required',
        message:
          "4. Can other people easily understand your child's speech? is required",
      },
    ],
    q5: [
      {
        type: 'required',
        message:
          '5. Does your child point to indicate that s/he wants something (e.g. a toy that is out of reach)? is required',
      },
    ],
    q6: [
      {
        type: 'required',
        message:
          '6. Does your child point to share interest with you (e.g. pointing at an interesting sight)? is required',
      },
    ],
    q7: [
      {
        type: 'required',
        message:
          "7. How long can your child's interest be maintained by a spinning object (e.g. washing machine, electric fan, toy car wheels)? is required",
      },
    ],
    q8: [
      {
        type: 'required',
        message: '8. How many words can your child say? is required',
      },
    ],
    q9: [
      {
        type: 'required',
        message:
          '9. Does your child pretend (egg care for dolls, talk on a toy phone)? is required',
      },
    ],
    q10: [
      {
        type: 'required',
        message: "10. Does your child follow where you're looking? is required",
      },
    ],
    q11: [
      {
        type: 'required',
        message:
          '11. How often does your child sniff or lick unusual objects? is required',
      },
    ],
    q12: [
      {
        type: 'required',
        message:
          '12. Does your child place your hand on an object when s/he wants you to use it (e.g. on a door handle when s/he wants you to open the door, on a toy when s/he wants you to activate it)? is required',
      },
    ],
    q13: [
      {
        type: 'required',
        message: '13. Does your child walk on tiptoe? is required',
      },
    ],
    q14: [
      {
        type: 'required',
        message:
          '14. How easy is it for your child to adapt when his/her routine changes or when things are out of their usual place? is required',
      },
    ],
    q15: [
      {
        type: 'required',
        message:
          '15. If you or someone else in the family is visibly upset, does your child show signs of wanting to comfort them (e.g. stroking their hair, hugging them)? is required',
      },
    ],
    q16: [
      {
        type: 'required',
        message:
          '16. Does your child do the same thing over and over again (e.g. running the tap, turning the light switch on and off, opening and closing doors)? is required',
      },
    ],
    q17: [
      {
        type: 'required',
        message:
          "17. Would you describe your child's first words as: is required",
      },
    ],
    q18: [
      {
        type: 'required',
        message:
          '18. Does your child echo things s/he hears (e.g. things that you say, lines from songs or movies, sounds)? is required',
      },
    ],
    q19: [
      {
        type: 'required',
        message:
          '19. Does your child use simple gestures (e.g. wave goodbye)? is required',
      },
    ],
    q20: [
      {
        type: 'required',
        message:
          '20. Does your child make unusual finger movements near his/her eyes? is required',
      },
    ],
    q21: [
      {
        type: 'required',
        message:
          '21. Does your child spontaneously look at your face to check your reaction when faced with something unfamiliar? is required',
      },
    ],
    q22: [
      {
        type: 'required',
        message:
          "22. How long can your child's interest be maintained by just one or two objects? is required",
      },
    ],
    q23: [
      {
        type: 'required',
        message:
          '23. Does your child twiddle objects repetitively (e.g. pieces of string)? is required',
      },
    ],
    q24: [
      {
        type: 'required',
        message: '24. Does your child seem oversensitive to noise? is required',
      },
    ],
    q25: [
      {
        type: 'required',
        message:
          '25. Does your child stare at nothing with no apparent purpose? is required',
      },
    ],
  };

  private buildForm() {
    this.quantitativeChecklistForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      q1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q2: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q3: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q4: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q5: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q6: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q7: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q8: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q9: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q10: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q11: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q12: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q13: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q14: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q15: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q16: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q17: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q18: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q19: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q20: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q21: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q22: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q23: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q24: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q25: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.quantitativeChecklistForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.quantitativeChecklistForm) {
      return;
    }
    const form = this.quantitativeChecklistForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
