import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { AssessmentEditFollowupComponent } from './assessment-edit-followup/assessment-edit-followup.component';

@Component({
  selector: 'app-assessment-follow-up',
  templateUrl: './assessment-follow-up.component.html',
  styleUrls: ['./assessment-follow-up.component.css'],
})
export class AssessmentFollowUpComponent implements OnInit {
  goal: any;
  isPatient: boolean = false;
  reloadRequired: string = 'No';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AssessmentFollowUpComponent>,
    private dialog: MatDialog
  ) {
    this.goal = data.goal;
    this.isPatient = data.isPatient;
  }

  ngOnInit(): void {}

  addEditNote(index) {
    let addEditFollowUpNote = this.dialog.open(
      AssessmentEditFollowupComponent,
      {
        data: { goal: this.goal, index: index },
        autoFocus: false,

        minWidth: '35vw',
      }
    );
    addEditFollowUpNote.afterClosed().subscribe((response) => {
      if (response.response === 'success') {
        this.reloadRequired = 'reload';
        this.goal.followUpDetails = response.newGoal.followUpDetails;
      }
    });
  }
}
