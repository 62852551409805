export const ClaimStatusCodeFor835List = [
  {
    id: '1',
    description: 'Processed as Primary',
  },
  {
    id: '2',
    description: 'Processed as Secondary',
  },
  {
    id: '3',
    description: 'Processed as Tertiary',
  },
  {
    id: '4',
    description: 'Denied',
  },
  {
    id: '19',
    description: 'Processed as Primary, Forwarded to Additional Payer(s)',
  },
  {
    id: '20',
    description: 'Processed as Secondary, Forwarded to Additional Payer(s)',
  },
  {
    id: '21',
    description: 'Processed as Tertiary, Forwarded to Additional Payer(s)',
  },
  {
    id: '22',
    description: 'Reversal of Previous Payment',
  },
  {
    id: '23',
    description: 'Not Our Claim, Forwarded to Additional Payer(s)',
  },
  {
    id: '25',
    description: 'Predetermination Pricing Only',
  },
];
