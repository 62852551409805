import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ITherapySession, Patient } from 'src/app/_models';
import { PatientService, ToastMessageService } from 'src/app/_services';

@Component({
  selector: 'app-intake-assign-clinician',
  templateUrl: './intake-assign-clinician.component.html',
  styleUrls: ['./intake-assign-clinician.component.css'],
})
export class IntakeAssignClinicianComponent implements OnInit {
  @Input() therapySession: ITherapySession;
  @Input() patient: Patient;
  @Input() hasBPSAIntakeViewAccess: boolean;
  @Input() hasBPSAIntakeAddAccess: boolean;
  @Input() hasBPSAIntakeEditAccess: boolean;
  @Output() updatedTherapySession = new EventEmitter<any>();

  processing: boolean = true;
  noActivePhysician: boolean = true;
  physicianList: any = [];

  constructor(
    private patientService: PatientService,
    private toastMessageService: ToastMessageService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loadPhysiciansBasedOnPatient();
  }

  loadPhysiciansBasedOnPatient() {
    this.processing = true;
    this.physicianList = [];

    this.patientService
      .getActiveProvidersAtMemberFacility(this.patient.id)
      .subscribe(
        (response) => {
          if (response) {
            if (response && response?.items) {
              this.noActivePhysician = false;
              this.physicianList = response.items;
            } else {
              this.noActivePhysician = true;
              this.toastMessageService.displayInfoMessage(
                'There is no active clinician for the selected patient facility'
              );
            }
          }
          this.processing = false;
        },
        (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load clinicians'
          );
          this.processing = false;
        }
      );
  }
}
