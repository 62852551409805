<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle *ngIf="type === 'Consent'">Upload Consent Form</p>
    <p matDialogTitle *ngIf="type === 'Session'">Upload Session Document</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="consentForm" (ngSubmit)="submit()">
    <div class="row">
      <div class="col-md-12">
        <input
          class="file-upload"
          type="file"
          (change)="onFileSelected($event)"
        />
        <br />

        <span
          style="font-weight: 400; font-size: 10px; color: red"
          *ngIf="fileSizeExceeded"
        >
          The uploaded file exceeds the maximum file size
        </span>
        <br />
        <span
          style="font-weight: 400; font-size: 10px"
          *ngIf="type === 'Consent'"
        >
          *** Uploading a new document will overwrite the existing version
        </span>
      </div>

      <div class="col-md-12" style="text-align: right">
        <button
          class="btn btn-primary"
          mat-raised-button
          type="submit"
          [disabled]="!consentForm.valid || processing"
        >
          Submit
          <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
