<!-- Search Box -->
<div
  class="row"
  style="
    margin-left: 5px;
    margin-right: 5px;
    row-gap: 15px;
    margin-bottom: 15px;
    margin-top: 15px;
  "
>
  <div class="col-sm-12 col-md-12">
    <div class="row" style="row-gap: 15px; column-gap: 15px; flex-wrap: wrap">
      <!-- Date Picker -->
      <div
        class="col"
        style="
          display: flex;
          align-items: center;
          border-radius: 10px;
          border: 1px solid rgb(0, 0, 0, 0.2);
          background-color: #ffffff;
          min-width: 300px;
        "
      >
        <span
          class="material-icons"
          style="text-align: left; font-size: 20px; opacity: 0.5"
        >
          calendar_month
        </span>
        <mat-date-range-input
          [rangePicker]="picker"
          matTooltip="To search, please select the date range and click on the search icon"
        >
          <input
            matStartDate
            placeholder="Date Range"
            (click)="picker.open()"
            [(ngModel)]="searchStartDate"
          />
          <input
            matEndDate
            [(ngModel)]="searchEndDate"
            (click)="picker.open()"
          />
        </mat-date-range-input>
        <button
          matIconSuffix
          type="button"
          mat-icon-button
          (click)="searchChanged()"
        >
          <mat-icon>search</mat-icon>
        </button>
        <button
          matIconSuffix
          type="button"
          mat-icon-button
          (click)="clearDate()"
        >
          <mat-icon>cancel</mat-icon>
        </button>
        <mat-date-range-picker
          #picker
          [disabled]="false"
        ></mat-date-range-picker>
      </div>

      <div
        *ngIf="!insuranceId"
        class="col"
        style="
          display: flex;
          align-items: center;
          padding: 5px;
          border-radius: 10px;
          border: 1px solid rgb(0, 0, 0, 0.2);
          background-color: #ffffff;
          padding: 5px;
          min-width: 140px;
        "
      >
        <span
          class="material-icons"
          style="text-align: left; font-size: 20px; opacity: 0.5"
        >
          pin
        </span>
        <input
          type="search"
          placeholder="Member Name"
          [(ngModel)]="memberName"
          (ngModelChange)="searchChanged()"
          style="
            padding-left: 15px;
            width: 100%;
            background: transparent;
            border: 0;
          "
        />
      </div>

      <div
        *ngIf="!insuranceId"
        class="col"
        style="
          display: flex;
          align-items: center;
          padding: 5px;
          border-radius: 10px;
          border: 1px solid rgb(0, 0, 0, 0.2);
          background-color: #ffffff;
          padding: 5px;
          min-width: 140px;
        "
      >
        <span
          class="material-icons"
          style="text-align: left; font-size: 20px; opacity: 0.5"
        >
          search
        </span>
        <input
          type="search"
          placeholder="Payer Name"
          [(ngModel)]="payerName"
          (ngModelChange)="searchChanged()"
          style="
            padding-left: 15px;
            width: 100%;
            background: transparent;
            border: 0;
          "
        />
      </div>
    </div>
  </div>

  <!-- <div
    class="col-sm-12 col-md-3"
    style="
      column-gap: 20px;
      display: flex;
      flex-wrap: wrap;
      text-align: right;
      flex-direction: row-reverse;
      padding: 0;
      row-gap: 10px;
    "
  >
 
    <button mat-raised-button [matMenuTriggerFor]="sortMenu">
      <span style="white-space: break-spaces"> Status {{ searchStatus }} </span>
      <mat-icon matSuffix>arrow_drop_down</mat-icon>
    </button>

    <mat-menu #sortMenu="matMenu">
      <div *ngFor="let option of searchStatusList">
        <button
          mat-menu-item
          style="font-weight: 400; font-size: 12px; width: 100%"
          (click)="optionChanged(option)"
        >
          {{ option }}
        </button>
      </div>
    </mat-menu>
  </div> -->
</div>

<!-- Progress bar -->
<div *ngIf="processing || apiError">
  <mat-progress-bar mode="indeterminate" *ngIf="processing"></mat-progress-bar>

  <mat-error *ngIf="apiError">
    An error occurred while retrieving eligibility records
  </mat-error>
</div>

<!-- No record found bar -->
<div *ngIf="data.total === 0 && !processing">
  <mat-error> No Eligibility Check records found </mat-error>
</div>

<!-- Table container -->
<div class="mat-elevation-z8" *ngIf="data.total > 0">
  <div class="table-responsive">
    <table mat-table [dataSource]="data.items" matSort matSortDisableClear>
      <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let row">
          <label class="labelBold">
            {{ row.createdDate | date : 'M/d/y, h:mm a' : currentTimezone }}
            {{ currentTimezone }}
          </label>
        </td>
      </ng-container>

      <ng-container matColumnDef="memberName">
        <th mat-header-cell *matHeaderCellDef>Member</th>
        <td mat-cell *matCellDef="let row">
          <label class="labelBold" *ngIf="row?.memberLastName">
            {{ row?.memberLastName }}, {{ row?.memberFirstName }}
          </label>
          <label
            class="labelBold"
            *ngIf="!row?.memberLastName && row?.request?.subscriber?.lastName"
          >
            {{ row.request.subscriber.lastName }}
            {{ row.request.subscriber.firstName }}
          </label>
          <br />
          <label *ngIf="row?.memberDOB">
            {{ row?.memberDOB }}
          </label>
        </td>
      </ng-container>

      <ng-container matColumnDef="subscriber">
        <th mat-header-cell *matHeaderCellDef>Subscriber</th>
        <td mat-cell *matCellDef="let row">
          <label class="labelBold" *ngIf="row?.subscriberLastName">
            {{ row?.subscriberLastName }}, {{ row?.subscriberFirstName }}
          </label>
          <label
            class="labelBold"
            *ngIf="
              !row?.subscriberLastName && row?.request?.subscriber?.lastName
            "
          >
            {{ row.request.subscriber.lastName }}
            {{ row.request.subscriber.firstName }}
          </label>
          <br />
          <label *ngIf="row?.subscriberDOB">
            {{ row?.subscriberDOB }}
          </label>
        </td>
      </ng-container>

      <ng-container matColumnDef="payer">
        <th mat-header-cell *matHeaderCellDef>Payer</th>
        <td mat-cell *matCellDef="let row">
          <label class="labelBold">{{ row?.payerName }}</label>
          <br />
          <label *ngIf="row?.payerCode">
            Payer ID : {{ row?.payerCode }}
          </label>
        </td>
      </ng-container>

      <ng-container matColumnDef="serviceType">
        <th mat-header-cell *matHeaderCellDef>Service Type</th>
        <td mat-cell *matCellDef="let row">
          {{ row.request.encounter.serviceTypeCodes[0] }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let row">
          <ng-container
            *ngIf="
              row?.status === 'Success' && row?.response?.errors?.length === 0
            "
          >
            <span
              *ngIf="
                row.response?.planStatus?.length > 0 &&
                row.response?.planStatus[0].statusCode === '1'
              "
              class="successStatus"
            >
              {{ row.response?.planStatus[0].status }}
            </span>
          </ng-container>
          <ng-container
            *ngIf="
              row?.status !== 'Success' && row?.response?.errors?.length === 0
            "
          >
            <span>
              {{ row.response?.planStatus[0].status }}
            </span>
          </ng-container>
          <span *ngIf="row?.response?.errors?.length > 0" class="errorStatus">
            Failed Request
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef style="text-align: right">
          Action
        </th>
        <td
          mat-cell
          *matCellDef="let row; let i = index"
          style="text-align: right"
        >
          <button
            mat-icon-button
            type="button"
            [color]="
              row?.status !== 'Success' || row?.response?.errors?.length > 0
                ? 'warn'
                : 'primary'
            "
            (click)="displayEligibilityTransactionDetails(row)"
            matTooltip="Preview"
          >
            <mat-icon> preview </mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <mat-paginator
    *ngIf="data.total > data.per_page"
    #paginator
    [showFirstLastButtons]="true"
    [length]="data.total"
    [pageSize]="data.per_page"
    (page)="getNext($event)"
  >
  </mat-paginator>
</div>
