import { createSelector } from '@ngrx/store';
import { State } from '..';
import { PermissionState } from './../state/permission.state';

export const getPermissionDataFeature = (state: State) => state.permission;

export const getMyPermissionsData = createSelector(
  getPermissionDataFeature,
  (state: PermissionState) => state.payload
);
export const getMyPermissionsDataError = createSelector(
  getPermissionDataFeature,
  (state: PermissionState) => state.processingError
);
export const getMyPermissionsDataLoading = createSelector(
  getPermissionDataFeature,
  (state: PermissionState) => state.processingRequest
);
