export const MedicationFrequency = [
  'Every 30 minutes',
  'Every hour',
  'Every 4 hour',
  'Every 8 hour',
  'Every 12 hour',
  '1 x Day',
  '2 x Day',
  '3 x Day',
  '4 x Day',
  '5 x Day',
  '6 x Day',
  '7 x Day',
  '8 x Day',
  '9 x Day',
  '10 x Day',
  '11 x Day',
  '12 x Day',
  'Once Every 2 Days',
  'Once Every 3 Days',
  'Once Every 4 Days',
  'Once Every 5 Days',
  'Once Every 6 Days',
  'Once Every 7 Days',
  'Once Every 8 Days',
  'Once Every 9 Days',
  'Once Every 10 Days',
  'Once Every 11 Days',
  'Once Every 12 Days',
  'Once Every 13 Days',
  'Once Every 14 Days',
  'Once Every Month',
  'Once Every 2 Months',
  'Once Every 3 Months',
  'Once Every 4 Months',
  'Once Every 5 Months',
  'Once Every 6 Months',
  'Once Every Year',
  'Once Every 2 Years',
];
