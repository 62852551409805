import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IAsiMedical } from 'src/app/_models/assessments/asi/asimedical.model';
import { AsiApiService } from 'src/app/_services/assessments/asi/asi.service';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-asi-medical',
  templateUrl: './asi-medical.component.html',
  styleUrls: ['./asi-medical.component.css']
})

export class AsiMedicalComponent implements OnInit {
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentRequestId: string;

  constructor(
    private asiApiService: AsiApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute) {
  }

  public action: string;
  public asimedicalForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  asimedical: IAsiMedical;
  asimedicalError: Error = null;
  errors: any = {};
  processing: boolean = false;
  systemGeneratedScore: number;

  public ngOnInit() {
    this.buildForm();
    this.errorHandler.handleErrors(this.asimedicalForm, this.errors);

    this.loadAsiMedical();
  }


  loadAsiMedical() {
    this.asiApiService.getAsiDetails(this.assessmentRequestId).subscribe(response => {
      if (response.data) {
        this.asimedicalForm.controls['m1'].setValue(response.data.asiMedical.m1);
        this.asimedicalForm.controls['m2'].setValue(response.data.asiMedical.m2);
        this.asimedicalForm.controls['m3'].setValue(response.data.asiMedical.m3);
        this.asimedicalForm.controls['m4'].setValue(response.data.asiMedical.m4);
        this.asimedicalForm.controls['m5'].setValue(response.data.asiMedical.m5);
        this.asimedicalForm.controls['m6'].setValue(response.data.asiMedical.m6);
        this.asimedicalForm.controls['m7'].setValue(response.data.asiMedical.m7);
        this.asimedicalForm.controls['m8'].setValue(response.data.asiMedical.m8);
        this.asimedicalForm.controls['m9'].setValue(response.data.asiMedical.m9);
        this.asimedicalForm.controls['m10'].setValue(response.data.asiMedical.m10);
        this.asimedicalForm.controls['m11'].setValue(response.data.asiMedical.m11);
        this.asimedicalForm.controls['comments'].setValue(response.data.asiMedical.comments);
        this.asimedicalForm.controls['clinicianScore'].setValue(response.data.asiMedical.clinicianScore);
      }
    });
  }
  onAsiMedicalFormSubmit() {
    if (this.asimedicalForm.invalid) {
      this.asimedicalForm.markAllAsTouched();
      return;
    }
    this.asimedical = Object.assign({}, this.asimedical);
    this.asimedical = Object.assign(this.asimedical, this.asimedicalForm.value);
    this.asiApiService.updateAsiMedical(this.asimedical).subscribe(() => {
    })
  };


  public validation_messages = {
    'clinicianScore': [
      { type: 'required', message: 'Clinician Score is required' },
    ],
    'm1': [
      { type: 'required', message: 'M1. How many times in your life have you been hospitalized for medical problems? is required' },
    ],
    'm2': [
      { type: 'required', message: 'M2. How long ago was your last hospitalization for  __ __ / __ __ a physical problem? is required' },
    ],
    'm3': [
      { type: 'required', message: 'M3. Do you have any chronic medical problems which continue to interfere with your life? is required' },
    ],
    'm4': [
      { type: 'required', message: 'M4. Are you taking any prescribed medication on a regular basis for a physical problem? is required' },
    ],
    'm5': [
      { type: 'required', message: 'M5. Do you receive a pension for a physical disability? is required' },
    ],
    'm6': [
      { type: 'required', message: 'M6. How many days have you experienced medical problems in the past 30 days?  is required' },
    ],
    'm7': [
      { type: 'required', message: 'M7. How troubled or bothered have you been by these medical problems in the past 30 days? is required' },
    ],
    'm8': [
      { type: 'required', message: 'M8. How important to you now is treatment for these medical problems?  is required' },
    ],
    'm9': [
      { type: 'required', message: 'M9. How would you rate the patient\'s need for medical treatment?  is required' },
    ],
    'm10': [
      { type: 'required', message: 'M10. Patient\'s  misrepresentation? is required' },
    ],
    'm11': [
      { type: 'required', message: 'M11. Patient\'s inability to understand?  is required' },
    ],
    'comments': [
      { type: 'required', message: 'Comments is required' },
    ],
  };

  private buildForm() {
    this.asimedicalForm = this.formBuilder.group({
      clinicianScore: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      systemScore: new FormControl(0, Validators.compose([
      ])),
      sessionId: new FormControl(this.sessionId, Validators.compose([
        Validators.required,
      ])),
      patientId: new FormControl(this.patientId, Validators.compose([
        Validators.required,
      ])),
      assessmentRequestId: new FormControl(this.assessmentRequestId, Validators.compose([
        Validators.required,
      ])),
      m1: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      m2: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      m3: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      m4: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      m5: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      m6: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      m7: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      m8: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      m9: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      m10: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      m11: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      comments: new FormControl('', Validators.compose([
        Validators.required,
      ])),
    });
    this.asimedicalForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }


  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.asimedicalForm) {
      return;
    }
    const form = this.asimedicalForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }

}