import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ITherapySession, Patient } from 'src/app/_models';
import { SearchSupervisingProviderComponent } from './search-supervising-provider/search-supervising-provider.component';

@Component({
  selector: 'app-edit-supervising-provider',
  templateUrl: './edit-supervising-provider.component.html',
  styleUrls: ['./edit-supervising-provider.component.css'],
})
export class EditSupervisingProviderComponent implements OnInit {
  therapySession: ITherapySession;
  parentClass: string = '';
  patient: Patient;
  passwordView: boolean = false;
  supervisingRPForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<EditSupervisingProviderComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    this.therapySession = data.therapySession;
    this.parentClass = data.parentClass;
    this.patient = data.patient;
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.supervisingRPForm = this.formBuilder.group({
      supervisingRPId: new FormControl(
        this.therapySession?.supervisingRPId,
        Validators.required
      ),
      supervisingRPFirstName: new FormControl(
        this.therapySession?.supervisingRPFirstName,
        Validators.required
      ),
      supervisingRPMiddleName: new FormControl(
        this.therapySession?.supervisingRPMiddleName
      ),
      supervisingRPLastName: new FormControl(
        this.therapySession?.supervisingRPLastName,
        Validators.required
      ),
      supervisingRPEmail: new FormControl(
        this.therapySession?.supervisingRPEmail,
        Validators.required
      ),
      supervisingRPPhone: new FormControl(
        this.therapySession?.supervisingRPPhone,
        Validators.compose([
          Validators.required,
          RxwebValidators.mask({ mask: '(999) 999-9999' }),
        ])
      ),
      supervisingRPTaxonomyCode: new FormControl(
        this.therapySession?.supervisingRPTaxonomyCode
      ),
      supervisingRPNPI: new FormControl(
        this.therapySession?.supervisingRPNPI,
        Validators.required
      ),
      supervisingRPBillingOption: new FormControl(
        this.therapySession?.supervisingRPBillingOption
      ),
      supervisingRPTaxId: new FormControl(
        this.therapySession?.supervisingRPTaxId
      ),
      supervisingRPSSN: new FormControl(this.therapySession?.supervisingRPSSN),
    });

    if (this.parentClass === 'Claims') {
      this.supervisingRPForm
        .get('supervisingRPBillingOption')
        .addValidators(Validators.required);
      this.supervisingRPForm.updateValueAndValidity();
    }
  }

  billingOptionChanged(event) {
    if (event.value === 'Tax Id') {
      this.supervisingRPForm
        .get('supervisingRPTaxId')
        .addValidators(Validators.required);
      this.supervisingRPForm.get('supervisingRPTaxId').updateValueAndValidity();

      this.supervisingRPForm.get('supervisingRPSSN').clearValidators();
      this.supervisingRPForm.get('supervisingRPSSN').setValue('');
      this.supervisingRPForm.get('supervisingRPSSN').updateValueAndValidity();
    } else {
      this.supervisingRPForm
        .get('supervisingRPSSN')
        .addValidators(Validators.required);
      this.supervisingRPForm.get('supervisingRPSSN').updateValueAndValidity();

      this.supervisingRPForm.get('supervisingRPTaxId').clearValidators();
      this.supervisingRPForm.get('supervisingRPTaxId').setValue('');
      this.supervisingRPForm.get('supervisingRPTaxId').updateValueAndValidity();
    }
  }

  searchSupervisingProvider() {
    let supervisorDialog = this.dialog.open(
      SearchSupervisingProviderComponent,
      {
        data: {
          sessionId: this.therapySession.id,
          patient: this.patient,
          parentClass: this.parentClass,
        },
        disableClose: true,
        autoFocus: false,
        minWidth: '60vw',
      }
    );
    supervisorDialog.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        let supervisorDetails = response.details;

        this.supervisingRPForm.controls['supervisingRPId'].setValue(
          supervisorDetails.id
        );
        this.supervisingRPForm.controls['supervisingRPFirstName'].setValue(
          supervisorDetails.firstName
        );
        this.supervisingRPForm.controls['supervisingRPMiddleName'].setValue(
          supervisorDetails.middleName
        );
        this.supervisingRPForm.controls['supervisingRPLastName'].setValue(
          supervisorDetails.lastName
        );
        this.supervisingRPForm.controls['supervisingRPEmail'].setValue(
          supervisorDetails.emailAddress
        );
        this.supervisingRPForm.controls['supervisingRPPhone'].setValue(
          supervisorDetails.phoneNumber
        );
        this.supervisingRPForm.controls['supervisingRPTaxonomyCode'].setValue(
          supervisorDetails.taxonomyCode
        );
        this.supervisingRPForm.controls['supervisingRPNPI'].setValue(
          supervisorDetails.individualNPI
        );
        this.supervisingRPForm.controls['supervisingRPBillingOption'].setValue(
          supervisorDetails.billingOption
        );
        this.supervisingRPForm.controls['supervisingRPTaxId'].setValue(
          supervisorDetails.taxId
        );
        this.supervisingRPForm.controls['supervisingRPSSN'].setValue(
          supervisorDetails.ssn
        );
      }
    });
  }

  submitForm() {
    let details = Object.assign({}, this.supervisingRPForm.value);
    this.dialogRef.close({ type: 'success', details: details });
  }
}
