import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { AngularMaterialModule } from 'src/app/material-imports';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { EulaAgreementComponent } from './eula-agreement/eula-agreement.component';

const routes: Routes = [
  {
    path: 'eula',
    component: EulaAgreementComponent
  }
]

@NgModule({
  declarations: [
    EulaAgreementComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    PipesModule,
    RouterModule.forChild(routes)
  ],

})
export class PagesWithoutTopBarModule { }
