<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>CORE 10 Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="acesAssessmentForm"
    novalidate
    (ngSubmit)="onAcesFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-10 asterix-after"
            >1. Did a parent or other adult in the household often or very often
            Swear at you, insult you, put you down, or humiliate you? Or Act in
            a way that made you afraid that you might be physically hurt?</label
          >
          <div class="col-sm-2">
            <mat-button-toggle-group formControlName="verbalOrPhysicalFear">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-10 asterix-after"
            >2. Did a parent or other adult in the household often or very
            often… Push, grab, slap, or throw something at you? Or Ever hit you
            so hard that you had marks or were injured?</label
          >
          <div class="col-sm-2">
            <mat-button-toggle-group formControlName="physicalAbuseMarks">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-10 asterix-after"
            >3. Did an adult or person at least 5 years older than you
            ever…Touch or fondle you or have you touch their body in a sexual
            way? Or Attempt or actually have oral or anal intercourse with
            you?</label
          >
          <div class="col-sm-2">
            <mat-button-toggle-group formControlName="sexualTouchOrAssault">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-10 asterix-after"
            >4. Did you often or very often feel that …No one in your family
            loved you or thought you were important or special? Or Your family
            didn't look out for each other, feel close to each other, or support
            each other?</label
          >
          <div class="col-sm-2">
            <mat-button-toggle-group formControlName="lackFamilyLoveSupport">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-10 asterix-after"
            >5. Did you often or very often feel that …You didn't have enough to
            eat, had to wear dirty clothes, and had no one to protect you? or
            Your parents were too drunk or high to take care of you or take you
            to the doctor if you needed it?</label
          >
          <div class="col-sm-2">
            <mat-button-toggle-group formControlName="neglectOrCareLack">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-10 asterix-after"
            >6. Was a biological parent ever lost to you through divorced,
            abandonment, or other reason?</label
          >
          <div class="col-sm-2">
            <mat-button-toggle-group formControlName="parentalLoss">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-10 asterix-after"
            >7. Was your mother or stepmother:Often or very often pushed,
            grabbed, slapped, or had something thrown at her? Or Sometimes,
            often, or very often kicked, bitten, hit with a fist, or hit with
            something hard? or Ever repeatedly hit over at least a few minutes
            or threatened with a gun or knife?</label
          >
          <div class="col-sm-2">
            <mat-button-toggle-group formControlName="motherAbuse">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-10 asterix-after"
            >8. Did you live with anyone who was a problem drinker or alcoholic
            or who used street drugs?</label
          >
          <div class="col-sm-2">
            <mat-button-toggle-group formControlName="substanceAbuseHome">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-10 asterix-after"
            >9. Was a household member depressed or mentally ill? Or Did a
            household member attempt suicide?</label
          >
          <div class="col-sm-2">
            <mat-button-toggle-group formControlName="familyMentalIllOrSuicide">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-10 asterix-after"
            >10. Did a household member go to prison?</label
          >
          <div class="col-sm-2">
            <mat-button-toggle-group formControlName="familyMemberPrison">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          acesAssessmentForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!acesAssessmentForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
