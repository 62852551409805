import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-template-dialog',
  templateUrl: './template-dialog.component.html',
  styleUrl: './template-dialog.component.css',
})
export class TemplateDialogComponent implements OnInit, OnDestroy {
  form: FormGroup;
  templateText: string = '';
  templateName: string = '';
  placeholders: any[] = [];
  output: string;
  formChangesSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<TemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.templateText = data.template.text;
    this.templateName = data.template.templateName;
  }

  public ngOnInit() {
    this.placeholders = this.getPlaceholders(this.templateText);
    const formControls = this.placeholders.reduce((acc, curr) => {
      acc[curr.name] = new FormControl('', Validators.required);
      return acc;
    }, {});

    this.form = new FormGroup(formControls);
    this.output = this.templateText;

    this.formChangesSubscription = this.form.valueChanges.subscribe(() => {
      this.output = this.substitutePlaceholders();
    });
  }

  ngOnDestroy(): void {
    this.formChangesSubscription.unsubscribe();
  }

  getPlaceholders(template: string): any[] {
    const regex = /\$\{([^\}]+)_([^\}]+)\}/g;
    let match;
    const placeholders = [];

    while ((match = regex.exec(template)) !== null) {
      placeholders.push({
        name: match[1],
        type: match[2],
        originalName: match[1] + '_' + match[2],
      });
    }

    return placeholders;
  }

  substitutePlaceholders(): string {
    let resultText = this.templateText;
    this.placeholders.forEach((placeholder) => {
      const value = this.form.controls[placeholder.name].value;
      if (value) {
        resultText = resultText.replace(
          new RegExp(`\\$\{${placeholder.originalName}\}`, 'g'),
          value
        );
      }
    });
    return resultText;
  }

  onSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.dialogRef.close(this.output);
  }
}
