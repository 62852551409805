<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Edit Note Type</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      [mat-dialog-close]="false"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="row">
    <div class="col-md-12">
      <mat-form-field appearance="outline">
        <mat-select
          placeholder="Select note type"
          [(ngModel)]="newNotetype"
          [ngModelOptions]="{ standalone: true }"
        >
          <mat-option
            *ngFor="let noteType of clinicalNoteTypes"
            [value]="noteType"
          >
            {{ noteType.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-12">
      <p style="font-weight: 400; margin-top: 15px; text-align: center">
        Are you sure you want to change the exisiting note type? Existing note
        changes will be lost.
      </p>
      <p
        style="
          margin-top: 15px;
          font-size: 12px;
          font-style: italic;
          font-weight: 400;
          color: #333333;
        "
      >
        * Only 1 note type can be associated with an appointment.
      </p>
    </div>
  </div>

  <div class="row">
    <div
      class="col-md-12"
      style="display: flex; justify-content: space-between"
    >
      <button
        mat-raised-button
        [mat-dialog-close]="false"
        type="button"
        (click)="cancel()"
      >
        Cancel
      </button>

      <button
        color="warn"
        mat-raised-button
        (click)="confirm()"
        cdkFocusInitial
        [disabled]="processing || !newNotetype?.id"
      >
        Proceed
        <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </div>
  </div>
</div>
