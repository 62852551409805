import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { DataTablesModel, Patient } from 'src/app/_models';
import {
  DateTimeZoneService,
  DiagnosisApiService,
  IcdCodeService,
  ToastMessageService,
} from 'src/app/_services';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { AddDiagnosisComponent } from './add-diagnosis/add-diagnosis.component';
import { EditDiagnosisComponent } from './edit-diagnosis/edit-diagnosis.component';

@Component({
  selector: 'app-diagnosis-codes',
  templateUrl: './diagnosis-codes.component.html',
  styleUrls: ['./diagnosis-codes.component.css'],
})
export class DiagnosisCodesComponent implements OnInit, OnChanges {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() patient: Patient;
  @Input() hasDiagnosisViewAccess: boolean;
  @Input() hasDiagnosisAddAccess: boolean;
  @Input() hasDiagnosisEditAccess: boolean;
  @Input() hasDiagnosisDeleteAccess: boolean;

  displayedColumns = ['icdCode', 'description', 'statusDate', 'status'];

  data: DataTablesModel = {} as DataTablesModel;
  status = 'Active';
  isLoadingResults = false;
  currentTimezone: string;

  constructor(
    public dialog: MatDialog,
    public diagnosisApiService: DiagnosisApiService,
    public icdCodeService: IcdCodeService,
    private toastMessageService: ToastMessageService,
    private dateTimeZoneService: DateTimeZoneService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit() {
    if (this.hasDiagnosisEditAccess || this.hasDiagnosisDeleteAccess) {
      this.displayedColumns.push('action');
    }

    this.loadDiagnosisList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.patient) {
      if (this.patient) {
        this.data.page = 0;
        this.data.per_page = 10;

        this.loadDiagnosisList();
      }
    }
  }

  filterOptionChanged(status) {
    this.status = status;
    this.data.page = 0;
    this.loadDiagnosisList();
  }

  public loadDiagnosisList() {
    this.isLoadingResults = true;

    this.diagnosisApiService
      .getDiagnosisList(
        this.patient.id,
        this.status,
        this.data.per_page,
        this.data.page
      )
      .subscribe({
        next: (response) => {
          this.isLoadingResults = false;
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }
        },
        error: (error) => {
          this.isLoadingResults = false;
        },
      });
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadDiagnosisList();
  }

  addDiagnosis() {
    const dialogRef = this.dialog.open(AddDiagnosisComponent, {
      data: {
        patient: this.patient,
        parentClass: 'Member',
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '50vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        this.loadDiagnosisList();
      }
    });
  }

  editDiagnosis(diagnosis) {
    const dialogRef = this.dialog.open(EditDiagnosisComponent, {
      data: { patient: this.patient, diagnosis: diagnosis },
      autoFocus: false,
      disableClose: true,
      minWidth: '35vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response && response === 'success') {
        this.loadDiagnosisList();
      }
    });
  }

  deleteDiagnosis(row) {
    let dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: {
        message: 'Are you sure you want to remove this diagnosis?',
      },
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.isLoadingResults = true;
        this.diagnosisApiService
          .deleteDiagnosis(row.id, row.patientId)
          .subscribe({
            next: (response) => {
              this.isLoadingResults = false;
              this.toastMessageService.displaySuccessMessage(
                'Diagnosis ' + row.icdCode + ' has been deleted'
              );
              if (this.data.items.length == 1) {
                this.data.page = this.data.page - 1;
                if (this.data.page < 0) {
                  this.data.page = 0;
                }
              }
              this.loadDiagnosisList();
            },
            error: (error) => {
              this.isLoadingResults = false;
              this.toastMessageService.displayErrorMessage(
                'Error: Failed to delete diagnosis.'
              );
            },
          });
      }
    });
  }
}
