import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { DataTablesModel } from '../_models';

@Injectable({ providedIn: 'root' })
export class OrgDocumentCategoriesApiService {
  constructor(private httpClient: HttpClient) {}

  getOrgDocumentCategoriesList(
    pageIndex: number,
    pageSize: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/orgdocumentcategorieslist`,
      { params: params }
    );
  }
}
