import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ICssrs } from 'src/app/_models/assessments/addiction/cssrs.model';
import {
  ToastMessageService,
} from 'src/app/_services';
import { CssrsApiService } from 'src/app/_services/assessments/addiction/cssrs.service';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-cssrs',
  templateUrl: './cssrs.component.html',
  styleUrl: './cssrs.component.css'
})
export class CssrsComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public cssrsApiService: CssrsApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public riskLevel: string;
  public cssrsForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  cssrs: ICssrs;
  cssrsError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;
  showQ3ThruQ5Questions: boolean = false;
  showQ7Question: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.cssrsForm, this.errors);

    this.loadCssrsAssessment();
  }

  loadCssrsAssessment() {
    this.cssrsForm.controls['patientId'].setValue(this.patientId);
    if (this.assessmentId) {
      this.cssrsApiService
        .getCssrsDetails(this.assessmentId)
        .subscribe((cssrs) => {
          if (cssrs.data) {
            this.cssrsForm.controls['wishToBeDead'].setValue(cssrs.data.wishToBeDead.answer);
            this.cssrsForm.controls['nonSpecificActiveSuicidalThoughts'].setValue(cssrs.data.nonSpecificActiveSuicidalThoughts.answer);
            this.cssrsForm.controls['activeSuicidalIdeationWithAnyMethodsNotPlanWithoutIntentToAct'].setValue(cssrs.data.activeSuicidalIdeationWithAnyMethodsNotPlanWithoutIntentToAct.answer);
            this.cssrsForm.controls['activeSuicidalIdeationWithSomeIntentToActWithoutSpecificPlan'].setValue(cssrs.data.activeSuicidalIdeationWithSomeIntentToActWithoutSpecificPlan.answer);
            this.cssrsForm.controls['activeSuicidalIdeationWithSpecificPlanAndIntent'].setValue(cssrs.data.activeSuicidalIdeationWithSpecificPlanAndIntent.answer);
            this.cssrsForm.controls['actualAttempt'].setValue(cssrs.data.actualAttempt.answer);
            this.cssrsForm.controls['actualAttemptWithinThreeMonths'].setValue(cssrs.data.actualAttemptWithinThreeMonths.answer);

            this.riskScore = cssrs.data.riskScore;
            this.riskLevel = cssrs.data.props['riskLevel'];
          }
          this.askFurtherQuestions();
        });
    }
  }
  onCssrsFormSubmit() {
    if (this.cssrsForm.invalid) {
      this.cssrsForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.cssrs = Object.assign({}, this.cssrs);
    this.cssrs.patientId =
      this.cssrsForm.controls['patientId'].value;
    this.cssrs.sessionId =
      this.cssrsForm.controls['sessionId'].value;
    this.cssrs.assessmentRequestId =
      this.cssrsForm.controls['assessmentRequestId'].value;

    this.cssrs.wishToBeDead = {
      answer:
        this.cssrsForm.controls['wishToBeDead'].value
    };
    this.cssrs.nonSpecificActiveSuicidalThoughts = {
      answer:
        this.cssrsForm.controls['nonSpecificActiveSuicidalThoughts'].value
    };
    this.cssrs.activeSuicidalIdeationWithAnyMethodsNotPlanWithoutIntentToAct = {
      answer:
        this.cssrsForm.controls['activeSuicidalIdeationWithAnyMethodsNotPlanWithoutIntentToAct'].value
    };
    this.cssrs.activeSuicidalIdeationWithSomeIntentToActWithoutSpecificPlan = {
      answer:
        this.cssrsForm.controls['activeSuicidalIdeationWithSomeIntentToActWithoutSpecificPlan'].value
    };
    this.cssrs.activeSuicidalIdeationWithSpecificPlanAndIntent = {
      answer:
        this.cssrsForm.controls['activeSuicidalIdeationWithSpecificPlanAndIntent'].value
    };
    this.cssrs.actualAttempt = {
      answer:
        this.cssrsForm.controls['actualAttempt'].value
    };
    this.cssrs.actualAttemptWithinThreeMonths = {
      answer:
        this.cssrsForm.controls['actualAttemptWithinThreeMonths'].value
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.cssrs.accessCode = this.accessCode;

      this.cssrsApiService
        .addGuestLinkCssrs(this.cssrs)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Columbia-Suicide Severity Rating Scale Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Columbia-Suicide Severity Rating Scale Assessment'
            );
          }
        );
    } else {
      this.cssrsApiService
        .addCssrs(this.cssrs)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Columbia-Suicide Severity Rating Scale Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Columbia-Suicide Severity Rating Scale Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    'wishToBeDead': [
      { type: 'required', message: '1) Have you wished you were dead or wished you could go to sleep and not wake up? is required' },
    ],
    'nonSpecificActiveSuicidalThoughts': [
      { type: 'required', message: '2) Have you actually had any thoughts of killing yourself? is required' },
    ],
    'activeSuicidalIdeationWithAnyMethodsNotPlanWithoutIntentToAct': [
      { type: 'required', message: '3) Have you been thinking about how you might do this? is required' },
    ],
    'activeSuicidalIdeationWithSomeIntentToActWithoutSpecificPlan': [
      { type: 'required', message: '4) Have you had these thoughts and had some intention of acting on them? is required' },
    ],
    'activeSuicidalIdeationWithSpecificPlanAndIntent': [
      { type: 'required', message: '5) Have you started to work out or worked out the details of how to kill yourself?   Do you intend to carry out this plan? is required' },
    ],
    'actualAttempt': [
      { type: 'required', message: '6) Have you ever done anything, started to do anything, or prepared to do anything to YES NO end your life? is required' },
    ],
    'actualAttemptWithinThreeMonths': [
      { type: 'required', message: 'Was this within the past three months? is required' },
    ],
  };

  private buildForm() {
    this.cssrsForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      wishToBeDead: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      nonSpecificActiveSuicidalThoughts: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      activeSuicidalIdeationWithAnyMethodsNotPlanWithoutIntentToAct: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      activeSuicidalIdeationWithSomeIntentToActWithoutSpecificPlan: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      activeSuicidalIdeationWithSpecificPlanAndIntent: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      actualAttempt: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      actualAttemptWithinThreeMonths: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
    });
    this.cssrsForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.cssrsForm) {
      return;
    }
    const form = this.cssrsForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }

  public askFurtherQuestions() {
    let q2answer = this.cssrsForm.controls['nonSpecificActiveSuicidalThoughts'].value;
    if ('Yes' === q2answer) {
      this.showQ3ThruQ5Questions = true;
      this.cssrsForm.get('activeSuicidalIdeationWithAnyMethodsNotPlanWithoutIntentToAct').setValidators([Validators.required]);
      this.cssrsForm.get('activeSuicidalIdeationWithSomeIntentToActWithoutSpecificPlan').setValidators([Validators.required]);
      this.cssrsForm.get('activeSuicidalIdeationWithSpecificPlanAndIntent').setValidators([Validators.required]);
    } else {
      this.showQ3ThruQ5Questions = false;
      this.cssrsForm.get('activeSuicidalIdeationWithAnyMethodsNotPlanWithoutIntentToAct').setValidators([]);
      this.cssrsForm.get('activeSuicidalIdeationWithSomeIntentToActWithoutSpecificPlan').setValidators([]);
      this.cssrsForm.get('activeSuicidalIdeationWithSpecificPlanAndIntent').setValidators([]);
      this.cssrsForm.controls['activeSuicidalIdeationWithAnyMethodsNotPlanWithoutIntentToAct'].setValue('');
      this.cssrsForm.controls['activeSuicidalIdeationWithSomeIntentToActWithoutSpecificPlan'].setValue('');
      this.cssrsForm.controls['activeSuicidalIdeationWithSpecificPlanAndIntent'].setValue('');
    }

    let q6answer = this.cssrsForm.controls['actualAttempt'].value;
    this.showQ7Question = false;
    if ('Yes' === q6answer) {
      this.showQ7Question = true;
      this.cssrsForm.get('actualAttemptWithinThreeMonths').setValidators([Validators.required]);
    } else {
      this.showQ7Question = false;
      this.cssrsForm.get('actualAttemptWithinThreeMonths').setValidators([]);
      this.cssrsForm.controls['actualAttemptWithinThreeMonths'].setValue('');
    }
  }
}
