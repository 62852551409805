<ng-container *ngIf="displayTitle">
  <mat-card-title>Member Medications</mat-card-title>
  <mat-divider></mat-divider>
</ng-container>

<div style="padding: 10px">
  <div
    class="row"
    *ngIf="hasMedicationAddAccess && patient.status === 'Active'"
    style="margin-bottom: 15px"
  >
    <div
      class="col-md-12"
      style="display: flex; justify-content: right; gap: 10px; flex-wrap: wrap"
    >
      <div *ngIf="isPhysician && prescriptionEnabled">
        <button
          mat-raised-button
          color="warn"
          (click)="syncMedications()"
          type="button"
        >
          <mat-icon>autorenew</mat-icon> Retrieve Medication History
        </button>
      </div>
      <div>
        <button
          mat-raised-button
          [color]="displayTitle ? 'warn' : ''"
          (click)="addMedication()"
          type="button"
          [style]="!displayTitle ? 'color: #2196f3' : ''"
        >
          + Add Medication
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="data.total === 0">
    <mat-error *ngIf="sessionId"> No medication assigned </mat-error>
    <mat-error *ngIf="!sessionId"> No medication found </mat-error>
  </div>

  <div class="mat-elevation-z8">
    <div *ngIf="isLoadingResults || apiError">
      <mat-progress-bar
        mode="indeterminate"
        *ngIf="isLoadingResults"
      ></mat-progress-bar>

      <mat-error *ngIf="apiError">
        An error occurred while retrieving Medication records
      </mat-error>
    </div>

    <div class="table-responsive" *ngIf="data.total > 0">
      <table mat-table [dataSource]="data.items" matSort matSortDisableClear>
        <ng-container matColumnDef="medicationName">
          <th mat-header-cell *matHeaderCellDef>Medication Name</th>
          <td mat-cell *matCellDef="let row">{{ row.medicationName }}</td>
        </ng-container>
        <ng-container matColumnDef="ndcCode">
          <th mat-header-cell *matHeaderCellDef>NDC Code</th>
          <td mat-cell *matCellDef="let row">{{ row?.productNDC }}</td>
        </ng-container>
        <ng-container matColumnDef="dosage">
          <th mat-header-cell *matHeaderCellDef>Dosage</th>
          <td mat-cell *matCellDef="let row">{{ row.dosage }}</td>
        </ng-container>
        <ng-container matColumnDef="startDate">
          <th mat-header-cell *matHeaderCellDef>Start Date</th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="row?.startDate">
              {{ row.startDate }}
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef>End Date</th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="row?.endDate">
              {{ row.endDate }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="frequency">
          <th mat-header-cell *matHeaderCellDef>Frequency</th>
          <td mat-cell *matCellDef="let row">{{ row.frequency }}</td>
        </ng-container>
        <ng-container matColumnDef="timeSlots">
          <th mat-header-cell *matHeaderCellDef>Time Slots</th>
          <td mat-cell *matCellDef="let row">
            <span>{{ row.time1 }}</span>
            <span *ngIf="row.time2">, {{ row.time2 }}</span>
            <span *ngIf="row.time3">, {{ row.time3 }}</span>
            <span *ngIf="row.time4">, {{ row.time4 }}</span>
            <span *ngIf="row.time5">, {{ row.time5 }}</span>
            <span *ngIf="row.time6">, {{ row.time6 }}</span>
            <span *ngIf="row.time7">, {{ row.time7 }}</span>
            <span *ngIf="row.time8">, {{ row.time8 }}</span>
            <span *ngIf="row.time9">, {{ row.time9 }}</span>
            <span *ngIf="row.time10">, {{ row.time10 }}</span>
            <span *ngIf="row.time11">, {{ row.time11 }}</span>
            <span *ngIf="row.time12">, {{ row.time12 }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="specialInstructions">
          <th mat-header-cell *matHeaderCellDef>Special Instructions</th>
          <td mat-cell *matCellDef="let row">
            {{ row.specialInstructions }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef style="text-align: right">
            Actions
          </th>
          <td mat-cell *matCellDef="let row" style="text-align: right">
            <button
              mat-raised-button
              [matMenuTriggerFor]="menu"
              class="btn btn-just-icon btn-white btn-fab btn-round"
            >
              <i class="material-icons text_align-center">more_vert</i>
            </button>

            <mat-menu #menu="matMenu">
              <button
                *ngIf="hasMedicationEditAccess"
                mat-menu-item
                (click)="editMedication(row)"
              >
                <mat-icon style="color: #f39121"> edit </mat-icon>
                <span>Edit</span>
              </button>
              <button
                *ngIf="hasMedicationDeleteAccess"
                mat-menu-item
                (click)="deleteMedication(row)"
              >
                <mat-icon style="color: red"> delete_forever </mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <mat-paginator
      #paginator
      [length]="data.total"
      [pageSize]="data.per_page"
      [showFirstLastButtons]="true"
      *ngIf="data.total > data.per_page"
      (page)="getNext($event)"
    >
    </mat-paginator>
  </div>
</div>

<mat-card appearance="raised" *ngIf="!hasMedicationViewAccess">
  <mat-card-header>
    <mat-card-title>
      <mat-error>
        Sorry, You don't have access to view medication records
      </mat-error>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content> </mat-card-content>
</mat-card>
