import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { IYpCore10 } from 'src/app/_models/assessments/general/ypcore10.model';
import {
  ToastMessageService
} from 'src/app/_services/';
import {
  YpCore10ApiService
} from 'src/app/_services/assessments/general/ypcore10.service';

@Component({
  selector: 'app-ypcore10-assessment-dialog',
  templateUrl: './ypcore10-assessment-dialog.component.html',
  styleUrls: ['./ypcore10-assessment-dialog.component.css']
})
export class Ypcore10AssessmentDialogComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public ypcore10AssessmentApiService: YpCore10ApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public ypcore10AssessmentForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  ypcore10Assessment: IYpCore10;
  ypcore10AssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.ypcore10AssessmentForm, this.errors);

    this.loadYpCore10();
  }

  loadYpCore10() {
    this.ypcore10AssessmentForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.ypcore10AssessmentApiService
        .getYpCore10Details(this.assessmentId)
        .subscribe((ypcore10Assessment) => {
          if (ypcore10Assessment.data) {

            this.ypcore10AssessmentForm.controls['feltEdgyOrNervous'].setValue(ypcore10Assessment.data.feltEdgyOrNervous.answer);
            this.ypcore10AssessmentForm.controls['haventFeltLikeTalkingToAnyone'].setValue(ypcore10Assessment.data.haventFeltLikeTalkingToAnyone.answer);
            this.ypcore10AssessmentForm.controls['feltAbleToCopeWhenThingsGoWrong'].setValue(ypcore10Assessment.data.feltAbleToCopeWhenThingsGoWrong.answer);
            this.ypcore10AssessmentForm.controls['thoughtOfHurtingMyself'].setValue(ypcore10Assessment.data.thoughtOfHurtingMyself.answer);
            this.ypcore10AssessmentForm.controls['someoneIFeltAbleToAskForHelp'].setValue(ypcore10Assessment.data.someoneIFeltAbleToAskForHelp.answer);
            this.ypcore10AssessmentForm.controls['thoughtsAndFeelingsDistressedMe'].setValue(ypcore10Assessment.data.thoughtsAndFeelingsDistressedMe.answer);
            this.ypcore10AssessmentForm.controls['problemsHaveFeltTooMuchForMe'].setValue(ypcore10Assessment.data.problemsHaveFeltTooMuchForMe.answer);
            this.ypcore10AssessmentForm.controls['beenHardToGoToSleepOrStayAsleep'].setValue(ypcore10Assessment.data.beenHardToGoToSleepOrStayAsleep.answer);
            this.ypcore10AssessmentForm.controls['feltUnhappy'].setValue(ypcore10Assessment.data.feltUnhappy.answer);
            this.ypcore10AssessmentForm.controls['doneAllTheThingsIWantedTo'].setValue(ypcore10Assessment.data.doneAllTheThingsIWantedTo.answer);

            this.riskScore = ypcore10Assessment.data.riskScore;
          }
        });
    }
  }
  onYpCore10FormSubmit() {
    if (this.ypcore10AssessmentForm.invalid) {
      this.ypcore10AssessmentForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.ypcore10Assessment = Object.assign({}, this.ypcore10Assessment);
    this.ypcore10Assessment.patientId =
      this.ypcore10AssessmentForm.controls['patientId'].value;
    this.ypcore10Assessment.sessionId =
      this.ypcore10AssessmentForm.controls['sessionId'].value;
    this.ypcore10Assessment.assessmentRequestId =
      this.ypcore10AssessmentForm.controls['assessmentRequestId'].value;
    this.ypcore10Assessment.feltEdgyOrNervous = {
      answer: this.ypcore10AssessmentForm.controls['feltEdgyOrNervous'].value,
    };
    this.ypcore10Assessment.haventFeltLikeTalkingToAnyone = {
      answer: this.ypcore10AssessmentForm.controls['haventFeltLikeTalkingToAnyone'].value,
    };
    this.ypcore10Assessment.feltAbleToCopeWhenThingsGoWrong = {
      answer: this.ypcore10AssessmentForm.controls['feltAbleToCopeWhenThingsGoWrong'].value,
    };
    this.ypcore10Assessment.thoughtOfHurtingMyself = {
      answer: this.ypcore10AssessmentForm.controls['thoughtOfHurtingMyself'].value,
    };
    this.ypcore10Assessment.someoneIFeltAbleToAskForHelp = {
      answer: this.ypcore10AssessmentForm.controls['someoneIFeltAbleToAskForHelp'].value,
    };
    this.ypcore10Assessment.thoughtsAndFeelingsDistressedMe = {
      answer:
        this.ypcore10AssessmentForm.controls['thoughtsAndFeelingsDistressedMe'].value,
    };
    this.ypcore10Assessment.problemsHaveFeltTooMuchForMe = {
      answer: this.ypcore10AssessmentForm.controls['problemsHaveFeltTooMuchForMe'].value,
    };
    this.ypcore10Assessment.beenHardToGoToSleepOrStayAsleep = {
      answer: this.ypcore10AssessmentForm.controls['beenHardToGoToSleepOrStayAsleep'].value,
    };
    this.ypcore10Assessment.feltUnhappy = {
      answer:
        this.ypcore10AssessmentForm.controls['feltUnhappy'].value,
    };
    this.ypcore10Assessment.doneAllTheThingsIWantedTo = {
      answer: this.ypcore10AssessmentForm.controls['doneAllTheThingsIWantedTo'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.ypcore10Assessment.accessCode = this.accessCode;

      this.ypcore10AssessmentApiService
        .addGuestYpCore10(this.ypcore10Assessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted YP-CORE 10 Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting YP-CORE 10 Assessment'
            );
          }
        );
    } else {
      this.ypcore10AssessmentApiService
        .addYpCore10(this.ypcore10Assessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted YP-CORE 10 Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting YP-CORE 10 Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    'feltEdgyOrNervous': [
      { type: 'required', message: '1. I\'ve felt edgy or nervous is required' },
    ],
    'haventFeltLikeTalkingToAnyone': [
      { type: 'required', message: '2. I haven\'t felt like talking to anyone is required' },
    ],
    'feltAbleToCopeWhenThingsGoWrong': [
      { type: 'required', message: '3. I\'ve felt able to cope when things go wrong is required' },
    ],
    'thoughtOfHurtingMyself': [
      { type: 'required', message: '4. I\'ve thought of hurting myself is required' },
    ],
    'someoneIFeltAbleToAskForHelp': [
      { type: 'required', message: '5. There\'s been someone I felt able to ask for help is required' },
    ],
    'thoughtsAndFeelingsDistressedMe': [
      { type: 'required', message: '6. My thoughts and feelings distressed me is required' },
    ],
    'problemsHaveFeltTooMuchForMe': [
      { type: 'required', message: '7. My problems have felt too much for me is required' },
    ],
    'beenHardToGoToSleepOrStayAsleep': [
      { type: 'required', message: '8. It\'s been hard to go to sleep or stay asleep is required' },
    ],
    'feltUnhappy': [
      { type: 'required', message: '9. I\'ve felt unhappy is required' },
    ],
    'doneAllTheThingsIWantedTo': [
      { type: 'required', message: '10. I\'ve done all the things I wanted to is required' },
    ],
  };

  private buildForm() {
    this.ypcore10AssessmentForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      feltEdgyOrNervous: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      haventFeltLikeTalkingToAnyone: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      feltAbleToCopeWhenThingsGoWrong: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      thoughtOfHurtingMyself: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      someoneIFeltAbleToAskForHelp: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      thoughtsAndFeelingsDistressedMe: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      problemsHaveFeltTooMuchForMe: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      beenHardToGoToSleepOrStayAsleep: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      feltUnhappy: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      doneAllTheThingsIWantedTo: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
    });
    this.ypcore10AssessmentForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.ypcore10AssessmentForm) {
      return;
    }
    const form = this.ypcore10AssessmentForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
