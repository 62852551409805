<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>The Mindful Attention Awareness Scale (MAAS)</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="maasAssessmentForm"
    novalidate
    (ngSubmit)="onMaasFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-12"
            >1. I could be experiencing some emotion and not be conscious of it
            until some time later.</label
          >
          <div class="col-md-12">
            <mat-button-toggle-group formControlName="unconsciousEmotion">
              <mat-button-toggle value="Almost Always"
                >Almost Always</mat-button-toggle
              >
              <mat-button-toggle value="Very Frequently"
                >Very Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Frequently"
                >Somewhat Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Infrequently"
                >Somewhat Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Very Infrequently"
                >Very Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Almost Never"
                >Almost Never</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >2. I break or spill things because of carelessness, not paying
            attention, or thinking of something else.</label
          >
          <div class="col-md-12">
            <mat-button-toggle-group formControlName="breakSpillThings">
              <mat-button-toggle value="Almost Always"
                >Almost Always</mat-button-toggle
              >
              <mat-button-toggle value="Very Frequently"
                >Very Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Frequently"
                >Somewhat Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Infrequently"
                >Somewhat Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Very Infrequently"
                >Very Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Almost Never"
                >Almost Never</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >3. I find it difficult to stay focused on what's happening in the
            present.</label
          >
          <div class="col-md-12">
            <mat-button-toggle-group formControlName="difficultyStayingFocused">
              <mat-button-toggle value="Almost Always"
                >Almost Always</mat-button-toggle
              >
              <mat-button-toggle value="Very Frequently"
                >Very Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Frequently"
                >Somewhat Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Infrequently"
                >Somewhat Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Very Infrequently"
                >Very Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Almost Never"
                >Almost Never</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >4. I tend to walk quickly to get where I'm going without paying
            attention to what I experience along the way.</label
          >
          <div class="col-md-12">
            <mat-button-toggle-group formControlName="quickWalking">
              <mat-button-toggle value="Almost Always"
                >Almost Always</mat-button-toggle
              >
              <mat-button-toggle value="Very Frequently"
                >Very Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Frequently"
                >Somewhat Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Infrequently"
                >Somewhat Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Very Infrequently"
                >Very Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Almost Never"
                >Almost Never</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >5. I tend not to notice feelings of physical tension or discomfort
            until they really grab my attention.</label
          >
          <div class="col-md-12">
            <mat-button-toggle-group formControlName="unnoticedTension">
              <mat-button-toggle value="Almost Always"
                >Almost Always</mat-button-toggle
              >
              <mat-button-toggle value="Very Frequently"
                >Very Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Frequently"
                >Somewhat Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Infrequently"
                >Somewhat Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Very Infrequently"
                >Very Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Almost Never"
                >Almost Never</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >6. I forget a person's name almost as soon as I've been told it for
            the first time.</label
          >
          <div class="col-md-12">
            <mat-button-toggle-group formControlName="forgettingNames">
              <mat-button-toggle value="Almost Always"
                >Almost Always</mat-button-toggle
              >
              <mat-button-toggle value="Very Frequently"
                >Very Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Frequently"
                >Somewhat Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Infrequently"
                >Somewhat Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Very Infrequently"
                >Very Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Almost Never"
                >Almost Never</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >7. It seems I am “running on automatic,” without much awareness of
            what I'm doing.</label
          >
          <div class="col-md-12">
            <mat-button-toggle-group formControlName="runningAutomatic">
              <mat-button-toggle value="Almost Always"
                >Almost Always</mat-button-toggle
              >
              <mat-button-toggle value="Very Frequently"
                >Very Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Frequently"
                >Somewhat Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Infrequently"
                >Somewhat Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Very Infrequently"
                >Very Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Almost Never"
                >Almost Never</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >8. I rush through activities without being really attentive to
            them.</label
          >
          <div class="col-md-12">
            <mat-button-toggle-group formControlName="rushingActivities">
              <mat-button-toggle value="Almost Always"
                >Almost Always</mat-button-toggle
              >
              <mat-button-toggle value="Very Frequently"
                >Very Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Frequently"
                >Somewhat Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Infrequently"
                >Somewhat Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Very Infrequently"
                >Very Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Almost Never"
                >Almost Never</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >9. I get so focused on the goal I want to achieve that I lose touch
            with what I'm doing right now to get there.</label
          >
          <div class="col-md-12">
            <mat-button-toggle-group formControlName="focusedOnGoal">
              <mat-button-toggle value="Almost Always"
                >Almost Always</mat-button-toggle
              >
              <mat-button-toggle value="Very Frequently"
                >Very Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Frequently"
                >Somewhat Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Infrequently"
                >Somewhat Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Very Infrequently"
                >Very Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Almost Never"
                >Almost Never</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >10. I do jobs or tasks automatically, without being aware of what
            I'm doing.</label
          >
          <div class="col-md-12">
            <mat-button-toggle-group formControlName="automaticTasks">
              <mat-button-toggle value="Almost Always"
                >Almost Always</mat-button-toggle
              >
              <mat-button-toggle value="Very Frequently"
                >Very Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Frequently"
                >Somewhat Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Infrequently"
                >Somewhat Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Very Infrequently"
                >Very Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Almost Never"
                >Almost Never</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >11. I find myself listening to someone with one ear, doing
            something else at the same time.</label
          >
          <div class="col-md-12">
            <mat-button-toggle-group formControlName="halfListening">
              <mat-button-toggle value="Almost Always"
                >Almost Always</mat-button-toggle
              >
              <mat-button-toggle value="Very Frequently"
                >Very Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Frequently"
                >Somewhat Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Infrequently"
                >Somewhat Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Very Infrequently"
                >Very Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Almost Never"
                >Almost Never</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >12. I drive places on 'automatic pilot' and then wonder why I went
            there.</label
          >
          <div class="col-md-12">
            <mat-button-toggle-group formControlName="automaticDriving">
              <mat-button-toggle value="Almost Always"
                >Almost Always</mat-button-toggle
              >
              <mat-button-toggle value="Very Frequently"
                >Very Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Frequently"
                >Somewhat Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Infrequently"
                >Somewhat Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Very Infrequently"
                >Very Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Almost Never"
                >Almost Never</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >13. I find myself preoccupied with the future or the past.</label
          >
          <div class="col-md-12">
            <mat-button-toggle-group
              formControlName="preoccupiedWithPastFuture"
            >
              <mat-button-toggle value="Almost Always"
                >Almost Always</mat-button-toggle
              >
              <mat-button-toggle value="Very Frequently"
                >Very Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Frequently"
                >Somewhat Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Infrequently"
                >Somewhat Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Very Infrequently"
                >Very Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Almost Never"
                >Almost Never</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >14. I find myself doing things without paying attention.</label
          >
          <div class="col-md-12">
            <mat-button-toggle-group formControlName="unattentiveActions">
              <mat-button-toggle value="Almost Always"
                >Almost Always</mat-button-toggle
              >
              <mat-button-toggle value="Very Frequently"
                >Very Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Frequently"
                >Somewhat Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Infrequently"
                >Somewhat Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Very Infrequently"
                >Very Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Almost Never"
                >Almost Never</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12"
            >15. I snack without being aware that I'm eating.</label
          >
          <div class="col-md-12">
            <mat-button-toggle-group formControlName="unawareSnacking">
              <mat-button-toggle value="Almost Always"
                >Almost Always</mat-button-toggle
              >
              <mat-button-toggle value="Very Frequently"
                >Very Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Frequently"
                >Somewhat Frequently</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat Infrequently"
                >Somewhat Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Very Infrequently"
                >Very Infrequently</mat-button-toggle
              >
              <mat-button-toggle value="Almost Never"
                >Almost Never</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          maasAssessmentForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!maasAssessmentForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
