import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-assessment-dialog-interface',
  templateUrl: './assessment-dialog-interface.component.html',
  styleUrls: ['./assessment-dialog-interface.component.css'],
})
export class AssessmentDialogInterfaceComponent implements OnInit {
  public displayTitle: string;
  public patientId: string;
  public sessionId: string;
  public assessmentRequestId: string;
  public assessmentType: string;
  public assessmentId: string;
  public isPatient: boolean;

  constructor(
    public dialogRef: MatDialogRef<AssessmentDialogInterfaceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.displayTitle = data.displayTitle;
    this.patientId = data.patientId;
    this.assessmentRequestId = data.assessmentRequestId;
    this.assessmentId = data.assessmentId;
    this.assessmentType = data.assessmentType;
    this.isPatient = data.isPatient;
  }

  ngOnInit(): void {}

  taskCompleted(hasChangeEvent: boolean) {
    if (hasChangeEvent) {
      this.dialogRef.close('success');
    }
  }
}
