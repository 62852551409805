import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IClaimRemittances } from 'src/app/_models';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class RemittancesAPIService {
  constructor(private httpClient: HttpClient) {}

  // get All Remittances
  getAllRemittances(
    payerName: string,
    payeeName: string,
    refNumber: string,
    status: string,
    startDate: any,
    endDate: any,
    pageSize: number,
    pageIndex: number
  ) {
    let params = new HttpParams();

    if (payerName) params = params.append('payerName', payerName);
    if (payeeName) params = params.append('payeeName', payeeName);
    if (refNumber) params = params.append('refNumber', refNumber);
    if (status) params = params.append('status', status);

    if (startDate) params = params.append('startDate', startDate);
    if (endDate) params = params.append('endDate', endDate);

    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<any>(`${environment.apiUrl}/getallremittances`, {
      params: params,
    });
  }

  // Get Remit Details
  getRemitDetails(id: string) {
    return this.httpClient.get<any>(`${environment.apiUrl}/remitdetails/${id}`);
  }

  recordRemitPayment(id: string): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient.put<any>(
      `${environment.apiUrl}/markremitasrecorded/${id}`,
      { headers }
    );
  }

  recordRemitReviewNeeded(id: string): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient.put<any>(
      `${environment.apiUrl}/markremitasreviewneeded/${id}`,
      { headers }
    );
  }

  // Get Remit Claim Details
  getRemitClaimDetail(id: string) {
    return this.httpClient.get<any>(
      `${environment.apiUrl}/remitclaimdetails/${id}`
    );
  }

  // Get Remit Claim Details
  getPaymentHistory(id: string) {
    return this.httpClient.get<any>(
      `${environment.apiUrl}/claimpayments/${id}`
    );
  }

  // Record Manual Payments
  recordManualRemitPayment(remitDetails: IClaimRemittances): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient.post<IClaimRemittances>(
      `${environment.apiUrl}/recordmanualremit`,
      remitDetails,
      { headers }
    );
  }
}
