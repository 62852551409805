<div class="container">
  <div class="dialogTitle" style="margin-bottom: 25px">
    <p matDialogTitle>Available Goals</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="row">
    <div class="col-md-12" style="text-align: right">
      <!-- Category Selection -->
      <button
        mat-raised-button
        [matMenuTriggerFor]="sortMenu"
        style="margin-right: 15px; border-radius: 15px"
      >
        Category : {{ selectedCategory }}
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #sortMenu="matMenu" class="filterMenu">
        <button
          mat-menu-item
          type="button"
          style="font-weight: 400; font-size: 12px; width: 100%"
          *ngFor="let group of assessmentGroups"
          (click)="filterOptionChanged(group.name)"
        >
          {{ group.name }}
        </button>
      </mat-menu>
    </div>

    <div
      class="col-md-12"
      style="text-align: center; margin-top: 15px; margin-bottom: 15px"
    >
      <mat-progress-bar
        mode="indeterminate"
        *ngIf="isLoadingResults"
      ></mat-progress-bar>

      <mat-error *ngIf="data.total == 0 && !isLoadingResults">
        No available goal found for the category {{ selectedCategory }}
      </mat-error>

      <app-goal-card
        *ngFor="let goal of data.items"
        [patient]="patient"
        [therapySession]="therapySession"
        [hasGoalsAddAccess]="hasGoalsAddAccess"
        [hasGoalsEditAccess]="hasGoalsEditAccess"
        [hasGoalsDeleteAccess]="hasGoalsDeleteAccess"
        [goal]="goal"
        (hasChanges)="changesOccured($event)"
        [type]="'Available'"
      >
      </app-goal-card>
    </div>
  </div>
</div>
