import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ITherapyNote } from 'src/app/_models/session/therapy-note';
import {
  ToastMessageService
} from 'src/app/_services';
import { TherapyNotesHttpService } from 'src/app/_services/session/therapy-notes.service';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-add-therapy-notes-dialog',
  templateUrl: './add-therapy-notes-dialog.component.html',
  styleUrl: './add-therapy-notes-dialog.component.css'
})
export class AddTherapyNotesDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AddTherapyNotesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public therapyNotesHttpService: TherapyNotesHttpService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private toastMessageService: ToastMessageService
  ) {
    this.action = data.action;
    this.therapyNote = data.therapyNote;
    this.patientId = data.patientId;
    this.sessionId = data.sessionId;
  }

  public patientId: string;
  public sessionId: string;
  public action: string;
  public therapyNoteForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  therapyNote: ITherapyNote;
  accountError: Error = null;
  errors: any = {};
  country: string = 'US';
  processing: boolean = false;

  public ngOnInit() {
    this.buildForm();
    this.errorHandler.handleErrors(this.therapyNoteForm, this.errors);

    if (this.action === 'EDIT') {
      this.therapyNoteForm.controls['id'].setValue(this.therapyNote.id);
      this.therapyNoteForm.controls['organizationId'].setValue(
        this.therapyNote.organizationId
      );
      this.therapyNoteForm.controls['patientId'].setValue(
        this.therapyNote.patientId
      );
      this.therapyNoteForm.controls['sessionId'].setValue(
        this.therapyNote.sessionId
      );
      this.therapyNoteForm.controls['text'].setValue(this.therapyNote.text);
    } else {
      this.therapyNoteForm.controls['patientId'].setValue(this.patientId);
      this.therapyNoteForm.controls['sessionId'].setValue(this.sessionId);
    }
  }

  onCaseNoteFormSubmit() {
    if (this.therapyNoteForm.invalid) {
      this.therapyNoteForm.markAllAsTouched();
      return;
    }

    this.therapyNote = Object.assign({}, this.therapyNote);
    this.therapyNote = Object.assign(
      this.therapyNote,
      this.therapyNoteForm.value
    );

    this.processing = true;

    if (this.action === 'EDIT') {
      this.therapyNotesHttpService
        .updateTherapyNote(this.therapyNote)
        .subscribe(
          () => {
            this.processing = false;
            this.dialogRef.close('success');
            this.toastMessageService.displaySuccessMessage(
              'Note has been updated'
            );
          },
          () => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to update the note'
            );
          }
        );
    } else {
      this.therapyNote.patientId = this.patientId;
      this.therapyNotesHttpService
        .addTherapyNote(this.therapyNote)
        .subscribe(
          () => {
            this.processing = false;
            this.dialogRef.close('success');
            this.toastMessageService.displaySuccessMessage(
              'Note has been added'
            );
          },
          () => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to add note'
            );
          }
        );
    }
  }

  private buildForm() {
    this.therapyNoteForm = this.formBuilder.group({
      id: new FormControl(null),
      sessionId: new FormControl(null),
      patientId: new FormControl(null),
      organizationId: new FormControl(null),
      text: new FormControl('', Validators.compose([Validators.required])),
    });

    this.therapyNoteForm.valueChanges.subscribe(() => this.onValueChanged());
  }

  public validation_messages = {
    text: [
      { type: 'required', message: 'Please type in your note' },
      {
        type: 'minlength',
        message: 'Case note needs to be longer than 2 characters',
      },
    ],
  };

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.therapyNoteForm) {
      return;
    }
    const form = this.therapyNoteForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
