import { Component, Input, OnInit } from '@angular/core';
import { Patient } from 'src/app/_models/patient/patient';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css'],
})
export class HistoryComponent implements OnInit {
  @Input() patient: Patient;

  @Input() hasMemberHistoryViewAccess: boolean;
  @Input() hasMemberHistoryEditAccess: boolean;
  @Input() hasFamilyHistoryViewAccess: boolean;
  @Input() hasFamilyHistoryEditAccess: boolean;

  activeTab: string = '';

  constructor() {}

  ngOnInit(): void {
    if (this.hasMemberHistoryViewAccess) {
      this.activeTab = 'PATIENT HISTORY';
    } else if (this.hasFamilyHistoryViewAccess) {
      this.activeTab = 'FAMILY HISTORY';
    }
  }
}
