import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataTablesModel, IReferringProvider, ResponseModal } from '../_models';

import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ReferringProviderAPIService {
  constructor(private httpClient: HttpClient) {}

  getReferringProvider(
    firstName: string,
    lastName: string,
    phoneNumber: string,
    status: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    if (firstName) params = params.append('firstName', firstName);
    if (lastName) params = params.append('lastName', lastName);
    if (phoneNumber) params = params.append('phoneNumber', phoneNumber);
    if (status) params = params.append('status', status);

    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/referringprovider`,
      {
        params: params,
      }
    );
  }

  getReferringProviderDetails(id: string): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/referringprovider/` + id
    );
  }

  addReferringProvider(referringProvider: IReferringProvider): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(`${environment.apiUrl}/referringprovider`, referringProvider, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateReferringProvider(
    referringProvider: IReferringProvider
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(`${environment.apiUrl}/referringprovider`, referringProvider, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
