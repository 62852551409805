<mat-card-title>Member Consent Documents</mat-card-title>
<mat-divider></mat-divider>

<div style="padding: 10px">
  <!-- Search Box -->
  <!-- Search Box -->
  <div class="row" style="row-gap: 15px; margin-bottom: 15px">
    <div class="col-sm-12 col-md-4">
      <div
        style="
          display: flex;
          align-items: center;
          padding: 5px;
          border-radius: 10px;
          border: 1px solid rgb(0, 0, 0, 0.2);
          background-color: #ffffff;
          padding: 5px;
        "
      >
        <span
          class="material-icons"
          style="text-align: left; font-size: 20px; opacity: 0.5"
        >
          search
        </span>
        <input
          type="search"
          placeholder="Document Name"
          [(ngModel)]="searchDocumentName"
          (ngModelChange)="searchByNameChanged()"
          style="
            padding-left: 15px;
            width: 100%;
            background: transparent;
            border: 0;
          "
        />
      </div>
    </div>
    <div
      class="col-sm-12 col-md-8"
      style="
        column-gap: 20px;
        display: flex;
        flex-wrap: wrap;
        text-align: right;
        flex-direction: row-reverse;
        padding: 0;
        row-gap: 10px;
      "
    >
      <div *ngIf="patient.status === 'Active'">
        <button mat-raised-button color="warn" (click)="addConsentDocument()">
          + Request Consent Document
        </button>
      </div>

      <button mat-raised-button [matMenuTriggerFor]="sortMenu">
        <span style="white-space: break-spaces" *ngIf="status === ''">
          Status
        </span>

        <span
          style="white-space: break-spaces; color: #3ac47d"
          *ngIf="status === 'Completed'"
        >
          Status: Completed
        </span>

        <span
          style="white-space: break-spaces; color: #d36d03"
          *ngIf="status === 'Pending'"
        >
          Status: Pending
        </span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>

      <mat-menu #sortMenu="matMenu">
        <button
          mat-menu-item
          style="font-weight: 400; font-size: 12px; width: 100%"
          (click)="filterOptionChanged('')"
        >
          None
        </button>
        <button
          mat-menu-item
          style="font-weight: 400; font-size: 12px; width: 100%"
          (click)="filterOptionChanged('Pending')"
        >
          Pending
        </button>
        <button
          mat-menu-item
          style="font-weight: 400; font-size: 12px; width: 100%"
          (click)="filterOptionChanged('Completed')"
        >
          Completed
        </button>
      </mat-menu>
    </div>
  </div>

  <div class="row" style="margin-bottom: 15px">
    <!-- Add Button -->
    <div
      *ngIf="patient.status === 'Active'"
      class="col-md-12"
      style="
        display: flex;
        flex-wrap: wrap;
        justify-content: right;
        column-gap: 15px;
        row-gap: 10px;
      "
    >
      <!-- <button mat-raised-button color="warn" type="button">
        + Request New Document
      </button> -->
    </div>

    <div class="col-md-12" style="padding-top: 15px">
      <mat-progress-bar
        mode="indeterminate"
        *ngIf="isLoadingResults || deleteProcessing"
      ></mat-progress-bar>

      <div *ngIf="data.total === 0">
        <mat-error> No {{ status }} document found </mat-error>
      </div>

      <div class="mat-elevation-z8 table-responsive" *ngIf="data.total > 0">
        <table mat-table [dataSource]="data.items" matSort matSortDisableClear>
          <ng-container matColumnDef="documentName">
            <th mat-header-cell *matHeaderCellDef>Document Name</th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="row.status != 'Completed'">
                {{ row.documentName }}
              </span>

              <button
                *ngIf="row.status === 'Completed'"
                mat-button
                color="primary"
                type="button"
                (click)="viewConsentDocument(row)"
              >
                {{ row.documentName }}
                <i class="material-icons"> preview </i>
              </button>
            </td>
          </ng-container>
          <ng-container matColumnDef="sharedOn">
            <th mat-header-cell *matHeaderCellDef>Shared On</th>
            <td mat-cell *matCellDef="let row">
              {{ row.requestedDate | date : 'MM/dd/y' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef style="text-align: right">
              Status
            </th>
            <td mat-cell *matCellDef="let row" style="text-align: right">
              <span [class]="row.status === 'Pending' ? 'pendingStatus' : ''">
                {{ row.status }}
                <span *ngIf="row.status === 'Completed'">
                  on {{ row.signedDate | date : 'MM/dd/y' }}
                </span>
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th
              mat-header-cell
              *matHeaderCellDef
              style="text-align: right; width: 35px"
            ></th>
            <td mat-cell *matCellDef="let row" style="text-align: right">
              <mat-icon
                id="deleteButton"
                #deleteButton
                style="color: red; cursor: pointer; opacity: 0.5"
                *ngIf="row.status === 'Pending'"
                (click)="deleteConsentDocument(row.id)"
              >
                delete_forever
              </mat-icon>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator
          #paginator
          [length]="data.total"
          [pageSize]="data.per_page"
          [showFirstLastButtons]="true"
          *ngIf="data.total > data.per_page"
          (page)="getNext($event)"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
