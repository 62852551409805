<div style="padding: 10px">
  <div class="row" style="margin-top: 15px; margin-bottom: 20px">
    <div
      [class]="
        hasMemberAssessmentAddAccess &&
        therapySession.status === 'Active' &&
        patient.status === 'Active'
          ? 'col-md-6'
          : 'col-md-12'
      "
    >
      <mat-chip-listbox
        [(ngModel)]="selectedOption"
        aria-label="Status"
        style="float: left"
      >
        <mat-chip-option
          color="primary"
          #event
          *ngFor="let option of options"
          [value]="option.id"
          (click)="toggleSelection(option.id, event)"
        >
          {{ option.description }}
        </mat-chip-option>
      </mat-chip-listbox>
    </div>
    <div
      class="col-md-6"
      *ngIf="
        hasMemberAssessmentAddAccess &&
        therapySession.status === 'Active' &&
        patient.status === 'Active'
      "
      style="
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        column-gap: 10px;
        row-gap: 10px;
      "
    >
      <button
        type="button"
        mat-raised-button
        class="mat-elevation-z8"
        style="color: #2196f3"
        (click)="requestNewAssessment()"
      >
        + Assessment
      </button>
    </div>
  </div>

  <mat-progress-bar
    mode="indeterminate"
    *ngIf="pendingAssessmentProcessing"
  ></mat-progress-bar>

  <div *ngIf="selectedOption === 'Pending'">
    <div
      *ngIf="assessmentRequests.total == 0 && !pendingAssessmentProcessing"
      style="padding-left: 15px"
    >
      <mat-error> No record found </mat-error>
    </div>

    <div
      class="table-responsive mat-elevation-z8"
      *ngIf="assessmentRequests.total > 0"
    >
      <table
        mat-table
        [dataSource]="assessmentRequests.items"
        matSort
        matSortDisableClear
      >
        <ng-container matColumnDef="createdDate">
          <th mat-header-cell *matHeaderCellDef style="font-weight: 900">
            Date
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.createdDate | date : 'MM/dd/yyyy' }}
            <br />
            {{ row.createdDate | date : 'shortTime' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="assessmentType">
          <th mat-header-cell *matHeaderCellDef style="font-weight: 900">
            Assessment Type
          </th>
          <td mat-cell *matCellDef="let row">{{ row.assessmentType }}</td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th
            mat-header-cell
            *matHeaderCellDef
            style="font-weight: 900; text-align: right"
          >
            Action
          </th>
          <td mat-cell *matCellDef="let row" style="text-align: right">
            <button
              mat-raised-button
              [matMenuTriggerFor]="menu"
              class="btn btn-just-icon btn-white btn-fab btn-round"
            >
              <i class="material-icons text_align-center">more_vert</i>
            </button>
            <mat-menu #menu="matMenu">
              <button
                *ngIf="hasAssessmentTakeAddAccess"
                mat-menu-item
                type="button"
                (click)="takeAssessment(row)"
              >
                <mat-icon style="color: #2196f3; opacity: 0.5">
                  edit_note
                </mat-icon>
                <span>Take Assessment</span>
              </button>

              <button
                mat-menu-item
                type="button"
                (click)="sendEmailLink(row)"
                [disabled]="otpLoader"
                *ngIf="
                  patient?.email && patient?.phoneNumber && !row?.inSessionOnly
                "
              >
                <mat-icon
                  id="deleteButton"
                  style="color: #78c000; opacity: 0.5"
                >
                  send
                </mat-icon>
                <span>Send via email</span>
              </button>

              <button
                *ngIf="hasHospitalDeviceFeature && !row?.inSessionOnly"
                type="button"
                mat-menu-item
                color="warn"
                (click)="generateAcessCode(row)"
                [disabled]="otpLoader"
              >
                <mat-icon color="warn">pin</mat-icon>
                <span>Request OTP</span>
              </button>

              <button
                *ngIf="hasMemberAssessmentDeleteAccess"
                mat-menu-item
                type="button"
                (click)="openDeleteConfirm(row)"
              >
                <mat-icon id="deleteButton" style="color: #d92550; opacity: 0.5"
                  >delete_forever</mat-icon
                >
                <span>Delete</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedPendingAssessmentColumns"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedPendingAssessmentColumns"
        ></tr>
      </table>
      <mat-paginator
        [length]="assessmentRequests.total"
        [pageSize]="assessmentRequests.per_page"
        *ngIf="assessmentRequests.total > assessmentRequests.per_page"
        (page)="getNext($event)"
        [showFirstLastButtons]="true"
      ></mat-paginator>
    </div>
  </div>

  <app-assessment-history
    *ngIf="selectedOption === 'In Review' || selectedOption === 'Completed'"
    [patientId]="patient.id"
    [showHeader]="false"
    [showSearchMember]="false"
    [hideMemberName]="true"
    [hideSessionId]="true"
    [status]="selectedOption"
    [sessionId]="sessionId"
    [hideCompletedBy]="false"
    [hideCreatedDate]="false"
    [hasHospitalDeviceFeature]="hasHospitalDeviceFeature"
    [hasMemberAssessmentAddAccess]="hasMemberAssessmentAddAccess"
    [hasMemberAssessmentEditAccess]="hasMemberAssessmentEditAccess"
    [hasMemberAssessmentDeleteAccess]="hasMemberAssessmentDeleteAccess"
    [hasAssessmentChartsViewAccess]="hasAssessmentChartsViewAccess"
    [hasAssessmentGuidanceViewAccess]="hasAssessmentGuidanceViewAccess"
    [hasAssessmentGuidanceEditAccess]="hasAssessmentGuidanceEditAccess"
    [hasAssessmentSummaryViewAccess]="hasAssessmentSummaryViewAccess"
    [hasAssessmentSummaryEditAccess]="hasAssessmentSummaryEditAccess"
    [hasAssessmentTakeAddAccess]="hasAssessmentTakeAddAccess"
  >
  </app-assessment-history>
</div>
