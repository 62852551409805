import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseModal } from 'src/app/_models';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class DoseSpotApiService {
    constructor(private httpClient: HttpClient) { }
    getSingleSignOnUrl(patientId: string): Observable<ResponseModal> {
        return this.httpClient.get<ResponseModal>(`${environment.apiUrl}/dosespot/singleSignOnUrl/` + patientId);
    }

    syncMedications(patientId: string): Observable<any> {
        return this.httpClient.get<ResponseModal>(`${environment.apiUrl}/dosespot/medications/` + patientId);
    }
}