import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { ISocialPhobia } from 'src/app/_models/assessments/anxiety/socialphobia.model';
import {
  SocialPhobiaApiService,
  ToastMessageService,
} from 'src/app/_services/';

@Component({
  selector: 'app-social-phobia',
  templateUrl: './social-phobia.component.html',
  styleUrls: ['./social-phobia.component.css'],
})
export class SocialPhobiaComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public socialPhobiaApiService: SocialPhobiaApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) {}
  public riskScore: number;
  public socialPhobiaForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  socialPhobiaAssessment: ISocialPhobia;
  socialPhobiaAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.socialPhobiaForm, this.errors);

    this.loadSocialPhobiaAssessment();
  }

  loadSocialPhobiaAssessment() {
    this.socialPhobiaForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.socialPhobiaApiService
        .getSocialPhobiaDetails(this.assessmentId)
        .subscribe((socialPhobiaAssessment) => {
          if (socialPhobiaAssessment.data) {
            this.socialPhobiaForm.controls['q1'].setValue(
              socialPhobiaAssessment.data.q1.answer
            );
            this.socialPhobiaForm.controls['q2'].setValue(
              socialPhobiaAssessment.data.q2.answer
            );
            this.socialPhobiaForm.controls['q3'].setValue(
              socialPhobiaAssessment.data.q3.answer
            );
            this.socialPhobiaForm.controls['q4'].setValue(
              socialPhobiaAssessment.data.q4.answer
            );
            this.socialPhobiaForm.controls['q5'].setValue(
              socialPhobiaAssessment.data.q5.answer
            );
            this.socialPhobiaForm.controls['q6'].setValue(
              socialPhobiaAssessment.data.q6.answer
            );
            this.socialPhobiaForm.controls['q7'].setValue(
              socialPhobiaAssessment.data.q7.answer
            );
            this.socialPhobiaForm.controls['q8'].setValue(
              socialPhobiaAssessment.data.q8.answer
            );
            this.socialPhobiaForm.controls['q9'].setValue(
              socialPhobiaAssessment.data.q9.answer
            );
            this.socialPhobiaForm.controls['q10'].setValue(
              socialPhobiaAssessment.data.q10.answer
            );
            this.socialPhobiaForm.controls['q11'].setValue(
              socialPhobiaAssessment.data.q11.answer
            );
            this.socialPhobiaForm.controls['q12'].setValue(
              socialPhobiaAssessment.data.q12.answer
            );
            this.socialPhobiaForm.controls['q13'].setValue(
              socialPhobiaAssessment.data.q13.answer
            );
            this.socialPhobiaForm.controls['q14'].setValue(
              socialPhobiaAssessment.data.q14.answer
            );
            this.socialPhobiaForm.controls['q15'].setValue(
              socialPhobiaAssessment.data.q15.answer
            );
            this.socialPhobiaForm.controls['q16'].setValue(
              socialPhobiaAssessment.data.q16.answer
            );
            this.socialPhobiaForm.controls['q17'].setValue(
              socialPhobiaAssessment.data.q17.answer
            );

            this.riskScore = socialPhobiaAssessment.data.riskScore;
          }
        });
    }
  }

  onFormSubmit() {
    if (this.socialPhobiaForm.invalid) {
      this.socialPhobiaForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.socialPhobiaAssessment = Object.assign(
      {},
      this.socialPhobiaAssessment
    );
    this.socialPhobiaAssessment.patientId =
      this.socialPhobiaForm.controls['patientId'].value;
    this.socialPhobiaAssessment.sessionId =
      this.socialPhobiaForm.controls['sessionId'].value;
    this.socialPhobiaAssessment.assessmentRequestId =
      this.socialPhobiaForm.controls['assessmentRequestId'].value;
    this.socialPhobiaAssessment.q1 = {
      answer: this.socialPhobiaForm.controls['q1'].value,
    };
    this.socialPhobiaAssessment.q2 = {
      answer: this.socialPhobiaForm.controls['q2'].value,
    };
    this.socialPhobiaAssessment.q3 = {
      answer: this.socialPhobiaForm.controls['q3'].value,
    };
    this.socialPhobiaAssessment.q4 = {
      answer: this.socialPhobiaForm.controls['q4'].value,
    };
    this.socialPhobiaAssessment.q5 = {
      answer: this.socialPhobiaForm.controls['q5'].value,
    };
    this.socialPhobiaAssessment.q6 = {
      answer: this.socialPhobiaForm.controls['q6'].value,
    };
    this.socialPhobiaAssessment.q7 = {
      answer: this.socialPhobiaForm.controls['q7'].value,
    };
    this.socialPhobiaAssessment.q8 = {
      answer: this.socialPhobiaForm.controls['q8'].value,
    };
    this.socialPhobiaAssessment.q9 = {
      answer: this.socialPhobiaForm.controls['q9'].value,
    };
    this.socialPhobiaAssessment.q10 = {
      answer: this.socialPhobiaForm.controls['q10'].value,
    };
    this.socialPhobiaAssessment.q11 = {
      answer: this.socialPhobiaForm.controls['q11'].value,
    };
    this.socialPhobiaAssessment.q12 = {
      answer: this.socialPhobiaForm.controls['q12'].value,
    };
    this.socialPhobiaAssessment.q13 = {
      answer: this.socialPhobiaForm.controls['q13'].value,
    };
    this.socialPhobiaAssessment.q14 = {
      answer: this.socialPhobiaForm.controls['q14'].value,
    };
    this.socialPhobiaAssessment.q15 = {
      answer: this.socialPhobiaForm.controls['q15'].value,
    };
    this.socialPhobiaAssessment.q16 = {
      answer: this.socialPhobiaForm.controls['q16'].value,
    };
    this.socialPhobiaAssessment.q17 = {
      answer: this.socialPhobiaForm.controls['q17'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.socialPhobiaAssessment.accessCode = this.accessCode;

      this.socialPhobiaApiService
        .addGuestSocialPhobia(this.socialPhobiaAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Social Phobia Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Social Phobia Assessment'
            );
          }
        );
    } else {
      this.socialPhobiaApiService
        .addSocialPhobia(this.socialPhobiaAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Social Phobia Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Social Phobia Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    q1: [
      {
        type: 'required',
        message: '1. I am afraid of people in authority. is required',
      },
    ],
    q2: [
      {
        type: 'required',
        message: '2. I am bothered by blushing in front of people. is required',
      },
    ],
    q3: [
      {
        type: 'required',
        message: '3. Parties and social events scare me. is required',
      },
    ],
    q4: [
      {
        type: 'required',
        message: "4. I avoid talking to people I don't know.is required",
      },
    ],
    q5: [
      {
        type: 'required',
        message: '5. Being criticized scares me a lot. is required',
      },
    ],
    q6: [
      {
        type: 'required',
        message:
          '6. I avoid doing things or speaking to people for fear of embarrassment. is required',
      },
    ],
    q7: [
      {
        type: 'required',
        message:
          '7. Sweating in front of people causes me distress. is required',
      },
    ],
    q8: [
      { type: 'required', message: '8. I avoid going to parties. is required' },
    ],
    q9: [
      {
        type: 'required',
        message:
          '9. I avoid activities in which I am the center of attention. is required',
      },
    ],
    q10: [
      {
        type: 'required',
        message: '10. Talking to strangers scares me. is required',
      },
    ],
    q11: [
      {
        type: 'required',
        message: '11. I avoid having to give speeches. is required',
      },
    ],
    q12: [
      {
        type: 'required',
        message:
          '12. I would do anything to avoid being criticized. is required',
      },
    ],
    q13: [
      {
        type: 'required',
        message:
          '13. Heart palpitations bother me when I am around people. is required',
      },
    ],
    q14: [
      {
        type: 'required',
        message:
          '14. I am afraid of doing things when people might be watching. is required',
      },
    ],
    q15: [
      {
        type: 'required',
        message:
          '15. Being embarrassed or looking stupid are among my worst fears. is required',
      },
    ],
    q16: [
      {
        type: 'required',
        message: '16. I avoid speaking to anyone in authority. is required',
      },
    ],
    q17: [
      {
        type: 'required',
        message:
          '17. Trembling or shaking in front of others is distressing to me. is required',
      },
    ],
  };

  private buildForm() {
    this.socialPhobiaForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      q1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q2: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q3: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q4: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q5: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q6: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q7: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q8: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q9: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q10: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q11: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q12: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q13: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q14: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q15: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q16: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q17: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.socialPhobiaForm.valueChanges.subscribe(() => this.onValueChanged());
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.socialPhobiaForm) {
      return;
    }
    const form = this.socialPhobiaForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
