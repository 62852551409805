<mat-card-title>Member Allergies</mat-card-title>
<mat-divider></mat-divider>

<div style="margin-top: 20px; padding: 10px">
  <div
    class="row"
    *ngIf="patient.status === 'Active' && hasAllergiesAddAccess"
    style="margin-bottom: 15px"
  >
    <div
      class="col-md-12"
      style="
        display: flex;
        flex-wrap: wrap;
        justify-content: right;
        column-gap: 15px;
        row-gap: 15px;
        margin-bottom: 15px;
      "
    >
      <button mat-raised-button [matMenuTriggerFor]="sortMenu">
        <span
          style="white-space: break-spaces; color: #3ac47d"
          *ngIf="status === 'Active'"
        >
          Status: Active
        </span>

        <span
          style="white-space: break-spaces; color: #d92550"
          *ngIf="status === 'Inactive'"
        >
          Status: Inactive
        </span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>

      <mat-menu #sortMenu="matMenu">
        <button
          mat-menu-item
          style="font-weight: 400; font-size: 12px; width: 100%"
          (click)="filterOptionChanged('Active')"
        >
          Active
        </button>
        <button
          mat-menu-item
          style="font-weight: 400; font-size: 12px; width: 100%"
          (click)="filterOptionChanged('Inactive')"
        >
          Inactive
        </button>
      </mat-menu>

      <button
        type="button"
        mat-raised-button
        color="warn"
        (click)="addAllergy()"
      >
        + Add Allergy
      </button>
    </div>
  </div>

  <div *ngIf="data.total === 0">
    <mat-error> No Allergy Records found </mat-error>
  </div>

  <div *ngIf="isLoadingResults || apiError">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="isLoadingResults"
    ></mat-progress-bar>

    <mat-error *ngIf="apiError">
      An error occurred while retrieving Allergy records
    </mat-error>
  </div>

  <div class="mat-elevation-z8" *ngIf="data.total > 0">
    <div>
      <table mat-table [dataSource]="data.items">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let row">{{ row.name }}</td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let row">
            <span
              *ngIf="row?.status === 'Active'"
              style="color: #3ac47d; font-weight: 900"
            >
              {{ row?.status }}
            </span>
            <span
              *ngIf="row?.status != 'Active'"
              style="color: #d92550; font-weight: 900"
            >
              {{ row?.status }}
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="reactionType">
          <th mat-header-cell *matHeaderCellDef>Reaction Type</th>
          <td mat-cell *matCellDef="let row">{{ row.reactionType }}</td>
        </ng-container>
        <ng-container matColumnDef="reaction">
          <th mat-header-cell *matHeaderCellDef>Reaction</th>
          <td mat-cell *matCellDef="let row">{{ row.reaction }}</td>
        </ng-container>
        <ng-container matColumnDef="onsetDate">
          <th mat-header-cell *matHeaderCellDef>Onset Date</th>
          <td mat-cell *matCellDef="let row">{{ row.onsetDate }}</td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef style="text-align: right">
            Actions
          </th>
          <td mat-cell *matCellDef="let row" style="text-align: right">
            <button
              mat-raised-button
              [matMenuTriggerFor]="menu"
              class="btn btn-just-icon btn-white btn-fab btn-round"
            >
              <i class="material-icons text_align-center">more_vert</i>
            </button>

            <mat-menu #menu="matMenu">
              <button
                *ngIf="hasAllergiesEditAccess"
                mat-menu-item
                (click)="editAllergy(row)"
              >
                <mat-icon style="color: #f39121"> edit </mat-icon>
                <span>Edit</span>
              </button>
              <button
                *ngIf="hasAllergiesDeleteAccess"
                mat-menu-item
                (click)="deleteAllergy(row)"
              >
                <mat-icon style="color: red"> delete_forever </mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>

    <mat-paginator
      [length]="data.total"
      [pageSize]="data.per_page"
      *ngIf="data.total > data.per_page"
      [showFirstLastButtons]="true"
      (page)="getNext($event)"
    ></mat-paginator>
  </div>
</div>
