<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle *ngIf="action === 'ADD'">Add New Medication</p>
    <p matDialogTitle *ngIf="action === 'EDIT'">Edit Medication</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form
    [formGroup]="medicationForm"
    novalidate
    (ngSubmit)="onMedicationFormSubmit()"
  >
    <div class="row">
      <div class="col-md-12">
        <label class="labelText">
          Medication Name <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option
              *ngFor="let option of ndcMedicationData.items"
              [value]="option.proprietaryName"
              (onSelectionChange)="updateMedicationInfo(option)"
            >
              {{ option.proprietaryName }} {{ option.activeNumeratorStrength }}
              {{ option.activeIngredUnit }}
            </mat-option>
          </mat-autocomplete>
          <input
            matInput
            placeholder="Search by Medication Name / NDC Code"
            formControlName="medicationName"
            name="medicationName"
            minlength="2"
            required
            [matAutocomplete]="auto"
          />

          <mat-error
            *ngFor="let validation of validation_messages.medicationName"
          >
            <mat-error
              class="error-message"
              *ngIf="
                medicationForm
                  .get('medicationName')
                  .hasError(validation.type) &&
                (medicationForm.get('medicationName').dirty ||
                  medicationForm.get('medicationName').touched)
              "
            >
              {{ validation.message }}</mat-error
            >
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <label class="labelText"> NDC Code </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="NDC Code"
            formControlName="productNDC"
            name="productNDC"
          />
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <label class="labelText"> Dosage </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Dosage"
            formControlName="dosage"
            name="dosage"
          />
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <label class="labelText"> Start Date </label>
        <mat-form-field appearance="outline">
          <input
            formControlName="startDate"
            matInput
            [matDatepicker]="startDatePicker"
            placeholder="Start Date"
            autoSlashDate
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
          <!-- <mat-error *ngIf="medicationForm.controls['startDate'].invalid">
            Please select a valid start date
          </mat-error> -->
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <label class="labelText"> End Date </label>
        <mat-form-field appearance="outline">
          <input
            formControlName="endDate"
            matInput
            [matDatepicker]="endDatePicker"
            placeholder="End Date"
            [min]="medicationForm.controls['startDate'].value"
            autoSlashDate
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="endDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
          <!-- <mat-error *ngIf="medicationForm.controls['endDate'].invalid">
            Please select a valid end date
          </mat-error> -->
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <label class="labelText"> Frequency </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="frequency" placeholder="Frequency">
            <mat-option *ngFor="let freq of medicationFrequency" [value]="freq">
              {{ freq }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <mat-form-field appearance="outline">
          <mat-label>Time Slot # 1</mat-label>

          <mat-select formControlName="time1" placeholder="Time Slot # 1">
            <div class="timeGrid" style="padding-left: 8px">
              <mat-option
                *ngFor="let time of timeSlotValues"
                [value]="time"
                style="margin: 0; padding: 0; font-size: 10px"
                >{{ time }}</mat-option
              >
            </div>
          </mat-select>
          <mat-error *ngIf="medicationForm.controls['time1'].invalid"
            >Please select the time</mat-error
          >
        </mat-form-field>
      </div>

      <div class="col-md-4" *ngIf="isFrequencyLessThanOrEqualTo(2)">
        <mat-form-field appearance="outline">
          <mat-label>Time Slot # 2</mat-label>
          <mat-select formControlName="time2" placeholder="Time Slot # 2">
            <div class="timeGrid" style="padding-left: 8px">
              <mat-option
                *ngFor="let time of timeSlotValues"
                [value]="time"
                style="margin: 0; padding: 0; font-size: 10px"
                >{{ time }}</mat-option
              >
            </div>
          </mat-select>
          <mat-error *ngIf="medicationForm.controls['time2'].invalid"
            >Please select the time</mat-error
          >
        </mat-form-field>
      </div>

      <div class="col-md-4" *ngIf="isFrequencyLessThanOrEqualTo(3)">
        <mat-form-field appearance="outline">
          <mat-label>Time Slot # 3</mat-label>
          <mat-select formControlName="time3" placeholder="Time Slot # 3">
            <div class="timeGrid" style="padding-left: 8px">
              <mat-option
                *ngFor="let time of timeSlotValues"
                [value]="time"
                style="margin: 0; padding: 0; font-size: 10px"
                >{{ time }}</mat-option
              >
            </div>
          </mat-select>
          <mat-error *ngIf="medicationForm.controls['time3'].invalid"
            >Please select the time</mat-error
          >
        </mat-form-field>
      </div>

      <div class="col-md-4" *ngIf="isFrequencyLessThanOrEqualTo(4)">
        <mat-form-field appearance="outline">
          <mat-label>Time Slot # 4</mat-label>
          <mat-select formControlName="time4" placeholder="Time Slot # 4">
            <div class="timeGrid" style="padding-left: 8px">
              <mat-option
                *ngFor="let time of timeSlotValues"
                [value]="time"
                style="margin: 0; padding: 0; font-size: 10px"
                >{{ time }}</mat-option
              >
            </div>
          </mat-select>
          <mat-error *ngIf="medicationForm.controls['time4'].invalid"
            >Please select the time</mat-error
          >
        </mat-form-field>
      </div>

      <div class="col-md-4" *ngIf="isFrequencyLessThanOrEqualTo(5)">
        <mat-form-field appearance="outline">
          <mat-label>Time Slot # 5</mat-label>
          <mat-select formControlName="time5" placeholder="Time Slot # 5">
            <div class="timeGrid" style="padding-left: 8px">
              <mat-option
                *ngFor="let time of timeSlotValues"
                [value]="time"
                style="margin: 0; padding: 0; font-size: 10px"
                >{{ time }}</mat-option
              >
            </div>
          </mat-select>
          <mat-error *ngIf="medicationForm.controls['time5'].invalid"
            >Please select the time</mat-error
          >
        </mat-form-field>
      </div>

      <div class="col-md-4" *ngIf="isFrequencyLessThanOrEqualTo(6)">
        <mat-form-field appearance="outline">
          <mat-label>Time Slot # 6</mat-label>
          <mat-select formControlName="time6" placeholder="Time Slot # 6">
            <div class="timeGrid" style="padding-left: 8px">
              <mat-option
                *ngFor="let time of timeSlotValues"
                [value]="time"
                style="margin: 0; padding: 0; font-size: 10px"
                >{{ time }}</mat-option
              >
            </div>
          </mat-select>
          <mat-error *ngIf="medicationForm.controls['time6'].invalid"
            >Please select the time</mat-error
          >
        </mat-form-field>
      </div>

      <div class="col-md-4" *ngIf="isFrequencyLessThanOrEqualTo(7)">
        <mat-form-field appearance="outline">
          <mat-label>Time Slot # 7</mat-label>
          <mat-select formControlName="time7" placeholder="Time Slot # 7">
            <div class="timeGrid" style="padding-left: 8px">
              <mat-option
                *ngFor="let time of timeSlotValues"
                [value]="time"
                style="margin: 0; padding: 0; font-size: 10px"
                >{{ time }}</mat-option
              >
            </div>
          </mat-select>
          <mat-error *ngIf="medicationForm.controls['time7'].invalid"
            >Please select the time</mat-error
          >
        </mat-form-field>
      </div>

      <div class="col-md-4" *ngIf="isFrequencyLessThanOrEqualTo(8)">
        <mat-form-field appearance="outline">
          <mat-label>Time Slot # 8</mat-label>
          <mat-select formControlName="time8" placeholder="Time Slot # 8">
            <div class="timeGrid" style="padding-left: 8px">
              <mat-option
                *ngFor="let time of timeSlotValues"
                [value]="time"
                style="margin: 0; padding: 0; font-size: 10px"
                >{{ time }}</mat-option
              >
            </div>
          </mat-select>
          <mat-error *ngIf="medicationForm.controls['time8'].invalid"
            >Please select the time</mat-error
          >
        </mat-form-field>
      </div>

      <div class="col-md-4" *ngIf="isFrequencyLessThanOrEqualTo(9)">
        <mat-form-field appearance="outline">
          <mat-label>Time Slot # 9</mat-label>
          <mat-select formControlName="time9" placeholder="Time Slot # 9">
            <div class="timeGrid" style="padding-left: 8px">
              <mat-option
                *ngFor="let time of timeSlotValues"
                [value]="time"
                style="margin: 0; padding: 0; font-size: 10px"
                >{{ time }}</mat-option
              >
            </div>
          </mat-select>
          <mat-error *ngIf="medicationForm.controls['time9'].invalid"
            >Please select the time</mat-error
          >
        </mat-form-field>
      </div>

      <div class="col-md-4" *ngIf="isFrequencyLessThanOrEqualTo(10)">
        <mat-form-field appearance="outline">
          <mat-label>Time Slot # 10</mat-label>
          <mat-select formControlName="time10" placeholder="Time Slot # 10">
            <div class="timeGrid" style="padding-left: 8px">
              <mat-option
                *ngFor="let time of timeSlotValues"
                [value]="time"
                style="margin: 0; padding: 0; font-size: 10px"
                >{{ time }}</mat-option
              >
            </div>
          </mat-select>
          <mat-error *ngIf="medicationForm.controls['time10'].invalid"
            >Please select the time</mat-error
          >
        </mat-form-field>
      </div>

      <div class="col-md-4" *ngIf="isFrequencyLessThanOrEqualTo(11)">
        <mat-form-field appearance="outline">
          <mat-label>Time Slot # 11</mat-label>
          <mat-select formControlName="time11" placeholder="Time Slot # 11">
            <div class="timeGrid" style="padding-left: 8px">
              <mat-option
                *ngFor="let time of timeSlotValues"
                [value]="time"
                style="margin: 0; padding: 0; font-size: 10px"
                >{{ time }}</mat-option
              >
            </div>
          </mat-select>
          <mat-error *ngIf="medicationForm.controls['time11'].invalid"
            >Please select the time</mat-error
          >
        </mat-form-field>
      </div>

      <div class="col-md-4" *ngIf="isFrequencyLessThanOrEqualTo(12)">
        <mat-form-field appearance="outline">
          <mat-label>Time Slot # 12</mat-label>
          <mat-select formControlName="time12" placeholder="Time Slot # 12">
            <div class="timeGrid" style="padding-left: 8px">
              <mat-option
                *ngFor="let time of timeSlotValues"
                [value]="time"
                style="margin: 0; padding: 0; font-size: 10px"
                >{{ time }}</mat-option
              >
            </div>
          </mat-select>
          <mat-error *ngIf="medicationForm.controls['time12'].invalid"
            >Please select the time</mat-error
          >
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <label class="labelText">Special Instructions</label>
        <mat-form-field appearance="outline">
          <textarea
            matInput
            placeholder="Special Instructions"
            formControlName="specialInstructions"
            name="specialInstructions"
          ></textarea>
        </mat-form-field>
      </div>
    </div>

    <mat-dialog-actions align="end">
      <button
        class="btn btn-primary"
        mat-raised-button
        type="submit"
        [disabled]="!medicationForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
