import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ITherapySession, Patient } from 'src/app/_models';

@Component({
  selector: 'app-bpsa-intake',
  templateUrl: './bpsa-intake.component.html',
  styleUrls: ['./bpsa-intake.component.css'],
})
export class BpsaIntakeComponent implements OnInit {
  @Input() patient: Patient;
  @Input() therapySession: ITherapySession;
  @Input() loggedInUserId: string;
  @Input() hasBPSAIntakeViewAccess: boolean;
  @Input() hasBPSAIntakeAddAccess: boolean;
  @Input() hasBPSAIntakeEditAccess: boolean;
  @Output() updatedTherapySession = new EventEmitter<any>();
  @Output() triggerEvent = new EventEmitter<any>();

  ngOnInit(): void {}

  isTaskCompleted(taskName: string) {
    return (
      this.therapySession.completedTasks &&
      this.therapySession.completedTasks.includes(taskName)
    );
  }

  refreshPageEvent(therapySession: any) {
    this.therapySession = therapySession;
    this.updatedTherapySession.emit(therapySession);
  }

  reloadPatientProfile(event) {
    if (event) {
      this.triggerEvent.emit({
        eventType: 'RELOAD_PATIENT_PROFILE',
      });
    }
  }
}
