import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { addDays } from 'date-fns';
import { IClaimRemittances, IClaims, IFacility } from 'src/app/_models';
import {
  DateTimeZoneService,
  PatientInsuranceApiService,
  RemittancesAPIService,
  ToastMessageService,
} from 'src/app/_services';
import { FacilitiesSandbox } from 'src/app/shared/sandbox/facilities.sandbox';
import { ClaimFilingIndicators } from 'src/app/shared/utilities/billing/claimFilingIndicators';
import { HealthInsuranceTypes } from 'src/app/shared/utilities/billing/healthInsuranceTypes';
import { AdjustmentGroupCodes } from 'src/app/shared/utilities/claimsAdjustments/adjustmentsGroupCode';
import { AdjustmentReasonCodes } from 'src/app/shared/utilities/claimsAdjustments/adjustmentsReasonCodes';
import { ClaimStatusCodeFor835List } from 'src/app/shared/utilities/claimsAdjustments/ClaimStatusCodeFor835';
import {
  PaymentMethodCodesList,
  PaymentMethodCodesListMap,
} from 'src/app/shared/utilities/claimsAdjustments/paymentMethodCodes';
import {
  convertDateToYYYYMMDDFormat,
  extractDateFromYYYYMMDDFormat,
} from 'src/app/shared/utilities/utilities';
import { RecordRemittanceSelectClaimComponent } from '../record-remittance-select-claim/record-remittance-select-claim.component';

@Component({
  selector: 'app-record-remittance',
  templateUrl: './record-remittance.component.html',
  styleUrl: './record-remittance.component.css',
})
export class RecordRemittanceComponent implements OnInit {
  isLoadingDetails: boolean = false;
  isLoadingResults: boolean = false;
  processing: boolean = false;

  adjustmentGroupCodesList = AdjustmentGroupCodes;
  adjustmentReasonCodesList = AdjustmentReasonCodes;
  healthInsuranceTypeList = HealthInsuranceTypes;
  claimFilingIndicatorList = ClaimFilingIndicators;
  paymentMethodsList = PaymentMethodCodesList;
  paymentMethodsListMap = PaymentMethodCodesListMap;
  claimStatusCodeList = ClaimStatusCodeFor835List;

  editRemitId: string = '';
  remitForm: FormGroup;
  remitDetails: IClaimRemittances;

  payeeName: string = '';
  myFacilities: Array<IFacility> = [];
  filteredFacilities: Array<IFacility> = [];

  payerName: string = '';
  payersList = [];
  filteredPayerList = [];
  paymentStatusList = ['New', 'Needs Review', 'Recorded'];

  maxDate = addDays(new Date(), 21);
  currentTimezone: string = '';
  errorsList = [];

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private dateTimeZoneService: DateTimeZoneService,
    private remittancesAPIService: RemittancesAPIService,
    private toastMessageService: ToastMessageService,
    private facilitiesSandbox: FacilitiesSandbox,
    private patientInsuranceApiService: PatientInsuranceApiService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit(): void {
    this.editRemitId = this.route.snapshot.paramMap.get('id');

    this.buildForm();

    this.loadInsuranceProviders();
    this.loadMyFacilities();

    // If edit remit Id is passed, load existing remit details
    if (this.editRemitId) {
      this.getRemitsDetails();
    }
  }

  getRemitsDetails() {
    this.isLoadingResults = true;

    this.remittancesAPIService.getRemitDetails(this.editRemitId).subscribe({
      next: (response) => {
        this.isLoadingResults = false;
        this.remitDetails = response.data;

        // Now we need to load the form controls with this remit data
        this.loadRemitDetailsOnForm();
      },
      error: (error) => {
        this.isLoadingResults = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to retrieve remits details.'
        );
      },
    });
  }

  private buildForm() {
    this.remitForm = this.formBuilder.group({
      id: new FormControl(''),
      organizationId: new FormControl(''),

      paymentReferenceNumber: new FormControl('', Validators.required),
      paymentMethodCode: new FormControl('', Validators.required),
      paymentMethodDescription: new FormControl('', Validators.required),

      paymentEffectiveDate: new FormControl('', Validators.required),
      paymentAmount: new FormControl('', Validators.required),

      payerName: new FormControl('', Validators.required),
      payerAddressLine1: new FormControl(''),
      payerAddressLine2: new FormControl(''),
      payerCity: new FormControl(''),
      payerState: new FormControl(''),
      payerZip: new FormControl(''),

      facilityIds: new FormControl([], Validators.required),

      payeeName: new FormControl('', Validators.required),
      payeeAddressLine1: new FormControl(''),
      payeeAddressLine2: new FormControl(''),
      payeeCity: new FormControl(''),
      payeeState: new FormControl(''),
      payeeZip: new FormControl(''),

      status: new FormControl('Recorded'),
      remitType: new FormControl('Manual'),
      paymentRecordedDate: new FormControl(''),

      claimLevelLoop: this.formBuilder.array([], Validators.required),
    });
  }

  loadRemitDetailsOnForm() {
    this.remitForm.controls['id'].setValue(this.remitDetails.id);
    this.remitForm.controls['organizationId'].setValue(
      this.remitDetails.organizationId
    );
    this.remitForm.controls['paymentReferenceNumber'].setValue(
      this.remitDetails.paymentReferenceNumber
    );
    this.remitForm.controls['paymentMethodCode'].setValue(
      this.remitDetails.paymentMethodCode
    );
    this.remitForm.controls['paymentMethodDescription'].setValue(
      this.remitDetails.paymentMethodDescription
    );
    this.remitForm.controls['paymentEffectiveDate'].setValue(
      extractDateFromYYYYMMDDFormat(this.remitDetails.paymentEffectiveDate)
    );
    this.remitForm.controls['paymentAmount'].setValue(
      this.remitDetails.paymentAmount
    );
    this.remitForm.controls['payerName'].setValue(this.remitDetails.payerName);
    this.remitForm.controls['payerAddressLine1'].setValue(
      this.remitDetails.payerAddressLine1
    );
    this.remitForm.controls['payerAddressLine2'].setValue(
      this.remitDetails.payerAddressLine2
    );
    this.remitForm.controls['payerCity'].setValue(this.remitDetails.payerCity);
    this.remitForm.controls['payerState'].setValue(
      this.remitDetails.payerState
    );
    this.remitForm.controls['payerZip'].setValue(this.remitDetails.payerZip);
    this.remitForm.controls['facilityIds'].setValue(
      this.remitDetails.facilityIds
    );
    this.remitForm.controls['payeeName'].setValue(this.remitDetails.payeeName);
    this.remitForm.controls['payeeAddressLine1'].setValue(
      this.remitDetails.payeeAddressLine1
    );
    this.remitForm.controls['payeeAddressLine2'].setValue(
      this.remitDetails.payeeAddressLine2
    );
    this.remitForm.controls['payeeCity'].setValue(this.remitDetails.payeeCity);
    this.remitForm.controls['payeeState'].setValue(
      this.remitDetails.payeeState
    );
    this.remitForm.controls['payeeZip'].setValue(this.remitDetails.payeeZip);
    this.remitForm.controls['status'].setValue(this.remitDetails.status);
    this.remitForm.controls['remitType'].setValue(this.remitDetails.remitType);
    this.remitForm.controls['paymentRecordedDate'].setValue(
      this.remitDetails.paymentRecordedDate
    );

    // Need to load Claim Level Loop
    const claimLevelLoopControl = <FormArray>(
      this.remitForm.get('claimLevelLoop')
    );

    this.remitDetails.claimLevelLoop.forEach((claimLevelLoop) => {
      // Control to add with existing values
      let controlToAdd = new FormGroup({
        id: new FormControl(claimLevelLoop.id),
        organizationId: new FormControl(claimLevelLoop.organizationId),
        paymentReferenceNumber: new FormControl(
          claimLevelLoop.paymentReferenceNumber,
          Validators.required
        ),
        paymentMethodCode: new FormControl(
          claimLevelLoop.paymentMethodCode,
          Validators.required
        ),
        paymentEffectiveDate: new FormControl(
          extractDateFromYYYYMMDDFormat(claimLevelLoop.paymentEffectiveDate),
          Validators.required
        ),
        paymentMethodDescription: new FormControl(
          claimLevelLoop.paymentMethodDescription,
          Validators.required
        ),
        mindwiseSubmittedClaims: new FormControl(true, Validators.required),
        claimId: new FormControl(claimLevelLoop.claimId, Validators.required),
        claimCode: new FormControl(
          claimLevelLoop.claimCode,
          Validators.required
        ),

        sessionId: new FormControl(
          claimLevelLoop.sessionId,
          Validators.required
        ),
        sessionCode: new FormControl(
          claimLevelLoop.sessionCode,
          Validators.required
        ),

        visitReason: new FormControl(
          claimLevelLoop.visitReason,
          Validators.required
        ),
        sessionDate: new FormControl(
          claimLevelLoop.sessionDate,
          Validators.required
        ),
        sessionDuration: new FormControl(
          claimLevelLoop.sessionDuration,
          Validators.required
        ),

        facilityId: new FormControl(
          claimLevelLoop.facilityId,
          Validators.required
        ),
        payerName: new FormControl(
          claimLevelLoop.payerName,
          Validators.required
        ),
        payeeName: new FormControl(
          claimLevelLoop.payeeName,
          Validators.required
        ),

        memberId: new FormControl(claimLevelLoop.memberId, Validators.required),
        memberFirstName: new FormControl(
          claimLevelLoop.memberFirstName,
          Validators.required
        ),
        memberLastName: new FormControl(
          claimLevelLoop.memberLastName,
          Validators.required
        ),

        providerNPI: new FormControl(claimLevelLoop.providerNPI),
        providerFirstName: new FormControl(claimLevelLoop.providerFirstName),
        providerLastName: new FormControl(claimLevelLoop.providerLastName),

        totalAmount: new FormControl(
          claimLevelLoop.totalAmount,
          Validators.required
        ),
        paidAmount: new FormControl(
          claimLevelLoop.paidAmount,
          Validators.required
        ),
        patientResponsibility: new FormControl(
          claimLevelLoop.patientResponsibility
        ),

        status: new FormControl(claimLevelLoop.status),
        claimStatusCode: new FormControl(
          claimLevelLoop.claimStatusCode,
          Validators.required
        ),
        claimStatusDescription: new FormControl(
          claimLevelLoop.claimStatusDescription,
          Validators.required
        ),
        pos: new FormControl(claimLevelLoop.pos),

        serviceLineLoop: this.formBuilder.array([]),
        claimLevelAdjustments: this.formBuilder.array([]),
        providerLevelAdjustments: this.formBuilder.array([]),
      });

      // Building service line level object
      const serviceLineLoopControl = <FormArray>(
        controlToAdd.get('serviceLineLoop')
      );
      if (claimLevelLoop.serviceLineLoop) {
        // Looping through each service line level for this claim
        claimLevelLoop.serviceLineLoop.forEach((serviceLineLoop) => {
          // Push this service line detail
          let serviceControlToPush = new FormGroup({
            adjudicationDate: new FormControl(
              extractDateFromYYYYMMDDFormat(serviceLineLoop.adjudicationDate),
              Validators.required
            ),

            serviceDate: new FormControl(serviceLineLoop.serviceDate),
            cptCode: new FormControl(
              serviceLineLoop.cptCode,
              Validators.required
            ),
            modifier1: new FormControl(''),
            modifier2: new FormControl(''),
            modifier3: new FormControl(''),
            modifier4: new FormControl(''),

            quantity: new FormControl(
              serviceLineLoop.quantity,
              Validators.required
            ),
            totalAmount: new FormControl(
              serviceLineLoop.totalAmount,
              Validators.required
            ),
            paidAmount: new FormControl(
              serviceLineLoop.paidAmount,
              Validators.required
            ),
            patientResponsibility: new FormControl(
              serviceLineLoop.patientResponsibility
            ),
            claimAdjustments: this.formBuilder.array([]),
          });

          const serviceLineAdjustmentsControl = <FormArray>(
            serviceControlToPush.get('claimAdjustments')
          );

          // Loop through all claim adjustments and push them to control
          if (serviceLineLoop.claimAdjustments) {
            serviceLineLoop.claimAdjustments.forEach((claimAdjustments) => {
              // Push this adjustment
              serviceLineAdjustmentsControl.push(
                new FormGroup({
                  groupCode: new FormControl(
                    claimAdjustments.groupCode,
                    Validators.required
                  ),
                  groupCodeDescription: new FormControl(
                    claimAdjustments.groupCodeDescription
                  ),
                  reasonCode: new FormControl(
                    claimAdjustments.reasonCode,
                    Validators.required
                  ),
                  reasonCodeDescription: new FormControl(
                    claimAdjustments.reasonCodeDescription
                  ),
                  amount: new FormControl(
                    claimAdjustments.amount,
                    Validators.required
                  ),
                  quantity: new FormControl(claimAdjustments.quantity),
                })
              );
            });
          }

          // Finally push this control
          serviceLineLoopControl.push(serviceControlToPush);
        });
      }

      // Building claim level adjustment object
      const claimLevelAdjustmentControl = <FormArray>(
        controlToAdd.get('claimLevelAdjustments')
      );
      if (claimLevelLoop.claimLevelAdjustments) {
        // Loop and push all the claim level adjustments
        claimLevelLoop.claimLevelAdjustments.forEach((claimLevelAdjustment) => {
          claimLevelAdjustmentControl.push(
            new FormGroup({
              groupCode: new FormControl(
                claimLevelAdjustment.groupCode,
                Validators.required
              ),
              groupCodeDescription: new FormControl(
                claimLevelAdjustment.groupCodeDescription
              ),
              reasonCode: new FormControl(
                claimLevelAdjustment.reasonCode,
                Validators.required
              ),
              reasonCodeDescription: new FormControl(
                claimLevelAdjustment.reasonCodeDescription
              ),
              amount: new FormControl(
                claimLevelAdjustment.amount,
                Validators.required
              ),
              quantity: new FormControl(claimLevelAdjustment.quantity),
            })
          );
        });
      }

      // Building provider level adjustment object
      const providerLevelAdjustmentControl = <FormArray>(
        controlToAdd.get('providerLevelAdjustments')
      );
      if (claimLevelLoop.providerLevelAdjustments) {
        // Loop and push all the claim level adjustments
        claimLevelLoop.providerLevelAdjustments.forEach(
          (providerLevelAdjustment) => {
            providerLevelAdjustmentControl.push(
              new FormGroup({
                groupCode: new FormControl(
                  providerLevelAdjustment.groupCode,
                  Validators.required
                ),
                groupCodeDescription: new FormControl(
                  providerLevelAdjustment.groupCodeDescription
                ),
                reasonCode: new FormControl(
                  providerLevelAdjustment.reasonCode,
                  Validators.required
                ),
                reasonCodeDescription: new FormControl(
                  providerLevelAdjustment.reasonCodeDescription
                ),
                amount: new FormControl(
                  providerLevelAdjustment.amount,
                  Validators.required
                ),
                quantity: new FormControl(providerLevelAdjustment.quantity),
              })
            );
          }
        );
      }
      // Finally push the whole control object as well
      claimLevelLoopControl.push(controlToAdd);
    });
  }

  loadInsuranceProviders() {
    this.isLoadingDetails = true;

    this.patientInsuranceApiService.getAllActiveProviders().subscribe({
      next: (response) => {
        if (response && response.items) {
          this.payersList = response.items;
          this.filteredPayerList = response.items;
        } else {
          this.payersList = [];
          this.filteredPayerList = [];
        }
        this.isLoadingDetails = false;
      },
      error: (error) => {
        this.isLoadingDetails = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to get insurance providers for this organization.'
        );
      },
    });
  }

  filterPayer() {
    if (this.payerName) {
      this.filteredPayerList = this.payersList.filter((payer) => {
        if (
          payer.payerName.toLowerCase().includes(this.payerName.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.filteredPayerList = [...this.payersList];
    }
  }

  loadMyFacilities() {
    this.facilitiesSandbox.facilities$.subscribe((response) => {
      if (response) {
        this.myFacilities = response;
        this.filteredFacilities = response;
      }
    });
  }

  filterPayee() {
    if (this.payeeName) {
      this.filteredFacilities = this.myFacilities.filter((payee) => {
        if (
          payee.facilityName
            .toLowerCase()
            .includes(this.payeeName.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.filteredFacilities = [...this.myFacilities];
    }
  }

  paymentMethodChanged(event) {
    this.remitForm.controls['paymentMethodDescription'].setValue(
      this.paymentMethodsListMap.get(event.value)
    );

    // Also make this the payment method for all claims objects
    const control = <FormArray>this.remitForm.get('claimLevelLoop');
    // For each inner controls, update the value
    control.controls.forEach((claimControl: FormGroup) => {
      claimControl.controls['paymentMethodCode'].setValue(
        this.remitForm.controls['paymentMethodCode'].value
      );
      claimControl.controls['paymentMethodDescription'].setValue(
        this.remitForm.controls['paymentMethodDescription'].value
      );
    });
  }

  paymentReferenceNumberChanged() {
    // Also make this the payment method for all claims objects
    const control = <FormArray>this.remitForm.get('claimLevelLoop');
    // For each inner controls, update the value
    control.controls.forEach((claimControl: FormGroup) => {
      claimControl.controls['paymentReferenceNumber'].setValue(
        this.remitForm.controls['paymentReferenceNumber'].value
      );
    });
  }

  payerChanged(event) {
    let selectedPayer = this.payersList
      .filter((payer) => payer.payerName === event.value)
      .at(0);

    this.remitForm.controls['payerAddressLine1'].setValue(
      selectedPayer?.addressLine1
    );
    this.remitForm.controls['payerAddressLine2'].setValue(
      selectedPayer?.addressLine2
    );
    this.remitForm.controls['payerCity'].setValue(selectedPayer?.city);
    this.remitForm.controls['payerState'].setValue(selectedPayer?.state);
    this.remitForm.controls['payerZip'].setValue(selectedPayer?.zip);

    const control = <FormArray>this.remitForm.get('claimLevelLoop');
    // For each inner controls, update the value
    control.controls.forEach((claimControl: FormGroup) => {
      claimControl.controls['payerName'].setValue(event.value);
    });
  }

  payeeChanged(event) {
    let selectedPayee = this.myFacilities
      .filter((payee) => payee.facilityName === event.value)
      .at(0);

    this.remitForm.controls['facilityIds'].setValue([`${selectedPayee.id}`]);
    this.remitForm.controls['payeeAddressLine1'].setValue(
      selectedPayee?.address
    );
    this.remitForm.controls['payeeAddressLine2'].setValue(
      selectedPayee?.addressLine2
    );
    this.remitForm.controls['payeeCity'].setValue(selectedPayee?.city);
    this.remitForm.controls['payeeState'].setValue(selectedPayee?.state);
    this.remitForm.controls['payeeZip'].setValue(selectedPayee?.zip);

    const control = <FormArray>this.remitForm.get('claimLevelLoop');
    // For each inner controls, update the value
    control.controls.forEach((claimControl: FormGroup) => {
      claimControl.controls['payeeName'].setValue(event.value);
    });
  }

  selectExistingClaims() {
    let dialogRef = this.dialog.open(RecordRemittanceSelectClaimComponent, {
      data: {},
      autoFocus: false,
      disableClose: true,
      minWidth: '70vw',
      minHeight: '70vh',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response?.type === 'success') {
        // Need to build the claim response array
        let selectedClaimObject = <IClaims>response.claim;

        const control = <FormArray>this.remitForm.get('claimLevelLoop');

        // Building claim level object
        let controlToAdd = new FormGroup({
          id: new FormControl(null),
          organizationId: new FormControl(selectedClaimObject.organizationId),
          paymentReferenceNumber: new FormControl(
            this.remitForm.controls['paymentReferenceNumber'].value,
            Validators.required
          ),
          paymentMethodCode: new FormControl(
            this.remitForm.controls['paymentMethodCode'].value,
            Validators.required
          ),
          paymentEffectiveDate: new FormControl(
            this.remitForm.controls['paymentEffectiveDate'].value,
            Validators.required
          ),
          paymentMethodDescription: new FormControl(
            this.remitForm.controls['paymentMethodDescription'].value,
            Validators.required
          ),
          mindwiseSubmittedClaims: new FormControl(true, Validators.required),
          claimId: new FormControl(selectedClaimObject.id, Validators.required),
          claimCode: new FormControl(
            selectedClaimObject.claimCode,
            Validators.required
          ),

          sessionId: new FormControl(
            selectedClaimObject.sessionId,
            Validators.required
          ),
          sessionCode: new FormControl(
            selectedClaimObject.sessionCode,
            Validators.required
          ),

          visitReason: new FormControl(
            selectedClaimObject.therapySession.visitReasonName,
            Validators.required
          ),
          sessionDate: new FormControl(
            selectedClaimObject.therapySession.start,
            Validators.required
          ),
          sessionDuration: new FormControl(
            selectedClaimObject.therapySession.sessionDuration,
            Validators.required
          ),

          facilityId: new FormControl(
            selectedClaimObject.billingProviderRefId,
            Validators.required
          ),
          payerName: new FormControl(
            this.remitForm.controls['payerName'].value,
            Validators.required
          ),
          payeeName: new FormControl(
            this.remitForm.controls['payeeName'].value,
            Validators.required
          ),

          memberId: new FormControl(
            selectedClaimObject.therapySession.patientId,
            Validators.required
          ),
          memberFirstName: new FormControl(
            selectedClaimObject.therapySession.patientFirstName,
            Validators.required
          ),
          memberLastName: new FormControl(
            selectedClaimObject.therapySession.patientLastName,
            Validators.required
          ),

          providerNPI: new FormControl(
            selectedClaimObject.therapySession.supervisingRenderingProvider ===
            'No'
              ? selectedClaimObject.therapySession.physicianNPI
              : selectedClaimObject.therapySession.supervisingRPNPI
          ),
          providerFirstName: new FormControl(
            selectedClaimObject.therapySession.supervisingRenderingProvider ===
            'No'
              ? selectedClaimObject.therapySession.physicianFirstName
              : selectedClaimObject.therapySession.supervisingRPFirstName
          ),
          providerLastName: new FormControl(
            selectedClaimObject.therapySession.supervisingRenderingProvider ===
            'No'
              ? selectedClaimObject.therapySession.physicianLastName
              : selectedClaimObject.therapySession.supervisingRPLastName
          ),

          totalAmount: new FormControl(
            selectedClaimObject.therapySession.billingTotal,
            Validators.required
          ),
          paidAmount: new FormControl(0, Validators.required),
          patientResponsibility: new FormControl(0),

          status: new FormControl('Unlinked'),
          claimStatusCode: new FormControl('', Validators.required),
          claimStatusDescription: new FormControl('', Validators.required),

          serviceLineLoop: this.formBuilder.array([]),
          claimLevelAdjustments: this.formBuilder.array([]),
          providerLevelAdjustments: this.formBuilder.array([]),
          pos: new FormControl(
            selectedClaimObject.therapySession.billingProviderPOSCode
          ),
        });

        // BUilding service line level object
        const serviceLineLoopControl = <FormArray>(
          controlToAdd.get('serviceLineLoop')
        );

        selectedClaimObject.therapySession.charges.forEach((charge, index) => {
          serviceLineLoopControl.push(
            new FormGroup({
              adjudicationDate: new FormControl(
                this.remitForm.controls['paymentEffectiveDate'].value,
                Validators.required
              ),

              serviceDate: new FormControl(
                convertDateToYYYYMMDDFormat(
                  new Date(selectedClaimObject.therapySession.start)
                )
              ),
              cptCode: new FormControl(charge.cptCode, Validators.required),
              modifier1: new FormControl(charge.modifier1),
              modifier2: new FormControl(charge.modifier2),
              modifier3: new FormControl(charge.modifier3),
              modifier4: new FormControl(charge.modifier4),

              quantity: new FormControl(
                charge.defaultUnit,
                Validators.required
              ),
              totalAmount: new FormControl(charge.charges, Validators.required),
              paidAmount: new FormControl('', Validators.required),
              patientResponsibility: new FormControl(''),
              claimAdjustments: this.formBuilder.array([]),
            })
          );
        });

        // Finally push this control
        control.push(controlToAdd);
      }
    });
  }

  removeClaim(index) {
    const control = <FormArray>this.remitForm.get('claimLevelLoop');
    control.removeAt(index);
  }

  claimStatusCodeChanged(event, claimIndex) {
    const control = <FormArray>this.remitForm.get('claimLevelLoop');
    const controlFormGroup = <FormGroup>control.at(claimIndex);

    let filteredClaimStatusCode = this.claimStatusCodeList
      .filter((code) => (code.id = event.value))
      .at(0);

    controlFormGroup.controls['claimStatusDescription'].setValue(
      filteredClaimStatusCode?.description
    );
  }

  addClaimLevelAdjustment(claimIndex) {
    const control = <FormArray>this.remitForm.get('claimLevelLoop');

    const controlFormGroup = <FormGroup>control.at(claimIndex);

    const claimLevelAdjustmentControl = <FormArray>(
      controlFormGroup.get('claimLevelAdjustments')
    );

    claimLevelAdjustmentControl.push(
      new FormGroup({
        groupCode: new FormControl('', Validators.required),
        groupCodeDescription: new FormControl(''),
        reasonCode: new FormControl('', Validators.required),
        reasonCodeDescription: new FormControl(''),
        amount: new FormControl('', Validators.required),
        quantity: new FormControl(''),
      })
    );
  }

  removeClaimLevelAdjustments(claimIndex, adjustmentIndex) {
    const control = <FormArray>this.remitForm.get('claimLevelLoop');

    const controlFormGroup = <FormGroup>control.at(claimIndex);
    const claimLevelAdjustmentControl = <FormArray>(
      controlFormGroup.get('claimLevelAdjustments')
    );

    claimLevelAdjustmentControl.removeAt(adjustmentIndex);
  }

  claimLevelAdjustmentGroupCodeChanged(event, claimIndex, adjustmentIndex) {
    let selectedAdjustment = this.adjustmentGroupCodesList
      .filter((adjustment) => adjustment.code === event.value)
      .at(0);

    const control = <FormArray>this.remitForm.get('claimLevelLoop');

    const controlFormGroup = <FormGroup>control.at(claimIndex);
    const claimLevelAdjustmentControl = <FormArray>(
      controlFormGroup.get('claimLevelAdjustments')
    );

    const controlAdjustmentFormGroup = <FormGroup>(
      claimLevelAdjustmentControl.at(adjustmentIndex)
    );

    controlAdjustmentFormGroup.controls['groupCodeDescription'].setValue(
      selectedAdjustment.description
    );
  }

  claimLevelAdjustmentReasonCodeChanged(event, claimIndex, adjustmentIndex) {
    let selectedReason = this.adjustmentReasonCodesList
      .filter((adjustment) => adjustment.code === event.value)
      .at(0);

    const control = <FormArray>this.remitForm.get('claimLevelLoop');

    const controlFormGroup = <FormGroup>control.at(claimIndex);
    const claimLevelAdjustmentControl = <FormArray>(
      controlFormGroup.get('claimLevelAdjustments')
    );

    const controlAdjustmentFormGroup = <FormGroup>(
      claimLevelAdjustmentControl.at(adjustmentIndex)
    );

    controlAdjustmentFormGroup.controls['reasonCodeDescription'].setValue(
      selectedReason.description
    );
  }

  addProviderLevelAdjustment(claimIndex) {
    const control = <FormArray>this.remitForm.get('claimLevelLoop');

    const controlFormGroup = <FormGroup>control.at(claimIndex);

    const providerLevelAdjustmentControl = <FormArray>(
      controlFormGroup.get('providerLevelAdjustments')
    );

    providerLevelAdjustmentControl.push(
      new FormGroup({
        groupCode: new FormControl('', Validators.required),
        groupCodeDescription: new FormControl(''),
        reasonCode: new FormControl('', Validators.required),
        reasonCodeDescription: new FormControl(''),
        amount: new FormControl('', Validators.required),
        quantity: new FormControl(''),
      })
    );
  }

  removeProviderLevelAdjustments(claimIndex, adjustmentIndex) {
    const control = <FormArray>this.remitForm.get('claimLevelLoop');

    const controlFormGroup = <FormGroup>control.at(claimIndex);
    const providerLevelAdjustmentControl = <FormArray>(
      controlFormGroup.get('providerLevelAdjustments')
    );

    providerLevelAdjustmentControl.removeAt(adjustmentIndex);
  }

  providerLevelAdjustmentGroupCodeChanged(event, claimIndex, adjustmentIndex) {
    let selectedAdjustment = this.adjustmentGroupCodesList
      .filter((adjustment) => adjustment.code === event.value)
      .at(0);

    const control = <FormArray>this.remitForm.get('claimLevelLoop');

    const controlFormGroup = <FormGroup>control.at(claimIndex);
    const providerLevelAdjustmentControl = <FormArray>(
      controlFormGroup.get('providerLevelAdjustments')
    );

    const controlAdjustmentFormGroup = <FormGroup>(
      providerLevelAdjustmentControl.at(adjustmentIndex)
    );

    controlAdjustmentFormGroup.controls['groupCodeDescription'].setValue(
      selectedAdjustment.description
    );
  }

  providerLevelAdjustmentReasonCodeChanged(event, claimIndex, adjustmentIndex) {
    let selectedReason = this.adjustmentReasonCodesList
      .filter((adjustment) => adjustment.code === event.value)
      .at(0);

    const control = <FormArray>this.remitForm.get('claimLevelLoop');

    const controlFormGroup = <FormGroup>control.at(claimIndex);
    const providerLevelAdjustmentControl = <FormArray>(
      controlFormGroup.get('providerLevelAdjustments')
    );

    const controlAdjustmentFormGroup = <FormGroup>(
      providerLevelAdjustmentControl.at(adjustmentIndex)
    );

    controlAdjustmentFormGroup.controls['reasonCodeDescription'].setValue(
      selectedReason.description
    );
  }

  addServiceLineLevelAdjustment(claimIndex, serviceLineIndex) {
    const control = <FormArray>this.remitForm.get('claimLevelLoop');
    const controlFormGroup = <FormGroup>control.at(claimIndex);

    const serviceLineLoopControl = <FormArray>(
      controlFormGroup.get('serviceLineLoop')
    );
    const serviceLineFormGroup = <FormGroup>(
      serviceLineLoopControl.at(serviceLineIndex)
    );

    const serviceLineClaimAdjustmentControl = <FormArray>(
      serviceLineFormGroup.get('claimAdjustments')
    );

    serviceLineClaimAdjustmentControl.push(
      new FormGroup({
        groupCode: new FormControl('', Validators.required),
        groupCodeDescription: new FormControl(''),
        reasonCode: new FormControl('', Validators.required),
        reasonCodeDescription: new FormControl(''),
        amount: new FormControl('', Validators.required),
        quantity: new FormControl(''),
      })
    );
  }

  removeServiceLineAdjustments(claimIndex, serviceLineIndex, adjustmentIndex) {
    const control = <FormArray>this.remitForm.get('claimLevelLoop');
    const controlFormGroup = <FormGroup>control.at(claimIndex);

    const serviceLineLoopControl = <FormArray>(
      controlFormGroup.get('serviceLineLoop')
    );
    const serviceLineFormGroup = <FormGroup>(
      serviceLineLoopControl.at(serviceLineIndex)
    );

    const serviceLineClaimAdjustmentControl = <FormArray>(
      serviceLineFormGroup.get('claimAdjustments')
    );

    serviceLineClaimAdjustmentControl.removeAt(adjustmentIndex);
  }

  serviceLineAdjustmentGroupCodeChanged(
    event,
    claimIndex,
    serviceLineIndex,
    adjustmentIndex
  ) {
    let selectedAdjustment = this.adjustmentGroupCodesList
      .filter((adjustment) => adjustment.code === event.value)
      .at(0);

    const control = <FormArray>this.remitForm.get('claimLevelLoop');
    const controlFormGroup = <FormGroup>control.at(claimIndex);

    const serviceLineLoopControl = <FormArray>(
      controlFormGroup.get('serviceLineLoop')
    );
    const serviceLineFormGroup = <FormGroup>(
      serviceLineLoopControl.at(serviceLineIndex)
    );

    const serviceLineClaimAdjustmentControl = <FormArray>(
      serviceLineFormGroup.get('claimAdjustments')
    );

    const controlAdjustmentFormGroup = <FormGroup>(
      serviceLineClaimAdjustmentControl.at(adjustmentIndex)
    );

    controlAdjustmentFormGroup.controls['groupCodeDescription'].setValue(
      selectedAdjustment.description
    );
  }

  serviceLineAdjustmentReasonCodeChanged(
    event,
    claimIndex,
    serviceLineIndex,
    adjustmentIndex
  ) {
    let selectedReason = this.adjustmentReasonCodesList
      .filter((adjustment) => adjustment.code === event.value)
      .at(0);

    const control = <FormArray>this.remitForm.get('claimLevelLoop');
    const controlFormGroup = <FormGroup>control.at(claimIndex);

    const serviceLineLoopControl = <FormArray>(
      controlFormGroup.get('serviceLineLoop')
    );
    const serviceLineFormGroup = <FormGroup>(
      serviceLineLoopControl.at(serviceLineIndex)
    );

    const serviceLineClaimAdjustmentControl = <FormArray>(
      serviceLineFormGroup.get('claimAdjustments')
    );

    const controlAdjustmentFormGroup = <FormGroup>(
      serviceLineClaimAdjustmentControl.at(adjustmentIndex)
    );

    controlAdjustmentFormGroup.controls['reasonCodeDescription'].setValue(
      selectedReason.description
    );
  }

  saveRemit() {
    this.remitDetails = Object.assign({}, this.remitDetails);
    this.remitDetails = Object.assign(this.remitDetails, this.remitForm.value);

    // Validate remit Details()
    if (this.validateRemitDetails()) {
      // We need to convert payment Effective date to YYYYMMDD format
      this.remitDetails.paymentEffectiveDate = convertDateToYYYYMMDDFormat(
        this.remitForm.controls['paymentEffectiveDate'].value
      );

      // We need to convert payment Effective date to YYYYMMDD format for each claim object
      this.remitDetails.claimLevelLoop.forEach((claimObj, index) => {
        const control = <FormArray>this.remitForm.get('claimLevelLoop');
        const controlFormGroup = <FormGroup>control.at(index);

        // At claim level
        claimObj.paymentEffectiveDate = convertDateToYYYYMMDDFormat(
          controlFormGroup.controls['paymentEffectiveDate'].value
        );

        // At service line level
        const serviceLineLoopControl = <FormArray>(
          controlFormGroup.get('serviceLineLoop')
        );
        serviceLineLoopControl.controls.forEach(
          (serviceLineLoop: FormGroup, serviceLineIndex) => {
            claimObj.serviceLineLoop[serviceLineIndex].adjudicationDate =
              convertDateToYYYYMMDDFormat(
                serviceLineLoop.controls['adjudicationDate'].value
              );
          }
        );
      });

      // Now that the dates are converted, call in API to update/create
      this.processing = true;

      this.remittancesAPIService
        .recordManualRemitPayment(this.remitDetails)
        .subscribe({
          next: (response) => {
            this.processing = false;
            this.toastMessageService.displaySuccessMessage(
              'Successfully saved the remit details.'
            );
            this.router.navigate(['/remittances/remit', response.data.id]);
          },
          error: (error) => {
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to save the remit details.'
            );
            this.processing = false;
          },
        });
    }
  }

  validateRemitDetails(): boolean {
    // Resetting the errors list
    this.errorsList = [];

    // First extracting the total paid amount for this remit
    let totalRemitPaidAmount = this.remitDetails.paymentAmount;

    // Now for each claim object, we add up the paid amount and make sure this amount matches
    let sumOfClaimPaidAmount: number = 0;

    this.remitDetails.claimLevelLoop.forEach(
      (claimLevelObject, claimLevelIndex) => {
        // Add in the payment amount for this claim
        sumOfClaimPaidAmount += claimLevelObject.paidAmount;
        sumOfClaimPaidAmount = parseFloat(sumOfClaimPaidAmount.toFixed(2));

        let claimLevelSubmittedAmount: number = claimLevelObject.totalAmount;
        let sumOfServiceLineAmounts: number = 0;

        // For this claim object, check if the service line total sums up
        claimLevelObject.serviceLineLoop.forEach((serviceLineLoop, i) => {
          // This is a for loop
          sumOfServiceLineAmounts += serviceLineLoop.paidAmount;
          sumOfServiceLineAmounts = parseFloat(
            sumOfServiceLineAmounts.toFixed(2)
          );

          //If there are adjustments
          serviceLineLoop.claimAdjustments.forEach(
            (serviceLineClaimAdjustment) => {
              sumOfServiceLineAmounts += serviceLineClaimAdjustment.amount;
              sumOfServiceLineAmounts = parseFloat(
                sumOfServiceLineAmounts.toFixed(2)
              );
            }
          );
        });

        // Now check if the claim Level Submitted Amount is equal to sum of service line level charges
        if (claimLevelSubmittedAmount != sumOfServiceLineAmounts) {
          // Push in error if the service line amount do not match up
          this.errorsList.push(
            'For Claim ' +
              claimLevelObject.claimCode +
              ', the submitted amount $' +
              claimLevelObject.totalAmount +
              ' does not equal to the sum of the paid amount and adjustments at service level ($' +
              sumOfServiceLineAmounts +
              ').'
          );
        }
      }
    );

    // Now check if the total paid amount is equal to sum of claim paid amounts
    if (totalRemitPaidAmount != sumOfClaimPaidAmount) {
      // Push in error if the service line amount do not match up
      this.errorsList.push(
        'The total paid amount ($' +
          totalRemitPaidAmount +
          ') is not equal to sum of all claim paid amounts ($' +
          sumOfClaimPaidAmount +
          ').'
      );
    }

    if (this.errorsList.length > 0) {
      this.toastMessageService.displayWarnMessage(
        'Please fix the following issues'
      );
      window.scroll(0, 0);
    }
    return this.errorsList.length == 0 ? true : false;
  }

  cancelEdit() {
    this.router.navigate(['/remittances/remit', this.editRemitId]);
  }
}
