<div>
  <!-- Heading -->
  <div class="col-md-12">
    <p style="font-size: 16px; font-weight: 450">
      <a routerLink="/" routerLinkActive="active">
        <em
          class="material-icons text_align-center"
          style="font-size: 18px; margin-top: -3px"
          >home</em
        >
      </a>
      &nbsp; / &nbsp; Assessments
    </p>
  </div>

  <mat-card-content>
    <div
      class="row"
      style="margin-top: 25px; margin-bottom: 20px; margin-left: 0px"
    >
      <div class="col-md-8">
        <mat-chip-listbox
          [(ngModel)]="selectedOption"
          aria-label="Status"
          style="float: left"
        >
          <mat-chip-option
            color="primary"
            #event
            *ngFor="let option of options"
            [value]="option.id"
            (click)="toggleSelection(option.id, event)"
          >
            {{ option.description }}
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
    </div>

    <app-assessment-history
      [showHeader]="true"
      [status]="selectedOption"
      [showSearchMember]="true"
      [hideCompletedBy]="false"
      [hideCreatedDate]="false"
      [hasMemberAssessmentViewAccess]="hasMemberAssessmentViewAccess"
      [hasMemberAssessmentAddAccess]="hasMemberAssessmentAddAccess"
      [hasMemberAssessmentEditAccess]="hasMemberAssessmentEditAccess"
      [hasMemberAssessmentDeleteAccess]="hasMemberAssessmentDeleteAccess"
      [hasAssessmentChartsViewAccess]="hasAssessmentChartsViewAccess"
      [hasAssessmentGuidanceViewAccess]="hasAssessmentGuidanceViewAccess"
      [hasAssessmentGuidanceEditAccess]="hasAssessmentGuidanceEditAccess"
      [hasAssessmentSummaryViewAccess]="hasAssessmentSummaryViewAccess"
      [hasAssessmentSummaryEditAccess]="hasAssessmentSummaryEditAccess"
      [hasAssessmentTakeAddAccess]="hasAssessmentTakeAddAccess"
    >
    </app-assessment-history>
  </mat-card-content>
</div>
