<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Edit Diagnosis</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="diagnosisForm" novalidate>
    <div class="row">
      <div class="col-md-12">
        <label class="labelText">
          ICD Code <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input matInput formControlName="icdCode" name="icdCode" readonly />
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <label class="labelText">
          Description<span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            formControlName="shortDesc"
            name="shortDesc"
            readonly
          />
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <label class="labelText">
          Status<span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="status">
            <mat-option value="Active">Active</mat-option>
            <mat-option value="Inactive">Inactive</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-12" style="text-align: right">
        <button
          class="btn btn-primary"
          mat-raised-button
          type="button"
          [disabled]="!diagnosisForm.valid || processing"
          (click)="submitForm()"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
