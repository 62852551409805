import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function npiValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;

        // NPI must be exactly 10 digits
        const npiRegex = /^[0-9]{10}$/;

        if (!value) {
            return { required: true };
        }

        if (!npiRegex.test(value)) {
            return { invalidNPI: true };
        }

        // Validate NPI using the Luhn algorithm
        if (!isValidNPI(value)) {
            return { invalidNPI: true };
        }

        return null; // No errors
    };
}

function isValidNPI(npi: string): boolean {
    // NPI must be 10 digits long
    if (npi.length !== 10) {
        return false;
    }

    // Prepend the "80840" prefix for the Luhn algorithm
    const npiWithPrefix = '80840' + npi;

    // Convert to array of numbers
    const npiArray = npiWithPrefix.split('').map(Number);

    // Apply the Luhn algorithm
    let sum = 0;
    for (let i = 0; i < npiArray.length; i++) {
        let currentDigit = npiArray[i];

        // Double every second digit starting from the right
        if (i % 2 === npiArray.length % 2) {
            currentDigit *= 2;
            if (currentDigit > 9) {
                currentDigit -= 9;
            }
        }
        sum += currentDigit;
    }

    // Valid if sum modulo 10 equals 0
    return sum % 10 === 0;
}
