<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Adolescent Depression Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="adolescentDepressionForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >1. Low mood, sadness, feeling blah or down, depressed, just can’t
            be bothered.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="lowMood">
              <mat-button-toggle value="hardly ever"
                >hardly ever</mat-button-toggle
              >
              <mat-button-toggle value="much of the time"
                >much of the time</mat-button-toggle
              >
              <mat-button-toggle value="most of the time"
                >most of the time</mat-button-toggle
              >
              <mat-button-toggle value="all the time"
                >all the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >2. Irritable, losing your temper easily, feeling pissed off, losing
            it.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="losingIt">
              <mat-button-toggle value="hardly ever"
                >hardly ever</mat-button-toggle
              >
              <mat-button-toggle value="much of the time"
                >much of the time</mat-button-toggle
              >
              <mat-button-toggle value="most of the time"
                >most of the time</mat-button-toggle
              >
              <mat-button-toggle value="all the time"
                >all the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >3. Sleeping differently from your usual (over the years before you
            got sick): trouble falling asleep, lying awake in bed.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="sleepDifficulties">
              <mat-button-toggle value="hardly ever"
                >hardly ever</mat-button-toggle
              >
              <mat-button-toggle value="much of the time"
                >much of the time</mat-button-toggle
              >
              <mat-button-toggle value="most of the time"
                >most of the time</mat-button-toggle
              >
              <mat-button-toggle value="all the time"
                >all the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >4. Feeling decreased interest in: hanging out with friends; being
            with your best friend; being with your boyfriend/girlfriend; going
            out of the house; doing school work or work; doing hobbies or sports
            or recreation.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="decreasedInterest">
              <mat-button-toggle value="hardly ever"
                >hardly ever</mat-button-toggle
              >
              <mat-button-toggle value="much of the time"
                >much of the time</mat-button-toggle
              >
              <mat-button-toggle value="most of the time"
                >most of the time</mat-button-toggle
              >
              <mat-button-toggle value="all the time"
                >all the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >5. Feelings of worthlessness, hopelessness, letting people down,
            not being a good person</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="feelingWorthless">
              <mat-button-toggle value="hardly ever"
                >hardly ever</mat-button-toggle
              >
              <mat-button-toggle value="much of the time"
                >much of the time</mat-button-toggle
              >
              <mat-button-toggle value="most of the time"
                >most of the time</mat-button-toggle
              >
              <mat-button-toggle value="all the time"
                >all the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >6. Feeling tired, feeling fatigued, low in energy, hard to get
            motivated, have to push to get things done, want to rest or lie down
            a lot.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="tired">
              <mat-button-toggle value="hardly ever"
                >hardly ever</mat-button-toggle
              >
              <mat-button-toggle value="much of the time"
                >much of the time</mat-button-toggle
              >
              <mat-button-toggle value="most of the time"
                >most of the time</mat-button-toggle
              >
              <mat-button-toggle value="all the time"
                >all the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >7. Trouble concentrating, can't keep your mind on schoolwork or
            work, daydreaming when you should be working, hard to focus when
            reading, getting “bored” with work or school.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="troubleConcentration">
              <mat-button-toggle value="hardly ever"
                >hardly ever</mat-button-toggle
              >
              <mat-button-toggle value="much of the time"
                >much of the time</mat-button-toggle
              >
              <mat-button-toggle value="most of the time"
                >most of the time</mat-button-toggle
              >
              <mat-button-toggle value="all the time"
                >all the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >8. Feeling that life is not very much fun, not feeling good when
            usually (before getting sick) would feel good, not getting as much
            pleasure from fun things as usual (before getting sick)</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="lifeIsNotVeryFun">
              <mat-button-toggle value="hardly ever"
                >hardly ever</mat-button-toggle
              >
              <mat-button-toggle value="much of the time"
                >much of the time</mat-button-toggle
              >
              <mat-button-toggle value="most of the time"
                >most of the time</mat-button-toggle
              >
              <mat-button-toggle value="all the time"
                >all the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >9. Feeling worried, nervous, panicky, tense, keyed up,
            anxious.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="worried">
              <mat-button-toggle value="hardly ever"
                >hardly ever</mat-button-toggle
              >
              <mat-button-toggle value="much of the time"
                >much of the time</mat-button-toggle
              >
              <mat-button-toggle value="most of the time"
                >most of the time</mat-button-toggle
              >
              <mat-button-toggle value="all the time"
                >all the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >10. Physical feelings of worry like: headaches, butterflies,
            nausea, tingling, restlessness, diarrhea, shakes or tremors.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="physicalFeelings">
              <mat-button-toggle value="hardly ever"
                >hardly ever</mat-button-toggle
              >
              <mat-button-toggle value="much of the time"
                >much of the time</mat-button-toggle
              >
              <mat-button-toggle value="most of the time"
                >most of the time</mat-button-toggle
              >
              <mat-button-toggle value="all the time"
                >all the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >11. Thoughts, plans or actions about suicide or self-harm.</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              [vertical]="true"
              style="width: 100%"
              formControlName="selfHarm"
            >
              <mat-button-toggle value="no thoughts or plans or actions"
                >no thoughts or plans or actions</mat-button-toggle
              >
              <mat-button-toggle
                value="occasional thoughts; no plans or actions"
                >occasional thoughts; no plans or actions</mat-button-toggle
              >
              <mat-button-toggle value="frequent thoughts; no plans or actions"
                >frequent thoughts; no plans or actions</mat-button-toggle
              >
              <mat-button-toggle value="plans and/or actions that have hurt"
                >plans and/or actions that have hurt</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          adolescentDepressionForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!adolescentDepressionForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
