<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Signature</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="row">
    <div
      #canvasContainer
      class="col-md-12"
      style="display: flex; justify-content: center"
    >
      <canvas #canvas height="250"></canvas>
    </div>

    <div
      class="col-md-12"
      style="display: flex; justify-content: space-between; margin-top: 25px"
    >
      <!-- Left side -->

      <button mat-raised-button type="button" (click)="clearPad()">
        Clear
      </button>

      <button
        mat-raised-button
        color="primary"
        type="button"
        (click)="saveSignature()"
        [disabled]="processing || !signatureStroke"
      >
        Submit Signature
        <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </div>
  </div>
</div>
