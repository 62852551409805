<!-- Group Level Notes -->
<div class="row" style="margin-top: 25px">
  <!-- Note not started Disclaimer -->
  <div class="col-md-12" *ngIf="!sessionDetails?.clinicalNoteId">
    <mat-error> Note has not been started yet </mat-error>
  </div>

  <!-- Note name and font size option -->
  <div class="col-md-8" style="display: flex; align-items: center">
    <!-- Header Note Tpe -->
    <label
      class="labelHeader justifyCenterContainer"
      *ngIf="sessionDetails?.clinicalNoteId"
    >
      Note - {{ sessionDetails?.clinicalNoteTypeName }}
    </label>

    <!-- Current Note Heading and details -->
    <!-- Handle Font Size -->
    <label *ngIf="sessionDetails?.clinicalNoteId">
      <div class="justifyCenterContainer">
        <button
          class="decreaseFontSizeButton"
          mat-icon-button
          type="button"
          (click)="handleFontSizeChange('decrease')"
        >
          <mat-icon>remove_circle</mat-icon>
        </button>
        Font
        <button
          class="increaseFontSizeButton"
          mat-icon-button
          type="button"
          (click)="handleFontSizeChange('increase')"
        >
          <mat-icon>add_circle</mat-icon>
        </button>
      </div>
    </label>
  </div>
  <!-- Load from last -->
  <div
    class="col-md-4"
    style="text-align: right"
    *ngIf="sessionDetails?.clinicalNoteId && sessionDetails.status === 'Active'"
  >
    <button
      mat-raised-button
      type="button"
      style="color: #2196f3"
      class="mat-elevation-z8"
      (click)="loadLastNote()"
    >
      <mat-icon color="primary">content_copy</mat-icon> Load Last Note
    </button>
  </div>

  <!-- Now display all the note sections -->
  <div
    class="col-md-12"
    *ngIf="sessionDetails?.clinicalNoteId && editor.length > 0"
  >
    <div class="row">
      <!-- Progress Bar -->
      <div class="col-md-12">
        <mat-progress-bar
          mode="indeterminate"
          *ngIf="isLoadingNGXEditors"
        ></mat-progress-bar>
      </div>

      <!-- Notes with NGX Editor Bar -->
      <!-- Looping through all the editor or form controls -->
      <div class="sideBarEndView">
        <!-- Editor -->
        <form [formGroup]="clinicalNoteForm">
          <div class="row">
            <!-- Left Side is for Group Level Notes -->
            <div [class]="tabPanelOpen ? 'col-md-6 col-xl-8' : 'col-md-12'">
              <div [class]="tabPanelOpen ? 'row maxHeightContatiner' : 'row'">
                <div
                  [class]="
                    selectedSectionName === section.name
                      ? 'highlitedBox col-md-12'
                      : ' col-md-12'
                  "
                  *ngFor="
                    let section of memberNotes?.sessionNotes?.sections;
                    let i = index
                  "
                  style="margin-bottom: 8px"
                >
                  <!-- Note Section header -->
                  <div class="sectionHeader">
                    <label style="margin-top: auto">
                      {{ section.name }}
                    </label>
                    <button
                      mat-button
                      color="primary"
                      type="button"
                      *ngIf="
                        selectedSectionName === section.name &&
                        hasChangesMap[section.id]
                      "
                    >
                      <mat-icon>autorenew</mat-icon> Saving
                    </button>
                    <button
                      mat-button
                      color="primary"
                      type="button"
                      *ngIf="
                        selectedSectionName === section.name &&
                        hadChangesEarlier[section.id] &&
                        autosaved
                      "
                    >
                      Auto Saved
                    </button>

                    <!-- Tab panel selection -->
                    <div *ngIf="sessionDetails.status === 'Active'">
                      <button
                        mat-button
                        color="primary"
                        type="button"
                        (click)="
                          openTabPanel(
                            'Text Shortcuts',
                            section.id,
                            section.name,
                            i
                          )
                        "
                      >
                        Text Shortcuts
                      </button>
                      <button
                        mat-button
                        color="primary"
                        type="button"
                        (click)="
                          openTabPanel('Templates', section.id, section.name, i)
                        "
                      >
                        Templates
                      </button>
                    </div>
                  </div>

                  <!-- // For Displaying group member note -->
                  <div class="groupLevelNoteContainer">
                    <mat-accordion>
                      <mat-expansion-panel style="border-radius: 15px">
                        <mat-expansion-panel-header style="padding-left: 0">
                          <div
                            class="justifyCenterContainer"
                            *ngIf="sessionDetails?.status === 'Active'"
                          >
                            <mat-checkbox
                              [checked]="isIncludeGroupNoteChecked(section.id)"
                              color="primary"
                              (click)="$event.stopPropagation()"
                              (change)="
                                groupSessionSectionConfigChanged(
                                  $event,
                                  section.id
                                )
                              "
                            >
                            </mat-checkbox>
                            <label style="margin: 0">
                              Include group-level note in print
                            </label>
                          </div>
                          <label
                            *ngIf="sessionDetails?.status != 'Active'"
                            style="padding-left: 15px"
                          >
                            View Group Level Note
                          </label>
                        </mat-expansion-panel-header>
                        <!-- // Show the inner content label -->
                        <label
                          [innerHTML]="groupSectionNotesMap.get(section.id)"
                        >
                        </label>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>

                  <!-- Editor-->
                  <div class="NgxEditor__Wrapper">
                    <!-- Editor Header -->
                    <ngx-editor-menu
                      [editor]="editor[i]"
                      [toolbar]="toolbar"
                      *ngIf="sessionDetails?.status === 'Active'"
                    >
                    </ngx-editor-menu>
                    <!-- Editor  -->
                    <ngx-editor
                      [editor]="editor[i]"
                      [formControlName]="section.id"
                      spellcheck="true"
                      (click)="
                        editorClicked($event, section.id, section.name, i);
                        hasChangesMap[section.id] = false
                      "
                      (ngModelChange)="onEditorChange($event, section.id)"
                    >
                    </ngx-editor>
                  </div>
                </div>
              </div>
            </div>

            <!-- Text Shortcut box -->
            <div
              *ngIf="tabPanelOpen"
              class="col-md-6 col-xl-4 mobileSectionView maxHeightContatiner"
            >
              <!-- General Card to handle shortcut and templates -->
              <app-clinical-shortcut-templates
                [activeTab]="activeTab"
                [selectedSectionName]="selectedSectionName"
                (shortCutResponse)="handleShortCutResponse($event)"
                (templateResponse)="handleTemplateResponse($event)"
                (closeCardTab)="closePanel()"
              >
              </app-clinical-shortcut-templates>
            </div>
          </div>
        </form>

        <!-- Sidebar -->
        <mat-card
          appearance="raised"
          class="mat-elevation-z8 sidebarCard"
          (mouseenter)="hover = true"
          (mouseleave)="hover = false"
          [class.expanded]="hover"
        >
          <div
            class="menuItem"
            *ngFor="let sideMenu of sideBarMenuItems"
            (click)="sideMenuClicked(sideMenu)"
          >
            <mat-icon>{{ sideMenu.icon }}</mat-icon>
            <span class="onHover" *ngIf="hover"> {{ sideMenu.name }} </span>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
