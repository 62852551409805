import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import {
  DataTablesModel,
  IOrgConfig,
  ITherapySession,
  Patient,
} from 'src/app/_models';
import { TherapySessionService, ToastMessageService } from 'src/app/_services';
import { DateTimeZoneService } from 'src/app/_services/date-time-zone.service';
import {
  SessionQueues,
  SessionQueuesColorMap,
} from 'src/app/shared/utilities/session/sessionQueues';
import { StartPhoneNoteComponent } from './dialogs/start-phone-note/start-phone-note.component';
import { SessionGeneralNotesComponent } from './session-general-notes/session-general-notes.component';

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.css'],
})
export class SessionsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() orgConfig: IOrgConfig;
  @Input() sessionId: string;
  @Input() patient: Patient;
  @Input() secondaryTab: string;
  @Input() status: string;

  @Input() preSelectedSession: string;

  @Input() hasMemberPaymentsViewAccess: boolean;
  @Input() hasMemberPaymentsAddAccess: boolean;
  @Input() hasMemberPaymentsEditAccess: boolean;
  @Input() hasMemberPaymentsDeleteAccess: boolean;

  @Input() hasCaseManagementFeature: boolean;
  @Input() hasMemberEditAccess: boolean;
  @Input() hasTeleHealthEnabled: boolean;
  @Input() hasHospitalDeviceFeature: boolean;
  @Input() hasClaimsFeature: boolean;
  @Input() hasASIFeatures: boolean;

  @Input() hasTherapySessionViewAccess: boolean;
  @Input() hasTherapySessionAddAccess: boolean;
  @Input() hasTherapySessionEditAccess: boolean;
  @Input() hasTherapySessionDeleteAccess: boolean;

  @Input() hasBPSAIntakeViewAccess: boolean;
  @Input() hasBPSAIntakeAddAccess: boolean;
  @Input() hasBPSAIntakeEditAccess: boolean;

  @Input() hasBPSAClinicalViewAccess: boolean;
  @Input() hasBPSAClinicalAddAccess: boolean;
  @Input() hasBPSAClinicalEditAccess: boolean;

  @Input() hasClinicalNotesViewAccess: boolean;
  @Input() hasClinicalNotesAddAccess: boolean;
  @Input() hasClinicalNotesEditAccess: boolean;

  @Input() hasSubjectivesViewAccess: boolean;
  @Input() hasSubjectivesEditAccess: boolean;

  @Input() hasObjectivesViewAccess: boolean;
  @Input() hasObjectivesEditAccess: boolean;

  @Input() hasSessionDocumentsViewAccess: boolean;
  @Input() hasSessionDocumentsAddAccess: boolean;
  @Input() hasSessionDocumentsDeleteAccess: boolean;

  @Input() hasMemberAssessmentViewAccess: boolean;
  @Input() hasMemberAssessmentAddAccess: boolean;
  @Input() hasMemberAssessmentEditAccess: boolean;
  @Input() hasMemberAssessmentDeleteAccess: boolean;
  @Input() hasAssessmentTakeAddAccess: boolean;

  @Input() hasAssessmentChartsViewAccess: boolean;
  @Input() hasAssessmentGuidanceViewAccess: boolean;
  @Input() hasAssessmentGuidanceEditAccess: boolean;
  @Input() hasAssessmentSummaryViewAccess: boolean;
  @Input() hasAssessmentSummaryEditAccess: boolean;

  @Input() hasBillingViewAccess: boolean;
  @Input() hasBillingEditAccess: boolean;

  @Input() hasGoalsViewAccess: boolean;
  @Input() hasGoalsAddAccess: boolean;
  @Input() hasGoalsEditAccess: boolean;
  @Input() hasGoalsDeleteAccess: boolean;

  @Input() hasDiagnosisViewAccess: boolean;
  @Input() hasDiagnosisAddAccess: boolean;
  @Input() hasDiagnosisEditAccess: boolean;
  @Input() hasDiagnosisDeleteAccess: boolean;

  @Input() hasMedicationViewAccess: boolean;
  @Input() hasMedicationAddAccess: boolean;
  @Input() hasMedicationEditAccess: boolean;
  @Input() hasMedicationDeleteAccess: boolean;
  @Input() prescriptionEnabled: boolean;

  @Output() output = new EventEmitter<any>();
  @Input() reload: Subject<boolean> = new Subject<boolean>();
  private subscriptions: Subscription = new Subscription();

  sessionQueues = SessionQueues;
  sessionQueuesColorMap = SessionQueuesColorMap;
  newIntakeSession: ITherapySession;

  displayedColumns = ['sessionCode', 'time', 'visitReason', 'queue'];
  data: DataTablesModel = {} as DataTablesModel;

  isLoadingResults = true;
  apiError = false;

  currentTimezone: string;
  selectedTherapySession: ITherapySession;
  patientId: string;

  sortOrder: string = 'DESC';

  searchSessionCode: string = '';
  searchPatientLastName: string = '';
  searchPatientFirstName: string = '';
  searchStatus: string = '';

  constructor(
    public dialog: MatDialog,
    public therapySessionService: TherapySessionService,
    private toastMessageService: ToastMessageService,
    private router: Router,
    private dateTimeZoneService: DateTimeZoneService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit() {
    this.data.page = 0;
    this.data.per_page = 10;

    const reloadSubscription = this.reload.subscribe((reload) => {
      if (reload) {
        this.selectedTherapySession = null;
        this.loadTherapySessionList();
      }
    });
    this.subscriptions.add(reloadSubscription);

    if (this.sessionId) {
      this.loadTherapySessionDetails();
    } else {
      this.loadTherapySessionList();
    }

    // Check if delete access is there
    if (
      (this.hasTherapySessionEditAccess || this.hasMemberPaymentsAddAccess) &&
      this.status != 'Closed'
    ) {
      if (!this.displayedColumns.includes('action')) {
        this.displayedColumns.push('action');
      }
    }

    if (this.hasCaseManagementFeature) {
      if (!this.displayedColumns.includes('case')) {
        this.displayedColumns.splice(1, 0, 'case');
      }
    }

    if (this.status) {
      if (!this.displayedColumns.includes('memberName')) {
        this.displayedColumns.splice(2, 0, 'memberName');
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.hasTherapySessionEditAccess ||
      changes?.hasMemberPaymentsAddAccess
    ) {
      if (
        (this.hasTherapySessionEditAccess || this.hasMemberPaymentsAddAccess) &&
        this.status != 'Closed'
      ) {
        if (!this.displayedColumns.includes('action')) {
          this.displayedColumns.push('action');
        }
      }
    }

    // If session id changed
    if (changes.sessionId) {
      this.selectedTherapySession = null;
      this.loadTherapySessionDetails();
    }
  }

  selectedSession(selectedTherapySession: ITherapySession) {
    if (selectedTherapySession.groupAppointment) {
      // For Normal navigate to session edit
      this.router.navigate([`/group-sessions/${selectedTherapySession.id}`], {
        queryParams: {
          parent: 'Member',
          memId: this.patient?.id,
        },
      });
    } else {
      this.router.navigate(
        ['/main/member/' + selectedTherapySession.patientId + '/NOTES'],
        {
          queryParams: {
            secondaryId: selectedTherapySession.id,
            secondaryTab: 'notedetails',
            hideMemberDetails: true,
          },
        }
      );
    }
  }

  public loadTherapySessionDetails() {
    if (this.sessionId) {
      this.isLoadingResults = true;
      this.apiError = false;

      this.therapySessionService
        .getTherapySessionDetails(this.sessionId)
        .subscribe({
          next: (response) => {
            this.isLoadingResults = false;
            if (response) {
              this.selectedTherapySession = response.data;
            }
          },
          error: (error) => {
            this.apiError = true;
            this.isLoadingResults = false;
          },
        });
    }
  }

  public loadTherapySessionList() {
    this.isLoadingResults = true;
    this.apiError = false;

    if (this.patient) {
      this.therapySessionService
        .getPatientTherapySessions(
          this.patient.id,
          this.patient.organizationId,
          this.preSelectedSession,
          this.data.per_page,
          this.data.page,
          this.sortOrder,
          this.searchSessionCode,
          this.searchStatus
        )
        .subscribe({
          next: (response) => {
            this.isLoadingResults = false;
            if (response && response.items) {
              this.data.items = response.items;
              this.data.total = response.total;

              if (this.preSelectedSession) {
                let pre = this.data.items.filter(
                  (i) => i.sessionCode === this.preSelectedSession
                );
                this.selectedTherapySession = pre[0];
                this.preSelectedSession = null;

                this.output.emit({ eventType: 'DESELECT_PRESELECTED_SESSION' });
              }
            } else {
              this.data.items = [];
              this.data.total = 0;
            }
          },
          error: (error) => {
            this.apiError = true;
            this.isLoadingResults = false;
          },
        });
    } else {
      this.therapySessionService
        .getClinicianTherapySessions(
          this.status,
          this.searchSessionCode,
          this.searchPatientLastName,
          this.searchPatientFirstName,
          this.data.per_page,
          this.data.page
        )
        .subscribe({
          next: (response) => {
            this.isLoadingResults = false;
            if (response && response.items) {
              this.data.items = response.items;
              this.data.total = response.total;
            }
            if (response == null) {
              this.data.items = [];
              this.data.total = 0;
            }
          },
          error: (error) => {
            this.apiError = true;
            this.isLoadingResults = false;
          },
        });
    }
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadTherapySessionList();
  }

  public onEventTriggered(payload: any) {
    if (payload.eventType === 'DESELECT_SESSION') {
      this.selectedTherapySession = null;
      this.loadTherapySessionList();
    }
    if (payload.eventType === 'RELOAD_PENDING_ASSESSMENT_REQUESTS') {
      this.output.emit(payload);
    }
    if (payload.eventType === 'RELOAD_TELEHEALTH_IF_APPLICABLE') {
      this.output.emit(payload);
    }
    if (payload.eventType === 'CHANGE_THERAPY_SESSION_OBJECT') {
      this.selectedTherapySession = payload.newTherapyObject;
    }
    if (payload.eventType === 'RELOAD_PATIENT_PROFILE') {
      this.output.emit(payload);
    }
  }

  addNewTherapySession() {
    this.router.navigate(['/sessionscheduler', this.patient.id]);
  }

  searchBySessionId() {
    // Restore page index and paginator index
    this.data.page = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }

    this.loadTherapySessionList();
  }

  filterOptionChanged(filterOption: string) {
    // First restore pagination
    this.data.page = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }

    if (filterOption === 'Closed') {
      this.displayedColumns = this.displayedColumns.filter(
        (e) => e != 'action'
      );
    } else {
      if (
        this.hasTherapySessionDeleteAccess ||
        this.hasMemberPaymentsAddAccess
      ) {
        if (!this.displayedColumns.includes('action')) {
          this.displayedColumns.push('action');
        }
      }
    }
    this.searchStatus = filterOption;
    this.loadTherapySessionList();
  }

  viewSessionLevelNote(row) {
    let dialogRef = this.dialog.open(SessionGeneralNotesComponent, {
      data: {
        therapySession: row,
        hasEditAccess: this.hasTherapySessionEditAccess,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        this.loadTherapySessionList();
      }
    });
  }

  // Handle Queue Status Changed
  queueStatusChanged(row, newQueue: string) {
    this.isLoadingResults = true;
    // Call in the endpoint to update the session Queue Status
    this.therapySessionService.updateQueueStatus(row.id, newQueue).subscribe({
      next: (response) => {
        if (response?.result) {
          // Now update the event queue status and color
          this.isLoadingResults = false;
          // Reload the list
          this.loadTherapySessionList();
          // Display Popup
          this.toastMessageService.displaySuccessMessage(
            'Queue for session ' + row.title + '  has been updated'
          );
        }
      },
      error: (error) => {
        this.isLoadingResults = false;

        this.toastMessageService.displayErrorMessage(
          'Error: Failed to update the queue status for ' + row.title
        );
      },
    });
  }

  collectPayment(row) {
    this.router.navigate(['main/collectmemberpayment', row.patientId], {
      queryParams: { sessionId: row.id },
    });
  }

  startPhoneNote() {
    let dialogRef = this.dialog.open(StartPhoneNoteComponent, {
      data: { patientId: this.patient.id },
      autoFocus: false,
      disableClose: true,
      minWidth: '35vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadTherapySessionList();
      }
    });
  }

  rescheduleSession(row) {
    if (row?.groupAppointment) {
      // For Normal navigate to session edit
      this.router.navigate([`/schedules/groupsessionscheduler/${row.id}`], {
        queryParams: {
          parent: 'Member',
          memId: this.patient?.id,
        },
      });
    } else {
      this.router.navigate([`/schedules/reschedule/${row.id}`], {
        queryParams: {
          parent: 'Member',
        },
      });
    }
  }
}
