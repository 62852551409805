import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Patient } from 'src/app/_models';
import { PatientService } from 'src/app/_services';

@Component({
  selector: 'app-search-supervising-provider',
  templateUrl: './search-supervising-provider.component.html',
  styleUrls: ['./search-supervising-provider.component.css'],
})
export class SearchSupervisingProviderComponent implements OnInit {
  patient: Patient;
  parentClass: string;
  sessionId: string;
  clinicianList: any = [];
  filteredClinicianList: any = [];
  processing: boolean = false;

  searchBySPName: string = '';

  displayedColumn = ['name', 'email', 'phone', 'action'];

  constructor(
    public dialogRef: MatDialogRef<SearchSupervisingProviderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private patientService: PatientService
  ) {
    this.sessionId = data?.sessionId;
    this.patient = data.patient;
    this.parentClass = data.parentClass;
  }

  ngOnInit(): void {
    this.loadPhysiciansBasedOnPatient();
  }

  loadPhysiciansBasedOnPatient() {
    this.processing = true;
    this.clinicianList = [];
    this.filteredClinicianList = [];

    if (this.parentClass === 'Claims') {
      this.patientService
        .getSupervisorList(this.patient?.id, this.patient?.facilityId)
        .subscribe({
          next: (response) => {
            if (response && response.length > 0) {
              this.clinicianList = response;
              this.filteredClinicianList = response;
            } else {
              this.clinicianList = null;
              this.filteredClinicianList = null;
            }
            this.processing = false;
          },
          error: (error) => {
            this.processing = false;
          },
        });
    } else if (this.parentClass === 'Group Sessions') {
      this.patientService.getAllActiveSupervisors(this.sessionId).subscribe({
        next: (response) => {
          if (response && response?.items) {
            this.clinicianList = response.items;
            this.filteredClinicianList = response.items;
          } else {
            this.clinicianList = null;
            this.filteredClinicianList = null;
          }
          this.processing = false;
        },
        error: (error) => {
          this.processing = false;
        },
      });
    } else {
      this.patientService
        .getActiveProvidersAtMemberFacility(this.patient?.id)
        .subscribe({
          next: (response) => {
            if (response && response?.items) {
              this.clinicianList = response.items;
              this.filteredClinicianList = response.items;
            } else {
              this.clinicianList = null;
              this.filteredClinicianList = null;
            }
            this.processing = false;
          },
          error: (error) => {
            this.processing = false;
          },
        });
    }
  }

  filterRP(event) {
    if (event) {
      this.filteredClinicianList = this.clinicianList.filter((rp) => {
        const lastName = rp.firstName.toLowerCase();
        const firstName = rp.lastName.toLowerCase();

        const concatFirstLast = firstName + ' ' + lastName;
        const concatLastFirst = lastName + ' ' + firstName;

        if (
          concatFirstLast.includes(event.toLowerCase()) ||
          concatLastFirst.includes(event.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.filteredClinicianList = this.clinicianList;
    }
  }

  selectRP(row) {
    this.dialogRef.close({ type: 'success', details: row });
  }
}
