import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ITherapySession, Patient } from 'src/app/_models';
import { DocumentHttpService } from 'src/app/_services';
import { PermissionsSandbox } from 'src/app/shared/sandbox/permissions.sandbox';
import { SignAndUnlockComponent } from './sign-and-unlock/sign-and-unlock.component';

@Component({
  selector: 'app-clinician-informed-consent',
  templateUrl: './clinician-informed-consent.component.html',
  styleUrls: ['./clinician-informed-consent.component.css'],
})
export class ClinicianInformedConsentComponent implements OnInit, OnChanges {
  @Input() patient: Patient;
  @Input() therapySession: ITherapySession;
  @Input() loggedInUserId: string;
  @Output() updatedTherapySession = new EventEmitter<any>();

  processing = false;
  displayedColumns = ['role', 'name', 'signatureDate', 'action'];

  data: any = [];

  constructor(
    public dialog: MatDialog,
    public documentApiService: DocumentHttpService,
    protected permissionsSandbox: PermissionsSandbox
  ) {}

  ngOnInit() {
    this.buildSignatureData();
  }

  buildSignatureData() {
    this.data = [];
    if (this.therapySession) {
      // Building for rendering provider
      this.data.push({
        role: 'Clinician/Rendering Provider',
        name:
          this.therapySession.physicianLastName +
          ', ' +
          this.therapySession.physicianFirstName,
        signed:
          this.therapySession.physicianId ===
          this.therapySession.signedPhysicianId
            ? this.therapySession.physicianSigned
            : 'No',
        userId: this.therapySession.physicianId,
        signatureId: this.therapySession.physicianSignatureId,
        signatureDate:
          this.therapySession.physicianId ===
          this.therapySession.signedPhysicianId
            ? this.therapySession.physicianSignatureDate
            : '',
      });

      if (this.therapySession?.supervisingRenderingProvider === 'Yes') {
        this.data.push({
          role: 'Supervising Rendering Provider',
          name:
            this.therapySession.supervisingRPFirstName +
            ' ' +
            this.therapySession.supervisingRPLastName,
          signed:
            this.therapySession?.supervisingRPId ===
            this.therapySession.signedSupervisorId
              ? this.therapySession.supervisorSigned
              : 'No',
          userId: this.therapySession?.supervisingRPId,
          signatureId: this.therapySession.supervisorSignatureId,
          signatureDate:
            this.therapySession?.supervisingRPId ===
            this.therapySession.signedSupervisorId
              ? this.therapySession.supervisorSignatureDate
              : '',
        });
      }
    }
  }

  openSignature(row, type) {
    if (type === 'ADD') {
      if (this.loggedInUserId === row.userId) {
        let dialogRef = this.dialog.open(SignAndUnlockComponent, {
          data: {
            role: row.role,
            name: row.name,
            userId: row.userId,
            therapySession: this.therapySession,
            type: type,
          },
          disableClose: true,
          autoFocus: false,
          minWidth: '35vw',
        });
        dialogRef.afterClosed().subscribe((response) => {
          if (response && response.type === 'success') {
            this.updatedTherapySession.emit(response.details);
          }
        });
      }
    } else {
      this.dialog.open(SignAndUnlockComponent, {
        data: {
          role: row.role,
          name: row.name,
          userId: row.userId,
          therapySession: this.therapySession,
          type: type,
          signatureId: row.signatureId,
        },
        disableClose: true,
        autoFocus: false,
        minWidth: '35vw',
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.therapySession) {
      this.buildSignatureData();
    }
  }
}
