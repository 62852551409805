import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import moment from 'moment';
import { DataTablesModel } from 'src/app/_models';
import { YoungManiaApiService } from 'src/app/_services';
import { greenChart, hexToRGBA, orangeChart, redChart, yellowChart } from 'src/app/shared/utilities/utilities';
@Component({
  selector: 'app-young-mania-rating-charts',
  templateUrl: './young-mania-rating-charts.component.html',
  styleUrls: ['./young-mania-rating-charts.component.css']
})
export class YoungManiaRatingChartsComponent implements OnInit {
  @Input() patientId: string;
  @Input() assessmentId: string;
  lineChartData: ChartConfiguration<'line'>['data'] = {
    datasets: [{
      tension: 0.4,
      data: [], borderColor: '#EEEEEE',
      pointBackgroundColor: '#333333',
      pointBorderColor: '#FFFFFF',
      pointHoverBackgroundColor: '#FFFFFF',
      pointHoverBorderColor: '#333333',
      pointStyle: 'circle',
      pointRadius: 5,
    }]
  };

  lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        title: {
          display: false
        }
      },
      annotation: {
        annotations: {
          greenBg:
          {
            type: 'box',
            yMin: 0,
            yMax: 20.5,
            backgroundColor: hexToRGBA(greenChart.backgroundColor, 1),
            borderColor: greenChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          },
          redBg: {
            type: 'box',
            yMin: 20.5,
            yMax: 60,
            backgroundColor: hexToRGBA(redChart.backgroundColor, 1),
            borderColor: redChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          }
        }
      }
    },
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        },
        min: 0,
        max: 60,
      }
    }
  };
  fourAssessmentDetailChartOptions: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        title: {
          display: false
        }
      }
    },
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        },
        min: 0,
        max: 4,
        ticks: { stepSize: 1 }
      }
    }
  };
  eightAssessmentDetailChartOptions: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        title: {
          display: false
        }
      }
    },
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        },
        min: 0,
        max: 8,
        ticks: { stepSize: 2 }
      }
    }
  };
  lineChartLegend = false;
  lineChartPlugins = [];
  lineChartType = 'line';
  detailChartPlugins = [];
  data: DataTablesModel = {} as DataTablesModel;
  processing$ = false;
  isLoadingResults = false;
  error: string = '';
  scoreMap = new Map();
  scoreDetails: any[] = [];
  constructor(public youngManiaApiService: YoungManiaApiService,
    private route: ActivatedRoute) {
  }
  ngOnInit() {
    this.data.page = 0;
    this.data.per_page = 10;
    this.loadGamingAddictionAssessments();
  }
  public loadGamingAddictionAssessments() {
    this.isLoadingResults = true;
    this.error = '';
    this.youngManiaApiService.getYoungManiaList(this.patientId, this.assessmentId, this.data.per_page, this.data.page).subscribe(response => {
      if (response && response.items) {
        this.data.items = response.items;
        this.data.total = response.total;
        var riskScores = [];
        var labels = [];
        this.scoreDetails = [];
        for (var item of response.items) {
          if (response.items.length === 1) {
            labels.push("");
            riskScores.push(item.riskScore);
          }
          riskScores.push(item.riskScore);
          var stillUtc = moment.utc(item.createdDate).toDate();
          var assessmentDate = moment(stillUtc).local().format('MM/DD/YY');
          labels.push(assessmentDate);
          for (var key in item) {
            if (item.hasOwnProperty(key)) {
              if (item[key] && item[key].hasOwnProperty("score")) {
                var scoreObj = item[key];
                var scores = [];
                if (this.scoreMap.get(key)) {
                  scores = this.scoreMap.get(key).scores;
                }
                if (response.items.length === 1) {
                  scores.push(scoreObj["score"]);
                }
                scores.push(scoreObj["score"]);
                var returnObj = { "scores": scores, "key": key, "title": scoreObj["title"], "answer": scoreObj["answer"], "chartData": [], "chartColor": this.getChartColor(scores) };
                this.scoreMap.set(key, returnObj);
              }
            }
          }
        }
        this.scoreMap.forEach((value, key) => {
          let dataset: any = {
            datasets: [
              {
                "data": value.scores,
                "borderColor": value.chartColor.borderColor,
                "backgroundColor": value.chartColor.backgroundColor,
                "tension": 0.4,
                "fill": true,
                pointBackgroundColor: '#333333',
                pointRadius: 5
              }
            ],
            "labels": labels,
            "title": value.title,
            "answer": value.answer
          };
          this.scoreDetails.push(dataset);
        });
        this.lineChartData.datasets[0].data = riskScores;
        this.lineChartData.labels = labels;
        this.isLoadingResults = false;
      }
      if (response == null) {
        this.data.items = [];
        this.data.total = 0;
      }
    },
      error => {
        this.error = error;
        this.isLoadingResults = false;
      })
  }
  getChartColor(scoringArray: any[]) {
    var score = scoringArray[scoringArray.length - 1];
    if (score >= 4) {
      return redChart;
    } else if (score >= 3) {
      return orangeChart;
    } else if (score >= 2) {
      return yellowChart;
    } else {
      return greenChart;
    }
  }
  getAltChartColor(scoringArray: any[]) {
    var score = scoringArray[scoringArray.length - 1];
    if (score >= 4) {
      return redChart;
    } else if (score >= 3) {
      return orangeChart;
    } else if (score >= 2) {
      return yellowChart;
    } else {
      return greenChart;
    }
  }
  getRiskScoreChartColor(scoringArray: any[], maxScore: number) {
    var lastScore = scoringArray[scoringArray.length - 1];
    if (lastScore >= 21) {
      return redChart;
    } else {
      return greenChart;
    }
  }

  getChartOptions(i: number) {
    if (i == 4 || i == 5 || i == 7 || i == 8) {
      return this.eightAssessmentDetailChartOptions;
    } else {
      return this.fourAssessmentDetailChartOptions;
    }
  }
}
