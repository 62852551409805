<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Add a Clinical Note</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="clinicalNoteForm">
    <div class="row">
      <div class="col-md-12">
        <label class="labelText">
          Note Type <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="clinicalNoteTypeName"
            (selectionChange)="noteTypeSelected($event)"
          >
            <mat-option
              *ngFor="let noteType of clinicalNoteTypes.items"
              [value]="noteType.name"
            >
              {{ noteType.name }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="clinicalNoteForm.controls['clinicalNoteTypeName'].invalid"
          >
            Please select a note type
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12" style="text-align: right">
        <button
          color="primary"
          mat-raised-button
          type="button"
          (click)="submitForm()"
          [disabled]="!clinicalNoteForm.valid || processing"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
