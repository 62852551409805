import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CareLog, CareLogLane, Case, Patient } from 'src/app/_models';
import { CareLogHttpService } from 'src/app/_services';

@Component({
  selector: 'app-carelog-added-list',
  templateUrl: './carelog-added-list.component.html',
  styleUrls: ['./carelog-added-list.component.css'],
})
export class CareLogAddedListComponent implements OnInit {
  @Input() lane: CareLogLane;
  @Input() logDate: Date;
  @Input() case: Case;
  @Input() patient: Patient;
  @Input() shift: number;
  @Input() showGoal: boolean;
  @Input() goals: any[];

  @Input() hasCaseLogViewAccess: boolean;
  @Input() hasCaseLogAddAccess: boolean;
  @Input() hasCaseLogEditAccess: boolean;
  @Input() hasCaseLogDeleteAccess: boolean;

  @Output() hasChanges = new EventEmitter<boolean>();
  caseId: string;
  notes: CareLog[] = [];

  constructor(
    public dialog: MatDialog,
    public careLogHttpService: CareLogHttpService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.caseId = this.route.snapshot.paramMap.get('id');
  }

  changesOccured(hasChangeEvent: boolean) {
    this.hasChanges.emit(true);
  }
}
