import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { IHamiltonAnxiety } from 'src/app/_models/assessments/anxiety/hamiltonanxiety.model';
import {
  HamiltonAnxietyApiService,
  ToastMessageService,
} from 'src/app/_services/';

@Component({
  selector: 'app-hamilton-anxiety',
  templateUrl: './hamilton-anxiety.component.html',
  styleUrls: ['./hamilton-anxiety.component.css'],
})
export class HamiltonAnxietyComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public hamiltonAnxietyApiService: HamiltonAnxietyApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) {}
  public riskScore: number;
  public hamiltonAnxietyForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  hamiltonAnxietyAssessment: IHamiltonAnxiety;
  hamiltonAnxietyAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.hamiltonAnxietyForm, this.errors);

    this.loadHamiltonAnxietyAssessment();
  }

  loadHamiltonAnxietyAssessment() {
    this.hamiltonAnxietyForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.hamiltonAnxietyApiService
        .getHamiltonAnxietyDetails(this.assessmentId)
        .subscribe((hamiltonAnxietyAssessment) => {
          if (hamiltonAnxietyAssessment.data) {
            this.hamiltonAnxietyForm.controls['anxiousMood'].setValue(
              hamiltonAnxietyAssessment.data.anxiousMood.answer
            );
            this.hamiltonAnxietyForm.controls['tension'].setValue(
              hamiltonAnxietyAssessment.data.tension.answer
            );
            this.hamiltonAnxietyForm.controls['fears'].setValue(
              hamiltonAnxietyAssessment.data.fears.answer
            );
            this.hamiltonAnxietyForm.controls['insomnia'].setValue(
              hamiltonAnxietyAssessment.data.insomnia.answer
            );
            this.hamiltonAnxietyForm.controls['intellectual'].setValue(
              hamiltonAnxietyAssessment.data.intellectual.answer
            );
            this.hamiltonAnxietyForm.controls['depressedMood'].setValue(
              hamiltonAnxietyAssessment.data.depressedMood.answer
            );
            this.hamiltonAnxietyForm.controls['somaticMuscular'].setValue(
              hamiltonAnxietyAssessment.data.somaticMuscular.answer
            );
            this.hamiltonAnxietyForm.controls['somaticSensory'].setValue(
              hamiltonAnxietyAssessment.data.somaticSensory.answer
            );
            this.hamiltonAnxietyForm.controls[
              'cardiovascularSymptoms'
            ].setValue(
              hamiltonAnxietyAssessment.data.cardiovascularSymptoms.answer
            );
            this.hamiltonAnxietyForm.controls['respiratorySymptoms'].setValue(
              hamiltonAnxietyAssessment.data.respiratorySymptoms.answer
            );
            this.hamiltonAnxietyForm.controls[
              'gastrointestinalSymptoms'
            ].setValue(
              hamiltonAnxietyAssessment.data.gastrointestinalSymptoms.answer
            );
            this.hamiltonAnxietyForm.controls['genitourinarySymptoms'].setValue(
              hamiltonAnxietyAssessment.data.genitourinarySymptoms.answer
            );
            this.hamiltonAnxietyForm.controls['autonomicSymptoms'].setValue(
              hamiltonAnxietyAssessment.data.autonomicSymptoms.answer
            );
            this.hamiltonAnxietyForm.controls['behaviorAtInterview'].setValue(
              hamiltonAnxietyAssessment.data.behaviorAtInterview.answer
            );

            this.riskScore = hamiltonAnxietyAssessment.data.riskScore;
          }
        });
    }
  }

  onFormSubmit() {
    if (this.hamiltonAnxietyForm.invalid) {
      this.hamiltonAnxietyForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.hamiltonAnxietyAssessment = Object.assign(
      {},
      this.hamiltonAnxietyAssessment
    );
    this.hamiltonAnxietyAssessment.patientId =
      this.hamiltonAnxietyForm.controls['patientId'].value;
    this.hamiltonAnxietyAssessment.sessionId =
      this.hamiltonAnxietyForm.controls['sessionId'].value;
    this.hamiltonAnxietyAssessment.assessmentRequestId =
      this.hamiltonAnxietyForm.controls['assessmentRequestId'].value;
    this.hamiltonAnxietyAssessment.anxiousMood = {
      answer: this.hamiltonAnxietyForm.controls['anxiousMood'].value,
    };
    this.hamiltonAnxietyAssessment.tension = {
      answer: this.hamiltonAnxietyForm.controls['tension'].value,
    };
    this.hamiltonAnxietyAssessment.fears = {
      answer: this.hamiltonAnxietyForm.controls['fears'].value,
    };
    this.hamiltonAnxietyAssessment.insomnia = {
      answer: this.hamiltonAnxietyForm.controls['insomnia'].value,
    };
    this.hamiltonAnxietyAssessment.intellectual = {
      answer: this.hamiltonAnxietyForm.controls['intellectual'].value,
    };
    this.hamiltonAnxietyAssessment.depressedMood = {
      answer: this.hamiltonAnxietyForm.controls['depressedMood'].value,
    };
    this.hamiltonAnxietyAssessment.somaticMuscular = {
      answer: this.hamiltonAnxietyForm.controls['somaticMuscular'].value,
    };
    this.hamiltonAnxietyAssessment.somaticSensory = {
      answer: this.hamiltonAnxietyForm.controls['somaticSensory'].value,
    };
    this.hamiltonAnxietyAssessment.cardiovascularSymptoms = {
      answer: this.hamiltonAnxietyForm.controls['cardiovascularSymptoms'].value,
    };
    this.hamiltonAnxietyAssessment.respiratorySymptoms = {
      answer: this.hamiltonAnxietyForm.controls['respiratorySymptoms'].value,
    };
    this.hamiltonAnxietyAssessment.gastrointestinalSymptoms = {
      answer:
        this.hamiltonAnxietyForm.controls['gastrointestinalSymptoms'].value,
    };
    this.hamiltonAnxietyAssessment.genitourinarySymptoms = {
      answer: this.hamiltonAnxietyForm.controls['genitourinarySymptoms'].value,
    };
    this.hamiltonAnxietyAssessment.autonomicSymptoms = {
      answer: this.hamiltonAnxietyForm.controls['autonomicSymptoms'].value,
    };
    this.hamiltonAnxietyAssessment.behaviorAtInterview = {
      answer: this.hamiltonAnxietyForm.controls['behaviorAtInterview'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.hamiltonAnxietyAssessment.accessCode = this.accessCode;

      this.hamiltonAnxietyApiService
        .addGuestHamiltonAnxiety(this.hamiltonAnxietyAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Hamiltion Anxiety Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Hamiltion Anxiety Assessment'
            );
          }
        );
    } else {
      this.hamiltonAnxietyApiService
        .addHamiltonAnxiety(this.hamiltonAnxietyAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Hamiltion Anxiety Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Hamiltion Anxiety Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    anxiousMood: [{ type: 'required', message: '1. Anxious Mood is required' }],
    tension: [{ type: 'required', message: '2. Tension is required' }],
    fears: [{ type: 'required', message: '3. Fears is required' }],
    insomnia: [{ type: 'required', message: '4. Insomnia is required' }],
    intellectual: [
      { type: 'required', message: '5. Intellectual is required' },
    ],
    depressedMood: [
      { type: 'required', message: '6. Depressed Mood is required' },
    ],
    somaticMuscular: [
      { type: 'required', message: '7. Somatic (muscular) is required' },
    ],
    somaticSensory: [
      { type: 'required', message: '8. Somatic (sensory) is required' },
    ],
    cardiovascularSymptoms: [
      { type: 'required', message: '9. Cardiovascular Symptoms is required' },
    ],
    respiratorySymptoms: [
      { type: 'required', message: '10. Respiratory Symptoms is required' },
    ],
    gastrointestinalSymptoms: [
      {
        type: 'required',
        message: '11. Gastrointestinal Symptoms is required',
      },
    ],
    genitourinarySymptoms: [
      { type: 'required', message: '12. Genitourinary Symptoms is required' },
    ],
    autonomicSymptoms: [
      { type: 'required', message: '13. Autonomic Symptoms is required' },
    ],
    behaviorAtInterview: [
      { type: 'required', message: '14. Behavior at Interview is required' },
    ],
  };

  private buildForm() {
    this.hamiltonAnxietyForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      anxiousMood: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      tension: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      fears: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      insomnia: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      intellectual: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      depressedMood: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      somaticMuscular: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      somaticSensory: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      cardiovascularSymptoms: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      respiratorySymptoms: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      gastrointestinalSymptoms: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      genitourinarySymptoms: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      autonomicSymptoms: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      behaviorAtInterview: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.hamiltonAnxietyForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.hamiltonAnxietyForm) {
      return;
    }
    const form = this.hamiltonAnxietyForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
