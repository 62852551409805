import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { debounceTime } from 'rxjs';
import { ITherapySession } from 'src/app/_models';
import { IBpsaSubstanceEtc } from 'src/app/_models/session/clinical/bpsa-clinical-substance-etc.model';
import { ToastMessageService } from 'src/app/_services';
import { BpsaSubstanceEtcApiService } from 'src/app/_services/session/clinical/bpsa-clinical-substance-etc.service';

@Component({
  selector: 'app-substance-mental-trauma-history',
  templateUrl: './substance-mental-trauma-history.component.html',
  styleUrls: ['./substance-mental-trauma-history.component.css'],
})
export class SubstanceMentalTraumaHistoryComponent implements OnInit {
  @Input() therapySession: ITherapySession;
  @Input() loggedInUserId: string;
  @Input() hasBPSAClinicalViewAccess: boolean;
  @Input() hasBPSAClinicalAddAccess: boolean;
  @Input() hasBPSAClinicalEditAccess: boolean;

  @Output() updatedTherapySession = new EventEmitter<any>();
  bpsasubstanceetc: IBpsaSubstanceEtc;

  constructor(
    public bpsasubstanceetcApiService: BpsaSubstanceEtcApiService,
    private formBuilder: FormBuilder,
    private toastMessageService: ToastMessageService,
    private route: ActivatedRoute
  ) {}

  public action: string;
  public bpsasubstanceetcForm: FormGroup;
  key: string;
  processing: boolean = false;

  public ngOnInit() {
    this.bpsasubstanceetc = this.therapySession.bpsaSubstanceEtc;
    this.key = this.route.snapshot.paramMap.get('id');
    this.buildForm();

    this.loadBpsaSubstanceEtc();
  }

  loadBpsaSubstanceEtc() {
    if (this.bpsasubstanceetc) {
      this.bpsasubstanceetcForm.controls['drinkAlcohol'].setValue(
        this.bpsasubstanceetc.drinkAlcohol
      );
      this.bpsasubstanceetcForm.controls['alcoholHowOften'].setValue(
        this.bpsasubstanceetc.alcoholHowOften
      );
      this.bpsasubstanceetcForm.controls['alcoholConsumptionTime'].setValue(
        this.bpsasubstanceetc.alcoholConsumptionTime
      );
      this.bpsasubstanceetcForm.controls['alcoholConsumptionWith'].setValue(
        this.bpsasubstanceetc.alcoholConsumptionWith
      );
      this.bpsasubstanceetcForm.controls['hideAlcoholConsumption'].setValue(
        this.bpsasubstanceetc.hideAlcoholConsumption
      );
      this.bpsasubstanceetcForm.controls['alcoholBlackouts'].setValue(
        this.bpsasubstanceetc.alcoholBlackouts
      );
      this.bpsasubstanceetcForm.controls['doesIllegalDrugs'].setValue(
        this.bpsasubstanceetc.doesIllegalDrugs
      );
      this.bpsasubstanceetcForm.controls['illegalDrugsHowOften'].setValue(
        this.bpsasubstanceetc.illegalDrugsHowOften
      );
      this.bpsasubstanceetcForm.controls[
        'illegalDrugsConsumptionTime'
      ].setValue(this.bpsasubstanceetc.illegalDrugsConsumptionTime);
      this.bpsasubstanceetcForm.controls[
        'illegalDrugsConsumptionWith'
      ].setValue(this.bpsasubstanceetc.illegalDrugsConsumptionWith);
      this.bpsasubstanceetcForm.controls['hideIllegalDrugs'].setValue(
        this.bpsasubstanceetc.hideIllegalDrugs
      );
      this.bpsasubstanceetcForm.controls['gamblingHistory'].setValue(
        this.bpsasubstanceetc.gamblingHistory
      );
      this.bpsasubstanceetcForm.controls['eatingDisorders'].setValue(
        this.bpsasubstanceetc.eatingDisorders
      );
      this.bpsasubstanceetcForm.controls[
        'beenToInpatientTreatmentFacility'
      ].setValue(this.bpsasubstanceetc.beenToInpatientTreatmentFacility);
      this.bpsasubstanceetcForm.controls[
        'inpatientTreatmentVisitDetails'
      ].setValue(this.bpsasubstanceetc.inpatientTreatmentVisitDetails);
      this.bpsasubstanceetcForm.controls['beenToPsychiatricHospital'].setValue(
        this.bpsasubstanceetc.beenToPsychiatricHospital
      );
      this.bpsasubstanceetcForm.controls[
        'psychiatricHospitalVisitDetails'
      ].setValue(this.bpsasubstanceetc.psychiatricHospitalVisitDetails);
      this.bpsasubstanceetcForm.controls['experiencedOverdose'].setValue(
        this.bpsasubstanceetc.experiencedOverdose
      );
      this.bpsasubstanceetcForm.controls['overdoseDetails'].setValue(
        this.bpsasubstanceetc.overdoseDetails
      );
      this.bpsasubstanceetcForm.controls['significantLifeChanges'].setValue(
        this.bpsasubstanceetc.significantLifeChanges
      );
      this.bpsasubstanceetcForm.controls[
        'significantLifeChangesInPastThreeMonths'
      ].setValue(this.bpsasubstanceetc.significantLifeChangesInPastThreeMonths);
      this.bpsasubstanceetcForm.controls['bullyingVictim'].setValue(
        this.bpsasubstanceetc.bullyingVictim
      );
      this.bpsasubstanceetcForm.controls['bullyingDetails'].setValue(
        this.bpsasubstanceetc.bullyingDetails
      );
      this.bpsasubstanceetcForm.controls['physicalAbuseVictim'].setValue(
        this.bpsasubstanceetc.physicalAbuseVictim
      );
      this.bpsasubstanceetcForm.controls['physicalAbuseDetails'].setValue(
        this.bpsasubstanceetc.physicalAbuseDetails
      );
      this.bpsasubstanceetcForm.controls['emotionalAbuseVictim'].setValue(
        this.bpsasubstanceetc.emotionalAbuseVictim
      );
      this.bpsasubstanceetcForm.controls['emotionalAbuseDetails'].setValue(
        this.bpsasubstanceetc.emotionalAbuseDetails
      );
      this.bpsasubstanceetcForm.controls['sexualAbuseVictim'].setValue(
        this.bpsasubstanceetc.sexualAbuseVictim
      );
      this.bpsasubstanceetcForm.controls['sexualAbuseDetails'].setValue(
        this.bpsasubstanceetc.sexualAbuseDetails
      );
      this.bpsasubstanceetcForm.controls['neglectedOrExploited'].setValue(
        this.bpsasubstanceetc.neglectedOrExploited
      );
      this.bpsasubstanceetcForm.controls[
        'neglectedOrExploitedDetails'
      ].setValue(this.bpsasubstanceetc.neglectedOrExploitedDetails);
      this.bpsasubstanceetcForm.controls['concerns'].setValue(
        this.bpsasubstanceetc.concerns
      );
    }

    // Initiate Auto Save
    this.initiateAutoSave();
  }

  onBpsaSubstanceEtcFormSubmit() {
    this.bpsasubstanceetc = Object.assign({}, this.bpsasubstanceetc);
    this.bpsasubstanceetc = Object.assign(
      this.bpsasubstanceetc,
      this.bpsasubstanceetcForm.value
    );

    this.processing = true;
    this.bpsasubstanceetcApiService
      .updateBpsaSubstanceEtc(this.bpsasubstanceetc)
      .subscribe({
        next: (response) => {
          this.processing = false;
          this.updatedTherapySession.emit(response);
        },
        error: (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Something went wrong. Please try again.'
          );
        },
      });
  }

  private buildForm() {
    this.bpsasubstanceetcForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      sessionId: new FormControl(
        this.therapySession.id,
        Validators.compose([Validators.required])
      ),
      patientId: new FormControl(
        this.therapySession.patientId,
        Validators.compose([Validators.required])
      ),

      drinkAlcohol: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      alcoholHowOften: new FormControl({
        value: '',
        disabled:
          this.therapySession.status != 'Active' ||
          (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
      }),
      alcoholConsumptionTime: new FormControl({
        value: '',
        disabled:
          this.therapySession.status != 'Active' ||
          (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
      }),
      alcoholConsumptionWith: new FormControl({
        value: '',
        disabled:
          this.therapySession.status != 'Active' ||
          (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
      }),
      hideAlcoholConsumption: new FormControl({
        value: '',
        disabled:
          this.therapySession.status != 'Active' ||
          (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
      }),
      alcoholBlackouts: new FormControl({
        value: '',
        disabled:
          this.therapySession.status != 'Active' ||
          (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
      }),

      doesIllegalDrugs: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      illegalDrugsHowOften: new FormControl({
        value: '',
        disabled:
          this.therapySession.status != 'Active' ||
          (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
      }),
      illegalDrugsConsumptionTime: new FormControl({
        value: '',
        disabled:
          this.therapySession.status != 'Active' ||
          (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
      }),
      illegalDrugsConsumptionWith: new FormControl({
        value: '',
        disabled:
          this.therapySession.status != 'Active' ||
          (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
      }),
      hideIllegalDrugs: new FormControl({
        value: '',
        disabled:
          this.therapySession.status != 'Active' ||
          (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
      }),
      gamblingHistory: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      eatingDisorders: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      beenToInpatientTreatmentFacility: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      inpatientTreatmentVisitDetails: new FormControl({
        value: '',
        disabled:
          this.therapySession.status != 'Active' ||
          (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
      }),
      beenToPsychiatricHospital: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      psychiatricHospitalVisitDetails: new FormControl({
        value: '',
        disabled:
          this.therapySession.status != 'Active' ||
          (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
      }),
      experiencedOverdose: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      overdoseDetails: new FormControl({
        value: '',
        disabled:
          this.therapySession.status != 'Active' ||
          (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
      }),
      significantLifeChanges: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      significantLifeChangesInPastThreeMonths: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      bullyingVictim: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      bullyingDetails: new FormControl({
        value: '',
        disabled:
          this.therapySession.status != 'Active' ||
          (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
      }),
      physicalAbuseVictim: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      physicalAbuseDetails: new FormControl({
        value: '',
        disabled:
          this.therapySession.status != 'Active' ||
          (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
      }),
      emotionalAbuseVictim: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      emotionalAbuseDetails: new FormControl({
        value: '',
        disabled:
          this.therapySession.status != 'Active' ||
          (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
      }),
      sexualAbuseVictim: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      sexualAbuseDetails: new FormControl({
        value: '',
        disabled:
          this.therapySession.status != 'Active' ||
          (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
      }),
      neglectedOrExploited: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      neglectedOrExploitedDetails: new FormControl({
        value: '',
        disabled:
          this.therapySession.status != 'Active' ||
          (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
      }),
      concerns: new FormControl({
        value: [],
        disabled:
          this.therapySession.status != 'Active' ||
          (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
      }),
    });
  }

  checkAlcoholAnswer() {
    let selectedAnswer =
      this.bpsasubstanceetcForm.controls['drinkAlcohol'].value;
    if (selectedAnswer === 'Yes') {
      this.bpsasubstanceetcForm.controls['alcoholHowOften'].addValidators(
        Validators.required
      );
      this.bpsasubstanceetcForm.controls[
        'alcoholHowOften'
      ].updateValueAndValidity();

      this.bpsasubstanceetcForm.controls[
        'alcoholConsumptionTime'
      ].addValidators(Validators.required);
      this.bpsasubstanceetcForm.controls[
        'alcoholConsumptionTime'
      ].updateValueAndValidity();

      this.bpsasubstanceetcForm.controls[
        'alcoholConsumptionWith'
      ].addValidators(Validators.required);
      this.bpsasubstanceetcForm.controls[
        'alcoholConsumptionWith'
      ].updateValueAndValidity();

      this.bpsasubstanceetcForm.controls[
        'hideAlcoholConsumption'
      ].addValidators(Validators.required);
      this.bpsasubstanceetcForm.controls[
        'hideAlcoholConsumption'
      ].updateValueAndValidity();

      this.bpsasubstanceetcForm.controls['alcoholBlackouts'].addValidators(
        Validators.required
      );
      this.bpsasubstanceetcForm.controls[
        'alcoholBlackouts'
      ].updateValueAndValidity();
    } else {
      this.bpsasubstanceetcForm.controls['alcoholHowOften'].clearValidators();
      this.bpsasubstanceetcForm.controls['alcoholHowOften'].reset();
      this.bpsasubstanceetcForm.controls[
        'alcoholHowOften'
      ].updateValueAndValidity();

      this.bpsasubstanceetcForm.controls[
        'alcoholConsumptionTime'
      ].clearValidators();
      this.bpsasubstanceetcForm.controls['alcoholConsumptionTime'].reset();
      this.bpsasubstanceetcForm.controls[
        'alcoholConsumptionTime'
      ].updateValueAndValidity();

      this.bpsasubstanceetcForm.controls[
        'alcoholConsumptionWith'
      ].clearValidators();
      this.bpsasubstanceetcForm.controls['alcoholConsumptionWith'].reset();
      this.bpsasubstanceetcForm.controls[
        'alcoholConsumptionWith'
      ].updateValueAndValidity();

      this.bpsasubstanceetcForm.controls[
        'hideAlcoholConsumption'
      ].clearValidators();
      this.bpsasubstanceetcForm.controls['hideAlcoholConsumption'].reset();
      this.bpsasubstanceetcForm.controls[
        'hideAlcoholConsumption'
      ].updateValueAndValidity();

      this.bpsasubstanceetcForm.controls['alcoholBlackouts'].clearValidators();
      this.bpsasubstanceetcForm.controls['alcoholBlackouts'].reset();
      this.bpsasubstanceetcForm.controls[
        'alcoholBlackouts'
      ].updateValueAndValidity();
    }
  }

  checkDrugAnswer() {
    let selectedAnswer =
      this.bpsasubstanceetcForm.controls['doesIllegalDrugs'].value;
    if (selectedAnswer === 'Yes') {
      this.bpsasubstanceetcForm.controls['illegalDrugsHowOften'].addValidators(
        Validators.required
      );
      this.bpsasubstanceetcForm.controls[
        'illegalDrugsHowOften'
      ].updateValueAndValidity();

      this.bpsasubstanceetcForm.controls[
        'illegalDrugsConsumptionTime'
      ].addValidators(Validators.required);
      this.bpsasubstanceetcForm.controls[
        'illegalDrugsConsumptionTime'
      ].updateValueAndValidity();

      this.bpsasubstanceetcForm.controls[
        'illegalDrugsConsumptionWith'
      ].addValidators(Validators.required);
      this.bpsasubstanceetcForm.controls[
        'illegalDrugsConsumptionWith'
      ].updateValueAndValidity();

      this.bpsasubstanceetcForm.controls['hideIllegalDrugs'].addValidators(
        Validators.required
      );
      this.bpsasubstanceetcForm.controls[
        'hideIllegalDrugs'
      ].updateValueAndValidity();
    } else {
      this.bpsasubstanceetcForm.controls[
        'illegalDrugsHowOften'
      ].clearValidators();
      this.bpsasubstanceetcForm.controls['illegalDrugsHowOften'].reset();
      this.bpsasubstanceetcForm.controls[
        'illegalDrugsHowOften'
      ].updateValueAndValidity();

      this.bpsasubstanceetcForm.controls[
        'illegalDrugsConsumptionTime'
      ].clearValidators();
      this.bpsasubstanceetcForm.controls['illegalDrugsConsumptionTime'].reset();
      this.bpsasubstanceetcForm.controls[
        'illegalDrugsConsumptionTime'
      ].updateValueAndValidity();

      this.bpsasubstanceetcForm.controls[
        'illegalDrugsConsumptionWith'
      ].clearValidators();
      this.bpsasubstanceetcForm.controls['illegalDrugsConsumptionWith'].reset();
      this.bpsasubstanceetcForm.controls[
        'illegalDrugsConsumptionWith'
      ].updateValueAndValidity();

      this.bpsasubstanceetcForm.controls['hideIllegalDrugs'].clearValidators();
      this.bpsasubstanceetcForm.controls['hideIllegalDrugs'].reset();
      this.bpsasubstanceetcForm.controls[
        'hideIllegalDrugs'
      ].updateValueAndValidity();
    }
  }

  checkImpatientTreatment() {
    let selectedAnswer =
      this.bpsasubstanceetcForm.controls['beenToInpatientTreatmentFacility']
        .value;
    if (selectedAnswer === 'Yes') {
      this.bpsasubstanceetcForm.controls[
        'inpatientTreatmentVisitDetails'
      ].addValidators(Validators.required);
      this.bpsasubstanceetcForm.controls[
        'inpatientTreatmentVisitDetails'
      ].updateValueAndValidity();
    } else {
      this.bpsasubstanceetcForm.controls[
        'inpatientTreatmentVisitDetails'
      ].clearValidators();
      this.bpsasubstanceetcForm.controls[
        'inpatientTreatmentVisitDetails'
      ].reset();
      this.bpsasubstanceetcForm.controls[
        'inpatientTreatmentVisitDetails'
      ].updateValueAndValidity();
    }
  }

  checkPsychiatricHospital() {
    let selectedAnswer =
      this.bpsasubstanceetcForm.controls['beenToPsychiatricHospital'].value;
    if (selectedAnswer === 'Yes') {
      this.bpsasubstanceetcForm.controls[
        'psychiatricHospitalVisitDetails'
      ].addValidators(Validators.required);
      this.bpsasubstanceetcForm.controls[
        'psychiatricHospitalVisitDetails'
      ].updateValueAndValidity();
    } else {
      this.bpsasubstanceetcForm.controls[
        'psychiatricHospitalVisitDetails'
      ].clearValidators();
      this.bpsasubstanceetcForm.controls[
        'psychiatricHospitalVisitDetails'
      ].reset();
      this.bpsasubstanceetcForm.controls[
        'psychiatricHospitalVisitDetails'
      ].updateValueAndValidity();
    }
  }

  checkExpOverdose() {
    let selectedAnswer =
      this.bpsasubstanceetcForm.controls['experiencedOverdose'].value;
    if (selectedAnswer === 'Yes') {
      this.bpsasubstanceetcForm.controls['overdoseDetails'].addValidators(
        Validators.required
      );
      this.bpsasubstanceetcForm.controls[
        'overdoseDetails'
      ].updateValueAndValidity();
    } else {
      this.bpsasubstanceetcForm.controls['overdoseDetails'].clearValidators();
      this.bpsasubstanceetcForm.controls['overdoseDetails'].reset();
      this.bpsasubstanceetcForm.controls[
        'overdoseDetails'
      ].updateValueAndValidity();
    }
  }

  checkBullying() {
    let selectedAnswer =
      this.bpsasubstanceetcForm.controls['bullyingVictim'].value;
    if (selectedAnswer === 'Yes') {
      this.bpsasubstanceetcForm.controls['bullyingDetails'].addValidators(
        Validators.required
      );
      this.bpsasubstanceetcForm.controls[
        'bullyingDetails'
      ].updateValueAndValidity();
    } else {
      this.bpsasubstanceetcForm.controls['bullyingDetails'].clearValidators();
      this.bpsasubstanceetcForm.controls['bullyingDetails'].reset();
      this.bpsasubstanceetcForm.controls[
        'bullyingDetails'
      ].updateValueAndValidity();
    }
  }

  checkPhysicalAbuse() {
    let selectedAnswer =
      this.bpsasubstanceetcForm.controls['physicalAbuseVictim'].value;
    if (selectedAnswer === 'Yes') {
      this.bpsasubstanceetcForm.controls['physicalAbuseDetails'].addValidators(
        Validators.required
      );
      this.bpsasubstanceetcForm.controls[
        'physicalAbuseDetails'
      ].updateValueAndValidity();
    } else {
      this.bpsasubstanceetcForm.controls[
        'physicalAbuseDetails'
      ].clearValidators();
      this.bpsasubstanceetcForm.controls['physicalAbuseDetails'].reset();
      this.bpsasubstanceetcForm.controls[
        'physicalAbuseDetails'
      ].updateValueAndValidity();
    }
  }

  checkEmotionalAbuse() {
    let selectedAnswer =
      this.bpsasubstanceetcForm.controls['emotionalAbuseVictim'].value;
    if (selectedAnswer === 'Yes') {
      this.bpsasubstanceetcForm.controls['emotionalAbuseDetails'].addValidators(
        Validators.required
      );
      this.bpsasubstanceetcForm.controls[
        'emotionalAbuseDetails'
      ].updateValueAndValidity();
    } else {
      this.bpsasubstanceetcForm.controls[
        'emotionalAbuseDetails'
      ].clearValidators();
      this.bpsasubstanceetcForm.controls['emotionalAbuseDetails'].reset();
      this.bpsasubstanceetcForm.controls[
        'emotionalAbuseDetails'
      ].updateValueAndValidity();
    }
  }

  checkSexualAbuse() {
    let selectedAnswer =
      this.bpsasubstanceetcForm.controls['sexualAbuseVictim'].value;
    if (selectedAnswer === 'Yes') {
      this.bpsasubstanceetcForm.controls['sexualAbuseDetails'].addValidators(
        Validators.required
      );
      this.bpsasubstanceetcForm.controls[
        'sexualAbuseDetails'
      ].updateValueAndValidity();
    } else {
      this.bpsasubstanceetcForm.controls[
        'sexualAbuseDetails'
      ].clearValidators();
      this.bpsasubstanceetcForm.controls['sexualAbuseDetails'].reset();
      this.bpsasubstanceetcForm.controls[
        'sexualAbuseDetails'
      ].updateValueAndValidity();
    }
  }

  checkNeglectedExp() {
    let selectedAnswer =
      this.bpsasubstanceetcForm.controls['neglectedOrExploited'].value;
    if (selectedAnswer === 'Yes') {
      this.bpsasubstanceetcForm.controls[
        'neglectedOrExploitedDetails'
      ].addValidators(Validators.required);
      this.bpsasubstanceetcForm.controls[
        'neglectedOrExploitedDetails'
      ].updateValueAndValidity();
    } else {
      this.bpsasubstanceetcForm.controls[
        'neglectedOrExploitedDetails'
      ].clearValidators();
      this.bpsasubstanceetcForm.controls['neglectedOrExploitedDetails'].reset();
      this.bpsasubstanceetcForm.controls[
        'neglectedOrExploitedDetails'
      ].updateValueAndValidity();
    }
  }

  initiateAutoSave() {
    this.bpsasubstanceetcForm.valueChanges
      .pipe(debounceTime(1200))
      .subscribe(() => {
        this.onBpsaSubstanceEtcFormSubmit();
      });
  }
}
