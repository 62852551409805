import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import moment from 'moment';
import { DataTablesModel } from 'src/app/_models';
import { CrossCuttingLevelOneSixToSeventeenApiService } from 'src/app/_services/assessments/general/crosscuttinglevelonesixtoseventeen.service';
import { CHART_COLORS, greenChart, hexToRGBA, redChart } from 'src/app/shared/utilities/utilities';
@Component({
  selector: 'app-dsm5-l1-sixtoseventeen-charts',
  templateUrl: './dsm5-l1-sixtoseventeen-charts.component.html',
  styleUrls: ['./dsm5-l1-sixtoseventeen-charts.component.css']
})
export class Dsm5L1SixtoseventeenChartsComponent implements OnInit {
  @Input() patientId: string;
  @Input() assessmentId: string;
  @Input() anchorTag: string;
  constructor(public crossCuttingLevelOneSixToSeventeenApiService: CrossCuttingLevelOneSixToSeventeenApiService,
    private route: ActivatedRoute) {
  }
  chartColorRed = CHART_COLORS.red;
  chartColorOrange = CHART_COLORS.orange;
  chartColorBlue = CHART_COLORS.blue;
  chartColorGreen = CHART_COLORS.green;
  chartColorPurple = CHART_COLORS.purple;
  lineChartData: ChartConfiguration<'line'>['data'] = {
    datasets: [{ tension: 0.4, data: [], label: 'Somatic', backgroundColor: this.chartColorRed, hoverBackgroundColor: this.chartColorRed },
    { data: [], label: 'Sleep', backgroundColor: this.chartColorOrange, hoverBackgroundColor: this.chartColorOrange },
    { data: [], label: 'Inattention', backgroundColor: this.chartColorBlue, hoverBackgroundColor: this.chartColorBlue },
    { data: [], label: 'Depression', backgroundColor: this.chartColorGreen, hoverBackgroundColor: this.chartColorGreen },
    { data: [], label: 'Anger / Irritability', backgroundColor: CHART_COLORS.grey, hoverBackgroundColor: CHART_COLORS.grey },
    { data: [], label: 'Mania', backgroundColor: this.chartColorPurple, hoverBackgroundColor: this.chartColorPurple },
    { data: [], label: 'Anxiety', backgroundColor: CHART_COLORS.midnightBlue, hoverBackgroundColor: CHART_COLORS.midnightBlue },
    { data: [], label: 'Psychosis', backgroundColor: CHART_COLORS.peach, hoverBackgroundColor: CHART_COLORS.peach },
    { data: [], label: 'Repetetive Thoughts And Behaviors', backgroundColor: CHART_COLORS.greyishBlue, hoverBackgroundColor: CHART_COLORS.greyishBlue },
    { data: [], label: 'Substance Use', backgroundColor: CHART_COLORS.brown, hoverBackgroundColor: CHART_COLORS.brown },
    { data: [], label: 'Suicidal Ideation / Suicidal Attempts', backgroundColor: CHART_COLORS.bloody, hoverBackgroundColor: CHART_COLORS.bloody },]
  };
  overallChartData: ChartConfiguration<'line'>['data'] = {
    datasets: [{
      tension: 0.4,
      data: [], label: 'Overall Score', borderColor: '#EEEEEE',
      pointBackgroundColor: '#333333',
      pointBorderColor: '#FFFFFF',
      pointHoverBackgroundColor: '#FFFFFF',
      pointHoverBorderColor: '#333333',
      pointStyle: 'circle',
      pointRadius: 5,
    }]
  };

  lineChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        min: 0,
        max: 10,
      },
      y: {
        stacked: true,
        display: false
      }
    }
  };
  overallChartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        title: {
          display: false
        }
      },
      annotation: {
        annotations: {
          greenBg:
          {
            type: 'box',
            yMin: 0,
            yMax: 38.5,
            backgroundColor: hexToRGBA(greenChart.backgroundColor, 1),
            borderColor: greenChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          },
          redBg: {
            type: 'box',
            yMin: 38.5,
            yMax: 76,
            backgroundColor: hexToRGBA(redChart.backgroundColor, 1),
            borderColor: redChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          }
        }
      }
    },
    scales: {
      x: {
        min: 0,
        max: 10,
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        },
        min: 0,
        max: 76,
        ticks: { stepSize: 10 }
      }
    }
  };
  assessmentDetailInitialChartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        title: {
          display: false
        }
      }
    },
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        },
        min: 0,
        max: 4,
        ticks: { stepSize: 1 }
      }
    }
  };
  yesNoChartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        title: {
          display: false
        }
      }
    },
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        },
        min: 0,
        max: 1,
        ticks: {
          stepSize: 1,
          callback: function (value, index, ticks) {
            if (typeof value === 'number') {
              return value > 0 ? "Yes / Don't Know" : "No";
            } else {
              return 'No';
            }
          }
        },
      }
    }
  };
  assessmentDetailChartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        title: {
          display: false
        }
      }
    },
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        },
        min: 0,
        max: 4,
        ticks: { stepSize: 1 }
      }
    }
  };

  lineChartLegend = false;
  lineChartPlugins = [{
  }];
  overallChartPlugins = [];
  lineChartType = 'line';
  mainChartType = 'bar';
  detailChartPlugins = [];

  data: DataTablesModel = {} as DataTablesModel;
  processing$ = false;
  isLoadingResults = false;
  error: string = '';
  scoreMap = new Map();
  scoreDetails: any[] = [];
  ngOnInit() {
    this.data.page = 0;
    this.data.per_page = 10;
    this.loadGamingAddictionAssessments();
  }
  public loadGamingAddictionAssessments() {
    this.isLoadingResults = true;
    this.error = '';
    this.crossCuttingLevelOneSixToSeventeenApiService.getCrossCuttingLevelOneSixToSeventeenList(this.patientId, this.assessmentId, this.data.per_page, this.data.page).subscribe(response => {
      if (response && response.items) {
        this.data.items = response.items;
        this.data.total = response.total;
        var riskScores = [];
        var labels = [];
        var lineChartLabels = [];
        this.scoreDetails = [];
        var somaticScores = [];
        var sleepScores = [];
        var inattentionScores = [];
        var depressionScores = [];
        var angerScores = [];
        var maniaScores = [];
        var anxietyScores = [];
        var psychosisScores = [];
        var repetetiveThoughtsAndBehaviorsScores = [];
        var substanceUseScores = [];
        var suicidalScores = [];
        for (var item of response.items) {
          if (response.items.length === 1) {
            labels.push("");
            riskScores.push(item.riskScore);
          }
          riskScores.push(item.riskScore);
          if (item.props) {
            somaticScores.push(item.props['somatic'] >= 2 ? item.props['somatic'] : 0);
            sleepScores.push(item.props['sleep'] >= 2 ? item.props['sleep'] : 0);
            inattentionScores.push(item.props['inattention'] >= 1 ? item.props['inattention'] : 0);
            depressionScores.push(item.props['depression'] >= 2 ? item.props['depression'] : 0);
            angerScores.push(item.props['anger'] >= 2 ? item.props['anger'] : 0);
            maniaScores.push(item.props['mania'] >= 2 ? item.props['mania'] : 0);
            anxietyScores.push(item.props['anxiety'] >= 2 ? item.props['anxiety'] : 0);
            psychosisScores.push(item.props['psychosis'] >= 1 ? item.props['psychosis'] : 0);
            repetetiveThoughtsAndBehaviorsScores.push(item.props['repetetiveThoughtsAndBehaviors'] >= 2 ? item.props['repetetiveThoughtsAndBehaviors'] : 0);
            substanceUseScores.push(item.props['substanceUse'] >= 1 ? item.props['substanceUse'] : 0);
            suicidalScores.push(item.props['suicidal'] >= 1 ? item.props['suicidal'] : 0);
          } else {
            somaticScores.push(0);
            sleepScores.push(0);
            inattentionScores.push(0);
            depressionScores.push(0);
            angerScores.push(0);
            maniaScores.push(0);
            anxietyScores.push(0);
            psychosisScores.push(0);
            repetetiveThoughtsAndBehaviorsScores.push(0);
            substanceUseScores.push(0);
            suicidalScores.push(0);
          }
          var stillUtc = moment.utc(item.createdDate).toDate();
          var assessmentDate = moment(stillUtc).local().format('MM/DD/YY');
          labels.push(assessmentDate);
          lineChartLabels.push(assessmentDate);
          var idx = 0;
          for (var key in item) {
            if (item.hasOwnProperty(key)) {
              if (item[key] && item[key].hasOwnProperty("score")) {
                var scoreObj = item[key];
                var scores = [];
                if (this.scoreMap.get(key)) {
                  scores = this.scoreMap.get(key).scores;
                }
                var chartColor: any = this.getChartColor(scores);

                if (idx >= 19 && (scoreObj["answer"] === 'Yes' || scoreObj["answer"] === "Don't Know")) {
                  chartColor = redChart;
                  scores.push(1);
                } else {
                  if (response.items.length === 1) {
                    scores.push(scoreObj["score"]);
                  }
                  scores.push(scoreObj["score"]);
                }
                if (idx < 19 && (idx === 3 || idx === 13 || idx === 14)) {
                  chartColor = scores[scores.length - 1] >= 1 ? redChart : greenChart;
                } else if (idx < 19) {
                  chartColor = scores[scores.length - 1] >= 2 ? redChart : greenChart;
                }
                var returnObj = { "scores": scores, "key": key, "title": scoreObj["title"], "answer": scoreObj["answer"], "chartData": [], "chartColor": chartColor };
                this.scoreMap.set(key, returnObj);
                idx++;
              }
            }
          }
        }
        var pushIdx = 0;
        this.scoreMap.forEach((value, key) => {
          let dataset: any = {
            datasets: [
              {
                "data": value.scores,
                "borderColor": value.chartColor.borderColor,
                "backgroundColor": value.chartColor.backgroundColor,
                "tension": 0.4,
                "fill": true,
                pointBackgroundColor: '#333333',
                pointRadius: 5
              }
            ],
            "labels": labels,
            "title": value.title,
            "answer": value.answer
          };
          this.scoreDetails.push(dataset);
          pushIdx++;
        });
        this.overallChartData.datasets[0].data = riskScores;
        this.lineChartData.datasets[0].data = somaticScores;
        this.lineChartData.datasets[1].data = sleepScores;
        this.lineChartData.datasets[2].data = inattentionScores;
        this.lineChartData.datasets[3].data = depressionScores;
        this.lineChartData.datasets[4].data = angerScores;
        this.lineChartData.datasets[5].data = maniaScores;
        this.lineChartData.datasets[6].data = anxietyScores;
        this.lineChartData.datasets[7].data = psychosisScores;
        this.lineChartData.datasets[8].data = repetetiveThoughtsAndBehaviorsScores;
        this.lineChartData.datasets[9].data = substanceUseScores;
        this.lineChartData.datasets[10].data = suicidalScores;
        this.lineChartData.labels = lineChartLabels;
        this.overallChartData.labels = labels;
        this.isLoadingResults = false;
      }
      if (response == null) {
        this.data.items = [];
        this.data.total = 0;
      }
    },
      error => {
        this.error = error;
        this.isLoadingResults = false;
      })
  }
  getChartColor(scoringArray: any[]) {
    var score = scoringArray[scoringArray.length - 1];
    if (score >= 2) {
      return redChart;
    } else {
      return greenChart;
    }
  }
}
