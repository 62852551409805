<div>
  <div class="col-md-12">
    <p style="font-size: 16px; font-weight: 450">
      <a routerLink="/" routerLinkActive="active" style="color: #2196f3">
        <em
          class="material-icons text_align-center"
          style="font-size: 18px; margin-top: -3px"
          >home</em
        >
      </a>
      &nbsp; / &nbsp; My Members
    </p>
  </div>
</div>

<br />

<!-- Providing Search Option -->
<div
  class="row"
  style="
    margin-bottom: 35px;
    margin-left: 25px;
    margin-right: 15px;
    row-gap: 15px;
  "
>
  <div class="col-sm-12 col-md-8">
    <div class="row" style="row-gap: 15px; column-gap: 15px; flex-wrap: wrap">
      <div
        class="col"
        style="
          display: flex;
          align-items: center;
          padding: 5px;
          border-radius: 10px;
          border: 1px solid rgb(0, 0, 0, 0.2);
          background-color: #ffffff;
          padding: 5px;
          min-width: 140px;
        "
      >
        <span
          class="material-icons"
          style="text-align: left; font-size: 20px; opacity: 0.5"
        >
          pin
        </span>
        <input
          type="search"
          placeholder="Member #"
          [(ngModel)]="memberNumber"
          (ngModelChange)="searchByNameChanged()"
          style="
            padding-left: 15px;
            width: 100%;
            background: transparent;
            border: 0;
          "
        />
      </div>
      <div
        class="col"
        style="
          display: flex;
          align-items: center;
          padding: 5px;
          border-radius: 10px;
          border: 1px solid rgb(0, 0, 0, 0.2);
          background-color: #ffffff;
          padding: 5px;
          min-width: 140px;
        "
      >
        <span
          class="material-icons"
          style="text-align: left; font-size: 20px; opacity: 0.5"
        >
          search
        </span>
        <input
          type="search"
          placeholder="Last Name"
          [(ngModel)]="lastName"
          (ngModelChange)="searchByNameChanged()"
          style="
            padding-left: 15px;
            width: 100%;
            background: transparent;
            border: 0;
          "
        />
      </div>

      <div
        class="col"
        style="
          display: flex;
          align-items: center;
          padding: 5px;
          border-radius: 10px;
          border: 1px solid rgb(0, 0, 0, 0.2);
          background-color: #ffffff;
          padding: 5px;
          min-width: 140px;
        "
      >
        <span
          class="material-icons"
          style="text-align: left; font-size: 20px; opacity: 0.5"
        >
          search
        </span>
        <input
          type="search"
          placeholder="First Name"
          [(ngModel)]="firstName"
          (ngModelChange)="searchByNameChanged()"
          style="
            padding-left: 15px;
            width: 100%;
            background: transparent;
            border: 0;
          "
        />
      </div>

      <div
        class="col"
        style="
          display: flex;
          align-items: center;
          padding: 5px;
          border-radius: 10px;
          border: 1px solid rgb(0, 0, 0, 0.2);
          background-color: #ffffff;
          padding: 5px;
          min-width: 140px;
        "
      >
        <span
          class="material-icons"
          style="text-align: left; font-size: 20px; opacity: 0.5"
        >
          call
        </span>
        <input
          type="search"
          placeholder="Phone"
          [(ngModel)]="phone"
          (ngModelChange)="searchByNameChanged()"
          style="
            padding-left: 15px;
            width: 100%;
            background: transparent;
            border: 0;
          "
        />
      </div>
    </div>
  </div>

  <div
    class="col-sm-12 col-md-4"
    style="
      column-gap: 20px;
      display: flex;
      flex-wrap: wrap;
      text-align: right;
      flex-direction: row-reverse;
      padding: 0;
      row-gap: 10px;
    "
  >
    <div>
      <button mat-raised-button [matMenuTriggerFor]="sortMenu">
        <span style="white-space: break-spaces">
          {{
            sortBy === ''
              ? 'Sort by'
              : sortBy === 'DESC'
              ? 'Recently Added'
              : 'Created Date'
          }}
        </span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #sortMenu="matMenu">
        <button mat-menu-item (click)="sortByChanged('')">None</button>
        <button (click)="sortByChanged('DESC')" mat-menu-item>
          Recently Added
        </button>
        <button (click)="sortByChanged('ASC')" mat-menu-item>
          Created Date
        </button>
      </mat-menu>
    </div>

    <div>
      <button mat-raised-button [matMenuTriggerFor]="filterMenu">
        <span
          style="white-space: break-spaces; color: #3ac47d"
          *ngIf="filterOption === 'Active'"
        >
          Status: {{ filterOption }}
        </span>
        <span
          style="white-space: break-spaces; color: #3f6ad8"
          *ngIf="filterOption === 'Discharged'"
        >
          Status: {{ filterOption }}
        </span>
        <span
          style="white-space: break-spaces; color: #d92550"
          *ngIf="filterOption === 'Deceased'"
        >
          Status: {{ filterOption }}
        </span>

        <mat-icon>filter_list</mat-icon>
      </button>
      <mat-menu #filterMenu="matMenu">
        <button
          mat-menu-item
          style="font-weight: 400; font-size: 12px; width: 100%"
          (click)="filterOptionChanged('Active')"
        >
          Active
        </button>

        <button
          mat-menu-item
          style="font-weight: 400; font-size: 12px; width: 100%"
          (click)="filterOptionChanged('Discharged')"
        >
          Discharged
        </button>

        <button
          mat-menu-item
          style="font-weight: 400; font-size: 12px; width: 100%"
          (click)="filterOptionChanged('Deceased')"
        >
          Deceased
        </button>
      </mat-menu>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div *ngIf="data.total === 0 && !processing">
      <mat-error> No Member found </mat-error>
    </div>

    <mat-progress-bar
      mode="indeterminate"
      *ngIf="processing"
    ></mat-progress-bar>

    <div *ngIf="data.total > 0">
      <div class="col-md-12" *ngFor="let row of data.items; let i = index">
        <mat-card appearance="raised" class="screen-display mat-elevation-z8">
          <div class="header-items">
            <!-- Handling Restricted vs not restricted -->
            <!-- Not Restricted members -->
            <button
              *ngIf="!row.restrictedMember"
              [routerLink]="['/main/member', row.id, 'NOTES']"
              type="button"
              mat-raised-button
              color="primary"
            >
              <u>
                {{ row.lastName }}, {{ row.firstName }}
                {{ row.middleName }}
              </u>
            </button>
            <!--  Restricted members -->
            <button
              *ngIf="row.restrictedMember"
              type="button"
              mat-raised-button
              [color]="
                !isOrgAdmin &&
                (row.authorizedUsers == null ||
                  row.authorizedUsers?.indexOf(loggedInUserId) < 0)
                  ? 'warn'
                  : 'primary'
              "
            >
              <!-- Without link -->
              <u
                *ngIf="
                  !isOrgAdmin &&
                  (row.authorizedUsers == null ||
                    row.authorizedUsers?.indexOf(loggedInUserId) < 0)
                "
              >
                {{ row.lastName }}, {{ row.firstName }}
                {{ row.middleName }}
              </u>
              <!-- With link -->
              <u
                *ngIf="
                  isOrgAdmin ||
                  !(
                    row.authorizedUsers == null ||
                    row.authorizedUsers?.indexOf(loggedInUserId) < 0
                  )
                "
                [routerLink]="['/main/member', row.id, 'NOTES']"
              >
                {{ row.lastName }}, {{ row.firstName }}
                {{ row.middleName }}
              </u>
            </button>

            <label
              *ngIf="row.restrictedMember"
              style="display: flex; align-items: center; color: red; margin: 0"
            >
              Restricted Member <mat-icon color="warn"> lock </mat-icon>
            </label>
          </div>
          <div class="card-member-data">
            <!-- Display Member Data -->
            <div
              class="row"
              *ngIf="
                !row.restrictedMember ||
                isOrgAdmin ||
                !(
                  row.authorizedUsers == null ||
                  row.authorizedUsers?.indexOf(loggedInUserId) < 0
                )
              "
            >
              <div class="col-md-3">
                <div class="mat-header-cell" style="font-weight: bold">
                  Member Number
                </div>
                <div class="mat-cell">
                  <label>{{ row?.memberNumber }}</label>
                </div>
              </div>
              <div class="col-md-2">
                <div class="mat-header-cell" style="font-weight: bold">
                  Status
                </div>
                <div class="mat-cell">
                  <label
                    [class]="
                      row?.status === 'Active'
                        ? 'activeStatus'
                        : 'inactiveStatus'
                    "
                  >
                    {{ row?.status }}
                  </label>
                </div>
              </div>
              <div class="col-md-2">
                <div class="mat-header-cell" style="font-weight: bold">
                  Gender
                </div>
                <div class="mat-cell" style="text-transform: capitalize">
                  <label>{{
                    row?.gender === 'M'
                      ? 'Male'
                      : row?.gender === 'F'
                      ? 'Female'
                      : row?.gender.toLowerCase()
                  }}</label>
                </div>
              </div>
              <div class="col-md-2">
                <div class="mat-header-cell" style="font-weight: bold">DOB</div>
                <div class="mat-cell">
                  <label>{{ row.dateOfBirth | date : 'MM/dd/y' }}</label>
                </div>
              </div>
              <div class="col-md-3">
                <div class="mat-header-cell" style="font-weight: bold">
                  Phone Number
                </div>
                <div class="mat-cell">
                  <label>
                    <span *ngIf="row.phoneNumber">
                      {{ row.phoneNumber | mask : '(000) 000-0000' }}
                    </span>
                  </label>
                </div>
              </div>
            </div>

            <!-- Display Restricted -->
            <div
              *ngIf="
                !isOrgAdmin &&
                row.restrictedMember &&
                (row.authorizedUsers == null ||
                  row.authorizedUsers?.indexOf(loggedInUserId) < 0)
              "
            >
              <mat-error>
                Sorry, you do not have access to view member details. Please
                contact your administrator.
              </mat-error>
            </div>
          </div>
        </mat-card>
      </div>
    </div>

    <div class="col-md-12">
      <mat-paginator
        *ngIf="data.total > data.per_page"
        #paginator
        [length]="data.total"
        [pageSize]="data.per_page"
        [showFirstLastButtons]="true"
        (page)="getNext($event)"
        style="background: transparent"
      >
      </mat-paginator>
    </div>
    <br />
  </div>
</div>
