import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { DataTablesModel } from '../../../_models';
import { FacilitiesHttpService } from '../../../_services/facilities.service';
import { FacilitiesSandbox } from '../../sandbox/facilities.sandbox';
import * as FacilityActions from '../actions/facility.actions';

@Injectable()
export class FacilityEffects {
  constructor(
    private facilityService: FacilitiesHttpService,
    private actions$: Actions,
    private facilitiesSandbox: FacilitiesSandbox
  ) {}

  getFacilities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FacilityActions.getFacilityListAction),
      withLatestFrom(this.facilitiesSandbox.facilities$),
      switchMap(([, list]) => {
        if (!list) {
          return this.facilityService.getMyFacilities(null).pipe(
            map((dataTable: DataTablesModel) => {
              return FacilityActions.getFacilityListSuccessAction({
                payload: dataTable.items,
              });
            }),
            catchError((error: Error) => {
              return of(
                FacilityActions.getFacilityListFailedAction({ payload: error })
              );
            })
          );
        }
      })
    );
  });

  public getFacilityResources$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(FacilityActions.getFacilityResourcesAction),
      withLatestFrom(this.facilitiesSandbox.facilityResources$),
      switchMap(([, storeState]) => {
        if (!storeState) {
          return this.facilityService.getMyFacilityResources().pipe(
            map((dataTable: DataTablesModel) => {
              return FacilityActions.getFacilityResourcesSuccessAction({
                payload: dataTable.items,
              });
            }),
            catchError((error: Error) => {
              return of(
                FacilityActions.getFacilityResourcesFailedAction({
                  payload: error,
                })
              );
            })
          );
        }
      })
    )
  );
}
