<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Panic Agoraphobia Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="panicAgoraphobiaForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10 mat-elevation-z8">
          <label class="col-md-12 asterix-after"
            >1. How frequently did you have panic attacks?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              [vertical]="true"
              style="width: 100%"
              formControlName="q1"
            >
              <mat-button-toggle value="no panic attacks in the past week"
                >no panic attacks in the past week</mat-button-toggle
              >
              <mat-button-toggle value="1 panic attack in the past week"
                >1 panic attack in the past week</mat-button-toggle
              >
              <mat-button-toggle value="2-3 panic attacks in the past week"
                >2-3 panic attacks in the past week</mat-button-toggle
              >
              <mat-button-toggle value="4-6 panic attacks in the past week"
                >4-6 panic attacks in the past week</mat-button-toggle
              >
              <mat-button-toggle
                value="more than 6 panic attacks in the past week"
                >more than 6 panic attacks in the past week</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10 mat-elevation-z8">
          <label class="col-md-12 asterix-after"
            >2. How severe were the panic attacks in the last week?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              [vertical]="true"
              style="width: 100%"
              formControlName="q2"
            >
              <mat-button-toggle value="no panic attacks"
                >no panic attacks</mat-button-toggle
              >
              <mat-button-toggle value="attacks were usually mild"
                >attacks were usually mild</mat-button-toggle
              >
              <mat-button-toggle value="attacks were usually moderate"
                >attacks were usually moderate</mat-button-toggle
              >
              <mat-button-toggle value="attacks were usually severe"
                >attacks were usually severe</mat-button-toggle
              >
              <mat-button-toggle value="attacks were usually extremely severe"
                >attacks were usually extremely severe</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10 mat-elevation-z8">
          <label class="col-md-12 asterix-after"
            >3. How long did the panic attacks last?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              [vertical]="true"
              style="width: 100%"
              formControlName="q3"
            >
              <mat-button-toggle value="no panic attacks"
                >no panic attacks</mat-button-toggle
              >
              <mat-button-toggle value="1 to 10 minutes"
                >1 to 10 minutes</mat-button-toggle
              >
              <mat-button-toggle value="10 to 60 minutes"
                >10 to 60 minutes</mat-button-toggle
              >
              <mat-button-toggle value="1 to 2 hours"
                >1 to 2 hours</mat-button-toggle
              >
              <mat-button-toggle value="over 2 hours"
                >over 2 hours</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10 mat-elevation-z8">
          <label class="col-md-12 asterix-after"
            >4. Were most of the attacks expected (occurring in feared
            situations) or unexpected (spontaneous)?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              [vertical]="true"
              style="width: 100%"
              formControlName="q4"
            >
              <mat-button-toggle value="mostly unexpected (or no panic attacks)"
                >mostly unexpected (or no panic attacks)</mat-button-toggle
              >
              <mat-button-toggle value="more unexpected than expected"
                >more unexpected than expected</mat-button-toggle
              >
              <mat-button-toggle value="some unexpected, some expected"
                >some unexpected, some expected</mat-button-toggle
              >
              <mat-button-toggle value="more expected than unexpected"
                >more expected than unexpected</mat-button-toggle
              >
              <mat-button-toggle value="mostly expected"
                >mostly expected</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10 mat-elevation-z8">
          <label class="col-md-12 asterix-after"
            >5. In the past week, did you avoid certain situations because you
            feared having a panic attack or a feeling of discomfort?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              [vertical]="true"
              style="width: 100%"
              formControlName="q5"
            >
              <mat-button-toggle
                value="no avoidance (or my attacks don't occur in specific situations"
                >no avoidance (or my attacks don't occur in specific
                situations</mat-button-toggle
              >
              <mat-button-toggle
                value="infrequent avoidance of feared situations"
                >infrequent avoidance of feared situations</mat-button-toggle
              >
              <mat-button-toggle
                value="occasional avoidance of feared situations"
                >occasional avoidance of feared situations</mat-button-toggle
              >
              <mat-button-toggle value="frequent avoidance of feared situations"
                >frequent avoidance of feared situations</mat-button-toggle
              >
              <mat-button-toggle
                value="very frequent avoidance of feared situations"
                >very frequent avoidance of feared situations</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10 mat-elevation-z8">
          <label class="col-md-12 asterix-after"
            >6. Please check the situation(s) you avoided or in which you
            developed panic attacks or a feeling of discomfort when you are not
            accompanied:</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              [vertical]="true"
              style="width: 100%"
              formControlName="q6"
              multiple
            >
              <mat-button-toggle value="Airplanes">Airplanes</mat-button-toggle>
              <mat-button-toggle value="Subways (underground)"
                >Subways (underground)</mat-button-toggle
              >
              <mat-button-toggle value="Buses, trains"
                >Buses, trains</mat-button-toggle
              >
              <mat-button-toggle value="Ships">Ships</mat-button-toggle>
              <mat-button-toggle value="Theaters, cinemas"
                >Theaters, cinemas</mat-button-toggle
              >
              <mat-button-toggle value="Supermarkets"
                >Supermarkets</mat-button-toggle
              >
              <mat-button-toggle value="Standing in lines (queues)"
                >Standing in lines (queues)</mat-button-toggle
              >
              <mat-button-toggle value="Auditoriums, stadiums"
                >Auditoriums, stadiums</mat-button-toggle
              >
              <mat-button-toggle value="Parties, social gatherings"
                >Parties, social gatherings</mat-button-toggle
              >
              <mat-button-toggle value="Crowds">Crowds</mat-button-toggle>
              <mat-button-toggle value="Restaurants"
                >Restaurants</mat-button-toggle
              >
              <mat-button-toggle value="Museums">Museums</mat-button-toggle>
              <mat-button-toggle value="Elevators (lifts)"
                >Elevators (lifts)</mat-button-toggle
              >
              <mat-button-toggle value="Enclosed spaces (tunnels)"
                >Enclosed spaces (tunnels)</mat-button-toggle
              >
              <mat-button-toggle value="Classrooms, lecture halls"
                >Classrooms, lecture halls</mat-button-toggle
              >
              <mat-button-toggle value="Driving or riding in a car"
                >Driving or riding in a car</mat-button-toggle
              >
              <mat-button-toggle value="Large rooms (lobbies)"
                >Large rooms (lobbies)</mat-button-toggle
              >
              <mat-button-toggle value="Walking on the street"
                >Walking on the street</mat-button-toggle
              >
              <mat-button-toggle value="Fluids,  wide streets, courtyards"
                >Fluids, wide streets, courtyards</mat-button-toggle
              >
              <mat-button-toggle value="High places"
                >High places</mat-button-toggle
              >
              <mat-button-toggle value="Crossing bridges"
                >Crossing bridges</mat-button-toggle
              >
              <mat-button-toggle value="Traveling away from home"
                >Traveling away from home</mat-button-toggle
              >
              <mat-button-toggle value="Staying home alone"
                >Staying home alone</mat-button-toggle
              >
              <mat-button-toggle value="Other">Other</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10 mat-elevation-z8">
          <label class="col-md-12 asterix-after"
            >7. How important were the avoided situations?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              [vertical]="true"
              style="width: 100%"
              formControlName="q7"
            >
              <mat-button-toggle value="unimportant (or no agoraphobia)"
                >unimportant (or no agoraphobia)</mat-button-toggle
              >
              <mat-button-toggle value="not very important"
                >not very important</mat-button-toggle
              >
              <mat-button-toggle value="moderately important"
                >moderately important</mat-button-toggle
              >
              <mat-button-toggle value="very important"
                >very important</mat-button-toggle
              >
              <mat-button-toggle value="extremely important"
                >extremely important</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10 mat-elevation-z8">
          <label class="col-md-12 asterix-after"
            >8. In the past week, did you suffer from the fear of having a panic
            attack (anticipatory anxiety or “fear of being afraid?”)</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              [vertical]="true"
              style="width: 100%"
              formControlName="q8"
            >
              <mat-button-toggle value="no anticipatory anxiety"
                >no anticipatory anxiety</mat-button-toggle
              >
              <mat-button-toggle
                value="infrequent fear of having a panic attack"
                >infrequent fear of having a panic attack</mat-button-toggle
              >
              <mat-button-toggle value="sometimes fear having a panic attack"
                >sometimes fear having a panic attack</mat-button-toggle
              >
              <mat-button-toggle value="frequent fear of having a panic attack"
                >frequent fear of having a panic attack</mat-button-toggle
              >
              <mat-button-toggle value="fear having a panic attack all the time"
                >fear having a panic attack all the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10 mat-elevation-z8">
          <label class="col-md-12 asterix-after"
            >9. How strong was this “fear of fear?”</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              [vertical]="true"
              style="width: 100%"
              formControlName="q9"
            >
              <mat-button-toggle value="none">none</mat-button-toggle>
              <mat-button-toggle value="mild">mild</mat-button-toggle>
              <mat-button-toggle value="moderate">moderate</mat-button-toggle>
              <mat-button-toggle value="marked">marked</mat-button-toggle>
              <mat-button-toggle value="extreme">extreme</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10 mat-elevation-z8">
          <label class="col-md-12 asterix-after"
            >10. In the past week, did panic attacks or agoraphobia lead to an
            impairment in your family relationships (partner, children,
            etc.)?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              [vertical]="true"
              style="width: 100%"
              formControlName="q10"
            >
              <mat-button-toggle value="no impairment"
                >no impairment</mat-button-toggle
              >
              <mat-button-toggle value="mild impairment"
                >mild impairment</mat-button-toggle
              >
              <mat-button-toggle value="moderate impairment"
                >moderate impairment</mat-button-toggle
              >
              <mat-button-toggle value="marked impairment"
                >marked impairment</mat-button-toggle
              >
              <mat-button-toggle value="extreme impairment"
                >extreme impairment</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10 mat-elevation-z8">
          <label class="col-md-12 asterix-after"
            >11. In the past week, did panic attacks or agoraphobia lead to an
            impairment of your social life and leisure activities (for example,
            you weren't able to go a film or party)?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              [vertical]="true"
              style="width: 100%"
              formControlName="q11"
            >
              <mat-button-toggle value="no impairment"
                >no impairment</mat-button-toggle
              >
              <mat-button-toggle value="mild impairment"
                >mild impairment</mat-button-toggle
              >
              <mat-button-toggle value="moderate impairment"
                >moderate impairment</mat-button-toggle
              >
              <mat-button-toggle value="marked impairment"
                >marked impairment</mat-button-toggle
              >
              <mat-button-toggle value="extreme impairment"
                >extreme impairment</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10 mat-elevation-z8">
          <label class="col-md-12 asterix-after"
            >12. In the past week, did panic attacks or agoraphobia lead to an
            impairment of your work or household responsibilities?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              [vertical]="true"
              style="width: 100%"
              formControlName="q12"
            >
              <mat-button-toggle value="no impairment"
                >no impairment</mat-button-toggle
              >
              <mat-button-toggle value="mild impairment"
                >mild impairment</mat-button-toggle
              >
              <mat-button-toggle value="moderate impairment"
                >moderate impairment</mat-button-toggle
              >
              <mat-button-toggle value="marked impairment"
                >marked impairment</mat-button-toggle
              >
              <mat-button-toggle value="extreme impairment"
                >extreme impairment</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10 mat-elevation-z8">
          <label class="col-md-12 asterix-after"
            >13. In the past week, did you worry about suffering harm from your
            panic attacks (for example, having a heart attack or
            fainting)?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              [vertical]="true"
              style="width: 100%"
              formControlName="q13"
            >
              <mat-button-toggle value="not true">not true</mat-button-toggle>
              <mat-button-toggle value="hardly true"
                >hardly true</mat-button-toggle
              >
              <mat-button-toggle value="partly true"
                >partly true</mat-button-toggle
              >
              <mat-button-toggle value="mostly true"
                >mostly true</mat-button-toggle
              >
              <mat-button-toggle value="definitely true"
                >definitely true</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10 mat-elevation-z8">
          <label class="col-md-12 asterix-after"
            >14. Do you sometimes believe that your doctor was wrong when he
            told you your symptoms (like rapid heart rate, tingling sensations,
            or shortness of breath) have a psychological cause? Do you believe
            the actually cause of these symptoms is an undiscovered physical
            problem?</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group
              [vertical]="true"
              style="width: 100%"
              formControlName="q14"
            >
              <mat-button-toggle value="not true (rather psychic disease)"
                >not true (rather psychic disease)</mat-button-toggle
              >
              <mat-button-toggle value="hardly true"
                >hardly true</mat-button-toggle
              >
              <mat-button-toggle value="partly true"
                >partly true</mat-button-toggle
              >
              <mat-button-toggle value="mostly true"
                >mostly true</mat-button-toggle
              >
              <mat-button-toggle
                value="definitely true (rather organic disease)"
                >definitely true (rather organic disease)</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          panicAgoraphobiaForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!panicAgoraphobiaForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
