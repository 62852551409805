import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { DataTablesModel, ITimeOff } from 'src/app/_models';
import {
  DateTimeZoneService,
  TimeOffAPIService,
  ToastMessageService,
} from 'src/app/_services';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { AddVacationPeriodComponent } from './dialogs/add-vacation-period/add-vacation-period.component';

@Component({
  selector: 'app-my-vacation',
  templateUrl: './my-vacation.component.html',
  styleUrls: ['./my-vacation.component.css'],
})
export class MyVacationComponent implements OnInit {
  @Input() canEditTimeOff: boolean;

  displayColumns = ['startDay', 'endDay', 'reason'];
  processing: boolean = true;
  data: DataTablesModel = {} as DataTablesModel;
  currentTimezone: string;

  constructor(
    public dialog: MatDialog,
    private timeOffApiService: TimeOffAPIService,
    private toastMessageService: ToastMessageService,
    private dateTimeZoneService: DateTimeZoneService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit(): void {
    this.data.per_page = 10;
    this.data.page = 0;

    if (this.canEditTimeOff) {
      if (!this.displayColumns.includes('actions')) {
        this.displayColumns.push('actions');
      }
    }

    this.loadMyTimeOffs();
  }

  public loadMyTimeOffs() {
    this.processing = true;

    this.timeOffApiService
      .getMyTimeOffs(this.data.per_page, this.data.page)
      .subscribe(
        (response) => {
          this.processing = false;
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }
        },
        (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to retrieve time off(s).'
          );
        }
      );
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadMyTimeOffs();
  }

  addTimeOff() {
    const dialogRef = this.dialog.open(AddVacationPeriodComponent, {
      data: { action: 'ADD' },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadMyTimeOffs();
      }
    });
  }

  public editTimeOff(timeOff: ITimeOff) {
    const dialogRef = this.dialog.open(AddVacationPeriodComponent, {
      data: { action: 'EDIT', timeOff: timeOff },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadMyTimeOffs();
      }
    });
  }

  deleteTimeOff(myvacationdays: ITimeOff) {
    const dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message: 'Are you sure that you want to delete this time off record?',
      },
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.timeOffApiService.deleteTimeOff(myvacationdays.id).subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Time Off records have been updated.'
            );

            // Handle Delete Pagination
            if (this.data.items.length == 1) {
              this.data.page = this.data.page - 1;
              if (this.data.page < 0) {
                this.data.page = 0;
              }
            }

            this.loadMyTimeOffs();
          },
          (error) => {
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to update the time off records.'
            );
          }
        );
      }
    });
  }
}
