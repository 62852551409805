import { Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { MyAssessmentsComponent } from './patient-components/assessments/my-assessments/my-assessments.component';
import { EligibilityRecordsComponent } from './patient-components/eligibility-records/eligibility-records.component';
import { GroupSessionsComponent } from './patient-components/group-sessions/group-sessions.component';
import { CollectMemberPaymentComponent } from './patient-components/member-tab/member-tab-sections/member-payments-transactions/collect-member-payment/collect-member-payment.component';
import { MyAssignedCasesComponent } from './patient-components/my-assigned-cases/my-assigned-cases.component';
import { PatientDetailsComponent } from './patient-details.component';

export const PatientDetailRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'member/:memberId/:primaryTab',
        component: PatientDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          roles: ['physician', 'orgadmin', 'superorgadmin', 'careprovider'],
          excludeRoles: ['patient', 'inhospitaldevice'],
        },
      },
      {
        path: 'collectmemberpayment/:memberId',
        component: CollectMemberPaymentComponent,
        canActivate: [AuthGuard],
        data: {
          roles: ['physician', 'orgadmin', 'superorgadmin', 'careprovider'],
          excludeRoles: ['patient', 'inhospitaldevice'],
        },
      },
      {
        path: 'assessments',
        component: MyAssessmentsComponent,
        canActivate: [AuthGuard],
        data: {
          roles: ['physician', 'orgadmin', 'superorgadmin', 'careprovider'],
          excludeRoles: ['patient', 'inhospitaldevice'],
        },
      },
      {
        path: 'cases',
        component: MyAssignedCasesComponent,
        canActivate: [AuthGuard],
        data: {
          roles: ['physician', 'orgadmin', 'superorgadmin', 'careprovider'],
          excludeRoles: ['patient', 'inhospitaldevice'],
        },
      },
      {
        path: 'group-sessions/:id',
        component: GroupSessionsComponent,
        canActivate: [AuthGuard],
        data: {
          roles: ['physician', 'orgadmin', 'superorgadmin', 'careprovider'],
          excludeRoles: ['patient', 'inhospitaldevice'],
        },
      },
      {
        path: 'eligibility-checks',
        component: EligibilityRecordsComponent,
        canActivate: [AuthGuard],
        data: {
          roles: ['physician', 'orgadmin', 'superorgadmin', 'careprovider'],
          excludeRoles: ['patient', 'inhospitaldevice'],
        },
      },
    ],
  },
];
