<mat-card
  appearance="raised"
  style="margin-top: 20px; border-radius: 15px"
  class="mat-elevation-z8"
>
  <p class="subCardHeader" (click)="sectionExpanded = !sectionExpanded">
    <span class="material-icons" style="color: red; font-size: 25px">
      warning
    </span>
    Alerts ({{ data.total }})
    <span
      class="material-icons"
      style="position: absolute; right: 10px"
      *ngIf="!sectionExpanded"
    >
      arrow_drop_down
    </span>
    <span
      class="material-icons"
      style="position: absolute; right: 10px"
      *ngIf="sectionExpanded"
    >
      arrow_drop_up
    </span>
  </p>

  <div *ngIf="sectionExpanded">
    <mat-divider></mat-divider>

    <!-- Add Button -->
    <div class="row">
      <div class="col-md-12" style="text-align: right; margin-top: 8px">
        <button
          type="button"
          mat-raised-button
          color="warn"
          (click)="addAlerts()"
        >
          + Add Alert
        </button>
      </div>

      <div
        style="
          display: flex;
          justify-content: space-between;
          margin-top: 8px;
          flex-wrap: wrap;
        "
      >
        <label style="padding-top: 8px" *ngIf="data.total > 0">
          Member has the following alerts or has not signed/submitted the
          following documents. If applicable, please verify the submitted
          documents.
        </label>
      </div>
    </div>

    <mat-progress-bar
      mode="indeterminate"
      *ngIf="isLoadingResults"
    ></mat-progress-bar>

    <div *ngIf="data?.total > 0" style="max-height: 400px; overflow-x: hidden">
      <!-- Loop through all to display the data -->
      <mat-card
        appearance="raised"
        *ngFor="let row of data.items"
        class="alertCard mat-elevation-z8"
        [style.border-left]="
          row.status === 'Pending' ? '5px solid red' : '5px solid orange'
        "
      >
        <!-- Left Side Icon -->
        <button
          mat-mini-fab
          type="button"
          style="background-color: transparent"
        >
          <mat-icon
            style="font-size: 18px"
            [style.color]="row.status === 'Pending' ? 'red' : 'orange'"
          >
            {{
              row.documentType === 'Consent Documents'
                ? 'border_color'
                : row.documentType === 'Member Alerts'
                ? 'report_problem'
                : 'feed'
            }}
          </mat-icon>
        </button>

        <!-- Right Side Alert Text -->
        <label class="labelBold">
          <span
            id="verifyButton"
            *ngIf="row.status === 'Pending_Verification'"
            style="display: flex; color: #d36d03"
            (click)="verifyDocument(row)"
          >
            <mat-icon style="font-size: 18px"> ads_click </mat-icon>
            Please Verify:
          </span>

          {{ row.documentName }}
          <br />
          <span
            class="requestedSection"
            *ngIf="row.documentType != 'Member Alerts'"
          >
            Requested on : {{ row.requestedDate | date : 'MM/dd/y' }}
          </span>
          <span
            class="requestedSection"
            *ngIf="row.documentType === 'Member Alerts'"
          >
            Created on : {{ row.requestedDate | date : 'MM/dd/y' }}
          </span>
        </label>

        <!-- Actions -->
        <div
          style="position: absolute; right: 10px; top: 10px"
          *ngIf="row.status === 'Pending'"
        >
          <span
            id="cancelButton"
            class="material-icons"
            style="
              color: gray;
              font-size: 17px;
              cursor: pointer;
              border-radius: 50%;
              z-index: 2;
              border: 2px solid darkgray;
              opacity: 0.5;
            "
            [matMenuTriggerFor]="menu"
          >
            more_vert
          </span>

          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              type="button"
              *ngIf="
                row.documentType != 'Member Alerts' &&
                row.status === 'Pending' &&
                row.documentType != 'Profile Forms'
              "
              (click)="fillOutDocument(row)"
            >
              <mat-icon style="color: #029102"> edit_note </mat-icon>
              <span>Fill Out Document</span>
            </button>

            <button
              mat-menu-item
              type="button"
              *ngIf="row.documentType != 'Member Alerts'"
              (click)="resendEmailAlerts()"
            >
              <mat-icon style="color: #f39121"> forward_to_inbox </mat-icon>
              <span>Resend Email</span>
            </button>
            <button
              mat-menu-item
              type="button"
              (click)="deleteMemberAlert(row.id, row.documentType)"
              [disabled]="deleteProcessing"
            >
              <mat-icon style="color: red"> delete_forever </mat-icon>
              <span>Delete</span>
            </button>
          </mat-menu>
        </div>
      </mat-card>
    </div>
  </div>
</mat-card>
