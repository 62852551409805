import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IAsiLegal } from 'src/app/_models/assessments/asi/asilegal.model';
import { AsiApiService } from 'src/app/_services/assessments/asi/asi.service';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-asi-legal',
  templateUrl: './asi-legal.component.html',
  styleUrls: ['./asi-legal.component.css']
})

export class AsiLegalComponent implements OnInit {
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentRequestId: string;

  constructor(
    private asiApiService: AsiApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute) {
  }

  public action: string;
  public asilegalForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  asilegal: IAsiLegal;
  asilegalError: Error = null;
  errors: any = {};
  processing: boolean = false;
  systemGeneratedScore: number;

  public ngOnInit() {
    this.buildForm();
    this.errorHandler.handleErrors(this.asilegalForm, this.errors);

    this.loadAsiLegal();
  }

  loadAsiLegal() {
    this.asiApiService.getAsiDetails(this.assessmentRequestId).subscribe(response => {
      if (response.data) {
        this.asilegalForm.controls['l1'].setValue(response.data.asiLegal.l1);
        this.asilegalForm.controls['l2'].setValue(response.data.asiLegal.l2);
        this.asilegalForm.controls['l3'].setValue(response.data.asiLegal.l3);
        this.asilegalForm.controls['l4'].setValue(response.data.asiLegal.l4);
        this.asilegalForm.controls['l5'].setValue(response.data.asiLegal.l5);
        this.asilegalForm.controls['l6'].setValue(response.data.asiLegal.l6);
        this.asilegalForm.controls['l7'].setValue(response.data.asiLegal.l7);
        this.asilegalForm.controls['l8'].setValue(response.data.asiLegal.l8);
        this.asilegalForm.controls['l9'].setValue(response.data.asiLegal.l9);
        this.asilegalForm.controls['l10'].setValue(response.data.asiLegal.l10);
        this.asilegalForm.controls['l11'].setValue(response.data.asiLegal.l11);
        this.asilegalForm.controls['l12'].setValue(response.data.asiLegal.l12);
        this.asilegalForm.controls['l13'].setValue(response.data.asiLegal.l13);
        this.asilegalForm.controls['l14'].setValue(response.data.asiLegal.l14);
        this.asilegalForm.controls['l15'].setValue(response.data.asiLegal.l15);
        this.asilegalForm.controls['l16'].setValue(response.data.asiLegal.l16);
        this.asilegalForm.controls['l17'].setValue(response.data.asiLegal.l17);
        this.asilegalForm.controls['l18'].setValue(response.data.asiLegal.l18);
        this.asilegalForm.controls['l19'].setValue(response.data.asiLegal.l19);
        this.asilegalForm.controls['l20'].setValue(response.data.asiLegal.l20);
        this.asilegalForm.controls['l21'].setValue(response.data.asiLegal.l21);
        this.asilegalForm.controls['l22'].setValue(response.data.asiLegal.l22);
        this.asilegalForm.controls['l23'].setValue(response.data.asiLegal.l23);
        this.asilegalForm.controls['l24'].setValue(response.data.asiLegal.l24);
        this.asilegalForm.controls['l25'].setValue(response.data.asiLegal.l25);
        this.asilegalForm.controls['l26'].setValue(response.data.asiLegal.l26);
        this.asilegalForm.controls['l27'].setValue(response.data.asiLegal.l27);
        this.asilegalForm.controls['l28'].setValue(response.data.asiLegal.l28);
        this.asilegalForm.controls['l29'].setValue(response.data.asiLegal.l29);
        this.asilegalForm.controls['l30'].setValue(response.data.asiLegal.l30);
        this.asilegalForm.controls['l31'].setValue(response.data.asiLegal.l31);
        this.asilegalForm.controls['l32'].setValue(response.data.asiLegal.l32);
        this.asilegalForm.controls['comments'].setValue(response.data.asiLegal.comments);
        this.asilegalForm.controls['clinicianScore'].setValue(response.data.asiLegal.clinicianScore);
      }
    });
  }
  onAsiLegalFormSubmit() {
    if (this.asilegalForm.invalid) {
      this.asilegalForm.markAllAsTouched();
      return;
    }
    this.asilegal = Object.assign({}, this.asilegal);
    this.asilegal = Object.assign(this.asilegal, this.asilegalForm.value);
    this.asiApiService.updateAsiLegal(this.asilegal).subscribe(() => {
    })
  };


  public validation_messages = {
    'clinicianScore': [
      { type: 'required', message: 'Clinician Score is required' },
    ],
    'l1': [
      { type: 'required', message: 'L1. Was this admission prompted or suggested by the criminal justice system?  is required' },
    ],
    'l2': [
      { type: 'required', message: 'L2. Are you on probation or parole?  is required' },
    ],
    'l3': [
      { type: 'required', message: 'L3. Are you on probation or parole?  is required' },
    ],
    'l4': [
      { type: 'required', message: 'L4. Parole/Probation Violations  is required' },
    ],
    'l5': [
      { type: 'required', message: 'L5. Drug Charges is required' },
    ],
    'l6': [
      { type: 'required', message: 'L6. Forgery is required' },
    ],
    'l7': [
      { type: 'required', message: 'L7. Weapons Offense is required' },
    ],
    'l8': [
      { type: 'required', message: 'L8. Burglary/Larceny/Breaking & Entering is required' },
    ],
    'l9': [
      { type: 'required', message: 'L9. Robbery is required' },
    ],
    'l10': [
      { type: 'required', message: 'L10. Assault is required' },
    ],
    'l11': [
      { type: 'required', message: 'L11. Arson is required' },
    ],
    'l12': [
      { type: 'required', message: 'L12. Rape is required' },
    ],
    'l13': [
      { type: 'required', message: 'L13. Homicide/Mnslaughter is required' },
    ],
    'l14': [
      { type: 'required', message: 'L14. Prostitution is required' },
    ],
    'l15': [
      { type: 'required', message: 'L15. Contempt of Court is required' },
    ],
    'l16': [
      { type: 'required', message: 'L16. Other is required' },
    ],
    'l17': [
      { type: 'required', message: 'L17. How many of these charges resulted in convictions? is required' },
    ],
    'l18': [
      { type: 'required', message: 'L18. Disorderly conduct, vagrancy, public intoxication is required' },
    ],
    'l19': [
      { type: 'required', message: 'L19. Driving while intoxicated is required' },
    ],
    'l20': [
      { type: 'required', message: 'L20. Major driving violations is required' },
    ],
    'l21': [
      { type: 'required', message: 'L21. Moving violations:  speeding, reckless driving, no license, etc. is required' },
    ],
    'l22': [
      { type: 'required', message: 'L22. If incarcerated 2 weeks or more, round this up to 1 month.   List total number of months incarcerated.  is required' },
    ],
    'l23': [
      { type: 'required', message: 'L23. What was it for? is required' },
    ],
    'l24': [
      { type: 'required', message: 'L24. Are you presently awaiting charges, trial, or sentence? is required' },
    ],
    'l25': [
      { type: 'required', message: 'L25. What for? is required' },
    ],
    'l26': [
      { type: 'required', message: 'L26. How many days in the past 30 were you detained or incarcerated?  is required' },
    ],
    'l27': [
      { type: 'required', message: 'L27. How many days in the past 30 have you engaged in illegal activities for profit? is required' },
    ],
    'l28': [
      { type: 'required', message: 'L28. How serious do you feel your present legal problems are?  is required' },
    ],
    'l29': [
      { type: 'required', message: 'L29. How important to you now is counseling or referral for these legal problems? is required' },
    ],
    'l30': [
      { type: 'required', message: 'L30. How would you rate the patient’s need for legal services or counseling? is required' },
    ],
    'l31': [
      { type: 'required', message: 'L31. How would you rate the patient’s need for legal services or counseling? is required' },
    ],
    'l32': [
      { type: 'required', message: 'L32. How would you rate the patient’s need for legal services or counseling? is required' },
    ],
    'comments': [
      { type: 'required', message: 'Comments is required' },
    ],
  };

  private buildForm() {
    this.asilegalForm = this.formBuilder.group({
      clinicianScore: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      systemScore: new FormControl(0, Validators.compose([
      ])),
      sessionId: new FormControl(this.sessionId, Validators.compose([
        Validators.required,
      ])),
      patientId: new FormControl(this.patientId, Validators.compose([
        Validators.required,
      ])),
      assessmentRequestId: new FormControl(this.assessmentRequestId, Validators.compose([
        Validators.required,
      ])),
      l1: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l2: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l3: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l4: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l5: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l6: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l7: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l8: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l9: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l10: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l11: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l12: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l13: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l14: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l15: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l16: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l17: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l18: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l19: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l20: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l21: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l22: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l23: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l24: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l25: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l26: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l27: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l28: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l29: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l30: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l31: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      l32: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      comments: new FormControl('', Validators.compose([
        Validators.required,
      ])),
    });
    this.asilegalForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.asilegalForm) {
      return;
    }
    const form = this.asilegalForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}