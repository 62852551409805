import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
import { CareLog, Case, Countries } from 'src/app/_models';
import { CareLogHttpService, ToastMessageService } from 'src/app/_services';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import {
  formatDate,
  formatDateTime,
  mmddyyyyhhmiToDate,
} from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-carelog-bowel-movement',
  templateUrl: './carelog-bowel-movement.component.html',
  styleUrls: ['./carelog-bowel-movement.component.css'],
})
export class CarelogBowelMovementComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() action: string;
  @Input() patientId: string;
  @Input() case: Case;
  @Input() logDate: Date;
  @Input() shift: number;
  @Input() carelog: CareLog;
  @Input() goals: any[];
  @Input() goalDrivenCare: boolean;
  @Output() taskCompleted = new EventEmitter<any>();

  todaysDate = new Date();

  orgConfig: any;
  isIndianOrg: boolean = false;
  initial = new FormControl('', Validators.required);
  details = new FormControl('', Validators.compose([]));
  initialAnswer: string = '';
  isProcessing: boolean = false;

  constructor(
    public careLogHttpService: CareLogHttpService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    dateTimeAdapter: DateTimeAdapter<any>,
    private toastMessageService: ToastMessageService,
    private orgConfigSandbox: OrgConfigSandbox
  ) {
    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          this.orgConfig = orgConfig;
          if (this.orgConfig?.organizationCountry === Countries.India) {
            this.isIndianOrg = true;
            dateTimeAdapter.setLocale('en-GB');
          }
        });
      }
    });
  }

  public bowelForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  errors: any = {};

  public ngOnInit() {
    this.buildForm();
    if (this.carelog && this.carelog.bowelMovement) {
      this.initialAnswer = this.carelog.bowelMovement.hadBowel;
      this.bowelForm.controls['hadBowel'].setValue(
        this.carelog.bowelMovement.hadBowel
      );
      this.bowelForm.controls['size'].setValue(this.carelog.bowelMovement.size);
      this.bowelForm.controls['color'].setValue(
        this.carelog.bowelMovement.color
      );
      this.bowelForm.controls['consistency'].setValue(
        this.carelog.bowelMovement.consistency
      );
      this.bowelForm.controls['customMessage'].setValue(
        this.carelog.customMessage
      );

      this.bowelForm.controls['activityDateTime'].setValue(
        mmddyyyyhhmiToDate(this.carelog.activityDateTime)
      );
    } else {
      this.bowelForm.controls['activityDateTime'].setValue(
        new Date(this.logDate)
      );
    }

    this.errorHandler.handleErrors(this.bowelForm, this.errors);
  }

  onBowelInfoSubmit() {
    if (this.bowelForm.invalid) {
      this.bowelForm.markAllAsTouched();
      return;
    }

    let bowelInfo: any = {};
    bowelInfo.hadBowel = this.bowelForm.controls['hadBowel'].value;
    bowelInfo.size = this.bowelForm.controls['size'].value;
    bowelInfo.color = this.bowelForm.controls['color'].value;
    bowelInfo.consistency = this.bowelForm.controls['consistency'].value;
    bowelInfo.activityDateTime = formatDateTime(
      this.bowelForm.controls['activityDateTime'].value
    );
    bowelInfo.customMessage = this.bowelForm.controls['customMessage'].value;

    if (this.carelog) {
      // Update here
      this.isProcessing = true;

      this.carelog.bowelMovement = bowelInfo;
      this.carelog.activityDateTime = formatDateTime(
        this.bowelForm.controls['activityDateTime'].value
      );
      this.carelog.customMessage =
        this.bowelForm.controls['customMessage'].value;

      if (this.action === 'ADD') {
        this.carelog.vitalInfoAvailable = false;
        this.carelog.patientId = this.case.patientId;
        this.carelog.message = 'Bowel Movement Info';

        this.addCareLog(this.carelog);
      } else {
        this.careLogHttpService
          .editCareLog(this.case.id, this.carelog)
          .subscribe(
            () => {
              this.isProcessing = false;
              let outputResponse = { note: this.carelog, response: 'success' };
              this.taskCompleted.emit(outputResponse);
              this.toastMessageService.displaySuccessMessage(
                'Bowel movement log has been updated'
              );
            },
            (error) => {
              this.isProcessing = false;
              this.toastMessageService.displayErrorMessage(
                'Failed to update bowel movement log'
              );
            }
          );
      }
    } else {
      this.carelog = Object.assign({}, this.carelog);
      this.carelog.vitalInfoAvailable = false;
      this.carelog.patientId = this.case.patientId;
      this.carelog.message = 'Bowel Movement Info';

      this.carelog.bowelMovement = bowelInfo;
      this.carelog.activityDateTime = formatDateTime(
        this.bowelForm.controls['activityDateTime'].value
      );
      this.carelog.customMessage =
        this.bowelForm.controls['customMessage'].value;
      this.carelog.vitalInfoAvailable = false;
      this.addCareLog(this.carelog);
    }
  }

  public addCareLog(carelog: CareLog) {
    this.isProcessing = true;
    carelog.caseId = this.case.id;
    carelog.logDate = formatDate(this.logDate);
    carelog.id = null;
    carelog.shift = this.shift;
    carelog.patientId = this.case.patientId;

    this.careLogHttpService.addCareLog(this.case.id, carelog).subscribe(() => {
      this.toastMessageService.displaySuccessMessage(
        'Bowel movement information has been updated.'
      );
      this.isProcessing = false;
      this.taskCompleted.emit(true);
    });
  }

  private buildForm() {
    this.bowelForm = this.formBuilder.group({
      hadBowel: new FormControl(Validators.compose([Validators.required])),
      color: new FormControl(null),
      size: new FormControl(null),
      consistency: new FormControl(null),
      activityDateTime: new FormControl(null, Validators.required),
      customMessage: new FormControl(''),
    });
  }

  selectInitialAnswer(initialAnswer: string, stepper: MatStepper) {
    this.initial.setValue(initialAnswer);
    this.initialAnswer = initialAnswer;
    this.bowelForm.controls['hadBowel'].setValue(initialAnswer);
    stepper.next();
  }

  public validation_messages = {
    size: [{ type: 'required', message: 'Size is required' }],
    color: [{ type: 'required', message: 'Color is required' }],
    consistency: [{ type: 'required', message: 'Consistency is required' }],
  };
}
