<div class="main-content" style="background-color: #eee" *ngIf="!isLoadingResults">
  <div>
    <div class="row pad10" *ngIf="!anchorTag">
      <div class="col-md-6">
        <mat-card>
          <mat-card-header>
            <h4 class="header text-center">
              Shopping Addiction - Overall Graph
            </h4>
          </mat-card-header>
          <mat-card-content>
            <div style="width: 100%">
              <div class="chart-wrapper">
                <canvas baseChart height="100%" [data]="lineChartData" [options]="lineChartOptions"
                  [legend]="lineChartLegend" [type]="lineChartType" [plugins]="lineChartPlugins">
                </canvas>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <br />
        <mat-card>
          <mat-card-header>
            <h4 class="header text-center">Symptom Chart</h4>
          </mat-card-header>
          <mat-card-content>
            <div style="width: 100%">
              <div class="chart-wrapper">
                <canvas baseChart height="100%" [data]="symptomChartData" [options]="symptomChartOptions"
                  [legend]="symptomChartLegend" [type]="symptomChartType">
                </canvas>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-md-6">
        <mat-card class="mat-card-auto-height">
          <mat-card-header>
            <h4 class="header text-center">Interpreting the Overall Score</h4>
          </mat-card-header>
          <mat-divider></mat-divider>
          <mat-card-content>
            <div class="row">
              <div class="col-md-2" style="font-weight: 400">Color</div>
              <div class="col-md-3" style="font-weight: 400">Score Range</div>
              <div class="col-md-7" style="padding-top: 4px; font-weight: 400">
                Description
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <button mat-raised-button class="btn btn-just-icon btn-fab btn-round" style="
                    height: 20px;
                    width: 20px;
                    min-width: 20px;
                    background-color: #5a9e7c;
                  "></button>
              </div>
              <div class="col-md-3" style="padding-top: 4px; font-weight: 300">
                0 to 3
              </div>
              <div class="col-md-7" style="padding-top: 4px; font-weight: 300">
                Not indicative of shopping addiction
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <button mat-raised-button class="btn btn-just-icon btn-fab btn-round" style="
                    height: 20px;
                    width: 20px;
                    min-width: 20px;
                    background-color: #ff6384;
                  "></button>
              </div>
              <div class="col-md-3" style="padding-top: 4px; font-weight: 300">
                4 to 7
              </div>
              <div class="col-md-7" style="padding-top: 4px; font-weight: 300">
                Indicative of shopping addiction
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="row pad10" *ngIf="
        scoreDetails.length > 0 && (!anchorTag || anchorTag === 'Salience')
      ">
      <div class="col-md-12">
        <mat-card class="mat-card-auto-height">
          <mat-card-header>
            <mat-card-title>Salience</mat-card-title>
          </mat-card-header>
          <mat-divider></mat-divider>
          <mat-card-content>
            <div class="row" style="padding: 15px">
              <div class="col-md-3">
                <mat-card class="mat-card-auto-height mat-elevation-z8">
                  <mat-card-header>
                    <h4 class="header text-center">
                      {{ scoreDetails[0].title }}
                    </h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetails[0]" [options]="assessmentDetailChartOptions"
                          [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetails[0].answer }}</i></small>
                </mat-card>
              </div>
              <div class="col-md-3">
                <mat-card class="mat-card-auto-height mat-elevation-z8">
                  <mat-card-header>
                    <h4 class="header text-center">
                      {{ scoreDetails[1].title }}
                    </h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetails[1]" [options]="assessmentDetailChartOptions"
                          [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetails[1].answer }}</i></small>
                </mat-card>
              </div>
              <div class="col-md-3">
                <mat-card class="mat-card-auto-height mat-elevation-z8">
                  <mat-card-header>
                    <h4 class="header text-center">
                      {{ scoreDetails[2].title }}
                    </h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetails[2]" [options]="assessmentDetailChartOptions"
                          [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetails[2].answer }}</i></small>
                </mat-card>
              </div>
              <div class="col-md-3">
                <mat-card class="mat-card-auto-height mat-elevation-z8">
                  <mat-card-header>
                    <h4 class="header text-center">
                      {{ scoreDetails[3].title }}
                    </h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetails[3]" [options]="assessmentDetailChartOptions"
                          [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetails[3].answer }}</i></small>
                </mat-card>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="row pad10" *ngIf="
        scoreDetails.length > 0 &&
        (!anchorTag || anchorTag === 'Mood Modification')
      ">
      <div class="col-md-12">
        <mat-card class="mat-card-auto-height">
          <mat-card-header>
            <mat-card-title>Mood Modification</mat-card-title>
          </mat-card-header>
          <mat-divider></mat-divider>
          <mat-card-content>
            <div class="row" style="padding: 15px">
              <div class="col-md-3">
                <mat-card class="mat-card-auto-height mat-elevation-z8">
                  <mat-card-header>
                    <h4 class="header text-center">
                      {{ scoreDetails[4].title }}
                    </h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetails[4]" [options]="assessmentDetailChartOptions"
                          [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetails[4].answer }}</i></small>
                </mat-card>
              </div>
              <div class="col-md-3">
                <mat-card class="mat-card-auto-height mat-elevation-z8">
                  <mat-card-header>
                    <h4 class="header text-center">
                      {{ scoreDetails[5].title }}
                    </h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetails[5]" [options]="assessmentDetailChartOptions"
                          [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetails[5].answer }}</i></small>
                </mat-card>
              </div>
              <div class="col-md-3">
                <mat-card class="mat-card-auto-height mat-elevation-z8">
                  <mat-card-header>
                    <h4 class="header text-center">
                      {{ scoreDetails[6].title }}
                    </h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetails[6]" [options]="assessmentDetailChartOptions"
                          [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetails[6].answer }}</i></small>
                </mat-card>
              </div>
              <div class="col-md-3">
                <mat-card class="mat-card-auto-height mat-elevation-z8">
                  <mat-card-header>
                    <h4 class="header text-center">
                      {{ scoreDetails[7].title }}
                    </h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetails[7]" [options]="assessmentDetailChartOptions"
                          [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetails[7].answer }}</i></small>
                </mat-card>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="row pad10" *ngIf="
        scoreDetails.length > 0 && (!anchorTag || anchorTag === 'Conflict')
      ">
      <div class="col-md-12">
        <mat-card class="mat-card-auto-height">
          <mat-card-header>
            <mat-card-title>Conflict</mat-card-title>
          </mat-card-header>
          <mat-divider></mat-divider>
          <mat-card-content>
            <div class="row" style="padding: 15px">
              <div class="col-md-3">
                <mat-card class="mat-card-auto-height mat-elevation-z8">
                  <mat-card-header>
                    <h4 class="header text-center">
                      {{ scoreDetails[8].title }}
                    </h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetails[8]" [options]="assessmentDetailChartOptions"
                          [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetails[8].answer }}</i></small>
                </mat-card>
              </div>
              <div class="col-md-3">
                <mat-card class="mat-card-auto-height mat-elevation-z8">
                  <mat-card-header>
                    <h4 class="header text-center">
                      {{ scoreDetails[9].title }}
                    </h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetails[9]" [options]="assessmentDetailChartOptions"
                          [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetails[9].answer }}</i></small>
                </mat-card>
              </div>
              <div class="col-md-3">
                <mat-card class="mat-card-auto-height mat-elevation-z8">
                  <mat-card-header>
                    <h4 class="header text-center">
                      {{ scoreDetails[10].title }}
                    </h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetails[10]" [options]="assessmentDetailChartOptions"
                          [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetails[10].answer }}</i></small>
                </mat-card>
              </div>
              <div class="col-md-3">
                <mat-card class="mat-card-auto-height mat-elevation-z8">
                  <mat-card-header>
                    <h4 class="header text-center">
                      {{ scoreDetails[11].title }}
                    </h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetails[11]" [options]="assessmentDetailChartOptions"
                          [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetails[11].answer }}</i></small>
                </mat-card>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="row pad10" *ngIf="
        scoreDetails.length > 0 && (!anchorTag || anchorTag === 'Tolerance')
      ">
      <div class="col-md-12">
        <mat-card class="mat-card-auto-height">
          <mat-card-header>
            <mat-card-title>Tolerance</mat-card-title>
          </mat-card-header>
          <mat-divider></mat-divider>
          <mat-card-content>
            <div class="row" style="padding: 15px">
              <div class="col-md-3">
                <mat-card class="mat-card-auto-height mat-elevation-z8">
                  <mat-card-header>
                    <h4 class="header text-center">
                      {{ scoreDetails[12].title }}
                    </h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetails[12]" [options]="assessmentDetailChartOptions"
                          [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetails[12].answer }}</i></small>
                </mat-card>
              </div>
              <div class="col-md-3">
                <mat-card class="mat-card-auto-height mat-elevation-z8">
                  <mat-card-header>
                    <h4 class="header text-center">
                      {{ scoreDetails[13].title }}
                    </h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetails[13]" [options]="assessmentDetailChartOptions"
                          [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetails[13].answer }}</i></small>
                </mat-card>
              </div>
              <div class="col-md-3">
                <mat-card class="mat-card-auto-height mat-elevation-z8">
                  <mat-card-header>
                    <h4 class="header text-center">
                      {{ scoreDetails[14].title }}
                    </h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetails[14]" [options]="assessmentDetailChartOptions"
                          [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetails[14].answer }}</i></small>
                </mat-card>
              </div>
              <div class="col-md-3">
                <mat-card class="mat-card-auto-height mat-elevation-z8">
                  <mat-card-header>
                    <h4 class="header text-center">
                      {{ scoreDetails[15].title }}
                    </h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetails[15]" [options]="assessmentDetailChartOptions"
                          [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetails[15].answer }}</i></small>
                </mat-card>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="row pad10" *ngIf="scoreDetails.length > 0 && (!anchorTag || anchorTag === 'Relapse')">
      <div class="col-md-12">
        <mat-card class="mat-card-auto-height">
          <mat-card-header>
            <mat-card-title>Relapse</mat-card-title>
          </mat-card-header>
          <mat-divider></mat-divider>
          <mat-card-content>
            <div class="row" style="padding: 15px">
              <div class="col-md-3">
                <mat-card class="mat-card-auto-height mat-elevation-z8">
                  <mat-card-header>
                    <h4 class="header text-center">
                      {{ scoreDetails[16].title }}
                    </h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetails[16]" [options]="assessmentDetailChartOptions"
                          [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetails[16].answer }}</i></small>
                </mat-card>
              </div>
              <div class="col-md-3">
                <mat-card class="mat-card-auto-height mat-elevation-z8">
                  <mat-card-header>
                    <h4 class="header text-center">
                      {{ scoreDetails[17].title }}
                    </h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetails[17]" [options]="assessmentDetailChartOptions"
                          [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetails[17].answer }}</i></small>
                </mat-card>
              </div>
              <div class="col-md-3">
                <mat-card class="mat-card-auto-height mat-elevation-z8">
                  <mat-card-header>
                    <h4 class="header text-center">
                      {{ scoreDetails[18].title }}
                    </h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetails[18]" [options]="assessmentDetailChartOptions"
                          [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetails[18].answer }}</i></small>
                </mat-card>
              </div>
              <div class="col-md-3">
                <mat-card class="mat-card-auto-height mat-elevation-z8">
                  <mat-card-header>
                    <h4 class="header text-center">
                      {{ scoreDetails[19].title }}
                    </h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetails[19]" [options]="assessmentDetailChartOptions"
                          [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetails[19].answer }}</i></small>
                </mat-card>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="row pad10" *ngIf="
        scoreDetails.length > 0 && (!anchorTag || anchorTag === 'Withdrawal')
      ">
      <div class="col-md-12">
        <mat-card class="mat-card-auto-height">
          <mat-card-header>
            <mat-card-title>Withdrawal</mat-card-title>
          </mat-card-header>
          <mat-divider></mat-divider>
          <mat-card-content>
            <div class="row" style="padding: 15px">
              <div class="col-md-3">
                <mat-card class="mat-card-auto-height mat-elevation-z8">
                  <mat-card-header>
                    <h4 class="header text-center">
                      {{ scoreDetails[20].title }}
                    </h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetails[20]" [options]="assessmentDetailChartOptions"
                          [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetails[20].answer }}</i></small>
                </mat-card>
              </div>
              <div class="col-md-3">
                <mat-card class="mat-card-auto-height mat-elevation-z8">
                  <mat-card-header>
                    <h4 class="header text-center">
                      {{ scoreDetails[21].title }}
                    </h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetails[21]" [options]="assessmentDetailChartOptions"
                          [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetails[21].answer }}</i></small>
                </mat-card>
              </div>
              <div class="col-md-3">
                <mat-card class="mat-card-auto-height mat-elevation-z8">
                  <mat-card-header>
                    <h4 class="header text-center">
                      {{ scoreDetails[22].title }}
                    </h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetails[22]" [options]="assessmentDetailChartOptions"
                          [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetails[22].answer }}</i></small>
                </mat-card>
              </div>
              <div class="col-md-3">
                <mat-card class="mat-card-auto-height mat-elevation-z8">
                  <mat-card-header>
                    <h4 class="header text-center">
                      {{ scoreDetails[23].title }}
                    </h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetails[23]" [options]="assessmentDetailChartOptions"
                          [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetails[23].answer }}</i></small>
                </mat-card>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="row pad10" *ngIf="
        scoreDetails.length > 0 && (!anchorTag || anchorTag === 'Problems')
      ">
      <div class="col-md-12">
        <mat-card class="mat-card-auto-height">
          <mat-card-header>
            <mat-card-title>Problems</mat-card-title>
          </mat-card-header>
          <mat-divider></mat-divider>
          <mat-card-content>
            <div class="row" style="padding: 15px">
              <div class="col-md-3">
                <mat-card class="mat-card-auto-height mat-elevation-z8">
                  <mat-card-header>
                    <h4 class="header text-center">
                      {{ scoreDetails[24].title }}
                    </h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetails[24]" [options]="assessmentDetailChartOptions"
                          [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetails[24].answer }}</i></small>
                </mat-card>
              </div>
              <div class="col-md-3">
                <mat-card class="mat-card-auto-height mat-elevation-z8">
                  <mat-card-header>
                    <h4 class="header text-center">
                      {{ scoreDetails[25].title }}
                    </h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetails[25]" [options]="assessmentDetailChartOptions"
                          [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetails[25].answer }}</i></small>
                </mat-card>
              </div>
              <div class="col-md-3">
                <mat-card class="mat-card-auto-height mat-elevation-z8">
                  <mat-card-header>
                    <h4 class="header text-center">
                      {{ scoreDetails[26].title }}
                    </h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetails[26]" [options]="assessmentDetailChartOptions"
                          [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetails[26].answer }}</i></small>
                </mat-card>
              </div>
              <div class="col-md-3">
                <mat-card class="mat-card-auto-height mat-elevation-z8">
                  <mat-card-header>
                    <h4 class="header text-center">
                      {{ scoreDetails[27].title }}
                    </h4>
                  </mat-card-header>
                  <mat-card-content>
                    <div style="width: 100%">
                      <div class="chart-wrapper">
                        <canvas baseChart [data]="scoreDetails[27]" [options]="assessmentDetailChartOptions"
                          [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </mat-card-content>
                  <small><b>Answer:</b> <i>{{ scoreDetails[27].answer }}</i></small>
                </mat-card>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>