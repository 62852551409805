export const Colors = [
  '#545454',
  '#ff8d6e',
  '#d6d6d6',
  '#4677cc',
  '#e0ddc8',
  '#067889',
  '#c74b76',
  '#fcde73',
  '#ffeb3c',
  '#ff9900',
  '#f44437',
  '#ea1e63',
  '#9c26b0',
  '#3f51b5',
  '#009788',
  '#4baf4f',
  '#7e5d4e',
  '#1dab2f',
  '#4981d6',
  '#d6d145',
  '#ff1717',
  '#f7961e',
  '#7056ff',
  '#e25dd2',
  '#34c8e0',
  '#0e9cd3',
];
