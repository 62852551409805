<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>CORE 10 Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="core10assessmentForm"
    novalidate
    (ngSubmit)="onCore10AssessmentFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >I have felt tense, anxious or nervous</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="tenseAnxiousNervous">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="Only occasionally"
                >Only occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time
              </mat-button-toggle>
            </mat-button-toggle-group>
            <mat-error
              *ngFor="let validation of validation_messages.tenseAnxiousNervous"
            >
              <mat-error
                class="error-message"
                *ngIf="
                  core10assessmentForm
                    .get('tenseAnxiousNervous')
                    .hasError(validation.type) &&
                  (core10assessmentForm.get('tenseAnxiousNervous').dirty ||
                    core10assessmentForm.get('tenseAnxiousNervous').touched)
                "
              >
                {{ validation.message }}</mat-error
              >
            </mat-error>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >I have felt I have someone to turn to for support when
            needed</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="someoneForSupport">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="Only occasionally"
                >Only occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >I have felt able to cope when things go wrong</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="ableToCope">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="Only occasionally"
                >Only occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >Talking to people has felt too much for me</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="talkingToPeople">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="Only occasionally"
                >Only occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >I have felt panic or terror</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="panicOrTerror">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="Only occasionally"
                >Only occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >I made plans to end my life</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="plannedToEndLife">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="Only occasionally"
                >Only occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >I have had difficulty getting to sleep or staying asleep</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group
              formControlName="difficultySleepingOrStaying"
            >
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="Only occasionally"
                >Only occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >I have felt despairing or hopeless</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="despairingOrHopeless">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="Only occasionally"
                >Only occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-3 asterix-after">I have felt unhappy</label>
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="feelUnhappy">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="Only occasionally"
                >Only occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-3 asterix-after"
            >Unwanted images or memories have been distressing me</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="unwantedImagesOrMemories">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="Only occasionally"
                >Only occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          core10assessmentForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!core10assessmentForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
