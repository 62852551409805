<div style="width: 100%; height: 100%; text-align: -webkit-center">
  <mat-card appearance="raised" style="border-radius: 20px">
    <mat-card-title style="text-align: center"
      >END USER LICENSE AGREEMENT</mat-card-title
    >
    <mat-card-content style="margin: 30px">
      <mat-spinner *ngIf="isLoading"></mat-spinner>
      <form [formGroup]="euladocumentForm" novalidate *ngIf="!isLoading">
        <mat-form-field appearance="fill">
          <textarea
            formControlName="message"
            readonly
            matInput
            rows="45"
          ></textarea>
        </mat-form-field>
        <mat-dialog-actions align="end" style="margin: 30px">
          <button
            color="warn"
            style="margin-left: 20px"
            mat-raised-button
            type="button"
            [disabled]="processing"
            (click)="disagreedToTerms()"
          >
            Disagree
          </button>
          <button
            color="primary"
            style="margin-left: 20px"
            mat-raised-button
            type="button"
            [disabled]="processing"
            (click)="agreedToTerms()"
          >
            Agree
          </button>
        </mat-dialog-actions>
      </form>
    </mat-card-content>
  </mat-card>
</div>
