<div class="main-content" style="background-color: #eee" *ngIf="!isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div class="container-fluid" *ngIf="!isLoadingResults">
        <br />
        <div class="row">
            <div class="col-md-6">
                <mat-card>
                    <mat-card-header>
                        <h4 class="header text-center">Overall Mean(average) Chart</h4>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="chart-wrapper">
                            <canvas baseChart height="100%" [data]="lineChartData" [options]="lineChartOptions"
                                [legend]="lineChartLegend" [type]="lineChartType" [plugins]="lineChartPlugins">
                            </canvas>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-md-6">
                <mat-card>
                    <mat-card-header>
                        <h4 class="header text-center">Scoring</h4>
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    <mat-card-content>
                        <div class="row">
                            <div class="col-md-3" style="font-weight: 400">Score</div>
                            <div class="col-md-7" style="padding-top: 4px; font-weight: 400">
                                Description
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3" style="padding-top: 4px; font-weight: 300">
                                1
                            </div>
                            <div class="col-md-7" style="padding-top: 4px; font-weight: 300">
                                Almost Always
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3" style="padding-top: 4px; font-weight: 300">
                                2
                            </div>
                            <div class="col-md-7" style="padding-top: 4px; font-weight: 300">
                                Very Frequently
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3" style="padding-top: 4px; font-weight: 300">
                                3
                            </div>
                            <div class="col-md-7" style="padding-top: 4px; font-weight: 300">
                                Somewhat Frequently
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3" style="padding-top: 4px; font-weight: 300">
                                4
                            </div>
                            <div class="col-md-7" style="padding-top: 4px; font-weight: 300">
                                Somewhat Infrequently
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3" style="padding-top: 4px; font-weight: 300">
                                5
                            </div>
                            <div class="col-md-7" style="padding-top: 4px; font-weight: 300">
                                Very Infrequently
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3" style="padding-top: 4px; font-weight: 300">
                                6
                            </div>
                            <div class="col-md-7" style="padding-top: 4px; font-weight: 300">
                                Almost Never
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <br />
        <div class="row">
            <ng-container *ngFor="let scoreDetail of scoreDetails">
                <div class="col-md-4" style="margin-bottom: 20px">
                    <mat-card>
                        <mat-card-header>
                            <h4 class="header text-center">{{ scoreDetail.title }}</h4>
                        </mat-card-header>
                        <mat-card-content>
                            <div style="width: 100%">
                                <div class="chart-wrapper">
                                    <canvas baseChart [data]="scoreDetail" [options]="assessmentDetailChartOptions"
                                        [legend]="lineChartLegend" [type]="lineChartType"
                                        [plugins]="detailChartPlugins">
                                    </canvas>
                                </div>
                            </div>
                        </mat-card-content>
                        <small><b>Answer:</b> <i>{{ scoreDetail.answer }}</i></small>
                    </mat-card>
                </div>
            </ng-container>
        </div>
    </div>
</div>