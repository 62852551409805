<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>DSM-5-TR Level 2 - Substance Use | Age 11-17</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="dsm5l2su11to17AssessmentForm"
    novalidate
    (ngSubmit)="onDsm5l2su11to17FormSubmit()"
    class="form-horizontal"
  >
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div style="font-size: 13px; font-weight: 450">
        During the past TWO (2) weeks, about how often did you ...
      </div>
      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >a. Have an alcoholic beverage (beer, wine, liquor, etc.)?
        </label>
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="alcoholicBeverage">
            <mat-button-toggle value="Not at all">Not at all</mat-button-toggle>
            <mat-button-toggle value="Less Than a Day or Two"
              >Less Than a Day or Two</mat-button-toggle
            >
            <mat-button-toggle value="Several Days"
              >Several Days</mat-button-toggle
            >
            <mat-button-toggle value="More Than Half the Days"
              >More Than Half the Days</mat-button-toggle
            >
            <mat-button-toggle value="Nearly Every Day"
              >Nearly Every Day</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >b. Have 4 or more drinks in a single day?</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="fourPlusDrinks">
            <mat-button-toggle value="Not at all">Not at all</mat-button-toggle>
            <mat-button-toggle value="Less Than a Day or Two"
              >Less Than a Day or Two</mat-button-toggle
            >
            <mat-button-toggle value="Several Days"
              >Several Days</mat-button-toggle
            >
            <mat-button-toggle value="More Than Half the Days"
              >More Than Half the Days</mat-button-toggle
            >
            <mat-button-toggle value="Nearly Every Day"
              >Nearly Every Day</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >c. Smoke a cigarette, a cigar, or pipe or use snuff or chewing
          tobacco?
        </label>
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="smokeOrTobaccoUse">
            <mat-button-toggle value="Not at all">Not at all</mat-button-toggle>
            <mat-button-toggle value="Less Than a Day or Two"
              >Less Than a Day or Two</mat-button-toggle
            >
            <mat-button-toggle value="Several Days"
              >Several Days</mat-button-toggle
            >
            <mat-button-toggle value="More Than Half the Days"
              >More Than Half the Days</mat-button-toggle
            >
            <mat-button-toggle value="Nearly Every Day"
              >Nearly Every Day</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>

    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div style="font-size: 13px; font-weight: 450">
        During the past TWO (2) weeks, about how often did you use any of the
        following medicines ON YOUR OWN, that is, without a doctor's
        prescription or in greater amounts or longer than prescribed?
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >d. Painkillers (like Vicodin)</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="painkillerUse">
            <mat-button-toggle value="Not at all">Not at all</mat-button-toggle>
            <mat-button-toggle value="Less Than a Day or Two"
              >Less Than a Day or Two</mat-button-toggle
            >
            <mat-button-toggle value="Several Days"
              >Several Days</mat-button-toggle
            >
            <mat-button-toggle value="More Than Half the Days"
              >More Than Half the Days</mat-button-toggle
            >
            <mat-button-toggle value="Nearly Every Day"
              >Nearly Every Day</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >e. Stimulants (like Ritalin, Adderall)</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="stimulantUse">
            <mat-button-toggle value="Not at all">Not at all</mat-button-toggle>
            <mat-button-toggle value="Less Than a Day or Two"
              >Less Than a Day or Two</mat-button-toggle
            >
            <mat-button-toggle value="Several Days"
              >Several Days</mat-button-toggle
            >
            <mat-button-toggle value="More Than Half the Days"
              >More Than Half the Days</mat-button-toggle
            >
            <mat-button-toggle value="Nearly Every Day"
              >Nearly Every Day</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >f. Sedatives or tranquilizers (like sleeping pills or Valium)
        </label>
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="sedativeUse">
            <mat-button-toggle value="Not at all">Not at all</mat-button-toggle>
            <mat-button-toggle value="Less Than a Day or Two"
              >Less Than a Day or Two</mat-button-toggle
            >
            <mat-button-toggle value="Several Days"
              >Several Days</mat-button-toggle
            >
            <mat-button-toggle value="More Than Half the Days"
              >More Than Half the Days</mat-button-toggle
            >
            <mat-button-toggle value="Nearly Every Day"
              >Nearly Every Day</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>

    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div style="font-size: 13px; font-weight: 450">Or drugs like:</div>
      <div class="row pad10">
        <label class="col-md-12 asterix-after">g. Steroids</label>
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="steroidUse">
            <mat-button-toggle value="Not at all">Not at all</mat-button-toggle>
            <mat-button-toggle value="Less Than a Day or Two"
              >Less Than a Day or Two</mat-button-toggle
            >
            <mat-button-toggle value="Several Days"
              >Several Days</mat-button-toggle
            >
            <mat-button-toggle value="More Than Half the Days"
              >More Than Half the Days</mat-button-toggle
            >
            <mat-button-toggle value="Nearly Every Day"
              >Nearly Every Day</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after">h. Other medicines</label>
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="otherMedUse">
            <mat-button-toggle value="Not at all">Not at all</mat-button-toggle>
            <mat-button-toggle value="Less Than a Day or Two"
              >Less Than a Day or Two</mat-button-toggle
            >
            <mat-button-toggle value="Several Days"
              >Several Days</mat-button-toggle
            >
            <mat-button-toggle value="More Than Half the Days"
              >More Than Half the Days</mat-button-toggle
            >
            <mat-button-toggle value="Nearly Every Day"
              >Nearly Every Day</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after">i. Marijuana</label>
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="marijuanaUse">
            <mat-button-toggle value="Not at all">Not at all</mat-button-toggle>
            <mat-button-toggle value="Less Than a Day or Two"
              >Less Than a Day or Two</mat-button-toggle
            >
            <mat-button-toggle value="Several Days"
              >Several Days</mat-button-toggle
            >
            <mat-button-toggle value="More Than Half the Days"
              >More Than Half the Days</mat-button-toggle
            >
            <mat-button-toggle value="Nearly Every Day"
              >Nearly Every Day</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after">j. Cocaine or crack</label>
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="cocaineUse">
            <mat-button-toggle value="Not at all">Not at all</mat-button-toggle>
            <mat-button-toggle value="Less Than a Day or Two"
              >Less Than a Day or Two</mat-button-toggle
            >
            <mat-button-toggle value="Several Days"
              >Several Days</mat-button-toggle
            >
            <mat-button-toggle value="More Than Half the Days"
              >More Than Half the Days</mat-button-toggle
            >
            <mat-button-toggle value="Nearly Every Day"
              >Nearly Every Day</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >k. Club drugs (like ecstasy)</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="clubDrugUse">
            <mat-button-toggle value="Not at all">Not at all</mat-button-toggle>
            <mat-button-toggle value="Less Than a Day or Two"
              >Less Than a Day or Two</mat-button-toggle
            >
            <mat-button-toggle value="Several Days"
              >Several Days</mat-button-toggle
            >
            <mat-button-toggle value="More Than Half the Days"
              >More Than Half the Days</mat-button-toggle
            >
            <mat-button-toggle value="Nearly Every Day"
              >Nearly Every Day</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >l. Hallucinogens (like LSD)</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="hallucinogenUse">
            <mat-button-toggle value="Not at all">Not at all</mat-button-toggle>
            <mat-button-toggle value="Less Than a Day or Two"
              >Less Than a Day or Two</mat-button-toggle
            >
            <mat-button-toggle value="Several Days"
              >Several Days</mat-button-toggle
            >
            <mat-button-toggle value="More Than Half the Days"
              >More Than Half the Days</mat-button-toggle
            >
            <mat-button-toggle value="Nearly Every Day"
              >Nearly Every Day</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after">m. Heroin</label>
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="heroinUse">
            <mat-button-toggle value="Not at all">Not at all</mat-button-toggle>
            <mat-button-toggle value="Less Than a Day or Two"
              >Less Than a Day or Two</mat-button-toggle
            >
            <mat-button-toggle value="Several Days"
              >Several Days</mat-button-toggle
            >
            <mat-button-toggle value="More Than Half the Days"
              >More Than Half the Days</mat-button-toggle
            >
            <mat-button-toggle value="Nearly Every Day"
              >Nearly Every Day</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >n. Inhalants or solvents (like glue)</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="inhalantUse">
            <mat-button-toggle value="Not at all">Not at all</mat-button-toggle>
            <mat-button-toggle value="Less Than a Day or Two"
              >Less Than a Day or Two</mat-button-toggle
            >
            <mat-button-toggle value="Several Days"
              >Several Days</mat-button-toggle
            >
            <mat-button-toggle value="More Than Half the Days"
              >More Than Half the Days</mat-button-toggle
            >
            <mat-button-toggle value="Nearly Every Day"
              >Nearly Every Day</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >o. Methamphetamine (like speed)
        </label>
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="methUse">
            <mat-button-toggle value="Not at all">Not at all</mat-button-toggle>
            <mat-button-toggle value="Less Than a Day or Two"
              >Less Than a Day or Two</mat-button-toggle
            >
            <mat-button-toggle value="Several Days"
              >Several Days</mat-button-toggle
            >
            <mat-button-toggle value="More Than Half the Days"
              >More Than Half the Days</mat-button-toggle
            >
            <mat-button-toggle value="Nearly Every Day"
              >Nearly Every Day</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>

    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          dsm5l2su11to17AssessmentForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!dsm5l2su11to17AssessmentForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
