<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Zung Self-Rating Scale</p>
  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-4"></div>
    <div class="col-sm-1"></div>
    <div class="col-sm-3">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-4"></div>
  </div>

  <form
    [formGroup]="zungselfratingscaleForm"
    novalidate
    (ngSubmit)="onZungSelfRatingScaleFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >1. I feel more nervous and anxious than usual.</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="feelNervousAndAnxious">
              <mat-button-toggle value="None or a little of the time"
                >None or a little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >2. I feel afraid for no reason at all.
          </label>
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="feelAfraid">
              <mat-button-toggle value="None or a little of the time"
                >None or a little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >3. I get upset easily or feel panicky.</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="getUpsetEasily">
              <mat-button-toggle value="None or a little of the time"
                >None or a little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >4. I feel like I'm falling apart and going to pieces.</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="feelLikeFallingApart">
              <mat-button-toggle value="None or a little of the time"
                >None or a little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >5. I feel that everything is all right and nothing bad will
            happen.</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="everythingIsOkay">
              <mat-button-toggle value="None or a little of the time"
                >None or a little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >6. My arms and legs shake and tremble.</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="armsAndLegsShake">
              <mat-button-toggle value="None or a little of the time"
                >None or a little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >7. I am bothered by headaches neck and back pain.</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group
              formControlName="botheredByHeadachesNeckBackPains"
            >
              <mat-button-toggle value="None or a little of the time"
                >None or a little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >8. I feel weak and get tired easily.</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="feelWeakAndTired">
              <mat-button-toggle value="None or a little of the time"
                >None or a little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >9. I feel calm and can sit still easily.</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="feelCalmAndCanSitStill">
              <mat-button-toggle value="None or a little of the time"
                >None or a little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >10. I can feel my heart beating fast.</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="feelHeartBeatingFast">
              <mat-button-toggle value="None or a little of the time"
                >None or a little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >11. I am bothered by dizzy spells.</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="botheredByDizzySpells">
              <mat-button-toggle value="None or a little of the time"
                >None or a little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >12. I have fainting spells or feel like it.</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="feelFaint">
              <mat-button-toggle value="None or a little of the time"
                >None or a little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >13. I can breathe in and out easily.</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="canBreatheEasily">
              <mat-button-toggle value="None or a little of the time"
                >None or a little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >14. I get numbness and tingling in my fingers and toes.</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="feelNumbnessAndTingling">
              <mat-button-toggle value="None or a little of the time"
                >None or a little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >15. I am bothered by stomach aches or indigestion.</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group
              formControlName="botheredByStomachAchesOrIngestion"
            >
              <mat-button-toggle value="None or a little of the time"
                >None or a little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >16. I have to empty my bladder often.</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="haveToEmptyBladderOften">
              <mat-button-toggle value="None or a little of the time"
                >None or a little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >17. My hands are usually dry and warm.</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="handsAreDryAndWarm">
              <mat-button-toggle value="None or a little of the time"
                >None or a little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >18. My face gets hot and blushes.</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="faceGetsHotAndBlushes">
              <mat-button-toggle value="None or a little of the time"
                >None or a little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >19. I fall asleep easily and get a good night's rest.</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="fallAsleepEasily">
              <mat-button-toggle value="None or a little of the time"
                >None or a little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after">20. I have nightmares.</label>
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="haveNightmares">
              <mat-button-toggle value="None or a little of the time"
                >None or a little of the time</mat-button-toggle
              >
              <mat-button-toggle value="Some of the time"
                >Some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Good part of the time"
                >Good part of the time</mat-button-toggle
              >
              <mat-button-toggle value="Most or all of the time"
                >Most or all of the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          zungselfratingscaleForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!zungselfratingscaleForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
