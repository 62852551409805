import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { DataTablesModel } from 'src/app/_models';
import {
  DateTimeZoneService,
  RemittancesAPIService,
  ToastMessageService,
} from 'src/app/_services';

@Component({
  selector: 'app-remittances-list',
  templateUrl: './remittances-list.component.html',
  styleUrl: './remittances-list.component.css',
})
export class RemittancesListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  searchStartDate: Date;
  searchEndDate: Date;
  payerName = '';
  payeeName: string = '';
  refNumber: string = '';
  searchStatus: string = '';
  searchStatusList = ['', 'New', 'Needs Review', 'Recorded'];

  processing: boolean = false;
  apiError: boolean = false;
  currentTimezone: string;
  currentTime = new Date();

  data: DataTablesModel = {} as DataTablesModel;
  displayedColumns = [
    'referenceNo',
    'payer',
    'payee',
    'paymentDetails',
    'status',
    'actions',
  ];

  constructor(
    public router: Router,
    private toastMessageService: ToastMessageService,
    private dateTimeZoneService: DateTimeZoneService,
    private remittancesApiService: RemittancesAPIService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit(): void {
    this.data.per_page = 10;
    this.data.page = 0;
    this.loadRemittances();
  }

  clearDate() {
    this.searchStartDate = null;
    this.searchEndDate = null;

    this.searchChanged();
  }

  searchChanged() {
    this.data.page = 0;
    this.loadRemittances();
  }

  optionChanged(option) {
    this.searchStatus = option;
    this.data.page = 0;
    this.loadRemittances();
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadRemittances();
  }

  loadRemittances() {
    this.processing = true;
    this.apiError = false;

    this.remittancesApiService
      .getAllRemittances(
        this.payerName,
        this.payeeName,
        this.refNumber,
        this.searchStatus,
        this.searchStartDate,
        this.searchEndDate,
        this.data.per_page,
        this.data.page
      )
      .subscribe({
        next: (response) => {
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }
          this.processing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get the remittances list.'
          );
          this.apiError = true;
          this.processing = false;
        },
      });
  }

  viewRemit(row) {
    this.router.navigate(['/remittances/remit/' + row.id]);
  }

  editRemit(row) {
    this.router.navigate(['/remittances/remit/recordremit', row.id]);
  }

  addNewRemit() {
    this.router.navigate(['/remittances/remit/recordremit']);
  }
}
