import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataTablesModel } from 'src/app/_models';
import { IAssessmentAlert } from 'src/app/_models/configure/assessment-alert.model';
import { environment } from 'src/environments/environment';


@Injectable({ providedIn: 'root' })
export class AssessmentAlertHttpService {
    constructor(private httpClient: HttpClient) { }

    getAssessmentAlerts(assessmentId: string, pageSize: number, pageIndex: number): Observable<DataTablesModel> {
        let params = new HttpParams();
        params = params.append('assessmentType', assessmentId);
        params = params.append('pageSize', pageSize.toString());
        params = params.append('pageIndex', pageIndex.toString());
        return this.httpClient.get<DataTablesModel>(`${environment.apiUrl}/assessment-alerts`, { params: params });
    }

    addAssessmentAlert(alert: IAssessmentAlert): Observable<IAssessmentAlert> {
        const headers = { 'content-type': 'application/json' };
        return this.httpClient
            .post<IAssessmentAlert>(`${environment.apiUrl}/assessment-alert`, alert, {
                headers,
            })
            .pipe(
                catchError((err) => {
                    console.error(err);
                    throw err;
                })
            );
    }

    updateAssessmentAlert(alert: IAssessmentAlert): Observable<IAssessmentAlert> {
        const headers = { 'content-type': 'application/json' };
        return this.httpClient
            .put<IAssessmentAlert>(`${environment.apiUrl}/assessment-alert`, alert, {
                headers,
            })
            .pipe(
                catchError((err) => {
                    console.error(err);
                    throw err;
                })
            );
    }

    deleteAssessmentAlert(alert: IAssessmentAlert): Observable<IAssessmentAlert> {
        return this.httpClient
            .delete<IAssessmentAlert>(
                `${environment.apiUrl}/assessment-alert/` + alert.id
            )
            .pipe(
                catchError((err) => {
                    console.error(err);
                    throw err;
                })
            );
    }
}