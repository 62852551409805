<mat-card appearance="raised" style="margin-top: 10px; margin-bottom: 30px">
  <div class="row" style="margin-bottom: 25px">
    <div class="col-md-6">
      <mat-card-title>
        <span
          style="color: #2196f3; cursor: pointer; font-weight: 500"
          (click)="deSelectReport()"
        >
          Reports
        </span>
        &nbsp; / &nbsp; Moods
      </mat-card-title>
    </div>
    <div
      class="col-md-6"
      *ngIf="hasMoodAddAccess && patient.status === 'Active'"
      style="text-align: right"
    >
      <button
        mat-raised-button
        color="warn"
        type="button"
        (click)="captureMood()"
      >
        Check-In
      </button>
    </div>
  </div>

  <mat-progress-bar
    mode="indeterminate"
    *ngIf="getMoodProcessing"
  ></mat-progress-bar>

  <div class="mat-elevation-z8">
    <mbsc-datepicker
      [(ngModel)]="selectedDate"
      [controls]="['calendar']"
      display="inline"
      [min]="minimumDate"
      max="currentDate"
      [calendarType]="calType"
      [calendarHeaderTemplate]="headerTemplate"
      (onPageChange)="onPageChange($event)"
      (onCellClick)="onCellClick($event)"
      [marked]="marked"
      [colors]="colors"
    >
      <ng-template #headerTemplate>
        <mbsc-calendar-prev></mbsc-calendar-prev>
        <mbsc-calendar-nav></mbsc-calendar-nav>
        <mbsc-calendar-next></mbsc-calendar-next>
      </ng-template>
    </mbsc-datepicker>
  </div>
  <!-- Legend -->
  <div
    style="
      margin-top: 15px;
      display: flex;
      column-gap: 30px;
      justify-content: center;
    "
  >
    <div *ngFor="let mood of moodColorIcon">
      <div style="text-align: center">
        <mat-icon [style.color]="mood.id" style="font-size: 16px">
          fiber_manual_record
        </mat-icon>
      </div>
      <div>
        <mat-icon [style.color]="mood.id">{{ mood.mood }}</mat-icon>
      </div>
    </div>
  </div>
</mat-card>
