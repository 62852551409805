<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Spence Children's Anxiety Scale (SCAS)</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="childrensAnxietyForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-6 asterix-after">1. I worry about things.</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q1">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >2. I am scared of the dark.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q2">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >3. When I have a problem, I get a funny feeling in my
            stomach.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q3">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after">4. I feel afraid.</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q4">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >5. I would feel afraid of being on my own at home.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q5">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >6. I feel scared when I have to take a test.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q6">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >7. I feel afraid if I have to use public toilets or
            bathrooms.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q7">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >8. I worry about being away from my parents.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q8">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >9. I feel afraid that I will make a fool of myself in front of
            people.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q9">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >10. I worry that I will do badly at my school work.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q10">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >11. I am popular amongst other kids my own age.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q11">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >12. I worry that something awful will happen to someone in my
            family.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q12">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >13. I suddenly feel as if I can't breathe when there is no reason
            for this.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q13">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >14. I have to keep checking that I have done things right (like the
            switch is off, or the door is locked).</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q14">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >15. I feel scared if I have to sleep on my own.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q15">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >16. I have trouble going to school in the mornings because I feel
            nervous or afraid.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q16">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after">17. I am good at sports.</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q17">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after">18. I am scared of dogs.</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q18">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >19. I can't seem to get bad or silly thoughts out of my
            head.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q19">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >20. When I have a problem, my heart beats really fast.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q20">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >21. I suddenly start to tremble or shake when there is no reason
            for this.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q21">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >22. I worry that something bad will happen to me.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q22">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >23. I am scared of going to the doctors or dentists.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q23">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >24. When I have a problem, I feel shaky.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q24">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >25. I am scared of being in high places or elevators
            (lifts).</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q25">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after">26. I am a good person.</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q26">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >27. I have to think of special thoughts to stop bad things from
            happening (like numbers or words).</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q27">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >28. I feel scared if I have to travel in the car, or on a bus or a
            train.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q28">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >29. I worry what other people think of me.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q29">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >30. I am afraid of being in crowded places (like shopping centers,
            the movies, buses, busy playgrounds).</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q30">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after">31. I feel happy.</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q31">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >32. All of a sudden I feel really scared for no reason at
            all.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q32">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >33. I am scared of insects or spiders.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q33">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >34. I suddenly become dizzy or faint when there is no reason for
            this.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q34">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >35. I feel afraid if I have to talk in front of my class.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q35">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >36. My heart suddenly starts to beat too quickly for no
            reason.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q36">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >37. I worry that I will suddenly get a scared feeling when there is
            nothing to be afraid of.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q37">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after">38. I like myself.</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q38">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >39. I am afraid of being in small closed places, like tunnels or
            small rooms.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q39">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >40. I have to do some things over and over again (like washing my
            hands, cleaning or putting things in a certain order).</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q40">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >41. I get bothered by bad or silly thoughts or pictures in my
            mind.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q41">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >42. I have to do some things in just the right way to stop bad
            things happening.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q42">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >43. I am proud of my school work.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q43">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >44. I would feel scared if I had to stay away from home
            overnight.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q44">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >45. Is there something else that you are really afraid of? How
            often are you afraid of this thing?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q45">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          childrensAnxietyForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!childrensAnxietyForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
