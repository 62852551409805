import { Component, Input, OnInit } from '@angular/core';
import { ITherapySession } from 'src/app/_models';

@Component({
  selector: 'app-session-print',
  templateUrl: './session-print.component.html',
  styleUrls: ['./session-print.component.css'],
})
export class SessionPrintComponent implements OnInit {
  @Input() therapySession: ITherapySession;

  sessionId: string;
  selectedOption = null;

  printList = [];
  constructor() {}

  ngOnInit(): void {
    this.sessionId = this.therapySession.id;

    this.determinePrintList();
  }

  determinePrintList() {
    // Phone notes list
    if (this.therapySession?.sessionType === 'Phone Note') {
      this.printList = [
        {
          title: 'Reports',
          forms: [
            {
              type: 'phoneNotes',
              name: 'Phone Notes',
            },
          ],
        },
      ];
    } else if (this.therapySession.groupAppointment) {
      // Regular Print list
      this.printList = [
        {
          title: 'Reports',
          forms: [
            {
              type: 'clinicalReports',
              name: 'Clinical Notes',
            },
          ],
        },
      ];
    } else {
      // Regular Print list
      this.printList = [
        {
          title: 'Clinical Reports',
          forms: [
            {
              type: 'clinicalReports',
              name: 'Clinical Notes',
            },
            {
              type: 'clinicalReports',
              name: 'Subjective & Objective',
            },
            {
              type: 'clinicalReports',
              name: 'Intake & Clinical Summary',
            },
          ],
        },
        {
          title: 'Intake Reports',
          forms: [
            {
              type: 'intakeReports',
              documentId: 'patient-demographic',
              name: 'Member Details',
            },
            {
              type: 'intakeReports',
              documentId: 'insurance-info',
              name: 'Member Insurance',
            },
            {
              type: 'intakeReports',
              documentId: 'health-history',
              name: 'Health Information',
            },
            // {
            //   type: 'intakeReports',
            //   documentId: 'health-history',
            //   name: 'Reasons For Clinical Guidance',
            // },
          ],
        },
        {
          title: 'Closing Reports',
          sessionStatus: 'Closed',
          forms: [
            {
              type: 'closingReports',
              name: 'Treatment Plan',
            },
          ],
        },
      ];
    }
  }
}
