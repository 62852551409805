<mat-card appearance="raised" class="insuranceCard" *ngIf="insurance">
  <!-- Header  -->
  <div style="padding-top: 8px">
    <!-- Insurance Payer -->
    <label
      class="labelHeader"
      style="text-align: center; width: 90%"
      [style.color]="
        insurance.insuranceType === 'Primary' ? '#029102' : '#f39121'
      "
    >
      {{ insurance.payerName }}
      <span *ngIf="insurance.claimPayerCode">
        ({{ insurance.claimPayerCode }})
      </span>
    </label>
  </div>
  <hr />
  <div class="gridContainer">
    <!-- Insurance Type -->
    <label class="labelText" style="font-weight: 500"> Insurance Type </label>
    <span
      [class]="
        insurance.insuranceType === 'Primary'
          ? 'primaryStatus'
          : insurance.insuranceType === 'Secondary'
          ? 'secondaryStatus'
          : ''
      "
    >
      {{ insurance.insuranceType }}
    </span>
    <!-- Insured Relationship -->
    <label class="labelText" style="font-weight: 500">
      Insured Relationship
    </label>
    <label class="labelText">
      {{
        insurance.relationship === '19'
          ? 'Child'
          : insurance.relationship === '21'
          ? 'Other'
          : insurance.relationship === '18'
          ? 'Self'
          : 'Spouse'
      }}
    </label>
    <!-- Subscriber Name -->
    <label class="labelText" style="font-weight: 500"> Subscriber Name </label>
    <label class="labelText">
      {{ insurance.subscriberLastName }}, {{ insurance.subscriberFirstName }}
    </label>
    <!-- Subscriber DOB -->
    <label class="labelText" style="font-weight: 500"> Subscriber DOB </label>
    <label class="labelText"> {{ insurance?.subscriberDOB }} </label>
    <!-- Member Name -->
    <label class="labelText" style="font-weight: 500"> Member Name </label>
    <label class="labelText"> {{ insurance.memberName }} </label>
    <!-- Member Id -->
    <label class="labelText" style="font-weight: 500"> Member Id </label>
    <label class="labelText" style="color: #2196f3">
      {{ insurance.memberId }}
    </label>
    <label class="labelText" style="font-weight: 500"> Member DOB </label>
    <label class="labelText">
      {{ insurance.dateOfBirth }}
    </label>
    <!-- Plan Id -->
    <label class="labelText" style="font-weight: 500" *ngIf="insurance?.planId">
      Plan Id
    </label>
    <label class="labelText" *ngIf="insurance?.planId">
      {{ insurance.planId }}
    </label>
    <!-- Group Id -->
    <label
      class="labelText"
      style="font-weight: 500"
      *ngIf="insurance?.groupId"
    >
      Group Id
    </label>
    <label class="labelText" *ngIf="insurance?.groupId">
      {{ insurance.groupId }}
    </label>
    <!-- Phone -->
    <label
      class="labelText"
      style="font-weight: 500"
      *ngIf="insurance?.payerPhone"
    >
      Payer Phone
    </label>
    <label class="labelText" *ngIf="insurance?.payerPhone">
      {{ insurance.payerPhone | phoneFormat }}
    </label>
    <!-- Fax -->
    <label
      class="labelText"
      style="font-weight: 500"
      *ngIf="insurance?.payerFax"
    >
      Payer Fax
    </label>
    <label class="labelText" *ngIf="insurance?.payerFax">
      {{ insurance.payerFax | phoneFormat }}
    </label>
    <!-- Employer/School -->
    <label
      class="labelText"
      style="font-weight: 500"
      *ngIf="insurance?.employerSchool"
    >
      Employer/School
    </label>
    <label class="labelText" *ngIf="insurance?.employerSchool">
      {{ insurance.employerSchool }}
    </label>
    <!-- Start Date -->
    <label
      class="labelText"
      style="font-weight: 500"
      *ngIf="insurance?.startDate"
    >
      Start Date
    </label>
    <label
      class="labelText"
      *ngIf="insurance?.startDate"
      style="font-weight: 500; color: #2196f3"
    >
      {{ insurance.startDate }}
    </label>
    <!-- End Date -->
    <label
      class="labelText"
      style="font-weight: 500"
      *ngIf="insurance?.endDate"
    >
      End Date
    </label>
    <label
      class="labelText"
      *ngIf="insurance?.endDate"
      style="font-weight: 500; color: #e82c2c"
    >
      {{ insurance.endDate }}
    </label>
    <label class="labelText" style="font-weight: 500" *ngIf="insurance?.copay">
      Copay
    </label>
    <label
      class="labelText"
      *ngIf="insurance?.copay"
      style="font-weight: 500; color: red"
    >
      {{ insurance.copay.toString() | currency : 'USD' }}
    </label>
    <label
      class="labelText"
      style="font-weight: 500"
      *ngIf="insurance?.deductible"
    >
      Deductible
    </label>
    <label
      class="labelText"
      *ngIf="insurance?.deductible"
      style="font-weight: 500; color: red"
    >
      {{ insurance.deductible.toString() | currency : 'USD' }}
    </label>

    <!-- Status -->
    <label class="labelText" style="font-weight: 500">
      Status <span style="color: red">*</span>
    </label>
    <label class="labelText" style="font-weight: 900">
      <span
        [style.color]="insurance.status === 'Active' ? '#029102' : '#e82c2c'"
        >{{ insurance.status }}</span
      >
    </label>
  </div>

  <mat-divider
    *ngIf="parentClass === 'Member'"
    style="margin-bottom: 10px"
  ></mat-divider>

  <mat-chip-listbox #chipList multiple="false">
    <mat-chip-option
      [class]="
        insurance.selecetedTab === 'Eligibility Checks'
          ? 'selectedChipClass mat-elevation-z8'
          : 'activeChipClass mat-elevation-z8'
      "
      (click)="insurance.selecetedTab = 'Eligibility Checks'"
    >
      Eligibility Checks
    </mat-chip-option>
    <mat-chip-option
      [class]="
        insurance.selecetedTab === 'Prior Authorizations'
          ? 'selectedChipClass mat-elevation-z8'
          : 'activeChipClass mat-elevation-z8'
      "
      (click)="insurance.selecetedTab = 'Prior Authorizations'"
    >
      Prior Authorizations ({{
        insurance?.priorAuths ? insurance?.priorAuths?.length : 0
      }})
    </mat-chip-option>
  </mat-chip-listbox>

  <div *ngIf="insurance.selecetedTab === 'Prior Authorizations'">
    <mat-card-title>
      Prior Authorizations ({{
        insurance?.priorAuths ? insurance?.priorAuths?.length : 0
      }})
    </mat-card-title>

    <div class="row" style="position: relative">
      <div
        class="col-md-12"
        *ngFor="let priorAuth of insurance.priorAuths"
        style="margin-bottom: 8px"
      >
        <div class="row">
          <div class="col">
            <label class="labelText" style="font-weight: 500">
              Auth # {{ priorAuth.authNumber }}
            </label>
          </div>
          <div class="col">
            <label class="labelText">
              {{ priorAuth.effectiveStartDate | date : 'MM/dd/y' }} -
              {{ priorAuth.effectiveEndDate | date : 'MM/dd/y' }}
            </label>
          </div>
          <div class="col">
            <label class="labelText" *ngIf="priorAuth.visitType === 'Limited'">
              <span
                style="font-weight: 600"
                [style.color]="
                  priorAuth.totalVisitNumber - priorAuth.usedVisits <= 5
                    ? 'red'
                    : ''
                "
              >
                {{ priorAuth.totalVisitNumber - priorAuth.usedVisits }}
              </span>
              out of <span>{{ priorAuth.totalVisitNumber }}</span> Visits
              Remaining
            </label>
            <label
              class="labelText"
              *ngIf="priorAuth.visitType === 'Unlimited'"
              style="font-style: italic"
            >
              Unlimited Visits
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="insurance.selecetedTab === 'Eligibility Checks'">
    <div class="row">
      <div class="col-md-12" style="padding: 20px 20px 0px 20px">
        <mat-card-title class="pull-left"> Eligibility Checks </mat-card-title>

        <button
          mat-raised-button
          class="pull-right"
          color="primary"
          type="button"
          (click)="runEligibility(insurance)"
        >
          Run Eligibiliy
        </button>
      </div>
    </div>

    <app-view-eligibility-reports
      *ngIf="insurance.selecetedTab === 'Eligibility Checks'"
      [insuranceId]="insurance.id"
      [reloadEligibilityReport]="reloadEligibilityReport.asObservable()"
    ></app-view-eligibility-reports>
  </div>
</mat-card>
