import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CareLog, Case, UrinalDrugScreen } from 'src/app/_models';
import { ToastMessageService } from 'src/app/_services';
import { CareLogHttpService } from 'src/app/_services/case/carelog.service';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import {
  buildValidationMsgs,
  formatDate,
  formatDateTime,
  mmddyyyyhhmiToDate,
} from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-carelog-urinal-drug-analysis',
  templateUrl: './carelog-urinal-drug-analysis.component.html',
  styleUrls: ['./carelog-urinal-drug-analysis.component.css'],
})
export class CarelogUrinalDrugAnalysisComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() action: string;
  @Input() patientId: string;
  @Input() case: Case;
  @Input() logDate: Date;
  @Input() shift: number;
  @Input() carelog: CareLog;
  @Input() goals: any[];
  @Input() goalDrivenCare: boolean;
  @Output() taskCompleted = new EventEmitter<any>();

  constructor(
    public careLogHttpService: CareLogHttpService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private toastMessageService: ToastMessageService
  ) {
    this.specimenValidityTests.forEach(test => {
      this.formControls[test.control] = new FormControl(
        '',
        Validators.compose([Validators.required])
      );
    });

    this.drugTests.forEach(drug => {
      if (drug.control === 'otherDesc') {
        this.formControls[drug.control] = new FormControl('');
      } else {
        this.formControls[drug.control] = new FormControl(
          '',
          Validators.compose([Validators.required])
        );
      }
    });

    this.formControls['activityDateTime'] = new FormControl(null);

    this.urinaldrugscreenForm = this.formBuilder.group(this.formControls);
  }

  public urinaldrugscreenForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  errors: any = {};
  isProcessing: boolean = false;
  urineDrugAnalysis: UrinalDrugScreen;
  processing: boolean = false;
  disableFormElements: boolean = false;
  todaysDate = new Date();

  displayedColumns: string[] = ['name', 'code', 'result'];

  specimenValidityTests = [
    { label: 'Oxidant', control: 'oxidant' },
    { label: 'Specific Gravity', control: 'specificGravity' },
    { label: 'Ph', control: 'ph' },
    { label: 'Nitrite', control: 'nitrite' },
    { label: 'Gl', control: 'gl' },
    { label: 'Creatinine', control: 'creatinine' }
  ];

  drugTests = [
    { name: 'Alcohol', code: 'ALC', control: 'alcohol' },
    { name: 'Amphetamines', code: 'AMP', control: 'amphetamine' },
    { name: 'Barbiturates', code: 'BAR', control: 'barbiturates' },
    { name: 'Buprenorphine', code: 'BUP', control: 'buprenorphine' },
    { name: 'Benzodiazepines', code: 'BZO', control: 'benzodiazepines' },
    { name: 'Cocaine', code: 'COC', control: 'cocaine' },
    { name: 'Cotinine', code: 'COT', control: 'cotinine' },
    { name: 'Methadone Metabolite', code: 'EDDP', control: 'methadonemetabolite' },
    { name: 'Ethyl Glucuronide', code: 'ETG', control: 'ethyl' },
    { name: 'Fentanyl', code: 'FTY', control: 'fentanyl' },
    { name: 'Heroin', code: 'HRN', control: 'heroin' },
    { name: 'Ketanine', code: 'KTY', control: 'ketanine' },
    { name: 'Methadone', code: 'MTD', control: 'methadone' },
    { name: 'Methamphetamine', code: 'mAMP/MET', control: 'methamphetamine' },
    { name: 'Ecstasy', code: 'MDMA', control: 'ecstasy' },
    { name: 'Morphine/Opiates 300', code: 'MOP/OPI300', control: 'morphine' },
    { name: 'Opiates', code: 'OPI', control: 'opiate' },
    { name: 'Oxycodone', code: 'OXY', control: 'oxycodone' },
    { name: 'Phencyclidine', code: 'PCP', control: 'phencyclidine' },
    { name: 'Propoxyphene', code: 'PPX', control: 'propoxyphene' },
    { name: 'Synthetic Cannabinoids', code: 'K2', control: 'syntheticcannabinoids' },
    { name: 'Marijuana', code: 'THC', control: 'marijuana' },
    { name: 'Tramadol', code: 'TRA', control: 'tramadol' },
    { name: 'Tricyclic Antidepressants', code: 'TCA', control: 'tricyclicAntidepressants' },
    { name: 'Other', code: '', control: 'other' },
    { name: 'Other Description', code: '', control: 'otherDesc', isRequired: false }
  ];
  formControls = {};


  ngOnInit(): void {
    this.urinaldrugscreenForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );

    if (this.carelog && this.carelog.urinalDrugScreen) {
      this.urinaldrugscreenForm.controls['alcohol'].setValue(
        this.carelog.urinalDrugScreen.alcohol
      );
      this.urinaldrugscreenForm.controls['oxidant'].setValue(
        this.carelog.urinalDrugScreen.oxidant
      );
      this.urinaldrugscreenForm.controls['specificGravity'].setValue(
        this.carelog.urinalDrugScreen.specificGravity
      );
      this.urinaldrugscreenForm.controls['ph'].setValue(
        this.carelog.urinalDrugScreen.ph
      );
      this.urinaldrugscreenForm.controls['nitrite'].setValue(
        this.carelog.urinalDrugScreen.nitrite
      );
      this.urinaldrugscreenForm.controls['gl'].setValue(
        this.carelog.urinalDrugScreen.gl
      );
      this.urinaldrugscreenForm.controls['creatinine'].setValue(
        this.carelog.urinalDrugScreen.creatinine
      );
      this.urinaldrugscreenForm.controls['amphetamine'].setValue(
        this.carelog.urinalDrugScreen.amphetamine
      );
      this.urinaldrugscreenForm.controls['barbiturates'].setValue(
        this.carelog.urinalDrugScreen.barbiturates
      );
      this.urinaldrugscreenForm.controls['benzodiazepines'].setValue(
        this.carelog.urinalDrugScreen.benzodiazepines
      );
      this.urinaldrugscreenForm.controls['buprenorphine'].setValue(
        this.carelog.urinalDrugScreen.buprenorphine
      );

      this.urinaldrugscreenForm.controls['cotinine'].setValue(
        this.carelog.urinalDrugScreen.cotinine
      );
      this.urinaldrugscreenForm.controls['cocaine'].setValue(
        this.carelog.urinalDrugScreen.cocaine
      );
      this.urinaldrugscreenForm.controls['methadonemetabolite'].setValue(
        this.carelog.urinalDrugScreen.methadonemetabolite
      );
      this.urinaldrugscreenForm.controls['ethyl'].setValue(
        this.carelog.urinalDrugScreen.ethyl
      );
      this.urinaldrugscreenForm.controls['fentanyl'].setValue(
        this.carelog.urinalDrugScreen.fentanyl
      );
      this.urinaldrugscreenForm.controls['heroin'].setValue(
        this.carelog.urinalDrugScreen.heroin
      );
      this.urinaldrugscreenForm.controls['ketanine'].setValue(
        this.carelog.urinalDrugScreen.ketanine
      );
      this.urinaldrugscreenForm.controls['methadone'].setValue(
        this.carelog.urinalDrugScreen.methadone
      );
      this.urinaldrugscreenForm.controls['methamphetamine'].setValue(
        this.carelog.urinalDrugScreen.methamphetamine
      );
      this.urinaldrugscreenForm.controls['ecstasy'].setValue(
        this.carelog.urinalDrugScreen.ecstasy
      );
      this.urinaldrugscreenForm.controls['morphine'].setValue(
        this.carelog.urinalDrugScreen.morphine
      );
      this.urinaldrugscreenForm.controls['opiate'].setValue(
        this.carelog.urinalDrugScreen.opiate
      );
      this.urinaldrugscreenForm.controls['oxycodone'].setValue(
        this.carelog.urinalDrugScreen.oxycodone
      );
      this.urinaldrugscreenForm.controls['phencyclidine'].setValue(
        this.carelog.urinalDrugScreen.phencyclidine
      );
      this.urinaldrugscreenForm.controls['propoxyphene'].setValue(
        this.carelog.urinalDrugScreen.propoxyphene
      );
      this.urinaldrugscreenForm.controls['syntheticcannabinoids'].setValue(
        this.carelog.urinalDrugScreen.syntheticcannabinoids
      );
      this.urinaldrugscreenForm.controls['marijuana'].setValue(
        this.carelog.urinalDrugScreen.marijuana
      );
      this.urinaldrugscreenForm.controls['tramadol'].setValue(
        this.carelog.urinalDrugScreen.tramadol
      );
      this.urinaldrugscreenForm.controls['tricyclicAntidepressants'].setValue(
        this.carelog.urinalDrugScreen.tricyclicAntidepressants
      );
      this.urinaldrugscreenForm.controls['other'].setValue(
        this.carelog.urinalDrugScreen.other
      );
      this.urinaldrugscreenForm.controls['otherDesc'].setValue(
        this.carelog.urinalDrugScreen.otherDesc
      );

      this.urinaldrugscreenForm.controls['activityDateTime'].setValue(
        mmddyyyyhhmiToDate(this.carelog.activityDateTime)
      );
    } else {
      this.urinaldrugscreenForm.controls['activityDateTime'].setValue(
        new Date(this.logDate)
      );
    }

    this.errorHandler.handleErrors(this.urinaldrugscreenForm, this.errors);
  }

  private buildForm() {
    this.urinaldrugscreenForm = this.formBuilder.group({
      activityDateTime: new FormControl(null),
      oxidant: new FormControl('', Validators.compose([Validators.required])),
      specificGravity: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      ph: new FormControl('', Validators.compose([Validators.required])),
      nitrite: new FormControl('', Validators.compose([Validators.required])),
      gl: new FormControl('', Validators.compose([Validators.required])),
      creatinine: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      amphetamine: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      barbiturates: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      benzodiazepines: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      buprenorphine: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      cotinine: new FormControl('', Validators.compose([Validators.required])),
      cocaine: new FormControl('', Validators.compose([Validators.required])),
      methadonemetabolite: new FormControl('', Validators.compose([Validators.required])),
      ethyl: new FormControl('', Validators.compose([Validators.required])),
      fentanyl: new FormControl('', Validators.compose([Validators.required])),
      heroin: new FormControl('', Validators.compose([Validators.required])),
      ketanine: new FormControl('', Validators.compose([Validators.required])),
      marijuana: new FormControl('', Validators.compose([Validators.required])),
      tramadol: new FormControl('', Validators.compose([Validators.required])),
      methadone: new FormControl('', Validators.compose([Validators.required])),
      methamphetamine: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      ecstasy: new FormControl('', Validators.compose([Validators.required])),
      morphine: new FormControl('', Validators.compose([Validators.required])),
      opiate: new FormControl('', Validators.compose([Validators.required])),
      oxycodone: new FormControl('', Validators.compose([Validators.required])),
      phencyclidine: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      propoxyphene: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      syntheticcannabinoids: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      tricyclicAntidepressants: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),

      alcohol: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
    });

  }

  public addCareLog(carelog: CareLog) {
    carelog.caseId = this.case.id;
    carelog.logDate = formatDate(this.logDate);
    carelog.id = null;
    carelog.shift = this.shift;
    carelog.patientId = this.case.patientId;

    this.careLogHttpService.addCareLog(this.case.id, carelog).subscribe(
      () => {
        this.toastMessageService.displaySuccessMessage(
          'New Urine Drug Screening Result has been successfully added.'
        );
        this.isProcessing = false;
        this.taskCompleted.emit(true);
      },
      (error) => {
        this.isProcessing = false;
        this.toastMessageService.displaySuccessMessage(
          'Error: Failed to add the urine drug screening.'
        );
      }
    );
  }

  onUrinalDrugScreenFormSubmit() {
    if (this.urinaldrugscreenForm.invalid) {
      this.urinaldrugscreenForm.markAllAsTouched();
      return;
    }

    this.urineDrugAnalysis = Object.assign({}, this.urineDrugAnalysis);
    this.urineDrugAnalysis = Object.assign(
      this.urineDrugAnalysis,
      this.urinaldrugscreenForm.value
    );

    this.isProcessing = true;

    if (this.carelog) {
      this.carelog.urinalDrugScreen = this.urineDrugAnalysis;
      this.carelog.activityDateTime = formatDateTime(
        this.urinaldrugscreenForm.controls['activityDateTime'].value
      );

      this.careLogHttpService.editCareLog(this.case.id, this.carelog).subscribe(
        () => {
          this.isProcessing = false;
          let outputResponse = { note: this.carelog, response: 'success' };
          this.taskCompleted.emit(outputResponse);
          this.toastMessageService.displaySuccessMessage(
            'Urine Anylsis log has been updated'
          );
        },
        (error) => {
          this.isProcessing = false;
          this.toastMessageService.displayErrorMessage(
            'Failed to update Urine Anylsis log'
          );
        }
      );
    } else {
      this.carelog = Object.assign({}, this.carelog);
      this.carelog.vitalInfoAvailable = false;
      this.carelog.patientId = this.case.patientId;
      this.carelog.message = 'Urine Drug Screening Record';
      this.carelog.urinalDrugScreen = this.urineDrugAnalysis;
      this.carelog.activityDateTime = formatDateTime(
        this.urinaldrugscreenForm.controls['activityDateTime'].value
      );
      this.carelog.vitalInfoAvailable = false;

      this.addCareLog(this.carelog);
    }
  }

  private onValueChanged() {
    if (!this.urinaldrugscreenForm) {
      return;
    }
    const form = this.urinaldrugscreenForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }

  public validation_messages = {
    oxidant: [{ type: 'required', message: 'Oxidant is required' }],
    specificGravity: [
      { type: 'required', message: 'Specific Gravity is required' },
    ],
    ph: [{ type: 'required', message: 'Ph is required' }],
    nitrite: [{ type: 'required', message: 'Nitrite is required' }],
    gl: [{ type: 'required', message: 'Gl is required' }],
    otherDesc: [{ type: 'required', message: 'Other Description is required' }],
    creatinine: [{ type: 'required', message: 'Creatinine is required' }],
  };

  handleOtherChange(event) {
    if (event.value === 'Not Tested') {
      this.urinaldrugscreenForm.controls['otherDesc'].setValue('');
      this.urinaldrugscreenForm.get('otherDesc').clearValidators();
      this.urinaldrugscreenForm.get('otherDesc').updateValueAndValidity();
    } else {
      this.urinaldrugscreenForm
        .get('otherDesc')
        .addValidators(Validators.required);
      this.urinaldrugscreenForm.get('otherDesc').updateValueAndValidity();
    }
  }
}
