import { IFacility } from '../../../_models';

export interface FacilityState {
  FacilityList: IFacility[];
  FacilityListError: Error;
  FacilityListSuccess: boolean;
  FacilityListLoading: boolean;

  FacilityResourcesList: any[];
  FacilityResourcesListError: Error;
  FacilityResourcesListSuccess: boolean;
  FacilityResourcesListLoading: boolean;
}

export const initialFacilityState: FacilityState = {
  FacilityList: undefined,
  FacilityListError: undefined,
  FacilityListSuccess: false,
  FacilityListLoading: false,

  FacilityResourcesList: undefined,
  FacilityResourcesListError: undefined,
  FacilityResourcesListSuccess: false,
  FacilityResourcesListLoading: false,
};
