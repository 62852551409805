import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import 'chartjs-plugin-annotation';
import moment from 'moment';
import { DataTablesModel } from 'src/app/_models';
import { SrsApiService } from 'src/app/_services/assessments/therapeutic-alliance/srs.service';
import { greenChart, hexToRGBA, orangeChart, redChart, yellowChart } from 'src/app/shared/utilities/utilities';
@Component({
  selector: 'app-srs-charts',
  templateUrl: './srs-charts.component.html',
  styleUrls: ['./srs-charts.component.css']
})
export class SrsChartsComponent implements OnInit {
  @Input() patientId: string;
  @Input() assessmentId: string;
  relationshipChartData: ChartConfiguration<'line'>['data'] = {
    datasets: [
      {
        tension: 0.4,
        data: [], borderColor: '#EEEEEE',
        pointBackgroundColor: '#333333',
        pointBorderColor: '#FFFFFF',
        pointHoverBackgroundColor: '#FFFFFF',
        pointHoverBorderColor: '#333333'
      },
    ]
  };
  goalsChartData: ChartConfiguration<'line'>['data'] = {
    datasets: [
      {
        tension: 0.4,
        data: [], borderColor: '#EEEEEE',
        pointBackgroundColor: '#333333',
        pointBorderColor: '#FFFFFF',
        pointHoverBackgroundColor: '#FFFFFF',
        pointHoverBorderColor: '#333333'
      },
    ]
  };
  approachChartData: ChartConfiguration<'line'>['data'] = {
    datasets: [
      {
        tension: 0.4,
        data: [], borderColor: '#EEEEEE',
        pointBackgroundColor: '#333333',
        pointBorderColor: '#FFFFFF',
        pointHoverBackgroundColor: '#FFFFFF',
        pointHoverBorderColor: '#333333'
      },
    ]
  };
  overallChartData: ChartConfiguration<'line'>['data'] = {
    datasets: [
      {
        tension: 0.4,
        data: [], borderColor: '#EEEEEE',
        pointBackgroundColor: '#333333',
        pointBorderColor: '#FFFFFF',
        pointHoverBackgroundColor: '#FFFFFF',
        pointHoverBorderColor: '#333333'
      },
    ]
  };


  chartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        title: {
          display: false
        }
      },
      annotation: {
        annotations: {
          redBg: {
            type: 'box',
            yMin: 0,
            yMax: 5.5,
            backgroundColor: hexToRGBA(redChart.backgroundColor, 1),
            borderColor: redChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          },
          greenBg:
          {
            type: 'box',
            yMin: 5.5,
            yMax: 10,
            backgroundColor: hexToRGBA(greenChart.backgroundColor, 1),
            borderColor: greenChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          },
        }
      },
    },
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        },
        min: 0,
        max: 10,
      }
    }
  };
  lineChartLegend = false;
  lineChartPlugins = [{
    // afterDraw: function (chart, args, options) {
    //   if (chart.chart.options.backgroundRules) {
    //     var lineAt = chart.config.options.lineAt;
    //     var ctxPlugin = chart.chart.ctx;
    //     var xAxe = chart.scales[chart.config.options.scales.x[0].id];
    //     var yAxe = chart.scales[chart.config.options.scales.y[0].id];
    //     ctxPlugin.strokeStyle = "red";
    //     ctxPlugin.beginPath();
    //     lineAt = yAxe.getPixelForValue(lineAt);
    //     ctxPlugin.moveTo(xAxe.left, lineAt);
    //     ctxPlugin.lineTo(xAxe.right, lineAt);
    //     ctxPlugin.stroke();
    //   }
    // }
  }];
  lineChartType = 'line';
  detailChartPlugins = [];

  data: DataTablesModel = {} as DataTablesModel;
  processing$ = false;
  isLoadingResults = true;
  error: string = '';
  scoreMap = new Map();
  scoreDetails: any[] = [];
  constructor(public apiService: SrsApiService,
    private route: ActivatedRoute) {
  }
  ngOnInit() {
    this.isLoadingResults = true;
    this.data.page = 0;
    this.data.per_page = 10;
    this.loadSrsAssessments();
  }
  public loadSrsAssessments() {
    this.isLoadingResults = true;
    this.error = '';
    this.apiService.getSrsList(this.patientId, this.assessmentId, this.data.per_page, this.data.page).subscribe(response => {
      if (response && response.items) {
        this.data.items = response.items;
        this.data.total = response.total;
        var relationshipRiskScores = [];
        var goalsRiskScores = [];
        var approachRiskScores = [];
        var overallRiskScores = [];
        var labels = [];
        this.scoreDetails = [];

        for (var item of response.items) {
          let i = 1;
          if (response.items.length === 1) {
            labels.push("");
            if (item.props) {
              relationshipRiskScores.push(item.props['relationshipRating']);
              goalsRiskScores.push(item.props['goalsAndTopicsRating']);
              approachRiskScores.push(item.props['approachRating']);
              overallRiskScores.push(item.props['overallRating']);
            } else {
              relationshipRiskScores.push(0);
              goalsRiskScores.push(0);
              approachRiskScores.push(0);
              overallRiskScores.push(0);
            }
          }
          if (item.props) {
            relationshipRiskScores.push(item.props['relationshipRating']);
            goalsRiskScores.push(item.props['goalsAndTopicsRating']);
            approachRiskScores.push(item.props['approachRating']);
            overallRiskScores.push(item.props['overallRating']);
          } else {
            relationshipRiskScores.push(0);
            goalsRiskScores.push(0);
            approachRiskScores.push(0);
            overallRiskScores.push(0);
          }

          var stillUtc = moment.utc(item.createdDate).toDate();
          var assessmentDate = moment(stillUtc).local().format('MM/DD/YY');
          labels.push(assessmentDate);
          for (var key in item) {
            if (item.hasOwnProperty(key)) {
              if (item[key] && item[key].hasOwnProperty("score")) {
                var scoreObj = item[key];
                var scores = [];
                if (this.scoreMap.get(key)) {
                  scores = this.scoreMap.get(key).scores;
                }
                if (response.items.length === 1) {
                  scores.push(scoreObj["score"]);
                }
                scores.push(scoreObj["score"]);
                var chartType = this.chartType(i);
                var returnObj = { "scores": scores, "key": key, "title": scoreObj["title"], "answer": scoreObj["answer"], "categoryName": chartType, "chartData": [], "chartColor": this.getChartColor(chartType, scores) };
                this.scoreMap.set(key, returnObj);
                i++;
              }
            }
          }
        }
        this.scoreMap.forEach((value, key) => {
          let dataset: any = {
            datasets: [
              {
                "data": value.scores,
                "borderColor": value.chartColor.borderColor,
                "backgroundColor": value.chartColor.backgroundColor,
                "tension": 0.4,
                "fill": true,
                pointBackgroundColor: '#333333',
                pointRadius: 5
              }
            ],
            "labels": labels,
            "title": value.title,
            "answer": value.answer
          };
          this.scoreDetails.push(dataset);
        });
        this.relationshipChartData.datasets[0].data = relationshipRiskScores;
        this.goalsChartData.datasets[0].data = goalsRiskScores;
        this.approachChartData.datasets[0].data = approachRiskScores;
        this.overallChartData.datasets[0].data = overallRiskScores;
        this.relationshipChartData.labels = labels;
        this.goalsChartData.labels = labels;
        this.approachChartData.labels = labels;
        this.overallChartData.labels = labels;
        this.isLoadingResults = false;
      }
      if (response == null) {
        this.data.items = [];
        this.data.total = 0;
      }
    },
      error => {
        this.error = error;
        this.isLoadingResults = false;
      })
  }
  getChartColor(chartType: string, scoringArray: any[]) {
    var score = scoringArray[scoringArray.length - 1];
    if (chartType === 'Conflicts') {
      if (score > 4) {
        return redChart;
      } else if (score > 3) {
        return orangeChart;
      } else if (score > 2) {
        return yellowChart;
      } else {
        return greenChart;
      }
    } else {
      if (score <= 2) {
        return redChart;
      } else if (score === 3) {
        return yellowChart;
      } else if (score === 4) {
        return orangeChart;
      } else {
        return greenChart;
      }
    }
  }
  chartType(questionNumber: number) {
    var categoryName = "";
    switch (questionNumber) {
      case 1:
      case 7:
      case 9:
      case 13:
      case 15:
      case 17:
        categoryName = 'Close';
        break;
      case 3:
      case 6:
      case 8:
      case 14:
      case 16:
      case 18:
        categoryName = 'Depend';
        break;
      case 2:
      case 4:
      case 5:
      case 10:
      case 11:
      case 12:
        categoryName = 'Anxiety';
        break;
      default:
        categoryName = 'n/a';
        break;
    }
    return categoryName;
  }
}