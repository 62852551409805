<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle *ngIf="role === 'Physician'">Goal Notes by clinician</p>
    <p matDialogTitle *ngIf="role === 'Patient'">Goal Notes by Member</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div style="margin-top: 20px">
    <!--Goal Notes -->
    <div *ngIf="!viewOnlyNotes?.length">
      <mat-error>No note found</mat-error>
    </div>
    <div
      class="notesContainer"
      *ngIf="viewOnlyNotes?.length > 0"
      style="margin-top: 10px"
    >
      <mat-card
        appearance="outlined"
        class="mat-elevation-z8"
        *ngFor="let note of viewOnlyNotes; let i = index"
        style="margin: 10px; position: relative; border-radius: 15px"
      >
        <div class="noteCardDetails">
          <label class="labelText">{{ note.notes }}</label>
        </div>
        <div class="createdByFooter">
          <i class="material-icons" style="vertical-align: bottom; color: gray"
            >edit_calendar</i
          >&nbsp;
          <label style="margin: 0; margin-top: 5px; font-size: 12px">
            {{ note.createdDate | date : 'MM/dd/y' }}
          </label>
        </div>
      </mat-card>
    </div>
  </div>
</div>
