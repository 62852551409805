<div class="main-content" style="background-color: #eee" *ngIf="!isLoadingResults">
  <div class="container-fluid">
    <div class="container-fluid">
      <br />
      <div class="row">
        <div class="col-md-6">
          <mat-card appearance="raised">
            <mat-card-header>
              <h4 class="header text-center">Overall Graph</h4>
            </mat-card-header>
            <mat-card-content>
              <div style="width: 100%">
                <div class="chart-wrapper">
                  <canvas baseChart [data]="lineChartData" [options]="lineChartOptions" [legend]="lineChartLegend"
                    [type]="mainChartType">
                  </canvas>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-md-6">
          <mat-card appearance="raised">
            <mat-card-header>
              <h4 class="header text-center">Interpreting the Overall Score</h4>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
              <div class="row">
                <div class="col-md-12" style="font-weight: 400">
                  The Aggression scale consists of 4 factors ranked from 0 to 1,
                  with 1 being the maximum.
                  <br /><br />
                  While there is no numerical cut-off, higher scores indicate
                  increasing severity.
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <br />
      <div class="row">
        <ng-container *ngFor="let scoreDetail of scoreDetails">
          <div class="col-md-4" style="margin-bottom: 20px">
            <mat-card appearance="raised">
              <mat-card-header>
                <h4 class="header text-center">{{ scoreDetail.title }}</h4>
              </mat-card-header>
              <mat-card-content>
                <div style="width: 100%">
                  <div class="chart-wrapper">
                    <canvas baseChart [data]="scoreDetail" [options]="scoreDetail.chartOptions" [legend]="true"
                      [type]="'bar'" height="200">
                    </canvas>
                  </div>
                </div>
              </mat-card-content>
              <small><b>Answer:</b> <i>{{ scoreDetail.answer }}</i></small>
            </mat-card>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>