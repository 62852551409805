<!-- Prediction Component -->
<mat-card
  appearance="outlined"
  class="mat-elevation-z8"
  style="
    border-radius: 15px;
    background: linear-gradient(to left, #c0e1fb, #ffffff);
  "
>
  <!-- Header Component -->
  <div class="row" style="align-items: center">
    <div class="col-4">
      <img
        src="/assets/img/robot-bot.png"
        style="height: 100px; width: 100px; object-fit: contain"
      />
    </div>
    <div class="col-8">
      <span style="font-weight: 500; font-size: 18px">
        Projected Behavior Insights
      </span>
    </div>
  </div>

  <br />

  <!-- Contents -->
  <div class="row">
    <!-- This is the loader when we are processing the data -->
    <div class="col-md-12" style="min-height: 250px" *ngIf="processing">
      <ngx-spinner
        bdColor="transparent"
        size="medium"
        color="#2196f3"
        type="ball-grid-beat"
        [fullScreen]="false"
      ></ngx-spinner>
    </div>
    <!-- This is the actual data from response -->
    <div class="col-md-12" *ngIf="!processing && !notEnoughData">
      <mat-card
        appearance="outlined"
        [style.border-left]="
          getTodaysBorderColor(actualPrediction.today.severity)
        "
        style="background: transparent"
        class="mat-elevation-z8"
      >
        <div>
          <span class="labelHeader">Today's Projection : </span>
          <span class="labelText">{{ currentDate | date : 'mediumDate' }}</span>
        </div>
        <div>
          <span class="labelText">
            {{ actualPrediction.today.prediction }}
          </span>
        </div>
      </mat-card>
      <br />
      <mat-card
        appearance="outlined"
        [style.border-left]="
          getTodaysBorderColor(actualPrediction.tomorrow.severity)
        "
        style="background: transparent"
        class="mat-elevation-z8"
      >
        <div>
          <span class="labelHeader">Tomorrow's Projection : </span>
          <span class="labelText">{{ tomorrow | date : 'mediumDate' }}</span>
        </div>
        <div>
          <span class="labelText">
            {{ actualPrediction.tomorrow.prediction }}
          </span>
        </div>
      </mat-card>
    </div>
    <!-- This is there are not enough data to predict -->
    <div class="col-md-12" *ngIf="!processing && notEnoughData">
      <mat-card
        appearance="outlined"
        [style.border-left]="'10px solid #2196f3'"
        style="background: transparent"
        class="mat-elevation-z8"
      >
        <div>
          <span class="labelHeader">Oops! </span>
        </div>
        <div>
          <span class="labelText">
            There is not enough data at this moment to be able predict the
            member's future behavior incidents. Please check back when more data
            is available.
          </span>
        </div>
      </mat-card>
    </div>
  </div>

  <!-- Footer & Disclaimer -->
  <div class="row" style="margin-top: 25px">
    <div
      *ngIf="!processing && !notEnoughData && actualPrediction?.requestedOn"
      class="col-md-12"
      style="
        align-items: center;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 15px;
      "
    >
      <label style="font-size: 10px; color: #333">
        Last checked on:
        {{
          actualPrediction.requestedOn
            | date : 'M/d/y, h:mm a' : currentTimezone
        }}
        {{ currentTimezone }}</label
      >
      <mat-icon
        style="color: green; cursor: pointer; font-size: 20px"
        (click)="refreshPrediction()"
      >
        replay
      </mat-icon>
    </div>
    <div class="col-md-12">
      <label style="font-style: italic; font-size: 12px">
        Please note that behavior prediction is not always straightforward, and
        there can be other factors at play. This prediction is based on the
        available data and the observed pattern. Actual behavior can still vary
        based on various circumstances and individual differences.
      </label>
    </div>
  </div>
</mat-card>
