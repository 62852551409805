<div
  class="dialogTitle"
  *ngIf="displayTitle"
  style="padding-left: 10px; padding-right: 10px"
>
  <p matDialogTitle>{{ assessmentType }}</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<app-internet-addiction
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Internet Addiction Assessment'"
>
</app-internet-addiction>
<app-alcohol-addiction
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Alcohol Addiction Assessment'"
></app-alcohol-addiction>
<app-cssrs
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Columbia-Suicide Severity Rating Scale (CSSRS)'"
></app-cssrs>
<app-cows
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Clinical Opiate Withdrawal Scale (COWS)'"
></app-cows>
<app-ciwa
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="
    assessmentType ===
    'Clinical Institute Withdrawal Assessment of Alcohol Scale, Revised (CIWA-AR)'
  "
></app-ciwa>

<app-gambling-addiction
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Gambling Addiction Assessment'"
>
</app-gambling-addiction>
<app-gaming-addiction
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Gaming Addiction Assessment'"
>
</app-gaming-addiction>
<app-sexual-addiction
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Sexual Addiction Assessment'"
>
</app-sexual-addiction>
<app-shopping-addiction
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Shopping Addiction Assessment'"
>
</app-shopping-addiction>
<app-dsm5-level2-substance-use
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'DSM-5-TR Level 2 - Substance Use | Age 11-17'"
>
</app-dsm5-level2-substance-use>
<app-taps-assessment
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'TAPS-I & TAPS-II'"
>
</app-taps-assessment>

<!-- ADHD Assessments -->
<app-adhd-diagnosis-rating
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Vanderbilt ADHD Diagnostic Rating Scale (VADRS)'"
></app-adhd-diagnosis-rating>
<app-self-report-scale
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Adult ADHD Self-Report Scale'"
></app-self-report-scale>
<app-attention-awareness-scale
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'The Mindful Attention Awareness Scale (MAAS)'"
>
</app-attention-awareness-scale>

<!-- Aggression Assessments -->
<app-aggression-questionnaire
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Buss Perry Aggression Questionnaire'"
></app-aggression-questionnaire>

<!-- Anxiety Assessments -->
<app-childrens-anxiety
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Spence Children\'s Anxiety Scale'"
></app-childrens-anxiety>
<app-generalized-anxiety
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Generalized Anxiety Disorder 7 Item Scale'"
></app-generalized-anxiety>
<app-hamilton-anxiety
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Hamilton Anxiety Rating Scale'"
></app-hamilton-anxiety>
<app-panic-agoraphobia
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Panic and Agoraphobia Scale'"
></app-panic-agoraphobia>
<app-penn-state-worry
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Penn State Worry Questionnaire'"
></app-penn-state-worry>
<app-short-health-anxiety
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Short Health Anxiety Inventory'"
></app-short-health-anxiety>
<app-social-phobia
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Social Phobia Inventory'"
></app-social-phobia>
<app-taylor-manifest
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Taylor Manifest Anxiety Scale'"
></app-taylor-manifest>
<app-zung-self-rating-scale-dialog
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Zung Self-Rating Scale'"
>
</app-zung-self-rating-scale-dialog>

<!-- Autism Assessments -->
<app-autism-spectrum-quotient
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Autism Spectrum Quotient'"
></app-autism-spectrum-quotient>
<app-autism-spectrum-screening
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Autism Spectrum Screening Questionnaire'"
></app-autism-spectrum-screening>
<app-childhood-autism-spectrum
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Childhood Autism Spectrum Test'"
></app-childhood-autism-spectrum>
<app-infant-toddler
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Infant-Toddler Checklist'"
></app-infant-toddler>
<app-quantitative-checklist
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Quantitative Checklist for Autism in Toddlers'"
></app-quantitative-checklist>
<app-ritvo-asperger
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Ritvo Autism & Asperger Diagnostic Scale'"
></app-ritvo-asperger>

<!-- Bipolar Assessments -->
<app-altman-mania
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Altman Self-Rating Mania Scale'"
></app-altman-mania>
<app-young-mania
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Young Mania Rating Scale'"
></app-young-mania>

<!-- Depression Assessments -->
<app-patient-health-questionnaire-dialog
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Patient Health Questionnaire'"
>
</app-patient-health-questionnaire-dialog>
<app-geriatric-depression-scale-dialog
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Geriatric Depression Scale (GDS)'"
>
</app-geriatric-depression-scale-dialog>
<app-adolescent-depression
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Kutcher Adolescent Depression Scale'"
></app-adolescent-depression>
<app-major-depression
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Major Depression Inventory'"
></app-major-depression>
<app-postnatal-depression
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Edinburgh Postnatal Depression Scale (EPDS)'"
></app-postnatal-depression>
<app-zung-depression-scale
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Zung Self-Rating Depression Scale'"
></app-zung-depression-scale>

<!-- Eating-Disorder Assessments -->
<app-binge-eating-scale
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Binge Eating Scale'"
></app-binge-eating-scale>
<app-eating-attitudes
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Eating Attitudes Test'"
></app-eating-attitudes>

<!-- General Assessments -->
<app-cross-cutting-level-one-adult
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'DSM 5 Level 1 Adult Assessment'"
>
</app-cross-cutting-level-one-adult>
<app-cross-cutting-level-one-eleven-to-seventeen
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'DSM 5 Level 1 (11-17) Assessment'"
>
</app-cross-cutting-level-one-eleven-to-seventeen>
<app-cross-cutting-level-one-six-to-seventeen
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'DSM 5 Level 1 (6-17) Assessment'"
>
</app-cross-cutting-level-one-six-to-seventeen>
<app-core10-assessment-dialog
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'CORE 10 Assessment'"
>
</app-core10-assessment-dialog>
<app-ypcore10-assessment-dialog
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'YP-CORE 10 Assessment'"
>
</app-ypcore10-assessment-dialog>
<app-cdrisc-assessment-dialog
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Connor-Davidson Resilience Scale(CD-RISC 10)'"
>
</app-cdrisc-assessment-dialog>
<app-survey-wellbeing-of-young-children
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Survey of Wellbeing of Young Children'"
>
</app-survey-wellbeing-of-young-children>
<app-differention-of-self-inventory
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Differentiation of Self Inventory - Short Form'"
>
</app-differention-of-self-inventory>

<!-- OCD Assessments -->
<app-obsessive-compulsive-inventory
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Obsessive Compulsive Inventory - Revised'"
></app-obsessive-compulsive-inventory>

<!-- Personality Assessments -->
<app-big-five
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Big 5 Personality Test'"
></app-big-five>
<app-empathy-quotient
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Empathy Quotient'"
></app-empathy-quotient>
<app-hare-psychopathy
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Hare Psychopathy Checklist'"
></app-hare-psychopathy>
<app-mystical-experience
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Revised Mystical Experience Questionnaire'"
></app-mystical-experience>
<app-personality-type
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Personality Type Indicator'"
></app-personality-type>
<app-toronto-empathy-dialog
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Toronto Empathy Questionnaire'"
>
</app-toronto-empathy-dialog>

<!-- Realtionship/Family Assessments -->
<app-aas-assessment-dialog
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Adult Attachment Scale (AAS)'"
></app-aas-assessment-dialog>
<app-aces-assessment-dialog
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Aversive Childhood Experiences Scale (ACES)'"
></app-aces-assessment-dialog>
<app-cprs-assessment-dialog
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Child-Parent Relationship Scale (CPRS)'"
></app-cprs-assessment-dialog>
<app-apgar-assessment-dialog
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Family APGAR'"
></app-apgar-assessment-dialog>
<app-csi-assessment-dialog
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'The Couples Satisfaction Index (CSI)'"
>
</app-csi-assessment-dialog>

<!-- Stress Assessments -->
<app-complicated-grief
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Inventory of Complicated Grief'"
></app-complicated-grief>
<app-ptsd-screening
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Primary Care PTSD Screen'"
></app-ptsd-screening>
<app-trauma-related-shame
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Trauma-Related Shame Assessment'"
></app-trauma-related-shame>

<!-- Therapeutic Alliance Assessments -->
<app-srs-assessment-dialog
  [displayTitle]="false"
  [isPatient]="isPatient"
  [assessmentRequestId]="assessmentRequestId"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  (taskCompleted)="taskCompleted($event)"
  *ngIf="assessmentType === 'Session Rating Scale (SRS)'"
></app-srs-assessment-dialog>
