<mat-card-title>Employment/Support Status</mat-card-title>
<mat-divider></mat-divider>

<div style="margin-top: 40px; padding: 10px">
  <form [formGroup]="asiemploymentsupportForm" novalidate (ngSubmit)="onAsiEmploymentSupportFormSubmit()">
    <div class="row">
      <!-- Question Box -->
      <div class="col-sm-12 col-xl-8">
        <mat-card appearance="raised" class="mat-elevation-z8" style="margin-bottom: 20px">
          <div class="col-md-12">
            <label class="labelText">E1. Education completed <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="e1" name="e1" required />
              <mat-error *ngFor="let validation of validation_messages.e1">
                <mat-error class="error-message" *ngIf="
                    asiemploymentsupportForm
                      .get('e1')
                      .hasError(validation.type) &&
                    (asiemploymentsupportForm.get('e1').dirty ||
                      asiemploymentsupportForm.get('e1').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <label class="labelText">E2. Training or technical education completed
              <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="e2" name="e2" required />
              <mat-error *ngFor="let validation of validation_messages.e2">
                <mat-error class="error-message" *ngIf="
                    asiemploymentsupportForm
                      .get('e2')
                      .hasError(validation.type) &&
                    (asiemploymentsupportForm.get('e2').dirty ||
                      asiemploymentsupportForm.get('e2').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12 pad10">
            <label class="labelText">E3. Do you have a profession, trade, or skill?
              <span style="color: red">*</span></label>
            <div class="col-sm-6">
              <mat-button-toggle-group formControlName="e3">
                <mat-button-toggle value="No">No</mat-button-toggle>
                <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="col-md-12">
            <label class="labelText">Specify <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="e3_detail" name="e3_detail" />
            </mat-form-field>
          </div>
          <div class="col-md-12 pad10">
            <label class="labelText">E4. Do you have a valid driver's license?
              <span style="color: red">*</span></label>
            <div class="col-sm-6">
              <mat-button-toggle-group formControlName="e4">
                <mat-button-toggle value="No">No</mat-button-toggle>
                <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="col-md-12 pad10">
            <label class="labelText">E5. Do you have an automobile available for use?
              <span style="color: red">*</span></label>
            <div class="col-sm-6">
              <mat-button-toggle-group formControlName="e5">
                <mat-button-toggle value="No">No</mat-button-toggle>
                <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="col-md-12">
            <label class="labelText">E6. How long was your longest full-time job?
              <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="e6" name="e6" required />
              <mat-error *ngFor="let validation of validation_messages.e6">
                <mat-error class="error-message" *ngIf="
                    asiemploymentsupportForm
                      .get('e6')
                      .hasError(validation.type) &&
                    (asiemploymentsupportForm.get('e6').dirty ||
                      asiemploymentsupportForm.get('e6').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <label class="labelText">E7. Usual (or last) occupation
              <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="e7" name="e7" required />
              <mat-error *ngFor="let validation of validation_messages.e7">
                <mat-error class="error-message" *ngIf="
                    asiemploymentsupportForm
                      .get('e7')
                      .hasError(validation.type) &&
                    (asiemploymentsupportForm.get('e7').dirty ||
                      asiemploymentsupportForm.get('e7').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <label class="labelText">Specify <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="e7_detail" name="e7_detail" />
            </mat-form-field>
          </div>
          <div class="col-md-12 pad10">
            <label class="labelText">E8. Does someone contribute to your support in any way?
              <span style="color: red">*</span>
            </label>
            <div class="col-sm-6">
              <mat-button-toggle-group formControlName="e8">
                <mat-button-toggle value="No">No</mat-button-toggle>
                <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="col-md-12 pad10">
            <label class="labelText">E9. Does this support constitute the majority of your support?
              <span style="color: red">*</span></label>
            <div class="col-sm-6">
              <mat-button-toggle-group formControlName="e9">
                <mat-button-toggle value="No">No</mat-button-toggle>
                <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="col-md-12">
            <label class="labelText">E10. Usual employment pattern, past 3 years?
              <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <mat-select formControlName="e10">
                <mat-option value="Full time (35+ hours)">Full time (35+ hours)</mat-option>
                <mat-option value="Part time (regular hours)">Part time (regular hours)</mat-option>
                <mat-option value="Part time (irregular hours)">Part time (irregular hours)</mat-option>
                <mat-option value="Student">Student</mat-option>
                <mat-option value="Military service">Military service</mat-option>
                <mat-option value="Retired/disability">Retired/disability</mat-option>
                <mat-option value="Unemployed">Unemployed</mat-option>
                <mat-option value="In controlled environment">In controlled environment</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <label class="labelText">E11. How many days were you paid for working in the past 30 days?
              <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="e11" name="e11" required />
              <mat-error *ngFor="let validation of validation_messages.e11">
                <mat-error class="error-message" *ngIf="
                    asiemploymentsupportForm
                      .get('e11')
                      .hasError(validation.type) &&
                    (asiemploymentsupportForm.get('e11').dirty ||
                      asiemploymentsupportForm.get('e11').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <label class="labelText">E12. Employment <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="e12" name="e12" required />
              <mat-error *ngFor="let validation of validation_messages.e12">
                <mat-error class="error-message" *ngIf="
                    asiemploymentsupportForm
                      .get('e12')
                      .hasError(validation.type) &&
                    (asiemploymentsupportForm.get('e12').dirty ||
                      asiemploymentsupportForm.get('e12').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <label class="labelText">E13. Unemployment compensation
              <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="e13" name="e13" required />
              <mat-error *ngFor="let validation of validation_messages.e13">
                <mat-error class="error-message" *ngIf="
                    asiemploymentsupportForm
                      .get('e13')
                      .hasError(validation.type) &&
                    (asiemploymentsupportForm.get('e13').dirty ||
                      asiemploymentsupportForm.get('e13').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <label class="labelText">E14. Welfare <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="e14" name="e14" required />
              <mat-error *ngFor="let validation of validation_messages.e14">
                <mat-error class="error-message" *ngIf="
                    asiemploymentsupportForm
                      .get('e14')
                      .hasError(validation.type) &&
                    (asiemploymentsupportForm.get('e14').dirty ||
                      asiemploymentsupportForm.get('e14').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <label class="labelText">E15. Pension, benefits or social security
              <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="e15" name="e15" required />
              <mat-error *ngFor="let validation of validation_messages.e15">
                <mat-error class="error-message" *ngIf="
                    asiemploymentsupportForm
                      .get('e15')
                      .hasError(validation.type) &&
                    (asiemploymentsupportForm.get('e15').dirty ||
                      asiemploymentsupportForm.get('e15').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <label class="labelText">E16. Mate, family or friends
              <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="e16" name="e16" required />
              <mat-error *ngFor="let validation of validation_messages.e16">
                <mat-error class="error-message" *ngIf="
                    asiemploymentsupportForm
                      .get('e16')
                      .hasError(validation.type) &&
                    (asiemploymentsupportForm.get('e16').dirty ||
                      asiemploymentsupportForm.get('e16').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <label class="labelText">E17. Illegal <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="e17" name="e17" required />
              <mat-error *ngFor="let validation of validation_messages.e17">
                <mat-error class="error-message" *ngIf="
                    asiemploymentsupportForm
                      .get('e17')
                      .hasError(validation.type) &&
                    (asiemploymentsupportForm.get('e17').dirty ||
                      asiemploymentsupportForm.get('e17').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <label class="labelText">E18. How many people depend on you for the majority of their
              food, shelter, etc.? <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="e18" name="e18" required />
              <mat-error *ngFor="let validation of validation_messages.e18">
                <mat-error class="error-message" *ngIf="
                    asiemploymentsupportForm
                      .get('e18')
                      .hasError(validation.type) &&
                    (asiemploymentsupportForm.get('e18').dirty ||
                      asiemploymentsupportForm.get('e18').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <label class="labelText">E19. How many days have you experienced employment problems in
              the past 30? <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="e19" name="e19" required />
              <mat-error *ngFor="let validation of validation_messages.e19">
                <mat-error class="error-message" *ngIf="
                    asiemploymentsupportForm
                      .get('e19')
                      .hasError(validation.type) &&
                    (asiemploymentsupportForm.get('e19').dirty ||
                      asiemploymentsupportForm.get('e19').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <label class="labelText">E20. How troubled or bothered have you been by these employment
              problems in the past 30 days?
              <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="e20" name="e20" required />
              <mat-error *ngFor="let validation of validation_messages.e20">
                <mat-error class="error-message" *ngIf="
                    asiemploymentsupportForm
                      .get('e20')
                      .hasError(validation.type) &&
                    (asiemploymentsupportForm.get('e20').dirty ||
                      asiemploymentsupportForm.get('e20').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <label class="labelText">E21. How important to you now is counseling for these employment
              problems? <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="e21" name="e21" required />
              <mat-error *ngFor="let validation of validation_messages.e21">
                <mat-error class="error-message" *ngIf="
                    asiemploymentsupportForm
                      .get('e21')
                      .hasError(validation.type) &&
                    (asiemploymentsupportForm.get('e21').dirty ||
                      asiemploymentsupportForm.get('e21').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <label class="labelText">E22. How would you rate the patient's need for employment
              counseling? <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="e22" name="e22" required />
              <mat-error *ngFor="let validation of validation_messages.e22">
                <mat-error class="error-message" *ngIf="
                    asiemploymentsupportForm
                      .get('e22')
                      .hasError(validation.type) &&
                    (asiemploymentsupportForm.get('e22').dirty ||
                      asiemploymentsupportForm.get('e22').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12 pad10">
            <label class="labelText">E23. Client's misrepresentation?
              <span style="color: red">*</span></label>
            <div class="col-sm-6">
              <mat-button-toggle-group formControlName="e23">
                <mat-button-toggle value="No">No</mat-button-toggle>
                <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="col-md-12 pad10">
            <label class="labelText">E24. Client's inability to understand?
              <span style="color: red">*</span></label>
            <div class="col-sm-6">
              <mat-button-toggle-group formControlName="e24">
                <mat-button-toggle value="No">No</mat-button-toggle>
                <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Comment Box -->
      <div class="col-sm-12 col-xl-4">
        <div class="row">
          <div class="col-sm-6">
            <mat-card class="custom-score-card mat-elevation-z8" appearance="raised">
              <label class="labelText">Clinician Score <span style="color: red">*</span></label>
              <mat-card-content>
                <mat-form-field appearance="outline" style="width:100px">
                  <input type="text" formControlName="clinicianScore" maxlength="1"
                    style="font-size:48px; font-weight: 900 !important" pattern="[0-9]" matInput required />
                </mat-form-field>
              </mat-card-content>
              <mat-error *ngFor="let validation of validation_messages.clinicianScore">
                <mat-error class="error-message" *ngIf="
                      asiemploymentsupportForm.get('clinicianScore').hasError(validation.type) &&
                      (asiemploymentsupportForm.get('clinicianScore').dirty ||
                        asiemploymentsupportForm.get('clinicianScore').touched)
                    ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-card>
          </div>
          <div class="col-sm-6">
            <mat-card class="custom-score-card mat-elevation-z8" appearance="raised">
              <label class="labelText" style="justify-content: center;">System Score</label>
              <mat-card-content>
                <span class="width:100px; font-size:48px; font-weight: 900 !important">
                  {{systemGeneratedScore}}
                </span>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div class="row" style="margin-top:15px">
          <div class="col-sm-12">

            <mat-card appearance="raised" class="mat-elevation-z8">
              <div class="row">
                <div class="col-md-12">
                  <label class="labelHeader">Comments</label>
                  <mat-form-field appearance="outline">
                    <textarea formControlName="comments" matInput rows="50"></textarea>
                  </mat-form-field>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end">
      <button [class]="
          asiemploymentsupportForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        " mat-raised-button type="submit" [disabled]="!asiemploymentsupportForm.valid || processing">
        Submit
      </button>
    </mat-dialog-actions>
  </form>
</div>