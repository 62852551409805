import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class DateTimeZoneService {
  localTimezone: string;

  constructor() {
    const timezone = moment.tz.guess(); // Get the current time zone
    const localTimezone = moment.tz(timezone).format('z'); // Retrieve the timezone abbreviation

    this.localTimezone = localTimezone || '';
  }

  getMyTimeZone() {
    return this.localTimezone;
  }
}
