<!-- Accounts Settings page, left side is the User View, right Side is the Main View -->
<div class="gridContainer">
  <!-- Left Grid Showing All the Settings -->
  <div class="leftGrid">
    <mat-card
      appearance="raised"
      class="mat-elevation-z8"
      style="border-radius: 15px"
    >
      <!-- Profile Name and Email -->
      <div class="profileSection">
        <i
          class="fa fa-user-circle-o fa-5x"
          style="color: cornflowerblue; font-size: 50px"
        ></i>
        <div style="display: flex; flex-direction: column; padding-top: 5px">
          <label class="labelHeading"> {{ lastName }}, {{ firstName }}</label>

          <label>{{ emailAddress }}</label>
        </div>
      </div>
      <!-- Settings Ul -->
      <div style="padding-top: 15px">
        <label
          class="labelHeading"
          style="margin-bottom: 15px; color: darkgray"
        >
          Settings
        </label>

        <!-- Looping -->
        <div *ngFor="let setting of mainSettings">
          <div
            class="settingsGridLayout"
            [class]="setting.text === tabSelected ? 'activeTab' : ''"
            (click)="tabSelected = setting.text"
          >
            <mat-icon style="color: darkgray">{{ setting.leftIcon }}</mat-icon>
            <span style="font-weight: 450">{{ setting.text }}</span>
            <mat-icon>{{ setting.rightIcon }}</mat-icon>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
  <!-- Right Grid Showing the settings clicked -->
  <div class="rightGrid">
    <mat-card
      appearance="raised"
      class="mat-elevation-z8"
      style="border-radius: 15px"
    >
      <app-profile *ngIf="tabSelected === 'Account'"></app-profile>

      <app-change-password
        *ngIf="tabSelected === 'Change Password'"
      ></app-change-password>

      <app-notifications-settings
        *ngIf="tabSelected === 'Notifications'"
        [isPhysician]="isPhysician"
        [isPatient]="isPatient"
      >
      </app-notifications-settings>

      <app-working-hours
        [canEditWorkingHours]="canEditWorkingHours"
        *ngIf="tabSelected === 'My Working Hours'"
      >
      </app-working-hours>

      <app-my-vacation
        [canEditTimeOff]="canEditTimeOff"
        *ngIf="tabSelected === 'Time Off & Holidays'"
      >
      </app-my-vacation>

      <app-member-completed-consent-documents
        *ngIf="tabSelected === 'Consent Documents'"
      >
      </app-member-completed-consent-documents>
    </mat-card>
  </div>
</div>
