<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Goal Notes</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      [mat-dialog-close]="reloadRequired"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div style="margin-top: 20px">
    <!-- Add Button -->
    <div style="text-align: right" *ngIf="goal?.status === 'Assigned'">
      <button mat-raised-button color="warn" type="button" (click)="addNote()">
        + Add Note
      </button>
    </div>
    <!--Goal Notes -->
    <div *ngIf="!goalNotes?.length">
      <mat-error>No note found</mat-error>
    </div>
    <div
      class="notesContainer"
      *ngIf="goalNotes?.length > 0"
      style="margin-top: 10px"
    >
      <mat-card
        appearance="outlined"
        class="mat-elevation-z8"
        *ngFor="let note of goalNotes; let i = index"
        style="margin: 10px; position: relative; border-radius: 15px"
      >
        <div class="noteCardDetails">
          <label class="labelText">{{ note.notes }}</label>

          <div *ngIf="goal.status != 'Completed'">
            <span
              class="material-icons"
              style="color: gray; font-size: 17px; cursor: pointer; z-index: 2"
              [matMenuTriggerFor]="menu"
            >
              more_vert
            </span>
          </div>
        </div>
        <div class="createdByFooter">
          <i class="material-icons" style="vertical-align: bottom; color: gray">
            edit_calendar
          </i>
          &nbsp;
          <label style="margin: 0; margin-top: 5px; font-size: 12px">
            {{ note.createdDate | date : 'MM/dd/y' }}
          </label>
          <i
            *ngIf="note.visibleByPatient && !isPatient"
            class="material-icons"
            style="
              color: #2196f3;
              font-size: 15px;
              vertical-align: bottom;
              position: absolute;
              left: 15px;
              bottom: 15px;
              cursor: pointer;
            "
            matTooltip="Visible by patient"
            [matTooltipPosition]="'above'"
          >
            sensor_occupied
          </i>
        </div>
        <mat-menu #menu="matMenu">
          <button mat-menu-item type="button" (click)="editNote(i)">
            <mat-icon style="color: #f39121"> edit </mat-icon>
            <span>Edit</span>
          </button>
          <button mat-menu-item type="button" (click)="deleteNote(i)">
            <mat-icon style="color: red"> delete_forever </mat-icon>
            <span>Delete</span>
          </button>
        </mat-menu>
      </mat-card>
    </div>
  </div>
</div>

<ng-template #callDeleteConfirmation>
  <form #userForm="ngForm">
    <h2 matDialogTitle style="color: red; text-align: center">
      Delete Confirmation
    </h2>
    <p style="font-weight: '400'">
      Are you sure you want to
      <span style="color: red; font-weight: bold">delete</span> this note?
    </p>
    <mat-dialog-actions align="center">
      <button mat-raised-button (click)="closeDeleteDialog('No')">No</button>
      <button
        class="btn-primary"
        type="button"
        mat-raised-button
        (click)="closeDeleteDialog('Yes')"
        [disabled]="deleteProcessing"
      >
        <span *ngIf="!deleteProcessing">Yes</span>
        <span *ngIf="deleteProcessing"
          >Deleting <i class="fa fa-spinner fa-spin"></i
        ></span>
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>
