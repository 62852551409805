<div class="row">
  <div class="col-md-12">
    <p style="font-size: 16px; font-weight: 450">
      <a routerLink="/" routerLinkActive="active">
        <em
          class="material-icons text_align-center"
          style="font-size: 18px; margin-top: -3px"
          >home</em
        >
      </a>
      &nbsp; / &nbsp;
      <span> <a>Schedules</a>&nbsp; / &nbsp; </span>
      <span>Shift Management</span>
    </p>
  </div>
</div>

<div
  *ngIf="
    isLoadingOrganizationDetails ||
    isLoadingResources ||
    isLoadingShiftsDetails ||
    isLoadingCalendar
  "
>
  <ngx-spinner
    bdColor="#fff"
    size="medium"
    color="lightsteelblue"
    style="height: 80vh; position: absolute; width: 100%; display: flex"
    type="square-jelly-box"
    [fullScreen]="false"
  >
    <div class="loading-container">
      <div class="loading-dot"></div>
      <div class="loading-dot"></div>
      <div class="loading-dot"></div>
    </div>
  </ngx-spinner>
</div>

<div
  *ngIf="
    !isLoadingOrganizationDetails &&
    !isLoadingResources &&
    !isLoadingShiftsDetails &&
    !isLoadingCalendar
  "
  style="margin-top: 15px"
>
  <mbsc-eventcalendar
    [data]="shifts"
    [slots]="slots"
    [view]="calView"
    [options]="calendarOptions"
    [headerTemplate]="headerTemp"
    [resourceTemplate]="resourceTemp"
    [slotTemplate]="slotTemp"
    [min]="minimumCalendarDate"
    class="md-shift-management-calendar"
  >
    <ng-template #headerTemp>
      <mbsc-calendar-nav></mbsc-calendar-nav>
      <div class="md-shift-header-controls">
        <mbsc-checkbox
          *ngFor="let st of shiftTimes"
          [(ngModel)]="st.checked"
          [label]="st.name"
          [disabled]="st.disabled"
          theme="material"
          (change)="checkboxChange($event)"
        ></mbsc-checkbox>
      </div>
      <mbsc-select
        [data]="views"
        inputStyle="box"
        [(ngModel)]="viewType"
        class="md-shift-cal-view"
        (onChange)="viewChange($event)"
      ></mbsc-select>
      <mbsc-calendar-prev></mbsc-calendar-prev>
      <mbsc-calendar-today></mbsc-calendar-today>
      <mbsc-calendar-next></mbsc-calendar-next>
      <br />
    </ng-template>
    <ng-template #resourceTemp let-resource>
      <div
        [ngClass]="{ 'md-shift-resource ': resource.children }"
        [ngStyle]="{
          color: resource.children ? resource.children[0].color : ''
        }"
      >
        <span *ngIf="resource.children"></span>
        {{ resource.name }}
      </div>
    </ng-template>
    <ng-template #slotTemp let-args>
      <div class="md-shift-header" *ngIf="formatMyDate(args.date) as date">
        <div
          class="md-shift-name"
          *ngIf="getShiftsNrs(date, args.slot.id) as shiftList"
        >
          {{ args.slot.name }}
          <br />
          <span class="md-shift-time">{{ args.slot.time }}</span>
        </div>
      </div>
    </ng-template>
  </mbsc-eventcalendar>
</div>

<mbsc-popup
  [options]="popupOptions"
  [buttons]="popupButtons"
  [headerText]="popupHeader"
  #popup
  class="employee-shifts-popup"
>
  <div
    class="row"
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 120px;
    "
  >
    <mat-icon>person</mat-icon> {{ selectedUser }}
  </div>

  <div
    *ngIf="isEdit && (isSuperOrgAdmin || isOrgAdmin)"
    class="mbsc-button-group"
  >
    <mbsc-button
      class="mbsc-button-block"
      color="danger"
      variant="outline"
      (click)="onDeleteClick()"
      [disabled]="isDeletingShift"
      >Delete shift</mbsc-button
    >
  </div>
</mbsc-popup>
