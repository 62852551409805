import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  MbscCalendarEvent,
  MbscEventcalendarOptions,
  MbscPopup,
  MbscPopupOptions,
  momentTimezone,
  setOptions,
} from '@mobiscroll/angular';
import { addDays, addMinutes, subMinutes } from 'date-fns';
import { KeycloakService } from 'keycloak-angular';
import moment from 'moment-timezone';
import {
  CoreService,
  DashboardsService,
  DateTimeZoneService,
  TherapySessionService,
  TimeOffAPIService,
  ToastMessageService,
  WorkingHoursDialogApiService,
} from 'src/app/_services';
import { SessionGeneralNotesComponent } from 'src/app/pages/patient-details/patient-components/sessions/session-general-notes/session-general-notes.component';
import { VideoIframeComponent } from 'src/app/shared/components/video-iframe/video-iframe.component';
import { FacilitiesSandbox } from 'src/app/shared/sandbox/facilities.sandbox';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { PermissionsSandbox } from 'src/app/shared/sandbox/permissions.sandbox';
import { VisitReasonsSandbox } from 'src/app/shared/sandbox/visit-reasons.sandbox';
import { TimeArrayMap } from 'src/app/shared/utilities/calendar/timeArrays';
import { TimeZonesCalendar } from 'src/app/shared/utilities/calendar/timeZonesCalendar';
import { DaysNumberMap } from 'src/app/shared/utilities/calendar/weekDays';
import { Colors } from 'src/app/shared/utilities/colors';
import {
  SessionQueues,
  SessionQueuesColorMap,
} from 'src/app/shared/utilities/session/sessionQueues';
import { hasAccess } from 'src/app/shared/utilities/utilities';

momentTimezone.moment = moment;
setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

@Component({
  selector: 'app-provider-dashboard-calendar',
  templateUrl: './provider-dashboard-calendar.component.html',
  styleUrl: './provider-dashboard-calendar.component.css',
})
export class ProviderDashboardCalendarComponent implements OnInit {
  @Output() output = new EventEmitter<any>();

  // Popup Elements
  @ViewChild('popup', { static: false })
  tooltip!: MbscPopup;
  anchor?: HTMLElement;
  timer: any;
  selectedEvent: any;
  isQueueSelectBoxOpen: boolean = false;

  loggedInUserId: string;

  // Importing Constants from the Shared Library
  colorsList = Colors;
  timeArrayMap = TimeArrayMap;
  daysNumberMap = DaysNumberMap;
  sessionQueues = SessionQueues;
  sessionQueuesColorMap = SessionQueuesColorMap;
  calendarTimeZones = TimeZonesCalendar;

  selectedCalendarTimeZone = '';
  selectedCaldendarTimeZoneDateFormat = '';

  hasTelehealthFeature: boolean = false;
  hostUrlProcessing: boolean = false;

  // Visit Reasons
  visitReasons: any = [];
  visitReasonsListMap: Map<string, any> = new Map<string, any>();

  organizationalTimeOffs: any = [];
  organizationalTimeOffLoading: boolean = true;

  // Colors Map For Visit Reasons
  visitReasonsColorMap: Map<string, string> = new Map();

  orgConfig: any;
  myFacilities: any = [];
  facilitiesSelectionList: any = [];
  filteredFacilities: any = [];

  searchFacilityName: string = '';
  sessionStatus: string = 'All';
  existingSession: any = [];
  activeCount: number = 0;
  closedCount: number = 0;

  // Permissions
  hasMemberPaymentsAddAccess = false;
  hasSessionsViewAccess: boolean = false;
  hasSessionEditAccess: boolean = false;
  hasMemberViewAccess: boolean = false;
  hasTherapySessionEditAccess: boolean = false;

  // MobiScroll Calendar Options
  myEvents: MbscCalendarEvent[] = [];
  mySessionStatusEvents: MbscCalendarEvent[] = [];
  filteredEvents: MbscCalendarEvent[] = [];

  timeCellStep = 30; // 1, 5, 10, 15, 20, 30, 60, 120, 180, 240, 360,
  timeLabelStep = 30; // 1, 5, 10, 15, 20, 30, 60, 120, 180, 240, 360,
  calendarViewType: string = 'day';
  calendarStartDay = 0; // Sunday is 0, Monday is 1, ...
  calendarEndDay = 6;
  invalids = [];
  invalidColors = [];
  allWorkingHoursArray = [];
  allDaysArray = [];
  calendarStartHour = '00:00';
  calendarEndHour = '24:00';
  currentTimezone: string;

  initialResponsiveView = {
    allDay: false,
    currentTimeIndicator: false,
    startTime: this.calendarStartHour,
    endTime: this.calendarEndHour,
    startDay: this.calendarStartDay,
    endDay: this.calendarEndDay,
    timeCellStep: this.timeCellStep,
    timeLabelStep: this.timeLabelStep,
  };

  // Current Date & time
  currentTime = new Date();
  yesterday = addDays(new Date(), -1);
  minimumCalendarDate = addDays(new Date(), -60);
  maximumCalendarDate = addDays(new Date(), 120);

  // Setting Up Initial MobiScroll Calendar View
  responsiveMobiScrollCalendarView = {
    // Min-width:0px
    xsmall: {
      view: {
        schedule: {
          type: this.calendarViewType,
          ...this.initialResponsiveView,
        },
      },
    },
    // min-width: 768px
    medium: {
      view: {
        schedule: {
          type: this.calendarViewType,
          ...this.initialResponsiveView,
        },
      },
    },
  };

  // Setting up initial MobiScroll Calendar options
  mobiscrollCalendarOptions: MbscEventcalendarOptions = {
    clickToCreate: false,
    dragToCreate: false,
    dragToMove: false,
    dragInTime: false,
    eventOverlap: true,
    timezonePlugin: momentTimezone,

    // On hover in display the event
    onEventHoverIn: (args) => {
      const event = args.event;

      this.selectedEvent = event;

      // Clear the current timer
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = null;

      this.anchor = args.domEvent.target;
      this.tooltip.open();
    },

    // Hide event tool tip on hover out
    onEventHoverOut: () => {
      this.timer = setTimeout(() => {
        this.selectedEvent = null;
        this.tooltip.close();
      }, 500);
    },

    // Open Tool tip on event Click
    onEventClick: (args) => {
      const event = args.event;
      this.selectedEvent = event;

      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = null;

      this.anchor = args.domEvent.target;
      this.tooltip.open();
    },
  };

  popupOptions: MbscPopupOptions = {
    display: 'anchored',
    touchUi: false,
    showOverlay: false,
    contentPadding: false,
    closeOnOverlayClick: false,
    focusOnClose: false,
    width: 350,
  };

  mouseEnter(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = null;
  }

  mouseLeave(): void {
    // Only if the Queue Selection Box is not open, then restore popup
    if (!this.isQueueSelectBoxOpen) {
      // If there was an old timer clear timeout
      if (this.timer) {
        clearTimeout(this.timer);
      }
      // Setup New Timer
      this.timer = setTimeout(() => {
        this.selectedEvent = null;

        this.tooltip.close();
      }, 200);
    }
  }

  constructor(
    private coreService: CoreService,
    private router: Router,
    private dashboardsService: DashboardsService,
    private therapySessionService: TherapySessionService,
    private workinghoursdialogapiservice: WorkingHoursDialogApiService,
    private toastMessageService: ToastMessageService,
    private timeOffAPIService: TimeOffAPIService,
    private facilitiesSandbox: FacilitiesSandbox,
    private visitReasonsSandBox: VisitReasonsSandbox,
    protected permissionsSandbox: PermissionsSandbox,
    private orgConfigSandbox: OrgConfigSandbox,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private keycloakService: KeycloakService,
    private dateTimeZoneService: DateTimeZoneService
  ) {
    this.loggedInUserId = this.coreService.getLoggedInCareProviderId();
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit(): void {
    // Default time zone based on user login
    this.selectedCalendarTimeZone = moment.tz.guess();
    this.selectedCaldendarTimeZoneDateFormat = moment
      .tz(this.selectedCalendarTimeZone)
      .format('z');

    // Check if this timezone exists or not
    this.checkTimeZoneExists();

    // load the visit reasons
    this.loadVisitReasons();

    // Handle Org Config to get features
    this.initializeOrgConfig();

    // First get all the facilities from sandbox
    this.loadMyFacilities();

    // Load Provider's Working Hours
    this.loadMyWorkingHours();

    // Load My Permissions();
    this.loadMyPermissions();

    // Load Organizational Holidays If Any
    this.loadOrganizationalHolidays();

    this.loadMyTimeOff();
  }

  // Handle time zone change to display date
  timeZoneChanged() {
    this.selectedCaldendarTimeZoneDateFormat = moment
      .tz(this.selectedCalendarTimeZone)
      .format('z');
  }

  // Check if the time zone exists and if not use the CT
  checkTimeZoneExists() {
    let timeZoneExists = false;
    this.calendarTimeZones.map((zone) => {
      if (zone.id === this.selectedCalendarTimeZone) {
        timeZoneExists = true;
      }
    });
    if (!timeZoneExists) {
      this.selectedCalendarTimeZone = 'America/Chicago';
      this.timeZoneChanged();
    }
  }

  // Get Visit Reasons From the SandBox
  loadVisitReasons() {
    this.visitReasonsSandBox.visitReasonsLoading$.subscribe((response) => {
      // If the visit Reasons Have Loaded Successfully
      if (!response) {
        // Now the load has been complete, get the visit reasons
        this.visitReasonsSandBox.visitReasons$.subscribe((visitReasons) => {
          this.visitReasons = visitReasons;
          this.visitReasonsColorMap = new Map<string, string>();

          // Set these visit reasons in color map
          visitReasons.forEach((visitReason) => {
            this.visitReasonsColorMap.set(visitReason.id, visitReason.color);
            this.visitReasonsListMap.set(visitReason.id, visitReason);
          });

          // Now load all the appointments for this logged in provider
          this.getAllSession();
        });
      }
    });
  }

  // Get the required orgconfig Information
  initializeOrgConfig() {
    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        // When load is complete, get the org config from app state
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          // Saving the org config
          this.orgConfig = orgConfig;

          // Check for organization features
          if (this.orgConfig && this.orgConfig?.features) {
            this.hasTelehealthFeature =
              this.orgConfig.features.includes('TELEHEALTH_ENABLED');
          }
        });
      }
    });
  }

  // Loading my permissions
  loadMyPermissions() {
    this.permissionsSandbox.permissions$.subscribe((response) => {
      this.hasMemberPaymentsAddAccess = hasAccess(
        this.keycloakService,
        'MEMBER_PAYMENTS_ADD',
        response,
        null
      );

      this.hasSessionsViewAccess = hasAccess(
        this.keycloakService,
        'THERAPY_SESSIONS_VIEW',
        response,
        null
      );

      this.hasSessionEditAccess = hasAccess(
        this.keycloakService,
        'THERAPY_SESSIONS_EDIT',
        response,
        null
      );

      this.hasMemberViewAccess = hasAccess(
        this.keycloakService,
        'MEMBERS_VIEW',
        response,
        null
      );

      this.hasTherapySessionEditAccess = hasAccess(
        this.keycloakService,
        'THERAPY_SESSIONS_EDIT',
        response,
        null
      );
    });
  }

  // Get All Organizational Holidays
  loadOrganizationalHolidays() {
    this.organizationalTimeOffLoading = true;
    this.timeOffAPIService.getOrganizationTimeOff(200, 0).subscribe({
      next: (response) => {
        if (response && response.items) {
          this.organizationalTimeOffs = response.items;

          // Now add these dates to invalids
          this.loadOrganizationalHolidaysOnMBSC();
        }
        this.organizationalTimeOffLoading = false;
      },
      error: (error) => {
        this.organizationalTimeOffLoading = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to retreive organizational holidays.'
        );
      },
    });
  }

  // Load Organizational Holidays as invalid in Mobiscroll
  loadOrganizationalHolidaysOnMBSC() {
    // Loop through each holiday for organization and mark them as invalid dates in calendar
    this.organizationalTimeOffs.forEach((holiday) => {
      // Start Date For Invalid in Mobi Scroll
      let startDate = new Date(holiday.startDay);
      // End Date For Invalid in Mobi Scroll
      let endDate = new Date(holiday.endDay);
      // Set End Date to be the start of the next day of end date
      // endDate.setDate(endDate.getDate() + 1);

      // Invalid Date Structure as per Mobi Scroll
      let invalidDateInCalendar = {
        start: addMinutes(startDate, 2),
        end: endDate,
        title: holiday?.reason,
        cssClass: 'md-stripes-gray-bg',
      };
      // Now update existing invalid and add this invalid date aswell
      this.invalids = [...this.invalids, invalidDateInCalendar];
      this.invalidColors = [...this.invalidColors, invalidDateInCalendar];
    });
  }

  loadMyTimeOff() {
    this.timeOffAPIService.getMyTimeOffs(200, 0).subscribe({
      next: (response) => {
        if (response && response?.items) {
          response.items.forEach((timeOff) => {
            // Start Date For Invalid in Mobi Scroll
            let startDate = new Date(timeOff.startDay);
            // End Date For Invalid in Mobi Scroll
            let endDate = new Date(timeOff.endDay);

            // Set End Date to be the start of the next day of end date
            // endDate.setDate(endDate.getDate() + 1);

            // Invalid Date Structure as per Mobi Scroll
            let invalidDateInCalendar = {
              start: startDate,
              end: endDate,
              title: timeOff?.reason,
              resource: timeOff.careProviderId,
              cssClass: 'md-rect-bg',
            };

            // Now update existing invalid and add this invalid date aswell
            this.invalids = [...this.invalids, invalidDateInCalendar];
            this.invalidColors = [...this.invalidColors, invalidDateInCalendar];
          });
        }
      },
      error: (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to load time offs'
        );
      },
    });
  }

  // Load All my facilities
  loadMyFacilities() {
    this.facilitiesSandbox.facilities$.subscribe((response) => {
      if (response) {
        this.myFacilities = response;
        this.filteredFacilities = response;
        this.facilitiesSelectionList = response;
      }
    });
  }

  // Load My Working Hours
  loadMyWorkingHours() {
    this.workinghoursdialogapiservice
      .getWorkingHours(this.loggedInUserId)
      .subscribe({
        next: (response) => {
          if (response && response?.data && response.data?.id) {
            // Now handle working hours
            this.determineWorkingHourForDay('SU', response.data?.sunday);
            this.determineWorkingHourForDay('MO', response.data?.monday);
            this.determineWorkingHourForDay('TU', response.data?.tuesday);
            this.determineWorkingHourForDay('WE', response.data?.wednesday);
            this.determineWorkingHourForDay('TH', response.data?.thursday);
            this.determineWorkingHourForDay('FR', response.data?.friday);
            this.determineWorkingHourForDay('SA', response.data?.saturday);
          } else {
            // Handle Default working hours for this provider
            this.handleDefaultWorkingHours();
          }
          //determining the calendar start and end time
          this.determineCalendarStartEndTime();
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load the office hours'
          );
        },
      });
  }

  // Determining calendar start and end time
  determineCalendarStartEndTime() {
    // Handle the time
    if (this.allWorkingHoursArray.length > 0) {
      // For check if there are numbers greater than 2400
      this.allWorkingHoursArray = this.allWorkingHoursArray.map((num) =>
        num >= 2400 ? 0 : num
      );

      // Now that we have converted the all working hours array
      let lowStart = Math.min(...this.allWorkingHoursArray);
      let highEnd = Math.max(...this.allWorkingHoursArray);

      // Now convert them into appropriate format
      let startTimeHour = Math.trunc(lowStart / 100);
      let startTimeHourString = startTimeHour.toString();

      let endTimeHour = Math.trunc(highEnd / 100);
      //endTimeHour = endTimeHour + 1;
      let endTimeHourString = endTimeHour.toString();

      if (startTimeHourString.length === 1) {
        startTimeHourString = '0' + startTimeHourString;
      }

      if (endTimeHourString.length === 1) {
        endTimeHourString = '0' + endTimeHourString;
      }

      if (this.allDaysArray.length > 0) {
        let lowDayStart = Math.min(...this.allDaysArray);
        let highDayEnd = Math.max(...this.allDaysArray);

        this.calendarStartDay = lowDayStart;
        this.calendarEndDay = highDayEnd;
      }

      // Now append this as the calendar start and end time range
      this.calendarStartHour = startTimeHourString + ':00';
      this.calendarEndHour = endTimeHourString + ':00';

      // Once the star and end Time hour has been determined
      this.responsiveMobiScrollCalendarView = {
        // Min-width:0px
        xsmall: {
          view: {
            schedule: {
              type: this.calendarViewType,
              ...this.initialResponsiveView,
              startTime: this.calendarStartHour,
              endTime: this.calendarEndHour,
              startDay: this.calendarStartDay,
              endDay: this.calendarEndDay,
            },
          },
        },
        // min-width: 768px
        medium: {
          view: {
            schedule: {
              type: this.calendarViewType,
              ...this.initialResponsiveView,
              startTime: this.calendarStartHour,
              endTime: this.calendarEndHour,
              startDay: this.calendarStartDay,
              endDay: this.calendarEndDay,
            },
          },
        },
      };
    }
  }

  // Handle the working hours for each day for each resource
  determineWorkingHourForDay(day, data) {
    // If this day is an off day, block the day for this resource
    if (data.offday) {
      this.blockAllDayForResource(day);
    } else {
      // If this is not a off day, now determine their working hours
      if (data?.workingHours) {
        // Now as there is working hours defined
        if (data.workingHours?.length == 1) {
          this.addSingleStartEndTime(
            day,
            data.workingHours[0].startTime,
            data.workingHours[0].endTime
          );
        } else if (data.workingHours?.length > 1) {
          this.addMultipleStartEndTime(day, data.workingHours);
        }
      }
    }
  }

  // Setting Default working hours to be 9-5 for the passed in resource
  handleDefaultWorkingHours() {
    // Block Saturday and Sundays First
    this.blockAllDayForResource('SA');
    this.blockAllDayForResource('SU');

    // For Monday To Friday set 9-5
    this.addSingleStartEndTime('MO', 900, 1700);
    this.addSingleStartEndTime('TU', 900, 1700);
    this.addSingleStartEndTime('WE', 900, 1700);
    this.addSingleStartEndTime('TH', 900, 1700);
    this.addSingleStartEndTime('FR', 900, 1700);
  }

  // Block Calendar Time For Single start and end time passed
  addSingleStartEndTime(day: string, startTime: number, endTime: number) {
    // Push this startTime & EndTime to the hours array
    this.allWorkingHoursArray.push(startTime, endTime);

    this.allDaysArray.push(this.daysNumberMap.get(day));

    // block the time before start time and after the end time
    let dayStartTime = '00:00';
    let dayEndTime = '24:00';

    let startTimeHour = Math.trunc(startTime / 100);
    let startTimeHourString = startTimeHour.toString();
    let startTimeMinutes = startTime % 100;
    let startTimeMinutesString = startTimeMinutes.toString();

    let endTimeHour = Math.trunc(endTime / 100);
    let endTimeHourString = endTimeHour.toString();
    let endTimeMinutes = endTime % 100;
    let endTimeMinutesString = endTimeMinutes.toString();

    // Handling for 24
    if (startTimeHour == 24) {
      startTimeHourString = '00';
    }
    if (endTimeHour == 24) {
      endTimeHourString = '00';
    }

    // Converting to Correct Format
    if (startTimeHourString.length === 1) {
      startTimeHourString = '0' + startTimeHourString;
    }
    if (endTimeHourString.length === 1) {
      endTimeHourString = '0' + endTimeHourString;
    }
    if (startTimeMinutesString.length === 1) {
      startTimeMinutesString = startTimeMinutesString + '0';
    }
    if (endTimeMinutesString.length === 1) {
      endTimeMinutesString = endTimeMinutesString + '0';
    }

    // Now block the time for this resource
    this.invalids = [
      ...this.invalids,
      {
        start: dayStartTime,
        end: startTimeHourString + ':' + startTimeMinutesString,
        recurring: {
          repeat: 'weekly',
          weekDays: day,
        },
      },
      {
        start: endTimeHourString + ':' + endTimeMinutesString,
        end: dayEndTime,
        recurring: {
          repeat: 'weekly',
          weekDays: day,
        },
      },
    ];
  }

  // Block Calendar Time For Multiple start and end time passed
  addMultipleStartEndTime(day: string, workingHours) {
    // block the time before start time and after the end time
    let dayStartTime = '00:00';
    let dayEndTime = '24:00';

    workingHours.forEach((workHour, index) => {
      let firstIndex = index == 0;
      let lastIndex = index == workingHours.length - 1;

      // Push this startTime & EndTime to the hours array
      this.allWorkingHoursArray.push(workHour.startTime, workHour.endTime);
      this.allDaysArray.push(this.daysNumberMap.get(day));

      let startTimeHour = Math.trunc(workHour.startTime / 100);
      let startTimeHourString = startTimeHour.toString();
      let startTimeMinutes = workHour.startTime % 100;
      let startTimeMinutesString = startTimeMinutes.toString();

      let endTimeHour = Math.trunc(workHour.endTime / 100);
      let endTimeHourString = endTimeHour.toString();
      let endTimeMinutes = workHour.endTime % 100;
      let endTimeMinutesString = endTimeMinutes.toString();

      if (startTimeHour == 24) {
        startTimeHourString = '00';
      }
      if (endTimeHour == 24) {
        endTimeHourString = '00';
      }

      // Converting to Correct Format
      if (startTimeHourString.length === 1) {
        startTimeHourString = '0' + startTimeHourString;
      }
      if (endTimeHourString.length === 1) {
        endTimeHourString = '0' + endTimeHourString;
      }
      if (startTimeMinutesString.length === 1) {
        startTimeMinutesString = '0' + startTimeMinutesString;
      }
      if (endTimeMinutesString.length === 1) {
        endTimeMinutesString = '0' + endTimeMinutesString;
      }

      if (firstIndex) {
        // Get the Next Index Start Time
        let nextIndexStartTimeHour = Math.trunc(
          workingHours[1].startTime / 100
        );
        let nextIndexStartTimeHourString = nextIndexStartTimeHour.toString();
        let nextIndexStartTimeMinutes = workingHours[1].startTime % 100;
        let nextIndexStartTimeMinutesString =
          nextIndexStartTimeMinutes.toString();
        // Converting to the right format
        if (nextIndexStartTimeHourString.length === 1) {
          nextIndexStartTimeHourString = '0' + nextIndexStartTimeHourString;
        }
        if (nextIndexStartTimeMinutesString.length === 1) {
          nextIndexStartTimeMinutesString =
            '0' + nextIndexStartTimeMinutesString;
        }
        // Blocking from start of day to start of time and end of time to next index start
        this.invalids = [
          ...this.invalids,
          {
            start: dayStartTime,
            end: startTimeHourString + ':' + startTimeMinutesString,

            recurring: {
              repeat: 'weekly',
              weekDays: day,
            },
          },
          {
            start: endTimeHourString + ':' + endTimeMinutesString,
            end:
              nextIndexStartTimeHourString +
              ':' +
              nextIndexStartTimeMinutesString,

            recurring: {
              repeat: 'weekly',
              weekDays: day,
            },
          },
        ];
      } else if (lastIndex) {
        // For last Index just block from last index end time to the end of the day
        this.invalids = [
          ...this.invalids,
          {
            start: endTimeHourString + ':' + endTimeMinutesString,
            end: dayEndTime,

            recurring: {
              repeat: 'weekly',
              weekDays: day,
            },
          },
        ];
      } else {
        // For multiple start and end times within the start and end index
        // Get the Next Index Start Time
        let previousIndexEndTimeHour = Math.trunc(
          workingHours[index - 1].endTime / 100
        );
        let previousIndexEndTimeHourString =
          previousIndexEndTimeHour.toString();
        let previousIndexEndTimeMinutes = workingHours[index - 1].endTime % 100;
        let previousIndexEndTimeMinutesString =
          previousIndexEndTimeMinutes.toString();
        // Converting to the right format
        if (previousIndexEndTimeHourString.length === 1) {
          previousIndexEndTimeHourString = '0' + previousIndexEndTimeHourString;
        }
        if (previousIndexEndTimeMinutesString.length === 1) {
          previousIndexEndTimeMinutesString =
            '0' + previousIndexEndTimeMinutesString;
        }

        // Get the Next Index Start Time
        let nextIndexStartTimeHour = Math.trunc(
          workingHours[index + 1].startTime / 100
        );
        let nextIndexStartTimeHourString = nextIndexStartTimeHour.toString();
        let nextIndexStartTimeMinutes = workingHours[index + 1].startTime % 100;
        let nextIndexStartTimeMinutesString =
          nextIndexStartTimeMinutes.toString();
        // Converting to the right format
        if (nextIndexStartTimeHourString.length === 1) {
          nextIndexStartTimeHourString = '0' + nextIndexStartTimeHourString;
        }
        if (nextIndexStartTimeMinutesString.length === 1) {
          nextIndexStartTimeMinutesString =
            '0' + nextIndexStartTimeMinutesString;
        }

        // Blocking from previos end to current start and current end to next start
        this.invalids = [
          ...this.invalids,
          {
            start:
              previousIndexEndTimeHourString +
              ':' +
              previousIndexEndTimeMinutesString,
            end: startTimeHourString + ':' + startTimeMinutesString,

            recurring: {
              repeat: 'weekly',
              weekDays: day,
            },
          },
          {
            start: endTimeHourString + ':' + endTimeMinutesString,
            end:
              nextIndexStartTimeHourString +
              ':' +
              nextIndexStartTimeMinutesString,

            recurring: {
              repeat: 'weekly',
              weekDays: day,
            },
          },
        ];
      }
    });
  }

  // Block all day for this resource
  blockAllDayForResource(day: string) {
    // Push this startTime & EndTime to the hours array
    this.allWorkingHoursArray.push(800, 1700);

    // Blocking all Day for the passed in day for the Resource
    this.invalids = [
      ...this.invalids,
      {
        recurring: {
          repeat: 'weekly',
          weekDays: day,
        },
      },
    ];
  }

  // Get all sessions for this provider
  getAllSession() {
    this.dashboardsService.getSessionsForProvider().subscribe({
      next: (response) => {
        if (response && response?.items) {
          this.existingSession = response.items;
          this.loadExistingEvents();
        }
      },
      error: (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Unable to retrieve existing session for this provider'
        );
      },
    });
  }

  // Loading Mobiscroll Events
  loadExistingEvents() {
    if (this.existingSession) {
      let allMobiScrollEvents = [];

      this.existingSession.forEach((session) => {
        let newEvent = <MbscCalendarEvent>{
          id: session.id,
          start: new Date(session.start),
          end: new Date(session.end),
          title: session.title,

          color:
            session.status === 'Canceled'
              ? 'darkgray'
              : this.visitReasonsColorMap.get(session.visitReasonId),

          editable: false,

          visitReasonId: session.visitReasonId,
          tooltip: session.billingProviderName,
          queue: session.queue,
          queueColor: this.sessionQueuesColorMap.get(session.queue),
          status: session.status,

          patientId: session.patientId,
          organizationId: session.organizationId,
          facilityId: session.billingProviderRefId,
          facilityName: session.billingProviderName,

          clinicalNoteStatus: session.clinicalNoteStatus,
          chargeStatus: session.chargeStatus,

          startTime: session.sessionStartTime,
          duration: session.sessionDuration,
          sessionCode: session.sessionCode,
          sessionType: session.sessionType,
          sessionFor: session.sessionFor,

          // Details for group appointments
          groupAppointment: session?.groupAppointment,
          groupMembers: session?.groupMembers,
          recurringAppointment: session?.recurringAppointment,
          recurringStart: session?.recurringStart,
          recurringEnd: session?.recurringEnd,
          recurringFrequency: session?.recurringFrequency,
          recurringDays: session?.recurringDays,
          sessionLevelNote: session?.sessionLevelNote,
        };

        allMobiScrollEvents.push(newEvent);
      });

      this.myEvents = [...allMobiScrollEvents];
      this.mySessionStatusEvents = [...allMobiScrollEvents];
      this.filteredEvents = [...allMobiScrollEvents];
    }
  }

  isFacilityVisible(facility) {
    return this.filteredFacilities.includes(facility);
  }

  selectDeselctFacility(event, facility) {
    if (event.checked) {
      // Add the facility to the list
      if (!this.filteredFacilities.includes(facility)) {
        this.filteredFacilities = [...this.filteredFacilities, facility];
        // Also add the events back to the list
        this.handleCheckBoxChangeEvents();
      }
    } else {
      // Remove the facility to the list
      if (this.filteredFacilities.includes(facility)) {
        this.filteredFacilities = this.filteredFacilities.filter(
          (resource) => resource != facility
        );

        this.handleCheckBoxChangeEvents();
      }
    }
  }

  selectAllFacilities() {
    this.filteredFacilities = [...this.myFacilities];

    this.handleCheckBoxChangeEvents();
  }

  deselectAllFacilities() {
    this.filteredFacilities = [];
    this.filteredEvents = [];
  }

  calendarTypeChanged(event) {
    this.calendarViewType = event.value;
    this.responsiveMobiScrollCalendarView = {
      // Min-width:0px
      xsmall: {
        view: {
          schedule: {
            type: this.calendarViewType,
            ...this.initialResponsiveView,
            startTime: this.calendarStartHour,
            endTime: this.calendarEndHour,
            startDay: this.calendarStartDay,
            endDay: this.calendarEndDay,
          },
        },
      },
      // min-width: 768px
      medium: {
        view: {
          schedule: {
            type: event.value,
            ...this.initialResponsiveView,
            startTime: this.calendarStartHour,
            endTime: this.calendarEndHour,
            startDay: this.calendarStartDay,
            endDay: this.calendarEndDay,
          },
        },
      },
    };
  }

  // Filter Events based on status
  sessionStatusChanged() {
    if (this.sessionStatus === 'All') {
      this.mySessionStatusEvents = [...this.myEvents];
      this.filteredEvents = [...this.myEvents];

      // Restore Facilities Selection
      this.handleCheckBoxChangeEvents();
    } else if (this.sessionStatus === 'Scheduled') {
      // For Scheduled ->Scheduled,Confirmed
      let scheduledEvents = this.myEvents.filter(
        (event) => event.queue === 'Scheduled' || event.queue === 'Confirmed'
      );
      this.mySessionStatusEvents = [...scheduledEvents];
      this.filteredEvents = [...scheduledEvents];

      // Restore Facilities Selection
      this.handleCheckBoxChangeEvents();
    } else if (this.sessionStatus === 'In Office') {
      // For Scheduled ->Scheduled,Confirmed
      let inOfficeEvents = this.myEvents.filter(
        (event) => event.queue === 'Arrived' || event.queue === 'Checked In'
      );
      this.mySessionStatusEvents = [...inOfficeEvents];
      this.filteredEvents = [...inOfficeEvents];

      // Restore facility and provider selections
      this.handleCheckBoxChangeEvents();
    } else if (this.sessionStatus === 'Closed') {
      let closedEvents = this.myEvents.filter(
        (event) =>
          event.status === 'Closed' ||
          event.status === 'Canceled' ||
          event.queue === 'Checked Out'
      );
      this.mySessionStatusEvents = [...closedEvents];
      this.filteredEvents = [...closedEvents];

      // Restore facility and provider selections
      this.handleCheckBoxChangeEvents();
    }
  }

  // Facility Name filter
  searchByFacilityNameChanged() {
    if (this.searchFacilityName) {
      this.facilitiesSelectionList = this.myFacilities.filter((facility) => {
        if (
          facility.facilityName
            .toLowerCase()
            .includes(this.searchFacilityName.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.facilitiesSelectionList = [...this.myFacilities];
    }
  }

  // Figure out which events to display based on the selected facility and providers
  handleCheckBoxChangeEvents() {
    let filteredEventsList: MbscCalendarEvent[] = [];

    this.mySessionStatusEvents.forEach((event) => {
      let isFacilityVisible = this.filteredFacilities.findIndex(
        (existingFacility) => existingFacility.id == event.facilityId
      );

      if (isFacilityVisible >= 0) {
        filteredEventsList.push(event);
      }
    });

    this.filteredEvents = [...filteredEventsList];
  }

  // Navigate to Member's page
  navigateToMemberPage(row) {
    // If only the user has access to view members
    if (this.hasMemberViewAccess) {
      this.router.navigate(['/main/member/' + row.patientId + '/NOTES']);
    }
  }
  // Navigate to sessions page
  navigateToSessionPage(row) {
    // If only the user has access to view member sessions
    if (this.hasSessionsViewAccess) {
      if (row?.groupAppointment) {
        // For Normal navigate to session edit
        this.router.navigate([`/group-sessions/${row.id}`], {
          queryParams: {
            parent: 'Home',
          },
        });
      } else {
        // For Normal navigate to session edit
        // Check if this is a group or regular session
        this.router.navigate(['/main/member/' + row.patientId + '/NOTES'], {
          queryParams: {
            secondaryId: row.id,
          },
        });
      }
    }
  }

  // If tele-health is enabled, determine if the join button is valid or not
  // Disable/Enable Join
  isJoinValid(session) {
    if (!session) {
      return false;
    }

    let currentTime = new Date();
    let appointmentStartTime = new Date(session.start);
    let appointmentEndTime = new Date(session.end);

    let delayedStartTime = subMinutes(appointmentStartTime, 5);
    let delayedEndTime = subMinutes(appointmentEndTime, 0);

    if (currentTime >= delayedStartTime && currentTime <= delayedEndTime) {
      return true;
    } else {
      return false;
    }
  }

  // Join Meeting
  JoinMeeting(session) {
    //First Get the host url
    this.hostUrlProcessing = true;

    this.therapySessionService
      .getHostUrl(session.id, session.patientId, session.organizationId)
      .subscribe({
        next: (response) => {
          let hostUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            response.data.hostUrl
          );
          this.tooltip.close();
          this.dialog.open(VideoIframeComponent, {
            data: {
              iframeMeeting: response.data.title,
              hostUrl: hostUrl,
            },
            minWidth: '100vw',
            maxWidth: '100vw',
            minHeight: '100vh',
            maxHeight: '100vh',
            autoFocus: false,
            disableClose: true,
          });
          this.hostUrlProcessing = false;
        },
        error: (error) => {
          this.hostUrlProcessing = false;
        },
      });
  }

  // Handling Mobiscroll Queue Boxes Change
  onQueueSelectionBoxOpen(event) {
    // Prevent the tool tip box from collapsing
    this.isQueueSelectBoxOpen = true;
  }
  // Handling Mobiscroll Queue Boxes Change
  onQueueSelectionBoxClose(event) {
    // Tool tip can now close
    this.isQueueSelectBoxOpen = false;
  }
  // Handle The Queue Status Change
  onQueueSelectionBoxChange(event) {
    if (event?.value) {
      let newQueueStatus = event.value;
      let selectedSessionId = this.selectedEvent.id;

      // Call in the endpoint to update the session Queue Status
      this.therapySessionService
        .updateQueueStatus(selectedSessionId, newQueueStatus)
        .subscribe({
          next: (response) => {
            if (response?.result) {
              // Now update the event queue status and color
              this.myEvents = this.myEvents.map((event) => {
                if (event.id === selectedSessionId) {
                  event.queue = newQueueStatus;
                  event.queueColor =
                    this.sessionQueuesColorMap.get(newQueueStatus);
                  event.status =
                    newQueueStatus === 'No Show' ||
                    newQueueStatus === 'Canceled'
                      ? 'Canceled'
                      : 'Active';
                  event.color =
                    event.status === 'Canceled'
                      ? 'darkgray'
                      : this.visitReasonsColorMap.get(event.visitReasonId);
                }
                return event;
              });

              // Now handle the filtering of events
              this.sessionStatusChanged();

              this.toastMessageService.displaySuccessMessage(
                'Queue for session ' +
                  this.selectedEvent.title +
                  '  has been updated'
              );

              // Clear timer and reset popup
              if (this.timer) {
                clearTimeout(this.timer);
              }

              setTimeout(() => {
                this.timer = null;
                this.selectedEvent = null;
                this.tooltip.close();
              }, 200);
              //Emit event to reload the header count
              this.output.emit({ type: 'RELOAD_STATS' });
            }
          },
          error: (error) => {
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to update the queue status for ' +
                this.selectedEvent.title
            );
          },
        });
    }
  }

  collectPayment(row) {
    this.router.navigate(['main/collectmemberpayment', row.patientId], {
      queryParams: { sessionId: row.id },
    });
  }

  rescheduleSession(row) {
    // For Group appointment navigate to group appointment scheduler
    if (row?.groupAppointment) {
      // For Normal navigate to session edit
      this.router.navigate([`/schedules/groupsessionscheduler/${row.id}`], {
        queryParams: {
          parent: 'Home',
        },
      });
    } else {
      // For Normal navigate to session edit
      this.router.navigate([`/schedules/reschedule/${row.id}`], {
        queryParams: {
          parent: 'Home',
        },
      });
    }
  }

  // Add Remove attendance
  takeAttendance(event: any, member: any, attendace: boolean) {
    this.therapySessionService
      .handleGroupSessionMemberAttendance(event.id, member.member.id, attendace)
      .subscribe({
        next: (response) => {
          // Now we first need to update the events properties
          this.myEvents = this.myEvents.map((filterEvent) => {
            if (filterEvent.id === event.id) {
              event.groupMembers = response.data.groupMembers;
            }
            return event;
          });
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to change the attendance status'
          );
        },
      });
  }

  viewSessionLevelNote(selectedEvent) {
    let sessionDetails = this.existingSession
      .filter((session) => session.id === selectedEvent.id)
      .at(0);

    if (sessionDetails) {
      this.dialog.open(SessionGeneralNotesComponent, {
        data: {
          therapySession: sessionDetails,
          hasEditAccess: this.hasTherapySessionEditAccess,
        },
        disableClose: true,
        autoFocus: false,
        minWidth: '35vw',
      });
    }
  }
}
