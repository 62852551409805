import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DataTablesModel, IMedication, ResponseModal } from '../../_models';

@Injectable({ providedIn: 'root' })
export class MedicationApiService {
  constructor(private httpClient: HttpClient) {}

  getMedications(
    patientId: string,
    sessionId: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('patientId', patientId);

    if (sessionId) params = params.append('sessionId', sessionId);

    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());
    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/medications`,
      { params: params }
    );
  }

  getMedicationDetails(medicationId: string): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/medication/` + medicationId
    );
  }

  addMedication(thisMedication: IMedication): Observable<IMedication> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<IMedication>(`${environment.apiUrl}/medication`, thisMedication, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateMedication(thisMedication: IMedication): Observable<IMedication> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<IMedication>(`${environment.apiUrl}/medication`, thisMedication, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  deleteMedication(id: string): Observable<void> {
    return this.httpClient
      .delete<void>(`${environment.apiUrl}/medication/` + id)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
