<div id="messagingroot">
  <stream-channel-list
    (click)="isMenuOpen = false"
    class="channel-list menu-{{ isMenuOpen ? 'open' : 'close' }} "
  >
  </stream-channel-list>
  <stream-channel style="width: 100%">
    <stream-channel-header>
      <button class="menu-button" (click)="isMenuOpen = !isMenuOpen">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="#2196f3"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3 8V6H21V8H3ZM3 13H21V11H3V13ZM3 18H21V16H3V18Z"
          />
        </svg>
      </button>
    </stream-channel-header>
    <stream-message-list></stream-message-list>
    <stream-notification-list></stream-notification-list>
    <stream-message-input></stream-message-input>
    <stream-thread name="thread">
      <stream-message-list mode="thread"></stream-message-list>
      <stream-message-input mode="thread"></stream-message-input>
    </stream-thread>
  </stream-channel>
</div>

<div class="closeButton" (click)="closeDialog()">
  <mat-icon style="color: #ff3742">cancel</mat-icon>
</div>

<ng-template
  #avatarTemplate
  let-name="name"
  let-imageUrl="imageUrl"
  let-size="size"
  let-type="type"
  let-location="location"
  let-user="user"
  let-channel="channel"
  let-initialsType="initialsType"
  let-showOnlineIndicator="showOnlineIndicator"
>
  <stream-avatar
    [name]="name"
    [imageUrl]="imageUrl"
    [size]="size"
    [type]="type"
    [location]="location"
    [user]="user"
    [channel]="channel"
    [initialsType]="'first-letter-of-each-word'"
    [showOnlineIndicator]="showOnlineIndicator"
  ></stream-avatar>
</ng-template>
