<mat-card-title>General Information</mat-card-title>
<mat-divider></mat-divider>

<div style="margin-top: 40px; padding: 10px">
  <form [formGroup]="asigeneralForm" novalidate (ngSubmit)="onAsiGeneralFormSubmit()">
    <div class="row">
      <!-- Question Box -->
      <div class="col-sm-12 col-xl-8">
        <mat-card appearance="raised" class="mat-elevation-z8" style="margin-bottom: 20px">
          <div class="col-md-12">
            <label class="labelText">G1. ID Number <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="g1" name="g1" required />
              <mat-error *ngFor="let validation of validation_messages.g1">
                <mat-error class="error-message" *ngIf="
                    asigeneralForm.get('g1').hasError(validation.type) &&
                    (asigeneralForm.get('g1').dirty ||
                      asigeneralForm.get('g1').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <label class="labelText">G4. Date of Admission <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input formControlName="g4" matInput [matDatepicker]="g4Picker" autoSlashDate />
              <mat-datepicker-toggle matSuffix [for]="g4Picker"></mat-datepicker-toggle>
              <mat-datepicker #g4Picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <label class="labelText">G5. Date of Interview <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input formControlName="g5" matInput [matDatepicker]="g5Picker" autoSlashDate />
              <mat-datepicker-toggle matSuffix [for]="g5Picker"></mat-datepicker-toggle>
              <mat-datepicker #g5Picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <label class="labelText">G6. Time Begun <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="g6" name="g6" required />
              <mat-error *ngFor="let validation of validation_messages.g6">
                <mat-error class="error-message" *ngIf="
                    asigeneralForm.get('g6').hasError(validation.type) &&
                    (asigeneralForm.get('g6').dirty ||
                      asigeneralForm.get('g6').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <label class="labelText">G7. Time Ended <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="g7" name="g7" required />
              <mat-error *ngFor="let validation of validation_messages.g7">
                <mat-error class="error-message" *ngIf="
                    asigeneralForm.get('g7').hasError(validation.type) &&
                    (asigeneralForm.get('g7').dirty ||
                      asigeneralForm.get('g7').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12 pad10">
            <label class="labelText">G8. Class <span style="color: red">*</span></label>&nbsp;
            <br />
            <mat-button-toggle-group formControlName="g8">
              <mat-button-toggle value="Intake">Intake</mat-button-toggle>
              <mat-button-toggle value="Follow-up">Follow-up</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div class="col-md-12 pad10">
            <label class="labelText">G9. Contact Code <span style="color: red">*</span></label>&nbsp;
            <br />
            <mat-button-toggle-group formControlName="g9">
              <mat-button-toggle value="In person">In person</mat-button-toggle>
              <mat-button-toggle value="Telephone">Telephone</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div class="col-md-12 pad10">
            <label class="labelText">G10. Gender <span style="color: red">*</span></label>&nbsp;
            <br />
            <mat-button-toggle-group formControlName="g10">
              <mat-button-toggle value="Male">Male</mat-button-toggle>
              <mat-button-toggle value="Female">Female</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div class="col-md-12">
            <label class="labelText">G99. Treatment Episode Number
              <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="g99" name="g99" required />
              <mat-error *ngFor="let validation of validation_messages.g99">
                <mat-error class="error-message" *ngIf="
                    asigeneralForm.get('g99').hasError(validation.type) &&
                    (asigeneralForm.get('g99').dirty ||
                      asigeneralForm.get('g99').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <label class="labelText">G11. Interviewer Code Number
              <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="g11" name="g11" required />
              <mat-error *ngFor="let validation of validation_messages.g11">
                <mat-error class="error-message" *ngIf="
                    asigeneralForm.get('g11').hasError(validation.type) &&
                    (asigeneralForm.get('g11').dirty ||
                      asigeneralForm.get('g11').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12 pad10">
            <label class="labelText">G12. Special <span style="color: red">*</span></label>&nbsp;
            <br />
            <mat-button-toggle-group formControlName="g12">
              <mat-button-toggle value="Patient terminated">Patient terminated</mat-button-toggle>
              <mat-button-toggle value="Patient refused">Patient refused</mat-button-toggle>
              <mat-button-toggle value="Patient unable to respond">Patient unable to respond</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-md-12">
            <label class="labelText">G14. How long have you lived at your current address?
              <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="g14" name="g14" required />
              <mat-error *ngFor="let validation of validation_messages.g14">
                <mat-error class="error-message" *ngIf="
                    asigeneralForm.get('g14').hasError(validation.type) &&
                    (asigeneralForm.get('g14').dirty ||
                      asigeneralForm.get('g14').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12 pad10">
            <label class="labelText">G15. Is this residence owned by you or your family?
              <span style="color: red">*</span></label>&nbsp;
            <br />
            <mat-button-toggle-group formControlName="g15">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div class="col-md-12">
            <label class="labelText">G16. Date of birth <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input formControlName="g16" matInput [matDatepicker]="g16Picker" autoSlashDate />
              <mat-datepicker-toggle matSuffix [for]="g16Picker"></mat-datepicker-toggle>
              <mat-datepicker #g16Picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-12 pad10">
            <label class="labelText">G17 Of what race do you consider yourself?
              <span style="color: red">*</span></label>&nbsp;
            <br />
            <mat-button-toggle-group formControlName="g17" vertical>
              <mat-button-toggle value="White (not Hisp)">White (not Hisp)</mat-button-toggle>
              <mat-button-toggle value="Black (not Hisp)">Black (not Hisp)</mat-button-toggle>
              <mat-button-toggle value="American Indian">American Indian</mat-button-toggle>
              <mat-button-toggle value="Alaskan Native">Alaskan Native</mat-button-toggle>
              <mat-button-toggle value="Asian/Pacific">Asian/Pacific</mat-button-toggle>
              <mat-button-toggle value="Hispanic-Mexican">Hispanic-Mexican</mat-button-toggle>
              <mat-button-toggle value="Hispanic-Puerto Rican">Hispanic-Puerto Rican</mat-button-toggle>
              <mat-button-toggle value="Hispanic-Cuban">Hispanic-Cuban</mat-button-toggle>
              <mat-button-toggle value="Unknown ">Unknown </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div class="col-md-12 pad10">
            <label class="labelText">G18. Do you have a religious preference?
              <span style="color: red">*</span></label>&nbsp;
            <br />
            <mat-button-toggle-group formControlName="g18">
              <mat-button-toggle value="Protestant">Protestant</mat-button-toggle>
              <mat-button-toggle value="Catholic">Catholic</mat-button-toggle>
              <mat-button-toggle value="Jewish">Jewish</mat-button-toggle>
              <mat-button-toggle value="Islamic">Islamic</mat-button-toggle>
              <mat-button-toggle value="Other">Other</mat-button-toggle>
              <mat-button-toggle value="None">None</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div class="col-md-12 pad10">
            <label class="labelText">G19. Have you been in a controlled environment in the past 30
              days? <span style="color: red">*</span></label>&nbsp;
            <br />
            <mat-button-toggle-group formControlName="g19">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Jail/prison">Jail/prison</mat-button-toggle>
              <mat-button-toggle value="Alcohol or drug tx">Alcohol or drug tx</mat-button-toggle>
              <mat-button-toggle value="Medical tx">Medical tx</mat-button-toggle>
              <mat-button-toggle value="Psychiatric tx">Psychiatric tx</mat-button-toggle>
              <mat-button-toggle value="Other">Other</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div class="col-md-12">
            <label class="labelText">G20. How many days? <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="g20" name="g20" required />
              <mat-error *ngFor="let validation of validation_messages.g20">
                <mat-error class="error-message" *ngIf="
                    asigeneralForm.get('g20').hasError(validation.type) &&
                    (asigeneralForm.get('g20').dirty ||
                      asigeneralForm.get('g20').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
        </mat-card>
      </div>

      <!-- Comment Box -->
      <div class="col-sm-12 col-xl-4">
        <div class="row">
          <div class="col-sm-6">
            <mat-card class="custom-score-card mat-elevation-z8" appearance="raised">
              <label class="labelText">Clinician Score <span style="color: red">*</span></label>
              <mat-card-content>
                <mat-form-field appearance="outline" style="width:100px">
                  <input type="text" formControlName="clinicianScore" maxlength="1"
                    style="font-size:48px; font-weight: 900 !important" pattern="[0-9]" matInput required />
                </mat-form-field>
              </mat-card-content>
              <mat-error *ngFor="let validation of validation_messages.clinicianScore">
                <mat-error class="error-message" *ngIf="
                      asigeneralForm.get('clinicianScore').hasError(validation.type) &&
                      (asigeneralForm.get('clinicianScore').dirty ||
                        asigeneralForm.get('clinicianScore').touched)
                    ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-card>
          </div>
          <div class="col-sm-6">
            <mat-card class="custom-score-card mat-elevation-z8" appearance="raised">
              <label class="labelText" style="justify-content: center;">System Score</label>
              <mat-card-content>
                <span class="width:100px; font-size:48px; font-weight: 900 !important">
                  {{systemGeneratedScore}}
                </span>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div class="row" style="margin-top:15px">
          <div class="col-sm-12">
            <mat-card appearance="raised" class="mat-elevation-z8">
              <div class="row">
                <div class="col-md-12">
                  <label class="labelText">Comments<span style="color: red">*</span></label>
                  <mat-form-field appearance="outline">
                    <textarea formControlName="comments" matInput rows="50" required></textarea>
                  </mat-form-field>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end">
      <button [class]="
          asigeneralForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        " mat-raised-button type="submit" [disabled]="!asigeneralForm.valid || processing">
        Submit*
      </button>
    </mat-dialog-actions>
  </form>
</div>