import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ITherapySession, Patient } from 'src/app/_models';
import { TherapySessionService } from 'src/app/_services';

@Component({
  selector: 'app-group-member-collect-payments',
  templateUrl: './group-member-collect-payments.component.html',
  styleUrl: './group-member-collect-payments.component.css',
})
export class GroupMemberCollectPaymentsComponent implements OnInit {
  sessionDetails: ITherapySession;
  selectedMemberId: string;
  patient: Patient;

  constructor(
    public dialogRef: MatDialogRef<GroupMemberCollectPaymentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public therapysessionApiService: TherapySessionService
  ) {
    this.sessionDetails = data.sessionDetails;
    this.selectedMemberId = data.selectedMemberId;
    this.patient = data.patient;
  }

  ngOnInit(): void {}

  closeDialog() {
    this.dialogRef.close();
  }
}
