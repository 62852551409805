import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IFacility } from 'src/app/_models/facility.model';
import { ReferralService } from 'src/app/_services/referrals/referral.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-add-edit-referral',
  templateUrl: './add-edit-referral.component.html',
  styleUrl: './add-edit-referral.component.css',
})
export class AddEditReferralComponent {
  currentStep = 1;
  isEdit: boolean = false;
  addReferralForm!: FormGroup;
  isChecked = false;
  submitted = false;
  patientData: any[];
  patientDataPatch: any[];
  patientId: string;
  processing: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private router: Router,
    private referralService: ReferralService,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
  ) {
    this.facilities = data.facilities;
  }

  facilities: Array<IFacility> = [];

  ngOnInit() {
    this.mainForm();

    this.patientId = this.activatedRoute.snapshot.params['id'];
    if (this.patientId) {
      this.getData();
      this.isEdit = true;
    }
  }

  mainForm() {
    this.addReferralForm = this._formBuilder.group({
      referralForm: this._formBuilder.group({
        facilityId: ['', Validators.required],
        serviceType: ['', Validators.required],
        stimulants: [''],
        referralMethod: ['', Validators.required],
        referredBy: ['', Validators.required],
        referralZipCode: [
          '',
          [Validators.pattern('^[0-9]{5}$'), Validators.maxLength(5)],
        ],
        referralFax: [''],
        mhuc: [],
      }),

      patientDetailsForm: this._formBuilder.group({
        name: [''],
        dateOfBirth: ['', Validators.required],
        phoneNumber: [''],
        email: [
          '',
          Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z,A-Z]+\\.[a-z,A-Z]{2,4}$'),
        ],
        referralNotes: [''],
        primaryInsurance: [],
        medicaid: [],
      }),

      mediaForm: this._formBuilder.group({
        documents: [''],
      }),
    });
  }

  getData() {
    this.referralService
      .getReferralById(this.patientId)
      .subscribe((data: any) => {
        if (data && data?.data) {
          this.patientDataPatch = data?.data;
          this.addReferralForm
            .get('referralClinicForm')
            ?.patchValue(this.patientDataPatch);
          this.addReferralForm
            .get('patientDetailsForm')
            ?.patchValue(this.patientDataPatch['patientDetails']);
          this.addReferralForm
            .get('patientDetailsForm')
            ?.patchValue(this.patientDataPatch);
        }
      });
  }

  onReferralFormSubmit() {
    let patientDetails: any = {};
    let payload: any = {};
    this.submitted = true;
    patientDetails['name'] =
      this.addReferralForm.get('patientDetailsForm').value.name;
    patientDetails['phoneNumber'] =
      this.addReferralForm.get('patientDetailsForm').value.phoneNumber;
    patientDetails['patientStatus'] =
      this.addReferralForm.get('patientDetailsForm').value.patientStatus;
    patientDetails['dateOfBirth'] =
      this.addReferralForm.get('patientDetailsForm').value.dateOfBirth;
    patientDetails['email'] =
      this.addReferralForm.get('patientDetailsForm').value.email;
    patientDetails['primaryInsurance'] =
      this.addReferralForm.get('patientDetailsForm').value.primaryInsurance;
    patientDetails['medicaid'] =
      this.addReferralForm.get('patientDetailsForm').value.medicaid;
    let transformdate;
    payload = Object.keys(this.addReferralForm.controls).reduce((acc, fg) => {
      if (fg !== 'patientDetailsForm') {
        acc = { ...acc, ...(this.addReferralForm?.get(fg)?.value || {}) };
      }
      if (this.addReferralForm?.get(fg)?.value.dateReceived) {
        const date = new Date(this.addReferralForm?.get(fg)?.value.dateReceived);
        // transformdate = this.datePipe.transform(date, 'yyyy-MM-dd', 'UTC+5:30');
      }
      return acc;
    }, {});

    payload['patientDetails'] = patientDetails;
    if (payload?.patientDetails?.dateOfBirth) {
      const date = new Date(payload.patientDetails.dateOfBirth);
    }
    payload.referralNotes =
      this.addReferralForm.get('patientDetailsForm').value.referralNotes;
    if (!this.isEdit) {
      this.referralService.createReferral(payload).subscribe((res: any) => { });
      this.router.navigateByUrl('/dashboard');
      this.submitted = false;
    }
    if (this.isEdit) {
      payload.id = this.patientId;
      this.referralService
        .updateReferral(payload, payload.id)
        .subscribe((res: any) => {
          this.currentStep++;
        });
      this.router.navigateByUrl('/dashboard');
      this.submitted = false;
    }
    this.dialog.closeAll();
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}
