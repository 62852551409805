import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { IInterntAddictionAssessment } from 'src/app/_models/assessments/addiction/internetaddictionassessment.model';
import {
  InterntAddictionAssessmentApiService,
  ToastMessageService,
} from 'src/app/_services/';

@Component({
  selector: 'app-internet-addiction',
  templateUrl: './internet-addiction.component.html',
  styleUrls: ['./internet-addiction.component.css'],
})
export class InternetAddictionComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public internetaddictionassessmentApiService: InterntAddictionAssessmentApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) {}
  public riskScore: number;
  public internetAddictionForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  internetaddictionassessment: IInterntAddictionAssessment;
  internetaddictionassessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.internetAddictionForm, this.errors);

    this.loadInterntAddictionAssessment();
  }

  loadInterntAddictionAssessment() {
    this.internetAddictionForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.internetaddictionassessmentApiService
        .getInterntAddictionAssessmentDetails(this.assessmentId)
        .subscribe((internetaddictionassessment) => {
          if (internetaddictionassessment.data) {
            this.internetAddictionForm.controls[
              'usingElectronicsLongerThanPlanned'
            ].setValue(
              internetaddictionassessment.data.usingElectronicsLongerThanPlanned
                .answer
            );
            this.internetAddictionForm.controls[
              'electronicsOverPartner'
            ].setValue(
              internetaddictionassessment.data.electronicsOverPartner.answer
            );
            this.internetAddictionForm.controls[
              'preferOnlineOverHouseChores'
            ].setValue(
              internetaddictionassessment.data.preferOnlineOverHouseChores
                .answer
            );
            this.internetAddictionForm.controls[
              'performanceDeteriorating'
            ].setValue(
              internetaddictionassessment.data.performanceDeteriorating.answer
            );
            this.internetAddictionForm.controls[
              'peopleAroundMeAreConcerned'
            ].setValue(
              internetaddictionassessment.data.peopleAroundMeAreConcerned.answer
            );
            this.internetAddictionForm.controls[
              'nonDisclosureOfOnlineActivity'
            ].setValue(
              internetaddictionassessment.data.nonDisclosureOfOnlineActivity
                .answer
            );
            this.internetAddictionForm.controls[
              'attentivenessSuffers'
            ].setValue(
              internetaddictionassessment.data.attentivenessSuffers.answer
            );
            this.internetAddictionForm.controls[
              'onSocialMediaAfterWakingUp'
            ].setValue(
              internetaddictionassessment.data.onSocialMediaAfterWakingUp.answer
            );
            this.internetAddictionForm.controls[
              'botheredWhenInterrupted'
            ].setValue(
              internetaddictionassessment.data.botheredWhenInterrupted.answer
            );
            this.internetAddictionForm.controls[
              'anxiousWithoutDevice'
            ].setValue(
              internetaddictionassessment.data.anxiousWithoutDevice.answer
            );
            this.internetAddictionForm.controls[
              'useInternetToEscapeLife'
            ].setValue(
              internetaddictionassessment.data.useInternetToEscapeLife.answer
            );
            this.internetAddictionForm.controls[
              'lessHappyWithoutAccessToInternet'
            ].setValue(
              internetaddictionassessment.data.lessHappyWithoutAccessToInternet
                .answer
            );
            this.internetAddictionForm.controls['putOffOtherThings'].setValue(
              internetaddictionassessment.data.putOffOtherThings.answer
            );
            this.internetAddictionForm.controls['missBeingOnline'].setValue(
              internetaddictionassessment.data.missBeingOnline.answer
            );
            this.internetAddictionForm.controls['stayUpLaterAtNight'].setValue(
              internetaddictionassessment.data.stayUpLaterAtNight.answer
            );
            this.internetAddictionForm.controls[
              'prefertoBeOnlineThanGoOutWithFriends'
            ].setValue(
              internetaddictionassessment.data
                .prefertoBeOnlineThanGoOutWithFriends.answer
            );
            this.internetAddictionForm.controls[
              'triedBeingOfflineWithoutSuccess'
            ].setValue(
              internetaddictionassessment.data.triedBeingOfflineWithoutSuccess
                .answer
            );
            this.internetAddictionForm.controls[
              'concernedAboutMissingOnlineActivity'
            ].setValue(
              internetaddictionassessment.data
                .concernedAboutMissingOnlineActivity.answer
            );

            this.riskScore = internetaddictionassessment.data.riskScore;
          }
        });
    }
  }

  onInterntAddictionAssessmentFormSubmit() {
    if (this.internetAddictionForm.invalid) {
      this.internetAddictionForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.internetaddictionassessment = Object.assign(
      {},
      this.internetaddictionassessment
    );
    this.internetaddictionassessment.patientId =
      this.internetAddictionForm.controls['patientId'].value;
    this.internetaddictionassessment.sessionId =
      this.internetAddictionForm.controls['sessionId'].value;
    this.internetaddictionassessment.assessmentRequestId =
      this.internetAddictionForm.controls['assessmentRequestId'].value;
    this.internetaddictionassessment.usingElectronicsLongerThanPlanned = {
      answer:
        this.internetAddictionForm.controls['usingElectronicsLongerThanPlanned']
          .value,
    };
    this.internetaddictionassessment.electronicsOverPartner = {
      answer:
        this.internetAddictionForm.controls['electronicsOverPartner'].value,
    };
    this.internetaddictionassessment.preferOnlineOverHouseChores = {
      answer:
        this.internetAddictionForm.controls['preferOnlineOverHouseChores']
          .value,
    };
    this.internetaddictionassessment.performanceDeteriorating = {
      answer:
        this.internetAddictionForm.controls['performanceDeteriorating'].value,
    };
    this.internetaddictionassessment.peopleAroundMeAreConcerned = {
      answer:
        this.internetAddictionForm.controls['peopleAroundMeAreConcerned'].value,
    };
    this.internetaddictionassessment.nonDisclosureOfOnlineActivity = {
      answer:
        this.internetAddictionForm.controls['nonDisclosureOfOnlineActivity']
          .value,
    };
    this.internetaddictionassessment.attentivenessSuffers = {
      answer: this.internetAddictionForm.controls['attentivenessSuffers'].value,
    };
    this.internetaddictionassessment.onSocialMediaAfterWakingUp = {
      answer:
        this.internetAddictionForm.controls['onSocialMediaAfterWakingUp'].value,
    };
    this.internetaddictionassessment.botheredWhenInterrupted = {
      answer:
        this.internetAddictionForm.controls['botheredWhenInterrupted'].value,
    };
    this.internetaddictionassessment.anxiousWithoutDevice = {
      answer: this.internetAddictionForm.controls['anxiousWithoutDevice'].value,
    };
    this.internetaddictionassessment.useInternetToEscapeLife = {
      answer:
        this.internetAddictionForm.controls['useInternetToEscapeLife'].value,
    };
    this.internetaddictionassessment.lessHappyWithoutAccessToInternet = {
      answer:
        this.internetAddictionForm.controls['lessHappyWithoutAccessToInternet']
          .value,
    };
    this.internetaddictionassessment.putOffOtherThings = {
      answer: this.internetAddictionForm.controls['putOffOtherThings'].value,
    };
    this.internetaddictionassessment.missBeingOnline = {
      answer: this.internetAddictionForm.controls['missBeingOnline'].value,
    };
    this.internetaddictionassessment.stayUpLaterAtNight = {
      answer: this.internetAddictionForm.controls['stayUpLaterAtNight'].value,
    };
    this.internetaddictionassessment.prefertoBeOnlineThanGoOutWithFriends = {
      answer:
        this.internetAddictionForm.controls[
          'prefertoBeOnlineThanGoOutWithFriends'
        ].value,
    };
    this.internetaddictionassessment.triedBeingOfflineWithoutSuccess = {
      answer:
        this.internetAddictionForm.controls['triedBeingOfflineWithoutSuccess']
          .value,
    };
    this.internetaddictionassessment.concernedAboutMissingOnlineActivity = {
      answer:
        this.internetAddictionForm.controls[
          'concernedAboutMissingOnlineActivity'
        ].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.internetaddictionassessment.accessCode = this.accessCode;

      this.internetaddictionassessmentApiService
        .addGuestInterntAddictionAssessment(this.internetaddictionassessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Internet Addiction Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Internet Addiction Assessment'
            );
          }
        );
    } else {
      this.internetaddictionassessmentApiService
        .addInterntAddictionAssessment(this.internetaddictionassessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Internet Addiction Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Internet Addiction Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    usingElectronicsLongerThanPlanned: [
      {
        type: 'required',
        message: 'I have felt tense, anxious or nervous is required',
      },
    ],
    electronicsOverPartner: [
      {
        type: 'required',
        message:
          'I have felt I have someone to turn to for support when needed is required',
      },
    ],
    preferOnlineOverHouseChores: [
      {
        type: 'required',
        message: 'I have felt able to cope when things go wrong is required',
      },
    ],
    performanceDeteriorating: [
      {
        type: 'required',
        message: 'Talking to people has felt too much for me is required',
      },
    ],
    peopleAroundMeAreConcerned: [
      { type: 'required', message: 'I have felt panic or terror is required' },
    ],
    nonDisclosureOfOnlineActivity: [
      { type: 'required', message: 'I made plans to end my life is required' },
    ],
    attentivenessSuffers: [
      {
        type: 'required',
        message:
          'I have had difficulty getting to sleep or staying asleep is required',
      },
    ],
    onSocialMediaAfterWakingUp: [
      {
        type: 'required',
        message: 'I have felt despairing or hopeless is required',
      },
    ],
    botheredWhenInterrupted: [
      { type: 'required', message: 'I have felt unhappy is required' },
    ],
    anxiousWithoutDevice: [
      {
        type: 'required',
        message:
          'Unwanted images or memories have been distressing is required',
      },
    ],
    useInternetToEscapeLife: [
      {
        type: 'required',
        message:
          'Unwanted images or memories have been distressing is required',
      },
    ],
    lessHappyWithoutAccessToInternet: [
      {
        type: 'required',
        message:
          'Unwanted images or memories have been distressing is required',
      },
    ],
    putOffOtherThings: [
      {
        type: 'required',
        message:
          'Unwanted images or memories have been distressing is required',
      },
    ],
    missBeingOnline: [
      {
        type: 'required',
        message:
          'Unwanted images or memories have been distressing is required',
      },
    ],
    stayUpLaterAtNight: [
      {
        type: 'required',
        message:
          'Unwanted images or memories have been distressing is required',
      },
    ],
    prefertoBeOnlineThanGoOutWithFriends: [
      {
        type: 'required',
        message:
          'Unwanted images or memories have been distressing is required',
      },
    ],
    triedBeingOfflineWithoutSuccess: [
      {
        type: 'required',
        message:
          'Unwanted images or memories have been distressing is required',
      },
    ],
    concernedAboutMissingOnlineActivity: [
      {
        type: 'required',
        message:
          'Unwanted images or memories have been distressing is required',
      },
    ],
  };

  private buildForm() {
    this.internetAddictionForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      usingElectronicsLongerThanPlanned: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      electronicsOverPartner: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      preferOnlineOverHouseChores: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      performanceDeteriorating: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      peopleAroundMeAreConcerned: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      nonDisclosureOfOnlineActivity: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      attentivenessSuffers: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      onSocialMediaAfterWakingUp: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      botheredWhenInterrupted: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      anxiousWithoutDevice: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      useInternetToEscapeLife: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      lessHappyWithoutAccessToInternet: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      putOffOtherThings: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      missBeingOnline: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      stayUpLaterAtNight: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      prefertoBeOnlineThanGoOutWithFriends: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      triedBeingOfflineWithoutSuccess: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      concernedAboutMissingOnlineActivity: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
    });
    this.internetAddictionForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.internetAddictionForm) {
      return;
    }
    const form = this.internetAddictionForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
