import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import moment from 'moment';
import { DataTablesModel } from 'src/app/_models';
import { AggressionQuestionnaireApiService } from 'src/app/_services';
import { CHART_COLORS, greenChart, orangeChart, redChart, yellowChart } from 'src/app/shared/utilities/utilities';
@Component({
  selector: 'app-aggression-questionnaire-charts',
  templateUrl: './aggression-questionnaire-charts.component.html',
  styleUrls: ['./aggression-questionnaire-charts.component.css']
})
export class AggressionQuestionnaireChartsComponent implements OnInit {
  @Input() patientId: string;
  @Input() assessmentId: string;
  chartColorRed = CHART_COLORS.red;
  chartColorOrange = CHART_COLORS.orange;
  chartColorBlue = CHART_COLORS.blue;
  chartColorGreen = CHART_COLORS.green;
  chartColorPurple = CHART_COLORS.purple;
  lineChartData: ChartConfiguration<'bar'>['data'] = {
    datasets: [{
      data: [], label: 'Physical Aggression', backgroundColor: this.chartColorRed, hoverBackgroundColor: this.chartColorRed, borderColor: '#EEEEEE',
      pointStyle: 'circle',
    },
    {
      data: [], label: 'Verbal Aggression', backgroundColor: this.chartColorOrange, hoverBackgroundColor: this.chartColorOrange, borderColor: '#EEEEEE',
      pointStyle: 'circle',
    },
    {
      data: [], label: 'Hostility', backgroundColor: this.chartColorBlue, hoverBackgroundColor: this.chartColorBlue, borderColor: '#EEEEEE',
      pointStyle: 'circle',
    },
    {
      data: [], label: 'Anger', backgroundColor: this.chartColorPurple, hoverBackgroundColor: this.chartColorPurple, borderColor: '#EEEEEE',
      pointStyle: 'circle',
    },]
  };

  lineChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        },
        min: 0,
        max: 1,
        ticks: { stepSize: 0.2 }
      }
    }
  };

  lineChartLegend = true;
  lineChartType = 'bar';
  mainChartType = 'bar';

  data: DataTablesModel = {} as DataTablesModel;
  processing$ = false;
  isLoadingResults = false;
  error: string = '';
  scoreMap = new Map();
  scoreDetails: any[] = [];
  constructor(public aggressionQuestionnaireApiService: AggressionQuestionnaireApiService,
    private route: ActivatedRoute) {
  }
  ngOnInit() {
    this.data.page = 0;
    this.data.per_page = 10;
    this.loadGamingAddictionAssessments();
  }
  public loadGamingAddictionAssessments() {
    this.isLoadingResults = true;
    this.error = '';
    this.aggressionQuestionnaireApiService.getAggressionQuestionnaireList(this.patientId, this.assessmentId, this.data.per_page, this.data.page).subscribe(response => {
      if (response && response.items) {
        this.data.items = response.items;
        this.data.total = response.total;
        var riskScores = [];
        var labels = [];
        this.scoreDetails = [];
        var paScores = [];
        var vaScores = [];
        var hScores = [];
        var aScores = [];
        for (var item of response.items) {
          riskScores.push(item.riskScore);
          if (item.props) {
            paScores.push(item.props['pa-score']);
            vaScores.push(item.props['va-score']);
            hScores.push(item.props['h-score']);
            aScores.push(item.props['a-score']);
          } else {
            paScores.push(0);
            vaScores.push(0);
            hScores.push(0);
            aScores.push(0);
          }
          var stillUtc = moment.utc(item.createdDate).toDate();
          var assessmentDate = moment(stillUtc).local().format('MM/DD/YY');
          labels.push(assessmentDate);
          for (var key in item) {
            if (item.hasOwnProperty(key)) {
              if (item[key] && item[key].hasOwnProperty("score")) {
                var scoreObj = item[key];
                var vaTempScores = [];
                var paTempScores = [];
                var hTempScores = [];
                var aTempScores = [];
                if (this.scoreMap.get(key)) {
                  vaTempScores = this.scoreMap.get(key).vaScores;
                  paTempScores = this.scoreMap.get(key).paScores;
                  hTempScores = this.scoreMap.get(key).hScores;
                  aTempScores = this.scoreMap.get(key).aScores;
                }
                var chartMaxVal = this.charMaxVal(key);
                if (scoreObj["props"]) {
                  vaTempScores.push(scoreObj["props"]["va"]);
                  paTempScores.push(scoreObj["props"]["pa"]);
                  aTempScores.push(scoreObj["props"]["a"]);
                  hTempScores.push(scoreObj["props"]["h"]);
                  var returnObj = {
                    "vaScores": vaTempScores,
                    "paScores": paTempScores,
                    "hScores": hTempScores,
                    "aScores": aTempScores,
                    "key": key,
                    "title": scoreObj["title"],
                    "answer": scoreObj["answer"],
                    "chartData": [],
                    "chartOptions": {
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        x: {
                          min: 0,
                          max: 10,
                          border: {
                            display: true
                          },
                          grid: {
                            display: true,
                            drawOnChartArea: true,
                            drawTicks: true,
                            z: 5
                          }
                        },
                        y: {
                          min: 0,
                          max: chartMaxVal,
                          stepSize: 0.04,
                          border: {
                            display: true
                          },
                          grid: {
                            display: true,
                            drawOnChartArea: true,
                            drawTicks: true,
                            z: 5
                          }
                        }
                      }
                    },
                  };
                  this.scoreMap.set(key, returnObj);
                }
              }
            }
          }
        }
        this.scoreMap.forEach((value, key) => {
          let dataset: any = {
            datasets: [
              { data: value.paScores, label: 'Physical Aggression', backgroundColor: this.chartColorRed, hoverBackgroundColor: this.chartColorRed },
              { data: value.vaScores, label: 'Verbal Aggression', backgroundColor: this.chartColorOrange, hoverBackgroundColor: this.chartColorOrange },
              { data: value.hScores, label: 'Hostility', backgroundColor: this.chartColorBlue, hoverBackgroundColor: this.chartColorBlue },
              { data: value.aScores, label: 'Anger', backgroundColor: this.chartColorPurple, hoverBackgroundColor: this.chartColorPurple }
            ],
            "labels": labels,
            "title": value.title,
            "answer": value.answer,
            "chartOptions": value.chartOptions
          };
          this.scoreDetails.push(dataset)
        });
        this.lineChartData.datasets[0].data = paScores;
        this.lineChartData.datasets[1].data = vaScores;
        this.lineChartData.datasets[2].data = hScores;
        this.lineChartData.datasets[3].data = aScores;
        this.lineChartData.labels = labels;
        this.isLoadingResults = false;
      }
      if (response == null) {
        this.data.items = [];
        this.data.total = 0;
      }
    },
      error => {
        this.error = error;
        this.isLoadingResults = false;
      })
  }
  getChartColor(scoringArray: any[]) {
    var score = scoringArray[scoringArray.length - 1];
    if (score >= 4) {
      return redChart;
    } else if (score >= 3) {
      return orangeChart;
    } else if (score >= 2) {
      return yellowChart;
    } else {
      return greenChart;
    }
  }
  charMaxVal(key: string) {
    var returnVal;
    switch (key) {
      case 'q2':
      case 'q5':
      case 'q8':
      case 'q11':
      case 'q13':
      case 'q16':
      case 'q22':
      case 'q25':
      case 'q29':
        returnVal = 0.11;
        break;
      case 'q3':
      case 'q7':
      case 'q10':
      case 'q15':
      case 'q17':
      case 'q20':
      case 'q24':
      case 'q26':
        returnVal = 0.12;
        break;
      case 'q1':
      case 'q9':
      case 'q12':
      case 'q18':
      case 'q19':
      case 'q23':
      case 'q28':
        returnVal = 0.14;
        break;
      case 'q4':
      case 'q6':
      case 'q14':
      case 'q21':
      case 'q27':
        returnVal = 0.20;
        break;
      default:
    }
    return returnVal;
  }
  getRiskScoreChartColor(scoringArray: any[], maxScore: number) {
    var lastScore = scoringArray[scoringArray.length - 1];
    if (lastScore >= 80) {
      return redChart;
    } else if (lastScore >= 50) {
      return orangeChart;
    } else if (lastScore >= 30) {
      return yellowChart;
    } else {
      return greenChart;
    }
  }
}
