<mat-card-title>Education and Vocational History</mat-card-title>
<mat-divider></mat-divider>

<div style="margin-top: 20px; padding: 10px">
  <form
    [formGroup]="bpsaeduvocationalhistoryForm"
    novalidate
    (ngSubmit)="onBpsaEduVocationalHistoryFormSubmit()"
  >
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText">
            Highest grade completed <span style="color: red">*</span>
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="gradeCompleted"
              name="gradeCompleted"
              required
            />
            <mat-error
              *ngIf="
                bpsaeduvocationalhistoryForm.controls['gradeCompleted'].invalid
              "
            >
              Highest grade completed is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >History of any behavioral difficulties?
            <span style="color: red">*</span></label
          >&nbsp;
          <br />

          <mat-button-toggle-group formControlName="behavioralDifficulties">
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="Member Denies"
              >Member Denies</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >What type of learner are you?
            <span style="color: red">*</span></label
          >&nbsp;
          <br />
          <mat-button-toggle-group formControlName="typeOfLearner">
            <mat-button-toggle value="Hands-on">Hands-on</mat-button-toggle>
            <mat-button-toggle value="visual">visual</mat-button-toggle>
            <mat-button-toggle value="auditory ">auditory </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >History of learning disorder?
            <span style="color: red">*</span></label
          >&nbsp;
          <br />
          <mat-button-toggle-group formControlName="learningDisorders">
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="Member Denies"
              >Member Denies</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >Currently Employed? <span style="color: red">*</span></label
          >&nbsp;
          <br />
          <mat-button-toggle-group formControlName="currentlyEmployed">
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="Member Denies"
              >Member Denies</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >How would you rate your current employment?
            <span style="color: red">*</span></label
          >&nbsp;
          <br />
          <mat-button-toggle-group formControlName="rateCurrentEmployment">
            <mat-button-toggle value="Poor">Poor</mat-button-toggle>
            <mat-button-toggle value="Unsatisfactory"
              >Unsatisfactory</mat-button-toggle
            >
            <mat-button-toggle value="Satisfactory"
              >Satisfactory</mat-button-toggle
            >
            <mat-button-toggle value="Good">Good</mat-button-toggle>
            <mat-button-toggle value="Very good">Very good</mat-button-toggle>
            <mat-button-toggle value="N/A">N/A</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >Have you ever served in the military?
            <span style="color: red">*</span></label
          >&nbsp;
          <br />
          <mat-button-toggle-group formControlName="servedInMilitary">
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="No">No</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
  </form>
</div>
