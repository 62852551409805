<div class="row">
  <div class="col-md-12">
    <p style="font-size: 16px; font-weight: 450">
      <a routerLink="/" routerLinkActive="active">
        <em
          class="material-icons text_align-center"
          style="font-size: 18px; margin-top: -3px"
          >home</em
        >
      </a>
      &nbsp; / &nbsp;
      <span>Play Books</span>
    </p>
  </div>
</div>

<br />
<div class="gridContainer" *ngIf="!selectedAssessmentType">
  <mat-card
    appearance="raised"
    *ngFor="let group of assessmentGroups"
    style="border-radius: 15px"
  >
    <p style="font-weight: 800; font-size: 19px">
      {{ group.name }}
      <a routerLink="/config/assessment-goals/{{ group.name }}">
        <i
          class="fa fa-bullseye"
          aria-hidden="true"
          style="color: #e64980; font-size: 18px; margin: 3px"
          matTooltip="Goals"
          [matTooltipPosition]="'above'"
        ></i>
      </a>
    </p>

    <ul>
      <li
        *ngFor="let assessment of group.assessment"
        style="margin-bottom: 5px"
      >
        <span *ngIf="assessment.enabled" style="font-weight: 400; opacity: 0.8">
          {{ assessment.name }}
        </span>

        <span
          *ngIf="!assessment.enabled"
          style="
            font-weight: 450;
            color: gray;
            cursor: not-allowed;
            opacity: 0.8;
          "
        >
          {{ assessment.name }}
        </span>
        <a routerLink="/config/careplan/{{ assessment.id }}"
          ><i
            class="material-icons link-icons"
            style="color: #ae3ec9; font-size: 18px; margin: 3px"
            matTooltip="Care plan"
            [matTooltipPosition]="'above'"
            >assignment</i
          >
        </a>
        <!-- <a routerLink="/config/assessment-goals/{{ assessment.id }}">
          <i class="fa fa-bullseye" aria-hidden="true" style="color: #e64980; font-size: 18px; margin: 3px"
            matTooltip="Goals" matTooltipClass="tooltip-color" [matTooltipPosition]="'below'"></i>
        </a> -->
        <a routerLink="/config/assessment-alerts/{{ assessment.id }}">
          <i
            class="fa fa-bell"
            aria-hidden="true"
            style="color: #2196f3; font-size: 18px; margin: 3px"
            matTooltip="Notifications"
            [matTooltipPosition]="'below'"
          ></i>
        </a>
      </li>
    </ul>
  </mat-card>
</div>
