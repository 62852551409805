import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataTablesModel } from 'src/app/_models';
import { ToastMessageService } from 'src/app/_services';
import { ConsentDocumentApiService } from 'src/app/_services/consent-docs/orgconsentdocument.service';
import { ConsentDocumentEsignComponent } from './consent-document-esign/consent-document-esign.component';
import { MemberToDoListUpdateInterfaceComponent } from './member-to-do-list-update-interface/member-to-do-list-update-interface.component';

@Component({
  selector: 'app-member-todo-list',
  templateUrl: './member-todo-list.component.html',
  styleUrl: './member-todo-list.component.css',
})
export class MemberTodoListComponent implements OnInit {
  isLoadingResults = false;
  data: DataTablesModel = {} as DataTablesModel;

  constructor(
    public dialog: MatDialog,
    public consentDocumentApiService: ConsentDocumentApiService,
    private toastMessageService: ToastMessageService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.loadMyToDoList();
  }

  public loadMyToDoList() {
    this.isLoadingResults = true;

    this.consentDocumentApiService.getMyIntakeToDoList().subscribe({
      next: (response) => {
        this.isLoadingResults = false;
        if (response && response.items) {
          this.data.items = response.items;
          this.data.total = response.total;
        } else {
          this.data.items = [];
          this.data.total = 0;
        }
      },
      error: (error) => {
        this.isLoadingResults = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to load the To-Do List'
        );
      },
    });
  }

  signConsentDocument(row) {
    let dialogRef = this.dialog.open(ConsentDocumentEsignComponent, {
      data: {
        recordId: row.id,
        consentDocumentId: row.consentDocumentId,
        documentName: row.documentName,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '45vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadMyToDoList();
      }
    });
  }

  updateInformation(row) {
    let dialogRef = this.dialog.open(MemberToDoListUpdateInterfaceComponent, {
      data: {
        row: row,
      },
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((response) => {
      this.loadMyToDoList();
    });
  }

  customForm(row) {
    this.router.navigate(['/customform/ve', row.id, row.consentDocumentId]);
  }
}
