import { createReducer, on } from '@ngrx/store';
import { UserSettingsPropsAction } from '../actions';
import { initialUserSettingsPropsState } from '../state/user-settings-props.state';

export const userSettingsPropsReducer = createReducer(
  initialUserSettingsPropsState,

  //On Load
  on(UserSettingsPropsAction.getUserSettingsProps, (state) => ({
    ...state,
    userSettingsLoading: true,
    userSettingsSuccess: false,
    userSettingsError: undefined,
  })),

  //On Success
  on(
    UserSettingsPropsAction.getUserSettingsSuccessAction,
    (state, { payload }) => {
      return {
        ...state,
        userSettings: payload,
        userSettingsLoading: false,
        userSettingsSuccess: true,
        userSettingsError: undefined,
      };
    }
  ),

  //On Failed
  on(
    UserSettingsPropsAction.getUserSettingsFailedAction,
    (state, { payload }) => ({
      ...state,
      userSettingsLoading: false,
      userSettingsSuccess: false,
      userSettingsError: payload,
    })
  )
);
