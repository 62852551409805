<mat-card
  appearance="raised"
  style="border-radius: 15px"
  class="mat-elevation-z8"
>
  <div
    style="
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-right: 15px;
      align-items: center;
    "
  >
    <div>
      <label
        style="
          font-size: 20px;
          font-weight: bold;
          color: rgb(0, 0, 0, 0.87);
          width: 100%;
          margin-bottom: 0;
        "
      >
        Mood Check-In
      </label>
      <label>How are you feeling?</label>
    </div>

    <div>
      <button
        color="primary"
        mat-raised-button
        type="button"
        (click)="captureMood()"
      >
        Check-In
      </button>
    </div>
  </div>
</mat-card>
