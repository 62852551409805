<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>{{ data.title }}</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      [mat-dialog-close]="false"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <p style="font-weight: 400; margin-top: 15px; text-align: center">
    {{ data.msg }}
  </p>

  <p
    style="
      margin-top: 15px;
      font-size: 12px;
      font-style: italic;
      font-weight: 400;
      color: #333333;
    "
    *ngIf="data.disclaimer"
  >
    {{ data.disclaimer }}
  </p>

  <mat-dialog-actions align="end" *ngIf="noButtonTitle || yesButtonTitle">
    <button
      *ngIf="noButtonTitle"
      mat-raised-button
      [mat-dialog-close]="false"
      type="button"
      (click)="cancel()"
    >
      {{ data.noButtonTitle }}
    </button>

    <button
      *ngIf="yesButtonTitle"
      color="warn"
      mat-raised-button
      [mat-dialog-close]="true"
      (click)="confirm()"
      cdkFocusInitial
    >
      {{ data.yesButtonTitle }}
    </button>
  </mat-dialog-actions>
</div>
