<mat-tab-group [@.disabled]="true">
  <mat-tab>
    <ng-template mat-tab-label>
      <i
        class="fa fa-check-circle"
        *ngIf="isTaskCompleted('INTAKE_MEMBER_INFO')"
        aria-hidden="true"
        style="color: #43a047; padding-right: 5px"
      ></i>
      Member Details
    </ng-template>
    <app-intake-member-data
      [patient]="patient"
      [therapySession]="therapySession"
      [hasBPSAIntakeViewAccess]="hasBPSAIntakeViewAccess"
      [hasBPSAIntakeAddAccess]="hasBPSAIntakeAddAccess"
      [hasBPSAIntakeEditAccess]="hasBPSAIntakeEditAccess"
      (updatedTherapySession)="refreshPageEvent($event)"
      (reloadPatientProfile)="reloadPatientProfile($event)"
    >
    </app-intake-member-data>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <i
        class="fa fa-check-circle"
        *ngIf="isTaskCompleted('INTAKE_INSURANCE_INFO')"
        aria-hidden="true"
        style="color: #43a047; padding-right: 5px"
      ></i>
      Insurance
    </ng-template>
    <app-intake-insurance-info
      [therapySession]="therapySession"
      [patient]="patient"
      [hasBPSAIntakeViewAccess]="hasBPSAIntakeViewAccess"
      [hasBPSAIntakeAddAccess]="hasBPSAIntakeAddAccess"
      [hasBPSAIntakeEditAccess]="hasBPSAIntakeEditAccess"
      (updatedTherapySession)="refreshPageEvent($event)"
    ></app-intake-insurance-info>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <i
        class="fa fa-check-circle"
        *ngIf="isTaskCompleted('INTAKE_HEALTH_INFO')"
        aria-hidden="true"
        style="color: #43a047; padding-right: 5px"
      ></i>
      Health Information
    </ng-template>
    <app-intake-health-info
      [patient]="patient"
      [therapySession]="therapySession"
      [hasBPSAIntakeViewAccess]="hasBPSAIntakeViewAccess"
      [hasBPSAIntakeAddAccess]="hasBPSAIntakeAddAccess"
      [hasBPSAIntakeEditAccess]="hasBPSAIntakeEditAccess"
      (updatedTherapySession)="refreshPageEvent($event)"
    ></app-intake-health-info>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <i
        class="fa fa-check-circle"
        *ngIf="isTaskCompleted('INTAKE_CLINICAL_GUIDANCE')"
        aria-hidden="true"
        style="color: #43a047; padding-right: 5px"
      ></i>
      Reasons for Clinical Guidance
    </ng-template>
    <app-intake-clinical-guidance-reasoning
      [patient]="patient"
      [therapySession]="therapySession"
      [hasBPSAIntakeViewAccess]="hasBPSAIntakeViewAccess"
      [hasBPSAIntakeAddAccess]="hasBPSAIntakeAddAccess"
      [hasBPSAIntakeEditAccess]="hasBPSAIntakeEditAccess"
      (updatedTherapySession)="refreshPageEvent($event)"
    ></app-intake-clinical-guidance-reasoning>
  </mat-tab>
  <!-- <mat-tab>
    <ng-template mat-tab-label>
      <i class="fa fa-check-circle" *ngIf="isTaskCompleted('RELEASE_MEDICAL_DOCUMENTS')" aria-hidden="true"
        style="color: #43a047; padding-right: 5px"></i>
      Authorization To Release Medical Records
    </ng-template>
    <app-release-documents [patient]="patient" [therapySession]="therapySession"
      (updatedTherapySession)="refreshPageEvent($event)"></app-release-documents>
  </mat-tab> -->
  <mat-tab>
    <ng-template mat-tab-label>
      <i
        class="fa fa-check-circle"
        aria-hidden="true"
        style="color: #43a047; padding-right: 5px"
      ></i>
      Clinician
    </ng-template>
    <app-intake-assign-clinician
      [patient]="patient"
      [therapySession]="therapySession"
      [hasBPSAIntakeViewAccess]="hasBPSAIntakeViewAccess"
      [hasBPSAIntakeAddAccess]="hasBPSAIntakeAddAccess"
      [hasBPSAIntakeEditAccess]="hasBPSAIntakeEditAccess"
      (updatedTherapySession)="refreshPageEvent($event)"
    >
    </app-intake-assign-clinician>
  </mat-tab>
</mat-tab-group>
