import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  DataTablesModel,
  IReoccurringAssessmentRequest,
  ResponseModal,
} from '../../_models';
import { IAssessmentRequest } from '../../_models/assessments/assessmentrequest.model';
@Injectable({ providedIn: 'root' })
export class AssessmentRequestApiService {
  constructor(private httpClient: HttpClient) {}

  getAssessmentRequestsByPatientId(
    patientId: string,
    sessionId: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('patientId', patientId);
    params = params.append('pageSize', pageSize);
    params = params.append('pageIndex', pageIndex);
    if (sessionId) params = params.append('sessionId', sessionId);

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/assessmentrequestlist`,
      { params: params }
    );
  }

  getAssessmentRequestDetails(
    assessmentrequestId: string
  ): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/assessmentrequest/` + assessmentrequestId
    );
  }

  addAssessmentRequest(
    thisAssessmentRequest: IAssessmentRequest
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<IAssessmentRequest>(
        `${environment.apiUrl}/assessmentrequest`,
        thisAssessmentRequest,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  deleteAssessmentRequest(id: string): Observable<void> {
    return this.httpClient
      .delete<void>(`${environment.apiUrl}/assessmentrequest/` + id)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  addReoccurringAssessment(
    thisAssessmentRequest: IReoccurringAssessmentRequest
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<IReoccurringAssessmentRequest>(
        `${environment.apiUrl}/reoccurringassessmentrequest`,
        thisAssessmentRequest,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getReoccurringAssessmentRequestList(
    patientId: string,
    pageSize: number,
    pageIndex: number
  ) {
    let params = new HttpParams();
    params = params.append('patientId', patientId);
    params = params.append('pageSize', pageSize);
    params = params.append('pageIndex', pageIndex);
    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/getreoccurringassessmentrequestlist`,
      { params: params }
    );
  }

  deleteReoccuringAssessment(id: string, patientId: string) {
    let params = new HttpParams();
    params = params.append('reoccurringId', id);
    params = params.append('patientId', patientId);
    return this.httpClient.delete<void>(
      `${environment.apiUrl}/deletereoccurringassessment`,
      { params: params }
    );
  }
}
