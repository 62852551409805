import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { ISelfReportScale } from 'src/app/_models/assessments/adhd/selfreportscale.model';
import {
  SelfReportScaleApiService,
  ToastMessageService,
} from 'src/app/_services/';

@Component({
  selector: 'app-self-report-scale',
  templateUrl: './self-report-scale.component.html',
  styleUrls: ['./self-report-scale.component.css'],
})
export class SelfReportScaleComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public selfReportScaleApiService: SelfReportScaleApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) {}
  public partARisckScore: number;
  public partBRisckScore: number;
  public selfReportScaleForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  selfReportScaleAssessment: ISelfReportScale;
  selfReportScaleAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.selfReportScaleForm, this.errors);

    this.loadSelfReportScaleAssessment();
  }

  loadSelfReportScaleAssessment() {
    this.selfReportScaleForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.selfReportScaleApiService
        .getSelfReportScaleDetails(this.assessmentId)
        .subscribe((selfReportScaleAssessment) => {
          if (selfReportScaleAssessment.data) {
            this.selfReportScaleForm.controls['q1'].setValue(
              selfReportScaleAssessment.data.q1.answer
            );
            this.selfReportScaleForm.controls['q2'].setValue(
              selfReportScaleAssessment.data.q2.answer
            );
            this.selfReportScaleForm.controls['q3'].setValue(
              selfReportScaleAssessment.data.q3.answer
            );
            this.selfReportScaleForm.controls['q4'].setValue(
              selfReportScaleAssessment.data.q4.answer
            );
            this.selfReportScaleForm.controls['q5'].setValue(
              selfReportScaleAssessment.data.q5.answer
            );
            this.selfReportScaleForm.controls['q6'].setValue(
              selfReportScaleAssessment.data.q6.answer
            );
            this.selfReportScaleForm.controls['q7'].setValue(
              selfReportScaleAssessment.data.q7.answer
            );
            this.selfReportScaleForm.controls['q8'].setValue(
              selfReportScaleAssessment.data.q8.answer
            );
            this.selfReportScaleForm.controls['q9'].setValue(
              selfReportScaleAssessment.data.q9.answer
            );
            this.selfReportScaleForm.controls['q10'].setValue(
              selfReportScaleAssessment.data.q10.answer
            );
            this.selfReportScaleForm.controls['q11'].setValue(
              selfReportScaleAssessment.data.q11.answer
            );
            this.selfReportScaleForm.controls['q12'].setValue(
              selfReportScaleAssessment.data.q12.answer
            );
            this.selfReportScaleForm.controls['q13'].setValue(
              selfReportScaleAssessment.data.q13.answer
            );
            this.selfReportScaleForm.controls['q14'].setValue(
              selfReportScaleAssessment.data.q14.answer
            );
            this.selfReportScaleForm.controls['q15'].setValue(
              selfReportScaleAssessment.data.q15.answer
            );
            this.selfReportScaleForm.controls['q16'].setValue(
              selfReportScaleAssessment.data.q16.answer
            );
            this.selfReportScaleForm.controls['q17'].setValue(
              selfReportScaleAssessment.data.q17.answer
            );
            this.selfReportScaleForm.controls['q18'].setValue(
              selfReportScaleAssessment.data.q18.answer
            );

            this.partARisckScore =
              selfReportScaleAssessment.data.props['partA'];
            this.partBRisckScore =
              selfReportScaleAssessment.data.props['partB'];
          }
        });
    }
  }

  onFormSubmit() {
    if (this.selfReportScaleForm.invalid) {
      this.selfReportScaleForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.selfReportScaleAssessment = Object.assign(
      {},
      this.selfReportScaleAssessment
    );
    this.selfReportScaleAssessment.patientId =
      this.selfReportScaleForm.controls['patientId'].value;
    this.selfReportScaleAssessment.sessionId =
      this.selfReportScaleForm.controls['sessionId'].value;
    this.selfReportScaleAssessment.assessmentRequestId =
      this.selfReportScaleForm.controls['assessmentRequestId'].value;
    this.selfReportScaleAssessment.q1 = {
      answer: this.selfReportScaleForm.controls['q1'].value,
    };
    this.selfReportScaleAssessment.q2 = {
      answer: this.selfReportScaleForm.controls['q2'].value,
    };
    this.selfReportScaleAssessment.q3 = {
      answer: this.selfReportScaleForm.controls['q3'].value,
    };
    this.selfReportScaleAssessment.q4 = {
      answer: this.selfReportScaleForm.controls['q4'].value,
    };
    this.selfReportScaleAssessment.q5 = {
      answer: this.selfReportScaleForm.controls['q5'].value,
    };
    this.selfReportScaleAssessment.q6 = {
      answer: this.selfReportScaleForm.controls['q6'].value,
    };
    this.selfReportScaleAssessment.q7 = {
      answer: this.selfReportScaleForm.controls['q7'].value,
    };
    this.selfReportScaleAssessment.q8 = {
      answer: this.selfReportScaleForm.controls['q8'].value,
    };
    this.selfReportScaleAssessment.q9 = {
      answer: this.selfReportScaleForm.controls['q9'].value,
    };
    this.selfReportScaleAssessment.q10 = {
      answer: this.selfReportScaleForm.controls['q10'].value,
    };
    this.selfReportScaleAssessment.q11 = {
      answer: this.selfReportScaleForm.controls['q11'].value,
    };
    this.selfReportScaleAssessment.q12 = {
      answer: this.selfReportScaleForm.controls['q12'].value,
    };
    this.selfReportScaleAssessment.q13 = {
      answer: this.selfReportScaleForm.controls['q13'].value,
    };
    this.selfReportScaleAssessment.q14 = {
      answer: this.selfReportScaleForm.controls['q14'].value,
    };
    this.selfReportScaleAssessment.q15 = {
      answer: this.selfReportScaleForm.controls['q15'].value,
    };
    this.selfReportScaleAssessment.q16 = {
      answer: this.selfReportScaleForm.controls['q16'].value,
    };
    this.selfReportScaleAssessment.q17 = {
      answer: this.selfReportScaleForm.controls['q17'].value,
    };
    this.selfReportScaleAssessment.q18 = {
      answer: this.selfReportScaleForm.controls['q18'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.selfReportScaleAssessment.accessCode = this.accessCode;

      this.selfReportScaleApiService
        .addGuestSelfReportScale(this.selfReportScaleAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Self Report Scale'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Self Report Scale'
            );
          }
        );
    } else {
      this.selfReportScaleApiService
        .addSelfReportScale(this.selfReportScaleAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Self Report Scale'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Self Report Scale'
            );
          }
        );
    }
  }

  public validation_messages = {
    q1: [
      {
        type: 'required',
        message:
          '1. How often do you have trouble wrapping up the final details of a project, once the challenging parts have been done? is required',
      },
    ],
    q2: [
      {
        type: 'required',
        message:
          '2. How often do you have difficulty getting things in order when you have to do a task that requires organization? is required',
      },
    ],
    q3: [
      {
        type: 'required',
        message:
          '3. How often do you have problems remembering appointments or obligations? is required',
      },
    ],
    q4: [
      {
        type: 'required',
        message:
          '4. When you have a task that requires a lot of thought, how often do you avoid or delay getting started? is required',
      },
    ],
    q5: [
      {
        type: 'required',
        message:
          '5. How often do you fidget or squirm with your hands or feet when you have to sit down for a long time? is required',
      },
    ],
    q6: [
      {
        type: 'required',
        message:
          '6. How often do you feel overly active and compelled to do things, like you were driven by a motor? is required',
      },
    ],
    q7: [
      {
        type: 'required',
        message:
          '7. How often do you make careless mistakes when you have to work on a boring or difficult project? is required',
      },
    ],
    q8: [
      {
        type: 'required',
        message:
          '8. How often do you have difficulty keeping your attention when you are doing boring or repetitive work? is required',
      },
    ],
    q9: [
      {
        type: 'required',
        message:
          '9. How often do you have difficulty concentrating on what people say to you, even when they are speaking to you directly? is required',
      },
    ],
    q10: [
      {
        type: 'required',
        message:
          '10. How often do you misplace or have difficulty finding things at home or at work? is required',
      },
    ],
    q11: [
      {
        type: 'required',
        message:
          '11. How often are you distracted by activity or noise around you? is required',
      },
    ],
    q12: [
      {
        type: 'required',
        message:
          '12. How often do you leave your seat in meetings or other situations in which you are expected to remain seated? is required',
      },
    ],
    q13: [
      {
        type: 'required',
        message: '13. How often do you feel restless or fidgety? is required',
      },
    ],
    q14: [
      {
        type: 'required',
        message:
          '14. How often do you have difficulty unwinding and relaxing when you have time to yourself? is required',
      },
    ],
    q15: [
      {
        type: 'required',
        message:
          '15. How often do you find yourself talking too much when you are in social situations? is required',
      },
    ],
    q16: [
      {
        type: 'required',
        message:
          '16. When you’re in a conversation, how often do you find yourself finishing the sentences of the people you are talking to, before they can finish them themselves? is required',
      },
    ],
    q17: [
      {
        type: 'required',
        message:
          '17. How often do you have difficulty waiting your turn in situations when turn taking is required? is required',
      },
    ],
    q18: [
      {
        type: 'required',
        message:
          '18. How often do you interrupt others when they are busy? is required',
      },
    ],
  };

  private buildForm() {
    this.selfReportScaleForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      q1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q2: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q3: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q4: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q5: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q6: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q7: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q8: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q9: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q10: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q11: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q12: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q13: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q14: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q15: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q16: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q17: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q18: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.selfReportScaleForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.selfReportScaleForm) {
      return;
    }
    const form = this.selfReportScaleForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
