<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Session Rating Scale (SRS)</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="col-md-12">
  <form
    [formGroup]="srsAssessmentForm"
    novalidate
    (ngSubmit)="onSrsFormSubmit()"
    class="form-horizontal"
  >
    <mat-card appearance="raised" class="mat-elevation-z8" style="margin: 15px">
      <label class="labelBold" style="text-align: center">Relationship</label>
      <div class="row">
        <div class="col-md-2">
          <label>I did not feel heard, understood, and respected.</label>
        </div>
        <div class="col-sm-8">
          <mat-slider
            [max]="max"
            [min]="min"
            [step]="step"
            [thumbLabel]="thumbLabel"
            style="width: 100%"
            ><input matSliderThumb formControlName="relationshipRating" />
          </mat-slider>
        </div>
        <div class="col-md-2">
          <label>I felt heard, understood, and respected.</label>
        </div>
      </div>
    </mat-card>

    <mat-card appearance="raised" class="mat-elevation-z8" style="margin: 15px">
      <label class="labelBold" style="text-align: center">
        Goals and Topics
      </label>
      <div class="row">
        <div class="col-md-2">
          <label
            >We did not work on or talk about what I wanted to work on and talk
            about.</label
          >
        </div>
        <div class="col-sm-8">
          <mat-slider
            [max]="max"
            [min]="min"
            [step]="step"
            [thumbLabel]="thumbLabel"
            style="width: 100%"
            ><input matSliderThumb formControlName="goalsAndTopicsRating" />
          </mat-slider>
        </div>
        <div class="col-md-2">
          <label
            >We worked on and talked about what I wanted to work on and talk
            about.</label
          >
        </div>
      </div>
    </mat-card>

    <mat-card appearance="raised" class="mat-elevation-z8" style="margin: 15px">
      <label class="labelBold" style="text-align: center"
        >Approach or Method</label
      >
      <div class="row">
        <div class="col-md-2">
          <label>The therapist's approach is not a good fit for me.</label>
        </div>
        <div class="col-sm-8">
          <mat-slider
            [max]="max"
            [min]="min"
            [step]="step"
            [thumbLabel]="thumbLabel"
            style="width: 100%"
            ><input matSliderThumb formControlName="approachRating" />
          </mat-slider>
        </div>
        <div class="col-md-2">
          <label>The therapist's approach is a good fit for me.</label>
        </div>
      </div>
    </mat-card>

    <mat-card appearance="raised" class="mat-elevation-z8" style="margin: 15px">
      <label class="labelBold" style="text-align: center">Overall</label>
      <div class="row">
        <div class="col-md-2">
          <label>There was something missing in the session today.</label>
        </div>
        <div class="col-sm-8">
          <mat-slider
            [max]="max"
            [min]="min"
            [step]="step"
            [thumbLabel]="thumbLabel"
            style="width: 100%"
            ><input matSliderThumb formControlName="overallRating" />
          </mat-slider>
        </div>
        <div class="col-md-2">
          <label>Overall, today's session was right for me.</label>
        </div>
      </div>
    </mat-card>

    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        color="primary"
        mat-raised-button
        type="submit"
        [disabled]="!srsAssessmentForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
