import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { IPatientHealthQuestionnaire } from 'src/app/_models/assessments/depression/patienthealthquestionnaire.model';
import {
  PatientHealthQuestionnaireApiService,
  ToastMessageService,
} from 'src/app/_services/';

@Component({
  selector: 'app-patient-health-questionnaire-dialog',
  templateUrl: './patient-health-questionnaire-dialog.component.html',
  styleUrls: ['./patient-health-questionnaire-dialog.component.css'],
})
export class PatientHealthQuestionnaireDialogComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public patienthealthquestionnaireApiService: PatientHealthQuestionnaireApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) {}
  public riskScore: number;
  public patienthealthquestionnaireForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  patienthealthquestionnaire: IPatientHealthQuestionnaire;
  patienthealthquestionnaireError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(
      this.patienthealthquestionnaireForm,
      this.errors
    );

    this.loadPatientHealthQuestionnaire();
  }

  loadPatientHealthQuestionnaire() {
    this.patienthealthquestionnaireForm.controls['patientId'].setValue(
      this.patientId
    );

    if (this.assessmentId) {
      this.patienthealthquestionnaireApiService
        .getPatientHealthQuestionnaireDetails(this.assessmentId)
        .subscribe((patienthealthquestionnaire) => {
          if (patienthealthquestionnaire.data) {
            this.patienthealthquestionnaireForm.controls[
              'littleInterest'
            ].setValue(patienthealthquestionnaire.data.littleInterest.answer);
            this.patienthealthquestionnaireForm.controls[
              'feelingDown'
            ].setValue(patienthealthquestionnaire.data.feelingDown.answer);
            this.patienthealthquestionnaireForm.controls[
              'troubleSleeping'
            ].setValue(patienthealthquestionnaire.data.troubleSleeping.answer);
            this.patienthealthquestionnaireForm.controls['feelTired'].setValue(
              patienthealthquestionnaire.data.feelTired.answer
            );
            this.patienthealthquestionnaireForm.controls[
              'poorAppetite'
            ].setValue(patienthealthquestionnaire.data.poorAppetite.answer);
            this.patienthealthquestionnaireForm.controls[
              'feelingBadAboutSelf'
            ].setValue(
              patienthealthquestionnaire.data.feelingBadAboutSelf.answer
            );
            this.patienthealthquestionnaireForm.controls[
              'troubleConcentrating'
            ].setValue(
              patienthealthquestionnaire.data.troubleConcentrating.answer
            );
            this.patienthealthquestionnaireForm.controls[
              'slowComparedToOthers'
            ].setValue(
              patienthealthquestionnaire.data.slowComparedToOthers.answer
            );
            this.patienthealthquestionnaireForm.controls[
              'badThoughts'
            ].setValue(patienthealthquestionnaire.data.badThoughts.answer);

            this.riskScore = patienthealthquestionnaire.data.riskScore;
          }
        });
    }
  }
  onPatientHealthQuestionnaireFormSubmit() {
    if (this.patienthealthquestionnaireForm.invalid) {
      this.patienthealthquestionnaireForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.patienthealthquestionnaire = Object.assign(
      {},
      this.patienthealthquestionnaire
    );
    this.patienthealthquestionnaire.patientId =
      this.patienthealthquestionnaireForm.controls['patientId'].value;
    this.patienthealthquestionnaire.sessionId =
      this.patienthealthquestionnaireForm.controls['sessionId'].value;
    this.patienthealthquestionnaire.assessmentRequestId =
      this.patienthealthquestionnaireForm.controls['assessmentRequestId'].value;
    this.patienthealthquestionnaire.littleInterest = {
      answer:
        this.patienthealthquestionnaireForm.controls['littleInterest'].value,
    };
    this.patienthealthquestionnaire.feelingDown = {
      answer: this.patienthealthquestionnaireForm.controls['feelingDown'].value,
    };
    this.patienthealthquestionnaire.troubleSleeping = {
      answer:
        this.patienthealthquestionnaireForm.controls['troubleSleeping'].value,
    };
    this.patienthealthquestionnaire.feelTired = {
      answer: this.patienthealthquestionnaireForm.controls['feelTired'].value,
    };
    this.patienthealthquestionnaire.poorAppetite = {
      answer:
        this.patienthealthquestionnaireForm.controls['poorAppetite'].value,
    };
    this.patienthealthquestionnaire.feelingBadAboutSelf = {
      answer:
        this.patienthealthquestionnaireForm.controls['feelingBadAboutSelf']
          .value,
    };
    this.patienthealthquestionnaire.troubleConcentrating = {
      answer:
        this.patienthealthquestionnaireForm.controls['troubleConcentrating']
          .value,
    };
    this.patienthealthquestionnaire.slowComparedToOthers = {
      answer:
        this.patienthealthquestionnaireForm.controls['slowComparedToOthers']
          .value,
    };
    this.patienthealthquestionnaire.badThoughts = {
      answer: this.patienthealthquestionnaireForm.controls['badThoughts'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.patienthealthquestionnaire.accessCode = this.accessCode;

      this.patienthealthquestionnaireApiService
        .addGuestPatientHealthQuestionnaire(this.patienthealthquestionnaire)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Patient Health Questionnaire'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Patient Health Questionnaire'
            );
          }
        );
    } else {
      this.patienthealthquestionnaireApiService
        .addPatientHealthQuestionnaire(this.patienthealthquestionnaire)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Patient Health Questionnaire'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Patient Health Questionnaire'
            );
          }
        );
    }
  }

  public validation_messages = {
    littleInterest: [
      {
        type: 'required',
        message: 'I have felt tense, anxious or nervous is required',
      },
    ],
    feelingDown: [
      {
        type: 'required',
        message:
          'I have felt I have someone to turn to for support when needed is required',
      },
    ],
    troubleSleeping: [
      {
        type: 'required',
        message: 'I have felt able to cope when things go wrong is required',
      },
    ],
    feelTired: [
      {
        type: 'required',
        message: 'Talking to people has felt too much for me is required',
      },
    ],
    poorAppetite: [
      { type: 'required', message: 'I have felt panic or terror is required' },
    ],
    feelingBadAboutSelf: [
      { type: 'required', message: 'I made plans to end my life is required' },
    ],
    troubleConcentrating: [
      {
        type: 'required',
        message:
          'I have had difficulty getting to sleep or staying asleep is required',
      },
    ],
    slowComparedToOthers: [
      {
        type: 'required',
        message: 'I have felt despairing or hopeless is required',
      },
    ],
    badThoughts: [
      { type: 'required', message: 'I have felt unhappy is required' },
    ],
  };

  private buildForm() {
    this.patienthealthquestionnaireForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      littleInterest: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      feelingDown: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      troubleSleeping: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      feelTired: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      poorAppetite: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      feelingBadAboutSelf: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      troubleConcentrating: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      slowComparedToOthers: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      badThoughts: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
    });
    this.patienthealthquestionnaireForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.patienthealthquestionnaireForm) {
      return;
    }
    const form = this.patienthealthquestionnaireForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
