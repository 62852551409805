import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ResponseModal } from '../_models';
import { LocalStorageService } from './local-storage.service';
@Injectable({ providedIn: 'root' })
export class OrgConfigApiService {
  constructor(
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService,
    private url: LocationStrategy
  ) { }

  getOrgConfigDetails(): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/orgconfig`
    );
  }

  getPhysicianOrgConfigDetails(): Observable<ResponseModal> {
    let precedence = '/main/member/';
    let successor1 = '/NOTES';
    let successor2 = '/CARE';
    let successor = null;
    let patientId = null;

    if (this.url.path().indexOf(successor1) > -1) {
      successor = successor1;
    }
    if (this.url.path().indexOf(successor2) > -1) {
      successor = successor2;
    }

    if (successor) {
      patientId = this.url
        .path()
        .substring(precedence.length, this.url.path().indexOf(successor));
    }

    let params = new HttpParams();
    if (patientId) params = params.append('patientId', patientId);

    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/orgconfig`,
      { params: params }
    );
  }
}
