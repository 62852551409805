<div class="container">
    <div class="dialogTitle">
        <p matDialogTitle *ngIf='action === "ADD"'>Add LabOrder</p>
        <p matDialogTitle *ngIf='action === "EDIT"'>Edit LabOrder</p>
        <button mat-mini-fab style="background-color: transparent" aria-label="close dialog" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <form [formGroup]="laborderForm" novalidate (ngSubmit)="onLabOrderFormSubmit()">
        <div class="row">
            <div class="col-md-12">
                <div class="row pad10">
                    <label class="col-md-12 asterix-after">Test Type</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label class="labelText">Test Date<span style="color: red">*</span></label>
                <mat-form-field appearance="outline">
                    <input formControlName="testDate" matInput [matDatepicker]="testDatePicker" autoSlashDate
                        placeholder="date">
                    <mat-datepicker-toggle matSuffix [for]="testDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #testDatePicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <label class="labelText">Fasting<span style="color: red">*</span></label>
                <mat-form-field appearance="outline">
                    <input matInput placeholder="Fasting" formControlName="fasting" name="fasting" required>
                    <mat-error *ngFor="let validation of validation_messages.fasting">
                        <mat-error class="error-message"
                            *ngIf="laborderForm.get('fasting').hasError(validation.type) && (laborderForm.get('fasting').dirty || laborderForm.get('fasting').touched)">{{validation.message}}</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label class="labelText">Member First Name<span style="color: red">*</span></label>
                <mat-form-field appearance="outline">
                    <input matInput placeholder="Member First Name" formControlName="patientFirstName"
                        name="patientFirstName" required>
                    <mat-error *ngFor="let validation of validation_messages.patientFirstName">
                        <mat-error class="error-message"
                            *ngIf="laborderForm.get('patientFirstName').hasError(validation.type) && (laborderForm.get('patientFirstName').dirty || laborderForm.get('patientFirstName').touched)">{{validation.message}}</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <label class="labelText">Member Last Name<span style="color: red">*</span></label>
                <mat-form-field appearance="outline">
                    <input matInput placeholder="Member Last Name" formControlName="patientLastName"
                        name="patientLastName" required>
                    <mat-error *ngFor="let validation of validation_messages.patientLastName">
                        <mat-error class="error-message"
                            *ngIf="laborderForm.get('patientLastName').hasError(validation.type) && (laborderForm.get('patientLastName').dirty || laborderForm.get('patientLastName').touched)">{{validation.message}}</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label class="labelText">Date Of Birth<span style="color: red">*</span></label>
                <mat-form-field appearance="outline">
                    <input formControlName="patientDob" matInput [matDatepicker]="patientDobPicker" autoSlashDate
                        placeholder="date">
                    <mat-datepicker-toggle matSuffix [for]="patientDobPicker"></mat-datepicker-toggle>
                    <mat-datepicker #patientDobPicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <label class="labelText">Member Email<span style="color: red">*</span></label>
                <mat-form-field appearance="outline">
                    <input matInput placeholder="Member Email" formControlName="patientEmail" name="patientEmail"
                        required>
                    <mat-error *ngFor="let validation of validation_messages.patientEmail">
                        <mat-error class="error-message"
                            *ngIf="laborderForm.get('patientEmail').hasError(validation.type) && (laborderForm.get('patientEmail').dirty || laborderForm.get('patientEmail').touched)">{{validation.message}}</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label class="labelText">Member Phone<span style="color: red">*</span></label>
                <mat-form-field appearance="outline">
                    <input matInput placeholder="Member Phone" formControlName="patientPhone" name="patientPhone"
                        required>
                    <mat-error *ngFor="let validation of validation_messages.patientPhone">
                        <mat-error class="error-message"
                            *ngIf="laborderForm.get('patientPhone').hasError(validation.type) && (laborderForm.get('patientPhone').dirty || laborderForm.get('patientPhone').touched)">{{validation.message}}</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <label class="labelText">Member Address Line 1<span style="color: red">*</span></label>
                <mat-form-field appearance="outline">
                    <input matInput placeholder="Patient Address Line 1" formControlName="patientAddressLine1"
                        name="patientAddressLine1" required>
                    <mat-error *ngFor="let validation of validation_messages.patientAddressLine1">
                        <mat-error class="error-message"
                            *ngIf="laborderForm.get('patientAddressLine1').hasError(validation.type) && (laborderForm.get('patientAddressLine1').dirty || laborderForm.get('patientAddressLine1').touched)">{{validation.message}}</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label class="labelText">Member Address Line 2</label>
                <mat-form-field appearance="outline">
                    <input matInput placeholder="Patient Address Line 2" formControlName="patientAddressLine2"
                        name="patientAddressLine2">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <label class="labelText">Member City<span style="color: red">*</span></label>
                <mat-form-field appearance="outline">
                    <input matInput placeholder="Member City" formControlName="patientCity" name="patientCity"
                        required>
                    <mat-error *ngFor="let validation of validation_messages.patientCity">
                        <mat-error class="error-message"
                            *ngIf="laborderForm.get('patientCity').hasError(validation.type) && (laborderForm.get('patientCity').dirty || laborderForm.get('patientCity').touched)">{{validation.message}}</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label class="labelText">Member State<span style="color: red">*</span></label>
                <mat-form-field appearance="outline">
                    <input matInput placeholder="Member State" formControlName="patientState" name="patientState"
                        required>
                    <mat-error *ngFor="let validation of validation_messages.patientState">
                        <mat-error class="error-message"
                            *ngIf="laborderForm.get('patientState').hasError(validation.type) && (laborderForm.get('patientState').dirty || laborderForm.get('patientState').touched)">{{validation.message}}</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <label class="labelText">Member Zip<span style="color: red">*</span></label>
                <mat-form-field appearance="outline">
                    <input matInput placeholder="Member Zip" formControlName="patientZip" name="patientZip" required>
                    <mat-error *ngFor="let validation of validation_messages.patientZip">
                        <mat-error class="error-message"
                            *ngIf="laborderForm.get('patientZip').hasError(validation.type) && (laborderForm.get('patientZip').dirty || laborderForm.get('patientZip').touched)">{{validation.message}}</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <mat-dialog-actions align="end">
            <button class="btn btn-primary pull-right" mat-raised-button type="submit"
                [disabled]="!laborderForm.valid || processing">
                Submit
            </button>
        </mat-dialog-actions>
    </form>

</div>