import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CareLog, Case } from 'src/app/_models';
import { ToastMessageService } from 'src/app/_services';
import { CareLogHttpService } from 'src/app/_services/case/carelog.service';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import {
  formatDate,
  formatDateTime,
  mmddyyyyhhmiToDate,
} from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-carelog-sleep-record',
  templateUrl: './carelog-sleep-record.component.html',
  styleUrls: ['./carelog-sleep-record.component.css'],
})
export class CarelogSleepRecordComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() action: string;
  @Input() patientId: string;
  @Input() case: Case;
  @Input() logDate: Date;
  @Input() shift: number;
  @Input() carelog: CareLog;
  @Input() goals: any[];
  @Input() goalDrivenCare: boolean;
  @Output() taskCompleted = new EventEmitter<any>();

  todaysDate = new Date();

  constructor(
    public careLogHttpService: CareLogHttpService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private toastMessageService: ToastMessageService
  ) {}

  public sleepLogForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  errors: any = {};
  isProcessing: boolean = false;

  ngOnInit(): void {
    this.buildForm();
    if (this.carelog && this.carelog.activityDateTime) {
      this.sleepLogForm.controls['activityDateTime'].setValue(
        mmddyyyyhhmiToDate(this.carelog.activityDateTime)
      );
      this.markTimeSelected(this.carelog.sleepTimes);
    } else {
      this.sleepLogForm.controls['activityDateTime'].setValue(
        new Date(this.logDate)
      );
    }

    this.errorHandler.handleErrors(this.sleepLogForm, this.errors);
  }

  private buildForm() {
    this.sleepLogForm = this.formBuilder.group({
      activityDateTime: new FormControl(null, Validators.required),
    });
  }

  markTimeSelected(times) {
    if (times && times.length > 0) {
      times.forEach((time) => {
        let currentIndex = this.timeArray.findIndex((i) => i.value == time);
        this.timeArray[currentIndex].selected = true;
      });
    }
  }

  isAtleastOneSlotSelected(): boolean {
    let oneSelected = false;
    this.timeArray.forEach((time) => {
      if (time.selected) {
        oneSelected = true;
      }
    });
    return oneSelected;
  }

  public updateSelectedTimeSlots() {
    let selectedTimeSlots = [];
    for (let i = 0; i < this.timeArray.length; i++) {
      if (this.timeArray[i].selected) {
        selectedTimeSlots.push(this.timeArray[i].value);
      }
    }

    if (selectedTimeSlots.length == 0) {
      return;
    }

    if (this.carelog) {
      // Update existing one
      this.isProcessing = true;
      this.carelog.sleepTimes = selectedTimeSlots;
      this.carelog.activityDateTime = formatDateTime(
        this.sleepLogForm.controls['activityDateTime'].value
      );

      if (this.action === 'ADD') {
        this.carelog.vitalInfoAvailable = false;
        this.carelog.patientId = this.case.patientId;
        this.carelog.message = 'Sleep Times';

        this.carelog.vitalInfoAvailable = false;
        this.addCareLog(this.carelog);
      } else {
        this.careLogHttpService
          .editCareLog(this.case.id, this.carelog)
          .subscribe(
            () => {
              this.isProcessing = false;
              let outputResponse = { note: this.carelog, response: 'success' };
              this.taskCompleted.emit(outputResponse);
              this.toastMessageService.displaySuccessMessage(
                'Sleep time log has been updated'
              );
            },
            (error) => {
              this.isProcessing = false;
              this.toastMessageService.displayErrorMessage(
                'Failed to update sleep time log'
              );
            }
          );
      }
    } else {
      // Add a new one
      this.carelog = Object.assign({}, this.carelog);
      this.carelog.vitalInfoAvailable = false;
      this.carelog.patientId = this.case.patientId;
      this.carelog.message = 'Sleep Times';

      this.carelog.sleepTimes = selectedTimeSlots;
      this.carelog.activityDateTime = formatDateTime(
        this.sleepLogForm.controls['activityDateTime'].value
      );
      this.carelog.vitalInfoAvailable = false;
      this.addCareLog(this.carelog);
    }
  }

  public addCareLog(carelog: CareLog) {
    this.isProcessing = true;
    carelog.caseId = this.case.id;
    carelog.logDate = formatDate(this.logDate);
    carelog.id = null;
    carelog.shift = this.shift;
    carelog.patientId = this.case.patientId;

    this.careLogHttpService.addCareLog(this.case.id, carelog).subscribe(() => {
      this.toastMessageService.displaySuccessMessage(
        'Sleep time log has been updated'
      );
      this.isProcessing = false;
      this.taskCompleted.emit(true);
    });
  }

  timeArray: any = [
    { value: '2400', text: '12 AM', selected: false },
    { value: '100', text: '1 AM', selected: false },
    { value: '200', text: '2 AM', selected: false },
    { value: '300', text: '3 AM', selected: false },
    { value: '400', text: '4 AM', selected: false },
    { value: '500', text: '5 AM', selected: false },
    { value: '600', text: '6 AM', selected: false },
    { value: '700', text: '7 AM', selected: false },
    { value: '800', text: '8 AM', selected: false },
    { value: '900', text: '9 AM', selected: false },
    { value: '1000', text: '10 AM', selected: false },
    { value: '1100', text: '11 AM', selected: false },
    { value: '1200', text: '12 PM', selected: false },
    { value: '1300', text: '1 PM', selected: false },
    { value: '1400', text: '2 PM', selected: false },
    { value: '1500', text: '3 PM', selected: false },
    { value: '1600', text: '4 PM', selected: false },
    { value: '1700', text: '5 PM', selected: false },
    { value: '1800', text: '6 PM', selected: false },
    { value: '1900', text: '7 PM', selected: false },
    { value: '2000', text: '8 PM', selected: false },
    { value: '2100', text: '9 PM', selected: false },
    { value: '2200', text: '10 PM', selected: false },
    { value: '2300', text: '11 PM', selected: false },
  ];
}
