<!-- Appointment Section -->
<mat-card
  appearance="raised"
  style="border-radius: 15px"
  class="mat-elevation-z8"
>
  <p style="font-weight: bold; font-size: 20px">
    My Upcoming Virtual Appointments
  </p>

  <!-- Virtual Appointment Section -->
  <ngx-spinner
    *ngIf="isProcessing"
    bdColor="#fff"
    size="medium"
    color="paleturquoise"
    type="timer"
    style="position: relative; display: contents"
    [fullScreen]="false"
  ></ngx-spinner>

  <div *ngIf="!isProcessing">
    <mat-error
      *ngIf="!data?.total || data?.total == 0"
      style="text-align: center"
    >
      No Tele-Health Appointment
    </mat-error>
    <div
      *ngIf="data && data?.total > 0"
      style="max-height: 600px; overflow-x: hidden"
    >
      <div *ngFor="let appointments of data.items">
        <mat-card
          appearance="raised"
          class="mat-elevation-z8"
          style="
            border-radius: 15px;
            margin: 10px;
            display: flex;
            flex-direction: column;
          "
        >
          <span style="font-weight: 700; opacity: 0.8">
            Provider: {{ appointments.physicianLastName }},
            {{ appointments.physicianFirstName }}
          </span>
          <span style="font-weight: 500; opacity: 0.8; font-size: 14px">
            {{ appointments.start | date : 'M/d/y, h:mm a' : currentTimezone }}
            {{ currentTimezone }}
          </span>

          <div
            style="
              margin-top: 5px;
              border-top: 1px solid gray;
              text-align: center;
            "
          >
            <button
              mat-button
              color="warn"
              [disabled]="!isJoinValid(appointments) || guestURLProcessing"
              (click)="JoinMeeting(appointments.id)"
            >
              Join
            </button>
          </div>
        </mat-card>
      </div>
      <!-- Paginator -->
      <mat-paginator
        [length]="data.total"
        [pageSize]="data.per_page"
        [showFirstLastButtons]="true"
        *ngIf="data.total > data.per_page"
        (page)="getNextVirtual($event)"
      >
      </mat-paginator>
    </div>
  </div>
</mat-card>
