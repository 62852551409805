import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Editor, schema, toDoc, toHTML, Toolbar } from 'ngx-editor';
import { ITherapySession } from 'src/app/_models';
import {
  DateTimeZoneService,
  TherapySessionService,
  ToastMessageService,
} from 'src/app/_services';
import { USERSETTINGSPROPS } from 'src/app/shared/constants/userSettings';
import { PermissionsSandbox } from 'src/app/shared/sandbox/permissions.sandbox';
import { UserSettingsPropsSandbox } from 'src/app/shared/sandbox/user-settings-props.sandbox';
import { SessionAddClinicalNoteComponent } from '../../sessions/session-notes/session-add-clinical-note/session-add-clinical-note.component';
import { LoadExistingClinicalNoteComponent } from '../../sessions/session-notes/load-existing-clinical-note/load-existing-clinical-note.component';

@Component({
  selector: 'app-group-level-notes',
  templateUrl: './group-level-notes.component.html',
  styleUrl: './group-level-notes.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class GroupLevelNotesComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Input() sessionDetails: ITherapySession;
  @Input() hasClinicalNotesAddAccess: boolean;

  updatedSessionDetailsResponse: any;
  @Output() refreshTherapyObject = new EventEmitter<any>();

  isLoadingResults: boolean = false;
  apiError: boolean = false;
  processing: boolean = false;

  currentTimezone: string = '';

  // Sandbox and font size
  sandboxFavList: any = [];
  editorFontSize: number = 14;

  //NGX Editor
  clinicalNoteForm: FormGroup;
  editor: Editor[] = [];
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['align_left', 'align_center', 'align_right'],
    ['horizontal_rule'],
  ];
  isLoadingNGXEditors: boolean = false;
  selectedSectionId: string;
  selectedSectionIndex: number;
  selectedSectionName: string;

  // Shortcuts opener
  activeTab: string = 'Text Shortcuts';
  hasChanges: boolean = false;
  autosaved: boolean = false;
  tabPanelOpen: boolean = false;
  hasChangesMap: { [key: string]: boolean } = {};
  hadChangesEarlier: { [key: string]: boolean } = {};
  changeTimeout: any;

  constructor(
    public router: Router,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private dateTimeZoneService: DateTimeZoneService,
    public therapysessionApiService: TherapySessionService,
    protected permissionsSandbox: PermissionsSandbox,
    private userSettingsPropsSandbox: UserSettingsPropsSandbox,
    private toastMessageService: ToastMessageService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit(): void {
    // Subcribe to user settings for font size
    this.userSettingsPropsSandbox.userSettingsProps$.subscribe(
      (userSettings) => {
        if (userSettings) {
          if (userSettings[USERSETTINGSPROPS.CLINICAL_NOTE_FONT]) {
            this.editorFontSize = Number(
              userSettings[USERSETTINGSPROPS.CLINICAL_NOTE_FONT]
            );
            // Re trigger font size
            this.handleFontSizeChange('Re-Trigger');
          }
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.destroyAllNGXEditors();
    if (this.updatedSessionDetailsResponse) {
      this.refreshTherapyObject.emit(this.updatedSessionDetailsResponse);
    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.sessionDetails) {
      this.clinicalNoteForm = this.formBuilder.group({});

      if (this.sessionDetails && this.sessionDetails?.clinicalNoteId) {
        // Load the ngx editors
        await this.destroyAllNGXEditors();
        // Now load new NGX Editor
        await this.loadNGXEditor();
      }

      // Clinical note disable component after the note is closed
      if (this.sessionDetails.status === 'Active') {
        this.clinicalNoteForm.enable();
      } else {
        this.clinicalNoteForm.disable();
      }
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.handleFontSizeChange('Re-Trigger');
    }, 400);
  }

  // Add new group note type
  addNewGroupNote() {
    let dialogRef = this.dialog.open(SessionAddClinicalNoteComponent, {
      data: { therapySession: this.sessionDetails, type: 'Group' },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });
    dialogRef.afterClosed().subscribe(async (response) => {
      if (response.type === 'success') {
        // Reload therapy object
        this.refreshTherapyObject.emit(response);
        // this.reloadSessionDetailsFromResponse(response);
        // Detroy all existing ngx editors and create new ones
        await this.destroyAllNGXEditors();
        // Now load new NGX Editor
        await this.loadNGXEditor();
      }
    });
  }

  // Change Editor click section
  editorClicked(event, sectionId, sectionName, index) {
    this.selectedSectionId = sectionId;
    this.selectedSectionName = sectionName;
    this.selectedSectionIndex = index;
  }

  async loadNGXEditor() {
    this.isLoadingNGXEditors = true;

    if (this.sessionDetails?.sessionNotes?.sections) {
      await this.sessionDetails.sessionNotes.sections.forEach((section, i) => {
        this.editor.push(new Editor());
        // Now check if this section already has data
        if (section.payload) {
          let ngxEditorData = section.payload;
          let htmlNGXData = toHTML(ngxEditorData);
          // Add form control with existing value
          this.clinicalNoteForm.addControl(
            section.id,
            this.formBuilder.control(htmlNGXData, Validators.required)
          );
        } else {
          // Add a empty form control
          this.clinicalNoteForm.addControl(
            section.id,
            this.formBuilder.control('', Validators.required)
          );
        }
        this.isLoadingNGXEditors = false;
      });
    }

    // Retrigger font -size
    this.handleFontSizeChange('Re-Trigger');
  }

  //  Side Panel For Shortcuts and Templates
  openTabPanel(
    type: string,
    sectionId: string,
    sectionName: string,
    index: number
  ) {
    this.tabPanelOpen = true;
    this.selectedSectionId = sectionId;
    this.selectedSectionName = sectionName;
    this.selectedSectionIndex = index;
    this.activeTab = type;
  }

  closePanel() {
    this.tabPanelOpen = false;
    this.selectedSectionId = null;
    this.selectedSectionName = null;
    this.selectedSectionIndex = null;
    this.activeTab = null;
  }

  handleShortCutResponse(shortcut) {
    var currentValue =
      this.clinicalNoteForm.controls[this.selectedSectionId].value;

    // If there is existing value add these in a new line
    if (currentValue) {
      this.clinicalNoteForm.controls[this.selectedSectionId].setValue(
        currentValue + '<p/>' + toHTML(shortcut.content)
      );
    } else {
      this.clinicalNoteForm.controls[this.selectedSectionId].setValue(
        toHTML(shortcut.content)
      );
    }
  }

  handleTemplateResponse(response) {
    var currentValue =
      this.clinicalNoteForm.controls[this.selectedSectionId].value;

    // If there is existing value add these in a new line
    if (currentValue) {
      this.clinicalNoteForm.controls[this.selectedSectionId].setValue(
        currentValue + '<p/>' + response
      );
    } else {
      this.clinicalNoteForm.controls[this.selectedSectionId].setValue(response);
    }
  }

  async destroyAllNGXEditors() {
    this.clinicalNoteForm.reset();
    // Detroy all ngx editor and its contents
    if (this.editor) {
      this.editor.forEach((editor) => {
        editor.destroy();
      });
      // Reset editor to be empty
      this.editor = [];
    }
  }

  // Handle font size
  // Handling of font size for ngx editor
  async handleFontSizeChange(type) {
    const editorContents = document.querySelectorAll(
      '.NgxEditor__Content'
    ) as NodeListOf<HTMLElement>;
    // Loop through all the selected elements and apply the changes
    // Adjust font size based on the action
    if (type === 'increase') {
      this.editorFontSize += 1;
    } else if (type === 'decrease') {
      this.editorFontSize -= 1;
    }
    // Optionally, keep the font size within reasonable limits
    if (this.editorFontSize < 10) {
      this.editorFontSize = 10; // Min size
    } else if (this.editorFontSize > 30) {
      this.editorFontSize = 30; // Max size
    }
    // Dispatch the even to store this font size
    if (type != 'Re-Trigger') {
      this.userSettingsPropsSandbox.handleUserSettingsPropsChange(
        USERSETTINGSPROPS.CLINICAL_NOTE_FONT,
        this.editorFontSize.toString()
      );
    }
    editorContents.forEach((editorContent) => {
      // Apply the new font size to each element
      editorContent.style.fontSize = `${this.editorFontSize}px`;
    });
  }

  // ngModelChange handler to capture changes from the editor
  onEditorChange(event: any, sectionId: string): void {
    // Set the hasChanges flag to true when there are changes
    this.hasChangesMap[sectionId] = true;
    this.autosaved = false;
    this.hasChanges = true;

    // Clear any previous timeouts and reset the timeout to 5 seconds
    if (this.changeTimeout) {
      clearTimeout(this.changeTimeout);
    }

    // Set a timeout to trigger the API call after 5 seconds of inactivity
    this.changeTimeout = setTimeout(() => {
      if (this.hasChangesMap[sectionId]) {
        this.hasChangesMap[sectionId] = false; // Reset the flag
        this.hadChangesEarlier[sectionId] = true;
        this.saveNotes(); // Call API after 5 seconds
      }
    }, 500);
  }

  saveNotes() {
    // For each section, generating the payload to save
    let payload: any = [];

    this.sessionDetails.sessionNotes.sections.forEach((section, i) => {
      let jsonDoc = toDoc(
        this.clinicalNoteForm.controls[section.id].value,
        schema
      );
      payload.push({
        id: section.id,
        name: section.name,
        payload: jsonDoc,
      });
    });

    this.hasChanges = true;
    this.autosaved = false;
    this.therapysessionApiService
      .updateSessionNote(this.sessionDetails.id, { sections: payload })
      .subscribe({
        next: (response) => {
          this.autosaved = true;
          this.hasChanges = false;
          this.updatedSessionDetailsResponse = response;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to update the clinical notes.'
          );
          this.autosaved = false;
          this.hasChanges = false;
        },
      });
  }

  // Load Data from the last Note
  loadLastNote() {
    let dialogRef = this.dialog.open(LoadExistingClinicalNoteComponent, {
      data: {
        therapySession: this.sessionDetails,
        groupAppointment: true,
        allGroupMember: true,
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '65vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response.type === 'success') {
        // Now we need to load this note to existing form controls in ngx editor
        if (response.sessionNotes) {
          response.sessionNotes.sections.forEach((section) => {
            // Now check if this section already has data
            if (section.payload) {
              let ngxEditorData = section.payload;
              let htmlNGXData = toHTML(ngxEditorData);
              // Add form control with existing value if the section exists
              if (this.clinicalNoteForm.controls[section.id]) {
                this.clinicalNoteForm.controls[section.id].setValue(
                  htmlNGXData
                );
              }
            }
          });
        }
      }
    });
  }
}
