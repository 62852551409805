import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { DataTablesModel } from 'src/app/_models';
import {
  DateTimeZoneService,
  ToastMessageService,
  TherapySessionService,
} from 'src/app/_services';

@Component({
  selector: 'app-patient-screen-sessions',
  templateUrl: './patient-screen-sessions.component.html',
  styleUrls: ['./patient-screen-sessions.component.css'],
})
export class PatientScreenSessionsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = ['time', 'facility', 'visitReason', 'provider'];

  data: DataTablesModel = {} as DataTablesModel;
  currentTimezone: string;
  isLoadingResults = true;
  apiError = false;

  sortOrder: string = 'DESC';

  searchSessionCode: string = '';
  searchStatus: string = 'Active';

  constructor(
    public therapysessionApiService: TherapySessionService,
    private toastMessageService: ToastMessageService,
    private dateTimeZoneService: DateTimeZoneService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit() {
    this.data.page = 0;
    this.data.per_page = 10;

    this.loadTherapySessionList();
  }

  public loadTherapySessionList() {
    this.isLoadingResults = true;
    this.apiError = false;

    let status = null;
    if (this.searchStatus != 'None') {
      status = this.searchStatus;
    }

    this.therapysessionApiService
      .memberGetTherapySessions(
        this.data.per_page,
        this.data.page,
        this.sortOrder,
        this.searchSessionCode,
        status
      )
      .subscribe({
        next: (response) => {
          this.isLoadingResults = false;
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          }
          if (response == null) {
            this.data.items = [];
            this.data.total = 0;
          }
        },
        error: (error) => {
          this.apiError = true;
          this.isLoadingResults = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load existing sessions'
          );
        },
      });
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadTherapySessionList();
  }

  searchBySessionId() {
    // Restore page index and paginator index
    this.data.page = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }

    this.loadTherapySessionList();
  }

  filterOptionChanged(filterOption: string) {
    // First restore pagination
    this.data.page = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }

    this.searchStatus = filterOption;
    this.loadTherapySessionList();
  }
}
