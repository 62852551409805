<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Gambling Addiction Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12" style="padding: 0">
  <div
    *ngIf="assessmentId && !isPatient"
    class="row"
    style="
      margin-bottom: 20px;
      display: flex;
      align-content: center;
      flex-direction: column;
      font-weight: 450;
    "
  >
    Risk Score
    <button
      mat-fab
      color="primary"
      aria-label="Score"
      style="margin-left: 10px"
    >
      {{ riskScore }}
    </button>
  </div>
  <form
    [formGroup]="gamblingAddictionForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >1. Have there ever been periods lasting 2 weeks or longer when you
            spent a lot of time thinking about your gambling experiences, or
            planning out future gambling ventures or bets?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group
              formControlName="q1"
              (change)="askFurtherQuestions()"
            >
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10" *ngIf="showQ2">
          <label class="col-md-9 asterix-after"
            >2. Have there ever been periods lasting two weeks or longer when
            you spend a lot of time thinking about ways of getting money to
            gamble with?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q2">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >3. Have you ever lied to family members, friends, or others about
            how much you gamble or how much money you lost on gambling?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group
              formControlName="q3"
              (change)="askFurtherQuestions()"
            >
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10" *ngIf="showQ4">
          <label class="col-md-9 asterix-after"
            >4. If so, had this happened three or more times?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q4">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >5. Have you ever tried to stop, cut down, or control your
            gambling?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group
              formControlName="q5"
              (change)="askFurtherQuestions()"
            >
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10" *ngIf="showQ678">
          <label class="col-md-9 asterix-after"
            >6. On one or more of the times when you tried to stop, cut down, or
            control your gambling, were you restless or irritable?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q6">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10" *ngIf="showQ678">
          <label class="col-md-9 asterix-after"
            >7. Have you ever tried but not succeeded in stopping, cutting down,
            or controlling your gambling?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group
              formControlName="q7"
              (change)="askFurtherQuestions()"
            >
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10" *ngIf="showQ678 && showQ8">
          <label class="col-md-9 asterix-after"
            >8. Has this happened three or more times?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q8">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10" *ngIf="showQ9">
          <label class="col-md-9 asterix-after"
            >9. Have there ever been periods when you needed to gamble with
            increasing amounts of money or with larger bets than before in order
            to get the same feeling of excitement?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q9">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10" *ngIf="showQ10">
          <label class="col-md-9 asterix-after"
            >10. Have you ever gambled to relieve uncomfortable feelings such as
            guilt, anxiety, helplessness, or depression?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group
              formControlName="q10"
              (click)="askFurtherQuestions()"
            >
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10" *ngIf="showQ11">
          <label class="col-md-9 asterix-after"
            >11. Have you ever gambled as a way to escape from personal
            problems?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q11">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10" *ngIf="showQ12">
          <label class="col-md-9 asterix-after"
            >12. Has there ever been a period when, if you lost money gambling
            one day, you would often return another day to get even?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q12">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10" *ngIf="showQ13">
          <label class="col-md-9 asterix-after"
            >13. Have you ever written a bad check or taken money that didn’t
            belong to you from family members or anyone else in order to pay for
            your gambling?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q13">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10" *ngIf="showQ14">
          <label class="col-md-9 asterix-after"
            >14. Has your gambling ever caused serious or repeated problems in
            your relationships with any of your family members or
            friends?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group
              formControlName="q14"
              (change)="askFurtherQuestions()"
            >
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10" *ngIf="showQ15">
          <label class="col-md-9 asterix-after"
            >15. Has your gambling caused you any problems in school, such as
            missing classes or days of school, or your grades dropping?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group
              formControlName="q15"
              (change)="askFurtherQuestions()"
            >
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10" *ngIf="showQ16">
          <label class="col-md-9 asterix-after"
            >16. Has your gambling ever caused you to lose a job, have trouble
            with your job, or miss out on an important job or career
            opportunity?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q16">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10" *ngIf="showQ17">
          <label class="col-md-9 asterix-after"
            >17. Have you ever needed to ask family members or anyone else to
            loan you money or otherwise bail you out of a desperate money
            situation that was largely caused by your gambling?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q17">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          gamblingAddictionForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!gamblingAddictionForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
