<div class="row">
  <div class="col-md-12" style="text-align: center">
    <label
      style="font-size: 18px; font-weight: 500; padding: 10px; color: black"
    >
      Assigned Goals
    </label>
  </div>
  <div class="col-md-12" style="text-align: right">
    <!-- Category Selection -->
    <button
      mat-raised-button
      [matMenuTriggerFor]="sortMenu"
      style="margin-right: 15px; border-radius: 15px"
    >
      <span
        style="white-space: break-spaces; color: #3ac47d"
        *ngIf="assignedGoalStatus === 'Active'"
      >
        Status: Active
      </span>

      <span
        style="white-space: break-spaces; color: #d92550"
        *ngIf="assignedGoalStatus === 'Closed'"
      >
        Status: Closed
      </span>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #sortMenu="matMenu">
      <button
        mat-menu-item
        type="button"
        style="font-weight: 400; font-size: 12px; width: 100%"
        (click)="changeStatus('Active')"
      >
        Active
      </button>
      <button
        mat-menu-item
        type="button"
        style="font-weight: 400; font-size: 12px; width: 100%"
        (click)="changeStatus('Closed')"
      >
        Closed
      </button>
    </mat-menu>
  </div>

  <div
    class="col-md-12"
    style="text-align: center; margin-top: 15px; margin-bottom: 15px"
  >
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="isLoadingResults"
    ></mat-progress-bar>

    <mat-error
      *ngIf="data.total == 0 && !isLoadingResults"
      style="padding: 5px"
    >
      No {{ assignedGoalStatus }} Goal
    </mat-error>

    <app-goal-card
      *ngFor="let goal of data.items"
      [patient]="patient"
      [therapySession]="therapySession"
      [loggedInUserId]="loggedInUserId"
      [goal]="goal"
      [type]="'Assigned'"
      [hasGoalsAddAccess]="hasGoalsAddAccess"
      [hasGoalsEditAccess]="hasGoalsEditAccess"
      [hasGoalsDeleteAccess]="hasGoalsDeleteAccess"
      (hasChanges)="changesOccured($event)"
    >
    </app-goal-card>
  </div>
</div>
