<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Eligibility Check Details</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div
    class="row"
    *ngIf="row?.status === 'Success' && row?.response?.errors?.length === 0"
  >
    <div class="col-md-12">
      <mat-card class="mat-elevation-z8">
        <mat-card-header>
          <mat-card-title>Payer</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="gridContainer">
            <label class="labelBold"> Name </label>
            <label class="labelText">
              {{ row?.response.payer.name }}
            </label>
            <label class="labelBold"> Tax ID Number </label>
            <label class="labelText">
              {{ row?.response.payer.federalTaxpayersIdNumber }}
            </label>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-md-12 pad20">
      <mat-card class="mat-elevation-z8">
        <mat-card-header>
          <mat-card-title>Information Receiver</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p></p>
          <div class="gridContainer">
            <label class="labelBold"> Type </label>
            <label class="labelText">
              {{ row?.response.provider.entityIdentifier }},
              {{ row?.response.provider.entityType }}
            </label>
            <label class="labelBold"> Name </label>
            <label class="labelText">
              {{ row?.response.provider.providerName }}
            </label>
            <label class="labelBold">
              National Provider Identifier (NPI)
            </label>
            <label class="labelText"> {{ row?.response.provider.npi }}</label>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-md-12 pad20">
      <mat-card class="mat-elevation-z8">
        <mat-card-header>
          <mat-card-title>Insured or Subscriber</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p></p>
          <div class="gridContainer">
            <label class="labelBold">Name</label>
            <label class="labelText">
              {{ row?.response.subscriber.lastName }}
              {{ row?.response.subscriber.firstName }}
              {{ row?.response.subscriber.middleName }}
            </label>
            <label class="labelBold"> Member Identification Number </label>
            <label class="labelText">
              {{ row?.response.subscriber.memberId }}
            </label>
            <label class="labelBold"> Group Number </label>
            <label class="labelText">
              {{ row?.response?.planInformation?.groupNumber }}
            </label>
            <label class="labelBold"> Description </label>
            <label class="labelText">
              {{ row?.response?.planInformation?.groupDescription }}
            </label>
            <label class="labelBold">Address</label>
            <label class="labelText">
              {{ row?.response?.subscriber?.address?.address1 }},
              {{ row?.response?.subscriber?.address?.city }}
              {{ row?.response?.subscriber?.address?.state }}
              {{ row?.response?.subscriber?.address?.postalCode }}
            </label>
            <label class="labelBold"> Date of Birth </label>
            <label class="labelText">
              {{ row?.response.subscriber.dateOfBirth | yyyymmddToUsFormat }}
            </label>
            <label class="labelBold">Gender:</label>
            <label class="labelText">
              {{ row?.response.subscriber.gender }}
            </label>
            <label class="labelBold"> Eligibility Begin </label>
            <label class="labelText">
              {{
                row?.response?.planDateInformation?.eligibilityBegin
                  | yyyymmddToUsFormat
              }}
            </label>
            <label class="labelBold">Plan Begin</label>
            <label class="labelText">
              {{
                row?.response?.planDateInformation?.planBegin
                  | yyyymmddToUsFormat
              }}
            </label>
            <label class="labelBold">Plan End</label>
            <label class="labelText">
              {{
                row?.response?.planDateInformation?.planEnd | yyyymmddToUsFormat
              }}
            </label>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-md-12 pad20" style="margin-bottom: 20px">
      <mat-card class="mat-elevation-z8">
        <mat-card-header>
          <mat-card-title>Covered</mat-card-title>
        </mat-card-header>
        <br />
        <mat-card-content>
          <mat-accordion>
            <mat-expansion-panel
              class="mat-elevation-z8"
              (opened)="activeLink = 'IND'"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Health Benefit Plan Coverage
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-chip-list aria-label="Tab Selection">
                <mat-chip
                  [selected]="activeLink === 'IND'"
                  [class]="activeLink === 'IND' ? 'mat-chip-active' : ''"
                  ><a (click)="activeLink = 'IND'">Individual</a>
                </mat-chip>
                <mat-chip
                  [selected]="activeLink === 'FAM'"
                  [class]="activeLink === 'FAM' ? 'mat-chip-active' : ''"
                  ><a (click)="activeLink = 'FAM'">Family</a>
                </mat-chip>
              </mat-chip-list>

              <div class="row" style="position: relative">
                <div class="col-md-12">
                  <table
                    mat-table
                    [dataSource]="
                      mapOfArrays['healthBenefitPlanCoverageData' + activeLink]
                    "
                    matSort
                    matSortDisableClear
                    style="position: relative; background: #eeeeee"
                  >
                    <ng-container matColumnDef="deductible">
                      <td mat-cell *matCellDef="let row; index as i">
                        <span *ngIf="row?.newGroup === 'Y'">{{
                          row?.name
                        }}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="networkType">
                      <td mat-cell *matCellDef="let row; index as i">
                        <span
                          *ngIf="
                            row?.newGroup === 'Y' || row?.newSubGroup === 'Y'
                          "
                        >
                          <span *ngIf="row?.inPlanNetworkIndicatorCode">
                            {{
                              row?.inPlanNetworkIndicatorCode === 'Y'
                                ? 'In-Network'
                                : row?.inPlanNetworkIndicatorCode === 'W'
                                ? 'In and Out of
                            Network'
                                : 'Out-of-Network'
                            }}
                          </span>
                          <span *ngIf="row?.insuranceType">
                            {{ row?.insuranceType }} {{ row?.planCoverage }}
                          </span>
                        </span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="individualOrFamily">
                      <td mat-cell *matCellDef="let row; index as i">
                        <span
                          *ngIf="
                            row?.newGroup === 'Y' || row?.newSubGroup === 'Y'
                          "
                          >{{
                            row?.coverageLevelCode === 'IND' ? 'Individual' : ''
                          }}</span
                        >
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="benefitAmount">
                      <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="row?.benefitAmount">
                          {{ row?.benefitAmount | currency : 'USD' }}
                        </ng-container>
                        <ng-container
                          *ngIf="!row?.benefitAmount && row?.benefitPercent"
                        >
                          {{ row?.benefitPercent * 100 }}%
                        </ng-container>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="term">
                      <td mat-cell *matCellDef="let row">
                        {{
                          row?.timeQualifier === null
                            ? ''
                            : row?.timeQualifier === 'Remaining'
                            ? row?.timeQualifier
                            : 'Per
                        ' + row?.timeQualifier
                        }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="benefitBegin">
                      <td mat-cell *matCellDef="let row">
                        <span
                          *ngIf="row?.benefitsDateInformation?.benefitBegin"
                        >
                          Benefit Begin
                          {{
                            row?.benefitsDateInformation?.benefitBegin
                              | yyyymmddToUsFormat
                          }}
                        </span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="additionalInformation">
                      <td mat-cell *matCellDef="let row">
                        <ul>
                          <ng-container
                            *ngFor="
                              let additionInfo of row?.additionalInformation
                            "
                          >
                            <li>{{ additionInfo?.description }}</li>
                          </ng-container>
                        </ul>
                      </td>
                    </ng-container>
                    <tr
                      mat-row
                      *matRowDef="
                        let row;
                        columns: displayedColumns;
                        let i = index
                      "
                    ></tr>
                  </table>
                </div>
              </div>
            </mat-expansion-panel>

            <ng-container
              *ngIf="emergencyServicesData && emergencyServicesData.length > 0"
            >
              <mat-expansion-panel
                class="mat-elevation-z8"
                (opened)="activeLink = 'IND'"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title> Emergency Services </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-chip-list aria-label="Tab Selection">
                  <mat-chip
                    [selected]="activeLink === 'IND'"
                    [class]="activeLink === 'IND' ? 'mat-chip-active' : ''"
                    ><a (click)="activeLink = 'IND'">Individual</a>
                  </mat-chip>
                  <mat-chip
                    [selected]="activeLink === 'FAM'"
                    [class]="activeLink === 'FAM' ? 'mat-chip-active' : ''"
                    ><a (click)="activeLink = 'FAM'">Family</a>
                  </mat-chip>
                </mat-chip-list>

                <div class="row" style="position: relative">
                  <div class="col-md-12">
                    <table
                      mat-table
                      [dataSource]="
                        mapOfArrays['emergencyServicesData' + activeLink]
                      "
                      matSort
                      matSortDisableClear
                      style="position: relative; background: #eeeeee"
                    >
                      <ng-container matColumnDef="deductible">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span *ngIf="row?.newGroup === 'Y'">{{
                            row?.name
                          }}</span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="networkType">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span
                            *ngIf="
                              row?.newGroup === 'Y' || row?.newSubGroup === 'Y'
                            "
                          >
                            <span *ngIf="row?.inPlanNetworkIndicatorCode">
                              {{
                                row?.inPlanNetworkIndicatorCode === 'Y'
                                  ? 'In-Network'
                                  : row?.inPlanNetworkIndicatorCode === 'W'
                                  ? 'In and Out of
                              Network'
                                  : 'Out-of-Network'
                              }}
                            </span>
                            <span *ngIf="row?.insuranceType">
                              {{ row?.insuranceType }} {{ row?.planCoverage }}
                            </span>
                          </span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="individualOrFamily">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span
                            *ngIf="
                              row?.newGroup === 'Y' || row?.newSubGroup === 'Y'
                            "
                            >{{
                              row?.coverageLevelCode === 'IND'
                                ? 'Individual'
                                : ''
                            }}</span
                          >
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="benefitAmount">
                        <td mat-cell *matCellDef="let row">
                          <ng-container *ngIf="row?.benefitAmount">
                            {{ row?.benefitAmount | currency : 'USD' }}
                          </ng-container>
                          <ng-container
                            *ngIf="!row?.benefitAmount && row?.benefitPercent"
                          >
                            {{ row?.benefitPercent * 100 }}%
                          </ng-container>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="term">
                        <td mat-cell *matCellDef="let row">
                          {{
                            row?.timeQualifier === null
                              ? ''
                              : row?.timeQualifier === 'Remaining'
                              ? row?.timeQualifier
                              : 'Per
                          ' + row?.timeQualifier
                          }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="benefitBegin">
                        <td mat-cell *matCellDef="let row">
                          <span
                            *ngIf="row?.benefitsDateInformation?.benefitBegin"
                          >
                            Benefit Begin
                            {{
                              row?.benefitsDateInformation?.benefitBegin
                                | yyyymmddToUsFormat
                            }}
                          </span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="additionalInformation">
                        <td mat-cell *matCellDef="let row">
                          <ul>
                            <ng-container
                              *ngFor="
                                let additionInfo of row?.additionalInformation
                              "
                            >
                              <li>{{ additionInfo.description }}</li>
                            </ng-container>
                          </ul>
                          <span
                            *ngIf="
                              row?.eligibilityAdditionalInformation
                                ?.industryCode
                            "
                            >On Campus -
                            {{
                              row?.eligibilityAdditionalInformation
                                ?.industryCode
                            }}</span
                          >
                        </td>
                      </ng-container>
                      <tr
                        mat-row
                        *matRowDef="
                          let row;
                          columns: displayedColumns;
                          let i = index
                        "
                      ></tr>
                    </table>
                  </div>
                </div>
              </mat-expansion-panel>
            </ng-container>

            <ng-container
              *ngIf="mentalHealthData && mentalHealthData.length > 0"
            >
              <mat-expansion-panel
                class="mat-elevation-z8"
                (opened)="activeLink = 'IND'"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title> Mental Health </mat-panel-title>
                </mat-expansion-panel-header>
                <table
                  mat-table
                  [dataSource]="mapOfArrays['mentalHealthData']"
                  matSort
                  matSortDisableClear
                  style="position: relative; background: #eeeeee"
                >
                  <ng-container matColumnDef="deductible">
                    <td mat-cell *matCellDef="let row; index as i">
                      <span *ngIf="row?.newGroup === 'Y'">{{ row?.name }}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="networkType">
                    <td mat-cell *matCellDef="let row; index as i">
                      <span
                        *ngIf="
                          row?.newGroup === 'Y' || row?.newSubGroup === 'Y'
                        "
                      >
                        <span *ngIf="row?.inPlanNetworkIndicatorCode">
                          {{
                            row?.inPlanNetworkIndicatorCode === 'Y'
                              ? 'In-Network'
                              : row?.inPlanNetworkIndicatorCode === 'W'
                              ? 'In and Out of
                          Network'
                              : 'Out-of-Network'
                          }}
                        </span>
                        <span *ngIf="row?.insuranceType">
                          {{ row?.insuranceType }} {{ row?.planCoverage }}
                        </span>
                      </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="individualOrFamily">
                    <td mat-cell *matCellDef="let row; index as i">
                      <span
                        *ngIf="
                          row?.newGroup === 'Y' || row?.newSubGroup === 'Y'
                        "
                        >{{
                          row?.coverageLevelCode === 'IND' ? 'Individual' : ''
                        }}</span
                      >
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="benefitAmount">
                    <td mat-cell *matCellDef="let row">
                      <ng-container *ngIf="row?.benefitAmount">
                        {{ row?.benefitAmount | currency : 'USD' }}
                      </ng-container>
                      <ng-container
                        *ngIf="!row?.benefitAmount && row?.benefitPercent"
                      >
                        {{ row?.benefitPercent * 100 }}%
                      </ng-container>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="term">
                    <td mat-cell *matCellDef="let row">
                      {{
                        row?.timeQualifier === null
                          ? ''
                          : row?.timeQualifier === 'Remaining'
                          ? row?.timeQualifier
                          : 'Per
                      ' + row?.timeQualifier
                      }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="benefitBegin">
                    <td mat-cell *matCellDef="let row">
                      <span *ngIf="row?.benefitsDateInformation?.benefitBegin">
                        Benefit Begin
                        {{
                          row?.benefitsDateInformation?.benefitBegin
                            | yyyymmddToUsFormat
                        }}
                      </span>
                      <span *ngIf="row?.authOrCertIndicator === 'Y'">
                        PRECERTIFICATION REQUIRED
                      </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="additionalInformation">
                    <td mat-cell *matCellDef="let row">
                      <ul>
                        <ng-container
                          *ngFor="
                            let additionInfo of row?.additionalInformation
                          "
                        >
                          <li>{{ additionInfo.description }}</li>
                        </ng-container>
                      </ul>
                      <span
                        *ngIf="
                          row?.eligibilityAdditionalInformation?.industryCode
                        "
                        >On Campus -
                        {{
                          row?.eligibilityAdditionalInformation?.industryCode
                        }}</span
                      >
                      <ng-container *ngIf="row?.benefitsRelatedEntity">
                        <div>
                          {{ row?.benefitsRelatedEntity.entityIdentifier }}:
                          {{ row?.benefitsRelatedEntity.entityName }}
                        </div>
                        <ng-container
                          *ngIf="
                            row?.benefitsRelatedEntity?.contactInformation
                              ?.contacts.length > 0
                          "
                        >
                          <div>Information Contact:</div>
                        </ng-container>
                        <ng-container
                          *ngFor="
                            let contact of row?.benefitsRelatedEntity
                              .contactInformation?.contacts
                          "
                        >
                          <div>
                            {{ contact.communicationMode }}:
                            {{ contact.communicationNumber }}
                          </div>
                        </ng-container>
                      </ng-container>
                    </td>
                  </ng-container>
                  <tr
                    mat-row
                    *matRowDef="
                      let row;
                      columns: displayedColumns;
                      let i = index
                    "
                  ></tr>
                </table>
              </mat-expansion-panel>
            </ng-container>

            <ng-container
              *ngIf="psychotherapyData && psychotherapyData.length > 0"
            >
              <mat-expansion-panel
                class="mat-elevation-z8"
                (opened)="activeLink = 'IND'"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title> Psychotherapy </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-chip-list aria-label="Tab Selection">
                  <mat-chip
                    [selected]="activeLink === 'IND'"
                    [class]="activeLink === 'IND' ? 'mat-chip-active' : ''"
                    ><a (click)="activeLink = 'IND'">Individual</a>
                  </mat-chip>
                  <mat-chip
                    [selected]="activeLink === 'FAM'"
                    [class]="activeLink === 'FAM' ? 'mat-chip-active' : ''"
                    ><a (click)="activeLink = 'FAM'">Family</a>
                  </mat-chip>
                </mat-chip-list>

                <div class="row" style="position: relative">
                  <div class="col-md-12">
                    <table
                      mat-table
                      [dataSource]="
                        mapOfArrays['psychotherapyData' + activeLink]
                      "
                      matSort
                      matSortDisableClear
                      style="position: relative; background: #eeeeee"
                    >
                      <ng-container matColumnDef="deductible">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span *ngIf="row?.newGroup === 'Y'">{{
                            row?.name
                          }}</span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="networkType">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span
                            *ngIf="
                              row?.newGroup === 'Y' || row?.newSubGroup === 'Y'
                            "
                          >
                            <span *ngIf="row?.inPlanNetworkIndicatorCode">
                              {{
                                row?.inPlanNetworkIndicatorCode === 'Y'
                                  ? 'In-Network'
                                  : row?.inPlanNetworkIndicatorCode === 'W'
                                  ? 'In and Out of
                              Network'
                                  : 'Out-of-Network'
                              }}
                            </span>
                            <span *ngIf="row?.insuranceType">
                              {{ row?.insuranceType }} {{ row?.planCoverage }}
                            </span>
                          </span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="individualOrFamily">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span
                            *ngIf="
                              row?.newGroup === 'Y' || row?.newSubGroup === 'Y'
                            "
                            >{{
                              row?.coverageLevelCode === 'IND'
                                ? 'Individual'
                                : ''
                            }}</span
                          >
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="benefitAmount">
                        <td mat-cell *matCellDef="let row">
                          <ng-container *ngIf="row?.benefitAmount">
                            {{ row?.benefitAmount | currency : 'USD' }}
                          </ng-container>
                          <ng-container
                            *ngIf="!row?.benefitAmount && row?.benefitPercent"
                          >
                            {{ row?.benefitPercent * 100 }}%
                          </ng-container>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="term">
                        <td mat-cell *matCellDef="let row">
                          {{
                            row?.timeQualifier === null
                              ? ''
                              : row?.timeQualifier === 'Remaining'
                              ? row?.timeQualifier
                              : 'Per
                          ' + row?.timeQualifier
                          }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="benefitBegin">
                        <td mat-cell *matCellDef="let row">
                          <span
                            *ngIf="row?.benefitsDateInformation?.benefitBegin"
                          >
                            Benefit Begin
                            {{
                              row?.benefitsDateInformation?.benefitBegin
                                | yyyymmddToUsFormat
                            }}
                          </span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="additionalInformation">
                        <td mat-cell *matCellDef="let row">
                          <ul>
                            <ng-container
                              *ngFor="
                                let additionInfo of row?.additionalInformation
                              "
                            >
                              <li>{{ additionInfo.description }}</li>
                            </ng-container>
                          </ul>
                          <span
                            *ngIf="
                              row?.eligibilityAdditionalInformation
                                ?.industryCode
                            "
                            >On Campus -
                            {{
                              row?.eligibilityAdditionalInformation
                                ?.industryCode
                            }}</span
                          >
                        </td>
                      </ng-container>
                      <tr
                        mat-row
                        *matRowDef="
                          let row;
                          columns: displayedColumns;
                          let i = index
                        "
                      ></tr>
                    </table>
                  </div>
                </div>
              </mat-expansion-panel>
            </ng-container>

            <ng-container *ngIf="outpatientData && outpatientData.length > 0">
              <mat-expansion-panel
                class="mat-elevation-z8"
                (opened)="activeLink = 'IND'"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title> Psychiatric - OutPatient </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-chip-list aria-label="Tab Selection">
                  <mat-chip
                    [selected]="activeLink === 'IND'"
                    [class]="activeLink === 'IND' ? 'mat-chip-active' : ''"
                    ><a (click)="activeLink = 'IND'">Individual</a>
                  </mat-chip>
                  <mat-chip
                    [selected]="activeLink === 'FAM'"
                    [class]="activeLink === 'FAM' ? 'mat-chip-active' : ''"
                    ><a (click)="activeLink = 'FAM'">Family</a>
                  </mat-chip>
                </mat-chip-list>

                <div class="row" style="position: relative">
                  <div class="col-md-12">
                    <table
                      mat-table
                      [dataSource]="mapOfArrays['outpatientData' + activeLink]"
                      matSort
                      matSortDisableClear
                      style="position: relative; background: #eeeeee"
                    >
                      <ng-container matColumnDef="deductible">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span *ngIf="row?.newGroup === 'Y'">{{
                            row?.name
                          }}</span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="networkType">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span
                            *ngIf="
                              row?.newGroup === 'Y' || row?.newSubGroup === 'Y'
                            "
                          >
                            <span *ngIf="row?.inPlanNetworkIndicatorCode">
                              {{
                                row?.inPlanNetworkIndicatorCode === 'Y'
                                  ? 'In-Network'
                                  : row?.inPlanNetworkIndicatorCode === 'W'
                                  ? 'In and Out of
                              Network'
                                  : 'Out-of-Network'
                              }}
                            </span>
                            <span *ngIf="row?.insuranceType">
                              {{ row?.insuranceType }} {{ row?.planCoverage }}
                            </span>
                          </span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="individualOrFamily">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span
                            *ngIf="
                              row?.newGroup === 'Y' || row?.newSubGroup === 'Y'
                            "
                            >{{
                              row?.coverageLevelCode === 'IND'
                                ? 'Individual'
                                : ''
                            }}</span
                          >
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="benefitAmount">
                        <td mat-cell *matCellDef="let row">
                          <ng-container *ngIf="row?.benefitAmount">
                            {{ row?.benefitAmount | currency : 'USD' }}
                          </ng-container>
                          <ng-container
                            *ngIf="!row?.benefitAmount && row?.benefitPercent"
                          >
                            {{ row?.benefitPercent * 100 }}%
                          </ng-container>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="term">
                        <td mat-cell *matCellDef="let row">
                          {{
                            row?.timeQualifier === null
                              ? ''
                              : row?.timeQualifier === 'Remaining'
                              ? row?.timeQualifier
                              : 'Per
                          ' + row?.timeQualifier
                          }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="benefitBegin">
                        <td mat-cell *matCellDef="let row">
                          <span
                            *ngIf="row?.benefitsDateInformation?.benefitBegin"
                          >
                            Benefit Begin
                            {{
                              row?.benefitsDateInformation?.benefitBegin
                                | yyyymmddToUsFormat
                            }}
                          </span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="additionalInformation">
                        <td mat-cell *matCellDef="let row">
                          <ul>
                            <ng-container
                              *ngFor="
                                let additionInfo of row?.additionalInformation
                              "
                            >
                              <li>{{ additionInfo.description }}</li>
                            </ng-container>
                          </ul>
                          <span
                            *ngIf="
                              row?.eligibilityAdditionalInformation
                                ?.industryCode
                            "
                            >On Campus -
                            {{
                              row?.eligibilityAdditionalInformation
                                ?.industryCode
                            }}</span
                          >
                        </td>
                      </ng-container>
                      <tr
                        mat-row
                        *matRowDef="
                          let row;
                          columns: displayedColumns;
                          let i = index
                        "
                      ></tr>
                    </table>
                  </div>
                </div>
              </mat-expansion-panel>
            </ng-container>

            <ng-container *ngIf="inpatientData && inpatientData.length > 0">
              <mat-expansion-panel
                class="mat-elevation-z8"
                (opened)="activeLink = 'IND'"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title> Psychiatric - InPatient </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-chip-list aria-label="Tab Selection">
                  <mat-chip
                    [selected]="activeLink === 'IND'"
                    [class]="activeLink === 'IND' ? 'mat-chip-active' : ''"
                    ><a (click)="activeLink = 'IND'">Individual</a>
                  </mat-chip>
                  <mat-chip
                    [selected]="activeLink === 'FAM'"
                    [class]="activeLink === 'FAM' ? 'mat-chip-active' : ''"
                    ><a (click)="activeLink = 'FAM'">Family</a>
                  </mat-chip>
                </mat-chip-list>

                <div class="row" style="position: relative">
                  <div class="col-md-12">
                    <table
                      mat-table
                      [dataSource]="mapOfArrays['inpatientData' + activeLink]"
                      matSort
                      matSortDisableClear
                      style="position: relative; background: #eeeeee"
                    >
                      <ng-container matColumnDef="deductible">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span *ngIf="row?.newGroup === 'Y'">{{
                            row?.name
                          }}</span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="networkType">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span
                            *ngIf="
                              row?.newGroup === 'Y' || row?.newSubGroup === 'Y'
                            "
                          >
                            <span *ngIf="row?.inPlanNetworkIndicatorCode">
                              {{
                                row?.inPlanNetworkIndicatorCode === 'Y'
                                  ? 'In-Network'
                                  : row?.inPlanNetworkIndicatorCode === 'W'
                                  ? 'In and Out of
                              Network'
                                  : 'Out-of-Network'
                              }}
                            </span>
                            <span *ngIf="row?.insuranceType">
                              {{ row?.insuranceType }} {{ row?.planCoverage }}
                            </span>
                          </span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="individualOrFamily">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span
                            *ngIf="
                              row?.newGroup === 'Y' || row?.newSubGroup === 'Y'
                            "
                            >{{
                              row?.coverageLevelCode === 'IND'
                                ? 'Individual'
                                : ''
                            }}</span
                          >
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="benefitAmount">
                        <td mat-cell *matCellDef="let row">
                          <ng-container *ngIf="row?.benefitAmount">
                            {{ row?.benefitAmount | currency : 'USD' }}
                          </ng-container>
                          <ng-container
                            *ngIf="!row?.benefitAmount && row?.benefitPercent"
                          >
                            {{ row?.benefitPercent * 100 }}%
                          </ng-container>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="term">
                        <td mat-cell *matCellDef="let row">
                          {{
                            row?.timeQualifier === null
                              ? ''
                              : row?.timeQualifier === 'Remaining'
                              ? row?.timeQualifier
                              : 'Per
                          ' + row?.timeQualifier
                          }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="benefitBegin">
                        <td mat-cell *matCellDef="let row">
                          <span
                            *ngIf="row?.benefitsDateInformation?.benefitBegin"
                          >
                            Benefit Begin
                            {{
                              row?.benefitsDateInformation?.benefitBegin
                                | yyyymmddToUsFormat
                            }}
                          </span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="additionalInformation">
                        <td mat-cell *matCellDef="let row">
                          <ul>
                            <ng-container
                              *ngFor="
                                let additionInfo of row?.additionalInformation
                              "
                            >
                              <li>{{ additionInfo.description }}</li>
                            </ng-container>
                          </ul>
                          <span
                            *ngIf="
                              row?.eligibilityAdditionalInformation
                                ?.industryCode
                            "
                            >On Campus -
                            {{
                              row?.eligibilityAdditionalInformation
                                ?.industryCode
                            }}</span
                          >
                        </td>
                      </ng-container>
                      <tr
                        mat-row
                        *matRowDef="
                          let row;
                          columns: displayedColumns;
                          let i = index
                        "
                      ></tr>
                    </table>
                  </div>
                </div>
              </mat-expansion-panel>
            </ng-container>

            <ng-container *ngIf="psychiatricData && psychiatricData.length > 0">
              <mat-expansion-panel
                class="mat-elevation-z8"
                (opened)="activeLink = 'IND'"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title> Psychiatric </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-chip-list aria-label="Tab Selection">
                  <mat-chip
                    [selected]="activeLink === 'IND'"
                    [class]="activeLink === 'IND' ? 'mat-chip-active' : ''"
                    ><a (click)="activeLink = 'IND'">Individual</a>
                  </mat-chip>
                  <mat-chip
                    [selected]="activeLink === 'FAM'"
                    [class]="activeLink === 'FAM' ? 'mat-chip-active' : ''"
                    ><a (click)="activeLink = 'FAM'">Family</a>
                  </mat-chip>
                </mat-chip-list>

                <div class="row" style="position: relative">
                  <div class="col-md-12">
                    <table
                      mat-table
                      [dataSource]="mapOfArrays['psychiatricData' + activeLink]"
                      matSort
                      matSortDisableClear
                      style="position: relative; background: #eeeeee"
                    >
                      <ng-container matColumnDef="deductible">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span *ngIf="row?.newGroup === 'Y'">{{
                            row?.name
                          }}</span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="networkType">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span
                            *ngIf="
                              row?.newGroup === 'Y' || row?.newSubGroup === 'Y'
                            "
                          >
                            <span *ngIf="row?.inPlanNetworkIndicatorCode">
                              {{
                                row?.inPlanNetworkIndicatorCode === 'Y'
                                  ? 'In-Network'
                                  : row?.inPlanNetworkIndicatorCode === 'W'
                                  ? 'In and Out of
                              Network'
                                  : 'Out-of-Network'
                              }}
                            </span>
                            <span *ngIf="row?.insuranceType">
                              {{ row?.insuranceType }} {{ row?.planCoverage }}
                            </span>
                          </span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="individualOrFamily">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span
                            *ngIf="
                              row?.newGroup === 'Y' || row?.newSubGroup === 'Y'
                            "
                            >{{
                              row?.coverageLevelCode === 'IND'
                                ? 'Individual'
                                : ''
                            }}</span
                          >
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="benefitAmount">
                        <td mat-cell *matCellDef="let row">
                          <ng-container *ngIf="row?.benefitAmount">
                            {{ row?.benefitAmount | currency : 'USD' }}
                          </ng-container>
                          <ng-container
                            *ngIf="!row?.benefitAmount && row?.benefitPercent"
                          >
                            {{ row?.benefitPercent * 100 }}%
                          </ng-container>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="term">
                        <td mat-cell *matCellDef="let row">
                          {{
                            row?.timeQualifier === null
                              ? ''
                              : row?.timeQualifier === 'Remaining'
                              ? row?.timeQualifier
                              : 'Per
                          ' + row?.timeQualifier
                          }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="benefitBegin">
                        <td mat-cell *matCellDef="let row">
                          <span
                            *ngIf="row?.benefitsDateInformation?.benefitBegin"
                          >
                            Benefit Begin
                            {{
                              row?.benefitsDateInformation?.benefitBegin
                                | yyyymmddToUsFormat
                            }}
                          </span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="additionalInformation">
                        <td mat-cell *matCellDef="let row">
                          <ul>
                            <ng-container
                              *ngFor="
                                let additionInfo of row?.additionalInformation
                              "
                            >
                              <li>{{ additionInfo.description }}</li>
                            </ng-container>
                          </ul>
                          <span
                            *ngIf="
                              row?.eligibilityAdditionalInformation
                                ?.industryCode
                            "
                            >On Campus -
                            {{
                              row?.eligibilityAdditionalInformation
                                ?.industryCode
                            }}</span
                          >
                        </td>
                      </ng-container>
                      <tr
                        mat-row
                        *matRowDef="
                          let row;
                          columns: displayedColumns;
                          let i = index
                        "
                      ></tr>
                    </table>
                  </div>
                </div>
              </mat-expansion-panel>
            </ng-container>

            <ng-container
              *ngIf="
                professionalPhysicianData &&
                professionalPhysicianData.length > 0
              "
            >
              <mat-expansion-panel
                class="mat-elevation-z8"
                (opened)="activeLink = 'IND'"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title> Professional (Physician) </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-chip-list aria-label="Tab Selection">
                  <mat-chip
                    [selected]="activeLink === 'IND'"
                    [class]="activeLink === 'IND' ? 'mat-chip-active' : ''"
                    ><a (click)="activeLink = 'IND'">Individual</a>
                  </mat-chip>
                  <mat-chip
                    [selected]="activeLink === 'FAM'"
                    [class]="activeLink === 'FAM' ? 'mat-chip-active' : ''"
                    ><a (click)="activeLink = 'FAM'">Family</a>
                  </mat-chip>
                </mat-chip-list>

                <div class="row" style="position: relative">
                  <div class="col-md-12">
                    <table
                      mat-table
                      [dataSource]="
                        mapOfArrays['professionalPhysicianData' + activeLink]
                      "
                      matSort
                      matSortDisableClear
                      style="position: relative; background: #eeeeee"
                    >
                      <ng-container matColumnDef="deductible">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span *ngIf="row?.newGroup === 'Y'">{{
                            row?.name
                          }}</span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="networkType">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span
                            *ngIf="
                              row?.newGroup === 'Y' || row?.newSubGroup === 'Y'
                            "
                          >
                            <span *ngIf="row?.inPlanNetworkIndicatorCode">
                              {{
                                row?.inPlanNetworkIndicatorCode === 'Y'
                                  ? 'In-Network'
                                  : row?.inPlanNetworkIndicatorCode === 'W'
                                  ? 'In and Out of
                              Network'
                                  : 'Out-of-Network'
                              }}
                            </span>
                            <span *ngIf="row?.insuranceType">
                              {{ row?.insuranceType }} {{ row?.planCoverage }}
                            </span>
                          </span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="individualOrFamily">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span
                            *ngIf="
                              row?.newGroup === 'Y' || row?.newSubGroup === 'Y'
                            "
                            >{{
                              row?.coverageLevelCode === 'IND'
                                ? 'Individual'
                                : ''
                            }}</span
                          >
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="benefitAmount">
                        <td mat-cell *matCellDef="let row">
                          <ng-container *ngIf="row?.benefitAmount">
                            {{ row?.benefitAmount | currency : 'USD' }}
                          </ng-container>
                          <ng-container
                            *ngIf="!row?.benefitAmount && row?.benefitPercent"
                          >
                            {{ row?.benefitPercent * 100 }}%
                          </ng-container>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="term">
                        <td mat-cell *matCellDef="let row">
                          {{
                            row?.timeQualifier === null
                              ? ''
                              : row?.timeQualifier === 'Remaining'
                              ? row?.timeQualifier
                              : 'Per
                          ' + row?.timeQualifier
                          }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="benefitBegin">
                        <td mat-cell *matCellDef="let row">
                          <span
                            *ngIf="row?.benefitsDateInformation?.benefitBegin"
                          >
                            Benefit Begin
                            {{
                              row?.benefitsDateInformation?.benefitBegin
                                | yyyymmddToUsFormat
                            }}
                          </span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="additionalInformation">
                        <td mat-cell *matCellDef="let row">
                          <ul>
                            <ng-container
                              *ngFor="
                                let additionInfo of row?.additionalInformation
                              "
                            >
                              <li>{{ additionInfo.description }}</li>
                            </ng-container>
                          </ul>
                          <span
                            *ngIf="
                              row?.eligibilityAdditionalInformation
                                ?.industryCode
                            "
                            >On Campus -
                            {{
                              row?.eligibilityAdditionalInformation
                                ?.industryCode
                            }}</span
                          >
                        </td>
                      </ng-container>
                      <tr
                        mat-row
                        *matRowDef="
                          let row;
                          columns: displayedColumns;
                          let i = index
                        "
                      ></tr>
                    </table>
                  </div>
                </div>
              </mat-expansion-panel>
            </ng-container>

            <ng-container
              *ngIf="
                physicianVisitWellData && physicianVisitWellData.length > 0
              "
            >
              <mat-expansion-panel
                class="mat-elevation-z8"
                (opened)="activeLink = 'IND'"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Physician Visit - Office: Well
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-chip-list aria-label="Tab Selection">
                  <mat-chip
                    [selected]="activeLink === 'IND'"
                    [class]="activeLink === 'IND' ? 'mat-chip-active' : ''"
                    ><a (click)="activeLink = 'IND'">Individual</a>
                  </mat-chip>
                  <mat-chip
                    [selected]="activeLink === 'FAM'"
                    [class]="activeLink === 'FAM' ? 'mat-chip-active' : ''"
                    ><a (click)="activeLink = 'FAM'">Family</a>
                  </mat-chip>
                </mat-chip-list>

                <div class="row" style="position: relative">
                  <div class="col-md-12">
                    <table
                      mat-table
                      [dataSource]="
                        mapOfArrays['physicianVisitWellData' + activeLink]
                      "
                      matSort
                      matSortDisableClear
                      style="position: relative; background: #eeeeee"
                    >
                      <ng-container matColumnDef="deductible">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span *ngIf="row?.newGroup === 'Y'">{{
                            row?.name
                          }}</span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="networkType">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span
                            *ngIf="
                              row?.newGroup === 'Y' || row?.newSubGroup === 'Y'
                            "
                          >
                            <span *ngIf="row?.inPlanNetworkIndicatorCode">
                              {{
                                row?.inPlanNetworkIndicatorCode === 'Y'
                                  ? 'In-Network'
                                  : row?.inPlanNetworkIndicatorCode === 'W'
                                  ? 'In and Out of
                              Network'
                                  : 'Out-of-Network'
                              }}
                            </span>
                            <span *ngIf="row?.insuranceType">
                              {{ row?.insuranceType }} {{ row?.planCoverage }}
                            </span>
                          </span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="individualOrFamily">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span
                            *ngIf="
                              row?.newGroup === 'Y' || row?.newSubGroup === 'Y'
                            "
                            >{{
                              row?.coverageLevelCode === 'IND'
                                ? 'Individual'
                                : ''
                            }}</span
                          >
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="benefitAmount">
                        <td mat-cell *matCellDef="let row">
                          <ng-container *ngIf="row?.benefitAmount">
                            {{ row?.benefitAmount | currency : 'USD' }}
                          </ng-container>
                          <ng-container
                            *ngIf="!row?.benefitAmount && row?.benefitPercent"
                          >
                            {{ row?.benefitPercent * 100 }}%
                          </ng-container>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="term">
                        <td mat-cell *matCellDef="let row">
                          {{
                            row?.timeQualifier === null
                              ? ''
                              : row?.timeQualifier === 'Remaining'
                              ? row?.timeQualifier
                              : 'Per
                          ' + row?.timeQualifier
                          }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="benefitBegin">
                        <td mat-cell *matCellDef="let row">
                          <span
                            *ngIf="row?.benefitsDateInformation?.benefitBegin"
                          >
                            Benefit Begin
                            {{
                              row?.benefitsDateInformation?.benefitBegin
                                | yyyymmddToUsFormat
                            }}
                          </span>
                          <span *ngIf="row?.authOrCertIndicator === 'Y'">
                            PRECERTIFICATION REQUIRED
                          </span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="additionalInformation">
                        <td mat-cell *matCellDef="let row">
                          <ul>
                            <ng-container
                              *ngFor="
                                let additionInfo of row?.additionalInformation
                              "
                            >
                              <li>{{ additionInfo.description }}</li>
                            </ng-container>
                          </ul>
                          <span
                            *ngIf="
                              row?.eligibilityAdditionalInformation
                                ?.industryCode
                            "
                            >On Campus -
                            {{
                              row?.eligibilityAdditionalInformation
                                ?.industryCode
                            }}</span
                          >
                          <ng-container *ngIf="row?.benefitsRelatedEntity">
                            <div>
                              {{ row?.benefitsRelatedEntity.entityIdentifier }}:
                              {{ row?.benefitsRelatedEntity.entityName }}
                            </div>
                            <div
                              *ngIf="
                                row?.benefitsRelatedEntity.contactInformation
                                  ?.contacts.length > 0
                              "
                            >
                              Information Contact:
                            </div>
                            <ng-container
                              *ngFor="
                                let contact of row?.benefitsRelatedEntity
                                  .contactInformation?.contacts
                              "
                            >
                              <div>
                                {{ contact.communicationMode }}:
                                {{ contact.communicationNumber }}
                              </div>
                            </ng-container>
                          </ng-container>
                        </td>
                      </ng-container>
                      <tr
                        mat-row
                        *matRowDef="
                          let row;
                          columns: displayedColumns;
                          let i = index
                        "
                      ></tr>
                    </table>
                  </div>
                </div>
              </mat-expansion-panel>
            </ng-container>

            <ng-container
              *ngIf="
                physicianVisitOfficeWithoutSpecialistsData['IND'] &&
                physicianVisitOfficeWithoutSpecialistsData['IND'].length > 0
              "
            >
              <mat-expansion-panel
                class="mat-elevation-z8"
                (opened)="activeLink = 'IND'"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Professional (Physician) Visit - Office
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-chip-list aria-label="Tab Selection">
                  <mat-chip
                    [selected]="activeLink === 'IND'"
                    [class]="activeLink === 'IND' ? 'mat-chip-active' : ''"
                    ><a (click)="activeLink = 'IND'">Individual</a>
                  </mat-chip>
                  <mat-chip
                    [selected]="activeLink === 'FAM'"
                    [class]="activeLink === 'FAM' ? 'mat-chip-active' : ''"
                    ><a (click)="activeLink = 'FAM'">Family</a>
                  </mat-chip>
                </mat-chip-list>

                <div class="row" style="position: relative">
                  <div class="col-md-12">
                    <table
                      mat-table
                      [dataSource]="
                        physicianVisitOfficeWithoutSpecialistsData[activeLink]
                      "
                      matSort
                      matSortDisableClear
                      style="position: relative; background: #eeeeee"
                    >
                      <ng-container matColumnDef="deductible">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span *ngIf="row?.newGroup === 'Y'">{{
                            row?.name
                          }}</span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="networkType">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span
                            *ngIf="
                              row?.newGroup === 'Y' || row?.newSubGroup === 'Y'
                            "
                          >
                            <span *ngIf="row?.inPlanNetworkIndicatorCode">
                              {{
                                row?.inPlanNetworkIndicatorCode === 'Y'
                                  ? 'In-Network'
                                  : row?.inPlanNetworkIndicatorCode === 'W'
                                  ? 'In and Out of
                              Network'
                                  : 'Out-of-Network'
                              }}
                            </span>
                            <span *ngIf="row?.insuranceType">
                              {{ row?.insuranceType }} {{ row?.planCoverage }}
                            </span>
                          </span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="individualOrFamily">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span
                            *ngIf="
                              row?.newGroup === 'Y' || row?.newSubGroup === 'Y'
                            "
                            >{{
                              row?.coverageLevelCode === 'IND'
                                ? 'Individual'
                                : ''
                            }}</span
                          >
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="benefitAmount">
                        <td mat-cell *matCellDef="let row">
                          <ng-container *ngIf="row?.benefitAmount">
                            {{ row?.benefitAmount | currency : 'USD' }}
                          </ng-container>
                          <ng-container
                            *ngIf="!row?.benefitAmount && row?.benefitPercent"
                          >
                            {{ row?.benefitPercent * 100 }}%
                          </ng-container>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="term">
                        <td mat-cell *matCellDef="let row">
                          {{
                            row?.timeQualifier === null
                              ? ''
                              : row?.timeQualifier === 'Remaining'
                              ? row?.timeQualifier
                              : 'Per
                          ' + row?.timeQualifier
                          }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="benefitBegin">
                        <td mat-cell *matCellDef="let row">
                          <span
                            *ngIf="row?.benefitsDateInformation?.benefitBegin"
                          >
                            Benefit Begin
                            {{
                              row?.benefitsDateInformation?.benefitBegin
                                | yyyymmddToUsFormat
                            }}
                          </span>
                          <span *ngIf="row?.authOrCertIndicator === 'Y'">
                            PRECERTIFICATION REQUIRED
                          </span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="additionalInformation">
                        <td mat-cell *matCellDef="let row">
                          <ul>
                            <ng-container
                              *ngFor="
                                let additionInfo of row?.additionalInformation
                              "
                            >
                              <li>{{ additionInfo.description }}</li>
                            </ng-container>
                          </ul>
                          <span
                            *ngIf="
                              row?.eligibilityAdditionalInformation
                                ?.industryCode
                            "
                            >On Campus -
                            {{
                              row?.eligibilityAdditionalInformation
                                ?.industryCode
                            }}</span
                          >
                          <ng-container *ngIf="row?.benefitsRelatedEntity">
                            <div>
                              {{ row?.benefitsRelatedEntity.entityIdentifier }}:
                              {{ row?.benefitsRelatedEntity.entityName }}
                            </div>
                            <div
                              *ngIf="
                                row?.benefitsRelatedEntity.contactInformation
                                  ?.contacts.length > 0
                              "
                            >
                              Information Contact:
                            </div>
                            <ng-container
                              *ngFor="
                                let contact of row?.benefitsRelatedEntity
                                  .contactInformation?.contacts
                              "
                            >
                              <div>
                                {{ contact.communicationMode }}:
                                {{ contact.communicationNumber }}
                              </div>
                            </ng-container>
                          </ng-container>
                        </td>
                      </ng-container>
                      <tr
                        mat-row
                        *matRowDef="
                          let row;
                          columns: displayedColumns;
                          let i = index
                        "
                      ></tr>
                    </table>
                  </div>
                </div>
              </mat-expansion-panel>
            </ng-container>

            <ng-container
              *ngIf="
                physicianVisitOfficeWithSpecialistsData[activeLink] &&
                physicianVisitOfficeWithSpecialistsData[activeLink].length > 0
              "
            >
              <mat-expansion-panel
                class="mat-elevation-z8"
                (opened)="activeLink = 'IND'"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Professional (Physician) Visit - Office - Specialist
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-chip-list aria-label="Tab Selection">
                  <mat-chip
                    [selected]="activeLink === 'IND'"
                    [class]="activeLink === 'IND' ? 'mat-chip-active' : ''"
                    ><a (click)="activeLink = 'IND'">Individual</a>
                  </mat-chip>
                  <mat-chip
                    [selected]="activeLink === 'FAM'"
                    [class]="activeLink === 'FAM' ? 'mat-chip-active' : ''"
                    ><a (click)="activeLink = 'FAM'">Family</a>
                  </mat-chip>
                </mat-chip-list>

                <div class="row" style="position: relative">
                  <div class="col-md-12">
                    <table
                      mat-table
                      [dataSource]="
                        physicianVisitOfficeWithSpecialistsData[activeLink]
                      "
                      matSort
                      matSortDisableClear
                      style="position: relative; background: #eeeeee"
                    >
                      <ng-container matColumnDef="deductible">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span *ngIf="row?.newGroup === 'Y'">{{
                            row?.name
                          }}</span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="networkType">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span
                            *ngIf="
                              row?.newGroup === 'Y' || row?.newSubGroup === 'Y'
                            "
                          >
                            <span *ngIf="row?.inPlanNetworkIndicatorCode">
                              {{
                                row?.inPlanNetworkIndicatorCode === 'Y'
                                  ? 'In-Network'
                                  : row?.inPlanNetworkIndicatorCode === 'W'
                                  ? 'In and Out of
                              Network'
                                  : 'Out-of-Network'
                              }}
                            </span>
                            <span *ngIf="row?.insuranceType">
                              {{ row?.insuranceType }} {{ row?.planCoverage }}
                            </span>
                          </span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="individualOrFamily">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span
                            *ngIf="
                              row?.newGroup === 'Y' || row?.newSubGroup === 'Y'
                            "
                            >{{
                              row?.coverageLevelCode === 'IND'
                                ? 'Individual'
                                : ''
                            }}</span
                          >
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="benefitAmount">
                        <td mat-cell *matCellDef="let row">
                          <ng-container *ngIf="row?.benefitAmount">
                            {{ row?.benefitAmount | currency : 'USD' }}
                          </ng-container>
                          <ng-container
                            *ngIf="!row?.benefitAmount && row?.benefitPercent"
                          >
                            {{ row?.benefitPercent * 100 }}%
                          </ng-container>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="term">
                        <td mat-cell *matCellDef="let row">
                          {{
                            row?.timeQualifier === null
                              ? ''
                              : row?.timeQualifier === 'Remaining'
                              ? row?.timeQualifier
                              : 'Per
                          ' + row?.timeQualifier
                          }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="benefitBegin">
                        <td mat-cell *matCellDef="let row">
                          <span
                            *ngIf="row?.benefitsDateInformation?.benefitBegin"
                          >
                            Benefit Begin
                            {{
                              row?.benefitsDateInformation?.benefitBegin
                                | yyyymmddToUsFormat
                            }}
                          </span>
                          <span *ngIf="row?.authOrCertIndicator === 'Y'">
                            PRECERTIFICATION REQUIRED
                          </span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="additionalInformation">
                        <td mat-cell *matCellDef="let row">
                          <ul>
                            <ng-container
                              *ngFor="
                                let additionInfo of row?.additionalInformation
                              "
                            >
                              <li>{{ additionInfo.description }}</li>
                            </ng-container>
                          </ul>
                          <span
                            *ngIf="
                              row?.eligibilityAdditionalInformation
                                ?.industryCode
                            "
                            >On Campus -
                            {{
                              row?.eligibilityAdditionalInformation
                                ?.industryCode
                            }}</span
                          >
                          <ng-container *ngIf="row?.benefitsRelatedEntity">
                            <div>
                              {{ row?.benefitsRelatedEntity.entityIdentifier }}:
                              {{ row?.benefitsRelatedEntity.entityName }}
                            </div>
                            <div
                              *ngIf="
                                row?.benefitsRelatedEntity.contactInformation
                                  ?.contacts.length > 0
                              "
                            >
                              Information Contact:
                            </div>
                            <ng-container
                              *ngFor="
                                let contact of row?.benefitsRelatedEntity
                                  .contactInformation?.contacts
                              "
                            >
                              <div>
                                {{ contact.communicationMode }}:
                                {{ contact.communicationNumber }}
                              </div>
                            </ng-container>
                          </ng-container>
                        </td>
                      </ng-container>
                      <tr
                        mat-row
                        *matRowDef="
                          let row;
                          columns: displayedColumns;
                          let i = index
                        "
                      ></tr>
                    </table>
                  </div>
                </div>
              </mat-expansion-panel>
            </ng-container>

            <ng-container *ngIf="limitationsData && limitationsData.length > 0">
              <mat-expansion-panel class="mat-elevation-z8">
                <mat-expansion-panel-header>
                  <mat-panel-title> Limitations </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="row" style="position: relative">
                  <div class="col-md-12">
                    <table
                      mat-table
                      [dataSource]="mapOfArrays['limitationsData']"
                      matSort
                      matSortDisableClear
                      style="position: relative; background: #eeeeee"
                    >
                      <ng-container matColumnDef="deductible">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span *ngIf="row?.newGroup === 'Y'"
                            >{{ row?.name }} ({{ row?.serviceTypes[0] }})</span
                          >
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="networkType">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span
                            *ngIf="
                              row?.newGroup === 'Y' || row?.newSubGroup === 'Y'
                            "
                          >
                            <span *ngIf="row?.inPlanNetworkIndicatorCode">
                              {{
                                row?.inPlanNetworkIndicatorCode === 'Y'
                                  ? 'In-Network'
                                  : row?.inPlanNetworkIndicatorCode === 'W'
                                  ? 'In and Out of
                              Network'
                                  : 'Out-of-Network'
                              }}
                            </span>
                            <span *ngIf="row?.insuranceType">
                              {{ row?.insuranceType }} {{ row?.planCoverage }}
                            </span>
                          </span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="individualOrFamily">
                        <td mat-cell *matCellDef="let row; index as i">
                          <span
                            *ngIf="
                              row?.newGroup === 'Y' || row?.newSubGroup === 'Y'
                            "
                            >{{
                              row?.coverageLevelCode === 'IND'
                                ? 'Individual'
                                : ''
                            }}</span
                          >
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="benefitAmount">
                        <td mat-cell *matCellDef="let row">
                          <ng-container *ngIf="row?.benefitQuantity">
                            {{ row?.benefitQuantity }}
                            {{ row?.quantityQualifier }}
                            <span *ngIf="row?.benefitsServiceDelivery">
                              per
                              {{ row?.benefitsServiceDelivery[0].numOfPeriods }}
                              {{
                                row?.benefitsServiceDelivery[0]
                                  .timePeriodQualifier
                              }}
                            </span>
                          </ng-container>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="term">
                        <td mat-cell *matCellDef="let row">
                          {{
                            row?.timeQualifier === null
                              ? ''
                              : row?.timeQualifier === 'Remaining'
                              ? row?.timeQualifier
                              : 'Per
                          ' + row?.timeQualifier
                          }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="benefitBegin">
                        <td mat-cell *matCellDef="let row">
                          <span
                            *ngIf="row?.benefitsDateInformation?.benefitBegin"
                          >
                            Benefit Begin
                            {{
                              row?.benefitsDateInformation?.benefitBegin
                                | yyyymmddToUsFormat
                            }}
                          </span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="additionalInformation">
                        <td mat-cell *matCellDef="let row">
                          <ul>
                            <ng-container
                              *ngFor="
                                let additionInfo of row?.additionalInformation
                              "
                            >
                              <li>{{ additionInfo.description }}</li>
                            </ng-container>
                          </ul>
                          <span
                            *ngIf="
                              row?.eligibilityAdditionalInformation
                                ?.industryCode
                            "
                            >On Campus -
                            {{
                              row?.eligibilityAdditionalInformation
                                ?.industryCode
                            }}</span
                          >
                        </td>
                      </ng-container>
                      <tr
                        mat-row
                        *matRowDef="
                          let row;
                          columns: displayedColumns;
                          let i = index
                        "
                      ></tr>
                    </table>
                  </div>
                </div>
              </mat-expansion-panel>
            </ng-container>
          </mat-accordion>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <ng-container
    *ngIf="row?.status !== 'Success' || row?.response?.errors?.length > 0"
  >
    <div class="row">
      <div class="col-md-12" style="margin-bottom: 25px">
        <mat-card
          appearance="outlined"
          *ngFor="let error of row?.response?.errors; let i = index"
          class="mat-elevation-z8"
          [ngStyle]="{ 'border-left': borderStyle }"
          style="margin-bottom: 10px"
        >
          <label class="labelText" style="color: red">{{
            error.description
          }}</label>
          <label class="labelText">{{ error.possibleResolutions }}</label>
        </mat-card>
      </div>
    </div>
  </ng-container>
</div>
