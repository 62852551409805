import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { IMyTimeSlotDuration } from 'src/app/_models/configure/mytimeslotduration.model';
import {
  MyTimeSlotDurationApiService,
  ToastMessageService,
} from 'src/app/_services';

interface Timings {
  minutes: number;
  text: string;
}

@Component({
  selector: 'app-slot-duration',
  templateUrl: './slot-duration.component.html',
  styleUrls: ['./slot-duration.component.css'],
})
export class SlotDurationComponent implements OnInit {
  public timeSlotDurationForm: FormGroup;
  loadingSlotDurationData: boolean = true;
  mytimeslotduration: IMyTimeSlotDuration;

  constructor(
    public mytimeslotdurationApiService: MyTimeSlotDurationApiService,
    private toastMessageService: ToastMessageService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.loadMyTimeSlotDuration();
  }

  private buildForm() {
    this.timeSlotDurationForm = this.formBuilder.group({
      id: new FormControl(null, Validators.compose([])),
      organizationId: new FormControl(null, Validators.compose([])),
      emailAddress: new FormControl(null, Validators.compose([])),
      timeSlotDuration: new FormControl(null, Validators.compose([])),
    });
  }

  loadMyTimeSlotDuration() {
    this.mytimeslotdurationApiService.getMyTimeSlotDurationDetails().subscribe(
      (mytimeslotduration) => {
        if (mytimeslotduration && mytimeslotduration.data) {
          this.timeSlotDurationForm.controls['id'].setValue(
            mytimeslotduration.data.id
          );
          this.timeSlotDurationForm.controls['organizationId'].setValue(
            mytimeslotduration.data.organizationId
          );
          this.timeSlotDurationForm.controls['emailAddress'].setValue(
            mytimeslotduration.data.emailAddress
          );
          this.timeSlotDurationForm.controls['timeSlotDuration'].setValue(
            mytimeslotduration.data.timeSlotDuration
          );
        }
        this.loadingSlotDurationData = false;
      },
      (error) => {
        this.loadingSlotDurationData = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to load session duration'
        );
      }
    );
  }

  public onTimeSlotDurationChange(event: MatSelectChange) {
    this.mytimeslotduration = Object.assign({}, this.mytimeslotduration);
    this.mytimeslotduration = Object.assign(
      this.mytimeslotduration,
      this.timeSlotDurationForm.value
    );
    this.mytimeslotduration.timeSlotDuration = Number.parseInt(
      event.source.triggerValue
    );
    this.mytimeslotdurationApiService
      .updateMyTimeSlotDuration(this.mytimeslotduration)
      .subscribe(
        (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully updated session duration'
          );
        },
        () => {
          this.toastMessageService.displayErrorMessage(
            'Failed to update session duration'
          );
        }
      );
  }

  timings: Timings[] = [
    { text: '15 Minutes', minutes: 15 },
    { text: '30 Minutes', minutes: 30 },
    { text: '60 Minutes', minutes: 60 },
  ];
}
