import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class SignatureService {
  constructor(private httpClient: HttpClient) {}

  saveSignature(fileType: string, file: File): Observable<any> {
    const formData: FormData = new FormData();

    formData.append('file', file);
    formData.append('fileType', fileType);

    return this.httpClient.post<any>(
      `${environment.apiUrl}/signature`,
      formData
    );
  }

  getSignature(): Observable<any> {
    let params = new HttpParams();

    return this.httpClient.get(`${environment.apiUrl}/signature`, {
      params: params,
    });
  }

  getSignatureById(id): Observable<any> {
    let params = new HttpParams();

    return this.httpClient.get(`${environment.apiUrl}/signature/${id}`, {
      params: params,
    });
  }

  saveGuestConsentSignature(
    requestId: string,
    accessCode: string,
    consentRequestId: string,
    consentDocumentId: string,
    pageName: string,
    pageUrl: string,
    fileType: string,
    file: File
  ): Observable<any> {
    const formData: FormData = new FormData();

    if (accessCode) formData.append('accessCode', accessCode);
    formData.append('requestId', requestId);
    formData.append('consentRequestId', consentRequestId);
    formData.append('consentDocumentId', consentDocumentId);
    formData.append('pageName', pageName);
    formData.append('pageUrl', pageUrl);

    formData.append('file', file);
    formData.append('fileType', fileType);

    return this.httpClient.post<any>(
      `${environment.apiUrl}/guestintake/signature`,
      formData
    );
  }
}
