import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { CustomDirectivesModule } from 'src/app/_directives/custom-directives.module';
import { AngularMaterialModule } from 'src/app/material-imports';
import { FacilitySearchComponent } from 'src/app/shared/components/search/facility-search/facility-search.component';
import { ProviderSearchComponent } from 'src/app/shared/components/search/provider-search/provider-search.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { AddAttemptDialogComponent } from './home/dialogs/add-attempt-dialog/add-attempt-dialog.component';
import { AddEditReferralComponent } from './home/dialogs/add-edit-referral/add-edit-referral.component';
import { HomeComponent } from './home/home.component';
import { ReferralStaffSearchComponent } from 'src/app/shared/components/search/referral-staff-search/referral-staff-search.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
  }
];

@NgModule({
  declarations: [HomeComponent, ProviderSearchComponent, FacilitySearchComponent, AddEditReferralComponent, AddAttemptDialogComponent, ReferralStaffSearchComponent],
  imports: [
    CommonModule,
    FormsModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    PipesModule,
    RouterModule.forChild(routes),
    CustomDirectivesModule,
    NgxMaterialTimepickerModule
  ],
  exports: [HomeComponent],
})
export class ReferralModule { }
