<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle *ngIf="this.action === 'ADD'">Add Note</p>
    <p matDialogTitle *ngIf="this.action === 'EDIT'">Edit Note</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <form [formGroup]="goalNotesForm" novalidate>
    <div class="row">
      <mat-form-field appearance="outline">
        <textarea
          matInput
          placeholder="Please add the notes here ..."
          formControlName="note"
          rows="8"
        ></textarea>
      </mat-form-field>
    </div>

    <div
      class="row"
      [style]="
        !isPatient
          ? 'justify-content: space-between'
          : 'justify-content:flex-end'
      "
    >
      <mat-checkbox
        color="primary"
        class="checkbox-margin"
        formControlName="visibleByPatient"
        *ngIf="!isPatient"
      >
        <span style="font-size: 11px">Visible By Member</span>
      </mat-checkbox>

      <button
        class="btn btn-primary"
        mat-raised-button
        type="button"
        (click)="submitGoalNotesForm()"
        [disabled]="!goalNotesForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </div>
  </form>
</div>
