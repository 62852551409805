import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { DataTablesModel, ResponseModal } from '../../../_models';
import { ITaps } from '../../../_models/assessments/addiction/taps.model';
@Injectable({ providedIn: 'root' })
export class TapsApiService {
    constructor(private httpClient: HttpClient) { }

    getTapsDetails(
        assessmentId: string
    ): Observable<ResponseModal> {
        return this.httpClient.get<ResponseModal>(
            `${environment.apiUrl}/assessments/taps/` + assessmentId
        );
    }

    addTaps(
        thisTaps: ITaps
    ): Observable<any> {
        const headers = { 'content-type': 'application/json' };
        return this.httpClient
            .post<ITaps>(
                `${environment.apiUrl}/assessments/taps`,
                thisTaps,
                { headers }
            )
            .pipe(
                catchError((err) => {
                    console.error(err);
                    throw err;
                })
            );
    }

    addGuestTaps(
        thisTaps: ITaps
    ): Observable<any> {
        const headers = { 'content-type': 'application/json' };
        return this.httpClient
            .post<ITaps>(
                `${environment.apiUrl}/guestassessments/taps`,
                thisTaps,
                { headers }
            )
            .pipe(
                catchError((err) => {
                    console.error(err);
                    throw err;
                })
            );
    }

    getTapsList(
        patientId: string,
        assessmentId: string,
        pageSize: number,
        pageIndex: number
    ): Observable<DataTablesModel> {
        let params = new HttpParams();
        params = params.append('patientId', patientId);
        params = params.append('assessmentId', assessmentId);
        params = params.append('pageSize', pageSize.toString());
        params = params.append('pageIndex', pageIndex.toString());

        return this.httpClient.get<DataTablesModel>(
            `${environment.apiUrl}/assessments/tapslist`,
            { params: params }
        );
    }
}
