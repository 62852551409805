<div class="row">
  <div class="col-sm-12 col-md-6 col-xl-4">
    <!-- Case Number -->
    <div class="longTwoGridContainer">
      <label class="labelBold">Case #</label>
      <label>
        {{ case?.caseNumber }}
      </label>
    </div>
    <!-- Room Number -->
    <div class="longTwoGridContainer" *ngIf="case?.roomNumber">
      <label class="labelBold">Room #</label>
      <label>
        {{ case?.roomNumber }}
      </label>
    </div>
    <!-- Case Manager -->
    <div class="longTwoGridContainer">
      <label class="labelBold">Case Manager</label>
      <label>
        {{ case?.caseManager }}
      </label>
    </div>
    <!-- Case Status -->
    <div class="longTwoGridContainer">
      <label class="labelBold">Status</label>
      <label
        class="statusCard"
        [style.color]="
          case.status === 'active'
            ? '#029102'
            : case.status === 'Transferred'
            ? '#d36d03'
            : '#a72323'
        "
        [style.background]="
          case.status === 'active'
            ? '#dbffdbde'
            : case.status === 'Transferred'
            ? '#efa7226e'
            : '#f3b3b3a1'
        "
      >
        {{ case?.status }}
      </label>
    </div>
  </div>

  <div class="col-sm-12 col-md-6 col-xl-4">
    <!-- Case Services -->
    <div class="longTwoGridContainer">
      <label class="labelBold">Services</label>
      <label>
        {{ case?.services }}
      </label>
    </div>
    <!-- Case Diagnosis -->
    <div class="longTwoGridContainer" *ngIf="case?.primaryDiagnosisCode">
      <label class="labelBold">Primary Diagnosis</label>
      <label>
        {{ case?.primaryDiagnosisCode }}
      </label>
    </div>
    <!-- Case Start -->
    <div class="longTwoGridContainer">
      <label class="labelBold">Admittance Date</label>
      <label>
        {{ case?.admittanceDate }}
      </label>
    </div>
    <!-- Case Close Date -->
    <div
      class="longTwoGridContainer"
      *ngIf="case.status != 'active' && case?.closeDate"
    >
      <label class="labelBold" style="text-transform: capitalize">
        Case {{ case.status }} Date
      </label>
      <label>
        {{ case?.closeDate }}
      </label>
    </div>
    <!-- Length of Stay -->
    <div class="longTwoGridContainer">
      <label class="labelBold">Length of Stay (LOS)</label>
      <label>
        {{ getLOS() }} {{ getLOS() <= 1 ? 'day' : 'days' }}
        <span
          *ngIf="case.status === 'active'"
          style="font-style: italic; color: #029102"
        >
          (Ongoing case)
        </span>
      </label>
    </div>
  </div>

  <div class="col-sm-12 col-md-6 col-xl-4">
    <!-- Case Description -->
    <div class="longTwoGridContainer" *ngIf="case?.description">
      <label class="labelBold">Description</label>
      <label>
        {{ case?.description }}
      </label>
    </div>

    <!-- Transferred Reason -->
    <div
      class="longTwoGridContainer"
      *ngIf="
        case?.transferredReasons &&
        case.status === 'Transferred' &&
        case?.transferredReasons?.length > 0
      "
    >
      <label class="labelBold">Transferred Reason</label>
      <label>
        {{ case?.transferredReasons }}
      </label>
    </div>

    <!-- Discharged Reasons  -->
    <div
      class="longTwoGridContainer"
      *ngIf="
        case?.dischargedReasons &&
        case.status === 'Discharged' &&
        case?.dischargedReasons?.length > 0
      "
    >
      <label class="labelBold">Discharged Reason</label>
      <label>
        {{ case?.dischargedReasons }}
      </label>
    </div>

    <!-- Discharged Reasons  -->
    <div class="longTwoGridContainer" *ngIf="case?.comments">
      <label class="labelBold">Comments</label>
      <label>
        {{ case?.comments }}
      </label>
    </div>

    <div class="longTwoGridContainer" *ngIf="hasDischargeSummaryDocuments">
      <label class="labelBold">Discharge Documents</label>
      <label
        class="labelPointer"
        (click)="triggerEvent('DOCUMENTS', 'DISCHARGE')"
      >
        View
      </label>
    </div>

    <!-- Grant Providers  -->
    <div
      class="longTwoGridContainer"
      *ngIf="
        case?.attachedGrantProviders && case?.attachedGrantProviders.length > 0
      "
    >
      <label class="labelBold"> Grant Providers </label>
      <div>
        <label
          *ngFor="let provider of case.attachedGrantProviders"
          style="padding: 0; margin: 0"
        >
          {{ provider.grantName }}
        </label>
      </div>
    </div>

    <!-- Insurance Providers  -->
    <div
      class="longTwoGridContainer"
      *ngIf="
        case?.attachedMemberInsurances &&
        case?.attachedMemberInsurances.length > 0
      "
    >
      <label class="labelBold"> Insurance Providers </label>
      <div>
        <label
          *ngFor="let provider of case.attachedMemberInsurances"
          style="padding: 0; margin: 0"
        >
          {{ provider.payerName }}
          <span *ngIf="provider.claimPayerCode">
            ({{ provider.claimPayerCode }})
          </span>
        </label>
      </div>
    </div>
  </div>
</div>
