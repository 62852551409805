export const MemberConditionsList: any = [
  { name: 'Alcohol Abuse', selected: false },
  { name: 'Anemia', selected: false },
  { name: 'Anesthetic Complication', selected: false },
  { name: 'Anxiety Disorder', selected: false },
  { name: 'Arthritis', selected: false },
  { name: 'Asthma', selected: false },
  { name: 'Autoimmune Problems', selected: false },
  { name: 'Birth Defects', selected: false },
  { name: 'Bladder Problems', selected: false },
  { name: 'Bleeding Disease', selected: false },
  { name: 'Blood Clots', selected: false },
  { name: 'Blood Transfusion(s)', selected: false },
  { name: 'Bowel Disease', selected: false },
  { name: 'Breast Cancer', selected: false },
  { name: 'Cervical Cancer', selected: false },
  { name: 'Colon Cancer', selected: false },
  { name: 'Depression', selected: false },
  { name: 'Diabetes', selected: false },
  { name: 'Growth/Development Disorder', selected: false },
  { name: 'Hearing Impairment', selected: false },
  { name: 'Heart Attack', selected: false },
  { name: 'Heart Disease', selected: false },
  { name: 'Heart Pain/Angina', selected: false },
  { name: 'Hepatitis A', selected: false },
  { name: 'Hepatitis B', selected: false },
  { name: 'Hepatitis C', selected: false },
  { name: 'High Blood Pressure', selected: false },
  { name: 'High Cholesterol', selected: false },
  { name: 'HIV', selected: false },
  { name: 'Hives', selected: false },
  { name: 'Kidney Disease', selected: false },
  { name: 'Liver Cancer', selected: false },
  { name: 'Liver Disease', selected: false },
  { name: 'Lung Cancer', selected: false },
  { name: 'Lung/Respiratory Disease', selected: false },
  { name: 'Mental Illness', selected: false },
  { name: 'Migraines', selected: false },
  { name: 'Osteoporosis', selected: false },
  { name: 'Prostate Cancer', selected: false },
  { name: 'Rectal Cancer', selected: false },
  { name: 'Reflux/GERD', selected: false },
  { name: 'Seizures/Convulsions', selected: false },
  { name: 'Severe Allergy', selected: false },
  { name: 'Sexually Transmitted Disease', selected: false },
  { name: 'Skin Cancer', selected: false },
  { name: 'Stroke/CVA of the Brain', selected: false },
  { name: 'Suicide Attempt', selected: false },
  { name: 'Thyroid Problems', selected: false },
  { name: 'Ulcer', selected: false },
  { name: 'Visual Impairment', selected: false },
  {
    name: 'Other Disease, Cancer, or Significant Medical Illness',
    selected: false,
  },
];
