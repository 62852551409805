import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { ToastMessageService, UsersService } from 'src/app/_services';

@Component({
  selector: 'app-change-password',
  templateUrl: 'change-password.component.html',
  styleUrls: ['change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {
  passwordForm: FormGroup;
  isSubmit: boolean = false;
  processing: boolean = false;
  showPassword: boolean = false;

  uppercaseRegex = /[A-Z]/;
  lowercaseRegex = /[a-z]/;
  digitRegex = /\d/;
  specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;

  newPasswordLengthValid: boolean = false;
  newPasswordUpperCaseValid: boolean = false;
  newPasswordLowerCaseValid: boolean = false;
  newPasswordOneNumberValid: boolean = false;
  newPasswordOneSpecialValid: boolean = false;

  constructor(
    private usersService: UsersService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit() {
    this.passwordForm = new FormGroup(
      {
        currentPassword: new FormControl('', Validators.required),
        newPassword: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(16),
          Validators.pattern(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,16}$/
          ),
        ]),
        confirmation: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(16),
          Validators.pattern(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,16}$/
          ),
        ]),
      },
      {
        validators: Validators.compose([
          this.newIsNotOld,
          this.newMatchesConfirm,
        ]),
      }
    );

    this.passwordForm.controls['newPassword'].valueChanges.subscribe(() => {
      this.checkPasswordConditions();
    });
  }

  newIsNotOld(group: FormGroup) {
    var newPW = group.controls['newPassword'];
    if (
      !group.controls['newPassword'].errors?.required &&
      group.controls['currentPassword'].value === newPW.value
    ) {
      newPW.setErrors({ newIsNotOld: true });
    }
    return null;
  }

  newMatchesConfirm(group: FormGroup) {
    var confirm = group.controls['confirmation'];
    if (
      !group.controls['confirmation'].errors?.required &&
      group.controls['newPassword'].value !== confirm.value
    ) {
      confirm.setErrors({ newMatchesConfirm: true });
    }
    return null;
  }

  get password() {
    return this.passwordForm.get('newPassword');
  }

  get confirm_password() {
    return this.passwordForm.get('confirmation');
  }

  onSubmit(formDirective: FormGroupDirective): boolean {
    this.isSubmit = true;
    if (this.passwordForm.invalid) {
      return false;
    } else {
      this.processing = true;
      this.usersService.updatePassword(this.passwordForm.value).subscribe(
        (res) => {
          if (res.result) {
            this.toastMessageService.displaySuccessMessage(res.message.text);
            formDirective.resetForm();
            this.passwordForm.reset();
          } else {
            this.toastMessageService.displayErrorMessage(res.message.text);
          }
          this.processing = false;
        },
        (error) => {
          this.toastMessageService.displayErrorMessage(
            'Failed to update password. '
          );
          this.processing = false;
        }
      );
      return true;
    }
  }

  get currentPassword() {
    return this.passwordForm.get('currentPassword');
  }

  get newPassword() {
    return this.passwordForm.get('newPassword');
  }

  get confirmation() {
    return this.passwordForm.get('confirmation');
  }

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  reset(formDirective: FormGroupDirective) {
    formDirective.resetForm();
    this.passwordForm.reset();
  }

  checkPasswordConditions() {
    let tempCurrentPassword = this.passwordForm.controls['newPassword'].value;

    // Check for length
    if (tempCurrentPassword.length >= 8 && tempCurrentPassword.length <= 16) {
      this.newPasswordLengthValid = true;
    } else {
      this.newPasswordLengthValid = false;
    }

    // Check for Upper Case
    if (this.uppercaseRegex.test(tempCurrentPassword)) {
      this.newPasswordUpperCaseValid = true;
    } else {
      this.newPasswordUpperCaseValid = false;
    }

    // Check for Lower Case
    if (this.lowercaseRegex.test(tempCurrentPassword)) {
      this.newPasswordLowerCaseValid = true;
    } else {
      this.newPasswordLowerCaseValid = false;
    }

    // Check for Numer
    if (this.digitRegex.test(tempCurrentPassword)) {
      this.newPasswordOneNumberValid = true;
    } else {
      this.newPasswordOneNumberValid = false;
    }

    // Check for Special Character
    if (this.specialCharRegex.test(tempCurrentPassword)) {
      this.newPasswordOneSpecialValid = true;
    } else {
      this.newPasswordOneSpecialValid = false;
    }
  }
}
