import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IBpsaFamilyHistoryList } from 'src/app/_models/session/clinical/bpsa-clinical-family-history-list.model';
import { IBpsaFamilyHistory } from 'src/app/_models/session/clinical/bpsa-clinical-family-history.model';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class BpsaFamilyHistoryApiService {
  constructor(private httpClient: HttpClient) {}

  updateBpsaFamilyHistory(
    thisBpsaFamilyHistory: IBpsaFamilyHistory
  ): Observable<IBpsaFamilyHistory> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<IBpsaFamilyHistory>(
        `${environment.apiUrl}/bpsafamilyhistory`,
        thisBpsaFamilyHistory,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateBpsaFamilyHistoryList(
    list: IBpsaFamilyHistoryList
  ): Observable<IBpsaFamilyHistoryList> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<IBpsaFamilyHistoryList>(
        `${environment.apiUrl}/bpsafamilyhealthconditions`,
        list,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
