import { Component, OnInit } from '@angular/core';
import { Patient } from 'src/app/_models';
import { PatientService, ToastMessageService } from 'src/app/_services';

@Component({
  selector: 'app-patient-self-information',
  templateUrl: './patient-self-information.component.html',
  styleUrl: './patient-self-information.component.css',
})
export class PatientSelfInformationComponent implements OnInit {
  isProcessing: boolean = false;
  showMemberInformation: boolean = false;
  patient: Patient;

  constructor(
    private patientApiService: PatientService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit(): void {
    this.loadMyDetails();
  }

  loadMyDetails() {
    this.isProcessing = true;
    this.patientApiService.getMyDetails().subscribe({
      next: (response) => {
        this.patient = response.data;
        this.isProcessing = false;
      },
      error: (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to load details'
        );
        this.isProcessing = false;
      },
    });
  }
}
