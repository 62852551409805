import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataTablesModel } from 'src/app/_models';
import {
  DateTimeZoneService,
  PatientAssessmentsService,
  ToastMessageService,
} from 'src/app/_services';
import { assessmentGroups } from 'src/app/shared/utilities/assessment-utils';
import { AssessmentChartDialogInterfaceComponent } from '../../../patient-details/patient-components/assessments/assessment-chart-dialog-interface/assessment-chart-dialog-interface.component';
import { AssessmentDialogInterfaceComponent } from '../../../patient-details/patient-components/assessments/assessment-dialog-interface/assessment-dialog-interface.component';
import { AssessmentMainDialogComponent } from '../../../patient-details/patient-components/assessments/assessment-main-dialog/assessment-main-dialog.component';

@Component({
  selector: 'app-patient-screen-assessments',
  templateUrl: './patient-screen-assessments.component.html',
  styleUrls: ['./patient-screen-assessments.component.css'],
})
export class PatientScreenAssessmentsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  assessmentGroups = assessmentGroups();
  isProcessing: boolean = false;
  data: DataTablesModel = {} as DataTablesModel;
  selectedTab: string = 'Pending';

  todaysDate = new Date();
  sortBy: string = 'None';
  filterOption: string = 'None';

  displayedColumns = ['type', 'requestedDate', 'action'];
  currentTimezone: string;

  constructor(
    private patientAssessmentService: PatientAssessmentsService,
    private spinner: NgxSpinnerService,
    private toastMessageService: ToastMessageService,
    public dialog: MatDialog,
    private dateTimeZoneService: DateTimeZoneService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit(): void {
    this.data.page = 0;
    this.data.per_page = 9;

    // Angular spinner
    this.spinner.show();

    // Load Dashboard Data
    this.loadPendingAssessments();
  }

  // Dashboard data
  loadPendingAssessments() {
    // Call in 2 separate API's to get pending and done request
    this.isProcessing = true;

    // Loading Assessments
    this.patientAssessmentService
      .getMyAssessments(this.selectedTab, this.data.per_page, this.data.page)
      .subscribe(
        (response) => {
          if (response) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }
          this.isProcessing = false;
        },
        (error) => {
          this.isProcessing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get assessment data'
          );
          this.data.items = [];
          this.data.total = 0;
        }
      );
  }

  // Switching the tabs
  switchTab(tabName: string) {
    this.selectedTab = tabName;
    this.data.page = 0;
    this.sortBy = 'None';
    this.filterOption = 'None';

    if (this.selectedTab === 'Pending') {
      this.loadPendingAssessments();
    } else if (this.selectedTab === 'In Review') {
      this.displayedColumns = ['type', 'requestedDate', 'action'];
      this.loadCompletedData();
    } else if (this.selectedTab === 'Completed') {
      this.displayedColumns = ['type', 'requestedDate', 'action'];
      this.loadCompletedData();
    }
  }

  loadCompletedData() {
    this.isProcessing = true;

    let filter = '';
    let sort = '';

    // Determining filter option here
    if (this.filterOption === 'None') {
      filter = null;
    } else {
      filter = this.filterOption;
    }

    // Determining sort option here
    if (this.sortBy === 'None') {
      sort = null;
    } else {
      if (this.sortBy === 'Date closest to now') {
        sort = 'DESC';
      }
      if (this.sortBy === 'Date farthest from now') {
        sort = 'ASC';
      }
    }

    this.patientAssessmentService
      .getSubmittedAssisgnments(
        this.selectedTab,
        this.data.per_page,
        this.data.page,
        sort,
        filter
      )
      .subscribe({
        next: (response) => {
          if (response) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }
          this.isProcessing = false;
        },
        error: (error) => {
          this.isProcessing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get ' + this.selectedTab + ' assessment data'
          );
          this.data.items = [];
          this.data.total = 0;
        },
      });
  }

  // Take Assessment()
  takeAssessment(assessment) {
    const dialogRef = this.dialog.open(AssessmentMainDialogComponent, {
      minWidth: '50vw',
      minHeight: '50vh',
      maxHeight: '95vh',
      disableClose: true,
      autoFocus: false,
      data: {
        patientId: assessment.patientId,
        sessionId: assessment.sessionId,
        assessmentType: assessment.assessmentType,
        requestId: assessment.id,
      },
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadPendingAssessments();
      }
    });
  }

  // pagination event
  getNext(event) {
    this.data.page = event.pageIndex;
    if (this.selectedTab === 'Pending') {
      this.loadPendingAssessments();
    } else if (this.selectedTab === 'In Review') {
      this.loadCompletedData();
    } else if (this.selectedTab === 'Completed') {
      this.loadCompletedData();
    }
  }

  // Display important if the assessment requested is > 7 days
  passedSevenDays(assessmentDate: Date) {
    let currentTime = this.todaysDate.getTime();
    let requestedTime = new Date(assessmentDate).getTime();

    let difference = Math.abs(currentTime - requestedTime);
    let differenceDays = Math.ceil(difference / (1000 * 3600 * 24));
    if (differenceDays >= 7) {
      return true;
    } else {
      return false;
    }
  }

  openDialog(
    dialogType: string,
    assessmentType: string,
    assessmentId: string,
    assessmentRequestId: string,
    patientId: string
  ) {
    let dialogComponent: any = {};
    dialogComponent.history = AssessmentDialogInterfaceComponent;
    dialogComponent.chart = AssessmentChartDialogInterfaceComponent;

    if (dialogComponent) {
      let component = dialogComponent.history;
      if (dialogType === 'chart') {
        component = dialogComponent.chart;
      }
      const dialogRef = this.dialog.open(component, {
        minWidth: '50vw',
        maxWidth: '90vw',
        minHeight: '50vh',
        maxHeight: '90vh',
        disableClose: true,
        autoFocus: false,
        data: {
          patientId: patientId,
          assessmentId: assessmentId,
          assessmentRequestId: assessmentRequestId,
          assessmentType: assessmentType,
          displayTitle: true,
          isPatient: true,
        },
      });
    }
  }

  // Filter and sort options
  sortByChanged(sortOption: string) {
    // First restore pagination
    this.data.page = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }

    this.sortBy = sortOption;
    this.loadCompletedData();
  }

  filterOptionChanged(filterOption: string) {
    // First restore pagination
    this.data.page = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }

    this.filterOption = filterOption;
    this.loadCompletedData();
  }
}
