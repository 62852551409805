<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>{{ requestedDocument?.documentName }}</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
