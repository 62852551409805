<div style="padding: 10px">
  <div class="row" *ngIf="
      therapySession.status === 'Active' &&
      hasGoalsAddAccess &&
      patient.status === 'Active'
    " style="margin-bottom: 15px">
    <div class="col-md-12" style="display: flex; justify-content: space-between">
      <!-- Goals Library -->
      <button mat-raised-button type="button" class="mat-elevation-z8"  (click)="openLibrary()" style="color: #2196f3">
        <mat-icon>library_books</mat-icon> Goals Library
      </button>

      <!-- Add Goal -->
      <button mat-raised-button type="button" class="mat-elevation-z8" style="color: #2196f3" (click)="addNewGoal()">
        + Add Goal
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <app-added-goals class="case-notes-list mat-elevation-z8" [patient]="patient" [data]="data"
        [therapySession]="therapySession" [loggedInUserId]="loggedInUserId" [isLoadingResults]="isLoadingResults"
        [assignedGoalStatus]="assignedGoalStatus" [hasGoalsAddAccess]="hasGoalsAddAccess"
        [hasGoalsEditAccess]="hasGoalsEditAccess" [hasGoalsDeleteAccess]="hasGoalsDeleteAccess"
        style="width: 100%; background-color: #c1d1c9; min-height: 100%" (hasChanges)="hasChanges($event)"
        (changeAssignedGoalStatus)="changeAssignedGoalStatus($event)">
      </app-added-goals>
    </div>
  </div>
</div>