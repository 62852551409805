import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Editor, Toolbar, toDoc, toHTML } from 'ngx-editor';
import { schema } from 'ngx-editor/schema';
import { DataTablesModel, ITherapySession, Patient } from 'src/app/_models';
import { TherapySessionService, ToastMessageService } from 'src/app/_services';
import { ClinicalNoteSectionsApiService } from 'src/app/_services/session/clinical/clinicalnotesections.service';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { USERSETTINGSPROPS } from 'src/app/shared/constants/userSettings';
import { FavoritesSandbox } from 'src/app/shared/sandbox/favorites-sandbox';
import { UserSettingsPropsSandbox } from 'src/app/shared/sandbox/user-settings-props.sandbox';
import { ChangeNoteTypeDialogComponent } from '../dialogs/change-note-type-dialog/change-note-type-dialog.component';
import { LoadExistingClinicalNoteComponent } from '../load-existing-clinical-note/load-existing-clinical-note.component';
import { SessionAddClinicalNoteComponent } from '../session-add-clinical-note/session-add-clinical-note.component';

@Component({
  selector: 'app-session-clinical-notes',
  templateUrl: './session-clinical-notes.component.html',
  styleUrl: './session-clinical-notes.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class SessionClinicalNotesComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() patient: Patient;
  @Input() therapySession: ITherapySession;
  @Input() hasClinicalNotesViewAccess: boolean;
  @Input() hasClinicalNotesAddAccess: boolean;
  @Input() hasClinicalNotesEditAccess: boolean;
  @Output() output = new EventEmitter<any>();
  @Output() expandrow = new EventEmitter<string>();

  clinicalNoteTypes: DataTablesModel = {} as DataTablesModel;
  clinicalNoteIdMap: Map<string, any> = new Map<string, any>();

  hasChangesMap: { [key: string]: boolean } = {};
  hadChangesEarlier: { [key: string]: boolean } = {};

  changeTimeout: any;
  therapySessionData: any;

  editor: Editor[] = [];
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['align_left', 'align_center', 'align_right'],
    ['horizontal_rule'],
  ];

  clinicalNoteForm: FormGroup;
  selectedSectionId: string;
  selectedSectionIndex: number;
  selectedSectionName: string;
  activeTab: string = 'Text Shortcuts';

  hasChanges: boolean = false;
  autosaved: boolean = false;
  tabPanelOpen: boolean = false;
  isLoadingNoteTypeData: boolean = false;
  isLoadingShortCutsTemplates: boolean = false;
  isLoadingNGXEditors: boolean = false;

  // Sandbox and font size
  editorFontSize: number = 14;

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private clinicalNoteSectionsApiService: ClinicalNoteSectionsApiService,
    public therapysessionApiService: TherapySessionService,
    private toastMessageService: ToastMessageService,
    private favoritesSandbox: FavoritesSandbox,
    private userSettingsPropsSandbox: UserSettingsPropsSandbox
  ) {
    // Subcribe to user settings for font size
    this.userSettingsPropsSandbox.userSettingsProps$.subscribe(
      (userSettings) => {
        if (userSettings) {
          if (userSettings[USERSETTINGSPROPS.CLINICAL_NOTE_FONT]) {
            this.editorFontSize = Number(
              userSettings[USERSETTINGSPROPS.CLINICAL_NOTE_FONT]
            );
            // Re trigger font size
            this.handleFontSizeChange('Re-Trigger');
          }
        }
      }
    );
  }

  ngAfterViewInit(): void {
    this.handleFontSizeChange('Re-Trigger');
  }

  ngOnInit(): void {
    this.clinicalNoteForm = this.formBuilder.group({});

    // Load the exisitng note and editiors only when a note type already exists
    if (
      this.therapySession?.clinicalNoteId ||
      this.therapySession?.sessionType === 'Phone Note'
    ) {
      this.loadNGXEditor();
    }

    this.loadOrganizationAllActiveNoteTypes();
  }

  ngOnDestroy(): void {
    if (this.hasChanges && !this.autosaved) {
      this.output.emit({
        eventType: 'RELOAD_THERPAY_OBJECT_AFTER_1_SECOND',
        therapySession: null,
        sessionId: this.therapySession?.id,
      });
      this.saveNotes();
    } else if (this.therapySessionData) {
      this.output.emit({
        eventType: 'RELOAD_THERPAY_OBJECT',
        therapySession: this.therapySessionData,
      });
    }
    // Destroy all NGX Editors
    this.destroyAllNGXEditors();
  }

  destroyAllNGXEditors() {
    this.editor.forEach((editor) => {
      editor.destroy();
    });
  }

  loadOrganizationAllActiveNoteTypes() {
    this.isLoadingNoteTypeData = true;
    this.clinicalNoteSectionsApiService
      .getOrganizationAllActiveNotesList()
      .subscribe({
        next: (response) => {
          if (response && response.items) {
            this.clinicalNoteTypes.items = response.items;
            this.clinicalNoteTypes.total = response.total;

            // Set map for each note type name -> id
            response.items.forEach((item) => {
              this.clinicalNoteIdMap.set(item.id, item);
            });
          } else {
            this.clinicalNoteTypes.items = [];
            this.clinicalNoteTypes.total = 0;
          }
          this.isLoadingNoteTypeData = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get the clinical note types'
          );
          this.isLoadingNoteTypeData = false;
        },
      });
  }

  async loadNGXEditor() {
    this.isLoadingNGXEditors = true;

    await this.therapySession.sessionNotes.sections.forEach((section, i) => {
      this.editor.push(new Editor());
      // Now check if this section already has data
      if (section.payload) {
        let ngxEditorData = section.payload;
        let htmlNGXData = toHTML(ngxEditorData);
        // Add form control with existing value
        this.clinicalNoteForm.addControl(
          section.id,
          this.formBuilder.control(htmlNGXData, Validators.required)
        );
      } else {
        // Add a empty form control
        this.clinicalNoteForm.addControl(
          section.id,
          this.formBuilder.control('', Validators.required)
        );
      }
      this.isLoadingNGXEditors = false;
    });

    // Retrigger font -size
    this.handleFontSizeChange('Re-Trigger');

    // Disable if the required permissions have not been granted
    if (
      (!this.hasClinicalNotesEditAccess && !this.hasClinicalNotesAddAccess) ||
      this.therapySession.status != 'Active'
    ) {
      this.clinicalNoteForm.disable();
    }
  }

  saveNotes() {
    // For each section, generating the payload to save
    let payload: any = [];

    if (!this.therapySession?.sessionNotes?.sections) {
      return;
    }

    this.therapySession.sessionNotes.sections.forEach((section, i) => {
      let jsonDoc = toDoc(
        this.clinicalNoteForm.controls[section.id].value,
        schema
      );
      payload.push({
        id: section.id,
        name: section.name,
        payload: jsonDoc,
      });
    });

    this.autosaved = false;
    this.therapysessionApiService
      .updateSessionNote(this.therapySession.id, { sections: payload })
      .subscribe({
        next: (response) => {
          this.therapySessionData = response.data;
          this.autosaved = true;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to update the clinical notes.'
          );
          this.autosaved = false;
        },
      });
  }

  //  Side Panel For Shortcuts and Templates
  openTabPanel(
    type: string,
    sectionId: string,
    sectionName: string,
    index: number
  ) {
    this.tabPanelOpen = true;
    this.selectedSectionId = sectionId;
    this.selectedSectionName = sectionName;
    this.selectedSectionIndex = index;

    this.activeTab = type;
    this.expandrow.emit('expand');
  }

  closePanel() {
    this.tabPanelOpen = false;
    this.selectedSectionId = null;
    this.selectedSectionName = null;
    this.selectedSectionIndex = null;
    this.activeTab = null;

    this.expandrow.emit('collapse');
  }

  getContent(shortcut) {
    let htmlContents = toHTML(shortcut.content);
    return htmlContents;
  }

  handleShortCutResponse(shortcut) {
    var currentValue =
      this.clinicalNoteForm.controls[this.selectedSectionId].value;

    // If there is existing value add these in a new line
    if (currentValue) {
      this.clinicalNoteForm.controls[this.selectedSectionId].setValue(
        currentValue + '<p/>' + toHTML(shortcut.content)
      );
    } else {
      this.clinicalNoteForm.controls[this.selectedSectionId].setValue(
        toHTML(shortcut.content)
      );
    }
  }

  handleTemplateResponse(response) {
    var currentValue =
      this.clinicalNoteForm.controls[this.selectedSectionId].value;

    // If there is existing value add these in a new line
    if (currentValue) {
      this.clinicalNoteForm.controls[this.selectedSectionId].setValue(
        currentValue + '<p/>' + response
      );
    } else {
      this.clinicalNoteForm.controls[this.selectedSectionId].setValue(response);
    }
  }

  // Load Data from the last Note
  loadLastNote() {
    let dialogRef = this.dialog.open(LoadExistingClinicalNoteComponent, {
      data: { therapySession: this.therapySession },
      autoFocus: false,
      disableClose: true,
      minWidth: '65vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response.type === 'success') {
        // Now we need to load this note to existing form controls in ngx editor
        if (response.sessionNotes) {
          response.sessionNotes.sections.forEach((section) => {
            // Now check if this section already has data
            if (section.payload) {
              let ngxEditorData = section.payload;
              let htmlNGXData = toHTML(ngxEditorData);
              // Add form control with existing value if the section exists
              if (this.clinicalNoteForm.controls[section.id]) {
                this.clinicalNoteForm.controls[section.id].setValue(
                  htmlNGXData
                );
              }
            }
          });
        }
      }
    });
  }

  // Change Editor click section
  editorClicked(event, sectionId, sectionName, index) {
    this.selectedSectionId = sectionId;
    this.selectedSectionName = sectionName;
    this.selectedSectionIndex = index;
  }

  changeNoteType() {
    let dialog = this.dialog.open(ChangeNoteTypeDialogComponent, {
      data: {
        clinicalNoteTypes: this.clinicalNoteTypes.items,
        therapySession: this.therapySession,
      },
      disableClose: true,
      autoFocus: false,
    });

    dialog.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        this.isLoadingNGXEditors = true;
        this.therapySession = response.therapySession;

        this.output.emit({
          eventType: 'RELOAD_THERPAY_OBJECT',
          therapySession: response.therapySession,
        });

        // Reset All the values of the form
        this.clinicalNoteForm.reset();

        // Reset All the values of the form
        this.therapySession.sessionNotes.sections.forEach((section, i) => {
          if (section.payload) {
            section.payload = null;
          }
          if (this.clinicalNoteForm.controls[section.id]) {
            this.clinicalNoteForm.controls[section.id].setValue('');
          }
        });

        // Destroy existing editorys
        this.destroyAllNGXEditors();

        // Now Reload the Form Control
        this.editor = [];
        this.loadNGXEditor();
      }
    });
  }

  addNewClinicalNote() {
    let dialogRef = this.dialog.open(SessionAddClinicalNoteComponent, {
      data: {
        therapySession: this.therapySession,
        patient: this.patient,
        type: 'Individual',
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response.type === 'success') {
        // Emit Therapy Session Change
        this.output.emit({
          eventType: 'RELOAD_THERPAY_OBJECT',
          therapySession: response.data,
        });

        this.isLoadingNGXEditors = true;
        this.therapySession = response.data;

        this.editor = [];
        this.loadNGXEditor();
      }
    });
  }

  deleteNote() {
    const dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to delete this clinical note?',
      },
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.therapysessionApiService
          .deleteClinicalNote(this.therapySession.id)
          .subscribe({
            next: (response) => {
              this.toastMessageService.displaySuccessMessage(
                'Successfully deleted the note type.'
              );

              this.output.emit({
                eventType: 'RELOAD_THERPAY_OBJECT',
                therapySession: response.data,
              });

              this.therapySession = response.data;
              // Reset All the values of the form
              this.clinicalNoteForm.reset();

              // Destroy existing editorys
              this.destroyAllNGXEditors();

              // Now Reload the Form Control
              this.editor = [];
            },
            error: (error) => {
              this.toastMessageService.displayErrorMessage(
                'Failed to delete the note type.'
              );
            },
          });
      }
    });
  }

  // ngModelChange handler to capture changes from the editor
  onEditorChange(event: any, sectionId: string): void {
    // Set the hasChanges flag to true when there are changes
    this.hasChangesMap[sectionId] = true;
    this.autosaved = false;
    this.hasChanges = true;

    // Clear any previous timeouts and reset the timeout to 5 seconds
    if (this.changeTimeout) {
      clearTimeout(this.changeTimeout);
    }

    // Set a timeout to trigger the API call after 5 seconds of inactivity
    this.changeTimeout = setTimeout(() => {
      if (this.hasChangesMap[sectionId]) {
        this.hasChangesMap[sectionId] = false; // Reset the flag
        this.hadChangesEarlier[sectionId] = true;
        this.saveNotes(); // Call API after 5 seconds
      }
    }, 500);
  }

  // Handling of font size for ngx editor
  handleFontSizeChange(type) {
    const editorContents = document.querySelectorAll(
      '.NgxEditor__Content'
    ) as NodeListOf<HTMLElement>;
    // Loop through all the selected elements and apply the changes
    // Adjust font size based on the action
    if (type === 'increase') {
      this.editorFontSize += 1;
    } else if (type === 'decrease') {
      this.editorFontSize -= 1;
    }
    // Optionally, keep the font size within reasonable limits
    if (this.editorFontSize < 10) {
      this.editorFontSize = 10; // Min size
    } else if (this.editorFontSize > 30) {
      this.editorFontSize = 30; // Max size
    }
    // Dispatch the even to store this font size
    if (type != 'Re-Trigger') {
      this.userSettingsPropsSandbox.handleUserSettingsPropsChange(
        USERSETTINGSPROPS.CLINICAL_NOTE_FONT,
        this.editorFontSize.toString()
      );
    }

    editorContents.forEach((editorContent) => {
      // Apply the new font size to each element
      editorContent.style.fontSize = `${this.editorFontSize}px`;
    });
  }
}
