<mat-card-title>Lab Orders</mat-card-title>
<mat-divider></mat-divider>

<div class="row" style="padding: 10px">
    <div class="col-md-12" style="text-align: right">
        <button type="button" mat-raised-button color="warn" (click)="addLabOrder()">
            + Lab Order
        </button>
    </div>
</div>
<div *ngIf="data.total === 0">
    <mat-error>
        No Lab Orders found
    </mat-error>
</div>
<div *ngIf="isLoadingResults || apiError">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <mat-error *ngIf="apiError">
        An error occurred while retrieving Lab Order records
    </mat-error>
</div>
<div class="mat-elevation-z8" *ngIf="data.total > 0">
    <div>
        <table mat-table [dataSource]="data.items" class="mat-elevation-z8" matSort matSortDisableClear>
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>Id</th>
                <td mat-cell *matCellDef="let row">{{row.id}}</td>
            </ng-container>
            <ng-container matColumnDef="organizationId">
                <th mat-header-cell *matHeaderCellDef>Organization Id</th>
                <td mat-cell *matCellDef="let row">{{row.organizationId}}</td>
            </ng-container>
            <ng-container matColumnDef="patientId">
                <th mat-header-cell *matHeaderCellDef>Patient Id</th>
                <td mat-cell *matCellDef="let row">{{row.patientId}}</td>
            </ng-container>
            <ng-container matColumnDef="testType">
                <th mat-header-cell *matHeaderCellDef>Test Type</th>
                <td mat-cell *matCellDef="let row">{{row.testType}}</td>
            </ng-container>
            <ng-container matColumnDef="testDate">
                <th mat-header-cell *matHeaderCellDef>Test Date</th>
                <td mat-cell *matCellDef="let row">{{row.testDate}}</td>
            </ng-container>
            <ng-container matColumnDef="fasting">
                <th mat-header-cell *matHeaderCellDef>Fasting</th>
                <td mat-cell *matCellDef="let row">{{row.fasting}}</td>
            </ng-container>
            <ng-container matColumnDef="patientFirstName">
                <th mat-header-cell *matHeaderCellDef>Patient First Name</th>
                <td mat-cell *matCellDef="let row">{{row.patientFirstName}}</td>
            </ng-container>
            <ng-container matColumnDef="patientLastName">
                <th mat-header-cell *matHeaderCellDef>Patient Last Name</th>
                <td mat-cell *matCellDef="let row">{{row.patientLastName}}</td>
            </ng-container>
            <ng-container matColumnDef="patientDob">
                <th mat-header-cell *matHeaderCellDef>Patient Dob</th>
                <td mat-cell *matCellDef="let row">{{row.patientDob}}</td>
            </ng-container>
            <ng-container matColumnDef="patientEmail">
                <th mat-header-cell *matHeaderCellDef>Patient Email</th>
                <td mat-cell *matCellDef="let row">{{row.patientEmail}}</td>
            </ng-container>
            <ng-container matColumnDef="patientPhone">
                <th mat-header-cell *matHeaderCellDef>Patient Phone</th>
                <td mat-cell *matCellDef="let row">{{row.patientPhone}}</td>
            </ng-container>
            <ng-container matColumnDef="patientAddressLine1">
                <th mat-header-cell *matHeaderCellDef>Patient Address Line 1</th>
                <td mat-cell *matCellDef="let row">{{row.patientAddressLine1}}</td>
            </ng-container>
            <ng-container matColumnDef="patientAddressLine2">
                <th mat-header-cell *matHeaderCellDef>Patient Address Line 2</th>
                <td mat-cell *matCellDef="let row">{{row.patientAddressLine2}}</td>
            </ng-container>
            <ng-container matColumnDef="patientCity">
                <th mat-header-cell *matHeaderCellDef>Patient City</th>
                <td mat-cell *matCellDef="let row">{{row.patientCity}}</td>
            </ng-container>
            <ng-container matColumnDef="patientState">
                <th mat-header-cell *matHeaderCellDef>Patient State</th>
                <td mat-cell *matCellDef="let row">{{row.patientState}}</td>
            </ng-container>
            <ng-container matColumnDef="patientZip">
                <th mat-header-cell *matHeaderCellDef>Patient Zip</th>
                <td mat-cell *matCellDef="let row">{{row.patientZip}}</td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let row; let i=index;">
                    <button mat-raised-button class="btn btn-just-icon btn-white btn-fab btn-round pull-right"
                        color="accent" (click)="editLabOrder(row)">
                        <i class="material-icons text_align-center">more_vert</i>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <mat-paginator [length]="data.total" [pageSize]="data.per_page" *ngIf="data.total > data.per_page"
        (page)="getNext($event)"></mat-paginator>
</div>