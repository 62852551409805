export const SessionQueues = [
  'Scheduled',
  'Rescheduled',
  'Confirmed',
  'Arrived',
  'Checked In',
  'Checked Out',
  'No Show',
  'Canceled',
];

export const SessionQueuesColorMap = new Map<string, string>([
  ['Scheduled', '#ffffff'],
  ['Rescheduled', '#ffffff'],
  ['Confirmed', '#ffffff'],
  ['Arrived', '#ffffff'],
  ['Checked In', '#ffffff'],
  ['Checked Out', '#ffffff'],
  ['No Show', '#d92550'],
  ['Canceled', '#d92550'],
  ['Closed', '#b6b6b6'],
]);
