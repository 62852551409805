import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ITherapySession } from 'src/app/_models';

@Component({
  selector: 'app-edit-billing-rendering-provider',
  templateUrl: './edit-billing-rendering-provider.component.html',
  styleUrls: ['./edit-billing-rendering-provider.component.css'],
})
export class EditBillingRenderingProviderComponent implements OnInit {
  therapySession: ITherapySession;
  type: string = '';
  passwordView: boolean = false;

  providerForm: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<EditBillingRenderingProviderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    this.type = data.type;
    this.therapySession = data.therapySession;
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.providerForm = this.formBuilder.group({
      npi: new FormControl(
        this.type === 'Billing'
          ? this.therapySession.billingProviderGroupNPI
          : this.therapySession.physicianNPI,
        Validators.required
      ),
      taxonomy: new FormControl(
        this.type === 'Billing'
          ? this.therapySession.billingProviderTaxonomyCode
          : this.therapySession.physicianTaxonomyCode
      ),
      billingOption: new FormControl(
        this.type === 'Billing'
          ? this.therapySession.billingProviderBillingOption
          : this.therapySession.physicianBillingOption,
        Validators.required
      ),
      taxId: new FormControl(
        this.type === 'Billing'
          ? this.therapySession.billingProviderTaxId
          : this.therapySession.physicianTaxId
      ),
      ssn: new FormControl(
        this.type === 'Billing'
          ? this.therapySession.billingProviderSSN
          : this.therapySession.physicianSSN
      ),
    });
  }

  billingOptionChanged(event) {
    if (event.value === 'Tax Id') {
      this.providerForm.get('taxId').addValidators(Validators.required);
      this.providerForm.get('taxId').updateValueAndValidity();

      this.providerForm.get('ssn').clearValidators();
      this.providerForm.get('ssn').setValue('');
      this.providerForm.get('ssn').updateValueAndValidity();
    } else {
      this.providerForm.get('ssn').addValidators(Validators.required);
      this.providerForm.get('ssn').updateValueAndValidity();

      this.providerForm.get('taxId').clearValidators();
      this.providerForm.get('taxId').setValue('');
      this.providerForm.get('taxId').updateValueAndValidity();
    }
  }

  submitForm() {
    let formValue = Object.assign({}, this.providerForm.value);
    this.dialogRef.close({ type: 'success', updatedProvder: formValue });
  }
}
