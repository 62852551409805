<mat-card
  appearance="raised"
  style="margin-bottom: 20px; border-radius: 15px; padding: 30px"
>
  <h3 style="font-size: 24; font-weight: bold">
    Appointments <span>&nbsp;&nbsp;({{ data.total }})</span>
  </h3>

  <mat-card-content>
    <!-- Search Box -->
    <div
      class="row"
      style="margin-right: 5px; row-gap: 15px; margin-bottom: 15px"
    >
      <div
        class="col-sm-12"
        style="
          column-gap: 20px;
          display: flex;
          flex-wrap: wrap;
          text-align: right;
          flex-direction: row-reverse;
          padding: 0;
          row-gap: 10px;
        "
      >
        <button mat-raised-button [matMenuTriggerFor]="sortMenu">
          <span
            style="white-space: break-spaces; color: #3ac47d"
            *ngIf="searchStatus === 'Active'"
          >
            Status: Active
          </span>
          <span
            style="white-space: break-spaces; color: #d92550"
            *ngIf="searchStatus === 'Canceled'"
          >
            Status: Canceled
          </span>
          <span
            style="white-space: break-spaces; color: #d92550"
            *ngIf="searchStatus === 'Closed'"
          >
            Status: Closed
          </span>
          <mat-icon>arrow_drop_down</mat-icon>
        </button>

        <mat-menu #sortMenu="matMenu">
          <button
            mat-menu-item
            style="font-weight: 400; font-size: 12px; width: 100%"
            (click)="filterOptionChanged('Active')"
          >
            Active
          </button>
          <button
            mat-menu-item
            style="font-weight: 400; font-size: 12px; width: 100%"
            (click)="filterOptionChanged('Closed')"
          >
            Closed
          </button>
          <button
            mat-menu-item
            style="font-weight: 400; font-size: 12px; width: 100%"
            (click)="filterOptionChanged('Canceled')"
          >
            Canceled
          </button>
        </mat-menu>
      </div>
    </div>

    <div *ngIf="data.total === 0 && !isLoadingResults">
      <mat-error> No appointments found </mat-error>
    </div>

    <div *ngIf="isLoadingResults || apiError">
      <mat-progress-bar
        mode="indeterminate"
        *ngIf="isLoadingResults"
      ></mat-progress-bar>

      <mat-error *ngIf="apiError">
        An error occurred while retrieving Therapy Session records
      </mat-error>
    </div>

    <div class="mat-elevation-z8" *ngIf="data.total > 0">
      <div class="table-responsive">
        <table
          mat-table
          [dataSource]="data.items"
          class="mat-elevation-z8"
          matSort
          matSortDisableClear
        >
          <ng-container matColumnDef="time">
            <th mat-header-cell *matHeaderCellDef>Details</th>
            <td mat-cell *matCellDef="let row" style="position: relative">
              <label class="labelBold">{{
                row.start | date : 'M/d/y' : currentTimezone
              }}</label>
              <label style="margin-left: 12px">
                ({{ row.start | date : 'h:mm a' : currentTimezone }} -
                {{ row.end | date : 'h:mm a' : currentTimezone }}
                {{ currentTimezone }})
              </label>

              <div *ngIf="row.groupAppointment">
                <span
                  class="justifyCenterContainer"
                  style="color: #787575; font-weight: 500"
                >
                  <mat-icon>group</mat-icon> Group Appointment
                </span>

                <span *ngFor="let member of row.groupMembers">
                  {{ member?.member?.lastName }},
                  {{ member?.member?.firstName }}
                  {{ member?.member?.middleName }}
                  <br />
                </span>
              </div>

              <div class="stausDotWrapper">
                <mat-icon
                  class="statusDot"
                  [style.color]="
                    row.status === 'Active'
                      ? '#3ac47d'
                      : row.status === 'Closed'
                      ? '#b6b6b6'
                      : '#d92550'
                  "
                >
                  fiber_manual_record
                </mat-icon>
                <span
                  class="statusIndicator"
                  [style.color]="
                    row.status === 'Active'
                      ? '#3ac47d'
                      : row.status === 'Closed'
                      ? '#b6b6b6'
                      : '#d92550'
                  "
                >
                  {{ row.status }}
                </span>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="visitReason">
            <th mat-header-cell *matHeaderCellDef>Visit Reason</th>
            <td mat-cell *matCellDef="let row">
              <b>{{ row.visitReasonName }}</b> - {{ row.sessionType }}
              <br />
              {{ row.sessionFor }}
            </td>
          </ng-container>
          <ng-container matColumnDef="provider">
            <th mat-header-cell *matHeaderCellDef>Provider</th>
            <td mat-cell *matCellDef="let row">
              {{ row.physicianLastName }}, {{ row.physicianFirstName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="facility">
            <th mat-header-cell *matHeaderCellDef>Facility</th>
            <td mat-cell *matCellDef="let row">
              <b>{{ row.billingProviderName }}</b>
              <br />
              {{ row.billingProviderAddressLine1 }}
              {{ row.billingProviderAddressLine2 }}
              <br />
              {{ row.billingProviderCity }} {{ row.billingProviderState }}
              {{ row.billingProviderZip }}
              <br />
              {{ row.billingProviderPhone | phoneFormat }}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let row">
              <span
                *ngIf="row.status === 'Active'"
                style="color: #3ac47d; font-weight: 900"
              >
                {{ row.status }}
              </span>
              <span
                *ngIf="row.status != 'Active'"
                style="color: #d92550; font-weight: 900"
              >
                {{ row.status }}
              </span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <mat-paginator
        [length]="data.total"
        [pageSize]="data.per_page"
        *ngIf="data.total > data.per_page"
        (page)="getNext($event)"
        [showFirstLastButtons]="true"
      ></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
