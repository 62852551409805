<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Internet Addiction Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12" style="padding: 0">
  <div
    *ngIf="assessmentId && !isPatient"
    class="row"
    style="
      margin-bottom: 20px;
      display: flex;
      align-content: center;
      flex-direction: column;
      font-weight: 450;
    "
  >
    Risk Score
    <button
      mat-fab
      color="primary"
      aria-label="Score"
      style="margin-left: 10px"
    >
      {{ riskScore }}
    </button>
  </div>

  <form
    [formGroup]="internetAddictionForm"
    novalidate
    (ngSubmit)="onInterntAddictionAssessmentFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >1. I find myself using my smartphone or computer longer than I
            planned to.
          </label>
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="usingElectronicsLongerThanPlanned"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
            <mat-error
              *ngFor="
                let validation of validation_messages.usingElectronicsLongerThanPlanned
              "
            >
              <mat-error
                class="error-message"
                *ngIf="
                  internetAddictionForm
                    .get('usingElectronicsLongerThanPlanned')
                    .hasError(validation.type) &&
                  (internetAddictionForm.get(
                    'usingElectronicsLongerThanPlanned'
                  ).dirty ||
                    internetAddictionForm.get(
                      'usingElectronicsLongerThanPlanned'
                    ).touched)
                "
              >
                {{ validation.message }}</mat-error
              >
            </mat-error>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            2. I would rather be on my smartphone or computer than interact with
            my partner needed.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="electronicsOverPartner">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            3. I would rather spend time online than do things around the
            house.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="preferOnlineOverHouseChores"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            4. My performance in school or at work suffers because of the amount
            of time I spend online.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="performanceDeteriorating">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            5. People close to me are concerned about the amount of time I spend
            on my smartphone or computer.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="peopleAroundMeAreConcerned"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            6. When asked what I do online, I prefer not to answer.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="nonDisclosureOfOnlineActivity"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            7. My productivity and attentiveness suffers because of the amount
            of time I spend online.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="attentivenessSuffers">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            8. I check my social media, text messages, or emails first thing
            after waking up.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="onSocialMediaAfterWakingUp"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            9. I am bothered when people interrupt me while I am using my
            computer or smartphone.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="botheredWhenInterrupted">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            10. I feel anxious when I do not have my smartphone with me.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="anxiousWithoutDevice">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            11. I use the internet to escape from my real life.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="useInternetToEscapeLife">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            12. I would be less interesting and happy without access to the
            internet.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="lessHappyWithoutAccessToInternet"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            13. I put off things I have to do by using my computer or
            smartphone.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="putOffOtherThings">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            14. When I am unable to use my smartphone, I miss it or think about
            what I could be doing online.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="missBeingOnline">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            15. I stay up later at night than I had intended due to doing things
            online.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="stayUpLaterAtNight">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            16. I would rather stay home and use the internet than go out with
            friends.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="prefertoBeOnlineThanGoOutWithFriends"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            17. I have tried to reduce my smartphone or internet use without
            success.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="triedBeingOfflineWithoutSuccess"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            18. I am concerned about missing out on thing online when not
            checking my smartphone.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="concernedAboutMissingOnlineActivity"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          internetAddictionForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!internetAddictionForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
