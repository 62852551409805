<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Family APGAR</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="apgarAssessmentForm"
    novalidate
    (ngSubmit)="onApgarFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >1. I am satisfied with the help I receive from my family when
            something is troubling me</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="satisfiedWithFamilyHelp">
              <mat-button-toggle value="Hardly ever"
                >Hardly ever</mat-button-toggle
              >
              <mat-button-toggle value="Some of the Time"
                >Some of the Time</mat-button-toggle
              >
              <mat-button-toggle value="Almost Always"
                >Almost Always</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >2. I am satisfied with the way my family discusses items of common
            interest and shares problem solving with me</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="familyDiscussesInterests">
              <mat-button-toggle value="Hardly ever"
                >Hardly ever</mat-button-toggle
              >
              <mat-button-toggle value="Some of the Time"
                >Some of the Time</mat-button-toggle
              >
              <mat-button-toggle value="Almost Always"
                >Almost Always</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >3. I find that my family accepts my wishes to take on new
            activities or make changes in my life</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="familyAcceptsNewActivities"
            >
              <mat-button-toggle value="Hardly ever"
                >Hardly ever</mat-button-toggle
              >
              <mat-button-toggle value="Some of the Time"
                >Some of the Time</mat-button-toggle
              >
              <mat-button-toggle value="Almost Always"
                >Almost Always</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >4. I am satisfied with the way my family expresses affection and
            responds to my feelings</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="satisfiedWithFamilyAffection"
            >
              <mat-button-toggle value="Hardly ever"
                >Hardly ever</mat-button-toggle
              >
              <mat-button-toggle value="Some of the Time"
                >Some of the Time</mat-button-toggle
              >
              <mat-button-toggle value="Almost Always"
                >Almost Always</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >5. I am satisfied with the amount of time my family and I spend
            together</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="satisfiedWithFamilyTime">
              <mat-button-toggle value="Hardly ever"
                >Hardly ever</mat-button-toggle
              >
              <mat-button-toggle value="Some of the Time"
                >Some of the Time</mat-button-toggle
              >
              <mat-button-toggle value="Almost Always"
                >Almost Always</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          apgarAssessmentForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!apgarAssessmentForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
