import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DateTimeZoneService,
  RemittancesAPIService,
  ToastMessageService,
} from 'src/app/_services';

@Component({
  selector: 'app-remittances-claims-details',
  templateUrl: './remittances-claims-details.component.html',
  styleUrl: './remittances-claims-details.component.css',
})
export class RemittancesClaimsDetailsComponent implements OnInit {
  remitId: string;
  claimId: string;
  referenceNumber: string;

  parent: string;
  subParent: string;

  isLoadingResults: boolean = false;
  apiError: boolean = false;

  claimDetails: any;
  processing: boolean = false;
  currentTimezone: string = '';

  displayedColumns = [
    'cptCode',
    'serviceDate',
    'billedAmount',
    'paidAmount',
    'patientResponsibility',
    'adjustments',
  ];

  adjustmentDisplayColumn = ['group', 'amount', 'reason', 'quantity'];

  constructor(
    public router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private remittancesApiService: RemittancesAPIService,
    private toastMessageService: ToastMessageService,
    private dateTimeZoneService: DateTimeZoneService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit(): void {
    this.remitId = this.route.snapshot.paramMap.get('remitId');
    this.claimId = this.route.snapshot.paramMap.get('claimId');
    this.referenceNumber = this.route.snapshot.paramMap.get('refNo');

    this.route.queryParamMap.subscribe((param) => {
      this.parent = param.get('parent');
      this.subParent = param.get('subParent');
    });

    this.getRemitClaimDetails();
  }

  getRemitClaimDetails() {
    this.isLoadingResults = true;
    this.apiError = false;

    this.remittancesApiService.getRemitClaimDetail(this.claimId).subscribe({
      next: (response) => {
        this.isLoadingResults = false;
        this.claimDetails = response.data;
      },
      error: (error) => {
        this.isLoadingResults = false;
        this.apiError = true;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to retrieve claim payment details.'
        );
      },
    });
  }
}
