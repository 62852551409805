import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-drug-screen-result',
  templateUrl: './drug-screen-result.component.html',
  styleUrl: './drug-screen-result.component.css'
})
export class DrugScreenResultComponent {
  @Input() label!: string;
  @Input() value!: string;
  @Input() code!: string;
  @Input() normalCondition!: string;
}
