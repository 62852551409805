import { createAction, props } from '@ngrx/store';
import { IVisitReasons } from 'src/app/_models';

export const getActiveVisitReasons = createAction(
  '[Get Active Visit Reasons] Get Active Visit Reasons'
);

export const getActiveVisitReasonsSuccessAction = createAction(
  '[Get Active Visit Reasons Success] Get Active Visit Reasons Success',
  props<{ payload: IVisitReasons[] }>()
);

export const getActiveVisitReasonFailedAction = createAction(
  '[Get Active Visit Reasons Failed] Get Active Visit Reasons Failed',
  props<{ payload: any }>()
);
