<div style="display: flex; justify-content: center">
  <mat-card
    appearance="raised"
    class="mat-elevation-z8"
    style="border-radius: 20px; max-width: 650px"
  >
    <div class="row" style="margin-top: 25px; margin-bottom: 30px">
      <div class="col-md-12" style="text-align: center">
        <mat-icon
          style="color: red; font-size: 70px; width: 100%; height: auto"
        >
          cancel
        </mat-icon>
        <label style="font-size: 18px; margin-top: 25px; width: 100%">
          We've noticed you canceled taking this assessment.
        </label>
        <label style="width: 85%">
          If you have decided to take this assessment at a later time, please
          ask your Clinician or respective staff to provide you with a new
          Access Code.
        </label>
      </div>
      <div class="col-md-12" style="text-align: center; margin-top: 20px">
        <button
          style="border-radius: 25px"
          type="button"
          mat-raised-button
          color="primary"
          routerLink="/facilityOTP"
        >
          Start New Appointment
        </button>
      </div>
    </div>
  </mat-card>
</div>
