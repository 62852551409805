<mat-card-title>Medical Status</mat-card-title>
<mat-divider></mat-divider>

<div style="margin-top: 40px; padding: 10px">
  <form [formGroup]="asimedicalForm" novalidate (ngSubmit)="onAsiMedicalFormSubmit()">
    <div class="row">
      <!-- Question Box -->
      <div class="col-sm-12 col-xl-8">
        <mat-card appearance="raised" class="mat-elevation-z8" style="margin-bottom: 20px">
          <div class="col-md-12">
            <label class="labelText">M1. How many times in your life have you been hospitalized for
              medical problems? <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="m1" name="m1" required />
              <mat-error *ngFor="let validation of validation_messages.m1">
                <mat-error class="error-message" *ngIf="
                    asimedicalForm.get('m1').hasError(validation.type) &&
                    (asimedicalForm.get('m1').dirty ||
                      asimedicalForm.get('m1').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <label class="labelText">M2. How long ago was your last hospitalization for a physical
              problem? <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="m2" name="m2" required />
              <mat-error *ngFor="let validation of validation_messages.m2">
                <mat-error class="error-message" *ngIf="
                    asimedicalForm.get('m2').hasError(validation.type) &&
                    (asimedicalForm.get('m2').dirty ||
                      asimedicalForm.get('m2').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12 pad10">
            <label class="labelText">M3. Do you have any chronic medical problems which continue to
              interfere with your life? <span style="color: red">*</span></label>
            <div class="col-sm-6">
              <mat-button-toggle-group formControlName="m3">
                <mat-button-toggle value="No">No</mat-button-toggle>
                <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="col-md-12 pad10">
            <label class="labelText">M4. Are you taking any prescribed medication on a regular basis
              for a physical problem? <span style="color: red">*</span></label>
            <div class="col-sm-6">
              <mat-button-toggle-group formControlName="m4">
                <mat-button-toggle value="No">No</mat-button-toggle>
                <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="col-md-12 pad10">
            <label class="labelText">M5. Do you receive a pension for a physical disability?
              <span style="color: red">*</span></label>
            <div class="col-sm-6">
              <mat-button-toggle-group formControlName="m5">
                <mat-button-toggle value="No">No</mat-button-toggle>
                <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="col-md-12">
            <label class="labelText">M6. How many days have you experienced medical problems in the
              past 30 days? <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="m6" name="m6" required />
              <mat-error *ngFor="let validation of validation_messages.m6">
                <mat-error class="error-message" *ngIf="
                    asimedicalForm.get('m6').hasError(validation.type) &&
                    (asimedicalForm.get('m6').dirty ||
                      asimedicalForm.get('m6').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12 pad10">
            <label class="labelText">M7. How troubled or bothered have you been by these medical
              problems in the past 30 days?
              <span style="color: red">*</span></label>
            <div class="col-sm-6">
              <mat-button-toggle-group formControlName="m7">
                <mat-button-toggle value="Not at all">Not at all</mat-button-toggle>
                <mat-button-toggle value="Slightly">Slightly</mat-button-toggle>
                <mat-button-toggle value="Moderately">Moderately</mat-button-toggle>
                <mat-button-toggle value="Considerably">Considerably</mat-button-toggle>
                <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="col-md-12 pad10">
            <label class="labelText">M8. How important to you now is treatment for these medical
              problems? <span style="color: red">*</span></label>
            <div class="col-sm-6">
              <mat-button-toggle-group formControlName="m8">
                <mat-button-toggle value="Not at all">Not at all</mat-button-toggle>
                <mat-button-toggle value="Slightly">Slightly</mat-button-toggle>
                <mat-button-toggle value="Moderately">Moderately</mat-button-toggle>
                <mat-button-toggle value="Considerably">Considerably</mat-button-toggle>
                <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="col-md-12">
            <label class="labelText">M9. How would you rate the patient's need for medical treatment?
              <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="m9" name="m9" required />
              <mat-error *ngFor="let validation of validation_messages.m9">
                <mat-error class="error-message" *ngIf="
                    asimedicalForm.get('m9').hasError(validation.type) &&
                    (asimedicalForm.get('m9').dirty ||
                      asimedicalForm.get('m9').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12 pad10">
            <label class="labelText">M10. Patient's misrepresentation?
              <span style="color: red">*</span></label>
            <div class="col-sm-6">
              <mat-button-toggle-group formControlName="m10">
                <mat-button-toggle value="No">No</mat-button-toggle>
                <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="col-md-12 pad10">
            <label class="labelText">M11. Patient's inability to understand?
              <span style="color: red">*</span>
            </label>
            <div class="col-sm-6">
              <mat-button-toggle-group formControlName="m11">
                <mat-button-toggle value="No">No</mat-button-toggle>
                <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Comment Box -->
      <div class="col-sm-12 col-xl-4">
        <div class="row">
          <div class="col-sm-6">
            <mat-card class="custom-score-card mat-elevation-z8" appearance="raised">
              <label class="labelText">Clinician Score <span style="color: red">*</span></label>
              <mat-card-content>
                <mat-form-field appearance="outline" style="width:100px">
                  <input type="text" formControlName="clinicianScore" maxlength="1"
                    style="font-size:48px; font-weight: 900 !important" pattern="[0-9]" matInput required />
                </mat-form-field>
              </mat-card-content>
              <mat-error *ngFor="let validation of validation_messages.clinicianScore">
                <mat-error class="error-message" *ngIf="
                      asimedicalForm.get('clinicianScore').hasError(validation.type) &&
                      (asimedicalForm.get('clinicianScore').dirty ||
                        asimedicalForm.get('clinicianScore').touched)
                    ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-card>
          </div>
          <div class="col-sm-6">
            <mat-card class="custom-score-card mat-elevation-z8" appearance="raised">
              <label class="labelText" style="justify-content: center;">System Score</label>
              <mat-card-content>
                <span class="width:100px; font-size:48px; font-weight: 900 !important">
                  {{systemGeneratedScore}}
                </span>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div class="row" style="margin-top:15px">
          <div class="col-sm-12">

            <mat-card appearance="raised" class="mat-elevation-z8">
              <div class="row">
                <div class="col-md-12">
                  <label class="labelHeader">Comments</label>
                  <mat-form-field appearance="outline">
                    <textarea formControlName="comments" matInput rows="40"></textarea>
                  </mat-form-field>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end">
      <button [class]="
          asimedicalForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        " mat-raised-button type="submit" [disabled]="!asimedicalForm.valid || processing">
        Submit
      </button>
    </mat-dialog-actions>
  </form>
</div>