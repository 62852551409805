<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Columbia-Suicide Severity Rating Scale (CSSRS)</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="col-md-12" style="padding: 0">
  <div
    *ngIf="assessmentId && !isPatient"
    class="row"
    style="
      margin-bottom: 20px;
      display: flex;
      align-content: center;
      flex-direction: column;
      font-weight: 450;
    "
  >
    <button mat-raised-button color="warn" *ngIf="riskScore >= 10">
      Risk Level - {{ riskLevel }}
    </button>
    <button
      mat-raised-button
      color="accent"
      *ngIf="riskScore >= 3 && riskScore < 10"
    >
      Risk Level - {{ riskLevel }}
    </button>
    <button
      mat-raised-button
      style="background-color: #5a9e7c"
      *ngIf="riskScore < 3"
    >
      Risk Level - {{ riskLevel }}
    </button>
  </div>

  <form [formGroup]="cssrsForm" novalidate (ngSubmit)="onCssrsFormSubmit()">
    <div class="row">
      <div class="col-md-12">
        <mat-card appearance="raised" class="pad20 mat-elevation-z8">
          <div style="font-size: 13px; font-weight: 450">
            In the last month...
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="row pad10">
                <label class="col-md-4 asterix-after"
                  >1) Have you wished you were dead or wished you could go to
                  sleep and not wake up?</label
                >
                <div class="col-sm-2">
                  <mat-button-toggle-group
                    [disabled]="disableFormElements"
                    formControlName="wishToBeDead"
                  >
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row pad10">
                <label class="col-md-4 asterix-after"
                  >2) Have you actually had any thoughts of killing
                  yourself?</label
                >
                <div class="col-sm-2">
                  <mat-button-toggle-group
                    [disabled]="disableFormElements"
                    formControlName="nonSpecificActiveSuicidalThoughts"
                    (change)="askFurtherQuestions()"
                  >
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </div>
          </div>
        </mat-card>

        <mat-card
          appearance="raised"
          class="pad20 mat-elevation-z8"
          *ngIf="showQ3ThruQ5Questions"
        >
          <div class="row">
            <div class="col-md-12">
              <div class="row pad10">
                <label class="col-md-4 asterix-after"
                  >3) Have you been thinking about how you might do this?</label
                >
                <div class="col-sm-2">
                  <mat-button-toggle-group
                    formControlName="activeSuicidalIdeationWithAnyMethodsNotPlanWithoutIntentToAct"
                  >
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row pad10">
                <label class="col-md-4 asterix-after"
                  >4) Have you had these thoughts and had some intention of
                  acting on them?</label
                >
                <div class="col-sm-2">
                  <mat-button-toggle-group
                    formControlName="activeSuicidalIdeationWithSomeIntentToActWithoutSpecificPlan"
                  >
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row pad10">
                <label class="col-md-4 asterix-after"
                  >5) Have you started to work out or worked out the details of
                  how to kill yourself? Do you intend to carry out this
                  plan?</label
                >
                <div class="col-sm-2">
                  <mat-button-toggle-group
                    [disabled]="disableFormElements"
                    formControlName="activeSuicidalIdeationWithSpecificPlanAndIntent"
                  >
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </div>
          </div>
        </mat-card>

        <mat-card appearance="raised" class="pad20 mat-elevation-z8">
          <div class="row">
            <div class="col-md-12">
              <div class="row pad10">
                <label class="col-md-4 asterix-after"
                  >6) Have you ever done anything, started to do anything, or
                  prepared to do anything to YES NO end your life?</label
                >
                <div class="col-sm-2">
                  <mat-button-toggle-group
                    [disabled]="disableFormElements"
                    formControlName="actualAttempt"
                    (change)="askFurtherQuestions()"
                  >
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </div>
            <div class="col-md-12" *ngIf="showQ7Question">
              <div class="row pad10">
                <label class="col-md-4 asterix-after"
                  >Was this within the past three months?</label
                >
                <div class="col-sm-2">
                  <mat-button-toggle-group
                    [disabled]="disableFormElements"
                    formControlName="actualAttemptWithinThreeMonths"
                  >
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
      <mat-dialog-actions align="end" *ngIf="!assessmentId">
        <button
          [class]="
            cssrsForm.valid
              ? 'btn btn-primary pull-right'
              : 'btn btn-default pull-right'
          "
          mat-raised-button
          type="submit"
          [disabled]="!cssrsForm.valid || processing"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </mat-dialog-actions>
    </div>
  </form>
</div>
