import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CoreService } from 'src/app/_services';
import { MoodLogApiService } from 'src/app/_services/hourlylogs/moodlog.service';
import { AddMoodLogDialogComponent } from './add-mood-log-dialog/add-mood-log-dialog.component';
@Component({
  selector: 'app-hourly-mood-assessment',
  templateUrl: './hourly-mood-assessment.component.html',
  styleUrls: ['./hourly-mood-assessment.component.css'],
})
export class HourlyMoodAssessmentComponent implements OnInit {
  patientId: string = '';

  constructor(
    public dialog: MatDialog,
    public moodlogApiService: MoodLogApiService,
    private coreService: CoreService
  ) {}

  ngOnInit(): void {
    this.patientId = this.coreService.getPatientId();
  }

  captureMood() {
    this.dialog.open(AddMoodLogDialogComponent, {
      data: { patientId: this.patientId },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });
  }
}
