import { Action, createReducer, on } from '@ngrx/store';
import * as PermissionActions from '../actions/permission.actions';
import { PermissionState, initialPermissionState } from '../state/permission.state';

export const permissionReducer = createReducer(
    initialPermissionState,
    on(PermissionActions.LoadPermissionsAction, (state) => ({
        ...state,
        processingRequest: true,
        processingError: undefined
    })),
    on(PermissionActions.LoadPermissionsSuccessAction, (state, { payload }) => ({
        ...state,
        payload: payload.items,
        processingRequest: false,
        reloadPermissionList: false
    })),
    on(PermissionActions.LoadPermissionsFailedAction, (state, { payload }) => ({
        ...state,
        processingError: payload.errors,
        processingRequest: false,
        reloadPermissionList: false
    }))
);

export function PermissionReducer(state: PermissionState | undefined, action: Action): PermissionState {
    return permissionReducer(state, action);
}