<div>
  <label style="font-size: 18px; font-weight: 400; padding: 10px; color: black">
    Done
  </label>
  <div [id]="lane.id">
    <app-carelog-card
      *ngFor="let carelog of lane.notes"
      [carelog]="carelog"
      [case]="case"
      [patient]="patient"
      [showGoal]="showGoal"
      [goals]="goals"
      [goalDrivenCare]="case.goalDrivenCare"
      (hasChanges)="changesOccured($event)"
      [logDate]="logDate"
      [shift]="shift"
      [hasCaseLogAddAccess]="hasCaseLogAddAccess"
      [hasCaseLogEditAccess]="hasCaseLogEditAccess"
      [hasCaseLogDeleteAccess]="hasCaseLogDeleteAccess"
    >
    </app-carelog-card>
  </div>
</div>
