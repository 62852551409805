import { Action, createReducer, on } from '@ngrx/store';
import * as FacilityActions from '../actions/facility.actions';
import { FacilityState, initialFacilityState } from '../state/facility.state';

export const facilityReducer = createReducer(
  initialFacilityState,
  on(FacilityActions.getFacilityListAction, (state) => ({
    ...state,
    FacilityListLoading: true,
    FacilityListSuccess: false,
    FacilityListError: undefined,
  })),
  on(FacilityActions.getFacilityListSuccessAction, (state, { payload }) => ({
    ...state,
    FacilityList: payload,
    FacilityListLoading: false,
    FacilityListSuccess: true,
    FacilityListError: undefined,
  })),
  on(FacilityActions.getFacilityListFailedAction, (state, { payload }) => ({
    ...state,
    FacilityList: undefined,
    FacilityListError: payload,
    FacilityListLoading: false,
  })),
  on(FacilityActions.getFacilityResourcesAction, (state) => ({
    ...state,
    FacilityResourcesListLoading: true,
    FacilityResourcesListError: undefined,
    FacilityResourcesListSuccess: false,
  })),
  on(
    FacilityActions.getFacilityResourcesSuccessAction,
    (state, { payload }) => ({
      ...state,
      FacilityResourcesList: payload,
      FacilityResourcesListLoading: false,
      FacilityResourcesListSuccess: true,
      FacilityResourcesListError: undefined,
    })
  ),
  on(
    FacilityActions.getFacilityResourcesFailedAction,
    (state, { payload }) => ({
      ...state,
      FacilityResourcesListError: payload,
      FacilityResourcesListLoading: false,
      FacilityResourcesListSuccess: false,
      FacilityResourcesList: undefined,
    })
  )
);

export function FacilityReducer(
  state: FacilityState | undefined,
  action: Action
): FacilityState {
  return facilityReducer(state, action);
}
