<mat-card
  style="
    border-radius: 15px;
    margin-top: 15px;
    max-height: 60vh;
    overflow: auto;
  "
>
  <label class="labelHeader">
    Review Assessments
    <span
      class="material-icons expandIcon"
      routerLink="/main/assessments"
      matTooltip="Show in Full Screen"
      [matTooltipPosition]="'below'"
    >
      launch
    </span>
  </label>

  <app-assessment-history
    [showHeader]="false"
    [status]="'In Review'"
    [showSearchMember]="false"
    [hideMemberName]="false"
    [hideSessionId]="true"
    [hideCompletedBy]="true"
    [hideCreatedDate]="true"
    [hasHospitalDeviceFeature]="hasHospitalDeviceFeature"
    [hasMemberAssessmentAddAccess]="hasMemberAssessmentAddAccess"
    [hasMemberAssessmentEditAccess]="hasMemberAssessmentEditAccess"
    [hasMemberAssessmentDeleteAccess]="hasMemberAssessmentDeleteAccess"
    [hasAssessmentChartsViewAccess]="hasAssessmentChartsViewAccess"
    [hasAssessmentGuidanceViewAccess]="hasAssessmentGuidanceViewAccess"
    [hasAssessmentGuidanceEditAccess]="hasAssessmentGuidanceEditAccess"
    [hasAssessmentSummaryViewAccess]="hasAssessmentSummaryViewAccess"
    [hasAssessmentSummaryEditAccess]="hasAssessmentSummaryEditAccess"
    [hasAssessmentTakeAddAccess]="hasAssessmentTakeAddAccess"
  >
  </app-assessment-history>
</mat-card>
