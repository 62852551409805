import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { ICrossCuttingLevelOneElevenToSeventeen } from 'src/app/_models/assessments/general/crosscuttingleveloneeleventoseventeen.model';
import { ToastMessageService } from 'src/app/_services/';
import { CrossCuttingLevelOneElevenToSeventeenApiService } from 'src/app/_services/assessments/general/crosscuttingleveloneeleventoseventeen.service';

@Component({
  selector: 'app-cross-cutting-level-one-eleven-to-seventeen',
  templateUrl: './cross-cutting-level-one-eleven-to-seventeen.component.html',
  styleUrls: ['./cross-cutting-level-one-eleven-to-seventeen.component.css'],
})
export class CrossCuttingLevelOneElevenToSeventeenComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  public inattentive: boolean;
  public somatic: boolean;
  public sleep: boolean;
  public depression: boolean;
  public anger: boolean;
  public mania: boolean;
  public anxiety: boolean;
  public psychosis: boolean;
  public repetetiveThoughtsAndBehaviors: boolean;
  public substanceUse: boolean;
  public suicidal: boolean;
  public memory: boolean;
  public dissociation: boolean;
  public personality: boolean;

  constructor(
    public crossCuttingLevelOneElevenToSeventeenApiService: CrossCuttingLevelOneElevenToSeventeenApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) {}
  public riskScore: number;
  public crosscuttingleveloneeleventoseventeenForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  crosscuttingleveloneeleventoseventeen: ICrossCuttingLevelOneElevenToSeventeen;
  crosscuttingleveloneeleventoseventeenError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(
      this.crosscuttingleveloneeleventoseventeenForm,
      this.errors
    );

    this.loadCrossCuttingLevelOneElevenToSeventeen();
  }

  loadCrossCuttingLevelOneElevenToSeventeen() {
    this.crosscuttingleveloneeleventoseventeenForm.controls[
      'patientId'
    ].setValue(this.patientId);

    if (this.assessmentId) {
      this.crossCuttingLevelOneElevenToSeventeenApiService
        .getCrossCuttingLevelOneElevenToSeventeenDetails(this.assessmentId)
        .subscribe((crosscuttingleveloneeleventoseventeen) => {
          if (crosscuttingleveloneeleventoseventeen.data) {
            this.crosscuttingleveloneeleventoseventeenForm.controls[
              'complained'
            ].setValue(
              crosscuttingleveloneeleventoseventeen.data.complained.answer
            );
            this.crosscuttingleveloneeleventoseventeenForm.controls[
              'worried'
            ].setValue(
              crosscuttingleveloneeleventoseventeen.data.worried.answer
            );
            this.crosscuttingleveloneeleventoseventeenForm.controls[
              'problemsSleeping'
            ].setValue(
              crosscuttingleveloneeleventoseventeen.data.problemsSleeping.answer
            );
            this.crosscuttingleveloneeleventoseventeenForm.controls[
              'attentionProblems'
            ].setValue(
              crosscuttingleveloneeleventoseventeen.data.attentionProblems
                .answer
            );
            this.crosscuttingleveloneeleventoseventeenForm.controls[
              'lessFun'
            ].setValue(
              crosscuttingleveloneeleventoseventeen.data.lessFun.answer
            );
            this.crosscuttingleveloneeleventoseventeenForm.controls[
              'sadOrDepressed'
            ].setValue(
              crosscuttingleveloneeleventoseventeen.data.sadOrDepressed.answer
            );
            this.crosscuttingleveloneeleventoseventeenForm.controls[
              'irritatedOrAnnoyed'
            ].setValue(
              crosscuttingleveloneeleventoseventeen.data.irritatedOrAnnoyed
                .answer
            );
            this.crosscuttingleveloneeleventoseventeenForm.controls[
              'angerOrTemper'
            ].setValue(
              crosscuttingleveloneeleventoseventeen.data.angerOrTemper.answer
            );
            this.crosscuttingleveloneeleventoseventeenForm.controls[
              'moreProjects'
            ].setValue(
              crosscuttingleveloneeleventoseventeen.data.moreProjects.answer
            );
            this.crosscuttingleveloneeleventoseventeenForm.controls[
              'sleptLess'
            ].setValue(
              crosscuttingleveloneeleventoseventeen.data.sleptLess.answer
            );
            this.crosscuttingleveloneeleventoseventeenForm.controls[
              'feltNervous'
            ].setValue(
              crosscuttingleveloneeleventoseventeen.data.feltNervous.answer
            );
            this.crosscuttingleveloneeleventoseventeenForm.controls[
              'stopWorrying'
            ].setValue(
              crosscuttingleveloneeleventoseventeen.data.stopWorrying.answer
            );
            this.crosscuttingleveloneeleventoseventeenForm.controls[
              'couldntDoThings'
            ].setValue(
              crosscuttingleveloneeleventoseventeen.data.couldntDoThings.answer
            );
            this.crosscuttingleveloneeleventoseventeenForm.controls[
              'heardVoices'
            ].setValue(
              crosscuttingleveloneeleventoseventeen.data.heardVoices.answer
            );
            this.crosscuttingleveloneeleventoseventeenForm.controls[
              'illusion'
            ].setValue(
              crosscuttingleveloneeleventoseventeen.data.illusion.answer
            );
            this.crosscuttingleveloneeleventoseventeenForm.controls[
              'thoughts'
            ].setValue(
              crosscuttingleveloneeleventoseventeen.data.thoughts.answer
            );
            this.crosscuttingleveloneeleventoseventeenForm.controls[
              'needToCheck'
            ].setValue(
              crosscuttingleveloneeleventoseventeen.data.needToCheck.answer
            );
            this.crosscuttingleveloneeleventoseventeenForm.controls[
              'worryALot'
            ].setValue(
              crosscuttingleveloneeleventoseventeen.data.worryALot.answer
            );
            this.crosscuttingleveloneeleventoseventeenForm.controls[
              'needToDoThings'
            ].setValue(
              crosscuttingleveloneeleventoseventeen.data.needToDoThings.answer
            );
            this.crosscuttingleveloneeleventoseventeenForm.controls[
              'alcoholicBeverage'
            ].setValue(
              crosscuttingleveloneeleventoseventeen.data.alcoholicBeverage
                .answer
            );
            this.crosscuttingleveloneeleventoseventeenForm.controls[
              'smokedCigarette'
            ].setValue(
              crosscuttingleveloneeleventoseventeen.data.smokedCigarette.answer
            );
            this.crosscuttingleveloneeleventoseventeenForm.controls[
              'drugs'
            ].setValue(crosscuttingleveloneeleventoseventeen.data.drugs.answer);
            this.crosscuttingleveloneeleventoseventeenForm.controls[
              'medicationWithoutPrescription'
            ].setValue(
              crosscuttingleveloneeleventoseventeen.data
                .medicationWithoutPrescription.answer
            );
            this.crosscuttingleveloneeleventoseventeenForm.controls[
              'thoughtAboutKilling'
            ].setValue(
              crosscuttingleveloneeleventoseventeen.data.thoughtAboutKilling
                .answer
            );
            this.crosscuttingleveloneeleventoseventeenForm.controls[
              'triedToKillSelf'
            ].setValue(
              crosscuttingleveloneeleventoseventeen.data.triedToKillSelf.answer
            );
            this.riskScore =
              crosscuttingleveloneeleventoseventeen.data.riskScore;

            this.somatic =
              crosscuttingleveloneeleventoseventeen.data.props['somatic'] >= 2;
            this.sleep =
              crosscuttingleveloneeleventoseventeen.data.props['sleep'] >= 2;
            this.depression =
              crosscuttingleveloneeleventoseventeen.data.props['depression'] >=
              2;
            this.anger =
              crosscuttingleveloneeleventoseventeen.data.props['anger'] >= 2;
            this.mania =
              crosscuttingleveloneeleventoseventeen.data.props['mania'] >= 2;
            this.anxiety =
              crosscuttingleveloneeleventoseventeen.data.props['anxiety'] >= 2;
            this.psychosis =
              crosscuttingleveloneeleventoseventeen.data.props['psychosis'] >=
              1;
            this.repetetiveThoughtsAndBehaviors =
              crosscuttingleveloneeleventoseventeen.data.props[
                'repetetiveThoughtsAndBehaviors'
              ] >= 2;
            this.substanceUse =
              crosscuttingleveloneeleventoseventeen.data.props[
                'substanceUse'
              ] >= 1;
            this.suicidal =
              crosscuttingleveloneeleventoseventeen.data.props['suicidal'] >= 1;
            this.memory =
              crosscuttingleveloneeleventoseventeen.data.props['memory'] >= 2;
            this.dissociation =
              crosscuttingleveloneeleventoseventeen.data.props[
                'dissociation'
              ] >= 2;
            this.personality =
              crosscuttingleveloneeleventoseventeen.data.props['personality'] >=
              2;
            this.inattentive =
              crosscuttingleveloneeleventoseventeen.data.props['inattention'] >=
              1;
          }
        });
    }
  }
  onCrossCuttingLevelOneElevenToSeventeenFormSubmit() {
    if (this.crosscuttingleveloneeleventoseventeenForm.invalid) {
      this.crosscuttingleveloneeleventoseventeenForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.crosscuttingleveloneeleventoseventeen = Object.assign(
      {},
      this.crosscuttingleveloneeleventoseventeen
    );
    this.crosscuttingleveloneeleventoseventeen.patientId =
      this.crosscuttingleveloneeleventoseventeenForm.controls[
        'patientId'
      ].value;
    this.crosscuttingleveloneeleventoseventeen.sessionId =
      this.crosscuttingleveloneeleventoseventeenForm.controls[
        'sessionId'
      ].value;
    this.crosscuttingleveloneeleventoseventeen.assessmentRequestId =
      this.crosscuttingleveloneeleventoseventeenForm.controls[
        'assessmentRequestId'
      ].value;

    this.crosscuttingleveloneeleventoseventeen.complained = {
      answer:
        this.crosscuttingleveloneeleventoseventeenForm.controls['complained']
          .value,
    };
    this.crosscuttingleveloneeleventoseventeen.worried = {
      answer:
        this.crosscuttingleveloneeleventoseventeenForm.controls['worried']
          .value,
    };
    this.crosscuttingleveloneeleventoseventeen.problemsSleeping = {
      answer:
        this.crosscuttingleveloneeleventoseventeenForm.controls[
          'problemsSleeping'
        ].value,
    };
    this.crosscuttingleveloneeleventoseventeen.attentionProblems = {
      answer:
        this.crosscuttingleveloneeleventoseventeenForm.controls[
          'attentionProblems'
        ].value,
    };
    this.crosscuttingleveloneeleventoseventeen.lessFun = {
      answer:
        this.crosscuttingleveloneeleventoseventeenForm.controls['lessFun']
          .value,
    };
    this.crosscuttingleveloneeleventoseventeen.sadOrDepressed = {
      answer:
        this.crosscuttingleveloneeleventoseventeenForm.controls[
          'sadOrDepressed'
        ].value,
    };
    this.crosscuttingleveloneeleventoseventeen.irritatedOrAnnoyed = {
      answer:
        this.crosscuttingleveloneeleventoseventeenForm.controls[
          'irritatedOrAnnoyed'
        ].value,
    };
    this.crosscuttingleveloneeleventoseventeen.angerOrTemper = {
      answer:
        this.crosscuttingleveloneeleventoseventeenForm.controls['angerOrTemper']
          .value,
    };
    this.crosscuttingleveloneeleventoseventeen.moreProjects = {
      answer:
        this.crosscuttingleveloneeleventoseventeenForm.controls['moreProjects']
          .value,
    };
    this.crosscuttingleveloneeleventoseventeen.sleptLess = {
      answer:
        this.crosscuttingleveloneeleventoseventeenForm.controls['sleptLess']
          .value,
    };

    this.crosscuttingleveloneeleventoseventeen.feltNervous = {
      answer:
        this.crosscuttingleveloneeleventoseventeenForm.controls['feltNervous']
          .value,
    };
    this.crosscuttingleveloneeleventoseventeen.stopWorrying = {
      answer:
        this.crosscuttingleveloneeleventoseventeenForm.controls['stopWorrying']
          .value,
    };
    this.crosscuttingleveloneeleventoseventeen.couldntDoThings = {
      answer:
        this.crosscuttingleveloneeleventoseventeenForm.controls[
          'couldntDoThings'
        ].value,
    };
    this.crosscuttingleveloneeleventoseventeen.heardVoices = {
      answer:
        this.crosscuttingleveloneeleventoseventeenForm.controls['heardVoices']
          .value,
    };
    this.crosscuttingleveloneeleventoseventeen.illusion = {
      answer:
        this.crosscuttingleveloneeleventoseventeenForm.controls['illusion']
          .value,
    };
    this.crosscuttingleveloneeleventoseventeen.thoughts = {
      answer:
        this.crosscuttingleveloneeleventoseventeenForm.controls['thoughts']
          .value,
    };
    this.crosscuttingleveloneeleventoseventeen.needToCheck = {
      answer:
        this.crosscuttingleveloneeleventoseventeenForm.controls['needToCheck']
          .value,
    };
    this.crosscuttingleveloneeleventoseventeen.worryALot = {
      answer:
        this.crosscuttingleveloneeleventoseventeenForm.controls['worryALot']
          .value,
    };
    this.crosscuttingleveloneeleventoseventeen.needToDoThings = {
      answer:
        this.crosscuttingleveloneeleventoseventeenForm.controls[
          'needToDoThings'
        ].value,
    };
    this.crosscuttingleveloneeleventoseventeen.alcoholicBeverage = {
      answer:
        this.crosscuttingleveloneeleventoseventeenForm.controls[
          'alcoholicBeverage'
        ].value,
    };
    this.crosscuttingleveloneeleventoseventeen.smokedCigarette = {
      answer:
        this.crosscuttingleveloneeleventoseventeenForm.controls[
          'smokedCigarette'
        ].value,
    };
    this.crosscuttingleveloneeleventoseventeen.drugs = {
      answer:
        this.crosscuttingleveloneeleventoseventeenForm.controls['drugs'].value,
    };
    this.crosscuttingleveloneeleventoseventeen.medicationWithoutPrescription = {
      answer:
        this.crosscuttingleveloneeleventoseventeenForm.controls[
          'medicationWithoutPrescription'
        ].value,
    };
    this.crosscuttingleveloneeleventoseventeen.thoughtAboutKilling = {
      answer:
        this.crosscuttingleveloneeleventoseventeenForm.controls[
          'thoughtAboutKilling'
        ].value,
    };
    this.crosscuttingleveloneeleventoseventeen.triedToKillSelf = {
      answer:
        this.crosscuttingleveloneeleventoseventeenForm.controls[
          'triedToKillSelf'
        ].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.crosscuttingleveloneeleventoseventeen.accessCode = this.accessCode;

      this.crossCuttingLevelOneElevenToSeventeenApiService
        .addGuestCrossCuttingLevelOneElevenToSeventeen(
          this.crosscuttingleveloneeleventoseventeen
        )
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted DSM 5 Level 1 Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting DSM 5 Level 1 Assessment'
            );
          }
        );
    } else {
      this.crossCuttingLevelOneElevenToSeventeenApiService
        .addCrossCuttingLevelOneElevenToSeventeen(
          this.crosscuttingleveloneeleventoseventeen
        )
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted DSM 5 Level 1 Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting DSM 5 Level 1 Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    complained: [
      {
        type: 'required',
        message:
          'Been bothered by stomachaches, headaches, or other aches and pains? is required',
      },
    ],
    worried: [
      {
        type: 'required',
        message: 'Worried about your health or about getting sick? is required',
      },
    ],
    problemsSleeping: [
      {
        type: 'required',
        message:
          'Been bothered by not being able to fall asleep or stay asleep, or by waking up too early? is required',
      },
    ],
    attentionProblems: [
      {
        type: 'required',
        message:
          'Been bothered by not being able to pay attention when you were in class or doing homework or reading a book or playing a game? is required',
      },
    ],
    lessFun: [
      {
        type: 'required',
        message: 'Had less fun doing things than you used to? is required',
      },
    ],
    sadOrDepressed: [
      {
        type: 'required',
        message: 'Felt sad or depressed for several hours? is required',
      },
    ],
    irritatedOrAnnoyed: [
      {
        type: 'required',
        message:
          'Felt more irritated or easily annoyed than usual? is required',
      },
    ],
    angerOrTemper: [
      {
        type: 'required',
        message: 'Felt angry or lost your temper? is required',
      },
    ],
    moreProjects: [
      {
        type: 'required',
        message:
          'Started lots more projects than usual or done more risky things than usual? is required',
      },
    ],
    sleptLess: [
      {
        type: 'required',
        message:
          'Slept less than usual but still had a lot of energy? is required',
      },
    ],
    feltNervous: [
      {
        type: 'required',
        message: 'Felt nervous, anxious, or scared? is required',
      },
    ],
    stopWorrying: [
      {
        type: 'required',
        message: 'Not been able to stop worrying? is required',
      },
    ],
    couldntDoThings: [
      {
        type: 'required',
        message:
          'Not been able to do things you wanted to or should have done because they made you feel nervous? is required',
      },
    ],
    heardVoices: [
      {
        type: 'required',
        message:
          'Heard voices—when there was no one there—speaking about you or telling you what to do or saying bad things to you?? is required',
      },
    ],
    illusion: [
      {
        type: 'required',
        message:
          'Had visions when you were completely awake—that is, seen something or someone that no one else could see? is required',
      },
    ],
    thoughts: [
      {
        type: 'required',
        message:
          'Had thoughts that kept coming into your mind that you would do something bad or that something bad would happen to you or to someone else? is required',
      },
    ],
    needToCheck: [
      {
        type: 'required',
        message:
          'Felt the need to check on certain things over and over again, like whether a door was locked or whether the stove was turned off? is required',
      },
    ],
    worryALot: [
      {
        type: 'required',
        message:
          'Worried a lot about things you touched being dirty or having germs or being poisoned? is required',
      },
    ],
    needToDoThings: [
      {
        type: 'required',
        message:
          'Felt you had to do things in a certain way, like counting or saying special things, to keep something bad from happening? is required',
      },
    ],
    alcoholicBeverage: [
      {
        type: 'required',
        message:
          'Had an alcoholic beverage (beer, wine, liquor, etc.,)? is required',
      },
    ],
    smokedCigarette: [
      {
        type: 'required',
        message:
          'Smoked a cigarette, a cigar, or pipe, or used snuff or chewing tobacco? is required',
      },
    ],
    drugs: [
      {
        type: 'required',
        message:
          'Used drugs like marijuana, cocaine, or crack, club drugs (Like Ecstasy), hallucinogens (like LSD), heroin, inhalants or insolvents (like glue), or methamphetamine (like speed)? is required',
      },
    ],
    medicationWithoutPrescription: [
      {
        type: 'required',
        message:
          "Used any medicine without a doctor's prescription to get high or change the way you feel (e.g., painkillers[like Vicodin], stimulants[like Ritalin or Adderall], sedatives or tranquilizers [like sleeping pills or Valium], or Steriods)? is required",
      },
    ],
    thoughtAboutKilling: [
      {
        type: 'required',
        message:
          'In the last two weeks, have you thought about killing yourself or committing suicide? is required',
      },
    ],
    triedToKillSelf: [
      {
        type: 'required',
        message: 'Have you EVER tried to kill yourself? is required',
      },
    ],
  };

  private buildForm() {
    this.crosscuttingleveloneeleventoseventeenForm = this.formBuilder.group({
      id: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      organizationId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      complained: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      worried: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      problemsSleeping: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      attentionProblems: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      lessFun: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      sadOrDepressed: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      irritatedOrAnnoyed: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      angerOrTemper: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      moreProjects: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      sleptLess: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feltNervous: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      stopWorrying: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      couldntDoThings: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      heardVoices: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      illusion: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      thoughts: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      needToCheck: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      worryALot: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      needToDoThings: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      alcoholicBeverage: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      smokedCigarette: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      drugs: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      medicationWithoutPrescription: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      thoughtAboutKilling: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      triedToKillSelf: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.crosscuttingleveloneeleventoseventeenForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    // for (let el in this.crosscuttingleveloneeleventoseventeenForm.controls) {
    //   if (this.crosscuttingleveloneeleventoseventeenForm.controls[el].errors) {
    //     console.log(el)
    //   }
    // }

    if (!this.crosscuttingleveloneeleventoseventeenForm) {
      return;
    }
    const form = this.crosscuttingleveloneeleventoseventeenForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
