<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>
      Existing Notes - {{ therapySession.clinicalNoteTypeName }}
    </p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-stepper linear="true" #stepper>
    <mat-step [stepControl]="category">
      <!-- Select Category Step -->
      <ng-template matStepLabel>Select Type</ng-template>

      <div class="row" style="margin-top: 15px">
        <div class="col-md-6" *ngIf="!allGroupMember">
          <mat-card
            class="mat-elevation-z8 cursorLabel"
            style="
              border-radius: 10px;
              cursor: pointer;
              justify-content: center;
              align-items: center;
              gap: 5px;
            "
            (click)="selectOption('Latest')"
          >
            <mat-icon class="cursorLabel">watch_later</mat-icon>
            <label class="labelBold cursorLabel"> Load Latest </label>
          </mat-card>
        </div>

        <div class="col-md-6">
          <mat-card
            class="mat-elevation-z8 cursorLabel"
            style="
              border-radius: 10px;
              cursor: pointer;
              justify-content: center;
              align-items: center;
              gap: 5px;
            "
            (click)="selectOption('PickAndChoose')"
          >
            <mat-icon class="cursorLabel">ads_click</mat-icon>
            <label class="labelBold cursorLabel"> Pick & Choose </label>
          </mat-card>
        </div>
      </div>

      <!-- Loader -->
      <div class="row" *ngIf="isLatestLoading" style="margin-top: 15px">
        Please wait ...
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </mat-step>

    <!-- Select own note -->
    <mat-step>
      <!-- Select Category Step -->
      <ng-template matStepLabel>Copy From Past Notes</ng-template>

      <div class="row">
        <div class="col-md-12">
          <!-- Progress Bar -->
          <mat-progress-bar
            mode="indeterminate"
            *ngIf="isProcessing"
          ></mat-progress-bar>

          <mat-error *ngIf="pastNotesData.total == 0 && !isProcessing">
            No past notes found
          </mat-error>
        </div>

        <div
          [class]="pdfViewOpen ? 'col-md-6' : 'col-md-12'"
          *ngIf="pastNotesData.total > 0"
        >
          <div class="table-responsive">
            <table
              mat-table
              [dataSource]="pastNotesData.items"
              class="mat-elevation-z8"
              matSort
              matSortDisableClear
            >
              <ng-container matColumnDef="session">
                <th mat-header-cell *matHeaderCellDef>Session</th>
                <td
                  mat-cell
                  *matCellDef="let row"
                  [style.background]="
                    selectedNoteId === row.id ? '#c0e1fb' : ''
                  "
                >
                  <b> {{ row.clinicalNoteTypeName }}</b>
                  <br />
                  {{ row.start | date : 'M/d/y' : currentTimezone }}
                  -
                  {{ row.visitReasonName }}
                </td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="text-align: right">
                  Actions
                </th>
                <td
                  mat-cell
                  *matCellDef="let row; let i = index"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                  "
                  [style.background]="
                    selectedNoteId === row.id ? '#c0e1fb' : ''
                  "
                >
                  <button
                    mat-icon-button
                    type="button"
                    style="margin: 5px"
                    color="primary"
                    (click)="viewNote(row)"
                  >
                    <mat-icon>visibility</mat-icon>
                  </button>
                  <button
                    mat-raised-button
                    type="button"
                    color="primary"
                    style="margin: 5px"
                    (click)="copyNote(row)"
                  >
                    Copy
                  </button>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </div>

        <!-- Open PDF on the right Side -->
        <div class="col-md-6 pdfViewWrapper" *ngIf="pdfViewOpen">
          <mat-progress-bar
            mode="indeterminate"
            *ngIf="isLoadingPDF"
          ></mat-progress-bar>

          <ng2-pdfjs-viewer
            [download]="'false'"
            [print]="'false'"
            [pdfSrc]="pdfSrc"
            #pdfViewerOnDemand
          ></ng2-pdfjs-viewer>
        </div>
      </div>
    </mat-step>
  </mat-stepper>
</div>
