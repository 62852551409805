<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Altman Mania Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="altmanManiaForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">1. Positive Mood</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="mood"
        >
          <mat-button-toggle
            value="I do not feel happier or more cheerful than usual."
            >I do not feel happier or more cheerful than
            usual.</mat-button-toggle
          >
          <mat-button-toggle
            value="I occasionally feel happier or more cheerful than usual."
            >I occasionally feel happier or more cheerful than
            usual.</mat-button-toggle
          >
          <mat-button-toggle
            value="I often feel happier or more cheerful than usual."
            >I often feel happier or more cheerful than
            usual.</mat-button-toggle
          >
          <mat-button-toggle
            value="I feel happier or more cheerful than usual most of the time."
            >I feel happier or more cheerful than usual most of the
            time.</mat-button-toggle
          >
          <mat-button-toggle
            value="I feel happier or more cheerful than usual all of the time."
            >I feel happier or more cheerful than usual all of the
            time.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">2. Self-Confidence</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="confidence"
        >
          <mat-button-toggle
            value="I do not feel more self-confident than usual."
            >I do not feel more self-confident than usual.</mat-button-toggle
          >
          <mat-button-toggle
            value="I occasionally feel more self-confident than usual."
            >I occasionally feel more self-confident than
            usual.</mat-button-toggle
          >
          <mat-button-toggle
            value="I often feel more self-confident than usual."
            >I often feel more self-confident than usual.</mat-button-toggle
          >
          <mat-button-toggle value="I feel more self-confident than usual."
            >I feel more self-confident than usual.</mat-button-toggle
          >
          <mat-button-toggle
            value="I feel extremely self-confident all of the time."
            >I feel extremely self-confident all of the time.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">3. Sleep Patterns</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="sleep"
        >
          <mat-button-toggle value="I do not need less sleep than usual."
            >I do not need less sleep than usual.</mat-button-toggle
          >
          <mat-button-toggle value="I occasionally need less sleep than usual."
            >I occasionally need less sleep than usual.</mat-button-toggle
          >
          <mat-button-toggle value="I often need less sleep than usual."
            >I often need less sleep than usual.</mat-button-toggle
          >
          <mat-button-toggle value="I frequently need less sleep than usual."
            >I frequently need less sleep than usual.</mat-button-toggle
          >
          <mat-button-toggle
            value="I can go all day and night without any sleep and still not feel tired."
            >I can go all day and night without any sleep and still not feel
            tired.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">4. Speech</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="talking"
        >
          <mat-button-toggle value="I do not talk more than usual."
            >I do not talk more than usual.</mat-button-toggle
          >
          <mat-button-toggle value="I occasionally talk more than usual."
            >I occasionally talk more than usual.</mat-button-toggle
          >
          <mat-button-toggle value="I often talk more than usual."
            >I often talk more than usual.</mat-button-toggle
          >
          <mat-button-toggle value="I frequently talk more than usual."
            >I frequently talk more than usual.</mat-button-toggle
          >
          <mat-button-toggle
            value="I talk constantly and cannot be interrupted."
            >I talk constantly and cannot be interrupted.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">5. Activity Level</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="activity"
        >
          <mat-button-toggle
            value="I have not been more
                    active (either socially, sexually, at work, home or school) than
                    usual."
            >I have not been more active (either socially, sexually, at work,
            home or school) than usual.</mat-button-toggle
          >
          <mat-button-toggle
            value="I have occasionally been more active than usual."
            >I have occasionally been more active than usual.</mat-button-toggle
          >
          <mat-button-toggle value="I have often been more active than usual."
            >I have often been more active than usual.</mat-button-toggle
          >
          <mat-button-toggle
            value="I have frequently been more active than usual."
            >I have frequently been more active than usual.</mat-button-toggle
          >
          <mat-button-toggle
            value="I am constantly active or on the go all the time."
            >I am constantly active or on the go all the
            time.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>

    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          altmanManiaForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!altmanManiaForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
