<div>
  <p style="font-size: 16px; font-weight: 450">
    <a routerLink="/" routerLinkActive="active">
      <em
        class="material-icons text_align-center"
        style="font-size: 18px; margin-top: -3px"
        >home</em
      >
    </a>
    &nbsp; / &nbsp;
    <a routerLink="/remittances/remit" routerLinkActive="active">
      <span style="color: #2196f3; cursor: pointer; font-weight: 500">
        Remittances
      </span>
    </a>
    &nbsp; / &nbsp; Remit # {{ remitDetails?.paymentReferenceNumber }}
  </p>
</div>

<div *ngIf="isLoadingResults || apiError">
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoadingResults"
  ></mat-progress-bar>

  <mat-error *ngIf="apiError">
    An error occurred while retrieving remittance details
  </mat-error>
</div>

<!-- Edit icon for manual payments -->
<div
  class="row"
  *ngIf="remitDetails?.id && remitDetails?.remitType === 'Manual'"
  style="margin: 15px 0px"
>
  <div class="col-md-12" style="text-align: right">
    <button
      mat-raised-button
      type="button"
      class="editButton"
      (click)="editRemit()"
    >
      <mat-icon>edit</mat-icon>
      Edit
    </button>
  </div>
</div>

<!-- // Display Remittances Data -->
<div class="row" *ngIf="remitDetails?.id">
  <!-- Remittance Header -->
  <div class="col-md-12">
    <label class="labelBold" *ngIf="remitDetails?.status === 'New'">
      Please review the payment information and change the status accordingly.
    </label>

    <mat-card appearance="raised" class="mat-elevation-z8 roundedBorder">
      <app-remittance-details-header
        [remitDetails]="remitDetails"
      ></app-remittance-details-header>
    </mat-card>
  </div>

  <!-- Remittance Actions -->
  <div
    class="col-md-12"
    *ngIf="
      remitDetails?.status === 'New' || remitDetails?.status === 'Needs Review'
    "
    style="
      margin: 20px 0px;
      justify-content: right;
      display: flex;
      gap: 15px;
      flex-wrap: wrap;
    "
  >
    <button
      mat-raised-button
      color="warn"
      type="button"
      *ngIf="remitDetails?.status === 'New'"
      (click)="needsReview()"
      [disabled]="processing"
    >
      <mat-icon>repeat</mat-icon> Needs Review
    </button>

    <button
      mat-raised-button
      color="primary"
      type="button"
      (click)="recordPayment()"
      [disabled]="processing"
    >
      <mat-icon>assignment_turned_in</mat-icon> Record Payment
    </button>
  </div>

  <!-- Claim Level Loop -->
  <div class="col-md-12" style="margin-top: 12px">
    <!-- Header -->
    <label class="labelHeader">
      <div class="justifyCenterContainer">
        <mat-icon>list_alt</mat-icon>
        Claim Responses ({{ remitDetails?.claimLevelLoop.length }})
      </div>
    </label>

    <!-- Claim List -->
    <div class="mat-elevation-z8" style="margin-top: 25px">
      <div class="table-responsive">
        <table
          mat-table
          [dataSource]="remitDetails.claimLevelLoop"
          class="mat-elevation-z8"
        >
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let row">
              <!-- For Mindwise Submitted Claims -->
              <div class="columnsGrid" *ngIf="row.mindwiseSubmittedClaims">
                <label>
                  Claim Id -
                  <button
                    mat-button
                    type="button"
                    color="primary"
                    (click)="viewClaimResponse(row)"
                  >
                    {{ row?.claimCode }}
                  </button>
                </label>
                <label>
                  Note Id -
                  <b> {{ row?.sessionCode }}, </b>
                </label>
                <label>
                  Member -
                  <b style="text-transform: capitalize">
                    {{ row?.memberLastName.toLowerCase() }},
                    {{ row?.memberFirstName.toLowerCase() }}
                  </b>
                </label>
                <label>
                  {{
                    row?.sessionDate | date : 'M/d/y, h:mm a' : currentTimezone
                  }}
                  {{ currentTimezone }},
                </label>
                <label>
                  {{ row?.visitReason }} ({{ row?.sessionDuration }}
                  min)
                </label>
              </div>
              <!-- For External EHR Submitted Claims -->
              <div *ngIf="!row.mindwiseSubmittedClaims">
                <label>
                  Claim Submitted through
                  <b> External EHR application</b>
                </label>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="claimStatusCode">
            <th mat-header-cell *matHeaderCellDef>Claim Status Code</th>
            <td mat-cell *matCellDef="let row">
              <span
                [class]="
                  row.claimStatusCode === '1'
                    ? 'primaryStatus'
                    : row.claimStatusCode === '2'
                    ? 'secondaryStatus'
                    : row.claimStatusCode === '4' ||
                      row.claimStatusCode === '22'
                    ? 'rejectedStatus'
                    : 'otherStatus'
                "
              >
                {{ row?.claimStatusDescription }}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="provider">
            <th mat-header-cell *matHeaderCellDef>Provider</th>
            <td mat-cell *matCellDef="let row">
              <label class="labelBold" style="text-transform: capitalize">
                {{ row?.providerLastName.toLowerCase() }},
                {{ row?.providerFirstName.toLowerCase() }}
                (NPI - {{ row?.providerNPI }})
              </label>
            </td>
          </ng-container>

          <ng-container matColumnDef="totalAmount">
            <th mat-header-cell *matHeaderCellDef>Submitted Amount</th>
            <td mat-cell *matCellDef="let row">
              <label class="labelBold">
                {{ row?.totalAmount | currency : 'USD' }}
              </label>
            </td>
          </ng-container>

          <ng-container matColumnDef="paidAmount">
            <th mat-header-cell *matHeaderCellDef>Paid Amount</th>
            <td mat-cell *matCellDef="let row">
              <label class="labelBold">
                {{ row?.paidAmount | currency : 'USD' }}
              </label>
            </td>
          </ng-container>

          <ng-container matColumnDef="patientResponsibility">
            <th mat-header-cell *matHeaderCellDef>Patient Responsibility</th>
            <td mat-cell *matCellDef="let row">
              <label
                class="labelBold"
                [style.color]="
                  row?.paidAmount < row?.totalAmount ? 'red' : 'green'
                "
              >
                {{ row?.patientResponsibility | currency : 'USD' }}
              </label>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef style="text-align: right">
              Action
            </th>
            <td mat-cell *matCellDef="let row" style="text-align: right">
              <button
                type="button"
                mat-raised-button
                (click)="viewClaimResponse(row)"
              >
                <mat-icon color="primary">visibility</mat-icon> View
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
