import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ConsentDocumentApiService } from 'src/app/_services/consent-docs/orgconsentdocument.service';
import { ToastMessageService } from 'src/app/_services/toast-message.service';
import { SignaturePadComponent } from 'src/app/pages/utils/signature-pad/signature-pad.component';

@Component({
  selector: 'app-consent-document-esign',
  templateUrl: './consent-document-esign.component.html',
  styleUrl: './consent-document-esign.component.css',
})
export class ConsentDocumentEsignComponent implements OnInit {
  @ViewChild('pdfViewerOnDemand', { static: false }) pdfViewerOnDemand;
  @ViewChild('signedPdf', { static: false }) signedPdf;

  recordId: string = '';
  consentDocumentId: string = '';
  documentName: string = '';

  isLoadingResults: boolean = true;
  processing = false;

  showSignedDocument = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConsentDocumentEsignComponent>,
    private dialog: MatDialog,
    public consentDocumentApiService: ConsentDocumentApiService,
    private toastMessageService: ToastMessageService
  ) {
    this.recordId = data.recordId;
    this.consentDocumentId = data.consentDocumentId;
    this.documentName = data.documentName;
  }

  ngOnInit(): void {
    this.getConsentDocumentInformation();
  }

  getConsentDocumentInformation() {
    this.isLoadingResults = true;

    this.consentDocumentApiService
      .previewConsentDocument(this.consentDocumentId)
      .subscribe(
        (res) => {
          this.isLoadingResults = false;
          this.pdfViewerOnDemand.pdfSrc = res;
          this.pdfViewerOnDemand.refresh();
        },
        (error) => {
          this.isLoadingResults = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to retrieve the consent document'
          );
        }
      );
  }

  openSignaturePad() {
    const dialogRef = this.dialog.open(SignaturePadComponent, {
      data: {
        consentRequestId: this.recordId,
        consentDocumentId: this.consentDocumentId,
        esignFormName: this.documentName,
        pageName: 'Member Consent Document',
        pageUrl: window.location.href,
      },
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((signatureId) => {
      if (signatureId) {
        // Display Toast and close this dialog
        this.toastMessageService.displaySuccessMessage(
          'Successfully signed the ' + this.documentName
        );
        this.dialogRef.close('success');
      }
    });
  }
}
