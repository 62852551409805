export const IntakeDocumentsList = [
  {
    id: 'consentDocuments',
    name: 'Consent Documents',
    documents: [],
  },
  {
    id: 'profileForms',
    name: 'Profile Forms',
    documents: [],
  },
  {
    id: 'medicalIntakeForms',
    name: 'Medical Intake Forms',
    documents: [],
  },
  {
    id: 'customForms',
    name: 'Custom Forms',
    documents: [],
  },
];
