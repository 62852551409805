
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IBpsaHealthHistory } from 'src/app/_models/session/clinical/bpsa-clinical-health-history.model';
import { IBpsaMedicalHistory } from 'src/app/_models/session/clinical/bpsa-clinical-medical-history.model';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class BpsaMedicalHistoryApiService {
    constructor(private httpClient: HttpClient) { }
    updateBpsaMedicalHistory(thisBpsaMedicalHistory: IBpsaMedicalHistory): Observable<IBpsaMedicalHistory> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.put<IBpsaMedicalHistory>(`${environment.apiUrl}/bpsamedicalhistory`, thisBpsaMedicalHistory, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }


    updateBpsaHealthHistory(thisBpsaHealthHistory: IBpsaHealthHistory): Observable<IBpsaHealthHistory> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.put<IBpsaHealthHistory>(`${environment.apiUrl}/bpsaclinicalhealthconditions`, thisBpsaHealthHistory, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }
}