import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MbscModule } from '@mobiscroll/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import {
  DateAdapter as CalendarDateAdapter,
  CalendarModule,
} from 'angular-calendar';
import { SchedulerModule } from 'angular-calendar-scheduler';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgChartsModule } from 'ng2-charts';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { NgxEditorModule } from 'ngx-editor';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgProgressModule } from 'ngx-progressbar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import {
  StreamAutocompleteTextareaModule,
  StreamChatModule,
} from 'stream-chat-angular';
import { SurveyModule } from 'survey-angular-ui';
import { environment } from '../environments/environment';
import { CustomDirectivesModule } from './_directives/custom-directives.module';
import { ChartService } from './_services/charts/chart.service';
import { LocalStorageService } from './_services/local-storage.service';
import { WebSocketService } from './_services/web-socket.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthConfigService } from './auth/auth-config.service';
import { AuthGuard } from './auth/auth.guard';
import { MessagingComponent } from './components/messaging/messaging.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { SidebarComponent } from './components/side-bar/sidebar.component';
import { AngularMaterialModule } from './material-imports';
import { AssessmentCancelledComponent } from './pages/assessment-cancelled/assessment-cancelled.component';
import { AssessmentCompleteComponent } from './pages/assessment-complete/assessment-complete.component';
import { CasesModule } from './pages/cases/cases.module';
import { ClaimsModule } from './pages/claims/claims.module';
import { ConfigurationsModule } from './pages/configurations/configurations.module';
import { NonProviderDashboardComponent } from './pages/dashboards/non-provider-dashboard/non-provider-dashboard.component';
import { PatientDashboardModule } from './pages/dashboards/patient-dashboard/patient-dashboard.module';
import { ProviderAllNotesComponent } from './pages/dashboards/provider-dashboard/provider-all-notes/provider-all-notes.component';
import { ProviderDashboardAssessmentsComponent } from './pages/dashboards/provider-dashboard/provider-dashboard-assessments/provider-dashboard-assessments.component';
import { ProviderDashboardCalendarComponent } from './pages/dashboards/provider-dashboard/provider-dashboard-calendar/provider-dashboard-calendar.component';
import { ProviderDashboardHeaderComponent } from './pages/dashboards/provider-dashboard/provider-dashboard-header/provider-dashboard-header.component';
import { ProviderDashboardComponent } from './pages/dashboards/provider-dashboard/provider-dashboard.component';
import { StaffSchedulesDashboardComponent } from './pages/dashboards/staff-schedules-dashboard/staff-schedules-dashboard.component';
import { FacilityOTPCodeComponent } from './pages/facility-otpcode/facility-otpcode.component';
import { FormsComponent } from './pages/forms/forms.component';
import { CustomFormsModule } from './pages/modules/custom-forms/custom-forms.module';
import { PagesWithoutTopBarModule } from './pages/pages-without-top-bar/pages-without-top-bar.module';
import { VerifySubmittedDocumentsComponent } from './pages/patient-details/patient-components/member-alerts/verify-submitted-documents/verify-submitted-documents.component';
import { PatientDetailModule } from './pages/patient-details/patient-details.module';
import { PatientsModule } from './pages/patients/patients.module';
import { PhysicianModule } from './pages/physician/physician.module';
import { ProfileComponent } from './pages/profile/profile.component';
import { ReferralModule } from './pages/referrals/referral.module';
import { RemittancesModule } from './pages/remittances/remittances.module';
import { GroupSessionSchedulerComponent } from './pages/schedulers/group-session-scheduler/group-session-scheduler.component';
import { IntakeSessionSchedulerComponent } from './pages/schedulers/intake-session-scheduler/intake-session-scheduler.component';
import { RescheduleAppointmentComponent } from './pages/schedulers/reschedule-appointment/reschedule-appointment.component';
import { SessionSchedulerComponent } from './pages/schedulers/session-scheduler/session-scheduler.component';
import { SignaturePadComponent } from './pages/utils/signature-pad/signature-pad.component';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';
import { GeneralDeleteConfirmDialogComponent } from './shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { VideoIframeComponent } from './shared/components/video-iframe/video-iframe.component';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { PipesModule } from './shared/pipes/pipes.module';
import { FacilitiesSandbox } from './shared/sandbox/facilities.sandbox';
import { FavoritesSandbox } from './shared/sandbox/favorites-sandbox';
import { OrgConfigSandbox } from './shared/sandbox/org-config.sandbox';
import { PermissionsSandbox } from './shared/sandbox/permissions.sandbox';
import { UserSettingsPropsSandbox } from './shared/sandbox/user-settings-props.sandbox';
import { VisitReasonsSandbox } from './shared/sandbox/visit-reasons.sandbox';
import { metaReducers, reducers } from './shared/store';
import {
  FacilityEffects,
  OrgConfigEffects,
  UserSettingsPropsEffect,
  VisitReasonsEffect,
} from './shared/store/effects';
import { FavoritesEffects } from './shared/store/effects/favorites.effects';
import { PermissionEffects } from './shared/store/effects/permission.effects';

export const DATE_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
    timeInput: 'LT',
    datetimeInput: 'L LT',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
    datetimeInput: 'L LT',
    timeInput: 'LT',
  },
};

function initializeKeycloak(
  keycloak: KeycloakService,
  authConfig: AuthConfigService
) {
  return () =>
    keycloak.init({
      config: {
        url: authConfig.auth,
        realm: authConfig.realm,
        clientId: authConfig.clientId,
      },
      initOptions: {
        onLoad: 'check-sso',
        pkceMethod: 'S256',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-renew.html',
      },
      enableBearerInterceptor: true,
      bearerExcludedUrls: ['/assets', '/clients/public'],
    });
}

@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    NavBarComponent,
    SidebarComponent,
    FormsComponent,
    ConfirmDialogComponent,
    FacilityOTPCodeComponent,
    AssessmentCompleteComponent,
    ProviderDashboardComponent,
    NonProviderDashboardComponent,
    StaffSchedulesDashboardComponent,
    ProviderDashboardHeaderComponent,
    ProviderDashboardAssessmentsComponent,
    ProviderDashboardCalendarComponent,
    ProviderAllNotesComponent,
    AssessmentCancelledComponent,
    GeneralDeleteConfirmDialogComponent,
    VideoIframeComponent,
    SignaturePadComponent,
    MessagingComponent,
    SessionSchedulerComponent,
    IntakeSessionSchedulerComponent,
    RescheduleAppointmentComponent,
    GroupSessionSchedulerComponent,
    VerifySubmittedDocumentsComponent
  ],
  imports: [
    CustomDirectivesModule,
    CustomFormsModule,
    MbscModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    FontAwesomeModule,
    AngularMaterialModule,
    ClaimsModule,
    ReferralModule,
    RemittancesModule,
    PatientsModule,
    NgxSpinnerModule,
    PatientDetailModule,
    PatientDashboardModule,
    PhysicianModule,
    CasesModule,
    ConfigurationsModule,
    PagesWithoutTopBarModule,
    RxReactiveFormsModule,
    KeycloakAngularModule,
    PipesModule,
    PdfJsViewerModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    SurveyModule,
    EffectsModule.forRoot([
      OrgConfigEffects,
      FacilityEffects,
      PermissionEffects,
      VisitReasonsEffect,
      FavoritesEffects,
      UserSettingsPropsEffect,
    ]),
    NgChartsModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300,
    }),
    NgProgressModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxEditorModule,
    CalendarModule.forRoot({
      provide: CalendarDateAdapter,
      useFactory: adapterFactory,
    }),
    SchedulerModule.forRoot({
      locale: 'en',
      headerDateFormat: 'daysRange',
      logEnabled: true,
    }),
    TranslateModule.forRoot(),
    StreamAutocompleteTextareaModule,
    StreamChatModule,
    NgxMaterialTimepickerModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, AuthConfigService],
    },
    {
      provide: Window,
      useValue: window,
    },
    provideNgxMask(),
    WebSocketService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    AuthGuard,
    AuthConfigService,
    OrgConfigSandbox,
    FacilitiesSandbox,
    PermissionsSandbox,
    VisitReasonsSandbox,
    FavoritesSandbox,
    UserSettingsPropsSandbox,
    LocalStorageService,
    { provide: 'WINDOW', useValue: window },
    { provide: LOCALE_ID, useValue: 'en-US' },
    DatePipe,
    ChartService,
  ],
  bootstrap: [AppComponent],
  exports: [AppRoutingModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
