<div class="row">
  <div class="col-md-12">
    <p style="font-size: 16px; font-weight: 450">
      <a routerLink="/" routerLinkActive="active">
        <em
          class="material-icons text_align-center"
          style="font-size: 18px; margin-top: -3px"
          >home</em
        >
      </a>
      &nbsp; / &nbsp;
      <span style="color: dimgray">Consent Documents</span>
    </p>
  </div>
</div>

<div class="row">
  <div class="col-md-12" style="padding-top: 15px">
    <div *ngIf="isLoadingResults">
      <mat-progress-bar
        mode="indeterminate"
        *ngIf="isLoadingResults"
      ></mat-progress-bar>
    </div>

    <div *ngIf="data.total === 0">
      <mat-error> No document found </mat-error>
    </div>

    <div class="table-responsive" *ngIf="data.total > 0">
      <table mat-table [dataSource]="data.items" matSort matSortDisableClear>
        <ng-container matColumnDef="documentName">
          <th mat-header-cell *matHeaderCellDef>Document Name</th>
          <td mat-cell *matCellDef="let row">
            <button
              *ngIf="row.status === 'Completed'"
              mat-button
              color="primary"
              type="button"
              (click)="viewConsentDocument(row)"
            >
              {{ row.documentName }}
              <i class="material-icons"> preview </i>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="sharedOn">
          <th mat-header-cell *matHeaderCellDef>Shared On</th>
          <td mat-cell *matCellDef="let row">
            {{ row.requestedDate | date : 'MM/dd/y' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef style="text-align: right">
            Status
          </th>
          <td mat-cell *matCellDef="let row" style="text-align: right">
            {{ row.status }} on {{ row.signedDate | date : 'MM/dd/y' }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        #paginator
        [length]="data.total"
        [pageSize]="data.per_page"
        [showFirstLastButtons]="true"
        *ngIf="data.total > data.per_page"
        (page)="getNext($event)"
      >
      </mat-paginator>
    </div>
  </div>
</div>
