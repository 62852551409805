<!-- Breadcrumb for remittance -->
<div *ngIf="this.remitId">
  <p style="font-size: 16px; font-weight: 450">
    <a routerLink="/" routerLinkActive="active">
      <em
        class="material-icons text_align-center"
        style="font-size: 18px; margin-top: -3px"
        >home</em
      >
    </a>
    &nbsp; / &nbsp;
    <a routerLink="/remittances/remit" routerLinkActive="active">
      <span style="color: #2196f3; cursor: pointer; font-weight: 500">
        Remittances
      </span>
    </a>
    &nbsp; / &nbsp;
    <a [routerLink]="['/remittances/remit', remitId]" routerLinkActive="active">
      <span style="color: #2196f3; cursor: pointer; font-weight: 500">
        Remit # {{ referenceNumber }}
      </span>
    </a>
    &nbsp; / &nbsp; {{ claimDetails?.claimCode }}
  </p>
</div>

<!-- Breadcrumb for claims -->
<div *ngIf="!this.remitId">
  <p style="font-size: 16px; font-weight: 450">
    <a routerLink="/" routerLinkActive="active">
      <em
        class="material-icons text_align-center"
        style="font-size: 18px; margin-top: -3px"
        >home</em
      >
    </a>
    &nbsp; / &nbsp;
    <a
      [routerLink]="
        subParent === 'claims'
          ? '/claims'
          : ['/main/member', claimDetails?.memberId, 'CLAIMS']
      "
      routerLinkActive="active"
    >
      <span
        style="
          color: #2196f3;
          cursor: pointer;
          font-weight: 500;
          text-transform: capitalize;
        "
      >
        Claims
      </span>
    </a>
    &nbsp; / &nbsp;
    <a
      routerLink="/remittances/remit/paymenthistory/{{ claimDetails?.claimId }}"
      [queryParams]="{ parent: parent, subParent: subParent }"
      routerLinkActive="active"
    >
      <span
        style="
          color: #2196f3;
          cursor: pointer;
          font-weight: 500;
          text-transform: capitalize;
        "
      >
        Claim # {{ claimDetails?.claimCode }}
      </span>
    </a>
    &nbsp; / &nbsp; {{ claimDetails?.createdDate | date : 'M/d/y' }}
  </p>
</div>

<div *ngIf="isLoadingResults || apiError">
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoadingResults"
  ></mat-progress-bar>

  <mat-error *ngIf="apiError">
    An error occurred while retrieving claim payment details
  </mat-error>
</div>

<!-- // Display Claim Payment Data -->
<div class="row" *ngIf="claimDetails?.id">
  <!-- Header -->
  <div class="col-md-12">
    <mat-card appearance="raised" class="mat-elevation-z8 roundedBorder">
      <div class="row">
        <!-- Claim Details -->
        <div class="col-md-4" style="margin-bottom: 15px">
          <div class="columnsGrid">
            <label class="labelHeader">
              <div class="justifyCenterContainer">
                <mat-icon style="margin-right: 10px">list_alt</mat-icon> Claim
                Information
              </div>
            </label>
          </div>

          <div class="columnsGrid">
            <label><b>Claim Control Number</b></label>
            <label class="labelBold">
              {{ claimDetails?.originalClaimRefNumber }}
            </label>
          </div>

          <div class="columnsGrid">
            <label><b>Claim Id</b></label>
            <label class="labelBold">
              {{ claimDetails?.claimCode }}
            </label>
          </div>

          <div class="columnsGrid">
            <label><b>Member</b></label>
            <label class="labelBold" style="text-transform: capitalize">
              {{ claimDetails?.memberLastName.toLowerCase() }},
              {{ claimDetails?.memberFirstName.toLowerCase() }}
            </label>
          </div>

          <div
            class="columnsGrid"
            *ngIf="claimDetails?.mindwiseSubmittedClaims"
          >
            <label><b>Session Details</b></label>
            <label class="labelBold">
              Note Id : {{ claimDetails?.sessionCode }}
            </label>
            <label class="labelBold" style="text-transform: capitalize">
              {{
                claimDetails?.sessionDate
                  | date : 'M/d/y, h:mm a' : currentTimezone
              }}
              {{ currentTimezone }}
            </label>
            <label class="labelBold" style="text-transform: capitalize">
              {{ claimDetails?.visitReason }} ({{
                claimDetails?.sessionDuration
              }}
              min)
            </label>
          </div>
        </div>

        <!-- Payment Details -->
        <div class="col-md-4" style="margin-bottom: 15px">
          <div class="columnsGrid">
            <label class="labelHeader">
              <div class="justifyCenterContainer">
                <mat-icon style="margin-right: 10px">paid</mat-icon> Payment
                Information
              </div>
            </label>
          </div>

          <div class="columnsGrid">
            <label><b>Payer</b></label>
            <label class="labelBold">
              {{ claimDetails?.payerName }}
            </label>
          </div>

          <div class="columnsGrid">
            <label><b>Payment Amount</b></label>
            <label class="labelBold">
              <span style="color: green">{{
                claimDetails?.paidAmount | currency : 'USD'
              }}</span>
              by
              {{ claimDetails?.paymentMethodCode }}
              ({{ claimDetails?.paymentMethodDescription }})
            </label>
            <label class="labelBold" style="text-transform: capitalize">
              Reference # {{ claimDetails?.paymentReferenceNumber }}
            </label>
          </div>

          <div class="columnsGrid">
            <label><b>Paid To</b></label>
            <label class="labelBold">
              {{ claimDetails?.payeeName }}
            </label>
          </div>
        </div>

        <!-- Charge Overview -->
        <div class="col-md-4" style="margin-bottom: 15px">
          <div class="columnsGrid">
            <label class="labelHeader">
              <div class="justifyCenterContainer">
                <mat-icon style="margin-right: 10px">receipt_long</mat-icon>
                Charges Overview
              </div>
            </label>
          </div>

          <div class="columnsGrid">
            <label><b>Total Charge</b></label>
            <label class="labelBold">
              {{ claimDetails?.totalAmount | currency : 'USD' }}
            </label>
          </div>

          <div class="columnsGrid">
            <label><b>Paid Amount</b></label>
            <label
              class="labelBold"
              [style.color]="
                claimDetails?.paidAmount < claimDetails?.totalAmount
                  ? 'red'
                  : 'green'
              "
            >
              {{ claimDetails?.paidAmount | currency : 'USD' }}
            </label>
          </div>

          <div class="columnsGrid">
            <label><b>Patient Responsibility</b></label>
            <label
              class="labelBold"
              [style.color]="
                claimDetails?.patientResponsibility > 0 ? 'red' : 'green'
              "
            >
              {{ claimDetails?.patientResponsibility | currency : 'USD' }}
            </label>
          </div>

          <label
            [class]="
              claimDetails?.claimStatusCode === '1'
                ? 'primaryStatus'
                : claimDetails?.claimStatusCode === '2'
                ? 'secondaryStatus'
                : claimDetails?.claimStatusCode === '4' ||
                  claimDetails?.claimStatusCode === '22'
                ? 'rejectedStatus'
                : 'otherStatus'
            "
          >
            {{ claimDetails?.claimStatusDescription }}
          </label>
        </div>
      </div>
    </mat-card>
  </div>

  <!-- Claim Level Adjustments -->
  <div
    class="col-md-12"
    style="margin-top: 25px"
    *ngIf="
      claimDetails?.claimLevelAdjustments &&
      claimDetails?.claimLevelAdjustments.length > 0
    "
  >
    <!-- Header -->
    <label class="labelHeader">
      <div class="justifyCenterContainer">
        <mat-icon>article</mat-icon>
        Claim Level Adjustments ({{
          claimDetails?.claimLevelAdjustments.length
        }})
      </div>
    </label>
    <!-- Claim Level Adjustments List -->
    <div class="mat-elevation-z8" style="margin-top: 5px">
      <div class="table-responsive">
        <table
          mat-table
          [dataSource]="claimDetails.claimLevelAdjustments"
          class="mat-elevation-z8"
        >
          <ng-container matColumnDef="group">
            <th mat-header-cell *matHeaderCellDef>Adjustment Group</th>
            <td mat-cell *matCellDef="let row">
              <label class="labelText">
                {{ row?.groupCode }} ({{ row?.groupCodeDescription }})
              </label>
            </td>
          </ng-container>

          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>Amount</th>
            <td mat-cell *matCellDef="let row">
              <label class="labelText">
                {{ row?.amount | currency : 'USD' }}
              </label>
            </td>
          </ng-container>

          <ng-container matColumnDef="reason">
            <th mat-header-cell *matHeaderCellDef>Reason</th>
            <td mat-cell *matCellDef="let row">
              <label class="labelText">
                {{ row?.reasonCode }} ({{ row?.reasonCodeDescription }})
              </label>
            </td>
          </ng-container>

          <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef>Quantity</th>
            <td mat-cell *matCellDef="let row">
              <label class="labelText">
                {{ row?.quantity }}
              </label>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="adjustmentDisplayColumn"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: adjustmentDisplayColumn"
          ></tr>
        </table>
      </div>
    </div>
  </div>

  <!-- Provider Level Adjustments -->
  <div
    class="col-md-12"
    style="margin-top: 25px"
    *ngIf="
      claimDetails?.providerLevelAdjustments &&
      claimDetails?.providerLevelAdjustments.length > 0
    "
  >
    <!-- Header -->
    <label class="labelHeader">
      <div class="justifyCenterContainer">
        <mat-icon>person</mat-icon>
        Provider Level Adjustments ({{
          claimDetails?.providerLevelAdjustments.length
        }})
      </div>
    </label>
    <!-- Claim Level Adjustments List -->
    <div class="mat-elevation-z8" style="margin-top: 5px">
      <div class="table-responsive">
        <table
          mat-table
          [dataSource]="claimDetails.providerLevelAdjustments"
          class="mat-elevation-z8"
        >
          <ng-container matColumnDef="group">
            <th mat-header-cell *matHeaderCellDef>Adjustment Group</th>
            <td mat-cell *matCellDef="let row">
              <label class="labelText">
                {{ row?.groupCode }} ({{ row?.groupCodeDescription }})
              </label>
            </td>
          </ng-container>

          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>Amount</th>
            <td mat-cell *matCellDef="let row">
              <label class="labelText">
                {{ row?.amount | currency : 'USD' }}
              </label>
            </td>
          </ng-container>

          <ng-container matColumnDef="reason">
            <th mat-header-cell *matHeaderCellDef>Reason</th>
            <td mat-cell *matCellDef="let row">
              <label class="labelText">
                {{ row?.reasonCode }} ({{ row?.reasonCodeDescription }})
              </label>
            </td>
          </ng-container>

          <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef>Quantity</th>
            <td mat-cell *matCellDef="let row">
              <label class="labelText">
                {{ row?.quantity }}
              </label>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="adjustmentDisplayColumn"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: adjustmentDisplayColumn"
          ></tr>
        </table>
      </div>
    </div>
  </div>

  <!-- Service line Claim Level Loop -->
  <div class="col-md-12" style="margin-top: 25px">
    <!-- Header -->
    <label class="labelHeader">
      <div class="justifyCenterContainer">
        <mat-icon>format_list_numbered</mat-icon>
        Service Line Payment Responses ({{
          claimDetails?.serviceLineLoop.length
        }})
      </div>
    </label>

    <!-- Claim List -->
    <div class="mat-elevation-z8" style="margin-top: 5px">
      <div class="table-responsive">
        <table
          mat-table
          [dataSource]="claimDetails.serviceLineLoop"
          class="mat-elevation-z8"
        >
          <ng-container matColumnDef="cptCode">
            <th mat-header-cell *matHeaderCellDef style="width: 100px">
              CPT Code
            </th>
            <td mat-cell *matCellDef="let row">
              <label class="labelBold" style="color: #2196f3">
                {{ row?.cptCode }}
              </label>
            </td>
          </ng-container>

          <ng-container matColumnDef="serviceDate">
            <th mat-header-cell *matHeaderCellDef style="width: 120px">
              Service Date
            </th>
            <td mat-cell *matCellDef="let row">
              <label class="labelBold">
                {{ row?.serviceDate }}
              </label>
            </td>
          </ng-container>

          <ng-container matColumnDef="billedAmount">
            <th mat-header-cell *matHeaderCellDef>Amount Billed</th>
            <td mat-cell *matCellDef="let row">
              <label class="labelBold">
                {{ row?.totalAmount | currency : 'USD' }}
              </label>
            </td>
          </ng-container>

          <ng-container matColumnDef="paidAmount">
            <th mat-header-cell *matHeaderCellDef>Paid Amount</th>
            <td mat-cell *matCellDef="let row">
              <label class="labelBold">
                {{ row?.paidAmount | currency : 'USD' }}
              </label>
            </td>
          </ng-container>

          <ng-container matColumnDef="patientResponsibility">
            <th mat-header-cell *matHeaderCellDef>Patient Responsibility</th>
            <td mat-cell *matCellDef="let row">
              <label
                class="labelBold"
                [style.color]="
                  row?.paidAmount < row?.totalAmount ? 'red' : 'green'
                "
              >
                {{ row?.patientResponsibility | currency : 'USD' }}
              </label>
            </td>
          </ng-container>
          <ng-container matColumnDef="adjustments">
            <th mat-header-cell *matHeaderCellDef>Adjustments</th>
            <td mat-cell *matCellDef="let row">
              <div
                class="columnsGrid"
                *ngFor="let adjustment of row.claimAdjustments"
                style="margin-bottom: 12px"
              >
                <label class="labelBold">
                  {{ adjustment?.groupCode }}
                  ({{ adjustment?.groupCodeDescription }})
                </label>
                <label style="margin: 0">
                  <b>{{ adjustment?.reasonCode }} : </b>
                  {{ adjustment?.reasonCodeDescription }}
                </label>
                <label style="margin: 0">
                  Amount - <b>{{ adjustment?.amount | currency : 'USD' }}</b>
                </label>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
