<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Trauma Related Shame Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="traumaRelatedShameForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >1. As a result of my traumatic experience, I have lost respect for
            myself.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q1">
              <mat-button-toggle value="Not true of me"
                >Not true of me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat true of me"
                >Somewhat true of me</mat-button-toggle
              >
              <mat-button-toggle value="Mostly true of me"
                >Mostly true of me</mat-button-toggle
              >
              <mat-button-toggle value="Completely true of me"
                >Completely true of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >2. Because of what happened to me, others find me less
            desirable.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q2">
              <mat-button-toggle value="Not true of me"
                >Not true of me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat true of me"
                >Somewhat true of me</mat-button-toggle
              >
              <mat-button-toggle value="Mostly true of me"
                >Mostly true of me</mat-button-toggle
              >
              <mat-button-toggle value="Completely true of me"
                >Completely true of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >3. I am ashamed of myself because of what happened to me.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q3">
              <mat-button-toggle value="Not true of me"
                >Not true of me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat true of me"
                >Somewhat true of me</mat-button-toggle
              >
              <mat-button-toggle value="Mostly true of me"
                >Mostly true of me</mat-button-toggle
              >
              <mat-button-toggle value="Completely true of me"
                >Completely true of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >4. As a result of my traumatic experience, others have seen parts
            of me that they want nothing to do with.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q4">
              <mat-button-toggle value="Not true of me"
                >Not true of me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat true of me"
                >Somewhat true of me</mat-button-toggle
              >
              <mat-button-toggle value="Mostly true of me"
                >Mostly true of me</mat-button-toggle
              >
              <mat-button-toggle value="Completely true of me"
                >Completely true of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >5. As a result of my traumatic experience, I cannot accept
            myself.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q5">
              <mat-button-toggle value="Not true of me"
                >Not true of me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat true of me"
                >Somewhat true of me</mat-button-toggle
              >
              <mat-button-toggle value="Mostly true of me"
                >Mostly true of me</mat-button-toggle
              >
              <mat-button-toggle value="Completely true of me"
                >Completely true of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >6. If others knew what happened to me, they would view me as
            inferior.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q6">
              <mat-button-toggle value="Not true of me"
                >Not true of me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat true of me"
                >Somewhat true of me</mat-button-toggle
              >
              <mat-button-toggle value="Mostly true of me"
                >Mostly true of me</mat-button-toggle
              >
              <mat-button-toggle value="Completely true of me"
                >Completely true of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >7. If others knew what happened to me, they would be disgusted with
            me.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q7">
              <mat-button-toggle value="Not true of me"
                >Not true of me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat true of me"
                >Somewhat true of me</mat-button-toggle
              >
              <mat-button-toggle value="Mostly true of me"
                >Mostly true of me</mat-button-toggle
              >
              <mat-button-toggle value="Completely true of me"
                >Completely true of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >8. I am ashamed of the way I behaved during my traumatic
            experience.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q8">
              <mat-button-toggle value="Not true of me"
                >Not true of me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat true of me"
                >Somewhat true of me</mat-button-toggle
              >
              <mat-button-toggle value="Mostly true of me"
                >Mostly true of me</mat-button-toggle
              >
              <mat-button-toggle value="Completely true of me"
                >Completely true of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >9. I am so ashamed of what happened to me that I sometimes want to
            escape from myself.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q9">
              <mat-button-toggle value="Not true of me"
                >Not true of me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat true of me"
                >Somewhat true of me</mat-button-toggle
              >
              <mat-button-toggle value="Mostly true of me"
                >Mostly true of me</mat-button-toggle
              >
              <mat-button-toggle value="Completely true of me"
                >Completely true of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >10. As a result of my traumatic experience, I find myself less
            desirable.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q10">
              <mat-button-toggle value="Not true of me"
                >Not true of me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat true of me"
                >Somewhat true of me</mat-button-toggle
              >
              <mat-button-toggle value="Mostly true of me"
                >Mostly true of me</mat-button-toggle
              >
              <mat-button-toggle value="Completely true of me"
                >Completely true of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >11. I am ashamed of the way I felt during my traumatic
            experience.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q11">
              <mat-button-toggle value="Not true of me"
                >Not true of me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat true of me"
                >Somewhat true of me</mat-button-toggle
              >
              <mat-button-toggle value="Mostly true of me"
                >Mostly true of me</mat-button-toggle
              >
              <mat-button-toggle value="Completely true of me"
                >Completely true of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >12. If others knew what had happened to me, they would look down on
            me.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q12">
              <mat-button-toggle value="Not true of me"
                >Not true of me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat true of me"
                >Somewhat true of me</mat-button-toggle
              >
              <mat-button-toggle value="Mostly true of me"
                >Mostly true of me</mat-button-toggle
              >
              <mat-button-toggle value="Completely true of me"
                >Completely true of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >13. As a result of my traumatic experience, there are parts of me
            that I want to get rid of.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q13">
              <mat-button-toggle value="Not true of me"
                >Not true of me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat true of me"
                >Somewhat true of me</mat-button-toggle
              >
              <mat-button-toggle value="Mostly true of me"
                >Mostly true of me</mat-button-toggle
              >
              <mat-button-toggle value="Completely true of me"
                >Completely true of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >14. If others knew what happened to me, they would not like
            me.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q14">
              <mat-button-toggle value="Not true of me"
                >Not true of me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat true of me"
                >Somewhat true of me</mat-button-toggle
              >
              <mat-button-toggle value="Mostly true of me"
                >Mostly true of me</mat-button-toggle
              >
              <mat-button-toggle value="Completely true of me"
                >Completely true of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >15. Because of my traumatic experience, I feel inferior to
            others.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q15">
              <mat-button-toggle value="Not true of me"
                >Not true of me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat true of me"
                >Somewhat true of me</mat-button-toggle
              >
              <mat-button-toggle value="Mostly true of me"
                >Mostly true of me</mat-button-toggle
              >
              <mat-button-toggle value="Completely true of me"
                >Completely true of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >16. If others knew what happened to me, they would be ashamed of
            me.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q16">
              <mat-button-toggle value="Not true of me"
                >Not true of me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat true of me"
                >Somewhat true of me</mat-button-toggle
              >
              <mat-button-toggle value="Mostly true of me"
                >Mostly true of me</mat-button-toggle
              >
              <mat-button-toggle value="Completely true of me"
                >Completely true of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >17. If others knew what happened to me, they would find me
            unacceptable.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q17">
              <mat-button-toggle value="Not true of me"
                >Not true of me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat true of me"
                >Somewhat true of me</mat-button-toggle
              >
              <mat-button-toggle value="Mostly true of me"
                >Mostly true of me</mat-button-toggle
              >
              <mat-button-toggle value="Completely true of me"
                >Completely true of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >18. As a result of my traumatic experience, a part of me has been
            exposed that others find shameful.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q18">
              <mat-button-toggle value="Not true of me"
                >Not true of me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat true of me"
                >Somewhat true of me</mat-button-toggle
              >
              <mat-button-toggle value="Mostly true of me"
                >Mostly true of me</mat-button-toggle
              >
              <mat-button-toggle value="Completely true of me"
                >Completely true of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >19. If others knew how I behaved during my traumatic experience,
            they would be ashamed of me.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q19">
              <mat-button-toggle value="Not true of me"
                >Not true of me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat true of me"
                >Somewhat true of me</mat-button-toggle
              >
              <mat-button-toggle value="Mostly true of me"
                >Mostly true of me</mat-button-toggle
              >
              <mat-button-toggle value="Completely true of me"
                >Completely true of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >20. My traumatic experience has revealed a part of me that I am
            ashamed of.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q20">
              <mat-button-toggle value="Not true of me"
                >Not true of me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat true of me"
                >Somewhat true of me</mat-button-toggle
              >
              <mat-button-toggle value="Mostly true of me"
                >Mostly true of me</mat-button-toggle
              >
              <mat-button-toggle value="Completely true of me"
                >Completely true of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >21. As a result of my traumatic experience, I don 't like
            myself.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q21">
              <mat-button-toggle value="Not true of me"
                >Not true of me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat true of me"
                >Somewhat true of me</mat-button-toggle
              >
              <mat-button-toggle value="Mostly true of me"
                >Mostly true of me</mat-button-toggle
              >
              <mat-button-toggle value="Completely true of me"
                >Completely true of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >22. If others knew how I felt during my traumatic experience, they
            would be ashamed of me.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q22">
              <mat-button-toggle value="Not true of me"
                >Not true of me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat true of me"
                >Somewhat true of me</mat-button-toggle
              >
              <mat-button-toggle value="Mostly true of me"
                >Mostly true of me</mat-button-toggle
              >
              <mat-button-toggle value="Completely true of me"
                >Completely true of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >23. Because of what happened to me, I am disgusted with
            myself.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q23">
              <mat-button-toggle value="Not true of me"
                >Not true of me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat true of me"
                >Somewhat true of me</mat-button-toggle
              >
              <mat-button-toggle value="Mostly true of me"
                >Mostly true of me</mat-button-toggle
              >
              <mat-button-toggle value="Completely true of me"
                >Completely true of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >24. I am so ashamed of what happened to me that I sometimes want to
            become invisible to others.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q24">
              <mat-button-toggle value="Not true of me"
                >Not true of me</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat true of me"
                >Somewhat true of me</mat-button-toggle
              >
              <mat-button-toggle value="Mostly true of me"
                >Mostly true of me</mat-button-toggle
              >
              <mat-button-toggle value="Completely true of me"
                >Completely true of me</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          traumaRelatedShameForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!traumaRelatedShameForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
