<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Print Interface</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      type="button"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="printForm">
    <div class="row">
      <!--   Select Member  -->
      <div class="col-md-12">
        <label class="labelText">
          Select Member <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="selectedMemberId">
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Select Member"
                noEntriesFoundLabel="No matching member found"
                [(ngModel)]="searchMember"
                (ngModelChange)="filterMember()"
                [ngModelOptions]="{ standalone: true }"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let member of membersSelectionList"
              [value]="member.member.id"
            >
              {{ member.member.lastName }}, {{ member.member.firstName }}
            </mat-option>
          </mat-select>

          <mat-error *ngIf="printForm.controls['selectedMemberId'].invalid">
            Please select a member
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Print Type -->
      <div class="col-md-12">
        <label class="labelText">
          Select Print Type <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="printType"
            (selectionChange)="printTypeChanged($event)"
          >
            <mat-option value="Current" *ngIf="sessionDetails?.clinicalNoteId">
              Current Session Only ({{ sessionDetails?.sessionCode }})
            </mat-option>
            <mat-option value="Custom"> Custom </mat-option>
          </mat-select>

          <mat-error *ngIf="printForm.controls['printType'].invalid">
            Please select the print option
          </mat-error>
        </mat-form-field>
      </div>

      <!-- For Custom Start Date -->
      <div
        class="col-md-6"
        *ngIf="printForm.controls['printType'].value === 'Custom'"
      >
        <label class="labelText">
          Start Date <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            formControlName="startDate"
            matInput
            [matDatepicker]="startDatePicker"
            placeholder="MM/DD/YYYY"
            autoSlashDate
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>

          <mat-error *ngIf="printForm.controls['startDate'].invalid">
            Please select a valid start date
          </mat-error>
        </mat-form-field>
      </div>
      <!-- For Custom End Date -->
      <div
        class="col-md-6"
        *ngIf="printForm.controls['printType'].value === 'Custom'"
      >
        <label class="labelText">
          End Date <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            formControlName="endDate"
            matInput
            [matDatepicker]="endDatePicker"
            placeholder="MM/DD/YYYY"
            autoSlashDate
            [min]="printForm.controls['startDate'].value"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="endDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>

          <mat-error *ngIf="printForm.controls['endDate'].invalid">
            Please select a valid end date
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Generate Button -->
      <div class="col-md-12" style="text-align: center">
        <button
          mat-raised-button
          type="button"
          color="primary"
          [disabled]="printForm.invalid || processing"
          (click)="generatePDF()"
        >
          <mat-icon>print</mat-icon> Generate PDF Note Document
          <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>

  <!-- Open PDF on the right Side -->
  <div class="row pdfViewWrapper" *ngIf="pdfViewOpen" style="margin-top: 15px">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="isLoadingPDF"
    ></mat-progress-bar>

    <mat-error *ngIf="!pdfSrc && !isLoadingPDF">
      No note found for the selected date range
    </mat-error>

    <ng2-pdfjs-viewer
      [download]="'true'"
      [print]="'true'"
      [pdfSrc]="pdfSrc"
      #pdfViewerOnDemand
    ></ng2-pdfjs-viewer>
  </div>
</div>
