import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { addDays } from 'date-fns';
import { NgxSpinnerService } from 'ngx-spinner';
import { Patient } from 'src/app/_models';
import {
  CoreService,
  DateTimeZoneService,
  OpenAIService,
} from 'src/app/_services';
import { formatDate } from 'src/app/shared/utilities/utilities';
import { environment } from '../../../../../../../environments/environment';

var SockJs = require('sockjs-client');
var Stomp = require('stompjs');

@Component({
  selector: 'app-behavior-prediction',
  templateUrl: './behavior-prediction.component.html',
  styleUrls: ['./behavior-prediction.component.css'],
})
export class BehaviorPredictionComponent implements OnInit, OnDestroy {
  @Input() patient: Patient;

  processing: boolean = true;
  notEnoughData: boolean = false;

  currentDate = new Date();
  tomorrow = addDays(this.currentDate, 1);

  currentTimezone: string;
  actualPrediction: any;
  public stompClient: any;

  constructor(
    private spinner: NgxSpinnerService,
    private coreService: CoreService,
    private openAIService: OpenAIService,
    private dateTimeZoneService: DateTimeZoneService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit(): void {
    // Angular spinner
    this.spinner.show();

    this.initializeWebSocket();

    setTimeout(() => {
      // Trigger backend call to get the open-api response
      this.getPrediction();
    }, 2000);
  }

  ngOnDestroy(): void {
    if (this.stompClient !== null) {
      this.stompClient.disconnect();
    }
  }

  refreshPrediction() {
    this.processing = true;

    let fomattedCurrentDate = formatDate(this.currentDate);

    this.openAIService
      .predictMemberBehavior(
        this.patient.id,
        this.patient.facilityId,
        fomattedCurrentDate,
        true
      )
      .subscribe();
  }

  getPrediction() {
    let fomattedCurrentDate = formatDate(this.currentDate);

    this.openAIService
      .predictMemberBehavior(
        this.patient.id,
        this.patient.facilityId,
        fomattedCurrentDate,
        false
      )
      .subscribe();
  }

  getTodaysBorderColor(severity) {
    if (severity === 'Severe') {
      return '10px solid red';
    } else if (severity === 'Moderate') {
      return '10px solid orange';
    } else {
      return '10px solid green';
    }
  }

  initializeWebSocket() {
    let myId = this.coreService.getSubId();

    let websocketChannel = '/message/behaviorPrediction/' + myId;

    const serverUrl = `${environment.apiUrl}/mw-socket`;
    const ws = new SockJs(serverUrl);
    this.stompClient = Stomp.over(ws);
    const that = this;

    // Listening to web socket event
    this.stompClient.connect({}, function (frame) {
      that.stompClient.subscribe(websocketChannel, (message) => {
        if (message) {
          if (message?.body) {
            if (
              message?.body == 'Not enough incidents to be able to predict.'
            ) {
              that.notEnoughData = true;
            } else {
              that.actualPrediction = JSON.parse(message?.body);
            }
            that.processing = false;
          }
        }
      });
    });
  }
}
