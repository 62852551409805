import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assessment-complete',
  templateUrl: './assessment-complete.component.html',
  styleUrls: ['./assessment-complete.component.css']
})
export class AssessmentCompleteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
