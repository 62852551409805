<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle *ngIf="action === 'ADD'">Add Note</p>
    <p matDialogTitle *ngIf="action === 'EDIT'">Edit Note</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form
    [formGroup]="therapyNoteForm"
    novalidate
    (ngSubmit)="onCaseNoteFormSubmit()"
  >
    <div class="row">
      <div class="col-md-12">
        <label class="labelText">Note <span style="color: red">*</span></label>
        <mat-form-field appearance="outline">
          <textarea
            matInput
            formControlName="text"
            name="text"
            rows="10"
            required
          ></textarea>
          <mat-error *ngFor="let validation of validation_messages.text">
            <mat-error
              class="error-message"
              *ngIf="
                therapyNoteForm.get('text').hasError(validation.type) &&
                (therapyNoteForm.get('text').dirty ||
                  therapyNoteForm.get('text').touched)
              "
            >
              {{ validation.message }}</mat-error
            >
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12" style="text-align: right">
        <button
          [class]="
            therapyNoteForm.valid ? 'btn btn-primary' : 'btn btn-default'
          "
          mat-raised-button
          type="submit"
          [disabled]="!therapyNoteForm.valid || processing"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
