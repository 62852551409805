<div>
  <p style="font-size: 16px; font-weight: 450">
    <a
      [routerLink]="
        parent === 'Member' && memId
          ? ['/main/member', memId, 'NOTES']
          : parent === 'Assessments'
          ? '/main/assessments'
          : '/'
      "
      routerLinkActive="active"
    >
      <em
        class="material-icons text_align-center"
        style="font-size: 18px; margin-top: -3px"
        >home</em
      >
    </a>
    &nbsp; / &nbsp; Group Session - {{ sessionDetails?.sessionCode }}
  </p>
</div>

<!-- Left and Right Side Components -->
<div class="row">
  <!-- Left Side with options and patients -->
  <div
    class="col-md-4 col-xl-3"
    style="
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-bottom: 20px;
    "
  >
    <!-- // First is either Notes or Billing -->
    <!-- Calendar Options -->
    <mat-card
      appearance="raised"
      class="mat-elevation-z8"
      style="border-radius: 15px"
    >
      <label class="labelHeader">View</label>
      <div style="margin-bottom: 10px">
        <!--  Session Status Button -->
        <mat-button-toggle-group
          [(ngModel)]="viewCategory"
          [ngModelOptions]="{ standalone: true }"
        >
          <mat-button-toggle value="Notes"> Notes </mat-button-toggle>
          <mat-button-toggle value="Charges" *ngIf="hasBillingViewAccess">
            Charges
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card>

    <label class="labelBold" style="text-align: center">For</label>

    <!-- Patients  -->
    <mat-card
      appearance="raised"
      class="mat-elevation-z8"
      style="border-radius: 15px"
    >
      <label class="labelHeader" *ngIf="sessionDetails && !processing">
        <div class="justifyCenterContainer">
          <mat-icon>groups</mat-icon> &nbsp; Members ({{ allMembers.length }})

          <!-- Add new member button -->
          <button
            *ngIf="sessionDetails.status === 'Active'"
            mat-icon-button
            type="button"
            color="warn"
            style="margin-left: auto"
            matTooltip="Add Members"
            (click)="addNewMembers()"
          >
            <mat-icon>add_circle</mat-icon>
          </button>
        </div>
      </label>

      <!-- Print Option -->
      <button
        mat-raised-button
        type="button"
        class="mat-elevation-z8"
        style="margin-bottom: 15px; background-color: #d6eafb"
        (click)="viewPrintNotes()"
      >
        <mat-icon> print </mat-icon>
        Print Note
      </button>

      <!-- Search Box -->
      <div
        style="
          display: flex;
          align-items: center;
          padding: 5px;
          border-radius: 10px;
          border: 1px solid rgb(0, 0, 0, 0.2);
          background-color: #ffffff;
          padding: 5px;
        "
      >
        <span
          class="material-icons"
          style="text-align: left; font-size: 20px; opacity: 0.5"
        >
          search
        </span>
        <input
          type="search"
          placeholder="Member Name"
          [(ngModel)]="searchMemberName"
          [ngModelOptions]="{ standalone: true }"
          (ngModelChange)="searchByMemberChanged()"
          style="
            padding-left: 15px;
            width: 100%;
            background: transparent;
            border: 0;
          "
        />
      </div>

      <!-- Progress bar -->
      <mat-progress-bar
        mode="indeterminate"
        *ngIf="processing"
      ></mat-progress-bar>

      <!-- Members List -->
      <div class="membersListBox">
        <!-- For All Members -->
        <div
          [class]="
            selectedMemberId === 'All Members'
              ? 'memberBox activeTab'
              : 'memberBox'
          "
        >
          <!-- Left Side Icon -->
          <mat-icon> group </mat-icon>
          <!-- Middle Side Details -->
          <span style="font-weight: 450" (click)="viewAllMembers()">
            All Members
          </span>
          <!-- Righ Aligned Icon / Action -->
          <div style="text-align: right">
            <!-- Show this check box when all members are selected -->
            <mat-icon *ngIf="selectedMemberId === 'All Members'">
              check
            </mat-icon>
          </div>
        </div>

        <!-- Now For Each Member -->
        <div
          *ngFor="let member of filteredMembers"
          [class]="
            selectedMemberId === member.member.id
              ? member.attendance
                ? 'memberBox activeTab'
                : 'memberBox warnTab'
              : 'memberBox'
          "
        >
          <!-- Left Side Icon -->
          <mat-icon [style.color]="member.attendance ? 'green' : 'red'">
            {{ member?.attendance ? 'check_circle' : 'cancel' }}
          </mat-icon>
          <!-- Middle Side Details -->
          <span
            style="font-weight: 450; cursor: pointer"
            (click)="viewMember(member)"
          >
            {{ member?.member?.lastName }},
            {{ member?.member?.firstName }}
            <br />
            <span style="font-size: 13px; font-weight: 400">
              {{
                member?.member?.gender === 'M'
                  ? 'Male'
                  : member?.member?.gender === 'F'
                  ? 'Female'
                  : member?.member?.gender
              }},
              {{ member?.member?.dateOfBirth }}
            </span>
            <!-- Disclaimer for attendance not taken -->
            <span
              style="color: red; font-size: 13px; font-weight: 400"
              *ngIf="!member.attendance"
            >
              <br />
              Attendance not taken
            </span>
          </span>
          <!--Action Button -->
          <button
            mat-icon-button
            type="button"
            [matMenuTriggerFor]="menu"
            *ngIf="sessionDetails?.status === 'Active'"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <!-- Menu Actions -->
          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              type="button"
              *ngIf="!member.attendance"
              (click)="takeAttendance(member, true)"
              [disabled]="processing"
            >
              <mat-icon style="color: green"> note_alt </mat-icon>
              <span>Take Attendance</span>
            </button>

            <button
              mat-menu-item
              type="button"
              *ngIf="member.attendance"
              (click)="takeAttendance(member, false)"
              [disabled]="processing"
            >
              <mat-icon style="color: red"> disabled_by_default </mat-icon>
              <span>Remove Attendance</span>
            </button>

            <button
              mat-menu-item
              type="button"
              (click)="addRemoveMemberToGroup(member.member, false)"
              [disabled]="processing"
            >
              <mat-icon style="color: red"> delete_forever </mat-icon>
              <span>Remove Member</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </mat-card>
  </div>

  <!-- Right side, either notes or charges -->
  <div
    class="col-md-8 col-xl-9"
    style="
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-bottom: 20px;
    "
  >
    <!-- Patients  -->
    <mat-card
      *ngIf="sessionDetails?.id"
      appearance="raised"
      class="mat-elevation-z8"
      style="border-radius: 15px"
    >
      <!-- Disclaimer -->
      <div class="justifyCenterContainer">
        <mat-icon>highlight</mat-icon> &nbsp;
        <label style="margin: 0; padding: 0; text-transform: capitalize">
          You are currently
          <b>viewing {{ viewCategory }} {{ getMembersType() }}</b>
        </label>
      </div>

      <!-- Close Note Button -->
      <div
        class="row"
        *ngIf="
          hasClinicalNotesEditAccess &&
          sessionDetails?.clinicalNoteId &&
          sessionDetails?.status === 'Active'
        "
      >
        <div class="col-md-12" style="text-align: right">
          <button
            mat-raised-button
            type="button"
            color="warn"
            (click)="closeNote()"
            [disabled]="processing"
          >
            <mat-icon>fact_check</mat-icon> Close Note
          </button>
        </div>
      </div>

      <!-- Progress bar -->
      <mat-progress-bar
        mode="indeterminate"
        *ngIf="processing"
      ></mat-progress-bar>

      <!-- For charges/billing -->
      <app-group-member-billing
        *ngIf="viewCategory === 'Charges'"
        [hasBillingEditAccess]="hasBillingEditAccess"
        [sessionDetails]="sessionDetails"
        [selectedMemberId]="selectedMemberId"
        (refreshTherapyObject)="reloadSessionDetailsFromResponse($event)"
      >
      </app-group-member-billing>

      <!-- For Group level notes -->
      <app-group-level-notes
        *ngIf="viewCategory === 'Notes' && selectedMemberId === 'All Members'"
        [sessionDetails]="sessionDetails"
        [hasClinicalNotesAddAccess]="hasClinicalNotesAddAccess"
        (refreshTherapyObject)="reloadSessionDetailsFromResponse($event)"
      ></app-group-level-notes>

      <!-- For Member Level Notes -->
      <app-group-member-level-notes
        *ngIf="viewCategory === 'Notes' && selectedMemberId != 'All Members'"
        [selectedMemberId]="selectedMemberId"
        [sessionDetails]="sessionDetails"
        (refreshTherapyObject)="reloadSessionDetailsFromResponse($event)"
        [hasGoalsViewAccess]="hasGoalsViewAccess"
        [hasGoalsAddAccess]="hasGoalsAddAccess"
        [hasGoalsEditAccess]="hasGoalsEditAccess"
        [hasGoalsDeleteAccess]="hasGoalsDeleteAccess"
        [hasDiagnosisViewAccess]="hasDiagnosisViewAccess"
        [hasDiagnosisAddAccess]="hasDiagnosisAddAccess"
        [hasDiagnosisEditAccess]="hasDiagnosisEditAccess"
        [hasDiagnosisDeleteAccess]="hasDiagnosisDeleteAccess"
        [hasMedicationViewAccess]="hasMedicationViewAccess"
        [hasMedicationAddAccess]="hasMedicationAddAccess"
        [hasMedicationEditAccess]="hasMedicationEditAccess"
        [hasMedicationDeleteAccess]="hasMedicationDeleteAccess"
        [hasMemberAssessmentViewAccess]="hasMemberAssessmentViewAccess"
        [hasMemberAssessmentAddAccess]="hasMemberAssessmentAddAccess"
        [hasMemberAssessmentEditAccess]="hasMemberAssessmentEditAccess"
        [hasMemberAssessmentDeleteAccess]="hasMemberAssessmentDeleteAccess"
        [hasAssessmentChartsViewAccess]="hasAssessmentChartsViewAccess"
        [hasAssessmentGuidanceViewAccess]="hasAssessmentGuidanceViewAccess"
        [hasAssessmentGuidanceEditAccess]="hasAssessmentGuidanceEditAccess"
        [hasAssessmentSummaryViewAccess]="hasAssessmentSummaryViewAccess"
        [hasAssessmentSummaryEditAccess]="hasAssessmentSummaryEditAccess"
        [hasAssessmentTakeAddAccess]="hasAssessmentTakeAddAccess"
        [hasMemberPaymentsAddAccess]="hasMemberPaymentsAddAccess"
        [orgConfig]="orgConfig"
      >
      </app-group-member-level-notes>
    </mat-card>
  </div>
</div>
