import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { IAltmanMania } from 'src/app/_models/assessments/bipolar/altmanmania.model';
import { AltmanManiaApiService, ToastMessageService } from 'src/app/_services/';

@Component({
  selector: 'app-altman-mania',
  templateUrl: './altman-mania.component.html',
  styleUrls: ['./altman-mania.component.css'],
})
export class AltmanManiaComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public altmanManiaApiService: AltmanManiaApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) {}
  public riskScore: number;
  public altmanManiaForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  altmanManiaAssessment: IAltmanMania;
  altmanManiaAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.altmanManiaForm, this.errors);

    this.loadAltmanManiaAssessment();
  }

  loadAltmanManiaAssessment() {
    this.altmanManiaForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.altmanManiaApiService
        .getAltmanManiaDetails(this.assessmentId)
        .subscribe((altmanManiaAssessment) => {
          if (altmanManiaAssessment.data) {
            this.altmanManiaForm.controls['mood'].setValue(
              altmanManiaAssessment.data.mood.answer
            );
            this.altmanManiaForm.controls['confidence'].setValue(
              altmanManiaAssessment.data.confidence.answer
            );
            this.altmanManiaForm.controls['sleep'].setValue(
              altmanManiaAssessment.data.sleep.answer
            );
            this.altmanManiaForm.controls['talking'].setValue(
              altmanManiaAssessment.data.talking.answer
            );
            this.altmanManiaForm.controls['activity'].setValue(
              altmanManiaAssessment.data.activity.answer
            );
            this.riskScore = altmanManiaAssessment.data.riskScore;
          }
        });
    }
  }

  onFormSubmit() {
    if (this.altmanManiaForm.invalid) {
      this.altmanManiaForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.altmanManiaAssessment = Object.assign({}, this.altmanManiaAssessment);
    this.altmanManiaAssessment.patientId =
      this.altmanManiaForm.controls['patientId'].value;
    this.altmanManiaAssessment.sessionId =
      this.altmanManiaForm.controls['sessionId'].value;
    this.altmanManiaAssessment.assessmentRequestId =
      this.altmanManiaForm.controls['assessmentRequestId'].value;
    this.altmanManiaAssessment.mood = {
      answer: this.altmanManiaForm.controls['mood'].value,
    };
    this.altmanManiaAssessment.confidence = {
      answer: this.altmanManiaForm.controls['confidence'].value,
    };
    this.altmanManiaAssessment.sleep = {
      answer: this.altmanManiaForm.controls['sleep'].value,
    };
    this.altmanManiaAssessment.talking = {
      answer: this.altmanManiaForm.controls['talking'].value,
    };
    this.altmanManiaAssessment.activity = {
      answer: this.altmanManiaForm.controls['activity'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.altmanManiaAssessment.accessCode = this.accessCode;

      this.altmanManiaApiService
        .addGuestAltmanMania(this.altmanManiaAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Altman Mania Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Altman Mania Assessment'
            );
          }
        );
    } else {
      this.altmanManiaApiService
        .addAltmanMania(this.altmanManiaAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Altman Mania Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Altman Mania Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    mood: [{ type: 'required', message: 'Positive Mood is required' }],
    confidence: [{ type: 'required', message: 'Self-Confidence is required' }],
    sleep: [{ type: 'required', message: 'Sleep Patterns is required' }],
    talking: [{ type: 'required', message: 'Speech is required' }],
    activity: [{ type: 'required', message: 'Activity Level is required' }],
  };

  private buildForm() {
    this.altmanManiaForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      mood: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      confidence: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      sleep: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      talking: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      activity: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.altmanManiaForm.valueChanges.subscribe(() => this.onValueChanged());
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.altmanManiaForm) {
      return;
    }
    const form = this.altmanManiaForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
