<div class="container">
  <div class="dialogTitle" *ngIf="isModalWindow">
    <p matDialogTitle></p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      [mat-dialog-close]="reloadRequired"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoadingResults"
  ></mat-progress-bar>

  <survey
    *ngIf="!isLoadingResults"
    [model]="model"
    style="min-height: 1000px"
  ></survey>

  <div class="row" *ngIf="formSubmitted" style="margin-top: 25px">
    <div class="col-md-12" style="text-align: center">
      <button mat-raised-button color="warn" (click)="closeScreen()">
        Close Screen
      </button>
    </div>
  </div>
</div>
