<div class="dialogTitle">
  <p matDialogTitle>In-Session Interview</p>
  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-stepper linear="true" #stepper>
  <mat-step [stepControl]="assessmentType">
    <!-- Select Assessment Step -->
    <ng-template matStepLabel>Select Interview</ng-template>
    <div class="row">
      <div
        class="col-md-6"
        style="padding: 10px"
        *ngFor="let assessment of assessmentList"
      >
        <mat-card
          appearance="raised"
          (click)="onSelectAssessmentType(assessment, stepper)"
          class="mat-elevation-z8 selectable-card"
        >
          <span
            [matTooltip]="assessment.name"
            class="selection"
            *ngIf="selectedAssessment === assessment.name"
          >
            <mat-icon
              class="primary-color-icon"
              style="color: green; margin-top: 5px; margin-right: 5px"
              >check_circle</mat-icon
            >
          </span>
          <div>
            <label
              class="labelText"
              style="font-size: 16px; font-weight: 450; cursor: pointer"
            >
              {{ assessment.name }}
            </label>
            <label
              class="labelText"
              style="font-size: 15px; font-weight: 400; cursor: pointer"
            >
              {{ assessment.description }}
            </label>
          </div>
        </mat-card>
      </div>
    </div>
  </mat-step>
  <mat-step>
    <!-- Confirmation Step -->
    <ng-template matStepLabel>Confirm</ng-template>
    <label class="labelHeader">Member Details</label>
    <mat-card appearance="raised" class="mat-elevation-z8">
      <div class="row">
        <div class="col-md-3">
          <p style="font-size: 16px; font-weight: 450">Member Name</p>
        </div>
        <div class="col-md-9">
          <p style="font-size: 16px; font-weight: 400">
            {{ patient.lastName }}, {{ patient.firstName }}
          </p>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-md-3">
          <p style="font-size: 16px; font-weight: 450">Member Number</p>
        </div>
        <div class="col-md-9">
          <p style="font-size: 16px; font-weight: 400">
            {{ patient.memberNumber }}
          </p>
        </div>
      </div> -->
      <div class="row">
        <div class="col-md-3">
          <p style="font-size: 16px; font-weight: 450">Gender</p>
        </div>
        <div class="col-md-9">
          <p style="font-size: 16px; font-weight: 400">
            {{
              patient.gender === 'M'
                ? 'Male'
                : patient.gender === 'F'
                ? 'Female'
                : patient.gender
            }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <p style="font-size: 16px; font-weight: 450">Date of Birth</p>
        </div>
        <div class="col-md-9">
          <p style="font-size: 16px; font-weight: 400">
            {{ patient.dateOfBirth }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <p style="font-size: 16px; font-weight: 450">Phone Number</p>
        </div>
        <div class="col-md-9">
          <p style="font-size: 16px; font-weight: 400">
            {{ patient.phoneNumber }}
          </p>
        </div>
      </div>
    </mat-card>
    <br />
    <label class="labelHeader">Requesting Assessment Details</label>
    <mat-card appearance="raised" class="mat-elevation-z8">
      <div class="row">
        <div class="col-md-3">
          <p style="font-size: 16px; font-weight: 450">Assessment</p>
        </div>
        <div class="col-md-8">
          <p style="font-size: 16px; font-weight: 400">
            {{ assessmentType.value }}
          </p>
        </div>
        <div class="col-md-1" style="text-align: right">
          <button
            type="button"
            mat-icon-button
            color="warn"
            (click)="assessmentStepper(stepper)"
          >
            <mat-icon style="color: gainsboro"> create</mat-icon>
          </button>
        </div>
      </div>
    </mat-card>
    <br />
    <form
      [formGroup]="assessmentForm"
      novalidate
      (ngSubmit)="assessmentRequestSubmit()"
    >
      <div class="row">
        <div class="col-md-12" style="text-align: right">
          <button
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="processing"
          >
            Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
          </button>
        </div>
      </div>
    </form>
  </mat-step>
</mat-stepper>
