import { Component, Input, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { IClaims } from 'src/app/_models';
import { ClaimResubmissionCodesList } from 'src/app/shared/utilities/billing/claimResubmissionCodes';
import { AdjustmentGroupCodes } from 'src/app/shared/utilities/claimsAdjustments/adjustmentsGroupCode';
import { AdjustmentReasonCodes } from 'src/app/shared/utilities/claimsAdjustments/adjustmentsReasonCodes';

@Component({
  selector: 'app-secondary-claims-history-details',
  templateUrl: './secondary-claims-history-details.component.html',
  styleUrl: './secondary-claims-history-details.component.css',
})
export class SecondaryClaimsHistoryDetailsComponent implements OnInit {
  @Input() secondaryClaimsDetails: IClaims;

  todaysDate = new Date();
  secondaryClaimForm: FormGroup;
  adjustmentGroupCodesList = AdjustmentGroupCodes;
  adjustmentReasonCodesList = AdjustmentReasonCodes;
  resubmissionCodesList = ClaimResubmissionCodesList;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm() {
    this.secondaryClaimForm = this.formBuilder.group({
      id: new FormControl({
        value: this.secondaryClaimsDetails.id,
        disabled: true,
      }),
      claimCode: new FormControl({
        value: this.secondaryClaimsDetails.claimCode,
        disabled: true,
      }),
      organizationId: new FormControl({
        value: this.secondaryClaimsDetails.organizationId,
        disabled: true,
      }),
      patientId: new FormControl({
        value: this.secondaryClaimsDetails.patientId,
        disabled: true,
      }),
      billingProviderRefId: new FormControl({
        value: this.secondaryClaimsDetails.billingProviderRefId,
        disabled: true,
      }),
      sessionId: new FormControl({
        value: this.secondaryClaimsDetails.sessionId,
        disabled: true,
      }),
      sessionCode: new FormControl({
        value: this.secondaryClaimsDetails.sessionCode,
        disabled: true,
      }),
      therapySession: new FormControl({
        value: this.secondaryClaimsDetails.therapySession,
        disabled: true,
      }),

      billingStatus: new FormControl({
        value: this.secondaryClaimsDetails.billingStatus,
        disabled: true,
      }),
      billingSequence: new FormControl(
        { value: this.secondaryClaimsDetails.billingSequence, disabled: true },
        Validators.required
      ),

      // Secondary Claims Extra Fields
      primaryClaimCode: new FormControl({
        value: this.secondaryClaimsDetails.primaryClaimCode,
        disabled: true,
      }),
      primaryClaimId: new FormControl({
        value: this.secondaryClaimsDetails.primaryClaimId,
        disabled: true,
      }),

      adjudicationDate: new FormControl({
        value: this.secondaryClaimsDetails.adjudicationDate,
        disabled: true,
      }),
      payerPaidAmount: new FormControl({
        value: this.secondaryClaimsDetails.payerPaidAmount,
        disabled: true,
      }),
      remainingPatientLiability: new FormControl({
        value: this.secondaryClaimsDetails.remainingPatientLiability,
        disabled: true,
      }),

      insuranceDetails: new FormControl({
        value: this.secondaryClaimsDetails.insuranceDetails,
        disabled: true,
      }),
      priorAuthCode: new FormControl({
        value: this.secondaryClaimsDetails.priorAuthCode,
        disabled: true,
      }),
      priorAuthDetails: new FormControl({
        value: this.secondaryClaimsDetails.priorAuthDetails,
        disabled: true,
      }),

      claimLevelAdjustments: this.formBuilder.array([]),
      serviceLevelDetails: this.formBuilder.array([]),

      resubmissionCode: new FormControl({
        value: this.secondaryClaimsDetails?.resubmissionCode,
        disabled: true,
      }),
    });

    this.loadClaimLevelAdjustment(
      this.secondaryClaimsDetails.claimLevelAdjustments
    );
    this.loadServiceLineDetails(
      this.secondaryClaimsDetails.serviceLevelDetails
    );
  }

  loadClaimLevelAdjustment(claimLevelAdjustments) {
    // Remove the value from old control COB Adjustments
    const oldCOBAdjustmentControl = <FormArray>(
      this.secondaryClaimForm.get('claimLevelAdjustments')
    );
    while (oldCOBAdjustmentControl.length > 0) {
      oldCOBAdjustmentControl.removeAt(0);
    }

    claimLevelAdjustments.forEach((cladjustments) => {
      const control = <FormArray>(
        this.secondaryClaimForm.get('claimLevelAdjustments')
      );
      control.push(
        new FormGroup({
          groupCode: new FormControl({
            value: cladjustments.groupCode,
            disabled: true,
          }),
          groupCodeDescription: new FormControl({
            value: cladjustments.groupCodeDescription,
            disabled: true,
          }),
          reasonCode: new FormControl({
            value: cladjustments.reasonCode,
            disabled: true,
          }),
          reasonCodeDescription: new FormControl({
            value: cladjustments.reasonCodeDescription,
            disabled: true,
          }),
          amount: new FormControl({
            value: cladjustments.amount,
            disabled: true,
          }),
          quantity: new FormControl({
            value: cladjustments.quantity,
            disabled: true,
          }),
        })
      );
    });
  }

  // Loading the service line details
  loadServiceLineDetails(serviceLineLoop) {
    // Main Form Array Control
    const control = <FormArray>(
      this.secondaryClaimForm.get('serviceLevelDetails')
    );

    // Begin loop
    serviceLineLoop.forEach((slCOB, index) => {
      let newFormGroup = new FormGroup({
        adjudicationDate: new FormControl({
          value: slCOB.adjudicationDate,
          disabled: true,
        }),

        cptCode: new FormControl({ value: slCOB.cptCode, disabled: true }),
        modifier1: new FormControl({
          value: slCOB.modifier1,
          disabled: true,
        }),
        modifier2: new FormControl({
          value: slCOB.modifier2,
          disabled: true,
        }),
        modifier3: new FormControl({
          value: slCOB.modifier3,
          disabled: true,
        }),
        modifier4: new FormControl({
          value: slCOB.modifier4,
          disabled: true,
        }),

        quantity: new FormControl({ value: slCOB.quantity, disabled: true }),
        totalAmount: new FormControl({
          value: slCOB.totalAmount,
          disabled: true,
        }),
        paidAmount: new FormControl({
          value: slCOB.paidAmount,
          disabled: true,
        }),
        patientResponsibility: new FormControl({
          value: slCOB.patientResponsibility,
          disabled: true,
        }),

        claimAdjustments: this.formBuilder.array([]),
      });

      // Claim adjustment for Service level
      if (slCOB.claimAdjustments && slCOB.claimAdjustments.length > 0) {
        slCOB.claimAdjustments.forEach((adjustment) => {
          const claimLevelAdjustmentControl = <FormArray>(
            newFormGroup.get('claimAdjustments')
          );
          claimLevelAdjustmentControl.push(
            new FormGroup({
              groupCode: new FormControl({
                value: adjustment.groupCode,
                disabled: true,
              }),
              groupCodeDescription: new FormControl({
                value: adjustment.groupCodeDescription,
                disabled: true,
              }),
              reasonCode: new FormControl({
                value: adjustment.reasonCode,
                disabled: true,
              }),
              reasonCodeDescription: new FormControl({
                value: adjustment.reasonCodeDescription,
                disabled: true,
              }),
              amount: new FormControl({
                value: adjustment.amount,
                disabled: true,
              }),
              quantity: new FormControl({
                value: adjustment.quantity,
                disabled: true,
              }),
            })
          );
        });
      }

      control.push(newFormGroup);
    });
  }
}
