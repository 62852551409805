import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { IAssessmentGoal } from 'src/app/_models/configure/assessment-goal.model';
import {
  AssessmentHistoryApiService,
  ToastMessageService,
} from 'src/app/_services';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { AddPatientGoalDialog } from './dialog/add/add.component';

@Component({
  selector: 'app-patient-assessment-goals',
  templateUrl: './patient-assessment-goals.component.html',
  styleUrls: ['./patient-assessment-goals.component.css'],
})
export class PatientAssessmentGoalsComponent implements OnInit {
  goalsList: any = [];
  assessmentId: string;
  assessmentRequest: any;
  deleteDialogRef: any;
  editDialogRef: any;
  goalToBeDeleted: any;
  goalToBeEdited: any;

  deleteProcessing: boolean = false;
  reloadRequired: string = 'no';

  constructor(
    public dialogRef: MatDialogRef<PatientAssessmentGoalsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public assessmenthistoryApiService: AssessmentHistoryApiService,
    private dialog: MatDialog,
    private toastMessageService: ToastMessageService
  ) {
    this.assessmentRequest = data.record;
    this.assessmentId = data.record.id;
    this.goalsList = data.record.assessmentGoals;
  }

  ngOnInit(): void {}

  addGoal() {
    this.editDialogRef = this.dialog.open(AddPatientGoalDialog, {
      data: {
        action: 'ADD',
        goalsList: this.goalsList,
        assessmentId: this.assessmentId,
      },
      autoFocus: false,
      minWidth: '35vw',
    });
    this.editDialogRef.afterClosed().subscribe((response) => {
      if (response && response.response === 'reload') {
        this.reloadRequired = 'reload';
        this.goalsList = response.updatedGoals;
      }
    });
  }

  editGoal(goal: IAssessmentGoal) {
    this.goalToBeEdited = goal;
    this.editDialogRef = this.dialog.open(AddPatientGoalDialog, {
      data: {
        action: 'EDIT',
        goal: goal,
        goalsList: this.goalsList,
        assessmentId: this.assessmentId,
      },
      autoFocus: false,
      minWidth: '35vw',
    });
    this.editDialogRef.afterClosed().subscribe((response) => {
      if (response && response.response === 'reload') {
        this.reloadRequired = 'reload';
        this.goalsList = response.updatedGoals;
      }
    });
  }

  deleteGoal(goal: IAssessmentGoal) {
    this.goalToBeDeleted = goal;

    let dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to remove this goal?',
      },
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        // First filter the goal and remove the goal to be deleted from the goals list
        let newGoalsList = this.goalsList.filter((goal) => {
          if (goal.description != this.goalToBeDeleted.description) {
            return true;
          }
        });

        // Update the goals list for this assessment history
        this.assessmenthistoryApiService
          .updateAssessmentGoals(this.assessmentId, newGoalsList)
          .subscribe(
            (response) => {
              this.deleteProcessing = false;
              this.toastMessageService.displaySuccessMessage(
                'Goal has been deleted.'
              );
              this.reloadRequired = 'reload';
              this.goalsList = newGoalsList;
              this.deleteDialogRef.close();
              this.deleteDialogRef = null;
              this.goalToBeDeleted = null;
            },
            (error) => {
              this.deleteProcessing = false;
              this.toastMessageService.displayErrorMessage(
                'Error: Failed to delete the goal.'
              );
            }
          );
      }
    });
  }
}
