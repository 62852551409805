<app-intake-header
  sectionTitle="Member Health Information"
  [therapySession]="therapySession"
  [patient]="patient"
  [showPrintButton]="true"
></app-intake-header>
<mat-divider></mat-divider>

<div style="margin-top: 20px; padding: 10px">
  <form
    [formGroup]="intakehealthinfoForm"
    novalidate
    (ngSubmit)="onIntakeHealthInfoFormSubmit()"
  >
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-sm-12">
          <label class="labelText"
            >How would you rate your current physical health?
            <span style="color: red">*</span></label
          >&nbsp;
          <mat-button-toggle-group formControlName="rateYourHealth">
            <mat-button-toggle value="Poor">Poor</mat-button-toggle>
            <mat-button-toggle value="Unsatisfactory"
              >Unsatisfactory</mat-button-toggle
            >
            <mat-button-toggle value="Satisfactory"
              >Satisfactory</mat-button-toggle
            >
            <mat-button-toggle value="Good">Good</mat-button-toggle>
            <mat-button-toggle value="Very good">Very good</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>

    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-sm-12">
          <label class="labelText"
            >How would you rate your current sleeping habits?
            <span style="color: red">*</span></label
          >&nbsp;
          <mat-button-toggle-group formControlName="slepingHabits">
            <mat-button-toggle value="Poor">Poor</mat-button-toggle>
            <mat-button-toggle value="Unsatisfactory"
              >Unsatisfactory</mat-button-toggle
            >
            <mat-button-toggle value="Satisfactory"
              >Satisfactory</mat-button-toggle
            >
            <mat-button-toggle value="Good">Good</mat-button-toggle>
            <mat-button-toggle value="Very good">Very good</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-sm-12">
          <label class="labelText"
            >How would you rate your current appetite or eating patterns?
            <span style="color: red">*</span></label
          >&nbsp;
          <mat-button-toggle-group formControlName="eatingPatterns">
            <mat-button-toggle value="Poor">Poor</mat-button-toggle>
            <mat-button-toggle value="Unsatisfactory"
              >Unsatisfactory</mat-button-toggle
            >
            <mat-button-toggle value="Satisfactory"
              >Satisfactory</mat-button-toggle
            >
            <mat-button-toggle value="Good">Good</mat-button-toggle>
            <mat-button-toggle value="Very good">Very good</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-sm-12">
          <label class="labelText"
            >Are you currently experiencing overwhelming sadness, grief, or
            depression? <span style="color: red">*</span></label
          >&nbsp;
          <mat-button-toggle-group formControlName="sadneddGriefDepression">
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="No">No</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-sm-12">
          <label class="labelText"
            >Are you currently experiencing suicidal thoughts, attempts, or
            self-harm? <span style="color: red">*</span></label
          >&nbsp;
          <mat-button-toggle-group formControlName="suicidalThoughts">
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="No">No</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-sm-12">
          <label class="labelText"
            >Are you experiencing anxiety, panic attacks, or phobias?
            <span style="color: red">*</span></label
          >&nbsp;
          <mat-button-toggle-group formControlName="anxietyPanicAttacksPhobias">
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="No">No</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-sm-12">
          <label class="labelText"
            >Are you currently experiencing any chronic pain?
            <span style="color: red">*</span></label
          >&nbsp;
          <mat-button-toggle-group formControlName="chronicPain">
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="No">No</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-sm-12">
          <label class="labelText"
            >Do you drink alcohol more than once a week?
            <span style="color: red">*</span></label
          >&nbsp;
          <mat-button-toggle-group formControlName="alcoholMoreThanOnceAWeek">
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="No">No</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-sm-12">
          <label class="labelText"
            >How often do you engage in recreational drug use?
            <span style="color: red">*</span></label
          >&nbsp;
          <mat-button-toggle-group formControlName="recreationalDrugUse">
            <mat-button-toggle value="Daily">Daily</mat-button-toggle>
            <mat-button-toggle value="Weekly">Weekly</mat-button-toggle>
            <mat-button-toggle value="Monthly">Monthly</mat-button-toggle>
            <mat-button-toggle value="Infrequently"
              >Infrequently</mat-button-toggle
            >
            <mat-button-toggle value="Never">Never</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
  </form>
</div>
