<div class="container">
  <div class="dialogTitle" style="margin-bottom: 25px">
    <p matDialogTitle>Manual Eligibility Check</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="eligibilityForm">
    <div class="row">
      <!-- Payer Information -->
      <div class="col-md-12" style="margin-bottom: 15px">
        <mat-card
          class="mat-elevation-z8"
          apperance="raised"
          style="border-radius: 10px"
        >
          <label class="labelHeader">Payer Information</label>

          <label class="labelText">
            Insurance payer <span style="color: red">*</span>
          </label>
          <mat-form-field appearance="outline">
            <mat-select
              formControlName="claimPayerId"
              required
              (selectionChange)="insuranceSelected($event)"
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Payer Name"
                  noEntriesFoundLabel="No matching payer found"
                  [(ngModel)]="searchPayer"
                  (ngModelChange)="searchByPayerChanged()"
                  [ngModelOptions]="{ standalone: true }"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let payer of insuranceList.items"
                [value]="payer.id"
              >
                {{ payer.payerName }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="eligibilityForm.controls['claimPayerId'].invalid">
              Please select the Insurance Provider
            </mat-error>
          </mat-form-field>
        </mat-card>
      </div>

      <!-- Provider Information -->
      <div class="col-md-12" style="margin-bottom: 15px">
        <mat-card
          class="mat-elevation-z8"
          apperance="raised"
          style="border-radius: 10px"
        >
          <label class="labelHeader">Provider Information</label>

          <div class="row">
            <div class="col-lg-4">
              <label class="labelText">
                Individual NPI <span style="color: red">*</span>
              </label>

              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="providerIndividualNPI"
                  required
                />

                <mat-error
                  *ngIf="eligibilityForm.get('providerIndividualNPI').invalid"
                >
                  Please enter a valid NPI
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <label class="labelText">
                Provider First Name <span style="color: red">*</span></label
              >
              <mat-form-field appearance="outline" fxFlex>
                <input matInput formControlName="providerFirstName" required />
                <mat-error
                  *ngIf="
                    eligibilityForm
                      .get('providerFirstName')
                      .hasError('required')
                  "
                >
                  First Name can't be empty
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <label class="labelText">
                Provider Last Name <span style="color: red">*</span></label
              >
              <mat-form-field appearance="outline" fxFlex>
                <input matInput formControlName="providerLastName" required />
                <mat-error
                  *ngIf="
                    eligibilityForm.get('providerLastName').hasError('required')
                  "
                >
                  Last Name can't be empty
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Subscriber Information -->
      <div class="col-md-12" style="margin-bottom: 15px">
        <mat-card
          class="mat-elevation-z8"
          apperance="raised"
          style="border-radius: 10px"
        >
          <label class="labelHeader">Member Information</label>

          <div class="row">
            <div class="col-md-6">
              <label class="labelText">
                Member ID <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline" fxFlex>
                <input matInput formControlName="subscriberMemberId" required />
                <mat-error
                  *ngIf="
                    eligibilityForm
                      .get('subscriberMemberId')
                      .hasError('required')
                  "
                >
                  Member ID can't be empty
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <label class="labelText">
                Member DOB <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline" fxFlex>
                <input
                  matInput
                  [max]="today"
                  [matDatepicker]="dobPicker"
                  formControlName="memberDOB"
                  required
                  autoSlashDate
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="dobPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #dobPicker></mat-datepicker>
                <mat-error
                  *ngIf="eligibilityForm.get('memberDOB').hasError('required')"
                >
                  DOB can't be empty
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <label class="labelText">
                Member First Name <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline" fxFlex>
                <input matInput formControlName="memberFirstName" required />
                <mat-error
                  *ngIf="
                    eligibilityForm.get('memberFirstName').hasError('required')
                  "
                >
                  First Name can't be empty
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <label class="labelText">
                Member Last Name <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline" fxFlex>
                <input matInput formControlName="memberLastName" required />
                <mat-error
                  *ngIf="
                    eligibilityForm.get('memberLastName').hasError('required')
                  "
                >
                  Last Name can't be empty
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Subscriber is same as the member -->
            <div class="col-md-12">
              <label class="labelBold">
                Member is the subscriber <span style="color: red">*</span>
              </label>

              <mat-checkbox
                color="primary"
                class="checkbox-margin"
                formControlName="subscriberSameAsMember"
                (change)="susbcriberSameAsChanged($event)"
              ></mat-checkbox>
            </div>

            <!-- If the subscriber is different -->
            <div
              class="col-md-6"
              *ngIf="!eligibilityForm.controls['subscriberSameAsMember'].value"
            >
              <label class="labelText">
                Subscriber First Name <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline" fxFlex>
                <input matInput formControlName="subscriberFirstName" />
                <mat-error
                  *ngIf="
                    eligibilityForm
                      .get('subscriberFirstName')
                      .hasError('required')
                  "
                >
                  First Name can't be empty
                </mat-error>
              </mat-form-field>
            </div>
            <div
              class="col-md-6"
              *ngIf="!eligibilityForm.controls['subscriberSameAsMember'].value"
            >
              <label class="labelText">
                Subscriber Last Name <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline" fxFlex>
                <input matInput formControlName="subscriberLastName" />
                <mat-error
                  *ngIf="
                    eligibilityForm
                      .get('subscriberLastName')
                      .hasError('required')
                  "
                >
                  Last Name can't be empty
                </mat-error>
              </mat-form-field>
            </div>
            <div
              class="col-md-12"
              *ngIf="!eligibilityForm.controls['subscriberSameAsMember'].value"
            >
              <label class="labelText">
                Subscriber DOB <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline" fxFlex>
                <input
                  matInput
                  [max]="today"
                  [matDatepicker]="dobPicker"
                  formControlName="subscriberDOB"
                  autoSlashDate
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="dobPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #dobPicker></mat-datepicker>
                <mat-error
                  *ngIf="
                    eligibilityForm.get('subscriberDOB').hasError('required')
                  "
                >
                  DOB can't be empty
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Service Information -->
      <div class="col-md-12" style="margin-bottom: 15px">
        <mat-card
          class="mat-elevation-z8"
          apperance="raised"
          style="border-radius: 10px"
        >
          <label class="labelHeader">Service Information</label>

          <div class="row">
            <div class="col-md-6">
              <label class="labelText">
                Service Type <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="serviceType">
                  <mat-option value="30">Medical Benefits (30)</mat-option>
                  <mat-option value="96">Professional (96)</mat-option>
                  <mat-option value="A4">Psychiatric (A4)</mat-option>
                  <mat-option value="A6">Psychotherapy (A6)</mat-option>
                  <mat-option value="A7">
                    Psychiatric - InPatient (A7)
                  </mat-option>
                  <mat-option value="A8">
                    Psychiatric - OutPatient (A8)
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="eligibilityForm.controls['serviceType'].invalid"
                >
                  Please select Service Type
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <label class="labelText">
                Date of Service <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  formControlName="startDate"
                  matInput
                  [matDatepicker]="startDatePicker"
                  [min]="today"
                  placeholder="MM/DD/YYYY"
                  required
                  autoSlashDate
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="startDatePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #startDatePicker></mat-datepicker>
                <mat-error
                  *ngIf="eligibilityForm.controls['startDate'].invalid"
                >
                  Please select the date of service
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <div class="col-md-12" style="text-align: right">
        <button
          color="primary"
          mat-raised-button
          type="button"
          [disabled]="processing || eligibilityForm.invalid"
          (click)="submitForm()"
        >
          Submit
          <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
