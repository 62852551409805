<div class="row" *ngIf="errors?.length > 0 && !dismissAlert">
  <div class="col-md-12">
    <mat-card appearance="raised" class="mat-elevation-z8 alertContainer">
      <div class="row">
        <!-- Alert Heading -->
        <div class="col-md-12" style="margin-bottom: 5px">
          <div class="alertHeading">
            <mat-icon style="color: red">warning_amber</mat-icon>
            <label class="labelBold" style="color: red"> Alerts </label>
          </div>

          <div class="alertAction">
            <button
              id="closeButton"
              mat-icon-button
              type="button"
              (click)="dismissAlert = !dismissAlert"
              style="opacity: 0.5"
              matTooltip="Dismiss Alert"
            >
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>

        <!-- // Rejected Reasons -->
        <div class="col-md-12" *ngFor="let error of errors">
          <mat-card
            appearance="raised"
            class="alertCard mat-elevation-z8"
            [style.border-left]="'5px solid'"
            [style.border-left-color]="error.color"
          >
            <label
              id="hoverAction"
              class="labelBold"
              [style.color]="error.color"
              (click)="reviewAlert(error)"
              matTooltip="Click to view"
            >
              {{ error.message }}
            </label>
          </mat-card>
        </div>
      </div>
    </mat-card>
  </div>
</div>
