<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Big Five Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-12 text-center">
      <button class="btn btn-primary" mat-raised-button>
        Agreeableness: {{ agreeableness }}/40
      </button>
      <button class="btn btn-primary" mat-raised-button>
        Extroversion: {{ extroversion }}/40
      </button>
      <button class="btn btn-primary" mat-raised-button>
        Conscientiousness: {{ conscientiousness }}/40
      </button>
      <button class="btn btn-primary" mat-raised-button>
        Neuroticism: {{ neuroticism }}/40
      </button>
      <button class="btn btn-primary" mat-raised-button>
        Openness: {{ openness }}/40
      </button>
    </div>
  </div>

  <form
    [formGroup]="bigFiveForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >1. I am the life of the party.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q1">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >2. I feel little concern for others.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q2">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >3. I am always prepared.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q3">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >4. I get stressed out easily.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q4">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >5. I have a rich vocabulary.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q5">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">6. I don’t talk a lot.</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q6">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >7. I am interested in people.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q7">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >8. I leave my belongings around.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q8">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >9. I am relaxed most of the time.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q9">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >10. I have difficulty understanding abstract ideas.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q10">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >11. I feel comfortable around people.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q11">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">12. I insult people.</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q12">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >13. I pay attention to details.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q13">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >14. I worry about things.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q14">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >15. I have a vivid imagination.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q15">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >16. I keep in the background.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q16">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >17. I sympathize with others’ feelings.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q17">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >18. I make a mess of things.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q18">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">19. I seldom feel blue.</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q19">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >20. I am not interested in abstract ideas.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q20">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >21. I start conversations.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q21">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >22. I am not interested in other people’s problems.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q22">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >23. I get chores done right away.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q23">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >24. I am easily disturbed.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q24">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >25. I have excellent ideas.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q25">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >26. I have little to say.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q26">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >27. I have a soft heart.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q27">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >28. I often forget to put things back in their proper place.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q28">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">29. I get upset easily.</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q29">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >30. I do not have a good imagination.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q30">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >31. I talk to a lot of different people at parties.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q31">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >32. I am not really interested in others.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q32">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">33. I like order.</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q33">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >34. I change my mood a lot.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q34">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >35. I am quick to understand things.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q35">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >36. I don’t like to draw attention to myself.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q36">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >37. I take time out for others.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q37">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">38. I shirk my duties.</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q38">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >39. I have frequent mood swings.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q39">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >40. I use difficult words.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q40">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >41. I don’t mind being the center of attention.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q41">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >42. I feel others’ emotions.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q42">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >43. I follow a schedule.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q43">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >44. I get irritated easily.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q44">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >45. I spend time reflecting on things.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q45">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >46. I am quiet around strangers.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q46">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >47. I make people feel at ease.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q47">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >48. I am exacting in my work.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q48">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">49. I often feel blue.</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q49">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">50. I am full of ideas.</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q50">
              <mat-button-toggle value="Disagree">Disagree</mat-button-toggle>
              <mat-button-toggle value="Slightly disagree"
                >Slightly disagree</mat-button-toggle
              >
              <mat-button-toggle value="Neutral">Neutral</mat-button-toggle>
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Agree">Agree</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          bigFiveForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!bigFiveForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
