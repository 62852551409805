import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class NpiAPIService {
  constructor(private httpClient: HttpClient) {}

  searchIndividualNPIRegistry(npiNumber: string) {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(`${environment.apiUrl}/checkidvnpirecord`, npiNumber, {
        headers,
      })
      .pipe(
        catchError((err) => {
          throw err;
        })
      );
  }
}
