<div class="row">
  <!-- Display Primary Claim Details -->
  <mat-accordion>
    <mat-expansion-panel style="border-radius: 15px; margin: 25px 0px">
      <mat-expansion-panel-header>
        <mat-panel-title style="justify-content: center">
          <label class="labelPointer"> View Primary Claim Details </label>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-session-billing
        [therapySession]="secondaryClaimsDetails?.therapySession"
        parentClass="Claims"
      ></app-session-billing>
    </mat-expansion-panel>
  </mat-accordion>

  <!-- Show Internal Scrubbing Errors -->
  <div
    class="col-md-12"
    style="margin-top: 15px; margin-bottom: 15px"
    *ngIf="
      secondaryClaimsDetails &&
      secondaryClaimsDetails?.mindwiseClaimsScrubbingErrors?.length > 0
    "
  >
    <div class="row">
      <div
        class="col-md-12"
        *ngFor="
          let error of secondaryClaimsDetails.mindwiseClaimsScrubbingErrors
        "
      >
        <mat-card
          appearance="raised"
          class="alertCard mat-elevation-z8"
          style="border-left: '5px solid red'"
        >
          <label class="labelText" style="color: red"> {{ error }}</label>
        </mat-card>
      </div>
    </div>
  </div>

  <!-- Show External Errors -->
  <div
    class="col-md-12"
    style="margin-top: 15px; margin-bottom: 15px"
    *ngIf="
      secondaryClaimsDetails &&
      secondaryClaimsDetails?.externalClaimsErrors?.length > 0
    "
  >
    <div class="row">
      <div
        class="col-md-12"
        *ngFor="let error of secondaryClaimsDetails.externalClaimsErrors"
      >
        <mat-card
          appearance="raised"
          class="alertCard mat-elevation-z8"
          style="border-left: '5px solid red'"
        >
          <label class="labelText" style="color: red"> {{ error }}</label>
        </mat-card>
      </div>
    </div>
  </div>

  <!-- Secondary Claims Details -->
  <div
    class="col-md-12"
    style="display: flex; gap: 15px; margin: 20px 0px; flex-wrap: wrap"
  >
    <label
      *ngIf="secondaryClaimsDetails?.billingStatus === 'On Hold'"
      class="holdClaimDisclaimer"
    >
      <mat-icon>back_hand</mat-icon>
      Claim is on Hold
    </label>
  </div>

  <!-- Claim Level Details -->
  <form [formGroup]="secondaryClaimForm">
    <div class="row">
      <!-- Claim Level COB -->
      <div class="col-md-6" style="margin-bottom: 15px">
        <label class="labelHeader">
          Secondary Claim Level COB Information
        </label>
        <mat-card
          appearance="raised"
          class="mat-elevation-z8"
          style="border-radius: 15px"
        >
          <div class="row">
            <div class="col-md-6">
              <label class="labelBold">Payer</label>
              <br />
              <label class="labelText">
                {{
                  secondaryClaimsDetails?.therapySession
                    ?.primaryInsuranceDetails?.payerName
                }}
              </label>
            </div>

            <div class="col-md-6">
              <label class="labelBold">
                Adjudication Date <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  formControlName="adjudicationDate"
                  matInput
                  [matDatepicker]="adjudicatedPicker"
                  (click)="adjudicatedPicker.open()"
                  [max]="todaysDate"
                  autoSlashDate
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="adjudicatedPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #adjudicatedPicker></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <label class="labelBold">
                Payer Paid Amount <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  formControlName="payerPaidAmount"
                  matInput
                  type="number"
                  min="0"
                />
                <span matPrefix>$</span>
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <label class="labelBold"> Remaining Patient Liability</label>
              <mat-form-field appearance="outline">
                <input
                  formControlName="remainingPatientLiability"
                  matInput
                  type="number"
                  min="0"
                />
                <span matPrefix>$</span>
              </mat-form-field>
            </div>

            <!-- Resubmission code -->
            <div
              style="margin-bottom: 12px"
              *ngIf="secondaryClaimsDetails?.claimSubmittedToPayer"
              class="col-md-12"
            >
              <label class="labelBold">
                Resubmission Code
                <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select
                  placeholder="Resubmission code"
                  formControlName="resubmissionCode"
                >
                  <mat-option
                    *ngFor="let code of resubmissionCodesList"
                    [value]="code.id"
                  >
                    {{ code.id }} - {{ code.description }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    secondaryClaimForm.controls['resubmissionCode'].invalid
                  "
                >
                  Please select the resubmission code
                </mat-error>
              </mat-form-field>
            </div>

            <div formArrayName="claimLevelAdjustments">
              <div
                *ngFor="
                  let claimAdjustment of secondaryClaimForm.get(
                    'claimLevelAdjustments'
                  )['controls'];
                  let index = index
                "
              >
                <div
                  class="row"
                  [formGroupName]="index"
                  style="margin-top: 15px"
                >
                  <div class="col-lg-3">
                    <label class="labelBold">
                      Adjustment Group <span style="color: red">*</span>
                    </label>
                    <mat-form-field appearance="outline">
                      <mat-select formControlName="groupCode">
                        <mat-option
                          *ngFor="let groupCode of adjustmentGroupCodesList"
                          [value]="groupCode.code"
                        >
                          {{ groupCode.code }} - {{ groupCode.description }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-3">
                    <label class="labelBold">
                      Amount <span style="color: red">*</span>
                    </label>
                    <mat-form-field appearance="outline">
                      <input
                        matInput
                        formControlName="amount"
                        type="number"
                        min="0"
                      />
                      <span matPrefix>$</span>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-3">
                    <label class="labelBold">
                      Reason Code <span style="color: red">*</span>
                    </label>
                    <mat-form-field appearance="outline">
                      <mat-select formControlName="reasonCode">
                        <mat-option
                          *ngFor="let reasonCode of adjustmentReasonCodesList"
                          [value]="reasonCode.code"
                        >
                          {{ reasonCode.code }} - {{ reasonCode.description }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-3">
                    <label class="labelBold">Units</label>
                    <mat-form-field appearance="outline">
                      <input matInput formControlName="quantity" />
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Secondary Claim Insurance-->
      <div class="col-md-6">
        <label class="labelHeader"> Secondary Insurance Information</label>
        <mat-card
          appearance="raised"
          class="mat-elevation-z8"
          style="border-radius: 15px"
        >
          <div class="row">
            <mat-error
              *ngIf="!secondaryClaimForm.controls['insuranceDetails'].value"
            >
              No secondary insurance selected
            </mat-error>
            <div
              style="margin: 15px 0px; position: relative"
              class="col-md-12"
              *ngIf="secondaryClaimForm.controls['insuranceDetails'].value"
            >
              <app-insurance-card
                [insurance]="
                  secondaryClaimForm.controls['insuranceDetails'].value
                "
                parentClass="Session"
              ></app-insurance-card>
            </div>

            <div
              class="col-md-12"
              *ngIf="secondaryClaimForm.controls['insuranceDetails'].value"
            >
              <label class="labelBold">
                Is there a Prior Authorization Code?
              </label>
              <mat-radio-group
                formControlName="priorAuthCode"
                style="column-gap: 15px; display: flex; flex-wrap: wrap"
                color="primary"
              >
                <mat-radio-button value="Yes"> Yes </mat-radio-button>
                <mat-radio-button value="No"> No </mat-radio-button>
              </mat-radio-group>
            </div>

            <div
              class-="col-md-12"
              *ngIf="
                secondaryClaimForm.controls['priorAuthCode'].value === 'Yes'
              "
            >
              <div
                *ngIf="secondaryClaimForm.controls['priorAuthDetails'].value"
                style="margin-top: 15px"
              >
                <div class="gridContainer">
                  <label class="labelBold"> Prior Auth # </label>
                  <label class="labelText">
                    {{
                      secondaryClaimForm.controls['priorAuthDetails'].value
                        .authNumber
                    }}
                  </label>
                  <label class="labelBold"> Effective Start </label>
                  <label class="labelText">
                    {{
                      secondaryClaimForm.controls['priorAuthDetails'].value
                        .effectiveStartDate | date : 'MM/dd/y'
                    }}
                  </label>
                  <label class="labelBold"> Effective End </label>
                  <label class="labelText">
                    {{
                      secondaryClaimForm.controls['priorAuthDetails'].value
                        .effectiveEndDate | date : 'MM/dd/y'
                    }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Service Level COB -->
      <div class="col-md-12" style="margin-top: 15px">
        <label class="labelHeader"> Service Level COB Information</label>
        <mat-card
          appearance="raised"
          class="mat-elevation-z8"
          style="border-radius: 15px"
        >
          <div formArrayName="serviceLevelDetails">
            <div
              style="border-bottom: 2px solid gainsboro"
              *ngFor="
                let serviceDetail of secondaryClaimForm.get(
                  'serviceLevelDetails'
                )['controls'];
                let index = index
              "
            >
              <div class="row" [formGroupName]="index">
                <div class="col-lg-1">
                  <label class="labelBold">CPT Code</label>
                  <br />
                  <label class="labelText">
                    {{ serviceDetail.controls['cptCode'].value }}
                  </label>
                </div>

                <div class="col-lg-1">
                  <label class="labelBold">Total Amount</label>
                  <br />
                  <label class="labelText">
                    {{
                      serviceDetail.controls['totalAmount'].value
                        | currency : 'USD'
                    }}
                  </label>
                </div>

                <div class="col-lg-2">
                  <label class="labelBold">
                    Adjudication Date <span style="color: red">*</span>
                  </label>
                  <mat-form-field appearance="outline">
                    <input
                      formControlName="adjudicationDate"
                      matInput
                      [matDatepicker]="adjudicatedPicker"
                      (click)="adjudicatedPicker.open()"
                      [max]="todaysDate"
                      autoSlashDate
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="adjudicatedPicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #adjudicatedPicker></mat-datepicker>
                  </mat-form-field>
                </div>

                <div class="col-lg-1">
                  <label class="labelBold">
                    Paid Units <span style="color: red">*</span>
                  </label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      type="number"
                      min="0"
                      formControlName="quantity"
                    />
                  </mat-form-field>
                </div>

                <div class="col-lg-1">
                  <label class="labelBold">
                    Paid Amount <span style="color: red">*</span>
                  </label>
                  <mat-form-field appearance="outline">
                    <input
                      formControlName="paidAmount"
                      matInput
                      type="number"
                      min="0"
                    />
                    <span matPrefix>$</span>
                  </mat-form-field>
                </div>

                <div
                  class="col"
                  style="border-left: 2px solid gainsboro; text-align: center"
                >
                  <label class="labelBold">Adjustments</label>
                  <br />
                  <div formArrayName="claimAdjustments">
                    <div
                      *ngFor="
                        let claimAdjustment of serviceDetail.get(
                          'claimAdjustments'
                        )['controls'];
                        let adjustmentIndex = index
                      "
                    >
                      <div
                        class="row"
                        [formGroupName]="adjustmentIndex"
                        style="margin-bottom: 15px"
                      >
                        <div class="col-lg-2">
                          <label class="labelBold">
                            Group <span style="color: red">*</span>
                          </label>
                          <mat-form-field appearance="outline">
                            <mat-select formControlName="groupCode">
                              <mat-option
                                *ngFor="
                                  let groupCode of adjustmentGroupCodesList
                                "
                                [value]="groupCode.code"
                              >
                                {{ groupCode.code }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>

                        <div class="col-lg-2">
                          <label class="labelBold">
                            Amount <span style="color: red">*</span>
                          </label>
                          <mat-form-field appearance="outline">
                            <input
                              matInput
                              formControlName="amount"
                              type="number"
                              min="0"
                            />
                            <span matPrefix>$</span>
                          </mat-form-field>
                        </div>

                        <div class="col-lg-2">
                          <label class="labelBold">
                            Reason <span style="color: red">*</span>
                          </label>
                          <mat-form-field appearance="outline">
                            <mat-select formControlName="reasonCode">
                              <mat-option
                                *ngFor="
                                  let reasonCode of adjustmentReasonCodesList
                                "
                                [value]="reasonCode.code"
                              >
                                {{ reasonCode.code }} -
                                {{ reasonCode.description }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>

                        <div class="col-lg-6">
                          <label class="labelBold"> Units </label>
                          <mat-form-field appearance="outline">
                            <input matInput formControlName="quantity" />
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </form>
</div>
