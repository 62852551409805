import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { CustomDirectivesModule } from 'src/app/_directives/custom-directives.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { AngularMaterialModule } from '../../material-imports';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ProfileComponent } from './profile/profile.component';

const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
  },
];

@NgModule({
  declarations: [ProfileComponent, ChangePasswordComponent],
  imports: [
    CommonModule,
    FormsModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    PipesModule,
    RouterModule.forChild(routes),
    CustomDirectivesModule,
  ],
  exports: [ProfileComponent, ChangePasswordComponent],
})
export class AccountModule {}
