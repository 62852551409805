import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { DataTablesModel } from 'src/app/_models';
import { ToastMessageService } from 'src/app/_services';
import { ConsentDocumentApiService } from 'src/app/_services/consent-docs/orgconsentdocument.service';
import { ViewMemberSignedConsentDocumentComponent } from '../../patient-details/patient-components/member-tab/member-tab-sections/member-consent-documents/view-member-signed-consent-document/view-member-signed-consent-document.component';

@Component({
  selector: 'app-member-completed-consent-documents',
  templateUrl: './member-completed-consent-documents.component.html',
  styleUrl: './member-completed-consent-documents.component.css',
})
export class MemberCompletedConsentDocumentsComponent implements OnInit {
  displayedColumns = ['documentName', 'sharedOn', 'status'];
  data: DataTablesModel = {} as DataTablesModel;
  isLoadingResults = false;

  constructor(
    public dialog: MatDialog,
    public consentDocumentApiService: ConsentDocumentApiService,
    private toastMessageService: ToastMessageService
  ) { }

  ngOnInit(): void {
    this.data.per_page = 10;
    this.data.page = 0;

    this.loadMySignedDocuments();
  }

  loadMySignedDocuments() {
    this.isLoadingResults = true;

    this.consentDocumentApiService
      .getMemberCompletedConsentDocuments(this.data.per_page, this.data.page)
      .subscribe({
        next: (response) => {
          this.isLoadingResults = false;
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }
        },
        error: (error) => {
          this.isLoadingResults = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load consent documents'
          );
        },
      });
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadMySignedDocuments();
  }

  viewConsentDocument(row) {
    this.dialog.open(ViewMemberSignedConsentDocumentComponent, {
      data: {
        recordId: row.id,
        documentName: row.documentName,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '55vw',
    });
  }
}
