import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HealthCondition, ITherapySession } from 'src/app/_models';
import { IBpsaFamilyHistoryList } from 'src/app/_models/session/clinical/bpsa-clinical-family-history-list.model';
import {
  BpsaFamilyHistoryApiService,
  ToastMessageService,
} from 'src/app/_services';

@Component({
  selector: 'app-member-family-history-list',
  templateUrl: './member-family-history-list.component.html',
  styleUrls: ['./member-family-history-list.component.css'],
})
export class MemberFamilyHistoryListComponent implements OnInit {
  @Input() therapySession: ITherapySession;
  @Input() loggedInUserId: string;
  @Input() hasBPSAClinicalAddAccess: boolean;
  @Input() hasBPSAClinicalEditAccess: boolean;

  @Output() updatedTherapySession = new EventEmitter<any>();

  private familyConditions: HealthCondition[];
  newFamilyHealthConditions: IBpsaFamilyHistoryList;

  familyConditionsList: any = [
    { name: 'Alcohol Abuse', selected: false },
    { name: 'Anemia', selected: false },
    { name: 'Anesthetic Complication', selected: false },
    { name: 'Arthritis', selected: false },
    { name: 'Asthma', selected: false },
    { name: 'Bladder Problems', selected: false },
    { name: 'Bleeding Disease', selected: false },
    { name: 'Breast Cancer', selected: false },
    { name: 'Colon Cancer', selected: false },
    { name: 'Depression', selected: false },
    { name: 'Diabetes', selected: false },
    { name: 'Heart Disease', selected: false },
    { name: 'High Blood Pressure', selected: false },
    { name: 'High Cholesterol', selected: false },
    { name: 'Kidney Disease', selected: false },
    { name: 'Leukemia', selected: false },
    { name: 'Lung/Respiratory Disease', selected: false },
    { name: 'Migraines', selected: false },
    { name: 'Osteoporosis', selected: false },
    { name: 'Other Cancer', selected: false },
    { name: 'Rectal Cancer', selected: false },
    { name: 'Seizures/Convulsions', selected: false },
    { name: 'Severe Allergy', selected: false },
    { name: 'Stroke/CVA of the Brain', selected: false },
    { name: 'Thyroid Problems', selected: false },
    {
      name: 'Mother, Grandmother, or Sister developed heart disease before the age of 65',
      selected: false,
    },
    {
      name: 'Father, Grandfather, or Brother developed heart disease before the age of 55',
      selected: false,
    },
  ];

  processing: boolean = false;
  constructor(
    public bpsafamilyhistoryApiService: BpsaFamilyHistoryApiService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit(): void {
    this.newFamilyHealthConditions = this.therapySession.bpsaFamilyHistoryList;

    this.familyConditions = this.newFamilyHealthConditions?.familyConditions;

    if (this.familyConditions) {
      for (let i = 0; i < this.familyConditionsList.length; i++) {
        if (this.familyConditions.includes(this.familyConditionsList[i].name)) {
          this.familyConditionsList[i].selected = true;
        }
      }
    }
  }

  updateFamilyHealthCondition() {
    let selectedHealthConditions = [];

    for (let i = 0; i < this.familyConditionsList.length; i++) {
      if (this.familyConditionsList[i].selected) {
        selectedHealthConditions.push(this.familyConditionsList[i].name);
      }
    }

    if (!this.newFamilyHealthConditions) {
      this.newFamilyHealthConditions = {} as IBpsaFamilyHistoryList;
    }

    this.newFamilyHealthConditions.sessionId = this.therapySession.id;
    this.newFamilyHealthConditions.patientId = this.therapySession.patientId;

    this.newFamilyHealthConditions.familyConditions = selectedHealthConditions;

    this.processing = true;
    this.bpsafamilyhistoryApiService
      .updateBpsaFamilyHistoryList(this.newFamilyHealthConditions)
      .subscribe(
        (response) => {
          this.processing = false;
          // this.toastMessageService.displaySuccessMessage(
          //   'Successfully updated Member Family Medical History'
          // );
        },
        (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Something went wrong. Please try again.'
          );
        }
      );
  }
}
