import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import {
  DateTimeZoneService,
  RemittancesAPIService,
  ToastMessageService,
} from 'src/app/_services';

@Component({
  selector: 'app-load-other-claim-from-eob',
  templateUrl: './load-other-claim-from-eob.component.html',
  styleUrl: './load-other-claim-from-eob.component.css',
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class LoadOtherClaimFromEobComponent implements OnInit {
  currentTimezone: string = '';
  primaryClaimDetails: any;
  isLoadingResults: boolean = false;

  paymentsList: any;
  displayedColumns = [
    'date',
    'details',
    'claimStatusCode',
    'totalAmount',
    'paidAmount',
    'patientResponsibility',
    'expand',
    'action',
  ];
  serviceColumns = [
    'cptCode',
    'serviceDate',
    'billedAmount',
    'paidAmount',
    'patientResponsibility',
    'adjustments',
  ];
  expandedElement: any;

  constructor(
    public dialogRef: MatDialogRef<LoadOtherClaimFromEobComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private remittancesApiService: RemittancesAPIService,
    private toastMessageService: ToastMessageService,
    private dateTimeZoneService: DateTimeZoneService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
    this.primaryClaimDetails = data.primaryClaimDetails;
  }

  ngOnInit(): void {
    this.getPaymentHistory();
  }

  getPaymentHistory() {
    this.isLoadingResults = true;
    this.remittancesApiService
      .getPaymentHistory(this.primaryClaimDetails.id)
      .subscribe({
        next: (response) => {
          this.isLoadingResults = false;
          this.paymentsList = response.items;
        },
        error: (error) => {
          this.isLoadingResults = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to retrieve claim payment details.'
          );
        },
      });
  }

  selectEOB(eob) {
    this.dialogRef.close({ type: 'success', eob: eob });
  }
}
