import {
  Component,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ToastMessageService } from 'src/app/_services';
import { AssessmentGoalHttpService } from 'src/app/_services/assessments/assessmentgoal.service';
import { AddAssessmentGoalNotesComponent } from './add-assessment-goal-notes/add-assessment-goal-notes.component';

@Component({
  selector: 'app-assessment-goal-notes',
  templateUrl: './assessment-goal-notes.component.html',
  styleUrls: ['./assessment-goal-notes.component.css'],
})
export class AssessmentGoalNotesComponent implements OnInit {
  @ViewChild('callDeleteConfirmation') callDeleteConfirmation: TemplateRef<any>;

  goal: any;
  goalNotes: any;
  isPatient: boolean = false;
  processing: boolean = false;
  deleteProcessing: boolean = false;
  reloadRequired: string = 'No';
  deleteNoteIndex: number;
  deleteDialogRef: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AssessmentGoalNotesComponent>,
    private dialog: MatDialog,
    private goalsApiService: AssessmentGoalHttpService,
    private toastMessageService: ToastMessageService
  ) {
    this.goal = data.goal;
    this.isPatient = data.isPatient;

    if (this.isPatient) {
      this.goalNotes = data.goal.patientNotes;
    } else {
      this.goalNotes = data.goal.physicianNotes;
    }
  }

  ngOnInit(): void {}

  addNote() {
    let addDialogRef = this.dialog.open(AddAssessmentGoalNotesComponent, {
      data: { goal: this.goal, action: 'ADD', isPatient: this.isPatient },
      autoFocus: false,
      disableClose: true,
      minWidth: '35vw',
    });
    addDialogRef.afterClosed().subscribe((response) => {
      if (response.response === 'success') {
        this.reloadRequired = 'reload';
        this.goalNotes = response.newNoteList;
      }
    });
  }

  editNote(index) {
    let editDialogRef = this.dialog.open(AddAssessmentGoalNotesComponent, {
      data: {
        goal: this.goal,
        action: 'EDIT',
        index: index,
        isPatient: this.isPatient,
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '35vw',
    });

    editDialogRef.afterClosed().subscribe((response) => {
      if (response.response === 'success') {
        this.reloadRequired = 'reload';
        this.goalNotes = response.newNoteList;
      }
    });
  }

  deleteNote(index) {
    this.deleteNoteIndex = index;
    this.deleteDialogRef = this.dialog.open(this.callDeleteConfirmation);
  }

  closeDeleteDialog(response) {
    if (response === 'Yes') {
      this.deleteProcessing = true;
      this.goalNotes.splice(this.deleteNoteIndex, 1);
      this.goal.goalNotes = this.goalNotes;

      this.goalsApiService.updateGoalNotes(this.goal, this.isPatient).subscribe(
        (response) => {
          this.deleteProcessing = false;
          this.reloadRequired = 'reload';
          this.toastMessageService.displaySuccessMessage(
            'Goal Notes have been updated.'
          );
          this.deleteDialogRef.close();
          this.deleteNoteIndex = null;
        },
        (error) => {
          this.deleteProcessing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to update goal notes.'
          );
        }
      );
    } else {
      this.deleteDialogRef.close();
      this.deleteNoteIndex = null;
    }
  }
}
