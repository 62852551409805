<div
  class="dialogTitle"
  *ngIf="displayTitle"
  style="padding-left: 10px; padding-right: 10px"
>
  <p matDialogTitle>
    <span *ngIf="!anchorTag">{{ assessmentType }} Charts</span>
  </p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<app-internet-addiction-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Internet Addiction Assessment'"
>
</app-internet-addiction-charts>
<app-alcohol-addiction-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Alcohol Addiction Assessment'"
>
</app-alcohol-addiction-charts>
<app-cssrs-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Columbia-Suicide Severity Rating Scale (CSSRS)'"
>
</app-cssrs-charts>
<app-ciwa-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="
    assessmentType ===
    'Clinical Institute Withdrawal Assessment of Alcohol Scale, Revised (CIWA-AR)'
  "
>
</app-ciwa-charts>
<app-cows-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Clinical Opiate Withdrawal Scale (COWS)'"
>
</app-cows-charts>
<app-gambling-addiction-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Gambling Addiction Assessment'"
>
</app-gambling-addiction-charts>
<app-gaming-addiction-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Gaming Addiction Assessment'"
>
</app-gaming-addiction-charts>
<app-sexual-addiction-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Sexual Addiction Assessment'"
>
</app-sexual-addiction-charts>
<app-shopping-addiction-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Shopping Addiction Assessment'"
>
</app-shopping-addiction-charts>
<app-dsm5-level2-substance-use-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'DSM-5-TR Level 2 - Substance Use | Age 11-17'"
>
</app-dsm5-level2-substance-use-charts>
<app-taps-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'TAPS-I & TAPS-II'"
>
</app-taps-charts>

<!-- ADHD Assessments -->
<app-adhd-diagnostic-rating-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Vanderbilt ADHD Diagnostic Rating Scale (VADRS)'"
></app-adhd-diagnostic-rating-charts>
<app-self-report-scale-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Adult ADHD Self-Report Scale'"
></app-self-report-scale-charts>
<app-attention-awareness-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'The Mindful Attention Awareness Scale (MAAS)'"
>
</app-attention-awareness-charts>

<!-- Aggression Assessments -->
<app-aggression-questionnaire-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Buss Perry Aggression Questionnaire'"
></app-aggression-questionnaire-charts>

<!-- Anxiety Assessments -->
<app-childrens-anxiety-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Spence Children\'s Anxiety Scale'"
></app-childrens-anxiety-charts>
<app-generalized-anxiety-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Generalized Anxiety Disorder 7 Item Scale'"
></app-generalized-anxiety-charts>
<app-hamilton-anxiety-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Hamilton Anxiety Rating Scale'"
></app-hamilton-anxiety-charts>
<app-panic-agoraphobia-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Panic and Agoraphobia Scale'"
></app-panic-agoraphobia-charts>
<app-penn-state-worry-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Penn State Worry Questionnaire'"
></app-penn-state-worry-charts>
<app-short-health-anxiety-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Short Health Anxiety Inventory'"
></app-short-health-anxiety-charts>
<app-social-phobia-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Social Phobia Inventory'"
></app-social-phobia-charts>
<app-taylor-manifest-anxiety-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Taylor Manifest Anxiety Scale'"
></app-taylor-manifest-anxiety-charts>
<app-zung-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Zung Self-Rating Scale'"
>
</app-zung-charts>

<!-- Autism Assessments -->
<app-autism-spectrum-quotient-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Autism Spectrum Quotient'"
></app-autism-spectrum-quotient-charts>
<app-autism-spectrum-screening-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Autism Spectrum Screening Questionnaire'"
></app-autism-spectrum-screening-charts>
<app-childhood-autism-spectrum-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Childhood Autism Spectrum Test'"
></app-childhood-autism-spectrum-charts>
<app-infant-toddler-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Infant-Toddler Checklist'"
></app-infant-toddler-charts>
<app-quantitative-checklist-for-autism-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Quantitative Checklist for Autism in Toddlers'"
></app-quantitative-checklist-for-autism-charts>
<app-ritvo-autism-asperger-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Ritvo Autism & Asperger Diagnostic Scale'"
></app-ritvo-autism-asperger-charts>

<!-- Bipolar Assessments -->
<app-altman-self-rating-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Altman Self-Rating Mania Scale'"
></app-altman-self-rating-charts>
<app-young-mania-rating-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Young Mania Rating Scale'"
></app-young-mania-rating-charts>

<!-- Depression Assessments -->
<app-patient-health-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Patient Health Questionnaire'"
>
</app-patient-health-charts>
<app-geriatric-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Geriatric Depression Scale (GDS)'"
>
</app-geriatric-charts>
<app-adolescent-depression-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Kutcher Adolescent Depression Scale'"
></app-adolescent-depression-charts>
<app-major-depression-inventory-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Major Depression Inventory'"
></app-major-depression-inventory-charts>
<app-postnatal-depression-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Edinburgh Postnatal Depression Scale (EPDS)'"
></app-postnatal-depression-charts>
<app-zung-depression-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Zung Self-Rating Depression Scale'"
></app-zung-depression-charts>

<!-- Eating-Disorder Assessments -->
<app-binge-eating-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Binge Eating Scale'"
></app-binge-eating-charts>
<app-eating-attitudes-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Eating Attitudes Test'"
></app-eating-attitudes-charts>

<!-- General Assessments -->
<app-differention-of-self-inventory-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Differentiation of Self Inventory - Short Form'"
>
</app-differention-of-self-inventory-charts>
<app-dsm5-l1-adult-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'DSM 5 Level 1 Adult Assessment'"
>
</app-dsm5-l1-adult-charts>
<app-dsm5-l1-sixtoseventeen-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'DSM 5 Level 1 (6-17) Assessment'"
>
</app-dsm5-l1-sixtoseventeen-charts>
<app-dsm5-l1-eleventoseventeen-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'DSM 5 Level 1 (11-17) Assessment'"
>
</app-dsm5-l1-eleventoseventeen-charts>
<app-core10-assessment-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'CORE 10 Assessment'"
>
</app-core10-assessment-charts>
<app-yp-core10-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'YP-CORE 10 Assessment'"
>
</app-yp-core10-charts>
<app-cdrisc-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Connor-Davidson Resilience Scale(CD-RISC 10)'"
>
</app-cdrisc-charts>

<!-- OCD Assessments -->
<app-obsessive-compulsive-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Obsessive Compulsive Inventory - Revised'"
></app-obsessive-compulsive-charts>

<!-- Personality Assessments -->
<app-big-five-personality-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Big 5 Personality Test'"
></app-big-five-personality-charts>
<app-empathy-quotient-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Empathy Quotient'"
></app-empathy-quotient-charts>
<app-hare-psychopathy-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Hare Psychopathy Checklist'"
></app-hare-psychopathy-charts>
<app-revised-mystical-experience-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Revised Mystical Experience Questionnaire'"
></app-revised-mystical-experience-charts>
<app-personality-type-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Personality Type Indicator'"
></app-personality-type-charts>
<app-toronto-empathy-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Toronto Empathy Questionnaire'"
>
</app-toronto-empathy-charts>

<!-- Relationship/Family Assessments -->
<app-aas-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Adult Attachment Scale (AAS)'"
>
</app-aas-charts>
<app-cprs-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Child-Parent Relationship Scale (CPRS)'"
>
</app-cprs-charts>
<app-aces-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Aversive Childhood Experiences Scale (ACES)'"
>
</app-aces-charts>
<app-apgar-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Family APGAR'"
>
</app-apgar-charts>
<app-csi-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'The Couples Satisfaction Index (CSI)'"
>
</app-csi-charts>

<!-- Stress Assessments -->
<app-inventory-of-complicated-grief-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Inventory of Complicated Grief'"
></app-inventory-of-complicated-grief-charts>
<app-ptsd-screening-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Primary Care PTSD Screen'"
></app-ptsd-screening-charts>
<app-trauma-related-shame-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Trauma-Related Shame Assessment'"
></app-trauma-related-shame-charts>

<!-- Therapeutic Alliance Assessments -->
<app-srs-charts
  [displayTitle]="false"
  [assessmentRequestId]="assessmentRequestId"
  [anchorTag]="anchorTag"
  [assessmentId]="assessmentId"
  [patientId]="patientId"
  [sessionId]="sessionId"
  *ngIf="assessmentType === 'Session Rating Scale (SRS)'"
>
</app-srs-charts>
