<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Member Diagnosis</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      type="button"
      (click)="closeTab()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoadingResults || processing"
  ></mat-progress-bar>

  <div class="row">
    <div
      class="col-md-12"
      style="text-align: right; margin-bottom: 12px"
      *ngIf="
        (!currentMemberDiagnosis || currentMemberDiagnosis.length < 12) &&
        hasDiagnosisAddAccess &&
        sessionDetails.status === 'Active'
      "
    >
      <button
        mat-raised-button
        type="button"
        (click)="addDiagnosis()"
        color="warn"
      >
        + Diagnosis Code
      </button>
    </div>

    <!-- For No Diagnosis Selected -->
    <div
      class="col-md-12"
      *ngIf="
        (!currentMemberDiagnosis || currentMemberDiagnosis.length == 0) &&
        !isLoadingResults
      "
    >
      <mat-error>No Diagnosis has been added</mat-error>
    </div>

    <div
      class="col-md-12"
      *ngIf="
        currentMemberDiagnosis &&
        currentMemberDiagnosis.length > 0 &&
        !processing
      "
    >
      <div class="table-responsive mat-elevation-z8">
        <table mat-table [dataSource]="currentMemberDiagnosis">
          <ng-container matColumnDef="diagnosisIndex">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row" style="color: #555555">
              {{ row?.diagnosisIndex }}
            </td>
          </ng-container>
          <ng-container matColumnDef="moveArrows">
            <th mat-header-cell *matHeaderCellDef></th>
            <td
              mat-cell
              *matCellDef="let row; let i = index"
              style="column-gap: 5px"
            >
              <button
                mat-mini-fab
                (click)="moveDiagnosis('UP', i)"
                style="margin: 5px; background: transparent; color: #555555"
              >
                <mat-icon> arrow_upward </mat-icon>
              </button>

              <button
                mat-mini-fab
                (click)="moveDiagnosis('DOWN', i)"
                style="margin: 5px; background: transparent; color: #555555"
              >
                <mat-icon> arrow_downward </mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="diagnosisCode">
            <th mat-header-cell *matHeaderCellDef>ICD Code</th>
            <td mat-cell *matCellDef="let row" style="max-width: 350px">
              {{ row.icdCode }}
            </td>
          </ng-container>

          <ng-container matColumnDef="diagnosisDescription">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let row">
              {{ row.shortDesc }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row; let i = index">
              <button
                mat-icon-button
                color="warn"
                (click)="removeDiagnosis(i)"
                type="button"
              >
                <mat-icon> cancel </mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="diagnosisColumn"></tr>
          <tr mat-row *matRowDef="let row; columns: diagnosisColumn"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
