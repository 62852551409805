import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { DataTablesModel, Patient } from 'src/app/_models';
import { CoreService, ToastMessageService } from 'src/app/_services';
import { AssessmentGoalHttpService } from 'src/app/_services/assessments/assessmentgoal.service';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { AddEditMemberGoalComponent } from './add-edit-member-goal/add-edit-member-goal.component';

@Component({
  selector: 'app-member-goals',
  templateUrl: './member-goals.component.html',
  styleUrls: ['./member-goals.component.css'],
})
export class MemberGoalsComponent implements OnInit, OnChanges {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() patient: Patient;
  @Input() isPhysician: boolean;

  @Input() hasGoalsViewAccess: boolean;
  @Input() hasGoalsAddAccess: boolean;
  @Input() hasGoalsEditAccess: boolean;
  @Input() hasGoalsDeleteAccess: boolean;

  @Output() output = new EventEmitter<any>();

  loggedInUserId: string = '';
  data: DataTablesModel = {} as DataTablesModel;
  isLoadingResults: boolean = true;

  order: string = 'DESC';
  goalStatus: string = 'Active';
  searchGoalDescription: string = '';

  constructor(
    public dialog: MatDialog,
    private coreService: CoreService,
    public assessmentGoalHttpService: AssessmentGoalHttpService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit(): void {
    if (this.isPhysician) {
      this.loggedInUserId = this.coreService.getLoggedInCareProviderId();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.patient) {
      this.data.page = 0;
      this.data.per_page = 12;

      this.loadMemberGoals();
    }
  }

  loadMemberGoals() {
    this.isLoadingResults = true;

    this.assessmentGoalHttpService
      .getSessionGoals(
        null,
        null,
        this.patient.id,
        this.data.per_page,
        this.data.page,
        this.order,
        this.goalStatus,
        this.searchGoalDescription
      )
      .subscribe(
        (response) => {
          if (response) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }

          this.isLoadingResults = false;
        },
        (error) => {
          this.isLoadingResults = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get goals for this session'
          );
        }
      );
  }

  filterOptionChanged(filterOption: string) {
    this.data.page = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.goalStatus = filterOption;
    this.loadMemberGoals();
  }

  sortByChanged(order: string) {
    this.data.page = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.order = order;
    this.loadMemberGoals();
  }

  searchByDescription() {
    this.data.page = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.loadMemberGoals();
  }

  getNextPage(event) {
    this.data.page = event.pageIndex;
    this.loadMemberGoals();
  }

  addGoal() {
    let dialogRef = this.dialog.open(AddEditMemberGoalComponent, {
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
      data: {
        action: 'ADD',
        loggedInUserId: this.loggedInUserId,
        patientId: this.patient.id,
        patientName: this.patient.firstName + ' ' + this.patient.lastName,
        organizationId: this.patient.organizationId,
        patient: this.patient,
      },
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadMemberGoals();
      }
    });
  }

  editGoal(goal) {
    let dialogRef = this.dialog.open(AddEditMemberGoalComponent, {
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
      data: {
        action: 'EDIT',
        goal: goal,
        patient: this.patient,
      },
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadMemberGoals();
      }
    });
  }

  deleteGoal(goalToBeDeleted) {
    let deleteDialogRef = this.dialog.open(
      GeneralDeleteConfirmDialogComponent,
      {
        disableClose: true,
        autoFocus: false,
        data: {
          message: 'Are you sure you want to remove this goal?',
        },
      }
    );

    deleteDialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.isLoadingResults = true;
        this.assessmentGoalHttpService
          .deletePatientAssessmentGoal(goalToBeDeleted)
          .subscribe(
            (response) => {
              this.toastMessageService.displaySuccessMessage(
                'Goal has been deleted.'
              );
              this.isLoadingResults = false;
              if (this.data.items.length == 1) {
                this.data.page = this.data.page - 1;
                if (this.data.page < 0) {
                  this.data.page = 0;
                }
              }
              this.loadMemberGoals();
            },
            (error) => {
              this.isLoadingResults = false;
              this.toastMessageService.displayErrorMessage(
                'Error: Failed to delete goal'
              );
            }
          );
      }
    });
  }
}
