import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { OwlDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { MbscModule } from '@mobiscroll/angular';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { AngularMaterialModule } from 'src/app/material-imports';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { AccountModule } from '../account/account.module';
import { AssessmentAlertsComponent } from './assessment-alerts/assessment-alerts.component';
import { AddAlertComponent } from './assessment-alerts/dialogs/add-alert/add-alert.component';
import { AssessmentGoalsComponent } from './assessment-goals/assessment-goals.component';
import { AddAssessmentGoalDialogComponent } from './assessment-goals/dialogs/add/add.component';
import { CarePlanComponent } from './care-plan/care-plan.component';
import { MemberCompletedConsentDocumentsComponent } from './member-completed-consent-documents/member-completed-consent-documents.component';
import { AddVacationPeriodComponent } from './my-vacation/dialogs/add-vacation-period/add-vacation-period.component';
import { MyVacationComponent } from './my-vacation/my-vacation.component';
import { NotificationsSettingsComponent } from './notifications-settings/notifications-settings.component';
import { PlayBookComponent } from './play-books/play-books.component';
import { SettingsDashboardComponent } from './settings-dashboard/settings-dashboard.component';
import { SlotDurationComponent } from './slot-duration/slot-duration.component';
import { WorkingHoursComponent } from './working-hours/working-hours.component';

const routes: Routes = [
  {
    path: 'playbooks',
    component: PlayBookComponent,
    canActivate: [AuthGuard],
    data: { roles: ['physician'] },
  },
  {
    path: 'careplan/:id',
    component: CarePlanComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['physician', 'orgadmin', 'superorgadmin'],
    },
  },
  {
    path: 'assessment-goals/:id',
    component: AssessmentGoalsComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['physician', 'orgadmin', 'superorgadmin'],
    },
  },
  {
    path: 'working-hours',
    component: WorkingHoursComponent,
    canActivate: [AuthGuard],
    data: { roles: ['physician'] },
  },
  {
    path: 'my-vacation',
    component: MyVacationComponent,
    canActivate: [AuthGuard],
    data: { roles: ['physician'] },
  },
  {
    path: 'slot-duration',
    component: SlotDurationComponent,
  },
  {
    path: 'assessment-alerts/:id',
    component: AssessmentAlertsComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['physician', 'orgadmin', 'superorgadmin'],
    },
  },
  {
    path: 'manage-settings',
    component: SettingsDashboardComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        'physician',
        'orgadmin',
        'superorgadmin',
        'careprovider',
        'patient',
      ],
      excludeRoles: ['inhospitaldevice'],
    },
  },
];

@NgModule({
  declarations: [
    AssessmentGoalsComponent,
    WorkingHoursComponent,
    MyVacationComponent,
    SlotDurationComponent,
    AddVacationPeriodComponent,
    CarePlanComponent,
    PlayBookComponent,
    AddAssessmentGoalDialogComponent,
    AssessmentAlertsComponent,
    AddAlertComponent,
    SettingsDashboardComponent,
    NotificationsSettingsComponent,
    MemberCompletedConsentDocumentsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    PipesModule,
    AccountModule,
    MbscModule,
    OwlDateTimeModule,
    RouterModule.forChild(routes),
  ],
})
export class ConfigurationsModule {}
