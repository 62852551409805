<mat-card-title>Substance, Mental Health, and Trauma History</mat-card-title>
<mat-divider></mat-divider>

<div style="margin-top: 20px; padding: 10px">
  <form
    [formGroup]="bpsasubstanceetcForm"
    novalidate
    (ngSubmit)="onBpsaSubstanceEtcFormSubmit()"
  >
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >Do you drink alcohol? <span style="color: red">*</span></label
          >&nbsp;
          <br />
          <mat-button-toggle-group
            formControlName="drinkAlcohol"
            (change)="checkAlcoholAnswer()"
          >
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="Member Denies"
              >Member Denies</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
      <div
        class="row"
        *ngIf="bpsasubstanceetcForm.value.drinkAlcohol === 'Yes'"
      >
        <div class="col-md-12">
          <label class="labelText"
            >How often? <span style="color: red">*</span></label
          >&nbsp;
          <br />
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="alcoholHowOften"
              name="alcoholHowOften"
            />
            <mat-error
              *ngIf="bpsasubstanceetcForm.controls['alcoholHowOften'].invalid"
            >
              How often do you drink alcohol is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div
        class="row"
        *ngIf="bpsasubstanceetcForm.value.drinkAlcohol === 'Yes'"
      >
        <div class="col-md-12">
          <label class="labelText"
            >Daytime, nighttime, or both
            <span style="color: red">*</span></label
          >&nbsp;
          <br />
          <mat-button-toggle-group formControlName="alcoholConsumptionTime">
            <mat-button-toggle value="Daytime">Daytime</mat-button-toggle>
            <mat-button-toggle value="Nighttime">Nighttime</mat-button-toggle>
            <mat-button-toggle value="Both">Both</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <div
        class="row"
        *ngIf="bpsasubstanceetcForm.value.drinkAlcohol === 'Yes'"
      >
        <div class="col-md-12">
          <label class="labelText"
            >Alone or with others or both
            <span style="color: red">*</span></label
          >&nbsp;
          <br />
          <mat-button-toggle-group formControlName="alcoholConsumptionWith">
            <mat-button-toggle value="Alone">Alone</mat-button-toggle>
            <mat-button-toggle value="With others"
              >With others</mat-button-toggle
            >
            <mat-button-toggle value="Both">Both</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <div
        class="row"
        *ngIf="bpsasubstanceetcForm.value.drinkAlcohol === 'Yes'"
      >
        <div class="col-md-12">
          <label class="labelText"
            >Do you feel the need to hide it or lie about it to others?
            <span style="color: red">*</span></label
          >&nbsp;
          <br />
          <mat-button-toggle-group formControlName="hideAlcoholConsumption">
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="Member Denies"
              >Member Denies</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
      <div
        class="row"
        *ngIf="bpsasubstanceetcForm.value.drinkAlcohol === 'Yes'"
      >
        <div class="col-md-12">
          <label class="labelText"
            >Have you experienced blackouts?
            <span style="color: red">*</span></label
          >&nbsp;
          <br />
          <mat-button-toggle-group formControlName="alcoholBlackouts">
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="No">No</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >Do you consume illegal or street drugs?
            <span style="color: red">*</span></label
          >&nbsp;
          <br />
          <mat-button-toggle-group
            formControlName="doesIllegalDrugs"
            (change)="checkDrugAnswer()"
          >
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="Member Denies"
              >Member Denies</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
      <div
        class="row"
        *ngIf="bpsasubstanceetcForm.value.doesIllegalDrugs === 'Yes'"
      >
        <div class="col-md-12">
          <label class="labelText"
            >How often? <span style="color: red">*</span></label
          >&nbsp;
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="illegalDrugsHowOften"
              name="illegalDrugsHowOften"
            />
            <mat-error
              *ngIf="
                bpsasubstanceetcForm.controls['illegalDrugsHowOften'].invalid
              "
            >
              How often do you use illegal drugs is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div
        class="row"
        *ngIf="bpsasubstanceetcForm.value.doesIllegalDrugs === 'Yes'"
      >
        <div class="col-md-12">
          <label class="labelText"
            >Daytime, nighttime, or both
            <span style="color: red">*</span></label
          >&nbsp;
          <br />
          <mat-button-toggle-group
            formControlName="illegalDrugsConsumptionTime"
          >
            <mat-button-toggle value="Daytime">Daytime</mat-button-toggle>
            <mat-button-toggle value="Nighttime">Nighttime</mat-button-toggle>
            <mat-button-toggle value="Both">Both</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <div
        class="row"
        *ngIf="bpsasubstanceetcForm.value.doesIllegalDrugs === 'Yes'"
      >
        <div class="col-md-12">
          <label class="labelText"
            >Alone or with others or both
            <span style="color: red">*</span></label
          >&nbsp;
          <br />
          <mat-button-toggle-group
            formControlName="illegalDrugsConsumptionWith"
          >
            <mat-button-toggle value="Alone">Alone</mat-button-toggle>
            <mat-button-toggle value="With others"
              >With others</mat-button-toggle
            >
            <mat-button-toggle value="Both">Both</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <div
        class="row"
        *ngIf="bpsasubstanceetcForm.value.doesIllegalDrugs === 'Yes'"
      >
        <div class="col-md-12">
          <label class="labelText"
            >Do you feel the need to hide it or lie about it to others?
            <span style="color: red">*</span></label
          >&nbsp;
          <br />
          <mat-button-toggle-group formControlName="hideIllegalDrugs">
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="Member Denies"
              >Member Denies</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >History of gambling? <span style="color: red">*</span></label
          >&nbsp;
          <br />
          <mat-button-toggle-group formControlName="gamblingHistory">
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="Member Denies"
              >Member Denies</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >History of eating disorders?
            <span style="color: red">*</span></label
          >&nbsp;
          <br />
          <mat-button-toggle-group formControlName="eatingDisorders">
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="Member Denies"
              >Member Denies</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >Have you ever gone to an inpatient treatment facility?
            <span style="color: red">*</span></label
          >&nbsp;
          <br />
          <mat-button-toggle-group
            formControlName="beenToInpatientTreatmentFacility"
            (change)="checkImpatientTreatment()"
          >
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="Member Denies"
              >Member Denies</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
      <div
        class="row"
        *ngIf="
          bpsasubstanceetcForm.value.beenToInpatientTreatmentFacility === 'Yes'
        "
      >
        <div class="col-md-12">
          <label class="labelText"
            >When, where, reason, length of stay, successful or unsuccessful
            <span style="color: red">*</span></label
          >&nbsp;
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="inpatientTreatmentVisitDetails"
              name="inpatientTreatmentVisitDetails"
            />
            <mat-error
              *ngIf="
                bpsasubstanceetcForm.controls['inpatientTreatmentVisitDetails']
                  .invalid
              "
            >
              Please provide details about your visit to inpatient treatment
              facility
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >Have you ever gone to a psychiatric hospital?
            <span style="color: red">*</span></label
          >&nbsp; <br />
          <mat-button-toggle-group
            formControlName="beenToPsychiatricHospital"
            (change)="checkPsychiatricHospital()"
          >
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="Member Denies"
              >Member Denies</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
      <div
        class="row"
        *ngIf="bpsasubstanceetcForm.value.beenToPsychiatricHospital === 'Yes'"
      >
        <div class="col-md-12">
          <label class="labelText"
            >When, where, reason, length of stay, successful or unsuccessful
            <span style="color: red">*</span></label
          >&nbsp;
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="psychiatricHospitalVisitDetails"
              name="psychiatricHospitalVisitDetails"
            />
            <mat-error
              *ngIf="
                bpsasubstanceetcForm.controls['psychiatricHospitalVisitDetails']
                  .invalid
              "
            >
              Please provide details about your visit to psychiatric hospital
              facility
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >Have you ever experienced an overdose?
            <span style="color: red">*</span></label
          >&nbsp;
          <br />
          <mat-button-toggle-group
            formControlName="experiencedOverdose"
            (change)="checkExpOverdose()"
          >
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="Member Denies"
              >Member Denies</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
      <div
        class="row"
        *ngIf="bpsasubstanceetcForm.value.experiencedOverdose === 'Yes'"
      >
        <div class="col-md-12">
          <label class="labelText"
            >When, did you get medical care, accidental overdose or intentional
            <span style="color: red">*</span></label
          >&nbsp;
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="overdoseDetails"
              name="overdoseDetails"
            />
            <mat-error
              *ngIf="bpsasubstanceetcForm.controls['overdoseDetails'].invalid"
            >
              Please provide your recent medical care details
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >What (If any) significant life changes or stressful events have you
            experienced within your lifetime?
            <span style="color: red">*</span></label
          >&nbsp;
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="significantLifeChanges"
              name="significantLifeChanges"
              placeholder="Type N/A if not applicable"
              required
            />
            <mat-error
              *ngIf="
                bpsasubstanceetcForm.controls['significantLifeChanges'].invalid
              "
            >
              Please provide if there is any significant life changes you've
              experienced
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >What (If any) significant life changes or stressful events have you
            experienced within the last 3 months?
            <span style="color: red">*</span></label
          >&nbsp;
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="significantLifeChangesInPastThreeMonths"
              name="significantLifeChangesInPastThreeMonths"
              placeholder="Type N/A if not applicable"
              required
            />
            <mat-error
              *ngIf="
                bpsasubstanceetcForm.controls[
                  'significantLifeChangesInPastThreeMonths'
                ].invalid
              "
            >
              Please provide if there is any significant life changes you've
              experienced in past 3 months
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >Have you ever been the victim of bullying/teasing?
            <span style="color: red">*</span></label
          >&nbsp;
          <br />
          <mat-button-toggle-group
            formControlName="bullyingVictim"
            (change)="checkBullying()"
          >
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="Member Denies"
              >Member Denies</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
      <div
        class="row"
        *ngIf="bpsasubstanceetcForm.value.bullyingVictim === 'Yes'"
      >
        <div class="col-md-12">
          <label class="labelText"
            >Please explain <span style="color: red">*</span></label
          >&nbsp;
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="bullyingDetails"
              name="bullyingDetails"
            />
            <mat-error
              *ngIf="bpsasubstanceetcForm.controls['bullyingDetails'].invalid"
            >
              Please explain if you ever been the victim of bullying/teasing
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >Have you ever been the victim of physical abuse?
            <span style="color: red">*</span></label
          >&nbsp;
          <br />
          <mat-button-toggle-group
            formControlName="physicalAbuseVictim"
            (change)="checkPhysicalAbuse()"
          >
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="Member Denies"
              >Member Denies</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
      <div
        class="row"
        *ngIf="bpsasubstanceetcForm.value.physicalAbuseVictim === 'Yes'"
      >
        <div class="col-md-12">
          <label class="labelText"
            >Please explain <span style="color: red">*</span></label
          >&nbsp;
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="physicalAbuseDetails"
              name="physicalAbuseDetails"
            />
            <mat-error
              *ngIf="
                bpsasubstanceetcForm.controls['physicalAbuseDetails'].invalid
              "
            >
              Please explain if you ever been the victim of physical abuse
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >Have you ever felt that you have been a victim of emotional abuse?
            <span style="color: red">*</span></label
          >&nbsp;
          <br />
          <mat-button-toggle-group
            formControlName="emotionalAbuseVictim"
            (change)="checkEmotionalAbuse()"
          >
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="Member Denies"
              >Member Denies</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
      <div
        class="row"
        *ngIf="bpsasubstanceetcForm.value.emotionalAbuseVictim === 'Yes'"
      >
        <div class="col-md-12">
          <label class="labelText"
            >Please explain <span style="color: red">*</span></label
          >&nbsp;
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="emotionalAbuseDetails"
              name="emotionalAbuseDetails"
            />
            <mat-error
              *ngIf="
                bpsasubstanceetcForm.controls['emotionalAbuseDetails'].invalid
              "
            >
              Please explain if you ever been the victim of emotional abuse
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >Have you ever been the victim of sexual abuse?
            <span style="color: red">*</span></label
          >&nbsp;
          <br />
          <mat-button-toggle-group
            formControlName="sexualAbuseVictim"
            (change)="checkSexualAbuse()"
          >
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="Member Denies"
              >Member Denies</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
      <div
        class="row"
        *ngIf="bpsasubstanceetcForm.value.sexualAbuseVictim === 'Yes'"
      >
        <div class="col-md-12">
          <label class="labelText"
            >Please explain <span style="color: red">*</span></label
          >&nbsp;
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="sexualAbuseDetails"
              name="sexualAbuseDetails"
            />
            <mat-error
              *ngIf="bpsasubstanceetcForm.controls['sexualAbuseVictim'].invalid"
            >
              Please explain if you ever been the victim of sexual abuse
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >Have you ever felt that you were being neglected or exploited?
            <span style="color: red">*</span></label
          >&nbsp;
          <br />
          <mat-button-toggle-group
            formControlName="neglectedOrExploited"
            (change)="checkNeglectedExp()"
          >
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="Member Denies"
              >Member Denies</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
      <div
        class="row"
        *ngIf="bpsasubstanceetcForm.value.neglectedOrExploited === 'Yes'"
      >
        <div class="col-md-12">
          <label class="labelText"
            >Please explain <span style="color: red">*</span></label
          >&nbsp;
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="neglectedOrExploitedDetails"
              name="neglectedOrExploitedDetails"
            />
            <mat-error
              *ngIf="
                bpsasubstanceetcForm.controls['neglectedOrExploitedDetails']
                  .invalid
              "
            >
              Please explain if you ever been neglected or exploited
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText">Are any of these of concern to you? </label
          >&nbsp;

          <mat-form-field appearance="outline">
            <mat-select formControlName="concerns" multiple="true">
              <mat-option value="Partner conflicts"
                >Partner conflicts</mat-option
              >
              <mat-option value="Children conflicts"
                >Children conflicts</mat-option
              >
              <mat-option value="Employer conflicts"
                >Employer conflicts</mat-option
              >
              <mat-option value="Employment issues"
                >Employment issues</mat-option
              >
              <mat-option value="Financial distress"
                >Financial distress</mat-option
              >
              <mat-option value="Unable to manage mood"
                >Unable to manage mood</mat-option
              >
              <mat-option value="Isolation">Isolation</mat-option>
              <mat-option value="Grief">Grief</mat-option>
              <mat-option value="Trauma">Trauma</mat-option>
              <mat-option value="Negative thoughts"
                >Negative thoughts</mat-option
              >
              <mat-option value="Lack of healthy boundaries lack of self-worth"
                >Lack of healthy boundaries lack of self-worth</mat-option
              >
              <mat-option value="Avoid people">Avoid people</mat-option>
              <mat-option value="Avoid activities">Avoid activities</mat-option>
              <mat-option value="Social events">Social events</mat-option>
              <mat-option value="Large crowds">Large crowds</mat-option>
              <mat-option value="Loss of interest">Loss of interest</mat-option>
              <mat-option value="Feeling disconnected from people"
                >Feeling disconnected from people</mat-option
              >
              <mat-option value="Member denies">Member denies</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
  </form>
</div>
