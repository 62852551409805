import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { debounceTime } from 'rxjs';
import { ITherapySession } from 'src/app/_models';
import { IBpsaFamilyHistory } from 'src/app/_models/session/clinical/bpsa-clinical-family-history.model';
import { ToastMessageService } from 'src/app/_services';
import { BpsaFamilyHistoryApiService } from 'src/app/_services/session/clinical/bpsa-clinical-family-history.service';

@Component({
  selector: 'app-member-family-history',
  templateUrl: './member-family-history.component.html',
  styleUrls: ['./member-family-history.component.css'],
})
export class MemberFamilyHistoryComponent implements OnInit {
  @Input() therapySession: ITherapySession;
  @Input() loggedInUserId: string;
  @Input() hasBPSAClinicalViewAccess: boolean;
  @Input() hasBPSAClinicalAddAccess: boolean;
  @Input() hasBPSAClinicalEditAccess: boolean;

  @Output() updatedTherapySession = new EventEmitter<any>();
  bpsafamilyhistory: IBpsaFamilyHistory;

  constructor(
    public bpsafamilyhistoryApiService: BpsaFamilyHistoryApiService,
    private formBuilder: FormBuilder,
    private toastMessageService: ToastMessageService,
    private route: ActivatedRoute
  ) {}

  public action: string;
  public bpsafamilyhistoryForm: FormGroup;
  key: string;
  processing: boolean = false;

  public ngOnInit() {
    this.bpsafamilyhistory = this.therapySession.bpsaFamilyHistory;
    this.key = this.route.snapshot.paramMap.get('id');
    this.buildForm();

    this.loadBpsaFamilyHistory();
  }

  loadBpsaFamilyHistory() {
    if (this.bpsafamilyhistory) {
      this.bpsafamilyhistoryForm.controls['raisedBy'].setValue(
        this.bpsafamilyhistory.raisedBy
      );
      this.bpsafamilyhistoryForm.controls['bornAndRaisedAt'].setValue(
        this.bpsafamilyhistory.bornAndRaisedAt
      );
      this.bpsafamilyhistoryForm.controls['siblings'].setValue(
        this.bpsafamilyhistory.siblings
      );
      this.bpsafamilyhistoryForm.controls['childhoodEnvironment'].setValue(
        this.bpsafamilyhistory.childhoodEnvironment
      );
      this.bpsafamilyhistoryForm.controls['howAsChild'].setValue(
        this.bpsafamilyhistory.howAsChild
      );
      this.bpsafamilyhistoryForm.controls['closestWithinFamily'].setValue(
        this.bpsafamilyhistory.closestWithinFamily
      );
      this.bpsafamilyhistoryForm.controls['mostDisconnectedWith'].setValue(
        this.bpsafamilyhistory.mostDisconnectedWith
      );
    }

    // Initiate Auto Save
    this.initiateAutoSave();
  }

  onBpsaFamilyHistoryFormSubmit() {
    this.bpsafamilyhistory = Object.assign({}, this.bpsafamilyhistory);
    this.bpsafamilyhistory = Object.assign(
      this.bpsafamilyhistory,
      this.bpsafamilyhistoryForm.value
    );

    this.processing = true;

    this.bpsafamilyhistoryApiService
      .updateBpsaFamilyHistory(this.bpsafamilyhistory)
      .subscribe({
        next: (response) => {
          this.processing = false;
          // this.toastMessageService.displaySuccessMessage(
          //   'Successfully updated Family History'
          // );
          this.updatedTherapySession.emit(response);
        },
        error: (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Something went wrong. Please try again.'
          );
        },
      });
  }

  private buildForm() {
    this.bpsafamilyhistoryForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      sessionId: new FormControl(
        this.therapySession.id,
        Validators.compose([Validators.required])
      ),
      patientId: new FormControl(
        this.therapySession.patientId,
        Validators.compose([Validators.required])
      ),
      raisedBy: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      bornAndRaisedAt: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      siblings: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      childhoodEnvironment: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      howAsChild: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      closestWithinFamily: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      mostDisconnectedWith: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
    });
  }

  initiateAutoSave() {
    this.bpsafamilyhistoryForm.valueChanges
      .pipe(debounceTime(1200))
      .subscribe(() => {
        this.onBpsaFamilyHistoryFormSubmit();
      });
  }
}
