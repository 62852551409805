import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import moment from 'moment';
import { DataTablesModel } from 'src/app/_models';
import { CHART_COLORS, greenChart, hexToRGBA, redChart } from 'src/app/shared/utilities/utilities';
import { ShoppingAddictionApiService } from '../../../../../../../_services/assessments/addiction/shoppingaddiction.service';
@Component({
  selector: 'app-shopping-addiction-charts',
  templateUrl: './shopping-addiction-charts.component.html',
  styleUrls: ['./shopping-addiction-charts.component.css'],
})
export class ShoppingAddictionChartsComponent implements OnInit {
  @Input() patientId: string;
  @Input() assessmentId: string;
  @Input() anchorTag: string;
  lineChartData: ChartConfiguration<'line'>['data'] = {
    datasets: [{
      tension: 0.4,
      data: [], borderColor: '#EEEEEE',
      pointBackgroundColor: '#333333',
      pointBorderColor: '#FFFFFF',
      pointHoverBackgroundColor: '#FFFFFF',
      pointHoverBorderColor: '#333333',
      pointStyle: 'circle',
      pointRadius: 5
    }]
  };
  symptomChartData: ChartConfiguration<'bar'>['data'] = {
    datasets: [
      {
        data: [],
        label: 'Salience',
        backgroundColor: CHART_COLORS.red,
        hoverBackgroundColor: CHART_COLORS.red,
      },
      {
        data: [],
        label: 'Mood Modification',
        backgroundColor: CHART_COLORS.orange,
        hoverBackgroundColor: CHART_COLORS.orange,
      },
      {
        data: [],
        label: 'Conflict',
        backgroundColor: CHART_COLORS.burgundy,
        hoverBackgroundColor: CHART_COLORS.burgundy,
      },
      {
        data: [],
        label: 'Tolerance',
        backgroundColor: CHART_COLORS.blue,
        hoverBackgroundColor: CHART_COLORS.blue,
      },
      {
        data: [],
        label: 'Relapse',
        backgroundColor: CHART_COLORS.green,
        hoverBackgroundColor: CHART_COLORS.green,
      },
      {
        data: [],
        label: 'Withdrawal',
        backgroundColor: CHART_COLORS.purple,
        hoverBackgroundColor: CHART_COLORS.purple,
      },
      {
        data: [],
        label: 'Problems',
        backgroundColor: CHART_COLORS.coralBlush,
        hoverBackgroundColor: CHART_COLORS.coralBlush,
      },
    ]
  };

  lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        title: {
          display: false
        }
      },
      annotation: {
        annotations: {
          greenBg:
          {
            type: 'box',
            yMin: 0,
            yMax: 3.5,
            backgroundColor: hexToRGBA(greenChart.backgroundColor, 1),
            borderColor: greenChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          },
          redBg: {
            type: 'box',
            yMin: 3.5,
            yMax: 7,
            backgroundColor: hexToRGBA(redChart.backgroundColor, 1),
            borderColor: redChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          }
        }
      },
    },
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        },
        min: 0,
        max: 7
      },
    }
  };
  symptomChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true
      }
    },
    scales: {
      x: {
        stacked: true
      },
      y: {
        stacked: true
      }
    },
  };
  assessmentDetailChartOptions: ChartOptions<'line'> = {
    responsive: true,
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        },
        min: 0,
        max: 4,
        ticks: { stepSize: 1 }
      },
    },
  };
  symptomChartPlugins = [];
  lineChartLegend = false;
  lineChartPlugins = [];
  lineChartType = 'line';
  symptomChartType = 'bar';
  symptomChartLegend = true;
  detailChartPlugins = [];
  data: DataTablesModel = {} as DataTablesModel;
  processing$ = false;
  isLoadingResults = false;
  error: string = '';
  scoreMap = new Map();
  minScoreMap = new Map();
  scoreDetails: any[] = [];
  constructor(
    public shoppingAddictionApiService: ShoppingAddictionApiService,
    private route: ActivatedRoute
  ) { }
  ngOnInit() {
    this.data.page = 0;
    this.data.per_page = 10;
    this.loadShoppingAddictionAssessments();
  }
  public loadShoppingAddictionAssessments() {
    this.isLoadingResults = true;
    this.error = '';
    this.shoppingAddictionApiService
      .getShoppingAddictionList(
        this.patientId,
        this.assessmentId,
        this.data.per_page,
        this.data.page
      )
      .subscribe(
        (response) => {
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
            var riskScores = [];
            var labels = [];
            var symptomLabels = [];
            this.scoreDetails = [];
            var salienceScores = [];
            var moodModificationScores = [];
            var conflictScores = [];
            var toleranceScores = [];
            var relapseScores = [];
            var withdrawalScores = [];
            var problemsScores = [];
            for (var item of response.items) {
              if (response.items.length === 1) {
                labels.push("");
                riskScores.push(item.riskScore);
                salienceScores.push(item.props['Salience'] > 0 ? 1 : 0);
                moodModificationScores.push(
                  item.props['Mood Modification'] > 0 ? 1 : 0
                );
                conflictScores.push(item.props['Conflict'] > 0 ? 1 : 0);
                toleranceScores.push(item.props['Tolerance'] > 0 ? 1 : 0);
                relapseScores.push(item.props['Relapse'] > 0 ? 1 : 0);
                withdrawalScores.push(item.props['Withdrawal'] > 0 ? 1 : 0);
                problemsScores.push(item.props['Problems'] > 0 ? 1 : 0);
              }
              riskScores.push(item.riskScore);
              var stillUtc = moment.utc(item.createdDate).toDate();
              var assessmentDate = moment(stillUtc).local().format('MM/DD/YY');
              labels.push(assessmentDate);
              symptomLabels.push(assessmentDate);
              if (item.props) {
                salienceScores.push(item.props['Salience'] > 0 ? 1 : 0);
                moodModificationScores.push(
                  item.props['Mood Modification'] > 0 ? 1 : 0
                );
                conflictScores.push(item.props['Conflict'] > 0 ? 1 : 0);
                toleranceScores.push(item.props['Tolerance'] > 0 ? 1 : 0);
                relapseScores.push(item.props['Relapse'] > 0 ? 1 : 0);
                withdrawalScores.push(item.props['Withdrawal'] > 0 ? 1 : 0);
                problemsScores.push(item.props['Problems'] > 0 ? 1 : 0);
              } else {
                salienceScores.push(0);
                moodModificationScores.push(0);
                conflictScores.push(0);
                toleranceScores.push(0);
                relapseScores.push(0);
                withdrawalScores.push(0);
                problemsScores.push(0);
              }
              for (var key in item) {
                if (item.hasOwnProperty(key)) {
                  if (item[key] && item[key].hasOwnProperty('score')) {
                    var scoreObj = item[key];
                    var scores = [];
                    if (this.scoreMap.get(key)) {
                      scores = this.scoreMap.get(key).scores;
                    }

                    if (response.items.length === 1) {
                      scores.push(scoreObj["score"]);
                    }
                    scores.push(scoreObj['score']);

                    var minScores = [];
                    if (this.minScoreMap.get(key)) {
                      minScores = this.minScoreMap.get(key).minScores;
                    }
                    minScores.push(scoreObj['minScore']);
                    var returnObj = {
                      scores: scores,
                      minScores: minScores,
                      key: key,
                      title: scoreObj['title'],
                      answer: scoreObj['answer'],
                      chartData: [],
                      chartColor: this.getChartColor(scores, minScores),
                    };
                    this.scoreMap.set(key, returnObj);
                    this.minScoreMap.set(key, returnObj);
                  }
                }
              }
            }
            this.scoreMap.forEach((value, key) => {
              let dataset: any = {
                datasets: [
                  {
                    "data": value.scores,
                    "borderColor": value.chartColor.borderColor,
                    "backgroundColor": value.chartColor.backgroundColor,
                    "tension": 0.4,
                    "fill": true,
                    pointBackgroundColor: '#333333',
                    pointRadius: 5
                  }
                ],
                "labels": labels,
                "title": value.title,
                "answer": value.answer
              };
              this.scoreDetails.push(dataset);
            });
            this.lineChartData.datasets[0].data = riskScores;
            this.symptomChartData.datasets[0].data = salienceScores;
            this.symptomChartData.datasets[1].data = moodModificationScores;
            this.symptomChartData.datasets[2].data = conflictScores;
            this.symptomChartData.datasets[3].data = toleranceScores;
            this.symptomChartData.datasets[4].data = relapseScores;
            this.symptomChartData.datasets[5].data = withdrawalScores;
            this.symptomChartData.datasets[6].data = problemsScores;

            this.symptomChartData.labels = symptomLabels;
            this.lineChartData.labels = labels;
            this.isLoadingResults = false;
          }
          if (response == null) {
            this.data.items = [];
            this.data.total = 0;
          }
        },
        (error) => {
          this.error = error;
          this.isLoadingResults = false;
        }
      );
  }
  getChartColor(scoringArray: any[], minScoreArray: any[]) {
    var score = scoringArray[scoringArray.length - 1];
    var minScore = minScoreArray[minScoreArray.length - 1];
    if (minScore > 0) {
      if (score >= minScore || score >= 4) {
        return redChart;
      } else {
        return greenChart;
      }
    } else {
      if (score >= 3) {
        return redChart;
      } else {
        return greenChart;
      }
    }
  }
  getRiskScoreChartColor(scoringArray: any[], maxScore: number) {
    var lastScore = scoringArray[scoringArray.length - 1];
    if (lastScore >= 4) {
      return redChart;
    } else {
      return greenChart;
    }
  }
}
