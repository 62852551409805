import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { CareLog, Case, Countries } from 'src/app/_models';
import { CareLogHttpService, ToastMessageService } from 'src/app/_services';
import { ErrorHandler } from '../../../../../../shared/handlers/error.handler';
import {
  formatDate,
  formatDateTime,
  mmddyyyyhhmiToDate,
} from '../../../../../../shared/utilities/utilities';

@Component({
  selector: 'app-carelog-seizure-log',
  templateUrl: './carelog-seizure-log.component.html',
  styleUrls: ['./carelog-seizure-log.component.css'],
})
export class CarelogSeizureLogComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() action: string;
  @Input() patientId: string;
  @Input() case: Case;
  @Input() logDate: Date;
  @Input() shift: number;
  @Input() carelog: CareLog;
  @Input() goals: any[];
  @Input() goalDrivenCare: boolean;
  @Output() taskCompleted = new EventEmitter<any>();

  todaysDate = new Date();

  orgConfig: any;
  isIndianOrg: boolean = false;
  initial = new FormControl('', Validators.required);
  details = new FormControl('', Validators.compose([]));
  initialAnswer: string = '';
  isProcessing: boolean = false;

  constructor(
    public careLogHttpService: CareLogHttpService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    dateTimeAdapter: DateTimeAdapter<any>,
    private orgConfigSandbox: OrgConfigSandbox,
    private toastMessageService: ToastMessageService
  ) {
    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          this.orgConfig = orgConfig;
          if (this.orgConfig?.organizationCountry === Countries.India) {
            this.isIndianOrg = true;
            dateTimeAdapter.setLocale('en-GB');
          }
        });
      }
    });
  }

  public seizureinfoForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  errors: any = {};

  public ngOnInit() {
    this.buildForm();
    if (this.carelog && this.carelog.seizureLog) {
      this.initialAnswer = this.carelog.seizureLog.hadSeizure;
      this.seizureinfoForm.controls['hadSeizure'].setValue(
        this.carelog.seizureLog.hadSeizure
      );
      this.seizureinfoForm.controls['startTime'].setValue(
        this.carelog.seizureLog.startTime
      );
      this.seizureinfoForm.controls['endTime'].setValue(
        this.carelog.seizureLog.endTime
      );
      this.seizureinfoForm.controls['called911'].setValue(
        this.carelog.seizureLog.called911
      );
      this.seizureinfoForm.controls['customMessage'].setValue(
        this.carelog.customMessage
      );
      this.seizureinfoForm.controls['activityDateTime'].setValue(
        mmddyyyyhhmiToDate(this.carelog.activityDateTime)
      );
    } else {
      this.seizureinfoForm.controls['activityDateTime'].setValue(
        new Date(this.logDate)
      );
    }

    this.errorHandler.handleErrors(this.seizureinfoForm, this.errors);
  }

  onSeizureinfoSubmit() {
    if (this.seizureinfoForm.invalid) {
      this.seizureinfoForm.markAllAsTouched();
      return;
    }

    let seizureinfo: any = {};
    seizureinfo.hadSeizure = this.seizureinfoForm.controls['hadSeizure'].value;
    if (this.initialAnswer === 'Yes') {
      seizureinfo.startTime = this.seizureinfoForm.controls['startTime'].value;
      seizureinfo.endTime = this.seizureinfoForm.controls['endTime'].value;
      seizureinfo.called911 = this.seizureinfoForm.controls['called911'].value;
    }

    if (this.carelog) {
      this.isProcessing = true;
      this.carelog.seizureLog = seizureinfo;
      this.carelog.activityDateTime = formatDateTime(
        this.seizureinfoForm.controls['activityDateTime'].value
      );
      this.carelog.customMessage =
        this.seizureinfoForm.controls['customMessage'].value;
      this.carelog.vitalInfoAvailable = false;

      if (this.action === 'ADD') {
        this.addCareLog(this.carelog);
      } else {
        this.careLogHttpService
          .editCareLog(this.case.id, this.carelog)
          .subscribe(
            () => {
              this.isProcessing = false;
              let outputResponse = { note: this.carelog, response: 'success' };
              this.taskCompleted.emit(outputResponse);
              this.toastMessageService.displaySuccessMessage(
                'Seizure log has been updated'
              );
            },
            (error) => {
              this.isProcessing = false;
              this.toastMessageService.displayErrorMessage(
                'Failed to update seizure log'
              );
            }
          );
      }
    } else {
      this.carelog = Object.assign({}, this.carelog);
      this.carelog.vitalInfoAvailable = false;
      this.carelog.patientId = this.case.patientId;
      this.carelog.message = 'Seizure Log';

      this.carelog.seizureLog = seizureinfo;
      this.carelog.activityDateTime = formatDateTime(
        this.seizureinfoForm.controls['activityDateTime'].value
      );
      this.carelog.customMessage =
        this.seizureinfoForm.controls['customMessage'].value;
      this.carelog.vitalInfoAvailable = false;
      this.addCareLog(this.carelog);
    }
  }

  public addCareLog(carelog: CareLog) {
    this.isProcessing = true;
    carelog.caseId = this.case.id;
    carelog.logDate = formatDate(this.logDate);
    carelog.id = null;
    carelog.shift = this.shift;
    carelog.patientId = this.case.patientId;

    this.careLogHttpService.addCareLog(this.case.id, carelog).subscribe(() => {
      this.toastMessageService.displaySuccessMessage(
        'Seizure log has been updated'
      );
      this.isProcessing = false;
      this.taskCompleted.emit(true);
    });
  }

  private buildForm() {
    this.seizureinfoForm = this.formBuilder.group({
      hadSeizure: new FormControl(Validators.compose([Validators.required])),
      endTime: new FormControl(null),
      startTime: new FormControl(null),
      called911: new FormControl(null),
      activityDateTime: new FormControl(null, Validators.required),
      customMessage: new FormControl(''),
    });
  }

  selectInitialAnswer(initialAnswer: string, stepper: MatStepper) {
    this.initial.setValue(initialAnswer);
    this.initialAnswer = initialAnswer;
    this.seizureinfoForm.controls['hadSeizure'].setValue(initialAnswer);

    stepper.next();
  }

  public validation_messages = {
    startTime: [{ type: 'required', message: 'Start Time is required' }],
    endTime: [{ type: 'required', message: 'End Time is required' }],
    called911: [{ type: 'required', message: '911 Called is required' }],
  };

  timeArray: any = [
    { value: '2400', text: '12 AM' },
    { value: '100', text: '1 AM' },
    { value: '200', text: '2 AM' },
    { value: '300', text: '3 AM' },
    { value: '400', text: '4 AM' },
    { value: '500', text: '5 AM' },
    { value: '600', text: '6 AM' },
    { value: '700', text: '7 AM' },
    { value: '800', text: '8 AM' },
    { value: '900', text: '9 AM' },
    { value: '1000', text: '10 AM' },
    { value: '1100', text: '11 AM' },
    { value: '1200', text: '12 PM' },
    { value: '1300', text: '1 PM' },
    { value: '1400', text: '2 PM' },
    { value: '1500', text: '3 PM' },
    { value: '1600', text: '4 PM' },
    { value: '1700', text: '5 PM' },
    { value: '1800', text: '6 PM' },
    { value: '1900', text: '7 PM' },
    { value: '2000', text: '8 PM' },
    { value: '2100', text: '9 PM' },
    { value: '2200', text: '10 PM' },
    { value: '2300', text: '11 PM' },
  ];
}
