<!-- Claims Screen with tab bar -->
<div class="row" style="margin-top: 10px; margin-bottom: 20px">
  <div class="col-md-3">
    <!-- DIsplay Claims Alerts if Any -->
    <app-claims-alert
      [claimStats]="claimTabs"
      (selectNewTab)="selectNewClaimsTab($event)"
    ></app-claims-alert>

    <!-- Different Claims Tab -->
    <mat-card class="settingsCard mat-elevation-z8" appearance="raised">
      <label class="labelHeader">Claims</label>
      <div class="row">
        <div class="col-md-12" *ngFor="let tab of claimTabs">
          <div
            class="tabContainer"
            [class]="selectedClaimTab === tab.tabName ? 'activeTab' : ''"
            (click)="selectedClaimTab = tab.tabName; reloadHeader.next(true)"
          >
            <!-- Icon -->
            <mat-icon [style.color]="tab.color"> {{ tab.icon }}</mat-icon>
            <!-- label -->
            <label class="labelBold" style="cursor: pointer">
              {{ tab.tabName }}
            </label>
            <!-- Count -->
            <label class="labelBold" style="cursor: pointer">
              <span *ngIf="!claimsStatsProcessing">
                {{ tab.count }}
              </span>
              <i
                class="fa fa-spinner fa-spin"
                *ngIf="claimsStatsProcessing"
              ></i>
            </label>
          </div>
        </div>
      </div>
    </mat-card>
  </div>

  <!-- RIght side -->
  <div class="col-md-9">
    <!-- Selected Claims Tab -->
    <app-claims-list
      [selectedClaimTab]="selectedClaimTab"
      (output)="reloadClaimStats($event)"
    ></app-claims-list>
  </div>
</div>
