<div class="container-wrap">
  <div class="container mat-elevation-z8">
    <div class="row" style="position: relative">
      <!-- Description -->
      <div class="col-md-12" style="text-align: left; position: relative">
        <!-- Heading -->
        <p
          style="font-size: 16px; font-weight: 400; color: #2196f3; width: 80%"
        >
          {{ goal.description }}
        </p>
      </div>

      <label class="labelText" style="text-align: left">
        <ul>
          <ng-container
            *ngFor="let objective of goal.objectives; let i = index"
          >
            <li style="margin-bottom: 5px">
              <span style="font-weight: 500">{{
                objective.objectiveDescription
              }}</span>
              <br />
              <span style="opacity: 0.6" *ngIf="type === 'Available'"
                >(Target {{ objective.targetNumber }}
                {{ objective.targetType }})
              </span>
              <span *ngIf="type === 'Assigned'"
                >Target Date :
                <span style="opacity: 0.7">{{
                  objective?.targetDate | date : 'MM/dd/y'
                }}</span>
                <br />
                Status:
                <span
                  style="opacity: 0.9"
                  [style.color]="
                    objective?.status === 'Canceled'
                      ? 'red'
                      : objective?.status === 'Attained'
                      ? '#3ac47d'
                      : '#ff9f40'
                  "
                >
                  {{ objective?.status }}
                </span>
              </span>
            </li>
          </ng-container>
        </ul>
      </label>
    </div>

    <!-- Action -->
    <div
      class="goalAction"
      *ngIf="hasGoalsAddAccess || hasGoalsEditAccess || hasGoalsDeleteAccess"
    >
      <button
        mat-icon-button
        type="button"
        *ngIf="
          !isProcessing &&
          type === 'Available' &&
          therapySession.status === 'Active' &&
          hasGoalsAddAccess
        "
        (click)="addGoalForSession(goal)"
      >
        <mat-icon style="color: green"> add_circle </mat-icon>
      </button>

      <button
        mat-icon-button
        *ngIf="
          !isProcessing &&
          type === 'Assigned' &&
          (hasGoalsEditAccess || hasGoalsDeleteAccess)
        "
        type="button"
        [matMenuTriggerFor]="menu"
      >
        <mat-icon style="color: #bbbbbb"> more_vert </mat-icon>
      </button>

      <mat-menu #menu="matMenu" style="max-height: 500px">
        <button
          *ngIf="hasGoalsEditAccess"
          mat-menu-item
          type="button"
          (click)="editSessionGoal(goal)"
        >
          <mat-icon style="color: #f39121"> edit </mat-icon>
          <span>Edit</span>
        </button>
        <button
          *ngIf="hasGoalsDeleteAccess && patient.status === 'Active'"
          mat-menu-item
          type="button"
          (click)="deleteGoal(goal)"
        >
          <mat-icon style="color: red"> delete_forever </mat-icon>
          <span>Delete</span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>
