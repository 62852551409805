import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { ITherapySession, Patient } from 'src/app/_models';
import { TherapySessionService, ToastMessageService } from 'src/app/_services';

@Component({
  selector: 'app-session-subjectives',
  templateUrl: './session-subjectives.component.html',
  styleUrls: ['./session-subjectives.component.css'],
})
export class SessionSubjectivesComponent implements OnInit {
  @Input() patient: Patient;
  @Input() loggedInUserId: string;
  @Input() therapySession: ITherapySession;

  @Input() hasSubjectivesViewAccess: boolean;
  @Input() hasSubjectivesEditAccess: boolean;

  @Output() output = new EventEmitter<any>();

  processing: boolean = false;
  hasChanges: boolean = false;

  subjectives: any;
  public subjectiveForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public therapysessionApiService: TherapySessionService,
    public toastMessageService: ToastMessageService
  ) {}

  ngOnInit(): void {
    this.buildForm();
    if (this.therapySession.subjective) {
      this.subjectiveForm.controls['subjective'].setValue(
        this.therapySession.subjective
      );
    }
  }

  private buildForm() {
    this.subjectiveForm = this.formBuilder.group({
      subjective: new FormControl({
        value: '',
        disabled:
          this.therapySession.status != 'Active' ||
          !this.hasSubjectivesEditAccess,
      }),
    });

    // Waiting for 2 event to have atleast 1 second gap
    this.subjectiveForm.controls['subjective'].valueChanges
      .pipe(debounceTime(1000))
      .subscribe(() => {
        this.hasChanges = true;
        this.submitForm();
      });
  }

  submitForm() {
    this.subjectives = Object.assign({}, this.subjectives);
    this.subjectives = Object.assign(
      this.subjectives,
      this.subjectiveForm.value
    );

    this.processing = true;
    this.therapysessionApiService
      .updateSubjective(this.therapySession.id, this.subjectives)
      .subscribe({
        next: (response) => {
          // this.toastMessageService.displaySuccessMessage(
          //   'Successfully updated the subjectives.'
          // );
          this.processing = false;
          this.output.emit({
            eventType: 'RELOAD_THERPAY_OBJECT',
            therapySession: response,
          });
        },
        error: (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to add subjective record.'
          );
        },
      });
  }
}
