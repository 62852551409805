import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { DataTablesModel, IOTP, Patient } from 'src/app/_models';
import { IEmailLink } from 'src/app/_models/emailLink.model';
import {
  AssessmentHistoryApiService,
  CoreService,
  OTPService,
  TherapySessionService,
  ToastMessageService,
} from 'src/app/_services';
import { AssessmentRequestApiService } from 'src/app/_services/assessments/assessmentrequest.service';
import { AddEditPatientComponent } from 'src/app/pages/patients/dialogs/add/add.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { AssessmentMainDialogComponent } from '../../../assessments/assessment-main-dialog/assessment-main-dialog.component';
import { ReoccurringAssessmentComponent } from '../../../assessments/reoccurring-assessment/reoccurring-assessment.component';
import { RequestAssessmentComponent } from '../../../assessments/request-assessment/request-assessment.component';
@Component({
  selector: 'app-member-assessments',
  templateUrl: './member-assessments.component.html',
  styleUrls: ['./member-assessments.component.css'],
})
export class MemberAssessmentsComponent implements OnInit, OnChanges {
  @Input() patient: Patient;
  @Input() isPhysician: boolean;
  @Input() hasHospitalDeviceFeature: boolean;
  @Input() hasMemberEditAccess: boolean;

  @Input() hasMemberAssessmentViewAccess: boolean;
  @Input() hasMemberAssessmentAddAccess: boolean;
  @Input() hasMemberAssessmentEditAccess: boolean;
  @Input() hasMemberAssessmentDeleteAccess: boolean;

  @Input() hasAssessmentChartsViewAccess: boolean;
  @Input() hasAssessmentGuidanceViewAccess: boolean;
  @Input() hasAssessmentGuidanceEditAccess: boolean;
  @Input() hasAssessmentSummaryViewAccess: boolean;

  @Input() hasAssessmentSummaryEditAccess: boolean;
  @Input() hasAssessmentTakeAddAccess: boolean;

  @Output() output = new EventEmitter<any>();

  data: DataTablesModel = {} as DataTablesModel;
  selectedOption: string = 'In Review';

  pendingAssessmentProcessing: boolean = false;
  assessmentRequests: any[];

  otpInterface: IOTP;
  newlinkRequest: IEmailLink;
  otpLoader: boolean = false;
  accessCode: string = '';
  orgConfig: any;

  isBPSAEnforced: boolean = true;

  options = [
    { id: 'Pending', description: 'Pending' },
    { id: 'In Review', description: 'In Review' },
    { id: 'Completed', description: 'Completed' },
  ];
  displayedPendingAssessmentColumns = [
    'createdDate',
    'sessionId',
    'assessmentType',
    'action',
  ];

  constructor(
    public dialog: MatDialog,
    public assessmentRequestApiService: AssessmentRequestApiService,
    public assessmenthistoryApiService: AssessmentHistoryApiService,
    private toastMessageService: ToastMessageService,
    private otpService: OTPService,
    private coreService: CoreService,
    public therapysessionApiService: TherapySessionService
  ) { }

  ngOnInit(): void {
    this.data.per_page = 10;
    this.data.page = 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.patient) {
      if (this.patient) {
        this.selectedOption = 'In Review';
      }
    }
  }

  getPendingAssessmentRequests() {
    this.pendingAssessmentProcessing = true;

    this.assessmentRequestApiService
      .getAssessmentRequestsByPatientId(
        this.patient.id,
        null,
        this.data.per_page,
        this.data.page
      )
      .subscribe(
        (response) => {
          if (response) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }
          this.pendingAssessmentProcessing = false;
        },
        (error) => {
          this.pendingAssessmentProcessing = false;
        }
      );
  }

  toggleSelection(option: any, chip: any) {
    if (chip) {
      if (!chip.selected) {
        this.data.page = 0;
        chip.toggleSelected();
      }
    }
    this.selectedOption = option;
    if (this.selectedOption === 'Pending') {
      this.data.page = 0;
      this.getPendingAssessmentRequests();
    }
  }

  requestNewAssessment() {
    const dialogRef = this.dialog.open(RequestAssessmentComponent, {
      minWidth: '50vw',
      minHeight: '50vh',
      maxHeight: '95vh',
      disableClose: true,
      autoFocus: false,
      data: {
        patient: this.patient,
      },
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        let payload: any = {};
        payload.eventType = 'RELOAD_PENDING_ASSESSMENT_REQUESTS';
        this.output.emit(payload);
        this.getPendingAssessmentRequests();
      }
    });
  }

  requestReoccurringAssessment() {
    const dialogRef = this.dialog.open(ReoccurringAssessmentComponent, {
      minWidth: '40vw',
      disableClose: true,
      autoFocus: false,
      data: {
        patient: this.patient,
      },
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        // Reload reoccuring assessment list
        this.toastMessageService.displaySuccessMessage(
          'Successfully added recurring assessment.'
        );

        this.getPendingAssessmentRequests();

        let payload: any = {};
        payload.eventType = 'RELOAD_PENDING_ASSESSMENT_REQUESTS';
        this.output.emit(payload);
      }
    });
  }

  openDeleteConfirm(row) {
    const dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message: 'Are you sure that you want cancel this assessment request?',
      },
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.pendingAssessmentProcessing = true;

        this.assessmenthistoryApiService
          .deletePendingAssessment(row.id, row.patientId, row.organizationId)
          .subscribe(
            (response) => {
              this.pendingAssessmentProcessing = false;
              this.toastMessageService.displaySuccessMessage(
                'Assessment request has been canceled'
              );
              // Handling delete pagination
              if (this.data.items.length == 1) {
                this.data.page = this.data.page - 1;
                if (this.data.page < 0) {
                  this.data.page = 0;
                }
              }
              this.getPendingAssessmentRequests();
              let payload: any = {};
              payload.eventType = 'RELOAD_PENDING_ASSESSMENT_REQUESTS';
              this.output.emit(payload);
            },
            (error) => {
              this.pendingAssessmentProcessing = false;
              this.toastMessageService.displayErrorMessage(
                'Error: Something went wrong while cancelling the assessment request.'
              );
            }
          );
      }
    });
  }

  // Generate a one-time access code
  generateAcessCode(assessment) {
    this.otpInterface = Object.assign({}, this.otpInterface);
    this.accessCode = '';
    // Populating the desired fields only
    this.otpInterface.organizationId = assessment.organizationId;
    this.otpInterface.assessmentCategory = assessment.assessmentCategory;
    this.otpInterface.assessmentType = assessment.assessmentType;
    this.otpInterface.assessmentId = assessment.id;
    this.otpInterface.patientEmailAddress = assessment.patientEmailAddress;
    this.otpInterface.patientId = assessment.patientId;
    this.otpInterface.patientName = assessment.patientName;
    this.otpInterface.patientFirstName = assessment.patientFirstName;
    this.otpInterface.patientMiddleName = assessment.patientMiddleName;
    this.otpInterface.patientLastName = assessment.patientLastName;

    this.otpInterface.requestorId = assessment.requestorId;
    this.otpInterface.requestorName = assessment.requestorName;
    this.otpInterface.dateOfBirth = this.patient.dateOfBirth;
    // Call in the API to get the code
    this.otpLoader = true;
    this.otpService.generateOTP(this.otpInterface).subscribe(
      (response) => {
        if (response.result) {
          this.accessCode = response.message.text;
          this.dialog.open(ConfirmDialogComponent, {
            data: {
              title: 'Access Code',
              msg: response.message.text,
              disclaimer:
                '* Please note that this code will expire in 15 minutes.',
            },
            disableClose: true,
            autoFocus: false,
          });
        }

        this.otpLoader = false;
      },
      (error) => {
        this.otpLoader = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Something went wrong. Please try again.'
        );
      }
    );
  }

  sendEmailLink(assessment) {
    if (this.patient?.email && this.patient?.phoneNumber) {
      // Generate the link and display success message
      this.newlinkRequest = Object.assign({}, this.newlinkRequest);
      this.newlinkRequest.organizationId = assessment.organizationId;
      this.newlinkRequest.assessmentCategory = assessment.assessmentCategory;
      this.newlinkRequest.assessmentType = assessment.assessmentType;
      this.newlinkRequest.assessmentId = assessment.id;
      this.newlinkRequest.patientId = assessment.patientId;
      this.newlinkRequest.dateOfBirth = this.patient.dateOfBirth;

      this.otpLoader = true;
      this.otpService.generateEmailLink(this.newlinkRequest).subscribe(
        (response) => {
          this.otpLoader = false;
          this.toastMessageService.displaySuccessMessage(
            'Email with the assessment link and code has been sent to the member email and phone respectively.'
          );
        },
        (error) => {
          this.otpLoader = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Something went wrong while generating the email link. Please try again.'
          );
        }
      );
    } else {
      let message = '';
      if (!this.patient?.email) {
        message = message + 'Member email ';
      }
      if (!this.patient?.phoneNumber) {
        if (message?.length > 2) {
          message =
            message +
            'and phone number is required to send the assessment link.';
        } else {
          message =
            'Member phone number is required to send the assessment link.';
        }
      }

      if (this.hasMemberEditAccess) {
        message =
          message +
          ' Please update the member details and try generating the link again.';
        this.toastMessageService.displayWarnMessage(message);
        // Now open edit member popup
        this.editPatient();
      } else {
        message =
          message +
          ' Please contact your admin to have member details updated or give you access to edit member details.';
        this.toastMessageService.displayWarnMessage(message);
      }
    }
  }

  editPatient() {
    const dialogRef = this.dialog.open(AddEditPatientComponent, {
      data: { action: 'EDIT', patient: this.patient },
      disableClose: true,
      autoFocus: false,
      minWidth: '40vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        let payload: any = {};
        payload.eventType = 'RELOAD_PATIENT_PROFILE';
        this.output.emit(payload);
      }
    });
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.getPendingAssessmentRequests();
  }

  // Take Assessment()
  takeAssessment(assessment) {
    const dialogRef = this.dialog.open(AssessmentMainDialogComponent, {
      minWidth: '50vw',
      minHeight: '50vh',
      maxHeight: '95vh',
      disableClose: true,
      autoFocus: false,
      data: {
        patientId: assessment.patientId,
        sessionId: assessment.sessionId,
        assessmentType: assessment.assessmentType,
        requestId: assessment.id,
      },
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        if (this.data.items.length == 1) {
          this.data.page = this.data.page - 1;
          if (this.data.page < 0) {
            this.data.page = 0;
          }
        }

        this.getPendingAssessmentRequests();
        let payload: any = {};
        payload.eventType = 'RELOAD_PENDING_ASSESSMENT_REQUESTS';
        this.output.emit(payload);
      }
    });
  }
}
