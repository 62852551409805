<div class="sidebar-wrapper" *ngIf="isAuthorized">
  <div class="user">
    <div class="user-info" style="text-align: center">
      <div class="sideBarText" style="font-size: 18px">Welcome</div>
      <div class="sideBarText">{{ name }}</div>
    </div>
  </div>

  <ul class="nav">
    <!-- Provider Home -->
    <li *ngIf="isPhysician" routerLinkActive="active" class="nav-item">
      <a routerLink="/provider-dashboard" class="nav-link">
        <i class="material-icons">home</i>
        <p>Home</p>
      </a>
    </li>

    <!-- NP Provider Home -->
    <li *ngIf="!isPhysician && !isPatient && hasTherapySessionFeature" routerLinkActive="active" class="nav-item">
      <a routerLink="/general-dashboard" class="nav-link">
        <i class="material-icons">home</i>
        <p>Home</p>
      </a>
    </li>

    <!-- Members -->
    <li *ngIf="!isPatient" routerLinkActive="active" class="nav-item">
      <a data-bs-toggle="collapse" href="#membersTab" class="nav-link">
        <i class="material-icons">group</i>
        <p>Members<b class="caret"></b></p>
      </a>

      <div id="membersTab" class="collapse" style="padding-left: 20px">
        <ul class="nav">
          <li routerLinkActive="active" class="nav-item" *ngIf="isPhysician">
            <a class="nav-link" routerLink="physician/patients">
              <mat-icon>people</mat-icon> &nbsp; My Members
            </a>
          </li>

          <li routerLinkActive="active" class="nav-item">
            <a class="nav-link" routerLink="/patients">
              <mat-icon>group</mat-icon> &nbsp; All Members
            </a>
          </li>

          <li *ngIf="hasCaseManagementFeature && hasCaseViewAccess" routerLinkActive="active" class="nav-item">
            <a class="nav-link" routerLink="/main/cases">
              <mat-icon>business_center</mat-icon> &nbsp; My Cases
            </a>
          </li>

          <li>
            <a class="nav-link" routerLink="/main/eligibility-checks">
              <mat-icon>credit_score</mat-icon> &nbsp; Eligibility Checks
            </a>
          </li>
        </ul>
      </div>
    </li>

    <li *ngIf="isPhysician" routerLinkActive="active" class="nav-item">
      <a routerLink="main/assessments" class="nav-link">
        <i class="material-icons">assignment</i>
        <p>Assessments</p>
      </a>
    </li>

    <!-- Patient Side Bar -->
    <li *ngIf="isPatient" routerLinkActive="active" class="nav-item">
      <a routerLink="/patientDashboard/Home" class="nav-link">
        <i class="material-icons">home</i>
        <p>Home</p>
      </a>
    </li>
    <li *ngIf="isPatient" routerLinkActive="active" class="nav-item">
      <a routerLink="/patientDashboard/Assessments" class="nav-link">
        <i class="material-icons">assignment</i>
        <p>Assessments</p>
      </a>
    </li>
    <li *ngIf="isPatient" routerLinkActive="active" class="nav-item">
      <a routerLink="/patientDashboard/Sessions" class="nav-link">
        <i class="material-icons">edit_calendar</i>
        <p>Appointments</p>
      </a>
    </li>
    <li routerLinkActive="active" class="nav-item" *ngIf="isPatient">
      <a routerLink="/patientDashboard/Goals" class="nav-link">
        <i class="material-icons">track_changes</i>
        <p>Goals</p>
      </a>
    </li>

    <!-- Non-Provider Side Bar -->
    <li *ngIf="!isPatient" routerLinkActive="active" class="nav-item">
      <a routerLink="forms" class="nav-link">
        <i class="material-icons">picture_as_pdf</i>
        <p>Forms</p>
      </a>
    </li>

    <!-- Claims -->
    <li *ngIf="!isPatient && hasClaimsFeature && hasClaimsViewAccess" routerLinkActive="active" class="nav-item">
      <a data-bs-toggle="collapse" href="#billingClaims" class="nav-link">
        <i class="material-icons">account_balance</i>
        <p>Billing<b class="caret"></b></p>
      </a>

      <div id="billingClaims" class="collapse" style="padding-left: 20px">
        <ul class="nav">
          <li routerLinkActive="active" class="nav-item">
            <a class="nav-link" routerLink="/claims">
              <mat-icon>account_balance_wallet</mat-icon> &nbsp; Claims
            </a>
          </li>
          <li routerLinkActive="active" class="nav-item">
            <a class="nav-link" routerLink="/remittances/remit">
              <mat-icon>paid</mat-icon> &nbsp; Remittances
            </a>
          </li>
        </ul>
      </div>
    </li>

    <!-- Schedules -->
    <li *ngIf="!isPatient" routerLinkActive="active" class="nav-item">
      <a data-bs-toggle="collapse" href="#scheduling" class="nav-link">
        <i class="material-icons">schedule</i>
        <p>Schedules<b class="caret"></b></p>
      </a>

      <div id="scheduling" class="collapse" style="padding-left: 20px">
        <ul class="nav">
          <li routerLinkActive="active" class="nav-item" *ngIf="hasTherapySessionAddAccess">
            <a class="nav-link" routerLink="/schedules/providerlistscheduler">
              <mat-icon>person</mat-icon> &nbsp; + Individual Appointment
            </a>
          </li>
          <li routerLinkActive="active" class="nav-item" *ngIf="hasTherapySessionAddAccess && hasGroupSessionFeature">
            <a class="nav-link" routerLink="/schedules/groupsessionscheduler">
              <mat-icon>group</mat-icon> &nbsp; + Group Appointment
            </a>
          </li>
          <li routerLinkActive="active" class="nav-item">
            <a class="nav-link" routerLink="/schedules/staffs-schedule-dashboard">
              <mat-icon>calendar_month</mat-icon> &nbsp; Staff Schedules
            </a>
          </li>
        </ul>
      </div>
    </li>

    <!-- IVR -->
    <li *ngIf="
        !isPatient &&
        hasIVRServices &&
        (hasIVRSessionsViewAccess ||
          hasIVRVoiceMessageViewAccess ||
          hasIVRAppointmentsViewAccess)
      " routerLinkActive="active" class="nav-item">
      <a data-bs-toggle="collapse" href="#ivrScheduling" class="nav-link">
        <i class="material-icons">support_agent</i>
        <p>IVR<b class="caret"></b></p>
      </a>

      <div id="ivrScheduling" class="collapse" style="padding-left: 20px">
        <ul class="nav">
          <li routerLinkActive="active" class="nav-item" *ngIf="hasIVRSessionsViewAccess">
            <a routerLink="/ivr/sessions" class="nav-link">
              <i class="material-icons">connect_without_contact</i>
              <p>Appointments</p>
            </a>
          </li>
          <li routerLinkActive="active" class="nav-item" *ngIf="hasIVRVoiceMessageViewAccess">
            <a routerLink="/ivr/voice-messages" class="nav-link">
              <i class="material-icons">phone_callback</i>
              <span class="sidebar-normal"> Voice Messages</span>
            </a>
          </li>
          <li routerLinkActive="active" class="nav-item" *ngIf="hasIVRAppointmentsViewAccess">
            <a routerLink="/ivr/scheduled-appointments" class="nav-link">
              <i class="material-icons">notifications_active</i>
              <span class="sidebar-normal"> Appointments</span>
            </a>
          </li>
        </ul>
      </div>
    </li>

    <div class="nav navBarMobileView" style="display: flex; flex: 1; flex-direction: column-reverse">
      <button class="btn btn-danger" id="qsLogoutBtn" (click)="logout()" style="margin-right: 20px; margin-left: 20px">
        <span class="material-icons"> logout </span>&nbsp;Log out
      </button>
      <li routerLinkActive="active" *ngFor="let item of accountMenu" class="nav-item">
        <a [routerLink]="[item.path]" class="nav-link">
          <i class="material-icons">{{ item.icontype }}</i>
          <p>{{ item.title }}</p>
        </a>
      </li>
    </div>
  </ul>
</div>