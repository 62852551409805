export const PaymentMethodCodesList = [
  {
    code: 'ACH',
    description: 'Automated Clearinghouse',
  },
  {
    code: 'BOP',
    description: 'Financial Institution Option',
  },
  {
    code: 'CHK',
    description: 'Check',
  },
  {
    code: 'FWT',
    description: 'Federal Reserve Funds/Wire Transfer',
  },
  {
    code: 'NON',
    description: 'Non Payment Data',
  },
];

export const PaymentMethodCodesListMap = new Map<string, string>([
  ['ACH', 'Automated Clearinghouse'],
  ['BOP', 'Financial Institution Option'],
  ['CHK', 'Check'],
  ['FWT', 'Federal Reserve Funds/Wire Transfer'],
  ['NON', 'Non Payment Data'],
]);
