<div style="margin-top: 10px; margin-bottom: 20px">
  <div class="row" *ngIf="hasPhysiciansAddAccess" style="margin-bottom: 15px">
    <div class="col-md-12" style="text-align: right">
      <button
        type="button"
        mat-raised-button
        color="warn"
        (click)="addPhysician()"
      >
        + Add Physician
      </button>
    </div>
  </div>

  <div *ngIf="data.total === 0">
    <mat-error> No physician found </mat-error>
  </div>
  <div *ngIf="isLoadingResults || apiError">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="isLoadingResults"
    ></mat-progress-bar>

    <mat-error *ngIf="apiError">
      An error occurred while retrieving Physician records
    </mat-error>
  </div>

  <div class="mat-elevation-z8" *ngIf="data.total > 0">
    <div class="table-responsive">
      <table mat-table [dataSource]="data.items" matSort matSortDisableClear>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let row">
            {{ row.lastName }}, {{ row.firstName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="speciality">
          <th mat-header-cell *matHeaderCellDef>Specialty</th>
          <td mat-cell *matCellDef="let row">{{ row.speciality }}</td>
        </ng-container>
        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef>Phone</th>
          <td mat-cell *matCellDef="let row" style="min-width: 150px">
            {{ row.phone | mask : '(000) 000-0000' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="fax">
          <th mat-header-cell *matHeaderCellDef>Fax</th>
          <td mat-cell *matCellDef="let row">{{ row.fax }}</td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let row">{{ row.email }}</td>
        </ng-container>
        <ng-container matColumnDef="hospital">
          <th mat-header-cell *matHeaderCellDef>Hospital</th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="row.hospitalName">{{ row.hospitalName }}<br /></span>
            <span *ngIf="row.hospitalAddressLine1"
              >{{ row.hospitalAddressLine1 }}<br
            /></span>
            <span *ngIf="row.hospitalAddressLine2"
              >{{ row.hospitalAddressLine2 }}<br
            /></span>
            <span
              *ngIf="row.hospitalCity || row.hospitalState || row.hospitalZip"
              >{{ row.hospitalCity }} {{ row.hospitalState }}
              {{ row.hospitalZip }}<br
            /></span>
            <span *ngIf="row.hospitalPhoneNumber"
              >{{ row.hospitalPhoneNumber }}<br
            /></span>
          </td>
        </ng-container>
        <ng-container matColumnDef="notes">
          <th mat-header-cell *matHeaderCellDef>Notes</th>
          <td mat-cell *matCellDef="let row">{{ row.notes }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef style="text-align: right">
            Actions
          </th>
          <td mat-cell *matCellDef="let record" style="text-align: right">
            <button
              mat-raised-button
              [matMenuTriggerFor]="menu"
              class="btn btn-just-icon btn-white btn-fab btn-round"
            >
              <i class="material-icons text_align-center">more_vert</i>
            </button>

            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                (click)="editPhysician(record)"
                *ngIf="hasPhysiciansEditAccess"
              >
                <mat-icon style="color: #f39121"> edit </mat-icon>
                <span>Edit</span>
              </button>
              <button
                mat-menu-item
                (click)="deletePhysician(record)"
                *ngIf="hasPhysiciansDeleteAccess"
              >
                <mat-icon style="color: red"> delete_forever </mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <mat-paginator
      #paginator
      [length]="data.total"
      [pageSize]="data.per_page"
      [showFirstLastButtons]="true"
      *ngIf="data.total > data.per_page"
      (page)="getNext($event)"
    >
    </mat-paginator>
  </div>
</div>

<mat-card appearance="raised" *ngIf="!hasPhysiciansViewAccess">
  <mat-card-header>
    <mat-card-title>
      <mat-error> Sorry, You don't have access to view Physicians </mat-error>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content> </mat-card-content>
</mat-card>
