import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { PermissionsSandbox } from 'src/app/shared/sandbox/permissions.sandbox';
import { hasAccess } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-provider-all-notes',
  templateUrl: './provider-all-notes.component.html',
  styleUrl: './provider-all-notes.component.css',
})
export class ProviderAllNotesComponent implements OnInit {
  status: string;

  //********  Member Permissions ********/
  orgConfig: any;
  permissionsLoaded = false;
  hasMemberPaymentsAddAccess = false;
  hasTherapySessionEditAccess = false;

  constructor(
    private route: ActivatedRoute,
    private keycloakService: KeycloakService,
    private orgConfigSandbox: OrgConfigSandbox,
    protected permissionsSandbox: PermissionsSandbox
  ) {}

  ngOnInit(): void {
    this.status = this.route.snapshot.paramMap.get('status');

    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        // When load is complete, get the org config from app state
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          this.orgConfig = orgConfig;
          // Saving the permissions
          this.permissionsSandbox.permissions$.subscribe((response) => {
            this.hasMemberPaymentsAddAccess = hasAccess(
              this.keycloakService,
              'MEMBER_PAYMENTS_ADD',
              response,
              null
            );

            this.hasTherapySessionEditAccess = hasAccess(
              this.keycloakService,
              'THERAPY_SESSIONS_EDIT',
              response,
              null
            );

            this.permissionsLoaded = true;
          });
        });
      }
    });
  }
}
