export const ClaimResubmissionCodesList = [
  {
    id: '1',
    description: 'Original',
  },
  {
    id: '6',
    description: 'Corrected',
  },
  {
    id: '7',
    description: 'Replacement',
  },
  {
    id: '8',
    description: 'Void',
  },
];
