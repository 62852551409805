<div class="container">
  <div class="dialogTitle" *ngIf="isModalWindow">
    <p matDialogTitle>{{ documentName }}</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="col-md-12" *ngIf="isLoadingResults">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div style="height: 600px; width: 100%">
    <ng2-pdfjs-viewer
      [download]="'false'"
      #pdfViewerOnDemand
    ></ng2-pdfjs-viewer>
  </div>

  <div
    class="col-md-12"
    *ngIf="!isLoadingResults"
    style="text-align: right; margin-top: 15px"
  >
    <button
      type="button"
      mat-raised-button
      color="primary"
      (click)="openSignaturePad()"
      [disabled]="processing"
    >
      <i class="material-icons" style="margin-right: 8px; font-size: 18px">
        edit
      </i>
      Sign Document <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
    </button>
  </div>
</div>
