import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  DataTablesModel,
  INewClinicalNote,
  ITherapySession,
  Patient,
} from 'src/app/_models';
import { TherapySessionService, ToastMessageService } from 'src/app/_services';
import { ClinicalNoteSectionsApiService } from 'src/app/_services/session/clinical/clinicalnotesections.service';

@Component({
  selector: 'app-session-add-clinical-note',
  templateUrl: './session-add-clinical-note.component.html',
  styleUrl: './session-add-clinical-note.component.css',
})
export class SessionAddClinicalNoteComponent implements OnInit {
  patient: Patient;
  therapySession: ITherapySession;
  addType: string;

  isLoadingData: boolean = false;
  processing: boolean = false;

  clinicalNoteTypes: DataTablesModel = {} as DataTablesModel;
  clinicalNoteNameIdMap: Map<string, string> = new Map<string, string>();

  public clinicalNoteForm: FormGroup;
  newClinicalNote: INewClinicalNote;

  constructor(
    public dialogRef: MatDialogRef<SessionAddClinicalNoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private clinicalNoteSectionsApiService: ClinicalNoteSectionsApiService,
    public therapysessionApiService: TherapySessionService,
    private toastMessageService: ToastMessageService
  ) {
    this.patient = data.patient;
    this.therapySession = data.therapySession;
    this.addType = data.type;
  }

  ngOnInit(): void {
    this.loadOrganizationAllActiveNoteTypes();
    this.buildForm();
  }

  private buildForm() {
    this.clinicalNoteForm = this.formBuilder.group({
      therapySessionId: new FormControl(
        this.therapySession.id,
        Validators.required
      ),
      clinicalNoteId: new FormControl('', Validators.required),
      clinicalNoteTypeName: new FormControl('', Validators.required),
    });
  }

  loadOrganizationAllActiveNoteTypes() {
    this.isLoadingData = true;
    this.clinicalNoteSectionsApiService
      .getOrganizationAllActiveNotesList()
      .subscribe({
        next: (response) => {
          if (response && response.items) {
            this.clinicalNoteTypes.items = response.items;
            this.clinicalNoteTypes.total = response.total;

            // Set map for each note type name -> id
            response.items.forEach((item) => {
              this.clinicalNoteNameIdMap.set(item.name, item.id);
            });
          } else {
            this.clinicalNoteTypes.items = [];
            this.clinicalNoteTypes.total = 0;
          }
          this.isLoadingData = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get the clinical note types'
          );
          this.isLoadingData = false;
        },
      });
  }

  // Set the clinical note ID as well
  noteTypeSelected(event) {
    let noteId = this.clinicalNoteNameIdMap.get(event.value);
    this.clinicalNoteForm.controls['clinicalNoteId'].setValue(noteId);
  }

  submitForm() {
    this.newClinicalNote = Object.assign({}, this.newClinicalNote);
    this.newClinicalNote = Object.assign(
      this.newClinicalNote,
      this.clinicalNoteForm.value
    );

    this.processing = true;

    if (this.addType === 'Group') {
      this.therapysessionApiService
        .addNewGroupClinicalNote(this.newClinicalNote)
        .subscribe({
          next: (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully added the note type to the Group Clincial Notes'
            );
            this.dialogRef.close({ type: 'success', data: response.data });
            this.processing = false;
          },
          error: (error) => {
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to add the note. ' + error?.text
            );
            this.processing = false;
          },
        });
    } else {
      this.therapysessionApiService
        .addNewSessionClinicalNote(this.newClinicalNote)
        .subscribe({
          next: (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully added the note type to the Session Clincial Notes'
            );
            this.dialogRef.close({ type: 'success', data: response.data });
            this.processing = false;
          },
          error: (error) => {
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to add the note. ' + error?.text
            );
            this.processing = false;
          },
        });
    }
  }
}
