import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { IChildhoodAutismSpectrum } from 'src/app/_models/assessments/autism/childhoodautismspectrum.model';
import {
  ChildhoodAutismSpectrumApiService,
  ToastMessageService,
} from 'src/app/_services/';

@Component({
  selector: 'app-childhood-autism-spectrum',
  templateUrl: './childhood-autism-spectrum.component.html',
  styleUrls: ['./childhood-autism-spectrum.component.css'],
})
export class ChildhoodAutismSpectrumComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public childhoodAutismSpectrumApiService: ChildhoodAutismSpectrumApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) {}
  public riskScore: number;
  public childhoodAutismSpectrumForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  childhoodAutismSpectrumAssessment: IChildhoodAutismSpectrum;
  childhoodAutismSpectrumAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(
      this.childhoodAutismSpectrumForm,
      this.errors
    );

    this.loadChildhoodAutismSpectrumAssessment();
  }

  loadChildhoodAutismSpectrumAssessment() {
    this.childhoodAutismSpectrumForm.controls['patientId'].setValue(
      this.patientId
    );

    if (this.assessmentId) {
      this.childhoodAutismSpectrumApiService
        .getChildhoodAutismSpectrumDetails(this.assessmentId)
        .subscribe((childhoodAutismSpectrumAssessment) => {
          if (childhoodAutismSpectrumAssessment.data) {
            this.childhoodAutismSpectrumForm.controls['q1'].setValue(
              childhoodAutismSpectrumAssessment.data.q1.answer
            );
            this.childhoodAutismSpectrumForm.controls['q2'].setValue(
              childhoodAutismSpectrumAssessment.data.q2.answer
            );
            this.childhoodAutismSpectrumForm.controls['q3'].setValue(
              childhoodAutismSpectrumAssessment.data.q3.answer
            );
            this.childhoodAutismSpectrumForm.controls['q4'].setValue(
              childhoodAutismSpectrumAssessment.data.q4.answer
            );
            this.childhoodAutismSpectrumForm.controls['q5'].setValue(
              childhoodAutismSpectrumAssessment.data.q5.answer
            );
            this.childhoodAutismSpectrumForm.controls['q6'].setValue(
              childhoodAutismSpectrumAssessment.data.q6.answer
            );
            this.childhoodAutismSpectrumForm.controls['q7'].setValue(
              childhoodAutismSpectrumAssessment.data.q7.answer
            );
            this.childhoodAutismSpectrumForm.controls['q8'].setValue(
              childhoodAutismSpectrumAssessment.data.q8.answer
            );
            this.childhoodAutismSpectrumForm.controls['q9'].setValue(
              childhoodAutismSpectrumAssessment.data.q9.answer
            );
            this.childhoodAutismSpectrumForm.controls['q10'].setValue(
              childhoodAutismSpectrumAssessment.data.q10.answer
            );
            this.childhoodAutismSpectrumForm.controls['q11'].setValue(
              childhoodAutismSpectrumAssessment.data.q11.answer
            );
            this.childhoodAutismSpectrumForm.controls['q12'].setValue(
              childhoodAutismSpectrumAssessment.data.q12.answer
            );
            this.childhoodAutismSpectrumForm.controls['q13'].setValue(
              childhoodAutismSpectrumAssessment.data.q13.answer
            );
            this.childhoodAutismSpectrumForm.controls['q14'].setValue(
              childhoodAutismSpectrumAssessment.data.q14.answer
            );
            this.childhoodAutismSpectrumForm.controls['q15'].setValue(
              childhoodAutismSpectrumAssessment.data.q15.answer
            );
            this.childhoodAutismSpectrumForm.controls['q16'].setValue(
              childhoodAutismSpectrumAssessment.data.q16.answer
            );
            this.childhoodAutismSpectrumForm.controls['q17'].setValue(
              childhoodAutismSpectrumAssessment.data.q17.answer
            );
            this.childhoodAutismSpectrumForm.controls['q18'].setValue(
              childhoodAutismSpectrumAssessment.data.q18.answer
            );
            this.childhoodAutismSpectrumForm.controls['q19'].setValue(
              childhoodAutismSpectrumAssessment.data.q19.answer
            );
            this.childhoodAutismSpectrumForm.controls['q20'].setValue(
              childhoodAutismSpectrumAssessment.data.q20.answer
            );
            this.childhoodAutismSpectrumForm.controls['q21'].setValue(
              childhoodAutismSpectrumAssessment.data.q21.answer
            );
            this.childhoodAutismSpectrumForm.controls['q22'].setValue(
              childhoodAutismSpectrumAssessment.data.q22.answer
            );
            this.childhoodAutismSpectrumForm.controls['q23'].setValue(
              childhoodAutismSpectrumAssessment.data.q23.answer
            );
            this.childhoodAutismSpectrumForm.controls['q24'].setValue(
              childhoodAutismSpectrumAssessment.data.q24.answer
            );
            this.childhoodAutismSpectrumForm.controls['q25'].setValue(
              childhoodAutismSpectrumAssessment.data.q25.answer
            );
            this.childhoodAutismSpectrumForm.controls['q26'].setValue(
              childhoodAutismSpectrumAssessment.data.q26.answer
            );
            this.childhoodAutismSpectrumForm.controls['q27'].setValue(
              childhoodAutismSpectrumAssessment.data.q27.answer
            );
            this.childhoodAutismSpectrumForm.controls['q28'].setValue(
              childhoodAutismSpectrumAssessment.data.q28.answer
            );
            this.childhoodAutismSpectrumForm.controls['q29'].setValue(
              childhoodAutismSpectrumAssessment.data.q29.answer
            );
            this.childhoodAutismSpectrumForm.controls['q30'].setValue(
              childhoodAutismSpectrumAssessment.data.q30.answer
            );
            this.childhoodAutismSpectrumForm.controls['q31'].setValue(
              childhoodAutismSpectrumAssessment.data.q31.answer
            );
            this.childhoodAutismSpectrumForm.controls['q32'].setValue(
              childhoodAutismSpectrumAssessment.data.q32.answer
            );
            this.childhoodAutismSpectrumForm.controls['q33'].setValue(
              childhoodAutismSpectrumAssessment.data.q33.answer
            );
            this.childhoodAutismSpectrumForm.controls['q34'].setValue(
              childhoodAutismSpectrumAssessment.data.q34.answer
            );
            this.childhoodAutismSpectrumForm.controls['q35'].setValue(
              childhoodAutismSpectrumAssessment.data.q35.answer
            );
            this.childhoodAutismSpectrumForm.controls['q36'].setValue(
              childhoodAutismSpectrumAssessment.data.q36.answer
            );
            this.childhoodAutismSpectrumForm.controls['q37'].setValue(
              childhoodAutismSpectrumAssessment.data.q37.answer
            );
            this.childhoodAutismSpectrumForm.controls['q38'].setValue(
              childhoodAutismSpectrumAssessment.data.q38.answer
            );
            this.childhoodAutismSpectrumForm.controls['q39'].setValue(
              childhoodAutismSpectrumAssessment.data.q39.answer
            );

            this.riskScore = childhoodAutismSpectrumAssessment.data.riskScore;
          }
        });
    }
  }

  onFormSubmit() {
    if (this.childhoodAutismSpectrumForm.invalid) {
      this.childhoodAutismSpectrumForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.childhoodAutismSpectrumAssessment = Object.assign(
      {},
      this.childhoodAutismSpectrumAssessment
    );
    this.childhoodAutismSpectrumAssessment.patientId =
      this.childhoodAutismSpectrumForm.controls['patientId'].value;
    this.childhoodAutismSpectrumAssessment.sessionId =
      this.childhoodAutismSpectrumForm.controls['sessionId'].value;
    this.childhoodAutismSpectrumAssessment.assessmentRequestId =
      this.childhoodAutismSpectrumForm.controls['assessmentRequestId'].value;
    this.childhoodAutismSpectrumAssessment.q1 = {
      answer: this.childhoodAutismSpectrumForm.controls['q1'].value,
    };
    this.childhoodAutismSpectrumAssessment.q2 = {
      answer: this.childhoodAutismSpectrumForm.controls['q2'].value,
    };
    this.childhoodAutismSpectrumAssessment.q3 = {
      answer: this.childhoodAutismSpectrumForm.controls['q3'].value,
    };
    this.childhoodAutismSpectrumAssessment.q4 = {
      answer: this.childhoodAutismSpectrumForm.controls['q4'].value,
    };
    this.childhoodAutismSpectrumAssessment.q5 = {
      answer: this.childhoodAutismSpectrumForm.controls['q5'].value,
    };
    this.childhoodAutismSpectrumAssessment.q6 = {
      answer: this.childhoodAutismSpectrumForm.controls['q6'].value,
    };
    this.childhoodAutismSpectrumAssessment.q7 = {
      answer: this.childhoodAutismSpectrumForm.controls['q7'].value,
    };
    this.childhoodAutismSpectrumAssessment.q8 = {
      answer: this.childhoodAutismSpectrumForm.controls['q8'].value,
    };
    this.childhoodAutismSpectrumAssessment.q9 = {
      answer: this.childhoodAutismSpectrumForm.controls['q9'].value,
    };
    this.childhoodAutismSpectrumAssessment.q10 = {
      answer: this.childhoodAutismSpectrumForm.controls['q10'].value,
    };
    this.childhoodAutismSpectrumAssessment.q11 = {
      answer: this.childhoodAutismSpectrumForm.controls['q11'].value,
    };
    this.childhoodAutismSpectrumAssessment.q12 = {
      answer: this.childhoodAutismSpectrumForm.controls['q12'].value,
    };
    this.childhoodAutismSpectrumAssessment.q13 = {
      answer: this.childhoodAutismSpectrumForm.controls['q13'].value,
    };
    this.childhoodAutismSpectrumAssessment.q14 = {
      answer: this.childhoodAutismSpectrumForm.controls['q14'].value,
    };
    this.childhoodAutismSpectrumAssessment.q15 = {
      answer: this.childhoodAutismSpectrumForm.controls['q15'].value,
    };
    this.childhoodAutismSpectrumAssessment.q16 = {
      answer: this.childhoodAutismSpectrumForm.controls['q16'].value,
    };
    this.childhoodAutismSpectrumAssessment.q17 = {
      answer: this.childhoodAutismSpectrumForm.controls['q17'].value,
    };
    this.childhoodAutismSpectrumAssessment.q18 = {
      answer: this.childhoodAutismSpectrumForm.controls['q18'].value,
    };
    this.childhoodAutismSpectrumAssessment.q19 = {
      answer: this.childhoodAutismSpectrumForm.controls['q19'].value,
    };
    this.childhoodAutismSpectrumAssessment.q20 = {
      answer: this.childhoodAutismSpectrumForm.controls['q20'].value,
    };
    this.childhoodAutismSpectrumAssessment.q21 = {
      answer: this.childhoodAutismSpectrumForm.controls['q21'].value,
    };
    this.childhoodAutismSpectrumAssessment.q22 = {
      answer: this.childhoodAutismSpectrumForm.controls['q22'].value,
    };
    this.childhoodAutismSpectrumAssessment.q23 = {
      answer: this.childhoodAutismSpectrumForm.controls['q23'].value,
    };
    this.childhoodAutismSpectrumAssessment.q24 = {
      answer: this.childhoodAutismSpectrumForm.controls['q24'].value,
    };
    this.childhoodAutismSpectrumAssessment.q25 = {
      answer: this.childhoodAutismSpectrumForm.controls['q25'].value,
    };
    this.childhoodAutismSpectrumAssessment.q26 = {
      answer: this.childhoodAutismSpectrumForm.controls['q26'].value,
    };
    this.childhoodAutismSpectrumAssessment.q27 = {
      answer: this.childhoodAutismSpectrumForm.controls['q27'].value,
    };
    this.childhoodAutismSpectrumAssessment.q28 = {
      answer: this.childhoodAutismSpectrumForm.controls['q28'].value,
    };
    this.childhoodAutismSpectrumAssessment.q29 = {
      answer: this.childhoodAutismSpectrumForm.controls['q29'].value,
    };
    this.childhoodAutismSpectrumAssessment.q30 = {
      answer: this.childhoodAutismSpectrumForm.controls['q30'].value,
    };
    this.childhoodAutismSpectrumAssessment.q31 = {
      answer: this.childhoodAutismSpectrumForm.controls['q31'].value,
    };
    this.childhoodAutismSpectrumAssessment.q32 = {
      answer: this.childhoodAutismSpectrumForm.controls['q32'].value,
    };
    this.childhoodAutismSpectrumAssessment.q33 = {
      answer: this.childhoodAutismSpectrumForm.controls['q33'].value,
    };
    this.childhoodAutismSpectrumAssessment.q34 = {
      answer: this.childhoodAutismSpectrumForm.controls['q34'].value,
    };
    this.childhoodAutismSpectrumAssessment.q35 = {
      answer: this.childhoodAutismSpectrumForm.controls['q35'].value,
    };
    this.childhoodAutismSpectrumAssessment.q36 = {
      answer: this.childhoodAutismSpectrumForm.controls['q36'].value,
    };
    this.childhoodAutismSpectrumAssessment.q37 = {
      answer: this.childhoodAutismSpectrumForm.controls['q37'].value,
    };
    this.childhoodAutismSpectrumAssessment.q38 = {
      answer: this.childhoodAutismSpectrumForm.controls['q38'].value,
    };
    this.childhoodAutismSpectrumAssessment.q39 = {
      answer: this.childhoodAutismSpectrumForm.controls['q39'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.childhoodAutismSpectrumAssessment.accessCode = this.accessCode;

      this.childhoodAutismSpectrumApiService
        .addGuestChildhoodAutismSpectrum(this.childhoodAutismSpectrumAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Childhood Autism Spectrum Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Childhood Autism Spectrum Assessment'
            );
          }
        );
    } else {
      this.childhoodAutismSpectrumApiService
        .addChildhoodAutismSpectrum(this.childhoodAutismSpectrumAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Childhood Autism Spectrum Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Childhood Autism Spectrum Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    q1: [
      {
        type: 'required',
        message:
          '1. Does s/he join in playing games with other children easily? is required',
      },
    ],
    q2: [
      {
        type: 'required',
        message:
          '2. Does s/he come up to you spontaneously for a chat? is required',
      },
    ],
    q3: [
      {
        type: 'required',
        message: '3. Was s/he speaking by 2 years old? is required',
      },
    ],
    q4: [
      { type: 'required', message: '4. Does s/he enjoy sports? is required' },
    ],
    q5: [
      {
        type: 'required',
        message:
          '5. Is it important to him/her to fit in with the peer group? is required',
      },
    ],
    q6: [
      {
        type: 'required',
        message:
          '6. Does s/he appear to notice unusual details that others miss? is required',
      },
    ],
    q7: [
      {
        type: 'required',
        message: '7. Does s/he tend to take things literally? is required',
      },
    ],
    q8: [
      {
        type: 'required',
        message:
          "8. When s/he was 3 years old, did s/he spend a lot of time pretending (e.g., play-acting being a superhero, or holding teddy's tea parties)?is required",
      },
    ],
    q9: [
      {
        type: 'required',
        message:
          '9. Does s/he like to do things over and over again, in the same way all the time? is required',
      },
    ],
    q10: [
      {
        type: 'required',
        message:
          '10. Does s/he find it easy to interact with other children? is required',
      },
    ],
    q11: [
      {
        type: 'required',
        message: '11. Can s/he keep a two-way conversation going? is required',
      },
    ],
    q12: [
      {
        type: 'required',
        message: '12. Can s/he read appropriately for his/her age? is required',
      },
    ],
    q13: [
      {
        type: 'required',
        message:
          '13. Does s/he mostly have the same interests as his/her peers? is required',
      },
    ],
    q14: [
      {
        type: 'required',
        message:
          '14. Does s/he have an interest which takes up so much time that s/he does little else? is required',
      },
    ],
    q15: [
      {
        type: 'required',
        message:
          '15. Does s/he have friends, rather than just acquaintances? is required',
      },
    ],
    q16: [
      {
        type: 'required',
        message:
          '16. Does s/he often bring you things s/he is interested in to show you? is required',
      },
    ],
    q17: [
      {
        type: 'required',
        message: '17. Does s/he enjoy joking around? is required',
      },
    ],
    q18: [
      {
        type: 'required',
        message:
          '18. Does s/he have difficulty understanding the rules for polite behavior? is required',
      },
    ],
    q19: [
      {
        type: 'required',
        message:
          '19. Does s/he appear to have an unusual memory for details? is required',
      },
    ],
    q20: [
      {
        type: 'required',
        message:
          '20. Is his/her voice unusual (e.g., overly adult, flat, or very monotonous)? is required',
      },
    ],
    q21: [
      {
        type: 'required',
        message: '21. Are people important to him/her? is required',
      },
    ],
    q22: [
      {
        type: 'required',
        message: '22. Can s/he dress him/herself? is required',
      },
    ],
    q23: [
      {
        type: 'required',
        message: '23. Is s/he good at turn-taking in conversation? is required',
      },
    ],
    q24: [
      {
        type: 'required',
        message:
          '24. Does s/he play imaginatively with other children, and engage in role-play? is required',
      },
    ],
    q25: [
      {
        type: 'required',
        message:
          '25. Does s/he often do or say things that are tactless or socially inappropriate? is required',
      },
    ],
    q26: [
      {
        type: 'required',
        message:
          '26. Can s/he count to 50 without leaving out any numbers? is required',
      },
    ],
    q27: [
      {
        type: 'required',
        message: '27. Does s/he make normal eye-contact? is required',
      },
    ],
    q28: [
      {
        type: 'required',
        message:
          '28. Does s/he have any unusual and repetitive movements? is required',
      },
    ],
    q29: [
      {
        type: 'required',
        message:
          '29. Is his/her social behavior very one-sided and always on his/her own terms? is required',
      },
    ],
    q30: [
      {
        type: 'required',
        message:
          '30. Does s/he sometimes say “you” or “s/he” when s/he means “I”? is required',
      },
    ],
    q31: [
      {
        type: 'required',
        message:
          '31. Does s/he prefer imaginative activities such as play-acting or story-telling, rather than numbers or lists of facts? is required',
      },
    ],
    q32: [
      {
        type: 'required',
        message:
          '32. Does s/he sometimes lose the listener because of not explaining what s/he is talking about? is required',
      },
    ],
    q33: [
      {
        type: 'required',
        message:
          '33. Can s/he ride a bicycle (even if with stabilizers)? is required',
      },
    ],
    q34: [
      {
        type: 'required',
        message:
          '34. Does s/he try to impose routines on him/herself, or on others, in such a way that it causes problems? is required',
      },
    ],
    q35: [
      {
        type: 'required',
        message:
          '35. Does s/he care how s/he is perceived by the rest of the group? is required',
      },
    ],
    q36: [
      {
        type: 'required',
        message:
          '36. Does s/he often turn conversations to his/her favorite subject rather than following what the other person wants to talk about? is required',
      },
    ],
    q37: [
      {
        type: 'required',
        message: '37. Does s/he have odd or unusual phrases? is required',
      },
    ],
    q38: [
      {
        type: 'required',
        message:
          '38. Have teachers/health visitors ever expressed any concerns about his/her development? is required',
      },
    ],
    q39: [
      {
        type: 'required',
        message:
          '39. Has s/he ever been diagnosed with any of the following: Language delay, ADHD, hearing or visual difficulties, Autism Spectrum Condition (including Asperger’s Syndrome, or a physical disability? is required',
      },
    ],
  };

  private buildForm() {
    this.childhoodAutismSpectrumForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      q1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q2: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q3: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q4: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q5: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q6: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q7: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q8: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q9: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q10: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q11: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q12: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q13: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q14: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q15: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q16: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q17: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q18: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q19: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q20: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q21: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q22: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q23: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q24: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q25: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q26: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q27: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q28: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q29: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q30: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q31: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q32: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q33: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q34: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q35: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q36: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q37: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q38: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q39: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.childhoodAutismSpectrumForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.childhoodAutismSpectrumForm) {
      return;
    }
    const form = this.childhoodAutismSpectrumForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
