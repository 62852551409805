import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ClaimsAPIService, ToastMessageService } from 'src/app/_services';

@Component({
  selector: 'app-claims',
  templateUrl: './claims.component.html',
  styleUrls: ['./claims.component.css'],
})
export class ClaimsComponent implements OnInit {
  claimsStatsProcessing: boolean = false;
  selectedClaimTab: string = 'All Claims';
  reloadHeader: Subject<boolean> = new Subject<boolean>();

  claimTabs = [
    {
      id: 'allClaims',
      tabName: 'All Claims',
      count: 0,
      color: '#2196f3',
      icon: 'list',
    },
    {
      id: 'inDraft',
      tabName: 'In Draft',
      count: 0,
      color: '#333333',
      icon: 'edit_note',
    },
    {
      id: 'onHold',
      tabName: 'On Hold',
      count: 0,
      color: '#a72323',
      icon: 'back_hand',
    },
    {
      id: 'queuedForSubmission',
      tabName: 'Queued for Submission',
      count: 0,
      color: '#d36d03',
      icon: 'access_time',
    },
    {
      id: 'processing',
      tabName: 'Processing',
      count: 0,
      color: 'orange',
      icon: 'hourglass_bottom',
    },
    {
      id: 'sentToPayer',
      tabName: 'Sent To Payer',
      count: 0,
      color: '#3e7aa4',
      icon: 'schedule_send',
    },
    {
      id: 'accepted',
      tabName: 'Accepted',
      count: 0,
      color: '#0346bc',
      icon: 'assignment_turned_in',
    },
    {
      id: 'eraReceived',
      tabName: 'ERA Received',
      count: 0,
      color: '#029102',
      icon: 'local_atm',
    },
    {
      id: 'rejected',
      tabName: 'Rejected',
      count: 0,
      color: '#a72323',
      icon: 'warning',
    },
    {
      id: 'archived',
      tabName: 'Archived',
      count: 0,
      color: '#b0c4de',
      icon: 'inventory_2',
    },
  ];

  constructor(
    public claimsApiService: ClaimsAPIService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit(): void {
    // load Claims Statistics
    this.loadClaimsStatistics();
  }

  loadClaimsStatistics() {
    this.claimsStatsProcessing = true;

    this.claimsApiService.getClaimStatistics().subscribe({
      next: (response) => {
        this.claimsStatsProcessing = false;
        if (response && response.data) {
          let allKeys = Object.keys(response.data);

          // Map/ReMap the count values
          this.claimTabs.map((tab) => {
            if (allKeys.includes(tab.id)) {
              tab.count = response.data[tab.id];
            }
          });

          let deepCopy = Object.assign([], this.claimTabs);
          this.claimTabs = deepCopy;
        }
      },
      error: (error) => {
        this.claimsStatsProcessing = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to load the claim statistics'
        );
      },
    });
  }

  selectNewClaimsTab(newTabName) {
    this.selectedClaimTab = newTabName;
  }

  reloadClaimStats(event) {
    this.loadClaimsStatistics();
  }
}
