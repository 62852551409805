export const ClaimFilingIndicators = [
  {
    id: '09',
    description: 'Self-pay',
  },
  {
    id: '12',
    description: 'Preferred Provider Organization (PPO)',
  },
  {
    id: '13',
    description: 'Point of Service (POS)',
  },
  {
    id: 'AM',
    description: 'Automobile Medical',
  },
  {
    id: 'BL',
    description: 'Blue Cross/Blue Shield',
  },
  {
    id: 'CH',
    description: 'Champus',
  },
  {
    id: 'CI',
    description: 'Commercial Insurance Co.',
  },
  {
    id: 'HM',
    description: 'Health Maintenance Organization',
  },
  {
    id: 'MB',
    description: 'Medicare Part B',
  },
  {
    id: 'MC',
    description: 'Medicaid',
  },
  {
    id: 'WC',
    description: 'Workers Compensation Health Claim',
  },
  {
    id: 'ZZ',
    description: 'Mutually Defined',
  },
];
