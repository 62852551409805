export const TimeZonesCalendar = [
  {
    id: 'America/New_York',
    description: 'Eastern Time (ET)',
  },
  {
    id: 'America/Chicago',
    description: 'Central Time (CT)',
  },
  {
    id: 'America/Denver',
    description: 'Mountain Time (MT)',
  },
  {
    id: 'America/Los_Angeles',
    description: 'Pacific Time (PT)',
  },
];

export function getTimeInTimeZone(
  date: Date,
  timeZone: string
): { hour: number; minute: number; formattedDate: string } {
  const options: Intl.DateTimeFormatOptions = {
    timeZone: timeZone,
    hour: '2-digit',
    minute: '2-digit',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour12: false,
  };
  const formatter = new Intl.DateTimeFormat('en-US', options);
  const parts = formatter.formatToParts(date);

  let hour = 0;
  let minute = 0;
  let formattedDate = '';

  parts.forEach((part) => {
    if (part.type === 'hour') {
      hour = parseInt(part.value, 10);
    } else if (part.type === 'minute') {
      minute = parseInt(part.value, 10);
    } else if (part.type === 'day') {
      formattedDate = `${part.value}-${formattedDate}`;
    } else if (part.type === 'month') {
      formattedDate = `${part.value}-${formattedDate}`;
    } else if (part.type === 'year') {
      formattedDate = `${formattedDate}${part.value}`;
    }
  });

  return { hour, minute, formattedDate };
}
