import { createSelector } from '@ngrx/store';
import { State } from '..';
import { FacilityState } from '../state/facility.state';

export const getFacilityDataFeature = (state: State) => state.facilities;

export const getFacilityData = createSelector(getFacilityDataFeature, (state: FacilityState) => state.FacilityList);
export const getFacilityDataSuccess = createSelector(getFacilityDataFeature, (state: FacilityState) => state.FacilityListSuccess);
export const getFacilityDataError = createSelector(getFacilityDataFeature, (state: FacilityState) => state.FacilityListError);
export const getFacilityDataLoading = createSelector(getFacilityDataFeature, (state: FacilityState) => state.FacilityListLoading);

export const getFacilityResourcesData = createSelector(getFacilityDataFeature, (state: FacilityState) => state.FacilityResourcesList);
export const getFacilityResourcesDataSuccess = createSelector(getFacilityDataFeature, (state: FacilityState) => state.FacilityResourcesListSuccess);
export const getFacilityResourcesDataError = createSelector(getFacilityDataFeature, (state: FacilityState) => state.FacilityResourcesListError);
export const getFacilityResourcesDataLoading = createSelector(getFacilityDataFeature, (state: FacilityState) => state.FacilityResourcesListLoading);
