<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Eating Attitudes Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="eatingAttitudesForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >1. I am terrified about being overweight.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q1"
        >
          <mat-button-toggle value="Always">Always</mat-button-toggle>
          <mat-button-toggle value="Usually">Usually</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Never">Never</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >2. I avoid eating when I am hungry.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q2"
        >
          <mat-button-toggle value="Always">Always</mat-button-toggle>
          <mat-button-toggle value="Usually">Usually</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Never">Never</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >3. I find myself preoccupied with food.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q3"
        >
          <mat-button-toggle value="Always">Always</mat-button-toggle>
          <mat-button-toggle value="Usually">Usually</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Never">Never</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >4. I have gone on eating binges where I feel that I may not be able to
        stop.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q4"
        >
          <mat-button-toggle value="Always">Always</mat-button-toggle>
          <mat-button-toggle value="Usually">Usually</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Never">Never</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >5. I cut my food into small pieces.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q5"
        >
          <mat-button-toggle value="Always">Always</mat-button-toggle>
          <mat-button-toggle value="Usually">Usually</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Never">Never</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >6. I aware of the calorie content of foods that I eat.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q6"
        >
          <mat-button-toggle value="Always">Always</mat-button-toggle>
          <mat-button-toggle value="Usually">Usually</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Never">Never</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >7. I particularly avoid food with a high carbohydrate content (i.e.
        bread, rice, potatoes, etc.)</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q7"
        >
          <mat-button-toggle value="Always">Always</mat-button-toggle>
          <mat-button-toggle value="Usually">Usually</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Never">Never</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >8. I feel that others would prefer if I ate more.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q8"
        >
          <mat-button-toggle value="Always">Always</mat-button-toggle>
          <mat-button-toggle value="Usually">Usually</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Never">Never</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >9. I vomit after I have eaten.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q9"
        >
          <mat-button-toggle value="Always">Always</mat-button-toggle>
          <mat-button-toggle value="Usually">Usually</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Never">Never</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >10. I feel extremely guilty after eating.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q10"
        >
          <mat-button-toggle value="Always">Always</mat-button-toggle>
          <mat-button-toggle value="Usually">Usually</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Never">Never</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >11. I am occupied with a desire to be thinner.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q11"
        >
          <mat-button-toggle value="Always">Always</mat-button-toggle>
          <mat-button-toggle value="Usually">Usually</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Never">Never</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >12. I think about burning up calories when I exercise.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q12"
        >
          <mat-button-toggle value="Always">Always</mat-button-toggle>
          <mat-button-toggle value="Usually">Usually</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Never">Never</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >13. Other people think that I am too thin.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q13"
        >
          <mat-button-toggle value="Always">Always</mat-button-toggle>
          <mat-button-toggle value="Usually">Usually</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Never">Never</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >14. I am preoccupied with the thought of having fat on my body.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q14"
        >
          <mat-button-toggle value="Always">Always</mat-button-toggle>
          <mat-button-toggle value="Usually">Usually</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Never">Never</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >15. I take longer than others to eat my meals.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q15"
        >
          <mat-button-toggle value="Always">Always</mat-button-toggle>
          <mat-button-toggle value="Usually">Usually</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Never">Never</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >16. I avoid foods with sugar in them.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q16"
        >
          <mat-button-toggle value="Always">Always</mat-button-toggle>
          <mat-button-toggle value="Usually">Usually</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Never">Never</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">17. I eat diet foods.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q17"
        >
          <mat-button-toggle value="Always">Always</mat-button-toggle>
          <mat-button-toggle value="Usually">Usually</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Never">Never</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >18. I feel that food controls my life.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q18"
        >
          <mat-button-toggle value="Always">Always</mat-button-toggle>
          <mat-button-toggle value="Usually">Usually</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Never">Never</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >19. I display self-control around food.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q19"
        >
          <mat-button-toggle value="Always">Always</mat-button-toggle>
          <mat-button-toggle value="Usually">Usually</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Never">Never</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >20. I feel that others pressure me to eat.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q20"
        >
          <mat-button-toggle value="Always">Always</mat-button-toggle>
          <mat-button-toggle value="Usually">Usually</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Never">Never</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >21. I give too much time and thought to food.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q21"
        >
          <mat-button-toggle value="Always">Always</mat-button-toggle>
          <mat-button-toggle value="Usually">Usually</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Never">Never</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >22. I feel uncomfortable after eating sweets.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q22"
        >
          <mat-button-toggle value="Always">Always</mat-button-toggle>
          <mat-button-toggle value="Usually">Usually</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Never">Never</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >23. I engage in dieting behavior.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q23"
        >
          <mat-button-toggle value="Always">Always</mat-button-toggle>
          <mat-button-toggle value="Usually">Usually</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Never">Never</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >24. I like my stomach to be empty.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q24"
        >
          <mat-button-toggle value="Always">Always</mat-button-toggle>
          <mat-button-toggle value="Usually">Usually</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Never">Never</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >25. I have the impulse to vomit after meals.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q25"
        >
          <mat-button-toggle value="Always">Always</mat-button-toggle>
          <mat-button-toggle value="Usually">Usually</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Never">Never</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >26. I enjoy trying new rich foods.</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q26"
        >
          <mat-button-toggle value="Always">Always</mat-button-toggle>
          <mat-button-toggle value="Usually">Usually</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Never">Never</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >A. Gone on eating binges where you feel that you may not be able to
        stop? (Defined as eating much more than most people would under the same
        circumstances and feeling that eating is out of control.)</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q27"
        >
          <mat-button-toggle value="Never">Never</mat-button-toggle>
          <mat-button-toggle value="Once a month or less"
            >Once a month or less</mat-button-toggle
          >
          <mat-button-toggle value="2-3 times a month"
            >2-3 times a month</mat-button-toggle
          >
          <mat-button-toggle value="Once a week">Once a week</mat-button-toggle>
          <mat-button-toggle value="2-6 times a week"
            >2-6 times a week</mat-button-toggle
          >
          <mat-button-toggle value="Once a day or more"
            >Once a day or more</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >B. Ever made yourself sick (vomited) to control your weight or
        shape?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q28"
        >
          <mat-button-toggle value="Never">Never</mat-button-toggle>
          <mat-button-toggle value="Once a month or less"
            >Once a month or less</mat-button-toggle
          >
          <mat-button-toggle value="2-3 times a month"
            >2-3 times a month</mat-button-toggle
          >
          <mat-button-toggle value="Once a week">Once a week</mat-button-toggle>
          <mat-button-toggle value="2-6 times a week"
            >2-6 times a week</mat-button-toggle
          >
          <mat-button-toggle value="Once a day or more"
            >Once a day or more</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >C. Ever used laxatives, diet pills or diuretics (water pills) to
        control your weight or shape?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q29"
        >
          <mat-button-toggle value="Never">Never</mat-button-toggle>
          <mat-button-toggle value="Once a month or less"
            >Once a month or less</mat-button-toggle
          >
          <mat-button-toggle value="2-3 times a month"
            >2-3 times a month</mat-button-toggle
          >
          <mat-button-toggle value="Once a week">Once a week</mat-button-toggle>
          <mat-button-toggle value="2-6 times a week"
            >2-6 times a week</mat-button-toggle
          >
          <mat-button-toggle value="Once a day or more"
            >Once a day or more</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >D. Exercised more than 60 minutes a day to lose or to control your
        weight?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q30"
        >
          <mat-button-toggle value="Never">Never</mat-button-toggle>
          <mat-button-toggle value="Once a month or less"
            >Once a month or less</mat-button-toggle
          >
          <mat-button-toggle value="2-3 times a month"
            >2-3 times a month</mat-button-toggle
          >
          <mat-button-toggle value="Once a week">Once a week</mat-button-toggle>
          <mat-button-toggle value="2-6 times a week"
            >2-6 times a week</mat-button-toggle
          >
          <mat-button-toggle value="Once a day or more"
            >Once a day or more</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >E. Lost 20 pounds or more in the past 6 months?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q31"
        >
          <mat-button-toggle value="Yes">Yes</mat-button-toggle>
          <mat-button-toggle value="No">No</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >F. Have you ever been treated for an eating disorder?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q32"
        >
          <mat-button-toggle value="Yes">Yes</mat-button-toggle>
          <mat-button-toggle value="No">No</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          eatingAttitudesForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!eatingAttitudesForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
