import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { IAssessmentAlert } from 'src/app/_models/configure/assessment-alert.model';
import { AssessmentApiService, ToastMessageService } from 'src/app/_services';
import { AssessmentAlertHttpService } from 'src/app/_services/configure/assessment-alert.service';

@Component({
  selector: 'app-add-alert',
  templateUrl: './add-alert.component.html',
  styleUrls: ['./add-alert.component.css'],
})
export class AddAlertComponent implements OnInit {
  action: string = '';
  selectedAssessmentType: string = '';
  editAssessmentDetails: IAssessmentAlert;

  numbers = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];

  isLoadingResults: boolean = false;
  assessmentDetailError: boolean = false;
  assessmentDetails: any = [];

  // Mat-Stepper
  selectedQuestion: any;
  selectedAnswer: any = [];

  assessmentAlertForm: FormGroup;
  processing: boolean = false;
  showFollowUp: boolean = false;

  // Inerface
  assessmentAlert: IAssessmentAlert;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddAlertComponent>,
    public assessmentAlertApiService: AssessmentAlertHttpService,
    private formBuilder: FormBuilder,
    private assessmentApiService: AssessmentApiService,
    private toastMessageService: ToastMessageService
  ) {
    this.action = data.action;
    this.selectedAssessmentType = data.selectedAssessmentType;
    if (this.action === 'EDIT') {
      this.editAssessmentDetails = data.alertDetails;
    }
  }

  public ngOnInit() {
    this.buildForm();
    this.getAssessmentDetails();

    if (this.action === 'EDIT') {
      this.assessmentAlertForm.controls['id'].setValue(
        this.editAssessmentDetails.id
      );
      this.assessmentAlertForm.controls['organizationId'].setValue(
        this.editAssessmentDetails.organizationId
      );
      this.assessmentAlertForm.controls['careProviderId'].setValue(
        this.editAssessmentDetails.careProviderId
      );
      this.assessmentAlertForm.controls['assessmentType'].setValue(
        this.editAssessmentDetails.assessmentType
      );
      this.assessmentAlertForm.controls['question'].setValue(
        this.editAssessmentDetails.question
      );
      this.assessmentAlertForm.controls['answers'].setValue(
        this.editAssessmentDetails.answers
      );
      this.assessmentAlertForm.controls['message'].setValue(
        this.editAssessmentDetails.message
      );
      this.assessmentAlertForm.controls['smsAlerts'].setValue(
        this.editAssessmentDetails.smsAlerts
      );
      this.assessmentAlertForm.controls['emailAlerts'].setValue(
        this.editAssessmentDetails.emailAlerts
      );
      this.assessmentAlertForm.controls['status'].setValue(
        this.editAssessmentDetails.status
      );
    }
  }

  public getAssessmentDetails() {
    this.isLoadingResults = true;

    this.assessmentApiService
      .getAssessmentDetails(this.selectedAssessmentType)
      .subscribe(
        (response) => {
          this.isLoadingResults = false;
          if (response && response.data) {
            this.assessmentDetails = response.data.fields;
            // If the action is of type edit
            if (this.action === 'EDIT') {
              this.loadSelectedQA();
            }
          } else {
            this.assessmentDetailError = true;
            this.assessmentDetails = [];
          }
        },
        (error) => {
          this.isLoadingResults = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load assessment details.'
          );
        }
      );
  }

  loadSelectedQA() {
    let questionIndex = this.assessmentDetails.findIndex((question) => {
      if (
        question.title == this.assessmentAlertForm.controls['question'].value
      ) {
        return true;
      }
    });

    this.selectedQuestion = questionIndex;
    this.selectedAnswer = this.assessmentAlertForm.controls['answers'].value;
  }

  buildForm() {
    this.assessmentAlertForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(null),
      careProviderId: new FormControl(null),
      assessmentType: this.selectedAssessmentType,

      classfieldName: new FormControl(),
      question: new FormControl(),
      answers: new FormControl(),

      message: new FormControl('', Validators.compose([Validators.required])),
      smsAlerts: new FormControl(null),
      emailAlerts: new FormControl(null),

      status: new FormControl('', Validators.required),
    });
  }

  // Change the stepper to go to next step
  nextStep(stepper: MatStepper) {
    stepper.next();
  }

  // Submitting the alert form
  submitForm() {
    this.processing = true;

    this.assessmentAlert = Object.assign({}, this.assessmentAlert);
    this.assessmentAlert = Object.assign(
      this.assessmentAlert,
      this.assessmentAlertForm.value
    );

    this.assessmentAlert.question =
      this.assessmentDetails[this.selectedQuestion].title;
    this.assessmentAlert.classfieldName =
      this.assessmentDetails[this.selectedQuestion].name;

    this.assessmentAlert.answers = this.selectedAnswer;

    if (this.action === 'ADD') {
      this.assessmentAlertApiService
        .addAssessmentAlert(this.assessmentAlert)
        .subscribe(
          (response) => {
            this.processing = false;
            this.toastMessageService.displaySuccessMessage(
              'New alert has been added.'
            );
            this.dialogRef.close('success');
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to add new alert.'
            );
          }
        );
    } else if (this.action === 'EDIT') {
      this.assessmentAlertApiService
        .updateAssessmentAlert(this.assessmentAlert)
        .subscribe(
          (response) => {
            this.processing = false;
            this.toastMessageService.displaySuccessMessage(
              'Alert has been updated.'
            );
            this.dialogRef.close('success');
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to update the alert.'
            );
          }
        );
    }
  }
}
