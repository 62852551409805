<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Complicated Grief Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="complicatedGriefForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >1. I think about this person so much that it's hard for me to do
            the things I normally do</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="thinkAboutThisPerson">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >2. Memories of the person who died upset me</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="memoriesOfThePerson">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >3. I feel I cannot accept the death of the person who died</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="cannotAcceptTheDeath">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >4. I feel myself longing for the person who died</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="longingForThePerson">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >5. I feel drawn to places and things associated with the person who
            died</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="thingsAssociatedWithThePersonWhoDied"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >6. I can't help feeling angry about his/her death</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="feelingAngryAboutTheirDeath"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >7. I feel disbelief over what happened</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="feelDisbelief">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >8. I feel stunned or dazed over what happened</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="feelStunnedOrDazed">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >9. Ever since he/she died, it is hard for me to trust
            people...</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="hardForMeToTrustPeople">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >10. Ever since he/she died, I feel as if I have lost the ability to
            care about other people or I feel distant from people I care
            about</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="lostTheAbilityToCareAboutOtherPeople"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >11. I feel lonely a great deal of the time ever since he/she
            died</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="feelLonelyAGreatDealOfTheTime"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >12. I have pain in the same area of my body or have some of the
            same symptoms as the person who died</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="havePain">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >13. I got out of my way to avoid reminders of the person who
            died</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="avoidRemindersOfThePersonWhoDied"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >14. I feel that life is empty without the person who died</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="lifeIsEmptyWithoutThePersonWhoDied"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >15. I hear the voice of the person who died speak to me</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="personWhoDiedSpeakToMe">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >16. I see the person who died stand before me</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="thePersonWhoDiedStandBeforeMe"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >17. I feel that it is unfair that I should live when this person
            died</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="unfairThatIShouldLive">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >18. I feel bitter over this person's death</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="bitterOverThisPersonsDeath"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >19. I feel envious of other who have not lost someone close</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="enviousOfOtherWhoHaveNotLostSomeoneClose"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          complicatedGriefForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!complicatedGriefForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
