import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-video-iframe',
  templateUrl: './video-iframe.component.html',
  styleUrl: './video-iframe.component.css',
})
export class VideoIframeComponent implements OnInit {
  iframeMeeting = '';
  hostUrl = '';

  constructor(
    public dialogRef: MatDialogRef<VideoIframeComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.iframeMeeting = data.iframeMeeting;
    this.hostUrl = data.hostUrl;
  }

  ngOnInit(): void {}

  closeVideo() {
    this.dialogRef.close();
  }
}
