<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>TAPS-I & TAPS-II</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <form
    [formGroup]="tapsAssessmentForm"
    novalidate
    (ngSubmit)="onTapsFormSubmit()"
    class="form-horizontal"
    *ngIf="patient"
  >
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >1. In the PAST 12 MONTHS, how often have you used any tobacco product
          (for example, cigarettes, ecigarettes, cigars, pipes, or smokeless
          tobacco)?
        </label>
        <div class="col-sm-6">
          <mat-button-toggle-group
            formControlName="tobaccoUse12M"
            (change)="setSubsequentQuestions('tobaccoUse12M')"
          >
            <mat-button-toggle value="Daily or Almost Daily"
              >Daily or Almost Daily</mat-button-toggle
            >
            <mat-button-toggle value="Weekly">Weekly</mat-button-toggle>
            <mat-button-toggle value="Monthly">Monthly</mat-button-toggle>
            <mat-button-toggle value="Less Than Monthly"
              >Less Than Monthly</mat-button-toggle
            >
            <mat-button-toggle value="Never">Never</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <div
        class="row pad10"
        *ngIf="patient.gender === 'MALE' || patient.gender === 'M'"
      >
        <label class="col-md-12 asterix-after"
          >2. In the PAST 12 MONTHS, how often have you had 5 or more drinks
          containing alcohol in one day? One standard drink is about 1 small
          glass of wine (5 oz), 1 beer (12 oz), or 1 single shot of
          liquor.</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group
            formControlName="male5Drinks12M"
            (change)="setSubsequentQuestions('male5Drinks12M')"
          >
            <mat-button-toggle value="Daily or Almost Daily"
              >Daily or Almost Daily</mat-button-toggle
            >
            <mat-button-toggle value="Weekly">Weekly</mat-button-toggle>
            <mat-button-toggle value="Monthly">Monthly</mat-button-toggle>
            <mat-button-toggle value="Less Than Monthly"
              >Less Than Monthly</mat-button-toggle
            >
            <mat-button-toggle value="Never">Never</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <div
        class="row pad10"
        *ngIf="patient.gender === 'FEMALE' || patient.gender === 'F'"
      >
        <label class="col-md-12 asterix-after"
          >2. In the PAST 12 MONTHS, how often have you had 4 or more drinks
          containing alcohol in one day? One standard drink is about 1 small
          glass of wine (5 oz), 1 beer (12 oz), or 1 single shot of liquor.
        </label>
        <div class="col-sm-6">
          <mat-button-toggle-group
            formControlName="female4Drinks12M"
            (change)="setSubsequentQuestions('female4Drinks12M')"
          >
            <mat-button-toggle value="Daily or Almost Daily"
              >Daily or Almost Daily</mat-button-toggle
            >
            <mat-button-toggle value="Weekly">Weekly</mat-button-toggle>
            <mat-button-toggle value="Monthly">Monthly</mat-button-toggle>
            <mat-button-toggle value="Less Than Monthly"
              >Less Than Monthly</mat-button-toggle
            >
            <mat-button-toggle value="Never">Never</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >3. In the PAST 12 MONTHS, how often have you used any drugs including
          marijuana, cocaine or crack, heroin, methamphetamine (crystal meth),
          hallucinogens, ecstasy/MDMA?
        </label>
        <div class="col-sm-6">
          <mat-button-toggle-group
            formControlName="drugUse12M"
            (change)="setSubsequentQuestions('drugUse12M')"
          >
            <mat-button-toggle value="Daily or Almost Daily"
              >Daily or Almost Daily</mat-button-toggle
            >
            <mat-button-toggle value="Weekly">Weekly</mat-button-toggle>
            <mat-button-toggle value="Monthly">Monthly</mat-button-toggle>
            <mat-button-toggle value="Less Than Monthly"
              >Less Than Monthly</mat-button-toggle
            >
            <mat-button-toggle value="Never">Never</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >4. In the PAST 12 MONTHS, how often have you used any prescription
          medications just for the feeling, more than prescribed or that were
          not prescribed for you? Prescription medications that may be used this
          way include: Opiate pain relievers (for example, OxyContin, Vicodin,
          Percocet, Methadone) Medications for anxiety or sleeping (for example,
          Xanax, Ativan, Klonopin) Medications for ADHD (for example, Adderall
          or Ritalin)</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group
            formControlName="rxFeelMoreUse12M"
            (change)="setSubsequentQuestions('rxFeelMoreUse12M')"
          >
            <mat-button-toggle value="Daily or Almost Daily"
              >Daily or Almost Daily</mat-button-toggle
            >
            <mat-button-toggle value="Weekly">Weekly</mat-button-toggle>
            <mat-button-toggle value="Monthly">Monthly</mat-button-toggle>
            <mat-button-toggle value="Less Than Monthly"
              >Less Than Monthly</mat-button-toggle
            >
            <mat-button-toggle value="Never">Never</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>

    <mat-card
      appearance="raised"
      class="pad20 mat-elevation-z8"
      *ngIf="
        requestTobaccoQstns ||
        requestAlcoholQstns ||
        requestPrescriptionQstns ||
        requestSubstanceQstns
      "
    >
      <div
        *ngIf="
          tapsAssessmentForm.controls['tobaccoUse12M'].value !== 'Never' &&
          tapsAssessmentForm.controls['tobaccoUse12M'].value !== ''
        "
      >
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >1. In the PAST 3 MONTHS, did you smoke a cigarette containing
            tobacco?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="past3MoDdYSmkCgrt"
              (change)="setSubsequentQuestions('past3MoDdYSmkCgrt')"
            >
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div
          class="row pad10"
          *ngIf="
            tapsAssessmentForm.controls['past3MoDdYSmkCgrt'].value === 'Yes'
          "
        >
          <label class="col-md-3 asterix-after"
            >a. In the PAST 3 MONTHS, did you usually smoke more than 10
            cigarettes each day?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="past3MoDdYsllySmk">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div
          class="row pad10"
          *ngIf="
            tapsAssessmentForm.controls['past3MoDdYSmkCgrt'].value === 'Yes'
          "
        >
          <label class="col-md-3 asterix-after"
            >b. In the PAST 3 MONTHS, did you usually smoke within 30 minutes
            after waking?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="past3MoDdYsllySmk1">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>

      <div
        *ngIf="
          (tapsAssessmentForm.controls['male5Drinks12M'].value !== 'Never' &&
            tapsAssessmentForm.controls['male5Drinks12M'].value !== '') ||
          (tapsAssessmentForm.controls['female4Drinks12M'].value !== 'Never' &&
            tapsAssessmentForm.controls['female4Drinks12M'].value !== '')
        "
      >
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >2. In the PAST 3 MONTHS, did you have a drink containing
            alcohol?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="past3MoDdYHvDrnkC"
              (change)="setSubsequentQuestions('past3MoDdYHvDrnkC')"
            >
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div
          class="row pad10"
          *ngIf="
            tapsAssessmentForm.controls['past3MoDdYHvDrnkC'].value === 'Yes' &&
            (patient.gender === 'FEMALE' || patient.gender === 'F')
          "
        >
          <label class="col-md-3 asterix-after"
            >a. In the PAST 3 MONTHS, did you have 4 or more drinks containing
            alcohol in a day?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="past3MoDdYHv4rMrD">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div
          class="row pad10"
          *ngIf="
            tapsAssessmentForm.controls['past3MoDdYHvDrnkC'].value === 'Yes' &&
            (patient.gender === 'MALE' || patient.gender === 'M')
          "
        >
          <label class="col-md-3 asterix-after"
            >b. In the PAST 3 MONTHS, did you have 5 or more drinks containing
            alcohol in a day?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="past3MoDdYHv5rMrD">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div
          class="row pad10"
          *ngIf="
            tapsAssessmentForm.controls['past3MoDdYHvDrnkC'].value === 'Yes'
          "
        >
          <label class="col-md-3 asterix-after"
            >c. In the PAST 3 MONTHS, have you tried and failed to control, cut
            down or stop drinking?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="past3MoHvYTrdndFlCtDwnrStpD"
            >
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div
          class="row pad10"
          *ngIf="
            tapsAssessmentForm.controls['past3MoDdYHvDrnkC'].value === 'Yes'
          "
        >
          <label class="col-md-3 asterix-after"
            >d. In the PAST 3 MONTHS, has anyone expressed concern about your
            drinking?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="past3MoHsnynxprss">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>

      <div
        *ngIf="
          tapsAssessmentForm.controls['drugUse12M'].value !== 'Never' &&
          tapsAssessmentForm.controls['drugUse12M'].value !== ''
        "
      >
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >3. In the PAST 3 MONTHS, did you use marijuana (hash, weed)?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="past3MoDdYsMrjnHsWeed"
              (change)="setSubsequentQuestions('past3MoDdYsMrjnHsWeed')"
            >
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div
          class="row pad10"
          *ngIf="
            tapsAssessmentForm.controls['past3MoDdYsMrjnHsWeed'].value === 'Yes'
          "
        >
          <label class="col-md-3 asterix-after"
            >a. In the PAST 3 MONTHS, have you had a strong desire or urge to
            use marijuana at least once a week or more often?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="past3MoHvYHdStrng">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div
          class="row pad10"
          *ngIf="
            tapsAssessmentForm.controls['past3MoDdYsMrjnHsWeed'].value === 'Yes'
          "
        >
          <label class="col-md-3 asterix-after"
            >b. In the PAST 3 MONTHS, has anyone expressed concern about your
            use of marijuana?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="past3MoHsnynxprss1">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >4. In the PAST 3 MONTHS, did you use cocaine, crack, or
            methamphetamine (crystal meth)?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="past3MoDdYsCcnCrackrMthmpht"
              (change)="setSubsequentQuestions('past3MoDdYsCcnCrackrMthmpht')"
            >
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div
          class="row pad10"
          *ngIf="
            tapsAssessmentForm.controls['past3MoDdYsCcnCrackrMthmpht'].value ===
            'Yes'
          "
        >
          <label class="col-md-3 asterix-after"
            >a. In the PAST 3 MONTHS, did you use cocaine, crack, or
            methamphetamine (crystal meth) at least once a week or more
            often?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="past3MoDdYsCcnCrackrMthmpht1"
            >
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div
          class="row pad10"
          *ngIf="
            tapsAssessmentForm.controls['past3MoDdYsCcnCrackrMthmpht'].value ===
            'Yes'
          "
        >
          <label class="col-md-3 asterix-after"
            >b. In the PAST 3 MONTHS, has anyone expressed concern about your
            use of cocaine, crack, or methamphetamine (crystal meth)?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="past3MoHsnynxprssCrackrMthm"
            >
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >5. In the PAST 3 MONTHS, did you use heroin?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="past3MoDdYsHrn"
              (change)="setSubsequentQuestions('past3MoDdYsHrn')"
            >
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div
          class="row pad10"
          *ngIf="tapsAssessmentForm.controls['past3MoDdYsHrn'].value === 'Yes'"
        >
          <label class="col-md-3 asterix-after"
            >a. In the PAST 3 MONTHS, have you tried and failed to control, cut
            down or stop using heroin?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="past3MoHvYTrdndFlCtDwnrStps"
            >
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div
          class="row pad10"
          *ngIf="tapsAssessmentForm.controls['past3MoDdYsHrn'].value === 'Yes'"
        >
          <label class="col-md-3 asterix-after"
            >b. In the PAST 3 MONTHS, has anyone expressed concern about your
            use of heroin?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="past3MoHsnynxprss2">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>

      <div
        *ngIf="
          tapsAssessmentForm.controls['rxFeelMoreUse12M'].value !== 'Never' &&
          tapsAssessmentForm.controls['rxFeelMoreUse12M'].value !== ''
        "
      >
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >6. In the PAST 3 MONTHS, did you use a prescription opiate pain
            reliever (for example, Percocet, Vicodin) not as prescribed or that
            was not prescribed for you?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="past3MoDdYsPrscrp"
              (change)="setSubsequentQuestions('past3MoDdYsPrscrp')"
            >
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div
          class="row pad10"
          *ngIf="
            tapsAssessmentForm.controls['past3MoDdYsPrscrp'].value === 'Yes'
          "
        >
          <label class="col-md-3 asterix-after"
            >a. In the PAST 3 MONTHS, have you tried and failed to control, cut
            down or stop using an opiate pain reliever?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="past3MoHvYTrdndFlCtDwnrSt1"
            >
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div
          class="row pad10"
          *ngIf="
            tapsAssessmentForm.controls['past3MoDdYsPrscrp'].value === 'Yes'
          "
        >
          <label class="col-md-3 asterix-after"
            >b. In the PAST 3 MONTHS, has anyone expressed concern about your
            use of an opiate pain reliever?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="past3MoHsnynxprss3">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >7. In the PAST 3 MONTHS, did you use a medication for anxiety or
            sleep (for example, Xanax, Ativan, or Klonopin) not as prescribed or
            that was not prescribed for you?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="past3MoDdYsMdctnF"
              (change)="setSubsequentQuestions('past3MoDdYsMdctnF')"
            >
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div
          class="row pad10"
          *ngIf="
            tapsAssessmentForm.controls['past3MoDdYsMdctnF'].value === 'Yes'
          "
        >
          <label class="col-md-3 asterix-after"
            >a. In the PAST 3 MONTHS, have you had a strong desire or urge to
            use medications for anxiety or sleep at least once a week or more
            often?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="past3MoHvYHdStrng1">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div
          class="row pad10"
          *ngIf="
            tapsAssessmentForm.controls['past3MoDdYsMdctnF'].value === 'Yes'
          "
        >
          <label class="col-md-3 asterix-after"
            >b. In the PAST 3 MONTHS, has anyone expressed concern about your
            use of medication for anxiety or sleep?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="past3MoHsnynxprss4">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >8. In the PAST 3 MONTHS, did you use a medication for ADHD (for
            example, Adderall, Ritalin) not as prescribed or that was not
            prescribed for you?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="past3MoDdYsMdctnF1"
              (change)="setSubsequentQuestions('past3MoDdYsMdctnF1')"
            >
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div
          class="row pad10"
          *ngIf="
            tapsAssessmentForm.controls['past3MoDdYsMdctnF1'].value === 'Yes'
          "
        >
          <label class="col-md-3 asterix-after"
            >a. In the PAST 3 MONTHS, did you use a medication for ADHD (for
            example, Adderall, Ritalin) at least once a week or more
            often?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="past3MoDdYsMdctnF2">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div
          class="row pad10"
          *ngIf="
            tapsAssessmentForm.controls['past3MoDdYsMdctnF1'].value === 'Yes'
          "
        >
          <label class="col-md-3 asterix-after"
            >b. In the PAST 3 MONTHS, has anyone expressed concern about your
            use of a medication for ADHD (for example, Adderall or
            Ritalin)?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="past3MoHsnynxprss5">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >9. In the PAST 3 MONTHS, did you use any other illegal or
            recreational drug (for example, ecstasy/molly, GHB, poppers, LSD,
            mushrooms, special K, bath salts, synthetic marijuana ('spice'),
            whip-its, etc.)?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="past3MoDdYsnythrl"
              (change)="setSubsequentQuestions('past3MoDdYsnythrl')"
            >
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div
          class="row pad10"
          *ngIf="
            tapsAssessmentForm.controls['past3MoDdYsnythrl'].value === 'Yes'
          "
        >
          <div class="col-md-6">
            <mat-form-field appearance="outline">
              <mat-label
                >In the PAST 3 MONTHS, what were the other drug(s) you
                used?</mat-label
              >
              <input
                matInput
                placeholder="In the PAST 3 MONTHS, what were the other drug(s) you used?"
                formControlName="past3MoWhtWrThthr"
                name="past3MoWhtWrThthr"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-card>

    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          tapsAssessmentForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!tapsAssessmentForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
