import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoreService } from 'src/app/_services';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';

@Component({
  selector: 'app-patient-dashboard',
  templateUrl: './patient-dashboard.component.html',
  styleUrls: ['./patient-dashboard.component.css'],
})
export class PatientDashboardComponent implements OnInit {
  mainTab: string = 'Home';
  secondaryId: string;

  teleHealthEnabled: boolean = false;
  orgConfig: any;

  isSSOView: boolean = false;

  constructor(
    private orgConfigSandbox: OrgConfigSandbox,
    private coreService: CoreService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // Determine if this flow is coming from SSO
    this.isSSOView = this.coreService.isSSOUser();

    this.route.params.subscribe((params) => {
      this.mainTab = this.route.snapshot.paramMap.get('tabId');
      this.secondaryId = this.route.snapshot.paramMap.get('secondaryId');
    });

    // Check For virtual appointment
    // Check organization config
    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          this.orgConfig = orgConfig;
          if (this.orgConfig && this.orgConfig?.features) {
            this.teleHealthEnabled =
              this.orgConfig?.features.includes('TELEHEALTH_ENABLED');
          }
        });
      }
    });
  }
}
