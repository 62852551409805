<div class="container">
  <!-- Heading -->
  <div class="dialogTitle">
    <p matDialogTitle>{{ assessmentRequest.assessmentType }} Goals</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      [mat-dialog-close]="reloadRequired"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <!-- Add Button -->
  <div class="row" style="margin-top: 20px; margin-bottom: 15px">
    <div class="col-md-6">
      <label>Patient</label>:
      <label class="labelText">{{
        assessmentRequest.patientLastName
          ? assessmentRequest.patientLastName +
            ', ' +
            assessmentRequest.patientFirstName
          : assessmentRequest.patientName
      }}</label>
    </div>
    <div class="col-md-6" style="text-align: right">
      <button mat-raised-button color="warn" type="button" (click)="addGoal()">
        + Add Goal
      </button>
    </div>
  </div>
  <!-- Body -->
  <div class="gridContainer">
    <mat-card
      appearance="raised"
      class="mat-elevation-z8"
      *ngFor="let goal of goalsList; let i = index"
      style="border-radius: 20px"
    >
      <span
        class="material-icons"
        style="
          color: gray;
          position: absolute;
          right: 15px;
          top: 20px;
          font-size: 17px;
          cursor: pointer;
          border-radius: 50%;
          z-index: 2;
          border: 2px solid darkgray;
        "
        [matMenuTriggerFor]="menu"
      >
        more_vert
      </span>

      <mat-menu #menu="matMenu">
        <button mat-menu-item type="button" (click)="editGoal(goal)">
          <mat-icon style="color: #f39121"> edit </mat-icon>
          <span>Edit</span>
        </button>
        <button mat-menu-item type="button" (click)="deleteGoal(goal)">
          <mat-icon style="color: red"> delete_forever </mat-icon>
          <span>Delete</span>
        </button>
      </mat-menu>

      <label class="goalHeader"> Goal: {{ i + 1 }} </label>

      <div class="goalContainer">
        <label class="labelHeader">Description </label>
        <label class="labelText">
          {{ goal.description }}
        </label>

        <label class="labelHeader">Target </label>
        <label class="labelText">
          {{ goal.targetNumber }} {{ goal.targetType }}
        </label>

        <label class="labelHeader">Follow Up </label>
        <label class="labelText">
          {{ goal.followUp }}
        </label>

        <label *ngIf="goal.followUp === 'Yes'" class="labelHeader"
          >Interval
        </label>
        <label *ngIf="goal.followUp === 'Yes'" class="labelText">
          {{ goal.followUpNumber }}
          {{ goal.followUpType }}
        </label>
      </div>
    </mat-card>
  </div>
</div>
