<div class="row">
  <div class="col-md-12">
    <p style="font-size: 16px; font-weight: 450">
      <a routerLink="/" routerLinkActive="active">
        <em
          class="material-icons text_align-center"
          style="font-size: 18px; margin-top: -3px"
          >home</em
        >
      </a>
      &nbsp; / &nbsp;
      <span style="color: dimgray">{{ status }} Notes</span>
    </p>
  </div>
</div>

<!-- Notes Details -->
<app-sessions
  *ngIf="permissionsLoaded"
  [status]="status"
  [orgConfig]="orgConfig"
  [hasMemberPaymentsAddAccess]="hasMemberPaymentsAddAccess"
  [hasTherapySessionEditAccess]="hasTherapySessionEditAccess"
>
</app-sessions>
