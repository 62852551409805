<mat-card-title>Family / Social Relationships</mat-card-title>
<mat-divider></mat-divider>

<div style="margin-top: 40px; padding: 10px">
  <form [formGroup]="asifamilysocialrelationshipsForm" novalidate
    (ngSubmit)="onAsiFamilySocialRelationshipsFormSubmit()">
    <div class="row">
      <!-- Question Box -->
      <div class="col-sm-12 col-xl-8">
        <mat-card appearance="raised" class="mat-elevation-z8" style="margin-bottom: 20px">
          <div class="col-md-12 pad10">
            <label class="labelText">F1. Marital Status </label>
            <br />
            <mat-button-toggle-group formControlName="f1" vertical>
              <mat-button-toggle value="Married">Married</mat-button-toggle>
              <mat-button-toggle value="Remarried">Remarried</mat-button-toggle>
              <mat-button-toggle value="Widowed">Widowed</mat-button-toggle>
              <mat-button-toggle value="Separated">Separated</mat-button-toggle>
              <mat-button-toggle value="Divorced">Divorced</mat-button-toggle>
              <mat-button-toggle value="Never married">Never married</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-md-12">
            <label class="labelText">F2. How long have you been in this marital status?</label>

            <mat-form-field appearance="outline">
              <input matInput formControlName="f2_years" id="f2_years" placeholder="Years" required />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <input matInput formControlName="f2_months" id="f2_months" placeholder="Months" />
            </mat-form-field>
          </div>

          <div class="col-md-12 pad10">
            <label class="labelText breakWord">F3. Are you satisfied with this situation?
            </label>
            <br />
            <mat-button-toggle-group formControlName="f3">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Indifferent">Indifferent</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-md-12 pad10">
            <label class="labelText breakWord">F4. Usual living arrangements (past 3 years)
            </label>
            <br />
            <mat-button-toggle-group formControlName="f4" vertical>
              <mat-button-toggle value="With sexual partner & children">With sexual partner &
                children</mat-button-toggle>
              <mat-button-toggle value="With sexual partner alone">With sexual partner alone</mat-button-toggle>
              <mat-button-toggle value="With children alone">With children alone</mat-button-toggle>
              <mat-button-toggle value="With parents">With parents</mat-button-toggle>
              <mat-button-toggle value="With family">With family</mat-button-toggle>
              <mat-button-toggle value="With friends">With friends</mat-button-toggle>
              <mat-button-toggle value="Alone">Alone</mat-button-toggle>
              <mat-button-toggle value="Controlled environment">Controlled environment</mat-button-toggle>
              <mat-button-toggle value="No stable arrangement">No stable arrangement</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-md-12">
            <label class="labelText">F5. How long have you lived in these arrangemenets?</label>

            <mat-form-field appearance="outline">
              <input matInput formControlName="f5_years" id="f5_years" placeholder="Years" required />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <input matInput formControlName="f5_months" id="f5_months" placeholder="Months" required />
            </mat-form-field>
          </div>

          <div class="col-md-12 pad10">
            <label class="labelText breakWord">F6. Are you satisfied with these arrangements?</label>
            <br />
            <mat-button-toggle-group formControlName="f6">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Indifferent">Indifferent</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-md-12 pad10">
            <label class="labelText breakWord">F7. Has a current alcohol problem?</label>
            <br />
            <mat-button-toggle-group formControlName="f7">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-md-12 pad10">
            <label class="labelText breakWord">F8. Uses non-prescribed drugs?
            </label>
            <br />
            <mat-button-toggle-group formControlName="f8">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-md-12 pad10">
            <label class="labelText breakWord">F9. With whom do you spend most of your free time?</label>
            <br />
            <mat-button-toggle-group formControlName="f9">
              <mat-button-toggle value="Family">Family</mat-button-toggle>
              <mat-button-toggle value="Friends">Friends</mat-button-toggle>
              <mat-button-toggle value="Alone">Alone</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-md-12 pad10">
            <label class="labelText breakWord">F10. Are you satisfied with spending your free time this way?
            </label>
            <br />
            <mat-button-toggle-group formControlName="f10">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Indifferent">Indifferent</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-md-12">
            <label class="labelText">F11. How many close friends do you have?</label>

            <mat-form-field appearance="outline">
              <input matInput formControlName="f11" id="f11" required />
            </mat-form-field>
          </div>

          <div class="col-md-12 pad10">
            <label class="labelText">F12. Mother </label>
            <br />
            <mat-button-toggle-group formControlName="f12">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-md-12 pad10">
            <label class="labelText">F13. Father </label>
            <br />
            <mat-button-toggle-group formControlName="f13">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-md-12 pad10">
            <label class="labelText">F14. Brothers/Sisters</label>
            <br />
            <mat-button-toggle-group formControlName="f14">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-md-12 pad10">
            <label class="labelText breakWord">F15. Sexual Partner/Spouse</label>
            <br />
            <mat-button-toggle-group formControlName="f15">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-md-12 pad10">
            <label class="labelText">F16. Children</label>
            <br />
            <mat-button-toggle-group formControlName="f16">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-md-12 pad10">
            <label class="labelText">F17. Friends</label>
            <br />
            <mat-button-toggle-group formControlName="f17" vertical>
              <mat-button-toggle value="Clearly NO for all in class">Clearly NO for all in class</mat-button-toggle>
              <mat-button-toggle value="Clearly YES for any in class">Clearly YES for any in class</mat-button-toggle>
              <mat-button-toggle value="Uncertain or “I don’t know”">Uncertain or “I don’t know”</mat-button-toggle>
              <mat-button-toggle value="Never was a relative ">Never was a relative
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-md-12">
            <label class="labelHeader">Have you had significant periods in which you have experienced
              serious problems getting along with</label>
            <div class="row">
              <!-- Template -->
              <div class="col-6"></div>
              <div class="col-3">
                <label class="labelHeader breakWord">Past 30 Days</label>
              </div>
              <div class="col-3">
                <label class="labelHeader breakWord">In Your Life</label>
              </div>
              <!-- Questions -->
              <div class="col-6">
                <label class="labelText breakWord">F18. Mother</label>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="f18_past30">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="f18_inlife">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>

              <div class="col-6">
                <label class="labelText breakWord">F19. Father</label>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="f19_past30">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="f19_inlife">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>

              <div class="col-6">
                <label class="labelText breakWord">F20. Brothers/Sister</label>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="f20_past30">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="f20_inlife">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>

              <div class="col-6">
                <label class="labelText breakWord">F21. Sexual Partner/Spouse</label>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="f21_past30">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="f21_inlife">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>

              <div class="col-6">
                <label class="labelText breakWord">F22. Children</label>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="f22_past30">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="f22_inlife">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>

              <div class="col-6">
                <label class="labelText breakWord">F23. Other significant family</label>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="f23_past30">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="f23_inlife">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>

              <div class="col-6">
                <label class="labelText breakWord">F24. Close Friends</label>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="f24_past30">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="f24_inlife">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>

              <div class="col-6">
                <label class="labelText breakWord">F25. Neighbors</label>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="f25_past30">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="f25_inlife">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>

              <div class="col-6">
                <label class="labelText breakWord">F26. Co-Workers</label>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="f26_past30">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="f26_inlife">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <label class="labelHeader">Has anyone ever abused you?</label>
            <div class="row">
              <!-- Template -->
              <div class="col-6"></div>
              <div class="col-3">
                <label class="labelHeader breakWord"> Past 30 Days </label>
              </div>
              <div class="col-3">
                <label class="labelHeader breakWord"> In Your Life </label>
              </div>
              <!-- Questions -->
              <div class="col-6">
                <label class="labelText breakWord">F27. Emotionally?</label>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="f27_past30">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="f27_inlife">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>

              <div class="col-6">
                <label class="labelText breakWord">F28. Physically?</label>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="f28_past30">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="f28_inlife">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>

              <div class="col-6">
                <label class="labelText breakWord">F29. Sexually?</label>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="f29_past30">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="f29_inlife">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <label class="labelHeader">How many days in the past 30 have you had serious
              conflicts?</label>
            <div class="row">
              <!-- Template -->
              <div class="col-9">
                <label class="labelText breakWord">F30. With your family?
                </label>
              </div>
              <div class="col-3">
                <mat-form-field appearance="outline">
                  <input matInput formControlName="f30" id="f30" type="number" required />
                </mat-form-field>
              </div>

              <div class="col-9">
                <label class="labelText breakWord">F31. With other people?
                </label>
              </div>
              <div class="col-3">
                <mat-form-field appearance="outline">
                  <input matInput formControlName="f31" id="f31" type="number" required />
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <label class="labelHeader">How troubled or bothered have you been in the past 30 days
              by</label>
            <div class="row">
              <!-- Template -->
              <div class="col-9">
                <label class="labelText breakWord">F32. Family problems </label>
              </div>
              <div class="col-3">
                <mat-form-field appearance="outline">
                  <input matInput formControlName="f32" id="f32" required />
                </mat-form-field>
              </div>

              <div class="col-9">
                <label class="labelText breakWord">F33. Social problems</label>
              </div>
              <div class="col-3">
                <mat-form-field appearance="outline">
                  <input matInput formControlName="f33" id="f33" required />
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <label class="labelHeader">How important to you now is treatment or counseling for
              these:</label>
            <div class="row">
              <div class="col-9">
                <label class="labelText breakWord">F34. Family problems</label>
              </div>
              <div class="col-3">
                <mat-form-field appearance="outline">
                  <input matInput formControlName="f34" id="f34" required />
                </mat-form-field>
              </div>

              <div class="col-9">
                <label class="labelText breakWord">F35. Social problems </label>
              </div>
              <div class="col-3">
                <mat-form-field appearance="outline">
                  <input matInput formControlName="f35" id="f35" required />
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <label class="labelText">F36. How would you rate the patient's need for family and/ or
              social counseling? <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="f36" name="f36" required />
              <mat-error *ngFor="let validation of validation_messages.f36">
                <mat-error class="error-message" *ngIf="
                    asifamilysocialrelationshipsForm
                      .get('f36')
                      .hasError(validation.type) &&
                    (asifamilysocialrelationshipsForm.get('f36').dirty ||
                      asifamilysocialrelationshipsForm.get('f36').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-12">
            <label class="labelHeader">Is the above information significantly distorted by</label>
            <div class="row">
              <div class="col-9">
                <label class="labelText breakWord">F37.Client's misrepresentation?</label>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="f37">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>

              <div class="col-9">
                <label class="labelText breakWord">F38. Client's inability to understand?</label>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="f38">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
      <!-- Comment Box -->
      <div class="col-sm-12 col-xl-4">
        <div class="row">
          <div class="col-sm-6">
            <mat-card class="custom-score-card mat-elevation-z8" appearance="raised">
              <label class="labelText">Clinician Score <span style="color: red">*</span></label>
              <mat-card-content>
                <mat-form-field appearance="outline" style="width:100px">
                  <input type="text" formControlName="clinicianScore" maxlength="1"
                    style="font-size:48px; font-weight: 900 !important" pattern="[0-9]" matInput required />
                </mat-form-field>
              </mat-card-content>
              <mat-error *ngFor="let validation of validation_messages.clinicianScore">
                <mat-error class="error-message" *ngIf="
                      asifamilysocialrelationshipsForm.get('clinicianScore').hasError(validation.type) &&
                      (asifamilysocialrelationshipsForm.get('clinicianScore').dirty ||
                        asifamilysocialrelationshipsForm.get('clinicianScore').touched)
                    ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-card>
          </div>
          <div class="col-sm-6">
            <mat-card class="custom-score-card mat-elevation-z8" appearance="raised">
              <label class="labelText" style="justify-content: center;">System Score</label>
              <mat-card-content>
                <span class="width:100px; font-size:48px; font-weight: 900 !important">
                  {{systemGeneratedScore}}
                </span>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div class="row" style="margin-top:15px">
          <div class="col-sm-12">

            <mat-card appearance="raised" class="mat-elevation-z8">
              <div class="row">
                <div class="col-md-12">
                  <label class="labelHeader">Comments</label>
                  <mat-form-field appearance="outline">
                    <textarea formControlName="comments" matInput rows="50"></textarea>
                  </mat-form-field>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end">
      <button [class]="
          asifamilysocialrelationshipsForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        " mat-raised-button type="submit" [disabled]="!asifamilysocialrelationshipsForm.valid || processing">
        Submit
      </button>
    </mat-dialog-actions>
  </form>
</div>