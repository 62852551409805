import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IAsiFamilyHistory } from 'src/app/_models/assessments/asi/asifamilyhistory.model';
import { AsiApiService } from 'src/app/_services/assessments/asi/asi.service';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-asi-family-history',
  templateUrl: './asi-family-history.component.html',
  styleUrls: ['./asi-family-history.component.css']
})

export class AsiFamilyHistoryComponent implements OnInit {
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentRequestId: string;

  constructor(
    private asiApiService: AsiApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute) {
  }

  public action: string;
  public asifamilyhistoryForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  asifamilyhistory: IAsiFamilyHistory;
  asifamilyhistoryError: Error = null;
  errors: any = {};
  processing: boolean = false;
  systemGeneratedScore: number;

  public ngOnInit() {
    this.buildForm();
    this.errorHandler.handleErrors(this.asifamilyhistoryForm, this.errors);

    this.loadAsiFamilyHistory();
  }


  loadAsiFamilyHistory() {
    this.asiApiService.getAsiDetails(this.assessmentRequestId).subscribe(response => {
      if (response.data) {
        this.asifamilyhistoryForm.controls['h1_alcohol'].setValue(response.data.asiFamilyHistory.h1_alcohol);
        this.asifamilyhistoryForm.controls['h1_drug'].setValue(response.data.asiFamilyHistory.h1_drug);
        this.asifamilyhistoryForm.controls['h1_psych'].setValue(response.data.asiFamilyHistory.h1_psych);
        this.asifamilyhistoryForm.controls['h2_alcohol'].setValue(response.data.asiFamilyHistory.h2_alcohol);
        this.asifamilyhistoryForm.controls['h2_drug'].setValue(response.data.asiFamilyHistory.h2_drug);
        this.asifamilyhistoryForm.controls['h2_psych'].setValue(response.data.asiFamilyHistory.h2_psych);
        this.asifamilyhistoryForm.controls['h3_alcohol'].setValue(response.data.asiFamilyHistory.h3_alcohol);
        this.asifamilyhistoryForm.controls['h3_drug'].setValue(response.data.asiFamilyHistory.h3_drug);
        this.asifamilyhistoryForm.controls['h3_psych'].setValue(response.data.asiFamilyHistory.h3_psych);
        this.asifamilyhistoryForm.controls['h4_alcohol'].setValue(response.data.asiFamilyHistory.h4_alcohol);
        this.asifamilyhistoryForm.controls['h4_drug'].setValue(response.data.asiFamilyHistory.h4_drug);
        this.asifamilyhistoryForm.controls['h4_psych'].setValue(response.data.asiFamilyHistory.h4_psych);
        this.asifamilyhistoryForm.controls['h5_alcohol'].setValue(response.data.asiFamilyHistory.h5_alcohol);
        this.asifamilyhistoryForm.controls['h5_drug'].setValue(response.data.asiFamilyHistory.h5_drug);
        this.asifamilyhistoryForm.controls['h5_psych'].setValue(response.data.asiFamilyHistory.h5_psych);
        this.asifamilyhistoryForm.controls['h6_alcohol'].setValue(response.data.asiFamilyHistory.h6_alcohol);
        this.asifamilyhistoryForm.controls['h6_drug'].setValue(response.data.asiFamilyHistory.h6_drug);
        this.asifamilyhistoryForm.controls['h6_psych'].setValue(response.data.asiFamilyHistory.h6_psych);
        this.asifamilyhistoryForm.controls['h7_alcohol'].setValue(response.data.asiFamilyHistory.h7_alcohol);
        this.asifamilyhistoryForm.controls['h7_drug'].setValue(response.data.asiFamilyHistory.h7_drug);
        this.asifamilyhistoryForm.controls['h7_psych'].setValue(response.data.asiFamilyHistory.h7_psych);
        this.asifamilyhistoryForm.controls['h8_alcohol'].setValue(response.data.asiFamilyHistory.h8_alcohol);
        this.asifamilyhistoryForm.controls['h8_drug'].setValue(response.data.asiFamilyHistory.h8_drug);
        this.asifamilyhistoryForm.controls['h8_psych'].setValue(response.data.asiFamilyHistory.h8_psych);
        this.asifamilyhistoryForm.controls['h9_alcohol'].setValue(response.data.asiFamilyHistory.h9_alcohol);
        this.asifamilyhistoryForm.controls['h9_drug'].setValue(response.data.asiFamilyHistory.h9_drug);
        this.asifamilyhistoryForm.controls['h9_psych'].setValue(response.data.asiFamilyHistory.h9_psych);
        this.asifamilyhistoryForm.controls['h10_alcohol'].setValue(response.data.asiFamilyHistory.h10_alcohol);
        this.asifamilyhistoryForm.controls['h10_drug'].setValue(response.data.asiFamilyHistory.h10_drug);
        this.asifamilyhistoryForm.controls['h10_psych'].setValue(response.data.asiFamilyHistory.h10_psych);
        this.asifamilyhistoryForm.controls['h11_alcohol'].setValue(response.data.asiFamilyHistory.h11_alcohol);
        this.asifamilyhistoryForm.controls['h11_drug'].setValue(response.data.asiFamilyHistory.h11_drug);
        this.asifamilyhistoryForm.controls['h11_psych'].setValue(response.data.asiFamilyHistory.h11_psych);
        this.asifamilyhistoryForm.controls['h12_alcohol'].setValue(response.data.asiFamilyHistory.h12_alcohol);
        this.asifamilyhistoryForm.controls['h12_drug'].setValue(response.data.asiFamilyHistory.h12_drug);
        this.asifamilyhistoryForm.controls['h12_psych'].setValue(response.data.asiFamilyHistory.h12_psych);
        this.asifamilyhistoryForm.controls['comments'].setValue(response.data.asiFamilyHistory.comments);
        this.asifamilyhistoryForm.controls['clinicianScore'].setValue(response.data.asiFamilyHistory.clinicianScore);
      }
    });
  }
  onAsiFamilyHistoryFormSubmit() {
    if (this.asifamilyhistoryForm.invalid) {
      this.asifamilyhistoryForm.markAllAsTouched();
      return;
    }
    this.asifamilyhistory = Object.assign({}, this.asifamilyhistory);
    this.asifamilyhistory = Object.assign(this.asifamilyhistory, this.asifamilyhistoryForm.value);
    this.asiApiService.updateAsiFamilyHistory(this.asifamilyhistory).subscribe(() => {
    })
  };


  public validation_messages = {
    'clinicianScore': [
      { type: 'required', message: 'Clinician Score is required' },
    ],
    'h1_alcohol': [
      { type: 'required', message: 'H1. Grandmother_alc is required' },
    ],
    'h1_drug': [
      { type: 'required', message: 'H1. Grandmother_drug is required' },
    ],
    'h1_psych': [
      { type: 'required', message: 'H1. Grandmother_psych is required' },
    ],
    'h2_alcohol': [
      { type: 'required', message: 'H2. Grandfather is required' },
    ],
    'h2_drug': [
      { type: 'required', message: 'H2. Grandfather is required' },
    ],
    'h2_psych': [
      { type: 'required', message: 'H2. Grandfather is required' },
    ],
    'h3_alcohol': [
      { type: 'required', message: 'H3. Mother is required' },
    ],
    'h3_drug': [
      { type: 'required', message: 'H3. Mother is required' },
    ],
    'h3_psych': [
      { type: 'required', message: 'H3. Mother is required' },
    ],
    'h4_alcohol': [
      { type: 'required', message: 'H4. Aunt is required' },
    ],
    'h4_drug': [
      { type: 'required', message: 'H4. Aunt is required' },
    ],
    'h4_psych': [
      { type: 'required', message: 'H4. Aunt is required' },
    ],
    'h5_alcohol': [
      { type: 'required', message: 'H5. Uncle is required' },
    ],
    'h5_drug': [
      { type: 'required', message: 'H5. Uncle is required' },
    ],
    'h5_psych': [
      { type: 'required', message: 'H5. Uncle is required' },
    ],
    'h6_alcohol': [
      { type: 'required', message: 'H6. Grandmother is required' },
    ],
    'h6_drug': [
      { type: 'required', message: 'H6. Grandmother is required' },
    ],
    'h6_psych': [
      { type: 'required', message: 'H6. Grandmother is required' },
    ],
    'h7_alcohol': [
      { type: 'required', message: 'H7. Grandfather is required' },
    ],
    'h7_drug': [
      { type: 'required', message: 'H7. Grandfather is required' },
    ],
    'h7_psych': [
      { type: 'required', message: 'H7. Grandfather is required' },
    ],
    'h8_alcohol': [
      { type: 'required', message: 'H8. Father is required' },
    ],
    'h8_drug': [
      { type: 'required', message: 'H8. Father is required' },
    ],
    'h8_psych': [
      { type: 'required', message: 'H8. Father is required' },
    ],
    'h9_alcohol': [
      { type: 'required', message: 'H9. Aunt is required' },
    ],
    'h9_drug': [
      { type: 'required', message: 'H9. Aunt is required' },
    ],
    'h9_psych': [
      { type: 'required', message: 'H9. Aunt is required' },
    ],
    'h10_alcohol': [
      { type: 'required', message: 'H10. Uncle is required' },
    ],
    'h10_drug': [
      { type: 'required', message: 'H10. Uncle is required' },
    ],
    'h10_psych': [
      { type: 'required', message: 'H10. Uncle is required' },
    ],
    'h11_alcohol': [
      { type: 'required', message: 'H11. Brother is required' },
    ],
    'h11_drug': [
      { type: 'required', message: 'H11. Brother is required' },
    ],
    'h11_psych': [
      { type: 'required', message: 'H11. Brother is required' },
    ],
    'h12_alcohol': [
      { type: 'required', message: 'H12. Sister is required' },
    ],
    'h12_drug': [
      { type: 'required', message: 'H12. Sister is required' },
    ],
    'h12_psych': [
      { type: 'required', message: 'H12. Sister is required' },
    ],
    'comments': [
      { type: 'required', message: 'Comments is required' },
    ],
  };

  private buildForm() {
    this.asifamilyhistoryForm = this.formBuilder.group({
      clinicianScore: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      systemScore: new FormControl(0, Validators.compose([
      ])),
      sessionId: new FormControl(this.sessionId, Validators.compose([
        Validators.required,
      ])),
      patientId: new FormControl(this.patientId, Validators.compose([
        Validators.required,
      ])),
      assessmentRequestId: new FormControl(this.assessmentRequestId, Validators.compose([
        Validators.required,
      ])),
      h1_alcohol: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h1_drug: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h1_psych: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h2_alcohol: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h2_drug: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h2_psych: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h3_alcohol: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h3_drug: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h3_psych: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h4_alcohol: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h4_drug: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h4_psych: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h5_alcohol: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h5_drug: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h5_psych: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h6_alcohol: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h6_drug: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h6_psych: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h7_alcohol: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h7_drug: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h7_psych: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h8_alcohol: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h8_drug: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h8_psych: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h9_alcohol: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h9_drug: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h9_psych: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h10_alcohol: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h10_drug: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h10_psych: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h11_alcohol: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h11_drug: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h11_psych: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h12_alcohol: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h12_drug: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      h12_psych: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      comments: new FormControl('', Validators.compose([
        Validators.required,
      ])),
    });
    this.asifamilyhistoryForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }


  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.asifamilyhistoryForm) {
      return;
    }
    const form = this.asifamilyhistoryForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }

}