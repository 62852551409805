<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Obsessive Compulsive Inventory Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="obsessiveCompulsiveInventoryForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >1. I have saved up so many things that they get in the way</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="savedUpSoManyThings">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="A Little">A Little</mat-button-toggle>
              <mat-button-toggle value="Moderately"
                >Moderately</mat-button-toggle
              >
              <mat-button-toggle value="A Lot">A Lot</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >2. I check things more often than necessary</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="checkThingsMoreOften">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="A Little">A Little</mat-button-toggle>
              <mat-button-toggle value="Moderately"
                >Moderately</mat-button-toggle
              >
              <mat-button-toggle value="A Lot">A Lot</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >3. I get upset if objects are not arranged properly</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="getUpsetIfObjectAreNotArrangedProperly"
            >
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="A Little">A Little</mat-button-toggle>
              <mat-button-toggle value="Moderately"
                >Moderately</mat-button-toggle
              >
              <mat-button-toggle value="A Lot">A Lot</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >4. I feel compelled to count while I am doing things</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="compelledToCount">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="A Little">A Little</mat-button-toggle>
              <mat-button-toggle value="Moderately"
                >Moderately</mat-button-toggle
              >
              <mat-button-toggle value="A Lot">A Lot</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >5. I find it difficult to touch an object when I know it has been
            touched by strangers or certain people</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="dontWantToTouchAnObjectIfTouchedByStrangers"
            >
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="A Little">A Little</mat-button-toggle>
              <mat-button-toggle value="Moderately"
                >Moderately</mat-button-toggle
              >
              <mat-button-toggle value="A Lot">A Lot</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >6. I find it difficult to control my own thoughts</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="difficultToControlMyOwnThoughts"
            >
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="A Little">A Little</mat-button-toggle>
              <mat-button-toggle value="Moderately"
                >Moderately</mat-button-toggle
              >
              <mat-button-toggle value="A Lot">A Lot</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >7. I collect things I don't need</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="collectThingsIDontNeed">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="A Little">A Little</mat-button-toggle>
              <mat-button-toggle value="Moderately"
                >Moderately</mat-button-toggle
              >
              <mat-button-toggle value="A Lot">A Lot</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >8. I repeatedly check doors, windows, drawers, etc</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="repeatedlyCheckDoorsWindowsDrawers"
            >
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="A Little">A Little</mat-button-toggle>
              <mat-button-toggle value="Moderately"
                >Moderately</mat-button-toggle
              >
              <mat-button-toggle value="A Lot">A Lot</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >9. I get upset if others change the way I have arranged
            things</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="getUpsetIfOthersChangeArrangedThings"
            >
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="A Little">A Little</mat-button-toggle>
              <mat-button-toggle value="Moderately"
                >Moderately</mat-button-toggle
              >
              <mat-button-toggle value="A Lot">A Lot</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >10. I feel I have to repeat certain numbers</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="repeatCertainNumbers">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="A Little">A Little</mat-button-toggle>
              <mat-button-toggle value="Moderately"
                >Moderately</mat-button-toggle
              >
              <mat-button-toggle value="A Lot">A Lot</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >11. I sometimes have to wash or clean myself simply because I feel
            contaminated</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="washOrCleanMyself">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="A Little">A Little</mat-button-toggle>
              <mat-button-toggle value="Moderately"
                >Moderately</mat-button-toggle
              >
              <mat-button-toggle value="A Lot">A Lot</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >12. I am upset by unpleasant thoughts that come into my mind
            against my will</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="upsetByUnpleasantThoughts"
            >
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="A Little">A Little</mat-button-toggle>
              <mat-button-toggle value="Moderately"
                >Moderately</mat-button-toggle
              >
              <mat-button-toggle value="A Lot">A Lot</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >13. I avoid throwing things away because I am afraid I might need
            them later</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="pilingUpThings">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="A Little">A Little</mat-button-toggle>
              <mat-button-toggle value="Moderately"
                >Moderately</mat-button-toggle
              >
              <mat-button-toggle value="A Lot">A Lot</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >14. I repeatedly check gas and water taps and light switches after
            turning them off</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="repeatedlyCheckGasAndWaterTapsAndLightSwitches"
            >
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="A Little">A Little</mat-button-toggle>
              <mat-button-toggle value="Moderately"
                >Moderately</mat-button-toggle
              >
              <mat-button-toggle value="A Lot">A Lot</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >15. I need things to be arranged in a particular way</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="arrangeInParticularWay">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="A Little">A Little</mat-button-toggle>
              <mat-button-toggle value="Moderately"
                >Moderately</mat-button-toggle
              >
              <mat-button-toggle value="A Lot">A Lot</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >16. I feel that there are good and bad numbers</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="goodAndBadNumbers">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="A Little">A Little</mat-button-toggle>
              <mat-button-toggle value="Moderately"
                >Moderately</mat-button-toggle
              >
              <mat-button-toggle value="A Lot">A Lot</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >17. I wash my hands more often and longer than necessary</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="washHandsOftenAndLongerThanNecessary"
            >
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="A Little">A Little</mat-button-toggle>
              <mat-button-toggle value="Moderately"
                >Moderately</mat-button-toggle
              >
              <mat-button-toggle value="A Lot">A Lot</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >18. I frequently get nasty thoughts and have difficulty in getting
            rid of them</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="getNastyThoughts">
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
              <mat-button-toggle value="A Little">A Little</mat-button-toggle>
              <mat-button-toggle value="Moderately"
                >Moderately</mat-button-toggle
              >
              <mat-button-toggle value="A Lot">A Lot</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          obsessiveCompulsiveInventoryForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!obsessiveCompulsiveInventoryForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
