import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { CoreService } from 'src/app/_services';
import { PermissionsSandbox } from 'src/app/shared/sandbox/permissions.sandbox';
import { hasAccess } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-provider-dashboard-assessments',
  templateUrl: './provider-dashboard-assessments.component.html',
  styleUrl: './provider-dashboard-assessments.component.css',
})
export class ProviderDashboardAssessmentsComponent implements OnInit {
  loggedInUserId: String;

  // Permissions List
  hasHospitalDeviceFeature: boolean = false;
  hasMemberAssessmentAddAccess: boolean = false;
  hasMemberAssessmentEditAccess: boolean = false;
  hasMemberAssessmentDeleteAccess: boolean = false;
  hasAssessmentChartsViewAccess: boolean = false;
  hasAssessmentGuidanceViewAccess: boolean = false;
  hasAssessmentGuidanceEditAccess: boolean = false;
  hasAssessmentSummaryViewAccess: boolean = false;
  hasAssessmentSummaryEditAccess: boolean = false;
  hasAssessmentTakeAddAccess: boolean = false;

  constructor(
    private coreService: CoreService,
    private keycloakService: KeycloakService,
    protected permissionsSandbox: PermissionsSandbox
  ) {
    this.loggedInUserId = this.coreService.getLoggedInCareProviderId();
  }

  ngOnInit(): void {
    this.permissionsSandbox.permissions$.subscribe((response) => {
      //********  Assessment Permissions ********/
      // Assessments
      this.hasMemberAssessmentAddAccess = hasAccess(
        this.keycloakService,
        'MEMBER_ASSESSMENTS_ADD',
        response,
        null
      );
      this.hasMemberAssessmentEditAccess = hasAccess(
        this.keycloakService,
        'MEMBER_ASSESSMENTS_EDIT',
        response,
        null
      );
      this.hasMemberAssessmentDeleteAccess = hasAccess(
        this.keycloakService,
        'MEMBER_ASSESSMENTS_DELETE',
        response,
        null
      );
      // Assessments Charts
      this.hasAssessmentChartsViewAccess = hasAccess(
        this.keycloakService,
        'CHARTS_VIEW',
        response,
        null
      );
      // Assessments Guidance
      this.hasAssessmentGuidanceViewAccess = hasAccess(
        this.keycloakService,
        'GUIDANCE_VIEW',
        response,
        null
      );
      this.hasAssessmentGuidanceEditAccess = hasAccess(
        this.keycloakService,
        'GUIDANCE_EDIT',
        response,
        null
      );
      // Assessments Summary
      this.hasAssessmentSummaryViewAccess = hasAccess(
        this.keycloakService,
        'SUMMARY_VIEW',
        response,
        null
      );
      this.hasAssessmentSummaryEditAccess = hasAccess(
        this.keycloakService,
        'SUMMARY_EDIT',
        response,
        null
      );
      // Assessments Take
      this.hasAssessmentTakeAddAccess = hasAccess(
        this.keycloakService,
        'TAKE_ASSESSMENTS_ADD',
        response,
        null
      );
    });
  }
}
