<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>{{ documentName }}</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="col-md-12" *ngIf="isLoadingResults">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div style="height: 700px; width: 100%">
    <ng2-pdfjs-viewer
      [download]="'false'"
      #pdfViewerOnDemand
    ></ng2-pdfjs-viewer>
  </div>
</div>
