import { createAction, props } from '@ngrx/store';
import { ResponseModal } from '../../../_models';

export const LoadOrgConfigAction = createAction(
  '[Get OrgConfig] Get OrgConfig'
);
export const LoadOrgConfigSuccessAction = createAction(
  '[Get OrgConfig] Get OrgConfig Success',
  props<{ payload: ResponseModal }>()
);
export const LoadOrgConfigFailedAction = createAction(
  '[Get OrgConfig] Get OrgConfig Failed',
  props<{ payload: any }>()
);
export const LoadPhysicianOrgConfigAction = createAction(
  '[Get OrgConfig] Get Physician OrgConfig'
);
