export const TimeArray = [
  { value: 2400, text: '12:00 AM' },
  { value: 2415, text: '12:15 AM' },
  { value: 2430, text: '12:30 AM' },
  { value: 2445, text: '12:45 AM' },

  { value: 100, text: '1:00 AM' },
  { value: 115, text: '1:15 AM' },
  { value: 130, text: '1:30 AM' },
  { value: 145, text: '1:45 AM' },

  { value: 200, text: '2:00 AM' },
  { value: 215, text: '2:15 AM' },
  { value: 230, text: '2:30 AM' },
  { value: 245, text: '2:45 AM' },

  { value: 300, text: '3:00 AM' },
  { value: 315, text: '3:15 AM' },
  { value: 330, text: '3:30 AM' },
  { value: 345, text: '3:45 AM' },

  { value: 400, text: '4:00 AM' },
  { value: 415, text: '4:15 AM' },
  { value: 430, text: '4:30 AM' },
  { value: 445, text: '4:45 AM' },

  { value: 500, text: '5:00 AM' },
  { value: 515, text: '5:15 AM' },
  { value: 530, text: '5:30 AM' },
  { value: 545, text: '5:45 AM' },

  { value: 600, text: '6:00 AM' },
  { value: 615, text: '6:15 AM' },
  { value: 630, text: '6:30 AM' },
  { value: 645, text: '6:45 AM' },

  { value: 700, text: '7:00 AM' },
  { value: 715, text: '7:15 AM' },
  { value: 730, text: '7:30 AM' },
  { value: 745, text: '7:45 AM' },

  { value: 800, text: '8:00 AM' },
  { value: 815, text: '8:15 AM' },
  { value: 830, text: '8:30 AM' },
  { value: 845, text: '8:45 AM' },

  { value: 900, text: '9:00 AM' },
  { value: 915, text: '9:15 AM' },
  { value: 930, text: '9:30 AM' },
  { value: 945, text: '9:45 AM' },

  { value: 1000, text: '10:00 AM' },
  { value: 1015, text: '10:15 AM' },
  { value: 1030, text: '10:30 AM' },
  { value: 1045, text: '10:45 AM' },

  { value: 1100, text: '11:00 AM' },
  { value: 1115, text: '11:15 AM' },
  { value: 1130, text: '11:30 AM' },
  { value: 1145, text: '11:45 AM' },

  { value: 1200, text: '12:00 PM' },
  { value: 1215, text: '12:15 PM' },
  { value: 1230, text: '12:30 PM' },
  { value: 1245, text: '12:45 PM' },

  { value: 1300, text: '1:00 PM' },
  { value: 1315, text: '1:15 PM' },
  { value: 1330, text: '1:30 PM' },
  { value: 1345, text: '1:45 PM' },

  { value: 1400, text: '2:00 PM' },
  { value: 1415, text: '2:15 PM' },
  { value: 1430, text: '2:30 PM' },
  { value: 1445, text: '2:45 PM' },

  { value: 1500, text: '3:00 PM' },
  { value: 1515, text: '3:15 PM' },
  { value: 1530, text: '3:30 PM' },
  { value: 1545, text: '3:45 PM' },

  { value: 1600, text: '4:00 PM' },
  { value: 1615, text: '4:15 PM' },
  { value: 1630, text: '4:30 PM' },
  { value: 1645, text: '4:45 PM' },

  { value: 1700, text: '5:00 PM' },
  { value: 1715, text: '5:15 PM' },
  { value: 1730, text: '5:30 PM' },
  { value: 1745, text: '5:45 PM' },

  { value: 1800, text: '6:00 PM' },
  { value: 1815, text: '6:15 PM' },
  { value: 1830, text: '6:30 PM' },
  { value: 1845, text: '6:45 PM' },

  { value: 1900, text: '7:00 PM' },
  { value: 1915, text: '7:15 PM' },
  { value: 1930, text: '7:30 PM' },
  { value: 1945, text: '7:45 PM' },

  { value: 2000, text: '8:00 PM' },
  { value: 2015, text: '8:15 PM' },
  { value: 2030, text: '8:30 PM' },
  { value: 2045, text: '8:45 PM' },

  { value: 2100, text: '9:00 PM' },
  { value: 2115, text: '9:15 PM' },
  { value: 2130, text: '9:30 PM' },
  { value: 2145, text: '9:45 PM' },

  { value: 2200, text: '10:00 PM' },
  { value: 2215, text: '10:15 PM' },
  { value: 2230, text: '10:30 PM' },
  { value: 2245, text: '10:45 PM' },

  { value: 2300, text: '11:00 PM' },
  { value: 2315, text: '11:15 PM' },
  { value: 2330, text: '11:30 PM' },
  { value: 2345, text: '11:45 PM' },
];

export const TimeArrayMap = new Map<number, string>([
  [2400, '12:00 AM'],
  [2405, '12:05 AM'],
  [2410, '12:10 AM'],
  [2415, '12:15 AM'],
  [2420, '12:20 AM'],
  [2425, '12:25 AM'],
  [2430, '12:30 AM'],
  [2435, '12:35 AM'],
  [2440, '12:40 AM'],
  [2445, '12:45 AM'],
  [2450, '12:50 AM'],
  [2455, '12:55 AM'],
  [100, '1:00 AM'],
  [105, '1:05 AM'],
  [110, '1:10 AM'],
  [115, '1:15 AM'],
  [120, '1:20 AM'],
  [125, '1:25 AM'],
  [130, '1:30 AM'],
  [135, '1:35 AM'],
  [140, '1:40 AM'],
  [145, '1:45 AM'],
  [150, '1:50 AM'],
  [155, '1:55 AM'],
  [200, '2:00 AM'],
  [205, '2:05 AM'],
  [210, '2:10 AM'],
  [215, '2:15 AM'],
  [220, '2:20 AM'],
  [225, '2:25 AM'],
  [230, '2:30 AM'],
  [235, '2:35 AM'],
  [240, '2:40 AM'],
  [245, '2:45 AM'],
  [250, '2:50 AM'],
  [255, '2:55 AM'],
  [300, '3:00 AM'],
  [305, '3:05 AM'],
  [310, '3:10 AM'],
  [315, '3:15 AM'],
  [320, '3:20 AM'],
  [325, '3:25 AM'],
  [330, '3:30 AM'],
  [335, '3:35 AM'],
  [340, '3:40 AM'],
  [345, '3:45 AM'],
  [350, '3:50 AM'],
  [355, '3:55 AM'],
  [400, '4:00 AM'],
  [405, '4:05 AM'],
  [410, '4:10 AM'],
  [415, '4:15 AM'],
  [420, '4:20 AM'],
  [425, '4:25 AM'],
  [430, '4:30 AM'],
  [435, '4:35 AM'],
  [440, '4:40 AM'],
  [445, '4:45 AM'],
  [450, '4:50 AM'],
  [455, '4:55 AM'],
  [500, '5:00 AM'],
  [505, '5:05 AM'],
  [510, '5:10 AM'],
  [515, '5:15 AM'],
  [520, '5:20 AM'],
  [525, '5:25 AM'],
  [530, '5:30 AM'],
  [535, '5:35 AM'],
  [540, '5:40 AM'],
  [545, '5:45 AM'],
  [550, '5:50 AM'],
  [555, '5:55 AM'],
  [600, '6:00 AM'],
  [605, '6:05 AM'],
  [610, '6:10 AM'],
  [615, '6:15 AM'],
  [620, '6:20 AM'],
  [625, '6:25 AM'],
  [630, '6:30 AM'],
  [635, '6:35 AM'],
  [640, '6:40 AM'],
  [645, '6:45 AM'],
  [650, '6:50 AM'],
  [655, '6:55 AM'],
  [700, '7:00 AM'],
  [705, '7:05 AM'],
  [710, '7:10 AM'],
  [715, '7:15 AM'],
  [720, '7:20 AM'],
  [725, '7:25 AM'],
  [730, '7:30 AM'],
  [735, '7:35 AM'],
  [740, '7:40 AM'],
  [745, '7:45 AM'],
  [750, '7:50 AM'],
  [755, '7:55 AM'],
  [800, '8:00 AM'],
  [805, '8:05 AM'],
  [810, '8:10 AM'],
  [815, '8:15 AM'],
  [820, '8:20 AM'],
  [825, '8:25 AM'],
  [830, '8:30 AM'],
  [835, '8:35 AM'],
  [840, '8:40 AM'],
  [845, '8:45 AM'],
  [850, '8:50 AM'],
  [855, '8:55 AM'],
  [900, '9:00 AM'],
  [905, '9:05 AM'],
  [910, '9:10 AM'],
  [915, '9:15 AM'],
  [920, '9:20 AM'],
  [925, '9:25 AM'],
  [930, '9:30 AM'],
  [935, '9:35 AM'],
  [940, '9:40 AM'],
  [945, '9:45 AM'],
  [950, '9:50 AM'],
  [955, '9:55 AM'],
  [1000, '10:00 AM'],
  [1005, '10:05 AM'],
  [1010, '10:10 AM'],
  [1015, '10:15 AM'],
  [1020, '10:20 AM'],
  [1025, '10:25 AM'],
  [1030, '10:30 AM'],
  [1035, '10:35 AM'],
  [1040, '10:40 AM'],
  [1045, '10:45 AM'],
  [1050, '10:50 AM'],
  [1055, '10:55 AM'],
  [1100, '11:00 AM'],
  [1105, '11:05 AM'],
  [1110, '11:10 AM'],
  [1115, '11:15 AM'],
  [1120, '11:20 AM'],
  [1125, '11:25 AM'],
  [1130, '11:30 AM'],
  [1135, '11:35 AM'],
  [1140, '11:40 AM'],
  [1145, '11:45 AM'],
  [1150, '11:50 AM'],
  [1155, '11:55 AM'],
  [1200, '12:00 PM'],
  [1205, '12:05 PM'],
  [1210, '12:10 PM'],
  [1215, '12:15 PM'],
  [1220, '12:20 PM'],
  [1225, '12:25 PM'],
  [1230, '12:30 PM'],
  [1235, '12:35 PM'],
  [1240, '12:40 PM'],
  [1245, '12:45 PM'],
  [1250, '12:50 PM'],
  [1255, '12:55 PM'],
  [1300, '1:00 PM'],
  [1305, '1:05 PM'],
  [1310, '1:10 PM'],
  [1315, '1:15 PM'],
  [1320, '1:20 PM'],
  [1325, '1:25 PM'],
  [1330, '1:30 PM'],
  [1335, '1:35 PM'],
  [1340, '1:40 PM'],
  [1345, '1:45 PM'],
  [1350, '1:50 PM'],
  [1355, '1:55 PM'],
  [1400, '2:00 PM'],
  [1405, '2:05 PM'],
  [1410, '2:10 PM'],
  [1415, '2:15 PM'],
  [1420, '2:20 PM'],
  [1425, '2:25 PM'],
  [1430, '2:30 PM'],
  [1435, '2:35 PM'],
  [1440, '2:40 PM'],
  [1445, '2:45 PM'],
  [1450, '2:50 PM'],
  [1455, '2:55 PM'],
  [1500, '3:00 PM'],
  [1505, '3:05 PM'],
  [1510, '3:10 PM'],
  [1515, '3:15 PM'],
  [1520, '3:20 PM'],
  [1525, '3:25 PM'],
  [1530, '3:30 PM'],
  [1535, '3:35 PM'],
  [1540, '3:40 PM'],
  [1545, '3:45 PM'],
  [1550, '3:50 PM'],
  [1555, '3:55 PM'],
  [1600, '4:00 PM'],
  [1605, '4:05 PM'],
  [1610, '4:10 PM'],
  [1615, '4:15 PM'],
  [1620, '4:20 PM'],
  [1625, '4:25 PM'],
  [1630, '4:30 PM'],
  [1635, '4:35 PM'],
  [1640, '4:40 PM'],
  [1645, '4:45 PM'],
  [1650, '4:50 PM'],
  [1655, '4:55 PM'],
  [1700, '5:00 PM'],
  [1705, '5:05 PM'],
  [1710, '5:10 PM'],
  [1715, '5:15 PM'],
  [1720, '5:20 PM'],
  [1725, '5:25 PM'],
  [1730, '5:30 PM'],
  [1735, '5:35 PM'],
  [1740, '5:40 PM'],
  [1745, '5:45 PM'],
  [1750, '5:50 PM'],
  [1755, '5:55 PM'],
  [1800, '6:00 PM'],
  [1805, '6:05 PM'],
  [1810, '6:10 PM'],
  [1815, '6:15 PM'],
  [1820, '6:20 PM'],
  [1825, '6:25 PM'],
  [1830, '6:30 PM'],
  [1835, '6:35 PM'],
  [1840, '6:40 PM'],
  [1845, '6:45 PM'],
  [1850, '6:50 PM'],
  [1855, '6:55 PM'],
  [1900, '7:00 PM'],
  [1905, '7:05 PM'],
  [1910, '7:10 PM'],
  [1915, '7:15 PM'],
  [1920, '7:20 PM'],
  [1925, '7:25 PM'],
  [1930, '7:30 PM'],
  [1935, '7:35 PM'],
  [1940, '7:40 PM'],
  [1945, '7:45 PM'],
  [1950, '7:50 PM'],
  [1955, '7:55 PM'],
  [2000, '8:00 PM'],
  [2005, '8:05 PM'],
  [2010, '8:10 PM'],
  [2015, '8:15 PM'],
  [2020, '8:20 PM'],
  [2025, '8:25 PM'],
  [2030, '8:30 PM'],
  [2035, '8:35 PM'],
  [2040, '8:40 PM'],
  [2045, '8:45 PM'],
  [2050, '8:50 PM'],
  [2055, '8:55 PM'],
  [2100, '9:00 PM'],
  [2105, '9:05 PM'],
  [2110, '9:10 PM'],
  [2115, '9:15 PM'],
  [2120, '9:20 PM'],
  [2125, '9:25 PM'],
  [2130, '9:30 PM'],
  [2135, '9:35 PM'],
  [2140, '9:40 PM'],
  [2145, '9:45 PM'],
  [2150, '9:50 PM'],
  [2155, '9:55 PM'],
  [2200, '10:00 PM'],
  [2205, '10:05 PM'],
  [2210, '10:10 PM'],
  [2215, '10:15 PM'],
  [2220, '10:20 PM'],
  [2225, '10:25 PM'],
  [2230, '10:30 PM'],
  [2235, '10:35 PM'],
  [2240, '10:40 PM'],
  [2245, '10:45 PM'],
  [2250, '10:50 PM'],
  [2255, '10:55 PM'],
  [2300, '11:00 PM'],
  [2305, '11:05 PM'],
  [2310, '11:10 PM'],
  [2315, '11:15 PM'],
  [2320, '11:20 PM'],
  [2325, '11:25 PM'],
  [2330, '11:30 PM'],
  [2335, '11:35 PM'],
  [2340, '11:40 PM'],
  [2345, '11:45 PM'],
  [2350, '11:50 PM'],
  [2355, '11:55 PM'],
]);
