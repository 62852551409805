<mat-dialog-content>
    <div class="row">
        <div class="col col-9">
            <h4 style="color: #333333;">{{this.data?.attempt}} Attempt Details</h4>
        </div>
        <div class="col col-3">
            <button mat-mini-fab mat-dialog-close style="float: right; background-color: #fff; color: #333333;"
                (click)="closeDialog()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <div class="row">
        <form [formGroup]="addAttemptForm" (ngSubmit)="onAttemptFormSubmit()">
            <div formGroupName="userData">
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6">
                            <label class="labelText" style="display: flex">Attempt Date</label>
                            <mat-form-field appearance="outline">
                                <input matInput [matDatepicker]="picker" formControlName="attemptDate"
                                    placeholder="Attempt Date" readonly required />

                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <label class="labelText" style="display: flex">Select Time</label>
                            <mat-form-field appearance="outline">
                                <input matInput [ngxTimepicker]="picker3" formControlName="selectTime"
                                    placeholder="Select Time" />
                                <ngx-material-timepicker #picker3 style="z-index: 1;"></ngx-material-timepicker>
                                <mat-icon matSuffix>schedule</mat-icon>

                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <label class="labelText" style="display: flex">{{this.data?.attempt}} Attempt Result</label>
                            <mat-form-field appearance="outline">
                                <mat-select formControlName="response" required placeholder="Attempt Result">
                                    <mat-option value="Success">Success</mat-option>
                                    <mat-option value="Left Voicemail">Left Voicemail</mat-option>
                                    <mat-option value="No Answer">No Answer</mat-option>
                                    <mat-option value="Line Busy">Line Busy</mat-option>
                                    <mat-option value="Wrong Number">Wrong Number</mat-option>
                                    <mat-option value="Follow-Up Needed">Follow-Up Needed</mat-option>
                                    <mat-option value="DND (Do Not Disturb) Requested">DND (Do Not Disturb)
                                        Requested</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <button mat-flat-button (click)="closeDialog()" type="submit"
                style="background-color: #2196f3; color: #fff; float: right; margin-left: 10px;">Submit</button>
            <button mat-stroked-button mat-dialog-close style="float: right;">Cancel</button>
        </form>
    </div>

</mat-dialog-content>