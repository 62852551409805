<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle *ngIf="action === 'ADD'">Add Goal</p>
    <p matDialogTitle *ngIf="action === 'EDIT'">Edit Goal</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div>
    <mat-stepper linear #stepper style="width: 100%">
      <!-- Select question first -->
      <!-- <mat-step [stepControl]="selectedQuestion" *ngIf="assessmentDetails && assessmentDetails.length > 0">
        <div class="col-md-12" style="text-align: center">
          <label class="labelText" *ngIf="action === 'ADD'">
            Please select a question <span style="color: red">*</span>
          </label>
          <label class="labelText" *ngIf="action === 'EDIT'">
            Selected Question
          </label>
          <mat-form-field appearance="outline">
            <mat-select [(ngModel)]="selectedQuestion" [disabled]="action === 'EDIT'">
              <mat-option *ngFor="let question of assessmentDetails; let i = index" [value]="i" style="font-size: 12px">
                {{ question.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div style="text-align: right">
          <button mat-button matStepperNext [disabled]="!(selectedQuestion >= 0)">
            Next
          </button>
        </div>
      </mat-step> -->
      <!-- Select answers  -->
      <!-- <mat-step [stepControl]="selectedAnswer" *ngIf="assessmentDetails && assessmentDetails.length > 0">
        <div class="col-md-12" style="text-align: center">
          <label class="labelText">
            Please select the response <span style="color: red">*</span>
          </label>
          <mat-form-field appearance="outline" *ngIf="selectedQuestion >= 0">
            <mat-select [(ngModel)]="selectedAnswer" multiple>
              <mat-option *ngFor="
                  let answer of assessmentDetails[selectedQuestion].answers;
                  let j = index
                " [value]="answer.value" style="font-size: 12px">
                {{ answer.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div style="display: flex; justify-content: space-between">
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext [disabled]="!(selectedAnswer.length > 0)">
            Next
          </button>
        </div>
      </mat-step> -->
      <!-- Now Describe the goal  -->
      <mat-step [stepControl]="assessmentGoalForm">
        <ng-template matStepLabel>Goal Details</ng-template>

        <form [formGroup]="assessmentGoalForm">
          <div class="row">
            <div class="col-md-12">
              <label class="labelText">
                Goal Description <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <textarea
                  matInput
                  placeholder=""
                  formControlName="description"
                  name="description"
                  rows="3"
                ></textarea>
                <mat-error
                  *ngIf="assessmentGoalForm.controls['description'].invalid"
                >
                  Goal description is required
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Add More Objectives -->
            <div class="col-md-12" style="margin-bottom: 12px">
              <button
                class="btn btn-success pull-right"
                (click)="createObjective('', '', '')"
              >
                + Add Objective
              </button>
            </div>

            <div class="col-md-12" formArrayName="objectives">
              <ng-container
                *ngFor="let objective of objectives().controls; let i = index"
              >
                <mat-card
                  appearance="raised"
                  class="pad20 mat-elevation-z8"
                  style="width: 100%; position: relative"
                >
                  <div
                    style="position: absolute; right: 10px; z-index: 1050"
                    *ngIf="objectives().length > 1"
                  >
                    <mat-icon
                      style="color: #f44336; font-size: 19px; cursor: pointer"
                      (click)="removeObjective(i)"
                      >delete_forever</mat-icon
                    >
                  </div>
                  <div class="input-wrapper" [formGroupName]="i">
                    <label class="labelText pull-left">
                      Objective <span style="color: red">*</span>
                    </label>
                    <mat-form-field appearance="outline">
                      <input
                        matInput
                        placeholder=""
                        formControlName="objectiveDescription"
                        required
                      />
                      <mat-error
                        *ngIf="objective.controls.objectiveDescription.invalid"
                      >
                        Please describe the objective
                      </mat-error>
                    </mat-form-field>

                    <div class="row">
                      <div class="col-md-6">
                        <label class="labelText"> Target # </label>
                        <mat-form-field appearance="outline">
                          <mat-select
                            formControlName="targetNumber"
                            placeholder="Target #"
                          >
                            <mat-option
                              *ngFor="let num of numbers"
                              [value]="num"
                            >
                              {{ num }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-md-6">
                        <label class="labelText"> Target Type </label>
                        <mat-form-field appearance="outline">
                          <mat-select
                            formControlName="targetType"
                            placeholder="Target Type"
                          >
                            <mat-option value="Days">Day(s)</mat-option>
                            <mat-option value="Weeks">Week(s)</mat-option>
                            <mat-option value="Months">Month(s)</mat-option>
                            <mat-option value="Years">Year(s)</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </mat-card>
              </ng-container>
            </div>

            <div class="col-md-6" *ngIf="action === 'EDIT'">
              <label class="labelText">
                Status <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="status" placeholder="Status">
                  <mat-option value="Active">Active</mat-option>
                  <mat-option value="Inactive">Inactive</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </form>
        <div style="display: flex; justify-content: right">
          <button
            mat-button
            matStepperNext
            [disabled]="assessmentGoalForm.invalid"
          >
            Next
          </button>
        </div>
      </mat-step>

      <!-- Summary -->
      <mat-step>
        <ng-template matStepLabel>Summary</ng-template>

        <p style="text-align: center; font-weight: 600; font-size: 20px">
          Goal Overview
        </p>

        <div class="summaryGridContainer" *ngIf="assessmentGoalForm.valid">
          <span *ngIf="assessmentDetails[selectedQuestion]">
            <label class="labelText">Question</label>
            <label>
              {{ assessmentDetails[selectedQuestion].title }}
            </label>

            <label class="labelText">Response(s)</label>
            <label>
              {{ selectedAnswer.toString() }}
            </label>
          </span>

          <label class="labelText">Description</label>
          <label>
            {{ assessmentGoalForm.controls['description'].value }}
          </label>
        </div>

        <ng-container
          *ngFor="let objective of objectives().controls; let i = index"
        >
          <mat-card
            appearance="raised"
            class="pad20 mat-elevation-z8"
            style="width: 100%"
          >
            <div class="summaryGridContainer">
              <label class="labelText pull-left"> Objective </label>
              <label>
                {{ objective.value.objectiveDescription }}
              </label>

              <label class="labelText">Target </label>
              <label>
                {{ objective.value.targetNumber }} &nbsp;
                {{ objective.value.targetType }}
              </label>
            </div>
          </mat-card>
        </ng-container>

        <div
          style="
            display: flex;
            justify-content: space-between;
            margin-top: 40px;
          "
        >
          <button mat-raised-button matStepperPrevious [disabled]="processing">
            Back
          </button>
          <button
            mat-raised-button
            color="primary"
            type="button"
            [disabled]="processing"
            (click)="submitForm()"
          >
            Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
          </button>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
</div>
