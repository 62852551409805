import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IIntakeClinicalGuidance } from 'src/app/_models/session/intake/intakeclinicalguidancereasoning.model';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class IntakeClinicalGuidanceApiService {
    constructor(private httpClient: HttpClient) { }
    updateIntakeClinicalGuidance(thisIntakeClinicalGuidance: IIntakeClinicalGuidance): Observable<IIntakeClinicalGuidance> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.put<IIntakeClinicalGuidance>(`${environment.apiUrl}/intakeclinicalguidance`, thisIntakeClinicalGuidance, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }
}