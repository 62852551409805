export const HealthInsuranceTypes = [
  {
    id: 'MCARE',
    description: 'MEDICARE',
  },
  {
    id: 'MCAID',
    description: 'MEDICAID',
  },
  {
    id: 'TRICR',
    description: 'TRICARE',
  },
  {
    id: 'CHPVA',
    description: 'CHAMPVA',
  },
  {
    id: 'GRPHP',
    description: 'GROUP HEALTH PLAN',
  },
  {
    id: 'FECBL',
    description: 'FECA BLK LUNG',
  },
  {
    id: 'OTHER',
    description: 'OTHER',
  },
];
