import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from 'src/app/material-imports';
import { SurveyModule } from 'survey-angular-ui';
import { CustomFormRequestComponent } from './custom-form-request/custom-form-request.component';
import { CustomFormRoutes } from './custom-forms.routing';

@NgModule({
  declarations: [
    CustomFormRequestComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(CustomFormRoutes),
    AngularMaterialModule,
    SurveyModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  exports: [
    CustomFormRequestComponent
  ],
})
export class CustomFormsModule { }
