import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IAsiGeneral } from 'src/app/_models/assessments/asi/asigeneral.model';
import { AsiApiService } from 'src/app/_services/assessments/asi/asi.service';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs, formatDate, mmddyyyyToDate } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-asi-general',
  templateUrl: './asi-general.component.html',
  styleUrls: ['./asi-general.component.css']
})

export class AsiGeneralComponent implements OnInit {
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentRequestId: string;

  constructor(
    private asiApiService: AsiApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute) {
  }

  public action: string;
  public asigeneralForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  asigeneral: IAsiGeneral;
  asigeneralError: Error = null;
  errors: any = {};
  processing: boolean = false;
  systemGeneratedScore: number;


  public ngOnInit() {
    this.buildForm();
    this.errorHandler.handleErrors(this.asigeneralForm, this.errors);

    this.loadAsiGeneral();
  }


  loadAsiGeneral() {
    this.asiApiService.getAsiDetails(this.assessmentRequestId).subscribe(response => {
      if (response.data) {
        this.asigeneralForm.controls['g1'].setValue(response.data.asiGeneral.g1);
        if (
          response.data.asiGeneral.g4 &&
          response.data.asiGeneral.g4 !== ''
        ) {
          this.asigeneralForm.controls['g4'].setValue(
            mmddyyyyToDate(response.data.asiGeneral.g4)
          );
        }
        if (
          response.data.asiGeneral.g5 &&
          response.data.asiGeneral.g5 !== ''
        ) {
          this.asigeneralForm.controls['g5'].setValue(
            mmddyyyyToDate(response.data.asiGeneral.g5)
          );
        }
        this.asigeneralForm.controls['g6'].setValue(response.data.asiGeneral.g6);
        this.asigeneralForm.controls['g7'].setValue(response.data.asiGeneral.g7);
        this.asigeneralForm.controls['g8'].setValue(response.data.asiGeneral.g8);
        this.asigeneralForm.controls['g9'].setValue(response.data.asiGeneral.g9);
        this.asigeneralForm.controls['g10'].setValue(response.data.asiGeneral.g10);
        this.asigeneralForm.controls['g99'].setValue(response.data.asiGeneral.g99);
        this.asigeneralForm.controls['g11'].setValue(response.data.asiGeneral.g11);
        this.asigeneralForm.controls['g12'].setValue(response.data.asiGeneral.g12);
        this.asigeneralForm.controls['g14'].setValue(response.data.asiGeneral.g14);
        this.asigeneralForm.controls['g15'].setValue(response.data.asiGeneral.g15);
        if (
          response.data.asiGeneral.g16 &&
          response.data.asiGeneral.g16 !== ''
        ) {
          this.asigeneralForm.controls['g16'].setValue(
            mmddyyyyToDate(response.data.asiGeneral.g16)
          );
        }
        this.asigeneralForm.controls['g17'].setValue(response.data.asiGeneral.g17);
        this.asigeneralForm.controls['g18'].setValue(response.data.asiGeneral.g18);
        this.asigeneralForm.controls['g19'].setValue(response.data.asiGeneral.g19);
        this.asigeneralForm.controls['g20'].setValue(response.data.asiGeneral.g20);
        this.asigeneralForm.controls['comments'].setValue(response.data.asiGeneral.comments);
        this.asigeneralForm.controls['clinicianScore'].setValue(response.data.asiGeneral.clinicianScore);
      }
    });
  }
  onAsiGeneralFormSubmit() {
    if (this.asigeneralForm.invalid) {
      this.asigeneralForm.markAllAsTouched();
      return;
    }
    this.asigeneral = Object.assign({}, this.asigeneral);
    this.asigeneral = Object.assign(this.asigeneral, this.asigeneralForm.value);
    if (this.asigeneral.g4 !== '') {
      this.asigeneral.g4 = formatDate(new Date(this.asigeneral.g4));
    }
    if (this.asigeneral.g5 !== '') {
      this.asigeneral.g5 = formatDate(new Date(this.asigeneral.g5));
    }
    if (this.asigeneral.g16 !== '') {
      this.asigeneral.g16 = formatDate(new Date(this.asigeneral.g16));
    }
    this.asiApiService.updateAsiGeneral(this.asigeneral).subscribe(() => {
    })
  };


  public validation_messages = {
    'clinicianScore': [
      { type: 'required', message: 'Clinician Score is required' },
    ],
    'g1': [
      { type: 'required', message: 'G1. ID Number is required' },
    ],
    'g4': [
      { type: 'required', message: 'G4. Date of Admission is required' },
    ],
    'g5': [
      { type: 'required', message: 'G5. Date of Interview is required' },
    ],
    'g6': [
      { type: 'required', message: 'G6. Time Begun is required' },
    ],
    'g7': [
      { type: 'required', message: 'G7. Time Ended is required' },
    ],
    'g8': [
      { type: 'required', message: 'G8. Class is required' },
    ],
    'g9': [
      { type: 'required', message: 'G9. Contact Code is required' },
    ],
    'g10': [
      { type: 'required', message: 'G10. Gender is required' },
    ],
    'g99': [
      { type: 'required', message: 'G99. Treatment Episode Number is required' },
    ],
    'g11': [
      { type: 'required', message: 'G11. Interviewer Code Number is required' },
    ],
    'g12': [
      { type: 'required', message: 'G12. Special is required' },
    ],
    'g14': [
      { type: 'required', message: 'G14. How long have you lived at your current address? is required' },
    ],
    'g15': [
      { type: 'required', message: 'G15. Is this residence owned by you or your family? is required' },
    ],
    'g16': [
      { type: 'required', message: 'G16. Date of birth is required' },
    ],
    'g17': [
      { type: 'required', message: 'G17 Of what race do you consider yourself? is required' },
    ],
    'g18': [
      { type: 'required', message: 'G18. Do you have a religious preference? is required' },
    ],
    'g19': [
      { type: 'required', message: 'G19. Have you been in a controlled environment in the past 30 days? is required' },
    ],
    'g20': [
      { type: 'required', message: 'G20. How many days? is required' },
    ],
    'comments': [
    ],
  };

  private buildForm() {
    this.asigeneralForm = this.formBuilder.group({
      clinicianScore: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      systemScore: new FormControl(0, Validators.compose([
      ])),
      sessionId: new FormControl(this.sessionId, Validators.compose([
        Validators.required,
      ])),
      patientId: new FormControl(this.patientId, Validators.compose([
        Validators.required,
      ])),
      assessmentRequestId: new FormControl(this.assessmentRequestId, Validators.compose([
        Validators.required,
      ])),
      g1: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      g4: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      g5: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      g6: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      g7: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      g8: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      g9: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      g10: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      g99: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      g11: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      g12: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      g14: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      g15: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      g16: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      g17: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      g18: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      g19: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      g20: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      comments: new FormControl('', Validators.compose([
      ])),
    });
    this.asigeneralForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.asigeneralForm) {
      return;
    }
    const form = this.asigeneralForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }

}