<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>
      Follow Up -
      {{ goal.followUpDetails[followUpIndex].followUpDate | date : 'MM/dd/y' }}
    </p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <!-- Body -->
  <div style="margin-top: 20px">
    <form [formGroup]="followUpNotesForm" novalidate>
      <div class="row">
        <mat-form-field appearance="outline">
          <textarea
            matInput
            placeholder="Please add the notes here ..."
            formControlName="followUpNote"
            rows="8"
          ></textarea>
        </mat-form-field>
      </div>

      <mat-dialog-actions align="end">
        <button
          class="btn btn-primary"
          mat-raised-button
          type="button"
          (click)="submitFollowUpNotesForm()"
          [disabled]="!followUpNotesForm.valid || processing"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </mat-dialog-actions>
    </form>
  </div>
</div>
