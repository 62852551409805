<div>
  <div class="col-md-12">
    <p style="font-size: 16px; font-weight: 450">
      <a routerLink="/" routerLinkActive="active" style="color: #2196f3">
        <em
          class="material-icons text_align-center"
          style="font-size: 18px; margin-top: -3px"
          >home</em
        >
      </a>
      &nbsp; / &nbsp; Members
    </p>
  </div>
</div>

<!-- If not consolidated search, display facility drop down -->
<div
  style="display: flex; justify-content: center; margin-top: 10px"
  *ngIf="!consolidatedMemberSearch"
>
  <mat-card appearance="raised" class="organizationContainer mat-elevation-z8">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="isLoadingFacilities || facilitySandboxLoading"
    ></mat-progress-bar>

    <mat-error
      *ngIf="
        noFacilitiesAssigned && !isLoadingFacilities && !facilitySandboxLoading
      "
    >
      No facility has been assigned. Please contact your administrator to have
      access to member records.
    </mat-error>

    <form
      style="display: flex; justify-content: center; column-gap: 20px"
      [formGroup]="facilitiesForm"
      *ngIf="facilities && facilities.length > 0"
    >
      <mat-form-field>
        <mat-label *ngIf="!selectedFacilityId">
          Please select a facility
        </mat-label>
        <mat-label *ngIf="selectedFacilityId"> Selected Facility </mat-label>
        <mat-select
          formControlName="facility"
          [(value)]="selectedFacilityId"
          (selectionChange)="selectedValue($event)"
        >
          <mat-option *ngFor="let facility of facilities" [value]="facility.id">
            {{ facility.facilityName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </mat-card>
</div>

<!-- Patients List, show when facility is selected or it is consolidated search -->
<div class="row">
  <div
    class="col-md-12"
    style="padding-top: 30px"
    *ngIf="selectedFacilityId || consolidatedMemberSearch"
  >
    <app-patients
      [consolidatedMemberSearch]="consolidatedMemberSearch"
      [facilityId]="selectedFacilityId"
      [facilityName]="selectedFacilityName"
    ></app-patients>
  </div>
</div>
