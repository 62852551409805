import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ITherapySession, Patient } from 'src/app/_models';
import { TherapySessionService, ToastMessageService } from 'src/app/_services';
import { ExistingDiagnosisComponent } from './existing-diagnosis/existing-diagnosis.component';

@Component({
  selector: 'app-session-diagnosis-codes',
  templateUrl: './session-diagnosis-codes.component.html',
  styleUrls: ['./session-diagnosis-codes.component.css'],
})
export class SessionDiagnosisCodesComponent implements OnInit {
  @Input() therapySession: ITherapySession;
  @Input() patient: Patient;
  @Input() loggedInUserId: string;

  @Input() hasDiagnosisViewAccess: boolean;
  @Input() hasDiagnosisAddAccess: boolean;
  @Input() hasDiagnosisEditAccess: boolean;
  @Input() hasDiagnosisDeleteAccess: boolean;
  @Output() output = new EventEmitter<any>();

  diagnosisColumn = ['diagnosisIndex', 'diagnosisCode', 'diagnosisDescription'];

  public diagnosisForm: FormGroup;

  indexMap = new Map()
    .set(0, 'A')
    .set(1, 'B')
    .set(2, 'C')
    .set(3, 'D')
    .set(4, 'E')
    .set(5, 'F')
    .set(6, 'G')
    .set(7, 'H')
    .set(8, 'I')
    .set(9, 'J')
    .set(10, 'K')
    .set(11, 'L');

  processing: boolean = false;

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public therapysessionApiService: TherapySessionService,
    public toastMessageService: ToastMessageService
  ) {}

  ngOnInit(): void {
    this.buildDiagnosisForm();
    this.pushExistingDiagnosis();

    if (
      this.therapySession.status === 'Active' &&
      this.hasDiagnosisDeleteAccess
    ) {
      this.diagnosisColumn.push('actions');
    }

    if (
      this.therapySession.status === 'Active' &&
      this.hasDiagnosisEditAccess
    ) {
      this.diagnosisColumn.splice(1, 0, 'moveArrows');
    }
  }

  buildDiagnosisForm() {
    this.diagnosisForm = this.formBuilder.group({
      diagnosisCodes: this.formBuilder.array([]),
    });
  }

  addDiagnosis() {
    const dialogRef = this.dialog.open(ExistingDiagnosisComponent, {
      data: {
        patient: this.patient,
        therapySession: this.therapySession,
        loggedInUserId: this.loggedInUserId,
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '50vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        this.pushDiagnosis(response.diagnosis);
      }
    });
  }

  pushExistingDiagnosis() {
    const control = <FormArray>this.diagnosisForm.get('diagnosisCodes');

    if (
      this.therapySession.diagnosisCodes &&
      this.therapySession.diagnosisCodes.length > 0
    ) {
      this.therapySession.diagnosisCodes.forEach((diagnosis) => {
        control.push(
          new FormGroup({
            id: new FormControl(diagnosis.id),
            icdCode: new FormControl(diagnosis.icdCode),
            shortDesc: new FormControl(diagnosis.shortDesc),
            longDesc: new FormControl(diagnosis.longDesc),
            diagnosisIndex: new FormControl(diagnosis.diagnosisIndex),
            status: new FormControl(diagnosis.status),
          })
        );
      });
    }
  }

  pushDiagnosis(diagnosis) {
    const control = <FormArray>this.diagnosisForm.get('diagnosisCodes');
    let index = control.value.length;

    control.push(
      new FormGroup({
        id: new FormControl(diagnosis.id),
        icdCode: new FormControl(diagnosis.icdCode),
        shortDesc: new FormControl(diagnosis.shortDesc),
        longDesc: new FormControl(diagnosis.longDesc),
        diagnosisIndex: new FormControl(this.indexMap.get(index)),
        status: new FormControl(diagnosis.status),
      })
    );

    // Auto Update Diagnosis codes
    this.updateBillingDiagnosis();
  }

  triggerEvent(payload: any) {
    this.output.emit(payload);
  }

  moveDiagnosis(direction, index) {
    const control = <FormArray>this.diagnosisForm.get('diagnosisCodes');
    let controlLength = control.length;

    if (controlLength == 1) {
      return;
    }

    // Upward
    if (direction === 'UP') {
      let previosIndex = index - 1;
      if (index == 0 || previosIndex < 0) {
        return;
      }

      // Now change position
      let tempFormGroup1 = Object.assign({}, control.at(index)) as FormGroup;
      let tempFormGroup2 = Object.assign(
        {},
        control.at(previosIndex)
      ) as FormGroup;

      control.at(index).setValue(tempFormGroup2.value);
      control.at(previosIndex).setValue(tempFormGroup1.value);

      let changeIndexControl1 = control.at(index) as FormGroup;
      let changeIndexControl2 = control.at(previosIndex) as FormGroup;

      changeIndexControl1.controls['diagnosisIndex'].setValue(
        this.indexMap.get(index)
      );
      changeIndexControl2.controls['diagnosisIndex'].setValue(
        this.indexMap.get(previosIndex)
      );

      // Auto Update Diagnosis codes
      this.updateBillingDiagnosis();
    } else {
      // Downward
      let nextIndex = index + 1;
      if (index - 1 == controlLength || nextIndex > controlLength - 1) {
        return;
      }

      let tempFormGroup1 = Object.assign({}, control.at(index)) as FormGroup;
      let tempFormGroup2 = Object.assign(
        {},
        control.at(nextIndex)
      ) as FormGroup;

      control.at(index).setValue(tempFormGroup2.value);
      control.at(nextIndex).setValue(tempFormGroup1.value);

      let changeIndexControl1 = control.at(index) as FormGroup;
      let changeIndexControl2 = control.at(nextIndex) as FormGroup;

      changeIndexControl1.controls['diagnosisIndex'].setValue(
        this.indexMap.get(index)
      );
      changeIndexControl2.controls['diagnosisIndex'].setValue(
        this.indexMap.get(nextIndex)
      );

      // Auto Update Diagnosis codes
      this.updateBillingDiagnosis();
    }
  }

  removeDiagnosis(index) {
    const control = <FormArray>this.diagnosisForm.get('diagnosisCodes');
    control.removeAt(index);

    // Resizing the index
    for (let i = index; i < control.length; i++) {
      let subFormGroup = control.at(i) as FormGroup;
      subFormGroup.controls['diagnosisIndex'].setValue(this.indexMap.get(i));
    }

    // Auto Update Diagnosis codes
    this.updateBillingDiagnosis();
  }

  updateBillingDiagnosis() {
    this.therapySession = Object.assign(
      this.therapySession,
      this.diagnosisForm.value
    );

    this.processing = true;
    this.therapysessionApiService
      .updateBillingInformation(this.therapySession.id, this.therapySession)
      .subscribe({
        next: (response) => {
          // this.toastMessageService.displaySuccessMessage(
          //   'Successfully updated the diagnosis information.'
          // );
          this.processing = false;
          this.output.emit({
            eventType: 'RELOAD_THERPAY_OBJECT',
            therapySession: response.data,
          });
        },
        error: (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to updated the diagnosis information.'
          );
        },
      });
  }
}
