<div>
  <p style="font-size: 16px; font-weight: 450">
    <a routerLink="/" routerLinkActive="active">
      <em
        class="material-icons text_align-center"
        style="font-size: 18px; margin-top: -3px"
        >home</em
      >
    </a>
    &nbsp; / &nbsp; Remittances
  </p>
</div>

<!-- Search Box -->
<div
  class="row"
  style="
    margin-left: 5px;
    margin-right: 5px;
    row-gap: 15px;
    margin-bottom: 15px;
  "
>
  <div class="col-sm-12 col-md-9">
    <div class="row" style="row-gap: 15px; column-gap: 15px; flex-wrap: wrap">
      <!-- Date Picker -->
      <div
        class="col"
        style="
          display: flex;
          align-items: center;
          border-radius: 10px;
          border: 1px solid rgb(0, 0, 0, 0.2);
          background-color: #ffffff;
          min-width: 300px;
        "
      >
        <span
          class="material-icons"
          style="text-align: left; font-size: 20px; opacity: 0.5"
        >
          calendar_month
        </span>
        <mat-date-range-input
          [rangePicker]="picker"
          [max]="currentTime"
          matTooltip="To search, please select the date range and click on the search icon"
        >
          <input
            matStartDate
            placeholder="Date Range"
            (click)="picker.open()"
            [(ngModel)]="searchStartDate"
          />
          <input
            matEndDate
            [(ngModel)]="searchEndDate"
            (click)="picker.open()"
          />
        </mat-date-range-input>
        <button
          matIconSuffix
          type="button"
          mat-icon-button
          (click)="searchChanged()"
        >
          <mat-icon>search</mat-icon>
        </button>
        <button
          matIconSuffix
          type="button"
          mat-icon-button
          (click)="clearDate()"
        >
          <mat-icon>cancel</mat-icon>
        </button>
        <mat-date-range-picker
          #picker
          [disabled]="false"
        ></mat-date-range-picker>
      </div>

      <div
        class="col"
        style="
          display: flex;
          align-items: center;
          padding: 5px;
          border-radius: 10px;
          border: 1px solid rgb(0, 0, 0, 0.2);
          background-color: #ffffff;
          padding: 5px;
          min-width: 140px;
        "
      >
        <span
          class="material-icons"
          style="text-align: left; font-size: 20px; opacity: 0.5"
        >
          pin
        </span>
        <input
          type="search"
          placeholder="Reference #"
          [(ngModel)]="refNumber"
          (ngModelChange)="searchChanged()"
          style="
            padding-left: 15px;
            width: 100%;
            background: transparent;
            border: 0;
          "
        />
      </div>

      <div
        class="col"
        style="
          display: flex;
          align-items: center;
          padding: 5px;
          border-radius: 10px;
          border: 1px solid rgb(0, 0, 0, 0.2);
          background-color: #ffffff;
          padding: 5px;
          min-width: 140px;
        "
      >
        <span
          class="material-icons"
          style="text-align: left; font-size: 20px; opacity: 0.5"
        >
          search
        </span>
        <input
          type="search"
          placeholder="Payer Name"
          [(ngModel)]="payerName"
          (ngModelChange)="searchChanged()"
          style="
            padding-left: 15px;
            width: 100%;
            background: transparent;
            border: 0;
          "
        />
      </div>

      <div
        class="col"
        style="
          display: flex;
          align-items: center;
          padding: 5px;
          border-radius: 10px;
          border: 1px solid rgb(0, 0, 0, 0.2);
          background-color: #ffffff;
          padding: 5px;
          min-width: 140px;
        "
      >
        <span
          class="material-icons"
          style="text-align: left; font-size: 20px; opacity: 0.5"
        >
          search
        </span>
        <input
          type="search"
          placeholder="Payee Name"
          [(ngModel)]="payeeName"
          (ngModelChange)="searchChanged()"
          style="
            padding-left: 15px;
            width: 100%;
            background: transparent;
            border: 0;
          "
        />
      </div>
    </div>
  </div>

  <div
    class="col-sm-12 col-md-3"
    style="
      column-gap: 20px;
      display: flex;
      flex-wrap: wrap;
      text-align: right;
      flex-direction: row-reverse;
      padding: 0;
      row-gap: 10px;
    "
  >
    <!-- Add button -->
    <button
      mat-raised-button
      type="button"
      color="warn"
      (click)="addNewRemit()"
    >
      + Add Remit
    </button>

    <!-- Sort button -->
    <button mat-raised-button [matMenuTriggerFor]="sortMenu">
      <span style="white-space: break-spaces"> Status {{ searchStatus }} </span>
      <mat-icon matSuffix>arrow_drop_down</mat-icon>
    </button>

    <mat-menu #sortMenu="matMenu">
      <div *ngFor="let option of searchStatusList">
        <button
          mat-menu-item
          style="font-weight: 400; font-size: 12px; width: 100%"
          (click)="optionChanged(option)"
        >
          {{ option }}
        </button>
      </div>
    </mat-menu>
  </div>
</div>

<div *ngIf="processing || apiError">
  <mat-progress-bar mode="indeterminate" *ngIf="processing"></mat-progress-bar>

  <mat-error *ngIf="apiError">
    An error occurred while retrieving remittances
  </mat-error>
</div>

<div *ngIf="data.total === 0 && !processing">
  <mat-error> No remittances found </mat-error>
</div>

<div class="mat-elevation-z8" *ngIf="data.total > 0" style="margin-top: 25px">
  <div class="table-responsive">
    <table mat-table [dataSource]="data.items" class="mat-elevation-z8">
      <ng-container matColumnDef="referenceNo">
        <th mat-header-cell *matHeaderCellDef>Reference Number</th>
        <td mat-cell *matCellDef="let row">
          <button
            mat-button
            type="button"
            color="primary"
            (click)="viewRemit(row)"
          >
            {{ row.paymentReferenceNumber }}
          </button>
          <br />
          <label> Uploaded : {{ row.createdDate | date : 'M/d/y' }} </label>
        </td>
      </ng-container>

      <ng-container matColumnDef="payer">
        <th mat-header-cell *matHeaderCellDef>Payer</th>
        <td mat-cell *matCellDef="let row">
          <div class="columnsGrid">
            <label class="labelBold">
              {{ row?.payerName }}
            </label>
            <label style="margin: 0" *ngIf="row?.payerAddressLine1">
              {{ row?.payerAddressLine1 }}
            </label>
            <label style="margin: 0" *ngIf="row?.payerAddressLine1">
              {{ row?.payerCity }}, {{ row?.payerState }}, {{ row?.payerZip }}
            </label>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="payee">
        <th mat-header-cell *matHeaderCellDef>Payee</th>
        <td mat-cell *matCellDef="let row">
          <div class="columnsGrid">
            <label class="labelBold">
              {{ row?.payeeName }}
            </label>
            <label style="margin: 0" *ngIf="row?.payeeAddressLine1">
              {{ row?.payeeAddressLine1 }}
            </label>
            <label style="margin: 0" *ngIf="row?.payeeAddressLine1">
              {{ row?.payeeCity }}, {{ row?.payeeState }}, {{ row?.payeeZip }}
            </label>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="paymentDetails">
        <th mat-header-cell *matHeaderCellDef>Payment Details</th>
        <td mat-cell *matCellDef="let row">
          <div class="columnsGrid">
            <label>
              Payment Method -
              <b> {{ row?.paymentMethodDescription }}</b>
            </label>

            <label>
              Amount -
              <b>{{ row?.paymentAmount | currency : 'USD' }}</b>
            </label>

            <label>
              Effective Date -
              <b>{{ row?.paymentEffectiveDate }}</b>
            </label>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let row">
          <div class="justifyCenterContainer">
            <span
              [class]="
                row.status === 'New'
                  ? 'newStatus'
                  : row.status === 'Needs Review'
                  ? 'reviewStatus'
                  : row.status === 'Recorded'
                  ? 'recordedStatus'
                  : ''
              "
            >
              {{ row.status }}
            </span>
            <mat-icon color="warn" *ngIf="row.status === 'Needs Review'">
              error_outline
            </mat-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef style="text-align: right">
          Actions
        </th>
        <td mat-cell *matCellDef="let row" style="text-align: right">
          <button
            type="button"
            mat-raised-button
            [matMenuTriggerFor]="menu"
            class="btn btn-just-icon btn-white btn-fab btn-round"
          >
            <i class="material-icons text_align-center">more_vert</i>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item type="button" (click)="viewRemit(row)">
              <mat-icon style="color: #2196f3"> visibility </mat-icon>
              <span>View</span>
            </button>
            <button
              mat-menu-item
              type="button"
              *ngIf="row?.remitType === 'Manual'"
              (click)="editRemit(row)"
            >
              <mat-icon style="color: #f39121"> edit </mat-icon>
              <span>Edit</span>
            </button>
            <!-- <button mat-menu-item type="button">
              <mat-icon style="color: #3e2abd"> print </mat-icon>
              <span>Print</span>
            </button> -->
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <mat-paginator
    [length]="data.total"
    [pageSize]="data.per_page"
    *ngIf="data.total > data.per_page"
    (page)="getNext($event)"
    [showFirstLastButtons]="true"
  ></mat-paginator>
</div>
