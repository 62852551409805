import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { INewClinicalNote, ITherapySession } from 'src/app/_models';
import { TherapySessionService, ToastMessageService } from 'src/app/_services';

@Component({
  selector: 'app-change-note-type-dialog',
  templateUrl: './change-note-type-dialog.component.html',
  styleUrl: './change-note-type-dialog.component.css',
})
export class ChangeNoteTypeDialogComponent {
  therapySession: ITherapySession;
  clinicalNoteTypes: any[];

  newNotetype: any;
  processing: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ChangeNoteTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public therapysessionApiService: TherapySessionService,
    private toastMessageService: ToastMessageService
  ) {
    this.therapySession = data.therapySession;
    this.clinicalNoteTypes = data.clinicalNoteTypes;
  }
  ngOnInit(): void {}

  public cancel() {
    this.dialogRef.close();
  }

  public confirm() {
    this.processing = true;

    let newClinicalNote: INewClinicalNote = {
      therapySessionId: this.therapySession.id,
      clinicalNoteId: this.newNotetype.id,
      clinicalNoteTypeName: this.newNotetype.name,
    };

    this.therapysessionApiService
      .addNewSessionClinicalNote(newClinicalNote)
      .subscribe({
        next: (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully changed the note type.'
          );
          this.dialogRef.close({
            type: 'success',
            therapySession: response.data,
          });
          this.processing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to update the note. ' + error?.text
          );
          this.processing = false;
        },
      });
  }

  @HostListener('keydown.esc')
  public onEsc() {
    this.dialogRef.close();
  }
}
