<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Goal:{{ goal.goalIndex }} Follow Ups</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      [mat-dialog-close]="reloadRequired"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <!-- Body -->
  <div
    style="
      margin-top: 20px;
      border: 2px solid gainsboro;
      padding: 10px;
      border-radius: 15px;
    "
  >
    <div
      [class]="isPatient ? 'oneGridTable' : 'followUpTable'"
      style="border-bottom: 2px solid gainsboro"
    >
      <label class="labelText">Date</label>
      <label class="labelText" *ngIf="!isPatient">Notes</label>
    </div>
    <div
      *ngFor="let followUp of goal.followUpDetails; let i = index"
      style="margin-top: 10px; position: relative"
    >
      <div [class]="isPatient ? 'oneGridTable' : 'followUpTable'">
        <label class="labelText">
          {{ followUp.followUpDate | date : 'MM/dd/y' }}
        </label>
        <label class="labelText" style="width: 95%" *ngIf="!isPatient">
          {{ followUp?.followUpNote }}
        </label>
      </div>
      <span
        *ngIf="goal?.status === 'Assigned' && !isPatient"
        class="material-icons"
        style="
          color: gray;
          font-size: 15px;
          cursor: pointer;
          z-index: 2;
          position: absolute;
          right: 2px;
          top: 2px;
        "
        (click)="addEditNote(i)"
      >
        edit
      </span>
    </div>
  </div>
</div>
