<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Personality Type Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="personalityTypeForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-12 asterix-after">1. At a party do you:</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q1">
              <mat-button-toggle value="Interact with many, including strangers"
                >Interact with many; including strangers</mat-button-toggle
              >
              <mat-button-toggle value="Interact with a few, known to you"
                >Interact with a few; known to you</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">2. Are you more:</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q2">
              <mat-button-toggle value="Realistic than speculative"
                >Realistic than speculative</mat-button-toggle
              >
              <mat-button-toggle value="Speculative than realistic"
                >Speculative than realistic</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">3. Is it worse to:</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q3">
              <mat-button-toggle value="Have your “head in the clouds”"
                >Have your “head in the clouds”</mat-button-toggle
              >
              <mat-button-toggle value="Be “in a rut”"
                >Be “in a rut”</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >4. Are you more impressed by:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q4">
              <mat-button-toggle value="Principles"
                >Principles</mat-button-toggle
              >
              <mat-button-toggle value="Emotions">Emotions</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >5. Are more drawn toward the:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q5">
              <mat-button-toggle value="Convincing"
                >Convincing</mat-button-toggle
              >
              <mat-button-toggle value="Touching">Touching</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >6. Do you prefer to work:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q6">
              <mat-button-toggle value="To deadlines"
                >To deadlines</mat-button-toggle
              >
              <mat-button-toggle value="Just “whenever”"
                >Just “whenever”</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >7. Do you tend to choose:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q7">
              <mat-button-toggle value="Rather carefully"
                >Rather carefully</mat-button-toggle
              >
              <mat-button-toggle value="Somewhat impulsively"
                >Somewhat impulsively</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">8. At parties do you:</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q8">
              <mat-button-toggle value="Stay late, with increasing energy"
                >Stay late, with increasing energy</mat-button-toggle
              >
              <mat-button-toggle value="Leave early with decreased energy"
                >Leave early with decreased energy</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >9. Are you more attracted to:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q9">
              <mat-button-toggle value="Sensible people"
                >Sensible people</mat-button-toggle
              >
              <mat-button-toggle value="Imaginative people"
                >Imaginative people</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >10. Are you more interested in:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q10">
              <mat-button-toggle value="What is actual"
                >What is actual</mat-button-toggle
              >
              <mat-button-toggle value="What is possible"
                >What is possible</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >11. In judging others are you more swayed by:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q11">
              <mat-button-toggle value="Laws than circumstances"
                >Laws than circumstances</mat-button-toggle
              >
              <mat-button-toggle value="Circumstances than laws"
                >Circumstances than laws</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >12. In approaching others is your inclination to be
            somewhat:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q12">
              <mat-button-toggle value="Objective">Objective</mat-button-toggle>
              <mat-button-toggle value="Personal">Personal</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">13. Are you more:</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q13">
              <mat-button-toggle value="Punctual">Punctual</mat-button-toggle>
              <mat-button-toggle value="Leisurely">Leisurely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >14. Does it bother you more having things:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q14">
              <mat-button-toggle value="Incomplete"
                >Incomplete</mat-button-toggle
              >
              <mat-button-toggle value="Completed">Completed</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >15. In your social groups do you:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q15">
              <mat-button-toggle value="Keep abreast of other's happenings"
                >Keep abreast of other's happenings</mat-button-toggle
              >
              <mat-button-toggle value="Get behind on the news"
                >Get behind on the news</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >16. In doing ordinary things are you more likely to:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q16">
              <mat-button-toggle value="Do it the usual way"
                >Do it the usual way</mat-button-toggle
              >
              <mat-button-toggle value="Do it your own way"
                >Do it your own way</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">17. Writers should:</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q17">
              <mat-button-toggle
                value="“Say what they mean and mean what they say”"
                >“Say what they mean and mean what they say”</mat-button-toggle
              >
              <mat-button-toggle value="Express things more by use of analogy"
                >Express things more by use of analogy</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >18. Which appeals to you more:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q18">
              <mat-button-toggle value="Consistency of thought"
                >Consistency of thought</mat-button-toggle
              >
              <mat-button-toggle value="Harmonious human relationships"
                >Harmonious human relationships</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >19. Are you more comfortable in making:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q19">
              <mat-button-toggle value="Logical judgments"
                >Logical judgments</mat-button-toggle
              >
              <mat-button-toggle value="Value judgments"
                >Value judgments</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">20. Do you want things:</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q20">
              <mat-button-toggle value="Settled and decided"
                >Settled and decided</mat-button-toggle
              >
              <mat-button-toggle value="Unsettled and undecided"
                >Unsettled and undecided</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >21. Would you say you are more:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q21">
              <mat-button-toggle value="Serious and determined"
                >Serious and determined</mat-button-toggle
              >
              <mat-button-toggle value="Easy-going"
                >Easy-going</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">22. In phoning do you:</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q22">
              <mat-button-toggle
                value="Rarely question that it will all be said"
                >Rarely question that it will all be said</mat-button-toggle
              >
              <mat-button-toggle value="Rehearse what you'll say"
                >Rehearse what you'll say</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">23. Facts:</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q23">
              <mat-button-toggle value="“Speak for themselves”"
                >“Speak for themselves”</mat-button-toggle
              >
              <mat-button-toggle value="Illustrate principles"
                >Illustrate principles</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">24. Are visionaries:</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q24">
              <mat-button-toggle value="somewhat annoying"
                >somewhat annoying</mat-button-toggle
              >
              <mat-button-toggle value="rather fascinating"
                >rather fascinating</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">25. Are you more often:</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q25">
              <mat-button-toggle value="a cool-headed person"
                >a cool-headed person</mat-button-toggle
              >
              <mat-button-toggle value="a warm-hearted person"
                >a warm-hearted person</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">26. Is it worse to be:</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q26">
              <mat-button-toggle value="unjust">unjust</mat-button-toggle>
              <mat-button-toggle value="merciless">merciless</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >27. Should one usually let events occur:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q27">
              <mat-button-toggle value="by careful selection and choice"
                >by careful selection and choice</mat-button-toggle
              >
              <mat-button-toggle value="randomly and by chance"
                >randomly and by chance</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >28. Do you feel better about:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q28">
              <mat-button-toggle value="having purchased"
                >having purchased</mat-button-toggle
              >
              <mat-button-toggle value="having the option to buy"
                >having the option to buy</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">29. In company do you:</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q29">
              <mat-button-toggle value="initiate conversation"
                >initiate conversation</mat-button-toggle
              >
              <mat-button-toggle value="wait to be approached"
                >wait to be approached</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">30. Common sense is:</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q30">
              <mat-button-toggle value="rarely questionable"
                >rarely questionable</mat-button-toggle
              >
              <mat-button-toggle value="frequently questionable"
                >frequently questionable</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >31. Children often do not:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q31">
              <mat-button-toggle value="make themselves useful enough"
                >make themselves useful enough</mat-button-toggle
              >
              <mat-button-toggle value="exercise their fantasy enough"
                >exercise their fantasy enough</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >32. In making decisions do you feel more comfortable with:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q32">
              <mat-button-toggle value="standards">standards</mat-button-toggle>
              <mat-button-toggle value="feelings">feelings</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">33. Are you more:</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q33">
              <mat-button-toggle value="firm than gentle"
                >firm than gentle</mat-button-toggle
              >
              <mat-button-toggle value="gentle than firm"
                >gentle than firm</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >34. Which is more admirable:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q34">
              <mat-button-toggle
                value="the ability to organize and be methodical"
                >the ability to organize and be methodical</mat-button-toggle
              >
              <mat-button-toggle value="the ability to adapt and make do"
                >the ability to adapt and make do</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >35. Do you put more value on:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q35">
              <mat-button-toggle value="infinite">infinite</mat-button-toggle>
              <mat-button-toggle value="open-minded"
                >open-minded</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >36. Does new and non-routine interaction with others:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q36">
              <mat-button-toggle value="stimulate and energize you"
                >stimulate and energize you</mat-button-toggle
              >
              <mat-button-toggle value="tax your reserves"
                >tax your reserves</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >37. Are you more frequently:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q37">
              <mat-button-toggle value="a practical sort of person"
                >a practical sort of person</mat-button-toggle
              >
              <mat-button-toggle value="a fanciful sort of person"
                >a fanciful sort of person</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >38. Are you more likely to:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q38">
              <mat-button-toggle value="see how others are useful"
                >see how others are useful</mat-button-toggle
              >
              <mat-button-toggle value="see how others see"
                >see how others see</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >39. Which is more satisfying:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q39">
              <mat-button-toggle value="to discuss an issue thoroughly"
                >to discuss an issue thoroughly</mat-button-toggle
              >
              <mat-button-toggle value="to arrive at agreement on an issue"
                >to arrive at agreement on an issue</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >40. Which rules you more:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q40">
              <mat-button-toggle value="your head">your head</mat-button-toggle>
              <mat-button-toggle value="your heart"
                >your heart</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >41. Are you more comfortable with work that is:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q41">
              <mat-button-toggle value="contracted"
                >contracted</mat-button-toggle
              >
              <mat-button-toggle value="done on a casual basis"
                >done on a casual basis</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >42. Do you tend to look for:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q42">
              <mat-button-toggle value="the orderly"
                >the orderly</mat-button-toggle
              >
              <mat-button-toggle value="whatever turns up"
                >whatever turns up</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">43. Do you prefer:</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q43">
              <mat-button-toggle value="many friends with brief contact"
                >many friends with brief contact</mat-button-toggle
              >
              <mat-button-toggle value="a few friends with more lengthy contact"
                >a few friends with more lengthy contact</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">44. Do you go more by:</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q44">
              <mat-button-toggle value="facts">facts</mat-button-toggle>
              <mat-button-toggle value="principles"
                >principles</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >45. Are you more interested in:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q45">
              <mat-button-toggle value="production and distribution"
                >production and distribution</mat-button-toggle
              >
              <mat-button-toggle value="design and research"
                >design and research</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >46. Which is more of a compliment:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q46">
              <mat-button-toggle value="There is a very logical person."
                >“There is a very logical person.”</mat-button-toggle
              >
              <mat-button-toggle value="There is a very sentimental person."
                >“There is a very sentimental person.”</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >47. Do you value in yourself more that you are:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q47">
              <mat-button-toggle value="unwavering"
                >unwavering</mat-button-toggle
              >
              <mat-button-toggle value="devoted">devoted</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >48. Do you more often prefer the:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q48">
              <mat-button-toggle value="final and unalterable statement"
                >final and unalterable statement</mat-button-toggle
              >
              <mat-button-toggle value="tentative and preliminary statement"
                >tentative and preliminary statement</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >49. Are you more comfortable:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q49">
              <mat-button-toggle value="after a decision"
                >after a decision</mat-button-toggle
              >
              <mat-button-toggle value="before a decision"
                >before a decision</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">50. Do you:</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q50">
              <mat-button-toggle
                value="speak easily and at length with strangers"
                >speak easily and at length with strangers</mat-button-toggle
              >
              <mat-button-toggle value="find little to say to strangers"
                >find little to say to strangers</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >51. Are you more likely to trust your:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q51">
              <mat-button-toggle value="experience"
                >experience</mat-button-toggle
              >
              <mat-button-toggle value="hunch">hunch</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">52. Do you feel:</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q52">
              <mat-button-toggle value="more practical than ingenious"
                >more practical than ingenious</mat-button-toggle
              >
              <mat-button-toggle value="more ingenious than practical"
                >more ingenious than practical</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >53. Which person is more to be complimented one of:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q53">
              <mat-button-toggle value="clear reason"
                >clear reason</mat-button-toggle
              >
              <mat-button-toggle value="strong feeling"
                >strong feeling</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >54. Are you inclined more to be:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q54">
              <mat-button-toggle value="fair-minded"
                >fair-minded</mat-button-toggle
              >
              <mat-button-toggle value="sympathetic"
                >sympathetic</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >55. Is it preferable mostly to:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q55">
              <mat-button-toggle value="make sure things are arranged"
                >make sure things are arranged</mat-button-toggle
              >
              <mat-button-toggle value="just let things happe"
                >just let things happe</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >56. In relationships should most things be:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q56">
              <mat-button-toggle value="re-negotiable"
                >re-negotiable</mat-button-toggle
              >
              <mat-button-toggle value="random and circumstantial"
                >random and circumstantial</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >57. When the phone rings do you:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q57">
              <mat-button-toggle value="hasten to get to it first"
                >hasten to get to it first</mat-button-toggle
              >
              <mat-button-toggle value="hope someone else will answer"
                >hope someone else will answer</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >58. Do you prize more in yourself:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q58">
              <mat-button-toggle value="a strong sense of reality"
                >a strong sense of reality</mat-button-toggle
              >
              <mat-button-toggle value="a vivid imagination"
                >a vivid imagination</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >59. Are you drawn more to:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q59">
              <mat-button-toggle value="fundamentals"
                >fundamentals</mat-button-toggle
              >
              <mat-button-toggle value="overtones">overtones</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >60. Which seems the greater error:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q60">
              <mat-button-toggle value="to be too passionate"
                >to be too passionate</mat-button-toggle
              >
              <mat-button-toggle value="to be too objective"
                >to be too objective</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >61. Do you see yourself as basically:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q61">
              <mat-button-toggle value="hard-headed"
                >hard-headed</mat-button-toggle
              >
              <mat-button-toggle value="soft-hearted"
                >soft-hearted</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >62. Which situation appeals to you more:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q62">
              <mat-button-toggle value="the structured and scheduled"
                >the structured and scheduled</mat-button-toggle
              >
              <mat-button-toggle value="the unstructured and unscheduled"
                >the unstructured and unscheduled</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >63. Are you a person that is more:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q63">
              <mat-button-toggle value="routinized than whimsical"
                >routinized than whimsical</mat-button-toggle
              >
              <mat-button-toggle value="whimsical than routinized"
                >whimsical than routinized</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >64. Are you more inclined to be:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q64">
              <mat-button-toggle value="easy to approach"
                >easy to approach</mat-button-toggle
              >
              <mat-button-toggle value="somewhat reserved"
                >somewhat reserved</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >65. In writings do you prefer:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q65">
              <mat-button-toggle value="the more literal"
                >the more literal</mat-button-toggle
              >
              <mat-button-toggle value="the more figurative"
                >the more figurative</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >66. Is it harder for you to:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q66">
              <mat-button-toggle value="identify with others"
                >identify with others</mat-button-toggle
              >
              <mat-button-toggle value="utilize others"
                >utilize others</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >67. Which do you wish more for yourself:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q67">
              <mat-button-toggle value="clarity of reason"
                >clarity of reason</mat-button-toggle
              >
              <mat-button-toggle value="strength of compassion"
                >strength of compassion</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >68. Which is the greater fault:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q68">
              <mat-button-toggle value="being indiscriminate"
                >being indiscriminate</mat-button-toggle
              >
              <mat-button-toggle value="being critical"
                >being critical</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after">69. Do you prefer the:</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q69">
              <mat-button-toggle value="planned event"
                >planned event</mat-button-toggle
              >
              <mat-button-toggle value="unplanned event"
                >unplanned event</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >70. Do you tend to be more:</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q70">
              <mat-button-toggle value="deliberate than spontaneous"
                >deliberate than spontaneous</mat-button-toggle
              >
              <mat-button-toggle value="spontaneous than deliberate"
                >spontaneous than deliberate</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          personalityTypeForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!personalityTypeForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
