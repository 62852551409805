<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Select Referring Provider</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <!-- Search Box -->
  <div
    class="row"
    style="margin-left: 5px; margin-right: 5px; row-gap: 15px; margin-top: 25px"
  >
    <div class="col-sm-12 col-md-8">
      <div class="row" style="row-gap: 15px; column-gap: 15px; flex-wrap: wrap">
        <div
          class="col"
          style="
            display: flex;
            align-items: center;
            padding: 5px;
            border-radius: 10px;
            border: 1px solid rgb(0, 0, 0, 0.2);
            background-color: #ffffff;
            padding: 5px;
            min-width: 180px;
          "
        >
          <span
            class="material-icons"
            style="text-align: left; font-size: 20px; opacity: 0.5"
          >
            search
          </span>
          <input
            type="search"
            placeholder="Last Name"
            [(ngModel)]="lastName"
            (ngModelChange)="searchByNameChanged()"
            style="
              padding-left: 15px;
              width: 100%;
              background: transparent;
              border: 0;
            "
          />
        </div>
        <div
          class="col"
          style="
            display: flex;
            align-items: center;
            padding: 5px;
            border-radius: 10px;
            border: 1px solid rgb(0, 0, 0, 0.2);
            background-color: #ffffff;
            padding: 5px;
            min-width: 180px;
          "
        >
          <span
            class="material-icons"
            style="text-align: left; font-size: 20px; opacity: 0.5"
          >
            search
          </span>
          <input
            type="search"
            placeholder="First Name"
            [(ngModel)]="firstName"
            (ngModelChange)="searchByNameChanged()"
            style="
              padding-left: 15px;
              width: 100%;
              background: transparent;
              border: 0;
            "
          />
        </div>
        <div
          class="col"
          style="
            display: flex;
            align-items: center;
            padding: 5px;
            border-radius: 10px;
            border: 1px solid rgb(0, 0, 0, 0.2);
            background-color: #ffffff;
            padding: 5px;
            min-width: 180px;
          "
        >
          <span
            class="material-icons"
            style="text-align: left; font-size: 20px; opacity: 0.5"
          >
            phone
          </span>
          <input
            type="search"
            placeholder="Phone #"
            [(ngModel)]="phoneNumber"
            (ngModelChange)="searchByNameChanged()"
            style="
              padding-left: 15px;
              width: 100%;
              background: transparent;
              border: 0;
            "
          />
        </div>
      </div>
    </div>

    <div
      class="col-sm-12 col-md-4"
      style="
        column-gap: 20px;
        display: flex;
        text-align: right;
        flex-direction: row-reverse;
        padding: 0;
        row-gap: 10px;
      "
    >
      <div>
        <button mat-raised-button color="warn" (click)="addReferringProvider()">
          + Add Referring Provider
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12" style="padding-top: 30px">
      <div *ngIf="referringProviderList.total === 0 && !isLoadingResults">
        <mat-error> No Referring Provider found </mat-error>
      </div>

      <div *ngIf="isLoadingResults || apiError">
        <mat-progress-bar
          mode="indeterminate"
          *ngIf="isLoadingResults"
        ></mat-progress-bar>

        <mat-error *ngIf="apiError">
          An error occurred while retrieving Referring Provider records
        </mat-error>
      </div>

      <div class="mat-elevation-z8" *ngIf="referringProviderList.total > 0">
        <div class="table-responsive">
          <table
            mat-table
            [dataSource]="referringProviderList.items"
            class="mat-elevation-z8"
            matSort
            matSortDisableClear
          >
            <ng-container matColumnDef="provider">
              <th mat-header-cell *matHeaderCellDef>Referring Provider</th>
              <td mat-cell *matCellDef="let row">
                <b>{{ row.lastName }}, {{ row.firstName }} </b>

                <span>
                  <br />
                  {{ row?.addressLine1 }} {{ row?.addressLine2 }} <br />
                  {{ row?.city }} {{ row?.state }} {{ row?.zip }}
                </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="npi">
              <th mat-header-cell *matHeaderCellDef>NPI</th>
              <td mat-cell *matCellDef="let row">
                <b>{{ row?.individualNPI }}</b>
              </td>
            </ng-container>

            <ng-container matColumnDef="taxonomy">
              <th mat-header-cell *matHeaderCellDef>Taxonomy Code</th>
              <td mat-cell *matCellDef="let row">
                {{ row?.taxonomyCode }}
              </td>
            </ng-container>

            <ng-container matColumnDef="emailAddress">
              <th mat-header-cell *matHeaderCellDef>Email Address</th>
              <td mat-cell *matCellDef="let row">{{ row.emailAddress }}</td>
            </ng-container>

            <ng-container matColumnDef="phoneNumber">
              <th mat-header-cell *matHeaderCellDef>Phone Number</th>
              <td mat-cell *matCellDef="let row">
                {{ row.phoneNumber | mask : '(000) 000-0000' }}
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let row">
                <span
                  style="white-space: break-spaces; color: #3ac47d"
                  *ngIf="status === 'Active'"
                >
                  {{ row.status }}
                </span>
                <span
                  style="white-space: break-spaces; color: #d92550"
                  *ngIf="status === 'Inactive'"
                >
                  {{ row.status }}
                </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef style="text-align: right">
                Actions
              </th>

              <td mat-cell *matCellDef="let record">
                <button
                  mat-raised-button
                  color="primary"
                  type="button"
                  (click)="select(record)"
                >
                  Select
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
        <mat-paginator
          [length]="data.total"
          [pageSize]="data.per_page"
          *ngIf="data.total > data.per_page"
          [showFirstLastButtons]="true"
          (page)="getNext($event)"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
