import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MemberConditionsList } from 'src/app/shared/utilities/memberMedicalConditions';
import {
  DataTablesModel,
  HealthCondition,
  HealthConditions,
  Patient,
} from '../../../../../_models';
import { HealthConditionsApiService } from '../../../../../_services';

@Component({
  selector: 'app-medical-history',
  templateUrl: './medical-history.component.html',
  styleUrls: ['./medical-history.component.css'],
})
export class MedicalHistoryComponent implements OnInit, OnChanges {
  @Input() patient: Patient;
  @Input() hasMemberHistoryViewAccess: boolean;
  @Input() hasMemberHistoryEditAccess: boolean;

  private patientMedicalConditions: HealthCondition[];

  memberConditionsList = MemberConditionsList;

  isLoadingResults = true;
  apiError = false;

  data: DataTablesModel = {} as DataTablesModel;
  healthConditions: HealthConditions;

  constructor(public healthConditionsApiService: HealthConditionsApiService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.patient) {
      if (this.patient) {
        this.loadHealthConditions();
      }
    }
  }

  public updateHealthCondition(condition: HealthCondition) {
    let selectedHealthConditions = [];
    for (let i = 0; i < this.memberConditionsList.length; i++) {
      if (this.memberConditionsList[i].selected) {
        selectedHealthConditions.push(this.memberConditionsList[i].name);
      }
    }

    if (!this.healthConditions) {
      this.healthConditions = {} as HealthConditions;
    }
    this.healthConditions.memberConditions = selectedHealthConditions;
    this.healthConditions.patientId = this.patient.id;

    this.healthConditionsApiService
      .updateHealthConditions(this.patient.id, this.healthConditions)
      .subscribe(() => {
        this.loadHealthConditions();
      });
  }

  public loadHealthConditions() {
    this.isLoadingResults = true;
    this.apiError = false;

    this.healthConditionsApiService
      .getExistingConditions(this.patient.id)
      .subscribe({
        next: (response) => {
          this.isLoadingResults = false;
          if (response && response.data) {
            // Reset the member conditions list
            this.resetMemberConditions();

            this.healthConditions = response.data;

            this.patientMedicalConditions = response.data.memberConditions;
            if (this.patientMedicalConditions) {
              for (let i = 0; i < this.memberConditionsList.length; i++) {
                if (
                  this.patientMedicalConditions.includes(
                    this.memberConditionsList[i].name
                  )
                ) {
                  this.memberConditionsList[i].selected = true;
                }
              }
            }
          } else {
            this.patientMedicalConditions = [];
            this.resetMemberConditions();
          }
        },
        error: (error) => {
          this.apiError = true;
          this.isLoadingResults = false;
        },
      });
  }

  resetMemberConditions() {
    this.memberConditionsList.map((condition) => {
      condition.selected = false;
      return condition;
    });
  }
}
