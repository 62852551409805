import { Component, Input, OnInit } from '@angular/core';
import { ToastMessageService } from 'src/app/_services';
import { NotificationService } from 'src/app/_services/notifications.service';

@Component({
  selector: 'app-notifications-settings',
  templateUrl: './notifications-settings.component.html',
  styleUrls: ['./notifications-settings.component.css'],
})
export class NotificationsSettingsComponent implements OnInit {
  @Input() isPhysician: boolean;
  @Input() isPatient: boolean;

  processing: boolean = false;

  oldNotificationSettings = [];
  newNotificationSettings = [];

  physicianDefaultSettings = [];

  patientDefaultSettings = [
    'ASSESSMENT_EMAIL',
    'THERAPY_SESSION_EMAIL',
    'GOALS_EMAIL',
  ];

  constructor(
    private notificationService: NotificationService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit(): void {
    this.loadExistingPreferences();
  }

  loadExistingPreferences() {
    this.processing = true;

    this.notificationService.getNotificationSettings().subscribe(
      (response) => {
        this.processing = false;
        if (response) {
          this.oldNotificationSettings = Object.assign([], response.data);
          this.newNotificationSettings = Object.assign([], response.data);
        } else {
          // If the response is null then it is the default settings
          if (this.isPhysician) {
            this.oldNotificationSettings = Object.assign(
              [],
              this.physicianDefaultSettings
            );
            this.newNotificationSettings = Object.assign(
              [],
              this.physicianDefaultSettings
            );
          } else if (this.isPatient) {
            this.oldNotificationSettings = Object.assign(
              [],
              this.patientDefaultSettings
            );
            this.newNotificationSettings = Object.assign(
              [],
              this.patientDefaultSettings
            );
          }
        }
      },
      (error) => {
        this.processing = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to get existing notification settings.'
        );
      }
    );
  }

  handleToggleChange(event, type) {
    // Check Event type checked/not checked
    if (event.checked) {
      if (!this.newNotificationSettings.includes(type)) {
        this.newNotificationSettings.push(type);
      }
    } else {
      if (this.newNotificationSettings.includes(type)) {
        this.newNotificationSettings = this.newNotificationSettings.filter(
          (permissions) => permissions != type
        );
      }
    }
  }

  getChecked(type) {
    return this.newNotificationSettings.includes(type);
  }

  resetChanges() {
    // Deep clone only by value not reference
    this.newNotificationSettings = Object.assign(
      [],
      this.oldNotificationSettings
    );
  }

  saveChanges() {
    this.processing = true;

    this.notificationService
      .updateNotificationSettings(this.newNotificationSettings)
      .subscribe(
        (response) => {
          this.processing = false;
          this.oldNotificationSettings = Object.assign(
            [],
            this.newNotificationSettings
          );
          this.toastMessageService.displaySuccessMessage(
            'Your notification preferences have been updated.'
          );
        },
        (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to update the notification preferences. Please try submitting again.'
          );
        }
      );
  }
}
