import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { DataTablesModel, Patient } from 'src/app/_models';
import { CPTCodeApiService, ToastMessageService } from 'src/app/_services';
import { FavoritesSandbox } from 'src/app/shared/sandbox/favorites-sandbox';
import { EditConfirmProcedureCodeComponent } from '../edit-confirm-procedure-code/edit-confirm-procedure-code.component';

@Component({
  selector: 'app-search-add-procedure-code',
  templateUrl: './search-add-procedure-code.component.html',
  styleUrls: ['./search-add-procedure-code.component.css'],
})
export class SearchAddProcedureCodeComponent implements OnInit {
  parentClass: string = '';
  billingProviderId: string = '';
  patient: Patient;
  primaryInsurance: any;

  displayedColumns = ['cptCode', 'description', 'modifiers', 'action'];

  organizationalCPTList: DataTablesModel = {} as DataTablesModel;
  facilityCPTList: DataTablesModel = {} as DataTablesModel;
  payerCPTList: DataTablesModel = {} as DataTablesModel;

  searchCPTCode: string = '';
  orgProcessing = false;
  facilityProcessing = false;
  payerProcessing = false;

  primaryInsuranceSelected = false;

  sandboxFavList: any = [];

  constructor(
    public dialogRef: MatDialogRef<SearchAddProcedureCodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cPTCodeApiService: CPTCodeApiService,
    private toastMessageService: ToastMessageService,
    private favoritesSandbox: FavoritesSandbox,
    private dialog: MatDialog
  ) {
    this.parentClass = data.parentClass;
    this.patient = data.patient;
    this.primaryInsurance = data.primaryInsurance;
    this.billingProviderId = data.billingProviderId;
  }

  ngOnInit(): void {
    this.organizationalCPTList.page = 0;
    this.organizationalCPTList.per_page = 10;

    this.facilityCPTList.page = 0;
    this.facilityCPTList.per_page = 10;

    this.payerCPTList.page = 0;
    this.payerCPTList.per_page = 10;

    // Load the basic CPT Codes
    this.loadOrganizationalCPT();

    // Load Service location CPT if applicable
    if (this.billingProviderId) {
      this.loadPatientFacilityCPT();
    }

    // Load insurance cpt codes if applicable
    if (this.primaryInsurance) {
      this.primaryInsuranceSelected = true;
      this.loadInsuranceCPT();
    }

    // Subscribe to favorite sandbox to update the starts
    this.favoritesSandbox.favoritesPayload$.subscribe((favoritesPayload) => {
      if (favoritesPayload) {
        this.sandboxFavList = favoritesPayload.data;

        this.toggleAllFavCodes();
      }
    });
  }

  loadOrganizationalCPT() {
    this.orgProcessing = true;

    this.cPTCodeApiService
      .getOrganizationCptCodeList(
        this.searchCPTCode,
        this.organizationalCPTList.per_page,
        this.organizationalCPTList.page
      )
      .subscribe({
        next: (response) => {
          if (response && response.items) {
            this.organizationalCPTList.items = response.items;
            this.organizationalCPTList.total = response.total;

            this.toggleAllFavCodes();
          } else {
            this.organizationalCPTList.items = [];
            this.organizationalCPTList.total = 0;
          }
          this.orgProcessing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get the organization procedural codes'
          );
          this.orgProcessing = false;
        },
      });
  }

  loadPatientFacilityCPT() {
    this.facilityProcessing = true;

    this.cPTCodeApiService
      .getCptCodeListByFacility(
        this.billingProviderId,
        this.searchCPTCode,
        this.facilityCPTList.per_page,
        this.facilityCPTList.page
      )
      .subscribe({
        next: (response) => {
          if (response && response.items) {
            this.facilityCPTList.items = response.items;
            this.facilityCPTList.total = response.total;

            this.toggleAllFavCodes();
          } else {
            this.facilityCPTList.items = [];
            this.facilityCPTList.total = 0;
          }
          this.facilityProcessing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get the member facility procedural codes'
          );
          this.facilityProcessing = false;
        },
      });
  }

  loadInsuranceCPT() {
    this.payerProcessing = true;

    this.cPTCodeApiService
      .getCptCodesByInsuranceList(
        this.primaryInsurance.payerId,
        this.searchCPTCode,
        this.payerCPTList.per_page,
        this.payerCPTList.page
      )
      .subscribe({
        next: (response) => {
          if (response && response.items) {
            this.payerCPTList.items = response.items;
            this.payerCPTList.total = response.total;

            this.toggleAllFavCodes();
          } else {
            this.payerCPTList.items = [];
            this.payerCPTList.total = 0;
          }
          this.payerProcessing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get the payer procedural codes'
          );
          this.payerProcessing = false;
        },
      });
  }

  codeSelected(row) {
    let dialogRef = this.dialog.open(EditConfirmProcedureCodeComponent, {
      data: {
        cptCode: row,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '40vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        this.dialogRef.close({ type: 'success', cptCode: response.cptCode });
      }
    });
  }

  searchChanged() {
    this.organizationalCPTList.page = 0;
    this.facilityCPTList.page = 0;

    this.loadOrganizationalCPT();

    // Load Service location CPT if applicable
    if (this.billingProviderId) {
      this.loadPatientFacilityCPT();
    }
    if (this.primaryInsurance) {
      this.loadInsuranceCPT();
    }
  }

  getNextPayerList(event) {
    this.payerCPTList.page = event.pageIndex;
    this.loadInsuranceCPT();
  }

  getNextOrgList(event) {
    this.organizationalCPTList.page = event.pageIndex;
    this.loadOrganizationalCPT();
  }

  getNextFacilityList(event) {
    this.facilityCPTList.page = event.pageIndex;
    this.loadPatientFacilityCPT();
  }

  // Toggle all favorites across the list
  toggleAllFavCodes() {
    // For organization
    if (this.organizationalCPTList.items) {
      this.organizationalCPTList.items.forEach((record) => {
        record.isFavorite = this.isFavorite('PROCEDURE_CODES', record.cptCode);
      });
    }
    // For Facilty
    if (this.facilityCPTList.items) {
      this.facilityCPTList.items.forEach((record) => {
        record.isFavorite = this.isFavorite('PROCEDURE_CODES', record.cptCode);
      });
    }

    // For insurance payers
    if (this.payerCPTList.items) {
      this.payerCPTList.items.forEach((record) => {
        record.isFavorite = this.isFavorite('PROCEDURE_CODES', record.cptCode);
      });
    }
  }

  // Determine if this Charge is fav or not
  isFavorite(categoryName: string, favoriteItem: string): boolean {
    if (this.sandboxFavList && this.sandboxFavList.favoriteCategories) {
      const category = this.sandboxFavList.favoriteCategories.find(
        (cat) => cat.name === categoryName
      );
      return category && category.userFavorites.includes(favoriteItem);
    } else {
      return false;
    }
  }

  // Toggle fav
  toggleFavorite(categoryName: string, item: any): void {
    if (item.isFavorite) {
      this.favoritesSandbox.removeFavorite(categoryName, item.cptCode);
    } else {
      this.favoritesSandbox.addFavorite(categoryName, item.cptCode);
    }
  }
}
