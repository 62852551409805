import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IIntakeHealthInfo } from 'src/app/_models/session/intake/intakehealthinfo.model';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class IntakeHealthInfoApiService {
  constructor(private httpClient: HttpClient) {}

  updateIntakeHealthInfo(
    thisIntakeHealthInfo: IIntakeHealthInfo
  ): Observable<IIntakeHealthInfo> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<IIntakeHealthInfo>(
        `${environment.apiUrl}/intakehealthinfo`,
        thisIntakeHealthInfo,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
