import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { OrgConfigReducer, VisitReasonReducer } from './reducers';
import { FacilityReducer } from './reducers/facility.reducers';
import {
  favoritesReducer,
  FavoritesState,
} from './reducers/favorites.reducers';
import { PermissionReducer } from './reducers/permission.reducers';
import { userSettingsPropsReducer } from './reducers/user-settings-props.reducers';
import { VisitReasonsState } from './state';
import { FacilityState } from './state/facility.state';
import { OrgConfigState } from './state/org-config.state';
import { PermissionState } from './state/permission.state';
import { UserSettingsPropsState } from './state/user-settings-props.state';

export interface State {
  orgConfig: OrgConfigState;
  facilities: FacilityState;
  permission: PermissionState;
  visitReasons: VisitReasonsState;
  favorites: FavoritesState;
  userSettingsProps: UserSettingsPropsState;
}

export const reducers: ActionReducerMap<State> = {
  orgConfig: OrgConfigReducer,
  facilities: FacilityReducer,
  permission: PermissionReducer,
  visitReasons: VisitReasonReducer,
  favorites: favoritesReducer,
  userSettingsProps: userSettingsPropsReducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? []
  : [];
