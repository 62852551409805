import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Patient } from 'src/app/_models';
import { DateTimeZoneService } from 'src/app/_services';
import { AddEditBehaviorIncidentDialogComponent } from 'src/app/pages/cases/case-incidents/dialogs/add-edit-behavior-incident-dialog/add-edit-behavior-incident-dialog.component';

@Component({
  selector: 'app-view-incident-logs',
  templateUrl: './view-incident-logs.component.html',
  styleUrls: ['./view-incident-logs.component.css'],
})
export class ViewIncidentLogsComponent implements OnInit {
  date: any;
  incidents: any;
  currentTimezone: string;
  patient: Patient;

  severityColorMap = new Map([
    ['Severe', 'red'],
    ['Moderate', 'orange'],
  ]);

  constructor(
    public dialogRef: MatDialogRef<ViewIncidentLogsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dateTimeZoneService: DateTimeZoneService,
    private dialog: MatDialog
  ) {
    this.date = data.date;
    this.incidents = data.incidents;
    this.patient = data.patient;
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit(): void {
    if (this.incidents.length == 1) {
      this.viewBehaviorIncident(this.incidents[0]);
    }
  }

  viewBehaviorIncident(incident: any) {
    let dialogRef2 = this.dialog.open(AddEditBehaviorIncidentDialogComponent, {
      data: {
        action: 'VIEW',
        patientId: this.patient.id,
        caseId: null,
        patient: this.patient,
        patientName: this.patient.firstName + ' ' + this.patient.lastName,
        incident: incident.behavioralIncident,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '55vw',
    });

    dialogRef2.afterClosed().subscribe(() => {
      if (this.incidents.length == 1) {
        this.dialogRef.close();
      }
    });
  }

  getBorderColor(data) {
    if (data?.severity) {
      let currentColor = this.severityColorMap.get(data.severity);
      return `10px solid ${currentColor}`;
    } else {
      return '';
    }
  }
}
