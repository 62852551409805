<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle *ngIf="action === 'ADD'">Add Insurance</p>
    <p matDialogTitle *ngIf="action === 'EDIT'">Edit Insurance</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="insuranceForm" novalidate>
    <!-- Insurance Information -->
    <mat-stepper linear #stepper>
      <mat-step>
        <ng-template matStepLabel>Insurance Info</ng-template>
        <div class="row" style="margin-top: 15px">
          <!-- Insurance Information -->
          <div class="col-md-12" style="margin-bottom: 12px">
            <label class="labelHeader">
              <mat-icon style="margin-right: 10px">badge</mat-icon> Insurance
              Information
            </label>
            <mat-card appearance="raised" class="mat-elevation-z8">
              <div class="row">
                <div class="col-md-12" style="margin-bottom: 12px">
                  <label class="labelText">
                    Insurance type <span style="color: red">*</span>
                  </label>
                  <br />
                  <mat-radio-group
                    aria-label="Select an option"
                    formControlName="insuranceType"
                    style="column-gap: 15px; display: flex; flex-wrap: wrap"
                    color="primary"
                  >
                    <mat-radio-button value="Primary"
                      >Primary insurance</mat-radio-button
                    >
                    <mat-radio-button value="Secondary"
                      >Secondary insurance</mat-radio-button
                    >
                    <mat-radio-button value="Other">Other</mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="col-md-12" style="margin-bottom: 12px">
                  <label class="labelText">
                    Member relationship to insured
                    <span style="color: red">*</span>
                  </label>
                  <br />
                  <mat-radio-group
                    aria-label="Select an option"
                    formControlName="relationship"
                    style="column-gap: 15px; display: flex; flex-wrap: wrap"
                    color="primary"
                  >
                    <mat-radio-button
                      *ngFor="let r of insuredRelationshipList"
                      [value]="r.id"
                      >{{ r.description }}</mat-radio-button
                    >
                  </mat-radio-group>
                </div>
                <div class="col-md-4">
                  <label class="labelText">
                    Insurance payer <span style="color: red">*</span>
                  </label>
                  <mat-form-field appearance="outline">
                    <mat-select
                      formControlName="payerId"
                      name="payerId"
                      required
                      (selectionChange)="insuranceSelected($event)"
                      [compareWith]="compare"
                    >
                      <mat-option>
                        <ngx-mat-select-search
                          placeholderLabel="Payer Name"
                          noEntriesFoundLabel="No matching payer found"
                          [(ngModel)]="searchPayer"
                          (ngModelChange)="searchByPayerChanged()"
                          [ngModelOptions]="{ standalone: true }"
                        ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option
                        *ngFor="let payer of insuranceList.items"
                        [value]="payer"
                      >
                        {{ payer.payerName }}
                        <span *ngIf="payer.claimPayerCode">
                          ({{ payer.claimPayerCode }})
                        </span>
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="insuranceForm.controls['payerId'].invalid"
                    >
                      Please select the Insurance Provider
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-md-4">
                  <label class="labelText">
                    Type of Health Insurance (HCFA 1500 Box 1)
                  </label>
                  <mat-form-field appearance="outline">
                    <mat-select formControlName="healthInsuranceType">
                      <mat-option
                        *ngFor="let insuranceType of healthInsuranceTypeList"
                        [value]="insuranceType.id"
                      >
                        {{ insuranceType.description }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-md-4">
                  <label class="labelText"> Claim Filing Indicator </label>
                  <mat-form-field appearance="outline">
                    <mat-select formControlName="claimFilingIndicator">
                      <mat-option
                        *ngFor="let indicatorType of claimFilingIndicatorList"
                        [value]="indicatorType.id"
                      >
                        {{ indicatorType.description }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-md-4">
                  <label class="labelText">
                    Member ID <span style="color: red">*</span>
                  </label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      formControlName="memberId"
                      name="memberId"
                      required
                    />
                    <mat-error
                      *ngIf="insuranceForm.controls['memberId'].invalid"
                    >
                      Please enter the Member ID
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <label class="labelText"> Plan ID </label>
                  <mat-form-field appearance="outline">
                    <input matInput formControlName="planId" name="planId" />
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <label class="labelText"> Group ID </label>
                  <mat-form-field appearance="outline">
                    <input matInput formControlName="groupId" name="groupId" />
                  </mat-form-field>
                </div>

                <div class="col-md-4">
                  <label class="labelText"> Insurance Payer Phone </label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      formControlName="payerPhone"
                      name="payerPhone"
                    />
                    <mat-error
                      *ngIf="insuranceForm.controls['payerPhone'].invalid"
                    >
                      Please enter the a valid phone number
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <label class="labelText"> Insurance Payer Fax </label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      formControlName="payerFax"
                      name="payerFax"
                    />
                    <mat-error
                      *ngIf="insuranceForm.controls['payerFax'].invalid"
                    >
                      Please enter the a valid fax number
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <label class="labelText"> Employer/School </label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      formControlName="employerSchool"
                      name="employerSchool"
                    />
                  </mat-form-field>
                </div>

                <div class="col-md-4">
                  <label class="labelText"> Copay </label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      type="number"
                      formControlName="copay"
                      name="copay"
                      min="0"
                    />
                    <mat-error *ngIf="insuranceForm.controls['copay'].invalid">
                      Copay needs to be greater than $0.00
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <label class="labelText"> Deductible </label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      type="number"
                      formControlName="deductible"
                      name="deductible"
                      min="0"
                    />
                    <mat-error
                      *ngIf="insuranceForm.controls['deductible'].invalid"
                    >
                      Deductible needs to be greater than $0.00
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4" *ngIf="!consentRequestId">
                  <label class="labelText">
                    Status <span style="color: red">*</span>
                  </label>
                  <mat-form-field appearance="outline">
                    <mat-select formControlName="status" placeholder="Status">
                      <mat-option value="Active">Active</mat-option>
                      <mat-option value="Inactive">Inactive</mat-option>
                    </mat-select>

                    <mat-error *ngIf="insuranceForm.controls['status'].invalid">
                      Please select the status
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-md-4">
                  <label class="labelText"> Start Date </label>
                  <mat-form-field appearance="outline">
                    <input
                      formControlName="startDate"
                      matInput
                      [matDatepicker]="startDatePicker"
                      [max]="maxDate"
                      placeholder="MM/DD/YYYY"
                      autoSlashDate
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="startDatePicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker></mat-datepicker>
                    <mat-error
                      *ngIf="insuranceForm.controls['startDate'].invalid"
                    >
                      Please select a valid start date
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <label class="labelText"> End Date </label>
                  <mat-form-field appearance="outline">
                    <input
                      formControlName="endDate"
                      matInput
                      [matDatepicker]="endDatePicker"
                      placeholder="MM/DD/YYYY"
                      [min]="insuranceForm.controls['startDate'].value"
                      autoSlashDate
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="endDatePicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #endDatePicker></mat-datepicker>
                    <mat-error
                      *ngIf="insuranceForm.controls['endDate'].invalid"
                    >
                      Please select a valid end date
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </mat-card>
          </div>

          <!-- Subscriber Information -->
          <div class="col-md-12" style="margin-bottom: 12px">
            <label class="labelHeader">
              <mat-icon style="margin-right: 10px">person</mat-icon> Subscriber
              Information
            </label>
            <mat-card appearance="raised" class="mat-elevation-z8">
              <div class="row">
                <div class="col-md-6">
                  <label class="labelText">
                    Subscriber First Name <span style="color: red">*</span>
                  </label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      formControlName="subscriberFirstName"
                      name="subscriberFirstName"
                      required
                    />
                    <mat-error
                      *ngIf="
                        insuranceForm.controls['subscriberFirstName'].invalid
                      "
                    >
                      Please enter the Subscriber First Name
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <label class="labelText">
                    Subscriber Last Name <span style="color: red">*</span>
                  </label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      formControlName="subscriberLastName"
                      name="subscriberLastName"
                      required
                    />
                    <mat-error
                      *ngIf="
                        insuranceForm.controls['subscriberLastName'].invalid
                      "
                    >
                      Please enter the Subscriber Last Name
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <label class="labelText">
                    Subscriber Date Of Birth
                    <span style="color: red">*</span></label
                  >
                  <mat-form-field appearance="outline">
                    <input
                      formControlName="subscriberDOB"
                      matInput
                      [matDatepicker]="subscriberDOBPicker"
                      [max]="maxDate"
                      placeholder="MM/DD/YYYY"
                      required
                      autoSlashDate
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="subscriberDOBPicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #subscriberDOBPicker></mat-datepicker>
                    <mat-error
                      *ngIf="insuranceForm.controls['subscriberDOB'].invalid"
                    >
                      Please enter a valid subscriber DOB
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <label class="labelText">
                    Subscriber Gender
                    <span style="color: red">*</span></label
                  >
                  <mat-form-field appearance="outline">
                    <mat-select formControlName="subscriberGender">
                      <mat-option [value]="r.id" *ngFor="let r of genderList">
                        {{ r.description }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="insuranceForm.controls['subscriberGender'].invalid"
                    >
                      Please select a valid gender
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </mat-card>
          </div>
          <!-- Address Information -->
          <div class="col-md-12" style="margin-bottom: 12px">
            <label class="labelHeader">
              <mat-icon style="margin-right: 10px">home</mat-icon>
              Insured Address
            </label>
            <mat-card appearance="raised" class="mat-elevation-z8">
              <div class="row">
                <div class="col-md-12">
                  <mat-checkbox
                    color="primary"
                    formControlName="sameInsuredAddress"
                    (change)="sameAsAddressChanged($event)"
                  >
                    Same as Member Address
                  </mat-checkbox>
                </div>
                <div class="col-md-8">
                  <label class="labelText">
                    Address 1 <span style="color: red">*</span>
                  </label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      [options]="options"
                      ngx-gp-autocomplete
                      #placesRef="ngx-places"
                      (onAddressChange)="handleAddressChange($event)"
                      formControlName="addressLine1"
                      name="addressLine1"
                      required
                      [readonly]="
                        insuranceForm.controls['sameInsuredAddress'].value
                      "
                    />

                    <mat-error
                      *ngIf="insuranceForm.controls['addressLine1'].invalid"
                    >
                      Please enter the address line 1
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <label class="labelText">Address 2</label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      formControlName="addressLine2"
                      name="addressLine2"
                      [readonly]="
                        insuranceForm.controls['sameInsuredAddress'].value
                      "
                    />
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <label class="labelText"
                    >City <span style="color: red">*</span></label
                  >
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      formControlName="city"
                      name="city"
                      placeholder="City"
                      [readonly]="
                        insuranceForm.controls['sameInsuredAddress'].value
                      "
                    />
                    <mat-error *ngIf="insuranceForm.controls['city'].invalid">
                      Please enter the city
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <label class="labelText"
                    >State <span style="color: red">*</span></label
                  >
                  <mat-form-field appearance="outline">
                    <mat-select
                      formControlName="state"
                      placeholder="State"
                      [disabled]="
                        insuranceForm.controls['sameInsuredAddress'].value
                      "
                    >
                      <mat-option
                        [value]="state.id"
                        *ngFor="let state of statesList"
                      >
                        {{ state.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="insuranceForm.controls['state'].invalid">
                      Please select the state
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <label class="labelText">
                    Zip <span style="color: red">*</span>
                  </label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      formControlName="zip"
                      name="zip"
                      placeholder="Zip"
                      [readonly]="
                        insuranceForm.controls['sameInsuredAddress'].value
                      "
                    />
                    <mat-error *ngIf="insuranceForm.controls['zip'].invalid">
                      Please enter a valid zip
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </mat-card>
          </div>

          <!-- Member Information -->
          <div class="col-md-12" style="margin-bottom: 12px">
            <label class="labelHeader">
              <mat-icon style="margin-right: 10px">person</mat-icon> Member
              Information
            </label>
            <mat-card appearance="raised" class="mat-elevation-z8">
              <div class="row">
                <div class="col-md-4">
                  <label class="labelText"> Member Name </label>
                  <mat-form-field appearance="outline">
                    <input matInput formControlName="memberName" />
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <label class="labelText"> Date of Birth </label>
                  <mat-form-field appearance="outline">
                    <input
                      formControlName="dateOfBirth"
                      matInput
                      [matDatepicker]="dobPicker"
                      autoSlashDate
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="dobPicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #dobPicker></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <label class="labelText"> Gender </label>
                  <mat-form-field appearance="outline">
                    <mat-select formControlName="gender">
                      <mat-option [value]="r.id" *ngFor="let r of genderList">
                        {{ r.description }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <!-- Disclaimer -->
                <div class="col-md-12">
                  <label style="font-style: italic; font-size: 9px">
                    * Member details are readonly. If you wish to make changes,
                    please make changes at member profile page.</label
                  >
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </mat-step>

      <!-- Authorization Information -->
      <mat-step *ngIf="!consentRequestId">
        <ng-template matStepLabel>Authorization</ng-template>
        <div class="row" style="margin-top: 15px">
          <div class="col-md-12" style="margin-bottom: 12px">
            <button
              type="button"
              mat-raised-button
              color="primary"
              (click)="addAuthorization()"
            >
              + Add Authorization
            </button>
          </div>

          <div formArrayName="priorAuths" style="width: 100%">
            <div
              class="col-md-12"
              *ngFor="
                let priorAuth of insuranceForm.get('priorAuths')['controls'];
                let i = index
              "
              style="margin-bottom: 15px"
            >
              <mat-card
                appearance="raised"
                class="mat-elevation-z8"
                [formGroupName]="i"
              >
                <div class="row" style="position: relative">
                  <div class="col-md-12" style="margin-top: 15px">
                    <label class="labelText">
                      Prior Auth # <span style="color: red">*</span>
                    </label>
                    <mat-form-field appearance="outline">
                      <input
                        matInput
                        formControlName="authNumber"
                        name="authNumber"
                        required
                      />
                      <mat-error
                        *ngIf="priorAuth.controls['authNumber'].invalid"
                      >
                        Please enter a valid prior auth #
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col">
                        <label class="labelText">
                          Visit Type <span style="color: red">*</span>
                        </label>
                        <mat-form-field appearance="outline">
                          <mat-select
                            formControlName="visitType"
                            placeholder="Visit Type"
                            (selectionChange)="visitOptionChanged($event, i)"
                          >
                            <mat-option value="Limited">Limited</mat-option>
                            <mat-option value="Unlimited">Unlimited</mat-option>
                          </mat-select>
                          <mat-error
                            *ngIf="priorAuth.controls['visitType'].invalid"
                          >
                            Please select a visit type
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div
                        class="col"
                        *ngIf="
                          priorAuth.controls['visitType'].value === 'Limited'
                        "
                      >
                        <label class="labelText">
                          # of Visits <span style="color: red">*</span>
                        </label>
                        <mat-form-field appearance="outline">
                          <input
                            matInput
                            formControlName="totalVisitNumber"
                            name="totalVisitNumber"
                            type="number"
                          />
                          <mat-error
                            *ngIf="
                              priorAuth.controls['totalVisitNumber'].invalid
                            "
                          >
                            Please enter a valid total visits
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label class="labelText">
                      Effective Start Date <span style="color: red">*</span>
                    </label>
                    <mat-form-field appearance="outline">
                      <input
                        formControlName="effectiveStartDate"
                        matInput
                        [matDatepicker]="estartDatePicker"
                        placeholder="MM/DD/YYYY"
                        required
                        autoSlashDate
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="estartDatePicker"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #estartDatePicker></mat-datepicker>
                      <mat-error
                        *ngIf="priorAuth.controls['effectiveStartDate'].invalid"
                      >
                        Please select the start date
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <label class="labelText">
                      Effective End Date <span style="color: red">*</span>
                    </label>
                    <mat-form-field appearance="outline">
                      <input
                        formControlName="effectiveEndDate"
                        matInput
                        [matDatepicker]="eEndDatePicker"
                        [min]="priorAuth.controls['effectiveStartDate'].value"
                        placeholder="MM/DD/YYYY"
                        required
                        autoSlashDate
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="eEndDatePicker"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #eEndDatePicker></mat-datepicker>
                      <mat-error
                        *ngIf="priorAuth.controls['effectiveEndDate'].invalid"
                      >
                        Please select a valid end date
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <label class="labelText"> Authorization Contact </label>
                    <mat-form-field appearance="outline">
                      <input
                        matInput
                        formControlName="authorizationContactName"
                        name="authorizationContactName"
                        placeholder="Contact Full Name"
                      />
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <label class="labelText"> Contact Phone </label>
                    <mat-form-field appearance="outline">
                      <input
                        matInput
                        formControlName="authorizationContactPhone"
                        name="authorizationContactPhone"
                      />
                      <mat-error
                        *ngIf="
                          priorAuth.controls['authorizationContactPhone']
                            .invalid
                        "
                      >
                        Please enter a valid phone number
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-12">
                    <label class="labelText"> Notes </label>
                    <mat-form-field appearance="outline">
                      <textarea
                        matInput
                        formControlName="notes"
                        name="notes"
                        rows="3"
                      >
                      </textarea>
                    </mat-form-field>
                  </div>
                </div>
                <div
                  style="position: absolute; right: 15px; z-index: 2; top: 0px"
                >
                  <button
                    mat-icon-button
                    type="button"
                    (click)="removeAuthorization(i)"
                  >
                    <mat-icon
                      style="color: red; opacity: 0.5"
                      id="deleteButton"
                    >
                      delete_forever
                    </mat-icon>
                  </button>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-step>
    </mat-stepper>
    <div
      class="col-md-12"
      style="display: flex; justify-content: end; margin-bottom: 15px"
    >
      <button
        class="btn btn-primary"
        mat-raised-button
        type="submit"
        [disabled]="!insuranceForm.valid || processing"
        (click)="submitForm()"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </div>
  </form>
</div>
