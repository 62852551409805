import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataTablesModel, ResponseModal } from 'src/app/_models';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CustomFormApiService {
  constructor(private httpClient: HttpClient) {}

  getFormVersion(formVersionId: string): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.customFormsUrl}/customformversion/` + formVersionId
    );
  }

  getGuestLinkFormContents(formVersionId: string): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.customFormsUrl}/guestlink/customformversion/` +
        formVersionId
    );
  }

  getActiveAndPublishedForms(): Observable<DataTablesModel> {
    return this.httpClient.get<DataTablesModel>(
      `${environment.customFormsUrl}/activeandpublishedforms`
    );
  }
}
