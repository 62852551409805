import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { IMyTimeSlotDuration } from '../../_models/configure/mytimeslotduration.model';
@Injectable({ providedIn: 'root' })
export class MyTimeSlotDurationApiService {
    constructor(private httpClient: HttpClient) { }
    getMyTimeSlotDurationDetails(): Observable<any> {
        return this.httpClient.get<any>(`${environment.apiUrl}/mytimeslotduration`);
    }
    updateMyTimeSlotDuration(thisMyTimeSlotDuration: IMyTimeSlotDuration): Observable<any> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.post<any>(`${environment.apiUrl}/mytimeslotduration`, thisMyTimeSlotDuration, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }
}