import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ITherapySession } from 'src/app/_models';
import { TherapySessionService, ToastMessageService } from 'src/app/_services';
import { SignatureService } from 'src/app/_services/utils/signature.service';
import { SignaturePadComponent } from 'src/app/pages/utils/signature-pad/signature-pad.component';

@Component({
  selector: 'app-sign-and-unlock',
  templateUrl: './sign-and-unlock.component.html',
  styleUrls: ['./sign-and-unlock.component.css'],
})
export class SignAndUnlockComponent implements OnInit {
  role: string = '';
  name: string = '';
  userId: string = '';
  roleType: string = '';
  type: string = '';
  signatureId: string = '';
  signatureURL: string = '';

  therapySession: ITherapySession;
  processing: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<SignAndUnlockComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private therapySessionService: TherapySessionService,
    private toastMessageService: ToastMessageService,
    private signatureService: SignatureService
  ) {
    this.role = data.role;
    this.name = data.name;
    this.userId = data.userId;
    this.type = data.type;
    this.therapySession = data.therapySession;
    this.signatureId = data.signatureId;

    if (this.role === 'Clinician/Rendering Provider') {
      this.roleType = 'Rendering';
    } else {
      this.roleType = 'Supervising';
    }
  }

  ngOnInit(): void {
    if (this.type === 'VIEW') {
      this.getSignature();
    }
  }

  getSignature() {
    this.processing = true;
    this.signatureService.getSignatureById(this.signatureId).subscribe(
      (response) => {
        this.processing = false;
        if (response.data) {
          this.signatureURL = response.data;
        } else {
          this.signatureURL = null;
        }
      },
      (error) => {
        this.processing = false;
        this.signatureURL = null;
        this.toastMessageService.displayErrorMessage(
          'Error: Could not find the signature'
        );
      }
    );
  }

  openSignaturePad() {
    const dialogRef = this.dialog.open(SignaturePadComponent, {
      data: {
        esignFormName: this.name + ' session signature',
        pageName: 'Clinical Sign & Unlock',
        pageUrl: window.location.href,
      },
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // Now process the therapy session add clinical consent form
        this.addSignatureToTherapy(result);
      }
    });
  }

  addSignatureToTherapy(signatureId) {
    this.processing = true;

    if (this.role === 'Clinician/Rendering Provider') {
      this.therapySession.signedPhysicianId = this.userId;
      this.therapySession.physicianSignatureId = signatureId;
      this.therapySession.physicianSigned = 'Yes';
      this.therapySession.physicianSignatureDate = new Date();
    } else if (this.role === 'Supervising Rendering Provider') {
      this.therapySession.signedSupervisorId = this.userId;
      this.therapySession.supervisorSignatureId = signatureId;
      this.therapySession.supervisorSigned = 'Yes';
      this.therapySession.supervisorSignatureDate = new Date();
    }

    this.therapySessionService
      .updateSignature(
        this.therapySession.id,
        this.therapySession,
        this.roleType
      )
      .subscribe(
        (response) => {
          this.processing = false;
          this.toastMessageService.displaySuccessMessage(
            'Signature has been successfully logged'
          );
          this.dialogRef.close({ type: 'success', details: response });
        },
        (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to log the signature'
          );
        }
      );
  }
}
