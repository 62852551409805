import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DataTablesModel, IContact, ResponseModal } from '../../_models';

@Injectable({ providedIn: 'root' })
export class ContactHttpService {
  constructor(private httpClient: HttpClient) {}

  getContacts(
    patientId: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('patientId', patientId);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());
    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/contacts`,
      { params: params }
    );
  }

  getContactDetails(contactId: string): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/contact/` + contactId
    );
  }

  addContact(contact: IContact): Observable<IContact> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<IContact>(`${environment.apiUrl}/contact`, contact, { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateContact(contact: IContact): Observable<IContact> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<IContact>(`${environment.apiUrl}/contact`, contact, { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
