import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ITherapySession, Patient } from 'src/app/_models';

@Component({
  selector: 'app-bpsa-clinical',
  templateUrl: './bpsa-clinical.component.html',
  styleUrls: ['./bpsa-clinical.component.css'],
})
export class BpsaClinicalComponent implements OnInit {
  @Input() patient: Patient;
  @Input() therapySession: ITherapySession;
  @Input() loggedInUserId: string;

  @Input() hasBPSAClinicalViewAccess: boolean;
  @Input() hasBPSAClinicalAddAccess: boolean;
  @Input() hasBPSAClinicalEditAccess: boolean;

  @Output() updatedTherapySession = new EventEmitter<any>();

  ngOnInit(): void {}

  isTaskCompleted(taskName: string) {
    return (
      this.therapySession.completedTasks &&
      this.therapySession.completedTasks.includes(taskName)
    );
  }

  refreshPageEvent(therapySession: any) {
    this.therapySession = therapySession;
    this.updatedTherapySession.emit(therapySession);
  }
}
