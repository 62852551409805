import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { FacilitiesSandbox } from 'src/app/shared/sandbox/facilities.sandbox';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
})
export class MainComponent implements OnInit, OnDestroy {
  public facilitiesForm: FormGroup;
  private queryParamsSubscription = new Subscription();

  selectedFacilityId: string;
  selectedFacilityName: string;
  orgConfig: any;
  facilities: any[];

  consolidatedMemberSearch: boolean = false;
  noFacilitiesAssigned: boolean = false;
  isLoadingFacilities: boolean = true;
  displayFacilityDropDown: boolean = true;
  facilitySandboxLoading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private facilitiesSandbox: FacilitiesSandbox,
    private formBuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    private orgConfigSandbox: OrgConfigSandbox
  ) {}

  ngOnInit(): void {
    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          this.orgConfig = orgConfig;

          this.consolidatedMemberSearch = this.orgConfig.features.includes(
            'CONSOLIDATED_MEMBER_SEARCH'
          );
        });
      }
    });

    this.buildForm();

    this.displayFacilityDropDown = true;

    this.queryParamsSubscription = this.route.queryParams.subscribe(
      (params) => {
        if (params['facilityId']) {
          this.selectedFacilityId = params.facilityId;
          if (params['facilityName']) {
            this.selectedFacilityName = params.facilityName;
          }
        } else {
          if (localStorage.getItem('selectedFacility')) {
            this.selectedFacilityId = JSON.parse(
              localStorage.getItem('selectedFacility')
            ).id;
            this.selectedFacilityName = JSON.parse(
              localStorage.getItem('selectedFacility')
            ).name;
          } else {
            let selectedFacility = JSON.parse(
              this.localStorageService.get('selectedFacility')
            );
            if (selectedFacility) {
              this.selectedFacilityId = selectedFacility.id;
              this.selectedFacilityName = selectedFacility.name;
            }
          }
        }
      }
    );

    if (localStorage.getItem('selectedFacility')) {
      this.selectedFacilityId = JSON.parse(
        localStorage.getItem('selectedFacility')
      ).id;

      this.facilitiesForm.controls['facility'].setValue(
        this.selectedFacilityId
      );
    }

    this.loadFacilities();
  }

  public loadFacilities() {
    this.facilities = null;
    this.isLoadingFacilities = true;
    this.noFacilitiesAssigned = false;

    this.facilitiesSandbox.facilitiesLoading$.subscribe((response) => {
      this.facilitySandboxLoading = response;
    });

    this.facilitiesSandbox.facilities$.subscribe((response) => {
      if (response) {
        this.facilities = response;
        if (this.facilities.length === 1) {
          this.selectedFacilityName = this.facilities[0].facilityName;
          this.selectedFacilityId = this.facilities[0].id;

          this.facilitiesForm.controls['facility'].setValue(
            this.facilities[0].id
          );

          let selectedFacility: any = {};
          selectedFacility.id = this.facilities[0].id;
          selectedFacility.name = this.facilities[0].facilityName;

          this.localStorageService.set('selectedFacility', selectedFacility);
        }
        if (this.facilities.length === 0) {
          this.noFacilitiesAssigned = true;
        } else {
          this.noFacilitiesAssigned = false;
        }

        // Check if the selected facility exists or not
        this.validateSelectedFacility();
        this.isLoadingFacilities = false;
      } else {
        this.noFacilitiesAssigned = true;
        this.isLoadingFacilities = false;
      }
    });
  }

  validateSelectedFacility() {
    if (this.selectedFacilityId) {
      let index = this.facilities.findIndex(
        (facility) => facility.id === this.selectedFacilityId
      );
      if (index < 0) {
        // Deselect and restore local storage
        this.selectedFacilityId = null;
        this.facilitiesForm.controls['facility'].setValue(null);
        localStorage.clear();
        this.localStorageService.clear();
      }
    }
  }

  ngOnDestroy() {
    this.queryParamsSubscription.unsubscribe();
  }

  private buildForm() {
    this.facilitiesForm = this.formBuilder.group({
      organization: new FormControl(null, Validators.compose([])),
      facility: new FormControl(null, Validators.compose([])),
    });
  }

  public selectedValue(event: MatSelectChange) {
    this.selectedFacilityId = event.value;
    this.selectedFacilityName = event.source.triggerValue;

    let selectedFacility: any = {};
    selectedFacility.id = this.selectedFacilityId;
    selectedFacility.name = this.selectedFacilityName;

    localStorage.setItem('selectedFacility', selectedFacility);

    this.localStorageService.set('selectedFacility', selectedFacility);
  }
}
