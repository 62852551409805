import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { DataTablesModel } from '../../../_models';
import { PermissionApiService } from '../../../_services';
import * as PermissionActions from '../actions/permission.actions';

@Injectable()
export class PermissionEffects {
  constructor(
    private permissionService: PermissionApiService,
    private actions$: Actions
  ) {}

  public getPermission$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PermissionActions.LoadPermissionsAction),
      mergeMap(() =>
        this.permissionService.getPermissions().pipe(
          map((permission: DataTablesModel) => {
            return PermissionActions.LoadPermissionsSuccessAction({
              payload: permission,
            });
          }),
          catchError((error: Error) => {
            return of(
              PermissionActions.LoadPermissionsFailedAction({ payload: error })
            );
          })
        )
      )
    )
  );
}
