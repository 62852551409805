export const TimeZones = [
  {
    id: 'AKT',
    description: 'Alaska Time Zone (AKT)',
  },
  {
    id: 'AT',
    description: 'Atlantic Time Zone (AT)',
  },
  {
    id: 'AZ',
    description: 'Arizona Time Zone (AZ)',
  },
  {
    id: 'CT',
    description: 'Central Time Zone (CT)',
  },
  {
    id: 'ET',
    description: 'Eastern Time Zone (ET)',
  },
  {
    id: 'HT',
    description: 'Hawaii Time Zone (HT)',
  },
  {
    id: 'MT',
    description: 'Mountain Time Zone (MT)',
  },
  {
    id: 'NT',
    description: 'Newfoundland Time Zone (NT)',
  },
  {
    id: 'PT',
    description: 'Pacific Time Zone (PT',
  },
  {
    id: 'YT',
    description: 'Yukon Time Zone (YT)',
  },
];
