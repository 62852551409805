import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Patient } from 'src/app/_models/patient/patient';

@Component({
  selector: 'app-member-report',
  templateUrl: './member-report.component.html',
  styleUrls: ['./member-report.component.css'],
})
export class MemberReportComponent implements OnInit, OnDestroy, OnChanges {
  @Input() hasMoodViewAccess: boolean;
  @Input() hasMoodAddAccess: boolean;
  @Input() hasIncidentViewAccess: boolean;
  @Input() hasIncidentAddAccess: boolean;
  @Input() patient: Patient;

  @Input() reload: Subject<boolean> = new Subject<boolean>();
  private subscriptions: Subscription = new Subscription();

  activeTab: string = '';

  reportTypes = [
    {
      title: 'Member Reports',
      forms: [],
    },
  ];

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.hasMoodViewAccess) {
      if (this.hasMoodViewAccess) {
        if (
          this.reportTypes[0].forms.indexOf(
            (form) => form.id === 'Mood Reports'
          ) < 0
        ) {
          this.reportTypes[0].forms.push({
            id: 'Mood Reports',
            tabName: 'MOOD',
          });
        }
      }
    }
    if (changes.hasIncidentViewAccess) {
      if (this.hasIncidentViewAccess) {
        if (
          this.reportTypes[0].forms.indexOf(
            (form) => form.id === 'Incident Reports'
          ) < 0
        ) {
          this.reportTypes[0].forms.push({
            id: 'Incident Reports',
            tabName: 'INCIDENTS',
          });
        }
      }
    }
  }

  ngOnInit(): void {
    // Check if they have view access or not
    const reloadSubscription = this.reload.subscribe((reload) => {
      if (reload) {
        this.activeTab = '';
      }
    });
    this.subscriptions.add(reloadSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  triggerEvent(payload: any) {
    if (payload.eventType === 'DESELECT_REPORT') {
      this.activeTab = '';
    }
  }
}
