import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import 'chartjs-plugin-annotation';
import moment from 'moment';
import { DataTablesModel } from 'src/app/_models';
import { AasApiService } from 'src/app/_services/assessments/relationship-family/aas.service';
import { greenChart, hexToRGBA, orangeChart, redChart, yellowChart } from 'src/app/shared/utilities/utilities';
@Component({
  selector: 'app-aas-charts',
  templateUrl: './aas-charts.component.html',
  styleUrls: ['./aas-charts.component.css']
})
export class AasChartsComponent implements OnInit {
  @Input() patientId: string;
  @Input() assessmentId: string;
  closeChartData: ChartConfiguration<'line'>['data'] = {
    datasets: [
      {
        tension: 0.4,
        data: [], borderColor: '#EEEEEE',
        pointBackgroundColor: '#333333',
        pointBorderColor: '#FFFFFF',
        pointHoverBackgroundColor: '#FFFFFF',
        pointHoverBorderColor: '#333333'
      },
    ]
  };
  dependChartData: ChartConfiguration<'line'>['data'] = {
    datasets: [
      {
        tension: 0.4,
        data: [], borderColor: '#EEEEEE',
        pointBackgroundColor: '#333333',
        pointBorderColor: '#FFFFFF',
        pointHoverBackgroundColor: '#FFFFFF',
        pointHoverBorderColor: '#333333'
      },
    ]
  };
  anxietyChartData: ChartConfiguration<'line'>['data'] = {
    datasets: [
      {
        tension: 0.4,
        data: [], borderColor: '#EEEEEE',
        pointBackgroundColor: '#333333',
        pointBorderColor: '#FFFFFF',
        pointHoverBackgroundColor: '#FFFFFF',
        pointHoverBorderColor: '#333333'
      },
    ]
  };


  closeChartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        title: {
          display: false
        }
      },
      annotation: {
        annotations: {
          greenBg:
          {
            type: 'box',
            yMin: 0,
            yMax: 50,
            backgroundColor: hexToRGBA(greenChart.backgroundColor, 1),
            borderColor: greenChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          }
        }
      }
    },
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        },
        min: 0,
        max: 5,
      }
    }
  };
  dependChartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        title: {
          display: false
        }
      },
      annotation: {
        annotations: {
          redBg: {
            type: 'box',
            yMin: 0,
            yMax: 5,
            backgroundColor: hexToRGBA(redChart.backgroundColor, 1),
            borderColor: redChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          }
        }
      }
    },
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        },
        min: 0,
        max: 5,
      }
    }
  };
  anxietyChartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        title: {
          display: false
        }
      },
      annotation: {
        annotations: {
          greenBg:
          {
            type: 'box',
            yMin: 0,
            yMax: 2.55,
            backgroundColor: hexToRGBA(greenChart.backgroundColor, 1),
            borderColor: greenChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          },
          redBg: {
            type: 'box',
            yMin: 2.55,
            yMax: 5,
            backgroundColor: hexToRGBA(redChart.backgroundColor, 1),
            borderColor: redChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          }
        }
      },
    },
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        },
        min: 0,
        max: 5,
      }
    }
  };
  assessmentDetailChartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        title: {
          display: false
        }
      }
    },
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        min: 1,
        max: 5,
        ticks: { stepSize: 1 },
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      }
    }
  };
  lineChartLegend = false;
  closeChartPlugins = [];
  dependChartPlugins = [];
  anxietyChartPlugins = [];
  lineChartType = 'line';
  detailChartPlugins = [];

  data: DataTablesModel = {} as DataTablesModel;
  processing$ = false;
  isLoadingResults = true;
  error: string = '';
  scoreMap = new Map();
  scoreDetails: any[] = [];
  constructor(public core10AssessmentApiService: AasApiService,
    private route: ActivatedRoute) {
  }
  ngOnInit() {
    this.isLoadingResults = true;
    this.data.page = 0;
    this.data.per_page = 10;
    this.loadAasAssessments();
  }
  public loadAasAssessments() {
    this.isLoadingResults = true;
    this.error = '';
    this.core10AssessmentApiService.getAasList(this.patientId, this.assessmentId, this.data.per_page, this.data.page).subscribe(response => {
      if (response && response.items) {
        this.data.items = response.items;
        this.data.total = response.total;
        var closeRiskScores = [];
        var dependRiskScores = [];
        var anxietyRiskScores = [];
        var labels = [];
        this.scoreDetails = [];

        for (var item of response.items) {
          let i = 1;
          if (response.items.length === 1) {
            labels.push("");
            if (item.props) {
              closeRiskScores.push(item.props['close']);
              dependRiskScores.push(item.props['depend']);
              anxietyRiskScores.push(item.props['anxiety']);
            } else {
              closeRiskScores.push(0);
              dependRiskScores.push(0);
              anxietyRiskScores.push(0);
            }
          }
          if (item.props) {
            closeRiskScores.push(item.props['close']);
            dependRiskScores.push(item.props['depend']);
            anxietyRiskScores.push(item.props['anxiety']);
          } else {
            closeRiskScores.push(0);
            dependRiskScores.push(0);
            anxietyRiskScores.push(0);
          }

          var stillUtc = moment.utc(item.createdDate).toDate();
          var assessmentDate = moment(stillUtc).local().format('MM/DD/YY');
          labels.push(assessmentDate);
          for (var key in item) {
            if (item.hasOwnProperty(key)) {
              if (item[key] && item[key].hasOwnProperty("score")) {
                var scoreObj = item[key];
                var scores = [];
                if (this.scoreMap.get(key)) {
                  scores = this.scoreMap.get(key).scores;
                }
                if (response.items.length === 1) {
                  scores.push(scoreObj["score"]);
                }
                scores.push(scoreObj["score"]);
                var chartType = this.chartType(i);
                var returnObj = { "scores": scores, "key": key, "title": scoreObj["title"], "answer": scoreObj["answer"], "categoryName": chartType, "chartData": [], "chartColor": this.getChartColor(chartType, scores) };
                this.scoreMap.set(key, returnObj);
                i++;
              }
            }
          }
        }
        this.scoreMap.forEach((value, key) => {
          let dataset: any = {
            datasets: [
              {
                "data": value.scores,
                "borderColor": value.chartColor.borderColor,
                "backgroundColor": value.chartColor.backgroundColor,
                "tension": 0.4,
                "fill": true,
                pointBackgroundColor: '#333333',
                pointRadius: 5
              }
            ],
            "labels": labels,
            "title": value.title,
            "answer": value.answer,
            "categoryName": value.categoryName
          };
          this.scoreDetails.push(dataset);
        });
        this.closeChartData.datasets[0].data = closeRiskScores;
        this.closeChartData.labels = labels;
        this.dependChartData.datasets[0].data = dependRiskScores;
        this.dependChartData.labels = labels;
        this.anxietyChartData.datasets[0].data = anxietyRiskScores;
        this.anxietyChartData.labels = labels;
        this.isLoadingResults = false;
      }
      if (response == null) {
        this.data.items = [];
        this.data.total = 0;
      }
    },
      error => {
        this.error = error;
        this.isLoadingResults = false;
      })
  }
  getChartColor(chartType: string, scoringArray: any[]) {
    var score = scoringArray[scoringArray.length - 1];
    if (chartType === 'Conflicts') {
      if (score > 4) {
        return redChart;
      } else if (score > 3) {
        return orangeChart;
      } else if (score > 2) {
        return yellowChart;
      } else {
        return greenChart;
      }
    } else {
      if (score <= 2) {
        return redChart;
      } else if (score === 3) {
        return yellowChart;
      } else if (score === 4) {
        return orangeChart;
      } else {
        return greenChart;
      }
    }
  }
  chartType(questionNumber: number) {
    var categoryName = "";
    switch (questionNumber) {
      case 1:
      case 6:
      case 8:
      case 12:
      case 13:
      case 17:
        categoryName = 'Close';
        break;
      case 2:
      case 5:
      case 7:
      case 14:
      case 16:
      case 18:
        categoryName = 'Depend';
        break;
      case 3:
      case 4:
      case 9:
      case 10:
      case 11:
      case 15:
        categoryName = 'Anxiety';
        break;
      default:
        categoryName = 'n/a';
        break;
    }
    return categoryName;
  }
}