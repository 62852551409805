<mat-card appearance="raised" class="mat-elevation-z8" style="border-radius: 15px">
    <label class="labelHeader">Facilities </label>

    <!-- Search Box -->
    <div style="
              display: flex;
              align-items: center;
              padding: 5px;
              border-radius: 10px;
              border: 1px solid rgb(0, 0, 0, 0.2);
              background-color: #ffffff;
              padding: 5px;
            ">
        <span class="material-icons" style="text-align: left; font-size: 20px; opacity: 0.5">
            search
        </span>
        <input type="search" placeholder="Facility Name" [(ngModel)]="searchFacilityName"
            [ngModelOptions]="{ standalone: true }" (ngModelChange)="searchByFacilityNameChanged()" style="
                padding-left: 15px;
                width: 100%;
                background: transparent;
                border: 0;
              " />
    </div>
    <!-- Quick Actions -->
    <div style="
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
            ">
        <button mat-button color="primary" type="button" (click)="selectAllFacilities()">
            Select All
        </button>
        <button mat-button color="warn" type="button" (click)="deselectAllFacilities()">
            Uncheck All
        </button>
    </div>

    <!-- Facilities List -->
    <div style="max-height: 450px; overflow: auto">
        <div *ngFor="let facility of facilitiesSelectionList">
            <mat-checkbox color="primary" [checked]="isFacilityVisible(facility)"
                (change)="selectDeselctFacility($event, facility)">
                {{ facility.facilityName }}
            </mat-checkbox>
        </div>
    </div>
</mat-card>