import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { setOptions } from '@mobiscroll/angular';
import { ITimeOff } from 'src/app/_models';
import { TimeOffAPIService, ToastMessageService } from 'src/app/_services';
import { dateRangeValidator } from 'src/app/shared/utilities/dateRangeValidator';

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});
@Component({
  selector: 'app-add-vacation-period',
  templateUrl: './add-vacation-period.component.html',
  styleUrls: ['./add-vacation-period.component.css'],
})
export class AddVacationPeriodComponent implements OnInit {
  public timeOffForm: FormGroup;
  processing: boolean = false;
  action: string;
  timeOff: ITimeOff;
  employeeId: string;

  constructor(
    public dialogRef: MatDialogRef<AddVacationPeriodComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private timeOffAPIService: TimeOffAPIService,
    private toastMessageService: ToastMessageService
  ) {
    this.action = data.action;
    this.timeOff = data.timeOff;
  }

  ngOnInit() {
    this.buildForm();

    if (this.action === 'EDIT') {
      this.timeOffForm.controls['id'].setValue(this.timeOff.id);
      this.timeOffForm.controls['organizationId'].setValue(
        this.timeOff.organizationId
      );
      this.timeOffForm.controls['careProviderId'].setValue(
        this.timeOff.careProviderId
      );
      this.timeOffForm.controls['careProviderFirstName'].setValue(
        this.timeOff.careProviderFirstName
      );
      this.timeOffForm.controls['careProviderLastName'].setValue(
        this.timeOff.careProviderLastName
      );
      this.timeOffForm.controls['careProviderEmail'].setValue(
        this.timeOff.careProviderEmail
      );
      this.timeOffForm.controls['startDay'].setValue(this.timeOff.startDay);
      this.timeOffForm.controls['endDay'].setValue(this.timeOff.endDay);
      this.timeOffForm.controls['reason'].setValue(this.timeOff.reason);
    }
  }

  private buildForm() {
    this.timeOffForm = this.formBuilder.group(
      {
        id: new FormControl(null),
        organizationId: new FormControl(null),
        careProviderId: new FormControl(''),
        careProviderFirstName: new FormControl(''),
        careProviderLastName: new FormControl(''),
        careProviderEmail: new FormControl(''),
        startDay: new FormControl('', Validators.required),
        endDay: new FormControl('', Validators.required),
        reason: new FormControl(''),
      },
      {
        validator: dateRangeValidator('startDay', 'endDay'),
      }
    );
  }

  onFormSubmit() {
    this.timeOff = Object.assign({}, this.timeOff);
    this.timeOff = Object.assign(this.timeOff, this.timeOffForm.value);

    this.processing = true;

    if (this.action === 'EDIT') {
      this.timeOffAPIService.updateMyTimeOff(this.timeOff).subscribe(
        () => {
          this.toastMessageService.displaySuccessMessage(
            'Time Off records have been updated'
          );
          this.processing = false;
          this.dialogRef.close('success');
        },
        () => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to update the Time Off record'
          );
        }
      );
    } else {
      this.timeOffAPIService.addMyTimeOff(this.timeOff).subscribe(
        () => {
          this.processing = false;
          this.toastMessageService.displaySuccessMessage(
            'Time Off records have been updated'
          );
          this.dialogRef.close('success');
        },
        () => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to update the Time Off record'
          );
        }
      );
    }
  }
}
