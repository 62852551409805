import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { WorkHours } from '../../_models/configure/workinghours.model';
import { DataTablesModel } from 'src/app/_models';
@Injectable({ providedIn: 'root' })
export class WorkingHoursDialogApiService {
  constructor(private httpClient: HttpClient) {}

  getAllMyProvidersSchedules(): Observable<DataTablesModel> {
    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/getproviderschedulesatassignedfacility`
    );
  }

  getProviderSchedulesForSession(
    patientId: string
  ): Observable<DataTablesModel> {
    let params = new HttpParams();

    params = params.append('patientId', patientId);

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/getproviderschedulesatmemberfacility`,
      { params: params }
    );
  }

  getWorkingHours(careProviderId: string): Observable<any> {
    let params = new HttpParams();
    if (careProviderId)
      params = params.append('careProviderId', careProviderId);

    return this.httpClient.get<any>(`${environment.apiUrl}/myschedule`, {
      params: params,
    });
  }

  upsertWorkingHours(workhours: WorkHours): Observable<WorkHours> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<WorkHours>(`${environment.apiUrl}/myschedule`, workhours, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
