<div class="container">
  <div class="dialogTitle" *ngIf="displayTitle">
    <p matDialogTitle>Urine Drug Screening Record</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form
    [formGroup]="urinaldrugscreenForm"
    novalidate
    (ngSubmit)="onUrinalDrugScreenFormSubmit()"
  >
    <div class="row" style="margin-top: 25px">
      <!-- Specimen Validity Test Results Column with Reduced Width -->
      <div
        class="col specimen-column mat-elevation-z8"
        style="margin: 0px 15px"
      >
        <div class="row">
          <div
            class="col-md-12 labelText"
            style="font-weight: 600; font-size: 17px; padding-bottom: 30px"
          >
            Specimen Validity Test Results
          </div>
          <div
            *ngFor="let test of specimenValidityTests"
            class="col-md-12"
            style="margin-bottom: 15px"
          >
            <label class="labelText">{{ test.label }}</label>
            <br />
            <mat-button-toggle-group [formControlName]="test.control">
              <mat-button-toggle value="Normal" style="width: 30%"
                >Normal</mat-button-toggle
              >
              <mat-button-toggle value="Abnormal" style="width: 32%"
                >Abnormal</mat-button-toggle
              >
              <mat-button-toggle value="Not Tested" style="width: 38%"
                >Not Tested</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
      </div>

      <!-- Drug Test Results Column -->
      <div class="col mat-elevation-z8" style="margin: 0px 15px">
        <div class="row">
          <div
            class="col-md-12 labelText"
            style="font-weight: 600; font-size: 17px; padding-bottom: 30px"
          >
            Drug Test Results
          </div>
          <div class="col-md-12">
            <table mat-table [dataSource]="drugTests" style="width: 100%">
              <!-- Drug Name Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Drug Name</th>
                <td mat-cell *matCellDef="let element">
                  <ng-container *ngIf="element.control !== 'otherDesc'">
                    {{ element.name }}
                  </ng-container>
                  <ng-container *ngIf="element.control === 'otherDesc'">
                    <span
                      *ngIf="
                        urinaldrugscreenForm.controls['other'].value === 'Yes'
                      "
                    >
                      {{ element.name }}
                    </span>
                  </ng-container>
                </td>
              </ng-container>

              <!-- Drug Code Column -->
              <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef>Drug Code</th>
                <td mat-cell *matCellDef="let element">{{ element.code }}</td>
              </ng-container>

              <!-- Radio Group for Test Results Column -->
              <ng-container matColumnDef="result">
                <th mat-header-cell *matHeaderCellDef>Test Result</th>
                <td mat-cell *matCellDef="let element">
                  <ng-container *ngIf="element.control !== 'otherDesc'">
                    <mat-button-toggle-group
                      [formControlName]="element.control"
                    >
                      <mat-button-toggle
                        value="Negative"
                        style="width: 30%"
                        *ngIf="element.control !== 'other'"
                        >Negative</mat-button-toggle
                      >
                      <mat-button-toggle
                        value="Presumptive Positive"
                        style="width: 40%"
                        *ngIf="element.control !== 'other'"
                        >Presumptive Positive</mat-button-toggle
                      >
                      <mat-button-toggle
                        value="Not Tested"
                        style="width: 30%"
                        *ngIf="element.control !== 'other'"
                        >Not Tested</mat-button-toggle
                      >
                      <mat-button-toggle
                        value="Yes"
                        style="width: 50%"
                        *ngIf="element.control === 'other'"
                        >Yes</mat-button-toggle
                      >
                      <mat-button-toggle
                        value="No"
                        style="width: 50%"
                        *ngIf="element.control === 'other'"
                        >No</mat-button-toggle
                      >
                    </mat-button-toggle-group>
                  </ng-container>
                  <ng-container *ngIf="element.control === 'otherDesc'">
                    <div
                      *ngIf="
                        urinaldrugscreenForm.controls['other'].value === 'Yes'
                      "
                    >
                      <mat-form-field appearance="outline">
                        <input
                          matInput
                          placeholder="Other Description"
                          formControlName="otherDesc"
                          name="otherDesc"
                        />
                        <mat-error
                          *ngIf="
                            urinaldrugscreenForm.controls['otherDesc'].invalid
                          "
                        >
                          Please enter a valid description
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </ng-container>
                </td>
              </ng-container>

              <!-- Table Header and Rows -->
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- Submit Button -->
    <mat-dialog-actions align="end">
      <button
        color="primary"
        mat-raised-button
        type="submit"
        [disabled]="!urinaldrugscreenForm.valid || isProcessing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="isProcessing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
