import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { DataTablesModel, ResponseModal } from '../../../_models';
import { IPatientHealthQuestionnaire } from '../../../_models/assessments/depression/patienthealthquestionnaire.model';
@Injectable({ providedIn: 'root' })
export class PatientHealthQuestionnaireApiService {
  constructor(private httpClient: HttpClient) {}

  getPatientHealthQuestionnaireDetails(
    assessmentId: string
  ): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/assessments/patienthealth/` + assessmentId
    );
  }

  addPatientHealthQuestionnaire(
    torontoEmpathyAssessment: IPatientHealthQuestionnaire
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<IPatientHealthQuestionnaire>(
        `${environment.apiUrl}/assessments/patienthealth`,
        torontoEmpathyAssessment,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  addGuestPatientHealthQuestionnaire(
    torontoEmpathyAssessment: IPatientHealthQuestionnaire
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<IPatientHealthQuestionnaire>(
        `${environment.apiUrl}/guestassessments/patienthealth`,
        torontoEmpathyAssessment,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getPatientHealthQuestionnaireList(
    patientId: string,
    assessmentId: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('patientId', patientId);
    params = params.append('assessmentId', assessmentId);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/assessments/patienthealthlist`,
      { params: params }
    );
  }
}
