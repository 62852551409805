import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Patient } from 'src/app/_models';
import {
  ClaimsAPIService,
  DateTimeZoneService,
  ToastMessageService,
} from 'src/app/_services';

@Component({
  selector: 'app-claims-transaction-history',
  templateUrl: './claims-transaction-history.component.html',
  styleUrl: './claims-transaction-history.component.css',
})
export class ClaimsTransactionHistoryComponent implements OnInit {
  claimId: string;
  parent: string;
  prevTab: string;

  isLoadingDetails: boolean = false;
  isLoadingResults: boolean = false;

  patient: Patient;
  currentTimezone: string;
  displayedColumns = ['date', 'message', 'actions'];
  claimDetails: any;
  claimsHistory = [];

  selectedViewHistory: any;
  viewHistorySelected: boolean = false;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private dateTimeZoneService: DateTimeZoneService,
    private claimsApiService: ClaimsAPIService,
    private toastMessageService: ToastMessageService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit(): void {
    this.claimId = this.route.snapshot.paramMap.get('id');
    this.route.queryParamMap.subscribe((param) => {
      this.prevTab = param.get('prevTab');
      this.parent = param.get('parent');
    });

    // Load Claim Details
    this.loadClaimDetails();

    // Load Claim Transactions
    this.loadClaimTransactions();
  }

  loadClaimDetails() {
    this.isLoadingDetails = true;
    this.claimsApiService.getClaimDetails(this.claimId).subscribe({
      next: (response) => {
        // Get claim details
        this.claimDetails = response.data;

        this.isLoadingDetails = false;
      },
      error: (error) => {
        this.isLoadingDetails = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to retrieve claim details'
        );
      },
    });
  }

  loadClaimTransactions() {
    this.isLoadingResults = true;

    this.claimsApiService.getClaimTransactionHistory(this.claimId).subscribe({
      next: (response) => {
        this.isLoadingResults = false;
        if (response && response?.items) {
          this.claimsHistory = response.items;
        }
      },
      error: (error) => {
        this.isLoadingResults = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to load claims history'
        );
      },
    });
  }

  viewHistoryDetails(claim) {
    this.selectedViewHistory = claim;
    this.viewHistorySelected = true;
    window.scrollTo(0, 0);
  }

  cancelViewClaimHistory() {
    this.selectedViewHistory = null;
    this.viewHistorySelected = false;
  }

  viewCurrentClaim() {
    this.router.navigate([`/claims/details/${this.claimId}`], {
      queryParams: {
        parent: this.parent,
        prevTab: this.prevTab,
      },
    });
  }
}
