import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  ICheckEligibility,
  IMemberInsurance,
  ITherapySession,
  Patient,
} from 'src/app/_models';
import {
  PatientInsuranceApiService,
  PatientService,
  ToastMessageService,
} from 'src/app/_services';
import { formatDate } from 'src/app/shared/utilities/utilities';
import { environment } from 'src/environments/environment';

var SockJs = require('sockjs-client');
var Stomp = require('stompjs');

@Component({
  selector: 'app-run-eligibility',
  templateUrl: './run-eligibility.component.html',
  styleUrls: ['./run-eligibility.component.css'],
})
export class RunEligibilityComponent implements OnInit, OnDestroy {
  public eligibilityForm: FormGroup;
  public stompClient: any;

  checkEligibility: ICheckEligibility;

  parentClass: string;
  therapySession: ITherapySession;
  patient: Patient;
  insurance: IMemberInsurance;

  renderingProviderList: any = [];
  filteredRenderingProviderList: any = [];
  searchByRP: string = '';
  rpLoaded = false;
  rpLoading = false;

  processing = false;
  today = new Date();

  constructor(
    public dialogRef: MatDialogRef<RunEligibilityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private toastMessageService: ToastMessageService,
    private patientService: PatientService,
    private patientEligibilityService: PatientInsuranceApiService
  ) {
    this.parentClass = data.parentClass;
    this.therapySession = data.therapySession;
    this.patient = data.patient;
    this.insurance = data.insurance;
  }

  ngOnInit(): void {
    this.loadRPList();

    this.buildForm();
  }

  ngOnDestroy(): void {
    if (this.stompClient !== null) {
      this.stompClient.disconnect();
    }
  }

  buildForm() {
    this.eligibilityForm = this.formBuilder.group({
      providerId: new FormControl('', Validators.required),
      patientId: new FormControl(this.patient.id, Validators.required),
      facilityId: new FormControl(this.patient.facilityId),
      insuranceId: new FormControl(this.insurance.id, Validators.required),
      serviceType: new FormControl(null, Validators.required),
      eligibilityLevel: new FormControl('Member', Validators.required),

      sessionId: new FormControl(null),
      startDate: new FormControl(new Date(), Validators.required),
      endDate: new FormControl(''),
    });
  }

  submitForm() {
    if (this.eligibilityForm.invalid) {
      return;
    }

    this.checkEligibility = Object.assign({}, this.checkEligibility);
    this.checkEligibility = Object.assign({}, this.eligibilityForm.value);

    this.checkEligibility.startDate = formatDate(
      new Date(this.checkEligibility.startDate)
    );

    if (this.checkEligibility.endDate) {
      this.checkEligibility.endDate = formatDate(
        new Date(this.checkEligibility.endDate)
      );
    }

    this.processing = true;
    this.patientEligibilityService
      .checkEligibility(this.checkEligibility)
      .subscribe(
        (response) => {
          this.listenToWebSocket(response.message.code);
          // this.processing = false;
          // this.dialogRef.close({ type: 'success', details: response });
        },
        (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Something went wrong while processing the eligibility request'
          );
          this.processing = false;
        }
      );
  }

  loadRPList() {
    this.rpLoaded = false;
    this.rpLoading = true;

    this.patientService
      .getActiveProvidersAtMemberFacility(this.patient.id)
      .subscribe(
        (response) => {
          if (response && response?.items) {
            this.renderingProviderList = response.items;
            this.filteredRenderingProviderList = response.items;
          } else {
            this.renderingProviderList = [];
            this.filteredRenderingProviderList = [];
          }
          this.rpLoaded = true;
          this.rpLoading = false;
        },
        (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load the Rendering Providers'
          );
          this.rpLoaded = true;
          this.rpLoading = false;
        }
      );
  }

  filterRP(event) {
    if (event) {
      this.filteredRenderingProviderList = this.renderingProviderList.filter(
        (provider) => {
          const lastName = provider.lastName.toLowerCase();
          const firstName = provider.firstName.toLowerCase();

          const concatFirstLast = firstName + ' ' + lastName;
          const concatLastFirst = lastName + ' ' + firstName;

          if (
            concatFirstLast.includes(event.toLowerCase()) ||
            concatLastFirst.includes(event.toLowerCase())
          ) {
            return true;
          } else {
            return false;
          }
        }
      );
    } else {
      this.filteredRenderingProviderList = this.renderingProviderList;
    }
  }

  listenToWebSocket(recordId: string) {
    let websocketChannel = '/message/eligibilityCheckStatus/' + recordId;

    const serverUrl = `${environment.apiUrl}/mw-socket`;
    const ws = new SockJs(serverUrl);
    this.stompClient = Stomp.over(ws);
    const that = this;

    // Listening to web socket event
    this.stompClient.connect({}, function (frame) {
      that.stompClient.subscribe(websocketChannel, (message) => {
        if (message) {
          if (message?.body) {
            that.processing = false;
            that.disconnect();
          }
        }
      });
    });
  }

  public disconnect() {
    if (this.stompClient !== null) {
      this.stompClient.disconnect();
      this.dialogRef.close({ type: 'success', details: null });
    }
  }
}
