<mat-card appearance="raised" style="border-radius: 15px">
  <!-- This code is for the top tab switch -->
  <div
    style="
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 20px;
      gap: 15px;
      align-items: center;
    "
  >
    <div style="font-weight: bold; font-size: 24px">
      Assessments
      <span>&nbsp;&nbsp;({{ data.total }})</span>
      <!-- &nbsp;<span class="material-icons"> error_outline </span> -->
    </div>
    <div>
      <button
        mat-button
        [class]="selectedTab == 'Pending' ? 'activeTab' : 'none'"
        type="button"
        (click)="switchTab('Pending')"
      >
        New
      </button>
      <button
        mat-button
        [class]="selectedTab == 'In Review' ? 'activePendingTab' : 'none'"
        type="button"
        (click)="switchTab('In Review')"
      >
        In Review
      </button>
      <button
        mat-button
        [class]="selectedTab == 'Completed' ? 'activeCompletedTab' : 'none'"
        (click)="switchTab('Completed')"
      >
        Completed
      </button>
    </div>
  </div>

  <!-- This is for search bar -->
  <div
    *ngIf="selectedTab != 'Pending'"
    class="row"
    style="
      margin-top: 10px;
      margin-bottom: 10px;
      margin-right: 20px;
      row-gap: 15px;
    "
  >
    <div
      class="col-md-12"
      style="
        column-gap: 20px;
        display: flex;
        justify-content: end;
        padding: 0;
        row-gap: 10px;
      "
    >
      <button mat-raised-button [matMenuTriggerFor]="filterMenu">
        <span style="white-space: break-spaces">
          {{ filterOption === 'None' ? 'Filter by' : filterOption }}
        </span>
        <mat-icon>filter_list</mat-icon>
      </button>

      <button mat-raised-button [matMenuTriggerFor]="sortMenu">
        <span style="white-space: break-spaces">
          {{ sortBy === 'None' ? 'Sort by' : sortBy }}
        </span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>

      <mat-menu #filterMenu="matMenu" xPosition="before" yPosition="below">
        <button mat-menu-item (click)="filterOptionChanged('None')">
          None
        </button>
        <ng-container *ngFor="let group of assessmentGroups; index as i">
          <button mat-menu-item [matMenuTriggerFor]="subMenu">
            {{ group.name }}
            <mat-menu #subMenu="matMenu" xPosition="before">
              <ng-container *ngFor="let assessment of group.assessment">
                <button
                  style="font-weight: 400; font-size: 12px; width: 100%"
                  mat-menu-item
                  [value]="assessment.name"
                  *ngIf="assessment.enabled"
                  (click)="filterOptionChanged(assessment.id)"
                >
                  {{ assessment.name }}
                </button>
              </ng-container>
            </mat-menu>
          </button>
        </ng-container>
      </mat-menu>

      <mat-menu #sortMenu="matMenu">
        <button
          mat-menu-item
          style="font-weight: 400; font-size: 12px; width: 100%"
          (click)="sortByChanged('None')"
          value="None"
        >
          None
        </button>
        <button
          (click)="sortByChanged('Date closest to now')"
          mat-menu-item
          style="font-weight: 400; font-size: 12px; width: 100%"
        >
          Date closest to now
        </button>
        <button
          (click)="sortByChanged('Date farthest from now')"
          mat-menu-item
          style="font-weight: 400; font-size: 12px; width: 100%"
        >
          Date farthest from now
        </button>
      </mat-menu>
    </div>
  </div>

  <mat-error *ngIf="data.total == 0">No assessment found</mat-error>

  <!-- This is showing the assessment data -->
  <div style="place-content: center; margin: 25px">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="isProcessing"
    ></mat-progress-bar>
    <!-- This is showing the pending assessment data -->
    <div *ngIf="selectedTab == 'Pending'">
      <div class="gridContainer">
        <div *ngFor="let assessment of data?.items">
          <mat-card
            appearance="raised"
            class="mat-elevation-z8"
            style="text-align: center; border-radius: 8px"
          >
            <div class="content">
              <div
                class="card-title"
                style="font-weight: 400; display: flex; flex-direction: column"
              >
                <span
                  style="
                    display: flex;
                    flex-direction: row;
                    place-content: space-between;
                    flex-direction: row-reverse;
                  "
                >
                  <span
                    style="
                      font-size: 10px;
                      text-align: right;
                      margin-bottom: 10px;
                      color: #198ba9;
                    "
                  >
                    Requested on:
                    {{ assessment.createdDate | date : 'mediumDate' }}
                  </span>
                  <span
                    class="material-icons"
                    style="color: red; font-size: 18px; cursor: help"
                    *ngIf="passedSevenDays(assessment.createdDate)"
                    matTooltip="Requested over a week ago"
                  >
                    error_outline
                  </span>
                </span>
                {{ assessment.assessmentCategory }}
              </div>
              <hr />
              <div class="card-text" style="font-weight: 400">
                {{ assessment.assessmentType }}
              </div>
            </div>
            <div class="actions" style="margin-top: 8px">
              <button
                mat-button
                style="color: cornflowerblue; font-size: 16px"
                (click)="takeAssessment(assessment)"
              >
                Take Assessment
              </button>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
    <div
      *ngIf="selectedTab != 'Pending' && data.total > 0"
      style="padding-bottom: 15px"
    >
      <div class="table-responsive mat-elevation-z8">
        <table mat-table [dataSource]="data.items" matSort matSortDisableClear>
          <ng-container matColumnDef="requestedDate">
            <th
              mat-header-cell
              *matHeaderCellDef
              style="background-color: #ffffff; color: gray"
            >
              Requested Date
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.createdDate | date : 'mediumDate' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="type">
            <th
              mat-header-cell
              *matHeaderCellDef
              style="background-color: #ffffff; color: gray"
            >
              Assessment Type
            </th>
            <td mat-cell *matCellDef="let row">{{ row.assessmentType }}</td>
          </ng-container>

          <ng-container matColumnDef="category">
            <th
              mat-header-cell
              *matHeaderCellDef
              style="background-color: #ffffff; color: gray"
            >
              Category
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.assessmentCategory }}
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th
              mat-header-cell
              *matHeaderCellDef
              style="background-color: #ffffff; color: gray; text-align: right"
            >
              Action
            </th>
            <td mat-cell *matCellDef="let row" style="text-align: right">
              <button
                type="button"
                color="primary"
                mat-button
                (click)="
                  openDialog(
                    'view',
                    row.assessmentType,
                    row.assessmentId,
                    row.assessmentRequestId,
                    row.patientId
                  )
                "
              >
                View
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
    <mat-paginator
      [length]="data.total"
      [pageSize]="data.per_page"
      [showFirstLastButtons]="true"
      *ngIf="data.total > data.per_page"
      (page)="getNext($event)"
    >
    </mat-paginator>
  </div>
</mat-card>
