import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { MbscModule } from '@mobiscroll/angular';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { NgxEditorModule } from 'ngx-editor';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgProgressModule } from 'ngx-progressbar';
import { CustomDirectivesModule } from 'src/app/_directives/custom-directives.module';
import { AngularMaterialModule } from 'src/app/material-imports';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { PatientDetailModule } from '../patient-details/patient-details.module';
import { ClaimsAlertComponent } from './claims-alert/claims-alert.component';
import { ClaimsDetailsComponent } from './claims-details/claims-details.component';
import { ClaimsListComponent } from './claims-list/claims-list.component';
import { ClaimsOverviewHeaderComponent } from './claims-overview-header/claims-overview-header.component';
import { ClaimsTransactionHistoryComponent } from './claims-transaction-history/claims-transaction-history.component';
import { ClaimsComponent } from './claims.component';
import { ClaimsRoutes } from './claims.routing';
import { FileOtherClaimsComponent } from './file-other-claims/file-other-claims.component';
import { LoadOtherClaimFromEobComponent } from './load-other-claim-from-eob/load-other-claim-from-eob.component';
import { SecondaryClaimsHistoryDetailsComponent } from './secondary-claims-history-details/secondary-claims-history-details.component';
import { SecondaryClaimsHistoryComponent } from './secondary-claims-history/secondary-claims-history.component';

@NgModule({
  imports: [
    RouterModule.forChild(ClaimsRoutes),
    PatientDetailModule,
    RouterModule,
    MbscModule,
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    PipesModule,
    AngularMaterialModule,
    DragDropModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    NgProgressModule,
    NgxGpAutocompleteModule,
    RxReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxEditorModule,
    CustomDirectivesModule,
  ],
  declarations: [
    ClaimsComponent,
    ClaimsListComponent,
    ClaimsAlertComponent,
    ClaimsDetailsComponent,
    ClaimsTransactionHistoryComponent,
    ClaimsOverviewHeaderComponent,
    FileOtherClaimsComponent,
    LoadOtherClaimFromEobComponent,
    SecondaryClaimsHistoryComponent,
    SecondaryClaimsHistoryDetailsComponent,
  ],
  exports: [],
  providers: [provideNgxMask()],
})
export class ClaimsModule {}
