<div class="row">
  <!-- Search Box -->
  <div
    class="col-md-12"
    style="
      column-gap: 20px;
      display: flex;
      flex-wrap: wrap;
      text-align: right;
      flex-direction: row-reverse;
      padding: 0;
      row-gap: 10px;
    "
  >
    <div *ngIf="hasMemberPaymentsAddAccess">
      <button
        mat-raised-button
        type="button"
        color="warn"
        (click)="collectNewPayment()"
      >
        + Collect New Payment
      </button>
    </div>

    <button mat-raised-button [matMenuTriggerFor]="sortMenu">
      <span style="white-space: break-spaces" *ngIf="status === ''">
        Status
      </span>
      <span
        style="white-space: break-spaces; color: #3ac47d"
        *ngIf="status === 'Success'"
      >
        Status: Success
      </span>
      <span
        style="white-space: break-spaces; color: #d36d03"
        *ngIf="status === 'Pending'"
      >
        Status: Pending
      </span>
      <span
        style="white-space: break-spaces; color: #d92550"
        *ngIf="status === 'Failed'"
      >
        Status: Failed
      </span>
      <span
        style="white-space: break-spaces; color: #d92550"
        *ngIf="status === 'Refunded'"
      >
        Status: Refunded
      </span>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>

    <mat-menu #sortMenu="matMenu">
      <button
        mat-menu-item
        style="font-weight: 400; font-size: 12px; width: 100%"
        (click)="filterOptionChanged('')"
      >
        None
      </button>
      <button
        mat-menu-item
        style="font-weight: 400; font-size: 12px; width: 100%"
        (click)="filterOptionChanged('Success')"
      >
        Success
      </button>
      <button
        mat-menu-item
        style="font-weight: 400; font-size: 12px; width: 100%"
        (click)="filterOptionChanged('Pending')"
      >
        Pending
      </button>
      <button
        mat-menu-item
        style="font-weight: 400; font-size: 12px; width: 100%"
        (click)="filterOptionChanged('Failed')"
      >
        Failed
      </button>
      <button
        mat-menu-item
        style="font-weight: 400; font-size: 12px; width: 100%"
        (click)="filterOptionChanged('Refunded')"
      >
        Refunded
      </button>
    </mat-menu>
  </div>

  <!-- Progress Bar -->
  <div class="col-md-12" *ngIf="isLoadingResults">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div class="col-md-12" style="padding-top: 30px">
    <!-- No Data -->
    <mat-error *ngIf="data.total == 0 && !isLoadingResults">
      No {{ status }} transaction record on file
    </mat-error>

    <div class="table-responsive mat-elevation-z8" *ngIf="data.total > 0">
      <table
        mat-table
        [dataSource]="data.items"
        class="mat-elevation-z8"
        matSort
        matSortDisableClear
      >
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef style="width: 180px">Date</th>
          <td mat-cell *matCellDef="let row" style="position: relative">
            <b>{{ row.paymentDate | date : 'MM/dd/YYYY' }}</b>
            <br />
            <label *ngIf="row?.sessionCode">
              Note Id:
              <span
                style="color: #2196f3; cursor: pointer; font-size: 12px"
                (click)="navigateToSession(row)"
              >
                {{ row?.sessionCode }}
              </span>
            </label>

            <button
              *ngIf="row?.paymentNote"
              id="chatButton"
              mat-icon-button
              color="primary"
              type="button"
              style="
                margin: 5px;
                opacity: 0.5;
                position: absolute;
                right: 0px;
                top: 0px;
              "
              (click)="viewNote(row)"
            >
              <mat-icon style="font-size: 16px">chat</mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="method">
          <th mat-header-cell *matHeaderCellDef>Payment Method</th>
          <td mat-cell *matCellDef="let row">
            <b>{{ row.paymentType }}</b>

            <span
              *ngIf="row.paymentType == 'Check' && row.checkNumber"
              style="text-transform: capitalize; margin-left: 10px"
            >
              ( # - {{ row.checkNumber }} )
            </span>
            <span
              *ngIf="row.paymentType == 'Card'"
              style="text-transform: capitalize; margin-left: 10px"
            >
              ( {{ row.cardType }} - {{ row.last4 }} )
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="totalAmount">
          <th mat-header-cell *matHeaderCellDef>Total Amount</th>
          <td mat-cell *matCellDef="let row">
            {{ row.totalAmount | currency : 'USD' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="fee">
          <th mat-header-cell *matHeaderCellDef>Fee</th>
          <td mat-cell *matCellDef="let row">
            {{ row.fee | currency : 'USD' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="net">
          <th mat-header-cell *matHeaderCellDef>Net</th>
          <td
            mat-cell
            *matCellDef="let row"
            [style.color]="row.net <= 0 ? 'red' : '#029102'"
          >
            {{ row.net | currency : 'USD' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let row">
            <span
              [class]="
                row.status === 'Success'
                  ? 'paidStatus'
                  : row.status === 'Pending'
                  ? 'pendingStatus'
                  : 'unPaidStatus'
              "
            >
              {{ row.status }}
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef style="text-align: right">
            Action
          </th>
          <td mat-cell *matCellDef="let row" style="text-align: right">
            <button
              *ngIf="row.status === 'Success'"
              mat-button
              color="warn"
              type="button"
              (click)="refundPayment(row)"
              [disabled]="refundProcessing"
            >
              Refund
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        [length]="data.total"
        [pageSize]="data.per_page"
        *ngIf="data.total > data.per_page"
        (page)="getNext($event)"
        [showFirstLastButtons]="true"
      ></mat-paginator>
    </div>
  </div>
</div>
