import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IOTP } from '../_models';
import { IEmailLink } from '../_models/emailLink.model';

@Injectable({ providedIn: 'root' })
export class OTPService {
  constructor(private httpClient: HttpClient) {}

  generateOTP(otpRequest: IOTP): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient.post<IOTP>(
      `${environment.apiUrl}/otpCode`,
      otpRequest,
      {
        headers,
      }
    );
  }

  verifyOTPCode(accessCode, dateOfBirth) {
    let params = new HttpParams();
    params = params.append('accessCode', accessCode);
    params = params.append('dateOfBirth', dateOfBirth);

    return this.httpClient.get<any>(`${environment.apiUrl}/verifyCode`, {
      params: params,
    });
  }

  generateEmailLink(request: IEmailLink): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient.post<IOTP>(
      `${environment.apiUrl}/generateemaillink`,
      request,
      {
        headers,
      }
    );
  }
}
