<div style="margin: 10px; padding-bottom: 40px">
  <div class="row">
    <div class="col-md-6">
      <mat-card-title>Session Documents</mat-card-title>
    </div>
    <div
      class="col-md-6"
      *ngIf="
        patient.status === 'Active' &&
        hasSessionDocumentsAddAccess &&
        therapySession.status === 'Active'
      "
      style="text-align: right"
    >
      <button mat-raised-button color="warn" (click)="addDocument()">
        + Upload Document
      </button>
    </div>
  </div>

  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoadingResults"
  ></mat-progress-bar>

  <div class="row" style="margin-bottom: 20px">
    <div class="col-md-12" *ngIf="data.total === 0 && !isLoadingResults">
      <mat-error> No document found </mat-error>
    </div>
    <mat-error *ngIf="apiError && !isLoadingResults">
      An error occurred while retrieving Document records
    </mat-error>
  </div>

  <div class="mat-elevation-z8" *ngIf="data.total > 0">
    <div class="table-responsive">
      <table mat-table [dataSource]="data.items" matSort matSortDisableClear>
        <ng-container matColumnDef="fileName">
          <th mat-header-cell *matHeaderCellDef>File Name</th>
          <td mat-cell *matCellDef="let row">
            {{ row.fileName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="uploadedOn">
          <th mat-header-cell *matHeaderCellDef>Uploaded On</th>
          <td mat-cell *matCellDef="let row">
            {{ row.createdDate | date : 'MM/dd/yyyy' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="uploadedBy">
          <th mat-header-cell *matHeaderCellDef>Uploaded By</th>
          <td mat-cell *matCellDef="let row">{{ row.uploadedBy }}</td>
        </ng-container>

        <ng-container matColumnDef="actions" stickyEnd>
          <th mat-header-cell *matHeaderCellDef style="text-align: right">
            Actions
          </th>
          <td mat-cell *matCellDef="let record" style="text-align: right">
            <button
              mat-raised-button
              [matMenuTriggerFor]="menu"
              class="btn btn-just-icon btn-white btn-fab btn-round"
            >
              <i class="material-icons text_align-center">more_vert</i>
            </button>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                (click)="downloadFile(record.sessionId, record.id)"
              >
                <mat-icon style="color: #2196f3">download</mat-icon>
                <span>Download File</span>
              </button>
              <button
                mat-menu-item
                (click)="deleteDocument(record.sessionId, record.id)"
                *ngIf="
                  hasSessionDocumentsDeleteAccess &&
                  therapySession.status === 'Active'
                "
              >
                <mat-icon style="color: red">delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <mat-paginator
    #paginator
    [showFirstLastButtons]="true"
    [length]="data.total"
    [pageSize]="data.per_page"
    *ngIf="data.total > data.per_page"
    (page)="getNext($event)"
  >
  </mat-paginator>
</div>
