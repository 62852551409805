import { Component, Input, OnInit } from '@angular/core';
import { Patient } from 'src/app/_models';

@Component({
  selector: 'app-member-contacts',
  templateUrl: './member-contacts.component.html',
  styleUrls: ['./member-contacts.component.css'],
})
export class MemberContactsComponent implements OnInit {
  @Input() hasPhysiciansViewAccess: boolean;
  @Input() hasPhysiciansAddAccess: boolean;
  @Input() hasPhysiciansEditAccess: boolean;
  @Input() hasPhysiciansDeleteAccess: boolean;

  @Input() hasContactsViewAccess: boolean;
  @Input() hasContactsAddAccess: boolean;
  @Input() hasContactsEditAccess: boolean;
  @Input() hasContactsDeleteAccess: boolean;

  @Input() patient: Patient;

  activeTab: string = '';

  constructor() {}

  ngOnInit(): void {
    if (this.hasContactsViewAccess) {
      this.activeTab = 'CONTACTS';
    } else if (this.hasPhysiciansViewAccess) {
      this.activeTab = 'PHYSICIANS';
    }
  }
}
