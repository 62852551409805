import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { DataTablesModel, Patient } from 'src/app/_models';
import { ToastMessageService } from 'src/app/_services';
import { ConsentDocumentApiService } from 'src/app/_services/consent-docs/orgconsentdocument.service';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { AcknowledgeMemberAlertsComponent } from './acknowledge-member-alerts/acknowledge-member-alerts.component';
import { MemberAddEditAlertsComponent } from './member-add-edit-alerts/member-add-edit-alerts.component';
import { VerifySubmittedDocumentsComponent } from './verify-submitted-documents/verify-submitted-documents.component';
import { CpCompleteRequestedDocumentsComponent } from './cp-complete-requested-documents/cp-complete-requested-documents.component';

@Component({
  selector: 'app-member-alerts',
  templateUrl: './member-alerts.component.html',
  styleUrl: './member-alerts.component.css',
})
export class MemberAlertsComponent implements OnInit, OnChanges {
  @Input() patient: Patient;
  @Input() isAlertAcknowledgementEnabled: boolean;
  @Input() reloadAlerts: Subject<boolean> = new Subject<boolean>();
  @Output() output = new EventEmitter<any>();

  sectionExpanded: boolean = true;
  isLoadingResults = false;
  deleteProcessing: boolean = false;

  data: DataTablesModel = {} as DataTablesModel;

  constructor(
    private dialog: MatDialog,
    public consentDocumentApiService: ConsentDocumentApiService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit(): void {
    this.reloadAlerts.subscribe((reload) => {
      if (reload) {
        this.loadMemberToDoList(false);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.patient) {
      if (this.patient) {
        this.loadMemberToDoList(true);
      }
    }
  }

  public loadMemberToDoList(showAlerts: boolean) {
    this.isLoadingResults = true;

    this.consentDocumentApiService
      .getMemberToDoList(this.patient.id)
      .subscribe({
        next: (response) => {
          this.isLoadingResults = false;
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;

            // Show Alerts first time someone enters this member screen
            if (showAlerts && this.isAlertAcknowledgementEnabled) {
              this.handleAlert();
            }
          } else {
            this.data.items = [];
            this.data.total = 0;
          }
        },
        error: (error) => {
          this.isLoadingResults = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load Member Alerts'
          );
        },
      });
  }

  handleAlert() {
    this.dialog.open(AcknowledgeMemberAlertsComponent, {
      data: { alerts: this.data, patient: this.patient },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });
  }

  addAlerts() {
    const dialogRef = this.dialog.open(MemberAddEditAlertsComponent, {
      data: { action: 'ADD', patient: this.patient },
      autoFocus: false,
      disableClose: true,
      minWidth: '40vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response && response === 'success') {
        this.loadMemberToDoList(false);
      }
    });
  }

  deleteMemberAlert(id: string, documentType: string) {
    const dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message:
          documentType === 'Member Alerts'
            ? 'Are you sure you want to remove this alert?'
            : 'Are you sure you want to cancel this document request?',
      },
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.deleteProcessing = true;

        this.consentDocumentApiService.deleteMemberAlert(id).subscribe({
          next: (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Member alert has been updated'
            );
            this.deleteProcessing = false;
            this.loadMemberToDoList(false);
          },
          error: (error) => {
            this.toastMessageService.displaySuccessMessage(
              'Error: Failed to remove the member alert'
            );
            this.deleteProcessing = false;
          },
        });
      } else {
        this.deleteProcessing = false;
      }
    });
  }

  verifyDocument(row: any) {
    let dialogRef = this.dialog.open(VerifySubmittedDocumentsComponent, {
      data: {
        row: row,
        patient: this.patient,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '45vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadMemberToDoList(false);
        // If this was a demographic information
        if (row?.documentName === 'Demographics Form') {
          this.output.emit({ eventType: 'RELOAD_PATIENT_PROFILE' });
        }
      }
    });
  }

  resendEmailAlerts() {
    if (!this.patient.email || !this.patient.phoneNumber) {
      this.toastMessageService.displayWarnMessage(
        'Both email address and phone number are needed to request the document via email. Please update member details to include both email and phone number.'
      );
      return;
    }

    this.consentDocumentApiService
      .resendIntakeEmail(this.patient.id)
      .subscribe({
        next: (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully sent the intake email'
          );
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to resend the intake email'
          );
        },
      });
  }

  // Fill out document by the care provider/provider
  fillOutDocument(row) {
    const dialogRef = this.dialog.open(CpCompleteRequestedDocumentsComponent, {
      data: {
        patient: this.patient,
        requestedDocument: row,
        process: 'fillOut',
      },
      minWidth: '55vw',
      autoFocus: false,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadMemberToDoList(false);
      }
    });
  }
}
