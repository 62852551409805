<mat-card
  apperance="raised"
  class="dashboardCardsContainer mat-elevation-z8"
  *ngIf="data.total > 0"
>
  <div class="dashboardTitleWrapper">
    <!-- Main Icon -->
    <div class="dashboardCardIcons" style="background-color: red">
      <mat-icon style="color: white">pending_actions</mat-icon>
    </div>
    <!-- Main Header -->
    <div class="dashboardMainTitle">
      <label class="labelHeader">To-Do List ({{ data.total }})</label>
    </div>
  </div>

  <div class="dashboardContentWrapper">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="isLoadingResults"
    ></mat-progress-bar>

    <mat-card
      appearance="raised"
      *ngFor="let row of data.items"
      class="mat-elevation-z8"
      style="border-left: 5px solid red; margin-bottom: 10px"
    >
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          padding-right: 15px;
          align-items: center;
        "
      >
        <!-- Document Name -->
        <label>
          {{ row.documentName }}
        </label>

        <!-- Document Action -->
        <div>
          <button
            *ngIf="row.documentType === 'Consent Documents'"
            mat-raised-button
            color="primary"
            type="button"
            (click)="signConsentDocument(row)"
          >
            <i
              class="material-icons"
              style="margin-right: 8px; font-size: 18px"
            >
              draw
            </i>
            Sign
          </button>
          <button
            *ngIf="row.documentType === 'Custom Forms'"
            mat-raised-button
            color="primary"
            type="button"
            (click)="customForm(row)"
          >
            <i
              class="material-icons"
              style="margin-right: 8px; font-size: 18px"
            >
              feed
            </i>
            Start
          </button>
          <button
            *ngIf="
              row.documentType !== 'Consent Documents' &&
              row.documentType !== 'Custom Forms'
            "
            mat-raised-button
            color="primary"
            type="button"
            (click)="updateInformation(row)"
          >
            <i
              class="material-icons"
              style="margin-right: 8px; font-size: 18px"
            >
              feed
            </i>
            Update
          </button>
        </div>
      </div>
    </mat-card>
  </div>
</mat-card>
