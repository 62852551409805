import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ICrossCuttingLevelOneElevenToSeventeen } from 'src/app/_models/assessments/general/crosscuttingleveloneeleventoseventeen.model';
import { environment } from '../../../../environments/environment';
import { DataTablesModel, ResponseModal } from '../../../_models';
@Injectable({ providedIn: 'root' })
export class CrossCuttingLevelOneElevenToSeventeenApiService {
  constructor(private httpClient: HttpClient) {}

  getCrossCuttingLevelOneElevenToSeventeenDetails(
    crosscuttingleveloneeleventoseventeenId: string
  ): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/crosscuttingleveloneeleventoseventeen/` +
        crosscuttingleveloneeleventoseventeenId
    );
  }

  addCrossCuttingLevelOneElevenToSeventeen(
    thisCrossCuttingLevelOneElevenToSeventeen: ICrossCuttingLevelOneElevenToSeventeen
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<ICrossCuttingLevelOneElevenToSeventeen>(
        `${environment.apiUrl}/crosscuttingleveloneeleventoseventeen`,
        thisCrossCuttingLevelOneElevenToSeventeen,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  addGuestCrossCuttingLevelOneElevenToSeventeen(
    thisCrossCuttingLevelOneElevenToSeventeen: ICrossCuttingLevelOneElevenToSeventeen
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<ICrossCuttingLevelOneElevenToSeventeen>(
        `${environment.apiUrl}/guestassessments/crosscuttingleveloneeleventoseventeen`,
        thisCrossCuttingLevelOneElevenToSeventeen,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getCrossCuttingLevelOneElevenToSeventeenList(
    patientId: string,
    assessmentId: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('patientId', patientId);
    params = params.append('assessmentId', assessmentId);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/assessments/crosscuttingleveloneeleventoseventeenlist`,
      { params: params }
    );
  }
}
