import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ReferralService } from 'src/app/_services/referrals/referral.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-add-attempt-dialog',
  templateUrl: './add-attempt-dialog.component.html',
  styleUrl: './add-attempt-dialog.component.css'
})
export class AddAttemptDialogComponent {
  @Output() attemptUpdated = new EventEmitter<void>();
  constructor(private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    private referralService: ReferralService,
    private datePipe: DatePipe,
    private snackBar: MatSnackBar,
  ) {

  }

  addAttemptForm: FormGroup;
  id: any;
  submitted = false;

  ngOnInit(): void {
    this.mainForm();
  }

  mainForm() {
    this.addAttemptForm = this._formBuilder.group({
      userData: this._formBuilder.group({
        attemptDate: ['', Validators.required],
        selectTime: ['', Validators.required],
        response: ['', Validators.required],
        staffAttempt: ['', Validators.required],
      }),
    })
  }

  onAttemptFormSubmit() {
    this.submitted = true;
    if (this.data?.attempt == '1st') {
      let transformdate;
      let id = this.data?.rowId;
      if (this.addAttemptForm.get('userData').get('attemptDate').value) {
        const date = new Date(this.addAttemptForm?.get('userData')?.value.attemptDate);
        transformdate = date;

      }
      let [t] = this.addAttemptForm.get('userData').get('selectTime').value.split(' ');
      let [h, m] = t.split(':').map(Number);
      let obj = {
        id: id,
        firstAttemptDate: transformdate,
        firstAttemptHour: h,
        firstAttemptMinute: m,
        firstAttemptResponse: this.addAttemptForm.get('userData').get('response').value
      }
      this.referralService.addFirstAttempt(obj).subscribe((data: any) => {
        if (this.data?.attempt == '1st') {
          this.showSnackbarTopPosition(
            '1st Attempt submitted successfully',
            'Done',
            '2000'
          );
        }
      })
      this.submitted = false;
    }

    if (this.data?.attempt == '2nd') {
      this.submitted = true;
      let transformdate;
      let id = this.data?.rowId;
      if (this.addAttemptForm.get('userData').get('attemptDate').value) {
        const date = new Date(this.addAttemptForm?.get('userData')?.value.attemptDate);
        // transformdate = this.datePipe.transform(date, 'yyyy-MM-dd', 'UTC+5:30');
        transformdate = date;
      }
      let [t] = this.addAttemptForm.get('userData').get('selectTime').value.split(' ');
      let [h, m] = t.split(':').map(Number);
      let obj = {
        id: id,
        secondAttemptDate: transformdate,
        secondAttemptHour: h,
        secondAttemptMinute: m,
        secondAttemptResponse: this.addAttemptForm.get('userData').get('response').value
      }
      this.referralService.addSecondAttempt(obj).subscribe((data: any) => {
        if (this.data?.attempt == '2nd') {
          this.showSnackbarTopPosition(
            '2nd Attempt submitted successfully',
            'Done',
            '2000'
          );
        }
      })
      this.submitted = false;
    }

    if (this.data?.attempt == '3rd') {
      this.submitted = true;
      let transformdate;
      let id = this.data?.rowId;
      if (this.addAttemptForm.get('userData').get('attemptDate').value) {
        const date = new Date(this.addAttemptForm?.get('userData')?.value.attemptDate);
        // transformdate = this.datePipe.transform(date, 'yyyy-MM-dd', 'UTC+5:30');
        transformdate = date;
      }
      let [t] = this.addAttemptForm.get('userData').get('selectTime').value.split(' ');
      let [h, m] = t.split(':').map(Number);
      let obj = {
        id: id,
        thirdAttemptDate: transformdate,
        thirdAttemptHour: h,
        thirdAttemptMinute: m,
        thirdAttemptResponse: this.addAttemptForm.get('userData').get('response').value
      }
      this.referralService.addThirdAttempt(obj).subscribe((data: any) => {
        if (this.data?.attempt == '3rd') {
          this.showSnackbarTopPosition(
            '3rd Attempt submitted successfully',
            'Done',
            '2000',
          );
        }
      })
    }
    this.submitted = false;
  }

  showSnackbarTopPosition(
    content: string,
    action: string | undefined,
    duration: any
  ) {
    this.snackBar.open(content, action, {
      duration: duration,
      verticalPosition: 'top', // Allowed values are  'top' | 'bottom'
      horizontalPosition: 'right', // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
    });
  }

  closeDialog() {
    this.attemptUpdated.emit();
    this.dialog.closeAll();
  }
}
