<div class="col-md-12" style="padding: 0">
  <div
    *ngIf="assessmentId && !isPatient"
    class="row"
    style="
      margin-bottom: 20px;
      display: flex;
      align-content: center;
      flex-direction: column;
      font-weight: 450;
    "
  >
    Risk Score
    <button
      mat-fab
      color="primary"
      aria-label="Score"
      style="margin-left: 10px"
    >
      {{ riskScore }}
    </button>
  </div>

  <form
    [formGroup]="gamingaddictionForm"
    novalidate
    (ngSubmit)="onGamingAddictionFormSubmit()"
  >
    <div class="row pad10">
      <label class="col-md-4 asterix-after"
        >1. How often do you find that you stay online longer than you
        intended?</label
      >
      <div class="col-sm-8">
        <mat-button-toggle-group formControlName="stayLongerThanIntended">
          <mat-button-toggle value="Not Applicable"
            >Not Applicable</mat-button-toggle
          >
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Occasionally"
            >Occasionally</mat-button-toggle
          >
          <mat-button-toggle value="Frequently">Frequently</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Always">Always</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10">
      <label class="col-md-4 asterix-after"
        >2. How often do you neglect household chores to spend more time
        online?</label
      >
      <div class="col-sm-8">
        <mat-button-toggle-group
          formControlName="neglectHouseholdChoresToSpendMoreTimeOnline"
        >
          <mat-button-toggle value="Not Applicable"
            >Not Applicable</mat-button-toggle
          >
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Occasionally"
            >Occasionally</mat-button-toggle
          >
          <mat-button-toggle value="Frequently">Frequently</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Always">Always</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10">
      <label class="col-md-4 asterix-after"
        >3. How often do you prefer the excitement of the Internet to intimacy
        with your partner?</label
      >
      <div class="col-sm-8">
        <mat-button-toggle-group
          formControlName="internetOverIntimacyWithYourPartner"
        >
          <mat-button-toggle value="Not Applicable"
            >Not Applicable</mat-button-toggle
          >
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Occasionally"
            >Occasionally</mat-button-toggle
          >
          <mat-button-toggle value="Frequently">Frequently</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Always">Always</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10">
      <label class="col-md-4 asterix-after"
        >4. How often do you form new relationships with fellow online
        users?</label
      >
      <div class="col-sm-8">
        <mat-button-toggle-group
          formControlName="formNewRelationshipsWithFellowOnlineUsers"
        >
          <mat-button-toggle value="Not Applicable"
            >Not Applicable</mat-button-toggle
          >
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Occasionally"
            >Occasionally</mat-button-toggle
          >
          <mat-button-toggle value="Frequently">Frequently</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Always">Always</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10">
      <label class="col-md-4 asterix-after"
        >5. How often do others in your life complain to you about the amount of
        time you spend online?</label
      >
      <div class="col-sm-8">
        <mat-button-toggle-group
          formControlName="complainToYouAboutTheAmountOfTimeYouSpendOnline"
        >
          <mat-button-toggle value="Not Applicable"
            >Not Applicable</mat-button-toggle
          >
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Occasionally"
            >Occasionally</mat-button-toggle
          >
          <mat-button-toggle value="Frequently">Frequently</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Always">Always</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10">
      <label class="col-md-4 asterix-after"
        >6. How often do your grades or school work suffer because of the amount
        of time you spend online?</label
      >
      <div class="col-sm-8">
        <mat-button-toggle-group
          formControlName="sufferBecauseOfTheAmountOfTimeYouSpendOnline"
        >
          <mat-button-toggle value="Not Applicable"
            >Not Applicable</mat-button-toggle
          >
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Occasionally"
            >Occasionally</mat-button-toggle
          >
          <mat-button-toggle value="Frequently">Frequently</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Always">Always</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10">
      <label class="col-md-4 asterix-after"
        >7. How often do you check your e-mail before something else that you
        need to do?</label
      >
      <div class="col-sm-8">
        <mat-button-toggle-group
          formControlName="checkYourEMailBeforeSomethingElseThatYouNeedToDo"
        >
          <mat-button-toggle value="Not Applicable"
            >Not Applicable</mat-button-toggle
          >
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Occasionally"
            >Occasionally</mat-button-toggle
          >
          <mat-button-toggle value="Frequently">Frequently</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Always">Always</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10">
      <label class="col-md-4 asterix-after"
        >8. How often does your job performance or productivity suffer because
        of the Internet?</label
      >
      <div class="col-sm-8">
        <mat-button-toggle-group
          formControlName="performanceOrProductivitySufferBecauseOfTheInternet"
        >
          <mat-button-toggle value="Not Applicable"
            >Not Applicable</mat-button-toggle
          >
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Occasionally"
            >Occasionally</mat-button-toggle
          >
          <mat-button-toggle value="Frequently">Frequently</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Always">Always</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10">
      <label class="col-md-4 asterix-after"
        >9. How often do you become defensive or secretive when anyone asks you
        what you do online?</label
      >
      <div class="col-sm-8">
        <mat-button-toggle-group
          formControlName="defensiveOrSecretiveWhenAnyoneAsksYouWhatYouDoOnline"
        >
          <mat-button-toggle value="Not Applicable"
            >Not Applicable</mat-button-toggle
          >
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Occasionally"
            >Occasionally</mat-button-toggle
          >
          <mat-button-toggle value="Frequently">Frequently</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Always">Always</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10">
      <label class="col-md-4 asterix-after"
        >10. How often do you block out disturbing thoughts about your life with
        soothing thoughts of the Internet?</label
      >
      <div class="col-sm-8">
        <mat-button-toggle-group
          formControlName="soothingThoughtsOfTheInternet"
        >
          <mat-button-toggle value="Not Applicable"
            >Not Applicable</mat-button-toggle
          >
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Occasionally"
            >Occasionally</mat-button-toggle
          >
          <mat-button-toggle value="Frequently">Frequently</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Always">Always</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10">
      <label class="col-md-4 asterix-after"
        >11. How often do you find yourself anticipating when you will go online
        again?</label
      >
      <div class="col-sm-8">
        <mat-button-toggle-group
          formControlName="anticipatingWhenYouWillGoOnlineAgain"
        >
          <mat-button-toggle value="Not Applicable"
            >Not Applicable</mat-button-toggle
          >
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Occasionally"
            >Occasionally</mat-button-toggle
          >
          <mat-button-toggle value="Frequently">Frequently</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Always">Always</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10">
      <label class="col-md-4 asterix-after"
        >12. How often do you fear that life without the Internet would be
        boring, empty, and joyless?</label
      >
      <div class="col-sm-8">
        <mat-button-toggle-group
          formControlName="lifeWithoutTheInternetWouldBeBoringEmptyAndJoyless"
        >
          <mat-button-toggle value="Not Applicable"
            >Not Applicable</mat-button-toggle
          >
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Occasionally"
            >Occasionally</mat-button-toggle
          >
          <mat-button-toggle value="Frequently">Frequently</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Always">Always</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10">
      <label class="col-md-4 asterix-after"
        >13. How often do you snap, yell, or act annoyed if someone bothers you
        while you are online?</label
      >
      <div class="col-sm-8">
        <mat-button-toggle-group formControlName="bothersYouWhileYouAreOnline">
          <mat-button-toggle value="Not Applicable"
            >Not Applicable</mat-button-toggle
          >
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Occasionally"
            >Occasionally</mat-button-toggle
          >
          <mat-button-toggle value="Frequently">Frequently</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Always">Always</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10">
      <label class="col-md-4 asterix-after"
        >14. How often do you lose sleep due to late-night log-ins?</label
      >
      <div class="col-sm-8">
        <mat-button-toggle-group
          formControlName="loseSleepDueToLateNightLogIns"
        >
          <mat-button-toggle value="Not Applicable"
            >Not Applicable</mat-button-toggle
          >
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Occasionally"
            >Occasionally</mat-button-toggle
          >
          <mat-button-toggle value="Frequently">Frequently</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Always">Always</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10">
      <label class="col-md-4 asterix-after"
        >15. How often do you feel preoccupied with the Internet when off-line,
        or fantasize about being online?</label
      >
      <div class="col-sm-8">
        <mat-button-toggle-group formControlName="preoccupiedWithTheInternet">
          <mat-button-toggle value="Not Applicable"
            >Not Applicable</mat-button-toggle
          >
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Occasionally"
            >Occasionally</mat-button-toggle
          >
          <mat-button-toggle value="Frequently">Frequently</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Always">Always</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10">
      <label class="col-md-4 asterix-after"
        >16. How often do you find yourself saying "just a few more minutes"
        when online?</label
      >
      <div class="col-sm-8">
        <mat-button-toggle-group
          formControlName="yourselfSayingJustAFewMoreMinutes"
        >
          <mat-button-toggle value="Not Applicable"
            >Not Applicable</mat-button-toggle
          >
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Occasionally"
            >Occasionally</mat-button-toggle
          >
          <mat-button-toggle value="Frequently">Frequently</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Always">Always</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10">
      <label class="col-md-4 asterix-after"
        >17. How often do you try to cut down the amount of time you spend
        online and fail?</label
      >
      <div class="col-sm-8">
        <mat-button-toggle-group
          formControlName="cutDownTheAmountOfTimeYouSpendOnline"
        >
          <mat-button-toggle value="Not Applicable"
            >Not Applicable</mat-button-toggle
          >
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Occasionally"
            >Occasionally</mat-button-toggle
          >
          <mat-button-toggle value="Frequently">Frequently</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Always">Always</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10">
      <label class="col-md-4 asterix-after"
        >18. How often do you try to hide how long you've been online?</label
      >
      <div class="col-sm-8">
        <mat-button-toggle-group formControlName="hideHowLongYouHaveBeenOnline">
          <mat-button-toggle value="Not Applicable"
            >Not Applicable</mat-button-toggle
          >
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Occasionally"
            >Occasionally</mat-button-toggle
          >
          <mat-button-toggle value="Frequently">Frequently</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Always">Always</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10">
      <label class="col-md-4 asterix-after"
        >19. How often do you choose to spend more time online over going out
        with others?</label
      >
      <div class="col-sm-8">
        <mat-button-toggle-group
          formControlName="spendMoreTimeOnlineOverGoingOutWithOthers"
        >
          <mat-button-toggle value="Not Applicable"
            >Not Applicable</mat-button-toggle
          >
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Occasionally"
            >Occasionally</mat-button-toggle
          >
          <mat-button-toggle value="Frequently">Frequently</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Always">Always</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10">
      <label class="col-md-4 asterix-after"
        >20. How often do you feel depressed, moody, or nervous when you are
        off-line, which goes away once you are back online?</label
      >
      <div class="col-sm-8">
        <mat-button-toggle-group
          formControlName="feelDepressedMoodyOrNervousWhenOffline"
        >
          <mat-button-toggle value="Not Applicable"
            >Not Applicable</mat-button-toggle
          >
          <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
          <mat-button-toggle value="Occasionally"
            >Occasionally</mat-button-toggle
          >
          <mat-button-toggle value="Frequently">Frequently</mat-button-toggle>
          <mat-button-toggle value="Often">Often</mat-button-toggle>
          <mat-button-toggle value="Always">Always</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          gamingaddictionForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!gamingaddictionForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
