import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { INote } from 'src/app/_models';
import { NoteHttpService, ToastMessageService } from 'src/app/_services/';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';

@Component({
  selector: 'app-add-note-dialog',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AddNoteDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AddNoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public noteApiService: NoteHttpService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private toastMessageService: ToastMessageService,
    private orgConfigSandbox: OrgConfigSandbox
  ) {
    this.action = data.action;
    this.note = data.note;
    this.patientId = data.patientId;
    this.caseId = data.caseId;
    this.availableGoals = data.availableGoals;

    this.goalDrivenService = data.goalDrivenService;
  }

  public patientId: string;
  public caseId: string;
  public action: string;
  public noteForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  note: INote;
  accountError: Error = null;
  errors: any = {};
  country: string = 'US';
  processing: boolean = false;
  isGoalDrivenOrg: boolean = false;
  orgConfig: any;
  totalShifts: number = 3;
  availableGoals: any[];
  goalDrivenService: boolean;

  public ngOnInit() {
    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          this.orgConfig = orgConfig;
          if (this.orgConfig.goalDrivenCare === 'Yes') {
            this.isGoalDrivenOrg = true;
          }
          this.totalShifts = this.orgConfig.totalShiftsPerDay;
        });
      }
    });

    this.buildForm();

    if (this.action === 'EDIT') {
      this.noteForm.controls['id'].setValue(this.note.id);
      this.noteForm.controls['organizationId'].setValue(
        this.note.organizationId
      );
      this.noteForm.controls['patientId'].setValue(this.note.patientId);
      this.noteForm.controls['caseId'].setValue(this.note.caseId);
      this.noteForm.controls['text'].setValue(this.note.text);
      this.noteForm.controls['category'].setValue(this.note.category);
      this.noteForm.controls['priority'].setValue(this.note.priority);
      this.noteForm.controls['shift'].setValue(this.note.shift);
      this.noteForm.controls['goals'].setValue(this.note.goals);
      this.noteForm.controls['additionalInput'].setValue(
        this.note.additionalInput
      );
    } else {
      this.noteForm.controls['patientId'].setValue(this.patientId);
      this.noteForm.controls['caseId'].setValue(this.caseId);
    }
  }

  onNoteFormSubmit() {
    if (this.noteForm.invalid) {
      this.noteForm.markAllAsTouched();
      return;
    }

    this.note = Object.assign({}, this.note);
    this.note = Object.assign(this.note, this.noteForm.value);

    this.processing = true;

    if (this.action === 'EDIT') {
      this.noteApiService.updateNote(this.note.caseId, this.note).subscribe(
        () => {
          this.processing = false;
          this.dialogRef.close('success');
          this.toastMessageService.displaySuccessMessage(
            'Task has been edited.'
          );
        },
        () => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to update the task'
          );
        }
      );
    } else {
      this.note.patientId = this.patientId;
      this.noteApiService.addNote(this.note.caseId, this.note).subscribe(
        () => {
          this.processing = false;
          this.dialogRef.close('success');
          this.toastMessageService.displaySuccessMessage(
            'New task has been added.'
          );
        },
        () => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to add the task'
          );
        }
      );
    }
  }

  private buildForm() {
    this.noteForm = this.formBuilder.group({
      id: new FormControl(null),
      caseId: new FormControl(null),
      patientId: new FormControl(null),
      organizationId: new FormControl(null),
      text: new FormControl('', Validators.compose([Validators.required])),
      category: new FormControl('', Validators.required),
      priority: new FormControl('', Validators.required),
      shift: new FormControl('', Validators.required),
      additionalInput: new FormControl(''),
      goals: new FormControl(this.action === 'EDIT' ? this.note.goals : []),
    });
  }
}
