<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Autism Spectrum Screening Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="autismSpectrumScreeningForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >1. is old-fashioned or precocious</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q1">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >2. is regarded as an “eccentric professor” by the other
            children</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q2">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >3. lives somewhat in a world of his/her own with restricted
            idiosyncratic intellectual interests</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q3">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >4. accumulates facts on certain subjects (good rote memory) but
            does not really understand the meaning</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q4">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >5. has a literal understanding of ambiguous and metaphorical
            language</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q5">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >6. has a deviant style of communication with a formal, fussy,
            old-fashioned or “robot-like” language</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q6">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >7. invents idiosyncratic words and expressions</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q7">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >8. has a different voice or speech</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q8">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >9. expresses sounds involuntarily; clears throat, grunts, smacks,
            cries or screams</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q9">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >10. is surprisingly good at some things and surprisingly poor at
            others</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q10">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >11. uses language freely but fails to make adjustment to fit social
            contexts or the needs of different listeners</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q11">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after">12. lacks empathy</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q12">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >13. makes naive and embarrassing remarks</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q13">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >14. has a deviant style of gaze</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q14">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >15. wishes to be sociable but fails to make relationships with
            peers</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q15">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >16. can be with other children but only on his/her terms</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q16">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after">17. lacks best friend</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q17">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after">18. lacks common sense</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q18">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >19. is poor at games: no idea of cooperating in a team, scores “own
            goals”</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q19">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >20. has clumsy, ill coordinated, ungainly, awkward movements or
            gestures</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q20">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >21. has involuntary face or body movements</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q21">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >22. has difficulties in completing simple daily activities because
            of compulsory repetition of certain actions or thoughts</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q22">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >23. has special routines: insists on no change</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q23">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >24. shows idiosyncratic attachment to objects</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q24">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >25. is bullied by other children</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q25">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >26. has markedly unusual facial expression</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q26">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >27. has markedly unusual posture</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q27">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          autismSpectrumScreeningForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!autismSpectrumScreeningForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
