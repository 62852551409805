import { Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { PatientDashboardComponent } from './patient-dashboard.component';

export const PatientDetailRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: ':tabId',
        component: PatientDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ['patient'] },
      },
      {
        path: ':tabId/:secondaryId',
        component: PatientDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ['patient'] },
      }
    ],
  }
];
