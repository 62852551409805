import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function dateRangeValidator(
  startControlName: string,
  endControlName: string
): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const startControl = formGroup.get(startControlName);
    const endControl = formGroup.get(endControlName);

    if (!startControl || !endControl) {
      return null;
    }

    const startDate = new Date(startControl.value);
    const endDate = new Date(endControl.value);

    if (endDate < startDate) {
      return { dateRange: true };
    }

    return null;
  };
}
