import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MbscCalendarEvent } from '@mobiscroll/angular';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ShiftApiService {
  constructor(private httpClient: HttpClient) {}

  addShift(calEvent: MbscCalendarEvent): Observable<any> {
    let shift: any = {};
    shift.location = calEvent.location;
    shift.locationId = calEvent.locationId;
    shift.title = calEvent.title;
    shift.userId = calEvent.resource;
    shift.description = calEvent.notes;
    shift.confirmed = true;
    shift.start = calEvent.start;
    shift.end = calEvent.end;
    shift.completed = false;
    shift.category = calEvent.category;
    shift.slot = calEvent.slot;
    shift.recurring = calEvent.recurring;

    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<MbscCalendarEvent[]>(`${environment.apiUrl}/shift`, shift, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateShift(calEvent: MbscCalendarEvent): Observable<MbscCalendarEvent[]> {
    let shift: any = {};

    shift.id = calEvent.id;
    shift.location = calEvent.location;
    shift.title = calEvent.title;
    shift.userId = calEvent.resource;
    shift.description = calEvent.notes;
    shift.confirmed = true;
    shift.start = calEvent.start;
    shift.end = calEvent.end;
    shift.completed = false;
    shift.category = calEvent.category;
    shift.slot = calEvent.slot;
    shift.recurring = calEvent.recurring;

    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<MbscCalendarEvent[]>(`${environment.apiUrl}/shift`, shift, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  deleteShift(id: string | number): Observable<void> {
    return this.httpClient
      .delete<void>(`${environment.apiUrl}/shift/` + id)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
