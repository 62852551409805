import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserExperience } from '../../app/_models';
import { environment } from '../../environments/environment';
import { PermissionsSandbox } from '../shared/sandbox/permissions.sandbox';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  private currentExperienceSubject: BehaviorSubject<UserExperience>;
  public currentExperience: Observable<UserExperience>;

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  permissions: Observable<String[]>;

  constructor(
    protected readonly keycloak: KeycloakService,
    protected permissionsSandbox: PermissionsSandbox,
    private http: HttpClient
  ) {
    this.currentExperienceSubject = new BehaviorSubject<UserExperience>(
      JSON.parse(sessionStorage.getItem('userExperience'))
    );
    this.currentExperience = this.currentExperienceSubject.asObservable();

    this.permissions = this.permissionsSandbox.permissions$.pipe(
      map((response: String[]) => {
        return response;
      })
    );
  }

  startLoading() {
    this.loading$.next(true);
  }

  stopLoading() {
    this.loading$.next(false);
  }

  getOrganizationId() {
    let claims = this.keycloak.getKeycloakInstance();
    return claims.tokenParsed['organizationId'];
  }

  getSubId() {
    let claims = this.keycloak.getKeycloakInstance();
    return claims.tokenParsed['sub'];
  }

  isSSOUser() {
    let userType = this.keycloak.getKeycloakInstance().tokenParsed['user_type'];

    return userType === 'SSO user';
  }

  getPatientId() {
    return this.keycloak.getKeycloakInstance().tokenParsed['patientId'];
  }

  getUserDetails() {
    /**
     * email, family_name, given_name, name, phone, preferred_username
     */

    let claims = this.keycloak.getKeycloakInstance();
    return {
      email: claims.tokenParsed['email'],
      firstName: claims.tokenParsed['given_name'],
      lastName: claims.tokenParsed['family_name'],
      name: claims.tokenParsed['name'],
      username: claims.tokenParsed['preferred_username'],
      phone: claims.tokenParsed['phone'],
      eulaVersion: claims.tokenParsed['eula-version'],
      orgId: claims.tokenParsed['organizationId'],
    };
  }

  getPhone(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/users/phone-number`).pipe(
      map((d: any) => {
        return d.data.phoneNumber;
      })
    );
  }

  editPhone(newPhn: string) {
    return this.http
      .post(`${environment.apiUrl}/users/update-phone`, { phoneNumber: newPhn })
      .pipe(
        map((d: any) => {
          return d.result;
        })
      );
  }

  changePassword(passwords) {
    return this.http.post(
      `${environment.apiUrl}/users/change-password`,
      passwords
    );
  }

  setLanguage(value: string) {
    this.currentExperienceSubject.value.language = value;
  }

  getLanguage() {
    return this.currentExperienceSubject.value.language;
  }

  setFacilityId(value: string) {
    this.currentExperienceSubject.value.facilityId = value;
  }

  getFacilityId() {
    return this.currentExperienceSubject.value.facilityId;
  }

  getLoggedInCareProviderId() {
    let claims = this.keycloak.getKeycloakInstance();

    return claims.tokenParsed['careProviderId'];
  }

  isPhysician() {
    return this.keycloak.isUserInRole('physician');
  }

  isCareProvider() {
    return this.keycloak.isUserInRole('careprovider');
  }

  isSuperOrgAdmin() {
    return this.keycloak.isUserInRole('superorgadmin');
  }

  isOrgAdmin() {
    return this.keycloak.isUserInRole('orgadmin');
  }

  isFacilityDevice() {
    return this.keycloak.isUserInRole('inhospitaldevice');
  }

  isPatient() {
    return this.keycloak.isUserInRole('patient');
  }
}
