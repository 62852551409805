import {
  NgxGpAutocompleteDirective,
  NgxGpAutocompleteOptions,
} from '@angular-magic/ngx-gp-autocomplete';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Patient } from 'src/app/_models';
import {
  CoreService,
  PatientService,
  ToastMessageService,
} from 'src/app/_services';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { EmploymentStatus } from 'src/app/shared/utilities/billing/employmentStatus';
import { Ethnicity } from 'src/app/shared/utilities/billing/ethnicity';
import { Gender } from 'src/app/shared/utilities/billing/gender';
import { MarriedStatus } from 'src/app/shared/utilities/billing/maritalStatus';
import { PreferredLanguages } from 'src/app/shared/utilities/billing/preferredLanguages';
import { Race } from 'src/app/shared/utilities/billing/race';
import { USStates } from 'src/app/shared/utilities/billing/states';
import { TimeZones } from 'src/app/shared/utilities/billing/timeZones';
import { formatDate, mmddyyyyToDate } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-member-profile-information',
  templateUrl: './member-profile-information.component.html',
  styleUrls: ['./member-profile-information.component.css'],
})
export class MemberProfileInformationComponent implements OnInit, OnChanges {
  @ViewChild('ngxPlaces') placesRef: NgxGpAutocompleteDirective;

  @Input() patient: Patient;
  @Input() hasMemberEditAccess: boolean;
  @Input() showTitle: boolean;
  @Output() output = new EventEmitter<any>();

  maxDate: Date;

  patientForm: FormGroup;
  usStates = USStates;
  processing: boolean = false;
  isIndianOrg: boolean = false;
  origPatientStatus: string = '';

  genderList = Gender;
  raceList = Race;
  ethnicityList = Ethnicity;
  languageList = PreferredLanguages;
  employmentList = EmploymentStatus;
  marriedStatusList = MarriedStatus;
  timeZoneList = TimeZones;

  options: NgxGpAutocompleteOptions = {
    componentRestrictions: { country: ['US'] },
    types: ['address'],
  };
  showSSN: boolean = false;

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private coreService: CoreService,
    private toastMessageService: ToastMessageService,
    public patientApiService: PatientService
  ) {}

  ngOnInit(): void {
    this.maxDate = new Date();
    this.buildForm();
    this.origPatientStatus = this.patient.status;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.patient) {
      this.buildForm();
      this.origPatientStatus = this.patient.status;
    }
  }

  private buildForm() {
    this.patientForm = this.formBuilder.group({
      id: new FormControl(this.patient.id),
      organizationId: new FormControl(
        this.patient.organizationId,
        Validators.required
      ),
      facilityId: new FormControl(this.patient.facilityId),
      firstName: new FormControl(
        { value: this.patient.firstName, disabled: !this.hasMemberEditAccess },
        Validators.required
      ),
      middleName: new FormControl({
        value: this.patient.middleName,
        disabled: !this.hasMemberEditAccess,
      }),
      lastName: new FormControl(
        { value: this.patient.lastName, disabled: !this.hasMemberEditAccess },
        Validators.required
      ),
      email: new FormControl(
        { value: this.patient.email, disabled: !this.hasMemberEditAccess },
        Validators.email
      ),
      dateOfBirth: new FormControl(
        {
          value: mmddyyyyToDate(this.patient.dateOfBirth),
          disabled: !this.hasMemberEditAccess,
        },
        Validators.required
      ),
      gender: new FormControl(
        { value: this.patient.gender, disabled: !this.hasMemberEditAccess },
        Validators.required
      ),

      ssn: new FormControl(
        { value: this.patient.ssn, disabled: !this.hasMemberEditAccess },
        Validators.compose([RxwebValidators.mask({ mask: '999-99-9999' })])
      ),

      genderAtBirth: new FormControl({
        value: this.patient.genderAtBirth,
        disabled: !this.hasMemberEditAccess,
      }),

      addressLine1: new FormControl(
        {
          value: this.patient.addressLine1,
          disabled: !this.hasMemberEditAccess,
        },
        Validators.required
      ),
      addressLine2: new FormControl({
        value: this.patient.addressLine2,
        disabled: !this.hasMemberEditAccess,
      }),
      city: new FormControl(
        { value: this.patient.city, disabled: !this.hasMemberEditAccess },
        Validators.required
      ),
      state: new FormControl(
        { value: this.patient.state, disabled: !this.hasMemberEditAccess },
        Validators.required
      ),
      zip: new FormControl(
        { value: this.patient.zip, disabled: !this.hasMemberEditAccess },
        Validators.compose([
          Validators.required,
          RxwebValidators.mask({ mask: '99999' }),
        ])
      ),
      phonePreference: new FormControl(
        {
          value: this.patient.phonePreference,
          disabled: !this.hasMemberEditAccess,
        },
        Validators.compose([Validators.required])
      ),
      phoneNumber: new FormControl(
        {
          value: this.patient.phoneNumber,
          disabled: !this.hasMemberEditAccess,
        },
        Validators.compose([RxwebValidators.mask({ mask: '(999) 999-9999' })])
      ),

      homePhoneNumber: new FormControl(
        {
          value: this.patient.homePhoneNumber,
          disabled: !this.hasMemberEditAccess,
        },
        Validators.compose([RxwebValidators.mask({ mask: '(999) 999-9999' })])
      ),
      workPhoneNumber: new FormControl(
        {
          value: this.patient.workPhoneNumber,
          disabled: !this.hasMemberEditAccess,
        },
        Validators.compose([RxwebValidators.mask({ mask: '(999) 999-9999' })])
      ),

      memberNumber: new FormControl({
        value: this.patient.memberNumber,
        disabled: !this.hasMemberEditAccess,
      }),
      mco: new FormControl({
        value: this.patient.mco,
        disabled: !this.hasMemberEditAccess,
      }),
      aadhaarNo: new FormControl({
        value: this.patient.aadhaarNo,
        disabled: !this.hasMemberEditAccess,
      }),
      panNo: new FormControl({
        value: this.patient.panNo,
        disabled: !this.hasMemberEditAccess,
      }),
      status: new FormControl({
        value: this.patient.status,
        disabled: !this.hasMemberEditAccess,
      }),

      deceasedDate: new FormControl({
        value: this.patient.deceasedDate,
        disabled: !this.hasMemberEditAccess,
      }),
      dischargedDate: new FormControl({
        value: this.patient.dischargedDate,
        disabled: !this.hasMemberEditAccess,
      }),
      preferredLanguage: new FormControl({
        value: this.patient.preferredLanguage,
        disabled: !this.hasMemberEditAccess,
      }),

      race: new FormControl({
        value: this.patient.race,
        disabled: !this.hasMemberEditAccess,
      }),
      ethnicity: new FormControl({
        value: this.patient.ethnicity,
        disabled: !this.hasMemberEditAccess,
      }),
      maritalStatus: new FormControl({
        value: this.patient.maritalStatus,
        disabled: !this.hasMemberEditAccess,
      }),
      employmentStatus: new FormControl({
        value: this.patient.employmentStatus,
        disabled: !this.hasMemberEditAccess,
      }),
      timeZone: new FormControl({
        value: this.patient.timeZone,
        disabled: !this.hasMemberEditAccess,
      }),
      pronouns: new FormControl({
        value: this.patient.pronouns,
        disabled: !this.hasMemberEditAccess,
      }),
      admittanceDate: new FormControl({
        value: mmddyyyyToDate(
          this.patient.admittanceDate ? this.patient.admittanceDate : ''
        ),
        disabled: !this.hasMemberEditAccess,
      }),

      height: new FormControl({
        value: this.patient.height ? this.patient.height : '',
        disabled: !this.hasMemberEditAccess,
      }),
      weight: new FormControl({
        value: this.patient.weight ? this.patient.weight : '',
        disabled: !this.hasMemberEditAccess,
      }),
    });
  }

  confirmPatientStatusChange(event: MatSelectChange) {
    let selectedValue = event.source.triggerValue;
    if (this.origPatientStatus === 'Active' && selectedValue !== 'Active') {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Please Confirm',
          msg:
            'Switching status to "' +
            selectedValue +
            '" will close all the Active Cases.',
          yesButtonTitle: 'Yes',
          noButtonTitle: 'No',
        },
        disableClose: true,
        autoFocus: false,
        minWidth: '20vw',
      });

      dialogRef.afterClosed().subscribe((confirm) => {
        if (!confirm) {
          this.patientForm.controls['status'].setValue('Active');
        }
      });
    }
  }

  handleAddressChange(address: google.maps.places.PlaceResult) {
    let addressField: string = '';

    for (const component of address.address_components as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number': {
          addressField = `${component.long_name} ${addressField}`;
          break;
        }

        case 'route': {
          addressField += component.long_name;
          break;
        }

        case 'locality':
          //city
          this.patientForm.controls['city'].setValue(component.long_name);
          break;

        case 'administrative_area_level_1': {
          //state
          this.patientForm.controls['state'].setValue(component.short_name);
          break;
        }

        case 'postal_code': {
          this.patientForm.controls['zip'].setValue(component.long_name);
          break;
        }
      }
    }
    if (addressField.trim() == '')
      addressField = address.formatted_address.split(',')[0];
    this.patientForm.controls['addressLine1'].setValue(addressField);
  }

  onPatientFormSubmit() {
    if (this.patientForm.invalid) {
      this.patientForm.markAllAsTouched();
      return;
    }

    this.patient = Object.assign({}, this.patient);
    this.patient = Object.assign(this.patient, this.patientForm.value);

    if (this.patient.dateOfBirth && this.patient.dateOfBirth !== '') {
      this.patient.dateOfBirth = formatDate(new Date(this.patient.dateOfBirth));
    }

    if (this.patient.admittanceDate && this.patient.admittanceDate !== '') {
      this.patient.admittanceDate = formatDate(
        new Date(this.patient.admittanceDate)
      );
    }

    if (this.patient.deceasedDate && this.patient.deceasedDate !== '') {
      this.patient.deceasedDate = formatDate(
        new Date(this.patient.deceasedDate)
      );
    }

    if (!this.patient.dischargedDate && this.patient.status === 'Discharged') {
      this.patient.dischargedDate = new Date();
    }

    if (!this.patient.deceasedDate && this.patient.status === 'Deceased') {
      this.patient.deceasedDate = formatDate(new Date());
    }

    this.processing = true;

    // Regular update
    this.patientApiService.updatePatient(this.patient).subscribe(
      (response) => {
        this.processing = false;
        this.toastMessageService.displaySuccessMessage(
          'Successfully updated patient record'
        );
        this.output.emit({ eventType: 'RELOAD_PATIENT_PROFILE' });
      },
      (error) => {
        this.processing = false;
        if (
          error.text ===
          'Another Member with the same Member Number already exists in the database'
        ) {
          this.toastMessageService.displayErrorMessage(
            'Error: Member with same Member Number already exists. Please update the member number to a new one.'
          );
          this.patientForm.controls['memberNumber'].setErrors({
            duplicate: true,
          });
          (<any>this.patientForm.get('memberNumber')).nativeElement.focus();
        } else {
          this.toastMessageService.displayErrorMessage(
            'Error: Something went wrong. Please try again.'
          );
        }
      }
    );
  }

  phonePreferenceChanged(event) {
    if (event.value === 'Home Phone') {
      this.patientForm
        .get('homePhoneNumber')
        .addValidators(Validators.required);
      this.patientForm.get('homePhoneNumber').updateValueAndValidity();

      this.patientForm.get('workPhoneNumber').clearValidators();
      this.patientForm.get('workPhoneNumber').updateValueAndValidity();
    } else if (event.value === 'Work Phone') {
      this.patientForm
        .get('workPhoneNumber')
        .addValidators(Validators.required);
      this.patientForm.get('workPhoneNumber').updateValueAndValidity();

      this.patientForm.get('homePhoneNumber').clearValidators();
      this.patientForm.get('homePhoneNumber').updateValueAndValidity();
    } else {
      this.patientForm.get('workPhoneNumber').clearValidators();
      this.patientForm.get('workPhoneNumber').updateValueAndValidity();

      this.patientForm.get('homePhoneNumber').clearValidators();
      this.patientForm.get('homePhoneNumber').updateValueAndValidity();
    }
  }

  genderChanged(event) {
    if (event.value === 'O') {
      this.patientForm.get('genderAtBirth').addValidators(Validators.required);
      this.patientForm.get('genderAtBirth').updateValueAndValidity();
    } else {
      this.patientForm.get('genderAtBirth').clearValidators();
      this.patientForm.get('genderAtBirth').updateValueAndValidity();
    }
  }
}
