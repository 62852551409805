<div class="row">
  <div class="col-md-12">
    <p style="font-size: 16px; font-weight: 450">
      <a routerLink="/" routerLinkActive="active">
        <em
          class="material-icons text_align-center"
          style="font-size: 18px; margin-top: -3px"
          >home</em
        >
      </a>
      &nbsp; / &nbsp;
      <span style="color: dimgray">My Time Off & Holidays</span>
    </p>
  </div>
</div>

<div class="row">
  <div class="col-md-12" style="text-align: right" *ngIf="canEditTimeOff">
    <button mat-raised-button type="button" color="warn" (click)="addTimeOff()">
      + Add Time Off
    </button>
  </div>

  <div class="col-12" style="margin-top: 10px">
    <div *ngIf="data.total === 0">
      <mat-error> No time off record found </mat-error>
    </div>

    <mat-progress-bar
      mode="indeterminate"
      *ngIf="processing"
    ></mat-progress-bar>

    <div class="mat-elevation-z8 table-responsive" *ngIf="data.total > 0">
      <table mat-table [dataSource]="data.items" matSort matSortDisableClear>
        <ng-container matColumnDef="startDay">
          <th mat-header-cell *matHeaderCellDef>Start Date</th>
          <td mat-cell *matCellDef="let row">
            <b>{{ row.startDay | date : 'M/d/y' : currentTimezone }}</b>
            <br />
            {{ row.startDay | date : 'h:mm a' : currentTimezone }}
            {{ currentTimezone }}
          </td>
        </ng-container>
        <ng-container matColumnDef="endDay">
          <th mat-header-cell *matHeaderCellDef>End Date</th>
          <td mat-cell *matCellDef="let row">
            <b>{{ row.endDay | date : 'M/d/y' : currentTimezone }}</b>
            <br />
            {{ row.endDay | date : 'h:mm a' : currentTimezone }}
            {{ currentTimezone }}
          </td>
        </ng-container>
        <ng-container matColumnDef="reason">
          <th mat-header-cell *matHeaderCellDef>Reason</th>
          <td mat-cell *matCellDef="let row">{{ row.reason }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef style="text-align: right">
            Actions
          </th>
          <td
            mat-cell
            *matCellDef="let row; let i = index"
            style="text-align: right"
          >
            <button
              mat-mini-fab
              [matMenuTriggerFor]="menu"
              style="background: transparent"
              type="button"
            >
              <mat-icon style="color: #00000099">more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
              <button mat-menu-item type="button" (click)="editTimeOff(row)">
                <mat-icon style="color: #f39121"> edit </mat-icon>
                <span>Edit</span>
              </button>

              <button mat-menu-item type="button" (click)="deleteTimeOff(row)">
                <mat-icon style="color: red"> delete_forever </mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
      </table>
      <mat-paginator
        #paginator
        [showFirstLastButtons]="true"
        [length]="data.total"
        [pageSize]="data.per_page"
        (page)="getNext($event)"
        *ngIf="data.total > data.per_page"
        style="width: 100%; background-color: transparent; margin-right: 20px"
      >
      </mat-paginator>
    </div>
  </div>
</div>
