<mat-card-title>Member Assessments</mat-card-title>
<mat-divider></mat-divider>

<div class="row" style="padding: 10px">
  <div class="row" style="margin-bottom: 20px; row-gap: 15px">
    <div class="col-md-7">
      <mat-chip-listbox
        [(ngModel)]="selectedOption"
        aria-label="Status"
        style="float: left"
      >
        <mat-chip-option
          color="primary"
          #event
          *ngFor="let option of options"
          [value]="option.id"
          (click)="toggleSelection(option.id, event)"
        >
          {{ option.description }}
        </mat-chip-option>
      </mat-chip-listbox>
    </div>

    <div
      class="col-md-5"
      *ngIf="patient.status === 'Active' && hasMemberAssessmentAddAccess"
      style="
        display: flex;
        justify-content: end;
        column-gap: 10px;
        padding: 0;
        flex-wrap: wrap;
        row-gap: 15px;
      "
    >
      <div>
        <button
          mat-raised-button
          color="warn"
          (click)="requestReoccurringAssessment()"
          type="button"
          style="font-size: 12px"
        >
          + Recurring Assessment
        </button>
      </div>

      <div>
        <button
          mat-raised-button
          color="warn"
          (click)="requestNewAssessment()"
          type="button"
          style="font-size: 12px"
        >
          + New Assessment
        </button>
      </div>
    </div>
  </div>

  <mat-progress-bar
    mode="indeterminate"
    *ngIf="pendingAssessmentProcessing"
  ></mat-progress-bar>

  <div class="col-md-12" *ngIf="selectedOption === 'Pending'">
    <div
      *ngIf="data.total === 0 && !pendingAssessmentProcessing"
      style="padding-left: 15px"
    >
      <mat-error> No record found </mat-error>
    </div>

    <mat-card
      appearance="raised"
      class="table-responsive mat-elevation-z8"
      *ngIf="data.total > 0"
    >
      <table mat-table [dataSource]="data.items" matSort matSortDisableClear>
        <ng-container matColumnDef="createdDate">
          <th mat-header-cell *matHeaderCellDef style="font-weight: 900">
            Date
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.createdDate | date : 'MM/dd/yyyy' }}
            <br />
            {{ row.createdDate | date : 'shortTime' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="sessionId">
          <th
            mat-header-cell
            *matHeaderCellDef
            style="font-weight: 900; width: 200px !important"
          >
            Note Id
          </th>
          <td mat-cell *matCellDef="let row">
            <span style="cursor: pointer; color: #2196f3; font-weight: bold">
              {{ row?.sessionCode }}
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="assessmentType">
          <th mat-header-cell *matHeaderCellDef style="font-weight: 900">
            Assessment Type
          </th>
          <td mat-cell *matCellDef="let row">{{ row.assessmentType }}</td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th
            mat-header-cell
            *matHeaderCellDef
            style="font-weight: 900; text-align: right"
          >
            Actions
          </th>
          <td mat-cell *matCellDef="let row" style="text-align: right">
            <button
              mat-raised-button
              [matMenuTriggerFor]="menu"
              class="btn btn-just-icon btn-white btn-fab btn-round"
            >
              <i class="material-icons text_align-center">more_vert</i>
            </button>
            <mat-menu #menu="matMenu">
              <button
                *ngIf="hasAssessmentTakeAddAccess"
                mat-menu-item
                type="button"
                (click)="takeAssessment(row)"
              >
                <mat-icon style="color: #2196f3; opacity: 0.5">
                  edit_note
                </mat-icon>
                <span>Take Assessment</span>
              </button>

              <button
                mat-menu-item
                type="button"
                (click)="sendEmailLink(row)"
                [disabled]="otpLoader"
                *ngIf="
                  patient?.email && patient?.phoneNumber && !row?.inSessionOnly
                "
              >
                <mat-icon style="color: #78c000; opacity: 0.5"> send </mat-icon>
                <span>Send via email</span>
              </button>

              <button
                *ngIf="hasHospitalDeviceFeature && !row?.inSessionOnly"
                type="button"
                mat-menu-item
                color="warn"
                (click)="generateAcessCode(row)"
                [disabled]="otpLoader"
              >
                <mat-icon color="warn">pin</mat-icon>
                <span>Request OTP</span>
              </button>

              <button
                *ngIf="hasMemberAssessmentDeleteAccess"
                mat-menu-item
                type="button"
                (click)="openDeleteConfirm(row)"
              >
                <mat-icon
                  id="deleteButton"
                  style="color: #d92550; opacity: 0.5"
                >
                  delete_forever
                </mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedPendingAssessmentColumns"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedPendingAssessmentColumns"
        ></tr>
      </table>
      <mat-paginator
        [length]="data.total"
        [pageSize]="data.per_page"
        *ngIf="data.total > data.per_page"
        (page)="getNext($event)"
        [showFirstLastButtons]="true"
      ></mat-paginator>
    </mat-card>
  </div>

  <app-assessment-history
    *ngIf="selectedOption === 'In Review' || selectedOption === 'Completed'"
    [patientId]="patient?.id"
    [showHeader]="true"
    [showSearchMember]="false"
    [hideMemberName]="true"
    [status]="selectedOption"
    [hideCompletedBy]="false"
    [hideCreatedDate]="false"
    [hasMemberAssessmentAddAccess]="hasMemberAssessmentAddAccess"
    [hasMemberAssessmentEditAccess]="hasMemberAssessmentEditAccess"
    [hasMemberAssessmentDeleteAccess]="hasMemberAssessmentDeleteAccess"
    [hasAssessmentChartsViewAccess]="hasAssessmentChartsViewAccess"
    [hasAssessmentGuidanceViewAccess]="hasAssessmentGuidanceViewAccess"
    [hasAssessmentGuidanceEditAccess]="hasAssessmentGuidanceEditAccess"
    [hasAssessmentSummaryViewAccess]="hasAssessmentSummaryViewAccess"
    [hasAssessmentSummaryEditAccess]="hasAssessmentSummaryEditAccess"
    [hasAssessmentTakeAddAccess]="hasAssessmentTakeAddAccess"
  >
  </app-assessment-history>
</div>
