import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { ICrossCuttingLevelOneAdult } from 'src/app/_models/assessments/general/crosscuttingleveloneadult.model';
import { ToastMessageService } from 'src/app/_services/';
import { CrossCuttingLevelOneAdultApiService } from 'src/app/_services/assessments/general/crosscuttingleveloneadult.service';
@Component({
  selector: 'app-cross-cutting-level-one-adult',
  templateUrl: './cross-cutting-level-one-adult.component.html',
  styleUrls: ['./cross-cutting-level-one-adult.component.css'],
})
export class CrossCuttingLevelOneAdultComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public crosscuttingleveloneadultApiService: CrossCuttingLevelOneAdultApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) {}
  public riskScore: number;
  public crosscuttingleveloneadultForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  crosscuttingleveloneadult: ICrossCuttingLevelOneAdult;
  crosscuttingleveloneadultError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public somatic: boolean;
  public sleep: boolean;
  public depression: boolean;
  public anger: boolean;
  public mania: boolean;
  public anxiety: boolean;
  public psychosis: boolean;
  public repetetiveThoughtsAndBehaviors: boolean;
  public substanceUse: boolean;
  public suicidal: boolean;
  public memory: boolean;
  public dissociation: boolean;
  public personality: boolean;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(
      this.crosscuttingleveloneadultForm,
      this.errors
    );

    this.loadCrossCuttingLevelOneAdult();
  }

  loadCrossCuttingLevelOneAdult() {
    this.crosscuttingleveloneadultForm.controls['patientId'].setValue(
      this.patientId
    );

    if (this.assessmentId) {
      this.crosscuttingleveloneadultApiService
        .getCrossCuttingLevelOneAdultDetails(this.assessmentId)
        .subscribe((crosscuttingleveloneadult) => {
          if (crosscuttingleveloneadult.data) {
            this.crosscuttingleveloneadultForm.controls[
              'littleInterest'
            ].setValue(crosscuttingleveloneadult.data.littleInterest.answer);
            this.crosscuttingleveloneadultForm.controls[
              'downDepressedHopeless'
            ].setValue(
              crosscuttingleveloneadult.data.downDepressedHopeless.answer
            );
            this.crosscuttingleveloneadultForm.controls[
              'irritatedGrouchyAngry'
            ].setValue(
              crosscuttingleveloneadult.data.irritatedGrouchyAngry.answer
            );
            this.crosscuttingleveloneadultForm.controls[
              'sleepingLess'
            ].setValue(crosscuttingleveloneadult.data.sleepingLess.answer);
            this.crosscuttingleveloneadultForm.controls[
              'moreProjects'
            ].setValue(crosscuttingleveloneadult.data.moreProjects.answer);
            this.crosscuttingleveloneadultForm.controls[
              'nervousAnxious'
            ].setValue(crosscuttingleveloneadult.data.nervousAnxious.answer);
            this.crosscuttingleveloneadultForm.controls[
              'panicFrightened'
            ].setValue(crosscuttingleveloneadult.data.panicFrightened.answer);
            this.crosscuttingleveloneadultForm.controls[
              'avoidingSituations'
            ].setValue(
              crosscuttingleveloneadult.data.avoidingSituations.answer
            );
            this.crosscuttingleveloneadultForm.controls['achesPains'].setValue(
              crosscuttingleveloneadult.data.achesPains.answer
            );
            this.crosscuttingleveloneadultForm.controls[
              'illnessIgnored'
            ].setValue(crosscuttingleveloneadult.data.illnessIgnored.answer);
            this.crosscuttingleveloneadultForm.controls['hurtingSelf'].setValue(
              crosscuttingleveloneadult.data.hurtingSelf.answer
            );
            this.crosscuttingleveloneadultForm.controls[
              'hearingVoices'
            ].setValue(crosscuttingleveloneadult.data.hearingVoices.answer);
            this.crosscuttingleveloneadultForm.controls['imagining'].setValue(
              crosscuttingleveloneadult.data.imagining.answer
            );
            this.crosscuttingleveloneadultForm.controls[
              'sleepProblems'
            ].setValue(crosscuttingleveloneadult.data.sleepProblems.answer);
            this.crosscuttingleveloneadultForm.controls[
              'memoryProblems'
            ].setValue(crosscuttingleveloneadult.data.memoryProblems.answer);
            this.crosscuttingleveloneadultForm.controls[
              'unpleasantThoughts'
            ].setValue(
              crosscuttingleveloneadult.data.unpleasantThoughts.answer
            );
            this.crosscuttingleveloneadultForm.controls[
              'performOverAndOver'
            ].setValue(
              crosscuttingleveloneadult.data.performOverAndOver.answer
            );
            this.crosscuttingleveloneadultForm.controls[
              'feelingDetached'
            ].setValue(crosscuttingleveloneadult.data.feelingDetached.answer);
            this.crosscuttingleveloneadultForm.controls[
              'dontKnowSelf'
            ].setValue(crosscuttingleveloneadult.data.dontKnowSelf.answer);
            this.crosscuttingleveloneadultForm.controls[
              'notCloseToOthers'
            ].setValue(crosscuttingleveloneadult.data.notCloseToOthers.answer);
            this.crosscuttingleveloneadultForm.controls['drinking'].setValue(
              crosscuttingleveloneadult.data.drinking.answer
            );
            this.crosscuttingleveloneadultForm.controls['cigarettes'].setValue(
              crosscuttingleveloneadult.data.cigarettes.answer
            );
            this.crosscuttingleveloneadultForm.controls['drugs'].setValue(
              crosscuttingleveloneadult.data.drugs.answer
            );
            this.riskScore = crosscuttingleveloneadult.data.riskScore;

            this.somatic = crosscuttingleveloneadult.data.props['somatic'] >= 2;
            this.sleep = crosscuttingleveloneadult.data.props['sleep'] >= 2;
            this.depression =
              crosscuttingleveloneadult.data.props['depression'] >= 2;
            this.anger = crosscuttingleveloneadult.data.props['anger'] >= 2;
            this.mania = crosscuttingleveloneadult.data.props['mania'] >= 2;
            this.anxiety = crosscuttingleveloneadult.data.props['anxiety'] >= 2;
            this.psychosis =
              crosscuttingleveloneadult.data.props['psychosis'] >= 1;
            this.repetetiveThoughtsAndBehaviors =
              crosscuttingleveloneadult.data.props[
                'repetetiveThoughtsAndBehaviors'
              ] >= 2;
            this.substanceUse =
              crosscuttingleveloneadult.data.props['substanceUse'] >= 2;
            this.suicidal =
              crosscuttingleveloneadult.data.props['suicidal'] >= 1;
            this.memory = crosscuttingleveloneadult.data.props['memory'] >= 2;
            this.dissociation =
              crosscuttingleveloneadult.data.props['dissociation'] >= 2;
            this.personality =
              crosscuttingleveloneadult.data.props['personality'] >= 2;
          }
        });
    }
  }

  onCrossCuttingLevelOneAdultFormSubmit() {
    if (this.crosscuttingleveloneadultForm.invalid) {
      this.crosscuttingleveloneadultForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.crosscuttingleveloneadult = Object.assign(
      {},
      this.crosscuttingleveloneadult
    );
    this.crosscuttingleveloneadult.patientId =
      this.crosscuttingleveloneadultForm.controls['patientId'].value;
    this.crosscuttingleveloneadult.sessionId =
      this.crosscuttingleveloneadultForm.controls['sessionId'].value;
    this.crosscuttingleveloneadult.assessmentRequestId =
      this.crosscuttingleveloneadultForm.controls['assessmentRequestId'].value;

    this.crosscuttingleveloneadult.littleInterest = {
      answer:
        this.crosscuttingleveloneadultForm.controls['littleInterest'].value,
    };
    this.crosscuttingleveloneadult.downDepressedHopeless = {
      answer:
        this.crosscuttingleveloneadultForm.controls['downDepressedHopeless']
          .value,
    };
    this.crosscuttingleveloneadult.irritatedGrouchyAngry = {
      answer:
        this.crosscuttingleveloneadultForm.controls['irritatedGrouchyAngry']
          .value,
    };
    this.crosscuttingleveloneadult.sleepingLess = {
      answer: this.crosscuttingleveloneadultForm.controls['sleepingLess'].value,
    };
    this.crosscuttingleveloneadult.moreProjects = {
      answer: this.crosscuttingleveloneadultForm.controls['moreProjects'].value,
    };
    this.crosscuttingleveloneadult.nervousAnxious = {
      answer:
        this.crosscuttingleveloneadultForm.controls['nervousAnxious'].value,
    };
    this.crosscuttingleveloneadult.panicFrightened = {
      answer:
        this.crosscuttingleveloneadultForm.controls['panicFrightened'].value,
    };
    this.crosscuttingleveloneadult.avoidingSituations = {
      answer:
        this.crosscuttingleveloneadultForm.controls['avoidingSituations'].value,
    };
    this.crosscuttingleveloneadult.achesPains = {
      answer: this.crosscuttingleveloneadultForm.controls['achesPains'].value,
    };
    this.crosscuttingleveloneadult.illnessIgnored = {
      answer:
        this.crosscuttingleveloneadultForm.controls['illnessIgnored'].value,
    };

    this.crosscuttingleveloneadult.hurtingSelf = {
      answer: this.crosscuttingleveloneadultForm.controls['hurtingSelf'].value,
    };
    this.crosscuttingleveloneadult.hearingVoices = {
      answer:
        this.crosscuttingleveloneadultForm.controls['hearingVoices'].value,
    };
    this.crosscuttingleveloneadult.imagining = {
      answer: this.crosscuttingleveloneadultForm.controls['imagining'].value,
    };
    this.crosscuttingleveloneadult.sleepProblems = {
      answer:
        this.crosscuttingleveloneadultForm.controls['sleepProblems'].value,
    };
    this.crosscuttingleveloneadult.memoryProblems = {
      answer:
        this.crosscuttingleveloneadultForm.controls['memoryProblems'].value,
    };
    this.crosscuttingleveloneadult.unpleasantThoughts = {
      answer:
        this.crosscuttingleveloneadultForm.controls['unpleasantThoughts'].value,
    };
    this.crosscuttingleveloneadult.performOverAndOver = {
      answer:
        this.crosscuttingleveloneadultForm.controls['performOverAndOver'].value,
    };
    this.crosscuttingleveloneadult.feelingDetached = {
      answer:
        this.crosscuttingleveloneadultForm.controls['feelingDetached'].value,
    };
    this.crosscuttingleveloneadult.dontKnowSelf = {
      answer: this.crosscuttingleveloneadultForm.controls['dontKnowSelf'].value,
    };
    this.crosscuttingleveloneadult.notCloseToOthers = {
      answer:
        this.crosscuttingleveloneadultForm.controls['notCloseToOthers'].value,
    };
    this.crosscuttingleveloneadult.drinking = {
      answer: this.crosscuttingleveloneadultForm.controls['drinking'].value,
    };
    this.crosscuttingleveloneadult.drugs = {
      answer: this.crosscuttingleveloneadultForm.controls['drugs'].value,
    };
    this.crosscuttingleveloneadult.cigarettes = {
      answer: this.crosscuttingleveloneadultForm.controls['cigarettes'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.crosscuttingleveloneadult.accessCode = this.accessCode;

      this.crosscuttingleveloneadultApiService
        .addGuestCrossCuttingLevelOneAdult(this.crosscuttingleveloneadult)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted DSM 5 Level 1 Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting DSM 5 Level 1 Assessment'
            );
          }
        );
    } else {
      this.crosscuttingleveloneadultApiService
        .addCrossCuttingLevelOneAdult(this.crosscuttingleveloneadult)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted DSM 5 Level 1 Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting DSM 5 Level 1 Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    littleInterest: [
      {
        type: 'required',
        message: '1. Little interest or pleasure in doing things?  is required',
      },
    ],
    downDepressedHopeless: [
      {
        type: 'required',
        message: '2. Feeling down, depressed, or hopeless?  is required',
      },
    ],
    irritatedGrouchyAngry: [
      {
        type: 'required',
        message:
          '3. Feeling more irritated, grouchy, or angry than usual?  is required',
      },
    ],
    sleepingLess: [
      {
        type: 'required',
        message:
          '4. Sleeping less than usual, but still have a lot of energy? is required',
      },
    ],
    moreProjects: [
      {
        type: 'required',
        message:
          '5. Starting lots more projects than usual or doing more risky things than usual?  is required',
      },
    ],
    nervousAnxious: [
      {
        type: 'required',
        message:
          '6. Feeling nervous, anxious, frightened, worried, or on edge? is required',
      },
    ],
    panicFrightened: [
      {
        type: 'required',
        message: '7. Feeling panic or being frightened? is required',
      },
    ],
    avoidingSituations: [
      {
        type: 'required',
        message: '8. Avoiding situations that make you anxious? is required',
      },
    ],
    achesPains: [
      {
        type: 'required',
        message:
          '9. Unexplained aches and pains (e.g., head, back, joints, abdomen, legs)? is required',
      },
    ],
    illnessIgnored: [
      {
        type: 'required',
        message:
          '10. Feeling that your illnesses are not being taken seriously enough? is required',
      },
    ],
    hurtingSelf: [
      {
        type: 'required',
        message: '11. Thoughts of actually hurting yourself?  is required',
      },
    ],
    hearingVoices: [
      {
        type: 'required',
        message:
          "12. Hearing things other people couldn't hear, such as voices even when no one was around? is required",
      },
    ],
    imagining: [
      {
        type: 'required',
        message:
          '13. Feeling that someone could hear your thoughts, or that you could hear what another person was thinking? is required',
      },
    ],
    sleepProblems: [
      {
        type: 'required',
        message:
          '14.Problems with sleep that affected your sleep quality over all? is required',
      },
    ],
    memoryProblems: [
      {
        type: 'required',
        message:
          '15. Problems with memory (e.g., learning new information) or with location (e.g., finding your way home)?  is required',
      },
    ],
    unpleasantThoughts: [
      {
        type: 'required',
        message:
          '16. Unpleasant thoughts, urges, or images that repeatedly enter your mind?  is required',
      },
    ],
    performOverAndOver: [
      {
        type: 'required',
        message:
          '17. Feeling driven to perform certain behaviors or mental acts over and over again? is required',
      },
    ],
    feelingDetached: [
      {
        type: 'required',
        message:
          '18. Feeling detached or distant from yourself, your body, your physical surroundings, or your memories? is required',
      },
    ],
    dontKnowSelf: [
      {
        type: 'required',
        message:
          '19. Not knowing who you really are or what you want out of life? is required',
      },
    ],
    notCloseToOthers: [
      {
        type: 'required',
        message:
          '20. Not feeling close to other people or enjoying your relationships with them? is required',
      },
    ],
    drinking: [
      {
        type: 'required',
        message:
          '21. Drinking at least 4 drinks of any kind of alcohol in a single day? is required',
      },
    ],
    cigarettes: [
      {
        type: 'required',
        message:
          '22. Smoking any cigarettes, a cigar, or pipe, or using snuff or chewing tobacco? is required',
      },
    ],
    drugs: [
      {
        type: 'required',
        message:
          "23. Using any of the following medicines ON YOUR OWN, that is, without a doctor's prescription, in greater amounts or longer than prescribed [e.g., painkillers (like Vicodin), stimulants (like Ritalin or Adderall), sedatives or tranquilizers (like sleeping pills or Valium), or drugs like marijuana, cocaine or crack, club drugs (like ecstasy), hallucinogens (like LSD), heroin, inhalants or solvents (like glue), or methamphetamine (like speed)]? is required",
      },
    ],
  };

  private buildForm() {
    this.crosscuttingleveloneadultForm = this.formBuilder.group({
      id: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      organizationId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      littleInterest: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      downDepressedHopeless: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      irritatedGrouchyAngry: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      sleepingLess: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      moreProjects: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      nervousAnxious: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      panicFrightened: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      avoidingSituations: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      achesPains: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      illnessIgnored: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      hurtingSelf: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      hearingVoices: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      imagining: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      sleepProblems: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      memoryProblems: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      unpleasantThoughts: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      performOverAndOver: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feelingDetached: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      dontKnowSelf: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      notCloseToOthers: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      drinking: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      cigarettes: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      drugs: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.crosscuttingleveloneadultForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    // for (let el in this.crosscuttingleveloneadultForm.controls) {
    //   if (this.crosscuttingleveloneadultForm.controls[el].errors) {
    //     console.log(el)
    //   }
    // }

    if (!this.crosscuttingleveloneadultForm) {
      return;
    }
    const form = this.crosscuttingleveloneadultForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
