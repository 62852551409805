<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle *ngIf="action === 'ADD'">Add New Allergy</p>
    <p matDialogTitle *ngIf="action === 'EDIT'">Edit Allergy</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="allergyForm" novalidate (ngSubmit)="onAllergyFormSubmit()">
    <div class="row" style="margin-top: 15px">
      <div class="col-md-12">
        <label class="labelText">
          Name <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input matInput formControlName="name" name="name" required />
          <mat-error *ngIf="allergyForm.controls['name'].invalid">
            Please enter the allergy name
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <label class="labelText">
          Reaction Type <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="reactionType">
            <mat-option value="Allergy">Allergy</mat-option>
            <mat-option value="Adverse Reaction">Adverse Reaction</mat-option>
          </mat-select>
          <mat-error *ngIf="allergyForm.controls['reactionType'].invalid">
            Please select the reaction type
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <label class="labelText"> Onset Date </label>
        <mat-form-field appearance="outline">
          <input
            formControlName="onsetDate"
            matInput
            [matDatepicker]="onsetDatePicker"
            autoSlashDate
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="onsetDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #onsetDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <label class="labelText"> Reaction </label>
        <mat-form-field appearance="outline">
          <textarea formControlName="reaction" matInput rows="5"></textarea>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <label class="labelText">
          Status <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="status">
            <mat-option value="Active">Active</mat-option>
            <mat-option value="Inactive">Inactive</mat-option>
          </mat-select>
          <mat-error *ngIf="allergyForm.controls['status'].invalid">
            Please select the status
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12" style="text-align: right">
        <button
          color="primary"
          mat-raised-button
          type="submit"
          [disabled]="!allergyForm.valid || processing"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
