import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { PermissionsSandbox } from 'src/app/shared/sandbox/permissions.sandbox';
import { hasAccess } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-my-assessments',
  templateUrl: './my-assessments.component.html',
  styleUrls: ['./my-assessments.component.css'],
})
export class MyAssessmentsComponent implements OnInit {
  selectedOption: string = 'In Review';

  //********  Assessment Permissions ********/
  // Assessments
  hasMemberAssessmentViewAccess = false;
  hasMemberAssessmentAddAccess = false;
  hasMemberAssessmentEditAccess = false;
  hasMemberAssessmentDeleteAccess = false;
  // Assessments Charts
  hasAssessmentChartsViewAccess = false;
  // Assessments Guidance
  hasAssessmentGuidanceViewAccess = false;
  hasAssessmentGuidanceEditAccess = false;
  // Assessments Summary
  hasAssessmentSummaryViewAccess = false;
  hasAssessmentSummaryEditAccess = false;
  // Assessments Take
  hasAssessmentTakeAddAccess = false;

  options = [
    { id: 'Pending', description: 'Pending' },
    { id: 'In Review', description: 'In Review' },
    { id: 'Completed', description: 'Completed' },
  ];

  constructor(
    private router: Router,
    private orgConfigSandbox: OrgConfigSandbox,
    private permissionsSandbox: PermissionsSandbox,
    private keycloakService: KeycloakService
  ) {
    if (this.router.getCurrentNavigation()?.extras.state) {
      let passedInStatus =
        this.router.getCurrentNavigation().extras.state.status;
      this.selectedOption = passedInStatus;
    }
  }

  ngOnInit(): void {
    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        // When load is complete, get the org config from app state
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          this.permissionsSandbox.permissions$.subscribe((response) => {
            //********  Assessment Permissions ********/
            // Assessments
            this.hasMemberAssessmentViewAccess = hasAccess(
              this.keycloakService,
              'MEMBER_ASSESSMENTS_VIEW',
              response,
              null
            );
            this.hasMemberAssessmentAddAccess = hasAccess(
              this.keycloakService,
              'MEMBER_ASSESSMENTS_ADD',
              response,
              null
            );
            this.hasMemberAssessmentEditAccess = hasAccess(
              this.keycloakService,
              'MEMBER_ASSESSMENTS_EDIT',
              response,
              null
            );
            this.hasMemberAssessmentDeleteAccess = hasAccess(
              this.keycloakService,
              'MEMBER_ASSESSMENTS_DELETE',
              response,
              null
            );
            // Assessments Charts
            this.hasAssessmentChartsViewAccess = hasAccess(
              this.keycloakService,
              'CHARTS_VIEW',
              response,
              null
            );
            // Assessments Guidance
            this.hasAssessmentGuidanceViewAccess = hasAccess(
              this.keycloakService,
              'GUIDANCE_VIEW',
              response,
              null
            );
            this.hasAssessmentGuidanceEditAccess = hasAccess(
              this.keycloakService,
              'GUIDANCE_EDIT',
              response,
              null
            );
            // Assessments Summary
            this.hasAssessmentSummaryViewAccess = hasAccess(
              this.keycloakService,
              'SUMMARY_VIEW',
              response,
              null
            );
            this.hasAssessmentSummaryEditAccess = hasAccess(
              this.keycloakService,
              'SUMMARY_EDIT',
              response,
              null
            );
            // Assessments Take
            this.hasAssessmentTakeAddAccess = hasAccess(
              this.keycloakService,
              'TAKE_ASSESSMENTS_ADD',
              response,
              null
            );
          });
        });
      }
    });
  }

  toggleSelection(option: any, chip: any) {
    if (chip) {
      if (!chip.selected) {
        chip.toggleSelected();
      }
    }
    this.selectedOption = option;
  }
}
