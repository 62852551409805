import { Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { ClaimRemitsPaymentHistoryComponent } from './claim-remits-payment-history/claim-remits-payment-history.component';
import { RemittancesClaimsDetailsComponent } from './remittances-claims-details/remittances-claims-details.component';
import { RemittancesDetailsComponent } from './remittances-details/remittances-details.component';
import { RemittancesListComponent } from './remittances-list/remittances-list.component';
import { RecordRemittanceComponent } from './record-remittance/record-remittance.component';

export const RemittancesRoutes: Routes = [
  {
    path: 'remit',
    children: [
      {
        path: '',
        component: RemittancesListComponent,
        canActivate: [AuthGuard],
        data: {
          roles: ['physician', 'orgadmin', 'superorgadmin', 'careprovider'],
          excludeRoles: ['patient', 'inhospitaldevice'],
        },
      },
      {
        path: 'recordremit',
        component: RecordRemittanceComponent,
        canActivate: [AuthGuard],
        data: {
          roles: ['physician', 'orgadmin', 'superorgadmin', 'careprovider'],
          excludeRoles: ['patient', 'inhospitaldevice'],
        },
      },
      {
        path: 'recordremit/:id',
        component: RecordRemittanceComponent,
        canActivate: [AuthGuard],
        data: {
          roles: ['physician', 'orgadmin', 'superorgadmin', 'careprovider'],
          excludeRoles: ['patient', 'inhospitaldevice'],
        },
      },
      {
        path: 'paymenthistory/:claimId',
        component: ClaimRemitsPaymentHistoryComponent,
        canActivate: [AuthGuard],
        data: {
          roles: ['physician', 'orgadmin', 'superorgadmin', 'careprovider'],
          excludeRoles: ['patient', 'inhospitaldevice'],
        },
      },
      {
        path: 'paymentremithistory/:claimId',
        component: RemittancesClaimsDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          roles: ['physician', 'orgadmin', 'superorgadmin', 'careprovider'],
          excludeRoles: ['patient', 'inhospitaldevice'],
        },
      },
      {
        path: ':id',
        component: RemittancesDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          roles: ['physician', 'orgadmin', 'superorgadmin', 'careprovider'],
          excludeRoles: ['patient', 'inhospitaldevice'],
        },
      },
      {
        path: ':remitId/:refNo/:claimId',
        component: RemittancesClaimsDetailsComponent,
        canActivate: [AuthGuard],
        data: {
          roles: ['physician', 'orgadmin', 'superorgadmin', 'careprovider'],
          excludeRoles: ['patient', 'inhospitaldevice'],
        },
      },
    ],
  },
];
