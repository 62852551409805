import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HealthCondition, ITherapySession } from 'src/app/_models';
import { IBpsaHealthHistory } from 'src/app/_models/session/clinical/bpsa-clinical-health-history.model';
import { BpsaMedicalHistoryApiService } from 'src/app/_services/session/clinical/bpsa-clinical-medical-history.service';
import { ToastMessageService } from 'src/app/_services/toast-message.service';
@Component({
  selector: 'app-member-health-history',
  templateUrl: './member-health-history.component.html',
  styleUrls: ['./member-health-history.component.css'],
})
export class MemberHealthHistoryComponent implements OnInit {
  @Input() therapySession: ITherapySession;
  @Input() loggedInUserId: string;
  @Input() hasBPSAClinicalAddAccess: boolean;
  @Input() hasBPSAClinicalEditAccess: boolean;

  @Output() updatedTherapySession = new EventEmitter<any>();

  memberConditionsList: any = [
    { name: 'Alcohol Abuse', selected: false },
    { name: 'Anemia', selected: false },
    { name: 'Anesthetic Complication', selected: false },
    { name: 'Anxiety Disorder', selected: false },
    { name: 'Arthritis', selected: false },
    { name: 'Asthma', selected: false },
    { name: 'Autoimmune Problems', selected: false },
    { name: 'Birth Defects', selected: false },
    { name: 'Bladder Problems', selected: false },
    { name: 'Bleeding Disease', selected: false },
    { name: 'Blood Clots', selected: false },
    { name: 'Blood Transfusion(s)', selected: false },
    { name: 'Bowel Disease', selected: false },
    { name: 'Breast Cancer', selected: false },
    { name: 'Cervical Cancer', selected: false },
    { name: 'Colon Cancer', selected: false },
    { name: 'Depression', selected: false },
    { name: 'Diabetes', selected: false },
    { name: 'Growth/Development Disorder', selected: false },
    { name: 'Hearing Impairment', selected: false },
    { name: 'Heart Attack', selected: false },
    { name: 'Heart Disease', selected: false },
    { name: 'Heart Pain/Angina', selected: false },
    { name: 'Hepatitis A', selected: false },
    { name: 'Hepatitis B', selected: false },
    { name: 'Hepatitis C', selected: false },
    { name: 'High Blood Pressure', selected: false },
    { name: 'High Cholesterol', selected: false },
    { name: 'HIV', selected: false },
    { name: 'Hives', selected: false },
    { name: 'Kidney Disease', selected: false },
    { name: 'Liver Cancer', selected: false },
    { name: 'Liver Disease', selected: false },
    { name: 'Lung Cancer', selected: false },
    { name: 'Lung/Respiratory Disease', selected: false },
    { name: 'Mental Illness', selected: false },
    { name: 'Migraines', selected: false },
    { name: 'Osteoporosis', selected: false },
    { name: 'Prostate Cancer', selected: false },
    { name: 'Rectal Cancer', selected: false },
    { name: 'Reflux/GERD', selected: false },
    { name: 'Seizures/Convulsions', selected: false },
    { name: 'Severe Allergy', selected: false },
    { name: 'Sexually Transmitted Disease', selected: false },
    { name: 'Skin Cancer', selected: false },
    { name: 'Stroke/CVA of the Brain', selected: false },
    { name: 'Suicide Attempt', selected: false },
    { name: 'Thyroid Problems', selected: false },
    { name: 'Ulcer', selected: false },
    { name: 'Visual Impairment', selected: false },
    {
      name: 'Other Disease, Cancer, or Significant Medical Illness',
      selected: false,
    },
  ];

  private patientMedicalConditions: HealthCondition[];
  healthConditions: IBpsaHealthHistory;

  processing: boolean = false;

  constructor(
    private bpsaMedicalHistoryApiService: BpsaMedicalHistoryApiService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit(): void {
    this.healthConditions = this.therapySession.bpsaHealthHistory;
    this.patientMedicalConditions = this.healthConditions?.memberConditions;

    if (this.patientMedicalConditions) {
      for (let i = 0; i < this.memberConditionsList.length; i++) {
        if (
          this.patientMedicalConditions.includes(
            this.memberConditionsList[i].name
          )
        ) {
          this.memberConditionsList[i].selected = true;
        }
      }
    }
  }

  updateHealthCondition() {
    let selectedHealthConditions = [];
    for (let i = 0; i < this.memberConditionsList.length; i++) {
      if (this.memberConditionsList[i].selected) {
        selectedHealthConditions.push(this.memberConditionsList[i].name);
      }
    }

    if (!this.healthConditions) {
      this.healthConditions = {} as IBpsaHealthHistory;
    }

    this.healthConditions.sessionId = this.therapySession.id;
    this.healthConditions.patientId = this.therapySession.patientId;
    this.healthConditions.organizationId = this.therapySession.organizationId;

    this.healthConditions.memberConditions = selectedHealthConditions;

    this.processing = true;
    this.bpsaMedicalHistoryApiService
      .updateBpsaHealthHistory(this.healthConditions)
      .subscribe(
        (response) => {
          this.processing = false;
          // this.toastMessageService.displaySuccessMessage(
          //   'Successfully updated Member Medical History'
          // );
        },
        (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Something went wrong. Please try again.'
          );
        }
      );
  }
}
