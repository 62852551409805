import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataTablesModel, ITherapySession, Patient } from 'src/app/_models';
import { IAssessmentGoal } from 'src/app/_models/configure/assessment-goal.model';
import { ToastMessageService } from 'src/app/_services';
import { AssessmentGoalHttpService } from 'src/app/_services/assessments/assessmentgoal.service';
import { AddEditSessionGoalComponent } from './goal-card/add-edit-session-goal/add-edit-session-goal.component';
import { AvailableGoalsComponent } from './available-goals/available-goals.component';

@Component({
  selector: 'app-session-goals-by-category',
  templateUrl: './session-goals-by-category.component.html',
  styleUrls: ['./session-goals-by-category.component.css'],
})
export class SessionGoalsByCategoryComponent implements OnInit {
  @Input() therapySession: ITherapySession;
  @Input() patient: Patient;
  @Input() loggedInUserId: string;

  @Input() hasGoalsViewAccess: boolean;
  @Input() hasGoalsAddAccess: boolean;
  @Input() hasGoalsEditAccess: boolean;
  @Input() hasGoalsDeleteAccess: boolean;

  selectedCategory: string = 'Addiction';
  data: DataTablesModel = {} as DataTablesModel;

  isLoadingResults: boolean = false;

  goals: IAssessmentGoal[] = [];
  assignedGoalStatus: string = 'Active';

  constructor(
    private dialog: MatDialog,
    public assessmentGoalHttpService: AssessmentGoalHttpService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit(): void {
    this.data.page = 0;
    this.data.per_page = 100;

    this.loadSessionGoals();
  }

  loadSessionGoals() {
    this.isLoadingResults = true;

    this.assessmentGoalHttpService
      .getSessionGoals(
        null,
        null,
        this.patient.id,
        this.data.per_page,
        this.data.page,
        null,
        this.assignedGoalStatus,
        null
      )
      .subscribe({
        next: (response) => {
          if (response) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }

          this.isLoadingResults = false;
        },
        error: (error) => {
          this.isLoadingResults = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get goals for this session'
          );
        },
      });
  }

  addNewGoal() {
    let dialogRef = this.dialog.open(AddEditSessionGoalComponent, {
      disableClose: true,
      autoFocus: false,
      minWidth: '32vw',
      data: {
        action: 'ADDNEW',
        therapySession: this.therapySession,
        loggedInUserId: this.loggedInUserId,
        patient: this.patient,
      },
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadSessionGoals();
      }
    });
  }

  hasChanges(hasChangeEvent: boolean) {
    if (hasChangeEvent) {
      this.loadSessionGoals();
    }
  }

  changeAssignedGoalStatus(event: any) {
    if (event?.newStatus) {
      this.assignedGoalStatus = event.newStatus;
      this.loadSessionGoals();
    }
  }

  openLibrary() {
    let dialogRef = this.dialog.open(AvailableGoalsComponent, {
      data: {
        patient: this.patient,
        therapySession: this.therapySession,
        hasGoalsAddAccess: this.hasGoalsAddAccess,
        hasGoalsEditAccess: this.hasGoalsEditAccess,
        hasGoalsDeleteAccess: this.hasGoalsDeleteAccess,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadSessionGoals();
      }
    });
  }
}
