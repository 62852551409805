<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>DSM 5 - Level 1 Adult Cross Cutting Symptoms</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row pad20">
    <div class="col-sm-12 text-center">
      Symptoms:
      <span
        *ngIf="
          !somatic &&
          !sleep &&
          !depression &&
          !anger &&
          !mania &&
          !anxiety &&
          !psychosis &&
          !repetetiveThoughtsAndBehaviors &&
          !substanceUse &&
          !suicidal &&
          !memory &&
          !dissociation &&
          !personality
        "
      >
        &nbsp;<strong>None</strong>
      </span>
      <br />
      <button class="btn btn-primary" mat-raised-button *ngIf="somatic">
        Somatic
      </button>
      <button class="btn btn-primary" mat-raised-button *ngIf="sleep">
        Sleep
      </button>
      <button class="btn btn-primary" mat-raised-button *ngIf="depression">
        Depression
      </button>
      <button class="btn btn-primary" mat-raised-button *ngIf="anger">
        Anger
      </button>
      <button class="btn btn-primary" mat-raised-button *ngIf="mania">
        Mania
      </button>
      <button class="btn btn-primary" mat-raised-button *ngIf="anxiety">
        Anxiety
      </button>
      <button class="btn btn-primary" mat-raised-button *ngIf="psychosis">
        Psychosis
      </button>
      <button
        class="btn btn-primary"
        mat-raised-button
        *ngIf="repetetiveThoughtsAndBehaviors"
      >
        Repetetive Thoughts And Behaviors
      </button>
      <button class="btn btn-primary" mat-raised-button *ngIf="substanceUse">
        Substance Use
      </button>
      <button class="btn btn-primary" mat-raised-button *ngIf="suicidal">
        Suicidal Ideation
      </button>
      <button class="btn btn-primary" mat-raised-button *ngIf="memory">
        Memory
      </button>
      <button class="btn btn-primary" mat-raised-button *ngIf="dissociation">
        Dissociation
      </button>
      <button class="btn btn-primary" mat-raised-button *ngIf="personality">
        Personality
      </button>
    </div>
  </div>

  <form
    [formGroup]="crosscuttingleveloneadultForm"
    novalidate
    (ngSubmit)="onCrossCuttingLevelOneAdultFormSubmit()"
  >
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <label class="col-md-6 asterix-after"
          >1. Little interest or pleasure in doing things?
        </label>
        <div class="col-md-6">
          <mat-button-toggle-group formControlName="littleInterest">
            <mat-button-toggle value="None">None</mat-button-toggle>
            <mat-button-toggle value="Slight">Slight</mat-button-toggle>
            <mat-button-toggle value="Mild">Mild</mat-button-toggle>
            <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
            <mat-button-toggle value="Severe">Severe</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <label class="col-md-6 asterix-after"
          >2. Feeling down, depressed, or hopeless?
        </label>
        <div class="col-md-6">
          <mat-button-toggle-group formControlName="downDepressedHopeless">
            <mat-button-toggle value="None">None</mat-button-toggle>
            <mat-button-toggle value="Slight">Slight</mat-button-toggle>
            <mat-button-toggle value="Mild">Mild</mat-button-toggle>
            <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
            <mat-button-toggle value="Severe">Severe</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <label class="col-md-6 asterix-after"
          >3. Feeling more irritated, grouchy, or angry than usual?
        </label>
        <div class="col-md-6">
          <mat-button-toggle-group formControlName="irritatedGrouchyAngry">
            <mat-button-toggle value="None">None</mat-button-toggle>
            <mat-button-toggle value="Slight">Slight</mat-button-toggle>
            <mat-button-toggle value="Mild">Mild</mat-button-toggle>
            <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
            <mat-button-toggle value="Severe">Severe</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <label class="col-md-6 asterix-after"
          >4. Sleeping less than usual, but still have a lot of energy?</label
        >
        <div class="col-md-6">
          <mat-button-toggle-group formControlName="sleepingLess">
            <mat-button-toggle value="None">None</mat-button-toggle>
            <mat-button-toggle value="Slight">Slight</mat-button-toggle>
            <mat-button-toggle value="Mild">Mild</mat-button-toggle>
            <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
            <mat-button-toggle value="Severe">Severe</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <label class="col-md-6 asterix-after"
          >5. Starting lots more projects than usual or doing more risky things
          than usual?
        </label>
        <div class="col-md-6">
          <mat-button-toggle-group formControlName="moreProjects">
            <mat-button-toggle value="None">None</mat-button-toggle>
            <mat-button-toggle value="Slight">Slight</mat-button-toggle>
            <mat-button-toggle value="Mild">Mild</mat-button-toggle>
            <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
            <mat-button-toggle value="Severe">Severe</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <label class="col-md-6 asterix-after"
          >6. Feeling nervous, anxious, frightened, worried, or on edge?</label
        >
        <div class="col-md-6">
          <mat-button-toggle-group formControlName="nervousAnxious">
            <mat-button-toggle value="None">None</mat-button-toggle>
            <mat-button-toggle value="Slight">Slight</mat-button-toggle>
            <mat-button-toggle value="Mild">Mild</mat-button-toggle>
            <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
            <mat-button-toggle value="Severe">Severe</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <label class="col-md-6 asterix-after"
          >7. Feeling panic or being frightened?</label
        >
        <div class="col-md-6">
          <mat-button-toggle-group formControlName="panicFrightened">
            <mat-button-toggle value="None">None</mat-button-toggle>
            <mat-button-toggle value="Slight">Slight</mat-button-toggle>
            <mat-button-toggle value="Mild">Mild</mat-button-toggle>
            <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
            <mat-button-toggle value="Severe">Severe</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <label class="col-md-6 asterix-after"
          >8. Avoiding situations that make you anxious?</label
        >
        <div class="col-md-6">
          <mat-button-toggle-group formControlName="avoidingSituations">
            <mat-button-toggle value="None">None</mat-button-toggle>
            <mat-button-toggle value="Slight">Slight</mat-button-toggle>
            <mat-button-toggle value="Mild">Mild</mat-button-toggle>
            <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
            <mat-button-toggle value="Severe">Severe</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <label class="col-md-6 asterix-after"
          >9. Unexplained aches and pains (e.g., head, back, joints, abdomen,
          legs)?</label
        >
        <div class="col-md-6">
          <mat-button-toggle-group formControlName="achesPains">
            <mat-button-toggle value="None">None</mat-button-toggle>
            <mat-button-toggle value="Slight">Slight</mat-button-toggle>
            <mat-button-toggle value="Mild">Mild</mat-button-toggle>
            <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
            <mat-button-toggle value="Severe">Severe</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <label class="col-md-6 asterix-after"
          >10. Feeling that your illnesses are not being taken seriously
          enough?</label
        >
        <div class="col-md-6">
          <mat-button-toggle-group formControlName="illnessIgnored">
            <mat-button-toggle value="None">None</mat-button-toggle>
            <mat-button-toggle value="Slight">Slight</mat-button-toggle>
            <mat-button-toggle value="Mild">Mild</mat-button-toggle>
            <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
            <mat-button-toggle value="Severe">Severe</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <label class="col-md-6 asterix-after"
          >11. Thoughts of actually hurting yourself?
        </label>
        <div class="col-md-6">
          <mat-button-toggle-group formControlName="hurtingSelf">
            <mat-button-toggle value="None">None</mat-button-toggle>
            <mat-button-toggle value="Slight">Slight</mat-button-toggle>
            <mat-button-toggle value="Mild">Mild</mat-button-toggle>
            <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
            <mat-button-toggle value="Severe">Severe</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <label class="col-md-6 asterix-after"
          >12. Hearing things other people couldn't hear, such as voices even
          when no one was around?</label
        >
        <div class="col-md-6">
          <mat-button-toggle-group formControlName="hearingVoices">
            <mat-button-toggle value="None">None</mat-button-toggle>
            <mat-button-toggle value="Slight">Slight</mat-button-toggle>
            <mat-button-toggle value="Mild">Mild</mat-button-toggle>
            <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
            <mat-button-toggle value="Severe">Severe</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <label class="col-md-6 asterix-after"
          >13. Feeling that someone could hear your thoughts, or that you could
          hear what another person was thinking?</label
        >
        <div class="col-md-6">
          <mat-button-toggle-group formControlName="imagining">
            <mat-button-toggle value="None">None</mat-button-toggle>
            <mat-button-toggle value="Slight">Slight</mat-button-toggle>
            <mat-button-toggle value="Mild">Mild</mat-button-toggle>
            <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
            <mat-button-toggle value="Severe">Severe</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <label class="col-md-6 asterix-after"
          >14.Problems with sleep that affected your sleep quality over
          all?</label
        >
        <div class="col-md-6">
          <mat-button-toggle-group formControlName="sleepProblems">
            <mat-button-toggle value="None">None</mat-button-toggle>
            <mat-button-toggle value="Slight">Slight</mat-button-toggle>
            <mat-button-toggle value="Mild">Mild</mat-button-toggle>
            <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
            <mat-button-toggle value="Severe">Severe</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <label class="col-md-6 asterix-after"
          >15. Problems with memory (e.g., learning new information) or with
          location (e.g., finding your way home)?
        </label>
        <div class="col-md-6">
          <mat-button-toggle-group formControlName="memoryProblems">
            <mat-button-toggle value="None">None</mat-button-toggle>
            <mat-button-toggle value="Slight">Slight</mat-button-toggle>
            <mat-button-toggle value="Mild">Mild</mat-button-toggle>
            <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
            <mat-button-toggle value="Severe">Severe</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <label class="col-md-6 asterix-after"
          >16. Unpleasant thoughts, urges, or images that repeatedly enter your
          mind?
        </label>
        <div class="col-md-6">
          <mat-button-toggle-group formControlName="unpleasantThoughts">
            <mat-button-toggle value="None">None</mat-button-toggle>
            <mat-button-toggle value="Slight">Slight</mat-button-toggle>
            <mat-button-toggle value="Mild">Mild</mat-button-toggle>
            <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
            <mat-button-toggle value="Severe">Severe</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <label class="col-md-6 asterix-after"
          >17. Feeling driven to perform certain behaviors or mental acts over
          and over again?</label
        >
        <div class="col-md-6">
          <mat-button-toggle-group formControlName="performOverAndOver">
            <mat-button-toggle value="None">None</mat-button-toggle>
            <mat-button-toggle value="Slight">Slight</mat-button-toggle>
            <mat-button-toggle value="Mild">Mild</mat-button-toggle>
            <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
            <mat-button-toggle value="Severe">Severe</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <label class="col-md-6 asterix-after"
          >18. Feeling detached or distant from yourself, your body, your
          physical surroundings, or your memories?</label
        >
        <div class="col-md-6">
          <mat-button-toggle-group formControlName="feelingDetached">
            <mat-button-toggle value="None">None</mat-button-toggle>
            <mat-button-toggle value="Slight">Slight</mat-button-toggle>
            <mat-button-toggle value="Mild">Mild</mat-button-toggle>
            <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
            <mat-button-toggle value="Severe">Severe</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <label class="col-md-6 asterix-after"
          >19. Not knowing who you really are or what you want out of
          life?</label
        >
        <div class="col-md-6">
          <mat-button-toggle-group formControlName="dontKnowSelf">
            <mat-button-toggle value="None">None</mat-button-toggle>
            <mat-button-toggle value="Slight">Slight</mat-button-toggle>
            <mat-button-toggle value="Mild">Mild</mat-button-toggle>
            <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
            <mat-button-toggle value="Severe">Severe</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <label class="col-md-6 asterix-after"
          >20. Not feeling close to other people or enjoying your relationships
          with them?</label
        >
        <div class="col-md-6">
          <mat-button-toggle-group formControlName="notCloseToOthers">
            <mat-button-toggle value="None">None</mat-button-toggle>
            <mat-button-toggle value="Slight">Slight</mat-button-toggle>
            <mat-button-toggle value="Mild">Mild</mat-button-toggle>
            <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
            <mat-button-toggle value="Severe">Severe</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <label class="col-md-6 asterix-after"
          >21. Drinking at least 4 drinks of any kind of alcohol in a single
          day?</label
        >
        <div class="col-md-6">
          <mat-button-toggle-group formControlName="drinking">
            <mat-button-toggle value="None">None</mat-button-toggle>
            <mat-button-toggle value="Slight">Slight</mat-button-toggle>
            <mat-button-toggle value="Mild">Mild</mat-button-toggle>
            <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
            <mat-button-toggle value="Severe">Severe</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <label class="col-md-6 asterix-after"
          >22. Smoking any cigarettes, a cigar, or pipe, or using snuff or
          chewing tobacco?</label
        >
        <div class="col-md-6">
          <mat-button-toggle-group formControlName="cigarettes">
            <mat-button-toggle value="None">None</mat-button-toggle>
            <mat-button-toggle value="Slight">Slight</mat-button-toggle>
            <mat-button-toggle value="Mild">Mild</mat-button-toggle>
            <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
            <mat-button-toggle value="Severe">Severe</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <label class="col-md-6 asterix-after"
          >23. Using any of the following medicines ON YOUR OWN, that is,
          without a doctor's prescription, in greater amounts or longer than
          prescribed [e.g., painkillers (like Vicodin), stimulants (like Ritalin
          or Adderall), sedatives or tranquilizers (like sleeping pills or
          Valium), or drugs like marijuana, cocaine or crack, club drugs (like
          ecstasy), hallucinogens (like LSD), heroin, inhalants or solvents
          (like glue), or methamphetamine (like speed)]?</label
        >
        <div class="col-md-6">
          <mat-button-toggle-group formControlName="drugs">
            <mat-button-toggle value="None">None</mat-button-toggle>
            <mat-button-toggle value="Slight">Slight</mat-button-toggle>
            <mat-button-toggle value="Mild">Mild</mat-button-toggle>
            <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
            <mat-button-toggle value="Severe">Severe</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>

    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          crosscuttingleveloneadultForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!crosscuttingleveloneadultForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
