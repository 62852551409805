<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>{{ templateName }}</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="form" novalidate (ngSubmit)="onSubmit()">
    <div *ngFor="let field of placeholders">
      <label class="labelText">{{ field.name | camelCaseToSpaces }}</label>
      <mat-form-field appearance="outline">
        <input
          matInput
          [formControlName]="field.name"
          [type]="
            field.type === 'number'
              ? 'number'
              : field.type === 'email'
              ? 'email'
              : 'text'
          "
        />
        <mat-error
          *ngIf="
            form.controls[field.name].invalid &&
            form.controls[field.name].touched
          "
        >
          {{ field.name | camelCaseToSpaces }} is required
        </mat-error>
      </mat-form-field>
    </div>

    <small>
      <i>"{{ output }}"</i>
    </small>

    <mat-dialog-actions align="end">
      <button
        [class]="
          form.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!form.valid"
      >
        Submit
      </button>
    </mat-dialog-actions>
  </form>
</div>
