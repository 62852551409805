<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>{{ row.documentName }}</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <!-- Only once the patient details are loaded -->
  <div class="col-md-12" *ngIf="isLoadingResults">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div
    class="row"
    *ngIf="row?.documentType === 'Custom Forms' && !isLoadingResults"
    style="margin-top: 15px; height: 100%"
  >
    <div class="col-md-12" style="min-height: 500px">
      <survey
        [model]="model"
        style="position: absolute; top: 0; left: 0; right: 0; bottom: 0"
      >
      </survey>
    </div>
  </div>

  <div
    *ngIf="
      row?.documentType === 'Consent Documents' ||
      row?.documentName === 'Authorization To Release Medical Records'
    "
    style="height: 700px; width: 100%"
  >
    <ng2-pdfjs-viewer [download]="'true'" #pdfViewerOnDemand></ng2-pdfjs-viewer>
  </div>

  <!-- For Consent Document -->
  <div
    *ngIf="row?.documentType === 'Consent Documents' && !isLoadingResults"
    class="row"
    style="margin-top: 15px"
  >
    <div
      class="col-md-12"
      style="display: flex; justify-content: space-between"
    >
      <button
        mat-raised-button
        color="warn"
        type="button"
        (click)="
          acceptRejectSignature(false, 'Document has been re-requested.')
        "
        [disabled]="processing"
      >
        <mat-icon>change_circle</mat-icon> Request Again
      </button>

      <button
        mat-raised-button
        color="primary"
        type="button"
        (click)="
          acceptRejectSignature(
            true,
            'Successfully verified the consent document.'
          )
        "
        [disabled]="processing"
      >
        <mat-icon>check_circle</mat-icon> Accept Signature
        <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </div>
  </div>

  <!-- For Authorization To Release Medical Records -->
  <div
    *ngIf="
      row?.documentName === 'Authorization To Release Medical Records' &&
      !isLoadingResults
    "
    class="row"
    style="margin-top: 15px"
  >
    <div
      class="col-md-12"
      style="display: flex; justify-content: space-between"
    >
      <button
        mat-raised-button
        color="warn"
        type="button"
        (click)="
          acceptRejectSignature(false, 'Document has been re-requested.')
        "
        [disabled]="processing"
      >
        <mat-icon>change_circle</mat-icon> Request Again
      </button>

      <button
        mat-raised-button
        color="primary"
        type="button"
        (click)="acceptMedicalReleaseForm()"
        [disabled]="processing"
      >
        <mat-icon>check_circle</mat-icon> Accept Document
        <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </div>
  </div>

  <!-- For Custom Forms -->
  <div
    *ngIf="row?.documentType === 'Custom Forms' && !isLoadingResults"
    class="row"
    style="margin-top: 15px"
  >
    <div
      class="col-md-12"
      style="display: flex; justify-content: space-between"
    >
      <button
        mat-raised-button
        color="warn"
        type="button"
        (click)="
          acceptRejectSignature(false, 'Document has been re-requested.')
        "
        [disabled]="processing"
      >
        <mat-icon>change_circle</mat-icon> Request Again
      </button>

      <button
        mat-raised-button
        color="primary"
        type="button"
        (click)="acceptCustomFormData()"
        [disabled]="processing"
      >
        <mat-icon>check_circle</mat-icon> Accept Document
        <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </div>
  </div>
</div>
