import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
import { CareLog, Case, Countries } from 'src/app/_models';
import {
  CareLogHttpService,
  TherapySessionService,
  ToastMessageService,
} from 'src/app/_services';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { ErrorHandler } from '../../../../../shared/handlers/error.handler';
import {
  formatDate,
  formatDateTime,
  mmddyyyyhhmiToDate,
} from '../../../../../shared/utilities/utilities';

@Component({
  selector: 'app-carelog-vitals',
  templateUrl: './carelog-vitals.component.html',
  styleUrls: ['./carelog-vitals.component.css'],
})
export class CarelogVitalsComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() action: string;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() case: Case;
  @Input() logDate: Date;
  @Input() shift: number;
  @Input() carelog: CareLog;
  @Input() goals: any[];
  @Input() goalDrivenCare: boolean;
  @Output() taskCompleted = new EventEmitter<any>();

  todaysDate = new Date();

  orgConfig: any;
  isIndianOrg: boolean = false;
  isProcessing: boolean = false;

  constructor(
    public careLogHttpService: CareLogHttpService,
    public therapySessionApiService: TherapySessionService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    dateTimeAdapter: DateTimeAdapter<any>,
    private toastMessageService: ToastMessageService,
    private orgConfigSandbox: OrgConfigSandbox
  ) {
    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          this.orgConfig = orgConfig;
          if (this.orgConfig?.organizationCountry === Countries.India) {
            this.isIndianOrg = true;
            dateTimeAdapter.setLocale('en-GB');
          }
        });
      }
    });
  }

  public vitalsForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  errors: any = {};

  public ngOnInit() {
    if (this.case) {
      this.patientId = this.case.id;
    }

    this.buildForm();

    if (this.carelog && this.carelog.vitalInfoAvailable) {
      if (this.carelog.height) {
        this.vitalsForm.controls['height'].setValue(this.carelog.height);
      }

      this.vitalsForm.controls['weight'].setValue(this.carelog.weight);
      this.vitalsForm.controls['pulse'].setValue(this.carelog.pulse);
      this.vitalsForm.controls['bodyTemperature'].setValue(
        this.carelog.bodyTemperature
      );
      this.vitalsForm.controls['bloodPressureLow'].setValue(
        this.carelog.bloodPressureLow
      );
      this.vitalsForm.controls['bloodPressureHigh'].setValue(
        this.carelog.bloodPressureHigh
      );
      this.vitalsForm.controls['oxygenLevel'].setValue(
        this.carelog.oxygenLevel
      );
      this.vitalsForm.controls['bloodSugar'].setValue(this.carelog.bloodSugar);
      this.vitalsForm.controls['activityDateTime'].setValue(
        mmddyyyyhhmiToDate(this.carelog.activityDateTime)
      );
      this.vitalsForm.controls['message'].setValue(this.carelog.customMessage);
    } else {
      this.vitalsForm.controls['activityDateTime'].setValue(
        new Date(this.logDate)
      );
    }

    this.errorHandler.handleErrors(this.vitalsForm, this.errors);
  }

  onVitalsSubmit() {
    if (this.vitalsForm.invalid) {
      this.vitalsForm.markAllAsTouched();
      return;
    }

    this.isProcessing = true;

    if (this.carelog) {
      if (this.action === 'ADD') {
        this.carelog.patientId = this.patientId;
        this.carelog.message = 'Vitals';

        this.carelog.height = this.vitalsForm.controls['height'].value;
        this.carelog.weight = this.vitalsForm.controls['weight'].value;
        this.carelog.pulse = this.vitalsForm.controls['pulse'].value;
        this.carelog.bodyTemperature =
          this.vitalsForm.controls['bodyTemperature'].value;
        this.carelog.bloodPressureLow =
          this.vitalsForm.controls['bloodPressureLow'].value;
        this.carelog.bloodPressureHigh =
          this.vitalsForm.controls['bloodPressureHigh'].value;
        this.carelog.oxygenLevel =
          this.vitalsForm.controls['oxygenLevel'].value;
        this.carelog.bloodSugar = this.vitalsForm.controls['bloodSugar'].value;
        this.carelog.activityDateTime = formatDateTime(
          this.vitalsForm.controls['activityDateTime'].value
        );
        this.carelog.customMessage = this.vitalsForm.controls['message'].value;
        this.carelog.vitalInfoAvailable = true;

        this.addCareLog(this.carelog);
      } else {
        this.carelog.height = this.vitalsForm.controls['height'].value;
        this.carelog.weight = this.vitalsForm.controls['weight'].value;
        this.carelog.pulse = this.vitalsForm.controls['pulse'].value;
        this.carelog.bodyTemperature =
          this.vitalsForm.controls['bodyTemperature'].value;
        this.carelog.bloodPressureLow =
          this.vitalsForm.controls['bloodPressureLow'].value;
        this.carelog.bloodPressureHigh =
          this.vitalsForm.controls['bloodPressureHigh'].value;
        this.carelog.oxygenLevel =
          this.vitalsForm.controls['oxygenLevel'].value;
        this.carelog.bloodSugar = this.vitalsForm.controls['bloodSugar'].value;
        this.carelog.activityDateTime = formatDateTime(
          this.vitalsForm.controls['activityDateTime'].value
        );
        this.carelog.customMessage = this.vitalsForm.controls['message'].value;
        this.carelog.vitalInfoAvailable = true;

        if (this.case) {
          this.careLogHttpService
            .editCareLog(this.case.id, this.carelog)
            .subscribe({
              next: () => {
                this.isProcessing = false;
                let outputResponse = {
                  note: this.carelog,
                  response: 'success',
                };
                this.taskCompleted.emit(outputResponse);
                this.toastMessageService.displaySuccessMessage(
                  'Vitals log has been updated'
                );
              },
              error: (error) => {
                this.isProcessing = false;
                this.toastMessageService.displayErrorMessage(
                  'Failed to update vitals log'
                );
              },
            });
        } else if (this.sessionId) {
          this.therapySessionApiService
            .addVitalsToClinicalNote(this.sessionId, this.carelog)
            .subscribe(() => {
              this.toastMessageService.displaySuccessMessage(
                'Vital Info has been updated successfully'
              );
              this.isProcessing = false;
              this.taskCompleted.emit(true);
            });
        }
      }
    } else {
      this.carelog = Object.assign({}, this.carelog);
      this.carelog.patientId = this.patientId;
      this.carelog.message = 'Vitals';

      this.carelog.height = this.vitalsForm.controls['height'].value;
      this.carelog.weight = this.vitalsForm.controls['weight'].value;
      this.carelog.pulse = this.vitalsForm.controls['pulse'].value;
      this.carelog.bodyTemperature =
        this.vitalsForm.controls['bodyTemperature'].value;
      this.carelog.bloodPressureLow =
        this.vitalsForm.controls['bloodPressureLow'].value;
      this.carelog.bloodPressureHigh =
        this.vitalsForm.controls['bloodPressureHigh'].value;
      this.carelog.oxygenLevel = this.vitalsForm.controls['oxygenLevel'].value;
      this.carelog.bloodSugar = this.vitalsForm.controls['bloodSugar'].value;
      this.carelog.activityDateTime = formatDateTime(
        this.vitalsForm.controls['activityDateTime'].value
      );
      this.carelog.customMessage = this.vitalsForm.controls['message'].value;
      this.carelog.vitalInfoAvailable = true;
      this.addCareLog(this.carelog);
    }
  }

  public addCareLog(carelog: CareLog) {
    this.isProcessing = true;
    carelog.logDate = formatDate(this.logDate);
    carelog.patientId = this.patientId;

    if (this.case) {
      carelog.id = null;
      carelog.shift = this.shift;
      carelog.caseId = this.case.id;
      this.careLogHttpService
        .addCareLog(this.case.id, carelog)
        .subscribe(() => {
          this.toastMessageService.displaySuccessMessage(
            'Vital Info has been added successfully'
          );
          this.isProcessing = false;
          this.taskCompleted.emit(true);
        });
    } else if (this.sessionId) {
      carelog.id = null;
      this.therapySessionApiService
        .addVitalsToClinicalNote(this.sessionId, carelog)
        .subscribe(() => {
          this.toastMessageService.displaySuccessMessage(
            'Vital Info has been added successfully'
          );
          this.isProcessing = false;
          this.taskCompleted.emit(true);
        });
    }
  }

  private buildForm() {
    this.vitalsForm = this.formBuilder.group(
      {
        pulse: new FormControl(null),
        bodyTemperature: new FormControl(null),
        bloodPressureLow: new FormControl(null),
        bloodPressureHigh: new FormControl(null),
        oxygenLevel: new FormControl(null),
        bloodSugar: new FormControl(null),
        height: new FormControl(null),
        weight: new FormControl(null),
        activityDateTime: new FormControl(null, Validators.required),
        message: new FormControl(''),
      },
      { validators: this.customValidator }
    );
  }

  customValidator(group: FormGroup): { [key: string]: any } | null {
    const fields = [
      'pulse',
      'bodyTemperature',
      'oxygenLevel',
      'bloodSugar',
      'weight',
      'height',
    ];
    const atLeastOneFilled = fields.some((field) => !!group.get(field)?.value);

    const bp1 = group.get('bloodPressureLow')?.value;
    const bp2 = group.get('bloodPressureHigh')?.value;
    const comboBpFilled = !!bp1 && !!bp2;

    return atLeastOneFilled || comboBpFilled
      ? null
      : { atLeastOneRequired: true };
  }

  public validation_messages = {
    weight: [{ type: 'required', message: 'Weight is required' }],
    pulse: [{ type: 'required', message: 'Pulse Rate is required' }],
    bodyTemperature: [
      { type: 'required', message: 'Body Temperature is required' },
      { type: 'pattern', message: 'Please enter a value between 95 and 107' },
    ],
    bloodPressureLow: [
      { type: 'required', message: 'Low Blood Pressure is required' },
    ],
    bloodPressureHigh: [
      { type: 'required', message: 'High Blood Pressure is required' },
    ],
    oxygenLevel: [{ type: 'required', message: 'Oxygen Level is required' }],
    bloodSugar: [{ type: 'required', message: 'Blood Sugar is required' }],
  };
}
