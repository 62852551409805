import { Component, OnInit, ViewChild } from '@angular/core';
import {
  MbscCalendarEvent,
  MbscEventcalendarOptions,
  MbscPopup,
  MbscPopupOptions,
  Notifications,
  formatDate,
  setOptions,
} from '@mobiscroll/angular';
import { addDays, addMinutes } from 'date-fns';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  CoreService,
  FacilitiesHttpService,
  ShiftApiService,
  ToastMessageService,
} from 'src/app/_services';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { Colors } from 'src/app/shared/utilities/colors';
import { printableTimePeriod } from 'src/app/shared/utilities/utilities';

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

@Component({
  selector: 'app-staff-schedules-dashboard',
  templateUrl: './staff-schedules-dashboard.component.html',
  styleUrl: './staff-schedules-dashboard.component.css',
  providers: [Notifications],
})
export class StaffSchedulesDashboardComponent implements OnInit {
  @ViewChild('popup', { static: false })
  popup!: MbscPopup;
  popupHeader!: string;

  isSuperOrgAdmin: boolean = false;
  isOrgAdmin: boolean = false;
  colors = Colors;
  currentTime = new Date();
  minimumCalendarDate = addDays(new Date(), -30);

  public isLoadingOrganizationDetails = true;
  public isLoadingResources = true;
  public isLoadingShiftsDetails = true;
  public isLoadingCalendar = true;
  public isSavingShift = false;
  public isDeletingShift = false;

  isEdit = false;

  selectedEvent = [];
  selectedUser: string = '';

  facilities: any[];
  resources = [];
  shifts: MbscCalendarEvent[] = [];
  slots = [];
  origSlots = [];
  shiftTimes = [];

  calendarOptions: MbscEventcalendarOptions;

  views = [
    {
      text: 'Day',
      value: 'day',
    },
    {
      text: 'Week',
      value: 'week',
    },
  ];
  viewType = 'week';

  calView: any = {
    timeline: {
      type: 'week',
      eventList: true,
      startDay: 0,
      endDay: 6,
    },
  };

  popupAddButtons = [
    'cancel',
    {
      handler: () => {
        if (!this.isSavingShift) {
          this.saveShift();
        }
      },
      keyCode: 'enter',
      text: 'Add',
      cssClass: 'mbsc-popup-button-primary',
    },
  ];

  popupEditButtons = ['cancel'];

  popupButtons: any = [];
  popupOptions: MbscPopupOptions = {
    display: 'bottom',
    contentPadding: false,
    fullScreen: true,
    focusOnClose: false,
    onClose: () => {
      if (!this.isEdit) {
        this.shifts = [...this.shifts];
      }
    },
    responsive: {
      medium: {
        display: 'center',
        width: 400,
        fullScreen: false,
        touchUi: false,
        showOverlay: false,
      },
    },
  };

  constructor(
    private toastMessageService: ToastMessageService,
    private facilityService: FacilitiesHttpService,
    private shiftApiService: ShiftApiService,
    private spinner: NgxSpinnerService,
    private coreService: CoreService,
    private orgConfigSandbox: OrgConfigSandbox
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.isSuperOrgAdmin = this.coreService.isSuperOrgAdmin();
    this.isOrgAdmin = this.coreService.isOrgAdmin();

    this.isLoadingOrganizationDetails = true;
    this.isLoadingResources = true;
    this.isLoadingShiftsDetails = true;
    this.isLoadingCalendar = true;

    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfigResponse) => {
          let orgConfig = orgConfigResponse;
          if (orgConfig) {
            let totalShifts = orgConfig.totalShiftsPerDay;
            this.shiftTimes = [];
            if (totalShifts >= 1) {
              let shift: any = {};
              shift.id = 1;
              shift.name = orgConfig.firstShiftName;
              shift.time = printableTimePeriod(
                orgConfig.firstShiftStartTime,
                orgConfig.firstShiftEndTime
              );
              shift.start = orgConfig.firstShiftStartTime;
              shift.end = orgConfig.firstShiftEndTime;
              this.slots.push(shift);
              this.origSlots.push(shift);

              this.shiftTimes.push({
                id: 1,
                name: orgConfig.firstShiftName,
                checked: true,
                disabled: 'false',
              });
            }

            if (totalShifts >= 2) {
              let shift: any = {};
              shift.id = 2;
              shift.name = orgConfig.secondShiftName;
              shift.time = printableTimePeriod(
                orgConfig.secondShiftStartTime,
                orgConfig.secondShiftEndTime
              );
              shift.start = orgConfig.secondShiftStartTime;
              shift.end = orgConfig.secondShiftEndTime;
              this.slots.push(shift);
              this.origSlots.push(shift);

              this.shiftTimes.push({
                id: 2,
                name: orgConfig.secondShiftName,
                checked: true,
                disabled: 'false',
              });
            }

            if (totalShifts >= 3) {
              let shift: any = {};
              shift.id = 3;
              shift.name = orgConfig.thirdShiftName;
              shift.time = printableTimePeriod(
                orgConfig.thirdShiftStartTime,
                orgConfig.thirdShiftEndTime
              );
              shift.start = orgConfig.thirdShiftStartTime;
              shift.end = orgConfig.thirdShiftEndTime;
              this.slots.push(shift);
              this.origSlots.push(shift);

              this.shiftTimes.push({
                id: 3,
                name: orgConfig.thirdShiftName,
                checked: true,
                disabled: 'false',
              });
            }

            this.isLoadingOrganizationDetails = false;
          }
        });
      }
    });

    // Getting a list of all the facilities
    this.facilityService.getMyFacilityShifts().subscribe((response) => {
      if (response) {
        this.facilities = response.items;

        for (const facility of this.facilities) {
          // If the facility does not have employee then not adding the facility here
          if (
            facility?.careProviderDetails &&
            facility?.careProviderDetails.length > 0
          ) {
            let record: any = {};
            record.id = facility.id;
            record.name = facility.facilityName;
            record.eventCreation = false;

            // Check if the children component exist

            let children = [];
            let careProviders = facility.careProviderDetails;

            careProviders.forEach((carePovider, i) => {
              let currentIndex = i % this.colors.length;

              let childRecord: any = {};
              childRecord.id = record.id + '~' + carePovider.id;
              childRecord.name =
                carePovider.firstName + ' ' + carePovider.lastName;
              childRecord.color = this.colors[currentIndex];

              children.push(childRecord);
            });

            this.resources.push(record);
            record.children = children;
          }
        }

        if (this.shifts) {
          this.setEmployeeNames();
        }
      }

      this.isLoadingResources = false;
    });

    // Get Employee shifts
    this.facilityService.getMyEmployeeShifts().subscribe((response) => {
      if (response) {
        this.shifts = [];
        let employeeShifts = response.items;

        for (const employeeShift of employeeShifts) {
          let event: any = {};
          event.id = employeeShift.id;
          event.start = new Date(employeeShift.start);
          event.end = addMinutes(new Date(employeeShift.end), -50);
          event.title = employeeShift.title;
          event.resource =
            employeeShift.locationId + '~' + employeeShift.userId;
          event.slot = employeeShift.slot;

          if (this.resources) {
            event.title = this.getEmployeeName(event.resource);
          }

          this.shifts.push(event);
        }
      }

      this.isLoadingShiftsDetails = false;
    });

    // When everyting is loaded then only execute the load CalendarOption
    setTimeout(() => {
      if (
        !this.isLoadingOrganizationDetails &&
        !this.isLoadingResources &&
        !this.isLoadingShiftsDetails
      ) {
        this.loadCalendarOptions();
      }
    }, 2000);
  }

  loadCalendarOptions() {
    this.calendarOptions = {
      resources: this.resources,
      dragToCreate: false,
      dragToResize: false,
      dragToMove: false,
      eventOverlap: false,

      clickToCreate: this.isSuperOrgAdmin || this.isOrgAdmin ? true : false,
      invalid: this.invalid,

      extendDefaultEvent: (event: any) => {
        return {
          title: this.getEmployeeName(event.resource),
        };
      },

      onEventCreated: (args: any) => {
        setTimeout(() => {
          this.isEdit = false;

          const event = args.event;
          const slot: any = this.slots.find((s) => s.id === event.slot);

          this.selectedEvent = event;
          this.selectedUser = event.title;

          this.saveShift();
        });
      },

      onEventClick: (args: any) => {
        this.isEdit = true;

        const event = args.event;
        const slot: any = this.slots.find((s) => s.id === event.slot);

        this.selectedEvent = event;
        this.selectedUser = event.title;

        // set popup options
        this.popupButtons = this.popupEditButtons;
        this.popupHeader =
          '<div>Edit ' +
          slot.name +
          '</div><div class="employee-shifts-day">' +
          formatDate('DDDD', new Date(event.start)) +
          '&nbsp;' +
          formatDate('DD MMMM YYYY', new Date(event.start)) +
          '</div>';
        // open the popup
        this.popup.open();
      },
    };
    this.isLoadingCalendar = false;
  }

  setEmployeeNames() {
    for (const shift of this.shifts) {
      shift.title = this.getEmployeeName(shift.resource);
    }
  }

  getEmployeeName(userId: any): any {
    for (const resource of this.resources) {
      if (resource.children) {
        for (const employee of resource.children) {
          if (employee.id === userId) {
            return employee.name;
          }
        }
      }
    }
  }

  invalid = [];

  saveShift(): void {
    // Calling api to save the shift
    this.isSavingShift = true;
    let tempShift: any = {};
    let resourceArray = this.selectedEvent['resource'].split('~');

    tempShift.location = this.facilities.filter(
      (facility) => facility.id === resourceArray[0]
    )[0].facilityName;
    tempShift.locationId = resourceArray[0];
    tempShift.resource = resourceArray[1];
    tempShift.start = new Date(this.selectedEvent['start']);
    tempShift.end = new Date(this.selectedEvent['end']);
    tempShift.category = 'SHIFT';
    tempShift.slot = this.selectedEvent['slot'];

    this.shiftApiService.addShift(tempShift).subscribe(
      (response) => {
        this.selectedEvent['id'] = response?.message?.text;
        this.isSavingShift = false;
        this.popup.close();
        this.shifts = [...this.shifts, this.selectedEvent];
        this.toastMessageService.displaySuccessMessage(
          'Schedule list has been updated.'
        );
        this.restoreDefaults();
      },
      (error) => {
        this.isSavingShift = false;
        this.shifts = [...this.shifts];
        this.toastMessageService.displayErrorMessage(
          'Error: Something went wrong'
        );
      }
    );
  }

  restoreDefaults() {
    this.selectedEvent = [];
    this.selectedUser = '';
  }

  onDeleteClick(): void {
    this.isDeletingShift = true;

    this.shiftApiService.deleteShift(this.selectedEvent['id']).subscribe(
      (response) => {
        this.isDeletingShift = false;
        this.shifts = this.shifts.filter(
          (item) => item.id != this.selectedEvent['id']
        );
        this.toastMessageService.displaySuccessMessage(
          'Schedule list has been updated.'
        );
        this.restoreDefaults();
        this.popup.close();
      },
      (error) => {
        this.isDeletingShift = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Something went wrong'
        );
      }
    );
  }

  getShiftsNrs(date: any, slotId: number): any {
    const shiftList: any = [];
    for (const shift of this.shifts) {
      // get slot id from resource id
      const resourceNr = +shift.resource.toString().charAt(0);
      if (shift.slot === slotId && date === this.formatMyDate(shift.start)) {
        shiftList[resourceNr - 1] = (shiftList[resourceNr - 1] || 0) + 1;
      }
    }
    return shiftList;
  }

  // Format Date
  formatMyDate(date: any): any {
    return formatDate('YYYY-MM-DD', new Date(date));
  }

  // Checkbox to determine the shift slots
  checkboxChange(ev: any): void {
    let filteredSlots: any = [];
    for (const slot of this.origSlots) {
      if (
        this.shiftTimes.find(
          (us: { id: number; checked: any }) => us.id === slot.id && us.checked
        )
      ) {
        filteredSlots.push(slot);
      }
    }

    if (filteredSlots.length === 1) {
      this.shiftTimes = this.shiftTimes.map((ut) =>
        ut.checked ? { ...ut, disabled: 'true' } : ut
      );
    } else {
      this.shiftTimes = this.shiftTimes.map((ut) => ({
        ...ut,
        disabled: 'false',
      }));
    }

    this.slots = filteredSlots;
  }

  // View Changed from day to week or vice versa
  viewChange(ev: any): void {
    this.calView =
      ev.value === 'day'
        ? {
            timeline: {
              type: 'day',
              eventList: true,
            },
          }
        : {
            timeline: {
              type: 'week',
              eventList: true,
              startDay: 0,
              endDay: 6,
            },
          };
  }
}
