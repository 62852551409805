import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { IMemberInsurance, ITherapySession, Patient } from 'src/app/_models';
import { DateTimeZoneService } from 'src/app/_services';
import { RunEligibilityComponent } from './run-eligibility/run-eligibility.component';

@Component({
  selector: 'app-insurance-card',
  templateUrl: './insurance-card.component.html',
  styleUrls: ['./insurance-card.component.css'],
})
export class InsuranceCardComponent implements OnInit {
  @Input() insurance: IMemberInsurance;
  @Input() parentClass: string;
  @Input() therapySession: ITherapySession;
  @Input() patient: Patient;
  @Output() reload = new EventEmitter<any>();

  currentTimezone: string;
  reloadEligibilityReport: Subject<boolean> = new Subject<boolean>();

  constructor(
    private dialog: MatDialog,
    private dateTimeZoneService: DateTimeZoneService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit(): void {}

  runEligibility(insurance) {
    let dialogRef = this.dialog.open(RunEligibilityComponent, {
      data: {
        parentClass: this.parentClass,
        therapySession: this.therapySession,
        patient: this.patient,
        insurance: insurance,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response.type === 'success') {
        this.reload.emit({ type: 'RELOAD', details: response.details });
        this.reloadEligibilityReport.next(true);
      }
    });
  }
}
