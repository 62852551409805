import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IUserFavoritesResponse } from '../../_models/users/user-favorites.model';

@Injectable({
    providedIn: 'root',
})
export class FavoritesService {
    constructor(private http: HttpClient) { }

    getFavorites(): Observable<IUserFavoritesResponse> {
        return this.http.get<IUserFavoritesResponse>(`${environment.apiUrl}/my-favorites`);
    }

    addFavorite(categoryName: string, favoriteItem: string): Observable<string> {
        const params = new HttpParams()
            .set('categoryName', categoryName)
            .set('favoriteItem', favoriteItem);
        return this.http.post<string>(`${environment.apiUrl}/add-favorite`, {}, { params });
    }

    removeFavorite(categoryName: string, favoriteItem: string): Observable<string> {
        const params = new HttpParams()
            .set('categoryName', categoryName)
            .set('favoriteItem', favoriteItem);
        return this.http.delete<string>(`${environment.apiUrl}/remove-favorite`, { params });
    }
}
