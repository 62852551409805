export const Race = [
  {
    id: 'AIOAN',
    description: 'American Indian or Alaska Native',
  },
  {
    id: 'ASIAN',
    description: 'Asian',
  },
  {
    id: 'BLKAA',
    description: 'Black or African American',
  },
  {
    id: 'NHOPI',
    description: 'Native Hawaiian or Other Pacific Islander',
  },
  {
    id: 'WHITE',
    description: 'White',
  },
  {
    id: 'DTSPY',
    description: 'Declined to specify',
  },
  {
    id: 'OTHRC',
    description: 'Other Race',
  },
];
