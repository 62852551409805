<!-- Providing Search Option -->
<div
  class="row"
  *ngIf="showHeader && status != 'Pending'"
  style="
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
    row-gap: 15px;
  "
>
  <div
    *ngIf="showSearchMember"
    class="col-sm-12 col-md-6 col-xl-4"
    style="text-align: left; padding: 0"
  >
    <div
      style="
        display: flex;
        align-items: center;
        padding: 5px;
        border-radius: 10px;
        border: 1px solid rgb(0, 0, 0, 0.2);
        width: 80%;
        background-color: #ffffff;
        padding: 5px;
      "
    >
      <span
        class="material-icons"
        style="text-align: left; font-size: 20px; opacity: 0.5"
      >
        search
      </span>
      <input
        type="search"
        placeholder="Search by Member Name"
        [(ngModel)]="searchByMName"
        (ngModelChange)="searchByNameChanged()"
        style="
          padding-left: 15px;
          width: 100%;
          background: transparent;
          border: 0;
        "
      />
    </div>
  </div>
  <div
    [class]="showSearchMember ? 'col-sm-12 col-md-6 col-xl-8' : 'col-md-12'"
    style="
      column-gap: 20px;
      display: flex;
      text-align: right;
      flex-direction: row-reverse;
      padding: 0;
      row-gap: 10px;
      flex-wrap: wrap;
    "
  >
    <button mat-raised-button [matMenuTriggerFor]="sortMenu">
      <span style="white-space: break-spaces">
        {{ sortBy === 'None' ? 'Sort by' : sortBy }}
      </span>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>

    <button
      mat-raised-button
      [matMenuTriggerFor]="filterMenu"
      *ngIf="status != 'Pending'"
    >
      <span style="white-space: break-spaces">
        {{ filterOption === 'None' ? 'Filter by' : filterOption }}
      </span>
      <mat-icon>filter_list</mat-icon>
    </button>

    <mat-menu #filterMenu="matMenu" xPosition="before" yPosition="below">
      <button mat-menu-item (click)="filterOptionChanged('None')">None</button>
      <ng-container *ngFor="let group of assessmentGroups; index as i">
        <button mat-menu-item [matMenuTriggerFor]="subMenu">
          {{ group.name }}
          <mat-menu #subMenu="matMenu" xPosition="before">
            <ng-container *ngFor="let assessment of group.assessment">
              <button
                style="font-weight: 400; font-size: 12px; width: 100%"
                mat-menu-item
                [value]="assessment.name"
                *ngIf="assessment.enabled"
                (click)="filterOptionChanged(assessment.id)"
              >
                {{ assessment.name }}
              </button>
            </ng-container>
          </mat-menu>
        </button>
      </ng-container>
    </mat-menu>

    <mat-menu #sortMenu="matMenu">
      <button
        mat-menu-item
        style="font-weight: 400; font-size: 12px; width: 100%"
        (click)="sortByChanged('None')"
        value="None"
      >
        None
      </button>
      <button
        (click)="sortByChanged('Date closest to now')"
        mat-menu-item
        style="font-weight: 400; font-size: 12px; width: 100%"
      >
        Date closest to now
      </button>
      <button
        (click)="sortByChanged('Date farthest from now')"
        mat-menu-item
        style="font-weight: 400; font-size: 12px; width: 100%"
      >
        Date farthest from now
      </button>
    </mat-menu>
  </div>
</div>

<mat-progress-bar
  mode="indeterminate"
  *ngIf="isLoadingResults || approveProcessing"
></mat-progress-bar>

<div class="row">
  <div *ngIf="data.total === 0 && !isLoadingResults">
    <mat-error> No record found </mat-error>
  </div>

  <div class="col-md-12" style="padding-top: 30px">
    <div class="mat-elevation-z8" *ngIf="data.total > 0">
      <div class="table-responsive">
        <table mat-table [dataSource]="data.items" matSort matSortDisableClear>
          <ng-container matColumnDef="createdDate">
            <th
              mat-header-cell
              *matHeaderCellDef
              style="font-weight: 900; width: 130px !important"
            >
              Date
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.createdDate | date : 'MM/dd/yyyy' : currentTimezone }}
              <br />
              {{ row.createdDate | date : 'shortTime' : currentTimezone }}
              {{ currentTimezone }}
            </td>
          </ng-container>
          <ng-container matColumnDef="completedDate">
            <th
              mat-header-cell
              *matHeaderCellDef
              style="font-weight: 900; width: 130px !important"
            >
              Reviewed On
            </th>
            <td mat-cell *matCellDef="let row">
              {{
                row.reviewCompletedOn | date : 'MM/dd/yyyy' : currentTimezone
              }}
              <br />
              {{ row.reviewCompletedOn | date : 'shortTime' : currentTimezone }}
              {{ currentTimezone }}
            </td>
          </ng-container>
          <ng-container matColumnDef="sessionId">
            <th
              mat-header-cell
              *matHeaderCellDef
              style="font-weight: 900; width: 200px !important"
            >
              Note Id
            </th>
            <td mat-cell *matCellDef="let row">
              <span
                style="cursor: pointer; color: #2196f3; font-weight: bold"
                (click)="navigateToSessionPage(row)"
              >
                {{ row?.sessionCode }}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="patientName">
            <th mat-header-cell *matHeaderCellDef style="font-weight: 900">
              Member Name
            </th>
            <td mat-cell *matCellDef="let row">
              <a [routerLink]="['/main/member', row.patientId, 'NOTES']">
                <b>
                  {{
                    row.patientLastName
                      ? row.patientLastName + ', ' + row.patientFirstName
                      : row.patientName
                  }}
                </b>
              </a>
            </td>
          </ng-container>
          <ng-container matColumnDef="assessmentType">
            <th mat-header-cell *matHeaderCellDef style="font-weight: 900">
              Assessment Type
            </th>
            <td mat-cell *matCellDef="let row">{{ row.assessmentType }}</td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef style="font-weight: 900">
              Status
            </th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="row.status === 'Pending'" class="pendingTab">
                {{ row.status }}
              </span>
              <span *ngIf="row.status === 'In Review'" class="reviewTab">
                {{ row.status }}
              </span>
              <span *ngIf="row.status === 'Completed'" class="completedTab">
                {{ row.status }}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="riskScore">
            <th mat-header-cell *matHeaderCellDef style="text-align: center">
              Risk Score
            </th>
            <td mat-cell *matCellDef="let row">
              <ng-container *ngIf="!row.props">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                  "
                >
                  <circle-progress
                    [percent]="(row.riskScore / row.maxScore) * 100"
                    [radius]="25"
                    [outerStrokeWidth]="5"
                    [innerStrokeWidth]="1"
                    [outerStrokeColor]="getCustomChartColors(row, null)"
                    [innerStrokeColor]="'#e3e3e3'"
                    [animation]="true"
                    [animationDuration]="700"
                    [title]="getTitle(row.riskScore, row.maxScore)"
                    [showSubtitle]="false"
                    [showUnits]="false"
                    [titleFontSize]="9"
                  ></circle-progress>
                </div>
              </ng-container>
              <ng-container
                *ngIf="
                  row.props &&
                  row.assessmentType === 'Shopping Addiction Assessment'
                "
              >
                <div class="fullGridContainer" style="text-align: center">
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel">Overall </span>
                    <circle-progress
                      [percent]="(row.riskScore / row.maxScore) * 100"
                      [radius]="25"
                      [outerStrokeWidth]="5"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="getCustomChartColors(row, null)"
                      [innerStrokeColor]="'#e3e3e3'"
                      [animation]="true"
                      [animationDuration]="700"
                      [title]="getTitle(row.riskScore, row.maxScore)"
                      [showSubtitle]="false"
                      [showUnits]="false"
                      [titleFontSize]="9"
                    ></circle-progress>
                  </div>

                  <mat-chip-listbox>
                    <mat-chip-option
                      class="mat-elevation-z8 activeChipClass"
                      *ngIf="row.props['Salience'] > 0"
                      (click)="
                        openDialog(
                          'chart',
                          row.assessmentType,
                          row.assessmentId,
                          row.assessmentRequestId,
                          row.patientId,
                          'Salience'
                        )
                      "
                      >Salience</mat-chip-option
                    >
                    <mat-chip-option
                      class="mat-elevation-z8 activeChipClass"
                      *ngIf="row.props['Mood Modification'] > 0"
                      (click)="
                        openDialog(
                          'chart',
                          row.assessmentType,
                          row.assessmentId,
                          row.assessmentRequestId,
                          row.patientId,
                          'Mood Modification'
                        )
                      "
                      >Mood Modification</mat-chip-option
                    >
                    <mat-chip-option
                      class="mat-elevation-z8 activeChipClass"
                      *ngIf="row.props['Conflict'] > 0"
                      (click)="
                        openDialog(
                          'chart',
                          row.assessmentType,
                          row.assessmentId,
                          row.assessmentRequestId,
                          row.patientId,
                          'Conflict'
                        )
                      "
                      >Conflict</mat-chip-option
                    >
                    <mat-chip-option
                      class="mat-elevation-z8 activeChipClass"
                      *ngIf="row.props['Tolerance'] > 0"
                      (click)="
                        openDialog(
                          'chart',
                          row.assessmentType,
                          row.assessmentId,
                          row.assessmentRequestId,
                          row.patientId,
                          'Tolerance'
                        )
                      "
                      >Tolerance</mat-chip-option
                    >
                    <mat-chip-option
                      class="mat-elevation-z8 activeChipClass"
                      *ngIf="row.props['Relapse'] > 0"
                      (click)="
                        openDialog(
                          'chart',
                          row.assessmentType,
                          row.assessmentId,
                          row.assessmentRequestId,
                          row.patientId,
                          'Relapse'
                        )
                      "
                      >Relapse</mat-chip-option
                    >

                    <mat-chip-option
                      class="mat-elevation-z8 activeChipClass"
                      *ngIf="row.props['Withdrawal'] > 0"
                      (click)="
                        openDialog(
                          'chart',
                          row.assessmentType,
                          row.assessmentId,
                          row.assessmentRequestId,
                          row.patientId,
                          'Withdrawal'
                        )
                      "
                      >Withdrawal</mat-chip-option
                    >
                    <mat-chip-option
                      class="mat-elevation-z8 activeChipClass"
                      *ngIf="row.props['Problems'] > 0"
                      (click)="
                        openDialog(
                          'chart',
                          row.assessmentType,
                          row.assessmentId,
                          row.assessmentRequestId,
                          row.patientId,
                          'Problems'
                        )
                      "
                      >Problems</mat-chip-option
                    >
                  </mat-chip-listbox>
                </div>
              </ng-container>

              <ng-container
                *ngIf="
                  row.props &&
                  row.assessmentType ===
                    'Columbia-Suicide Severity Rating Scale (CSSRS)'
                "
              >
                <div class="fullGridContainer">
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <button
                      mat-flat-button
                      color="warn"
                      *ngIf="row.riskScore >= 10"
                    >
                      {{ row.props['riskLevel'] }}
                    </button>
                    <button
                      mat-flat-button
                      color="accent"
                      *ngIf="row.riskScore >= 3 && row.riskScore < 10"
                    >
                      {{ row.props['riskLevel'] }}
                    </button>
                    <button
                      mat-flat-button
                      style="background-color: #5a9e7c"
                      *ngIf="row.riskScore < 3"
                    >
                      {{ row.props['riskLevel'] }}
                    </button>
                  </div>
                </div>
              </ng-container>

              <ng-container
                *ngIf="row.props && row.assessmentType === 'TAPS-I & TAPS-II'"
              >
                <div class="fourGridContainer">
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <span class="scoreLabel"> Tobacco Risk </span>
                    <button
                      *ngIf="row.props['tobacco'] === 0"
                      mat-raised-button
                      class="btn btn-just-icon btn-fab btn-round"
                      style="
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        background-color: #5a9e7c;
                      "
                    ></button>
                    <button
                      *ngIf="row.props['tobacco'] === 1"
                      mat-raised-button
                      class="btn btn-just-icon btn-fab btn-round"
                      style="
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        background-color: #ffcd56;
                      "
                    ></button>
                    <button
                      *ngIf="row.props['tobacco'] === 2"
                      mat-raised-button
                      class="btn btn-just-icon btn-fab btn-round"
                      style="
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        background-color: #ff8d33;
                      "
                    ></button>
                    <button
                      *ngIf="row.props['tobacco'] === 3"
                      mat-raised-button
                      class="btn btn-just-icon btn-fab btn-round"
                      style="
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        background-color: #ff6384;
                      "
                    ></button>
                  </div>

                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <span class="scoreLabel"> Alcohol Risk </span>
                    <button
                      *ngIf="row.props['alcohol'] === 0"
                      mat-raised-button
                      class="btn btn-just-icon btn-fab btn-round"
                      style="
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        background-color: #5a9e7c;
                      "
                    ></button>
                    <button
                      *ngIf="row.props['alcohol'] === 1"
                      mat-raised-button
                      class="btn btn-just-icon btn-fab btn-round"
                      style="
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        background-color: #ffcd56;
                      "
                    ></button>
                    <button
                      *ngIf="row.props['alcohol'] === 2"
                      mat-raised-button
                      class="btn btn-just-icon btn-fab btn-round"
                      style="
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        background-color: #ff8d33;
                      "
                    ></button>
                    <button
                      *ngIf="row.props['alcohol'] === 3"
                      mat-raised-button
                      class="btn btn-just-icon btn-fab btn-round"
                      style="
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        background-color: #ff6384;
                      "
                    ></button>
                  </div>

                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <span class="scoreLabel"> Sedatives Risk </span>
                    <button
                      *ngIf="row.props['sedative'] === 0"
                      mat-raised-button
                      class="btn btn-just-icon btn-fab btn-round"
                      style="
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        background-color: #5a9e7c;
                      "
                    ></button>
                    <button
                      *ngIf="row.props['sedative'] === 1"
                      mat-raised-button
                      class="btn btn-just-icon btn-fab btn-round"
                      style="
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        background-color: #ffcd56;
                      "
                    ></button>
                    <button
                      *ngIf="row.props['sedative'] === 2"
                      mat-raised-button
                      class="btn btn-just-icon btn-fab btn-round"
                      style="
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        background-color: #ff8d33;
                      "
                    ></button>
                    <button
                      *ngIf="row.props['sedative'] === 3"
                      mat-raised-button
                      class="btn btn-just-icon btn-fab btn-round"
                      style="
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        background-color: #ff6384;
                      "
                    ></button>
                  </div>

                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <span class="scoreLabel"> Cannabis Risk </span>
                    <button
                      *ngIf="row.props['cannabis'] === 0"
                      mat-raised-button
                      class="btn btn-just-icon btn-fab btn-round"
                      style="
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        background-color: #5a9e7c;
                      "
                    ></button>
                    <button
                      *ngIf="row.props['cannabis'] === 1"
                      mat-raised-button
                      class="btn btn-just-icon btn-fab btn-round"
                      style="
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        background-color: #ffcd56;
                      "
                    ></button>
                    <button
                      *ngIf="row.props['cannabis'] === 2"
                      mat-raised-button
                      class="btn btn-just-icon btn-fab btn-round"
                      style="
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        background-color: #ff8d33;
                      "
                    ></button>
                    <button
                      *ngIf="row.props['cannabis'] === 3"
                      mat-raised-button
                      class="btn btn-just-icon btn-fab btn-round"
                      style="
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        background-color: #ff6384;
                      "
                    ></button>
                  </div>

                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <span class="scoreLabel"> Stimulant Risk </span>
                    <button
                      *ngIf="row.props['stimulant'] === 0"
                      mat-raised-button
                      class="btn btn-just-icon btn-fab btn-round"
                      style="
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        background-color: #5a9e7c;
                      "
                    ></button>
                    <button
                      *ngIf="row.props['stimulant'] === 1"
                      mat-raised-button
                      class="btn btn-just-icon btn-fab btn-round"
                      style="
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        background-color: #ffcd56;
                      "
                    ></button>
                    <button
                      *ngIf="row.props['stimulant'] === 2"
                      mat-raised-button
                      class="btn btn-just-icon btn-fab btn-round"
                      style="
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        background-color: #ff8d33;
                      "
                    ></button>
                    <button
                      *ngIf="row.props['stimulant'] === 3"
                      mat-raised-button
                      class="btn btn-just-icon btn-fab btn-round"
                      style="
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        background-color: #ff6384;
                      "
                    ></button>
                  </div>

                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <span class="scoreLabel"> Opioids Risk </span>
                    <button
                      *ngIf="row.props['opioid'] === 0"
                      mat-raised-button
                      class="btn btn-just-icon btn-fab btn-round"
                      style="
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        background-color: #5a9e7c;
                      "
                    ></button>
                    <button
                      *ngIf="row.props['opioid'] === 1"
                      mat-raised-button
                      class="btn btn-just-icon btn-fab btn-round"
                      style="
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        background-color: #ffcd56;
                      "
                    ></button>
                    <button
                      *ngIf="row.props['opioid'] === 2"
                      mat-raised-button
                      class="btn btn-just-icon btn-fab btn-round"
                      style="
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        background-color: #ff8d33;
                      "
                    ></button>
                    <button
                      *ngIf="row.props['opioid'] === 3"
                      mat-raised-button
                      class="btn btn-just-icon btn-fab btn-round"
                      style="
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        background-color: #ff6384;
                      "
                    ></button>
                  </div>

                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <span class="scoreLabel"> Other Substance Risk </span>
                    <button
                      *ngIf="row.props['other'] === 0"
                      mat-raised-button
                      class="btn btn-just-icon btn-fab btn-round"
                      style="
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        background-color: #5a9e7c;
                      "
                    ></button>
                    <button
                      *ngIf="row.props['other'] === 1"
                      mat-raised-button
                      class="btn btn-just-icon btn-fab btn-round"
                      style="
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        background-color: #ffcd56;
                      "
                    ></button>
                    <button
                      *ngIf="row.props['other'] === 2"
                      mat-raised-button
                      class="btn btn-just-icon btn-fab btn-round"
                      style="
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        background-color: #ff8d33;
                      "
                    ></button>
                    <button
                      *ngIf="row.props['other'] === 3"
                      mat-raised-button
                      class="btn btn-just-icon btn-fab btn-round"
                      style="
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        background-color: #ff6384;
                      "
                    ></button>
                  </div>
                </div>
              </ng-container>

              <ng-container
                *ngIf="
                  row.props &&
                  row.assessmentType ===
                    'Differentiation of Self Inventory - Short Form'
                "
              >
                <div class="threeGridContainer">
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Average Score </span>
                    <circle-progress
                      [percent]="row.props['averageOverallScore'] * 100"
                      [radius]="30"
                      [outerStrokeWidth]="2"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="
                        getCustomChartColors(row, 'averageOverallScore')
                      "
                      [innerStrokeColor]="'#e3e3e3'"
                      [showSubtitle]="false"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="9"
                      [title]="getTitle(row.props['averageOverallScore'], 100)"
                    ></circle-progress>
                  </div>

                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> I Position </span>
                    <circle-progress
                      [percent]="row.props['averageIp'] * 100"
                      [radius]="30"
                      [outerStrokeWidth]="2"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="
                        getCustomChartColors(row, 'averageIp')
                      "
                      [innerStrokeColor]="'#e3e3e3'"
                      [showSubtitle]="false"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="9"
                      [title]="getTitle(row.props['averageIp'], 6)"
                    ></circle-progress>
                  </div>

                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Emotional Reactivity </span>
                    <circle-progress
                      [percent]="row.props['averageEr'] * 100"
                      [radius]="30"
                      [outerStrokeWidth]="2"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="
                        getCustomChartColors(row, 'averageEr')
                      "
                      [innerStrokeColor]="'#e3e3e3'"
                      [showSubtitle]="false"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="9"
                      [subtitleFontSize]="6"
                      [title]="getTitle(row.props['averageEr'], 6)"
                    ></circle-progress>
                  </div>

                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Fusion with Others </span>
                    <circle-progress
                      [percent]="row.props['averageFo'] * 100"
                      [radius]="30"
                      [outerStrokeWidth]="2"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="
                        getCustomChartColors(row, 'averageFo')
                      "
                      [innerStrokeColor]="'#e3e3e3'"
                      [showSubtitle]="false"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="9"
                      [subtitleFontSize]="6"
                      [title]="getTitle(row.props['averageFo'], 6)"
                    ></circle-progress>
                  </div>

                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Emotional Cutoff </span>
                    <circle-progress
                      [percent]="row.props['averageEc'] * 100"
                      [radius]="30"
                      [outerStrokeWidth]="2"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="
                        getCustomChartColors(row, 'averageEc')
                      "
                      [innerStrokeColor]="'#e3e3e3'"
                      [showSubtitle]="false"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="9"
                      [subtitleFontSize]="6"
                      [title]="getTitle(row.props['averageEc'], 6)"
                    ></circle-progress>
                  </div>
                </div>
              </ng-container>

              <ng-container
                *ngIf="
                  row.props &&
                  row.assessmentType === 'Adult ADHD Self-Report Scale'
                "
              >
                <div class="twoGridContainer">
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Part A </span>
                    <circle-progress
                      [percent]="(row.props['partA'] / 6) * 100"
                      [radius]="25"
                      [outerStrokeWidth]="5"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="getCustomChartColors(row, 'partA')"
                      [innerStrokeColor]="'#e3e3e3'"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="9"
                      [subtitleFontSize]="7"
                      [title]="getTitle(row.props['partA'], 6)"
                      [showSubtitle]="false"
                    ></circle-progress>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Part B </span>
                    <circle-progress
                      [percent]="(row.props['partB'] / 12) * 100"
                      [radius]="25"
                      [outerStrokeWidth]="5"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="getCustomChartColors(row, 'partB')"
                      [innerStrokeColor]="'#e3e3e3'"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="9"
                      [subtitleFontSize]="7"
                      [title]="getTitle(row.props['partB'], 12)"
                      [showSubtitle]="false"
                    ></circle-progress>
                  </div>
                </div>
              </ng-container>
              <ng-container
                *ngIf="
                  row.props &&
                  row.assessmentType ===
                    'Vanderbilt ADHD Diagnostic Rating Scale (VADRS)'
                "
              >
                <div class="fullGridContainer" style="text-align: center">
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel">Overall </span>
                    <circle-progress
                      [percent]="(row.riskScore / row.maxScore) * 100"
                      [radius]="28"
                      [outerStrokeWidth]="5"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="getCustomChartColors(row, null)"
                      [innerStrokeColor]="'#e3e3e3'"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [showSubtitle]="false"
                      [titleFontSize]="9"
                      [subtitleFontSize]="7"
                      [title]="getTitle(row.riskScore, row.maxScore)"
                    ></circle-progress>
                  </div>

                  <mat-chip-listbox>
                    <mat-chip-option
                      class="mat-elevation-z8 activeChipClass"
                      *ngIf="
                        row.props['inattentive'] >= 6 &&
                        row.props['performance'] >= 1
                      "
                      (click)="
                        openDialog(
                          'chart',
                          row.assessmentType,
                          row.assessmentId,
                          row.assessmentRequestId,
                          row.patientId,
                          'ADHD inattentive type'
                        )
                      "
                      >ADHD inattentive type</mat-chip-option
                    >
                    <mat-chip-option
                      class="mat-elevation-z8 activeChipClass"
                      *ngIf="
                        row.props['inattentive'] >= 6 &&
                        row.props['performance'] >= 1
                      "
                      (click)="
                        openDialog(
                          'chart',
                          row.assessmentType,
                          row.assessmentId,
                          row.assessmentRequestId,
                          row.patientId,
                          'ADHD hyperactive/impulsive type'
                        )
                      "
                      >ADHD hyperactive/impulsive type</mat-chip-option
                    >
                    <mat-chip-option
                      class="mat-elevation-z8 activeChipClass"
                      *ngIf="
                        row.props['inattentive'] >= 6 &&
                        row.props['inattentive'] >= 6 &&
                        row.props['performance'] >= 1
                      "
                      (click)="
                        openDialog(
                          'chart',
                          row.assessmentType,
                          row.assessmentId,
                          row.assessmentRequestId,
                          row.patientId,
                          'Combined Inattention/Hyperactivity'
                        )
                      "
                      >Combined Inattention/Hyperactivity</mat-chip-option
                    >
                    <mat-chip-option
                      class="mat-elevation-z8 activeChipClass"
                      *ngIf="
                        row.props['defiant'] >= 4 &&
                        row.props['performance'] >= 1
                      "
                      (click)="
                        openDialog(
                          'chart',
                          row.assessmentType,
                          row.assessmentId,
                          row.assessmentRequestId,
                          row.patientId,
                          'Oppositional defiant disorder (ODD)'
                        )
                      "
                      >Oppositional defiant disorder (ODD)</mat-chip-option
                    >
                    <mat-chip-option
                      class="mat-elevation-z8 activeChipClass"
                      *ngIf="
                        row.props['conduct'] >= 3 &&
                        row.props['performance'] >= 1
                      "
                      (click)="
                        openDialog(
                          'chart',
                          row.assessmentType,
                          row.assessmentId,
                          row.assessmentRequestId,
                          row.patientId,
                          'Conduct Disorder'
                        )
                      "
                      >Conduct Disorder</mat-chip-option
                    >
                    <mat-chip-option
                      class="mat-elevation-z8 activeChipClass"
                      *ngIf="
                        row.props['depression'] >= 3 &&
                        row.props['performance'] >= 1
                      "
                      (click)="
                        openDialog(
                          'chart',
                          row.assessmentType,
                          row.assessmentId,
                          row.assessmentRequestId,
                          row.patientId,
                          'Anxiety/Depression'
                        )
                      "
                      >Anxiety/Depression</mat-chip-option
                    >
                  </mat-chip-listbox>
                </div>
              </ng-container>
              <ng-container
                *ngIf="
                  row.props && row.assessmentType === 'Big 5 Personality Test'
                "
              >
                <div class="threeGridContainer">
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Agreeableness </span>
                    <circle-progress
                      [percent]="
                        (row.props['a-score'] / row.props['a-max']) * 100
                      "
                      [radius]="35"
                      [outerStrokeWidth]="5"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="getCustomChartColors(row, 'a-max')"
                      [innerStrokeColor]="'#e3e3e3'"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [showSubtitle]="false"
                      [titleFontSize]="9"
                      [subtitleFontSize]="7"
                      [title]="
                        getTitle(row.props['a-score'], row.props['a-max'])
                      "
                    ></circle-progress>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Extroversion </span>
                    <circle-progress
                      [percent]="
                        (row.props['e-score'] / row.props['e-max']) * 100
                      "
                      [radius]="35"
                      [outerStrokeWidth]="5"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="getCustomChartColors(row, 'e-max')"
                      [innerStrokeColor]="'#e3e3e3'"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [showSubtitle]="false"
                      [titleFontSize]="9"
                      [subtitleFontSize]="7"
                      [title]="
                        getTitle(row.props['e-score'], row.props['e-max'])
                      "
                    ></circle-progress>
                  </div>

                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Conscientiousness </span>
                    <circle-progress
                      [percent]="
                        (row.props['c-score'] / row.props['c-max']) * 100
                      "
                      [radius]="35"
                      [outerStrokeWidth]="5"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="getCustomChartColors(row, 'c-max')"
                      [innerStrokeColor]="'#e3e3e3'"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [showSubtitle]="false"
                      [titleFontSize]="9"
                      [subtitleFontSize]="7"
                      [title]="
                        getTitle(row.props['c-score'], row.props['c-max'])
                      "
                    ></circle-progress>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Neuroticism </span>
                    <circle-progress
                      [percent]="
                        (row.props['n-score'] / row.props['n-max']) * 100
                      "
                      [radius]="35"
                      [outerStrokeWidth]="5"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="getCustomChartColors(row, 'n-max')"
                      [innerStrokeColor]="'#e3e3e3'"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [showSubtitle]="false"
                      [titleFontSize]="9"
                      [subtitleFontSize]="7"
                      [title]="
                        getTitle(row.props['n-score'], row.props['n-max'])
                      "
                    ></circle-progress>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Openness </span>
                    <circle-progress
                      [percent]="
                        (row.props['o-score'] / row.props['o-max']) * 100
                      "
                      [radius]="35"
                      [outerStrokeWidth]="5"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="getCustomChartColors(row, 'o-max')"
                      [innerStrokeColor]="'#e3e3e3'"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [showSubtitle]="false"
                      [titleFontSize]="9"
                      [subtitleFontSize]="7"
                      [title]="
                        getTitle(row.props['o-score'], row.props['o-max'])
                      "
                    ></circle-progress>
                  </div>
                </div>
              </ng-container>
              <ng-container
                *ngIf="
                  row.props &&
                  row.assessmentType === 'Buss Perry Aggression Questionnaire'
                "
              >
                <div class="twoGridContainer">
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Physical Aggression </span>
                    <circle-progress
                      [percent]="
                        (row.props['pa-score'] / row.props['pa-max']) * 100
                      "
                      [radius]="20"
                      [outerStrokeWidth]="2"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="getCustomChartColors(row, 'pa-score')"
                      [innerStrokeColor]="'#e3e3e3'"
                      [showSubtitle]="false"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="5"
                      [title]="
                        getTitle(row.props['pa-score'], row.props['pa-max'])
                      "
                    ></circle-progress>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Verbal Aggression </span>

                    <circle-progress
                      [percent]="
                        (row.props['va-score'] / row.props['va-max']) * 100
                      "
                      [radius]="20"
                      [outerStrokeWidth]="2"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="getCustomChartColors(row, 'va-score')"
                      [innerStrokeColor]="'#e3e3e3'"
                      [showSubtitle]="false"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="5"
                      [title]="
                        getTitle(row.props['va-score'], row.props['va-max'])
                      "
                    ></circle-progress>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Hostility </span>
                    <circle-progress
                      [percent]="
                        (row.props['h-score'] / row.props['h-max']) * 100
                      "
                      [radius]="20"
                      [outerStrokeWidth]="2"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="getCustomChartColors(row, 'h-score')"
                      [innerStrokeColor]="'#e3e3e3'"
                      [showSubtitle]="false"
                      [animation]="true"
                      [animationDuration]="700"
                      [titleFontSize]="5"
                      [showUnits]="false"
                      [title]="
                        getTitle(row.props['h-score'], row.props['h-max'])
                      "
                    ></circle-progress>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Anger </span>

                    <circle-progress
                      [percent]="
                        (row.props['a-score'] / row.props['a-max']) * 100
                      "
                      [radius]="20"
                      [outerStrokeWidth]="2"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="getCustomChartColors(row, 'a-score')"
                      [innerStrokeColor]="'#e3e3e3'"
                      [showSubtitle]="false"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="5"
                      [title]="
                        getTitle(row.props['a-score'], row.props['a-max'])
                      "
                    ></circle-progress>
                  </div>
                </div>
              </ng-container>
              <ng-container
                *ngIf="
                  row.props &&
                  row.assessmentType === 'Short Health Anxiety Inventory'
                "
              >
                <div class="threeGridContainer">
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Total Score </span>
                    <circle-progress
                      [percent]="(row.riskScore / row.maxScore) * 100"
                      [radius]="20"
                      [outerStrokeWidth]="2"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="
                        getCustomChartColors(row, 'riskScore')
                      "
                      [innerStrokeColor]="'#e3e3e3'"
                      [showSubtitle]="false"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="5"
                      [title]="getTitle(row.riskScore, row.maxScore)"
                    ></circle-progress>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Primary Score </span>
                    <circle-progress
                      [percent]="(row.props['primary'] / 42) * 100"
                      [radius]="20"
                      [outerStrokeWidth]="2"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="getCustomChartColors(row, 'primary')"
                      [innerStrokeColor]="'#e3e3e3'"
                      [showSubtitle]="false"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="5"
                      [title]="getTitle(row.props['primary'], 42)"
                    ></circle-progress>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel">
                      Negative Consequences Score
                    </span>
                    <circle-progress
                      [percent]="(row.props['negative'] / 12) * 100"
                      [radius]="20"
                      [outerStrokeWidth]="2"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="getCustomChartColors(row, 'negative')"
                      [innerStrokeColor]="'#e3e3e3'"
                      [showSubtitle]="false"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="5"
                      [title]="getTitle(row.props['negative'], 12)"
                    ></circle-progress>
                  </div>
                </div>
              </ng-container>
              <ng-container
                *ngIf="
                  row.props && row.assessmentType === 'Infant-Toddler Checklist'
                "
              >
                <div class="twoGridContainer">
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Total Score </span>
                    <circle-progress
                      [percent]="(row.riskScore / row.maxScore) * 100"
                      [radius]="20"
                      [outerStrokeWidth]="2"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="
                        getCustomChartColors(row, 'riskScore')
                      "
                      [innerStrokeColor]="
                        getCustomChartColors(row, 'riskScore')
                      "
                      [showSubtitle]="false"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="6"
                      [title]="getTitle(row.riskScore, row.maxScore)"
                    ></circle-progress>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Communication Composite: </span>
                    <circle-progress
                      [percent]="(row.props['communication'] / 26) * 100"
                      [radius]="20"
                      [outerStrokeWidth]="2"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="
                        getCustomChartColors(row, 'communication')
                      "
                      [innerStrokeColor]="
                        getCustomChartColors(row, 'communication')
                      "
                      [showSubtitle]="false"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="6"
                      [title]="getTitle(row.props['communication'], 26)"
                    ></circle-progress>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel">
                      Expressive Speech Composite:
                    </span>
                    <circle-progress
                      [percent]="(row.props['speech'] / 14) * 100"
                      [radius]="20"
                      [outerStrokeWidth]="2"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="getCustomChartColors(row, 'speech')"
                      [innerStrokeColor]="getCustomChartColors(row, 'speech')"
                      [showSubtitle]="false"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="6"
                      [title]="getTitle(row.props['speech'], 14)"
                    ></circle-progress>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Symbolic Composite: </span>
                    <circle-progress
                      [percent]="(row.props['symbolic'] / 17) * 100"
                      [radius]="20"
                      [outerStrokeWidth]="2"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="getCustomChartColors(row, 'symbolic')"
                      [innerStrokeColor]="getCustomChartColors(row, 'symbolic')"
                      [showSubtitle]="false"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="6"
                      [title]="getTitle(row.props['symbolic'], 17)"
                    ></circle-progress>
                  </div>
                </div>
              </ng-container>

              <ng-container
                *ngIf="
                  row.props &&
                  (row.assessmentType === 'DSM 5 Level 1 Adult Assessment' ||
                    row.assessmentType === 'DSM 5 Level 1 (11-17) Assessment' ||
                    row.assessmentType === 'DSM 5 Level 1 (6-17) Assessment')
                "
              >
                <div class="fullGridContainer" style="text-align: center">
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel">Overall </span>
                    <circle-progress
                      [percent]="(row.riskScore / row.maxScore) * 100"
                      [radius]="28"
                      [outerStrokeWidth]="5"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="
                        getCustomChartColors(row, row.assessmentType)
                      "
                      [innerStrokeColor]="'#e3e3e3'"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [showSubtitle]="false"
                      [titleFontSize]="9"
                      [subtitleFontSize]="7"
                      [title]="getTitle(row.riskScore, row.maxScore)"
                    ></circle-progress>
                  </div>

                  <mat-chip-listbox>
                    <mat-chip-option
                      class="mat-elevation-z8 activeChipClass"
                      *ngIf="row.props['depression'] >= 2"
                      (click)="
                        openDialog(
                          'chart',
                          row.assessmentType,
                          row.assessmentId,
                          row.assessmentRequestId,
                          row.patientId,
                          'Depression'
                        )
                      "
                      >Depression</mat-chip-option
                    >
                    <mat-chip-option
                      class="mat-elevation-z8 activeChipClass"
                      *ngIf="row.props['anger'] >= 2"
                      (click)="
                        openDialog(
                          'chart',
                          row.assessmentType,
                          row.assessmentId,
                          row.assessmentRequestId,
                          row.patientId,
                          'Anger'
                        )
                      "
                      >{{
                        row.assessmentType === 'DSM 5 Level 1 Adult Assessment'
                          ? 'Anger'
                          : 'Anger / Irritability'
                      }}</mat-chip-option
                    >
                    <mat-chip-option
                      class="mat-elevation-z8 activeChipClass"
                      *ngIf="row.props['anxiety'] >= 2"
                      (click)="
                        openDialog(
                          'chart',
                          row.assessmentType,
                          row.assessmentId,
                          row.assessmentRequestId,
                          row.patientId,
                          'Anxiety'
                        )
                      "
                      >Anxiety</mat-chip-option
                    >
                    <mat-chip-option
                      class="mat-elevation-z8 activeChipClass"
                      *ngIf="row.props['inattention'] >= 1"
                      (click)="
                        openDialog(
                          'chart',
                          row.assessmentType,
                          row.assessmentId,
                          row.assessmentRequestId,
                          row.patientId,
                          'Inattention'
                        )
                      "
                      >Inattention</mat-chip-option
                    >
                    <mat-chip-option
                      class="mat-elevation-z8 activeChipClass"
                      *ngIf="row.props['mania'] >= 2"
                      (click)="
                        openDialog(
                          'chart',
                          row.assessmentType,
                          row.assessmentId,
                          row.assessmentRequestId,
                          row.patientId,
                          'Mania'
                        )
                      "
                      >Mania</mat-chip-option
                    >

                    <mat-chip-option
                      class="mat-elevation-z8 activeChipClass"
                      *ngIf="row.props['somatic'] >= 2"
                      (click)="
                        openDialog(
                          'chart',
                          row.assessmentType,
                          row.assessmentId,
                          row.assessmentRequestId,
                          row.patientId,
                          'Somatic Symptoms'
                        )
                      "
                      >Somatic Symptoms</mat-chip-option
                    >
                    <mat-chip-option
                      class="mat-elevation-z8 activeChipClass"
                      *ngIf="row.props['suicidal'] >= 1"
                      (click)="
                        openDialog(
                          'chart',
                          row.assessmentType,
                          row.assessmentId,
                          row.assessmentRequestId,
                          row.patientId,
                          'Suicidal Ideation'
                        )
                      "
                      >Suicidal Ideation</mat-chip-option
                    >
                    <mat-chip-option
                      class="mat-elevation-z8 activeChipClass"
                      *ngIf="row.props['psychosis'] >= 1"
                      (click)="
                        openDialog(
                          'chart',
                          row.assessmentType,
                          row.assessmentId,
                          row.assessmentRequestId,
                          row.patientId,
                          'Psychosis'
                        )
                      "
                      >Psychosis</mat-chip-option
                    >
                    <mat-chip-option
                      class="mat-elevation-z8 activeChipClass"
                      *ngIf="row.props['sleep'] >= 2"
                      (click)="
                        openDialog(
                          'chart',
                          row.assessmentType,
                          row.assessmentId,
                          row.assessmentRequestId,
                          row.patientId,
                          'Sleep Problems'
                        )
                      "
                      >Sleep Problems</mat-chip-option
                    >
                    <mat-chip-option
                      class="mat-elevation-z8 activeChipClass"
                      *ngIf="row.props['memory'] >= 2"
                      (click)="
                        openDialog(
                          'chart',
                          row.assessmentType,
                          row.assessmentId,
                          row.assessmentRequestId,
                          row.patientId,
                          'Memory'
                        )
                      "
                      >Memory</mat-chip-option
                    >
                    <mat-chip-option
                      class="mat-elevation-z8 activeChipClass"
                      *ngIf="row.props['repetetiveThoughtsAndBehaviors'] >= 2"
                      (click)="
                        openDialog(
                          'chart',
                          row.assessmentType,
                          row.assessmentId,
                          row.assessmentRequestId,
                          row.patientId,
                          'Repetitive Thoughts and Behaviors'
                        )
                      "
                      >Repetitive Thoughts and Behaviors</mat-chip-option
                    >
                    <mat-chip-option
                      class="mat-elevation-z8 activeChipClass"
                      *ngIf="row.props['dissociation'] >= 2"
                      (click)="
                        openDialog(
                          'chart',
                          row.assessmentType,
                          row.assessmentId,
                          row.assessmentRequestId,
                          row.patientId,
                          'Dissociation'
                        )
                      "
                      >Dissociation</mat-chip-option
                    >
                    <mat-chip-option
                      class="mat-elevation-z8 activeChipClass"
                      *ngIf="row.props['personality'] >= 2"
                      (click)="
                        openDialog(
                          'chart',
                          row.assessmentType,
                          row.assessmentId,
                          row.assessmentRequestId,
                          row.patientId,
                          'Personality Functioning'
                        )
                      "
                      >Personality Functioning</mat-chip-option
                    >
                    <mat-chip-option
                      class="mat-elevation-z8 activeChipClass"
                      *ngIf="row.props['substanceUse'] >= 1"
                      (click)="
                        openDialog(
                          'chart',
                          row.assessmentType,
                          row.assessmentId,
                          row.assessmentRequestId,
                          row.patientId,
                          'Substance Use'
                        )
                      "
                      >Substance Use</mat-chip-option
                    >
                  </mat-chip-listbox>
                </div>
              </ng-container>

              <ng-container
                *ngIf="
                  row.props &&
                  row.assessmentType === 'Adult Attachment Scale (AAS)'
                "
              >
                <div class="threeGridContainer">
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Close </span>
                    <circle-progress
                      [percent]="row.props['close'] * 100"
                      [radius]="20"
                      [outerStrokeWidth]="2"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="getCustomChartColors(row, 'close')"
                      [innerStrokeColor]="'#e3e3e3'"
                      [showSubtitle]="false"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="6"
                      [subtitleFontSize]="6"
                      [title]="getTitle(row.props['close'], 5)"
                    ></circle-progress>
                  </div>

                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Depend </span>
                    <circle-progress
                      [percent]="row.props['depend'] * 100"
                      [radius]="20"
                      [outerStrokeWidth]="2"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="getCustomChartColors(row, 'depend')"
                      [innerStrokeColor]="'#e3e3e3'"
                      [showSubtitle]="false"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="6"
                      [subtitleFontSize]="6"
                      [title]="getTitle(row.props['depend'], 5)"
                    ></circle-progress>
                  </div>

                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Anxiety </span>
                    <circle-progress
                      [percent]="row.props['anxiety'] * 100"
                      [radius]="20"
                      [outerStrokeWidth]="2"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="getCustomChartColors(row, 'anxiety')"
                      [innerStrokeColor]="'#e3e3e3'"
                      [showSubtitle]="false"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="6"
                      [subtitleFontSize]="6"
                      [title]="getTitle(row.props['anxiety'], 5)"
                    ></circle-progress>
                  </div>
                </div>
              </ng-container>

              <ng-container
                *ngIf="
                  row.props &&
                  row.assessmentType ===
                    'Child-Parent Relationship Scale (CPRS)'
                "
              >
                <div class="threeGridContainer">
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Closeness </span>
                    <circle-progress
                      [percent]="(row.props['closeness'] / 50) * 100"
                      [radius]="20"
                      [outerStrokeWidth]="2"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="
                        getCustomChartColors(row, 'closeness')
                      "
                      [innerStrokeColor]="'#e3e3e3'"
                      [showSubtitle]="false"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="6"
                      [subtitleFontSize]="6"
                      [title]="getTitle(row.props['closeness'], 50)"
                    ></circle-progress>
                  </div>

                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Conflict </span>
                    <circle-progress
                      [percent]="(row.props['conflict'] / 60) * 100"
                      [radius]="20"
                      [outerStrokeWidth]="2"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="getCustomChartColors(row, 'conflict')"
                      [innerStrokeColor]="'#e3e3e3'"
                      [showSubtitle]="false"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="6"
                      [subtitleFontSize]="6"
                      [title]="getTitle(row.props['conflict'], 60)"
                    ></circle-progress>
                  </div>

                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Dependence </span>
                    <circle-progress
                      [percent]="(row.props['dependence'] / 20) * 100"
                      [radius]="20"
                      [outerStrokeWidth]="2"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="
                        getCustomChartColors(row, 'dependence')
                      "
                      [innerStrokeColor]="'#e3e3e3'"
                      [showSubtitle]="false"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="6"
                      [subtitleFontSize]="6"
                      [title]="getTitle(row.props['dependence'], 20)"
                    ></circle-progress>
                  </div>
                </div>
              </ng-container>

              <ng-container
                *ngIf="row.assessmentType === 'Trauma-Related Shame Assessment'"
              >
                <div class="threeGridContainer">
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Total Score </span>
                    <circle-progress
                      [percent]="(row.riskScore / row.maxScore) * 100"
                      [radius]="20"
                      [outerStrokeWidth]="2"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="
                        getCustomChartColors(row, 'riskScore')
                      "
                      [innerStrokeColor]="'#e3e3e3'"
                      [showSubtitle]="false"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="6"
                      [title]="getTitle(row.riskScore, row.maxScore)"
                    ></circle-progress>
                  </div>

                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Internal Shame: </span>
                    <circle-progress
                      [percent]="(row.props['internal'] / row.riskScore) * 100"
                      [radius]="20"
                      [outerStrokeWidth]="2"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="getCustomChartColors(row, 'internal')"
                      [innerStrokeColor]="'#e3e3e3'"
                      [showSubtitle]="true"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="6"
                      [subtitleFontSize]="6"
                      [title]="getTitle(row.props['internal'], row.riskScore)"
                      [subtitle]="
                        getSubTitle(
                          math.round(
                            (row.props['internal'] / row.riskScore) * 100
                          )
                        )
                      "
                    ></circle-progress>
                  </div>

                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> External Guilt: </span>
                    <circle-progress
                      [percent]="(row.props['external'] / row.riskScore) * 100"
                      [radius]="20"
                      [outerStrokeWidth]="2"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="getCustomChartColors(row, 'external')"
                      [innerStrokeColor]="'#e3e3e3'"
                      [showSubtitle]="true"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="6"
                      [subtitleFontSize]="6"
                      [title]="getTitle(row.props['external'], row.riskScore)"
                      [subtitle]="
                        getSubTitle(
                          math.round(
                            (row.props['external'] / row.riskScore) * 100
                          )
                        )
                      "
                    ></circle-progress>
                  </div>
                </div>
              </ng-container>
              <ng-container
                *ngIf="
                  row.props &&
                  row.assessmentType ===
                    'Revised Mystical Experience Questionnaire'
                "
              >
                <div class="twoGridContainer">
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Mystical </span>
                    <circle-progress
                      [percent]="(row.props['mystical'] / 5) * 100"
                      [radius]="25"
                      [outerStrokeWidth]="5"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="getCustomChartColors(row, 'mystical')"
                      [innerStrokeColor]="'#e3e3e3'"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="9"
                      [subtitleFontSize]="7"
                      [title]="getTitle(row.props['mystical'], 5)"
                      [showSubtitle]="false"
                    ></circle-progress>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Mood </span>
                    <circle-progress
                      [percent]="(row.props['mood'] / 5) * 100"
                      [radius]="25"
                      [outerStrokeWidth]="5"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="getCustomChartColors(row, 'mood')"
                      [innerStrokeColor]="'#e3e3e3'"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="9"
                      [subtitleFontSize]="7"
                      [title]="getTitle(row.props['mood'], 5)"
                      [showSubtitle]="false"
                    ></circle-progress>
                  </div>
                </div>
                <div class="twoGridContainer">
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Space / Time </span>
                    <circle-progress
                      [percent]="(row.props['spacetime'] / 5) * 100"
                      [radius]="25"
                      [outerStrokeWidth]="5"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="
                        getCustomChartColors(row, 'spacetime')
                      "
                      [innerStrokeColor]="'#e3e3e3'"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="9"
                      [subtitleFontSize]="7"
                      [title]="getTitle(row.props['spacetime'], 5)"
                      [showSubtitle]="false"
                    ></circle-progress>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Ineffability </span>
                    <circle-progress
                      [percent]="(row.props['ineffability'] / 5) * 100"
                      [radius]="25"
                      [outerStrokeWidth]="5"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="
                        getCustomChartColors(row, 'ineffability')
                      "
                      [innerStrokeColor]="'#e3e3e3'"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="9"
                      [subtitleFontSize]="7"
                      [title]="getTitle(row.props['ineffability'], 5)"
                      [showSubtitle]="false"
                    ></circle-progress>
                  </div>
                </div>
              </ng-container>

              <ng-container
                *ngIf="row.assessmentType === 'Session Rating Scale (SRS)'"
              >
                <div class="twoGridContainer">
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Relationship </span>
                    <circle-progress
                      [percent]="(row.props['relationshipRating'] / 10) * 100"
                      [radius]="20"
                      [outerStrokeWidth]="2"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="
                        getCustomChartColors(row, 'relationshipRating')
                      "
                      [innerStrokeColor]="'#e3e3e3'"
                      [showSubtitle]="false"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="6"
                      [title]="getTitle(row.props['relationshipRating'], 10)"
                    ></circle-progress>
                  </div>

                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Goals and Topics </span>
                    <circle-progress
                      [percent]="(row.props['goalsAndTopicsRating'] / 10) * 100"
                      [radius]="20"
                      [outerStrokeWidth]="2"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="
                        getCustomChartColors(row, 'goalsAndTopicsRating')
                      "
                      [innerStrokeColor]="'#e3e3e3'"
                      [showSubtitle]="false"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="6"
                      [subtitleFontSize]="6"
                      [title]="getTitle(row.props['goalsAndTopicsRating'], 10)"
                    ></circle-progress>
                  </div>

                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Approach or Method </span>
                    <circle-progress
                      [percent]="(row.props['approachRating'] / 10) * 100"
                      [radius]="20"
                      [outerStrokeWidth]="2"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="
                        getCustomChartColors(row, 'approachRating')
                      "
                      [innerStrokeColor]="'#e3e3e3'"
                      [showSubtitle]="false"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="6"
                      [subtitleFontSize]="6"
                      [title]="getTitle(row.props['approachRating'], 10)"
                    ></circle-progress>
                  </div>

                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    "
                  >
                    <span class="scoreLabel"> Overall </span>
                    <circle-progress
                      [percent]="(row.props['overallRating'] / 10) * 100"
                      [radius]="20"
                      [outerStrokeWidth]="2"
                      [innerStrokeWidth]="1"
                      [outerStrokeColor]="
                        getCustomChartColors(row, 'overallRating')
                      "
                      [innerStrokeColor]="'#e3e3e3'"
                      [showSubtitle]="false"
                      [animation]="true"
                      [animationDuration]="700"
                      [showUnits]="false"
                      [titleFontSize]="6"
                      [subtitleFontSize]="6"
                      [title]="getTitle(row.props['overallRating'], 10)"
                    ></circle-progress>
                  </div>
                </div>
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="createdBy">
            <th mat-header-cell *matHeaderCellDef style="font-weight: 900">
              Completed By
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row?.assessmentCompletedBy }}
            </td>
          </ng-container>
          <!-- <ng-container matColumnDef="goals">
                <th mat-header-cell *matHeaderCellDef style="font-weight: 900">
                  Goals
                </th>
                <td mat-cell *matCellDef="let row">
                  <span
                    *ngIf="
                      row?.assessmentGoals &&
                      row?.assessmentGoals.length > 0 &&
                      row.status == 'Completed'
                    "
                    style="color: #2196f3; cursor: pointer; font-weight: 500"
                    (click)="viewAssessmentGoals(row)"
                  >
                    Progress -
                    <span>
                      {{ getBackgroundColor(row, 'percentage') }} %
                      <mat-progress-bar
                        mode="determinate"
                        value="{{ getBackgroundColor(row, 'percentage') }}"
                        style="max-width: 100px"
                      >
                      </mat-progress-bar>
                    </span>
                  </span>
                  <span
                    *ngIf="
                      (!row?.assessmentGoals ||
                        row?.assessmentGoals.length == 0) &&
                      row.status == 'Completed'
                    "
                    style="cursor: not-allowed"
                  >
                    N/A
                  </span>
                </td>
              </ng-container> -->
          <ng-container matColumnDef="pendingAction">
            <th
              mat-header-cell
              *matHeaderCellDef
              style="font-weight: 900; text-align: right"
            >
              Action
            </th>
            <td mat-cell *matCellDef="let row" style="text-align: right">
              <button
                mat-raised-button
                [matMenuTriggerFor]="menu"
                class="btn btn-just-icon btn-white btn-fab btn-round"
              >
                <i class="material-icons text_align-center">more_vert</i>
              </button>
              <mat-menu #menu="matMenu">
                <button
                  *ngIf="hasAssessmentTakeAddAccess"
                  mat-menu-item
                  type="button"
                  (click)="takeAssessment(row)"
                >
                  <mat-icon style="color: #2196f3; opacity: 0.5">
                    edit_note
                  </mat-icon>
                  <span>Take Assessment</span>
                </button>

                <button
                  *ngIf="hasMemberAssessmentDeleteAccess"
                  mat-menu-item
                  type="button"
                  (click)="openDeleteConfirm(row)"
                >
                  <mat-icon
                    id="deleteButton"
                    style="color: #d92550; opacity: 0.5"
                  >
                    delete_forever
                  </mat-icon>
                  <span>Delete</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th
              mat-header-cell
              *matHeaderCellDef
              style="font-weight: 900; text-align: right"
            >
              Actions
            </th>
            <td mat-cell *matCellDef="let row" style="text-align: right">
              <button
                mat-raised-button
                [matMenuTriggerFor]="menu"
                class="btn btn-just-icon btn-white btn-fab btn-round"
              >
                <i class="material-icons text_align-center">more_vert</i>
              </button>
              <mat-menu #menu="matMenu" class="actionsMenu">
                <!-- View -->
                <button
                  mat-menu-item
                  (click)="
                    openDialog(
                      'view',
                      row.assessmentType,
                      row.assessmentId,
                      row.assessmentRequestId,
                      row.patientId
                    )
                  "
                >
                  <mat-icon style="color: #2196f3"> visibility </mat-icon>
                  <span>View</span>
                </button>
                <!-- Charts -->
                <button
                  *ngIf="hasAssessmentChartsViewAccess"
                  mat-menu-item
                  (click)="
                    openDialog(
                      'chart',
                      row.assessmentType,
                      row.assessmentId,
                      row.assessmentRequestId,
                      row.patientId
                    )
                  "
                >
                  <mat-icon style="color: #219ebc"> insights </mat-icon>
                  <span>Charts</span>
                </button>
                <!-- Guidance -->
                <button
                  *ngIf="
                    hasAssessmentGuidanceViewAccess &&
                    row.questions &&
                    row.status !== 'Completed'
                  "
                  mat-menu-item
                  (click)="loadGuidanceDoc(row)"
                >
                  <mat-icon style="color: #023047">
                    format_list_bulleted_add
                  </mat-icon>
                  <span>Guidance</span>
                </button>
                <!-- Goals -->
                <!-- <button
                      *ngIf="isPhysician && row.status !== 'Completed'"
                      mat-menu-item
                      (click)="loadAssessmentGoals(row)"
                    >
                      <mat-icon style="color: red">
                        crisis_alert
                      </mat-icon>
                      <span>Goals</span>
                    </button> -->
                <!-- Notes -->
                <button
                  *ngIf="
                    hasAssessmentSummaryViewAccess &&
                    row.questions &&
                    row.status !== 'Completed'
                  "
                  mat-menu-item
                  (click)="summary(row)"
                >
                  <mat-icon style="color: #d92550"> note_alt</mat-icon>
                  <span>Summary</span>
                </button>
                <!-- Print -->
                <button
                  *ngIf="
                    hasAssessmentGuidanceViewAccess &&
                    row.status != 'Pending' &&
                    row.questions
                  "
                  mat-menu-item
                  (click)="
                    printAssessment(
                      row.assessmentType,
                      row.assessmentId,
                      row.patientId
                    )
                  "
                >
                  <mat-icon style="color: #794c8a"> local_printshop</mat-icon>
                  <span>Print</span>
                </button>
                <!-- Submit -->
                <button
                  *ngIf="
                    hasMemberAssessmentEditAccess &&
                    row.questions &&
                    row.status !== 'Completed'
                  "
                  mat-menu-item
                  style="color: green"
                  (click)="approveAssessment(row)"
                >
                  <mat-icon style="color: green"> verified</mat-icon>
                  <span>Submit</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <mat-paginator
        [length]="data.total"
        [pageSize]="data.per_page"
        [pageIndex]="pageIndex"
        [showFirstLastButtons]="true"
        *ngIf="data.total > data.per_page"
        (page)="getNext($event)"
      >
      </mat-paginator>
    </div>
  </div>
</div>
