import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { WorkHours } from 'src/app/_models';
import {
  ToastMessageService,
  WorkingHoursDialogApiService,
} from 'src/app/_services';
import { TimeArray } from 'src/app/shared/utilities/calendar/timeArrays';
import { WeekDays } from 'src/app/shared/utilities/calendar/weekDays';

@Component({
  selector: 'app-working-hours',
  templateUrl: './working-hours.component.html',
  styleUrls: ['./working-hours.component.css'],
})
export class WorkingHoursComponent implements OnInit {
  @Input() canEditWorkingHours: boolean;

  timeArray = TimeArray;
  weekDays = WeekDays;

  showSchedule: any = {};
  processing: boolean = false;
  isLoadingResults: boolean = true;
  myworkinghours: WorkHours;

  public addworkinghoursForm: FormGroup;

  constructor(
    private workinghoursdialogapiservice: WorkingHoursDialogApiService,
    private formBuilder: FormBuilder,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit() {
    // On load, set everyting to false
    for (let weekDay of this.weekDays) {
      this.showSchedule[weekDay] = false;
    }

    // Build Form
    this.buildForm();

    // Load Existing Working hours if exists
    this.loadWorkingHours();
  }

  private buildForm() {
    this.addworkinghoursForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(null),
      careProviderId: new FormControl(''),

      emailAddress: new FormControl(null),
      sunday: this.formBuilder.group({
        offday: true,
        workingHours: this.formBuilder.array([]),
      }),
      monday: this.formBuilder.group({
        offday: true,
        workingHours: this.formBuilder.array([]),
      }),
      tuesday: this.formBuilder.group({
        offday: true,
        workingHours: this.formBuilder.array([]),
      }),
      wednesday: this.formBuilder.group({
        offday: true,
        workingHours: this.formBuilder.array([]),
      }),
      thursday: this.formBuilder.group({
        offday: true,
        workingHours: this.formBuilder.array([]),
      }),
      friday: this.formBuilder.group({
        offday: true,
        workingHours: this.formBuilder.array([]),
      }),
      saturday: this.formBuilder.group({
        offday: true,
        workingHours: this.formBuilder.array([]),
      }),
    });
  }

  loadWorkingHours() {
    this.isLoadingResults = true;

    this.workinghoursdialogapiservice.getWorkingHours(null).subscribe(
      (myworkinghours) => {
        if (myworkinghours) {
          this.addworkinghoursForm.controls['id'].setValue(
            myworkinghours.data.id
          );
          this.addworkinghoursForm.controls['organizationId'].setValue(
            myworkinghours.data.organizationId
          );
          this.addworkinghoursForm.controls['emailAddress'].setValue(
            myworkinghours.data.emailAddress
          );

          for (let weekDay of this.weekDays) {
            let selectedWorkingDay = myworkinghours.data[weekDay];
            let subFormGroup = this.addworkinghoursForm.controls[
              weekDay
            ] as FormGroup;
            subFormGroup.controls['offday'].setValue(selectedWorkingDay.offday);
            this.showSchedule[weekDay] = !selectedWorkingDay.offday;
            let workingHourRecords = selectedWorkingDay['workingHours'];

            // Loading Working Hours record
            for (let record of workingHourRecords) {
              this.addNewRecord(weekDay, record.startTime, record.endTime);
            }
          }
          this.isLoadingResults = false;
        } else {
          this.isLoadingResults = false;
        }
      },
      (error) => {
        this.isLoadingResults = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to get work schedule'
        );
      }
    );
  }

  addNewRecord(day: string, startTime: Number, endTime: Number) {
    const control = <FormArray>(
      this.addworkinghoursForm.get([day, 'workingHours'])
    );
    control.push(
      new FormGroup({
        startTime: new FormControl({
          value: startTime,
          disabled: !this.canEditWorkingHours,
        }),
        endTime: new FormControl({
          value: endTime,
          disabled: !this.canEditWorkingHours,
        }),
      })
    );
  }

  deleteRecord(day: string, i: number) {
    const control = <FormArray>(
      this.addworkinghoursForm.get([day, 'workingHours'])
    );
    control.removeAt(i);
  }

  deleteAllRecord(day: string) {
    const control = <FormArray>(
      this.addworkinghoursForm.get([day, 'workingHours'])
    );
    let controlLength = control.length;
    for (let i = 0; i < controlLength; i++) {
      this.deleteRecord(day, 0);
    }
  }

  getRecords(fieldName: string): FormArray {
    return this.addworkinghoursForm.get(fieldName) as FormArray;
  }

  toShowSchedule(day: string) {
    this.showSchedule[day] = !this.showSchedule[day];

    let subFormGroup = this.addworkinghoursForm.controls[`${day}`] as FormGroup;
    subFormGroup.controls['offday'].setValue(!this.showSchedule[day]);

    if (!this.showSchedule[day]) {
      this.deleteAllRecord(day);
    } else {
      this.addNewRecord(day, 0, 0);
    }
  }

  displaySchedule(day: string) {
    let show = this.showSchedule[day];
    return show;
  }

  getDayWorkingHoursControl(day: string) {
    let control = <FormGroup>this.addworkinghoursForm.controls[`${day}`];
    let innerControl = control.controls['workingHours'];
    return innerControl['controls'];
  }

  addSchedule() {
    this.myworkinghours = Object.assign({}, this.myworkinghours);
    this.myworkinghours = Object.assign(
      this.myworkinghours,
      this.addworkinghoursForm.value
    );

    this.processing = true;
    this.workinghoursdialogapiservice
      .upsertWorkingHours(this.myworkinghours)
      .subscribe(
        (response) => {
          this.toastMessageService.displaySuccessMessage(
            'Successfully updated the employee office Hours.'
          );
          this.processing = false;
        },
        (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'An error occured while updating work schedule'
          );
        }
      );
  }

  presetWeekDays() {
    // First Clear All Data
    this.clearAll();

    // Now Set All the WorkDays to 9-5
    for (let weekDay of this.weekDays) {
      if (weekDay != 'sunday' && weekDay != 'saturday') {
        this.showSchedule[weekDay] = true;

        let subFormGroup = this.addworkinghoursForm.controls[
          `${weekDay}`
        ] as FormGroup;
        subFormGroup.controls['offday'].setValue(false);

        this.addNewRecord(weekDay, 900, 1700);
      }
    }
  }

  clearAll() {
    // First Remove all the working hours
    for (let weekDay of this.weekDays) {
      this.showSchedule[weekDay] = false;

      let subFormGroup = this.addworkinghoursForm.controls[
        `${weekDay}`
      ] as FormGroup;
      subFormGroup.controls['offday'].setValue(true);

      this.deleteAllRecord(weekDay);
    }
  }
}
