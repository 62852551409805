<!-- BreadCrumb -->
<div>
  <p style="font-size: 16px; font-weight: 450">
    <a routerLink="/" routerLinkActive="active">
      <em
        class="material-icons text_align-center"
        style="font-size: 18px; margin-top: -3px"
        >home</em
      >
    </a>
    &nbsp; / &nbsp;
    <span
      [routerLink]="
        parent === 'claims'
          ? '/claims'
          : ['/main/member/', primaryClaimDetails?.patientId, 'CLAIMS']
      "
      style="color: #2196f3; cursor: pointer; font-weight: 500"
    >
      Claims
    </span>
    &nbsp; / &nbsp; Secondary Insurance Claim
  </p>
</div>

<!-- Progress bar -->
<div *ngIf="isLoadingDetails || isLoadingResults">
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoadingDetails || isLoadingResults"
  ></mat-progress-bar>
</div>

<!-- Display the list of history -->
<div class="row" *ngIf="primaryClaimDetails?.claimCode">
  <!-- Claim Overview -->
  <div class="col-md-12" style="margin-bottom: 15px; margin: 5px">
    <label class="labelHeader"> Primary Claim Overview </label>
    <app-claims-overview-header
      [therapySession]="primaryClaimDetails?.therapySession"
    ></app-claims-overview-header>
    <br />
    <!-- Display Primary Claim Details -->
    <mat-accordion>
      <mat-expansion-panel
        style="border-radius: 15px"
        class="primary-claim-watermark"
      >
        <mat-expansion-panel-header>
          <mat-panel-title style="justify-content: center">
            <label class="labelPointer"> View Primary Claim Details </label>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-session-billing
          [therapySession]="primaryClaimDetails?.therapySession"
          parentClass="Claims"
        ></app-session-billing>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div
    class="col-md-12"
    style="display: flex; gap: 15px; margin: 20px 0px; flex-wrap: wrap"
  >
    <label
      *ngIf="secondaryClaimDetails?.billingStatus === 'On Hold'"
      class="holdClaimDisclaimer"
    >
      <mat-icon>back_hand</mat-icon>
      Claim is on Hold
    </label>

    <button
      type="button"
      mat-raised-button
      *ngIf="secondaryClaimDetails?.id"
      (click)="viewHistory()"
    >
      <mat-icon style="color: #2196f3">history</mat-icon> View History
    </button>

    <button
      *ngIf="
        secondaryClaimForm.controls['billingStatus'].value === 'In Draft' ||
        secondaryClaimForm.controls['billingStatus'].value === 'On Hold'
      "
      mat-raised-button
      type="button"
      color="warn"
      (click)="loadDataFromEOB()"
      style="margin-left: auto"
    >
      <mat-icon>system_update_alt</mat-icon>
      Load From EOB
    </button>
  </div>

  <!-- Show Internal Scrubbing Errors -->
  <div
    class="col-md-12"
    style="margin-top: 15px; margin-bottom: 15px"
    *ngIf="
      secondaryClaimDetails &&
      secondaryClaimDetails?.mindwiseClaimsScrubbingErrors?.length > 0
    "
  >
    <div class="row">
      <div
        class="col-md-12"
        *ngFor="
          let error of secondaryClaimDetails.mindwiseClaimsScrubbingErrors
        "
      >
        <mat-card
          appearance="raised"
          class="alertCard mat-elevation-z8"
          style="border-left: '5px solid red'"
        >
          <label class="labelText" style="color: red"> {{ error }}</label>
        </mat-card>
      </div>
    </div>
  </div>

  <!-- Show External Errors -->
  <div
    class="col-md-12"
    style="margin-top: 15px; margin-bottom: 15px"
    *ngIf="
      secondaryClaimDetails &&
      secondaryClaimDetails?.externalClaimsErrors?.length > 0
    "
  >
    <div class="row">
      <div
        class="col-md-12"
        *ngFor="let error of secondaryClaimDetails.externalClaimsErrors"
      >
        <mat-card
          appearance="raised"
          class="alertCard mat-elevation-z8"
          style="border-left: '5px solid red'"
        >
          <label class="labelText" style="color: red"> {{ error }}</label>
        </mat-card>
      </div>
    </div>
  </div>

  <!-- Claim Level Details -->
  <form [formGroup]="secondaryClaimForm">
    <div class="row">
      <!-- Claim Level COB -->
      <div class="col-md-6" style="margin-bottom: 15px">
        <label class="labelHeader">
          Secondary Claim Level COB Information
        </label>
        <mat-card
          appearance="raised"
          class="mat-elevation-z8"
          style="border-radius: 15px"
        >
          <div class="row">
            <div class="col-md-6">
              <label class="labelBold">Payer</label>
              <br />
              <label class="labelText">
                {{
                  primaryClaimDetails?.therapySession?.primaryInsuranceDetails
                    ?.payerName
                }}
              </label>
            </div>

            <div class="col-md-6">
              <label class="labelBold">
                Adjudication Date <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  formControlName="adjudicationDate"
                  matInput
                  [matDatepicker]="adjudicatedPicker"
                  (click)="adjudicatedPicker.open()"
                  autoSlashDate
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="adjudicatedPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #adjudicatedPicker></mat-datepicker>
                <mat-error
                  *ngIf="
                    secondaryClaimForm.controls['adjudicationDate'].invalid
                  "
                >
                  Please select the adjudication date
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <label class="labelBold">
                Payer Paid Amount <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  formControlName="payerPaidAmount"
                  matInput
                  type="number"
                  min="0"
                />
                <span matPrefix>$</span>
                <mat-error
                  *ngIf="secondaryClaimForm.controls['payerPaidAmount'].invalid"
                >
                  Please enter the payer paid amount
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <label class="labelBold"> Remaining Patient Liability</label>
              <mat-form-field appearance="outline">
                <input
                  formControlName="remainingPatientLiability"
                  matInput
                  type="number"
                  min="0"
                />
                <span matPrefix>$</span>
              </mat-form-field>
            </div>

            <!-- Resubmission code -->
            <div
              style="margin-bottom: 12px"
              *ngIf="secondaryClaimDetails?.claimSubmittedToPayer"
              class="col-md-12"
            >
              <label class="labelBold">
                Resubmission Code
                <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <mat-select
                  placeholder="Resubmission code"
                  formControlName="resubmissionCode"
                >
                  <mat-option
                    *ngFor="let code of resubmissionCodesList"
                    [value]="code.id"
                  >
                    {{ code.id }} - {{ code.description }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    secondaryClaimForm.controls['resubmissionCode'].invalid
                  "
                >
                  Please select the resubmission code
                </mat-error>
              </mat-form-field>
            </div>

            <!-- For Claim Level Adjustments -->
            <div
              class="col-md-12"
              style="text-align: right"
              *ngIf="
                secondaryClaimForm.controls['claimLevelAdjustments'].value
                  .length == 0
              "
            >
              <button
                mat-raised-button
                type="button"
                style="background: #e3e3e3"
                (click)="addClaimLevelAdjustments()"
              >
                + Add Claim Level Adjustment
              </button>
            </div>

            <div formArrayName="claimLevelAdjustments">
              <div
                *ngFor="
                  let claimAdjustment of secondaryClaimForm.get(
                    'claimLevelAdjustments'
                  )['controls'];
                  let index = index
                "
              >
                <div
                  class="row"
                  [formGroupName]="index"
                  style="margin-top: 15px"
                >
                  <div class="col-lg-3">
                    <label class="labelBold">
                      Adjustment Group <span style="color: red">*</span>
                    </label>
                    <mat-form-field appearance="outline">
                      <mat-select formControlName="groupCode">
                        <mat-option
                          *ngFor="let groupCode of adjustmentGroupCodesList"
                          [value]="groupCode.code"
                        >
                          {{ groupCode.code }} - {{ groupCode.description }}
                        </mat-option>
                      </mat-select>

                      <mat-error
                        *ngIf="claimAdjustment.controls['groupCode'].invalid"
                      >
                        Please select the group
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-3">
                    <label class="labelBold">
                      Amount <span style="color: red">*</span>
                    </label>
                    <mat-form-field appearance="outline">
                      <input
                        matInput
                        formControlName="amount"
                        type="number"
                        min="0"
                      />
                      <span matPrefix>$</span>
                      <mat-error
                        *ngIf="claimAdjustment.controls['amount'].invalid"
                      >
                        Please enter the amount
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-3">
                    <label class="labelBold">
                      Reason Code <span style="color: red">*</span>
                    </label>
                    <mat-form-field appearance="outline">
                      <mat-select formControlName="reasonCode">
                        <mat-option
                          *ngFor="let reasonCode of adjustmentReasonCodesList"
                          [value]="reasonCode.code"
                        >
                          {{ reasonCode.code }} - {{ reasonCode.description }}
                        </mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="claimAdjustment.controls['reasonCode'].invalid"
                      >
                        Please select the reason code
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-3">
                    <div class="row">
                      <div class="col-6">
                        <label class="labelBold">Units</label>
                        <mat-form-field appearance="outline">
                          <input matInput formControlName="quantity" />
                        </mat-form-field>
                      </div>
                      <div
                        class="col-6"
                        style="
                          display: flex;
                          align-items: center;
                          flex-wrap: wrap;
                        "
                      >
                        <button
                          mat-icon-button
                          type="button"
                          id="deleteButton"
                          #deleteButton
                          style="color: red; opacity: 0.5"
                          (click)="removeClaimLevelAdjustments(index)"
                          matTooltip="Remove adjustment"
                        >
                          <mat-icon>delete_forever</mat-icon>
                        </button>

                        <button
                          type="button"
                          mat-icon-button
                          (click)="addClaimLevelAdjustments()"
                          matTooltip="Click to add adjustment"
                        >
                          <mat-icon>add_circle</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Secondary Claim Insurance-->
      <div class="col-md-6">
        <label class="labelHeader"> Secondary Insurance Information</label>
        <mat-card
          appearance="raised"
          class="mat-elevation-z8"
          style="border-radius: 15px"
        >
          <div class="row">
            <div class="col-md-12" style="text-align: right">
              <button
                mat-raised-button
                type="button"
                style="background: #e3e3e3"
                (click)="addEditInsurance()"
              >
                Add/Edit Insurance
              </button>
            </div>

            <mat-error
              *ngIf="secondaryClaimForm.controls['insuranceDetails'].invalid"
            >
              Please select the secondary insurance
            </mat-error>

            <!-- // For Primary Insurance type and indicator -->
            <div
              class="row"
              *ngIf="secondaryClaimForm.controls['insuranceDetails'].value"
            >
              <div class="col-md-12">
                <label class="labelText">
                  Type of Health Insurance
                  <span style="opacity: 0.5; font-size: 12px">
                    (HCFA 1500 Box 1)
                  </span>
                  <span style="color: red"> * </span>
                </label>
                <mat-form-field appearance="outline">
                  <mat-select formControlName="healthInsuranceType">
                    <mat-option
                      *ngFor="let insuranceType of healthInsuranceTypeList"
                      [value]="insuranceType.id"
                    >
                      {{ insuranceType.description }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      secondaryClaimForm.controls['healthInsuranceType'].invalid
                    "
                  >
                    Please select the health insurance type
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-12">
                <label class="labelText">
                  Claim Filing Indicator
                  <span style="color: red"> * </span>
                </label>
                <mat-form-field appearance="outline">
                  <mat-select formControlName="claimFilingIndicator">
                    <mat-option
                      *ngFor="let indicatorType of claimFilingIndicatorList"
                      [value]="indicatorType.id"
                    >
                      {{ indicatorType.description }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      secondaryClaimForm.controls['claimFilingIndicator']
                        .invalid
                    "
                  >
                    Please select the claim indicator type
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div
              style="margin: 15px 0px; position: relative"
              class="col-md-12"
              *ngIf="secondaryClaimForm.controls['insuranceDetails'].valid"
            >
              <app-insurance-card
                [insurance]="
                  secondaryClaimForm.controls['insuranceDetails'].value
                "
                [patient]="patient"
                parentClass="Session"
              ></app-insurance-card>
              <!-- Insurance Action -->
              <div style="position: absolute; right: 25px; top: 20px">
                <mat-icon
                  style="color: red; cursor: pointer"
                  (click)="removeInsurance()"
                >
                  delete_forever
                </mat-icon>
              </div>
            </div>

            <div
              class="col-md-12"
              *ngIf="secondaryClaimForm.controls['insuranceDetails'].value"
            >
              <label class="labelBold">
                Is there a Prior Authorization Code?
              </label>
              <mat-radio-group
                formControlName="priorAuthCode"
                style="column-gap: 15px; display: flex; flex-wrap: wrap"
                color="primary"
                (change)="priorAuthChanged($event)"
              >
                <mat-radio-button value="Yes"> Yes </mat-radio-button>
                <mat-radio-button value="No"> No </mat-radio-button>
              </mat-radio-group>
            </div>

            <div
              class-="col-md-12"
              *ngIf="
                secondaryClaimForm.controls['priorAuthCode'].value === 'Yes' &&
                secondaryClaimForm.controls['insuranceDetails'].value
              "
            >
              <button
                type="button"
                mat-raised-button
                color="primary"
                (click)="lookUpPriorAuth()"
              >
                <i class="material-icons">search</i> Lookup Prior Auth
              </button>

              <div
                *ngIf="secondaryClaimForm.controls['priorAuthDetails'].value"
                style="margin-top: 15px"
              >
                <div class="gridContainer">
                  <label class="labelBold"> Prior Auth # </label>
                  <label class="labelText">
                    {{
                      secondaryClaimForm.controls['priorAuthDetails'].value
                        .authNumber
                    }}
                  </label>
                  <label class="labelBold"> Effective Start </label>
                  <label class="labelText">
                    {{
                      secondaryClaimForm.controls['priorAuthDetails'].value
                        .effectiveStartDate | date : 'MM/dd/y'
                    }}
                  </label>
                  <label class="labelBold"> Effective End </label>
                  <label class="labelText">
                    {{
                      secondaryClaimForm.controls['priorAuthDetails'].value
                        .effectiveEndDate | date : 'MM/dd/y'
                    }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Service Level COB -->
      <div class="col-md-12" style="margin-top: 15px">
        <label class="labelHeader"> Service Level COB Information</label>
        <mat-card
          appearance="raised"
          class="mat-elevation-z8"
          style="border-radius: 15px"
        >
          <!-- Each service Line -->
          <div formArrayName="serviceLevelDetails">
            <div class="row">
              <div
                *ngFor="
                  let serviceDetail of secondaryClaimForm.get(
                    'serviceLevelDetails'
                  )['controls'];
                  let index = index
                "
                class="col-md-12"
              >
                <mat-card
                  [formGroupName]="index"
                  appearance="raised"
                  class="mat-elevation-z8 serviceLineBox"
                >
                  <div class="row">
                    <!-- CPT Code -->
                    <div class="col-md-2">
                      <label class="labelBold">CPT Code</label>
                      <br />
                      <label class="labelText">
                        {{ serviceDetail.controls['cptCode'].value }}
                      </label>
                    </div>
                    <!-- Total Amount -->
                    <div class="col-lg-2">
                      <label class="labelBold">Total Amount</label>
                      <br />
                      <label class="labelText">
                        {{
                          serviceDetail.controls['totalAmount'].value
                            | currency : 'USD'
                        }}
                      </label>
                    </div>
                    <!-- Adjudication Date  -->
                    <div class="col-md-3">
                      <label class="labelBold">
                        Adjudication Date <span style="color: red">*</span>
                      </label>
                      <mat-form-field appearance="outline">
                        <input
                          formControlName="adjudicationDate"
                          matInput
                          [matDatepicker]="adjudicatedPicker"
                          (click)="adjudicatedPicker.open()"
                          autoSlashDate
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="adjudicatedPicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #adjudicatedPicker></mat-datepicker>
                        <mat-error
                          *ngIf="
                            serviceDetail.controls['adjudicationDate'].invalid
                          "
                        >
                          Please select the adjudication date
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <!--  Paid Unit -->
                    <div class="col-md-3">
                      <label class="labelBold">
                        Paid Units <span style="color: red">*</span>
                      </label>
                      <mat-form-field appearance="outline">
                        <input
                          matInput
                          type="number"
                          min="0"
                          formControlName="quantity"
                        />
                        <mat-error
                          *ngIf="serviceDetail.controls['quantity'].invalid"
                        >
                          Please enter the paid unit of service
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <!--  Paid Amount -->
                    <div class="col-md-2">
                      <label class="labelBold">
                        Paid Amount <span style="color: red">*</span>
                      </label>
                      <mat-form-field appearance="outline">
                        <input
                          formControlName="paidAmount"
                          matInput
                          type="number"
                          min="0"
                        />
                        <span matPrefix>$</span>
                        <mat-error
                          *ngIf="serviceDetail.controls['paidAmount'].invalid"
                        >
                          Please enter the payer paid amount
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <!-- Adjustments -->
                    <div class="col-md-12 borderTopBox">
                      <label class="labelBold">
                        <div class="justifyCenterContainer">
                          <mat-icon>toc</mat-icon>
                          Service Level Adjustments

                          <button
                            mat-icon-button
                            type="button"
                            color="warn"
                            (click)="addClaimServiceLevelAdjustments(index)"
                          >
                            <mat-icon>add_circle</mat-icon>
                          </button>
                        </div>
                      </label>

                      <div formArrayName="claimAdjustments">
                        <div
                          *ngFor="
                            let claimAdjustment of serviceDetail.get(
                              'claimAdjustments'
                            )['controls'];
                            let adjustmentIndex = index
                          "
                        >
                          <div
                            class="row"
                            [formGroupName]="adjustmentIndex"
                            style="margin-bottom: 15px"
                          >
                            <div class="col-md-3">
                              <label class="labelBold">
                                Group <span style="color: red">*</span>
                              </label>
                              <mat-form-field appearance="outline">
                                <mat-select
                                  formControlName="groupCode"
                                  (selectionChange)="
                                    serviceLineAdjustmentGroupCodeChanged(
                                      $event,
                                      index,
                                      adjustmentIndex
                                    )
                                  "
                                >
                                  <mat-option
                                    *ngFor="
                                      let groupCode of adjustmentGroupCodesList
                                    "
                                    [value]="groupCode.code"
                                  >
                                    {{ groupCode.code }}
                                  </mat-option>
                                </mat-select>

                                <mat-error
                                  *ngIf="
                                    claimAdjustment.controls['groupCode']
                                      .invalid
                                  "
                                >
                                  Please select the group
                                </mat-error>
                              </mat-form-field>
                            </div>

                            <div class="col-md-3">
                              <label class="labelBold">
                                Amount <span style="color: red">*</span>
                              </label>
                              <mat-form-field appearance="outline">
                                <input
                                  matInput
                                  formControlName="amount"
                                  type="number"
                                  min="0"
                                />
                                <span matPrefix>$</span>
                                <mat-error
                                  *ngIf="
                                    claimAdjustment.controls['amount'].invalid
                                  "
                                >
                                  Please enter the amount
                                </mat-error>
                              </mat-form-field>
                            </div>

                            <div class="col-md-3">
                              <label class="labelBold">
                                Reason <span style="color: red">*</span>
                              </label>
                              <mat-form-field appearance="outline">
                                <mat-select
                                  formControlName="reasonCode"
                                  (selectionChange)="
                                    serviceLineAdjustmentReasonCodeChanged(
                                      $event,
                                      index,
                                      adjustmentIndex
                                    )
                                  "
                                >
                                  <mat-option
                                    *ngFor="
                                      let reasonCode of adjustmentReasonCodesList
                                    "
                                    [value]="reasonCode.code"
                                  >
                                    {{ reasonCode.code }} -
                                    {{ reasonCode.description }}
                                  </mat-option>
                                </mat-select>
                                <mat-error
                                  *ngIf="
                                    claimAdjustment.controls['reasonCode']
                                      .invalid
                                  "
                                >
                                  Please select the reason code
                                </mat-error>
                              </mat-form-field>
                            </div>

                            <div class="col-md-3">
                              <div class="row">
                                <div class="col-6">
                                  <label class="labelBold"> Units </label>
                                  <mat-form-field appearance="outline">
                                    <input
                                      matInput
                                      formControlName="quantity"
                                    />
                                  </mat-form-field>
                                </div>
                                <div
                                  class="col-6"
                                  style="
                                    display: flex;
                                    align-items: center;
                                    flex-wrap: wrap;
                                  "
                                >
                                  <button
                                    mat-icon-button
                                    type="button"
                                    id="deleteButton"
                                    #deleteButton
                                    style="color: red; opacity: 0.5"
                                    (click)="
                                      removeServiceLevelAdjustments(
                                        index,
                                        adjustmentIndex
                                      )
                                    "
                                    matTooltip="Remove adjustment"
                                  >
                                    <mat-icon>delete_forever</mat-icon>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Disclaimer -->
      <div class="col-md-12" style="margin-top: 15px">
        <label>
          <i>Important:</i> Be sure to double-check totals using the formula
          <b>Paid Amount + All Adjustments = Service Line Charge</b>
        </label>
        <br />
        <label>
          <i>Important:</i> Always type in a zero if something is a zero dollar
          amount; do not just leave it blank.
        </label>
      </div>

      <!-- Action Buttons -->
      <div
        class="col-md-12"
        style="
          display: flex;
          justify-content: right;
          margin-top: 15px;
          gap: 25px;
        "
      >
        <button
          *ngIf="
            secondaryClaimForm.controls['billingStatus'].value === 'In Draft' ||
            secondaryClaimForm.controls['billingStatus'].value === 'Rejected'
          "
          mat-raised-button
          type="button"
          [disabled]="processing"
          (click)="markClaimOnHold()"
        >
          <i class="material-icons">back_hand</i> Mark On Hold
          <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>

        <button
          mat-raised-button
          type="button"
          [disabled]="processing"
          *ngIf="
            secondaryClaimForm.controls['billingStatus'].value === 'In Draft' ||
            secondaryClaimForm.controls['billingStatus'].value === 'On Hold'
          "
          (click)="saveForm()"
        >
          <i class="material-icons">save</i> Save
          <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>

        <button
          mat-raised-button
          type="button"
          color="warn"
          [disabled]="processing || secondaryClaimForm.invalid"
          (click)="submitForm()"
        >
          <i class="material-icons">send</i> Submit Claim
          <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
