// shared.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SearchService {
    private selectedProviders = new BehaviorSubject<any[]>([]);
    private selectedFacilities = new BehaviorSubject<any[]>([]);
    private selectedReferralStaffMembers = new BehaviorSubject<any[]>([]);

    selectedProviders$ = this.selectedProviders.asObservable();
    selectedFacilities$ = this.selectedFacilities.asObservable();
    selectedReferralStaffMembers$ = this.selectedFacilities.asObservable();

    updateSelectedProviders(providers: any[]) {
        this.selectedProviders.next(providers);
    }

    updateSelectedFacilities(facilities: any[]) {
        this.selectedFacilities.next(facilities);
    }

    updateReferralStaffMembers(staffMembers: any[]) {
        this.selectedReferralStaffMembers.next(staffMembers);
    }
}
