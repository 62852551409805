<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Major Depression Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="majorDepressionForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >1. Have you felt low in spirits or sad?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q1"
        >
          <mat-button-toggle value="All The Time"
            >All The Time</mat-button-toggle
          >
          <mat-button-toggle value="Most Of The Time"
            >Most Of The Time</mat-button-toggle
          >
          <mat-button-toggle value="Slightly More Than Half The Time"
            >Slightly More Than Half The Time</mat-button-toggle
          >
          <mat-button-toggle value="Slightly Less Than Half The Time"
            >Slightly Less Than Half The Time</mat-button-toggle
          >
          <mat-button-toggle value="Some Of The Time"
            >Some Of The Time</mat-button-toggle
          >
          <mat-button-toggle value="At No Time">At No Time</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >2. Have you lost interest in your daily activities?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q2"
        >
          <mat-button-toggle value="All The Time"
            >All The Time</mat-button-toggle
          >
          <mat-button-toggle value="Most Of The Time"
            >Most Of The Time</mat-button-toggle
          >
          <mat-button-toggle value="Slightly More Than Half The Time"
            >Slightly More Than Half The Time</mat-button-toggle
          >
          <mat-button-toggle value="Slightly Less Than Half The Time"
            >Slightly Less Than Half The Time</mat-button-toggle
          >
          <mat-button-toggle value="Some Of The Time"
            >Some Of The Time</mat-button-toggle
          >
          <mat-button-toggle value="At No Time">At No Time</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >3. Have you felt lacking in energy and strength?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q3"
        >
          <mat-button-toggle value="All The Time"
            >All The Time</mat-button-toggle
          >
          <mat-button-toggle value="Most Of The Time"
            >Most Of The Time</mat-button-toggle
          >
          <mat-button-toggle value="Slightly More Than Half The Time"
            >Slightly More Than Half The Time</mat-button-toggle
          >
          <mat-button-toggle value="Slightly Less Than Half The Time"
            >Slightly Less Than Half The Time</mat-button-toggle
          >
          <mat-button-toggle value="Some Of The Time"
            >Some Of The Time</mat-button-toggle
          >
          <mat-button-toggle value="At No Time">At No Time</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >4. Have you felt less self-confident?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q4"
        >
          <mat-button-toggle value="All The Time"
            >All The Time</mat-button-toggle
          >
          <mat-button-toggle value="Most Of The Time"
            >Most Of The Time</mat-button-toggle
          >
          <mat-button-toggle value="Slightly More Than Half The Time"
            >Slightly More Than Half The Time</mat-button-toggle
          >
          <mat-button-toggle value="Slightly Less Than Half The Time"
            >Slightly Less Than Half The Time</mat-button-toggle
          >
          <mat-button-toggle value="Some Of The Time"
            >Some Of The Time</mat-button-toggle
          >
          <mat-button-toggle value="At No Time">At No Time</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >5. Have you had a bad conscience or feelings of guilt?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q5"
        >
          <mat-button-toggle value="All The Time"
            >All The Time</mat-button-toggle
          >
          <mat-button-toggle value="Most Of The Time"
            >Most Of The Time</mat-button-toggle
          >
          <mat-button-toggle value="Slightly More Than Half The Time"
            >Slightly More Than Half The Time</mat-button-toggle
          >
          <mat-button-toggle value="Slightly Less Than Half The Time"
            >Slightly Less Than Half The Time</mat-button-toggle
          >
          <mat-button-toggle value="Some Of The Time"
            >Some Of The Time</mat-button-toggle
          >
          <mat-button-toggle value="At No Time">At No Time</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >6. Have you felt that life wasn’t worth living?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q6"
        >
          <mat-button-toggle value="All The Time"
            >All The Time</mat-button-toggle
          >
          <mat-button-toggle value="Most Of The Time"
            >Most Of The Time</mat-button-toggle
          >
          <mat-button-toggle value="Slightly More Than Half The Time"
            >Slightly More Than Half The Time</mat-button-toggle
          >
          <mat-button-toggle value="Slightly Less Than Half The Time"
            >Slightly Less Than Half The Time</mat-button-toggle
          >
          <mat-button-toggle value="Some Of The Time"
            >Some Of The Time</mat-button-toggle
          >
          <mat-button-toggle value="At No Time">At No Time</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >7. Have you had difficulty in concentrating?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q7"
        >
          <mat-button-toggle value="All The Time"
            >All The Time</mat-button-toggle
          >
          <mat-button-toggle value="Most Of The Time"
            >Most Of The Time</mat-button-toggle
          >
          <mat-button-toggle value="Slightly More Than Half The Time"
            >Slightly More Than Half The Time</mat-button-toggle
          >
          <mat-button-toggle value="Slightly Less Than Half The Time"
            >Slightly Less Than Half The Time</mat-button-toggle
          >
          <mat-button-toggle value="Some Of The Time"
            >Some Of The Time</mat-button-toggle
          >
          <mat-button-toggle value="At No Time">At No Time</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >8. Have you felt very restless?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q8"
        >
          <mat-button-toggle value="All The Time"
            >All The Time</mat-button-toggle
          >
          <mat-button-toggle value="Most Of The Time"
            >Most Of The Time</mat-button-toggle
          >
          <mat-button-toggle value="Slightly More Than Half The Time"
            >Slightly More Than Half The Time</mat-button-toggle
          >
          <mat-button-toggle value="Slightly Less Than Half The Time"
            >Slightly Less Than Half The Time</mat-button-toggle
          >
          <mat-button-toggle value="Some Of The Time"
            >Some Of The Time</mat-button-toggle
          >
          <mat-button-toggle value="At No Time">At No Time</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >9. Have you felt subdued or slowed down?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q9"
        >
          <mat-button-toggle value="All The Time"
            >All The Time</mat-button-toggle
          >
          <mat-button-toggle value="Most Of The Time"
            >Most Of The Time</mat-button-toggle
          >
          <mat-button-toggle value="Slightly More Than Half The Time"
            >Slightly More Than Half The Time</mat-button-toggle
          >
          <mat-button-toggle value="Slightly Less Than Half The Time"
            >Slightly Less Than Half The Time</mat-button-toggle
          >
          <mat-button-toggle value="Some Of The Time"
            >Some Of The Time</mat-button-toggle
          >
          <mat-button-toggle value="At No Time">At No Time</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >10. Have you had trouble sleeping at night?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q10"
        >
          <mat-button-toggle value="All The Time"
            >All The Time</mat-button-toggle
          >
          <mat-button-toggle value="Most Of The Time"
            >Most Of The Time</mat-button-toggle
          >
          <mat-button-toggle value="Slightly More Than Half The Time"
            >Slightly More Than Half The Time</mat-button-toggle
          >
          <mat-button-toggle value="Slightly Less Than Half The Time"
            >Slightly Less Than Half The Time</mat-button-toggle
          >
          <mat-button-toggle value="Some Of The Time"
            >Some Of The Time</mat-button-toggle
          >
          <mat-button-toggle value="At No Time">At No Time</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >11. Have you suffered from reduced appetite?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q11"
        >
          <mat-button-toggle value="All The Time"
            >All The Time</mat-button-toggle
          >
          <mat-button-toggle value="Most Of The Time"
            >Most Of The Time</mat-button-toggle
          >
          <mat-button-toggle value="Slightly More Than Half The Time"
            >Slightly More Than Half The Time</mat-button-toggle
          >
          <mat-button-toggle value="Slightly Less Than Half The Time"
            >Slightly Less Than Half The Time</mat-button-toggle
          >
          <mat-button-toggle value="Some Of The Time"
            >Some Of The Time</mat-button-toggle
          >
          <mat-button-toggle value="At No Time">At No Time</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >12. Have you suffered from increased appetite?</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="q12"
        >
          <mat-button-toggle value="All The Time"
            >All The Time</mat-button-toggle
          >
          <mat-button-toggle value="Most Of The Time"
            >Most Of The Time</mat-button-toggle
          >
          <mat-button-toggle value="Slightly More Than Half The Time"
            >Slightly More Than Half The Time</mat-button-toggle
          >
          <mat-button-toggle value="Slightly Less Than Half The Time"
            >Slightly Less Than Half The Time</mat-button-toggle
          >
          <mat-button-toggle value="Some Of The Time"
            >Some Of The Time</mat-button-toggle
          >
          <mat-button-toggle value="At No Time">At No Time</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>

    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          majorDepressionForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!majorDepressionForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
