import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDiagnosis, Patient } from 'src/app/_models';
import { DiagnosisApiService, ToastMessageService } from 'src/app/_services';

@Component({
  selector: 'app-edit-diagnosis',
  templateUrl: './edit-diagnosis.component.html',
  styleUrls: ['./edit-diagnosis.component.css'],
})
export class EditDiagnosisComponent implements OnInit {
  patient: Patient;
  diagnosis: IDiagnosis;
  public diagnosisForm: FormGroup;
  processing: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<EditDiagnosisComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public diagnosisApiService: DiagnosisApiService,
    private toastMessageService: ToastMessageService
  ) {
    this.patient = data.patient;
    this.diagnosis = data.diagnosis;
  }

  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm() {
    this.diagnosisForm = this.formBuilder.group({
      id: new FormControl(this.diagnosis.id, Validators.required),
      organizationId: new FormControl(
        this.diagnosis.organizationId,
        Validators.required
      ),
      patientId: new FormControl(this.diagnosis.patientId, Validators.required),
      icdCode: new FormControl(this.diagnosis.icdCode, Validators.required),
      shortDesc: new FormControl(this.diagnosis.shortDesc, Validators.required),
      longDesc: new FormControl(this.diagnosis.longDesc, Validators.required),
      status: new FormControl(this.diagnosis.status, Validators.required),
    });
  }

  submitForm() {
    this.diagnosis = Object.assign({}, this.diagnosis);
    this.diagnosis = Object.assign(this.diagnosis, this.diagnosisForm.value);

    this.processing = true;
    this.diagnosisApiService.updateDiagnosis(this.diagnosis).subscribe(
      (response) => {
        this.processing = false;
        this.toastMessageService.displaySuccessMessage(
          'Successfully updated the diagnosis'
        );
        this.dialogRef.close('success');
      },
      (error) => {
        this.processing = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to update the diagnosis'
        );
      }
    );
  }
}
