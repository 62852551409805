import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { IMaas } from 'src/app/_models/assessments/adhd/maas.model';
import {
  ToastMessageService
} from 'src/app/_services/';
import {
  MaasApiService
} from 'src/app/_services/assessments/adhd/maas.service';

@Component({
  selector: 'app-attention-awareness-scale',
  templateUrl: './attention-awareness-scale.component.html',
  styleUrls: ['./attention-awareness-scale.component.css']
})
export class AttentionAwarenessScaleComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public maasAssessmentApiService: MaasApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public maasAssessmentForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  maasAssessment: IMaas;
  maasAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.maasAssessmentForm, this.errors);

    this.loadMaas();
  }

  loadMaas() {
    this.maasAssessmentForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.maasAssessmentApiService
        .getMaasDetails(this.assessmentId)
        .subscribe((maasAssessment) => {
          if (maasAssessment.data) {

            this.maasAssessmentForm.controls['unconsciousEmotion'].setValue(maasAssessment.data.unconsciousEmotion.answer);
            this.maasAssessmentForm.controls['breakSpillThings'].setValue(maasAssessment.data.breakSpillThings.answer);
            this.maasAssessmentForm.controls['difficultyStayingFocused'].setValue(maasAssessment.data.difficultyStayingFocused.answer);
            this.maasAssessmentForm.controls['quickWalking'].setValue(maasAssessment.data.quickWalking.answer);
            this.maasAssessmentForm.controls['unnoticedTension'].setValue(maasAssessment.data.unnoticedTension.answer);
            this.maasAssessmentForm.controls['forgettingNames'].setValue(maasAssessment.data.forgettingNames.answer);
            this.maasAssessmentForm.controls['runningAutomatic'].setValue(maasAssessment.data.runningAutomatic.answer);
            this.maasAssessmentForm.controls['rushingActivities'].setValue(maasAssessment.data.rushingActivities.answer);
            this.maasAssessmentForm.controls['focusedOnGoal'].setValue(maasAssessment.data.focusedOnGoal.answer);
            this.maasAssessmentForm.controls['automaticTasks'].setValue(maasAssessment.data.automaticTasks.answer);
            this.maasAssessmentForm.controls['halfListening'].setValue(maasAssessment.data.halfListening.answer);
            this.maasAssessmentForm.controls['automaticDriving'].setValue(maasAssessment.data.automaticDriving.answer);
            this.maasAssessmentForm.controls['preoccupiedWithPastFuture'].setValue(maasAssessment.data.preoccupiedWithPastFuture.answer);
            this.maasAssessmentForm.controls['unattentiveActions'].setValue(maasAssessment.data.unattentiveActions.answer);
            this.maasAssessmentForm.controls['unawareSnacking'].setValue(maasAssessment.data.unawareSnacking.answer);

            this.riskScore = maasAssessment.data.riskScore;
          }
        });
    }
  }
  onMaasFormSubmit() {
    if (this.maasAssessmentForm.invalid) {
      this.maasAssessmentForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.maasAssessment = Object.assign({}, this.maasAssessment);
    this.maasAssessment.patientId =
      this.maasAssessmentForm.controls['patientId'].value;
    this.maasAssessment.sessionId =
      this.maasAssessmentForm.controls['sessionId'].value;
    this.maasAssessment.assessmentRequestId =
      this.maasAssessmentForm.controls['assessmentRequestId'].value;

    this.maasAssessment.unconsciousEmotion = { answer: this.maasAssessmentForm.controls['unconsciousEmotion'].value };
    this.maasAssessment.breakSpillThings = { answer: this.maasAssessmentForm.controls['breakSpillThings'].value };
    this.maasAssessment.difficultyStayingFocused = { answer: this.maasAssessmentForm.controls['difficultyStayingFocused'].value };
    this.maasAssessment.quickWalking = { answer: this.maasAssessmentForm.controls['quickWalking'].value };
    this.maasAssessment.unnoticedTension = { answer: this.maasAssessmentForm.controls['unnoticedTension'].value };
    this.maasAssessment.forgettingNames = { answer: this.maasAssessmentForm.controls['forgettingNames'].value };
    this.maasAssessment.runningAutomatic = { answer: this.maasAssessmentForm.controls['runningAutomatic'].value };
    this.maasAssessment.rushingActivities = { answer: this.maasAssessmentForm.controls['rushingActivities'].value };
    this.maasAssessment.focusedOnGoal = { answer: this.maasAssessmentForm.controls['focusedOnGoal'].value };
    this.maasAssessment.automaticTasks = { answer: this.maasAssessmentForm.controls['automaticTasks'].value };
    this.maasAssessment.halfListening = { answer: this.maasAssessmentForm.controls['halfListening'].value };
    this.maasAssessment.automaticDriving = { answer: this.maasAssessmentForm.controls['automaticDriving'].value };
    this.maasAssessment.preoccupiedWithPastFuture = { answer: this.maasAssessmentForm.controls['preoccupiedWithPastFuture'].value };
    this.maasAssessment.unattentiveActions = { answer: this.maasAssessmentForm.controls['unattentiveActions'].value };
    this.maasAssessment.unawareSnacking = { answer: this.maasAssessmentForm.controls['unawareSnacking'].value };


    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.maasAssessment.accessCode = this.accessCode;

      this.maasAssessmentApiService
        .addGuestMaas(this.maasAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted The Mindful Attention Awareness Scale (MAAS) Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting The Mindful Attention Awareness Scale (MAAS) Assessment'
            );
          }
        );
    } else {
      this.maasAssessmentApiService
        .addMaas(this.maasAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted The Mindful Attention Awareness Scale (MAAS) Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting The Mindful Attention Awareness Scale (MAAS) Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    'unconsciousEmotion':[
      { type: 'required', message: '1. I could be experiencing some emotion and not be conscious of it until some time later. is required' },
    ],
    'breakSpillThings':[
      { type: 'required', message: '2. I break or spill things because of carelessness, not paying attention, or thinking of something else. is required' },
    ],
    'difficultyStayingFocused':[
      { type: 'required', message: '3. I find it difficult to stay focused on what\'s happening in the present. is required' },
    ],
    'quickWalking':[
      { type: 'required', message: '4. I tend to walk quickly to get where I\'m going without paying attention to what I experience along the way. is required' },
    ],
    'unnoticedTension':[
      { type: 'required', message: '5. I tend not to notice feelings of physical tension or discomfort until they really grab my attention. is required' },
    ],
    'forgettingNames':[
      { type: 'required', message: '6. I forget a person\'s name almost as soon as I\'ve been told it for the first time. is required' },
    ],
    'runningAutomatic':[
      { type: 'required', message: '7. It seems I am “running on automatic,” without much awareness of what I\'m doing. is required' },
    ],
    'rushingActivities':[
      { type: 'required', message: '8. I rush through activities without being really attentive to them. is required' },
    ],
    'focusedOnGoal':[
      { type: 'required', message: '9. I get so focused on the goal I want to achieve that I lose touch with what I\'m doing right now to get there. is required' },
    ],
    'automaticTasks':[
      { type: 'required', message: '10. I do jobs or tasks automatically, without being aware of what I\'m doing. is required' },
    ],
    'halfListening':[
      { type: 'required', message: '11. I find myself listening to someone with one ear, doing something else at the same time. is required' },
    ],
    'automaticDriving':[
      { type: 'required', message: '12. I drive places on \'automatic pilot\' and then wonder why I went there. is required' },
    ],
    'preoccupiedWithPastFuture':[
      { type: 'required', message: '13. I find myself preoccupied with the future or the past. is required' },
    ],
    'unattentiveActions':[
      { type: 'required', message: '14. I find myself doing things without paying attention. is required' },
    ],
    'unawareSnacking':[
      { type: 'required', message: '15. I snack without being aware that I\'m eating. is required' },
    ],
  };

  private buildForm() {
    this.maasAssessmentForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      unconsciousEmotion: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      breakSpillThings: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      difficultyStayingFocused: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      quickWalking: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      unnoticedTension: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      forgettingNames: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      runningAutomatic: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      rushingActivities: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      focusedOnGoal: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      automaticTasks: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      halfListening: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      automaticDriving: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      preoccupiedWithPastFuture: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      unattentiveActions: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      unawareSnacking: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
    });
    this.maasAssessmentForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.maasAssessmentForm) {
      return;
    }
    const form = this.maasAssessmentForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
