<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Geriatric Depression Scale</p>
  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-6"></div>
    <div class="col-sm-1"></div>
    <div class="col-sm-3">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-6"></div>
  </div>

  <form
    [formGroup]="geriatricdepressionscaleForm"
    novalidate
    (ngSubmit)="onGeriatricDepressionScaleFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >1. Are you basically satisfied with your life?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="satisfiedWithLife">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >2. Have you dropped many of your activities and interests?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="droppedActivitiesAndInterests"
            >
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >3. Do you feel that your life is empty?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="feelLifeIsEmpty">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >4. Do you often get bored?
          </label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="gettingBored">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >5. Are you hopeful about the future?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="hopefulAboutFuture">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >6. Are you bothered by thoughts you can't get out of your
            head?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="botheredByThoughts">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >7. Are you in good spirits most of the time?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="inGoodSpirits">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >8. Are you afraid something bad is going to happen to you?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="afraidOfSomthingBad">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >9. Do you feel happy most of the time?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="feelHappy">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >10. Do you often feel helpless?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="feelHelpless">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >11. Do you often feel restless and fidgety?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="feelRestless">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >12. Do you prefer to stay at home rather than going out and doing
            new things?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="preferStayAtHome">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >13. Do you frequently worry about the future?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="worryAboutFuture">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >14. Do you feel you have more problems with memory than
            most?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="problemsWithMemory">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >15. Do you think it is wonderful to be alive right now?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="goodToBeAlive">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >16. Do you often feel downhearted and blue?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="feelDownhearted">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >17. Do you feel pretty worthless the way you are now?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="feelWorthless">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >18. Do you worry a lot about the past?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="worryAboutPast">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >19. Do you find life very exciting?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="lifeIsExciting">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >20. Is it hard for you to get started on new projects?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="hardToStartNewProjects">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >21. Do you feel full of energy?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="feelEnergy">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >22. Do you feel your situation is hopeless?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="feelHopeless">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >23. Do you think that most people are better off than you
            are?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="otherPeopleAreBetter">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >24. Do you frequently get upset over little things?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="feelUpset">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >25. Do you frequently feel like crying?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="feelLikeCrying">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >26. Do you have trouble concentrating?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="troubleConcentrating">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >27. Do you enjoy getting up in the morning?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="wakeUpInTheMorning">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >28. Do you avoid social gatherings?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="avoidSocialGatherings">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >29. Is it easy for you to make decisions?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="easyToMakeDecisions">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >30. Is your mind as clear as it used to be?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="mindClear">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          geriatricdepressionscaleForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!geriatricdepressionscaleForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
