<div class="container">
  <div class="dialogTitle" *ngIf="isModalWindow">
    <p matDialogTitle *ngIf="action != 'VERIFY'">My Health History</p>
    <p matDialogTitle *ngIf="action === 'VERIFY'">Member Health History</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="row">
    <div class="col-md-12" style="margin-bottom: 15px">
      <label class="labelText">
        Please take the time to fill out this form as completely as possible.
        This will help us get a comprehensive health history and expedite your
        clinic evaluation time.
      </label>
    </div>
  </div>

  <form [formGroup]="memberHealthHistoryForm" novalidate>
    <mat-stepper linear="true" #stepper>
      <!-- Physicians -->
      <mat-step label="Physicians">
        <div class="row">
          <div class="col-md-12">
            <label class="labelBold">
              Please list the names of all physicians you currenty see.
            </label>
          </div>

          <div formArrayName="physicians">
            <div
              *ngFor="
                let physician of memberHealthHistoryForm.get('physicians')[
                  'controls'
                ];
                let index = index
              "
            >
              <div class="row" [formGroupName]="index" style="margin-top: 15px">
                <div class="col-6 col-md-3">
                  <label class="labelText">First Name</label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      placeholder="First Name"
                      formControlName="firstName"
                      name="firstName"
                    />
                    <mat-error *ngIf="physician.controls['firstName'].invalid">
                      Please enter a valid first name
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-6 col-md-3">
                  <label class="labelText">Last Name</label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      placeholder="Last Name"
                      formControlName="lastName"
                      name="lastName"
                    />
                    <mat-error *ngIf="physician.controls['lastName'].invalid">
                      Please enter a valid last name
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-2">
                  <label class="labelText">Phone #</label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      placeholder="Phone #"
                      formControlName="phone"
                      name="phone"
                    />
                    <mat-error *ngIf="physician.controls['phone'].invalid">
                      Please enter a valid phone number
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <label class="labelText">Email</label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      type="email"
                      placeholder="Email"
                      formControlName="email"
                      name="email"
                    />
                    <mat-error *ngIf="physician.controls['email'].invalid">
                      Please enter a valid email
                    </mat-error>
                  </mat-form-field>
                </div>
                <div
                  class="col-md-1"
                  style="display: flex; align-items: center"
                >
                  <button
                    mat-raised-button
                    color="primary"
                    type="button"
                    *ngIf="
                      index ===
                      memberHealthHistoryForm.get('physicians')['controls']
                        .length -
                        1
                    "
                    (click)="addNewPhysician()"
                  >
                    Add
                  </button>

                  <button
                    mat-raised-button
                    color="warn"
                    type="button"
                    *ngIf="
                      index !=
                      memberHealthHistoryForm.get('physicians')['controls']
                        .length -
                        1
                    "
                    (click)="removePhysician(index)"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12" style="text-align: right; margin-top: 15px">
            <button mat-raised-button matStepperNext type="button">Next</button>
          </div>
        </div>
      </mat-step>

      <!-- Medications -->
      <mat-step label="Medications">
        <div class="row">
          <div class="col-md-12">
            <label class="labelBold">
              Please list the names of all medications, including
              over-the-counter and homeopathic/natural remedies, with dosages
              and how often you take it
            </label>
          </div>

          <div formArrayName="medications">
            <div
              *ngFor="
                let medication of memberHealthHistoryForm.get('medications')[
                  'controls'
                ];
                let index = index
              "
            >
              <div class="row" [formGroupName]="index" style="margin-top: 15px">
                <div class="col-md-4">
                  <label class="labelText">Medication Name</label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      placeholder="Medication Name"
                      formControlName="medicationName"
                      name="medicationName"
                    />
                    <mat-error
                      *ngIf="medication.controls['medicationName'].invalid"
                    >
                      Please enter a valid medication name
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <label class="labelText">Dosage</label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      placeholder="Dosage"
                      formControlName="dosage"
                      name="dosage"
                    />
                    <mat-error *ngIf="medication.controls['dosage'].invalid">
                      Please enter a valid dosage
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <label class="labelText">Frequency</label>
                  <mat-form-field appearance="outline">
                    <mat-select
                      formControlName="frequency"
                      placeholder="Frequency"
                    >
                      <mat-option
                        *ngFor="let freq of medicationFrequency"
                        [value]="freq"
                      >
                        {{ freq }}
                      </mat-option>
                    </mat-select>

                    <mat-error *ngIf="medication.controls['frequency'].invalid">
                      Please select a valid frequency
                    </mat-error>
                  </mat-form-field>
                </div>

                <div
                  class="col-md-1"
                  style="display: flex; align-items: center"
                >
                  <button
                    mat-raised-button
                    color="primary"
                    type="button"
                    *ngIf="
                      index ===
                      memberHealthHistoryForm.get('medications')['controls']
                        .length -
                        1
                    "
                    (click)="addNewMedication()"
                  >
                    Add
                  </button>

                  <button
                    mat-raised-button
                    color="warn"
                    type="button"
                    *ngIf="
                      index !=
                      memberHealthHistoryForm.get('medications')['controls']
                        .length -
                        1
                    "
                    (click)="removeMedication(index)"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-md-12"
            style="
              display: flex;
              justify-content: space-between;
              margin-top: 15px;
            "
          >
            <button mat-raised-button matStepperPrevious type="button">
              Back
            </button>

            <button mat-raised-button matStepperNext type="button">Next</button>
          </div>
        </div>
      </mat-step>

      <!-- Allergies -->
      <mat-step label="Allergies">
        <div class="row">
          <div class="col-md-12">
            <label class="labelBold">
              Please list all your allergies and what reaction(s) you have
            </label>
          </div>

          <div formArrayName="allergies">
            <div
              *ngFor="
                let allergy of memberHealthHistoryForm.get('allergies')[
                  'controls'
                ];
                let index = index
              "
            >
              <div class="row" [formGroupName]="index" style="margin-top: 15px">
                <div class="col-md-6">
                  <label class="labelText">Allergy</label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      placeholder="Allergy"
                      formControlName="name"
                      name="name"
                    />
                    <mat-error *ngIf="allergy.controls['name'].invalid">
                      Please enter a valid allergy
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-5">
                  <label class="labelText">Reaction</label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      placeholder="Reaction"
                      formControlName="reaction"
                      name="reaction"
                    />
                    <mat-error *ngIf="allergy.controls['reaction'].invalid">
                      Please enter a valid reaction
                    </mat-error>
                  </mat-form-field>
                </div>

                <div
                  class="col-md-1"
                  style="display: flex; align-items: center"
                >
                  <button
                    mat-raised-button
                    color="primary"
                    type="button"
                    *ngIf="
                      index ===
                      memberHealthHistoryForm.get('allergies')['controls']
                        .length -
                        1
                    "
                    (click)="addNewAllergy()"
                  >
                    Add
                  </button>

                  <button
                    mat-raised-button
                    color="warn"
                    type="button"
                    *ngIf="
                      index !=
                      memberHealthHistoryForm.get('allergies')['controls']
                        .length -
                        1
                    "
                    (click)="removeAllergy(index)"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-md-12"
            style="
              display: flex;
              justify-content: space-between;
              margin-top: 15px;
            "
          >
            <button mat-raised-button matStepperPrevious type="button">
              Back
            </button>

            <button mat-raised-button matStepperNext type="button">Next</button>
          </div>
        </div>
      </mat-step>

      <!-- Medical Conditions -->
      <mat-step label="Medical History">
        <div class="row">
          <div class="col-md-12">
            <label class="labelBold">
              Do you suffer from any of the following medical conditions?
            </label>
          </div>
          <div
            class="col-sm-6 col-md-3"
            *ngFor="let history of memberConditionsList"
          >
            <mat-checkbox
              color="primary"
              class="checkbox-margin"
              [checked]="isMedicalHistoryChecked(history)"
              (change)="selectDeselectMemberHistory($event, history)"
            >
              {{ history.name }}
            </mat-checkbox>
          </div>
          <div
            class="col-md-12"
            style="
              display: flex;
              justify-content: space-between;
              margin-top: 15px;
            "
          >
            <button mat-raised-button matStepperPrevious type="button">
              Back
            </button>

            <button mat-raised-button matStepperNext type="button">Next</button>
          </div>
        </div>
      </mat-step>

      <!-- Family Conditions -->
      <mat-step label="Family Health History">
        <div class="row">
          <div class="col-md-12">
            <label class="labelBold">
              Check if any of your blood relatives had any of the following:
            </label>
          </div>
          <div
            class="col-sm-6 col-md-3"
            *ngFor="let history of familyConditionsList"
          >
            <mat-checkbox
              color="primary"
              class="checkbox-margin"
              [checked]="isFamilyHistoryChecked(history)"
              (change)="selectDeselectFamilyHistory($event, history)"
            >
              {{ history.name }}
            </mat-checkbox>
          </div>
          <div
            class="col-md-12"
            style="display: flex; justify-content: space-between"
          >
            <button mat-raised-button matStepperPrevious type="button">
              Back
            </button>

            <button mat-raised-button matStepperNext type="button">Next</button>
          </div>
        </div>
      </mat-step>

      <!-- Social/Dietary Habits -->
      <mat-step label="Additional Details">
        <div class="row">
          <!-- Smoke -->
          <div class="col-md-12">
            <label class="labelBold"> Do you smoke? </label>

            <mat-radio-group
              formControlName="smoke"
              color="primary"
              (change)="smokeAnswerChanged($event)"
            >
              <mat-radio-button value="Yes"> Yes </mat-radio-button>
              <mat-radio-button value="No">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <div
            class="col-md-12"
            *ngIf="memberHealthHistoryForm.controls['smoke'].value === 'Yes'"
          >
            <label class="labelBold">
              How often do you smoke? <span style="color: red">*</span>
            </label>
            <mat-form-field appearance="outline">
              <input
                matInput
                formControlName="smokeFrequency"
                name="smokeFrequency"
              />
              <mat-error
                *ngIf="
                  memberHealthHistoryForm.controls['smokeFrequency'].invalid
                "
              >
                Please input how often you smoke
              </mat-error>
            </mat-form-field>
          </div>

          <!-- Alcohol -->
          <div class="col-md-12">
            <label class="labelBold"> Do you use alcohol? </label>

            <mat-radio-group
              formControlName="alcohol"
              color="primary"
              (change)="alcoholAnswerChanged($event)"
            >
              <mat-radio-button value="Yes"> Yes </mat-radio-button>
              <mat-radio-button value="No">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <div
            class="col-md-12"
            *ngIf="memberHealthHistoryForm.controls['alcohol'].value === 'Yes'"
          >
            <label class="labelBold">
              How often do you drink? <span style="color: red">*</span>
            </label>
            <mat-form-field appearance="outline">
              <input
                matInput
                formControlName="alcoholFrequency"
                name="alcoholFrequency"
              />

              <mat-error
                *ngIf="
                  memberHealthHistoryForm.controls['alcoholFrequency'].invalid
                "
              >
                Please input how often you drink
              </mat-error>
            </mat-form-field>
          </div>

          <!-- Any Surgeries -->
          <div class="col-md-12">
            <label class="labelBold"> Please list any surgeries </label>
            <mat-form-field appearance="outline">
              <textarea
                matInput
                formControlName="surgeries"
                name="message"
                spellcheck="true"
                rows="5"
              ></textarea>
            </mat-form-field>
          </div>

          <!-- Other Illness -->
          <div class="col-md-12">
            <label class="labelBold"> Please list any illness </label>
            <mat-form-field appearance="outline">
              <textarea
                matInput
                formControlName="illness"
                spellcheck="true"
                rows="5"
              ></textarea>
            </mat-form-field>
          </div>

          <!-- Last Menstrual Cycle -->
          <div class="col-md-12" *ngIf="patient?.gender === 'F'">
            <label class="labelBold"> Last menstrual cycle </label>
            <mat-form-field appearance="outline">
              <input
                matInput
                type="date"
                formControlName="lastMenstrualCycle"
                name="lastMenstrualCycle"
              />
            </mat-form-field>
          </div>

          <div
            class="col-md-12"
            style="display: flex; justify-content: space-between"
          >
            <button mat-raised-button matStepperPrevious type="button">
              Back
            </button>

            <button
              mat-raised-button
              color="primary"
              type="submit"
              [disabled]="processing"
              (click)="submitForm()"
            >
              Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
            </button>
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </form>
</div>
