import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FavoritesState } from '../reducers/favorites.reducers';

export const selectFavoritesState = createFeatureSelector<FavoritesState>('favorites');

export const selectFavoritesPayload = createSelector(
    selectFavoritesState,
    (state: FavoritesState) => state.favoritesPayload
);

export const selectFavoritesLoading = createSelector(
    selectFavoritesState,
    (state: FavoritesState) => state.loading
);

export const selectFavoritesError = createSelector(
    selectFavoritesState,
    (state: FavoritesState) => state.error
);
