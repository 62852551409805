export enum LaneStatus {
    AVAILABLE = 'Available',
    APPLIED = 'Applied'
}

export enum CareLogType {
    GENERAL = 'General',
    CLS = 'Community Living Support',
    CLSFM = 'Community Living Support Family Model',
    ES = 'Employment Services',
    CI = 'Community Integration',
    ILST = 'Independent Living Skills Training',
    CT = 'Community Transportation',
    ESR = 'Employment Services Reports',
}

export enum CareLogSeverity {
    LOWEST = 'Lowest',
    LOW = 'Low',
    MEDIUM = 'Medium',
    HIGH = 'High',
    HIGHEST = 'Highest'
}