import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { DataTablesModel, ResponseModal } from '../../../_models';
import { IAggressionQuestionnaire } from '../../../_models/assessments/aggression/aggressionquestionnaire.model';
@Injectable({ providedIn: 'root' })
export class AggressionQuestionnaireApiService {
  constructor(private httpClient: HttpClient) {}

  getAggressionQuestionnaireDetails(
    aggressionquestionnaireId: string
  ): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/aggressionquestionnaire/` +
        aggressionquestionnaireId
    );
  }

  addAggressionQuestionnaire(
    thisAggressionQuestionnaire: IAggressionQuestionnaire
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/aggressionquestionnaire`,
        thisAggressionQuestionnaire,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  addGuestAggressionQuestionnaire(
    thisAggressionQuestionnaire: IAggressionQuestionnaire
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/guestassessments/aggressionquestionnaire`,
        thisAggressionQuestionnaire,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getAggressionQuestionnaireList(
    patientId: string,
    assessmentId: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('patientId', patientId);
    params = params.append('assessmentId', assessmentId);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/aggressionquestionnairelist`,
      { params: params }
    );
  }
}
