import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { IPersonalityType } from 'src/app/_models/assessments/personality/personalitytype.model';
import {
  PersonalityTypeApiService,
  ToastMessageService,
} from 'src/app/_services/';

@Component({
  selector: 'app-personality-type',
  templateUrl: './personality-type.component.html',
  styleUrls: ['./personality-type.component.css'],
})
export class PersonalityTypeComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public personalityTypeApiService: PersonalityTypeApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) {}
  public riskScore: number;
  public personalityTypeForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  personalityTypeAssessment: IPersonalityType;
  personalityTypeAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.personalityTypeForm, this.errors);

    this.loadPersonalityTypeAssessment();
  }

  loadPersonalityTypeAssessment() {
    this.personalityTypeForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.personalityTypeApiService
        .getPersonalityTypeDetails(this.assessmentId)
        .subscribe((personalityTypeAssessment) => {
          if (personalityTypeAssessment.data) {
            this.personalityTypeForm.controls['q1'].setValue(
              personalityTypeAssessment.data.q1.answer
            );
            this.personalityTypeForm.controls['q2'].setValue(
              personalityTypeAssessment.data.q2.answer
            );
            this.personalityTypeForm.controls['q3'].setValue(
              personalityTypeAssessment.data.q3.answer
            );
            this.personalityTypeForm.controls['q4'].setValue(
              personalityTypeAssessment.data.q4.answer
            );
            this.personalityTypeForm.controls['q5'].setValue(
              personalityTypeAssessment.data.q5.answer
            );
            this.personalityTypeForm.controls['q6'].setValue(
              personalityTypeAssessment.data.q6.answer
            );
            this.personalityTypeForm.controls['q7'].setValue(
              personalityTypeAssessment.data.q7.answer
            );
            this.personalityTypeForm.controls['q8'].setValue(
              personalityTypeAssessment.data.q8.answer
            );
            this.personalityTypeForm.controls['q9'].setValue(
              personalityTypeAssessment.data.q9.answer
            );
            this.personalityTypeForm.controls['q10'].setValue(
              personalityTypeAssessment.data.q10.answer
            );
            this.personalityTypeForm.controls['q11'].setValue(
              personalityTypeAssessment.data.q11.answer
            );
            this.personalityTypeForm.controls['q12'].setValue(
              personalityTypeAssessment.data.q12.answer
            );
            this.personalityTypeForm.controls['q13'].setValue(
              personalityTypeAssessment.data.q13.answer
            );
            this.personalityTypeForm.controls['q14'].setValue(
              personalityTypeAssessment.data.q14.answer
            );
            this.personalityTypeForm.controls['q15'].setValue(
              personalityTypeAssessment.data.q15.answer
            );
            this.personalityTypeForm.controls['q16'].setValue(
              personalityTypeAssessment.data.q16.answer
            );
            this.personalityTypeForm.controls['q17'].setValue(
              personalityTypeAssessment.data.q17.answer
            );
            this.personalityTypeForm.controls['q18'].setValue(
              personalityTypeAssessment.data.q18.answer
            );
            this.personalityTypeForm.controls['q19'].setValue(
              personalityTypeAssessment.data.q19.answer
            );
            this.personalityTypeForm.controls['q20'].setValue(
              personalityTypeAssessment.data.q20.answer
            );
            this.personalityTypeForm.controls['q21'].setValue(
              personalityTypeAssessment.data.q21.answer
            );
            this.personalityTypeForm.controls['q22'].setValue(
              personalityTypeAssessment.data.q22.answer
            );
            this.personalityTypeForm.controls['q23'].setValue(
              personalityTypeAssessment.data.q23.answer
            );
            this.personalityTypeForm.controls['q24'].setValue(
              personalityTypeAssessment.data.q24.answer
            );
            this.personalityTypeForm.controls['q25'].setValue(
              personalityTypeAssessment.data.q25.answer
            );
            this.personalityTypeForm.controls['q26'].setValue(
              personalityTypeAssessment.data.q26.answer
            );
            this.personalityTypeForm.controls['q27'].setValue(
              personalityTypeAssessment.data.q27.answer
            );
            this.personalityTypeForm.controls['q28'].setValue(
              personalityTypeAssessment.data.q28.answer
            );
            this.personalityTypeForm.controls['q29'].setValue(
              personalityTypeAssessment.data.q29.answer
            );
            this.personalityTypeForm.controls['q30'].setValue(
              personalityTypeAssessment.data.q30.answer
            );
            this.personalityTypeForm.controls['q31'].setValue(
              personalityTypeAssessment.data.q31.answer
            );
            this.personalityTypeForm.controls['q32'].setValue(
              personalityTypeAssessment.data.q32.answer
            );
            this.personalityTypeForm.controls['q33'].setValue(
              personalityTypeAssessment.data.q33.answer
            );
            this.personalityTypeForm.controls['q34'].setValue(
              personalityTypeAssessment.data.q34.answer
            );
            this.personalityTypeForm.controls['q35'].setValue(
              personalityTypeAssessment.data.q35.answer
            );
            this.personalityTypeForm.controls['q36'].setValue(
              personalityTypeAssessment.data.q36.answer
            );
            this.personalityTypeForm.controls['q37'].setValue(
              personalityTypeAssessment.data.q37.answer
            );
            this.personalityTypeForm.controls['q38'].setValue(
              personalityTypeAssessment.data.q38.answer
            );
            this.personalityTypeForm.controls['q39'].setValue(
              personalityTypeAssessment.data.q39.answer
            );
            this.personalityTypeForm.controls['q40'].setValue(
              personalityTypeAssessment.data.q40.answer
            );
            this.personalityTypeForm.controls['q41'].setValue(
              personalityTypeAssessment.data.q41.answer
            );
            this.personalityTypeForm.controls['q42'].setValue(
              personalityTypeAssessment.data.q42.answer
            );
            this.personalityTypeForm.controls['q43'].setValue(
              personalityTypeAssessment.data.q43.answer
            );
            this.personalityTypeForm.controls['q44'].setValue(
              personalityTypeAssessment.data.q44.answer
            );
            this.personalityTypeForm.controls['q45'].setValue(
              personalityTypeAssessment.data.q45.answer
            );
            this.personalityTypeForm.controls['q46'].setValue(
              personalityTypeAssessment.data.q46.answer
            );
            this.personalityTypeForm.controls['q47'].setValue(
              personalityTypeAssessment.data.q47.answer
            );
            this.personalityTypeForm.controls['q48'].setValue(
              personalityTypeAssessment.data.q48.answer
            );
            this.personalityTypeForm.controls['q49'].setValue(
              personalityTypeAssessment.data.q49.answer
            );
            this.personalityTypeForm.controls['q50'].setValue(
              personalityTypeAssessment.data.q50.answer
            );
            this.personalityTypeForm.controls['q51'].setValue(
              personalityTypeAssessment.data.q51.answer
            );
            this.personalityTypeForm.controls['q52'].setValue(
              personalityTypeAssessment.data.q52.answer
            );
            this.personalityTypeForm.controls['q53'].setValue(
              personalityTypeAssessment.data.q53.answer
            );
            this.personalityTypeForm.controls['q54'].setValue(
              personalityTypeAssessment.data.q54.answer
            );
            this.personalityTypeForm.controls['q55'].setValue(
              personalityTypeAssessment.data.q55.answer
            );
            this.personalityTypeForm.controls['q56'].setValue(
              personalityTypeAssessment.data.q56.answer
            );
            this.personalityTypeForm.controls['q57'].setValue(
              personalityTypeAssessment.data.q57.answer
            );
            this.personalityTypeForm.controls['q58'].setValue(
              personalityTypeAssessment.data.q58.answer
            );
            this.personalityTypeForm.controls['q59'].setValue(
              personalityTypeAssessment.data.q59.answer
            );
            this.personalityTypeForm.controls['q60'].setValue(
              personalityTypeAssessment.data.q60.answer
            );
            this.personalityTypeForm.controls['q61'].setValue(
              personalityTypeAssessment.data.q61.answer
            );
            this.personalityTypeForm.controls['q62'].setValue(
              personalityTypeAssessment.data.q62.answer
            );
            this.personalityTypeForm.controls['q63'].setValue(
              personalityTypeAssessment.data.q63.answer
            );
            this.personalityTypeForm.controls['q64'].setValue(
              personalityTypeAssessment.data.q64.answer
            );
            this.personalityTypeForm.controls['q65'].setValue(
              personalityTypeAssessment.data.q65.answer
            );
            this.personalityTypeForm.controls['q66'].setValue(
              personalityTypeAssessment.data.q66.answer
            );
            this.personalityTypeForm.controls['q67'].setValue(
              personalityTypeAssessment.data.q67.answer
            );
            this.personalityTypeForm.controls['q68'].setValue(
              personalityTypeAssessment.data.q68.answer
            );
            this.personalityTypeForm.controls['q69'].setValue(
              personalityTypeAssessment.data.q69.answer
            );
            this.personalityTypeForm.controls['q70'].setValue(
              personalityTypeAssessment.data.q70.answer
            );

            this.riskScore = personalityTypeAssessment.data.riskScore;
          }
        });
    }
  }

  onFormSubmit() {
    if (this.personalityTypeForm.invalid) {
      this.personalityTypeForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.personalityTypeAssessment = Object.assign(
      {},
      this.personalityTypeAssessment
    );
    this.personalityTypeAssessment.patientId =
      this.personalityTypeForm.controls['patientId'].value;
    this.personalityTypeAssessment.sessionId =
      this.personalityTypeForm.controls['sessionId'].value;
    this.personalityTypeAssessment.assessmentRequestId =
      this.personalityTypeForm.controls['assessmentRequestId'].value;
    this.personalityTypeAssessment.q1 = {
      answer: this.personalityTypeForm.controls['q1'].value,
    };
    this.personalityTypeAssessment.q2 = {
      answer: this.personalityTypeForm.controls['q2'].value,
    };
    this.personalityTypeAssessment.q3 = {
      answer: this.personalityTypeForm.controls['q3'].value,
    };
    this.personalityTypeAssessment.q4 = {
      answer: this.personalityTypeForm.controls['q4'].value,
    };
    this.personalityTypeAssessment.q5 = {
      answer: this.personalityTypeForm.controls['q5'].value,
    };
    this.personalityTypeAssessment.q6 = {
      answer: this.personalityTypeForm.controls['q6'].value,
    };
    this.personalityTypeAssessment.q7 = {
      answer: this.personalityTypeForm.controls['q7'].value,
    };
    this.personalityTypeAssessment.q8 = {
      answer: this.personalityTypeForm.controls['q8'].value,
    };
    this.personalityTypeAssessment.q9 = {
      answer: this.personalityTypeForm.controls['q9'].value,
    };
    this.personalityTypeAssessment.q10 = {
      answer: this.personalityTypeForm.controls['q10'].value,
    };
    this.personalityTypeAssessment.q11 = {
      answer: this.personalityTypeForm.controls['q11'].value,
    };
    this.personalityTypeAssessment.q12 = {
      answer: this.personalityTypeForm.controls['q12'].value,
    };
    this.personalityTypeAssessment.q13 = {
      answer: this.personalityTypeForm.controls['q13'].value,
    };
    this.personalityTypeAssessment.q14 = {
      answer: this.personalityTypeForm.controls['q14'].value,
    };
    this.personalityTypeAssessment.q15 = {
      answer: this.personalityTypeForm.controls['q15'].value,
    };
    this.personalityTypeAssessment.q16 = {
      answer: this.personalityTypeForm.controls['q16'].value,
    };
    this.personalityTypeAssessment.q17 = {
      answer: this.personalityTypeForm.controls['q17'].value,
    };
    this.personalityTypeAssessment.q18 = {
      answer: this.personalityTypeForm.controls['q18'].value,
    };
    this.personalityTypeAssessment.q19 = {
      answer: this.personalityTypeForm.controls['q19'].value,
    };
    this.personalityTypeAssessment.q20 = {
      answer: this.personalityTypeForm.controls['q20'].value,
    };
    this.personalityTypeAssessment.q21 = {
      answer: this.personalityTypeForm.controls['q21'].value,
    };
    this.personalityTypeAssessment.q22 = {
      answer: this.personalityTypeForm.controls['q22'].value,
    };
    this.personalityTypeAssessment.q23 = {
      answer: this.personalityTypeForm.controls['q23'].value,
    };
    this.personalityTypeAssessment.q24 = {
      answer: this.personalityTypeForm.controls['q24'].value,
    };
    this.personalityTypeAssessment.q25 = {
      answer: this.personalityTypeForm.controls['q25'].value,
    };
    this.personalityTypeAssessment.q26 = {
      answer: this.personalityTypeForm.controls['q26'].value,
    };
    this.personalityTypeAssessment.q27 = {
      answer: this.personalityTypeForm.controls['q27'].value,
    };
    this.personalityTypeAssessment.q28 = {
      answer: this.personalityTypeForm.controls['q28'].value,
    };
    this.personalityTypeAssessment.q29 = {
      answer: this.personalityTypeForm.controls['q29'].value,
    };
    this.personalityTypeAssessment.q30 = {
      answer: this.personalityTypeForm.controls['q30'].value,
    };
    this.personalityTypeAssessment.q31 = {
      answer: this.personalityTypeForm.controls['q31'].value,
    };
    this.personalityTypeAssessment.q32 = {
      answer: this.personalityTypeForm.controls['q32'].value,
    };
    this.personalityTypeAssessment.q33 = {
      answer: this.personalityTypeForm.controls['q33'].value,
    };
    this.personalityTypeAssessment.q34 = {
      answer: this.personalityTypeForm.controls['q34'].value,
    };
    this.personalityTypeAssessment.q35 = {
      answer: this.personalityTypeForm.controls['q35'].value,
    };
    this.personalityTypeAssessment.q36 = {
      answer: this.personalityTypeForm.controls['q36'].value,
    };
    this.personalityTypeAssessment.q37 = {
      answer: this.personalityTypeForm.controls['q37'].value,
    };
    this.personalityTypeAssessment.q38 = {
      answer: this.personalityTypeForm.controls['q38'].value,
    };
    this.personalityTypeAssessment.q39 = {
      answer: this.personalityTypeForm.controls['q39'].value,
    };
    this.personalityTypeAssessment.q40 = {
      answer: this.personalityTypeForm.controls['q40'].value,
    };
    this.personalityTypeAssessment.q41 = {
      answer: this.personalityTypeForm.controls['q41'].value,
    };
    this.personalityTypeAssessment.q42 = {
      answer: this.personalityTypeForm.controls['q42'].value,
    };
    this.personalityTypeAssessment.q43 = {
      answer: this.personalityTypeForm.controls['q43'].value,
    };
    this.personalityTypeAssessment.q44 = {
      answer: this.personalityTypeForm.controls['q44'].value,
    };
    this.personalityTypeAssessment.q45 = {
      answer: this.personalityTypeForm.controls['q45'].value,
    };
    this.personalityTypeAssessment.q46 = {
      answer: this.personalityTypeForm.controls['q46'].value,
    };
    this.personalityTypeAssessment.q47 = {
      answer: this.personalityTypeForm.controls['q47'].value,
    };
    this.personalityTypeAssessment.q48 = {
      answer: this.personalityTypeForm.controls['q48'].value,
    };
    this.personalityTypeAssessment.q49 = {
      answer: this.personalityTypeForm.controls['q49'].value,
    };
    this.personalityTypeAssessment.q50 = {
      answer: this.personalityTypeForm.controls['q50'].value,
    };
    this.personalityTypeAssessment.q51 = {
      answer: this.personalityTypeForm.controls['q51'].value,
    };
    this.personalityTypeAssessment.q52 = {
      answer: this.personalityTypeForm.controls['q52'].value,
    };
    this.personalityTypeAssessment.q53 = {
      answer: this.personalityTypeForm.controls['q53'].value,
    };
    this.personalityTypeAssessment.q54 = {
      answer: this.personalityTypeForm.controls['q54'].value,
    };
    this.personalityTypeAssessment.q55 = {
      answer: this.personalityTypeForm.controls['q55'].value,
    };
    this.personalityTypeAssessment.q56 = {
      answer: this.personalityTypeForm.controls['q56'].value,
    };
    this.personalityTypeAssessment.q57 = {
      answer: this.personalityTypeForm.controls['q57'].value,
    };
    this.personalityTypeAssessment.q58 = {
      answer: this.personalityTypeForm.controls['q58'].value,
    };
    this.personalityTypeAssessment.q59 = {
      answer: this.personalityTypeForm.controls['q59'].value,
    };
    this.personalityTypeAssessment.q60 = {
      answer: this.personalityTypeForm.controls['q60'].value,
    };
    this.personalityTypeAssessment.q61 = {
      answer: this.personalityTypeForm.controls['q61'].value,
    };
    this.personalityTypeAssessment.q62 = {
      answer: this.personalityTypeForm.controls['q62'].value,
    };
    this.personalityTypeAssessment.q63 = {
      answer: this.personalityTypeForm.controls['q63'].value,
    };
    this.personalityTypeAssessment.q64 = {
      answer: this.personalityTypeForm.controls['q64'].value,
    };
    this.personalityTypeAssessment.q65 = {
      answer: this.personalityTypeForm.controls['q65'].value,
    };
    this.personalityTypeAssessment.q66 = {
      answer: this.personalityTypeForm.controls['q66'].value,
    };
    this.personalityTypeAssessment.q67 = {
      answer: this.personalityTypeForm.controls['q67'].value,
    };
    this.personalityTypeAssessment.q68 = {
      answer: this.personalityTypeForm.controls['q68'].value,
    };
    this.personalityTypeAssessment.q69 = {
      answer: this.personalityTypeForm.controls['q69'].value,
    };
    this.personalityTypeAssessment.q70 = {
      answer: this.personalityTypeForm.controls['q70'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.personalityTypeAssessment.accessCode = this.accessCode;

      this.personalityTypeApiService
        .addGuestPersonalityType(this.personalityTypeAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Personality Type Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed adding Personality Type Assessment'
            );
          }
        );
    } else {
      this.personalityTypeApiService
        .addPersonalityType(this.personalityTypeAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Personality Type Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed adding Personality Type Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    q1: [{ type: 'required', message: '1. At a party do you: is required' }],
    q2: [{ type: 'required', message: '2. Are you more: is required' }],
    q3: [{ type: 'required', message: '3. Is it worse to: is required' }],
    q4: [
      {
        type: 'required',
        message: '4. Are you more impressed by: is required',
      },
    ],
    q5: [
      {
        type: 'required',
        message: '5. Are more drawn toward the: is required',
      },
    ],
    q6: [
      { type: 'required', message: '6. Do you prefer to work: is required' },
    ],
    q7: [
      { type: 'required', message: '7. Do you tend to choose: is required' },
    ],
    q8: [{ type: 'required', message: '8. At parties do you: is required' }],
    q9: [
      {
        type: 'required',
        message: '9. Are you more attracted to: is required',
      },
    ],
    q10: [
      {
        type: 'required',
        message: '10. Are you more interested in: is required',
      },
    ],
    q11: [
      {
        type: 'required',
        message: '11. In judging others are you more swayed by: is required',
      },
    ],
    q12: [
      {
        type: 'required',
        message:
          '12. In approaching others is your inclination to be somewhat: is required',
      },
    ],
    q13: [{ type: 'required', message: '13. Are you more: is required' }],
    q14: [
      {
        type: 'required',
        message: '14. Does it bother you more having things: is required',
      },
    ],
    q15: [
      {
        type: 'required',
        message: '15. In your social groups do you: is required',
      },
    ],
    q16: [
      {
        type: 'required',
        message:
          '16. In doing ordinary things are you more likely to: is required',
      },
    ],
    q17: [{ type: 'required', message: '17. Writers should: is required' }],
    q18: [
      {
        type: 'required',
        message: '18. Which appeals to you more: is required',
      },
    ],
    q19: [
      {
        type: 'required',
        message: '19. Are you more comfortable in making: is required',
      },
    ],
    q20: [{ type: 'required', message: '20. Do you want things: is required' }],
    q21: [
      {
        type: 'required',
        message: '21. Would you say you are more: is required',
      },
    ],
    q22: [{ type: 'required', message: '22. In phoning do you: is required' }],
    q23: [{ type: 'required', message: '23. Facts: is required' }],
    q24: [{ type: 'required', message: '24. Are visionaries: is required' }],
    q25: [{ type: 'required', message: '25. Are you more often: is required' }],
    q26: [{ type: 'required', message: '26. Is it worse to be: is required' }],
    q27: [
      {
        type: 'required',
        message: '27. Should one usually let events occur: is required',
      },
    ],
    q28: [
      {
        type: 'required',
        message: '28. Do you feel better about: is required',
      },
    ],
    q29: [{ type: 'required', message: '29. In company do you: is required' }],
    q30: [{ type: 'required', message: '30. Common sense is: is required' }],
    q31: [
      { type: 'required', message: '31. Children often do not: is required' },
    ],
    q32: [
      {
        type: 'required',
        message:
          '32. In making decisions do you feel more comfortable with: is required',
      },
    ],
    q33: [{ type: 'required', message: '33. Are you more: is required' }],
    q34: [
      { type: 'required', message: '34. Which is more admirable: is required' },
    ],
    q35: [
      {
        type: 'required',
        message: '35. Do you put more value on: is required',
      },
    ],
    q36: [
      {
        type: 'required',
        message:
          '36. Does new and non-routine interaction with others: is required',
      },
    ],
    q37: [
      { type: 'required', message: '37. Are you more frequently: is required' },
    ],
    q38: [
      { type: 'required', message: '38. Are you more likely to: is required' },
    ],
    q39: [
      {
        type: 'required',
        message: '39. Which is more satisfying: is required',
      },
    ],
    q40: [
      { type: 'required', message: '40. Which rules you more: is required' },
    ],
    q41: [
      {
        type: 'required',
        message: '41. Are you more comfortable with work that is: is required',
      },
    ],
    q42: [
      { type: 'required', message: '42. Do you tend to look for: is required' },
    ],
    q43: [{ type: 'required', message: '43. Do you prefer: is required' }],
    q44: [{ type: 'required', message: '44. Do you go more by: is required' }],
    q45: [
      {
        type: 'required',
        message: '45. Are you more interested in: is required',
      },
    ],
    q46: [
      {
        type: 'required',
        message: '46. Which is more of a compliment: is required',
      },
    ],
    q47: [
      {
        type: 'required',
        message: '47. Do you value in yourself more that you are: is required',
      },
    ],
    q48: [
      {
        type: 'required',
        message: '48. Do you more often prefer the: is required',
      },
    ],
    q49: [
      {
        type: 'required',
        message: '49. Are you more comfortable: is required',
      },
    ],
    q50: [{ type: 'required', message: '50. Do you: is required' }],
    q51: [
      {
        type: 'required',
        message: '51. Are you more likely to trust your: is required',
      },
    ],
    q52: [{ type: 'required', message: '52. Do you feel: is required' }],
    q53: [
      {
        type: 'required',
        message:
          '53. Which person is more to be complimented one of: is required',
      },
    ],
    q54: [
      {
        type: 'required',
        message: '54. Are you inclined more to be: is required',
      },
    ],
    q55: [
      {
        type: 'required',
        message: '55. Is it preferable mostly to: is required',
      },
    ],
    q56: [
      {
        type: 'required',
        message: '56. In relationships should most things be: is required',
      },
    ],
    q57: [
      {
        type: 'required',
        message: '57. When the phone rings do you: is required',
      },
    ],
    q58: [
      {
        type: 'required',
        message: '58. Do you prize more in yourself: is required',
      },
    ],
    q59: [
      { type: 'required', message: '59. Are you drawn more to: is required' },
    ],
    q60: [
      {
        type: 'required',
        message: '60. Which seems the greater error: is required',
      },
    ],
    q61: [
      {
        type: 'required',
        message: '61. Do you see yourself as basically: is required',
      },
    ],
    q62: [
      {
        type: 'required',
        message: '62. Which situation appeals to you more: is required',
      },
    ],
    q63: [
      {
        type: 'required',
        message: '63. Are you a person that is more: is required',
      },
    ],
    q64: [
      {
        type: 'required',
        message: '64. Are you more inclined to be: is required',
      },
    ],
    q65: [
      {
        type: 'required',
        message: '65. In writings do you prefer: is required',
      },
    ],
    q66: [
      { type: 'required', message: '66. Is it harder for you to: is required' },
    ],
    q67: [
      {
        type: 'required',
        message: '67. Which do you wish more for yourself: is required',
      },
    ],
    q68: [
      {
        type: 'required',
        message: '68. Which is the greater fault: is required',
      },
    ],
    q69: [{ type: 'required', message: '69. Do you prefer the: is required' }],
    q70: [
      { type: 'required', message: '70. Do you tend to be more: is required' },
    ],
  };

  private buildForm() {
    this.personalityTypeForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      q1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q2: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q3: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q4: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q5: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q6: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q7: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q8: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q9: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q10: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q11: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q12: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q13: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q14: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q15: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q16: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q17: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q18: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q19: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q20: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q21: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q22: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q23: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q24: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q25: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q26: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q27: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q28: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q29: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q30: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q31: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q32: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q33: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q34: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q35: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q36: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q37: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q38: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q39: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q40: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q41: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q42: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q43: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q44: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q45: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q46: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q47: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q48: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q49: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q50: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q51: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q52: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q53: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q54: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q55: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q56: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q57: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q58: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q59: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q60: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q61: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q62: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q63: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q64: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q65: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q66: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q67: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q68: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q69: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q70: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.personalityTypeForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.personalityTypeForm) {
      return;
    }
    const form = this.personalityTypeForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
