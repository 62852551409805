import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class OpenAIService {
  constructor(private httpClient: HttpClient) {}

  predictMemberBehavior(
    patientId,
    facilityId,
    date,
    refresh: boolean
  ): Observable<any> {
    let params = new HttpParams();

    params = params.append('patientId', patientId);
    params = params.append('facilityId', facilityId);
    params = params.append('date', date);
    params = params.append('refresh', refresh);

    return this.httpClient.get<any>(
      `${environment.apiUrl}/openai-behaviorprediction-request`,
      { params: params }
    );
  }
}
