import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RouterModule } from '@angular/router';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgProgressModule } from 'ngx-progressbar';
import { CustomDirectivesModule } from 'src/app/_directives/custom-directives.module';
import { AngularMaterialModule } from '../../material-imports';
import { AddEditPatientComponent } from './dialogs/add/add.component';
import { MainComponent } from './main/main.component';
import { PatientsComponent } from './patients.component';
import { PatientRoutes } from './patients.routing';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(PatientRoutes),
    FormsModule,
    AngularMaterialModule,
    DragDropModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300,
    }),
    NgProgressModule,
    NgxGpAutocompleteModule,
    RxReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    CustomDirectivesModule,
    PipesModule,
  ],
  declarations: [PatientsComponent, AddEditPatientComponent, MainComponent],
  exports: [AddEditPatientComponent, PatientsComponent],
  providers: [provideNgxMask()],
})
export class PatientsModule {}
