import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CareLog, IAbcForm, Patient } from 'src/app/_models';
import { CareLogHttpService, ToastMessageService } from 'src/app/_services';
import { IncidentsApiService } from 'src/app/_services/case/incidents.service';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import {
  buildValidationMsgs,
  formatDate,
  mmddyyyyToDate,
} from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-add-edit-behavior-incident-dialog',
  templateUrl: './add-edit-behavior-incident-dialog.component.html',
  styleUrls: ['./add-edit-behavior-incident-dialog.component.css'],
})
export class AddEditBehaviorIncidentDialogComponent implements OnInit {
  public action: string;
  public incidentForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  incident: IAbcForm;
  incidentId: string;
  incidentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  caseId: string;
  patient: Patient;
  patientId: string;
  patientName: string;
  organizationId: string;
  disableFormElements: boolean = false;
  todaysDate = new Date();

  careLog: CareLog;

  constructor(
    public dialogRef: MatDialogRef<AddEditBehaviorIncidentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public incidentsApiService: IncidentsApiService,
    public careLogHttpService: CareLogHttpService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private toastMessageService: ToastMessageService
  ) {
    this.action = data.action;
    this.incidentId = data?.incidentId;
    this.caseId = data?.caseId;
    this.patient = data.patient;
    this.patientId = data?.patientId;
    this.patientName = data?.patientName;
    this.organizationId = data?.organizationId;
    this.incident = data?.incident;
  }

  public ngOnInit() {
    this.buildForm();
    this.errorHandler.handleErrors(this.incidentForm, this.errors);

    if (this.action === 'VIEW') {
      this.disableFormElements = true;

      this.incidentForm.controls['id'].setValue(this.incident.id);
      this.incidentForm.controls['patientId'].setValue(this.incident.patientId);
      this.incidentForm.controls['caseId'].setValue(this.incident.caseId);
      this.incidentForm.controls['severity'].setValue(this.incident.severity);
      this.incidentForm.controls['patientName'].setValue(
        this.incident.patientName
      );
      this.incidentForm.controls['placeOfIncident'].setValue(
        this.incident.placeOfIncident
      );
      if (this.incident.incidentDate) {
        this.incidentForm.controls['incidentDate'].setValue(
          mmddyyyyToDate(this.incident.incidentDate)
        );
      }
      this.incidentForm.controls['incidentStartTime'].setValue(
        this.incident.incidentStartTime
      );
      this.incidentForm.controls['incidentEndTime'].setValue(
        this.incident.incidentEndTime
      );
      this.incidentForm.controls['neededFirstAid'].setValue(
        this.incident.neededFirstAid
      );
      this.incidentForm.controls['firstAidNeededBy'].setValue(
        this.incident.firstAidNeededBy
      );
      this.incidentForm.controls['formalBehaviorPlan'].setValue(
        this.incident.formalBehaviorPlan
      );
      this.incidentForm.controls['individualDoing'].setValue(
        this.incident.individualDoing
      );
      this.incidentForm.controls['othersDoing'].setValue(
        this.incident.othersDoing
      );
      this.incidentForm.controls['staffDoing'].setValue(
        this.incident.staffDoing
      );
      this.incidentForm.controls['environment'].setValue(
        this.incident.environment
      );
      this.incidentForm.controls['behaviorTriggeredBy'].setValue(
        this.incident.behaviorTriggeredBy
      );
      this.incidentForm.controls['comments'].setValue(this.incident.comments);
      this.incidentForm.controls['sb_hitSelf'].setValue(
        this.incident.sb_hitSelf
      );
      this.incidentForm.controls['sb_bitSelf'].setValue(
        this.incident.sb_bitSelf
      );
      this.incidentForm.controls['sb_scratchSelf'].setValue(
        this.incident.sb_scratchSelf
      );
      this.incidentForm.controls['sb_pinchedSelf'].setValue(
        this.incident.sb_pinchedSelf
      );
      this.incidentForm.controls['sb_pulledOwnHair'].setValue(
        this.incident.sb_pulledOwnHair
      );
      this.incidentForm.controls['sb_bangedHead'].setValue(
        this.incident.sb_bangedHead
      );
      this.incidentForm.controls['sb_vomits'].setValue(this.incident.sb_vomits);
      this.incidentForm.controls['sb_rumination'].setValue(
        this.incident.sb_rumination
      );
      this.incidentForm.controls['sb_other'].setValue(this.incident.sb_other);
      this.incidentForm.controls['pa_hitSomeone'].setValue(
        this.incident.pa_hitSomeone
      );
      this.incidentForm.controls['pa_bitSomeone'].setValue(
        this.incident.pa_bitSomeone
      );
      this.incidentForm.controls['pa_scratchSomeone'].setValue(
        this.incident.pa_scratchSomeone
      );
      this.incidentForm.controls['pa_pinchedSomeone'].setValue(
        this.incident.pa_pinchedSomeone
      );
      this.incidentForm.controls['pa_pulledHair'].setValue(
        this.incident.pa_pulledHair
      );
      this.incidentForm.controls['pa_headButted'].setValue(
        this.incident.pa_headButted
      );
      this.incidentForm.controls['pa_pushedPulled'].setValue(
        this.incident.pa_pushedPulled
      );
      this.incidentForm.controls['pa_kickedSomeone'].setValue(
        this.incident.pa_kickedSomeone
      );
      this.incidentForm.controls['pa_threwSomething'].setValue(
        this.incident.pa_threwSomething
      );
      this.incidentForm.controls['va_threatsToHurtOthers'].setValue(
        this.incident.va_threatsToHurtOthers
      );
      this.incidentForm.controls['va_threatsToHurtSelf'].setValue(
        this.incident.va_threatsToHurtSelf
      );
      this.incidentForm.controls['va_threatsToRunAway'].setValue(
        this.incident.va_threatsToRunAway
      );
      this.incidentForm.controls['va_threatsToBreakDestroy'].setValue(
        this.incident.va_threatsToBreakDestroy
      );
      this.incidentForm.controls['va_cursedStaff'].setValue(
        this.incident.va_cursedStaff
      );
      this.incidentForm.controls['va_cursedPeer'].setValue(
        this.incident.va_cursedPeer
      );
      this.incidentForm.controls['va_sexualComments'].setValue(
        this.incident.va_sexualComments
      );
      this.incidentForm.controls['va_other1'].setValue(this.incident.va_other1);
      this.incidentForm.controls['va_other2'].setValue(this.incident.va_other2);
      this.incidentForm.controls['other_refusalToDo'].setValue(
        this.incident.other_refusalToDo
      );
      this.incidentForm.controls['other_stealing'].setValue(
        this.incident.other_stealing
      );
      this.incidentForm.controls['other_runningAway'].setValue(
        this.incident.other_runningAway
      );
      this.incidentForm.controls['other_disrobingInPublic'].setValue(
        this.incident.other_disrobingInPublic
      );
      this.incidentForm.controls['other_inappropriateMasturbation'].setValue(
        this.incident.other_inappropriateMasturbation
      );
      this.incidentForm.controls['other_uncontestedSexualAct'].setValue(
        this.incident.other_uncontestedSexualAct
      );
      this.incidentForm.controls['other_propertyDestruction'].setValue(
        this.incident.other_propertyDestruction
      );
      this.incidentForm.controls['other_toiletAccident'].setValue(
        this.incident.other_toiletAccident
      );
      this.incidentForm.controls['other_pica'].setValue(
        this.incident.other_pica
      );
      this.incidentForm.controls['behavior'].setValue(this.incident.behavior);
      this.incidentForm.controls['consequence_plannedIgnore'].setValue(
        this.incident.consequence_plannedIgnore
      );
      this.incidentForm.controls['consequence_plannedIgnoreDuration'].setValue(
        this.incident.consequence_plannedIgnoreDuration
      );
      this.incidentForm.controls['consequence_verbalCounseling'].setValue(
        this.incident.consequence_verbalCounseling
      );
      this.incidentForm.controls[
        'consequence_verbalCounselingDuration'
      ].setValue(this.incident.consequence_verbalCounselingDuration);
      this.incidentForm.controls['consequence_redirected'].setValue(
        this.incident.consequence_redirected
      );
      this.incidentForm.controls['consequence_redirectedToWhat'].setValue(
        this.incident.consequence_redirectedToWhat
      );
      this.incidentForm.controls['consequence_redirectedToWhere'].setValue(
        this.incident.consequence_redirectedToWhere
      );
      this.incidentForm.controls['consequence_manualRestraint'].setValue(
        this.incident.consequence_manualRestraint
      );
      this.incidentForm.controls['consequence_responseBlock'].setValue(
        this.incident.consequence_responseBlock
      );
      this.incidentForm.controls['consequence_responseBlockDuration'].setValue(
        this.incident.consequence_responseBlockDuration
      );
      this.incidentForm.controls['consequence_cbaApproved'].setValue(
        this.incident.consequence_cbaApproved
      );
      this.incidentForm.controls['consequence_mechanicalRestraint'].setValue(
        this.incident.consequence_mechanicalRestraint
      );
      this.incidentForm.controls['consequence_chemicalRestraint'].setValue(
        this.incident.consequence_chemicalRestraint
      );
      this.incidentForm.controls['consequence_timeOut'].setValue(
        this.incident.consequence_timeOut
      );
      this.incidentForm.controls['consequence_other'].setValue(
        this.incident.consequence_other
      );
      this.incidentForm.controls['consequences'].setValue(
        this.incident.consequences
      );
      this.incidentForm.controls['staffName'].setValue(this.incident.staffName);

      this.incidentForm.disable();
    }
  }

  onAbcFormSubmit() {
    if (this.incidentForm.invalid) {
      this.incidentForm.markAllAsTouched();
      return;
    }
    this.incident = Object.assign({}, this.incident);
    this.incident = Object.assign(this.incident, this.incidentForm.value);
    if (this.incident.incidentDate !== '') {
      this.incident.incidentDate = formatDate(
        new Date(this.incident.incidentDate)
      );
    }

    this.careLog = Object.assign({}, this.careLog);
    this.careLog.id = null;
    this.careLog.patientId = this.patientId;
    this.careLog.organizationId = this.organizationId;
    this.careLog.message = 'Behavioral Incident';
    this.careLog.behavioralIncident = this.incident;

    this.processing = true;
    if (this.action === 'VIEW') {
      this.dialogRef.close();
    } else {
      this.careLogHttpService
        .addCareLogBehavioralIncident(this.careLog)
        .subscribe(
          () => {
            this.processing = false;
            this.dialogRef.close('success');
            this.toastMessageService.displaySuccessMessage(
              'New incident has been added.'
            );
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displaySuccessMessage(
              'Error: Something went wrong while adding the incident.'
            );
          }
        );
    }
  }

  public validation_messages = {
    id: [],
    patientId: [{ type: 'required', message: 'Member Id is required' }],
    caseId: [{ type: 'required', message: 'Case Id is required' }],
    patientName: [{ type: 'required', message: 'Member Name is required' }],
    placeOfIncident: [
      {
        type: 'required',
        message: 'Place where behavior happened is required',
      },
    ],
    incidentDate: [{ type: 'required', message: 'Incident Date is required' }],
    incidentStartTime: [
      { type: 'required', message: 'Incident Start Time is required' },
    ],
    incidentEndTime: [
      { type: 'required', message: 'Incident End Time is required' },
    ],
    neededFirstAid: [
      { type: 'required', message: 'Needed First Aid is required' },
    ],
    firstAidNeededBy: [],
    formalBehaviorPlan: [
      { type: 'required', message: 'Has Formal Behavior Plan is required' },
    ],
    individualDoing: [],
    othersDoing: [],
    staffDoing: [],
    environment: [],
    behaviorTriggeredBy: [],
    comments: [],
    sb_hitSelf: [],
    sb_bitSelf: [],
    sb_scratchSelf: [],
    sb_pinchedSelf: [],
    sb_pulledOwnHair: [],
    sb_bangedHead: [],
    sb_vomits: [],
    sb_rumination: [],
    sb_other: [],
    pa_hitSomeone: [],
    pa_bitSomeone: [],
    pa_scratchSomeone: [],
    pa_pinchedSomeone: [],
    pa_pulledHair: [],
    pa_headButted: [],
    pa_pushedPulled: [],
    pa_kickedSomeone: [],
    pa_threwSomething: [],
    va_threatsToHurtOthers: [],
    va_threatsToHurtSelf: [],
    va_threatsToRunAway: [],
    va_threatsToBreakDestroy: [],
    va_cursedStaff: [],
    va_cursedPeer: [],
    va_sexualComments: [],
    va_other1: [],
    va_other2: [],
    other_refusalToDo: [],
    other_stealing: [],
    other_runningAway: [],
    other_disrobingInPublic: [],
    other_inappropriateMasturbation: [],
    other_uncontestedSexualAct: [],
    other_propertyDestruction: [],
    other_toiletAccident: [],
    other_pica: [],
    behavior: [],
    consequence_plannedIgnore: [],
    consequence_plannedIgnoreDuration: [],
    consequence_verbalCounseling: [],
    consequence_verbalCounselingDuration: [],
    consequence_redirected: [],
    consequence_redirectedToWhat: [],
    consequence_redirectedToWhere: [],
    consequence_manualRestraint: [],
    consequence_responseBlock: [],
    consequence_responseBlockDuration: [],
    consequence_cbaApproved: [],
    consequence_mechanicalRestraint: [],
    consequence_chemicalRestraint: [],
    consequence_timeOut: [],
    consequence_other: [],
    consequences: [{ type: 'required', message: 'Consequences is required' }],
    staffName: [{ type: 'required', message: 'Staff Name is required' }],
  };

  private buildForm() {
    this.incidentForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        this.patientId,
        Validators.compose([Validators.required])
      ),
      caseId: new FormControl(this.caseId),
      severity: new FormControl('', Validators.required),
      patientName: new FormControl(
        this.patientName,
        Validators.compose([Validators.required])
      ),
      patientFirstName: new FormControl(this.patient.firstName),
      patientMiddleName: new FormControl(this.patient.middleName),
      patientLastName: new FormControl(this.patient.lastName),

      placeOfIncident: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      incidentDate: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      incidentStartTime: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      incidentEndTime: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      neededFirstAid: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      firstAidNeededBy: new FormControl('', Validators.compose([])),
      formalBehaviorPlan: new FormControl('', Validators.compose([])),
      individualDoing: new FormControl('', Validators.compose([])),
      othersDoing: new FormControl('', Validators.compose([])),
      staffDoing: new FormControl('', Validators.compose([])),
      environment: new FormControl('', Validators.compose([])),
      behaviorTriggeredBy: new FormControl('', Validators.compose([])),
      comments: new FormControl('', Validators.compose([])),
      sb_hitSelf: new FormControl('', Validators.compose([])),
      sb_bitSelf: new FormControl('', Validators.compose([])),
      sb_scratchSelf: new FormControl('', Validators.compose([])),
      sb_pinchedSelf: new FormControl('', Validators.compose([])),
      sb_pulledOwnHair: new FormControl('', Validators.compose([])),
      sb_bangedHead: new FormControl('', Validators.compose([])),
      sb_vomits: new FormControl('', Validators.compose([])),
      sb_rumination: new FormControl('', Validators.compose([])),
      sb_other: new FormControl('', Validators.compose([])),
      pa_hitSomeone: new FormControl('', Validators.compose([])),
      pa_bitSomeone: new FormControl('', Validators.compose([])),
      pa_scratchSomeone: new FormControl('', Validators.compose([])),
      pa_pinchedSomeone: new FormControl('', Validators.compose([])),
      pa_pulledHair: new FormControl('', Validators.compose([])),
      pa_headButted: new FormControl('', Validators.compose([])),
      pa_pushedPulled: new FormControl('', Validators.compose([])),
      pa_kickedSomeone: new FormControl('', Validators.compose([])),
      pa_threwSomething: new FormControl('', Validators.compose([])),
      va_threatsToHurtOthers: new FormControl('', Validators.compose([])),
      va_threatsToHurtSelf: new FormControl('', Validators.compose([])),
      va_threatsToRunAway: new FormControl('', Validators.compose([])),
      va_threatsToBreakDestroy: new FormControl('', Validators.compose([])),
      va_cursedStaff: new FormControl('', Validators.compose([])),
      va_cursedPeer: new FormControl('', Validators.compose([])),
      va_sexualComments: new FormControl('', Validators.compose([])),
      va_other1: new FormControl('', Validators.compose([])),
      va_other2: new FormControl('', Validators.compose([])),
      other_refusalToDo: new FormControl('', Validators.compose([])),
      other_stealing: new FormControl('', Validators.compose([])),
      other_runningAway: new FormControl('', Validators.compose([])),
      other_disrobingInPublic: new FormControl('', Validators.compose([])),
      other_inappropriateMasturbation: new FormControl(
        '',
        Validators.compose([])
      ),
      other_uncontestedSexualAct: new FormControl('', Validators.compose([])),
      other_propertyDestruction: new FormControl('', Validators.compose([])),
      other_toiletAccident: new FormControl('', Validators.compose([])),
      other_pica: new FormControl('', Validators.compose([])),
      behavior: new FormControl('', Validators.compose([])),
      consequence_plannedIgnore: new FormControl('', Validators.compose([])),
      consequence_plannedIgnoreDuration: new FormControl(
        '',
        Validators.compose([])
      ),
      consequence_verbalCounseling: new FormControl('', Validators.compose([])),
      consequence_verbalCounselingDuration: new FormControl(
        '',
        Validators.compose([])
      ),
      consequence_redirected: new FormControl('', Validators.compose([])),
      consequence_redirectedToWhat: new FormControl('', Validators.compose([])),
      consequence_redirectedToWhere: new FormControl(
        '',
        Validators.compose([])
      ),
      consequence_manualRestraint: new FormControl('', Validators.compose([])),
      consequence_responseBlock: new FormControl('', Validators.compose([])),
      consequence_responseBlockDuration: new FormControl(
        '',
        Validators.compose([])
      ),
      consequence_cbaApproved: new FormControl('', Validators.compose([])),
      consequence_mechanicalRestraint: new FormControl(
        '',
        Validators.compose([])
      ),
      consequence_chemicalRestraint: new FormControl(
        '',
        Validators.compose([])
      ),
      consequence_timeOut: new FormControl('', Validators.compose([])),
      consequence_other: new FormControl('', Validators.compose([])),
      consequences: new FormControl('', Validators.compose([])),
      staffName: new FormControl('', Validators.compose([])),
    });
    this.incidentForm.valueChanges.subscribe(() => this.onValueChanged());
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (this.incidentForm.controls['neededFirstAid'].value === 'No') {
      if (
        this.incidentForm.controls['firstAidNeededBy'].value &&
        this.incidentForm.controls['firstAidNeededBy'].value != ''
      ) {
        this.incidentForm.controls['firstAidNeededBy'].setValue('');
      }
    }

    if (!this.incidentForm.controls['consequence_plannedIgnore'].value) {
      if (this.incidentForm.controls['consequence_plannedIgnore'].value != '') {
        this.incidentForm.controls[
          'consequence_plannedIgnoreDuration'
        ].setValue('');
      }
    }

    if (!this.incidentForm.controls['consequence_verbalCounseling'].value) {
      if (
        this.incidentForm.controls['consequence_verbalCounselingDuration']
          .value != ''
      ) {
        this.incidentForm.controls[
          'consequence_verbalCounselingDuration'
        ].setValue('');
      }
    }

    if (!this.incidentForm.controls['consequence_redirected'].value) {
      if (
        this.incidentForm.controls['consequence_redirectedToWhat'].value != ''
      ) {
        this.incidentForm.controls['consequence_redirectedToWhat'].setValue('');
      }
      if (
        this.incidentForm.controls['consequence_redirectedToWhere'].value != ''
      ) {
        this.incidentForm.controls['consequence_redirectedToWhere'].setValue(
          ''
        );
      }
    }

    if (!this.incidentForm.controls['consequence_responseBlock'].value) {
      if (
        this.incidentForm.controls['consequence_responseBlockDuration'].value !=
        ''
      ) {
        this.incidentForm.controls[
          'consequence_responseBlockDuration'
        ].setValue('');
      }
    }

    if (!this.incidentForm) {
      return;
    }
    const form = this.incidentForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }

  showRedirectedOptions() {
    var redirected = this.incidentForm.controls['consequence_redirected'].value;
    if (!redirected) {
      this.incidentForm.controls['consequence_redirectedToWhat'].setValue('');
      this.incidentForm.controls['consequence_redirectedToWhere'].setValue('');
    }
    return redirected;
  }
}
