<!-- Group Level Notes -->
<div class="row" style="margin-top: 25px">
  <!-- Add button If Note has not been started-->
  <div
    class="col-md-12"
    style="text-align: right"
    *ngIf="
      hasClinicalNotesAddAccess &&
      sessionDetails?.status === 'Active' &&
      !sessionDetails?.clinicalNoteId
    "
  >
    <button
      type="button"
      mat-raised-button
      color="warn"
      (click)="addNewGroupNote()"
    >
      + Group Note
    </button>
  </div>

  <!-- Note not started Disclaimer -->
  <div class="col-md-12" *ngIf="!sessionDetails?.clinicalNoteId">
    <mat-error> Note has not been started yet </mat-error>
  </div>

  <!-- Note name and font size option -->
  <div class="col-md-8" style="display: flex; align-items: center">
    <!-- Header Note Tpe -->
    <label
      class="labelHeader justifyCenterContainer"
      *ngIf="sessionDetails?.clinicalNoteId"
    >
      Note - {{ sessionDetails?.clinicalNoteTypeName }}

      <button
        *ngIf="
          sessionDetails?.clinicalNoteId && sessionDetails?.status === 'Active'
        "
        mat-icon-button
        type="button"
        matTooltip="Change Note Type"
        id="changeButton"
        style="opacity: 0.3"
        (click)="addNewGroupNote()"
      >
        <mat-icon> change_circle </mat-icon>
      </button>
    </label>

    <!-- Current Note Heading and details -->
    <!-- Handle Font Size -->
    <label *ngIf="sessionDetails?.clinicalNoteId">
      <div class="justifyCenterContainer">
        <button
          class="decreaseFontSizeButton"
          mat-icon-button
          type="button"
          (click)="handleFontSizeChange('decrease')"
        >
          <mat-icon>remove_circle</mat-icon>
        </button>
        Font
        <button
          class="increaseFontSizeButton"
          mat-icon-button
          type="button"
          (click)="handleFontSizeChange('increase')"
        >
          <mat-icon>add_circle</mat-icon>
        </button>
      </div>
    </label>
  </div>
  <!-- Load from last -->
  <div
    class="col-md-4"
    style="text-align: right"
    *ngIf="
      sessionDetails?.clinicalNoteId && sessionDetails?.status === 'Active'
    "
  >
    <button
      mat-raised-button
      type="button"
      style="color: #2196f3"
      class="mat-elevation-z8"
      (click)="loadLastNote()"
    >
      <mat-icon color="primary">content_copy</mat-icon> Load Last Note
    </button>
  </div>

  <!-- Now display all the note sections -->
  <div
    class="col-md-12"
    *ngIf="sessionDetails?.clinicalNoteId && editor.length > 0"
  >
    <div class="row">
      <!-- Progress Bar -->
      <div class="col-md-12">
        <mat-progress-bar
          mode="indeterminate"
          *ngIf="isLoadingNGXEditors"
        ></mat-progress-bar>
      </div>

      <!-- Notes with NGX Editor Bar -->
      <!-- Looping through all the editor or form controls -->
      <form [formGroup]="clinicalNoteForm">
        <div class="row">
          <!-- Left Side is for Group Level Notes -->
          <div [class]="tabPanelOpen ? 'col-md-6 col-xl-8' : 'col-md-12'">
            <div [class]="tabPanelOpen ? 'row maxHeightContatiner' : 'row'">
              <div
                [class]="
                  selectedSectionName === section.name
                    ? 'highlitedBox col-md-12'
                    : ' col-md-12'
                "
                *ngFor="
                  let section of sessionDetails?.sessionNotes?.sections;
                  let i = index
                "
                style="margin-bottom: 8px"
              >
                <!-- Note Section header -->
                <div class="sectionHeader">
                  <label style="margin-top: auto">
                    {{ section.name }}
                  </label>
                  <button
                    mat-button
                    color="primary"
                    type="button"
                    *ngIf="
                      selectedSectionName === section.name &&
                      hasChangesMap[section.id]
                    "
                  >
                    <mat-icon>autorenew</mat-icon> Saving
                  </button>
                  <button
                    mat-button
                    color="primary"
                    type="button"
                    *ngIf="
                      selectedSectionName === section.name &&
                      hadChangesEarlier[section.id] &&
                      autosaved
                    "
                  >
                    Auto Saved
                  </button>

                  <!-- Tab panel selection -->
                  <div *ngIf="sessionDetails.status === 'Active'">
                    <button
                      mat-button
                      color="primary"
                      type="button"
                      (click)="
                        openTabPanel(
                          'Text Shortcuts',
                          section.id,
                          section.name,
                          i
                        )
                      "
                    >
                      Text Shortcuts
                    </button>
                    <button
                      mat-button
                      color="primary"
                      type="button"
                      (click)="
                        openTabPanel('Templates', section.id, section.name, i)
                      "
                    >
                      Templates
                    </button>
                  </div>
                </div>

                <!-- Editor-->
                <div class="NgxEditor__Wrapper">
                  <!-- Editor Header -->
                  <ngx-editor-menu
                    [editor]="editor[i]"
                    [toolbar]="toolbar"
                    *ngIf="sessionDetails?.status === 'Active'"
                  >
                  </ngx-editor-menu>
                  <!-- Editor  -->
                  <ngx-editor
                    [editor]="editor[i]"
                    [formControlName]="section.id"
                    spellcheck="true"
                    (click)="
                      editorClicked($event, section.id, section.name, i);
                      hasChangesMap[section.id] = false
                    "
                    (ngModelChange)="onEditorChange($event, section.id)"
                  >
                  </ngx-editor>
                </div>
              </div>
            </div>
          </div>

          <!-- Text Shortcut box -->
          <div
            *ngIf="tabPanelOpen"
            class="col-md-6 col-xl-4 mobileSectionView maxHeightContatiner"
          >
            <!-- General Card to handle shortcut and templates -->
            <app-clinical-shortcut-templates
              [activeTab]="activeTab"
              [selectedSectionName]="selectedSectionName"
              (shortCutResponse)="handleShortCutResponse($event)"
              (templateResponse)="handleTemplateResponse($event)"
              (closeCardTab)="closePanel()"
            >
            </app-clinical-shortcut-templates>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
