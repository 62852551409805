<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle *ngIf="action === 'ADD'">Add New Alert</p>
    <p matDialogTitle *ngIf="action === 'EDIT'">Edit Alert</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="memberAlertForm">
    <div class="row">
      <div class="col-md-12">
        <label class="labelText">
          Alert Description <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <textarea
            matInput
            placeholder="Alert ..."
            formControlName="documentName"
            name="documentName"
            spellcheck="true"
            required
          ></textarea>
          <mat-error *ngIf="memberAlertForm.controls['documentName'].invalid">
            Please provide the alert description
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12" style="text-align: right">
        <button
          color="primary"
          mat-raised-button
          type="button"
          (click)="submitForm()"
          [disabled]="memberAlertForm.invalid || isProcessing"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="isProcessing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
