export interface PermissionState {
    reloadPermission: boolean;

    payload: string[];
    processingRequest: boolean;
    processingError: Error;
}

export const initialPermissionState: PermissionState = {
    reloadPermission: undefined,

    payload: undefined,
    processingRequest: false,
    processingError: undefined,
}