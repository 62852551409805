import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { IAces } from 'src/app/_models/assessments/relationship-family/aces.model';
import {
  ToastMessageService
} from 'src/app/_services/';
import {
  AcesApiService
} from 'src/app/_services/assessments/relationship-family/aces.service';

@Component({
  selector: 'app-aces-assessment-dialog',
  templateUrl: './aces-assessment-dialog.component.html',
  styleUrls: ['./aces-assessment-dialog.component.css']
})
export class AcesAssessmentDialogComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public acesAssessmentApiService: AcesApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public acesAssessmentForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  acesAssessment: IAces;
  acesAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.acesAssessmentForm, this.errors);

    this.loadAces();
  }

  loadAces() {
    this.acesAssessmentForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.acesAssessmentApiService
        .getAcesDetails(this.assessmentId)
        .subscribe((acesAssessment) => {
          if (acesAssessment.data) {
            this.acesAssessmentForm.controls['verbalOrPhysicalFear'].setValue(acesAssessment.data.verbalOrPhysicalFear.answer);
            this.acesAssessmentForm.controls['physicalAbuseMarks'].setValue(acesAssessment.data.physicalAbuseMarks.answer);
            this.acesAssessmentForm.controls['sexualTouchOrAssault'].setValue(acesAssessment.data.sexualTouchOrAssault.answer);
            this.acesAssessmentForm.controls['lackFamilyLoveSupport'].setValue(acesAssessment.data.lackFamilyLoveSupport.answer);
            this.acesAssessmentForm.controls['neglectOrCareLack'].setValue(acesAssessment.data.neglectOrCareLack.answer);
            this.acesAssessmentForm.controls['parentalLoss'].setValue(acesAssessment.data.parentalLoss.answer);
            this.acesAssessmentForm.controls['motherAbuse'].setValue(acesAssessment.data.motherAbuse.answer);
            this.acesAssessmentForm.controls['substanceAbuseHome'].setValue(acesAssessment.data.substanceAbuseHome.answer);
            this.acesAssessmentForm.controls['familyMentalIllOrSuicide'].setValue(acesAssessment.data.familyMentalIllOrSuicide.answer);
            this.acesAssessmentForm.controls['familyMemberPrison'].setValue(acesAssessment.data.familyMemberPrison.answer);

            this.riskScore = acesAssessment.data.riskScore;
          }
        });
    }
  }
  onAcesFormSubmit() {
    if (this.acesAssessmentForm.invalid) {
      this.acesAssessmentForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.acesAssessment = Object.assign({}, this.acesAssessment);
    this.acesAssessment.patientId =
      this.acesAssessmentForm.controls['patientId'].value;
    this.acesAssessment.sessionId =
      this.acesAssessmentForm.controls['sessionId'].value;
    this.acesAssessment.assessmentRequestId =
      this.acesAssessmentForm.controls['assessmentRequestId'].value;

    this.acesAssessment.verbalOrPhysicalFear = {
      answer:
        this.acesAssessmentForm.controls['verbalOrPhysicalFear'].value,
    };
    this.acesAssessment.physicalAbuseMarks = {
      answer:
        this.acesAssessmentForm.controls['physicalAbuseMarks'].value,
    };
    this.acesAssessment.sexualTouchOrAssault = {
      answer:
        this.acesAssessmentForm.controls['sexualTouchOrAssault'].value,
    };
    this.acesAssessment.lackFamilyLoveSupport = {
      answer:
        this.acesAssessmentForm.controls['lackFamilyLoveSupport'].value,
    };
    this.acesAssessment.neglectOrCareLack = {
      answer:
        this.acesAssessmentForm.controls['neglectOrCareLack'].value,
    };
    this.acesAssessment.parentalLoss = {
      answer:
        this.acesAssessmentForm.controls['parentalLoss'].value,
    };
    this.acesAssessment.motherAbuse = {
      answer:
        this.acesAssessmentForm.controls['motherAbuse'].value,
    };
    this.acesAssessment.substanceAbuseHome = {
      answer:
        this.acesAssessmentForm.controls['substanceAbuseHome'].value,
    };
    this.acesAssessment.familyMentalIllOrSuicide = {
      answer:
        this.acesAssessmentForm.controls['familyMentalIllOrSuicide'].value,
    };
    this.acesAssessment.familyMemberPrison = {
      answer:
        this.acesAssessmentForm.controls['familyMemberPrison'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.acesAssessment.accessCode = this.accessCode;

      this.acesAssessmentApiService
        .addGuestAces(this.acesAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Core 10 Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Core 10 Assessment'
            );
          }
        );
    } else {
      this.acesAssessmentApiService
        .addAces(this.acesAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Core 10 Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Core 10 Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    'verbalOrPhysicalFear': [
      { type: 'required', message: '1. Did a parent or other adult in the household often or very often Swear at you, insult you, put you down, or humiliate you? Or Act in a way that made you afraid that you might be physically hurt? is required' },
    ],
    'physicalAbuseMarks': [
      { type: 'required', message: '2. Did a parent or other adult in the household often or very often… Push, grab, slap, or throw something at you? Or Ever hit you so hard that you had marks or were injured? is required' },
    ],
    'sexualTouchOrAssault': [
      { type: 'required', message: '3. Did an adult or person at least 5 years older than you ever…Touch or fondle you or have you touch their body in a sexual way? Or Attempt or actually have oral or anal intercourse with you? is required' },
    ],
    'lackFamilyLoveSupport': [
      { type: 'required', message: '4. Did you often or very often feel that …No one in your family loved you or thought you were important or special? Or Your family didn\'t look out for each other, feel close to each other, or support each other? is required' },
    ],
    'neglectOrCareLack': [
      { type: 'required', message: '5. Did you often or very often feel that …You didn\'t have enough to eat, had to wear dirty clothes, and had no one to protect you? or Your parents were too drunk or high to take care of you or take you to the doctor if you needed it? is required' },
    ],
    'parentalLoss': [
      { type: 'required', message: '6. Was a biological parent ever lost to you through divorced, abandonment, or other reason? is required' },
    ],
    'motherAbuse': [
      { type: 'required', message: '7. Was your mother or stepmother:Often or very often pushed, grabbed, slapped, or had something thrown at her? Or Sometimes, often, or very often kicked, bitten, hit with a fist, or hit with something hard? or Ever repeatedly hit over at least a few minutes or threatened with a gun or knife? is required' },
    ],
    'substanceAbuseHome': [
      { type: 'required', message: '8. Did you live with anyone who was a problem drinker or alcoholic or who used street drugs? is required' },
    ],
    'familyMentalIllOrSuicide': [
      { type: 'required', message: '9. Was a household member depressed or mentally ill? Or Did a household member attempt suicide? is required' },
    ],
    'familyMemberPrison': [
      { type: 'required', message: '10. Did a household member go to prison? is required' },
    ],
  };

  private buildForm() {
    this.acesAssessmentForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      verbalOrPhysicalFear: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      physicalAbuseMarks: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      sexualTouchOrAssault: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      lackFamilyLoveSupport: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      neglectOrCareLack: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      parentalLoss: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      motherAbuse: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      substanceAbuseHome: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      familyMentalIllOrSuicide: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      familyMemberPrison: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
    });
    this.acesAssessmentForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.acesAssessmentForm) {
      return;
    }
    const form = this.acesAssessmentForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
