<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Vanderbilt ADHD Diagnostic Rating Scale (VADRS)</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-12 text-center">
      <button class="btn btn-primary" mat-raised-button *ngIf="inattentive">
        Inattentive
      </button>
      <button class="btn btn-primary" mat-raised-button *ngIf="hyperactive">
        Hyperactive/impulsive
      </button>
      <button
        class="btn btn-primary"
        mat-raised-button
        *ngIf="inattentive && hyperactive"
      >
        Combined Inattention/Hyperactivity
      </button>
      <button class="btn btn-primary" mat-raised-button *ngIf="defiant">
        Oppositional defiant disorder (ODD)
      </button>
      <button class="btn btn-primary" mat-raised-button *ngIf="conduct">
        Conduct Disorder
      </button>
      <button class="btn btn-primary" mat-raised-button *ngIf="depression">
        Anxiety/Depression
      </button>
    </div>
  </div>

  <form
    [formGroup]="adhdDiagnosisRatingForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >1. Does not pay attention to details or makes careless mistakes
            with, for example, homework</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q1">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >2. Has difficulty keeping attention to what needs to be done</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q2">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >3. Does not seem to listen when spoken to directly</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q3">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >4. Does not follow through when given directions and fails to
            finish activities (not due to refusal or failure to
            understand)</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q4">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >5. Has difficulty organizing tasks and activities</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q5">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >6. Avoids, dislikes, or does not want to start tasks that require
            ongoing mental effort</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q6">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >7. Loses things necessary for tasks or activities (toys,
            assignments, pencils, or books)</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q7">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >8. Is easily distracted by noises or other stimuli</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q8">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >9. Is forgetful in daily activities</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q9">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >10. Fidgets with hands or feet or squirms in seat</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q10">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >11. Leaves seat when remaining seated is expected</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q11">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >12. Runs about or climbs too much when remaining seated is
            expected</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q12">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >13. Has difficulty playing or beginning quiet play
            activities</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q13">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >14. Is “on the go” or often acts as if “driven by a motor”</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q14">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after">15. Talks too much</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q15">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >16. Blurts out answers before questions have been completed</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q16">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >17. Has difficulty waiting his or her turn</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q17">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >18. Interrupts or intrudes in on others’ conversations and/or
            activities</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q18">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after">19. Argues with adults</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q19">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after">20. Loses temper</label>
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q20">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >21. Actively defies or refuses to go along with adults’ requests or
            rules</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q21">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >22. Deliberately annoys people</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q22">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >23. Blames others for his or her mistakes or misbehaviors</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q23">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >24. Is touchy or easily annoyed by others</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q24">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >25. Is angry or resentful</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q25">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >26. Is spiteful and wants to get even</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q26">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >27. Bullies, threatens, or intimidates others</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q27">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >28. Starts physical fights</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q28">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >29. Lies to get out of trouble or to avoid obligations (i.e.,“cons”
            others)</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q29">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >30. Is truant from school (skips school) without permission</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q30">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >31. Is physically cruel to people</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q31">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >32. Has stolen things that have value</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q32">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >33. Deliberately destroys others’ property</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q33">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >34. Has used a weapon that can cause serious harm (bat, knife,
            brick, gun)</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q34">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >35. Is physically cruel to animals</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q35">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >36. Has deliberately set fires to cause damage</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q36">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >37. Has broken into someone else's home, business, or car</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q37">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >38. Has stayed out at night without permission</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q38">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >39. Has run away from home overnight</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q39">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >40. Has forced someone into sexual activity</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q40">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >41. Is fearful, anxious, or worried</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q41">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >42. Is afraid to try new things for fear of making mistakes</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q42">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >43. Feels worthless or inferior</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q43">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >44. Blames self for problems, feels guilty</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q44">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >45. Feels lonely, unwanted, or unloved; complains that “no one
            loves him or her”</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q45">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >46. Is sad, unhappy, or depressed</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q46">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >47. Is self-conscious or easily embarrassed</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q47">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Occasionally"
                >Occasionally</mat-button-toggle
              >
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Very Often"
                >Very Often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >48. Overall school performance</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="q48">
              <mat-button-toggle value="Excellent">Excellent</mat-button-toggle>
              <mat-button-toggle value="Above Average"
                >Above Average</mat-button-toggle
              >
              <mat-button-toggle value="Average">Average</mat-button-toggle>
              <mat-button-toggle value="Somewhat of a Problem"
                >Somewhat of a Problem</mat-button-toggle
              >
              <mat-button-toggle value="Problematic"
                >Problematic</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after">49. Reading</label>
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="q49">
              <mat-button-toggle value="Excellent">Excellent</mat-button-toggle>
              <mat-button-toggle value="Above Average"
                >Above Average</mat-button-toggle
              >
              <mat-button-toggle value="Average">Average</mat-button-toggle>
              <mat-button-toggle value="Somewhat of a Problem"
                >Somewhat of a Problem</mat-button-toggle
              >
              <mat-button-toggle value="Problematic"
                >Problematic</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after">50. Writing</label>
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="q50">
              <mat-button-toggle value="Excellent">Excellent</mat-button-toggle>
              <mat-button-toggle value="Above Average"
                >Above Average</mat-button-toggle
              >
              <mat-button-toggle value="Average">Average</mat-button-toggle>
              <mat-button-toggle value="Somewhat of a Problem"
                >Somewhat of a Problem</mat-button-toggle
              >
              <mat-button-toggle value="Problematic"
                >Problematic</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after">51. Mathematics</label>
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="q51">
              <mat-button-toggle value="Excellent">Excellent</mat-button-toggle>
              <mat-button-toggle value="Above Average"
                >Above Average</mat-button-toggle
              >
              <mat-button-toggle value="Average">Average</mat-button-toggle>
              <mat-button-toggle value="Somewhat of a Problem"
                >Somewhat of a Problem</mat-button-toggle
              >
              <mat-button-toggle value="Problematic"
                >Problematic</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >52. Relationship with parents</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="q52">
              <mat-button-toggle value="Excellent">Excellent</mat-button-toggle>
              <mat-button-toggle value="Above Average"
                >Above Average</mat-button-toggle
              >
              <mat-button-toggle value="Average">Average</mat-button-toggle>
              <mat-button-toggle value="Somewhat of a Problem"
                >Somewhat of a Problem</mat-button-toggle
              >
              <mat-button-toggle value="Problematic"
                >Problematic</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >53. Relationship with siblings</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="q53">
              <mat-button-toggle value="Excellent">Excellent</mat-button-toggle>
              <mat-button-toggle value="Above Average"
                >Above Average</mat-button-toggle
              >
              <mat-button-toggle value="Average">Average</mat-button-toggle>
              <mat-button-toggle value="Somewhat of a Problem"
                >Somewhat of a Problem</mat-button-toggle
              >
              <mat-button-toggle value="Problematic"
                >Problematic</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >54. Relationship with peers</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="q54">
              <mat-button-toggle value="Excellent">Excellent</mat-button-toggle>
              <mat-button-toggle value="Above Average"
                >Above Average</mat-button-toggle
              >
              <mat-button-toggle value="Average">Average</mat-button-toggle>
              <mat-button-toggle value="Somewhat of a Problem"
                >Somewhat of a Problem</mat-button-toggle
              >
              <mat-button-toggle value="Problematic"
                >Problematic</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >55. Participation in organized activities</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="q55">
              <mat-button-toggle value="Excellent">Excellent</mat-button-toggle>
              <mat-button-toggle value="Above Average"
                >Above Average</mat-button-toggle
              >
              <mat-button-toggle value="Average">Average</mat-button-toggle>
              <mat-button-toggle value="Somewhat of a Problem"
                >Somewhat of a Problem</mat-button-toggle
              >
              <mat-button-toggle value="Problematic"
                >Problematic</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          adhdDiagnosisRatingForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!adhdDiagnosisRatingForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
