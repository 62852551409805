<mat-card-title>Member Diagnosis</mat-card-title>
<mat-divider></mat-divider>

<div style="margin-top: 20px; padding: 10px">
  <div class="row">
    <div class="col-md-12" style="
        display: flex;
        flex-wrap: wrap;
        justify-content: right;
        column-gap: 15px;
        row-gap: 10px;
        margin-bottom: 15px;
      ">
      <button mat-raised-button [matMenuTriggerFor]="sortMenu">
        <span style="white-space: break-spaces; color: #3ac47d" *ngIf="status === 'Active'">
          Status: Active
        </span>

        <span style="white-space: break-spaces; color: #d92550" *ngIf="status === 'Inactive'">
          Status: Inactive
        </span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>

      <mat-menu #sortMenu="matMenu">
        <button mat-menu-item style="font-weight: 400; font-size: 12px; width: 100%"
          (click)="filterOptionChanged('Active')">
          Active
        </button>
        <button mat-menu-item style="font-weight: 400; font-size: 12px; width: 100%"
          (click)="filterOptionChanged('Inactive')">
          Inactive
        </button>
      </mat-menu>

      <button *ngIf="hasDiagnosisAddAccess" mat-raised-button color="warn" (click)="addDiagnosis()">
        + Add Diagnosis
      </button>
    </div>
  </div>

  <div *ngIf="data.total === 0 && !isLoadingResults">
    <mat-error> No {{ status }} diagnosis found </mat-error>
  </div>

  <mat-progress-bar mode="indeterminate" *ngIf="isLoadingResults"></mat-progress-bar>

  <div class="mat-elevation-z8" *ngIf="data.total > 0">
    <table mat-table [dataSource]="data.items" matSort matSortDisableClear>
      <ng-container matColumnDef="icdCode">
        <th mat-header-cell *matHeaderCellDef>Icd Code</th>
        <td mat-cell *matCellDef="let row" style="font-weight: 500">
          {{ row.icdCode }}
        </td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let row">{{ row.shortDesc }}</td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let row">
          <span *ngIf="row?.status === 'Active'" style="color: #3ac47d; font-weight: 900">
            {{ row?.status }}
          </span>
          <span *ngIf="row?.status != 'Active'" style="color: #d92550; font-weight: 900">
            {{ row?.status }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="statusDate">
        <th mat-header-cell *matHeaderCellDef>Status Date</th>
        <td mat-cell *matCellDef="let row">
          {{ row.modifiedDate | date : 'M/d/y' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="text-align: right">
          Action
        </th>
        <td mat-cell *matCellDef="let row; let i = index" style="text-align: right">
          <button mat-raised-button [matMenuTriggerFor]="menu" class="btn btn-just-icon btn-white btn-fab btn-round">
            <i class="material-icons text_align-center">more_vert</i>
          </button>
          <mat-menu #menu="matMenu">
            <button *ngIf="hasDiagnosisEditAccess" mat-menu-item (click)="editDiagnosis(row)">
              <mat-icon style="color: #f39121"> edit </mat-icon>
              <span>Edit</span>
            </button>
            <button *ngIf="hasDiagnosisDeleteAccess" mat-menu-item (click)="deleteDiagnosis(row)">
              <mat-icon style="color: red"> delete_forever </mat-icon>
              <span>Delete</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <mat-paginator [length]="data.total" [pageSize]="data.per_page" *ngIf="data.total > data.per_page"
    (page)="getNext($event)"></mat-paginator>
</div>