<!-- // Bread Crumbs -->
<div class="row">
  <p style="font-size: 16px; font-weight: 450; margin-left: 10px">
    <a routerLink="/" routerLinkActive="active">
      <em
        class="material-icons text_align-center"
        style="font-size: 18px; margin-top: -3px"
        >home</em
      > </a
    >&nbsp; / &nbsp;
    <a [routerLink]="['/main/member', patientId, 'NOTES']">
      Member - {{ patient?.lastName }}, {{ patient?.firstName }} </a
    >&nbsp; / &nbsp; Schedule a New Appointment
  </p>
</div>

<form [formGroup]="therapysessionForm">
  <!-- Multiple Mat Stepper for Different Screens -->
  <mat-stepper linear #stepper style="background-color: transparent">
    <!-- Step 1 - Calendar Details -->
    <mat-step
      label="Calendar"
      state="calendaricon"
      [stepControl]="therapysessionForm.controls['title']"
    >
      <div class="row">
        <!-- Headers -->
        <!-- Calendar Components -->
        <div
          class="col-md-3"
          style="
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-bottom: 20px;
            max-height: 100vh;
            overflow-y: auto;
          "
        >
          <!-- Left Calendar Components -->
          <mat-card
            appearance="raised"
            class="mat-elevation-z8"
            style="border-radius: 15px"
          >
            <!-- Calendar Type -->
            <label class="labelHeader calendarTypeContainer">
              Calendar Type
            </label>
            <div class="calendarTypeContainer" style="margin-bottom: 10px">
              <mat-button-toggle-group
                [(ngModel)]="calendarViewType"
                [ngModelOptions]="{ standalone: true }"
                (change)="calendarTypeChanged($event)"
              >
                <mat-button-toggle value="week"> Week </mat-button-toggle>
                <mat-button-toggle value="day"> Day </mat-button-toggle>
              </mat-button-toggle-group>
            </div>

            <!-- Calendar Time Zone -->
            <label class="labelHeader">Time Zone</label>
            <mat-form-field appearance="outline">
              <mat-select
                [(ngModel)]="selectedCalendarTimeZone"
                [ngModelOptions]="{ standalone: true }"
                (selectionChange)="timeZoneChanged()"
              >
                <mat-option
                  *ngFor="let zone of calendarTimeZones"
                  [value]="zone.id"
                >
                  {{ zone.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Calendar Increments -->
            <label class="labelHeader">Calendar Increment</label>
            <mat-form-field appearance="outline">
              <mat-select
                [(ngModel)]="timeCellStep"
                [ngModelOptions]="{ standalone: true }"
                (selectionChange)="timeCellStepChanged()"
              >
                <mat-option
                  *ngFor="let cellstep of timeCellSteps"
                  [value]="cellstep"
                >
                  {{ cellstep }} minutes
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!-- Calendar Days Range -->
            <label class="labelHeader">Calendar Days Range</label>
            <mat-slider
              min="0"
              max="6"
              discrete
              step="1"
              color="primary"
              [displayWith]="formatDaySliderLabel"
            >
              <input
                [value]="calendarStartDay"
                [(ngModel)]="calendarStartDay"
                [ngModelOptions]="{ standalone: true }"
                matSliderStartThumb
                (valueChange)="sliderValueChanged()"
              />
              <input
                [value]="calendarEndDay"
                [(ngModel)]="calendarEndDay"
                [ngModelOptions]="{ standalone: true }"
                matSliderEndThumb
                (valueChange)="sliderValueChanged()"
              />
            </mat-slider>
          </mat-card>

          <mat-card
            appearance="raised"
            class="mat-elevation-z8"
            style="border-radius: 15px"
          >
            <label class="labelHeader">
              Providers ({{ filteredAllResources?.length }})
            </label>
            <!-- Search Box -->
            <div
              style="
                display: flex;
                align-items: center;
                padding: 5px;
                border-radius: 10px;
                border: 1px solid rgb(0, 0, 0, 0.2);
                background-color: #ffffff;
                padding: 5px;
              "
            >
              <span
                class="material-icons"
                style="text-align: left; font-size: 20px; opacity: 0.5"
              >
                search
              </span>
              <input
                type="search"
                placeholder="Provider Name"
                [(ngModel)]="searchProviderName"
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="searchByProviderNameChanged()"
                style="
                  padding-left: 15px;
                  width: 100%;
                  background: transparent;
                  border: 0;
                "
              />
            </div>

            <!-- Quick Actions Box -->
            <div
              style="
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                padding-top: 15px;
              "
            >
              <button
                mat-button
                color="primary"
                type="button"
                (click)="selectAllProviders()"
              >
                Select All
              </button>
              <button
                mat-button
                color="warn"
                type="button"
                (click)="deselectAllProviders()"
              >
                Uncheck All
              </button>
            </div>
            <!-- Providers List -->
            <div style="max-height: 600px; overflow: auto">
              <div *ngFor="let provider of filteredAllResources">
                <mat-checkbox
                  color="primary"
                  [disabled]="!isResourceFacilityVisible(provider)"
                  [checked]="isResourceVisible(provider)"
                  (change)="selectDeselctResource($event, provider)"
                >
                  {{ provider.name }}
                </mat-checkbox>
              </div>
            </div>
          </mat-card>

          <!-- Facilities  -->
          <mat-card
            appearance="raised"
            class="mat-elevation-z8"
            style="border-radius: 15px"
          >
            <label class="labelHeader">
              Facilities ({{ facilitiesSelectionList?.length }})
            </label>

            <!-- Search Box -->
            <div
              style="
                display: flex;
                align-items: center;
                padding: 5px;
                border-radius: 10px;
                border: 1px solid rgb(0, 0, 0, 0.2);
                background-color: #ffffff;
                padding: 5px;
              "
            >
              <span
                class="material-icons"
                style="text-align: left; font-size: 20px; opacity: 0.5"
              >
                search
              </span>
              <input
                type="search"
                placeholder="Facility Name"
                [(ngModel)]="searchFacilityName"
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="searchByFacilityNameChanged()"
                style="
                  padding-left: 15px;
                  width: 100%;
                  background: transparent;
                  border: 0;
                "
              />
            </div>
            <!-- Quick Actions -->
            <div
              style="
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
              "
            >
              <button
                mat-button
                color="primary"
                type="button"
                (click)="selectAllFacilities()"
              >
                Select All
              </button>
              <button
                mat-button
                color="warn"
                type="button"
                (click)="deselectAllFacilities()"
              >
                Uncheck All
              </button>
            </div>

            <!-- Facilities List -->
            <div style="max-height: 450px; overflow: auto">
              <div *ngFor="let facility of facilitiesSelectionList">
                <mat-checkbox
                  color="primary"
                  [checked]="isFacilityVisible(facility)"
                  (change)="selectDeselctFacility($event, facility)"
                >
                  {{ facility.facilityName }}
                </mat-checkbox>
              </div>
            </div>
          </mat-card>
        </div>

        <!-- MBSC Calendar Components -->
        <div class="col-md-9" style="max-height: 100vh; overflow-y: auto">
          <mat-error *ngIf="!providersListLoading && providersList.length == 0">
            No providers found. Please contact your administrator.
          </mat-error>

          <!-- Select a provider message -->
          <mat-error
            *ngIf="!providersListLoading && filteredResources.length == 0"
          >
            Please select a provider
          </mat-error>

          <!-- Right Calendar Components -->
          <mbsc-eventcalendar
            #mbscCalendar
            [data]="myEvents"
            [resources]="filteredResources"
            [invalid]="invalids"
            [colors]="invalidColors"
            [options]="mobiscrollCalendarOptions"
            [responsive]="responsiveMobiScrollCalendarView"
            [min]="minimumCalendarDate"
            [max]="maximumCalendarDate"
            [scheduleEventTemplate]="eventTemplate"
            [displayTimezone]="selectedCalendarTimeZone"
          >
            <!-- Custom Event eventTemplate -->
            <ng-template #eventTemplate let-data>
              <div
                class="md-custom-event-cont"
                [ngStyle]="{
                  'border-left': '10px solid ' + data.color,
                  background: data.color
                }"
              >
                <div class="md-custom-event-wrapper">
                  <div class="md-custom-event-details">
                    <div class="md-custom-event-title">
                      <mat-icon
                        *ngIf="data.original.status == 'Canceled'"
                        style="
                          color: red;
                          font-size: 17px;
                          height: 15px !important;
                        "
                      >
                        highlight_off
                      </mat-icon>
                      {{ data.title }}
                    </div>
                    <div class="md-custom-event-time">
                      {{ data.start + ' - ' + data.end }}
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </mbsc-eventcalendar>

          <!-- Event Popup -->
          <mbsc-popup
            class="md-tooltip"
            #popup
            [anchor]="anchor"
            [options]="popupOptions"
          >
            <div (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()">
              <!-- Event Title -->
              <div
                class="md-tooltip-header"
                [ngStyle]="{ background: selectedEvent?.color }"
                (click)="navigateToMemberPage(selectedEvent)"
              >
                <label
                  class="labelBold"
                  style="color: #ffffff; cursor: pointer"
                >
                  {{ selectedEvent?.title }}
                </label>
              </div>
              <!-- Event Information -->
              <div class="md-tooltip-info">
                <div>
                  <label class="labelBold">Appointment : </label>
                  <label
                    class="labelText"
                    style="color: #2196f3; cursor: pointer"
                    (click)="navigateToSessionPage(selectedEvent)"
                  >
                    &nbsp;{{ selectedEvent?.sessionCode }}
                  </label>
                </div>
                <div>
                  <label class="labelBold">Appointment Type : </label>
                  <label class="labelBold">
                    &nbsp;{{ selectedEvent?.sessionType }}
                  </label>
                </div>
                <div>
                  <label class="labelBold">Appointment Start : </label>
                  <label class="labelText">
                    &nbsp;{{
                      selectedEvent?.start
                        | date
                          : 'M/d/y, h:mm a'
                          : selectedCaldendarTimeZoneDateFormat
                    }}
                    {{ selectedCaldendarTimeZoneDateFormat }}
                  </label>
                </div>
                <div>
                  <label class="labelBold">Appointment Duration : </label>
                  <label class="labelText">
                    &nbsp;{{ selectedEvent?.duration }} minutes
                  </label>
                </div>
                <div>
                  <label class="labelBold">Facility : </label>
                  <label class="labelText">
                    &nbsp;{{ selectedEvent?.facilityName }}
                  </label>
                </div>
                <div>
                  <label class="labelBold">Status : </label>
                  <label class="labelText">
                    <span
                      [style]="
                        selectedEvent?.status === 'Active'
                          ? 'color: #3ac47d; font-weight: 900'
                          : 'color: #d92550; font-weight: 900'
                      "
                    >
                      &nbsp;{{ selectedEvent?.status }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </mbsc-popup>
        </div>
      </div>

      <!-- Mat Stepper Buttons -->
      <div class="row" style="margin-top: 20px">
        <div class="col-md-12" style="text-align: right">
          <button
            mat-raised-button
            type="button"
            color="primary"
            matStepperNext
          >
            Next
          </button>
        </div>
      </div>
    </mat-step>
    <!-- Step 2 - New Session Details -->
    <mat-step
      state="editIcon"
      [stepControl]="therapysessionForm"
      label="New Appointment Details"
    >
      <div class="row">
        <!-- Dividing the Sessions Screen into 3 components -->
        <div
          class="col-md-3"
          style="
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-bottom: 20px;
          "
        >
          <!-- Member Details -->
          <mat-card
            appearance="raised"
            class="mat-elevation-z8"
            style="border-radius: 15px"
          >
            <label class="labelHeader"> Member Details </label>
            <!-- Member Details -->
            <div class="twoGridContainer">
              <!-- Member Name -->
              <label class="labelBold">Member Name</label>
              <label class="labelText">
                {{ newSession.patientLastName }},{{ ' ' }}
                {{ newSession.patientFirstName }}{{ ' ' }}
                {{ newSession.patientMiddleName }}
              </label>
              <!-- Member DOB -->
              <label class="labelBold">Member DOB</label>
              <label class="labelText">
                {{ newSession.patentDOB }}
              </label>
              <!-- Member Email -->
              <label class="labelBold" *ngIf="patient?.email">Email</label>
              <label class="labelText" *ngIf="patient?.email">
                {{ patient.email }}
              </label>
              <!-- Member Email -->
              <label class="labelBold" *ngIf="patient?.phoneNumber"
                >Phone
              </label>
              <label class="labelText" *ngIf="patient?.phoneNumber">
                {{ patient.phoneNumber | phoneFormat }}
              </label>
            </div>
          </mat-card>
          <!-- Session Details -->
          <mat-card
            appearance="raised"
            class="mat-elevation-z8"
            style="border-radius: 15px"
          >
            <label class="labelHeader"> Appointment Details </label>
            <div class="row">
              <!-- Visit Type -->
              <div class="col-md-12">
                <label class="labelBold">
                  Please select a visit reason
                  <span style="color: red">*</span>
                </label>
                <mat-form-field appearance="outline">
                  <mat-select
                    formControlName="visitReasonId"
                    placeholder="Visit Reasons"
                    (selectionChange)="visitReasonChanged()"
                  >
                    <mat-option
                      *ngFor="let visitReason of visitReasons"
                      [value]="visitReason.id"
                    >
                      {{ visitReason.reasonName }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="therapysessionForm.controls['visitReasonId'].invalid"
                  >
                    Please select one of the visit reason
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Session Date, time, duration  -->
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <label class="labelBold">Start Date</label>
                    <mat-form-field appearance="outline">
                      <input
                        matInput
                        formControlName="sessionStartDate"
                        readonly
                      />
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <label class="labelBold">Start Time</label>
                    <mat-form-field appearance="outline">
                      <input
                        matInput
                        formControlName="sessionStartTime"
                        readonly
                      />
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <label class="labelBold">Duration</label>
                    <mat-form-field appearance="outline">
                      <input
                        matInput
                        placeholder="Duration"
                        formControlName="sessionDuration"
                        type="number"
                        required
                        min="0"
                      />
                      <span matSuffix>min</span>
                      <mat-error
                        *ngIf="
                          therapysessionForm.controls['sessionDuration'].invalid
                        "
                      >
                        Required
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <!-- Session For, Type -->
              <div class="col-md-12">
                <div class="row">
                  <div class="col-sm-6">
                    <label class="labelBold">
                      Appointment For
                      <span style="color: red">*</span>
                    </label>
                    <mat-form-field appearance="outline">
                      <mat-select
                        formControlName="sessionFor"
                        placeholder="Appointment For"
                      >
                        <mat-option value="Individual">Individual</mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="
                          therapysessionForm.controls['sessionFor'].invalid
                        "
                      >
                        Appointment For is required
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-6">
                    <label class="labelBold">
                      Appointment Type
                      <span style="color: red">*</span>
                    </label>
                    <mat-form-field appearance="outline">
                      <mat-select
                        formControlName="sessionType"
                        placeholder="Appointment Type"
                      >
                        <mat-option value="In-Person Appointment">
                          In-Person
                        </mat-option>
                        <mat-option value="Tele-Health Appointment">
                          Tele-Health
                        </mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="
                          therapysessionForm.controls['sessionType'].invalid
                        "
                      >
                        Appointment Type is required
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </mat-card>

          <!-- Member Document Details -->
          <mat-card
            appearance="raised"
            class="mat-elevation-z8"
            style="border-radius: 15px"
          >
            <label class="labelHeader"> Member Document </label>
            <div class="row">
              <div class="col-md-12">
                <mat-form-field appearance="outline">
                  <mat-select
                    formControlName="memberIntakeDocuments"
                    multiple
                    placeholder="Select documents"
                  >
                    <mat-select-trigger>
                      <mat-chip-listbox>
                        <mat-chip-row
                          *ngFor="
                            let intakeDocuments of therapysessionForm.controls[
                              'memberIntakeDocuments'
                            ].value;
                            let i = index
                          "
                          (removed)="removeIntakeDocument(intakeDocuments, i)"
                        >
                          {{ intakeDocuments.documentName }}
                          <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                          </button>
                        </mat-chip-row>
                      </mat-chip-listbox>
                    </mat-select-trigger>
                    <mat-optgroup
                      *ngFor="let documentGroup of intakeDocumentsList"
                      [label]="documentGroup.name"
                    >
                      <mat-option
                        *ngFor="let documentOption of documentGroup.documents"
                        [value]="documentOption"
                      >
                        {{ documentOption.documentName }}
                      </mat-option>
                    </mat-optgroup>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-12 columnGrid">
              <label *ngIf="!patient?.email">
                * Member does not have an <b>email address</b> associated with
                their account.
              </label>
              <label *ngIf="!patient?.phoneNumber">
                * Member does not have a <b>phone number</b> associated with
                their account.
              </label>
              <label *ngIf="!patient?.phoneNumber || !patient?.email">
                * To submit the document via email after requesting it, both an
                email address and phone number are needed.
              </label>
            </div>
          </mat-card>
          <!-- Associated Care Case Details -->
          <mat-card
            *ngIf="hasCareFeature"
            appearance="raised"
            class="mat-elevation-z8"
            style="border-radius: 15px"
          >
            <div class="row">
              <div class="col-9">
                <label class="labelHeader"> Associate with Care Case # </label>
                <mat-form-field appearance="outline">
                  <mat-select
                    formControlName="caseId"
                    placeholder="Cases"
                    (selectionChange)="careCaseChanged($event)"
                  >
                    <mat-option value=""></mat-option>
                    <mat-option
                      *ngFor="let cases of existingCaseList"
                      [value]="cases.id"
                    >
                      {{ cases.caseNumber }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div
                class="col-3"
                *ngIf="hasCaseAddAccess"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <button
                  mat-icon-button
                  type="button"
                  (click)="addNewCase()"
                  matTooltip="Add a new care case"
                >
                  <mat-icon color="primary">add_circle</mat-icon>
                </button>
              </div>
            </div>
          </mat-card>
        </div>
        <!-- Providers Details -->
        <div
          class="col-md-4"
          style="
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            gap: 20px;
            margin-bottom: 20px;
          "
        >
          <!-- Provider Details -->
          <mat-card
            appearance="raised"
            class="mat-elevation-z8"
            style="border-radius: 15px"
          >
            <div class="row">
              <!-- Provider Details -->
              <div class="col-md-12">
                <label class="labelHeader"> Provider Details </label>
                <div class="twoGridContainer">
                  <!-- Provider Name -->
                  <label class="labelBold">Provider Name</label>
                  <label class="labelText">
                    {{ newSession.physicianLastName }},{{ ' ' }}
                    {{ newSession.physicianFirstName }}{{ ' ' }}
                    {{ newSession.physicianMiddleName }}
                  </label>
                  <!-- Provider Email -->
                  <label class="labelBold">Provider Email</label>
                  <label class="labelText">
                    {{ newSession.physicianEmail }}
                  </label>
                  <!-- Provider Phone -->
                  <label class="labelBold">Provider Phone</label>
                  <label class="labelText">
                    <span *ngIf="newSession.physicianPhone">
                      {{ newSession?.physicianPhone | phoneFormat }}
                    </span>
                  </label>
                </div>
              </div>
              <!-- Supervising Rendering Provider -->
              <div class="col-md-12" style="margin-top: 25px">
                <label class="labelBold">
                  Is there a supervising rendering provider?
                  <span style="color: red">*</span>
                </label>
                <br />
                <mat-radio-group
                  formControlName="supervisingRenderingProvider"
                  style="column-gap: 15px; display: flex; flex-wrap: wrap"
                  color="primary"
                  (change)="supervisingProviderChanged($event)"
                >
                  <mat-radio-button value="Yes"> Yes </mat-radio-button>
                  <mat-radio-button value="No"> No </mat-radio-button>
                </mat-radio-group>
              </div>
              <!-- If Supervising Rendering Provider is Yes -->
              <div
                class="col-md-12"
                *ngIf="
                  therapysessionForm.controls['supervisingRenderingProvider']
                    .value === 'Yes'
                "
                style="margin-top: 25px"
              >
                <label class="labelBold">
                  Supervising Rendering Provider
                  <span style="color: red">*</span>
                </label>
                <mat-form-field appearance="outline">
                  <mat-select
                    formControlName="supervisingRPId"
                    (selectionChange)="newSupervisorSelected()"
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Supervising Provider Name"
                        noEntriesFoundLabel="'No matching supervising provider found'"
                        [(ngModel)]="searchSupervisingProviderName"
                        (ngModelChange)="filterSupervisingProvider()"
                        [ngModelOptions]="{ standalone: true }"
                      ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                      [value]="supervisor.id"
                      *ngFor="let supervisor of filteredSupervisingProviderList"
                    >
                      {{ supervisor.lastName }}, {{ supervisor.firstName }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      therapysessionForm.controls['supervisingRPId'].invalid
                    "
                  >
                    Please select the supervising provider
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- Referring Provider -->
              <div class="col-md-12" style="margin-top: 25px">
                <label class="labelBold">
                  Is there a referring provider?
                  <span style="color: red">*</span>
                </label>
                <br />
                <mat-radio-group
                  formControlName="referringProvider"
                  style="column-gap: 15px; display: flex; flex-wrap: wrap"
                  color="primary"
                  (change)="referringProviderChanged($event)"
                >
                  <mat-radio-button value="Yes"> Yes </mat-radio-button>
                  <mat-radio-button value="No"> No </mat-radio-button>
                </mat-radio-group>
              </div>
              <div
                class="col-md-12"
                style="text-align: right"
                *ngIf="
                  therapysessionForm.controls['referringProvider'].value ===
                  'Yes'
                "
              >
                <button
                  type="button"
                  mat-raised-button
                  color="primary"
                  (click)="selectReferringProvider()"
                >
                  Select New Referring Provider
                </button>
              </div>
              <div
                class="col-md-12"
                *ngIf="
                  therapysessionForm.controls['referringProvider'].value ===
                    'Yes' &&
                  therapysessionForm.controls['referringProviderId'].valid
                "
              >
                <label class="labelHeader"> Referring Provider Details </label>
                <div class="twoGridContainer">
                  <!-- Referring Provider Name -->
                  <label class="labelBold">Provider Name</label>
                  <label class="labelText">
                    {{
                      therapysessionForm.controls['referringProviderLastName']
                        .value
                    }},{{ ' ' }}
                    {{
                      therapysessionForm.controls['referringProviderFirstName']
                        .value
                    }}{{ ' ' }}
                    {{
                      therapysessionForm.controls['referringProviderMiddleName']
                        .value
                    }}
                  </label>
                  <!-- Referring Provider NPI -->
                  <label class="labelBold">Provider NPI</label>
                  <label class="labelText">
                    {{
                      therapysessionForm.controls['referringProviderNPI'].value
                    }}
                  </label>
                  <!-- Referring Provider Taxonomy -->
                  <label class="labelBold">Provider Taxonomy</label>
                  <label class="labelText">
                    {{
                      therapysessionForm.controls['referringProviderTaxonomy']
                        .value
                    }}
                  </label>
                </div>
              </div>
            </div>
          </mat-card>

          <!-- General Billing Details -->
          <mat-card
            appearance="raised"
            class="mat-elevation-z8"
            style="border-radius: 15px"
          >
            <div class="row">
              <!-- Provider Details -->
              <div class="col-md-12">
                <label class="labelHeader"> Billing Details </label>
              </div>

              <!-- Select Facility -->
              <div class="col-md-12">
                <label class="labelBold">
                  Service Location
                  <span style="color: red">*</span>
                </label>
                <mat-form-field appearance="outline">
                  <mat-select
                    formControlName="billingProviderRefId"
                    placeholder="Select a facility"
                  >
                    <mat-option
                      *ngFor="let facility of selectedProviderFacilities"
                      [value]="facility.id"
                    >
                      {{ facility.facilityName }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      therapysessionForm.controls['billingProviderRefId']
                        .invalid
                    "
                  >
                    Please select one of the facility
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-12" style="margin-top: 5px">
                <label class="labelBold">
                  Billing Type <span style="color: red">*</span>
                </label>
                <br />
                <mat-radio-group
                  aria-label="Select an option"
                  formControlName="billingType"
                  style="column-gap: 15px; display: flex; flex-wrap: wrap"
                  color="primary"
                  (change)="billingTypeChanged($event)"
                >
                  <mat-radio-button value="Self-Pay">
                    Self-Pay
                  </mat-radio-button>
                  <mat-radio-button value="Insurance">
                    Insurance
                  </mat-radio-button>
                </mat-radio-group>
                <mat-error
                  *ngIf="therapysessionForm.controls['billingType'].invalid"
                >
                  Please select the billing type
                </mat-error>
              </div>

              <div class="col-md-12" style="margin-top: 12px">
                <label class="labelBold">
                  Is this an emergency service?
                  <span style="opacity: 0.5; font-size: 12px">
                    (Box 24c on the CMS1500)
                  </span>
                </label>
                <br />
                <mat-radio-group
                  aria-label="Select an option"
                  formControlName="emgType"
                  style="column-gap: 15px; display: flex; flex-wrap: wrap"
                  color="primary"
                >
                  <mat-radio-button value="NA"> NA </mat-radio-button>
                  <mat-radio-button value="Yes"> Yes </mat-radio-button>
                  <mat-radio-button value="No"> No </mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="col-md-6" style="margin-top: 12px">
                <label class="labelBold">
                  EPSDT
                  <span style="opacity: 0.5; font-size: 12px">
                    (Box 24H on the CMS1500)
                  </span>
                </label>
                <br />
                <mat-radio-group
                  aria-label="Select an option"
                  formControlName="epsdtFamilyPlan"
                  style="column-gap: 15px; display: flex; flex-wrap: wrap"
                  color="primary"
                  (change)="epsdtTypeChanged($event)"
                >
                  <mat-radio-button value="NA"> NA </mat-radio-button>
                  <mat-radio-button value="Yes"> Yes </mat-radio-button>
                  <mat-radio-button value="No"> No </mat-radio-button>
                </mat-radio-group>
              </div>
              <div
                class="col-md-6"
                style="margin-bottom: 12px"
                *ngIf="
                  therapysessionForm.controls['epsdtFamilyPlan'].value === 'Yes'
                "
              >
                <label class="labelBold">
                  EPSDT Condition Indicator
                  <span style="opacity: 0.5; font-size: 12px">
                    (Shaded area of Box 24H on the CMS1500)
                  </span>
                </label>
                <mat-form-field appearance="outline">
                  <mat-select formControlName="epsdtType" placeholder="">
                    <mat-option value=""></mat-option>
                    <mat-option
                      [value]="condition.id"
                      *ngFor="let condition of epsdtConditionTypes"
                    >
                      {{ condition.id }} - {{ condition.description }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <!-- Member Conditions -->
              <div class="col-md-12" style="margin-top: 12px">
                <label class="labelBold">
                  Is the Member Condition Related To
                  <span style="opacity: 0.5; font-size: 12px">
                    (Box 10 on the CMS1500)
                  </span>
                </label>
              </div>
              <!-- Employment -->
              <div
                class="col-md-12"
                style="margin-top: 12px; padding-left: 45px"
              >
                <label class="labelBold">
                  Employment
                  <span style="opacity: 0.5; font-size: 12px">
                    (Box 10a on the CMS1500)
                  </span>
                </label>
                <br />
                <mat-radio-group
                  aria-label="Select an option"
                  formControlName="conditionRTEmployment"
                  style="column-gap: 15px; display: flex; flex-wrap: wrap"
                  color="primary"
                >
                  <mat-radio-button value="Yes"> Yes </mat-radio-button>
                  <mat-radio-button value="No"> No </mat-radio-button>
                </mat-radio-group>
              </div>
              <!-- Auto Accident -->
              <div
                class="col-md-12"
                style="margin-top: 12px; padding-left: 45px"
              >
                <div class="row">
                  <!-- General Question -->
                  <div class="col">
                    <label class="labelBold">
                      Auto Accident
                      <span style="opacity: 0.5; font-size: 12px">
                        (Box 10b on the CMS1500)
                      </span>
                    </label>
                    <br />
                    <mat-radio-group
                      aria-label="Select an option"
                      formControlName="conditionRTAutoAccident"
                      style="column-gap: 15px; display: flex; flex-wrap: wrap"
                      color="primary"
                      (change)="autoAccidentTypeChanged($event)"
                    >
                      <mat-radio-button value="Yes"> Yes </mat-radio-button>
                      <mat-radio-button value="No"> No </mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <!-- State Question -->
                  <div
                    class="col"
                    *ngIf="
                      therapysessionForm.controls['conditionRTAutoAccident']
                        .value === 'Yes'
                    "
                  >
                    <label class="labelText">
                      Place (State) <span style="color: red">*</span></label
                    >
                    <mat-form-field appearance="outline">
                      <mat-select
                        formControlName="conditionRTAutoState"
                        placeholder="State"
                      >
                        <mat-option
                          [value]="state.id"
                          *ngFor="let state of usStateList"
                        >
                          {{ state.name }}
                        </mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="
                          therapysessionForm.controls['conditionRTAutoState']
                            .invalid
                        "
                      >
                        Please select the state
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <!-- Date Question -->
                  <div
                    class="col"
                    *ngIf="
                      therapysessionForm.controls['conditionRTAutoAccident']
                        .value === 'Yes' ||
                      therapysessionForm.controls['conditionRTOtherAccident']
                        .value === 'Yes'
                    "
                  >
                    <label class="labelText">
                      Accident Date <span style="color: red">*</span></label
                    >
                    <mat-form-field appearance="outline">
                      <input
                        formControlName="conditionRTAutoDate"
                        matInput
                        [matDatepicker]="accidentDatePicker"
                        placeholder="Date"
                        [max]="currentTime"
                        autoSlashDate
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="accidentDatePicker"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #accidentDatePicker></mat-datepicker>
                      <mat-error
                        *ngIf="
                          therapysessionForm.controls['conditionRTAutoDate']
                            .invalid
                        "
                      >
                        Please select a valid date
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <!-- Qualifier Question -->
                  <div
                    class="col"
                    *ngIf="
                      therapysessionForm.controls['conditionRTAutoAccident']
                        .value === 'Yes'
                    "
                  >
                    <label class="labelText">
                      Qualifier <span style="color: red">*</span></label
                    >
                    <mat-form-field appearance="outline">
                      <mat-select
                        formControlName="conditionRTAutoQualifier"
                        placeholder="Qualifier"
                      >
                        <mat-option
                          [value]="qualifier.id"
                          *ngFor="let qualifier of autoAccidentQualifierList"
                        >
                          {{ qualifier.name }}
                        </mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="
                          therapysessionForm.controls[
                            'conditionRTAutoQualifier'
                          ].invalid
                        "
                      >
                        Please select the qualifier
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <!-- Other Accident -->
              <div
                class="col-md-12"
                style="margin-top: 12px; padding-left: 45px"
              >
                <label class="labelBold">
                  Other Accident
                  <span style="opacity: 0.5; font-size: 12px">
                    (Box 10c on the CMS1500)
                  </span>
                </label>
                <br />
                <mat-radio-group
                  aria-label="Select an option"
                  formControlName="conditionRTOtherAccident"
                  style="column-gap: 15px; display: flex; flex-wrap: wrap"
                  color="primary"
                  (change)="otherAccidentTypeChanged($event)"
                >
                  <mat-radio-button value="Yes"> Yes </mat-radio-button>
                  <mat-radio-button value="No"> No </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </mat-card>
        </div>
        <!-- Insurance & Cost Details -->
        <div
          class="col-md-5"
          style="
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            gap: 20px;
            margin-bottom: 20px;
          "
        >
          <!-- Insurance Details -->
          <mat-card
            appearance="raised"
            class="mat-elevation-z8"
            style="border-radius: 15px"
          >
            <div class="row">
              <!-- Primary Insurance Details -->
              <div class="col-md-12">
                <label class="labelHeader">Primary Insurance Details </label>
                <!-- Add Button -->
                <div style="text-align: right">
                  <button
                    mat-raised-button
                    color="primary"
                    type="button"
                    (click)="searchInsurance('Primary')"
                  >
                    + Add Primary Insurance
                  </button>
                </div>
                <!-- Display Insurance -->
                <div
                  style="
                    margin-top: 15px;
                    margin-bottom: 15px;
                    position: relative;
                  "
                >
                  <!-- Primary Insurance Information -->
                  <app-insurance-card
                    [insurance]="
                      therapysessionForm.controls['primaryInsuranceDetails']
                        .value
                    "
                    [patient]="patient"
                    parentClass="Session"
                    [therapySession]="newSession"
                  ></app-insurance-card>
                  <!-- Insurance Action -->
                  <div
                    style="position: absolute; right: 25px; top: 20px"
                    *ngIf="
                      therapysessionForm.controls['primaryInsuranceDetails']
                        .value
                    "
                  >
                    <mat-icon
                      style="
                        background-color: white;
                        color: red;
                        cursor: pointer;
                      "
                      (click)="removeInsurance('Primary')"
                    >
                      delete_forever
                    </mat-icon>
                  </div>
                </div>
                <mat-error
                  *ngIf="
                    therapysessionForm.controls['primaryInsuranceDetails']
                      .invalid
                  "
                >
                  Please select/add a primary insurance
                </mat-error>
              </div>
              <div class="col-md-12">
                <label class="labelBold">
                  Is there another Health Benefit Plan?
                  <span style="opacity: 0.5; font-size: 12px">
                    (Box 11d on the CMS1500)
                  </span>
                </label>
                <br />
                <mat-radio-group
                  aria-label="Select an option"
                  formControlName="anotherHealthPlan"
                  style="column-gap: 15px; display: flex; flex-wrap: wrap"
                  color="primary"
                  (change)="secondaryInsuranceChaged($event)"
                >
                  <mat-radio-button value="Yes"> Yes </mat-radio-button>
                  <mat-radio-button value="No"> No </mat-radio-button>
                </mat-radio-group>
              </div>
              <!-- Secondary Insurance -->
              <div
                class="col-md-12"
                style="
                  display: flex;
                  flex-direction: column;
                  flex-wrap: wrap;
                  margin-top: 25px;
                "
                *ngIf="
                  therapysessionForm.controls['anotherHealthPlan'].value ===
                  'Yes'
                "
              >
                <label class="labelHeader">
                  Secondary Insurance Details
                  <span style="opacity: 0.5; font-size: 12px">
                    (Box 9 on the CMS1500)
                  </span>
                </label>
                <div style="text-align: right">
                  <button
                    mat-raised-button
                    color="primary"
                    type="button"
                    (click)="searchInsurance('Secondary')"
                  >
                    + Add Secondary Insurance
                  </button>
                </div>
                <!-- Display Insurance -->
                <div
                  style="
                    margin-top: 15px;
                    margin-bottom: 15px;
                    position: relative;
                  "
                >
                  <!-- Insurance Information -->
                  <app-insurance-card
                    [insurance]="
                      therapysessionForm.controls['secondaryInsuranceDetails']
                        .value
                    "
                    [patient]="patient"
                    parentClass="Session"
                    [therapySession]="newSession"
                  ></app-insurance-card>
                  <!-- Insurance Action -->
                  <div
                    style="position: absolute; right: 25px; top: 20px"
                    *ngIf="
                      therapysessionForm.controls['secondaryInsuranceDetails']
                        .value
                    "
                  >
                    <mat-icon
                      style="
                        background-color: white;
                        color: red;
                        cursor: pointer;
                      "
                      (click)="removeInsurance('Secondary')"
                    >
                      delete_forever
                    </mat-icon>
                  </div>
                </div>
                <mat-error
                  *ngIf="
                    therapysessionForm.controls['secondaryInsuranceDetails']
                      .invalid
                  "
                >
                  Please select/add a secondary insurance
                </mat-error>
              </div>
            </div>
          </mat-card>
          <!-- Insurance Prior Auth Details -->
          <mat-card
            appearance="raised"
            class="mat-elevation-z8"
            style="border-radius: 15px"
          >
            <div class="row">
              <!-- Primary Insurance Details -->
              <div class="col-md-12">
                <label class="labelHeader">Prior Authorization </label>
              </div>

              <div class="col-md-12" style="margin-top: 5px">
                <label class="labelBold">
                  Is there a Prior Authorization Code?
                  <span style="opacity: 0.5; font-size: 12px">
                    (Box 23 on the CMS1500)
                  </span>
                </label>
                <br />
                <mat-radio-group
                  aria-label="Select an option"
                  formControlName="priorAuthCode"
                  style="column-gap: 15px; display: flex; flex-wrap: wrap"
                  color="primary"
                  (change)="priorAuthChanged($event)"
                >
                  <mat-radio-button value="Yes"> Yes </mat-radio-button>
                  <mat-radio-button value="No"> No </mat-radio-button>
                </mat-radio-group>
              </div>

              <!-- IF there is a prior authorization code -->
              <div
                class="col-md-12"
                *ngIf="
                  therapysessionForm.controls['priorAuthCode'].value === 'Yes'
                "
              >
                <!-- If primary insurance is not selected -->
                <div
                  *ngIf="
                    !therapysessionForm.controls['primaryInsuranceDetails']
                      .value
                  "
                >
                  <label style="font-weight: 450; font-style: italic">
                    * Please first select the primary insurance to view the list
                    of available prior authorizations.
                  </label>
                </div>
                <!-- If primary insurance is  selected -->
                <div
                  class="row"
                  *ngIf="
                    therapysessionForm.controls['primaryInsuranceDetails'].value
                  "
                >
                  <div class="col-md-12" style="text-align: right">
                    <button
                      type="button"
                      mat-raised-button
                      color="primary"
                      (click)="lookUpPriorAuth()"
                    >
                      <i class="material-icons">search</i> Lookup Prior Auth
                    </button>
                  </div>
                  <div
                    class="col-md-12"
                    *ngIf="
                      therapysessionForm.controls['priorAuthDetails'].value
                    "
                    style="margin-top: 15px"
                  >
                    <div class="twoGridContainer">
                      <label class="labelBold"> Prior Auth # </label>
                      <label class="labelText">
                        {{
                          therapysessionForm.controls['priorAuthDetails'].value
                            .authNumber
                        }}
                      </label>
                      <label class="labelBold"> Effective Start </label>
                      <label class="labelText">
                        {{
                          therapysessionForm.controls['priorAuthDetails'].value
                            .effectiveStartDate | date : 'MM/dd/y'
                        }}
                      </label>
                      <label class="labelBold"> Effective End </label>
                      <label class="labelText">
                        {{
                          therapysessionForm.controls['priorAuthDetails'].value
                            .effectiveEndDate | date : 'MM/dd/y'
                        }}
                      </label>
                    </div>
                  </div>
                  <mat-error
                    *ngIf="
                      therapysessionForm.controls['priorAuthDetails'].invalid
                    "
                  >
                    Please select a prior auth #
                  </mat-error>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
      </div>

      <!-- Mat Stepper Buttons -->
      <div class="row" style="margin-top: 20px">
        <div
          class="col-md-12"
          style="display: flex; justify-content: space-between"
        >
          <button mat-raised-button type="button" matStepperPrevious>
            Back
          </button>

          <button
            mat-raised-button
            color="primary"
            type="button"
            matStepperNext
          >
            Next
          </button>
        </div>
      </div>
    </mat-step>
    <!-- Step 3 - Verify -->
    <mat-step label="Confirm" state="confirmIcon">
      <!-- Session Summary -->
      <div class="row">
        <!-- Dividing the Sessions Screen into 3 components -->
        <!-- First Session Details -->
        <div
          class="col-md-4"
          style="
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            gap: 20px;
            margin-bottom: 20px;
          "
        >
          <!-- Session Details -->
          <mat-card
            appearance="raised"
            class="mat-elevation-z8"
            style="border-radius: 15px"
          >
            <label class="labelHeader"> Appointment Details </label>
            <!-- Session Details -->
            <div class="twoGridContainer">
              <!-- Member Name -->
              <label class="labelBold">Member Name</label>
              <label class="labelText">
                {{ newSession.patientLastName }},{{ ' ' }}
                {{ newSession.patientFirstName }}{{ ' ' }}
                {{ newSession.patientMiddleName }}
              </label>

              <!-- Appointment Start  -->
              <label class="labelBold">Appointment Start</label>
              <label class="labelText">
                {{
                  therapysessionForm.controls['start'].value
                    | date
                      : 'M/d/y    h:mm a'
                      : selectedCaldendarTimeZoneDateFormat
                }}
                {{ selectedCaldendarTimeZoneDateFormat }}
              </label>
              <!-- Session Duration  -->
              <label class="labelBold">Appointment Duration</label>
              <label class="labelText">
                {{ therapysessionForm.controls['sessionDuration'].value }}
                minutes
              </label>
              <!-- Visit Reason  -->
              <label class="labelBold">Visit Reason</label>
              <label class="labelText">
                {{ therapysessionForm.controls['visitReasonName'].value }}
              </label>
              <!-- Session For  -->
              <label class="labelBold">Appointment For</label>
              <label class="labelText">
                {{ therapysessionForm.controls['sessionFor'].value }}
              </label>
              <!-- Session Type  -->
              <label class="labelBold">Appointment Type</label>
              <label class="labelText">
                {{ therapysessionForm.controls['sessionType'].value }}
              </label>
              <!-- Session Type  -->
              <label class="labelBold">Emergency Service</label>
              <label class="labelText">
                {{ therapysessionForm.controls['emgType'].value }}
              </label>
            </div>
          </mat-card>

          <!--  Provider Details -->
          <mat-card
            appearance="raised"
            class="mat-elevation-z8"
            style="border-radius: 15px"
          >
            <label class="labelHeader"> Provider Details </label>
            <!-- Session Details -->
            <div class="twoGridContainer">
              <!-- Provider Name -->
              <label class="labelBold">Provider Name</label>
              <label class="labelText">
                {{ newSession.physicianLastName }},{{ ' ' }}
                {{ newSession.physicianFirstName }}{{ ' ' }}
                {{ newSession.physicianMiddleName }}
              </label>

              <!-- Supervisor Name -->
              <label
                class="labelBold"
                *ngIf="
                  therapysessionForm.controls['supervisingRenderingProvider']
                    .value == 'No'
                "
              >
                Supervising Provider
              </label>
              <label
                class="labelText"
                *ngIf="
                  therapysessionForm.controls['supervisingRenderingProvider']
                    .value == 'No'
                "
              >
                {{
                  therapysessionForm.controls['supervisingRenderingProvider']
                    .value
                }}
              </label>

              <label
                class="labelBold"
                *ngIf="
                  therapysessionForm.controls['supervisingRenderingProvider']
                    .value == 'Yes'
                "
              >
                Supervisor Name
              </label>
              <label
                class="labelText"
                *ngIf="
                  therapysessionForm.controls['supervisingRenderingProvider']
                    .value == 'Yes'
                "
              >
                {{
                  therapysessionForm.controls['supervisingRPLastName'].value
                }},{{ ' ' }}
                {{ therapysessionForm.controls['supervisingRPFirstName'].value
                }}{{ ' ' }}
                {{
                  therapysessionForm.controls['supervisingRPMiddleName'].value
                }}
              </label>

              <!-- Referring Name -->
              <label
                class="labelBold"
                *ngIf="
                  therapysessionForm.controls['referringProvider'].value == 'No'
                "
              >
                Referring Provider
              </label>
              <label
                class="labelText"
                *ngIf="
                  therapysessionForm.controls['referringProvider'].value == 'No'
                "
              >
                {{ therapysessionForm.controls['referringProvider'].value }}
              </label>
              <label
                class="labelBold"
                *ngIf="
                  therapysessionForm.controls['supervisingRenderingProvider']
                    .value == 'Yes'
                "
              >
                Referring Provider
              </label>
              <label
                class="labelText"
                *ngIf="
                  therapysessionForm.controls['referringProvider'].value ==
                  'Yes'
                "
              >
                {{
                  therapysessionForm.controls['referringProviderLastName']
                    .value
                }},{{ ' ' }}
                {{
                  therapysessionForm.controls['referringProviderFirstName']
                    .value
                }}{{ ' ' }}
                {{
                  therapysessionForm.controls['referringProviderMiddleName']
                    .value
                }}
              </label>
            </div>
          </mat-card>

          <!--  Custom Note  -->
          <mat-card
            appearance="raised"
            class="mat-elevation-z8"
            style="border-radius: 15px"
          >
            <label class="labelHeader"> Appointment note </label>
            <mat-form-field appearance="outline">
              <textarea
                matInput
                placeholder="Add in any note related to this appointment"
                formControlName="sessionLevelNote"
                name="scheduleNote"
                spellcheck="true"
              ></textarea>
            </mat-form-field>
          </mat-card>
        </div>

        <!-- Billing Details -->
        <div
          class="col-md-5"
          style="
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            gap: 20px;
            margin-bottom: 20px;
          "
        >
          <!-- Session Details -->
          <mat-card
            appearance="raised"
            class="mat-elevation-z8"
            style="border-radius: 15px"
          >
            <label class="labelHeader"> Billing Details </label>
            <div class="twoGridContainer">
              <!-- Billing Type  -->
              <label class="labelBold">Service Location</label>
              <label class="labelText">
                {{
                  facilitiesListMap.get(
                    therapysessionForm.controls['billingProviderRefId'].value
                  )?.facilityName
                }}
              </label>

              <!-- Billing Type  -->
              <label class="labelBold">Billing Type</label>
              <label class="labelText">
                {{ therapysessionForm.controls['billingType'].value }}
              </label>
              <!-- Prior Auth -->
              <label
                class="labelBold"
                *ngIf="therapysessionForm.controls['priorAuthDetails'].value"
              >
                Prior Auth
              </label>
              <label
                class="labelText"
                *ngIf="therapysessionForm.controls['priorAuthDetails'].value"
              >
                {{
                  therapysessionForm.controls['priorAuthDetails'].value
                    .authNumber
                }}
              </label>
              <!-- Primary Insurance Details -->
              <label
                class="labelBold"
                *ngIf="
                  therapysessionForm.controls['primaryInsuranceDetails'].value
                "
              >
                Primary Insurance
              </label>
              <app-insurance-card
                *ngIf="
                  therapysessionForm.controls['primaryInsuranceDetails'].value
                "
                [insurance]="
                  therapysessionForm.controls['primaryInsuranceDetails'].value
                "
                [patient]="patient"
                parentClass="Session"
                [therapySession]="newSession"
              ></app-insurance-card>

              <!-- Another Insurance -->
              <label
                class="labelBold"
                *ngIf="
                  therapysessionForm.controls['anotherHealthPlan'].value ===
                  'Yes'
                "
              >
                Secondary Insurance
              </label>
              <app-insurance-card
                *ngIf="
                  therapysessionForm.controls['anotherHealthPlan'].value ===
                  'Yes'
                "
                [insurance]="
                  therapysessionForm.controls['secondaryInsuranceDetails'].value
                "
                [patient]="patient"
                parentClass="Session"
                [therapySession]="newSession"
              ></app-insurance-card>
            </div>
          </mat-card>
        </div>

        <!-- Charge Details -->
        <div class="col-md-3">
          <mat-card
            appearance="raised"
            class="mat-elevation-z8"
            style="border-radius: 15px"
          >
            <label class="labelHeader"> Appointment Charges </label>
            <!-- Session Charges -->
            <div class="row">
              <div
                class="col-md-12"
                *ngIf="therapysessionForm.controls['charges'].value.length < 6"
                style="text-align: right"
              >
                <button
                  mat-raised-button
                  color="primary"
                  type="button"
                  (click)="addCharge()"
                >
                  + Add Charge
                </button>
              </div>
              <!-- If there are no charges captured -->
              <div
                class="col-md-12"
                *ngIf="therapysessionForm.controls['charges'].value.length == 0"
              >
                <mat-error>No charge captured</mat-error>
              </div>
              <!-- If  charges  are captured -->
              <div
                class="col-md-12 table-responsive"
                *ngIf="therapysessionForm.controls['charges'].value.length > 0"
              >
                <table
                  mat-table
                  [dataSource]="therapysessionForm.controls['charges'].value"
                >
                  <ng-container matColumnDef="cptCode">
                    <th mat-header-cell *matHeaderCellDef>Procedure Code</th>
                    <td mat-cell *matCellDef="let row">
                      {{ row.cptCode }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="charges">
                    <th mat-header-cell *matHeaderCellDef>Charges</th>
                    <td mat-cell *matCellDef="let row">
                      {{ row.charges | currency : 'USD' }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>Actions</th>
                    <td mat-cell *matCellDef="let row; let i = index">
                      <button
                        mat-mini-fab
                        [matMenuTriggerFor]="menu"
                        style="background: transparent"
                        type="button"
                      >
                        <mat-icon style="color: #00000099">more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu">
                        <button
                          mat-menu-item
                          type="button"
                          (click)="editCharge(row, i)"
                        >
                          <mat-icon style="color: #f39121"> edit </mat-icon>
                          <span>Edit</span>
                        </button>
                        <button
                          mat-menu-item
                          type="button"
                          (click)="removeCharge(i)"
                        >
                          <mat-icon style="color: red">
                            delete_forever
                          </mat-icon>
                          <span>Delete</span>
                        </button>
                      </mat-menu>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="chargesColumn"></tr>
                  <tr mat-row *matRowDef="let row; columns: chargesColumn"></tr>
                </table>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
      <!-- Mat Stepper Buttons -->
      <div class="row" style="margin-top: 20px">
        <div
          class="col-md-12"
          style="display: flex; justify-content: space-between"
        >
          <button mat-raised-button type="button" matStepperPrevious>
            Back
          </button>

          <button
            mat-raised-button
            color="primary"
            type="button"
            [disabled]="therapysessionForm.invalid || processing"
            (click)="submitForm()"
          >
            Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
          </button>
        </div>
      </div>
    </mat-step>
    <!-- Overriding Stepper Icons -->
    <ng-template matStepperIcon="calendaricon">
      <mat-icon>calendar_month</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="editIcon">
      <mat-icon>edit_note</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="confirmIcon">
      <mat-icon>checklist_rtl</mat-icon>
    </ng-template>
  </mat-stepper>
</form>
