import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Patient } from 'src/app/_models';
import { ToastMessageService, UploadFilesService } from 'src/app/_services';
import { ConsentDocumentApiService } from 'src/app/_services/consent-docs/orgconsentdocument.service';
import { CustomFormApiService } from 'src/app/_services/custom-forms/customforms.service';
import { FormResponseApiService } from 'src/app/_services/custom-forms/formresponse.service';
import { GuestIntakeDemographicsComponent } from 'src/app/pages/guest/guest-intake-demographics/guest-intake-demographics.component';
import { GuestIntakeMedicalHistoryComponent } from 'src/app/pages/guest/guest-intake-medical-history/guest-intake-medical-history.component';
import { extractPDFFileNameFromCustomFormTitle } from 'src/app/shared/utilities/utilities';
import { environment } from 'src/environments/environment';
import { ComponentCollection, Model, setLicenseKey } from 'survey-core';
import { SurveyPDF } from 'survey-pdf';
import { AddEditMemberInsuranceComponent } from '../../member-tab/member-tab-sections/member-insurance/add-edit-member-insurance/add-edit-member-insurance.component';

@Component({
  selector: 'app-verify-submitted-documents',
  templateUrl: './verify-submitted-documents.component.html',
  styleUrl: './verify-submitted-documents.component.css',
})
export class VerifySubmittedDocumentsComponent implements OnInit {
  @ViewChild('pdfViewerOnDemand', { static: false }) pdfViewerOnDemand;
  row: any;
  patient: Patient = null;
  isLoadingResults: boolean = true;
  processing: boolean = false;
  model: Model;

  dataPatient: Patient;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<VerifySubmittedDocumentsComponent>,
    private dialog: MatDialog,
    public consentDocumentApiService: ConsentDocumentApiService,
    public customformApiService: CustomFormApiService,
    public formResponseApiService: FormResponseApiService,
    private toastMessageService: ToastMessageService,
    private uploadFilesService: UploadFilesService
  ) {
    this.row = data.row;
    this.dataPatient = data.patient;
  }

  ngOnInit(): void {
    if (this.row?.documentType === 'Custom Forms') {
      setLicenseKey(environment.surveyJsLicenseKey);

      ComponentCollection.Instance.remove('dualSignatures');
      ComponentCollection.Instance.add({
        name: 'dualSignatures',
        title: 'Dual Signatures',
        iconName: 'icon-toolbox-signature-16x16',
        elementsJSON: [
          {
            type: 'panel',
            name: 'panel1',
            visible: true,
            elements: [
              {
                type: 'signaturepad',
                name: 'memberonly-signature',
                title: 'Member signature',
                minWidth: '256px',
                signatureWidth: 480,
                signatureHeight: 80,
                penColor: 'black',
                signatureAutoScaleEnabled: true,
                placeholder: 'Member signature',
                visible: true,
              },
              {
                type: 'signaturepad',
                name: 'careprovideronly-signature',
                title: 'Care Provider signature',
                startWithNewLine: false,
                minWidth: '256px',
                penColor: 'black',
                signatureWidth: 480,
                signatureHeight: 80,
                signatureAutoScaleEnabled: true,
                placeholder: 'Care Provider signature',
                visible: true,
              },
            ],
          },
        ],
      });

      this.loadSurveyJson(
        this.row.customFormData.payload,
        this.row.consentDocumentId
      );
    } else if (this.row?.documentType === 'Consent Documents') {
      this.getConsentDocumentInformation();
    } else if (this.row?.documentName === 'Insurance Information') {
      this.verifyInsurance();
    } else if (this.row?.documentName === 'Member Medical History') {
      this.verifyIntakeMedicalHistory();
    } else if (this.row?.documentName === 'Demographics Form') {
      this.verifyIntakeDemographics();
    } else if (
      this.row?.documentName === 'Authorization To Release Medical Records'
    ) {
      this.verifyReleaseOfMedicalRecords();
    }
  }

  verifyReleaseOfMedicalRecords() {
    this.isLoadingResults = true;

    this.consentDocumentApiService
      .getSignedConsentDocument(this.row.id)
      .subscribe(
        (res) => {
          this.isLoadingResults = false;
          this.pdfViewerOnDemand.pdfSrc = res;
          this.pdfViewerOnDemand.refresh();
        },
        (error) => {
          this.isLoadingResults = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to retrieve the release of medical information document'
          );
        }
      );
  }

  verifyIntakeDemographics() {
    const dialogRef = this.dialog.open(GuestIntakeDemographicsComponent, {
      data: {
        action: 'VERIFY',
        consentRequestId: this.row.id,
        documentName: this.row.documentName,
        oldPatientDemographics: this.dataPatient,
        patient: this.row.patientDTO,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '45vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.dialogRef.close('success');
      } else {
        this.dialogRef.close();
      }
    });
  }

  verifyIntakeMedicalHistory() {
    const dialogRef = this.dialog.open(GuestIntakeMedicalHistoryComponent, {
      data: {
        action: 'VERIFY',
        consentRequestId: this.row.id,
        documentName: this.row.documentName,
        patient: this.dataPatient,
        history: this.row.memberIntakeHistory,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '45vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.dialogRef.close('success');
      } else {
        this.dialogRef.close();
      }
    });
  }

  verifyInsurance() {
    const addInsuranceDialog = this.dialog.open(
      AddEditMemberInsuranceComponent,
      {
        data: {
          action: 'EDIT',
          patient: this.dataPatient,
          consentRequestId: this.row.id,
          memberInsurance: this.row.patientInsuranceDTO,
        },
        autoFocus: false,
        disableClose: true,
        minWidth: '40vw',
      }
    );
    addInsuranceDialog.afterClosed().subscribe((response) => {
      if (response && response === 'success') {
        this.dialogRef.close('success');
      } else {
        this.dialogRef.close();
      }
    });
  }

  getConsentDocumentInformation() {
    this.isLoadingResults = true;

    this.consentDocumentApiService
      .getSignedConsentDocument(this.row.id)
      .subscribe(
        (res) => {
          this.isLoadingResults = false;
          this.pdfViewerOnDemand.pdfSrc = res;
          this.pdfViewerOnDemand.refresh();
        },
        (error) => {
          this.isLoadingResults = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to retrieve the consent document'
          );
        }
      );
  }

  acceptRejectSignature(option: boolean, message: string) {
    this.processing = true;

    this.consentDocumentApiService
      .acceptRejectConsentDocument(this.row.id, option)
      .subscribe({
        next: (response) => {
          this.processing = false;
          if (option) {
            this.toastMessageService.displaySuccessMessage(message);
          } else {
            this.toastMessageService.displaySuccessMessage(message);
          }

          this.dialogRef.close('success');
        },
        error: (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Something went wrong. Please try again.'
          );
        },
      });
  }

  acceptMedicalReleaseForm() {
    this.processing = true;
    const file = new File([], 'temp_holder.png');

    this.consentDocumentApiService
      .memberSubmitReleaseOfInformation(
        this.row.id,
        this.row.releaseMedicalRecordsDTO,
        file
      )
      .subscribe({
        next: (response) => {
          this.processing = false;
          this.toastMessageService.displaySuccessMessage(
            'Successfully verified the release of medical records form.'
          );
          this.dialogRef.close('success');
        },
        error: (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to verify the form. Please try again'
          );
        },
      });
  }

  loadSurveyJson(formData: any, formVersionId: string) {
    this.isLoadingResults = true;

    this.customformApiService.getFormVersion(formVersionId).subscribe({
      next: async (response) => {
        if (response) {
          const survey = new Model(response.data.formDefinition);
          survey.allowEditSurveyTitle = false;
          survey.JSON = response.data.formDefinition;
          survey.data = formData;

          this.model = survey;
          this.model.showNavigationButtons = false;
          this.model.mode = 'display';

          this.model.onAfterRenderQuestion.add((sender, options) => {
            const question = options.question;
            if (question.name.startsWith('memberonly-')) {
              question.readOnly = true;
            }
          });

          this.model.onAfterRenderQuestion.add((sender, options) => {
            const question = options.question;
            if (question.name.startsWith('careprovideronly-')) {
              question.readOnly = false;
              this.model.mode = 'edit';
            }
          });
          this.isLoadingResults = false;
        } else {
          this.isLoadingResults = false;
        }
      },
      error: (error) => {
        console.error('Failed to load survey:', error);
        this.isLoadingResults = false;
      },
    });
  }

  // Save custom document
  acceptCustomFormData() {
    this.processing = true;

    const pdfWidth =
      !!this.model && this.model.pdfWidth ? this.model.pdfWidth : 210;
    const pdfHeight =
      !!this.model && this.model.pdfHeight ? this.model.pdfHeight : 297;
    const options = {
      fontSize: 14,
      compress: true,
      margins: {
        left: 10,
        right: 10,
        top: 10,
        bot: 10,
      },
      format: [pdfWidth, pdfHeight],
    };

    const surveyPdf = new SurveyPDF(this.model.JSON, options);
    surveyPdf.data = this.model.data;

    surveyPdf.mode = 'display';

    // Getting the blob from survery js component
    surveyPdf.raw('blob').then((blob) => {
      let fileName = extractPDFFileNameFromCustomFormTitle(
        this.row.documentName
      );
      fileName = fileName + '.pdf';
      this.processing = true;

      // Set the file name
      const file = new File([blob], fileName);

      this.uploadFilesService
        .uploadMemberDocument(
          this.dataPatient.id,
          'Member Document',
          file,
          this.row.id,
          this.row?.hasAttachments ? 'Y' : 'N',
          null
        )
        .subscribe({
          next: (response) => {
            this.acceptRejectSignature(
              true,
              'Successfully verified the document.'
            );
          },
          error: (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to verify the document'
            );
          },
        });
    });
  }
}
