<div style="display: flex; justify-content: center">
  <mat-card
    appearance="raised"
    *ngIf="orgConfigLoaded"
    class="mat-elevation-z8"
    style="border-radius: 20px; max-width: 650px"
  >
    <!-- Logo -->
    <div style="text-align: center">
      <img
        src="{{ orgConfig.logo }}"
        style="height: 70px; width: 180px; object-fit: contain"
      />
    </div>

    <form
      [formGroup]="accessCodeForm"
      novalidate
      style="margin-top: 25px; margin-bottom: 25px"
      #formDir="ngForm"
    >
      <div style="text-align: center">
        <label>
          To get started, please enter your access code and date of birth.
        </label>
      </div>

      <div class="row">
        <div class="col-md-12">
          <label class="labelGuest">Access Code</label>
          <mat-form-field appearance="outline">
            <input
              matInput
              placeholder="Access Code"
              formControlName="accessCode"
              name="accessCode"
              required
              minlength="6"
              maxlength="6"
            />
            <mat-error *ngIf="accessCodeForm.controls['accessCode'].invalid">
              Access Code is Invalid
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-12">
          <label class="labelGuest">Date of Birth</label>
          <mat-form-field appearance="outline">
            <input
              formControlName="dateOfBirth"
              matInput
              [matDatepicker]="dobDatePicker"
              [max]="todaysDate"
              placeholder="MM/DD/YYYY"
              required
              autoSlashDate
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="dobDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #dobDatePicker></mat-datepicker>
            <mat-error *ngIf="accessCodeForm.controls['dateOfBirth'].invalid">
              Please enter a valid Date of Birth
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-12">
          <button
            style="width: 100%; border-radius: 25px"
            type="button"
            mat-raised-button
            color="primary"
            [disabled]="accessCodeForm.invalid || processing"
            (click)="submitForm(formDir)"
          >
            Start My Appointment
            <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
          </button>
        </div>
      </div>
    </form>
  </mat-card>
</div>
