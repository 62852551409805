import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Patient } from 'src/app/_models';
import { ICustomFormVersion } from 'src/app/_models/custom-forms/customformversion.model';
import { IFormResponse } from 'src/app/_models/custom-forms/formresponse.model';
import { CoreService } from 'src/app/_services';
import { CustomFormApiService } from 'src/app/_services/custom-forms/customforms.service';
import { FormResponseApiService } from 'src/app/_services/custom-forms/formresponse.service';
import { ToastMessageService } from 'src/app/_services/toast-message.service';
import { UploadFilesService } from 'src/app/_services/upload-files.service';
import { environment } from 'src/environments/environment';
import { ComponentCollection, Model, setLicenseKey } from 'survey-core';

@Component({
  selector: 'app-guest-custom-forms',
  templateUrl: './guest-custom-forms.component.html',
  styleUrl: './guest-custom-forms.component.css',
})
export class GuestCustomFormsComponent implements OnInit {
  model: Model;

  customFormVersion: ICustomFormVersion;
  isLoadingResults = false;
  error: string = '';
  processing: boolean = false;
  isModalWindow: boolean = true;
  formResponse: IFormResponse;
  hasAttachments: boolean = false;
  formSubmitted: boolean = false;
  isPatient: boolean = false;

  patient: Patient;
  requestId: string = '';
  accessCode: string = '';
  organizationId: string = '';
  consentRequestId: string = '';
  consentDocumentId: string = '';
  documentName: string = '';

  reloadRequired: string = '';
  process: string = '';

  constructor(
    @Optional()
    public dialogRef: MatDialogRef<GuestCustomFormsComponent> | null = null,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any = null,
    public customformApiService: CustomFormApiService,
    public formResponseApiService: FormResponseApiService,
    private toastMessageService: ToastMessageService,
    private coreService: CoreService,
    private uploadFilesService: UploadFilesService
  ) {
    if (data) {
      this.patient = data.patient;
      this.requestId = data.requestId;
      this.accessCode = data.accessCode;
      this.organizationId = data.organizationId;
      this.consentRequestId = data.consentRequestId;
      this.consentDocumentId = data.consentDocumentId;
      this.documentName = data.documentName;
      this.process = data.process;
    } else {
      this.isModalWindow = false;
    }
  }

  ngOnInit(): void {
    this.isPatient = this.coreService.isPatient();

    setLicenseKey(environment.surveyJsLicenseKey);
    ComponentCollection.Instance.remove('dualSignatures');
    ComponentCollection.Instance.add({
      name: 'dualSignatures',
      title: 'Dual Signatures',
      iconName: 'icon-toolbox-signature-16x16',
      elementsJSON: [
        {
          type: 'panel',
          name: 'panel1',
          visible: true,
          elements: [
            {
              type: 'signaturepad',
              name: 'memberonly-signature',
              title: 'Member signature',
              minWidth: '256px',
              signatureWidth: 480,
              signatureHeight: 80,
              penColor: 'black',
              signatureAutoScaleEnabled: true,
              placeholder: 'Member signature',
              visible: true,
            },
            {
              type: 'signaturepad',
              name: 'careprovideronly-signature',
              title: 'Care Provider signature',
              startWithNewLine: false,
              minWidth: '256px',
              penColor: 'black',
              signatureWidth: 480,
              signatureHeight: 80,
              signatureAutoScaleEnabled: true,
              placeholder: 'Care Provider signature',
              visible: true,
            },
          ],
        },
      ],
    });

    this.model = new Model();

    this.loadSurveyJson(this.consentDocumentId);
  }

  public loadSurveyJson(formVersionId: string) {
    this.isLoadingResults = true;
    this.error = '';

    this.customformApiService
      .getGuestLinkFormContents(formVersionId)
      .subscribe({
        next: (response) => {
          this.isLoadingResults = false;
          if (response) {
            this.model = new Model(response.data.formDefinition);
            this.model.allowEditSurveyTitle = false;
            this.model.JSON = response.data.formDefinition;

            this.formResponse = Object.assign({}, this.formResponse);
            this.formResponse.formVersionId = formVersionId;
            this.formResponse.versionNumber = response.data.versionNumber;
            this.formResponse.formName = response.data.formName;

            if (this.isPatient || this.process === 'fillOut') {
              this.model.onAfterRenderQuestion.add((sender, options) => {
                const question = options.question;
                if (question.name.startsWith('careprovideronly-')) {
                  question.readOnly = true;
                }
              });
            }

            if (!this.isPatient && this.process != 'fillOut') {
              this.model.onAfterRenderQuestion.add((sender, options) => {
                const question = options.question;
                if (question.name.startsWith('memberonly-')) {
                  question.readOnly = true;
                }
              });
            }

            // For updating the form
            this.model.onComplete.add((sender, options) => {
              this.formResponse.payload = sender.data;

              // Determining guest vs care provider filing the form
              if (this.accessCode) {
                // Guest filing the form
                this.formResponseApiService
                  .addGuestFormResponse(
                    this.consentRequestId,
                    this.formResponse,
                    this.hasAttachments,
                    this.requestId,
                    this.accessCode
                  )
                  .subscribe({
                    next: (response) => {
                      this.processing = false;
                      this.toastMessageService.displaySuccessMessage(
                        'Successfully submitted the form.'
                      );
                      this.model.data = sender.data;

                      this.formSubmitted = true;
                      this.reloadRequired = 'success';
                    },
                    error: (error) => {
                      this.processing = false;
                      this.toastMessageService.displayErrorMessage(
                        'Error: Something went wrong. Please refresh the page.'
                      );
                    },
                  });
              } else {
                // CP / provider filing the form
                this.formResponseApiService
                  .addFormResponse(
                    this.requestId,
                    this.formResponse,
                    this.hasAttachments
                  )
                  .subscribe({
                    next: (response) => {
                      this.processing = false;
                      this.toastMessageService.displaySuccessMessage(
                        'Successfully submitted the form.'
                      );
                      this.model.data = sender.data;
                      this.formSubmitted = true;
                      this.reloadRequired = 'success';
                    },
                    error: (error) => {
                      this.processing = false;
                      this.toastMessageService.displayErrorMessage(
                        'Error: Something went wrong. Please refresh the page.'
                      );
                    },
                  });
              }
            });

            this.model.onUploadFiles.add((survey, options) => {
              this.hasAttachments = true;

              if (this.accessCode) {
                this.uploadFilesService
                  .uploadGuestCustomFormAttachments(
                    this.patient.id,
                    'Member Document',
                    options.files,
                    this.consentRequestId,
                    this.requestId,
                    this.accessCode
                  )
                  .subscribe({
                    next: (event) => {
                      // Pass the uploaded file's metadata back to SurveyJS
                      const uploadedFiles = options.files.map((file, index) => {
                        return {
                          file: file,
                          content: file.name, // or event.someResponseProperty if applicable
                        };
                      });

                      options.callback('success', uploadedFiles);
                    },
                    error: (error) => {
                      options.callback('error');
                    },
                  });
              } else {
                this.uploadFilesService
                  .uploadMemberAttachments(
                    this.patient.id,
                    'Member Document',
                    options.files,
                    this.requestId
                  )
                  .subscribe({
                    next: (event) => {
                      // Pass the uploaded file's metadata back to SurveyJS
                      const uploadedFiles = options.files.map((file, index) => {
                        return {
                          file: file,
                          content: file.name, // or event.someResponseProperty if applicable
                        };
                      });

                      options.callback('success', uploadedFiles);
                    },
                    error: (error) => {
                      options.callback('error');
                    },
                  });
              }
            });
          }
        },
        error: (error) => {
          this.error = error;
          this.isLoadingResults = false;
        },
      });
  }

  closeScreen() {
    this.dialogRef.close('success');
  }
}
