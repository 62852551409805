import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DataTablesModel, INote } from '../../_models';


@Injectable({ providedIn: 'root' })
export class NoteHttpService {
    constructor(private httpClient: HttpClient) { }

    getNotes(caseId: string, patientId: string, pageSize: number, pageIndex: number): Observable<DataTablesModel> {
        let params = new HttpParams();
        params = params.append('patientId', patientId);
        params = params.append('pageSize', pageSize.toString());
        params = params.append('pageIndex', pageIndex.toString());
        return this.httpClient.get<DataTablesModel>(`${environment.apiUrl}/case/${caseId}/notes`, { params: params });
    }

    getShiftNotes(caseId: string, patientId: string, shift: string): Observable<DataTablesModel> {
        let params = new HttpParams();
        params = params.append('patientId', patientId);
        params = params.append('shift', shift);

        return this.httpClient.get<DataTablesModel>(`${environment.apiUrl}/case/${caseId}/notes`, { params: params });
    }

    addNote(caseId: string, note: INote): Observable<INote> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.post<INote>(`${environment.apiUrl}/case/${caseId}/note`, note, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }

    updateNote(caseId: string, note: INote): Observable<INote> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.put<INote>(`${environment.apiUrl}/case/${caseId}/note`, note, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }

    deleteNote(caseId: string, note: INote): Observable<INote> {
        return this.httpClient.delete<INote>(`${environment.apiUrl}/case/${caseId}/note/` + note.id).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }

}