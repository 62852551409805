import { DOCUMENT, LocationStrategy } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { BehaviorSubject } from 'rxjs';
import { CoreService } from './_services';
import { FacilitiesSandbox } from './shared/sandbox/facilities.sandbox';
import { FavoritesSandbox } from './shared/sandbox/favorites-sandbox';
import { OrgConfigSandbox } from './shared/sandbox/org-config.sandbox';
import { PermissionsSandbox } from './shared/sandbox/permissions.sandbox';
import { UserSettingsPropsSandbox } from './shared/sandbox/user-settings-props.sandbox';
import { VisitReasonsSandbox } from './shared/sandbox/visit-reasons.sandbox';

declare global {
  interface Window {
    cookieconsent: any;
    Helpshift: any;
    helpshiftConfig: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit {
  public isLoggedIn = false;

  public isFacilityDevice = false;
  public isPhysician = false;
  public isPatient = false;
  public isSuperOrgAdmin = false;

  //Mobile View || SSO View
  isGuestUrl = false;
  isMobileWebView = false;
  isSSOView = false;
  hideHelpShift: string | null;
  helpShiftProps: any;
  loadHelpShift: boolean = false;
  userLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private keycloak: KeycloakService,
    protected orgConfigSandbox: OrgConfigSandbox,
    protected readonly router: Router,
    private facilitiesSandbox: FacilitiesSandbox,
    protected permissionsSandbox: PermissionsSandbox,
    protected visitReasonsSandbox: VisitReasonsSandbox,
    private favoritesSandbox: FavoritesSandbox,
    private userSettingsPropsSandbox: UserSettingsPropsSandbox,
    private url: LocationStrategy,
    private coreService: CoreService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document
  ) {}

  public ngOnInit() {
    let isUserLoggedIn = this.keycloak.isLoggedIn();

    // Determine if this url is a guest url
    if (this.url.path().indexOf('/guestlink/') > -1) {
      this.isGuestUrl = true;
      localStorage.setItem('hide_helpshift', JSON.stringify(true));
    }

    // Determine if this flow is coming from mobile app
    if (this.url.path().indexOf('/mwv/') > -1) {
      this.isMobileWebView = true;
    }

    if (
      (this.url.path().indexOf('config/assessment') > -1 ||
        this.url.path().indexOf('config/careplan') > -1) &&
      (this.coreService.isSuperOrgAdmin() || this.coreService.isOrgAdmin())
    ) {
      this.isMobileWebView = true;
    }

    if (!this.isGuestUrl) {
      if (isUserLoggedIn) {
        this.isPatient = this.coreService.isPatient();
        this.isPhysician = this.coreService.isPhysician();

        this.loadCookieConsentScript();
        this.configureHelpShift();
        this.loadHelpShiftScript();
        Chart.register(...registerables);
        Chart.register(annotationPlugin);

        this.isLoggedIn = true;
        let userDetails = this.coreService.getUserDetails();

        // Checking the EULA Version and not for mobile view
        let eulaVersion = userDetails.eulaVersion;
        if ((!eulaVersion || eulaVersion < 1) && !this.isMobileWebView) {
          if (this.url.path().indexOf('pwotb') === -1) {
            window.location.href = '/pwotb/eula';
          }
        }

        // If the user logged is has the role 'inhospitaldevice
        this.isFacilityDevice = this.coreService.isFacilityDevice();
        if (this.isFacilityDevice) {
          localStorage.setItem('hide_helpshift', JSON.stringify(true));
        }
        // If the logged in User is SuperOrgAdmin
        this.isSuperOrgAdmin = this.coreService.isSuperOrgAdmin();

        // If the logged in user is a patient
        // Determine if they are in SSO View or Regular View
        if (this.isPatient) {
          this.isSSOView = this.coreService.isSSOUser();
        }

        // Trigger Organization Config Load for all Users
        this.orgConfigSandbox.triggerOrgConfigLoad();

        // Trigger Visit Reasons for this organization
        this.visitReasonsSandbox.triggerVisitReasonsLoad();
        this.favoritesSandbox.loadFavorites();
        this.userSettingsPropsSandbox.loadUserSettingsProps();

        // Except for superorgadmin trigger permissions for all other people
        if (!this.isSuperOrgAdmin && !this.isPatient) {
          this.permissionsSandbox.triggerPermissionsLoad();
        }

        // Get all assigned facilities for all roles except patient and clinicians
        if (!this.isPatient) {
          this.facilitiesSandbox.triggerFacilitiesLoad();
        }

        // Set Help Shift Props
        let userType = '';
        if (this.isPatient) {
          userType = 'patient';
        } else if (this.isPhysician) {
          userType = 'physician';
        } else {
          userType = 'careprovider';
        }

        let helpShiftProps = {
          fullName: userDetails.name,
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          phoneNumber: userDetails.phone,
          userType: userType,
          email: userDetails.email,
          userName: userDetails.username,
          organizationId: userDetails.orgId,
        };

        localStorage.setItem('helpShiftProps', JSON.stringify(helpShiftProps));

        this.keycloak.keycloakEvents$.subscribe({
          next: (e) => {
            if (e.type == KeycloakEventType.OnTokenExpired) {
              this.keycloak
                .updateToken(100)
                .then((refreshed) => {
                  if (!refreshed) {
                    this.login();
                  }
                })
                .catch(() => {
                  this.login();
                });
            }
          },
        });

        // If the window path is undefined or if it is a SSO View, forcing the app to go to the auth guard
        if (!this.url.path() || this.isSSOView) {
          this.router.navigate(['/']);
          localStorage.setItem('hide_helpshift', JSON.stringify(true));
        }

        setTimeout(() => {
          this.setupCookieConsent();
        }, 1800);
        this.userLoggedIn$.next(true);
      } else {
        this.isLoggedIn = false;
        this.login();
      }
    }
  }

  ngAfterViewInit(): void {
    this.userLoggedIn$.subscribe(() => {
      if (this.isLoggedIn && !this.isGuestUrl) {
        setTimeout(() => {
          this.initializeHelpshift();
        }, 5000);
      }
    });
  }

  ngOnDestroy(): void {
    this.userLoggedIn$.unsubscribe();
  }

  public login() {
    localStorage.clear();
    this.keycloak.login({
      redirectUri: window.location.origin + '/',
    });
  }

  loadCookieConsentScript() {
    this.loadScripts(
      `https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.js`
    );
  }

  loadHelpShiftScript() {
    const scriptId = 'hs-chat';
    const script = document.createElement('script');
    script.id = scriptId;
    script.async = true;
    script.src = 'https://webchat.helpshift.com/latest/webChat.js';
    document.head.appendChild(script);
  }

  loadScripts(scriptFile: string) {
    const script = this.renderer.createElement('script');
    script.src = scriptFile;
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(this.document.body, script);
  }

  setupCookieConsent() {
    this.hideHelpShift = localStorage.getItem('hide_helpshift');
    if (!this.hideHelpShift) {
      window.cookieconsent.initialise({
        palette: {
          popup: { background: '#237afc' },
          button: { background: 'transparent', text: '#fff', border: '#fff' },
        },
        position: 'bottom-left',
      });
    }
  }

  initializeHelpshift() {
    window.Helpshift('init');
    window.Helpshift('setCustomIssueFields', this.mapHelpShiftProps());
  }

  configureHelpShift() {
    window.helpshiftConfig = {
      platformId: this.getPlatformId(),
      domain: 'mindwisehealth',
      language: 'en',
      widgetOptions: {
        position: 'bottom-left',
      },
    };
  }

  getPlatformId() {
    if (this.isPatient) {
      return 'mindwisehealth_platform_20230517151039352-5ed94fb56548737';
    } else if (this.isPhysician) {
      return 'mindwisehealth_platform_20230515144109691-d157f4562cc63c5';
    }
    return 'mindwisehealth_platform_20230517150921293-01f1b77440d4926';
  }

  mapHelpShiftProps() {
    let helpShiftProps = JSON.parse(localStorage.getItem('helpShiftProps'));
    return {
      full_name: { type: 'singleline', value: helpShiftProps.fullName },
      first_name: { type: 'singleline', value: helpShiftProps.firstName },
      last_name: { type: 'singleline', value: helpShiftProps.lastName },
      username: { type: 'singleline', value: helpShiftProps.userName },
      phone_no: { type: 'singleline', value: helpShiftProps.phoneNumber },
      email_id: { type: 'singleline', value: helpShiftProps.email },
      organization_id: {
        type: 'singleline',
        value: helpShiftProps.organizationId,
      },
    };
  }
}
