<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Generalized Anxiety Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="generalizedAnxietyForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-12">1. Feeling nervous anxious or on edge</label>
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="feelings">
              <mat-button-toggle value="Not At All"
                >Not At All</mat-button-toggle
              >
              <mat-button-toggle value="Several Days"
                >Several Days</mat-button-toggle
              >
              <mat-button-toggle value="Over Half The Days"
                >Over Half The Days</mat-button-toggle
              >
              <mat-button-toggle value="Nearly Everyday"
                >Nearly Everyday</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12"
            >2. Not being able to stop or control worrying</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="notBeingAbleToStop">
              <mat-button-toggle value="Not At All"
                >Not At All</mat-button-toggle
              >
              <mat-button-toggle value="Several Days"
                >Several Days</mat-button-toggle
              >
              <mat-button-toggle value="Over Half The Days"
                >Over Half The Days</mat-button-toggle
              >
              <mat-button-toggle value="Nearly Everyday"
                >Nearly Everyday</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12"
            >3. Worrying too much about different things</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="worryingToMuch">
              <mat-button-toggle value="Not At All"
                >Not At All</mat-button-toggle
              >
              <mat-button-toggle value="Several Days"
                >Several Days</mat-button-toggle
              >
              <mat-button-toggle value="Over Half The Days"
                >Over Half The Days</mat-button-toggle
              >
              <mat-button-toggle value="Nearly Everyday"
                >Nearly Everyday</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12">4. Trouble relaxing</label>
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="noRelaxation">
              <mat-button-toggle value="Not At All"
                >Not At All</mat-button-toggle
              >
              <mat-button-toggle value="Several Days"
                >Several Days</mat-button-toggle
              >
              <mat-button-toggle value="Over Half The Days"
                >Over Half The Days</mat-button-toggle
              >
              <mat-button-toggle value="Nearly Everyday"
                >Nearly Everyday</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12"
            >5. Being so restless that it’s hard to sit still</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="restless">
              <mat-button-toggle value="Not At All"
                >Not At All</mat-button-toggle
              >
              <mat-button-toggle value="Several Days"
                >Several Days</mat-button-toggle
              >
              <mat-button-toggle value="Over Half The Days"
                >Over Half The Days</mat-button-toggle
              >
              <mat-button-toggle value="Nearly Everyday"
                >Nearly Everyday</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12"
            >6. Becoming easily annoyed or irritable</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="easilyAnnoyed">
              <mat-button-toggle value="Not At All"
                >Not At All</mat-button-toggle
              >
              <mat-button-toggle value="Several Days"
                >Several Days</mat-button-toggle
              >
              <mat-button-toggle value="Over Half The Days"
                >Over Half The Days</mat-button-toggle
              >
              <mat-button-toggle value="Nearly Everyday"
                >Nearly Everyday</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12"
            >7. Feeling afraid as if something awful might happen</label
          >
          <div class="col-sm-12">
            <mat-button-toggle-group formControlName="feelingAfraid">
              <mat-button-toggle value="Not At All"
                >Not At All</mat-button-toggle
              >
              <mat-button-toggle value="Several Days"
                >Several Days</mat-button-toggle
              >
              <mat-button-toggle value="Over Half The Days"
                >Over Half The Days</mat-button-toggle
              >
              <mat-button-toggle value="Nearly Everyday"
                >Nearly Everyday</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          generalizedAnxietyForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!generalizedAnxietyForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
