import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DataTablesModel, IDocument } from '../../_models';

@Injectable({ providedIn: 'root' })
export class DocumentHttpService {
  constructor(private httpClient: HttpClient) { }

  getCaseDocuments(
    caseId: string,
    patientId: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('patientId', patientId);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());
    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/case/${caseId}/documents/all`,
      { params: params }
    );
  }

  getMemberDocuments(
    patientId: string,
    fileName: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    if (fileName) params = params.append('fileName', fileName);

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/member/${patientId}/documents/all`,
      { params: params }
    );
  }

  getDocumentAttachments(
    patientId: string,
    parentId: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('parentId', parentId);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/member/${patientId}/documents/all`,
      { params: params }
    );
  }

  getSessionConsentDocuments(
    sessionId: string,
    patientId: string,
    organizationId: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('patientId', patientId);
    params = params.append('organizationId', organizationId);
    params = params.append('sessionId', sessionId);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/allsessionconsentdocuments`,
      { params: params }
    );
  }

  getSessionDocuments(
    sessionId: string,
    patientId: string,
    organizationId: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('patientId', patientId);
    params = params.append('organizationId', organizationId);
    params = params.append('sessionId', sessionId);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/allsessiondocuments`,
      { params: params }
    );
  }

  getDischargeDocuments(
    caseId: string,
    patientId: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('patientId', patientId);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());
    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/case/${caseId}/discharge-documents/all`,
      { params: params }
    );
  }

  uploadDocument(caseId: string, data: FormData): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.apiUrl}/case/${caseId}/documents`,
      data
    );
  }

  addDocument(caseId: string, file: File): Observable<any> {
    var formData: any = new FormData();
    formData.append('file', file);

    return this.httpClient
      .post<IDocument>(
        `${environment.apiUrl}/case/${caseId}/documents`,
        formData,
        {
          reportProgress: true,
          observe: 'events',
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  deleteDocument(caseId: string, document: IDocument): Observable<IDocument> {
    return this.httpClient
      .delete<IDocument>(
        `${environment.apiUrl}/case/${caseId}/documents/` + document.id
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  deleteSessionDocument(
    sessionId: string,
    documentId: string
  ): Observable<IDocument> {
    return this.httpClient
      .delete<IDocument>(
        `${environment.apiUrl}/session/${sessionId}/documents/` + documentId
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  printDocument(
    caseId: string,
    fromDate: string,
    toDate: string,
    shiftNumber: number
  ): any {
    return this.httpClient
      .get(
        `${environment.apiUrl}/reports/case?caseId=${caseId}&fromDate=${fromDate}&toDate=${toDate}&shiftNumber=${shiftNumber}`,
        { responseType: 'blob' }
      )
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  downloadDocument(caseId: string, fileId: string): Observable<any> {
    return this.httpClient.get(
      `${environment.apiUrl}/case/${caseId}/document/${fileId}`,
      { responseType: 'blob' }
    );
  }

  downloadMemberDocument(patientId: string, fileId: string): Observable<any> {
    return this.httpClient.get(
      `${environment.apiUrl}/member/${patientId}/document/${fileId}`,
      { responseType: 'blob' }
    );
  }

  downloadFile(url: string): Observable<any> {
    return this.httpClient.get(
      `${environment.apiUrl}${url}`,
      { responseType: 'blob' }
    );
  }

  downloadSessionDocument(
    sessionId: string,
    fileId: string,
    patientId: string
  ): Observable<any> {
    let params = new HttpParams();
    params = params.append('patientId', patientId);
    params = params.append('sessionId', sessionId);
    params = params.append('fileId', fileId);

    return this.httpClient.get(
      `${environment.apiUrl}/sessionconsentdocuments`,
      { responseType: 'blob', params: params }
    );
  }

  bowelReport(caseId: string, month: string, year: number): any {
    return this.httpClient
      .get(
        `${environment.apiUrl}/reports/bowel?caseId=${caseId}&month=${month}&year=${year}`,
        { responseType: 'blob' }
      )
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  seizureReport(caseId: string, month: string, year: number): any {
    return this.httpClient
      .get(
        `${environment.apiUrl}/reports/seizures?caseId=${caseId}&month=${month}&year=${year}`,
        { responseType: 'blob' }
      )
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  sleeplogReport(caseId: string, month: string, year: number): any {
    return this.httpClient
      .get(
        `${environment.apiUrl}/reports/sleeplog?caseId=${caseId}&month=${month}&year=${year}`,
        { responseType: 'blob' }
      )
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  therapyClosureDocument(sessionId: string, patientId: string): any {
    let params = new HttpParams();
    params = params.append('sessionId', sessionId);
    params = params.append('patientId', patientId);
    return this.httpClient
      .get(
        `${environment.apiUrl}/therapy-session-reports/subjective-objective`,
        { params: params, responseType: 'blob' }
      )
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  treatmentPlanDocument(sessionId: string, patientId: string): any {
    let params = new HttpParams();
    params = params.append('sessionId', sessionId);
    params = params.append('patientId', patientId);
    return this.httpClient
      .get(`${environment.apiUrl}/treatment-plan-report`, {
        params: params,
        responseType: 'blob',
      })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  bpsaSummaryDocument(sessionId: string, patientId: string): any {
    let params = new HttpParams();
    params = params.append('sessionId', sessionId);
    params = params.append('patientId', patientId);
    return this.httpClient
      .get(`${environment.apiUrl}/bpsa-summary-report`, {
        params: params,
        responseType: 'blob',
      })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  intakeDocument(
    sessionId: string,
    patientId: string,
    documentType: string
  ): any {
    let params = new HttpParams();
    params = params.append('sessionId', sessionId);
    params = params.append('patientId', patientId);
    params = params.append('documentType', documentType);
    return this.httpClient
      .get(`${environment.apiUrl}/intake-report`, {
        params: params,
        responseType: 'blob',
      })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }
}
