import {
  NgxGpAutocompleteDirective,
  NgxGpAutocompleteOptions,
} from '@angular-magic/ngx-gp-autocomplete';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import SignaturePad from 'signature_pad';
import { IReleaseMedicalRecords } from 'src/app/_models/session/intake/releasemedicalrecords.model';
import { ToastMessageService } from 'src/app/_services';
import { ConsentDocumentApiService } from 'src/app/_services/consent-docs/orgconsentdocument.service';
import { Gender } from 'src/app/shared/utilities/billing/gender';
import { USStates } from 'src/app/shared/utilities/billing/states';

@Component({
  selector: 'app-guest-intake-release-of-information',
  templateUrl: './guest-intake-release-of-information.component.html',
  styleUrl: './guest-intake-release-of-information.component.css',
})
export class GuestIntakeReleaseOfInformationComponent
  implements OnInit, AfterViewInit {
  @Input() memberId: string = '';
  @Input() requestId: string = '';
  @Input() accessCode: string = '';
  @Input() organizationId: string = '';
  @Input() consentRequestId: string = '';
  @Input() consentDocumentId: string = '';
  @Input() documentName: string = '';
  @Input() action: string = '';

  @ViewChild('ngxPlaces') placesRef: NgxGpAutocompleteDirective;
  @ViewChild('canvas') canvasEl!: ElementRef;
  @ViewChild('canvasContainer') containerRef!: ElementRef<HTMLDivElement>;
  options: NgxGpAutocompleteOptions = {
    componentRestrictions: { country: ['US'] },
    types: ['address'],
  };

  usStates = USStates;
  genderList = Gender;

  signaturePad!: SignaturePad;
  signatureStroke = false;

  public releaseForm: FormGroup;
  processing: boolean = false;
  isModalWindow: boolean = true;

  releaseOfInformation: IReleaseMedicalRecords;

  constructor(
    @Optional() public dialogRef: MatDialogRef<GuestIntakeReleaseOfInformationComponent> | null = null,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any = null,
    private formBuilder: FormBuilder,
    public consentDocumentApiService: ConsentDocumentApiService,
    private toastMessageService: ToastMessageService
  ) {
    if (data) {
      this.memberId = data.patient.id;
      this.requestId = data.requestId;
      this.accessCode = data.accessCode;
      this.organizationId = data.organizationId;
      this.consentRequestId = data.consentRequestId;
      this.consentDocumentId = data.consentDocumentId;
      this.documentName = data.documentName;
      this.action = data.action;
    }else{
      this.isModalWindow = false;
    }
  }

  ngOnInit(): void {
    this.buildForm();
  }

  ngAfterViewInit(): void {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
    this.addSignaturePadListener();
    this.resizeCanvas();
  }

  @HostListener('window:resize')
  onWindowResize(): void {
    this.resizeCanvas();
  }

  resizeCanvas(): void {
    if (this.canvasEl) {
      const canvas = this.canvasEl.nativeElement;
      const container = this.containerRef.nativeElement;

      // Set canvas dimensions to match container's dimensions
      canvas.width = container.clientWidth - 30;
    }
  }

  clearPad() {
    this.signatureStroke = false;
    this.signaturePad.clear();
  }

  addSignaturePadListener() {
    if (this.signaturePad) {
      this.signaturePad.addEventListener('beginStroke', () => {
        this.signatureStroke = true;
      });
    }
  }

  private buildForm() {
    this.releaseForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(null),
      memberId: new FormControl(this.memberId),
      sentToName: new FormControl('', Validators.required),
      sentToGender: new FormControl('', Validators.required),
      sentToHomePhone: new FormControl(
        '',
        Validators.compose([RxwebValidators.mask({ mask: '(999) 999-9999' })])
      ),
      sentToWorkPhone: new FormControl(
        '',
        Validators.compose([RxwebValidators.mask({ mask: '(999) 999-9999' })])
      ),
      addressLine1: new FormControl('', Validators.required),
      addressLine2: new FormControl(''),
      city: new FormControl('', Validators.compose([Validators.required])),
      state: new FormControl('', Validators.compose([Validators.required])),
      zip: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          RxwebValidators.mask({ mask: '99999' }),
        ])
      ),
      infoReleasedType: new FormControl('', Validators.required),
      specificInfoDetails: new FormControl(''),
      purposeOfDisclosure: new FormControl('', Validators.required),
      excludeInformation: this.formBuilder.group({
        drugAlcohol: false,
        sexualDisease: false,
        hivAids: false,
        mentalIllness: false,
      }),
    });
  }

  infoReleasedTypeChanged(event) {
    if (event.value === 'Specific') {
      this.releaseForm
        .get('specificInfoDetails')
        .addValidators(Validators.required);
      this.releaseForm.get('specificInfoDetails').updateValueAndValidity();
    } else {
      this.releaseForm.controls['specificInfoDetails'].setValue('');
      this.releaseForm.get('specificInfoDetails').clearValidators();
      this.releaseForm.get('specificInfoDetails').updateValueAndValidity();
    }
  }

  handleAddressChange(address: google.maps.places.PlaceResult) {
    let addressField: string = '';

    for (const component of address.address_components as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number': {
          addressField = `${component.long_name} ${addressField}`;
          break;
        }

        case 'route': {
          addressField += component.long_name;
          break;
        }

        case 'locality':
          //city
          this.releaseForm.controls['city'].setValue(component.long_name);
          break;

        case 'administrative_area_level_1': {
          //state
          this.releaseForm.controls['state'].setValue(component.short_name);
          break;
        }

        case 'postal_code': {
          this.releaseForm.controls['zip'].setValue(component.long_name);
          break;
        }
      }
    }
    if (addressField.trim() == '')
      addressField = address.formatted_address.split(',')[0];
    this.releaseForm.controls['addressLine1'].setValue(addressField);
  }

  submitForm() {
    this.releaseOfInformation = Object.assign({}, this.releaseOfInformation);
    this.releaseOfInformation = Object.assign(
      this.releaseOfInformation,
      this.releaseForm.value
    );

    const dataUrl = this.signaturePad.toDataURL();

    this.convertDataURLToFile(dataUrl, (file) => {
      this.processing = true;

      // Guest link
      if (this.accessCode) {
        this.consentDocumentApiService
          .guestSubmitReleaseOfInformation(
            this.requestId,
            this.accessCode,
            this.consentRequestId,
            this.releaseOfInformation,
            file
          )
          .subscribe({
            next: (response) => {
              this.processing = false;
              this.toastMessageService.displaySuccessMessage(
                'Successfully submitted the release of medical records form.'
              );
              this.dialogRef.close('success');
            },
            error: (error) => {
              this.processing = false;
              this.toastMessageService.displayErrorMessage(
                'Error: Failed to submit the form. Please try again'
              );
            },
          });
      } else {
        this.consentDocumentApiService
          .memberSubmitReleaseOfInformation(
            this.consentRequestId,
            this.releaseOfInformation,
            file
          )
          .subscribe({
            next: (response) => {
              this.processing = false;
              this.toastMessageService.displaySuccessMessage(
                'Successfully submitted the release of medical records form.'
              );
              this.dialogRef.close('success');
            },
            error: (error) => {
              this.processing = false;
              this.toastMessageService.displayErrorMessage(
                'Error: Failed to submit the form. Please try again'
              );
            },
          });
      }
    });
  }

  private convertDataURLToFile(
    dataUrl: string,
    callback: (file: File) => void
  ) {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    const file = new File([u8arr], 'signature.png', { type: mime });
    callback(file);
  }
}
