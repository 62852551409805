import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Countries, IGoal } from 'src/app/_models';
import { GoalHttpService, ToastMessageService } from 'src/app/_services/';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import {
  buildValidationMsgs,
  formatDate,
  mmddyyyyToDate,
} from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-add-goal-dialog',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AddGoalDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AddGoalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public goalApiService: GoalHttpService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private _dateAdapter: DateAdapter<any>,
    private localStorageService: LocalStorageService,
    private toastMessageService: ToastMessageService,
    private orgConfigSandbox: OrgConfigSandbox
  ) {
    this.action = data.action;
    this.goal = data.goal;
    this.patientId = data.patientId;
    this.caseId = data.caseId;
  }

  public patientId: string;
  public caseId: string;
  public action: string;
  public goalForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  goal: IGoal;
  accountError: Error = null;
  errors: any = {};
  country: string = 'US';
  processing: boolean = false;

  public ngOnInit() {
    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfigResponse) => {
          let orgConfig = orgConfigResponse;
          if (orgConfig.organizationCountry === Countries.India) {
            this._dateAdapter.setLocale('en-GB');
          }
        });
      }
    });

    this.buildForm();
    this.errorHandler.handleErrors(this.goalForm, this.errors);

    if (this.action === 'EDIT') {
      this.goalForm.controls['id'].setValue(this.goal.id);
      this.goalForm.controls['organizationId'].setValue(
        this.goal.organizationId
      );
      this.goalForm.controls['patientId'].setValue(this.goal.patientId);
      this.goalForm.controls['caseId'].setValue(this.goal.caseId);
      this.goalForm.controls['description'].setValue(this.goal.description);
      this.goalForm.controls['status'].setValue(this.goal.status);
      this.goalForm.controls['priority'].setValue(this.goal.priority);
      this.goalForm.controls['rank'].setValue(this.goal.rank);
      this.goalForm.controls['category'].setValue(this.goal.category);
      if (this.goal.targetDate) {
        this.goalForm.controls['targetDate'].setValue(
          mmddyyyyToDate(this.goal.targetDate)
        );
      }
      if (this.goal.closedDate) {
        this.goalForm.controls['closedDate'].setValue(
          mmddyyyyToDate(this.goal.closedDate)
        );
      }
    } else {
      this.goalForm.controls['patientId'].setValue(this.patientId);
      this.goalForm.controls['caseId'].setValue(this.caseId);
    }
  }

  onGoalFormSubmit() {
    if (this.goalForm.invalid) {
      this.goalForm.markAllAsTouched();
      return;
    }

    this.goal = Object.assign({}, this.goal);
    this.goal = Object.assign(this.goal, this.goalForm.value);

    if (this.goal?.targetDate && this.goal.targetDate !== '') {
      this.goal.targetDate = formatDate(new Date(this.goal.targetDate));
    }
    if (this.goal?.closedDate && this.goal.closedDate !== '') {
      this.goal.closedDate = formatDate(new Date(this.goal.closedDate));
    }

    this.processing = true;

    if (this.action === 'EDIT') {
      this.goalApiService.updateGoal(this.goal.caseId, this.goal).subscribe(
        (response) => {
          this.processing = false;
          this.dialogRef.close('success');
          this.toastMessageService.displaySuccessMessage(
            'Goal has been updated.'
          );
        },
        (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to update the goal.'
          );
        }
      );
    } else {
      this.goal.patientId = this.patientId;
      this.goalApiService.addGoal(this.goal.caseId, this.goal).subscribe(
        (response) => {
          this.processing = false;
          this.dialogRef.close('success');
          this.toastMessageService.displaySuccessMessage(
            'New Goal has been added.'
          );
        },
        () => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to add the goal.'
          );
        }
      );
    }
  }

  private buildForm() {
    this.goalForm = this.formBuilder.group({
      id: new FormControl(null),
      caseId: new FormControl(null),
      patientId: new FormControl(null),
      organizationId: new FormControl(null),
      description: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      status: new FormControl(''),
      priority: new FormControl(''),
      rank: new FormControl(''),
      category: new FormControl(''),
      targetDate: new FormControl(''),
      closedDate: new FormControl(''),
    });

    this.goalForm.valueChanges.subscribe(() => this.onValueChanged());
  }

  public validation_messages = {
    description: [
      { type: 'required', message: 'Description is required' },
      {
        type: 'minlength',
        message: 'Description cannot be less than 2 characters long',
      },
    ],
  };

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.goalForm) {
      return;
    }
    const form = this.goalForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }

  statusChanged() {
    let newStatus = this.goalForm.controls['status'].value;
    if (newStatus === 'active') {
      this.goalForm.controls['closedDate'].setValue('');
    } else {
      this.goalForm.controls['closedDate'].setValue(new Date());
    }
  }
}
