import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css'],
})
export class ConfirmDialogComponent implements OnInit {
  yesButtonTitle = '';
  noButtonTitle = '';
  title = '';
  msg = '';
  disclaimer = '';

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.title = data.title;
    this.msg = data.msg;
    this.disclaimer = data.disclaimer;
    this.yesButtonTitle = data.yesButtonTitle;
    this.noButtonTitle = data.noButtonTitle;
  }
  ngOnInit(): void {}

  public cancel() {
    this.close(false);
  }

  public close(value) {
    this.dialogRef.close(value);
  }

  public confirm() {
    this.close(true);
  }

  @HostListener('keydown.esc')
  public onEsc() {
    this.close(false);
  }
}
