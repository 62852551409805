export const PatientInsuredRelationship = [
  {
    id: '18',
    description: 'Self',
  },
  {
    id: '01',
    description: 'Spouse',
  },
  {
    id: '19',
    description: 'Child',
  },
  {
    id: '21',
    description: 'Other',
  },
];
