import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { DataTablesModel } from '../_models';
@Injectable({ providedIn: 'root' })
export class OrganizationApiService {
	constructor(private httpClient: HttpClient) { }
	getMyOrganizations(): Observable<DataTablesModel> {
		return this.httpClient.get<DataTablesModel>(`${environment.apiUrl}/my-organizations`);
	}
}