import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { ICprs } from 'src/app/_models/assessments/relationship-family/cprs.model';
import {
  ToastMessageService
} from 'src/app/_services/';
import {
  CprsApiService
} from 'src/app/_services/assessments/relationship-family/cprs.service';

@Component({
  selector: 'app-cprs-assessment-dialog',
  templateUrl: './cprs-assessment-dialog.component.html',
  styleUrls: ['./cprs-assessment-dialog.component.css']
})
export class CprsAssessmentDialogComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public cprsAssessmentApiService: CprsApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }

  public closeness: number;
  public conflicts: number;
  public dependence: number;
  public riskScore: number;
  public cprsAssessmentForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  cprsAssessment: ICprs;
  cprsAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.cprsAssessmentForm, this.errors);

    this.loadCprs();
  }

  loadCprs() {
    this.cprsAssessmentForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.cprsAssessmentApiService
        .getCprsDetails(this.assessmentId)
        .subscribe((cprsAssessment) => {
          if (cprsAssessment.data) {
            this.cprsAssessmentForm.controls['warmChildRelation'].setValue(cprsAssessment.data.warmChildRelation.answer);
            this.cprsAssessmentForm.controls['struggleWithChild'].setValue(cprsAssessment.data.struggleWithChild.answer);
            this.cprsAssessmentForm.controls['childSeeksComfort'].setValue(cprsAssessment.data.childSeeksComfort.answer);
            this.cprsAssessmentForm.controls['childShunsTouch'].setValue(cprsAssessment.data.childShunsTouch.answer);
            this.cprsAssessmentForm.controls['childValuesRel'].setValue(cprsAssessment.data.childValuesRel.answer);
            this.cprsAssessmentForm.controls['childHurtByCorrection'].setValue(cprsAssessment.data.childHurtByCorrection.answer);
            this.cprsAssessmentForm.controls['childRefusesHelp'].setValue(cprsAssessment.data.childRefusesHelp.answer);
            this.cprsAssessmentForm.controls['childPrideOnPraise'].setValue(cprsAssessment.data.childPrideOnPraise.answer);
            this.cprsAssessmentForm.controls['childFearsSeparation'].setValue(cprsAssessment.data.childFearsSeparation.answer);
            this.cprsAssessmentForm.controls['childSharesInfo'].setValue(cprsAssessment.data.childSharesInfo.answer);
            this.cprsAssessmentForm.controls['childDependent'].setValue(cprsAssessment.data.childDependent.answer);
            this.cprsAssessmentForm.controls['childAngryAtMe'].setValue(cprsAssessment.data.childAngryAtMe.answer);
            this.cprsAssessmentForm.controls['childPleases'].setValue(cprsAssessment.data.childPleases.answer);
            this.cprsAssessmentForm.controls['childFeelsUnfair'].setValue(cprsAssessment.data.childFeelsUnfair.answer);
            this.cprsAssessmentForm.controls['childAsksForHelp'].setValue(cprsAssessment.data.childAsksForHelp.answer);
            this.cprsAssessmentForm.controls['inTuneWithChild'].setValue(cprsAssessment.data.inTuneWithChild.answer);
            this.cprsAssessmentForm.controls['childSeesPunishment'].setValue(cprsAssessment.data.childSeesPunishment.answer);
            this.cprsAssessmentForm.controls['childJealousy'].setValue(cprsAssessment.data.childJealousy.answer);
            this.cprsAssessmentForm.controls['childStaysAngry'].setValue(cprsAssessment.data.childStaysAngry.answer);
            this.cprsAssessmentForm.controls['childResponseToDisc'].setValue(cprsAssessment.data.childResponseToDisc.answer);
            this.cprsAssessmentForm.controls['childDrainsEnergy'].setValue(cprsAssessment.data.childDrainsEnergy.answer);
            this.cprsAssessmentForm.controls['childCopiesBehavior'].setValue(cprsAssessment.data.childCopiesBehavior.answer);
            this.cprsAssessmentForm.controls['childBadMood'].setValue(cprsAssessment.data.childBadMood.answer);
            this.cprsAssessmentForm.controls['childsMoodSwings'].setValue(cprsAssessment.data.childsMoodSwings.answer);
            this.cprsAssessmentForm.controls['uncomfyParenting'].setValue(cprsAssessment.data.uncomfyParenting.answer);
            this.cprsAssessmentForm.controls['thinkOfChildWork'].setValue(cprsAssessment.data.thinkOfChildWork.answer);
            this.cprsAssessmentForm.controls['childWhinesForNeeds'].setValue(cprsAssessment.data.childWhinesForNeeds.answer);
            this.cprsAssessmentForm.controls['childSneaky'].setValue(cprsAssessment.data.childSneaky.answer);
            this.cprsAssessmentForm.controls['childSharesFeelings'].setValue(cprsAssessment.data.childSharesFeelings.answer);
            this.cprsAssessmentForm.controls['effectiveParenting'].setValue(cprsAssessment.data.effectiveParenting.answer);
            this.riskScore = cprsAssessment.data.riskScore;

            this.closeness = cprsAssessment.data.props['closeness'];
            this.conflicts = cprsAssessment.data.props['conflict'];
            this.dependence = cprsAssessment.data.props['dependence'];

          }
        });
    }
  }
  onCprsFormSubmit() {
    if (this.cprsAssessmentForm.invalid) {
      this.cprsAssessmentForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.cprsAssessment = Object.assign({}, this.cprsAssessment);
    this.cprsAssessment.patientId =
      this.cprsAssessmentForm.controls['patientId'].value;
    this.cprsAssessment.sessionId =
      this.cprsAssessmentForm.controls['sessionId'].value;
    this.cprsAssessment.assessmentRequestId =
      this.cprsAssessmentForm.controls['assessmentRequestId'].value;

    this.cprsAssessment.warmChildRelation = {
      answer:
        this.cprsAssessmentForm.controls['warmChildRelation'].value,
    };
    this.cprsAssessment.struggleWithChild = {
      answer:
        this.cprsAssessmentForm.controls['struggleWithChild'].value,
    };
    this.cprsAssessment.childSeeksComfort = {
      answer:
        this.cprsAssessmentForm.controls['childSeeksComfort'].value,
    };
    this.cprsAssessment.childShunsTouch = {
      answer:
        this.cprsAssessmentForm.controls['childShunsTouch'].value,
    };
    this.cprsAssessment.childValuesRel = {
      answer:
        this.cprsAssessmentForm.controls['childValuesRel'].value,
    };
    this.cprsAssessment.childHurtByCorrection = {
      answer:
        this.cprsAssessmentForm.controls['childHurtByCorrection'].value,
    };
    this.cprsAssessment.childRefusesHelp = {
      answer:
        this.cprsAssessmentForm.controls['childRefusesHelp'].value,
    };
    this.cprsAssessment.childPrideOnPraise = {
      answer:
        this.cprsAssessmentForm.controls['childPrideOnPraise'].value,
    };
    this.cprsAssessment.childFearsSeparation = {
      answer:
        this.cprsAssessmentForm.controls['childFearsSeparation'].value,
    };
    this.cprsAssessment.childSharesInfo = {
      answer:
        this.cprsAssessmentForm.controls['childSharesInfo'].value,
    };
    this.cprsAssessment.childDependent = {
      answer:
        this.cprsAssessmentForm.controls['childDependent'].value,
    };
    this.cprsAssessment.childAngryAtMe = {
      answer:
        this.cprsAssessmentForm.controls['childAngryAtMe'].value,
    };
    this.cprsAssessment.childPleases = {
      answer:
        this.cprsAssessmentForm.controls['childPleases'].value,
    };
    this.cprsAssessment.childFeelsUnfair = {
      answer:
        this.cprsAssessmentForm.controls['childFeelsUnfair'].value,
    };
    this.cprsAssessment.childAsksForHelp = {
      answer:
        this.cprsAssessmentForm.controls['childAsksForHelp'].value,
    };
    this.cprsAssessment.inTuneWithChild = {
      answer:
        this.cprsAssessmentForm.controls['inTuneWithChild'].value,
    };
    this.cprsAssessment.childSeesPunishment = {
      answer:
        this.cprsAssessmentForm.controls['childSeesPunishment'].value,
    };
    this.cprsAssessment.childJealousy = {
      answer:
        this.cprsAssessmentForm.controls['childJealousy'].value,
    };
    this.cprsAssessment.childStaysAngry = {
      answer:
        this.cprsAssessmentForm.controls['childStaysAngry'].value,
    };
    this.cprsAssessment.childResponseToDisc = {
      answer:
        this.cprsAssessmentForm.controls['childResponseToDisc'].value,
    };
    this.cprsAssessment.childDrainsEnergy = {
      answer:
        this.cprsAssessmentForm.controls['childDrainsEnergy'].value,
    };
    this.cprsAssessment.childCopiesBehavior = {
      answer:
        this.cprsAssessmentForm.controls['childCopiesBehavior'].value,
    };
    this.cprsAssessment.childBadMood = {
      answer:
        this.cprsAssessmentForm.controls['childBadMood'].value,
    };
    this.cprsAssessment.childsMoodSwings = {
      answer:
        this.cprsAssessmentForm.controls['childsMoodSwings'].value,
    };
    this.cprsAssessment.uncomfyParenting = {
      answer:
        this.cprsAssessmentForm.controls['uncomfyParenting'].value,
    };
    this.cprsAssessment.thinkOfChildWork = {
      answer:
        this.cprsAssessmentForm.controls['thinkOfChildWork'].value,
    };
    this.cprsAssessment.childWhinesForNeeds = {
      answer:
        this.cprsAssessmentForm.controls['childWhinesForNeeds'].value,
    };
    this.cprsAssessment.childSneaky = {
      answer:
        this.cprsAssessmentForm.controls['childSneaky'].value,
    };
    this.cprsAssessment.childSharesFeelings = {
      answer:
        this.cprsAssessmentForm.controls['childSharesFeelings'].value,
    };
    this.cprsAssessment.effectiveParenting = {
      answer:
        this.cprsAssessmentForm.controls['effectiveParenting'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.cprsAssessment.accessCode = this.accessCode;

      this.cprsAssessmentApiService
        .addGuestCprs(this.cprsAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Core 10 Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Core 10 Assessment'
            );
          }
        );
    } else {
      this.cprsAssessmentApiService
        .addCprs(this.cprsAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Core 10 Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Core 10 Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    'warmChildRelation': [
      { type: 'required', message: '1. I share an affectionate, warm relationship with my child. is required' },
    ],
    'struggleWithChild': [
      { type: 'required', message: '2. My child and I always seem to be struggling with each other is required' },
    ],
    'childSeeksComfort': [
      { type: 'required', message: '3. If upset, my child will seek comfort from me. is required' },
    ],
    'childShunsTouch': [
      { type: 'required', message: '4. My child is uncomfortable with physical affection or touch from me. is required' },
    ],
    'childValuesRel': [
      { type: 'required', message: '5. My child values his/her relationship with me. is required' },
    ],
    'childHurtByCorrection': [
      { type: 'required', message: '6. My child appears hurt or embarrassed when I correct him/her. is required' },
    ],
    'childRefusesHelp': [
      { type: 'required', message: '7. My child does not want to accept help when he/she needs it. is required' },
    ],
    'childPrideOnPraise': [
      { type: 'required', message: '8. When I praise my child, he/she beams with pride. is required' },
    ],
    'childFearsSeparation': [
      { type: 'required', message: '9. My child reacts strongly to separation from me. is required' },
    ],
    'childSharesInfo': [
      { type: 'required', message: '10. My child spontaneously shares information about himself/herself. is required' },
    ],
    'childDependent': [
      { type: 'required', message: '11. My child is overly dependent on me. is required' },
    ],
    'childAngryAtMe': [
      { type: 'required', message: '12. My child easily becomes angry at me. is required' },
    ],
    'childPleases': [
      { type: 'required', message: '13. My child tries to please me. is required' },
    ],
    'childFeelsUnfair': [
      { type: 'required', message: '14. My child feels that I treat him/her unfairly. is required' },
    ],
    'childAsksForHelp': [
      { type: 'required', message: '15. My child asks for my help when he/she really does not need help. is required' },
    ],
    'inTuneWithChild': [
      { type: 'required', message: '16. It is easy to be in tune with what my child is feeling. is required' },
    ],
    'childSeesPunishment': [
      { type: 'required', message: '17. My child sees me as a source of punishment and criticism. is required' },
    ],
    'childJealousy': [
      { type: 'required', message: '18. My child expresses hurt or jealousy when I spend time with other children. is required' },
    ],
    'childStaysAngry': [
      { type: 'required', message: '19. My child remains angry or is resistant after being disciplined. is required' },
    ],
    'childResponseToDisc': [
      { type: 'required', message: '20. When my child is misbehaving, he/she responds to my look or tone of voice. is required' },
    ],
    'childDrainsEnergy': [
      { type: 'required', message: '21. Dealing with my child drains my energy. is required' },
    ],
    'childCopiesBehavior': [
      { type: 'required', message: '22. I\'ve noticed my child copying my behavior or ways of doing things. is required' },
    ],
    'childBadMood': [
      { type: 'required', message: '23. When my child is in a bad mood, I know we\'re in for a long and difficult day. is required' },
    ],
    'childsMoodSwings': [
      { type: 'required', message: '24. My child\'s feelings toward me can be unpredictable or can change suddenly. is required' },
    ],
    'uncomfyParenting': [
      { type: 'required', message: '25. Despite my best efforts, I\'m uncomfortable with how my child and I get along. is required' },
    ],
    'thinkOfChildWork': [
      { type: 'required', message: '26. I often think about my child when at work. is required' },
    ],
    'childWhinesForNeeds': [
      { type: 'required', message: '27. My child whines or cries when he/she wants something from me. is required' },
    ],
    'childSneaky': [
      { type: 'required', message: '28. My child is sneaky or manipulative with me. is required' },
    ],
    'childSharesFeelings': [
      { type: 'required', message: '29. My child openly shares his/her feelings and experiences with me. is required' },
    ],
    'effectiveParenting': [
      { type: 'required', message: '30. My interactions with my child make me feel effective and confident as a parent. is required' },
    ],
  };

  private buildForm() {
    this.cprsAssessmentForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      warmChildRelation: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      struggleWithChild: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      childSeeksComfort: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      childShunsTouch: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      childValuesRel: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      childHurtByCorrection: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      childRefusesHelp: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      childPrideOnPraise: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      childFearsSeparation: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      childSharesInfo: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      childDependent: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      childAngryAtMe: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      childPleases: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      childFeelsUnfair: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      childAsksForHelp: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      inTuneWithChild: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      childSeesPunishment: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      childJealousy: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      childStaysAngry: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      childResponseToDisc: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      childDrainsEnergy: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      childCopiesBehavior: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      childBadMood: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      childsMoodSwings: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      uncomfyParenting: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      thinkOfChildWork: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      childWhinesForNeeds: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      childSneaky: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      childSharesFeelings: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      effectiveParenting: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
    });
    this.cprsAssessmentForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.cprsAssessmentForm) {
      return;
    }
    const form = this.cprsAssessmentForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
