<div class="container">
  <div class="dialogTitle" *ngIf="displayTitle">
    <p matDialogTitle>Behavioral Incident</p>

    <button
      mat-raised-button
      type="button"
      class="btn btn-primary"
      (click)="print()"
      *ngIf="action === 'VIEW'"
    >
      <mat-icon>print</mat-icon>
    </button>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div ngxPrint printSectionId="print-section" style="margin-top: 20px">
    <div id="print-section">
      <form
        [formGroup]="incidentForm"
        novalidate
        (ngSubmit)="onAbcFormSubmit()"
      >
        <div class="row" style="row-gap: 15px">
          <div class="col-sm-12 col-md-8">
            <mat-card
              appearance="raised"
              class="mat-elevation-z8"
              style="border-radius: 15px"
            >
              <label class="labelText">
                Place where behavior happened
                <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <textarea
                  cdkTextareaAutosize
                  matInput
                  [readonly]="disableFormElements"
                  formControlName="placeOfIncident"
                  name="placeOfIncident"
                  required
                ></textarea>
                <mat-error
                  *ngFor="let validation of validation_messages.placeOfIncident"
                >
                  <mat-error
                    class="error-message"
                    *ngIf="
                      incidentForm
                        .get('placeOfIncident')
                        .hasError(validation.type) &&
                      (incidentForm.get('placeOfIncident').dirty ||
                        incidentForm.get('placeOfIncident').touched)
                    "
                    >{{ validation.message }}</mat-error
                  >
                </mat-error>
              </mat-form-field>

              <div class="row">
                <div class="col-md-12">
                  <label class="labelText">
                    Severity
                    <span style="color: red">*</span>
                  </label>
                  <mat-form-field appearance="outline">
                    <mat-select formControlName="severity">
                      <mat-option value="Severe">Severe</mat-option>
                      <mat-option value="Moderate">Moderate</mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="incidentForm.controls['severity'].invalid"
                    >
                      Please select the severity
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="row" style="margin-bottom: 12px">
                <div class="col-md-6">
                  <label class="labelText">
                    Did someone need First Aid?
                    <span style="color: red">*</span>
                  </label>
                  <br />
                  <mat-button-toggle-group
                    formControlName="neededFirstAid"
                    *ngIf="action === 'ADD'"
                  >
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                  <label *ngIf="action === 'VIEW'">{{
                    incidentForm.get('neededFirstAid').value
                  }}</label>
                </div>
                <div
                  class="col-md-6"
                  *ngIf="incidentForm.get('neededFirstAid').value === 'Yes'"
                >
                  <label class="labelText"> First Aid Needed By </label>
                  <mat-form-field appearance="outline">
                    <textarea
                      cdkTextareaAutosize
                      matInput
                      [readonly]="disableFormElements"
                      formControlName="firstAidNeededBy"
                      name="firstAidNeededBy"
                    ></textarea>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <label class="labelText">
                    Does this individual have a formal behavior plan?
                  </label>
                  <br />
                  <mat-button-toggle-group
                    formControlName="formalBehaviorPlan"
                    *ngIf="action === 'ADD'"
                  >
                    <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                  <label *ngIf="action === 'VIEW'">{{
                    incidentForm.get('formalBehaviorPlan').value
                  }}</label>
                </div>
              </div>
            </mat-card>
          </div>

          <div class="col-sm-12 col-md-4">
            <mat-card
              appearance="raised"
              class="mat-elevation-z8"
              style="border-radius: 15px"
            >
              <label class="labelText">
                Date <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  formControlName="incidentDate"
                  matInput
                  [matDatepicker]="incidentDatePicker"
                  placeholder="Date"
                  [max]="todaysDate"
                  autoSlashDate
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="incidentDatePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #incidentDatePicker></mat-datepicker>
                <mat-error
                  *ngIf="incidentForm.controls['incidentDate'].invalid"
                >
                  Please select/input a valid date
                </mat-error>
              </mat-form-field>

              <mbsc-datepicker
                [controls]="['time']"
                select="range"
                display="anchored"
                [startInput]="start"
                [endInput]="end"
                [touchUi]="true"
                [inputStyle]="'outline'"
                [labelStyle]="'stacked'"
              ></mbsc-datepicker>

              <label class="labelText">
                Start Time <span style="color: red">*</span>
              </label>
              <mbsc-input
                #start
                formControlName="incidentStartTime"
                [inputStyle]="'outline'"
                labelStyle="blah"
                style="margin: 0px"
                label=""
              ></mbsc-input>

              <br />
              <label class="labelText">
                End Time <span style="color: red">*</span>
              </label>
              <mbsc-input
                #end
                formControlName="incidentEndTime"
                [inputStyle]="'outline'"
                labelStyle="blah"
                style="margin: 0px"
                label=""
              ></mbsc-input>
            </mat-card>
          </div>
        </div>
        <br />

        <div class="row">
          <div class="col-md-12">
            <mat-card
              appearance="raised"
              class="mat-elevation-z8"
              style="border-radius: 15px"
            >
              <label class="labelText" style="font-weight: 900; font-size: 20px"
                >ANTECEDENT</label
              >
              <br />
              <label>Describe what happened BEFORE the Behavior </label>

              <mat-card-content>
                <label class="labelText">What was the individual doing?</label>
                <mat-form-field appearance="outline">
                  <textarea
                    cdkTextareaAutosize
                    matInput
                    [readonly]="disableFormElements"
                    formControlName="individualDoing"
                    name="individualDoing"
                  ></textarea>
                  <mat-error
                    *ngFor="
                      let validation of validation_messages.individualDoing
                    "
                  >
                    <mat-error
                      class="error-message"
                      *ngIf="
                        incidentForm
                          .get('individualDoing')
                          .hasError(validation.type) &&
                        (incidentForm.get('individualDoing').dirty ||
                          incidentForm.get('individualDoing').touched)
                      "
                      >{{ validation.message }}</mat-error
                    >
                  </mat-error>
                </mat-form-field>

                <label class="labelText"
                  >What were the other individuals doing?</label
                >
                <mat-form-field appearance="outline">
                  <textarea
                    cdkTextareaAutosize
                    matInput
                    [readonly]="disableFormElements"
                    formControlName="othersDoing"
                    name="othersDoing"
                  ></textarea>
                  <mat-error
                    *ngFor="let validation of validation_messages.othersDoing"
                  >
                    <mat-error
                      class="error-message"
                      *ngIf="
                        incidentForm
                          .get('othersDoing')
                          .hasError(validation.type) &&
                        (incidentForm.get('othersDoing').dirty ||
                          incidentForm.get('othersDoing').touched)
                      "
                      >{{ validation.message }}</mat-error
                    >
                  </mat-error>
                </mat-form-field>

                <label class="labelText"
                  >What were staff person(s) doing?</label
                >
                <mat-form-field appearance="outline">
                  <textarea
                    cdkTextareaAutosize
                    matInput
                    [readonly]="disableFormElements"
                    formControlName="staffDoing"
                    name="staffDoing"
                  ></textarea>
                  <mat-error
                    *ngFor="let validation of validation_messages.staffDoing"
                  >
                    <mat-error
                      class="error-message"
                      *ngIf="
                        incidentForm
                          .get('staffDoing')
                          .hasError(validation.type) &&
                        (incidentForm.get('staffDoing').dirty ||
                          incidentForm.get('staffDoing').touched)
                      "
                      >{{ validation.message }}</mat-error
                    >
                  </mat-error>
                </mat-form-field>

                <label class="labelText"
                  >How was the environment (i.e. Hot, Cold, Noisy, Crowded,
                  Calm, Quiet, etc.)?</label
                >
                <mat-form-field appearance="outline">
                  <textarea
                    cdkTextareaAutosize
                    matInput
                    [readonly]="disableFormElements"
                    formControlName="environment"
                    name="environment"
                  ></textarea>
                  <mat-error
                    *ngFor="let validation of validation_messages.environment"
                  >
                    <mat-error
                      class="error-message"
                      *ngIf="
                        incidentForm
                          .get('environment')
                          .hasError(validation.type) &&
                        (incidentForm.get('environment').dirty ||
                          incidentForm.get('environment').touched)
                      "
                      >{{ validation.message }}</mat-error
                    >
                  </mat-error>
                </mat-form-field>

                <label class="labelText"
                  >What do you think triggered the behaviors(s)?</label
                >
                <mat-form-field appearance="outline">
                  <textarea
                    cdkTextareaAutosize
                    matInput
                    [readonly]="disableFormElements"
                    formControlName="behaviorTriggeredBy"
                    name="behaviorTriggeredBy"
                  ></textarea>
                  <mat-error
                    *ngFor="
                      let validation of validation_messages.behaviorTriggeredBy
                    "
                  >
                    <mat-error
                      class="error-message"
                      *ngIf="
                        incidentForm
                          .get('behaviorTriggeredBy')
                          .hasError(validation.type) &&
                        (incidentForm.get('behaviorTriggeredBy').dirty ||
                          incidentForm.get('behaviorTriggeredBy').touched)
                      "
                      >{{ validation.message }}</mat-error
                    >
                  </mat-error>
                </mat-form-field>

                <label class="labelText">Other Comments</label>
                <mat-form-field appearance="outline">
                  <textarea
                    cdkTextareaAutosize
                    matInput
                    [readonly]="disableFormElements"
                    formControlName="comments"
                    name="comments"
                  ></textarea>
                </mat-form-field>
              </mat-card-content>
            </mat-card>
          </div>
        </div>

        <br />
        <div class="row">
          <div class="col-md-12">
            <mat-card
              appearance="raised"
              class="mat-elevation-z8"
              style="border-radius: 15px"
            >
              <label class="labelText" style="font-weight: 900; font-size: 20px"
                >BEHAVIOR</label
              >
              <br />
              <label
                >Describe what you saw the individual do DURING the
                Behavior</label
              >

              <mat-card-content>
                <div class="row">
                  <div class="col-sm-6">
                    <label class="labelText">Self Abuse (SIB)</label>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="sb_hitSelf"
                        >Hit Self</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="sb_bitSelf"
                        >Bit Self</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="sb_scratchSelf"
                        >Scratch Self</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="sb_pinchedSelf"
                        >Pinched Self</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="sb_pulledOwnHair"
                        >Pulled Own Hair</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="sb_bangedHead"
                        >Banged Head</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="sb_vomits"
                        >Vomits</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="sb_rumination"
                        >Rumination</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="sb_other"
                        >Other</mat-checkbox
                      >
                    </section>
                  </div>
                  <div class="col-sm-6">
                    <label class="labelText">Physical Aggression</label>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="pa_hitSomeone"
                        >Hit Someone</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="pa_bitSomeone"
                        >Bit Someone</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="pa_scratchSomeone"
                        >Scratched Someone</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="pa_pinchedSomeone"
                        >Pinched Someone</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="pa_pulledHair"
                        >Pulled Hair</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="pa_headButted"
                        >Head Butted</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="pa_pushedPulled"
                        >Pushed/Pulled</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="pa_kickedSomeone"
                        >Kicked Someone</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="pa_threwSomething"
                        >Threw Something</mat-checkbox
                      >
                    </section>
                  </div>

                  <div class="col-sm-6">
                    <label class="labelText">Verbal Aggression</label>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="va_threatsToHurtOthers"
                        >Threats to Hurt Others</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="va_threatsToHurtSelf"
                        >Threats to Hurt Self</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="va_threatsToRunAway"
                        >Threats to Run Away</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="va_threatsToBreakDestroy"
                        >Threats To Break/Destroy</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="va_cursedStaff"
                        >Cursed Staff</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="va_cursedPeer"
                        >Cursed Peer</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="va_sexualComments"
                        >Sexual Comments</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="va_other1"
                        >Other</mat-checkbox
                      >
                    </section>
                  </div>
                  <div class="col-sm-6">
                    <label class="labelText">Other Common Behaviors</label>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="other_refusalToDo"
                        >Refusal to do Something</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="other_stealing"
                        >Stealing</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="other_runningAway"
                        >Running Away</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="other_disrobingInPublic"
                        >Disrobing in Public</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="other_inappropriateMasturbation"
                        >Inappropriate Masturbation</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="other_uncontestedSexualAct"
                        >Uncontested Sexual Act</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="other_propertyDestruction"
                        >Property Destruction</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="other_toiletAccident"
                        >Toilet Accident</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="other_pica"
                        >PICA</mat-checkbox
                      >
                    </section>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label class="labelText"
                      >Behavior: Give specific details including names, injured
                      body parts, items destroyed or if provoked:</label
                    >
                    <mat-form-field appearance="outline">
                      <textarea
                        cdkTextareaAutosize
                        matInput
                        [readonly]="disableFormElements"
                        formControlName="behavior"
                        name="behavior"
                      ></textarea>
                    </mat-form-field>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>

        <br />
        <div class="row">
          <div class="col-md-12">
            <mat-card
              appearance="raised"
              class="mat-elevation-z8"
              style="border-radius: 15px"
            >
              <label class="labelText" style="font-weight: 900; font-size: 20px"
                >CONSEQUENCE</label
              >
              <br />
              <label>Describe what happened AFTER the Behavior</label>

              <mat-card-content>
                <div class="row">
                  <div class="col-sm-6">
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="consequence_plannedIgnore"
                        >Planned Ignore</mat-checkbox
                      >
                    </section>
                    <div
                      *ngIf="
                        incidentForm.get('consequence_plannedIgnore').value
                      "
                    >
                      <mat-label>How long?</mat-label>
                      <mat-form-field appearance="outline">
                        <textarea
                          cdkTextareaAutosize
                          matInput
                          [readonly]="disableFormElements"
                          formControlName="consequence_plannedIgnoreDuration"
                          name="consequence_plannedIgnoreDuration"
                        >
                        </textarea>
                      </mat-form-field>
                    </div>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="consequence_verbalCounseling"
                        >Verbal Counseling</mat-checkbox
                      >
                    </section>
                    <div
                      *ngIf="
                        incidentForm.get('consequence_verbalCounseling').value
                      "
                    >
                      <label class="labelText">How long?</label>
                      <mat-form-field appearance="outline">
                        <textarea
                          matInput
                          cdkTextareaAutosize
                          [readonly]="disableFormElements"
                          formControlName="consequence_verbalCounselingDuration"
                          name="consequence_verbalCounselingDuration"
                        ></textarea>
                      </mat-form-field>
                    </div>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="consequence_redirected"
                        >Redirected</mat-checkbox
                      >
                    </section>
                    <div
                      *ngIf="incidentForm.get('consequence_redirected').value"
                    >
                      <label class="labelText">Redirected to What?</label>
                      <mat-form-field appearance="outline">
                        <textarea
                          matInput
                          cdkTextareaAutosize
                          [readonly]="disableFormElements"
                          formControlName="consequence_redirectedToWhat"
                          name="consequence_redirectedToWhat"
                        ></textarea>
                      </mat-form-field>

                      <label class="labelText">Redirected to Where?</label>
                      <mat-form-field appearance="outline">
                        <textarea
                          matInput
                          cdkTextareaAutosize
                          [readonly]="disableFormElements"
                          formControlName="consequence_redirectedToWhere"
                          name="consequence_redirectedToWhere"
                        ></textarea>
                      </mat-form-field>
                    </div>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="consequence_responseBlock"
                        >Response Block</mat-checkbox
                      >
                    </section>
                    <div
                      *ngIf="
                        incidentForm.get('consequence_responseBlock').value
                      "
                    >
                      <label class="labelText">Response Block Duration</label>
                      <mat-form-field appearance="outline">
                        <textarea
                          matInput
                          cdkTextareaAutosize
                          [readonly]="disableFormElements"
                          formControlName="consequence_responseBlockDuration"
                          name="consequence_responseBlockDuration"
                        ></textarea>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="consequence_manualRestraint"
                        >Manual Restraint</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="consequence_cbaApproved"
                        >CBA Approved</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="consequence_mechanicalRestraint"
                        >Mechanical Restraint</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="consequence_chemicalRestraint"
                        >Chemical Restraint</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="consequence_timeOut"
                        >Time Out</mat-checkbox
                      >
                    </section>
                    <section class="checkbox-section">
                      <mat-checkbox
                        color="primary"
                        class="checkbox-margin"
                        formControlName="consequence_other"
                        >Other</mat-checkbox
                      >
                    </section>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>

        <br />
        <div class="row">
          <div class="col-md-12">
            <mat-card
              appearance="raised"
              class="mat-elevation-z8"
              style="border-radius: 15px"
            >
              <label class="labelText" style="font-weight: 900; font-size: 20px"
                >Consequences</label
              >
              <br />
              <label>Give Specific details on actions taken by staff</label>

              <mat-card-content>
                <mat-form-field appearance="outline">
                  <textarea
                    cdkTextareaAutosize
                    matInput
                    [readonly]="disableFormElements"
                    formControlName="consequences"
                    name="consequences"
                  ></textarea>
                </mat-form-field>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <br />
        <div
          class="col-md-12"
          *ngIf="action === 'ADD'"
          style="text-align: right"
        >
          <button
            color="primary"
            mat-raised-button
            type="submit"
            [disabled]="!incidentForm.valid || isProcessing"
          >
            Submit <i class="fa fa-spinner fa-spin" *ngIf="isProcessing"></i>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
