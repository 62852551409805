import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import {
  CoreService,
  DashboardsService,
  ToastMessageService,
} from 'src/app/_services';
import { FacilitiesSandbox } from 'src/app/shared/sandbox/facilities.sandbox';

@Component({
  selector: 'app-provider-dashboard-header',
  templateUrl: './provider-dashboard-header.component.html',
  styleUrl: './provider-dashboard-header.component.css',
})
export class ProviderDashboardHeaderComponent implements OnInit {
  @Input() reloadHeaderStatistics: Subject<boolean> = new Subject<boolean>();

  loggedInUserId: String;
  loggedInUserName: String;

  loading: boolean = true;
  dashboadStatics: any = [];
  myFacilities: any = [];

  combinedDashboardCards: any = [];
  assessmentsCards: any = [];
  noteCards: any = [];
  otherCards: any = [];

  constructor(
    private coreService: CoreService,
    private router: Router,
    private dashboardsService: DashboardsService,
    private facilitiesSandbox: FacilitiesSandbox,
    private toastMessageService: ToastMessageService
  ) {
    this.loggedInUserId = this.coreService.getLoggedInCareProviderId();
    this.loggedInUserName = this.coreService.getUserDetails().name;
  }

  ngOnInit(): void {
    // First get all the facilities from sandbox
    this.loadMyFacilities();

    // Subcribe for reload Stats event
    this.reloadHeaderStatistics.subscribe((reload) => {
      if (reload) {
        this.loadProviderDashboardStats();
      }
    });
  }

  // Load All my facilities
  loadMyFacilities() {
    this.facilitiesSandbox.facilities$.subscribe((response) => {
      if (response) {
        this.myFacilities = response;

        // Once Facilities are loaded, load the dashboards stats
        this.loadProviderDashboardStats();
      } else {
        // Simply load providers stats
        this.loadProviderDashboardStats();
      }
    });
  }

  loadProviderDashboardStats() {
    this.loading = true;
    this.dashboardsService.getProviderDashboardStats().subscribe({
      next: (response) => {
        this.dashboadStatics = response?.data;
        this.loadDashboardCards();
        this.loading = false;
      },
      error: (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to load the dashboard statistics'
        );
        this.loading = false;
      },
    });
  }

  loadDashboardCards() {
    if (this.dashboadStatics) {
      this.assessmentsCards = [
        {
          title: 'Requested Assessments',
          icon: 'pending_actions',
          iconBackgroundColor: '#42424ad9',
          count: this.dashboadStatics?.pendingAssessmentCount,
          route: 'Pending',
          cursor: 'pointer',
        },
        {
          title: 'Review Assessments',
          icon: 'rate_review',
          iconBackgroundColor: '#49a3f1d9',
          count: this.dashboadStatics?.inReviewAssessmentCount,
          route: 'In Review',
          cursor: 'pointer',
        },
        {
          title: 'Completed Assessments',
          icon: 'assignment_turned_in',
          iconBackgroundColor: '#66bb6ad9',
          count: this.dashboadStatics?.completedAssessmentCount,
          route: 'Completed',
          cursor: 'pointer',
        },
      ];
      this.noteCards = [
        {
          title: 'Active Notes',
          icon: 'pending_actions',
          iconBackgroundColor: '#2196f3',
          count: this.dashboadStatics?.activeSessionCount,
          route: 'notes/Active',
          cursor: 'pointer',
        },
        {
          title: 'Closed Notes',
          icon: 'sports_score',
          iconBackgroundColor: '#3ac47d',
          count: this.dashboadStatics?.completedSessionCount,
          route: 'notes/Closed',
          cursor: 'pointer',
        },
        // {
        //   title: 'Canceled Notes',
        //   icon: 'cancel',
        //   iconBackgroundColor: '#d92550',
        //   count: this.dashboadStatics?.canceledSessionCount,
        //   route: '#',
        //   cursor: 'not-allowed',
        // },
      ];

      this.otherCards = [
        {
          title: 'My Members',
          icon: 'people',
          iconBackgroundColor: '#2196f3',
          count: this.dashboadStatics?.myMembersCount,
          route: 'Members',
          cursor: 'pointer',
        },
        {
          title: 'Assigned Facilities',
          icon: 'home_work',
          iconBackgroundColor: '#3ac47d',
          count: this.myFacilities?.length,
          route: 'Patients',
          cursor: 'pointer',
        },
      ];

      this.combinedDashboardCards = [
        {
          title: 'Notes',
          icon: 'edit_note',
          iconBackgroundColor: '#1dab2fd6',
          cards: this.noteCards,
        },
        {
          title: 'Assessments',
          icon: 'insights',
          iconBackgroundColor: '#ea4b81',
          cards: this.assessmentsCards,
        },
        {
          title: 'General',
          icon: 'dashboard',
          iconBackgroundColor: '#4677cc',
          cards: this.otherCards,
        },
      ];
    }
  }

  navigateTo(route: string) {
    if (route != '#') {
      switch (route) {
        case 'Pending':
        case 'In Review':
        case 'Completed':
          this.router.navigate(['/main/assessments'], {
            state: { status: route },
          });
          break;

        case 'Members':
          this.router.navigate(['/physician/patients']);
          break;

        case 'Patients':
          this.router.navigate(['/patients']);
          break;

        default:
          this.router.navigate(['/' + route]);
          break;
      }
    }
  }
}
