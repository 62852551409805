import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { CoreService } from 'src/app/_services';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { PermissionsSandbox } from 'src/app/shared/sandbox/permissions.sandbox';
import { hasAccess } from 'src/app/shared/utilities/utilities';
import { environment } from 'src/environments/environment';

//Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

@Component({
  selector: 'app-sidebar-cmp',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  isAuthorized = true;
  userName: string = '';
  name: string = '';
  isPhysician: boolean = false;
  isPatient: boolean = false;

  // Org Config
  orgConfig: any = null;
  orgConfigLoaded: boolean = false;
  // Permissions
  hasTherapySessionFeature: boolean = false;
  hasCaseManagementFeature: boolean = false;
  hasIVRServices: boolean = false;
  hasClaimsFeature = false;
  hasClaimsViewAccess = false;
  hasTherapySessionAddAccess = false;
  hasIVRSessionsViewAccess = false;
  hasIVRVoiceMessageViewAccess = false;
  hasIVRVoiceMessageEditAccess = false;
  hasIVRAppointmentsViewAccess = false;
  hasGroupSessionFeature = false;
  hasCaseViewAccess = false;

  accountMenu = [
    {
      path: '/config/manage-settings',
      title: 'Account Settings',
      icontype: 'manage_accounts',
    },
  ];

  constructor(
    private keycloak: KeycloakService,
    private coreService: CoreService,
    protected orgConfigSandbox: OrgConfigSandbox,
    protected permissionsSandbox: PermissionsSandbox
  ) {}

  ngOnInit() {
    this.isPatient = this.coreService.isPatient();
    this.isPhysician = this.coreService.isPhysician();

    this.keycloak.loadUserProfile().then((user) => {
      this.userName = user.username;
      this.name = user.firstName + ' ' + user.lastName;
    });

    this.UnauthorizedListener();

    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        this.orgConfigSandbox.orgConfig$.subscribe((response) => {
          this.orgConfig = response;
          this.orgConfigLoaded = true;
          if (this.orgConfig && this.orgConfig?.features) {
            this.hasCaseManagementFeature =
              this.orgConfig.features.includes('CASE_MANAGEMENT');

            this.hasTherapySessionFeature =
              this.orgConfig.features.includes('THERAPY_MANAGEMENT');

            this.hasIVRServices =
              this.orgConfig.features.includes('IVR_SERVICES');

            this.hasClaimsFeature =
              this.orgConfig.features.includes('ORG_CLAIMS');

            this.hasGroupSessionFeature =
              this.orgConfig.features.includes('GROUP_SESSIONS');

            this.permissionsSandbox.permissions$.subscribe((response) => {
              this.hasClaimsViewAccess = hasAccess(
                this.keycloak,
                'CLAIMS_VIEW',
                response,
                null
              );
              this.hasIVRSessionsViewAccess = hasAccess(
                this.keycloak,
                'IVR_SESSIONS_VIEW',
                response,
                null
              );
              this.hasIVRVoiceMessageViewAccess = hasAccess(
                this.keycloak,
                'IVR_VOICE_MESSAGES_VIEW',
                response,
                null
              );

              this.hasIVRAppointmentsViewAccess = hasAccess(
                this.keycloak,
                'IVR_APPOINTMENTS_VIEW',
                response,
                null
              );

              this.hasTherapySessionAddAccess = hasAccess(
                this.keycloak,
                'THERAPY_SESSIONS_ADD',
                response,
                null
              );

              this.hasCaseViewAccess = hasAccess(
                this.keycloak,
                'CASES_VIEW',
                response,
                null
              );
            });
          }
        });
      }
    });
  }

  UnauthorizedListener() {
    //Subscribe for unauthorized event
    addEventListener('Unauthorized 403', (event) => {
      this.isAuthorized = false;
    });
  }

  public logout() {
    localStorage.clear();
    this.keycloak.logout().then(() => {
      // this.keycloak.clearToken();
    });
  }
}
