<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>General Notes</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form
    [formGroup]="generalNoteForm"
    style="margin-top: 20px"
    (ngSubmit)="updateNote()"
  >
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="outline">
          <textarea
            matInput
            formControlName="sessionLevelNote"
            name="sessionLevelNote"
            spellcheck="true"
            rows="15"
          ></textarea>
        </mat-form-field>
      </div>

      <div
        class="col-md-12"
        style="text-align: right"
        *ngIf="therapySession.status === 'Active' && hasEditAccess"
      >
        <button
          class="btn btn-primary"
          mat-raised-button
          type="submit"
          [disabled]="processing"
        >
          Update <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
