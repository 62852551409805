import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { DataTablesModel, ITherapySession, Patient } from 'src/app/_models';
import {
  PatientService,
  TherapySessionService,
  ToastMessageService,
} from 'src/app/_services';
import { AddEditPatientComponent } from 'src/app/pages/patients/dialogs/add/add.component';

@Component({
  selector: 'app-group-sessions-handle-members',
  templateUrl: './group-sessions-handle-members.component.html',
  styleUrl: './group-sessions-handle-members.component.css',
})
export class GroupSessionsHandleMembersComponent implements OnInit {
  sessionId: string;
  sessionDetails: ITherapySession;
  consolidatedMemberSearch: boolean;
  hasMemberAddAccess: boolean;
  showMemberNumber: boolean;

  reloadRequired: boolean = false;
  membersList: DataTablesModel = {} as DataTablesModel;
  isMembersLoading: boolean = false;
  memberFirstName: string = '';
  memberLastName: string = '';
  memberPhone: string = '';
  displayedColumns = ['memberName', 'address', 'phoneNumber', 'actions'];

  isLoadingDetails: boolean = true;
  processing: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<GroupSessionsHandleMembersComponent>,
    public patientService: PatientService,
    public therapySessionService: TherapySessionService,
    private toastMessageService: ToastMessageService
  ) {
    this.sessionId = data.sessionId;
    this.sessionDetails = data.sessionDetails;
    this.consolidatedMemberSearch = data.consolidatedMemberSearch;
    this.hasMemberAddAccess = data.hasMemberAddAccess;
    this.showMemberNumber = data.showMemberNumber;
  }

  ngOnInit(): void {
    this.membersList.per_page = 10;
    this.membersList.page = 0;

    this.loadMembersList();
  }

  // Search Member Changed
  searchByNameChanged() {
    this.membersList.page = 0;

    this.loadMembersList();
  }

  // Loading Existing Member for this facility
  loadMembersList() {
    this.isMembersLoading = true;

    this.patientService
      .getPatients(
        this.consolidatedMemberSearch
          ? null
          : this.sessionDetails.billingProviderRefId,
        null,
        this.memberFirstName,
        this.memberLastName,
        this.memberPhone,
        'Active',
        this.membersList.per_page,
        this.membersList.page
      )
      .subscribe({
        next: (response) => {
          if (response && response.items) {
            this.membersList.items = response.items;
            this.membersList.total = response.total;
          } else {
            this.membersList.items = [];
            this.membersList.total = 0;
          }
          this.isMembersLoading = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load members at the selected facility'
          );
          this.isMembersLoading = false;
        },
      });
  }

  // Add New Member if the user has Add Member Permission
  addMember() {
    const dialogRef = this.dialog.open(AddEditPatientComponent, {
      data: {
        action: 'ADD',
        patient: null,
        facilityId: this.sessionDetails.billingProviderRefId,
        showMemberNumber: this.showMemberNumber,
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '40vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadMembersList();
      }
    });
  }

  // Handle member selected toggle
  isMemberSelected(selectedMember: Patient) {
    let memberSelected = false;

    this.sessionDetails.groupMembers.forEach((member) => {
      if (member.member.id === selectedMember.id) {
        memberSelected = true;
      }
    });
    return memberSelected;
  }

  getNext(event: PageEvent) {
    this.membersList.page = event.pageIndex;
    this.loadMembersList();
  }

  // Handle group members logic
  addRemoveMemberToGroup(member: any, type: boolean) {
    this.processing = true;

    this.therapySessionService
      .handleGroupSessionMembers(this.sessionId, member.id, type)
      .subscribe({
        next: (response) => {
          this.sessionDetails = response.data;
          this.processing = false;
          this.reloadRequired = true;
        },
        error: (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to edit group members'
          );
        },
      });
  }
}
