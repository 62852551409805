import { Component, Input, OnInit } from '@angular/core';
import { Patient } from 'src/app/_models';

@Component({
  selector: 'app-member-payments',
  templateUrl: './member-payments.component.html',
  styleUrl: './member-payments.component.css',
})
export class MemberPaymentsComponent implements OnInit {
  @Input() patient: Patient;
  @Input() hasMemberPaymentsAddAccess: boolean;
  @Input() hasMemberPaymentsEditAccess: boolean;
  @Input() hasMemberPaymentsDeleteAccess: boolean;
  @Input() hasTherapySessionViewAccess: boolean;

  activeTab: string = 'Transactions';

  constructor() {}

  ngOnInit(): void {}
}
