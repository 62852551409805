<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Edit Goal</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="patientAssessmentGoalForm" style="padding-top: 10px">
    <div class="row">
      <div class="col-md-12">
        <label class="labelText">
          Goal Description <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <textarea
            matInput
            formControlName="description"
            placeholder="Description"
            readonly
            rows="3"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <label class="labelText">
          Expected Completion Date<span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            [matDatepicker]="picker"
            matInput
            [min]="todaysDate"
            formControlName="targetCompletionDate"
            autoSlashDate
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <label class="labelText">
          Follow Up <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="followUp"
            (selectionChange)="changeFollowUp()"
          >
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-6" *ngIf="showFollowUp">
        <label class="labelText">
          Follow Up # <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="followUpNumber"
            placeholder="Follow Up"
            required
          >
            <mat-option *ngFor="let num of numbers" [value]="num">
              {{ num }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6" *ngIf="showFollowUp">
        <label class="labelText">
          Follow Up Type <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="followUpType"
            placeholder="Follow Up"
            required
          >
            <mat-option value="Days">Day(s)</mat-option>
            <mat-option value="Weeks">Week(s)</mat-option>
            <mat-option value="Months">Month(s)</mat-option>
            <mat-option value="Years">Year(s)</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div
      class="col-md-12"
      style="padding: 0; display: flex; justify-content: space-between"
    >
      <button mat-raised-button mat-dialog-close type="button">Cancel</button>
      <button
        class="btn-primary"
        type="button"
        color="primary"
        mat-raised-button
        [disabled]="editProcessing || patientAssessmentGoalForm.invalid"
        (click)="updateGoal()"
      >
        <span *ngIf="!editProcessing">Update</span>
        <span *ngIf="editProcessing"
          >Updating <i class="fa fa-spinner fa-spin"></i
        ></span>
      </button>
    </div>
  </form>
</div>
