import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Case, DataTablesModel, ResponseModal } from '../../_models';

@Injectable({ providedIn: 'root' })
export class CaseApiService {
  constructor(private httpClient: HttpClient) {}

  getCases(
    patientId: string,
    caseNumber: string,
    description: string,
    caseManager: string,
    status: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('patientId', patientId);

    if (caseNumber) params = params.append('caseNumber', caseNumber);
    if (description) params = params.append('description', description);
    if (caseManager) params = params.append('caseManager', caseManager);
    if (status) params = params.append('status', status);

    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(`${environment.apiUrl}/cases`, {
      params: params,
    });
  }

  getMyAssignedCases(
    memberName: string,
    caseNumber: string,
    description: string,
    status: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();

    if (memberName) params = params.append('memberName', memberName);
    if (caseNumber) params = params.append('caseNumber', caseNumber);
    if (description) params = params.append('description', description);
    if (status) params = params.append('status', status);

    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/mycases`,
      {
        params: params,
      }
    );
  }

  getCaseDetails(caseId: string): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/case/` + caseId
    );
  }

  addCase(thisCase: Case): Observable<Case> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<Case>(`${environment.apiUrl}/case`, thisCase, { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateCase(thisCase: Case): Observable<Case> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<Case>(`${environment.apiUrl}/case`, thisCase, { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  deleteCase(id: string): Observable<void> {
    return this.httpClient
      .delete<void>(`${environment.apiUrl}/case/` + id)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getCaseTypes(): Observable<DataTablesModel> {
    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/casetypes-options`
    );
  }

  getServiceTypes(): Observable<DataTablesModel> {
    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/casetypes-servicetypes`
    );
  }

  getAllActiveServiceOptions(): Observable<DataTablesModel> {
    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/casetypes-servicetypes-services`
    );
  }

  getAllActiveGrantProviders(): Observable<DataTablesModel> {
    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/activegrantproviders`
    );
  }
}
