export const AutoAccidentQualifier = [
  { id: '454', name: 'Initial Treatment' },
  { id: '304', name: 'Latest Visit or Consultation' },
  { id: '453', name: 'Acute Manifestation of a Chronic Condition' },
  { id: '439', name: 'Accident' },
  { id: '455', name: 'Last X-ray' },
  { id: '471', name: 'Prescription' },
  { id: '090', name: 'Report Start (Assumed Care Date)' },
  { id: '091', name: 'Report End (Relinquished Care Date)' },
  { id: '444', name: 'First Visit or Consultation' },
];
