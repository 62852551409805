import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { DataTablesModel, Patient } from 'src/app/_models';
import { IMemberPharmacy } from 'src/app/_models/patient/memberpharmacy.model';
import { ToastMessageService } from 'src/app/_services';
import { MemberPharmacyApiService } from 'src/app/_services/patient/memberpharmacy.service';
import { AddEditMemberpharmacyDialogComponent } from './dialogs/add-edit-memberpharmacy-dialog/add-edit-memberpharmacy-dialog.component';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-member-pharmacy',
  styleUrls: ['member-pharmacy.component.css'],
  templateUrl: 'member-pharmacy.component.html',
})
export class MemberPharmacyComponent implements OnInit, OnChanges {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() patient: Patient;

  displayedColumns = [
    'pharmacyName',
    'address1',
    'address2',
    'city',
    'state',
    'zip',
    'phoneNumber',
  ];

  data: DataTablesModel = {} as DataTablesModel;

  searchName: string = '';
  searchStatus: string = 'Active';
  isLoadingResults = false;
  apiError = false;

  constructor(
    public dialog: MatDialog,
    public memberpharmacyApiService: MemberPharmacyApiService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.patient) {
      this.data.page = 0;
      this.data.per_page = 10;

      this.loadMemberPharmacyList();
    }
  }

  // Load All Pharmacies
  loadMemberPharmacyList() {
    this.isLoadingResults = true;
    this.apiError = false;

    this.memberpharmacyApiService
      .getMemberPharmacyList(
        this.patient.id,
        this.searchName,
        this.searchStatus,
        this.data.per_page,
        this.data.page
      )
      .subscribe({
        next: (response) => {
          this.isLoadingResults = false;
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load pharmacies'
          );
          this.apiError = true;
          this.isLoadingResults = false;
        },
      });
  }

  addMemberPharmacy() {
    const dialogRef = this.dialog.open(AddEditMemberpharmacyDialogComponent, {
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
      data: {
        action: 'ADD',
        patientId: this.patient.id,
      },
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadMemberPharmacyList();
      }
    });
  }

  public editMemberPharmacy(memberpharmacy: IMemberPharmacy) {
    const dialogRef = this.dialog.open(AddEditMemberpharmacyDialogComponent, {
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
      data: {
        action: 'EDIT',
        patientId: this.patient.id,
        memberpharmacy: memberpharmacy,
      },
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadMemberPharmacyList();
      }
    });
  }

  deleteMemberPharmacy(memberpharmacy: IMemberPharmacy) {
    const dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message: 'Are you sure that you want to remove this pharmacy?',
      },
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.memberpharmacyApiService
          .deleteMemberPharmacy(memberpharmacy.id)
          .subscribe({
            next: (response) => {
              this.toastMessageService.displaySuccessMessage(
                'Successfully removed the pharmacy'
              );
              // Handling delete pagination
              if (this.data.items.length == 1) {
                this.data.page = this.data.page - 1;
                if (this.data.page < 0) {
                  this.data.page = 0;
                }
              }
              // Load new list
              this.loadMemberPharmacyList();
            },
            error: (error) => {
              this.toastMessageService.displayErrorMessage(
                'Error: Failed to remove the pharmacy'
              );
            },
          });
      }
    });
  }

  markAsPrimary(memberpharmacy: IMemberPharmacy) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Please Confirm',
        msg: 'Are you sure that you want to mark this pharmacy as primary?',
        yesButtonTitle: 'Yes',
        noButtonTitle: 'No',
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '40vw',
    });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.memberpharmacyApiService
          .markAsPrimary(memberpharmacy.id)
          .subscribe({
            next: (response) => {
              this.toastMessageService.displaySuccessMessage(
                'Successfully marked the pharmacy as primary'
              );

              // Load new list
              this.loadMemberPharmacyList();
            },
            error: (error) => {
              this.toastMessageService.displayErrorMessage(
                'Error: Failed to mark the pharmacy as primary'
              );
            },
          });
      }
    });
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadMemberPharmacyList();
  }

  filterOptionChanged(filterOption: string) {
    this.data.page = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.searchStatus = filterOption;
    this.loadMemberPharmacyList();
  }

  searchChanged() {
    this.data.page = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }

    this.loadMemberPharmacyList();
  }
}
