import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Patient } from 'src/app/_models';
import { ToastMessageService } from 'src/app/_services';
import { ConsentDocumentApiService } from 'src/app/_services/consent-docs/orgconsentdocument.service';
import { CustomFormApiService } from 'src/app/_services/custom-forms/customforms.service';
import { IntakeDocumentsList } from 'src/app/shared/utilities/calendar/intakeDocumentsList';

@Component({
  selector: 'app-request-intake-documents',
  templateUrl: './request-intake-documents.component.html',
  styleUrl: './request-intake-documents.component.css',
})
export class RequestIntakeDocumentsComponent implements OnInit {
  intakeDocumentsList = IntakeDocumentsList;
  newDocumentsForm: FormGroup;
  processing: boolean = false;
  patient: Patient;

  constructor(
    public dialogRef: MatDialogRef<RequestIntakeDocumentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private consentDocumentApiService: ConsentDocumentApiService,
    public customformApiService: CustomFormApiService,
    private toastMessageService: ToastMessageService,
    private formBuilder: FormBuilder
  ) {
    this.patient = data.patient;
  }

  ngOnInit(): void {
    this.buildForm();

    this.getIntakeDocumentsList();
  }

  // Get Intake Documents list
  getIntakeDocumentsList() {
    this.consentDocumentApiService
      .getOrganizationAllIntakeDocuments()
      .subscribe({
        next: (response) => {
          if (response && response.data) {
            let consentDocuments = response.data?.consentDocuments;
            let profileForms = response.data?.profileForms;
            let medicalIntakeForms = response.data?.medicalIntakeForms;

            // Now update the intakeDocumentsList
            this.intakeDocumentsList.forEach((documentType) => {
              if (documentType.id === 'consentDocuments') {
                documentType.documents = consentDocuments;
              } else if (documentType.id === 'profileForms') {
                documentType.documents = profileForms;
              } else if (documentType.id === 'medicalIntakeForms') {
                documentType.documents = medicalIntakeForms;
              }
            });
            this.loadCustomFormList();
          }
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load intake document categories.'
          );
        },
      });
  }

  loadCustomFormList() {
    this.customformApiService.getActiveAndPublishedForms().subscribe({
      next: (response) => {
        if (response && response.items) {
          const documents: { [key: string]: any }[] = [];

          this.intakeDocumentsList.forEach((documentType) => {
            if (documentType.id === 'customForms') {
              response.items.forEach((item) => {
                // Create a new object with the updated keys
                const updatedItem: { [key: string]: any } = {
                  documentName: item.formName,
                  id: item.versionId,
                  documentType: 'Custom Forms',
                  consentRenewal: 'Yearly',
                };
                documents.push(updatedItem);
              });
              documentType.documents = documents;
            }
          });
          // Filter the empty ones
          this.intakeDocumentsList = this.intakeDocumentsList.filter(
            (document) =>
              document.documents != null && document.documents.length > 0
          );
        }
      },
      error: (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to load custom forms.'
        );
      },
    });
  }

  buildForm() {
    this.newDocumentsForm = this.formBuilder.group({
      documents: new FormControl([], Validators.required),
    });
  }

  removeIntakeDocument(document, index) {
    let existingIntakeDocuments = this.newDocumentsForm.controls['documents']
      .value as [];

    existingIntakeDocuments.splice(index, 1);

    this.newDocumentsForm.controls['documents'].setValue(
      existingIntakeDocuments
    );
  }

  submitForm() {
    let payload = this.newDocumentsForm.controls['documents'].value;
    this.processing = true;

    this.consentDocumentApiService
      .requestNewConsentDocument(payload, this.patient.id)
      .subscribe({
        next: (response) => {
          this.processing = false;
          this.toastMessageService.displaySuccessMessage(
            'Successfully requested the document(s)'
          );
          this.dialogRef.close('success');
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to request the document(s)'
          );
          this.processing = false;
        },
      });
  }
}
