<div style="display: flex; justify-content: center">
  <mat-card appearance="raised" class="mat-elevation-z8" style="border-radius: 20px; max-width: 650px">
    <div class="row" style="margin-top: 25px; margin-bottom: 30px">
      <div class="col-md-12" style="text-align: center">
        <mat-icon style="color: #4caf50; font-size: 70px; width: 100%; height: auto">
          check_circle
        </mat-icon>
        <label style="font-size: 18px; margin-top: 25px; width: 100%">
          Thank you for completing the assessment!
        </label>
        <label style="width: 85%">
          Your assessment has been successfully submitted to the Clinician for
          review.
        </label>
      </div>
      <div class="col-md-12" style="text-align: center; margin-top: 20px">
        <button style="border-radius: 25px" type="button" mat-raised-button color="primary" routerLink="/facilityOTP">
          New Appointment
        </button>
      </div>
    </div>
  </mat-card>
</div>