import {
  Component,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { CoreService, ToastMessageService } from 'src/app/_services';
import { AssessmentGoalHttpService } from 'src/app/_services/assessments/assessmentgoal.service';
import { AssessmentGoalNotesComponent } from './dialogs/assessment-goal-notes/assessment-goal-notes.component';
import { EditAssessmentGoalComponent } from './dialogs/edit-assessment-goal/edit-assessment-goal.component';
import { AssessmentFollowUpComponent } from './dialogs/assessment-follow-up/assessment-follow-up.component';
import { GoalNotesByPatientComponent } from './dialogs/goal-notes-by-patient/goal-notes-by-patient.component';

@Component({
  selector: 'app-view-patient-assessment-goals',
  templateUrl: './view-patient-assessment-goals.component.html',
  styleUrls: ['./view-patient-assessment-goals.component.css'],
})
export class ViewPatientAssessmentGoalsComponent implements OnInit {
  @ViewChild('callDeleteConfirmation') callDeleteConfirmation: TemplateRef<any>;
  @ViewChild('callCompleteConfirmation')
  callCompleteConfirmation: TemplateRef<any>;
  assessmentRequest: any;

  assessmentId: string = '';
  assessmentRequestId: string = '';
  patientId: string = '';
  assessmentType: string = '';
  organizationId: string = '';

  isLoadingResults: boolean = false;
  goalsList: any = [];

  completeDialogRef: any;
  goalMarkCompleted: any;
  completeProcessing: boolean = false;

  deleteDialogRef: any;
  goalToBeDeleted: any;
  deleteProcessing: boolean = false;

  reloadRequired: string = 'No';
  isPatient = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ViewPatientAssessmentGoalsComponent>,
    private goalsApiService: AssessmentGoalHttpService,
    private dialog: MatDialog,
    private toastMessageService: ToastMessageService,
    private coreService: CoreService
  ) {
    this.assessmentRequest = data.record;
    this.assessmentId = data.record.id;
    this.assessmentRequestId = data.record.assessmentRequestId;
    this.patientId = data.record.patientId;
    this.assessmentType = data.record.assessmentType;
    this.organizationId = data.record.organizationId;
  }

  ngOnInit(): void {
    this.isPatient = this.coreService.isPatient();
    // Load goals for this assessment
    this.loadAssessmentGoals();
  }

  loadAssessmentGoals() {
    this.isLoadingResults = true;
    this.goalsApiService
      .getPatientAssessmentGoals(
        this.assessmentId,
        this.assessmentRequestId,
        this.patientId,
        this.assessmentType,
        this.organizationId
      )
      .subscribe(
        (response) => {
          if (response && response.length > 0) {
            this.goalsList = response;
          } else {
            this.goalsList = [];
          }
          this.isLoadingResults = false;
        },
        (error) => {
          this.goalsList = [];
          this.isLoadingResults = false;
        }
      );
  }

  viewGoalNotes(goal) {
    let goalDialogRef = this.dialog.open(AssessmentGoalNotesComponent, {
      disableClose: true,
      autoFocus: false,
      data: { goal: goal, isPatient: this.isPatient },
      minWidth: '40vw',
    });
    goalDialogRef.afterClosed().subscribe((response) => {
      if (response === 'reload') {
        this.loadAssessmentGoals();
        this.reloadRequired = 'reload';
      }
    });
  }

  viewOnlyNotes(goal, role) {
    this.dialog.open(GoalNotesByPatientComponent, {
      disableClose: true,
      autoFocus: false,
      data: { goal: goal, role: role },
      minWidth: '40vw',
    });
  }

  editGoal(goal) {
    let editDialogRef = this.dialog.open(EditAssessmentGoalComponent, {
      data: { goal: goal },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });
    editDialogRef.afterClosed().subscribe((response) => {
      if (response === 'reload') {
        this.loadAssessmentGoals();
        this.reloadRequired = 'reload';
      }
    });
  }

  viewFollowUp(goal) {
    let followUpDialogRef = this.dialog.open(AssessmentFollowUpComponent, {
      data: { goal: goal, isPatient: this.isPatient },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });
    followUpDialogRef.afterClosed().subscribe((response) => {
      if (response === 'reload') {
        this.loadAssessmentGoals();
        this.reloadRequired = 'reload';
      }
    });
  }

  markGoalAsComplete(goal) {
    this.goalMarkCompleted = goal;
    this.completeDialogRef = this.dialog.open(this.callCompleteConfirmation);
  }

  closeCompleteDialog(response) {
    if (response === 'Yes') {
      this.completeProcessing = true;
      this.goalsApiService.markGoalAsComplete(this.goalMarkCompleted).subscribe(
        (response) => {
          this.completeProcessing = false;
          this.toastMessageService.displaySuccessMessage(
            'Goal ' +
              this.goalMarkCompleted.goalIndex +
              ' has been marked as completed.'
          );
          this.loadAssessmentGoals();
          this.completeDialogRef.close();
          this.completeDialogRef = null;
          this.goalMarkCompleted = null;
          this.reloadRequired = 'reload';
        },
        (error) => {
          this.completeProcessing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to mark the goal as completed'
          );
        }
      );
    } else {
      this.completeDialogRef.close();
      this.completeDialogRef = null;
      this.goalMarkCompleted = null;
    }
  }

  deleteGoal(goal) {
    this.goalToBeDeleted = goal;
    this.deleteDialogRef = this.dialog.open(this.callDeleteConfirmation);
  }

  closeDeleteDialog(response) {
    if (response === 'Yes') {
      this.deleteProcessing = true;
      this.goalsApiService
        .deletePatientAssessmentGoal(this.goalToBeDeleted)
        .subscribe(
          (response) => {
            this.deleteProcessing = false;
            this.toastMessageService.displaySuccessMessage(
              'Goal ' + this.goalToBeDeleted.goalIndex + ' has been deleted.'
            );
            this.loadAssessmentGoals();
            this.deleteDialogRef.close();
            this.deleteDialogRef = null;
            this.goalToBeDeleted = null;
            this.reloadRequired = 'reload';
          },
          (error) => {
            this.deleteProcessing = false;
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to delete goal'
            );
          }
        );
    } else {
      this.deleteDialogRef.close();
      this.deleteDialogRef = null;
      this.goalToBeDeleted = null;
    }
  }
}
