import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { DataTablesModel, ITherapySession, Patient } from 'src/app/_models';
import { DiagnosisApiService } from 'src/app/_services';
import { AddDiagnosisComponent } from '../../../../diagnosis-codes/add-diagnosis/add-diagnosis.component';

@Component({
  selector: 'app-existing-diagnosis',
  templateUrl: './existing-diagnosis.component.html',
  styleUrls: ['./existing-diagnosis.component.css'],
})
export class ExistingDiagnosisComponent implements OnInit {
  therapySession: ITherapySession;
  patient: Patient;
  loggedInUserId: string;

  existingDiagnosisList: DataTablesModel = {} as DataTablesModel;
  isLoadingResults: boolean = true;

  displayedColumns = ['code', 'description', 'action'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ExistingDiagnosisComponent>,
    private dialog: MatDialog,
    public diagnosisApiService: DiagnosisApiService
  ) {
    this.patient = data.patient;
    this.therapySession = data.therapySession;
    this.loggedInUserId = data.loggedInUserId;
  }

  ngOnInit(): void {
    this.existingDiagnosisList.page = 0;
    this.existingDiagnosisList.per_page = 100;

    this.loadExistingDiagnosis();
  }

  public loadExistingDiagnosis() {
    this.isLoadingResults = true;

    this.diagnosisApiService
      .getDiagnosisList(
        this.patient.id,
        'Active',
        this.existingDiagnosisList.per_page,
        this.existingDiagnosisList.page
      )
      .subscribe(
        (response) => {
          this.isLoadingResults = false;
          if (response && response.items) {
            this.existingDiagnosisList.items = response.items;
            this.existingDiagnosisList.total = response.total;
          }
          if (response == null) {
            this.existingDiagnosisList.items = [];
            this.existingDiagnosisList.total = 0;
          }
        },
        (error) => {
          this.isLoadingResults = false;
        }
      );
  }

  selectDiagnosis(diagnosis) {
    this.dialogRef.close({ type: 'success', diagnosis: diagnosis });
  }

  checkIfAlreadyExists(icd) {
    let diagnosisCodes = this.therapySession?.diagnosisCodes;
    if (diagnosisCodes && diagnosisCodes.length > 0) {
      let filteredDiagnosis = diagnosisCodes.findIndex(
        (element) => element.icdCode == icd.icdCode
      );
      if (filteredDiagnosis < 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  getNext(event: PageEvent) {
    this.existingDiagnosisList.page = event.pageIndex;
    this.loadExistingDiagnosis();
  }

  addNewDiagnosis() {
    let addDialogRef = this.dialog.open(AddDiagnosisComponent, {
      data: { patient: this.patient, parentClass: 'Session' },
      disableClose: true,
      autoFocus: false,
      minWidth: '50vw',
    });

    addDialogRef.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        this.dialogRef.close({
          type: 'success',
          diagnosis: response.diagnosis,
        });
      }
    });
  }
}
