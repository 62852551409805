import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../store';
import {
  getFacilityListAction,
  getFacilityResourcesAction,
} from '../store/actions/facility.actions';
import {
  getFacilityData,
  getFacilityDataError,
  getFacilityDataLoading,
  getFacilityResourcesData,
} from '../store/selectors/facility.selectors';
import { Sandbox } from './base.sandbox';

@Injectable()
export class FacilitiesSandbox extends Sandbox {
  constructor(protected appState$: Store<State>) {
    super(appState$);
  }

  public facilities$ = this.appState$.select(getFacilityData);

  public facilitiesLoading$ = this.appState$.select(getFacilityDataLoading);

  public facilitiesError$ = this.appState$.select(getFacilityDataError);

  public facilityResources$ = this.appState$.select(getFacilityResourcesData);

  public triggerFacilitiesLoad() {
    this.appState$.dispatch(getFacilityListAction());
  }

  public triggerFacilityResourcesLoad() {
    this.appState$.dispatch(getFacilityResourcesAction());
  }
}
