<div class="row">
  <p style="font-size: 16px; font-weight: 450; margin-left: 10px">
    <a routerLink="/" routerLinkActive="active">
      <em
        class="material-icons text_align-center"
        style="font-size: 18px; margin-top: -3px"
        >home</em
      > </a
    >&nbsp; / &nbsp; Organizational Documents
  </p>
</div>

<div class="gridContainer" *ngIf="data && data.total > 0">
  <mat-card
    appearance="raised"
    *ngFor="let category of data.items; let i = index"
    style="border-radius: 15px"
  >
    <mat-card-title>{{ category.name }}</mat-card-title>
    <ng-container *ngIf="!isLoadingFiles">
      <div
        *ngFor="let file of getFilesByCategoryId(category.id)"
        style="margin-bottom: 5px"
      >
        <i
          class="fa fa-dot-circle-o"
          aria-hidden="true"
          style="color: #2196f3"
        ></i>
        <a
          (click)="downloadFile(file.id, category.id)"
          target="_blank"
          style="
            font-weight: 400;
            color: #2196f3;
            cursor: pointer;
            opacity: 0.8;
          "
        >
          {{ file.fileName }}
        </a>
      </div>
    </ng-container>
  </mat-card>
</div>

<mat-paginator
  style="background-color: transparent"
  [length]="data.total"
  [pageSize]="data.per_page"
  (page)="getNext($event)"
  *ngIf="data.total > data.per_page"
>
</mat-paginator>
