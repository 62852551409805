<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Hamiltion Anxiety Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="hamiltonAnxietyForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-3 asterix-after">1. Anxious Mood</label>
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="anxiousMood">
              <mat-button-toggle value="Not Present"
                >Not Present</mat-button-toggle
              >
              <mat-button-toggle value="Mild">Mild</mat-button-toggle>
              <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
              <mat-button-toggle value="Severe">Severe</mat-button-toggle>
              <mat-button-toggle value="Very Severe"
                >Very Severe</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after">2. Tension</label>
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="tension">
              <mat-button-toggle value="Not Present"
                >Not Present</mat-button-toggle
              >
              <mat-button-toggle value="Mild">Mild</mat-button-toggle>
              <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
              <mat-button-toggle value="Severe">Severe</mat-button-toggle>
              <mat-button-toggle value="Very Severe"
                >Very Severe</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after">3. Fears</label>
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="fears">
              <mat-button-toggle value="Not Present"
                >Not Present</mat-button-toggle
              >
              <mat-button-toggle value="Mild">Mild</mat-button-toggle>
              <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
              <mat-button-toggle value="Severe">Severe</mat-button-toggle>
              <mat-button-toggle value="Very Severe"
                >Very Severe</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after">4. Insomnia</label>
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="insomnia">
              <mat-button-toggle value="Not Present"
                >Not Present</mat-button-toggle
              >
              <mat-button-toggle value="Mild">Mild</mat-button-toggle>
              <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
              <mat-button-toggle value="Severe">Severe</mat-button-toggle>
              <mat-button-toggle value="Very Severe"
                >Very Severe</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after">5. Intellectual</label>
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="intellectual">
              <mat-button-toggle value="Not Present"
                >Not Present</mat-button-toggle
              >
              <mat-button-toggle value="Mild">Mild</mat-button-toggle>
              <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
              <mat-button-toggle value="Severe">Severe</mat-button-toggle>
              <mat-button-toggle value="Very Severe"
                >Very Severe</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after">6. Depressed Mood</label>
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="depressedMood">
              <mat-button-toggle value="Not Present"
                >Not Present</mat-button-toggle
              >
              <mat-button-toggle value="Mild">Mild</mat-button-toggle>
              <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
              <mat-button-toggle value="Severe">Severe</mat-button-toggle>
              <mat-button-toggle value="Very Severe"
                >Very Severe</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after">7. Somatic (muscular)</label>
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="somaticMuscular">
              <mat-button-toggle value="Not Present"
                >Not Present</mat-button-toggle
              >
              <mat-button-toggle value="Mild">Mild</mat-button-toggle>
              <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
              <mat-button-toggle value="Severe">Severe</mat-button-toggle>
              <mat-button-toggle value="Very Severe"
                >Very Severe</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after">8. Somatic (sensory)</label>
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="somaticSensory">
              <mat-button-toggle value="Not Present"
                >Not Present</mat-button-toggle
              >
              <mat-button-toggle value="Mild">Mild</mat-button-toggle>
              <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
              <mat-button-toggle value="Severe">Severe</mat-button-toggle>
              <mat-button-toggle value="Very Severe"
                >Very Severe</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >9. Cardiovascular Symptoms</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="cardiovascularSymptoms">
              <mat-button-toggle value="Not Present"
                >Not Present</mat-button-toggle
              >
              <mat-button-toggle value="Mild">Mild</mat-button-toggle>
              <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
              <mat-button-toggle value="Severe">Severe</mat-button-toggle>
              <mat-button-toggle value="Very Severe"
                >Very Severe</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after">10. Respiratory Symptoms</label>
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="respiratorySymptoms">
              <mat-button-toggle value="Not Present"
                >Not Present</mat-button-toggle
              >
              <mat-button-toggle value="Mild">Mild</mat-button-toggle>
              <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
              <mat-button-toggle value="Severe">Severe</mat-button-toggle>
              <mat-button-toggle value="Very Severe"
                >Very Severe</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >11. Gastrointestinal Symptoms</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="gastrointestinalSymptoms">
              <mat-button-toggle value="Not Present"
                >Not Present</mat-button-toggle
              >
              <mat-button-toggle value="Mild">Mild</mat-button-toggle>
              <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
              <mat-button-toggle value="Severe">Severe</mat-button-toggle>
              <mat-button-toggle value="Very Severe"
                >Very Severe</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >12. Genitourinary Symptoms</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="genitourinarySymptoms">
              <mat-button-toggle value="Not Present"
                >Not Present</mat-button-toggle
              >
              <mat-button-toggle value="Mild">Mild</mat-button-toggle>
              <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
              <mat-button-toggle value="Severe">Severe</mat-button-toggle>
              <mat-button-toggle value="Very Severe"
                >Very Severe</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after">13. Autonomic Symptoms</label>
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="autonomicSymptoms">
              <mat-button-toggle value="Not Present"
                >Not Present</mat-button-toggle
              >
              <mat-button-toggle value="Mild">Mild</mat-button-toggle>
              <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
              <mat-button-toggle value="Severe">Severe</mat-button-toggle>
              <mat-button-toggle value="Very Severe"
                >Very Severe</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >14. Behavior at Interview</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="behaviorAtInterview">
              <mat-button-toggle value="Not Present"
                >Not Present</mat-button-toggle
              >
              <mat-button-toggle value="Mild">Mild</mat-button-toggle>
              <mat-button-toggle value="Moderate">Moderate</mat-button-toggle>
              <mat-button-toggle value="Severe">Severe</mat-button-toggle>
              <mat-button-toggle value="Very Severe"
                >Very Severe</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          hamiltonAnxietyForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!hamiltonAnxietyForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
