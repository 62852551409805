<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>NPI Registry</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="row" style="padding-bottom: 25px">
    <!-- Search Box -->
    <div class="col-md-12" *ngIf="type === 'NEW'">
      <div class="row">
        <div class="col-8">
          <div
            style="
              display: flex;
              align-items: center;
              padding: 5px;
              border-radius: 10px;
              border: 1px solid rgb(0, 0, 0, 0.2);
              background-color: #ffffff;
              padding: 5px;
            "
          >
            <span
              class="material-icons"
              style="text-align: left; font-size: 20px; opacity: 0.5"
            >
              search
            </span>
            <input
              type="search"
              placeholder="NPI #"
              minlength="10"
              maxlength="10"
              [(ngModel)]="searchNPI"
              style="
                padding-left: 15px;
                width: 100%;
                background: transparent;
                border: 0;
              "
            />
          </div>
        </div>
        <div class="col-4">
          <button
            mat-raised-button
            color="primary"
            type="button"
            (click)="search()"
            [disabled]="isProcessing || !isSearchValid()"
          >
            Search
          </button>
        </div>
      </div>
    </div>

    <!-- Progress Bar -->
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="isProcessing"
      style="margin: 15px"
    ></mat-progress-bar>

    <!-- Individual Box -->
    <div class="col-md-12">
      <div class="row">
        <!-- No Data -->
        <div
          class="col-md-12"
          *ngIf="!isProcessing && !responseCareProvider && npiLoaded"
          style="margin-top: 15px"
        >
          <mat-error>No User with the provided NPI was found.</mat-error>
        </div>
        <!--  Data -->
        <div
          class="col-md-12"
          *ngIf="!isProcessing && responseCareProvider"
          style="margin-top: 15px"
        >
          <div class="col-md-12">
            <label> The user with the following details was found. </label>
            <mat-card
              appearance="raised"
              class="col-md-12 mat-elevation-z8"
              style="margin-bottom: 15px"
            >
              <div class="twoGridContainer">
                <label class="labelText" style="font-weight: 500">Name </label>
                <label class="labelText">
                  {{ responseCareProvider.lastName }},{{ ' ' }}
                  {{ responseCareProvider.firstName }}&nbsp;
                  {{ responseCareProvider.middleName }}&nbsp;
                </label>

                <label class="labelText" style="font-weight: 500">NPI </label>
                <label class="labelText">{{
                  responseCareProvider?.individualNPI
                }}</label>

                <label class="labelText" style="font-weight: 500"
                  >Taxonomy
                </label>
                <label class="labelText">{{
                  responseCareProvider?.taxonomyCode
                }}</label>

                <label class="labelText" style="font-weight: 500"
                  >Address 1
                </label>
                <label class="labelText">
                  {{ responseCareProvider?.addressLine1 }}
                </label>

                <label class="labelText" style="font-weight: 500"
                  >Address 2
                </label>
                <label class="labelText">
                  {{ responseCareProvider?.addressLine2 }}
                </label>

                <label class="labelText" style="font-weight: 500">City </label>
                <label class="labelText">
                  {{ responseCareProvider?.city }}
                </label>

                <label class="labelText" style="font-weight: 500">State </label>
                <label class="labelText">
                  {{ responseCareProvider?.state }}
                </label>

                <label class="labelText" style="font-weight: 500">Zip </label>
                <label class="labelText">
                  {{ responseCareProvider?.zip }}
                </label>
              </div>
            </mat-card>
          </div>
          <div class="col-md-12" style="text-align: center">
            <button
              mat-raised-button
              color="primary"
              type="button"
              (click)="select()"
            >
              Select User
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
