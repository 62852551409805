import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Subject } from 'rxjs';
import { CoreService } from 'src/app/_services';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { PermissionsSandbox } from 'src/app/shared/sandbox/permissions.sandbox';
import { hasAccess } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-provider-dashboard',
  templateUrl: './provider-dashboard.component.html',
  styleUrl: './provider-dashboard.component.css',
})
export class ProviderDashboardComponent implements OnInit {
  loggedInUserId: String;
  reloadHeaderStatistics: Subject<boolean> = new Subject<boolean>();

  orgConfig: any;
  hasCaseManagementFeature: boolean = false;
  hasCaseViewAccess = false;

  constructor(
    private coreService: CoreService,
    protected permissionsSandbox: PermissionsSandbox,
    private keycloakService: KeycloakService,
    private orgConfigSandbox: OrgConfigSandbox
  ) {
    this.loggedInUserId = this.coreService.getLoggedInCareProviderId();
  }

  ngOnInit(): void {
    // Handle Org Config to get features
    this.initializeOrgConfig();

    // Load My Permissions();
    this.loadMyPermissions();
  }

  // Get the required orgconfig Information
  initializeOrgConfig() {
    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        // When load is complete, get the org config from app state
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          // Saving the org config
          this.orgConfig = orgConfig;

          // Check for organization features
          if (this.orgConfig && this.orgConfig?.features) {
            this.hasCaseManagementFeature =
              this.orgConfig.features.includes('CASE_MANAGEMENT');
          }
        });
      }
    });
  }

  // Loading my permissions
  loadMyPermissions() {
    this.permissionsSandbox.permissions$.subscribe((response) => {
      this.hasCaseViewAccess = hasAccess(
        this.keycloakService,
        'CASES_VIEW',
        response,
        null
      );
    });
  }

  reloadHeaderStats(event) {
    if (event.type === 'RELOAD_STATS') {
      this.reloadHeaderStatistics.next(true);
    }
  }
}
