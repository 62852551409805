<mat-card appearance="raised">
  <p
    *ngIf="!slectedOption"
    style="font-size: 16px; font-weight: 400; padding-top: 10px"
  >
    <span style="font-weight: 500"> Print Center </span>
  </p>
  <p
    *ngIf="slectedOption"
    style="font-size: 16px; font-weight: 400; padding-top: 10px"
  >
    <span
      (click)="slectedOption = null"
      style="color: #2196f3; cursor: pointer; font-weight: 500"
    >
      Print Center
    </span>
    &nbsp; / &nbsp; {{ slectedOption }}
  </p>

  <div class="gridContainer" *ngIf="!slectedOption">
    <mat-card
      appearance="raised"
      *ngFor="let individualForm of formList; let i = index"
      style="border-radius: 15px"
      class="matCardBox mat-elevation-z8"
    >
      <p style="font-weight: 800; font-size: 19px">
        {{ individualForm.title }}
      </p>

      <div
        *ngFor="let forms of individualForm.forms"
        style="margin-bottom: 5px"
      >
        <i
          class="fa fa-file-pdf-o"
          aria-hidden="true"
          style="color: #2196f3"
        ></i>
        <a
          (click)="slectedOption = forms.reportName"
          style="
            font-weight: 400;
            color: #2196f3;
            cursor: pointer;
            opacity: 0.8;
          "
        >
          {{ forms.reportName }}
        </a>
      </div>
    </mat-card>
  </div>

  <app-print-carelog-report
    [case]="case"
    *ngIf="slectedOption === 'Care Logs'"
  ></app-print-carelog-report>

  <app-print-bowel-report
    [case]="case"
    *ngIf="slectedOption === 'Bowel Movement Report'"
  ></app-print-bowel-report>

  <app-print-seizure-report
    [case]="case"
    *ngIf="slectedOption === 'Seizure Log Report'"
  ></app-print-seizure-report>

  <app-print-sleep-report
    [case]="case"
    *ngIf="slectedOption === 'Sleep Log Report'"
  ></app-print-sleep-report>
</mat-card>
