<mat-card-title>Member Payment Information</mat-card-title>
<mat-divider></mat-divider>

<div style="padding: 10px">
  <div class="row">
    <div class="col-md-12">
      <button
        mat-button
        type="button"
        [class]="activeTab === 'Cards' ? 'activeTab' : ''"
        (click)="activeTab = 'Cards'"
      >
        Credit & Debit Cards
      </button>
      <button
        mat-button
        type="button"
        (click)="activeTab = 'Transactions'"
        [class]="activeTab === 'Transactions' ? 'activeTab' : ''"
      >
        Transactions
      </button>
    </div>

    <div class="col-md-12" *ngIf="activeTab === 'Cards'">
      <app-member-payments-cards
        [patient]="patient"
        [hasMemberPaymentsAddAccess]="hasMemberPaymentsAddAccess"
        [hasMemberPaymentsDeleteAccess]="hasMemberPaymentsDeleteAccess"
      >
      </app-member-payments-cards>
    </div>

    <div class="col-md-12" *ngIf="activeTab === 'Transactions'">
      <app-member-payments-transactions
        [patient]="patient"
        [hasMemberPaymentsAddAccess]="hasMemberPaymentsAddAccess"
        [hasMemberPaymentsEditAccess]="hasMemberPaymentsEditAccess"
        [hasMemberPaymentsDeleteAccess]="hasMemberPaymentsDeleteAccess"
        [hasTherapySessionViewAccess]="hasTherapySessionViewAccess"
      >
      </app-member-payments-transactions>
    </div>
  </div>
</div>
