export const FamilyConditionsList: any = [
  {
    name: 'I am adopted and do not know biological family history',
    selected: false,
  },
  { name: 'Family History Unknown', selected: false },
  { name: 'Alcohol Abuse', selected: false },
  { name: 'Anemia', selected: false },
  { name: 'Anesthetic Complication', selected: false },
  { name: 'Arthritis', selected: false },
  { name: 'Asthma', selected: false },
  { name: 'Bladder Problems', selected: false },
  { name: 'Bleeding Disease', selected: false },
  { name: 'Breast Cancer', selected: false },
  { name: 'Colon Cancer', selected: false },
  { name: 'Depression', selected: false },
  { name: 'Diabetes', selected: false },
  { name: 'Heart Disease', selected: false },
  { name: 'High Blood Pressure', selected: false },
  { name: 'High Cholesterol', selected: false },
  { name: 'Kidney Disease', selected: false },
  { name: 'Leukemia', selected: false },
  { name: 'Lung/Respiratory Disease', selected: false },
  { name: 'Migraines', selected: false },
  { name: 'Osteoporosis', selected: false },
  { name: 'Other Cancer', selected: false },
  { name: 'Rectal Cancer', selected: false },
  { name: 'Seizures/Convulsions', selected: false },
  { name: 'Severe Allergy', selected: false },
  { name: 'Stroke/CVA of the Brain', selected: false },
  { name: 'Thyroid Problems', selected: false },
  {
    name: 'Mother, Grandmother, or Sister developed heart disease before the age of 65',
    selected: false,
  },
  {
    name: 'Father, Grandfather, or Brother developed heart disease before the age of 55',
    selected: false,
  },
];
