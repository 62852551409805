<div class="row">
  <div class="col-md-12">
    <p style="font-size: 16px; font-weight: 450">
      <a routerLink="/" routerLinkActive="active">
        <em
          class="material-icons text_align-center"
          style="font-size: 18px; margin-top: -3px"
          >home</em
        >
      </a>
      &nbsp; / &nbsp;
      <span style="color: dimgray">Change Password </span>
    </p>
  </div>
</div>

<br />

<form
  (ngSubmit)="onSubmit(formDir)"
  name="passwordForm"
  [formGroup]="passwordForm"
  #formDir="ngForm"
  style="margin-bottom: 30px"
>
  <div class="row">
    <div class="col-md-12">
      <span class="headerText">
        Current Password <span style="color: red">*</span>
      </span>
      <mat-form-field appearance="outline">
        <input
          matInput
          name="currentPassword"
          type="password"
          formControlName="currentPassword"
          required
        />
        <mat-error
          *ngIf="
            currentPassword?.invalid &&
            (currentPassword?.dirty || currentPassword?.touched || isSubmit)
          "
        >
          <span *ngIf="currentPassword?.errors?.required">
            Please enter Current Password.
          </span>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-12">
      <span class="headerText">
        New Password <span style="color: red">*</span>
      </span>
      <mat-form-field appearance="outline">
        <input
          matInput
          name="newPassword"
          [type]="showPassword ? 'text' : 'password'"
          formControlName="newPassword"
          required
        />
        <mat-icon matSuffix (click)="togglePasswordVisibility()">
          {{ showPassword ? 'visibility_off' : 'visibility' }}
        </mat-icon>
        <mat-error
          *ngIf="
            newPassword?.invalid &&
            (newPassword?.dirty || newPassword?.touched || isSubmit)
          "
        >
          <span *ngIf="newPassword.errors?.newIsNotOld">
            New password cannot be same as old password
          </span>
          <span *ngIf="!newPassword.errors?.newIsNotOld">
            Please enter a valid Password.
          </span>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-12">
      <span class="headerText">
        Confirm New Password <span style="color: red">*</span>
      </span>
      <mat-form-field appearance="outline">
        <input
          matInput
          name="confirmation"
          [type]="showPassword ? 'text' : 'password'"
          formControlName="confirmation"
          required
        />
        <mat-icon matSuffix (click)="togglePasswordVisibility()">
          {{ showPassword ? 'visibility_off' : 'visibility' }}
        </mat-icon>
        <mat-error
          *ngIf="
            (confirmation?.invalid &&
              (confirmation?.dirty || confirmation?.touched || isSubmit)) ||
            passwordForm.errors?.mismatch
          "
        >
          <span *ngIf="confirmation?.errors || passwordForm?.errors?.mismatch">
            Password doesn't match
          </span>
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Action Buttons -->
    <div
      class="col-md-12"
      style="
        display: flex;
        justify-content: center;
        row-gap: 20px;
        column-gap: 20px;
      "
    >
      <button mat-raised-button type="button" (click)="reset(formDir)">
        Reset
      </button>

      <button
        color="primary"
        mat-raised-button
        type="submit"
        [disabled]="!passwordForm.valid || processing"
      >
        Update Password
      </button>
    </div>

    <!-- Password Conditions -->
    <div
      class="col-md-12"
      style="display: flex; flex-direction: column; margin-top: 20px"
    >
      <label style="font-weight: 500; font-size: 16px">Password must:</label>
      <label style="display: flex; align-items: center; column-gap: 10px">
        <i
          class="material-icons"
          style="font-size: 14px"
          *ngIf="!newPasswordLengthValid"
        >
          fiber_manual_record
        </i>
        <i
          class="material-icons"
          style="font-size: 14px; color: green"
          *ngIf="newPasswordLengthValid"
        >
          check_circle
        </i>
        Be at least 8 characters long
      </label>
      <label style="display: flex; align-items: center; column-gap: 10px">
        <i
          class="material-icons"
          style="font-size: 14px"
          *ngIf="!newPasswordUpperCaseValid"
        >
          fiber_manual_record
        </i>
        <i
          class="material-icons"
          style="font-size: 14px; color: green"
          *ngIf="newPasswordUpperCaseValid"
        >
          check_circle
        </i>
        Contain at least one uppercase letter</label
      >
      <label style="display: flex; align-items: center; column-gap: 10px">
        <i
          class="material-icons"
          style="font-size: 14px"
          *ngIf="!newPasswordLowerCaseValid"
        >
          fiber_manual_record
        </i>
        <i
          class="material-icons"
          style="font-size: 14px; color: green"
          *ngIf="newPasswordLowerCaseValid"
        >
          check_circle
        </i>
        Contain at least one lowecase letter</label
      >
      <label style="display: flex; align-items: center; column-gap: 10px">
        <i
          class="material-icons"
          style="font-size: 14px"
          *ngIf="!newPasswordOneNumberValid"
        >
          fiber_manual_record
        </i>
        <i
          class="material-icons"
          style="font-size: 14px; color: green"
          *ngIf="newPasswordOneNumberValid"
        >
          check_circle
        </i>
        Contain at least one number</label
      >
      <label style="display: flex; align-items: center; column-gap: 10px">
        <i
          class="material-icons"
          style="font-size: 14px"
          *ngIf="!newPasswordOneSpecialValid"
        >
          fiber_manual_record
        </i>
        <i
          class="material-icons"
          style="font-size: 14px; color: green"
          *ngIf="newPasswordOneSpecialValid"
        >
          check_circle
        </i>
        Contain at least one special character</label
      >
    </div>
  </div>
</form>
