import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterModule, Routes } from '@angular/router';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { CustomDirectivesModule } from 'src/app/_directives/custom-directives.module';
import { AngularMaterialModule } from 'src/app/material-imports';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { MyPatientsComponent } from './my-patients/my-patients.component';

const routes: Routes = [
  {
    path: 'patients',
    component: MyPatientsComponent,
    canActivate: [AuthGuard],
    data: { roles: ['physician'] },
  },
];

@NgModule({
  declarations: [MyPatientsComponent],
  imports: [
    CommonModule,
    FormsModule,
    AngularMaterialModule,
    MatFormFieldModule,
    NgxMaskDirective,
    NgxMaskPipe,
    PipesModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    RouterModule.forChild(routes),
    CustomDirectivesModule,
  ],
  providers: [provideNgxMask()],
})
export class PhysicianModule {}
