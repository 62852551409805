import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { DataTablesModel } from 'src/app/_models';
import { IAssessmentAlert } from 'src/app/_models/configure/assessment-alert.model';
import { CoreService, ToastMessageService } from 'src/app/_services';
import { AssessmentAlertHttpService } from 'src/app/_services/configure/assessment-alert.service';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { AddAlertComponent } from './dialogs/add-alert/add-alert.component';

@Component({
  selector: 'app-assessment-alerts',
  templateUrl: './assessment-alerts.component.html',
  styleUrls: ['./assessment-alerts.component.css'],
})
export class AssessmentAlertsComponent implements OnInit {
  selectedAssessmentType: string;

  data: DataTablesModel = {} as DataTablesModel;
  isLoadingResults = false;
  apiError = false;
  action: string = '';
  error = '';
  displayBreadCrumbs = false;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public assessmentAlertApiService: AssessmentAlertHttpService,
    private toastMessageService: ToastMessageService,
    private coreService: CoreService,
  ) { }

  ngOnInit(): void {
    this.displayBreadCrumbs = !this.coreService.isSuperOrgAdmin() && !this.coreService.isOrgAdmin();
    this.selectedAssessmentType = this.route.snapshot.paramMap.get('id');
    this.data.per_page = 10;
    this.data.page = 0;
    this.loadAssessmentAlerts();
  }

  loadAssessmentAlerts() {
    this.isLoadingResults = true;

    this.assessmentAlertApiService
      .getAssessmentAlerts(
        this.selectedAssessmentType,
        this.data.per_page,
        this.data.page
      )
      .subscribe(
        (response) => {
          this.isLoadingResults = false;
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }
        },
        () => {
          this.isLoadingResults = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to retrieve alerts.'
          );
        }
      );
  }

  addAlert() {
    this.action = 'ADD';
    const dialogRef = this.dialog.open(AddAlertComponent, {
      data: {
        action: this.action,
        selectedAssessmentType: this.selectedAssessmentType,
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '50vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response === 'success') {
        this.loadAssessmentAlerts();
      }
    });
  }

  editAlert(alert: IAssessmentAlert) {
    this.action = 'EDIT';
    const dialogRef = this.dialog.open(AddAlertComponent, {
      data: {
        action: this.action,
        selectedAssessmentType: this.selectedAssessmentType,
        alertDetails: alert,
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '50vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response === 'success') {
        this.loadAssessmentAlerts();
      }
    });
  }

  deleteAlert(alert: IAssessmentAlert) {
    let dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to remove this alert?',
      },
      autoFocus: false,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.isLoadingResults = true;
        this.assessmentAlertApiService.deleteAssessmentAlert(alert).subscribe(
          (response) => {
            this.isLoadingResults = false;
            this.toastMessageService.displaySuccessMessage(
              'Alert has been deleted'
            );
            // Handling delete pagination
            if (this.data.items.length == 1) {
              this.data.page = this.data.page - 1;
              if (this.data.page < 0) {
                this.data.page = 0;
              }
            }
            this.loadAssessmentAlerts();
          },
          (error) => {
            this.isLoadingResults = false;
            this.toastMessageService.displayErrorMessage(
              'Error: Something went wrong. Please try again'
            );
          }
        );
      }
    });
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadAssessmentAlerts();
  }
}
