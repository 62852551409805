import {
  NgxGpAutocompleteDirective,
  NgxGpAutocompleteOptions,
} from '@angular-magic/ngx-gp-autocomplete';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Patient } from 'src/app/_models';
import {
  PatientService,
  ToastMessageService,
  UserApiService,
} from 'src/app/_services/';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { EmploymentStatus } from 'src/app/shared/utilities/billing/employmentStatus';
import { Ethnicity } from 'src/app/shared/utilities/billing/ethnicity';
import { Gender } from 'src/app/shared/utilities/billing/gender';
import { MarriedStatus } from 'src/app/shared/utilities/billing/maritalStatus';
import { PreferredLanguages } from 'src/app/shared/utilities/billing/preferredLanguages';
import { Race } from 'src/app/shared/utilities/billing/race';
import { USStates } from 'src/app/shared/utilities/billing/states';
import { TimeZones } from 'src/app/shared/utilities/billing/timeZones';
import { formatDate, mmddyyyyToDate } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-add-edit-patient',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css'],
})
export class AddEditPatientComponent implements OnInit {
  @ViewChild('ngxPlaces') placesRef: NgxGpAutocompleteDirective;
  options: NgxGpAutocompleteOptions = {
    componentRestrictions: { country: ['US'] },
    types: ['address'],
  };

  patient: Patient;
  action: string;
  isOrgAdmin: boolean;
  facilityId: string;
  maxDate: Date;
  showMemberNumber: boolean;

  public patientForm: FormGroup;

  // Careproviders
  searchCareProvider: string = '';
  allCareProviders: any[];
  filteredCareProvidersList: any[];
  careProvidersLoading: boolean = false;

  orgConfig: any;
  processing: boolean = false;
  origPatientStatus: string = '';

  usStates = USStates;
  genderList = Gender;
  raceList = Race;
  ethnicityList = Ethnicity;
  languageList = PreferredLanguages;
  employmentList = EmploymentStatus;
  timeZoneList = TimeZones;
  marriedStatusList = MarriedStatus;

  showSSN: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddEditPatientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public patientApiService: PatientService,
    private formBuilder: FormBuilder,
    private userApiService: UserApiService,
    private toastMessageService: ToastMessageService
  ) {
    if (data) {
      this.action = data.action;
      this.patient = data.patient;
      this.facilityId = data.facilityId;
      this.isOrgAdmin = data.isOrgAdmin;
      this.showMemberNumber = data.showMemberNumber;
    }
  }

  public ngOnInit() {
    this.maxDate = new Date();
    this.loadAllActiveCareProviders();

    this.buildForm();

    if (this.action === 'EDIT') {
      this.origPatientStatus = this.patient.status;
      this.patientForm.controls['id'].setValue(this.patient.id);
      this.patientForm.controls['organizationId'].setValue(
        this.patient.organizationId
      );
      this.patientForm.controls['facilityId'].setValue(this.patient.facilityId);
      this.patientForm.controls['firstName'].setValue(this.patient.firstName);
      this.patientForm.controls['middleName'].setValue(this.patient.middleName);
      this.patientForm.controls['lastName'].setValue(this.patient.lastName);

      if (this.patient.dateOfBirth && this.patient.dateOfBirth !== '') {
        this.patientForm.controls['dateOfBirth'].setValue(
          mmddyyyyToDate(this.patient.dateOfBirth)
        );
      }

      if (this.patient.deceasedDate && this.patient.deceasedDate !== '') {
        this.patientForm.controls['deceasedDate'].setValue(
          mmddyyyyToDate(this.patient.deceasedDate)
        );
      }

      if (this.patient.dischargedDate) {
        this.patientForm.controls['dischargedDate'].setValue(
          new Date(this.patient.dischargedDate)
        );
      }

      this.patientForm.controls['gender'].setValue(this.patient.gender);

      this.patientForm.controls['ssn'].setValue(this.patient.ssn);
      this.patientForm.controls['genderAtBirth'].setValue(
        this.patient.genderAtBirth
      );

      this.patientForm.controls['addressLine1'].setValue(
        this.patient.addressLine1
      );
      this.patientForm.controls['addressLine2'].setValue(
        this.patient.addressLine2
      );
      this.patientForm.controls['city'].setValue(this.patient.city);
      this.patientForm.controls['state'].setValue(this.patient.state);
      this.patientForm.controls['zip'].setValue(this.patient.zip);
      this.patientForm.controls['restrictedMember'].setValue(
        this.patient.restrictedMember
      );

      this.patientForm.controls['phonePreference'].setValue(
        this.patient.phonePreference
      );
      this.patientForm.controls['phoneNumber'].setValue(
        this.patient.phoneNumber
      );
      this.patientForm.controls['homePhoneNumber'].setValue(
        this.patient.homePhoneNumber
      );
      this.patientForm.controls['workPhoneNumber'].setValue(
        this.patient.workPhoneNumber
      );

      this.patientForm.controls['memberNumber'].setValue(
        this.patient.memberNumber
      );
      this.patientForm.controls['status'].setValue(this.patient.status);
      this.patientForm.controls['preferredLanguage'].setValue(
        this.patient.preferredLanguage
      );
      this.patientForm.controls['email'].setValue(this.patient.email);
      this.patientForm.controls['race'].setValue(this.patient.race);
      this.patientForm.controls['ethnicity'].setValue(this.patient.ethnicity);
      this.patientForm.controls['maritalStatus'].setValue(
        this.patient.maritalStatus
      );
      this.patientForm.controls['employmentStatus'].setValue(
        this.patient.employmentStatus
      );
      this.patientForm.controls['timeZone'].setValue(this.patient.timeZone);
      this.patientForm.controls['pronouns'].setValue(this.patient.pronouns);

      if (this.patient.admittanceDate && this.patient.admittanceDate !== '') {
        this.patientForm.controls['admittanceDate'].setValue(
          mmddyyyyToDate(this.patient.admittanceDate)
        );
      }

      if (this.patient.height) {
        this.patientForm.controls['height'].setValue(this.patient.height);
      }
      if (this.patient.weight) {
        this.patientForm.controls['weight'].setValue(this.patient.weight);
      }

      this.patientForm.controls['primaryCPId'].setValue(
        this.patient.primaryCPId
      );
      this.patientForm.controls['primaryCPFirstName'].setValue(
        this.patient.primaryCPFirstName
      );
      this.patientForm.controls['primaryCPMiddleName'].setValue(
        this.patient.primaryCPMiddleName
      );
      this.patientForm.controls['primaryCPLastName'].setValue(
        this.patient.primaryCPLastName
      );
      this.patientForm.controls['primaryCPEmail'].setValue(
        this.patient.primaryCPEmail
      );
      this.patientForm.controls['primaryCPPhone'].setValue(
        this.patient.primaryCPPhone
      );
    }
  }

  // Loading all active care providers
  loadAllActiveCareProviders() {
    this.careProvidersLoading = true;

    this.userApiService.getAllActiveCareProviders(this.facilityId).subscribe({
      next: (response) => {
        if (response && response.items) {
          this.allCareProviders = response.items;
          this.filteredCareProvidersList = response.items;
        } else {
          this.allCareProviders = [];
          this.filteredCareProvidersList = [];
        }
        this.careProvidersLoading = false;
      },
      error: (error) => {
        this.careProvidersLoading = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to load active case managers'
        );
      },
    });
  }

  // Filter care providers
  filterCareProviders() {
    if (this.searchCareProvider) {
      this.filteredCareProvidersList = this.allCareProviders.filter(
        (provider) => {
          const concatFirstLast = provider.firstName + ' ' + provider.lastName;
          const concatLastFirst = provider.lastName + ' ' + provider.firstName;

          if (
            concatFirstLast
              .toLowerCase()
              .includes(this.searchCareProvider.toLowerCase()) ||
            concatLastFirst
              .toLowerCase()
              .includes(this.searchCareProvider.toLowerCase())
          ) {
            return true;
          } else {
            return false;
          }
        }
      );
    } else {
      this.filteredCareProvidersList = [...this.allCareProviders];
    }
  }

  // Load care provider details on form
  careProviderSelected(event) {
    if (event.value) {
      let selectedCareProvider = this.allCareProviders
        .filter((provider) => provider.id === event.value)
        .at(0);

      this.patientForm.controls['primaryCPFirstName'].setValue(
        selectedCareProvider.firstName
      );
      this.patientForm.controls['primaryCPMiddleName'].setValue(
        selectedCareProvider.middleName
      );
      this.patientForm.controls['primaryCPLastName'].setValue(
        selectedCareProvider.lastName
      );
      this.patientForm.controls['primaryCPEmail'].setValue(
        selectedCareProvider.emailAddress
      );
      this.patientForm.controls['primaryCPPhone'].setValue(
        selectedCareProvider.phoneNumber
      );
    } else {
      this.patientForm.controls['primaryCPId'].setValue(null);
      this.patientForm.controls['primaryCPFirstName'].setValue(null);
      this.patientForm.controls['primaryCPMiddleName'].setValue(null);
      this.patientForm.controls['primaryCPLastName'].setValue(null);
      this.patientForm.controls['primaryCPEmail'].setValue(null);
      this.patientForm.controls['primaryCPPhone'].setValue(null);
    }
  }

  onPatientFormSubmit() {
    if (this.patientForm.invalid) {
      this.patientForm.markAllAsTouched();
      return;
    }

    this.patient = Object.assign({}, this.patient);
    this.patient = Object.assign(this.patient, this.patientForm.value);

    //
    if (this.patient.dateOfBirth && this.patient.dateOfBirth !== '') {
      this.patient.dateOfBirth = formatDate(new Date(this.patient.dateOfBirth));
    }

    if (this.patient.deceasedDate && this.patient.deceasedDate !== '') {
      this.patient.deceasedDate = formatDate(
        new Date(this.patient.deceasedDate)
      );
    }

    if (this.patient.admittanceDate && this.patient.admittanceDate !== '') {
      this.patient.admittanceDate = formatDate(
        new Date(this.patient.admittanceDate)
      );
    }

    if (!this.patient.dischargedDate && this.patient.status === 'Discharged') {
      this.patient.dischargedDate = new Date();
    }

    if (!this.patient.deceasedDate && this.patient.status === 'Deceased') {
      this.patient.deceasedDate = formatDate(new Date());
    }

    this.processing = true;

    if (this.action === 'EDIT') {
      this.patientApiService.updatePatient(this.patient).subscribe({
        next: (response) => {
          this.processing = false;
          this.toastMessageService.displaySuccessMessage(
            'Successfully updated patient record'
          );
          this.dialogRef.close('success');
        },
        error: (error) => {
          this.processing = false;
          if (
            error.text ===
            'Another Member with the same Member Number already exists in the database'
          ) {
            this.toastMessageService.displayErrorMessage(
              'Error: Member with same Member Number already exists. Please update the member number to a new one.'
            );
            this.patientForm.controls['memberNumber'].setErrors({
              duplicate: true,
            });
            (<any>this.patientForm.get('memberNumber')).nativeElement.focus();
          } else {
            this.toastMessageService.displayErrorMessage(
              'Error: Something went wrong. Please try again.'
            );
          }
        },
      });
    } else {
      this.patientApiService.addPatient(this.patient).subscribe({
        next: (response) => {
          this.processing = false;
          this.toastMessageService.displaySuccessMessage(
            'Successfully added new patient'
          );
          this.dialogRef.close('success');
        },
        error: (error) => {
          this.processing = false;
          if (
            error.text ===
            'Another Member with the same Member Number already exists in the database'
          ) {
            this.toastMessageService.displayErrorMessage(
              'Error: Member with same Member Number already exists. Please update the member number to a new one.'
            );
            this.patientForm.controls['memberNumber'].setErrors({
              duplicate: true,
            });
            (<any>this.patientForm.get('memberNumber')).nativeElement.focus();
          } else if (error.text === 'Duplicate Email') {
            this.toastMessageService.displayErrorMessage(
              'Error: Member with the given email already exists.'
            );
          } else {
            this.toastMessageService.displayErrorMessage(
              'Error: Something went wrong. Please try again.'
            );
          }
        },
      });
    }
  }

  private buildForm() {
    this.patientForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(null),

      facilityId: new FormControl(this.facilityId ? this.facilityId : null),
      firstName: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      middleName: new FormControl(''),
      lastName: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      ssn: new FormControl(
        '',
        Validators.compose([RxwebValidators.mask({ mask: '999-99-9999' })])
      ),
      genderAtBirth: new FormControl(''),
      email: new FormControl('', Validators.email),
      dateOfBirth: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      gender: new FormControl(null, Validators.compose([Validators.required])),
      addressLine1: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      addressLine2: new FormControl(''),
      city: new FormControl('', Validators.compose([Validators.required])),
      state: new FormControl('', Validators.compose([Validators.required])),
      zip: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          RxwebValidators.mask({ mask: '99999' }),
        ])
      ),

      phonePreference: new FormControl('Cell Phone', Validators.required),
      phoneNumber: new FormControl(
        null,
        Validators.compose([RxwebValidators.mask({ mask: '(999) 999-9999' })])
      ),

      homePhoneNumber: new FormControl(
        '',
        Validators.compose([RxwebValidators.mask({ mask: '(999) 999-9999' })])
      ),
      workPhoneNumber: new FormControl(
        '',
        Validators.compose([RxwebValidators.mask({ mask: '(999) 999-9999' })])
      ),

      memberNumber: new FormControl(null),
      mco: new FormControl(null),
      aadhaarNo: new FormControl(null),
      panNo: new FormControl(null),
      status: new FormControl(null),

      deceasedDate: new FormControl(null),
      dischargedDate: new FormControl(null),
      preferredLanguage: new FormControl(null),

      race: new FormControl(''),
      ethnicity: new FormControl(''),
      maritalStatus: new FormControl(''),
      employmentStatus: new FormControl(''),
      timeZone: new FormControl(''),
      pronouns: new FormControl(''),
      admittanceDate: new FormControl(''),

      restrictedMember: new FormControl(
        { value: false, disabled: this.isOrgAdmin ? false : true },
        Validators.compose([Validators.required])
      ),

      height: new FormControl(''),
      weight: new FormControl(''),

      // Newly added, primary cp attached to a patient
      primaryCPId: new FormControl(''),
      primaryCPFirstName: new FormControl(''),
      primaryCPMiddleName: new FormControl(''),
      primaryCPLastName: new FormControl(''),
      primaryCPEmail: new FormControl(''),
      primaryCPPhone: new FormControl(''),
    });
  }

  confirmPatientStatusChange(event: MatSelectChange) {
    let selectedValue = event.source.triggerValue;
    if (this.origPatientStatus === 'Active' && selectedValue !== 'Active') {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Please Confirm',
          msg:
            'Switching status to "' +
            selectedValue +
            '" will close all the Active Cases.',
          yesButtonTitle: 'Yes',
          noButtonTitle: 'No',
        },
        disableClose: true,
        autoFocus: false,
        minWidth: '20vw',
      });

      dialogRef.afterClosed().subscribe((confirm) => {
        if (!confirm) {
          this.patientForm.controls['status'].setValue('Active');
        }
      });
    }
  }

  handleAddressChange(address: google.maps.places.PlaceResult) {
    let addressField: string = '';

    for (const component of address.address_components as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number': {
          addressField = `${component.long_name} ${addressField}`;
          break;
        }

        case 'route': {
          addressField += component.long_name;
          break;
        }

        case 'locality':
          //city
          this.patientForm.controls['city'].setValue(component.long_name);
          break;

        case 'administrative_area_level_1': {
          //state
          this.patientForm.controls['state'].setValue(component.short_name);
          break;
        }

        case 'postal_code': {
          this.patientForm.controls['zip'].setValue(component.long_name);
          break;
        }
      }
    }
    if (addressField.trim() == '')
      addressField = address.formatted_address.split(',')[0];
    this.patientForm.controls['addressLine1'].setValue(addressField);
  }

  phonePreferenceChanged(event) {
    if (event.value === 'Home Phone') {
      this.patientForm
        .get('homePhoneNumber')
        .addValidators(Validators.required);
      this.patientForm.get('homePhoneNumber').updateValueAndValidity();

      this.patientForm.get('workPhoneNumber').clearValidators();
      this.patientForm.get('workPhoneNumber').updateValueAndValidity();
    } else if (event.value === 'Work Phone') {
      this.patientForm
        .get('workPhoneNumber')
        .addValidators(Validators.required);
      this.patientForm.get('workPhoneNumber').updateValueAndValidity();

      this.patientForm.get('homePhoneNumber').clearValidators();
      this.patientForm.get('homePhoneNumber').updateValueAndValidity();
    } else {
      this.patientForm.get('workPhoneNumber').clearValidators();
      this.patientForm.get('workPhoneNumber').updateValueAndValidity();

      this.patientForm.get('homePhoneNumber').clearValidators();
      this.patientForm.get('homePhoneNumber').updateValueAndValidity();
    }
  }

  genderChanged(event) {
    if (event.value === 'O') {
      this.patientForm.get('genderAtBirth').addValidators(Validators.required);
      this.patientForm.get('genderAtBirth').updateValueAndValidity();
    } else {
      this.patientForm.get('genderAtBirth').clearValidators();
      this.patientForm.get('genderAtBirth').updateValueAndValidity();
    }
  }
}
