<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle *ngIf="action === 'ADD'">Add Goal</p>
    <p matDialogTitle *ngIf="action === 'EDIT'">Edit Goal</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="goalForm" novalidate (ngSubmit)="onGoalFormSubmit()">
    <div class="row">
      <div class="col-md-12">
        <label class="labelText">
          Description <span style="color: red">*</span></label
        >
        <mat-form-field appearance="outline">
          <textarea
            matInput
            placeholder="Goal Description ..."
            formControlName="description"
            name="description"
            rows="5"
            required
          ></textarea>
          <mat-error *ngFor="let validation of validation_messages.description">
            <mat-error
              class="error-message"
              *ngIf="
                goalForm.get('description').hasError(validation.type) &&
                (goalForm.get('description').dirty ||
                  goalForm.get('description').touched)
              "
            >
              {{ validation.message }}</mat-error
            >
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12" *ngIf="action === 'EDIT'">
        <label class="labelText"> Status </label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="status"
            placeholder="Status"
            (selectionChange)="statusChanged()"
          >
            <mat-option value="active">Active</mat-option>
            <mat-option value="closed">Closed</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <label class="labelText"> Priority </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="priority" placeholder="Priority">
            <mat-option></mat-option>
            <mat-option value="1">1</mat-option>
            <mat-option value="2">2</mat-option>
            <mat-option value="3">3</mat-option>
            <mat-option value="4">4</mat-option>
            <mat-option value="5">5</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <label class="labelText"> Category </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="category" placeholder="Category">
            <mat-option></mat-option>
            <mat-option value="Managing My Money">Managing My Money</mat-option>
            <mat-option value="My Employment Information and Interests"
              >My Employment Information and Interests</mat-option
            >
            <mat-option value="My Relationships">My Relationships</mat-option>
            <mat-option value="My Physical Health"
              >My Physical Health</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <label class="labelText"> Rank </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="rank" placeholder="Rank">
            <mat-option></mat-option>
            <mat-option value="low">Low</mat-option>
            <mat-option value="medium">Medium</mat-option>
            <mat-option value="high">High</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <label class="labelText"> Target Date </label>
        <mat-form-field appearance="outline">
          <input
            formControlName="targetDate"
            matInput
            [matDatepicker]="targetDatePicker"
            autoSlashDate
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="targetDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #targetDatePicker></mat-datepicker>
          <mat-error *ngIf="goalForm.controls['targetDate'].invalid">
            Please select a valid date
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="col-md-12"
        *ngIf="
          action === 'EDIT' && goalForm.controls['status'].value === 'closed'
        "
      >
        <label class="labelText"> Close Date </label>
        <mat-form-field appearance="outline">
          <input
            formControlName="closedDate"
            matInput
            [matDatepicker]="closedDatePicker"
            autoSlashDate
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="closedDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #closedDatePicker></mat-datepicker>
          <mat-error></mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12" style="text-align: right">
        <button
          color="primary"
          mat-raised-button
          type="submit"
          [disabled]="!goalForm.valid || processing"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
