<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Add New Diagnosis</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-stepper linear #stepper style="width: 100%">
    <!-- Step 1 Select code -->
    <mat-step [stepControl]="selectedICD">
      <ng-template matStepLabel>Select Diagnosis Code</ng-template>

      <!-- Search box -->
      <div class="row" style="justify-content: right; margin-right: 15px">
        <div
          style="
            display: flex;
            align-items: center;
            padding: 5px;
            border-radius: 10px;
            border: 1px solid rgb(0, 0, 0, 0.2);
            background-color: #ffffff;
            padding: 5px;
          "
        >
          <span
            class="material-icons"
            style="text-align: left; font-size: 20px; opacity: 0.5"
          >
            search
          </span>
          <input
            type="search"
            placeholder="Search ICD Code"
            [(ngModel)]="icdSearchName"
            (ngModelChange)="searchIcdCode()"
            style="
              padding-left: 15px;
              width: 100%;
              background: transparent;
              border: 0;
            "
          />
        </div>
      </div>

      <!-- Progress Bar -->
      <mat-progress-bar
        mode="indeterminate"
        *ngIf="isLoadingResults"
      ></mat-progress-bar>

      <!-- Mat Tab Group -->
      <mat-tab-group dynamicHeight style="margin-top: 5px">
        <!-- My Favourites List -->
        <mat-tab *ngIf="!icdSearchName">
          <ng-template mat-tab-label>
            <mat-icon>favorite</mat-icon>
            &nbsp;My favorites
          </ng-template>

          <div *ngIf="favICDCodeData.total > 0; else noFavoritesFound">
            <div
              *ngFor="let icdCodeData of favICDCodeData.items"
              style="padding: 5px"
              class="searchResult"
            >
              <mat-icon
                [ngClass]="icdCodeData.isFavorite ? 'favorite' : 'not-favorite'"
                (click)="toggleFavorite('ICD_CODES', icdCodeData)"
              >
                star
              </mat-icon>
              <span
                id="ICD_CODE"
                (click)="selectIcd(icdCodeData, stepper)"
                style="cursor: pointer; font-weight: 450"
              >
                {{ icdCodeData.icdCode }} - {{ icdCodeData.icdDescription }}
              </span>
            </div>
          </div>

          <!-- No favorites found Disclaimer-->
          <ng-template #noFavoritesFound>
            <mat-error>No favorites found</mat-error>
          </ng-template>
        </mat-tab>

        <!-- Search List -->
        <mat-tab *ngIf="icdSearchName">
          <ng-template mat-tab-label>
            <mat-icon>search</mat-icon>
            &nbsp;Search Results
          </ng-template>

          <div *ngIf="icdCodeData.total > 0">
            <div
              *ngFor="let icdCodeData of icdCodeData.items"
              style="padding: 5px"
              class="searchResult"
            >
              <mat-icon
                [ngClass]="icdCodeData.isFavorite ? 'favorite' : 'not-favorite'"
                (click)="toggleFavorite('ICD_CODES', icdCodeData)"
              >
                star
              </mat-icon>
              <span
                id="ICD_CODE"
                (click)="selectIcd(icdCodeData, stepper)"
                style="cursor: pointer; font-weight: 450"
              >
                {{ icdCodeData.icdCode }} - {{ icdCodeData.icdDescription }}
              </span>
            </div>
            <mat-paginator
              *ngIf="icdCodeData.total > icdCodeData.per_page"
              [length]="icdCodeData.total"
              [pageSize]="icdCodeData.per_page"
              (page)="getNext($event)"
            >
            </mat-paginator>
          </div>
          <div *ngIf="icdCodeData.total === 0 && !isLoadingResults">
            <mat-error> No ICD 10 Code found</mat-error>
          </div>
        </mat-tab>
      </mat-tab-group>

      <div style="text-align: right">
        <button mat-button matStepperNext [disabled]="selectedICD.length == 0">
          Next
        </button>
      </div>
    </mat-step>

    <!-- Step 2 Verify -->
    <mat-step>
      <ng-template matStepLabel>Review</ng-template>
      <div class="row">
        <div class="col-md-12">
          <div class="twoGridContainer">
            <label>Diagnosis Code</label>
            <label class="labelText">
              {{ diagnosisForm.controls['icdCode'].value }}
            </label>

            <label>Description</label>
            <label class="labelText">
              {{ diagnosisForm.controls['longDesc'].value }}
            </label>
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: space-between">
        <button
          mat-raised-button
          type="button"
          class="btn btn-light"
          matStepperPrevious
          [disabled]="isProcessing"
        >
          Back
        </button>
        <button
          class="btn btn-primary"
          mat-raised-button
          type="submit"
          [disabled]="!diagnosisForm.valid || isProcessing"
          (click)="submitDiagnosis()"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="isProcessing"></i>
        </button>
      </div>
    </mat-step>
  </mat-stepper>
</div>
