<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Signature</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

  <div class="row">
    <div
      #canvasContainer
      class="col-md-12"
      style="display: flex; justify-content: center"
    >
      <canvas
        #canvas
        height="250"
        *ngIf="!existingSignature || redoSig"
      ></canvas>
      <img
        *ngIf="existingSignature && !redoSig"
        [src]="existingSignature"
        alt="signature"
      />
    </div>

    <div
      class="col-md-12"
      style="display: flex; justify-content: space-between; margin-top: 25px"
    >
      <!-- Left side -->
      <div>
        <button
          mat-raised-button
          *ngIf="!existingSignature || redoSig"
          type="button"
          (click)="clearPad()"
        >
          Clear
        </button>

        <button
          mat-raised-button
          *ngIf="existingSignature && !redoSig"
          type="button"
          (click)="redoSignature()"
        >
          Redo Signature
        </button>
      </div>
      <!-- Right side -->
      <div>
        <button
          mat-raised-button
          *ngIf="!existingSignature || redoSig"
          color="primary"
          type="button"
          (click)="saveSignature()"
          [disabled]="processing || !signatureStroke"
        >
          Submit Signature
          <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
        <button
          mat-raised-button
          *ngIf="existingSignature && !redoSig"
          color="primary"
          type="button"
          (click)="performESignature()"
          [disabled]="processing"
        >
          Submit Signature
          <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </div>
</div>
