import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { PermissionsSandbox } from 'src/app/shared/sandbox/permissions.sandbox';
import { hasAccess } from 'src/app/shared/utilities/utilities';
import { DataTablesModel, Patient } from '../../_models';
import {
  CoreService,
  PatientService,
  ToastMessageService,
} from '../../_services';
import { AddEditPatientComponent } from './dialogs/add/add.component';

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss'],
})
export class PatientsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() facilityId: string;
  @Input() consolidatedMemberSearch: boolean = false;

  @Input() set facilityName(facilityName: string) {
    if (facilityName && facilityName.length > 0) {
      this.selectedFacilityName = facilityName;
      this.loadPatients();
    }
  }

  displayedColumns = [
    'patient',
    'dateOfBirth',
    'gender',
    'address',
    'phoneNumber',
    'pcprovider',
  ];

  processing$ = false;
  processingNonFacility$ = false;

  data: DataTablesModel = {} as DataTablesModel;
  orgConfig: any;

  permissions: Observable<String[]>;
  hasViewAccess = false;
  hasEditAccess = false;
  hasAddAccess = false;
  checkingPermissions = true;
  showMemberNumber: boolean = false;

  patientLandingPage: string = 'NOTES';
  selectedFacilityName: string;

  memberNumber: string = '';
  firstName: string = '';
  lastName: string = '';
  phone: string = '';
  status: string = 'Active';

  isOrgAdmin: boolean = false;
  loggedInUserId: string = '';

  constructor(
    public dialog: MatDialog,
    public patientApiService: PatientService,
    private keycloakService: KeycloakService,
    protected permissionsSandbox: PermissionsSandbox,
    public coreService: CoreService,
    private orgConfigSandbox: OrgConfigSandbox,
    private toastMessageService: ToastMessageService
  ) {
    this.data.per_page = 10;
    this.data.page = 0;
  }

  ngOnInit() {
    this.isOrgAdmin =
      this.coreService.isSuperOrgAdmin() || this.coreService.isOrgAdmin();
    this.loggedInUserId = this.coreService.getLoggedInCareProviderId();

    this.permissionsSandbox.permissions$.subscribe((response) => {
      this.checkingPermissions = false;
      this.hasViewAccess = hasAccess(
        this.keycloakService,
        'MEMBERS_VIEW',
        response,
        null
      );

      this.hasEditAccess = hasAccess(
        this.keycloakService,
        'MEMBERS_EDIT',
        response,
        null
      );

      this.hasAddAccess = hasAccess(
        this.keycloakService,
        'MEMBERS_ADD',
        response,
        null
      );

      if (this.hasEditAccess) {
        if (!this.displayedColumns.includes('actions')) {
          this.displayedColumns.push('actions');
        }
      }
    });

    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          this.orgConfig = orgConfig;

          if (this.orgConfig?.features && this.orgConfig?.features.length > 0) {
            if (this.orgConfig.features.includes('CASE_MANAGEMENT')) {
              this.patientLandingPage = 'CARE';
            }

            if (this.orgConfig.features.includes('OUTPATIENT_CARE')) {
              this.patientLandingPage = 'NOTES';
            }

            this.consolidatedMemberSearch = this.orgConfig.features.includes(
              'CONSOLIDATED_MEMBER_SEARCH'
            );

            this.showMemberNumber = this.orgConfig.features.includes(
              'MANUAL_MEMBER_NUMBER'
            );

            // If this is a consolidated Member Search, load all organization wide patients
            if (this.consolidatedMemberSearch) {
              this.loadPatients();
            }
          }
        });
      }
    });
  }

  searchByNameChanged() {
    // Restore page index and paginator index
    this.data.page = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }

    this.loadPatients();
  }

  filterOptionChanged(option: string) {
    // Restore page index and paginator index
    this.data.page = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }

    this.status = option;
    this.loadPatients();
  }

  addPatient() {
    const dialogRef = this.dialog.open(AddEditPatientComponent, {
      data: {
        action: 'ADD',
        patient: null,
        facilityId: this.facilityId,
        isOrgAdmin: this.isOrgAdmin,
        showMemberNumber: this.showMemberNumber,
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '40vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadPatients();
      }
    });
  }

  public editPatient(patient: Patient) {
    const dialogRef = this.dialog.open(AddEditPatientComponent, {
      data: {
        action: 'EDIT',
        patient: patient,
        isOrgAdmin: this.isOrgAdmin,
        showMemberNumber: this.showMemberNumber,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '40vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadPatients();
      }
    });
  }

  public loadPatients() {
    this.processing$ = true;

    this.patientApiService
      .getPatients(
        this.facilityId,
        this.memberNumber,
        this.firstName,
        this.lastName,
        this.phone,
        this.status,
        this.data.per_page,
        this.data.page
      )
      .subscribe({
        next: (response) => {
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }

          this.processing$ = false;
        },
        error: (error) => {
          this.processing$ = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load members'
          );
        },
      });
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadPatients();
  }
}
