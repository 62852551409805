<mat-card-title>Member Goals</mat-card-title>
<mat-divider></mat-divider>

<div style="margin-top: 20px; padding: 10px">
  <!-- Search Box -->
  <div
    class="row"
    style="margin-right: 5px; row-gap: 15px; margin-bottom: 15px"
  >
    <div class="col-sm-12 col-md-4">
      <div
        style="
          display: flex;
          align-items: center;
          padding: 5px;
          border-radius: 10px;
          border: 1px solid rgb(0, 0, 0, 0.2);
          background-color: #ffffff;
          padding: 5px;
        "
      >
        <span
          class="material-icons"
          style="text-align: left; font-size: 20px; opacity: 0.5"
        >
          search
        </span>
        <input
          type="search"
          placeholder="Goal description"
          [(ngModel)]="searchGoalDescription"
          (ngModelChange)="searchByDescription()"
          style="
            padding-left: 15px;
            width: 100%;
            background: transparent;
            border: 0;
          "
        />
      </div>
    </div>
    <div
      class="col-sm-12 col-md-8"
      style="
        column-gap: 20px;
        display: flex;
        text-align: right;
        flex-direction: row-reverse;
        padding: 0;
        row-gap: 10px;
        flex-wrap: wrap;
      "
    >
      <button
        mat-raised-button
        color="warn"
        type="button"
        *ngIf="hasGoalsAddAccess && patient.status === 'Active'"
        (click)="addGoal()"
      >
        + New Goal
      </button>

      <button mat-raised-button [matMenuTriggerFor]="filterMenu">
        <span
          style="white-space: break-spaces; color: #2196f3"
          *ngIf="order === 'DESC'"
        >
          Sort By: Latest
        </span>

        <span
          style="white-space: break-spaces; color: #2196f3"
          *ngIf="order === 'ASC'"
        >
          Sort By: Oldest
        </span>
        <mat-icon>filter_list</mat-icon>
      </button>

      <button mat-raised-button [matMenuTriggerFor]="sortMenu">
        <span
          style="white-space: break-spaces; color: #3ac47d"
          *ngIf="goalStatus === 'Active'"
        >
          Status: Active
        </span>

        <span
          style="white-space: break-spaces; color: #d92550"
          *ngIf="goalStatus === 'Closed'"
        >
          Status: Closed
        </span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>

      <mat-menu #sortMenu="matMenu">
        <button
          mat-menu-item
          style="font-weight: 400; font-size: 12px; width: 100%"
          (click)="filterOptionChanged('Active')"
        >
          Active
        </button>
        <button
          mat-menu-item
          style="font-weight: 400; font-size: 12px; width: 100%"
          (click)="filterOptionChanged('Closed')"
        >
          Closed
        </button>
      </mat-menu>

      <mat-menu #filterMenu="matMenu">
        <button
          (click)="sortByChanged('DESC')"
          mat-menu-item
          style="font-weight: 400; font-size: 12px; width: 100%"
        >
          Created Date: Latest
        </button>
        <button
          (click)="sortByChanged('ASC')"
          mat-menu-item
          style="font-weight: 400; font-size: 12px; width: 100%"
        >
          Created Date: Oldest
        </button>
      </mat-menu>
    </div>
  </div>

  <div *ngIf="data.total === 0 && !isLoadingResults">
    <mat-error> No {{ goalStatus }} Goal </mat-error>
  </div>

  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoadingResults"
  ></mat-progress-bar>

  <!-- Goal Cards -->
  <div class="gridContainer" *ngIf="data?.total > 0" style="margin-top: 30px">
    <div class="container mat-elevation-z8" *ngFor="let goal of data.items">
      <div class="row" style="position: relative">
        <!-- Description -->
        <div class="col-md-12" style="text-align: left">
          <!-- Heading -->
          <p
            style="
              font-size: 16px;
              font-weight: 400;
              color: #2196f3;
              width: 80%;
            "
          >
            {{ goal.description }}
          </p>
        </div>
        <label class="labelText" style="text-align: left">
          <ul>
            <ng-container
              *ngFor="let objective of goal.objectives; let i = index"
            >
              <li style="margin-bottom: 5px">
                <span style="font-weight: 500">{{
                  objective.objectiveDescription
                }}</span>
                <br />

                <span
                  >Target Date :
                  <span style="opacity: 0.7">{{
                    objective?.targetDate | date : 'MM/dd/y'
                  }}</span>
                  <br />
                  Status:
                  <span
                    style="opacity: 0.9; font-weight: 450"
                    [style.color]="
                      objective?.status === 'Canceled'
                        ? 'red'
                        : objective?.status === 'Attained'
                        ? '#3ac47d'
                        : '#ff9f40'
                    "
                  >
                    {{ objective?.status }}
                  </span>
                </span>
              </li>
            </ng-container>
          </ul>
        </label>

        <!-- Action -->
        <div
          class="goalAction"
          *ngIf="hasGoalsEditAccess || hasGoalsDeleteAccess"
        >
          <div
            class="material-icons"
            style="
              color: #af8f85;
              cursor: pointer;
              font-size: 17px;
              border-radius: 50%;
              z-index: 2;
              position: absolute;
              right: 0px;
            "
            [matMenuTriggerFor]="menu"
          >
            more_vert
          </div>
          <mat-menu #menu="matMenu" style="max-height: 500px">
            <button
              *ngIf="hasGoalsEditAccess"
              mat-menu-item
              type="button"
              (click)="editGoal(goal)"
            >
              <mat-icon style="color: #f39121"> edit </mat-icon>
              <span>Edit</span>
            </button>
            <button
              *ngIf="hasGoalsDeleteAccess && patient.status === 'Active'"
              mat-menu-item
              type="button"
              (click)="deleteGoal(goal)"
            >
              <mat-icon style="color: red"> delete_forever </mat-icon>
              <span>Delete</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>

  <mat-paginator
    [length]="data.total"
    [pageSize]="data.per_page"
    *ngIf="data.total > data.per_page"
    (page)="getNextPage($event)"
    [showFirstLastButtons]="true"
  ></mat-paginator>
</div>
