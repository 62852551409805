import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import moment from 'moment';
import { DataTablesModel } from 'src/app/_models';
import { BigFiveApiService } from 'src/app/_services';
import { CHART_COLORS, greenChart, redChart } from 'src/app/shared/utilities/utilities';
@Component({
  selector: 'app-big-five-personality-charts',
  templateUrl: './big-five-personality-charts.component.html',
  styleUrls: ['./big-five-personality-charts.component.css']
})
export class BigFivePersonalityChartsComponent implements OnInit {
  @Input() patientId: string;
  @Input() assessmentId: string;
  chartColorRed = CHART_COLORS.red;
  chartColorOrange = CHART_COLORS.orange;
  chartColorBlue = CHART_COLORS.blue;
  chartColorGreen = CHART_COLORS.green;
  chartColorPurple = CHART_COLORS.purple;
  lineChartData: ChartConfiguration<'line'>['data'] = {
    datasets: [{ tension: 0.4, data: [], label: 'Agreeableness', borderColor: this.chartColorRed, backgroundColor: "transparent", pointBackgroundColor: this.chartColorRed },
    { tension: 0.4, data: [], label: 'Extroversion', borderColor: this.chartColorOrange, backgroundColor: "transparent", pointBackgroundColor: this.chartColorOrange },
    { tension: 0.4, data: [], label: 'Conscientiousness', borderColor: this.chartColorBlue, backgroundColor: "transparent", pointBackgroundColor: this.chartColorBlue },
    { tension: 0.4, data: [], label: 'Neuroticism', borderColor: this.chartColorPurple, backgroundColor: "transparent", pointBackgroundColor: this.chartColorPurple },
    { tension: 0.4, data: [], label: 'Openness', borderColor: this.chartColorGreen, backgroundColor: "transparent", pointBackgroundColor: this.chartColorGreen },]
  };

  lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        title: {
          display: false
        }
      }
    },
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        },
        min: 0,
        max: 40,
        ticks: { stepSize: 5 }
      }
    }
  };
  assessmentDetailChartOptions: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        title: {
          display: false
        }
      }
    },
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        min: -5,
        max: 5,
        ticks: { stepSize: 1 }
      }
    }
  };
  lineChartLegend = false;
  lineChartPlugins = [];
  lineChartType = 'line';
  detailChartPlugins = [];
  data: DataTablesModel = {} as DataTablesModel;
  processing$ = false;
  isLoadingResults = false;
  error: string = '';
  scoreMap = new Map();
  scoreDetails: any[] = [];
  constructor(public bigFiveApiService: BigFiveApiService,
    private route: ActivatedRoute) {
  }
  ngOnInit() {
    this.data.page = 0;
    this.data.per_page = 10;
    this.loadGamingAddictionAssessments();
  }
  public loadGamingAddictionAssessments() {
    this.isLoadingResults = true;
    this.error = '';
    this.bigFiveApiService.getBigFiveList(this.patientId, this.assessmentId, this.data.per_page, this.data.page).subscribe(response => {
      if (response && response.items) {
        var agreeablenessScores = [];
        var extroversionScores = [];
        var conscientiousnessScores = [];
        var neuroticismScores = [];
        var opennessScores = [];

        this.data.items = response.items;
        this.data.total = response.total;
        var riskScores = [];
        var labels = [];
        this.scoreDetails = [];
        for (var item of response.items) {
          if (response.items.length === 1) {
            labels.push("");
            riskScores.push(item.riskScore);
            agreeablenessScores.push(item.props['a-score']);
            extroversionScores.push(item.props['e-score']);
            conscientiousnessScores.push(item.props['c-score']);
            neuroticismScores.push(item.props['n-score']);
            opennessScores.push(item.props['o-score']);
          }
          riskScores.push(item.riskScore);
          if (item.props) {
            agreeablenessScores.push(item.props['a-score']);
            extroversionScores.push(item.props['e-score']);
            conscientiousnessScores.push(item.props['c-score']);
            neuroticismScores.push(item.props['n-score']);
            opennessScores.push(item.props['o-score']);
          } else {
            agreeablenessScores.push(0);
            extroversionScores.push(0);
            conscientiousnessScores.push(0);
            neuroticismScores.push(0);
            opennessScores.push(0);
          }
          var stillUtc = moment.utc(item.createdDate).toDate();
          var assessmentDate = moment(stillUtc).local().format('MM/DD/YY');
          labels.push(assessmentDate);
          for (var key in item) {
            if (item.hasOwnProperty(key)) {
              if (item[key] && item[key].hasOwnProperty("score")) {
                var scoreObj = item[key];
                var aScores = [];
                var eScores = [];
                var cScores = [];
                var nScores = [];
                var oScores = [];
                if (this.scoreMap.get(key)) {
                  aScores = this.scoreMap.get(key).a;
                  eScores = this.scoreMap.get(key).e;
                  cScores = this.scoreMap.get(key).c;
                  nScores = this.scoreMap.get(key).n;
                  oScores = this.scoreMap.get(key).o;
                }

                if (response.items.length === 1 && scoreObj["props"]) {
                  aScores.push(scoreObj["props"]["a"]);
                  eScores.push(scoreObj["props"]["e"]);
                  cScores.push(scoreObj["props"]["c"]);
                  nScores.push(scoreObj["props"]["n"]);
                  oScores.push(scoreObj["props"]["o"]);
                } else {
                  aScores.push(0);
                  eScores.push(0);
                  cScores.push(0);
                  nScores.push(0);
                  oScores.push(0);
                }

                if (scoreObj["props"]) {
                  aScores.push(scoreObj["props"]["a"]);
                  eScores.push(scoreObj["props"]["e"]);
                  cScores.push(scoreObj["props"]["c"]);
                  nScores.push(scoreObj["props"]["n"]);
                  oScores.push(scoreObj["props"]["o"]);
                } else {
                  aScores.push(0);
                  eScores.push(0);
                  cScores.push(0);
                  nScores.push(0);
                  oScores.push(0);
                }
                var returnObj = {
                  "a": aScores, "e": eScores, "c": cScores, "n": nScores, "o": oScores, "key": key, "title": scoreObj["title"], "answer": scoreObj["answer"], "chartData": [{ data: [], label: 'Agreeableness', borderColor: this.chartColorRed, backgroundColor: "transparent", pointBackgroundColor: this.chartColorRed },
                  { data: [], label: 'Extroversion', borderColor: this.chartColorOrange, backgroundColor: "transparent", pointBackgroundColor: this.chartColorOrange },
                  { data: [], label: 'Conscientiousness', borderColor: this.chartColorBlue, backgroundColor: "transparent", pointBackgroundColor: this.chartColorBlue },
                  { data: [], label: 'Neuroticism', borderColor: this.chartColorPurple, backgroundColor: "transparent", pointBackgroundColor: this.chartColorPurple },
                  { data: [], label: 'Openness', borderColor: this.chartColorGreen, backgroundColor: "transparent", pointBackgroundColor: this.chartColorGreen },]
                };
                this.scoreMap.set(key, returnObj);
              }
            }
          }
        }
        this.scoreMap.forEach((value, key) => {
          let dataset: any = {
            datasets: [
              {
                tension: 0.4,
                "data": value.a,
                "borderColor": this.chartColorRed,
                "backgroundColor": this.chartColorRed,
              },
              {
                tension: 0.4,
                "data": value.e,
                "borderColor": this.chartColorOrange,
                "backgroundColor": this.chartColorOrange,
              },
              {
                tension: 0.4,
                "data": value.c,
                "borderColor": this.chartColorBlue,
                "backgroundColor": this.chartColorBlue,
              },
              {
                tension: 0.4,
                "data": value.n,
                "borderColor": this.chartColorPurple,
                "backgroundColor": this.chartColorPurple,
              },
              {
                tension: 0.4,
                "data": value.o,
                "borderColor": this.chartColorGreen,
                "backgroundColor": this.chartColorGreen,
              }
            ],
            "labels": labels,
            "title": value.title,
            "answer": value.answer
          };
          this.scoreDetails.push(dataset);
        });
        this.lineChartData.datasets[0].data = agreeablenessScores;
        this.lineChartData.datasets[1].data = extroversionScores;
        this.lineChartData.datasets[2].data = conscientiousnessScores;
        this.lineChartData.datasets[3].data = neuroticismScores;
        this.lineChartData.datasets[4].data = opennessScores;
        this.lineChartData.labels = labels;
        this.isLoadingResults = false;
      }
      if (response == null) {
        this.data.items = [];
        this.data.total = 0;
      }
    },
      error => {
        this.error = error;
        this.isLoadingResults = false;
      })
  }
  getChartColor(scoringArray: any[]) {
    var score = scoringArray[scoringArray.length - 1];
    if (score < 20) {
      return redChart;
    } else {
      return greenChart;
    }
  }
}
