import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ITherapySession, ResponseModal } from 'src/app/_models';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(private httpClient: HttpClient) {}

  getClinicianNotifications(pageSize: number, pageIndex: number) {
    let params = new HttpParams();

    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<any>(
      `${environment.apiUrl}/cliniciannotificationslist`,
      { params: params }
    );
  }

  getMemberNotifications(pageSize: number, pageIndex: number) {
    let params = new HttpParams();

    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<any>(
      `${environment.apiUrl}/membernotificationslist`,
      { params: params }
    );
  }

  // Delete notification
  deleteNotification(id: string) {
    return this.httpClient.delete<any>(
      `${environment.apiUrl}/notifications/` + id
    );
  }

  // Delete notification
  deleteAllNotification() {
    return this.httpClient.delete<any>(
      `${environment.apiUrl}/allnotifications`
    );
  }

  // Mark notification as read
  markAsRead(id: string) {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient.put<any>(
      `${environment.apiUrl}/notifications/` + id,
      { headers }
    );
  }

  // Update Status to Seen
  updateSeenStatus(newNotification) {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/notificationupdatestatus`,
        newNotification,
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  // Get Existing Settings for notifications
  getNotificationSettings() {
    return this.httpClient.get<any>(
      `${environment.apiUrl}/notificationssettings`
    );
  }

  updateNotificationSettings(newPermissionsList) {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/notificationssettings`,
        newPermissionsList,
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
