import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class DashboardsService {
  constructor(private httpClient: HttpClient) {}

  getProviderDashboardStats() {
    return this.httpClient.get<any>(
      `${environment.apiUrl}/provider-dashboard-stats`
    );
  }

  getMemberDashboardStats() {
    return this.httpClient.get<any>(
      `${environment.apiUrl}/member-dashboard-stats`
    );
  }

  getSessionsForProvider() {
    return this.httpClient.get<any>(
      `${environment.apiUrl}/getproviderdashboardsessions`
    );
  }

  getSessionsForNonProvider() {
    return this.httpClient.get<any>(
      `${environment.apiUrl}/getnonproviderdashboardsessions`
    );
  }

  getAllActiveProviders() {
    return this.httpClient.get<any>(
      `${environment.apiUrl}/getallactiveproviders`
    );
  }
}
