import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { debounceTime } from 'rxjs';
import { ITherapySession } from 'src/app/_models';
import { IBpsaEduVocationalHistory } from 'src/app/_models/session/clinical/bpsa-clinical-edu-vocational-history.model';
import { ToastMessageService } from 'src/app/_services';
import { BpsaEduVocationalHistoryApiService } from 'src/app/_services/session/clinical/bpsa-clinical-edu-vocational-history.service';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';

@Component({
  selector: 'app-educational-and-vocational-history',
  templateUrl: './educational-and-vocational-history.component.html',
  styleUrls: ['./educational-and-vocational-history.component.css'],
})
export class EducationalAndVocationalHistoryComponent implements OnInit {
  @Input() therapySession: ITherapySession;
  @Input() loggedInUserId: string;
  @Input() hasBPSAClinicalViewAccess: boolean;
  @Input() hasBPSAClinicalAddAccess: boolean;
  @Input() hasBPSAClinicalEditAccess: boolean;

  @Output() updatedTherapySession = new EventEmitter<any>();
  bpsaeduvocationalhistory: IBpsaEduVocationalHistory;

  constructor(
    public bpsaeduvocationalhistoryApiService: BpsaEduVocationalHistoryApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private toastMessageService: ToastMessageService,
    private route: ActivatedRoute
  ) {}

  public action: string;
  public bpsaeduvocationalhistoryForm: FormGroup;
  key: string;
  processing: boolean = false;

  public ngOnInit() {
    this.bpsaeduvocationalhistory =
      this.therapySession.bpsaEduVocationalHistory;
    this.key = this.route.snapshot.paramMap.get('id');
    this.buildForm();
    this.loadBpsaEduVocationalHistory();
  }

  loadBpsaEduVocationalHistory() {
    if (this.bpsaeduvocationalhistory) {
      this.bpsaeduvocationalhistoryForm.controls['gradeCompleted'].setValue(
        this.bpsaeduvocationalhistory.gradeCompleted
      );
      this.bpsaeduvocationalhistoryForm.controls[
        'behavioralDifficulties'
      ].setValue(this.bpsaeduvocationalhistory.behavioralDifficulties);
      this.bpsaeduvocationalhistoryForm.controls['typeOfLearner'].setValue(
        this.bpsaeduvocationalhistory.typeOfLearner
      );
      this.bpsaeduvocationalhistoryForm.controls['learningDisorders'].setValue(
        this.bpsaeduvocationalhistory.learningDisorders
      );
      this.bpsaeduvocationalhistoryForm.controls['currentlyEmployed'].setValue(
        this.bpsaeduvocationalhistory.currentlyEmployed
      );
      this.bpsaeduvocationalhistoryForm.controls[
        'rateCurrentEmployment'
      ].setValue(this.bpsaeduvocationalhistory.rateCurrentEmployment);
      this.bpsaeduvocationalhistoryForm.controls['servedInMilitary'].setValue(
        this.bpsaeduvocationalhistory.servedInMilitary
      );
    }

    // Initiate Auto Save
    this.initiateAutoSave();
  }

  onBpsaEduVocationalHistoryFormSubmit() {
    this.bpsaeduvocationalhistory = Object.assign(
      {},
      this.bpsaeduvocationalhistory
    );
    this.bpsaeduvocationalhistory = Object.assign(
      this.bpsaeduvocationalhistory,
      this.bpsaeduvocationalhistoryForm.value
    );

    this.processing = true;
    this.bpsaeduvocationalhistoryApiService
      .updateBpsaEduVocationalHistory(this.bpsaeduvocationalhistory)
      .subscribe({
        next: (response) => {
          this.processing = false;
          // this.toastMessageService.displaySuccessMessage(
          //   'Successfully updated Education and Vocational History'
          // );
          this.updatedTherapySession.emit(response);
        },
        error: (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Something went wrong. Please try again.'
          );
        },
      });
  }

  private buildForm() {
    this.bpsaeduvocationalhistoryForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      sessionId: new FormControl(
        this.therapySession.id,
        Validators.compose([Validators.required])
      ),
      patientId: new FormControl(
        this.therapySession.patientId,
        Validators.compose([Validators.required])
      ),
      gradeCompleted: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      behavioralDifficulties: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      typeOfLearner: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      learningDisorders: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      currentlyEmployed: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      rateCurrentEmployment: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
      servedInMilitary: new FormControl(
        {
          value: '',
          disabled:
            this.therapySession.status != 'Active' ||
            (!this.hasBPSAClinicalAddAccess && !this.hasBPSAClinicalEditAccess),
        },
        Validators.compose([Validators.required])
      ),
    });
  }

  initiateAutoSave() {
    this.bpsaeduvocationalhistoryForm.valueChanges
      .pipe(debounceTime(1200))
      .subscribe(() => {
        this.onBpsaEduVocationalHistoryFormSubmit();
      });
  }
}
