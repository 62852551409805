import { IOrgConfig, SearchParameters } from '../../../_models';

export interface OrgConfigState {
    reloadOrgConfig: boolean;
    requestOrgConfigPayload: SearchParameters;

    payload: IOrgConfig;
    processingRequest: boolean;
    processingError: Error;
}

export const initialOrgConfigState: OrgConfigState = {
    reloadOrgConfig: undefined,
    requestOrgConfigPayload: undefined,

    payload: undefined,
    processingRequest: false,
    processingError: undefined,
}