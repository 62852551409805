import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { DataTablesModel, IMedication, Patient } from 'src/app/_models';
import {
  CoreService,
  MedicationApiService,
  ToastMessageService,
} from 'src/app/_services';
import { DoseSpotApiService } from 'src/app/_services/dosespot/dosespot.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { PermissionsSandbox } from 'src/app/shared/sandbox/permissions.sandbox';
import { AddEditMedicationDialogComponent } from './dialogs/add-edit-medication-dialog/add-edit-medication-dialog.component';

@Component({
  selector: 'app-medications',
  styleUrls: ['medications.component.css'],
  templateUrl: 'medications.component.html',
})
export class MedicationsComponent implements OnInit, OnChanges {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() patient: Patient;
  @Input() sessionId: string;

  @Input() hasMedicationViewAccess: boolean;
  @Input() hasMedicationAddAccess: boolean;
  @Input() hasMedicationEditAccess: boolean;
  @Input() hasMedicationDeleteAccess: boolean;
  @Input() prescriptionEnabled: boolean;

  @Input() displayTitle: boolean = true;

  displayedColumns = [
    'medicationName',
    'ndcCode',
    'dosage',
    'startDate',
    'endDate',
    'frequency',
    'specialInstructions',
  ];

  data: DataTablesModel = {} as DataTablesModel;
  processing$ = false;
  isLoadingResults = false;
  apiError = false;

  orgConfig: any;
  isIndianOrg: boolean = false;
  isPatientActive = false;
  isPhysician: boolean = false;

  constructor(
    public dialog: MatDialog,
    public medicationApiService: MedicationApiService,
    private doseSpotApiService: DoseSpotApiService,
    protected permissionsSandbox: PermissionsSandbox,
    private toastMessageService: ToastMessageService,
    private coreService: CoreService
  ) {}

  ngOnInit() {
    this.isPhysician = this.coreService.isPhysician();

    if (this.hasMedicationEditAccess || this.hasMedicationDeleteAccess) {
      this.displayedColumns.push('actions');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.patient) {
      this.data.page = 0;
      this.data.per_page = 10;

      this.loadMedications();
    }
  }

  addMedication() {
    const dialogRef = this.dialog.open(AddEditMedicationDialogComponent, {
      data: {
        action: 'ADD',
        medication: null,
        patient: this.patient,
        sessionId: this.sessionId,
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '40vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response && response === 'success') {
        this.loadMedications();
      }
    });
  }

  public editMedication(medication: IMedication) {
    const dialogRef = this.dialog.open(AddEditMedicationDialogComponent, {
      data: {
        action: 'EDIT',
        medication: medication,
        patient: this.patient,
        sessionId: this.sessionId,
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '40vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response && response === 'success') {
        this.loadMedications();
      }
    });
  }

  deleteMedication(medication: IMedication) {
    const dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to delete this medication?',
      },
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.medicationApiService
          .deleteMedication(medication.id)
          .subscribe(() => {
            this.loadMedications();
            this.toastMessageService.displaySuccessMessage(
              'Medications have been updated'
            );
          });
      }
    });
  }

  public loadMedications() {
    this.isLoadingResults = true;
    this.apiError = false;

    this.medicationApiService
      .getMedications(
        this.patient.id,
        this.sessionId,
        this.data.per_page,
        this.data.page
      )
      .subscribe({
        next: (response) => {
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }

          this.isLoadingResults = false;
        },
        error: (error) => {
          this.apiError = true;
          this.isLoadingResults = false;
        },
      });
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadMedications();
  }

  syncMedications() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Patient's Medication History",
        disclaimer:
          'Disclaimer: Certain information may not be available or accurate in this report, including items that the patient asked not be disclosed due to patient privacy concerns, over-the-counter medications, low cost prescriptions, prescriptions paid for by the patient or non-participating sources, or errors in insurance claims information. The provider should independently verify medication history with the patient.',
        msg: 'Patient has given consent to retrieve medication history?',
        yesButtonTitle: 'Yes',
        noButtonTitle: 'No',
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '20vw',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.apiError = false;
        this.doseSpotApiService.syncMedications(this.patient.id).subscribe(
          (response) => {
            if (response) {
              this.toastMessageService.displayInfoMessage(
                'Please wait for a few minutes for the medications to be updated.'
              );
            }
          },
          (error) => {
            this.apiError = true;
          }
        );
      }
    });
  }
}
