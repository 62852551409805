import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DataTablesModel, IAbcForm, ResponseModal } from '../../_models';
@Injectable({ providedIn: 'root' })
export class IncidentsApiService {
  constructor(private httpClient: HttpClient) {}

  getIncidentsList(
    patientId: string,
    caseId: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('patientId', patientId);
    params = params.append('caseId', caseId);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());
    params = params.append('order', 'DESC');
    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/incidents`,
      { params: params }
    );
  }

  getAllIncidentsForTheSelectedYear(
    year: string,
    patientId: string,
    organizationId: string
  ) {
    let params = new HttpParams();

    params = params.append('year', year);
    params = params.append('patientId', patientId);
    params = params.append('organizationId', organizationId);

    return this.httpClient.get<any>(
      `${environment.apiUrl}/memberallincidentslog`,
      {
        params: params,
      }
    );
  }

  getAbcFormDetails(abcIncidentId: string): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/incidents-behavioral/` + abcIncidentId
    );
  }

  addAbcForm(thisAbcForm: IAbcForm): Observable<IAbcForm> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<IAbcForm>(
        `${environment.apiUrl}/incidents-behavioral`,
        thisAbcForm,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
