import { Component, Inject, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ITherapySession } from 'src/app/_models';
import {
  DateTimeZoneService,
  TherapySessionService,
  ToastMessageService,
} from 'src/app/_services';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';

@Component({
  selector: 'app-close-session',
  templateUrl: './close-session.component.html',
  styleUrls: ['./close-session.component.css'],
})
export class CloseSessionComponent implements OnInit {
  public sessionId: string;
  public objectiveForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  objectiveError: Error = null;
  errors: any = {};
  processing: boolean = false;
  mainObject: any;
  therapySession: ITherapySession;

  sessionClosingDetails = new FormControl('Valid', Validators.required);
  subjectiveStep = new FormControl('');
  objectiveStep = new FormControl('');
  confirmStep = new FormControl('');
  currentTimezone: string;

  currentTime = new Date();
  constructor(
    public dialogRef: MatDialogRef<CloseSessionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public therapysessionApiService: TherapySessionService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private toastMessageService: ToastMessageService,
    private dateTimeZoneService: DateTimeZoneService
  ) {
    this.sessionId = data.sessionId;
    this.therapySession = data.therapySession;
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  public ngOnInit() {
    this.buildForm();
    this.errorHandler.handleErrors(this.objectiveForm, this.errors);

    // Prepopulatiing modality if any
    if (this.therapySession.therapeuticModality) {
      this.objectiveForm.controls['therapeuticModality'].setValue(
        this.therapySession.therapeuticModality
      );
      this.sessionClosingDetails.setValue('valid');
    }
    // Prepopulatiing subjectives if any
    if (this.therapySession.subjective) {
      this.objectiveForm.controls['subjective'].setValue(
        this.therapySession.subjective
      );
    }

    // Prepopulatiing objectives if any
    if (this.therapySession.objective) {
      this.objectives().controls['observations'].setValue(
        this.therapySession.objective.observations
      );
      this.objectives().controls['obeAEB'].setValue(
        this.therapySession.objective.obeAEB
      );
      this.objectives().controls['mood'].setValue(
        this.therapySession.objective.mood
      );
      this.objectives().controls['mAEB'].setValue(
        this.therapySession.objective.mAEB
      );
      this.objectives().controls['cognition'].setValue(
        this.therapySession.objective.cognition
      );
      this.objectives().controls['perception'].setValue(
        this.therapySession.objective.perception
      );
      this.objectives().controls['thought'].setValue(
        this.therapySession.objective.thought
      );
      this.objectives().controls['behaviourTowardsTherapist'].setValue(
        this.therapySession.objective.behaviourTowardsTherapist
      );
      this.objectives().controls['insight'].setValue(
        this.therapySession.objective.insight
      );
      this.objectives().controls['judgement'].setValue(
        this.therapySession.objective.judgement
      );
    }
  }

  onCloseSessionSubmit() {
    if (this.objectiveForm.invalid) {
      this.objectiveForm.markAllAsTouched();
      return;
    }

    this.mainObject = Object.assign({}, this.mainObject);
    this.mainObject = Object.assign(this.mainObject, this.objectiveForm.value);

    // Check for dates
    if (this.mainObject.start > this.mainObject.end) {
      this.toastMessageService.displayInfoMessage(
        'Session Start Time should be earlier than End Time'
      );
      return;
    }

    this.processing = true;
    this.therapysessionApiService
      .closeSession(this.sessionId, this.mainObject)
      .subscribe(
        () => {
          this.processing = false;
          this.toastMessageService.displaySuccessMessage(
            'Session ' + this.therapySession.sessionCode + ' has been closed.'
          );
          this.dialogRef.close('success');
        },
        (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error occured while closing therapy session'
          );
        }
      );
  }

  objectives() {
    return this.objectiveForm.controls['objective'] as FormGroup;
  }

  private buildForm() {
    this.objectiveForm = this.formBuilder.group({
      start: new FormControl(new Date(this.therapySession.start)),
      end: new FormControl(new Date(this.therapySession.end)),
      therapeuticModality: new FormControl([]),
      objective: this.formBuilder.group({
        observations: this.formBuilder.array(
          this.populateQuestions(this.observations)
        ),
        obeAEB: new FormControl('', Validators.compose([])),
        mood: this.formBuilder.array(this.populateQuestions(this.moodOptions)),
        mAEB: new FormControl('', Validators.compose([])),
        cognition: this.formBuilder.array(
          this.populateQuestions(this.cognitionOptions)
        ),
        perception: this.formBuilder.array(
          this.populateQuestions(this.perceptionOptions)
        ),
        thought: this.formBuilder.array(
          this.populateQuestions(this.thoughtOptions)
        ),
        behaviourTowardsTherapist: this.formBuilder.array(
          this.populateQuestions(this.behaviourTowardsTherapistOptions)
        ),
        insight: this.formBuilder.array(
          this.populateQuestions(this.insightOptions)
        ),
        judgement: this.formBuilder.array(
          this.populateQuestions(this.judgementOptions)
        ),
      }),
      subjective: new FormControl('', Validators.compose([])),
    });
    this.objectiveForm.valueChanges.subscribe(() => this.onValueChanged());
  }

  populateQuestions(questions: any) {
    let arrayItems = [];
    questions.map((question) => {
      arrayItems.push(this.createQuestion(question));
    });
    return arrayItems;
  }

  createQuestion(quest: any) {
    return this.formBuilder.group({
      label: [quest.label],
      code: [quest.code],
      responses: this.formBuilder.array(this.populateBoxes(quest.responses)),
    });
  }

  populateBoxes(boxes: any) {
    let arrayItems = [];
    boxes.map((box) => {
      arrayItems.push(this.createBox(box));
    });
    return arrayItems;
  }

  createBox(box: any) {
    return this.formBuilder.group({
      value: [box.value],
      selected: [box.selected],
    });
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.objectiveForm) {
      return;
    }
  }

  onNextClicked(stepper: MatStepper) {
    stepper.next();
  }

  public observations = [
    {
      label: 'Appearance',
      code: 'obsAppearance',
      responses: [
        { value: 'Neat', selected: false },
        { value: 'Disheveled', selected: false },
        { value: 'Inappropriate', selected: false },
        { value: 'Bizarre', selected: false },
        { value: 'Neglected', selected: false },
      ],
    },
    {
      label: 'Speech',
      code: 'obsSpeech',
      responses: [
        { value: 'Normal', selected: false },
        { value: 'Tangential', selected: false },
        { value: 'Pressured', selected: false },
        { value: 'Impoverished', selected: false },
        { value: 'Other', selected: false },
      ],
    },
    {
      label: 'Eye Contact',
      code: 'obsEyeContact',
      responses: [
        { value: 'Normal', selected: false },
        { value: 'Intense', selected: false },
        { value: 'Avoidant', selected: false },
        { value: 'Tearful', selected: false },
      ],
    },
    {
      label: 'Motor Activity',
      code: 'obsMotorActivity',
      responses: [
        { value: 'Normal', selected: false },
        { value: 'Restless', selected: false },
        { value: 'Agitated', selected: false },
        { value: 'Slowed', selected: false },
      ],
    },
    {
      label: 'Affect',
      code: 'obsAffect',
      responses: [
        { value: 'Congruent', selected: false },
        { value: 'Restricted', selected: false },
        { value: 'Flat', selected: false },
        { value: 'Blunted', selected: false },
        { value: 'Labile', selected: false },
        { value: 'Inappropriate', selected: false },
      ],
    },
  ];

  public moodOptions = [
    {
      code: 'm',
      responses: [
        { value: 'Euthymic', selected: false },
        { value: 'Anxious', selected: false },
        { value: 'Angry', selected: false },
        { value: 'Depressed', selected: false },
        { value: 'Euphoric', selected: false },
        { value: 'Irritable', selected: false },
        { value: 'Pessimistic', selected: false },
        { value: 'Optimistic', selected: false },
        { value: 'Hypomanic', selected: false },
      ],
    },
  ];

  public cognitionOptions = [
    {
      label: 'Orientation',
      code: 'cogOrientation',
      responses: [
        { value: 'Normal/None', selected: false },
        { value: 'X4', selected: false },
        { value: 'X3', selected: false },
        { value: 'Place', selected: false },
        { value: 'Object/Situation', selected: false },
        { value: 'Person', selected: false },
        { value: 'Time', selected: false },
      ],
    },
    {
      label: 'Organization',
      code: 'cogOrganization',
      responses: [
        { value: 'Normal', selected: false },
        { value: 'Logical', selected: false },
        { value: 'Goal-Directed', selected: false },
        { value: 'Circumstantial', selected: false },
        { value: 'Loose', selected: false },
        { value: 'Perseverations', selected: false },
      ],
    },
    {
      label: 'Memory Impairment',
      code: 'cogMemory',
      responses: [
        { value: 'Normal/None', selected: false },
        { value: 'Short-Term', selected: false },
        { value: 'Long-Term', selected: false },
        { value: 'Fair', selected: false },
        { value: 'Poor', selected: false },
      ],
    },
    {
      label: 'Attention',
      code: 'cogAttention',
      responses: [
        { value: 'Normal', selected: false },
        { value: 'Distracted', selected: false },
        { value: 'Unaware', selected: false },
        { value: 'Inattentive', selected: false },
        { value: 'Hyperfocused', selected: false },
      ],
    },
  ];

  public perceptionOptions = [
    {
      label: 'Hallucinations',
      code: 'percHallucinations',
      responses: [
        { value: 'None', selected: false },
        { value: 'Auditory', selected: false },
        { value: 'Visual', selected: false },
        { value: 'Other', selected: false },
      ],
    },
    {
      label: 'Other',
      code: 'percOther',
      responses: [
        { value: 'None', selected: false },
        { value: 'Derealization', selected: false },
        { value: 'Depersonalization', selected: false },
      ],
    },
  ];

  public thoughtOptions = [
    {
      label: 'Suicidal',
      code: 'thSuicidal',
      responses: [
        { value: 'None', selected: false },
        { value: 'Ideation', selected: false },
        { value: 'Plan', selected: false },
        { value: 'Intent', selected: false },
        { value: 'Self Harm', selected: false },
      ],
    },
    {
      label: 'Homicidal',
      code: 'thHomicidal',
      responses: [
        { value: 'None', selected: false },
        { value: 'Ideation', selected: false },
        { value: 'Plan', selected: false },
        { value: 'Intent', selected: false },
      ],
    },
    {
      label: 'Content',
      code: 'thContent',
      responses: [
        { value: 'Delusions', selected: false },
        { value: 'Congruent', selected: false },
        { value: 'Paranoid', selected: false },
        { value: 'Incongruent', selected: false },
        { value: 'Persecution', selected: false },
        { value: 'Indecisive', selected: false },
        { value: 'Illusions', selected: false },
        { value: 'Suspicions', selected: false },
        { value: 'Ideas Of Reference', selected: false },
        { value: 'Ideas Of Influence', selected: false },
        { value: 'Grandiose', selected: false },
      ],
    },
  ];

  public behaviourTowardsTherapistOptions = [
    {
      code: 'btt',
      responses: [
        { value: 'Cooperative', selected: false },
        { value: 'Guarded', selected: false },
        { value: 'Hyperactive', selected: false },
        { value: 'Agitated', selected: false },
        { value: 'Paranoid', selected: false },
        { value: 'Stereotyped', selected: false },
        { value: 'Aggressive', selected: false },
        { value: 'Bizarre', selected: false },
        { value: 'Withdrawn', selected: false },
        { value: 'Dependent', selected: false },
        { value: 'Dramatic', selected: false },
        { value: 'Passive', selected: false },
        { value: 'Poor Storyteller', selected: false },
        { value: 'Uninterested', selected: false },
        { value: 'Resistant', selected: false },
        { value: 'Silly Goofy', selected: false },
        { value: 'Critical', selected: false },
        { value: 'Hostile', selected: false },
        { value: 'Sarcastic', selected: false },
        { value: 'Threatening', selected: false },
        { value: 'Submissive', selected: false },
        { value: 'Suspicious', selected: false },
        { value: 'Defensive', selected: false },
        { value: 'Manipulative', selected: false },
        { value: 'Argumentative', selected: false },
      ],
    },
  ];

  public insightOptions = [
    {
      code: 'ins',
      responses: [
        { value: 'Good', selected: false },
        { value: 'Fair', selected: false },
        { value: 'Poor', selected: false },
        { value: 'Uses Connections', selected: false },
        { value: 'Flashes Of', selected: false },
        { value: 'Gaps', selected: false },
        { value: 'Unaware', selected: false },
      ],
    },
  ];

  public judgementOptions = [
    {
      code: 'jud',
      responses: [
        { value: 'Normal', selected: false },
        { value: 'Intense', selected: false },
        { value: 'Avoidant', selected: false },
        { value: 'Tearful', selected: false },
      ],
    },
  ];

  getAnswers(key: string, code: string) {
    let outPut = '';
    let innerControl = this.objectiveForm.controls['objective'] as FormGroup;
    let formArray = innerControl.controls[key] as FormArray;
    formArray.controls.forEach((element, index) => {
      let codeCtrlVal = element.get('code').value;
      if (codeCtrlVal === code) {
        let responses = element.get('responses') as FormArray;
        Object.keys(responses.controls).forEach((key: string) => {
          const abstractControl = responses.get(key);
          if (abstractControl.value.selected) {
            if (outPut != '') {
              outPut += ', ';
            }
            outPut += abstractControl.value.value;
          }
        });
      }
    });
    if (outPut === '') outPut = '-- n/a --';
    return outPut;
  }

  getAebValue(key: string) {
    let outPut = '';
    let innerControl = this.objectiveForm.controls['objective'] as FormGroup;
    if (innerControl.get(key)) {
      outPut = innerControl.get(key).value;
    }
    return outPut;
  }

  setSessionTherapeutic() {
    if (this.objectiveForm.controls['therapeuticModality'].valid) {
      this.sessionClosingDetails.setValue('valid');
    } else {
      this.sessionClosingDetails.setValue('');
    }
  }
}
