import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DataTablesModel, IGoal } from '../../_models';


@Injectable({ providedIn: 'root' })
export class GoalHttpService {
    constructor(private httpClient: HttpClient) { }

    getGoals(caseId: string, patientId: string, allRecords: boolean, pageSize: number, pageIndex: number): Observable<DataTablesModel> {
        let params = new HttpParams();
        params = params.append('patientId', patientId);
        params = params.append('allRecords', allRecords);
        params = params.append('pageSize', pageSize.toString());
        params = params.append('pageIndex', pageIndex.toString());
        return this.httpClient.get<DataTablesModel>(`${environment.apiUrl}/case/${caseId}/goals`, { params: params });
    }

    addGoal(caseId: string, goal: IGoal): Observable<IGoal> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.post<IGoal>(`${environment.apiUrl}/case/${caseId}/goal`, goal, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }

    updateGoal(caseId: string, goal: IGoal): Observable<IGoal> {
        const headers = { 'content-type': 'application/json' }
        return this.httpClient.put<IGoal>(`${environment.apiUrl}/case/${caseId}/goal`, goal, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }

    deleteGoal(caseId: string, goal: IGoal): Observable<IGoal> {
        return this.httpClient.delete<IGoal>(`${environment.apiUrl}/case/${caseId}/goal/` + goal.id).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }

}