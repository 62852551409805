<mat-card
  appearance="raised"
  class="mat-elevation-z8"
  style="margin-top: 10px; margin-bottom: 20px"
>
  <mat-card-title> Family Medical History </mat-card-title>
  <mat-card-subtitle style="font-style: italic; font-weight: 450">
    Member Family has a history of the following:
    <span style="color: #039be5; font-weight: 500">(highlighted in blue)</span>
    <br />
    (Includes parents, grandparents, siblings, and children)
  </mat-card-subtitle>

  <mat-error *ngIf="!hasFamilyHistoryViewAccess">
    Sorry, You don't have access to view Family Medical History
  </mat-error>

  <div *ngIf="hasFamilyHistoryViewAccess">
    <div class="row" *ngIf="hasFamilyHistoryEditAccess">
      <div
        class="col-sm-6 col-xl-4"
        *ngFor="let condition of familyConditionsList"
      >
        <mat-chip-option
          [class]="condition.selected ? 'mat-chip-active' : 'mat-chip-inactive'"
          (click)="
            condition.selected = !condition.selected;
            updateFamilyHealthCondition(condition)
          "
        >
          {{ condition.name }}
        </mat-chip-option>
      </div>
    </div>
    <div class="row" *ngIf="!hasFamilyHistoryEditAccess">
      <div
        class="col-sm-6 col-xl-4"
        *ngFor="let condition of familyConditionsList"
      >
        <mat-chip-option
          [class]="condition.selected ? 'mat-chip-active' : 'mat-chip-inactive'"
        >
          {{ condition.name }}
        </mat-chip-option>
      </div>
    </div>
  </div>
</mat-card>
