import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { IGeneralizedAnxiety } from 'src/app/_models/assessments/anxiety/generalizedanxiety.model';
import {
  GeneralizedAnxietyApiService,
  ToastMessageService,
} from 'src/app/_services/';

@Component({
  selector: 'app-generalized-anxiety',
  templateUrl: './generalized-anxiety.component.html',
  styleUrls: ['./generalized-anxiety.component.css'],
})
export class GeneralizedAnxietyComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public generalizedAnxietyApiService: GeneralizedAnxietyApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) {}
  public riskScore: number;
  public generalizedAnxietyForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  generalizedAnxietyAssessment: IGeneralizedAnxiety;
  generalizedAnxietyAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.generalizedAnxietyForm, this.errors);

    this.loadGeneralizedAnxietyAssessment();
  }

  loadGeneralizedAnxietyAssessment() {
    this.generalizedAnxietyForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.generalizedAnxietyApiService
        .getGeneralizedAnxietyDetails(this.assessmentId)
        .subscribe((generalizedAnxietyAssessment) => {
          if (generalizedAnxietyAssessment.data) {
            this.generalizedAnxietyForm.controls['feelings'].setValue(
              generalizedAnxietyAssessment.data.feelings.answer
            );
            this.generalizedAnxietyForm.controls['notBeingAbleToStop'].setValue(
              generalizedAnxietyAssessment.data.notBeingAbleToStop.answer
            );
            this.generalizedAnxietyForm.controls['worryingToMuch'].setValue(
              generalizedAnxietyAssessment.data.worryingToMuch.answer
            );
            this.generalizedAnxietyForm.controls['noRelaxation'].setValue(
              generalizedAnxietyAssessment.data.noRelaxation.answer
            );
            this.generalizedAnxietyForm.controls['restless'].setValue(
              generalizedAnxietyAssessment.data.restless.answer
            );
            this.generalizedAnxietyForm.controls['easilyAnnoyed'].setValue(
              generalizedAnxietyAssessment.data.easilyAnnoyed.answer
            );
            this.generalizedAnxietyForm.controls['feelingAfraid'].setValue(
              generalizedAnxietyAssessment.data.feelingAfraid.answer
            );

            this.riskScore = generalizedAnxietyAssessment.data.riskScore;
          }
        });
    }
  }

  onFormSubmit() {
    if (this.generalizedAnxietyForm.invalid) {
      this.generalizedAnxietyForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.generalizedAnxietyAssessment = Object.assign(
      {},
      this.generalizedAnxietyAssessment
    );
    this.generalizedAnxietyAssessment.patientId =
      this.generalizedAnxietyForm.controls['patientId'].value;
    this.generalizedAnxietyAssessment.sessionId =
      this.generalizedAnxietyForm.controls['sessionId'].value;
    this.generalizedAnxietyAssessment.assessmentRequestId =
      this.generalizedAnxietyForm.controls['assessmentRequestId'].value;
    this.generalizedAnxietyAssessment.feelings = {
      answer: this.generalizedAnxietyForm.controls['feelings'].value,
    };
    this.generalizedAnxietyAssessment.notBeingAbleToStop = {
      answer: this.generalizedAnxietyForm.controls['notBeingAbleToStop'].value,
    };
    this.generalizedAnxietyAssessment.worryingToMuch = {
      answer: this.generalizedAnxietyForm.controls['worryingToMuch'].value,
    };
    this.generalizedAnxietyAssessment.noRelaxation = {
      answer: this.generalizedAnxietyForm.controls['noRelaxation'].value,
    };
    this.generalizedAnxietyAssessment.restless = {
      answer: this.generalizedAnxietyForm.controls['restless'].value,
    };
    this.generalizedAnxietyAssessment.easilyAnnoyed = {
      answer: this.generalizedAnxietyForm.controls['easilyAnnoyed'].value,
    };
    this.generalizedAnxietyAssessment.feelingAfraid = {
      answer: this.generalizedAnxietyForm.controls['feelingAfraid'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.generalizedAnxietyAssessment.accessCode = this.accessCode;

      this.generalizedAnxietyApiService
        .addGuestGeneralizedAnxiety(this.generalizedAnxietyAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Generalized Anxiety Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Generalized Anxiety Assessment'
            );
          }
        );
    } else {
      this.generalizedAnxietyApiService
        .addGeneralizedAnxiety(this.generalizedAnxietyAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Generalized Anxiety Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Generalized Anxiety Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    feelings: [
      {
        type: 'required',
        message: '1. Feeling nervous anxious or on edge is required',
      },
    ],
    notBeingAbleToStop: [
      {
        type: 'required',
        message: '2. Not being able to stop or control worrying is required',
      },
    ],
    worryingToMuch: [
      {
        type: 'required',
        message: '3. Worrying too much about different things is required',
      },
    ],
    noRelaxation: [
      { type: 'required', message: '4. Trouble relaxing is required' },
    ],
    restless: [
      {
        type: 'required',
        message: '5. Being so restless that it’s hard to sit still is required',
      },
    ],
    easilyAnnoyed: [
      {
        type: 'required',
        message: '6. Becoming easily annoyed or irritable is required',
      },
    ],
    feelingAfraid: [
      {
        type: 'required',
        message:
          '7. Feeling afraid as if something awful might happen is required',
      },
    ],
  };

  private buildForm() {
    this.generalizedAnxietyForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      feelings: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      notBeingAbleToStop: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      worryingToMuch: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      noRelaxation: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      restless: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      easilyAnnoyed: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feelingAfraid: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.generalizedAnxietyForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.generalizedAnxietyForm) {
      return;
    }
    const form = this.generalizedAnxietyForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
