import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Patient } from 'src/app/_models/patient/patient';

@Component({
  selector: 'app-member-erx',
  templateUrl: './erx.component.html',
  styleUrls: ['./erx.component.css'],
})
export class ErxComponent implements OnInit {
  @Input() patient: Patient;

  @Input() hasMedicationViewAccess: boolean;
  @Input() hasMedicationAddAccess: boolean;
  @Input() hasMedicationEditAccess: boolean;
  @Input() hasMedicationDeleteAccess: boolean;
  @Input() prescriptionEnabled: boolean;

  @Output() output = new EventEmitter<any>();
  activeTab: string = '';

  constructor() {}

  ngOnInit(): void {
    if (this.hasMedicationViewAccess) {
      this.activeTab = 'Medication History';
    } else {
      this.activeTab = 'E-Prescriptions';
    }
  }

  triggerEvent(event) {
    this.output.emit(event);
  }
}
