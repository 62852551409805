import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {
  HealthConditions,
  IMemberIntakeHistory,
  ResponseModal,
} from '../../_models';

@Injectable({ providedIn: 'root' })
export class HealthConditionsApiService {
  constructor(private httpClient: HttpClient) {}

  updateHealthConditions(
    patientId: string,
    healthConditions: HealthConditions
  ): Observable<HealthConditions> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<HealthConditions>(
        `${environment.apiUrl}/` + patientId + `/health-conditions`,
        healthConditions,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getExistingConditions(patientId: string): Observable<ResponseModal> {
    let params = new HttpParams();
    params = params.append('patientId', patientId);
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/` + patientId + `/health-conditions`,
      { params: params }
    );
  }

  updateGuestIntakeMedicalHistoryDetails(
    requestId: string,
    accessCode: string,
    consentRequestId: string,
    history: IMemberIntakeHistory
  ) {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/guestintake/memberhealthconditions/${requestId}/${accessCode}/${consentRequestId}`,
        history,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  memberSelfUpdateMedicalConditions(
    consentRequestId: string,
    history: IMemberIntakeHistory
  ) {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/memberselfupdatemedicalhistory/${consentRequestId}`,
        history,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
