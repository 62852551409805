import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ITherapySession } from 'src/app/_models';
import { TherapySessionService, ToastMessageService } from 'src/app/_services';

@Component({
  selector: 'app-session-general-notes',
  templateUrl: './session-general-notes.component.html',
  styleUrls: ['./session-general-notes.component.css'],
})
export class SessionGeneralNotesComponent implements OnInit {
  therapySession: ITherapySession;
  hasEditAccess: boolean;
  selectedMemberId: string;
  existingGroupMemberNote: string;

  public generalNoteForm: FormGroup;
  processing: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<SessionGeneralNotesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastMessageService: ToastMessageService,
    public therapysessionApiService: TherapySessionService,
    private formBuilder: FormBuilder
  ) {
    this.therapySession = data.therapySession;
    this.hasEditAccess = data.hasEditAccess;
    this.selectedMemberId = data.selectedMemberId;
    this.existingGroupMemberNote = data.existingGroupMemberNote;
  }

  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm() {
    if (this.selectedMemberId) {
      // For group appointments
      this.generalNoteForm = this.formBuilder.group({
        sessionLevelNote: {
          value: this.existingGroupMemberNote,
          disabled:
            this.therapySession.status != 'Active' || !this.hasEditAccess,
        },
      });
    } else {
      // For individual appointments
      this.generalNoteForm = this.formBuilder.group({
        sessionLevelNote: {
          value: this.therapySession.sessionLevelNote,
          disabled:
            this.therapySession.status != 'Active' || !this.hasEditAccess,
        },
      });
    }
  }

  updateNote() {
    this.therapySession = Object.assign(
      this.therapySession,
      this.generalNoteForm.value
    );

    this.processing = true;

    if (this.selectedMemberId) {
      let payload = {
        sessionLevelNote:
          this.generalNoteForm.controls['sessionLevelNote'].value,
      };
      this.therapysessionApiService
        .updateGroupMemberGeneralNote(
          this.therapySession.id,
          this.selectedMemberId,
          payload
        )
        .subscribe({
          next: (response) => {
            this.processing = false;
            this.toastMessageService.displaySuccessMessage(
              'Successfulyy updated the note.'
            );
            this.dialogRef.close({
              type: 'success',
              res: response,
            });
          },
          error: (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to update the note.'
            );
          },
        });
    } else {
      this.therapysessionApiService
        .updateGeneralNote(this.therapySession.id, this.therapySession)
        .subscribe({
          next: (response) => {
            this.processing = false;
            this.toastMessageService.displaySuccessMessage(
              'Successfully updated the note.'
            );
            this.dialogRef.close({
              type: 'success',
              updatedTherapy: response.data,
            });
          },
          error: (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to update the note.'
            );
          },
        });
    }
  }
}
