import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { DataTablesModel, Patient } from 'src/app/_models';
import { PatientService, ToastMessageService } from 'src/app/_services';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-assign-access',
  templateUrl: './assign-access.component.html',
  styleUrl: './assign-access.component.css',
})
export class AssignAccessComponent implements OnInit {
  existingUserList: DataTablesModel = {} as DataTablesModel;
  unAssignedUserList: DataTablesModel = {} as DataTablesModel;

  patient: Patient;

  existingProcessing: boolean = false;
  unAssignedProcessing: boolean = false;
  processing: boolean = false;

  unAuthorizedFirstName: string = '';
  unAuthorizedLastName: string = '';
  email: string = '';

  authorizedFirstName: string = '';
  authorizedLastName: string = '';

  unAssignedDisplayedColumns = ['name', 'email', 'action'];

  constructor(
    public dialogRef: MatDialogRef<AssignAccessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private patientApiService: PatientService,
    private toastMessageService: ToastMessageService
  ) {
    this.patient = data.patient;
  }

  ngOnInit(): void {
    this.existingUserList.page = 0;
    this.existingUserList.per_page = 10;

    this.unAssignedUserList.page = 0;
    this.unAssignedUserList.per_page = 10;

    this.loadUnauthorizedStaffMembers();
    this.loadAuthorizedStaffMembers();
  }

  loadAuthorizedStaffMembers() {
    this.existingProcessing = true;

    this.patientApiService
      .getAuthorizedStaffMembers(
        this.patient.id,
        this.authorizedFirstName,
        this.authorizedLastName,
        this.email,
        this.existingUserList.per_page,
        this.existingUserList.page
      )
      .subscribe({
        next: (response) => {
          this.existingProcessing = false;
          if (response && response.items) {
            this.existingUserList.items = response.items;
            this.existingUserList.total = response.total;
          } else {
            this.existingUserList.items = [];
            this.existingUserList.total = 0;
          }
        },
        error: (error) => {
          this.existingProcessing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to retrieve authorized users'
          );
        },
      });
  }

  loadUnauthorizedStaffMembers() {
    this.unAssignedProcessing = true;

    this.patientApiService
      .loadUnauthorizedStaffMembers(
        this.patient.id,
        this.unAuthorizedFirstName,
        this.unAuthorizedLastName,
        this.email,
        this.unAssignedUserList.per_page,
        this.unAssignedUserList.page
      )
      .subscribe({
        next: (response) => {
          this.unAssignedProcessing = false;
          if (response && response.items) {
            this.unAssignedUserList.items = response.items;
            this.unAssignedUserList.total = response.total;
          } else {
            this.unAssignedUserList.items = [];
            this.unAssignedUserList.total = 0;
          }
        },
        error: (error) => {
          this.unAssignedProcessing = false;

          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get unauthorized users'
          );
        },
      });
  }

  // Assign user as authorized user
  assignUser(careProviderId: string) {
    this.processing = true;
    this.unAssignedProcessing = true;

    this.patientApiService
      .assignAccessToUsers(this.patient.id, careProviderId)
      .subscribe({
        next: (res) => {
          this.processing = false;
          // Handle Pagination
          if (this.unAssignedUserList.items.length == 1) {
            this.unAssignedUserList.page = this.unAssignedUserList.page - 1;

            if (this.unAssignedUserList.page < 0) {
              this.unAssignedUserList.page = 0;
            }
          }

          this.loadUnauthorizedStaffMembers();
          this.loadAuthorizedStaffMembers();
        },
        error: (error) => {
          this.processing = false;
          this.unAssignedProcessing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to assign user. Please try again.'
          );
        },
      });
  }

  // Remove user access
  unAssignUser(careProviderId: string, lastName: string) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        msg: `Are you sure you want the member access from user ${lastName} ?`,
        title: 'Please Confirm',
        yesButtonTitle: 'Proceed',
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.processing = true;

        this.patientApiService
          .unAssignAccessToUsers(this.patient.id, careProviderId)
          .subscribe({
            next: (res) => {
              this.processing = false;

              // Handle Pagination
              if (this.existingUserList.items.length == 1) {
                this.existingUserList.page = this.existingUserList.page - 1;
                if (this.existingUserList.page < 0) {
                  this.existingUserList.page = 0;
                }
              }

              this.loadAuthorizedStaffMembers();
              this.loadUnauthorizedStaffMembers();
            },
            error: () => {
              this.processing = false;
              this.toastMessageService.displayErrorMessage(
                'Error: Failed to unassign user. Please try again.'
              );
            },
          });
      }
    });
  }

  searchUnauthorizedChanged() {
    this.unAssignedUserList.page = 0;
    this.loadUnauthorizedStaffMembers();
  }

  getNextUnAssignedUser(event) {
    this.unAssignedUserList.page = event.pageIndex;
    this.loadUnauthorizedStaffMembers();
  }

  searchAuthorizedChanged() {
    this.existingUserList.page = 0;
    this.loadAuthorizedStaffMembers();
  }

  getNextExistingUser(event) {
    this.existingUserList.page = event.pageIndex;
    this.loadAuthorizedStaffMembers();
  }
}
