import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { DataTablesModel, ResponseModal } from '../../../_models';
import { IHarePsychopathy } from '../../../_models/assessments/personality/harepsychopathy.model';
@Injectable({ providedIn: 'root' })
export class HarePsychopathyApiService {
  constructor(private httpClient: HttpClient) {}

  getHarePsychopathyDetails(
    harepsychopathyId: string
  ): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/harepsychopathy/` + harepsychopathyId
    );
  }

  addHarePsychopathy(thisHarePsychopathy: IHarePsychopathy): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(`${environment.apiUrl}/harepsychopathy`, thisHarePsychopathy, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  addGuestHarePsychopathy(
    thisHarePsychopathy: IHarePsychopathy
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/guestassessments/harepsychopathy`,
        thisHarePsychopathy,
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getHarePsychopathyList(
    patientId: string,
    assessmentId: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('patientId', patientId);
    params = params.append('assessmentId', assessmentId);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/harepsychopathylist`,
      { params: params }
    );
  }
}
