import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { DataTablesModel, ResponseModal } from '../../../_models';
import { IGamingAddiction } from '../../../_models/assessments/addiction/gamingaddiction.model';
@Injectable({ providedIn: 'root' })
export class GamingAddictionApiService {
  constructor(private httpClient: HttpClient) {}

  getGamingAddictionDetails(
    gamingaddictionId: string
  ): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/assessments/gamingaddiction/` + gamingaddictionId
    );
  }

  addGamingAddiction(thisGamingAddiction: IGamingAddiction): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<IGamingAddiction>(
        `${environment.apiUrl}/assessments/gamingaddiction`,
        thisGamingAddiction,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  addGuestGamingAddiction(
    thisGamingAddiction: IGamingAddiction
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<IGamingAddiction>(
        `${environment.apiUrl}/guestassessments/gamingaddiction`,
        thisGamingAddiction,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getGamingAddictionList(
    patientId: string,
    assessmentId: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('patientId', patientId);
    params = params.append('assessmentId', assessmentId);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/assessments/gamingaddictionlist`,
      { params: params }
    );
  }
}
