import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CareLog, DataTablesModel } from '../../_models';

@Injectable({ providedIn: 'root' })
export class CareLogHttpService {
  constructor(private httpClient: HttpClient) {}

  getCareLogs(
    caseId: string,
    currentDate: String,
    shiftNumber: number
  ): Observable<DataTablesModel> {
    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/case/` +
        caseId +
        '/carelogByDay/' +
        currentDate +
        '/shift/' +
        shiftNumber
    );
  }

  addCareLog(caseId: string, careLog: CareLog): Observable<CareLog> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<CareLog>(
        `${environment.apiUrl}/case/` + caseId + `/carelog`,
        careLog,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  addCareLogBehavioralIncident(careLog: CareLog): Observable<CareLog> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<CareLog>(
        `${environment.apiUrl}/carelogbehavioralincident`,
        careLog,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  editCareLog(caseId: string, careLog: CareLog): Observable<CareLog> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<CareLog>(
        `${environment.apiUrl}/case/` + caseId + `/carelog`,
        careLog,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  upsertSessionMemberVitals(
    sessionId: string,
    memberId: string,
    careLog: CareLog
  ): Observable<CareLog> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<CareLog>(
        `${environment.apiUrl}/upsertgroupsessionvital/${sessionId}/${memberId}`,
        careLog,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  deleteCareLog(
    caseId: string,
    logId: string,
    logNumber: string,
    logDate: String,
    shiftNumber: number
  ): Observable<void> {
    return this.httpClient
      .delete<void>(
        `${environment.apiUrl}/case/` +
          caseId +
          `/logid/` +
          logId +
          `/lognumber/` +
          logNumber +
          `/logdate/` +
          logDate +
          '/shift/' +
          shiftNumber
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getAllIncidentsForTheSelectedYear(
    year: string,
    patientId: string,
    organizationId: string
  ) {
    let params = new HttpParams();

    params = params.append('year', year);
    params = params.append('patientId', patientId);
    params = params.append('organizationId', organizationId);

    return this.httpClient.get<any>(
      `${environment.apiUrl}/memberallincidentslog`,
      {
        params: params,
      }
    );
  }
}
