import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { DataTablesModel } from 'src/app/_models';
import {
  CoreService,
  PatientService,
  ToastMessageService,
} from 'src/app/_services';

@Component({
  selector: 'app-my-patients',
  templateUrl: './my-patients.component.html',
  styleUrls: ['./my-patients.component.css'],
})
export class MyPatientsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  processing = true;
  data: DataTablesModel = {} as DataTablesModel;

  loggedInUserId: string = '';
  memberNumber: string = '';
  firstName: string = '';
  lastName: string = '';
  phone: string = '';

  sortBy: string = '';
  filterOption: string = 'Active';
  isOrgAdmin: boolean = false;

  constructor(
    public dialog: MatDialog,
    public coreService: CoreService,
    public patientService: PatientService,
    private toastMessageService: ToastMessageService
  ) {
    this.data.per_page = 10;
    this.data.page = 0;
  }

  ngOnInit() {
    this.isOrgAdmin =
      this.coreService.isSuperOrgAdmin() || this.coreService.isOrgAdmin();

    this.loggedInUserId = this.coreService.getLoggedInCareProviderId();
    this.loadPatients();
  }

  public loadPatients() {
    this.processing = true;

    this.patientService
      .getMyPatients(
        this.data.per_page,
        this.data.page,
        this.filterOption,
        this.sortBy,
        this.memberNumber,
        this.firstName,
        this.lastName,
        this.phone
      )
      .subscribe({
        next: (response) => {
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }
          this.processing = false;
        },
        error: (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get patients.'
          );
        },
      });
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadPatients();
  }

  // Filter and search option
  searchByNameChanged() {
    // Resetting pagination first
    this.data.page = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }

    this.loadPatients();
  }

  filterOptionChanged(filterOption: string) {
    // Resetting pagination first
    this.data.page = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }

    this.filterOption = filterOption;
    this.loadPatients();
  }

  sortByChanged(sortOption: string) {
    // Resetting pagination first
    this.data.page = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }

    this.sortBy = sortOption;
    this.loadPatients();
  }
}
