<div class="row">
  <div class="col-md-12">
    <mat-card appearance="raised" style="border-radius: 15px">
      <!-- Session Information Box -->
      <div class="row">
        <!-- Billing provider -->
        <div class="col">
          <label class="labelBold">Billing Provider</label>
          <br />
          <label style="font-weight: 500">
            <b>
              {{ therapySession?.billingProviderName }}
            </b>
            <br />
            <span *ngIf="therapySession?.billingProviderPhone">
              {{ therapySession?.billingProviderPhone | phoneFormat }}
            </span>
            <br />
            {{ therapySession?.billingProviderAddressLine1 }},
            <br />
            {{ therapySession?.billingProviderCity }},
            {{ therapySession?.billingProviderState }},
            {{ therapySession?.billingProviderZip }}
            <br />
            Group NPI ({{ therapySession?.billingProviderGroupNPI }})
          </label>
        </div>

        <!-- Member details-->
        <div class="col">
          <label class="labelBold">Member</label>
          <br />
          <label style="font-weight: 500">
            <b>
              {{ therapySession?.patientLastName }},{{ ' ' }}
              {{ therapySession?.patientFirstName }}
            </b>
            <br />
            <span *ngIf="therapySession?.intakeMemberData.phoneNumber">
              {{ therapySession?.intakeMemberData.phoneNumber | phoneFormat }}
            </span>
            <br />
            {{ therapySession?.intakeMemberData.addressLine1 }},
            <br />
            {{ therapySession?.intakeMemberData.city }},
            {{ therapySession?.intakeMemberData.state }},
            {{ therapySession?.intakeMemberData.zip }}
          </label>
        </div>

        <!-- Appointment details-->
        <div class="col">
          <label class="labelBold">Appointment Details</label>
          <br />
          <label style="font-weight: 500">
            <b>
              {{ therapySession?.visitReasonName }} ({{
                therapySession?.sessionDuration
              }}
              min)
            </b>
            <br />
            {{
              therapySession?.start | date : 'M/d/y, h:mm a' : currentTimezone
            }}
            {{ currentTimezone }}
            <br />
            {{ therapySession?.sessionType }}
          </label>
        </div>

        <!-- Payer details-->
        <div class="col">
          <label class="labelBold">Primary Payer</label>
          <br />
          <label style="font-weight: 500">
            <b>
              {{ therapySession?.primaryInsuranceDetails?.payerName }}
            </b>
            <br />
            <u><b>Insured Address</b></u>
            <br />
            {{ therapySession?.primaryInsuranceDetails?.addressLine1 }},
            <br />
            {{ therapySession?.primaryInsuranceDetails?.city }},
            {{ therapySession?.primaryInsuranceDetails?.state }},
            {{ therapySession?.primaryInsuranceDetails?.zip }}
            <br />
            <b style="color: red">
              Total Charge -
              {{ therapySession?.billingTotal | currency : 'USD' }}
            </b>
          </label>
        </div>
      </div>
    </mat-card>
  </div>
</div>
