import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StreamApiService } from 'src/app/_services/messaging/stream.service';
import {
  AvatarContext,
  ChannelService,
  ChatClientService,
  CustomTemplatesService,
  StreamI18nService,
} from 'stream-chat-angular';

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.component.html',
  styleUrl: './messaging.component.css',
})
export class MessagingComponent implements OnInit, AfterViewInit {
  @ViewChild('avatarTemplate')
  private avatarTemplate!: TemplateRef<AvatarContext>;

  channelService: ChannelService;
  chatService: ChatClientService;
  streamI18nService: StreamI18nService;

  isMenuOpen = true;

  constructor(
    public dialogRef: MatDialogRef<MessagingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customTemplatesService: CustomTemplatesService,
    public streamApiService: StreamApiService
  ) {
    this.channelService = data.channelService;
    this.chatService = data.chatService;
    this.streamI18nService = data.streamI18nService;
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.customTemplatesService.avatarTemplate$.next(this.avatarTemplate);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
