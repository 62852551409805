import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataTablesModel, ResponseModal } from 'src/app/_models';
import { IAllergy } from 'src/app/_models/patient/allergy.model';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AllergyApiService {
  constructor(private httpClient: HttpClient) {}

  getAllergyList(
    patientId: string,
    status: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    if (status) params = params.append('status', status);
    params = params.append('patientId', patientId);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/allergylist`,
      { params: params }
    );
  }

  getAllergyDetails(allergyId: string): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/allergy/` + allergyId
    );
  }

  addAllergy(thisAllergy: IAllergy): Observable<IAllergy> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<IAllergy>(`${environment.apiUrl}/allergy`, thisAllergy, { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateAllergy(thisAllergy: IAllergy): Observable<IAllergy> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<IAllergy>(`${environment.apiUrl}/allergy`, thisAllergy, { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  // Delete notification
  deleteAllery(id: string) {
    return this.httpClient.delete<any>(`${environment.apiUrl}/allergy/` + id);
  }
}
