import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { ITherapySession } from 'src/app/_models';
import {
  DateTimeZoneService,
  TherapySessionService,
  ToastMessageService,
} from 'src/app/_services';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { PermissionsSandbox } from 'src/app/shared/sandbox/permissions.sandbox';
import { hasAccess } from 'src/app/shared/utilities/utilities';
import { GroupSessionPrintNotesComponent } from './group-session-print-notes/group-session-print-notes.component';
import { GroupSessionsHandleMembersComponent } from './group-sessions-handle-members/group-sessions-handle-members.component';

@Component({
  selector: 'app-group-sessions',
  templateUrl: './group-sessions.component.html',
  styleUrl: './group-sessions.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class GroupSessionsComponent implements OnInit {
  sessionId: string;
  memId: string;
  parent: string;

  isLoadingResults: boolean = false;
  apiError: boolean = false;
  processing: boolean = false;

  sessionDetails: ITherapySession;
  currentTimezone: string = '';

  // By Default Note Section
  viewCategory: string = 'Notes';
  selectedMemberId: string = 'All Members';

  // Members List
  orgConfig: any;
  consolidatedMemberSearch = false;
  showMemberNumber = false;
  hasMemberAddAccess = false;
  searchMemberName: string = '';
  allMembers = [];
  membersSelectionList = [];
  filteredMembers = [];
  memberNamesMap: Map<string, string> = new Map();

  // Loading the required permissions
  hasTherapySessionEditAccess = false;
  hasClinicalNotesAddAccess = false;
  hasClinicalNotesEditAccess = false;
  // Member Goals
  hasGoalsViewAccess = false;
  hasGoalsAddAccess = false;
  hasGoalsEditAccess = false;
  hasGoalsDeleteAccess = false;
  // Member Diagnosis
  hasDiagnosisViewAccess = false;
  hasDiagnosisAddAccess = false;
  hasDiagnosisEditAccess = false;
  hasDiagnosisDeleteAccess = false;
  // Member Medications
  hasMedicationViewAccess = false;
  hasMedicationAddAccess = false;
  hasMedicationEditAccess = false;
  hasMedicationDeleteAccess = false;
  // Assessments
  hasMemberAssessmentViewAccess = false;
  hasMemberAssessmentAddAccess = false;
  hasMemberAssessmentEditAccess = false;
  hasMemberAssessmentDeleteAccess = false;
  // Assessments Charts
  hasAssessmentChartsViewAccess = false;
  // Assessments Guidance
  hasAssessmentGuidanceViewAccess = false;
  hasAssessmentGuidanceEditAccess = false;
  // Assessments Summary
  hasAssessmentSummaryViewAccess = false;
  hasAssessmentSummaryEditAccess = false;
  // Assessments Take
  hasAssessmentTakeAddAccess = false;
  hasMemberPaymentsAddAccess = false;

  // Session billing
  hasBillingViewAccess = false;
  hasBillingEditAccess = false;

  constructor(
    public router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService,
    private dateTimeZoneService: DateTimeZoneService,
    private orgConfigSandbox: OrgConfigSandbox,
    public therapySessionService: TherapySessionService,
    protected permissionsSandbox: PermissionsSandbox,
    private keycloakService: KeycloakService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit(): void {
    this.sessionId = this.route.snapshot.paramMap.get('id');
    this.route.queryParamMap.subscribe((param) => {
      this.memId = param.get('memId');
      this.parent = param.get('parent');
    });

    // Handle Org Config to get features
    this.initializeOrgConfig();
    this.loadMyPermissions();

    // Get session detail
    this.getSessionDetails();
  }

  // Get the required orgconfig Information
  initializeOrgConfig() {
    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        // When load is complete, get the org config from app state
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          // Saving the org config
          this.orgConfig = orgConfig;

          // Check for organization features
          if (this.orgConfig && this.orgConfig?.features) {
            this.consolidatedMemberSearch = this.orgConfig.features.includes(
              'CONSOLIDATED_MEMBER_SEARCH'
            );

            this.showMemberNumber = this.orgConfig.features.includes(
              'MANUAL_MEMBER_NUMBER'
            );
          }
        });
      }
    });
  }

  // Get My Permissions
  loadMyPermissions() {
    this.permissionsSandbox.permissions$.subscribe((response) => {
      this.hasTherapySessionEditAccess = hasAccess(
        this.keycloakService,
        'THERAPY_SESSIONS_EDIT',
        response,
        null
      );
      this.hasClinicalNotesAddAccess = hasAccess(
        this.keycloakService,
        'CLINICAL_NOTES_ADD',
        response,
        null
      );
      this.hasClinicalNotesEditAccess = hasAccess(
        this.keycloakService,
        'CLINICAL_NOTES_EDIT',
        response,
        null
      );
      this.hasMemberAddAccess = hasAccess(
        this.keycloakService,
        'MEMBERS_ADD',
        response,
        null
      );
      // Assessments
      this.hasMemberAssessmentViewAccess = hasAccess(
        this.keycloakService,
        'MEMBER_ASSESSMENTS_VIEW',
        response,
        null
      );
      this.hasMemberAssessmentAddAccess = hasAccess(
        this.keycloakService,
        'MEMBER_ASSESSMENTS_ADD',
        response,
        null
      );
      this.hasMemberAssessmentEditAccess = hasAccess(
        this.keycloakService,
        'MEMBER_ASSESSMENTS_EDIT',
        response,
        null
      );
      this.hasMemberAssessmentDeleteAccess = hasAccess(
        this.keycloakService,
        'MEMBER_ASSESSMENTS_DELETE',
        response,
        null
      );
      // Assessments Charts
      this.hasAssessmentChartsViewAccess = hasAccess(
        this.keycloakService,
        'CHARTS_VIEW',
        response,
        null
      );
      this.hasAssessmentGuidanceEditAccess = hasAccess(
        this.keycloakService,
        'GUIDANCE_EDIT',
        response,
        null
      );
      // Assessments Summary
      this.hasAssessmentSummaryViewAccess = hasAccess(
        this.keycloakService,
        'SUMMARY_VIEW',
        response,
        null
      );
      this.hasAssessmentSummaryEditAccess = hasAccess(
        this.keycloakService,
        'SUMMARY_EDIT',
        response,
        null
      );
      // Assessments Take
      this.hasAssessmentTakeAddAccess = hasAccess(
        this.keycloakService,
        'TAKE_ASSESSMENTS_ADD',
        response,
        null
      );
      this.hasAssessmentGuidanceViewAccess = hasAccess(
        this.keycloakService,
        'GUIDANCE_VIEW',
        response,
        null
      );
      // Member Goals
      this.hasGoalsViewAccess = hasAccess(
        this.keycloakService,
        'GOALS_VIEW',
        response,
        null
      );
      this.hasGoalsAddAccess = hasAccess(
        this.keycloakService,
        'GOALS_ADD',
        response,
        null
      );
      this.hasGoalsEditAccess = hasAccess(
        this.keycloakService,
        'GOALS_EDIT',
        response,
        null
      );
      this.hasGoalsDeleteAccess = hasAccess(
        this.keycloakService,
        'GOALS_DELETE',
        response,
        null
      );
      // Member Diagnosis
      this.hasDiagnosisViewAccess = hasAccess(
        this.keycloakService,
        'DIAGNOSIS_VIEW',
        response,
        null
      );
      this.hasDiagnosisAddAccess = hasAccess(
        this.keycloakService,
        'DIAGNOSIS_ADD',
        response,
        null
      );
      this.hasDiagnosisEditAccess = hasAccess(
        this.keycloakService,
        'DIAGNOSIS_EDIT',
        response,
        null
      );
      this.hasDiagnosisDeleteAccess = hasAccess(
        this.keycloakService,
        'DIAGNOSIS_DELETE',
        response,
        null
      );
      // Member Medications
      this.hasMedicationViewAccess = hasAccess(
        this.keycloakService,
        'MEDICATIONS_VIEW',
        response,
        null
      );
      this.hasMedicationAddAccess = hasAccess(
        this.keycloakService,
        'MEDICATIONS_ADD',
        response,
        null
      );
      this.hasMedicationEditAccess = hasAccess(
        this.keycloakService,
        'MEDICATIONS_EDIT',
        response,
        null
      );
      this.hasMedicationDeleteAccess = hasAccess(
        this.keycloakService,
        'MEDICATIONS_DELETE',
        response,
        null
      );
      // Payments
      this.hasMemberPaymentsAddAccess = hasAccess(
        this.keycloakService,
        'MEMBER_PAYMENTS_ADD',
        response,
        null
      );
      this.hasBillingViewAccess = hasAccess(
        this.keycloakService,
        'SESSION_BILLING_VIEW',
        response,
        null
      );
      this.hasBillingEditAccess = hasAccess(
        this.keycloakService,
        'SESSION_BILLING_EDIT',
        response,
        null
      );
    });
  }

  // Get details about the existing group session
  getSessionDetails() {
    this.isLoadingResults = true;
    this.apiError = false;

    this.therapySessionService
      .getTherapySessionDetails(this.sessionId)
      .subscribe({
        next: (response) => {
          this.reloadSessionDetailsFromResponse(response);

          this.isLoadingResults = false;
        },
        error: (error) => {
          this.isLoadingResults = false;
          this.apiError = true;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to retrieve session details.'
          );
        },
      });
  }

  // Loading group members
  loadGroupMembers() {
    // Resetting the List
    this.allMembers = [];
    this.filteredMembers = [];
    this.membersSelectionList = [];
  }

  // Search by name changed
  searchByMemberChanged() {
    if (this.searchMemberName) {
      this.filteredMembers = this.allMembers.filter((member) => {
        const lastName = member.member.lastName.toLowerCase();
        const firstName = member.member.firstName.toLowerCase();
        const concatFirstLast = firstName + ' ' + lastName;
        const concatLastFirst = lastName + ' ' + firstName;
        if (
          concatFirstLast.includes(this.searchMemberName.toLowerCase()) ||
          concatLastFirst.includes(this.searchMemberName.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      this.filteredMembers = [...this.allMembers];
    }
  }

  // Add Remove attendance
  takeAttendance(member: any, attendace: boolean) {
    this.processing = true;

    this.therapySessionService
      .handleGroupSessionMemberAttendance(
        this.sessionId,
        member.member.id,
        attendace
      )
      .subscribe({
        next: (response) => {
          this.reloadSessionDetailsFromResponse(response);
          this.processing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to change the attendance status'
          );
          this.processing = false;
        },
      });
  }

  // Common function to update session details
  reloadSessionDetailsFromResponse(response) {
    // Now we first need to update the existing details
    this.sessionDetails = response.data;
    // Now re-load Group Members
    this.allMembers = response.data.groupMembers;
    this.filteredMembers = response.data.groupMembers;
    this.membersSelectionList = response.data.groupMembers;
    // Also handle search
    this.searchByMemberChanged();
    // Load Members Name Map
    this.loadMembersNameMap();
  }

  // Select this member
  viewMember(member) {
    this.selectedMemberId = member.member.id;
  }
  // View All Members
  viewAllMembers() {
    this.selectedMemberId = 'All Members';
  }
  // Id->Member name map
  loadMembersNameMap() {
    this.allMembers.forEach((member) => {
      let memberName = member.member.lastName + ', ' + member.member.firstName;
      this.memberNamesMap.set(member.member.id, memberName);
    });
  }
  // Get member type for label
  getMembersType() {
    if (this.selectedMemberId === 'All Members') {
      return 'at the group level';
    } else {
      // Need to find the members
      return 'for ' + this.memberNamesMap.get(this.selectedMemberId);
    }
  }

  addNewMembers() {
    const dialogRef = this.dialog.open(GroupSessionsHandleMembersComponent, {
      data: {
        sessionDetails: this.sessionDetails,
        sessionId: this.sessionId,
        consolidatedMemberSearch: this.consolidatedMemberSearch,
        hasMemberAddAccess: this.hasMemberAddAccess,
        showMemberNumber: this.showMemberNumber,
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '50vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        // Reload session object
        this.getSessionDetails();
      }
    });
  }

  // Handle group members logic
  addRemoveMemberToGroup(member: any, type: boolean) {
    this.processing = true;

    this.therapySessionService
      .handleGroupSessionMembers(this.sessionId, member.id, type)
      .subscribe({
        next: (response) => {
          if (!type && this.selectedMemberId != 'All Members') {
            // Re-select to All Members
            this.selectedMemberId = 'All Members';
          }

          this.reloadSessionDetailsFromResponse(response);

          this.processing = false;
        },
        error: (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to edit group members'
          );
        },
      });
  }

  closeNote() {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Close Group Note',
        msg: 'Are you sure that you want to close the group note?',
        disclaimer:
          'By closing this session note, all associated notes and charges for each group member will also be closed. Please ensure that all necessary documentation is complete before proceeding.',
        yesButtonTitle: 'Proceed',
        noButtonTitle: 'Cancel',
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '40vw',
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.processing = true;

        this.therapySessionService.closeGroupSession(this.sessionId).subscribe({
          next: (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Group Session has been closed'
            );
            this.reloadSessionDetailsFromResponse(response);
            this.processing = false;
          },
          error: (error) => {
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to close the group session'
            );
            this.processing = false;
          },
        });
      }
    });
  }

  viewPrintNotes() {
    const dialogRef = this.dialog.open(GroupSessionPrintNotesComponent, {
      data: {
        sessionDetails: this.sessionDetails,
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '50vw',
    });
  }
}
