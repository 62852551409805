<mat-tab-group [@.disabled]="true">
    <mat-tab>
        <ng-template mat-tab-label>
            <i class="fa fa-check-circle" *ngIf="isTaskCompleted('GENERAL_INFORMATION')" aria-hidden="true"
                style="color: #43a047; padding-right: 5px">
            </i>
            General Information
        </ng-template>
        <app-asi-general [assessmentRequestId]="assessmentRequestId" [patientId]="patientId" [sessionId]="sessionId"
            [loggedInUserId]="loggedInUserId">
        </app-asi-general>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <i class="fa fa-check-circle" *ngIf="isTaskCompleted('MEDICAL_STATUS')" aria-hidden="true"
                style="color: #43a047; padding-right: 5px"></i>
            Medical Status
        </ng-template>
        <app-asi-medical [assessmentRequestId]="assessmentRequestId" [patientId]="patientId" [sessionId]="sessionId"
            [loggedInUserId]="loggedInUserId"></app-asi-medical>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <i class="fa fa-check-circle" *ngIf="isTaskCompleted('EMPLOYMENT_SUPPORT_STATUS')" aria-hidden="true"
                style="color: #43a047; padding-right: 5px"></i>
            Employment/Support Status
        </ng-template>
        <app-asi-employment-support [assessmentRequestId]="assessmentRequestId" [patientId]="patientId"
            [sessionId]="sessionId" [loggedInUserId]="loggedInUserId"></app-asi-employment-support>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <i class="fa fa-check-circle" *ngIf="isTaskCompleted('ALCOHOL_DRUGS')" aria-hidden="true"
                style="color: #43a047; padding-right: 5px"></i>
            Alcohol / Drugs
        </ng-template>
        <app-asi-alcohol-drugs [assessmentRequestId]="assessmentRequestId" [patientId]="patientId"
            [sessionId]="sessionId" [loggedInUserId]="loggedInUserId"></app-asi-alcohol-drugs>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <i class="fa fa-check-circle" *ngIf="isTaskCompleted('LEGAL_STATUS')" aria-hidden="true"
                style="color: #43a047; padding-right: 5px"></i>
            Legal Status
        </ng-template>
        <app-asi-legal [assessmentRequestId]="assessmentRequestId" [patientId]="patientId" [sessionId]="sessionId"
            [loggedInUserId]="loggedInUserId"></app-asi-legal>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <i class="fa fa-check-circle" *ngIf="isTaskCompleted('FAMILY_HISTORY')" aria-hidden="true"
                style="color: #43a047; padding-right: 5px"></i>
            Family History
        </ng-template>
        <app-asi-family-history [assessmentRequestId]="assessmentRequestId" [patientId]="patientId"
            [sessionId]="sessionId" [loggedInUserId]="loggedInUserId"></app-asi-family-history>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <i class="fa fa-check-circle" *ngIf="isTaskCompleted('FAMILY_SOCIAL_RELATIONSHIPS')" aria-hidden="true"
                style="color: #43a047; padding-right: 5px"></i>
            Family / Social Relationships
        </ng-template>
        <app-asi-family-social-relationships [assessmentRequestId]="assessmentRequestId" [patientId]="patientId"
            [sessionId]="sessionId" [loggedInUserId]="loggedInUserId"></app-asi-family-social-relationships>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <i class="fa fa-check-circle" *ngIf="isTaskCompleted('PSYCHIATRIC_STATUS')" aria-hidden="true"
                style="color: #43a047; padding-right: 5px"></i>
            Psychiatric Status
        </ng-template>
        <app-asi-psychiatric [assessmentRequestId]="assessmentRequestId" [patientId]="patientId" [sessionId]="sessionId"
            [loggedInUserId]="loggedInUserId"></app-asi-psychiatric>
    </mat-tab>
</mat-tab-group>