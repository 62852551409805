<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Young Mania Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="youngManiaForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">1. Elevated Mood</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="mood"
        >
          <mat-button-toggle value="Absent">Absent</mat-button-toggle>
          <mat-button-toggle value="Mildly or possibly increased"
            >Mildly or possibly increased</mat-button-toggle
          >
          <mat-button-toggle
            value="Definite subjective elevation; optimistic, self-confident; cheerful; appropriate to content"
            >Definite subjective elevation; optimistic, self-confident;
            cheerful; appropriate to content</mat-button-toggle
          >
          <mat-button-toggle
            value="Elevated, inappropriate to content; humorous"
            >Elevated, inappropriate to content; humorous</mat-button-toggle
          >
          <mat-button-toggle value="Euphoric, inappropriate laughter, singing"
            >Euphoric, inappropriate laughter, singing</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >2. Increased Motor Activity or Energy</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="activity"
        >
          <mat-button-toggle value="Absent">Absent</mat-button-toggle>
          <mat-button-toggle value="Subjectively increased"
            >Subjectively increased</mat-button-toggle
          >
          <mat-button-toggle value="Animated; gestures increased"
            >Animated; gestures increased</mat-button-toggle
          >
          <mat-button-toggle
            value="Excessive energy; hyperactive at times; restless (can be calmed)"
            >Excessive energy; hyperactive at times; restless (can be
            calmed)</mat-button-toggle
          >
          <mat-button-toggle
            value="Motor excitement; continuous hyperactivity (cannot be calmed)"
            >Motor excitement; continuous hyperactivity (cannot be
            calmed)</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">3. Sexual Interest</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="interests"
        >
          <mat-button-toggle value="Normal; not increased"
            >Normal; not increased</mat-button-toggle
          >
          <mat-button-toggle value="Mildly or possibly increased"
            >Mildly or possibly increased</mat-button-toggle
          >
          <mat-button-toggle value="Definite subjective increase"
            >Definite subjective increase</mat-button-toggle
          >
          <mat-button-toggle
            value="Spontaneous sexual content; elaborates on sexual matters; hypersexual"
            >Spontaneous sexual content; elaborates on sexual matters;
            hypersexual</mat-button-toggle
          >
          <mat-button-toggle value="Overt sexual acts"
            >Overt sexual acts</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">4. Sleep</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="rest"
        >
          <mat-button-toggle value="No decrease in sleep"
            >No decrease in sleep</mat-button-toggle
          >
          <mat-button-toggle
            value="Sleeping less than normal amount by up to one hour"
            >Sleeping less than normal amount by up to one
            hour</mat-button-toggle
          >
          <mat-button-toggle
            value="Sleeping less than normal by more than one hour"
            >Sleeping less than normal by more than one hour</mat-button-toggle
          >
          <mat-button-toggle value="Decreased need for sleep"
            >Decreased need for sleep</mat-button-toggle
          >
          <mat-button-toggle value="No need for sleep at all"
            >No need for sleep at all</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">5. Irritability</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="anoyyed"
        >
          <mat-button-toggle value="Absent">Absent</mat-button-toggle>
          <mat-button-toggle value="Subjectively increased"
            >Subjectively increased</mat-button-toggle
          >
          <mat-button-toggle
            value="Irritable at times; recent episodes of anger or annoyance"
            >Irritable at times; recent episodes of anger or
            annoyance</mat-button-toggle
          >
          <mat-button-toggle value="Frequently irritable; short, curt"
            >Frequently irritable; short, curt</mat-button-toggle
          >
          <mat-button-toggle value="Hostile, uncooperative"
            >Hostile, uncooperative</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">6. Speech: Rate & Amount</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="speaking"
        >
          <mat-button-toggle value="No increase">No increase</mat-button-toggle>
          <mat-button-toggle value="Feel talkative"
            >Feel talkative</mat-button-toggle
          >
          <mat-button-toggle
            value="Increased rate or amount at times, verbose at times"
            >Increased rate or amount at times, verbose at
            times</mat-button-toggle
          >
          <mat-button-toggle
            value="Push; consistently increased rate and amount;"
            >Push; consistently increased rate and amount;</mat-button-toggle
          >
          <mat-button-toggle
            value="Pressured; uninterruptedly, continuous speech"
            >Pressured; uninterruptedly, continuous speech</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >7. Language: Thought Disorder</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="thoughts"
        >
          <mat-button-toggle value="Absent">Absent</mat-button-toggle>
          <mat-button-toggle
            value="Circumstantial; mild distractibility; quick thoughts"
            >Circumstantial; mild distractibility; quick
            thoughts</mat-button-toggle
          >
          <mat-button-toggle
            value="Distractible; loses goal of thought; change topics frequently; racing thoughts"
            >Distractible; loses goal of thought; change topics frequently;
            racing thoughts</mat-button-toggle
          >
          <mat-button-toggle
            value="Flight of ideas; tangentially; difficult to follow; rhyming, echolalia"
            >Flight of ideas; tangentially; difficult to follow; rhyming,
            echolalia</mat-button-toggle
          >
          <mat-button-toggle value="Incoherent; communication impossible"
            >Incoherent; communication impossible</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">8. Content</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="content"
        >
          <mat-button-toggle value="Normal">Normal</mat-button-toggle>
          <mat-button-toggle value="Questionable plans, new interests"
            >Questionable plans, new interests</mat-button-toggle
          >
          <mat-button-toggle value="Special project(s); hyper religious"
            >Special project(s); hyper religious</mat-button-toggle
          >
          <mat-button-toggle
            value="Grandiose or paranoid ideas; ideas of reference"
            >Grandiose or paranoid ideas; ideas of reference</mat-button-toggle
          >
          <mat-button-toggle value="Delusions; hallucinations"
            >Delusions; hallucinations</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after"
        >9. Disruptive or Aggressive Behavior</label
      >
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="behavior"
        >
          <mat-button-toggle value="Absent">Absent</mat-button-toggle>
          <mat-button-toggle value="Sarcastic; loud at times, guarded"
            >Sarcastic; loud at times, guarded</mat-button-toggle
          >
          <mat-button-toggle value="Demanding; threats"
            >Demanding; threats</mat-button-toggle
          >
          <mat-button-toggle value="Threats, shouting"
            >Threats, shouting</mat-button-toggle
          >
          <mat-button-toggle value="Assaultive; destructive"
            >Assaultive; destructive</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">10. Appearance</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="visualAppearance"
        >
          <mat-button-toggle value="Appropriate dress and grooming"
            >Appropriate dress and grooming</mat-button-toggle
          >
          <mat-button-toggle value="Minimally unkempt"
            >Minimally unkempt</mat-button-toggle
          >
          <mat-button-toggle
            value="Poorly groomed; moderately disheveled; overdressed"
            >Poorly groomed; moderately disheveled;
            overdressed</mat-button-toggle
          >
          <mat-button-toggle value="Disheveled; partly clothed; garish make-up"
            >Disheveled; partly clothed; garish make-up</mat-button-toggle
          >
          <mat-button-toggle value="Completely unkempt; decorated; bizarre garb"
            >Completely unkempt; decorated; bizarre garb</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">11. Insight</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="change"
        >
          <mat-button-toggle
            value="Present; admits illness; agrees with need for treatment"
            >Present; admits illness; agrees with need for
            treatment</mat-button-toggle
          >
          <mat-button-toggle value="Possibly ill"
            >Possibly ill</mat-button-toggle
          >
          <mat-button-toggle value="Admits behavior change, but denies illness"
            >Admits behavior change, but denies illness</mat-button-toggle
          >
          <mat-button-toggle
            value="Admits possible change in behavior, but denies illness"
            >Admits possible change in behavior, but denies
            illness</mat-button-toggle
          >
          <mat-button-toggle value="Denies any behavior change"
            >Denies any behavior change</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>

    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          youngManiaForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!youngManiaForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
