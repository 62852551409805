<app-intake-header
  sectionTitle="Reasons For Clinical Guidance"
  [therapySession]="therapySession"
  [patient]="patient"
  [showPrintButton]="true"
></app-intake-header>
<mat-divider></mat-divider>

<div style="margin-top: 20px; padding: 10px">
  <form
    [formGroup]="intakeclinicalguidanceForm"
    novalidate
    (ngSubmit)="onIntakeClinicalGuidanceFormSubmit()"
  >
    <label class="labelText"
      >My reason(s) for clinical guidance is/are
      <span style="color: red">*</span></label
    >&nbsp;
    <mat-form-field appearance="outline">
      <textarea formControlName="reasons" matInput rows="5"></textarea>
      <mat-error *ngIf="intakeclinicalguidanceForm.controls['reasons'].invalid">
        Please enter the reason(s) for clinical guidance
      </mat-error>
    </mat-form-field>

    <label class="labelText"
      >My expectation(s) is/are <span style="color: red">*</span></label
    >&nbsp;
    <mat-form-field appearance="outline">
      <textarea formControlName="expectations" matInput rows="5"></textarea>
      <mat-error
        *ngIf="intakeclinicalguidanceForm.controls['expectations'].invalid"
      >
        Please enter the expectations(s)
      </mat-error>
    </mat-form-field>

    <label class="labelText"
      >My preference(s) in a clinician is/are
      <span style="color: red">*</span></label
    >&nbsp;
    <mat-form-field appearance="outline">
      <textarea formControlName="preferences" matInput rows="5"></textarea>
      <mat-error
        *ngIf="intakeclinicalguidanceForm.controls['preferences'].invalid"
      >
        Please enter the preference(s) in a clinician
      </mat-error>
    </mat-form-field>

    <label class="labelText">Additional comment(s) </label>&nbsp;
    <mat-form-field appearance="outline">
      <textarea
        formControlName="additionalComments"
        matInput
        rows="5"
      ></textarea>
    </mat-form-field>
  </form>
</div>
