<mat-card-title>E-Prescriptions</mat-card-title>
<mat-card-content>
  <div class="mat-elevation-z8">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="showSpinner"
    ></mat-progress-bar>

    <!-- Error -->
    <div *ngIf="!isLoadingResults && apiError">
      <mat-error>
        Error Occured. Failed to load the prescription page.
      </mat-error>
    </div>

    <!-- Iframe link -->
    <div id="tmp-dospespot-div" *ngIf="!isLoadingResults && !apiError">
      <iframe
        [src]="iFrameUrl | sanitizeUrl"
        id="medications-iframe"
        style="width: 100%; height: 1000px; border: 0px"
        class="embed-responsive embed-responsive-16by9"
      ></iframe>
    </div>
  </div>
</mat-card-content>
