import { createAction, props } from '@ngrx/store';
import { IUserFavoritesResponse } from '../../../_models/users/user-favorites.model';

// Action for loading all favorites
export const loadFavorites = createAction(
    '[Favorites] Load Favorites'
);

export const loadFavoritesSuccess = createAction(
    '[Favorites] Load Favorites Success',
    props<{ favoritesPayload: IUserFavoritesResponse }>()
);

export const loadFavoritesFailure = createAction(
    '[Favorites] Load Favorites Failure',
    props<{ error: string }>()
);

// Action for adding a favorite
export const addFavorite = createAction(
    '[Favorites] Add Favorite',
    props<{ categoryName: string; favoriteItem: string }>()
);

export const addFavoriteSuccess = createAction(
    '[Favorites] Add Favorite Success',
    props<{ categoryName: string; favoriteItem: string }>()
);

export const addFavoriteFailure = createAction(
    '[Favorites] Add Favorite Failure',
    props<{ error: string }>()
);

// Action for removing a favorite
export const removeFavorite = createAction(
    '[Favorites] Remove Favorite',
    props<{ categoryName: string; favoriteItem: string }>()
);

export const removeFavoriteSuccess = createAction(
    '[Favorites] Remove Favorite Success',
    props<{ categoryName: string; favoriteItem: string }>()
);

export const removeFavoriteFailure = createAction(
    '[Favorites] Remove Favorite Failure',
    props<{ error: string }>()
);
