import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataTablesModel, IAcknowledgeAlerts, Patient } from 'src/app/_models';
import { ConsentDocumentApiService } from 'src/app/_services/consent-docs/orgconsentdocument.service';

@Component({
  selector: 'app-acknowledge-member-alerts',
  templateUrl: './acknowledge-member-alerts.component.html',
  styleUrl: './acknowledge-member-alerts.component.css',
})
export class AcknowledgeMemberAlertsComponent implements OnInit {
  alerts: DataTablesModel = {} as DataTablesModel;
  patient: Patient;

  acknowledgeAlerts: IAcknowledgeAlerts;

  constructor(
    public dialogRef: MatDialogRef<AcknowledgeMemberAlertsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public consentDocumentApiService: ConsentDocumentApiService
  ) {
    this.alerts = data.alerts;
    this.patient = data.patient;
  }

  ngOnInit(): void {}

  submitAcknowledgement() {
    this.acknowledgeAlerts = Object.assign({}, this.acknowledgeAlerts);
    this.acknowledgeAlerts.memberId = this.patient.id;
    this.acknowledgeAlerts.alerts = this.alerts.items;

    // Simply acknowledge and close this tab
    this.consentDocumentApiService
      .acknowledgePatientAlerts(this.acknowledgeAlerts)
      .subscribe();

    this.dialogRef.close('success');
  }
}
