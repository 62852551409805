import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EulaDocumentApiService } from 'src/app/_services/utils/euladocument.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-eula-agreement',
  templateUrl: './eula-agreement.component.html',
  styleUrls: ['./eula-agreement.component.css']
})
export class EulaAgreementComponent implements OnInit {
  isLoading = true;
  public euladocumentForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  processing = false;

  constructor(public euladocumentApiService: EulaDocumentApiService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.buildForm();
    this.loadEulaDocument();
  }

  loadEulaDocument() {
    this.isLoading = true;
    this.euladocumentApiService.getEulaDocumentDetails().subscribe(euladocument => {
      this.isLoading = false;
      if (euladocument.data) {
        this.euladocumentForm.controls['message'].setValue(euladocument.data.message);
        this.euladocumentForm.controls['version'].setValue(euladocument.data.version);
      }
    });
  }

  private buildForm() {
    this.euladocumentForm = this.formBuilder.group({
      message: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      version: new FormControl('', Validators.compose([
        Validators.required,
      ])),
    });
  }

  agreedToTerms() {
    this.processing = true;
    this.euladocumentApiService.addEulaContract(this.euladocumentForm.controls['message'].value, this.euladocumentForm.controls['version'].value).subscribe(() => {
      window.location.href = '/';
    })
  };

  disagreedToTerms() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Disagree?',
        msg: 'Sorry, you won\'t have any access to the site unless you agree to the end user license document',
        yesButtonTitle: 'OK',
        noButtonTitle: 'Close',
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '20vw',
      maxWidth: '250px',
    });
  };
}
