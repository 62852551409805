<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Prior Authorization</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="row" style="margin-top: 15px">
    <div class="col-md-12" style="text-align: right; margin-bottom: 15px">
      <button
        mat-raised-button
        type="button"
        color="warn"
        (click)="editInsurance()"
      >
        + Add New Prior Auth
      </button>
    </div>

    <mat-progress-bar
      mode="indeterminate"
      *ngIf="processing"
    ></mat-progress-bar>

    <div class="col-md-12">
      <!-- if No Data -->
      <div
        *ngIf="!processing && (priorAuths?.length == 0 || !priorAuths?.length)"
      >
        <mat-error>No Prior Authorization found for this insurance</mat-error>
      </div>
      <!-- if Data -->
      <div
        class="mat-elevation-z8"
        *ngIf="!processing && priorAuths?.length > 0"
      >
        <div class="table-responsive">
          <table mat-table [dataSource]="priorAuths">
            <ng-container matColumnDef="authNum">
              <th mat-header-cell *matHeaderCellDef>Auth #</th>
              <td mat-cell *matCellDef="let row" style="font-weight: 500">
                {{ row.authNumber }}
              </td>
            </ng-container>
            <ng-container matColumnDef="start">
              <th mat-header-cell *matHeaderCellDef>Start Date</th>
              <td mat-cell *matCellDef="let row">
                {{ row.effectiveStartDate | date : 'MM/dd/y' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="end">
              <th mat-header-cell *matHeaderCellDef>End Date</th>
              <td mat-cell *matCellDef="let row">
                {{ row.effectiveEndDate | date : 'MM/dd/y' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="remaining">
              <th mat-header-cell *matHeaderCellDef>Visits Remaining</th>
              <td mat-cell *matCellDef="let row">
                <span *ngIf="row.visitType === 'Limited'">
                  <span
                    style="font-weight: 600"
                    [style.color]="
                      row.totalVisitNumber - row.usedVisits <= 5 ? 'red' : ''
                    "
                  >
                    {{ row.totalVisitNumber - row.usedVisits }}
                  </span>
                  out of <span>{{ row.totalVisitNumber }}</span> Visits
                  Remaining
                </span>
                <span *ngIf="row.visitType === 'Unlimited'">
                  Unlimited Visits
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="validity">
              <th mat-header-cell *matHeaderCellDef>Validity</th>
              <td mat-cell *matCellDef="let row">
                <span *ngIf="getValidity(row)">
                  <mat-icon style="color: green">thumb_up</mat-icon>
                </span>
                <span *ngIf="!getValidity(row)">
                  <mat-icon style="color: red">thumb_down</mat-icon>
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>Action</th>
              <td mat-cell *matCellDef="let row">
                <button
                  *ngIf="getValidity(row)"
                  mat-raised-button
                  color="primary"
                  type="button"
                  (click)="selectAuth(row)"
                >
                  Select
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
