<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Member Assessments</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      type="button"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="row">
    <div class="col-md-12">
      <app-session-assessments
        [therapySession]="sessionDetails"
        [patient]="patient"
        [hasMemberAssessmentViewAccess]="true"
        [hasMemberAssessmentAddAccess]="hasMemberAssessmentAddAccess"
        [hasMemberAssessmentEditAccess]="hasMemberAssessmentEditAccess"
        [hasMemberAssessmentDeleteAccess]="hasMemberAssessmentDeleteAccess"
        [hasAssessmentTakeAddAccess]="hasAssessmentTakeAddAccess"
        [hasAssessmentChartsViewAccess]="hasAssessmentChartsViewAccess"
        [hasAssessmentGuidanceViewAccess]="hasAssessmentGuidanceViewAccess"
        [hasAssessmentGuidanceEditAccess]="hasAssessmentGuidanceEditAccess"
        [hasAssessmentSummaryViewAccess]="hasAssessmentSummaryViewAccess"
        [hasAssessmentSummaryEditAccess]="hasAssessmentSummaryEditAccess"
      ></app-session-assessments>
    </div>
  </div>
</div>
