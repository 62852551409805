import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IMoodLog } from 'src/app/_models/hourlylogs/moodlog.model';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class MoodLogApiService {
  constructor(private httpClient: HttpClient) {}

  getMoodRecord(year: string, patientId: string, organizationId: string) {
    let params = new HttpParams();

    params = params.append('year', year);
    params = params.append('patientId', patientId);
    params = params.append('organizationId', organizationId);

    return this.httpClient.get<any>(`${environment.apiUrl}/membermoodlog`, {
      params: params,
    });
  }

  updateMoodLog(thisMoodLog: IMoodLog): Observable<IMoodLog> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<IMoodLog>(`${environment.apiUrl}/moodlog`, thisMoodLog, { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  // Delete mood log
  deleteMoodLog(id: string) {
    return this.httpClient.delete<any>(`${environment.apiUrl}/moodlog/` + id);
  }
}
