import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { DataTablesModel, IContact, Patient } from 'src/app/_models';
import { ContactHttpService, ToastMessageService } from 'src/app/_services';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { AddContactDialogComponent } from './dialogs/add/add.component';

@Component({
  selector: 'app-member-personal-contacts',
  styleUrls: ['personal-contacts.component.css'],
  templateUrl: 'personal-contacts.component.html',
})
export class MemberPersonalContactsComponent implements OnInit, OnChanges {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() patient: Patient;
  @Input() hasContactsViewAccess: boolean;
  @Input() hasContactsAddAccess: boolean;
  @Input() hasContactsEditAccess: boolean;
  @Input() hasContactsDeleteAccess: boolean;

  displayedColumns = [
    'contactType',
    'name',
    'relation',
    'phone',
    'maritalStatus',
    'email',
    'address',
    'notes',
  ];
  data: DataTablesModel = {} as DataTablesModel;

  isLoadingResults = true;
  apiError = false;

  constructor(
    public dialog: MatDialog,
    public contactApiService: ContactHttpService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit() {
    if (this.hasContactsDeleteAccess || this.hasContactsEditAccess) {
      this.displayedColumns.push('actions');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.patient) {
      if (this.patient) {
        this.data.per_page = 10;
        this.data.page = 0;

        this.loadContacts();
      }
    }
  }

  addContact() {
    const dialogRef = this.dialog.open(AddContactDialogComponent, {
      data: { action: 'ADD', contact: null, patientId: this.patient.id },
      autoFocus: false,
      disableClose: true,
      minWidth: '40vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadContacts();
      }
    });
  }
  public editContact(contact: IContact) {
    const dialogRef = this.dialog.open(AddContactDialogComponent, {
      data: { action: 'EDIT', contact },
      disableClose: true,
      autoFocus: false,
      minWidth: '40vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadContacts();
      }
    });
  }

  deleteContact(contact: IContact) {
    const dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to remove this contact information?',
      },
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        contact.active = false;
        this.contactApiService.updateContact(contact).subscribe(() => {
          this.loadContacts();
          this.toastMessageService.displaySuccessMessage(
            'Contact information has been updated.'
          );
        });
      }
    });
  }

  public loadContacts() {
    this.isLoadingResults = true;
    this.apiError = false;

    this.contactApiService
      .getContacts(this.patient.id, this.data.per_page, this.data.page)
      .subscribe(
        (response) => {
          this.isLoadingResults = false;
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          }
          if (response == null) {
            this.data.items = [];
            this.data.total = 0;
          }
        },
        (error) => {
          this.apiError = true;
          this.isLoadingResults = false;
        }
      );
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadContacts();
  }

  provideOnlineAccess(contact: IContact) {
    contact.onlineAccess = !contact.onlineAccess;
    this.contactApiService.updateContact(contact).subscribe(
      (res) => {
        this.toastMessageService.displaySuccessMessage(
          'Successfully provided the online access'
        );
      },
      (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Something went wrong.'
        );
      }
    );
  }
}
