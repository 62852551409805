import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  LoadOrgConfigAction,
  LoadPhysicianOrgConfigAction,
} from '../../shared/store/actions/org-config.actions';
import {
  getOrgConfigData,
  getOrgConfigDataError,
  getOrgConfigDataLoading,
} from '../../shared/store/selectors/org-config.selectors';
import { State } from '../store';
import { Sandbox } from './base.sandbox';

@Injectable()
export class OrgConfigSandbox extends Sandbox {
  constructor(protected appState$: Store<State>) {
    super(appState$);
  }
  public orgConfig$ = this.appState$.select(getOrgConfigData);
  public orgConfigLoading$ = this.appState$.select(getOrgConfigDataLoading);
  public orgConfigError$ = this.appState$.select(getOrgConfigDataError);

  public triggerOrgConfigLoad() {
    this.appState$.dispatch(LoadOrgConfigAction());
  }

  public triggerPhysicianOrgConfigLoad() {
    this.appState$.dispatch(LoadPhysicianOrgConfigAction());
  }
}
