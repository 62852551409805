<mat-card-title>Member Insurance</mat-card-title>
<mat-divider></mat-divider>

<div style="padding: 10px">
  <!-- Add Insurance Button -->
  <div class="row" *ngIf="hasMemberEditAccess">
    <div class="col-md-12" style="text-align: right">
      <button
        mat-raised-button
        color="warn"
        type="button"
        (click)="addInsurance()"
      >
        + Add Insurance
      </button>
    </div>
  </div>

  <!-- Progress Bar and Error -->
  <div *ngIf="data.total === 0">
    <mat-error> No insurance information found </mat-error>
  </div>

  <div *ngIf="processing || apiError">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="processing"
    ></mat-progress-bar>

    <mat-error *ngIf="apiError">
      An error occurred while retrieving insurance records
    </mat-error>
  </div>

  <div
    class="row"
    *ngIf="data.total > 0"
    style="margin-top: 15px; row-gap: 25px"
  >
    <div class="col-sm-12 col-xl-6" *ngFor="let insurance of data.items">
      <!-- Insurance Cards -->
      <app-insurance-card
        [insurance]="insurance"
        [patient]="patient"
        parentClass="Member"
        (reload)="reload($event)"
      ></app-insurance-card>

      <!-- Action Button -->
      <div class="insuraneCardAction" *ngIf="hasMemberEditAccess">
        <span
          class="material-icons"
          style="
            color: #af8f85;
            cursor: pointer;
            font-size: 17px;
            border-radius: 50%;
            z-index: 2;
            border: 2px solid darkgray;
          "
          [matMenuTriggerFor]="menu"
        >
          more_vert
        </span>
        <mat-menu #menu="matMenu" style="max-height: 500px">
          <button
            mat-menu-item
            type="button"
            (click)="editInsurance(insurance)"
          >
            <mat-icon style="color: #f39121"> edit </mat-icon>
            <span>Edit</span>
          </button>
          <button
            mat-menu-item
            type="button"
            (click)="deleteInsurance(insurance)"
          >
            <mat-icon style="color: red"> delete_forever </mat-icon>
            <span>Delete</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>

  <mat-paginator
    #paginator
    [length]="data.total"
    [pageSize]="data.per_page"
    [showFirstLastButtons]="true"
    *ngIf="data.total > data.per_page"
    (page)="getNext($event)"
  >
  </mat-paginator>
</div>
