import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { DataTablesModel, Patient } from 'src/app/_models';
import {
  PatientInsuranceApiService,
  ToastMessageService,
} from 'src/app/_services';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { AddEditMemberInsuranceComponent } from './add-edit-member-insurance/add-edit-member-insurance.component';

@Component({
  selector: 'app-member-insurance',
  templateUrl: './member-insurance.component.html',
  styleUrls: ['./member-insurance.component.css'],
})
export class MemberInsuranceComponent implements OnInit, OnChanges {
  @Input() patient: Patient;
  @Input() hasMemberEditAccess: boolean;

  data: DataTablesModel = {} as DataTablesModel;
  processing: boolean = false;
  apiError: boolean = false;

  insuranceType: string = '';
  memberId: string = '';
  payer: string = '';
  status: string = '';

  constructor(
    public dialog: MatDialog,
    private toastMessageService: ToastMessageService,
    private patientInsuranceApiService: PatientInsuranceApiService
  ) {}

  ngOnInit(): void {
    this.data.per_page = 10;
    this.data.page = 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.patient) {
      if (this.patient) {
        this.data.per_page = 10;
        this.data.page = 0;
        this.loadInsuranceData();
      }
    }
  }

  loadInsuranceData() {
    this.processing = true;
    this.patientInsuranceApiService
      .getMemberInsurances(
        this.patient.id,
        this.insuranceType,
        this.memberId,
        this.payer,
        this.status,
        this.data.per_page,
        this.data.page
      )
      .subscribe({
        next: (response) => {
          if (response) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }
          this.processing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get member insurance details.'
          );
          this.processing = false;
        },
      });
  }

  addInsurance() {
    const dialogRef = this.dialog.open(AddEditMemberInsuranceComponent, {
      data: { action: 'ADD', patient: this.patient },
      autoFocus: false,
      disableClose: true,
      minWidth: '40vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response && response === 'success') {
        this.loadInsuranceData();
      }
    });
  }

  editInsurance(insurance) {
    const dialogRef = this.dialog.open(AddEditMemberInsuranceComponent, {
      data: {
        action: 'EDIT',
        patient: this.patient,
        memberInsurance: insurance,
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '40vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response && response === 'success') {
        this.loadInsuranceData();
      }
    });
  }

  deleteInsurance(insurance) {
    const dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to remove this insurance?',
      },
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.processing = true;
        this.patientInsuranceApiService.deleteInsurance(insurance.id).subscribe(
          () => {
            this.loadInsuranceData();
            this.toastMessageService.displaySuccessMessage(
              'Successfully deleted the insurance.'
            );
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to delete the insurance record.'
            );
          }
        );
      }
    });
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadInsuranceData();
  }

  reload(event) {
    if (event?.type === 'RELOAD') {
      this.loadInsuranceData();
    }
  }
}
