import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { ICrossCuttingLevelOneSixToSeventeen } from 'src/app/_models/assessments/general/crosscuttinglevelonesixtoseventeen.model';
import { ToastMessageService } from 'src/app/_services/';
import { CrossCuttingLevelOneSixToSeventeenApiService } from 'src/app/_services/assessments/general/crosscuttinglevelonesixtoseventeen.service';

@Component({
  selector: 'app-cross-cutting-level-one-six-to-seventeen',
  templateUrl: './cross-cutting-level-one-six-to-seventeen.component.html',
  styleUrls: ['./cross-cutting-level-one-six-to-seventeen.component.css'],
})
export class CrossCuttingLevelOneSixToSeventeenComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  public inattentive: boolean;
  public somatic: boolean;
  public sleep: boolean;
  public depression: boolean;
  public anger: boolean;
  public mania: boolean;
  public anxiety: boolean;
  public psychosis: boolean;
  public repetetiveThoughtsAndBehaviors: boolean;
  public substanceUse: boolean;
  public suicidal: boolean;
  public memory: boolean;
  public dissociation: boolean;
  public personality: boolean;

  constructor(
    public crossCuttingLevelOneSixToSeventeenApiService: CrossCuttingLevelOneSixToSeventeenApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) {}
  public riskScore: number;
  public crosscuttinglevelonesixtoseventeenForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  crosscuttinglevelonesixtoseventeen: ICrossCuttingLevelOneSixToSeventeen;
  crosscuttinglevelonesixtoseventeenError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(
      this.crosscuttinglevelonesixtoseventeenForm,
      this.errors
    );

    this.loadCrossCuttingLevelOneSixToSeventeen();
  }

  loadCrossCuttingLevelOneSixToSeventeen() {
    this.crosscuttinglevelonesixtoseventeenForm.controls['patientId'].setValue(
      this.patientId
    );

    if (this.assessmentId) {
      this.crossCuttingLevelOneSixToSeventeenApiService
        .getCrossCuttingLevelOneSixToSeventeenDetails(this.assessmentId)
        .subscribe((crosscuttinglevelonesixtoseventeen) => {
          if (crosscuttinglevelonesixtoseventeen.data) {
            this.crosscuttinglevelonesixtoseventeenForm.controls[
              'complained'
            ].setValue(
              crosscuttinglevelonesixtoseventeen.data.complained.answer
            );
            this.crosscuttinglevelonesixtoseventeenForm.controls[
              'worried'
            ].setValue(crosscuttinglevelonesixtoseventeen.data.worried.answer);
            this.crosscuttinglevelonesixtoseventeenForm.controls[
              'problemsSleeping'
            ].setValue(
              crosscuttinglevelonesixtoseventeen.data.problemsSleeping.answer
            );
            this.crosscuttinglevelonesixtoseventeenForm.controls[
              'attentionProblems'
            ].setValue(
              crosscuttinglevelonesixtoseventeen.data.attentionProblems.answer
            );
            this.crosscuttinglevelonesixtoseventeenForm.controls[
              'lessFun'
            ].setValue(crosscuttinglevelonesixtoseventeen.data.lessFun.answer);
            this.crosscuttinglevelonesixtoseventeenForm.controls[
              'sadOrDepressed'
            ].setValue(
              crosscuttinglevelonesixtoseventeen.data.sadOrDepressed.answer
            );
            this.crosscuttinglevelonesixtoseventeenForm.controls[
              'irritatedOrAnnoyed'
            ].setValue(
              crosscuttinglevelonesixtoseventeen.data.irritatedOrAnnoyed.answer
            );
            this.crosscuttinglevelonesixtoseventeenForm.controls[
              'angerOrTemper'
            ].setValue(
              crosscuttinglevelonesixtoseventeen.data.angerOrTemper.answer
            );
            this.crosscuttinglevelonesixtoseventeenForm.controls[
              'moreProjects'
            ].setValue(
              crosscuttinglevelonesixtoseventeen.data.moreProjects.answer
            );
            this.crosscuttinglevelonesixtoseventeenForm.controls[
              'sleptLess'
            ].setValue(
              crosscuttinglevelonesixtoseventeen.data.sleptLess.answer
            );
            this.crosscuttinglevelonesixtoseventeenForm.controls[
              'feltNervous'
            ].setValue(
              crosscuttinglevelonesixtoseventeen.data.feltNervous.answer
            );
            this.crosscuttinglevelonesixtoseventeenForm.controls[
              'stopWorrying'
            ].setValue(
              crosscuttinglevelonesixtoseventeen.data.stopWorrying.answer
            );
            this.crosscuttinglevelonesixtoseventeenForm.controls[
              'couldntDoThings'
            ].setValue(
              crosscuttinglevelonesixtoseventeen.data.couldntDoThings.answer
            );
            this.crosscuttinglevelonesixtoseventeenForm.controls[
              'heardVoices'
            ].setValue(
              crosscuttinglevelonesixtoseventeen.data.heardVoices.answer
            );
            this.crosscuttinglevelonesixtoseventeenForm.controls[
              'illusion'
            ].setValue(crosscuttinglevelonesixtoseventeen.data.illusion.answer);
            this.crosscuttinglevelonesixtoseventeenForm.controls[
              'thoughts'
            ].setValue(crosscuttinglevelonesixtoseventeen.data.thoughts.answer);
            this.crosscuttinglevelonesixtoseventeenForm.controls[
              'needToCheck'
            ].setValue(
              crosscuttinglevelonesixtoseventeen.data.needToCheck.answer
            );
            this.crosscuttinglevelonesixtoseventeenForm.controls[
              'worryALot'
            ].setValue(
              crosscuttinglevelonesixtoseventeen.data.worryALot.answer
            );
            this.crosscuttinglevelonesixtoseventeenForm.controls[
              'needToDoThings'
            ].setValue(
              crosscuttinglevelonesixtoseventeen.data.needToDoThings.answer
            );
            this.crosscuttinglevelonesixtoseventeenForm.controls[
              'alcoholicBeverage'
            ].setValue(
              crosscuttinglevelonesixtoseventeen.data.alcoholicBeverage.answer
            );
            this.crosscuttinglevelonesixtoseventeenForm.controls[
              'smokedCigarette'
            ].setValue(
              crosscuttinglevelonesixtoseventeen.data.smokedCigarette.answer
            );
            this.crosscuttinglevelonesixtoseventeenForm.controls[
              'drugs'
            ].setValue(crosscuttinglevelonesixtoseventeen.data.drugs.answer);
            this.crosscuttinglevelonesixtoseventeenForm.controls[
              'medicationWithoutPrescription'
            ].setValue(
              crosscuttinglevelonesixtoseventeen.data
                .medicationWithoutPrescription.answer
            );
            this.crosscuttinglevelonesixtoseventeenForm.controls[
              'thoughtAboutKilling'
            ].setValue(
              crosscuttinglevelonesixtoseventeen.data.thoughtAboutKilling.answer
            );
            this.crosscuttinglevelonesixtoseventeenForm.controls[
              'triedToKillSelf'
            ].setValue(
              crosscuttinglevelonesixtoseventeen.data.triedToKillSelf.answer
            );
            this.riskScore = crosscuttinglevelonesixtoseventeen.data.riskScore;

            this.somatic =
              crosscuttinglevelonesixtoseventeen.data.props['somatic'] >= 2;
            this.sleep =
              crosscuttinglevelonesixtoseventeen.data.props['sleep'] >= 2;
            this.depression =
              crosscuttinglevelonesixtoseventeen.data.props['depression'] >= 2;
            this.anger =
              crosscuttinglevelonesixtoseventeen.data.props['anger'] >= 2;
            this.mania =
              crosscuttinglevelonesixtoseventeen.data.props['mania'] >= 2;
            this.anxiety =
              crosscuttinglevelonesixtoseventeen.data.props['anxiety'] >= 2;
            this.psychosis =
              crosscuttinglevelonesixtoseventeen.data.props['psychosis'] >= 1;
            this.repetetiveThoughtsAndBehaviors =
              crosscuttinglevelonesixtoseventeen.data.props[
                'repetetiveThoughtsAndBehaviors'
              ] >= 2;
            this.substanceUse =
              crosscuttinglevelonesixtoseventeen.data.props['substanceUse'] >=
              1;
            this.suicidal =
              crosscuttinglevelonesixtoseventeen.data.props['suicidal'] >= 1;
            this.memory =
              crosscuttinglevelonesixtoseventeen.data.props['memory'] >= 2;
            this.dissociation =
              crosscuttinglevelonesixtoseventeen.data.props['dissociation'] >=
              2;
            this.personality =
              crosscuttinglevelonesixtoseventeen.data.props['personality'] >= 2;
            this.inattentive =
              crosscuttinglevelonesixtoseventeen.data.props['inattention'] >= 1;
          }
        });
    }
  }
  onCrossCuttingLevelOneSixToSeventeenFormSubmit() {
    if (this.crosscuttinglevelonesixtoseventeenForm.invalid) {
      this.crosscuttinglevelonesixtoseventeenForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.crosscuttinglevelonesixtoseventeen = Object.assign(
      {},
      this.crosscuttinglevelonesixtoseventeen
    );
    this.crosscuttinglevelonesixtoseventeen.patientId =
      this.crosscuttinglevelonesixtoseventeenForm.controls['patientId'].value;
    this.crosscuttinglevelonesixtoseventeen.sessionId =
      this.crosscuttinglevelonesixtoseventeenForm.controls['sessionId'].value;
    this.crosscuttinglevelonesixtoseventeen.assessmentRequestId =
      this.crosscuttinglevelonesixtoseventeenForm.controls[
        'assessmentRequestId'
      ].value;

    this.crosscuttinglevelonesixtoseventeen.complained = {
      answer:
        this.crosscuttinglevelonesixtoseventeenForm.controls['complained']
          .value,
    };
    this.crosscuttinglevelonesixtoseventeen.worried = {
      answer:
        this.crosscuttinglevelonesixtoseventeenForm.controls['worried'].value,
    };
    this.crosscuttinglevelonesixtoseventeen.problemsSleeping = {
      answer:
        this.crosscuttinglevelonesixtoseventeenForm.controls['problemsSleeping']
          .value,
    };
    this.crosscuttinglevelonesixtoseventeen.attentionProblems = {
      answer:
        this.crosscuttinglevelonesixtoseventeenForm.controls[
          'attentionProblems'
        ].value,
    };
    this.crosscuttinglevelonesixtoseventeen.lessFun = {
      answer:
        this.crosscuttinglevelonesixtoseventeenForm.controls['lessFun'].value,
    };
    this.crosscuttinglevelonesixtoseventeen.sadOrDepressed = {
      answer:
        this.crosscuttinglevelonesixtoseventeenForm.controls['sadOrDepressed']
          .value,
    };
    this.crosscuttinglevelonesixtoseventeen.irritatedOrAnnoyed = {
      answer:
        this.crosscuttinglevelonesixtoseventeenForm.controls[
          'irritatedOrAnnoyed'
        ].value,
    };
    this.crosscuttinglevelonesixtoseventeen.angerOrTemper = {
      answer:
        this.crosscuttinglevelonesixtoseventeenForm.controls['angerOrTemper']
          .value,
    };
    this.crosscuttinglevelonesixtoseventeen.moreProjects = {
      answer:
        this.crosscuttinglevelonesixtoseventeenForm.controls['moreProjects']
          .value,
    };
    this.crosscuttinglevelonesixtoseventeen.sleptLess = {
      answer:
        this.crosscuttinglevelonesixtoseventeenForm.controls['sleptLess'].value,
    };

    this.crosscuttinglevelonesixtoseventeen.feltNervous = {
      answer:
        this.crosscuttinglevelonesixtoseventeenForm.controls['feltNervous']
          .value,
    };
    this.crosscuttinglevelonesixtoseventeen.stopWorrying = {
      answer:
        this.crosscuttinglevelonesixtoseventeenForm.controls['stopWorrying']
          .value,
    };
    this.crosscuttinglevelonesixtoseventeen.couldntDoThings = {
      answer:
        this.crosscuttinglevelonesixtoseventeenForm.controls['couldntDoThings']
          .value,
    };
    this.crosscuttinglevelonesixtoseventeen.heardVoices = {
      answer:
        this.crosscuttinglevelonesixtoseventeenForm.controls['heardVoices']
          .value,
    };
    this.crosscuttinglevelonesixtoseventeen.illusion = {
      answer:
        this.crosscuttinglevelonesixtoseventeenForm.controls['illusion'].value,
    };
    this.crosscuttinglevelonesixtoseventeen.thoughts = {
      answer:
        this.crosscuttinglevelonesixtoseventeenForm.controls['thoughts'].value,
    };
    this.crosscuttinglevelonesixtoseventeen.needToCheck = {
      answer:
        this.crosscuttinglevelonesixtoseventeenForm.controls['needToCheck']
          .value,
    };
    this.crosscuttinglevelonesixtoseventeen.worryALot = {
      answer:
        this.crosscuttinglevelonesixtoseventeenForm.controls['worryALot'].value,
    };
    this.crosscuttinglevelonesixtoseventeen.needToDoThings = {
      answer:
        this.crosscuttinglevelonesixtoseventeenForm.controls['needToDoThings']
          .value,
    };
    this.crosscuttinglevelonesixtoseventeen.alcoholicBeverage = {
      answer:
        this.crosscuttinglevelonesixtoseventeenForm.controls[
          'alcoholicBeverage'
        ].value,
    };
    this.crosscuttinglevelonesixtoseventeen.smokedCigarette = {
      answer:
        this.crosscuttinglevelonesixtoseventeenForm.controls['smokedCigarette']
          .value,
    };
    this.crosscuttinglevelonesixtoseventeen.drugs = {
      answer:
        this.crosscuttinglevelonesixtoseventeenForm.controls['drugs'].value,
    };
    this.crosscuttinglevelonesixtoseventeen.medicationWithoutPrescription = {
      answer:
        this.crosscuttinglevelonesixtoseventeenForm.controls[
          'medicationWithoutPrescription'
        ].value,
    };
    this.crosscuttinglevelonesixtoseventeen.thoughtAboutKilling = {
      answer:
        this.crosscuttinglevelonesixtoseventeenForm.controls[
          'thoughtAboutKilling'
        ].value,
    };
    this.crosscuttinglevelonesixtoseventeen.triedToKillSelf = {
      answer:
        this.crosscuttinglevelonesixtoseventeenForm.controls['triedToKillSelf']
          .value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.crosscuttinglevelonesixtoseventeen.accessCode = this.accessCode;

      this.crossCuttingLevelOneSixToSeventeenApiService
        .addGuestCrossCuttingLevelOneSixToSeventeen(
          this.crosscuttinglevelonesixtoseventeen
        )
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted DSM 5 Level 1 Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting DSM 5 Level 1 Assessment'
            );
          }
        );
    } else {
      this.crossCuttingLevelOneSixToSeventeenApiService
        .addCrossCuttingLevelOneSixToSeventeen(
          this.crosscuttinglevelonesixtoseventeen
        )
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted DSM 5 Level 1 Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting DSM 5 Level 1 Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    complained: [
      {
        type: 'required',
        message:
          '1. Complained of stomachaches, headaches, or other aches and pains? is required',
      },
    ],
    worried: [
      {
        type: 'required',
        message:
          '2. Said he/she was worried about his/herhealth or about getting sick? is required',
      },
    ],
    problemsSleeping: [
      {
        type: 'required',
        message:
          '3. Had problems sleeping—that is, trouble falling asleep, staying asleep,or waking up too early? is required',
      },
    ],
    attentionProblems: [
      {
        type: 'required',
        message:
          '4. Had problems paying attention when he/she was in class or doing his/her homework or reading a book or playing a game? is required',
      },
    ],
    lessFun: [
      {
        type: 'required',
        message:
          '5. Had less fun doing things than he/she used to? is required',
      },
    ],
    sadOrDepressed: [
      {
        type: 'required',
        message: '6. Seemed sad or depressed for several hours? is required',
      },
    ],
    irritatedOrAnnoyed: [
      {
        type: 'required',
        message: '7. Seemed angry or lost his/her temper? is required',
      },
    ],
    angerOrTemper: [
      {
        type: 'required',
        message: '8. Felt angry or lost your temper? is required',
      },
    ],
    moreProjects: [
      {
        type: 'required',
        message:
          '9. Started lots more projects than usual or did more risky things than usual? is required',
      },
    ],
    sleptLess: [
      {
        type: 'required',
        message:
          '10. Slept less than usual for him/her, but still had lots of energy? is required',
      },
    ],
    feltNervous: [
      {
        type: 'required',
        message:
          '11. Said he/she felt nervous, anxious, or scared? is required',
      },
    ],
    stopWorrying: [
      {
        type: 'required',
        message: '12. Not been able to stop worrying? is required',
      },
    ],
    couldntDoThings: [
      {
        type: 'required',
        message:
          "13. Said he/she couldn'tdo things he/ she wanted to or should have done, because they made him / her feel nervous ? is required",
      },
    ],
    heardVoices: [
      {
        type: 'required',
        message:
          '14. Said that he/she heard voices—when there was no one there—speaking about him/her or telling him/her what to do or saying bad things to him/her? is required',
      },
    ],
    illusion: [
      {
        type: 'required',
        message:
          '15. Said that he/she had a vision when he/she was completely awake—that is, saw something or someone that no one else could see? is required',
      },
    ],
    thoughts: [
      {
        type: 'required',
        message:
          '16. Said that he/she had thoughts that kept coming into his/her mind that he/she would do something bad or that something bad would happen to him/her or to someone else? is required',
      },
    ],
    needToCheck: [
      {
        type: 'required',
        message:
          '17. Said he/she felt the need to check on certain things over and over again, like whether a door was locked or whether the stove was turned off? is required',
      },
    ],
    worryALot: [
      {
        type: 'required',
        message:
          '18. Seemed to worry alot about things he/she touched being dirty or having germs or being poisoned? is required',
      },
    ],
    needToDoThings: [
      {
        type: 'required',
        message:
          '19. Said that he/she had to do things in a certain way, like counting or saying special things out loud, in order to keep something bad from happening? is required',
      },
    ],
    alcoholicBeverage: [
      {
        type: 'required',
        message:
          '20. Had an alcoholic beverage (beer, wine, liquor, etc.)? is required',
      },
    ],
    smokedCigarette: [
      {
        type: 'required',
        message:
          '21. Smoked a cigarette, a cigar, or pipe, or used snuff or chewing tobacco? is required',
      },
    ],
    drugs: [
      {
        type: 'required',
        message:
          '22. Used drugs like marijuana, cocaine, or crack, club drugs (Like Ecstasy), hallucinogens (like LSD), heroin, inhalants or insolvents (like glue), or methamphetamine (like speed)? is required',
      },
    ],
    medicationWithoutPrescription: [
      {
        type: 'required',
        message:
          "23. Used any medicine without a doctor's prescription to get high or change the way you feel(e.g., painkillers[like Vicodin], stimulants[like Ritalin or Adderall], sedatives or tranquilizers [like sleeping pills or Valium], or Steriods)?is required",
      },
    ],
    thoughtAboutKilling: [
      {
        type: 'required',
        message:
          '24. In the past TWO (2) WEEKS,has he/she talked about wanting to kill himself/herself or about wanting to commit suicide? is required',
      },
    ],
    triedToKillSelf: [
      {
        type: 'required',
        message:
          '25. Has he/she EVER tried to kill himself/herself? is required',
      },
    ],
  };

  private buildForm() {
    this.crosscuttinglevelonesixtoseventeenForm = this.formBuilder.group({
      id: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      organizationId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      complained: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      worried: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      problemsSleeping: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      attentionProblems: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      lessFun: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      sadOrDepressed: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      irritatedOrAnnoyed: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      angerOrTemper: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      moreProjects: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      sleptLess: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feltNervous: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      stopWorrying: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      couldntDoThings: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      heardVoices: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      illusion: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      thoughts: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      needToCheck: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      worryALot: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      needToDoThings: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      alcoholicBeverage: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      smokedCigarette: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      drugs: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      medicationWithoutPrescription: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      thoughtAboutKilling: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      triedToKillSelf: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.crosscuttinglevelonesixtoseventeenForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    // for (let el in this.crosscuttinglevelonesixtoseventeenForm.controls) {
    //   if (this.crosscuttinglevelonesixtoseventeenForm.controls[el].errors) {
    //     console.log(el)
    //   }
    // }

    if (!this.crosscuttinglevelonesixtoseventeenForm) {
      return;
    }
    const form = this.crosscuttinglevelonesixtoseventeenForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
