import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { ISrs } from 'src/app/_models/assessments/therapeutic-alliance/srs.model';
import {
  ToastMessageService
} from 'src/app/_services/';
import {
  SrsApiService
} from 'src/app/_services/assessments/therapeutic-alliance/srs.service';

@Component({
  selector: 'app-srs-assessment-dialog',
  templateUrl: './srs-assessment-dialog.component.html',
  styleUrls: ['./srs-assessment-dialog.component.css']
})
export class SrsAssessmentDialogComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public srsAssessmentApiService: SrsApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public srsAssessmentForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  srsAssessment: ISrs;
  srsAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;


  autoTicks = false;
  disabled = false;
  invert = false;
  max = 10;
  min = 0;
  showTicks = true;
  step = 0.1;
  thumbLabel = true;
  value = 5;
  vertical = false;
  tickInterval = 1;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.srsAssessmentForm, this.errors);

    this.loadSrs();
  }

  loadSrs() {
    this.srsAssessmentForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.srsAssessmentApiService
        .getSrsDetails(this.assessmentId)
        .subscribe((srsAssessment) => {
          if (srsAssessment.data) {
            this.srsAssessmentForm.controls['relationshipRating'].setValue(srsAssessment.data.relationshipRating);
            this.srsAssessmentForm.controls['goalsAndTopicsRating'].setValue(srsAssessment.data.goalsAndTopicsRating);
            this.srsAssessmentForm.controls['approachRating'].setValue(srsAssessment.data.approachRating);
            this.srsAssessmentForm.controls['overallRating'].setValue(srsAssessment.data.overallRating);

            this.riskScore = srsAssessment.data.riskScore;
          }
        });
    }
  }

  onSrsFormSubmit() {
    if (this.srsAssessmentForm.invalid) {
      this.srsAssessmentForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.srsAssessment = Object.assign({}, this.srsAssessment);
    this.srsAssessment.patientId =
      this.srsAssessmentForm.controls['patientId'].value;
    this.srsAssessment.sessionId =
      this.srsAssessmentForm.controls['sessionId'].value;
    this.srsAssessment.assessmentRequestId =
      this.srsAssessmentForm.controls['assessmentRequestId'].value;

    this.srsAssessment.relationshipRating = this.srsAssessmentForm.controls['relationshipRating'].value;
    this.srsAssessment.goalsAndTopicsRating = this.srsAssessmentForm.controls['goalsAndTopicsRating'].value;
    this.srsAssessment.approachRating = this.srsAssessmentForm.controls['approachRating'].value;
    this.srsAssessment.overallRating = this.srsAssessmentForm.controls['overallRating'].value;

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.srsAssessment.accessCode = this.accessCode;

      this.srsAssessmentApiService
        .addGuestSrs(this.srsAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Session Rating Scale (SRS)'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Session Rating Scale (SRS)'
            );
          }
        );
    } else {
      this.srsAssessmentApiService
        .addSrs(this.srsAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Session Rating Scale (SRS)'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Session Rating Scale (SRS)'
            );
          }
        );
    }
  }

  public validation_messages = {
    'relationshipRating': [
      { type: 'required', message: 'Relationship rating is required' },
    ],
    'goalsAndTopicsRating': [
      { type: 'required', message: 'Goals and Topics rating is required' },
    ],
    'approachRating': [
      { type: 'required', message: 'Approach or Method rating is required' },
    ],
    'overallRating': [
      { type: 'required', message: 'Overall rating is required' },
    ],
  };

  private buildForm() {
    this.srsAssessmentForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      relationshipRating: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      goalsAndTopicsRating: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      approachRating: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      overallRating: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),

    });
    this.srsAssessmentForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.srsAssessmentForm) {
      return;
    }
    const form = this.srsAssessmentForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }

  getSliderTickInterval(): number | 'auto' {
    if (this.showTicks) {
      return this.autoTicks ? 'auto' : this.tickInterval;
    }

    return 0;
  }
}
