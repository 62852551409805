import { Component, ErrorHandler, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ITherapySession, Patient } from 'src/app/_models';
import { ToastMessageService } from 'src/app/_services';
import { AsiApiService } from 'src/app/_services/assessments/asi/asi.service';
import { CoreService } from 'src/app/_services/core.service';

@Component({
  selector: 'app-asi-home',
  templateUrl: './asi-home.component.html',
  styleUrls: ['./asi-home.component.css']
})
export class AsiHomeComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  @Output() updatedTherapySession = new EventEmitter<any>();

  loggedInUserId: string;

  constructor(
    private coreService: CoreService,
    private asiApiService: AsiApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService

  ) {
  }

  ngOnInit(): void {
    this.loggedInUserId = this.coreService.getLoggedInCareProviderId();
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.loadCowsAssessment();
  }

  isTaskCompleted(taskName: string) {
    return (
      // this.therapySession.completedTasks &&
      // this.therapySession.completedTasks.includes(taskName)
      false
    );
  }

  processing: boolean = false;
  disableFormElements: boolean = false;

  loadCowsAssessment() {
    if (this.assessmentId) {

    }
  }
}
