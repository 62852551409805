<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Empathy Quotient Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="empathyQuotientForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >1. I can easily tell if someone else wants to enter a
            conversation.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q1">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >2. I prefer animals to humans.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q2">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >3. I try to keep up with the current trends and fashions.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q3">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >4. I find it difficult to explain to others things that I
            understand easily, when they don't understand it the first
            time.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q4">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after">5. I dream most nights.</label>
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q5">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >6. I really enjoy caring for other people.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q6">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >7. I try to solve my own problems rather than discussing them with
            others.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q7">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >8. I find it hard to know what to do in a social situation.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q8">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >9. I am at my best first thing in the morning.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q9">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >10. People often tell me that I went too far in driving my point
            home in a discussion.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q10">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >11. It doesn't bother me too much if I am late meeting a
            friend.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q11">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >12. Friendships and relationships are just too difficult, so I tend
            not to bother with them.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q12">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >13. I would never break a law, no matter how minor.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q13">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >14. I often find it difficult to judge if something is rude or
            polite.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q14">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >15. In a conversation, I tend to focus on my own thoughts rather
            than on what my listener might be thinking.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q15">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >16. I prefer practical jokes to verbal humor.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q16">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >17. I live life for today rather than the future.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q17">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >18. When I was a child, I enjoyed cutting up worms to see what
            would happen.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q18">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >19. I can pick up quickly if someone says one thing but means
            another.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q19">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >20. I tend to have very strong opinions about morality.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q20">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >21. It is hard for me to see why some things upset people so
            much.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q21">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >22. I find it easy to put myself in somebody else's shoes.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q22">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >23. I think that good manners are the most important thing a parent
            can teach their child.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q23">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >24. I like to do things on the spur of the moment.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q24">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >25. I am good at predicting how someone will feel.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q25">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >26. I am quick to spot when someone in a group is feeling awkward
            or uncomfortable.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q26">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >27. If I say something that someone else is offended by, I think
            that that's their problem, not mine.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q27">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >28. If anyone asked me if I liked their haircut, I would reply
            truthfully, even if I didn't like it.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q28">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >29. I can't always see why someone should have felt offended by a
            remark.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q29">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >30. People often tell me that I am very unpredictable.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q30">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >31. I enjoy being the center of attention at any social
            gathering.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q31">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >32. Seeing people cry doesn't really upset me.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q32">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >33. I enjoy having discussions about politics.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q33">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >34. I am very blunt, which some people take to be rudeness, even
            though this is unintentional.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q34">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >35. I don't find social situations confusing.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q35">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >36. Other people tell me I am good at understanding how they are
            feeling and what they are thinking.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q36">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >37. When I talk to people, I tend to talk about their experiences
            rather than my own.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q37">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >38. It upsets me to see an animal in pain.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q38">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >39. I am able to make decisions without being influenced by
            people's feelings.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q39">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >40. I can't relax until I have done everything I had planned to do
            that day.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q40">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >41. I can easily tell if someone else is interested or bored with
            what I am saying.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q41">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >42. I get upset if I see people suffering on news programs.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q42">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >43. Friends usually talk to me about their problems as they say
            that I am very understanding.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q43">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >44. I can sense if I am intruding, even if the other person doesn't
            tell me.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q44">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >45. I often start new hobbies, but quickly become bored with them
            and move on to something else.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q45">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >46. People sometimes tell me that I have gone too far with
            teasing.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q46">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >47. I would be too nervous to go on a big rollercoaster.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q47">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >48. Other people often say that I am insensitive, though I don't
            always see why.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q48">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >49. If I see a stranger in a group, I think that it is up to them
            to make an effort to join in.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q49">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >50. I usually stay emotionally detached when watching a
            film.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q50">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >51. I like to be very organized in day-to-day life and often makes
            lists of the chores I have to do.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q51">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >52. I can tune into how someone else feels rapidly and
            intuitively.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q52">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >53. I don't like to take risks.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q53">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >54. I can easily work out what another person might want to talk
            about.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q54">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >55. I can tell if someone is masking their true emotion.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q55">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >56. Before making a decision, I always weigh up the pros and
            cons.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q56">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >57. I don't consciously work out the rules of social
            situations.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q57">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >58. I am good at predicting what someone will do.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q58">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >59. I tend to get emotionally involved with a friend's
            problems.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q59">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >60. I can usually appreciate the other person's viewpoint, even if
            I don't agree with it.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q60">
              <mat-button-toggle value="Strongly Agree"
                >Strongly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Strongly Disagree"
                >Strongly Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          empathyQuotientForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!empathyQuotientForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
