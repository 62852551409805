<!-- BreadCrumb -->
<div>
  <p style="font-size: 16px; font-weight: 450">
    <a routerLink="/" routerLinkActive="active">
      <em
        class="material-icons text_align-center"
        style="font-size: 18px; margin-top: -3px"
        >home</em
      >
    </a>
    &nbsp; / &nbsp;
    <span
      [routerLink]="
        parent === 'claims'
          ? '/claims'
          : ['/main/member/', claimDetails?.patientId, 'CLAIMS']
      "
      style="color: #2196f3; cursor: pointer; font-weight: 500"
    >
      Claims
    </span>
    &nbsp; / &nbsp; {{ claimDetails?.claimCode }}
    <span *ngIf="viewHistorySelected">
      &nbsp; / &nbsp;
      {{
        selectedViewHistory?.createdDate
          | date : 'M/d/y, h:mm a' : currentTimezone
      }}
    </span>
  </p>
</div>

<!-- Progress bar -->
<div *ngIf="isLoadingDetails || isLoadingResults">
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoadingDetails || isLoadingResults"
  ></mat-progress-bar>
</div>

<!-- Display the list of history -->
<div
  class="row"
  style="margin: 15px"
  *ngIf="!viewHistorySelected && claimDetails?.claimCode"
>
  <!-- Claim Overview -->
  <div class="col-md-12">
    <label class="labelHeader"> Claim Overview </label>
    <app-claims-overview-header
      [therapySession]="claimDetails?.therapySession"
    ></app-claims-overview-header>
  </div>

  <!-- Additional Claim Action Buttons-->
  <div class="col-md-12" style="margin: 20px 0px">
    <button mat-raised-button (click)="viewCurrentClaim()">
      <mat-icon style="color: #2196f3">view_timeline</mat-icon> View Current
      Claim
    </button>
  </div>

  <!-- Claim History Details -->
  <div class="col-md-12">
    <label class="labelHeader"> Claim History </label>

    <mat-card appearance="raised" class="col-md-12" style="border-radius: 15px">
      <!-- Progress Bar -->
      <mat-progress-bar
        mode="indeterminate"
        *ngIf="isLoadingResults"
      ></mat-progress-bar>

      <!-- No Claim History -->
      <mat-error
        *ngIf="
          !isLoadingResults && (!claimsHistory || claimsHistory.length === 0)
        "
      >
        No history found
      </mat-error>

      <!--If there is/are claim transaction -->
      <div
        class="mat-elevation-z8"
        *ngIf="claimsHistory && claimsHistory.length > 0"
      >
        <div class="table-responsive">
          <table
            mat-table
            [dataSource]="claimsHistory"
            class="mat-elevation-z8"
          >
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef>Date</th>
              <td mat-cell *matCellDef="let row">
                {{ row.createdDate | date : 'M/d/y, h:mm a' : currentTimezone }}
                {{ currentTimezone }}
              </td>
            </ng-container>

            <ng-container matColumnDef="message">
              <th mat-header-cell *matHeaderCellDef>Message</th>
              <td mat-cell *matCellDef="let row">
                <b>{{ row?.message }}</b>
                <br />
                {{ row?.insuranceClaims?.mindwiseClaimsScrubbingErrors }}
                <br />
                {{ row?.insuranceClaims?.externalClaimsErrors }}
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef style="text-align: right">
                Action
              </th>
              <td mat-cell *matCellDef="let row" style="text-align: right">
                <button
                  type="button"
                  mat-raised-button
                  color="primary"
                  (click)="viewHistoryDetails(row)"
                >
                  View
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>
    </mat-card>
  </div>
</div>

<!-- Display Individual History -->
<div class="row history-watermark" *ngIf="viewHistorySelected">
  <div class="col-md-12">
    <button
      mat-raised-button
      type="button"
      style="color: #2196f3"
      (click)="cancelViewClaimHistory()"
    >
      <mat-icon> arrow_back </mat-icon> Back
    </button>
  </div>

  <!-- // Re-use the same billing component - Display only mode -->
  <app-session-billing
    [externalClaimsErrors]="
      selectedViewHistory?.insuranceClaims?.externalClaimsErrors
    "
    [billingStatus]="selectedViewHistory?.insuranceClaims.billingStatus"
    [therapySession]="selectedViewHistory?.insuranceClaims.therapySession"
    parentClass="Claims"
  ></app-session-billing>
</div>
