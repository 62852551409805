<div class="row" style="padding-right: 20px; margin-bottom: 20px">
  <div class="col-md-12">
    <form [formGroup]="searchForm" novalidate (ngSubmit)="onFormSubmit()">
      <div class="row">
        <div class="col-md-3 mr-auto"></div>
        <div class="col-md-2 mr-auto">
          <mat-form-field>
            <mat-label>From </mat-label>
            <input
              formControlName="fromDate"
              matInput
              [matDatepicker]="fromDatePicker"
              placeholder="From"
              [max]="todaysDate"
              autoSlashDate
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="fromDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #fromDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-2 mr-auto">
          <mat-form-field>
            <mat-label>To </mat-label>
            <input
              formControlName="toDate"
              matInput
              [matDatepicker]="toDatePicker"
              placeholder="To"
              [min]="searchForm.controls['fromDate'].value"
              [max]="todaysDate"
              autoSlashDate
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="toDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #toDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-2 mr-auto">
          <mat-form-field>
            <mat-label>Shift</mat-label>
            <mat-select formControlName="shiftNumber">
              <mat-option value="0">All Shifts</mat-option>
              <mat-option value="1"
                >{{
                  orgConfig.firstShiftName ? orgConfig.firstShiftName : '1st'
                }}
              </mat-option>
              <mat-option value="2" *ngIf="totalShifts > 1">
                {{
                  orgConfig.secondShiftName ? orgConfig.secondShiftName : '2nd'
                }}</mat-option
              >
              <mat-option value="3" *ngIf="totalShifts > 2">
                {{
                  orgConfig.thirdShiftName ? orgConfig.thirdShiftName : '3rd'
                }}</mat-option
              >
              <mat-option
                value="3"
                *ngIf="
                  totalShifts < 3 && orgConfig.archivedData?.thirdShiftName
                "
              >
                {{ orgConfig.archivedData?.thirdShiftName }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-1 mr-auto" style="align-self: center">
          <button mat-raised-button (click)="clearForm()">CLEAR</button>
        </div>
        <div class="col-md-2 mr-auto"></div>
      </div>
    </form>
  </div>

  <div class="col-md-12">
    <mat-error *ngIf="displayNoRecordsMsg" style="text-align: center"
      >Unable to find any records for the above selected dates/shift
    </mat-error>
  </div>

  <mat-progress-bar mode="indeterminate" *ngIf="processing"></mat-progress-bar>

  <div style="min-height: 900px; width: 100%">
    <ng2-pdfjs-viewer [download]="false" #pdfViewerOnDemand></ng2-pdfjs-viewer>
  </div>
</div>
