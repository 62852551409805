<mat-card
  appearance="raised"
  class="mat-elevation-z8"
  style="margin-top: 10px; margin-bottom: 20px"
>
  <mat-card-title> Member Medical History </mat-card-title>
  <mat-card-subtitle style="font-style: italic; font-weight: 450">
    Member has a history of the following:
    <span style="color: #039be5; font-weight: 500">(highlighted in blue)</span>
  </mat-card-subtitle>

  <mat-error *ngIf="!hasMemberHistoryViewAccess">
    Sorry, You don't have access to view Member Medical History
  </mat-error>

  <div *ngIf="hasMemberHistoryViewAccess">
    <div class="row" *ngIf="hasMemberHistoryEditAccess">
      <mat-chip-listbox>
        <div
          class="col-sm-6 col-xl-4"
          *ngFor="let condition of memberConditionsList"
        >
          <mat-chip-option
            [class]="
              condition.selected ? 'mat-chip-active' : 'mat-chip-inactive'
            "
            (click)="
              condition.selected = !condition.selected;
              updateHealthCondition(condition)
            "
          >
            {{ condition.name }}
          </mat-chip-option>
        </div>
      </mat-chip-listbox>
    </div>

    <div class="row" *ngIf="!hasMemberHistoryEditAccess">
      <div
        class="col-sm-6 col-xl-4"
        *ngFor="let condition of memberConditionsList"
      >
        <mat-chip-option
          [class]="condition.selected ? 'mat-chip-active' : 'mat-chip-inactive'"
        >
          {{ condition.name }}
        </mat-chip-option>
      </div>
    </div>
  </div>
</mat-card>
