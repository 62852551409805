<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Claims</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <!-- Search Box -->
  <div
    class="row"
    style="
      margin-left: 5px;
      margin-right: 5px;
      row-gap: 15px;
      margin-bottom: 15px;
    "
  >
    <div class="col-sm-12">
      <div class="row" style="row-gap: 15px; column-gap: 15px; flex-wrap: wrap">
        <div
          class="col"
          style="
            display: flex;
            align-items: center;
            padding: 5px;
            border-radius: 10px;
            border: 1px solid rgb(0, 0, 0, 0.2);
            background-color: #ffffff;
            padding: 5px;
            min-width: 140px;
          "
        >
          <span
            class="material-icons"
            style="text-align: left; font-size: 20px; opacity: 0.5"
          >
            search
          </span>
          <input
            type="search"
            placeholder="Member Name"
            [(ngModel)]="searchMemberName"
            (ngModelChange)="searchChanged()"
            style="
              padding-left: 15px;
              width: 100%;
              background: transparent;
              border: 0;
            "
          />
        </div>

        <div
          class="col"
          style="
            display: flex;
            align-items: center;
            padding: 5px;
            border-radius: 10px;
            border: 1px solid rgb(0, 0, 0, 0.2);
            background-color: #ffffff;
            padding: 5px;
            min-width: 140px;
          "
        >
          <span
            class="material-icons"
            style="text-align: left; font-size: 20px; opacity: 0.5"
          >
            pin
          </span>
          <input
            type="search"
            placeholder="Claim Id"
            [(ngModel)]="searchClaimCode"
            (ngModelChange)="searchChanged()"
            style="
              padding-left: 15px;
              width: 100%;
              background: transparent;
              border: 0;
            "
          />
        </div>

        <div
          class="col"
          style="
            display: flex;
            align-items: center;
            padding: 5px;
            border-radius: 10px;
            border: 1px solid rgb(0, 0, 0, 0.2);
            background-color: #ffffff;
            padding: 5px;
            min-width: 140px;
          "
        >
          <span
            class="material-icons"
            style="text-align: left; font-size: 20px; opacity: 0.5"
          >
            search
          </span>
          <input
            type="search"
            placeholder="Provider Name"
            [(ngModel)]="searchProviderName"
            (ngModelChange)="searchChanged()"
            style="
              padding-left: 15px;
              width: 100%;
              background: transparent;
              border: 0;
            "
          />
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="processing || apiError">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="processing"
    ></mat-progress-bar>

    <mat-error *ngIf="apiError">
      An error occurred while retrieving claims records
    </mat-error>
  </div>

  <div *ngIf="claimsListData.total === 0 && !processing">
    <mat-error> No claims found </mat-error>
  </div>

  <div style="padding-bottom: 50px" *ngIf="claimsListData.total > 0">
    <div class="table-responsive mat-elevation-z8">
      <table mat-table [dataSource]="claimsListData.items">
        <ng-container matColumnDef="member">
          <th mat-header-cell *matHeaderCellDef>Member</th>
          <td mat-cell *matCellDef="let row">
            <b>
              {{ row?.therapySession?.patientLastName }},
              {{ row?.therapySession?.patientFirstName }}
            </b>
          </td>
        </ng-container>

        <ng-container matColumnDef="payer">
          <th mat-header-cell *matHeaderCellDef>Payer</th>
          <td mat-cell *matCellDef="let row">
            {{
              row?.billingSequence === 'P'
                ? row?.therapySession?.primaryInsuranceDetails?.payerName
                : row?.insuranceDetails?.payerName
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="referenceIds">
          <th mat-header-cell *matHeaderCellDef>Reference Ids</th>
          <td mat-cell *matCellDef="let row">
            <!-- Session Note ref id -->
            <div class="columnsGrid" *ngIf="row?.claimCode">
              <label><b>Claim Id</b></label>
              <label class="labelBold">
                {{ row?.claimCode }}
              </label>
            </div>

            <!-- Session Note ref id -->
            <div class="columnsGrid" *ngIf="row?.sessionCode">
              <label><b>Note Id</b></label>
              <label class="labelBold">
                {{ row?.sessionCode }}
              </label>
            </div>

            <!-- Clearing house ref id -->
            <div class="columnsGrid" *ngIf="row?.clearingHouseRefId">
              <label><b>Clearing House Instance Id</b></label>
              <label class="labelBold">
                {{ row?.clearingHouseRefId }}
              </label>
            </div>

            <!-- Original CMS id -->
            <div class="columnsGrid" *ngIf="row?.originalCMSClaimRefNo">
              <label><b>Orginial CMS Id</b></label>
              <label class="labelBold">
                {{ row?.originalCMSClaimRefNo }}
              </label>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="details">
          <th mat-header-cell *matHeaderCellDef>Service Details</th>
          <td mat-cell *matCellDef="let row" style="width: 290px">
            <b>
              Provider - {{ row?.therapySession?.physicianLastName }},
              {{ row?.therapySession?.physicianFirstName }}
            </b>
            <br />
            <b>
              {{ row?.therapySession.visitReasonName }} -
              {{ row?.therapySession.billingType }}
            </b>
            <br />
            {{ row?.therapySession.sessionType }}
            <br />
            <b>{{
              row?.therapySession?.start | date : 'M/d/y' : currentTimezone
            }}</b>
            <br />
            {{ row?.therapySession?.start | date : 'h:mm a' : currentTimezone }}
            -
            {{ row?.therapySession?.end | date : 'h:mm a' : currentTimezone }}
            {{ row?.therapySession?.start ? currentTimezone : '' }}
            <br />
          </td>
        </ng-container>

        <ng-container matColumnDef="totalCharge">
          <th mat-header-cell *matHeaderCellDef>Total Charge</th>
          <td mat-cell *matCellDef="let row">
            {{ row?.therapySession?.billingTotal | currency : 'USD' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef style="text-align: right">
            Actions
          </th>
          <td mat-cell *matCellDef="let row" style="text-align: right">
            <button
              mat-raised-button
              type="button"
              color="primary"
              (click)="selectClaim(row)"
            >
              Select
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <mat-paginator
      [length]="claimsListData.total"
      [pageSize]="claimsListData.per_page"
      *ngIf="claimsListData.total > claimsListData.per_page"
      (page)="getNext($event)"
      [showFirstLastButtons]="true"
    ></mat-paginator>
  </div>
</div>
