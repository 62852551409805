<mat-card-title>Legal Status</mat-card-title>
<mat-divider></mat-divider>
<div style="margin-top: 40px; padding: 10px">
  <form [formGroup]="asilegalForm" novalidate (ngSubmit)="onAsiLegalFormSubmit()">
    <div class="row">
      <div class="col-sm-12 col-xl-8">
        <mat-card appearance="raised" class="mat-elevation-z8" style="margin-bottom: 20px">
          <div class="col-md-12 pad10">
            <label class="labelText breakWord">L1. Was this admission prompted or suggested by the criminal
              justice system? <span style="color: red">*</span>
            </label>
            <br />
            <mat-button-toggle-group formControlName="l1">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-md-12 pad10">
            <label class="labelText breakWord">L2. Are you on probation or parole?
              <span style="color: red">*</span></label>
            <br />
            <mat-button-toggle-group formControlName="l2">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-md-12">
            <label class="labelHeader breakWord">
              How many times in your life have you been arrested and charged
              with the following?
            </label>
            <br />
            <div class="row">
              <div class="col-8">
                <label class="labelText breakWord">L3. Shoplifting/Vandalism</label>
              </div>
              <div class="col-4">
                <mat-form-field appearance="outline">
                  <input matInput type="number" formControlName="l3" name="l3" required />
                </mat-form-field>
              </div>
              <div class="col-8">
                <label class="labelText breakWord">L4. Parole/Probation Violation</label>
              </div>
              <div class="col-4">
                <mat-form-field appearance="outline">
                  <input matInput type="number" formControlName="l4" name="l4" required />
                </mat-form-field>
              </div>
              <div class="col-8">
                <label class="labelText breakWord">L5. Drug Charges</label>
              </div>
              <div class="col-4">
                <mat-form-field appearance="outline">
                  <input matInput type="number" formControlName="l5" name="l5" required />
                </mat-form-field>
              </div>
              <div class="col-8">
                <label class="labelText breakWord">L6. Forgery</label>
              </div>
              <div class="col-4">
                <mat-form-field appearance="outline">
                  <input matInput type="number" formControlName="l6" name="l6" required />
                </mat-form-field>
              </div>
              <div class="col-8">
                <label class="labelText breakWord">L7. Weapons Offense</label>
              </div>
              <div class="col-4">
                <mat-form-field appearance="outline">
                  <input matInput type="number" formControlName="l7" name="l7" required />
                </mat-form-field>
              </div>
              <div class="col-8">
                <label class="labelText">L8. Burglary/Larceny/BreakWording & Entering</label>
              </div>
              <div class="col-4">
                <mat-form-field appearance="outline">
                  <input matInput type="number" formControlName="l8" name="l8" required />
                </mat-form-field>
              </div>
              <div class="col-8">
                <label class="labelText">L9. Robbery</label>
              </div>
              <div class="col-4">
                <mat-form-field appearance="outline">
                  <input matInput type="number" formControlName="l9" name="l9" required />
                </mat-form-field>
              </div>
              <div class="col-8">
                <label class="labelText">L10. Assault</label>
              </div>
              <div class="col-4">
                <mat-form-field appearance="outline">
                  <input matInput type="number" formControlName="l10" name="l10" required />
                </mat-form-field>
              </div>
              <div class="col-8">
                <label class="labelText">L11. Arson</label>
              </div>
              <div class="col-4">
                <mat-form-field appearance="outline">
                  <input matInput type="number" formControlName="l11" name="l11" required />
                </mat-form-field>
              </div>
              <div class="col-8">
                <label class="labelText">L12. Rape</label>
              </div>
              <div class="col-4">
                <mat-form-field appearance="outline">
                  <input matInput type="number" formControlName="l12" name="l12" required />
                </mat-form-field>
              </div>
              <div class="col-8">
                <label class="labelText">L13. Homicide/Manslaughter</label>
              </div>
              <div class="col-4">
                <mat-form-field appearance="outline">
                  <input matInput type="number" formControlName="l13" name="l13" required />
                </mat-form-field>
              </div>
              <div class="col-8">
                <label class="labelText">L14. Prostitution</label>
              </div>
              <div class="col-4">
                <mat-form-field appearance="outline">
                  <input matInput type="number" formControlName="l14" name="l14" required />
                </mat-form-field>
              </div>
              <div class="col-8">
                <label class="labelText">L15. Contempt of Court</label>
              </div>
              <div class="col-4">
                <mat-form-field appearance="outline">
                  <input matInput type="number" formControlName="l15" name="l15" required />
                </mat-form-field>
              </div>
              <div class="col-8">
                <label class="labelText">L16. Other </label>
              </div>
              <div class="col-4">
                <mat-form-field appearance="outline">
                  <input matInput type="number" formControlName="l16" name="l16" required />
                </mat-form-field>
              </div>
              <div class="col-8">
                <label class="labelText">L17. How many of these charges resulted in convictions?
                </label>
              </div>
              <div class="col-4">
                <mat-form-field appearance="outline">
                  <input matInput type="number" formControlName="l17" name="l17" required />
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <label class="labelHeader">
              How many times in your life have you been charged with the
              following:
            </label>
            <br />
            <div class="row">
              <div class="col-8">
                <label class="labelText">L18. Disorderly conduct, vagrancy,public intoxication
                </label>
              </div>
              <div class="col-4">
                <mat-form-field appearance="outline">
                  <input matInput type="number" formControlName="l18" name="l18" required />
                </mat-form-field>
              </div>
              <div class="col-8">
                <label class="labelText">L19. Driving while intoxicated </label>
              </div>
              <div class="col-4">
                <mat-form-field appearance="outline">
                  <input matInput type="number" formControlName="l19" name="l19" required />
                </mat-form-field>
              </div>
              <div class="col-8">
                <label class="labelText breakWord">L20. Major driving violations (speeding, reckless driving,
                  etc.)
                </label>
              </div>
              <div class="col-4">
                <mat-form-field appearance="outline">
                  <input matInput type="number" formControlName="l20" name="l20" required />
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="row">
              <div class="col-8">
                <label class="labelText breakWord">L21. How many months were you incarcerated in your life?
                </label>
              </div>
              <div class="col-4">
                <mat-form-field appearance="outline">
                  <input matInput type="number" formControlName="l21" name="l21" required />
                </mat-form-field>
              </div>

              <div class="col-8">
                <label class="labelText breakWord">L22. How long was your last incarceration?
                </label>
              </div>
              <div class="col-4">
                <mat-form-field appearance="outline">
                  <input matInput type="number" formControlName="l22" name="l22" required />
                </mat-form-field>
              </div>

              <div class="col-8">
                <label class="labelText breakWord">L23. What was it for?
                </label>
              </div>
              <div class="col-4">
                <mat-form-field appearance="outline">
                  <input matInput type="number" formControlName="l23" name="l23" required />
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-md-12 pad10">
            <label class="labelText breakWord">L24. Are you presently awaiting charges, trial, or
              sentence?</label>
            <div class="col-sm-6">
              <mat-button-toggle-group formControlName="l24">
                <mat-button-toggle value="No">No</mat-button-toggle>
                <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>

          <div class="col-md-12">
            <label class="labelText breakWord">L25. What for? <span style="color: red">*</span></label>
            <mat-form-field appearance="outline">
              <input matInput formControlName="l25" name="l25" required />
              <mat-error *ngFor="let validation of validation_messages.l25">
                <mat-error class="error-message" *ngIf="
                    asilegalForm.get('l25').hasError(validation.type) &&
                    (asilegalForm.get('l25').dirty ||
                      asilegalForm.get('l25').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-12">
            <div class="row">
              <div class="col-8">
                <label class="labelText breakWord">
                  L26. How many days in the past 30 days were you detained or
                  incarcerated?
                </label>
              </div>
              <div class="col-4">
                <mat-form-field appearance="outline">
                  <input matInput type="number" formControlName="l26" name="l26" required />
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <label class="labelText breakWord">L27. How many days in the past 30 have you engaged in illegal
              activities for profit? <span style="color: red">*</span></label>
            <mat-form-field appearance="outline">
              <input matInput formControlName="l27" name="l27" required />
              <mat-error *ngFor="let validation of validation_messages.l27">
                <mat-error class="error-message" *ngIf="
                    asilegalForm.get('l27').hasError(validation.type) &&
                    (asilegalForm.get('l27').dirty ||
                      asilegalForm.get('l27').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-12 pad10">
            <label class="labelText breakWord">L28. How serious do you feel your present legal problems are?
            </label>
            <br />
            <mat-button-toggle-group formControlName="l28">
              <mat-button-toggle value="Not at all">Not at all</mat-button-toggle>
              <mat-button-toggle value="Slightly">Slightly</mat-button-toggle>
              <mat-button-toggle value="Moderately">Moderately</mat-button-toggle>
              <mat-button-toggle value="Considerably">Considerably</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-md-12 pad10">
            <label class="labelText breakWord">L29. How important to you now is counseling or referral for these
              legal problems?</label>
            <br />
            <mat-button-toggle-group formControlName="l29">
              <mat-button-toggle value="Not at all">Not at all</mat-button-toggle>
              <mat-button-toggle value="Slightly">Slightly</mat-button-toggle>
              <mat-button-toggle value="Moderately">Moderately</mat-button-toggle>
              <mat-button-toggle value="Considerably">Considerably</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-md-12">
            <label class="labelText breakWord">L30. How would you rate the patient’s need for legal services or
              counseling? <span style="color: red">*</span></label>&nbsp;
            <mat-form-field appearance="outline">
              <input matInput formControlName="l30" name="l30" required />
              <mat-error *ngFor="let validation of validation_messages.l30">
                <mat-error class="error-message" *ngIf="
                    asilegalForm.get('l30').hasError(validation.type) &&
                    (asilegalForm.get('l30').dirty ||
                      asilegalForm.get('l30').touched)
                  ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12 pad10">
            <label class="labelText breakWord">L31. How would you rate the patient’s need for legal services or
              counseling?</label>
            <br />
            <mat-button-toggle-group formControlName="l31">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div class="col-md-12 pad10">
            <label class="labelText breakWord">L32. How would you rate the patient’s need for legal services or
              counseling?</label>
            <br />
            <mat-button-toggle-group formControlName="l32">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </mat-card>
      </div>
      <!-- Comment Box -->
      <div class="col-sm-12 col-xl-4">
        <div class="row">
          <div class="col-sm-6">
            <mat-card class="custom-score-card mat-elevation-z8" appearance="raised">
              <label class="labelText">Clinician Score <span style="color: red">*</span></label>
              <mat-card-content>
                <mat-form-field appearance="outline" style="width:100px">
                  <input type="text" formControlName="clinicianScore" maxlength="1"
                    style="font-size:48px; font-weight: 900 !important" pattern="[0-9]" matInput required />
                </mat-form-field>
              </mat-card-content>
              <mat-error *ngFor="let validation of validation_messages.clinicianScore">
                <mat-error class="error-message" *ngIf="
                      asilegalForm.get('clinicianScore').hasError(validation.type) &&
                      (asilegalForm.get('clinicianScore').dirty ||
                        asilegalForm.get('clinicianScore').touched)
                    ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-card>
          </div>
          <div class="col-sm-6">
            <mat-card class="custom-score-card mat-elevation-z8" appearance="raised">
              <label class="labelText" style="justify-content: center;">System Score</label>
              <mat-card-content>
                <span class="width:100px; font-size:48px; font-weight: 900 !important">
                  {{systemGeneratedScore}}
                </span>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div class="row" style="margin-top:15px">
          <div class="col-sm-12">

            <mat-card appearance="raised" class="mat-elevation-z8">
              <div class="row">
                <div class="col-md-12">
                  <label class="labelHeader">Comments</label>
                  <mat-form-field appearance="outline">
                    <textarea formControlName="comments" matInput rows="50"></textarea>
                  </mat-form-field>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end">
      <button [class]="
          asilegalForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        " mat-raised-button type="submit" [disabled]="!asilegalForm.valid || processing">
        Submit
      </button>
    </mat-dialog-actions>
  </form>
</div>