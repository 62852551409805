import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  DataTablesModel,
  IMemberDefaultPayment,
  Patient,
} from 'src/app/_models';
import {
  MemberPaymentsApiService,
  ToastMessageService,
} from 'src/app/_services';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';

@Component({
  selector: 'app-member-payments-cards',
  templateUrl: './member-payments-cards.component.html',
  styleUrl: './member-payments-cards.component.css',
})
export class MemberPaymentsCardsComponent implements OnInit, OnChanges {
  @Input() patient: Patient;
  @Input() hasMemberPaymentsAddAccess: boolean;
  @Input() hasMemberPaymentsDeleteAccess: boolean;

  defaultPaymentId: string = '';
  isLoadingResults: boolean = false;
  processing: boolean = false;
  savedPaymentMehods: DataTablesModel = {} as DataTablesModel;
  memberDefaulPayment: IMemberDefaultPayment;

  constructor(
    private dialog: MatDialog,
    private memberPaymentsApiService: MemberPaymentsApiService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.patient) {
      if (this.patient) {
        this.loadSavedCardPayments();
        this.getDefaultPaymentMethod();
      }
    }
  }

  loadSavedCardPayments() {
    this.isLoadingResults = true;
    this.memberPaymentsApiService
      .getMemberSavedPaymentMethods(this.patient.id)
      .subscribe({
        next: (response) => {
          if (response && response.items) {
            this.savedPaymentMehods.items = response.items;
            this.savedPaymentMehods.total = response.total;
          } else {
            this.savedPaymentMehods.items = [];
            this.savedPaymentMehods.total = 0;
          }
          this.isLoadingResults = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load saved cards'
          );
          this.isLoadingResults = false;
        },
      });
  }

  addNewCard() {
    this.processing = true;

    this.memberPaymentsApiService
      .addMemberCardPaymentMethod(this.patient.id)
      .subscribe({
        next: (response) => {
          if (response && response.data) {
            window.open(response.data, '_self');
          }
          this.processing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to generate the card link'
          );
          this.processing = false;
        },
      });
  }

  deleteCard(card) {
    let dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message: `Are you sure you want to remove the card ending in ${card.last4}?`,
      },
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.memberPaymentsApiService
          .deleteMemberSavedPaymentMethod(
            this.patient.id,
            card.customer,
            card.id
          )
          .subscribe({
            next: (response) => {
              this.toastMessageService.displaySuccessMessage(
                'Successfully removed the card ending in ' + card.last4
              );
              this.loadSavedCardPayments();
              this.getDefaultPaymentMethod();
            },
            error: (error) => {
              this.toastMessageService.displayErrorMessage(
                'Error: Failed to remove the card.'
              );
            },
          });
      }
    });
  }

  // Get Default Member Card Payment Id
  getDefaultPaymentMethod() {
    this.memberPaymentsApiService
      .getMemberDefaultPaymentMethod(this.patient.id)
      .subscribe({
        next: (response) => {
          if (response && response.data) {
            this.defaultPaymentId = response.data.paymentMethodId;
          }
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get the default payment card'
          );
        },
      });
  }

  // Mark this card as the Default Payment
  markAsDefault(card) {
    this.memberDefaulPayment = Object.assign({}, this.memberDefaulPayment);

    this.memberDefaulPayment.memberId = this.patient.id;
    this.memberDefaulPayment.stripeCustomerId = card.customer;
    this.memberDefaulPayment.paymentMethodId = card.id;
    this.memberDefaulPayment.type = card.type;
    this.memberDefaulPayment.last4 = card.last4;

    this.memberPaymentsApiService
      .setMemberDefaultPaymentMethod(this.memberDefaulPayment)
      .subscribe({
        next: (response) => {
          // Reload Default payment Id
          this.toastMessageService.displaySuccessMessage(
            'Default payment method has been updated'
          );
          this.defaultPaymentId = card.id;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to set the default payment id'
          );
        },
      });
  }
}
