import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { DataTablesModel } from '../../../_models';
import { VisitReasonsAPIService } from '../../../_services';
import { VisitReasonsAction } from '../actions';

@Injectable()
export class VisitReasonsEffect {
  constructor(
    private visitReasonAPIService: VisitReasonsAPIService,
    private actions$: Actions
  ) {}

  public getVisitReasons$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VisitReasonsAction.getActiveVisitReasons),
      mergeMap(() =>
        this.visitReasonAPIService.getAllActiveVisitReasons().pipe(
          map((visitReasons: DataTablesModel) => {
            return VisitReasonsAction.getActiveVisitReasonsSuccessAction({
              payload: visitReasons?.items,
            });
          }),
          catchError((error: Error) => {
            return of(
              VisitReasonsAction.getActiveVisitReasonFailedAction({
                payload: error,
              })
            );
          })
        )
      )
    )
  );
}
