import { IVisitReasons } from 'src/app/_models';

export interface VisitReasonsState {
  visitReasons: IVisitReasons[];
  visitReasonsError: Error;
  visitReasonsSuccess: boolean;
  visitReasonsLoading: boolean;
}

export const initialVisitReasonsState: VisitReasonsState = {
  visitReasons: undefined,
  visitReasonsError: undefined,
  visitReasonsSuccess: false,
  visitReasonsLoading: false,
};
