import { Injectable } from '@angular/core';

export enum Environment {
  Prod = 'prod',
  Staging = 'staging',
  Test = 'test',
  Dev = 'dev',
  Local = 'local',
}

@Injectable({ providedIn: 'root' })
export class AuthConfigService {
  private _env: Environment;
  private _apiUrl: string;
  private _authConfig = {
    auth: 'https://login.bundlen.website',
    realm: 'Dev-Home-AspectN',
    issuer: 'https://login.bundlen.website/realms/Dev-Home-AspectN',
    redirectUri: window.location.origin + '/',
    clientId: 'dev-home-aspectn-8c015ab7-6245-46a1-8686-9fba4fd24753',
    responseType: 'code',
    scope: 'openid profile email',
    useSilentRefresh: false,
    showDebugInformation: false,
    disableAtHashCheck: true,
    requireHttps: true,
    logoutUrl:
      'https://login.bundlen.website/realms/Dev-Home-AspectN/protocol/openid-connect/logout',
  };

  get env(): Environment {
    return this._env;
  }

  get apiUrl(): string {
    return this._apiUrl;
  }

  get auth(): string {
    return this._authConfig.auth;
  }

  get realm(): string {
    return this._authConfig.realm;
  }

  get clientId(): string {
    return this._authConfig.clientId;
  }

  get authConfig(): any {
    return this._authConfig;
  }

  constructor() {
    this.setEnvVariables();
  }

  private setEnvVariables(): void {
    this._authConfig.requireHttps = true;
    this._authConfig.showDebugInformation = false;

    const hostname = window && window.location && window.location.hostname;
    if (/localhost/.test(hostname)) {
      this._env = Environment.Local;
      this._apiUrl = 'http://localhost:8081/api';
      this._authConfig.requireHttps = false;
      this._authConfig.showDebugInformation = true;
      this._authConfig.realm = 'Dev-Home-AspectN';
      this._authConfig.issuer =
        this._authConfig.auth + '/realms/' + this._authConfig.realm;
      this._authConfig.clientId =
        'dev-home-aspectn-8c015ab7-6245-46a1-8686-9fba4fd24753';
      this._authConfig.logoutUrl =
        this._authConfig.issuer + '/protocol/openid-connect/logout';
    } else if (/^dev-mindwise/.test(hostname)) {
      this._env = Environment.Dev;
      this._authConfig.requireHttps = false;
      this._authConfig.showDebugInformation = true;
      this._authConfig.realm = 'Dev-Home-AspectN';
      this._authConfig.issuer =
        this._authConfig.auth + '/realms/' + this._authConfig.realm;
      this._authConfig.clientId =
        'dev-home-mindwise-2ef6d466-cfe9-4312-bc97-458319193da9';
      this._authConfig.logoutUrl =
        this._authConfig.issuer + '/protocol/openid-connect/logout';
    } else if (/^dev-*/.test(hostname)) {
      this._env = Environment.Dev;
      this._authConfig.requireHttps = false;
      this._authConfig.showDebugInformation = true;
      this._authConfig.realm = 'Dev-Home-AspectN';
      this._authConfig.issuer =
        this._authConfig.auth + '/realms/' + this._authConfig.realm;
      this._authConfig.clientId =
        'dev-home-aspectn-8c015ab7-6245-46a1-8686-9fba4fd24753';
      this._authConfig.logoutUrl =
        this._authConfig.issuer + '/protocol/openid-connect/logout';
    } else if (/^test-*/.test(hostname)) {
      this._env = Environment.Test;
      this._authConfig.realm = 'Dev-Home-AspectN';
      this._authConfig.issuer =
        this._authConfig.auth + '/realms/' + this._authConfig.realm;
      this._authConfig.clientId =
        'dev-home-aspectn-8c015ab7-6245-46a1-8686-9fba4fd24753';
      this._authConfig.logoutUrl =
        this._authConfig.issuer + '/protocol/openid-connect/logout';
    } else if (/^stage-*/.test(hostname)) {
      this._env = Environment.Staging;
      this._authConfig.realm = 'Dev-Home-AspectN';
      this._authConfig.issuer =
        this._authConfig.auth + '/realms/' + this._authConfig.realm;
      this._authConfig.clientId =
        'dev-home-aspectn-8c015ab7-6245-46a1-8686-9fba4fd24753';
      this._authConfig.logoutUrl =
        this._authConfig.issuer + '/protocol/openid-connect/logout';
    } else {
      this._env = Environment.Prod;
      this._authConfig.realm = 'Prod-AspectN';
      this._authConfig.auth = 'https://login.mindwisehealth.com';
      this._authConfig.issuer =
        this._authConfig.auth + '/realms/' + this._authConfig.realm;
      this._authConfig.clientId =
        'prod-home-aspectn-82f47444-60a6-4132-981e-567d21bde46b';
      this._authConfig.logoutUrl =
        this._authConfig.issuer + '/protocol/openid-connect/logout';
    }
  }
}
