<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle style="color: red">Member Alerts ({{ alerts.total }})</p>
  </div>

  <div class="row">
    <label class="labelText" style="padding-top: 8px">
      The member has the following pending alerts or unsigned/unsubmitted
      documents. If applicable, please verify the submitted documents after
      acknowledging.
    </label>

    <div class="col-md-12" style="margin-bottom: 15px">
      <div class="alertContainer">
        <mat-card
          appearance="raised"
          *ngFor="let row of alerts.items"
          class="alertCard"
          [style.border-left]="
            row.status === 'Pending' ? '5px solid red' : '5px solid orange'
          "
        >
          <label class="labelBold">
            {{ row.documentName }}
          </label>
        </mat-card>
      </div>
    </div>
    <button mat-raised-button color="primary" (click)="submitAcknowledgement()">
      Acknowledge & Proceed
    </button>
  </div>
</div>
