import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastMessageService, UploadFilesService } from 'src/app/_services/';

@Component({
  selector: 'app-add-member-document',
  templateUrl: './add-member-document.component.html',
  styleUrls: ['./add-member-document.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AddMemberDocumentComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AddMemberDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public uploadFilesService: UploadFilesService,
    private toastMessageService: ToastMessageService
  ) {
    this.patientId = data.patientId;
    this.preSelectedFileType = data.preSelectedFileType;
  }

  public patientId: string;
  public preSelectedFileType: string;
  processing: boolean = false;
  progress = 0;
  documentForm: FormGroup;

  ngOnInit(): void {
    this.documentForm = new FormGroup({
      fileType: new FormControl(
        this.preSelectedFileType ? this.preSelectedFileType : null,
        Validators.required
      ),
      additionalInfo: new FormControl(null),
      image: new FormControl(null, [Validators.required]),
    });
  }

  submit() {
    this.processing = true;
    this.uploadFilesService
      .uploadMemberDocument(
        this.patientId,
        this.documentForm.controls['fileType'].value,
        this.documentForm.controls['image'].value,
        null,
        null,
        this.documentForm.controls['additionalInfo'].value
      )
      .subscribe({
        next: (response) => {
          this.processing = false;
          this.dialogRef.close(this.documentForm.controls['fileType'].value);
        },
        error: (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to upload the file.'
          );
        },
      });
  }
}
