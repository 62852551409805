<mat-tab-group [@.disabled]="true">
  <mat-tab>
    <ng-template mat-tab-label>
      <i
        *ngIf="isTaskCompleted('CLINICAL_INFORMED_CONSENT')"
        class="fa fa-check-circle"
        aria-hidden="true"
        style="color: #43a047; padding-right: 5px"
      >
      </i>
      <i
        class="fa fa-exclamation-circle"
        *ngIf="!isTaskCompleted('CLINICAL_INFORMED_CONSENT')"
        aria-hidden="true"
        style="color: red; padding-right: 5px"
      >
      </i>
      Provider Consent
    </ng-template>
    <app-clinician-informed-consent
      [therapySession]="therapySession"
      [patient]="patient"
      [loggedInUserId]="loggedInUserId"
      (updatedTherapySession)="refreshPageEvent($event)"
    >
    </app-clinician-informed-consent>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <i
        class="fa fa-check-circle"
        *ngIf="isTaskCompleted('CLINICAL_FAMILY_HISTORY')"
        aria-hidden="true"
        style="color: #43a047; padding-right: 5px"
      ></i>
      Family History
    </ng-template>
    <app-member-family-history
      [therapySession]="therapySession"
      [loggedInUserId]="loggedInUserId"
      [hasBPSAClinicalViewAccess]="hasBPSAClinicalViewAccess"
      [hasBPSAClinicalAddAccess]="hasBPSAClinicalAddAccess"
      [hasBPSAClinicalEditAccess]="hasBPSAClinicalEditAccess"
      (updatedTherapySession)="refreshPageEvent($event)"
    ></app-member-family-history>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <i
        class="fa fa-check-circle"
        *ngIf="isTaskCompleted('CLINICAL_EDUCATION_ETC')"
        aria-hidden="true"
        style="color: #43a047; padding-right: 5px"
      ></i>
      Education and Vocational History
    </ng-template>
    <app-educational-and-vocational-history
      [therapySession]="therapySession"
      [loggedInUserId]="loggedInUserId"
      [hasBPSAClinicalViewAccess]="hasBPSAClinicalViewAccess"
      [hasBPSAClinicalAddAccess]="hasBPSAClinicalAddAccess"
      [hasBPSAClinicalEditAccess]="hasBPSAClinicalEditAccess"
      (updatedTherapySession)="refreshPageEvent($event)"
    ></app-educational-and-vocational-history>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <i
        class="fa fa-check-circle"
        *ngIf="isTaskCompleted('CLINICAL_FINANCIAL_ETC')"
        aria-hidden="true"
        style="color: #43a047; padding-right: 5px"
      ></i>
      Financial History, Marital Status, Family Lifestyle, Legal Issues
    </ng-template>
    <app-financial-marital-family-legal-history
      [therapySession]="therapySession"
      [loggedInUserId]="loggedInUserId"
      [hasBPSAClinicalViewAccess]="hasBPSAClinicalViewAccess"
      [hasBPSAClinicalAddAccess]="hasBPSAClinicalAddAccess"
      [hasBPSAClinicalEditAccess]="hasBPSAClinicalEditAccess"
      (updatedTherapySession)="refreshPageEvent($event)"
    ></app-financial-marital-family-legal-history>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <i
        class="fa fa-check-circle"
        *ngIf="isTaskCompleted('CLINICAL_MEDICAL_HISTORY')"
        aria-hidden="true"
        style="color: #43a047; padding-right: 5px"
      ></i>
      Medical History
    </ng-template>
    <app-member-medical-history
      [therapySession]="therapySession"
      [loggedInUserId]="loggedInUserId"
      [hasBPSAClinicalViewAccess]="hasBPSAClinicalViewAccess"
      [hasBPSAClinicalAddAccess]="hasBPSAClinicalAddAccess"
      [hasBPSAClinicalEditAccess]="hasBPSAClinicalEditAccess"
      (updatedTherapySession)="refreshPageEvent($event)"
    ></app-member-medical-history>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <i
        class="fa fa-check-circle"
        *ngIf="isTaskCompleted('CLINICAL_SUBSTANCE_ETC')"
        aria-hidden="true"
        style="color: #43a047; padding-right: 5px"
      ></i>
      Substance, Mental Health, and Trauma History
    </ng-template>
    <app-substance-mental-trauma-history
      [therapySession]="therapySession"
      [loggedInUserId]="loggedInUserId"
      [hasBPSAClinicalViewAccess]="hasBPSAClinicalViewAccess"
      [hasBPSAClinicalAddAccess]="hasBPSAClinicalAddAccess"
      [hasBPSAClinicalEditAccess]="hasBPSAClinicalEditAccess"
      (updatedTherapySession)="refreshPageEvent($event)"
    ></app-substance-mental-trauma-history>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <i
        class="fa fa-check-circle"
        *ngIf="isTaskCompleted('CLINICAL_CULTURAL_ETC')"
        aria-hidden="true"
        style="color: #43a047; padding-right: 5px"
      ></i>
      Cultural, Spiritual, Leisure Activities, Member’s Strengths
    </ng-template>
    <app-cultural-spirutual-leisure-strengths
      [therapySession]="therapySession"
      [loggedInUserId]="loggedInUserId"
      [hasBPSAClinicalViewAccess]="hasBPSAClinicalViewAccess"
      [hasBPSAClinicalAddAccess]="hasBPSAClinicalAddAccess"
      [hasBPSAClinicalEditAccess]="hasBPSAClinicalEditAccess"
      (updatedTherapySession)="refreshPageEvent($event)"
    ></app-cultural-spirutual-leisure-strengths>
  </mat-tab>
</mat-tab-group>
