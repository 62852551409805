<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Sexual Addiction Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12" style="padding: 0">
  <div
    *ngIf="assessmentId && !isPatient"
    class="row"
    style="
      margin-bottom: 20px;
      display: flex;
      align-content: center;
      flex-direction: column;
      font-weight: 450;
    "
  >
    Risk Score
    <button
      mat-fab
      color="primary"
      aria-label="Score"
      style="margin-left: 10px"
    >
      {{ riskScore }}
    </button>
  </div>

  <form
    [formGroup]="sexualAddictionForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >1. Were you sexually abused as a child or adolescent?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q1">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >2. Did your parents have trouble with sexual behavior?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q2">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >3. Do you often find yourself preoccupied with sexual
            thoughts?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q3">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >4. Do you feel that your sexual behavior is not normal?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q4">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >5. Do you ever feel bad about your sexual behavior?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q5">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >6. Has your sexual behavior ever created problems for you and your
            family?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q6">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >7. Have you ever sought help for sexual behavior you did not
            like?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q7">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >8. Has anyone been hurt emotionally because of your sexual
            behavior?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q8">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >9. Are any of your sexual activities against the law?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q9">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >10. Have you made efforts to quit a type of sexual activity and
            failed?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q10">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >11. Do you hide some of your sexual behaviors from others?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q11">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >12. Have you attempted to stop some parts of your sexual
            activity?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q12">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >13. Have you felt degraded by your sexual behaviors?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q13">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >14. When you have sex, do you feel depressed afterwards?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q14">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >15. Do you feel controlled by your sexual desire?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q15">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >16. Have important parts of your life (such as job, family,
            friends, leisure activities) been neglected because you were
            spending too much time on sex?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q16">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >17. Do you ever think your sexual desire is stronger than you
            are?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q17">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >18. Is sex almost all you think about?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q18">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >19. Has sex (or romantic fantasies) been a way for you to escape
            your problems?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q19">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >20. Has sex become the most important thing in your life?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q20">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >21. Are you in crisis over sexual matters?</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q21">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >22. The internet has created sexual problems for me.</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q22">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >23. I spend too much time online for sexual purposes.</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q23">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >24. I have purchased services online for erotic purposes (sites for
            dating)</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q24">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >25. I have used the internet to make romantic or erotic connections
            with people online.</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q25">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >26. People in my life have been upset about my sexual activities
            online.</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q26">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >27. I have attempted to stop my online sexual behaviors.</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q27">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >28. I have subscribed to or regularly purchased or rented sexually
            explicit materials (magazines, videos, books or online
            pornography).</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q28">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >29. I have been sexual with minors.</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q29">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >30. I have spent considerable time and money on strip clubs, adult
            bookstores and movie houses.</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q30">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >31. I have engaged prostitutes and escorts to satisfy my sexual
            needs.</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q31">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >32. I have spent considerable time surfing pornography
            online.</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q32">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >33. I have used magazines, videos or online pornography even when
            there was considerable risk of being caught by family members who
            would be upset by my behavior.</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q33">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >34. I have regularly purchased romantic novels or sexually explicit
            magazines.</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q34">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >35. I have stayed in romantic relationships after they became
            emotionally abusive.</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q35">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >36. I have traded sex for money or gifts.</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q36">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >37. I have maintained multiple romantic or sexual relationships at
            the same time.</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q37">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >38. After sexually acting out, I sometimes refrain from all sex for
            a significant period.</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q38">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >39. I have regularly engaged in sadomasochistic behavior.</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q39">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >40. I visit sexual bath-houses, sex clubs or video/bookstores as
            part of my regular sexual activity.</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q40">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >41. I have engaged in unsafe or “risky” sex even though I knew it
            could cause me harm.</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q41">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >42. I have cruised public restrooms, rest areas or parks looking
            for sex with strangers.</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q42">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >43. I believe casual or anonymous sex has kept me from having more
            long-term intimate relationships.</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q43">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >44. My sexual behavior has put me at risk for arrest for lewd
            conduct or public indecency.</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q44">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-9 asterix-after"
            >45. I have been paid for sex.</label
          >
          <div class="col-sm-3">
            <mat-button-toggle-group formControlName="q45">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          sexualAddictionForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!sexualAddictionForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
