import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { DataTablesModel } from 'src/app/_models/data-tables-modal';
import { IReferral } from 'src/app/_models/referrals/referral.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ReferralService {
    constructor(private httpClient: HttpClient) { }

    //create listing
    createReferral(
        referral: IReferral
    ): Observable<IReferral> {
        return this.httpClient
            .post<any>(`${environment.referralUrl}/referral/create`, referral)
            .pipe(
                catchError((err) => {
                    console.error(err);
                    throw err;
                })
            );
    }

    updateReferral(
        referral: IReferral, id: any
    ): Observable<any> {
        return this.httpClient
            .put<any>(`${environment.referralUrl}/referral/update`, referral, id)
            .pipe(
                catchError((err) => {
                    console.error(err);
                    throw err;
                })
            );
    }

    addFirstAttempt(firstAttempt: any
    ): Observable<any> {
        return this.httpClient
            .post<any>(`${environment.referralUrl}/referral/add/first-attempt`, firstAttempt)
            .pipe(
                catchError((err) => {
                    console.error(err);
                    throw err;
                })
            );
    }

    addSecondAttempt(
        secondAttempt: any
    ): Observable<any> {
        return this.httpClient
            .post<any>(`${environment.referralUrl}/referral/add/second-attempt`, secondAttempt)
            .pipe(
                catchError((err) => {
                    console.error(err);
                    throw err;
                })
            );
    }

    addThirdAttempt(
        thirdAttempt: any
    ): Observable<any> {
        return this.httpClient
            .post<any>(`${environment.referralUrl}/referral/add/third-attempt`, thirdAttempt)
            .pipe(
                catchError((err) => {
                    console.error(err);
                    throw err;
                })
            );
    }

    getDetails(status: string, selectedFacilities: any[], staffMembers: any[]): Observable<DataTablesModel> {
        const payload: any = {};
        if (status) payload.status = status;
        if (selectedFacilities) {
            payload.facilities = selectedFacilities.map((facility: any) => facility.id);
        }
        if (staffMembers) {
            payload.staffMembers = staffMembers.map((staffMember: any) => staffMember.id);
        }

        return this.httpClient.post<DataTablesModel>(`${environment.referralUrl}/referrals`, payload);
    }

    getReferralById(referralId: string): Observable<any> {
        return this.httpClient
            .get<any>(
                `${environment.referralUrl}/referral/get/${referralId}`
            )
            .pipe(
                map((response) => {
                    return response || {};
                }),
                catchError(this.errorMgmt)
            );
    }

    //error Handling
    errorMgmt(error: HttpErrorResponse) {
        let errorMessage = ' ';
        if (error.error instanceof ErrorEvent) {
            //get client side error
            errorMessage = error.error.message;
        } else {
            //get serverside error
            errorMessage = `Error: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
    }


    getStaffMembers() {
        return this.httpClient.get<any>(
            `${environment.referralUrl}/referralStaffMembers`
        );
    }
}
