import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import moment from 'moment';
import { DataTablesModel } from 'src/app/_models';
import { AdhdDiagnosisRatingApiService } from 'src/app/_services';
import { CHART_COLORS, greenChart, hexToRGBA, orangeChart, redChart, yellowChart } from 'src/app/shared/utilities/utilities';
@Component({
  selector: 'app-adhd-diagnostic-rating-charts',
  templateUrl: './adhd-diagnostic-rating-charts.component.html',
  styleUrls: ['./adhd-diagnostic-rating-charts.component.css']
})
export class AdhdDiagnosticRatingChartsComponent implements OnInit {
  @Input() patientId: string;
  @Input() assessmentId: string;
  @Input() anchorTag: string;
  chartColorRed = CHART_COLORS.red;
  chartColorOrange = CHART_COLORS.orange;
  chartColorBlue = CHART_COLORS.blue;
  chartColorGreen = CHART_COLORS.green;
  chartColorPurple = CHART_COLORS.purple;
  charColorBurgundy = CHART_COLORS.burgundy;
  lineChartData: ChartConfiguration<'line'>['data'] = {
    datasets: [{ data: [], label: 'Inattentive type', backgroundColor: this.chartColorRed, hoverBackgroundColor: this.chartColorRed },
    { data: [], label: 'Hyperactive/Impulsive type', backgroundColor: this.chartColorOrange, hoverBackgroundColor: this.chartColorOrange },
    { data: [], label: 'Combined Inattention/Hyperactivity', backgroundColor: this.charColorBurgundy, hoverBackgroundColor: this.charColorBurgundy },
    { data: [], label: 'Oppositional defiant disorder (ODD)', backgroundColor: this.chartColorBlue, hoverBackgroundColor: this.chartColorBlue },
    { data: [], label: 'Conduct Disorder', backgroundColor: this.chartColorGreen, hoverBackgroundColor: this.chartColorGreen },
    { data: [], label: 'Anxiety/Depression', backgroundColor: this.chartColorPurple, hoverBackgroundColor: this.chartColorPurple },]
  };
  overallChartData: ChartConfiguration<'line'>['data'] = {
    datasets: [{
      tension: 0.4,
      data: [], label: 'Overall Score',
      borderColor: '#EEEEEE',
      pointBackgroundColor: '#333333',
      pointBorderColor: '#FFFFFF',
      pointHoverBackgroundColor: '#FFFFFF',
      pointHoverBorderColor: '#333333',
      pointStyle: 'circle',
      pointRadius: 5,
    }]
  };

  lineChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true
      }
    },
    scales: {
      x: {
        stacked: true
      },
      y: {
        stacked: true,
        display: false
      }
    }
  };
  overallChartOptions: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        title: {
          display: false
        }
      },
      annotation: {
        annotations: {
          greenBg:
          {
            type: 'box',
            yMin: 0,
            yMax: 45.5,
            backgroundColor: hexToRGBA(greenChart.backgroundColor, 1),
            borderColor: greenChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          },
          yellowBg: {
            type: 'box',
            yMin: 45.5,
            yMax: 90.5,
            backgroundColor: hexToRGBA(yellowChart.backgroundColor, 1),
            borderColor: yellowChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          },
          orangeBg: {
            type: 'box',
            yMin: 90.5,
            yMax: 135.5,
            backgroundColor: hexToRGBA(orangeChart.backgroundColor, 1),
            borderColor: orangeChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          },
          redBg: {
            type: 'box',
            yMin: 135.5,
            yMax: 181,
            backgroundColor: hexToRGBA(redChart.backgroundColor, 1),
            borderColor: redChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          }
        }
      }
    },
    scales: {
      x: {
        min: 0,
        max: 10
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        },
        min: 0,
        max: 181,
        ticks: { stepSize: 40 }
      }
    }
  };
  assessmentDetailInitialChartOptions: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        title: {
          display: false
        }
      }
    },
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        },
        min: 0,
        max: 3,
        ticks: { stepSize: 1 }
      }
    }
  };
  assessmentDetailChartOptions: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        title: {
          display: false
        }
      }
    },
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        },
        min: 0,
        max: 5,
        ticks: { stepSize: 1 }
      }
    }
  };

  lineChartLegend = true;
  lineChartPlugins = [];
  overallChartLegend = false;
  overallChartPlugins = [];
  lineChartType = 'bar';
  detailChartType = 'line';
  detailChartLegend = false;
  detailChartPlugins = [];

  data: DataTablesModel = {} as DataTablesModel;
  processing$ = false;
  isLoadingResults = false;
  error: string = '';
  scoreMap = new Map();
  scoreDetails: any[] = [];
  firstSetScoreDetails: any[] = [];
  constructor(public adhdDiagnosisRatingApiService: AdhdDiagnosisRatingApiService,
    private route: ActivatedRoute) {
  }
  ngOnInit() {
    this.data.page = 0;
    this.data.per_page = 10;
    this.loadGamingAddictionAssessments();
  }
  public loadGamingAddictionAssessments() {
    this.isLoadingResults = true;
    this.error = '';
    this.adhdDiagnosisRatingApiService.getAdhdDiagnosisRatingList(this.patientId, this.assessmentId, this.data.per_page, this.data.page).subscribe(response => {
      if (response && response.items) {
        this.data.items = response.items;
        this.data.total = response.total;
        var riskScores = [];
        var labels = [];
        this.scoreDetails = [];
        this.firstSetScoreDetails = [];
        var inattentiveScores = [];
        var hyperactiveScores = [];
        var combinedScores = [];
        var defiantScores = [];
        var conductScores = [];
        var depressionScores = [];
        for (var item of response.items) {
          if (response.items.length === 1) {
            labels.push("");
            riskScores.push(item.riskScore);
          }
          riskScores.push(item.riskScore);
          if (item.props) {
            inattentiveScores.push((item.props['inattentive'] >= 6 && item.props['performance'] > 0) ? 1 : 0);
            hyperactiveScores.push((item.props['hyperactive'] >= 6 && item.props['performance'] > 0) ? 1 : 0);
            combinedScores.push((item.props['inattentive'] >= 6 && item.props['hyperactive'] >= 6 && item.props['performance'] > 0) ? 1 : 0);
            defiantScores.push((item.props['defiant'] >= 4 && item.props['performance'] > 0) ? 1 : 0);
            conductScores.push((item.props['conduct'] >= 3 && item.props['performance'] > 0) ? 1 : 0);
            depressionScores.push((item.props['depression'] >= 3 && item.props['performance'] > 0) ? 1 : 0);
          } else {
            inattentiveScores.push(0);
            hyperactiveScores.push(0);
            combinedScores.push(0);
            defiantScores.push(0);
            conductScores.push(0);
            depressionScores.push(0);
          }
          var stillUtc = moment.utc(item.createdDate).toDate();
          var assessmentDate = moment(stillUtc).local().format('MM/DD/YY');
          labels.push(assessmentDate);
          var idx = 0;
          for (var key in item) {
            if (item.hasOwnProperty(key)) {
              if (item[key] && item[key].hasOwnProperty("score")) {
                var scoreObj = item[key];
                var scores = [];
                if (this.scoreMap.get(key)) {
                  scores = this.scoreMap.get(key).scores;
                }
                if (response.items.length === 1) {
                  scores.push(scoreObj["score"]);
                }
                scores.push(scoreObj["score"]);
                var chartColor: any;
                if (idx < 47) {
                  chartColor = this.getFirstChartColor(scores);
                } else {
                  chartColor = this.getChartColor(scores);
                }
                var returnObj = { "scores": scores, "key": key, "title": scoreObj["title"], "answer": scoreObj["answer"], "chartData": [], "chartColor": chartColor };
                this.scoreMap.set(key, returnObj);
                idx++;
              }
            }
          }
        }
        var pushIdx = 0;
        this.scoreMap.forEach((value, key) => {
          let dataset: any = {
            datasets: [
              {
                "data": value.scores,
                "borderColor": value.chartColor.borderColor,
                "backgroundColor": value.chartColor.backgroundColor,
                "tension": 0.4,
                "fill": true,
                pointBackgroundColor: '#333333',
                pointRadius: 5
              }
            ],
            "labels": labels,
            "title": value.title,
            "answer": value.answer
          };
          if (pushIdx < 47) {
            this.firstSetScoreDetails.push(dataset);
          } else {
            this.scoreDetails.push(dataset);
          }
          pushIdx++;
        });
        this.overallChartData.datasets[0].data = riskScores;
        this.lineChartData.datasets[0].data = inattentiveScores;
        this.lineChartData.datasets[1].data = hyperactiveScores;
        this.lineChartData.datasets[2].data = combinedScores;
        this.lineChartData.datasets[3].data = defiantScores;
        this.lineChartData.datasets[4].data = conductScores;
        this.lineChartData.datasets[5].data = depressionScores;
        this.lineChartData.labels = labels;
        this.overallChartData.labels = labels;
        this.isLoadingResults = false;
      }
      if (response == null) {
        this.data.items = [];
        this.data.total = 0;
      }
    },
      error => {
        this.error = error;
        this.isLoadingResults = false;
      })
  }
  getFirstChartColor(scoringArray: any[]) {
    var score = scoringArray[scoringArray.length - 1];
    if (score >= 2) {
      return redChart;
    } else {
      return greenChart;
    }
  }
  getChartColor(scoringArray: any[]) {
    var score = scoringArray[scoringArray.length - 1];
    if (score >= 4) {
      return redChart;
    } else if (score >= 3) {
      return orangeChart;
    } else if (score >= 2) {
      return yellowChart;
    } else {
      return greenChart;
    }
  }
}
