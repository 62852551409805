<mat-dialog-content>
    <div class="container" style="padding-bottom: 13px;">
        <div class="row">
            <div class="col col-12">
                <button mat-mini-fab mat-dialog-close
                    style="float: right; background: #ffffff; background-color: #ffffff; color: #333333;"
                    (click)="closeDialog()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
        <h3 style="font-weight: 400; ">Referral Information</h3>
        <form [formGroup]="addReferralForm">
            <div class="mat-elevation-z10" style="padding: 15px; border-radius: 5px;">
                <div formGroupName="referralForm">
                    <div class="row">
                        <div class="col-md-4">
                            <label class="labelText">
                                Facility <span style="color: red">*</span></label>
                            <mat-form-field appearance="outline">
                                <mat-select formControlName="facilityId">
                                    <mat-option *ngFor="let facility of facilities" [value]="facility.id">
                                        {{ facility.facilityName }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <label class="labelText" style="display: flex">Service Type</label>
                            <mat-form-field appearance="outline">
                                <input matInput placeholder="Service Type" formControlName="serviceType" />
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <label class="labelText" style="display: flex">Stimulants <span style="color: red">*</span></label>
                            <mat-form-field appearance="outline">
                                <input matInput placeholder="Stimulants" formControlName="stimulants" name="stimulants"
                                    required />
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <label class="labelText" style="display: flex">Referral Method <span style="color: red">*</span></label>
                            <mat-form-field appearance="outline">
                                <mat-select formControlName="referralMethod" required
                                    placeholder="Select Referral Method">
                                    <mat-option value="Email">Email</mat-option>
                                    <mat-option value="Fax">Fax</mat-option>
                                    <mat-option value="other">Other</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <label class="labelText" style="display: flex">Referred By <span style="color: red">*</span></label>
                            <mat-form-field appearance="outline">
                                <input matInput placeholder="Referred By" formControlName="referredBy" required />
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <label class="labelText" style="display: flex">Referral ZipCode <span style="color: red">*</span></label>
                            <mat-form-field appearance="outline">
                                <input matInput placeholder="Referral Zip Code" formControlName="referralZipCode"
                                    type="number" [min]="0" required />
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <label class="labelText" style="display: flex">Referral Fax</label>
                            <mat-form-field appearance="outline">
                                <input matInput placeholder="Referred by Fax Number" formControlName="referralFax"
                                    type="number" [min]="0" />
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-slide-toggle [(ngModel)]="isChecked" formControlName="mhuc">MHUC</mat-slide-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <h3 style="font-weight: 400; margin-top: 3%;">Patient Details</h3>

            <div class="mat-elevation-z10" style="padding: 15px; margin-top: 10px; border-radius: 5px;">
                <div formGroupName="patientDetailsForm">
                    <div class="row">
                        <div class="col-md-4">
                            <label class="labelText" style="display: flex">Name <span style="color: red">*</span></label>
                            <mat-form-field appearance="outline">
                                <input matInput placeholder="Name" formControlName="name" required />
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <label class="labelText" style="display: flex">Phone Number <span style="color: red">*</span></label>
                            <mat-form-field appearance="outline">
                                <input matInput placeholder="Phone Number" formControlName="phoneNumber" type="number"
                                    [min]="0" required />
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <label class="labelText" style="display: flex">Date Of Birth <span style="color: red">*</span></label>
                            <mat-form-field appearance="outline">
                                <input matInput [matDatepicker]="pickerdob" formControlName="dateOfBirth"
                                    placeholder="Date Of Birth" readonly required />
                                <mat-datepicker-toggle matSuffix [for]="pickerdob"></mat-datepicker-toggle>
                                <mat-datepicker #pickerdob></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="col-md-4">
                            <label class="labelText" style="display: flex">Email</label>
                            <mat-form-field appearance="outline">
                                <input matInput placeholder="Email Address" formControlName="email" type="email"
                                    required />
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <label class="labelText" style="display: flex">Primary Insurance</label>
                            <mat-form-field appearance="outline">
                                <input matInput placeholder="Primary insurance" formControlName="primaryInsurance" />
                            </mat-form-field>
                        </div>

                        <div class="col-md-12">
                            <label class="labelText" style="display: flex">Notes</label>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <textarea matInput formControlName="referralNotes" rows="4"></textarea>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-slide-toggle [(ngModel)]="isChecked" formControlName="medicaid">Primary
                                Medicaid</mat-slide-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <h3 style="font-weight: 400; margin-top: 30px;">Media</h3>
            <div class="mat-elevation-z10" style="padding: 15px; margin-top: 10px; border-radius: 5px;">
                <div formGroupName="mediaForm">
                    <div class="row">
                        <div class="col-md-12">
                            <p style="text-align: left; padding-top: 10px">Attachments</p>
                            <app-drag-and-drop formControlName="documents" appDragAndDrop>
                            </app-drag-and-drop>
                        </div>
                    </div>
                    <div class="example-element-diagram">
                        <p class="para-style">
                            <mat-icon class="material-icons">
                                upload_file
                            </mat-icon>
                        </p>
                        <p class="para-style">

                            <span style="color: #2196f3; font-weight: 600">Click here</span>
                            &nbsp; to upload or drop files here
                        </p>
                    </div>
                </div>
            </div>

            <div class="row" style="margin-top:30px">
                <div class="col-md-12" style="text-align: right">
                    <button color="primary" mat-raised-button type="submit" (click)="onReferralFormSubmit()"
                        [disabled]="!addReferralForm.valid || processing">
                        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
                    </button>
                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>