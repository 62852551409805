import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ITherapySession } from 'src/app/_models';
import { DocumentHttpService, ToastMessageService } from 'src/app/_services';

@Component({
  selector: 'app-treatment-plan',
  templateUrl: './treatment-plan.component.html',
  styleUrls: ['./treatment-plan.component.css']
})
export class TreatmentPlanComponent implements OnInit {
  @Input() therapySession: ITherapySession;
  @ViewChild('pdfViewerOnDemand', { static: false }) pdfViewerOnDemand;

  displayNoRecordsMsg = false;
  pdfSrc = null;
  loadingReport = false;

  constructor(
    public documentApiService: DocumentHttpService,
    private toastMessageService: ToastMessageService
  ) { }

  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm() {
    this.generateReport();
  }

  generateReport() {
    this.displayNoRecordsMsg = false;
    this.loadingReport = true;

    this.documentApiService
      .treatmentPlanDocument(
        this.therapySession.id,
        this.therapySession.patientId
      )
      .subscribe(
        (res) => {
          this.loadingReport = false;
          if (res) {
            this.pdfViewerOnDemand.pdfSrc = res;
            this.pdfViewerOnDemand.refresh();
            this.displayNoRecordsMsg = false;
          } else {
            this.pdfViewerOnDemand.pdfSrc = null;
            this.pdfViewerOnDemand.refresh();
            this.displayNoRecordsMsg = true;
          }
        },
        (error) => {
          this.loadingReport = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to generate report'
          );
        }
      );
  }
}
