import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Patient } from 'src/app/_models';
import { ToastMessageService } from 'src/app/_services';
import { ConsentDocumentApiService } from 'src/app/_services/consent-docs/orgconsentdocument.service';

@Component({
  selector: 'app-member-add-consent-documents',
  templateUrl: './member-add-consent-documents.component.html',
  styleUrl: './member-add-consent-documents.component.css',
})
export class MemberAddConsentDocumentsComponent implements OnInit {
  isLoadingResults: boolean = true;
  processing: boolean = false;

  consentDocumentsList: any = [];
  patient: Patient;
  newDocumentsForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MemberAddConsentDocumentsComponent>,
    public consentDocumentApiService: ConsentDocumentApiService,
    private formBuilder: FormBuilder,
    private toastMessageService: ToastMessageService
  ) {
    this.patient = data.patient;
  }

  ngOnInit(): void {
    this.loadActiveConsentDocuments();
    this.buildForm();
  }

  buildForm() {
    this.newDocumentsForm = this.formBuilder.group({
      documents: new FormControl([], Validators.required),
    });
  }

  loadActiveConsentDocuments() {
    this.isLoadingResults = true;

    this.consentDocumentApiService
      .getOrganizationAllConsentDocuments()
      .subscribe({
        next: (response) => {
          if (response && response.items) {
            this.consentDocumentsList = response.items;
          }
          this.isLoadingResults = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load the consent documents list.'
          );
          this.isLoadingResults = false;
        },
      });
  }

  removeConsentDocument(document, index) {
    let existingConsentDocuments = this.newDocumentsForm.controls['documents']
      .value as [];

    existingConsentDocuments.splice(index, 1);

    this.newDocumentsForm.controls['documents'].setValue(
      existingConsentDocuments
    );
  }

  submitForm() {
    let payload = this.newDocumentsForm.controls['documents'].value;
    this.processing = true;

    this.consentDocumentApiService
      .requestNewConsentDocument(payload, this.patient.id)
      .subscribe({
        next: (response) => {
          this.processing = false;
          this.toastMessageService.displaySuccessMessage(
            'Successfully requested the consent document(s)'
          );
          this.dialogRef.close('success');
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to request the consent document(s)'
          );
          this.processing = false;
        },
      });
  }
}
