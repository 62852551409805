import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { DataTablesModel, ITherapySession, Patient } from 'src/app/_models';
import { DocumentHttpService, ToastMessageService } from 'src/app/_services';
import { GeneralDeleteConfirmDialogComponent } from 'src/app/shared/components/general-delete-confirm-dialog/general-delete-confirm-dialog.component';
import { AddConsentDocumentComponent } from '../bpsa/bpsa-clinical/clinician-informed-consent/add-consent-document/add-consent-document.component';

@Component({
  selector: 'app-session-documents',
  templateUrl: './session-documents.component.html',
  styleUrls: ['./session-documents.component.css'],
})
export class SessionDocumentsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('pdfViewerOnDemand', { static: false }) pdfViewerOnDemand;

  @Input() patient: Patient;
  @Input() hasSessionDocumentsViewAccess: boolean;
  @Input() hasSessionDocumentsAddAccess: boolean;
  @Input() hasSessionDocumentsDeleteAccess: boolean;
  @Input() therapySession: ITherapySession;
  @Output() output = new EventEmitter<any>();

  displayedColumns = ['fileName', 'uploadedOn', 'uploadedBy', 'actions'];

  data: DataTablesModel = {} as DataTablesModel;
  isLoadingResults = false;
  apiError = false;

  constructor(
    public dialog: MatDialog,
    public documentApiService: DocumentHttpService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit() {
    this.data.per_page = 10;
    this.data.page = 0;

    this.loadDocuments();
  }

  addDocument() {
    const dialogRef = this.dialog.open(AddConsentDocumentComponent, {
      data: {
        patientId: this.patient.id,
        sessionId: this.therapySession.id,
        sessionCode: this.therapySession.sessionCode,
        type: 'Session',
      },
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadDocuments();
      }
    });
  }

  downloadFile(fileSessionId: string, fileId: string) {
    this.isLoadingResults = true;
    this.documentApiService
      .downloadSessionDocument(fileSessionId, fileId, this.patient.id)
      .subscribe(
        (response: any) => {
          this.isLoadingResults = false;
          let blob: any = new Blob([response], { type: response.type });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        },
        (error) => {
          this.isLoadingResults = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Something went wrong. Please try again'
          );
        }
      );
  }

  public loadDocuments() {
    this.isLoadingResults = true;
    this.apiError = false;

    this.documentApiService
      .getSessionDocuments(
        this.therapySession.id,
        this.patient.id,
        this.patient.organizationId,
        this.data.per_page,
        this.data.page
      )
      .subscribe(
        (response) => {
          this.isLoadingResults = false;
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          }
          if (response == null) {
            this.data.items = [];
            this.data.total = 0;
          }
        },
        (error) => {
          this.apiError = true;
          this.isLoadingResults = false;
        }
      );
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadDocuments();
  }

  deleteDocument(fileSessionId: string, fileId: string) {
    const dialogRef = this.dialog.open(GeneralDeleteConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to delete this document?',
      },
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.isLoadingResults = true;
        this.documentApiService
          .deleteSessionDocument(fileSessionId, fileId)
          .subscribe(
            () => {
              this.isLoadingResults = false;
              // Handle Delete Pagination
              if (this.data.items.length == 1) {
                this.data.page = this.data.page - 1;
                if (this.data.page < 0) {
                  this.data.page = 0;
                }
              }

              this.loadDocuments();
            },
            (error) => {
              this.isLoadingResults = false;
              this.toastMessageService.displayErrorMessage(
                'Error: Something went wrong. Please try again'
              );
            }
          );
      }
    });
  }
}
