<mat-card
  appearance="raised"
  class="mat-elevation-z8"
  style="border-radius: 2px"
>
  <div class="row">
    <div class="col-md-8">
      <label class="labelHeader"> Objectives </label>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12" [formGroup]="objectiveForm" novalidate>
      <mat-card appearance="raised" class="mat-elevation-z8 margin20">
        <h3 style="font-weight: 450">Observations</h3>

        <ng-container formArrayName="observations">
          <div
            *ngFor="let observation of observations; let questId = index"
            [formGroupName]="questId"
          >
            <label class="labelText">
              {{ observation.label }}
            </label>
            <br />
            <ng-container formArrayName="responses">
              <ng-container
                *ngFor="let options of observation.responses; let optId = index"
                [formGroupName]="optId"
              >
                <mat-checkbox
                  color="primary"
                  class="checkbox-margin"
                  formControlName="selected"
                  >{{ options.value }}</mat-checkbox
                >
              </ng-container>
            </ng-container>
          </div>
        </ng-container>

        <mat-form-field appearance="outline">
          <mat-label>AEB (up to 400 characters)</mat-label>
          <textarea
            matInput
            formControlName="obeAEB"
            name="obeAEB"
            spellcheck="true"
            rows="4"
            maxlength="400"
            [readonly]="
              (therapySession.physicianId != loggedInUserId &&
                therapySession?.supervisingRPId != loggedInUserId) ||
              therapySession.status != 'Active'
            "
          ></textarea>
        </mat-form-field>
      </mat-card>

      <mat-card appearance="raised" class="mat-elevation-z8 margin20">
        <h3 style="font-weight: 450">Mood</h3>

        <ng-container formArrayName="mood">
          <div
            *ngFor="let option of moodOptions; let questId = index"
            [formGroupName]="questId"
          >
            <ng-container formArrayName="responses">
              <ng-container
                *ngFor="let response of option.responses; let optId = index"
                [formGroupName]="optId"
              >
                <mat-checkbox
                  color="primary"
                  class="checkbox-margin"
                  formControlName="selected"
                  >{{ response.value }}</mat-checkbox
                >
              </ng-container>
            </ng-container>
          </div>
        </ng-container>

        <mat-form-field appearance="outline">
          <mat-label>AEB (up to 400 characters)</mat-label>
          <textarea
            matInput
            formControlName="mAEB"
            name="mAEB"
            spellcheck="true"
            rows="4"
            maxlength="400"
            [readonly]="
              (therapySession.physicianId != loggedInUserId &&
                therapySession?.supervisingRPId != loggedInUserId) ||
              therapySession.status != 'Active'
            "
          ></textarea>
        </mat-form-field>
      </mat-card>

      <mat-card appearance="raised" class="mat-elevation-z8 margin20">
        <h3 style="font-weight: 450">Cognition</h3>

        <ng-container formArrayName="cognition">
          <div
            *ngFor="let option of cognitionOptions; let questId = index"
            [formGroupName]="questId"
          >
            <label class="labelText">{{ option.label }}</label
            ><br />
            <ng-container formArrayName="responses">
              <ng-container
                *ngFor="let response of option.responses; let optId = index"
                [formGroupName]="optId"
              >
                <mat-checkbox
                  color="primary"
                  class="checkbox-margin"
                  formControlName="selected"
                  >{{ response.value }}</mat-checkbox
                >
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </mat-card>

      <mat-card appearance="raised" class="mat-elevation-z8 margin20">
        <h3 style="font-weight: 450">Perception</h3>

        <ng-container formArrayName="perception">
          <div
            *ngFor="let option of perceptionOptions; let questId = index"
            [formGroupName]="questId"
          >
            <label class="labelText">{{ option.label }}</label
            ><br />
            <ng-container formArrayName="responses">
              <ng-container
                *ngFor="let response of option.responses; let optId = index"
                [formGroupName]="optId"
              >
                <mat-checkbox
                  color="primary"
                  class="checkbox-margin"
                  formControlName="selected"
                  >{{ response.value }}</mat-checkbox
                >
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </mat-card>

      <mat-card appearance="raised" class="mat-elevation-z8 margin20">
        <h3 style="font-weight: 450">Thoughts</h3>

        <ng-container formArrayName="thought">
          <div
            *ngFor="let option of thoughtOptions; let questId = index"
            [formGroupName]="questId"
          >
            <label class="labelText">{{ option.label }}</label
            ><br />
            <ng-container formArrayName="responses">
              <ng-container
                *ngFor="let response of option.responses; let optId = index"
                [formGroupName]="optId"
              >
                <mat-checkbox
                  color="primary"
                  class="checkbox-margin"
                  formControlName="selected"
                  >{{ response.value }}</mat-checkbox
                >
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </mat-card>

      <mat-card appearance="raised" class="mat-elevation-z8 margin20">
        <h3 style="font-weight: 450">Behavior Towards Therapist</h3>

        <ng-container formArrayName="behaviourTowardsTherapist">
          <div
            *ngFor="
              let option of behaviourTowardsTherapistOptions;
              let questId = index
            "
            [formGroupName]="questId"
          >
            <ng-container formArrayName="responses">
              <ng-container
                *ngFor="let response of option.responses; let optId = index"
                [formGroupName]="optId"
              >
                <mat-checkbox
                  color="primary"
                  class="checkbox-margin"
                  formControlName="selected"
                  >{{ response.value }}</mat-checkbox
                >
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </mat-card>

      <mat-card appearance="raised" class="mat-elevation-z8 margin20">
        <h3 style="font-weight: 450">Insight</h3>

        <ng-container formArrayName="insight">
          <div
            *ngFor="let option of insightOptions; let questId = index"
            [formGroupName]="questId"
          >
            <ng-container formArrayName="responses">
              <ng-container
                *ngFor="let response of option.responses; let optId = index"
                [formGroupName]="optId"
              >
                <mat-checkbox
                  color="primary"
                  class="checkbox-margin"
                  formControlName="selected"
                  >{{ response.value }}</mat-checkbox
                >
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </mat-card>

      <mat-card appearance="raised" class="mat-elevation-z8 margin20">
        <h3 style="font-weight: 450">Judgement</h3>

        <ng-container formArrayName="judgement">
          <div
            *ngFor="let option of judgementOptions; let questId = index"
            [formGroupName]="questId"
          >
            <ng-container formArrayName="responses">
              <ng-container
                *ngFor="let response of option.responses; let optId = index"
                [formGroupName]="optId"
              >
                <mat-checkbox
                  color="primary"
                  class="checkbox-margin"
                  formControlName="selected"
                  >{{ response.value }}</mat-checkbox
                >
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </mat-card>
    </div>
  </div>
</mat-card>
