<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Postnatal Depression Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="postnatalDepressionForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >1. I have been able to laugh and see the funny side of
            things</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="funnySide">
              <mat-button-toggle value="As much as I always could"
                >As much as I always could</mat-button-toggle
              >
              <mat-button-toggle value="Not quite so much now"
                >Not quite so much now</mat-button-toggle
              >
              <mat-button-toggle value="Definitely not so much now"
                >Definitely not so much now</mat-button-toggle
              >
              <mat-button-toggle value="Not at all"
                >Not at all</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >2. I have looked forward with enjoyment to things</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="enjoyment">
              <mat-button-toggle value="As much as I ever did"
                >As much as I ever did</mat-button-toggle
              >
              <mat-button-toggle value="Rather less than I used to"
                >Rather less than I used to</mat-button-toggle
              >
              <mat-button-toggle value="Definitely less than I used to"
                >Definitely less than I used to</mat-button-toggle
              >
              <mat-button-toggle value="Hardly at all"
                >Hardly at all</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >3. I have blamed myself unnecessarily when things went wrong</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="blamedMyself">
              <mat-button-toggle value="Yes, most of the time"
                >Yes, most of the time</mat-button-toggle
              >
              <mat-button-toggle value="Yes, some of the time"
                >Yes, some of the time</mat-button-toggle
              >
              <mat-button-toggle value="Not very often"
                >Not very often</mat-button-toggle
              >
              <mat-button-toggle value="No, never">No, never</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >4. I have been anxious or worried for no good reason</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="noGoodReason">
              <mat-button-toggle value="No not at all"
                >No not at all</mat-button-toggle
              >
              <mat-button-toggle value="Hardly ever"
                >Hardly ever</mat-button-toggle
              >
              <mat-button-toggle value="Yes, sometimes"
                >Yes, sometimes</mat-button-toggle
              >
              <mat-button-toggle value="Yes, very often"
                >Yes, very often</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >5. I have felt scared or panicky for no very good reason</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="feelingScared">
              <mat-button-toggle value="Yes, quite a lot"
                >Yes, quite a lot</mat-button-toggle
              >
              <mat-button-toggle value="Yes, sometimes"
                >Yes, sometimes</mat-button-toggle
              >
              <mat-button-toggle value="No, not much"
                >No, not much</mat-button-toggle
              >
              <mat-button-toggle value="No, not at all"
                >No, not at all</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >6. Things have been getting on top of me</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              [vertical]="true"
              style="width: 100%"
              formControlName="top"
            >
              <mat-button-toggle
                value="Yes, most of the time I haven't been able to cope at all"
                >Yes, most of the time I haven't been able to cope at
                all</mat-button-toggle
              >
              <mat-button-toggle
                value="Yes, sometimes I haven't been coping as well as usual"
                >Yes, sometimes I haven't been coping as well as
                usual</mat-button-toggle
              >
              <mat-button-toggle
                value="No, most of the time I have coped quite well"
                >No, most of the time I have coped quite well</mat-button-toggle
              >
              <mat-button-toggle value="No, I have been coping as well as ever"
                >No, I have been coping as well as ever</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >7. I have been so unhappy that I have had difficulty
            sleeping</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="difficultySleeping">
              <mat-button-toggle value="Yes, most of the time"
                >Yes, most of the time</mat-button-toggle
              >
              <mat-button-toggle value="Yes, sometimes"
                >Yes, sometimes</mat-button-toggle
              >
              <mat-button-toggle value="Not very often"
                >Not very often</mat-button-toggle
              >
              <mat-button-toggle value="No, not at all"
                >No, not at all</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >8. I have felt sad or miserable</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="sadness">
              <mat-button-toggle value="Yes, most of the time"
                >Yes, most of the time</mat-button-toggle
              >
              <mat-button-toggle value="Yes, sometimes"
                >Yes, sometimes</mat-button-toggle
              >
              <mat-button-toggle value="Not very often"
                >Not very often</mat-button-toggle
              >
              <mat-button-toggle value="No, not at all"
                >No, not at all</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >9. I have been so unhappy that I have been crying.</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="unhappy">
              <mat-button-toggle value="Yes, most of the time"
                >Yes, most of the time</mat-button-toggle
              >
              <mat-button-toggle value="Yes, quite often"
                >Yes, quite often</mat-button-toggle
              >
              <mat-button-toggle value="Only occasionally"
                >Only occasionally</mat-button-toggle
              >
              <mat-button-toggle value="No, never">No, never</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-12 asterix-after"
            >10. The thought of harming myself has occurred to me</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="harmingMyself">
              <mat-button-toggle value="Yes, quite often"
                >Yes, quite often</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Hardly ever"
                >Hardly ever</mat-button-toggle
              >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          postnatalDepressionForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!postnatalDepressionForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
