<app-intake-header sectionTitle="Assigned Clinician" [therapySession]="therapySession" [patient]="patient"
  [showPrintButton]="false"></app-intake-header>
<mat-divider></mat-divider>

<div style="padding: 10px">
  <mat-progress-bar mode="indeterminate" *ngIf="processing"></mat-progress-bar>

  <mat-error *ngIf="noActivePhysician">
    No active Clinician/Rendering Provider found for the selected patient
    facility. Please contact your administrator.
  </mat-error>

  <!-- Clinician Information -->
  <div *ngIf="therapySession?.physicianId">
    <div class="twoGridContainer">
      <label class="labelText" style="font-weight: 500">First Name </label>
      <label class="labelText">
        {{ therapySession?.physicianFirstName }}
      </label>

      <label class="labelText" style="font-weight: 500">Last Name </label>
      <label class="labelText">
        {{ therapySession?.physicianLastName }}
      </label>

      <label class="labelText" style="font-weight: 500">Email </label>
      <label class="labelText">{{ therapySession?.physicianEmail }}</label>

      <label class="labelText" style="font-weight: 500">Phone </label>
      <label class="labelText">{{
        therapySession?.physicianPhone | mask : '(000) 000-0000'
        }}</label>
    </div>
  </div>
</div>