<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Search Supervising Provider</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="row">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="processing"
    ></mat-progress-bar>

    <div
      class="col-md-12 table-responsive"
      *ngIf="!processing && !clinicianList"
    >
      <mat-error>
        No Active Rendering Provider found at the member's facility
      </mat-error>
    </div>

    <div
      class="col-md-12"
      *ngIf="clinicianList?.length > 0"
      style="margin-top: 15px; margin-bottom: 8px"
    >
      <div
        style="
          display: flex;
          align-items: center;
          padding: 5px;
          border-radius: 10px;
          border: 1px solid rgb(0, 0, 0, 0.2);
          background-color: #ffffff;
          padding: 5px;
        "
      >
        <span
          class="material-icons"
          style="text-align: left; font-size: 20px; opacity: 0.5"
        >
          search
        </span>
        <input
          type="search"
          placeholder="Supervisor Name"
          [(ngModel)]="searchBySPName"
          (ngModelChange)="filterRP($event)"
          style="
            padding-left: 15px;
            width: 100%;
            background: transparent;
            border: 0;
          "
        />
      </div>
    </div>

    <div class="col-md-12 table-responsive" *ngIf="clinicianList?.length > 0">
      <table mat-table [dataSource]="filteredClinicianList">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let row">
            <b>{{ row?.lastName }}, {{ row?.firstName }}</b>
          </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let row" style="max-width: 350px">
            {{ row.emailAddress }}
          </td>
        </ng-container>

        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef>Phone</th>
          <td mat-cell *matCellDef="let row">
            {{ row.phoneNumber | phoneFormat }}
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell *matCellDef="let row; let i = index">
            <button mat-raised-button color="warn" (click)="selectRP(row)">
              Select
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumn"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
      </table>
    </div>
  </div>
</div>
