import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { IShoppingAddiction } from 'src/app/_models/assessments/addiction/shoppingaddiction.model';
import {
  ShoppingAddictionApiService,
  ToastMessageService,
} from 'src/app/_services';

@Component({
  selector: 'app-shopping-addiction',
  templateUrl: './shopping-addiction.component.html',
  styleUrls: ['./shopping-addiction.component.css'],
})
export class ShoppingAddictionComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public shoppingAddictionApiService: ShoppingAddictionApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) {}
  public riskScore: number;
  public shoppingaddictionForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  shoppingaddiction: IShoppingAddiction;
  shoppingaddictionError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.shoppingaddictionForm, this.errors);
    this.loadShoppingAssessment();
  }

  loadShoppingAssessment() {
    this.shoppingaddictionForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.shoppingAddictionApiService
        .getShoppingAddictionDetails(this.assessmentId)
        .subscribe((shoppingaddiction) => {
          if (shoppingaddiction.data) {
            this.shoppingaddictionForm.controls[
              'shoppingIsMostImportant'
            ].setValue(shoppingaddiction.data.shoppingIsMostImportant.answer);
            this.shoppingaddictionForm.controls[
              'thinkAboutShoppingAllTheTime'
            ].setValue(
              shoppingaddiction.data.thinkAboutShoppingAllTheTime.answer
            );
            this.shoppingaddictionForm.controls[
              'planningOrThinkingOfShopping'
            ].setValue(
              shoppingaddiction.data.planningOrThinkingOfShopping.answer
            );
            this.shoppingaddictionForm.controls[
              'thoughtsAboutShopping'
            ].setValue(shoppingaddiction.data.thoughtsAboutShopping.answer);
            this.shoppingaddictionForm.controls['shopToFeelBetter'].setValue(
              shoppingaddiction.data.shopToFeelBetter.answer
            );
            this.shoppingaddictionForm.controls['shopToChangeTheMood'].setValue(
              shoppingaddiction.data.shopToChangeTheMood.answer
            );
            this.shoppingaddictionForm.controls[
              'forgetAboutPersonalProblems'
            ].setValue(
              shoppingaddiction.data.forgetAboutPersonalProblems.answer
            );
            this.shoppingaddictionForm.controls[
              'buyThingsInOrderToReduceFeelings'
            ].setValue(
              shoppingaddiction.data.buyThingsInOrderToReduceFeelings.answer
            );
            this.shoppingaddictionForm.controls[
              'negativelyAffectsYourActivities'
            ].setValue(
              shoppingaddiction.data.negativelyAffectsYourActivities.answer
            );
            this.shoppingaddictionForm.controls[
              'giveLessPriorityToOtherThings'
            ].setValue(
              shoppingaddiction.data.giveLessPriorityToOtherThings.answer
            );
            this.shoppingaddictionForm.controls[
              'ignoredPeopleBecauseOfShopping'
            ].setValue(
              shoppingaddiction.data.ignoredPeopleBecauseOfShopping.answer
            );
            this.shoppingaddictionForm.controls['argumentsWithOthers'].setValue(
              shoppingaddiction.data.argumentsWithOthers.answer
            );
            this.shoppingaddictionForm.controls[
              'increasingInclination'
            ].setValue(shoppingaddiction.data.increasingInclination.answer);
            this.shoppingaddictionForm.controls[
              'buyMuchMoreThanIntended'
            ].setValue(shoppingaddiction.data.buyMuchMoreThanIntended.answer);
            this.shoppingaddictionForm.controls[
              'obtainTheSameSatisfaction'
            ].setValue(shoppingaddiction.data.obtainTheSameSatisfaction.answer);
            this.shoppingaddictionForm.controls[
              'spendMoreTimeShoppingBuying'
            ].setValue(
              shoppingaddiction.data.spendMoreTimeShoppingBuying.answer
            );
            this.shoppingaddictionForm.controls[
              'triedToCutDownOnShopping'
            ].setValue(shoppingaddiction.data.triedToCutDownOnShopping.answer);
            this.shoppingaddictionForm.controls[
              'notListeningToOthers'
            ].setValue(shoppingaddiction.data.notListeningToOthers.answer);
            this.shoppingaddictionForm.controls[
              'haveDecidedToShopLless'
            ].setValue(shoppingaddiction.data.haveDecidedToShopLless.answer);
            this.shoppingaddictionForm.controls[
              'relapsedToExcessiveShopping'
            ].setValue(
              shoppingaddiction.data.relapsedToExcessiveShopping.answer
            );
            this.shoppingaddictionForm.controls[
              'becomeStressedIfObstructed'
            ].setValue(
              shoppingaddiction.data.becomeStressedIfObstructed.answer
            );
            this.shoppingaddictionForm.controls[
              'grumpyWhenUnableToShop'
            ].setValue(shoppingaddiction.data.grumpyWhenUnableToShop.answer);
            this.shoppingaddictionForm.controls['feelBadIfPrevented'].setValue(
              shoppingaddiction.data.feelBadIfPrevented.answer
            );
            this.shoppingaddictionForm.controls[
              'strongUrgeToBuyThings'
            ].setValue(shoppingaddiction.data.strongUrgeToBuyThings.answer);
            this.shoppingaddictionForm.controls['economicProblems'].setValue(
              shoppingaddiction.data.economicProblems.answer
            );
            this.shoppingaddictionForm.controls[
              'impairedYourWellBeing'
            ].setValue(shoppingaddiction.data.impairedYourWellBeing.answer);
            this.shoppingaddictionForm.controls[
              'worriedSoMuchAboutShopping'
            ].setValue(
              shoppingaddiction.data.worriedSoMuchAboutShopping.answer
            );
            this.shoppingaddictionForm.controls['poorConscience'].setValue(
              shoppingaddiction.data.poorConscience.answer
            );

            this.riskScore = shoppingaddiction.data.riskScore;
          }
        });
    }
  }
  onShoppingAddictionFormSubmit() {
    if (this.shoppingaddictionForm.invalid) {
      this.shoppingaddictionForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.shoppingaddiction = Object.assign({}, this.shoppingaddiction);
    this.shoppingaddiction.patientId =
      this.shoppingaddictionForm.controls['patientId'].value;
    this.shoppingaddiction.sessionId =
      this.shoppingaddictionForm.controls['sessionId'].value;
    this.shoppingaddiction.assessmentRequestId =
      this.shoppingaddictionForm.controls['assessmentRequestId'].value;

    this.shoppingaddiction.shoppingIsMostImportant = {
      answer:
        this.shoppingaddictionForm.controls['shoppingIsMostImportant'].value,
    };
    this.shoppingaddiction.thinkAboutShoppingAllTheTime = {
      answer:
        this.shoppingaddictionForm.controls['thinkAboutShoppingAllTheTime']
          .value,
    };
    this.shoppingaddiction.planningOrThinkingOfShopping = {
      answer:
        this.shoppingaddictionForm.controls['planningOrThinkingOfShopping']
          .value,
    };
    this.shoppingaddiction.thoughtsAboutShopping = {
      answer:
        this.shoppingaddictionForm.controls['thoughtsAboutShopping'].value,
    };
    this.shoppingaddiction.shopToFeelBetter = {
      answer: this.shoppingaddictionForm.controls['shopToFeelBetter'].value,
    };
    this.shoppingaddiction.shopToChangeTheMood = {
      answer: this.shoppingaddictionForm.controls['shopToChangeTheMood'].value,
    };
    this.shoppingaddiction.forgetAboutPersonalProblems = {
      answer:
        this.shoppingaddictionForm.controls['forgetAboutPersonalProblems']
          .value,
    };
    this.shoppingaddiction.buyThingsInOrderToReduceFeelings = {
      answer:
        this.shoppingaddictionForm.controls['buyThingsInOrderToReduceFeelings']
          .value,
    };
    this.shoppingaddiction.negativelyAffectsYourActivities = {
      answer:
        this.shoppingaddictionForm.controls['negativelyAffectsYourActivities']
          .value,
    };
    this.shoppingaddiction.giveLessPriorityToOtherThings = {
      answer:
        this.shoppingaddictionForm.controls['giveLessPriorityToOtherThings']
          .value,
    };
    this.shoppingaddiction.ignoredPeopleBecauseOfShopping = {
      answer:
        this.shoppingaddictionForm.controls['ignoredPeopleBecauseOfShopping']
          .value,
    };
    this.shoppingaddiction.argumentsWithOthers = {
      answer: this.shoppingaddictionForm.controls['argumentsWithOthers'].value,
    };
    this.shoppingaddiction.increasingInclination = {
      answer:
        this.shoppingaddictionForm.controls['increasingInclination'].value,
    };
    this.shoppingaddiction.buyMuchMoreThanIntended = {
      answer:
        this.shoppingaddictionForm.controls['buyMuchMoreThanIntended'].value,
    };
    this.shoppingaddiction.obtainTheSameSatisfaction = {
      answer:
        this.shoppingaddictionForm.controls['obtainTheSameSatisfaction'].value,
    };
    this.shoppingaddiction.spendMoreTimeShoppingBuying = {
      answer:
        this.shoppingaddictionForm.controls['spendMoreTimeShoppingBuying']
          .value,
    };
    this.shoppingaddiction.triedToCutDownOnShopping = {
      answer:
        this.shoppingaddictionForm.controls['triedToCutDownOnShopping'].value,
    };
    this.shoppingaddiction.notListeningToOthers = {
      answer: this.shoppingaddictionForm.controls['notListeningToOthers'].value,
    };
    this.shoppingaddiction.haveDecidedToShopLless = {
      answer:
        this.shoppingaddictionForm.controls['haveDecidedToShopLless'].value,
    };
    this.shoppingaddiction.relapsedToExcessiveShopping = {
      answer:
        this.shoppingaddictionForm.controls['relapsedToExcessiveShopping']
          .value,
    };
    this.shoppingaddiction.becomeStressedIfObstructed = {
      answer:
        this.shoppingaddictionForm.controls['becomeStressedIfObstructed'].value,
    };
    this.shoppingaddiction.grumpyWhenUnableToShop = {
      answer:
        this.shoppingaddictionForm.controls['grumpyWhenUnableToShop'].value,
    };
    this.shoppingaddiction.feelBadIfPrevented = {
      answer: this.shoppingaddictionForm.controls['feelBadIfPrevented'].value,
    };
    this.shoppingaddiction.strongUrgeToBuyThings = {
      answer:
        this.shoppingaddictionForm.controls['strongUrgeToBuyThings'].value,
    };
    this.shoppingaddiction.economicProblems = {
      answer: this.shoppingaddictionForm.controls['economicProblems'].value,
    };
    this.shoppingaddiction.impairedYourWellBeing = {
      answer:
        this.shoppingaddictionForm.controls['impairedYourWellBeing'].value,
    };
    this.shoppingaddiction.worriedSoMuchAboutShopping = {
      answer:
        this.shoppingaddictionForm.controls['worriedSoMuchAboutShopping'].value,
    };
    this.shoppingaddiction.poorConscience = {
      answer: this.shoppingaddictionForm.controls['poorConscience'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.shoppingaddiction.accessCode = this.accessCode;

      this.shoppingAddictionApiService
        .addGuestShoppingAddiction(this.shoppingaddiction)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Shopping Addiction Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Shopping Addiction Assessment'
            );
          }
        );
    } else {
      this.shoppingAddictionApiService
        .addShoppingAddiction(this.shoppingaddiction)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Shopping Addiction Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Shopping Addiction Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    shoppingIsMostImportant: [
      {
        type: 'required',
        message:
          'Shopping/buying is the most important thing in your life is required',
      },
    ],
    thinkAboutShoppingAllTheTime: [
      {
        type: 'required',
        message: 'Think about shopping/buying things all the time is required',
      },
    ],
    planningOrThinkingOfShopping: [
      {
        type: 'required',
        message:
          'Spend a lot of time planning or thinking of shopping/buying is required',
      },
    ],
    thoughtsAboutShopping: [
      {
        type: 'required',
        message:
          'Thoughts about shopping/buying keep popping in the head is required',
      },
    ],
    shopToFeelBetter: [
      { type: 'required', message: 'Shop in order to feel better is required' },
    ],
    shopToChangeTheMood: [
      {
        type: 'required',
        message: 'shop/buy things in order to change the mood is required',
      },
    ],
    forgetAboutPersonalProblems: [
      {
        type: 'required',
        message:
          'Shop/buy things in order to forget about personal problems is required',
      },
    ],
    buyThingsInOrderToReduceFeelings: [
      {
        type: 'required',
        message:
          'Shop/buy things in order to reduce feelings of guilt, anxiety, helplessness, loneliness or depression is required',
      },
    ],
    negativelyAffectsYourActivities: [
      {
        type: 'required',
        message:
          'Shop/buy so much that it negatively affects your daily routine/activities is required',
      },
    ],
    giveLessPriorityToOtherThings: [
      {
        type: 'required',
        message:
          'Give less priority to hobbies, leisure activities, job/studies, or exercise because of shopping/buying is required',
      },
    ],
    ignoredPeopleBecauseOfShopping: [
      {
        type: 'required',
        message:
          'Ignored your partner, family, and friends because of shopping/buying is required',
      },
    ],
    argumentsWithOthers: [
      {
        type: 'required',
        message:
          'Often end up in arguments with other because of shopping/buying is required',
      },
    ],
    increasingInclination: [
      {
        type: 'required',
        message:
          'Have an increasing inclination to shop/buy things is required',
      },
    ],
    buyMuchMoreThanIntended: [
      {
        type: 'required',
        message: 'Shop/buy much more than intended/planned is required',
      },
    ],
    obtainTheSameSatisfaction: [
      {
        type: 'required',
        message:
          'Need to shop/buy more and more to obtain the same satisfaction as before is required',
      },
    ],
    spendMoreTimeShoppingBuying: [
      {
        type: 'required',
        message: 'Spend more and more time shopping/buying is required',
      },
    ],
    triedToCutDownOnShopping: [
      {
        type: 'required',
        message:
          'Tried to cut down on shopping/buying without success is required',
      },
    ],
    notListeningToOthers: [
      {
        type: 'required',
        message:
          'Not listening to others when told to reduce shopping/buying is required',
      },
    ],
    haveDecidedToShopLless: [
      {
        type: 'required',
        message:
          'Have decided to shop/buy less, but have not been able to do so is required',
      },
    ],
    relapsedToExcessiveShopping: [
      {
        type: 'required',
        message:
          'Managed to limit shopping/buying for certain period but experienced relapse is required',
      },
    ],
    becomeStressedIfObstructed: [
      {
        type: 'required',
        message:
          'Become stressed if obstructed from shopping/buying things is required',
      },
    ],
    grumpyWhenUnableToShop: [
      {
        type: 'required',
        message:
          'Become sour and grumpy when unable to shop for some reasons is required',
      },
    ],
    feelBadIfPrevented: [
      {
        type: 'required',
        message:
          'Feel bad if prevented from shopping/buying things is required',
      },
    ],
    strongUrgeToBuyThings: [
      {
        type: 'required',
        message:
          'Feel a strong urge to shop/buy things if it has been a while since last shopped is required',
      },
    ],
    economicProblems: [
      {
        type: 'required',
        message: 'Shop/buy so much which caused economic problems is required',
      },
    ],
    impairedYourWellBeing: [
      {
        type: 'required',
        message:
          'Shop/buy so much that it has impaired your well-being is required',
      },
    ],
    worriedSoMuchAboutShopping: [
      {
        type: 'required',
        message:
          'Worried so much about shopping that it sometimes has made you sleepless is required',
      },
    ],
    poorConscience: [
      {
        type: 'required',
        message:
          'Have been bothered with poor conscience because of shopping/buying is required',
      },
    ],
  };

  private buildForm() {
    this.shoppingaddictionForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      shoppingIsMostImportant: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      thinkAboutShoppingAllTheTime: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      planningOrThinkingOfShopping: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      thoughtsAboutShopping: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      shopToFeelBetter: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      shopToChangeTheMood: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      forgetAboutPersonalProblems: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      buyThingsInOrderToReduceFeelings: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      negativelyAffectsYourActivities: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      giveLessPriorityToOtherThings: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      ignoredPeopleBecauseOfShopping: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      argumentsWithOthers: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      increasingInclination: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      buyMuchMoreThanIntended: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      obtainTheSameSatisfaction: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      spendMoreTimeShoppingBuying: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      triedToCutDownOnShopping: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      notListeningToOthers: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      haveDecidedToShopLless: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      relapsedToExcessiveShopping: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      becomeStressedIfObstructed: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      grumpyWhenUnableToShop: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      feelBadIfPrevented: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      strongUrgeToBuyThings: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      economicProblems: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      impairedYourWellBeing: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      worriedSoMuchAboutShopping: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      poorConscience: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
    });
    this.shoppingaddictionForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.shoppingaddictionForm) {
      return;
    }
    const form = this.shoppingaddictionForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
