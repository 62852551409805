<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Autism Spectrum Quotient Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="autismSpectrumQuotientForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >1. I prefer to do things with others rather than on my own.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q1">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >2. I prefer to do things the same way over and over again.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q2">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >3. If I try to imagine something, I find it very easy to create a
            picture in my mind.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q3">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >4. I frequently get so strongly absorbed in one thing that I lose
            sight of other things.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q4">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >5. I often notice small sounds when others do not.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q5">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >6. I usually notice car number plates or similar strings of
            information.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q6">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >7. Other people frequently tell me that what I’ve said is impolite,
            even though I think it is polite.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q7">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >8. When I’m reading a story, I can easily imagine what the
            characters might look like.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q8">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >9. I am fascinated by dates.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q9">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >10. In a social group, I can easily keep track of several different
            people’s conversations.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q10">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >11. I find social situations easy.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q11">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >12. I tend to notice details that others do not.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q12">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >13. I would rather go to a library than to a party.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q13">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >14. I find making up stories easy.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q14">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >15. I find myself drawn more strongly to people than to
            things.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q15">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >16. I tend to have very strong interests, which I get upset about
            if I can’t pursue.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q16">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >17. I enjoy social chitchat.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q17">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >18. When I talk, it isn’t always easy for others to get a word in
            edgewise.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q18">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >19. I am fascinated by numbers.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q19">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >20. When I’m reading a story, I find it difficult to work out the
            characters’ intentions.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q20">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >21. I don’t particularly enjoy reading fiction.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q21">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >22. I find it hard to make new friends.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q22">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >23. I notice patterns in things all the time.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q23">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >24. I would rather go to the theater than to a museum.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q24">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >25. It does not upset me if my daily routine is disturbed.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q25">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >26. I frequently find that I don’t know how to keep a conversation
            going.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q26">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >27. I find it easy to “read between the lines” when someone is
            talking to me.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q27">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >28. I usually concentrate more on the whole picture, rather than on
            the small details.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q28">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >29. I am not very good at remembering phone numbers.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q29">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >30. I don’t usually notice small changes in a situation or a
            person’s appearance.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q30">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >31. I know how to tell if someone listening to me is getting
            bored.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q31">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >32. I find it easy to do more than one thing at once.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q32">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >33. When I talk on the phone, I’m not sure when it’s my turn to
            speak.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q33">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >34. I enjoy doing things spontaneously.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q34">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >35. I am often the last to understand the point of a joke.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q35">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >36. I find it easy to work out what someone is thinking or feeling
            just by looking at their face.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q36">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >37. If there is an interruption, I can switch back to what I was
            doing very quickly.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q37">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >38. I am good at social chitchat.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q38">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >39. People often tell me that I keep going on and on about the same
            thing.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q39">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >40. When I was young, I used to enjoy playing games involving
            pretending with other children.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q40">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >41. I like to collect information about categories of things (e.g.,
            types of cars, birds, trains, plants).</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q41">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >42. I find it difficult to imagine what it would be like to be
            someone else.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q42">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >43. I like to carefully plan any activities I participate
            in.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q43">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >44. I enjoy social occasions.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q44">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >45. I find it difficult to work out people’s intentions.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q45">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >46. New situations make me anxious.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q46">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >47. I enjoy meeting new people.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q47">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >48. I am a good diplomat.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q48">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >49. I am not very good at remembering people’s date of
            birth.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q49">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-4 asterix-after"
            >50. I find it very easy to play games with children that involve
            pretending.</label
          >
          <div class="col-sm-8">
            <mat-button-toggle-group formControlName="q50">
              <mat-button-toggle value="Definitely Agree"
                >Definitely Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Agree"
                >Slightly Agree</mat-button-toggle
              >
              <mat-button-toggle value="Slightly Disagree"
                >Slightly Disagree</mat-button-toggle
              >
              <mat-button-toggle value="Definitely Disagree"
                >Definitely Disagree</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          autismSpectrumQuotientForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!autismSpectrumQuotientForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
