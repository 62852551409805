<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Toronto Empathy Questionnaire</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="torontoEmpathyForm"
    novalidate
    (ngSubmit)="onTorontoEmpathyAssessmentFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >1. When someone else is feeling excited, I tend to get excited
            too</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="getExcitedWhenOthersDo">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
            <mat-error
              *ngFor="
                let validation of validation_messages.getExcitedWhenOthersDo
              "
            >
              <mat-error
                class="error-message"
                *ngIf="
                  torontoEmpathyForm
                    .get('getExcitedWhenOthersDo')
                    .hasError(validation.type) &&
                  (torontoEmpathyForm.get('getExcitedWhenOthersDo').dirty ||
                    torontoEmpathyForm.get('getExcitedWhenOthersDo').touched)
                "
              >
                {{ validation.message }}</mat-error
              >
            </mat-error>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after"
            >2. Other people's misfortunes do not disturb me a great deal</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="othersMisfortunesDoNotDisturbMe"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            3. It upsets me to see someone being treated disrespectfully</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="upsetWhenOthersAreDisrespected"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            4. I remain unaffected when someone close to me is happy</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="unaffectedWhenOthersAreHappy"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            5. I enjoy making other people feel better</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="enjoyMakingOthersFeelBetter"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            6. I have tender, concerned feelings for people less fortunate than
            me</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="feelForOthersLessForunate"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            7. When a friend starts to talk about his\her problems, I try to
            steer the conversation towards something else</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="dontListenToFriendsProblems"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            8. I can tell when others are sad even when they do not say
            anything</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="canSenseWhenOthersBeingSad"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            9. I find that I am “in tune” with other people's moods</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="inTuneWithsOthersMoods">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            10. I do not feel sympathy for people who cause their own serious
            illnesses</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="dontFeelForPeopleCausingTheirOwnIllness"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            11. I become irritated when someone cries</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="getIrritatedWhenSomeoneCries"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            12. I am not really interested in how other people feel</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="dontCareAboutOthersFeelings"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            13. I get a strong urge to help when I see someone who is
            upset</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="urgeToHelpSomeoneUpset">
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            14. When I see someone being treated unfairly, I do not feel very
            much pity for them</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="dontFeelForUnfairlyTreatedPeople"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            15. I find it silly for people to cry out of happiness</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="feelSillyForPeopleToCryOutOfHappiness"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-sm-6 asterix-after">
            16. When I see someone being taken advantage of, I feel kind of
            protective towards him\her
          </label>
          <div class="col-sm-6">
            <mat-button-toggle-group
              formControlName="protectiveTowardsSomeoneTakenAdvantageOf"
            >
              <mat-button-toggle value="Never">Never</mat-button-toggle>
              <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
              <mat-button-toggle value="Sometimes">Sometimes</mat-button-toggle>
              <mat-button-toggle value="Often">Often</mat-button-toggle>
              <mat-button-toggle value="Always">Always</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          torontoEmpathyForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!torontoEmpathyForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
