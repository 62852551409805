import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ResponseModal } from '../../_models';

@Injectable({
  providedIn: 'root',
})
export class AssessmentApiService {
  constructor(private httpClient: HttpClient) {}

  getPatientAssessmentDetails(
    assessmentHistoryId: string
  ): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/patient-assessment-details/` + assessmentHistoryId
    );
  }

  getAssessmentDetails(assessmentType: string): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/assessment-details/` + assessmentType
    );
  }

  getAssessmentGuide(assessmentId: string): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/assessment-guide/` + assessmentId
    );
  }

  updateAssessment(assessment: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(`${environment.apiUrl}/assessment-details`, assessment, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updatePatientAssessment(assessment: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/patient-assessment-details`,
        assessment,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updatePatientAssessmentSummary(assessment: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/patient-assessment-summary`,
        assessment,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  printAssessment(
    assessmentType: string,
    assessmentId: string,
    patientId: string
  ): any {
    return this.httpClient
      .get(
        `${
          environment.apiUrl
        }/assessment-print?assessmentId=${assessmentId}&assessmentType=${encodeURIComponent(
          assessmentType
        )}&patientId=${patientId}`,
        { responseType: 'blob' }
      )
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }
}
