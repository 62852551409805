<div class="row" *ngIf="displayBreadCrumbs">
  <div class="col-md-12">
    <p style="font-size: 16px; font-weight: 450">
      <a routerLink="/" routerLinkActive="active">
        <em class="material-icons text_align-center" style="font-size: 18px; margin-top: -3px">home</em>
      </a>
      &nbsp; / &nbsp;
      <span *ngIf="selectedAssessmentType">
        <a routerLink="/config/playbooks">Assessment Care Plans</a>&nbsp; /
        &nbsp;
        {{ selectedAssessmentType }}
      </span>
      <span *ngIf="!selectedAssessmentType"> Assessment Care Plans</span>
    </p>
  </div>
</div>

<mat-progress-bar mode="indeterminate" *ngIf="selectedAssessmentType && isLoading"></mat-progress-bar>
<div *ngIf="selectedAssessmentType && questions && !isLoading">
  <form [formGroup]="answerForm" (keydown.enter)="$event.preventDefault()">
    <mat-card appearance="raised" class="pad20 mat-elevation-z8" *ngFor="let question of questions">
      <label class="col-md-12 asterix-after">{{ question.title }}</label>
      <div class="col-md-12">
        <label *ngIf="question.description">{{ question.description }}</label>
        <mat-tab-group>
          <mat-tab [label]="answer.value" *ngFor="let answer of question.answers">
            <div class="col-md-12">
              <br />
              <ng-container [formArrayName]="answer.id">
                <ol class="list-style">
                  <li *ngFor="
                      let questionCtrl of guidanceRecords(answer.id).controls;
                      index as i
                    ">
                    <div [formGroupName]="i">
                      <div>
                        <mat-form-field appearance="fill">
                          <textarea matInput formControlName="guidance" name="guidance"
                            (change)="onSubmit()"></textarea>
                        </mat-form-field>
                      </div>
                      <button mat-mini-fab color="warn" type="button" (click)="removeGuidanceRecord(answer.id, i)"
                        class="poll-remove-btn">
                        X
                      </button>
                    </div>
                  </li>
                </ol>
                <div fxLayout="row">
                  <button mat-raised-button class="btn btn-success" type="button"
                    (click)="addGuidanceRecord(answer.id, '')">
                    Add Record
                  </button>
                </div>
              </ng-container>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </mat-card>

    <div class="row" style="justify-content: flex-end; padding-right: 20px; margin: 20px">
      <button mat-raised-button color="warn" (click)="onSubmit()" [disabled]="processing">
        Save
      </button>
    </div>
  </form>
</div>