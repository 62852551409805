import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { IMemberIntakeHistory, Patient } from 'src/app/_models';
import {
  HealthConditionsApiService,
  ToastMessageService,
} from 'src/app/_services';
import { FamilyConditionsList } from 'src/app/shared/utilities/familyMedicalConditions';
import { MedicationFrequency } from 'src/app/shared/utilities/medicationFrequency';
import { MemberConditionsList } from 'src/app/shared/utilities/memberMedicalConditions';

@Component({
  selector: 'app-guest-intake-medical-history',
  templateUrl: './guest-intake-medical-history.component.html',
  styleUrl: './guest-intake-medical-history.component.css',
})
export class GuestIntakeMedicalHistoryComponent implements OnInit {
  @Input() requestId: string = '';
  @Input() accessCode: string = '';
  @Input() organizationId: string = '';
  @Input() consentRequestId: string = '';
  @Input() consentDocumentId: string = '';
  @Input() documentName: string = '';
  @Input() patient: Patient = null;

  memberConditionsList = MemberConditionsList;
  familyConditionsList = FamilyConditionsList;
  medicationFrequency = MedicationFrequency;

  processing: boolean = false;
  isModalWindow: boolean = true;

  action: string = '';

  newMemberHistoryList = [];
  newFamilyHistoryList = [];
  memberHealthHistoryForm: FormGroup;

  memberHealthIntakeHistory: IMemberIntakeHistory;

  constructor(
    @Optional() public dialogRef: MatDialogRef<GuestIntakeMedicalHistoryComponent> | null = null,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any = null,
    private formBuilder: FormBuilder,
    private healthConditionsApiService: HealthConditionsApiService,
    private toastMessageService: ToastMessageService
  ) {
    if (data) {
      this.patient = data.patient;
      this.requestId = data.requestId;
      this.accessCode = data.accessCode;
      this.organizationId = data.organizationId;
      this.consentRequestId = data.consentRequestId;
      this.consentDocumentId = data.consentDocumentId;
      this.documentName = data.documentName;
      this.action = data.action;
      this.memberHealthIntakeHistory = data.history;
    }else{
      this.isModalWindow = false;
    }
  }

  ngOnInit(): void {
    this.resetConditions();

    this.buildForm();

    if (this.action === 'VERIFY') {
      this.loadExistingDetails();
    }
  }

  resetConditions() {
    this.memberConditionsList.map((condition) => {
      condition.selected = false;
      return condition;
    });

    this.familyConditionsList.map((condition) => {
      condition.selected = false;
      return condition;
    });
  }

  loadExistingDetails() {
    //load Physicians
    this.memberHealthIntakeHistory?.physicians.forEach((physician) => {
      const control = <FormArray>this.memberHealthHistoryForm.get('physicians');
      control.push(
        new FormGroup({
          firstName: new FormControl(physician.firstName),
          lastName: new FormControl(physician.lastName),
          phone: new FormControl(
            physician.phone,
            Validators.compose([
              RxwebValidators.mask({ mask: '(999) 999-9999' }),
            ])
          ),
          email: new FormControl(
            physician.email,
            Validators.compose([Validators.email])
          ),
        })
      );
    });

    //load Medications
    this.memberHealthIntakeHistory?.medications.forEach((medications) => {
      const control = <FormArray>(
        this.memberHealthHistoryForm.get('medications')
      );

      control.push(
        new FormGroup({
          medicationName: new FormControl(medications.medicationName),
          dosage: new FormControl(medications.dosage),
          frequency: new FormControl(medications.frequency),
        })
      );
    });

    //load Allergies
    this.memberHealthIntakeHistory?.allergies.forEach((allergy) => {
      const control = <FormArray>this.memberHealthHistoryForm.get('allergies');

      control.push(
        new FormGroup({
          name: new FormControl(allergy.name),
          reaction: new FormControl(allergy.reaction),
        })
      );
    });

    this.memberHealthHistoryForm.controls['smoke'].setValue(
      this.memberHealthIntakeHistory?.smoke
    );
    this.memberHealthHistoryForm.controls['smokeFrequency'].setValue(
      this.memberHealthIntakeHistory?.smokeFrequency
    );
    this.memberHealthHistoryForm.controls['alcohol'].setValue(
      this.memberHealthIntakeHistory?.alcohol
    );
    this.memberHealthHistoryForm.controls['alcoholFrequency'].setValue(
      this.memberHealthIntakeHistory?.alcoholFrequency
    );

    this.memberHealthHistoryForm.controls['surgeries'].setValue(
      this.memberHealthIntakeHistory?.surgeries
    );
    this.memberHealthHistoryForm.controls['illness'].setValue(
      this.memberHealthIntakeHistory?.illness
    );

    if (this.memberHealthIntakeHistory?.lastMenstrualCycle) {
      this.memberHealthHistoryForm.controls['lastMenstrualCycle'].setValue(
        new Date(this.memberHealthIntakeHistory.lastMenstrualCycle)
      );
    }

    // For medical history
    this.memberConditionsList.forEach((condition) => {
      if (
        this.memberHealthIntakeHistory?.memberMedicalHistory.includes(
          condition.name
        )
      ) {
        this.newMemberHistoryList.push(condition);
      }
    });

    // For family history
    this.familyConditionsList.forEach((condition) => {
      if (
        this.memberHealthIntakeHistory?.memberFamilyHistory.includes(
          condition.name
        )
      ) {
        this.newFamilyHistoryList.push(condition);
      }
    });
  }

  buildForm() {
    this.memberHealthHistoryForm = this.formBuilder.group({
      physicians: this.formBuilder.array([]),
      medications: this.formBuilder.array([]),
      allergies: this.formBuilder.array([]),

      smoke: new FormControl(''),
      smokeFrequency: new FormControl(''),
      alcohol: new FormControl(''),
      alcoholFrequency: new FormControl(''),

      surgeries: new FormControl(''),
      illness: new FormControl(''),
      lastMenstrualCycle: new FormControl(''),
    });

    if (this.action != 'VERIFY') {
      this.addNewPhysician();
      this.addNewMedication();
      this.addNewAllergy();
    }
  }

  addNewPhysician() {
    const control = <FormArray>this.memberHealthHistoryForm.get('physicians');

    control.push(
      new FormGroup({
        firstName: new FormControl(''),
        lastName: new FormControl(''),
        phone: new FormControl(
          '',
          Validators.compose([RxwebValidators.mask({ mask: '(999) 999-9999' })])
        ),
        email: new FormControl('', Validators.compose([Validators.email])),
      })
    );
  }

  removePhysician(index) {
    const control = <FormArray>this.memberHealthHistoryForm.get('physicians');
    control.removeAt(index);
  }

  addNewMedication() {
    const control = <FormArray>this.memberHealthHistoryForm.get('medications');

    control.push(
      new FormGroup({
        medicationName: new FormControl(''),
        dosage: new FormControl(''),
        frequency: new FormControl(''),
      })
    );
  }

  removeMedication(index) {
    const control = <FormArray>this.memberHealthHistoryForm.get('medications');
    control.removeAt(index);
  }

  addNewAllergy() {
    const control = <FormArray>this.memberHealthHistoryForm.get('allergies');

    control.push(
      new FormGroup({
        name: new FormControl(''),
        reaction: new FormControl(''),
      })
    );
  }

  removeAllergy(index) {
    const control = <FormArray>this.memberHealthHistoryForm.get('allergies');
    control.removeAt(index);
  }

  selectDeselectMemberHistory(event, history) {
    if (event.checked) {
      if (!this.newMemberHistoryList.includes(history)) {
        this.newMemberHistoryList.push(history);
      }
    } else {
      if (this.newMemberHistoryList.includes(history)) {
        let index = this.newMemberHistoryList.indexOf(history);
        this.newMemberHistoryList.splice(index, 1);
      }
    }
  }

  selectDeselectFamilyHistory(event, history) {
    if (event.checked) {
      if (!this.newFamilyHistoryList.includes(history)) {
        this.newFamilyHistoryList.push(history);
      }
    } else {
      if (this.newFamilyHistoryList.includes(history)) {
        let index = this.newFamilyHistoryList.indexOf(history);
        this.newFamilyHistoryList.splice(index, 1);
      }
    }
  }

  smokeAnswerChanged(event) {
    if (event.value === 'Yes') {
      this.memberHealthHistoryForm
        .get('smokeFrequency')
        .addValidators(Validators.required);
      this.memberHealthHistoryForm
        .get('smokeFrequency')
        .updateValueAndValidity();
    } else {
      this.memberHealthHistoryForm.get('smokeFrequency').clearValidators();
      this.memberHealthHistoryForm
        .get('smokeFrequency')
        .updateValueAndValidity();
    }
  }

  alcoholAnswerChanged(event) {
    if (event.value === 'Yes') {
      this.memberHealthHistoryForm
        .get('alcoholFrequency')
        .addValidators(Validators.required);
      this.memberHealthHistoryForm
        .get('alcoholFrequency')
        .updateValueAndValidity();
    } else {
      this.memberHealthHistoryForm.get('alcoholFrequency').clearValidators();
      this.memberHealthHistoryForm
        .get('alcoholFrequency')
        .updateValueAndValidity();
    }
  }

  isMedicalHistoryChecked(condition) {
    return this.newMemberHistoryList.includes(condition);
  }

  isFamilyHistoryChecked(condition) {
    return this.newFamilyHistoryList.includes(condition);
  }

  submitForm() {
    let memberMedicalConditions = [];
    let familyMedicalConditions = [];

    this.newMemberHistoryList.forEach((history) => {
      memberMedicalConditions.push(history.name);
    });
    this.newFamilyHistoryList.forEach((history) => {
      familyMedicalConditions.push(history.name);
    });

    this.memberHealthIntakeHistory = Object.assign(
      {},
      this.memberHealthHistoryForm.value
    );
    this.memberHealthIntakeHistory = Object.assign(
      this.memberHealthIntakeHistory,
      this.memberHealthHistoryForm.value
    );

    this.memberHealthIntakeHistory.memberMedicalHistory =
      memberMedicalConditions;
    this.memberHealthIntakeHistory.memberFamilyHistory =
      familyMedicalConditions;

    this.processing = true;

    if (this.accessCode) {
      this.healthConditionsApiService
        .updateGuestIntakeMedicalHistoryDetails(
          this.requestId,
          this.accessCode,
          this.consentRequestId,
          this.memberHealthIntakeHistory
        )
        .subscribe({
          next: (response) => {
            this.processing = false;
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted medical history.'
            );
            this.dialogRef.close('success');
          },
          error: (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to submit the details'
            );
          },
        });
    } else {
      this.healthConditionsApiService
        .memberSelfUpdateMedicalConditions(
          this.consentRequestId,
          this.memberHealthIntakeHistory
        )
        .subscribe({
          next: (response) => {
            this.processing = false;
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted medical history.'
            );
            this.dialogRef.close('success');
          },
          error: (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to submit the details'
            );
          },
        });
    }
  }
}
