import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastMessageService {
  constructor(private toastr: ToastrService) {}

  displaySuccessMessage(message: string) {
    this.toastr.success('', message, {
      positionClass: 'toastr-position',
      progressBar: true,
      timeOut: 4000,
    });
  }

  displayInfoMessage(message: string) {
    this.toastr.info('', message, {
      positionClass: 'toastr-position',
      progressBar: true,
      timeOut: 4000,
    });
  }

  displayErrorMessage(message: string) {
    this.toastr.error('', message, {
      positionClass: 'toastr-position',
      progressBar: true,
      timeOut: 4000,
    });
  }

  displayWarnMessage(message: string) {
    this.toastr.warning('', message, {
      positionClass: 'toastr-position',
      progressBar: true,
      timeOut: 4000,
    });
  }
}
