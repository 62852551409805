import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NpiAPIService, ToastMessageService } from 'src/app/_services';

@Component({
  selector: 'app-lookup-npi',
  templateUrl: './lookup-npi.component.html',
  styleUrls: ['./lookup-npi.component.css'],
})
export class LookupNpiComponent implements OnInit {
  isProcessing: boolean = false;
  npiLoaded: boolean = false;

  searchNPI: string = '';
  responseCareProvider: any;
  type: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<LookupNpiComponent>,
    private toastMessageService: ToastMessageService,
    public npiAPIService: NpiAPIService
  ) {
    this.type = data.type;
    if (data.type === 'SEARCH') {
      this.searchNPI = data.npi;
    }
  }

  ngOnInit(): void {
    if (this.type === 'SEARCH') {
      this.search();
    }
  }

  search() {
    this.responseCareProvider = null;
    this.isProcessing = true;
    this.npiLoaded = false;

    this.npiAPIService.searchIndividualNPIRegistry(this.searchNPI).subscribe(
      (response) => {
        if (response && response.data) {
          this.responseCareProvider = response.data;
        }
        this.isProcessing = false;
        this.npiLoaded = true;
      },
      (error) => {
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to search the NPI Registry'
        );
        this.isProcessing = false;
      }
    );
  }

  isSearchValid() {
    if (!this.searchNPI || this.searchNPI.length != 10) {
      return false;
    }

    return true;
  }

  select() {
    this.dialogRef.close({
      type: 'success',
      details: this.responseCareProvider,
    });
  }
}
