import {
  NgxGpAutocompleteDirective,
  NgxGpAutocompleteOptions,
} from '@angular-magic/ngx-gp-autocomplete';
import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Countries, IPhysician } from 'src/app/_models';
import { PhysicianHttpService, ToastMessageService } from 'src/app/_services/';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { USStates } from 'src/app/shared/utilities/billing/states';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-add-physician-dialog',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AddPhysicianDialogComponent implements OnInit {
  @ViewChild('ngxPlaces') placesRef: NgxGpAutocompleteDirective;
  options: NgxGpAutocompleteOptions = {
    componentRestrictions: { country: ['US'] },
    types: ['address'],
  };

  constructor(
    public dialogRef: MatDialogRef<AddPhysicianDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public physicianApiService: PhysicianHttpService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private toastMessageService: ToastMessageService,
    private orgConfigSandbox: OrgConfigSandbox
  ) {
    this.action = data.action;
    this.physician = data.physician;
    this.patientId = data.patientId;
  }

  public patientId: string;
  public action: string;
  public physicianForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  physician: IPhysician;
  accountError: Error = null;
  errors: any = {};
  country: string = 'US';
  isIndianOrg: boolean = false;
  orgConfig: any;
  processing: boolean = false;
  usStates = USStates;

  public ngOnInit() {
    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          this.orgConfig = orgConfig;
          if (this.orgConfig?.organizationCountry === Countries.India) {
            this.isIndianOrg = true;
          }
        });
      }
    });

    this.buildForm();
    this.errorHandler.handleErrors(this.physicianForm, this.errors);

    if (this.action === 'EDIT') {
      this.physicianForm.controls['id'].setValue(this.physician.id);
      this.physicianForm.controls['organizationId'].setValue(
        this.physician.organizationId
      );
      this.physicianForm.controls['patientId'].setValue(
        this.physician.patientId
      );
      this.physicianForm.controls['firstName'].setValue(
        this.physician.firstName
      );
      this.physicianForm.controls['lastName'].setValue(this.physician.lastName);
      this.physicianForm.controls['speciality'].setValue(
        this.physician.speciality
      );
      this.physicianForm.controls['phone'].setValue(this.physician.phone);
      this.physicianForm.controls['fax'].setValue(this.physician.fax);
      this.physicianForm.controls['email'].setValue(this.physician.email);

      this.physicianForm.controls['hospitalName'].setValue(
        this.physician.hospitalName
      );
      this.physicianForm.controls['hospitalAddressLine1'].setValue(
        this.physician.hospitalAddressLine1
      );
      this.physicianForm.controls['hospitalAddressLine2'].setValue(
        this.physician.hospitalAddressLine2
      );
      this.physicianForm.controls['hospitalCity'].setValue(
        this.physician.hospitalCity
      );
      this.physicianForm.controls['hospitalState'].setValue(
        this.physician.hospitalState
      );
      this.physicianForm.controls['hospitalZip'].setValue(
        this.physician.hospitalZip
      );
      this.physicianForm.controls['hospitalPhoneNumber'].setValue(
        this.physician.hospitalPhoneNumber
      );

      this.physicianForm.controls['notes'].setValue(this.physician.notes);
    } else {
    }
  }

  onPhysicianFormSubmit() {
    if (this.physicianForm.invalid) {
      this.physicianForm.markAllAsTouched();
      return;
    }

    this.physician = Object.assign({}, this.physician);
    this.physician = Object.assign(this.physician, this.physicianForm.value);

    this.processing = true;
    if (this.action === 'EDIT') {
      this.physicianApiService.updatePhysician(this.physician).subscribe(
        () => {
          this.processing = false;
          this.toastMessageService.displaySuccessMessage(
            'Physician details have been updated'
          );
          this.dialogRef.close('success');
        },
        () => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to edit physician details'
          );
        }
      );
    } else {
      this.physician.patientId = this.patientId;
      this.physicianApiService.addPhysician(this.physician).subscribe(
        () => {
          this.processing = false;
          this.toastMessageService.displaySuccessMessage(
            'New physician has been added'
          );
          this.dialogRef.close('success');
        },
        () => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to edit physician details'
          );
        }
      );
    }
  }

  private buildForm() {
    this.physicianForm = this.formBuilder.group({
      id: new FormControl(null),
      caseId: new FormControl(null),
      patientId: new FormControl(null),
      organizationId: new FormControl(null),
      firstName: new FormControl('', Validators.compose([Validators.required])),
      lastName: new FormControl('', Validators.compose([Validators.required])),
      speciality: new FormControl(''),
      phone: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          RxwebValidators.mask({ mask: '(999) 999-9999' }),
        ])
      ),
      fax: new FormControl(
        '',
        Validators.compose([RxwebValidators.mask({ mask: '(999) 999-9999' })])
      ),
      email: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ])
      ),
      hospitalName: new FormControl(''),
      hospitalAddressLine1: new FormControl(''),
      hospitalAddressLine2: new FormControl(''),
      hospitalCity: new FormControl(''),
      hospitalState: new FormControl(''),
      hospitalZip: new FormControl(
        '',
        Validators.compose([RxwebValidators.mask({ mask: '99999' })])
      ),
      hospitalPhoneNumber: new FormControl(
        null,
        Validators.compose([
          this.isIndianOrg
            ? RxwebValidators.mask({ mask: '99999-99999' })
            : RxwebValidators.mask({ mask: '(999) 999-9999' }),
        ])
      ),
      notes: new FormControl(''),
    });

    this.physicianForm.valueChanges.subscribe(() => this.onValueChanged());
  }

  public validation_messages = {
    firstName: [
      { type: 'required', message: 'First Name is required' },
      {
        type: 'minlength',
        message: 'First Name cannot be less than 2 characters long',
      },
    ],
    lastName: [
      { type: 'required', message: 'Last Name is required' },
      {
        type: 'minlength',
        message: 'Last Name cannot be less than 2 characters long',
      },
    ],
    phone: [
      {
        type: 'pattern',
        message: 'Please enter a valid 10 digit US Phone Number',
      },
    ],
    fax: [
      {
        type: 'pattern',
        message: 'Please enter a valid 10 digit US Phone Number',
      },
    ],
    email: [{ type: 'pattern', message: 'Please enter a valid email address' }],
  };

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    // for (let el in this.physicianForm.controls) {
    //   if (this.physicianForm.controls[el].errors) {
    //     console.log(el)
    //   }
    // }

    if (!this.physicianForm) {
      return;
    }
    const form = this.physicianForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }

  handleAddressChange(address: google.maps.places.PlaceResult) {
    let addressField: string = '';

    for (const component of address.address_components as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number': {
          addressField = `${component.long_name} ${addressField}`;
          break;
        }

        case 'route': {
          addressField += component.long_name;
          break;
        }

        case 'locality':
          //city
          this.physicianForm.controls['hospitalCity'].setValue(
            component.long_name
          );
          break;

        case 'administrative_area_level_1': {
          //state
          this.physicianForm.controls['hospitalState'].setValue(
            component.short_name
          );
          break;
        }

        case 'postal_code': {
          this.physicianForm.controls['hospitalZip'].setValue(
            component.long_name
          );
          break;
        }
      }
    }
    if (addressField.trim() == '')
      addressField = address.formatted_address.split(',')[0];
    this.physicianForm.controls['hospitalAddressLine1'].setValue(addressField);
  }
}
