import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { CoreService } from '../_services';
import { OrgConfigSandbox } from '../shared/sandbox/org-config.sandbox';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected readonly router: Router,
    private orgConfigSandbox: OrgConfigSandbox,
    protected readonly coreService: CoreService,
    protected readonly keycloak: KeycloakService
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + '/',
      });
    }

    // If the url path is /
    if (state.url === '/' || state.url === '' || state.url === '/home') {
      if (this.coreService.isPhysician()) {
        this.router.navigate(['/provider-dashboard']);
      } else if (this.coreService.isFacilityDevice()) {
        this.router.navigate(['/facilityOTP']);
      } else if (this.coreService.isPatient()) {
        // If this is a patient
        this.coreService.isSSOUser()
          ? this.router.navigate(['/patientSSODashboard/Assessments'])
          : this.router.navigate(['/patientDashboard/Home']);
      } else {
        // For Non-Providers
        // Determine if the therapy session feature is there or not
        // and navigate to general dashboard or members page
        this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
          if (!response) {
            this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
              let hasTherapySessionFeature =
                orgConfig?.features.includes('THERAPY_MANAGEMENT');
              if (hasTherapySessionFeature) {
                this.router.navigate(['/general-dashboard']);
              } else {
                this.router.navigate(['/patients']);
              }
            });
          }
        });
      }
    }

    // Get the roles required from the route.
    const requiredRoles = route.data.roles;
    const excludeRoles = route.data?.excludeRoles;

    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }

    let roleValid = false;
    let excludeRoleExist = false;

    // First check for roles to exclude
    if (excludeRoles) {
      excludeRoles.forEach((element) => {
        if (this.roles.includes(element)) {
          excludeRoleExist = true;
        }
      });
    }

    if (excludeRoleExist) {
      return false;
    }

    // For loop for required roles
    for (let i = 0; i < requiredRoles.length; i++) {
      let currentRequiredRole = requiredRoles[i];

      if (this.roles.includes(currentRequiredRole)) {
        roleValid = true;
      }
    }

    return roleValid;
  }
}
