<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Connor-Davidson Resilience Scale(CD-RISC 10)</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="cdriscAssessmentForm"
    novalidate
    (ngSubmit)="onCdriscFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >1. I am able to adapt when changes occur.</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="adaptability">
              <mat-button-toggle value="Not true at all"
                >Not true at all</mat-button-toggle
              >
              <mat-button-toggle value="Rarely true"
                >Rarely true</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes true"
                >Sometimes true</mat-button-toggle
              >
              <mat-button-toggle value="Often true"
                >Often true</mat-button-toggle
              >
              <mat-button-toggle value="True nearly all the time"
                >True nearly all the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >2. I can deal with whatever comes my way.</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="resilience">
              <mat-button-toggle value="Not true at all"
                >Not true at all</mat-button-toggle
              >
              <mat-button-toggle value="Rarely true"
                >Rarely true</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes true"
                >Sometimes true</mat-button-toggle
              >
              <mat-button-toggle value="Often true"
                >Often true</mat-button-toggle
              >
              <mat-button-toggle value="True nearly all the time"
                >True nearly all the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >3. I try to see the humorous side of things when I am faced with
            problems.</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="senseOfHumor">
              <mat-button-toggle value="Not true at all"
                >Not true at all</mat-button-toggle
              >
              <mat-button-toggle value="Rarely true"
                >Rarely true</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes true"
                >Sometimes true</mat-button-toggle
              >
              <mat-button-toggle value="Often true"
                >Often true</mat-button-toggle
              >
              <mat-button-toggle value="True nearly all the time"
                >True nearly all the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >4. Having to cope with stress can make me stronger.</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="stressGrowth">
              <mat-button-toggle value="Not true at all"
                >Not true at all</mat-button-toggle
              >
              <mat-button-toggle value="Rarely true"
                >Rarely true</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes true"
                >Sometimes true</mat-button-toggle
              >
              <mat-button-toggle value="Often true"
                >Often true</mat-button-toggle
              >
              <mat-button-toggle value="True nearly all the time"
                >True nearly all the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >5. I tend to bounce back after illness, injury or other
            hardships.</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="recoveryAbility">
              <mat-button-toggle value="Not true at all"
                >Not true at all</mat-button-toggle
              >
              <mat-button-toggle value="Rarely true"
                >Rarely true</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes true"
                >Sometimes true</mat-button-toggle
              >
              <mat-button-toggle value="Often true"
                >Often true</mat-button-toggle
              >
              <mat-button-toggle value="True nearly all the time"
                >True nearly all the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >6. I believe I can achieve my goals, even if there are
            obstacles.</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="goalPersistence">
              <mat-button-toggle value="Not true at all"
                >Not true at all</mat-button-toggle
              >
              <mat-button-toggle value="Rarely true"
                >Rarely true</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes true"
                >Sometimes true</mat-button-toggle
              >
              <mat-button-toggle value="Often true"
                >Often true</mat-button-toggle
              >
              <mat-button-toggle value="True nearly all the time"
                >True nearly all the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >7. Under pressure, I stay focused and think clearly.</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="pressureFocus">
              <mat-button-toggle value="Not true at all"
                >Not true at all</mat-button-toggle
              >
              <mat-button-toggle value="Rarely true"
                >Rarely true</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes true"
                >Sometimes true</mat-button-toggle
              >
              <mat-button-toggle value="Often true"
                >Often true</mat-button-toggle
              >
              <mat-button-toggle value="True nearly all the time"
                >True nearly all the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >8. I am not easily discouraged by failure.</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="failureResilience">
              <mat-button-toggle value="Not true at all"
                >Not true at all</mat-button-toggle
              >
              <mat-button-toggle value="Rarely true"
                >Rarely true</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes true"
                >Sometimes true</mat-button-toggle
              >
              <mat-button-toggle value="Often true"
                >Often true</mat-button-toggle
              >
              <mat-button-toggle value="True nearly all the time"
                >True nearly all the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >9. I think of myself as a strong person when dealing with life's
            challenges and difficulties.</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="personalStrength">
              <mat-button-toggle value="Not true at all"
                >Not true at all</mat-button-toggle
              >
              <mat-button-toggle value="Rarely true"
                >Rarely true</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes true"
                >Sometimes true</mat-button-toggle
              >
              <mat-button-toggle value="Often true"
                >Often true</mat-button-toggle
              >
              <mat-button-toggle value="True nearly all the time"
                >True nearly all the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row pad10">
          <label class="col-md-3 asterix-after"
            >10. I am able to handle unpleasant or painful feelings like
            sadness, fear, and anger.</label
          >
          <div class="col-sm-9">
            <mat-button-toggle-group formControlName="emotionalManagement">
              <mat-button-toggle value="Not true at all"
                >Not true at all</mat-button-toggle
              >
              <mat-button-toggle value="Rarely true"
                >Rarely true</mat-button-toggle
              >
              <mat-button-toggle value="Sometimes true"
                >Sometimes true</mat-button-toggle
              >
              <mat-button-toggle value="Often true"
                >Often true</mat-button-toggle
              >
              <mat-button-toggle value="True nearly all the time"
                >True nearly all the time</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          cdriscAssessmentForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!cdriscAssessmentForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
