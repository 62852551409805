import { Component, Input } from '@angular/core';

import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { Patient } from 'src/app/_models/patient/patient';
import { ITherapySession } from 'src/app/_models/session/therapySession.model';
import { PrintDialogComponent } from '../../../session-notes/dialogs/print-dialog/print-dialog.component';

@Component({
  selector: 'app-intake-header',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatIconModule, MatButtonModule],
  templateUrl: './intake-header.component.html',
  styleUrl: './intake-header.component.css'
})
export class IntakeHeaderComponent {

  @Input() sectionTitle: string;
  @Input() patient: Patient;
  @Input() therapySession: ITherapySession;
  @Input() showPrintButton: boolean;

  constructor(
    public dialog: MatDialog,
    public router: Router
  ) {
  }

  printDialog() {
    this.dialog.open(PrintDialogComponent, {
      data: { therapySession: this.therapySession, patient: this.patient },
      autoFocus: false,
      disableClose: true,
      minWidth: '65vw',
    });
  }
}
