<mat-card-title>Member Contacts</mat-card-title>
<mat-divider></mat-divider>


<div style="margin-top: 20px; padding: 10px">

  <mat-chip-listbox #chipList multiple="false">
    <mat-chip-option [class]="
        activeTab === 'CONTACTS'
          ? 'selectedChipClass mat-elevation-z8'
          : 'activeChipClass mat-elevation-z8'
      " (click)="activeTab = 'CONTACTS'">
      Personal Contacts
    </mat-chip-option>
    <mat-chip-option [class]="
        activeTab === 'PHYSICIANS'
          ? 'selectedChipClass mat-elevation-z8'
          : 'activeChipClass mat-elevation-z8'
      " (click)="activeTab = 'PHYSICIANS'">
      Physicians
    </mat-chip-option>
  </mat-chip-listbox>

  <app-member-personal-contacts *ngIf="activeTab === 'CONTACTS'" [patient]="patient"
    [hasContactsViewAccess]="hasContactsViewAccess" [hasContactsAddAccess]="hasContactsAddAccess"
    [hasContactsEditAccess]="hasContactsEditAccess"
    [hasContactsDeleteAccess]="hasContactsDeleteAccess"></app-member-personal-contacts>

  <app-member-physicians *ngIf="activeTab === 'PHYSICIANS'" [patient]="patient"
    [hasPhysiciansViewAccess]="hasPhysiciansViewAccess" [hasPhysiciansAddAccess]="hasPhysiciansAddAccess"
    [hasPhysiciansEditAccess]="hasPhysiciansEditAccess"
    [hasPhysiciansDeleteAccess]="hasPhysiciansDeleteAccess"></app-member-physicians>
</div>