import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ITherapyNote } from 'src/app/_models/session/therapy-note';
import { environment } from '../../../environments/environment';
import { DataTablesModel, IPatientNote } from '../../_models';

@Injectable({ providedIn: 'root' })
export class PatientNotesHttpService {
  constructor(private httpClient: HttpClient) { }

  getCaseNotes(
    caseId: string,
    patientId: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('patientId', patientId);
    params = params.append('pageSize', pageSize);
    params = params.append('pageIndex', pageIndex);
    params = params.append('order', 'DESC');
    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/case/${caseId}/case-notes`,
      { params: params }
    );
  }

  getMemberNotes(
    patientId: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('patientId', patientId);
    params = params.append('pageSize', pageSize);
    params = params.append('pageIndex', pageIndex);
    params = params.append('order', 'DESC');
    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/getallmembernotes`,
      { params: params }
    );
  }



  addCaseNote(caseId: string, caseNote: IPatientNote): Observable<IPatientNote> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<IPatientNote>(
        `${environment.apiUrl}/case/${caseId}/case-note`,
        caseNote,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateCaseNote(caseId: string, caseNote: IPatientNote): Observable<IPatientNote> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<IPatientNote>(
        `${environment.apiUrl}/case/${caseId}/case-note`,
        caseNote,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  deleteCaseNote(
    caseId: string,
    note: IPatientNote
  ): Observable<IPatientNote> {
    return this.httpClient
      .delete<IPatientNote>(
        `${environment.apiUrl}/case/${caseId}/case-note/` + note.id
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
