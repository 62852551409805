import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
import { CareLog, Case, Countries } from 'src/app/_models';
import { CareLogHttpService } from 'src/app/_services';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';

@Component({
  selector: 'app-carelog-dialog-selector',
  templateUrl: './carelog-dialog-selector.component.html',
  styleUrls: ['./carelog-dialog-selector.component.css'],
})
export class CarelogDialogSelectorComponent implements OnInit {
  sessionId: any;
  patientId: any;
  logDate: Date;
  shift: number;
  goals: any[];
  carelog: CareLog;
  addingCustomNote: boolean = true;
  goalDrivenCare: boolean = true;
  orgConfig: any;
  isIndianOrg: boolean = false;

  public action: string;
  case: Case;
  initialAnswer: string = '';
  public initialForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CarelogDialogSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public careLogHttpService: CareLogHttpService,
    private formBuilder: FormBuilder,
    private dateTimeAdapter: DateTimeAdapter<any>,
    private orgConfigSandbox: OrgConfigSandbox
  ) {
    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          this.orgConfig = orgConfig;
          if (this.orgConfig?.organizationCountry === Countries.India) {
            this.isIndianOrg = true;
            dateTimeAdapter.setLocale('en-GB');
          }
        });
      }
    });

    this.action = data.action;
    this.case = data.case;
    this.patientId = data.patientId;
    this.sessionId = data.sessionId;
    this.logDate = data.logDate;
    this.shift = data.shift;
    if (data.initialAnswer) {
      this.initialAnswer = data.initialAnswer;
    }
    if (data.goals) {
      this.goals = data.goals.filter((goal) => goal.status !== 'DELETED');
    } else {
      this.goals = [];
    }

    this.carelog = data.carelog;
    this.goalDrivenCare = data.goalDrivenCare;
    if (this.carelog) this.addingCustomNote = false;
  }

  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm() {
    this.initialForm = this.formBuilder.group({
      logType: new FormControl(Validators.compose([Validators.required])),
    });
  }

  selectInitialAnswer(initialAnswer: string) {
    this.initialAnswer = initialAnswer;
    this.initialForm.controls['logType'].setValue(initialAnswer);
  }

  taskCompleted(hasChangeEvent: any) {
    if (hasChangeEvent) {
      this.dialogRef.close('success');
    }
  }
}
