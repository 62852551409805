<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Procedure Code</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="cptCodeForm" novalidate (ngSubmit)="oncptCodeFormSubmit()">
    <div class="row">
      <div class="col-md-12" style="margin-bottom: 12px">
        <label class="labelHeader">
          <mat-icon style="margin-right: 10px">local_atm</mat-icon> General
          Information
        </label>
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-6">
              <label class="labelText">
                CPT Code <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="cptCode"
                  name="cptCode"
                  required
                  readonly
                />

                <mat-error *ngIf="cptCodeForm.controls['cptCode'].invalid">
                  Please enter the CPT Code
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <label class="labelText"> Code Description </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="description"
                  name="description"
                />
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <label class="labelText">
                Rate <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <span matPrefix>$ &nbsp;</span>
                <input
                  matInput
                  formControlName="rate"
                  name="rate"
                  type="number"
                  required
                  min="0"
                />
                <mat-error *ngIf="cptCodeForm.controls['rate'].invalid">
                  Rate must be greater than $0
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <label class="labelText">
                Default Duration in minutes (min)
                <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="duration"
                  name="duration"
                  type="number"
                  required
                />
                <mat-error *ngIf="cptCodeForm.controls['duration'].invalid">
                  Please enter the duration
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <label class="labelText">
                Unit <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="defaultUnit"
                  name="defaultUnit"
                  type="number"
                  min="1"
                  required
                />
                <mat-error *ngIf="cptCodeForm.controls['defaultUnit'].invalid">
                  Unit must be greater than 1
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <label class="labelText">
                Diagnosis Pointer
                <span style="opacity: 0.5; font-size: 12px">
                  (Box 24E on the CMS1500)
                </span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="diagnosisPointer"
                  name="diagnosisPointer"
                  maxlength="4"
                />
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>
      <div class="col-md-12" style="margin-bottom: 12px">
        <label class="labelHeader">
          <mat-icon style="margin-right: 10px">edit_document</mat-icon>
          Modifiers
        </label>
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-6">
              <label class="labelText"> Modifier 1 </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="modifier1"
                  name="modifier1"
                  minlength="2"
                  maxlength="2"
                />

                <mat-error *ngIf="cptCodeForm.controls['modifier1'].invalid">
                  Please enter a valid modifier
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <label class="labelText"> Modifier 2 </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="modifier2"
                  name="modifier2"
                  minlength="2"
                  maxlength="2"
                />

                <mat-error *ngIf="cptCodeForm.controls['modifier2'].invalid">
                  Please enter a valid modifier
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <label class="labelText"> Modifier 3 </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="modifier3"
                  name="modifier3"
                  minlength="2"
                  maxlength="2"
                />

                <mat-error *ngIf="cptCodeForm.controls['modifier3'].invalid">
                  Please enter a valid modifier
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <label class="labelText"> Modifier 4 </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="modifier4"
                  name="modifier4"
                  minlength="2"
                  maxlength="2"
                />

                <mat-error *ngIf="cptCodeForm.controls['modifier4'].invalid">
                  Please enter a valid modifier
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>
      <div class="col-md-12" style="text-align: right">
        <button
          class="btn btn-primary"
          mat-raised-button
          type="submit"
          [disabled]="!cptCodeForm.valid || processing"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
