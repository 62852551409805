import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { ICsi } from 'src/app/_models/assessments/relationship-family/csi.model';
import {
  ToastMessageService
} from 'src/app/_services/';
import {
  CsiApiService
} from 'src/app/_services/assessments/relationship-family/csi.service';

@Component({
  selector: 'app-csi-assessment-dialog',
  templateUrl: './csi-assessment-dialog.component.html',
  styleUrls: ['./csi-assessment-dialog.component.css']
})
export class CsiAssessmentDialogComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public csiAssessmentApiService: CsiApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public csiAssessmentForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  csiAssessment: ICsi;
  csiAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  autoTicks = false;
  disabled = false;
  invert = false;
  max = 5;
  min = 0;
  showTicks = true;
  step = 1;
  thumbLabel = true;
  value = 5;
  vertical = false;
  tickInterval = 1;


  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.csiAssessmentForm, this.errors);

    this.loadCore10Assessment();
  }

  loadCore10Assessment() {
    this.csiAssessmentForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.csiAssessmentApiService
        .getCsiDetails(this.assessmentId)
        .subscribe((csiAssessment) => {
          if (csiAssessment.data) {

            this.csiAssessmentForm.controls['pleaseIndicateTheDegree'].setValue(csiAssessment.data.pleaseIndicateTheDegree.answer);
            this.csiAssessmentForm.controls['amountOfTimeSpent'].setValue(csiAssessment.data.amountOfTimeSpent.answer);
            this.csiAssessmentForm.controls['makingMajorDecisions'].setValue(csiAssessment.data.makingMajorDecisions.answer);
            this.csiAssessmentForm.controls['demonstrationsOfAffection'].setValue(csiAssessment.data.demonstrationsOfAffection.answer);
            this.csiAssessmentForm.controls['inGeneralHowOftenWell'].setValue(csiAssessment.data.inGeneralHowOftenWell.answer);
            this.csiAssessmentForm.controls['howOftenWishHadnt'].setValue(csiAssessment.data.howOftenWishHadnt.answer);
            this.csiAssessmentForm.controls['stillFeelStrongConnect'].setValue(csiAssessment.data.stillFeelStrongConnect.answer);
            this.csiAssessmentForm.controls['ifIHadMyLifeOver'].setValue(csiAssessment.data.ifIHadMyLifeOver.answer);
            this.csiAssessmentForm.controls['ourRelationshipIsStrong'].setValue(csiAssessment.data.ourRelationshipIsStrong.answer);
            this.csiAssessmentForm.controls['sometimesWonderIfElse'].setValue(csiAssessment.data.sometimesWonderIfElse.answer);
            this.csiAssessmentForm.controls['myRelationshipMakesHappy'].setValue(csiAssessment.data.myRelationshipMakesHappy.answer);
            this.csiAssessmentForm.controls['haveWarmComfortable'].setValue(csiAssessment.data.haveWarmComfortable.answer);
            this.csiAssessmentForm.controls['cantImagineEnding'].setValue(csiAssessment.data.cantImagineEnding.answer);
            this.csiAssessmentForm.controls['feelCanConfideIn'].setValue(csiAssessment.data.feelCanConfideIn.answer);
            this.csiAssessmentForm.controls['haveHadSecondThoughts'].setValue(csiAssessment.data.haveHadSecondThoughts.answer);
            this.csiAssessmentForm.controls['forMePartnerIsPerfect'].setValue(csiAssessment.data.forMePartnerIsPerfect.answer);
            this.csiAssessmentForm.controls['reallyFeelLikePartOf'].setValue(csiAssessment.data.reallyFeelLikePartOf.answer);
            this.csiAssessmentForm.controls['cannotImagineAnother'].setValue(csiAssessment.data.cannotImagineAnother.answer);
            this.csiAssessmentForm.controls['howRewardingIsYour'].setValue(csiAssessment.data.howRewardingIsYour.answer);
            this.csiAssessmentForm.controls['howWellDoesPartnerMeet'].setValue(csiAssessment.data.howWellDoesPartnerMeet.answer);
            this.csiAssessmentForm.controls['toWhatExtentHasMet'].setValue(csiAssessment.data.toWhatExtentHasMet.answer);
            this.csiAssessmentForm.controls['inGeneralHowSatisfied'].setValue(csiAssessment.data.inGeneralHowSatisfied.answer);
            this.csiAssessmentForm.controls['howGoodIsYourCompared'].setValue(csiAssessment.data.howGoodIsYourCompared.answer);
            this.csiAssessmentForm.controls['doYouEnjoyPartnersComp'].setValue(csiAssessment.data.doYouEnjoyPartnersComp.answer);
            this.csiAssessmentForm.controls['howOftenDoYouHaveFun'].setValue(csiAssessment.data.howOftenDoYouHaveFun.answer);
            this.csiAssessmentForm.controls['interestingOrBoring'].setValue(csiAssessment.data.interestingOrBoring.answer);
            this.csiAssessmentForm.controls['badOrGood'].setValue(csiAssessment.data.badOrGood.answer);
            this.csiAssessmentForm.controls['fullOrEmpty'].setValue(csiAssessment.data.fullOrEmpty.answer);
            this.csiAssessmentForm.controls['lonelyOrFriendly'].setValue(csiAssessment.data.lonelyOrFriendly.answer);
            this.csiAssessmentForm.controls['sturdyOrFragile'].setValue(csiAssessment.data.sturdyOrFragile.answer);
            this.csiAssessmentForm.controls['discouragingOrHopeful'].setValue(csiAssessment.data.discouragingOrHopeful.answer);
            this.csiAssessmentForm.controls['enjoyableOrMiserable'].setValue(csiAssessment.data.enjoyableOrMiserable.answer);

            this.riskScore = csiAssessment.data.riskScore;
          }
        });
    }
  }
  onCsiFormSubmit() {
    if (this.csiAssessmentForm.invalid) {
      this.csiAssessmentForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.csiAssessment = Object.assign({}, this.csiAssessment);
    this.csiAssessment.patientId =
      this.csiAssessmentForm.controls['patientId'].value;
    this.csiAssessment.sessionId =
      this.csiAssessmentForm.controls['sessionId'].value;
    this.csiAssessment.assessmentRequestId =
      this.csiAssessmentForm.controls['assessmentRequestId'].value;

    this.csiAssessment.pleaseIndicateTheDegree = { answer: this.csiAssessmentForm.controls['pleaseIndicateTheDegree'].value };
    this.csiAssessment.amountOfTimeSpent = { answer: this.csiAssessmentForm.controls['amountOfTimeSpent'].value };
    this.csiAssessment.makingMajorDecisions = { answer: this.csiAssessmentForm.controls['makingMajorDecisions'].value };
    this.csiAssessment.demonstrationsOfAffection = { answer: this.csiAssessmentForm.controls['demonstrationsOfAffection'].value };
    this.csiAssessment.inGeneralHowOftenWell = { answer: this.csiAssessmentForm.controls['inGeneralHowOftenWell'].value };
    this.csiAssessment.howOftenWishHadnt = { answer: this.csiAssessmentForm.controls['howOftenWishHadnt'].value };
    this.csiAssessment.stillFeelStrongConnect = { answer: this.csiAssessmentForm.controls['stillFeelStrongConnect'].value };
    this.csiAssessment.ifIHadMyLifeOver = { answer: this.csiAssessmentForm.controls['ifIHadMyLifeOver'].value };
    this.csiAssessment.ourRelationshipIsStrong = { answer: this.csiAssessmentForm.controls['ourRelationshipIsStrong'].value };
    this.csiAssessment.sometimesWonderIfElse = { answer: this.csiAssessmentForm.controls['sometimesWonderIfElse'].value };
    this.csiAssessment.myRelationshipMakesHappy = { answer: this.csiAssessmentForm.controls['myRelationshipMakesHappy'].value };
    this.csiAssessment.haveWarmComfortable = { answer: this.csiAssessmentForm.controls['haveWarmComfortable'].value };
    this.csiAssessment.cantImagineEnding = { answer: this.csiAssessmentForm.controls['cantImagineEnding'].value };
    this.csiAssessment.feelCanConfideIn = { answer: this.csiAssessmentForm.controls['feelCanConfideIn'].value };
    this.csiAssessment.haveHadSecondThoughts = { answer: this.csiAssessmentForm.controls['haveHadSecondThoughts'].value };
    this.csiAssessment.forMePartnerIsPerfect = { answer: this.csiAssessmentForm.controls['forMePartnerIsPerfect'].value };
    this.csiAssessment.reallyFeelLikePartOf = { answer: this.csiAssessmentForm.controls['reallyFeelLikePartOf'].value };
    this.csiAssessment.cannotImagineAnother = { answer: this.csiAssessmentForm.controls['cannotImagineAnother'].value };
    this.csiAssessment.howRewardingIsYour = { answer: this.csiAssessmentForm.controls['howRewardingIsYour'].value };
    this.csiAssessment.howWellDoesPartnerMeet = { answer: this.csiAssessmentForm.controls['howWellDoesPartnerMeet'].value };
    this.csiAssessment.toWhatExtentHasMet = { answer: this.csiAssessmentForm.controls['toWhatExtentHasMet'].value };
    this.csiAssessment.inGeneralHowSatisfied = { answer: this.csiAssessmentForm.controls['inGeneralHowSatisfied'].value };
    this.csiAssessment.howGoodIsYourCompared = { answer: this.csiAssessmentForm.controls['howGoodIsYourCompared'].value };
    this.csiAssessment.doYouEnjoyPartnersComp = { answer: this.csiAssessmentForm.controls['doYouEnjoyPartnersComp'].value };
    this.csiAssessment.howOftenDoYouHaveFun = { answer: this.csiAssessmentForm.controls['howOftenDoYouHaveFun'].value };
    this.csiAssessment.interestingOrBoring = { answer: "" + this.csiAssessmentForm.controls['interestingOrBoring'].value };
    this.csiAssessment.badOrGood = { answer: "" + this.csiAssessmentForm.controls['badOrGood'].value };
    this.csiAssessment.fullOrEmpty = { answer: "" + this.csiAssessmentForm.controls['fullOrEmpty'].value };
    this.csiAssessment.lonelyOrFriendly = { answer: "" + this.csiAssessmentForm.controls['lonelyOrFriendly'].value };
    this.csiAssessment.sturdyOrFragile = { answer: "" + this.csiAssessmentForm.controls['sturdyOrFragile'].value };
    this.csiAssessment.discouragingOrHopeful = { answer: "" + this.csiAssessmentForm.controls['discouragingOrHopeful'].value };
    this.csiAssessment.enjoyableOrMiserable = { answer: "" + this.csiAssessmentForm.controls['enjoyableOrMiserable'].value };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.csiAssessment.accessCode = this.accessCode;

      this.csiAssessmentApiService
        .addGuestCsi(this.csiAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted The Couples Satisfaction Index (CSI) Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting The Couples Satisfaction Index (CSI) Assessment'
            );
          }
        );
    } else {
      this.csiAssessmentApiService
        .addCsi(this.csiAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted The Couples Satisfaction Index (CSI) Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting The Couples Satisfaction Index (CSI) Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    'pleaseIndicateTheDegree': [
      { type: 'required', message: '1. Please indicate the degree of happiness, all things considered, of your relationship. is required' },
    ],
    'amountOfTimeSpent': [
      { type: 'required', message: '2. Amount of time spent together is required' },
    ],
    'makingMajorDecisions': [
      { type: 'required', message: '3. Making major decisions is required' },
    ],
    'demonstrationsOfAffection': [
      { type: 'required', message: '4. Demonstrations of affection is required' },
    ],
    'inGeneralHowOftenWell': [
      { type: 'required', message: '5. In general, how often do you think that things between you and your partner are going well? is required' },
    ],
    'howOftenWishHadnt': [
      { type: 'required', message: '6. How often do you wish you hadn’t gotten into this relationship? is required' },
    ],
    'stillFeelStrongConnect': [
      { type: 'required', message: '7. I still feel a strong connection with my partner is required' },
    ],
    'ifIHadMyLifeOver': [
      { type: 'required', message: '8. If I had my life to live over, I would marry (or live with/date) the same person is required' },
    ],
    'ourRelationshipIsStrong': [
      { type: 'required', message: '9. Our relationship is strong is required' },
    ],
    'sometimesWonderIfElse': [
      { type: 'required', message: '10. I sometimes wonder if there is someone else out there for me is required' },
    ],
    'myRelationshipMakesHappy': [
      { type: 'required', message: '11. My relationship with my partner makes me happy is required' },
    ],
    'haveWarmComfortable': [
      { type: 'required', message: '12. I have a warm and comfortable relationship with my partner is required' },
    ],
    'cantImagineEnding': [
      { type: 'required', message: '13. I can\'t imagine ending my relationship with my partner is required' },
    ],
    'feelCanConfideIn': [
      { type: 'required', message: '14. I feel that I can confide in my partner about virtually anything is required' },
    ],
    'haveHadSecondThoughts': [
      { type: 'required', message: '15. I have had second thoughts about this relationship recently is required' },
    ],
    'forMePartnerIsPerfect': [
      { type: 'required', message: '16. For me, my partner is the perfect romantic partner is required' },
    ],
    'reallyFeelLikePartOf': [
      { type: 'required', message: '17. I really feel like part of a team with my partner is required' },
    ],
    'cannotImagineAnother': [
      { type: 'required', message: '18. I cannot imagine another person making me as happy as my partner does is required' },
    ],
    'howRewardingIsYour': [
      { type: 'required', message: '19. How rewarding is your relationship with your partner? is required' },
    ],
    'howWellDoesPartnerMeet': [
      { type: 'required', message: '20. How well does your partner meet your needs? is required' },
    ],
    'toWhatExtentHasMet': [
      { type: 'required', message: '21. To what extent has your relationship met your original expectations? is required' },
    ],
    'inGeneralHowSatisfied': [
      { type: 'required', message: '22. In general, how satisfied are you with your relationship? is required' },
    ],
    'howGoodIsYourCompared': [
      { type: 'required', message: '23. How good is your relationship compared to most? is required' },
    ],
    'doYouEnjoyPartnersComp': [
      { type: 'required', message: '24. Do you enjoy your partner\'s company? is required' },
    ],
    'howOftenDoYouHaveFun': [
      { type: 'required', message: '25. How often do you and your partner have fun together? is required' },
    ],
    'interestingOrBoring': [
      { type: 'required', message: '26. INTERESTING or BORING is required' },
    ],
    'badOrGood': [
      { type: 'required', message: '27. BAD or GOOD is required' },
    ],
    'fullOrEmpty': [
      { type: 'required', message: '28. FULL or EMPTY is required' },
    ],
    'lonelyOrFriendly': [
      { type: 'required', message: '29. LONELY or FRIENDLY is required' },
    ],
    'sturdyOrFragile': [
      { type: 'required', message: '30. STURDY or FRAGILE is required' },
    ],
    'discouragingOrHopeful': [
      { type: 'required', message: '31. DISCOURAGING or HOPEFUL is required' },
    ],
    'enjoyableOrMiserable': [
      { type: 'required', message: '32. ENJOYABLE or MISERABLE is required' },
    ],
  };

  private buildForm() {
    this.csiAssessmentForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      pleaseIndicateTheDegree: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      amountOfTimeSpent: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      makingMajorDecisions: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      demonstrationsOfAffection: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      inGeneralHowOftenWell: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      howOftenWishHadnt: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      stillFeelStrongConnect: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      ifIHadMyLifeOver: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      ourRelationshipIsStrong: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      sometimesWonderIfElse: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      myRelationshipMakesHappy: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      haveWarmComfortable: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      cantImagineEnding: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      feelCanConfideIn: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      haveHadSecondThoughts: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      forMePartnerIsPerfect: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      reallyFeelLikePartOf: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      cannotImagineAnother: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      howRewardingIsYour: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      howWellDoesPartnerMeet: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      toWhatExtentHasMet: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      inGeneralHowSatisfied: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      howGoodIsYourCompared: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      doYouEnjoyPartnersComp: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      howOftenDoYouHaveFun: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      interestingOrBoring: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      badOrGood: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      fullOrEmpty: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      lonelyOrFriendly: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      sturdyOrFragile: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      discouragingOrHopeful: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      enjoyableOrMiserable: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
    });
    this.csiAssessmentForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.csiAssessmentForm) {
      return;
    }
    const form = this.csiAssessmentForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }

  getSliderTickInterval(): number | 'auto' {
    if (this.showTicks) {
      return this.autoTicks ? 'auto' : this.tickInterval;
    }
    return 0;
  }
}
