<mat-card-title>Family History</mat-card-title>
<mat-divider></mat-divider>

<div style="margin-top: 40px; padding: 10px">
  <form [formGroup]="asifamilyhistoryForm" novalidate (ngSubmit)="onAsiFamilyHistoryFormSubmit()">
    <div class="row">
      <!-- Question Box -->
      <div class="col-sm-12 col-xl-8">
        <!-- // Mother Side -->
        <mat-card appearance="raised" class="mat-elevation-z8" style="margin-bottom: 20px">
          <!-- Template -->
          <div class="row" style="margin-bottom: 20px">
            <div class="col-3">
              <label class="labelHeader">Mother's Side</label>
            </div>
            <div class="col-3">
              <label class="labelHeader">Alcohol</label>
            </div>
            <div class="col-3">
              <label class="labelHeader">Drug</label>
            </div>
            <div class="col-3">
              <label class="labelHeader">Psych</label>
            </div>
          </div>
          <!-- Questions -->
          <div class="row">
            <div class="col-3">
              <label class="labelText">H1. Grandmother</label>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="h1_alcohol" id="h1_alcohol" required />
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="h1_drug" id="h1_drug" required />
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="h1_psych" id="h1_psych" required />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-3">
              <label class="labelText">H2. Grandfather</label>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="h2_alcohol" id="h2_alcohol" required />
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput id="h2_drug" formControlName="h2_drug" required />
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="h2_psych" id="h2_psych" required />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-3">
              <label class="labelText">H3. Mother</label>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="h3_alcohol" id="h3_alcohol" required />
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput id="h3_drug" formControlName="h3_drug" required />
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="h3_psych" id="h3_psych" required />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-3">
              <label class="labelText">H4. Aunt</label>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="h4_alcohol" id="h4_alcohol" required />
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="h4_drug" id="h4_drug" required />
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="h4_psych" id="h4_psych" required />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-3">
              <label class="labelText">H5. Uncle</label>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="h5_alcohol" id="h5_alcohol" required />
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput id="h5_drug" formControlName="h5_drug" required />
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="h5_psych" id="h5_psych" required />
              </mat-form-field>
            </div>
          </div>
        </mat-card>

        <mat-card appearance="raised" class="mat-elevation-z8" style="margin-bottom: 20px">
          <!-- Template -->
          <div class="row" style="margin-bottom: 20px">
            <div class="col-3">
              <label class="labelHeader">Father's Side</label>
            </div>
            <div class="col-3">
              <label class="labelHeader">Alcohol</label>
            </div>
            <div class="col-3">
              <label class="labelHeader">Drug</label>
            </div>
            <div class="col-3">
              <label class="labelHeader">Psych</label>
            </div>
          </div>
          <!-- Questions -->
          <div class="row">
            <div class="col-3">
              <label class="labelText">H6. Grandmother</label>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="h6_alcohol" id="h6_alcohol" required />
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="h6_drug" id="h6_drug" required />
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="h6_psych" id="h6_psych" required />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-3">
              <label class="labelText">H7. Grandfather</label>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="h7_alcohol" id="h7_alcohol" required />
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput id="h7_drug" formControlName="h7_drug" required />
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="h7_psych" id="h7_psych" required />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-3">
              <label class="labelText">H8. Father</label>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="h8_alcohol" id="h8_alcohol" required />
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput id="h8_drug" formControlName="h8_drug" required />
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="h8_psych" id="h8_psych" required />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-3">
              <label class="labelText">H9. Aunt</label>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="h9_alcohol" id="h9_alcohol" required />
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="h9_drug" id="h9_drug" required />
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="h9_psych" id="h9_psych" required />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-3">
              <label class="labelText">H10. Uncle</label>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="h10_alcohol" id="h10_alcohol" required />
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput id="h10_drug" formControlName="h10_drug" required />
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="h10_psych" id="h10_psych" required />
              </mat-form-field>
            </div>
          </div>
        </mat-card>

        <mat-card appearance="raised" class="mat-elevation-z8" style="margin-bottom: 20px">
          <!-- Template -->
          <div class="row" style="margin-bottom: 20px">
            <div class="col-3">
              <label class="labelHeader">Siblings</label>
            </div>
            <div class="col-3">
              <label class="labelHeader">Alcohol</label>
            </div>
            <div class="col-3">
              <label class="labelHeader">Drug</label>
            </div>
            <div class="col-3">
              <label class="labelHeader">Psych</label>
            </div>
          </div>
          <!-- Questions -->
          <div class="row">
            <div class="col-3">
              <label class="labelText">H11. Brother</label>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="h11_alcohol" id="h11_alcohol" required />
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="h11_drug" id="h11_drug" required />
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="h11_psych" id="h11_psych" required />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-3">
              <label class="labelText">H12. Sister</label>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="h12_alcohol" id="h12_alcohol" required />
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput id="h12_drug" formControlName="h12_drug" required />
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <input matInput formControlName="h12_psych" id="h12_psych" required />
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- Comment Box -->
      <div class="col-sm-12 col-xl-4">
        <div class="row">
          <div class="col-sm-6">
            <mat-card class="custom-score-card mat-elevation-z8" appearance="raised">
              <label class="labelText">Clinician Score <span style="color: red">*</span></label>
              <mat-card-content>
                <mat-form-field appearance="outline" style="width:100px">
                  <input type="text" formControlName="clinicianScore" maxlength="1"
                    style="font-size:48px; font-weight: 900 !important" pattern="[0-9]" matInput required />
                </mat-form-field>
              </mat-card-content>
              <mat-error *ngFor="let validation of validation_messages.clinicianScore">
                <mat-error class="error-message" *ngIf="
                      asifamilyhistoryForm.get('clinicianScore').hasError(validation.type) &&
                      (asifamilyhistoryForm.get('clinicianScore').dirty ||
                        asifamilyhistoryForm.get('clinicianScore').touched)
                    ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-card>
          </div>
          <div class="col-sm-6">
            <mat-card class="custom-score-card mat-elevation-z8" appearance="raised">
              <label class="labelText" style="justify-content: center;">System Score</label>
              <mat-card-content>
                <span class="width:100px; font-size:48px; font-weight: 900 !important">
                  {{systemGeneratedScore}}
                </span>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div class="row" style="margin-top:15px">
          <div class="col-sm-12">

            <mat-card appearance="raised" class="mat-elevation-z8">
              <div class="row">
                <div class="col-md-12">
                  <label class="labelHeader">Comments</label>
                  <mat-form-field appearance="outline">
                    <textarea formControlName="comments" matInput rows="50"></textarea>
                  </mat-form-field>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </div>

    <mat-dialog-actions align="end">
      <button [class]="
          asifamilyhistoryForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        " mat-raised-button type="submit" [disabled]="!asifamilyhistoryForm.valid || processing">
        Submit
      </button>
    </mat-dialog-actions>
  </form>
</div>