import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { MbscModule } from '@mobiscroll/angular';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { NgxEditorModule } from 'ngx-editor';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgProgressModule } from 'ngx-progressbar';
import { CustomDirectivesModule } from 'src/app/_directives/custom-directives.module';
import { AngularMaterialModule } from 'src/app/material-imports';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { ClaimRemitsPaymentHistoryComponent } from './claim-remits-payment-history/claim-remits-payment-history.component';
import { RecordRemittanceSelectClaimComponent } from './record-remittance-select-claim/record-remittance-select-claim.component';
import { RecordRemittanceComponent } from './record-remittance/record-remittance.component';
import { RemittanceClaimsOverviewHeaderComponent } from './remittance-claims-overview-header/remittance-claims-overview-header.component';
import { RemittanceDetailsHeaderComponent } from './remittance-details-header/remittance-details-header.component';
import { RemittancesClaimsDetailsComponent } from './remittances-claims-details/remittances-claims-details.component';
import { RemittancesDetailsComponent } from './remittances-details/remittances-details.component';
import { RemittancesListComponent } from './remittances-list/remittances-list.component';
import { RemittancesRoutes } from './remittances.routing';

@NgModule({
  imports: [
    RouterModule.forChild(RemittancesRoutes),
    MbscModule,
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    PipesModule,
    AngularMaterialModule,
    DragDropModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    NgProgressModule,
    NgxGpAutocompleteModule,
    RxReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxEditorModule,
    CustomDirectivesModule,
  ],
  declarations: [
    RemittancesListComponent,
    RemittancesDetailsComponent,
    RemittanceDetailsHeaderComponent,
    RemittancesClaimsDetailsComponent,
    ClaimRemitsPaymentHistoryComponent,
    RemittanceClaimsOverviewHeaderComponent,
    RecordRemittanceComponent,
    RecordRemittanceSelectClaimComponent,
  ],
  providers: [provideNgxMask()],
  exports: [],
})
export class RemittancesModule {}
