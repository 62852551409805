<div class="container">
  <div class="dialogTitle" style="margin-bottom: 25px">
    <p matDialogTitle *ngIf="action === 'ADD'">Add Pharmacy</p>
    <p matDialogTitle *ngIf="action === 'EDIT'">Edit Pharmacy</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="memberpharmacyForm">
    <div class="row">
      <div class="col-md-12">
        <label class="labelText">
          Pharmacy Name<span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Pharmacy Name"
            formControlName="pharmacyName"
            name="pharmacyName"
            required
          />
          <mat-error
            *ngIf="memberpharmacyForm.controls['pharmacyName'].invalid"
          >
            Please enter the pharmacy name
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-8">
        <label class="labelText">
          Address Line 1 <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            [options]="options"
            ngx-gp-autocomplete
            #placesRef="ngx-places"
            placeholder="Address 1"
            formControlName="address1"
            (onAddressChange)="handleAddressChange($event)"
            name="address1"
          />
          <mat-error *ngIf="memberpharmacyForm.controls['address1'].invalid">
            Please enter the address line 1
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <label class="labelText">Address 2</label>
        <mat-form-field appearance="outline">
          <input matInput formControlName="address2" name="address2" />
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <label class="labelText">City <span style="color: red">*</span></label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="City"
            formControlName="city"
            name="city"
          />
          <mat-error *ngIf="memberpharmacyForm.controls['city'].invalid">
            Please enter a valid city
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <label class="labelText">State <span style="color: red">*</span></label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="State"
            formControlName="state"
            name="state"
          />
          <mat-error *ngIf="memberpharmacyForm.controls['state'].invalid">
            Please enter a valid state
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <label class="labelText">Zip <span style="color: red">*</span></label>
        <mat-form-field appearance="outline">
          <input matInput placeholder="Zip" formControlName="zip" name="zip" />
          <mat-error *ngIf="memberpharmacyForm.controls['zip'].invalid">
            Please enter a valid zip
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <label class="labelText">Phone</label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="(999) 999-9999"
            formControlName="phoneNumber"
            name="phoneNumber"
          />
          <mat-error *ngIf="memberpharmacyForm.controls['phoneNumber'].invalid">
            Please enter a valid phone number
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <label class="labelText">Fax</label>
        <mat-form-field appearance="outline">
          <input matInput formControlName="faxNumber" name="faxNumber" />
          <mat-error *ngIf="memberpharmacyForm.controls['faxNumber'].invalid">
            Please enter a valid fax number
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <label class="labelText">
          Status <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="status" placeholder="Status">
            <mat-option value="Active">Active</mat-option>
            <mat-option value="Inactive">Inactive</mat-option>
          </mat-select>
          <mat-error *ngIf="memberpharmacyForm.controls['status'].invalid">
            Please select a valid status
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <section class="checkbox-section">
          <mat-checkbox
            color="primary"
            class="checkbox-margin"
            formControlName="primaryPharmacy"
          >
            Mark this pharmacy as primary
          </mat-checkbox>
        </section>
      </div>

      <div class="col-md-12" style="text-align: right">
        <button
          color="primary"
          mat-raised-button
          type="button"
          [disabled]="!memberpharmacyForm.valid || processing"
          (click)="onMemberPharmacyFormSubmit()"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
