import { createSelector } from '@ngrx/store';
import { State } from '..';
import { UserSettingsPropsState } from '../state';

export const getUserSettingsProps = (state: State) => state.userSettingsProps;

export const getUserSettingsPropsData = createSelector(
  getUserSettingsProps,
  (state: UserSettingsPropsState) => state.userSettings
);

export const getUserSettingsPropsLoading = createSelector(
  getUserSettingsProps,
  (state: UserSettingsPropsState) => state.userSettingsLoading
);
