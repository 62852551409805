<mat-card-title>Psychiatric Status</mat-card-title>
<mat-divider></mat-divider>

<div style="margin-top: 40px; padding: 10px">
  <form [formGroup]="asipsychiatricForm" novalidate (ngSubmit)="onAsiPsychiatricFormSubmit()">
    <div class="row">
      <!-- Question Box -->
      <div class="col-sm-12 col-xl-8">
        <mat-card appearance="raised" class="mat-elevation-z8" style="margin-bottom: 20px">
          <div class="col-md-12">
            <label class="labelHeader">How important times have you been treated for any psychological
              or emotional problems:</label>
            <div class="row">
              <div class="col-9">
                <label class="labelText breakWord">P1. In a hospital or inpatient setting?</label>
              </div>
              <div class="col-3">
                <mat-form-field appearance="outline">
                  <input matInput formControlName="p1" id="p1" required />
                </mat-form-field>
              </div>

              <div class="col-9">
                <label class="labelText breakWord">P2. Outpatient/private patient?
                </label>
              </div>
              <div class="col-3">
                <mat-form-field appearance="outline">
                  <input matInput formControlName="p2" id="p2" required />
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="col-md-12 pad10">
            <label class="labelText breakWord">P3. Do you receive a pension for a psychiatric disability?</label>
            <br />
            <mat-button-toggle-group formControlName="p3">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-md-12">
            <label class="labelHeader">Have you had a significant period of time (that was not a direct
              result of drug/alcohol use) in which you have:</label>
            <div class="row">
              <!-- Template -->
              <div class="col-6"></div>
              <div class="col-3">
                <label class="labelHeader breakWord">Past 30 Days</label>
              </div>
              <div class="col-3">
                <label class="labelHeader breakWord">In Your Life</label>
              </div>
              <!-- Questions -->
              <div class="col-6">
                <label class="labelText breakWord">P4. Experienced serious depression</label>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="p4_past30">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="p4_inlife">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>

              <div class="col-6">
                <label class="labelText breakWord">P5. Experienced serious anxiety or tension</label>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="p5_past30">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="p5_inlife">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>

              <div class="col-6">
                <label class="labelText breakWord">P6. Experienced hallucinations</label>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="p6_past30">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="p6_inlife">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>

              <div class="col-6">
                <label class="labelText breakWord">P7. Experienced trouble understanding, concentrating or
                  remembering</label>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="p7_past30">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="p7_inlife">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>

              <div class="col-6">
                <label class="labelText breakWord">P8. Experienced trouble controlling violent behavior
                  including episodes or rage or violence</label>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="p8_past30">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="p8_inlife">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>

              <div class="col-6">
                <label class="labelText breakWord">P9. Experienced serious thoughts of suicide</label>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="p9_past30">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="p9_inlife">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>

              <div class="col-6">
                <label class="labelText breakWord">P10. Attempted suicide
                </label>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="p10_past30">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="p10_inlife">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>

              <div class="col-6">
                <label class="labelText breakWord">P11. Been prescribed medication for any psychological or
                  emotional problems
                </label>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="p11_past30">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="col-3">
                <mat-button-toggle-group formControlName="p11_inlife">
                  <mat-button-toggle value="No">No</mat-button-toggle>
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <label class="labelText breakWord">P12. How many days in the past 30 have you experienced these
              psychological or emotional problems?</label>

            <mat-form-field appearance="outline">
              <input matInput formControlName="p12" id="p12" required />
            </mat-form-field>
          </div>

          <div class="col-md-12 pad10">
            <label class="labelText breakWord">P13. How much have you been troubled or bothered by these
              psychological or emotional problems in the past 30 days?
            </label>
            <br />
            <mat-button-toggle-group formControlName="p13">
              <mat-button-toggle value="Not at all">Not at all</mat-button-toggle>
              <mat-button-toggle value="Slightly">Slightly</mat-button-toggle>
              <mat-button-toggle value="Moderately">Moderately</mat-button-toggle>
              <mat-button-toggle value="Considerably">Considerably</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-md-12 pad10">
            <label class="labelText breakWord">P14. How important to you now is treatment for these
              psychological problems?
            </label>
            <br />
            <mat-button-toggle-group formControlName="p14">
              <mat-button-toggle value="Not at all">Not at all</mat-button-toggle>
              <mat-button-toggle value="Slightly">Slightly</mat-button-toggle>
              <mat-button-toggle value="Moderately">Moderately</mat-button-toggle>
              <mat-button-toggle value="Considerably">Considerably</mat-button-toggle>
              <mat-button-toggle value="Extremely">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-md-12 pad10">
            <label class="labelText breakWord">P15. Obviously depressed/withdrawn</label>
            <br />
            <mat-button-toggle-group formControlName="p15">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-md-12 pad10">
            <label class="labelText breakWord">P16. Obviously hostile</label>
            <br />
            <mat-button-toggle-group formControlName="p16">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-md-12 pad10">
            <label class="labelText breakWord">P17. Obviously anxious/nervous</label>
            <br />
            <mat-button-toggle-group formControlName="p17">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-md-12 pad10">
            <label class="labelText breakWord">P18. Having trouble with reality testing, thought disorders,
              paranoid thinking
            </label>
            <br />
            <mat-button-toggle-group formControlName="p18">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-md-12 pad10">
            <label class="labelText breakWord">P19. Having trouble comprehending, concerntrating,
              remembering</label>
            <br />
            <mat-button-toggle-group formControlName="p19">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-md-12 pad10">
            <label class="labelText breakWord">P20. Having suicical thoughts</label>
            <br />
            <mat-button-toggle-group formControlName="p20">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-md-12">
            <label class="labelText breakWord">P21. How would you rate the patient's need for
              psychiatric/psychological treatment?</label>
            <mat-form-field appearance="outline">
              <input matInput formControlName="p21" id="p21" required />
            </mat-form-field>
          </div>

          <div class="col-md-12">
            <label class="labelHeader">Is the above information significantly distored by:</label>
          </div>

          <div class="col-md-12 pad10">
            <label class="labelText breakWord">P22. Client's misrepresentation?</label>
            <br />
            <mat-button-toggle-group formControlName="p22">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="col-md-12 pad10">
            <label class="labelText breakWord">P23. Client's inability to understand?</label>
            <br />
            <mat-button-toggle-group formControlName="p23">
              <mat-button-toggle value="No">No</mat-button-toggle>
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </mat-card>
      </div>
      <!-- Comment Box -->
      <div class="col-sm-12 col-xl-4">
        <div class="row">
          <div class="col-sm-6">
            <mat-card class="custom-score-card mat-elevation-z8" appearance="raised">
              <label class="labelText">Clinician Score <span style="color: red">*</span></label>
              <mat-card-content>
                <mat-form-field appearance="outline" style="width:100px">
                  <input type="text" formControlName="clinicianScore" maxlength="1"
                    style="font-size:48px; font-weight: 900 !important" pattern="[0-9]" matInput required />
                </mat-form-field>
              </mat-card-content>
              <mat-error *ngFor="let validation of validation_messages.clinicianScore">
                <mat-error class="error-message" *ngIf="
                      asipsychiatricForm.get('clinicianScore').hasError(validation.type) &&
                      (asipsychiatricForm.get('clinicianScore').dirty ||
                        asipsychiatricForm.get('clinicianScore').touched)
                    ">{{ validation.message }}</mat-error>
              </mat-error>
            </mat-card>
          </div>
          <div class="col-sm-6">
            <mat-card class="custom-score-card mat-elevation-z8" appearance="raised">
              <label class="labelText" style="justify-content: center;">System Score</label>
              <mat-card-content>
                <span class="width:100px; font-size:48px; font-weight: 900 !important">
                  {{systemGeneratedScore}}
                </span>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div class="row" style="margin-top:15px">
          <div class="col-sm-12">

            <mat-card appearance="raised" class="mat-elevation-z8">
              <div class="row">
                <div class="col-md-12">
                  <label class="labelHeader">Comments</label>
                  <mat-form-field appearance="outline">
                    <textarea formControlName="comments" matInput rows="50"></textarea>
                  </mat-form-field>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end">
      <button [class]="
          asipsychiatricForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        " mat-raised-button type="submit" [disabled]="!asipsychiatricForm.valid || processing">
        Submit
      </button>
    </mat-dialog-actions>
  </form>
</div>