import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { DataTablesModel, ITherapySession } from 'src/app/_models';
import {
  DateTimeZoneService,
  TherapySessionService,
  ToastMessageService,
} from 'src/app/_services';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-load-existing-clinical-note',
  templateUrl: './load-existing-clinical-note.component.html',
  styleUrl: './load-existing-clinical-note.component.css',
})
export class LoadExistingClinicalNoteComponent implements OnInit {
  @ViewChild('pdfViewerOnDemand', { static: false }) pdfViewerOnDemand;
  @ViewChild(MatStepper) stepper: MatStepper;

  therapySession: ITherapySession;
  groupAppointment: boolean;
  selectedMemberId: string;
  allGroupMember: boolean = false;

  displayedColumns = ['session', 'action'];
  pdfViewOpen: boolean = false;
  pdfSrc = null;
  selectedNoteId: string = '';

  isProcessing: boolean = false;
  isLatestLoading: boolean = false;
  isLoadingPDF: boolean = false;
  pastNotesData: DataTablesModel = {} as DataTablesModel;
  currentTimezone: string;

  category = new FormControl('', Validators.required);

  constructor(
    public dialogRef: MatDialogRef<LoadExistingClinicalNoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public therapysessionApiService: TherapySessionService,
    private toastMessageService: ToastMessageService,
    private dateTimeZoneService: DateTimeZoneService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
    this.therapySession = data.therapySession;
    this.groupAppointment = data?.groupAppointment;
    this.selectedMemberId = data?.selectedMemberId;
    this.allGroupMember = data?.allGroupMember;
  }

  ngOnInit(): void {
    if (!this.allGroupMember) {
      this.loadPastNoteData();
    } else {
      this.loadPastGroupNoteData();
    }
  }

  loadPastGroupNoteData() {
    this.isProcessing = true;

    this.therapysessionApiService
      .getPastGroupClinicalNotes(this.therapySession.id)
      .subscribe({
        next: (response) => {
          if (response) {
            this.pastNotesData.items = response.items;
            this.pastNotesData.total = response.total;
          } else {
            this.pastNotesData.items = [];
            this.pastNotesData.total = 0;
          }
          this.isProcessing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load past clinical notes'
          );
          this.isProcessing = false;
        },
      });
  }

  loadPastNoteData() {
    this.isProcessing = true;

    this.therapysessionApiService
      .getPastClinicalNotesData(this.therapySession.id, this.selectedMemberId)
      .subscribe({
        next: (response) => {
          if (response) {
            this.pastNotesData.items = response.items;
            this.pastNotesData.total = response.total;
          } else {
            this.pastNotesData.items = [];
            this.pastNotesData.total = 0;
          }
          this.isProcessing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load past clinical notes'
          );
          this.isProcessing = false;
        },
      });
  }

  viewNote(note) {
    this.selectedNoteId = note.id;
    this.isLoadingPDF = true;
    this.pdfViewOpen = true;

    this.therapysessionApiService
      .previewClinicalNotesDocument(note.id, note?.patientId)
      .subscribe({
        next: (response) => {
          if (response) {
            this.pdfSrc = response;

            setTimeout(() => {
              this.pdfViewerOnDemand.refresh();
              this.isLoadingPDF = false;
            }, 400);
          } else {
            this.pdfSrc = null;

            setTimeout(() => {
              this.pdfViewerOnDemand.refresh();
              this.isLoadingPDF = false;
            }, 400);
          }
        },
        error: (error) => {
          this.isLoadingPDF = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to generate the note view'
          );
        },
      });
  }

  copyNote(note) {
    let confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Please confirm',
        msg:
          'Are you sure you want to copy the note from ' +
          new Date(note.start).toLocaleDateString() +
          ` - ${note.visitReasonName}, and current note changes may be lost. Do you want to continue?`,
        yesButtonTitle: 'Yes',
        noButtonTitle: 'Cancel',
      },
      autoFocus: false,
    });

    confirmDialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.dialogRef.close({
          type: 'success',
          sessionNotes: note.sessionNotes,
        });
      }
    });
  }

  selectOption(option) {
    this.category.setValue(option);

    if (option === 'PickAndChoose') {
      this.stepper.next();
    } else {
      this.getLatestForMemberSections();
    }
  }

  getLatestForMemberSections() {
    this.isLatestLoading = true;
    // getLatestForMemberNoteSections
    this.therapysessionApiService
      .getLatestForMemberNoteSections(
        this.groupAppointment
          ? this.selectedMemberId
          : this.therapySession.patientId
      )
      .subscribe({
        next: (response) => {
          // Now we need to build the payload
          if (response && response.data) {
            if (response.data?.noteSectionContents) {
              // Now build the payload
              let keys = Object.keys(response.data?.noteSectionContents);
              let sections = [];
              keys.forEach((key) => {
                sections.push({
                  id: key,
                  payload: response.data?.noteSectionContents[key],
                });
              });

              // Now all sections have been constructed
              this.dialogRef.close({
                type: 'success',
                sessionNotes: { sections: sections },
              });
            } else {
              this.toastMessageService.displayWarnMessage(
                'No past clinical note sections found for this member.'
              );
            }
          } else {
            this.toastMessageService.displayWarnMessage(
              'No past clinical note sections found for this member.'
            );
          }
          this.isLatestLoading = false;
        },
        error: (error) => {
          this.isLatestLoading = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load the latest section contents'
          );
        },
      });
  }
}
