import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataTablesModel, ResponseModal } from 'src/app/_models';
import { IFormResponse } from 'src/app/_models/custom-forms/formresponse.model';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class FormResponseApiService {
  constructor(private httpClient: HttpClient) {}
  getFormResponseList(
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.customFormsUrl}/formresponselist`,
      { params: params }
    );
  }

  getFormResponseDetails(formresponseId: string): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.customFormsUrl}/formresponse/` + formresponseId
    );
  }

  addFormResponse(
    id: string,
    thisFormResponse: IFormResponse,
    hasAttachments: boolean
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/memberintake/customform/${id}/${hasAttachments}`,
        thisFormResponse,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  addGuestFormResponse(
    documentId: string,
    thisFormResponse: IFormResponse,
    hasAttachments: boolean,
    requestId: string,
    accessCode: string
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/guestintake/customform/${documentId}/${requestId}/${accessCode}/${hasAttachments}`,
        thisFormResponse,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
