<mat-card appearance="raised" style="margin-top: 10px; margin-bottom: 20px">
  <div class="row">
    <mat-card-title>Documents</mat-card-title>

    <!-- Search box -->
    <div class="row">
      <div class="col-sm-12 col-md-8" style="margin-bottom: 15px">
        <div
          class="col"
          style="
            display: flex;
            align-items: center;
            padding: 5px;
            border-radius: 10px;
            border: 1px solid rgb(0, 0, 0, 0.2);
            background-color: #ffffff;
            padding: 5px;
            min-width: 140px;
            max-width: 450px;
          "
        >
          <span
            class="material-icons"
            style="text-align: left; font-size: 20px; opacity: 0.5"
          >
            search
          </span>
          <input
            type="search"
            placeholder="Document Name"
            [(ngModel)]="searchDocumentName"
            (ngModelChange)="searchChanged()"
            style="
              padding-left: 15px;
              width: 100%;
              background: transparent;
              border: 0;
            "
          />
        </div>
      </div>
      <div
        class="col-sm-12 col-md-4"
        *ngIf="patient.status === 'Active'"
        style="
          column-gap: 20px;
          display: flex;
          flex-wrap: wrap;
          text-align: right;
          flex-direction: row;
          padding: 0;
          row-gap: 10px;
          justify-content: right;
        "
      >
        <button
          type="button"
          mat-raised-button
          color="warn"
          (click)="requestIntakeDocument()"
        >
          + Request Document
        </button>

        <button
          type="button"
          mat-raised-button
          color="warn"
          (click)="addDocument()"
        >
          + Upload Document
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="isLoadingResults || apiError">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="isLoadingResults"
    ></mat-progress-bar>

    <mat-error *ngIf="apiError">
      An error occurred while retrieving Documents
    </mat-error>
  </div>

  <div *ngIf="data.total === 0 && !isLoadingResults">
    <mat-error> No document found </mat-error>
  </div>

  <div
    class="mat-elevation-z8"
    *ngIf="data.total > 0"
    style="margin-bottom: 15px; margin-top: 15px"
  >
    <div class="table-responsive">
      <table
        mat-table
        [dataSource]="data.items"
        matSort
        multiTemplateDataRows
        matSortDisableClear
      >
        <ng-container matColumnDef="fileName">
          <th mat-header-cell *matHeaderCellDef>Name 1</th>
          <td mat-cell *matCellDef="let row">
            {{ row.fileName }}

            <div
              style="font-size: 13px; color: #888888"
              *ngIf="row.additionalInfo"
            >
              <b>Notes:</b> <i>{{ row.additionalInfo }}</i>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="fileType">
          <th mat-header-cell *matHeaderCellDef>Document Type</th>
          <td mat-cell *matCellDef="let row">{{ row.fileType }}</td>
        </ng-container>
        <ng-container matColumnDef="uploadedDate">
          <th mat-header-cell *matHeaderCellDef>Upload Date</th>
          <td mat-cell *matCellDef="let row">
            {{ row.createdDate | date : 'MM/dd/y' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="uploadedBy">
          <th mat-header-cell *matHeaderCellDef>Uploaded By</th>
          <td mat-cell *matCellDef="let row">{{ row.createdBy }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th
            mat-header-cell
            *matHeaderCellDef
            style="text-align: right; padding-right: 30px"
          >
            Actions
          </th>
          <td mat-cell *matCellDef="let row" style="text-align: right">
            <!-- Expand Icon -->
            <button
              mat-icon-button
              color="primary"
              aria-label="expand row"
              *ngIf="row.attachmentsExist"
              (click)="toggleRow(row); $event.stopPropagation()"
            >
              <span style="display: flex; align-items: center">
                <i class="material-icons" style="padding: 0px"> attach_file </i>
                <i class="material-icons" style="margin-left: -10px">
                  {{
                    row.showAttachments
                      ? 'keyboard_arrow_up'
                      : 'keyboard_arrow_down'
                  }}
                </i>
              </span>
            </button>
            <!-- Preview Icon -->
            <button
              mat-icon-button
              color="primary"
              *ngIf="isSupportedFileType(row.fileExtension)"
              (click)="openPreviewDialog(row)"
              matTooltip="preview"
            >
              <i class="material-icons"> preview </i>
            </button>
            <!-- Download Icon -->
            <button
              matTooltip="download"
              mat-icon-button
              color="primary"
              *ngIf="!isSupportedFileType(row.fileExtension)"
              (click)="downloadFile(row.patientId, row.id)"
            >
              <i class="material-icons"> download </i>
            </button>
            <!-- Delete Icon -->
            <button
              matTooltip="delete"
              mat-icon-button
              color="primary"
              (click)="deleteDocument(row)"
            >
              <mat-icon style="color: red"> delete_forever </mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <ng-container *matCellDef="let row">
            <ng-container *ngIf="row.showAttachments">
              <td mat-cell [attr.colspan]="5">
                <div class="example-element-detail">
                  <div>
                    <label>Attached Documents</label>

                    <i
                      class="fa fa-spinner fa-spin"
                      *ngIf="loadingAttachments"
                    ></i>
                    <ng-container *ngIf="!loadingAttachments">
                      <ul class="nav">
                        <li
                          routerLinkActive="active"
                          *ngFor="let attachment of attachments.items"
                          class="nav-item"
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 5px;
                          "
                        >
                          <span style="flex-grow: 1">{{
                            attachment.fileName
                          }}</span>
                          <div>
                            <button
                              mat-icon-button
                              color="primary"
                              *ngIf="
                                isSupportedFileType(attachment.fileExtension)
                              "
                              (click)="openPreviewDialog(attachment)"
                              matTooltip="preview"
                            >
                              <i class="material-icons">preview</i>
                            </button>
                            <button
                              mat-tooltip="download"
                              mat-icon-button
                              color="primary"
                              *ngIf="
                                !isSupportedFileType(attachment.fileExtension)
                              "
                              (click)="
                                downloadFile(
                                  attachment.patientId,
                                  attachment.id
                                )
                              "
                            >
                              <i class="material-icons">download</i>
                            </button>
                          </div>
                        </li>
                      </ul>
                    </ng-container>
                  </div>
                </div>
              </td>
            </ng-container>
          </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
          class="example-detail-row"
        ></tr>

        <ng-container *ngFor="let row of data.items">
          <ng-container *ngIf="row.isPreview">
            <tr>
              <td colspan="5">
                <div>
                  <!-- Your document preview component or logic here -->
                  <iframe [src]="row.previewUrl | sanitizeUrl"></iframe>
                </div>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </table>
    </div>
    <mat-paginator
      #paginator
      [showFirstLastButtons]="true"
      [length]="data.total"
      [pageSize]="data.per_page"
      *ngIf="data.total > data.per_page"
      (page)="getNext($event)"
    >
    </mat-paginator>

    <ng-container *ngIf="previewRow">
      <div style="min-height: 900px; width: 100%">
        <ng2-pdfjs-viewer
          [download]="false"
          #pdfViewerOnDemand
        ></ng2-pdfjs-viewer>
      </div>
    </ng-container>
  </div>
</mat-card>
