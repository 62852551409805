import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { assessmentGroups } from 'src/app/shared/utilities/assessment-utils';

@Component({
  selector: 'app-assessment-main-dialog',
  templateUrl: './assessment-main-dialog.component.html',
  styleUrls: ['./assessment-main-dialog.component.css'],
})
export class AssessmentMainDialogComponent implements OnInit {
  selectedCategory: string = '';
  selectedAssessment: string = '';
  assessmentList: any[] = [];
  patientId: string;
  requestId: string;
  sessionId: string;
  accessCode: string;
  assessmentGroups: any[] = assessmentGroups();

  constructor(
    public dialogRef: MatDialogRef<AssessmentMainDialogComponent>,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.patientId = data.patientId;
    this.sessionId = data.sessionId;
    this.selectedAssessment = data.assessmentType;
    this.requestId = data.requestId;
    this.accessCode = data.accessCode;
  }

  ngOnInit(): void { }

  getAssessmentTypes(selectedCategory: string) {
    let category = this.assessmentGroups.filter((category) => category.name === selectedCategory);
    return category[0].assessment;
  }

  taskCompleted(hasChangeEvent: boolean) {
    if (hasChangeEvent) {
      this.dialogRef.close('success');
    }
  }
}
