<div
  class="main-content"
  style="background-color: #eee"
  *ngIf="!isLoadingResults"
>
  <div class="container-fluid">
    <div class="container-fluid">
      <br />
      <div class="row">
        <div class="col-md-6">
          <mat-card appearance="raised">
            <mat-card-header>
              <h4 class="header text-center">Overall Graph</h4>
            </mat-card-header>
            <mat-card-content>
              <div style="width: 100%">
                <div class="chart-wrapper">
                  <canvas
                    baseChart
                    [data]="lineChartData"
                    [options]="lineChartOptions"
                    [legend]="lineChartLegend"
                    [type]="lineChartType"
                    [plugins]="lineChartPlugins"
                  >
                  </canvas>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-md-6">
          <mat-card appearance="raised">
            <mat-card-header>
              <h4 class="header text-center">Interpreting the Scores</h4>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
              <div class="row">
                <div class="col-md-2" style="font-weight: 400">Color</div>
                <div class="col-md-3" style="font-weight: 400">Type</div>
                <div
                  class="col-md-7"
                  style="padding-top: 4px; font-weight: 400"
                >
                  Description
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <button
                    mat-raised-button
                    class="btn btn-just-icon btn-fab btn-round"
                    style="
                      height: 20px;
                      width: 20px;
                      min-width: 20px;
                      background-color: #ff6384;
                    "
                  ></button>
                </div>
                <div
                  class="col-md-3"
                  style="padding-top: 4px; font-weight: 300"
                >
                  Agreeableness (A)
                </div>
                <div
                  class="col-md-7"
                  style="padding-top: 4px; font-weight: 300"
                >
                  Agreeableness (A) reflects much individuals adjust their
                  behavior to suit others. High scorers are typically polite and
                  like people. Low scorers tend to 'tell it like it is
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <button
                    mat-raised-button
                    class="btn btn-just-icon btn-fab btn-round"
                    style="
                      height: 20px;
                      width: 20px;
                      min-width: 20px;
                      background-color: #ff9f40;
                    "
                  ></button>
                </div>
                <div
                  class="col-md-3"
                  style="padding-top: 4px; font-weight: 300"
                >
                  Extroversion (E)
                </div>
                <div
                  class="col-md-7"
                  style="padding-top: 4px; font-weight: 300"
                >
                  Extroversion (E) is the personality trait of seeking
                  fulfillment from sources outside the self or in community.
                  High scorers tend to be very social while low scorers prefer
                  to work on their projects alone.
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <button
                    mat-raised-button
                    class="btn btn-just-icon btn-fab btn-round"
                    style="
                      height: 20px;
                      width: 20px;
                      min-width: 20px;
                      background-color: #36a2eb;
                    "
                  ></button>
                </div>
                <div
                  class="col-md-3"
                  style="padding-top: 4px; font-weight: 300"
                >
                  Conscientiousness (C)
                </div>
                <div
                  class="col-md-7"
                  style="padding-top: 4px; font-weight: 300"
                >
                  Conscientiousness (C) is the personality trait of being honest
                  and hardworking. High scorers tend to follow rules and prefer
                  clean homes. Low scorers may be messy and cheat others.
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <button
                    mat-raised-button
                    class="btn btn-just-icon btn-fab btn-round"
                    style="
                      height: 20px;
                      width: 20px;
                      min-width: 20px;
                      background-color: #9966ff;
                    "
                  ></button>
                </div>
                <div
                  class="col-md-3"
                  style="padding-top: 4px; font-weight: 300"
                >
                  Neuroticism (N)
                </div>
                <div
                  class="col-md-7"
                  style="padding-top: 4px; font-weight: 300"
                >
                  Neuroticism (N) is the personality trait of being emotional
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <button
                    mat-raised-button
                    class="btn btn-just-icon btn-fab btn-round"
                    style="
                      height: 20px;
                      width: 20px;
                      min-width: 20px;
                      background-color: #4bc0c0;
                    "
                  ></button>
                </div>
                <div
                  class="col-md-3"
                  style="padding-top: 4px; font-weight: 300"
                >
                  Openness to Experience (O)
                </div>
                <div
                  class="col-md-7"
                  style="padding-top: 4px; font-weight: 300"
                >
                  Openness to Experience (O) is the personality trait of seeking
                  new experience and intellectual pursuits. High scores may day
                  dream a lot. Low scorers may be very down to earth
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <br />
      <div class="row">
        <ng-container *ngFor="let scoreDetail of scoreDetails">
          <div class="col-md-4" style="margin-bottom: 20px">
            <mat-card appearance="raised">
              <mat-card-header>
                <h4 class="header text-center">{{ scoreDetail.title }}</h4>
              </mat-card-header>
              <mat-card-content>
                <div style="width: 100%">
                  <div class="chart-wrapper">
                    <canvas
                      baseChart
                      [data]="scoreDetail"
                      [options]="assessmentDetailChartOptions"
                      [legend]="lineChartLegend"
                      [type]="lineChartType"
                      [plugins]="detailChartPlugins"
                    >
                    </canvas>
                  </div>
                </div>
              </mat-card-content>
              <small
                ><b>Answer:</b> <i>{{ scoreDetail.answer }}</i></small
              >
            </mat-card>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
