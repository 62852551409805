<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Phone Note</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form
    [formGroup]="assignForm"
    novalidate
    (ngSubmit)="onSessionAssignFormSubmit()"
  >
    <div class="row">
      <div class="col-md-12">
        <label class="labelText"> Provider </label>

        <mat-form-field appearance="outline">
          <mat-select
            formControlName="physicianId"
            placeholder="Please select a provider"
          >
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Provider Name"
                noEntriesFoundLabel="No matching provider found"
                [(ngModel)]="providerName"
                (ngModelChange)="filterProvider()"
                [ngModelOptions]="{ standalone: true }"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option></mat-option>
            <mat-option
              [value]="supervisor.id"
              *ngFor="let supervisor of filteredProvidersList"
            >
              {{ supervisor.lastName }}, {{ supervisor.firstName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <label class="labelText">
          Note <span style="color: red">*</span>
        </label>

        <div class="NgxEditor__Wrapper">
          <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
          </ngx-editor-menu>
          <ngx-editor [editor]="editor" formControlName="note"> </ngx-editor>
        </div>
      </div>

      <div class="col-md-12" style="text-align: right; margin-top: 15px">
        <button
          color="primary"
          mat-raised-button
          type="submit"
          [disabled]="!assignForm.valid || processing"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </form>
</div>
