import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OTPService, ToastMessageService } from 'src/app/_services';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { AssessmentMainDialogComponent } from '../patient-details/patient-components/assessments/assessment-main-dialog/assessment-main-dialog.component';

@Component({
  selector: 'app-facility-otpcode',
  templateUrl: './facility-otpcode.component.html',
  styleUrls: ['./facility-otpcode.component.css'],
})
export class FacilityOTPCodeComponent implements OnInit {
  public accessCodeForm: FormGroup;

  orgConfig: any = null;
  orgConfigLoaded: boolean = false;
  todaysDate = new Date();

  processing: boolean = false;

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private otpService: OTPService,
    private toastr: ToastMessageService,
    private router: Router,
    private datePipe: DatePipe,
    private orgConfigSandbox: OrgConfigSandbox
  ) {}

  ngOnInit(): void {
    this.buildForm();

    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        this.orgConfigSandbox.orgConfig$.subscribe((response) => {
          this.orgConfig = response;
          this.orgConfigLoaded = true;
        });
      }
    });
  }

  buildForm() {
    this.accessCodeForm = this.formBuilder.group({
      accessCode: new FormControl('', Validators.required),
      dateOfBirth: new FormControl('', Validators.required),
    });
  }

  submitForm(formDirective: FormGroupDirective) {
    if (this.accessCodeForm.invalid) {
      return;
    }

    let inputDateOfBirth = this.datePipe.transform(
      this.accessCodeForm.controls['dateOfBirth'].value,
      'MM/dd/yyyy'
    );

    this.processing = true;
    // Now that the form is valid
    this.otpService
      .verifyOTPCode(
        this.accessCodeForm.controls['accessCode'].value,
        inputDateOfBirth
      )
      .subscribe(
        (response) => {
          this.processing = false;
          formDirective.resetForm();

          if (response) {
            const dialogRef = this.dialog.open(AssessmentMainDialogComponent, {
              minWidth: '80vw',
              minHeight: '50vh',
              maxHeight: '95vh',
              disableClose: true,
              autoFocus: false,
              data: {
                patientId: response.patientId,
                assessmentType: response.assessmentType,
                requestId: response.assessmentId,
              },
            });
            dialogRef.afterClosed().subscribe((response) => {
              if (response === 'success') {
                this.router.navigate(['/assessmentComplete']);
              } else {
                this.router.navigate(['/assessmentCancelled']);
              }
            });
          }
        },
        (error) => {
          this.processing = false;
          this.toastr.displayErrorMessage('Error: Invalid Request');
        }
      );
  }
}
