<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Behavior Incidents for {{ date }}</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div
    class="row"
    style="display: flex; row-gap: 15px; margin-top: 15px; padding-bottom: 35px"
  >
    <mat-card
      appearance="raised"
      class="col-md-12 mat-elevation-z8 incidentCard"
      *ngFor="let data of incidents"
      [style.border-left]="getBorderColor(data.behavioralIncident)"
      style="cursor: pointer"
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          row-gap: 5px;
        "
      >
        <div class="contextContainer">
          <label class="headerText">Severity:</label>
          <label
            class="headerContext"
            [style.color]="
              severityColorMap.get(data.behavioralIncident.severity)
            "
            >{{ data.behavioralIncident?.severity }}</label
          >
        </div>
        <div class="contextContainer">
          <label class="headerText">Start Time:</label>
          <label class="headerContext">{{
            data.behavioralIncident.incidentStartTime
          }}</label>
        </div>
        <div class="contextContainer">
          <label class="headerText">End Time:</label>
          <label class="headerContext">{{
            data.behavioralIncident.incidentEndTime
          }}</label>
        </div>
      </div>
      <!-- View Button -->
      <button
        mat-raised-button
        color="primary"
        type="button"
        (click)="viewBehaviorIncident(data)"
      >
        View
      </button>
    </mat-card>
  </div>
</div>
