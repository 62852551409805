import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  DataTablesModel,
  IMemberDefaultPayment,
  IMemberPaymentHistory,
  ResponseModal,
} from 'src/app/_models';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class MemberPaymentsApiService {
  constructor(private httpClient: HttpClient) {}

  getMemberSavedPaymentMethods(memberId: string): Observable<DataTablesModel> {
    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/memberstoredcards/${memberId}`
    );
  }

  addMemberCardPaymentMethod(memberId: string): Observable<any> {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient
      .post<any>(`${environment.apiUrl}/addmembercard/${memberId}`, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  deleteMemberSavedPaymentMethod(
    memberId: string,
    memberStripeId: string,
    paymentMethodId: string
  ): Observable<void> {
    let params = new HttpParams();

    params = params.append('memberId', memberId);
    params = params.append('memberStripeId', memberStripeId);
    params = params.append('paymentMethodId', paymentMethodId);

    return this.httpClient
      .delete<void>(`${environment.apiUrl}/removememberpaymentmethod`, {
        params,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  setMemberDefaultPaymentMethod(memberDefaulPayment: IMemberDefaultPayment) {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient
      .post<IMemberDefaultPayment>(
        `${environment.apiUrl}/setmemberdefaultpayment`,
        memberDefaulPayment,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getMemberDefaultPaymentMethod(memberId: string): Observable<ResponseModal> {
    return this.httpClient.get<ResponseModal>(
      `${environment.apiUrl}/getMemberDefaultPaymentId/${memberId}`
    );
  }

  collectMemberPayment(
    memberPayment: IMemberPaymentHistory
  ): Observable<ResponseModal> {
    const headers = { 'content-type': 'application/json' };

    return this.httpClient
      .post<ResponseModal>(
        `${environment.apiUrl}/collectnewmemberpayment`,
        memberPayment,
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
