<div style="margin-top: 10px; margin-bottom: 20px">
  <div class="row" *ngIf="hasContactsAddAccess" style="margin-bottom: 15px">
    <div class="col-md-12" style="text-align: right">
      <button
        type="button"
        mat-raised-button
        color="warn"
        (click)="addContact()"
      >
        + Add Personal Contact
      </button>
    </div>
  </div>

  <div *ngIf="data.total === 0">
    <mat-error> No contact information found </mat-error>
  </div>
  <div *ngIf="isLoadingResults || apiError">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="isLoadingResults"
    ></mat-progress-bar>
    <mat-error *ngIf="apiError">
      An error occurred while retrieving contact information
    </mat-error>
  </div>

  <div class="mat-elevation-z8" *ngIf="data.total > 0">
    <div class="table-responsive">
      <table mat-table [dataSource]="data.items" matSort matSortDisableClear>
        <ng-container matColumnDef="contactType">
          <th mat-header-cell *matHeaderCellDef>Type</th>
          <td
            mat-cell
            *matCellDef="let row"
            style="align-items: center; min-width: 150px"
          >
            <span
              [class]="
                row.contactType === 'Primary Contact'
                  ? 'primaryContact'
                  : row?.contactType === 'Secondary Contact'
                  ? 'secondaryContact'
                  : ''
              "
            >
              {{ row.contactType }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let row">
            {{ row?.lastName }}, {{ row?.firstName }} {{ row?.middleName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="relation">
          <th mat-header-cell *matHeaderCellDef>Relation</th>
          <td mat-cell *matCellDef="let row" style="font-weight: 500">
            {{ row.relation }}
          </td>
        </ng-container>

        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef>Phone</th>
          <td mat-cell *matCellDef="let row" style="min-width: 180px">
            <span *ngIf="row?.phone" class="phoneGrid">
              <label
                [class]="
                  row?.phonePreference === 'Cell Phone'
                    ? 'labelText preferred'
                    : 'labelText'
                "
              >
                Cell
              </label>
              <label
                [class]="
                  row?.phonePreference === 'Cell Phone'
                    ? 'labelText preferred'
                    : 'labelText'
                "
              >
                {{ row.phone | mask : '(000) 000-0000' }}
              </label>
            </span>

            <span *ngIf="row?.homePhoneNumber" class="phoneGrid">
              <label
                [class]="
                  row?.phonePreference === 'Home Phone'
                    ? 'labelText preferred'
                    : 'labelText'
                "
              >
                Home
              </label>
              <label
                [class]="
                  row?.phonePreference === 'Home Phone'
                    ? 'labelText preferred'
                    : 'labelText'
                "
              >
                {{ row.homePhoneNumber | mask : '(000) 000-0000' }}
              </label>
            </span>

            <span *ngIf="row?.workPhoneNumber" class="phoneGrid">
              <label
                [class]="
                  row?.phonePreference === 'Work Phone'
                    ? 'labelText preferred'
                    : 'labelText'
                "
              >
                Work
              </label>
              <label
                [class]="
                  row?.phonePreference === 'Work Phone'
                    ? 'labelText preferred'
                    : 'labelText'
                "
              >
                {{ row.workPhoneNumber | mask : '(000) 000-0000' }}
              </label>
            </span>

            <span *ngIf="row?.fax" class="phoneGrid">
              <label class="labelText"> Fax </label>
              <label class="labelText">
                {{ row.fax | mask : '(000) 000-0000' }}
              </label>
            </span>
          </td>

          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef>Address</th>
            <td mat-cell *matCellDef="let row">
              <span>{{ row.addressLine1 }} {{ row.addressLine2 }}</span>
              <span *ngIf="row.city || row.state || row.zip"
                ><br />{{ row.city }} {{ row.state }} {{ row.zip }}</span
              >
            </td>
          </ng-container>
        </ng-container>

        <ng-container matColumnDef="maritalStatus">
          <th mat-header-cell *matHeaderCellDef>Marital Status</th>
          <td mat-cell *matCellDef="let row">
            {{
              row?.maritalStatus === 'MARRD'
                ? 'Married'
                : row?.maritalStatus === 'SINGL'
                ? 'Single'
                : row?.maritalStatus === 'WIDWD'
                ? 'Widowed'
                : row?.maritalStatus === 'SPRTD'
                ? 'Separated'
                : row?.maritalStatus === 'DVRCD'
                ? 'Divorced'
                : row?.maritalStatus === 'UNKWN'
                ? 'Unknown'
                : row?.maritalStatus === 'ENGD'
                ? 'Engaged'
                : row?.maritalStatus === 'INTRL'
                ? 'Interlocutory'
                : row?.maritalStatus === 'PRVSLYMRD'
                ? 'Previously Married'
                : row?.maritalStatus === 'NVRMRD'
                ? 'Never Married'
                : 'Other'
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let row">{{ row.email }}</td>
        </ng-container>
        <ng-container matColumnDef="notes">
          <th mat-header-cell *matHeaderCellDef>Notes</th>
          <td mat-cell *matCellDef="let row">{{ row.notes }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef style="text-align: right">
            Actions
          </th>
          <td mat-cell *matCellDef="let record" style="text-align: right">
            <button
              mat-raised-button
              [matMenuTriggerFor]="menu"
              class="btn btn-just-icon btn-white btn-fab btn-round"
            >
              <i class="material-icons text_align-center">more_vert</i>
            </button>

            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                (click)="editContact(record)"
                *ngIf="hasContactsEditAccess"
              >
                <mat-icon style="color: #f39121"> edit </mat-icon>
                <span>Edit</span>
              </button>
              <button
                mat-menu-item
                (click)="deleteContact(record)"
                *ngIf="hasContactsDeleteAccess"
              >
                <mat-icon style="color: red"> delete_forever </mat-icon>
                <span>Delete</span>
              </button>
              <!-- <button mat-menu-item class="status-toggle">
                <mat-icon>co_present</mat-icon>
                <span class="mr-2">Online Access</span>
                <mat-slide-toggle
                  color="primary"
                  (change)="provideOnlineAccess(record)"
                  (click)="$event.stopPropagation()"
                  [checked]="record.onlineAccess"
                >
                </mat-slide-toggle>
              </button> -->
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <mat-paginator
      #paginator
      [length]="data.total"
      [pageSize]="data.per_page"
      [showFirstLastButtons]="true"
      *ngIf="data.total > data.per_page"
      (page)="getNext($event)"
    >
    </mat-paginator>
  </div>
</div>

<mat-card appearance="raised" *ngIf="!hasContactsViewAccess">
  <mat-card-header>
    <mat-card-title>
      <mat-error>
        Sorry, You don't have access to view Member Contacts
      </mat-error>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content> </mat-card-content>
</mat-card>
