import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DataTablesModel,
  IMemberPaymentHistory,
  Patient,
} from 'src/app/_models';
import {
  MemberPaymentsApiService,
  PatientService,
  ToastMessageService,
} from 'src/app/_services';

@Component({
  selector: 'app-collect-member-payment',
  templateUrl: './collect-member-payment.component.html',
  styleUrl: './collect-member-payment.component.css',
})
export class CollectMemberPaymentComponent implements OnInit {
  @ViewChild(MatStepper) stepper: MatStepper;

  // Patient Details
  @Input() sessionId: string = '';
  @Input() parent: string = '';
  @Input() patientId: string;
  @Output() closeDialog = new EventEmitter<boolean>();

  patientDetailsLoading: boolean = true;
  patient: Patient;

  defaultPaymentId: string = '';
  savedPaymentMehods: DataTablesModel = {} as DataTablesModel;

  public newPatientPaymentForm: FormGroup;
  processing: boolean = false;
  memberPaymentHistory: IMemberPaymentHistory;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private patientAPIService: PatientService,
    private memberPaymentsApiService: MemberPaymentsApiService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('memberId')) {
      this.patientId = this.route.snapshot.paramMap.get('memberId');
    }

    this.buildForm();

    if (!this.parent) {
      this.route.queryParams.subscribe((param) => {
        if (param?.sessionId) {
          this.newPatientPaymentForm.controls['sessionId'].setValue(
            param.sessionId
          );
          this.sessionId = param.sessionId;
        }
        this.parent = param?.parent;
      });
    } else {
      if (this.sessionId) {
        this.newPatientPaymentForm.controls['sessionId'].setValue(
          this.sessionId
        );
      }
    }

    // First Load Patient Details
    this.loadPatientDetails();
    this.loadSavedCardPayments();
    this.getDefaultPaymentMethod();
  }

  buildForm() {
    this.newPatientPaymentForm = this.formBuilder.group({
      id: new FormControl(''),
      organizationId: new FormControl(''),
      organizationName: new FormControl(''),
      stripeConnectedAccountId: new FormControl(''),

      facilityId: new FormControl(''),
      facilityName: new FormControl(''),

      memberId: new FormControl(this.patientId, Validators.required),
      stripeCustomerId: new FormControl(''),
      memberFirstName: new FormControl('', Validators.required),
      memberMiddleName: new FormControl(''),
      memberLastName: new FormControl('', Validators.required),

      totalAmount: new FormControl('', Validators.required),
      paymentNote: new FormControl(''),
      addNoteToSession: new FormControl(false),
      fee: new FormControl(''),
      net: new FormControl(''),

      status: new FormControl('Payment Intent'),

      paymentMethodId: new FormControl('', Validators.required),
      paymentType: new FormControl('Card', Validators.required),
      newPaymentMethod: new FormControl('No', Validators.required),
      saveNewPaymentMethod: new FormControl('No', Validators.required),
      paymentDate: new FormControl(''),

      checkNumber: new FormControl(''),

      sessionId: new FormControl(''),
      sessionCode: new FormControl(''),
      sessionTitle: new FormControl(''),
      sessionStart: new FormControl(''),
    });
  }

  submitForm() {
    this.memberPaymentHistory = Object.assign({}, this.memberPaymentHistory);
    this.memberPaymentHistory = Object.assign(
      this.memberPaymentHistory,
      this.newPatientPaymentForm.value
    );

    this.processing = true;

    this.memberPaymentsApiService
      .collectMemberPayment(this.memberPaymentHistory)
      .subscribe({
        next: (response) => {
          // Determine whether it is a new card or payment intent or just
          if (
            this.memberPaymentHistory.paymentType === 'Card' &&
            this.memberPaymentHistory.paymentMethodId === 'Add New'
          ) {
            window.open(response.data.paymentURL, '_self');
          } else if (this.memberPaymentHistory.paymentType === 'Card') {
            // Display the payment intent status and log error if any
            // If it was a check or cash, simply display the toast and return
            this.toastMessageService.displaySuccessMessage(
              'Successfully recorded the payment record'
            );
            if (this.parent === 'Group Note') {
              // Navigate to group session page
              // For Normal navigate to session edit
              this.closeDialog.emit(true);
            } else {
              // Navigate to member page
              this.router.navigate(
                ['/main/member/' + this.patientId + '/MEMBER'],
                {
                  queryParams: {
                    secondaryId: '3',
                  },
                }
              );
            }
          } else {
            // If it was a check or cash, simply display the toast and return
            this.toastMessageService.displaySuccessMessage(
              'Successfully recorded the payment record'
            );

            if (this.parent === 'Group Note') {
              // Navigate to group session page
              // For Normal navigate to session edit
              this.closeDialog.emit(true);
            } else {
              // Navigate to member page
              this.router.navigate(
                ['/main/member/' + this.patientId + '/MEMBER'],
                {
                  queryParams: {
                    secondaryId: '3',
                  },
                }
              );
            }
          }
          this.processing = false;
        },
        error: (error) => {
          if (
            this.memberPaymentHistory.paymentType === 'Card' &&
            this.memberPaymentHistory.paymentMethodId === 'Add New'
          ) {
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to generate the checkout link'
            );
          } else if (this.memberPaymentHistory.paymentType === 'Card') {
            this.toastMessageService.displayErrorMessage(
              'Error: The card payment was declined'
            );
          } else {
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to record the payment'
            );
          }

          this.processing = false;
        },
      });
  }

  // Get Patient Information
  loadPatientDetails() {
    this.patientDetailsLoading = true;

    this.patientAPIService.getPatientDetails(this.patientId, null).subscribe({
      next: (response) => {
        if (response && response.data) {
          this.patient = response.data;
          this.newPatientPaymentForm.controls['organizationId'].setValue(
            this.patient.organizationId
          );
          this.newPatientPaymentForm.controls['facilityId'].setValue(
            this.patient.facilityId
          );
          this.newPatientPaymentForm.controls['stripeCustomerId'].setValue(
            this.patient.stripeCustomerId
          );
          this.newPatientPaymentForm.controls['memberFirstName'].setValue(
            this.patient.firstName
          );
          this.newPatientPaymentForm.controls['memberMiddleName'].setValue(
            this.patient.middleName
          );
          this.newPatientPaymentForm.controls['memberLastName'].setValue(
            this.patient.lastName
          );
        }
        this.patientDetailsLoading = false;
      },
      error: (error) => {
        this.patientDetailsLoading = false;
        this.toastMessageService.displayErrorMessage(
          "Error: Failed to retreive member's information."
        );
      },
    });
  }

  // Load Existing member saved cards
  loadSavedCardPayments() {
    this.memberPaymentsApiService
      .getMemberSavedPaymentMethods(this.patientId)
      .subscribe({
        next: (response) => {
          if (response && response.items) {
            this.savedPaymentMehods.items = response.items;
            this.savedPaymentMehods.total = response.total;
          } else {
            this.savedPaymentMehods.items = [];
            this.savedPaymentMehods.total = 0;
          }
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load saved cards'
          );
        },
      });
  }

  // Get Default Member Card Payment Id
  getDefaultPaymentMethod() {
    this.memberPaymentsApiService
      .getMemberDefaultPaymentMethod(this.patientId)
      .subscribe({
        next: (response) => {
          if (response && response.data) {
            this.defaultPaymentId = response.data.paymentMethodId;
            this.newPatientPaymentForm.controls['paymentMethodId'].setValue(
              response.data.paymentMethodId
            );
          }
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get the default payment card'
          );
        },
      });
  }

  // Handle Payment Method Changed
  paymentMethodChanged(event) {
    if (event.value === 'Cash' || event.value === 'Check') {
      this.newPatientPaymentForm.controls['paymentMethodId'].setValue('');
      this.newPatientPaymentForm.controls['paymentMethodId'].clearValidators();
      this.newPatientPaymentForm.controls[
        'paymentMethodId'
      ].updateValueAndValidity();

      this.newPatientPaymentForm.controls['newPaymentMethod'].setValue('No');
      this.newPatientPaymentForm.controls['saveNewPaymentMethod'].setValue(
        'No'
      );
    } else {
      this.newPatientPaymentForm.controls['paymentMethodId'].setValidators(
        Validators.required
      );
      this.newPatientPaymentForm.controls[
        'paymentMethodId'
      ].updateValueAndValidity();
    }
  }

  // Handle Card Changed
  cardSelected(event) {
    if (event.value != 'Add New') {
      this.newPatientPaymentForm.controls['newPaymentMethod'].setValue('No');
      this.newPatientPaymentForm.controls['saveNewPaymentMethod'].setValue(
        'No'
      );
    }
  }

  isSaveNewPaymentChecked() {
    return (
      this.newPatientPaymentForm.controls['saveNewPaymentMethod'].value ===
      'Yes'
    );
  }

  saveCardOnFileChanged(event) {
    if (event.checked) {
      this.newPatientPaymentForm.controls['saveNewPaymentMethod'].setValue(
        'Yes'
      );
    } else {
      this.newPatientPaymentForm.controls['saveNewPaymentMethod'].setValue(
        'No'
      );
    }
  }
}
