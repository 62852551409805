import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { DataTablesModel } from 'src/app/_models';
import {
  ReferringProviderAPIService,
  ToastMessageService,
} from 'src/app/_services';
import { EditReferringProviderComponent } from '../edit-referring-provider/edit-referring-provider.component';

@Component({
  selector: 'app-select-referring-provider',
  templateUrl: './select-referring-provider.component.html',
  styleUrls: ['./select-referring-provider.component.css'],
})
export class SelectReferringProviderComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = [
    'provider',
    'npi',
    'taxonomy',
    'emailAddress',
    'phoneNumber',
    'actions',
  ];

  firstName: string = '';
  lastName: string = '';
  phoneNumber: string = '';
  status: string = 'Active';

  referringProviderList: DataTablesModel = {} as DataTablesModel;
  isLoadingResults = false;
  apiError = false;

  constructor(
    public dialogRef: MatDialogRef<SelectReferringProviderComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private referringProviderAPIService: ReferringProviderAPIService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit(): void {
    this.referringProviderList.per_page = 10;
    this.referringProviderList.page = 0;

    this.loadReferringProviders();
  }

  loadReferringProviders() {
    this.isLoadingResults = true;
    this.apiError = false;

    this.referringProviderAPIService
      .getReferringProvider(
        this.firstName,
        this.lastName,
        this.phoneNumber,
        this.status,
        this.referringProviderList.per_page,
        this.referringProviderList.page
      )
      .subscribe(
        (response) => {
          this.isLoadingResults = false;
          if (response && response.items) {
            this.referringProviderList.items = response.items;
            this.referringProviderList.total = response.total;
          }
          if (response == null) {
            this.referringProviderList.items = [];
            this.referringProviderList.total = 0;
          }
        },
        (error) => {
          this.isLoadingResults = false;
          this.apiError = true;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load the referring providers'
          );
        }
      );
  }

  select(referringProvider) {
    this.dialogRef.close({ type: 'success', details: referringProvider });
  }

  addReferringProvider() {
    const dialogRef = this.dialog.open(EditReferringProviderComponent, {
      data: {
        action: 'ADD',
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '30vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.loadReferringProviders();
      }
    });
  }

  searchByNameChanged() {
    // Restore page index and paginator index
    this.data.page = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.loadReferringProviders();
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadReferringProviders();
  }
}
