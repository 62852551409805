import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { IDsm5l2su11to17 } from 'src/app/_models/assessments/addiction/dsm5l2su11to17.model';
import {
  ToastMessageService
} from 'src/app/_services/';
import {
  Dsm5l2su11to17ApiService
} from 'src/app/_services/assessments/addiction/dsm5l2su11to17.service';

@Component({
  selector: 'app-dsm5-level2-substance-use',
  templateUrl: './dsm5-level2-substance-use.component.html',
  styleUrls: ['./dsm5-level2-substance-use.component.css']
})
export class Dsm5Level2SubstanceUseComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public dsm5l2su11to17AssessmentApiService: Dsm5l2su11to17ApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) { }
  public riskScore: number;
  public dsm5l2su11to17AssessmentForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  dsm5l2su11to17Assessment: IDsm5l2su11to17;
  dsm5l2su11to17AssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.dsm5l2su11to17AssessmentForm, this.errors);

    this.loadDsm5l2su11to17();
  }

  loadDsm5l2su11to17() {
    this.dsm5l2su11to17AssessmentForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.dsm5l2su11to17AssessmentApiService
        .getDsm5l2su11to17Details(this.assessmentId)
        .subscribe((dsm5l2su11to17Assessment) => {
          if (dsm5l2su11to17Assessment.data) {

            this.dsm5l2su11to17AssessmentForm.controls['alcoholicBeverage'].setValue(dsm5l2su11to17Assessment.data.alcoholicBeverage.answer);
            this.dsm5l2su11to17AssessmentForm.controls['fourPlusDrinks'].setValue(dsm5l2su11to17Assessment.data.fourPlusDrinks.answer);
            this.dsm5l2su11to17AssessmentForm.controls['smokeOrTobaccoUse'].setValue(dsm5l2su11to17Assessment.data.smokeOrTobaccoUse.answer);
            this.dsm5l2su11to17AssessmentForm.controls['painkillerUse'].setValue(dsm5l2su11to17Assessment.data.painkillerUse.answer);
            this.dsm5l2su11to17AssessmentForm.controls['stimulantUse'].setValue(dsm5l2su11to17Assessment.data.stimulantUse.answer);
            this.dsm5l2su11to17AssessmentForm.controls['sedativeUse'].setValue(dsm5l2su11to17Assessment.data.sedativeUse.answer);
            this.dsm5l2su11to17AssessmentForm.controls['steroidUse'].setValue(dsm5l2su11to17Assessment.data.steroidUse.answer);
            this.dsm5l2su11to17AssessmentForm.controls['otherMedUse'].setValue(dsm5l2su11to17Assessment.data.otherMedUse.answer);
            this.dsm5l2su11to17AssessmentForm.controls['marijuanaUse'].setValue(dsm5l2su11to17Assessment.data.marijuanaUse.answer);
            this.dsm5l2su11to17AssessmentForm.controls['cocaineUse'].setValue(dsm5l2su11to17Assessment.data.cocaineUse.answer);
            this.dsm5l2su11to17AssessmentForm.controls['clubDrugUse'].setValue(dsm5l2su11to17Assessment.data.clubDrugUse.answer);
            this.dsm5l2su11to17AssessmentForm.controls['hallucinogenUse'].setValue(dsm5l2su11to17Assessment.data.hallucinogenUse.answer);
            this.dsm5l2su11to17AssessmentForm.controls['heroinUse'].setValue(dsm5l2su11to17Assessment.data.heroinUse.answer);
            this.dsm5l2su11to17AssessmentForm.controls['inhalantUse'].setValue(dsm5l2su11to17Assessment.data.inhalantUse.answer);
            this.dsm5l2su11to17AssessmentForm.controls['methUse'].setValue(dsm5l2su11to17Assessment.data.methUse.answer);

            this.riskScore = dsm5l2su11to17Assessment.data.riskScore;
          }
        });
    }
  }
  onDsm5l2su11to17FormSubmit() {
    if (this.dsm5l2su11to17AssessmentForm.invalid) {
      this.dsm5l2su11to17AssessmentForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.dsm5l2su11to17Assessment = Object.assign({}, this.dsm5l2su11to17Assessment);
    this.dsm5l2su11to17Assessment.patientId =
      this.dsm5l2su11to17AssessmentForm.controls['patientId'].value;
    this.dsm5l2su11to17Assessment.sessionId =
      this.dsm5l2su11to17AssessmentForm.controls['sessionId'].value;
    this.dsm5l2su11to17Assessment.assessmentRequestId =
      this.dsm5l2su11to17AssessmentForm.controls['assessmentRequestId'].value;

    this.dsm5l2su11to17Assessment.alcoholicBeverage = { answer: this.dsm5l2su11to17AssessmentForm.controls['alcoholicBeverage'].value };
    this.dsm5l2su11to17Assessment.fourPlusDrinks = { answer: this.dsm5l2su11to17AssessmentForm.controls['fourPlusDrinks'].value };
    this.dsm5l2su11to17Assessment.smokeOrTobaccoUse = { answer: this.dsm5l2su11to17AssessmentForm.controls['smokeOrTobaccoUse'].value };
    this.dsm5l2su11to17Assessment.painkillerUse = { answer: this.dsm5l2su11to17AssessmentForm.controls['painkillerUse'].value };
    this.dsm5l2su11to17Assessment.stimulantUse = { answer: this.dsm5l2su11to17AssessmentForm.controls['stimulantUse'].value };
    this.dsm5l2su11to17Assessment.sedativeUse = { answer: this.dsm5l2su11to17AssessmentForm.controls['sedativeUse'].value };
    this.dsm5l2su11to17Assessment.steroidUse = { answer: this.dsm5l2su11to17AssessmentForm.controls['steroidUse'].value };
    this.dsm5l2su11to17Assessment.otherMedUse = { answer: this.dsm5l2su11to17AssessmentForm.controls['otherMedUse'].value };
    this.dsm5l2su11to17Assessment.marijuanaUse = { answer: this.dsm5l2su11to17AssessmentForm.controls['marijuanaUse'].value };
    this.dsm5l2su11to17Assessment.cocaineUse = { answer: this.dsm5l2su11to17AssessmentForm.controls['cocaineUse'].value };
    this.dsm5l2su11to17Assessment.clubDrugUse = { answer: this.dsm5l2su11to17AssessmentForm.controls['clubDrugUse'].value };
    this.dsm5l2su11to17Assessment.hallucinogenUse = { answer: this.dsm5l2su11to17AssessmentForm.controls['hallucinogenUse'].value };
    this.dsm5l2su11to17Assessment.heroinUse = { answer: this.dsm5l2su11to17AssessmentForm.controls['heroinUse'].value };
    this.dsm5l2su11to17Assessment.inhalantUse = { answer: this.dsm5l2su11to17AssessmentForm.controls['inhalantUse'].value };
    this.dsm5l2su11to17Assessment.methUse = { answer: this.dsm5l2su11to17AssessmentForm.controls['methUse'].value };
    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.dsm5l2su11to17Assessment.accessCode = this.accessCode;

      this.dsm5l2su11to17AssessmentApiService
        .addGuestDsm5l2su11to17(this.dsm5l2su11to17Assessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted DSM-5-TR Level 2 - Substance Use | Age 11-17 Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting DSM-5-TR Level 2 - Substance Use | Age 11-17 Assessment'
            );
          }
        );
    } else {
      this.dsm5l2su11to17AssessmentApiService
        .addDsm5l2su11to17(this.dsm5l2su11to17Assessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted DSM-5-TR Level 2 - Substance Use | Age 11-17 Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting DSM-5-TR Level 2 - Substance Use | Age 11-17 Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    'alcoholicBeverage': [
      { type: 'required', message: 'a. Have an alcoholic beverage (beer, wine, liquor, etc.)? is required' },
    ],
    'fourPlusDrinks': [
      { type: 'required', message: 'b. Have 4 or more drinks in a single day? is required' },
    ],
    'smokeOrTobaccoUse': [
      { type: 'required', message: 'c. Smoke a cigarette, a cigar, or pipe or use snuff or chewing tobacco? is required' },
    ],
    'painkillerUse': [
      { type: 'required', message: 'd. Painkillers (like Vicodin) is required' },
    ],
    'stimulantUse': [
      { type: 'required', message: 'e. Stimulants (like Ritalin, Adderall) is required' },
    ],
    'sedativeUse': [
      { type: 'required', message: 'f. Sedatives or  tranquilizers (like sleeping pills or Valium) is required' },
    ],
    'steroidUse': [
      { type: 'required', message: 'g. Steroids is required' },
    ],
    'otherMedUse': [
      { type: 'required', message: 'h. Other medicines is required' },
    ],
    'marijuanaUse': [
      { type: 'required', message: 'i. Marijuana is required' },
    ],
    'cocaineUse': [
      { type: 'required', message: 'j. Cocaine or crack is required' },
    ],
    'clubDrugUse': [
      { type: 'required', message: 'k. Club drugs (like ecstasy) is required' },
    ],
    'hallucinogenUse': [
      { type: 'required', message: 'l. Hallucinogens (like LSD) is required' },
    ],
    'heroinUse': [
      { type: 'required', message: 'm. Heroin is required' },
    ],
    'inhalantUse': [
      { type: 'required', message: 'n. Inhalants or solvents (like glue) is required' },
    ],
    'methUse': [
      { type: 'required', message: 'o. Methamphetamine (like speed) is required' },
    ],
  };

  private buildForm() {
    this.dsm5l2su11to17AssessmentForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),

      alcoholicBeverage: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      fourPlusDrinks: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      smokeOrTobaccoUse: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      painkillerUse: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      stimulantUse: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      sedativeUse: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      steroidUse: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      otherMedUse: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      marijuanaUse: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      cocaineUse: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      clubDrugUse: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      hallucinogenUse: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      heroinUse: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      inhalantUse: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
      methUse: new FormControl({ value: '', disabled: this.disableFormElements }, Validators.compose([
        Validators.required,
      ])),
    });
    this.dsm5l2su11to17AssessmentForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.dsm5l2su11to17AssessmentForm) {
      return;
    }
    const form = this.dsm5l2su11to17AssessmentForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
