import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { Case, DataTablesModel } from 'src/app/_models';
import { CaseApiService, ToastMessageService } from 'src/app/_services';
import { AddEditCaseDialogComponent } from 'src/app/pages/cases/case-dialogs/case-add-edit/case-add-edit.component';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import { PermissionsSandbox } from 'src/app/shared/sandbox/permissions.sandbox';
import { hasAccess } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-my-assigned-cases',
  templateUrl: './my-assigned-cases.component.html',
  styleUrl: './my-assigned-cases.component.css',
})
export class MyAssignedCasesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() hideHeader: boolean = false;

  // Search Fields
  memberName: string = '';
  searchCaseId: string = '';
  searchDescription: string = '';
  searchStatus: string = 'active';

  hasCaseEditAccess: boolean = false;
  hasClosedCaseViewAccess: boolean = false;
  orgConfig: any;

  displayedColumns: string[] = ['caseDetails', 'services', 'payor', 'status'];
  data: DataTablesModel = {} as DataTablesModel;
  isLoadingResults = true;
  apiError = false;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private orgConfigSandbox: OrgConfigSandbox,
    protected permissionsSandbox: PermissionsSandbox,
    private keycloakService: KeycloakService,
    public caseApiService: CaseApiService,
    public toastMessageService: ToastMessageService
  ) {}

  ngOnInit(): void {
    this.data.per_page = 10;
    this.data.page = 0;

    this.loadOrgConfigAndPermissions();

    this.loadCases();
  }

  loadOrgConfigAndPermissions() {
    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          // Saving the org config
          this.orgConfig = orgConfig;

          // Saving the permissions
          this.permissionsSandbox.permissions$.subscribe((response) => {
            this.hasCaseEditAccess = hasAccess(
              this.keycloakService,
              'CASES_EDIT',
              response,
              null
            );

            this.hasClosedCaseViewAccess = hasAccess(
              this.keycloakService,
              'CLOSED_CASES_VIEW',
              response,
              null
            );

            if (this.hasCaseEditAccess) {
              if (!this.displayedColumns.includes('actions')) {
                this.displayedColumns.push('actions');
              }
            }
          });
        });
      }
    });
  }

  public loadCases() {
    this.isLoadingResults = true;
    this.apiError = false;

    this.caseApiService
      .getMyAssignedCases(
        this.memberName,
        this.searchCaseId,
        this.searchDescription,
        this.searchStatus,
        this.data.per_page,
        this.data.page
      )
      .subscribe({
        next: (response) => {
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }
          this.isLoadingResults = false;
        },
        error: () => {
          this.isLoadingResults = false;
          this.apiError = true;
        },
      });
  }

  setSelectCase(row) {
    // this.selectedCaseId = caseId;
    this.router.navigate(['/main/member/' + row.patientId + '/CARE'], {
      queryParams: {
        secondaryId: row.id,
      },
    });
  }

  public editCase(currentCase: Case) {
    const dialogRef = this.dialog.open(AddEditCaseDialogComponent, {
      data: {
        action: 'EDIT',
        case: currentCase,
        patientId: currentCase.patientId,
      },
      disableClose: true,
      autoFocus: false,
      minWidth: '35vw',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response && response === 'success') {
        this.loadCases();
      }
    });
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadCases();
  }

  searchChanged() {
    this.data.page = 0;
    this.loadCases();
  }

  filterOptionChanged(option) {
    this.searchStatus = option;
    this.searchChanged();
  }

  getLOS(row) {
    const startDate = new Date(row.admittanceDate);
    const today = new Date();

    startDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    const timeDifference = today.getTime() - startDate.getTime();
    const daysDifference = Math.ceil(
      Math.abs(Math.floor(timeDifference / (1000 * 60 * 60 * 24)))
    );

    if (row.status === 'active') {
      return daysDifference;
    } else {
      if (!row.closeDate) {
        return null;
      }
      const closeDate = new Date(row.closeDate);
      closeDate.setHours(0, 0, 0, 0);
      const timeDifference2 = closeDate.getTime() - startDate.getTime();
      const daysDifference2 = Math.ceil(
        Math.abs(Math.floor(timeDifference2 / (1000 * 60 * 60 * 24)))
      );
      return daysDifference2;
    }
  }

  getEstimatedTitle(row, type) {
    const startDate = new Date(row.admittanceDate);
    const today = new Date();
    const targetDate = new Date(row.expectedDischargeDate);

    startDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    targetDate.setHours(0, 0, 0, 0);
    // Calculate the difference in time and convert to days
    const timeDifference = targetDate.getTime() - today.getTime();
    let daysDifference = Math.ceil(
      Math.abs(Math.floor(timeDifference / (1000 * 60 * 60 * 24)))
    );
    let status: string;
    let outerColor: string;
    let innerColor: string;
    let percentage: string;

    const totalDuration = Math.ceil(
      (targetDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
    );
    const elapsedDays = Math.ceil(
      (today.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
    );
    // Calculate the percentage of time elapsed
    const elapsedPercentage =
      totalDuration > 0 ? (elapsedDays / totalDuration) * 100 : 0;

    // Determine if the date is in the past, today, or the future
    if (targetDate.toDateString() === today.toDateString()) {
      status = 'Days Remaining';
      outerColor = '#78C000';
      innerColor = '#C7E596';
      percentage = '100';
    } else if (targetDate > today) {
      if (daysDifference <= 1) {
        status = 'Day Remaining';
      } else {
        status = 'Days Remaining';
      }

      if (elapsedPercentage <= 30) {
        outerColor = '#78C000';
      } else if (elapsedPercentage <= 60) {
        outerColor = '#fff83a';
      } else if (elapsedPercentage <= 90) {
        outerColor = '#FDB900';
      } else {
        outerColor = '#FF6347';
      }

      innerColor = '#C7E596';
      percentage = elapsedPercentage.toFixed(2);
    } else {
      if (daysDifference <= 1) {
        status = 'Day Ago';
      } else {
        status = 'Days Ago';
      }

      outerColor = '#FF6347';
      innerColor = '#C7E596';
      percentage = '120';
    }
    if (type === 'days') {
      return daysDifference;
    } else if (type === 'colorOuter') {
      return outerColor;
    } else if (type === 'colorInner') {
      return innerColor;
    } else if (type === 'percentage') {
      return percentage;
    } else {
      return status;
    }
  }
}
