import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import moment from 'moment';
import { DataTablesModel } from 'src/app/_models';
import { greenChart, hexToRGBA, orangeChart, redChart, yellowChart } from 'src/app/shared/utilities/utilities';
import { CssrsApiService } from '../../../../../../../_services/assessments/addiction/cssrs.service';
@Component({
  selector: 'app-cssrs-charts',
  templateUrl: './cssrs-charts.component.html',
  styleUrl: './cssrs-charts.component.css'
})
export class CssrsChartsComponent implements OnInit {
  @Input() patientId: string;
  @Input() assessmentId: string;
  lineChartData: ChartConfiguration<'bar'>['data'] = {
    datasets: [{
      data: [], label: 'Risk Level', backgroundColor: '#2196F3', borderColor: '#333333',
    }]
  };

  lineChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        title: {
          display: false
        }
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            var score = context.parsed.y;
            return score > 2 ? 'High Risk' : score > 1 ? 'Moderate Risk' : score > 0 ? 'Low Risk' : '';
          }
        }
      },
      annotation: {
        annotations: {
          greenBg:
          {
            type: 'box',
            yMin: 0,
            yMax: 0.5,
            backgroundColor: hexToRGBA(greenChart.backgroundColor, 0.2),
            borderColor: greenChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          },
          yellowBg:
          {
            type: 'box',
            yMin: 0.5,
            yMax: 1.5,
            backgroundColor: hexToRGBA(yellowChart.backgroundColor, 0.2),
            borderColor: yellowChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          },
          orangeBg:
          {
            type: 'box',
            yMin: 1.5,
            yMax: 2.5,
            backgroundColor: hexToRGBA(orangeChart.backgroundColor, 0.2),
            borderColor: orangeChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          },
          redBg: {
            type: 'box',
            yMin: 2.5,
            backgroundColor: hexToRGBA(redChart.backgroundColor, 0.2),
            borderColor: redChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          }
        }
      }
    },
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        },
        min: 0,
        max: 3,
        ticks: {
          stepSize: 1,
          callback: function (value, index, ticks) {
            if (typeof value === 'number') {
              return value > 2 ? 'High Risk' : value > 1 ? 'Moderate Risk' : value > 0 ? 'Low Risk' : 'No Risk Identified';
            } else {
              return '';
            }
          }
        }
      }
    }
  };
  assessmentDetailChartOptions: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        title: {
          display: false
        }
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            var score = context.parsed.y;
            var label = '';
            if (score >= 10) {
              label += 'High Risk'
            } else if (score > 1) {
              label += 'Moderate Risk'
            } else if (score === 1) {
              label += 'Low Risk'
            }
            return label;
          }
        }
      },
    },
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        },
        min: 0,
        max: 1,
        ticks: {
          stepSize: 1,
          callback: function (value, index, ticks) {
            if (typeof value === 'number') {
              return value > 0 ? 'Yes' : 'No';
            } else {
              return 'No';
            }
          }
        }
      }
    }
  };
  lineChartType = 'bar';
  detailChartPlugins = [];
  data: DataTablesModel = {} as DataTablesModel;
  processing$ = false;
  isLoadingResults = false;
  error: string = '';
  scoreMap = new Map();
  scoreDetails: any[] = [];
  constructor(
    public cssrsApiService: CssrsApiService,
    private route: ActivatedRoute) {
  }
  ngOnInit() {
    this.data.page = 0;
    this.data.per_page = 10;
    this.loadCssrsAssessments();
  }
  public loadCssrsAssessments() {
    this.isLoadingResults = true;
    this.error = '';
    this.cssrsApiService.getCssrsList(this.patientId, this.assessmentId, this.data.per_page, this.data.page).subscribe(response => {
      if (response && response.items) {
        this.data.items = response.items;
        this.data.total = response.total;
        var riskScores = [];
        var bgColors = [];
        var labels = [];
        this.scoreDetails = [];
        for (var item of response.items) {
          // if (response.items.length === 1) {
          //   labels.push("");

          //   let riskScore = 0;
          //   if (item.riskScore < 1) {
          //     riskScore = 0;
          //   } else if (item.riskScore < 3) {
          //     riskScore = 1;
          //   } else if (item.riskScore < 10) {
          //     riskScore = 2;
          //   } else {
          //     riskScore = 3;
          //   }
          //   riskScores.push(riskScore);
          // }

          let riskScore = 0;
          if (item.riskScore < 1) {
            riskScore = 0;
            bgColors.push(hexToRGBA(greenChart.backgroundColor, 1));
          } else if (item.riskScore < 3) {
            riskScore = 1;
            bgColors.push(hexToRGBA(yellowChart.backgroundColor, 1));
          } else if (item.riskScore < 10) {
            riskScore = 2;
            bgColors.push(hexToRGBA(orangeChart.backgroundColor, 1));
          } else {
            riskScore = 3;
            bgColors.push(hexToRGBA(redChart.backgroundColor, 1));
          }
          riskScores.push(riskScore);

          var stillUtc = moment.utc(item.createdDate).toDate();
          var assessmentDate = moment(stillUtc).local().format('MM/DD/YY');
          labels.push(assessmentDate);
          for (var key in item) {
            if (item.hasOwnProperty(key)) {
              if (item[key] && item[key].hasOwnProperty("score")) {
                var scoreObj = item[key];
                var scores = [];
                if (this.scoreMap.get(key)) {
                  scores = this.scoreMap.get(key).scores;
                }
                // if (response.items.length === 1) {
                //   scores.push(scoreObj["score"]);
                // }
                scores.push(scoreObj["score"]);
                var returnObj = { "scores": scores, "key": key, "title": scoreObj["title"], "answer": scoreObj["answer"], "chartData": [], "chartColor": this.getChartColor(scores) };
                this.scoreMap.set(key, returnObj);
              }
            }
          }
        }
        this.scoreMap.forEach((value, key) => {
          let dataset: any = {
            datasets: [
              {
                "data": value.scores,
                "borderColor": value.chartColor.borderColor,
                "backgroundColor": value.chartColor.backgroundColor,
                "tension": 0.4,
                pointBackgroundColor: '#333333',
                pointRadius: 5
              }
            ],
            "labels": labels,
            "title": value.title,
            "answer": value.answer
          };
          this.scoreDetails.push(dataset)
        });
        this.lineChartData.datasets[0].data = riskScores;
        this.lineChartData.datasets[0].backgroundColor = bgColors;
        this.lineChartData.labels = labels;
        this.isLoadingResults = false;
      }
      if (response == null) {
        this.data.items = [];
        this.data.total = 0;
      }
    },
      error => {
        this.error = error;
        this.isLoadingResults = false;
      })
  }
  getChartColor(scoringArray: any[]) {
    var score = scoringArray[scoringArray.length - 1];
    if (score === 1) {
      return yellowChart;
    } else if (score > 1 && score < 10) {
      return orangeChart;
    } else if (score >= 10) {
      return redChart;
    } else {
      return greenChart;
    }
  }
}
