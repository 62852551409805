import { Component, Inject, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IAssessmentGoal } from 'src/app/_models/configure/assessment-goal.model';
import {
  AssessmentHistoryApiService,
  ToastMessageService,
} from 'src/app/_services';

@Component({
  selector: 'app-patient-goal-dialog',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css'],
})
export class AddPatientGoalDialog implements OnInit {
  public action: string;
  public oldGoal: IAssessmentGoal;
  private newGoal: IAssessmentGoal;
  goalsList: any;
  assessmentId: string;

  numbers = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];

  editProcessing: boolean = false;
  showFollowUp: boolean = false;

  public patientAssessmentGoalForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public addEditDialogRef: MatDialogRef<AddPatientGoalDialog>,
    private formBuilder: FormBuilder,
    private assessmenthistoryApiService: AssessmentHistoryApiService,
    private toastMessageService: ToastMessageService
  ) {
    this.action = data.action;
    this.goalsList = data.goalsList;
    this.assessmentId = data.assessmentId;

    if (this.action === 'EDIT') {
      this.oldGoal = data.goal;
    }
  }

  ngOnInit(): void {
    this.buildForm();

    if (this.goalsList == null) {
      this.goalsList = [];
    }

    if (this.action === 'EDIT') {
      this.patientAssessmentGoalForm.controls['id'].setValue(this.oldGoal.id);
      this.patientAssessmentGoalForm.controls['organizationId'].setValue(
        this.oldGoal.organizationId
      );
      this.patientAssessmentGoalForm.controls['careProviderId'].setValue(
        this.oldGoal.careProviderId
      );
      this.patientAssessmentGoalForm.controls['assessmentType'].setValue(
        this.oldGoal.assessmentType
      );
      this.patientAssessmentGoalForm.controls['question'].setValue(
        this.oldGoal.question
      );
      this.patientAssessmentGoalForm.controls['answers'].setValue(
        this.oldGoal.answers
      );
      this.patientAssessmentGoalForm.controls['description'].setValue(
        this.oldGoal.description
      );
      if (this.oldGoal && this.oldGoal.objectives) {
        this.patientAssessmentGoalForm.controls['objectives'].setValue(
          this.oldGoal.objectives
        );
      }
      this.patientAssessmentGoalForm.controls['status'].setValue(
        this.oldGoal.status
      );
      this.changeFollowUp();
    }
  }

  buildForm() {
    this.patientAssessmentGoalForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(null),
      careProviderId: new FormControl(null),
      assessmentType: new FormControl(null),

      classfieldName: new FormControl(),
      question: new FormControl(),
      answers: new FormControl(),

      description: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      objectives: this.formBuilder.array([this.createObjective('', '', '')]),
      status: new FormControl('Active'),
    });
  }

  createObjective(
    objectiveDesc: string,
    targetNumber: string,
    targetType: string
  ): FormGroup {
    return new FormGroup({
      objectiveDescription: new FormControl(objectiveDesc, Validators.required),
      targetNumber: new FormControl(targetNumber),
      targetType: new FormControl(targetType),
    });
  }

  objectives(): FormArray {
    return this.patientAssessmentGoalForm.get('objectives') as FormArray;
  }

  closeEditDialog(response) {
    if (response === 'Yes') {
      this.editProcessing = true;
      // Add a new goal
      if (this.action === 'ADD') {
        this.newGoal = Object.assign({}, this.newGoal);
        this.newGoal = Object.assign(
          this.newGoal,
          this.patientAssessmentGoalForm.value
        );
        this.goalsList.push(this.newGoal);
      } // Update the goal
      else if (this.action === 'EDIT') {
        let index = this.goalsList.findIndex((element) => {
          if (element.description === this.oldGoal.description) return true;
        });

        if (index >= 0) {
          this.goalsList[index].targetNumber =
            this.patientAssessmentGoalForm.controls['targetNumber'].value;
          this.goalsList[index].targetType =
            this.patientAssessmentGoalForm.controls['targetType'].value;
          this.goalsList[index].followUp =
            this.patientAssessmentGoalForm.controls['followUp'].value;
          this.goalsList[index].followUpNumber =
            this.patientAssessmentGoalForm.controls['followUpNumber'].value;
          this.goalsList[index].followUpType =
            this.patientAssessmentGoalForm.controls['followUpType'].value;
        }
      }

      // Update the goals list for this assessment history
      this.assessmenthistoryApiService
        .updateAssessmentGoals(this.assessmentId, this.goalsList)
        .subscribe(
          (response) => {
            this.editProcessing = false;
            this.addEditDialogRef.close({
              response: 'reload',
              updatedGoals: this.goalsList,
            });
            this.toastMessageService.displaySuccessMessage(
              'Assessment goal(s) have been updated.'
            );
          },
          (error) => {
            this.editProcessing = false;
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to update the goals.'
            );
          }
        );
    } else {
      this.addEditDialogRef.close();
    }
  }

  changeFollowUp() {
    if (this.patientAssessmentGoalForm.controls['followUp'].value === 'Yes') {
      this.showFollowUp = true;
    } else {
      this.showFollowUp = false;
      this.patientAssessmentGoalForm.controls[
        'followUpNumber'
      ].clearValidators();
      this.patientAssessmentGoalForm.controls['followUpNumber'].reset();
      this.patientAssessmentGoalForm.controls['followUpType'].clearValidators();
      this.patientAssessmentGoalForm.controls['followUpType'].reset();
    }
  }
}
