import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { IYoungMania } from 'src/app/_models/assessments/bipolar/youngmania.model';
import { ToastMessageService, YoungManiaApiService } from 'src/app/_services/';

@Component({
  selector: 'app-young-mania',
  templateUrl: './young-mania.component.html',
  styleUrls: ['./young-mania.component.css'],
})
export class YoungManiaComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public youngManiaApiService: YoungManiaApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) {}
  public riskScore: number;
  public youngManiaForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  youngManiaAssessment: IYoungMania;
  youngManiaAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.youngManiaForm, this.errors);

    this.loadYoungManiaAssessment();
  }

  loadYoungManiaAssessment() {
    this.youngManiaForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.youngManiaApiService
        .getYoungManiaDetails(this.assessmentId)
        .subscribe((youngManiaAssessment) => {
          if (youngManiaAssessment.data) {
            this.youngManiaForm.controls['mood'].setValue(
              youngManiaAssessment.data.mood.answer
            );
            this.youngManiaForm.controls['activity'].setValue(
              youngManiaAssessment.data.activity.answer
            );
            this.youngManiaForm.controls['interests'].setValue(
              youngManiaAssessment.data.interests.answer
            );
            this.youngManiaForm.controls['rest'].setValue(
              youngManiaAssessment.data.rest.answer
            );
            this.youngManiaForm.controls['anoyyed'].setValue(
              youngManiaAssessment.data.anoyyed.answer
            );
            this.youngManiaForm.controls['speaking'].setValue(
              youngManiaAssessment.data.speaking.answer
            );
            this.youngManiaForm.controls['thoughts'].setValue(
              youngManiaAssessment.data.thoughts.answer
            );
            this.youngManiaForm.controls['content'].setValue(
              youngManiaAssessment.data.content.answer
            );
            this.youngManiaForm.controls['behavior'].setValue(
              youngManiaAssessment.data.behavior.answer
            );
            this.youngManiaForm.controls['visualAppearance'].setValue(
              youngManiaAssessment.data.visualAppearance.answer
            );
            this.youngManiaForm.controls['change'].setValue(
              youngManiaAssessment.data.change.answer
            );

            this.riskScore = youngManiaAssessment.data.riskScore;
          }
        });
    }
  }

  onFormSubmit() {
    if (this.youngManiaForm.invalid) {
      this.youngManiaForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.youngManiaAssessment = Object.assign({}, this.youngManiaAssessment);
    this.youngManiaAssessment.patientId =
      this.youngManiaForm.controls['patientId'].value;
    this.youngManiaAssessment.sessionId =
      this.youngManiaForm.controls['sessionId'].value;
    this.youngManiaAssessment.assessmentRequestId =
      this.youngManiaForm.controls['assessmentRequestId'].value;
    this.youngManiaAssessment.mood = {
      answer: this.youngManiaForm.controls['mood'].value,
    };
    this.youngManiaAssessment.activity = {
      answer: this.youngManiaForm.controls['activity'].value,
    };
    this.youngManiaAssessment.interests = {
      answer: this.youngManiaForm.controls['interests'].value,
    };
    this.youngManiaAssessment.rest = {
      answer: this.youngManiaForm.controls['rest'].value,
    };
    this.youngManiaAssessment.anoyyed = {
      answer: this.youngManiaForm.controls['anoyyed'].value,
    };
    this.youngManiaAssessment.speaking = {
      answer: this.youngManiaForm.controls['speaking'].value,
    };
    this.youngManiaAssessment.thoughts = {
      answer: this.youngManiaForm.controls['thoughts'].value,
    };
    this.youngManiaAssessment.content = {
      answer: this.youngManiaForm.controls['content'].value,
    };
    this.youngManiaAssessment.behavior = {
      answer: this.youngManiaForm.controls['behavior'].value,
    };
    this.youngManiaAssessment.visualAppearance = {
      answer: this.youngManiaForm.controls['visualAppearance'].value,
    };
    this.youngManiaAssessment.change = {
      answer: this.youngManiaForm.controls['change'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.youngManiaAssessment.accessCode = this.accessCode;

      this.youngManiaApiService
        .addGuestYoungMania(this.youngManiaAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Young Mania Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Young Mania Assessment'
            );
          }
        );
    } else {
      this.youngManiaApiService
        .addYoungMania(this.youngManiaAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Young Mania Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Young Mania Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    mood: [{ type: 'required', message: 'Elevated Mood is required' }],
    activity: [
      {
        type: 'required',
        message: 'Increased Motor Activity or Energy is required',
      },
    ],
    interests: [{ type: 'required', message: 'Sexual Interest is required' }],
    rest: [{ type: 'required', message: 'Sleep is required' }],
    anoyyed: [{ type: 'required', message: 'Irritability is required' }],
    speaking: [
      { type: 'required', message: 'Speech: Rate & Amount is required' },
    ],
    thoughts: [
      { type: 'required', message: 'Language: Thought Disorder is required' },
    ],
    content: [{ type: 'required', message: 'Content is required' }],
    behavior: [
      {
        type: 'required',
        message: 'Disruptive or Aggressive Behavior is required',
      },
    ],
    visualAppearance: [{ type: 'required', message: 'Appearance is required' }],
    change: [{ type: 'required', message: 'Insight is required' }],
  };

  private buildForm() {
    this.youngManiaForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      mood: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      activity: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      interests: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      rest: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      anoyyed: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      speaking: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      thoughts: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      content: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      behavior: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      visualAppearance: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      change: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.youngManiaForm.valueChanges.subscribe(() => this.onValueChanged());
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.youngManiaForm) {
      return;
    }
    const form = this.youngManiaForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
