import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ManualEligibilityModalComponent } from './manual-eligibility-modal/manual-eligibility-modal.component';
var SockJs = require('sockjs-client');
var Stomp = require('stompjs');

@Component({
  selector: 'app-eligibility-records',
  templateUrl: './eligibility-records.component.html',
  styleUrl: './eligibility-records.component.css',
})
export class EligibilityRecordsComponent {
  public stompClient: any;
  reloadEligibilityReport: Subject<boolean> = new Subject<boolean>();

  constructor(public dialog: MatDialog) {}

  openEligibilityModal(): void {
    const dialogRef = this.dialog.open(ManualEligibilityModalComponent, {
      disableClose: true,
      autoFocus: false,
      minWidth: '45vw',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.reloadEligibilityReport.next(true);
        this.listenToWebSocket(result.message.code);
      }
    });
  }

  listenToWebSocket(recordId: string) {
    let websocketChannel = '/message/eligibilityCheckStatus/' + recordId;

    const serverUrl = `${environment.apiUrl}/mw-socket`;
    const ws = new SockJs(serverUrl);
    this.stompClient = Stomp.over(ws);
    const that = this;

    // Listening to web socket event
    this.stompClient.connect({}, function (frame) {
      that.stompClient.subscribe(websocketChannel, (message) => {
        if (message) {
          if (message?.body) {
            that.disconnect();
            this.reloadEligibilityReport.next(true);
          }
        }
      });
    });
  }

  public disconnect() {
    if (this.stompClient !== null) {
      this.stompClient.disconnect();
    }
  }

  public refresh() {
    this.reloadEligibilityReport.next(true);
  }
}
