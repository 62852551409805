import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { mmddyyyyStringFromDate } from 'src/app/shared/utilities/utilities';
import { Case, Countries, DataTablesModel } from 'src/app/_models';
import { DocumentHttpService, ToastMessageService } from 'src/app/_services';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';

@Component({
  selector: 'app-print-carelog-report',
  templateUrl: './print-carelog-report.component.html',
  styleUrls: ['./print-carelog-report.component.css'],
})
export class PrintCarelogReportComponent implements OnInit {
  @Input() case: Case;
  @ViewChild('pdfViewerOnDemand', { static: false }) pdfViewerOnDemand;

  public searchForm: FormGroup;

  data: DataTablesModel = {} as DataTablesModel;

  shiftNumber = null;
  fromDate = null;
  toDate = null;
  pdfSrc = null;

  displayNoRecordsMsg = false;
  isIndianOrg: boolean = false;
  processing: boolean = false;
  orgConfig: any;
  totalShifts: number = 3;
  todaysDate = new Date();
  constructor(
    private formBuilder: FormBuilder,
    public documentApiService: DocumentHttpService,
    private _dateAdapter: DateAdapter<any>,
    private localStorageService: LocalStorageService,
    private orgConfigSandbox: OrgConfigSandbox,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit(): void {
    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          this.orgConfig = orgConfig;
          if (this.orgConfig?.organizationCountry === Countries.India) {
            this.isIndianOrg = true;
          }
          this.totalShifts = this.orgConfig.totalShiftsPerDay;
        });
      }
    });

    this.buildForm();
  }

  private buildForm() {
    this.searchForm = this.formBuilder.group({
      fromDate: new FormControl('', Validators.compose([Validators.required])),
      toDate: new FormControl('', Validators.compose([Validators.required])),
      shiftNumber: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
    });
    this.searchForm.valueChanges.subscribe(() => this.onFormSubmit());
  }

  public validation_messages = {
    shiftNumber: [{ type: 'required', message: 'Shift Number  is required' }],
    fromDate: [{ type: 'required', message: 'From Date  is required' }],
    toDate: [{ type: 'required', message: 'To Date  is required' }],
  };

  onFormSubmit() {
    this.displayNoRecordsMsg = false;
    if (this.searchForm.invalid) {
      this.searchForm.markAllAsTouched();
      return;
    }
    let searchDetails = {};
    searchDetails = Object.assign(searchDetails, this.searchForm.value);

    // this.pdfViewerOnDemand.pdfSrc = `${environment.apiUrl}/patient-services/reports/case?caseId=${this.case.id}&logDate=${mmddyyyyStringFromDate(new Date(this.searchForm.controls['fromDate'].value))}&shiftNumber=${this.searchForm.controls['shiftNumber'].value}`;
    // this.pdfViewerOnDemand.refresh();
    this.processing = true;

    this.documentApiService
      .printDocument(
        this.case.id,
        mmddyyyyStringFromDate(
          new Date(this.searchForm.controls['fromDate'].value)
        ),
        mmddyyyyStringFromDate(
          new Date(this.searchForm.controls['toDate'].value)
        ),
        this.searchForm.controls['shiftNumber'].value
      )
      .subscribe(
        (res) => {
          this.processing = false;
          if (res) {
            this.pdfViewerOnDemand.pdfSrc = res;
            this.pdfViewerOnDemand.refresh();
            this.displayNoRecordsMsg = false;
          } else {
            this.pdfViewerOnDemand.pdfSrc = null;
            this.pdfViewerOnDemand.refresh();
            this.displayNoRecordsMsg = true;
          }
        },
        (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Something went wrong'
          );
        }
      );
  }

  clearForm() {
    this.searchForm.controls['shiftNumber'].setValue('');
    this.searchForm.controls['fromDate'].setValue('');
    this.searchForm.controls['toDate'].setValue('');

    this.shiftNumber = null;
    this.fromDate = null;
    this.toDate = null;
    this.pdfViewerOnDemand.pdfSrc = null;
    this.pdfViewerOnDemand.refresh();
    this.displayNoRecordsMsg = false;

    this.data = {} as DataTablesModel;
  }
}
