import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import 'chartjs-plugin-annotation';
import moment from 'moment';
import { DataTablesModel } from 'src/app/_models';
import { CprsApiService } from 'src/app/_services/assessments/relationship-family/cprs.service';
import { greenChart, hexToRGBA, orangeChart, redChart, yellowChart } from 'src/app/shared/utilities/utilities';
@Component({
  selector: 'app-cprs-charts',
  templateUrl: './cprs-charts.component.html',
  styleUrls: ['./cprs-charts.component.css']
})
export class CprsChartsComponent implements OnInit {
  @Input() patientId: string;
  @Input() assessmentId: string;

  closenessChartData: ChartConfiguration<'line'>['data'] = {
    datasets: [
      {
        tension: 0.4,
        data: [], borderColor: '#EEEEEE',
        pointBackgroundColor: '#333333',
        pointBorderColor: '#FFFFFF',
        pointHoverBackgroundColor: '#FFFFFF',
        pointHoverBorderColor: '#333333'
      },
    ]
  };
  conflictChartData: ChartConfiguration<'line'>['data'] = {
    datasets: [
      {
        tension: 0.4,
        data: [], borderColor: '#EEEEEE',
        pointBackgroundColor: '#333333',
        pointBorderColor: '#FFFFFF',
        pointHoverBackgroundColor: '#FFFFFF',
        pointHoverBorderColor: '#333333'
      },
    ]
  };
  dependenceChartData: ChartConfiguration<'line'>['data'] = {
    datasets: [
      {
        tension: 0.4,
        data: [], borderColor: '#EEEEEE',
        pointBackgroundColor: '#333333',
        pointBorderColor: '#FFFFFF',
        pointHoverBackgroundColor: '#FFFFFF',
        pointHoverBorderColor: '#333333'
      },
    ]
  };

  closenessChartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        title: {
          display: false
        }
      },
      annotation: {
        annotations: [
          {
            type: 'box',
            yMin: 0,
            yMax: 50,
            backgroundColor: hexToRGBA(greenChart.backgroundColor, 1),
            borderColor: greenChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          },
          {
            type: 'line',
            yMin: 35.5,
            yMax: 35.5,
            borderColor: 'blue',
            borderWidth: 3,
            borderDash: [5],
            label: {
              content: 'Mean Scores for Father: 35-36',
              backgroundColor: 'blue',
              display: true,
              yAdjust: 15,
              xAdjust: 15,
            }
          },
          {
            type: 'line',
            yMin: 37,
            yMax: 37,
            borderColor: 'pink',
            borderWidth: 3,
            borderDash: [5],
            label: {
              content: 'Mean Scores for Mother: 37',
              backgroundColor: 'pink',
              display: true,
              yAdjust: -15,
              xAdjust: 15,
            }
          }]
      },
    },
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        },
        min: 0,
        max: 50,
      }
    }
  };
  conflictChartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        title: {
          display: false
        }
      },
      annotation: {
        annotations: [
          {
            type: 'box',
            yMin: 0,
            yMax: 60,
            backgroundColor: hexToRGBA(redChart.backgroundColor, 1),
            borderColor: redChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          }, {
            type: 'line',
            yMin: 14.5,
            yMax: 14.5,
            borderColor: 'blue',
            borderWidth: 3,
            borderDash: [5],
            label: {
              content: 'Mean Scores for Father: 14-15',
              backgroundColor: 'blue',
              display: true,
              yAdjust: 15,
              xAdjust: 15,
            }
          },
          {
            type: 'line',
            yMin: 15.5,
            yMax: 15.5,
            borderColor: 'pink',
            borderWidth: 3,
            borderDash: [5],
            label: {
              content: 'Mean Scores for Mother: 15-16',
              backgroundColor: 'pink',
              display: true,
              yAdjust: -15,
              xAdjust: 15,
            }
          }]
      },
    },
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        },
        min: 0,
        max: 60,
      }
    }
  };
  dependenceChartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        title: {
          display: false
        }
      },
      annotation: {
        annotations: {
          greenBg:
          {
            type: 'box',
            yMin: 0,
            yMax: 10.5,
            backgroundColor: hexToRGBA(greenChart.backgroundColor, 1),
            borderColor: greenChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          },
          redBg: {
            type: 'box',
            yMin: 10.5,
            yMax: 20,
            backgroundColor: hexToRGBA(redChart.backgroundColor, 1),
            borderColor: redChart.borderColor,
            drawTime: 'beforeDatasetsDraw',
          }
        }
      }
    },
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        },
        min: 0,
        max: 20,
      }
    }
  };
  assessmentDetailChartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        title: {
          display: false
        }
      }
    },
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        min: 1,
        max: 5,
        ticks: { stepSize: 1 },
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      }
    }
  };
  lineChartLegend = false;
  closenessChartPlugins = [{
    // afterDraw: function (chart, args, options) {
    //   var lineAt = chart.config.options.lineAt;
    //   var ctxPlugin = chart.chart.ctx;
    //   var xAxe = chart.scales[chart.config.options.scales.x[0].id];
    //   var yAxe = chart.scales[chart.config.options.scales.y[0].id];
    //   ctxPlugin.strokeStyle = "red";
    //   ctxPlugin.beginPath();
    //   lineAt = yAxe.getPixelForValue(lineAt);
    //   ctxPlugin.moveTo(xAxe.left, lineAt);
    //   ctxPlugin.lineTo(xAxe.right, lineAt);
    //   ctxPlugin.stroke();
    // }
  }];
  conflictChartPlugins = [{
    // afterDraw: function (chart, args, options) {
    //   var lineAt = chart.config.options.lineAt;
    //   var ctxPlugin = chart.chart.ctx;
    //   var xAxe = chart.scales[chart.config.options.scales.x[0].id];
    //   var yAxe = chart.scales[chart.config.options.scales.y[0].id];
    //   ctxPlugin.strokeStyle = "red";
    //   ctxPlugin.beginPath();
    //   lineAt = yAxe.getPixelForValue(lineAt);
    //   ctxPlugin.moveTo(xAxe.left, lineAt);
    //   ctxPlugin.lineTo(xAxe.right, lineAt);
    //   ctxPlugin.stroke();
    // }
  }];
  dependenceChartPlugins = [{
    // afterDraw: function (chart, args, options) {
    //   var lineAt = chart.config.options.lineAt;
    //   var ctxPlugin = chart.chart.ctx;
    //   var xAxe = chart.scales[chart.config.options.scales.x[0].id];
    //   var yAxe = chart.scales[chart.config.options.scales.y[0].id];
    //   ctxPlugin.strokeStyle = "red";
    //   ctxPlugin.beginPath();
    //   lineAt = yAxe.getPixelForValue(lineAt);
    //   ctxPlugin.moveTo(xAxe.left, lineAt);
    //   ctxPlugin.lineTo(xAxe.right, lineAt);
    //   ctxPlugin.stroke();
    // }
  }];
  lineChartType = 'line';
  detailChartPlugins = [];
  data: DataTablesModel = {} as DataTablesModel;
  processing$ = false;
  isLoadingResults = true;
  error: string = '';
  scoreMap = new Map();
  scoreDetails: any[] = [];
  constructor(public core10AssessmentApiService: CprsApiService,
    private route: ActivatedRoute) {
  }
  ngOnInit() {
    this.isLoadingResults = true;
    this.data.page = 0;
    this.data.per_page = 10;
    this.loadCprsAssessments();
  }
  public loadCprsAssessments() {
    this.isLoadingResults = true;
    this.error = '';
    this.core10AssessmentApiService.getCprsList(this.patientId, this.assessmentId, this.data.per_page, this.data.page).subscribe(response => {
      if (response && response.items) {
        this.data.items = response.items;
        this.data.total = response.total;
        var closenessRiskScores = [];
        var conflictRiskScores = [];
        var dependenceRiskScores = [];
        var labels = [];
        this.scoreDetails = [];

        for (var item of response.items) {
          let i = 1;
          if (response.items.length === 1) {
            labels.push("");
            if (item.props) {
              closenessRiskScores.push(item.props['closeness']);
              conflictRiskScores.push(item.props['conflict']);
              dependenceRiskScores.push(item.props['dependence']);
            } else {
              closenessRiskScores.push(0);
              conflictRiskScores.push(0);
              dependenceRiskScores.push(0);
            }
          }
          if (item.props) {
            closenessRiskScores.push(item.props['closeness']);
            conflictRiskScores.push(item.props['conflict']);
            dependenceRiskScores.push(item.props['dependence']);
          } else {
            closenessRiskScores.push(0);
            conflictRiskScores.push(0);
            dependenceRiskScores.push(0);
          }

          var stillUtc = moment.utc(item.createdDate).toDate();
          var assessmentDate = moment(stillUtc).local().format('MM/DD/YY');
          labels.push(assessmentDate);
          for (var key in item) {
            if (item.hasOwnProperty(key)) {
              if (item[key] && item[key].hasOwnProperty("score")) {
                var scoreObj = item[key];
                var scores = [];
                if (this.scoreMap.get(key)) {
                  scores = this.scoreMap.get(key).scores;
                }
                if (response.items.length === 1) {
                  scores.push(scoreObj["score"]);
                }
                scores.push(scoreObj["score"]);
                var chartType = this.chartType(i);
                var returnObj = { "scores": scores, "key": key, "title": scoreObj["title"], "answer": scoreObj["answer"], "categoryName": chartType, "chartData": [], "chartColor": this.getChartColor(chartType, scores) };
                this.scoreMap.set(key, returnObj);
                i++;
              }
            }
          }
        }
        this.scoreMap.forEach((value, key) => {
          let dataset: any = {
            datasets: [
              {
                "data": value.scores,
                "borderColor": value.chartColor.borderColor,
                "backgroundColor": value.chartColor.backgroundColor,
                "tension": 0.4,
                "fill": true,
                pointBackgroundColor: '#333333',
                pointRadius: 5
              }
            ],
            "labels": labels,
            "title": value.title,
            "answer": value.answer,
            "categoryName": value.categoryName
          };
          this.scoreDetails.push(dataset);
        });
        this.closenessChartData.datasets[0].data = closenessRiskScores;
        this.conflictChartData.datasets[0].data = conflictRiskScores;
        this.dependenceChartData.datasets[0].data = dependenceRiskScores;

        this.closenessChartData.labels = labels;
        this.conflictChartData.labels = labels;
        this.dependenceChartData.labels = labels;

        this.isLoadingResults = false;
      }
      if (response == null) {
        this.data.items = [];
        this.data.total = 0;
      }
    },
      error => {
        this.error = error;
        this.isLoadingResults = false;
      })
  }
  getChartColor(chartType: string, scoringArray: any[]) {
    var score = scoringArray[scoringArray.length - 1];
    if (chartType === 'Conflicts') {
      if (score > 4) {
        return redChart;
      } else if (score > 3) {
        return orangeChart;
      } else if (score > 2) {
        return yellowChart;
      } else {
        return greenChart;
      }
    } else {
      if (score <= 2) {
        return redChart;
      } else if (score === 3) {
        return yellowChart;
      } else if (score === 4) {
        return orangeChart;
      } else {
        return greenChart;
      }
    }
  }
  chartType(questionNumber: number) {
    var categoryName = "";
    switch (questionNumber) {
      case 2:
      case 12:
      case 14:
      case 17:
      case 18:
      case 19:
      case 21:
      case 23:
      case 24:
      case 25:
      case 27:
      case 28:
        categoryName = 'Conflicts';
        break;
      case 1:
      case 3:
      case 5:
      case 8:
      case 10:
      case 13:
      case 16:
      case 22:
      case 29:
      case 30:
        categoryName = 'Closeness';
        break;
      case 6:
      case 9:
      case 11:
      case 26:
        categoryName = 'Dependence';
        break;
      default:
        categoryName = 'n/a';
        break;
    }
    return categoryName;
  }
}