import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { DataTablesModel } from 'src/app/_models';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class MemberPaymentHistoryApiService {
  constructor(private httpClient: HttpClient) {}

  getMemberPaymentHistory(
    memberId: string,
    status: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();

    if (status) params = params.append('status', status);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageIndex', pageIndex.toString());

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/memberpaymenthistory/${memberId}`,
      { params: params }
    );
  }

  refundMemberPayment(paymentHistoryId: string): Observable<void> {
    return this.httpClient
      .delete<void>(
        `${environment.apiUrl}/refundmemberpayment/${paymentHistoryId}`,
        {}
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
}
