import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { OrgConfigSandbox } from 'src/app/shared/sandbox/org-config.sandbox';
import {
  formatDate,
  formatDateTime,
  mmddyyyyhhmiToDate,
  randonString,
} from 'src/app/shared/utilities/utilities';
import { CareLog, Case, Countries } from 'src/app/_models';
import { CareLogHttpService, ToastMessageService } from 'src/app/_services/';
import { LocalStorageService } from 'src/app/_services/local-storage.service';

@Component({
  selector: 'app-carelog-add',
  templateUrl: './carelog-add.component.html',
  styleUrls: ['./carelog-add.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AddCareLogDialogComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() action: string;
  @Input() patientId: string;
  @Input() case: Case;
  @Input() logDate: Date;
  @Input() shift: number;
  @Input() carelog: CareLog;
  @Input() goals: any[];
  @Input() goalDrivenCare: boolean;
  @Output() taskCompleted = new EventEmitter<any>();

  addingCustomNote: boolean = true;
  orgConfig: any;
  isIndianOrg: boolean = false;
  isProcessing: boolean = false;

  todaysDate = new Date();

  constructor(
    public careLogHttpService: CareLogHttpService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private dateTimeAdapter: DateTimeAdapter<any>,
    private localStorageService: LocalStorageService,
    private toastMessageService: ToastMessageService,
    private orgConfigSandbox: OrgConfigSandbox
  ) {
    this.orgConfigSandbox.orgConfigLoading$.subscribe((response) => {
      if (!response) {
        this.orgConfigSandbox.orgConfig$.subscribe((orgConfig) => {
          this.orgConfig = orgConfig;
          if (this.orgConfig?.organizationCountry === Countries.India) {
            this.isIndianOrg = true;
            dateTimeAdapter.setLocale('en-GB');
          }
        });
      }
    });

    if (this.goals) {
      this.goals = this.goals.filter((goal) => goal.status !== 'DELETED');
    } else {
      this.goals = [];
    }
    if (this.carelog) this.addingCustomNote = false;
  }

  public caseForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  note: CareLog;
  accountError: Error = null;
  errors: any = {};
  processing: boolean = false;

  public ngOnInit() {
    this.buildForm();
    if (this.carelog) {
      this.caseForm.controls['message'].setValue(this.carelog.message);
      this.caseForm.controls['goal'].setValue(this.carelog.goal);
      if (this.goals) {
        let goalIds = [];
        for (let i = 0; i < this.goals.length; i++) {
          goalIds.push(this.goals[i].id);
        }
        this.caseForm.controls['goals'].setValue(this.carelog.goals);
      }
      this.caseForm.controls['customMessage'].setValue(
        this.carelog.customMessage
      );
      this.caseForm.controls['activityDateTime'].setValue(
        mmddyyyyhhmiToDate(this.carelog.activityDateTime)
      );
    } else {
      this.caseForm.controls['activityDateTime'].setValue(
        new Date(this.logDate)
      );
    }

    this.errorHandler.handleErrors(this.caseForm, this.errors);
  }

  private buildForm() {
    this.caseForm = this.formBuilder.group({
      id: new FormControl(null),
      organizationId: new FormControl(null),
      patientId: new FormControl(''),
      message: new FormControl('', Validators.required),
      customMessage: new FormControl(''),
      caseNumber: new FormControl(null),
      goal: new FormControl(null, Validators.compose([])),
      activityDateTime: new FormControl(null, Validators.required),
      goals: new FormControl(
        null,
        Validators.compose([
          this.goalDrivenCare && (!this.carelog || !this.carelog.goal)
            ? Validators.required
            : null,
        ])
      ),
    });
  }

  onCaseNoteSubmit() {
    if (this.caseForm.invalid) {
      this.caseForm.markAllAsTouched();
      return;
    }

    this.note = Object.assign({}, this.note);
    this.note = Object.assign(this.note, this.caseForm.value);
    this.note.goal = this.caseForm.controls['goal'].value;
    this.note.goals = this.caseForm.controls['goals'].value;
    if (this.note.goals && this.note.goals.length == 0) this.note.goals = null;
    this.note.patientId = this.case.patientId;

    this.note.activityDateTime = formatDateTime(
      this.caseForm.controls['activityDateTime'].value
    );

    this.processing = true;
    if (this.carelog) {
      this.note.logDate = this.carelog.logDate;
      this.note.logNumber = this.carelog.logNumber;
      this.note.shift = this.carelog.shift;
      this.note.id = this.carelog.id;
      this.note.caseId = this.case.id;
      this.careLogHttpService.editCareLog(this.case.id, this.note).subscribe(
        () => {
          this.processing = false;
          let outputResponse = { note: this.note, response: 'success' };
          this.taskCompleted.emit(outputResponse);
          this.toastMessageService.displaySuccessMessage(
            'Care log has been updated'
          );
        },
        (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Failed to update care log'
          );
        }
      );
    } else {
      this.note.logDate = formatDate(this.logDate);
      this.note.caseId = this.case.id;
      this.note.logNumber = randonString(7);
      this.note.shift = this.shift;
      this.careLogHttpService.addCareLog(this.case.id, this.note).subscribe(
        () => {
          this.processing = false;
          let outputResponse = { note: this.note, response: 'success' };
          this.taskCompleted.emit(outputResponse);
          this.toastMessageService.displaySuccessMessage(
            'Care log has been added'
          );
        },
        (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Failed to add new care log'
          );
        }
      );
    }
  }
}
