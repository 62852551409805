import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { IReferringProvider } from 'src/app/_models';
import {
  ReferringProviderAPIService,
  ToastMessageService,
} from 'src/app/_services';
import { Gender } from 'src/app/shared/utilities/billing/gender';
import { USStates } from 'src/app/shared/utilities/billing/states';
import { formatDate } from 'src/app/shared/utilities/utilities';
import { LookupNpiComponent } from '../lookup-npi/lookup-npi.component';
import {
  NgxGpAutocompleteDirective,
  NgxGpAutocompleteOptions,
} from '@angular-magic/ngx-gp-autocomplete';

@Component({
  selector: 'app-edit-referring-provider',
  templateUrl: './edit-referring-provider.component.html',
  styleUrls: ['./edit-referring-provider.component.css'],
})
export class EditReferringProviderComponent implements OnInit {
  @ViewChild('ngxPlaces') placesRef: NgxGpAutocompleteDirective;
  public userForm: FormGroup;
  referringProvider: IReferringProvider;

  options: NgxGpAutocompleteOptions = {
    componentRestrictions: { country: ['US'] },
    types: ['address'],
  };

  action: string = '';
  processing: boolean = false;
  apiError: boolean = false;

  passwordView: boolean = false;

  maxDate = new Date();

  statesList = USStates;
  genderList = Gender;

  constructor(
    public dialogRef: MatDialogRef<EditReferringProviderComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private referringProviderAPIService: ReferringProviderAPIService,
    private toastMessageService: ToastMessageService
  ) {
    this.action = data.action;
  }

  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm() {
    this.userForm = this.formBuilder.group({
      id: new FormControl(''),
      organizationId: new FormControl(''),

      prefix: new FormControl(''),
      credential: new FormControl(''),

      firstName: new FormControl('', Validators.required),
      middleName: new FormControl(''),
      lastName: new FormControl('', Validators.required),

      emailAddress: new FormControl(
        '',
        Validators.compose([
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ])
      ),
      phoneType: new FormControl('Cell', Validators.required),
      phoneNumber: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          RxwebValidators.mask({ mask: '(999) 999-9999' }),
        ])
      ),
      faxNumber: new FormControl(
        '',
        Validators.compose([RxwebValidators.mask({ mask: '(999) 999-9999' })])
      ),

      dateOfBirth: new FormControl(''),
      taxonomyCode: new FormControl(''),
      individualNPI: new FormControl('', Validators.required),

      gender: new FormControl('', Validators.required),
      addressLine1: new FormControl(''),
      addressLine2: new FormControl(''),
      city: new FormControl(''),
      state: new FormControl(''),
      zip: new FormControl(''),
      country: new FormControl(''),

      status: new FormControl('Active', Validators.required),
    });
  }

  lookupNPI(type) {
    let dialogRef = this.dialog.open(LookupNpiComponent, {
      data: { type: type, npi: this.userForm.controls['individualNPI'].value },
      disableClose: true,
      autoFocus: false,
      minWidth: '30vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        // Populate details
        let responseDetails = response.details;
        this.userForm.controls['firstName'].setValue(responseDetails.firstName);
        this.userForm.controls['middleName'].setValue(
          responseDetails.middleName
        );
        this.userForm.controls['lastName'].setValue(responseDetails.lastName);
        this.userForm.controls['prefix'].setValue(responseDetails.prefix);
        this.userForm.controls['credential'].setValue(
          responseDetails.credential
        );
        this.userForm.controls['individualNPI'].setValue(
          responseDetails.individualNPI
        );
        this.userForm.controls['taxonomyCode'].setValue(
          responseDetails.taxonomyCode
        );

        this.userForm.controls['addressLine1'].setValue(
          responseDetails.addressLine1
        );
        this.userForm.controls['addressLine2'].setValue(
          responseDetails.addressLine2
        );
        this.userForm.controls['city'].setValue(responseDetails.city);
        this.userForm.controls['state'].setValue(responseDetails.state);
        this.userForm.controls['zip'].setValue(responseDetails.zip);
        this.userForm.controls['gender'].setValue(responseDetails.gender);
      }
    });
  }

  handleAddressChange(address: google.maps.places.PlaceResult) {
    let addressField: string = '';

    for (const component of address.address_components as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number': {
          addressField = `${component.long_name} ${addressField}`;
          break;
        }

        case 'route': {
          addressField += component.long_name;
          break;
        }

        case 'locality':
          //city
          this.userForm.controls['city'].setValue(component.long_name);
          break;

        case 'administrative_area_level_1': {
          //state
          this.userForm.controls['state'].setValue(component.short_name);
          break;
        }

        case 'postal_code': {
          this.userForm.controls['zip'].setValue(component.long_name);
          break;
        }
      }
    }
    if (addressField.trim() == '')
      addressField = address.formatted_address.split(',')[0];
    this.userForm.controls['addressLine1'].setValue(addressField);
  }

  submitForm() {
    if (this.userForm.invalid) {
      this.userForm.markAllAsTouched();
      return;
    }

    this.referringProvider = Object.assign({}, this.referringProvider);
    this.referringProvider = Object.assign(
      this.referringProvider,
      this.userForm.value
    );

    if (this.referringProvider.dateOfBirth) {
      this.referringProvider.dateOfBirth = formatDate(
        new Date(this.referringProvider.dateOfBirth)
      );
    }

    this.processing = true;

    this.referringProviderAPIService
      .addReferringProvider(this.referringProvider)
      .subscribe(
        (result) => {
          this.toastMessageService.displaySuccessMessage(
            'Referring Provider ' +
              this.referringProvider.firstName +
              ' ' +
              this.referringProvider.lastName +
              ' has been added'
          );
          this.processing = false;
          this.dialogRef.close('success');
        },
        (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Something went wrong while adding the referring provider.'
          );

          this.processing = false;
        }
      );
  }
}
