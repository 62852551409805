import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DataTablesModel } from '../../_models';

@Injectable({ providedIn: 'root' })
export class UserApiService {
  constructor(private httpClient: HttpClient) {}

  getAllActiveCareProviders(facilityId: string): Observable<DataTablesModel> {
    let params = new HttpParams();
    if (facilityId) params = params.append('facilityId', facilityId);

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/allactivecareproviders`,
      { params: params }
    );
  }

  getAllCareProvidersAtMyAssignedFacility(): Observable<DataTablesModel> {
    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/myassignedactivecareproviders`
    );
  }
}
