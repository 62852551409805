<!-- Search Box -->
<div class="row" style="margin-left: 5px; margin-right: 5px; row-gap: 15px">
  <div class="col-sm-12 col-md-8">
    <div class="row" style="row-gap: 15px; column-gap: 15px; flex-wrap: wrap">
      <div
        class="col"
        style="
          display: flex;
          align-items: center;
          padding: 5px;
          border-radius: 10px;
          border: 1px solid rgb(0, 0, 0, 0.2);
          background-color: #ffffff;
          padding: 5px;
          min-width: 140px;
        "
      >
        <span
          class="material-icons"
          style="text-align: left; font-size: 20px; opacity: 0.5"
        >
          pin
        </span>
        <input
          type="search"
          placeholder="Member #"
          [(ngModel)]="memberNumber"
          (ngModelChange)="searchByNameChanged()"
          style="
            padding-left: 15px;
            width: 100%;
            background: transparent;
            border: 0;
          "
        />
      </div>

      <div
        class="col"
        style="
          display: flex;
          align-items: center;
          padding: 5px;
          border-radius: 10px;
          border: 1px solid rgb(0, 0, 0, 0.2);
          background-color: #ffffff;
          padding: 5px;
          min-width: 140px;
        "
      >
        <span
          class="material-icons"
          style="text-align: left; font-size: 20px; opacity: 0.5"
        >
          search
        </span>
        <input
          type="search"
          placeholder="Last Name"
          [(ngModel)]="lastName"
          (ngModelChange)="searchByNameChanged()"
          style="
            padding-left: 15px;
            width: 100%;
            background: transparent;
            border: 0;
          "
        />
      </div>

      <div
        class="col"
        style="
          display: flex;
          align-items: center;
          padding: 5px;
          border-radius: 10px;
          border: 1px solid rgb(0, 0, 0, 0.2);
          background-color: #ffffff;
          padding: 5px;
          min-width: 140px;
        "
      >
        <span
          class="material-icons"
          style="text-align: left; font-size: 20px; opacity: 0.5"
        >
          search
        </span>
        <input
          type="search"
          placeholder="First Name"
          [(ngModel)]="firstName"
          (ngModelChange)="searchByNameChanged()"
          style="
            padding-left: 15px;
            width: 100%;
            background: transparent;
            border: 0;
          "
        />
      </div>

      <div
        class="col"
        style="
          display: flex;
          align-items: center;
          padding: 5px;
          border-radius: 10px;
          border: 1px solid rgb(0, 0, 0, 0.2);
          background-color: #ffffff;
          padding: 5px;
          min-width: 140px;
        "
      >
        <span
          class="material-icons"
          style="text-align: left; font-size: 20px; opacity: 0.5"
        >
          call
        </span>
        <input
          type="search"
          placeholder="Phone"
          [(ngModel)]="phone"
          (ngModelChange)="searchByNameChanged()"
          style="
            padding-left: 15px;
            width: 100%;
            background: transparent;
            border: 0;
          "
        />
      </div>
    </div>
  </div>
  <div
    class="col-sm-12 col-md-4"
    style="
      column-gap: 20px;
      display: flex;
      flex-wrap: wrap;
      text-align: right;
      flex-direction: row-reverse;
      padding: 0;
      row-gap: 10px;
    "
  >
    <div *ngIf="!checkingPermissions && hasViewAccess && hasAddAccess">
      <button mat-raised-button color="warn" (click)="addPatient()">
        + Add Member
      </button>
    </div>

    <div>
      <button mat-raised-button [matMenuTriggerFor]="sortMenu">
        <span
          style="white-space: break-spaces; color: #3ac47d"
          *ngIf="status === 'Active'"
        >
          Status: {{ status }}
        </span>
        <span
          style="white-space: break-spaces; color: #3f6ad8"
          *ngIf="status === 'Discharged'"
        >
          Status: {{ status }}
        </span>
        <span
          style="white-space: break-spaces; color: #d92550"
          *ngIf="status === 'Deceased'"
        >
          Status: {{ status }}
        </span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #sortMenu="matMenu">
        <button
          mat-menu-item
          style="font-weight: 400; font-size: 12px; width: 100%"
          (click)="filterOptionChanged('Active')"
        >
          Active
        </button>
        <button
          mat-menu-item
          style="font-weight: 400; font-size: 12px; width: 100%"
          (click)="filterOptionChanged('Discharged')"
        >
          Discharged
        </button>
        <button
          mat-menu-item
          style="font-weight: 400; font-size: 12px; width: 100%"
          (click)="filterOptionChanged('Deceased')"
        >
          Deceased
        </button>
      </mat-menu>
    </div>
  </div>
</div>

<!-- Displaying members list -->
<div class="row" *ngIf="!checkingPermissions && hasViewAccess">
  <div class="col-md-12" style="padding-top: 30px">
    <!-- Displaying Selected Facility Name -->
    <div
      *ngIf="!consolidatedMemberSearch"
      style="
        font-size: 16px;
        font-weight: 450;
        color: dimgray;
        padding-bottom: 12px;
      "
    >
      <i class="fa fa-hospital-o" style="margin-right: 10px; font-size: 32px">
      </i>
      Members at
      {{ selectedFacilityName }}
    </div>

    <div *ngIf="data.total === 0 && !processing$">
      <mat-error> No records found </mat-error>
    </div>

    <mat-progress-bar
      mode="indeterminate"
      *ngIf="processing$"
    ></mat-progress-bar>

    <div class="mat-elevation-z8" *ngIf="data.total > 0">
      <div class="table-responsive">
        <table
          mat-table
          [dataSource]="data.items"
          class="mat-elevation-z8"
          matSort
          matSortDisableClear
          matSortDirection="desc"
        >
          <!-- Member Details -->
          <ng-container matColumnDef="patient">
            <th mat-header-cell *matHeaderCellDef style="font-weight: 900">
              Member
            </th>
            <td mat-cell *matCellDef="let row">
              <div class="columnGrid">
                <!-- just diplay the name if this is an unauthorized user with no access -->
                <label
                  *ngIf="
                    row.restrictedMember &&
                    !isOrgAdmin &&
                    (row.authorizedUsers == null ||
                      row.authorizedUsers?.indexOf(loggedInUserId) < 0)
                  "
                  style="color: red; margin: 0"
                >
                  {{ row.lastName }}, {{ row.firstName }} {{ row.middleName }}
                </label>
                <!-- Not Restricted or access given member-->
                <a
                  [routerLink]="['/main/member', row.id, patientLandingPage]"
                  *ngIf="
                    !row.restrictedMember ||
                    isOrgAdmin ||
                    (row.restrictedMember &&
                      row.authorizedUsers != null &&
                      row.authorizedUsers?.indexOf(loggedInUserId) > -1)
                  "
                >
                  {{ row.lastName }}, {{ row.firstName }} {{ row.middleName }}
                </a>
                <!-- Member Number -->
                <label
                  style="margin: 0"
                  [style.color]="row.restrictedMember ? 'red' : ''"
                >
                  ID : {{ row.memberNumber }}
                </label>
                <!-- Showing Restricted Member -->
                <label
                  *ngIf="row.restrictedMember"
                  style="
                    display: flex;
                    align-items: center;
                    color: red;
                    margin: 0;
                  "
                >
                  Restricted Member <mat-icon color="warn"> lock </mat-icon>
                </label>
              </div>
            </td>
          </ng-container>

          <!-- Member DOB -->
          <ng-container matColumnDef="dateOfBirth">
            <th mat-header-cell *matHeaderCellDef style="font-weight: 900">
              Date Of Birth
            </th>
            <td mat-cell *matCellDef="let row">
              {{
                row.restrictedMember &&
                !isOrgAdmin &&
                (row.authorizedUsers == null ||
                  row.authorizedUsers?.indexOf(loggedInUserId) < 0)
                  ? ''
                  : row.dateOfBirth
              }}
            </td>
          </ng-container>

          <!-- Member Address -->
          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef>Address</th>
            <td mat-cell *matCellDef="let row">
              <span
                *ngIf="
                  !(
                    row.restrictedMember &&
                    !isOrgAdmin &&
                    (row.authorizedUsers == null ||
                      row.authorizedUsers?.indexOf(loggedInUserId) < 0)
                  )
                "
              >
                {{ row.addressLine1 }} {{ row.addressLine2 }}<br />
                {{ row.city }}, {{ row.state }}, {{ row.zip }}
              </span>
            </td>
          </ng-container>

          <!-- Gender -->
          <ng-container matColumnDef="gender">
            <th mat-header-cell *matHeaderCellDef style="font-weight: 900">
              Gender
            </th>
            <td
              mat-cell
              *matCellDef="let row"
              style="text-transform: capitalize"
            >
              {{
                row.restrictedMember &&
                !isOrgAdmin &&
                (row.authorizedUsers == null ||
                  row.authorizedUsers?.indexOf(loggedInUserId) < 0)
                  ? ''
                  : row?.gender === 'M'
                  ? 'Male'
                  : row?.gender === 'F'
                  ? 'Female'
                  : row?.gender.toLowerCase()
              }}
            </td>
          </ng-container>

          <!-- Phone Number -->
          <ng-container matColumnDef="phoneNumber">
            <th mat-header-cell *matHeaderCellDef style="font-weight: 900">
              Phone Number
            </th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="row.phoneNumber">
                {{
                  row.restrictedMember &&
                  !isOrgAdmin &&
                  (row.authorizedUsers == null ||
                    row.authorizedUsers?.indexOf(loggedInUserId) < 0)
                    ? ''
                    : (row.phoneNumber | mask : '(000) 000-0000')
                }}
              </span>
            </td>
          </ng-container>

          <!-- Member PC Provider -->
          <ng-container matColumnDef="pcprovider">
            <th mat-header-cell *matHeaderCellDef>Primary Care Provider</th>
            <td mat-cell *matCellDef="let row">
              <span
                *ngIf="
                  !(
                    row.restrictedMember &&
                    !isOrgAdmin &&
                    (row.authorizedUsers == null ||
                      row.authorizedUsers?.indexOf(loggedInUserId) < 0)
                  ) && row.primaryCPId
                "
              >
                {{ row?.primaryCPLastName }}, {{ row?.primaryCPFirstName }}
                {{ row?.primaryCPMiddleName }} <br />
                <label style="margin: 0"> {{ row?.primaryCPEmail }} </label
                ><br />
                <label style="margin: 0">
                  {{ row?.primaryCPPhone | phoneFormat }}
                </label>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th
              mat-header-cell
              *matHeaderCellDef
              style="font-weight: 900; text-align: right"
            >
              Action
            </th>
            <td mat-cell *matCellDef="let row" style="text-align: right">
              <button
                mat-raised-button
                *ngIf="
                  !row.restrictedMember ||
                  isOrgAdmin ||
                  (row.authorizedUsers != null &&
                    row.authorizedUsers?.indexOf(loggedInUserId) >= 0)
                "
                (click)="editPatient(row)"
                class="btn btn-just-icon btn-white btn-fab btn-round"
              >
                <i class="material-icons text_align-center">edit</i>
              </button>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <mat-paginator
        #paginator
        [length]="data.total"
        [pageSize]="data.per_page"
        [showFirstLastButtons]="true"
        *ngIf="data.total > data.per_page"
        (page)="getNext($event)"
      >
      </mat-paginator>
    </div>
  </div>
</div>

<!-- Display No Access To view members -->
<div
  class="row error-div"
  *ngIf="!checkingPermissions && !hasViewAccess && !processing$"
>
  <mat-card appearance="raised">
    <mat-card-header>
      <mat-card-title>
        <mat-error> Sorry, you don't have access to view members </mat-error>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content> </mat-card-content>
  </mat-card>
</div>
