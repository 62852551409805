import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { debounceTime } from 'rxjs';
import { ITherapySession, Patient } from 'src/app/_models';
import { ToastMessageService, TherapySessionService } from 'src/app/_services';

@Component({
  selector: 'app-session-objectives',
  templateUrl: './session-objectives.component.html',
  styleUrls: ['./session-objectives.component.css'],
})
export class SessionObjectivesComponent implements OnInit {
  @Input() patient: Patient;
  @Input() loggedInUserId: string;
  @Input() therapySession: ITherapySession;

  @Input() hasObjectivesViewAccess: boolean;
  @Input() hasObjectivesEditAccess: boolean;

  @Output() output = new EventEmitter<any>();

  processing: boolean = false;

  objectives: any;
  public objectiveForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public therapysessionApiService: TherapySessionService,
    public toastMessageService: ToastMessageService
  ) {}

  ngOnInit(): void {
    this.buildForm();

    // Now try to prepopulate therapy objectives
    if (this.therapySession.objective) {
      this.objectiveForm.controls['observations'].setValue(
        this.therapySession.objective.observations
      );
      this.objectiveForm.controls['obeAEB'].setValue(
        this.therapySession.objective.obeAEB
      );
      this.objectiveForm.controls['mood'].setValue(
        this.therapySession.objective.mood
      );
      this.objectiveForm.controls['mAEB'].setValue(
        this.therapySession.objective.mAEB
      );
      this.objectiveForm.controls['cognition'].setValue(
        this.therapySession.objective.cognition
      );
      this.objectiveForm.controls['perception'].setValue(
        this.therapySession.objective.perception
      );
      this.objectiveForm.controls['thought'].setValue(
        this.therapySession.objective.thought
      );
      this.objectiveForm.controls['behaviourTowardsTherapist'].setValue(
        this.therapySession.objective.behaviourTowardsTherapist
      );
      this.objectiveForm.controls['insight'].setValue(
        this.therapySession.objective.insight
      );
      this.objectiveForm.controls['judgement'].setValue(
        this.therapySession.objective.judgement
      );
    }

    if (
      !this.hasObjectivesEditAccess ||
      this.therapySession.status != 'Active'
    ) {
      this.objectiveForm.disable();
    }
  }

  private buildForm() {
    this.objectiveForm = this.formBuilder.group({
      observations: this.formBuilder.array(
        this.populateQuestions(this.observations)
      ),
      obeAEB: new FormControl('', Validators.compose([])),
      mood: this.formBuilder.array(this.populateQuestions(this.moodOptions)),
      mAEB: new FormControl('', Validators.compose([])),
      cognition: this.formBuilder.array(
        this.populateQuestions(this.cognitionOptions)
      ),
      perception: this.formBuilder.array(
        this.populateQuestions(this.perceptionOptions)
      ),
      thought: this.formBuilder.array(
        this.populateQuestions(this.thoughtOptions)
      ),
      behaviourTowardsTherapist: this.formBuilder.array(
        this.populateQuestions(this.behaviourTowardsTherapistOptions)
      ),
      insight: this.formBuilder.array(
        this.populateQuestions(this.insightOptions)
      ),
      judgement: this.formBuilder.array(
        this.populateQuestions(this.judgementOptions)
      ),
    });

    this.objectiveForm.valueChanges.pipe(debounceTime(1200)).subscribe(() => {
      this.submitForm();
    });
  }

  populateQuestions(questions: any) {
    let arrayItems = [];
    questions.map((question) => {
      arrayItems.push(this.createQuestion(question));
    });
    return arrayItems;
  }

  createQuestion(quest: any) {
    return this.formBuilder.group({
      label: [quest.label],
      code: [quest.code],
      responses: this.formBuilder.array(this.populateBoxes(quest.responses)),
    });
  }

  populateBoxes(boxes: any) {
    let arrayItems = [];
    boxes.map((box) => {
      arrayItems.push(this.createBox(box));
    });
    return arrayItems;
  }

  createBox(box: any) {
    return this.formBuilder.group({
      value: [box.value],
      selected: [box.selected],
    });
  }

  public observations = [
    {
      label: 'Appearance',
      code: 'obsAppearance',
      responses: [
        { value: 'Neat', selected: false },
        { value: 'Disheveled', selected: false },
        { value: 'Inappropriate', selected: false },
        { value: 'Bizarre', selected: false },
        { value: 'Neglected', selected: false },
      ],
    },
    {
      label: 'Speech',
      code: 'obsSpeech',
      responses: [
        { value: 'Normal', selected: false },
        { value: 'Tangential', selected: false },
        { value: 'Pressured', selected: false },
        { value: 'Impoverished', selected: false },
        { value: 'Other', selected: false },
      ],
    },
    {
      label: 'Eye Contact',
      code: 'obsEyeContact',
      responses: [
        { value: 'Normal', selected: false },
        { value: 'Intense', selected: false },
        { value: 'Avoidant', selected: false },
        { value: 'Tearful', selected: false },
      ],
    },
    {
      label: 'Motor Activity',
      code: 'obsMotorActivity',
      responses: [
        { value: 'Normal', selected: false },
        { value: 'Restless', selected: false },
        { value: 'Agitated', selected: false },
        { value: 'Slowed', selected: false },
      ],
    },
    {
      label: 'Affect',
      code: 'obsAffect',
      responses: [
        { value: 'Congruent', selected: false },
        { value: 'Restricted', selected: false },
        { value: 'Flat', selected: false },
        { value: 'Blunted', selected: false },
        { value: 'Labile', selected: false },
        { value: 'Inappropriate', selected: false },
      ],
    },
  ];

  public moodOptions = [
    {
      code: 'm',
      responses: [
        { value: 'Euthymic', selected: false },
        { value: 'Anxious', selected: false },
        { value: 'Angry', selected: false },
        { value: 'Depressed', selected: false },
        { value: 'Euphoric', selected: false },
        { value: 'Irritable', selected: false },
        { value: 'Pessimistic', selected: false },
        { value: 'Optimistic', selected: false },
        { value: 'Hypomanic', selected: false },
      ],
    },
  ];

  public cognitionOptions = [
    {
      label: 'Orientation',
      code: 'cogOrientation',
      responses: [
        { value: 'Normal/None', selected: false },
        { value: 'X4', selected: false },
        { value: 'X3', selected: false },
        { value: 'Place', selected: false },
        { value: 'Object/Situation', selected: false },
        { value: 'Person', selected: false },
        { value: 'Time', selected: false },
      ],
    },
    {
      label: 'Organization',
      code: 'cogOrganization',
      responses: [
        { value: 'Normal', selected: false },
        { value: 'Logical', selected: false },
        { value: 'Goal-Directed', selected: false },
        { value: 'Circumstantial', selected: false },
        { value: 'Loose', selected: false },
        { value: 'Perseverations', selected: false },
      ],
    },
    {
      label: 'Memory Impairment',
      code: 'cogMemory',
      responses: [
        { value: 'Normal/None', selected: false },
        { value: 'Short-Term', selected: false },
        { value: 'Long-Term', selected: false },
        { value: 'Fair', selected: false },
        { value: 'Poor', selected: false },
      ],
    },
    {
      label: 'Attention',
      code: 'cogAttention',
      responses: [
        { value: 'Normal', selected: false },
        { value: 'Distracted', selected: false },
        { value: 'Unaware', selected: false },
        { value: 'Inattentive', selected: false },
        { value: 'Hyperfocused', selected: false },
      ],
    },
  ];

  public perceptionOptions = [
    {
      label: 'Hallucinations',
      code: 'percHallucinations',
      responses: [
        { value: 'None', selected: false },
        { value: 'Auditory', selected: false },
        { value: 'Visual', selected: false },
        { value: 'Other', selected: false },
      ],
    },
    {
      label: 'Other',
      code: 'percOther',
      responses: [
        { value: 'None', selected: false },
        { value: 'Derealization', selected: false },
        { value: 'Depersonalization', selected: false },
      ],
    },
  ];

  public thoughtOptions = [
    {
      label: 'Suicidal',
      code: 'thSuicidal',
      responses: [
        { value: 'None', selected: false },
        { value: 'Ideation', selected: false },
        { value: 'Plan', selected: false },
        { value: 'Intent', selected: false },
        { value: 'Self Harm', selected: false },
      ],
    },
    {
      label: 'Homicidal',
      code: 'thHomicidal',
      responses: [
        { value: 'None', selected: false },
        { value: 'Ideation', selected: false },
        { value: 'Plan', selected: false },
        { value: 'Intent', selected: false },
      ],
    },
    {
      label: 'Content',
      code: 'thContent',
      responses: [
        { value: 'Delusions', selected: false },
        { value: 'Congruent', selected: false },
        { value: 'Paranoid', selected: false },
        { value: 'Incongruent', selected: false },
        { value: 'Persecution', selected: false },
        { value: 'Indecisive', selected: false },
        { value: 'Illusions', selected: false },
        { value: 'Suspicions', selected: false },
        { value: 'Ideas Of Reference', selected: false },
        { value: 'Ideas Of Influence', selected: false },
        { value: 'Grandiose', selected: false },
      ],
    },
  ];

  public behaviourTowardsTherapistOptions = [
    {
      code: 'btt',
      responses: [
        { value: 'Cooperative', selected: false },
        { value: 'Guarded', selected: false },
        { value: 'Hyperactive', selected: false },
        { value: 'Agitated', selected: false },
        { value: 'Paranoid', selected: false },
        { value: 'Stereotyped', selected: false },
        { value: 'Aggressive', selected: false },
        { value: 'Bizarre', selected: false },
        { value: 'Withdrawn', selected: false },
        { value: 'Dependent', selected: false },
        { value: 'Dramatic', selected: false },
        { value: 'Passive', selected: false },
        { value: 'Poor Storyteller', selected: false },
        { value: 'Uninterested', selected: false },
        { value: 'Resistant', selected: false },
        { value: 'Silly Goofy', selected: false },
        { value: 'Critical', selected: false },
        { value: 'Hostile', selected: false },
        { value: 'Sarcastic', selected: false },
        { value: 'Threatening', selected: false },
        { value: 'Submissive', selected: false },
        { value: 'Suspicious', selected: false },
        { value: 'Defensive', selected: false },
        { value: 'Manipulative', selected: false },
        { value: 'Argumentative', selected: false },
      ],
    },
  ];

  public insightOptions = [
    {
      code: 'ins',
      responses: [
        { value: 'Good', selected: false },
        { value: 'Fair', selected: false },
        { value: 'Poor', selected: false },
        { value: 'Uses Connections', selected: false },
        { value: 'Flashes Of', selected: false },
        { value: 'Gaps', selected: false },
        { value: 'Unaware', selected: false },
      ],
    },
  ];

  public judgementOptions = [
    {
      code: 'jud',
      responses: [
        { value: 'Normal', selected: false },
        { value: 'Intense', selected: false },
        { value: 'Avoidant', selected: false },
        { value: 'Tearful', selected: false },
      ],
    },
  ];

  getAnswers(key: string, code: string) {
    let outPut = '';
    let innerControl = this.objectiveForm.controls['objective'] as FormGroup;
    let formArray = innerControl.controls[key] as FormArray;
    formArray.controls.forEach((element, index) => {
      let codeCtrlVal = element.get('code').value;
      if (codeCtrlVal === code) {
        let responses = element.get('responses') as FormArray;
        Object.keys(responses.controls).forEach((key: string) => {
          const abstractControl = responses.get(key);
          if (abstractControl.value.selected) {
            if (outPut != '') {
              outPut += ', ';
            }
            outPut += abstractControl.value.value;
          }
        });
      }
    });
    if (outPut === '') outPut = '-- n/a --';
    return outPut;
  }

  getAebValue(key: string) {
    let outPut = '';
    let innerControl = this.objectiveForm.controls['objective'] as FormGroup;
    if (innerControl.get(key)) {
      outPut = innerControl.get(key).value;
    }
    return outPut;
  }

  submitForm() {
    this.objectives = Object.assign({}, this.objectives);
    this.objectives = Object.assign(this.objectives, this.objectiveForm.value);

    let payload = {
      objective: this.objectives,
    };

    this.processing = true;
    this.therapysessionApiService
      .updateObjective(this.therapySession.id, payload)
      .subscribe({
        next: (response) => {
          // this.toastMessageService.displaySuccessMessage(
          //   'Successfully updated the objectives.'
          // );
          this.processing = false;
          this.output.emit({
            eventType: 'RELOAD_THERPAY_OBJECT',
            therapySession: response,
          });
        },
        error: (error) => {
          this.processing = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to add objectives record.'
          );
        },
      });
  }
}
