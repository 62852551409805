<mat-card-title> Medical History</mat-card-title>
<mat-divider></mat-divider>

<div style="margin-top: 20px; padding: 10px">
  <form
    [formGroup]="bpsamedicalhistoryForm"
    novalidate
    (ngSubmit)="onBpsaMedicalHistoryFormSubmit()"
  >
    <div class="row pad20">
      <div class="col-md-12">
        <label class="labelText"
          >Are you currently taking any prescription medication?
          <span style="color: red">*</span></label
        >&nbsp;
        <br />
        <mat-button-toggle-group
          formControlName="takingPrescriptionMedication"
          (change)="checkPrescriptionMedication('prescriptionMedications')"
        >
          <mat-button-toggle value="Yes">Yes</mat-button-toggle>
          <mat-button-toggle value="Member Denies"
            >Member Denies</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div
      class="row pad20"
      *ngIf="
        bpsamedicalhistoryForm.controls['takingPrescriptionMedication']
          .value === 'Yes'
      "
    >
      <div class="col-md-12" formArrayName="prescriptionMedications">
        <label class="labelText"
          >Please list medications, dosages, frequency, and reason for use
          <span style="color: red">*</span></label
        >&nbsp;
        <br />

        <mat-card
          appearance="outlined"
          class="mat-elevation-z8"
          *ngFor="
            let prescription of bpsamedicalhistoryForm.get(
              'prescriptionMedications'
            )['controls'];
            let i = index
          "
          style="margin-bottom: 15px"
        >
          <div class="row" [formGroupName]="i">
            <div class="col-xl-6">
              <label class="labelText changeInnerFont">
                Medication <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="medication"
                  placeholder="Search by name/NDC Code"
                  required
                  [matAutocomplete]="auto"
                />
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                  <mat-option
                    *ngFor="let option of ndcMedicationData.items"
                    [value]="option.proprietaryName"
                    (onSelectionChange)="
                      updateMedicationInfo('prescriptionMedications', i, option)
                    "
                    style="font-size: 10px"
                  >
                    {{ option.proprietaryName }}
                    {{ option.activeNumeratorStrength }}
                    {{ option.activeIngredUnit }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="prescription.controls.medication.invalid">
                  Medication name is required.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-xl-6">
              <label class="labelText changeInnerFont">
                Dosage <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="dosage" required />
                <mat-error *ngIf="prescription.controls.dosage.invalid">
                  Dosage amount is required.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-xl-6">
              <label class="labelText changeInnerFont"> Reasons for use </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="reasons" />
              </mat-form-field>
            </div>

            <div class="col-xl-4">
              <label class="labelText changeInnerFont">
                Frequency <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="frequency" required />
                <mat-error *ngIf="prescription.controls.frequency.invalid">
                  Frequency is required.
                </mat-error>
              </mat-form-field>
            </div>

            <div
              class="col-xl-2"
              style="align-self: center"
              *ngIf="
                therapySession.status === 'Active' &&
                (therapySession.physicianId === loggedInUserId ||
                  loggedInUserId === therapySession?.supervisingRPId)
              "
            >
              <button
                type="button"
                mat-mini-fab
                color="warn"
                fxFlex="nogrow"
                *ngIf="
                  bpsamedicalhistoryForm.get('prescriptionMedications')[
                    'controls'
                  ].length > 1
                "
                (click)="removeMedication(i, 'prescriptionMedications')"
              >
                <mat-icon>delete_forever</mat-icon>
              </button>
              <button
                type="button"
                color="primary"
                mat-raised-button
                (click)="addMedication('prescriptionMedications')"
                style="margin-left: 5px"
                *ngIf="
                  i ==
                  bpsamedicalhistoryForm.controls['prescriptionMedications']
                    .value.length -
                    1
                "
              >
                <mat-icon>library_add</mat-icon>
              </button>
            </div>
          </div>
        </mat-card>
      </div>
    </div>

    <div class="row pad20">
      <div class="col-md-12">
        <label class="labelText"
          >Are you taking any over-the-counter medication?
          <span style="color: red">*</span></label
        >&nbsp;
        <br />
        <mat-button-toggle-group
          formControlName="takingOverTheCounterMedication"
          (change)="checkCounterMedication('overTheCounterMedications')"
        >
          <mat-button-toggle value="Yes">Yes</mat-button-toggle>
          <mat-button-toggle value="Member Denies"
            >Member Denies</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>

    <div
      class="row pad20"
      *ngIf="
        bpsamedicalhistoryForm.controls['takingOverTheCounterMedication']
          .value === 'Yes'
      "
    >
      <div class="col-md-12" formArrayName="overTheCounterMedications">
        <label class="labelText"
          >Please list medications, dosages, frequency, and reason for use
          <span style="color: red">*</span></label
        >&nbsp;
        <br />

        <mat-card
          appearance="outlined"
          class="mat-elevation-z8"
          *ngFor="
            let prescription of bpsamedicalhistoryForm.get(
              'overTheCounterMedications'
            )['controls'];
            let i = index
          "
          style="margin-bottom: 15px"
        >
          <div class="row" [formGroupName]="i">
            <div class="col-xl-6">
              <label class="labelText changeInnerFont">
                Medication <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="medication"
                  placeholder="Search by name/NDC Code"
                  required
                  [matAutocomplete]="auto2"
                />
                <mat-autocomplete
                  autoActiveFirstOption
                  #auto2="matAutocomplete"
                >
                  <mat-option
                    *ngFor="let option of ndcMedicationData.items"
                    [value]="option.proprietaryName"
                    (onSelectionChange)="
                      updateMedicationInfo(
                        'overTheCounterMedications',
                        i,
                        option
                      )
                    "
                    style="font-size: 10px"
                  >
                    {{ option.proprietaryName }}
                    {{ option.activeNumeratorStrength }}
                    {{ option.activeIngredUnit }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="prescription.controls.medication.invalid">
                  Medication name is required.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-xl-6">
              <label class="labelText changeInnerFont">
                Dosage <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="dosage" required />
                <mat-error *ngIf="prescription.controls.dosage.invalid">
                  Dosage amount is required.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-xl-6">
              <label class="labelText changeInnerFont"> Reasons for use </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="reasons" />
              </mat-form-field>
            </div>

            <div class="col-xl-4">
              <label class="labelText changeInnerFont">
                Frequency <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="frequency" required />
                <mat-error *ngIf="prescription.controls.frequency.invalid">
                  Frequency is required.
                </mat-error>
              </mat-form-field>
            </div>

            <div
              class="col-xl-2"
              style="align-self: center"
              *ngIf="
                therapySession.status === 'Active' &&
                (therapySession.physicianId === loggedInUserId ||
                  loggedInUserId === therapySession?.supervisingRPId)
              "
            >
              <button
                type="button"
                mat-mini-fab
                color="warn"
                fxFlex="nogrow"
                *ngIf="
                  bpsamedicalhistoryForm.get('overTheCounterMedications')[
                    'controls'
                  ].length > 1
                "
                (click)="removeMedication(i, 'overTheCounterMedications')"
              >
                <mat-icon>delete_forever</mat-icon>
              </button>
              <button
                type="button"
                color="primary"
                mat-raised-button
                (click)="addMedication('overTheCounterMedications')"
                style="margin-left: 5px"
                *ngIf="
                  i ==
                  bpsamedicalhistoryForm.controls['overTheCounterMedications']
                    .value.length -
                    1
                "
              >
                <mat-icon>library_add</mat-icon>
              </button>
            </div>
          </div>
        </mat-card>
      </div>
    </div>

    <app-member-health-history
      [therapySession]="therapySession"
      [loggedInUserId]="loggedInUserId"
      [hasBPSAClinicalAddAccess]="hasBPSAClinicalAddAccess"
      [hasBPSAClinicalEditAccess]="hasBPSAClinicalEditAccess"
    ></app-member-health-history>
  </form>
</div>
