<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Document Preview</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div mat-dialog-content style="height: 900px; width: 100%">
    <ng2-pdfjs-viewer [download]="true" #pdfViewerOnDemand></ng2-pdfjs-viewer>

    <div *ngIf="displayNoRecordsMsg">
      <mat-error style="text-align: center">
        Unable to preview the selected document
      </mat-error>
    </div>
  </div>
</div>
