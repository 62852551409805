import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hours',
})
export class Hours implements PipeTransform {
  transform(hour: any): string {
    if (!isNaN(hour)) {
      hour = Number(hour);
    }
    var hours = hour / 100;
    var minutes = hour % 100;
    var final = hours - minutes / 100;
    let part1 = final >= 12 ? 'PM' : 'AM';
    var final2;
    if (final > 12) {
      final2 = final - 12;
    } else {
      final2 = final;
    }
    var output = (final2 + '').length == 1 ? `0${final2}` : final2;
    var output2 = (minutes + '').length == 1 ? `0${minutes}` : minutes;
    return output + ':' + output2 + ' ' + part1;
  }
}
