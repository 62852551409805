import { Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { MainComponent } from './main/main.component';
import { PatientsComponent } from './patients.component';

export const PatientRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        data: {
          roles: ['physician', 'orgadmin', 'superorgadmin', 'careprovider'],
          excludeRoles: ['patient', 'inhospitaldevice'],
        },
      },
      {
        path: 'patients',
        component: PatientsComponent,
        canActivate: [AuthGuard],
        data: {
          roles: ['physician', 'orgadmin', 'superorgadmin', 'careprovider'],
          excludeRoles: ['patient', 'inhospitaldevice'],
        },
      },
    ],
  },
];
