<div class="dialogTitle" style="padding-left: 10px; padding-right: 10px">
  <p matDialogTitle>Assessment Request</p>
  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-stepper linear="true" #stepper>
  <mat-step [stepControl]="category">
    <!-- Select Category Step -->
    <ng-template matStepLabel>Select Category</ng-template>

    <!-- Search Asssessments -->
    <div class="row">
      <div
        class="col-md-12"
        style="
          display: flex;
          align-items: center;
          padding: 5px;
          border-radius: 10px;
          border: 1px solid rgb(0, 0, 0, 0.2);
          background-color: #ffffff;
          margin: 5px 5px;
        "
      >
        <span
          class="material-icons"
          style="text-align: left; font-size: 20px; opacity: 0.5"
        >
          search
        </span>
        <input
          type="search"
          placeholder="Search Assessment"
          [(ngModel)]="searchAssessment"
          (ngModelChange)="searchAssessmentChanged()"
          style="
            padding-left: 15px;
            width: 100%;
            background: transparent;
            border: 0;
          "
        />
      </div>
    </div>

    <mat-tab-group dynamicHeight>
      <!-- My Favourites List -->
      <mat-tab *ngIf="!searchAssessment">
        <ng-template mat-tab-label>
          <mat-icon>favorite</mat-icon>
          &nbsp;My favorites
        </ng-template>

        <!-- Show all assessments that are favorites -->
        <div
          class="row"
          *ngIf="favoriteAssessments.length > 0; else noFavoritesFound"
        >
          <div
            class="col-md-6 col-xl-4"
            *ngFor="let assessment of favoriteAssessments"
          >
            <mat-card
              *ngIf="assessment.enabled || !isProd"
              [ngClass]="
                !assessment.enabled && !isProd
                  ? 'mat-elevation-z8 selectable-card red-border-card'
                  : 'mat-elevation-z8 selectable-card'
              "
              (click)="onSelectAssessmentType(assessment, stepper, true)"
            >
              <!-- Check circle to denote this is selected -->
              <span
                [matTooltip]="assessment.name"
                class="selection"
                *ngIf="selectedAssessment?.name === assessment.name"
              >
                <mat-icon
                  class="primary-color-icon"
                  style="color: green; margin-top: 5px; margin-right: 5px"
                >
                  check_circle
                </mat-icon>
              </span>

              <!-- Assessment Details -->
              <div>
                <label
                  class="labelText assessmentHeader"
                  style="font-size: 16px; font-weight: 450; cursor: pointer"
                >
                  {{ assessment.name }}
                  <mat-icon
                    [ngClass]="
                      assessment.isFavorite ? 'favorite' : 'not-favorite'
                    "
                    *ngIf="assessment.enabled"
                    (click)="
                      toggleFavorite('ASSESSMENTS', assessment, $event);
                      $event.stopPropagation()
                    "
                  >
                    star
                  </mat-icon>
                </label>
                <span *ngIf="assessment.inSessionOnly">
                  <mat-error> (In-Session Only) </mat-error>
                </span>
                <br />
                <label
                  class="labelText"
                  style="font-size: 15px; font-weight: 400; cursor: pointer"
                >
                  {{ assessment.description }}
                </label>
              </div>
            </mat-card>
          </div>
        </div>

        <!-- No favorites found Disclaimer-->
        <ng-template #noFavoritesFound>
          <mat-error>No favorites found</mat-error>
        </ng-template>
      </mat-tab>

      <!-- Categories List -->
      <mat-tab *ngIf="!searchAssessment">
        <ng-template mat-tab-label>
          <mat-icon>list</mat-icon>
          &nbsp;Categories
        </ng-template>

        <!-- Display All Categories -->
        <div class="row" style="margin-top: 5px">
          <div
            class="col-md-6 col-xl-4"
            *ngFor="let category of assessmentGroups"
          >
            <mat-card
              class="selectable-card mat-elevation-z8"
              (click)="selectCategory(category.name, stepper)"
            >
              <span
                [matTooltip]="category.name"
                class="selection"
                *ngIf="selectedCategory === category.name"
              >
                <mat-icon
                  class="primary-color-icon"
                  style="color: green; margin-top: 5px"
                  >check_circle</mat-icon
                >
              </span>

              <label
                class="labelText"
                style="font-size: 16px; cursor: pointer"
                >{{ category.name }}</label
              >
            </mat-card>
          </div>
        </div>
      </mat-tab>

      <!-- Search List -->
      <mat-tab *ngIf="searchAssessment">
        <ng-template mat-tab-label>
          <mat-icon>search</mat-icon>
          &nbsp;Search Results
        </ng-template>

        <!-- Display all search results -->
        <div class="row" *ngIf="filteredAssessments.length > 0; else noResults">
          <div
            class="col-md-6 col-xl-4"
            *ngFor="let assessment of filteredAssessments"
          >
            <mat-card
              *ngIf="assessment.enabled || !isProd"
              [ngClass]="
                !assessment.enabled && !isProd
                  ? 'mat-elevation-z8 selectable-card red-border-card'
                  : 'mat-elevation-z8 selectable-card'
              "
              (click)="onSelectAssessmentType(assessment, stepper, true)"
            >
              <span
                [matTooltip]="assessment.name"
                class="selection"
                *ngIf="selectedAssessment?.name === assessment.name"
              >
                <mat-icon
                  class="primary-color-icon"
                  style="color: green; margin-top: 5px; margin-right: 5px"
                  >check_circle</mat-icon
                >
              </span>

              <div>
                <label
                  class="labelText assessmentHeader"
                  style="font-size: 16px; font-weight: 450; cursor: pointer"
                >
                  {{ assessment.name }}
                  <mat-icon
                    [ngClass]="
                      assessment.isFavorite ? 'favorite' : 'not-favorite'
                    "
                    *ngIf="assessment.enabled"
                    (click)="
                      toggleFavorite('ASSESSMENTS', assessment, $event);
                      $event.stopPropagation()
                    "
                    >star</mat-icon
                  >
                </label>
                <span *ngIf="assessment.inSessionOnly">
                  <mat-error> (In-Session Only) </mat-error>
                </span>
                <br />
                <label
                  class="labelText"
                  style="font-size: 15px; font-weight: 400; cursor: pointer"
                >
                  {{ assessment.description }}
                </label>
              </div>
            </mat-card>
          </div>
        </div>
        <ng-template #noResults>
          <mat-error>No results found</mat-error>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </mat-step>

  <!-- // Step 2 - Regular flow from category -->
  <mat-step [stepControl]="assessmentType">
    <!-- Select Assessment Step -->
    <ng-template matStepLabel>Select Assessment</ng-template>
    <div class="row">
      <div class="col-md-6 col-xl-4" *ngFor="let assessment of assessmentList">
        <mat-card
          *ngIf="assessment.enabled || !isProd"
          (click)="onSelectAssessmentType(assessment, stepper, false)"
          [ngClass]="
            !assessment.enabled && !isProd
              ? 'mat-elevation-z8 selectable-card red-border-card'
              : 'mat-elevation-z8 selectable-card'
          "
        >
          <span
            [matTooltip]="assessment.name"
            class="selection"
            *ngIf="selectedAssessment.name === assessment.name"
          >
            <mat-icon
              class="primary-color-icon"
              style="color: green; margin-top: 5px; margin-right: 5px"
              >check_circle</mat-icon
            >
          </span>

          <div>
            <label
              class="labelText assessmentHeader"
              style="font-size: 16px; font-weight: 450; cursor: pointer"
            >
              {{ assessment.name }}
              <mat-icon
                [ngClass]="assessment.isFavorite ? 'favorite' : 'not-favorite'"
                *ngIf="assessment.enabled"
                (click)="
                  toggleFavorite('ASSESSMENTS', assessment, $event);
                  $event.stopPropagation()
                "
                >star</mat-icon
              >
            </label>
            <span *ngIf="assessment.inSessionOnly">
              <mat-error> (In-Session Only) </mat-error>
            </span>
            <br />
            <label
              class="labelText"
              style="font-size: 15px; font-weight: 400; cursor: pointer"
            >
              {{ assessment.description }}
            </label>
          </div>
        </mat-card>
      </div>
    </div>
  </mat-step>

  <!-- // Step 3 - Confirm Step -->
  <mat-step>
    <!-- Confirmation Step -->
    <ng-template matStepLabel>Confirm</ng-template>
    <label class="labelHeader">Member Details</label>
    <mat-card appearance="raised" class="mat-elevation-z8">
      <div class="row">
        <div class="col-md-3">
          <p style="font-size: 16px; font-weight: 450">Member Name</p>
        </div>
        <div class="col-md-9">
          <p style="font-size: 16px; font-weight: 400">
            {{ patient.lastName }}, {{ patient.firstName }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <p style="font-size: 16px; font-weight: 450">Gender</p>
        </div>
        <div class="col-md-9">
          <p style="font-size: 16px; font-weight: 400">
            {{
              patient.gender === 'M'
                ? 'Male'
                : patient.gender === 'F'
                ? 'Female'
                : patient.gender
            }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <p style="font-size: 16px; font-weight: 450">Date of Birth</p>
        </div>
        <div class="col-md-9">
          <p style="font-size: 16px; font-weight: 400">
            {{ patient.dateOfBirth }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <p style="font-size: 16px; font-weight: 450">Phone Number</p>
        </div>
        <div class="col-md-9">
          <p style="font-size: 16px; font-weight: 400">
            {{ patient.phoneNumber }}
          </p>
        </div>
      </div>
    </mat-card>
    <br />
    <label class="labelHeader">Requesting Assessment Details</label>
    <mat-card appearance="raised" class="mat-elevation-z8">
      <div class="row">
        <div class="col-md-3">
          <p style="font-size: 16px; font-weight: 450">Category</p>
        </div>
        <div class="col-md-8">
          <p style="font-size: 16px; font-weight: 400">{{ category.value }}</p>
        </div>
        <div class="col-md-1" style="text-align: right">
          <button
            type="button"
            mat-icon-button
            color="warn"
            (click)="categoryStepper(stepper)"
          >
            <mat-icon style="color: gainsboro"> create</mat-icon>
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
          <p style="font-size: 16px; font-weight: 450">Assessment</p>
        </div>
        <div class="col-md-8">
          <p style="font-size: 16px; font-weight: 400">
            {{ assessmentType.value }}
            <span *ngIf="selectedAssessment?.inSessionOnly">
              <mat-error> (In-Session Only) </mat-error>
            </span>
          </p>
        </div>
        <div class="col-md-1" style="text-align: right">
          <button
            type="button"
            mat-icon-button
            color="warn"
            (click)="assessmentStepper(stepper)"
          >
            <mat-icon style="color: gainsboro"> create</mat-icon>
          </button>
        </div>
      </div>

      <div
        class="row"
        *ngIf="
          patient?.email &&
          patient?.phoneNumber &&
          !selectedAssessment?.inSessionOnly
        "
      >
        <div class="col-md-3">
          <p style="font-size: 16px; font-weight: 450">Send via Email</p>
        </div>

        <div class="col-md-9">
          <form [formGroup]="assessmentForm">
            <section class="checkbox-section">
              <mat-checkbox
                color="primary"
                class="checkbox-margin"
                formControlName="sendViaEmail"
              >
                <span
                  *ngIf="!assessmentForm.controls['sendViaEmail'].value"
                  style="white-space: break-spaces"
                >
                  ( Please check this box if you would like to send an email
                  link to the member for taking this assessment. )
                </span>
              </mat-checkbox>
            </section>
          </form>
        </div>
      </div>
    </mat-card>
    <br />

    <div class="row">
      <div class="col-md-12" style="text-align: right">
        <button
          mat-raised-button
          color="primary"
          type="button"
          [disabled]="processing"
          (click)="assessmentRequestSubmit()"
        >
          Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
        </button>
      </div>
    </div>
  </mat-step>
</mat-stepper>
