<mat-card
  *ngIf="hasClinicalNotesViewAccess"
  appearance="raised"
  class="mat-elevation-z8"
  style="border-radius: 2px; margin-bottom: 45px"
>
  <div class="row">
    <div class="col-md-8" style="display: flex">
      <!-- Header -->
      <label class="labelHeader">
        <mat-icon style="margin-right: 10px">note_alt</mat-icon>
        {{
          therapySession?.sessionType === 'Phone Note'
            ? 'Phone Note'
            : 'Clinical Note'
        }}
        <button
          *ngIf="
            therapySession.clinicalNoteId &&
            (hasClinicalNotesEditAccess || hasClinicalNotesAddAccess) &&
            therapySession.status === 'Active'
          "
          mat-icon-button
          (click)="changeNoteType()"
          type="button"
          matTooltip="Change Note Type"
          id="changeButton"
          style="opacity: 0.3"
        >
          <mat-icon> change_circle </mat-icon>
        </button>

        <button
          *ngIf="
            therapySession.clinicalNoteId &&
            hasClinicalNotesEditAccess &&
            therapySession.status === 'Active'
          "
          mat-icon-button
          (click)="deleteNote()"
          type="button"
          matTooltip="Delete Note"
          id="deleteButton"
          style="opacity: 0.3"
        >
          <mat-icon style="color: red"> delete_forever </mat-icon>
        </button>
      </label>

      <!-- Handle Font Size -->
      <label
        *ngIf="
          therapySession.clinicalNoteId ||
          therapySession?.sessionType === 'Phone Note'
        "
      >
        <div class="justifyCenterContainer">
          <button
            class="decreaseFontSizeButton"
            mat-icon-button
            type="button"
            (click)="handleFontSizeChange('decrease')"
          >
            <mat-icon>remove_circle</mat-icon>
          </button>
          Font
          <button
            class="increaseFontSizeButton"
            mat-icon-button
            type="button"
            (click)="handleFontSizeChange('increase')"
          >
            <mat-icon>add_circle</mat-icon>
          </button>
        </div>
      </label>
    </div>

    <div
      class="col-md-4"
      *ngIf="
        therapySession?.clinicalNoteId &&
        (hasClinicalNotesEditAccess || hasClinicalNotesAddAccess) &&
        therapySession.status === 'Active' &&
        therapySession?.sessionType !== 'Phone Note'
      "
      style="text-align: right"
    >
      <button
        mat-raised-button
        type="button"
        class="mat-elevation-z8"
        style="color: #2196f3"
        (click)="loadLastNote()"
      >
        <mat-icon color="primary">content_copy</mat-icon> Load Last Note
      </button>
    </div>
  </div>

  <!-- If a note id does not exist, show the add button -->
  <div
    class="row"
    *ngIf="
      !therapySession?.clinicalNoteId &&
      therapySession?.sessionType !== 'Phone Note'
    "
    style="margin-top: 15px; margin-bottom: 20px"
  >
    <div
      class="col-md-12"
      style="text-align: right"
      *ngIf="hasClinicalNotesAddAccess && therapySession.status === 'Active'"
    >
      <button
        type="button"
        mat-raised-button
        color="warn"
        (click)="addNewClinicalNote()"
      >
        + Clinical Note
      </button>
    </div>
    <div class="col-md-12">
      <mat-error> No note found </mat-error>
    </div>
  </div>

  <!-- If a note id does exist -->
  <div
    *ngIf="
      therapySession?.clinicalNoteId ||
      therapySession?.sessionType === 'Phone Note'
    "
    class="row"
    style="margin-top: 15px"
  >
    <div class="col-md-12">
      <div class="row">
        <!-- Displaying all the sections -->
        <div class="col-md-12">
          <label class="labelHeader">
            {{ therapySession.clinicalNoteTypeName }}
          </label>
        </div>

        <!-- Progress Bar -->
        <div class="col-md-12">
          <mat-progress-bar
            mode="indeterminate"
            *ngIf="isLoadingNGXEditors"
          ></mat-progress-bar>
        </div>

        <!-- Left side form controls -->
        <div [class]="tabPanelOpen ? 'col-md-6 col-xl-8' : 'col-md-12'">
          <div [class]="tabPanelOpen ? 'row maxHeightContatiner' : 'row'">
            <div
              [class]="
                selectedSectionName === section.name
                  ? 'highlitedBox col-md-12'
                  : ' col-md-12'
              "
              *ngFor="
                let section of therapySession.sessionNotes.sections;
                let i = index
              "
              style="margin-bottom: 8px"
            >
              <form [formGroup]="clinicalNoteForm">
                <!-- Clinical Note Section -->
                <div class="sectionHeader">
                  <label
                    style="margin-top: auto"
                    [class]="
                      selectedSectionName === section.name ? 'labelBold' : ''
                    "
                  >
                    {{ section.name }}
                  </label>
                  <button
                    mat-button
                    color="primary"
                    type="button"
                    *ngIf="
                      selectedSectionName === section.name &&
                      hasChangesMap[section.id]
                    "
                  >
                    <mat-icon>autorenew</mat-icon> Saving
                  </button>
                  <button
                    mat-button
                    color="primary"
                    type="button"
                    *ngIf="
                      selectedSectionName === section.name &&
                      hadChangesEarlier[section.id] &&
                      autosaved
                    "
                  >
                    Auto Saved
                  </button>

                  <!-- Tab panel selection -->
                  <div
                    *ngIf="
                      (hasClinicalNotesEditAccess ||
                        hasClinicalNotesAddAccess) &&
                      therapySession.status === 'Active'
                    "
                  >
                    <button
                      mat-button
                      color="primary"
                      type="button"
                      (click)="
                        openTabPanel(
                          'Text Shortcuts',
                          section.id,
                          section.name,
                          i
                        )
                      "
                    >
                      Text Shortcuts
                    </button>
                    <button
                      mat-button
                      color="primary"
                      type="button"
                      (click)="
                        openTabPanel('Templates', section.id, section.name, i)
                      "
                    >
                      Templates
                    </button>
                  </div>
                </div>

                <!-- NGX Note Editor -->
                <div class="NgxEditor__Wrapper">
                  <ngx-editor-menu
                    [editor]="editor[i]"
                    [toolbar]="toolbar"
                    *ngIf="
                      (hasClinicalNotesEditAccess ||
                        hasClinicalNotesAddAccess) &&
                      therapySession.status === 'Active'
                    "
                  >
                  </ngx-editor-menu>
                  <ngx-editor
                    [editor]="editor[i]"
                    [formControlName]="section.id"
                    spellcheck="true"
                    (click)="
                      editorClicked($event, section.id, section.name, i);
                      hasChangesMap[section.id] = false
                    "
                    (ngModelChange)="onEditorChange($event, section.id)"
                  >
                  </ngx-editor>
                </div>
              </form>
            </div>
          </div>
        </div>

        <!-- Right Side Templates/shortcuts -->
        <!-- Mobile will take over the entire screens -->
        <div
          *ngIf="tabPanelOpen"
          class="col-md-6 col-xl-4 mobileSectionView maxHeightContatiner"
        >
          <!-- General Card to handle shortcut and templates -->
          <app-clinical-shortcut-templates
            [activeTab]="activeTab"
            [selectedSectionName]="selectedSectionName"
            (shortCutResponse)="handleShortCutResponse($event)"
            (templateResponse)="handleTemplateResponse($event)"
            (closeCardTab)="closePanel()"
          >
          </app-clinical-shortcut-templates>
        </div>
      </div>
    </div>
  </div>
</mat-card>
