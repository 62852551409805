export const EmploymentStatus = [
  {
    id: 'UNKWN',
    description: 'Unknown',
  },
  {
    id: 'EMPLY',
    description: 'Employed',
  },
  {
    id: 'FTSTD',
    description: 'Full Time Student',
  },
  {
    id: 'PTSTD',
    description: 'Part Time Student',
  },
  {
    id: 'RETRD',
    description: 'Retired',
  },
  {
    id: 'DISBD',
    description: 'Disabled',
  },
  {
    id: 'HNMKR',
    description: 'Homemaker',
  },
  {
    id: 'UNEMP',
    description: 'Unemployed',
  },
];
