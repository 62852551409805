import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { IGeriatricDepressionScale } from 'src/app/_models/assessments/depression/geriatricdepressionscale.model';
import {
  GeriatricDepressionScaleApiService,
  ToastMessageService,
} from 'src/app/_services/';

@Component({
  selector: 'app-geriatric-depression-scale-dialog',
  templateUrl: './geriatric-depression-scale-dialog.component.html',
  styleUrls: ['./geriatric-depression-scale-dialog.component.css'],
})
export class GeriatricDepressionScaleDialogComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public geriatricdepressionscaleApiService: GeriatricDepressionScaleApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) {}

  public riskScore: number;
  public action: string;
  public geriatricdepressionscaleForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  geriatricdepressionscale: IGeriatricDepressionScale;
  geriatricdepressionscaleError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(
      this.geriatricdepressionscaleForm,
      this.errors
    );

    this.loadGeriatricDepressionScale();
  }

  loadGeriatricDepressionScale() {
    this.geriatricdepressionscaleForm.controls['patientId'].setValue(
      this.patientId
    );

    if (this.assessmentId) {
      this.geriatricdepressionscaleApiService
        .getGeriatricDepressionScaleDetails(this.assessmentId)
        .subscribe((geriatricdepressionscale) => {
          if (geriatricdepressionscale.data) {
            this.geriatricdepressionscaleForm.controls['id'].setValue(
              geriatricdepressionscale.data.id
            );
            this.geriatricdepressionscaleForm.controls[
              'organizationId'
            ].setValue(geriatricdepressionscale.data.organizationId);
            this.geriatricdepressionscaleForm.controls['patientId'].setValue(
              geriatricdepressionscale.data.patientId
            );
            this.geriatricdepressionscaleForm.controls[
              'satisfiedWithLife'
            ].setValue(geriatricdepressionscale.data.satisfiedWithLife.answer);
            this.geriatricdepressionscaleForm.controls[
              'droppedActivitiesAndInterests'
            ].setValue(
              geriatricdepressionscale.data.droppedActivitiesAndInterests.answer
            );
            this.geriatricdepressionscaleForm.controls[
              'feelLifeIsEmpty'
            ].setValue(geriatricdepressionscale.data.feelLifeIsEmpty.answer);
            this.geriatricdepressionscaleForm.controls['gettingBored'].setValue(
              geriatricdepressionscale.data.gettingBored.answer
            );
            this.geriatricdepressionscaleForm.controls[
              'hopefulAboutFuture'
            ].setValue(geriatricdepressionscale.data.hopefulAboutFuture.answer);
            this.geriatricdepressionscaleForm.controls[
              'botheredByThoughts'
            ].setValue(geriatricdepressionscale.data.botheredByThoughts.answer);
            this.geriatricdepressionscaleForm.controls[
              'inGoodSpirits'
            ].setValue(geriatricdepressionscale.data.inGoodSpirits.answer);
            this.geriatricdepressionscaleForm.controls[
              'afraidOfSomthingBad'
            ].setValue(
              geriatricdepressionscale.data.afraidOfSomthingBad.answer
            );
            this.geriatricdepressionscaleForm.controls['feelHappy'].setValue(
              geriatricdepressionscale.data.feelHappy.answer
            );
            this.geriatricdepressionscaleForm.controls['feelHelpless'].setValue(
              geriatricdepressionscale.data.feelHelpless.answer
            );
            this.geriatricdepressionscaleForm.controls['feelRestless'].setValue(
              geriatricdepressionscale.data.feelRestless.answer
            );
            this.geriatricdepressionscaleForm.controls[
              'preferStayAtHome'
            ].setValue(geriatricdepressionscale.data.preferStayAtHome.answer);
            this.geriatricdepressionscaleForm.controls[
              'worryAboutFuture'
            ].setValue(geriatricdepressionscale.data.worryAboutFuture.answer);
            this.geriatricdepressionscaleForm.controls[
              'problemsWithMemory'
            ].setValue(geriatricdepressionscale.data.problemsWithMemory.answer);
            this.geriatricdepressionscaleForm.controls[
              'goodToBeAlive'
            ].setValue(geriatricdepressionscale.data.goodToBeAlive.answer);
            this.geriatricdepressionscaleForm.controls[
              'feelDownhearted'
            ].setValue(geriatricdepressionscale.data.feelDownhearted.answer);
            this.geriatricdepressionscaleForm.controls[
              'feelWorthless'
            ].setValue(geriatricdepressionscale.data.feelWorthless.answer);
            this.geriatricdepressionscaleForm.controls[
              'worryAboutPast'
            ].setValue(geriatricdepressionscale.data.worryAboutPast.answer);
            this.geriatricdepressionscaleForm.controls[
              'lifeIsExciting'
            ].setValue(geriatricdepressionscale.data.lifeIsExciting.answer);
            this.geriatricdepressionscaleForm.controls[
              'hardToStartNewProjects'
            ].setValue(
              geriatricdepressionscale.data.hardToStartNewProjects.answer
            );
            this.geriatricdepressionscaleForm.controls['feelEnergy'].setValue(
              geriatricdepressionscale.data.feelEnergy.answer
            );
            this.geriatricdepressionscaleForm.controls['feelHopeless'].setValue(
              geriatricdepressionscale.data.feelHopeless.answer
            );
            this.geriatricdepressionscaleForm.controls[
              'otherPeopleAreBetter'
            ].setValue(
              geriatricdepressionscale.data.otherPeopleAreBetter.answer
            );
            this.geriatricdepressionscaleForm.controls['feelUpset'].setValue(
              geriatricdepressionscale.data.feelUpset.answer
            );
            this.geriatricdepressionscaleForm.controls[
              'feelLikeCrying'
            ].setValue(geriatricdepressionscale.data.feelLikeCrying.answer);
            this.geriatricdepressionscaleForm.controls[
              'troubleConcentrating'
            ].setValue(
              geriatricdepressionscale.data.troubleConcentrating.answer
            );
            this.geriatricdepressionscaleForm.controls[
              'wakeUpInTheMorning'
            ].setValue(geriatricdepressionscale.data.wakeUpInTheMorning.answer);
            this.geriatricdepressionscaleForm.controls[
              'avoidSocialGatherings'
            ].setValue(
              geriatricdepressionscale.data.avoidSocialGatherings.answer
            );
            this.geriatricdepressionscaleForm.controls[
              'easyToMakeDecisions'
            ].setValue(
              geriatricdepressionscale.data.easyToMakeDecisions.answer
            );
            this.geriatricdepressionscaleForm.controls['mindClear'].setValue(
              geriatricdepressionscale.data.mindClear.answer
            );
            this.riskScore = geriatricdepressionscale.data.riskScore;
          }
        });
    }
  }
  onGeriatricDepressionScaleFormSubmit() {
    if (this.geriatricdepressionscaleForm.invalid) {
      this.geriatricdepressionscaleForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.geriatricdepressionscale = Object.assign(
      {},
      this.geriatricdepressionscale
    );
    this.geriatricdepressionscale.patientId =
      this.geriatricdepressionscaleForm.controls['patientId'].value;
    this.geriatricdepressionscale.sessionId =
      this.geriatricdepressionscaleForm.controls['sessionId'].value;
    this.geriatricdepressionscale.assessmentRequestId =
      this.geriatricdepressionscaleForm.controls['assessmentRequestId'].value;
    this.geriatricdepressionscale.satisfiedWithLife = {
      answer:
        this.geriatricdepressionscaleForm.controls['satisfiedWithLife'].value,
    };
    this.geriatricdepressionscale.droppedActivitiesAndInterests = {
      answer:
        this.geriatricdepressionscaleForm.controls[
          'droppedActivitiesAndInterests'
        ].value,
    };
    this.geriatricdepressionscale.feelLifeIsEmpty = {
      answer:
        this.geriatricdepressionscaleForm.controls['feelLifeIsEmpty'].value,
    };
    this.geriatricdepressionscale.gettingBored = {
      answer: this.geriatricdepressionscaleForm.controls['gettingBored'].value,
    };
    this.geriatricdepressionscale.hopefulAboutFuture = {
      answer:
        this.geriatricdepressionscaleForm.controls['hopefulAboutFuture'].value,
    };
    this.geriatricdepressionscale.botheredByThoughts = {
      answer:
        this.geriatricdepressionscaleForm.controls['botheredByThoughts'].value,
    };
    this.geriatricdepressionscale.inGoodSpirits = {
      answer: this.geriatricdepressionscaleForm.controls['inGoodSpirits'].value,
    };
    this.geriatricdepressionscale.afraidOfSomthingBad = {
      answer:
        this.geriatricdepressionscaleForm.controls['afraidOfSomthingBad'].value,
    };
    this.geriatricdepressionscale.feelHappy = {
      answer: this.geriatricdepressionscaleForm.controls['feelHappy'].value,
    };
    this.geriatricdepressionscale.feelHelpless = {
      answer: this.geriatricdepressionscaleForm.controls['feelHelpless'].value,
    };
    this.geriatricdepressionscale.feelRestless = {
      answer: this.geriatricdepressionscaleForm.controls['feelRestless'].value,
    };
    this.geriatricdepressionscale.preferStayAtHome = {
      answer:
        this.geriatricdepressionscaleForm.controls['preferStayAtHome'].value,
    };
    this.geriatricdepressionscale.worryAboutFuture = {
      answer:
        this.geriatricdepressionscaleForm.controls['worryAboutFuture'].value,
    };
    this.geriatricdepressionscale.problemsWithMemory = {
      answer:
        this.geriatricdepressionscaleForm.controls['problemsWithMemory'].value,
    };
    this.geriatricdepressionscale.goodToBeAlive = {
      answer: this.geriatricdepressionscaleForm.controls['goodToBeAlive'].value,
    };
    this.geriatricdepressionscale.feelDownhearted = {
      answer:
        this.geriatricdepressionscaleForm.controls['feelDownhearted'].value,
    };
    this.geriatricdepressionscale.feelWorthless = {
      answer: this.geriatricdepressionscaleForm.controls['feelWorthless'].value,
    };
    this.geriatricdepressionscale.worryAboutPast = {
      answer:
        this.geriatricdepressionscaleForm.controls['worryAboutPast'].value,
    };
    this.geriatricdepressionscale.lifeIsExciting = {
      answer:
        this.geriatricdepressionscaleForm.controls['lifeIsExciting'].value,
    };
    this.geriatricdepressionscale.hardToStartNewProjects = {
      answer:
        this.geriatricdepressionscaleForm.controls['hardToStartNewProjects']
          .value,
    };
    this.geriatricdepressionscale.feelEnergy = {
      answer: this.geriatricdepressionscaleForm.controls['feelEnergy'].value,
    };
    this.geriatricdepressionscale.feelHopeless = {
      answer: this.geriatricdepressionscaleForm.controls['feelHopeless'].value,
    };
    this.geriatricdepressionscale.otherPeopleAreBetter = {
      answer:
        this.geriatricdepressionscaleForm.controls['otherPeopleAreBetter']
          .value,
    };
    this.geriatricdepressionscale.feelUpset = {
      answer: this.geriatricdepressionscaleForm.controls['feelUpset'].value,
    };
    this.geriatricdepressionscale.feelLikeCrying = {
      answer:
        this.geriatricdepressionscaleForm.controls['feelLikeCrying'].value,
    };
    this.geriatricdepressionscale.troubleConcentrating = {
      answer:
        this.geriatricdepressionscaleForm.controls['troubleConcentrating']
          .value,
    };
    this.geriatricdepressionscale.wakeUpInTheMorning = {
      answer:
        this.geriatricdepressionscaleForm.controls['wakeUpInTheMorning'].value,
    };
    this.geriatricdepressionscale.avoidSocialGatherings = {
      answer:
        this.geriatricdepressionscaleForm.controls['avoidSocialGatherings']
          .value,
    };
    this.geriatricdepressionscale.easyToMakeDecisions = {
      answer:
        this.geriatricdepressionscaleForm.controls['easyToMakeDecisions'].value,
    };
    this.geriatricdepressionscale.mindClear = {
      answer: this.geriatricdepressionscaleForm.controls['mindClear'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.geriatricdepressionscale.accessCode = this.accessCode;

      this.geriatricdepressionscaleApiService
        .addGuestGeriatricDepressionScale(this.geriatricdepressionscale)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Geriatric Depression Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Geriatric Depression Assessment'
            );
          }
        );
    } else {
      this.geriatricdepressionscaleApiService
        .addGeriatricDepressionScale(this.geriatricdepressionscale)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Geriatric Depression Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Geriatric Depression Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    id: [{ type: 'required', message: 'Id is required' }],
    organizationId: [
      { type: 'required', message: 'Organization Id is required' },
    ],
    patientId: [{ type: 'required', message: 'Member Id is required' }],
    satisfiedWithLife: [
      {
        type: 'required',
        message: 'Are you basically satisfied with your life? is required',
      },
    ],
    droppedActivitiesAndInterests: [
      {
        type: 'required',
        message:
          'Have you dropped many of your activities and interests? is required',
      },
    ],
    feelLifeIsEmpty: [
      {
        type: 'required',
        message: 'Do you feel that your life is empty? is required',
      },
    ],
    gettingBored: [
      { type: 'required', message: 'Do you often get bored? is required' },
    ],
    hopefulAboutFuture: [
      {
        type: 'required',
        message: 'Are you hopeful about the future? is required',
      },
    ],
    botheredByThoughts: [
      {
        type: 'required',
        message:
          'Bothered By ThoughtsAre you bothered by thoughts you can’t get out of your head? is required',
      },
    ],
    inGoodSpirits: [
      {
        type: 'required',
        message: 'Are you in good spirits most of the time? is required',
      },
    ],
    afraidOfSomthingBad: [
      {
        type: 'required',
        message:
          'Are you afraid something bad is going to happen to you? is required',
      },
    ],
    feelHappy: [
      {
        type: 'required',
        message: 'Do you feel happy most of the time? is required',
      },
    ],
    feelHelpless: [
      { type: 'required', message: 'Do you often feel helpless? is required' },
    ],
    feelRestless: [
      {
        type: 'required',
        message: 'Do you often feel restless and fidgety? is required',
      },
    ],
    preferStayAtHome: [
      {
        type: 'required',
        message:
          'Do you prefer to stay at home rather than going out and doing new things? is required',
      },
    ],
    worryAboutFuture: [
      {
        type: 'required',
        message: 'Do you frequently worry about the future? is required',
      },
    ],
    problemsWithMemory: [
      {
        type: 'required',
        message:
          'Do you feel you have more problems with memory than most? is required',
      },
    ],
    goodToBeAlive: [
      {
        type: 'required',
        message:
          'Do you think it is wonderful to be alive right now? is required',
      },
    ],
    feelDownhearted: [
      {
        type: 'required',
        message: 'Do you often feel downhearted and blue? is required',
      },
    ],
    feelWorthless: [
      {
        type: 'required',
        message:
          'Do you feel pretty worthless the way you are now? is required',
      },
    ],
    worryAboutPast: [
      {
        type: 'required',
        message: 'Do you worry a lot about the past? is required',
      },
    ],
    lifeIsExciting: [
      {
        type: 'required',
        message: 'Do you find life very exciting? is required',
      },
    ],
    hardToStartNewProjects: [
      {
        type: 'required',
        message:
          'Is it hard for you to get started on new projects? is required',
      },
    ],
    feelEnergy: [
      { type: 'required', message: 'Do you feel full of energy? is required' },
    ],
    feelHopeless: [
      {
        type: 'required',
        message: 'Do you feel your situation is hopeless? is required',
      },
    ],
    otherPeopleAreBetter: [
      {
        type: 'required',
        message:
          'Do you think that most people are better off than you are? is required',
      },
    ],
    feelUpset: [
      {
        type: 'required',
        message: 'Do you frequently get upset over little things? is required',
      },
    ],
    feelLikeCrying: [
      {
        type: 'required',
        message: 'Do you frequently feel like crying? is required',
      },
    ],
    troubleConcentrating: [
      {
        type: 'required',
        message: 'Do you have trouble concentrating? is required',
      },
    ],
    wakeUpInTheMorning: [
      {
        type: 'required',
        message: 'Do you enjoy getting up in the morning? is required',
      },
    ],
    avoidSocialGatherings: [
      {
        type: 'required',
        message: 'Do you avoid social gatherings? is required',
      },
    ],
    easyToMakeDecisions: [
      {
        type: 'required',
        message: 'Is it easy for you to make decisions? is required',
      },
    ],
    mindClear: [
      {
        type: 'required',
        message: 'Is your mind as clear as it used to be? is required',
      },
    ],
  };

  private buildForm() {
    this.geriatricdepressionscaleForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      satisfiedWithLife: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      droppedActivitiesAndInterests: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feelLifeIsEmpty: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      gettingBored: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      hopefulAboutFuture: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      botheredByThoughts: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      inGoodSpirits: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      afraidOfSomthingBad: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feelHappy: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feelHelpless: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feelRestless: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      preferStayAtHome: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      worryAboutFuture: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      problemsWithMemory: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      goodToBeAlive: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feelDownhearted: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feelWorthless: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      worryAboutPast: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      lifeIsExciting: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      hardToStartNewProjects: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feelEnergy: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feelHopeless: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      otherPeopleAreBetter: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feelUpset: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      feelLikeCrying: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      troubleConcentrating: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      wakeUpInTheMorning: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      avoidSocialGatherings: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      easyToMakeDecisions: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      mindClear: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.geriatricdepressionscaleForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.geriatricdepressionscaleForm) {
      return;
    }
    const form = this.geriatricdepressionscaleForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
