import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-goal-notes-by-patient',
  templateUrl: './goal-notes-by-patient.component.html',
  styleUrls: ['./goal-notes-by-patient.component.css'],
})
export class GoalNotesByPatientComponent implements OnInit {
  viewOnlyNotes: any;
  role: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<GoalNotesByPatientComponent>
  ) {
    this.role = data.role;
    if (this.role === 'Physician') {
      this.viewOnlyNotes = data.goal.physicianNotes;
    } else if (this.role === 'Patient') {
      this.viewOnlyNotes = data.goal.patientNotes;
    }
  }

  ngOnInit(): void {}
}
