import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import moment from 'moment';
import { DataTablesModel } from 'src/app/_models';
import { MysticalExperienceApiService } from 'src/app/_services';
import { CHART_COLORS, greenChart, orangeChart, redChart, yellowChart } from 'src/app/shared/utilities/utilities';
@Component({
  selector: 'app-revised-mystical-experience-charts',
  templateUrl: './revised-mystical-experience-charts.component.html',
  styleUrls: ['./revised-mystical-experience-charts.component.css']
})
export class RevisedMysticalExperienceChartsComponent implements OnInit {
  @Input() patientId: string;
  @Input() assessmentId: string;
  chartColorRed = CHART_COLORS.red;
  chartColorOrange = CHART_COLORS.orange;
  chartColorBlue = CHART_COLORS.blue;
  chartColorGreen = CHART_COLORS.green;
  chartColorPurple = CHART_COLORS.purple;
  lineChartData: ChartConfiguration<'line'>['data'] = {
    datasets: [{ tension: 0.4, data: [], label: 'Mystical', borderColor: this.chartColorRed, backgroundColor: this.chartColorRed, pointBackgroundColor: this.chartColorRed },
    { data: [], label: 'Positive Mood', borderColor: this.chartColorOrange, backgroundColor: this.chartColorOrange, pointBackgroundColor: this.chartColorOrange },
    { data: [], label: 'Transcendence of Time and Space', borderColor: this.chartColorBlue, backgroundColor: this.chartColorBlue, pointBackgroundColor: this.chartColorBlue },
    { data: [], label: 'Ineffability', borderColor: this.chartColorPurple, backgroundColor: this.chartColorPurple, pointBackgroundColor: this.chartColorPurple }]
  };

  lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        title: {
          display: false
        }
      }
    },
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        },
        min: 0,
        max: 5,
        ticks: { stepSize: 1 }
      }
    }
  };
  assessmentDetailChartOptions: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        title: {
          display: false
        }
      }
    },
    scales: {
      x: {
        min: 0,
        max: 10,
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          z: 5
        },
        min: 0,
        max: 5,
        ticks: { stepSize: 1 }
      }
    }
  };
  lineChartLegend = false;
  lineChartPlugins = [];
  lineChartType = 'line';
  detailChartPlugins = [];
  data: DataTablesModel = {} as DataTablesModel;
  processing$ = false;
  isLoadingResults = false;
  error: string = '';
  scoreMap = new Map();
  scoreDetails: any[] = [];
  constructor(public mysticalExperienceApiService: MysticalExperienceApiService,
    private route: ActivatedRoute) {
  }
  ngOnInit() {
    this.data.page = 0;
    this.data.per_page = 10;
    this.loadGamingAddictionAssessments();
  }
  public loadGamingAddictionAssessments() {
    this.isLoadingResults = true;
    this.error = '';
    this.mysticalExperienceApiService.getMysticalExperienceList(this.patientId, this.assessmentId, this.data.per_page, this.data.page).subscribe(response => {
      if (response && response.items) {
        var mysticalScores = [];
        var moodScores = [];
        var spaceTimeScores = [];
        var ineffabilityScores = [];
        this.data.items = response.items;
        this.data.total = response.total;
        var riskScores = [];
        var labels = [];
        this.scoreDetails = [];
        for (var item of response.items) {
          if (response.items.length === 1) {
            labels.push("");
            riskScores.push(item.riskScore);
            mysticalScores.push(item.props['mystical']);
            moodScores.push(item.props['mood']);
            spaceTimeScores.push(item.props['spacetime']);
            ineffabilityScores.push(item.props['ineffability']);
          }

          riskScores.push(item.riskScore);
          if (item.props) {
            mysticalScores.push(item.props['mystical']);
            moodScores.push(item.props['mood']);
            spaceTimeScores.push(item.props['spacetime']);
            ineffabilityScores.push(item.props['ineffability']);
          } else {
            mysticalScores.push(0);
            moodScores.push(0);
            spaceTimeScores.push(0);
            ineffabilityScores.push(0);
          }
          var stillUtc = moment.utc(item.createdDate).toDate();
          var assessmentDate = moment(stillUtc).local().format('MM/DD/YY');
          labels.push(assessmentDate);
          for (var key in item) {
            if (item.hasOwnProperty(key)) {
              if (item[key] && item[key].hasOwnProperty("score")) {
                var scoreObj = item[key];
                var scores = [];
                if (this.scoreMap.get(key)) {
                  scores = this.scoreMap.get(key).scores;
                }
                if (response.items.length === 1) {
                  scores.push(scoreObj["score"]);
                }
                scores.push(scoreObj["score"]);
                var returnObj = { "scores": scores, "key": key, "title": scoreObj["title"], "answer": scoreObj["answer"], "chartData": [], "chartColor": this.getChartColor(scores) };
                this.scoreMap.set(key, returnObj);
              }
            }
          }
        }
        this.scoreMap.forEach((value, key) => {
          let dataset: any = {
            datasets: [
              {
                "data": value.scores,
                "borderColor": value.chartColor.borderColor,
                "backgroundColor": value.chartColor.backgroundColor,
                "tension": 0.4,
                "fill": true,
                pointBackgroundColor: '#333333',
                pointRadius: 5
              }
            ],
            "labels": labels,
            "title": value.title,
            "answer": value.answer
          };
          this.scoreDetails.push(dataset);
        });

        this.lineChartData.datasets[0].data = mysticalScores;
        this.lineChartData.datasets[1].data = moodScores;
        this.lineChartData.datasets[2].data = spaceTimeScores;
        this.lineChartData.datasets[3].data = ineffabilityScores;
        this.lineChartData.labels = labels;
        this.isLoadingResults = false;
      }
      if (response == null) {
        this.data.items = [];
        this.data.total = 0;
      }
    },
      error => {
        this.error = error;
        this.isLoadingResults = false;
      })
  }
  getChartColor(scoringArray: any[]) {
    var score = scoringArray[scoringArray.length - 1];
    if (score >= 4) {
      return redChart;
    } else if (score >= 3) {
      return orangeChart;
    } else if (score >= 2) {
      return yellowChart;
    } else {
      return greenChart;
    }
  }
}
