import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastMessageService } from 'src/app/_services';

@Component({
  selector: 'app-edit-confirm-procedure-code',
  templateUrl: './edit-confirm-procedure-code.component.html',
  styleUrls: ['./edit-confirm-procedure-code.component.css'],
})
export class EditConfirmProcedureCodeComponent implements OnInit {
  cptCode: any;
  processing: boolean = false;
  public cptCodeForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<EditConfirmProcedureCodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastMessageService: ToastMessageService,
    private formBuilder: FormBuilder
  ) {
    this.cptCode = data.cptCode;
  }

  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm() {
    this.cptCodeForm = this.formBuilder.group({
      id: new FormControl(this.cptCode?.id),
      cptCode: new FormControl(
        { value: this.cptCode?.cptCode, disabled: true },
        Validators.required
      ),
      description: new FormControl({
        value: this.cptCode?.description,
        disabled: true,
      }),
      rate: new FormControl(
        { value: this.cptCode?.rate, disabled: true },
        Validators.required
      ),
      defaultUnit: new FormControl(
        this.cptCode?.defaultUnit,
        Validators.required
      ),
      duration: new FormControl({
        value: this.cptCode?.duration,
        disabled: true,
      }),
      durationUnit: new FormControl('min'),
      modifier1: new FormControl(this.cptCode?.modifier1),
      modifier2: new FormControl(this.cptCode?.modifier2),
      modifier3: new FormControl(this.cptCode?.modifier3),
      modifier4: new FormControl(this.cptCode?.modifier4),
      diagnosisPointer: new FormControl(this.cptCode?.diagnosisPointer),
    });
  }

  oncptCodeFormSubmit() {
    this.cptCode = Object.assign({}, this.cptCode);
    this.cptCode = Object.assign(this.cptCode, this.cptCodeForm.value);
    this.dialogRef.close({ type: 'success', cptCode: this.cptCode });
  }
}
