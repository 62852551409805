<div class="container">
  <div class="dialogTitle" *ngIf="displayTitle">
    <p matDialogTitle>Enter Seizure Info</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="seizureinfoForm" (ngSubmit)="onSeizureinfoSubmit()">
    <mat-stepper linear="true" #stepper>
      <mat-step [stepControl]="initial">
        <ng-template matStepLabel>Had Seizures?</ng-template>

        <br />
        <div class="row">
          <label class="labelText">
            Had Seizures during the shift? <span style="color: red">*</span>
          </label>
        </div>

        <div class="row">
          <div class="col-6" style="padding: 10px">
            <mat-card
              appearance="raised"
              (click)="selectInitialAnswer('Yes', stepper)"
              class="mat-elevation-z8 selectable-card"
            >
              <span class="selection" *ngIf="initialAnswer === 'Yes'">
                <mat-icon
                  class="primary-color-icon"
                  style="color: green; margin-top: 5px"
                  >check_circle</mat-icon
                >
              </span>
              <label class="labelText" style="font-size: 16px; cursor: pointer">
                Yes
              </label>
            </mat-card>
          </div>
          <div class="col-6" style="padding: 10px">
            <mat-card
              appearance="raised"
              (click)="selectInitialAnswer('No', stepper)"
              class="mat-elevation-z8 selectable-card"
            >
              <span class="selection" *ngIf="initialAnswer === 'No'">
                <mat-icon
                  class="primary-color-icon"
                  style="color: green; margin-top: 5px"
                  >check_circle</mat-icon
                >
              </span>
              <label class="labelText" style="font-size: 16px; cursor: pointer">
                No
              </label>
            </mat-card>
          </div>
        </div>
      </mat-step>

      <mat-step [stepControl]="details">
        <ng-template matStepLabel>Details / Confirm</ng-template>
        <div class="row">
          <div class="col-md-6" *ngIf="initialAnswer === 'Yes'">
            <label class="labelText">Start Time</label>
            <mat-form-field appearance="outline">
              <mat-select placeholder="Start Time" formControlName="startTime">
                <mat-option
                  *ngFor="let startTime of timeArray"
                  [value]="startTime.value"
                >
                  {{ startTime.text }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-6" *ngIf="initialAnswer === 'Yes'">
            <label class="labelText">End Time</label>
            <mat-form-field appearance="outline">
              <mat-select placeholder="End Time" formControlName="endTime">
                <mat-option
                  *ngFor="let endTime of timeArray"
                  [value]="endTime.value"
                  >{{ endTime.text }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-12" *ngIf="initialAnswer === 'Yes'">
            <section class="checkbox-section">
              <mat-checkbox
                color="primary"
                class="checkbox-margin"
                formControlName="called911"
              >
                Called 911
              </mat-checkbox>
            </section>
          </div>

          <div class="col-md-12">
            <label class="labelText">Additional Details</label>
            <mat-form-field appearance="outline">
              <textarea
                matInput
                placeholder="Additional Details"
                formControlName="customMessage"
                name="customMessage"
              ></textarea>
            </mat-form-field>
          </div>

          <div class="col-md-12">
            <label class="labelText">
              Activity Date/Time <span style="color: red">*</span>
            </label>
            <mat-form-field appearance="outline">
              <input
                matInput
                [owlDateTime]="dt1"
                [owlDateTimeTrigger]="dt1"
                placeholder="Date Time"
                formControlName="activityDateTime"
                [max]="todaysDate"
              />
              <owl-date-time #dt1></owl-date-time>

              <mat-error
                *ngIf="seizureinfoForm.controls['activityDateTime'].invalid"
              >
                Please select the activity date and time
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-12" style="text-align: right">
            <button
              color="primary"
              mat-raised-button
              type="submit"
              [disabled]="!seizureinfoForm.valid || isProcessing"
            >
              Submit <i class="fa fa-spinner fa-spin" *ngIf="isProcessing"></i>
            </button>
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </form>
</div>
