<div>
  <p style="font-size: 16px; font-weight: 450">
    <a routerLink="/" routerLinkActive="active">
      <em
        class="material-icons text_align-center"
        style="font-size: 18px; margin-top: -3px"
        >home</em
      >
    </a>
    &nbsp; / &nbsp;
    <a
      [routerLink]="
        parent === 'claims'
          ? '/claims'
          : parent === 'claimsdetails'
          ? ['/claims/details/', claimId]
          : ['/main/member', claimDetails?.patientId, 'CLAIMS']
      "
      [queryParams]="{ parent: subParent }"
      routerLinkActive="active"
    >
      <span
        style="
          color: #2196f3;
          cursor: pointer;
          font-weight: 500;
          text-transform: capitalize;
        "
      >
        Claims Payments
      </span>
    </a>
    &nbsp; / &nbsp; {{ claimDetails?.claimCode }}
  </p>
</div>

<div *ngIf="isLoadingResults || isLoadingDetails || apiError">
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoadingResults || isLoadingDetails"
  ></mat-progress-bar>

  <mat-error *ngIf="apiError">
    An error occurred while retrieving claim payment details
  </mat-error>
</div>

<div class="row" style="margin: 10px">
  <!-- Claim OverView -->
  <div class="col-md-12" *ngIf="claimDetails?.id">
    <label class="labelHeader">
      <div class="justifyCenterContainer">
        <mat-icon style="margin-right: 10px">list_alt</mat-icon> Claim Overview
      </div>
    </label>

    <app-remittance-claims-overview-header
      [therapySession]="claimDetails?.therapySession"
    >
    </app-remittance-claims-overview-header>
  </div>

  <div class="col-md-12" style="margin-top: 15px">
    <label class="labelHeader">
      <div class="justifyCenterContainer">
        <mat-icon style="margin-right: 10px">local_atm</mat-icon>
        Payments ({{ paymentsList?.length }})
        <i class="fa fa-spinner fa-spin" *ngIf="isLoadingResults"></i>
      </div>
    </label>

    <!-- Claim List -->
    <div
      class="mat-elevation-z8"
      style="margin-top: 15px"
      *ngIf="paymentsList && paymentsList.length > 0"
    >
      <div class="table-responsive">
        <table mat-table [dataSource]="paymentsList" class="mat-elevation-z8">
          <ng-container matColumnDef="details">
            <th mat-header-cell *matHeaderCellDef>Payment Details</th>
            <td mat-cell *matCellDef="let row">
              <div class="columnsGrid">
                <label>
                  Payer -
                  <b> {{ row?.payerName }}</b>
                </label>

                <label>
                  Payment Reference -
                  <b> {{ row?.paymentReferenceNumber }}</b>
                </label>

                <label>
                  Payment Method -
                  <b> {{ row?.paymentMethodDescription }}</b>
                </label>

                <label>
                  Effective Date -
                  <b> {{ row?.paymentEffectiveDate }}</b>
                </label>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="claimStatusCode">
            <th mat-header-cell *matHeaderCellDef>Claim Status Code</th>
            <td mat-cell *matCellDef="let row">
              <span
                [class]="
                  row.claimStatusCode === '1'
                    ? 'primaryStatus'
                    : row.claimStatusCode === '2'
                    ? 'secondaryStatus'
                    : row.claimStatusCode === '4' ||
                      row.claimStatusCode === '22'
                    ? 'rejectedStatus'
                    : 'otherStatus'
                "
              >
                {{ row?.claimStatusDescription }}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Uploaded</th>
            <td mat-cell *matCellDef="let row">
              <label>{{ row.createdDate | date : 'M/d/y' }}</label>
            </td>
          </ng-container>

          <ng-container matColumnDef="totalAmount">
            <th mat-header-cell *matHeaderCellDef>Submitted Amount</th>
            <td mat-cell *matCellDef="let row">
              <label class="labelBold">
                {{ row?.totalAmount | currency : 'USD' }}
              </label>
            </td>
          </ng-container>

          <ng-container matColumnDef="paidAmount">
            <th mat-header-cell *matHeaderCellDef>Paid Amount</th>
            <td mat-cell *matCellDef="let row">
              <label class="labelBold">
                {{ row?.paidAmount | currency : 'USD' }}
              </label>
            </td>
          </ng-container>

          <ng-container matColumnDef="patientResponsibility">
            <th mat-header-cell *matHeaderCellDef>Patient Responsibility</th>
            <td mat-cell *matCellDef="let row">
              <label
                class="labelBold"
                [style.color]="
                  row?.paidAmount < row?.totalAmount ? 'red' : 'green'
                "
              >
                {{ row?.patientResponsibility | currency : 'USD' }}
              </label>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef style="text-align: right">
              Action
            </th>
            <td mat-cell *matCellDef="let row" style="text-align: right">
              <button
                type="button"
                mat-raised-button
                (click)="viewClaimResponse(row)"
              >
                <mat-icon color="primary">visibility</mat-icon> View
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
