import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseModal } from 'src/app/_models';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class StreamApiService {
    constructor(private httpClient: HttpClient) { }
    getStreamToken(): Observable<ResponseModal> {
        return this.httpClient.get<ResponseModal>(`${environment.apiUrl}/streamuser-token`);
    }
}