import { Action, createReducer, on } from '@ngrx/store';
import * as OrgConfigActions from '../actions/org-config.actions';
import {
  OrgConfigState,
  initialOrgConfigState,
} from '../state/org-config.state';

export const orgconfigReducer = createReducer(
  initialOrgConfigState,
  on(OrgConfigActions.LoadOrgConfigAction, (state) => ({
    ...state,
    processingRequest: true,
    processingError: undefined,
  })),
  on(OrgConfigActions.LoadOrgConfigSuccessAction, (state, { payload }) => ({
    ...state,
    payload: payload.data,
    processingRequest: false,
    reloadOrgConfigList: false,
  })),
  on(OrgConfigActions.LoadOrgConfigFailedAction, (state, { payload }) => ({
    ...state,
    processingError: payload.errors,
    processingRequest: false,
    reloadOrgConfigList: false,
  })),
  on(OrgConfigActions.LoadPhysicianOrgConfigAction, (state) => ({
    ...state,
    processingRequest: true,
    processingError: undefined,
  }))
);

export function OrgConfigReducer(
  state: OrgConfigState | undefined,
  action: Action
): OrgConfigState {
  return orgconfigReducer(state, action);
}
