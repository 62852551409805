import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ITherapySession } from 'src/app/_models';
import { TherapySessionService } from 'src/app/_services';
import { ExistingDiagnosisComponent } from '../../sessions/session-codes/session-diagnosis-codes/existing-diagnosis/existing-diagnosis.component';

@Component({
  selector: 'app-group-member-diagnosis',
  templateUrl: './group-member-diagnosis.component.html',
  styleUrl: './group-member-diagnosis.component.css',
})
export class GroupMemberDiagnosisComponent implements OnInit {
  sessionDetails: ITherapySession;
  selectedMemberId: string;

  hasDiagnosisAddAccess: boolean = false;
  hasDiagnosisEditAccess: boolean = false;
  hasDiagnosisDeleteAccess: boolean = false;

  updatedSessionDetailsResponse: any;
  // Diagnosis list
  currentMemberDiagnosis = [];
  diagnosisColumn = [
    'moveArrows',
    'diagnosisIndex',
    'diagnosisCode',
    'diagnosisDescription',
  ];
  indexMap = new Map()
    .set(0, 'A')
    .set(1, 'B')
    .set(2, 'C')
    .set(3, 'D')
    .set(4, 'E')
    .set(5, 'F')
    .set(6, 'G')
    .set(7, 'H')
    .set(8, 'I')
    .set(9, 'J')
    .set(10, 'K')
    .set(11, 'L');

  isLoadingResults: boolean = false;
  processing: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<GroupMemberDiagnosisComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public therapysessionApiService: TherapySessionService
  ) {
    this.sessionDetails = data.sessionDetails;
    this.selectedMemberId = data.selectedMemberId;
    this.hasDiagnosisAddAccess = data.hasDiagnosisAddAccess;
    this.hasDiagnosisEditAccess = data.hasDiagnosisEditAccess;
    this.hasDiagnosisDeleteAccess = data.hasDiagnosisDeleteAccess;
  }

  ngOnInit(): void {
    this.extractCurrentMemberDiagnosis();

    // Push the actions column to the diagnosis column
    if (this.hasDiagnosisEditAccess || this.hasDiagnosisEditAccess) {
      if (
        !this.diagnosisColumn.includes('actions') &&
        this.sessionDetails.status === 'Active'
      ) {
        this.diagnosisColumn.push('actions');
      }
    }

    if (this.sessionDetails.status != 'Active') {
      if (this.diagnosisColumn.includes('moveArrows')) {
        this.diagnosisColumn = this.diagnosisColumn.filter(
          (column) => column != 'moveArrows'
        );
      }
    }
  }

  extractCurrentMemberDiagnosis() {
    this.isLoadingResults = true;
    this.currentMemberDiagnosis = this.sessionDetails.groupMembers
      .filter((member) => member.member.id === this.selectedMemberId)
      .at(0)?.diagnosisCodes;
    this.isLoadingResults = false;
  }

  addDiagnosis() {
    const dialogRef = this.dialog.open(ExistingDiagnosisComponent, {
      data: {
        patient: {
          id: this.selectedMemberId,
          organizationId: this.sessionDetails.organizationId,
        },
        therapySession: this.sessionDetails,
      },
      autoFocus: false,
      disableClose: true,
      minWidth: '50vw',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.type === 'success') {
        // Now we need to add this diagnosis to the member diagnosis list and refresh the object
        let diagnosis = response.diagnosis;

        if (!this.currentMemberDiagnosis) {
          this.currentMemberDiagnosis = [];
        }

        // Check if the same diagnosis does exist or not
        let diagnosisExists = this.currentMemberDiagnosis.filter(
          (emp) => emp.icdCode === diagnosis.icdCode
        );
        if (diagnosisExists.length > 0) {
          return;
        }

        let index = this.currentMemberDiagnosis.length;
        this.currentMemberDiagnosis.push({
          id: diagnosis.id,
          icdCode: diagnosis.icdCode,
          shortDesc: diagnosis.shortDesc,
          longDesc: diagnosis.longDesc,
          diagnosisIndex: this.indexMap.get(index),
          status: diagnosis.status,
        });

        this.updateTherapyDiagnosis();
      }
    });
  }

  updateTherapyDiagnosis() {
    this.processing = true;

    // Now append this diagnosis to the member list
    this.therapysessionApiService
      .updateGroupDianosis(this.sessionDetails.id, this.selectedMemberId, {
        diagnosisCodes: this.currentMemberDiagnosis,
      })
      .subscribe({
        next: (response) => {
          this.processing = false;
          this.updatedSessionDetailsResponse = response;
        },
        error: (error) => {
          this.processing = false;
        },
      });
  }

  closeTab() {
    if (this.updatedSessionDetailsResponse) {
      this.dialogRef.close({
        type: 'success',
        res: this.updatedSessionDetailsResponse,
      });
    } else {
      this.dialogRef.close();
    }
  }

  moveDiagnosis(direction, index) {
    if (
      this.currentMemberDiagnosis &&
      this.currentMemberDiagnosis?.length == 1
    ) {
      return;
    }

    // Upward
    if (direction === 'UP') {
      let previosIndex = index - 1;
      if (index == 0 || previosIndex < 0) {
        return;
      }

      let icdCode = this.currentMemberDiagnosis[index].icdCode;
      let longDesc = this.currentMemberDiagnosis[index].longDesc;
      let shortDesc = this.currentMemberDiagnosis[index].shortDesc;

      this.currentMemberDiagnosis[index].icdCode =
        this.currentMemberDiagnosis[previosIndex].icdCode;
      this.currentMemberDiagnosis[index].longDesc =
        this.currentMemberDiagnosis[previosIndex].longDesc;
      this.currentMemberDiagnosis[index].shortDesc =
        this.currentMemberDiagnosis[previosIndex].shortDesc;

      this.currentMemberDiagnosis[previosIndex].icdCode = icdCode;
      this.currentMemberDiagnosis[previosIndex].longDesc = longDesc;
      this.currentMemberDiagnosis[previosIndex].shortDesc = shortDesc;

      // Update the diagnosis
      this.updateTherapyDiagnosis();
    } else {
      // Downward
      let nextIndex = index + 1;
      if (
        index - 1 == this.currentMemberDiagnosis.length ||
        nextIndex > this.currentMemberDiagnosis.length - 1
      ) {
        return;
      }

      let icdCode = this.currentMemberDiagnosis[index].icdCode;
      let longDesc = this.currentMemberDiagnosis[index].longDesc;
      let shortDesc = this.currentMemberDiagnosis[index].shortDesc;

      this.currentMemberDiagnosis[index].icdCode =
        this.currentMemberDiagnosis[nextIndex].icdCode;
      this.currentMemberDiagnosis[index].longDesc =
        this.currentMemberDiagnosis[nextIndex].longDesc;
      this.currentMemberDiagnosis[index].shortDesc =
        this.currentMemberDiagnosis[nextIndex].shortDesc;

      this.currentMemberDiagnosis[nextIndex].icdCode = icdCode;
      this.currentMemberDiagnosis[nextIndex].longDesc = longDesc;
      this.currentMemberDiagnosis[nextIndex].shortDesc = shortDesc;

      // Update the diagnosis
      this.updateTherapyDiagnosis();
    }
  }

  removeDiagnosis(index) {
    this.currentMemberDiagnosis.splice(index, 1);

    // Resizing the index
    for (let i = index; i < this.currentMemberDiagnosis.length; i++) {
      this.currentMemberDiagnosis[i].diagnosisIndex = this.indexMap.get(i);
    }

    // Update the diagnosis
    this.updateTherapyDiagnosis();
  }
}
