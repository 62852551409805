export const EPSDTConditionInticatorType = [
  {
    id: 'AV',
    description: 'Available',
  },
  {
    id: 'NU',
    description: 'Not Used',
  },
  {
    id: 'S2',
    description: 'Under Treatment',
  },
  {
    id: 'ST',
    description: 'New Services Requested',
  },
];

export const EPSDTFamilyType = [
  {
    id: 'NA',
    description: 'Not Applicable',
  },
  {
    id: 'No',
    description: 'No',
  },
  {
    id: 'Yes',
    description: 'Yes',
  },
];
