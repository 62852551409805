import {
  HttpClient,
  HttpEvent,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private httpClient: HttpClient) {}

  upload(
    categoryId: string,
    fileType: string,
    file: File
  ): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);
    formData.append('categoryId', categoryId);

    const req = new HttpRequest(
      'POST',
      `${environment.apiUrl}/document`,
      formData,
      {
        reportProgress: true,
        responseType: 'json',
      }
    );

    return this.httpClient.request(req);
  }

  getFiles(): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}/documents/all`);
  }

  downloadDocument(fileId: string, categoryId: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('categoryId', categoryId);

    return this.httpClient.get(`${environment.apiUrl}/document/${fileId}`, {
      responseType: 'blob',
      params: params,
    });
  }

  uploadMultipleFiles(
    categoryId: string,
    fileType: string,
    file: File
  ): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);
    formData.append('categoryId', categoryId);

    const req = new HttpRequest(
      'POST',
      `${environment.apiUrl}/document`,
      formData,
      {
        reportProgress: true,
        responseType: 'json',
      }
    );

    return this.httpClient.request(req);
  }
}
