// Angular Component for Signature Pad
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import SignaturePad from 'signature_pad';
import { ISignatureLog } from 'src/app/_models/utils/signaturelog.model';
import { ToastMessageService } from 'src/app/_services';
import { SignatureService } from 'src/app/_services/utils/signature.service';
import { SignatureLogApiService } from 'src/app/_services/utils/signaturelog.service';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.css'],
})
export class SignaturePadComponent implements OnInit, AfterViewInit {
  @ViewChild('canvas') canvasEl!: ElementRef;
  @ViewChild('canvasContainer') containerRef!: ElementRef<HTMLDivElement>;

  signatureLog: ISignatureLog = {} as ISignatureLog;
  signatureNeeded!: boolean;
  signaturePad!: SignaturePad;
  signatureImg!: string;
  existingSignature: string | null = null;
  signatureStroke = false;

  redoSig: boolean = false;
  loading: boolean = false;
  processing: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<SignaturePadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public signaturelogApiService: SignatureLogApiService,
    private signatureService: SignatureService,
    private toastMessageService: ToastMessageService
  ) {
    if (data.consentRequestId) {
      this.signatureLog.consentRequestId = data.consentRequestId;
      this.signatureLog.consentDocumentId = data.consentDocumentId;
    }

    this.signatureLog.esignFormName = data.esignFormName;
    this.signatureLog.pageName = data.pageName;
    this.signatureLog.pageUrl = data.pageUrl;
  }

  ngOnInit() {
    this.getSignature();
  }

  ngAfterViewInit(): void {
    this.resizeCanvas();
  }

  addSignaturePadListener() {
    if (this.signaturePad) {
      this.signaturePad.addEventListener('beginStroke', () => {
        this.signatureStroke = true;
      });
    }
  }

  // Get Existing Signature If Exists
  getSignature() {
    this.loading = true;

    this.signatureService.getSignature().subscribe(
      (signatureData) => {
        if (signatureData.data) {
          this.existingSignature = signatureData.data;
        } else {
          this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
          this.addSignaturePadListener();
        }

        this.loading = false;
      },
      (error) => {
        this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
        this.addSignaturePadListener();

        this.loading = false;
        this.existingSignature = null;
      }
    );
  }

  // Saving The Signature
  saveSignature() {
    const dataUrl = this.signaturePad.toDataURL();

    this.processing = true;

    this.convertDataURLToFile(dataUrl, (file) => {
      this.signatureService.saveSignature('png', file).subscribe(
        (response) => {
          this.processing = false;
          this.performESignature();
        },
        (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to save the signature.'
          );
          this.processing = false;
        }
      );
    });
  }

  performESignature() {
    this.processing = true;

    this.signaturelogApiService.performESignature(this.signatureLog).subscribe(
      (response) => {
        this.processing = false;
        this.dialogRef.close(response.data);
      },
      (error) => {
        this.processing = false;
        this.toastMessageService.displayErrorMessage(
          'Error: Failed to perform the e-signature.'
        );
      }
    );
  }

  clearPad() {
    this.signatureStroke = false;
    this.signaturePad.clear();
  }

  redoSignature() {
    this.redoSig = true;

    this.wait(1000).then((result) => {
      this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
      this.addSignaturePadListener();
    });
  }

  wait(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  private convertDataURLToFile(
    dataUrl: string,
    callback: (file: File) => void
  ) {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    const file = new File([u8arr], 'signature.png', { type: mime });
    callback(file);
  }

  @HostListener('window:resize')
  onWindowResize(): void {
    this.resizeCanvas();
  }

  resizeCanvas(): void {
    if (this.canvasEl) {
      const canvas = this.canvasEl.nativeElement;
      const container = this.containerRef.nativeElement;

      // Set canvas dimensions to match container's dimensions
      canvas.width = container.clientWidth - 30;
    }
  }
}
