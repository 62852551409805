import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../store';
import { LoadPermissionsAction } from '../store/actions/permission.actions';
import {
  getMyPermissionsData,
  getMyPermissionsDataError,
  getMyPermissionsDataLoading,
} from '../store/selectors/permission.selectors';
import { Sandbox } from './base.sandbox';

@Injectable()
export class PermissionsSandbox extends Sandbox {
  constructor(protected appState$: Store<State>) {
    super(appState$);
  }

  public permissions$ = this.appState$.select(getMyPermissionsData);

  public permissionsLoading$ = this.appState$.select(
    getMyPermissionsDataLoading
  );

  public permissionsError$ = this.appState$.select(getMyPermissionsDataError);

  public triggerPermissionsLoad() {
    this.appState$.dispatch(LoadPermissionsAction());
  }
}
