import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IAsiFamilySocialRelationships } from 'src/app/_models/assessments/asi/asifamilysocialrelationships.model';
import { AsiApiService } from 'src/app/_services/assessments/asi/asi.service';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-asi-family-social-relationships',
  templateUrl: './asi-family-social-relationships.component.html',
  styleUrls: ['./asi-family-social-relationships.component.css']
})

export class AsiFamilySocialRelationshipsComponent implements OnInit {
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentRequestId: string;

  constructor(
    private asiApiService: AsiApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute) {
  }

  public action: string;
  public asifamilysocialrelationshipsForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  asifamilysocialrelationships: IAsiFamilySocialRelationships;
  asifamilysocialrelationshipsError: Error = null;
  errors: any = {};
  processing: boolean = false;
  systemGeneratedScore: number;

  public ngOnInit() {
    this.buildForm();
    this.errorHandler.handleErrors(this.asifamilysocialrelationshipsForm, this.errors);

    this.loadAsiFamilySocialRelationships();
  }


  loadAsiFamilySocialRelationships() {
    this.asiApiService.getAsiDetails(this.assessmentRequestId).subscribe(response => {
      if (response.data) {
        this.asifamilysocialrelationshipsForm.controls['f1'].setValue(response.data.asiFamilySocialRelationships.f1);
        this.asifamilysocialrelationshipsForm.controls['f2_years'].setValue(response.data.asiFamilySocialRelationships.f2_years);
        this.asifamilysocialrelationshipsForm.controls['f2_months'].setValue(response.data.asiFamilySocialRelationships.f2_months);
        this.asifamilysocialrelationshipsForm.controls['f3'].setValue(response.data.asiFamilySocialRelationships.f3);
        this.asifamilysocialrelationshipsForm.controls['f4'].setValue(response.data.asiFamilySocialRelationships.f4);
        this.asifamilysocialrelationshipsForm.controls['f5_years'].setValue(response.data.asiFamilySocialRelationships.f5_years);
        this.asifamilysocialrelationshipsForm.controls['f5_months'].setValue(response.data.asiFamilySocialRelationships.f5_months);
        this.asifamilysocialrelationshipsForm.controls['f6'].setValue(response.data.asiFamilySocialRelationships.f6);
        this.asifamilysocialrelationshipsForm.controls['f7'].setValue(response.data.asiFamilySocialRelationships.f7);
        this.asifamilysocialrelationshipsForm.controls['f8'].setValue(response.data.asiFamilySocialRelationships.f8);
        this.asifamilysocialrelationshipsForm.controls['f9'].setValue(response.data.asiFamilySocialRelationships.f9);
        this.asifamilysocialrelationshipsForm.controls['f10'].setValue(response.data.asiFamilySocialRelationships.f10);
        this.asifamilysocialrelationshipsForm.controls['f11'].setValue(response.data.asiFamilySocialRelationships.f11);
        this.asifamilysocialrelationshipsForm.controls['f12'].setValue(response.data.asiFamilySocialRelationships.f12);
        this.asifamilysocialrelationshipsForm.controls['f13'].setValue(response.data.asiFamilySocialRelationships.f13);
        this.asifamilysocialrelationshipsForm.controls['f14'].setValue(response.data.asiFamilySocialRelationships.f14);
        this.asifamilysocialrelationshipsForm.controls['f15'].setValue(response.data.asiFamilySocialRelationships.f15);
        this.asifamilysocialrelationshipsForm.controls['f16'].setValue(response.data.asiFamilySocialRelationships.f16);
        this.asifamilysocialrelationshipsForm.controls['f17'].setValue(response.data.asiFamilySocialRelationships.f17);
        this.asifamilysocialrelationshipsForm.controls['f18_past30'].setValue(response.data.asiFamilySocialRelationships.f18_past30);
        this.asifamilysocialrelationshipsForm.controls['f18_inlife'].setValue(response.data.asiFamilySocialRelationships.f18_inlife);
        this.asifamilysocialrelationshipsForm.controls['f19_past30'].setValue(response.data.asiFamilySocialRelationships.f19_past30);
        this.asifamilysocialrelationshipsForm.controls['f19_inlife'].setValue(response.data.asiFamilySocialRelationships.f19_inlife);
        this.asifamilysocialrelationshipsForm.controls['f20_past30'].setValue(response.data.asiFamilySocialRelationships.f20_past30);
        this.asifamilysocialrelationshipsForm.controls['f20_inlife'].setValue(response.data.asiFamilySocialRelationships.f20_inlife);
        this.asifamilysocialrelationshipsForm.controls['f21_past30'].setValue(response.data.asiFamilySocialRelationships.f21_past30);
        this.asifamilysocialrelationshipsForm.controls['f21_inlife'].setValue(response.data.asiFamilySocialRelationships.f21_inlife);
        this.asifamilysocialrelationshipsForm.controls['f22_past30'].setValue(response.data.asiFamilySocialRelationships.f22_past30);
        this.asifamilysocialrelationshipsForm.controls['f22_inlife'].setValue(response.data.asiFamilySocialRelationships.f22_inlife);
        this.asifamilysocialrelationshipsForm.controls['f23_past30'].setValue(response.data.asiFamilySocialRelationships.f23_past30);
        this.asifamilysocialrelationshipsForm.controls['f23_inlife'].setValue(response.data.asiFamilySocialRelationships.f23_inlife);
        this.asifamilysocialrelationshipsForm.controls['f24_past30'].setValue(response.data.asiFamilySocialRelationships.f24_past30);
        this.asifamilysocialrelationshipsForm.controls['f24_inlife'].setValue(response.data.asiFamilySocialRelationships.f24_inlife);
        this.asifamilysocialrelationshipsForm.controls['f25_past30'].setValue(response.data.asiFamilySocialRelationships.f25_past30);
        this.asifamilysocialrelationshipsForm.controls['f25_inlife'].setValue(response.data.asiFamilySocialRelationships.f25_inlife);
        this.asifamilysocialrelationshipsForm.controls['f26_past30'].setValue(response.data.asiFamilySocialRelationships.f26_past30);
        this.asifamilysocialrelationshipsForm.controls['f26_inlife'].setValue(response.data.asiFamilySocialRelationships.f26_inlife);
        this.asifamilysocialrelationshipsForm.controls['f27_past30'].setValue(response.data.asiFamilySocialRelationships.f27_past30);
        this.asifamilysocialrelationshipsForm.controls['f27_inlife'].setValue(response.data.asiFamilySocialRelationships.f27_inlife);
        this.asifamilysocialrelationshipsForm.controls['f28_past30'].setValue(response.data.asiFamilySocialRelationships.f28_past30);
        this.asifamilysocialrelationshipsForm.controls['f28_inlife'].setValue(response.data.asiFamilySocialRelationships.f28_inlife);
        this.asifamilysocialrelationshipsForm.controls['f29_past30'].setValue(response.data.asiFamilySocialRelationships.f29_past30);
        this.asifamilysocialrelationshipsForm.controls['f29_inlife'].setValue(response.data.asiFamilySocialRelationships.f29_inlife);
        this.asifamilysocialrelationshipsForm.controls['f30'].setValue(response.data.asiFamilySocialRelationships.f30);
        this.asifamilysocialrelationshipsForm.controls['f31'].setValue(response.data.asiFamilySocialRelationships.f31);
        this.asifamilysocialrelationshipsForm.controls['f32'].setValue(response.data.asiFamilySocialRelationships.f32);
        this.asifamilysocialrelationshipsForm.controls['f33'].setValue(response.data.asiFamilySocialRelationships.f33);
        this.asifamilysocialrelationshipsForm.controls['f34'].setValue(response.data.asiFamilySocialRelationships.f34);
        this.asifamilysocialrelationshipsForm.controls['f35'].setValue(response.data.asiFamilySocialRelationships.f35);
        this.asifamilysocialrelationshipsForm.controls['f36'].setValue(response.data.asiFamilySocialRelationships.f36);
        this.asifamilysocialrelationshipsForm.controls['f37'].setValue(response.data.asiFamilySocialRelationships.f37);
        this.asifamilysocialrelationshipsForm.controls['f38'].setValue(response.data.asiFamilySocialRelationships.f38);
        this.asifamilysocialrelationshipsForm.controls['comments'].setValue(response.data.asiFamilySocialRelationships.comments);
        this.asifamilysocialrelationshipsForm.controls['clinicianScore'].setValue(response.data.asiFamilySocialRelationships.clinicianScore);
      }
    });
  }
  onAsiFamilySocialRelationshipsFormSubmit() {
    if (this.asifamilysocialrelationshipsForm.invalid) {
      this.asifamilysocialrelationshipsForm.markAllAsTouched();
      return;
    }
    this.asifamilysocialrelationships = Object.assign({}, this.asifamilysocialrelationships);
    this.asifamilysocialrelationships = Object.assign(this.asifamilysocialrelationships, this.asifamilysocialrelationshipsForm.value);
    this.asiApiService.updateAsiFamilySocialRelationships(this.asifamilysocialrelationships).subscribe(() => {
    })
  };


  public validation_messages = {
    'clinicianScore': [
      { type: 'required', message: 'Clinician Score is required' },
    ],
    'f1': [
      { type: 'required', message: 'F1. Marital Status  is required' },
    ],
    'f2_years': [
      { type: 'required', message: 'F2. How long have you been in this marital status?  is required' },
    ],
    'f2_months': [
      { type: 'required', message: 'F2. How long have you been in this marital status? is required' },
    ],
    'f3': [
      { type: 'required', message: 'F3. Are you satisfied with this situation?  is required' },
    ],
    'f4': [
      { type: 'required', message: 'F4. Usual living arrangements (past 3 years)  is required' },
    ],
    'f5_years': [
      { type: 'required', message: 'F5. How long have you lived in these arrangements? is required' },
    ],
    'f5_months': [
      { type: 'required', message: 'F5. How long have you lived in these arrangements? is required' },
    ],
    'f6': [
      { type: 'required', message: 'F6. Are you satisfied with these arrangements? is required' },
    ],
    'f7': [
      { type: 'required', message: 'F7. Has a current alcohol problem? is required' },
    ],
    'f8': [
      { type: 'required', message: 'F8. Uses non-prescribed drugs?  is required' },
    ],
    'f9': [
      { type: 'required', message: 'F9. With whom do you spend most of your free time? is required' },
    ],
    'f10': [
      { type: 'required', message: 'F10. Are you satisfied with spending your free time this way?  is required' },
    ],
    'f11': [
      { type: 'required', message: 'F11. How many close friends do you have?  is required' },
    ],
    'f12': [
      { type: 'required', message: 'F12. Mother  is required' },
    ],
    'f13': [
      { type: 'required', message: 'F13. Father  is required' },
    ],
    'f14': [
      { type: 'required', message: 'F14. Brothers/Sisters is required' },
    ],
    'f15': [
      { type: 'required', message: 'F15. Sexual Partner/Spouse is required' },
    ],
    'f16': [
      { type: 'required', message: 'F16. Children is required' },
    ],
    'f17': [
      { type: 'required', message: 'F17. Friends is required' },
    ],
    'f18_past30': [
      { type: 'required', message: 'F18. Mother is required' },
    ],
    'f18_inlife': [
      { type: 'required', message: 'F18. Mother is required' },
    ],
    'f19_past30': [
      { type: 'required', message: 'F19. Father  is required' },
    ],
    'f19_inlife': [
      { type: 'required', message: 'F19. Father  is required' },
    ],
    'f20_past30': [
      { type: 'required', message: 'F20. Brothers/Sisters  is required' },
    ],
    'f20_inlife': [
      { type: 'required', message: 'F20. Brothers/Sisters  is required' },
    ],
    'f21_past30': [
      { type: 'required', message: 'F21. Sexual Partner/Spouse  is required' },
    ],
    'f21_inlife': [
      { type: 'required', message: 'F21. Sexual Partner/Spouse  is required' },
    ],
    'f22_past30': [
      { type: 'required', message: 'F22. Children  is required' },
    ],
    'f22_inlife': [
      { type: 'required', message: 'F22. Children  is required' },
    ],
    'f23_past30': [
      { type: 'required', message: 'F23. Other significant family  is required' },
    ],
    'f23_inlife': [
      { type: 'required', message: 'F23. Other significant family  is required' },
    ],
    'f24_past30': [
      { type: 'required', message: 'F24. Close Friends  is required' },
    ],
    'f24_inlife': [
      { type: 'required', message: 'F24. Close Friends  is required' },
    ],
    'f25_past30': [
      { type: 'required', message: 'F25. Neighbors  is required' },
    ],
    'f25_inlife': [
      { type: 'required', message: 'F25. Neighbors  is required' },
    ],
    'f26_past30': [
      { type: 'required', message: 'F26. Co-Workers is required' },
    ],
    'f26_inlife': [
      { type: 'required', message: 'F26. Co-Workers  is required' },
    ],
    'f27_past30': [
      { type: 'required', message: 'F27. Emotionally? is required' },
    ],
    'f27_inlife': [
      { type: 'required', message: 'F27. Emotionally? is required' },
    ],
    'f28_past30': [
      { type: 'required', message: 'F28. Physically?  is required' },
    ],
    'f28_inlife': [
      { type: 'required', message: 'F28. Physically?  is required' },
    ],
    'f29_past30': [
      { type: 'required', message: 'F29. Sexually? is required' },
    ],
    'f29_inlife': [
      { type: 'required', message: 'F29. Sexually? is required' },
    ],
    'f30': [
      { type: 'required', message: 'F30. With your family?  is required' },
    ],
    'f31': [
      { type: 'required', message: 'F31. With other people? is required' },
    ],
    'f32': [
      { type: 'required', message: 'F32. Family problems  is required' },
    ],
    'f33': [
      { type: 'required', message: 'F33. Social problems is required' },
    ],
    'f34': [
      { type: 'required', message: 'F34. Family problems is required' },
    ],
    'f35': [
      { type: 'required', message: 'F35. Social problems  is required' },
    ],
    'f36': [
      { type: 'required', message: 'F36. How would you rate the patient\'s need for family and/ or social counseling?  is required' },
    ],
    'f37': [
      { type: 'required', message: 'F37. Client\'s misrepresentation?  is required' },
    ],
    'f38': [
      { type: 'required', message: 'F38. Client\'s inability to understand is required' },
    ],
    'comments': [
      { type: 'required', message: 'Comments is required' },
    ],
  };

  private buildForm() {
    this.asifamilysocialrelationshipsForm = this.formBuilder.group({
      clinicianScore: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      systemScore: new FormControl(0, Validators.compose([
      ])),
      sessionId: new FormControl(this.sessionId, Validators.compose([
        Validators.required,
      ])),
      patientId: new FormControl(this.patientId, Validators.compose([
        Validators.required,
      ])),
      assessmentRequestId: new FormControl(this.assessmentRequestId, Validators.compose([
        Validators.required,
      ])),
      f1: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f2_years: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f2_months: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f3: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f4: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f5_years: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f5_months: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f6: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f7: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f8: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f9: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f10: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f11: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f12: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f13: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f14: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f15: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f16: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f17: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f18_past30: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f18_inlife: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f19_past30: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f19_inlife: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f20_past30: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f20_inlife: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f21_past30: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f21_inlife: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f22_past30: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f22_inlife: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f23_past30: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f23_inlife: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f24_past30: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f24_inlife: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f25_past30: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f25_inlife: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f26_past30: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f26_inlife: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f27_past30: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f27_inlife: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f28_past30: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f28_inlife: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f29_past30: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f29_inlife: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f30: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f31: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f32: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f33: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f34: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f35: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f36: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f37: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      f38: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      comments: new FormControl('', Validators.compose([
        Validators.required,
      ])),
    });
    this.asifamilysocialrelationshipsForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }


  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.asifamilysocialrelationshipsForm) {
      return;
    }
    const form = this.asifamilysocialrelationshipsForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }

}