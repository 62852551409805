<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Clinical Opiate Withdrawal Scale (COWS)</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="row">
  <!-- Risk Score -->
  <div
    class="col-md-12"
    *ngIf="assessmentId && !isPatient"
    style="
      margin-bottom: 20px;
      display: flex;
      align-content: center;
      flex-direction: column;
      font-weight: 450;
      align-items: center;
    "
  >
    Risk Score
    <button
      mat-fab
      color="primary"
      aria-label="Score"
      style="margin-left: 10px"
    >
      {{ riskScore }}
    </button>
  </div>
</div>

<form [formGroup]="cowsForm" novalidate (ngSubmit)="onCowsFormSubmit()">
  <div class="row">
    <!-- Reason for this assessment -->
    <div class="col-md-12">
      <mat-card
        class="mat-elevation-z8"
        style="border-radius: 15px; height: 100%"
      >
        <div class="col-md-12">
          <label class="labelText">
            Reason for this assessment<span style="color: red">*</span>
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              placeholder="Reason for this assessment"
              formControlName="reasonForAssessment"
              name="reasonForAssessment"
              minlength="1"
              required
            />
            <mat-error *ngIf="cowsForm.controls['reasonForAssessment'].invalid">
              Reason for this assessment is required
            </mat-error>
          </mat-form-field>
        </div>
      </mat-card>
    </div>

    <div class="col-md-6 col-xl-4 pad10">
      <mat-card
        class="mat-elevation-z8"
        style="border-radius: 15px; height: 100%"
      >
        <label class="labelBold"> Resting Pulse Rate </label>
        <label>
          beats/minute, Measured after patient is sitting or lying for one
          minute
        </label>

        <mat-button-toggle-group
          [disabled]="disableFormElements"
          vertical="true"
          formControlName="restingPulseRate"
        >
          <mat-button-toggle value="Pulse rate 80 or below" class="toggleLabel"
            >Pulse rate 80 or below</mat-button-toggle
          >
          <mat-button-toggle value="Pulse rate 81-100" class="toggleLabel"
            >Pulse rate 81-100</mat-button-toggle
          >
          <mat-button-toggle value="Pulse rate 101-120" class="toggleLabel"
            >Pulse rate 101-120</mat-button-toggle
          >
          <mat-button-toggle
            value="Pulse rate greater than 120"
            class="toggleLabel"
            >Pulse rate greater than 120</mat-button-toggle
          >
        </mat-button-toggle-group>
      </mat-card>
    </div>

    <div class="col-md-6 col-xl-4 pad10">
      <mat-card
        class="mat-elevation-z8"
        style="border-radius: 15px; height: 100%"
      >
        <label class="labelBold"> GI Upset</label>
        <label> Over last 1/2 hour </label>

        <mat-button-toggle-group
          [disabled]="disableFormElements"
          vertical="true"
          formControlName="giUpset"
        >
          <mat-button-toggle value="No GI symptoms" class="toggleLabel"
            >No GI symptoms</mat-button-toggle
          >
          <mat-button-toggle value="Stomach cramps" class="toggleLabel"
            >Stomach cramps</mat-button-toggle
          >
          <mat-button-toggle value="Nausea or loose stool" class="toggleLabel"
            >Nausea or loose stool</mat-button-toggle
          >
          <mat-button-toggle value="Vomiting or diarrhea" class="toggleLabel"
            >Vomiting or diarrhea</mat-button-toggle
          >
          <mat-button-toggle
            value="Multiple episodes of diarrhea or vomiting"
            class="toggleLabel"
            >Multiple episodes of diarrhea or vomiting</mat-button-toggle
          >
        </mat-button-toggle-group>
      </mat-card>
    </div>

    <div class="col-md-6 col-xl-4 pad10">
      <mat-card
        class="mat-elevation-z8"
        style="border-radius: 15px; height: 100%"
      >
        <label class="labelBold">Sweating</label>
        <label>
          Over past 1/2 hour not accounted for by room temperature or patient
          activity
        </label>

        <mat-button-toggle-group
          [disabled]="disableFormElements"
          vertical="true"
          formControlName="sweating"
        >
          <mat-button-toggle
            value="No report of chills or flushing"
            class="toggleLabel"
            >No report of chills or flushing</mat-button-toggle
          >
          <mat-button-toggle
            value="Subjective report of chills or flushing"
            class="toggleLabel"
            >Subjective report of chills or flushing</mat-button-toggle
          >
          <mat-button-toggle
            value="Flushed or observable moistness on face"
            class="toggleLabel"
            >Flushed or observable moistness on face</mat-button-toggle
          >
          <mat-button-toggle
            value="Beads of sweat on brow or face"
            class="toggleLabel"
            >Beads of sweat on brow or face</mat-button-toggle
          >
          <mat-button-toggle
            value="Sweat streaming off face"
            class="toggleLabel"
            >Sweat streaming off face</mat-button-toggle
          >
        </mat-button-toggle-group>
      </mat-card>
    </div>

    <div class="col-md-6 col-xl-4 pad10">
      <mat-card
        class="mat-elevation-z8"
        style="border-radius: 15px; height: 100%"
      >
        <label class="labelBold">Tremor</label>
        <label>Observation of outstretched hands </label>

        <mat-button-toggle-group
          [disabled]="disableFormElements"
          vertical="true"
          formControlName="tremor"
        >
          <mat-button-toggle value="No tremor" class="toggleLabel"
            >No tremor</mat-button-toggle
          >
          <mat-button-toggle
            value="Tremor can be felt but not observed"
            class="toggleLabel"
            >Tremor can be felt but not observed</mat-button-toggle
          >
          <mat-button-toggle
            value="Slight tremor observable"
            class="toggleLabel"
            >Slight tremor observable</mat-button-toggle
          >
          <mat-button-toggle
            value="Gross tremor or muscle twitching"
            class="toggleLabel"
            >Gross tremor or muscle twitching</mat-button-toggle
          >
        </mat-button-toggle-group>
      </mat-card>
    </div>

    <div class="col-md-6 col-xl-4 pad10">
      <mat-card
        class="mat-elevation-z8"
        style="border-radius: 15px; height: 100%"
      >
        <label class="labelBold">Restlessness</label>
        <label>Observation during assessment </label>

        <mat-button-toggle-group
          [disabled]="disableFormElements"
          vertical="true"
          formControlName="restlessness"
        >
          <mat-button-toggle value="Able to sit still" class="toggleLabel"
            >Able to sit still</mat-button-toggle
          >
          <mat-button-toggle
            value="Reports difficulty sitting still but is able to do so"
            class="toggleLabel"
            >Reports difficulty sitting still but is able to do
            so</mat-button-toggle
          >
          <mat-button-toggle
            value="Frequent shifting or extraneous movements of legs/arms"
            class="toggleLabel"
            >Frequent shifting or extraneous movements of
            legs/arms</mat-button-toggle
          >
          <mat-button-toggle
            value="Unable to sit still for more than a few seconds"
            class="toggleLabel"
            >Unable to sit still for more than a few seconds</mat-button-toggle
          >
        </mat-button-toggle-group>
      </mat-card>
    </div>

    <div class="col-md-6 col-xl-4 pad10">
      <mat-card
        class="mat-elevation-z8"
        style="border-radius: 15px; height: 100%"
      >
        <label class="labelBold">Yawning</label>
        <label>Observation during assessment</label>

        <mat-button-toggle-group
          [disabled]="disableFormElements"
          vertical="true"
          formControlName="yawning"
        >
          <mat-button-toggle value="No yawning" class="toggleLabel"
            >No yawning</mat-button-toggle
          >
          <mat-button-toggle
            value="Yawning once or twice during assessment"
            class="toggleLabel"
            >Yawning once or twice during assessment</mat-button-toggle
          >
          <mat-button-toggle
            value="Yawning three or more times during assessment"
            class="toggleLabel"
            >Yawning three or more times during assessment</mat-button-toggle
          >
          <mat-button-toggle
            value="Yawning several times/minute"
            class="toggleLabel"
            >Yawning several times/minute</mat-button-toggle
          >
        </mat-button-toggle-group>
      </mat-card>
    </div>

    <div class="col-md-6 col-xl-4 pad10">
      <mat-card
        class="mat-elevation-z8"
        style="border-radius: 15px; height: 100%"
      >
        <label class="labelBold">Pupil size</label>

        <mat-button-toggle-group
          [disabled]="disableFormElements"
          vertical="true"
          formControlName="pupilSize"
        >
          <mat-button-toggle
            value="Pupils pinned or normal size for room light"
            class="toggleLabel"
            >Pupils pinned or normal size for room light</mat-button-toggle
          >
          <mat-button-toggle
            value="Pupils possibly larger than normal for room light"
            class="toggleLabel"
            >Pupils possibly larger than normal for room
            light</mat-button-toggle
          >
          <mat-button-toggle
            value="Pupils moderately dilated"
            class="toggleLabel"
            >Pupils moderately dilated</mat-button-toggle
          >
          <mat-button-toggle
            value="Pupils so dilated that only the rim of the iris is visible"
            class="toggleLabel"
            >Pupils so dilated that only the rim of the iris is
            visible</mat-button-toggle
          >
        </mat-button-toggle-group>
      </mat-card>
    </div>

    <div class="col-md-6 col-xl-4 pad10">
      <mat-card
        class="mat-elevation-z8"
        style="border-radius: 15px; height: 100%"
      >
        <label class="labelBold">Anxiety or Irritability</label>

        <mat-button-toggle-group
          [disabled]="disableFormElements"
          vertical="true"
          formControlName="anxietyIrritability"
        >
          <mat-button-toggle value="None" class="toggleLabel"
            >None</mat-button-toggle
          >
          <mat-button-toggle
            value="Patient reports increasing irritability or anxiousness"
            class="toggleLabel"
            >Patient reports increasing irritability or
            anxiousness</mat-button-toggle
          >
          <mat-button-toggle
            value="Patient obviously irritable or anxious"
            class="toggleLabel"
            >Patient obviously irritable or anxious</mat-button-toggle
          >
          <mat-button-toggle
            value="Patient so irritable or anxious that participation in the assessment is difficult"
            class="toggleLabel"
            >Patient so irritable or anxious that participation in the
            assessment is difficult</mat-button-toggle
          >
        </mat-button-toggle-group>
      </mat-card>
    </div>

    <div class="col-md-6 col-xl-4 pad10">
      <mat-card
        class="mat-elevation-z8"
        style="border-radius: 15px; height: 100%"
      >
        <label class="labelBold">Bone or Joint aches</label>
        <label>
          If patient was having pain previously, only the additional component
          attributed to opiates withdrawal is scored</label
        >

        <mat-button-toggle-group
          [disabled]="disableFormElements"
          vertical="true"
          formControlName="boneJointAches"
        >
          <mat-button-toggle value="Not present" class="toggleLabel"
            >Not present</mat-button-toggle
          >
          <mat-button-toggle value="Mild diffuse discomfort" class="toggleLabel"
            >Mild diffuse discomfort</mat-button-toggle
          >
          <mat-button-toggle
            value="Patient reports severe diffuse aching of joints/muscles"
            class="toggleLabel"
            >Patient reports severe diffuse aching of
            joints/muscles</mat-button-toggle
          >
          <mat-button-toggle
            value="Patient is rubbing joints or muscles and is unable to sit still because of discomfort"
            class="toggleLabel"
            >Patient is rubbing joints or muscles and is unable to sit still
            because of discomfort</mat-button-toggle
          >
        </mat-button-toggle-group>
      </mat-card>
    </div>

    <div class="col-md-6 col-xl-4 pad10">
      <mat-card
        class="mat-elevation-z8"
        style="border-radius: 15px; height: 100%"
      >
        <label class="labelBold">Gooseflesh skin</label>

        <mat-button-toggle-group
          [disabled]="disableFormElements"
          vertical="true"
          formControlName="goosefleshSkin"
        >
          <mat-button-toggle value="Skin is smooth" class="toggleLabel"
            >Skin is smooth</mat-button-toggle
          >
          <mat-button-toggle
            value="Piloerrection of skin can be felt or hairs standing up on arms"
            class="toggleLabel"
            >Piloerrection of skin can be felt or hairs standing up on
            arms</mat-button-toggle
          >
          <mat-button-toggle value="Prominent piloerrection" class="toggleLabel"
            >Prominent piloerrection</mat-button-toggle
          >
        </mat-button-toggle-group>
      </mat-card>
    </div>

    <div class="col-md-6 col-xl-4 pad10">
      <mat-card
        class="mat-elevation-z8"
        style="border-radius: 15px; height: 100%"
      >
        <label class="labelBold"> Runny nose or tearing</label>
        <label>Not accounted for by cold - symptoms or allergies</label>

        <mat-button-toggle-group
          [disabled]="disableFormElements"
          vertical="true"
          formControlName="runnyNoseOrTearing"
        >
          <mat-button-toggle value="Not present" class="toggleLabel"
            >Not present</mat-button-toggle
          >
          <mat-button-toggle
            value="Nasal stuffiness or unusually moist eyes"
            class="toggleLabel"
            >Nasal stuffiness or unusually moist eyes</mat-button-toggle
          >
          <mat-button-toggle value="Nose running or tearing" class="toggleLabel"
            >Nose running or tearing</mat-button-toggle
          >
          <mat-button-toggle
            value="Nose constantly running or tears streaming down cheeks"
            class="toggleLabel"
            >Nose constantly running or tears streaming down
            cheeks</mat-button-toggle
          >
        </mat-button-toggle-group>
      </mat-card>
    </div>

    <div class="col-md-12" style="text-align: right" *ngIf="!assessmentId">
      <button
        color="primary"
        mat-raised-button
        type="submit"
        [disabled]="!cowsForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </div>
  </div>
</form>
