<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Childhood Autism Spectrum Assessment</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="childhoodAutismSpectrumForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="card">
      <div class="card-body">
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >1. Does s/he join in playing games with other children
            easily?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q1">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >2. Does s/he come up to you spontaneously for a chat?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q2">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >3. Was s/he speaking by 2 years old?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q3">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >4. Does s/he enjoy sports?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q4">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >5. Is it important to him/her to fit in with the peer group?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q5">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >6. Does s/he appear to notice unusual details that others
            miss?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q6">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >7. Does s/he tend to take things literally?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q7">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >8. When s/he was 3 years old, did s/he spend a lot of time
            pretending (e.g., play-acting being a superhero, or holding teddy's
            tea parties)?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q8">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >9. Does s/he like to do things over and over again, in the same way
            all the time?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q9">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >10. Does s/he find it easy to interact with other children?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q10">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >11. Can s/he keep a two-way conversation going?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q11">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >12. Can s/he read appropriately for his/her age?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q12">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >13. Does s/he mostly have the same interests as his/her
            peers?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q13">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >14. Does s/he have an interest which takes up so much time that
            s/he does little else?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q14">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >15. Does s/he have friends, rather than just acquaintances?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q15">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >16. Does s/he often bring you things s/he is interested in to show
            you?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q16">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >17. Does s/he enjoy joking around?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q17">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >18. Does s/he have difficulty understanding the rules for polite
            behavior?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q18">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >19. Does s/he appear to have an unusual memory for details?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q19">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >20. Is his/her voice unusual (e.g., overly adult, flat, or very
            monotonous)?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q20">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >21. Are people important to him/her?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q21">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >22. Can s/he dress him/herself?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q22">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >23. Is s/he good at turn-taking in conversation?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q23">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >24. Does s/he play imaginatively with other children, and engage in
            role-play?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q24">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >25. Does s/he often do or say things that are tactless or socially
            inappropriate?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q25">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >26. Can s/he count to 50 without leaving out any numbers?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q26">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >27. Does s/he make normal eye-contact?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q27">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >28. Does s/he have any unusual and repetitive movements?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q28">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >29. Is his/her social behavior very one-sided and always on his/her
            own terms?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q29">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >30. Does s/he sometimes say “you” or “s/he” when s/he means
            “I”?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q30">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >31. Does s/he prefer imaginative activities such as play-acting or
            story-telling, rather than numbers or lists of facts?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q31">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >32. Does s/he sometimes lose the listener because of not explaining
            what s/he is talking about?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q32">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >33. Can s/he ride a bicycle (even if with stabilizers)?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q33">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >34. Does s/he try to impose routines on him/herself, or on others,
            in such a way that it causes problems?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q34">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >35. Does s/he care how s/he is perceived by the rest of the
            group?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q35">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >36. Does s/he often turn conversations to his/her favorite subject
            rather than following what the other person wants to talk
            about?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q36">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >37. Does s/he have odd or unusual phrases?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q37">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >38. Have teachers/health visitors ever expressed any concerns about
            his/her development?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q38">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row pad10">
          <label class="col-md-6 asterix-after"
            >39. Has s/he ever been diagnosed with any of the following:
            Language delay, ADHD, hearing or visual difficulties, Autism
            Spectrum Condition (including Asperger’s Syndrome, or a physical
            disability?</label
          >
          <div class="col-sm-6">
            <mat-button-toggle-group formControlName="q39">
              <mat-button-toggle value="Yes">Yes</mat-button-toggle>
              <mat-button-toggle value="No">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          childhoodAutismSpectrumForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!childhoodAutismSpectrumForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
