import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';

import { DataTablesModel, Patient } from 'src/app/_models';
import {
  MemberPaymentHistoryApiService,
  ToastMessageService,
} from 'src/app/_services';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ViewMemberPaymentNotesComponent } from './view-member-payment-notes/view-member-payment-notes.component';

@Component({
  selector: 'app-member-payments-transactions',
  templateUrl: './member-payments-transactions.component.html',
  styleUrl: './member-payments-transactions.component.css',
})
export class MemberPaymentsTransactionsComponent implements OnInit, OnChanges {
  @Input() patient: Patient;
  @Input() hasMemberPaymentsAddAccess: boolean;
  @Input() hasMemberPaymentsEditAccess: boolean;
  @Input() hasMemberPaymentsDeleteAccess: boolean;
  @Input() hasTherapySessionViewAccess: boolean;

  displayedColumns = ['date', 'method', 'totalAmount', 'fee', 'net', 'status'];
  data: DataTablesModel = {} as DataTablesModel;
  isLoadingResults: boolean = false;
  refundProcessing: boolean = false;
  status: string = '';

  constructor(
    private router: Router,
    private memberPaymentHistoryApiService: MemberPaymentHistoryApiService,
    private dialog: MatDialog,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit(): void {
    this.data.per_page = 10;
    this.data.page = 0;

    this.handleStatusChangedDisplayedColumns();

    // Call endpoint again after 10 seconds
    setTimeout(() => {
      this.loadMemberPaymentHistory();
    }, 10000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.patient) {
      if (this.patient) {
        this.data.per_page = 10;
        this.data.page = 0;
        this.loadMemberPaymentHistory();
      }
    }
  }

  loadMemberPaymentHistory() {
    this.isLoadingResults = true;

    this.memberPaymentHistoryApiService
      .getMemberPaymentHistory(
        this.patient.id,
        this.status,
        this.data.per_page,
        this.data.page
      )
      .subscribe({
        next: (response) => {
          if (response && response.items) {
            this.data.items = response.items;
            this.data.total = response.total;
          } else {
            this.data.items = [];
            this.data.total = 0;
          }
          this.isLoadingResults = false;
        },
        error: (error) => {
          this.isLoadingResults = false;
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to load member payment history'
          );
        },
      });
  }

  handleStatusChangedDisplayedColumns() {
    if (this.hasMemberPaymentsEditAccess) {
      if (this.status === '' || this.status === 'Success') {
        if (!this.displayedColumns.includes('action')) {
          this.displayedColumns.push('action');
        }
      } else {
        this.displayedColumns = [
          'date',
          'method',
          'totalAmount',
          'fee',
          'net',
          'status',
        ];
      }
    }
  }

  collectNewPayment() {
    this.router.navigate(['main/collectmemberpayment', this.patient.id]);
  }

  filterOptionChanged(newStatus: string) {
    this.status = newStatus;
    this.data.page = 0;
    this.loadMemberPaymentHistory();
    this.handleStatusChangedDisplayedColumns();
  }

  getNext(event: PageEvent) {
    this.data.page = event.pageIndex;
    this.loadMemberPaymentHistory();
  }

  refundPayment(row) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Refund Payment',
        msg: `Are you sure that you want to refund this payment of $${row.totalAmount}?`,
        disclaimer:
          row.paymentType == 'Card'
            ? "* Refunds might take 5-10 days to appear on a customer's statement. The processing fees for the original payment won't be returned."
            : `* Original Method of Payment: ${row.paymentType}. Please click 'Yes' only when you have successfully refunded the total amount either in original payment method or in cash.`,
        yesButtonTitle: 'Yes',
        noButtonTitle: 'No',
      },
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.refundProcessing = true;
        this.memberPaymentHistoryApiService
          .refundMemberPayment(row.id)
          .subscribe({
            next: (response) => {
              this.toastMessageService.displaySuccessMessage(
                'Refund has been successfully processed.'
              );
              this.refundProcessing = false;
              this.loadMemberPaymentHistory();
            },
            error: (error) => {
              this.toastMessageService.displayErrorMessage(
                'Error: Failed to issue the refund'
              );
              this.refundProcessing = false;
            },
          });
      }
    });
  }

  navigateToSession(row) {
    if (this.hasTherapySessionViewAccess) {
      if (row?.groupAppointment) {
        // For Normal navigate to session edit
        this.router.navigate([`/group-sessions/${row.sessionId}`], {
          queryParams: {
            parent: 'Home',
          },
        });
      } else {
        // For Normal navigate to session edit
        // Check if this is a group or regular session
        this.router.navigate(['/main/member/' + row.memberId + '/NOTES'], {
          queryParams: {
            secondaryId: row.sessionId,
          },
        });
      }
    }
  }

  viewNote(row) {
    this.dialog.open(ViewMemberPaymentNotesComponent, {
      data: { notes: row.paymentNote },
      minWidth: '35vw',
      disableClose: true,
      autoFocus: false,
    });
  }
}
