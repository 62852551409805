<div class="main-content" style="background-color: #eee" *ngIf="!isLoadingResults">
  <div class="container-fluid">
    <div class="container-fluid">
      <br />
      <div class="row">
        <div class="col-md-6">
          <mat-card appearance="raised">
            <mat-card-header>
              <h4 class="header text-center">Overall Graph</h4>
            </mat-card-header>
            <mat-card-content>
              <div style="width: 100%">
                <div class="chart-wrapper">
                  <canvas baseChart [data]="lineChartData" [options]="lineChartOptions" [legend]="lineChartLegend"
                    [type]="lineChartType" [plugins]="lineChartPlugins">
                  </canvas>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-md-6">
          <mat-card appearance="raised">
            <mat-card-header>
              <h4 class="header text-center">Interpreting the Overall Score</h4>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
              <div class="row">
                <div class="col-md-2" style="font-weight: 400">Color</div>
                <div class="col-md-3" style="font-weight: 400">Score Range</div>
                <div class="col-md-7" style="padding-top: 4px; font-weight: 400">
                  Description
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <button mat-raised-button class="btn btn-just-icon btn-fab btn-round" style="
                      height: 20px;
                      width: 20px;
                      min-width: 20px;
                      background-color: #5a9e7c;
                    "></button>
                </div>
                <div class="col-md-3" style="padding-top: 4px; font-weight: 300">
                  14 to 57
                </div>
                <div class="col-md-7" style="padding-top: 4px; font-weight: 300">
                  Average total score for this age group
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <button mat-raised-button class="btn btn-just-icon btn-fab btn-round" style="
                      height: 20px;
                      width: 20px;
                      min-width: 20px;
                      background-color: #ff6384;
                    "></button>
                </div>
                <div class="col-md-3" style="padding-top: 4px; font-weight: 300">
                  0 to 13
                </div>
                <div class="col-md-7" style="padding-top: 4px; font-weight: 300">
                  May be a concern at this age
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-md-6">
          <mat-card appearance="raised">
            <mat-card-header>
              <h4 class="header text-center">Communication Composite</h4>
            </mat-card-header>
            <mat-card-content>
              <div style="width: 100%">
                <div class="chart-wrapper">
                  <canvas baseChart [data]="communicationChartData" [options]="communicationChartOptions"
                    [legend]="lineChartLegend" [type]="lineChartType" [plugins]="communicationChartPlugins">
                  </canvas>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-md-6">
          <mat-card appearance="raised">
            <mat-card-header>
              <h4 class="header text-center">
                Interpreting Communication Composite Score
              </h4>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
              <div class="row">
                <div class="col-md-2" style="font-weight: 400">Color</div>
                <div class="col-md-3" style="font-weight: 400">Score Range</div>
                <div class="col-md-7" style="padding-top: 4px; font-weight: 400">
                  Description
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <button mat-raised-button class="btn btn-just-icon btn-fab btn-round" style="
                      height: 20px;
                      width: 20px;
                      min-width: 20px;
                      background-color: #5a9e7c;
                    "></button>
                </div>
                <div class="col-md-3" style="padding-top: 4px; font-weight: 300">
                  8 to 26
                </div>
                <div class="col-md-7" style="padding-top: 4px; font-weight: 300">
                  Average communication score for this age group
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <button mat-raised-button class="btn btn-just-icon btn-fab btn-round" style="
                      height: 20px;
                      width: 20px;
                      min-width: 20px;
                      background-color: #ff6384;
                    "></button>
                </div>
                <div class="col-md-3" style="padding-top: 4px; font-weight: 300">
                  0 to 7
                </div>
                <div class="col-md-7" style="padding-top: 4px; font-weight: 300">
                  May be a concern at this age
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-md-6">
          <mat-card appearance="raised">
            <mat-card-header>
              <h4 class="header text-center">Expressive Speech Composite</h4>
            </mat-card-header>
            <mat-card-content>
              <div style="width: 100%">
                <div class="chart-wrapper">
                  <canvas baseChart [data]="speechChartData" [options]="speechChartOptions" [legend]="lineChartLegend"
                    [type]="lineChartType" [plugins]="speechChartPlugins">
                  </canvas>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-md-6">
          <mat-card appearance="raised">
            <mat-card-header>
              <h4 class="header text-center">
                Interpreting Expressive Speech Composite Score
              </h4>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
              <div class="row">
                <div class="col-md-2" style="font-weight: 400">Color</div>
                <div class="col-md-3" style="font-weight: 400">Score Range</div>
                <div class="col-md-7" style="padding-top: 4px; font-weight: 400">
                  Description
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <button mat-raised-button class="btn btn-just-icon btn-fab btn-round" style="
                      height: 20px;
                      width: 20px;
                      min-width: 20px;
                      background-color: #5a9e7c;
                    "></button>
                </div>
                <div class="col-md-3" style="padding-top: 4px; font-weight: 300">
                  3 to 14
                </div>
                <div class="col-md-7" style="padding-top: 4px; font-weight: 300">
                  average speech score for this age group
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <button mat-raised-button class="btn btn-just-icon btn-fab btn-round" style="
                      height: 20px;
                      width: 20px;
                      min-width: 20px;
                      background-color: #ff6384;
                    "></button>
                </div>
                <div class="col-md-3" style="padding-top: 4px; font-weight: 300">
                  0 to 2
                </div>
                <div class="col-md-7" style="padding-top: 4px; font-weight: 300">
                  May be a concern at this age
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-md-6">
          <mat-card appearance="raised">
            <mat-card-header>
              <h4 class="header text-center">Symbolic Composite</h4>
            </mat-card-header>
            <mat-card-content>
              <div style="width: 100%">
                <div class="chart-wrapper">
                  <canvas baseChart [data]="symbolicChartData" [options]="symbolicChartOptions"
                    [legend]="lineChartLegend" [type]="lineChartType" [plugins]="symbolicChartPlugins">
                  </canvas>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-md-6">
          <mat-card appearance="raised">
            <mat-card-header>
              <h4 class="header text-center">
                Interpreting Symbolic Composite Score
              </h4>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
              <div class="row">
                <div class="col-md-2" style="font-weight: 400">Color</div>
                <div class="col-md-3" style="font-weight: 400">Score Range</div>
                <div class="col-md-7" style="padding-top: 4px; font-weight: 400">
                  Description
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <button mat-raised-button class="btn btn-just-icon btn-fab btn-round" style="
                      height: 20px;
                      width: 20px;
                      min-width: 20px;
                      background-color: #5a9e7c;
                    "></button>
                </div>
                <div class="col-md-3" style="padding-top: 4px; font-weight: 300">
                  3 to 17
                </div>
                <div class="col-md-7" style="padding-top: 4px; font-weight: 300">
                  Average symbolic composite score for this age group
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <button mat-raised-button class="btn btn-just-icon btn-fab btn-round" style="
                      height: 20px;
                      width: 20px;
                      min-width: 20px;
                      background-color: #ff6384;
                    "></button>
                </div>
                <div class="col-md-3" style="padding-top: 4px; font-weight: 300">
                  0 to 3
                </div>
                <div class="col-md-7" style="padding-top: 4px; font-weight: 300">
                  May be a concern at this age
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <br />
      <div class="row">
        <ng-container *ngFor="let scoreDetail of scoreDetails">
          <div class="col-md-4" style="margin-bottom: 20px">
            <mat-card appearance="raised">
              <mat-card-header>
                <h4 class="header text-center">{{ scoreDetail.title }}</h4>
              </mat-card-header>
              <mat-card-content>
                <div style="width: 100%">
                  <div class="chart-wrapper">
                    <canvas baseChart [data]="scoreDetail" [options]="assessmentDetailChartOptions"
                      [legend]="lineChartLegend" [type]="lineChartType" [plugins]="detailChartPlugins">
                    </canvas>
                  </div>
                </div>
              </mat-card-content>
              <small><b>Answer:</b> <i>{{ scoreDetail.answer }}</i></small>
            </mat-card>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>