<mat-card-title>Family History</mat-card-title>
<mat-divider></mat-divider>

<div style="margin-top: 20px; padding: 10px">
  <form
    [formGroup]="bpsafamilyhistoryForm"
    novalidate
    (ngSubmit)="onBpsaFamilyHistoryFormSubmit()"
  >
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText">
            Who raised you? <span style="color: red">*</span>
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="raisedBy"
              name="raisedBy"
              required
            />

            <mat-error
              *ngIf="bpsafamilyhistoryForm.controls['raisedBy'].invalid"
            >
              Who raised you is required.
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >Where were you born and raised?
            <span style="color: red">*</span></label
          >&nbsp;
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="bornAndRaisedAt"
              name="bornAndRaisedAt"
              required
            />
            <mat-error
              *ngIf="bpsafamilyhistoryForm.controls['bornAndRaisedAt'].invalid"
            >
              Where were you born and raised is required.
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText">
            Do you have siblings, brothers, half-brothers, sisters, or
            half-sisters? <span style="color: red">*</span> </label
          ><br />
          <mat-button-toggle-group formControlName="siblings">
            <mat-button-toggle value="Yes">Yes</mat-button-toggle>
            <mat-button-toggle value="No">No</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >How would you describe your childhood environment?
            <span style="color: red">*</span></label
          >&nbsp;
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="childhoodEnvironment"
              name="childhoodEnvironment"
              required
            />
            <mat-error
              *ngIf="
                bpsafamilyhistoryForm.controls['childhoodEnvironment'].invalid
              "
            >
              What was your childhood environment like is required.
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >What were you like as a child?
            <span style="color: red">*</span></label
          >&nbsp;
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="howAsChild"
              name="howAsChild"
              required
            />
            <mat-error
              *ngIf="bpsafamilyhistoryForm.controls['howAsChild'].invalid"
            >
              What were you like a child is required.
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >Who do you feel the closest to within your family?
            <span style="color: red">*</span></label
          >&nbsp;
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="closestWithinFamily"
              name="closestWithinFamily"
              required
            />
            <mat-error
              *ngIf="
                bpsafamilyhistoryForm.controls['closestWithinFamily'].invalid
              "
            >
              Who do you feel the closest to within your family is required.
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row">
        <div class="col-md-12">
          <label class="labelText"
            >Who do you feel the most disconnected from within in your family?
            <span style="color: red">*</span></label
          >&nbsp;
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="mostDisconnectedWith"
              name="mostDisconnectedWith"
              required
            />
            <mat-error
              *ngIf="
                bpsafamilyhistoryForm.controls['mostDisconnectedWith'].invalid
              "
            >
              Who do you feel the most disconnected from within in your family
              is required.
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card>

    <app-member-family-history-list
      [therapySession]="therapySession"
      [loggedInUserId]="loggedInUserId"
      [hasBPSAClinicalAddAccess]="hasBPSAClinicalAddAccess"
      [hasBPSAClinicalEditAccess]="hasBPSAClinicalEditAccess"
    ></app-member-family-history-list>
  </form>
</div>
