import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { DataTablesModel } from 'src/app/_models';
import {
  ClaimsAPIService,
  DateTimeZoneService,
  ToastMessageService,
} from 'src/app/_services';

@Component({
  selector: 'app-record-remittance-select-claim',
  templateUrl: './record-remittance-select-claim.component.html',
  styleUrl: './record-remittance-select-claim.component.css',
})
export class RecordRemittanceSelectClaimComponent implements OnInit {
  searchClaimCode = '';
  searchMemberName: string = '';
  searchProviderName: string = '';

  processing: boolean = false;
  apiError: boolean = false;
  currentTimezone: string;

  claimsListData: DataTablesModel = {} as DataTablesModel;
  displayedColumns = [
    'member',
    'payer',
    'referenceIds',
    'details',
    'totalCharge',
    'actions',
  ];

  constructor(
    public dialogRef: MatDialogRef<RecordRemittanceSelectClaimComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private claimsApiService: ClaimsAPIService,
    private toastMessageService: ToastMessageService,
    private dateTimeZoneService: DateTimeZoneService
  ) {
    this.currentTimezone = dateTimeZoneService.getMyTimeZone();
  }

  ngOnInit(): void {
    this.claimsListData.per_page = 10;
    this.claimsListData.page = 0;

    this.loadClaims();
  }

  loadClaims() {
    this.processing = true;
    this.apiError = false;
    this.claimsApiService
      .getAllClaims(
        this.searchMemberName,
        this.searchProviderName,
        'All Claims',
        this.searchClaimCode,
        null,
        null,
        null,
        null,
        this.claimsListData.per_page,
        this.claimsListData.page
      )
      .subscribe({
        next: (response) => {
          if (response && response.items) {
            this.claimsListData.items = response.items;
            this.claimsListData.total = response.total;
          } else {
            this.claimsListData.items = [];
            this.claimsListData.total = 0;
          }
          this.processing = false;
        },
        error: (error) => {
          this.toastMessageService.displayErrorMessage(
            'Error: Failed to get the claims list.'
          );
          this.apiError = true;
          this.processing = false;
        },
      });
  }

  getNext(event: PageEvent) {
    this.claimsListData.page = event.pageIndex;
    this.loadClaims();
  }

  searchChanged() {
    this.claimsListData.page = 0;
    this.loadClaims();
  }

  selectClaim(claim) {
    this.dialogRef.close({ type: 'success', claim: claim });
  }
}
