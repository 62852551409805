import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ResponseModal } from '../../_models';
import { IEulaContract } from '../../_models/eulacontract.model';
@Injectable({ providedIn: 'root' })
export class EulaDocumentApiService {
    constructor(private httpClient: HttpClient) { }
    getEulaDocumentDetails(): Observable<ResponseModal> {
        return this.httpClient.get<ResponseModal>(`${environment.apiUrl}/euladocument`);
    }

    addEulaContract(document: string, documentVersion: number): Observable<IEulaContract> {
        let eulaContract: any = {};
        eulaContract.document = document;
        eulaContract.documentVersion = documentVersion;

        const headers = { 'content-type': 'application/json' }
        return this.httpClient.post<IEulaContract>(`${environment.apiUrl}/eulacontract`, eulaContract, { headers }).pipe(
            catchError((err) => {
                console.error(err);
                throw err;
            }
            )
        );
    }
}