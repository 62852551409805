<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>Binge Eating Scale</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="bingeEatingScaleForm"
    novalidate
    (ngSubmit)="onFormSubmit()"
    class="form-horizontal"
  >
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">1.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="question1"
        >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I don't feel self - conscious about my weight or body size when I'm with others"
            >I don't feel self - conscious about my weight or body size when I'm
            with others.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I feel concerned about how I look to others, but it normally does not make me feel disappointed with myself"
            >I feel concerned about how I look to others, but it normally does
            not make me feel disappointed with myself.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I do not get self-conscious about my appearance and weight which makes me feel disappointed in myself"
            >I do not get self-conscious about my appearance and weight which
            makes me feel disappointed in myself.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I feel very self-conscious about my weight and frequently, I feel intense shame and disgust for myself. I try to avoid social contacts because of my self-consciousness"
            >I feel very self-conscious about my weight and frequently, I feel
            intense shame and disgust for myself. I try to avoid social contacts
            because of my self-consciousness.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">2.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="question2"
        >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I don't have any difficulty eating slowly in the proper manner"
            >I don't have any difficulty eating slowly in the proper
            manner.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value='Although I seem to "Gobble down" foods, I don&apos;t end up feeling stuffed because of eating too much'
            >Although I seem to "Gobble down" foods, I don't end up feeling
            stuffed because of eating too much.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="At times, I tend to eat quickly and then, I feel uncomfortably full afterwards"
            >At times, I tend to eat quickly and then, I feel uncomfortably full
            afterwards.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I have the habit of bolting down my food, without really chewing it. When this happens I usually feel uncomfortably stuffed because I've eaten too much"
            >I have the habit of bolting down my food, without really chewing
            it. When this happens I usually feel uncomfortably stuffed because
            I've eaten too much.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">3.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="question3"
        >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I feel capable to control my eating urges when I want to"
            >I feel capable to control my eating urges when I want
            to.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I feel like I have failed to control my eating more than the average person"
            >I feel like I have failed to control my eating more than the
            average person.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I feel utterly helpless when it comes to feeling in control of my eating urges"
            >I feel utterly helpless when it comes to feeling in control of my
            eating urges.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="Because I feel so helpless about controlling my eating I have become very desperate about trying to get in control"
            >Because I feel so helpless about controlling my eating I have
            become very desperate about trying to get in
            control.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">4.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="question4"
        >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I don't have the habit of eating when I'm bored"
            >I don't have the habit of eating when I'm bored.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I sometimes eat when I'm bored, but often I'm able to &quot;get busy&quot; and get my mind off food"
            >I sometimes eat when I am bored, but often I'm able to "get busy"
            and get my mind off food.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I have a regular habit of eating when I'm bored, but occasionally, I can use some other activity to get my mind off eating"
            >I have a regular habit of eating when I'm bored, but occasionally,
            I can use some other activity to get my mind off
            eating.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I have a strong habit of eating when I'm bored. Nothing seems to help me break the habit"
            >I have a strong habit of eating when I'm bored. Nothing seems to
            help me break the habit.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">5.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="question5"
        >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I'm usually physically hungry when I eat something"
            >I'm usually physically hungry when I eat
            something.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="Occasionally, I eat something on impulse even though I am really not hungry"
            >Occasionally, I eat something on impulse even though I am really
            not hungry.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I have the regular habit of eating habits, that I might not really enjoy, to satisfy a hungry feeling even though physically, I don't need the food"
            >I have the regular habit of eating habits, that I might not really
            enjoy, to satisfy a hungry feeling even though physically, I don't
            need the food.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="Although I'm not physically hungry, I get a hungry feeling in my mouth that only seems to be satisfied when I eat a food, like a sandwich, that fills my mouth. Sometimes,when  I eat the food to satisfy my mouth hunger, I then spit the food out so I won't gain weight"
            >Although I'm not physically hungry, I get a hungry feeling in my
            mouth that only seems to be satisfied when I eat a food, like a
            sandwich, that fills my mouth. Sometimes,when I eat the food to
            satisfy my mouth hunger, I then spit the food out so I won't gain
            weight.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">6.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="question6"
        >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I don't feel any guilt or self-hate after I overeat"
            >I don't feel any guilt or self-hate after I
            overeat.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="After I overeat, occasionally I feel guilt or self-hate"
            >After I overeat, occasionally I feel guilt or
            self-hate.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="Almost all the time I experience strong guilt or self-hate after I overeat"
            >Almost all the time I experience strong guilt or self-hate after I
            overeat.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">7.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="question7"
        >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I don't lose total control of my eating when dieting even after periods when I overeat"
            >I don't lose total control of my eating when dieting even after
            periods when I overeat.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value='Sometimes when I eat a "forbidden food" on a diet, I feel like I "blew it" and eat even more'
            >Sometimes when I eat a "forbidden food" on a diet, I feel like I
            "blew it" and eat even more.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value='Frequently, I have the habit of saying to myself, "I&apos;ve blown it now, why not go all the way" when I overeat on a diet. When that happens, I eat even more'
            >Frequently, I have the habit of saying to myself, "I've blown it
            now, why not go all the way" when I overeat on a diet. When that
            happens, I eat even more.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value='I have a regular habit of starting strict diets for myself, but I break the diets by going on an eating binge. My life seems to be either a "feast" or "famine"'
            >I have a regular habit of starting strict diets for myself, but I
            break the diets by going on an eating binge. My life seems to be
            either a " feast" or "famine" .</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">8.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="question8"
        >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I rarely eat so much food that I feel uncomfortably stuffed afterwards"
            >I rarely eat so much food that I feel uncomfortably stuffed
            afterwards.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="Usually about once a month, I eat such a quantity of food, I end up feeling very stuffed"
            >Usually about once a month, I eat such a quantity of food, I end up
            feeling very stuffed.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I have regular periods during the month when I eat large amounts of food, either at mealtime or at snacks"
            >I have regular periods during the month when I eat large amounts of
            food, either at mealtime or at snacks.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I eat so much food that I regularly feel quite uncomfortable after eating and sometimes a bit nauseous"
            >I eat so much food that I regularly feel quite uncomfortable after
            eating and sometimes a bit nauseous.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">9.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="question9"
        >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="My level of calorie intake does not go up very  high or go down very low on a regular basis"
            >My level of calorie intake does not go up very high or go down very
            low on a regular basis.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="Sometimes after I overeat, I will try to reduce my caloric intake to almost nothing to compensate for the excess calories I've eaten"
            >Sometimes after I overeat, I will try to reduce my caloric intake
            to almost nothing to compensate for the excess calories I've
            eaten.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I have a regular habit of overeating during at night. It seems that my routine is not to be hungry in the morning but overeat in the evening"
            >I have a regular habit of overeating during at night. It seems that
            my routine is not to be hungry in the morning but overeat in the
            evening.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value='In my adult years, I have had week-long periods where I practically starve myself. This follows periods when I overeat. It seems I live a life of either "feast or famine"'
            >In my adult years, I have had week-long periods where I practically
            starve myself. This follows periods when I overeat. It seems I live
            a life of either "feast or famine".</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">10.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="question10"
        >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value='I usually am able to stop eating when I want to. I know when "enough is enough"'
            >I usually am able to stop eating when I want to. I know when
            "enough is enough".</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="Every so often, I experience a compulsion to eat which I can't seem to control"
            >Every so often, I experience a compulsion to eat which I can't seem
            to control.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="Frequently, I experience strong urges to eat which I seem unable to control, but at other times I can control my eating urges"
            >Frequently, I experience strong urges to eat which I seem unable to
            control, but at other times I can control my eating
            urges.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I feel incapable of controlling urges to eat. I have a fear of not being able to stop eating voluntarily"
            >I feel incapable of controlling urges to eat. I have a fear of not
            being able to stop eating voluntarily.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">11.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="question11"
        >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I don't have any problem stopping eating when I feel full"
            >I don't have any problem stopping eating when I feel
            full.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I usually can stop eating when I feel full but occasionally overeat leaving me feeling uncomfortably stuffed"
            >I usually can stop eating when I feel full but occasionally overeat
            leaving me feeling uncomfortably stuffed.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I have a problem stopping eating once I start and usually  I feel uncomfortably stuffed after I eat"
            >I have a problem stopping eating once I start and usually I feel
            uncomfortably stuffed after I eat.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="Because I have a problem not being able to stop eating when I want, I sometimes have to induce vomiting to relieve my stuffed feeling"
            >Because I have a problem not being able to stop eating when I want,
            I sometimes have to induce vomiting to relieve my stuffed
            feeling.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">12.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="question12"
        >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I seem to eat just as much when I 'm with others (family, social gatherings) as when I'm by myself"
            >I seem to eat just as much when I 'm with others (family, social
            gatherings) as when I'm by myself.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="Sometimes, when I'm with other persons, I don't eat as much as I want to eat because I'm self-conscious about my eating"
            >Sometimes, when I'm with other persons, I don't eat as much as I
            want to eat because I'm self-conscious about my
            eating.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="Frequently, I eat only a small amount of food when others are present, because I'm very embarrassed about my eating"
            >Frequently, I eat only a small amount of food when others are
            present, because I'm very embarrassed about my
            eating.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value='I feel so ashamed about overeating that I pick times to overeat when I know no one will see me. I feel like a "closet eater"'
            >I feel so ashamed about overeating that I pick times to overeat
            when I know no one will see me. I feel like a "closet
            eater".</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">13.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="question13"
        >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I eat three meals a day with only an occasional between meal snack"
            >I eat three meals a day with only an occasional between meal
            snack.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I eat 3 meals a day, but I also normally snack between meals"
            >I eat 3 meals a day, but I also normally snack between
            meals.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="When I am snacking heavily, I get in the habit of skipping regular meals"
            >When I am snacking heavily, I get in the habit of skipping regular
            meals.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="There are regular periods when I seem to be continually eating with no planned meals"
            >There are regular periods when I seem to be continually eating with
            no planned meals.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">14.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="question14"
        >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I don't think much about trying to control unwanted eating urges"
            >I don't think much about trying to control unwanted eating
            urges.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="At least some of the time, I feel my thoughts are pre-occupied with trying to control my eating urges"
            >At least some of the time, I feel my thoughts are pre-occupied with
            trying to control my eating urges.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I feel that frequently I spend much time thinking about how much I ate or about trying not to eat anymore"
            >I feel that frequently I spend much time thinking about how much I
            ate or about trying not to eat anymore.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="It seems to me that most of my waking hours are pre-occupied by thoughts about eating or not eating. I feel like I'm constantly struggling not to eat"
            >It seems to me that most of my waking hours are pre-occupied by
            thoughts about eating or not eating. I feel like I'm constantly
            struggling not to eat.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">15.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="question15"
        >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I don't think about food a great deal"
            >I don't think about food a great deal.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I have strong cravings for food but they last only for brief periods of time"
            >I have strong cravings for food but they last only for brief
            periods of time.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I have days when I can't seem to think about anything else but food"
            >I have days when I can't seem to think about anything else but
            food.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="Most of my days seem to be pre-occupied with thoughts about food. I feel like I live to eat"
            >Most of my days seem to be pre-occupied with thoughts about food. I
            feel like I live to eat.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="row pad10 mat-elevation-z8">
      <label class="col-md-12 asterix-after">16.</label>
      <div class="col-sm-12">
        <mat-button-toggle-group
          [vertical]="true"
          style="width: 100%"
          formControlName="question16"
        >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="I usually know whether or not I'm physically hungry. I take the right portion of food to satisfy me"
            >I usually know whether or not I'm physically hungry. I take the
            right portion of food to satisfy me.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value="Occasionally, I feel uncertain about knowing whether or not I'm physically hungry. At these times it's hard to know how much food I should take to satisfy me"
            >Occasionally, I feel uncertain about knowing whether or not I'm
            physically hungry. At these times it's hard to know how much food I
            should take to satisfy me.</mat-button-toggle
          >
          <mat-button-toggle
            style="white-space: pre-wrap"
            value='Even though I might know how many calories I should eat, I don&apos;t have any idea what is a "normal" amount of food for me'
            >Even though I might know how many calories I should eat, I don't
            have any idea what is a "normal" amount of food for
            me.</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>

    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          bingeEatingScaleForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!bingeEatingScaleForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
