import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ToastMessageService } from 'src/app/_services';
import { ConsentDocumentApiService } from 'src/app/_services/consent-docs/orgconsentdocument.service';
import { GuestIntakeSignaturePadComponent } from '../guest-intake-signature-pad/guest-intake-signature-pad.component';

@Component({
  selector: 'app-guest-intake-consent-documents',
  templateUrl: './guest-intake-consent-documents.component.html',
  styleUrl: './guest-intake-consent-documents.component.css',
})
export class GuestIntakeConsentDocumentsComponent implements OnInit {
  @ViewChild('pdfViewerOnDemand', { static: false }) pdfViewerOnDemand;
  @ViewChild('signedPdf', { static: false }) signedPdf;

  requestId: string = '';
  accessCode: string = '';
  organizationId: string = '';
  consentRequestId: string = '';
  consentDocumentId: string = '';
  documentName: string = '';

  isLoadingResults: boolean = true;
  processing = false;
  isModalWindow: boolean = true;

  constructor(
    @Optional() public dialogRef: MatDialogRef<GuestIntakeConsentDocumentsComponent> | null = null,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any = null,
    private dialog: MatDialog,
    public consentDocumentApiService: ConsentDocumentApiService,
    private toastMessageService: ToastMessageService
  ) {
    if (data) {
      this.requestId = data.requestId;
      this.accessCode = data.accessCode;
      this.organizationId = data.organizationId;
      this.consentRequestId = data.consentRequestId;
      this.consentDocumentId = data.consentDocumentId;
      this.documentName = data.documentName;
    }else{
      this.isModalWindow = false;
    }
  }

  ngOnInit(): void {
    this.getConsentDocumentInformation();
  }

  getConsentDocumentInformation() {
    this.isLoadingResults = true;

    // Calling in different endpoint for guest vs logged in user
    if (this.accessCode) {
      // FOr guests, accesscode needs to be present
      this.consentDocumentApiService
        .previewConsentDocumentForGuest(
          this.requestId,
          this.accessCode,
          this.consentDocumentId
        )
        .subscribe(
          (res) => {
            this.isLoadingResults = false;
            this.pdfViewerOnDemand.pdfSrc = res;
            this.pdfViewerOnDemand.refresh();
          },
          (error) => {
            this.isLoadingResults = false;
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to retrieve the consent document'
            );
          }
        );
    } else {
      // For logged in CP/Provider
      this.consentDocumentApiService
        .previewConsentDocument(this.consentDocumentId)
        .subscribe(
          (res) => {
            this.isLoadingResults = false;
            this.pdfViewerOnDemand.pdfSrc = res;
            this.pdfViewerOnDemand.refresh();
          },
          (error) => {
            this.isLoadingResults = false;
            this.toastMessageService.displayErrorMessage(
              'Error: Failed to retrieve the consent document'
            );
          }
        );
    }
  }

  openSignaturePad() {
    const dialogRef = this.dialog.open(GuestIntakeSignaturePadComponent, {
      data: {
        requestId: this.requestId,
        accessCode: this.accessCode,
        organizationId: this.organizationId,
        consentRequestId: this.consentRequestId,
        consentDocumentId: this.consentDocumentId,
        esignFormName: this.documentName,
        pageName: 'Guest Link Consent Document',
        pageUrl: window.location.href,
      },
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response === 'success') {
        this.dialogRef.close('success');
      }
    });
  }
}
