<div class="dialogTitle" *ngIf="displayTitle">
  <p matDialogTitle>The Couples Satisfaction Index (CSI)</p>

  <button
    mat-mini-fab
    style="background-color: transparent; color: #555555"
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="col-md-12">
  <div *ngIf="assessmentId && !isPatient" class="row pad10">
    <div class="col-sm-5"></div>
    <div class="col-sm-2">
      Risk Score:
      <button
        mat-fab
        color="primary"
        aria-label="Score"
        style="margin-left: 10px"
      >
        {{ riskScore }}
      </button>
    </div>
    <div class="col-sm-5"></div>
  </div>

  <form
    [formGroup]="csiAssessmentForm"
    novalidate
    (ngSubmit)="onCsiFormSubmit()"
    class="form-horizontal"
  >
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >1. Please indicate the degree of happiness, all things considered, of
          your relationship.</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="pleaseIndicateTheDegree">
            <mat-button-toggle value="Extremely Unhappy"
              >Extremely Unhappy</mat-button-toggle
            >
            <mat-button-toggle value="Fairly Unhappy"
              >Fairly Unhappy</mat-button-toggle
            >
            <mat-button-toggle value="A Little Unhappy"
              >A Little Unhappy</mat-button-toggle
            >
            <mat-button-toggle value="Happy">Happy</mat-button-toggle>
            <mat-button-toggle value="Very Happy">Very Happy</mat-button-toggle>
            <mat-button-toggle value="Extremely Happy"
              >Extremely Happy</mat-button-toggle
            >
            <mat-button-toggle value="Perfect">Perfect</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>

    <div style="font-size: 13px; font-weight: 450">
      Most people have disagreements in their relationships. Please indicate
      below the approximate extent of agreement or disagreement between you and
      your partner for each item on the following list.
    </div>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >2. Amount of time spent together</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="amountOfTimeSpent">
            <mat-button-toggle value="Always Agree"
              >Always Agree</mat-button-toggle
            >
            <mat-button-toggle value="Almost Always Agree"
              >Almost Always Agree</mat-button-toggle
            >
            <mat-button-toggle value="Occasionally Disagree"
              >Occasionally Disagree</mat-button-toggle
            >
            <mat-button-toggle value="Frequently Disagree"
              >Frequently Disagree</mat-button-toggle
            >
            <mat-button-toggle value="Almost Always Disagree"
              >Almost Always Disagree</mat-button-toggle
            >
            <mat-button-toggle value="Always Disagree"
              >Always Disagree</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after">3. Making major decisions</label>
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="makingMajorDecisions">
            <mat-button-toggle value="Always Agree"
              >Always Agree</mat-button-toggle
            >
            <mat-button-toggle value="Almost Always Agree"
              >Almost Always Agree</mat-button-toggle
            >
            <mat-button-toggle value="Occasionally Disagree"
              >Occasionally Disagree</mat-button-toggle
            >
            <mat-button-toggle value="Frequently Disagree"
              >Frequently Disagree</mat-button-toggle
            >
            <mat-button-toggle value="Almost Always Disagree"
              >Almost Always Disagree</mat-button-toggle
            >
            <mat-button-toggle value="Always Disagree"
              >Always Disagree</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >4. Demonstrations of affection</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="demonstrationsOfAffection">
            <mat-button-toggle value="Always Agree"
              >Always Agree</mat-button-toggle
            >
            <mat-button-toggle value="Almost Always Agree"
              >Almost Always Agree</mat-button-toggle
            >
            <mat-button-toggle value="Occasionally Disagree"
              >Occasionally Disagree</mat-button-toggle
            >
            <mat-button-toggle value="Frequently Disagree"
              >Frequently Disagree</mat-button-toggle
            >
            <mat-button-toggle value="Almost Always Disagree"
              >Almost Always Disagree</mat-button-toggle
            >
            <mat-button-toggle value="Always Disagree"
              >Always Disagree</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>

    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >5. In general, how often do you think that things between you and
          your partner are going well?</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="inGeneralHowOftenWell">
            <mat-button-toggle value="All the Time"
              >All the Time</mat-button-toggle
            >
            <mat-button-toggle value="Most of the Time"
              >Most of the Time</mat-button-toggle
            >
            <mat-button-toggle value="More often than Not"
              >More often than Not</mat-button-toggle
            >
            <mat-button-toggle value="Occasionally"
              >Occasionally</mat-button-toggle
            >
            <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
            <mat-button-toggle value="Never">Never</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >6. How often do you wish you hadn’t gotten into this
          relationship?</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="howOftenWishHadnt">
            <mat-button-toggle value="All the Time"
              >All the Time</mat-button-toggle
            >
            <mat-button-toggle value="Most of the Time"
              >Most of the Time</mat-button-toggle
            >
            <mat-button-toggle value="More often than Not"
              >More often than Not</mat-button-toggle
            >
            <mat-button-toggle value="Occasionally"
              >Occasionally</mat-button-toggle
            >
            <mat-button-toggle value="Rarely">Rarely</mat-button-toggle>
            <mat-button-toggle value="Never">Never</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>

    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >7. I still feel a strong connection with my partner</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="stillFeelStrongConnect">
            <mat-button-toggle value="Not at all True"
              >Not at all True</mat-button-toggle
            >
            <mat-button-toggle value="A little True"
              >A little True</mat-button-toggle
            >
            <mat-button-toggle value="Somewhat True"
              >Somewhat True</mat-button-toggle
            >
            <mat-button-toggle value="Mostly True"
              >Mostly True</mat-button-toggle
            >
            <mat-button-toggle value="Almost Completely True"
              >Almost Completely True</mat-button-toggle
            >
            <mat-button-toggle value="Completely True"
              >Completely True</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >8. If I had my life to live over, I would marry (or live with/date)
          the same person</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="ifIHadMyLifeOver">
            <mat-button-toggle value="Not at all True"
              >Not at all True</mat-button-toggle
            >
            <mat-button-toggle value="A little True"
              >A little True</mat-button-toggle
            >
            <mat-button-toggle value="Somewhat True"
              >Somewhat True</mat-button-toggle
            >
            <mat-button-toggle value="Mostly True"
              >Mostly True</mat-button-toggle
            >
            <mat-button-toggle value="Almost Completely True"
              >Almost Completely True</mat-button-toggle
            >
            <mat-button-toggle value="Completely True"
              >Completely True</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >9. Our relationship is strong</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="ourRelationshipIsStrong">
            <mat-button-toggle value="Not at all True"
              >Not at all True</mat-button-toggle
            >
            <mat-button-toggle value="A little True"
              >A little True</mat-button-toggle
            >
            <mat-button-toggle value="Somewhat True"
              >Somewhat True</mat-button-toggle
            >
            <mat-button-toggle value="Mostly True"
              >Mostly True</mat-button-toggle
            >
            <mat-button-toggle value="Almost Completely True"
              >Almost Completely True</mat-button-toggle
            >
            <mat-button-toggle value="Completely True"
              >Completely True</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >10. I sometimes wonder if there is someone else out there for
          me</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="sometimesWonderIfElse">
            <mat-button-toggle value="Not at all True"
              >Not at all True</mat-button-toggle
            >
            <mat-button-toggle value="A little True"
              >A little True</mat-button-toggle
            >
            <mat-button-toggle value="Somewhat True"
              >Somewhat True</mat-button-toggle
            >
            <mat-button-toggle value="Mostly True"
              >Mostly True</mat-button-toggle
            >
            <mat-button-toggle value="Almost Completely True"
              >Almost Completely True</mat-button-toggle
            >
            <mat-button-toggle value="Completely True"
              >Completely True</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >11. My relationship with my partner makes me happy</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="myRelationshipMakesHappy">
            <mat-button-toggle value="Not at all True"
              >Not at all True</mat-button-toggle
            >
            <mat-button-toggle value="A little True"
              >A little True</mat-button-toggle
            >
            <mat-button-toggle value="Somewhat True"
              >Somewhat True</mat-button-toggle
            >
            <mat-button-toggle value="Mostly True"
              >Mostly True</mat-button-toggle
            >
            <mat-button-toggle value="Almost Completely True"
              >Almost Completely True</mat-button-toggle
            >
            <mat-button-toggle value="Completely True"
              >Completely True</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >12. I have a warm and comfortable relationship with my partner</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="haveWarmComfortable">
            <mat-button-toggle value="Not at all True"
              >Not at all True</mat-button-toggle
            >
            <mat-button-toggle value="A little True"
              >A little True</mat-button-toggle
            >
            <mat-button-toggle value="Somewhat True"
              >Somewhat True</mat-button-toggle
            >
            <mat-button-toggle value="Mostly True"
              >Mostly True</mat-button-toggle
            >
            <mat-button-toggle value="Almost Completely True"
              >Almost Completely True</mat-button-toggle
            >
            <mat-button-toggle value="Completely True"
              >Completely True</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >13. I can't imagine ending my relationship with my partner</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="cantImagineEnding">
            <mat-button-toggle value="Not at all True"
              >Not at all True</mat-button-toggle
            >
            <mat-button-toggle value="A little True"
              >A little True</mat-button-toggle
            >
            <mat-button-toggle value="Somewhat True"
              >Somewhat True</mat-button-toggle
            >
            <mat-button-toggle value="Mostly True"
              >Mostly True</mat-button-toggle
            >
            <mat-button-toggle value="Almost Completely True"
              >Almost Completely True</mat-button-toggle
            >
            <mat-button-toggle value="Completely True"
              >Completely True</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >14. I feel that I can confide in my partner about virtually
          anything</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="feelCanConfideIn">
            <mat-button-toggle value="Not at all True"
              >Not at all True</mat-button-toggle
            >
            <mat-button-toggle value="A little True"
              >A little True</mat-button-toggle
            >
            <mat-button-toggle value="Somewhat True"
              >Somewhat True</mat-button-toggle
            >
            <mat-button-toggle value="Mostly True"
              >Mostly True</mat-button-toggle
            >
            <mat-button-toggle value="Almost Completely True"
              >Almost Completely True</mat-button-toggle
            >
            <mat-button-toggle value="Completely True"
              >Completely True</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >15. I have had second thoughts about this relationship
          recently</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="haveHadSecondThoughts">
            <mat-button-toggle value="Not at all True"
              >Not at all True</mat-button-toggle
            >
            <mat-button-toggle value="A little True"
              >A little True</mat-button-toggle
            >
            <mat-button-toggle value="Somewhat True"
              >Somewhat True</mat-button-toggle
            >
            <mat-button-toggle value="Mostly True"
              >Mostly True</mat-button-toggle
            >
            <mat-button-toggle value="Almost Completely True"
              >Almost Completely True</mat-button-toggle
            >
            <mat-button-toggle value="Completely True"
              >Completely True</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >16. For me, my partner is the perfect romantic partner</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="forMePartnerIsPerfect">
            <mat-button-toggle value="Not at all True"
              >Not at all True</mat-button-toggle
            >
            <mat-button-toggle value="A little True"
              >A little True</mat-button-toggle
            >
            <mat-button-toggle value="Somewhat True"
              >Somewhat True</mat-button-toggle
            >
            <mat-button-toggle value="Mostly True"
              >Mostly True</mat-button-toggle
            >
            <mat-button-toggle value="Almost Completely True"
              >Almost Completely True</mat-button-toggle
            >
            <mat-button-toggle value="Completely True"
              >Completely True</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >17. I really feel like part of a team with my partner</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="reallyFeelLikePartOf">
            <mat-button-toggle value="Not at all True"
              >Not at all True</mat-button-toggle
            >
            <mat-button-toggle value="A little True"
              >A little True</mat-button-toggle
            >
            <mat-button-toggle value="Somewhat True"
              >Somewhat True</mat-button-toggle
            >
            <mat-button-toggle value="Mostly True"
              >Mostly True</mat-button-toggle
            >
            <mat-button-toggle value="Almost Completely True"
              >Almost Completely True</mat-button-toggle
            >
            <mat-button-toggle value="Completely True"
              >Completely True</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >18. I cannot imagine another person making me as happy as my partner
          does</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="cannotImagineAnother">
            <mat-button-toggle value="Not at all True"
              >Not at all True</mat-button-toggle
            >
            <mat-button-toggle value="A little True"
              >A little True</mat-button-toggle
            >
            <mat-button-toggle value="Somewhat True"
              >Somewhat True</mat-button-toggle
            >
            <mat-button-toggle value="Mostly True"
              >Mostly True</mat-button-toggle
            >
            <mat-button-toggle value="Almost Completely True"
              >Almost Completely True</mat-button-toggle
            >
            <mat-button-toggle value="Completely True"
              >Completely True</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>

    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >19. How rewarding is your relationship with your partner?</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="howRewardingIsYour">
            <mat-button-toggle value="Not at All">Not at All</mat-button-toggle>
            <mat-button-toggle value="A little">A little</mat-button-toggle>
            <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
            <mat-button-toggle value="Mostly">Mostly</mat-button-toggle>
            <mat-button-toggle value="Almost completely"
              >Almost completely</mat-button-toggle
            >
            <mat-button-toggle value="Completely">Completely</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >20. How well does your partner meet your needs?</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="howWellDoesPartnerMeet">
            <mat-button-toggle value="Not at All">Not at All</mat-button-toggle>
            <mat-button-toggle value="A little">A little</mat-button-toggle>
            <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
            <mat-button-toggle value="Mostly">Mostly</mat-button-toggle>
            <mat-button-toggle value="Almost completely"
              >Almost completely</mat-button-toggle
            >
            <mat-button-toggle value="Completely">Completely</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >21. To what extent has your relationship met your original
          expectations?</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="toWhatExtentHasMet">
            <mat-button-toggle value="Not at All">Not at All</mat-button-toggle>
            <mat-button-toggle value="A little">A little</mat-button-toggle>
            <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
            <mat-button-toggle value="Mostly">Mostly</mat-button-toggle>
            <mat-button-toggle value="Almost completely"
              >Almost completely</mat-button-toggle
            >
            <mat-button-toggle value="Completely">Completely</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >22. In general, how satisfied are you with your relationship?</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="inGeneralHowSatisfied">
            <mat-button-toggle value="Not at All">Not at All</mat-button-toggle>
            <mat-button-toggle value="A little">A little</mat-button-toggle>
            <mat-button-toggle value="Somewhat">Somewhat</mat-button-toggle>
            <mat-button-toggle value="Mostly">Mostly</mat-button-toggle>
            <mat-button-toggle value="Almost completely"
              >Almost completely</mat-button-toggle
            >
            <mat-button-toggle value="Completely">Completely</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>

    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >23. How good is your relationship compared to most?</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="howGoodIsYourCompared">
            <mat-button-toggle value="Worse than all others"
              >Worse than all others</mat-button-toggle
            >
            <mat-button-toggle value="Poor">Poor</mat-button-toggle>
            <mat-button-toggle value="Fair">Fair</mat-button-toggle>
            <mat-button-toggle value="Good">Good</mat-button-toggle>
            <mat-button-toggle value="Very Good">Very Good</mat-button-toggle>
            <mat-button-toggle value="Better than all others"
              >Better than all others</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>

    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >24. Do you enjoy your partner's company?</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="doYouEnjoyPartnersComp">
            <mat-button-toggle value="Never">Never</mat-button-toggle>
            <mat-button-toggle value="Less than once a month"
              >Less than once a month</mat-button-toggle
            >
            <mat-button-toggle value="Once or twice a month"
              >Once or twice a month</mat-button-toggle
            >
            <mat-button-toggle value="Once or twice a week"
              >Once or twice a week</mat-button-toggle
            >
            <mat-button-toggle value="Once a day">Once a day</mat-button-toggle>
            <mat-button-toggle value="More Often">More Often</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row pad10">
        <label class="col-md-12 asterix-after"
          >25. How often do you and your partner have fun together?</label
        >
        <div class="col-sm-6">
          <mat-button-toggle-group formControlName="howOftenDoYouHaveFun">
            <mat-button-toggle value="Never">Never</mat-button-toggle>
            <mat-button-toggle value="Less than once a month"
              >Less than once a month</mat-button-toggle
            >
            <mat-button-toggle value="Once or twice a month"
              >Once or twice a month</mat-button-toggle
            >
            <mat-button-toggle value="Once or twice a week"
              >Once or twice a week</mat-button-toggle
            >
            <mat-button-toggle value="Once a day">Once a day</mat-button-toggle>
            <mat-button-toggle value="More Often">More Often</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-card>

    <div style="font-size: 13px; font-weight: 450">
      For each of the following items, select the answer that best describes how
      you feel about your relationship. Base your responses on your first
      impressions and immediate feelings about the item.
    </div>
    <mat-card appearance="raised" class="pad20 mat-elevation-z8">
      <mat-card-content>
        <div class="row pad10">
          <div class="col-sm-12">
            <label class="header">26.)</label>
            <div class="row">
              <div class="col-md-2" style="text-align: right">
                <label>INTERESTING</label>
              </div>
              <div class="col-md-8">
                <mat-slider
                  style="width: 100%"
                  [max]="max"
                  [min]="min"
                  [step]="step"
                  [thumbLabel]="thumbLabel"
                  ><input
                    matSliderThumb
                    formControlName="interestingOrBoring"
                  />
                </mat-slider>
              </div>
              <div class="col-md-2"><label>BORING</label></div>
            </div>
          </div>
        </div>
        <div class="row pad10">
          <div class="col-sm-12">
            <label class="header">27.)</label>
            <div class="row">
              <div class="col-md-2" style="text-align: right">
                <label>BAD</label>
              </div>
              <div class="col-md-8">
                <mat-slider
                  style="width: 100%"
                  [max]="max"
                  [min]="min"
                  [step]="step"
                  [thumbLabel]="thumbLabel"
                  ><input matSliderThumb formControlName="badOrGood" />
                </mat-slider>
              </div>
              <div class="col-md-2"><label>GOOD</label></div>
            </div>
          </div>
        </div>
        <div class="row pad10">
          <div class="col-sm-12">
            <label class="header">28.)</label>
            <div class="row">
              <div class="col-md-2" style="text-align: right">
                <label>FULL</label>
              </div>
              <div class="col-md-8">
                <mat-slider
                  style="width: 100%"
                  [max]="max"
                  [min]="min"
                  [step]="step"
                  [thumbLabel]="thumbLabel"
                  ><input matSliderThumb formControlName="fullOrEmpty" />
                </mat-slider>
              </div>
              <div class="col-md-2"><label>EMPTY</label></div>
            </div>
          </div>
        </div>
        <div class="row pad10">
          <div class="col-sm-12">
            <label class="header">29.)</label>
            <div class="row">
              <div class="col-md-2" style="text-align: right">
                <label>LONELY</label>
              </div>
              <div class="col-md-8">
                <mat-slider
                  style="width: 100%"
                  [max]="max"
                  [min]="min"
                  [step]="step"
                  [thumbLabel]="thumbLabel"
                  ><input matSliderThumb formControlName="lonelyOrFriendly" />
                </mat-slider>
              </div>
              <div class="col-md-2"><label>FRIENDLY</label></div>
            </div>
          </div>
        </div>
        <div class="row pad10">
          <div class="col-sm-12">
            <label class="header">30.)</label>
            <div class="row">
              <div class="col-md-2" style="text-align: right">
                <label>STURDY</label>
              </div>
              <div class="col-md-8">
                <mat-slider
                  style="width: 100%"
                  [max]="max"
                  [min]="min"
                  [step]="step"
                  [thumbLabel]="thumbLabel"
                  ><input matSliderThumb formControlName="sturdyOrFragile" />
                </mat-slider>
              </div>
              <div class="col-md-2"><label>FRAGILE</label></div>
            </div>
          </div>
        </div>
        <div class="row pad10">
          <div class="col-sm-12">
            <label class="header">31.)</label>
            <div class="row">
              <div class="col-md-2" style="text-align: right">
                <label>DISCOURAGING</label>
              </div>
              <div class="col-md-8">
                <mat-slider
                  style="width: 100%"
                  [max]="max"
                  [min]="min"
                  [step]="step"
                  [thumbLabel]="thumbLabel"
                  ><input
                    matSliderThumb
                    formControlName="discouragingOrHopeful"
                  />
                </mat-slider>
              </div>
              <div class="col-md-2"><label>HOPEFUL</label></div>
            </div>
          </div>
        </div>
        <div class="row pad10">
          <div class="col-sm-12">
            <label class="header">32.)</label>
            <div class="row">
              <div class="col-md-2" style="text-align: right">
                <label>ENJOYABLE</label>
              </div>
              <div class="col-md-8">
                <mat-slider
                  style="width: 100%"
                  [max]="max"
                  [min]="min"
                  [step]="step"
                  [thumbLabel]="thumbLabel"
                  ><input
                    matSliderThumb
                    formControlName="enjoyableOrMiserable"
                  />
                </mat-slider>
              </div>
              <div class="col-md-2"><label>MISERABLE</label></div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-dialog-actions align="end" *ngIf="!assessmentId">
      <button
        [class]="
          csiAssessmentForm.valid
            ? 'btn btn-primary pull-right'
            : 'btn btn-default pull-right'
        "
        mat-raised-button
        type="submit"
        [disabled]="!csiAssessmentForm.valid || processing"
      >
        Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
      </button>
    </mat-dialog-actions>
  </form>
</div>
