import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { buildValidationMsgs } from 'src/app/shared/utilities/utilities';
import { IMajorDepression } from 'src/app/_models/assessments/depression/majordepression.model';
import {
  MajorDepressionApiService,
  ToastMessageService,
} from 'src/app/_services/';

@Component({
  selector: 'app-major-depression',
  templateUrl: './major-depression.component.html',
  styleUrls: ['./major-depression.component.css'],
})
export class MajorDepressionComponent implements OnInit {
  @Input() displayTitle: boolean = true;
  @Input() patientId: string;
  @Input() sessionId: string;
  @Input() assessmentId: string;
  @Input() assessmentRequestId: string;
  @Input() isPatient: boolean;
  @Input() accessCode: string;
  @Output() taskCompleted = new EventEmitter<boolean>();

  constructor(
    public majorDepressionApiService: MajorDepressionApiService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) {}
  public riskScore: number;
  public majorDepressionForm: FormGroup;
  public formErrors: Record<string, Record<string, string>> = {};
  majorDepressionAssessment: IMajorDepression;
  majorDepressionAssessmentError: Error = null;
  errors: any = {};
  processing: boolean = false;
  disableFormElements: boolean = false;

  public ngOnInit() {
    if (this.assessmentId) {
      this.disableFormElements = true;
    }
    this.buildForm();
    this.errorHandler.handleErrors(this.majorDepressionForm, this.errors);

    this.loadMajorDepressionAssessment();
  }

  loadMajorDepressionAssessment() {
    this.majorDepressionForm.controls['patientId'].setValue(this.patientId);

    if (this.assessmentId) {
      this.majorDepressionApiService
        .getMajorDepressionDetails(this.assessmentId)
        .subscribe((majorDepressionAssessment) => {
          if (majorDepressionAssessment.data) {
            this.majorDepressionForm.controls['q1'].setValue(
              majorDepressionAssessment.data.q1.answer
            );
            this.majorDepressionForm.controls['q2'].setValue(
              majorDepressionAssessment.data.q2.answer
            );
            this.majorDepressionForm.controls['q3'].setValue(
              majorDepressionAssessment.data.q3.answer
            );
            this.majorDepressionForm.controls['q4'].setValue(
              majorDepressionAssessment.data.q4.answer
            );
            this.majorDepressionForm.controls['q5'].setValue(
              majorDepressionAssessment.data.q5.answer
            );
            this.majorDepressionForm.controls['q6'].setValue(
              majorDepressionAssessment.data.q6.answer
            );
            this.majorDepressionForm.controls['q7'].setValue(
              majorDepressionAssessment.data.q7.answer
            );
            this.majorDepressionForm.controls['q8'].setValue(
              majorDepressionAssessment.data.q8.answer
            );
            this.majorDepressionForm.controls['q9'].setValue(
              majorDepressionAssessment.data.q9.answer
            );
            this.majorDepressionForm.controls['q10'].setValue(
              majorDepressionAssessment.data.q10.answer
            );
            this.majorDepressionForm.controls['q11'].setValue(
              majorDepressionAssessment.data.q11.answer
            );
            this.majorDepressionForm.controls['q12'].setValue(
              majorDepressionAssessment.data.q12.answer
            );

            this.riskScore = majorDepressionAssessment.data.riskScore;
          }
        });
    }
  }

  onFormSubmit() {
    if (this.majorDepressionForm.invalid) {
      this.majorDepressionForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.majorDepressionAssessment = Object.assign(
      {},
      this.majorDepressionAssessment
    );
    this.majorDepressionAssessment.patientId =
      this.majorDepressionForm.controls['patientId'].value;
    this.majorDepressionAssessment.sessionId =
      this.majorDepressionForm.controls['sessionId'].value;
    this.majorDepressionAssessment.assessmentRequestId =
      this.majorDepressionForm.controls['assessmentRequestId'].value;
    this.majorDepressionAssessment.q1 = {
      answer: this.majorDepressionForm.controls['q1'].value,
    };
    this.majorDepressionAssessment.q2 = {
      answer: this.majorDepressionForm.controls['q2'].value,
    };
    this.majorDepressionAssessment.q3 = {
      answer: this.majorDepressionForm.controls['q3'].value,
    };
    this.majorDepressionAssessment.q4 = {
      answer: this.majorDepressionForm.controls['q4'].value,
    };
    this.majorDepressionAssessment.q5 = {
      answer: this.majorDepressionForm.controls['q5'].value,
    };
    this.majorDepressionAssessment.q6 = {
      answer: this.majorDepressionForm.controls['q6'].value,
    };
    this.majorDepressionAssessment.q7 = {
      answer: this.majorDepressionForm.controls['q7'].value,
    };
    this.majorDepressionAssessment.q8 = {
      answer: this.majorDepressionForm.controls['q8'].value,
    };
    this.majorDepressionAssessment.q9 = {
      answer: this.majorDepressionForm.controls['q9'].value,
    };
    this.majorDepressionAssessment.q10 = {
      answer: this.majorDepressionForm.controls['q10'].value,
    };
    this.majorDepressionAssessment.q11 = {
      answer: this.majorDepressionForm.controls['q11'].value,
    };
    this.majorDepressionAssessment.q12 = {
      answer: this.majorDepressionForm.controls['q12'].value,
    };

    // Determine if it is a guest url or regular
    if (this.accessCode) {
      this.majorDepressionAssessment.accessCode = this.accessCode;

      this.majorDepressionApiService
        .addGuestMajorDepression(this.majorDepressionAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Major Depression Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Major Depression Assessment'
            );
          }
        );
    } else {
      this.majorDepressionApiService
        .addMajorDepression(this.majorDepressionAssessment)
        .subscribe(
          (response) => {
            this.toastMessageService.displaySuccessMessage(
              'Successfully submitted Major Depression Assessment'
            );
            if (response.result) this.taskCompleted.emit(true);
            this.processing = false;
          },
          (error) => {
            this.processing = false;
            this.toastMessageService.displayErrorMessage(
              'Failed submitting Major Depression Assessment'
            );
          }
        );
    }
  }

  public validation_messages = {
    q1: [
      {
        type: 'required',
        message: '1. Have you felt low in spirits or sad? is required',
      },
    ],
    q2: [
      {
        type: 'required',
        message:
          '2. Have you lost interest in your daily activities? is required',
      },
    ],
    q3: [
      {
        type: 'required',
        message: '3. Have you felt lacking in energy and strength? is required',
      },
    ],
    q4: [
      {
        type: 'required',
        message: '4. Have you felt less self-confident? is required',
      },
    ],
    q5: [
      {
        type: 'required',
        message:
          '5. Have you had a bad conscience or feelings of guilt? is required',
      },
    ],
    q6: [
      {
        type: 'required',
        message: '6. Have you felt that life wasn’t worth living? is required',
      },
    ],
    q7: [
      {
        type: 'required',
        message: '7. Have you had difficulty in concentrating? is required',
      },
    ],
    q8: [
      {
        type: 'required',
        message: '8. Have you felt very restless? is required',
      },
    ],
    q9: [
      {
        type: 'required',
        message: '9. Have you felt subdued or slowed down? is required',
      },
    ],
    q10: [
      {
        type: 'required',
        message: '10. Have you had trouble sleeping at night? is required',
      },
    ],
    q11: [
      {
        type: 'required',
        message: '11. Have you suffered from reduced appetite? is required',
      },
    ],
    q12: [
      {
        type: 'required',
        message: '12. Have you suffered from increased appetite? is required',
      },
    ],
  };

  private buildForm() {
    this.majorDepressionForm = this.formBuilder.group({
      id: new FormControl('', Validators.compose([])),
      organizationId: new FormControl('', Validators.compose([])),
      patientId: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([])
      ),
      sessionId: new FormControl({
        value: this.sessionId,
        disabled: this.disableFormElements,
      }),
      assessmentRequestId: new FormControl(
        { value: this.assessmentRequestId, disabled: this.disableFormElements },
        Validators.compose([])
      ),
      q1: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q2: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q3: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q4: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q5: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q6: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q7: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q8: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q9: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q10: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q11: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
      q12: new FormControl(
        { value: '', disabled: this.disableFormElements },
        Validators.compose([Validators.required])
      ),
    });
    this.majorDepressionForm.valueChanges.subscribe(() =>
      this.onValueChanged()
    );
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

  private onValueChanged() {
    if (!this.majorDepressionForm) {
      return;
    }
    const form = this.majorDepressionForm;
    this.formErrors = {};
    buildValidationMsgs(form, this.formErrors, this.validation_messages);
  }
}
