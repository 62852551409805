<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Member Medications</p>
    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      type="button"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="row">
    <div class="col-md-12">
      <app-medications
        [patient]="patient"
        [sessionId]="sessionDetails.id"
        [prescriptionEnabled]="false"
        [displayTitle]="false"
        [hasMedicationViewAccess]="true"
        [hasMedicationAddAccess]="hasMedicationAddAccess"
        [hasMedicationEditAccess]="hasMedicationEditAccess"
        [hasMedicationDeleteAccess]="hasMedicationDeleteAccess"
      >
      </app-medications>
    </div>
  </div>
</div>
