<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle *ngIf="action === 'ADD'">Add Physician</p>
    <p matDialogTitle *ngIf="action === 'EDIT'">Edit Physician</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form
    [formGroup]="physicianForm"
    novalidate
    (ngSubmit)="onPhysicianFormSubmit()"
  >
    <div class="row">
      <!-- General Information -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <label class="labelHeader">
          <mat-icon style="margin-right: 10px">person</mat-icon> General
          Information
        </label>
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-6">
              <label class="labelText">
                First Name <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="First Name"
                  formControlName="firstName"
                  name="firstName"
                  required
                />
                <mat-error
                  *ngFor="let validation of validation_messages.firstName"
                >
                  <mat-error
                    class="error-message"
                    *ngIf="
                      physicianForm
                        .get('firstName')
                        .hasError(validation.type) &&
                      (physicianForm.get('firstName').dirty ||
                        physicianForm.get('firstName').touched)
                    "
                  >
                    {{ validation.message }}</mat-error
                  >
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <label class="labelText">
                Last Name <span style="color: red">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Last Name"
                  formControlName="lastName"
                  name="lastName"
                  required
                />
                <mat-error
                  *ngFor="let validation of validation_messages.lastName"
                >
                  <mat-error
                    class="error-message"
                    *ngIf="
                      physicianForm.get('lastName').hasError(validation.type) &&
                      (physicianForm.get('lastName').dirty ||
                        physicianForm.get('lastName').touched)
                    "
                  >
                    {{ validation.message }}</mat-error
                  >
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <label class="labelText"
                >Email <span style="color: red">*</span></label
              >
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Email"
                  formControlName="email"
                  name="email"
                  required
                />
                <mat-error *ngIf="physicianForm.controls['email'].invalid">
                  Please enter a valid email address
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <label class="labelText"
                >Phone <span style="color: red">*</span></label
              >
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Phone"
                  formControlName="phone"
                  name="phone"
                  required
                />
                <mat-error *ngIf="physicianForm.controls['phone'].invalid">
                  Please enter a valid phone number
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <label class="labelText">Fax</label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Fax"
                  formControlName="fax"
                  name="fax"
                />
                <mat-error *ngFor="let validation of validation_messages.fax">
                  <mat-error
                    class="error-message"
                    *ngIf="
                      physicianForm.get('fax').hasError(validation.type) &&
                      (physicianForm.get('fax').dirty ||
                        physicianForm.get('fax').touched)
                    "
                  >
                    {{ validation.message }}</mat-error
                  >
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <label class="labelText"> Specialty </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Specialty"
                  formControlName="speciality"
                  name="speciality"
                />
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>
      <!-- Clinic Information -->
      <div class="col-md-12" style="margin-bottom: 12px">
        <label class="labelHeader">
          <mat-icon style="margin-right: 10px">home_health</mat-icon> Clinic
          Information
        </label>
        <mat-card appearance="raised" class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-8">
              <label class="labelText">Hospital Name </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Hospital Name"
                  formControlName="hospitalName"
                  name="hospitalName"
                />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">Phone</label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Hospital Phone Number"
                  formControlName="hospitalPhoneNumber"
                  name="hospitalPhoneNumber"
                />
                <mat-error
                  *ngIf="physicianForm.controls['hospitalPhoneNumber'].invalid"
                >
                  Please enter a valid phone number
                </mat-error>
                <mat-error></mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-8">
              <label class="labelText">Hospital Address Line 1 </label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  [options]="options"
                  ngx-gp-autocomplete
                  #placesRef="ngx-places"
                  (onAddressChange)="handleAddressChange($event)"
                  placeholder="Hospital Address Line 1"
                  formControlName="hospitalAddressLine1"
                  name="hospitalAddressLine1"
                />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">Hospital Address Line 2</label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Hospital Address Line2"
                  formControlName="hospitalAddressLine2"
                  name="hospitalAddressLine2"
                />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">City</label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Hospital City"
                  formControlName="hospitalCity"
                  name="hospitalCity"
                />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">State</label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="hospitalState" placeholder="State">
                  <mat-option [value]="state.id" *ngFor="let state of usStates">
                    {{ state.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="physicianForm.controls['hospitalState'].invalid"
                >
                  Please select a valid state
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="labelText">Zip</label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Hospital {{ isIndianOrg ? 'Pin Code' : 'Zip' }}"
                  formControlName="hospitalZip"
                  name="hospitalZip"
                />
                <mat-error
                  *ngIf="physicianForm.controls['hospitalZip'].invalid"
                >
                  Please enter a valid zip
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <label class="labelText">Notes</label>
        <mat-form-field appearance="outline">
          <textarea
            matInput
            placeholder="Notes"
            formControlName="notes"
            name="notes"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
    <button
      class="btn btn-primary pull-right"
      mat-raised-button
      type="submit"
      [disabled]="!physicianForm.valid || processing"
    >
      Submit <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
    </button>
  </form>
</div>
