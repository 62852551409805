<div class="row">
  <div class="col-md-12">
    <p style="font-size: 16px; font-weight: 450">
      <a routerLink="/" routerLinkActive="active">
        <em
          class="material-icons text_align-center"
          style="font-size: 18px; margin-top: -3px"
          >home</em
        >
      </a>
      &nbsp; / &nbsp;
      <span style="color: dimgray">My Daily Work Schedule </span>
    </p>
  </div>
</div>

<mat-progress-bar
  mode="indeterminate"
  *ngIf="isLoadingResults"
></mat-progress-bar>

<!-- Quick Buttons -->
<div class="row" *ngIf="!isLoadingResults && canEditWorkingHours">
  <!-- Quick Pre-Set -->
  <div
    class="col-md-12"
    style="
      display: flex;
      flex-wrap: wrap;
      gap: 25px;
      justify-content: space-between;
    "
  >
    <button
      type="button"
      mat-raised-button
      color="primary"
      style="border-radius: 25px"
      (click)="presetWeekDays()"
    >
      <i class="material-icons">dataset</i> Set M-F, 9am - 5pm
    </button>

    <button
      type="button"
      mat-raised-button
      color="warn"
      style="border-radius: 25px"
      (click)="clearAll()"
    >
      <i class="material-icons">clear</i> Clear All
    </button>
  </div>
</div>

<div class="row" style="display: flex; justify-content: center">
  <div class="calendarContainer">
    <form [formGroup]="addworkinghoursForm" multiple="true" style="width: 100%">
      <div *ngIf="!isLoadingResults">
        <!-- For All Days -->
        <div
          style="width: 100%"
          *ngFor="let individualDay of weekDays; let i = index"
        >
          <div class="gridContainer" formGroupName="{{ individualDay }}">
            <p class="heading" style="text-transform: capitalize">
              {{ individualDay }}
            </p>
            <div class="toggleButton">
              <mat-slide-toggle
                color="primary"
                [checked]="showSchedule[individualDay]"
                [disabled]="!canEditWorkingHours"
                (change)="toShowSchedule(individualDay)"
              >
              </mat-slide-toggle>
              {{ showSchedule[individualDay] ? '' : 'Off Day' }}
            </div>
            <div class="workHours" *ngIf="displaySchedule(individualDay)">
              <div formArrayName="workingHours" style="width: 100%">
                <div
                  *ngFor="
                    let child of getDayWorkingHoursControl(individualDay);
                    let index = index
                  "
                >
                  <div class="timeBox" [formGroupName]="index">
                    <mat-form-field
                      appearance="outline"
                      style="min-width: 80px; max-width: 150px"
                    >
                      <mat-select
                        placeholder="Starts at"
                        formControlName="startTime"
                      >
                        <mat-option
                          *ngFor="let startTime of timeArray"
                          [value]="startTime.value"
                        >
                          {{ startTime.text }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <label
                      class="labelText"
                      style="margin-top: 15px; font-weight: 500; opacity: 0.7"
                      >TO</label
                    >
                    <mat-form-field
                      appearance="outline"
                      style="min-width: 80px; max-width: 150px"
                    >
                      <mat-select
                        placeholder="Ends at"
                        formControlName="endTime"
                      >
                        <mat-option
                          *ngFor="let endTime of timeArray"
                          [value]="endTime.value"
                          >{{ endTime.text }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                    <button
                      type="button"
                      mat-mini-fab
                      *ngIf="index == 0 && canEditWorkingHours"
                      style="margin-top: 5px; background-color: transparent"
                      (click)="addNewRecord(individualDay, 0, 0)"
                    >
                      <mat-icon style="color: #2196f3"> add </mat-icon>
                    </button>
                    <button
                      mat-mini-fab
                      type="button"
                      *ngIf="index !== 0 && canEditWorkingHours"
                      style="margin-top: 5px; background-color: transparent"
                      (click)="deleteRecord(individualDay, index)"
                    >
                      <mat-icon style="color: red"> delete </mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="text-align: center" *ngIf="canEditWorkingHours">
          <button
            color="primary"
            mat-raised-button
            type="submit"
            [disabled]="processing"
            (click)="addSchedule()"
          >
            Save Schedule
            <i class="fa fa-spinner fa-spin" *ngIf="processing"></i>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="row" *ngIf="!canEditWorkingHours">
  <label
    ><i>
      * If you would like to make changes to your working hours, please reach
      out to your system administrator.
    </i></label
  >
</div>
