<div class="container">
  <div class="dialogTitle">
    <p matDialogTitle>Edit Supervising Provider</p>

    <button
      mat-mini-fab
      style="background-color: transparent; color: #555555"
      aria-label="close dialog"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <form [formGroup]="supervisingRPForm" novalidate style="margin-top: 20px">
    <div class="row">
      <div class="col-md-12" style="text-align: right">
        <button
          mat-raised-button
          color="primary"
          type="button"
          (click)="searchSupervisingProvider()"
        >
          Search Supervising Provider
        </button>
      </div>

      <div
        class="col-md-12"
        *ngIf="supervisingRPForm.controls['supervisingRPId'].invalid"
      >
        <label style="font-style: italic; margin-top: 15px">
          * Please search and select the supervising rendering provider.
        </label>
      </div>

      <div
        class="col-md-4"
        *ngIf="supervisingRPForm.controls['supervisingRPId'].valid"
      >
        <label class="labelText">
          First Name <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Name"
            formControlName="supervisingRPFirstName"
            name="supervisingRPFirstName"
            required
          />
          <mat-error
            *ngIf="supervisingRPForm.controls['supervisingRPFirstName'].invalid"
          >
            Please enter the supervisor first name
          </mat-error>
        </mat-form-field>
      </div>
      <div
        class="col-md-4"
        *ngIf="supervisingRPForm.controls['supervisingRPId'].valid"
      >
        <label class="labelText"> Middle Name </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Name"
            formControlName="supervisingRPMiddleName"
            name="supervisingRPMiddleName"
          />
          <mat-error
            *ngIf="
              supervisingRPForm.controls['supervisingRPMiddleName'].invalid
            "
          >
            Please enter the supervisor middle name
          </mat-error>
        </mat-form-field>
      </div>
      <div
        class="col-md-4"
        *ngIf="supervisingRPForm.controls['supervisingRPId'].valid"
      >
        <label class="labelText">
          Last Name <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Name"
            formControlName="supervisingRPLastName"
            name="supervisingRPLastName"
            required
          />
          <mat-error
            *ngIf="supervisingRPForm.controls['supervisingRPLastName'].invalid"
          >
            Please enter the supervisor last name
          </mat-error>
        </mat-form-field>
      </div>
      <div
        class="col-md-6"
        *ngIf="supervisingRPForm.controls['supervisingRPId'].valid"
      >
        <label class="labelText">
          Email <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            type="email"
            placeholder="Email"
            formControlName="supervisingRPEmail"
            name="supervisingRPEmail"
            required
          />
          <mat-error
            *ngIf="supervisingRPForm.controls['supervisingRPEmail'].invalid"
          >
            Please enter the supervisor email
          </mat-error>
        </mat-form-field>
      </div>
      <div
        class="col-md-6"
        *ngIf="supervisingRPForm.controls['supervisingRPId'].valid"
      >
        <label class="labelText">
          Phone <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            formControlName="supervisingRPPhone"
            name="supervisingRPPhone"
            required
          />
          <mat-error
            *ngIf="supervisingRPForm.controls['supervisingRPPhone'].invalid"
          >
            Please enter a valid phone number
          </mat-error>
        </mat-form-field>
      </div>
      <div
        class="col-md-6"
        *ngIf="supervisingRPForm.controls['supervisingRPId'].valid"
      >
        <label class="labelText"> NPI <span style="color: red">*</span> </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="NPI"
            formControlName="supervisingRPNPI"
            name="npi"
            minlength="10"
            maxlength="10"
          />
          <mat-error
            *ngIf="supervisingRPForm.controls['supervisingRPNPI'].invalid"
          >
            Please enter a valid NPI
          </mat-error>
        </mat-form-field>
      </div>
      <div
        class="col-md-6"
        style="margin-bottom: 12px"
        *ngIf="supervisingRPForm.controls['supervisingRPId'].valid"
      >
        <label class="labelText"> Taxonomy Code </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Taxonomy Code"
            formControlName="supervisingRPTaxonomyCode"
            name="taxonomy"
          />
          <mat-error
            *ngIf="
              supervisingRPForm.controls['supervisingRPTaxonomyCode'].invalid
            "
          >
            Please enter the Taxonomy Code
          </mat-error>
        </mat-form-field>
      </div>
      <div
        class="col-md-6"
        style="margin-bottom: 12px"
        *ngIf="
          parentClass === 'Claims' &&
          supervisingRPForm.controls['supervisingRPId'].valid
        "
      >
        <label class="labelText">
          Does this provider bill using the Tax Id or SSN?
          <span style="color: red">*</span>
        </label>
        <br />
        <mat-radio-group
          aria-label="Select an option"
          formControlName="supervisingRPBillingOption"
          style="column-gap: 15px; display: flex; flex-wrap: wrap"
          color="primary"
          (change)="billingOptionChanged($event)"
        >
          <mat-radio-button value="Tax Id">Tax Id</mat-radio-button>
          <mat-radio-button value="SSN">SSN</mat-radio-button>
        </mat-radio-group>
      </div>
      <div
        class="col-md-6"
        *ngIf="
          parentClass === 'Claims' &&
          supervisingRPForm.controls['supervisingRPBillingOption'].value ===
            'Tax Id' &&
          supervisingRPForm.controls['supervisingRPId'].valid
        "
      >
        <label class="labelText">
          Tax Id
          <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Tax Id"
            formControlName="supervisingRPTaxId"
            name="taxId"
            [type]="passwordView ? 'text' : 'password'"
          />
          <mat-icon matSuffix (click)="passwordView = !passwordView">{{
            passwordView ? 'visibility' : 'visibility_off'
          }}</mat-icon>
          <mat-error
            *ngIf="supervisingRPForm.controls['supervisingRPTaxId'].invalid"
          >
            Please enter the Tax Id
          </mat-error>
        </mat-form-field>
      </div>
      <div
        class="col-md-6"
        *ngIf="
          parentClass === 'Claims' &&
          supervisingRPForm.controls['supervisingRPBillingOption'].value ===
            'SSN' &&
          supervisingRPForm.controls['supervisingRPId'].valid
        "
      >
        <label class="labelText">
          SSN
          <span style="color: red">*</span>
        </label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="SSN"
            formControlName="supervisingRPSSN"
            name="ssn"
            [type]="passwordView ? 'text' : 'password'"
          />
          <mat-icon matSuffix (click)="passwordView = !passwordView">{{
            passwordView ? 'visibility' : 'visibility_off'
          }}</mat-icon>
          <mat-error
            *ngIf="supervisingRPForm.controls['supervisingRPSSN'].invalid"
          >
            Please enter the SSN
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="col-md-12"
        style="text-align: right"
        *ngIf="supervisingRPForm.controls['supervisingRPId'].valid"
      >
        <button
          class="btn btn-primary pull-right"
          mat-raised-button
          type="button"
          (click)="submitForm()"
          [disabled]="!supervisingRPForm.valid"
        >
          Update
        </button>
      </div>
    </div>
  </form>
</div>
