import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { DataTablesModel } from '../_models/data-tables-modal';

@Injectable({
  providedIn: 'root',
})
export class NDCMedicationService {
  constructor(private httpClient: HttpClient) {}

  getMedicationInformation(
    name: string,
    pageSize: number,
    pageIndex: number
  ): Observable<DataTablesModel> {
    let params = new HttpParams();

    if (name != null && name.length > 0) {
      params = params.append('name', name);
    }

    params = params.append('pageSize', pageSize);
    params = params.append('pageIndex', pageIndex);

    return this.httpClient.get<DataTablesModel>(
      `${environment.apiUrl}/ndcmedicationlist`,
      { params }
    );
  }
}
