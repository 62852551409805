import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AssessmentApiService } from 'src/app/_services';
import { assessmentGroups } from 'src/app/shared/utilities/assessment-utils';

@Component({
  selector: 'app-play-books',
  templateUrl: './play-books.component.html',
  styleUrls: ['./play-books.component.css'],
})
export class PlayBookComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    public assessmentApiService: AssessmentApiService
  ) {
    this.answerForm = this.fb.group({
      assessmentName: new FormControl('', Validators.compose([])),
    });
  }

  selectedAssessmentType: string;
  questions: any[];
  isLoading = true;
  error: string = '';
  metadata: any;
  answerForm: FormGroup;
  assessmentGroups: any[] = assessmentGroups();

  ngOnInit(): void {}
}
