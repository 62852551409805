<!-- Regular Login View -->
<div class="row" *ngIf="!isSSOView">
  <!-- Left Side -->
  <div class="col-md-4 col-xl-3" style="
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      margin-bottom: 15px;
    ">
    <!-- First Show Member Information -->
    <app-patient-self-information></app-patient-self-information>

    <!-- This is For Virtual Appointments -->
    <app-member-dashboard-virtual-appointments *ngIf="teleHealthEnabled"></app-member-dashboard-virtual-appointments>

    <!-- Patient Mood Check In -->
    <app-hourly-mood-assessment></app-hourly-mood-assessment>
  </div>

  <!-- Right  Side -->
  <div class="col-md-8 col-xl-9">
    <!-- Home Page  -->
    <app-patient-dashboard-homepage *ngIf="mainTab === 'Home'"></app-patient-dashboard-homepage>

    <!-- Assessments Page  -->
    <app-patient-screen-assessments *ngIf="mainTab === 'Assessments'"></app-patient-screen-assessments>

    <!-- Sessions Tab -->
    <app-patient-screen-sessions *ngIf="mainTab === 'Sessions'">
    </app-patient-screen-sessions>

    <!-- Goal Tab -->
    <app-patient-screen-goals *ngIf="mainTab === 'Goals'">
    </app-patient-screen-goals>

    <app-consent-document-esign *ngIf="mainTab === 'e-signature' && secondaryId"
      [documentId]="secondaryId"></app-consent-document-esign>
  </div>
</div>

<!-- SSO View -->
<div class="row" *ngIf="isSSOView">
  <div class="col-md-12">
    <!-- Assessments Page  -->
    <app-patient-screen-assessments *ngIf="mainTab === 'Assessments'"></app-patient-screen-assessments>

    <!-- Sessions Tab -->
    <app-patient-screen-sessions *ngIf="mainTab === 'Appointments'">
    </app-patient-screen-sessions>

    <!-- Goal Tab -->
    <app-patient-screen-goals *ngIf="mainTab === 'Goals'">
    </app-patient-screen-goals>
  </div>
</div>