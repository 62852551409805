<div class="row">
  <!-- Dividing into two parts  -->
  <!-- Left is for Graphs -->
  <div class="col-md-8">
    <div class="row">
      <!-- Sessions Attended -->
      <div class="col-sm-12 col-lg-12">
        <mat-card
          appearance="raised"
          style="border-radius: 15px; margin-bottom: 15px; align-items: center"
        >
          <div
            id="patientSessionsAttendedChart"
            style="height: 500px; width: 100%"
          ></div>
        </mat-card>
      </div>
      <!-- Top 10 Assessments -->
      <div class="col-sm-12 col-lg-12">
        <mat-card
          appearance="raised"
          style="border-radius: 15px; margin-bottom: 15px; align-items: center"
        >
          <div
            id="patientTopTenAssesmentsChart"
            style="height: 500px; width: 100%"
          ></div>
        </mat-card>
      </div>
    </div>
  </div>
  <!-- Right Side is for statistics -->
  <div class="col-md-4">
    <!-- To - Do List Cards -->
    <app-member-todo-list></app-member-todo-list>

    <!-- Highlights Card -->
    <mat-card
      apperance="raised"
      class="dashboardCardsContainer mat-elevation-z8"
      *ngIf="highlightsCard"
    >
      <!-- Main Icon -->
      <div
        class="dashboardCardIcons"
        [style.background]="highlightsCard.iconBackgroundColor"
      >
        <mat-icon style="color: white">{{ highlightsCard.icon }}</mat-icon>
      </div>
      <!-- Main Header -->
      <div class="dashboardMainTitle">
        <label class="labelHeader">{{ highlightsCard.title }}</label>
      </div>
      <!-- Inner Dashboard components -->
      <div class="dashboardContentWrapper">
        <div
          class="contentWrapper"
          *ngFor="let content of highlightsCard.cards"
          (click)="navigateTo(content.route)"
        >
          <mat-icon [style.color]="content.iconBackgroundColor">
            {{ content.icon }}
          </mat-icon>
          <label class="labelText" [style.cursor]="content.cursor">
            {{ content.title }}
          </label>
          <label class="labelBold" [style.cursor]="content.cursor">
            {{ content.count }}
          </label>
        </div>
      </div>
    </mat-card>

    <!-- Overall Assessment Progress -->
    <mat-card
      class="mat-elevation-z8"
      appearance="raised"
      style="
        border-radius: 15px;
        margin-top: 15px;
        margin-bottom: 15px;
        align-items: center;
      "
    >
      <div
        id="patientOverallAssessmentProgressChart"
        style="height: 500px; width: 100%"
      ></div>
    </mat-card>
  </div>
</div>
